export const GET_USER_STATUS = "GET_USER_STATUS"
export const GET_USER_STATUS_SUCCESS = "GET_USER_STATUS_SUCCESS"
export const GET_USER_STATUS_ERROR = "GET_USER_STATUS_ERROR"

export const GET_USER_STATUS_LIST = "GET_USER_STATUS_LIST"
export const GET_USER_STATUS_LIST_SUCCESS = "GET_USER_STATUS_LIST_SUCCESS"
export const GET_USER_STATUS_LIST_ERROR = "GET_USER_STATUS_LIST_ERROR"
  
export const CREATE_USER_STATUS = "CREATE_USER_STATUS"
export const CREATE_USER_STATUS_SUCCESS = "CREATE_USER_STATUS_SUCCESS"
export const CREATE_USER_STATUS_ERROR = "CREATE_USER_STATUS_ERROR"

export const UPDATE_USER_STATUS = "UPDATE_USER_STATUS"
export const UPDATE_USER_STATUS_SUCCESS = "UPDATE_USER_STATUS_SUCCESS"
export const UPDATE_USER_STATUS_ERROR = "UPDATE_USER_STATUS_ERROR"

export const DELETE_USER_STATUS = "DELETE_USER_STATUS"
export const DELETE_USER_STATUS_SUCCESS = "DELETE_USER_STATUS_SUCCESS"
export const DELETE_USER_STATUS_ERROR = "DELETE_USER_STATUS_ERROR"