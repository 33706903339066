import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import ReactApexChart from "react-apexcharts";
import { noData } from '../../../utils/data';
import LoadingSpinner from '../../../components/Common/Loader';
import { secondsToTime, serialize } from '../../../utils/utils';
import { formatCurrency } from '../../../utils/formats';
import GraphFilters from './Filters';
import { getUserProfile } from '../../../helpers/functions_helper';
import usersService from '../../../services/users.service';
import { currentMonth, currentYear } from '../../../common/staticdata';
import ChartFilters from '../../../components/Chart/Filters';
import Chart from '../../../components/Chart';

interface UserVsExpiredProps extends CartProps {
    filters?: any;
}

const UserVsExpired: FC<UserVsExpiredProps> = ({ hideHeading, filters = null }) => {

    const { t } = useTranslation();

    const userObj = getUserProfile();

    const inititalFilter = {
        week: null,
        quarter: null,
        month: null,
        year: currentYear,
        user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_ID : null
    };

    const [loading, setLoading] = useState<boolean>(true);
    const [query, setQuery] = useState<string | null>(serialize(inititalFilter));
    const [filter, setFilter] = useState<any>(inititalFilter);
    useEffect(() => {
        clearWorkAllocationFilter();
    }, [localStorage.getItem("I18N_LANGUAGE")]);
    const [data, setData] = useState<any>({
        series: [],
        options: {
            chart: {
                width: 380,
                type: 'pie',
            },
            colors: [],
            labels: [],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        show: true,
                        position: 'left'
                    }
                }
            }],
            legend: {
                show: true,
                position: 'right'
            },
            // stroke: {
            //     colors: ['#333'],
            //     width: 1
            // },
            noData: noData,
        },
    })

    const getChartData = async () => {
        const result = await usersService.userVsExpired(query);
        if (result.data.status === 'success') {
            let series: any = [];
            if (result?.data?.data.expired || result?.data?.data?.non_expired) {
                series = [result?.data?.data?.expired || 0, result?.data?.data?.non_expired || 0];
            }
            setData((data: any) => ({
                ...data,
                series: series,
                options: {
                    ...data.options,
                    colors: ['#5d7afd', '#efb34c'],
                    labels: [t('Expired user'), t('Non expired user')],
                }

            }))
        }
        setLoading(false);
    }

    const clearWorkAllocationFilter = () => {
        setFilter(inititalFilter);
        setData((state: any) => ({
            ...state,
            options: {
                ...state.options,
                labels: [t('Expired user'), t('Non expired user')],
            }
        }));
    }

    useEffect(() => {
        loading && getChartData();
    }, [loading])

    useEffect(() => {
        setQuery(serialize(filter));
        setLoading(true);
    }, [filter])

    useEffect(() => {
        filters && setFilter(filters)
    }, [filters])

    return (
        <>
            <div className="d-sm-flex flex-wrap">
                {!hideHeading &&
                    <h4 className="card-title mb-4">{t("Expired user vs Non expired user")}</h4>
                }
                <div className="ms-auto">
                    <ChartFilters
                        filter={filter}
                        setFilter={setFilter}
                        clearFilter={clearWorkAllocationFilter}
                    />
                </div>
            </div>
            <div className="clearfix"></div>
            {loading ? <LoadingSpinner height={380} /> : <div style={{ height: '100%' }}><Chart options={data.options} series={data.series} type="pie" height={380} /></div>}
        </>
    )
}

export default UserVsExpired;