import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Nav, NavItem, Row } from "reactstrap"
import { useSelector } from "react-redux";
import './style.scss';
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../components/Layout/Breadcrumb";
import CompanyVsIndividual from "./CompanyVsIndividual";
import SplitPayrollCreatedbyCompany from "./SplitPayrollCreatedbyCompany";
import SplitPayRollDailyCounter from "./SplitPayRollDailyCounter";

import { useTranslation } from "react-i18next";
import { getUserProfile, widgetAccess } from "../../../helpers/functions_helper";
import GraphFilters from "../../../components/Common/GraphFilters";




const SplitPayrollGraphicalView = (props: any) => {
  const { t } = useTranslation();
  document.title = `${t("Split Payroll")} - ${t("Graphical view")} | " ${process.env.REACT_APP_NAME}`;
  const userProfile = getUserProfile("all-split-payroll");
  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "#",
      label: t("Split Payroll"),
      active: true,
    },
  ];
  const inititalFilter = {
    week: null,
    quarter: null,
    month: null,
    year: null,
    user_id:null,
  };
  const [filter, setFilter] = useState<any>(inititalFilter);

  const clearFilter = () => {
    setFilter(inititalFilter);
  };
  return (
    <React.Fragment>
      <>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              breadcrumbTitle="Split Payroll"
              breadcrumbItems={breadcrumbItems}
              endAdornment={
                <div className="d-flex gap-2 text-end">
                  <Link to="/split-payroll/list/all" className={`btn btn-outline-secondary btn-label btn-sm`}>
                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                      list_alt
                    </span>
                    {t("List view")}
                  </Link>

                  <Link to="/split-payroll/graph" className={`btn btn-info btn-label btn-sm`}>
                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                      insert_chart
                    </span>
                    {t("Graphical view")}
                  </Link>

                  {userProfile.CAN_CREATE ? (
                    <Link to="/split-payroll/new-split-payroll" className="btn btn-primary btn-label btn-sm">
                      <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                        add
                      </span>{" "}
                      {t("Create split payroll")}
                    </Link>
                  ) : null}
                </div>
              }
            />
            {widgetAccess('Split_Payroll_Count') ? <SplitPayRollDailyCounter /> : null}
            <div className="d-flex justify-content-end mb-2">
              <h5 className="card-title me-2">{t("Global Filter")}</h5>
              <div className="ms-2">
                <GraphFilters
                  filter={filter}
                  setFilter={setFilter}
                  clearWorkAllocationFilter={clearFilter}
                  userObj={userProfile}
                />
              </div>
            </div>
            {widgetAccess('SplitPayroll_Created_by_Company') ? <Card className="border-0">
              <CardBody>
                <SplitPayrollCreatedbyCompany 
                filters={filter}
                />
              </CardBody>
            </Card>:null}
            <Row>
              {widgetAccess('Company_Vs_Individual') ?<Col sm={6}>
                <Card className="border-0">
                  <CardBody>
                    <CompanyVsIndividual 
                    filters={filter}
                    />
                  </CardBody>
                </Card>
              </Col>:null}
            </Row>
          </Container>
        </div>
      </>
    </React.Fragment>
  )
}


export default SplitPayrollGraphicalView
