import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  Label,
  Input,
  Button,
  FormFeedback,
  Alert,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

// Service
import commonService from "../../../services/common.service";

const UserStatusForm = (props: any) => {
  let userStatusData = props.userStatusData;

  const validationSchema = Yup.object().shape({
    user_status_value: Yup.string()
      .required("The user status field is required.")
      .min(2, "The user status must be at least 2 characters.")
      .max(200, "The user status may not be greater than 200 characters."),
  });

  const handleSubmit = (values: {
    id: string;
    user_status_id: string;
    user_status_value: string;
    color_code: string;
  }) => {
    props.onSubmitClick(values);
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: (userStatusData && userStatusData.id) || "",
      user_status_id: (userStatusData && userStatusData.user_status_id) || "",
      user_status_value: (userStatusData && userStatusData.user_status_value) || "",
      color_code: (userStatusData && userStatusData.color_code) || "#000000",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setErrors }) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    if (!validation.isSubmitting) return;
    if (Object.keys(validation.errors).length > 0) {
      document
        .getElementById(Object.keys(validation.errors)[0])
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
      document
        .getElementById(Object.keys(validation.errors)[0])
        ?.focus({ preventScroll: true });
    }
  }, [validation.isSubmitting, validation.errors]);

  return (
    <React.Fragment>
      <Modal
        size="md"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">{props.modalTitle}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label="Close"
          ></button>
        </div>

        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          autoComplete="off"
        >
          <ModalBody>
            {props.errorMsg ? 
              <Alert color="danger">
                {props.errorMsg}

                {props.errorData.data ?
                  Object.keys(props.errorData.data).map(key => (
                    <li color="danger" key={key}>{props.errorData.data[key]}</li>
                  )) : null
                }
              </Alert> : null
            }

            <Input
              id="id"
              name="id"
              type="hidden"
              value={validation.values.id || ""}
              onChange={validation.handleChange}
            />
            <Input
              id="user_status_id"
              name="user_status_id"
              type="hidden"
              value={validation.values.user_status_id || ""}
              onChange={validation.handleChange}
            />

            <Row>
              <Col className="col-12">
                <div className="mb-3">
                  <Label className="form-label">
                    User status{" "}
                    <span className="text-warning is_required">*</span>
                  </Label>
                  <div className="color_palette_main_div">
                    <Input
                      id="user_status_value"
                      name="user_status_value"
                      className="form-control"
                      placeholder="User status"
                      type="text"
                      value={validation.values.user_status_value || ""}
                      autoFocus={true}
                      onKeyUp={validation.handleChange}
                      onChange={validation.handleChange}
                      invalid={
                        validation.touched.user_status_value &&
                        validation.errors.user_status_value
                          ? true
                          : false
                      }
                    />
                    {validation.touched.user_status_value &&
                    validation.errors.user_status_value ? (
                      <FormFeedback type="invalid">
                        {validation.errors.user_status_value}
                      </FormFeedback>
                    ) : null}

                    <div className="color_palette_div">
                      <Input
                        id="color_code"
                        name="color_code"
                        type="color"
                        value={validation.values.color_code || ""}
                        className="color_palette"
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              className="btn-block"
              type="submit"
              disabled={props.loading}
            >
              {props.loading ? (
                <span>
                  <i className="fa-solid fa-spinner fa-spin"></i>
                  <span>&nbsp; Please wait...</span>
                </span>
              ) : (
                <span>Submit</span>
              )}
            </Button>
            <Button
              color="danger"
              className="btn-block"
              type="reset"
              onClick={props.onCloseClick}
              disabled={props.loading}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default UserStatusForm;
