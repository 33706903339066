import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, CardHeader, Col, Form, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, Row, Table } from 'reactstrap';
import './workdayCalender.scss';
import Select from "react-select";
import { filterCountryByLanguage, getDependentMultipleWhereSelectOptions, getSelectOptions, getUserProfile, yearDataPreOnly } from '../../helpers/functions_helper';
import { workCalender } from '../../common/staticdata';
import moment from 'moment';
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from 'react-toastify';
import questionnaireService from '../../services/questionnaire.service';
import { useDispatch } from 'react-redux';
import Flatpickr from "react-flatpickr";
import { useNavigate } from 'react-router-dom';

const WorkdayCalender = (props: any) => {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const userProfile = getUserProfile('all-users');
    const [loading, setLoading] = useState(false);
    const [calenderView, setCalenderView] = useState(false);
    const [countryOptions, setCountryOptions] = useState([]);
    const [allCountry, setAllCountry] = useState([]);
    const [modal, setModal] = useState(false);
    const [workCntryUser, setWorkCntryUser] = useState<any>([]);// { label: "BELGIUM", value: 21 }
    const [displayType, setDisplayType] = useState("");
    const [thisYear, setThisYear] = useState<any>(props.thisYear);
    const [defltCntry, setDefltCntry] = useState(0);
    const [countrySelected, setCountrySelected] = useState(0);
    const [show183Modal, setShow183Modal] = useState(false);
    const toastId = React.useRef(null) as any;
    const dispatch = useDispatch();
    let { user_id, questionnaire_id, questionnaire } = props;
    useEffect(() => {
        setFromDate(moment('01-01-' + thisYear).format("YYYY-MM-DD"));
        setToDate(moment('12-31-' + thisYear).format("YYYY-MM-DD"));
    }, [thisYear]);
    const time = {
        constants: {
            days: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
            months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        },
    };

    const year: any = [];
    const weekStartsOnMonday = true; // considering monday as start of week
    const totalWeekends: any = [];
    time.constants.months.forEach((month, i) => {
        const firstDate = new Date(thisYear, i, 1);
        const lastDate = new Date(thisYear, i + 1, 0);
        const totalDays = lastDate.getDate();
        const weekends = [];
        const offset = firstDate.getDay() - 1;
        for (let date = 1; date <= totalDays; date++) {
            if ((date + offset) % 7 === 0 || (date + offset) % 7 === 6) {
                weekends.push(date);
                totalWeekends.push(date);
            }
        }
        year.push({
            name: time.constants.months[i],
            number: i,
            firstDay: firstDate.getDay(),
            totalDays: totalDays,
            weekends: weekends,
        });
    });


    const firstColumnWeekDay = year.reduce((result: any, current: any) => {
        if (current.firstDay === 0) {
            return result;
        }
        return current.firstDay < result ? current.firstDay : result;
    }, 6);

    const daysInYear = (year: any) => {
        return ((year % 4 === 0 && year % 100 > 0) || year % 400 == 0) ? 366 : 365;
    }


    //find the length of columns
    const longestRow = year.reduce((result: any, current: any) => {
        const cellsBeforeFirstDay = current.firstDay === 0 ?
            6 :
            current.firstDay - firstColumnWeekDay;
        const rowTotal = cellsBeforeFirstDay + current.totalDays;
        return rowTotal > result ? rowTotal : result;
    }, 0);

    // get an array for th
    const startDay = weekStartsOnMonday ? 1 : 0;
    const endDay = weekStartsOnMonday ? longestRow + 1 : longestRow;
    const th: any = [];
    for (let i = startDay; i < endDay; i++) {
        th.push(time.constants.days[i % 7]);
    }

    for (let month of year) {
        month.startCell = ((month.firstDay - firstColumnWeekDay) + 7) % 7;
        month.endCell = month.startCell + month.totalDays - 1;
    }


    const [calenderData, setCalenderData] = useState<any>([]);
    const [reportData, setReportData] = useState([]);
    const [userData, setUserData] = useState<any>([]);
    const [fromDate, setFromDate] = useState<any>("");
    const [toDate, setToDate] = useState<any>("");
    const [totalworkDays, setTotalWorkDays] = useState<any>(0);
    const [workDays, setWorkDays] = useState<any>(0);
    const [sickday, setSickDays] = useState(0);
    const [compensation, setCompensation] = useState(0);
    const [bankholiday, setBankHoliday] = useState(0);
    const [holiday, setHoliday] = useState(0);
    const [workdaybel, setWorkdaybel] = useState(0);
    const [weekendWorking, setWeekendWorking] = useState(0);
    const [tr, setTr] = useState<any>([]);
    const [extradata, setExtradata] = useState<any>([]);
    const [displayData, setDisplayData] = useState<any>([]);
    const [loadingRecord, setLoadingRecord] = useState<any>(true);
    useEffect(() => {
        if (calenderView) {
            getCalendarData();
        }
    }, [calenderView]);
    const getCalendarData = async () => {
        if (user_id) {
            setLoadingRecord(true);
            const from_date = fromDate != "" ? fromDate : moment('01-01-' + thisYear).format("YYYY-MM-DD");
            const to_date = toDate != "" ? toDate : moment('12-31-' + thisYear).format("YYYY-MM-DD");
            const response = await questionnaireService.getCalendarData({ user_id, thisYear, questionnaire_id, fromDate: from_date, toDate: to_date });
            if (response.status === 'success') {
                setCalenderData(response.data);
                setReportData(response.data_group);
                setUserData(response.data_user);
                setSickDays(response.sickdays);
                setCompensation(response.compensation);
                setHoliday(response.holidays);
                setWorkdaybel(response.belgium_worked_day);
                setTotalWorkDays(response.total_working_days);
                setWorkDays(response.working_days);
                setWeekendWorking(response.weekend_working_day)
                setExtradata(response.dta_extra);
                setBankHoliday(response.bank_holiday_count);
                drawRow(response.data, fromDate, toDate);
                setIntialData({ ...intialData, work_country: response.data_user?.default_country });
                setLoadingRecord(false);
                const cntryar: any = [];
                response?.data_183.forEach((element: any) => {
                    cntryar.push({
                        country: element.country,
                        value: element.count,
                        name: element.country_name,
                        name_french: element.country_name_french,
                        name_dutch: element.country_name_dutch
                    })
                });
                const opt = filterCountryByLanguage(cntryar);
                if (opt) {
                    setTotal183(opt);
                }
                //setTotal183(response?.data_183);
            } else if (response.status === 'error' && response.data && response.data.ERROR_CODE == "CAN_VIEW") {
                return navigate("/404");
            } else {

            }
            setLoading(false);
        }
    }

    useEffect(() => {
        setDisplayData([]);
        const data: any = [];
        if (extradata.length > 0 && displayType != "") {
            extradata.forEach((element: any) => {
                if (element.value == displayType) {
                    data.push(element);
                }
            });
            setDisplayData(data);
        }

    }, [extradata, displayType])


    useEffect(() => {
        getSelectOptions(
            "tbl_master_country",
            "name",
            // ["worked_country"],
            // [1],
            '',
            ['iso',
                'worked_country',
                "name",
                "name_french",
                "name_dutch",]
        ).then(function (options) {
            let countryOptionsList = filterCountryByLanguage(options);
            const countryOptions = countryOptionsList.filter((cnt: any) => cnt.worked_country == 1);
            setCountryOptions(countryOptions);
            setAllCountry(countryOptionsList);
        });
    }, [localStorage.getItem("I18N_LANGUAGE")])

    const uniqueWorkCntryUser = (array: any) => {
        const uniqueWorkCntryUser = [...workCntryUser, ...array].filter(
            (country, index, self) => self.findIndex((i: any) => i.value === country.value) === index);
        return uniqueWorkCntryUser;
    }


    useEffect(() => {
        if (defltCntry) {
            updateUserCalenderData();
        }
    }, [defltCntry])

    useEffect(() => {
        if (userData && userData?.user_id) {
            setFromDate(userData.date_from);
            setToDate(userData.date_to);
            setDefltCntry(userData.default_country);
            updateWorkcountry(userData.country, userData.default_country);
        }
    }, [userData, countryOptions, localStorage.getItem("I18N_LANGUAGE")])


    useEffect(() => {
        if (workCntryUser) {
            const opt = filterCountryByLanguage(workCntryUser);
            setWorkCntryUser(opt);
        }
    }, [localStorage.getItem("I18N_LANGUAGE")])


    // useEffect(() => {
    //     if (defltCntry) {
    //         const workedCntry = allCountry.filter((cntry: any) => cntry.value == defltCntry);
    //         setWorkCntryUser(uniqueWorkCntryUser(workedCntry));
    //         updateUserCalenderData();
    //     }
    // }, [defltCntry])


    useEffect(() => {
        if (reportData.length > 0) {
            let total_days = 0;
            let other_days = 0;
            reportData.forEach((data: any) => {
                if (data.name != "") {
                    const dt = workCalender.find((cal: any) => cal.value == data.value);
                    data.type = dt?.label;
                    if (dt?.value == "W") {
                        total_days = total_days + data?.count;
                    } else {
                        other_days = other_days + data?.count;
                    }

                }

            })
            // setTotalWorkDays(total_days);
        }
    }, [reportData]);


    const drawRow = (data: any, fromDate: any, toDate: any) => {
        //creating row array
        const tr: any = [];
        setLoading(true);
        for (let month of year) {
            const td: any = [];
            let currentDay = 1;
            for (let i = 0; i < longestRow; i++) {
                if (i >= month.startCell && i <= month.endCell) {
                    let val: any = "";
                    let noexist = false;
                    const curDate = moment(currentDay + '-' + month.name + '-' + thisYear).format("YYYY-MM-DD");
                    const preDate = moment(currentDay + '-' + month.name + '-' + thisYear).subtract(1, 'days').format("YYYY-MM-DD");
                    const preDate2 = moment(currentDay + '-' + month.name + '-' + thisYear).subtract(2, 'days').format("YYYY-MM-DD");
                    if (fromDate != "" && toDate != "" && (curDate < fromDate || toDate < curDate)) {
                        noexist = true;
                    } else if (fromDate != "" && fromDate > curDate) {
                        noexist = true;
                    } else {
                        noexist = false;
                    }
                    if (data.length > 0) {
                        let val = data.find((dt: any) => moment(dt.date_val).format("YYYY-MM-DD") == moment(currentDay + '-' + month.name + '-' + thisYear).format("YYYY-MM-DD"));
                        let color_val: any = {};
                        if (val) {
                            color_val = workCalender.find((cal: any) => cal.value == val.value);
                        } else if (month.weekends.includes(currentDay)) {
                        }

                        //else if (month.weekends.includes(currentDay-1) || month.weekends.includes(currentDay-2) || month.weekends.includes(currentDay) || month.weekends.includes(currentDay+1) || month.weekends.includes(currentDay+2)) {
                        //const isFirstDayOfYear = moment(currentDay + '-' + month.name + '-' + thisYear).isSame(moment().startOf('year'), 'day');
                        //console.log(data);
                        // data.find((dt:any)=>{
                        //     const data_previous = (moment(dt.date_val).subtract(1, 'days')).format("YYYY-MM-DD");
                        //     const data_previous_2 = (moment(dt.date_val).subtract(2, 'days')).format("YYYY-MM-DD");
                        //     const data_previous_3 = (moment(dt.date_val).add(1, 'days')).format("YYYY-MM-DD");
                        //     const data_previous_4 = (moment(dt.date_val).add(2, 'days')).format("YYYY-MM-DD");
                        //     let val_previous = data.find((dt: any) => (data_previous == curDate) || (data_previous_2 == curDate) || (data_previous_3 == curDate) || (data_previous_4 == curDate) );
                        //     console.log(val_previous);
                        //     if (val_previous)
                        //      color_val = workCalender.find((cal: any) => cal.value == val_previous?.value);
                        // })
                        // let val_previous = data.find((dt: any) => ((moment(dt.date_val).subtract(1, 'days')).format("YYYY-MM-DD") == preDate) || ((moment(dt.date_val).subtract(2, 'days')).format("YYYY-MM-DD") == preDate2));
                        // console.log(val_previous);
                        // if (val_previous)
                        //     color_val = workCalender.find((cal: any) => cal.value == val_previous?.value);
                        //}

                        td.push({
                            number: currentDay,
                            weekend: month.weekends.includes(currentDay) ? true : false,
                            notexist: noexist,
                            date: curDate,
                            value: val ? val : "",
                            hasValue: val ? val.date_val : "",
                            color: color_val.color ? color_val.color : "",
                            title: color_val.label ? color_val.label : "",
                            country: val ? val.name : "",
                            bank_holiday: (val && val?.value && val?.value && val?.value?.value == "BH") ? true : false,
                        });
                    } else {
                        td.push({
                            number: currentDay,
                            weekend: month.weekends.includes(currentDay) ? true : false,
                            hasValue: "",
                            notexist: noexist,
                            date: curDate,
                            value: {},
                            color: "",
                            title: "",
                            country: "",
                            bank_holiday: (val && val?.value && val?.value && val?.value?.value == "BH") ? true : false,
                        });
                    }

                    currentDay++;
                } else {
                    td.push({
                        number: 0,
                        weekend: false,
                        hasValue: true,
                        notexist: true,
                        date: "",
                        value: "",
                        color: "",
                        title: "",
                        country: "",
                        bank_holiday: false,
                    });
                }
            }
            tr.push({ ...month, td });
        }
        //console.log(tr);
        setTr(tr);
        setLoading(false);
    }


    //draggable select
    const [select, setSelect] = useState([0, 0, 0, 0]);

    // This variable will control if the user is dragging or not
    const [drag, setDrag] = useState(false);

    const handleMouseDown = (e: any, row: any, col: any) => {
        setDrag(true);
        setSelect([row, col, row, col]);
        setSelDate([]);
    }
    const [countryValidation, setCountryValidation] = useState(false);
    const handleMouseUp = (e: any, row: any, col: any, day: any) => {
        setIntialData({
            ...intialData, ...{
                work_type: day?.value?.value,
                work_country: day?.value?.country,
                half_day: day?.value?.half_day,
            }
        })
        setDrag(false);
        if (workCntryUser.length > 0) {
            setModal(true);
        } else {
            toastId.current = toast(t('Please select applicable countries'), {
                type: toast.TYPE.WARNING,
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });
            setCountryValidation(true);
        }

    }

    const handleMultipleSel = (e: any, row: any, col: any) => {
        e.preventDefault();
        if (drag) {
            const [iniRow, iniCol] = select;
            if (iniRow <= row && iniCol <= col) {
                setSelect([iniRow, iniCol, row, col]);
            } else if (iniRow >= row && iniCol >= col) {
                setSelect([row, col, iniRow, iniCol]);
            }
            // let [iniRow, iniCol, endRow, endCol] = select;
            // console.log(select);
            // if (iniRow <= row && iniCol <= col)
            //     setSelect([iniRow, iniCol, row, col]);

            // if (iniRow >= row && iniCol >= col)
            //     setSelect([row, col, endRow, endCol]);

        }
    }


    const getClassName = (row: any, col: any, item: any) => {

        let [iniRow, iniCol, endRow, endCol] = select;
        if (iniRow == endRow && iniCol == endCol && row == iniRow && col == iniCol) {
            return "selected";
            //return item.weekend ? "weekend_work" : "selected";
        } else if (iniRow == endRow && row == iniRow && col >= iniCol && col <= endCol) {
            return "selected";
            //return item.weekend ? "weekend_work" : "selected";
        } else if (iniRow != endRow && row >= iniRow && row <= endRow) {
            if (row == iniRow && col >= iniCol) {
                return "selected";
                //return item.weekend ? "weekend_work" : "selected";
            } else if (row == endRow && col <= endCol) {
                return "selected";
                //return item.weekend ? "weekend_work" : "selected";
            } else if (row != endRow && row != iniRow) {
                return "selected";
                //return item.weekend ? "weekend_work" : "selected";
            } else {
                //return "";
                return item.weekend ? "weekend" : "";
            }
        } else {
            //return "";
            return item.weekend ? "weekend" : "";
        }
        // if(row<= iniRow && row >= endRow && col >= iniCol && col <= endCol){
        //     return item.weekend ? "weekend" : "selected";
        // }
        // else if(iniRow == endRow && iniCol == endCol && row == iniRow && col == iniCol){
        //     return item.weekend ? "weekend" : "selected";
        // }else if(iniRow == endRow && row<= iniRow && col >= iniCol && col <= endCol){
        //     return item.weekend ? "weekend" : "selected";
        // }
        // if (iniRow != endRow && row >= iniRow && row <= endRow && col >= iniCol) {
        //     return item.weekend ? "weekend" : "selected";
        // } else if (iniRow == endRow && iniCol == endCol && row == iniRow && col == iniCol) {
        //     return "selected";
        // }
        // else if (iniRow == endRow && row >= iniRow && row <= endRow && col >= iniCol && col <= endCol) {
        //     return item.weekend ? "weekend" : "selected";
        // } else {
        //     return item.weekend ? "weekend" : "";
        // }

        // if (iniRow != endRow && row >= iniRow && row <= endRow && col >= iniCol && col <= item.endCell) {
        //     return item.weekend ? "weekend" : "selected";
        // } else if (iniRow == endRow && iniCol == endCol && row == iniRow && col == iniCol) {
        //     return "selected";
        // }
        // else if (iniRow == endRow && row >= iniRow && row <= endRow && col >= iniCol && col <= endCol) {
        //     return item.weekend ? "weekend" : "selected";
        // } else {
        //     return item.weekend ? "weekend" : "";
        // }
    }

    const [intialData, setIntialData] = useState({
        work_type: 'W',
        work_country: '',
        half_day: 0
    });

    const validationSchema = Yup.object().shape({
        work_country: Yup.string()
            .required("The Country field is required."),
    });


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: intialData,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });

    const [total183, setTotal183] = useState<any>([]);
    const handleSubmit = async (values: any) => {
        if (user_id) {
            setLoading(true);
            const response = await questionnaireService.addCalendarRangeData({ selDates, user_id, thisYear, questionnaire_id, ...intialData, fromDate, toDate });
            toastId.current = toast(t("adding work day data..."), {
                autoClose: false,
                isLoading: true,
            });
            if (response.status == "success") {
                const cntryar: any = [];
                response.data?.data_183.forEach((element: any) => {
                    cntryar.push({
                        country: element.country,
                        value: element.count,
                        name: element.country_name,
                        name_french: element.country_name_french,
                        name_dutch: element.country_name_dutch
                    })
                });
                const opt = filterCountryByLanguage(cntryar);
                if (opt) {
                    setTotal183(opt);
                }
                //setTotal183(response.data?.data_183);
                if (response.data && response.data?.is183days && !response.data?.task_exist) {
                    if (userProfile.USER_ID == user_id) {
                        onClickCreateTask();
                    } else {
                        setShow183Modal(true);
                    }
                } else {
                    setShow183Modal(false);
                }
                toast.update(toastId.current, {
                    type: toast.TYPE.SUCCESS,
                    render: t("Data added successfully"),
                    autoClose: 5000,
                    isLoading: false,
                    delay: 500,
                });

                setModal(false);
                setLoading(false);
                getCalendarData();
                setSelect([0, 0, 0, 0])
            } else {
                toast.update(toastId.current, {
                    type: toast.TYPE.ERROR,
                    render: t("Please try again."),
                    autoClose: 5000,
                    isLoading: false,
                    delay: 500,
                });
                setLoading(false);
                setModal(false);
            }
        }
    }

    useEffect(() => {
        if (total183) {
            const cntryar: any = [];
            total183.forEach((element: any) => {
                cntryar.push({
                    country: element.country,
                    value: element.count,
                    name: element.country_name,
                    name_french: element.country_name_french,
                    name_dutch: element.country_name_dutch
                })
            });
            const opt = filterCountryByLanguage(cntryar);
            if (opt) {
                setTotal183(opt);
            }
        }
    }, [localStorage.getItem("I18N_LANGUAGE")])

    const [clickDel, setClickDel] = useState(false);
    const handleDelete = async () => {
        if (user_id) {
            setClickDel(true);
            if (!isCountryChanged) {
                setLoading(true);
                const response = await questionnaireService.removeCalendarRangeData({ selDates, user_id, thisYear, ...intialData, fromDate, toDate });
                toastId.current = toast(t("adding work day data..."), {
                    autoClose: false,
                    isLoading: true,
                });
                if (response.status == "success") {
                    toast.update(toastId.current, {
                        type: toast.TYPE.SUCCESS,
                        render: t("Data removed successfully"),
                        autoClose: 5000,
                        isLoading: false,
                        delay: 500,
                    });

                    setModal(false);
                    setLoading(false);
                    getCalendarData();
                    setSelect([0, 0, 0, 0])
                } else {
                    toast.update(toastId.current, {
                        type: toast.TYPE.ERROR,
                        render: t("Please try again."),
                        autoClose: 5000,
                        isLoading: false,
                        delay: 500,
                    });
                    setLoading(false);
                    setModal(false);
                }
            }
        }
    }


    const handleChange = (fieldName: string, fieldValue: any) => {
        setIntialData({ ...intialData, [fieldName]: fieldValue });
    };

    const handleWorkedCountry = (name: any, date: any) => {
        if (name == 'worked_country') {
            const bel_del = date.filter((dt: any) => dt.value == userData?.default_country);
            const unmatchedItems = getUnmatchedItems(workCntryUser, date);
            if (bel_del.length == 0) {
                toastId.current = toast(t("You cant delete ") + userData?.name, {
                    type: toast.TYPE.WARNING,
                    autoClose: 5000,
                    isLoading: false,
                    delay: 500,
                });
            } else if (unmatchedItems[0]) {
                const data_exist: any = reportData.filter((re: any) => re.id == unmatchedItems[0].value);
                if (data_exist.length > 0) {
                    toastId.current = toast(t("You can't delete " + (data_exist[0]?.name) + " ,data exist in calender."), {
                        type: toast.TYPE.WARNING,
                        autoClose: 5000,
                        isLoading: false,
                        delay: 500,
                    });
                } else {
                    setWorkCntryUser(date);
                    setCountrySelected(date);
                }
            } else {
                setWorkCntryUser(date);
                setCountrySelected(date);
            }

        } else if (name == 'worked_country_from') {
            setFromDate(date);
        } else if (name == 'worked_country_to') {
            setToDate(date);
        }
    }


    const getUnmatchedItems = (array1: any, array2: any) => {
        // Filter items in array1 that are not found in array2
        const unmatchedItems = array1.filter((item1: any) => {
            return !array2.find((item2: any) => item1.label === item2.label && item1.value === item2.value);
        });
        return unmatchedItems;
    };

    useEffect(() => {
        if (countrySelected) {
            updateUserCalenderData();
        }
    }, [countrySelected])


    const updateUserCalenderData = async () => {
        if (user_id) {
            setLoading(true);
            const default_cntry = (defltCntry) ? defltCntry : null;
            const work_country_user = workCntryUser.filter(
                (item: any, index: any, self: any) => self.findIndex((i: any) => i.value === item.value) === index
            );
            const response = await questionnaireService.addUserCalenderData({ user_id, thisYear, fromDate, toDate, work_country_user, default_cntry });
            if (response.status = "success") {
                setLoading(false);
                updateWorkcountry(response?.data?.country, response?.data?.default_cnty);
                getCalendarData();
            }
        }
    }


    const updateWorkcountry = async (country: any, default_cntry: any) => {
        if (country && country != "") {
            const cntryname = country.replace(' ', '');
            let cntrys = cntryname.split(",").map(Number);
            cntrys.push(default_cntry);
            const uniqueValues = cntrys.filter((value: any, index: any, self: any) => self.indexOf(value) === index);
            const workedCntry = allCountry.filter((cntry: any) => uniqueValues.includes(cntry?.value));
            setWorkCntryUser(uniqueWorkCntryUser(workedCntry));
        } else {
            const workedCntry = allCountry.filter((cntry: any) => cntry.value == userData.default_country);
            setWorkCntryUser(uniqueWorkCntryUser(workedCntry));
        }

    }

    const onClickCreateTask = async () => {
        if (userProfile.USER_ID != user_id) {
            toastId.current = toast("creating task for work day...", {
                autoClose: false,
                isLoading: true,
            });
        }
        const response = await questionnaireService.addTaskfromWorkDay({ user_id });
        if (response.status = "success") {
            setShow183Modal(false);
            if (userProfile.USER_ID != user_id) {
                toast.update(toastId.current, {
                    type: toast.TYPE.SUCCESS,
                    render: "Task is created for the user.",
                    autoClose: 5000,
                    isLoading: false,
                    delay: 500,
                });
            }
        } else {
            setShow183Modal(false);
            if (userProfile.USER_ID != user_id) {
                toast.update(toastId.current, {
                    type: toast.TYPE.ERROR,
                    render: "Task is not created for the user.",
                    autoClose: 5000,
                    isLoading: false,
                    delay: 500,
                });
            }
        }
    }

    useEffect(() => {
        if (fromDate !== "" && toDate != "") {
            updateUserCalenderData();
        } else {
            getCalendarData();
        }
    }, [fromDate, toDate]);


    const [selDates, setSelDate] = useState<any>([]);
    const [weekendDay, setWeekendDay] = useState(false);
    useEffect(() => {
        if (select) {
            const sel_dates: any = [];
            let [iniRow, iniCol, endRow, endCol] = select;
            if ((tr?.[iniRow]?.td?.[iniCol].weekend) && iniRow == endRow && iniCol == endCol) {
                if (tr?.[iniRow]?.td?.[iniCol].date)
                    sel_dates.push(tr?.[iniRow]?.td?.[iniCol].date);
                setWeekendDay(true);
            } else if (iniRow == endRow && iniCol == endCol) {
                if (tr?.[iniRow]?.td?.[iniCol].date)
                    sel_dates.push(tr?.[iniRow]?.td?.[iniCol].date);
                setWeekendDay(false);
            } else if (iniRow == endRow && iniCol != endCol) {
                for (let j = iniCol; j <= endCol; j++) {
                    if (tr?.[iniRow]?.td?.[j].date)
                        sel_dates.push(tr?.[iniRow]?.td?.[j].date);
                    // if (tr?.[iniRow]?.td?.[j].date && !(tr?.[iniRow]?.td?.[j].weekend) && !(tr?.[j]?.td?.[j].bank_holiday))
                    //     sel_dates.push(tr?.[iniRow]?.td?.[j].date);
                }
                setWeekendDay(false);
            } else if (iniRow != endRow) {
                for (let i = iniRow; i <= endRow; i++) {
                    for (let j = 0; j <= endDay; j++) {
                        if (i == iniRow && j >= iniCol) {
                            if (tr?.[i]?.td?.[j]?.date)
                                sel_dates.push(tr?.[i]?.td?.[j].date);
                            // if (tr?.[i]?.td?.[j]?.date && !(tr?.[i]?.td?.[j].weekend) && !(tr?.[j]?.td?.[j].bank_holiday))
                            //     sel_dates.push(tr?.[i]?.td?.[j].date);
                            setWeekendDay(false);
                        } else if (i == endRow && j <= endCol) {
                            if (tr?.[i]?.td?.[j]?.date)
                                sel_dates.push(tr?.[i]?.td?.[j].date);
                            // if (tr?.[i]?.td?.[j]?.date && !(tr?.[i]?.td?.[j].weekend) && !(tr?.[j]?.td?.[j].bank_holiday))
                            //     sel_dates.push(tr?.[i]?.td?.[j].date);
                            setWeekendDay(false);
                        } else if (i != iniRow && i != endRow) {
                            if (tr?.[i]?.td?.[j]?.date)
                                sel_dates.push(tr?.[i]?.td?.[j].date);
                            // if (tr?.[i]?.td?.[j]?.date && !(tr?.[i]?.td?.[j].weekend) && !(tr?.[j]?.td?.[j].bank_holiday))
                            //     sel_dates.push(tr?.[i]?.td?.[j].date);
                            setWeekendDay(false);
                        }

                    }
                }
            }

            //console.log(sel_dates);
            // for (let i = iniRow; i <= endRow; i++) {
            //     for (let j = iniCol; j <= endCol; j++) {
            //         if ((tr?.[i]?.td?.[j].weekend) && iniRow == endRow && iniCol == endCol) {
            //             sel_dates.push(tr?.[i]?.td?.[j].date);
            //             setWeekendDay(true);
            //         } else if (!(tr?.[i]?.td?.[j].weekend)) {
            //             sel_dates.push(tr?.[i]?.td?.[j].date);
            //             setWeekendDay(false);
            //         }
            //     }
            // }
            setSelDate(sel_dates);
        }
    }, [select])

    const [enableDel, setEnableDel] = useState(false);
    const [countryChanged, setCountryChanged] = useState<any>([]);
    useEffect(() => {
        if (calenderData) {
            setIsCountryChanged(false);
            setClickDel(false);
            const dates: any = [];
            const country_list: any = [];
            const allData: any = [];
            calenderData.forEach((element: any) => {
                dates.push(element.date_val);
                allData.push(element);
                country_list.push(element.country);
            });
            setCountryChanged(allData);
            const date_equal = checkSubset(dates, selDates);
            if (date_equal) {
                setEnableDel(true);
            } else {
                setEnableDel(false);
            }
        }
    }, [selDates])


    let checkSubset = (parentArray: any, subsetArray: any) => {
        return subsetArray.every((el: any) => {
            return parentArray.includes(el)
        })
    }

    const [isCountryChanged, setIsCountryChanged] = useState(false);
    useEffect(() => {
        if (intialData.work_country && selDates) {
            const matchingObjects = countryChanged.filter((parentObj: any) => {
                return selDates.some((selDate: any) => selDate === parentObj.date_val);
            });
            const countr: any = [];
            matchingObjects.forEach((element: any) => {
                countr.push(element.country);
            });
            if (countr.includes(intialData.work_country)) {
                setIsCountryChanged(false);
            } else {
                setIsCountryChanged(true);
                validation.setFieldError("work_country", "You cannt change worked country");
            }
        }

    }, [intialData.work_country, selDates])


    return (
        <React.Fragment>
            <Row className='workday-calender'>
                <Col>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col sm={10}>
                                    <CardHeader className='mb-3'>
                                        <Row className='calender-range'>
                                            <div className="mb-3 row">
                                                <label className="col-md-2 col-form-label"><b>{t('Work day range')}:</b></label>
                                                <label className="col-md-2 col-form-label">{t('Year')}:</label>
                                                <div className="col-md-2">
                                                    <Select
                                                        id="year"
                                                        name="year"
                                                        className="is-invalid"
                                                        options={yearDataPreOnly}
                                                        defaultValue={yearDataPreOnly.find((opt: any) => opt.value === ((new Date()).getFullYear()))}
                                                        isSearchable={true}
                                                        onChange={(option: any) => {
                                                            setThisYear(option.value);
                                                        }}
                                                    />
                                                </div>
                                                <label className="col-md-2 col-form-label">{t('Date From')}:</label>
                                                <div className="col-md-2">
                                                    <div className="input-group is-invalid">
                                                        <Flatpickr
                                                            id="worked_country_from"
                                                            name="worked_country_from"
                                                            className="form-control d-block"
                                                            type="text"
                                                            placeholder="DD/MM/YYYY"
                                                            value={fromDate != "" ? fromDate : moment('01-01-' + thisYear).format("YYYY-MM-DD")}
                                                            options={{
                                                                altInput: true,
                                                                altFormat: "d/m/Y",
                                                                dateFormat: "Y-m-d",
                                                                minDate: moment('01-01-' + thisYear).format("YYYY-MM-DD"),
                                                                maxDate: moment('12-31-' + thisYear).format("YYYY-MM-DD"),
                                                                //mode: "range",
                                                                allowInput: true,
                                                                onChange: function (selectedDates, dateStr, instance) {
                                                                    handleWorkedCountry('worked_country_from', dateStr);
                                                                },
                                                                locale: { firstDayOfWeek: 1 }
                                                            }}
                                                        />
                                                        <span className="input-group-text">
                                                            <i className="fa-solid fa-calendar"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <label className="col-md-2 col-form-label">{t('Date To')}:</label>
                                                <div className="col-md-2">
                                                    <div className="input-group is-invalid">
                                                        <Flatpickr
                                                            id="worked_country_to"
                                                            name="worked_country_to"
                                                            className="form-control d-block"
                                                            type="text"
                                                            placeholder="DD/MM/YYYY"
                                                            value={toDate != "" ? toDate : moment('12-31-' + thisYear).format("YYYY-MM-DD")}
                                                            options={{
                                                                altInput: true,
                                                                altFormat: "d/m/Y",
                                                                dateFormat: "Y-m-d",
                                                                minDate: moment('01-01-' + thisYear).format("YYYY-MM-DD"),
                                                                maxDate: moment('12-31-' + thisYear).format("YYYY-MM-DD"),
                                                                defaultDate: moment('12-31-' + thisYear).format("YYYY-MM-DD"),
                                                                allowInput: true,
                                                                onChange: function (selectedDates, dateStr, instance) {
                                                                    handleWorkedCountry('worked_country_to', dateStr);
                                                                },
                                                                locale: { firstDayOfWeek: 1 }
                                                            }}
                                                        />
                                                        <span className="input-group-text">
                                                            <i className="fa-solid fa-calendar"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                        <Row>
                                            <Col xs={8}>
                                                <Label className="form-label">
                                                    {t('Applicable countries for this year')}
                                                </Label>
                                                <div className="input-group">
                                                    <Select
                                                        id="user_status"
                                                        name="user_status"
                                                        options={countryOptions}
                                                        value={workCntryUser}
                                                        isSearchable={true}
                                                        className="countrySelect"
                                                        isMulti={true}
                                                        onChange={(e: any) => {
                                                            handleWorkedCountry('worked_country', e);
                                                        }}
                                                        isDisabled={(tr.length == 0) ? true : false}
                                                    />
                                                    <Row className='mt-2'>
                                                        <span className='text-left'><span className="text-warning is_required mt-1">* </span>{t('All white background days are ')}
                                                            <Select
                                                                className='worked_default_country is-invalid'
                                                                options={allCountry}
                                                                value={
                                                                    allCountry.length
                                                                        ? allCountry.find((option: any) => option.value == defltCntry)
                                                                        : ""
                                                                }
                                                                onChange={(option: any) => {
                                                                    //handleDefaultCountry(option.value);
                                                                    setDefltCntry(option.value);
                                                                }}
                                                            />

                                                            {t('work days.')}</span>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                </Col>
                                <Col sm={2}>
                                    <Row className="justify-content-end mt-3">
                                        {workCalender.map((dt: any, key: any) => {
                                            return (
                                                <span><span className='px-1 ps-1' style={{ "backgroundColor": dt.color, width: "5px" }}></span><span className='me-3 ms-1'>{t(dt.label)}</span></span>
                                            );
                                        })}
                                    </Row>
                                </Col>
                            </Row>
                            <Table className='table table-bordered'>
                                <thead>
                                    <tr>
                                        <th></th>
                                        {th.map((day: any, key: any) => {
                                            return (
                                                <th>
                                                    {day}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {tr.length == 0 &&
                                        <tr>
                                            <td colSpan={longestRow}> {t("Loading...")}</td>
                                        </tr>
                                    }
                                    {tr.length > 0 && tr.map((month: any, key: any) => {
                                        return (
                                            <tr>
                                                <td>{t(month.name)}</td>
                                                {month.td.map((day: any, keycol: any) => {
                                                    return (
                                                        <>
                                                            {day.notexist ?
                                                                <td className='disabled'></td>
                                                                :
                                                                (
                                                                    day?.value?.value == "BH" ?
                                                                        <td className="bank_holiday">{day.number}</td>
                                                                        :
                                                                        <td onMouseDown={(e) => handleMouseDown(e, key, keycol)}
                                                                            onMouseUp={(e) => handleMouseUp(e, key, keycol, day)}
                                                                            onMouseMove={(e) => handleMultipleSel(e, key, keycol)}
                                                                            className={getClassName(key, keycol, day)}
                                                                            style={{ "backgroundColor": day.color ? day.color : "" }}
                                                                            title={day.title + ' ' + day.country + ' ' + moment(day.date).format("DD/MM/YYYY")}
                                                                        >{day.number}</td>
                                                                )
                                                            }
                                                        </>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>

                            <Row>
                                <Col sm={12}>
                                    <h6 className='text-primary mt-3'>Report</h6>
                                    <table className="table table-striped w-100 mb-0 workday-report">
                                        <tbody>
                                            {loadingRecord ? (
                                                < tr>
                                                    <td colSpan={5}>{t("Loading...")}</td>
                                                </tr>
                                            ) :
                                                <>
                                                    <tr key="report_tr">
                                                        <td key="report_td_1">{t('Total number days in')} {thisYear}</td>
                                                        <td key="report_td_2">{daysInYear(thisYear)}</td>
                                                        <td key="report_td_13">{t('Total Weekdays')}</td>
                                                        <td key="report_td_14">{workDays}</td>
                                                        <td key="report_td_3">{t('Total Working Days')}</td>
                                                        <td key="report_td_4">{totalworkDays}</td>
                                                        <td colSpan={reportData ? ((reportData.length * 2)) : 0}></td>
                                                    </tr>
                                                    <tr>
                                                        <td key="report_td_5">{t('Work days in ')}{userData?.name} &nbsp;<span className='text-info'>({((workdaybel / totalworkDays) * 100).toFixed(2)}%)</span></td>
                                                        <td key="report_td_6">{workdaybel}</td>
                                                        {reportData && reportData.length > 0 &&
                                                            reportData.map((report: any) => {
                                                                return (
                                                                    <>
                                                                        {
                                                                            report.name &&
                                                                            (<><td>{t(`${report.type}s in `)}{report.name.toLowerCase()} <span className='text-info'> ({((report.count / totalworkDays) * 100).toFixed(2)}%)</span></td>
                                                                                <td>{report.count}</td></>)
                                                                        }
                                                                    </>


                                                                );
                                                            })
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td key="report_td_7">{t('Sick Days')}
                                                        </td>
                                                        <td key="report_td_8">{sickday}</td>
                                                        <td key="report_td_9">{t('Compensation')}
                                                        </td>
                                                        <td key="report_td_10">{compensation}</td>
                                                        <td key="report_td_11">{t('Holidays')}
                                                        </td>
                                                        <td key="report_td_12">{holiday}</td>
                                                        <td key="report_td_14">{t('Bank Holidays')}
                                                        </td>
                                                        <td key="report_td_16">{bankholiday}</td>
                                                        <td colSpan={reportData ? ((reportData.length * 2) - 2) : 0}></td>
                                                    </tr>
                                                    {displayData && displayData.length > 0 && (
                                                        <tr>
                                                            {
                                                                displayData.map((data: any) => {
                                                                    return (
                                                                        <>
                                                                            {
                                                                                data.name &&
                                                                                (<><td>{data?.name}</td>
                                                                                    <td>{data.count}</td></>)
                                                                            }
                                                                        </>


                                                                    );
                                                                })
                                                            }
                                                            <td colSpan={reportData ? ((reportData.length * 2) - (displayData.length * 2)) : 0}></td>
                                                        </tr>
                                                    )}

                                                    {total183.length > 0 && (
                                                        <>
                                                            <tr>
                                                                <td colSpan={reportData ? ((reportData.length * 2) - 2) : 0}> {t("Total working days outside of ")} {userData?.name} </td>
                                                            </tr>
                                                            <tr>
                                                                {
                                                                    total183.map((td1: any) => {
                                                                        return (
                                                                            <>
                                                                                <td>
                                                                                    {td1.country}
                                                                                </td>
                                                                                <td>
                                                                                    {td1.value}
                                                                                </td>
                                                                            </>

                                                                        )
                                                                    })
                                                                }
                                                            </tr>
                                                        </>
                                                    )}
                                                    {/* <tr>
                                                        <td key="report_id_14">{t('Weekend working day')}</td>
                                                        <td>{weekendWorking}</td>
                                                        <td colSpan={reportData ? ((reportData.length * 2) - 4) : 0}></td>
                                                    </tr> */}
                                                </>
                                            }

                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            {!!modal &&
                <Modal isOpen={modal} toggle={() => setModal(!modal)} centered={true}>
                    <div className="modal-header">
                        <h5 className="modal-title">
                            {enableDel ? t('Update') : t('Add data')}
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => setModal(!modal)}
                            aria-label="Close"
                        ></button>
                    </div>
                    <ModalBody>
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                            }}
                            className="row gy-2 gx-3 align-items-center workcalender-form">
                            <Row>
                                <Col>
                                    <div className="form-check float-start mb-2">
                                        <input
                                            id="half_day"
                                            name="half_day"
                                            className="form-check-input"
                                            type="checkbox"
                                            value={intialData.half_day}
                                            checked={
                                                intialData.half_day === 1
                                                    ? true
                                                    : false
                                            }
                                            onBlur={validation.handleBlur}
                                            onChange={(e: any) => {
                                                handleChange("half_day", intialData.half_day === 1
                                                    ? 0
                                                    : 1);
                                            }}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="half_day"
                                        >
                                            {t('Half day')}
                                        </label>
                                    </div>
                                </Col>
                                <Col>
                                    {workCalender.map((dt: any, key: any) => {
                                        return (
                                            <>
                                                {dt.value !== "BH" &&
                                                    <FormGroup check>
                                                        <Label check className={(dt.value != "W" && weekendDay) ? "disabled" : ""}>
                                                            <Input type="radio" name="work_type" checked={dt.value == intialData.work_type ? true : false} onChange={() => handleChange("work_type", dt.value)}
                                                                disabled={(dt.value != "W" && weekendDay) ? true : false} />{t(dt.label)}
                                                        </Label>
                                                    </FormGroup>
                                                }
                                            </>
                                        );
                                    })}
                                </Col>

                                <Col>
                                    <Label className="form-label">
                                        {t('Country')} <span className="text-warning is_required">*</span>
                                    </Label>
                                    <Select
                                        id="work_country"
                                        name="work_country"
                                        options={workCntryUser}
                                        isSearchable={true}
                                        onChange={(option: any) => {
                                            handleChange("work_country", option.value)
                                        }}
                                        value={
                                            intialData.work_country
                                                ? countryOptions.find(
                                                    (option: any) => option.value == intialData.work_country
                                                )
                                                : ""
                                        }
                                    />
                                    {validation.touched.work_country &&
                                        validation.errors.work_country ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.work_country}
                                        </FormFeedback>
                                    ) : null}

                                    {(isCountryChanged && clickDel) && (
                                        <div className="invalid-feedback">{t('Coutry should not be changed.')}</div>
                                    )
                                    }
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {!!enableDel &&
                            <Button color="warning" size="md" onClick={() => handleDelete()}>
                                {loading ? (
                                    <span>
                                        <i className="fa-solid fa-spinner fa-spin"></i>
                                        <span>&nbsp; {t("Please wait...")}</span>
                                    </span>
                                ) : (
                                    t('Delete')
                                )}</Button>
                        }
                        <Button color="primary" size="md" onClick={() => validation.handleSubmit()}>
                            {loading ? (
                                <span>
                                    <i className="fa-solid fa-spinner fa-spin"></i>
                                    <span>&nbsp; {t("Please wait...")}</span>
                                </span>
                            ) : (
                                <span>{enableDel ? t('Update') : t('Save')} </span>
                            )}
                        </Button>
                        <Button color="danger" size="md" onClick={() => setModal(!modal)}>{t('Cancel')}</Button>
                    </ModalFooter>
                </Modal>
            }

            {!!show183Modal &&
                <Modal isOpen={show183Modal} toggle={() => setShow183Modal(!show183Modal)} centered={true}>
                    <div className="modal-header">
                        <h5 className="modal-title">{t("Tax return")}</h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => setShow183Modal(false)}
                            aria-label="Close"
                        ></button>
                    </div>
                    <ModalBody>
                        <p>{t("Do you want to create task for this user as total work days is greater than 183")}</p>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="submit"
                            className="btn btn-primary btn-md ms-2"
                            onClick={onClickCreateTask}
                            disabled={loading}
                        >
                            {t("Create Task")}
                        </button>
                        <button
                            type="button"
                            className="btn btn-outline-danger btn-md ms-2"
                            onClick={() => setShow183Modal(false)}
                        >
                            {t("Cancel")}
                        </button>
                    </ModalFooter>
                </Modal>
            }
        </React.Fragment>
    )
}

export default WorkdayCalender