import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
  Alert,
} from "reactstrap";

// Actions
import { CompanyCategoryAction } from "../../../store/actions";

// Import Breadcrumb
import Breadcrumbs from "../../../components/Layout/Breadcrumb";

// Import DeleteModal
import CompanyCategoryModal from "./CompanyCategoryForm";

// Import DeleteModal
import DeleteModal from "../../../components/Common/DeleteModal";

// Import DataTable
import DataTable from "../../../components/Common/DataTable";

import { getDataTableQuery } from "../../../helpers/functions_helper";
import { useTranslation } from "react-i18next";
const AllCompanyCategory = (props: any) => {
  const {t} = useTranslation();
  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "#",
      label: t("Company category"),
      active: true,
    },
  ];
  document.title = `${t("Company category")} | ${process.env.REACT_APP_NAME}`;
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();

  let {
    datatableData,
    datatableErrorMsg,
    createCompanyCategorySuccessMsg,
    createCompanyCategoryErrorMsg,
    createCompanyCategoryErrorData,
    updateCompanyCategorySuccessMsg,
    updateCompanyCategoryErrorMsg,
    updateCompanyCategoryErrorData,
    deleteCompanyCategorySuccessMsg,
    deleteCompanyCategoryErrorMsg,
    deleteCompanyCategoryErrorData,
    getCompanyCategoryErrorMsg,
    loading,
  } = useSelector((state: any) => ({
    datatableData: state.CompanyCategory.GET_COMPANY_CATEGORY_LIST_SUCCESS,
    datatableErrorMsg: state.CompanyCategory.GET_COMPANY_CATEGORY_LIST_ERROR.message,

    createCompanyCategorySuccessMsg:
      state.CompanyCategory.CREATE_COMPANY_CATEGORY_SUCCESS.message,
    createCompanyCategoryErrorMsg:
      state.CompanyCategory.CREATE_COMPANY_CATEGORY_ERROR.message,
    createCompanyCategoryErrorData:
      state.CompanyCategory.CREATE_COMPANY_CATEGORY_ERROR.data,

    updateCompanyCategorySuccessMsg:
      state.CompanyCategory.UPDATE_COMPANY_CATEGORY_SUCCESS.message,
    updateCompanyCategoryErrorMsg:
      state.CompanyCategory.UPDATE_COMPANY_CATEGORY_ERROR.message,
    updateCompanyCategoryErrorData:
      state.CompanyCategory.UPDATE_COMPANY_CATEGORY_ERROR.data,

    deleteCompanyCategorySuccessMsg:
      state.CompanyCategory.DELETE_COMPANY_CATEGORY_SUCCESS.message,
    deleteCompanyCategoryErrorMsg:
      state.CompanyCategory.DELETE_COMPANY_CATEGORY_ERROR.message,
    deleteCompanyCategoryErrorData:
      state.CompanyCategory.DELETE_COMPANY_CATEGORY_ERROR.data,

    getCompanyCategoryErrorMsg: state.CompanyCategory.GET_COMPANY_CATEGORY_ERROR.message,

    loading: state.CompanyCategory.loading,
  }));

  // DataTable ----------------------------------------------
  function renderHeaderHTML(column: any, colIndex: any) {
    return <span dangerouslySetInnerHTML={{ __html: column.text }} />;
  }
  function renderRowHTML(rowContent: any, row: any) {
    return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
  }

  useEffect(()=>{
    setLoadingListData(true);
    setCompanyCategoryListData([]);
  },[localStorage.getItem("I18N_LANGUAGE")])

  const columns = [
    {
      dataField: "DT_RowIndex",
      text: t("Sr. No."),
      sort: false,
      search: false,
    },
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: true,
    //   search: true,
    // },
    {
      dataField: "category_value_html",
      text: t("Company category"),
      sort: true,
      search: true,
      formatter: renderRowHTML,
    },
    {
      dataField: "action",
      text: t("Action"),
      sort: false,
      search: false,
      formatter: (rowContent: any, row: any): any => {
        return (
          <div className="d-flex gap-2 text-end">
            <Link
              className="btn btn-pill btn-outline-primary btn-sm"
              to="#"
              onClick={() => onClickEdit(row)}
            >
              <i className="fa-regular fa-pen-to-square"></i> {t("Edit")}
            </Link>
            <Link
              className="btn btn-pill btn-outline-danger btn-sm"
              to="#"
              onClick={() => onClickDelete(row)}
            >
              <i className="fa-regular fa-trash-can"></i> {t("Delete")}
            </Link>
          </div>
        );
      },
    },
  ];

  const [loadingListData, setLoadingListData] = useState(true);
  const [datatableOptions, setDatatableOptions] = useState({
    draw: 1,
    page: 1,
    recordsTotal: 0,
    recordsFiltered: 0,
    sizePerPage: 20,
    searchText: "",
    sortField: "id",
    sortOrder: "desc",
  });

  useEffect(() => {
    if (loadingListData === true) {
      dispatch(
        CompanyCategoryAction.getCompanyCategoryList(
          getDataTableQuery(datatableOptions, columns),
          props.history
        )
      );
    }
  }, [loadingListData]);

  const [companyCategoryListData, setCompanyCategoryListData] = useState([]);
  useEffect(() => {
    if (datatableData) {
      setCompanyCategoryListData(datatableData.data);

      const updatedDatatableOptions = {
        ...datatableOptions,
        recordsTotal: datatableData.recordsTotal,
        recordsFiltered: datatableData.recordsFiltered,
      };
      setDatatableOptions(updatedDatatableOptions);

      setLoadingListData(false);
      setLoader(false);
    }
  }, [datatableData]);

  const onTableChange = (type: any, newState: any) => {
    let draw = datatableOptions.draw + 1;
    let page = 1;
    if (type == "search") {
      page = 1;
    } else {
      page = newState.page;
    }
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: draw,
      page: page,
      sizePerPage: newState.sizePerPage,
      searchText: newState.searchText,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
    };
    setDatatableOptions(updatedDatatableOptions);
    setLoadingListData(true);
  };

  const clearFilter = () => {
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: 1,
      page: 1,
      sizePerPage: 20,
      searchText: "",
      sortField: "id",
      sortOrder: "desc",
    };
    setDatatableOptions(updatedDatatableOptions);
    setLoadingListData(true);
  };
  // Datatable -------------------------------------------------

  // Delete Form --------------------------------------------------------
  const [deleteCompanyCategory, setDeleteCompanyCategory] = useState({
    id: "",
    category_id: ""
  });
  const [recordName, setRecordName] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (row: any) => {
    const deleteCompanyCategoryData = {
      ...deleteCompanyCategory,
      id: row.id,
      category_id: row.category_id,
    };
    setDeleteCompanyCategory(deleteCompanyCategoryData);
    setRecordName(row.category_value);
    setDeleteModal(true);
  };

  const handleDeleteCompanyCategory = () => {
    dispatch(CompanyCategoryAction.deleteCompanyCategory(deleteCompanyCategory, props.history));
  };
  // Delete Form --------------------------------------------------------

  // Master Form -------------------------------------------------------- 
  const [companyCategoryData, setCompanyCategoryData] = useState({});

  const [masterFormModal, setMasterFormModal] = useState(false);
  const [masterFormModalTitle, setMasterFormModalTitle] = useState(t("Create company category"));

  const onClickModal = () => {
    setCompanyCategoryData('');
    setMasterFormModalTitle(t("Create company category"));
    setMasterFormModal(true);
  };

  const onClickEdit = (row: any) => {
    setCompanyCategoryData(row);
    setMasterFormModalTitle(t("Update company category"));
    setMasterFormModal(true);
  }

  const handleSubmitCompanyCategory = (values: any) => {
    if (values.id && values.category_id) {
      dispatch(CompanyCategoryAction.updateCompanyCategory(values, props.history));
    } else {
      dispatch(CompanyCategoryAction.createCompanyCategory(values, props.history));
    }
  };
  // Master Form --------------------------------------------------------

  // Messages -------------------------------------------------
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorData, setErrorData] = useState({ data: {} });

  useEffect(() => {
    if (createCompanyCategorySuccessMsg) {
      setSuccessMsg(createCompanyCategorySuccessMsg);
      setLoadingListData(true);
      window.scrollTo(0, 0);
      setErrorMsg("");
      setErrorData({ data: {} });
      dispatch(CompanyCategoryAction.createCompanyCategorySuccess(""));
      setMasterFormModal(false);
    }
  }, [createCompanyCategorySuccessMsg]);

  useEffect(() => {
    if (createCompanyCategoryErrorMsg) {
      setErrorMsg(createCompanyCategoryErrorMsg);
      setErrorData({ data: createCompanyCategoryErrorData });
      window.scrollTo(0, 0);
      setSuccessMsg("");
      dispatch(CompanyCategoryAction.createCompanyCategoryError(""));
    }
  }, [createCompanyCategoryErrorMsg]);

  useEffect(() => {
    if (updateCompanyCategorySuccessMsg) {
      setSuccessMsg(updateCompanyCategorySuccessMsg);
      setLoadingListData(true);
      window.scrollTo(0, 0);
      setErrorMsg("");
      setErrorData({ data: {} });
      dispatch(CompanyCategoryAction.updateCompanyCategorySuccess(""));
      setMasterFormModal(false);
    }
  }, [updateCompanyCategorySuccessMsg]);

  useEffect(() => {
    if (updateCompanyCategoryErrorMsg) {
      setErrorMsg(updateCompanyCategoryErrorMsg);
      setErrorData({ data: updateCompanyCategoryErrorData });
      window.scrollTo(0, 0);
      setSuccessMsg("");
      dispatch(CompanyCategoryAction.updateCompanyCategoryError(""));
    }
  }, [updateCompanyCategoryErrorMsg]);

  useEffect(() => {
    if (deleteCompanyCategorySuccessMsg) {
      setSuccessMsg(deleteCompanyCategorySuccessMsg);
      setLoadingListData(true);
      window.scrollTo(0, 0);
      setErrorMsg("");
      setErrorData({ data: {} });
      dispatch(CompanyCategoryAction.deleteCompanyCategorySuccess(""));
      setDeleteModal(false);
    }
  }, [deleteCompanyCategorySuccessMsg]);

  useEffect(() => {
    if (deleteCompanyCategoryErrorMsg) {
      setErrorMsg(deleteCompanyCategoryErrorMsg);
      setErrorData({ data: deleteCompanyCategoryErrorData });
      window.scrollTo(0, 0);
      setSuccessMsg("");
      dispatch(CompanyCategoryAction.deleteCompanyCategoryError(""));
      setDeleteModal(false);
    }
  }, [deleteCompanyCategoryErrorMsg]);

  useEffect(() => {
    if (getCompanyCategoryErrorMsg) {
      setErrorMsg(getCompanyCategoryErrorMsg);
      window.scrollTo(0, 0);
      setSuccessMsg("");
      dispatch(CompanyCategoryAction.getCompanyCategoryError(""));
    }
  }, [getCompanyCategoryErrorMsg]);
  // Messages -------------------------------------------------

  return (
    <React.Fragment>

      {deleteCompanyCategory && (
        <DeleteModal
          recordName={recordName}
          modalTitle={t("company category")}
          show={deleteModal}
          onDeleteClick={handleDeleteCompanyCategory}
          onCloseClick={() => setDeleteModal(false)}
          loading={loading}
        />
      )}

      {masterFormModal && (
        <CompanyCategoryModal
          modalTitle={masterFormModalTitle}
          show={masterFormModal}
          loading={loading}
          companyCategoryData={companyCategoryData}
          onSubmitClick={handleSubmitCompanyCategory}
          onCloseClick={() => setMasterFormModal(false)}
          errorMsg={errorMsg}
          errorData={errorData}
        />
      )}

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={t("Company category")}
            breadcrumbItems={breadcrumbItems}
            endAdornment={
              <Link
                to="#"
                className="btn btn-primary btn-sm waves-effect waves-light btn-label"
                onClick={() => onClickModal()}
              >
                <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">add</span> {t("Create company category")}
              </Link>
            }
          />

          <Row>
            <Col xl="12">
              {successMsg ? <Alert color="success">{successMsg}</Alert> : null}
              {errorMsg ? <Alert color="danger">{errorMsg}</Alert> : null}

              <Card>
                <CardBody>
                  <DataTable
                    columns={columns}
                    products={companyCategoryListData}
                    datatableOptions={datatableOptions}
                    filters={[]}
                    onTableChange={onTableChange}
                    loadingListData={loadingListData}
                    clearFilter={clearFilter}
                    slug="masters"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AllCompanyCategory;
