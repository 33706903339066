import { GET_ALERTS, GET_ALERT_ERROR, GET_ALERT_SUCCESS } from "./actionTypes";

export const getAlerts = (query: any, history: any) => ({
    type: GET_ALERTS,
    payload: { query, history },
})

export const getAlertsSuccess = (data: any) => ({
    type: GET_ALERT_SUCCESS,
    payload: data,
})

export const getAlertFail= (error : any) => ({
    type: GET_ALERT_ERROR,
    payload: error
})