import React, { Component } from "react";

class Preloader extends Component {
  render() {
    return (
      <React.Fragment>
        <div id="preloader">
          <div id="preloader_status">
            <div className="spinner-chase">
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
              <div className="chase-dot"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Preloader;
