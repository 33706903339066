import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    Row,
    Col,
    Form,
    Label,
    Input,
    Button,
    FormFeedback,
    Container,
    Card,
    CardBody,
    CardFooter,
    TabContent,
    NavItem,
    NavLink,
    Nav,
    TabPane,
    FormGroup
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getDependentSelectOptions, getFirstErrorElement } from "../../../helpers/functions_helper";
// @ts-ignore
import CustomEditor from "ckeditor5-custom-build/build/ckeditor";
// @ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Select from "react-select";
import { debounce, sortBy, find, findIndex } from 'lodash';
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AsyncCreatableSelect from 'react-select/async-creatable';
import Dropzone from "../../../components/Common/Dropzone";
import Breadcrumbs from "../../../components/Layout/Breadcrumb";
import { getUserProfile, ckEditorConfiguration } from "../../../helpers/functions_helper";
import EmailService from '../../../services/email.service';
import { typeOptions2, targetOptions } from "../../../common/staticdata";
import commonService from "../../../services/common.service";
import emailService from "../../../services/email.service";
import { useNavigate } from "react-router";
import './styles.scss';
import { typeHeaderFooter, HeaderFooter } from "./data";
import EmailHeader from './components/Header';
import EmailFooter from './components/Footer';
import FooterButton from "./components/FooterButton";
import Target from "./components/Target";



export const MyOption = (props: any) => {
    const { innerProps, innerRef } = props
    if (props.data.hasOwnProperty('__isNew__')) {
        return (
            <div className="d-flex p-1" ref={innerRef} {...innerProps}>
                <div className="flex-grow-1 chat-user-box">
                    <p className="user-title m-0">{props.data.label}</p>
                </div>
            </div>
        );
    }
    else
        return (
            <div className="d-flex p-1" ref={innerRef} {...innerProps}>
                <div className="d-flex me-1 avatar-xs">
                    <span className="avatar-title rounded-circle">{props.data.label.substring(0, 1)}</span>
                </div>
                <div className="flex-grow-1 chat-user-box">
                    <p className="user-title m-0">{props.data.label}</p>
                    <p className="text-muted">{props.data.value}</p>
                </div>
            </div>
        );
};


const EditEmailTemplate = () => {

    const userProfile = getUserProfile('email-template');
    const { t } = useTranslation();
    const { id, template_id } = useParams();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [filters, setFilter] = useState('');

    const [loadingCode, setLoadingCode] = useState(true);
    const [query, setQuery] = useState("");
    const [moduleList, setModuleList] = useState([]);
    const [languageList, setLanguageList] = useState<any>([]);
    const [emailCategoryList, setEmailCategoryList] = useState([]);
    const toastId = useRef(null) as any;
    const [langTab, setLangTab] = useState<number>(1);
    const [tempy, setTempy] = useState<any>({});
    const [intialData, setIntialData] = useState({
        id: "",
        template_id: "",
        module: "",
        email_category: "",
        title: "",
        subject: "",
        template: ``,
        from: "",
        to: "",
        hf_id: "",
        button: "",
        is_folder_permission: false,
        folder_link: '',
        attachment: "",
        applies_to: "",
        language: "",
        target: "",
        template_data: [],
        submit_btn_code: 'save'
    });

    const breadcrumbItems = [
        {
            url: "/dashboard",
            label: t("Dashboard"),
            active: false,
        },
        {
            url: "/masters/email-template/list",
            label: t("Email Template List"),
            active: false,
        },
        {
            url: "/masters/email-template/list",
            label: t("Edit template"),
            active: true,
        },
    ];

    const insertSubId = typeof template_id != 'undefined' ? template_id.split('-')[0] : 0;
    const [template, setTemplate] = useState({ header: '<p></p>', footer: '<p></p>', middle: '', button: "" });
    const validationSchema = Yup.object().shape({
        module: Yup.string().required(t("The module field is required.")),
        title: Yup.string()
            .required(t("The name field is required."))
            .min(2, t("The name must be at least 2 characters.")),
        subject: Yup.string()
            .required(t("The subject field is required."))
            .min(2, t("The subject must be at least 2 characters.")),
        template: Yup.string()
            .required(t("The template is required."))
            .min(2, t("The template must be at least 2 characters.")),
        from: Yup.string()
            .required(t("The from field is required."))
            .min(2, t("The from must be at least 2 characters.")),
        applies_to: Yup.string().required(t("The applies to field is required.")),
    });

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: intialData,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });

    const handleChange = (fieldName: string, fieldValue: string) => {
        setIntialData(preState => ({ ...preState, [fieldName]: fieldValue }));
    };

    function updateInitialData(data: any) {
        const templateData: any = data.template_data;
        if (templateData.length > 0) {
            setIntialData((preState) => ({
                ...preState,
                id: data.id,
                template_id: data.template_id,
                module: data.module,
                email_category: data.email_category,
                title: data.title,
                subject: templateData ? templateData[0].subject || '' : '',
                template: templateData ? templateData[0].template || '' : '',
                from: data.from,
                to: data.to,
                hf_id: data.hf_id,
                button: templateData ? templateData[0].button || '' : '',
                is_folder_permission: data.is_folder_permission,
                folder_link: data.folder_link || '',
                target: data.target,
                applies_to: data.applies_to,
                attachment: templateData && templateData[0].template_file,
                template_data: data.template_data,
                submit_btn_code: "save_add_new"
            }));
        } else {
            setIntialData((preState) => ({
                ...preState,
                id: data.id,
                template_id: data.template_id,
                module: data.module,
                email_category: data.email_category,
                title: data.title,
                from: data.from,
                to: data.to,
                hf_id: data.hf_id,
                button: data.btn_label,
                is_folder_permission: data.is_folder_permission,
                folder_link: data.folder_link || '',
                target: data.target,
                applies_to: data.applies_to,
                submit_btn_code: "save_add_new"
            }));
        }


        setLoadingCode(true);
        window.scrollTo(0, 0);
    }

    const redirectPage = () => {
        if (intialData.submit_btn_code === "save_add_new") {
            return navigate("/masters/email-template/new");
        }
        if (intialData.submit_btn_code === "save_close") {
            return navigate("/masters/email-template/list");
        }
        if (intialData.submit_btn_code === "save") {
            return navigate("/masters/email-template/edit/" + intialData.id + "/" + intialData.template_id);
        }
    }


    const multiUploadSuccess = (isSuccess: boolean) => {
        if (isSuccess) {
            redirectPage()
        }
    }

    const handleSubmit = async (values: {
        id: string;
        template_id: string;
        module: string;
        email_category: string;
        title: string;
        subject: string;
        template: string;
        from: string;
        to: string;
        hf_id: string;
        target: string;
        button: string;
        template_data: any;
        attachment: string;
        is_folder_permission: boolean;
        folder_link: string;
        applies_to: string;
    }) => {
        try {
            toastId.current = toast(t("Updating email template")+"...", {
                autoClose: false,
                isLoading: true,
            });

            const res = await emailService.updateEmailTemplate(values);
            if (res.data.status === "success") {
                toast.update(toastId.current, {
                    type: toast.TYPE.SUCCESS,
                    render: t("Email template update successfully"),
                    autoClose: 5000,
                    isLoading: false,
                    delay: 500,
                });
                redirectPage();
            } else {
                toast.update(toastId.current, {
                    type: toast.TYPE.ERROR,
                    render: res.data.message,
                    autoClose: 5000,
                    isLoading: false,
                    delay: 500,
                });
            }
        } catch (error: any) {
            toast.update(toastId.current, {
                type: toast.TYPE.ERROR,
                render: error,
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });
        }
    };

    useEffect(() => {
        if (!validation.isSubmitting) return;
        if (Object.keys(validation.errors).length > 0) {
            const element = getFirstErrorElement(validationSchema, validation);
            document.getElementById(element)?.scrollIntoView({ behavior: "smooth", block: "center" });
            document.getElementById(element)?.focus({ preventScroll: true });
        }
    }, [validation.isSubmitting, validation.errors]);

    const loadOptions = async (keyword: string) => {
        const filter = {
            search: keyword,
            extra: filters,
        };
        const user_email = await EmailService.searchUser(filter) as any;
        return user_email?.data?.data || [];
    };
    const loadSuggestedOptions = useCallback(
        debounce((inputValue, callback) => {
            loadOptions(inputValue).then(options => callback(options))
        }, 500), []);

    const getSelectOptions = async (tableName: string, fieldName: string) => {
        const response = await commonService.getSelectOptionsList(
            tableName,
            fieldName,
            query ? query : ""
        );
        return response.data.data;
    };


    const getEditTemplate = async () => {
        if (id && template_id) {
            const res = await emailService.getEmailTemplate({ id: id, template_id: template_id });
            if (res.data.status == "success") {
                setTemplate(res.data.data[0]);
                updateInitialData(res.data.data[0]);
                setTempy(res?.data?.data[0]?.template_data);
                setTemplate((preState: any) => ({ ...preState, header: HeaderFooter[0].header, footer: HeaderFooter[0].footer, button: HeaderFooter[0].button }))
            }
        }
    }

    useEffect(() => {
        getEditTemplate();
        headerFooterImport({ value: "1" });
    }, [id, template_id])


    const loadModuleList = async () => {
        const module_list: any = await getSelectOptions('tbl_master_module', 'module_value');
        setModuleList(module_list);
    }

    const loadLanguage = async () => {
        const language_list: any = await getSelectOptions('tbl_master_language', 'language_value');
        let sortLang = sortBy(language_list, ['value']);
        setLanguageList(sortLang);
    }

    useEffect(() => {
        if (languageList && tempy.length === 0) {
            let t_data = languageList ? languageList.map((item: any) => { return { 'language': item.label, 'language_id': item.value, template: '', subject: '', template_file: '' } }) : [];
            setIntialData((preState: any) => ({ ...preState, template_data: t_data }));
        }
    }, [languageList, tempy])



    const getEmailCategory = async () => {
        const emailCategoryList: any = await getDependentSelectOptions('tbl_master_email_category', 'email_template_category_value', 'module_category_id', parseInt(intialData.module));
        setEmailCategoryList(emailCategoryList);
    }

    useEffect(() => {
        if (intialData.module) {
            getEmailCategory();
        }
    }, [intialData.module])

    useEffect(() => {
        loadModuleList();
        loadLanguage();
    }, [])

    const headerFooterImport = (value: any) => {
        setIntialData((preState: any) => ({ ...preState, hf_id: value.value }))
        setTemplate((preState: any) => ({ ...preState, header: HeaderFooter[0].header, footer: HeaderFooter[0].footer }))
    }

    const updateButtonLabel = () => {
        if (template.button) {
            const Uregex = /{{(\w+)}}/g;
            const newStr = template.button.replace(Uregex, (match) => {
                return `${intialData.button}`;
            });
            setTemplate((preState) => ({ ...preState, button: newStr }))
        }
    }

    useEffect(() => {
        //updateButtonLabel();
        headerFooterImport({ value: "1" });
    }, [intialData.button])


    const handleLangTab = (tabid: number) => {
        const templateData: any = intialData.template_data;
        const current_tab = findIndex(templateData, { language_id: tabid });
        if (current_tab > -1) {
            setIntialData((preState: any) => ({
                ...preState,
                template: templateData[current_tab].template || '',
                subject: templateData[current_tab].subject || '',
                attachment: templateData[current_tab].attachment || '',
                button: templateData[current_tab].button || '',
                middle: templateData[current_tab].template || ''
            }));
        } else {
            const lang_index = findIndex(languageList, { value: tabid });
            if (lang_index > -1 && current_tab === -1) {
                templateData.push({ 'language': languageList[lang_index].label, 'language_id': languageList[lang_index].value, template: '', subject: '', template_file: '', button: '' })
                setIntialData((preState: any) => ({
                    ...preState,
                    template: '',
                    subject: '',
                    attachment: '',
                    button: '',
                    template_data: templateData
                }));
                setTemplate(preState => ({ ...preState, middle: '' }));
            }
        }
        setLangTab(tabid);
    }

    useEffect(() => {
        const templateData: any = intialData.template_data;
        if (langTab > 0) {
            const index_array = langTab - 1;
            if (templateData.length > 0) {
                templateData[index_array].template = intialData.template || '';
                templateData[index_array].subject = intialData.subject || '';
                templateData[index_array].attachment = intialData.attachment || '';
                templateData[index_array].button = intialData.button || '';
                setIntialData((preState: any) => ({
                    ...preState,
                    template_data: templateData
                }));
            }
        }

    }, [intialData.template, intialData.button, intialData.subject])

    useEffect(() => {
        if (intialData.template) {
            let newTemplate = intialData.template;
            setTemplate(preState => ({ ...preState, middle: newTemplate }));
        }

    }, [intialData.template])

    function handleFolderChange(value: boolean) {
        setIntialData(preState => ({ ...preState, is_folder_permission: value }));
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        breadcrumbTitle={t("Edit Email Template")}
                        breadcrumbItems={breadcrumbItems}
                    />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <Form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}
                                    autoComplete="off">
                                    <CardBody>
                                        <Row>
                                            <Col className="col-12">
                                                <Row>
                                                    <Col xl={4} lg={4} md={4} sm={12}>
                                                        <div className="mb-3">
                                                            <Label className="form-label">
                                                                {t("Name")}<span className="text-warning is_required">*</span>
                                                            </Label>
                                                            <Input
                                                                id="name"
                                                                name="name"
                                                                className="form-control"
                                                                placeholder={t("Template name")}
                                                                type="text"
                                                                value={intialData.title || ""}
                                                                autoFocus={true}
                                                                onBlur={validation.handleBlur}
                                                                onChange={(e) => handleChange("title", e.target.value)}
                                                                invalid={validation.errors.title ? true : false}
                                                            />
                                                            {validation.errors.title ? (
                                                                <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col xl={4} lg={4} md={4} sm={12}>
                                                        <div className="mb-3">
                                                            <Label className="form-label">
                                                                {t("Module Name")} <span className="text-warning is_required">*</span>
                                                            </Label>
                                                            <Select
                                                                id="module"
                                                                name="module"
                                                                className="is-invalid"
                                                                placeholder={t("Module Name")}
                                                                options={moduleList}
                                                                value={
                                                                    intialData.module
                                                                        ? moduleList.find(
                                                                            (option: any) => option.value == intialData.module
                                                                        )
                                                                        : ""
                                                                }
                                                                isSearchable={false}
                                                                isDisabled={false}
                                                                onBlur={() => validation.setFieldTouched("module", true)}
                                                                onChange={(e: any) => {
                                                                    handleChange("module", e.value);
                                                                }}
                                                            />
                                                            {validation.touched.module && validation.errors.module ? (
                                                                <FormFeedback type="invalid">{validation.errors.module}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col xl={4} lg={4} md={4} sm={12}>
                                                        <div className="mb-3">
                                                            <Label className="form-label">
                                                                {t("Email Category")} <span className="text-warning is_required">*</span>
                                                            </Label>
                                                            <Select
                                                                id="email_category"
                                                                name="email_category"
                                                                className="is-invalid"
                                                                placeholder={t("Email Category")}
                                                                options={emailCategoryList}
                                                                value={
                                                                    intialData.email_category
                                                                        ? emailCategoryList.find(
                                                                            (option: any) => option.value == intialData.email_category
                                                                        )
                                                                        : ""
                                                                }
                                                                isSearchable={false}
                                                                isDisabled={false}
                                                                onBlur={() => validation.setFieldTouched("email_category", true)}
                                                                onChange={(e: any) => {
                                                                    handleChange("email_category", e.value);
                                                                }}
                                                            />
                                                            {validation.touched.email_category && validation.errors.email_category ? (
                                                                <FormFeedback type="invalid">{validation.errors.email_category}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col xl={4} lg={4} md={4} sm={12}>
                                                        <div className="mb-3">
                                                            <Label className="form-label">
                                                                {t("Applies to")} <span className="text-warning is_required">*</span>
                                                            </Label>
                                                            <Select
                                                                id="type"
                                                                name="type"
                                                                className="is-invalid"
                                                                placeholder={t("Applies to")}
                                                                options={typeOptions2}
                                                                value={
                                                                    intialData.applies_to
                                                                        ? typeOptions2.find(
                                                                            (option: any) => option.value == intialData.applies_to
                                                                        )
                                                                        : ""
                                                                }
                                                                isSearchable={false}
                                                                isDisabled={false}
                                                                onBlur={() => validation.setFieldTouched("type", true)}
                                                                onChange={(e: any) => {
                                                                    handleChange("applies_to", e.value);
                                                                }}
                                                            />
                                                            {validation.touched.applies_to && validation.errors.applies_to ? (
                                                                <FormFeedback type="invalid">{validation.errors.applies_to}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col xl={4} lg={4} md={4} sm={12}>
                                                        <div className="mb-3">
                                                            <Label className="form-label">
                                                                {t("Target to")} <span className="text-warning is_required">*</span>
                                                            </Label>
                                                            <Select
                                                                id="target_to"
                                                                name="target_to"
                                                                className="is-invalid"
                                                                placeholder={t("Target to")}
                                                                options={targetOptions}
                                                                value={
                                                                    intialData.target
                                                                        ? targetOptions.find(
                                                                            (option: any) => option.value == intialData.target
                                                                        )
                                                                        : ""
                                                                }
                                                                isSearchable={false}
                                                                isDisabled={false}
                                                                onBlur={() => validation.setFieldTouched("target", true)}
                                                                onChange={(e: any) => {
                                                                    handleChange("target", e.value);
                                                                }}
                                                            />
                                                            {validation.touched.target && validation.errors.target ? (
                                                                <FormFeedback type="invalid">{validation.errors.target}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col xl={4} lg={4} md={4} sm={12}>
                                                        <div className="mb-3">
                                                            <Label className="form-label">
                                                                {t("From")}<span className="text-warning is_required">*</span>
                                                            </Label>
                                                            <Input
                                                                id="from"
                                                                name="from"
                                                                className="form-control"
                                                                placeholder={t("From")}
                                                                type="text"
                                                                value={intialData.from || ""}
                                                                autoFocus={true}
                                                                onBlur={validation.handleBlur}
                                                                onChange={(e) => handleChange("from", e.target.value)}
                                                                invalid={validation.errors.from ? true : false}
                                                            />
                                                            {validation.errors.from ? (
                                                                <FormFeedback type="invalid">{validation.errors.from}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col xl={4} lg={4} md={4} sm={12}>
                                                        <div className="mb-3">
                                                            <Label className="form-label">
                                                                {t("To (Emails)")}
                                                            </Label>
                                                            <AsyncCreatableSelect
                                                                cacheOptions
                                                                loadOptions={loadSuggestedOptions}
                                                                defaultOptions
                                                                placeholder={t("To (Emails)")}
                                                                isMulti
                                                                isClearable={false}
                                                                formatCreateLabel={userInput => `Add new email id ${userInput}`}
                                                                components={{
                                                                    DropdownIndicator: () => null,
                                                                    IndicatorSeparator: () => null,
                                                                    Option: MyOption,
                                                                }}
                                                                onChange={(e: any) => handleChange('to', e)}
                                                                value={intialData.to}
                                                            />
                                                            {validation.errors.to ? (
                                                                <FormFeedback type="invalid">{validation.errors.to}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col xl={2} lg={2} md={2} sm={12}>
                                                        <FormGroup check>
                                                            <div className="pt-4 mt-2">
                                                                <Input
                                                                    checked={intialData.is_folder_permission ? true: false}
                                                                    onChange={(e) => handleFolderChange(e.target.checked)}
                                                                    type="checkbox"
                                                                />
                                                                <Label className="form-label text-primary">
                                                                    {t("Folder link")}
                                                                </Label>
                                                            </div>
                                                        </FormGroup>
                                                    </Col>
                                                    {
                                                        intialData.is_folder_permission ? <Col l xl={6} lg={6} md={6} sm={12}>
                                                            <div className="mb-3">
                                                                <Label className="form-label">
                                                                    {t("Folder Link")}<span className="text-warning is_required">*</span>
                                                                </Label>
                                                                <Input
                                                                    id="folder_link"
                                                                    name="folder_link"
                                                                    className="form-control"
                                                                    placeholder={t("Folder Link")}
                                                                    type="text"
                                                                    value={intialData.folder_link || ""}
                                                                    autoFocus={true}
                                                                    onBlur={validation.handleBlur}
                                                                    onChange={(e) => {
                                                                        handleChange("folder_link", e.target.value);
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col> : null}
                                                    <div>
                                                        <Nav tabs>
                                                            {
                                                                languageList && languageList.map((item: any) => <NavItem>
                                                                    <NavLink
                                                                        className={langTab === item.value ? "active" : ''}
                                                                        onClick={() => handleLangTab(item.value)}
                                                                    >
                                                                        {item.label}
                                                                    </NavLink>
                                                                </NavItem>)
                                                            }
                                                        </Nav>
                                                        <TabContent activeTab={langTab} className="tab-contain-border">
                                                            <TabPane tabId={langTab} className="p-2">
                                                                <Row>
                                                                    <Col l xl={9} lg={9} md={9} sm={9}>
                                                                        <div className="mb-3">
                                                                            <Label className="form-label">
                                                                                {t("Subject")}<span className="text-warning is_required">*</span>
                                                                            </Label>
                                                                            <Input
                                                                                id="subject"
                                                                                name="subject"
                                                                                className="form-control"
                                                                                placeholder={t("Subject")}
                                                                                type="text"
                                                                                value={intialData.subject || ""}
                                                                                autoFocus={true}
                                                                                onBlur={validation.handleBlur}
                                                                                onChange={(e) => {
                                                                                    handleChange("subject", e.target.value);
                                                                                    //handleChangeTemplate();
                                                                                }}
                                                                                invalid={validation.errors.subject ? true : false}
                                                                            />
                                                                            {validation.errors.subject ? (
                                                                                <FormFeedback type="invalid">{validation.errors.subject}</FormFeedback>
                                                                            ) : null}
                                                                        </div>
                                                                    </Col>
                                                                    <Col xl={3} lg={3} md={3} sm={3}>
                                                                        <div className="mb-3">
                                                                            <Label className="form-label">
                                                                                {t("Button-Label")}<span className="text-warning is_required">*</span>
                                                                            </Label>
                                                                            <Input
                                                                                id="btn_label"
                                                                                name="btn_label"
                                                                                className="form-control"
                                                                                placeholder={t("Button-Label")}
                                                                                type="text"
                                                                                value={intialData.button || ""}
                                                                                autoFocus={true}
                                                                                onBlur={validation.handleBlur}
                                                                                onChange={(e) => {
                                                                                    handleChange("button", e.target.value);
                                                                                    //handleChangeTemplate();
                                                                                }}
                                                                                invalid={validation.errors.button ? true : false}
                                                                            />
                                                                            {validation.errors.button ? (
                                                                                <FormFeedback type="invalid">{validation.errors.button}</FormFeedback>
                                                                            ) : null}
                                                                        </div>
                                                                    </Col>

                                                                    <Col xl={6} lg={6} md={6} sm={6}>
                                                                        <div id="email_ckeditor" className="mb-3">
                                                                            <Label className="form-label">
                                                                                {t("Template")}<span className="text-warning is_required">*</span>
                                                                            </Label>
                                                                            <CKEditor
                                                                                editor={CustomEditor}
                                                                                config={ckEditorConfiguration}
                                                                                data={intialData.template}
                                                                                onChange={(e: any, editor: any) => {
                                                                                    handleChange("template", editor.getData());
                                                                                    //handleChangeTemplate();
                                                                                }}
                                                                                id="template"
                                                                                name="template"
                                                                                className="form-control"
                                                                                onBlur={() => validation.setFieldTouched("template", true)}
                                                                            />
                                                                            {validation.errors.template ? (
                                                                                <FormFeedback type="invalid">{validation.errors.template}</FormFeedback>
                                                                            ) : null}
                                                                        </div>
                                                                    </Col>
                                                                    <Col xl={6} lg={6} md={6} sm={6}>
                                                                        <div className="mb-3">
                                                                            <Label className="form-label">
                                                                                {t("Preview")}
                                                                            </Label>
                                                                            <div>
                                                                                <EmailHeader header={template.header} />
                                                                                <div style={{ 'padding': '15px' }}>
                                                                                    <Target target={intialData.target} language={langTab} />
                                                                                    <div dangerouslySetInnerHTML={{ __html: template.middle }} />
                                                                                    <FooterButton title={intialData.button} />
                                                                                </div>
                                                                                {/* <FooterButton title={intialData.button} /> */}
                                                                                <EmailFooter footer={template.footer} />
                                                                            </div>

                                                                        </div>
                                                                    </Col>
                                                                    <Col className="col-12">
                                                                        <hr></hr>
                                                                    </Col>

                                                                    <Col className="col-12">
                                                                        <div className="mb-3">
                                                                            <Label className="form-label">{t("Attachments")}</Label>
                                                                            <Dropzone
                                                                                moduleData={{
                                                                                    module_app_id: 16,
                                                                                    module_main_id: 0,
                                                                                    module_sub_id: "",
                                                                                    section_id: 0,
                                                                                    file_path: "email",
                                                                                }}
                                                                                fetchData={false}
                                                                                isOpen={true}
                                                                                insertSubId={insertSubId}
                                                                                onSuccess={multiUploadSuccess}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </TabPane>
                                                        </TabContent>
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter className="d-sm-flex flex-wrap bg-transparent border-top">
                                        <div className="d-flex gap-2">
                                            {/* <Button
                                                color="outline-danger"
                                                className="btn-block"
                                                type="button"
                                                onClick={() => updateInitialData(template)}
                                                disabled={loading}>
                                                {loading ? (
                                                    <span>
                                                        <i className="fa-solid fa-spinner fa-spin"></i>
                                                        <span>&nbsp; Please wait...</span>
                                                    </span>
                                                ) : (
                                                    <span>Reset</span>
                                                )}
                                            </Button>

                                            <Link className="btn btn-block btn-outline-danger" to={"/users/all-users"}>
                                                Cancel
                                            </Link> */}
                                        </div>

                                        <div className="ms-auto d-flex gap-2 justify-content-end">
                                            <Input
                                                id="submit_btn_code"
                                                name="submit_btn_code"
                                                type="hidden"
                                                onBlur={validation.handleBlur}
                                                onChange={(e) => handleChange("submit_btn_code", e.target.value)}
                                            />
                                            {userProfile.CAN_CREATE ? (
                                                <>
                                                    <Button
                                                        color="primary"
                                                        className="btn-block"
                                                        type="submit"
                                                        onClick={() => handleChange("submit_btn_code", "save_add_new")}
                                                        disabled={loading}>
                                                        {loading ? (
                                                            <span>
                                                                <i className="fa-solid fa-spinner fa-spin"></i>
                                                                <span>&nbsp; {t("Please wait...")}</span>
                                                            </span>
                                                        ) : (
                                                            <span>{t("Update & Add new")}</span>
                                                        )}
                                                    </Button>

                                                    <Button
                                                        color="primary"
                                                        className="btn-block"
                                                        type="submit"
                                                        onClick={() => handleChange("submit_btn_code", "save_close")}
                                                        disabled={loading}>
                                                        {loading ? (
                                                            <span>
                                                                <i className="fa-solid fa-spinner fa-spin"></i>
                                                                <span>&nbsp; {t("Please wait...")}</span>
                                                            </span>
                                                        ) : (
                                                            <span>{t("Update & Close")}</span>
                                                        )}
                                                    </Button>

                                                    <Button
                                                        color="primary"
                                                        className="btn-block"
                                                        type="submit"
                                                        onClick={() => handleChange("submit_btn_code", "save")}
                                                        disabled={loading}>
                                                        {loading ? (
                                                            <span>
                                                                <i className="fa-solid fa-spinner fa-spin"></i>
                                                                <span>&nbsp; {t("Please wait...")}</span>
                                                            </span>
                                                        ) : (
                                                            <span>{t("Update")}</span>
                                                        )}
                                                    </Button>
                                                </>
                                            ) : null}
                                        </div>
                                    </CardFooter>
                                </Form>

                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default EditEmailTemplate;