import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, List, ListGroup, ListGroupItem, Row } from "reactstrap";
import Button from "../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { CommonAction } from "../../store/actions";
import notificationService from "../../services/notification.service";
import { getDataTableQuery, getUserProfile } from "../../helpers/functions_helper";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../Common/Loader";

const ActivityBoard = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const userProfile = getUserProfile();


    const { getNotificationfetched, getNotificationcount } = useSelector((state: any) => ({
        getNotificationfetched: state.Common.notificationfetched,
        getNotificationcount: state.Common.GET_NOTIFICATION_COUNT_SUCCESS,
    }));

    const columns = [
        {
            dataField: "id",
        },
    ];

    const columnData = [
        t("TO DO"),
        t("IN PREPARATION"),
        t("IN REVIEW"),
        t("RESPONSIBLE TO SIGN OFF"),
        t("TO BE SENT"),
        t("SENT"),
        t("CANCELLED"),
        t("INVOICED"),
        t("DONE"),
    ];
    const columnColor = [
        "#556ee6",
        "#34c38f",
        "#50a5f1",
        "#f1b44c",
        "#74788d",
        "#fd7e14",
        "#198754",
        "#20c997",
        "#0dcaf0",
    ];

    const [notificationData, setNotificationData] = useState([]);
    const [loadingNotificationData, setLoadingNotificationData] = useState(true);
    const [datatableOptions, setDatatableOptions] = useState({
        draw: 1,
        page: 1,
        recordsTotal: 0,
        recordsFiltered: 0,
        sizePerPage: 100,
        searchText: "",
        sortField: "id",
        sortOrder: "desc",
    });

    const loadingNotification = async () => {
        setNotificationData([]);
        setLoadingNotificationData(true);

        try {
            const res = (await notificationService.getNotificationList(
                { ...getDataTableQuery(datatableOptions, columns), type: 'ACTIVITY' }
            )) as any;

            if (res.data.status === "success") {
                setNotificationData(res?.data?.data);
                setLoadingNotificationData(false);
                // setViewMoreNotificationData(false);
            } else {
                setNotificationData([]);
                setLoadingNotificationData(false);
                // setViewMoreNotificationData(false);
            }
        } catch (err) {
            setNotificationData([]);
            setLoadingNotificationData(false);
            //   setViewMoreNotificationData(false);
        }
    };


    const getNotificationCount = async () => {
        dispatch(CommonAction.getNotificationCount());
    };

    const updateNotificationStatus = async (data: any) => {
        try {
            const res = (await notificationService.updateNotificationStatus({
                module_app_id: data.module_app_id,
                module_main_id: data.module_main_id,
                module_sub_id: data.module_sub_id,
            })) as any;
        } catch (err) { }
    };

    const handleNotificationViewed = (data: any) => {
        const id = data.id;
        setNotificationData(notificationData.filter((item: any) => item?.id !== id));
        // dispatch(CommonAction.getNotificationCountSuccess({ data: notificationCount - 1 }));
        // setMenu(false);

        if (
            data.action === "COMMENT" ||
            data.action === "ATTACHMENT" ||
            data.action === "CREATE EXCEEDED"
        ) {
            updateNotificationStatus(data);
        }

        const to = data.custom_array && JSON.parse(data.custom_array)[0]?.notification_url
            ? JSON.parse(data.custom_array)[0]?.notification_url
            : data.module_app_id === 4
                ? `/users/comments/${userProfile.USER_ID}/${userProfile.USER_SUB_ID}`
                : data.module_app_id === 8 && data.module_main_id === 0
                    ? `/${data.url}?filters={"is_filtered":true,"company_id":"","user_id":"${data.created_by}"}`
                    : `/${data.url_edit}${data.module_main_id}/${data.module_sub_id}`;

        navigate(to)
    };

    const activityTitle = (data: any) => {
        // let title = data.subaction + '---' + data.action + '----' + data.module_value;
        let title = '';
        if (data.module_value === 'Invoice') {
            if (data.subaction === 'INVOICE' && data.action === 'SEND') {
                title = t('You have received a new invoice {{inv_no}}', { inv_no: data?.custom_message });
            } else if (data.subaction === 'CREDIT_NOTE' && data.action === 'SEND') {
                title = t('You have received a new credit note {{inv_no}}', { inv_no: data?.custom_message });
            } else if (data.subaction === 'REMINDER_ON_DUE' && data.action === 'SEND') {
                title = t("Just a reminder that your payment for Invoice '{{inv_no}}' is due.", { inv_no: data?.custom_message });
            } else if (data.subaction === 'REMINDER1' && data.action === 'SEND') {
                title = t("Just a reminder that your payment for Invoice '{{inv_no}}' is due.", { inv_no: data?.custom_message });
            } else {
                // title = t(data?.custom_message);
            }
        }
        if (data.module_value === 'Task') {
            if (data.action === 'CREATE') {
                title = t('{{tsk_nm}} task is created for you', { tsk_nm: data?.custom_message });
            }
        }
        if (data.module_value === 'Subtask') {
            if (data.action === 'CREATE') {
                title = t('{{tsk_nm}} sub-task is created for you', { tsk_nm: data?.custom_message });
            }
        }
        if (data.module_value === 'Questionnaire') {
            if (data.action === 'SEND') {
                title = t('{{vrb}} is waiting for you', { vrb: data?.custom_message });
            }
        }
        if (data.module_value === 'Dispute') {
            if (data.subaction === 'DISPUTE_ACCEPTED_BY_PARTNER') {
                title = t("Partner has accepted the dispute")
            }
            if (data.subaction === 'DISPUTE_REJECTED_BY_PARTNER') {
                title = t("Partner has rejected the dispute")
            }
            if (data.subaction === 'DISPUTE_PARTNER_CREATED_CREDIT_NOTE') {
                title = t("Partner has created credit note for the dispute")
            }

            // title = data?.custom_message;
        }
        return title;
    }

    useEffect(() => {
        if (getNotificationfetched === false) {
            getNotificationCount();
            dispatch(CommonAction.setNotificationFetched(true));
        }
    }, [getNotificationfetched])

    useEffect(() => {
        loadingNotification();
    }, [])

    return (
        <div>
            <div className="d-sm-flex flex-wrap flex-end mb-2">
                <div className="card-title">{t("Activity Board")}</div>
                {/* <div className="ms-auto  gap-2 right-controls ">
                    {userProfile.CAN_CREATE ? <><Button size="sm" color="light" onClick={() => setOpen(true)}>{t("Add News Feed")}</Button> &nbsp; </> : null}
                    {newsFeeds.length > 0 &&
                        <>
                            <Link className="btn btn-light btn-sm" to={'/news-feed/all'}>{t("View All")}</Link>

                            &nbsp;&nbsp;&nbsp;
                            <Button size="sm" color="light" onClick={() => gotoPrev()}><span className="align-middle material-symbols-outlined fw-light fs-4">arrow_back</span></Button>
                            &nbsp;
                            <Button size="sm" color="light" onClick={() => gotoNext()}><span className="align-middle material-symbols-outlined fw-light fs-4">arrow_forward</span></Button>
                        </>
                    }
                </div> */}
            </div>
            <Row>
                <div style={{ overflowY: 'auto', overflowX: 'hidden', height: '420px' }}>
                    {loadingNotificationData ? <LoadingSpinner /> : <ListGroup flush>
                        {notificationData?.map((data: any) => (
                            <ListGroupItem className="d-flex">
                                {/* {JSON.stringify(data)} */}
                                <div className="flex-grow-1">
                                    {/* {<p>{`${data.action} --- ${data.subaction} ---- ${data.module_value} --- ${data.custom_message}`}</p>} */}
                                    <h6 className="mt-0 mb-1">
                                        {activityTitle(data)}
                                    </h6>
                                    <div className="font-size-12 text-muted">
                                        {/* {data.custom_message && <p className="mb-1">{t(data.custom_message)}</p>} */}
                                        {/* {data.activity_title && <p className="mb-1">{t(data.activity_title)}</p>} */}

                                        {/* {data.custom_array &&
                                    JSON.parse(data.custom_array)[0]?.task_status &&
                                    JSON.parse(data.custom_array)[0]?.task_status["old_status"] &&
                                    JSON.parse(data.custom_array)[0]?.task_status["new_status"] && (
                                        <div className="d-flex p-0 mt-2 mb-2 gap-1">
                                            <span
                                                className="badge_custome badge rounded-pill"
                                                style={{
                                                    background:
                                                        columnColor[
                                                        parseInt(
                                                            JSON.parse(data.custom_array)[0]?.task_status[
                                                            "old_status"
                                                            ]
                                                        ) - 1
                                                        ],
                                                }}
                                            >
                                                {
                                                    columnData[
                                                    parseInt(
                                                        JSON.parse(data.custom_array)[0]?.task_status["old_status"]
                                                    ) - 1
                                                    ]
                                                }
                                            </span>
                                            <span className="align-middle material-symbols-outlined fw-light">
                                                arrow_forward
                                            </span>
                                            <span
                                                className="badge_custome badge rounded-pill"
                                                style={{
                                                    background:
                                                        columnColor[
                                                        parseInt(
                                                            JSON.parse(data.custom_array)[0]?.task_status[
                                                            "new_status"
                                                            ]
                                                        ) - 1
                                                        ],
                                                }}
                                            >
                                                {
                                                    columnData[
                                                    parseInt(
                                                        JSON.parse(data.custom_array)[0]?.task_status["new_status"]
                                                    ) - 1
                                                    ]
                                                }
                                            </span>
                                        </div>
                                    )} */}


                                    </div>
                                </div>
                                <div className="btn-wrapper">
                                    <Button size="sm" className="ms-auto" fullWidth color="primary" onClick={() => handleNotificationViewed(data)}>{t('View')} {t(data.module_value)}</Button>
                                </div>
                            </ListGroupItem>
                        ))}
                    </ListGroup>}
                </div>
            </Row>
        </div>
    )
}

export default ActivityBoard;