import React from "react";
import { Nav, NavItem } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getUserProfile } from "../../helpers/functions_helper";

const NavItems = (props: any) => {
  const { t } = useTranslation();
  const userProfile = getUserProfile("all-sub-task");

  return (
    <React.Fragment>
      <Nav tabs>
        <NavItem>
          <Link
            style={{ cursor: "pointer" }}
            to={`/tasks/subtask-details/${props.id}/${props.subtask_id}`}
            className={`${
              window.location.pathname ===
              "/tasks/subtask-details/" + props.id + "/" + props.subtask_id
                ? "active"
                : ""
            }  nav-link`}
          >
            {t("Subtask details")}
          </Link>
        </NavItem>

        {userProfile.USER_COMPANY === 1 && (
          <NavItem>
            <Link
              style={{ cursor: "pointer" }}
              to={`/tasks/subtask/timesheet/${props.id}/${props.subtask_id}`}
              className={`${
                window.location.pathname ===
                "/tasks/subtask/timesheet/" + props.id + "/" + props.subtask_id
                  ? "active"
                  : ""
              }  nav-link`}
            >
              {t("Time sheet")}
            </Link>
          </NavItem>
        )}
      </Nav>
    </React.Fragment>
  );
};

export default NavItems;
