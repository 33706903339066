import { takeEvery, call, put } from "redux-saga/effects";
import accessModuleService from "../../../services/masters.access.module.service";
import { AnyAction } from "redux";

// AccessModules Redux States
import {
  GET_ACCESS_MODULE,
  GET_ACCESS_MODULES_LIST,
  CREATE_ACCESS_MODULE,
  UPDATE_ACCESS_MODULE,
  DELETE_ACCESS_MODULE,
} from "./actionTypes";

import { 
    getAccessModuleSuccess, 
    getAccessModuleError,
    
    getAccessModulesListSuccess,
    getAccessModulesListError,

    createAccessModuleSuccess,
    createAccessModuleError,

    updateAccessModuleSuccess,
    updateAccessModuleError,

    deleteAccessModuleSuccess,
    deleteAccessModuleError,
} from "./actions";

function* getAccessModule({ payload: { accessroles, history } }: AnyAction): any {
  try {
    const response = yield call(accessModuleService.getAccessModule, accessroles);
    if (response.data && response.data.status === "success") {
      yield put(getAccessModuleSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getAccessModuleError(response));
    } else {
      yield put(getAccessModuleError(response.data));
    }
  } catch (error: any) {
    yield put(getAccessModuleError(error));
  }
}

function* getAccessModulesList({ payload: { query, history } }: AnyAction): any {
  try {
    const response = yield call(accessModuleService.getAccessModulesList, query);
    if (response.data && response.data.status === "success") {
      yield put(getAccessModulesListSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getAccessModulesListError(response.data));
    } else {
      yield put(getAccessModulesListError(response));
    }
  } catch (error: any) {
    yield put(getAccessModulesListError(error));
  }
}

function* createAccessModule({ payload: { accessroles, history } }: AnyAction): any {
  try {
    const response = yield call(accessModuleService.createAccessModule, accessroles);
    if (response.data && response.data.status === "success") {
      yield put(createAccessModuleSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(createAccessModuleError(response.data));
    } else {
      yield put(createAccessModuleError(response));
    }
  } catch (error: any) {
    yield put(createAccessModuleError(error));
  }
}

function* updateAccessModule({ payload: { accessroles, history } }: AnyAction): any {
  try {
    const response = yield call(accessModuleService.updateAccessModule, accessroles);
    if (response.data && response.data.status === "success") {
      yield put(updateAccessModuleSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(updateAccessModuleError(response.data));
    } else {
      yield put(updateAccessModuleError(response));
    }
  } catch (error: any) {
    yield put(updateAccessModuleError(error));
  }
}

function* deleteAccessModule({ payload: { accessroles, history } }: AnyAction): any {
  try {
    const response = yield call(accessModuleService.deleteAccessModule, accessroles);
    if (response.data && response.data.status === "success") {
      yield put(deleteAccessModuleSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(deleteAccessModuleError(response.data));
    } else {
      yield put(deleteAccessModuleError(response));
    }
  } catch (error: any) {
    yield put(deleteAccessModuleError(error));
  }
}

function* accessModulesSaga(): any {
  yield takeEvery(GET_ACCESS_MODULE, getAccessModule);
  yield takeEvery(GET_ACCESS_MODULES_LIST, getAccessModulesList);
  yield takeEvery(CREATE_ACCESS_MODULE, createAccessModule);
  yield takeEvery(UPDATE_ACCESS_MODULE, updateAccessModule);
  yield takeEvery(DELETE_ACCESS_MODULE, deleteAccessModule);
}

export default accessModulesSaga;
