import { call, put, takeEvery } from "redux-saga/effects"
import emailService from "../../services/email.service";
import { AnyAction } from "redux";

import {
  GET_INBOX_MAILS,
  ADD_NEW_INBOX_MAIL,
  DELETE_INBOX_MAIL,
  GET_STARRED_MAILS,
  GET_IMPORTANT_MAILS,
  GET_DRAFT_MAILS,
  GET_SENT_MAILS,
  GET_TRASH_MAILS,
  GET_FOLDER_LIST,
  GET_JUNK_MAILS,
  SET_READ_UNREAD,
  GET_FOLDER_MESSAGE,
  
} from "./actionTypes"

import {
  getInboxMailsSuccess,
  getInboxMailsFail,
  addInboxMailFail,
  addInboxMailSuccess,
  deleteInboxMailSuccess,
  deleteInboxMailFail,
  getStarredMailsSuccess,
  getStarredMailsFail,
  getImportantMailsSuccess,
  getImportantMailsFail,
  getDraftMailsSuccess,
  getDraftMailsFail,
  getSentMailsSuccess,
  getSentMailsFail,
  getTrashMailsSuccess,
  getTrashMailsFail,
  getFolderListSuccess,
  getFolderListFail,
  getJunkMailsSuccess,
  getJunkMailsFail,
  setReadUnreadSuccess,
  setReadUnreadFail,
  getFolderMessage,
  getFolderMessageSuccess,
  getFolderMessageFail,
} from "./actions"


function* fetchInboxMails({ payload: { query, history } }: AnyAction): any {
  try {
    const response = yield call(emailService.getInboxMails, query);
    if(response['code']){
      yield put(getInboxMailsFail(response));
    }
    yield put(getInboxMailsSuccess(response.data));
  } catch (error: any) {
    yield put(getInboxMailsFail(error));
  }
}

function* fetchJunkMails({ payload: { query, history } }: AnyAction): any {
  try {
    const response = yield call(emailService.getJunkMails, query);
    if(response['code']){
      yield put(getJunkMailsFail(response));
    }
    yield put(getJunkMailsSuccess(response.data));
  } catch (error: any) {
    yield put(getJunkMailsFail(error));
  }
}


function* fetchFolderList({ payload: { query, history } }: AnyAction): any {
  try {
    const response = yield call(emailService.getFolderList, query);
    if(response['code']){
      yield put(getFolderListFail(response));
    }
    yield put(getFolderListSuccess(response.data));
  } catch (error: any) {
    yield put(getFolderListFail(error));
  }
}

function* fetchStarredMails({ payload: { query, history } }: AnyAction): any {
  try {
    const response = yield call(emailService.getStarredMails, query)
    yield put(getStarredMailsSuccess(response))
  } catch (error) {
    yield put(getStarredMailsFail(error))
  }
}

function* fetchImportantMails({ payload: { query, history } }: AnyAction): any {
  try {
    const response = yield call(emailService.getImportantMails, query)
    yield put(getImportantMailsSuccess(response))
  } catch (error) {
    yield put(getImportantMailsFail(error))
  }
}

function* fetchDraftMails({ payload: { query, history } }: AnyAction): any {
  try {
    const response = yield call(emailService.getDraftMails, query)
    if(response['code']){
      yield put(getDraftMailsFail(response));
    }
    yield put(getDraftMailsSuccess(response.data))
  } catch (error) {
    yield put(getDraftMailsFail(error))
  }
}

function* fetchSentMails({ payload: { query, history } }: AnyAction): any {
  try {
    const response = yield call(emailService.getSentMails, query)
    if(response['code']){
      yield put(getDraftMailsFail(response));
    }
    yield put(getSentMailsSuccess(response.data))
  } catch (error) {
    yield put(getSentMailsFail(error))
  }
}

function* fetchTrashMails({ payload: { query, history } }: AnyAction): any {
  try {
    const response = yield call(emailService.getTrashMails, query)
    if(response['code']){
      yield put(getDraftMailsFail(response));
    }
    yield put(getTrashMailsSuccess(response.data))
  } catch (error) {
    yield put(getTrashMailsFail(error))
  }
}

function* onAddNewInboxMail({ payload: inboxmail }: AnyAction): any {
  try {
    const response = yield call(emailService.addNewInboxMail, inboxmail)
    yield put(addInboxMailSuccess(response))
  } catch (error) {
    yield put(addInboxMailFail(error))
  }
}

function* onDeleteInboxMail({ payload: inboxmail }: AnyAction): any {
  try {
    const response = yield call(emailService.deleteInboxMail, inboxmail)
    yield put(deleteInboxMailSuccess(response))
  } catch (error) {
    yield put(deleteInboxMailFail(error))
  }
}


function* setReadUnreadMail({ payload: inboxmail }: AnyAction): any {
  try {
    const response = yield call(emailService.readUnreadMail, inboxmail)
    yield put(setReadUnreadSuccess(response))
  } catch (error) {
    yield put(setReadUnreadFail(error))
  }
}


function* getFolderMail({ payload: folder }: AnyAction): any {
  try {
    const response = yield call(emailService.getFolderMail, folder)
    if(response['code']){
      yield put(getFolderMessageFail(response));
    }
    yield put(getFolderMessageSuccess(response.data));
  } catch (error) {
    yield put(getFolderMessageFail(error))
  }
}



function* mailsSaga() {
  yield takeEvery(GET_INBOX_MAILS, fetchInboxMails)
  yield takeEvery(GET_STARRED_MAILS, fetchStarredMails)
  yield takeEvery(GET_IMPORTANT_MAILS, fetchImportantMails)
  yield takeEvery(GET_DRAFT_MAILS, fetchDraftMails)
  yield takeEvery(GET_SENT_MAILS, fetchSentMails)
  yield takeEvery(GET_TRASH_MAILS, fetchTrashMails)
  yield takeEvery(ADD_NEW_INBOX_MAIL, onAddNewInboxMail)
  yield takeEvery(DELETE_INBOX_MAIL, onDeleteInboxMail)
  yield takeEvery(GET_FOLDER_LIST, fetchFolderList)
  yield takeEvery(GET_JUNK_MAILS, fetchJunkMails)
  yield takeEvery(SET_READ_UNREAD, setReadUnreadMail)
  yield takeEvery(GET_FOLDER_MESSAGE, getFolderMail)
}

export default mailsSaga