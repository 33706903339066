class filters {
    private _filters: any=null;
    setFilter(filter: any) {
        
        this._filters = filter;
    }
    getFilter() {
        return this._filters;
    }
    clear(){
        this._filters=null
    }
}

export default new filters();