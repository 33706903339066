import React from 'react';
import { Container} from 'reactstrap';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import NavItems from './NavItems';
import ScrollUp from '../../components/ScrollUpDown/ScrollUp';
import ScrollDown from '../../components/ScrollUpDown/ScrollDown';
import Breadcrumbs from '../../components/Layout/Breadcrumb';
import WorkdayCalender from '../../components/WorkdayCalender/WorkdayCalender';
import { useTranslation } from 'react-i18next';
const EditWorkdayCalender = (props: any) => {
    const {t} = useTranslation();
    document.title = `${t("User details")} - ${t("Workday calender")} | ${process.env.REACT_APP_NAME}`;
    const breadcrumbItems = [
        {
          url: "/dashboard",
          label: t("Dashboard"),
          active: false,
        },
        {
          url: "/users/all-users",
          label: t("Users"),
          active: false,
        },
        {
          url: "#",
          label: t("Update user"),
          active: true,
        },
    ];

    let { id, user_id} = useParams();
    const thisYear = moment().format('YYYY');

    return (
        <React.Fragment>
            <ScrollUp />
            <ScrollDown />

            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        breadcrumbTitle={t("Update user")}
                        breadcrumbItems={breadcrumbItems}
                    />

                    <NavItems id={id} user_id={user_id} />
                    <WorkdayCalender user_id={id} thisYear={thisYear} />
                </Container>
            </div>

        </React.Fragment>
    )
}

export default EditWorkdayCalender