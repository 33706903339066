import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  NavItem,
  Nav,
  Form,
  Input,
  Button,
} from "reactstrap";
import { dateFormat } from "../../utils/formats";
import SimpleBar from "simplebar-react";
import Breadcrumbs from "../../components/Layout/Breadcrumb";
import DeleteModal from "../../components/Common/DeleteModal";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import userManualService from "../../services/user.manual.service";
import { UserManualAction } from "../../store/actions";
import { getUserProfile } from "../../helpers/functions_helper";

const UserManual = (props: any) => {
  const { t } = useTranslation();
  document.title = `${t("User manual")} | ` + process.env.REACT_APP_NAME;
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let { type, id, user_manual_id } = useParams();
  const toastId = React.useRef(null) as any;
  const userProfile = getUserProfile("all-user-manual");
  const [loadingListData, setLoadingListData] = useState(true);
  const [userManualList, setUserManualList] = useState([]);
  const [loadingManualData, setLoadingManualData] = useState(true);
  const [userManualData, setUserManualData] = useState<any>([]);

  const [deleteWhere, setDeleteWhere] = useState({
    id: "",
    user_manual_id: "",
  });
  const [recordName, setRecordName] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  let {
    userManualListData,
    userManualListError,
    userManualContentData,
    userManualContentError,
    loading,
  } = useSelector((state: any) => ({
    userManualListData: state.UserManual.GET_USER_MANUAL_LIST_SUCCESS,
    userManualListError: state.UserManual.GET_USER_MANUAL_LIST_ERROR,
    userManualContentData: state.UserManual.GET_USER_MANUAL_SUCCESS,
    userManualContentError: state.UserManual.GET_USER_MANUAL_ERROR,

    loading: state.Users.loading,
  }));

  const getAll = async () => {
    setUserManualData([]);
    if (userManualListData.length === 0)
      dispatch(UserManualAction.getUserManualList(props.history));
  };

  const gerUserManual = async (data: any) => {
    dispatch(UserManualAction.getUserManual(data, props.history));
  };

  const onClickDelete = (row: any) => {
    const data = {
      ...deleteWhere,
      id: row.id,
      user_manual_id: row.user_manual_id,
    };
    setDeleteWhere(data);
    setRecordName(row.title);
    setDeleteModal(true);
  };

  const handleDelete = () => {
    toastId.current = toast(`${t("Deleting")} ${t("user manual")}...`, {
      autoClose: false,
      isLoading: true,
    });
    deleteRecord(deleteWhere);
    setDeleteModal(false);
  };

  const deleteRecord = async (data: any) => {
    try {
      const res = (await userManualService.delete(data)) as any;
      if (res.data.status === "success") {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoadingListData(true);
        // setLoading(false);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        // setLoading(false);
      }
    } catch (err: any) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      // setLoading(false);
    }
  };

  useEffect(() => {
    if (userManualListData && userManualListData.data && userManualListData.data.length) {
      setUserManualList(userManualListData.data);
      if (userManualListData.data[0]) {
        if (type === "all") {
          return navigate(
            `/user-manual/list/${userManualListData.data[0].id}/${userManualListData.data[0].user_manual_id}`
          );
        }
        setLoadingListData(false);
      }
    }
  }, [userManualListData]);

  useEffect(() => {
    if (userManualListError.length > 0) {
      setLoadingListData(false);
    }
  }, [userManualListError]);

  useEffect(() => {
    if (userManualContentError.length > 0) {
      setLoadingManualData(false);
    }
  }, [userManualContentError]);

  useEffect(() => {
    if (userManualContentData) {
      setUserManualData(userManualContentData.data);
      setLoadingManualData(false);
    }
  }, [userManualContentData]);

  useEffect(() => {
    if (loadingListData === true) {
      getAll();
    }
  }, [loadingListData]);

  useEffect(() => {
    if (id && user_manual_id) {
      gerUserManual({ id: id, user_manual_id: user_manual_id });
    }
  }, [type, id, user_manual_id]);

  return (
    <React.Fragment>
      {deleteWhere && (
        <DeleteModal
          recordName={recordName}
          modalTitle={t("User manual")}
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
          loading={loading}
        />
      )}

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={t("User manual")}
            breadcrumbItems={[
              {
                url: "/dashboard",
                label: t("Dashboard"),
                active: false,
              },
              {
                url: "#",
                label: t("User manual"),
                active: true,
              },
            ]}
            endAdornment={
              <Nav className="nav justify-content-end gap-2">
                {userManualData && userManualData.length ? (
                  userProfile.USER_ID === parseInt(userManualData[0]?.created_by) ||
                  userProfile.CAN_EDIT_ALL ? (
                    <NavItem>
                      <Link
                        to={`/user-manual/edit-user-manual/${userManualData[0].id}/${userManualData[0].user_manual_id}`}
                        className={`btn btn-primary btn-label btn-sm`}
                      >
                        <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                          edit
                        </span>{" "}
                        {t("Edit")}
                      </Link>
                    </NavItem>
                  ) : null
                ) : null}

                {userProfile.CAN_CREATE ? (
                  <NavItem>
                    <Link
                      to="/user-manual/new-user-manual"
                      className={`btn btn-primary btn-label btn-sm`}
                    >
                      <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                        add
                      </span>{" "}
                      {t("Create")}
                    </Link>
                  </NavItem>
                ) : null}
              </Nav>
            }
          />

          <Row>
            <Col className="col-3">
              <Card>
                <CardBody className="p-4">
                  <div className="search-box">
                    <p className="text-muted">{t("Search")}</p>
                    <div className="position-relative">
                      <Input
                        type="text"
                        className="form-control rounded bg-light border-light"
                        placeholder={`${t("Search")}...`}
                      />
                      <i className="fa-solid fa-magnifying-glass search-icon"></i>
                    </div>
                  </div>

                  <hr className="my-4"></hr>

                  {/* <SimpleBar style={{ height: "Calc(100vh - 340px)" }}> */}
                    {userManualList &&
                      userManualList.map(
                        (item: any, idx: number) =>
                          item.user_manual_parent_id === 0 && (
                            <React.Fragment key={idx}>
                              <div
                                className={
                                  userManualData &&
                                  userManualData[0]?.id &&
                                  item.id === userManualData[0]?.id
                                    ? "user-manual-list parent-page active"
                                    : "user-manual-list parent-page"
                                }
                              >
                                <Row className="mb-2">
                                  <Col className="col-12">
                                    <Link
                                      to={`/user-manual/list/${item?.id}/${item?.user_manual_id}`}
                                      className="py-2 d-block font-size-14 text-truncate"
                                    >
                                      <span className="align-middle material-symbols-outlined fw-light fs-4">
                                        chevron_right
                                      </span>
                                      {localStorage.getItem("I18N_LANGUAGE") === "English"
                                        ? item.title
                                        : localStorage.getItem("I18N_LANGUAGE") === "French"
                                        ? item.title_french
                                        : localStorage.getItem("I18N_LANGUAGE") === "Dutch"
                                        ? item.title_dutch
                                        : item.title}
                                    </Link>

                                    <div className="btn-group">
                                      <Button
                                        color="outline-default"
                                        className="shadow-none border-0"
                                        onClick={() => onClickDelete(item)}
                                      >
                                        <span className="align-middle material-symbols-outlined fw-light fs-5">
                                          close
                                        </span>
                                      </Button>

                                      <Link
                                        to={`/user-manual/new-user-manual?filters={"parent_id":${item.id}}`}
                                        className="btn btn-outline-default shadow-none border-0"
                                        title={t("Create a child page")}
                                      >
                                        <span className="align-middle material-symbols-outlined fw-light fs-5">
                                          add
                                        </span>
                                      </Link>
                                    </div>
                                  </Col>
                                </Row>
                              </div>

                              {/* Child page */}
                              {userManualList.map(
                                (subitem: any, subidx: number) =>
                                  subitem.user_manual_parent_id === item.id && (
                                    <React.Fragment key={subidx}>
                                      <div
                                        className={
                                          userManualData &&
                                          userManualData[0]?.id &&
                                          subitem.id === userManualData[0]?.id
                                            ? "user-manual-list child-page active"
                                            : "user-manual-list child-page"
                                        }
                                      >
                                        <Row className="mb-2">
                                          <Col className="col-12">
                                            <Link
                                              to={`/user-manual/list/${subitem?.id}/${subitem?.user_manual_id}`}
                                              className="py-2 d-block font-size-14 text-truncate"
                                            >
                                              <span className="font-size-14 me-2">&#8226;</span>
                                              {localStorage.getItem("I18N_LANGUAGE") === "English"
                                                ? subitem.title
                                                : localStorage.getItem("I18N_LANGUAGE") === "French"
                                                ? subitem.title_french
                                                : localStorage.getItem("I18N_LANGUAGE") === "Dutch"
                                                ? subitem.title_dutch
                                                : subitem.title}
                                            </Link>

                                            <div className="btn-group">
                                              <Button
                                                color="outline-default"
                                                className="shadow-none border-0"
                                                onClick={() => onClickDelete(subitem)}
                                              >
                                                <span className="align-middle material-symbols-outlined fw-light fs-5">
                                                  close
                                                </span>
                                              </Button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </React.Fragment>
                                  )
                              )}
                            </React.Fragment>
                          )
                      )}
                  {/* </SimpleBar> */}
                </CardBody>
              </Card>
            </Col>

            <Col className="col-9">
              <Card>
                <CardHeader className="bg-transparent border-bottom border-primary mt-2 pb-0">
                  {userManualData && userManualData.length ? (
                    <CardTitle className="text-uppercase">
                      {localStorage.getItem("I18N_LANGUAGE") === "English"
                        ? userManualData[0].title
                        : localStorage.getItem("I18N_LANGUAGE") === "French"
                        ? userManualData[0].title_french
                        : localStorage.getItem("I18N_LANGUAGE") === "Dutch"
                        ? userManualData[0].title_dutch
                        : userManualData[0].title}
                    </CardTitle>
                  ) : loadingManualData ? (
                    <CardTitle className="placeholder-glow">
                      <span className="placeholder placeholder-lg col-12"></span>
                    </CardTitle>
                  ) : null}

                  <Row>
                    <Col className="col-12 mt-2 mb-3">
                      {userManualData &&
                      userManualData[0] &&
                      userManualData[0].created_by_first_name &&
                      userManualData[0].created_by_last_name ? (
                        <div>
                          <ul className="list-inline mb-1">
                            <li className="list-inline-item me-3">
                              <span className="text-muted">
                                <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                  person
                                </span>
                                {t("Created by")} {userManualData[0].created_by_first_name}{" "}
                                {userManualData[0].created_by_last_name}
                              </span>
                            </li>
                            <li className="list-inline-item me-3">
                              <span className="text-muted">
                                <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                  calendar_today
                                </span>
                                {dateFormat(userManualData[0].created_at)}
                              </span>
                            </li>
                          </ul>
                        </div>
                      ) : null}

                      {userManualData &&
                      userManualData[0] &&
                      userManualData[0].updated_by_first_name &&
                      userManualData[0].updated_by_last_name ? (
                        <div>
                          <ul className="list-inline">
                            <li className="list-inline-item me-3">
                              <span className="text-muted">
                                <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                  person
                                </span>
                                {t("Last modified by")} {userManualData[0].updated_by_first_name}{" "}
                                {userManualData[0].updated_by_last_name}
                              </span>
                            </li>
                            <li className="list-inline-item me-3">
                              <span className="text-muted">
                                <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                  calendar_today
                                </span>
                                {dateFormat(userManualData[0].updated_at)}
                              </span>
                            </li>
                          </ul>
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={12} className="user-manual">
                      {userManualData && userManualData.length ? (
                        <div
                          className="mb-0"
                          dangerouslySetInnerHTML={
                            localStorage.getItem("I18N_LANGUAGE") === "English"
                              ? { __html: userManualData[0].notes }
                              : localStorage.getItem("I18N_LANGUAGE") === "French"
                              ? { __html: userManualData[0].notes_french }
                              : localStorage.getItem("I18N_LANGUAGE") === "Dutch"
                              ? { __html: userManualData[0].notes_dutch }
                              : { __html: userManualData[0].notes }
                          }
                        />
                      ) : loadingManualData ? (
                        <div className="mb-0 placeholder-glow">
                          <span className="placeholder placeholder-lg col-8"></span>
                          <div className="col-12 mb-3"></div>
                          <span className="placeholder placeholder-lg col-6"></span>
                          <span className="placeholder placeholder-sm col-12"></span>
                        </div>
                      ) : null}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserManual;
