import React, { useState, useEffect } from "react";
import "./styles.css";

const ScrollDown = (props: any) => {
  const top = props.top;
  const [showBtnScrollDown, setShowBtnScrollDown] = useState(false);

  const goToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const docHeight: number = document.body.scrollHeight;
    window.addEventListener("scroll", () => {
      if (window.scrollY < 400 && docHeight > 1000) {
        setShowBtnScrollDown(true);
      } else {
        setShowBtnScrollDown(false);
      }
    });
  }, []);

  useEffect(() => {
    const docHeight: number = document.body.scrollHeight;
    if (docHeight < 1000) {
      setShowBtnScrollDown(false);
    }
    if (docHeight > 1000 && window.scrollY === 0) {
      setShowBtnScrollDown(true);
    }
  }, [document.body.scrollHeight]);

  return (
    <React.Fragment>
      {showBtnScrollDown && (
        <button
          type="button"
          className="btn btn-primary btn-floating btn-sm"
          id="btnScrollDown"
          title="Scroll down"
          style={{ top: top ? top : "140px" }}
          onClick={goToBottom}
        >
          <span className="align-middle material-symbols-outlined fw-light fs-4">
            expand_more
          </span>
        </button>
      )}
    </React.Fragment>
  );
};

export default ScrollDown;
