import React, { useEffect, useState } from "react";
//import { Link } from "react-router-dom";
import previewImg from "../../../assets/images/preview.jpg";
import Flatpickr from "react-flatpickr";
import fileDownload from "js-file-download";
import axios from "axios";
import { fileTypesOptions } from "../../../common/staticdata";
import { Button, Col, Form, Label, Row, FormFeedback, ModalFooter, Card, CardBody, CardTitle, CardSubtitle, Container, Nav, NavItem } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import commonService from "../../../services/common.service";
import "../../Questionnaire/style.scss";
import MultiInput from "../../Questionnaire/MultiInput";
import QuestionView from "../../Questionnaire/QuestionView";
import moment from "moment";
import Table from "./Table";
import TableMultiInput from "../../Questionnaire/multiinput/Table";
import { filterOptionsSetHiddenByValue, getDependentMultipleWhereSelectOptions, getDependentSelectOptions, getDependentSelectOptionsWithOrder, getSelectOptions, getUserProfile, onlyPositiveValue, yearData } from "../../../helpers/functions_helper";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../components/Layout/Breadcrumb";
import { useTranslation } from "react-i18next";
import { QuestionnaireQuestionAction } from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../../components/Common/Loader";
import { toast } from "react-toastify";
import Select from "../../../components/Form/Select/Select";
import TextEditor from "../../../components/Form/TextEditor";
import Input from "../../../components/Form/Input";
import DatePicker from "../../../components/Form/DatePicker/DatePicker";
const QuestionsForm = (props: any) => {
    const { t } = useTranslation();
    const breadcrumbItems = [
        {
            url: "/dashboard",
            label: t("Dashboard"),
            active: false,
        },
        {
            url: "/masters/questionnaire-questions",
            label: t("Questionnaire"),
            active: true,
        },
        {
            url: "#",
            label: t("Create"),
            active: true,
        },
    ];

    const dispatch = useDispatch();
    let navigate = useNavigate();
    document.title = `${t("Questionnaire")} - ${t("create questionnaire questions")} | ${process.env.REACT_APP_NAME}`;
    const [intialData, setIntialData] = useState({
        id: '',
        question_id: '',
        tab_id: null,
        navigate_tab: '',
        input_type: null,
        question: '',
        placeholder: '',
        condition_question: 0,
        condition_answer: '',
        condition_question_2: '',
        condition_answer_2: '',
        min_date: '',
        max_date: '',
        max_length: '',
        same_page_with: '',
        field_width: '',
        field_width_input: '',
        add_condition: false,
        user_type: '',
        questionnaire_type_id: null,
        answers: {},
        answer_values: {},
        multiinput: {},
        set_condition: false,
        togglenext: null,
        togglenexttab: null,
        condition_to_other: 0,
        condition_or: 0,
        required_field: 0,
        enable_submit: 0,
        disable_past_date: 0,
        disable_future_date: 0,
        pop_up: 0,
        pop_up_description: '',
        tooltip: 0,
        tooltip_description: '',
        map_field_name: '',
        map_table_name: '',
        map_field_type: '',
        duration: '',
        sample_attachment: '',
        file_name: '',
        file_extension: '',
        file_types: [] as any[],
        file_type_values: "",
        year: (new Date()).getFullYear(),
        enable_google_address: 0,
        perentage_depend_id: '',
        space_input: '',
        space_input_value: '',
        answers_option: '',
        answers_ids: '',
        multi_input_data: [],
        condition_input_type: 0,
        question_html: ""
        //info: (intialData && intialData.info_html) || '',
    });
    const [multiselect, setmultiselect] = useState(false);
    const [radioinput, setradioinput] = useState(false);
    const [inputplaceholder, setInputplaceholder] = useState(false);
    const [inputPercentage, setInputPercentage] = useState(false);
    const [inputDate, setInputdate] = useState(false);
    const [multiinput, setmultiinput] = useState(false);
    const [table, settable] = useState(false);
    const [inputFile, setInputFile] = useState(false);
    const [sampleFile, setSampleFile] = useState(false);
    const [mandatory, setMandatory] = useState(false);
    const [preview, setpreview] = useState(false);
    const [questionID, setQuetsionID] = useState(0);
    const [questionID2, setQuetsionID2] = useState(0);
    const [ConditonanswerType, setConditonanswerType] = useState("");
    const [ConditonanswerType2, setConditonanswerType2] = useState("");

    const [optionlist, setOptionlist] = useState<string[]>([]);
    const [rows1, setrows1] = useState([""]);
    const [rowsValue, setrowsValue] = useState([""]);
    const [userTypeFlag, setUserTypeFlag] = useState(false);

    /* set user Status */
    const [userStatusOption, setuserStatusOption] = useState([]);
    const [loadingUserStatus, setloadingUserStatus] = useState(true);
    const [rowsinput, setrowsinput] = useState<any>([{
        "question": "",
        "input_type": "",
        "id": "",
        "options": []
    }]);

    /* set tab*/
    const [loadingTab, setLoadingTab] = useState(true);
    const [tabOptions, setTabOptions] = useState([]);



    /*set input type*/
    const [loadingInput, setLoadingInput] = useState(true);
    const [loadingInputMulti, setLoadingInputMulti] = useState(true);
    const [inputMultioptionArray, setInputMultioptionArray] = useState([9]);//6
    const [inputOptions, setInputOptions] = useState([]);
    const [inputOptionsMulti, setInputOptionsMulti] = useState([]);


    /*set connection type*/
    const [loadingConnectionQuestion, setLoadingConnectionQuestion] = useState(true);
    const [ConnectionQuestionOptions, setConnectionQuestionOptions] = useState([]);
    /*set map with*/
    const [loadingMapUserField, setLoadingMapUserField] = useState(true);
    const [mapUserFieldOptions, setMapUserFieldOptions] = useState<any>([]);
    const [mapUserFieldValue, setMapUserFieldValue] = useState<any>({
        value: null,
        type: '',
        table: '',
        label: t("Please select..."),
    });

    /*set same page with*/
    const [loadingSamePagequestion, setLoadingSamePagequestion] = useState(true);
    const [SamePagequestionOptions, setSamePagequestionOptions] = useState([]);

    const [loadingpercentQuestion, setLoadingPercentQuestion] = useState(true);
    const [percentQuestion, setPercentQuestion] = useState([]);


    /*set related question answer*/
    const [loadingRelatedanswer, setLoadingRelatedanswer] = useState(true);
    const [relatedanswerOptions, setRelatedanswerOptions] = useState([]);
    const [relatedanswerValue, setRelatedanswerValue] = useState<any>();

    /* set questionnaire type */
    const [questionnaireTypeOption, setQuestionnaireTypeOption] = useState([]);

    const [loadingQuestionnaireType, setLoadingQuestionnaireType] = useState(true);

    const widthOptions: any = [
        { "label": t("Full width"), "value": 12 },
        { "label": t("Half width"), "value": 6 },
        { "label": t("One third width"), "value": 4 },
        { "label": t("Quarter width"), "value": 3 },
        { "label": t("-- Remove --"), "value": "" },
    ]

    const inputWidthOptions: any = [
        { "label": t("Full width"), "value": 100 },
        { "label": t("Three fourth width"), "value": 75 },
        { "label": t("Half width"), "value": 50 },
        { "label": t("Quarter width"), "value": 25 },
        { "label": t("-- Remove --"), "value": "" },
    ]

    const durationOptions: any = [
        { "label": t("One Month"), "value": 1 },
        { "label": t("Two Months"), "value": 2 },
        { "label": t("Three Months"), "value": 3 },
        { "label": t("Four Months"), "value": 4 },
        { "label": t("Five Months"), "value": 5 },
        { "label": t("Six Months"), "value": 6 },
        { "label": t("Seven Months"), "value": 7 },
        { "label": t("Eight Months"), "value": 8 },
        { "label": t("Nine Months"), "value": 9 },
        { "label": t("Ten Months"), "value": 10 },
        { "label": t("Eleven Months"), "value": 11 },
        { "label": t("One Year"), "value": 12 },
        { "label": t("-- Remove --"), "value": "" },
    ]


    const spaceInput: any = [
        { "label": t("left"), "value": "pad-left" },
        { "label": t("right"), "value": "pad-right" },
        { "label": t("top"), "value": "pad-top" },
        { "label": t("bottom"), "value": "pad-bottom" },
        { "label": t("-- Remove --"), "value": "" },
    ]


    const [countryOptions, setCountryOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    let data = useSelector((state: any) => ({
        success: state.QuestionnaireQuestions.success,
        error: state.QuestionnaireQuestions.error
    }))

    useEffect(() => {
        if (data.success !== "") {
            toast(data.success, { type: toast.TYPE.SUCCESS });
            return navigate("/masters/questionnaire-questions");
        }
        if (data.error !== "") {
            toast(data.error, { type: toast.TYPE.ERROR })
        }
    }, [data.success, data.error]);

    const [mapTableName, setMapTableName] = useState("");
    const [mapType, setMapType] = useState("");

    /*table state */
    const [tableData, setTableData] = useState<any>([]);
    useEffect(() => {
        if (intialData.input_type == 21) {

            let multi_data_table: any = [];
            tableData.forEach((data: any, index: any) => {
                if (data) {
                    data.forEach((dt: any, index2: any) => {
                        if (typeof dt === 'number') {
                            multi_data_table.push({ "question": "table", "input_type": dt, "id": "", "options": [], array_row: index, array_column: index2 });
                        } else if (Array.isArray(dt)) {
                            multi_data_table.push({ "question": dt[1], "input_type": dt[0], "id": "", "options": [], array_row: index, array_column: index2 });
                        } else {
                            multi_data_table.push({ "question": dt, "input_type": 0, "id": "", "options": [], array_row: index, array_column: index2 });
                        }
                    });
                }
            });
            if (multi_data_table.length > 0) {
                setrowsinput(multi_data_table);
            }
        }
    }, [tableData]);


    /* file upload */
    const [fileData, setFileData] = useState<any>(null);
    const onSelectFile = (e: any) => {
        if (e.target.files || e.target.files.length !== 0) {
            setFileData(e.target.files[0]);
            //uploadAttachment(e.target.files[0]);
            // setIntialData({ ...intialData, 'sample_attachment': e.target.files[0]});
            // validation.setFieldValue("sample_attachment", e.target.files);
        }
    };

    // Download attachment ------------------------------------------------------------
    const onClickDownload = (row: any) => {
        const SERVER_URL = process.env.REACT_APP_SERVER_URL;
        const AUTH_USER = localStorage.getItem("authUser");
        const ACCESS_TOKEN = JSON.parse(String(AUTH_USER));
        axios({
            url:
                SERVER_URL +
                "/masters/questionnaire-questions/download-attachment/" +
                row.id,
            method: "GET",
            headers: {
                "Content-Type": "application/" + row.file_extension,
                "Access-Control-Allow-Origin": "*",
                Authorization: "Bearer " + ACCESS_TOKEN.accessToken,
            },
            responseType: "blob",
        }).then((response: any) => {
            fileDownload(response.data, row.file_name + "." + row.file_extension);
        });
    };



    // Select options list API call -------------------------------------------------
    const [query, setQuery] = useState("");
    const getSelectMapOptions = async () => {
        const response = (await commonService.getSelectMapOptionsList()) as any;
        return response.data?.data;
    };

    useEffect(() => {
        getSelectOptions("tbl_master_country", "name").then(function (options: any) {
            setCountryOptions(options);
        });
    }, [])


    useEffect(() => {
        if (intialData && intialData.user_type == "") {
            setUserTypeFlag(false);
        } else {
            setUserTypeFlag(true);
        }
    }, [intialData.user_type])


    const getDependentWhereInSelectOptionsList = async (
        tableName: string,
        fieldName: string,
        whereField: string,
        whereId: number,
        whereField2: string,
        whereId2: number,
        whereinField: string,
        whereIds: number[],
    ) => {
        const response = await commonService.getDependentWhereInSelectOptionsList(
            tableName,
            fieldName,
            whereField,
            whereId,
            whereField2,
            whereId2,
            whereinField,
            whereIds,
            query ? query : ""
        );
        return response.data?.data;
    };


    const stringArrayToObject = (value: any) => {
        if (value.length > 2) {
            var newJson = value.replace(/&quot;/g, '"');
            return eval(newJson);
        }
        else {
            return [];
        }
    }


    useEffect(() => {

        getSelectOptions("tbl_master_user_status", "user_status_value").then(function (
            options
        ) {
            setuserStatusOption(options);
            setloadingUserStatus(false);
        });

        getSelectOptions("tbl_master_questionnaire_input_type", "input_type_value").then(function (options) {
            options = options.filter((option: any) => [7, 10, 15, 17].includes(option.value) == false)
            setInputOptions(options);
            setLoadingInput(false);
            const optionMulti = options.filter((option: any) => option.value ? !inputMultioptionArray.includes(option.value) : false);

            setInputOptionsMulti(optionMulti);
            setLoadingInputMulti(false);
        });



        if (intialData && intialData.answers_option) {
            const answeroption = (intialData.answers_option.toString().split('|'));
            setrows1(answeroption);
            setOptionlist(answeroption);
        }

        if (intialData && intialData.answers_ids) {
            const answers_ids = (intialData.answers_ids.toString().split(','));
            setrowsValue(answers_ids);
        }

        if (intialData && intialData.multi_input_data) {
            if (intialData.multi_input_data.length > 0)
                setrowsinput(intialData.multi_input_data);
        }


        if (intialData && intialData.condition_input_type) {
            switch (intialData.condition_input_type) {
                case (1 || 4):
                    setConditonanswerType("input");
                    break;
                case (3 || 6):
                    setConditonanswerType("multiselect");
                    break;
                case (2):
                    setConditonanswerType("radio");
                    break;

                default:
                    break;
            }
        }



    }, [])


    function renderRowHTML(rowContent: any) {
        return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
    }

    useEffect(() => {
        if (intialData.user_type && intialData.year) {
            setQuestionnaireTypeOption([]);
            setLoadingQuestionnaireType(true);
            handleChange('questionnaire_type_id', "");
            getDependentMultipleWhereSelectOptions(
                "tbl_master_questionnaire_type",
                "questionnaire_type",
                ["user_type", "year"],
                [intialData.user_type, intialData.year]
            ).then(function (options) {
                setQuestionnaireTypeOption(options);
                setLoadingQuestionnaireType(false);
            });
        } else if (intialData.year) {
            setQuestionnaireTypeOption([]);
            setLoadingQuestionnaireType(true);
            handleChange('questionnaire_type_id', "");
            getDependentMultipleWhereSelectOptions(
                "tbl_master_questionnaire_type",
                "questionnaire_type",
                ["year"],
                [intialData.year]
            ).then(function (options) {
                options.push({ value: "", label: t("-- Remove --") });
                setQuestionnaireTypeOption(options);
                setLoadingQuestionnaireType(false);
            });
        }
    }, [intialData.user_type, intialData.year]);



    useEffect(() => {
        if (intialData.questionnaire_type_id) {
            setTabOptions([]);
            setLoadingTab(true);
            getDependentSelectOptionsWithOrder("tbl_master_questionnaire_tab", "questionnaire_tab_value", "questionnaire_type_id", intialData.questionnaire_type_id, "order").then(function (options) {
                options.push({ value: "", label: t("-- Remove --") });
                setTabOptions(options);
                setLoadingTab(false);
                const selectedOption = options?.find((option: any) => option.value === intialData.tab_id)
                handleChange("tab_id", selectedOption?.value);
            });
        }


    }, [intialData.questionnaire_type_id]);


    useEffect(() => {
        if (intialData.tab_id && intialData.questionnaire_type_id) {
            getDependentWhereInSelectOptionsList("tbl_master_questionnaire_questions", "question", "tab_id", intialData.tab_id,
                "questionnaire_type_id", intialData.questionnaire_type_id, "input_type", [2, 3, 5, 6, 23, 24, 25, 26]).then(function (options) {
                    options.push({ value: "", label: t("-- Remove --") });
                    setConnectionQuestionOptions(options);
                    setLoadingConnectionQuestion(false);
                });

            getDependentWhereInSelectOptionsList("tbl_master_questionnaire_questions", "question", "tab_id", intialData.tab_id,
                "questionnaire_type_id", intialData.questionnaire_type_id, "input_type", [12]).then(function (optionsPer) {
                    optionsPer.push({ value: "", label: t("-- Remove --") });
                    setPercentQuestion(optionsPer);
                    setLoadingPercentQuestion(false);
                });


            getDependentSelectOptionsWithOrder("tbl_master_questionnaire_questions", "question", "tab_id", intialData.tab_id, "order").then(function (options) {
                options.push({ value: "", label: t("-- Remove --") });
                setSamePagequestionOptions(options);
                setLoadingSamePagequestion(false);
            });
        }


    }, [intialData.tab_id])




    useEffect(() => {
        const radioar: any = [{ "label": t("Yes"), "value": "Yes" }, { "label": t("No"), "value": "No" }];
        radioar.push({ value: "", label: t("-- Remove --") });
        setRelatedanswerOptions(radioar);
        setConditonanswerType("radio");
        setRelatedanswerValue([]);
        setIntialData({ ...intialData, 'condition_answer': '' });
        // if (multiselect) {
        if (intialData.condition_question > 0) {
            getDependentSelectOptions("tbl_master_questionnaire_answers", "answer_label", "question_id", intialData.condition_question).then(function (options) {
                if (options && options.length > 0) {
                    setRelatedanswerOptions(options);
                    setLoadingRelatedanswer(false);
                    if (typeof intialData.condition_answer === 'string' && intialData.condition_answer) {
                        let selectedOption = [] as any;
                        const module_id_group = intialData.condition_answer;
                        const str_array = module_id_group?.split(", ");
                        if (str_array.length > 0) {
                            for (let i = 0; i < str_array.length; i++) {
                                selectedOption.push(options?.find((option: any) => option.value == str_array[i]))
                            }
                        }
                        validation.setFieldValue("condition_answer", selectedOption)
                        setRelatedanswerValue(selectedOption);
                    }
                    setConditonanswerType("multiselect");
                } else {
                    setRelatedanswerOptions(radioar);
                    if (intialData.condition_answer == "Yes") {
                        const answer: any = [{ "label": t("Yes"), "value": "Yes" }];
                        setRelatedanswerValue(answer);
                    } else if (intialData.condition_answer == "No") {
                        const answer: any = [{ "label": t("No"), "value": "No" }];
                        setRelatedanswerValue(answer);
                    }

                }

            });
        }
        // } else if (radioinput) {
        //     const radioar: any = [{ "label": "Yes", "value": "1" }, { "label": "No", "value": "0" }]
        //     setRelatedanswerOptions(radioar);
        // }
    }, [intialData.condition_question])


    useEffect(() => {
        if (intialData.tab_id) {
            setLoadingMapUserField(true);
            getSelectMapOptions().then(function (options: any) {
                options.push({ value: "", label: t("-- Remove --") });
                setMapUserFieldOptions(options);
                setLoadingMapUserField(false);
                let selectedOption = "";
                options.forEach((opt: any) => {
                    opt && opt.options?.find((option: any) => {
                        if (option.value === intialData.map_field_name && opt.label === intialData.map_field_type) {
                            selectedOption = option;
                        }
                    }
                    )
                });
                handleChange('map_field_name', selectedOption);
                if (selectedOption) {
                    setMapUserFieldValue(selectedOption);
                }
            })
        }

    }, [intialData.tab_id]);



    const [percentField, setPercentField] = useState(false);
    useEffect(() => {
        if (intialData.input_type == 3 || intialData.input_type == 6 || intialData.input_type == 5) {
            setmultiselect(true);
        } else {
            setmultiselect(false);
        }
        if (intialData.input_type == 1) {
            setInputplaceholder(true);
        } else {
            setInputplaceholder(false);
        }
        if (intialData.input_type == 4) {
            setInputdate(true);
        }
        else {
            setInputdate(false);
        }
        if (intialData.input_type == 2) {
            setradioinput(true);
        } else {
            setradioinput(false);
        }
        if (intialData.input_type == 8) {
            setInputFile(true);
        } else {
            setInputFile(false);
        }

        if (intialData.input_type == 19) {
            setSampleFile(true);
        } else {
            setSampleFile(false);
        }

        if (intialData.input_type == 9) {
            setmultiinput(true);
        } else {
            setmultiinput(false);
        }

        if (intialData.input_type == 21) {
            settable(true);
        } else {
            settable(false);
        }

        if (intialData.input_type == 12) {
            setInputPercentage(true);
        } else {
            setInputPercentage(false);
        }

        if (intialData.input_type && [7, 10, 15, 18, 19, 21].includes(intialData.input_type)) {
            setMandatory(false);
        } else {
            setMandatory(true);
        }
    }, [intialData.input_type])

    const handleConditonChange = (e: any) => {
        setIntialData({ ...intialData, set_condition: !intialData.set_condition });
    }




    const validationSchema = Yup.object().shape({
        year: Yup.number().nullable()
            .required("Year field is required."),
        questionnaire_type_id: Yup.number().nullable()
            .required("Questionnaire type field is required."),
        tab_id: Yup.number().nullable()
            .required("Tab field is required."),
        input_type: Yup.number().nullable()
            .required("Input type field is required."),
        question: Yup.string().when(
            ["input_type"],
            {
                is: (input_type: number) =>
                    input_type !== 20 && input_type !== 9 && input_type !== 21,
                then: Yup.string().required("Question field is required"),
            }
        ),
    });




    // useEffect(() => {
    //     if (intialData.condition_question != 0 || intialData.same_page_with != '' || intialData.min_date != '' || intialData.max_date != '' || intialData.duration != '' || intialData.max_length != '' || intialData.condition_to_other == 1 || intialData.togglenext != null || intialData.togglenexttab != null || intialData.map_field_name != "" || intialData.file_types.length > 0) {
    //         setIntialData({ ...intialData, set_condition: true });
    //     }
    //     setMapTableName(intialData.map_table_name);
    //     setMapType(intialData.map_field_type);
    // }, [intialData]);

    const handleChangemap = (fieldValue: any, tableName: string) => {
        setMapTableName(tableName);
        setMapType(fieldValue);
    }

    const handleChange = (fieldName: string, fieldValue: any) => {
        if (fieldName == 'togglenext') {
            setIntialData({ ...intialData, [fieldName]: (intialData.togglenext == fieldValue) ? null : fieldValue });
        } else if (fieldName == 'togglenexttab') {
            setIntialData({ ...intialData, [fieldName]: (intialData.togglenexttab == fieldValue) ? null : fieldValue });
        }
        else {
            setIntialData({ ...intialData, [fieldName]: fieldValue });
        }

    };


    const handleChangecheck = (fieldName: string) => {
        if (fieldName == 'condition_to_other') {
            setIntialData({ ...intialData, [fieldName]: intialData.condition_to_other == 1 ? 0 : 1 });
        }
        if (fieldName == 'required_field') {
            setIntialData({ ...intialData, [fieldName]: intialData.required_field == 1 ? 0 : 1 });
        }
        if (fieldName == 'enable_submit') {
            setIntialData({ ...intialData, [fieldName]: intialData.enable_submit == 1 ? 0 : 1 });
        }
        if (fieldName == 'disable_past_date') {
            setIntialData({ ...intialData, [fieldName]: intialData.disable_past_date == 1 ? 0 : 1 });
        }
        if (fieldName == 'disable_future_date') {
            setIntialData({ ...intialData, [fieldName]: intialData.disable_future_date == 1 ? 0 : 1 });
        }
        if (fieldName == 'pop_up') {
            setIntialData({ ...intialData, [fieldName]: intialData.pop_up == 1 ? 0 : 1 });
        }
        if (fieldName == 'tooltip') {
            setIntialData({ ...intialData, [fieldName]: intialData.tooltip == 1 ? 0 : 1 });
        }
        if (fieldName == 'enable_google_address') {
            setIntialData({ ...intialData, [fieldName]: intialData.enable_google_address == 1 ? 0 : 1 });
        }
    }

    function htmlDecode(input: any) {
        var doc = new DOMParser().parseFromString(input, "text/html");
        return doc.documentElement.textContent;
    }


    const CKinputHandler = (event: any, editor: any) => {
        setIntialData({ ...intialData, question: editor.getData() });
        validation.setFieldValue("question", editor.getData());
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: intialData,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            values.map_table_name = mapTableName;
            values.map_field_type = mapType;
            values.answers = JSON.stringify(rows1);
            values.answer_values = JSON.stringify(rowsValue);
            values.multiinput = JSON.stringify(rowsinput);
            values.condition_answer = (values.condition_answer !== "") ? JSON.stringify(values.condition_answer) : "";
            values.sample_attachment = fileData;
            values.file_type_values = JSON.stringify(values.file_types);
            onSubmitClick(values);
        },
    });

    useEffect(() => {

        if (!validation.isSubmitting) return;
        if (Object.keys(validation.errors).length > 0) {
            document
                .getElementById(Object.keys(validation.errors)[0])
                ?.scrollIntoView({ behavior: "smooth", block: "center" });
            document.getElementById(Object.keys(validation.errors)[0])?.focus({ preventScroll: true });
        }
    }, [validation.isSubmitting, validation.errors]);

    useEffect(() => {
        if (intialData.input_type && intialData.question != "") {
            if ((intialData.input_type == 3 || intialData.input_type == 6) && rows1.length > 0) {
                setpreview(true);
            }
            else if (intialData.input_type == 9) {
                setpreview(true);
            }
            else {
                setpreview(true);
            }
        }
    }, [intialData.input_type, intialData.question]);


    // handle option list
    function handleAddRowNested() {
        setrows1([...rows1, ""]);
        setrowsValue([...rowsValue, ""]);
    }

    function handleRemoveRow(row: any) {
        var modifiedRows = [...rows1];
        const index = modifiedRows.indexOf(row);
        modifiedRows.splice(index, 1);
        var modifiedids = [...rowsValue];
        modifiedids.splice(index, 1);
        setrows1(modifiedRows);
        setrowsValue(modifiedids);
    }

    const handleOption = (e: any) => {
        const id = e.target.name.split("_").pop();
        var modifiedRows = [...rows1];
        modifiedRows[id] = e.target.value;
        setrows1(modifiedRows);
    }


    function handleAddRowQuestionNested() {
        let input_temp_ref = 'input_' + moment().unix();
        setrowsinput([...rowsinput, {
            "question": "",
            "input_type": "",
            "id": "",
            "options": [],
            "ref": input_temp_ref,
        }]);
    }

    function handleRemoveRowQuestion(formrow: any) {
        var modifiedRows = [...rowsinput];
        const index = modifiedRows.indexOf(formrow);
        modifiedRows.splice(index, 1);

        setrowsinput(modifiedRows);
    }


    function handleinput(e: any, type: any, key: any) {
        var modifiedRows = [...rowsinput];
        if (type === "question") {
            modifiedRows[key].question = e.target.value;
        } else if (e.target.value == 3 || e.target.value == 6 || e.target.value == 5) {
            modifiedRows[key].input_type = e.target.value;
            if (modifiedRows[key].options.length < 1)
                modifiedRows[key].options.push({ "label": '', "value": '' });
        } else {
            modifiedRows[key].input_type = e.target.value;
            modifiedRows[key].options = [];
        }

        setrowsinput(modifiedRows);
    }


    //MultiSelect option @Rahul K
    const addNewOption = (key: number) => {
        var modifiedRows = [...rowsinput];
        modifiedRows[key].options.push({ "label": '', "value": '' });
        setrowsinput(modifiedRows);
    }

    const handleinputOption = (e: any, type: any, key: any, optionkey: number) => {

        let optionValue = { "label": e.target.value, "value": e.target.value };
        var modifiedRows = [...rowsinput];
        modifiedRows[key].options[optionkey] = optionValue;
        setrowsinput(modifiedRows);
    }

    const handleRemoveRowQuestionOption = (masterIndex: number, index: number) => {
        var modifiedRows = [...rowsinput];
        const indexItem = modifiedRows[masterIndex].options.indexOf(index);
        modifiedRows[masterIndex].options.splice(indexItem, 1);
        setrowsinput(modifiedRows);
    }

    //multiple input
    const handleMultiInputChange = (e: any, ref: any) => {

    }


    const colorStyles = {
        control: (styles: any) => ({ ...styles }),
        groupHeading: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
            return { ...styles, fontWeight: "bold", color: "black", fontSize: "12px" };
        },
    };

    const onSubmitClick = (values: any) => {
        setLoading(true);
        if (values && values?.id && values.question_id) {
            dispatch(QuestionnaireQuestionAction.updateQuestionnaireQuestions(values, props.history));
            setLoading(false);
        } else {
            dispatch(QuestionnaireQuestionAction.addNewQuestionnaireQuestions(values));
            setLoading(false);
        }
    };


    const [yearList, setYearList] = useState<any>([]);
    const [userStatusList, setUserStatusList] = useState<any>([]);

    useEffect(() => {

        if (userStatusOption) {
            const options = userStatusOption?.map((option: any) => ({
                label: t(option.label),
                value: option.value
            }));
            setUserStatusList(options);
        }

        if (yearData) {
            const options = yearData?.map((option: any) => ({
                label: t(option.label),
                value: option.value
            }));
            setYearList(options);
        }

    }, [localStorage.getItem("I18N_LANGUAGE"), userStatusOption])


    return (
        <React.Fragment>
            <div className="page-content questionnaire-list">
                <Container fluid>
                    <Breadcrumbs
                        breadcrumbTitle={t("Questions")}
                        breadcrumbItems={breadcrumbItems}
                    />
                    <Card>
                        <CardBody>
                            {loading ? <LoadingSpinner /> :
                                <Row>
                                    <Col lg="12" className="questionnaire-form-modle questionnaire">
                                        <Form
                                            onSubmit={(e: any) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            <Row>
                                                <Col lg="2">
                                                    <div className="mb-3">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="customCheckcolor1"
                                                            name="user_type_flag"
                                                            onChange={e => {
                                                                setUserTypeFlag(!userTypeFlag);
                                                                if (!e.target.checked) {
                                                                    handleChange('questionnaire_type_id', 0);
                                                                }
                                                            }}
                                                            checked={userTypeFlag}
                                                            onBlur={validation.handleBlur}
                                                        />
                                                        <Select
                                                            id="user_type"
                                                            name="user_type"
                                                            label="User type"
                                                            className="is-invalid"
                                                            options={userStatusList}
                                                            isSearchable={true}
                                                            isDisabled={loadingUserStatus || !userTypeFlag}
                                                            isLoading={loadingUserStatus}
                                                            value={
                                                                intialData.user_type
                                                                    ? userStatusList?.find(
                                                                        (option: any) =>
                                                                            option.value == intialData.user_type
                                                                    )
                                                                    : ""
                                                            }
                                                            onBlur={() =>
                                                                validation.setFieldTouched("user_type", true)
                                                            }
                                                            onChange={(e: any) => {
                                                                handleChange("user_type", e.value);
                                                            }}
                                                            touched={validation.touched.user_type}
                                                            error={validation.errors.user_type}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg="2">
                                                    <div className="mb-3">
                                                        <Label className="form-label">
                                                            {t("Year")}{" "}
                                                            <span className="text-warning is_required">*</span>
                                                        </Label>
                                                        <Select
                                                            id="year"
                                                            name="year"
                                                            className="is-invalid"
                                                            options={yearList}
                                                            value={
                                                                intialData.year
                                                                    ? yearList?.find(
                                                                        (option: any) =>
                                                                            option.value == intialData.year
                                                                    )
                                                                    : ""
                                                            }
                                                            isSearchable={true}
                                                            onChange={(option: any) => {
                                                                handleChange('year', option.value);
                                                            }}
                                                            touched={validation.touched.year}
                                                            error={validation.errors.year}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg="3">
                                                    <div className="mb-3">
                                                        <Select
                                                            id="questionnaire_type_id"
                                                            name="questionnaire_type_id"
                                                            className="is-invalid"
                                                            isSearchable={true}
                                                            label="Questionnaire type"
                                                            required
                                                            isDisabled={loadingQuestionnaireType}
                                                            options={questionnaireTypeOption}
                                                            value={
                                                                intialData.questionnaire_type_id
                                                                    ? questionnaireTypeOption?.find(
                                                                        (option: any) =>
                                                                            option.value == intialData.questionnaire_type_id
                                                                    )
                                                                    : ""
                                                            }
                                                            onBlur={() =>
                                                                validation.setFieldTouched("questionnaire_type_id", true)
                                                            }
                                                            onChange={(e: any) => {
                                                                handleChange("questionnaire_type_id", e.value);
                                                            }}
                                                            touched={validation.touched.questionnaire_type_id}
                                                            error={validation.errors.questionnaire_type_id}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg="3">
                                                    <div className="mb-3">
                                                        <Select
                                                            id="tab_id"
                                                            name="tab_id"
                                                            className="is-invalid"
                                                            label="Tab"
                                                            required
                                                            options={tabOptions}
                                                            isSearchable={false}
                                                            isDisabled={loadingTab}
                                                            value={
                                                                intialData.tab_id
                                                                    ? tabOptions?.find(
                                                                        (option: any) =>
                                                                            option.value == intialData.tab_id
                                                                    )
                                                                    : ""
                                                            }
                                                            onBlur={() =>
                                                                validation.setFieldTouched("tab_id", true)
                                                            }
                                                            onChange={(e: any) => {
                                                                handleChange("tab_id", e.value);
                                                            }}
                                                            touched={validation.touched.tab_id}
                                                            error={validation.errors.tab_id}
                                                        />

                                                    </div>
                                                </Col>
                                                <Col lg="2">
                                                    <div className="mb-3">
                                                        <Select
                                                            id="input_type"
                                                            name="input_type"
                                                            className="is-invalid"
                                                            label="Input type"
                                                            required
                                                            options={inputOptions}
                                                            isSearchable={true}
                                                            value={
                                                                intialData.input_type
                                                                    ? inputOptions?.find(
                                                                        (option: any) =>
                                                                            option.value == intialData.input_type
                                                                    )
                                                                    : ""
                                                            }
                                                            onBlur={() =>
                                                                validation.setFieldTouched("input_type", true)
                                                            }
                                                            onChange={(e: any) => {
                                                                handleChange("input_type", e.value);
                                                            }}
                                                            touched={validation.touched.input_type}
                                                            error={validation.errors.input_type}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg="6">
                                                    <div className="mb-3">
                                                        <div className="is-invalid">
                                                            <TextEditor
                                                                id="question"
                                                                name="question"
                                                                label="Question"
                                                                required={(intialData.input_type !== 20 && intialData.input_type !== 9 && intialData.input_type !== 21) ? true : false}
                                                                className="form-control"
                                                                value={intialData.question}
                                                                onChange={CKinputHandler}
                                                                onBlur={() => validation.setFieldTouched("question", true)}
                                                                touched={validation.touched.question}
                                                                error={validation.errors.question}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                {!sampleFile &&
                                                    <Col lg="6" className="mt-4">
                                                        <Card style={{ overflow: "auto", height: "97%" }}>
                                                            <CardBody>
                                                                <CardTitle className="text-center"> {t("Preview")}</CardTitle>
                                                                {!preview &&
                                                                    <>
                                                                        <CardSubtitle className="mb-3">
                                                                            {t("Preview will be available after answering mandatory questions.")}
                                                                        </CardSubtitle>
                                                                        <Row>
                                                                            <Col md={12}>
                                                                                <img
                                                                                    className="rounded-circle preview-img"
                                                                                    src={previewImg}
                                                                                    alt="Header Avatar"
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </>
                                                                }
                                                                {preview &&
                                                                    <>
                                                                        {
                                                                            intialData.input_type == 9 || intialData.input_type == 21 ?
                                                                                <>
                                                                                    {intialData.input_type == 9 ?
                                                                                        <MultiInput
                                                                                            label={intialData.question}
                                                                                            multi_input_data={rowsinput}
                                                                                            reference_name={undefined}
                                                                                            user_data_answer={undefined}
                                                                                            is_disabled={false}
                                                                                            handleMultiInputChange={(e: any, ref: any) => handleMultiInputChange(e, ref)}
                                                                                            input_type={intialData.input_type}
                                                                                        />
                                                                                        :
                                                                                        <TableMultiInput
                                                                                            label={intialData.question}
                                                                                            multi_input_data={rowsinput}
                                                                                            reference_name={undefined}
                                                                                            user_data_answer={undefined}
                                                                                            is_disabled={false}
                                                                                            handleMultiInputChange={(e: any, ref: any) => handleMultiInputChange(e, ref)}
                                                                                            input_type={intialData.input_type}
                                                                                        />
                                                                                    }
                                                                                </>
                                                                                :
                                                                                <Col lg={validation.values.field_width ? validation.values.field_width : 12} className="preview-question-border">
                                                                                    <QuestionView questionrow={intialData} input_type={validation.values.input_type} field_width={validation.values.field_width} question={validation.values.question} reference_name={undefined} placeholder={validation.values.placeholder} user_data_answer={undefined} countryOptions={countryOptions}
                                                                                        answer_option_list={rows1} handleChange={undefined} handleKeyDown={undefined} handleChangeMulti={undefined} handleMultiInputChange={undefined}
                                                                                        handleChangeMulticheckbox={undefined} handleChangeCountry={undefined} handlecalender={undefined} handleChangecheck={undefined} section_id={undefined}
                                                                                        multi_input_data={undefined} adminview={true} questionnaireID={undefined} questionnaireSubID={undefined} answerOnly={undefined} activeTabnames={undefined} required={undefined} />
                                                                                </Col>
                                                                        }

                                                                    </>
                                                                }

                                                            </CardBody>
                                                        </Card>
                                                    </Col>
                                                }
                                                {sampleFile &&
                                                    <Col lg="6" className="mt-5">
                                                        <Label>{t("Upload files")}</Label>
                                                        <Input
                                                            id="sample_attachment"
                                                            name="sample_attachment"
                                                            type="file"
                                                            //accept="image/*"
                                                            // value={avatarImage}
                                                            onChange={onSelectFile}
                                                        />
                                                        {validation.touched.sample_attachment &&
                                                            validation.errors.sample_attachment ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.sample_attachment}
                                                            </FormFeedback>
                                                        ) : null}
                                                        <br />
                                                        <Row>
                                                            {
                                                                intialData.file_name != '' && intialData.sample_attachment != '' &&
                                                                <>
                                                                    <div className="d-flex gap-2 text-end">
                                                                        <span
                                                                            className="link-primary"
                                                                            title={intialData.file_name}
                                                                            onClick={() => onClickDownload(intialData)}>
                                                                            {intialData.file_name.substring(0, 50)}
                                                                            {intialData.file_name.length >= 50 && "..."}
                                                                            {"."}
                                                                            {intialData.file_extension}
                                                                        </span>
                                                                        {intialData.id ?
                                                                            <Button
                                                                                outline={true}
                                                                                color="primary"
                                                                                size="sm"
                                                                                onClick={() => onClickDownload(intialData)}
                                                                                title={t("Download file")}>
                                                                                <i className="fa-solid fa-cloud-arrow-down"></i>
                                                                            </Button>
                                                                            : null
                                                                        }
                                                                    </div>
                                                                </>

                                                            }
                                                        </Row>
                                                    </Col>
                                                }
                                            </Row>

                                            {multiselect &&
                                                <Row>
                                                    <Col lg={8}>
                                                        <div className="inner-repeater mb-4">
                                                            <Label>{t("Options :")}</Label>
                                                            <table style={{ width: "100%" }}>
                                                                <tbody>

                                                                    {(rows1 || []).map((formRow, key) => (

                                                                        <tr key={key}>
                                                                            <td>
                                                                                <Row className="mb-2">
                                                                                    <Col md="10" style={{ position: "relative" }}>
                                                                                        <Input
                                                                                            type="text"
                                                                                            className="inner form-control"
                                                                                            placeholder={t("Options")}
                                                                                            name={'option_' + key}
                                                                                            value={formRow || ''}
                                                                                            onChange={e => {
                                                                                                handleOption(e);
                                                                                            }}
                                                                                        />
                                                                                        <Button
                                                                                            color="danger"
                                                                                            className="btn btn-primary btn-sm waves-light btn-close-question"
                                                                                            onClick={e => {
                                                                                                handleRemoveRow(formRow);
                                                                                            }}
                                                                                        >
                                                                                            <i className="fas fa-close label-icon"></i>
                                                                                        </Button>
                                                                                        {/* 
                                                                                    {/* 
                                                                                        {/* 
                                                                                    {/* 
                                                                                        {/* 
                                                                                        <Button
                                                                                            color="btn-sm btn-close-question"
                                                                                            onClick={e => {
                                                                                                handleRemoveRow(formRow.id);
                                                                                            }}
                                                                                        >
                                                                                            <i className="fas fa-window-close"></i>
                                                                                        </Button> */}
                                                                                    </Col>
                                                                                    <Col md="2">
                                                                                        <Button
                                                                                            onClick={() => {
                                                                                                handleAddRowNested();
                                                                                            }}
                                                                                            color="primary"
                                                                                            className="btn btn-sm btn-add-question"
                                                                                        >
                                                                                            <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">add</span>
                                                                                        </Button>
                                                                                    </Col>
                                                                                </Row>
                                                                            </td>
                                                                        </tr>
                                                                    ))}

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }

                                            {multiinput &&
                                                <Row>
                                                    <Col lg={8}>
                                                        <div className="inner-repeater mb-4">
                                                            <table style={{ width: "100%" }}>
                                                                <tbody>

                                                                    {(rowsinput || []).map((formRow: any, key: number) => (

                                                                        <tr key={key}>
                                                                            <td>
                                                                                <Row className="mb-2">

                                                                                    <Col md="10" style={{ position: "relative" }}>
                                                                                        <Row>
                                                                                            <Col md="6">
                                                                                                <Label>{t("Questions :")}</Label>
                                                                                                <Input
                                                                                                    type="text"
                                                                                                    className=" form-control"
                                                                                                    placeholder={t("Questions :")}
                                                                                                    name={'question_' + key}
                                                                                                    value={formRow.question || ''}
                                                                                                    onChange={e => {
                                                                                                        handleinput(e, "question", key);
                                                                                                    }}
                                                                                                />
                                                                                            </Col>
                                                                                            <Col md="6">
                                                                                                <Label>{t("Input Type :")}</Label>
                                                                                                <select className="form-control" onChange={e => {
                                                                                                    handleinput(e, "input_type", key);
                                                                                                }}>
                                                                                                    <option value="0">{t("Select")}</option>
                                                                                                    {inputOptionsMulti.map((option: any) => (
                                                                                                        <option value={option.value} selected={(option.value == formRow.input_type) ? true : false}>{option.label}</option>
                                                                                                    ))}

                                                                                                </select>
                                                                                                {
                                                                                                    (formRow.input_type == '3' || formRow.input_type == '6' || formRow.input_type == '5') &&
                                                                                                    <>
                                                                                                        {
                                                                                                            formRow.options.map((_item: any, op: number) =>
                                                                                                                <Row className="mt-2" key={op}>
                                                                                                                    <Col>
                                                                                                                        <Input
                                                                                                                            type="text"
                                                                                                                            className="inner form-control"
                                                                                                                            placeholder={t("Options")}
                                                                                                                            name={'input_type_' + key + "_" + op}
                                                                                                                            value={_item.value}
                                                                                                                            onChange={e => {
                                                                                                                                handleinputOption(e, "input_type", key, op);
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </Col>
                                                                                                                    <Col sm="2">
                                                                                                                        {
                                                                                                                            formRow.options?.length - 1 === op ?
                                                                                                                                <Button
                                                                                                                                    color="warning"
                                                                                                                                    className="btn btn-primary btn-sm waves-light btn-close-question"
                                                                                                                                    onClick={e => {
                                                                                                                                        addNewOption(key);
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">add</span>
                                                                                                                                </Button> :
                                                                                                                                <Button
                                                                                                                                    color="danger"
                                                                                                                                    className="btn btn-primary btn-sm waves-light btn-close-question"
                                                                                                                                    onClick={e => {
                                                                                                                                        handleRemoveRowQuestionOption(key, op);
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <i className="fas fa-close label-icon"></i>
                                                                                                                                </Button>
                                                                                                                        }
                                                                                                                    </Col>
                                                                                                                </Row>

                                                                                                            )
                                                                                                        }

                                                                                                    </>
                                                                                                }



                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                                    <Col md="2" className="multiinput-icons">
                                                                                        <Button
                                                                                            color="danger"
                                                                                            className="btn btn-primary btn-sm waves-light btn-close-question"
                                                                                            onClick={e => {
                                                                                                handleRemoveRowQuestion(formRow);
                                                                                            }}
                                                                                        >
                                                                                            <i className="fas fa-close label-icon"></i>
                                                                                        </Button>
                                                                                        <Button
                                                                                            onClick={() => {
                                                                                                handleAddRowQuestionNested();
                                                                                            }}
                                                                                            color="primary"
                                                                                            className="btn btn-sm btn-add-question"
                                                                                        >
                                                                                            <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">add</span>
                                                                                        </Button>
                                                                                    </Col>
                                                                                </Row>
                                                                            </td>
                                                                        </tr>
                                                                    ))}

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                            {table &&
                                                <Table setTableData={setTableData} multi_input_data={intialData.multi_input_data} />
                                            }

                                            {inputplaceholder &&
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formrow-category_name-Input">
                                                            {t("Placeholder")}
                                                            <span className="text-warning is_required">*</span>
                                                        </Label>
                                                        <Input
                                                            name="placeholder"
                                                            className="form-control"
                                                            placeholder={t("Placeholder")}
                                                            type="text"
                                                            value={intialData.placeholder}
                                                            onBlur={validation.handleBlur}
                                                            onChange={(e) =>
                                                                handleChange("placeholder", e.target.value)
                                                            }
                                                            invalid={
                                                                validation.touched.placeholder &&
                                                                    validation.errors.placeholder
                                                                    ? true
                                                                    : false
                                                            } />
                                                        {validation.touched.placeholder &&
                                                            validation.errors.placeholder ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.placeholder}
                                                            </FormFeedback>
                                                        ) : null}

                                                    </div>
                                                </Col>
                                            }
                                            <Row>
                                                {(intialData.input_type == 8 && intialData.pop_up) ?
                                                    <Col lg="4">
                                                        <div className="mb-3 mt-3">
                                                            <Label htmlFor="formrow-category_name-Input">
                                                                {t("Pop up description")}
                                                            </Label>
                                                            <Input
                                                                name="pop_up_description"
                                                                className="form-control"
                                                                placeholder={t("Pop up description")}
                                                                type="text"
                                                                value={intialData.pop_up_description}
                                                                onBlur={validation.handleBlur}
                                                                onChange={(e) =>
                                                                    handleChange("pop_up_description", e.target.value)
                                                                } />

                                                        </div>
                                                    </Col>
                                                    : null}
                                                {intialData.tooltip ? (
                                                    <Col lg="4">
                                                        <div className="mt-3">
                                                            <Label htmlFor="formrow-category_name-Input">
                                                                {t("Tooltip description")}
                                                            </Label>
                                                            <Input
                                                                name="tooltip_description"
                                                                className="form-control"
                                                                placeholder={t("Tooltip description")}
                                                                type="text"
                                                                value={intialData.tooltip_description}
                                                                onBlur={validation.handleBlur}
                                                                onChange={(e) =>
                                                                    handleChange("tooltip_description", e.target.value)
                                                                } />

                                                        </div>
                                                    </Col>
                                                ) : null}
                                                <Col lg="4">
                                                    <div className="mb-3 mt-3">
                                                        <Label htmlFor="formrow-category_name-Input">
                                                            {t("Question width")}
                                                        </Label>
                                                        <Select
                                                            id="field_width"
                                                            name="field_width"
                                                            options={widthOptions}
                                                            isSearchable={false}
                                                            value={
                                                                intialData.field_width
                                                                    ? widthOptions?.find(
                                                                        (option: any) =>
                                                                            option.value == intialData.field_width
                                                                    )
                                                                    : ""
                                                            }
                                                            onBlur={() =>
                                                                validation.setFieldTouched("field_width", true)
                                                            }
                                                            onChange={(e: any) => {
                                                                handleChange("field_width", e.value);
                                                            }}
                                                        />
                                                        {validation.touched.field_width &&
                                                            validation.errors.field_width ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.field_width}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col lg="4">
                                                    <div className="mb-3 mt-3">
                                                        <Label htmlFor="formrow-category_name-Input">
                                                            {t("Field width")}
                                                        </Label>
                                                        <Select
                                                            id="field_width_input"
                                                            name="field_width_input"
                                                            options={inputWidthOptions}
                                                            isSearchable={false}
                                                            value={
                                                                intialData.field_width_input
                                                                    ? inputWidthOptions?.find(
                                                                        (option: any) =>
                                                                            option.value == intialData.field_width_input
                                                                    )
                                                                    : ""
                                                            }
                                                            onBlur={() =>
                                                                validation.setFieldTouched("field_width_input", true)
                                                            }
                                                            onChange={(e: any) => {
                                                                handleChange("field_width_input", e.value);
                                                            }}
                                                        />
                                                        {validation.touched.field_width_input &&
                                                            validation.errors.field_width_input ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.field_width_input}
                                                            </FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="mb-3 mt-3">
                                                        <Label htmlFor="formrow-category_name-Input">
                                                            {t("Space")}
                                                        </Label>
                                                        <Select
                                                            id="space_input"
                                                            name="space_input"
                                                            options={spaceInput}
                                                            isSearchable={false}
                                                            value={
                                                                intialData.space_input
                                                                    ? spaceInput?.find(
                                                                        (option: any) =>
                                                                            option.value == intialData.space_input
                                                                    )
                                                                    : ""
                                                            }
                                                            onBlur={() =>
                                                                validation.setFieldTouched("space_input", true)
                                                            }
                                                            onChange={(e: any) => {
                                                                handleChange("space_input", e.value);
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <Label htmlFor="formrow-category_name-Input">
                                                        {t("Space input value")}
                                                    </Label>
                                                    <Input
                                                        name="space_input_value"
                                                        className="form-control"
                                                        placeholder={t("Space Input value")}
                                                        type="number"
                                                        min={1}
                                                        max={10}
                                                        onKeyPress={(e) => onlyPositiveValue(e)}
                                                        value={intialData.space_input_value}
                                                        onBlur={validation.handleBlur}
                                                        onChange={(e) =>
                                                            handleChange("space_input_value", e.target.value)
                                                        } />
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col lg="3">
                                                    <div className="form-check form-check-primary mb-3">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="set_condition"
                                                            name="set_condition"
                                                            onBlur={validation.handleBlur}
                                                            onChange={(e) => {
                                                                handleChange("set_condition", e.target.value)
                                                                handleConditonChange(e);
                                                            }}
                                                            checked={intialData.set_condition}
                                                        />

                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="set_condition"
                                                        >
                                                            {t("Add condition")}
                                                        </label>

                                                    </div>
                                                </Col>
                                                <Col lg="2">
                                                    <div className="form-check form-check-primary mb-3">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="customCheckenableSubmit"
                                                            name="enable_submit"
                                                            onBlur={validation.handleBlur}
                                                            checked={intialData.enable_submit === 1 ? true : false}
                                                            onChange={(e: any) => {
                                                                handleChangecheck("enable_submit");
                                                            }}
                                                        />

                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="customCheckenableSubmit"
                                                        >
                                                            {t("Enable submit button")}
                                                        </label>

                                                    </div>
                                                </Col>
                                                {mandatory ?
                                                    <Col lg="2">
                                                        <div className="form-check form-check-primary mb-3">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="customCheckrequired"
                                                                name="required_field"
                                                                onBlur={validation.handleBlur}
                                                                checked={intialData.required_field === 1 ? true : false}
                                                                onChange={(e: any) => {
                                                                    handleChangecheck("required_field");
                                                                }}
                                                            />

                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="customCheckrequireds"
                                                            >
                                                                {t("Mandatory")}
                                                            </label>

                                                        </div>
                                                    </Col>
                                                    : null}

                                                {
                                                    intialData.input_type == 8 ?
                                                        <Col lg="2">
                                                            <div className="form-check form-check-primary mb-3">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id="customCheckpop_up"
                                                                    name="pop_up"
                                                                    onBlur={validation.handleBlur}
                                                                    checked={intialData.pop_up === 1 ? true : false}
                                                                    onChange={(e: any) => {
                                                                        handleChangecheck("pop_up");
                                                                    }}
                                                                />

                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="customCheckpop_up"
                                                                >
                                                                    {t("Pop up notification")}
                                                                </label>

                                                            </div>
                                                        </Col>
                                                        : null
                                                }
                                                <Col lg="2">
                                                    <div className="form-check form-check-primary mb-3">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="customCheckenable_google_address"
                                                            name="enable_google_address"
                                                            onBlur={validation.handleBlur}
                                                            checked={intialData.enable_google_address === 1 ? true : false}
                                                            onChange={(e: any) => {
                                                                handleChangecheck("enable_google_address");
                                                            }}
                                                        />

                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="customCheckenable_google_address"
                                                        >
                                                            {t("Enable google address")}
                                                        </label>

                                                    </div>
                                                </Col>
                                                <Col lg="2">
                                                    <div className="form-check form-check-primary mb-3">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="customChecktooltip"
                                                            name="tooltip"
                                                            onBlur={validation.handleBlur}
                                                            checked={intialData.tooltip === 1 ? true : false}
                                                            onChange={(e: any) => {
                                                                handleChangecheck("tooltip");
                                                            }}
                                                        />

                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="customChecktooltip"
                                                        >
                                                            {t("Tooltip")}
                                                        </label>

                                                    </div>
                                                </Col>
                                            </Row>
                                            {intialData.set_condition &&
                                                <Row className="border p-3">
                                                    {(radioinput || intialData.input_type == 16) ?
                                                        <Col lg="12">
                                                            <Row>
                                                                {intialData.input_type == 16 &&
                                                                    <Col lg={4} className="mb-3">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            id="togglenext"
                                                                            name="togglenext"
                                                                            onChange={e => {
                                                                                handleChange("togglenext", !intialData.togglenext)
                                                                            }}
                                                                            checked={intialData.togglenext ? true : false}
                                                                            onBlur={validation.handleBlur}
                                                                        />
                                                                        <label
                                                                            className="form-check-label ms-1"
                                                                            htmlFor="togglenext"
                                                                        >
                                                                            {t("Navigate to next question when checkbox is ticked")}
                                                                        </label>
                                                                    </Col>
                                                                }
                                                                {!!radioinput &&
                                                                    <Col lg="5">
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="customChecktoggle"
                                                                        >
                                                                            {t("Navigate to next question when answer is")}
                                                                        </label>
                                                                        <div className="form-group">
                                                                            <label className="container_radio version_2">{t("Yes")}
                                                                                <input type="checkbox"
                                                                                    name="togglenext"
                                                                                    onChange={() => handleChange("togglenext", 1)}
                                                                                    checked={intialData.togglenext === 1 ? true : false}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value="1" />
                                                                                <span className="checkmark"></span>
                                                                            </label>
                                                                            <label className="container_radio version_2">{t("No")}
                                                                                <input type="checkbox" name="togglenext"
                                                                                    onChange={() => handleChange("togglenext", 0)}
                                                                                    checked={intialData.togglenext === 0 ? true : false}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value="0" />
                                                                                <span className="checkmark"></span>
                                                                            </label>
                                                                        </div>
                                                                    </Col>
                                                                }
                                                                {!!(intialData.input_type == 16) ?
                                                                    <Col lg={4} className="mb-3">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            id="customCheckcolor1"
                                                                            name="togglenexttab"
                                                                            onChange={e => {
                                                                                handleChange("togglenexttab", !intialData.togglenexttab)
                                                                            }}
                                                                            checked={intialData.togglenexttab ? true : false}
                                                                            onBlur={validation.handleBlur}
                                                                        />
                                                                        <label
                                                                            className="form-check-label ms-1"
                                                                            htmlFor="customChecktoggle"
                                                                        >
                                                                            {t("Navigate to next tab when checkbox is ticked")}
                                                                        </label>
                                                                    </Col>
                                                                    :
                                                                    <Col lg="3">
                                                                        <div className="form-group">
                                                                            <label
                                                                                className="form-check-label"
                                                                                htmlFor="customChecktoggle"
                                                                            >
                                                                                {t("Navigate to next tab when answer is")}
                                                                            </label>
                                                                            <label className="container_radio version_2">{t("Yes")}
                                                                                <input type="radio"
                                                                                    name="togglenexttab"
                                                                                    onChange={() => handleChange("togglenexttab", 1)}
                                                                                    checked={intialData.togglenexttab === 1 ? true : false}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value="1" />
                                                                                <span className="checkmark"></span>
                                                                            </label>
                                                                            <label className="container_radio version_2">{t("No")}
                                                                                <input type="radio" name="togglenexttab"
                                                                                    onChange={() => handleChange("togglenexttab", 0)}
                                                                                    checked={intialData.togglenexttab === 0 ? true : false}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value="0" />
                                                                                <span className="checkmark"></span>
                                                                            </label>
                                                                        </div>
                                                                    </Col>
                                                                }
                                                                {(intialData.togglenexttab != null && intialData.tab_id) &&
                                                                    <Col lg="3">
                                                                        <div className="mb-3">
                                                                            <Label className="form-label">
                                                                                {t("Navigate to Tab")}{" "}
                                                                                <span className="text-warning is_required">*</span>
                                                                            </Label>
                                                                            <Select
                                                                                id="navigate_tab"
                                                                                name="navigate_tab"
                                                                                className="is-invalid"
                                                                                options={filterOptionsSetHiddenByValue(tabOptions, [intialData.tab_id])}
                                                                                isSearchable={false}
                                                                                isDisabled={loadingTab}
                                                                                value={
                                                                                    intialData.navigate_tab
                                                                                        ? tabOptions?.find(
                                                                                            (option: any) =>
                                                                                                option.value == intialData.navigate_tab
                                                                                        )
                                                                                        : ""
                                                                                }
                                                                                onBlur={() =>
                                                                                    validation.setFieldTouched("navigate_tab", true)
                                                                                }
                                                                                onChange={(e: any) => {
                                                                                    handleChange("navigate_tab", e.value);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </Col>
                                                                }

                                                            </Row>
                                                        </Col>
                                                        : null
                                                    }
                                                    {!!inputDate &&
                                                        <Row>
                                                            <Col lg="3">
                                                                <Label> {t("Min Date")}</Label>
                                                                <div className="mb-3">
                                                                    <DatePicker
                                                                        id="min_date"
                                                                        name="min_date"
                                                                        className="form-control d-block"
                                                                        type="text"
                                                                        placeholder={t("Min Date")}
                                                                        options={{
                                                                            altInput: true,
                                                                            altFormat: "d/m/Y",
                                                                            dateFormat: "Y-m-d",
                                                                            maxDate: "today",
                                                                            allowInput: true,
                                                                            onChange: function (
                                                                                selectedDates,
                                                                                dateStr,
                                                                                instance
                                                                            ) {
                                                                                handleChange("min_date", dateStr);
                                                                                validation.setFieldValue(
                                                                                    "min_date",
                                                                                    dateStr
                                                                                );
                                                                            },
                                                                            onClose: function (
                                                                                selectedDates,
                                                                                dateStr,
                                                                                instance
                                                                            ) {
                                                                                handleChange("min_date", dateStr);
                                                                                validation.setFieldValue(
                                                                                    "min_date",
                                                                                    dateStr
                                                                                );
                                                                            },
                                                                            locale: { firstDayOfWeek: 1 }
                                                                        }}
                                                                        value={intialData.min_date || ""}
                                                                    />
                                                                    {/* <span className="input-group-text">
                                                                        <span className="align-middle material-symbols-outlined fw-light fs-4">calendar_today</span>
                                                                    </span> */}
                                                                </div>
                                                            </Col>
                                                            <Col lg="3">
                                                                <Label>{t("Max Date")}</Label>
                                                                <div className="mb-3">
                                                                    <DatePicker
                                                                        id="max_date"
                                                                        name="max_date"
                                                                        className="form-control d-block"
                                                                        type="text"
                                                                        placeholder={t("Max Date")}
                                                                        options={{
                                                                            altInput: true,
                                                                            altFormat: "d/m/Y",
                                                                            dateFormat: "Y-m-d",
                                                                            allowInput: true,
                                                                            onChange: function (
                                                                                selectedDates,
                                                                                dateStr,
                                                                                instance
                                                                            ) {
                                                                                handleChange("max_date", dateStr);
                                                                                validation.setFieldValue(
                                                                                    "max_date",
                                                                                    dateStr
                                                                                );
                                                                            },
                                                                            onClose: function (
                                                                                selectedDates,
                                                                                dateStr,
                                                                                instance
                                                                            ) {
                                                                                handleChange("max_date", dateStr);
                                                                                validation.setFieldValue(
                                                                                    "max_date",
                                                                                    dateStr
                                                                                );
                                                                            },
                                                                            locale: { firstDayOfWeek: 1 }
                                                                        }}
                                                                        value={intialData.max_date || ""}
                                                                    />
                                                                    {/* <span className="input-group-text">
                                                                        <span className="align-middle material-symbols-outlined fw-light fs-4">calendar_today</span>
                                                                    </span> */}
                                                                </div>
                                                            </Col>
                                                            <Col lg="3">
                                                                <div className="mb-3">
                                                                    <Label htmlFor="formrow-category_name-Input">
                                                                        {t("Duration")}
                                                                    </Label>
                                                                    <Select
                                                                        id="duration"
                                                                        name="duration"
                                                                        className="is-invalid"
                                                                        options={durationOptions}
                                                                        isSearchable={true}
                                                                        value={
                                                                            intialData.duration
                                                                                ? durationOptions?.find(
                                                                                    (option: any) =>
                                                                                        option.value == intialData.duration
                                                                                )
                                                                                : ""
                                                                        }
                                                                        onBlur={() =>
                                                                            validation.setFieldTouched("duration", true)
                                                                        }
                                                                        onChange={(e: any) => {
                                                                            handleChange("duration", e.value);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col lg="3">
                                                                <div>
                                                                    <div className="form-check form-check-primary mt-1">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            id="customCheckpassDate"
                                                                            name="disable_past_date"
                                                                            onBlur={validation.handleBlur}
                                                                            checked={intialData.disable_past_date === 1 ? true : false}
                                                                            onChange={(e: any) => {
                                                                                handleChangecheck("disable_past_date");
                                                                            }}
                                                                        />

                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="customCheckpassDate"
                                                                        >
                                                                            {t("Disable past dates")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="form-check form-check-primary mt-3">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            id="customCheckFutureDate"
                                                                            name="disable_future_date"
                                                                            onBlur={validation.handleBlur}
                                                                            checked={intialData.disable_future_date === 1 ? true : false}
                                                                            onChange={(e: any) => {
                                                                                handleChangecheck("disable_future_date");
                                                                            }}
                                                                        />

                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor="customCheckFutureDate"
                                                                        >
                                                                            {t("Disable future dates")}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    }
                                                    {
                                                        intialData.input_type == 1 && (
                                                            <Col lg="4" className="mb-3">
                                                                <Label>{t("Max length of input")}</Label>
                                                                <Input
                                                                    name="max_length"
                                                                    className="form-control"
                                                                    placeholder={t("Max length of input")}
                                                                    type="number"
                                                                    value={intialData.max_length}
                                                                    onChange={(e: any) => {
                                                                        handleChange("max_length", e.target.value);
                                                                    }}
                                                                />
                                                            </Col>
                                                        )
                                                    }
                                                    <Row>
                                                        <Col lg="4">
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-category_name-Input">
                                                                    {t("Display this question when")}
                                                                </Label>
                                                                <Select
                                                                    id="condition_question"
                                                                    name="condition_question"
                                                                    className="is-invalid"
                                                                    options={ConnectionQuestionOptions}
                                                                    isSearchable={true}
                                                                    value={
                                                                        intialData.condition_question
                                                                            ? ConnectionQuestionOptions?.find(
                                                                                (option: any) =>
                                                                                    option.value == intialData.condition_question
                                                                            )
                                                                            : ""
                                                                    }
                                                                    onBlur={() =>
                                                                        validation.setFieldTouched("condition_question", true)
                                                                    }
                                                                    onChange={(e: any) => {
                                                                        handleChange("condition_question", e.value);
                                                                        setQuetsionID(e.value);
                                                                    }}
                                                                />
                                                                {validation.touched.condition_question &&
                                                                    validation.errors.condition_question ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.condition_question}
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </div></Col>
                                                        <Col lg="4">

                                                            <div className="mb-3 ">
                                                                <Label htmlFor="formrow-category_name-Input">
                                                                    {t("Display this question when answer")}
                                                                </Label>
                                                                <Select
                                                                    id="condition_answer"
                                                                    name="condition_answer[]"
                                                                    className="is-invalid"
                                                                    //isMulti
                                                                    isMulti={(ConditonanswerType == "multiselect") ? true : false}
                                                                    options={relatedanswerOptions}
                                                                    value={relatedanswerValue}
                                                                    // value={
                                                                    //     intialData.condition_answer
                                                                    //         ? relatedanswerOptions.find(
                                                                    //             (option: any) =>
                                                                    //                 option.value == intialData.condition_answer
                                                                    //         )
                                                                    //         : ""
                                                                    // }
                                                                    onBlur={() =>
                                                                        validation.setFieldTouched("condition_answer", true)
                                                                    }
                                                                    onChange={(e: any) => {
                                                                        setRelatedanswerValue(e);
                                                                        handleChange("condition_answer", e);
                                                                    }}
                                                                />
                                                                {validation.touched.condition_answer &&
                                                                    validation.errors.condition_answer ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.condition_answer}
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>
                                                        {/* <Col lg="12" className="text-center mb-3">

                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="customCheck_or_condtion"
                                                    name="condition_or"
                                                    onChange={(e: any) => {
                                                        handleChange("condition_or", e.target.value);
                                                    }}
                                                    checked={intialData.condition_or}
                                                    onBlur={validation.handleBlur}
                                                />

                                                <label
                                                    className="form-check-label"
                                                    htmlFor="customCheck_or_condtion"
                                                >
                                                    OR (question will display either of the two conditions)
                                                </label>
                                            </Col>
                                            <Col lg="6">
                                                <div className="mb-3">
                                                    <Label htmlFor="formrow-category_name-Input">
                                                        2. Display this question when
                                                    </Label>
                                                    <Select
                                                        id="condition_question_2"
                                                        name="condition_question_2"
                                                        className="is-invalid"
                                                        options={ConnectionQuestionOptions}
                                                        value={
                                                            intialData.condition_question_2
                                                                ? ConnectionQuestionOptions.find(
                                                                    (option: any) =>
                                                                        option.value == intialData.condition_question_2
                                                                )
                                                                : ""
                                                        }
                                                        onBlur={() =>
                                                            validation.setFieldTouched("condition_question_2", true)
                                                        }
                                                        onChange={(e: any) => {
                                                            handleChange("condition_question_2", e.value);
                                                            setQuetsionID2(e.value);
                                                        }}
                                                        isSearchable={true}
                                                    />
                                                    {validation.touched.condition_question_2 &&
                                                        validation.errors.condition_question_2 ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.condition_question_2}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div></Col>
                                            <Col lg="6">

                                                <div className="mb-3 ">
                                                    <Label htmlFor="formrow-category_name-Input">
                                                        2.  Display this question when answer
                                                    </Label>
                                                    <Select
                                                        id="condition_answer_2"
                                                        name="condition_answer_2[]"
                                                        className="is-invalid"
                                                        //isMulti
                                                        isMulti={(ConditonanswerType2 == "multiselect") ? true : false}
                                                        options={relatedanswerOptions}
                                                        value={
                                                            intialData.condition_answer_2
                                                                ? relatedanswerOptions.find(
                                                                    (option: any) =>
                                                                        option.value == intialData.condition_answer_2
                                                                )
                                                                : ""
                                                        }
                                                        onBlur={() =>
                                                            validation.setFieldTouched("condition_answer_2", true)
                                                        }
                                                        onChange={(e: any) => {
                                                            handleChange("condition_answer_2", e.value);
                                                        }}
                                                    />
                                                    {validation.touched.condition_answer_2 &&
                                                        validation.errors.condition_answer_2 ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.condition_answer_2}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col> */}
                                                        <Col lg="4">
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-category_name-Input">
                                                                    {t("Display samepage which contains")}
                                                                </Label>
                                                                <Select
                                                                    id="same_page_with"
                                                                    name="same_page_with"
                                                                    className="is-invalid"
                                                                    options={SamePagequestionOptions}
                                                                    isSearchable={true}
                                                                    value={
                                                                        intialData.same_page_with
                                                                            ? SamePagequestionOptions?.find(
                                                                                (option: any) =>
                                                                                    option.value == intialData.same_page_with
                                                                            )
                                                                            : ""
                                                                    }
                                                                    onBlur={() =>
                                                                        validation.setFieldTouched("same_page_with", true)
                                                                    }
                                                                    onChange={(e: any) => {
                                                                        handleChange("same_page_with", e.value);
                                                                    }}
                                                                />
                                                                {validation.touched.same_page_with &&
                                                                    validation.errors.same_page_with ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.same_page_with}
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </div></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col lg="4">
                                                            <div className="mb-3">
                                                                <Label htmlFor="formrow-category_name-Input">
                                                                    {t("Map data")}
                                                                </Label>
                                                                <Select
                                                                    id="map_field_name"
                                                                    name="map_field_name"
                                                                    className="is-invalid"
                                                                    options={mapUserFieldOptions}
                                                                    styles={colorStyles}
                                                                    isSearchable={true}
                                                                    value={mapUserFieldValue}
                                                                    onBlur={() =>
                                                                        validation.setFieldTouched("map_field_name", true)
                                                                    }
                                                                    onChange={(e: any) => {
                                                                        handleChangemap(e.type, e.table);
                                                                        handleChange("map_field_name", e.value);
                                                                        setMapUserFieldValue(e);
                                                                    }}
                                                                />
                                                                {validation.touched.map_field_name &&
                                                                    validation.errors.map_field_name ? (
                                                                    <FormFeedback type="invalid">
                                                                        {validation.errors.map_field_name}
                                                                    </FormFeedback>
                                                                ) : null}
                                                            </div></Col>
                                                        {!!inputFile &&
                                                            <Col lg="4">
                                                                <div className="mb-3">
                                                                    <Label htmlFor="formrow-category_name-Input">
                                                                        {t("File type")}
                                                                    </Label>
                                                                    <Select
                                                                        id="file_types"
                                                                        name="file_types"
                                                                        className="is-invalid"
                                                                        isMulti={true}
                                                                        options={fileTypesOptions}
                                                                        isSearchable={true}
                                                                        value={
                                                                            intialData.file_types.length
                                                                                ? fileTypesOptions.filter((option: any) =>
                                                                                    intialData.file_types?.includes(option.value)
                                                                                )
                                                                                : null
                                                                        }
                                                                        onBlur={() =>
                                                                            validation.setFieldTouched("file_types", true)
                                                                        }
                                                                        onChange={(e: any) => {
                                                                            handleChange(
                                                                                "file_types",
                                                                                e?.map((i: any) => i.value)
                                                                            );
                                                                        }}
                                                                    />
                                                                </div></Col>
                                                        }
                                                        {!!inputPercentage &&
                                                            <Col lg="4">
                                                                <div className="mb-3">
                                                                    <Label htmlFor="formrow-category_name-Input">
                                                                        {t("Dependent percentage field")}
                                                                    </Label>
                                                                    <Select
                                                                        id="perentage_depend_id"
                                                                        name="perentage_depend_id"
                                                                        className="is-invalid"
                                                                        options={percentQuestion}
                                                                        isSearchable={true}
                                                                        value={
                                                                            intialData.perentage_depend_id
                                                                                ? percentQuestion?.find(
                                                                                    (option: any) =>
                                                                                        option.value == intialData.perentage_depend_id
                                                                                )
                                                                                : ""
                                                                        }
                                                                        onBlur={() =>
                                                                            validation.setFieldTouched("perentage_depend_id", true)
                                                                        }
                                                                        onChange={(e: any) => {
                                                                            handleChange("perentage_depend_id", e.value);
                                                                        }}
                                                                    />
                                                                    {validation.touched.perentage_depend_id &&
                                                                        validation.errors.perentage_depend_id ? (
                                                                        <FormFeedback type="invalid">
                                                                            {validation.errors.perentage_depend_id}
                                                                        </FormFeedback>
                                                                    ) : null}
                                                                </div></Col>
                                                        }
                                                        <Col lg="3">
                                                            <div className="form-check form-check-primary mb-3 mt-4">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id="customCheckother"
                                                                    name="condition_to_other"
                                                                    onChange={(e: any) => {
                                                                        handleChangecheck("condition_to_other");
                                                                    }}

                                                                    checked={intialData.condition_to_other == 1}
                                                                    onBlur={validation.handleBlur}
                                                                />

                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="customCheckother"
                                                                >
                                                                    {t("Add condition to other tabs")}
                                                                </label>

                                                            </div>

                                                        </Col>
                                                    </Row>
                                                </Row>
                                            }
                                            <ModalFooter>
                                                <Button
                                                    color="primary"
                                                    className="btn-block mx-2"
                                                    type="submit"
                                                    disabled={loading}
                                                    onClick={(e: any) => {
                                                        e.preventDefault();
                                                        validation.handleSubmit();
                                                        return false;
                                                    }}
                                                >
                                                    {loading ? (
                                                        <span>
                                                            <i className="fa-solid fa-spinner fa-spin"></i>
                                                            <span>&nbsp; {t("Please wait...")}</span>
                                                        </span>
                                                    ) : (
                                                        <span>{t("Submit")}</span>
                                                    )}
                                                </Button>
                                                <Link className="btn btn-block btn-outline-danger" to={"/masters/questionnaire-questions"}>
                                                    {t("Cancel")}
                                                </Link>
                                            </ModalFooter>
                                        </Form>
                                    </Col>
                                </Row>}
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment >
    );
};
export default QuestionsForm;