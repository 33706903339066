import { takeEvery, call, put } from "redux-saga/effects";
import tasksKanbanService from "../../services/kanban.tasks.service";
import { AnyAction } from "redux";

// Tasks Redux States
import {
  GET_KANBAN_TASK,
  GET_KANBAN_TASKS_LIST,
  CREATE_KANBAN_TASK,
  UPDATE_KANBAN_TASK,
  DELETE_KANBAN_TASK,
  GET_KANBAN_SEARCH,
  GET_TASK_ALLOCATION_LIST,
  GET_TASK_ALLOCATION
} from "./actionTypes";

import { 
    getKanbanTaskSuccess, 
    getKanbanTaskError,
    
    getKanbanTasksListSuccess,
    getKanbanTasksListError,

    createKanbanTaskSuccess,
    createKanbanTaskError,

    updateKanbanTaskSuccess,
    updateKanbanTaskError,

    deleteKanbanTaskSuccess,
    deleteKanbanTaskError,
    getKanbanSearchSuccess,
    getKanbanSearchError,

    getTaskAllocationSuccess,
    getTaskAllocationError,

    getTaskAllocationListError,
    getTaskAllocationListSuccess,

} from "./actions";

function* getKanbanTask({ payload: { tasks, history } }: AnyAction): any {
  try {
    const response = yield call(tasksKanbanService.getKanbanTask, tasks);
    if (response.data && response.data.status === "success") {
      yield put(getKanbanTaskSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getKanbanTaskError(response.data));
    } else {
      yield put(getKanbanTaskError(response));
    }
  } catch (error: any) {
    yield put(getKanbanTaskError(error));
  }
}

function* getKanbanTasksList({ payload: { query, history } }: AnyAction): any {
  try {
    const response = yield call(tasksKanbanService.getKanbanTasksList, query);
    if (response.data && response.data.status === "success") {
      yield put(getKanbanTasksListSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getKanbanTasksListError(response.data));
    } else {
      yield put(getKanbanTasksListError(response));
    }
  } catch (error: any) {
    yield put(getKanbanTasksListError(error));
  }
}

function* createKanbanTask({ payload: { tasks, history } }: AnyAction): any {
  try {
    const response = yield call(tasksKanbanService.createKanbanTask, tasks);
    if (response.data && response.data.status === "success") {
      yield put(createKanbanTaskSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(createKanbanTaskError(response.data));
    } else {
      yield put(createKanbanTaskError(response));
    }
  } catch (error: any) {
    yield put(createKanbanTaskError(error));
  }
}

function* updateKanbanTask({ payload: { tasks, history } }: AnyAction): any {
  try {
    const response = yield call(tasksKanbanService.updateKanbanTask, tasks);
    if (response.data && response.data.status === "success") {
      yield put(updateKanbanTaskSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(updateKanbanTaskError(response.data));
    } else {
      yield put(updateKanbanTaskError(response));
    }
  } catch (error: any) {
    yield put(updateKanbanTaskError(error));
  }
}

function* deleteKanbanTask({ payload: { tasks, history } }: AnyAction): any {
  try {
    const response = yield call(tasksKanbanService.deleteKanbanTask, tasks);
    if (response.data && response.data.status === "success") {
      yield put(deleteKanbanTaskSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(deleteKanbanTaskError(response.data));
    } else {
      yield put(deleteKanbanTaskError(response));
    }
  } catch (error: any) {
    yield put(deleteKanbanTaskError(error));
  }
}

function* kanbanSearch({ payload: { tasks, history } }: AnyAction): any {
  try {
    const response = yield call(tasksKanbanService.getKanbanSearch, tasks);
    if (response.data && response.data.status === "success") {
      yield put(getKanbanSearchSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getKanbanSearchError(response.data));
    } else {
      yield put(getKanbanSearchError(response));
    }
  } catch (error: any) {
    yield put(getKanbanSearchError(error));
  }
}


function* getTaskAllocationByID({ payload: { tasks, history } }: AnyAction): any {
  try {
    const response = yield call(tasksKanbanService.getTaskAllocationByID, tasks);
    if (response.data && response.data.status === "success") {
      yield put(getTaskAllocationSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getTaskAllocationError(response.data));
    } else {
      yield put(getTaskAllocationError(response));
    }
  } catch (error: any) {
    yield put(getTaskAllocationError(error));
  }
}

function* getTaskAllocationList({ payload: { tasks, history } }: AnyAction): any {
  try {
    const response = yield call(tasksKanbanService.getTaskAllocationList, tasks);
    if (response.data && response.data.status === "success") {
      yield put(getTaskAllocationListSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getTaskAllocationListError(response.data));
    } else {
      yield put(getTaskAllocationListError(response));
    }
  } catch (error: any) {
    yield put(getTaskAllocationListError(error));
  }
}


function* tasksSaga(): any {
  yield takeEvery(GET_KANBAN_TASK, getKanbanTask);
  yield takeEvery(GET_KANBAN_TASKS_LIST, getKanbanTasksList);
  yield takeEvery(CREATE_KANBAN_TASK, createKanbanTask);
  yield takeEvery(UPDATE_KANBAN_TASK, updateKanbanTask);
  yield takeEvery(DELETE_KANBAN_TASK, deleteKanbanTask);
  yield takeEvery(GET_KANBAN_SEARCH, kanbanSearch);
  yield takeEvery(GET_TASK_ALLOCATION_LIST, getTaskAllocationList);
  yield takeEvery(GET_TASK_ALLOCATION, getTaskAllocationByID);

}

export default tasksSaga;
