import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import DataTable from '../../components/Common/DataTable';
import Breadcrumbs from '../../components/Layout/Breadcrumb';
import { getDataTableQuery, getUserProfile } from '../../helpers/functions_helper';
import { AlertAction, QuestionnaireTypeAction } from '../../store/actions';
import Drawer from "../../components/Drawer";
import EditCompany from '../Masters/Company/EditCompany';
import EditUser from '../Users/EditUser';
const breadcrumbItems = [
    {
        url: "/dashboard",
        label: "Dashboard",
        active: false,
    },
    {
        url: "#",
        label: "Alerts",
        active: true,
    },
];
const Alert = (props: any) => {

    const dispatch = useDispatch();
    const userProfile = getUserProfile();
    const { t } = useTranslation();
    document.title = "Alerts | All alerts | " + process.env.REACT_APP_NAME;


    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [filters, setFilters] = useState({
        is_filtered: false,
        user_type: 0,
        year: "",
    });
    const [loadingtablistData, setLoadingtablistData] = useState(true);
    const handleFiltersChange = (obj: any) => {
        setFilters((state: any) => ({ ...state, ...obj }));
    };
    const [previewTitle, setPreviewTitle] = useState("Preview questions");

    const handleApplyFilters = () => {
        setLoadingtablistData(true);
        setOpenFilter(false);
    };

    const [openCompany, setOpenCompany] = useState<boolean>(false);
    const [openUser, setOpenUser] = useState<boolean>(false);
    const [dataval, setData] = useState<any>([]);

    const handleCompanyClick = (data: any) => {
        setData(data);
        setOpenCompany(true);
    }

    const handleUserClick = (data: any) => {
        setData(data);
        setOpenUser(true);
    }

    // DataTable ----------------------------------------------
    function renderHeaderHTML(column: any, colIndex: any) {
        return <span dangerouslySetInnerHTML={{ __html: column.text }} />;
    }
    function renderRowHTML(rowContent: any, row: any) {
        return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
    }



    const columns = [
        {
            dataField: "DT_RowIndex",
            text: "Sr. No.",
            sort: true,
            search: false,
            classes: 'fix-col-left',
            headerClasses: 'fix-col-left',
            headerStyle: { width: '155px' },
            style: { width: '155px' },
        },
        {
            dataField: "company_name_value",
            text: `${t('Company')}`,
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => {
                if (row.company_id && !(userProfile.IS_NON_TC_USER && !userProfile.IS_COMPANY_HR)) {
                    return (
                        <div className="link-primary" onClick={() => handleCompanyClick(row)}>
                            {row.company_name_value}
                        </div>
                    );
                } else {
                    return row.company_name_value;
                }
            },
        },
        {
            dataField: "user_value",
            text: `${t('Name of the employee')}`,
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => {
                return (
                    <div className="link-primary" onClick={() => handleUserClick(row)}>
                        {row.first_name} {row.last_name}
                    </div>
                );
            },
        },

        {
            dataField: "type",
            text: "Type",
            sort: true,
            search: true,
            formatter: renderRowHTML,
        },
        {
            dataField: "due_date_value",
            text: "Due date",
            sort: true,
            search: true,
            formatter: renderRowHTML,
        },
        {
            dataField: "expiry_date_value",
            text: "Expiry date",
            sort: true,
            search: true,
        },
    ];


    //get department list
    const [datatableOptions, setDatatableOptions] = useState({
        draw: 1,
        page: 1,
        recordsTotal: 0,
        recordsFiltered: 0,
        sizePerPage: 20,
        searchText: "",
        sortField: "id",
        sortOrder: "desc",
    });

    const [tablistData, settablistData] = useState<Array<any>>([]);



    // get store data
    let data = useSelector((state: any) => ({
        success: state.Alert.success,
        alertlist: state.Alert.alertlist,
        error: state.Alert.error,
    }));



    useEffect(() => {
        if (data.alertlist?.data) {
            settablistData(data.alertlist.data);

            const updatedDatatableOptions = {
                ...datatableOptions,
                recordsTotal: data.alertlist.recordsTotal,
                recordsFiltered: data.alertlist.recordsFiltered,
            };
            setDatatableOptions(updatedDatatableOptions);

            setLoadingtablistData(false);
        }
    }, [data.alertlist]);

    const onTableChange = (type: any, newState: any) => {
        let draw = datatableOptions.draw + 1;
        let page = 1;
        if (type == "search") {
            page = 1;
        } else {
            page = newState.page;
        }

        const updatedDatatableOptions = {
            ...datatableOptions,
            draw: draw,
            page: page,
            sizePerPage: newState.sizePerPage,
            searchText: newState.searchText,
            sortField: newState.sortField,
            sortOrder: newState.sortOrder,
            scrollY: "300px",
            scrollX: true,
            scrollCollapse: true,
            fixedColumns: {
                left: 1,
                right: 1
            }
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingtablistData(true);
    };


    const clearFilter = () => {
        const updatedDatatableOptions = {
            ...datatableOptions,
            draw: 1,
            page: 1,
            sizePerPage: 20,
            searchText: "",
            sortField: "id",
            sortOrder: "desc",
        };
        setDatatableOptions(updatedDatatableOptions);

        const filter = {
            ...filters,
            is_filtered: false,
            user_type: 0,
            year: "",
        }
        setFilters(filter);

        setLoadingtablistData(true);
    };
    // Datatable -------------------------------------------------


    useEffect(() => {
        if (data.success !== "") {
            toast(data.success, { type: toast.TYPE.SUCCESS })
            setLoadingtablistData(true);
        }
        if (data.error !== "") {
            toast(data.error, { type: toast.TYPE.ERROR })
        }
    }, [data.success, data.error, dispatch]);

    useEffect(() => {
        if (loadingtablistData === true) {
            dispatch(
                AlertAction.getAlerts(
                    { ...getDataTableQuery(datatableOptions, columns), filters },
                    props.history
                )
            );
        }
    }, [loadingtablistData]);


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        breadcrumbTitle="Alert"
                        breadcrumbItems={breadcrumbItems}
                    />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <DataTable
                                        columns={columns}
                                        products={tablistData}
                                        datatableOptions={datatableOptions}
                                        onTableChange={onTableChange}
                                        loadingListData={loadingtablistData}
                                        tableName="tbl_master_questionnaire_tab"
                                        dependentField=""
                                        dependentId=""
                                        clearFilter={clearFilter}
                                        settingTableName="tbl_master_questionnaire_type"
                                        slug="all-questionnaires"
                                        customButtons={
                                            <Button
                                                outline
                                                color={filters.is_filtered === true ? "secondary" : "light"}
                                                onClick={() => setOpenFilter((state) => !state)}
                                            >
                                                {filters.is_filtered === false ? (
                                                    <span className="align-middle material-symbols-outlined fw-light fs-4">filter_alt</span>
                                                ) : (
                                                    <span className="align-middle material-symbols-outlined fw-light fs-4">filter_alt_off</span>
                                                )}
                                            </Button>
                                        } />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {dataval?.company_details?.id ?
                <Drawer
                    title={t('Company')}
                    open={openCompany}
                    onClose={() => { setOpenCompany((state:any) => !state); setLoadingtablistData(true); }}
                    fullWidth
                >
                    <EditCompany id={dataval?.company_details?.id} company_id={dataval?.company_details?.company_id} type={1} isWindow={true} />
                </Drawer>
                :null
            }
            {dataval?.user?.id ?
                <Drawer
                    title={t('User')}
                    open={openUser}
                    onClose={() => { setOpenUser((state:any) => !state); setLoadingtablistData(true); }}
                    fullWidth
                >
                    <EditUser id={dataval?.user?.id} user_id={dataval?.user?.user_id} type={1} isWindow={true} />
                </Drawer>
                :null
            }
        </React.Fragment>
    )
}

export default Alert