import React, { FC, useEffect, useState } from 'react'
import { Col, Input, Label, Row } from 'reactstrap';

import Select from "react-select";
import commonService from '../../services/common.service';
import { addAllUsersInUsers, addIndividualInCompany, getSelectOptions, getUserProfile } from '../../helpers/functions_helper';
import { useTranslation } from 'react-i18next';
import Flatpickr from '../../components/Form/Flatpickr';
import { useSelector } from 'react-redux';
type FiltersProps = {
    filters: any;
    onApply: any;
    onClear: any;
    onChange?: any;
}

const ExpiryFilters: FC<FiltersProps> = ({ filters, onApply, onClear, onChange }) => {

    const userProfile = getUserProfile("all-users");
    const { t } = useTranslation();

    const [companyOption, setCompanyOption] = useState([]);
    const [loadingCompany, setloadingCompanyOption] = useState(true);
    const [companyID, setCompanyID] = useState();

    /* set questionnaire type */
    const typeOption = [
        {
            "value": "user_end_date",
            "label": t("User end date")
        },
        {
            "value": "mutuality_exp_date",
            "label": t("Mutality expiry date")
        },
        {
            "value": "tax_claim_due_date",
            "label": t("Tax claim due date")
        },
        {
            "value": "tax_regime_external_due_date_original",
            "label": t("Tax regime due date")
        },
        {
            "value": "tax_regime_end_date_original",
            "label": t("Tax regime end date")
        },
        {
            "value": "single_permit_end_date",
            "label": t("Single permit end date")
        },
        {
            "value": "single_permit_due_date",
            "label": t("Single permit due date")
        },
        {
            "value": "residence_card_exp_date",
            "label": t("Residence card expiry date")
        },
        {
            "value": "residence_card_due_date",
            "label": t("Residence card due date")
        },
        {
            "value": "passport_exp_date",
            "label": t("Passport expiry date")
        },
        {
            "value": "passport_due_date",
            "label": t("Passport due date")
        },
        {
            "value": "tax_on_web_expiry_date",
            "label": t("Tax on web expiry date")
        },
        {
            "value": "tax_on_web_due_date",
            "label": t("Tax on web due date")
        },
        {
            "value": "annexure_46_exp_date",
            "label": t("Annexture exp date")
        },

        {
            "value": "annexure_46_due_date",
            "label": t("Annexture due date")
        }
    ];

    const [query, setQuery] = useState("");
  

    let { getCompanyOptionsList, loading } = useSelector((state: any) => ({
        getCompanyOptionsList: state.Common.GET_COMPANY_OPTIONS_LIST_SUCCESS,
        loading: state.Common.loading,
      }));
    

    useEffect(() => {
        if (getCompanyOptionsList.data) {
          const item = addIndividualInCompany(getCompanyOptionsList.data) as any;
          if (userProfile.CAN_VIEW_ALL) {
            setCompanyOption(item);
          } else if (!userProfile.CAN_VIEW_ALL && userProfile.CAN_VIEW) {
            getSelectOptions("tbl_master_company", "company_name", '', ["created_by"]).then(
              function (options) {
                const opt = options.filter((opt: any) => (opt.created_by === userProfile.USER_ID || opt.value === userProfile.USER_COMPANY));
                setCompanyOption(opt);
              });
          } else {
            setCompanyOption([]);
          }
    
          setloadingCompanyOption(false);
        }
      }, [getCompanyOptionsList]);


    const [loadingUsers, setLoadingUsers] = useState(true);
    const [usersOptions, setUsersOptions] = useState([]);
    const getDependentSelectOptions = async (
        tableName: string,
        fieldName: string,
        whereField: string,
        whereID: number
    ) => {
        const response = await commonService.getDependentSelectOptionsList(
            tableName,
            fieldName,
            whereField,
            whereID,
            query ? query : ""
        );
        return response.data.data;
    };

    useEffect(() => {
        if (filters.company_id!="") {
            getDependentSelectOptions("tbl_master_users", "first_name", "company", filters.company_id).then(function (
                options
            ) {
                const item = addAllUsersInUsers(options) as any;
                setUsersOptions(item);
                setLoadingUsers(false);
            });
        }else {
            const company_id = userProfile.USER_COMPANY;
            getDependentSelectOptions("tbl_master_users", "first_name", "company", company_id).then(function (
                options
            ) {
                const item = addAllUsersInUsers(options) as any;
                setUsersOptions(item);
                setLoadingUsers(false);
            });
        }
    }, [filters.company_id]);

    return (
        <React.Fragment>
            {!!userProfile.IS_COMPANY_ADMIN &&
                <Row>
                    <Col>
                        <div className="mb-3">
                            <Label className="form-label">
                                {t("Company")}
                            </Label>
                            {loadingCompany ? <Input id={`ph_cmp`} disabled value={'Loading...'} /> :
                                <Select
                                    id="company_id"
                                    name="company_id"
                                    className="is-invalid"
                                    options={companyOption}
                                    //@ts-ignore
                                    defaultValue={companyOption.find((o: any) => o.value === filters?.company_id)}
                                    isSearchable={true}
                                    isDisabled={loadingCompany}
                                    onChange={(option: any) => {
                                        onChange({ is_filtered: true, company_id: option.value })
                                        setCompanyID(option.value);
                                    }}
                                />}
                        </div>
                    </Col>
                </Row>
            }
            <Row>
                <Col>
                    <div className="mb-3">
                        <Label className="form-label">
                            {t("User")}
                        </Label>
                        <Select
                            options={usersOptions}
                            value={
                                filters?.user_id
                                    ? usersOptions.find((option: any) => option.value == filters?.user_id)
                                    : ""
                            }
                            isSearchable={true}
                            isDisabled={(loadingUsers || filters.company_id == "") ? true : false}
                            isLoading={loadingUsers}
                            onChange={(option: any) => {
                                onChange({ is_filtered: true, user_id: option.value });
                            }}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="mb-3">
                        <Label className="form-label">
                            {t("Type")}{" "}
                        </Label>
                        <Select
                            id="type"
                            name="type"
                            options={typeOption}
                            //@ts-ignore
                            defaultValue={typeOption.find((o: any) => o.value === filters?.type)}
                            isSearchable={true}
                            onChange={(option: any) => {
                                onChange({ is_filtered: true, type: option.value })
                            }}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label>{t("From")}</Label>
                    <div className="input-group is-invalid">
                        <Flatpickr
                            id="start_date"
                            name="start_date"
                            className="form-control d-block"
                            type="text"
                            value={filters?.start_date}
                            placeholder="DD/MM/YYYY"
                            options={{
                                altInput: true,
                                altFormat: "d/m/Y",
                                dateFormat: "Y-m-d H:i:S",
                                defaultDate: filters.start_date,
                                allowInput: true,
                                onChange: function (selectedDates, dateStr, instance) {
                                    onChange({
                                        is_filtered: true,
                                        start_date: dateStr,
                                    });
                                },
                                locale: { firstDayOfWeek: 1 },
                            }}
                        />
                    </div>
                </Col>
                <Col>
                    <Label>{t("To")}</Label>
                    <div className="input-group is-invalid">
                        <Flatpickr
                            id="end_date"
                            name="end_date"
                            className="form-control d-block"
                            type="text"
                            value={filters?.end_date}
                            placeholder="DD/MM/YYYY"
                            options={{
                                altInput: true,
                                altFormat: "d/m/Y",
                                dateFormat: "Y-m-d H:i:S",
                                defaultDate: filters.end_date,
                                allowInput: true,
                                onChange: function (selectedDates, dateStr, instance) {
                                    onChange({
                                        is_filtered: true,
                                        end_date: dateStr,
                                    });
                                },
                                locale: { firstDayOfWeek: 1 },
                            }}
                        />
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default ExpiryFilters