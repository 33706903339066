import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import DataTable from '../../../components/Common/DataTable';
import DeleteModal from '../../../components/Common/DeleteModal';
import Breadcrumbs from '../../../components/Layout/Breadcrumb';
import { getDataTableQuery, getUserProfile } from '../../../helpers/functions_helper';
import { dateFormat } from '../../../utils/formats';
import { deleteTemplate, getAllDocumentTemplates } from '../DocSignature/helper';
import fileDownload from 'js-file-download';
import commonService from '../../../services/common.service';
import { Buffer } from "buffer";
import TemplateListModel from '../DocSignature/TemplateListModal';
import { getAsset, prepareAssets } from './utils/prepareAssets';
import { Pdf, usePdf } from './hooks/usePdf';
import { base64ToBlob, ggID } from './utils/helpers';
import { UploadTypes, useUploader } from './hooks/useUploader';
import { useAttachments } from './hooks/useAttachments';
import { blobToFiles } from '../../../components/Common/Pdftotext';
import axios from 'axios';
import { yesNoTag } from '../../../utils/utils';
const DocTemplateList = ({ loadingData = false }) => {
    const { t } = useTranslation();
    const userObj = getUserProfile();
    let navigate = useNavigate();
    const [showTemplateModel, setShowTemplateModel] = useState(false);
    const toastId = React.useRef(null) as any;
    const moduleHeading = t('Document template')
    document.title = `${moduleHeading} | ` + process.env.REACT_APP_NAME;
    const breadcrumbItems = [
        {
            url: "/dashboard",
            label: t("Dashboard"),
            active: false,
        },
        {
            url: "#",
            label: moduleHeading,
            active: true,
        },
    ];
    const [loadingListData, setLoadingListData] = useState<boolean>(true);
    const [deleteRec, setDeleteRec] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<any>();
    const [datatableOptions, setDatatableOptions] = useState({
        draw: 1,
        page: 1,
        recordsTotal: 0,
        recordsFiltered: 0,
        sizePerPage: 10,
        searchText: "",
    });
    const [list, setList] = useState([]);
    useEffect(() => {
        prepareAssets();
    }, [])

    function renderRowHTML(rowContent: any, row: any) {
        return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
      }
    const columns =[
        {
            dataField: "DT_RowIndex",
            text: t("Sr. No."),
            sort: false,
            search: false,
        },
        {
            dataField: "document_name",
            text: t("Template name"),
            sort: true,
            search: true,
            classes: 'table-cell-text-wrap',
            formatter: (rowContent: any, row: any): any => (
                <Link to={`/client-onboarding/edit-template/${row.id}/${row.template_id}`}>
                    {rowContent}
                </Link>
            )
        },
        {
            dataField: "document_type",
            text: t("Document type"),
            sort: true,
            search: true,
        },
        {
            dataField: "toggle",
            text: t("HTML content"),
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => {
                return renderRowHTML(
                  yesNoTag(row?.toggle, row?.toggle === 0 ? t("Yes") : t("No")),
                  null
                );
              },
        },
        {
            dataField: "created_by_value",
            text: t("Created by"),
            sort: true,
            search: true,
            //formatter: (rowContent: any, row: any): any => (row.created_by_value)
        },
        {
            dataField: "created_at_value",
            text: t("Created on"),
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => dateFormat(row.created_at)
        },
        {
            dataField: "updated_by_value",
            text: t("Updated by"),
            sort: true,
            search: true,
           // formatter: (rowContent: any, row: any): any => (row.updated_by_value)
        },
        {
            dataField: "updated_at_value",
            text: t("Updated on"),
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => dateFormat(row.updated_at)
        },
        {
            dataField: "action",
            text: t("Action"),
            sort: false,
            search: false,
            width: 200,
            formatter: (rowContent: any, row: any): any => {
                return (
                    <div className="d-flex gap-2 text-end">
                        <Link
                            className="btn btn-pill btn-outline-primary btn-sm"
                            to={`/client-onboarding/edit-template/${row.id}/${row.template_id}`}
                            //to={row.doc_exist ? "#" : `/client-onboarding/edit-template/${row.id}/${row.template_id}`}
                            //onClick={() => onEditClick(row)}
                        >
                            <i className="fa-regular fa-pen-to-square"></i> {t("Edit")}
                        </Link>
                        <Link
                            className="btn btn-pill btn-outline-danger btn-sm"
                            to="#"
                            onClick={() => onDeleteClick(row)}
                        >
                            <i className="fa-regular fa-trash-can"></i> {t("Delete")}
                        </Link>
                        <Link
                            className="btn btn-pill btn-outline-primary btn-sm"
                            to="#"
                            onClick={() => handleSingleDownload(row)}
                        >
                            <i className="fa-regular fas-download"></i> {t("Download")}
                        </Link>
                    </div>
                );
            },
        },
    ];

    useEffect(() => {
        setLoadingListData(true);
        setList([]);
      }, [localStorage.getItem("I18N_LANGUAGE")])
    

    const onTableChange = (type: any, newState: any) => {
        let draw = datatableOptions.draw + 1;
        let page = 1;
        if (type == "search") {
            page = 1;
        } else {
            page = newState.page;
        }
        const updatedDatatableOptions = {
            ...datatableOptions,
            draw: draw,
            page: page,
            sizePerPage: newState.sizePerPage,
            searchText: newState.searchText,
            sortField: newState.sortField,
            sortOrder: newState.sortOrder,
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingListData(true);
    };

    const clearFilter = () => {
        const updatedDatatableOptions = {
            ...datatableOptions,
            draw: 1,
            page: 1,
            sizePerPage: 10,
            searchText: "",
            sortField: "id",
            sortOrder: "desc",
        };
        setDatatableOptions(updatedDatatableOptions);

        setLoadingListData(true);
    };

    const getList = async () => {
        const res = await getAllDocumentTemplates({ ...getDataTableQuery(datatableOptions, columns) });
        if (res) {
            setList(res.data.data);
            const updatedDatatableOptions = {
                ...datatableOptions,
                recordsTotal: res.data.recordsTotal,
                recordsFiltered: res.data.recordsFiltered,
            };
            setDatatableOptions(updatedDatatableOptions);
            setLoadingListData(false);
        }
    }

    const onEditClick = (row: any) => {
        if (row.doc_exist) {
            toast.error(t("Template canot edit as document related to template is already sent to user."));
        }
    }

    const onDeleteClick = (row: any) => {
        // if (row.doc_exist) {
        //     toast.error(t("Template canot delete as document related to template is already sent to user."));
        // } else {
            setDeleteRec(true)
            setSelectedRow(row);
        //}
    }

    const handleDelete = async (id: number) => {
        const res = await deleteTemplate(id);
        if (res) {
            setDeleteRec(false);
            setLoadingListData(true);
        }
    }

    const { initialize, savePdfFromList } = usePdf();
    const initializePageAndAttachments = (pdfDetails: Pdf) => {
        if (pdfDetails) {
            initialize(pdfDetails);
            const numberOfPages = pdfDetails.pages.length;
            resetAttachments(numberOfPages);
        }
    };
    const { reload: reloadPdf } = useUploader({
        use: UploadTypes.PDF,
        afterUploadPdf: initializePageAndAttachments,
    });
    const {  reset: resetAttachments } = useAttachments();
   
    const handleSingleDownload = async (row: any) => {
        const result = await commonService.downloadAttachment({ id: Buffer.from(String(row.attach_id)).toString("base64"), attachment_id: Buffer.from(String(row.attach_sub_id)).toString("base64") });
        if (result) {
            fileDownload(result.data, row.document_name + "." + row.file_extension);
            toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: t('Template downloaded successfully'), autoClose: 5000, isLoading: false, delay: 500 });
        } else {
            toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: t('Template not downloaded successfully'), autoClose: 5000, isLoading: false, delay: 500 });
        }
    }

    // const handleSingleDownload = async (row: any) => {
    //     if (row.toggle) {
    //         const attach = row.attachments;
    //         if (attach && attach.length) {
    //             console.log(row.attach_id, row.attach_sub_id, row.file_extension );
    //             handleUploadDocument(row ).then((file)=>{
    //                 console.log(file);
    //                 //downloadImage(attach)
    //             });
    //         } else {
    //             const result = await commonService.downloadAttachment({ id: Buffer.from(String(row.attach_id)).toString("base64"), attachment_id: Buffer.from(String(row.attach_sub_id)).toString("base64") });
    //             if (result) {
    //                 fileDownload(result.data, row.document_name + "." + row.file_extension);
    //                 toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: t('Template downloaded successfully'), autoClose: 5000, isLoading: false, delay: 500 });
    //             } else {
    //                 toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: t('Template not downloaded successfully'), autoClose: 5000, isLoading: false, delay: 500 });
    //             }
    //         }
    //     } else {
    //         const result = await commonService.downloadAttachment({ id: Buffer.from(String(row.attach_id)).toString("base64"), attachment_id: Buffer.from(String(row.attach_sub_id)).toString("base64") });
    //         if (result) {
    //             fileDownload(result.data, row.document_name + "." + row.file_extension);
    //             toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: t('Template downloaded successfully'), autoClose: 5000, isLoading: false, delay: 500 });
    //         } else {
    //             toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: t('Template not downloaded successfully'), autoClose: 5000, isLoading: false, delay: 500 });
    //         }
    //     }

    // }


    const handleUploadDocument = async (row: any) => {
        const SERVER_URL = process.env.REACT_APP_SERVER_URL;
        const AUTH_USER = localStorage.getItem("authUser");
        const ACCESS_TOKEN = JSON.parse(String(AUTH_USER));
        await axios({
            url:
                SERVER_URL +
                "/common/download-attachment/" +
                Buffer.from(String(row.attach_id)).toString("base64") +
                "/" +
                Buffer.from(String(row.attach_sub_id)).toString("base64"),
            method: "GET",
            headers: {
                "Content-Type": "application/" + row.file_extension,
                "Access-Control-Allow-Origin": "*",
                Authorization: "Bearer " + ACCESS_TOKEN.accessToken,
            },
            responseType: "arraybuffer",
        }).then((response: any) => {
            if (response.data) {
                blobToFiles(response.data, 'my_file.pdf', 'application/pdf').then((file: any) => {
                    reloadPdf(file);
                    downloadImage(file,row.attachments);
                    //reloadPdf(file).then(()=> {return file;});
                });
            }
        });
    }

    const downloadImage = async (file: any,allPageAttachments: any) => {
        const attachear: any = [];
        allPageAttachments.forEach((element: any, indexelement: any) => {
            if (element.length) {
                attachear[indexelement] = [];

                element.forEach((ele: any, indexele: any) => {
                    const newEle = { ...ele };
                    if (ele.type === "image") {
                        const img = new Image();
                        img.src = ele.imgstr;
                        // img.onload = () => {

                        // };

                        //    createImageElementFromBase64(ele.imgstr).then((imgEl:any)=>{
                        //     newEle.img = img;
                        //    });
                        newEle.img = img;
                        newEle.file = base64ToBlob(ele.imgstr);
                        newEle.id = ggID();
                        delete newEle.imgstr;
                    }

                    attachear[indexelement][indexele] = newEle;
                });
            }
        });
        const download = await getAsset('download');
        await savePdfFromList(file,attachear).then((dt: any) => {
            download(dt, 'download.pdf', 'application/pdf');
        });
    }

    // useEffect(() => {
    //     setLoadingListData(loadingData);
    // }, [loadingData])

    useEffect(() => {
        if (loadingListData === true)
            getList();
    }, [loadingListData])


    const handleSelectedTemplate = (temp: any) => { };

    return (
        <React.Fragment>
            <div className={"page-content"}>
                <Container fluid>
                    <Breadcrumbs
                        breadcrumbTitle={moduleHeading}
                        breadcrumbItems={breadcrumbItems}
                        endAdornment={
                            <div className="d-flex gap-2 text-end">
                                {(userObj.USER_COMPANY !== 1) ? null : <>
                                    {/* <Link to="#" className={`btn btn-outline-secondary btn-label btn-sm`} onClick={() => setShowTemplateModel(true)}>
                                        <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                            list_alt
                                        </span>
                                        {t("Get templates")}
                                    </Link> */}
                                    <Link to="/client-onboarding/template/list" className={`btn btn-primary btn-sm waves-effect waves-light btn-label`}>
                                        <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                            list_alt
                                        </span>
                                        {t("List view")}
                                    </Link>
                                    <Link
                                        to="/client-onboarding/template/create"
                                        className="btn btn-outline-secondary btn-label btn-sm"
                                    >
                                        <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">add</span> {t(`Create document template`)}
                                    </Link>
                                </>
                                }
                            </div>
                        }

                    />

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <DataTable
                                        columns={columns}
                                        products={list}
                                        datatableOptions={datatableOptions}
                                        filters={[]}
                                        onTableChange={onTableChange}
                                        loadingListData={loadingListData}
                                        clearFilter={clearFilter}
                                    />
                                    {deleteRec && (
                                        <DeleteModal
                                            recordName={selectedRow?.document_name || ''}
                                            modalTitle={t("Document template")}
                                            show={deleteRec}
                                            onDeleteClick={() => handleDelete(selectedRow?.id || 0)}
                                            onCloseClick={() => setDeleteRec(false)}
                                            loading={false}
                                        />
                                    )}
                                    <TemplateListModel
                                        title={t("Template search")}
                                        show={showTemplateModel}
                                        selectTemplate={handleSelectedTemplate}
                                        onCloseClick={() => setShowTemplateModel(false)}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default DocTemplateList;