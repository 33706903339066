import React from 'react'
import { Col, Label, Row } from 'reactstrap';

const CheckboxMultiInput = (props: any) => {
    let { label, name, placeholder, options, value, handleChangeMulti, handleChangeMulticheckbox, disabled, answer_only, handleKeyDown, handleChange, tabnotnext, fullwidth, isMulti, isDisabled, field_width_input } = props;
    return (
        <div className={answer_only ? "checkbox_answer mt-3 d-flex flex-row" : "mt-3"}>
            <Label for="basicpill-lastname-input2">
                {label}
            </Label>
            {!!options &&
                <Row>
                    {
                        options.map((item: any, key: any) => (
                            <React.Fragment key={key}>

                                {answer_only && value.includes(item) &&
                                    <div key={key} >
                                        <div className="form-group" key={key}>
                                            <label className="container_check version_2">{item}
                                                <input
                                                    type={isMulti ? "checkbox" : "radio"}
                                                    name={name} value={item}
                                                    className="required" checked
                                                    disabled={isDisabled}
                                                />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                }
                                {Math.abs(key % 2) === 0 && !answer_only &&
                                    <div className={field_width_input ? `w-${field_width_input}` : ''} key={key}>
                                        <div className="form-group" key={key}>
                                            <label className="container_check version_2">{item}
                                                <input
                                                    type={isMulti ? "checkbox" : "radio"}
                                                    name={name}
                                                    value={item}
                                                    className="required"
                                                    defaultChecked={
                                                        value.includes(item)
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) => handleChangeMulticheckbox(e)}
                                                    disabled={isDisabled} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                }
                                {Math.abs(key % 2) === 1 && !answer_only &&
                                    <div className={field_width_input ? `w-${field_width_input}` : ''} key={key}>
                                        <div className="form-group" key={key}>
                                            <label className="container_check version_2">{item}
                                                <input type={isMulti ? "checkbox" : "radio"} name={name} value={item} className="required"
                                                    defaultChecked={
                                                        value.includes(item)
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={(e) => handleChangeMulticheckbox(e)}
                                                    disabled={isDisabled} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                }
                            </React.Fragment>
                        ))
                    }
                </Row>
            }
        </div>
    )
}

export default CheckboxMultiInput