import {
  GET_ACCESS_ROLE,
  GET_ACCESS_ROLE_SUCCESS,
  GET_ACCESS_ROLE_ERROR,

  GET_ACCESS_ROLES_LIST,
  GET_ACCESS_ROLES_LIST_SUCCESS,
  GET_ACCESS_ROLES_LIST_ERROR,

  CREATE_ACCESS_ROLE,
  CREATE_ACCESS_ROLE_SUCCESS,
  CREATE_ACCESS_ROLE_ERROR,

  UPDATE_ACCESS_ROLE,
  UPDATE_ACCESS_ROLE_SUCCESS,
  UPDATE_ACCESS_ROLE_ERROR,

  DELETE_ACCESS_ROLE,
  DELETE_ACCESS_ROLE_SUCCESS,
  DELETE_ACCESS_ROLE_ERROR,
} from "./actionTypes";

// ---------------------------------------------GET_ACCESS_ROLE
export const getAccessRole = (accessroles: any, history: any) => {
  return {
    type: GET_ACCESS_ROLE,
    payload: { accessroles, history },
  };
};

export const getAccessRoleSuccess = (data: {}) => {
  return {
    type: GET_ACCESS_ROLE_SUCCESS,
    payload: data,
  };
};

export const getAccessRoleError = (error: {}) => {
  return {
    type: GET_ACCESS_ROLE_ERROR,
    payload: error,
  };
};

// ---------------------------------------------GET_ACCESS_ROLES_LIST
export const getAccessRolesList = (query: any, history: any) => {
  return {
    type: GET_ACCESS_ROLES_LIST,
    payload: {query, history},
  };
};

export const getAccessRolesListSuccess = (data: {}) => {
  return {
    type: GET_ACCESS_ROLES_LIST_SUCCESS,
    payload: data,
  };
};

export const getAccessRolesListError = (error: {}) => {
  return {
    type: GET_ACCESS_ROLES_LIST_ERROR,
    payload: error,
  };
};

// ---------------------------------------------CREATE_ACCESS_ROLE
export const createAccessRole = (accessroles: any, history: any) => {
  return {
    type: CREATE_ACCESS_ROLE,
    payload: { accessroles, history },
  };
};

export const createAccessRoleSuccess = (accessroles: {}) => {
  return {
    type: CREATE_ACCESS_ROLE_SUCCESS,
    payload: accessroles,
  };
};

export const createAccessRoleError = (error: {}) => {
  return {
    type: CREATE_ACCESS_ROLE_ERROR,
    payload: error,
  };
};

// ---------------------------------------------UPDATE_ACCESS_ROLE
export const updateAccessRole = (accessroles: any, history: any) => {
  return {
    type: UPDATE_ACCESS_ROLE,
    payload: { accessroles, history },
  };
};

export const updateAccessRoleSuccess = (accessroles: {}) => {
  return {
    type: UPDATE_ACCESS_ROLE_SUCCESS,
    payload: accessroles,
  };
};

export const updateAccessRoleError = (error: {}) => {
  return {
    type: UPDATE_ACCESS_ROLE_ERROR,
    payload: error,
  };
};

// ---------------------------------------------DELETE_ACCESS_ROLE
export const deleteAccessRole = (accessroles: any, history: any) => {
  return {
    type: DELETE_ACCESS_ROLE,
    payload: { accessroles, history },
  };
};

export const deleteAccessRoleSuccess = (accessroles: {}) => {
  return {
    type: DELETE_ACCESS_ROLE_SUCCESS,
    payload: accessroles,
  };
};

export const deleteAccessRoleError = (error: {}) => {
  return {
    type: DELETE_ACCESS_ROLE_ERROR,
    payload: error,
  };
};
