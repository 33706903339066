import { takeEvery, call, put } from "redux-saga/effects";
import invoicesService from "../../services/invoices.service";
import { AnyAction } from "redux";

// Tasks Redux States
import {
  GET_INVOICES_LIST
} from "./actionTypes";

import {
  setAllInvoices
} from "./actions";


function* getAllInvoices({ payload: { query, history } }: AnyAction): any {
  try {
    const response = yield call(invoicesService.getAllInvoices, { ...query });

    if (response.data && response.data.status === "success") {
      yield put(setAllInvoices(response.data));
    } else if (response.data && response.data.status === "error") {
      // yield put(getTasksListError(response.data));
    } else {
      //yield put(getTasksListError(response));
    }
  } catch (error: any) {
    // yield put(getTasksListError(error));
  }
}


function* invoicesSaga(): any {
  yield takeEvery(GET_INVOICES_LIST, getAllInvoices);
}

export default invoicesSaga;
