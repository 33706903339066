import React, { FC, useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardSubtitle, CardTitle, Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import { formatCurrency } from "../../../utils/formats";
import invoicesService from "../../../services/invoices.service";
import { toast } from "react-toastify";
import { getUserProfile, quarterOfYear, select2Styles } from "../../../helpers/functions_helper";
import { yearOptions } from "../../../helpers/functions_helper";
import { currentMonth, currentYear, options } from "../../../common/staticdata";
import { noData } from "../../../utils/data";
import LoadingSpinner from "../../../components/Common/Loader";
import Chart from "../../../components/Chart";
import ChartFilters from "../../../components/Chart/Filters";
import { serialize } from "../../../utils/utils";

type InvoiceTypeProps = {
    hideHeading?: boolean;
}
const InvoiceType: FC<InvoiceTypeProps> = ({ hideHeading = false }) => {

    const { t } = useTranslation();
    const userObj = getUserProfile('all-invoices');

    const inititalFilter = {
        week: null,
        quarter: null,
        month: currentMonth,
        year: currentYear,
        user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_ID : null,
        user_name: null
    };

    const [query, setQuery] = useState<string | null>(serialize(inititalFilter));
    const [filter, setFilter] = useState<any>(inititalFilter);

    const [loadingText, setLoadingText] = useState<string>('Loading...');
    const [loading, setLoading] = useState<boolean>(true);
    const [formValues, setFormValues] = useState<any>({
        invoice_type: quarterOfYear(),
    })

    const clearFilter = () => {
        setFilter(inititalFilter)
    }
    const [dChart, setDChart] = useState<any>({
        options: {
            plotOptions: {
                pie: {
                    donut: {
                        size: '50%',
                    },

                },
            },
            labels: [t('Invoice'), t('Credit Note')],
            legend: {
                show: true,
                position: 'bottom',
            },
            noData: noData
        },
        series: [],

    })

    const getInvoiceType = async (range: any) => {
        try {
            const res = await invoicesService.getInvoiceType(range);
            if (res.status === 'success') {
                setDChart((state: any) => ({
                    ...state,
                    series: res.invoice_type.map((i: any) => i.counts)
                }));
                setLoading(false)
            }
        } catch (error: any) {
            toast(error)
        }
    }

    useEffect(() => {
        loading && getInvoiceType(formValues.invoice_type);
    }, [loading])

    useEffect(() => {
        setDChart((state: any) => ({
            ...state,
            options: {
                ...state.options,
                labels: [t('Invoice'), t('Credit Note')],
            }
        }));
    }, [t])


    useEffect(() => {
        setLoading(true)
    }, [formValues.invoice_type])

    return (
        <div style={{ height: '370px' }}>
            <div className="d-sm-flex flex-wrap">
                <CardTitle className="mb-3">{!hideHeading && t("Invoice Type")}</CardTitle>
                <div className="ms-auto">
                    {/* <ChartFilters
                        filter={filter}
                        setFilter={setFilter}
                        clearFilter={clearFilter}
                        enableUser={true}
                        enableCompany={true}
                    /> */}
                    <Select
                        id="invoice_type"
                        name="invoice_type"
                        className="form-select-sm myClassName"
                        styles={select2Styles}
                        value={options.find((i: any) => i.value === formValues.invoice_type)}
                        options={options}
                        onChange={(option: any) => {
                            setFormValues((state: any) => ({ ...state, invoice_type: option.value }))
                        }}
                    />
                </div>
            </div>
            <Chart loading={loading} options={dChart.options} series={dChart.series} type="donut" height={335} />
        </div>

    )
}

export default InvoiceType;