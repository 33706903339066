import { all, fork } from "redux-saga/effects"

import CommonSaga from "./common/saga"
import TasksSaga from "./tasks/saga"
import UsersSaga from "./users/saga"
import TimeSheetsSaga from "./timesheets/saga"
import AccessRolesSaga from "./masters/accessrole/saga"
import AccessModulesSaga from "./masters/accessmodule/saga"
import CompanySaga from "./masters/company/saga"
import CompanyCategorySaga from "./masters/companycategory/saga"
import feesSaga from "./masters/fees/saga"
import taskTypesSaga from "./masters/tasktype/saga"
import taskStatusSaga from "./masters/taskstatus/saga"
import questionnaireStatusSaga from "./masters/questionnairestatus/saga"
import questionnaireTabSaga from "./masters/questionnairetab/saga"
import questionnaireTypeSaga from "./masters/questionnairetype/saga"
import questionnaireQuestionsSaga from "./masters/questionnairequestions/saga"
import questionnaireSaga from "./questionnaire/saga"
import questionnaireUserDataSaga from "./quesionuserdata/saga"
import taskPrioritySaga from "./masters/taskpriority/saga"
import userFunctionSaga from "./masters/userfunction/saga"
import userTypeSaga from "./masters/usertype/saga"
import userStatusSaga from "./masters/userstatus/saga"
import kanbanTaskSaga from "./kanbantask/saga"
import subTaskSaga from "./subtasks/saga";
import invoicesSaga from "./invoices/saga"
import dashboardSaga from "./dashboard/saga"
import mailsSaga from "./mails/saga"
import userManualSaga from "./usermanual/saga"
import emailCategorySaga from './masters/emailtemplatecategory/saga';
import AlertSaga from "./alert/saga"
import SaltEdgeSaga from "./saltedge/saga"
export default function* rootSaga() {
  yield all([
    fork(CommonSaga),
    fork(TasksSaga),
    fork(UsersSaga),
    fork(TimeSheetsSaga),
    fork(AccessRolesSaga),
    fork(AccessModulesSaga),
    fork(CompanySaga),
    fork(CompanyCategorySaga),
    fork(feesSaga),
    fork(taskTypesSaga),
    fork(taskStatusSaga),
    fork(questionnaireStatusSaga),
    fork(questionnaireTabSaga),
    fork(questionnaireTypeSaga),
    fork(questionnaireQuestionsSaga),
    fork(questionnaireSaga),
    fork(questionnaireUserDataSaga),
    fork(taskPrioritySaga),
    fork(userFunctionSaga),
    fork(userTypeSaga),
    fork(userStatusSaga),
    fork(kanbanTaskSaga),
    fork(subTaskSaga),
    fork(invoicesSaga),
    fork(dashboardSaga),
    fork(mailsSaga),
    fork(userManualSaga),
    fork(emailCategorySaga),
    fork(AlertSaga),
    fork(SaltEdgeSaga)
  ])
}
