import React, { FC, useEffect, useState } from 'react';
import { Card, CardBody, CardSubtitle, CardText, CardTitle, Col, Label, Row } from 'reactstrap';
import emailService from '../../../../services/email.service';
import { getUserProfile } from '../../../../helpers/functions_helper';
import { useTranslation } from 'react-i18next';
import { typeHeaderFooter, HeaderFooter } from "../data";
import EmailHeader from '../components/Header';
import EmailFooter from '../components/Footer';
import FooterButton from "../components/FooterButton";

import Target from "../components/Target";
import { targetOptions } from "../../../../common/staticdata";
import { find } from 'lodash';

type ViewTemplateProps = {
    id: number;
}

const ViewTemplate: FC<ViewTemplateProps> = ({ id }) => {

    const { t } = useTranslation();
    const userObj = getUserProfile();
    const [templateList, setTemplateList] = useState([]);

    const getTemplateList = async (id: number) => {
        const res = await emailService.getEmailTemplateView(id);
        if (res.data.status === "success") {
            setTemplateList(res.data.data);
        }
        console.log(res.data.data);
    }

    useEffect(() => {
        getTemplateList(id);
    }, [id])

    const getTarget = (id: string) => {
        const tdata = find(targetOptions, { value: id });
        return typeof tdata != undefined ? tdata?.label : '';
    }

    return (
        <React.Fragment>
            {(userObj.IS_ADMIN === 1) &&
                <>
                    <Row>
                        {
                            templateList && templateList.map((item: any) =>
                                <Col xl={12} key={item.id}>
                                    <Card>
                                        <CardBody>
                                            <CardTitle tag="h5">
                                                {item.subject}
                                            </CardTitle>
                                            <CardSubtitle
                                                className="mb-2 text-muted"
                                                tag="h6"
                                            >
                                                {item.language}
                                            </CardSubtitle>
                                        </CardBody>
                                        <CardBody>
                                                <div>
                                                    <EmailHeader header={HeaderFooter[0].header} />
                                                    <div style={{ 'padding': '15px' }}>
                                                        <Target target={getTarget(item.target) || ''} />
                                                        <div dangerouslySetInnerHTML={{ __html: item.template }} />
                                                        <FooterButton title={item.button} />
                                                    </div>
                                                    <EmailFooter footer={HeaderFooter[0].footer} />
                                                </div>
                                            </CardBody>
                                    </Card>
                                </Col>)
                        }
                    </Row>
                </>
            }

        </React.Fragment>

    )
}

export default ViewTemplate;