import axios from "axios";
import { getAxiosConfigFileUploadWithToken, getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class Service {

    async create(data: any) {
        const response = await axios
            .post(
                SERVER_URL + url.NEWSLETTER.CREATE,
                data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }


    async duplicate(data: any) {
        const response = await axios
            .post(
                SERVER_URL + url.NEWSLETTER.DUPLICATE,
                data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async update(data: any) {
        const response = await axios
            .put(
                SERVER_URL + url.NEWSLETTER.UPDATE,
                data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async delete(id: any) {
        const response = await axios
            .delete(
                SERVER_URL + url.NEWSLETTER.DELETE + '/' + id,
                {
                    headers: getAxiosConfigWithToken()
                }
            );
        return response.data;
    }

    async getAll(data: any) {
        const response = await axios
            .post(SERVER_URL + url.NEWSLETTER.GET_ALL,
                data,
                {
                    headers: getAxiosConfigWithToken(),
                }
            ).then(function (response: any) {
                return response;
            })
            .catch(function (error: any) {
                return error;
            });
        return response;
    }

    async getList() {
        const response = await axios
            .post(SERVER_URL + url.NEWSLETTER.GET_LIST,
                {
                    headers: getAxiosConfigWithToken(),
                }
            ).then(function (response: any) {
                return response;
            })
            .catch(function (error: any) {
                return error;
            });
        return response;
    }

    async get(id: number,news_letter_id:string) {
        const response = await axios
            .get(
                SERVER_URL + url.NEWSLETTER.GET + '/' + id+'/'+news_letter_id,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async uploadImage(DATA:any) {
        console.log(DATA);
        const response = await axios
            .post(
                SERVER_URL + url.NEWSLETTER.UPLOAD_IMAGE,DATA,
                {
                    headers: getAxiosConfigFileUploadWithToken()
                }
            );

        return response.data;
    }


    async sendMail(DATA:any) {
        const response = await axios
            .post(
                SERVER_URL + url.NEWSLETTER.SEND_NEWS_LETTER_MAIL,DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    //Schedule API
    async getNewsLetterSchedule(id:number,schedule_id:string) {
        const response = await axios
            .get(
                SERVER_URL + url.NEWSLETTER.GET_NEWS_LETTER_SCHEDULE+'/'+id+'/'+schedule_id,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async createNewsLetterSchedule(DATA:any) {
        const response = await axios
            .post(
                SERVER_URL + url.NEWSLETTER.CREATE_NEWS_LETTER_SCHEDULE,DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async updateNewsLetterSchedule(DATA:any) {
        const response = await axios
            .put(
                SERVER_URL + url.NEWSLETTER.UPDATE_NEWS_LETTER_SCHEDULE,DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async deleteNewsLetterSchedule(id:number) {
        const response = await axios
            .delete(
                SERVER_URL + url.NEWSLETTER.DELETE_NEWS_LETTER_SCHEDULE+'/'+id,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }



}

export default new Service();