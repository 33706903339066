import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

const Callback = (porps: any) => {
  let navigate = useNavigate();
  const { instance, accounts, inProgress } = useMsal();

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      return navigate("/login");
    }
  }, [inProgress, InteractionStatus]);

  return (
    <>
      <div>Please wait....</div>
    </>
  );
};

export default Callback;
