import React from "react";
import { Nav, NavItem } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getUserProfile } from "../../helpers/functions_helper";

const NavItems = (props: any) => {
  const { t } = useTranslation();
  const userProfile = getUserProfile("all-tasks");

  return (
    <React.Fragment>
      <Nav tabs className="nav-tabs-custom">
        <NavItem>
          <Link
            style={{ cursor: "pointer" }}
            to="/tasks/all-tasks"
            className={`${
              window.location.pathname === "/tasks/all-tasks" ? "active" : ""
            }  nav-link`}
          >
            <span className="d-block d-sm-none">
              <i className="fas fa-home"></i>
            </span>
            <span className="d-none d-sm-block">{t("Task")}</span>
          </Link>
        </NavItem>
        
        <NavItem>
          <Link
            style={{ cursor: "pointer" }}
            to="/tasks/all-sub-tasks"
            className={`${
              window.location.pathname === "/tasks/all-sub-tasks"
                ? "active"
                : ""
            }  nav-link`}
          >
            <span className="d-block d-sm-none">
              <i className="fas fa-home"></i>
            </span>
            <span className="d-none d-sm-block">{t("Subtask")}</span>
          </Link>
        </NavItem>

        {userProfile.USER_COMPANY === 1 ? (
          <NavItem>
            <Link
              style={{ cursor: "pointer" }}
              to="/tasks/archive-task"
              className={`${
                window.location.pathname === "/tasks/archive-task"
                  ? "active"
                  : ""
              }  nav-link`}
            >
              <span className="d-block d-sm-none">
                <i className="fas fa-home"></i>
              </span>
              <span className="d-none d-sm-block">{t("Archived")}</span>
            </Link>
          </NavItem>
        ) : null}
      </Nav>
    </React.Fragment>
  );
};

export default NavItems;
