import React, { useEffect, useState, Suspense } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    ListGroup,
    ListGroupItem,
    Input,
} from "reactstrap";
import Chart from 'react-apexcharts'
import WidgetBasic from "../../components/Widgets/Basic";
import invoicesService from "../../services/invoices.service";
import { formatCurrency } from "../../utils/formats";
import { toast } from "react-toastify";
import Select from "react-select";
import { getUserProfile, widgetAccess, yearOptions } from "../../helpers/functions_helper";
// import InvoicePerTaskType from "./Charts/InvoicePerTaskType";
import InvoiceVsPaid from "./Charts/InvoiceVsPaid";
import SalesReport from "./Charts/SalesReport";
// import InvoiceType from "./Charts/InvoiceType";
import TaskAmountVsServiceCharge from "./Charts/TaskAmountVsServiceCharge";
// import InvoiceCount from "./Charts/InvoiceCount";
import InvoiceGeneratedFromGeneralAccount from "./Charts/InvoiceGeneratedFromGeneralAccount";
import ReminderPaymentDue from "./Charts/ReminderPaymentDue";
import CashFlow from "./Charts/CashFlow";
import TopClients from "./Charts/TopClients";
import ScheduleOfPaymentsClientHistory from "./Charts/ScheduleOfPaymentsClientHistory";
import LoadingSpinner from "../../components/Common/Loader";
import InvoiceGeneratedFromTimesheet from "./Charts/InvoiceGeneratedFromTimesheet";
import TodaysTrends from "./Charts/TodaysTrends";
import ChartFilters from "../../components/Chart/Filters";
const InvoiceCount = React.lazy(() => import('./Charts/InvoiceCount'));
const InvoicePerTaskType = React.lazy(() => import('./Charts/InvoicePerTaskType'));
const InvoiceType = React.lazy(() => import('./Charts/InvoiceType'));

const options = [
    { value: 'Q1-2023', label: 'Q1-2023' },
    { value: 'Q2-2023', label: 'Q2-2023' },
    { value: 'Q3-2023', label: 'Q3-2023' },
    { value: 'Q4-2023', label: 'Q4-2023' },
];


const styles = {
    control: (base: any) => ({
        ...base,
        minHeight: 22,
        heigth: 20
    }),
    dropdownIndicator: (base: any) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    clearIndicator: (base: any) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
    }),
};


const InvoiceAnalytics = (props: any) => {

    const userObj = getUserProfile('all-invoices');

    const inititalFilter = {
        week: null,
        quarter: null,
        month: null,
        year: null,
        user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_ID : null
    };
    const [loadingText, setLoadingText] = useState<string>('Loading...');

    const [counts, setCounts] = useState<any>({
        paid: 0,
        notPaid: 0,
        partialPaid: 0,
        cancelled: 0,
        creditNotes: 0,
    })

    const [filter, setFilter] = useState<any>(inititalFilter);

    const clearFilter = () => {
        setFilter(inititalFilter);
    }


    const getAnalyticsData = async () => {
        setLoadingText("Loading...");
        const res = await invoicesService.getAnalytics();
        if (res.status === 'success') {
            setCounts((state: any) => ({
                ...state,
                paid: res.counts.paid || 0,
                notPaid: res.counts.not_paid || 0,
                partialPaid: res.counts.partial_paid || 0,
                cancelled: res.counts.cancelled || 0,
                creditNotes: res.counts.credit_memo || 0,
            }));

        }
        setLoadingText("No data found");

    }

    useEffect(() => {
        getAnalyticsData()
    }, [])

    return (
        <>
            {/* <Row>
                <Col>
                    <div className="d-sm-flex flex-wrap my-3">
                        <p></p>
                        <div className="ms-auto">
                            <ChartFilters filter={filter} setFilter={setFilter} clearFilter={clearFilter} />
                        </div>
                    </div>
                </Col>
            </Row> */}
            {widgetAccess('Invoice_Counts') &&
                <Row>
                    <Suspense fallback={<LoadingSpinner />}>
                        <InvoiceCount />
                    </Suspense>
                </Row>
            }
            <Row>
                {/* {widgetAccess('Invoice_Trends') &&
                    <Col lg='12' md='12'>
                        <Card>
                            <CardBody>
                                <Suspense fallback={<LoadingSpinner />}>
                                    <TodaysTrends />
                                </Suspense>
                            </CardBody>
                        </Card>
                    </Col>
                } */}
                {widgetAccess('Invoice_generated_direactly_from_timesheet') &&
                    <Col lg='12' md='12'>
                        <Card>
                            <CardBody>
                                <Suspense fallback={<LoadingSpinner />}>
                                    <InvoiceGeneratedFromTimesheet />
                                </Suspense>
                            </CardBody>
                        </Card>
                    </Col>
                }
                {widgetAccess('Invoice_per_task_type') &&
                    <Col lg='8' md='12'>
                        <Card>
                            <CardBody>
                                <Suspense fallback={<LoadingSpinner />}>
                                    <InvoicePerTaskType />
                                </Suspense>
                            </CardBody>
                        </Card>
                    </Col>
                }
                {widgetAccess('Invoice_Type') &&
                    <Col lg='4' md='12'>
                        <Card>
                            <CardBody>
                                <Suspense fallback={<LoadingSpinner />}>
                                    <InvoiceType />
                                </Suspense>
                            </CardBody>
                        </Card>
                    </Col>
                }

                {widgetAccess('Sales_report') &&
                    <Col lg='7' md='12'>
                        <Card>
                            <CardBody>
                                <SalesReport />
                            </CardBody>
                        </Card>
                    </Col>
                }
                {widgetAccess('Task_amount_vs_service_charge_incurred') &&
                    <Col lg='5' md='12'>
                        <Card>
                            <CardBody>
                                <TaskAmountVsServiceCharge />
                            </CardBody>
                        </Card>
                    </Col>
                }
                {widgetAccess('Invoiced_vs_Paid') &&
                    <Col lg='5' md='12'>
                        <Card>
                            <CardBody>
                                <InvoiceVsPaid />
                            </CardBody>
                        </Card>
                    </Col>
                }
                {widgetAccess('Reminder_sent_for_payment_due') &&
                    <Col lg='7' md='12'>
                        <Card>
                            <CardBody>
                                <ReminderPaymentDue />
                            </CardBody>
                        </Card>
                    </Col>
                }
                {widgetAccess('Invoice_Generated_from_General_Account') &&
                    <Col lg='5' md='12'>
                        <Card>
                            <CardBody>
                                <InvoiceGeneratedFromGeneralAccount />
                            </CardBody>
                        </Card>
                    </Col>
                }
                {widgetAccess('Cash_Flow') &&
                    <Col lg='7' md='12'>
                        <Card>
                            <CardBody>
                                <CashFlow />
                            </CardBody>
                        </Card>
                    </Col>
                }
                {widgetAccess('Top_Clients') &&
                    <Col lg='5' md='12'>
                        <Card>
                            <CardBody>
                                <TopClients />
                            </CardBody>
                        </Card>
                    </Col>
                }
                {widgetAccess('Schedule_of_Payment_clients_History') &&
                    <Col lg='7' md='12'>
                        <Card>
                            <CardBody>
                                <ScheduleOfPaymentsClientHistory />
                            </CardBody>
                        </Card>
                    </Col>
                }
            </Row>
        </>
    );
};

export default InvoiceAnalytics;
