import { useState, useEffect, memo } from "react";
import WidgetBasic from "../../../components/Widgets/Basic";
import { Row, Col } from "reactstrap";
import tasksService from "../../../services/tasks.service";
import filters from "../filter";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { currentMonth, currentYear } from "../../../common/staticdata";
import { find, filter, map } from 'lodash';

export const intialLabels = [];
export const intialData: any = [
  { name: "TO DO", data: [0] },
  { name: "IN PREPARATION", data: [0] },
  { name: "FINALIZED", data: [0] },
  { name: "SIGN OFF", data: [0] },
  { name: "SENT", data: [0] },
];

export const initialFilter = {
  week: null,
  quarter: null,
  month: currentMonth,
  year: currentYear,
};
type variant =
  | "primary"
  | "secondary"
  | "info"
  | "warning"
  | "danger"
  | "success"
  | "default";
type taskChartProps = {
  count: number;
  status: string;
  color: variant;
  icon: string;
};
export const taskCount: taskChartProps[] = [
  {
    count: 0,
    status: "TO DO",
    color: "primary",
    icon: "event_note",
  },
  {
    count: 0,
    status: "IN PREPARATION",
    color: "secondary",
    icon: "edit_calendar",
  },
  {
    count: 0,
    status: "IN REVIEW",
    color: "info",
    icon: "event_upcoming",
  },
  {
    count: 0,
    status: "RESPONSIBLE TO SIGN OFF",
    color: "warning",
    icon: "event_available",
  },
  {
    count: 0,
    status: "TO BE SENT",
    color: "default",
    icon: "calendar_apps_script",
  },
  {
    count: 0,
    status: "SENT",
    color: "secondary",
    icon: "schedule_send",
  },
  {
    count: 0,
    status: "CANCELLED",
    color: "danger",
    icon: "event_busy",
  },
  {
    count: 0,
    status: "INVOICED",
    color: "success",
    icon: "receipt_long",
  },
  {
    count: 0,
    status: "DONE",
    color: "success",
    icon: "assignment_turned_in",
  },
];
const StatusWiseTaskCount = (props: any) => {
  const { query } = props;
  const [statusWiseTaskCount, setStatusWiseTaskCount] =
    useState<any>(taskCount);
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getStatusTaskCount = async () => {
    try {
      const data = await tasksService.getStatusWiseTaskCount(query);
      if (data.data.status === "success") {
        setStatusWiseTaskCount(data.data.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error: any) {
      toast(error);
    }

  };
  const viewTaskList = (filter: any) => {
    const local_filter = JSON.parse(localStorage.getItem('filters') as any);
    const is_filter = find(local_filter,(f:any)=>f.url === '/tasks/all-tasks');
    if (is_filter) {
      const f = map(local_filter, (f: any) => {
        if (f.url === '/tasks/all-tasks') {
          console.log(f);
          f.filters.status = [filter.id];
        }
        return f;
      }
      );
      localStorage.setItem('filters', JSON.stringify(f));
      navigate("../tasks/all-tasks");
    } else {
      local_filter.push({
        "url": "/tasks/all-tasks",
        "datatableOptions": {
          "draw": 1,
          "page": 1,
          "recordsTotal": 252,
          "recordsFiltered": 252,
          "sizePerPage": 20,
          "searchText": "",
          "sortField": "id",
          "sortOrder": "desc"
        },
        "filters": {
          "is_filtered": true,
          "company_id": [],
          "user_id": [],
          "internal_user_id": [],
          "type": [],
          "status": [
            1
          ],
          "is_archive": 0,
          "start_date": "",
          "due_date": "",
          "closing_date": ""
        }
      });
      localStorage.setItem('filters', JSON.stringify(local_filter));
      navigate("../tasks/all-tasks");

    }
  };

  useEffect(() => {
    getStatusTaskCount();
  }, []);

  return (
    <Row>
      <Col
        className="col-3 "
        onClick={() => viewTaskList(statusWiseTaskCount[0])}
      >
        <WidgetBasic
          to={"#"}
          variant="primary"
          loading={loading}
          label={statusWiseTaskCount[0]?.status}
          value={statusWiseTaskCount[0]?.count}
          icon={
            <span className="material-symbols-outlined icon">
              {statusWiseTaskCount[0]?.icon}
            </span>
          }
        />
      </Col>
      <Col
        className="col-3"
        onClick={() => viewTaskList(statusWiseTaskCount[1])}
      >
        <WidgetBasic
          to={"#"}
          variant="secondary"
          loading={loading}
          label={statusWiseTaskCount[1]?.status}
          value={statusWiseTaskCount[1]?.count}
          icon={
            <span className="material-symbols-outlined icon">
              {statusWiseTaskCount[1]?.icon}
            </span>
          }
        />
      </Col>
      <Col
        className="col-3"
        onClick={() => viewTaskList(statusWiseTaskCount[2])}
      >
        <WidgetBasic
          to={"#"}
          variant="info"
          loading={loading}
          label={statusWiseTaskCount[2]?.status}
          value={statusWiseTaskCount[2]?.count}
          icon={
            <span className="material-symbols-outlined icon">
              {statusWiseTaskCount[2]?.icon}
            </span>
          }
        />
      </Col>
      <Col
        className="col-3"
        onClick={() => viewTaskList(statusWiseTaskCount[3])}
      >
        <WidgetBasic
          to={"#"}
          variant="default"
          loading={loading}
          label={statusWiseTaskCount[3]?.status}
          value={statusWiseTaskCount[3]?.count}
          icon={
            <span className="material-symbols-outlined icon">
              {statusWiseTaskCount[3]?.icon}
            </span>
          }
        />
      </Col>
      <Col
        className="col-3"
        onClick={() => viewTaskList(statusWiseTaskCount[4])}
      >
        <WidgetBasic
          to={"#"}
          variant="warning"
          loading={loading}
          label={statusWiseTaskCount[4]?.status}
          value={statusWiseTaskCount[4]?.count}
          icon={
            <span className="material-symbols-outlined icon">
              {statusWiseTaskCount[4]?.icon}
            </span>
          }
        />
      </Col>
      <Col
        className="col-3"
        onClick={() => viewTaskList(statusWiseTaskCount[5])}
      >
        <WidgetBasic
          to={"#"}
          variant="primary"
          loading={loading}
          label={statusWiseTaskCount[5]?.status}
          value={statusWiseTaskCount[5]?.count}
          icon={
            <span className="material-symbols-outlined icon">
              {statusWiseTaskCount[5]?.icon}
            </span>
          }
        />
      </Col>
      <Col
        className="col-3"
        onClick={() => viewTaskList(statusWiseTaskCount[6])}
      >
        <WidgetBasic
          to={"#"}
          variant="danger"
          loading={loading}
          label={statusWiseTaskCount[6]?.status}
          value={statusWiseTaskCount[6]?.count}
          icon={
            <span className="material-symbols-outlined icon">
              {statusWiseTaskCount[6]?.icon}
            </span>
          }
        />
      </Col>
      <Col
        className="col-3"
        onClick={() => viewTaskList(statusWiseTaskCount[7])}
      >
        <WidgetBasic
          to={"#"}
          variant="success"
          loading={loading}
          label={statusWiseTaskCount[7]?.status}
          value={statusWiseTaskCount[7]?.count}
          icon={
            <span className="material-symbols-outlined icon">
              {statusWiseTaskCount[7]?.icon}
            </span>
          }
        />
      </Col>
      <Col
        className="col-3"
        onClick={() => viewTaskList(statusWiseTaskCount[8])}
      >
        <WidgetBasic
          to={"#"}
          variant="success"
          loading={loading}
          label={statusWiseTaskCount[8]?.status}
          value={statusWiseTaskCount[8]?.count}
          icon={
            <span className="material-symbols-outlined icon">
              {statusWiseTaskCount[8]?.icon}
            </span>
          }
        />
      </Col>
      <Col
        className="col-3"
      //onClick={() => viewTaskList(statusWiseTaskCount[9])}
      >
        <WidgetBasic
          to={"#"}
          variant="info"
          loading={loading}
          label={t('NEW TASK')}
          value={statusWiseTaskCount[9]?.count[0]}
          icon={
            <span className="material-symbols-outlined icon">
              {statusWiseTaskCount[9]?.icon}
            </span>
          }
          seconday={[{
            label: t('In this week'),
            value: statusWiseTaskCount[9]?.count[1]
          }]}
        />
      </Col>
      <Col
        className="col-3"
      //onClick={() => viewTaskList(statusWiseTaskCount[10])}
      >
        <WidgetBasic
          to={"#"}
          variant="info"
          loading={loading}
          label={t('CLOSED')}
          value={statusWiseTaskCount[10]?.count[0]}
          icon={
            <span className="material-symbols-outlined icon">
              {statusWiseTaskCount[10]?.icon}
            </span>
          }
          seconday={[{
            label: t('In this week'),
            value: statusWiseTaskCount[10]?.count[1]
          }]}
        />
      </Col>
      {typeof statusWiseTaskCount[11] !== undefined && <Col
        className="col-3"
      //onClick={() => viewTaskList(statusWiseTaskCount[11])}
      >
        <WidgetBasic
          to={"#"}
          variant="secondary"
          loading={loading}
          label={t('SENSITIVE')}
          value={statusWiseTaskCount[11]?.count}
          icon={
            <span className="material-symbols-outlined icon">
              {statusWiseTaskCount[11]?.icon}
            </span>
          }

        />
      </Col>}
    </Row>
  );
};

export default memo(StatusWiseTaskCount);
