import React, { useEffect, useState, FC } from "react";
import {
    Col,
} from "reactstrap";
import Chart from 'react-apexcharts'
import WidgetBasic from "../../../components/Widgets/Basic";
import invoicesService from "../../../services/invoices.service";
import disputesServices from "../../../services/disputes.services";
import { useTranslation } from "react-i18next";

interface CountsProps extends CartProps {
    filters?: any;
    widgetId?: 'Total_Paid' | 'Not_Paid' | 'Partial_Paid' | 'Over_Paid' | 'Cancelled' | 'Credit_Notes' | 'Disputes' | 'All';
}

const InvoiceCount: FC<CountsProps> = ({ hideHeading, filters = null, widgetId = 'All' }) => {

    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(true);

    const [counts, setCounts] = useState<any>({
        paid: 0,
        notPaid: 0,
        partialPaid: 0,
        overPaid: 0,
        cancelled: 0,
        creditNotes: 0,
        to_check_on: 0,
        to_check_off: 0,
        total_sent: 0,
        sent_paid: 0,
        sent_over_paid: 0,
        reminder_1: 0,
        reminder_2: 0,
    })
    const [dispute, setDispute] = useState<any>({
        total: 0,
        pending: 0,
        accepted: 0,
        rejected: 0
    })

    const getDispute = async () => {
        const disputes = await disputesServices.getDisputeCount();
        if (disputes.data.status === 'success') {
            setDispute({
                total: disputes?.data?.data?.total,
                pending: disputes?.data?.data?.pending,
                accepted: disputes?.data?.data?.accepted,
                rejected: disputes?.data?.data?.rejected
            });
        }
    }

    const getAnalyticsData = async () => {

        const res = await invoicesService.getAnalytics();
        if (res.status === 'success') {
            setCounts((state: any) => ({
                ...state,
                paid: res.counts.paid || 0,
                notPaid: res.counts.not_paid || 0,
                partialPaid: res.counts.partial_paid || 0,
                overPaid: res.counts.over_paid || 0,
                cancelled: res.counts.cancelled || 0,
                creditNotes: res.counts.credit_memo || 0,
                to_check_on: res.counts.to_check_on || 0,
                to_check_off: res.counts.to_check_off || 0,
                total_sent: res.counts.total_sent || 0,
                sent_paid: res.counts.sent_paid || 0,
                sent_over_paid: res.counts.sent_over_paid || 0,
                reminder_1: res.counts.reminder_1 || 0,
                reminder_2: res.counts.reminder_2 || 0,
            }));

        }

        setLoading(false)
    }

    useEffect(() => {
        getDispute();
        getAnalyticsData();
    }, [])

    return (
        <>
            {(widgetId === 'All' || widgetId === 'Total_Paid') &&
                <Col xl='2' lg='4'>
                    <WidgetBasic variant="primary" loading={loading} label="Total Paid" value={counts.paid} icon={<span className="material-symbols-outlined icon">
                        receipt_long
                    </span>} to='/invoices-management/invoices/paid' />
                </Col>
            }
            {(widgetId === 'All' || widgetId === 'Not_Paid') &&
                <Col xl='2' lg='4'>
                    <WidgetBasic variant="danger" loading={loading} label="Not Paid" value={counts.notPaid} icon={<span className="material-symbols-outlined icon">
                        design_services
                    </span>} to='/invoices-management/invoices/notpaid' />
                </Col>
            }
            {(widgetId === 'All' || widgetId === 'Partial_Paid') &&
                <Col xl='2' lg='4'>
                    <WidgetBasic variant="warning" loading={loading} label="Partial Paid" value={counts.partialPaid} icon={<span className="material-symbols-outlined icon">
                        list_alt
                    </span>} to='/invoices-management/invoices/partialPaid' />
                </Col>
            }
            {(widgetId === 'All' || widgetId === 'Over_Paid') &&
                <Col xl='2' lg='4'>
                    <WidgetBasic variant="info" loading={loading} label="Over Paid" value={counts.overPaid} icon={<span className="material-symbols-outlined icon">
                        toc
                    </span>} to='/invoices-management/invoices/overPaid' />
                </Col>
            }
            {(widgetId === 'All' || widgetId === 'Cancelled') &&
                <Col xl='2' lg='4'>
                    <WidgetBasic variant="secondary" loading={loading} label="Cancelled" value={counts.cancelled} icon={<span className="material-symbols-outlined icon">
                        quick_reference
                    </span>} to='/invoices-management/invoices/cancelled' />
                </Col>
            }
            {(widgetId === 'All' || widgetId === 'Credit_Notes') &&
                <Col xl='2' lg='4'>
                    <WidgetBasic variant="success" loading={loading} label="Credit Notes" value={counts.creditNotes} icon={<span className="material-symbols-outlined icon">
                        source_notes
                    </span>} to='/invoices-management/invoices/creditnote' />
                </Col>
            }
            {(widgetId === 'All' || widgetId === 'Credit_Notes') &&
                <Col xl='3' lg='5'>
                    <WidgetBasic
                        variant="info"
                        loading={loading}
                        label="To Check"
                        value={null}
                        icon={<span className="material-symbols-outlined icon" >
                            toggle_on
                        </span>}
                        seconday={[
                            {
                                label: t('ON'),
                                value: counts.to_check_on,
                                to: '/invoices-management/invoices/on'
                            },
                            {
                                label: t('OFF'),
                                value: counts.to_check_off,
                                to: '/invoices-management/invoices/off'
                            }
                        ]}
                    />
                </Col>
            }
            {
                (widgetId === 'All' || widgetId === 'Credit_Notes') &&
                <Col xl='5' lg='7'>
                    <WidgetBasic
                        variant="primary"
                        loading={loading}
                        label="Sent invoices"
                        value={counts.total_sent}
                        icon={<span className="material-symbols-outlined icon">
                            export_notes
                        </span>}
                        seconday={[
                            {
                                label: t('Paid'),
                                value: counts.sent_paid,
                                to: '/invoices-management/invoices/paid'
                            },
                            {
                                label: t('Over Paid'),
                                value: counts.sent_over_paid,
                                to: '/invoices-management/invoices/overPaid'
                            },
                            {
                                label: `${t("Reminder")} 1`,
                                value: counts.reminder_1,
                            },
                            {
                                label: `${t("Reminder")} 2`,
                                value: counts.reminder_2,
                            },
                        ]}
                    />
                </Col>
            }
            {
                (widgetId === 'All' || widgetId === 'Disputes') &&
                <Col xl='4' lg='5'>
                    <WidgetBasic
                        variant="danger"
                        loading={loading}
                        label="Disputes"
                        value={dispute?.total}
                        icon={<span className="material-symbols-outlined icon">
                            error
                        </span>}
                        seconday={[
                            {
                                label: 'Pending',
                                value: dispute?.pending,
                                to: '/invoices-management/invoices/dispute?dispute_status=pending'
                            },
                            {
                                label: 'Accepted',
                                value: dispute?.accepted,
                                to: '/invoices-management/invoices/dispute?dispute_status=accept'
                            },
                            {
                                label: 'Rejected',
                                value: dispute?.rejected,
                                to: '/invoices-management/invoices/dispute?dispute_status=reject'
                            },
                        ]}
                    />
                </Col>
            }
        </>
    );
};

export default InvoiceCount;
