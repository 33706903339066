import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  Button,
  FormFeedback,
  Alert,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

// Import images
import logoDark from "../../assets/images/logos/tc-logo-red-block-with-name.png";

import loginService from "../../services/auth.service";

import reCAPTCHA from "../../components/Common/reCAPTCHA";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../../components/Form/ErrorMessage";
const recaptcha = new reCAPTCHA(process.env.REACT_APP_RECAPTCHA_SITE_KEY!, "forgotpassword");

const ForgotPassword = (props: any) => {
  const { t } = useTranslation();
  document.title = t("Forgot password") + " | " + process.env.REACT_APP_NAME;

  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorData, setErrorData] = useState<any>({ data: {} });

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email field is required.")
      .max(100, "Email may not be greater than 100 characters.")
      .email("Email must be a valid email address."),
  });

  const handleForgotPassword = async (values: { email: string; re_captcha_token: any }) => {
    try {
      const res = (await loginService.forgot(values)) as any;
      if (res.data && res.data.status === "success") {
        setSuccessMsg(res.data.message);
        setErrorMsg("");
        setErrorData({ data: {} });
        setLoading(false);
      } else if (res.data && res.data.status === "error") {
        setErrorMsg(res.data.message);
        setErrorData({ data: res.data.data });
        setSuccessMsg("");
        setLoading(false);
      } else {
        setErrorMsg(res.message);
        setSuccessMsg("");
        setLoading(false);
      }
    } catch (err) {
      setErrorMsg(err as any);
      setSuccessMsg("");
      setLoading(false);
    }
  };

  const validation = useFormik({
    initialValues: {
      email: "",
      re_captcha_token: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      let response: any = await recaptcha.getToken();
      if (response[0].status === "success") {
        var newData: any = { ...values, re_captcha_token: response[0].token };
        handleForgotPassword(newData);
      } else {
        setLoading(false);
        setErrorMsg(
          t(
            "Could not connect to the reCAPTCHA service. Please check your internet connection and reload to get a reCAPTCHA challenge."
          )
        );
      }
    },
  });

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-white" style={{ borderBottom: "1px solid #c71e26" }}>
                  <Row>
                    <Col className="col-8">
                      <div className="p-4">
                        <h5 style={{ color: "#c71e26" }}>{t("Forgot password")} !</h5>
                        <p style={{ color: "#c71e26" }}>{process.env.REACT_APP_NAME}</p>
                      </div>
                    </Col>
                    <Col className="col-4 p-4 align-self-end">
                      <img src={logoDark} className="img-fluid" width={80} />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2 mt-3">
                    {errorMsg ? (
                      <Alert color="danger">
                        {errorMsg}

                        {errorData.data
                          ? Object.keys(errorData.data).map((key) => (
                              <li color="danger" key={key}>
                                {errorData.data[key]}
                              </li>
                            ))
                          : null}
                      </Alert>
                    ) : null}

                    {!successMsg ? (
                      <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="mb-3">
                          <Label className="form-label">{t("Email")}</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder={t("Enter email")}
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            disabled={loading}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          <ErrorMessage error={validation.errors.email} touched={validation.touched.email} label={"Email"} />
                        </div>

                        <div className="mt-3 d-grid">
                          <Button
                            color="primary"
                            className="btn-block"
                            type="submit"
                            disabled={loading}
                          >
                            {loading ? (
                              <span>
                                <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                                  hourglass_empty
                                </span>
                                <span>{t("Please wait...")}</span>
                              </span>
                            ) : (
                              <span>
                                <span className="align-middle me-1 material-symbols-outlined fw-light fs-4">
                                  lock_reset
                                </span>
                                <span>{t("Forgot")}</span>
                              </span>
                            )}
                          </Button>
                        </div>
                      </Form>
                    ) : (
                      <div className="align-middle border-0 mt-5 mb-5">
                        <div className="text-center mt-5 mb-3">
                          <span
                            className="align-middle material-symbols-outlined text-muted fw-lighter"
                            style={{ fontSize: "80px" }}
                          >
                            local_post_office
                          </span>
                        </div>

                        <div className="mt-1 mb-5 text-center">
                          <h5 className="text-truncate text-muted fw-lighter">{successMsg}</h5>
                        </div>
                      </div>
                    )}

                    <div className="mt-4 text-center">
                      <Link to="/login" className="text-muted">
                        <span className="align-middle me-1 material-symbols-outlined fw-light fs-4">
                          login
                        </span>
                        {t("Back to login")}
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-5 text-center">
                <p>
                  {t("Don't have an account ?")}
                  <Link to="/register-user" className="fw-medium text-primary ms-1">
                    {t("Signup Now")}
                  </Link>
                </p>
                <p>
                  © {new Date().getFullYear()}{" "}
                  <strong>Tax Consult HR Tax & International Mobility</strong>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ForgotPassword;
