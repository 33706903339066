import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col, Button } from "reactstrap";
import SimpleBar from "simplebar-react";
import notificationService from "../../../services/notification.service";
import { getDataTableQuery, getUserProfile,wssConfig } from "../../../helpers/functions_helper";
import Avatar from "../../Avatar";
// Actions
import { CommonAction } from "../../../store/actions";

import Echo from "laravel-echo";
declare global {
  interface Window {
    Pusher: any;
    Echo: any;
  }
}

window.Pusher = require("pusher-js");
window.Echo = new Echo(wssConfig());
const channel = window.Echo.channel("notification");

const NotificationDropdown = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userProfile = getUserProfile();

  const [menu, setMenu] = useState<boolean>(false);
  const [showViewMore, setShowViewMore] = useState(false);
  const [loadingNotificationData, setLoadingNotificationData] = useState(true);
  const [viewMoreNotificationData, setViewMoreNotificationData] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationData, setNotificationData] = useState([]);

  const columnData = [
    t("TO DO"),
    t("IN PREPARATION"),
    t("IN REVIEW"),
    t("RESPONSIBLE TO SIGN OFF"),
    t("TO BE SENT"),
    t("SENT"),
    t("CANCELLED"),
    t("INVOICED"),
    t("DONE"),
  ];
  const columnColor = [
    "#556ee6",
    "#34c38f",
    "#50a5f1",
    "#f1b44c",
    "#74788d",
    "#fd7e14",
    "#198754",
    "#20c997",
    "#0dcaf0",
  ];

  const connectNotificationLive = () => {
    channel
      .subscribed(() => {
        // console.log("subscribe");
      })
      .listen(".notification", (e: any) => {
        const usersList = e.notification.users_list;
        const isNotified = usersList.includes(userProfile.USER_ID);
        if (isNotified === true) {
          getNotificationCount();
        }
      })
      .error((e: any) => {
        // console.log(e, "error");
      });
  };

  const columns = [
    {
      dataField: "id",
    },
  ];

  const [datatableOptions, setDatatableOptions] = useState({
    draw: 1,
    page: 1,
    recordsTotal: 0,
    recordsFiltered: 0,
    sizePerPage: 5,
    searchText: "",
    sortField: "id",
    sortOrder: "desc",
  });

  const { getNotificationfetched, getNotificationcount } = useSelector((state: any) => ({
    getNotificationfetched: state.Common.notificationfetched,
    getNotificationcount: state.Common.GET_NOTIFICATION_COUNT_SUCCESS,
  }));

  const getNotificationCount = async () => {
    dispatch(CommonAction.getNotificationCount());
  };

  const updateNotificationStatus = async (data: any) => {
    try {
      const res = (await notificationService.updateNotificationStatus({
        module_app_id: data.module_app_id,
        module_main_id: data.module_main_id,
        module_sub_id: data.module_sub_id,
      })) as any;
    } catch (err) { }
  };

  const handleNotificationViewed = (data: any) => {
    const id = data.id;
    setNotificationData(notificationData.filter((item: any) => item?.id !== id));
    dispatch(CommonAction.getNotificationCountSuccess({ data: notificationCount - 1 }));
    setMenu(false);

    if (
      data.action === "COMMENT" ||
      data.action === "ATTACHMENT" ||
      data.action === "CREATE EXCEEDED"
    ) {
      updateNotificationStatus(data);
    }
  };

  const loadingNotification = async () => {
    setNotificationData([]);
    setLoadingNotificationData(true);

    try {
      const res = (await notificationService.getNotificationList(
        getDataTableQuery(datatableOptions, columns)
      )) as any;

      if (res.data.status === "success") {
        setNotificationData(res?.data?.data);
        setLoadingNotificationData(false);
        setViewMoreNotificationData(false);
      } else {
        setNotificationData([]);
        setLoadingNotificationData(false);
        setViewMoreNotificationData(false);
      }
    } catch (err) {
      setNotificationData([]);
      setLoadingNotificationData(false);
      setViewMoreNotificationData(false);
    }
  };

  const viewMore = () => {
    setDatatableOptions((prevData: any) => ({
      ...prevData,
      page: prevData.page + 1,
    }));
    setViewMoreNotificationData(true);
  };

  useEffect(() => {
    setNotificationCount(getNotificationcount.data);
  }, [getNotificationcount]);

  useEffect(() => {
    connectNotificationLive();
  }, []);

  useEffect(() => {
    if (getNotificationfetched === false) {
      getNotificationCount();
      dispatch(CommonAction.setNotificationFetched(true));
    }
  }, [getNotificationfetched]);

  useEffect(() => {
    if (menu === false) {
      setDatatableOptions((prevData: any) => ({
        ...prevData,
        page: 1,
      }));
    }
    if (menu === true || viewMoreNotificationData === true) {
      loadingNotification();
    }
  }, [menu, viewMoreNotificationData]);

  useEffect(() => {
    const currentPage = datatableOptions?.page;
    const isPage = notificationCount - currentPage * 5;

    if (isPage > 0) {
      setShowViewMore(true);
    } else {
      setShowViewMore(false);
    }
  }, [datatableOptions, notificationCount]);

  return (
    <React.Fragment>
      {notificationCount > 0 ? (
        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="dropdown d-inline-block"
          tag="li"
        >
          <DropdownToggle
            className={`btn header-item noti-icon ${window.location.pathname === "/dashboard/notifications" ? "header-icon-active" : ""
              }`}
            tag="button"
            id="page-header-notifications-dropdown"
            title={t("Notifications")}
          >
            <span className="align-middle material-symbols-outlined fw-light">notifications</span>
            {notificationCount > 0 && (
              <span className="pulse badge bg-danger rounded-pill">{notificationCount}</span>
            )}
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0"> {t("Notifications")} </h6>
                </Col>
                <div className="col-auto">
                  <Link to="/dashboard/notifications" className="small">
                    <span className="badge_custome badge-soft-primary badge bg-primary rounded-pill">
                      {t("View All")}
                    </span>
                  </Link>
                </div>
              </Row>
            </div>

            <SimpleBar style={{ maxHeight: "230px" }}>
              {notificationData && notificationData.length > 0 ? (
                notificationData.map((data: any) => (
                  <Link
                    id={`notification_${data.id}`}
                    key={data.id}
                    to={
                      data.custom_array && JSON.parse(data.custom_array)[0]?.notification_url
                        ? JSON.parse(data.custom_array)[0]?.notification_url
                        : data.module_app_id === 4
                          ? `/users/comments/${userProfile.USER_ID}/${userProfile.USER_SUB_ID}`
                          : data.module_app_id === 8 && data.module_main_id === 0
                            ? `/${data.url}?filters={"is_filtered":true,"company_id":"","user_id":"${data.created_by}"}`
                            : `/${data.url_edit}${data.module_main_id}/${data.module_sub_id}`
                    }
                    className="text-reset notification-item"
                    onClick={() => handleNotificationViewed(data)}
                  >
                    <div className="d-flex">
                      <Avatar
                        id={data.id}
                        src={data.user_logo_data}
                        name={
                          data.first_name && data.last_name
                            ? `${data.first_name} ${data.last_name}`
                            : ""
                        }
                      />
                      <div className="flex-grow-1">
                        <h6 className="mt-0 mb-1">
                          {data.custom_array && JSON.parse(data.custom_array)[0]?.notification_title
                            ? t(JSON.parse(data.custom_array)[0]?.notification_title)
                            : t(data.action_value)
                          }
                        </h6>
                        <div className="font-size-12 text-muted">
                          {data.custom_message && <p className="mb-1">{t(data.custom_message)}</p>}

                          {data.custom_array &&
                            JSON.parse(data.custom_array)[0]?.task_status &&
                            JSON.parse(data.custom_array)[0]?.task_status["old_status"] &&
                            JSON.parse(data.custom_array)[0]?.task_status["new_status"] && (
                              <div className="d-flex p-0 mt-2 mb-2 gap-1">
                                <span
                                  className="badge_custome badge rounded-pill"
                                  style={{
                                    background:
                                      columnColor[
                                      parseInt(
                                        JSON.parse(data.custom_array)[0]?.task_status[
                                        "old_status"
                                        ]
                                      ) - 1
                                      ],
                                  }}
                                >
                                  {
                                    columnData[
                                    parseInt(
                                      JSON.parse(data.custom_array)[0]?.task_status["old_status"]
                                    ) - 1
                                    ]
                                  }
                                </span>
                                <span className="align-middle material-symbols-outlined fw-light">
                                  arrow_forward
                                </span>
                                <span
                                  className="badge_custome badge rounded-pill"
                                  style={{
                                    background:
                                      columnColor[
                                      parseInt(
                                        JSON.parse(data.custom_array)[0]?.task_status[
                                        "new_status"
                                        ]
                                      ) - 1
                                      ],
                                  }}
                                >
                                  {
                                    columnData[
                                    parseInt(
                                      JSON.parse(data.custom_array)[0]?.task_status["new_status"]
                                    ) - 1
                                    ]
                                  }
                                </span>
                              </div>
                            )}

                          {data.first_name && data.last_name && (
                            <p className="mt-2 mb-0">
                              <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                account_circle
                              </span>
                              {`${data.first_name} ${data.last_name}`}
                            </p>
                          )}
                          <p className="mb-0">
                            <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                              schedule
                            </span>
                            {data.time_elapsed}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))
              ) : (
                <div className="text-center mt-3 mb-3">
                  {loadingNotificationData ? (
                    <div className="align-middle">
                      <div className="text-center mt-3 mb-3">
                        <span
                          className="align-middle material-symbols-outlined text-muted fw-lighter"
                          style={{ fontSize: "40px" }}
                        >
                          hourglass_empty
                        </span>
                      </div>

                      <div className="mt-1 mb-3 text-center">
                        <h5 className="text-truncate text-muted fw-lighter">{t("Loading...")}</h5>
                      </div>
                    </div>
                  ) : (
                    <div className="align-middle">
                      <div className="text-center mt-3 mb-3">
                        <span
                          className="align-middle material-symbols-outlined text-muted fw-lighter"
                          style={{ fontSize: "40px" }}
                        >
                          notifications_off
                        </span>
                      </div>

                      <div className="mt-1 mb-3 text-center">
                        <h5 className="text-truncate text-muted fw-lighter">
                          No new notification to view!
                        </h5>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </SimpleBar>

            {showViewMore && (
              <div className="p-2 border-top d-grid">
                <Button className="btn btn-sm font-size-12 text-center" onClick={() => viewMore()}>
                  {viewMoreNotificationData === false ? (
                    <>
                      <span className="align-middle me-1 material-symbols-outlined fw-light fs-4">
                        arrow_circle_right
                      </span>
                      {t("View more")}..
                    </>
                  ) : (
                    <>
                      <span className="align-middle me-1 material-symbols-outlined fw-light fs-4">
                        hourglass_empty
                      </span>
                      {t("Loading...")}
                    </>
                  )}
                </Button>
              </div>
            )}
          </DropdownMenu>
        </Dropdown>
      ) : (
        <Link to={`/dashboard/notifications`}>
          <button
            type="button"
            className={`btn header-item noti-icon ${window.location.pathname === "/dashboard/notifications" ? "header-icon-active" : ""
              }`}
            title="Show all notification"
          >
            <span className="align-middle material-symbols-outlined fw-light">notifications</span>
          </button>
        </Link>
      )}
    </React.Fragment>
  );
};

export default NotificationDropdown;
