import moment from "moment";

export const initialValues: splitPayrollTypes = {
  id: "",
  split_payroll_id: "",
  code: "",
  custome_code: "",
  company_id: "",
  company_value: "",
  employee_id: "",
  employee_value: "",
  family_status: "",
  spouse_earns: "2",
  children_total: 0,
  children_under_3: 0,
  municipal_id: "",
  is_default_tax: 1,
  municipal_tax: 7,
  // computation_year: parseInt(moment().format("YYYY")),
  computation_year: null,
  worldwide_gross_salary: 0,
  sp_country_data: [],
  sp_fringe_benefit_data: [],
  fringe_benefit: "1",
  fringe_benefit_date: "",
  co_2_emission: 0,
  catalogue_price: 0,
  sssc_application: "2",
  employee_group_insurance_premiums: 0,
  benefits_in_social_security: 0,

  created_by_first_name: "",
  created_by_last_name: "",
  created_at: "",
  updated_by_first_name: "",
  updated_by_last_name: "",
  updated_at: "",

  submit_btn_code: 1,
};

export const personalInfoInitialValues: personalInfoTypes = {
  tax_free_amount_for_attribution_to_spouse: 0, // MASTER VALUE
  tax_free_amount_for_children_less_3_year_old: 0, // MASTER VALUE
  tax_free_amount_for_single_parents: 0, // MASTER VALUE
  tax_free_amount_for_taxpayer: 0, // CALCULATED VALUE
  tax_free_amount_for_spouse: 0, // CALCULATED VALUE
  business_expenses_director_percentage: 0, // MASTER VALUE
  business_expenses_director_maximum_amount: 0, // MASTER VALUE
  P_Expenses: [], // MASTER VALUE
  commuting_exemption: 0, // MASTER VALUE
  attribution_to_spouse: 0, // MASTER VALUE
  min_bik_per_year: 0,
  revaluation_factor: 0,
  P_TAXES_EXEMPT_AMOUNT: [],
  P_TAXES: [],
  P_CHILDREN: [],
  companyCarStatuses: [],
  SS_SOCIAL_SECURITY: [],

  EST_TAX_RATE: 0,

  DED_10_MIN: 0,
  DED_10_MAX: 0,
  WORK_DAYS: 0,
  RAS: [],
  FINAL_TAX: [],

  C_WERBUNG: 0,
  C_SONDER: 0,
  C_SPECIAL_EXP: 0,
  C_REBATE_TREATY: 0,
  C_SOLIDARITY: 0,
  C_SOLIDARITY_MIN_LIMIT: 0,
  C_SOLIDARITY_MIN_PERC: 0,
  TAX_RATE_G: [],
  GER_TAX_RATE: 0,

  POL_TAX_RATE: 0,
  EXCHANGE_RATE_POL: 0,
  PERSONAL_ALLOWANCE_P: [],
  TAX_RATE_P: [],
  STANDARD_DEDUCTION: 0,

  ROM_TAX_RATE: 0,

  SPN_TAX_RATE: 0,
  TAX_RATE_S: [],
  EMPLOYMENT_REDUCTION: [],

  C_DED_BE_SS: 0,
  GENERAL_REBATE: [],
  EMPLOYMENT_REBATE: [],
  TAX_RATE_N: [],
  SS_RATE_N: [],

  EXCHANGE_RATE_UK: 0,
  PERS_ALL_SINGLE: 0,
  PERS_ALL_DEDUCTION: 0,
  GBP_OVER_GBP: 0,
  PERS_ALL_MARRIED: 0,
  TAX_RATE_UK: [],

  EXCHANGE_RATE_USA: 0,
  PERS_ALL_USA: [],
  PERS_ALL_USA_MAR: [],
  TAX_RATE_USA_SINGLE: [],
  TAX_RATE_USA_MARRIED: [],
  USA_STATE_TAX: 0,
};

export const familyStatuses = (t: any) => {
  return [
    { value: 1, label: t("Single") },
    { value: 2, label: t("Married / Legal cohabitant") },
    { value: 3, label: t("Divorced") },
  ]
};

export const familyStatusesList = [
  { value: 1, label: "Single" },
  { value: 2, label: "Married / Legal cohabitant" },
  { value: 3, label: "Divorced" },
];

export const yesNo = (t: any) => {
  return [
    { value: 1, label: t("Yes") },
    { value: 2, label: t("No") },
  ]
};

export const empDirIncome = (t: any) => {
  return [
    { value: 1, label: t("Employee") },
    { value: 2, label: t("Director") },
  ]
};

export const taxYear = (id: number, year: number) => {
  const currentYear = year;
  const previousYear = currentYear - 1;
  let taxYearValue = "";

  if (id === 1) {
    taxYearValue = String(currentYear);
  }
  if (id === 2) {
    taxYearValue = String(previousYear);
  }
  if (id === 3) {
    taxYearValue = String(previousYear + "/" + currentYear);
  }
  if (id === 4) {
    taxYearValue = "Double tax treaty";
  }
  return taxYearValue;
};

// export const companyCarStatuses = [
//   {
//     value: 1,
//     label: "No Company car",
//     co2: 0, // MASTER VALUE
//     basic: 0,
//     increase: 0,
//     min: 0,
//     max: 0,
//     applicable: 0,
//     cal_0: 0,
//     cal_1: 0,
//     cal_2: 0,
//     cal_3: 0,
//     cal_4: 0,
//     cal_5: 0,
//     cal_6: 0,
//   },
//   {
//     value: 2,
//     label: "Company car: Petrol / LPG",
//     co2: 0, // MASTER VALUE
//     basic: 5.5,
//     increase: 0.1,
//     min: 4,
//     max: 18,
//     applicable: 0,
//     cal_0: 0,
//     cal_1: 0,
//     cal_2: 0,
//     cal_3: 0,
//     cal_4: 0,
//     cal_5: 0,
//     cal_6: 0,
//   },
//   {
//     value: 3,
//     label: "Company car: Diesel",
//     co2: 0, // MASTER VALUE
//     basic: 5.5,
//     increase: 0.1,
//     min: 4,
//     max: 18,
//     applicable: 0,
//     cal_0: 0,
//     cal_1: 0,
//     cal_2: 0,
//     cal_3: 0,
//     cal_4: 0,
//     cal_5: 0,
//     cal_6: 0,
//   },
//   {
//     value: 4,
//     label: "Company car: Electric",
//     co2: 0, // MASTER VALUE
//     basic: 4,
//     increase: 0,
//     min: 4,
//     max: 4,
//     applicable: 0,
//     cal_0: 0,
//     cal_1: 0,
//     cal_2: 0,
//     cal_3: 0,
//     cal_4: 0,
//     cal_5: 0,
//     cal_6: 0,
//   },
// ];

export const companyCarPercentile = [
  {
    months_range: "0 - 12 months",
    months_value: 100,
  },
  {
    months_range: "13 - 24 months",
    months_value: 94,
  },
  {
    months_range: "25 - 36 months",
    months_value: 88,
  },
  {
    months_range: "37 - 48 months",
    months_value: 82,
  },
  {
    months_range: "49 - 60 months",
    months_value: 76,
  },
  {
    months_range: "> 60 months",
    months_value: 70,
  },
];

// export const ssSocialSecurity = [
//   {
//     C122: 0,
//     C123: 0,
//     C124: 0,
//     C125: 0,

//     D122: 14658.44, // MASTER VALUE
//     D123: 63297.86, // MASTER VALUE
//     D124: 93281.01, // MASTER VALUE
//     D125: 0,

//     E122: 0,
//     E123: 20.5,
//     E124: 14.16,
//     E125: 0,

//     F122: 3123.68, // MASTER VALUE
//     F123: 0,
//     F124: 0,
//     F125: 0,
//   },
// ];

// MASTER VALUE
// export const P_TAXES_EXEMPT_AMOUNT = [
//   [0, 9750, 0.25, 0],
//   [9750, 13870, 0.3, 2437.5],
//   [13870, 23120, 0.4, 3673.5],
//   [23120, 42370, 0.45, 7373.5],
//   [42370, 0, 0.5, 16036],
// ];
// export const P_TAXES = [
//   [0, 13870, 0.25, 0],
//   [13870, 24480, 0.4, 3467.5],
//   [24480, 42370, 0.45, 7711.5],
//   [42370, 0, 0.5, 15762],
// ];
// export const P_CHILDREN = [
//   [0, 0, 0, 0],
//   [1, 0, 0, 1690],
//   [2, 0, 0, 4340],
//   [3, 0, 0, 9730],
//   [4, 0, 0, 15740],
//   [5, 6010, 0, 21750],
//   [6, 0, 0, 27760],
// ];
// export const P_Expenses = [
//   [0, 16799.98, 0.3, 0],
//   [16799.98, 0, 0, 5040],
// ];
export const P_SSSC = [
  [0, 0, 0, 0],
  [18592.02, 0, 0.09, 18592.02],
  [21070.97, 223.1, 0.013, 21070.96],
  [60161.86, 731.28, 0, 0],
];
// export const SS_SOCIAL_SECURITY = [
//   [0, 14658.44, 0, 3123.68],
//   [14658.44, 63297.86, 20.5, 3123.68],
//   [63297.86, 93281.01, 14.16, 13094.76],
//   [93281.01, 0, 0, 17340.38],
// ];

// FRANCE
// export const RAS = [
//   [0, 48, 0, 0],
//   [48, 92, 12, 0],
//   [92, 0, 20, 5.28],
// ];
// export const FINAL_TAX = [
//   [0, 0, 0, 0],
//   [0, 0, 0, 0],
//   [0, 25710, 20, 0],
//   [25710, 0, 30, 5142],
// ];

// GERMANY
// export const TAX_RATE_G = [
//   [0, 9408, 0, 0],
//   [0, 14532, 14, 0],
//   [0, 57051, 23.97, 0],
//   [0, 270500, 42, 0],
//   [0, 0, 45, 0],
// ];

// POLAND
// export const PERSONAL_ALLOWANCE_P = [
//   [0, 8000, 0, 1360],
//   [0, 13000, 0, 0],
//   [0, 85528, 0, 525],
//   [0, 127000, 0, 0],
//   [0, 0, 0, 0],
// ];
// export const TAX_RATE_P = [
//   [0, 0, 0, 0],
//   [0, 85528, 17, 0],
//   [0, 0, 32, 0],
// ];

// SPAIN
// export const TAX_RATE_S = [
//   [0, 12450, 19, 0],
//   [0, 20200, 24, 0],
//   [0, 35200, 30, 0],
//   [0, 60000, 37, 0],
//   [0, 300000, 45, 0],
//   [0, 0, 47, 0],
// ];
// export const EMPLOYMENT_REDUCTION = [
//   [0, 11250, 0, 3700],
//   [0, 14450, 1.15625, 0],
//   [0, 0, 0, 0],
// ];

// NETHERLANDS
// export const GENERAL_REBATE = [
//   [0, 21043, 2837, 0],
//   [21043, 68507, 2837, -5.977],
//   [68507, 0, 0, 0],
// ];
// export const EMPLOYMENT_REBATE = [
//   [0, 10108, 0, 4.581],
//   [10108, 21835, 463, 28.771],
//   [21835, 35652, 3837, 2.663],
//   [35652, 105736, 4205, -6],
//   [105736, 0, 0, 0],
// ];
// export const TAX_RATE_N = [
//   [0, 21043, 9.45, 0],
//   [21043, 35129, 9.45, 1989],
//   [35129, 68507, 37.10, 3320],
//   [68507, 0, 49.50, 15703],
// ];
// export const SS_RATE_N = [
//   [0, 21043, 27.65, 0],
//   [21043, 35129, 27.65, 5818],
//   [35129, 68507, 0, 9713],
//   [68507, 0, 0, 9713],
// ];

// UNITED KINGDOM
// export const TAX_RATE_UK = [
//   [-1, 0, 0, 0],
//   [0, 37700, 20, 0],
//   [37700, 150000, 40, 7540],
//   [150000, 0, 45, 52460],
// ];

// UNITED STATES
// export const PERS_ALL_USA = [
//   [0, 261500, 0]
// ];
// export const PERS_ALL_USA_MAR = [
//   [0, 156900, 0]
// ];
// export const TAX_RATE_USA_SINGLE = [
//   [0, 9950, 10, 0],
//   [9950, 40525, 12, 995],
//   [40525, 86375, 22, 4664],
//   [86375, 164925, 24, 14751],
//   [164925, 209425, 32, 33603],
//   [209425, 523600, 35, 47843],
//   [523600, 0, 37, 157804.25],
// ];
// export const TAX_RATE_USA_MARRIED = [
//   [0, 9950, 10, 0],
//   [9950, 40525, 12, 995],
//   [40525, 86375, 22, 4664],
//   [86375, 164925, 24, 14751],
//   [164925, 209425, 32, 33603],
//   [209425, 314150, 35, 47843],
//   [314150, 0, 37, 84496.75],
// ];