import { Table } from "reactstrap";

const PrivacyPolicy = (props: any) => {
  return (
    <>
      <h4>1. Over deze Verklaring</h4>
      <p>
        1.1 Deze Privacyverklaring ("Verklaring") legt uit hoe we (zoals hieronder gedefinieerd)
        informatie die, alleen of in combinatie met andere informatie, betrekking heeft op u
        ("Persoonsgegevens") in uw hoedanigheid van klant of begunstigde van onze diensten, of
        leverancier (of als onze contactpersoon bij deze entiteiten) ("u" of "uw") van Tax Consult
        HR Tax & International Mobility ("Tax Consult", "we", en "onze") verzamelen, delen en
        gebruiken.
      </p>
      <p>
        1.2 Deze Verklaring bevat ook informatie over de rechten waarover u beschikt met betrekking
        tot uw Persoonsgegevens die we verwerken en hoe u deze kan uitoefenen.
      </p>
      <p>
        1.3 Tax Consult HR Tax & International Mobility neemt de naleving van haar privacy
        verplichtingen ernstig. Daarom hebben we deze Verklaring opgesteld, die de standaarden
        beschrijft die Tax Consult toepast om uw Persoonsgegevens te beschermen.
      </p>
      <p>
        1.4 Voor de toepassing van deze Verklaring, treedt Tax Consult HR Tax & International
        Mobility SRL, waarmee u een contract hebt gesloten of die diensten aan u verleent als
        begunstigde, op als verwerkingsverantwoordelijke voor uw Persoonsgegevens. Als
        verwerkingsverantwoordelijke, is Tax Consult verantwoordelijk om ervoor te zorgen dat de
        verwerking van Persoonsgegevens in overeenstemming is met de toepasselijke wetgeving inzake
        gegevensbescherming, en meer specifiek met de Algemene Verordening Gegevensbescherming.
      </p>
      <p>
        1.5 Neem alstublieft de tijd om deze Verklaring aandachtig door te nemen. Indien u vragen of
        opmerkingen zou hebben, gelieve contact op te nemen met{" "}
        <a href="mailto:privacy@taxconsult.be">privacy@taxconsult.be</a>.
      </p>

      <h4>2. Welke Persoonsgegevens verzamelt Tax Consult en waarom?</h4>
      <p>
        2.1 De categorieën van uw Persoonsgegevens die we kunnen verzamelen, en de redenen waarvoor
        we deze verwerken, omvatten:
      </p>

      <Table bordered={true}>
        <thead>
          <tr>
            <th>Waarom we het verzamelen</th>
            <th>Categorieën van Persoonsgegevens</th>
            <th>Wettelijke basis</th>
          </tr>
          <tr>
            <th colSpan={3}>
              Verwerking met betrekking tot diensten aan onze klanten en begunstigden
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Om te zorgen voor de naleving van onze anti-witwasverplichtingen ten aanzien van onze
              cliënten en de ontvangers van onze diensten.
            </td>
            <td>
              Identificatiegegevens, contactgegevens, financiële en economische gegevens,
              huishoudelijke gegevens, gegevens over persoonlijke kenmerken, data over levensstijl,
              beroepsgegevens, gegevens over identiteitsbewijzen.
            </td>
            <td>Wettelijke verplichting waaraan wij onderworpen zijn.</td>
          </tr>
          <tr>
            <td>
              Opstellen van aangiften inkomstenbelasting voor ingezeten en niet-ingezeten
              belastingplichtigen.
            </td>
            <td>Dezelfde categorieën gegevens als hierboven.</td>
            <td>
              Contractuele noodzaak wanneer onze cliënt een natuurlijke persoon is; ons
              gerechtvaardigd belang om onze diensten te verlenen wanneer onze cliënt een
              rechtspersoon is.
            </td>
          </tr>
          <tr>
            <td>
              Om diensten te verlenen in verband met belasting- en socialezekerheidsramingen,
              salarissen, verschuldigde sociale lasten
            </td>
            <td>Dezelfde categorieën gegevens als hierboven.</td>
            <td>
              Contractuele noodzaak wanneer onze klant een natuurlijke persoon is; ons
              gerechtvaardigd belang bij het verlenen van onze diensten wanneer onze klant een
              rechtspersoon is.
            </td>
          </tr>
          <tr>
            <td>Het uitvoeren van belastingcontroles en administratieve belastingvorderingen</td>
            <td>
              Dezelfde categorieën gegevens als hierboven, afhankelijk van de uit te voeren
              specifieke taken.
            </td>
            <td>
              Contractuele noodzaak wanneer onze klant een natuurlijke persoon is; ons
              gerechtvaardigd belang bij het verlenen van onze diensten wanneer onze klant een
              rechtspersoon is.
            </td>
          </tr>
          <tr>
            <td>Consulting services, including special tax status and immigration issues</td>
            <td>
              Dezelfde categorieën gegevens als hierboven, afhankelijk van de uit te voeren
              specifieke taken.
            </td>
            <td>
              Contractuele noodzaak wanneer onze cliënt een natuurlijke persoon is; ons
              gerechtvaardigd belang bij het verlenen van onze diensten wanneer onze cliënt een
              rechtspersoon is.
            </td>
          </tr>
          <tr>
            <td>
              Voor de afwikkeling van transacties met ons, facturering van klantendiensten en
              invordering van klantenschulden.
            </td>
            <td>Identificatiegegevens, contactgegevens, financiële gegevens.</td>
            <td>Contractuele noodzaak.</td>
          </tr>
          <tr>
            <td>
              Beheer van cliënten (uitvoering van de procedure voor aanvaarding van cliënten,
              opstelling en beheer van opdrachtbrieven, beheer van mandaten tegenover de
              autoriteiten en tegenover onze leveranciers van IT voor boekhoudkundige toepassingen).
            </td>
            <td>
              Identificatiegegevens, contactgegevens, financiële gegevens, gegevens over het
              huishouden, gegevens over persoonlijke kenmerken.
            </td>
            <td>Contractuele noodzaak.</td>
          </tr>
          <tr>
            <td>Voor het beheer van klachten en geschillen met klanten.</td>
            <td>Identificatiegegevens, contactgegevens, gegevens in de contractdocumentatie.</td>
            <td>Ons gerechtvaardigd belang om onze rechten en belangen te vrijwaren.</td>
          </tr>
          <tr>
            <td colSpan={3}>Verwerkingen gerelateerd aan onze leveranciers</td>
          </tr>
          <tr>
            <td>Voor het beheer van leveranciers en de uitvoering van transacties met ons.</td>
            <td>Identificatiegegevens, contactgegevens, financiële gegevens.</td>
            <td>Contractuele noodzaak.</td>
          </tr>
          <tr>
            <td>Voor het beheer van klachten en geschillen met leveranciers.</td>
            <td>
              Identificatiegegevens, zoals naam, adres (zakelijk of privé), telefoonnummer, enz.
            </td>
            <td>Ons gerechtvaardigd belang om onze rechten en belangen te vrijwaren.</td>
          </tr>
        </tbody>
      </Table>
      <p>
        2.2 U kunt ons sommige van de hierboven vermelde Persoonsgegevens rechtstreeks verstrekken.
        Wij ontvangen echter ook Persoonsgegevens over u uit bronnen van derden wanneer wij op
        B2B-basis een contract sluiten met zakelijke klanten om bepaalde diensten te verlenen
        waarvan u de begunstigde bent (zoals uw werkgever of het bedrijf waarvan u aandeelhouder of
        functionaris bent of een tussenpersoon waarvoor u een begunstigde bent).
      </p>
      <p>
        De categorieën van Persoonsgegevens die we verzamelen van derde partijen omvatten uw
        identificatiegegevens (naam, voornaam), uw contactgegevens, uw professionele gegevens, en de
        gegevens die noodzakelijk zijn voor de uitvoering van de diensten waarvan u de begunstigde
        bent.
      </p>
      <p>
        2.3 Indien nodig verwerken wij Persoonsgegevens die informatie kunnen bevatten over etnische
        afkomst, politieke opvattingen of godsdienstige overtuiging, lichamelijke of geestelijke
        gezondheid of toestand of het plegen of vermeend plegen van strafbare feiten en daarmee
        verband houdende rechtsvorderingen en biometrische informatie ("Gevoelige
        Persoonsgegevens"). Wij zullen de hoeveelheid Gevoelige Persoonsgegevens die wij verwerken
        tot een minimum beperken, en alleen wanneer dat nodig is om aan onze wettelijke
        verplichtingen te voldoen, bijvoorbeeld anti-witwaspraktijken, of wanneer u dergelijke
        gegevens aan ons hebt verstrekt met het oog op het verlenen van een belastingsdienst.
      </p>
      <h4>3. Aan wie geeft Tax Consult uw Persoonsgegevens door?</h4>
      <p>3.1 We kunnen uw Persoonsgegevens meedelen aan de volgende categorieën van ontvangers:</p>
      <ul>
        <li>
          aan onze derde partijen verkopers, dienstverleners en partners die diensten leveren aan
          ons voor het verwerken van persoonsgegevens, of die anderszins Persoonsgegevens verwerken
          voor doeleinden die omschreven zijn in deze Verklaring of aan u meegedeeld werden op het
          ogenblik dat we uw Persoonsgegevens verzamelen. Deze dienstverleners verlenen ons diensten
          op de volgende gebieden: CRM software, boekhoudsoftware of -programma's, programma's voor
          documentbeheer en aanwervingsbureaus, IT diensten (waaronder Admin Consult (Syneton). Dit
          houdt ook in: Emasphere, Winbooks, iController, ETC Tool, Dynamic Flows, Tax Consult NV en
          onze email diensten;
        </li>
        <li>
          aan elke bevoegde wetshandhavingsinstantie, regelgevende instantie, overheidsinstantie,
          rechtbank of andere derde partij indien we geloven dat een mededeling noodzakelijk is (i)
          vanwege de geldende wetgeving of regulering, (ii) om onze wettelijke rechten uit te
          oefenen, vast te stellen of te verdedigen, of (iii) om uw vitale belangen of diegene van
          een andere persoon te beschermen;
        </li>
        <li>
          In het kader van de diensten die wij verlenen, delen wij bepaalde Persoonsgegevens mee aan
          de bevoegde autoriteiten (bijv. voor fiscale doeleinden) of aan andere soortgelijke
          entiteiten (bijv. het Belgisch Staatsblad, of de Kruispuntbank voor Ondernemingen).
          Afhankelijk van de te verlenen diensten en waar nodig, kunnen wij sommige van uw
          Persoonsgegevens meedelen aan Belgische of buitenlandse deskundigen en sociale
          secretariaten;
        </li>
        <li>
          aan onze auditoren, wettelijke vertegenwoordigers en gelijkaardige agenten in het kader
          van adviesverlening die zij aan ons verstrekken (bijvoorbeeld advocaten en notarissen);
        </li>
        <li>
          aan een potentiële koper (en diens agenten en adviseurs) in verband met een voorgenomen
          koop, fusie of overname van gelijk welk deel van onze business, op voorwaarde dat we de
          koper informeren dat hij enkel uw Persoonsgegevens mag gebruiken voor de doeleinden
          omschreven in deze Verklaring;
        </li>
        <li>
          aan gelijk welke andere persoon indien we uw voorafgaande toestemming hebben voor deze
          mededeling.{" "}
        </li>
      </ul>

      <h4>4. Hoe beschermen we uw privacy?</h4>
      <p>
        4.1 We zullen uw Persoonsgegevens verwerken in overeenstemming met deze Verklaring, als
        volgt:
      </p>
      <ul>
        <li>
          Behoorlijkheid: We zullen uw Persoonsgegevens behoorlijk verwerken. Dit betekent dat we
          transparant zijn over hoe we uw Persoonsgegevens verwerken en dat we deze zullen verwerken
          in overeenstemming met de toepasselijke wet.
        </li>
        <li>
          Doelbinding: We zullen uw Persoonsgegevens voor welbepaalde en rechtmatige doeleinden
          verwerken, en zullen deze niet verwerken op een wijze die niet verenigbaar is met deze
          doeleinden.
        </li>
        <li>
          Proportionaliteit: We zullen uw Persoonsgegevens verwerken op een wijze die evenredig is
          met de doeleinden die de verwerking beoogt te bereiken.
        </li>
        <li>
          Juistheid van gegevens: We nemen passende maatregelen om te verzekeren dat de
          Persoonsgegevens waarover we beschikken juist en volledig zijn, en waar nodig,
          geactualiseerd worden. Het is echter ook uw eigen verantwoordelijkheid om ervoor te zorgen
          dat uw Persoonsgegevens zo juist, volledig en actueel mogelijk worden bijgehouden door Tax
          Consult onmiddellijk te informeren over gelijk welke wijzigingen of fouten.
        </li>
        <li>
          Gegevensbeveiliging: We gebruiken gepaste technische en organisatorische maatregelen om de
          Persoonsgegevens die we over u verzamelen en verwerken te beschermen. De maatregelen die
          we gebruiken zijn ontworpen om een beveiligingsniveau te voorzien dat afgestemd is op het
          risico van de verwerking van uw Persoonsgegevens.
        </li>
        <li>
          Verwerkers: We kunnen derde partijen betrekken om uw Persoonsgegevens te verwerken voor en
          ten behoeve van Tax Consult. We verplichten zulke verwerkers om de Persoonsgegevens
          uitsluitend volgens onze instructies te verwerken en te handelen en om passende stappen te
          ondernemen om te verzekeren dat de Persoonsgegevens beschermd blijven.
        </li>
        <li>
          Internationale doorgifte van gegevens: Onze servers bevinden zich in de Europese
          Economische Ruimte (EER). Af en toe doen wij echter een beroep op externe deskundigen of
          consultants die buiten de EER gevestigd zijn. Dit betekent dat wanneer wij uw
          Persoonsgegevens verzamelen, wij die kunnen overdragen aan het land waar de deskundige of
          consultant gevestigd is. Dergelijke overdrachten zijn strikt noodzakelijk om u de
          overeengekomen contractuele diensten te verlenen en op uw verzoek (bv. indien voor uw zaak
          de tussenkomst van een deskundige in een derde land vereist is). Nadere inlichtingen
          kunnen op verzoek worden verstrekt.
        </li>
        <li>
          Gegevensbewaring: We bewaren Persoonsgegevens die we van u verzamelen zolang we daartoe
          een gerechtvaardigde bedrijfsbehoefte hebben (bijvoorbeeld, om de dienst die u hebt
          verzocht te leveren of om in overeenstemming te zijn met toepasselijke wettelijke, fiscale
          of boekhoudkundige vereisten). Wanneer we niet langer over een gerechtvaardigde
          bedrijfsbehoefte beschikken om uw Persoonsgegevens te verwerken, zullen we deze ofwel
          verwijderen ofwel anonimiseren of, indien het voorgaande niet mogelijk is (bijvoorbeeld,
          omdat uw Persoonsgegevens opgeslagen werden in back-up archieven), dan zullen we uw
          Persoonsgegevens veilig bewaren en deze isoleren van elke verdere verwerking tot de
          verwijdering mogelijk is.
        </li>
      </ul>

      <h4>5. Uw rechten betreffende gegevensbescherming</h4>
      <p>
        5.1 U beschikt over de volgende rechten betreffende gegevensbescherming die u kan uitoefenen
        door contact op te nemen met
        <a href="mailto:privacy@taxconsult.be">privacy@taxconsult.be</a> :
      </p>
      <ul>
        <li>
          Het recht om toegang, correctie, actualisering of verwijdering van uw persoonsgegevens op
          eender welk ogenblik te verzoeken.
        </li>
        <li>
          Bovendien kunt u, in bepaalde omstandigheden, bezwaar maken tegen de verwerking van uw
          Persoonsgegevens, ons vragen om de verwerking van uw Persoonsgegevens te beperken of
          verzoeken om de overdraagbaarheid van uw Persoonsgegevens.
        </li>
        <li>
          Indien we uw Persoonsgegevens verzameld en verwerkt hebben met uw toestemming, dan kan u
          op gelijk welk ogenblik uw toestemming intrekken. Het intrekken van uw toestemming heeft
          geen invloed op de rechtmatigheid van gelijk welke verwerking die we uitgevoerd hebben
          vóór de intrekking of de verwerking van uw Persoonsgegevens die uitgevoerd wordt op basis
          van rechtmatige gronden anders dan uw toestemming.
        </li>
        <li>
          Indien u een klacht heeft of bezorgd bent over hoe we uw Persoonsgegevens verwerken,
          zullen we trachten om deze bezorgdheden te verhelpen. Indien u vindt dat we uw klacht of
          bezorgdheid onvoldoende hebben aangepakt, beschikt u over het recht om een klacht in te
          dienen bij een autoriteit voor gegevensbescherming over onze verzameling en ons gebruik
          van uw Persoonsgegevens. Voor meer informatie, gelieve contact op te nemen met uw lokale
          autoriteit voor gegevensbescherming. (Contactgegevens voor autoriteiten voor
          gegevensbescherming in de Europese Economische Ruimte, Zwitserland en bepaalde
          niet-Europese landen (inclusief de VS en Canada) zijn hier beschikbaar.)
        </li>
      </ul>

      <p>
        5.2 We beantwoorden alle verzoeken die we ontvangen van individuen die hun rechten
        betreffende gegevensbescherming wensen uit te oefenen in overeenstemming met de
        toepasselijke wetgeving betreffende gegevensbescherming.
      </p>

      <h4>6. Wijzigingen aan deze Verklaring </h4>
      <p>
        6.1 Deze Verklaring kan van tijd tot tijd gewijzigd worden om aangepast te worden aan
        juridische, technische of commerciële wijzigingen. Wanneer we onze Verklaring zouden
        wijzigen, zullen we de gepaste maatregelen nemen om u hierover te informeren, aangepast aan
        het belang van de wijzigingen die we doorvoeren. Indien en waar vereist door de
        toepasselijke wetgeving betreffende gegevensbescherming, zullen wij uw toestemming vragen
        voor materiële wijzigingen aan deze Verklaring.
      </p>
      <p>
        6.2 U kunt zien wanneer deze Verklaring voor het laatst werd gewijzigd, door de "laatst
        gewijzigd op" datum te bekijken die bovenaan deze Verklaring wordt getoond.
      </p>
    </>
  );
};

export default PrivacyPolicy;
