export const formatCurrency = (amt: any, currencySymbol = true as boolean) => {
  const amount = !amt ? 0 : parseFloat(amt);
  if (currencySymbol) {
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "EUR",
    }).format(amount);
  } else {
    return new Intl.NumberFormat("de-DE", {
      minimumFractionDigits: 2
    }).format(amount);
  }
};

export const dateFormat = (date: any, options?: any, lang?: string) => {
  // alert(lang);
  const tempDate = new Date(date);
  return new Intl.DateTimeFormat(lang ? lang : "fr-BE", options).format(
    tempDate
  );
};


/**
 * Formats the size
 */
export const formatBytes = (bytes: any, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const bytesToMegaBytes = (bytes: number) => {
  return bytes / 1024 ** 2;
};
