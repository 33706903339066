import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  Row,
} from "reactstrap";
import DataTable from "../../components/Common/DataTable";
import Breadcrumbs from "../../components/Layout/Breadcrumb";

//redux
import { useDispatch, useSelector } from "react-redux";

// Actions
import { QuestionnaireAction } from "../../store/actions";
import { dateTimeString, getDataTableQuery, getUserProfile, handleFilters } from "../../helpers/functions_helper";
import DeleteModal from "../../components/Common/DeleteModal";
import QuestionModal from "./QuestionnaireCreate";
import { toast } from "react-toastify";
import Filters from './Filters';
import Drawer from "../../components/Drawer";
import EditCompany from "../Masters/Company/EditCompany";
import EditUser from "../Users/EditUser";
import Loader from "../../components/Layout/Loader";
import { useTranslation } from "react-i18next";
import QuestionnaireService from "../../services/questionnaire.service"
import questionnaireService from "../../services/questionnaire.service";
import fileDownload from "js-file-download";
const List = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "#",
      label: t("Questionnaires"),
      active: true,
    },
  ];
  const toastId = React.useRef(null) as any;
  const userProfile = getUserProfile("all-questionnaires");
  const savedfilters = handleFilters("GET");
  const user_id = props.user || null;
  const company_id = props.company || null;
  const { status } = useParams();

  if (!user_id && !company_id) {
    document.title = `${t("Questionnaire")} - ${t("List view")} | ${process.env.REACT_APP_NAME}`;
    window.history.replaceState(null, document.title, "/questionnaires/all-questionnaires");
  }

  // DataTable ----------------------------------------------
  function renderHeaderHTML(column: any, colIndex: any) {
    return <span dangerouslySetInnerHTML={{ __html: column.text }} />;
  }
  function renderRowHTML(rowContent: any, row: any) {
    return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
  }

  const [questionnairelist, setquestionnairelist] = useState<Array<any>>([]);
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const [questionaireData, setQuestionaireData] = useState({});
  const [loadingListData, setLoadingListData] = useState(true);
  const [datatableOptions, setDatatableOptions] = useState(
    savedfilters && savedfilters?.datatableOptions
      ? (savedfilters?.datatableOptions as any)
      : {
        draw: 1,
        page: 1,
        recordsTotal: 0,
        recordsFiltered: 0,
        sizePerPage: 20,
        searchText: "",
        sortField: "id",
        sortOrder: "desc",
      }
  );
  const [filters, setFilters] = useState(
    savedfilters && savedfilters?.filters
      ? (savedfilters?.filters as any)
      : {
        is_filtered: false,
        company_id: company_id,
        user_id: user_id,
        user_type: 0,
        year: "",
        questionnaire_type_id: 0,
        status: status ? status : null,
      }
  );



  const onTableChange = (type: any, newState: any) => {
    let draw = datatableOptions.draw + 1;
    let page = 1;
    if (type === "search") {
      page = 1;
    } else {
      page = newState.page;
    }
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: draw,
      page: page,
      sizePerPage: newState.sizePerPage,
      searchText: newState.searchText,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
    };
    setDatatableOptions(updatedDatatableOptions);
    setQuestionnaireData([]);
    setLoadingListData(true);
  };

  useEffect(() => {
    setQuestionnaireData([]);
    setLoadingListData(true);
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  const clearFilter = () => {
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: 1,
      page: 1,
      sizePerPage: 20,
      searchText: "",
      sortField: "last_name",
      sortOrder: "asc",
    };
    setDatatableOptions(updatedDatatableOptions);

    const filter = {
      ...filters,
      is_filtered: false,
      company_id: company_id,
      user_id: user_id,
      user_type: 0,
      year: "",
      questionnaire_type_id: 0,
      status: status ? status : null,
    };
    setFilters(filter);

    setQuestionnaireData([]);
    setLoadingListData(true);
  };



  // get store data
  let data = useSelector((state: any) => ({
    questionnairelist: state.Questionnaire.GET_QUESTIONNAIRE_LIST,
    success: state.Questionnaire.success,
    error: state.Questionnaire.error
  }));

  const [questionnaire, setQuestionnaire] = useState<any>();
  const [openCompany, setOpenCompany] = useState<boolean>(false);
  const [openUser, setOpenUser] = useState<boolean>(false);

  const handleCompanyClick = (questionnaire: any) => {
    setQuestionnaire(questionnaire);
    setOpenCompany(true);
  }

  const handleUserClick = (dt: any) => {
    setQuestionnaire(dt);
    setOpenUser(true);
  }

  const columns = [
    {
      dataField: "DT_RowIndex",
      text: t("Sr. No."),
      sort: false,
      search: false,
    },

    {
      dataField: "company_name_value",
      text: `${t('Client')}`,
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        if (row.company_id && !(userProfile.IS_NON_TC_USER && !userProfile.CAN_VIEW_ALL)) {
          return (
            <div className="link-primary" onClick={() => handleCompanyClick(row)}>
              {row.company_name_value}
            </div>
          );
        } else {
          return row.company_name_value;
        }
      },
    },
    {
      dataField: "user_value",
      text: `${t('Name of the employee')}`,
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        // if (!props.company && !props.user) {
        return (
          <div className="link-primary" onClick={() => handleUserClick(row)}>
            {row.user_first_name} {row.user_last_name}
          </div>
        );
        // } else {
        //   return row.user_first_name + ' ' + row.user_last_name;
        // }
      },
    },
    {
      dataField: "questionnaire_type_html",
      text: `${t('Questionnaire Type')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "type_value_html",
      text: `${t('Task type')}`,
      sort: true,
      search: true,
      formatter: renderRowHTML,
    },
    {
      dataField: "year",
      text: `${t('Year')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "tax_return_type_value",
      text: `${t('Tax return type')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "user_status_value",
      text: `${t('User type')}`,
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        if (row.user_status === 1) {
          return <span className="badge_custome badge-soft-primary badge bg-primary rounded-pill">{t(row.user_status_value)}</span>;
        } else if (row.user_status === 2) {
          return <span className="badge_custome badge-soft-danger badge bg-danger rounded-pill">{t(row.user_status_value)}</span>;
        }
      },
    },
    {
      dataField: "link_html",
      text: `${t('Questionnaire Link')}`,
      sort: true,
      search: true,
      // formatter: renderRowHTML,
      formatter: (rowContent: any, row: any): any => {
        if (row.link === null || row.link === "" || !row.link) {
          return null;
        }
        return (
          <div className="d-flex align-items-center me-3">
            {/* <a
              href={`${window.location.origin}/questionnaire-user-data/${row.id}/${row.questionnaire_id}`}
              className="medium"
            >
              {`${window.location.origin}/questionnaire`}
            </a> */}
            {/* <Link id="masterUserLink" to={`/questionnaire-user-data/${row.id}/${row.questionnaire_id}`}>
              {`${window.location.origin}/questionnaire`}
            </Link> */}
            {/* <a
              href={`${window.location.origin}/questionnaires/edit-questionnaire/${row.id}/${row.questionnaire_id}`}
              className="medium"
            >
              <span
                className="btn btn-pill btn-outline-primary btn-sm"
              // to={"/questionnaires/edit-questionnaire/" + row.id + "/" + row.questionnaire_id}
              >
                View questionnaire
                <i className="fa-solid fa-arrow-up-right-from-square ms-2"></i>
              </span>
            </a> */}
            {userProfile.USER_ID != row.employee && (row.status_value == 2 || row.status_value == 3 || row.status_value == 5 || row.status_value == 6) ?
              <Link to={`/questionnaires/edit-questionnaire/${row.id}/${row.questionnaire_id}`} >
                <span className="btn btn-pill btn-outline-primary btn-sm" >
                  {t('View questionnaire')}
                  <i className="fa-solid fa-arrow-up-right-from-square ms-2"></i>
                </span>
              </Link>
              :
              (
                <>
                  {userProfile.USER_ID == row.employee && (row.status_value == 1 || row.status_value == 4) ?
                    <Link to={`/questionnaires/edit-questionnaire/${row.id}/${row.questionnaire_id}`} >
                      <span className="btn btn-pill btn-outline-primary btn-sm" >
                        {t('View questionnaire')}
                        <i className="fa-solid fa-arrow-up-right-from-square ms-2"></i>
                      </span>
                    </Link>
                    :
                    <Link to={'#'} >
                      <span className="btn btn-pill btn-outline-primary btn-sm disabled" >
                        {t('View questionnaire')}
                        <i className="fa-solid fa-arrow-up-right-from-square ms-2"></i>
                      </span>
                    </Link>

                  }
                </>
              )

            }
          </div>
        );
      },
    },
    {
      dataField: "questionnaire_status_value_html",
      text: `${t('Status')}`,
      sort: true,
      search: true,
      formatter: renderRowHTML,
    },
    {
      dataField: "created_by_value",
      text: `${t('Created By')}`,
      sort: true,
      search: true,
      hidden: !userProfile.IS_INTERNAL,
    },
    {
      dataField: "created_at",
      text: `${t('Created date')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "submitted_at",
      text: `${t('Submitted date')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "expiry_date",
      text: `${t('Expiry date')}`,
      sort: true,
      search: true,
      formatter: renderRowHTML,
    },
    {
      dataField: "due_date",
      text: `${t('Due date')}`,
      sort: true,
      search: true,
      formatter: renderRowHTML,
      hidden: !userProfile.IS_INTERNAL,
    },
    {
      dataField: "action",
      text: `${t('Action')}`,
      sort: false,
      search: false,
      hidden: !(userProfile.CAN_CREATE && userProfile.USER_COMPANY === 1),
      formatter: (rowContent: any, row: any): any => {
        return (
          <div className="d-flex gap-2 text-end">
            {(row.status_value != 9 && row.status_value != 5 && row.status_value != 6) ? (
              <Link
                className="btn btn-pill btn-outline-warning btn-sm"
                to="#"
                onClick={() => onClickTerminate(row)}
              >
                {t('Terminate')}
              </Link>
            ) : null}

            <Link
              className="btn btn-pill btn-outline-danger btn-sm"
              to="#"
              onClick={() => onClickDelete(row)}
            >
              <i className="fa-regular fa-trash-can"></i> {t('Delete')}
            </Link>
          </div>
        );
      },
    },
  ];


  const handleLoadingstate = () => {
    setLoadingListData(true);
  };

  const handleFiltersChange = (obj: any) => {
    setFilters((state: any) => ({ ...state, ...obj }));
  };
  const handleApplyFilters = () => {
    setLoadingListData(true);
    setOpenFilter(false);
  };




  useEffect(() => {
    if (status != null) {
      setFilters((filter: any) => ({ ...filter, status: status }));
      setLoadingListData(true);
      dispatch(
        QuestionnaireAction.getQuestionnairelistFail(""));
    }
  }, [status])
  // Datatable -------------------------------------------------

  // Delete Form --------------------------------------------------------
  const [deleteData, setDeleteData] = useState({
    id: "",
    questionnaire_id: ""
  });
  const [recordName, setRecordName] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickTerminate = async (row: any) => {
    const deleteRowData = {
      ...deleteData,
      id: row.id,
      questionnaire_id: row.questionnaire_id,
    };
    const res = (await QuestionnaireService.onClickTerminate(deleteRowData)) as any;
    if (res.status == "success") {
      setLoadingListData(true);
    }
  }

  const onClickDelete = (row: any) => {
    if (row.status_value !== 1) {
      const messg = t(
        `You cannot delete the questionnaire as it is in ` + row.questionnaire_status_value
      );
      toast.dismiss();
      toast.warn(messg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    const deleteRowData = {
      ...deleteData,
      id: row.id,
      questionnaire_id: row.questionnaire_id,
    };
    setDeleteData(deleteRowData);
    setRecordName(row.questionnaire_type_html + ' sent to ' + row.user_first_name);
    setDeleteModal(true);
    setQuestionaireData(row);
  };

  const handleDeleteData = () => {
    setDeleteModal(false);
    const messg = t(
      `Deleting questionnaire...`
    );
    toastId.current = toast(messg, {
      autoClose: false,
      isLoading: true,
    });
    dispatch(QuestionnaireAction.deleteQuestionnaire(deleteData, props.history));
    setLoadingListData(true);
  };


  // send questionnaire form
  const [masterFormModal, setMasterFormModal] = useState(false);
  const [masterFormModalTitle, setMasterFormModalTitle] = useState<any>(t(
    `Create Questionnaire`
  ));
  const [questionnaireData, setQuestionnaireData] = useState([]);

  const onClickModal = () => {
    setQuestionnaireData([]);
    setMasterFormModalTitle(t(
      `CREATE QUESTIONNAIRE`
    ));
    setMasterFormModal(true);
  };


  const handleSubmitCompany = (values: any) => {
    const messg = t(
      `Sending questionnaire...`
    );
    toastId.current = toast(messg, {
      autoClose: false,
      isLoading: true,
    });
    dispatch(QuestionnaireAction.addNewQuestionnaire(values));
  };



  useEffect(() => {
    if (loadingListData === true) {
      loadingDatatable();
    }
  }, [loadingListData]);


  const loadingDatatable = async () => {
    try {
      const res = (await questionnaireService.getQuestionnaireDatatableList({
        ...getDataTableQuery(datatableOptions, columns),
        filters,
      })) as any;

      if (res.data.status === "success") {
        setquestionnairelist(res.data.data);

        const updatedDatatableOptions = {
          ...datatableOptions,
          recordsTotal: res.data.recordsTotal,
          recordsFiltered: res.data.recordsFiltered,
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingListData(false);
      } else {
        setquestionnairelist([]);

        const updatedDatatableOptions = {
          ...datatableOptions,
          recordsTotal: 0,
          recordsFiltered: 0,
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingListData(false);
      }
    } catch (err) {
      setquestionnairelist([]);

      const updatedDatatableOptions = {
        ...datatableOptions,
        recordsTotal: 0,
        recordsFiltered: 0,
      };
      setDatatableOptions(updatedDatatableOptions);
      setLoadingListData(false);
    }
  };

  useEffect(() => {
    if (data.questionnairelist?.data) {
      setquestionnairelist(data.questionnairelist.data);

      const updatedDatatableOptions = {
        ...datatableOptions,
        recordsTotal: data.questionnairelist.recordsTotal,
        recordsFiltered: data.questionnairelist.recordsFiltered,
      };
      setDatatableOptions(updatedDatatableOptions);
      setLoadingListData(false);
    } else {
      setquestionnairelist([]);
    }
  }, [data.questionnairelist]);

  useEffect(() => {
    if (data.success !== "") {
      toast.update(toastId.current, {
        type: toast.TYPE.SUCCESS,
        render: data.success,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setDeleteModal(false);
      setLoadingListData(true);
    }
    if (data.error !== "") {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: data.error,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoadingListData(false);
    }
  }, [data.success, data.error]);

  const [loadingExport, setLoadingExport] = useState(false);
  const exportAll = async () => {
    try {
      setLoadingExport(true);
      const res = (await questionnaireService.getQuestionnaireDatatableExport({
        ...getDataTableQuery(datatableOptions, columns),
        filters,
      })) as any;

      if (res) {
        fileDownload(res.data, `questionnaire-${dateTimeString()}-export.xlsx`);
        setLoadingExport(false);
      }
    } catch (err) {
      setLoadingExport(false);
    }
  };

  return (
    <React.Fragment>
      {questionaireData && (
        <DeleteModal
          recordName={recordName}
          modalTitle={t(
            `Questionnaire`
          )}
          show={deleteModal}
          onDeleteClick={handleDeleteData}
          onCloseClick={() => setDeleteModal(false)}
          loading={false}
        />
      )}


      {masterFormModal ? (
        <QuestionModal
          modalTitle={masterFormModalTitle}
          show={masterFormModal}
          // loading={loading}
          questionnaireData={questionnaireData}
          onSubmit={handleSubmitCompany}
          onCloseClick={() => setMasterFormModal(false)}
        //errorMsg={errorMsg}
        //errorData={errorData}
        />
      ) : (

        <div className={user_id || company_id ? "" : "page-content"}>
          <Container fluid>

            {!user_id && !company_id ? (
              <Breadcrumbs
                breadcrumbTitle={t('Questionnaires')}
                breadcrumbItems={breadcrumbItems}
                endAdornment={
                  <div className="d-flex gap-2 text-end">
                    <Link to="/questionnaires/all-questionnaires" className={`btn btn-info btn-label btn-sm`}>
                      <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                        list_alt
                      </span>
                      {t("List view")}
                    </Link>
                    {userProfile?.IS_GRAPHICAL_VIEW ? (
                      <Link to="/questionnaires/graph" className={`btn btn-outline-secondary btn-label btn-sm`}>
                        <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                          insert_chart
                        </span>
                        {t("Graphical view")}
                      </Link>
                    ) : null}

                    {userProfile.CAN_CREATE ? (
                      <Link to="/questionnaires/create-questionnaire" className="btn btn-primary btn-label btn-sm">
                        <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                          add
                        </span>{" "}
                        {t("Create questionnaire")}
                      </Link>
                    ) : null}
                  </div>
                }
              />

            ) : null}
            <Row>
              <Col xl="12">
                <Card>
                  {/* <CardHeader className="bg-transparent border-bottom">
                  <div className="d-sm-flex flex-wrap">
                    <CardTitle className="card-title text-uppercase">
                      Questionnaire list
                    </CardTitle>
                    <div className="ms-auto d-flex gap-2">
                        
                    </div>
                  </div>
                </CardHeader> */}
                  <CardBody>
                    <DataTable
                      columns={columns}
                      products={questionnairelist}
                      datatableOptions={datatableOptions}
                      filters={filters}
                      handleLoadingstate={handleLoadingstate}
                      onTableChange={onTableChange}
                      loadingListData={loadingListData}
                      clearFilter={clearFilter}
                      settingTableName="questionnaire_list_table"
                      slug="all-questionnaires"
                      customButtons={
                        <>
                          {!company_id && !user_id && userProfile.CAN_VIEW_ALL ? (
                            <Button
                              title={t("Filter")}
                              outline={filters.is_filtered === true ? false : true}
                              color={filters.is_filtered === true ? "secondary" : "light"}
                              onClick={() => setOpenFilter((state) => !state)}>
                              {filters.is_filtered === false ? (
                                <span className="align-middle material-symbols-outlined fw-light fs-4">filter_alt</span>
                              ) : (
                                <span className="align-middle material-symbols-outlined fw-light fs-4">filter_alt_off</span>
                              )}
                            </Button>
                          ) : null}

                          {userProfile.CAN_EXPORT ? (
                            <Button
                              title={t("Export all")}
                              outline={true}
                              color={"light"}
                              onClick={() => {
                                exportAll();
                              }}
                              disabled={loadingExport}
                            >
                              {loadingExport ? (
                                <span className="align-middle material-symbols-outlined fw-light fs-4 fa-spin">
                                  hourglass_empty
                                </span>
                              ) : (
                                <span className="align-middle material-symbols-outlined fw-light fs-4">
                                  export_notes
                                </span>
                              )}
                            </Button>
                          ) : null}
                        </>
                      }
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
      <Drawer
        title={t("Filters")}
        open={openFilter}
        onClose={() => setOpenFilter((state) => !state)}
        onReset={clearFilter}
        footer={
          <Button color="primary" style={{ margin: "16px" }} onClick={handleApplyFilters}>
            {t("Apply")}
          </Button>
        }
      >
        <Filters
          filters={filters}
          onApply={() => { }}
          onClear={() => { }}
          onChange={(obj: any) => handleFiltersChange(obj)}
          company_id={company_id}
        />
      </Drawer>
      {
        questionnaire?.company_details?.id &&
        <Drawer
          title={t('Client')}
          open={openCompany}
          onClose={() => { setOpenCompany((state) => !state); setLoadingListData(true); }}
          fullWidth
        >
          <EditCompany id={questionnaire?.company_details?.id} company_id={questionnaire?.company_details?.company_id} type={1} isWindow={true} />
        </Drawer>
      }
      {
        questionnaire?.user_data?.id &&
        <Drawer
          title={t('User')}
          open={openUser}
          onClose={() => { setOpenUser((state) => !state); setLoadingListData(true); }}
          fullWidth
        >
          <EditUser id={questionnaire?.user_data?.id} user_id={questionnaire?.user_data?.user_id} type={1} isWindow={true} />
        </Drawer>
      }
    </React.Fragment >
  );
};

export default List;
