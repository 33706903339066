import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  Label,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getFirstErrorElement } from "../../../helpers/functions_helper";
import { useTranslation } from "react-i18next";

const FeeForm = (props: any) => {
  const { t } = useTranslation();
  const feeData = props.feeData;

  const [intialData, setIntialData] = useState({
    id: "",
    fee_id: "",
    category: "",
    category_of_fees: "",
    description: "",
    category_french: "",
    category_of_fees_french: "",
    description_french: "",
    category_dutch: "",
    category_of_fees_dutch: "",
    description_dutch: "",
    fees_amount: "",
    fees_code: "",
    color_code: "#000000",
    estimated_time: "",
    is_preliminary_billing: 0,
    is_income_year: 0,
    is_income_year_onward: 0,
    is_assistant_administrative: 0,
    is_internal: 0,
    is_questionnaire: 0,
    is_sensitive: 0,
    is_resident: 0,
    is_non_resident: 0,
    is_tax_return_type: 0,
    is_ubo_aml:0,
  });

  Yup.setLocale({
    mixed: {
      required: "${label} " + t("field is required."),
    },
    string: {
      min: "${label} " + t("must be at least") + " ${min} " + t("characters."),
      max: "${label} " + t("may not be greater than") + " ${max} " + t("characters."),
    },
  });
  const validationSchema = Yup.object().shape({
    category: Yup.string()
      .label(t("Category"))
      .required()
      .min(2),
    category_of_fees: Yup.string()
      .label(t("category of fees"))
      .required()
      .min(2),
    description: Yup.string()
      .label(t("Description of the service"))
      .required()
      .min(2),
    category_french: Yup.string()
      .label(`${t("Category")} (${t("french")})`)
      .required()
      .min(2),
    category_of_fees_french: Yup.string()
      .label(`${t("category of fees")} (${t("french")})`)
      .required()
      .min(2),
    description_french: Yup.string()
      .label(`${t("Description of the service")} (${t("french")})`)
      .required()
      .min(2),
    category_dutch: Yup.string()
      .label(`${t("Category")} (${t("dutch")})`)
      .required()
      .min(2),
    category_of_fees_dutch: Yup.string()
      .label(`${t("category of fees")} (${t("dutch")})`)
      .required()
      .min(2),
    description_dutch: Yup.string()
      .label(`${t("Description of the service")} (${t("dutch")})`)
      .required()
      .min(2),
    fees_amount: Yup.string()
      .label(t("Fees amount"))
      .required(),
    fees_code: Yup.string()
      .label(t("Fees code"))
      .required()
      .min(2)
      .max(10),
    estimated_time: Yup.string()
      .label(t("Estimated time"))
      .required(),
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: intialData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handlePreliminaryBilling = () => {
    if (intialData.is_preliminary_billing === 1) {
      setIntialData((prevData: any) => ({
        ...prevData,
        is_preliminary_billing: 0,
      }));
    } else {
      setIntialData((prevData: any) => ({
        ...prevData,
        is_preliminary_billing: 1,
      }));
    }
  };

  const handleIncomeYear = () => {
    if (intialData.is_income_year === 1) {
      setIntialData((prevData: any) => ({ ...prevData, is_income_year: 0 }));
    } else {
      setIntialData((prevData: any) => ({
        ...prevData,
        is_income_year: 1,
        is_income_year_onward: 0,
      }));
    }
  };

  const handleIncomeYear_onward = () => {
    if (intialData.is_income_year_onward === 1) {
      setIntialData((prevData: any) => ({
        ...prevData,
        is_income_year_onward: 0,
      }));
    } else {
      setIntialData((prevData: any) => ({
        ...prevData,
        is_income_year_onward: 1,
        is_income_year: 0,
      }));
    }
  };

  const handleAssistantAdministrative = () => {
    if (intialData.is_assistant_administrative === 1) {
      setIntialData((prevData: any) => ({
        ...prevData,
        is_assistant_administrative: 0,
      }));
    } else {
      setIntialData((prevData: any) => ({
        ...prevData,
        is_assistant_administrative: 1,
      }));
    }
  };

  const handleInternal = () => {
    if (intialData.is_internal === 1) {
      setIntialData((prevData: any) => ({ ...prevData, is_internal: 0 }));
    } else {
      setIntialData((prevData: any) => ({ ...prevData, is_internal: 1 }));
    }
  };

  const handleQuestionnaire = () => {
    if (intialData.is_questionnaire === 1) {
      setIntialData((prevData: any) => ({ ...prevData, is_questionnaire: 0 }));
    } else {
      setIntialData((prevData: any) => ({ ...prevData, is_questionnaire: 1 }));
    }
  };

  const handleSensitiveChange = () => {
    if (intialData.is_sensitive === 1) {
      setIntialData((prevData: any) => ({ ...prevData, is_sensitive: 0 }));
    } else {
      setIntialData((prevData: any) => ({ ...prevData, is_sensitive: 1 }));
    }
  };

  const handleResident = () => {
    if (intialData.is_resident === 1) {
      setIntialData((prevData: any) => ({ ...prevData, is_resident: 0 }));
    } else {
      setIntialData((prevData: any) => ({
        ...prevData,
        is_resident: 1,
        is_non_resident: 0,
      }));
    }
  };

  const handleNonResident = () => {
    if (intialData.is_non_resident === 1) {
      setIntialData((prevData: any) => ({
        ...prevData,
        is_non_resident: 0,
      }));
    } else {
      setIntialData((prevData: any) => ({
        ...prevData,
        is_non_resident: 1,
        is_resident: 0,
      }));
    }
  };

  const handleTaxReturnType = () => {
    if (intialData.is_tax_return_type === 1) {
      setIntialData((prevData: any) => ({ ...prevData, is_tax_return_type: 0 }));
    } else {
      setIntialData((prevData: any) => ({ ...prevData, is_tax_return_type: 1 }));
    }
  };

  const handleUboAml = () => {
    if (intialData.is_ubo_aml === 1) {
      setIntialData((prevData: any) => ({ ...prevData, is_ubo_aml: 0 }));
    } else {
      setIntialData((prevData: any) => ({ ...prevData, is_ubo_aml: 1 }));
    }
  };

  const handleChange = (fieldName: string, fieldValue: string) => {
    setIntialData((preState) => ({ ...preState, [fieldName]: fieldValue }));
  };

  const handleSubmit = (values: {
    id: string;
    fee_id: string;
    category: string;
    category_of_fees: string;
    description: string;
    category_french: string;
    category_of_fees_french: string;
    description_french: string;
    category_dutch: string;
    category_of_fees_dutch: string;
    description_dutch: string;
    fees_amount: string;
    fees_code: string;
    color_code: string;
    estimated_time: string;
    is_preliminary_billing: number;
    is_income_year: number;
    is_income_year_onward: number;
    is_assistant_administrative: number;
    is_internal: number;
    is_questionnaire: number;
    is_sensitive: number;
    is_resident: number;
    is_non_resident: number;
    is_tax_return_type: number;
    is_ubo_aml:number;
  }) => {
    props.onSubmitClick(values);
  };

  useEffect(() => {
    setIntialData(feeData);
  }, [feeData]);

  useEffect(() => {
    if (!validation.isSubmitting) return;
    if (Object.keys(validation.errors).length > 0) {
      const element = getFirstErrorElement(validationSchema, validation);
      document
        .getElementById(element)
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
      document.getElementById(element)?.focus({ preventScroll: true });
    }
  }, [validation.isSubmitting, validation.errors]);

  return (
    <React.Fragment>
      <Modal
        size="xl"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">{props.modalTitle}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label={t("Close")}
          ></button>
        </div>

        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          autoComplete="off"
        >
          <ModalBody>
            <Input
              id="id"
              name="id"
              type="hidden"
              value={intialData.id || ""}
            />
            <Input
              id="fee_id"
              name="fee_id"
              type="hidden"
              value={intialData.fee_id || ""}
            />

            <Row>
              <Col className="col-12">
                <Row>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">
                        <span className="badge badge-soft-primary me-2">
                          {t("English")}
                        </span>
                        {t("Category (Object)")}{" "}
                        <span className="text-warning is_required">*</span>
                      </Label>
                      <Input
                        id="category"
                        name="category"
                        className="form-control"
                        type="text"
                        value={intialData.category || ""}
                        autoFocus={true}
                        onBlur={validation.handleBlur}
                        onChange={(e) =>
                          handleChange("category", e.target.value)
                        }
                        invalid={validation.errors.category ? true : false}
                      />
                      {validation.errors.category ? (
                        <FormFeedback type="invalid">
                          {validation.errors.category}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">
                        {t("Category of fees")}{" "}
                        <span className="text-warning is_required">*</span>
                      </Label>
                      <Input
                        id="category_of_fees"
                        name="category_of_fees"
                        className="form-control"
                        type="text"
                        value={intialData.category_of_fees || ""}
                        onBlur={validation.handleBlur}
                        onChange={(e) =>
                          handleChange("category_of_fees", e.target.value)
                        }
                        invalid={
                          validation.errors.category_of_fees ? true : false
                        }
                      />
                      {validation.errors.category_of_fees ? (
                        <FormFeedback type="invalid">
                          {validation.errors.category_of_fees}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">
                        {t("Description of the service")}{" "}
                        <span className="text-warning is_required">*</span>
                      </Label>
                      <Input
                        id="description"
                        name="description"
                        className="form-control"
                        type="text"
                        maxLength={500}
                        value={intialData.description || ""}
                        onBlur={validation.handleBlur}
                        onChange={(e) =>
                          handleChange("description", e.target.value)
                        }
                        invalid={validation.errors.description ? true : false}
                      />
                      {validation.errors.description ? (
                        <FormFeedback type="invalid">
                          {validation.errors.description}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col className="col-12">
                <Row>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">
                        <span className="badge badge-soft-info me-2">
                          {t("French")}
                        </span>
                        {t("Category (Object)")}{" "}
                        <span className="text-warning is_required">*</span>
                      </Label>
                      <Input
                        id="category_french"
                        name="category_french"
                        className="form-control"
                        type="text"
                        value={intialData.category_french || ""}
                        onBlur={validation.handleBlur}
                        onChange={(e) =>
                          handleChange("category_french", e.target.value)
                        }
                        invalid={
                          validation.errors.category_french ? true : false
                        }
                      />
                      {validation.errors.category_french ? (
                        <FormFeedback type="invalid">
                          {validation.errors.category_french}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">
                        {t("Category of fees")}{" "}
                        <span className="text-warning is_required">*</span>
                      </Label>
                      <Input
                        id="category_of_fees_french"
                        name="category_of_fees_french"
                        className="form-control"
                        type="text"
                        value={intialData.category_of_fees_french || ""}
                        onBlur={validation.handleBlur}
                        onChange={(e) =>
                          handleChange(
                            "category_of_fees_french",
                            e.target.value
                          )
                        }
                        invalid={
                          validation.errors.category_of_fees_french
                            ? true
                            : false
                        }
                      />
                      {validation.errors.category_of_fees_french ? (
                        <FormFeedback type="invalid">
                          {validation.errors.category_of_fees_french}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">
                        {t("Description of the service")}{" "}
                        <span className="text-warning is_required">*</span>
                      </Label>
                      <Input
                        id="description_french"
                        name="description_french"
                        className="form-control"
                        type="text"
                        maxLength={500}
                        value={intialData.description_french || ""}
                        onBlur={validation.handleBlur}
                        onChange={(e) =>
                          handleChange("description_french", e.target.value)
                        }
                        invalid={
                          validation.errors.description_french ? true : false
                        }
                      />
                      {validation.errors.description_french ? (
                        <FormFeedback type="invalid">
                          {validation.errors.description_french}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col className="col-12">
                <Row>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">
                        <span className="badge badge-soft-danger me-2">
                          {t("Dutch")}
                        </span>{" "}
                        {t("Category (Object)")}{" "}
                        <span className="text-warning is_required">*</span>
                      </Label>
                      <Input
                        id="category_dutch"
                        name="category_dutch"
                        className="form-control"
                        type="text"
                        value={intialData.category_dutch || ""}
                        onBlur={validation.handleBlur}
                        onChange={(e) =>
                          handleChange("category_dutch", e.target.value)
                        }
                        invalid={
                          validation.errors.category_dutch ? true : false
                        }
                      />
                      {validation.errors.category_dutch ? (
                        <FormFeedback type="invalid">
                          {validation.errors.category_dutch}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">
                        {t("Category of fees")}{" "}
                        <span className="text-warning is_required">*</span>
                      </Label>
                      <Input
                        id="category_of_fees_dutch"
                        name="category_of_fees_dutch"
                        className="form-control"
                        type="text"
                        value={intialData.category_of_fees_dutch || ""}
                        onBlur={validation.handleBlur}
                        onChange={(e) =>
                          handleChange("category_of_fees_dutch", e.target.value)
                        }
                        invalid={
                          validation.errors.category_of_fees_dutch
                            ? true
                            : false
                        }
                      />
                      {validation.errors.category_of_fees_dutch ? (
                        <FormFeedback type="invalid">
                          {validation.errors.category_of_fees_dutch}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">
                        {t("Description of the service")}{" "}
                        <span className="text-warning is_required">*</span>
                      </Label>
                      <Input
                        id="description_dutch"
                        name="description_dutch"
                        className="form-control"
                        type="text"
                        maxLength={500}
                        value={intialData.description_dutch || ""}
                        onBlur={validation.handleBlur}
                        onChange={(e) =>
                          handleChange("description_dutch", e.target.value)
                        }
                        invalid={
                          validation.errors.description_dutch ? true : false
                        }
                      />
                      {validation.errors.description_dutch ? (
                        <FormFeedback type="invalid">
                          {validation.errors.description_dutch}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>

          <ModalBody className="border-top">
            <Row>
              <Col xl={4} lg={4} md={4} sm={12}>
                <div className="mb-3">
                  <Label className="form-label">
                    {t("Fees amount")}{" "}
                    <span className="text-warning is_required">*</span>
                  </Label>
                  <Input
                    id="fees_amount"
                    name="fees_amount"
                    className="form-control"
                    type="number"
                    value={intialData.fees_amount || ""}
                    onBlur={validation.handleBlur}
                    onChange={(e) =>
                      handleChange("fees_amount", e.target.value)
                    }
                    invalid={validation.errors.fees_amount ? true : false}
                  />
                  {validation.errors.fees_amount ? (
                    <FormFeedback type="invalid">
                      {validation.errors.fees_amount}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col xl={4} lg={4} md={4} sm={12}>
                <div className="mb-3">
                  <Label className="form-label">
                    {t("Fees code")}{" "}
                    <span className="text-warning is_required">*</span>
                  </Label>
                  <div className="color_palette_main_div">
                    <Input
                      id="fees_code"
                      name="fees_code"
                      className="form-control"
                      type="text"
                      value={intialData.fees_code || ""}
                      onBlur={validation.handleBlur}
                      onChange={(e) =>
                        handleChange("fees_code", e.target.value)
                      }
                      invalid={validation.errors.fees_code ? true : false}
                    />
                    {validation.errors.fees_code ? (
                      <FormFeedback type="invalid">
                        {validation.errors.fees_code}
                      </FormFeedback>
                    ) : null}

                    <div className="color_palette_div">
                      <Input
                        id="color_code"
                        name="color_code"
                        type="color"
                        value={intialData.color_code || ""}
                        className="color_palette"
                        onBlur={validation.handleBlur}
                        onChange={(e) =>
                          handleChange("color_code", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </Col>

              <Col xl={4} lg={4} md={4} sm={12}>
                <div className="mb-3">
                  <Label className="form-label">
                    {t("Estimated time")}{" "}
                    <span className="text-warning is_required">*</span>
                  </Label>
                  <Flatpickr
                    id="estimated_time"
                    name="estimated_time"
                    className="form-control"
                    type="text"
                    value={intialData.estimated_time || ""}
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: "H:i",
                      time_24hr: true,
                      allowInput: true,
                      defaultHour: 0,
                      defaultMinute: 15,
                      onChange: function (selectedDates, dateStr, instance) {
                        handleChange("estimated_time", dateStr);
                      },
                      onClose: function (selectedDates, dateStr, instance) {
                        handleChange("estimated_time", dateStr);
                      },
                      locale: { firstDayOfWeek: 1 },
                    }}
                  />
                  {validation.errors.estimated_time ? (
                    <FormFeedback type="invalid">
                      {validation.errors.estimated_time}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col className="col-12"></Col>

              <Col className="col-12 align-items-center">
                <div className="mb-3">
                  <Label className="form-label mb-3">{t("Type support for")}</Label>

                  <Row>
                    <Col xl={4} lg={4} md={4} sm={12}>
                      <div className="form-check mb-3">
                        <input
                          id="is_preliminary_billing"
                          name="is_preliminary_billing"
                          className="form-check-input"
                          type="checkbox"
                          value={intialData.is_preliminary_billing}
                          checked={
                            intialData.is_preliminary_billing === 1
                              ? true
                              : false
                          }
                          onBlur={validation.handleBlur}
                          onChange={handlePreliminaryBilling}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="is_preliminary_billing"
                        >
                          {t("Preliminary billing")}
                        </label>
                      </div>
                    </Col>

                    <Col xl={4} lg={4} md={4} sm={12}>
                      <div className="form-check mb-3">
                        <input
                          id="is_income_year"
                          name="is_income_year"
                          className="form-check-input"
                          type="checkbox"
                          value={intialData.is_income_year}
                          checked={
                            intialData.is_income_year === 1 ? true : false
                          }
                          onBlur={validation.handleBlur}
                          onChange={handleIncomeYear}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="is_income_year"
                        >
                          {t("Income year")}
                        </label>
                      </div>
                    </Col>

                    <Col xl={4} lg={4} md={4} sm={12}>
                      <div className="form-check mb-3">
                        <input
                          id="is_income_year_onward"
                          name="is_income_year_onward"
                          className="form-check-input"
                          type="checkbox"
                          value={intialData.is_income_year_onward}
                          checked={
                            intialData.is_income_year_onward === 1
                              ? true
                              : false
                          }
                          onBlur={validation.handleBlur}
                          onChange={handleIncomeYear_onward}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="is_income_year_onward"
                        >
                          {t("Income year onward")}
                        </label>
                      </div>
                    </Col>

                    <Col xl={4} lg={4} md={4} sm={12}>
                      <div className="form-check mb-3">
                        <input
                          id="is_assistant_administrative"
                          name="is_assistant_administrative"
                          className="form-check-input"
                          type="checkbox"
                          value={intialData.is_assistant_administrative}
                          checked={
                            intialData.is_assistant_administrative === 1
                              ? true
                              : false
                          }
                          onBlur={validation.handleBlur}
                          onChange={handleAssistantAdministrative}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="is_assistant_administrative"
                        >
                          {t("Assistant administrative")}
                        </label>
                      </div>
                    </Col>

                    <Col xl={4} lg={4} md={4} sm={12}>
                      <div className="form-check mb-3">
                        <input
                          id="is_internal"
                          name="is_internal"
                          className="form-check-input"
                          type="checkbox"
                          value={intialData.is_internal}
                          checked={intialData.is_internal === 1 ? true : false}
                          onBlur={validation.handleBlur}
                          onChange={handleInternal}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="is_internal"
                        >
                          {t("Internal")}
                        </label>
                      </div>
                    </Col>

                    <Col xl={4} lg={4} md={4} sm={12}>
                      <div className="form-check mb-3">
                        <input
                          id="is_questionnaire"
                          name="is_questionnaire"
                          className="form-check-input"
                          type="checkbox"
                          value={intialData.is_questionnaire}
                          checked={
                            intialData.is_questionnaire === 1 ? true : false
                          }
                          onBlur={validation.handleBlur}
                          onChange={handleQuestionnaire}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="is_questionnaire"
                        >
                          {t("Questionnaire")}
                        </label>
                      </div>
                    </Col>

                    <Col xl={4} lg={4} md={4} sm={12}>
                      <div className="form-check mb-3">
                        <input
                          id="is_sensitive"
                          name="is_sensitive"
                          className="form-check-input"
                          type="checkbox"
                          value={intialData.is_sensitive}
                          checked={intialData.is_sensitive === 1 ? true : false}
                          onBlur={validation.handleBlur}
                          onChange={handleSensitiveChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="is_sensitive"
                        >
                          {t("Sensitive")}
                        </label>
                      </div>
                    </Col>

                    <Col xl={4} lg={4} md={4} sm={12}>
                      <div className="form-check mb-3">
                        <input
                          id="is_resident"
                          name="is_resident"
                          className="form-check-input"
                          type="checkbox"
                          value={intialData.is_resident}
                          checked={
                            intialData.is_resident === 1 ? true : false
                          }
                          onBlur={validation.handleBlur}
                          onChange={handleResident}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="is_resident"
                        >
                          {t("Resident")}
                        </label>
                      </div>
                    </Col>

                    <Col xl={4} lg={4} md={4} sm={12}>
                      <div className="form-check mb-3">
                        <input
                          id="is_non_resident"
                          name="is_non_resident"
                          className="form-check-input"
                          type="checkbox"
                          value={intialData.is_non_resident}
                          checked={
                            intialData.is_non_resident === 1
                              ? true
                              : false
                          }
                          onBlur={validation.handleBlur}
                          onChange={handleNonResident}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="is_non_resident"
                        >
                          {t("Non-Resident")}
                        </label>
                      </div>
                    </Col>

                    <Col xl={4} lg={4} md={4} sm={12}>
                      <div className="form-check mb-3">
                        <input
                          id="is_tax_return_type"
                          name="is_tax_return_type"
                          className="form-check-input"
                          type="checkbox"
                          value={intialData.is_tax_return_type}
                          checked={
                            intialData.is_tax_return_type === 1
                              ? true
                              : false
                          }
                          onBlur={validation.handleBlur}
                          onChange={handleTaxReturnType}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="is_tax_return_type"
                        >
                          {t("Tax return type")}
                        </label>
                      </div>
                    </Col>

                    <Col xl={4} lg={4} md={4} sm={12}>
                      <div className="form-check mb-3">
                        <input
                          id="is_ubo_aml"
                          name="is_ubo_aml"
                          className="form-check-input"
                          type="checkbox"
                          value={intialData.is_ubo_aml}
                          checked={
                            intialData.is_ubo_aml === 1
                              ? true
                              : false
                          }
                          onBlur={validation.handleBlur}
                          onChange={handleUboAml}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="is_ubo_aml"
                        >
                          UBO {t("or")} AML
                        </label>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              className="btn-block"
              type="submit"
              disabled={props.loading}
            >
              {props.loading ? (
                <span>
                  <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                    hourglass_empty
                  </span>
                  <span>{t("Please wait...")}</span>
                </span>
              ) : (
                <span>{t("Submit")}</span>
              )}
            </Button>
            <Button
              color="danger"
              className="btn-block"
              type="reset"
              onClick={props.onCloseClick}
              disabled={props.loading}
            >
              {t("Cancel")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default FeeForm;
