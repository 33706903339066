import React, { FC, useState, useEffect } from 'react';
import { Col, Container, FormGroup, Label, OffcanvasBody, Row } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import moment from 'moment';
import { toast } from 'react-toastify';
import { invoiceStatuses } from '../../../common/staticdata';
import { formatCurrency } from '../../../utils/formats';
import disputesService from '../../../services/disputes.services';
import Button from '../../../components/Button/Button';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/Form/Input';
import EditInvoice from '../EditInvoice';
import EditInvoiceForm from './EditInvoice';
import WrongRecipientForm from './WrongRecipientForm';



type DisputeFormProps = {
    values: any;
    onApply?: any;
    onClose: any;
    onChange?: any;
}

type DisputeFormType = {
    invoice_id: number;
    dispute_date: string | any;
    amount: number | string;
    comment: string;
    raise_for: number;
    deleted_items?: Array<number>;
    recipient: {
        company: any,
        address: any,
        contact_person_name: any,
        contact_person_email: any,
        tva_number: any,
        company_type: number
    }
    isSubmitting: boolean
}

const DisputeForm: FC<DisputeFormProps> = ({ values, onApply, onClose, onChange }) => {
    const toastId = React.useRef(null) as any;
    const { t } = useTranslation();

    const [formValues, setFormValues] = useState<DisputeFormType>({
        invoice_id: values.id,
        dispute_date: moment(new Date()).format('YYYY-MM-DD'),
        amount: values.paid_amount,
        comment: '',
        raise_for: 1,
        deleted_items: [],
        recipient: {
            company: null,
            address: null,
            contact_person_name: null,
            contact_person_email: null,
            tva_number: null,
            company_type: 1
        },
        isSubmitting: false
    });
    const [errors, setErrors] = useState<any>({
        amount: null,
        comment: null,
        company: null,
        address: null,
        contact_person_name: null,
        contact_person_email: null,
        tva_number: null
    });

    const handleSubmit = async (values: DisputeFormType) => {
        if (values.recipient.company_type === 1 && values.raise_for === 3) {
            if (!values.recipient.company) {
                setErrors((errs: any) => ({ ...errs, company: 'Company is required.' }));
                return false;
            }
        } else {
            setErrors((errs: any) => ({ ...errs, company: null }));
        }
        if (!values.amount) {
            setErrors((errs: any) => ({ ...errs, amount: 'Amount field is required.' }));
            return false;
        }
        if (!values.comment) {
            setErrors((errs: any) => ({ ...errs, comment: 'Comment field is required.' }));
            return false;
        }
        toastId.current = toast(t("Submitting dispute..."), { autoClose: false, isLoading: true });
        setFormValues((state: DisputeFormType) => ({ ...state, isSubmitting: true }))
        const result = await disputesService.create(values);
        if (result.status === 'success') {
            onApply();
            onClose();
            toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: t('Dispute submitted successfully'), autoClose: 5000, isLoading: false, delay: 500 });
        }
        setFormValues((state: DisputeFormType) => ({ ...state, isSubmitting: false }))
    }

    useEffect(() => {
        if (formValues.raise_for === 3) {
            setFormValues((state: DisputeFormType) => ({ ...state, amount: values.total, comment: t('Sent to wrong receipient') }))
        } else {
            setFormValues((state: DisputeFormType) => ({ ...state, amount: 0, comment: '' }))
        }
        setFormValues((state: DisputeFormType) => ({ ...state, deleted_items: [] }))
    }, [formValues.raise_for])

    useEffect(() => {
        if (formValues.raise_for === 3 && formValues.recipient.company_type === 1) {
            if (!formValues.recipient.company) {
                setErrors((errs: any) => ({ ...errs, company: t('Company is required.') }));
            } else {
                setErrors((errs: any) => ({ ...errs, company: null }));
            }
        } else {
            setErrors((errs: any) => ({ ...errs, company: null }));
        }

        if (formValues.raise_for === 1) {
            if (!formValues.comment || formValues.comment === '') {
                setErrors((errs: any) => ({ ...errs, comment: t('Please enter your comment') }));
            } else {
                setErrors((errs: any) => ({ ...errs, comment: null }));
            }
            if (formValues.amount <= 0) {
                setErrors((errs: any) => ({ ...errs, amount: t('Please enter amount') }));
            } else {
                setErrors((errs: any) => ({ ...errs, amount: null }));
            }
            if (values.total < formValues?.amount) {
                setErrors((state: any) => ({ ...state, amount: t('Dispute amount must be less than ') + formatCurrency(values.total) }))
            } else {
                setErrors((state: any) => ({ ...state, amount: null }))

            }
        } else {
            setErrors((errs: any) => ({ ...errs, comment: null }));
            setErrors((errs: any) => ({ ...errs, amount: null }));
        }

        var validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (formValues.recipient.company && !formValues?.recipient?.contact_person_email?.match(validRegex)) {
            setErrors((errs: any) => ({ ...errs, contact_person_email: t('Please enter valid email.') }));
        } else {
            setErrors((errs: any) => ({ ...errs, contact_person_email: null }));
        }
    }, [formValues])

    return (
        <React.Fragment>
            <OffcanvasBody>
                {/* <Row>
                    <Col>
                        <div className="mb-3">
                            <h5 className="invoice-number">
                                INVOICE NUMBER: {values?.invoice_number}
                            </h5>

                        </div>
                    </Col>
                </Row> */}
                <Row>
                    <Col>
                        <FormGroup tag="fieldset" row>
                            <Label>{t("Dispute raise for")}</Label>
                            <Col sm={10}>
                                <FormGroup check>
                                    <Input
                                        type="radio"
                                        checked={formValues.raise_for === 1}
                                        label='Amount'
                                        value={1}
                                        name="raise_for"
                                        id="raise_for_1"
                                        onChange={(e: any) => setFormValues((state: DisputeFormType) => ({ ...state, raise_for: 1 }))}
                                    />
                                </FormGroup>
                                <FormGroup check>
                                    <Input
                                        type="radio"
                                        checked={formValues.raise_for === 2}
                                        label='Wrong items'
                                        value={2}
                                        name="raise_for"
                                        id="raise_for_2"
                                        onChange={(e: any) => setFormValues((state: DisputeFormType) => ({ ...state, raise_for: 2 }))}
                                    />
                                </FormGroup>
                                <FormGroup check>
                                    <Input
                                        type="radio"
                                        checked={formValues.raise_for === 3}
                                        label='Sent to wrong recipient'
                                        value={3}
                                        name="raise_for"
                                        id="raise_for_3"
                                        onChange={(e: any) => setFormValues((state: DisputeFormType) => ({ ...state, raise_for: 3 }))}
                                    />
                                </FormGroup>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                {(formValues.raise_for === 1) &&
                    <>
                        <Row>
                            <Col>
                                <div className="mb-3">
                                    <Label className="form-label">
                                        {t("Date")}
                                    </Label>
                                    <Row>
                                        <Col>
                                            <div className="input-group is-invalid">
                                                <Flatpickr
                                                    id="refund_date"
                                                    name="refund_date"
                                                    className="form-control d-block"
                                                    type="text"
                                                    placeholder="DD/MM/YYYY"
                                                    value={formValues?.dispute_date}
                                                    readOnly
                                                    disabled={true}
                                                    options={{
                                                        altInput: true,
                                                        altFormat: "d/m/Y",
                                                        dateFormat: "Y-m-d",
                                                        defaultDate: values?.refund_date,
                                                        allowInput: true,
                                                        onChange: function (
                                                            selectedDates,
                                                            dateStr,
                                                            instance
                                                        ) {
                                                            setFormValues((state: DisputeFormType) => ({ ...state, refund_date: dateStr }))
                                                        },
                                                        locale: { firstDayOfWeek: 1 }
                                                    }}
                                                />
                                                <span className="input-group-text">
                                                    <span className="align-middle material-symbols-outlined fw-light fs-4">calendar_today</span>
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="mb-3">
                                    <Input
                                        id={`amount`}
                                        name='amount'
                                        type='number'
                                        placeholder={'100'}
                                        label={t("Amount")}
                                        required
                                        touched={!!errors?.amount}
                                        error={errors?.amount}
                                        className='rightAlign'
                                        value={formValues?.amount}
                                        onChange={(e) => {

                                            setFormValues((state: DisputeFormType) => ({ ...state, amount: e.target.value }));
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className="mb-3">
                                    <Input
                                        id={`comment`}
                                        name='comment'
                                        type='textarea'
                                        placeholder={t('Enter reason for raising the dispute here')}
                                        label={t("Comment")}
                                        required
                                        touched={!!errors?.comment}
                                        error={errors?.comment}
                                        className=''
                                        value={formValues?.comment || ''}
                                        onChange={(e) => {
                                            setFormValues((state: DisputeFormType) => ({ ...state, comment: e.target.value }))
                                        }}
                                    />
                                </div>

                            </Col>
                        </Row>
                    </>
                }
                {formValues.raise_for === 2 && <Col><EditInvoiceForm values={values} formValues={formValues} setFormValues={setFormValues} errors={errors} onClose={() => { }} /></Col>}
                {formValues.raise_for === 3 && <Col><WrongRecipientForm values={values} formValues={formValues} setFormValues={setFormValues} errors={errors} onClose={() => { }} /></Col>}


            </OffcanvasBody>
            <Col>
                <Button
                    color="primary"
                    style={{ margin: "16px", width: "calc(100% - 64px)", top: "calc(100vh - 70px)", position: "absolute" }}
                    onClick={() => handleSubmit(formValues)}
                    disabled={formValues.isSubmitting || formValues.amount <= 0 || Object.values(errors).some((err: any) => err !== null)}
                    loading={formValues.isSubmitting}
                    fullWidth
                >
                    {t("Submit")}
                </Button>
                {/* {JSON.stringify(errors)} */}
            </Col>
        </React.Fragment >
    )
}

export default DisputeForm;