/* tab */
export const GET_QUESTIONNAIRE_TABS = "GET_QUESTIONNAIRE_TABS"
export const GET_QUESTIONNAIRE_TABS_SUCCESS = "GET_QUESTIONNAIRE_TAB_SUCCESS"
export const GET_QUESTIONNAIRE_TABS_FAIL = "GET_QUESTIONNAIRE_TABS_FAIL"

/* tab list*/
export const GET_QUESTIONNAIRE_TAB_LIST = "GET_QUESTIONNAIRE_TAB_LIST"
export const GET_QUESTIONNAIRE_TAB_LIST_SUCCESS = "GET_QUESTIONNAIRE_TAB_LIST_SUCCESS"
export const GET_QUESTIONNAIRE_TAB_LIST_FAIL = "GET_QUESTIONNAIRE_TAB_LIST_FAIL"

/* tab DETAIL*/
export const GET_QUESTIONNAIRE_TAB_DETAIL = "GET_QUESTIONNAIRE_TAB_DETAIL"
export const GET_QUESTIONNAIRE_TAB_DETAIL_SUCCESS = "GET_QUESTIONNAIRE_TAB_DETAIL_SUCCESS"
export const GET_QUESTIONNAIRE_TAB_DETAIL_FAIL = "GET_QUESTIONNAIRE_TAB_DETAIL_FAIL"

/**
 * add Tab
 */
export const ADD_NEW_QUESTIONNAIRE_TAB = "ADD_NEW_QUESTIONNAIRE_TAB"
export const ADD_QUESTIONNAIRE_TAB_SUCCESS = "ADD_QUESTIONNAIRE_TAB_SUCCESS"
export const ADD_QUESTIONNAIRE_TAB_FAIL = "ADD_QUESTIONNAIRE_TAB_FAIL"

/**
 * Edit tab
 */
export const UPDATE_QUESTIONNAIRE_TAB = "UPDATE_QUESTIONNAIRE_TAB"
export const UPDATE_QUESTIONNAIRE_TAB_SUCCESS = "UPDATE_QUESTIONNAIRE_TAB_SUCCESS"
export const UPDATE_QUESTIONNAIRE_TAB_FAIL = "UPDATE_QUESTIONNAIRE_TAB_FAIL"

/**
 * Delete tab
 */
export const DELETE_QUESTIONNAIRE_TAB = "DELETE_QUESTIONNAIRE_TAB"
export const DELETE_QUESTIONNAIRE_TAB_SUCCESS = "DELETE_QUESTIONNAIRE_TAB_SUCCESS"
export const DELETE_QUESTIONNAIRE_TAB_FAIL = "DELETE_QUESTIONNAIRE_TAB_FAIL"