import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class QuestionnaireStatusService {
    async getQuestionnaireStatus(DATA: any) {
        const response = await axios
            .get(SERVER_URL + url.GET_QUESTIONNAIRE_STATUS + "/" + DATA.id + "/" + DATA.tbl_master_questionnaire_status,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async getQuestionnaireStatusList(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_QUESTIONNAIRE_STATUS_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            );
        return response;
    }

    async createQuestionnaireStatus(DATA: any) {
        const response = await axios
            .put(SERVER_URL + url.CREATE_QUESTIONNAIRE_STATUS, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async updateQuestionnaireStatus(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.UPDATE_QUESTIONNAIRE_STATUS, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async deleteQuestionnaireStatus(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.DELETE_QUESTIONNAIRE_STATUS, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }
}

export default new QuestionnaireStatusService();