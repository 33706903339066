import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper";
import * as url from "../helpers/url_helper";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class clientAllocation {
  async getAllUsers(data: any) {
    const response = await axios
      .post(SERVER_URL + url.CLIENT_ALLOCATION.GET_ALL_USERS, data, {
        headers: getAxiosConfigWithToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async getAllClients(data: any) {
    const response = await axios
      .post(SERVER_URL + url.CLIENT_ALLOCATION.GET_ALL_CLIENTS, data, {
        headers: getAxiosConfigWithToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async updateClient(DATA: any) {
    const response = await axios
      .post(SERVER_URL + url.CLIENT_ALLOCATION.UPDATE, DATA,
        {
          headers: getAxiosConfigWithToken()
        }
      ).then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }
}

export default new clientAllocation();
