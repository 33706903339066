import { takeEvery, call, put } from "redux-saga/effects";
import companyCategoryService from "../../../services/masters.company.category.service";
import { AnyAction } from "redux";

// CompanyCategory Redux States
import {
  GET_COMPANY_CATEGORY,
  GET_COMPANY_CATEGORY_LIST,
  CREATE_COMPANY_CATEGORY,
  UPDATE_COMPANY_CATEGORY,
  DELETE_COMPANY_CATEGORY,
} from './actionTypes';

import {
  getCompanyCategorySuccess,
  getCompanyCategoryError,

  getCompanyCategoryListSuccess,
  getCompanyCategoryListError,

  createCompanyCategorySuccess,
  createCompanyCategoryError,

  updateCompanyCategorySuccess,
  updateCompanyCategoryError,

  deleteCompanyCategorySuccess,
  deleteCompanyCategoryError,
} from './actions';

function* getCompanyCategory({ payload: { companycategory, history } }: AnyAction): any {
  try {
    const response = yield call(companyCategoryService.getCompanyCategory, companycategory);
    if (response.data && response.data.status === "success") {
      yield put(getCompanyCategorySuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getCompanyCategoryError(response.data));
    } else {
      yield put(getCompanyCategoryError(response));
    }
  } catch (error: any) {
    yield put(getCompanyCategoryError(error));
  }
}

function* getCompanyCategoryList({ payload: { query, history } }: AnyAction): any {
  try {
    const response = yield call(companyCategoryService.getCompanyCategoryList, query);
    if (response.data && response.data.status === "success") {
      yield put(getCompanyCategoryListSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getCompanyCategoryListError(response.data));
    } else {
      yield put(getCompanyCategoryListError(response));
    }
  } catch (error: any) {
    yield put(getCompanyCategoryListError(error));
  }
}

function* createCompanyCategory({ payload: { companycategory, history } }: AnyAction): any {
  try {
    const response = yield call(companyCategoryService.createCompanyCategory, companycategory);
    if (response.data && response.data.status === "success") {
      yield put(createCompanyCategorySuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(createCompanyCategoryError(response.data));
    } else {
      yield put(createCompanyCategoryError(response));
    }
  } catch (error: any) {
    yield put(createCompanyCategoryError(error));
  }
}

function* updateCompanyCategory({ payload: { companycategory, history } }: AnyAction): any {
  try {
    const response = yield call(companyCategoryService.updateCompanyCategory, companycategory);
    if (response.data && response.data.status === "success") {
      yield put(updateCompanyCategorySuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(updateCompanyCategoryError(response.data));
    } else {
      yield put(updateCompanyCategoryError(response));
    }
  } catch (error: any) {
    yield put(updateCompanyCategoryError(error));
  }
}

function* deleteCompanyCategory({ payload: { companycategory, history } }: AnyAction): any {
  try {
    const response = yield call(companyCategoryService.deleteCompanyCategory, companycategory);
    if (response.data && response.data.status === "success") {
      yield put(deleteCompanyCategorySuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(deleteCompanyCategoryError(response.data));
    } else {
      yield put(deleteCompanyCategoryError(response));
    }
  } catch (error: any) {
    yield put(deleteCompanyCategoryError(error));
  }
}

function* userFunctionSaga(): any {
  yield takeEvery(GET_COMPANY_CATEGORY, getCompanyCategory);
  yield takeEvery(GET_COMPANY_CATEGORY_LIST, getCompanyCategoryList);
  yield takeEvery(CREATE_COMPANY_CATEGORY, createCompanyCategory);
  yield takeEvery(UPDATE_COMPANY_CATEGORY, updateCompanyCategory);
  yield takeEvery(DELETE_COMPANY_CATEGORY, deleteCompanyCategory);
}

export default userFunctionSaga;
