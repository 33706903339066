import { memo } from "react";
import { CardHeader } from "reactstrap";

type TitleBarProps = {
    is_maxi: boolean,
    is_mini: boolean,
    title?: string,
    handleMinimize?: () => void,
    handleMaximum?: () => void,
    handleSaveClose?: () => void,
    t:any
}


const TitleBar = (props: TitleBarProps) => {
    const { is_mini, is_maxi, handleMaximum, handleMinimize, title, handleSaveClose,t } = props;
    const xtitle = typeof title === 'undefined' ? t('New Message') : title;
    return (
        <CardHeader className="bg-primary" style={{ padding: '10px 5px',cursor:'pointer' }}>
            <div className="d-flex float-end">
                {
                    is_mini ?
                        <span style={{ fontSize: '18px' }} data-tooltip="Maximum" className="material-symbols-outlined text-light fw-light" onClick={handleMinimize}>
                            maximize
                        </span> :
                        <span style={{ fontSize: '18px' }} data-tooltip="Minimize" className="material-symbols-outlined text-light fw-light" onClick={handleMinimize}>
                            minimize
                        </span>
                }

                {/* {
                    is_maxi ?
                        <span style={{ fontSize: '18px' }} className="material-symbols-outlined text-light fw-light" data-tooltip="Full screen (Shift for pop-out)" onClick={handleMaximum}>
                            open_in_full
                        </span> :
                        <span style={{ fontSize: '18px' }} className="material-symbols-outlined text-light fw-light" data-tooltip="Full screen (Shift for pop-out)" onClick={handleMaximum}>
                            open_in_full
                        </span>
                } */}
                <span style={{ fontSize: '18px' }} className="material-symbols-outlined text-light fw-light" data-tooltip="Save &amp; close" onClick={handleSaveClose}>
                    close
                </span>
            </div>
            <h6 className="mb-0 text-light">{xtitle}</h6>
        </CardHeader>
    )
}

export default memo(TitleBar);