import axios from "axios";
import { getAxiosConfigWithToken, getAxiosConfigWithAzureToken, getAxiosConfigFileUploadWithToken, getAxiosConfigWithAzureTokenOnly } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"
import { attachmentProps } from "../pages/Email/components/type";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const MICROSOFT_GRAPH_URL = 'https://graph.microsoft.com/v1.0';
// when token expire 
const ERROR_MSG = {
    code: "InvalidAuthenticationToken",
    message: "Access token has expired or is not yet valid."
};

const DEFAULT_QUERY = 'sender,receivedDateTime,subject,from,isRead,hasAttachments,bodyPreview&count=true';

class EmailService {

    async getInboxMails(param?: string) {
        const response = await axios
            .get(`${MICROSOFT_GRAPH_URL}/me/mailFolders/inbox/messages?$select=${DEFAULT_QUERY}` + param, {
                headers: await getAxiosConfigWithAzureToken(),
                responseType: "json",
            })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return ERROR_MSG;
            });
        return response;
    }

    async getEmailAttachment(id?: string) {
        const response = await axios
            .get(`${MICROSOFT_GRAPH_URL}/me/messages/${id}/attachments`, {
                headers: await getAxiosConfigWithAzureToken(),
                responseType: "json",
            })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }


    async getFolderList(id?: string) {
        const response = await axios
            .get(`${MICROSOFT_GRAPH_URL}/me/mailFolders`, {
                headers: await getAxiosConfigWithAzureToken(),
                responseType: "json",
            })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getEmail(id?: string) {
        const response = await axios
            .get(`${MICROSOFT_GRAPH_URL}/me/messages/${id}`, {
                headers: await getAxiosConfigWithAzureToken(),
                responseType: "json",
            })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }


    async getStarredMails(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_TASKS_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getImportantMails(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_TASKS_SUB_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getDraftMails(param?: string) {
        const response = await axios
            .get(`${MICROSOFT_GRAPH_URL}/me/mailFolders/drafts/messages?$select=${DEFAULT_QUERY}` + param, {
                headers: await getAxiosConfigWithAzureToken(),
                responseType: "json",
            })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return ERROR_MSG;
            });
        return response;
    }

    async getSentMails(param?: string) {
        const response = await axios
            .get(`${MICROSOFT_GRAPH_URL}/me/mailFolders/sentitems/messages?$select=${DEFAULT_QUERY}` + param, {
                headers: await getAxiosConfigWithAzureToken(),
                responseType: "json",
            })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return ERROR_MSG;
            });
        return response;
    }

    async getTrashMails(param?: string) {
        const response = await axios
            .get(`${MICROSOFT_GRAPH_URL}/me/mailFolders/deleteditems/messages?$select=${DEFAULT_QUERY}` + param, {
                headers: await getAxiosConfigWithAzureToken(),
                responseType: "json",
            })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return ERROR_MSG;
            });
        return response;
    }

    async getJunkMails(param?: string) {
        const response = await axios
            .get(`${MICROSOFT_GRAPH_URL}/me/mailFolders/junkemail/messages?$select=${DEFAULT_QUERY}` + param, {
                headers: await getAxiosConfigWithAzureToken(),
                responseType: "json",
            })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return ERROR_MSG;
            });
        return response;
    }


    async getFolderMail(params: { folder_id: string, query: string }) {
        const url = params.folder_id ==='etc' ? `${MICROSOFT_GRAPH_URL}/me/messages?$filter=(from/emailAddress/address) eq 'noreply-hrtaxone@taxconsult.be'`:`${MICROSOFT_GRAPH_URL}/me/mailFolders/${params.folder_id}/messages?$select=${DEFAULT_QUERY}` + params.query ;
        const response = await axios
            .get(url, {
                headers: await getAxiosConfigWithAzureToken(),
                responseType: "json",
            })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error.response.data.error || ERROR_MSG;
            });
        return response;
    }

    async searchMessage(search: string) {
        const postQuery = {
            "requests": [
                {
                    "entityTypes": [
                        "message"
                    ],
                    "query": {
                        "queryString": search
                    },
                    "from": 0,
                    "size": 25
                }
            ]
        };
        const response = await axios
            .post(`${MICROSOFT_GRAPH_URL}/search/query`, postQuery, {
                headers: await getAxiosConfigWithAzureToken(),
                responseType: "json",
            })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return ERROR_MSG;
            });
        return response;
    }


    async addNewInboxMail(DATA: any) {
        const ACCESS_TOKEN = localStorage.getItem("accessTokenSSO");
        const postData = { ...DATA, accessToken: ACCESS_TOKEN }
        const response = await axios
            .post(SERVER_URL + url.EMAIL.CREATE_DRAFT_MAIL, postData,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async deleteInboxMail(id: any) {
        const response = await axios
            .delete(MICROSOFT_GRAPH_URL + '/me/messages/' + id,
                {
                    headers: await getAxiosConfigWithAzureToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async readUnreadMail(data: any) {
        const postData = {
            isRead: data.isRead
        }
        const response = await axios
            .patch(MICROSOFT_GRAPH_URL + '/me/messages/' + data.id, postData,
                {
                    headers: await getAxiosConfigWithAzureToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async moveMessageToFolder(data: any) {
        const postData = {
            destinationId: data.destinationId
        }
        const response = await axios
            .post(MICROSOFT_GRAPH_URL + '/me/messages/' + data.id + '/move', postData,
                {
                    headers: await getAxiosConfigWithAzureToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }


    async sentMail(DATA: any) {
        const response = await axios
            .post(`${MICROSOFT_GRAPH_URL}/me/sendMail`, DATA, {
                headers: await getAxiosConfigWithAzureToken(),
                responseType: "blob",
            })
            .then(function (response) {
                //console.log(response);
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async searchUser(query: any) {
        const response = await axios
            .post(SERVER_URL + url.EMAIL.SEARCH_USER, query,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async searchAttachment(query: any) {
        const response = await axios
            .post(SERVER_URL + url.EMAIL.SEARCH_ATTACHMENT, query,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getAttachment(postData: attachmentProps) {
        const response = await axios
            .post(SERVER_URL + url.EMAIL.GET_FILE_SYSTEM_LIST, postData,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getUserEmail(query: any) {
        const response = await axios
            .post(SERVER_URL + url.EMAIL.GET_USER_LIST, query,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }


    // Email Template Services


    async getEmailTemplateList(query: any) {
        const response = await axios
            .post(SERVER_URL + url.EMAIL.GET_EMAIL_TEMPLATE_LIST, query,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async createEmailTemplate(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.EMAIL.CREATE_EMAIL_TEMPLATE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getEmailTemplate(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.EMAIL.GET_EMAIL_TEMPLATE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getEmailTemplateView(id: number) {
        const response = await axios
            .get(SERVER_URL + url.EMAIL.GET_EMAIL_TEMPLATE_VIEW + '/' + id,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async updateEmailTemplate(DATA: any) {
        const response = await axios
            .put(SERVER_URL + url.EMAIL.UPDATE_EMAIL_TEMPLATE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async deleteEmailTemplate(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.EMAIL.DELETE_EMAIL_TEMPLATE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async duplicateEmailTemplate(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.EMAIL.DUPLICATE_EMAIL_TEMPLATE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async searchTemplate(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.EMAIL.SEARCH_TEMPLATE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }


    async updateEmailTemplateAttachmentStatus(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.EMAIL.UPDATED_EMAIL_ATTACHMENT_STATUS, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getEmailTemplateCategory(id: number) {
        const response = await axios
            .get(SERVER_URL + url.EMAIL.GET_EMAIL_CATEGORY_LIST + '/' + id,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }


    //Backend Mail Send
    async sendMail(DATA: any) {
        const ACCESS_TOKEN = await getAxiosConfigWithAzureTokenOnly() || localStorage.getItem("accessTokenSSO");
        const postData = { ...DATA, accessToken: ACCESS_TOKEN }
        const response = await axios
            .post(SERVER_URL + url.EMAIL.SEND_EMAIL, postData,
                {
                    //headers: getAxiosConfigFileUploadWithToken(),
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async draftMail(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.EMAIL.DRAFT_EMAIL, DATA,
                {
                    headers: await getAxiosConfigWithAzureToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async trashMail(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.EMAIL.TRASH_EMAIL, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async uploadDraftMailAttachment(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.EMAIL.UPLOAD_DRAFT_MAIL_ATTACHMENT, DATA,
                {
                    headers: getAxiosConfigFileUploadWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getDraftMailAttachment(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.EMAIL.GET_DRAFT_MAIL_ATTACHMENT, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }



    async getContactPerson(emailid: string) {
        const response = await axios
            .get(SERVER_URL + url.EMAIL.GET_CONTACT_PERSON + '/' + emailid,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async deteteDraftFile(id: string) {
        const response = await axios
            .delete(SERVER_URL + url.EMAIL.DELETE_DRAFT_MAIL_ATTACHMENN + '/' + id,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getComment(data: string) {
        const response = await axios
            .post(SERVER_URL + url.EMAIL.GET_COMMENT_LIST, data,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }


    async signature(data: string) {
        const response = await axios
            .post(SERVER_URL + url.EMAIL.SIGNATURE, data,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getsignaturelist() {
        const response = await axios
            .get(SERVER_URL + url.EMAIL.GET_SIGNATURE,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getsignatureformail(data: number) {
        const response = await axios
            .get(SERVER_URL + url.EMAIL.GET_SIGNATURE_MAIL + '/' + data,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async updateSignatureForMail(data: any) {
        const response = await axios
            .post(SERVER_URL + url.EMAIL.UPDATE_SIGNATURE_MAIL, data,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getHTMLToPdf(data: any) {
        const response = await axios
            .post(SERVER_URL + url.EMAIL.GET_HTML_TO_PDF, data,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }





}

export default new EmailService();