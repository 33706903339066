import React, { FC, useEffect, useState } from 'react';
import { Card, CardBody, CardSubtitle, CardTitle, Col, Row } from 'reactstrap';
import newsLetterService from '../../../services/news.letter.service';
import { useTranslation } from 'react-i18next';
import { Letter } from 'react-letter';
type ViewTemplateProps = {
    id: number;
    news_letter_id: string;
}
const ViewNewsLetter: FC<ViewTemplateProps> = ({ id, news_letter_id }) => {
    const { t } = useTranslation();
    const [templateList, setTemplateList] = useState<any>({});
    const getTemplateList = async (id: number) => {
        const res = await newsLetterService.get(id, news_letter_id);
        if (res.status === "success") {
            setTemplateList(res?.data);
        }
    }

    useEffect(() => {
        getTemplateList(id);
    }, [id])


    return (
        <React.Fragment>
            <Row>
                {
                    templateList &&
                    <Col xl={12} >
                        <Card>
                            <CardBody>
                                <CardTitle tag="h5">
                                    {/* {item.subject} */}
                                </CardTitle>
                                <CardSubtitle
                                    className="mb-2 text-muted"
                                    tag="h6"
                                >
                                    {/* {item.language} */}
                                </CardSubtitle>
                            </CardBody>
                            <CardBody>
                                <Letter html={templateList.html}
                                    className="p-2"
                                />
                            </CardBody>
                        </Card>
                    </Col>
                }
            </Row>
        </React.Fragment >

    )
}

export default ViewNewsLetter;