import React, { FC, useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardTitle } from "reactstrap";
import { formatCurrency } from "../../../utils/formats";
import invoicesService from "../../../services/invoices.service";
import { toast } from "react-toastify";
import { select2Styles } from "../../../helpers/functions_helper";
import { options, yearOptions, currentYear } from "../../../common/staticdata";
import LoadingSpinner from "../../../components/Common/Loader";
import { noData } from "../../../utils/data";
import Chart from "../../../components/Chart";

type CashFlowProps = {
    hideHeading?: boolean;
}
const CashFlow: FC<CashFlowProps> = ({ hideHeading = false }) => {

    const { t } = useTranslation();

    const [loadingText, setLoadingText] = useState<string>('No data found');
    const [loading, setLoading] = useState<boolean>(true);
    const [formValues, setFormValues] = useState<any>({
        invoice_per_task_type: currentYear
    })
    const [categories, setCategories] = useState<any>([]);
    const [series, setSeries] = useState<any>([]);
    const [chartData, setChartData] = useState<any>({
        options: {
            chart: {
                type: 'line',
                height: 350,
                toolbar: {
                    show: false
                }
            },
            stroke: {
                width: [0, 4],
                curve: 'smooth'
            },

            dataLabels: {
                enabled: false,
                enabledOnSeries: [1],
                formatter: function (value: any, opts: any) {
                    return formatCurrency(value);
                },
            },
            labels: [],
            colors: ["#00e396", "#f1b44c"],

            yaxis: {
                type: 'string',
                labels: {
                    formatter: function (value: any) {
                        return formatCurrency(value);
                    }
                },
            },
            tooltip: {
                shared: true,
                intersect: false,
                y: {
                    formatter: function (y: any) {
                        if (typeof y !== "undefined") {
                            return formatCurrency(y);
                        }
                        return y;

                    }
                }
            },
            noData: noData
        },
        series: []
    });

    const getCashFlow = async (range: any) => {
        setLoadingText(t("Loading..."));
        try {
            const res = await invoicesService.getCashFlow(range);
            if (res.status === 'success') {
                setCategories(res.labels.map((a: any) => a));
                setSeries(res.tasksData.map((a: any) => ({ ...a, name: a?.name })));
                setChartData((state: any) => ({
                    ...state,
                    series: res.tasksData.map((a: any) => ({ ...a, name: t(a?.name) })),
                    options: {
                        ...state.options,
                        labels: res.labels.map((a: any) => t(a))
                    }
                }));
            }
            setLoadingText(t("No data found"));
            setLoading(false);
        } catch (error: any) {
            toast(error)
        }
    }

    useEffect(() => {
        setLoading(true)
    }, [formValues.invoice_per_task_type])

    useEffect(() => {
        loading && getCashFlow(formValues.invoice_per_task_type);
    }, [loading])

    useEffect(() => {
        setChartData((state: any) => ({
            ...state,
            series: series.map((a: any) => ({ ...a, name: t(a?.name) })),
            options: {
                ...state.options,
                labels: categories.map((a: any) => t(a))
            }
        }));
    }, [t])

    return (
        <>
            <div className="d-sm-flex flex-wrap">
                <CardTitle className="mb-3">{!hideHeading && t("Cash Flow")}</CardTitle>
                <div className="ms-auto">
                    <Select
                        id="invoice_per_task_type"
                        name="invoice_per_task_type"
                        className="form-select-sm myClassName"
                        styles={select2Styles}
                        value={yearOptions.find((i: any) => i.value === formValues.invoice_per_task_type)}
                        options={yearOptions}
                        onChange={(option: any) => {
                            setFormValues((state: any) => ({ ...state, invoice_per_task_type: option.value }))
                        }}
                    />
                </div>
            </div>
            <Chart loading={loading} options={chartData.options} series={chartData.series} type="bar" height={335} />
        </>

    )
}

export default CashFlow;