import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class TasksService {

    taskData: any = '';

    async getTask(DATA: any) {
        const response = await axios
            .get(SERVER_URL + url.GET_TASK + "/" + DATA.id + "/" + DATA.task_id,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getTasksList(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_TASKS_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getSubTasksList(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_TASKS_SUB_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async createTask(DATA: any) {
        const response = await axios
            .put(SERVER_URL + url.CREATE_TASK, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async updateTask(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.UPDATE_TASK, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async deleteTask(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.DELETE_TASK, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getUserTaskListUpComming(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_USER_TASK_UPCOMMING, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getUserTaskListInProcess(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_USER_TASK_INPROCESS, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getTaskAllocationByID(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(SERVER_URL + url.GET_TASK_ALLOCATION + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getTaskAllocationList(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(SERVER_URL + url.GET_TASKS_ALLOCATION_LIST + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getStatusWiseTaskCount(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(SERVER_URL + url.GET_STATUS_WISE_TASK_COUNT + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getOriginatedTaskTypeCount(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(SERVER_URL + url.GET_ORIGINATED_WISE_TASK_LIST + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getTaskRevenueStream(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_ORIGINATED_WISE_TASK_AMOUNT_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getTaskByCountry(query: any) {
        const response = await axios
            .get(SERVER_URL + url.GET_TASK_BY_COUNTRY+'?'+query,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getTaskGeneratedByQuestionnaire(query: any) {
        const response = await axios
            .get(SERVER_URL + url.GET_TASK_GENERATED_BY_QUESTIONNAIRE+'?'+query,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }


    
    async getInternalTaskVsExternalTask(query: any) {
        const response = await axios
            .get(SERVER_URL + url.GET_INTERNALTASK_VS_EXTERNALTASK+'?'+query,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async addFlag(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.ADD_FLAG_TASK, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async removeFlag(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.REMOVE_FLAG_TASK, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    getTaskData() {
        return this.taskData;
    }

    setTaskData(data: any) {
        this.taskData = data;
    }

    clearTaskData(){
        this.taskData='';
    }

    async getTaskInvoicedBudget(DATA: any) {
        const response = await axios
            .get(SERVER_URL + url.GET_TASK_INVOICED_BUDGET + "/" + DATA.id,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getTasksExport(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_TASKS_EXPORT, DATA,
                {
                    headers: getAxiosConfigWithToken(),
                    responseType: "blob",
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

}
export default new TasksService();