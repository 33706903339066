import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class TimeSheetsService {
    async getTimeSheet(DATA: any) {
        const response = await axios
            .get(SERVER_URL + url.GET_TIME_SHEET + "/" + DATA.id + "/" + DATA.time_sheet_id,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getTimeSheetsGroupList(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_TIME_SHEETS_GROUP_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getTimeSheetsList(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_TIME_SHEETS_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getTimeSheetsExport(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_TIME_SHEETS_EXPORT, DATA,
                {
                    headers: getAxiosConfigWithToken(),
                    responseType: "blob",
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async createTimeSheet(DATA: any) {
        const response = await axios
            .put(SERVER_URL + url.CREATE_TIME_SHEET, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getTimeSheetForm(DATA: any) {
        const response = await axios
            .get(SERVER_URL + url.GET_TIME_SHEET_FORM + "/" + DATA.module_id + "/" + DATA.module_main_id + "/" + DATA.module_sub_id,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async createTimeSheetForm(DATA: any) {
        const response = await axios
            .put(SERVER_URL + url.CREATE_TIME_SHEET_FORM, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async updateTimeSheet(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.UPDATE_TIME_SHEET, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async deleteTimeSheet(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.DELETE_TIME_SHEET, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async timesheetVsAmount(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(SERVER_URL + url.TIMESHEET_VS_AMOUNT + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async timePerTask(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(SERVER_URL + url.TIME_PER_TASK + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async feeVsServiceCharge(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(SERVER_URL + url.FEE_VS_SERVICE_CHARGE + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async externalVsInternal(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(SERVER_URL + url.EXTERNAL_VS_INTERNAL + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async perUserTaskTime(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(SERVER_URL + url.PER_USER_TASK_TIME + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async perUserPerTaskStatus(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(SERVER_URL + url.PER_USER_PER_TASK_STATUS + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async timePerTaskPerUser(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(SERVER_URL + url.TIME_PER_TASK_PER_USER + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async perUserPerTaskTimeSpent(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(SERVER_URL + url.PER_USER_PER_TASK_TIME_SPENT + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getCounts(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(SERVER_URL + url.TIMESHEET_COUNTS + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }
}

export default new TimeSheetsService();