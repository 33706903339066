import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { useEffect } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import {  InteractionStatus } from "@azure/msal-browser";
import AutoLogout from "../components/Layout/AutoLogout";

type Props = { isAuthProtected: boolean; };

function AuthMiddleware({ isAuthProtected }: Props) {
  const location = useLocation();
  let navigate = useNavigate();
 
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();


  const forceLogout = () => {
    window.localStorage.clear();
    return navigate("/login", { state: { from: location } });
  };

  useEffect(() => {
    if (
      localStorage.getItem("accessTokenSSO") !== null &&
      isAuthenticated === false &&
      inProgress === InteractionStatus.None
    ) {
      forceLogout();
    }
  }, [localStorage.getItem("accessTokenSSO"), isAuthenticated, inProgress]);


  return isAuthProtected === true && !localStorage.getItem("authUser") ? (
    <Navigate to="/login" state={{ from: location }} />
  ) : isAuthProtected === false && localStorage.getItem("authUser") ? (
    <Navigate to="/dashboard" state={{ from: location }} />
  ) : (
    <AutoLogout>
      <Outlet />
    </AutoLogout>
  );
}

export default AuthMiddleware;
