import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  Button,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import broadcastService from "../../../services/broadcast.service";
import {
  getDataTableQuery,
  getUserProfile,
  wssConfig
} from "../../../helpers/functions_helper";
import Avatar from "../../Avatar";
// Actions
import { CommonAction } from "../../../store/actions";

import Echo from "laravel-echo";
import notificationService from "../../../services/notification.service";

declare global {
  interface Window {
    Pusher: any;
    Echo: any;
  }
}
window.Pusher = require("pusher-js");
window.Echo = new Echo(wssConfig());
const channel = window.Echo.channel("public.broadcast");

const AnnouncementDropdown = (props: any) => {
  
  const { t } = useTranslation();
  const userProfile = getUserProfile('broadcast');
  const [menu, setMenu] = useState<boolean>(false);
  const [showViewMore, setShowViewMore] = useState(false);
  const [loadingNotificationData, setLoadingNotificationData] = useState(true);
  const [viewMoreNotificationData, setViewMoreNotificationData] =
    useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationData, setNotificationData] = useState([]);

  const connectNotificationLive = () => {
    channel
      .subscribed(()=>{})
      .listen(".broadcast", (e: any) => {
        getNotificationCount();
      })
      .error((e: any) => {
         console.log(e, "error");
      });
  };

  const columns = [
    {
      dataField: "id",
    },
  ];

  const [datatableOptions, setDatatableOptions] = useState({
    draw: 1,
    page: 1,
    recordsTotal: 0,
    recordsFiltered: 0,
    sizePerPage: 5,
    searchText: "",
    sortField: "id",
    sortOrder: "desc",
    type:1
  });


  const getNotificationCount = async () => {
    const res= await broadcastService.count();
    setNotificationCount(res.count || 0)
  };


  const loadingNotification = async () => {
    setNotificationData([]);
    setLoadingNotificationData(true);

    try {
      const res = (await broadcastService.getAll(
        getDataTableQuery(datatableOptions, columns),1
      )) as any;
      if (res.data.status === "success") {
        setNotificationData(res?.data?.data);
        setLoadingNotificationData(false);
        setViewMoreNotificationData(false);
      } else {
        setNotificationData([]);
        setLoadingNotificationData(false);
        setViewMoreNotificationData(false);
      }
    } catch (err) {
      setNotificationData([]);
      setLoadingNotificationData(false);
      setViewMoreNotificationData(false);
    }
  };

  const viewMore = () => {
    setDatatableOptions((prevData: any) => ({
      ...prevData,
      page: prevData.page + 1,
    }));
    setViewMoreNotificationData(true);
  };

  useEffect(() => {
    connectNotificationLive();
    getNotificationCount();
  }, []);

  useEffect(() => {
    if (menu === false) {
      setDatatableOptions((prevData: any) => ({
        ...prevData,
        page: 1,
      }));
    }
    if (menu === true || viewMoreNotificationData === true) {
      loadingNotification();
    }
  }, [menu, viewMoreNotificationData]);

  useEffect(() => {
    const currentPage = datatableOptions?.page;
    const isPage = notificationCount - currentPage * 5;

    if (isPage > 0) {
      setShowViewMore(true);
    } else {
      setShowViewMore(false);
    }
  }, [datatableOptions, notificationCount]);

  const handleNotificationViewed =async(data:any)=>{
    const res = (await broadcastService.setBroadcastReadUnread({ broadcast_id: data.id, is_read: true })) as any;
    getNotificationCount();
  }

  if(userProfile.CAN_VIEW!=true)
  return null
  else

  return (
    <React.Fragment>
      {notificationCount > 0 ? (
        <Dropdown
          isOpen={menu}
          toggle={() => setMenu(!menu)}
          className="dropdown d-inline-block"
          tag="li"
        >
          <DropdownToggle
            className={`btn header-item noti-icon ${window.location.pathname === "/broadcast/all" ? "header-icon-active" : ""}`}
            tag="button"
            id="page-header-notifications-dropdown"
            title={t("Broadcast")}
          >
            <span className="align-middle material-symbols-outlined fw-light" style={{ fontSize: '30px', rotate: '-20deg' }}>
            campaign
            </span>
            {notificationCount > 0 && (
              <span className="pulse badge bg-danger rounded-pill">
                {notificationCount}
              </span>
            )}
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0"> {t("Broadcast")} </h6>
                </Col>
                <div className="col-auto">
                  <Link to="/broadcast/all" className="small">
                    <span className="badge_custome badge-soft-primary badge bg-primary rounded-pill">
                      {t("View All")}
                    </span>
                  </Link>
                </div>
              </Row>
            </div>

            <SimpleBar style={{ maxHeight: "230px" }}>
              {notificationData && notificationData.length > 0 ? (
                notificationData.map((data: any) => (
                  <Link
                    id={`notification_${data.id}`}
                    key={data.id}
                    to={`/broadcast/view/${data?.id}/${data?.broadcast_id}`}
                    className="text-reset notification-item"
                    onClick={() => handleNotificationViewed(data)}
                  >
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <h6 className="mt-0 mb-1">
                        {data.title && (
                            <p className="mb-1">{data.title}</p>
                          )}
                        </h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-0">
                            <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                            event_available
                            </span>
                            {t("Start Date")} : {data.start_date}
                          </p>
                          <p className="mb-0">
                            <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                            event_available
                            </span>
                            {t("End Date")} : {data.end_date}
                          </p>
                          <p className="mb-0">
                            <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                              schedule
                            </span>
                            {data.time_elapsed}
                          </p>
                        </div>
                      </div>
                    </div>
                   
                  </Link>
                ))
              ) : (
                <div className="text-center mt-3 mb-3">
                  {loadingNotificationData ? (
                    <div className="align-middle">
                      <div className="text-center mt-3 mb-3">
                        <span
                          className="align-middle material-symbols-outlined text-muted fw-lighter"
                          style={{ fontSize: "40px" }}
                        >
                          hourglass_empty
                        </span>
                      </div>

                      <div className="mt-1 mb-3 text-center">
                        <h5 className="text-truncate text-muted fw-lighter">
                        {t("Loading...")}
                        </h5>
                      </div>
                    </div>
                  ) : (
                    <div className="align-middle">
                      <div className="text-center mt-3 mb-3">
                        <span
                          className="align-middle material-symbols-outlined text-muted fw-lighter"
                          style={{ fontSize: "40px" }}
                        >
                          notifications_off
                        </span>
                      </div>

                      <div className="mt-1 mb-3 text-center">
                        <h5 className="text-truncate text-muted fw-lighter">
                          {t("No new notification to view!")}
                        </h5>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </SimpleBar>

            {/* {showViewMore && (
              <div className="p-2 border-top d-grid">
                <Button
                  className="btn btn-sm font-size-12 text-center"
                  onClick={() => viewMore()}
                >
                  {viewMoreNotificationData === false ? (
                    <>
                      <span className="align-middle me-1 material-symbols-outlined fw-light fs-4">
                        arrow_circle_right
                      </span>
                      {t("View more..")}
                    </>
                  ) : (
                    <>
                      <span className="align-middle me-1 material-symbols-outlined fw-light fs-4">
                        hourglass_empty
                      </span>
                      {t("Loading...")}
                    </>
                  )}
                </Button>
              </div>
            )} */}
          </DropdownMenu>
        </Dropdown>
      ) : (
        <Link to={`/broadcast/all`}>
          <button
            type="button"
            className={`btn header-item noti-icon ${window.location.pathname === "/broadcast/all" ? "header-icon-active" : ""}`}
            title={t("Show all notification")}
          >
            <span className="align-middle material-symbols-outlined fw-light">
            campaign
            </span>
          </button>
        </Link>
      )}
    </React.Fragment>
  );
};

export default AnnouncementDropdown;
