import fileDownload from 'js-file-download';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { Container, Card } from 'reactstrap';
import MultiComments from '../../components/Common/MultiComments';
import Drawer from '../../components/Drawer';
import Breadcrumbs from '../../components/Layout/Breadcrumb';
import Loader from '../../components/Layout/Loader';
import invoicesService from '../../services/invoices.service';
import ViewInvoice from './ViewInvoice';

const breadcrumbItems = [
    {
        url: "/dashboard",
        label: "Dashboard",
        active: false,
    },
    {
        url: "/invoices-management/invoices/creditnote",
        label: "Credit Notes",
        active: false,
    },
    {
        url: "#",
        label: "View Credit Note",
        active: true,
    },
];

const ViewCreditNotePage = () => {

    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const toastId = React.useRef(null) as any;
    const { getUserProfile } = useSelector((state: any) => ({
        getUserProfile: state.Users.GET_USER_PROFILE_SUCCESS,
    }));

    const [invoice, setInvoice] = useState<InvoiceType>();
    const [openComment, setOpenComment] = useState<boolean>(false);

    const getInvoiceData = async (id: number) => {
        const res = await invoicesService.getInvoice(id);
        if (res.status === 'success') {
            setInvoice(res?.data);
        } else {
            navigate('/404')
        }
    }

    const handleDownload = async (invoice: any) => {
        toastId.current = toast("Downloading Credit note...", {
            autoClose: false,
            isLoading: true,
        });
        // window.open(process.env.REACT_APP_SERVER_URL + `/invoices/download/${invoice.id}`, '_blank')
        const res = await invoicesService.downloadInvoice(invoice?.id);
        if (res.status === 200) {
            fileDownload(res.data, 'credit-note-' + invoice.invoice_number + '.pdf');
            toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: 'Credit note downloaded successfully', autoClose: 5000, isLoading: false, delay: 500 });
        } else {
            toast.update(toastId.current, { type: toast.TYPE.ERROR, render: 'Sorry, something went wrong!', autoClose: 5000, isLoading: false, delay: 500 });
        }
    }

    const handleSend = async (id: number) => {
        toast.dismiss();
        toastId.current = toast("Sending a credit note...", { autoClose: false, isLoading: true });
        try {
            const res = (await invoicesService.sendInvoice(id)) as any;
            console.log("Response", res);
            if (res.status === "success") {
                toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: 'Credit note sent successfully', autoClose: 5000, isLoading: false, delay: 500 });
            } else {
                toast.update(toastId.current, { type: toast.TYPE.ERROR, render: 'Something went wrong.', autoClose: 5000, isLoading: false, delay: 500 });
            }
        } catch (err: any) {
            toast.update(toastId.current, { type: toast.TYPE.ERROR, render: err, autoClose: 5000, isLoading: false, delay: 500 });
        }
    };

    useEffect(() => {
        //@ts-ignore
        getInvoiceData(parseInt(id, 10));
    }, [id])

    document.title = "View Credit Note | " + process.env.REACT_APP_NAME;

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs
                    breadcrumbTitle={`View Credit Note`}
                    breadcrumbItems={breadcrumbItems}
                    endAdornment={
                        <>
                            <button
                                className="btn btn-primary waves-light btn-label btn-sm me-1"
                                onClick={() => handleDownload(invoice)}
                            >
                                <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                    download
                                </span>
                                {t("Download")}
                            </button>

                            <button
                                className="btn btn-primary waves-light btn-label btn-sm me-1"
                                onClick={() => handleDownload(invoice)}
                            >
                                <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                    print
                                </span>
                                {t("Print")}
                            </button>

                            {(getUserProfile.data[0].access_role[0].role_is_admin === 1 && getUserProfile.data[0].company === 1) && (invoice?.status === 'Draft' || invoice?.status === 'Sent') ?
                                <button
                                    className="btn btn-primary waves-light btn-label btn-sm me-1"
                                    onClick={() => handleSend(invoice?.id || 0)}
                                >
                                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                        send
                                    </span>
                                    {invoice.status === 'Sent' ? t('Resend') : t('Send')}
                                </button> : null}

                        </>
                    }
                />
                <div style={{ padding: '10px 8%' }}>
                    {invoice ? <ViewInvoice {...invoice} /> : <Loader />}
                </div>
            </Container>
            <Drawer
                title={t("Comments")}
                open={openComment}
                onClose={() => setOpenComment((state) => !state)}
            >
                <MultiComments
                    moduleData={{
                        module_app_id: 5,
                        module_main_id: id,
                        // module_sub_id: invoice_id,
                        section_id: 0,
                    }}
                    fetchData={true}
                    isOpen={false}
                />
            </Drawer>
        </div>
    )
}

export default ViewCreditNotePage;