import React, { memo, useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, Button, Label, Form, FormFeedback } from "reactstrap";
import Select from "react-select";
import { TaskStatus } from "../../utils/data";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const TaskMoveModal = (props: any) => {
  const { onYesClick } = props;

  const { t } = useTranslation();
  const [initialData, setInitialData] = useState({ assignee: "" });
  const [assigneeOption, setAssigneeOption] = useState([]);

  const columnData = [
    t("TO DO"),
    t("IN PREPARATION"),
    t("IN REVIEW"),
    t("RESPONSIBLE TO SIGN OFF"),
    t("TO BE SENT"),
    t("SENT"),
    t("CANCELLED"),
    t("INVOICED"),
    t("DONE"),
  ];
  const columnColor = [
    "#556ee6",
    "#34c38f",
    "#50a5f1",
    "#f1b44c",
    "#74788d",
    "#fd7e14",
    "#198754",
    "#20c997",
    "#0dcaf0",
  ];

  let { getUserOptionsList } = useSelector((state: any) => ({
    getUserOptionsList: state.Common.GET_USER_OPTIONS_LIST_SUCCESS_NEW,
  }));

  const handleChange = (fieldName: string | any, fieldValue: any) => {
    setInitialData((preState) => ({ ...preState, [fieldName]: fieldValue }));
  };

  const handleSubmit = () => {
    if (props.to.toColumnId === 5) {
      if (initialData.assignee !== "") onYesClick(initialData);
    } else {
      onYesClick(initialData);
    }
  };

  useEffect(() => {
    if (getUserOptionsList.data) {
      setAssigneeOption(getUserOptionsList.data);
    }
  }, [getUserOptionsList.data]);

  useEffect(() => {
    if (props?.data?.assistant_administrative)
      setInitialData((preState) => ({
        ...preState,
        assignee: props?.data?.assistant_administrative,
      }));
  }, [props?.data?.assistant_administrative]);

  return (
    <React.Fragment>
      <Modal
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title text-truncate">
            {" "}
            <span
              className="text-wrap"
              dangerouslySetInnerHTML={{ __html: props.data.title }}
            ></span>
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label="Close"
          ></button>
        </div>
        <ModalBody>
          {`${t("You are about move the")} ${props.data.is_sub_task ? t("subtask") : t("task")} ${t("from")} `}
          <span style={{ color: columnColor[props.from.fromColumnId - 1] }} className="fw-bolder">
            {columnData[props.from.fromColumnId - 1]}
          </span>{" "}
          {t("to")}{" "}
          <span style={{ color: columnColor[props.to.toColumnId - 1] }} className="fw-bolder">
            {columnData[props.to.toColumnId - 1]}
          </span>{" "}
          <br />
          {t("Are you sure?")}
          {props.to.toColumnId === 5 && (
            <div className="mt-3 mb-3">
              <Label className="form-label">
                {t("Responsible person for sending")}{" "}
                <span className="text-warning is_required">*</span>
              </Label>
              <Select
                id="assignee"
                name="assignee"
                className="is-invalid"
                options={assigneeOption}
                value={
                  initialData.assignee
                    ? assigneeOption.find((option: any) => option.value == initialData.assignee)
                    : ""
                }
                isSearchable={true}
                onChange={(e: any) => {
                  handleChange("assignee", e.value);
                }}
              />
              {initialData.assignee === "" ? (
                <FormFeedback type="invalid">
                  {t("Responsible person for sending")} {t("field is required.")}
                </FormFeedback>
              ) : null}
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => handleSubmit()}>
            {t("Yes")}
          </Button>{" "}
          <Button onClick={props.onNoClick}>{t("No")}</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default memo(TaskMoveModal);
