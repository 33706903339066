import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { toast } from "react-toastify";
import Breadcrumbs from "../../../components/Layout/Breadcrumb";
import DataTable from "../../../components/Common/DataTable";
import FormModal from "./Form";
import DeleteModal from "../../../components/Common/DeleteModal";

import { getDataTableQuery } from "../../../helpers/functions_helper";
import splitPayrollYearService from "../../../services/master.split.payroll.year.service";
import { useTranslation } from "react-i18next";

const MasterForm = (props: any) => {
  const { t } = useTranslation();
  document.title = `${t("Split payroll")} ${t("year")} | ${process.env.REACT_APP_NAME}`;

  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "#",
      label: `${t("Split payroll")} ${t("year")}`,
      active: true,
    },
  ];

  const toastId = React.useRef(null) as any;
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingListData, setLoadingListData] = useState(true);
  const [datatableListData, setDatatableListData] = useState([]);
  const [formData, setFormData] = useState({});
  const [masterFormModal, setMasterFormModal] = useState(false);
  const [deleteWhere, setDeleteWhere] = useState({ id: "", split_payroll_year_id: "" });
  const [recordName, setRecordName] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  const create = async (values: formTypes_SplitPayrollYear) => {
    try {
      toastId.current = toast(`${t("Creating")} ${t("split payroll")} ${t("year")}...`, {
        autoClose: false,
        isLoading: true,
      });

      const res = (await splitPayrollYearService.create(values)) as any;
      if (res.data.status === "success") {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoading(false);
        setMasterFormModal(false);
        setLoadingListData(true);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        if (res.data.data) {
          Object.keys(res.data.data).map((key) => {
            toast(res.data.data[key][0], {
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              delay: 500,
            });
          });
        }
        setLoading(false);
      }
    } catch (err: any) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }
  };

  const update = async (values: formTypes_SplitPayrollYear) => {
    try {
      toastId.current = toast(`${t("Updating")} ${t("split payroll")} ${t("year")}...`, {
        autoClose: false,
        isLoading: true,
      });

      const res = (await splitPayrollYearService.update(values)) as any;
      if (res.data.status === "success") {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoading(false);
        setMasterFormModal(false);
        setLoadingListData(true);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        if (res.data.data) {
          Object.keys(res.data.data).map((key) => {
            toast(res.data.data[key][0], {
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              delay: 500,
            });
          });
        }
        setLoading(false);
      }
    } catch (err: any) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }
  };

  const deleteRecord = async (data: any) => {
    try {
      toastId.current = toast(`${t("Deleting")} ${t("split payroll")} ${t("year")}...`, {
        autoClose: false,
        isLoading: true,
      });

      const res = (await splitPayrollYearService.delete(data)) as any;
      if (res.data.status === "success") {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoading(false);
        setDeleteModal(false);
        setLoadingListData(true);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoading(false);
      }
    } catch (err: any) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }
  };

  const onClickCreate = () => {
    setFormData("");
    setMasterFormModal(true);
  };

  const onClickEdit = (row: any) => {
    setFormData(row);
    setMasterFormModal(true);
  };

  const onClickDuplicate = (row: any) => {
    const data = {
      ...row,
      id: "",
      split_payroll_year_id: "",
      computation_year: "",
    };
    setFormData(data);
    setMasterFormModal(true);
  };

  const onClickDelete = (row: any) => {
    const data = {
      ...deleteWhere,
      id: row.id,
      split_payroll_year_id: row.split_payroll_year_id,
    };
    setDeleteWhere(data);
    setRecordName(t("Computation year") + " " + row.computation_year);
    setDeleteModal(true);
  };

  const handleSubmit = (values: any) => {
    if (values.id && values.split_payroll_year_id) {
      update(values);
    } else {
      create(values);
    }
  };

  const handleDelete = () => {
    deleteRecord(deleteWhere);
  };

  function renderRowHTML(rowContent: any, row: any) {
    return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
  }
  const columns = [
    {
      dataField: "DT_RowIndex",
      text: t("Sr. No."),
      sort: false,
      search: false,
      classes: "fix-col-left",
      headerClasses: "fix-col-left",
      headerStyle: { width: "65px" },
      style: { width: "65px" },
    },
    {
      dataField: "computation_year",
      text: t("Computation year"),
      sort: true,
      search: true,
      align: "right",
    },
    {
      dataField: "tax_free_amount_for_attribution_to_spouse",
      text: t("Tax-free amount for attribution to spouse"),
      sort: false,
      search: false,
      align: "right",
    },
    {
      dataField: "tax_free_amount_for_children_less_3_year_old",
      text: t("Tax-free amount for children < 3 years old"),
      sort: false,
      search: false,
      align: "right",
    },
    {
      dataField: "tax_free_amount_for_single_parents",
      text: t("Tax-free amount for single parents"),
      sort: false,
      search: false,
      align: "right",
    },
    {
      dataField: "commuting_exemption",
      text: t("Commuting exemption"),
      sort: false,
      search: false,
      align: "right",
    },
    {
      dataField: "attribution_to_spouse",
      text: t("Attribution to spouse"),
      sort: false,
      search: false,
      align: "right",
    },
    {
      dataField: "min_bik_per_year",
      text: t("Minimum BIK"),
      sort: false,
      search: false,
      align: "right",
    },
    {
      dataField: "revaluation_factor",
      text: t("Revaluation factor"),
      sort: false,
      search: false,
      align: "right",
    },
    {
      dataField: "action",
      text: t("Action"),
      sort: false,
      search: false,
      formatter: (rowContent: any, row: any): any => {
        return (
          <div className="d-flex gap-2 text-end">
            <Link
              className="btn btn-pill btn-outline-primary btn-sm"
              to="#"
              onClick={() => onClickDuplicate(row)}
            >
              <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                content_copy
              </span>
              {t("Duplicate")}
            </Link>
            <Link
              className="btn btn-pill btn-outline-primary btn-sm"
              to="#"
              onClick={() => onClickEdit(row)}
            >
              <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                edit
              </span>
              {t("Edit")}
            </Link>
            <Link
              className="btn btn-pill btn-outline-danger btn-sm"
              to="#"
              onClick={() => onClickDelete(row)}
            >
              <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                delete
              </span>
              {t("Delete")}
            </Link>
          </div>
        );
      },
    },
  ];

  const [datatableOptions, setDatatableOptions] = useState({
    draw: 1,
    page: 1,
    recordsTotal: 0,
    recordsFiltered: 0,
    sizePerPage: 20,
    searchText: "",
    sortField: "id",
    sortOrder: "desc",
  });

  const onTableChange = (type: any, newState: any) => {
    let draw = datatableOptions.draw + 1;
    let page = 1;
    if (type == "search") {
      page = 1;
    } else {
      page = newState.page;
    }
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: draw,
      page: page,
      sizePerPage: newState.sizePerPage,
      searchText: newState.searchText,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
    };
    setDatatableOptions(updatedDatatableOptions);
    setDatatableListData([]);
    setLoadingListData(true);
  };

  const clearFilter = () => {
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: 1,
      page: 1,
      sizePerPage: 20,
      searchText: "",
      sortField: "id",
      sortOrder: "desc",
    };
    setDatatableOptions(updatedDatatableOptions);
    setLoadingListData(true);
  };

  const loadingDatatable = async () => {
    try {
      const res = (await splitPayrollYearService.getAll(
        getDataTableQuery(datatableOptions, columns)
      )) as any;

      if (res.data.status === "success") {
        setDatatableListData(res.data.data);

        const updatedDatatableOptions = {
          ...datatableOptions,
          recordsTotal: res.data.recordsTotal,
          recordsFiltered: res.data.recordsFiltered,
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingListData(false);
      } else {
        setDatatableListData([]);
        setLoadingListData(false);
      }
    } catch (err) {
      setDatatableListData([]);
      setLoadingListData(false);
    }
  };

  useEffect(() => {
    if (loadingListData === true) {
      loadingDatatable();
    }
  }, [loadingListData]);

  useEffect(() => {
    setDatatableListData([]);
    setLoadingListData(true);
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  return (
    <React.Fragment>
      {deleteWhere && (
        <DeleteModal
          recordName={recordName}
          modalTitle={`${t("Split payroll")} ${t("year")}`}
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
          loading={loading}
        />
      )}

      {masterFormModal && (
        <FormModal
          show={masterFormModal}
          loading={loading}
          formData={formData}
          onSubmitClick={handleSubmit}
          onCloseClick={() => setMasterFormModal(false)}
        />
      )}

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={`${t("Split payroll")} ${t("year")}`}
            breadcrumbItems={breadcrumbItems}
            endAdornment={
              <Link
                to="#"
                className="btn btn-primary btn-sm waves-effect waves-light btn-label"
                onClick={() => onClickCreate()}
              >
                <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                  add
                </span>{" "}
                {`${t("Create")} ${t("split payroll")} ${t("year")}`}
              </Link>
            }
          />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <DataTable
                    columns={columns}
                    products={datatableListData}
                    datatableOptions={datatableOptions}
                    filters={[]}
                    onTableChange={onTableChange}
                    loadingListData={loadingListData}
                    clearFilter={clearFilter}
                    settingTableName="split_payroll_year_master_table"
                    slug="masters"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MasterForm;
