import React, { useEffect, useState } from "react";
import { Card, CardTitle, CardBody, Table, Button } from "reactstrap";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import ProgressBar from "../../components/Common/ProgressBar";
import tasksService from "../../services/tasks.service";
import alertService from "../../services/alert.service";

import { useTranslation } from "react-i18next";

const CompanyUserAlert = (props: any) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);

    // Get comment ------------------------------------------------------------
    const [alertList, setAlertList] = useState<any>([]);
    const [activeTab, toggleTab] = useState("1");

    const getUserCompanyToDo = async () => {
        const response = await alertService.getUserCompanyToDo({});
        if (response.data.status === "success") {
            setAlertList(response.data.data);
            setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(true);
        setAlertList([]);
        getUserCompanyToDo();
    }, [localStorage.getItem("I18N_LANGUAGE")])

    useEffect(() => {
        setLoading(true);
        setAlertList([]);
        if (activeTab === '1') {
            getUserCompanyToDo()
        }

    }, [activeTab]);

    // Get comment ------------------------------------------------------------
    function createMarkup(val: any) {
        return { __html: val };
    }

    return (
        <React.Fragment>
            <div className="d-sm-flex flex-wrap">
                {!props.hideHeading &&
                    <CardTitle className="mb-3">
                        {t('New Clients')}
                    </CardTitle>
                }
            </div>
            {/* <div className="mb-3">
                <Button
                    size="sm"
                    color={`${activeTab === "1" ? "primary" : ""}`}
                    onClick={() => {
                        toggleTab("1");
                    }}
                >
                    {t('TO DO')}
                </Button>
            </div> */}

            <SimpleBar style={{ height: '376px' }}>
                <Table className="table table-wrap align-middle table-hover mb-0">
                    <thead className="thead-light" style={{ position: 'sticky', top: '0', zIndex: '1' }}>
                        <tr>
                            <th>{t('Company') + '/' + t('User')}</th>
                            <th>{t('Type')}</th>
                            <th>{t('Created by')}</th>
                            <th>{t('Action')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {alertList && alertList.length > 0 ? (
                            alertList.map((item: any, index: number) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            {item.type == "Company" ?
                                                <Link
                                                    className="text-decoration-underline"
                                                    to={
                                                        "/masters/company/company-details/" +
                                                        item.id +
                                                        "/" +
                                                        item.company_id +
                                                        "/process"
                                                    }
                                                >
                                                    {item.company_name}
                                                </Link>
                                                :
                                                <Link
                                                    className="text-decoration-underline"
                                                    to={
                                                        "/users/user-details/" +
                                                        item.id +
                                                        "/" +
                                                        item.user_id +
                                                        "/process"
                                                    }
                                                >
                                                    {`${item.first_name} ${item.last_name}`}
                                                </Link>
                                            }


                                        </td>
                                        <td>
                                            {item.type}
                                        </td>
                                        <td>
                                            {(item.created_by_first_name == null && item.is_website_user) ?
                                                <>{t("Website User")}</>
                                                :
                                                <>
                                                    {(item.created_by_first_name == null) ?
                                                        <></> :
                                                        <Link
                                                            className="text-decoration-underline"
                                                            to={
                                                                "/users/user-details/" +
                                                                item.created_by +
                                                                "/" +
                                                                item.created_by_sub_user_id
                                                            }
                                                        >
                                                            {`${item.created_by_first_name} ${item.created_by_last_name}`}
                                                        </Link>
                                                    }
                                                </>
                                            }

                                        </td>
                                        <td>
                                            {item.type == "company" ?
                                                <Link
                                                    className="btn btn-pill btn-outline-primary btn-sm"
                                                    to={
                                                        "/masters/company/company-details/" +
                                                        item.id +
                                                        "/" +
                                                        item.company_id +
                                                        "/process"
                                                    }
                                                >
                                                    {t('Process')}
                                                </Link>

                                                :
                                                <Link
                                                    className="btn btn-pill btn-outline-primary btn-sm"
                                                    to={
                                                        "/users/user-details/" +
                                                        item.id +
                                                        "/" +
                                                        item.user_id +
                                                        "/process"
                                                    }
                                                >
                                                    {t('Process')}
                                                </Link>
                                            }
                                        </td>
                                    </tr>
                                );
                                //}
                            })
                        ) : (
                            <tr style={{ height: 350 }}>
                                {loading ? (
                                    <td className="align-middle">
                                        <div>
                                            <h5 className="font-size-14 mb-1 placeholder-glow">
                                                <span className="placeholder col-6"></span>
                                            </h5>
                                            <p className="text-muted mb-1 placeholder-glow">
                                                <span className="placeholder col-12"></span>
                                            </p>
                                            <p className="text-muted mb-0 placeholder-glow">
                                                <span className="placeholder col-12"></span>
                                            </p>
                                        </div>
                                    </td>
                                ) : (
                                    <td className="align-middle border-0" colSpan={5}>
                                        <div className="text-center mt-3 mb-3">
                                            <span className="align-middle material-symbols-outlined text-muted fw-lighter" style={{ fontSize: "80px" }}>task</span>
                                        </div>

                                        <div className="mt-1 mb-3 text-center">
                                            <h5 className="text-truncate text-muted fw-lighter">
                                                {t('YAY!!! You have no new clients to process!')}
                                            </h5>
                                        </div>
                                    </td>
                                )}
                            </tr>
                        )}
                    </tbody>
                </Table>
            </SimpleBar>
        </React.Fragment>
    );
};

export default CompanyUserAlert;
