import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_QUESTIONNAIRE_QUESTIONS, GET_QUESTIONNAIRE_QUESTIONS_DETAIL, ADD_NEW_QUESTIONNAIRE_QUESTIONS, DELETE_QUESTIONNAIRE_QUESTIONS, UPDATE_QUESTIONNAIRE_QUESTIONS, GET_QUESTIONS_LIST, GET_QUESTIONS_LIST_WITH_ID, GET_QUESTION } from "./actionTypes"
import {
    getQuestionnaireQuestionsSuccess,
    getQuestionnaireQuestionsFail,
    getQuestionnaireQuestionsDetailFail,
    addQuestionnaireQuestionsFail,
    addQuestionnaireQuestionsSuccess,
    updateQuestionnaireQuestionsSuccess,
    updateQuestionnaireQuestionsFail,
    deleteQuestionnaireQuestionsSuccess,
    deleteQuestionnaireQuestionsFail,
    getQuestionsSuccess,
    getQuestionsFail,
    getQuestionsByIdSuccess,
    getQuestionsByIdFail,
    getQuestionSuccess,
    getQuestionError
} from "./actions"

import { AnyAction } from 'redux'

import MastersQuestionnaireQuestionsService from "../../../services/masters.questionnaire.questions.service"

function* fetchQuestionnaireQuestions({ payload: { query, history } }: AnyAction): any {
    try {
        const response = yield call(MastersQuestionnaireQuestionsService.getQuestionnaireQuestionsList, { ...query })
        if (response && response.data && response.data.status === 'success') {
            yield put(getQuestionnaireQuestionsSuccess(response.data))
        } else if (response && response.status === 'error') {
            yield put(getQuestionnaireQuestionsFail(response))
        } else {
            yield put(getQuestionnaireQuestionsFail(response))
        }
    } catch (error) {
        yield put(getQuestionnaireQuestionsFail(error))
    }
}

function* fetchQuestionsListWithId({ payload: { tabId, conditions, history } }: AnyAction): any {
    try {
        const response = yield call(MastersQuestionnaireQuestionsService.getQuestionsListWithId, { tabId, conditions });
        if (response && response.data && response.data.status === 'success') {
            yield put(getQuestionsByIdSuccess(response.data))
        } else if (response && response.status === 'error') {
            yield put(getQuestionsByIdFail(response))
        } else {
            yield put(getQuestionsByIdFail(response))
        }
    } catch (error: any) {
        yield put(getQuestionsByIdFail(error))
    }
}



function* fetchQuestionsList(): any {
    try {
        const response = yield call(MastersQuestionnaireQuestionsService.getQuestionsList);
        if (response && response.data && response.data.status === 'success') {
            yield put(getQuestionsSuccess(response.data))
        } else if (response && response.status === 'error') {
            yield put(getQuestionsFail(response))
        } else {
            yield put(getQuestionsFail(response))
        }
    } catch (error: any) {
        yield put(getQuestionsFail(error))
    }
}

function* fetchQuestionnaireQuestionsDetail({ statusId }: AnyAction) {
    try {
        //  const response = yield call(getQuestionnaireDetails, companyId)
        // yield put(getQuestionnaireDetailSuccess(response))
    } catch (error) {
        yield put(getQuestionnaireQuestionsDetailFail(error))
    }
}

function* onUpdateQuestionnaireQuestions({ payload: { statusdata, history } }: AnyAction): any {
    try {
        const response = yield call(MastersQuestionnaireQuestionsService.updateQuestionnaireQuestions, statusdata)
        if (response && response.data && response.data.status === 'success') {
            yield put(updateQuestionnaireQuestionsSuccess(response.data))
        } else if (response && response.status === 'error') {
            yield put(updateQuestionnaireQuestionsFail(response))
        } else {
            yield put(updateQuestionnaireQuestionsFail(response))
        }
    } catch (error) {
        yield put(updateQuestionnaireQuestionsFail(error))
    }
}

function* onDeleteQuestionnaireQuestions({ payload: { statusdata, history } }: AnyAction): any {
    try {
        const response = yield call(MastersQuestionnaireQuestionsService.deleteQuestionnaireQuestions, statusdata)
        if (response && response.data && response.data.status === 'success') {
            yield put(deleteQuestionnaireQuestionsSuccess(response))
        } else if (response && response.status === 'error') {
            yield put(deleteQuestionnaireQuestionsFail(response))
        } else {
            yield put(deleteQuestionnaireQuestionsFail(response))
        }

    } catch (error) {
        yield put(deleteQuestionnaireQuestionsFail(error))
    }
}

function* onAddNewQuestionnaireQuestions({ payload: { statusdata, history } }: AnyAction): any {
    try {
        const response = yield call(MastersQuestionnaireQuestionsService.createQuestionnaireQuestions, statusdata)
        if (response && response.data && response.data.status === 'success') {
            yield put(addQuestionnaireQuestionsSuccess(response.data))
        } else if (response && response.status === 'error') {
            yield put(addQuestionnaireQuestionsFail(response))
        } else {
            yield put(addQuestionnaireQuestionsFail(response))
        }
    } catch (error) {
        yield put(addQuestionnaireQuestionsFail(error))
    }
}


function* getQuestion({ payload: { question, history } }: AnyAction): any {
    try {
        const response = yield call(MastersQuestionnaireQuestionsService.getQuestion, question)
        if (response && response.data && response.data.status === 'success') {
            yield put(getQuestionSuccess(response.data))
        } else if (response && response.status === 'error') {
            yield put(getQuestionError(response))
        } else {
            yield put(getQuestionError(response))
        }
    } catch (error: any) {
        console.log(error);
        yield put(getQuestionError(error))
    }
}


function* companysSaga(): any {
    yield takeEvery(GET_QUESTIONNAIRE_QUESTIONS, fetchQuestionnaireQuestions)
    yield takeEvery(GET_QUESTIONS_LIST, fetchQuestionsList)
    yield takeEvery(GET_QUESTIONS_LIST_WITH_ID, fetchQuestionsListWithId)
    yield takeEvery(GET_QUESTIONNAIRE_QUESTIONS_DETAIL, fetchQuestionnaireQuestionsDetail)
    yield takeEvery(ADD_NEW_QUESTIONNAIRE_QUESTIONS, onAddNewQuestionnaireQuestions)
    yield takeEvery(UPDATE_QUESTIONNAIRE_QUESTIONS, onUpdateQuestionnaireQuestions)
    yield takeEvery(DELETE_QUESTIONNAIRE_QUESTIONS, onDeleteQuestionnaireQuestions)
    yield takeEvery(GET_QUESTION, getQuestion)
}

export default companysSaga
