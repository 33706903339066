import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Button,
  Row,
  Col,
} from "reactstrap";
import { vLookup } from "../../helpers/functions_helper";
import { formatCurrency } from "../../utils/formats";
import { useTranslation } from "react-i18next";

const SSSCModel_226 = (props: any) => {
  const { t } = useTranslation();
  let spCountryData = props.spCountryData;
  const EXCHANGE_RATE = props.EXCHANGE_RATE;
  const USA_STATE_TAX = props.USA_STATE_TAX;
  const PERS_ALL_USA = props.PERS_ALL_USA;
  const PERS_ALL_USA_MAR = props.PERS_ALL_USA_MAR;
  const TAX_RATE_USA_SINGLE = props.TAX_RATE_USA_SINGLE;
  const TAX_RATE_USA_MARRIED = props.TAX_RATE_USA_MARRIED;
  const D_FAM_STAT = props.D_FAM_STAT;

  const [COUNTRY_IDX, setCOUNTRY_IDX] = useState<number>();
  const [D_EMP_DIR, setD_EMP_DIR] = useState<number>(1);
  const [D_GROSS_COUNTRY, setD_GROSS_COUNTRY] = useState<number>(0);
  const [C_TAXES_COUNTRY, setC_TAXES_COUNTRY] = useState<number>(0);
  const [PERS_ALL_USA_CALCULATED, setPERS_ALL_USA_CALCULATED] = useState([]);
  const [PERS_ALL_USA_MAR_CALCULATED, setPERS_ALL_USA_MAR_CALCULATED] = useState([]);

  const [E7, setE7] = useState<number>(0);
  const [K7, setK7] = useState<number>(0);
  const [D_GROSS_USD, setD_GROSS_USD] = useState<number>(0);
  const [B73, setB73] = useState<number>(0);
  const [C_NET_TAXABLE_COUNTRY, setC_NET_TAXABLE_COUNTRY] = useState<number>(0);
  const [C_TAX_USA, setC_TAX_USA] = useState<number>(0);
  const [B78, setB78] = useState<number>(0);
  const [C_TAX_EUR_USA, setC_TAX_EUR_USA] = useState<number>(0);

  useEffect(() => {
    if (spCountryData) {
      const COUNTRY_DATA = spCountryData.find((data: any) => data.country_id === 226);

      if (COUNTRY_DATA) {
        setCOUNTRY_IDX(spCountryData.findIndex((data: any) => data.country_id === 226));
        setD_GROSS_COUNTRY(
          spCountryData.find((data: any) => data.country_id === 226)?.D_GROSS_COUNTRY
        );
        setD_EMP_DIR(spCountryData.find((data: any) => data.country_id === 226)?.emp_dir);
      }
    }
  }, [spCountryData]);

  useEffect(() => {
    if (PERS_ALL_USA_MAR && PERS_ALL_USA_MAR.length > 0) {
      let J6 = PERS_ALL_USA_MAR[0][2];
      setK7(Number(J6) * 0.02);
    }
  }, [PERS_ALL_USA_MAR]);

  useEffect(() => {
    if (PERS_ALL_USA && PERS_ALL_USA.length > 0) {
      let newData = [] as any;
      for (let i = 0; i <= 50; i++) {
        let tempData = [] as any;
        if (i === 0) {
          tempData = [
            Number(PERS_ALL_USA[i][0]),
            Number(PERS_ALL_USA[i][1]),
            Number(PERS_ALL_USA[i][2]),
          ];
        } else {
          if (i === 50) {
            tempData = [Number(newData[i - 1][1]), 0, Number(newData[i - 1][2]) - Number(E7)];
          } else {
            tempData = [
              Number(newData[i - 1][1]),
              Number(newData[i - 1][1]) + 2500,
              Number(newData[i - 1][2]) - Number(E7),
            ];
          }
        }
        newData.push(tempData);
      }

      setPERS_ALL_USA_CALCULATED(newData);
    }
  }, [PERS_ALL_USA, E7]);

  useEffect(() => {
    if (PERS_ALL_USA_MAR && PERS_ALL_USA_MAR.length > 0) {
      let newData = [] as any;
      for (let i = 0; i <= 50; i++) {
        let tempData = [] as any;
        if (i === 0) {
          tempData = [
            Number(PERS_ALL_USA_MAR[i][0]),
            Number(PERS_ALL_USA_MAR[i][1]),
            Number(PERS_ALL_USA_MAR[i][2]),
          ];
        } else {
          if (i === 50) {
            tempData = [Number(newData[i - 1][1]), 0, Number(newData[i - 1][2]) - Number(K7)];
          } else {
            tempData = [
              Number(newData[i - 1][1]),
              Number(newData[i - 1][1]) + 1250,
              Number(newData[i - 1][2]) - Number(K7),
            ];
          }
        }
        newData.push(tempData);
      }

      setPERS_ALL_USA_MAR_CALCULATED(newData);
    }
  }, [PERS_ALL_USA_MAR, K7]);

  useEffect(() => {
    setD_GROSS_USD(Number(D_GROSS_COUNTRY) / Number(EXCHANGE_RATE));
  }, [D_GROSS_COUNTRY, EXCHANGE_RATE]);

  useEffect(() => {
    if (
      PERS_ALL_USA &&
      PERS_ALL_USA.length > 0 &&
      PERS_ALL_USA_MAR &&
      PERS_ALL_USA_MAR.length > 0
    ) {
      // =IF(D_FAM_STAT<>2;VLOOKUP(B71;PERS_ALL_USA;3);VLOOKUP(B71;PERS_ALL_USA_MAR;3))
      if (parseInt(D_FAM_STAT) !== 2) {
        setB73(vLookup(D_GROSS_USD, PERS_ALL_USA, 3));
      } else {
        setB73(vLookup(D_GROSS_USD, PERS_ALL_USA_MAR, 3));
      }
    }
  }, [D_FAM_STAT, D_GROSS_USD, PERS_ALL_USA, PERS_ALL_USA_MAR]);

  useEffect(() => {
    // =B71-B73
    setC_NET_TAXABLE_COUNTRY(Number(D_GROSS_USD) - Number(B73));
  }, [D_GROSS_USD, B73]);

  useEffect(() => {
    if (
      TAX_RATE_USA_SINGLE &&
      TAX_RATE_USA_SINGLE.length > 0 &&
      TAX_RATE_USA_MARRIED &&
      TAX_RATE_USA_MARRIED.length > 0
    ) {
      // =IF(D_FAM_STAT<>2;VLOOKUP(C_NET_TAXABLE_USA;B58:E64;4)+(C_NET_TAXABLE_USA-VLOOKUP(C_NET_TAXABLE_USA;B58:E64;1))*VLOOKUP(C_NET_TAXABLE_USA;B58:E64;3);VLOOKUP(C_NET_TAXABLE_USA;H58:K64;4)+(C_NET_TAXABLE_USA-VLOOKUP(C_NET_TAXABLE_USA;H58:K64;1))*VLOOKUP(C_NET_TAXABLE_USA;H58:K64;3))
      if (parseInt(D_FAM_STAT) !== 2) {
        const VLOOKUP_4 = vLookup(C_NET_TAXABLE_COUNTRY, TAX_RATE_USA_SINGLE, 4);
        const VLOOKUP_3 = vLookup(C_NET_TAXABLE_COUNTRY, TAX_RATE_USA_SINGLE, 3);
        const VLOOKUP_1 = vLookup(C_NET_TAXABLE_COUNTRY, TAX_RATE_USA_SINGLE, 1);

        setC_TAX_USA(
          Number(VLOOKUP_4) +
            (Number(C_NET_TAXABLE_COUNTRY) - Number(VLOOKUP_1)) * (Number(VLOOKUP_3) / 100)
        );
      } else {
        const VLOOKUP_4 = vLookup(C_NET_TAXABLE_COUNTRY, TAX_RATE_USA_MARRIED, 4);
        const VLOOKUP_3 = vLookup(C_NET_TAXABLE_COUNTRY, TAX_RATE_USA_MARRIED, 3);
        const VLOOKUP_1 = vLookup(C_NET_TAXABLE_COUNTRY, TAX_RATE_USA_MARRIED, 1);

        setC_TAX_USA(
          Number(VLOOKUP_4) +
            (Number(C_NET_TAXABLE_COUNTRY) - Number(VLOOKUP_1)) * (Number(VLOOKUP_3) / 100)
        );
      }
    }
  }, [D_FAM_STAT, C_NET_TAXABLE_COUNTRY, TAX_RATE_USA_SINGLE, TAX_RATE_USA_MARRIED]);

  useEffect(() => {
    setB78(Number(C_NET_TAXABLE_COUNTRY) * (Number(USA_STATE_TAX) / 100));
  }, [C_NET_TAXABLE_COUNTRY, USA_STATE_TAX]);

  useEffect(() => {
    // =IF(ISERROR(ROUND(((C_TAX_USA+B78)*C4);2));0;ROUND(((C_TAX_USA+B78)*C4);2))
    setC_TAX_EUR_USA((Number(C_TAX_USA) + Number(B78)) * Number(EXCHANGE_RATE));
  }, [C_TAX_USA, B78, EXCHANGE_RATE]);

  useEffect(() => {
    if (COUNTRY_IDX) {
      props.onCalculationUpdate({
        COUNTRY_IDX: COUNTRY_IDX,
        C_TAXES_COUNTRY: C_TAX_EUR_USA,
      });
    }
  }, [COUNTRY_IDX, C_TAX_EUR_USA]);

  return (
    <React.Fragment>
      <Modal
        size="xl"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">{t("UNITED STATES")}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label={t("Close")}
          ></button>
        </div>

        <ModalBody>
          <Card>
            <CardHeader>
              <CardTitle>1. {t("PARAMETERS")}</CardTitle>
            </CardHeader>

            <CardBody>
              <div className="table-responsive mb-3">
                <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr>
                      <td>{t("Exchange rate")}: USD 1 = EUR</td>
                      <td className="text-end">
                        {formatCurrency(Number(EXCHANGE_RATE).toFixed(4), false)}
                      </td>
                      <td>{t("Average exchange rate")} 01/01/2021 - 31/12/2021</td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              <Row>
                <Col>
                  <div className="table-responsive mb-3">
                    <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                      <tbody>
                        <tr className="align-bottom">
                          <th colSpan={4} className="text-center">
                            {t("PERSONAL ALLOWANCE")} ({t("SINGLE")}) - {t("Phase out")}:{" "}
                            {formatCurrency(E7.toFixed(2), false)}
                          </th>
                        </tr>
                        {PERS_ALL_USA_CALCULATED &&
                          PERS_ALL_USA_CALCULATED.map((item: any, idx: number) => (
                            <tr key={idx} className="align-bottom">
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[0]).toFixed(2), false)}
                              </td>
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[1]).toFixed(2), false)}
                              </td>
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[2]).toFixed(2), false)}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>

                <Col>
                  <div className="table-responsive mb-3">
                    <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                      <tbody>
                        <tr className="align-bottom">
                          <th colSpan={4} className="text-center">
                            {t("PERSONAL ALLOWANCE")} ({t("MARRIED")}) - {t("Phase out")}:{" "}
                            {formatCurrency(K7.toFixed(2), false)}
                          </th>
                        </tr>
                        {PERS_ALL_USA_MAR_CALCULATED &&
                          PERS_ALL_USA_MAR_CALCULATED.map((item: any, idx: number) => (
                            <tr key={idx} className="align-bottom">
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[0]).toFixed(2), false)}
                              </td>
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[1]).toFixed(2), false)}
                              </td>
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[2]).toFixed(2), false)}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="table-responsive mb-3">
                    <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                      <tbody>
                        <tr className="align-bottom">
                          <th colSpan={4} className="text-center">
                            {t("Tax rates")} - ({t("Single")})
                          </th>
                        </tr>
                        {TAX_RATE_USA_SINGLE &&
                          TAX_RATE_USA_SINGLE.map((item: any, idx: number) => (
                            <tr key={idx} className="align-bottom">
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[0]).toFixed(2), false)}
                              </td>
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[1]).toFixed(2), false)}
                              </td>
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[2]).toFixed(2), false)}%
                              </td>
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[3]).toFixed(2), false)}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
                <Col>
                  <div className="table-responsive mb-3">
                    <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                      <tbody>
                        <tr className="align-bottom">
                          <th colSpan={4} className="text-center">
                            {t("Tax rates")} - ({t("Married")} - {t("filing separately")})
                          </th>
                        </tr>
                        {TAX_RATE_USA_MARRIED &&
                          TAX_RATE_USA_MARRIED.map((item: any, idx: number) => (
                            <tr key={idx} className="align-bottom">
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[0]).toFixed(2), false)}
                              </td>
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[1]).toFixed(2), false)}
                              </td>
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[2]).toFixed(2), false)}%
                              </td>
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[3]).toFixed(2), false)}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>

              <div className="table-responsive mb-3">
                <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr>
                      <td>{t("Average state tax")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(USA_STATE_TAX).toFixed(2), false)}%
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>2. {t("COMPUTATION")}</CardTitle>
            </CardHeader>

            <CardBody>
              <div className="table-responsive mb-3">
                <Table className="table table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr>
                      <td>
                        {t("GROSS")} {t("UNITED STATES")}
                      </td>
                      <td className="text-end">
                        {formatCurrency(Number(D_GROSS_COUNTRY).toFixed(2), false)}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("GROSS")} USD</td>
                      <td className="text-end">
                        {formatCurrency(Number(D_GROSS_USD).toFixed(2), false)}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={2}></td>
                    </tr>

                    <tr>
                      <td>{t("PERSONAL ALLOWANCE")}</td>
                      <td className="text-end">{formatCurrency(Number(B73).toFixed(2), false)}</td>
                    </tr>

                    <tr>
                      <td colSpan={2}></td>
                    </tr>

                    <tr>
                      <td>{t("NET TAXABLE")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_NET_TAXABLE_COUNTRY).toFixed(2), false)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}></td>
                    </tr>

                    <tr>
                      <td>
                        {t("TAX")} {t("USA")}
                      </td>
                      <td className="text-end">
                        {formatCurrency(Number(C_TAX_USA).toFixed(2), false)}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("STATE TAX")}</td>
                      <td className="text-end">{formatCurrency(Number(B78).toFixed(2), false)}</td>
                    </tr>
                    <tr>
                      <td>
                        {t("TAX")} EUR {t("USA")}
                      </td>
                      <td className="text-end">
                        {formatCurrency(Number(C_TAX_EUR_USA).toFixed(2), false)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </ModalBody>

        <ModalFooter>
          <Button color="danger" className="btn-block" type="reset" onClick={props.onCloseClick}>
            {t("Close")}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default SSSCModel_226;
