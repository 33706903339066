import {
  GET_TIME_SHEET,
  GET_TIME_SHEET_SUCCESS,
  GET_TIME_SHEET_ERROR,
  GET_TIME_SHEETS_GROUP_LIST,
  GET_TIME_SHEETS_GROUP_LIST_SUCCESS,
  GET_TIME_SHEETS_GROUP_LIST_ERROR,
  GET_TIME_SHEETS_LIST,
  GET_TIME_SHEETS_LIST_SUCCESS,
  GET_TIME_SHEETS_LIST_ERROR,
  CREATE_TIME_SHEET,
  CREATE_TIME_SHEET_SUCCESS,
  CREATE_TIME_SHEET_ERROR,
  GET_TIME_SHEET_FORM,
  GET_TIME_SHEET_FORM_SUCCESS,
  GET_TIME_SHEET_FORM_ERROR,
  CREATE_TIME_SHEET_FORM,
  CREATE_TIME_SHEET_FORM_SUCCESS,
  CREATE_TIME_SHEET_FORM_ERROR,
  UPDATE_TIME_SHEET,
  UPDATE_TIME_SHEET_SUCCESS,
  UPDATE_TIME_SHEET_ERROR,
  DELETE_TIME_SHEET,
  DELETE_TIME_SHEET_SUCCESS,
  DELETE_TIME_SHEET_ERROR,
} from "./actionTypes";

const initialState = {
  GET_TIME_SHEET_SUCCESS: "",
  GET_TIME_SHEET_ERROR: "",
  GET_TIME_SHEETS_GROUP_LIST_SUCCESS: "",
  GET_TIME_SHEETS_GROUP_LIST_ERROR: "",
  GET_TIME_SHEETS_LIST_SUCCESS: "",
  GET_TIME_SHEETS_LIST_ERROR: "",
  CREATE_TIME_SHEET_SUCCESS: "",
  CREATE_TIME_SHEET_ERROR: "",
  GET_TIME_SHEET_FORM_SUCCESS: "",
  GET_TIME_SHEET_FORM_ERROR: "",
  CREATE_TIME_SHEET_FORM_SUCCESS: "",
  CREATE_TIME_SHEET_FORM_ERROR: "",
  UPDATE_TIME_SHEET: "",
  UPDATE_TIME_SHEET_SUCCESS: "",
  UPDATE_TIME_SHEET_ERROR: "",
  DELETE_TIME_SHEET: "",
  DELETE_TIME_SHEET_SUCCESS: "",
  DELETE_TIME_SHEET_ERROR: "",
  loading: false,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    // ---------------------------------------------GET_TIME_SHEET
    case GET_TIME_SHEET:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_TIME_SHEET_SUCCESS:
      state = {
        ...state,
        GET_TIME_SHEET_SUCCESS: action.payload,
        loading: false,
      };
      break;

    case GET_TIME_SHEET_ERROR:
      state = {
        ...state,
        GET_TIME_SHEET_ERROR: action.payload,
        loading: false,
      };
      break;

    // ---------------------------------------------GET_TIME_SHEETS_GROUP_LIST
    case GET_TIME_SHEETS_GROUP_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_TIME_SHEETS_GROUP_LIST_SUCCESS:
      state = {
        ...state,
        GET_TIME_SHEETS_GROUP_LIST_SUCCESS: action.payload,
        loading: false,
      };
      break;

    case GET_TIME_SHEETS_GROUP_LIST_ERROR:
      state = {
        ...state,
        GET_TIME_SHEETS_GROUP_LIST_ERROR: action.payload,
        loading: false,
      };
      break;

    // ---------------------------------------------GET_TIME_SHEETS_LIST
    case GET_TIME_SHEETS_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_TIME_SHEETS_LIST_SUCCESS:
      state = {
        ...state,
        GET_TIME_SHEETS_LIST_SUCCESS: action.payload,
        loading: false,
      };
      break;

    case GET_TIME_SHEETS_LIST_ERROR:
      state = {
        ...state,
        GET_TIME_SHEETS_LIST_ERROR: action.payload,
        loading: false,
      };
      break;

    // ---------------------------------------------CREATE_TIME_SHEET
    case CREATE_TIME_SHEET:
      state = {
        ...state,
        loading: true,
      };
      break;

    case CREATE_TIME_SHEET_SUCCESS:
      state = {
        ...state,
        CREATE_TIME_SHEET_SUCCESS: action.payload,
        loading: false,
      };
      break;

    case CREATE_TIME_SHEET_ERROR:
      state = {
        ...state,
        CREATE_TIME_SHEET_ERROR: action.payload,
        loading: false,
      };
      break;

    // ---------------------------------------------GET_TIME_SHEET_FORM
    case GET_TIME_SHEET_FORM:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_TIME_SHEET_FORM_SUCCESS:
      state = {
        ...state,
        GET_TIME_SHEET_FORM_SUCCESS: action.payload,
        loading: false,
      };
      break;

    case GET_TIME_SHEET_FORM_ERROR:
      state = {
        ...state,
        GET_TIME_SHEET_FORM_ERROR: action.payload,
        loading: false,
      };
      break;

    // ---------------------------------------------CREATE_TIME_SHEET_FORM
    case CREATE_TIME_SHEET_FORM:
      state = {
        ...state,
        loading: true,
      };
      break;

    case CREATE_TIME_SHEET_FORM_SUCCESS:
      state = {
        ...state,
        CREATE_TIME_SHEET_FORM_SUCCESS: action.payload,
        loading: false,
      };
      break;

    case CREATE_TIME_SHEET_FORM_ERROR:
      state = {
        ...state,
        CREATE_TIME_SHEET_FORM_ERROR: action.payload,
        loading: false,
      };
      break;

    // ---------------------------------------------UPDATE_TIME_SHEET
    case UPDATE_TIME_SHEET:
      state = {
        ...state,
        loading: true,
      };
      break;

    case UPDATE_TIME_SHEET_SUCCESS:
      state = {
        ...state,
        UPDATE_TIME_SHEET_SUCCESS: action.payload,
        loading: false,
      };
      break;

    case UPDATE_TIME_SHEET_ERROR:
      state = {
        ...state,
        UPDATE_TIME_SHEET_ERROR: action.payload,
        loading: false,
      };
      break;

    // ---------------------------------------------DELETE_TIME_SHEET
    case DELETE_TIME_SHEET:
      state = {
        ...state,
        loading: true,
      };
      break;

    case DELETE_TIME_SHEET_SUCCESS:
      state = {
        ...state,
        DELETE_TIME_SHEET_SUCCESS: action.payload,
        loading: false,
      };
      break;

    case DELETE_TIME_SHEET_ERROR:
      state = {
        ...state,
        DELETE_TIME_SHEET_ERROR: action.payload,
        loading: false,
      };
      break;

    // ---------------------------------------------DEFAULT
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default reducer;
