import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useNavigate, useLocation } from "react-router";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  Button,
  FormFeedback,
  Alert,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { initialValues } from "./helper";

// Import images
import logoDark from "../../assets/images/logos/tc-logo-red-block-with-name.png";

import usersService from "../../services/users.service";

import reCAPTCHA from "../../components/Common/reCAPTCHA";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../../components/Form/ErrorMessage";
const recaptcha = new reCAPTCHA(process.env.REACT_APP_RECAPTCHA_SITE_KEY!, "register_user");

const NewUser = (props: any) => {
  const { t } = useTranslation();
  document.title = t("Register user") + " | " + process.env.REACT_APP_NAME;

  let navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [redirectLogin, setRedirectLogin] = useState(false);

  const [intialData, setIntialData] = useState(initialValues);

  const [successMsg, setSuccessMsg] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [errorData, setErrorData] = useState<any>({ data: {} });

  const handleChange = (fieldName: string, fieldValue: any) => {
    setIntialData({ ...intialData, [fieldName]: fieldValue });
    localStorage.setItem(
      "registerUser",
      JSON.stringify({ ...intialData, [fieldName]: fieldValue })
    );
  };

  const handleCheck = () => {
    if (intialData.is_gdpr_policy_accepted === false) {
      return navigate("/register-user/gdpr-policy", { state: { from: location } });
    } else {
      if (intialData.is_accepted === 1) {
        setIntialData({
          ...intialData,
          is_accepted: 0,
        });
      } else {
        setIntialData({
          ...intialData,
          is_accepted: 1,
        });
      }
    }
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required("First name field is required.")
      .min(3, "First name must be at least 3 characters.")
      .max(25, "First name may not be greater than 25 characters."),
    last_name: Yup.string()
      .required("Last name field is required.")
      .min(3, "Last name must be at least 3 characters.")
      .max(25, "Last name may not be greater than 25 characters."),
    email: Yup.string()
      .required("Email field is required.")
      .max(100, "Email may not be greater than 100 characters.")
      .email("Email must be a valid email address."),
  });

  const handleNewUser = async (values: any) => {
    try {
      const res = (await usersService.registerUser(values)) as any;
      if (res.data && res.data.status === "success") {
        setSuccessMsg(res.data.message);
        setErrorMsg("");
        setErrorData({ data: {} });
        localStorage.clear();
        setRedirectLogin(true);

        setTimeout(() => {
          return navigate("/login");
        }, 5000);
      } else if (res.data && res.data.status === "error") {
        setErrorMsg(res.data.message);
        setErrorData({ data: res.data.data });
        setSuccessMsg("");
        setLoading(false);
      } else {
        setErrorMsg(res.message);
        setSuccessMsg("");
        setLoading(false);
      }
    } catch (err) {
      setErrorMsg(err as any);
      setSuccessMsg("");
      setLoading(false);
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: intialData,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      let response: any = await recaptcha.getToken();
      if (response[0].status === "success") {
        var newData: any = { ...values, re_captcha_token: response[0].token };
        handleNewUser(newData);
      } else {
        setLoading(false);
        setErrorMsg(
          t(
            "Could not connect to the reCAPTCHA service. Please check your internet connection and reload to get a reCAPTCHA challenge."
          )
        );
      }
    },
  });

  useEffect(() => {
    const registerUser = localStorage.getItem("registerUser");
    if (registerUser) {
      setIntialData(JSON.parse(registerUser));
    }
  }, []);

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-white" style={{ borderBottom: "1px solid #c71e26" }}>
                  <Row>
                    <Col className="col-8">
                      <div className="p-4">
                        <h5 style={{ color: "#c71e26" }}>{t("Register user")} !</h5>
                        <p style={{ color: "#c71e26" }}>{process.env.REACT_APP_NAME}</p>
                      </div>
                    </Col>
                    <Col className="col-4 p-4 align-self-end">
                      <img src={logoDark} className="img-fluid" width={80} />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2 mt-3">
                    {successMsg ? <Alert color="success">{successMsg}</Alert> : null}

                    {errorMsg ? (
                      <Alert color="danger">
                        {errorMsg}

                        {errorData.data
                          ? Object.keys(errorData.data).map((key) => (
                              <li color="danger" key={key}>
                                {errorData.data[key]}
                              </li>
                            ))
                          : null}
                      </Alert>
                    ) : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">
                          {t("First name")} <span className="text-warning is_required">*</span>
                        </Label>
                        <Input
                          id="first_name"
                          name="first_name"
                          placeholder={t("First name")}
                          type="text"
                          autoFocus={true}
                          value={intialData.first_name}
                          onBlur={validation.handleBlur}
                          onChange={(e) => handleChange("first_name", e.target.value)}
                          disabled={loading}
                          invalid={
                            validation.touched.first_name && validation.errors.first_name
                              ? true
                              : false
                          }
                        />
                        <ErrorMessage error={validation.errors.first_name} touched={validation.touched.first_name} label={"First name"} />
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">
                          {t("Last name")} <span className="text-warning is_required">*</span>
                        </Label>
                        <Input
                          id="last_name"
                          name="last_name"
                          placeholder={t("Last name")}
                          type="text"
                          value={intialData.last_name}
                          onBlur={validation.handleBlur}
                          onChange={(e) => handleChange("last_name", e.target.value)}
                          disabled={loading}
                          invalid={
                            validation.touched.last_name && validation.errors.last_name
                              ? true
                              : false
                          }
                        />
                        <ErrorMessage error={validation.errors.last_name} touched={validation.touched.last_name} label={"Last name"} />
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">
                          {t("Email")} <span className="text-warning is_required">*</span>
                        </Label>
                        <Input
                          id="email"
                          name="email"
                          placeholder={t("Email")}
                          type="email"
                          autoComplete="off"
                          value={intialData.email}
                          onBlur={validation.handleBlur}
                          onChange={(e) => handleChange("email", e.target.value)}
                          disabled={loading}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        <ErrorMessage error={validation.errors.email} touched={validation.touched.email} label={"Email"} />
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">{t("Mobile")}</Label>
                        <Input
                          id="mobile"
                          name="mobile"
                          placeholder={t("Mobile")}
                          type="tel"
                          value={intialData.mobile}
                          onBlur={validation.handleBlur}
                          onChange={(e) => handleChange("mobile", e.target.value)}
                          disabled={loading}
                          invalid={
                            validation.touched.mobile && validation.errors.mobile ? true : false
                          }
                        />
                        <ErrorMessage error={validation.errors.mobile} touched={validation.touched.mobile} label={"Mobile"} />
                      </div>

                      <div className="mb-3">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="is_accepted"
                            value={intialData.is_accepted}
                            checked={intialData.is_accepted === 1 ? true : false}
                            onChange={handleCheck}
                            disabled={loading}
                          />
                          <label className="form-check-label" htmlFor="is_accepted">
                            {t(
                              'By clicking the button \"Register\" you acknowledge reading and accepting our'
                            )}
                          </label>
                        </div>
                        <div className="text-center">
                          <Link
                            to="/register-user/privacy-policy"
                            state={{ from: location }}
                            className="link-primary text-decoration-underline me-1"
                          >
                            {t("Privacy policy")}
                          </Link>
                          {t("and")}
                          <Link
                            to="/register-user/terms-conditions"
                            state={{ from: location }}
                            className="link-primary text-decoration-underline ms-1"
                          >
                            {t("Terms & conditions")}
                          </Link>
                        </div>
                      </div>

                      <div className="mt-3 d-grid">
                        <Button
                          color="primary"
                          className="btn-block"
                          type="submit"
                          disabled={
                            loading
                              ? loading
                              : !(intialData.is_gdpr_policy_accepted && intialData.is_accepted)
                          }
                        >
                          {loading ? (
                            <span>
                              <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                                hourglass_empty
                              </span>
                              {loading && <span>Please wait...</span>}
                              {redirectLogin && (
                                <span>
                                  <br />
                                  {t("we are redirecting to login page...")}
                                </span>
                              )}
                            </span>
                          ) : (
                            <span>
                              <span className="align-middle me-1 material-symbols-outlined fw-light fs-4">
                                how_to_reg
                              </span>
                              <span>{t("Register")}</span>
                            </span>
                          )}
                        </Button>
                      </div>
                    </Form>

                    <div className="mt-4 text-center">
                      <Link to="/login" className="text-muted">
                        <span className="align-middle me-1 material-symbols-outlined fw-light fs-4">
                          login
                        </span>
                        {t("Back to login")}
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()}{" "}
                  <strong>Tax Consult HR Tax & International Mobility</strong>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NewUser;
