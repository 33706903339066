import React from 'react';
import { Button, ModalHeader } from 'reactstrap';

interface Props {
  onConfirm: () => void;
  onDismiss: () => void;
  title: string;
}

export const ConfirmContent: React.FC<Props> = ({
  title,
  onConfirm,
  onDismiss,
}) => (
  <div>
    <ModalHeader as="h4" inverted>
      {title}
    </ModalHeader>

    <Button onClick={onDismiss}>No</Button>
    <Button negative onClick={onConfirm}>
      Yes
    </Button>
  </div>
);
