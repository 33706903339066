import { takeEvery, call, put } from "redux-saga/effects";
import userStatusService from "../../../services/masters.user.status.service";
import { AnyAction } from "redux";

// UserStatus Redux States
import {
  GET_USER_STATUS,
  GET_USER_STATUS_LIST,
  CREATE_USER_STATUS,
  UPDATE_USER_STATUS,
  DELETE_USER_STATUS,
} from "./actionTypes";

import { 
    getUserStatusSuccess, 
    getUserStatusError,
    
    getUserStatusListSuccess,
    getUserStatusListError,

    createUserStatusSuccess,
    createUserStatusError,

    updateUserStatusSuccess,
    updateUserStatusError,

    deleteUserStatusSuccess,
    deleteUserStatusError,
} from "./actions";

function* getUserStatus({ payload: { userstatus, history } }: AnyAction): any {
  try {
    const response = yield call(userStatusService.getUserStatus, userstatus);
    if (response.data && response.data.status === "success") {
      yield put(getUserStatusSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getUserStatusError(response.data));
    } else {
      yield put(getUserStatusError(response));
    }
  } catch (error: any) {
    yield put(getUserStatusError(error));
  }
}

function* getUserStatusList({ payload: { query, history } }: AnyAction): any {
  try {
    const response = yield call(userStatusService.getUserStatusList, query);
    if (response.data && response.data.status === "success") {
      yield put(getUserStatusListSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getUserStatusListError(response.data));
    } else {
      yield put(getUserStatusListError(response));
    }
  } catch (error: any) {
    yield put(getUserStatusListError(error));
  }
}

function* createUserStatus({ payload: { userstatus, history } }: AnyAction): any {
  try {
    const response = yield call(userStatusService.createUserStatus, userstatus);
    if (response.data && response.data.status === "success") {
      yield put(createUserStatusSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(createUserStatusError(response.data));
    } else {
      yield put(createUserStatusError(response));
    }
  } catch (error: any) {
    yield put(createUserStatusError(error));
  }
}

function* updateUserStatus({ payload: { userstatus, history } }: AnyAction): any {
  try {
    const response = yield call(userStatusService.updateUserStatus, userstatus);
    if (response.data && response.data.status === "success") {
      yield put(updateUserStatusSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(updateUserStatusError(response.data));
    } else {
      yield put(updateUserStatusError(response));
    }
  } catch (error: any) {
    yield put(updateUserStatusError(error));
  }
}

function* deleteUserStatus({ payload: { userstatus, history } }: AnyAction): any {
  try {
    const response = yield call(userStatusService.deleteUserStatus, userstatus);
    if (response.data && response.data.status === "success") {
      yield put(deleteUserStatusSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(deleteUserStatusError(response.data));
    } else {
      yield put(deleteUserStatusError(response));
    }
  } catch (error: any) {
    yield put(deleteUserStatusError(error));
  }
}

function* userStatusSaga(): any {
  yield takeEvery(GET_USER_STATUS, getUserStatus);
  yield takeEvery(GET_USER_STATUS_LIST, getUserStatusList);
  yield takeEvery(CREATE_USER_STATUS, createUserStatus);
  yield takeEvery(UPDATE_USER_STATUS, updateUserStatus);
  yield takeEvery(DELETE_USER_STATUS, deleteUserStatus);
}

export default userStatusSaga;
