import {
  GET_USER_MANUAL_LIST,
  GET_USER_MANUAL_LIST_SUCCESS,
  GET_USER_MANUAL_LIST_ERROR,

  GET_USER_MANUAL,
  GET_USER_MANUAL_SUCCESS,
  GET_USER_MANUAL_ERROR,
} from "./actionTypes";

// ---------------------------------------------GET_USER_MANUAL_LIST
export const getUserManualList = (history: any) => {
  return {
    type: GET_USER_MANUAL_LIST,
    payload: { history },
  };
};

export const getUserManualListSuccess = (data: {}) => {
  return {
    type: GET_USER_MANUAL_LIST_SUCCESS,
    payload: data,
  };
};

export const getUserManualListError = (error: {}) => {
  return {
    type: GET_USER_MANUAL_LIST_ERROR,
    payload: error,
  };
};

// ---------------------------------------------GET_USER_MANUAL
export const getUserManual = (data: any, history: any) => {
  return {
    type: GET_USER_MANUAL,
    payload: { data, history },
  };
};

export const getUserManualSuccess = (data: {}) => {
  return {
    type: GET_USER_MANUAL_SUCCESS,
    payload: data,
  };
};

export const getUserManualError = (error: {}) => {
  return {
    type: GET_USER_MANUAL_ERROR,
    payload: error,
  };
};