import React, { FC, useEffect, useState } from 'react'
import { Col, Input, Label, Row } from 'reactstrap';

import Select from "react-select";
import commonService from '../../../services/common.service';
import { addAllUsersInUsers, addIndividualInCompany, getDependentSelectOptionsWithOrder, getUserProfile, yearData } from '../../../helpers/functions_helper';
import { useTranslation } from 'react-i18next';
import { statusOptions } from '../../../common/staticdata';
import Flatpickr from '../../../components/Form/Flatpickr';
import { CommonAction } from '../../../store/actions';

import { useDispatch, useSelector } from "react-redux";
type FiltersProps = {
    filters: any;
    onApply: any;
    onClear: any;
    onChange?: any;
    company_id?: any;
}

const Filters: FC<FiltersProps> = ({ filters, onApply, onClear, onChange, company_id }) => {
    const { t } = useTranslation();
    const userProfile = getUserProfile();
    const dispatch = useDispatch();
    const [companyOption, setCompanyOption] = useState([]);
    const [loadingCompany, setLoadingCompany] = useState(true);
    const [loadingCompanyList, setloadingCompanyList] = useState(true);
    const [companyID, setCompanyID] = useState<any>(filters?.company_id);
    const [loadingUsers, setLoadingUsers] = useState(true);
    const [usersOptions, setUsersOptions] = useState([]);
    const [query, setQuery] = useState("");
    const getDependentSelectOptions = async (
        tableName: string,
        fieldName: string,
        whereField: string,
        whereID: number
    ) => {
        const response = await commonService.getDependentSelectOptionsList(
            tableName,
            fieldName,
            whereField,
            whereID,
            query ? query : ""
        );
        return response.data.data;
    };

    let { getCompanyOptionsList, getUserOptionsList } = useSelector((state: any) => ({
        getCompanyOptionsList: state.Common.GET_COMPANY_OPTIONS_LIST_SUCCESS_NEW,
        getUserOptionsList: state.Common.GET_USER_OPTIONS_LIST_SUCCESS_NEW,
    }));

    useEffect(() => {
        if (!getCompanyOptionsList) dispatch(CommonAction.getCompanyOptionsList_New());
        if (!getUserOptionsList) dispatch(CommonAction.getUserOptionsList_New());
    }, []);

    useEffect(() => {
        if (getCompanyOptionsList.data) {
            setCompanyOption(getCompanyOptionsList.data);
            setLoadingCompany(false);
        }
    }, [getCompanyOptionsList]);


    useEffect(() => {
        if (companyID) {
            getDependentSelectOptions("tbl_master_users", "first_name", "company", companyID).then(function (
                options
            ) {
                const item = addAllUsersInUsers(options) as any;
                setUsersOptions(item);
                setLoadingUsers(false);
            });
        } else {
            const company_id = userProfile.USER_COMPANY;
            getDependentSelectOptions("tbl_master_users", "first_name", "company", company_id).then(function (
                options
            ) {
                const item = addAllUsersInUsers(options) as any;
                setUsersOptions(item);
                setLoadingUsers(false);
            });
        }
    }, [companyID]);

    const [usersList, setUsersList] = useState<any>([]);
    useEffect(() => {
        if (usersOptions) {
            const Options = usersOptions?.map((option: any) => ({
                label: t(option.label),
                value: option.value
            }));
            setUsersList(Options);
        }
    }, [usersOptions, localStorage.getItem("I18N_LANGUAGE")])


    const DocStatusList = [
        {
            "label": t("Draft"),
            value: 1,
        },
        {
            "label": t("Sent"),
            value: 2,
        },

    ];

    const StatusList = [
        {
            "label": t("To do"),
            value: 1,
        },
        {
            "label": t("Submitted"),
            value: 3,
        },
        {
            "label": t("Refused"),
            value: 2,
        },
        {
            "label": t("Finalized"),
            value: 4,
        },
        {
            "label": t("Expired"),
            value: 5,
        },

    ];

    return (
        <React.Fragment>
            {!!userProfile.IS_COMPANY_ADMIN &&
                <Row>
                    <Col>
                        <div className="mb-3">
                            <Label className="form-label">
                                {t("Client")}
                            </Label>
                            {loadingCompany ? <Input id={`ph_cmp`} disabled value={t('Loading...')} /> :
                                <Select
                                    id="company_id"
                                    name="company_id"
                                    className="is-invalid"
                                    options={companyOption}
                                    placeholder={t("Select...")}
                                    value={
                                        filters?.company_id
                                            ? companyOption.find((option: any) => option.value == filters?.company_id)
                                            : ""
                                    }
                                    isSearchable={true}
                                    isDisabled={loadingCompany}
                                    onChange={(option: any) => {
                                        onChange({ is_filtered: true, company_id: option.value })
                                        setCompanyID(option.value);
                                    }}
                                />
                            }
                        </div>
                    </Col>
                </Row>
            }
            <Row>
                <Col>
                    <div className="mb-3">
                        <Label className="form-label">
                            {t("User")}
                        </Label>
                        <Select
                            options={usersList}
                            value={
                                filters?.user_id
                                    ? usersList?.find((option: any) => option.value == filters?.user_id)
                                    : ""
                            }
                            placeholder={t("Select...")}
                            isSearchable={true}
                            isDisabled={loadingUsers}
                            isLoading={loadingUsers}
                            onChange={(option: any) => {
                                onChange({ is_filtered: true, user_id: option.value });
                            }}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="mb-3">
                        <Label className="form-label">
                            {t("File name")}{" "}
                        </Label>
                        <Input type="text" name="file_name" value={filters.file_name} placeholder='File name'
                        onChange={(e:any)=>{
                            onChange({ is_filtered: true, file_name: e.target.value });
                        }}></Input>
                        
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="mb-3">
                        <Label className="form-label">
                            {t(" Document status")}{" "}
                        </Label>
                        <Select
                            id="status"
                            name="status"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={DocStatusList}
                            value={
                                filters?.document_status
                                    ? DocStatusList.find((option: any) => option.value == filters?.document_status)
                                    : ""
                            }
                            isSearchable={true}
                            onChange={(option: any) => {
                                onChange({ is_filtered: true, document_status: option.value })
                            }}
                        />

                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="mb-3">
                        <Label className="form-label">
                            {t("Status")}{" "}
                        </Label>
                        <Select
                            id="status"
                            name="status"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={StatusList}
                            value={
                                filters?.status
                                    ? StatusList.find((option: any) => option.value == filters?.status)
                                    : ""
                            }
                            isSearchable={true}
                            onChange={(option: any) => {
                                onChange({ is_filtered: true, status: option.value })
                            }}
                        />

                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="mb-3">
                        <Row>
                            <Label>{t('Expiry date')}</Label>
                            <Col>
                                <Label>{t('From')}</Label>
                                <div className="input-group is-invalid">
                                    <Flatpickr
                                        id="expiry_date_from"
                                        name="expiry_date_from"
                                        className="form-control d-block"
                                        type="text"
                                        placeholder={t("From")}
                                        value={filters?.expiry_date_from}
                                        options={{
                                            altInput: true,
                                            altFormat: "d/m/Y",
                                            dateFormat: "Y-m-d H:i:S",
                                            defaultDate: filters.expiry_date_from,
                                            allowInput: true,
                                            onChange: function (
                                                selectedDates,
                                                dateStr,
                                                instance
                                            ) {
                                                onChange({ is_filtered: true, expiry_date_from: dateStr })
                                            },
                                            locale: { firstDayOfWeek: 1 }
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col>
                                <Label>{t('To')}</Label>
                                <div className="input-group is-invalid">
                                    <Flatpickr
                                        id="expiry_date_to"
                                        name="expiry_date_to"
                                        className="form-control d-block"
                                        type="text"
                                        placeholder={t("To")}
                                        value={filters?.expiry_date_to}
                                        options={{
                                            altInput: true,
                                            altFormat: "d/m/Y",
                                            dateFormat: "Y-m-d H:i:S",
                                            defaultDate: filters.expiry_date_to,
                                            allowInput: true,
                                            onChange: function (
                                                selectedDates,
                                                dateStr,
                                                instance
                                            ) {
                                                onChange({ is_filtered: true, expiry_date_to: dateStr })
                                            },
                                            locale: { firstDayOfWeek: 1 }
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="mb-3">
                        <Row>
                            <Label>{t('Due date')}</Label>
                            <Col>
                                <Label>{t('From')}</Label>
                                <div className="input-group is-invalid">
                                    <Flatpickr
                                        id="due_date_from"
                                        name="due_date_from"
                                        className="form-control d-block"
                                        type="text"
                                        placeholder={t("From")}
                                        value={filters?.due_date_from}
                                        options={{
                                            altInput: true,
                                            altFormat: "d/m/Y",
                                            dateFormat: "Y-m-d H:i:S",
                                            defaultDate: filters.due_date_from,
                                            allowInput: true,
                                            onChange: function (
                                                selectedDates,
                                                dateStr,
                                                instance
                                            ) {
                                                onChange({ is_filtered: true, due_date_from: dateStr })
                                            },
                                            locale: { firstDayOfWeek: 1 }
                                        }}
                                    />
                                </div>
                            </Col>
                            <Col>
                                <Label>{t('To')}</Label>
                                <div className="input-group is-invalid">
                                    <Flatpickr
                                        id="due_date_to"
                                        name="due_date_to"
                                        className="form-control d-block"
                                        type="text"
                                        placeholder={t("To")}
                                        value={filters?.due_date_to}
                                        options={{
                                            altInput: true,
                                            altFormat: "d/m/Y",
                                            dateFormat: "Y-m-d H:i:S",
                                            defaultDate: filters.due_date_to,
                                            allowInput: true,
                                            onChange: function (
                                                selectedDates,
                                                dateStr,
                                                instance
                                            ) {
                                                onChange({ is_filtered: true, due_date_to: dateStr })
                                            },
                                            locale: { firstDayOfWeek: 1 }
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default Filters