import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Button,
} from "reactstrap";
import { vLookup } from "../../helpers/functions_helper";
import { formatCurrency } from "../../utils/formats";
import { useTranslation } from "react-i18next";

const SSSCModel_80 = (props: any) => {
  const { t } = useTranslation();
  let spCountryData = props.spCountryData;

  let C_WERBUNG = props.C_WERBUNG;
  let C_SONDER = props.C_SONDER;
  let C_SPECIAL_EXP = props.C_SPECIAL_EXP;
  let C_REBATE_TREATY = props.C_REBATE_TREATY;
  let C_SOLIDARITY = props.C_SOLIDARITY;
  let C_SOLIDARITY_MIN_LIMIT = props.C_SOLIDARITY_MIN_LIMIT;
  let C_SOLIDARITY_MIN_PERC = props.C_SOLIDARITY_MIN_PERC;

  let TAX_RATE_G = props.TAX_RATE_G;
  let TAX_RATE = props.TAX_RATE; // B21

  const [COUNTRY_IDX, setCOUNTRY_IDX] = useState<number>();
  const [D_EMP_DIR, setD_EMP_DIR] = useState<number>(1);

  // const [C_WERBUNG, setC_WERBUNG] = useState<number>(1000);
  // const [C_SONDER, setC_SONDER] = useState<number>(0);
  // const [C_SPECIAL_EXP, setC_SPECIAL_EXP] = useState<number>(36);
  // const [C_REBATE_TREATY, setC_REBATE_TREATY] = useState<number>(8); // %

  // const [C_SOLIDARITY, setC_SOLIDARITY] = useState<number>(5.5); // %
  // const [C_SOLIDARITY_MIN_LIMIT, setC_SOLIDARITY_MIN_LIMIT] =
  //   useState<number>(972);
  // const [C_SOLIDARITY_MIN_PERC, setC_SOLIDARITY_MIN_PERC] =
  //   useState<number>(20); // %

  // const [B21, setB21] = useState<number>(30); // %

  const [D_GROSS_COUNTRY, setD_GROSS_COUNTRY] = useState<number>(0);
  const [C_NET_TAXABLE_COUNTRY, setC_NET_TAXABLE_COUNTRY] = useState<number>(0);
  const [C_NET_TAXABLE_ROUNDED, setC_NET_TAXABLE_ROUNDED] = useState<number>(0);
  const [TAX_RATE_G_CALCULATED, setTAX_RATE_G_CALCULATED] = useState<any>(TAX_RATE_G);

  const [C_BASE_TAX, setC_BASE_TAX] = useState<number>(0);
  const [C_BASE_TAX_ROUNDED, setC_BASE_TAX_ROUNDED] = useState<number>(0);
  const [C_REBATE, setC_REBATE] = useState<number>(0);
  const [C_SOLIDARITY_TAX, setC_SOLIDARITY_TAX] = useState<number>(0);
  const [C_SUBTOTAL_TAX, setC_SUBTOTAL_TAX] = useState<number>(0);

  const [C_TAX_COUNTRY, setC_TAX_COUNTRY] = useState<number>(0);
  const [D41, setD41] = useState<number>(0);

  useEffect(() => {
    if (spCountryData) {
      const COUNTRY_DATA = spCountryData.find((data: any) => data.country_id === 80);

      if (COUNTRY_DATA) {
        setCOUNTRY_IDX(spCountryData.findIndex((data: any) => data.country_id === 80));
        setD_GROSS_COUNTRY(
          spCountryData.find((data: any) => data.country_id === 80)?.D_GROSS_COUNTRY
        );
        setD_EMP_DIR(spCountryData.find((data: any) => data.country_id === 80)?.emp_dir);
      }
    }
  }, [spCountryData]);

  useEffect(() => {
    setC_NET_TAXABLE_COUNTRY(
      Number(D_GROSS_COUNTRY) - Number(C_WERBUNG) - Number(C_SONDER) - Number(C_SPECIAL_EXP)
    );
  }, [D_GROSS_COUNTRY, C_WERBUNG, C_SONDER, C_SPECIAL_EXP]);

  useEffect(() => {
    let C_NET_TAXABLE_COUNTRY_VALUE = 0;

    if (C_NET_TAXABLE_COUNTRY < 0) {
      C_NET_TAXABLE_COUNTRY_VALUE = 0;
    } else {
      C_NET_TAXABLE_COUNTRY_VALUE = Math.floor(C_NET_TAXABLE_COUNTRY);
    }

    setC_NET_TAXABLE_ROUNDED(C_NET_TAXABLE_COUNTRY_VALUE);
  }, [C_NET_TAXABLE_COUNTRY]);

  useEffect(() => {
    if (TAX_RATE_G && TAX_RATE_G.length > 0) {
      var newData: any = [...TAX_RATE_G];
      newData.map((item: any, index: number) => {
        if (index !== 0) {
          newData[index][0] = newData[index - 1][1];
        }
      });

      newData[0][3] = 0;

      // =((997,8*(C_NET_TAXABLE_ROUNDED-C15)/10000)+1400)*(C_NET_TAXABLE_ROUNDED-C15)/10000
      newData[1][3] =
        (((997.8 * (Number(C_NET_TAXABLE_ROUNDED) - Number(TAX_RATE_G[0][1]))) / 10000 + 1400) *
          (Number(C_NET_TAXABLE_ROUNDED) - Number(TAX_RATE_G[0][1]))) /
        10000;

      // =((220,13*(C_NET_TAXABLE_ROUNDED-C16)/10000)+2397)*((C_NET_TAXABLE_ROUNDED-C16)/10000)+948,49
      newData[2][3] =
        ((220.13 * (Number(C_NET_TAXABLE_ROUNDED) - Number(TAX_RATE_G[1][1]))) / 10000 + 2397) *
          ((Number(C_NET_TAXABLE_ROUNDED) - Number(TAX_RATE_G[1][1])) / 10000) +
        948.49;

      // =C_NET_TAXABLE_ROUNDED*D18-8621,75
      newData[3][3] = Number(C_NET_TAXABLE_ROUNDED) * (Number(TAX_RATE_G[3][2]) / 100) - 8621.75;

      // =C_NET_TAXABLE_ROUNDED*D19-16437,7
      newData[4][3] = Number(C_NET_TAXABLE_ROUNDED) * (Number(TAX_RATE_G[4][2]) / 100) - 16437.7;

      setTAX_RATE_G_CALCULATED(newData);
    }
  }, [C_NET_TAXABLE_ROUNDED, TAX_RATE_G]);

  useEffect(() => {
    if (TAX_RATE_G_CALCULATED && TAX_RATE_G_CALCULATED.length > 0) {
      let C_BASE_TAX_VALUE = vLookup(C_NET_TAXABLE_ROUNDED, TAX_RATE_G_CALCULATED, 4);
      setC_BASE_TAX(C_BASE_TAX_VALUE);
    }
  }, [C_NET_TAXABLE_ROUNDED, TAX_RATE_G_CALCULATED]);

  useEffect(() => {
    setC_BASE_TAX_ROUNDED(Math.floor(C_BASE_TAX));
  }, [C_BASE_TAX]);

  useEffect(() => {
    setC_REBATE(Number(C_BASE_TAX_ROUNDED) * (Number(C_REBATE_TREATY) / 100));
  }, [C_BASE_TAX_ROUNDED, C_REBATE_TREATY]);

  useEffect(() => {
    let C_SOLIDARITY_TAX_VALUE = 0;

    if (Number(C_BASE_TAX_ROUNDED) > Number(C_SOLIDARITY_MIN_LIMIT)) {
      C_SOLIDARITY_TAX_VALUE = Math.min(
        (Number(C_BASE_TAX_ROUNDED) - Number(C_REBATE)) * (Number(C_SOLIDARITY) / 100),
        (Number(C_BASE_TAX_ROUNDED) - Number(C_REBATE) - Number(C_SOLIDARITY_MIN_LIMIT)) *
          (Number(C_SOLIDARITY_MIN_PERC) / 100)
      );
    } else {
      C_SOLIDARITY_TAX_VALUE = 0;
    }

    setC_SOLIDARITY_TAX(C_SOLIDARITY_TAX_VALUE);
  }, [C_BASE_TAX_ROUNDED, C_SOLIDARITY_MIN_LIMIT, C_REBATE, C_SOLIDARITY, C_SOLIDARITY_MIN_PERC]);

  useEffect(() => {
    setC_SUBTOTAL_TAX(Number(C_BASE_TAX_ROUNDED) + Number(C_SOLIDARITY_TAX) - Number(C_REBATE));
  }, [C_BASE_TAX_ROUNDED, C_SOLIDARITY_TAX, C_REBATE]);

  useEffect(() => {
    setD41(Number(D_GROSS_COUNTRY) * (Number(TAX_RATE) / 100));
  }, [D_GROSS_COUNTRY, TAX_RATE]);

  useEffect(() => {
    let C_TAX_COUNTRY_VALUE = 0;
    if (D_EMP_DIR === 1) {
      C_TAX_COUNTRY_VALUE = C_SUBTOTAL_TAX;
    } else {
      C_TAX_COUNTRY_VALUE = D41;
    }

    setC_TAX_COUNTRY(C_TAX_COUNTRY_VALUE);
  }, [D_EMP_DIR, C_SUBTOTAL_TAX, D41]);

  useEffect(() => {
    if (COUNTRY_IDX) {
      props.onCalculationUpdate({
        COUNTRY_IDX: COUNTRY_IDX,
        C_TAXES_COUNTRY: C_TAX_COUNTRY,
      });
    }
  }, [COUNTRY_IDX, C_TAX_COUNTRY]);

  return (
    <React.Fragment>
      <Modal
        size="xl"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">{t("GERMANY")}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label={t("Close")}
          ></button>
        </div>

        <ModalBody>
          <Card>
            <CardHeader>
              <CardTitle>1. {t("PARAMETERS")}</CardTitle>
            </CardHeader>

            <CardBody>
              <div className="table-responsive mb-3">
                <Table className="table table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr>
                      <td>{t("ADVERTISING")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_WERBUNG).toFixed(2), false)}
                      </td>
                      <td>{t("Advertising costs")}</td>
                    </tr>
                    <tr>
                      <td>{t("SPECIAL")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_SONDER).toFixed(2), false)}
                      </td>
                      <td>{t("Special editions")}</td>
                    </tr>
                    <tr>
                      <td>{t("SPECIAL EXP")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_SPECIAL_EXP).toFixed(2), false)}
                      </td>
                      <td>{t("Special expense deduction")}</td>
                    </tr>
                    <tr>
                      <td>{t("REBATE TREATY")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_REBATE_TREATY).toFixed(2), false)}%
                      </td>
                      <td>{t("Tax rebate double tax treaty")}</td>
                    </tr>

                    <tr>
                      <td colSpan={3}></td>
                    </tr>

                    <tr>
                      <td>{t("SOLIDARITY")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_SOLIDARITY).toFixed(2), false)}%
                      </td>
                      <td>{t("Solidarity tax")}</td>
                    </tr>
                    <tr>
                      <td>
                        {t("SOLIDARITY")} ({t("MIN LIMIT")})
                      </td>
                      <td className="text-end">
                        {formatCurrency(Number(C_SOLIDARITY_MIN_LIMIT).toFixed(2), false)}
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>
                        {t("SOLIDARITY")} ({t("MIN PERC")})
                      </td>
                      <td className="text-end">
                        {formatCurrency(Number(C_SOLIDARITY_MIN_PERC).toFixed(2), false)}%
                      </td>
                      <td></td>
                    </tr>

                    <tr>
                      <td colSpan={3}></td>
                    </tr>

                    <tr>
                      <td>{t("Tax rate - Director")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(TAX_RATE).toFixed(2), false)}%
                      </td>
                      <td>{t("(director's income at supervisory board)")}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              <div className="table-responsive mb-3">
                <Table className="table table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr className="align-bottom">
                      <td colSpan={4} className="text-end">
                        {t(
                          "Rates for married taxpayers are only applicable in case the spouse is also living in Germany. No longer non-resident of Germany"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th colSpan={2} className="text-center">
                        {t("Single taxpayers")}
                      </th>
                      <th className="text-end">{t("Tax rate")}</th>
                      <th className="text-end">{t("Formula")}</th>
                    </tr>
                    {TAX_RATE_G_CALCULATED &&
                      TAX_RATE_G_CALCULATED.map((item: any, idx: number) => (
                        <tr key={idx} className="align-bottom">
                          <td className="text-end">
                            {formatCurrency(parseFloat(item[0]).toFixed(2), false)}
                          </td>
                          <td className="text-end">
                            {formatCurrency(parseFloat(item[1]).toFixed(2), false)}
                          </td>
                          <td className="text-end">
                            {formatCurrency(parseFloat(item[2]).toFixed(2), false)}%
                          </td>
                          <td className="text-end">
                            {formatCurrency(parseFloat(item[3]).toFixed(2), false)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>2. {t("COMPUTATION")}</CardTitle>
            </CardHeader>

            <CardBody>
              <div className="table-responsive mb-3">
                <Table className="table table-hover nowrap w-100 mb-0">
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-end">{t("Employee")}</th>
                      <th className="text-end">{t("Director")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t("GROSS")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(D_GROSS_COUNTRY).toFixed(2), false)}
                      </td>
                      <td className="text-end">
                        {formatCurrency(Number(D_GROSS_COUNTRY).toFixed(2), false)}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("ADVERTISING")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_WERBUNG).toFixed(2), false)}
                      </td>
                      <td className="text-end"></td>
                    </tr>
                    <tr>
                      <td>{t("SPECIAL")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_SONDER).toFixed(2), false)}
                      </td>
                      <td className="text-end"></td>
                    </tr>
                    <tr>
                      <td>{t("SPECIAL EXP")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_SPECIAL_EXP).toFixed(2), false)}
                      </td>
                      <td className="text-end"></td>
                    </tr>

                    <tr>
                      <td colSpan={3}></td>
                    </tr>

                    <tr>
                      <td>{t("NET TAXABLE")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_NET_TAXABLE_COUNTRY).toFixed(2), false)}
                      </td>
                      <td className="text-end"></td>
                    </tr>
                    <tr>
                      <td>
                        {t("NET TAXABLE")} ({t("ROUNDED")})
                      </td>
                      <td className="text-end">
                        {formatCurrency(Number(C_NET_TAXABLE_ROUNDED).toFixed(2), false)}
                      </td>
                      <td className="text-end"></td>
                    </tr>

                    <tr>
                      <td colSpan={3}></td>
                    </tr>

                    <tr>
                      <td>{t("BASE TAX")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_BASE_TAX).toFixed(2), false)}
                      </td>
                      <td className="text-end"></td>
                    </tr>
                    <tr>
                      <td>
                        {t("BASE TAX")} {t("ROUNDED")}
                      </td>
                      <td className="text-end">
                        {formatCurrency(Number(C_BASE_TAX_ROUNDED).toFixed(2), false)}
                      </td>
                      <td className="text-end"></td>
                    </tr>
                    <tr>
                      <td>{t("REBATE")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_REBATE).toFixed(2), false)}
                      </td>
                      <td className="text-end"></td>
                    </tr>
                    <tr>
                      <td>{t("SOLIDARITY TAX")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_SOLIDARITY_TAX).toFixed(2), false)}
                      </td>
                      <td className="text-end"></td>
                    </tr>
                    <tr>
                      <td>{t("SUBTOTAL TAX")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_SUBTOTAL_TAX).toFixed(2), false)}
                      </td>
                      <td className="text-end"></td>
                    </tr>

                    <tr>
                      <td colSpan={3}></td>
                    </tr>

                    <tr>
                      <td>{t("COUNTRY TAX")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_TAX_COUNTRY).toFixed(2), false)}
                      </td>
                      <td className="text-end">{formatCurrency(Number(D41).toFixed(2), false)}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </ModalBody>

        <ModalFooter>
          <Button color="danger" className="btn-block" type="reset" onClick={props.onCloseClick}>
            {t("Close")}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default SSSCModel_80;
