/* COMPANY */
export const GET_QUESTIONNAIRE_STATUSES = "GET_QUESTIONNAIRE_STATUSES"
export const GET_QUESTIONNAIRE_STATUSES_SUCCESS = "GET_QUESTIONNAIRE_STATUS_SUCCESS"
export const GET_QUESTIONNAIRE_STATUSES_FAIL = "GET_QUESTIONNAIRE_STATUSES_FAIL"

/* COMPANY DETAIL*/
export const GET_QUESTIONNAIRE_STATUS_DETAIL = "GET_QUESTIONNAIRE_STATUS_DETAIL"
export const GET_QUESTIONNAIRE_STATUS_DETAIL_SUCCESS = "GET_QUESTIONNAIRE_STATUS_DETAIL_SUCCESS"
export const GET_QUESTIONNAIRE_STATUS_DETAIL_FAIL = "GET_QUESTIONNAIRE_STATUS_DETAIL_FAIL"

/**
 * add company
 */
export const ADD_NEW_QUESTIONNAIRE_STATUS = "ADD_NEW_QUESTIONNAIRE_STATUS"
export const ADD_QUESTIONNAIRE_STATUS_SUCCESS = "ADD_QUESTIONNAIRE_STATUS_SUCCESS"
export const ADD_QUESTIONNAIRE_STATUS_FAIL = "ADD_QUESTIONNAIRE_STATUS_FAIL"

/**
 * Edit company
 */
export const UPDATE_QUESTIONNAIRE_STATUS = "UPDATE_QUESTIONNAIRE_STATUS"
export const UPDATE_QUESTIONNAIRE_STATUS_SUCCESS = "UPDATE_QUESTIONNAIRE_STATUS_SUCCESS"
export const UPDATE_QUESTIONNAIRE_STATUS_FAIL = "UPDATE_QUESTIONNAIRE_STATUS_FAIL"

/**
 * Delete company
 */
export const DELETE_QUESTIONNAIRE_STATUS = "DELETE_QUESTIONNAIRE_STATUS"
export const DELETE_QUESTIONNAIRE_STATUS_SUCCESS = "DELETE_QUESTIONNAIRE_STATUS_SUCCESS"
export const DELETE_QUESTIONNAIRE_STATUS_FAIL = "DELETE_QUESTIONNAIRE_STATUS_FAIL"