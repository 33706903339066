import React, { FC, useEffect, useState } from 'react'
import { Col, Label, Row } from 'reactstrap';

import Select from "react-select";
import commonService from '../../../services/common.service';
import { yearData } from '../../../helpers/functions_helper';
import { useTranslation } from 'react-i18next';

type FiltersProps = {
    filters: any;
    onApply: any;
    onClear: any;
    onChange?: any;
    filter_type: any;
}

const Filters: FC<FiltersProps> = ({ filters, onApply, onClear, onChange, filter_type }) => {
    const {t} = useTranslation();
    /* set user Status */
    const [userStatusOption, setuserStatusOption] = useState([]);
    const [loadingUserStatus, setloadingUserStatus] = useState(true);
    /* set questionnaire type */
    const [questionnaireTypeOption, setQuestionnaireTypeOption] = useState([]);
    const [questionnaireType, setQuestionnaireType] = useState({
        value: null,
        label: t("Please select...")
    });
    const [loadingQuestionnaireType, setLoadingQuestionnaireType] = useState(true);

    const [userType, setUserType] = useState<any>(filters?.user_type);
    const [yearValue, setYearValue] = useState<any>(filters?.year);

    const getSelectOptionsOrder = async (tableName: string, fieldName: string) => {
        const response = await commonService.getSelectOptionsListOrder(
            tableName,
            fieldName
        );
        return response.data.data;
    };
    const [query, setQuery] = useState("");
    const getDependentSelectOptions = async (
        tableName: string,
        fieldName: string,
        whereField: string,
        whereID: number
    ) => {
        const response = await commonService.getDependentSelectOptionsList(
            tableName,
            fieldName,
            whereField,
            whereID,
            query ? query : ""
        );
        return response.data.data;
    };
    const getDependentMultipleWhereSelectOptions = async (
        tableName: string,
        fieldName: string,
        whereFields: any,
        whereIds: any
    ) => {
        const response = await commonService.getDependentMultipleWhereSelectOptionsList(
            tableName,
            fieldName,
            whereFields,
            whereIds,
            query ? query : ""
        );
        return response.data.data;
    };


    useEffect(() => {

        getSelectOptionsOrder("tbl_master_user_status", "user_status_value").then(function (
            options
        ) {
            setuserStatusOption(options);
            setloadingUserStatus(false);
        });

        getDependentMultipleWhereSelectOptions(
            "tbl_master_questionnaire_type",
            "questionnaire_type",
            ["year"],
            [filters.year]
        ).then(function (options) {
            setQuestionnaireTypeOption(options);
            setLoadingQuestionnaireType(false);
        });

    }, [])


    useEffect(() => {
        if (filters.user_type && filters.year) {
            setQuestionnaireTypeOption([]);
            setLoadingQuestionnaireType(true);
            getDependentMultipleWhereSelectOptions(
                "tbl_master_questionnaire_type",
                "questionnaire_type",
                ["user_type", "year"],
                [filters.user_type, filters.year]
            ).then(function (options) {
                let opt: any = [];
                opt.push(
                    {
                        value: 0,
                        label: t("Please select...")
                    },
                    ...options
                );
                setQuestionnaireTypeOption(opt);
                setLoadingQuestionnaireType(false);
            });
        } else if (filters.year) {
            setQuestionnaireTypeOption([]);
            setLoadingQuestionnaireType(true);
            getDependentMultipleWhereSelectOptions(
                "tbl_master_questionnaire_type",
                "questionnaire_type",
                ["year"],
                [filters.year]
            ).then(function (options) {
                let opt: any = [];
                opt.push(
                    {
                        value: 0,
                        label: t("Please select...")
                    },
                    ...options
                );
                setQuestionnaireTypeOption(opt);
                setLoadingQuestionnaireType(false);
            });
        }
    }, [filters?.year, filters?.user_type]);



    const [yearList, setYearList] = useState<any>([]);
    const [userStatusList, setUserStatusList] = useState<any>([]);
  
    useEffect(() => {
  
      if (userStatusOption) {
        const options = userStatusOption?.map((option: any) => ({
          label: t(option.label),
          value: option.value
        }));
        setUserStatusList(options);
      }
  
      if (yearData) {
        const options = yearData?.map((option: any) => ({
          label: t(option.label),
          value: option.value
        }));
        setYearList(options);
      }
  
    }, [localStorage.getItem("I18N_LANGUAGE"), userStatusOption])

    return (
        <React.Fragment>
            <Row>
                <Col xl={filter_type == "list" ? "2" : "6"}>
                    <div className="mb-3">
                        <Label className="form-label">
                        {t("User type")}{" "}
                        </Label>
                        <Select
                            id="user_type"
                            name="user_type"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={userStatusList}
                            //@ts-ignore
                            value={userStatusList?.find((o: any) => o.value === filters?.user_type)}
                            isSearchable={true}
                            isDisabled={loadingUserStatus}
                            onChange={(option: any) => {
                                onChange({ user_type: option.value })
                            }}
                        />

                    </div>
                </Col>
                <Col xl={filter_type == "list" ? "2" : "6"}>
                    <div className="mb-3">
                        <Label className="form-label">
                        {t("Year")}{" "}
                        </Label>
                        <Select
                            id="questionnaire_year"
                            name="year"
                            className="is-invalid"
                            options={yearList}
                            placeholder={t("Select...")}
                            value={yearList?.find((opt: any) => opt.value === filters?.year)}
                            isSearchable={true}
                            onChange={(option: any) => {
                                onChange({ year: option.value, questionnaire_type_id: 0 })
                            }}
                        />
                    </div>
                </Col>
                <Col xl={filter_type == "list" ? "4" : "12"}>
                    <div className="mb-3">
                        <Label className="form-label">
                        {t("Questionnaire type")}{" "}
                        </Label>
                        <Select
                            id="questionnaire_type_id"
                            name="questionnaire_type_id"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={questionnaireTypeOption}
                            value={questionnaireTypeOption.find((opt: any) => opt.value === filters?.questionnaire_type_id)}
                            isSearchable={true}
                            isDisabled={loadingQuestionnaireType}
                            onChange={(option: any) => {
                                onChange({ questionnaire_type_id: option.value })
                            }}
                        />
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default Filters