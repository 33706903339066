import { memo } from 'react';
import { ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { MaterialIcon, Button } from './styles';
import './style.scss';
const MailReadButton = (props: any) => {
    return (
        <div className='row'>
            <div className='col-8'>
                <Button size='sm' className='btn-email me-2' color="light" onClick={() => props.handleOnMailCompose('reply')}>
                    <MaterialIcon color="#a857b4">reply</MaterialIcon>
                    {" "}<span>Reply</span>
                </Button>
                <Button size='sm' className='btn-email me-2' color="light" onClick={() => props.handleOnMailCompose('reply_all')}>
                    <MaterialIcon color="#a857b4">reply_all</MaterialIcon>
                    {" "}<span>Reply All</span>
                </Button>
                <Button size='sm' color="light" className='btn-email me-2' onClick={() => props.handleOnMailCompose('forward')}>
                    <MaterialIcon>forward</MaterialIcon>{" "}Forward
                </Button>
               
                <Button size='sm' color="light" onClick={() => props.handleCreateTask()} className='btn-email me-2'>
                    <MaterialIcon>task</MaterialIcon>{" "}Create Task
                </Button>
                <Button size='sm' color="light" className='btn-email me-2' onClick={()=>props.handleDeleteMail()}>
                    <MaterialIcon>delete</MaterialIcon>{" "}Delete
                </Button>
            </div>
        </div>
    )
}

export default memo(MailReadButton); 