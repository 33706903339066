import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const CreateTask = (props: any) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Link to={`/tasks/new-task`}>
        <button
          type="button"
          className={`btn header-item noti-icon ${window.location.pathname === "/tasks/new-task" ? "header-icon-active" : ""}`}
          title={t("Create task")}
        >
          <span className="align-middle material-symbols-outlined fw-light">
            task
          </span>
        </button>
      </Link>
    </React.Fragment>
  );
};

export default CreateTask;
