import React, { useEffect, useState, useMemo, FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
    Badge,
    Label,
    Modal,
    ModalBody,
    FormGroup,
} from "reactstrap";

import { toast } from 'react-toastify';
import { Buffer } from "buffer";

// Actions
import { InvoicesAction } from "../../store/actions";

// Import DataTable
import DataTable from "../../components/Common/DataTable";

import { getDataTableQuery, getUserProfile, handleFilters } from "../../helpers/functions_helper";
import { invoicesList } from "./data";
import { formatCurrency, dateFormat } from "../../utils/formats";
import moment from "moment";
import "./style.scss";
import invoicesService from "../../services/invoices.service";
import Filters from "./Filters";
import KebabMenu from "../../components/KebabMenu";
import PaymentForm from "./PaymentForm";
import Drawer from "../../components/Drawer";
import PaymentHistory from "./PaymentHistory";
import MultiComments from "../../components/Common/MultiComments";
import RefundForm from "./RefundForm";
import CancelInvoice from "./CancelInvoice";
import Reminders from "./Reminders";
import EditCompany from "../Masters/Company/EditCompany";
import EditUser from "../Users/EditUser";
import fileDownload from "js-file-download";
import Input from "../../components/Form/Input";
import { useTranslation, I18nContext } from "react-i18next";
import Dropzone from "react-dropzone";
import DisputeForm from "./Disputes/Form";
import DisputeList from "./Disputes/View";
import ReminderHistory from "./ReminderHistory";
import { renderRowHTML } from "../../utils/utils";
import { languageCodes, statusBadge } from "../../common/staticdata";
import ViewInvoice from "./ViewInvoice";

//Mail Action
import { setDraftEmailData } from "../../store/mails/actions";
import { draftmailProps } from "../Email/components/type";
import { useIsAuthenticated } from "@azure/msal-react";
import Select from "../../components/Form/Select/Select";
import i18next from "i18next";
import Button from "../../components/Button/Button";
import mastersCompanyService from "../../services/masters.company.service";
import axios from "axios";

const breadcrumbItems = [
    {
        url: "/dashboard",
        label: "Dashboard",
        active: false,
    },
    {
        url: "#",
        label: "Invoices",
        active: true,
    },
];

type InvoiceListProps = {
    company_id?: any;
    user_id?: any;
    task_id?: any;
    type?: any;
    invoice_id?: number;
}


const InvoiceList: FC<InvoiceListProps> = ({ company_id = 0, user_id = 0, task_id = 0, type = 'all', invoice_id = 0 }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation() as any;

    const periodOptions = [
        { value: 1, label: '1 - January' },
        { value: 2, label: '2 - February' },
        { value: 3, label: '3 - March' },
        { value: 4, label: '4 - April' },
        { value: 5, label: '5 - May' },
        { value: 6, label: '6 - June' },
        { value: 7, label: '7 - July' },
        { value: 8, label: '8 - August' },
        { value: 9, label: '9 - September' },
        { value: 10, label: '10 - October' },
        { value: 11, label: '11 - November' },
        { value: 12, label: '12 - December' },
    ];

    const savedfilters = handleFilters("GET");
    const [searchParams] = useSearchParams();

    const {
        datatableData,
    } = useSelector((state: any) => ({
        datatableData: state.Invoices.INVOICES_LIST,
    }));

    const [loader, setLoader] = useState(true);
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [openPaymentForm, setOpenPaymentForm] = useState<boolean>(false);
    const [openPaymentHistory, setOpenPaymentHistory] = useState<boolean>(false);
    const [openReminderHistory, setOpenReminderHistory] = useState<boolean>(false);
    const [openComment, setOpenComment] = useState<boolean>(false);
    const [openCancel, setOpenCancel] = useState<boolean>(false);
    const [openRefund, setOpenRefund] = useState<boolean>(false);
    const [openCompany, setOpenCompany] = useState<boolean>(false);
    const [openUser, setOpenUser] = useState<boolean>(false);
    const [openAct, setOpenAct] = useState<boolean>(false);
    const [openActUpload, setOpenActUpload] = useState<boolean>(false);
    const [openCSV, setOpenCSV] = useState<boolean>(false);
    const [openCsf, setOpenCsf] = useState<boolean>(false);
    const [openDisputeForm, setOpenDisputeForm] = useState<boolean>(false);
    const [openDisputes, setOpenDisputes] = useState<boolean>(false);
    const [openInvoice, setOpenInvoice] = useState<boolean>(false);
    const [downloading, setDownloading] = useState<boolean>(false);
    const [actParams, setActParams] = useState<any>({
        invoice_journal: 'VEADMI',
        credit_note_journal: 'NCADMI',
        book_year: new Date().getFullYear() - 2019,
        period: null,
        customer_ledger_account: 400000,
        sales_ledger_account: 700000,
        vat_ledger_account: 451000,
        sales_discount_ledger_account: 499000

    })


    const [loadingListData, setLoadingListData] = useState(true);
    const [tasksListData, setTasksListData] = useState<Array<any>>([]);
    const [datatableOptions, setDatatableOptions] = useState((savedfilters && savedfilters?.datatableOptions && !(company_id || user_id || task_id))
        ? (savedfilters?.datatableOptions as any)
        : {
            draw: 1,
            page: 1,
            recordsTotal: 0,
            recordsFiltered: 0,
            sizePerPage: 20,
            searchText: "",
            sortField: "id",
            sortOrder: "desc",
        });

    const [filters, setFilters] = useState<invoiceFilters>(savedfilters?.filters && !(company_id || user_id || task_id) ? savedfilters?.filters : {
        is_filtered: false,
        company_id: company_id,
        invoice_id: invoice_id,
        contact_person_id: user_id,
        task_id: task_id,
        invoice_date_from: "",
        invoice_date_to: "",
        status: "",
        payment_status: "",
        dispute_status: "",
        is_dispute: false,
        partner: null
    });

    const [selectedInvoice, setSelectedInvoice] = useState<any>();
    const [actCount, setActCount] = useState<number>(0);
    const [loadingCsf, setLoadingCsf] = useState<boolean>(false);
    const [cancelledAmount, setCancelledAmount] = useState<any>({
        total: 0,
        amount_due: 0,
        tax_excluded: 0,
        tax: 0,
        expenses: 0
    });

    const isAzureAuthenticated = useIsAuthenticated();
    // UserProfile ---------------------------------------------

    const toastId = React.useRef(null) as any;


    const userObj = getUserProfile('all-invoices');

    // Download attachment ------------------------------------------------------------
    const onClickDownload = (row: any) => {
        const SERVER_URL = process.env.REACT_APP_SERVER_URL;
        const AUTH_USER = localStorage.getItem("authUser");
        const ACCESS_TOKEN = JSON.parse(String(AUTH_USER));
        axios({
            url:
                SERVER_URL +
                "/common/download-attachment/" +
                Buffer.from(String(row.id)).toString("base64") +
                "/" +
                Buffer.from(String(row.attachment_id)).toString("base64"),
            method: "GET",
            headers: {
                "Content-Type": "application/" + row.file_extension,
                "Access-Control-Allow-Origin": "*",
                Authorization: "Bearer " + ACCESS_TOKEN.accessToken,
            },
            responseType: "blob",
        }).then((response: any) => {
            fileDownload(response.data, row.file_name + "." + row.file_extension);
        });
    };
    // Download attachment ------------------------------------------------------------

    const handleReceivePayment = (invoice: any) => {
        setSelectedInvoice(invoice)
        setOpenPaymentForm(true);
    }

    const handleComment = (invoice: any) => {
        setSelectedInvoice(invoice)
        setOpenComment(true);
    }

    const handleCancelled = (invoice: any) => {
        setSelectedInvoice(invoice)
        setOpenCancel(true);
    }

    const handleRefund = (invoice: any) => {
        setSelectedInvoice(invoice)
        setOpenRefund(true);
    }

    const handlePaymentHistory = (invoice: any) => {
        setSelectedInvoice(invoice)
        setOpenPaymentHistory(true);
    }

    const handleReminderHistory = (invoice: any) => {
        setSelectedInvoice(invoice)
        setOpenReminderHistory(true);
    }

    const handleCompanyClick = (invoice: any) => {
        setSelectedInvoice(invoice)
        setOpenCompany(true);
    }

    const handleUserClick = (invoice: any) => {
        setSelectedInvoice(invoice)
        setOpenUser(true);
    }

    const handleDisputeForm = (invoice: any) => {
        setSelectedInvoice(invoice);
        setOpenDisputeForm(true);
    }

    const handleDisputeView = (invoice: any) => {
        setSelectedInvoice(invoice);
        setOpenDisputes(true);
    }

    const handleOpenInvoice = (invoice: any) => {
        setSelectedInvoice(invoice);
        setOpenInvoice(true);
    }

    const getCsfCount = async () => {
        const res = await mastersCompanyService.getCsfCount();
        return res.data.data
    };

    const handleDownload = async (invoice: any) => {
        const id = invoice?.id as number;
        let msg = t("Downloading an invoice...");
        let msgSuccess = t("Invoice downloaded successfully");
        let filename = 'invoice-' + invoice.invoice_number + '.pdf';
        if (invoice.invoice_type === 'CREDIT_MEMO') {
            msg = t("Downloading a credit note...");
            msgSuccess = t("Credit note downloaded successfully");
            filename = 'credit-note-' + invoice.invoice_number + '.pdf';
        }

        toastId.current = toast(msg, {
            autoClose: false,
            isLoading: true,
        });
        // window.open(process.env.REACT_APP_SERVER_URL + `/invoices/download/${invoice.id}`, '_blank')
        const res = await invoicesService.downloadInvoice(invoice?.id);
        if (res.status === 200) {
            fileDownload(res.data, filename);
            toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: msgSuccess, autoClose: 5000, isLoading: false, delay: 500 });
        } else {
            toast.update(toastId.current, { type: toast.TYPE.ERROR, render: t('Sorry, something went wrong!'), autoClose: 5000, isLoading: false, delay: 500 });
        }
    }

    const downloadAct = async () => {
        const csf = await getCsfCount();
        // alert(csf)
        if (csf > 0) {
            setOpenCsf(true);
        } else {
            setOpenAct(true);
        }
        // window.open(process.env.REACT_APP_SERVER_URL + `/invoices/export-act`, '_blank')

    }

    const uploadCsv = async () => {
        setOpenCSV(true);
    }

    const handleCsvUpload = async (selectedFiles: any) => {
        const files = Array.from(selectedFiles);

        if (files.length) {

            toastId.current = toast(t("Uploading csv file..."), {
                autoClose: false,
                isLoading: true,
            });

            const formData = new FormData();
            formData.append('uploaded_file', selectedFiles[0])
            const headers = {
                'content-type': 'multipart/form-data'
            }
            const res = await invoicesService.uploadCsv(formData, Headers);
            if (res.status === "success") {
                toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: res.message, autoClose: 5000, isLoading: false, delay: 500 });
                setOpenCSV(false);
                setLoadingListData(true);
            } else {
                toast.update(toastId.current, { type: toast.TYPE.ERROR, render: res.message, autoClose: 5000, isLoading: false, delay: 500 });
            }
        } else {

        }
    }

    const uploadAct = async () => {
        setOpenActUpload(true);
    }

    const handleActUpload = async (selectedFiles: any) => {
        const files = Array.from(selectedFiles);

        if (files.length) {

            toastId.current = toast(t("Uploading ACT file..."), {
                autoClose: false,
                isLoading: true,
            });

            const formData = new FormData();
            formData.append('uploaded_file', selectedFiles[0])
            const headers = {
                'content-type': 'multipart/form-data'
            }
            const res = await invoicesService.uploadAct(formData, Headers);
            if (res.status === "success") {
                toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: res.message, autoClose: 5000, isLoading: false, delay: 500 });
                // console.log("res", res);
                setLoadingListData(true);
                // setOpenActUpload(false);
            } else {
                toast.update(toastId.current, { type: toast.TYPE.ERROR, render: res.message, autoClose: 5000, isLoading: false, delay: 500 });
            }
            setOpenActUpload(false);
        } else {

        }
    }

    const handleDownloadCsf = async () => {
        setLoadingCsf(true);
        toastId.current = toast(t("Downloading csf.csv file..."), {
            autoClose: false,
            isLoading: true,
        });

        const res = await mastersCompanyService.downloadCsf();
        setLoadingCsf(false);

        if (res.status === 200) {
            fileDownload(res?.data, "csf.csv");
            toast.update(toastId.current, {
                type: toast.TYPE.SUCCESS,
                render: t("Csf.csv file is sucessfully downloaded"),
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });

        } else {
            toast.update(toastId.current, {
                type: toast.TYPE.ERROR,
                render: t("No company or user to export"),
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });
        }
        setOpenCsf(false);
        setOpenAct(true);
    };


    const handleActDownload = async () => {
        setDownloading(true);
        const res = await invoicesService.downloadAct(actParams);
        if (res) {
            fileDownload(res, 'ACT.txt');
            setLoadingListData(true);
            setDownloading(false);
            setOpenAct(false);
            toast(t('ACT.txt is successfuly downloaded'), { type: toast.TYPE.SUCCESS })
        } else {
            toast(t('No invoice available to export in ACT.txt'), { type: toast.TYPE.ERROR })
            setDownloading(false);
        }
    }

    const handleParamsChange = (e: any) => {
        setActParams((params: any) => ({ ...params, [e.target.name]: e.target.value }))
    }

    const handleSend = async (invoice: InvoiceType) => {
        const id = invoice?.id as number;
        let msg = t("Sending an invoice...");
        if (invoice.invoice_type === 'CREDIT_MEMO') {
            msg = t("Sending a credit note...");
        }
        toast.dismiss();
        toastId.current = toast(msg, { autoClose: false, isLoading: true });
        try {
            const res = await invoicesService.sendInvoice(id);
            if (res.status === "success") {
                toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: res.message, autoClose: 5000, isLoading: false, delay: 500 });
                // toast.success("Success Notification !");
                setLoadingListData(true);
            } else {
                toast.update(toastId.current, { type: toast.TYPE.ERROR, render: res.message, autoClose: 5000, isLoading: false, delay: 500 });
            }
        } catch (err: any) {
            toast.update(toastId.current, { type: toast.TYPE.ERROR, render: err, autoClose: 5000, isLoading: false, delay: 500 });
        }
    };

    const handleCheck = async (id: number) => {
        const res = (await invoicesService.uncheckInvoice(id)) as any;
        if (res.status === "success") {
            setLoadingListData(true);
        }
    };

    const handleAct = async (id: number) => {
        const res = (await invoicesService.addToACT(id)) as any;
        if (res.status === "success") {
            setLoadingListData(true);
        }
    };

    const handleFiltersChange = (obj: any) => {
        // alert(JSON.stringify(obj));
        setFilters((state) => ({ ...state, ...obj }));
    };

    const handleApplyFilters = () => {
        setLoadingListData(true);
        setOpenFilter(false);
    };

    // UserRoles ---------------------------------------------

    // DataTable ----------------------------------------------
    const columns = useMemo(() => [
        {
            dataField: "id",
            text: "ID",
            sort: true,
            hidden: true
        },
        {
            dataField: "invoice_number",
            text: type === "creditnote" ? t("Credit Note Number") : t("Invoice Number"),
            sort: true,
            search: true,
            footer: "Total",
            // classes: 'fix-col-left',
            // headerClasses: 'fix-col-left',
            headerStyle: { width: '155px' },
            style: { width: '155px' },
            formatter: (rowContent: any, row: any): any => {
                return (
                    <span>
                        {row.invoice_type === "CREDIT_MEMO" ? (
                            <>
                                <Link to={"/invoices-management/view-credit-note/" + row.id}>
                                    {renderRowHTML(row.invoice_number, null)}
                                </Link>
                                {row.dispute.length > 0 && (<Badge onClick={() => handleDisputeView(row)} color="warning">{t("Dispute")}</Badge>)}
                                {row?.cn_invoice &&
                                    <p>
                                        <Label>{t('Invoice')}: &nbsp;</Label>
                                        <Link to={"#"} onClick={() => handleOpenInvoice(row?.cn_invoice)}>
                                            {row?.cn_invoice?.invoice_number}
                                        </Link>
                                    </p>
                                }
                            </>
                        ) : (
                            <>
                                <Link to={"/invoices-management/view-invoice/" + row.id + "/" + type}>
                                    {renderRowHTML(row.invoice_number, null)}
                                </Link>
                                {row.dispute.length > 0 && (<> &nbsp;<Badge onClick={() => handleDisputeView(row)} color="danger">Dispute</Badge></>)}
                                <div className="font-size-11">
                                    {row?.credit_notes?.length > 0 && row?.credit_notes?.map((credit_note: any, i: number) => (
                                        <i key={i}>
                                            <Label>{t('Credit Note')} #{i + 1}: &nbsp;</Label>
                                            <Link to={"#"} onClick={() => handleOpenInvoice(credit_note)}>
                                                {credit_note?.invoice_number}
                                            </Link>
                                            {(i < row?.credit_notes?.length - 1) ? <br /> : null}
                                        </i>
                                    ))}
                                </div>
                                {/* 
                                {row?.credit_note_details &&
                                    <p>
                                        <Label>{t('Credit Note')}: &nbsp;</Label>
                                        <Link to={"/invoices-management/view-credit-note/" + row?.credit_note_details?.id}>
                                            {row?.credit_note_details?.invoice_number}
                                        </Link>
                                    </p>
                                } */}
                            </>
                        )}
                    </span>
                );
            },
        },
        {
            dataField: "company_name",
            text: t("Client"),
            footer: "",
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => {
                return (
                    <>
                        {
                            row?.company_id === 0 ? (
                                <div className="link-primary" onClick={() => handleUserClick(row)}>
                                    {row.company_name}
                                </div>
                            ) :
                                (<div className="link-primary" onClick={() => handleCompanyClick(row)}>
                                    {row.company_name}
                                </div>)
                        }
                    </>
                );
            },
            // classes: 'fix-col-left',
            // headerClasses: 'fix-col-left',
            style: { left: '150px' },
            headerStyle: { left: '150px' }

        },
        {
            dataField: "project.name",
            text: t("Project"),
            sort: true,
            search: true,
            footer: "",
        },
        {
            dataField: "invoice_date",
            text: type === 'creditnote' ? t("Credit note date") : t("Invoice Date"),
            sort: true,
            search: true,
            footer: "",
            formatter: (rowContent: any, row: any): any => {
                return row.status === 'Sent' ? dateFormat(row.invoice_date) : '';
            },
        },
        {
            dataField: "due_date",
            text: t("Due Date"),
            sort: true,
            search: true,
            footer: "",
            formatter: (rowContent: any, row: any): any => {
                return row.status === 'Sent' ? dateFormat(row.due_date) : '';
            },
            hidden: type === 'creditnote'
        },
        {
            dataField: "reference",
            text: t("Reference"),
            sort: true,
            search: true,
            footer: "",
        },
        {
            dataField: "paid_date",
            text: t("Paid Date"),
            sort: true,
            search: true,
            footer: "",
            formatter: (rowContent: any, row: any): any => {
                return row.paid_date ? dateFormat(row.paid_date) : '';
            },
        },
        {
            dataField: "contact_person",
            text: t("Contact person"),
            sort: true,
            search: true,
            footer: "",
            formatter: (rowContent: any, row: any): any => {
                return (
                    <div className="link-primary" onClick={() => handleUserClick(row)}>
                        {row.contact_person}
                    </div>
                );
            },
        },
        {
            dataField: "next_activity",
            text: t("Next Activity"),
            sort: true,
            search: true,
            footer: "",
            formatter: (rowContent: any, row: any): any => {
                return t(row.next_activity);
            },
        },
        {
            dataField: "tax_excluded",
            text: t("Tax Excluded"),
            sort: true,
            search: true,
            classes: 'right-align',
            headerClasses: 'right-align',
            footer: (columnData: any) =>
                formatCurrency(
                    columnData.reduce(
                        (tax_excluded: number, acc: number) => acc + tax_excluded,
                        0
                    ) - cancelledAmount.tax_excluded
                ),
            footerAlign: (column: any, colIndex: number) => "right",
            formatter: (rowContent: any, row: any): any => {
                return formatCurrency(row.tax_excluded);
            },
        },
        {
            dataField: "tax_amount",
            text: t("Tax"),
            sort: true,
            search: true,
            classes: 'right-align',
            headerClasses: 'right-align',
            footer: (columnData: any) =>
                formatCurrency(
                    columnData.reduce(
                        (tax_amount: number, acc: number) => acc + tax_amount,
                        0
                    ) - cancelledAmount.tax_amount
                ),
            footerAlign: (column: any, colIndex: number) => "right",
            formatter: (rowContent: any, row: any): any => {
                return formatCurrency(row.tax_amount);
            },
            hidden: type === 'creditnote'
        },
        {
            dataField: "expenses",
            text: t("Expenses"),
            sort: true,
            search: true,
            classes: 'right-align',
            headerClasses: 'right-align',
            footer: (columnData: any) =>
                formatCurrency(
                    columnData.reduce(
                        (expenses: number, acc: number) => acc + expenses,
                        0
                    ) - cancelledAmount.expenses
                ),
            footerAlign: (column: any, colIndex: number) => "right",
            formatter: (rowContent: any, row: any): any => {
                return formatCurrency(row.expenses);
            },
            hidden: type === 'creditnote'
        },
        {
            dataField: "total",
            text: t("Total"),
            sort: true,
            search: true,
            classes: 'right-align',
            headerClasses: 'right-align',
            footer: (columnData: any) =>
                formatCurrency(
                    columnData.reduce(
                        (total: number, acc: number) => acc + total,
                        0
                    ) - cancelledAmount.total
                ),
            footerAlign: (column: any, colIndex: number) => "right",
            formatter: (rowContent: any, row: any): any => {
                return formatCurrency(row.total);
            },
            hidden: type === 'creditnote'
        },
        {
            dataField: "credit_notes",
            text: t("Credit note amount"),
            sort: false,
            search: false,
            footer: "",
            classes: 'rightAlign',
            formatter: (rowContent: any, row: any): any => {
                return row.invoice_type === "CREDIT_MEMO" ? formatCurrency(row?.credit_note_amount) : formatCurrency(row?.credit_notes?.reduce(function (acc: any, obj: any) { return acc + obj.credit_note_amount; }, 0));
            },
        },
        {
            dataField: "paid_amount",
            text: t("Paid amount"),
            sort: true,
            search: true,
            classes: 'right-align',
            headerClasses: 'right-align',
            footer: (columnData: any) =>
                formatCurrency(
                    columnData.reduce(
                        (paid_amount: number, acc: number) => acc + paid_amount,
                        0
                    )
                ),
            footerAlign: (column: any, colIndex: number) => "right",
            formatter: (rowContent: any, row: any): any => {
                return formatCurrency(row.paid_amount);
            },
            hidden: type === 'creditnote'
        },
        {
            dataField: "amount_due",
            text: t("Amount Due"),
            sort: true,
            search: true,
            classes: 'right-align',
            headerClasses: 'right-align',
            formatter: (rowContent: any, row: any): any => {
                return formatCurrency(row.final_amount_due);
            },
            footer: (columnData: any) =>
                formatCurrency(
                    columnData.reduce(
                        (final_amount_due: number, acc: number) => acc + final_amount_due,
                        0
                    ) - cancelledAmount.amount_due
                ),
            footerAlign: (column: any, colIndex: number) => "right",
            hidden: type === 'creditnote'
        },
        {
            dataField: "to_check",
            text: t("To Check"),
            sort: true,
            search: true,
            footer: "",
            formatter: (rowContent: any, row: any): any => {
                const color =
                    statusBadge.find((i: any) => i.status === row.payment_status)
                        ?.color || "primary";
                return (
                    <FormGroup switch inline className="form-check-info">
                        <Input
                            type="switch"
                            checked={row?.to_check}
                            onChange={() => handleCheck(row.id)}
                            label={row?.to_check ? t('Yes') : t('No')}
                        />
                    </FormGroup>
                );
            },
        },
        {
            dataField: "payment_status",
            text: t("Payment status"),
            sort: true,
            search: true,
            footer: "",
            formatter: (rowContent: any, row: any): any => {
                const color =
                    statusBadge.find((i: any) => i.status === row.payment_status)
                        ?.color || "primary";
                return (
                    <h5>
                        <Badge color={color} pill className={`badge_custome  badge-soft-${color}`}>
                            {t(row.payment_status)}
                        </Badge>
                    </h5>
                );
            },
            hidden: type === 'creditnote'
        },
        {
            dataField: "status",
            text: t("Status"),
            sort: true,
            search: true,
            footer: "",
            formatter: (rowContent: any, row: any): any => {
                const color =
                    statusBadge.find((i: any) => i.status === row.status)?.color ||
                    "primary";
                return (
                    <h5>
                        <Badge color={color} pill className={`badge_custome  badge-soft-${color}`}>
                            {t(row.status)}
                        </Badge>
                    </h5>
                );
            },
        },
        {
            dataField: "reminder",
            text: t("Reminder"),
            sort: false,
            search: false,
            footer: "",
            formatter: (rowContent: any, row: any): any => {
                return (
                    row.status === 'Draft' ? '' : <Reminders {...row} />
                );
            },
            hidden: type === 'creditnote'
        },
        {
            dataField: "account",
            text: t("General Accounts"),
            sort: true,
            search: true,
            footer: "",
        },
        {
            dataField: "is_exported_to_act",
            text: t("Exported to ACT"),
            sort: true,
            search: true,
            footer: "",
            hidden: !(userObj.IS_PARTNER),
            formatter: (rowContent: any, row: any): any => {
                return (
                    <>
                        <FormGroup switch inline className="form-check-success">
                            <Input
                                type="switch"
                                checked={row?.is_exported_to_act}
                                onChange={() => handleAct(row.id)}
                                label={row?.is_exported_to_act ? t('Yes') : t('No')}
                            />
                        </FormGroup>
                    </>
                );
            },
        },
        // {
        //     dataField: "electronic_invoicing",
        //     text: t("Electronic invoicing"),
        //     sort: true,
        //     search: true,
        //     footer: "",
        // },
        {
            dataField: "details.timesheet_code",
            text: t("Time sheet code"),
            sort: false,
            search: true,
            footer: "",
            formatter: (rowContent: any, row: any): any => {
                const tsArr = row?.details
                    ? row.details?.map((d: any) => d.timesheet_code)
                    : [];

                //@ts-ignore
                let uniqueChars = [...new Set(tsArr)];
                return (
                    <div className="d-flex gap-2">
                        {uniqueChars?.map((d: any, indx: number) => (
                            <span key={indx} className="clip">
                                {d}
                            </span>
                        ))}
                    </div>
                );
            },
            hidden: type === 'creditnote'
        },


        {
            dataField: "source_document",
            text: t("Attachments"),
            sort: true,
            search: true,
            footer: "",
            formatter: (rowContent: any, row: any): any => {

                return (
                    <div className="d-flex gap-2">
                        {row?.attachments?.length > 0 && row?.attachments?.map((att: any, i: number) => <Badge onClick={() => onClickDownload(att)} key={i}>{att.file_name} <span className="align-middle material-symbols-outlined fw-light font-size-12 label-icon mx-1">
                            download
                        </span></Badge>)}
                    </div>
                );
            },
        },
        {
            dataField: "created_at",
            text: t("Created on"),
            sort: true,
            search: true,
            footer: "",
            formatter: (rowContent: any, row: any): any => {
                return row.created_at ? dateFormat(row.created_at, { dateStyle: 'long', timeStyle: 'medium' }, languageCodes(i18next.language)) : '';
            },
        },
        {
            dataField: "created_by",
            text: t("Created by"),
            sort: true,
            search: true,
            footer: "",
            formatter: (rowContent: any, row: any): any => {
                return t(row.created_by);
            },
        },
        {
            dataField: "created_from",
            text: t("Created from"),
            sort: true,
            search: true,
            footer: "",
            formatter: (rowContent: any, row: any): any => {

                return (
                    <div className="d-flex gap-2">
                        {row?.created_from?.length > 0 && row?.created_from?.map((att: any, i: number) => <Badge key={i} className="p-1" color="primary">{t(att)}</Badge>)}
                    </div>
                );
            },
        },
        {
            dataField: "updated_at",
            text: t("Updated on"),
            sort: true,
            search: true,
            footer: "",
            formatter: (rowContent: any, row: any): any => {
                return row.updated_at && row.updated_by ? dateFormat(row.updated_at, { dateStyle: 'long', timeStyle: 'medium' }, languageCodes(i18next.language)) : '';
            },
        },
        {
            dataField: "updated_by",
            text: t("Updated by"),
            sort: true,
            search: true,
            footer: "",
            formatter: (rowContent: any, row: any): any => {
                return row.updated_by;
            },
        },
        {
            dataField: "action",
            text: '',
            sort: false,
            search: false,
            csvExport: false,
            width: '60px',
            classes: 'fix-col-right',
            headerClasses: 'fix-col-right',
            footer: "",
            hidden: task_id > 0,
            formatter: (rowContent: any, row: any): any => {
                return (
                    <div className="d-flex gap-2 text-end">
                        {/* {CAN_EDIT ? (
              row.status !== "Sent" ? (
                <Link
                  className="btn btn-pill btn-outline-primary btn-sm"
                  to={"/invoices-management/edit-invoice/" + row.id + ""}
                >
                  <i className="fa-regular fa-pen-to-square"></i> Edit
                </Link>
              ) : (
                <Button color="primary" size="sm" outline disabled>
                  <i className="fa-regular fa-pen-to-square"></i> Edit
                </Button>
              )
            ) : (
              ""
            )}
            <Button
              color="primary"
              className="btn btn-pill btn-sm"
              onClick={() => handleSend(row.id)}
            >
              <i className="fa-solid fa-paper-plane"></i>{" "}
              {row.status === "Sent" && row.to_check === 1 ? "Sent" : "Send"}
            </Button> */}
                        <KebabMenu menus={
                            type === "creditnote" ?
                                [
                                    // {
                                    //     label: 'Edit',
                                    //     icon: <i className="fa-regular fa-pen-to-square"></i>,
                                    //     onClick: () => navigate("/invoices-management/edit-credit-note/" + row.id + ""),
                                    //     disabled: row.status !== "Draft",
                                    //     hidden: !(userObj.IS_SUPER_ADMIN || userObj.IS_PARTNER)
                                    // },
                                    {
                                        label: row.status === "Draft" ? t('Send') : t('Resend'),
                                        icon: <i className="fa-solid fa-paper-plane"></i>,
                                        onClick: () => handleSend(row),
                                        disabled: row.payment_status === "Refunded" || row.status === "Cancelled",
                                        hidden: !(userObj.IS_SUPER_ADMIN || userObj.IS_PARTNER) && userObj.CAN_EDIT
                                    },
                                    {
                                        label: t('Comments'),
                                        icon: <i className="fa-solid fa-comment"></i>,
                                        onClick: () => handleComment(row),
                                        hidden: !(userObj.IS_PARTNER)
                                    },
                                    {
                                        label: t('Download'),
                                        icon: <i className="fa-solid fa-download"></i>,
                                        onClick: () => handleDownload(row)
                                    },
                                    {
                                        label: t('Email'),
                                        icon: <i className="fa-solid fa-envelope-o"></i>,
                                        onClick: () => composeMail(row),
                                        hidden: (!(userObj.IS_SUPER_ADMIN || userObj.IS_PARTNER) || !isAzureAuthenticated)
                                    }
                                ]
                                :
                                [
                                    {
                                        label: t('Edit'),
                                        icon: <i className="fa-regular fa-pen-to-square"></i>,
                                        onClick: () => navigate("/invoices-management/edit-invoice/" + row.id + ""),
                                        // onClick: () => window.open("/invoices-management/edit-invoice/" + row.id + "", '_self', 'noreferrer'),
                                        disabled: row.status !== "Draft",
                                        hidden: !(userObj.IS_SUPER_ADMIN || userObj.IS_PARTNER || userObj.CAN_EDIT)
                                    },
                                    {
                                        label: row.status === "Draft" ? t('Send') : t('Resend'),
                                        icon: <i className="fa-solid fa-paper-plane"></i>,
                                        onClick: () => handleSend(row),
                                        disabled: row.payment_status === "Refunded" || row.status === "Cancelled",
                                        hidden: !(userObj.IS_SUPER_ADMIN || userObj.IS_PARTNER) && userObj.CAN_EDIT
                                    },
                                    // {
                                    //     label: t('Send Reminder'),
                                    //     icon: <i className="fa-solid fa-paper-plane"></i>,
                                    //     onClick: () => handleSend(row),
                                    //     disabled: (row.payment_status === "Refunded" || row.status === "Cancelled") || row.to_check === 1,
                                    //     hidden: (new Date(row.due_date) > new Date() || row.payment_status === 'Paid') || row.status === 'Draft'

                                    // },
                                    {
                                        label: t('Comments'),
                                        icon: <i className="fa-solid fa-comment"></i>,
                                        onClick: () => handleComment(row),
                                        hidden: !(userObj.IS_PARTNER)
                                    },
                                    {
                                        label: t('Cancel'),
                                        icon: <i className="fa-solid fa-cancel"></i>,
                                        onClick: () => handleCancelled(row),
                                        disabled: row.status !== 'Draft'
                                    },
                                    {
                                        label: t('Refund'),
                                        icon: <i className="fa-solid fa-rotate-left"></i>,
                                        onClick: () => handleRefund(row),
                                        disabled: !(row.payment_status === 'Paid' || row.payment_status === 'Partial Paid') && row.amount_due >= 0
                                    },
                                    {
                                        label: t('Create Credit Note'),
                                        icon: <i className="fa-solid fa-circle-plus"></i>,
                                        onClick: () => navigate("/invoices-management/new-credit-Note/" + row.id + ""),
                                        disabled: row.status !== "Sent",
                                        hidden: row.amount_due <= 0 || !(userObj.IS_SUPER_ADMIN || userObj.IS_PARTNER),
                                    },
                                    {
                                        label: t('Received Payment'),
                                        icon: <i className="fa-solid fa-money-check"></i>,
                                        onClick: () => handleReceivePayment(row),
                                        disabled: row.payment_status === 'Paid' || row.amount_due <= 0 || row.payment_status === "Refunded" || row.status === "Cancelled",
                                        hidden: !(userObj.IS_SUPER_ADMIN || userObj.IS_PARTNER) || row.status === 'Draft'
                                    },
                                    {
                                        label: t('Transaction History'),
                                        icon: <span className="align-middle material-symbols-outlined fw-light fs-4">visibility</span>,
                                        onClick: () => handlePaymentHistory(row),
                                        disabled: row.payment_status === 'Not Paid' || row.status === "Cancelled",
                                    },
                                    {
                                        label: t('Reminder History'),
                                        icon: <span className="align-middle material-symbols-outlined fw-light fs-4">calendar_today</span>,
                                        onClick: () => handleReminderHistory(row),
                                        hidden: row.status !== 'Sent',
                                    },
                                    {
                                        label: t('Download'),
                                        icon: <i className="fa-solid fa-download"></i>,
                                        onClick: () => handleDownload(row)
                                    },
                                    // {
                                    //     label: 'Raise Dispute',
                                    //     icon: <i className="fa-solid fa-circle-exclamation"></i>,
                                    //     onClick: () => handleDisputeForm(row),
                                    //     hidden: row.status === 'Draft'
                                    // },
                                    {
                                        label: t('Disputes'),
                                        icon: <i className="fa-solid fa-circle-exclamation"></i>,
                                        onClick: () => handleDisputeView(row),
                                        hidden: row.status === 'Draft' || !(userObj.IS_SUPER_ADMIN || userObj.IS_PARTNER)
                                    },
                                    {
                                        label: t('Email'),
                                        icon: <i className="fa-solid fa-envelope-o"></i>,
                                        onClick: () => composeMail(row),
                                        hidden: (!(userObj.IS_SUPER_ADMIN || userObj.IS_PARTNER) || !isAzureAuthenticated)

                                    }
                                ]
                        } />
                    </div>
                );
            },
        },
    ], [type, cancelledAmount, t]);

    const publicColumns = useMemo(() => [
        {
            dataField: "invoice_number",
            text: type === "creditnote" ? t("Credit Note Number") : t("Invoice Number"),
            sort: true,
            search: true,
            classes: 'fix-col-left',
            headerClasses: 'fix-col-left',
            headerStyle: { width: '155px' },
            style: { width: '155px' },
            formatter: (rowContent: any, row: any): any => {
                return (
                    <>
                        {row.invoice_type === "CREDIT_MEMO" ? (
                            <>
                                <Link to={"/invoices-management/view-credit-note/" + row.id}>
                                    {renderRowHTML(row.invoice_number, null)}
                                </Link>
                                {row.dispute.length > 0 && (<Badge onClick={() => handleDisputeView(row)} color="warning">{t("Dispute")}</Badge>)}
                                {row?.cn_invoice &&
                                    <p>
                                        <Label>{t('Invoice')}: &nbsp;</Label>
                                        <Link to={"/invoices-management/view-invoice/" + row?.cn_invoice?.id + "/" + type}>
                                            {row?.cn_invoice?.invoice_number}
                                        </Link>
                                    </p>
                                }
                            </>
                        ) : (
                            <>
                                <Link to={"/invoices-management/view-invoice/" + row.id + "/" + type}>
                                    {renderRowHTML(row.invoice_number, null)}
                                </Link>
                                {row.dispute.length > 0 && (<> &nbsp;<Badge onClick={() => handleDisputeView(row)} color="danger">{t("Dispute")}</Badge></>)}
                                {(row?.credit_note_details && row?.credit_note_details?.status === 'Sent') &&
                                    <p>
                                        <Label>{t('Credit Note')}: &nbsp;</Label>
                                        <Link to={"/invoices-management/view-credit-note/" + row?.credit_note_details?.id}>
                                            {row?.credit_note_details?.invoice_number}
                                        </Link>
                                    </p>
                                }
                            </>
                        )}
                    </>
                );
            },
        },
        {
            dataField: "invoice_date",
            text: type === 'creditnote' ? t("Credit note date") : t("Invoice Date"),
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => {
                return dateFormat(row.invoice_date);
            },
        },
        {
            dataField: "due_date",
            text: t("Due Date"),
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => {
                return dateFormat(row.due_date);
            },
        },
        {
            dataField: "reference",
            text: t("Reference"),
            sort: true,
            search: true,
        },
        {
            dataField: "total",
            text: t("Total"),
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => {
                return formatCurrency(row.total);
            },
        },
        {
            dataField: "credit_notes",
            text: t("Credit note amount"),
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => {
                return row.invoice_type === "CREDIT_MEMO" ? formatCurrency(row?.credit_note_amount) : formatCurrency(row?.credit_notes?.reduce(function (acc: any, obj: any) { return acc + obj.credit_note_amount; }, 0));
            },
        },
        {
            dataField: "paid_amount",
            text: t("Paid amount"),
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => {
                return formatCurrency(row.paid_amount);
            },
        },
        {
            dataField: "amount_due",
            text: t("Amount Due"),
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => {
                return formatCurrency(row.final_amount_due);
            },
        },
        {
            dataField: "payment_status",
            text: t("Payment status"),
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => {
                const color =
                    statusBadge.find((i: any) => i.status === row.payment_status)
                        ?.color || "primary";
                return (
                    <h5>
                        <Badge color={color} pill className={`badge_custome  badge-soft-${color}`}>
                            {t(row.payment_status)}
                        </Badge>
                    </h5>
                );
            },
        },
        // {
        //     dataField: "status",
        //     text: "Status",
        //     sort: true,
        //     search: true,
        //     formatter: (rowContent: any, row: any): any => {
        //         const color =
        //             statusBadge.find((i: any) => i.status === row.status)?.color ||
        //             "primary";
        //         return (
        //             <h5>
        //                 <Badge color={color} pill className={`badge_custome  badge-soft-${color}`}>
        //                     {row.status}
        //                 </Badge>
        //             </h5>
        //         );
        //     },
        // },
        {
            dataField: "source_document",
            text: t("Attachments"),
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => {
                return (
                    <div className="d-flex gap-2">
                        {row?.attachments?.length > 0 && row?.attachments?.map((att: any, i: number) => <Badge onClick={() => onClickDownload(att)} key={i}>{att.file_name} <span className="align-middle material-symbols-outlined fw-light font-size-12 label-icon mx-1">
                            download
                        </span></Badge>)}
                    </div>
                );
            },
        },
        {
            dataField: "action",
            text: <i className="fa-solid fa-ellipsis-vertical m-0 text-muted font-size-18"></i>,
            sort: false,
            search: false,
            width: '60px',
            classes: 'fix-col-right',
            headerClasses: 'fix-col-right',
            formatter: (rowContent: any, row: any): any => {
                return (
                    <div className="d-flex gap-2 text-end">
                        {/* {CAN_EDIT ? (
              row.status !== "Sent" ? (
                <Link
                  className="btn btn-pill btn-outline-primary btn-sm"
                  to={"/invoices-management/edit-invoice/" + row.id + ""}
                >
                  <i className="fa-regular fa-pen-to-square"></i> Edit
                </Link>
              ) : (
                <Button color="primary" size="sm" outline disabled>
                  <i className="fa-regular fa-pen-to-square"></i> Edit
                </Button>
              )
            ) : (
              ""
            )}
            <Button
              color="primary"
              className="btn btn-pill btn-sm"
              onClick={() => handleSend(row.id)}
            >
              <i className="fa-solid fa-paper-plane"></i>{" "}
              {row.status === "Sent" && row.to_check === 1 ? "Sent" : "Send"}
            </Button> */}
                        <KebabMenu menus={
                            [
                                {
                                    label: t('Transaction History'),
                                    icon: <span className="align-middle material-symbols-outlined fw-light fs-4">visibility</span>,
                                    onClick: () => handlePaymentHistory(row),
                                    disabled: row.payment_status === 'Not Paid'
                                },
                                {
                                    label: t('Download'),
                                    icon: <i className="fa-solid fa-download"></i>,
                                    onClick: () => handleDownload(row)
                                },
                                {
                                    label: t('Disputes'),
                                    icon: <i className="fa-solid fa-circle-exclamation"></i>,
                                    onClick: () => handleDisputeView(row),
                                    hidden: row.status === 'Draft'
                                }
                            ]
                        } />
                    </div>
                );
            },
        },
    ], [type, t]);

    const rowStyle2 = (row: any, rowIndex: number) => {
        const style = {} as any;

        if (new Date(row.due_date) <= new Date() && row.status === 'Sent') {
            style.backgroundColor = "#FFF0F6";
            style.color = "#EB2F96";
        } else {
            style.backgroundColor = "#FFF";
        }
        //To highlight edited or created record
        if (location?.state?.id === row.id) {
            style.backgroundColor = "#fffaea";
        }

        // if (row.dispute.length > 0) {
        //   style.backgroundColor = "#F00";
        //   style.color = "#EB2F96";
        // }

        return style;
    };

    const cancelledInvoices = useMemo(() => datatableData.data?.filter((i: any) => i.status === 'Cancelled'), [datatableData]);

    const getSum = (key: any) => cancelledInvoices.reduce((accumulator: any, currentValue: any) => accumulator + currentValue[key], 0)


    useEffect(() => {
        if (loadingListData === true) {
            // setTasksListData([]);
            dispatch(
                InvoicesAction.getAllInvoices(
                    { ...getDataTableQuery(datatableOptions, columns), type, filters },
                    {}
                )
            );
        }
    }, [loadingListData]);

    useEffect(() => {
        if (type !== 'graph') {
            setLoadingListData(true);
            setTasksListData([]);
        }
    }, [type, t]);

    useEffect(() => {
        if (datatableData?.data) {
            setActCount(datatableData?.count);

            if (type === 'followup')
                setTasksListData(datatableData.data?.filter((i: any) => !i.credit_note_details || i.credit_note_details?.amount_due !== 0));
            else
                setTasksListData(datatableData.data);

            const updatedDatatableOptions = {
                ...datatableOptions,
                recordsTotal: datatableData.recordsTotal,
                recordsFiltered: datatableData.recordsFiltered,
            };
            setCancelledAmount((state: any) => ({
                ...state,
                total: getSum('total'),
                tax: getSum('tax'),
                tax_amount: getSum('tax_amount'),
                amount_due: getSum('final_amount_due'),
                tax_excluded: getSum('tax_excluded'),
                expenses: getSum('expenses'),
            }))
            setDatatableOptions(updatedDatatableOptions);
            setLoadingListData(false);
            setLoader(false);
        }

    }, [datatableData]);

    const onTableChange = (types: any, newState: any) => {
        let draw = datatableOptions.draw + 1;
        let page = 1;
        if (types == "search") {
            page = 1;
        } else {
            page = newState.page;
        }

        const updatedDatatableOptions = {
            ...datatableOptions,
            draw: draw,
            page: page,
            sizePerPage: newState.sizePerPage,
            searchText: newState.searchText,
            sortField: newState.sortField,
            sortOrder: newState.sortOrder,
            scrollY: "300px",
            scrollX: true,
            scrollCollapse: true,
            fixedColumns: {
                left: 1,
                right: 1
            }
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingListData(true);
    };

    const clearFilter = () => {
        const updatedDatatableOptions = {
            ...datatableOptions,
            draw: 1,
            page: 1,
            sizePerPage: 20,
            searchText: "",
            sortField: "id",
            sortOrder: "desc",
        };
        setDatatableOptions(updatedDatatableOptions);

        const filter = {
            ...filters,
            is_filtered: false,
            company_id: company_id,
            contact_person_id: user_id,
            task_id: task_id,
            invoice_date_from: "",
            invoice_date_to: "",
            status: "",
            payment_status: "",
            dispute_status: "",
            is_dispute: false,
            partner: null
        }
        setFilters(filter);
        handleFilters('SET', updatedDatatableOptions, filter)
        setLoadingListData(true);
    };

    useEffect(() => {
        if (company_id || user_id || task_id) {
            setTasksListData([]);
            setLoadingListData(true);
        }
    }, [company_id, user_id, task_id])

    useEffect(() => {
        if (task_id) {
            setTasksListData([]);
            setLoadingListData(true);
        }
    }, [task_id])

    useEffect(() => {
        if (searchParams) {
            const dispute_status = searchParams?.get('dispute_status');
            if (dispute_status) {
                setTasksListData([]);
                setFilters((state) => ({ ...state, dispute_status: dispute_status }));
            } else {
                setFilters((state) => ({ ...state, dispute_status: "" }));
            }
            const status = searchParams?.get('status');
            if (status) {
                setTasksListData([]);
                setFilters((state) => ({ ...state, status: status }));
            } else {
                setFilters((state) => ({ ...state, status: "" }));
            }
        }
    }, [searchParams])

    useEffect(() => {
        if (searchParams?.get('dispute_status') == filters.dispute_status)
            setLoadingListData(true);

        if (searchParams?.get('status') == filters.status)
            setLoadingListData(true);

    }, [filters.dispute_status])

    //Email compose

    const composeMail = async (invoice: any) => {
        let filename = 'invoice-' + invoice.invoice_number + '.pdf';
        let msg = "Downloading an invoice...";
        let msgSuccess = "Invoice downloaded successfully";

        if (invoice.invoice_type === 'CREDIT_MEMO') {
            msg = "Downloading a credit note...";
            msgSuccess = "Credit note downloaded successfully";
            filename = 'credit-note-' + invoice.invoice_number + '.pdf';
        }

        toastId.current = toast(msg, {
            autoClose: false,
            isLoading: true,
        });

        const res: any = await invoicesService.downloadInvoice(invoice?.id);
        if (res.status === 200) {
            toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: res.message, autoClose: 5000, isLoading: false, delay: 500 });
            let file = new File([res.data], filename, { type: "application/pdf", lastModified: new Date().getTime() });
            let contact_person = {
                label: `${invoice?.contact_person_details.first_name} ${invoice?.contact_person_details.last_name}`,
                value: invoice?.contact_person_details?.email
            }
            const draftMail: draftmailProps = {
                title: filename,
                filters: {},
                openMailBox: 1,
                subject: `${invoice?.company_name}-${invoice?.invoice_number}`,
                to: [contact_person],
                files: [file],
            };
            dispatch(setDraftEmailData(draftMail));
        } else {

        }
    };


    return (
        <React.Fragment>
            <DataTable
                columns={userObj.USER_COMPANY === 1 ? columns : publicColumns}
                products={tasksListData}
                datatableOptions={datatableOptions}
                filters={filters}
                onTableChange={onTableChange}
                loadingListData={loadingListData}
                clearFilter={clearFilter}
                rowStyle={rowStyle2}
                bordered={false}
                striped={false}
                exportedFileName="invoices"
                settingTableName="invoices"
                slug="all-invoices"
                customButtons={<>
                    {((userObj.IS_ADMIN || userObj.IS_PARTNER) && userObj.USER_COMPANY === 1) || userObj.CAN_DOWNLOAD_CSF_ACT ?
                        !(company_id || user_id || task_id) &&
                        <>
                            <Button
                                outline
                                color="light"
                                onClick={() => downloadAct()}
                                title={t("Download ACT txt file")}
                                disabled={actCount <= 0}
                            >
                                <span className="align-middle material-symbols-outlined fw-light fs-4">download</span>
                                <Badge color={"danger"} className={actCount <= 0 ? "bg-soft" : ""} pill>{actCount}</Badge>
                            </Button>
                            <Button
                                outline
                                color="light"
                                onClick={() => uploadCsv()}
                                title={t("Upload winbooks csv file")}
                            >
                                <span className="align-middle material-symbols-outlined fw-light fs-4">upload</span>
                            </Button>
                            {/* <Button
                                outline
                                color="light"
                                onClick={() => uploadAct()}
                                title={t("Upload winbooks Act file")}
                            >
                                <span className="align-middle material-symbols-outlined fw-light fs-4">cloud_upload</span>
                            </Button> */}

                        </> : <></>
                    }
                    <Button
                        title={t("Filter")}
                        outline={filters.is_filtered === true ? false : true}
                        color={filters.is_filtered === true ? "secondary" : "light"}
                        onClick={() => setOpenFilter((state) => !state)}>
                        {filters.is_filtered === false ? (
                            <span className="align-middle material-symbols-outlined fw-light fs-4">filter_alt</span>
                        ) : (
                            <span className="align-middle material-symbols-outlined fw-light fs-4">filter_alt_off</span>
                        )}
                    </Button>
                </>
                }
            />
            <Drawer
                title={t("Filters")}
                open={openFilter}
                onClose={() => setOpenFilter((state) => !state)}
                onReset={clearFilter}
                footer={
                    <Button
                        color="primary"
                        style={{ margin: "16px" }}
                        onClick={handleApplyFilters}
                    >
                        {t("Apply")}
                    </Button>
                }
            >
                <Filters
                    filters={filters}
                    onApply={() => { }}
                    onClear={() => { }}
                    onChange={(obj: any) => handleFiltersChange(obj)}
                    hideCompany={company_id > 0 || user_id > 0 || task_id > 0}
                    loading={loadingListData}
                />
            </Drawer>
            <Drawer
                title={t("Receive Payment")}
                open={openPaymentForm}
                onClose={() => setOpenPaymentForm((state) => !state)}
                withoutBody={true}
            >
                <PaymentForm
                    values={selectedInvoice}
                    onApply={() => { }}
                    onClose={() => { setOpenPaymentForm(false); setLoadingListData(true); }}
                    onChange={(obj: any) => handleFiltersChange(obj)}
                />
            </Drawer>

            <Drawer
                title={t("Transaction History")}
                open={openPaymentHistory}
                onClose={() => setOpenPaymentHistory((state) => !state)}
            >
                <PaymentHistory invoice={selectedInvoice} />
            </Drawer>

            <Drawer
                title={t("Reminder History")}
                open={openReminderHistory}
                onClose={() => setOpenReminderHistory((state) => !state)}
            >
                <ReminderHistory invoice={selectedInvoice} />
            </Drawer>

            <Drawer
                title={t("Refund")}
                open={openRefund}
                onClose={() => setOpenRefund((state) => !state)}
                withoutBody={true}
            >
                <RefundForm
                    values={selectedInvoice}
                    onClose={() => { setOpenRefund(false); setLoadingListData(true); }}
                />
            </Drawer>

            <CancelInvoice
                show={openCancel}
                invoice={selectedInvoice}
                onCloseClick={() => setOpenCancel((state) => !state)}
                onSubmit={() => {
                    setLoadingListData(true);
                    setOpenCancel((state) => !state);
                }} />

            <Drawer
                title={t("Comments")}
                open={openComment}
                onClose={() => setOpenComment((state) => !state)}
            >
                <MultiComments
                    moduleData={{
                        module_app_id: 5,
                        module_main_id: selectedInvoice?.id,
                        module_sub_id: selectedInvoice?.invoice_id,
                        section_id: 0,
                    }}
                    commentForIDs={selectedInvoice?.status === 'Sent' ? [
                        {
                            value: selectedInvoice?.contact_person_details?.id,
                            label: selectedInvoice?.contact_person_details?.first_name + ' ' + selectedInvoice?.contact_person_details?.last_name,
                            isHidden: userObj.USER_COMPANY === 1 ? false : true,
                        },
                    ] : []}
                    fetchData={true}
                    isOpen={false}
                />
            </Drawer>

            <Drawer
                title={t("Company")}
                open={openCompany}
                onClose={() => setOpenCompany((state) => !state)}
                fullWidth
            >
                <EditCompany id={selectedInvoice?.company_details?.id} company_id={selectedInvoice?.company_details?.company_id} type={1} isWindow={true} />
            </Drawer>

            <Drawer
                title={t("User")}
                open={openUser}
                onClose={() => setOpenUser((state) => !state)}
                fullWidth
            >
                <EditUser id={selectedInvoice?.contact_person_details?.id} user_id={selectedInvoice?.contact_person_details?.user_id} type={1} isWindow={true} />
            </Drawer>

            <Drawer
                title={selectedInvoice?.invoice_type === 'INVOICE' ? "Invoice" : 'Credit Note'}
                open={openInvoice}
                onClose={() => setOpenInvoice((state) => !state)}
                fullWidth
            >
                <ViewInvoice {...selectedInvoice} />
            </Drawer>

            <Drawer
                title={t("Export to ACT")}
                open={openAct}
                onClose={() => setOpenAct((state) => !state)}
                footer={
                    <Button
                        color="primary"
                        style={{ margin: "16px" }}
                        onClick={handleActDownload}
                        disabled={downloading}
                    >
                        {downloading ? t('Downloading') : t('Download')}
                    </Button>
                }
            >
                <div>
                    <Input
                        id="invoice_journal"
                        name="invoice_journal"
                        label={t("Invoice Journal")}
                        value={actParams.invoice_journal}
                        onChange={handleParamsChange}
                        className="mb-3"
                    />
                    <Input
                        id="credit_note_journal"
                        name="credit_note_journal"
                        label={t("Credit Note Journal")}
                        value={actParams.credit_note_journal}
                        onChange={handleParamsChange}
                        className="mb-3"
                    />
                    <Input
                        id="book_year"
                        name="book_year"
                        label={t("Book Year")}
                        value={actParams.book_year}
                        className="mb-3"
                        onChange={handleParamsChange}
                    />
                    <div className="mb-3">
                        <Select
                            id="period"
                            name="period"
                            label={t("Period")}
                            value={periodOptions.find((p: any) => p.value === actParams.period)}
                            options={periodOptions}
                            onChange={(option: any) => setActParams((params: any) => ({ ...params, period: option.value }))}
                            required
                            touched={true}
                            error={!actParams.period ? 'Period is required' : null}
                        />
                    </div>
                    <Input
                        id="customer_ledger_account"
                        name="customer_ledger_account"
                        label={t("Customer ledger account")}
                        value={actParams.customer_ledger_account}
                        onChange={handleParamsChange}
                        className="mb-3"
                    />
                    <Input
                        id="sales_ledger_account"
                        name="sales_ledger_account"
                        label={t("Sales ledger account")}
                        value={actParams.sales_ledger_account}
                        onChange={handleParamsChange}
                        className="mb-3"
                    />
                    <Input
                        id="vat_ledger_account"
                        name="vat_ledger_account"
                        label={t("VAT ledger account")}
                        value={actParams.vat_ledger_account}
                        onChange={handleParamsChange}
                        className="mb-3"
                    />
                    <Input
                        id="sales_discount_ledger_account"
                        name="sales_discount_ledger_account"
                        label={t("Sales discount ledger account")}
                        value={actParams.sales_discount_ledger_account}
                        onChange={handleParamsChange}
                        className="mb-3"
                    />
                </div>
            </Drawer>

            <Drawer
                title={t("Raise Dispute")}
                open={openDisputeForm}
                onClose={() => setOpenDisputeForm((state) => !state)}
                style={{ width: '500px' }}
            >
                <DisputeForm values={selectedInvoice} onClose={() => setOpenDisputeForm((state) => !state)} onApply={() => setLoadingListData(true)} />
            </Drawer>

            <Drawer
                title={t("View Disputes")}
                open={openDisputes}
                onClose={() => setOpenDisputes((state) => !state)}
                style={{ width: '500px' }}
            >
                <DisputeList values={selectedInvoice} onClose={() => setOpenDisputes((state) => !state)} onApply={() => setLoadingListData(true)} />
            </Drawer>

            <Modal
                size="lg"
                isOpen={openCSV}
                toggle={() => setOpenCSV((state: boolean) => !state)}
                backdrop="static"
                keyboard={false}
                autoFocus={false}
                centered={true}
            >
                <div className="modal-header">
                    <h5 className="modal-title">{t("Upload winbooks csv file")}</h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => setOpenCSV((state: boolean) => !state)}
                        aria-label="Close"
                    ></button>
                </div>
                <ModalBody>
                    <Dropzone onDrop={handleCsvUpload} accept={['.csv']}>
                        {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                                <div className="dz-message needsclick mt-2" {...getRootProps()}>
                                    <input
                                        {...getInputProps()}
                                        name="file_name"
                                        accept={'.csv'}
                                        disabled={true}
                                    />
                                    <div className="mb-3">
                                        <i className="display-4 text-muted fa-solid fa-cloud-arrow-up" />
                                    </div>
                                    <h4>{t("Drop files here or click to upload.")}</h4>
                                </div>
                            </div>
                        )}
                    </Dropzone>
                </ModalBody>
            </Modal>

            <Modal
                size="lg"
                isOpen={openActUpload}
                toggle={() => setOpenActUpload((state: boolean) => !state)}
                backdrop="static"
                keyboard={false}
                autoFocus={false}
                centered={true}
            >
                <div className="modal-header">
                    <h5 className="modal-title">{t("Upload winbooks Act file")}</h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => setOpenActUpload((state: boolean) => !state)}
                        aria-label="Close"
                    ></button>
                </div>
                <ModalBody>
                    <Dropzone onDrop={handleActUpload} accept={['.txt']}>
                        {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                                <div className="dz-message needsclick mt-2" {...getRootProps()}>
                                    <input
                                        {...getInputProps()}
                                        name="file_name"
                                        accept={'.txt'}
                                        disabled={true}
                                    />
                                    <div className="mb-3">
                                        <i className="display-4 text-muted fa-solid fa-cloud-arrow-up" />
                                    </div>
                                    <h4>{t("Drop files here or click to upload.")}</h4>
                                </div>
                            </div>
                        )}
                    </Dropzone>
                </ModalBody>
            </Modal>

            <Modal
                size="md"
                isOpen={openCsf}
                toggle={() => setOpenCsf((state: boolean) => !state)}
                backdrop="static"
                keyboard={false}
                autoFocus={false}
                centered={false}
            >
                <ModalBody className=" bg-soft bg-danger text-danger font-size-17">
                    <p>{t("CSF.csv file is available to download. please download it before downloading ACT.")}</p>
                    <hr />
                    <div className="d-flex justify-content-between">
                        <Button loading={loadingCsf} color="primary" onClick={() => handleDownloadCsf()}>{t("Download CSF")}</Button>
                        <Button color="danger" onClick={() => { setOpenCsf(false); setOpenAct(true) }}>{t("Cancel")}</Button>
                    </div>
                </ModalBody>
            </Modal>

        </React.Fragment>

    )
}

export default InvoiceList;