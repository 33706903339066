import React, { useContext, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import logoLight from "../../assets/images/logos/tc-logo-white-block-without-name.png";
import logoDark from "../../assets/images/logos/tc-logo-red-block-without-name.png";

import LanguageDropdown from "./TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "./TopbarDropdown/NotificationDropdown";
import ProfileMenu from "./TopbarDropdown/ProfileMenu";
import CreateTask from "./TopbarDropdown/CreateTask";
import EventCalendar from "./TopbarDropdown/EventCalendar";
import UserManual from "./TopbarDropdown/UserManual";
import CreateShortcuts from "./TopbarDropdown/CreateShortcuts";
import { getUserProfile } from "../../helpers/functions_helper";

// Actions
import { CommonAction } from "../../store/actions";
import { setDraftEmailData } from "../../store/mails/actions";
import EmailButton from '../../pages/Email/components/EmailButton';
import ReportIssueModal from "../../pages/Issue/ReportIssueModal";
import AnnouncementDropdown from "./TopbarDropdown/Announcement";
import { useTranslation } from "react-i18next";

const Header = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userProfile = useMemo(() => getUserProfile(props?.slug), [props?.slug]);
  const taskProfile = getUserProfile("all-tasks");
  const eventProfile = getUserProfile("all-events");
  const helpProfile = getUserProfile("all-user-manual");
  const [issueTrackerModal, setIssueTrackerModal] = useState<boolean>(false);

  const toggleMenu = () => {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }

    if (body.classList.contains("vertical-collpsed")) {
      dispatch(CommonAction.setSidebarcollpsed(false));
    } else {
      dispatch(CommonAction.setSidebarcollpsed(true));
    }
  };

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  //Email compose
  const composeMail = () => {
    const draftMail: any = {
      title: "New Message",
      filters: {},
      openMailBox: 1,
      to: []
    };
    dispatch(setDraftEmailData(draftMail));
  };

  const setIssueModal = () => {
    setIssueTrackerModal(preState => (!preState));
  }

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/dashboard" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoDark} alt="" height="40" />
                </span>
              </Link>

              <Link to="/dashboard" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoLight} alt="" height="40" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={toggleMenu}
              className="btn btn-sm px-3 font-size-16 header-item"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>
          </div>

          <div className="d-flex">
            <LanguageDropdown />

            <div className="dropdown d-none d-lg-inline-block ms-1">
              {userProfile.IS_INTERNAL ? (
                <>
                  {taskProfile.CAN_CREATE && <CreateTask />}
                  <CreateShortcuts />
                  {eventProfile.CAN_VIEW && <EventCalendar />}
                </>
              ) : null}

              <button
                type="button"
                onClick={toggleFullscreen}
                className="btn header-item noti-icon"
                data-toggle="fullscreen"
                title={t("Full screen")}
              >
                <span className="align-middle material-symbols-outlined fw-light">
                  fullscreen
                </span>
              </button>

              {(userProfile.IS_INTERNAL && userProfile.IS_PARTNER) && (
                <Link
                  to={
                    "/masters/company/company-details/" +
                    userProfile.USER_COMPANY +
                    "/" +
                    userProfile.USER_COMPANY_SUB_ID
                  }
                >
                  <button
                    type="button"
                    className={`btn header-item noti-icon ${window.location.pathname ===
                      "/masters/company/company-details/" +
                      userProfile.USER_COMPANY +
                      "/" +
                      userProfile.USER_COMPANY_SUB_ID
                      ? "header-icon-active"
                      : ""
                      }`}
                    title={t("Settings")}
                  >
                    <span className="align-middle material-symbols-outlined fw-light">
                      settings
                    </span>
                  </button>
                </Link>
              )}

              {userProfile.USER_TYPE !== 3 && (
                <Link to="/dashboard/audit-logs">
                  <button
                    type="button"
                    className={`btn header-item noti-icon ${window.location.pathname === "/dashboard/audit-logs"
                      ? "header-icon-active"
                      : ""
                      }`}
                    title={t("Audit log")}
                  >
                    <span className="align-middle material-symbols-outlined fw-light">
                      timeline
                    </span>
                  </button>
                </Link>
              )}

              {helpProfile.CAN_VIEW && <UserManual />}
            </div>
            {userProfile.IS_INTERNAL ? <button
              type="button"
              onClick={() => setIssueModal()}
              className="btn header-item noti-icon"
              data-toggle="Bug Report"
              title={t("Bug Report")}
            >
              <span className="align-middle material-symbols-outlined fw-light" style={{ fontSize: '30px' }}>
                bug_report
              </span>
            </button> : null}

            <NotificationDropdown />
            <AnnouncementDropdown />
            <EmailButton handlerCompose={() => composeMail()} is_header_bar={true} />
            <ProfileMenu />
          </div>
        </div>
      </header>
      {(issueTrackerModal && userProfile.IS_INTERNAL)   ? <ReportIssueModal
        show={issueTrackerModal}
        onCloseClick={() => setIssueModal()}
      /> : null}
    </React.Fragment>
  );
};

export default Header;
