import React, { useEffect, useState } from "react";
//import { Link } from "react-router-dom";
//import Dropzone from "react-dropzone";
import {
  Button,
  Col,
  Form,
  Label,
  Row,
  FormFeedback,
  UncontrolledAlert,
  Modal,
  ModalBody,
  ModalFooter,

} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
//redux
import { useSelector } from "react-redux";
// @ts-ignore
import CustomEditor from "ckeditor5-custom-build/build/ckeditor";
// @ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { ckEditorConfiguration, getDependentSelectOptions, getSelectOptions, yearData } from "../../../helpers/functions_helper";
import { languageOptions, questionnaireTypes } from "../../../common/staticdata";
import { useTranslation } from "react-i18next";
import Input from "../../../components/Form/Input";
import Select from "../../../components/Form/Select/Select";


// Import Breadcrumb
//import Breadcrumbs from "../../../components/Layout/Breadcrumb";
const QuestionnaireForm = (props: any) => {
  let statusData = props.statusData;
  const { t } = useTranslation();
  // for setting color picker value
  const [color, setColor] = useState('');

  /* set user Status */
  const [userStatusOption, setuserStatusOption] = useState([]);
  const [userstatusValue, setUserstatusValue] = useState({
    value: 0,
    label: t("Please select..."),
  });
  const [loadingUserStatus, setloadingUserStatus] = useState(true);
  const handlecolor = (e: any) => {
    setColor(e.target.value)
  }

  const [typeOption, setTypeOption] = useState([]);
  const [loadingTypeOption, setloadingTypeOption] = useState(true);
  const [typeValue, setTypeValue] = useState();

  const [questionnaireTypeOption, setQuestionnaireTypeOption] = useState([]);
  const [loadingquestionnaireTypeOption, setloadingquestionnaireTypeOption] = useState(true);
  const [questionnaireTypeValue, setQuestionnaireTypeValue] = useState<any>();
  const [regionOptions, setRegionOptions] = useState<any>([])

  const [error, setError] = useState('');
  const [userTypeFlag, setUserTypeFlag] = useState(false);

  let data = useSelector((state: any) => ({
    success: state.QuestionnaireStatus.success,
    error: state.QuestionnaireStatus.error,
    isModel: state.QuestionnaireStatus.isModel,
  }));



  useEffect(() => {
    getSelectOptions("tbl_master_user_status", "user_status_value").then(function (
      options
    ) {
      setuserStatusOption(options);
      setloadingUserStatus(false);
      const selectedOption = options.find((option: any) => option.value === statusData.user_type);
      setUserstatusValue(selectedOption);
    });

    getDependentSelectOptions("tbl_master_fees", "description", "is_questionnaire", 1).then(function (options) {
      setTypeOption(options);
      setloadingTypeOption(false);
      const selectedOption = options.find((option: any) => option.value == statusData.task_type);
      setTypeValue(selectedOption);
    });

    getSelectOptions("tbl_master_region", "region").then(function (
      options
    ) {
      setRegionOptions(options);
    });

  }, [])

  const [regionList, setRegionList] = useState<any>([]);
  const [yearList, setYearList] = useState<any>([]);
  const [languageList, setLanguageList] = useState<any>([]);
  const [userStatusList, setUserStatusList] = useState<any>([]);
  const [questionnaireTypeList, setQuestionnaireTypeList] = useState<any>([]);
  useEffect(() => {

    if (userStatusOption) {
      const options = userStatusOption?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setUserStatusList(options);
    }

    if (yearData) {
      const options = yearData?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setYearList(options);
    }


    if (regionOptions) {
      const options = regionOptions?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setRegionList(options);
    }

    if(languageOptions){
      const options = languageOptions?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setLanguageList(options);
    }

  }, [localStorage.getItem("I18N_LANGUAGE"), regionOptions, userStatusOption])



  useEffect(() => {
    if (data.error !== error) setError(data.error);
  }, [data.error]);


  const CKinputHandler = (event: any, editor: any) => {
    validation.setFieldValue("content", editor.getData());
  };

  const validationSchema = Yup.object().shape({
    questionnaire_type: Yup.string()
      .required("Questionnaire type field is required.")
      .min(2, "Questionnaire type must be greater than 2 characters.")
      .max(100, "Questionnaire type may not be greater than 100 characters."),
    task_type: Yup.string()
      .required("Task type field is required."),
    language: Yup.string()
      .required("Language field is required."),
    year: Yup.string()
      .required("Year field is required."),
    type: Yup.string()
      .required("Type field is required."),
    region_id: Yup.string().when(
      ["type"],
      {
        is: (type: string) =>
          type == "single_permit" || type == "single_permit_outside",
        then: Yup.string().required(
          "Region field is required"
        ),
      }
    ),
  });


  const validation = useFormik({
    initialValues: {
      id: (statusData && statusData.id) || '',
      questionnaire_type_id: (statusData && statusData.questionnaire_type_id) || '',
      questionnaire_type: (statusData && statusData.questionnaire_type) || '',
      color_code: (statusData && statusData.color_code) || "#000000",
      user_type_flag: (statusData && statusData.user_type_flag) || 0,
      user_type: (statusData && statusData.user_type) || '',
      task_type: (statusData && statusData.task_type) || '',
      content: (statusData && statusData.content) || '',
      language: (statusData && statusData.language) || '',
      year: (statusData && statusData.year) || ((new Date()).getFullYear()),
      admin_template: (statusData && statusData.admin_template) || 0,
      view_all: (statusData && statusData.view_all) || 0,
      type: (statusData && statusData.type) || '',
      company: (statusData && statusData.company) || 0,
      individual: (statusData && statusData.individual) || 0,
      region_id: (statusData && statusData.region_id) || '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (props.type == "edit") {
        props.onSubmitClick(values);
      } else {
        props.onExportClick(values);
      }

    },
  });


  useEffect(() => {
    if (statusData.user_type != "") {
      setUserTypeFlag(true);
    } else {
      setUserTypeFlag(false);
    }
  }, [statusData])

  return (
    <React.Fragment>
      <Modal
        size="lg"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">{props.modalTitle}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label="Close"
          ></button>
        </div>
        <Row>
          <Col lg="12">
            {error && data.isModel && error !== '' ? (
              <UncontrolledAlert color="danger" role="alert">
                {error}
              </UncontrolledAlert>
            ) : null}
            <Form
              onSubmit={(e: any) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              onChange={() => setError('')}
            >
              <ModalBody>
                <Col lg="12">
                  <div className="mb-3">
                    <div className='color_palette_main_div'>
                      <Input
                        name="questionnaire_type"
                        className="form-control"
                        label="Questionnaire type"
                        required
                        placeholder={t("Questionnaire type")}
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.questionnaire_type || ""}
                        invalid={
                          validation.touched.questionnaire_type &&
                            validation.errors.questionnaire_type
                            ? true
                            : false
                        }
                        error={validation.errors.questionnaire_type}
                        touched={validation.touched.questionnaire_type}
                      />
                      <div className="color_palette_div">
                        <input
                          name="color_code"
                          className="color_palette form-control"
                          type="color"
                          id="example-color-input"
                          onChange={e => { handlecolor(e); validation.handleChange(e) }}
                          onBlur={validation.handleBlur}
                          value={validation.values.color_code || ""}

                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Row>
                  <Col lg="4">
                    <div className="mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input mx-1"
                        id="customCheckcolor1"
                        name="user_type_flag"
                        onChange={e => {
                          setUserTypeFlag(!userTypeFlag);
                          if (!e.target.checked) {
                            setUserstatusValue({
                              value: 0,
                              label: t("Please select..."),
                            })
                            validation.setFieldValue("user_type", null);
                          }
                        }}
                        checked={userTypeFlag}
                        onBlur={validation.handleBlur}
                      />
                      <Select
                        id="user_type"
                        name="user_type"
                        label="User type"
                        className="is-invalid"
                        options={userStatusList}
                        value={userstatusValue}
                        isSearchable={true}
                        isDisabled={!userTypeFlag}
                        onChange={(option: any) => {
                          setUserstatusValue(option);
                          validation.setFieldValue("user_type", option.value);
                        }}
                        error={validation.errors.user_type}
                        touched={validation.touched.user_type}
                      />
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="mb-3">
                      <Select
                        id="language"
                        name="language"
                        label="Language"
                        required
                        className="is-invalid"
                        options={languageList}
                        defaultValue={languageList?.find((opt: any) => opt.value == validation.values.language)}
                        isSearchable={false}
                        onBlur={() => validation.setFieldTouched("language", true)}
                        onChange={(option: any) => {
                          validation.setFieldValue("language", option.value);
                        }}
                        error={validation.errors.language}
                        touched={validation.touched.language}
                      />
                    </div>
                  </Col>
                  <Col lg="4">
                    <div className="mb-3">
                      <Select
                        id="year"
                        name="year"
                        label="Year"
                        required
                        className="is-invalid"
                        options={yearList}
                        defaultValue={yearList.find((opt: any) => opt.value == validation.values.year)}
                        isSearchable={true}
                        onChange={(option: any) => {
                          validation.setFieldValue("year", option.value);
                        }}
                        error={validation.errors.year}
                        touched={validation.touched.year}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className="col-md-8 mb-3">
                    <Select
                      id="type"
                      name="task_type"
                      label="Task type"
                      required
                      className="is-invalid"
                      options={typeOption}
                      value={typeOption && typeOption.find((opt: any) => opt.value == validation.values.task_type)}
                      isSearchable={true}
                      isDisabled={loadingTypeOption}
                      // onBlur={() =>
                      //     validation.setFieldTouched("type", true)
                      // }
                      onChange={(option: any) => {
                        validation.setFieldValue(
                          "task_type",
                          option.value
                        );
                        setTypeValue(option);
                      }}
                      error={validation.errors.task_type}
                      touched={validation.touched.task_type}
                    />
                  </div>
                  <div className="col-md-4 mb-3">
                    <Select
                      id="type"
                      name="type"
                      label="Type"
                      required
                      className="is-invalid"
                      options={questionnaireTypes}
                      value={questionnaireTypes.find((opt: any) => opt.value == validation.values.type)}
                      isSearchable={true}
                      //isDisabled={loadingquestionnaireTypeOption}
                      // onBlur={() =>
                      //     validation.setFieldTouched("type", true)
                      // }
                      onChange={(option: any) => {
                        validation.setFieldValue(
                          "type",
                          option.value
                        );
                        setQuestionnaireTypeValue(option);
                      }}
                      error={validation.errors.type}
                      touched={validation.touched.type}
                    />
                  </div>

                </Row>
                {!!(validation.values.type == "single_permit" || validation.values.type == "single_permit_outside") && (
                  <Row>
                    <div className="col-md-4 mb-3">
                      <Select
                        id="region_id"
                        name="region_id"
                        className="is-invalid"
                        label="Region"
                        required
                        options={regionList}
                        placeholder={t("Select...")}
                        value={regionList?.find((opt: any) => opt.value == validation.values.region_id)}
                        isSearchable={true}
                        //isDisabled={loadingquestionnaireTypeOption}
                        // onBlur={() =>
                        //     validation.setFieldTouched("type", true)
                        // }
                        onChange={(option: any) => {
                          validation.setFieldValue(
                            "region_id",
                            option.value
                          );
                        }}
                        error={validation.errors.region_id}
                        touched={validation.touched.region_id}
                      />
                    </div>
                  </Row>
                )}
                <Row>
                  <Col xl={3}>
                    <div className="form-check form-check-primary mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="admin_template"
                        name="admin_template"
                        onChange={e => {
                          validation.setFieldValue("admin_template", !validation.values.admin_template);
                        }}
                        checked={validation.values.admin_template}
                        onBlur={validation.handleBlur}
                      />

                      <label
                        className="form-check-label"
                        htmlFor="admin_template"
                      >
                        {t("One view template")}
                      </label>

                    </div>
                  </Col>
                  <Col xl={3}>
                    <div className="form-check form-check-primary mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheckview"
                        name="view_all"
                        onChange={e => {
                          validation.setFieldValue("view_all", !validation.values.view_all);
                        }}
                        checked={validation.values.view_all}
                        onBlur={validation.handleBlur}
                      />

                      <label
                        className="form-check-label"
                        htmlFor="customCheckview"
                      >
                        {t("View all")}
                      </label>

                    </div>
                  </Col>
                  {(validation.values.type == "ubo_aml" || validation.values.type == "ubo") || validation.values.type == "risque" ?
                    <>
                      <Col xl={3}>
                        <div className="form-check form-check-primary mb-3">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckcompany"
                            name="company"
                            onChange={e => {
                              validation.setFieldValue("company", !validation.values.company);
                            }}
                            checked={validation.values.company}
                            onBlur={validation.handleBlur}
                          />

                          <label
                            className="form-check-label"
                            htmlFor="customCheckcompany"
                          >
                            {t("Company")}
                          </label>
                        </div>
                      </Col>
                      <Col xl={3}>
                        <div className="form-check form-check-primary mb-3">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="customCheckindividual"
                            name="individual"
                            onChange={e => {
                              validation.setFieldValue("individual", !validation.values.individual);
                            }}
                            checked={validation.values.individual}
                            onBlur={validation.handleBlur}
                          />

                          <label
                            className="form-check-label"
                            htmlFor="customCheckindividual"
                          >
                            {t("Individual")}
                          </label>
                        </div>
                      </Col>
                    </>
                    : null
                  }

                </Row>
                <Col xl="12" className="mb-3">
                  <Label className="form-label">
                    {t("Content")} {" "}
                  </Label>
                  <div className="is-invalid">
                    <CKEditor
                      editor={CustomEditor}
                      config={ckEditorConfiguration}
                      data={validation.values.content}
                      // value={validation.values.tooltip_data || ""}
                      onChange={CKinputHandler}
                      id="content"
                      name="content"
                      className="form-control"
                    />
                  </div>
                </Col>
                <ModalFooter>
                  <Button
                    color="primary"
                    className="btn-block"
                    type="submit"
                    disabled={props.loading}
                  >
                    {props.loading ? (
                      <span>
                        <i className="fa-solid fa-spinner fa-spin"></i>
                        <span>&nbsp; {t("Please wait...")}</span>
                      </span>
                    ) : (
                      <span>{props.type == "edit" ? t("Submit") : t("Export")}</span>
                    )}
                  </Button>

                  <Button
                    color="danger"
                    className="btn-block"
                    type="reset"
                    onClick={props.onCloseClick}
                    disabled={props.loading}
                  >
                    {t("Cancel")}
                  </Button>
                </ModalFooter>
              </ModalBody>
            </Form>
          </Col>
        </Row>
      </Modal>
    </React.Fragment >
  );
};
export default QuestionnaireForm;
