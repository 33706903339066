import React, { useCallback, useEffect, useRef, useState, memo } from 'react';
// @ts-ignore
import CustomEditor from "ckeditor5-custom-build/build/ckeditor";
// @ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { getUserProfile, ckEditorConfigurationEmail } from "../../../helpers/functions_helper";
import { useDropzone } from 'react-dropzone';
import { Button, ButtonGroup, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Row, UncontrolledDropdown } from 'reactstrap';
import { debounce, findIndex } from 'lodash';
import { toast } from "react-toastify";

import useWindowDimensions from './hook';
import FileMenu from './FileMenu';
import AsyncCreatableSelect from 'react-select/async-creatable';
import EmailService from '../../../services/email.service';
import './style.scss';
import { useSelector, useDispatch } from "react-redux";

//Mail Action
import { setDraftEmailData } from "../../../store/mails/actions";

import UserOptions from './UserOptions';
import AttachmentOptions from './AttachmentOptions';
import AttachmentListModel from './Model/AttachmentListModel';
import { MaterialIcon } from './styles';
import { draftmailProps } from './type';
import fileDownload from 'js-file-download';
import axios from "axios";
import { Buffer } from "buffer";
import { useTranslation } from 'react-i18next';
import Attachment from './Attachment';

const ComposeMail = (props: any) => {

    const [toolBar, setToolBar] = React.useState({
        is_mini: true,
        is_maxi: false,
    });

    const dispatch = useDispatch();
    const toastId = useRef(null) as any;
    const { t } = useTranslation();
    const { composeType } = props;
    const [draftFiles, setDraftFiles] = useState<any>([]);

    const onDrop = useCallback((acceptedFiles: any) => {
        setDraftFiles([...draftFiles, ...acceptedFiles]);
        uploadFiles(acceptedFiles);
    }, [draftFiles])

    const { getRootProps, getInputProps, isDragActive, acceptedFiles, open } = useDropzone({ onDrop });
    const [documentList, setDocumentList] = useState([]);

    //Uploda Document 
    const [uploadDocModal, setUploadDocModal] = useState<boolean>(false);
    const [uploadDocData, setUploadDocData] = useState<any>([]);

    const { width, height } = useWindowDimensions();

    //Template list model
    const [showTemplateModel, setShowTemplateModel] = useState<boolean>(false);

    //Signature list model    
    const [showSignatureModel, setSignatureModel] = useState<boolean>(false);

    //show hidden , cc bcc
    const [isCC, setIsCC] = useState<boolean>(false);
    const [isBCC, setIsBCC] = useState<boolean>(false);

    const { draftEmail } = useSelector((state: any) => ({ draftEmail: state.Mails.draft as draftmailProps }));
    const { file_system_attachment, title, subject, body, attachment, to, cc, bcc, signature, openMailBox, filters } = draftEmail;

    //File uploading
    const [progressInfos, setProgressInfos] = useState<any>({ val: [] });
    const [message, setMessage] = useState<any>([]);
    const [fileInfos, setFileInfos] = useState<any>([]);
    const progressInfosRef = useRef<any>(null);

    useEffect(() => {
        if (fileInfos.length > 0) {
            fileInfos.map((item: any) => {
                const d = findIndex(draftFiles, { name: `${item?.name}.${item?.file_extension}` });
                if (d > -1) {
                    const draftFile = draftFiles;
                    draftFile[d]['file_name_source'] = item?.path;
                    draftFile[d]['file_extension'] = item?.file_extension;
                    setDraftFiles(draftFile);
                }
            })
        }
    }, [fileInfos])


    const uploadFiles = async (dfiles: any) => {
        const files = Array.from(dfiles);
        let _progressInfos = files.map((file: any) => ({ percentage: 0, fileName: file.name }));
        progressInfosRef.current = {
            val: _progressInfos,
        }
        const uploadPromises = files.map((file, i) => upload(i, file));
        const res = await Promise.all(uploadPromises);
        setFileInfos(res);
        setMessage([]);
    };

    const upload = async (idx: any, file: any) => {
        let _progressInfos = [...progressInfosRef.current.val];
        const formData = new FormData();
        formData.append("file_name", file as any);
        return EmailService.uploadDraftMailAttachment(formData)
            .then((data) => {
                setMessage((prevMessage: any) => ([
                    ...prevMessage,
                    "Uploaded the file successfully: " + file.name,
                ]));
                console.log(data?.data?.status === "success");
                if (data?.data?.status === "success") {
                    console.log(data)
                    return data.data.data;
                }
            })
            .catch(() => {
                _progressInfos[idx].percentage = 0;
                setProgressInfos({ val: _progressInfos });
                setMessage((prevMessage: any) => ([
                    ...prevMessage,
                    "Could not upload the file: " + file.name,
                ]));
            });
    };

    const createDraftMail = async () => {
        const postData = {
            type: composeType,
            id: props?.data?.id
        };
        const res = await EmailService.addNewInboxMail(postData);
        console.log(res);
    }

    useEffect(() => {
        if (composeType === 'reply' || composeType === 'reply_all') {
            if (props?.data?.sender?.emailAddress) {
                const data = [{
                    label: props?.data?.sender?.emailAddress?.name,
                    value: props?.data?.sender?.emailAddress?.address,
                }];
                const draft = draftEmail;
                draft.to = data;
                dispatch(setDraftEmailData(draft));
            }

            if (props?.data?.subject) {
                const draft = draftEmail;
                draft.subject = props?.data?.subject;
                dispatch(setDraftEmailData(draft));
            }

        }
        if (composeType === 'forward') {
            const draft = draftEmail;
            draft.to = [];
            dispatch(setDraftEmailData(draft));
        }

        if (props?.data?.id) {
            const draft = draftEmail;
            draft.id = props?.data?.id;
            dispatch(setDraftEmailData(draft));
        }

    }, [props.data])


    const deleteFile = async (f: any) => {
        const newFiles = [...draftFiles];
        newFiles.splice(newFiles.indexOf(f), 1);
        setDraftFiles(newFiles);
        await EmailService.deteteDraftFile(f.file_name_source);
    }

    

    useEffect(() => {
        const draft = draftEmail;
        draft.attachment = draftFiles;
        dispatch(setDraftEmailData(draft));
    }, [draftFiles]);


    const files = draftFiles.map((file: any, i: number) => (
        <div className='col-4' key={i}>
            <Attachment
                size={file.size}
                name={file.path}
                handleOnDelete={() => deleteFile(file)}
                deleteOption={true}
            />
        </div>

    ));


    const sendMail = async (type: string) => {
        toastId.current = toast(t("Sending mail"), {
            autoClose: false,
            isLoading: true,
        });
        const draft = draftEmail;
        draft.send_type = composeType || 'send';
        draft.button = type;
        const res = await EmailService.sendMail(draftEmail);
        if (res?.status === 200 && res?.data.status === "success") {
            toast.update(toastId.current, {
                type: toast.TYPE.SUCCESS,
                render: t("Message sent"),
                autoClose: 5000,
                isLoading: false,
                delay: 500,
              });
            const draft = draftEmail;
            draft.openMailBox = 0;
            draft.subject = '';
            draft.to = [];
            dispatch(setDraftEmailData({}));
            props.handleChange();
        } else {
            toast.update(toastId.current, {
                type: toast.TYPE.ERROR,
                render: 'Error Token expire',
                autoClose: 5000,
                isLoading: false,
                delay: 500,
              });
        }
    }


    const loadOptions = async (keyword: string) => {
        const filter = {
            search: keyword,
            extra: filters,
        };
        const user_email = await EmailService.searchUser(filter) as any;
        return user_email?.data?.data || [];
    };
    const loadSuggestedOptions = useCallback(
        debounce((inputValue, callback) => {
            loadOptions(inputValue).then(options => callback(options))
        }, 500), []);


    const attachDoc = (item: any) => {
        setUploadDocData((preState: any) => ([{ ...preState, item }]));
    }

    // User List
    const loadUserList = async (id: string) => {
        const filter = {
            company: id,
            extra: filters,
        };
        const user_email = await EmailService.getUserEmail(filter) as any;
        if (user_email.data.status === "success") {
            if (user_email.data.data.length > 0) {
                const draft = draftEmail;
                draft.to = user_email.data.data;
                dispatch(setDraftEmailData(draft));
            }
        }
    }

    useEffect(() => {
        // if (filters?.company && typeof draftEmail?.to === 'undefined') {
        //     loadUserList(filters?.company)
        // }
    }, [filters?.company])


    //Mail Address 
    //TO
    const handleOnChangeTo = (value: any) => {
        const draft = draftEmail;
        draft.to = value;
        dispatch(setDraftEmailData(draft));
    };
    //CC
    const handleOnChangeCc = (value: any) => {
        if (value.length > 0) {
            const draft = draftEmail;
            draft.cc = value;
            dispatch(setDraftEmailData(draft));
        }
    };
    //BCC
    const handleOnChangeBcc = (value: any) => {
        const draft = draftEmail;
        draft.bcc = value;
        dispatch(setDraftEmailData(draft));
    };

    //Subject and Body Change Handler
    const handleChange = (fieldName: string, fieldValue: string) => {
        const draft = draftEmail;
        draft.body = fieldValue;
        dispatch(setDraftEmailData(draft));
    };

    const handleOnChange = (value: any) => {
        const draft = draftEmail;
        draft.subject = value;
        dispatch(setDraftEmailData(draft));
    };


    const handleSelectedTemplate = (temp: any) => {
        const draft = draftEmail;
        draft.body = temp;
        dispatch(setDraftEmailData(draft));
    }
    const deleteFileSystem = (f: any) => {
        const system_file = [...draftEmail.file_system_attachment || []];
        system_file.splice(system_file.indexOf(f), 1);
        const draft = draftEmail;
        draft.file_system_attachment = system_file;
        dispatch(setDraftEmailData(draft));
    }

    const handleSelectedFileSystem = (temp: any) => {
        const draft = draftEmail;
        draft.file_system_attachment = temp;
        dispatch(setDraftEmailData(draft));
    }

    //Mail Box Operation
    //Minimum, Maximum, Drag, Close 
    const handleMaximum = () => {
        //TODO
        const draft = draftEmail;
        draft.openMailBox = draft.openMailBox === 1 ? 3 : 1;
        dispatch(setDraftEmailData(draft));
    }

    const handleMinimize = () => {
        //TODO
        //Save mail to draft in microsoft graphql
        const draft = draftEmail;
        draft.openMailBox = draft.openMailBox === 1 ? 2 : 1;
        dispatch(setDraftEmailData(draft));
    }

    const handleClose = () => {
        const draft = draftEmail;
        draft.openMailBox = 0;
        dispatch(setDraftEmailData(draft));
        props.handleChange();
    }

    useEffect(() => {
        if (composeType === 'forward') {
            if (props?.data?.body?.content) {
                const draft = draftEmail;
                draft.body = props?.data?.body?.content || '';
                dispatch(setDraftEmailData(draft));
            }
        }
    }, [composeType])

    // Download attachment ------------------------------------------------------------
    const onClickDownload = (row: any) => {
        const SERVER_URL = process.env.REACT_APP_SERVER_URL;
        const AUTH_USER = localStorage.getItem("authUser");
        const ACCESS_TOKEN = JSON.parse(String(AUTH_USER));
        axios({
            url:
                SERVER_URL +
                "/common/download-attachment/" +
                Buffer.from(String(row.id)).toString("base64") +
                "/" +
                Buffer.from(String(row.attachment_id)).toString("base64"),
            method: "GET",
            headers: {
                "Content-Type": "application/" + row.file_extension,
                "Access-Control-Allow-Origin": "*",
                Authorization: "Bearer " + ACCESS_TOKEN.accessToken,
            },
            responseType: "blob",
        }).then((response: any) => {
            fileDownload(response.data, row.file_name + "." + row.file_extension);
        });
    };
    // Download attachment ------------------------------------------------------------


    const getSignature = async () => {
        try {
            const res = await EmailService.getsignatureformail(2);
            if (res.data.status === 'success') {
                if (res.data.data.signature) {
                    const draft = draftEmail;
                    draft.body = res.data.data.signature;
                    dispatch(setDraftEmailData(draft));
                }
            }
        } catch (error) {

        }
    }
    useEffect(() => { getSignature() }, [])


    return (
        <div className='shadow-sm pb-2 mb-3'>
            <Row className="mb-0 mt-5 mt-lg-0 " >
                <Col>
                    <div className="mb-3 d-flex">
                        <div className='flex-grow-1'>
                            <AsyncCreatableSelect
                                cacheOptions
                                loadOptions={loadSuggestedOptions}
                                defaultOptions
                                placeholder="To"
                                isMulti
                                isClearable={false}
                                formatCreateLabel={userInput => `${t("Add new email id")} ${userInput}`}
                                components={{
                                    DropdownIndicator: () => null,
                                    IndicatorSeparator: () => null,
                                    Option: UserOptions,
                                }}
                                value={draftEmail?.to || []}
                                onChange={handleOnChangeTo}
                            />
                        </div>
                        <div>
                            <Button onClick={() => setIsCC(preState => !preState)}
                                color="link"
                            >
                                Cc
                            </Button>
                            <Button onClick={() => setIsBCC(preState => !preState)}
                                color="link"
                            >
                                Bcc
                            </Button>
                        </div>
                    </div>
                    {(isCC || composeType === 'forward') && <div className="mb-3">
                        <AsyncCreatableSelect
                            cacheOptions
                            loadOptions={loadSuggestedOptions}
                            defaultOptions
                            isMulti
                            isClearable={false}
                            formatCreateLabel={userInput => `${t("Add new email id")} ${userInput}`}
                            components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                                Option: UserOptions,
                            }}
                            placeholder="Cc"
                            value={draftEmail?.cc || []}
                            onChange={handleOnChangeCc}
                        />
                    </div>}
                    {isBCC && <div className="mb-3">
                        <AsyncCreatableSelect
                            cacheOptions
                            loadOptions={loadSuggestedOptions}
                            defaultOptions
                            isMulti
                            isClearable={false}
                            formatCreateLabel={userInput => `${t("Add new email id")} ${userInput}`}
                            components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                                Option: UserOptions,
                            }}
                            placeholder="Bcc"
                            value={draftEmail?.bcc || []}
                            onChange={handleOnChangeBcc}
                        />
                    </div>}
                    {composeType === 'forward' && <div className="mb-3">
                        <Input
                            type="text"
                            value={subject}
                            className="form-control"
                            placeholder={t("Subject")}
                            onChange={(e) => handleOnChange(e.target.value)}
                        />
                    </div>}

                    <div  {...getRootProps({
                        onClick: event => event.stopPropagation(),
                    })}>
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <div className="dz-message needsclick mt-2">
                                <div className="mb-3">
                                    <span className="align-middle material-symbols-outlined fw-lighter display-4 text-muted">
                                        cloud_upload
                                    </span>
                                </div>
                                <h4>{t("Drop files here or click to upload.")}</h4>
                            </div>

                        ) :
                            (
                                <div>
                                    <CKEditor
                                        editor={CustomEditor}
                                        config={ckEditorConfigurationEmail}
                                        data={body || ''}
                                        id="comment"
                                        name="comment"
                                        className="form-control"
                                        onChange={(e: any, editor: any) => {
                                            handleChange("template", editor.getData());
                                        }}

                                    />
                                    <aside className='mt-2 mb-1'>
                                        <div className="justify-content-left row p-2">{files}</div>
                                    </aside>
                                </div>
                            )
                        }
                    </div>
                </Col>

            </Row>
            <Row className='mb-3 p-2'>
                {(file_system_attachment !== undefined && file_system_attachment?.length > 0) && <div className='col-12 p-2'>File System Attachment</div>}
                {(file_system_attachment !== undefined && file_system_attachment?.length > 0) && file_system_attachment?.map((item: any) => <div className='col-4 mb-1 p-1' key={item.id}>
                    <ButtonGroup style={{ width: '100%' }}>
                        <Button
                            color="light"
                            outline
                            type="button"
                            className="text-truncate"
                        >
                            <i className="fa-solid fa-file text-primary"></i> {item.label}
                        </Button>
                        <ButtonGroup>
                            <UncontrolledDropdown>
                                <DropdownToggle caret color='light'>
                                    <MaterialIcon size={20}>expand_more</MaterialIcon>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => deleteFileSystem(item)}>
                                        <MaterialIcon size={20}>delete</MaterialIcon> Delete
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={() => onClickDownload(item)}>
                                        <MaterialIcon size={20}>preview</MaterialIcon>View
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </ButtonGroup>
                    </ButtonGroup>
                </div>
                )}
            </Row>
            <Row className='mb-4'>
                <Col>
                    <div className='row'>
                        <div className='col-8 d-flex align-items-center'>
                            <Button
                                color="success"
                                className='bg-btn-color me-2'
                                onClick={() => sendMail('send')}
                            >
                                {t("Send")}
                            </Button>
                            {' '}
                            <Button
                                color="light"
                                outline
                                className='me-2'
                                onClick={() => sendMail('draft')}
                            >
                                {t("Save in Draft")}
                            </Button>
                            {' '}
                            <Button
                                color="light"
                                outline
                                className='me-2'
                                onClick={() => handleClose()}
                            >
                                {t("Discard")}
                            </Button>
                            {' '}
                            <FileMenu
                                documents={documentList || []}
                                handleFileOpener={open}
                                handleFileSystemModel={() => setShowTemplateModel(true)}
                                attachDoc={(e:any) => attachDoc(e)}
                                t={t}
                            />
                            {' '}
                        </div>
                    </div>
                </Col>
            </Row>
            <AttachmentListModel
                title={t("File System")}
                filters={filters}
                show={showTemplateModel}
                selectFiles={handleSelectedFileSystem}
                onCloseClick={() => setShowTemplateModel(false)}
            />
        </div>

    )

}

export default memo(ComposeMail);