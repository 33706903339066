import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class AlertService {

    async getUserAlertListUpComming(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_USER_ALERT_UPCOMING, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getUserAlertListInProcess(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_USER_ALERT_INPROCESS, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getUserAlertListInFollowup(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_USER_ALERT_FOLLOWUP, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }


    async getUserAlertList(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_USER_ALERT_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }


    async getUserCompanyToDo(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_USER_COMPANY_ALERT_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }


    async getUserCompanyReminders(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_USER_COMPANY_REMINDER_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

}
export default new AlertService();