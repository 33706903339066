import React, { useEffect, useState } from "react";
//import { Link } from "react-router-dom";
//import Dropzone from "react-dropzone";
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
  UncontrolledAlert,
  Modal,
  ModalBody,
  ModalFooter,

} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

const RegionForm = (props: any) => {
  let regionData = props.regionData;

  const validationSchema = Yup.object().shape({
    region: Yup.string()
      .required("The region field is required.")
      .min(2, "The region field must be greater than 2 characters.")
      .max(100, "The region field may not be greater than 100 characters.")

  });

  const validation = useFormik({
    initialValues: {
      id: (regionData && regionData.id) || '',
      region_id: (regionData && regionData.region_id) || '',
      region: (regionData && regionData.region) || '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      props.onSubmitClick(values);
    },
  });


  return (
    <React.Fragment>
      <Modal
        size="md"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">{props.modalTitle}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label="Close"
          ></button>
        </div>
        <Row>
          <Col lg="12">
            {/* {error && data.isModel && error !== '' ? (
              <UncontrolledAlert color="danger" role="alert">
                {error}
              </UncontrolledAlert>
            ) : null} */}
            <Form
              onSubmit={(e: any) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              //onChange={() => setError('')}
            >
              <ModalBody>
                <Col lg="12">
                  <div className="mb-3">
                    <Label htmlFor="formrow-category_name-Input">
                      Region
                      <span className="text-warning is_required">*</span>
                    </Label>
                    <div className='color_palette_main_div'>
                    <Input
                      name="region"
                      className="form-control"
                      placeholder="Region"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.region || ""}
                      invalid={
                        validation.touched.region &&
                          validation.errors.region
                          ? true
                          : false
                      }
                    />
                    {validation.touched.region &&
                      validation.errors.region ? (
                      <FormFeedback type="invalid">
                        {validation.errors.region}
                      </FormFeedback>
                    ) : null}
                    </div>
                  </div>
                </Col>
                <ModalFooter>
                  <Button
                    color="primary"
                    className="btn-block"
                    type="submit"
                    disabled={props.loading}
                  >
                    {props.loading ? (
                      <span>
                        <i className="fa-solid fa-spinner fa-spin"></i>
                        <span>&nbsp; Please wait...</span>
                      </span>
                    ) : (
                      <span>Submit</span>
                    )}
                  </Button>
                  <Button
                    color="danger"
                    className="btn-block"
                    type="reset"
                    onClick={props.onCloseClick}
                    disabled={props.loading}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalBody>
            </Form>
          </Col>
        </Row>
      </Modal>
    </React.Fragment >
  );
};
export default RegionForm;
