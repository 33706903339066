import englishFlag from "../assets/images/flags/british_flag.jpg";
import frenchFlag from "../assets/images/flags/french_flag.jpg";
import dutchFlag from "../assets/images/flags/netherland_flag.jpg";

const languages : {[key: string]: any} = {
  "English": {
    label: "English",
    code: "en",
    flag: englishFlag,
  },
  "French": {
    label: "French",
    code: "fr",
    flag: frenchFlag,
  },
  "Dutch": {
    label: "Dutch",
    code: "nl",
    flag: dutchFlag,
  }
}

export default languages;
