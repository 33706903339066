import {
    GET_QUESTIONNAIRE_USER_DATA,
    GET_QUESTIONNAIRE_USER_DATA_FAIL,
    GET_QUESTIONNAIRE_USER_DATA_SUCCESS,
    ADD_NEW_QUESTIONNAIRE_USER_DATA,
    ADD_QUESTIONNAIRE_USER_DATA_SUCCESS,
    ADD_QUESTIONNAIRE_USER_DATA_FAIL,
    SUBMIT_QUESTIONNAIRE_USER_DATA,
    SUBMIT_QUESTIONNAIRE_USER_DATA_SUCCESS,
    SUBMIT_QUESTIONNAIRE_USER_DATA_FAIL,
    INCOMPLETE_QUESTIONNAIRE_USER_DATA,
    INCOMPLETE_QUESTIONNAIRE_USER_DATA_SUCCESS,
    INCOMPLETE_QUESTIONNAIRE_USER_DATA_FAIL,
    UPDATE_QUESTIONNAIRE_USER_STATUS,
    UPDATE_QUESTIONNAIRE_USER_STATUS_SUCCESS,
    UPDATE_QUESTIONNAIRE_USER_STATUS_FAIL,
} from "./actiontypes"


export const getQuestionnaireUserData = (user_data: any, history: any) => ({
    type: GET_QUESTIONNAIRE_USER_DATA,
    payload: { user_data, history },
})

export const getQuestionnaireUserDataSuccess = (data: any) => ({
    type: GET_QUESTIONNAIRE_USER_DATA_SUCCESS,
    payload: data,
})

export const getQuestionnaireUserDataFail = (data: any) => ({
    type: GET_QUESTIONNAIRE_USER_DATA_FAIL,
    payload: data,
})

export const addNewQuestionnaireUserData = (data: any) => ({
    type: ADD_NEW_QUESTIONNAIRE_USER_DATA,
    payload: data,
})

export const addQuestionnaireUserDataSuccess = (data: any) => ({
    type: ADD_QUESTIONNAIRE_USER_DATA_SUCCESS,
    payload: data,
})

export const addQuestionnaireUserDataFail = (error: any) => ({
    type: ADD_QUESTIONNAIRE_USER_DATA_FAIL,
    payload: error,
})

export const SubmitQuestionnaireUserData = (data: any) => ({
    type: SUBMIT_QUESTIONNAIRE_USER_DATA,
    payload: data,
})

export const SubmitQuestionnaireUserDataSuccess = (data: any) => ({
    type: SUBMIT_QUESTIONNAIRE_USER_DATA_SUCCESS,
    payload: data,
})

export const SubmitQuestionnaireUserDataFail = (error: any) => ({
    type: SUBMIT_QUESTIONNAIRE_USER_DATA_FAIL,
    payload: error,
})

export const IncompleteQuestionnaireUserData = (data: any) => ({
    type: INCOMPLETE_QUESTIONNAIRE_USER_DATA,
    payload: data,
})

export const IncompleteQuestionnaireUserDataSuccess = (data: any) => ({
    type: INCOMPLETE_QUESTIONNAIRE_USER_DATA_SUCCESS,
    payload: data,
})

export const IncompleteQuestionnaireUserDataFail = (error: any) => ({
    type: INCOMPLETE_QUESTIONNAIRE_USER_DATA_FAIL,
    payload: error,
})


export const updateQuestionnaireUserStatus = (data: any) => ({
    type: UPDATE_QUESTIONNAIRE_USER_STATUS,
    payload: data,
})

export const updateQuestionnaireStatusUserSuccess = (data: any) => ({
    type: UPDATE_QUESTIONNAIRE_USER_STATUS_SUCCESS,
    payload: data,
})

export const updateQuestionnaireStatusUserFail = (error: any) => ({
    type: UPDATE_QUESTIONNAIRE_USER_STATUS_FAIL,
    payload: error,
})