/* QUESTIONNAIRE */
export const GET_QUESTIONNAIRE = "GET_QUESTIONNAIRE"
export const GET_QUESTIONNAIRE_SUCCESS = "GET_QUESTIONNAIRE_SUCCESS"
export const GET_QUESTIONNAIRE_FAIL = "GET_QUESTIONNAIRE_FAIL"

/*QUESTIONNAIRE LIST */
export const GET_QUESTIONNAIRE_LIST = "GET_QUESTIONNAIRE_LIST"
export const GET_QUESTIONNAIRE_LIST_SUCCESS = "GET_QUESTIONNAIRE_LIST_SUCCESS"
export const GET_QUESTIONNAIRE_LIST_FAIL = "GET_QUESTIONNAIRE_LIST_FAIL"


/* QUESTIONNAIRE DETAIL*/
export const GET_QUESTIONNAIRE_DETAIL = "GET_QUESTIONNAIRE_DETAIL"
export const GET_QUESTIONNAIRE_DETAIL_SUCCESS = "GET_QUESTIONNAIRE_DETAIL_SUCCESS"
export const GET_QUESTIONNAIRE_DETAIL_FAIL = "GET_QUESTIONNAIRE_DETAIL_FAIL"

/**
 * add QUESTIONNAIRE
 */
export const ADD_NEW_QUESTIONNAIRE = "ADD_NEW_QUESTIONNAIRE"
export const ADD_QUESTIONNAIRE_SUCCESS = "ADD_QUESTIONNAIRE_SUCCESS"
export const ADD_QUESTIONNAIRE_FAIL = "ADD_QUESTIONNAIRE_FAIL"

/**
 * Edit QUESTIONNAIRE
 */
export const UPDATE_QUESTIONNAIRE = "UPDATE_QUESTIONNAIRE"
export const UPDATE_QUESTIONNAIRE_SUCCESS = "UPDATE_QUESTIONNAIRE_SUCCESS"
export const UPDATE_QUESTIONNAIRE_FAIL = "UPDATE_QUESTIONNAIRE_FAIL"

/**
 * Delete QUESTIONNAIRE
 */
export const DELETE_QUESTIONNAIRE = "DELETE_QUESTIONNAIRE"
export const DELETE_QUESTIONNAIRE_SUCCESS = "DELETE_QUESTIONNAIRE_SUCCESS"
export const DELETE_QUESTIONNAIRE_FAIL = "DELETE_QUESTIONNAIRE_FAIL"


/****
 *  get Questionnaire Full data
 */
export const GET_QUESTIONNAIRE_FULL_DATA = "GET_QUESTIONNAIRE_FULL_DATA"
export const GET_QUESTIONNAIRE_FULL_DATA_SUCCESS = "GET_QUESTIONNAIRE_FULL_DATA_SUCCESS"
export const GET_QUESTIONNAIRE_FULL_DATA_FAIL = "GET_QUESTIONNAIRE_FULL_DATA_FAIL"

/*** Send questionnaire from task */
export const SEND_QUESTIONNAIRE_FROM_TASK = "SEND_QUESTIONNAIRE_FROM_TASK"
export const SEND_QUESTIONNAIRE_FROM_TASK_SUCCESS = "SEND_QUESTIONNAIRE_FROM_TASK_SUCCESS"
export const SEND_QUESTIONNAIRE_FROM_TASK_ERROR = "SEND_QUESTIONNAIRE_FROM_TASK_ERROR"
