import { memo, useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, Table, Input } from "reactstrap";
//@ts-ignore
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";




const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: "none",
  background: isDragging ? "white" : "white",
  boxShadow: isDragging
    ? "0 0 .4rem #666"
    : "none",
  ...draggableStyle
});

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "white" : "white",
});

const reorder = (list: any, startIndex: number, endIndex: number) => {
  console.log('reorder', list, startIndex, endIndex);
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const DataTableSettingsModal = ({
  show,
  columns,
  hiddenColumnsData,
  sortColumnsData,
  onSubmitClick,
  onResetClick,
  onCloseClick,
  loading = false,
}: DataTableSettingProps) => {
  const [hiddenColumns, setHiddenColumns] = useState<Array<number>>([]);
  const [sortColumns, setSortColumns] = useState<Array<number>>([]);
  const [xcoloumn, setXcoloumn] = useState<any>([]);
  const [ischanges, setIsChange] = useState<boolean>(false);
  const { t } = useTranslation();
  const handleCheck = (idx: number) => {
    if (hiddenColumns.includes(idx)) {
      setHiddenColumns(hiddenColumns.filter((item: any) => item !== idx));
    } else {
      setHiddenColumns([...hiddenColumns, idx]);
    }
    setIsChange(true);
  };

  useEffect(() => {
    columns.map((_: any, index: number) => columns[index].id = index);
    setXcoloumn(columns);
    sortColumnsByUserSetting();
  }, [columns])


  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const items: any = reorder(xcoloumn, result.source.index, result.destination.index);
    setXcoloumn(items);
    const sortCol = Object.values(items).map((key: any) => key.id);
    console.log("headers", 'items pop up=> ', items, sortCol)
    setSortColumns(sortCol);
    setIsChange(true);
  }

  function sortFunc(a: any, b: any) {
    return sortColumnsData.indexOf(a.id) - sortColumnsData.indexOf(b.id);
  }

  const sortColumnsByUserSetting = () => {
    if (sortColumnsData.length > 0) {
      const sortCol = xcoloumn.sort(sortFunc);
      setXcoloumn(sortCol);
    }
  }

  useEffect(() => {
    setHiddenColumns(hiddenColumnsData);
  }, [hiddenColumnsData]);

  return (
    <Modal
      size="md"
      isOpen={show}
      toggle={onCloseClick}
      backdrop="static"
      keyboard={false}
      autoFocus={false}
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title">{t("List view settings")}</h5>
        <button
          type="button"
          className="btn-close"
          onClick={onCloseClick}
          aria-label="Close"
        ></button>
      </div>
      <ModalBody>

        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided: any, snapshot: any) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  className="text-left bg-white p-2"
                >
                  <div className="row">
                    <div className="col-3 p-2 border-top font-weight-bold">{t("Visibility")}</div>
                    <div className="col-9 p-2 border-top font-weight-bold">{t("Column name")}</div>
                  </div>
                  {(xcoloumn && xcoloumn.length > 0) && xcoloumn.map((item: any, index: number) =>
                    (item.hidden === true && item.text != '') ? null :
                      <Draggable key={`item-${item.id}`} draggableId={`item-${item.id}`} index={index}>
                        {(provided: any, snapshot: any) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                            className="row "
                          >
                            <div className="col-3 p-2 border-top">
                              <div className="form-check mb-3">
                                <Input
                                  id={`is_visible_${item.id}`}
                                  name="is_visible[]"
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultChecked={
                                    hiddenColumnsData &&
                                    !hiddenColumnsData.includes(item.id)
                                  }
                                  onClick={() => handleCheck(item.id)}
                                />
                              </div>
                            </div>
                            <div className="col-9 p-2 border-top">
                              {t(item.text)}
                            </div>
                          </div>
                        )}
                      </Draggable>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn btn-primary btn-md ms-2"
          onClick={() => {
            onSubmitClick &&
              onSubmitClick({
                hidden_columns: hiddenColumns,
                sort_columns: sortColumns,
              })
            setIsChange(false);
          }
          }
          disabled={loading || !ischanges}
        >
          {loading ? (
            <span>
              <i className="fa-solid fa-spinner fa-spin"></i>
              <span>&nbsp; Please wait...</span>
            </span>
          ) : (
            <span>Submit</span>
          )}
        </button>

        <button
          type="button"
          className="btn btn-outline-danger btn-md ms-2"
          onClick={onResetClick}
          disabled={loading}
        >
          {loading ? (
            <span>
              <i className="fa-solid fa-spinner fa-spin"></i>
              <span>&nbsp; Please wait...</span>
            </span>
          ) : (
            <span>Reset</span>
          )}
        </button>

        <button
          type="button"
          className="btn btn-outline-danger btn-md ms-2"
          onClick={onCloseClick}
        >
          Cancel
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default DataTableSettingsModal;
