import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Nav, NavItem } from "reactstrap";

// Import Breadcrumb
import Breadcrumbs from "../../components/Layout/Breadcrumb";
import AllTimeSheets from "../TimeSheets/AllTimeSheets";

import ScrollUp from "../../components/ScrollUpDown/ScrollUp";
import ScrollDown from "../../components/ScrollUpDown/ScrollDown";

import { useTranslation } from "react-i18next";
import { getUserProfile } from "../../helpers/functions_helper";

import NavItems from "./NavItemsTask";

import tasksService from "../../services/tasks.service";
import { toast } from "react-toastify";

const EditTaskTimesheetList = (props: any) => {
  const { t } = useTranslation();
  document.title = `${t("Update")} ${t("task")} | ` + process.env.REACT_APP_NAME;

  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "/tasks/kanban-view",
      label: t("Kanban view"),
      active: false,
    },
    {
      url: "/tasks/all-tasks",
      label: t("List view"),
      active: false,
    },
    {
      url: "#",
      label: `${t("Update")} ${t("task")}`,
      active: true,
    },
  ];

  const breadcrumbItemsExternalUser = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "/tasks/all-tasks",
      label: t("Tasks"),
      active: false,
    },
    {
      url: "#",
      label: `${t("Update")} ${t("task")}`,
      active: true,
    },
  ];

  const userProfile = getUserProfile("all-tasks");
  const { id, task_id } = useParams();
  const refId = { id, task_id };

  const [loading, setLoading] = useState(true);
  const [initialData, setInitialData] = useState({
    company: "",
    subtask_count: 0,
    invoice_count: 0,
  });

  const get = async () => {
    try {
      const res = (await tasksService.getTask({
        id,
        task_id,
      })) as any;
      if (res.data.status === "success") {
        setInitialData(res.data.data[0]);
        setLoading(false);
      } else {
        toast(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 5000,
          delay: 500,
        });
        setLoading(false);
      }
    } catch (err: any) {
      toast(err?.message, {
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        delay: 500,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    get();
  }, []);

  return (
    <React.Fragment>
      <ScrollUp />
      <ScrollDown />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={`${t("Update")} ${t("task")}`}
            breadcrumbItems={
              userProfile.USER_COMPANY === 1 ? breadcrumbItems : breadcrumbItemsExternalUser
            }
          />

          <NavItems
            id={id}
            task_id={task_id}
            company={initialData.company}
            subtask_count={initialData.subtask_count}
            invoice_count={initialData.invoice_count}
            loading={loading}
          />
          <AllTimeSheets task_id={refId} type="list" />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditTaskTimesheetList;
