import {
    GET_QUESTIONNAIRE_QUESTIONS,
    GET_QUESTIONNAIRE_QUESTIONS_FAIL,
    GET_QUESTIONNAIRE_QUESTIONS_SUCCESS,
    GET_QUESTIONS_LIST,
    GET_QUESTIONS_LIST_SUCCESS,
    GET_QUESTIONS_LIST_FAIL,
    GET_QUESTIONS_LIST_WITH_ID,
    GET_QUESTIONS_LIST_WITH_ID_SUCCESS,
    GET_QUESTIONS_LIST_WITH_ID_FAIL,
    GET_QUESTIONNAIRE_QUESTIONS_DETAIL,
    ADD_NEW_QUESTIONNAIRE_QUESTIONS,
    ADD_QUESTIONNAIRE_QUESTIONS_SUCCESS,
    ADD_QUESTIONNAIRE_QUESTIONS_FAIL,
    UPDATE_QUESTIONNAIRE_QUESTIONS,
    UPDATE_QUESTIONNAIRE_QUESTIONS_SUCCESS,
    UPDATE_QUESTIONNAIRE_QUESTIONS_FAIL,
    DELETE_QUESTIONNAIRE_QUESTIONS,
    DELETE_QUESTIONNAIRE_QUESTIONS_SUCCESS,
    DELETE_QUESTIONNAIRE_QUESTIONS_FAIL,
    GET_QUESTIONNAIRE_QUESTIONS_DETAIL_FAIL,
    GET_QUESTIONNAIRE_QUESTIONS_DETAIL_SUCCESS,
    GET_QUESTION,
    GET_QUESTION_SUCCESS,
    GET_QUESTION_ERROR,
} from "./actionTypes"
import QuestionModel from "./interface"
export const getQuestionnaireQuestions = (query: any, history: any) => ({
    type: GET_QUESTIONNAIRE_QUESTIONS,
    payload: { query, history },
})

export const getQuestionnaireQuestionsSuccess = (questions: any) => ({
    type: GET_QUESTIONNAIRE_QUESTIONS_SUCCESS,
    payload: questions,
})

export const getQuestionsById = (tabId: any, conditions: any, history: any) => ({
    type: GET_QUESTIONS_LIST_WITH_ID,
    payload: { tabId, conditions, history },
})

export const getQuestionsByIdSuccess = (questions: QuestionModel[]) => ({
    type: GET_QUESTIONS_LIST_WITH_ID_SUCCESS,
    payload: questions,
})

export const getQuestionsByIdFail = (questions: QuestionModel[]) => ({
    type: GET_QUESTIONS_LIST_WITH_ID_FAIL,
    payload: questions,
})

export const getQuestions = () => ({
    type: GET_QUESTIONS_LIST
})

export const getQuestionsSuccess = (questions: QuestionModel[]) => ({
    type: GET_QUESTIONS_LIST_SUCCESS,
    payload: questions,
})

export const getQuestionsFail = (questions: QuestionModel[]) => ({
    type: GET_QUESTIONS_LIST_FAIL,
    payload: questions,
})

export const addNewQuestionnaireQuestions = (statusdata: QuestionModel) => ({
    type: ADD_NEW_QUESTIONNAIRE_QUESTIONS,
    payload: { statusdata },
})

export const addQuestionnaireQuestionsSuccess = (statusdata: QuestionModel) => ({
    type: ADD_QUESTIONNAIRE_QUESTIONS_SUCCESS,
    payload: statusdata,
})

export const addQuestionnaireQuestionsFail = (error: any) => ({
    type: ADD_QUESTIONNAIRE_QUESTIONS_FAIL,
    payload: error,
})

export const updateQuestionnaireQuestions = (statusdata: QuestionModel, history: any) => ({
    type: UPDATE_QUESTIONNAIRE_QUESTIONS,
    payload: { statusdata, history },
})

export const updateQuestionnaireQuestionsSuccess = (statusdata: QuestionModel) => ({
    type: UPDATE_QUESTIONNAIRE_QUESTIONS_SUCCESS,
    payload: statusdata,
})

export const updateQuestionnaireQuestionsFail = (error: any) => ({
    type: UPDATE_QUESTIONNAIRE_QUESTIONS_FAIL,
    payload: error,
})

export const deleteQuestionnaireQuestions = (statusdata: any, history: any) => ({
    type: DELETE_QUESTIONNAIRE_QUESTIONS,
    payload: { statusdata, history },
})

export const deleteQuestionnaireQuestionsSuccess = (statusdata: any) => ({
    type: DELETE_QUESTIONNAIRE_QUESTIONS_SUCCESS,
    payload: statusdata,
})

export const deleteQuestionnaireQuestionsFail = (error: any) => ({
    type: DELETE_QUESTIONNAIRE_QUESTIONS_FAIL,
    payload: error,
})

export const getQuestionnaireQuestionsFail = (error: any) => ({
    type: GET_QUESTIONNAIRE_QUESTIONS_FAIL,
    payload: error,
})

export const getQuestionnaireQuestionsDetail = (statusId: any) => ({
    type: GET_QUESTIONNAIRE_QUESTIONS_DETAIL,
    statusId,
})

export const getQuestionnaireQuestionsDetailSuccess = (statusDetails: QuestionModel) => ({
    type: GET_QUESTIONNAIRE_QUESTIONS_DETAIL_SUCCESS,
    payload: statusDetails,
})

export const getQuestionnaireQuestionsDetailFail = (error: any) => ({
    type: GET_QUESTIONNAIRE_QUESTIONS_DETAIL_FAIL,
    payload: error,
})

// GET question by id 
export const getQuestion = (question: any, history: any) => {
    return {
        type: GET_QUESTION,
        payload: { question, history },
    };
};

export const getQuestionSuccess = (data: {}) => {
    return {
        type: GET_QUESTION_SUCCESS,
        payload: data,
    };
};

export const getQuestionError = (error: {}) => {
    return {
        type: GET_QUESTION_ERROR,
        payload: error,
    };
};