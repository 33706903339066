import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Buffer } from "buffer";
import { Button, Card, CardBody, Col, Container, Input, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap'

import { useUploader, UploadTypes } from './hooks/useUploader';
import { useAttachments } from './hooks/useAttachments';
import { Pdf, usePdf } from './hooks/usePdf';
import { MenuBar } from './components/MenuBar';
import { base64ToBlob, ggID } from './utils/helpers';
import { AttachmentTypes } from './entities';
import { Attachments } from './components/Attachments';
import { DrawingModal } from './components/DrawingModal';
import { getAsset } from './utils/prepareAssets';
import { changeStatusRefuse } from '../DocSignature/helper';
import axios from 'axios';
import { blobToFiles } from '../../../components/Common/Pdftotext';
import Loader from '../../../components/Layout/Loader';
import SendDocument from '../DocSignature/SendDocument';
import { useTranslation } from 'react-i18next';
import Drawer from '../../../components/Drawer';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import MultiComments from '../../../components/Common/MultiComments';
import { readAsDataURL, readAsImage } from './utils/asyncReader';
import { getAxiosConfigFileUploadWithToken, getUserProfile } from '../../../helpers/functions_helper';

import * as url from "../../../helpers/url_helper";
import documentTemplateService from '../../../services/document.template.service';
import commonService from '../../../services/common.service';
import Signature from '../../Users/Signature';
const DocEdit = (props: any) => {
    const { t } = useTranslation();
    const whiteboardRef = useRef<any>(null);
    const [drawingModalOpen, setDrawingModalOpen] = useState(false);
    const [commentModal, setCommentModal] = useState(false);
    const [comment, setComment] = useState("");
    const toastId = useRef(null) as any;
    const [allPageAttachments, setAllPageAttachments] = useState<any>([]);
    const [pageAttachments, setPageAttachments] = useState<any>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [type, setType] = useState(props.type);
    const [mandatComment, setMondatComment] = useState(false);
    const [commentAdded, setCommentAdded] = useState(false);
    const [enableSubmit, setEnableSubmit] = useState(false);

    const userProfile = getUserProfile();
    const [newAllAttachments, setNewAllAttachments] = useState<any>([]);
    const [savedFlag, setSavedFlag] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [style, setStyle] = useState({
        'fontFamily': 'Times-Roman',
        'color': "",
        'backgroundColor': ""
    });

    const [refuseModal, setRefuseModal] = useState(false);
    let navigate = useNavigate();
    const { file, setFile, initialize, pageIndex, isMultiPage, isFirstPage, isLastPage, currentPage, isSaving, savePdf, previousPage, nextPage, setDimensions, name, dimensions } = usePdf();
    const { add: addAttachment, reset: resetAttachments, update, remove, setPageIndex } = useAttachments();
    const initializePageAndAttachments = (pdfDetails: Pdf) => {
        if (pdfDetails) {
            initialize(pdfDetails);
            const numberOfPages = pdfDetails.pages.length;
            resetAttachments(numberOfPages);
            setTotalPages(numberOfPages);
        }
    };
    const { inputRef: pdfInput, handleClick: handlePdfClick, isUploading, onClick, upload: uploadPdf, reload: reloadPdf } = useUploader({
        use: UploadTypes.PDF,
        afterUploadPdf: initializePageAndAttachments,
    });
    const { inputRef: imageInput, handleClick: handleImageClick, onClick: onImageClick, upload: uploadImage, uploadSign: reloadImage } = useUploader({
        use: UploadTypes.IMAGE,
        afterUploadAttachment: addAttachment,
    });

    const [isComposeMail, setIsComposeMail] = useState<boolean>(false);

    const [templateData, setTemplateData] = useState<any>([]);
    const [title, setTitle] = useState("");
    const [curStatus, setCurStatus] = useState(null);
    const [attachmentId, setAttachmentId] = useState<any>(0);
    const [attachmentSubId, setAttachmentSubId] = useState<any>(0);
    const [moduleData, setModuleData] = useState({})

    useEffect(() => {
        setCommentAdded(false);
        if (props.templateData) {
            setModuleData({
                module_app_id: 14,
                module_main_id: props.id,
                module_sub_id: props?.template_id,
                section_id: 0,
                comment: "",
                comment_for_ids: "",
            })
            setTemplateData(props.templateData);
            if (props.templateData?.contact_person_id == userProfile.USER_ID) {
                setIsAdmin(false);
            } else {
                setIsAdmin(true);
            }

            if (props.template && props.viewOnly) {
                setAttachmentId(props.templateData?.attachment_id);
                setAttachmentSubId(props.templateData?.attachment_sub_id);
                handleUploadDocument(props.templateData?.attachment_id, props.templateData?.attachment_sub_id, props.templateData?.file_extension);
            } else if (props.template && !props.viewOnly) {
                const attachments = JSON.parse(props.templateData?.attachments);
                setNewAllAttachments(attachments);
                setAttachmentId(props.templateData?.attachment_id);
                setAttachmentSubId(props.templateData?.attachment_sub_id);
                handleUploadDocument(props.templateData?.attachment_id, props.templateData?.attachment_sub_id, props.templateData?.file_extension);
            } else if (props.templateData?.document_status == 1 && props.templateData) {
                setSavedFlag(true);
                setAttachmentId(props.templateData?.attachment_id);
                setAttachmentSubId(props.templateData?.attachment_sub_id);
                handleUploadDocument(props.templateData?.attachment_id, props.templateData?.attachment_sub_id, props.templateData?.file_extension);
                const attachments = JSON.parse(props.templateData?.attachments);
                setNewAllAttachments(attachments);
                setCurStatus(props.templateData?.status);
            } else if (props.templateData?.status == 3 && props.attachmentData) {
                setAttachmentId(props.templateData?.attachment_id);
                setAttachmentSubId(props.templateData?.attachment_sub_id);
                handleUploadDocument(props.templateData?.attachment_id, props.templateData?.attachment_sub_id, props.templateData?.file_extension);
                setCurStatus(props.templateData?.status);
            }
            else {
                setAttachmentId(props.templateData?.attachment_id);
                setAttachmentSubId(props.templateData?.attachment_sub_id);
                handleUploadDocument(props.templateData?.attachment_id, props.templateData?.attachment_sub_id, props.templateData?.file_extension);
            }
        }
    }, [props.templateData])


    const hiddenInputs = (
        <>
            <input
                data-testid="pdf-input"
                ref={pdfInput}
                type="file"
                name="pdf"
                id="pdf"
                accept="application/pdf"
                onChange={uploadPdf}
                onClick={onClick}
                style={{ display: 'none' }}
            />
            <input
                ref={imageInput}
                type="file"
                id="image"
                name="image"
                accept="image/*"
                onClick={onImageClick}
                style={{ display: 'none' }}
                onChange={(e: any) => uploadImageAttachment(e)}
            />
        </>
    )
    const addText = (text = "", x = 10, y = 10) => {
        const newTextAttachment: TextAttachment = {
            id: ggID(),
            type: AttachmentTypes.TEXT,
            x: x,
            y: y,
            width: 120,
            height: 25,
            size: 16,
            lineHeight: 1.4,
            fontFamily: 'Times-Roman',
            text: text,
            pageIndex: pageIndex,
            textOnly: true,
        };
        let newEle = [...allPageAttachments];
        if (!newEle[pageIndex]) {
            newEle[pageIndex] = [];
        }
        newEle[pageIndex].push(newTextAttachment);
        setAllPageAttachments(newEle);
        setPageAttachments(newEle[pageIndex]);
    };


    const updateStyle = (FontFamily: any) => {
        console.log("update Style", FontFamily);
    }



    const uploadImageAttachment = async (e: any) => {
        const file = e.target.files[0];
        const url = await readAsDataURL(file);
        const img = await readAsImage(url as string);
        const id = ggID();
        const imageAttachemnt: ImageAttachment = {
            id,
            type: AttachmentTypes.IMAGE,
            width: 200,
            height: 200,
            x: 0,
            y: 0,
            img,
            file,
            pageIndex: pageIndex
        };
        let newEle = [...allPageAttachments];
        if (!newEle[pageIndex]) {
            newEle[pageIndex] = [];
        }
        newEle[pageIndex].push(imageAttachemnt);
        setAllPageAttachments(newEle);
        setPageAttachments(newEle[pageIndex]);
    }


    const addDrawing = (drawing?: { width: number, height: number, path: string }) => {
        if (!drawing) return;

        const newDrawingAttachment: DrawingAttachment = {
            id: ggID(),
            type: AttachmentTypes.DRAWING,
            ...drawing,
            x: 10,
            y: 10,
            scale: 1,
            pageIndex: pageIndex
        }
        let newEle = [...allPageAttachments];
        if (!newEle[pageIndex]) {
            newEle[pageIndex] = [];
        }
        newEle[pageIndex].push(newDrawingAttachment);
        setAllPageAttachments(newEle);
        setPageAttachments(newEle[pageIndex]);
    }

    const signatureAdded = (siganture: any, data: any) => {
        if (siganture) {
            setEnableSubmit(true);
            const img = new Image();
            img.src = siganture;
            img.onload = () => {
                const blob = base64ToBlob(img.src);
                const newImageAttachment: any = {
                    id: ggID(),
                    type: AttachmentTypes.IMAGE,
                    file: blob as File,
                    img: img, // Include the Image element
                    width: 200,
                    height: 200,
                    x: 10,
                    y: 10,
                    pageIndex: pageIndex,
                    data: data
                };
                let newEle = [...allPageAttachments];
                if (!newEle[pageIndex]) {
                    newEle[pageIndex] = [];
                }
                newEle[pageIndex].push(newImageAttachment);
                setAllPageAttachments(newEle);
                setPageAttachments(newEle[pageIndex]);
            };
        }
    }

    const [docStatus, setDocStatus] = useState(0);
    const addComposeMail = (type: any) => {
        setDocStatus(type);
        setTitle((type == 2) ? t("SEND") : t("SAVE"));
        //handleSavePdf();
        // if (type == 2) {
        //     setIsComposeMail(true);
        // }
        // else {
        //     setIsComposeMail(false);
        if (!savedFlag) {
            setIsComposeMail(!isComposeMail);
        } else {
            const data = {
                id: templateData?.id,
                doc_id: templateData?.doc_signature_id,
                company_id: templateData?.company_id,
                user_id: templateData?.contact_person_id,
                doc_signature_id: templateData?.id,
                document_name: templateData.filename ? templateData.filename : props.templateData?.document_name,
                template_id: templateData?.id,
                due_date: templateData?.due_date,
                expiry_date: templateData?.expiry_date,
                doc_status: type,
                document_status: type,
                multiple_user_id: templateData?.multiple_user_id,
                user_id_cc: templateData?.user_id_cc,
                type: type
            };
            create(data);
        }
    }


    const [fileData, setFileData] = useState("");
    const fetchData = async () => {
        await savePdf(allPageAttachments).then((dt: any) => {
            setFileData((fileData: any) => {
                return {
                    ...fileData,
                    ...dt
                };
            });
        });
    }

    useEffect(() => {
        fetchData();
    }, [allPageAttachments])
    // const handleSavePdf = async () => {
    //     await savePdf(allPageAttachments).then((dt: any) => {
    //         setFileData(dt);
    //     });
    //     /// reloadPdf(file_data);
    // };
    useLayoutEffect(() => setPageIndex(pageIndex), [pageIndex, setPageIndex]);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [width, setWidth] = useState((dimensions && dimensions.width) || 0);
    const [height, setHeight] = useState((dimensions && dimensions.height) || 0);

    useEffect(() => {
        if (currentPage) {
            const renderPage = async (p: Promise<any>) => {
                const _page = await p;
                if (_page) {
                    const context = canvasRef.current?.getContext('2d');
                    const viewport = _page.getViewport({ scale: 1.50 });

                    setWidth(viewport.width);
                    setHeight(viewport.height);

                    if (context) {
                        await _page.render({
                            canvasContext: canvasRef.current?.getContext('2d'),
                            viewport,
                        }).promise;

                        const newDimensions = {
                            width: viewport.width,
                            height: viewport.height,
                        };

                        setDimensions(newDimensions as Dimensions);
                    }
                }
            };
            renderPage(currentPage);
        }

    }, [currentPage, pageIndex]);



    const handleUploadDocument = async (id: any, attachment_id: any, file_extension: any) => {
        const SERVER_URL = process.env.REACT_APP_SERVER_URL;
        const AUTH_USER = localStorage.getItem("authUser");
        const ACCESS_TOKEN = JSON.parse(String(AUTH_USER));
        await axios({
            url:
                SERVER_URL +
                "/common/download-attachment/" +
                Buffer.from(String(id)).toString("base64") +
                "/" +
                Buffer.from(String(attachment_id)).toString("base64"),
            method: "GET",
            headers: {
                "Content-Type": "application/" + file_extension,
                "Access-Control-Allow-Origin": "*",
                Authorization: "Bearer " + ACCESS_TOKEN.accessToken,
            },
            responseType: "arraybuffer",
        }).then((response: any) => {
            if (response.data) {
                blobToFiles(response.data, 'my_file.pdf', 'application/pdf').then(async (file: any) => {
                    reloadPdf(file);
                });
            }
        });
    }

    const downloadImage = async () => {
        const download = await getAsset('download');
        await savePdf(allPageAttachments).then((dt: any) => {
            download(dt, 'download.pdf', 'application/pdf');
        });
    }

    const addRefuseModal = () => {
        setRefuseModal(true);
    }

    const updateCommentAdded = () => {
        setCommentAdded(true);
    }


    const onClickRefuse = async () => {
        if (!commentAdded) {
            setMondatComment(true);
        } else {
            onClickRefuseSubmit();
        }

    }


    const addComment = async () => {
        const values = {
            module_app_id: 14,
            module_main_id: props.id,
            module_sub_id: props?.template_id,
            section_id: 0,
            comment_for_ids: [],
            comment: comment
        }
        const res = (await commonService.createComment(values)) as any;
        if (res.data.status === "success") {
            onClickRefuseSubmit();
        } else {
            toast(t("Something went wrong, please try again."), {
                type: toast.TYPE.ERROR,
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });
        }
    }


    const onClickRefuseSubmit = async () => {
        toastId.current = toast(t("Changing status..."), {
            autoClose: false,
            isLoading: true,
        });
        const result = await changeStatusRefuse({ id: props.id, doc_id: props.template_id, comment, status: 2 });
        if (result.data.status == "success" && result.data) {
            toast.update(toastId.current, {
                type: toast.TYPE.SUCCESS,
                render: t("Document status changed to refused status"),
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });
            setRefuseModal(false);
            const formData = new FormData();
            formData.append("file_idx", "0");
            formData.append("module_app_id", "14");
            formData.append("module_main_id", props.id);
            formData.append("module_sub_id", props.template_id);
            formData.append("section_id", "");
            formData.append("file_name", "");
            formData.append("file_extension", 'pdf');
            formData.append("file_path", 'user_document');
            formData.append(
                "short_description",
                ""
            );
            formData.append(
                "attachment_for_ids", ""
            );
            formData.append("type", "file");
            sendEmailandNotification(formData);
            return navigate("/client-onboarding/doc-signature/list");
        }
    }



    useEffect(() => {
        setPageAttachments(allPageAttachments[pageIndex]);
    }, [pageIndex])

    const updateAttachment = (attachment: any) => {
        const attahmentUp: any = [];
        allPageAttachments.forEach((pageAttachments: any, pageIndex: any) => {
            attahmentUp[pageIndex] = [];
            pageAttachments.forEach((atta: any, index: any) => {
                if (attachment.pageIndex === pageIndex && index === attachment.index) {
                    const newEle = { ...atta, ...attachment };
                    // console.log(newEle);
                    attahmentUp[pageIndex][index] = newEle;
                } else {
                    attahmentUp[pageIndex][index] = atta;
                }
            });
        })
        setAllPageAttachments(attahmentUp);
        setPageAttachments(attahmentUp[pageIndex]);
    }

    const removeAttachment = (index: any, pageIndex: any) => {
        const newAllPageAttachmentsRemove = allPageAttachments.map(
            (otherPageAttachments: any, page_index: any) =>
                page_index === pageIndex
                    ? pageAttachments.filter(
                        (attachment: any, _attachmentIndex: any) =>
                            _attachmentIndex !== index
                    )
                    : otherPageAttachments);
        setAllPageAttachments(newAllPageAttachmentsRemove);
        setPageAttachments(newAllPageAttachmentsRemove[pageIndex]);
    }


    useEffect(() => {
        if (newAllAttachments && newAllAttachments.length) {
            const attachear: any = [];
            newAllAttachments.forEach((element: any, indexelement: any) => {
                attachear[indexelement] = [];

                element.forEach((ele: any, indexele: any) => {
                    const newEle = { ...ele };
                    if (ele.type === "image") {
                        const img = new Image();
                        img.src = ele.imgstr;
                        // img.onload = () => {

                        // };

                        //    createImageElementFromBase64(ele.imgstr).then((imgEl:any)=>{
                        //     newEle.img = img;
                        //    });
                        newEle.img = img;
                        newEle.file = base64ToBlob(ele.imgstr);
                        newEle.id = ggID();
                        delete newEle.imgstr;
                    }

                    attachear[indexelement][indexele] = newEle;
                });
            });
            setAllPageAttachments(attachear);
            setPageAttachments(attachear[pageIndex]);
        }

    }, [newAllAttachments]);

    useEffect(() => {
        if (!allPageAttachments.length) {
            let allAttachments: any = [];
            for (let i = 0; i < totalPages; i++) {
                allAttachments[i] = [];
            }
            setAllPageAttachments(allAttachments);
            setPageAttachments([]);
        }

    }, [totalPages])


    const create = async (intialData: any) => {
        const msg = t("Updating document");
        try {
            toastId.current = toast(msg, {
                autoClose: false,
                isLoading: true,
            });
            const attachments = allPageAttachments;
            const attachear: any = [];
            attachments.forEach((element: any, indexelement: any) => {
                attachear[indexelement] = [];

                element.forEach((ele: any, indexele: any) => {
                    const newEle = { ...ele }; // Create a copy of the element

                    if (ele.type === "image") {
                        newEle.imgstr = ele.img.currentSrc; // Assign currentSrc to imgstr property
                        delete newEle.img; // Remove the img property to avoid circular JSON structure
                    }

                    attachear[indexelement][indexele] = newEle;
                });
            });
            const template_id = (type == "document" ? props.attachmentData?.id : props.templateData.id);
            const input_data = {
                ...intialData,
                template_id: template_id,
                attachments: JSON.stringify(attachear),
                id: props.id,
            };
            const res = (await documentTemplateService.sendDocument(input_data)) as any;
            if (res.data.status === "success") {
                if (input_data.doc_status == 2) {
                    const user_data = res.data?.data?.users;
                    user_data.forEach((element: any) => {
                        uploadAttachment(element); // upload doc on send only
                    });
                    toast.update(toastId.current, {
                        type: toast.TYPE.SUCCESS,
                        render: t("Document submitted successfully."),
                        autoClose: 5000,
                        isLoading: false,
                        delay: 500,
                    });
                } else {
                    toast.update(toastId.current, {
                        type: toast.TYPE.SUCCESS,
                        render: t("Document saved successfully."),
                        autoClose: 5000,
                        isLoading: false,
                        delay: 500,
                    });
                    return navigate("/client-onboarding/doc-signature/list");
                }
            } else {
                toast.update(toastId.current, {
                    type: toast.TYPE.ERROR,
                    render: res.message,
                    autoClose: 5000,
                    isLoading: false,
                    delay: 500,
                });

                if (res.data.data) {
                    Object.keys(res.data.data).map((key) => {
                        toast(res.data.data[key][0], {
                            type: toast.TYPE.ERROR,
                            autoClose: 5000,
                            delay: 500,
                        });
                    });
                }
                //setLoading(false);
            }
        } catch (err: any) {
            toast.update(toastId.current, {
                type: toast.TYPE.ERROR,
                render: err?.message,
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });
            //setLoading(false);
        }
    };


    const uploadAttachment = async (data: any, template= false) => {
        if (data) {
            await savePdf(allPageAttachments).then((dt: any) => {
                blobToFiles(dt, data.filename + '.pdf', 'application/pdf').then((file: any) => {
                    upload(data, file);
                });
            });
            if(!template)
            return navigate("/client-onboarding/doc-signature/list");
        }

    }

    const upload = async (data: any, file_data: any) => {
        const formData = new FormData();
        formData.append("file_idx", "0");
        formData.append("module_app_id", "14");
        formData.append("module_main_id", data.id);
        formData.append("module_sub_id", data.doc_signature_id);
        formData.append("section_id", data.section_id ? data.section_id : 0);
        formData.append("file_name", file_data as any);
        formData.append("file_extension", 'pdf');
        formData.append("file_path", 'user_document');
        formData.append(
            "short_description",
            ""
        );
        formData.append(
            "attachment_for_ids", ""
        );
        formData.append("type", "file");
        if (attachmentId && attachmentSubId) {
            formData.append("id", attachmentId);
            formData.append("attachment_id", attachmentSubId);
        }
        const SERVER_URL = process.env.REACT_APP_SERVER_URL;
        await axios.post(SERVER_URL + url.UPLOAD_ATTACHMENT, formData, {
            headers: getAxiosConfigFileUploadWithToken()
        }).then(function (response: any) {
            if (response.data.status == "success" && !data.section_id) {
                sendEmailandNotification(formData);
            }
        })
    }

    const sendEmailandNotification = async (data: any) => {
        const res = (await documentTemplateService.sendNotificationEmail(data)) as any;
        if (res.data.status === "success") {

        }
    }

    const submitDocument = async () => {
        toastId.current = toast("Submitting document", {
            autoClose: false,
            isLoading: true,
        });
        const result = await changeStatusRefuse({ id: props.id, doc_id: props.template_id, comment, status: 3 });
        if (result.data.status == "success" && result.data) {
            toast.update(toastId.current, {
                type: toast.TYPE.SUCCESS,
                render: result.data.message,
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });
            uploadAttachment(result.data.data);
        }
    }


    const finalizeDocument = async () => {
        toastId.current = toast(t("Finalizing document"), {
            autoClose: false,
            isLoading: true,
        });
        const result = await changeStatusRefuse({ id: props.id, doc_id: props.template_id, comment, status: 4 });
        if (result.data.status == "success" && result.data) {
            toast.update(toastId.current, {
                type: toast.TYPE.SUCCESS,
                render: t("Document status changes to finalized."),
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });
            return navigate("/client-onboarding/doc-signature/list");
        }
    }


    const submitTemplate = async () => {
        toastId.current = toast(t("Updating template"), {
            autoClose: false,
            isLoading: true,
        });
        const attachear: any = [];
        allPageAttachments.forEach((element: any, indexelement: any) => {
            attachear[indexelement] = [];

            element.forEach((ele: any, indexele: any) => {
                if (ele.x) {
                    const newEle = { ...ele }; // Create a copy of the element

                    if (ele.type === "image") {
                        newEle.imgstr = ele.img.currentSrc; // Assign currentSrc to imgstr property
                        delete newEle.img; // Remove the img property to avoid circular JSON structure
                    }

                    attachear[indexelement][indexele] = newEle;
                }
            });
        });
        const input_data = {
            template_id: props.template_id,
            attachments: JSON.stringify(attachear),
            id: props.id,
            type: (templateData && templateData.type) || "",
            toggle: (templateData && templateData.toggle == 1 ? true : false) || false,
            doc_type: (templateData && templateData.doc_type) || 1,
            document_name: (templateData && templateData.document_name) || "",
            description: (templateData && templateData.description) || "",
        };

        const res = (await documentTemplateService.update(input_data)) as any;
        if (res.status === "success") {
            const input_data_upload = {
                doc_signature_id: input_data.template_id,
                id: input_data.id,
                section_id: 2,
                filename: input_data.document_name
            };
            uploadAttachment(input_data_upload, true);
            setTimeout(function () {
                toast.update(toastId.current, {
                    type: toast.TYPE.SUCCESS,
                    render: res.message,
                    autoClose: 5000,
                    isLoading: false,
                    delay: 500,
                });
                return navigate("/client-onboarding/template/list");
            }, 3000);
        } else {
            toast.update(toastId.current, {
                type: toast.TYPE.ERROR,
                render: res.data.message,
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });
        }
    }

    const getComments = () => {
        setCommentModal(true)
    }


    return (
        <>
            {hiddenInputs}
            <MenuBar
                //savePdf={handleSavePdf}
                addText={addText}
                addImage={handleImageClick}
                addDrawing={() => setDrawingModalOpen(true)}
                savingPdfStatus={isSaving}
                uploadNewPdf={handlePdfClick}
                isPdfLoaded={!!file}
                addComposeMail={addComposeMail}
                isAdmin={isAdmin}
                id={props.id}
                template_id={props.template_id}
                downloadImage={downloadImage}
                addRefuseModal={addRefuseModal}
                signatureAdded={signatureAdded}
                submitDocument={submitDocument}
                finalizeDocument={finalizeDocument}
                curStatus={curStatus}
                setDocStatus={setDocStatus}
                template={props.template}
                submitTemplate={submitTemplate}
                viewOnly={props.viewOnly}
                getComments={getComments}
                updateStyle={updateStyle}
                templateData={templateData}
                enableSubmit={enableSubmit}
            />


            {!file ? (
                <Loader />
            ) :
                <Row className={!props.popup ? "docview" : ""}>
                    <Col xs={1}></Col>
                    <Col xs={1} className='align-self-center'>
                        {isMultiPage && (
                            <Button size="sm" color="primary" onClick={previousPage} disabled={isFirstPage}><span className="align-middle material-symbols-outlined fw-light fs-4">arrow_back</span>&nbsp;{t("Prev")}</Button>
                        )}
                    </Col>
                    <Col xs={8}>
                        {currentPage && (
                            <div
                                data-testid="page"
                                ref={whiteboardRef}
                            >
                                <div
                                    style={{ position: 'relative' }}
                                    key={pageIndex}
                                >
                                    <canvas ref={canvasRef} width={width} height={height} style={{ border: '1px solid' }}/>
                                    {/* <Page
                                                            dimensions={dimensions}
                                                            updateDimensions={setDimensions}
                                                            page={currentPage}
                                                            pageIndex={pageIndex}
                                                        /> */}
                                    {dimensions && (
                                        <Attachments
                                            pdfName={name}
                                            removeAttachment={removeAttachment}
                                            updateAttachment={updateAttachment}
                                            pageDimensions={dimensions}
                                            attachments={allPageAttachments[pageIndex]}
                                            canvasRef={canvasRef}
                                            pageIndex={pageIndex}
                                            width={dimensions.width}
                                            height={dimensions.height}
                                            x={dimensions.x + 10}
                                            y={dimensions.y}
                                            style={style}
                                            setDimensions={setDimensions}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </Col>
                    <Col xs={1} className='align-self-center'>
                        {isMultiPage && (
                            <Button size="sm" color="primary" onClick={nextPage}
                                disabled={isLastPage}
                            >&nbsp;{t("Next")}<span className="align-middle material-symbols-outlined fw-light fs-4">arrow_forward</span></Button>

                        )}
                    </Col>
                    <Col xs={1}></Col>
                </Row>
            }
            <DrawingModal
                open={drawingModalOpen}
                dismiss={() => setDrawingModalOpen(false)}
                confirm={addDrawing}
            />
            {isComposeMail &&
                <Modal
                    size="lg"
                    isOpen={isComposeMail}
                    toggle={() => setIsComposeMail(!isComposeMail)}
                    backdrop="static"
                    keyboard={false}
                    autoFocus={false}
                >
                    <div className="modal-header">
                        <h5 className="modal-title">{(docStatus == 2) ? t("Send document") : t("Save document")}</h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => setIsComposeMail(false)}
                            aria-label={t("Close")}
                        ></button>
                    </div>
                    <ModalBody>
                        <SendDocument templateData={templateData} file={fileData} title={title} attachments={allPageAttachments} pageAttachments={pageAttachments} type={docStatus} create={create}
                            setIsComposeMail={setIsComposeMail} />
                    </ModalBody>

                </Modal>
            }
            {/* {refuseModal &&
                <Drawer
                    title={t('Edit Document')}
                    open={refuseModal}
                    onClose={() => { setRefuseModal((state) => !state); }}
                    fullWidth={false}
                >
                    <Col xl={12} lg={12} md={12} sm={12}>
                        <MultiComments
                            moduleData={moduleData}
                            fetchData={true}
                            isOpen={false}
                            hiddenColumns={[3]}
                        />
                    </Col>
                    <div className="ms-auto d-flex gap-2 justify-content-end">
                        <Button className='btn-warning' onClick={onClickRefuse}>{t("Refuse")}</Button>
                        <Button className='btn-danger' onClick={() => { setRefuseModal((state) => !state); }}>{t("Cancel")}</Button>
                    </div>
                </Drawer>
            } */}



            {commentModal &&
                <Drawer
                    title={t('Comments')}
                    open={commentModal}
                    onClose={() => { setCommentModal((state) => !state); }}
                    fullWidth={false}
                >
                    <Col xl={12} lg={12} md={12} sm={12}>
                        <MultiComments
                            moduleData={moduleData}
                            fetchData={true}
                            isOpen={false}
                            onSuccess={updateCommentAdded}
                            hiddenColumns={[3]}
                        />
                    </Col>
                </Drawer>
            }

            {
                refuseModal &&
                <Modal isOpen={refuseModal} toggle={() => setRefuseModal(!refuseModal)} centered={true}>
                    <div className="modal-header">
                        <h5 className="modal-title">
                            {t("Refuse document")}
                        </h5>
                    </div>
                    <ModalBody>
                        <div className="mb-3">
                            <Label className="form-label">
                                {t("Reason for refuse")}
                            </Label>
                            <Input
                                type='textarea'
                                id={`reason`}
                                name='reason'
                                maxLength={500}
                                placeholder={t('Enter reason')}
                                onChange={(e) => {
                                    setComment(e.target.value);
                                }}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button"
                            className="btn btn-primary btn-md ms-2"
                            disabled={comment === ""}
                            onClick={addComment}
                        >
                            {t("Submit")}
                        </button>

                    </ModalFooter>
                </Modal >

            }
        </>
    )
}

export default DocEdit