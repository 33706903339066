import React, { useEffect, useState } from 'react';
import labelPlugin from "flatpickr/dist/plugins/labelPlugin/labelPlugin";
// import Select from "react-select";
import Select from '../../components/Form/Select/Select'
import { Badge, Button, ButtonGroup, Col, FormFeedback, FormGroup, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter, Nav, NavItem, Row, Table } from 'reactstrap';
// import Flatpickr from "react-flatpickr";
import Flatpickr from '../../components/Form/Flatpickr';
import Input from '../../components/Form/Input';
import { formatCurrency } from '../../utils/formats';
import commonService from '../../services/common.service';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
// @ts-ignore
import CustomEditor from "ckeditor5-custom-build/build/ckeditor";
// @ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { addIndividualInCompany, ckEditorConfiguration, strToSecond, datePickerI18, getUserProfile } from "../../helpers/functions_helper";
import MultiComments from '../../components/Common/MultiComments';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RadioInput from '../Questionnaire/RadioInput';
import { FieldArray } from 'formik';
import Drawer from '../../components/Drawer';
import ViewInvoice from './ViewInvoice';
import DropzoneUpload from '../../components/Common/Dropzone';
import DatePicker from '../../components/Form/DatePicker/DatePicker';
import invoicesService from '../../services/invoices.service';
import AllTimeSheets from '../TimeSheets/AllTimeSheets';
import Confirm from '../../components/Common/Confirm';
import { toast } from 'react-toastify';

const trans = [
    {
        code: 1,
        dear: 'Dear',
        body: '<div><p>We have the pleasure to provide you here below with our invoice for services rendered up to day.</p></div>'
    },
    {
        code: 2,
        dear: 'Chère',
        body: '<div><p>Nous avons le plaisir de vous fournir ci-dessous notre facture pour les prestations rendues à ce jour.</p></div>'
    },
    {
        code: 3,
        dear: 'Beste',
        body: '<div><p>Wij hebben het genoegen u hieronder onze factuur te verstrekken voor de diensten die tot op de dag van vandaag zijn geleverd.</p></div>'
    }
]

const InvoiceForm = ({ form, emptyProduct, isCreditNote = false, reloadData }: any) => {
    const { values, errors, touched, setFieldTouched, setFieldValue, setFieldError, handleChange, } = form;

    const { t } = useTranslation();
    const navigate = useNavigate();
    const toastId = React.useRef(null) as any;

    const {
        getCompanyOptionsList
    } = useSelector((state: any) => ({
        getCompanyOptionsList: state.Common.GET_COMPANY_OPTIONS_LIST_SUCCESS.data,
    }));

    const emptyTimesheet = {
        collaborator: "Super Admin",
        description: "",
        end_time: null,
        fees: 750,
        hourly_rate: 295,
        invoice_details_id: 13825,
        invoice_id: 538,
        invoice_timesheet_id: "256Z8MT7I3",
        responsible_person: "",
        service_charge: 590,
        start_date: "",
        start_time: null,
        sub_task_id: null,
        task_id: 750,
        time_spend: "00:00:00",
        timesheet_id: 1202,
        title: "",
    }

    const userObj = getUserProfile();

    // let index = 0;

    const [companyOption, setCompanyOption] = useState([]);
    const [projectOption, setProjectOption] = useState([]);
    const [taskOptions, setTaskOptions] = useState([]);
    const [contactPersonOption, setContactPersonOption] = useState([]);
    const [consultPersonOption, setConsultPersonOption] = useState([]);
    const [loadingCompany, setloadingCompanyOption] = useState(true);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingContactPerson, setloadingContactPerson] = useState(true);
    const [loadingFeesTypeOptions, setLoadingFeesTypeOptions] = useState(true);
    const [feesTypeOptions, setFeesTypeOptions] = useState([]);
    const [query, setQuery] = useState("");
    const [type, setType] = useState<string>('PRODUCTS');
    const [amountType, setAmountType] = useState<number>(1);
    const [expandCreditNote, setExpandCreditNote] = useState<boolean>(false);
    const [openInvoice, setOpenInvoice] = useState<boolean>(false);
    const [selectedInvoice, setSelectedInvoice] = useState<any>();
    const [selectedTask, setSelectedTask] = useState<any>(null);
    const [deletedItems, setDeletedItems] = useState<any>({ details: [], timesheets: [] });
    const [customErrors, setCustomErrors] = useState<any>();
    const [isEditing, setEditing] = useState<boolean>(false);
    const [carryForwardModal, setCarryForwardModal] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [deleteTimesheetModal, setDeleteTimesheetModal] = useState<boolean>(false);
    const [deleteAllTimesheetModal, setDeleteAllTimesheetModal] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<number>(-1);
    const [selectedCarryForwardInvoice, setSelecteCarryForwardInvoice] = useState<any>();

    const getSelectOptions = async (tableName: string, fieldName: string, otherField?: Array<string>) => {
        const response = await commonService.getSelectOptionsList(
            tableName,
            fieldName,
            query ? query : "",
            otherField
        );
        return response.data.data;
    };

    const getDependentSelectOptions = async (
        tableName: string,
        fieldName: string,
        whereField: string,
        whereID: number
    ) => {
        const response = await commonService.getDependentSelectOptionsList(
            tableName,
            fieldName,
            whereField,
            whereID,
            query ? query : ""
        );
        return response.data.data;
    };

    const getTaskByCompany = async (data: any) => {
        const response = await commonService.getTaskByCompany(data);
        return response.data.data;
    };


    const handleAddRow = () => {
        setFieldValue('details', [...values?.details, emptyProduct]);
    }

    const handleAddTimesheet = (details: any) => {

        let temp = [...values?.timesheets, emptyTimesheet];
        // console.log("temp timesheets", temp)
        setFieldValue('timesheets', temp);
    }

    const handleOpenInvoice = (invoice: any) => {
        setSelectedInvoice(invoice);
        setOpenInvoice(true);
    }

    const handleDeleteRow = (index: number) => {
        if (!deleteModal) {
            setSelectedRow(index);
            setDeleteModal(true);
        } else {
            setDeleteModal(false);
            const id = values?.details.find((d: ProductType, i: number) => i === index)?.id;
            const temp = values?.details.filter((d: ProductType, i: number) => i !== index);
            const tempTimeSheet = values?.timesheets?.filter((d: any, i: number) => d.invoice_details_id !== id);
            const tempAdd = temp?.length === 0 ? [emptyProduct] : temp;
            setFieldValue('details', [...tempAdd]);
            setFieldValue('timesheets', [...tempTimeSheet]);

            const deletedDetails = values?.details.filter((d: ProductType, i: number) => i === index);
            const deletedTimesheet = values?.timesheets?.filter((d: any, i: number) => d.invoice_details_id === id);
            setDeletedItems((state: any) => ({ ...state, details: [...state?.details, ...deletedDetails], timesheets: [...state?.timesheets, ...deletedTimesheet] }))

            setFieldValue('')
            setSelectedRow(-1);
        }
    }

    const handleDeleteTimeSheet = (id: number) => {
        if (!deleteTimesheetModal) {
            setSelectedRow(id);
            setDeleteTimesheetModal(true)
        } else {
            setDeleteTimesheetModal(false);
            const tempTimeSheet = values?.timesheets?.filter((d: any, i: number) => d.id !== id);
            setFieldValue('timesheets', [...tempTimeSheet]);

            const deletedTimesheet = values?.timesheets?.filter((d: any, i: number) => d.id === id);
            setDeletedItems((state: any) => ({ ...state, timesheets: [...state?.timesheets, ...deletedTimesheet] }))
            setSelectedRow(-1);
        }
    }

    const handleDeleteAllTimeSheet = (id: number) => {
        if (!deleteAllTimesheetModal) {
            setSelectedRow(id);
            setDeleteAllTimesheetModal(true)
        } else {
            setDeleteAllTimesheetModal(false)
            const tempTimeSheet = values?.timesheets?.filter((d: any, i: number) => d.invoice_details_id !== id);
            setFieldValue('timesheets', [...tempTimeSheet]);

            const deletedTimesheet = values?.timesheets?.filter((d: any, i: number) => d.invoice_details_id === id);
            setDeletedItems((state: any) => ({ ...state, timesheets: [...state?.timesheets, ...deletedTimesheet] }))
            setSelectedRow(-1);
        }
    }


    const calculateSubtotal = (qty: number, price: number, discount: number, tax: number) => {
        // alert(`${qty} :: ${price} :: ${discount} :: ${tax}`)
        let total = (qty * price);
        let withDisc = total - ((total * discount) / 100);
        let afterTax = withDisc + ((withDisc * tax) / 100);
        return withDisc;
    }

    const handleCalculation = () => {

        let servicesTotal = 0;
        if (values?.is_hourly_rate === 0) {
            servicesTotal = values?.details?.reduce((tot: number, cur: any) => tot + parseFloat(cur.subtotal), 0);
        }
        if (values?.is_hourly_rate === 1) {
            servicesTotal = values?.timesheets?.reduce((tot: number, cur: any) => tot + parseFloat(cur.service_charge), 0);
        }
        if (values?.is_hourly_rate === 2) {
            // servicesTotal = values?.timesheets?.reduce((total: number, acc: any) => total + acc?.service_charge, 0) + values.details.reduce((tot: number, cur: any) => tot + parseFloat(cur.subtotal), 0);
            // servicesTotal = values?.details?.reduce((tot: number, cur: any) => tot + parseFloat(cur.subtotal), 0);
            values.details.forEach((a: any, i: number) => {
                if (a?.is_hourly) {
                    servicesTotal += a?.timesheets?.filter((ts: any) => !deletedItems?.timesheets?.find((d: any) => d.id === ts?.id))?.reduce((total: number, acc: any) => total + acc?.service_charge, 0);
                    // console.log("servicesTotal", servicesTotal, deletedItems, a?.timesheets?.filter((ts: any) => !deletedItems?.timesheets?.find((d: any) => d.id === ts?.id)))
                } else {
                    servicesTotal += a?.subtotal;
                }
            })
        }



        const total = servicesTotal + Number(values?.expenses);
        const taxAmt = (total * values.tax_rate) / 100;
        const dueAmt = (taxAmt + total) - values?.paid_amount;

        setFieldValue('sub_total', servicesTotal);
        setFieldValue('total', total);
        setFieldValue('tax_amount', taxAmt);
        setFieldValue('amount_due', dueAmt);
        setFieldValue('final_amount_due', dueAmt);
    }

    const formatOptionLabel = ({ value, label, status, budget_amount, is_budget, total }: any) => (
        <Row>
            <Col className='font-size-14'>{label}</Col>
            {/* <Col className='text-muted'>
                {value}
            </Col> */}
            <Col className={`right-align ${budget_amount <= 0 ? 'text-danger' : 'text-success'} font-size-15`}>
                {is_budget ? 'Budget: ' + formatCurrency(budget_amount) : ''}
            </Col>
        </Row>
    );

    const handleCarryForward = async (data: any) => {
        if (!carryForwardModal) {
            await setSelecteCarryForwardInvoice(data);
            setCarryForwardModal(true);
        } else {
            setCarryForwardModal(false);
            toastId.current = toast(t(`Creating carry forward invoice...`), { autoClose: false, isLoading: true });
            const res = await invoicesService.carryForward({ ...data, invoice: values })
            if (res?.status === 'success') {
                reloadData && reloadData();
                toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: t('Carry forward invoice created successfully'), autoClose: 5000, isLoading: false, delay: 500 });
            } else {
                toast.update(toastId.current, { type: toast.TYPE.WARNING, render: t('Something went wrong'), autoClose: 5000, isLoading: false, delay: 500 });
            }
        }
    }

    useEffect(() => {
        handleCalculation();
        if (feesTypeOptions.length > 0) {
            //@ts-ignore
            // setFeesTypeOptions((state: any) => [...state?.map((x: any) => ({ ...x, label: x.label + x.description }))])
        }
    }, [values?.details, values?.expenses])

    useEffect(() => {
        handleCalculation();
        if (values?.is_hourly_rate === 1) {
            // setFieldValue('details', values.details?.filter((d: any) => d.timesheets && d.timesheets.length > 0))
        }
    }, [values?.is_hourly_rate])

    useEffect(() => {
        getSelectOptions("tbl_master_company", "company_name", ['company_address', 'postal_code', 'city', 'country', 'vatcat', 'vat_percentage', 'TVW_Nr', 'company_name', 'invoice_payable_period', 'invoice_contact_person']).then(function (
            options
        ) {
            const item = addIndividualInCompany(options) as any;
            setCompanyOption(item);
            setloadingCompanyOption(false);
        });

        getSelectOptions("tbl_master_fees", "fees_code", ['description', 'fees_amount', 'fees_code', 'is_internal', 'is_sensitive', 'id']).then(function (options) {
            setFeesTypeOptions(options?.filter((option: any) => option.is_internal !== 1 && option.is_sensitive !== 1)?.map((x: any) => ({
                ...x, label: `${x.label} - ${x.description}`
            })));
            setLoadingFeesTypeOptions(false);


        });

    }, []);

    useEffect(() => {
        setloadingContactPerson(true);
        getDependentSelectOptions(
            "tbl_projects",
            "name",
            "company_id",
            parseInt(values.company_id),
        ).then(function (options) {
            setProjectOption(options);
        });

        getDependentSelectOptions(
            "tbl_master_users",
            "first_name",
            "company",
            parseInt(values.company_id)
        ).then(function (options) {
            if (values?.company_id != 0) {
                const option = options.find((cp: any) => cp.value == values?.company?.invoice_contact_person);
                setContactPersonOption(options.filter((cp: any) => cp.value == values?.company?.invoice_contact_person));
                setConsultPersonOption(options);
                setFieldValue("contact_person_id", parseInt(values?.company?.invoice_contact_person));
                setFieldValue("content", (`${trans.find((a: any) => a.code === option?.language)?.dear} ${option?.honorific}, ${trans.find((a: any) => a.code === option?.language)?.body}`));
            } else {
                setContactPersonOption(options);
            }
            setloadingContactPerson(false);
        })

    }, [values.company_id])

    useEffect(() => {
        if (values.contact_person_id) {
            getTaskByCompany({
                company_id: values.company_id,
                contact_person_id: values.contact_person_id,
                project_id: values.project_id,
            }).then(function (options) {
                setTaskOptions(options);
            });
        }
    }, [values.contact_person_id, values.project_id])


    useEffect(() => {

        if (companyOption.length > 0) {
            // alert(JSON.stringify(values));
            setFieldValue('company_id', values.company_id);
        }
    }, [companyOption.length])

    useEffect(() => {
        // alert(values?.timesheets?.find((a: any) => a.id === 35)?.service_charge)
        // console.log('timesheet', values?.timesheets)
        handleCalculation();
        setFieldValue('deletedItems', deletedItems);
    }, [values?.timesheets, deletedItems])

    useEffect(() => {
        if (values?.task_id) {
            if (feesTypeOptions) {
                const fees = feesTypeOptions.find((a: any) => a.value === selectedTask?.type) as any;
                const price = fees?.fees_amount ? fees?.fees_amount : 0;
                const quantity = selectedTask?.responsible_person_id?.length > 0 ? selectedTask?.responsible_person_id?.length : 1;
                const subtotal = quantity * price;
                setFieldValue(`details[${0}][timesheet_id]`, selectedTask?.type)
                setFieldValue(`details[${0}][timesheet_code]`, fees?.fees_code);
                setFieldValue(`details[${0}][price]`, price);
                setFieldValue(`details[${0}][timesheet_name]`, fees?.description);
                setFieldValue(`details[${0}][subtotal]`, subtotal);
                setFieldValue(`details[${0}][quantity]`, quantity);
                setFieldValue('expenses', selectedTask?.expense);
                setFieldValue('po_number', selectedTask?.purchase_order_number);
                // setFieldValue('sub_total', subtotal + selectedTask?.expense);
                setFieldValue(`details[${0}][responsible_person]`, consultPersonOption.filter((co: any) => selectedTask.responsible_person_id.map((r: any) => r.responsible_person).includes(co.value)).map((d: any) => d.label).join(', '));
            }
        } else {
            setSelectedTask(null)
        }
    }, [values?.task_id])

    useEffect(() => {
        if (values.tasks && values.tasks?.length > 0) {
            let tmp = values?.tasks[0]?.task;
            tmp.budget_amount = values?.tasks[0]?.task?.budget_amount - (values?.total_invoice > 0 ? values?.total_invoice : values?.tasks[0]?.task?.invoices.filter((x: any) => x.invoice.status === 'Sent').reduce((t: number, a: any) => t + parseFloat(a.invoice.total + a.invoice.expenses), 0));
            // console.log('tmp', tmp)
            setSelectedTask(tmp)
            // setSelectedTask(values.tasks[0].task)
        }
    }, [values?.tasks])

    const invoiceEditing = async (id: number, invoice_id: number, is_editing: number) => {
        const res = await invoicesService.updateEditing(id, invoice_id, is_editing);
    }

    useEffect(() => {
        const handleTabClose = (event: any) => {
            event.preventDefault();
            invoiceEditing(values.id, values.invoice_id, 0);
            console.log('beforeunload event triggered');

            return (event.returnValue =
                'Custom exit?');
        };

        window.addEventListener('beforeunload', handleTabClose);

        if (values.id) {
            if (values.is_editing) {
                if (values.is_editing.id !== userObj.USER_ID) {
                    setEditing(true);
                }
            } else {
                invoiceEditing(values.id, values.invoice_id, 1);
            }
        }
        return () => {
            window.removeEventListener('beforeunload', handleTabClose);
            if (values.id) {
                if (values.is_editing) {
                    if (values.is_editing.id === userObj.USER_ID) {
                        invoiceEditing(values.id, values.invoice_id, 0);
                    }
                } else {
                    invoiceEditing(values.id, values.invoice_id, 0);
                }
            }
        }
    }, [])




    // useEffect(() => {
    //     console.log("errors", errors?.details, touched);
    //     // setFieldValue(`details[${0}][responsible_person]`, consultPersonOption.filter((co:any) => co. ).map((d: any) => d.label).join(', '));
    // }, [errors])


    return (
        <React.Fragment>
            {/* {JSON.stringify(customErrors)} */}
            <Row>
                <Col className="col-3">
                    <div className="mb-3">
                        <Select
                            id="company_id"
                            name="company_id"
                            className="is-invalid"
                            label='Client'
                            required
                            options={companyOption}
                            loading={loadingCompany}
                            //@ts-ignore
                            value={companyOption.find((o: any) => o.value == values?.company_id)}
                            isSearchable={true}
                            isDisabled={loadingCompany || isCreditNote}
                            error={errors.company_id}
                            touched={touched.company_id}
                            onChange={(option: any) => {
                                // alert(JSON.stringify(option));
                                setFieldValue("company_id", parseInt(option.value));
                                setFieldValue("company", option);
                                //@ts-ignore
                                setFieldValue("due_date", moment(values?.invoice_date).add(option.invoice_payable_period, 'days').format("YYYY-MM-DD hh:mm:ss"));
                                setFieldValue("tax_rate", option.vat_percentage);

                            }}
                            onBlur={() => setFieldTouched('company_id', true)}
                        />
                    </div>
                </Col>

                <Col className="col-3">
                    <div className="mb-3">

                        <Select
                            id="contact_person_id"
                            name="contact_person_id"
                            className="is-invalid"
                            label='Contact Person'
                            required
                            options={contactPersonOption}
                            //@ts-ignore
                            value={values?.contact_person_id ? contactPersonOption.find((o: any) => o.value === values?.contact_person_id) : ''}
                            isSearchable={true}
                            isDisabled={loadingCompany || isCreditNote}
                            loading={loadingContactPerson}
                            error={errors.contact_person_id}
                            touched={touched.contact_person_id}
                            onChange={(option: any) => {
                                // alert(JSON.stringify(option))
                                setFieldValue("contact_person_details", option);
                                setFieldValue("contact_person_id", option.value);

                                setFieldValue("content", (`${t('Dear')} ${option.honorific}, ${t(values.body)}`));
                                if (option?.company === 0) {
                                    setFieldValue("tax_rate", option.vat_percentage);
                                }
                            }}
                            onBlur={() => setFieldTouched('contact_person_id', true)}
                        />
                    </div>
                </Col>
                <Col className="col-3">
                    <div className="mb-3">

                        <Select
                            id="project_id"
                            name="project_id"
                            label="Project"
                            className="is-invalid"
                            options={projectOption}
                            loading={loadingContactPerson}
                            //@ts-ignore
                            value={projectOption.find((o: any) => o.value === values?.project_id)}
                            isSearchable={true}
                            isDisabled={loadingContactPerson || isCreditNote}
                            onChange={(option: any) => {
                                setFieldValue("project_id", option.value);
                                setFieldValue('po_number', option?.po_number);
                            }}
                        />
                    </div>
                </Col>
                <Col className="col-3">
                    <div className="mb-3">

                        <Input
                            id='po_number'
                            label='Purchase order number'
                            placeholder={t('Purchase order number')}
                            readOnly={isCreditNote}
                            value={values?.po_number}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onBlur={() => setFieldTouched('po_number', true)}
                            error={errors.po_number}
                            touched={touched.po_number}
                        />
                    </div>

                </Col>
            </Row>
            {(values.company_id !== null && values.contact_person_id) && !values.id ?
                <Row>
                    <Col className='mb-3'>
                        <Select
                            id="task_id"
                            name="task_id"
                            className="is-invalid"
                            options={taskOptions}
                            label="Task Title"
                            formatOptionLabel={formatOptionLabel}
                            value={taskOptions.find((o: any) => o.value === values?.task_id)}
                            isClearable={!!values?.task_id}
                            isSearchable={true}
                            isDisabled={loadingContactPerson || isCreditNote}
                            loading={loadingContactPerson}
                            onChange={(option: any) => {
                                if (option) {
                                    setFieldValue("task_id", parseInt(option.value));
                                    setFieldValue(`details[${0}][responsible_person_id]`, option.responsible_person_id.map((r: any) => r.responsible_person));

                                    setSelectedTask(option);
                                    if (option?.is_budget) {
                                        setFieldValue("budget", parseInt(option.budget_amount));
                                    }
                                } else {
                                    setFieldValue("task_id", null);
                                }
                                // setFieldValue("company", option);
                            }}
                        />
                    </Col>
                </Row> : null
            }
            <Row>
                <Col className="col-3">
                    <div className="mb-3">
                        <Input
                            id="reference"
                            name="reference"
                            label="Payment Reference"
                            required
                            className="form-control"
                            placeholder="Reference"
                            value={values.reference}
                            type="text"
                            autoFocus={false}
                            readOnly={isCreditNote}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onBlur={() => setFieldTouched('reference', true)}
                            error={errors.reference}
                            touched={touched.reference}

                        />
                    </div>
                </Col>
                <Col className="col-3">
                    <div className="mb-3">

                        <DatePicker
                            id="invoice_date"
                            name="invoice_date"
                            label="Invoice Date"
                            required
                            className="form-control d-block"
                            type="text"
                            placeholder="DD/MM/YYYY"
                            value={values?.invoice_date}
                            disabled={isCreditNote}
                            options={{
                                altInput: true,
                                altFormat: "d/m/Y",
                                dateFormat: "Y-m-d",
                                defaultDate: values?.invoice_date,
                                allowInput: true,
                                plugins: [labelPlugin()],
                                onChange: function (
                                    selectedDates,
                                    dateStr,
                                    instance
                                ) {
                                    setFieldValue(
                                        "invoice_date",
                                        dateStr
                                    );
                                    setFieldValue(
                                        "due_date",
                                        moment(dateStr).add(90, 'days').format('YYYY-MM-DD')
                                    );
                                },
                                onOpen: () => {
                                    setFieldTouched(
                                        "invoice_date",
                                        true
                                    );
                                },
                            }}
                            error={errors.invoice_date}
                            touched={touched.invoice_date}
                        />
                    </div>

                </Col>
                <Col className="col-3">
                    <Row>
                        <Col className="col-10">
                            <div className="mb-3">

                                <DatePicker
                                    id="due_date"
                                    name="due_date"
                                    label="Due Date"
                                    required
                                    className="form-control d-block"
                                    type="text"
                                    placeholder="DD/MM/YYYY"
                                    value={values.due_date}
                                    disabled={isCreditNote}
                                    options={{
                                        altInput: true,
                                        altFormat: "d/m/Y",
                                        dateFormat: "Y-m-d",
                                        defaultDate: values?.due_date,
                                        minDate: values.invoice_date,
                                        allowInput: true,
                                        onChange: function (
                                            selectedDates,
                                            dateStr,
                                            instance
                                        ) {
                                            setFieldValue(
                                                "due_date",
                                                dateStr
                                            );
                                        },
                                        locale: { firstDayOfWeek: 1 }
                                    }}
                                    touched={touched.due_date}
                                    error={errors.due_date}
                                />
                            </div>
                        </Col>
                        <Col className="col-2"> <div className="mt-4">{t("OR")}</div></Col>
                    </Row>
                </Col>

                <Col className="col-3">
                    <div className="mb-3">
                        <Label className="form-label">
                            {t("Term")}
                        </Label>

                        <Select
                            id="term"
                            name="term"
                            className="is-invalid"
                            options={[
                                { value: 30, label: `30 ${t("days")}` },
                                { value: 60, label: `60 ${t("days")}` },
                                { value: 90, label: `90 ${t("days")}` },
                            ]}
                            isSearchable={true}
                            //@ts-ignore
                            defaultValue={null}
                            isDisabled={isCreditNote}
                            onBlur={() =>
                                setFieldTouched("term", true)
                            }
                            onChange={(option: any) => {
                                setFieldValue("term", option.value);
                                setFieldValue(
                                    "due_date",
                                    moment(values.invoice_date).add(option.value, 'days').format('YYYY-MM-DD')
                                );
                            }}
                        />
                        {touched.term && errors.term ? (
                            <FormFeedback type="invalid">
                                {errors.term}
                            </FormFeedback>
                        ) : null}
                    </div>
                </Col>
            </Row>

            <Row>
                <Col className="col-12">
                    <div className='d-flex justify-content-between bg-light  rounded-start'>
                        <Nav tabs className="nav-tabs-custom">
                            <NavItem>
                                <Link
                                    style={{ cursor: "pointer" }}
                                    to={'#'}
                                    onClick={() => setType('PRODUCTS')}
                                    className={type === "PRODUCTS" ? "nav-link active" : "nav-link"}
                                >
                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                    <span className="d-none d-sm-block">{t("Items")}</span>
                                </Link>
                            </NavItem>
                            <NavItem>
                                <Link
                                    style={{ cursor: "pointer" }}
                                    onClick={() => setType('OTHERDETAILS')}
                                    to={'#'}
                                    className={type === "OTHERDETAILS" ? "nav-link active" : "nav-link"}
                                >
                                    <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                                    <span className="d-none d-sm-block">{t("Other details")}</span>
                                </Link>
                            </NavItem>
                            {!!values.id &&
                                <NavItem>
                                    <Link
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setType('COMMENTS')}
                                        to={'#'}
                                        className={type === "COMMENTS" ? "nav-link active" : "nav-link"}
                                    >
                                        <span className="d-block d-sm-none"><i className="fas fa-comment"></i></span>
                                        <span className="d-none d-sm-block">{t("Comments")}</span>
                                    </Link>
                                </NavItem>
                            }
                            {!!values.id &&
                                <NavItem>
                                    <Link
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setType('ATTACHMENTS')}
                                        to={'#'}
                                        className={type === "ATTACHMENTS" ? "nav-link active" : "nav-link"}
                                    >
                                        <span className="d-block d-sm-none"><i className="fas fa-comment"></i></span>
                                        <span className="d-none d-sm-block">{t("Attachments")}</span>
                                    </Link>
                                </NavItem>
                            }
                            {values?.timesheets_count > 0 &&
                                <NavItem>
                                    <Link
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setType('TIMESHEET')}
                                        to={'#'}
                                        className={type === "TIMESHEET" ? "nav-link active" : "nav-link"}
                                    >
                                        <span className="d-block d-sm-none"><i className="fas fa-comment"></i></span>
                                        <span className="d-none d-sm-block">{t("Time sheet")}</span>
                                    </Link>
                                </NavItem>
                            }
                        </Nav>
                        {((!!values.id && values?.timesheets?.length > 0) && !isCreditNote) &&
                            <div className='bg-light rounded-end'>
                                {/* <FormGroup switch inline className={"form-check-success mx-5 mt-2"}>

                                <Input
                                    type="switch"
                                    name={'is_hourly_rate'}
                                    id={'is_hourly_rate'}
                                    checked={values?.is_hourly_rate}
                                    onChange={(e: any) => setFieldValue(
                                        "is_hourly_rate",
                                        e.target.checked
                                    )}
                                    label={'Hourly rate'}
                                />
                                <Label for="is_hourly_rate">{t("Hourly rate")}</Label>
                            </FormGroup> */}
                                {/* <ButtonGroup aria-label="Basic example">
                                    <Button
                                        type="button"
                                        color='primary'
                                        outline
                                        active={values?.is_hourly_rate === 0}
                                        onClick={(e: any) => setFieldValue(
                                            "is_hourly_rate",
                                            0
                                        )}
                                    >
                                        {t("Fixed rate")}
                                    </Button>
                                    <Button
                                        type="button"
                                        color='primary'
                                        outline
                                        active={values?.is_hourly_rate === 1}
                                        onClick={(e: any) => setFieldValue(
                                            "is_hourly_rate",
                                            1
                                        )}
                                    >
                                        {t("Hourly rate")}
                                    </Button>
                                    <Button
                                        type="button"
                                        color='primary'
                                        outline
                                        active={values?.is_hourly_rate === 2}
                                        onClick={(e: any) => setFieldValue(
                                            "is_hourly_rate",
                                            2
                                        )}
                                    >
                                        {t("Combined")}
                                    </Button>
                                </ButtonGroup> */}
                            </div>
                        }
                    </div>
                    <div>
                        {type === 'PRODUCTS' ?
                            <Table>
                                <thead className='border-bottom border-gray'>
                                    <tr>
                                        <th>{t("#")}</th>
                                        {values?.is_hourly_rate === 2 && <th style={{ width: '140px' }}>{
                                            <div className='d-inline-flex'>
                                                <Input
                                                    type="checkbox"
                                                    name={'is_hourly_all'}
                                                    id={'is_hourly_all'}
                                                    className='mx-2'
                                                    checked={values.is_hourly}
                                                    // disabled={p?.timesheets?.length === 0}
                                                    onChange={(e: any) => {
                                                        values?.details?.map((p: ProductType, i: number) => {
                                                            if (p?.timesheets?.length)
                                                                setFieldValue(`details[${i}][is_hourly]`, e.target.checked ? 1 : 0)
                                                        })
                                                    }}
                                                />
                                                {t("Fixed/Hourly")}
                                            </div>
                                        }</th>}
                                        {/* <th>{t("Task Code")}</th> */}
                                        <th colSpan={2} style={{ width: '30%' }}>{t("Task type")}</th>
                                        <th>{t("Person Concerned")}</th>
                                        <th>{t("Quantity")}</th>
                                        <th style={{ width: '200px' }}>{selectedTask?.is_budget === 1 && !isCreditNote ? t('Remaining Budget') : t("Price")}</th>
                                        <th>{t("Disc")}%</th>
                                        <th className="rightAlign">{t("Subtotal")}</th>
                                        <th style={{ width: '120px' }}></th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {values?.details?.map((p: ProductType, i: number) => (

                                        <React.Fragment key={i}>
                                            <tr className={(values.details[i].isDisc || values.details[i].description) || p?.timesheets?.length ? 'border-bottom-0 border-white' : 'border-bottom border-gray'}>
                                                <td>{i + 1}.</td>
                                                {values?.is_hourly_rate === 2 && <td>
                                                    <FormGroup switch inline className={"form-check-primary d-flex"}>
                                                        <Input
                                                            type="switch"
                                                            name={'is_hourly'}
                                                            id={'is_hourly_' + i}
                                                            checked={values.details[i].is_hourly}
                                                            disabled={p?.timesheets?.length === 0}
                                                            onChange={(e: any) => {
                                                                setFieldValue(`details[${i}][is_hourly]`, e.target.checked ? 1 : 0);
                                                            }}
                                                        />
                                                        <Label for={'is_hourly_' + i}>{t("Hourly")}</Label>
                                                    </FormGroup>
                                                </td>
                                                }
                                                <td colSpan={2}>
                                                    <Select
                                                        id={`timesheet_code`}
                                                        name={`timesheet_code`}
                                                        className="is-invalid"
                                                        options={feesTypeOptions}
                                                        //@ts-ignore
                                                        // isOptionDisabled={(option) => option?.disabled}
                                                        isSearchable={true}
                                                        isDisabled={loadingFeesTypeOptions || isCreditNote || values?.task_id}
                                                        //@ts-ignore
                                                        value={feesTypeOptions ? feesTypeOptions.find((o: any) => o.value === values.details[i].timesheet_id) : null}
                                                        onBlur={() =>
                                                            setFieldTouched(`details[${i}][timesheet_code]`, true)
                                                        }
                                                        onChange={(option: any) => {
                                                            //alert(JSON.stringify(option))
                                                            setFieldValue(`details[${i}][timesheet_id]`, option.id);
                                                            setFieldValue(`details[${i}][timesheet_code]`, option.fees_code);
                                                            setFieldValue(`details[${i}][price]`, option.fees_amount);
                                                            setFieldValue(`details[${i}][timesheet_name]`, option.description);
                                                            setFieldValue(
                                                                `details[${i}][subtotal]`,
                                                                calculateSubtotal(p?.quantity, option?.fees_amount, p?.discount, p?.taxes)
                                                            )
                                                        }}
                                                        touched={touched?.details ? touched?.details[i]?.timesheet_code : false}
                                                        error={errors?.details ? errors?.details[i]?.timesheet_code : null}
                                                    />
                                                    {values?.details[i]?.is_carry_forwarded ? <Badge>{t("Carry forwarded")}</Badge> : null}
                                                    {(!values.details[i].isDisc && !values.details[i].description && !isCreditNote) &&
                                                        <a onClick={() => {
                                                            setFieldValue(
                                                                `details[${i}][isDisc]`,
                                                                true
                                                            )
                                                        }}
                                                            className='btn-link sm'
                                                        >{t("Add Description")}</a>}
                                                </td>
                                                {/* <td style={{ width: '300px' }}>
                                                    <Input
                                                        id="timesheet_name"
                                                        name="timesheet_name"
                                                        className="form-control"
                                                        placeholder="Name"
                                                        type="text"
                                                        readOnly
                                                        autoFocus={false}
                                                        value={values.details[i].timesheet_name}
                                                        onKeyUp={handleChange}
                                                        onChange={(e: any) => setFieldValue(
                                                            `details[${i}][timesheet_name]`,
                                                            e.target.value
                                                        )}
                                                    />
                                                </td> */}
                                                <td>
                                                    <Select
                                                        id="responsible_person_id"
                                                        name="responsible_person_id"
                                                        className="is-invalid"
                                                        options={consultPersonOption}
                                                        //@ts-ignore
                                                        value={consultPersonOption.filter((o: any) => values.details[i]?.responsible_person_id?.includes(o.value))}
                                                        isSearchable={true}
                                                        isMulti
                                                        isDisabled={loadingCompany || isCreditNote}
                                                        loading={loadingContactPerson}
                                                        onChange={(option: any) => {
                                                            setFieldValue(`details[${i}][responsible_person_id]`, option.map((d: any) => d.value));
                                                            setFieldValue(`details[${i}][responsible_person]`, option.map((d: any) => d.label).join(', '));
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        id="quantity"
                                                        name="quantity"
                                                        className="form-control right-align"
                                                        placeholder="Qty"
                                                        type="text"
                                                        style={{ width: '80px' }}
                                                        autoFocus={false}
                                                        onKeyUp={handleChange}
                                                        value={values.details[i]?.quantity}
                                                        readOnly={isCreditNote}
                                                        onChange={(e: any) => {
                                                            if (e.target?.value >= 0) {
                                                                setFieldValue(
                                                                    `details[${i}][subtotal]`,
                                                                    calculateSubtotal(e.target?.value, values.details[i]?.price, values.details[i]?.discount, values.details[i]?.taxes)
                                                                )
                                                                setFieldValue(
                                                                    `details[${i}][quantity]`,
                                                                    e.target?.value
                                                                )
                                                            }
                                                        }}

                                                    />
                                                </td>
                                                <td className=''>
                                                    {selectedTask?.is_budget === 1 && !isCreditNote ?
                                                        <Input
                                                            id="price"
                                                            name="price"
                                                            className="form-control right-align"
                                                            placeholder="Amount"
                                                            type="text"
                                                            autoFocus={false}
                                                            value={values.details[i].price}
                                                            onKeyUp={handleChange}
                                                            readOnly={isCreditNote}
                                                            startAdornment={formatCurrency(selectedTask?.budget_amount - values.details.filter((a: any, x: number) => x < i).reduce((t: any, a: any) => t + a?.subtotal, 0))}
                                                            onChange={(e: any) => {
                                                                if (e.target?.value >= 0) {
                                                                    // setFieldValue(
                                                                    //     `details[${i}][price]`,
                                                                    //     e.target.value
                                                                    // )
                                                                    const budget_amt = selectedTask?.budget_amount - values.details.filter((a: any, x: number) => x < i).reduce((t: any, a: any) => t + a?.subtotal, 0);
                                                                    const name = `details_${i}_price`;
                                                                    if (budget_amt < e.target?.value) {
                                                                        // alert('error')
                                                                        setFieldTouched(`details_${i}_price`, true);

                                                                        setCustomErrors((errors: any) => ({ ...errors, [name]: 'Amount should be less than ' + budget_amt }))
                                                                        return false;
                                                                    } else {
                                                                        setCustomErrors((errors: any) => ({ ...errors, [name]: null }))
                                                                    }
                                                                    setFieldValue(
                                                                        `details[${i}][price]`,
                                                                        e.target?.value
                                                                    )
                                                                    setFieldValue(
                                                                        `details[${i}][subtotal]`,
                                                                        calculateSubtotal(1, e.target?.value, values.details[i]?.discount, values.details[i]?.taxes)
                                                                    )
                                                                }
                                                            }}
                                                            error={customErrors && customErrors[`details_${i}_price`]}
                                                            touched={touched[`details_${i}_price`]}

                                                        /> :
                                                        <Input
                                                            id="price"
                                                            name="price"
                                                            className="form-control right-align"
                                                            placeholder="Price"
                                                            type="text"
                                                            autoFocus={false}
                                                            value={values.details[i].price}
                                                            onKeyUp={handleChange}
                                                            readOnly={isCreditNote}
                                                            onChange={(e: any) => {
                                                                if (e.target?.value >= 0) {
                                                                    setFieldValue(
                                                                        `details[${i}][price]`,
                                                                        e.target.value
                                                                    )
                                                                    setFieldValue(
                                                                        `details[${i}][subtotal]`,
                                                                        calculateSubtotal(values.details[i]?.quantity, e.target?.value, values.details[i]?.discount, values.details[i]?.taxes)
                                                                    )
                                                                }
                                                            }}

                                                        />
                                                    }
                                                </td>
                                                <td>
                                                    <Input
                                                        id="discount"
                                                        name="discount"
                                                        className="form-control right-align"
                                                        placeholder="Discount"
                                                        type="text"
                                                        autoFocus={false}
                                                        onKeyUp={handleChange}
                                                        value={values.details[i]?.discount}
                                                        readOnly={isCreditNote}
                                                        onChange={(e: any) => {
                                                            if (e.target?.value >= 0 && e.target?.value <= 100) {
                                                                setFieldValue(
                                                                    `details[${i}][discount]`,
                                                                    e.target?.value
                                                                )
                                                                setFieldValue(
                                                                    `details[${i}][subtotal]`,
                                                                    calculateSubtotal(values.details[i]?.quantity, values.details[i]?.price, e.target.value, values.details[i]?.taxes)
                                                                )
                                                            }
                                                        }}
                                                    />
                                                </td>
                                                {values?.is_hourly_rate === 2 ?
                                                    <td className="rightAlign p-3">{values.details[i].is_hourly ? '' : formatCurrency(p?.subtotal)}</td>
                                                    :
                                                    <td className="rightAlign p-3">{values?.is_hourly_rate === 1 ? '' : formatCurrency(p?.subtotal)}</td>
                                                }
                                                <td className='rightAlign'>
                                                    {!isCreditNote && (
                                                        <>
                                                            <Button
                                                                color="primary"
                                                                className='btn-sm mx-1'
                                                                title={t('Carry Forward')}
                                                                onClick={() => handleCarryForward({ details: p, details_id: values.details[i].id })}
                                                                disabled={values?.details?.length <= 1}
                                                            >
                                                                <span className="align-middle material-symbols-outlined fw-light fs-4">
                                                                    forward
                                                                </span>
                                                            </Button>
                                                            <Button
                                                                color="secondary"
                                                                outline
                                                                className='btn-sm'
                                                                title={t('Delete Line')}
                                                                onClick={() => handleDeleteRow(i)}
                                                            >
                                                                <span className="align-middle material-symbols-outlined fw-light fs-4">
                                                                    delete
                                                                </span>
                                                            </Button>
                                                        </>
                                                    )}
                                                </td>

                                            </tr>
                                            {
                                                (values.details[i].isDisc || values.details[i].description) ?
                                                    <tr className={!p?.timesheets || p?.timesheets?.length == 0 ? 'border-bottom border-gray' : 'border-bottom-0 border-white'}>
                                                        <td></td>
                                                        {values?.is_hourly_rate === 2 && <td></td>}
                                                        <td colSpan={6}>
                                                            <Label>{t("Description")}</Label>
                                                            <Input
                                                                id="description"
                                                                name="description"
                                                                className="form-control"
                                                                placeholder="Description"
                                                                maxLength={200}
                                                                value={values.details[i].description}
                                                                type="text"
                                                                autoFocus={false}
                                                                onKeyUp={handleChange}
                                                                readOnly={isCreditNote}
                                                                onChange={(e: any) => {
                                                                    setFieldValue(
                                                                        `details[${i}][description]`,
                                                                        e.target.value
                                                                    )
                                                                }}
                                                            />
                                                        </td>
                                                        <td></td>
                                                        <td className='rightAlign align-bottom'>
                                                            {!isCreditNote &&
                                                                <>
                                                                    <Button
                                                                        color="secondary"
                                                                        outline
                                                                        className='btn-sm'
                                                                        title={t('Remove Description')}
                                                                        onClick={() => {
                                                                            setFieldValue(
                                                                                `details[${i}][isDisc]`,
                                                                                false
                                                                            );
                                                                            setFieldValue(
                                                                                `details[${i}][description]`,
                                                                                null
                                                                            );
                                                                        }}
                                                                    >
                                                                        <span className="align-middle material-symbols-outlined fw-light fs-4">
                                                                            remove
                                                                        </span>
                                                                    </Button>
                                                                </>
                                                            }
                                                        </td>
                                                    </tr> : null
                                            }


                                            {p?.timesheets?.length > 0 && values?.is_hourly_rate ?
                                                <tr className='border-bottom border-gray'>
                                                    <td></td>
                                                    {values?.is_hourly_rate === 2 && <td></td>}
                                                    <td colSpan={8} className="bt-soft bg-indigo bg-gradient rounded p-0">
                                                        <Table>
                                                            <thead>
                                                                <tr>
                                                                    <th>{t("#")}</th>
                                                                    <th>{t("Timesheet Description")}</th>
                                                                    <th>{t("Start Date")}</th>
                                                                    <th>{t("Time Spent")}</th>
                                                                    <th>{t("Person Concerned")}</th>
                                                                    <th>{t("Collaborator")}</th>
                                                                    <th className='rightAlign' style={{ width: '140px' }}>{t("Service Charge")}</th>
                                                                    <th className='rightAlign' style={{ width: '60px' }}>
                                                                        {!isCreditNote &&
                                                                            <Button
                                                                                color="danger"
                                                                                outline
                                                                                className='btn-sm'
                                                                                title={t('Delete all')}
                                                                                onClick={() => handleDeleteAllTimeSheet(p?.id || 0)}
                                                                            >
                                                                                <span className="align-middle material-symbols-outlined fw-light fs-4">
                                                                                    folder_delete
                                                                                </span>
                                                                            </Button>
                                                                        }
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {p?.timesheets?.map((ts: any, idx: number) => {
                                                                    const index = values?.timesheets?.findIndex((a: any) => a.id === ts.id);
                                                                    return (
                                                                        index > -1 &&
                                                                        <tr key={ts.id}>
                                                                            <td>{idx + 1}.</td>
                                                                            <td style={{ width: '440px' }}>
                                                                                <Input
                                                                                    name={`timesheets[${index}][title]`}
                                                                                    value={values?.timesheets?.find((a: any) => a.id === ts.id)?.title}
                                                                                    className="form-control custom-input"
                                                                                    onChange={(e: any) => setFieldValue(`timesheets[${index}][title]`, e.target.value)}
                                                                                    readOnly={isCreditNote}
                                                                                />
                                                                            </td>
                                                                            <td style={{ width: '200px' }} >
                                                                                <Flatpickr
                                                                                    name={`timesheets[${index}][start_date]`}
                                                                                    className="form-control d-block custom-input"
                                                                                    type="text"
                                                                                    placeholder="DD/MM/YYYY"
                                                                                    value={values?.timesheets?.find((a: any) => a.id === ts.id)?.start_date}
                                                                                    disabled={isCreditNote}
                                                                                    options={{
                                                                                        altInput: true,
                                                                                        altFormat: "d/m/Y",
                                                                                        dateFormat: "Y-m-d H:i:S",
                                                                                        defaultDate: ts?.start_date,
                                                                                        allowInput: true,
                                                                                        onChange: function (
                                                                                            selectedDates,
                                                                                            dateStr,
                                                                                            instance
                                                                                        ) {
                                                                                            setFieldValue(`timesheets[${index}][start_date]`, dateStr)
                                                                                        },
                                                                                        locale: { firstDayOfWeek: 1 }
                                                                                    }}
                                                                                />
                                                                            </td>
                                                                            <td style={{ width: '200px' }}>

                                                                                <Flatpickr
                                                                                    name={`timesheets[${index}][time_spend]`}
                                                                                    className="form-control d-block custom-input"
                                                                                    type="text"
                                                                                    placeholder="HH:SS"
                                                                                    value={values?.timesheets?.find((a: any) => a.id === ts.id)?.time_spend}
                                                                                    disabled={isCreditNote}
                                                                                    options={{
                                                                                        enableTime: true,
                                                                                        noCalendar: true,
                                                                                        altFormat: "H/i/S",
                                                                                        dateFormat: "H:i:S",
                                                                                        time_24hr: true,
                                                                                        allowInput: true,
                                                                                        onChange: function (
                                                                                            selectedDates,
                                                                                            dateStr,
                                                                                            instance
                                                                                        ) {
                                                                                            setFieldValue(`timesheets[${index}][time_spend]`, dateStr);
                                                                                            const sc = strToSecond(dateStr) * ts?.hourly_rate;
                                                                                            setFieldValue(`timesheets[${index}][service_charge]`, sc);
                                                                                            setFieldValue(`details[${i}][timesheets][${idx}][service_charge]`, sc);
                                                                                        },
                                                                                        locale: { firstDayOfWeek: 1 }
                                                                                    }}
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input
                                                                                    name={`timesheets[${index}][responsible_person]`}
                                                                                    value={values?.timesheets?.find((a: any) => a.id === ts.id)?.responsible_person}
                                                                                    className="form-control custom-input"
                                                                                    onChange={(e: any) => setFieldValue(`timesheets[${index}][responsible_person]`, e.target.value)}
                                                                                    readOnly
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <Input value={ts?.collaborator} className="form-control custom-input" readOnly />
                                                                            </td>
                                                                            {values?.is_hourly_rate === 2 ?
                                                                                <td className='rightAlign'>
                                                                                    {/* <Input value={ts?.service_charge} className="form-control custom-input" readOnly /> */}
                                                                                    {values.details[i].is_hourly ? formatCurrency(values?.timesheets?.find((a: any) => a.id === ts.id)?.service_charge) : ''}
                                                                                </td>
                                                                                :
                                                                                <td className='rightAlign'>
                                                                                    {/* <Input value={ts?.service_charge} className="form-control custom-input" readOnly /> */}
                                                                                    {formatCurrency(values?.timesheets?.find((a: any) => a.id === ts.id)?.service_charge)}
                                                                                </td>
                                                                            }

                                                                            <td style={{ width: '120px', textAlign: 'right' }}>
                                                                                {!isCreditNote && (
                                                                                    <>
                                                                                        {p?.timesheets?.length > 1 &&
                                                                                            <Button
                                                                                                color="primary"
                                                                                                className='btn-sm mx-1'
                                                                                                onClick={() => handleCarryForward({ details: p, details_id: values.details[i].id, timesheet_id: ts?.id })}
                                                                                                title={t('Carry Forward')}
                                                                                                disabled={!values.details[i].is_hourly || p?.timesheets?.length <= 1}
                                                                                            >
                                                                                                <span className="align-middle material-symbols-outlined fw-light fs-4">
                                                                                                    forward
                                                                                                </span>
                                                                                            </Button>
                                                                                        }
                                                                                        <Button
                                                                                            color="danger"
                                                                                            outline
                                                                                            className='btn-sm'
                                                                                            title={t('Delete Timesheet')}
                                                                                            onClick={() => handleDeleteTimeSheet(ts.id)}
                                                                                        >
                                                                                            <span className="align-middle material-symbols-outlined fw-light fs-4">
                                                                                                delete
                                                                                            </span>
                                                                                        </Button>
                                                                                    </>
                                                                                )}
                                                                            </td>
                                                                        </tr>

                                                                    );

                                                                })}
                                                                {(p?.is_hourly && p?.timesheets?.length > 1) ?
                                                                    <tr className='gray-bg'>
                                                                        <td colSpan={6} className='rightAlign bold'>
                                                                            {t('Group sub total')}
                                                                        </td>
                                                                        <td className='rightAlign bold'>{formatCurrency(p?.timesheets?.reduce((total: number, a: any) => total + a?.service_charge, 0))}</td>
                                                                        <td></td>
                                                                    </tr> : null
                                                                }
                                                                {
                                                                    // values.details[i].is_hourly ?
                                                                    //     <tr>
                                                                    //         <td colSpan={7}>
                                                                    //             <Button
                                                                    //                 type='button'
                                                                    //                 size='sm'
                                                                    //                 color='default'
                                                                    //                 className='btn-link'
                                                                    //                 onClick={() => handleAddTimesheet(values.details[i])}
                                                                    //             >
                                                                    //                 {t("Add timesheet")}
                                                                    //             </Button>
                                                                    //         </td>
                                                                    //     </tr> : null
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </td>
                                                </tr> : null
                                            }

                                        </React.Fragment>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        {values?.is_hourly_rate === 2 && <td></td>}
                                        <td colSpan={5}>
                                            {(!isCreditNote && values.details[values.details.length - 1]?.timesheet_id && values?.is_hourly_rate !== 1 && !values.task_id) ? <a className="btn-link" onClick={handleAddRow}>{t("Add Line")}</a> : null}
                                        </td>
                                        <td>{t("Services rendered")}</td>
                                        <td className='right-align'>{formatCurrency(values?.sub_total)}</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        {values?.is_hourly_rate === 2 && <td></td>}
                                        <td colSpan={6}></td>
                                        <td>{t("Expenses")}</td>
                                        <td className="right-align">
                                            <Input
                                                id="expenses"
                                                name="expenses"
                                                className="form-control ms-auto"
                                                style={{ width: '80px', textAlign: 'right' }}
                                                placeholder="0"
                                                type="text"
                                                readOnly={isCreditNote}
                                                autoFocus={false}
                                                onKeyUp={handleChange}
                                                value={values?.expenses}
                                                onChange={(e: any) => {
                                                    if (e.target?.value >= 0) {
                                                        setFieldValue(
                                                            `expenses`,
                                                            e.target.value
                                                        )
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        {values?.is_hourly_rate === 2 && <td></td>}
                                        <td colSpan={6}></td>
                                        <td>{t("Sub total")}</td>
                                        <td className='right-align'>{formatCurrency(values?.total)}</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        {values?.is_hourly_rate === 2 && <td></td>}
                                        <td colSpan={6}></td>
                                        <td>{t("VAT")} ({values?.tax_rate}%)</td>
                                        <td className='right-align'>+{formatCurrency(values?.tax_amount)}</td>
                                        <td></td>
                                    </tr>
                                    {!!values?.paid_amount &&
                                        <tr>
                                            {values?.is_hourly_rate === 2 && <td></td>}
                                            <td colSpan={6}></td>
                                            <td>{t("Paid amount")}</td>
                                            <td className='right-align'>-{formatCurrency(values?.paid_amount)}</td>
                                            <td></td>
                                        </tr>
                                    }

                                    {values?.credit_notes?.length > 0 ?
                                        <tr className='bold'>
                                            {values?.is_hourly_rate === 2 && <td></td>}
                                            <td colSpan={6}></td>
                                            <td>
                                                {t("Credit Note")} <span title={expandCreditNote ? 'Collaps' : 'Expand'} className='cursor-pointer' onClick={() => setExpandCreditNote((state: boolean) => !state)}>({expandCreditNote ? '-' : '+'})</span>
                                            </td>
                                            <td className='right-align'>-{formatCurrency(values?.credit_notes?.reduce(function (acc: any, obj: any) { return acc + obj.credit_note_amount; }, 0))}</td>
                                            <td></td>
                                        </tr> : null
                                    }
                                    {expandCreditNote ? values?.credit_notes?.length > 0 && values?.credit_notes?.map((cn: any) => (
                                        <tr className=''>
                                            {values?.is_hourly_rate === 2 && <td></td>}
                                            <td colSpan={6}></td>
                                            <td>{t("Credit Note")} (<Link to={'#'} onClick={() => handleOpenInvoice(cn)}>{cn?.invoice_number}</Link>)</td>
                                            <td className='right-align'>-{formatCurrency(cn?.credit_note_amount)}</td>
                                            <td></td>
                                        </tr>
                                    )) : null}
                                    {isCreditNote ?
                                        <tr className='bold'>
                                            {values?.is_hourly_rate === 2 && <td></td>}
                                            <td colSpan={6}></td>
                                            <td>{t("Total due")}</td>
                                            <td className='right-align'>{formatCurrency(values?.final_amount_due)}</td>
                                            <td></td>
                                        </tr>
                                        :
                                        <tr className='bold'>
                                            {values?.is_hourly_rate === 2 && <td></td>}
                                            <td colSpan={6}></td>
                                            <td>{t("Total due")}</td>
                                            <td className='right-align'>{formatCurrency(values?.amount_due)}</td>
                                            <td></td>
                                        </tr>
                                    }
                                    {isCreditNote ?
                                        <React.Fragment>
                                            <tr>
                                                {values?.is_hourly_rate === 2 && <td></td>}
                                                <td colSpan={6}></td>
                                                <td className='gray-bg' width={300}>{t("Credit note amount")}
                                                    <br />
                                                    <FormGroup switch inline className={"form-check-primary d-flex"}>
                                                        <Input
                                                            type="switch"
                                                            name={'amount_type'}
                                                            id={'amount_type'}
                                                            checked={amountType === 2}
                                                            onChange={(e: any) => {
                                                                setAmountType(e.target.checked ? 2 : 1);
                                                                setFieldValue(
                                                                    `total_cn`, ''
                                                                );
                                                                setFieldValue(
                                                                    `tax_cn`,
                                                                    0
                                                                );
                                                                setFieldValue(
                                                                    `credit_note_amount`,
                                                                    0
                                                                );
                                                            }}
                                                        />
                                                        <Label for='amount_type' className='text-muted'>{t("Percentage")}</Label>
                                                    </FormGroup>
                                                    {/* <Input
                                                        type='radio'
                                                        id="amount_type_fixed"
                                                        name='amount_type'
                                                        checked={amountType === 1}
                                                        onChange={() => {
                                                            setAmountType(1); setFieldValue(
                                                                `total_cn`, ''
                                                            );
                                                            setFieldValue(
                                                                `tax_cn`,
                                                                0
                                                            );
                                                            setFieldValue(
                                                                `credit_note_amount`,
                                                                0
                                                            );
                                                        }}
                                                    />
                                                    <Label for='amount_type_fixed' className='mx-2 text-muted'>{t("Fixed Amount")}</Label>

                                                    <Input
                                                        type='radio'
                                                        name='amount_type'
                                                        id="amount_type_percentage"
                                                        checked={amountType === 2}
                                                        onChange={() => {
                                                            setAmountType(2);
                                                            setFieldValue(
                                                                `total_cn`, ''
                                                            );
                                                            setFieldValue(
                                                                `tax_cn`,
                                                                0
                                                            );
                                                            setFieldValue(
                                                                `credit_note_amount`,
                                                                0
                                                            );
                                                        }}
                                                    />
                                                    <Label for="amount_type_percentage" className='mx-2 text-muted'>{t("Percentage")}</Label> */}

                                                </td>
                                                <td className='gray-bg right-align' width={200}>
                                                    <Input
                                                        id="total_cn"
                                                        name="total_cn"
                                                        className={`form-control right-align ms-auto ${errors.credit_note_amount ? 'is-invalid' : ''}`}
                                                        placeholder="0"
                                                        type="text"
                                                        autoFocus={false}
                                                        onKeyUp={handleChange}
                                                        value={values?.total_cn}
                                                        endAdornment={<span className="material-symbols-outlined font-size-16">{amountType === 2 ? 'percent' : 'euro'}</span>}
                                                        onChange={(e: any) => {
                                                            handleChange(e);
                                                            if (amountType === 1) {
                                                                const total_cn = (parseFloat(e.target.value) * 100) / (100 + values?.tax_rate);
                                                                const tax_amt = (total_cn * values?.tax_rate) / 100;
                                                                if (e.target.value > (values?.final_amount_due)) {
                                                                    const total_cn = (parseFloat(values?.final_amount_due) * 100) / (100 + values?.tax_rate);
                                                                    const tax_amt = (total_cn * values?.tax_rate) / 100;
                                                                    setFieldValue(
                                                                        `total_cn`,
                                                                        values?.final_amount_due
                                                                    )
                                                                    setFieldValue(
                                                                        `tax_cn`,
                                                                        tax_amt
                                                                    );
                                                                    setFieldValue(
                                                                        `credit_note_amount`,
                                                                        values?.final_amount_due
                                                                    );
                                                                } else {
                                                                    setFieldValue(
                                                                        `total_cn`,
                                                                        e.target.value
                                                                    )
                                                                    setFieldValue(
                                                                        `tax_cn`,
                                                                        tax_amt
                                                                    );
                                                                    setFieldValue(
                                                                        `credit_note_amount`,
                                                                        total_cn + tax_amt
                                                                    );

                                                                }
                                                            } else {
                                                                const amt = (values?.final_amount_due * parseFloat(e.target.value)) / 100;
                                                                const total_cn = (amt * 100) / (100 + values?.tax_rate);
                                                                const tax_amt = (total_cn * values?.tax_rate) / 100;
                                                                if (amt > (values?.final_amount_due)) {
                                                                    const total_cn = (parseFloat(values?.final_amount_due) * 100) / (100 + values?.tax_rate);
                                                                    const tax_amt = (total_cn * values?.tax_rate) / 100;
                                                                    setFieldValue(
                                                                        `total_cn`,
                                                                        100
                                                                    )
                                                                    setFieldValue(
                                                                        `tax_cn`,
                                                                        tax_amt
                                                                    );
                                                                    setFieldValue(
                                                                        `credit_note_amount`,
                                                                        total_cn + tax_amt
                                                                    );
                                                                } else {
                                                                    setFieldValue(
                                                                        `total_cn`,
                                                                        e.target.value
                                                                    )
                                                                    setFieldValue(
                                                                        `tax_cn`,
                                                                        tax_amt
                                                                    );
                                                                    setFieldValue(
                                                                        `credit_note_amount`,
                                                                        total_cn + tax_amt
                                                                    );

                                                                }
                                                            }


                                                        }}
                                                    />


                                                    {errors.credit_note_amount ? (
                                                        <FormFeedback type="invalid">
                                                            {errors.credit_note_amount}
                                                        </FormFeedback>
                                                    ) : null}
                                                </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                {values?.is_hourly_rate === 2 && <td></td>}
                                                <td colSpan={6}></td>
                                                <td className='gray-bg'>{t("Credit note amount")}</td>
                                                <td className='gray-bg right-align'>{formatCurrency(values?.credit_note_amount - values?.tax_cn)}</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                {values?.is_hourly_rate === 2 && <td></td>}
                                                <td colSpan={6}></td>
                                                <td className='gray-bg'>{t("VAT on credit note")} ({values?.tax_rate}%)</td>
                                                <td className='gray-bg right-align'>+{formatCurrency(values?.tax_cn)}</td>
                                                <td></td>
                                            </tr>
                                            <tr className='bold'>
                                                {values?.is_hourly_rate === 2 && <td></td>}
                                                <td colSpan={6}></td>
                                                <td className='gray-bg'>{t("Total credit note amount")}</td>
                                                <td className='gray-bg right-align'>{formatCurrency(values?.credit_note_amount)}</td>
                                                <td></td>
                                            </tr>
                                        </React.Fragment>
                                        : null}
                                </tfoot>
                            </Table>
                            : null
                        }
                        {type === 'OTHERDETAILS' ?
                            <div>
                                <div className="mb-3 m-3">
                                    <CKEditor
                                        editor={CustomEditor}
                                        data={values.content}
                                        config={ckEditorConfiguration}
                                        onChange={(e: any, editor: any) => setFieldValue('content', editor.getData())}
                                        id="content"
                                        name="content"
                                        className="form-control"
                                        onBlur={() =>
                                            setFieldTouched("content", true)
                                        }
                                    />

                                    {touched.content &&
                                        errors.content ? (
                                        <FormFeedback type="invalid">
                                            {errors.content}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </div> : null
                        }
                        {type === 'COMMENTS' ?
                            <div>

                                {values.id &&
                                    <MultiComments
                                        moduleData={{
                                            module_app_id: 5,
                                            module_main_id: values?.id,
                                            module_sub_id: values?.invoice_id,
                                            section_id: 0,
                                        }}
                                        fetchData={true}
                                        isOpen={false}
                                    />
                                }
                            </div> : null
                        }
                        {type === 'ATTACHMENTS' ?
                            <div>
                                <Row>
                                    <Col>
                                        {/* <code><pre>{JSON.stringify(values, null, 2)}</pre></code> */}
                                        <DropzoneUpload
                                            moduleData={{
                                                module_app_id: 5,
                                                module_main_id: values?.id,
                                                module_sub_id: values?.invoice_id,
                                                section_id: values?.invoice_number,
                                                file_path: "invoices",
                                                isNotify: false,
                                            }}
                                            hideList={false}
                                            fetchData={true}
                                            isOpen={true}
                                            enableExpiryDate={false}
                                            attachmentForIDs={[
                                                {
                                                    value: values.contact_person_id,
                                                    label: values.contact_person,
                                                }

                                            ]}
                                        // onUpload={(files: any) => handleUploadDocument(files)}
                                        />
                                    </Col>
                                </Row>
                            </div> : null
                        }
                        {type === 'TIMESHEET' ?
                            <div>
                                <Row>
                                    <Col>
                                        {type === 'TIMESHEET' ? <AllTimeSheets company_id={values?.company_id} contact_person_id={values.contact_person_id} type="list" /> : null}
                                    </Col>
                                </Row>
                            </div> : null
                        }
                    </div>
                </Col>
            </Row>
            <Drawer
                title={selectedInvoice?.invoice_type === 'INVOICE' ? "Invoice" : 'Credit Note'}
                open={openInvoice}
                onClose={() => setOpenInvoice((state) => !state)}
                fullWidth
            >
                <ViewInvoice {...selectedInvoice} />
            </Drawer>
            <Modal
                size="md"
                isOpen={isEditing}
                toggle={() => setEditing((state: boolean) => !state)}
                backdrop="static"
                keyboard={false}
                autoFocus={true}
                centered={true}
            >

                <ModalBody className='bg-danger bg-soft font-size-18 text-danger text-center'>
                    {t("Name is already updating this Invoice", { name: values?.is_editing?.name })}
                </ModalBody>
            </Modal>
            <Confirm
                isOpen={carryForwardModal}
                message='Are you sure, you want to carry forward?'
                onYesClick={() => handleCarryForward(selectedCarryForwardInvoice)}
                onNoClick={() => setCarryForwardModal(false)}
            />
            <Confirm
                isOpen={deleteModal}
                message='Are you sure you want to delete this service?'
                onYesClick={() => handleDeleteRow(selectedRow)}
                onNoClick={() => setDeleteModal(false)}
            />
            <Confirm
                isOpen={deleteTimesheetModal}
                message='Are you sure you want to delete this timesheet?'
                onYesClick={() => handleDeleteTimeSheet(selectedRow)}
                onNoClick={() => setDeleteTimesheetModal(false)}
            />
            <Confirm
                isOpen={deleteAllTimesheetModal}
                message='Are you sure you want to delete all timesheets?'
                onYesClick={() => handleDeleteAllTimeSheet(selectedRow)}
                onNoClick={() => setDeleteAllTimesheetModal(false)}
            />
        </React.Fragment >
    )
}

export default InvoiceForm;