import React, { useEffect, useState, useRef, useMemo } from "react";
// @ts-ignore
//import Board, { addCard } from '@asseinfo/react-kanban';
// @ts-ignore
import Board, { addCard } from "@lourenci/react-kanban";
import { Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, Row, UncontrolledDropdown } from "reactstrap";
import { map, size, cloneDeep } from "lodash";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Echo from "laravel-echo";
import { useTranslation } from "react-i18next";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import CardTaskBox from "./CardTaskBox";
import RenderCardTitle from "./RenderCardTitle";
import kanbanTasksService from "../../services/kanban.tasks.service";
import {
  KanbanTaskAction,
  TasksAction,
  TimeSheetsAction,
} from "../../store/actions";
import "./style.scss";
import TaskMoveModal from "./TaskMoveModal";
import SubTaskListModal from "../Tasks/SubTaskListModal";
import GlassWrapper from "../../components/Common/GlassWrapper";
import Breadcrumbs from "../../components/Layout/Breadcrumb";
import MoreUser from "./MoreUser";
import SubTasksService from "../../services/subtasks.service";
import { CommonAction } from "../../store/actions";
import NewTimeSheetFormModal from "../TimeSheets/NewTimeSheetForm";
import Tooltip from "../../components/Tooltip/Tooltip";

import FlagModal from "./FlagModal/Modal";
import tasksService from "../../services/tasks.service";
import { TaskStatus } from "../../utils/data";
import commonService from "../../services/common.service";
import { getUserProfile, datePickerI18, wssConfig } from "../../helpers/functions_helper";
import Drawer from "../../components/Drawer";
//import Filters from "./Filter";

declare global {
  interface Window {
    Pusher: any;
    Echo: any;
  }
}

window.Pusher = require("pusher-js");
window.Echo = new Echo(wssConfig());
const channel = window.Echo.channel("public.kanban.1");
//window.Pusher.logToConsole = true;

const KanbanBoard = (props: any) => {
  const { t } = useTranslation();
  document.title = `${t("Tasks")} - ${t("Kanban view")} | ` + process.env.REACT_APP_NAME;
  const dispatch = useDispatch();
  const toastId = React.useRef(null) as any;
  const tempDx: any = useRef([]);
  const selectedTask: any = useRef([]);
  const [assignee, setAssignee] = useState<any>(null);

  const userIDRef = useRef(0);
  const ROW_HEIGHT = 139;
  let navigate = useNavigate();
  const userProfile = getUserProfile("all-tasks");
  const subtaskProfile = getUserProfile("all-sub-task");
  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "#",
      label: t("Kanban view"),
      active: true,
    },
  ];

  let {
    kanbanData,
    kanbanSuccessMsg,
    current_time_get_data,
    kanbanloading,
    updateTaskData,
    updateTaskSuccessMsg,
    updateTaskErrorMsg,

    getTaskData,
    getTaskSuccessMsg,
    getTaskErrorMsg,

    createTimeSheetData,
    createTimeSheetSuccessMsg,
    createTimeSheetErrorData,
    createTimeSheetErrorMsg,

    getCompanyOptionsList,
    getUserOptionsList,
  } = useSelector((state: any) => ({
    kanbanData: state.KanbanTask.GET_KANBAN_TASKS_LIST_SUCCESS,
    kanbanSuccessMsg: state.KanbanTask.GET_KANBAN_TASKS_LIST_SUCCESS.message,
    current_time_get_data:
      state.KanbanTask.GET_KANBAN_TASKS_LIST_SUCCESS.current_time,
    updateTaskData: state.KanbanTask.UPDATE_KANBAN_TASK,
    updateTaskSuccessMsg: state.KanbanTask.UPDATE_KANBAN_TASK_SUCCESS.message,
    updateTaskErrorMsg: state.KanbanTask.UPDATE_KANBAN_TASK_ERROR,
    kanbanloading: state.KanbanTask.loading,

    getTaskData: state.Tasks.GET_TASK_SUCCESS,
    getTaskSuccessMsg: state.Tasks.GET_TASK_SUCCESS.message,
    getTaskErrorMsg: state.Tasks.GET_TASK_ERROR.message,

    createTimeSheetData: state.TimeSheets.CREATE_TIME_SHEET_FORM_SUCCESS,
    createTimeSheetSuccessMsg:
      state.TimeSheets.CREATE_TIME_SHEET_FORM_SUCCESS.message,
    createTimeSheetErrorData: state.TimeSheets.CREATE_TIME_SHEET_FORM_ERROR,
    createTimeSheetErrorMsg:
      state.TimeSheets.CREATE_TIME_SHEET_FORM_ERROR.message,

    getCompanyOptionsList: state.Common.GET_COMPANY_OPTIONS_LIST_SUCCESS_NEW,
    getUserOptionsList: state.Common.GET_USER_OPTIONS_LIST_SUCCESS_NEW,
  }));

  //Message
  const [filter, setFilter] = useState<any>({
    term: "",
    company: "",
    user: [],
    sub_task_filter: "",
    mytask: false,
    date: "",
    flag: 0,
    subtask: false,
    task_id: ""
  });
  const tempData = useRef<any>();
  const [kanbanViewData, setKanbanViewData] = useState<any>(() => {
    return kanbanData;
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [isFilter, setIsFilter] = useState<boolean>(true);
  const [filters, setFilters] = useState<any>({});
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  useEffect(() => {
    if (userProfile.USER_COMPANY !== 1) {
      return navigate("/tasks/all-tasks");
    }
  }, [userProfile.USER_COMPANY])

  useEffect(() => {
    if (updateTaskSuccessMsg) {
      dispatch(KanbanTaskAction.getKanbanTasksList([], props.history));
      updateToast(updateTaskSuccessMsg, "success");
      dispatch(KanbanTaskAction.updateKanbanTaskSuccess(""));
      if (kanbanData) {
        updatedTask();
      }
    }
  }, [updateTaskSuccessMsg]);

  const updatedTask = () => {
    let newData: any = { ...kanbanViewData };
    let moveData: any = { ...moveCardData.card };

    moveData.status = moveCardData.destination?.toColumnId;
    let data: any = kanbanViewData.columns[
      moveCardData.source.fromColumnId - 1
    ].cards.filter((item: any) => moveCardData.card.id != item.id);
    newData.columns[moveCardData.source.fromColumnId - 1].cards = data;
    newData.columns[moveCardData.source.fromColumnId - 1].count =
      newData.columns[moveCardData.source.fromColumnId - 1].count - 1;
    newData.columns[moveCardData.destination?.toColumnId - 1].count =
      newData.columns[moveCardData.destination?.toColumnId - 1].count + 1;
    const newBoard = addCard(
      kanbanViewData,
      { id: moveCardData.destination?.toColumnId },
      moveData
    );

    //Sort Record Due Date
    newBoard.columns[moveCardData.destination?.toColumnId - 1].cards.sort(
      function (a: any, b: any) {
        return a.due_date > b.due_date ? 1 : a.due_date < b.due_date ? -1 : 0;
      }
    );

    setKanbanViewData({ ...newBoard });
    updateInTempData(moveCardData);
  };

  //TODO
  //Temp Object Updated when move card
  const updateInTempData = (moveCardData: any) => {
    let moveDataTemp: any = { ...moveCardData.card };
    moveDataTemp.status = moveCardData.destination?.toColumnId;
    moveDataTemp.is_sub_task_filter = false;
    let data: any = tempData.current.columns[
      moveCardData.source.fromColumnId - 1
    ].cards.filter((item: any) => moveCardData.card.id != item.id);
    tempData.current.columns[moveCardData.source.fromColumnId - 1].cards = data;
    tempData.current.columns[moveCardData.source.fromColumnId - 1].count =
      tempData.current.columns[moveCardData.source.fromColumnId - 1].count - 1;
    tempData.current.columns[moveCardData.destination?.toColumnId - 1].count =
      tempData.current.columns[moveCardData.destination.toColumnId - 1].count +
      1;
    const newBoardx = addCard(
      tempData.current,
      { id: moveCardData.destination?.toColumnId },
      moveDataTemp
    );
    newBoardx.columns[moveCardData.destination?.toColumnId - 1].cards.sort(
      function (a: any, b: any) {
        return a.due_date > b.due_date ? 1 : a.due_date < b.due_date ? -1 : 0;
      }
    );
    tempData.current = newBoardx;
    updateHeightComponent();
  };

  //UPDATE COLUMN HEIGHT
  useEffect(() => {
    setTimeout(function () {
      updateHeightComponent();
    }, 6);
  }, [kanbanloading]);

  const updateHeightComponent = () => {
    if (document.getElementsByClassName("react-kanban-board").length != 0) {
      const scrollHeight =
        document.getElementsByClassName("react-kanban-board")[0].scrollHeight;
      const elements = document.getElementsByClassName("react-kanban-column");
      for (var i = 0; i < elements.length; i++) {
        var element = elements[i] as HTMLDivElement;
        element.style.height = `${scrollHeight}px`;
      }
    }
  };

  const handleFiltersChange = (obj: any) => {
    setFilter((preState: any) => ({ ...preState, date: obj.from_date }));
  };

  const onPrevDayClick = () => {
    const currentDate = moment().format("YYYY-MM-DD");
    let newDate: any = null;
    if (filter.date) {
      newDate = moment(filter.date, "YYYY-MM-DD").subtract(1, "days");
    } else {
      newDate = moment(currentDate, "YYYY-MM-DD").subtract(1, "days");
    }
    setFilter((preState: any) => ({
      ...preState,
      date: moment(newDate).format("YYYY-MM-DD"),
    }));
  };

  const onNextDayClick = () => {
    const currentDate = moment().format("YYYY-MM-DD");
    let newDate: any = null;
    if (filter.date) {
      newDate = moment(filter.date, "YYYY-MM-DD").add(1, "days");
    } else {
      newDate = moment(currentDate, "YYYY-MM-DD").add(0, "days");
    }
    setFilter((preState: any) => ({
      ...preState,
      date: moment(newDate).format("YYYY-MM-DD"),
    }));
  };

  // Sub Task List Model
  /**
   * TODO
   * SUB LIST DISPLAY WHEN USER UPDATE TASK SATUS SEND AND SUBTASK STATUS != SEND
   */
  const [subTaskListData, setSubTaskListData] = useState<any>({});
  const [isSubTaskList, setIsSubTaskList] = useState(false);

  useEffect(() => {
    if (updateTaskErrorMsg.flag === 1) {
      updateToast(updateTaskErrorMsg, "fail");
      dispatch(KanbanTaskAction.updateKanbanTaskError(""));
      setIsSubTaskList(true);
      setSubTaskListData(updateTaskErrorMsg);
    }
    if (updateTaskErrorMsg.flag == 0) {
      toast.error(updateTaskErrorMsg.message);
      dispatch(KanbanTaskAction.updateKanbanTaskError(""));
    }
  }, [updateTaskErrorMsg]);

  useEffect(() => {
    dispatch(KanbanTaskAction.getKanbanTasksList([], props.history));
  }, []);

  const updateTaskStatus = (data: any) => {
    dispatch(KanbanTaskAction.updateKanbanTask({ ...data }, props.history));
  };

  //Toast Message
  const updateToast = (msg: string, type: string, position?: string) => {
    const toastOption = {
      autoClose: 5000,
      hideProgressBar: false,
    };
    if (type == "success") toastId.current = toast.success(msg, toastOption);
    if (type == "fail") toastId.current = toast.warning(msg, toastOption);
  };

  const updatedKanbanData = (data: any) => {
    setLoading(true);
    filterCard(data);
    tempDx.current = JSON.parse(JSON.stringify(kanbanData.data));
    updateKanbanTemp({ ...tempDx.current });
    dispatch(KanbanTaskAction.getKanbanTaskSuccess(""));
    updateHeightComponent();
  };

  //const [virtualHeight, setvirtualHeight] = useState<number>(100);

  useEffect(() => {
    if (kanbanData) {
      updatedKanbanData(kanbanData.data);
    }
  }, [current_time_get_data]);

  const updateKanbanTemp = (params: any) => {
    tempData.current = params;
  };

  // Time Spent Model ----------------------------------------------------
  const [timeSpent, setTimeSpent] = useState(false);
  const [timeSpentData, setTimeSpentData] = useState<any>({});
  const [newTimeSpentFormModal, setNewTimeSpentFormModal] = useState(false);
  const TaskData: any = [];

  const handleSubmitTimeSpent = (values: any) => {
    toastId.current = toast(`${t("Creating")} ${t("timesheet")}...`, {
      autoClose: false,
      isLoading: true,
    });
    dispatch(TimeSheetsAction.createTimeSheetForm(values, props.history));
  };

  useEffect(() => {
    if (createTimeSheetSuccessMsg) {
      setTimeSpent(true);
      dispatch(TimeSheetsAction.createTimeSheetFormSuccess(""));
      setNewTimeSpentFormModal(false);

      //update task status
      if (moveCardData.card) {
        let moveData: any = { ...moveCardData.card };
        moveData.status = moveCardData.destination.toColumnId;
        updateTaskStatus({ ...moveData, assignee });
      }

      toast.update(toastId.current, {
        type: toast.TYPE.SUCCESS,
        render: createTimeSheetSuccessMsg,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
  }, [createTimeSheetSuccessMsg]);

  useEffect(() => {
    if (createTimeSheetErrorMsg) {
      dispatch(TimeSheetsAction.createTimeSheetFormError(""));

      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: createTimeSheetErrorMsg,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
  }, [createTimeSheetErrorMsg]);
  // Time Spent Model ----------------------------------------------------

  /* ROLE Preparer,Reviewer,FileManager,Partner  Option */
  useEffect(() => {
    if (!getCompanyOptionsList) dispatch(CommonAction.getCompanyOptionsList());
    if (!getUserOptionsList) dispatch(CommonAction.getUserOptionsList());
  }, []);

  const [UserOption, setUserOption] = useState([]);
  const [moreUser, setMoreUser] = useState([]);

  useEffect(() => {
    if (getUserOptionsList.data) {
      if (userProfile.USER_ID) {
        let userData = cloneDeep(getUserOptionsList.data);
        userData.unshift(
          userData.splice(
            userData.findIndex(
              (item: any) => item.id === userProfile.USER_ID
            ),
            1
          )[0]
        );
        setUserOption(userData);
        let fiterListWithStartValue = userData.filter(
          (_: any, i: number) => i > 4
        );
        setMoreUser(fiterListWithStartValue);
      }
    }
  }, [getUserOptionsList]);

  /* Company Master and User */
  const [companyOption, setCompanyOption] = useState([]);
  const [companyValue, setCompanyValue] = useState<any>([]);
  const [loadingCompany, setloadingCompanyOption] = useState(true);

  useEffect(() => {
    //dynamically change page-content bottom padding
    let elementPageContain =
      document.querySelector<HTMLElement>(".page-content");
    if (elementPageContain) {
      elementPageContain.style.paddingBottom = "0px";
    }
    //if filter present in
    const exiting_filter = kanbanTasksService.getFilter();
    if (exiting_filter) {
      setFilter((preState:any)=>({ ...preState, 
        term:exiting_filter?.term ? exiting_filter?.term : "",
        user:exiting_filter?.user ? exiting_filter?.user : [],
        sub_task_filter:exiting_filter?.sub_task_filter ? exiting_filter?.sub_task_filter : "",
        mytask:exiting_filter?.mytask ? exiting_filter?.mytask : false,
        date:exiting_filter?.date ? exiting_filter?.date : "",
        flag:exiting_filter?.flag ? exiting_filter?.flag : 0,
        subtask:exiting_filter?.subtask ? exiting_filter?.subtask :false,
        task_id: exiting_filter?.task_id ? exiting_filter?.task_id :""
      }));

      if(exiting_filter?.company_data){
        const company = exiting_filter?.company_data.map((item: any) => item?.value);
        setFilter((preState:any)=>({ ...preState, company,company_data:exiting_filter?.company_data }));
        setCompanyValue(exiting_filter?.company_data);
      }
    }
  }, []);

  useEffect(() => {
    if (getCompanyOptionsList.data) {
      setCompanyOption(getCompanyOptionsList.data);
      setloadingCompanyOption(false);
    }
  }, [getCompanyOptionsList]);

  /**
   * Filter
   */
  useEffect(() => {
    if (Array.isArray(companyValue)) {
      if (companyValue.length > 0) {
        const company = companyValue.map((item: any) => item?.value);
        setFilter((preState:any)=>({ ...preState, company,company_data:companyValue }));
      } else {
        setFilter((preState:any)=>({ ...preState, company: [] }));
      }
    }
  }, [companyValue]);

  const userFilter = (user: string) => {
    let items = filter.user;
    const index = items.indexOf(user);
    if (index > -1) {
      items = items.filter((e: any) => e !== user);
    } else {
      items.push(user);
    }
    setFilter((preState: any) => ({ ...preState, user: items }));
  };

  const searchFilter = (value: any) => {
    let terms = value;
    setFilter((preState: any) => ({ ...preState, term: terms }));
  };

  const filterButton = () => {
    if (filter.company != "" || filter.term != "" || filter.user.length > 0) {
      //setIsFilter(true);
    } else {
      //setIsFilter(false);
    }
  };

  //TODO
  //move filter
  const [isMoveTask, setIsMoveTask] = useState(false);
  const [moveCardData, setMoveCardData] = useState<any>([]);
  const [moveCardMsg, setMoveCardMsg] = useState(false);

  const moveCard = (_card: any, source: any, destination: any) => {
    if (_card?.flag === 1) {
      const messg = t(
        `${t("The Task/Subtask is blocked by")} ${_card.tagging_user_name}. ${t(
          "The status cannot be changed until flagged OFF."
        )}`
      );
      toast.dismiss();
      toast.warn(messg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (
      _card?.q_id !== null &&
      (_card?.q_status_id !== 6 && _card?.q_status_id !== 5) &&
      destination.toColumnId === 6
    ) {
      const messg = t(
        `${t(
          "Task/Subtask status cannot be changed to sent as Questionnaire status is not yet finalized."
        )}`
      );
      toast.dismiss();
      toast.warn(messg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (
      _card?.q_id !== null &&
      _card?.q_status_id !== 9 &&
      destination?.toColumnId === 7
    ) {
      const messg = t(
        `${t(
          "You cannot cancel the Task/Subtask until the Questionnaire linked to this Task/Subtask is terminated."
        )}`
      );
      toast.dismiss();
      toast.warn(messg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }


    if (
      parseInt(destination?.toColumnId) === 8 ||
      (parseInt(destination?.toColumnId) === 9 &&
        parseInt(source?.fromColumnId) !== 8) ||
      (parseInt(source?.fromColumnId) === 8 &&
        parseInt(destination?.toColumnId) < 8)
    ) {
      const msg = t(
        `${t(
          "Task/Subtask cannot move in this status."
        )}`
      );
      const toastOption = {
        autoClose: 5000,
        hideProgressBar: false,
      };
      toastId.current = toast.warn(msg, toastOption);
      return;
    }

    let data = {
      card: _card,
      source,
      destination,
    };

    if (data?.destination?.toColumnId === data?.source?.fromColumnId) return;

    if (
      userProfile.USER_ID === parseInt(_card.preparer) &&
      (parseInt(destination.toColumnId) === 1 ||
        parseInt(destination.toColumnId) === 2 ||
        parseInt(destination.toColumnId) === 3) &&
      source.fromColumnId !== 3 &&
      source.fromColumnId !== 6 &&
      source.fromColumnId !== 7
    ) {
      setMoveCardData(data);
      setMoveCardMsg(false);
      setIsMoveTask(true);
    } else if (
      userProfile.USER_ID === parseInt(_card.reviewer) &&
      (parseInt(destination.toColumnId) === 2 ||
        parseInt(destination.toColumnId) === 3 ||
        parseInt(destination.toColumnId) === 4) &&
      source.fromColumnId === 3 &&
      source.fromColumnId !== 6 &&
      source.fromColumnId !== 7
    ) {
      setMoveCardData(data);
      setMoveCardMsg(false);
      setIsMoveTask(true);
    } else if (
      userProfile.USER_ID === parseInt(_card.file_manager) &&
      (parseInt(destination.toColumnId) === 3 ||
        parseInt(destination.toColumnId) === 4 ||
        parseInt(destination.toColumnId) === 5 ||
        parseInt(destination.toColumnId) === 6 ||
        parseInt(destination.toColumnId) === 7) &&
      source.fromColumnId === 4 &&
      source.fromColumnId !== 6 &&
      source.fromColumnId !== 7
    ) {
      setMoveCardData(data);
      setMoveCardMsg(false);
      setIsMoveTask(true);
    } else if (
      userProfile.USER_ID === parseInt(_card.partner) &&
      source.fromColumnId !== 6 &&
      source.fromColumnId !== 7
    ) {
      setMoveCardData(data);
      setMoveCardMsg(false);
      setIsMoveTask(true);
    } else if (
      (userProfile.USER_ID === parseInt(_card.assistant_administrative) || userProfile.USER_ID === parseInt(_card.assignee)) &&
      source.fromColumnId === 5 &&
      destination.toColumnId === 6
    ) {
      setMoveCardData(data);
      setMoveCardMsg(false);
      setIsMoveTask(true);
    } else if (
      ((!_card.is_sub_task && userProfile.CAN_EDIT_ALL) || (_card.is_sub_task && subtaskProfile.CAN_EDIT_ALL)) &&
      source.fromColumnId !== 6 &&
      source.fromColumnId !== 7
    ) {
      setMoveCardData(data);
      setMoveCardMsg(false);
      setIsMoveTask(true);
    } else {
      const msg = t(
        `${t(
          "Sorry you don't have permission to change status."
        )}`
      );
      const toastOption = {
        autoClose: 5000,
        hideProgressBar: false,
      };
      toastId.current = toast.warn(msg, toastOption);
    }
  };

  const updateTimeStamp = (data: any) => {
    let module_id = 0;
    let module_main_id = 0;
    let module_sub_id = 0;
    let main_id = 0;

    if (data.task_id) {
      module_id = 2;
      module_main_id = data.pid;
      module_sub_id = data.task_id;
      main_id = data.pid;
    }
    if (data.subtask_id) {
      module_id = 7;
      module_main_id = data.pid;
      module_sub_id = data.subtask_id;
      main_id = data.FK_task_id;
    }

    setTimeSpentData({
      is_new: false,
      module_id: module_id, // 2-Task // 7-Subtask
      module_main_id: module_main_id, // ID
      module_sub_id: module_sub_id, // Sub ID
      main_id: main_id,
      title: data?.title,
      company: data.company,
      project: data?.project,
      contact_person: data?.contact_person,
      responsible_person: data?.responsible_person,
      fees_type: data.type,
      fees_amount: data.fees_amount,
      expense: 0, // data.expense,
      is_sensitive: data.sensitive,

      start_date: "",
      start_time: "",
      end_time: "",
      time_spent: "",
    });
    setNewTimeSpentFormModal(true);
  };

  const onYesClick = (data: any) => {
    setIsMoveTask(false);
    let moveData: any = { ...moveCardData.card };
    moveData.status = moveCardData.destination.toColumnId;
    setAssignee(data?.assignee);
    updateTimeStamp(moveData);
  };

  // const [isSubTaskFiter, setIsSubTaskFilter] = useState(false);
  // const subTaskFilter = useCallback(async (taskid: number) => {
  //   if (isSubTaskFiter) {
  //     setKanbanViewData(tempData.current);
  //     setFilter((preState: any) => ({ ...preState, sub_task_filter: "" }));
  //     setIsSubTaskFilter(false);
  //   } else {
  //     setFilter((preState: any) => ({ ...preState, sub_task_filter: taskid }));
  //     const cpData = JSON.parse(JSON.stringify(tempData.current));
  //     if (taskid != null) {
  //       await cpData.columns.forEach((element: any, index: number) => {
  //         var edata = element.cards.filter((s: any, i: number) => {
  //           if (s.is_sub_task) {
  //             if (s.is_sub_task && parseInt(s.FK_task_id) === taskid) {
  //               cpData.columns[index].cards[i].is_sub_task_filter = true;
  //               return s.is_sub_task && parseInt(s.FK_task_id) === taskid;
  //             }
  //           } else {
  //             if (s.pid === taskid && s.is_sub_task === false)
  //               cpData.columns[index].cards[i].is_sub_task_filter = true;

  //             return s.pid === taskid && s.is_sub_task === false;
  //           }
  //         });
  //         cpData.columns[index].cards = edata;
  //         cpData.columns[index].count = edata.length;
  //       });
  //       setIsSubTaskFilter(true);
  //       setKanbanViewData(cpData);
  //     }
  //   }
  // }, []);

  const subTaskFilter = (task_id: number) => {
    if (filter.subtask && filter.task_id != "") {
      setFilter((preState: any) => ({ ...preState, subtask: false, task_id: "" }));
    } else
      setFilter((preState: any) => ({ ...preState, subtask: true, task_id: task_id }));
  }

  const onNoClick = () => {
    setIsMoveTask(false);
  };

  const filterCard = (data: any) => {
    const cpData = JSON.parse(JSON.stringify(data));
    if (
      filter.term != "" ||
      filter.company.length > 0 ||
      filter.user.length > 0 ||
      filter.date != "" || filter.flag != "" || (filter?.subtask && filter?.task_id)
    ) {
       cpData.columns.forEach((element: any, index: number) => {
        var edata = element.cards.filter((s: any, subindex: number) => {
          return Object.values(s).some((str: any) => {
            if (typeof str == "string") {
              //if fiter only term value
              if (
                filter.term != "" 
                && filter.company.length == 0 
                && filter.user.length == 0 
                && filter.flag == 0 
                && (filter?.subtask == false && filter?.task_id == "")
                && filter?.date =='' 
              ) {
                return filter.term
                  ? str.toLowerCase().includes(filter.term.toLowerCase())
                  : false;
              }

              //if fiter having term and company value
              if (
                filter.term != "" 
                && filter.company.length > 0 
                && filter.user.length == 0 
                && filter.flag == 0 
                && (filter?.subtask == false && filter?.task_id == "")
                && filter?.date ==''
              ) {
                return (
                  (filter.term
                    ? str.toLowerCase().includes(filter.term.toLowerCase())
                    : false) &&
                  (filter.company.length > 0 && filter?.company.some((x: number) => s?.company == x))
                  //(filter.company ? s?.company == filter.company : false)
                );
              }

              //if fiter having term and user value
              if (
                filter.term != "" 
                && filter.company.length == 0 
                && filter.user.length > 0 
                && filter.flag == 0 
                && (filter?.subtask == false && filter?.task_id == "")
                && filter?.date ==''
              ) {
                return (
                  (filter.term
                    ? str.toLowerCase().includes(filter.term.toLowerCase())
                    : false) && uFilter(s)
                );
              }

              //if fiter having company value
              if (
                filter.term == "" 
                && filter.company.length > 0 
                && filter.user.length == 0 
                && s?.company_name != null 
                && filter.flag == 0 
                && (filter?.subtask == false && filter?.task_id == "")
                && filter?.date ==''
              ) {
                return filter.company.length > 0 && filter?.company.some((x: number) => s?.company == x)
                //return filter.company ? s?.company == filter.company : false;
              }

              //if fiter having company and user value
              if (
                filter.term == "" &&
                filter.company.length > 0 &&
                filter.user.length > 0 
                && filter?.date =='' &&
                s?.company_name != null && filter.flag == 0 && (filter?.subtask == false && filter?.task_id == "")
                && filter?.date ==''
              ) {
                return (
                  (filter.company.length > 0 && filter?.company.some((x: number) => s?.company == x)) &&
                  uFilter(s)
                );
              }

              //if fiter having user value
              if (
                filter.term == "" &&
                filter.company.length == 0 &&
                filter.user.length > 0 && filter.flag == 0 && (filter?.subtask == false && filter?.task_id == "")
                && filter?.date ==''
              ) {
                return uFilter(s);
              }

              //if filter having flag value
              if (
                filter.term == "" &&
                filter.company.length == 0 &&
                filter.user.length == 0 &&
                filter.flag == 1 && (filter?.subtask == false && filter?.task_id == "")
                && filter?.date ==''
              ) {
                return (filter.flag ? s?.flag == filter.flag ? true : false : false);
              }

              //if filter having flag and terms value 
              if (
                filter.term != "" &&
                filter.company.length == 0 &&
                filter.user.length == 0 &&
                filter.flag == 1 && (filter?.subtask == false && filter?.task_id == "")
                && filter?.date ==''
              ) {
                return ((filter.term ? str.toLowerCase().includes(filter.term.toLowerCase()) : false) && (filter.flag ? s?.flag == filter.flag ? true : false : false));
              }

              //if filter having flag and terms value and company
              if (
                filter.term != "" &&
                filter.company.length > 0 &&
                filter.user.length == 0 &&
                filter.flag == 1 && (filter?.subtask == false && filter?.task_id == "")
                && filter?.date ==''
              ) {
                return ((filter.company.length > 0 && filter?.company.some((x: number) => s?.company == x)) && (filter.term ? str.toLowerCase().includes(filter.term.toLowerCase()) : false) && (filter.flag ? s?.flag == filter.flag ? true : false : false));
              }

              //if filter having flag and company
              if (
                filter.term == "" &&
                filter.company.length > 0 &&
                filter.user.length == 0 &&
                filter.flag == 1 && (filter?.subtask == false && filter?.task_id == "")
                && filter?.date ==''
              ) {
                return ((filter.company.length > 0 && filter?.company.some((x: number) => s?.company == x)) && (filter.flag ? s?.flag == filter.flag ? true : false : false));
              }

              //if filter having flag and user
              if (
                filter.term == "" &&
                filter.company.length == 0 &&
                filter.user.length > 0 &&
                filter.flag == 1 && (filter?.subtask == false && filter?.task_id == "")
                && filter?.date ==''
              ) {
                return (uFilter(s) && (filter.flag ? s?.flag == filter.flag ? true : false : false));
              }

              //if filter having unflag and user
              if (
                filter.term == "" &&
                filter.company.length == 0 &&
                filter.user.length > 0 &&
                filter.flag == 2 && (filter?.subtask == false && filter?.task_id == "")
                && filter?.date ==''
              ) {
                return (uFilter(s) && (filter.flag ? s?.flag == !filter.flag ? true : false : false));
              }


              //if filter having unflag value
              if (
                filter.term == "" &&
                filter.company.length == 0 &&
                filter.user.length == 0 &&
                filter.flag == 2 && (filter?.subtask == false && filter?.task_id == "")
                && filter?.date ==''
              ) {
                return (filter.flag ? s?.flag == !filter.flag ? true : false : false);
              }

              //if filter having date then
              if (filter?.date != "" && (filter?.subtask == false && filter?.task_id == "") && filter.company.length == 0 && filter.user.length == 0) {
                const daterang = filter?.date.split("to");
                if (daterang.length > 1) {
                  var compareDate = moment(s?.closing_date, "YYYY-MM-DD");
                  var startDate = moment(daterang[0].trim(), "YYYY-MM-DD");
                  var endDate = moment(daterang[1].trim(), "YYYY-MM-DD");
                  return compareDate.isBetween(startDate, endDate)
                } else {
                  var compareDate = moment(s?.closing_date, "YYYY-MM-DD");
                  var startDate = moment(daterang[0].trim(), "YYYY-MM-DD");
                  return compareDate.isSame(startDate);
                }
              }

              if (
                filter.term == "" &&
                filter.company.length > 0 &&
                filter.user.length == 0 &&
                filter?.date != "" &&
                filter.flag == 0 && (filter?.subtask == false && filter?.task_id == "")
              ) {
                const daterang = filter?.date.split("to");
                if (daterang.length > 1) {
                  var compareDate = moment(s?.closing_date, "YYYY-MM-DD");
                  var startDate = moment(daterang[0].trim(), "YYYY-MM-DD");
                  var endDate = moment(daterang[1].trim(), "YYYY-MM-DD");
                  return (compareDate.isBetween(startDate, endDate) && (filter.company.length > 0 && filter?.company.some((x: number) => s?.company == x)));
                } else {
                  var compareDate = moment(s?.closing_date, "YYYY-MM-DD");
                  var startDate = moment(daterang[0].trim(), "YYYY-MM-DD");
                  return (compareDate.isSame(startDate) && (filter.company.length > 0 && filter?.company.some((x: number) => s?.company == x)));
                }
              }

              //SUBTASK FILTER
              if ((filter?.subtask && filter?.task_id != "") &&
                filter.term == "" &&
                filter.company.length == 0 &&
                filter.user.length == 0 &&
                filter.flag == 0) {
                if (s.is_sub_task) {
                  if (s.is_sub_task && parseInt(s.FK_task_id) === filter?.task_id) {
                    cpData.columns[index].cards[subindex].is_sub_task_filter = true;
                    return (s.is_sub_task && parseInt(s.FK_task_id) === filter?.task_id);
                  }
                } else {
                  if (s.pid === filter?.task_id && s.is_sub_task === false)
                    cpData.columns[index].cards[subindex].is_sub_task_filter = true;

                  return s.pid === filter?.task_id && s.is_sub_task === false;
                }

              }

              //SUBTASK FILTER WITH COMPANY 
              if ((filter?.subtask && filter?.task_id != "") &&
                filter.term == "" &&
                filter.company.length > 0 &&
                filter.user.length == 0 &&
                filter.flag == 0) {
                if (s.is_sub_task) {
                  if (s.is_sub_task && parseInt(s.FK_task_id) === filter?.task_id) {
                    cpData.columns[index].cards[subindex].is_sub_task_filter = true;
                    return (s.is_sub_task && parseInt(s.FK_task_id) === filter?.task_id) && ((filter.company.length > 0 && filter?.company.some((x: number) => s?.company == x)));
                  }
                } else {
                  if (s.pid === filter?.task_id && s.is_sub_task === false)
                    cpData.columns[index].cards[subindex].is_sub_task_filter = true;

                  return (s.pid === filter?.task_id && s.is_sub_task === false) && ((filter.company.length > 0 && filter?.company.some((x: number) => s?.company == x)));
                }
              }

              return (
                (filter.term
                  ? str.toLowerCase().includes(filter.term.toLowerCase())
                  : false) &&
                (s?.company != null && (filter.company.length > 0 && filter?.company.some((x: number) => s?.company == x))) &&
                uFilter(s)
              );
            } else {
              return false;
            }
          });
        });

        cpData.columns[index].cards = edata;
        cpData.columns[index].count = edata.length;
      });
      setKanbanViewData(cpData);
    } else {
      setKanbanViewData(cpData);
      updateHeightComponent();
    }
  };

  function uFilter(value: any) {
    if (filter.user.length > 0) {
      return filter.user.some((e: any) => {
        if (filter.mytask === true)
          return (
            value.preparer === e ||
            value.reviewer === e ||
            value.file_manager === e ||
            value.partner === e ||
            value.assistant_administrative === e ||
            value.assignee === e
          );
        switch (value.status) {
          case 1:
            return value.preparer === e;
          case 2:
            return value.preparer === e;
          case 3:
            return value.reviewer === e;
          case 4:
            return value.file_manager === e;
          case 5:
            return value.partner === e || value.assistant_administrative === e || value.assignee === e;
          default:
            return false;
        }
      });
    } else return false;
  }

  useMemo(()=>{
    if (typeof tempData.current == "object") {
      kanbanTasksService.setFilter(filter);
      filterCard(tempData.current);
    }
  },[filter])

  const clearFilter = () => {
    setFilter({ term: "", company: "", user: [], mytask: false, date: "", flag: 0, subtask: false, task_id: '' });
    setCompanyValue([]);
    dispatch(KanbanTaskAction.getKanbanTasksList([], props.history));
  };

  // const [xvalue, setXvalue] = useState(0);
  // const [scrollValue, setScrollValue] = useState(0);

  // const onMouseMove = (event: any) => {
  //   setXvalue(event.current.x);
  // };

  // const scrollEventList = (value: any) => {
  //  
  // }

  // useEffect(() => {
  //   if (document.getElementsByClassName('react-kanban-board').length != 0)
  //     setScrollValue(document.getElementsByClassName('react-kanban-board')[0].scrollWidth);

  //   const element = (document.getElementsByClassName('react-kanban-board')[0] as HTMLDivElement);

  //   const onScroll = (e: any) => {
  //     const target = e.target as HTMLTextAreaElement;
  //     setScrollValue(target.scrollLeft);
  //   };
  //   if (element) {
  //     element.addEventListener('scroll', onScroll);
  //   }

  // }, [])

  // useEffect(() => {
  //   if (document.getElementsByClassName('react-kanban-board').length != 0) {
  //     setScrollValue(document.getElementsByClassName('react-kanban-board')[0].scrollWidth);
  //     switch (true) {
  //       case xvalue >= 0 && xvalue <= 5:
  //         document.getElementsByClassName('react-kanban-board')[0].scrollLeft = 0;
  //         break
  //       case xvalue >= 5 && xvalue <= 10:
  //         document.getElementsByClassName('react-kanban-board')[0].scrollLeft = Math.round(scrollValue / 6);
  //         break;
  //       case xvalue >= 10 && xvalue <= 15:
  //         document.getElementsByClassName('react-kanban-board')[0].scrollLeft = Math.round(scrollValue / 5);
  //         break
  //       case xvalue >= 15 && xvalue <= 20:
  //         document.getElementsByClassName('react-kanban-board')[0].scrollLeft = Math.round(scrollValue / 4);
  //         break;
  //       case xvalue >= 21 && xvalue <= 25:
  //         document.getElementsByClassName('react-kanban-board')[0].scrollLeft = Math.round(scrollValue / 3);
  //         break
  //       case xvalue >= 25 && xvalue <= 28:
  //         document.getElementsByClassName('react-kanban-board')[0].scrollLeft = Math.round(scrollValue / 2);
  //         break
  //       case xvalue >= 29 && xvalue <= 31:
  //         document.getElementsByClassName('react-kanban-board')[0].scrollLeft = scrollValue;
  //         break
  //       default:
  //         //document.getElementsByClassName('react-kanban-board')[0].scrollLeft = 0;
  //         break;
  //     }
  //   }
  // }, [xvalue])

  //Sidebar Task Details
  const getTask = async (id: string, task_id: string) => {
    dispatch(TasksAction.getTask({ id, task_id }, props.history));
  };

  //Add flag for need more information for the task
  const [isAddFlag, setIsAddFlag] = useState<boolean>(false);

  const addFlag = (task: any) => {
    selectedTask.current = task;
    setIsAddFlag(true);
  };

  const removeFlag = async (task: any) => {
    toast.dismiss();
    toastId.current = toast(t("Updating flag..."), {
      autoClose: false,
      isLoading: true,
    });
    const postData = {
      id: task.pid,
      task_id: task?.is_sub_task ? task?.subtask_id : task?.task_id,
      is_sub_task: task?.is_sub_task
    };
    const res = await tasksService.removeFlag(postData);
    if (res.data.status === "success") {
      toast.update(toastId.current, {
        type: toast.TYPE.SUCCESS,
        render: t("Flag removed successfully"),
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      reflagUpdateSuccess(task);
    } else {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: t("Something went wrong."),
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
  };

  const handleArchive = async (task: any) => {
    toastId.current = toast(t("Updating archive status..."), {
      autoClose: false,
      isLoading: true,
    });

    const res = (await commonService.handleArchive({
      id: task.pid,
      table_name: task.is_sub_task === true ? "tbl_subtask" : "tbl_task",
      field_name: "is_archive",
      is_archive: 0,
    })) as any;

    if (res.data.status === "success") {
      toast.update(toastId.current, {
        type: toast.TYPE.SUCCESS,
        render: res.data.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      dispatch(KanbanTaskAction.getKanbanTasksList([], props.history));
    } else {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: res.data.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
  };

  const flagUpdateSuccess = (task: any) => {
    updateTimeStamp(task);
    dispatch(KanbanTaskAction.getKanbanTasksList([], props.history));
  };
  const reflagUpdateSuccess = (task: any) => {
    dispatch(KanbanTaskAction.getKanbanTasksList([], props.history));
  };

  // const [toggleSideTaskBar, setToggleSideTaskBar] = useState(false);

  // const getItem = async (id: string, sub_task_id: string) => {
  //   if (tempData.current.columns) {
  //     const res = kanbanViewData.columns.find((item: any) =>
  //       item.cards.some(
  //         (it: any) =>
  //           it.is_sub_task === true &&
  //           it.pid === id &&
  //           it.subtask_id === sub_task_id
  //       )
  //     );
  //     return res.cards[0];
  //   }
  // };

  const updateMultipleTaskStatus = async (filterData: any) => {
    const updateSubTaskList =
      (await SubTasksService.updateMultipleSubTaskStatus({
        data: filterData,
        status: subTaskListData.update_status,
      })) as any;

    if ((updateSubTaskList.data.status = "success")) {
      updateToast("Subtask successfully updated", "success");
      dispatch(KanbanTaskAction.getKanbanTasksList([], props.history));
      // const destination = { toPosition: 0, toColumnId: updateSubTaskList.data.update_status }

      // //const card =
      // let moveCard = {};
      //
      // //const sub_task_item = getItem()

      // updateSubTaskList.data.data.forEach(async (element: any) => {
      //   let record = await getItem(element.id, element.subtask_id);
      //   setMoveCardData({});
      //   const source = { fromPosition: 0, fromColumnId: record.status };
      //   let moveData = {
      //     card:record,
      //     source,
      //     destination
      //   };
      //   if(record){
      //     setMoveCardData(moveData);
      //     updatedTask();
      //   }

      // });
      //updatedTask();
      setIsSubTaskList(false);
    } else {
      updateToast("Subtask not update", "fail");
    }
  };

  //GET TASK LOCATION WHERE TASK PRESENT
  // const taskItem = useRef<any>([]);
  // const wsTask = useRef<any>([]);
  // const [dD, setDD] = useState(false);
  // const getCurrentTaskItem = (task: any) => {
  //   if (tempDx.current) {
  //     const res = tempDx.current.columns.map((item: any) =>
  //       item.cards.some((it: any) => {
  //         if (
  //           it.is_sub_task === true &&
  //           it.pid === task.id &&
  //           it.subtask_id === task.subtask_id
  //         ) {
  //           taskItem.current = it;
  //           setDD(true);
  //         } else if (it.pid === task.id && it.task_id === task.task_id) {
  //           taskItem.current = it;
  //           setDD(true);
  //         }
  //       })
  //     );
  //   }
  // };

  // useEffect(() => {
  //   if (taskItem.current) {
  //     let source = { fromPosition: 0, fromColumnId: taskItem.current.status };
  //     let destination = { toPosition: 0, toColumnId: wsTask.current.status };
  //     let data = {
  //       card: taskItem.current,
  //       source,
  //       destination
  //     }
  //     setMoveCardData(data);
  //     updatedTask();
  //   }
  // }, [dD])

  //WEB SOCKET

  const connectKanbanLive = () => {
    channel.listen(".kanban", (e: any) => {
        if (e.user_id != userIDRef.current) {
          dispatch(KanbanTaskAction.getKanbanTasksList([], props.history));
          toast.dismiss();
          const toastOption = {
            autoClose: 2000,
            hideProgressBar: false,
            position: toast.POSITION.TOP_RIGHT,
          };
          toastId.current = toast.update(
            `"${e.task.title}" updated task....`,
            toastOption
          );
        }
      })
      .error((e: any) => console.log(e, "error"));
  };

  useEffect(() => {
    connectKanbanLive();
  }, []);

  const UserLabel = useMemo(() => `${size(UserOption) - 5}+`, [UserOption]);

  return (
    <React.Fragment>
      {newTimeSpentFormModal && (
        <NewTimeSheetFormModal
          show={newTimeSpentFormModal}
          loading={loading}
          timeSpentData={timeSpentData}
          onSubmitClick={handleSubmitTimeSpent}
          onCloseClick={() => setNewTimeSpentFormModal(false)}
        />
      )}
      {isMoveTask && (
        <TaskMoveModal
          from={moveCardData.source}
          to={moveCardData.destination}
          data={moveCardData.card}
          show={isMoveTask}
          onYesClick={(data: any) => onYesClick(data)}
          onNoClick={() => onNoClick()}
          onCloseClick={() => setIsMoveTask(false)}
        />
      )}
      {isSubTaskList && (
        <SubTaskListModal
          subTaskList={subTaskListData}
          show={isSubTaskList}
          onCloseClick={() => setIsSubTaskList(false)}
          onUpdateSubTaskStatus={(data: any) => updateMultipleTaskStatus(data)}
        />
      )}

      {isAddFlag && (
        <FlagModal
          show={isAddFlag}
          task={selectedTask.current}
          success={flagUpdateSuccess}
          onCloseClick={() => setIsAddFlag(false)}
        />
      )}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={t("Tasks")}
            breadcrumbItems={breadcrumbItems}
            endAdornment={
              <div className="d-flex gap-2 text-end">
                {userProfile?.IS_INTERNAL && (
                  <>
                    <Link
                      to="/tasks/all-tasks"
                      className={`btn btn-outline-secondary btn-label btn-sm`}
                    >
                      <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                        list_alt
                      </span>
                      {t("List view")}
                    </Link>

                    <Link
                      to="/tasks/kanban-view"
                      className={`btn btn-info btn-label btn-sm`}
                    >
                      <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                        view_kanban
                      </span>
                      {t("Kanban view")}
                    </Link>
                  </>
                )}

                {userProfile?.IS_INTERNAL && userProfile?.CAN_VIEW_ALL && (
                  <Link to="/tasks/graph" className={`btn btn-outline-secondary btn-label btn-sm`}>
                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                      insert_chart
                    </span>
                    {t("Graphical view")}
                  </Link>
                )}

                {userProfile.CAN_CREATE ? (
                  <Link to="/tasks/new-task" className="btn btn-primary btn-label btn-sm">
                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                      add
                    </span>{" "}
                    {t("Create task")}
                  </Link>
                ) : null}
              </div>
            }
          />
          <Row className="bg-light align-items-center mb-1 p-2">
            <Col sm={3} className="search-box pe-1">
              <div>
                <div className="search-box">
                  <div className="position-relative">
                    <Input
                      type="text"
                      onChange={(e) => searchFilter(e.target.value)}
                      value={filter.term}
                      className="form-control rounded bg-white"
                      placeholder={t("Search")}
                    />
                    <span className="align-middle material-symbols-outlined fw-light fs-4 search-icon">
                      search
                    </span>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              sm={3}
              className="avatar-group float-start pe-0 align-items-center"
              style={{ width: "auto" }}
            >
              {map(
                UserOption,
                (member: any, index) =>
                  index < 5 && (
                    <div
                      className={"avatar-group-item avatar-kanban-group "}
                      key={member.values}
                    >
                      <Link
                        to="#"
                        onClick={() => userFilter(member.value)}
                        className={
                          "d-inline-block in-active-border " +
                          (filter.user.indexOf(member.value) == -1
                            ? ""
                            : "active-border")
                        }
                      >
                        {member.label && (
                          <Tooltip content={member.label} direction="top">
                            <div
                              className="avatar-xs"
                              style={{ height: "1.6rem", width: "1.6rem" }}
                            >
                              <span
                                className={`avatar-title rounded-circle ${index == 0 ? "bg-primary" : "bg-info"
                                  } text-white font-size-14 ktooltip`}
                                id={"person_" + index}
                                style={{ textTransform: "uppercase" }}
                              >
                                {member.label.charAt(0)}
                              </span>
                            </div>
                          </Tooltip>
                        )}
                      </Link>
                    </div>
                  )
              )}
              {size(UserOption) > 5 && (
                <MoreUser
                  label={UserLabel}
                  updateUser={(e) => userFilter(e.value)}
                  moreUserList={moreUser}
                  selectUser={filter.user}
                />
              )}
            </Col>

            <Col sm={3} className="pe-0 ps-1">
              <Select
                id="compnay"
                name="compnay"
                className="is-invalid bg-light"
                options={companyOption}
                isSearchable={true}
                value={companyValue}
                placeholder={t("Select Client")}
                isDisabled={loadingCompany}
                onChange={(option: any) => {
                  setCompanyValue(option);
                }}
                styles={{
                  menu: (provided, state) => ({ ...provided, zIndex: 9999 }),
                }}
                isMulti
              />
            </Col>
            <Col className="ps-0">
              <Tooltip content={t("My Task")} direction="top">
                <button
                  className={`btn ms-1 ${filter.mytask ? "btn-primary" : "bg-white btn-outline-light"
                    }`}
                  style={{ borderColor: "#ced4da" }}
                  onClick={() => {
                    setFilter((preState: any) => ({
                      ...preState,
                      mytask: !preState.mytask,
                    }));
                    const user_id = userProfile.USER_ID ? userProfile.USER_ID : "";
                    userFilter(user_id);
                  }}
                >
                  <span className="align-middle material-symbols-outlined fw-light fs-4">
                    account_circle
                  </span>
                </button>
              </Tooltip>

              <div
                className="dt-buttons btn-group ms-1"
                style={{ width: "340px" }}
              >
                <div className="input-group">
                  <button
                    className="btn bg-white btn-outline-light"
                    title={t("Previous day")}
                    onClick={() => onPrevDayClick()}
                  >
                    <span className="align-middle material-symbols-outlined fw-light fs-4">
                      chevron_left
                    </span>
                  </button>
                  <Flatpickr
                    id="from_to_date"
                    name="from_to_date"
                    className="form-control d-block"
                    type="text"
                    placeholder="DD/MM/YYYY"
                    value={filter?.date}
                    options={{
                      altInput: true,
                      altFormat: "d/m/Y",
                      dateFormat: "Y-m-d",
                      mode: "range",
                      allowInput: true,
                      onChange: function (selectedDates, dateStr, instance) {
                        handleFiltersChange({
                          is_filtered: true,
                          from_date: dateStr,
                          to_date: dateStr,
                        });
                      },
                      locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                    }}
                  />
                  <span className="input-group-text">
                    <span className="align-middle material-symbols-outlined fw-light fs-4">
                      calendar_today
                    </span>
                  </span>
                  {moment().format("YYYY-MM-DD") !==
                    moment(filter?.date).format("YYYY-MM-DD") && (
                      <button
                        className="btn bg-white btn-outline-light"
                        title={t("Next day")}
                        onClick={() => onNextDayClick()}
                      >
                        <span className="align-middle material-symbols-outlined fw-light fs-4">
                          chevron_right
                        </span>
                      </button>
                    )}
                </div>
              </div>

              {/* <button
                className={`btn ms-1 ${kanbanloading ? "btn-primary" : "bg-white btn-outline-light"
                  }`}
                style={{ borderColor: "#ced4da" }}
                onClick={() => setOpenFilter((state) => !state)}
              >
                <span className="align-middle material-symbols-outlined fw-light fs-4">filter_alt</span>
              </button> */}

              <UncontrolledDropdown style={{ display: 'inline-block' }}>
                <DropdownToggle
                  href="#"
                  className={`btn ms-1 ${filter.flag != 0 ? "btn-primary" : "bg-white btn-outline-light"}`}
                  tag="a"
                  style={{ borderColor: "#ced4da" }}
                >
                  <span className="align-middle material-symbols-outlined fw-light fs-4">flag</span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem
                    href="#"
                    onClick={() => setFilter((preState: any) => ({ ...preState, flag: (preState.flag === 0 || preState.flag === 2) ? 1 : 0 }))}
                  >
                    <div className="form-check form-check-inline">
                      <Input className="form-check-input" type="checkbox" id="flaggedCheckbox"
                        value="1"
                        checked={filter.flag === 1 ? true : false}
                      //onChange={(e) => setFilter((preState: any) => ({ ...preState, flag: e.target.checked ? 1 : 0 }))}
                      />
                      <Label className="form-check-label" htmlFor="flaggedCheckbox">{t("Flagged")}</Label>
                    </div>
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    onClick={() => setFilter((preState: any) => ({ ...preState, flag: (preState.flag === 0 || preState.flag === 1) ? 2 : 0 }))}
                  >
                    <div className="form-check form-check-inline">
                      <Input className="form-check-input" type="checkbox" id="unflaggedCheckbox"
                        value="2"
                        checked={filter.flag === 2 ? true : false}
                      //onChange={(e) => setFilter((preState: any) => ({ ...preState, flag:preState.flag===0 ? 2 : 0}))}
                      />
                      <Label className="form-check-label" htmlFor="unflaggedCheckbox">{t("Unflagged")}</Label>
                    </div>

                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              {isFilter && (
                <button
                  className={`btn ms-1 ${kanbanloading ? "btn-primary" : "bg-white btn-outline-light"
                    }`}
                  style={{ borderColor: "#ced4da" }}
                  onClick={() => clearFilter()}
                >
                  {kanbanloading ? (
                    <span className="align-middle material-symbols-outlined fw-light fs-4 fa-spin">
                      autorenew
                    </span>
                  ) : (
                    <span className="align-middle material-symbols-outlined fw-light fs-4">
                      autorenew
                    </span>
                  )}
                </button>
              )}
            </Col>
          </Row>
          {loading && kanbanViewData ? (
            <>
              <Board
                isVirtualList
                rowHeight={ROW_HEIGHT}
                width={363}
                height={900}
                renderColumnHeader={(
                  { title, count, id }: any,
                  index: number
                ) => <RenderCardTitle title={title} count={count} key={id} />}
                renderCard={(data: any, { dragging }: any) =>
                  data.filter ? (
                    <CardTaskBox
                      data={data}
                      dragging={dragging}
                      key={data.id}
                      onClickSubTaskList={subTaskFilter}
                      getTaskByID={getTask}
                      changeFlag={addFlag}
                      removeFlag={removeFlag}
                      handleArchive={handleArchive}
                    />
                  ) : null
                }
                onCardDragEnd={moveCard}
                disableColumnDrag
              >
                {kanbanViewData}
              </Board>

              {/* <GlassWrapper onScrollMe={onMouseMove} boardScrollValue={scrollValue} /> */}
            </>
          ) : (
            <div className="react-kanban-board">
              <div style={{ whiteSpace: "nowrap" }}>
                {TaskStatus.map((item, index) => (
                  <>
                    <div
                      key={index}
                      style={{
                        height: "972px",
                        minHeight: "28px",
                        display: "inlineblock",
                        verticalAlign: "top",
                      }}
                      className="react-kanban-column"
                    >
                      <div>
                        <div className="kanban-title">
                          <div className="h3 fs-6 card-title">
                            {item}
                            <h6 className="float-end fs-6">(0 Task)</h6>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "363px",
                          height: "900px",
                          position: "relative",
                        }}
                        className="react-kanban-virtualized-cards-container"
                      >
                        <div className="react-kanban-card-wrapper">
                          <div
                            style={{
                              display: "inline-block",
                              whiteSpace: "normal",
                            }}
                          >
                            <div className="task-box false false 0 card">
                              <div className="borad-width card-body">
                                <p className="text-muted mb-0 placeholder-glow">
                                  <span className="placeholder col-12"></span>
                                </p>
                                <div>
                                  <br />
                                </div>
                                <p className="text-muted mb-0 placeholder-glow">
                                  <span className="placeholder col-12"></span>
                                </p>
                                <p className="text-muted mb-0 placeholder-glow">
                                  <span className="placeholder col-4"></span>
                                </p>
                                <p className="text-muted mb-0 placeholder-glow">
                                  <span className="placeholder col-3"></span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          )}
        </Container>
        {/* <Drawer
          title={t("Filters")}
          open={openFilter}
          onClose={() => setOpenFilter((state) => !state)}
          onReset={clearFilter}
          footer={
            <Button color="primary" style={{ margin: "16px" }} onClick={handleApplyFilters}>
              {t("Apply")}
            </Button>
          }
        >
          <Filters
            filters={filters}
            onApply={() => { }}
            onClear={() => { }}
            onChange={(obj: any) => handleFiltersChange(obj)}
          />
        </Drawer> */}
      </div>
    </React.Fragment>
  );
};

export default KanbanBoard;
