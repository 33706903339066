import {
  GET_USER_MANUAL_LIST,
  GET_USER_MANUAL_LIST_SUCCESS,
  GET_USER_MANUAL_LIST_ERROR,

  GET_USER_MANUAL,
  GET_USER_MANUAL_SUCCESS,
  GET_USER_MANUAL_ERROR,
} from "./actionTypes";

const initialState = {
  GET_USER_MANUAL_LIST_SUCCESS: [],
  GET_USER_MANUAL_LIST_ERROR: [],

  GET_USER_MANUAL_SUCCESS: [],
  GET_USER_MANUAL_ERROR: [],
  loading: false,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    // ---------------------------------------------GET_USER_MANUAL_LIST
    case GET_USER_MANUAL_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_USER_MANUAL_LIST_SUCCESS:
      state = {
        ...state,
        GET_USER_MANUAL_LIST_SUCCESS: action.payload,
        loading: false,
      };
      break;

    case GET_USER_MANUAL_LIST_ERROR:
      state = {
        ...state,
        GET_USER_MANUAL_LIST_ERROR: action.payload,
        loading: false,
      };
      break;
    // ---------------------------------------------GET_USER_MANUAL
    case GET_USER_MANUAL:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_USER_MANUAL_SUCCESS:
      state = {
        ...state,
        GET_USER_MANUAL_SUCCESS: action.payload,
        loading: false,
      };
      break;

    case GET_USER_MANUAL_ERROR:
      state = {
        ...state,
        GET_USER_MANUAL_ERROR: action.payload,
        loading: false,
      };
      break;

    // ---------------------------------------------DEFAULT
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default reducer;
