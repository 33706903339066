import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Card, CardBody, Nav, Button } from "reactstrap";
import { Link } from "react-router-dom";

// Import Breadcrumb
import Breadcrumbs from "../../../components/Layout/Breadcrumb";
import UsersList from "../../Users/AllUsers";

import ScrollUp from "../../../components/ScrollUpDown/ScrollUp";
import ScrollDown from "../../../components/ScrollUpDown/ScrollDown";

import { useTranslation } from "react-i18next";
import {
  getUserProfile,
  handleFilters,
} from "../../../helpers/functions_helper";

import NavItems from "./NavItems";

import { AttributOptions } from "../../../common/staticdata";
//Mail Action
import { setDraftEmailData } from "../../../store/mails/actions";
import { MaterialIcon } from "../../Email/components/styles";
import { draftmailProps } from "../../Email/components/type";
import EmailButton from "../../Email/components/EmailButton";
const EditCompanyEmployees = (props: any) => {
  const { t } = useTranslation();
  document.title = `${t("Update Company")} | ${process.env.REACT_APP_NAME}`;
  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "/masters/company",
      label: t("Company"),
      active: true,
    },
    {
      url: "#",
      label: t("Update Company"),
      active: true,
    },
  ];
  const dispatch = useDispatch();
  const data_table_id = useSelector((state: any) => state.Mails.data_table_id);
  const userProfile = getUserProfile("all-company");
  let { id, company_id, type } = useParams();
  const { isWindow } = props;
  if (!id || !company_id) {
    id = props?.id;
    company_id = props?.company_id;
    type = props?.type;
  }
  const [filterData, setFilterData] = useState<any>([]);
  const [intialData, setIntialData] = useState({});

  useEffect(() => {
    if (filterData?.company_id) {
      setIntialData({ ...intialData, company: filterData?.company_id });
    }
    if (filterData?.select_attribute) {
      const title = filterData?.select_attribute
        ? AttributOptions.find(
          (option: any) => option.value == filterData?.select_attribute
        )
        : "";
      if (title) setIntialData({ ...intialData, title: `${title?.label}` });
    }
  }, [filterData]);

  function s2ab(s: any) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  //Email compose
  const composeMail = async () => {
    const savedfilters = handleFilters("GET");
    const table_id = "dataTable_" + data_table_id;
    if (table_id) {
      const file = await import("xlsx").then((XLSX) => {
        const table_elt = document.getElementById(table_id);
        const workbook = XLSX.utils.table_to_book(table_elt);
        const wbout = XLSX.write(workbook, {
          bookType: "xlsx",
          bookSST: true,
          type: "binary",
        });
        var blob: any = new Blob([s2ab(wbout)], {
          type: "application/octet-stream",
        });
        return new File([blob], `company.xlsx`, {
          type: "application/octet-stream",
          lastModified: new Date().getTime(),
        });
      });
      const draftMail: draftmailProps = {
        title: t("New Message"),
        filters: {
          company: [
            { id: id, company_id: company_id, only_contact_person: true },
          ],
        },
        openMailBox: 1,
        subject: savedfilters?.filters?.select_attribute || "",
        to: [],
        files: [file],
      };
      dispatch(setDraftEmailData(draftMail));
    }
  };

  return (
    <React.Fragment>
      <ScrollUp />
      <ScrollDown />

      <div className={isWindow ? "" : "page-content"}>
        <Container fluid>
          {!isWindow ? (
            <Breadcrumbs
              breadcrumbTitle={t("Update Company")}
              breadcrumbItems={breadcrumbItems}
              endAdornment={
                <Nav className="nav justify-content-end">
                  {userProfile.IS_COMPANY_ADMIN ? (
                    <EmailButton handlerCompose={() => composeMail()} />
                  ) : null}
                </Nav>
              }
            />
          ) : null}

          {!isWindow ? <NavItems id={id} company_id={company_id} /> : null}

          <Row>
            <Col xl="12">
              <Card
                className={`${!isWindow ? "border-top-0" : ""
                  } rounded-0 rounded-bottom`}
              >
                <CardBody>
                  <Row>
                    <UsersList
                      company={id}
                      type={type}
                      list_type={2}
                      setFilterData={setFilterData}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditCompanyEmployees;
