import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Button,
} from "reactstrap";
import { vLookup } from "../../helpers/functions_helper";
import { useTranslation } from "react-i18next";

const SSSCModel_73 = (props: any) => {
  const { t } = useTranslation();
  let spCountryData = props.spCountryData;
  const C_GS_TOTAL = props.C_GS_TOTAL;
  const RAS = props.RAS;
  const FINAL_TAX = props.FINAL_TAX;

  const DED_10_MIN = props.DED_10_MIN;
  const DED_10_MAX = props.DED_10_MAX;
  const WORK_DAYS = props.WORK_DAYS; // B6

  const [COUNTRY_IDX, setCOUNTRY_IDX] = useState<number>();

  // const [DED_10_MIN, setDED_10_MIN] = useState<number>(0);
  // const [DED_10_MAX, setDED_10_MAX] = useState<number>(12652);
  // const [B6, setB6] = useState<number>(220); // Working days

  const [D_GROSS_COUNTRY, setD_GROSS_COUNTRY] = useState<number>(0);
  const [D_SS_COUNTRY, setD_SS_COUNTRY] = useState<number>(0);
  const [D_TAX_OPTION_COUNTRY, setD_TAX_OPTION_COUNTRY] = useState<number>(1);
  const [C_NET_TAXABLE_COUNTRY, setC_NET_TAXABLE_COUNTRY] = useState<number>(0);

  const [C_DEDUCTION_10, setC_DEDUCTION_10] = useState<number>(0);
  const [C_TAXABLE_COUNTRY, setC_TAXABLE_COUNTRY] = useState<number>(0);

  const [B24, setB24] = useState<number>(0);
  const [C_DAILY_INC, setC_DAILY_INC] = useState<number>(0);

  const [C_TAX_DAY, setC_TAX_DAY] = useState<number>(0);
  const [C_TAX_YEAR, setC_TAX_YEAR] = useState<number>(0);

  const [PART_1_TAX, setPART_1_TAX] = useState<number>(0);
  const [PART_1_BASIC, setPART_1_BASIC] = useState<number>(0);
  const [PART_2_TAX, setPART_2_TAX] = useState<number>(0);
  const [PART_2_BASIC, setPART_2_BASIC] = useState<number>(0);
  const [PART_3_TAX, setPART_3_TAX] = useState<number>(0);
  const [PART_3_BASIC, setPART_3_BASIC] = useState<number>(0);

  const [F26, setF26] = useState<number>(0);
  const [F27, setF27] = useState<number>(0);

  const [I21, setI21] = useState<number>(0);
  const [I22, setI22] = useState<number>(0);
  const [I23, setI23] = useState<number>(0);

  const [I25, setI25] = useState<number>(0);
  const [I26, setI26] = useState<number>(0);

  const [I27, setI27] = useState<number>(0);

  const [C_TAXES_COUNTRY, setC_TAXES_COUNTRY] = useState<number>(0);

  useEffect(() => {
    if (spCountryData) {
      const COUNTRY_DATA = spCountryData.find((data: any) => data.country_id === 73);

      if (COUNTRY_DATA) {
        setCOUNTRY_IDX(spCountryData.findIndex((data: any) => data.country_id === 73));
        setD_GROSS_COUNTRY(
          spCountryData.find((data: any) => data.country_id === 73)?.D_GROSS_COUNTRY
        );
        setD_SS_COUNTRY(spCountryData.find((data: any) => data.country_id === 73)?.D_SS_COUNTRY);
        setD_TAX_OPTION_COUNTRY(
          spCountryData.find((data: any) => data.country_id === 73)?.country_tax_option
        );
      }
    }
  }, [spCountryData]);

  useEffect(() => {
    setC_NET_TAXABLE_COUNTRY(Number(D_GROSS_COUNTRY) + Number(D_SS_COUNTRY) * -1);
  }, [D_GROSS_COUNTRY, D_SS_COUNTRY]);

  useEffect(() => {
    setC_DEDUCTION_10(Number(C_NET_TAXABLE_COUNTRY) * 0.1);
  }, [C_NET_TAXABLE_COUNTRY]);

  useEffect(() => {
    setI21(Math.min(DED_10_MAX, C_DEDUCTION_10));
  }, [DED_10_MAX, C_DEDUCTION_10]);

  useEffect(() => {
    setC_TAXABLE_COUNTRY(Number(C_NET_TAXABLE_COUNTRY) - Number(C_DEDUCTION_10));
  }, [C_NET_TAXABLE_COUNTRY, C_DEDUCTION_10]);

  useEffect(() => {
    if (C_GS_TOTAL <= 0) {
      setB24(0);
    } else {
      setB24((Number(WORK_DAYS) * Number(D_GROSS_COUNTRY)) / Number(C_GS_TOTAL));
    }
  }, [WORK_DAYS, D_GROSS_COUNTRY, C_GS_TOTAL]);

  useEffect(() => {
    if (B24 <= 0) {
      setC_DAILY_INC(0);
    } else {
      setC_DAILY_INC(Math.round(Number(C_TAXABLE_COUNTRY) / Number(B24)));
    }
  }, [C_TAXABLE_COUNTRY, B24]);

  useEffect(() => {
    if (RAS && RAS.length > 0) {
      const VLOOKUP_4 = vLookup(C_DAILY_INC, RAS, 4);
      const VLOOKUP_3 = vLookup(C_DAILY_INC, RAS, 3);
      const VLOOKUP_1 = vLookup(C_DAILY_INC, RAS, 1);

      setC_TAX_DAY(
        Number(VLOOKUP_4) + (Number(VLOOKUP_3) / 100) * (Number(C_DAILY_INC) - Number(VLOOKUP_1))
      );
    }
  }, [C_DAILY_INC, RAS]);

  useEffect(() => {
    setC_TAX_YEAR(Number(C_TAX_DAY) * Number(B24));
  }, [C_TAX_DAY, B24]);

  useEffect(() => {
    if (RAS && RAS.length > 0) {
      let B10 = RAS[1][0];
      let C10 = RAS[1][1];
      let D10 = RAS[1][2];
      let PART_2_TAX_VALUE = 0;

      if (Number(C_DAILY_INC) < Number(B10)) {
        PART_2_TAX_VALUE = 0;
      } else if (Number(C_DAILY_INC) > Number(C10)) {
        PART_2_TAX_VALUE = (Number(C10) - Number(B10)) * (Number(D10) / 100);
      } else {
        PART_2_TAX_VALUE = (Number(C_DAILY_INC) - Number(B10)) * (Number(D10) / 100);
      }

      setPART_2_TAX(PART_2_TAX_VALUE);
    }
  }, [RAS, C_DAILY_INC]);

  useEffect(() => {
    if (RAS && RAS.length > 0) {
      let B11 = RAS[2][0];
      let D11 = RAS[2][2];
      let PART_3_TAX_VALUE = 0;

      if (Number(C_DAILY_INC) < Number(B11)) {
        PART_3_TAX_VALUE = 0;
      } else {
        PART_3_TAX_VALUE = (Number(C_DAILY_INC) - Number(B11)) * (Number(D11) / 100);
      }

      setPART_3_TAX(PART_3_TAX_VALUE);
    }
  }, [RAS, C_DAILY_INC]);

  useEffect(() => {
    if (RAS && RAS.length > 0) {
      let B10 = RAS[1][0];
      let C10 = RAS[1][1];
      let PART_2_BASIC_VALUE = 0;

      if (Number(C_DAILY_INC) < Number(B10)) {
        PART_2_BASIC_VALUE = Number(B10) * Number(B24);
      } else if (Number(C_DAILY_INC) > Number(C10)) {
        PART_2_BASIC_VALUE = Number(B10) * Number(B24);
      }

      setPART_2_BASIC(PART_2_BASIC_VALUE);
      setF26(PART_2_BASIC_VALUE);
    }
  }, [RAS, C_DAILY_INC, B24]);

  useEffect(() => {
    if (RAS && RAS.length > 0) {
      let B10 = RAS[1][0];
      let B11 = RAS[2][0];
      let C10 = RAS[1][1];
      let PART_3_BASIC_VALUE = 0;

      if (Number(C_DAILY_INC) < Number(B10)) {
        PART_3_BASIC_VALUE = 0;
      }
      if (Number(C_DAILY_INC) > Number(B11)) {
        PART_3_BASIC_VALUE = Number(C10) * Number(B24);
      } else {
        PART_3_BASIC_VALUE = (Number(C_DAILY_INC) - Number(B10)) * Number(B24);
      }

      setPART_3_BASIC(PART_3_BASIC_VALUE);
      setF27(PART_3_BASIC_VALUE);
    }
  }, [RAS, C_DAILY_INC, B24]);

  useEffect(() => {
    setI22(Number(F26) + Number(F27));
  }, [F26, F27]);

  useEffect(() => {
    setI23(Number(C_NET_TAXABLE_COUNTRY) - Number(I21) - Number(I22));
  }, [C_NET_TAXABLE_COUNTRY, I21, I22]);

  useEffect(() => {
    if (FINAL_TAX && FINAL_TAX.length > 0) {
      const VLOOKUP_1 = vLookup(I23, FINAL_TAX, 1);
      const VLOOKUP_3 = vLookup(I23, FINAL_TAX, 3);
      const VLOOKUP_4 = vLookup(I23, FINAL_TAX, 4);

      setI25((Number(I23) - Number(VLOOKUP_1)) * (Number(VLOOKUP_3) / 100) + Number(VLOOKUP_4));
    }
  }, [I23, FINAL_TAX]);

  useEffect(() => {
    setI26(Number(PART_3_TAX) * Number(B24) * -1);
  }, [PART_3_TAX, B24]);

  useEffect(() => {
    setI27(Math.max(0, Number(I25) + Number(I26)));
  }, [I25, I26]);

  useEffect(() => {
    if (Number(D_TAX_OPTION_COUNTRY) === 1) {
      setC_TAXES_COUNTRY(C_TAX_YEAR);
    } else {
      setC_TAXES_COUNTRY(Number(I27) + Number(C_TAX_YEAR));
    }
  }, [D_TAX_OPTION_COUNTRY, C_TAX_YEAR, I27]);

  useEffect(() => {
    if (COUNTRY_IDX) {
      props.onCalculationUpdate({
        COUNTRY_IDX: COUNTRY_IDX,
        C_TAXES_COUNTRY: C_TAXES_COUNTRY,
      });
    }
  }, [COUNTRY_IDX, C_TAXES_COUNTRY]);

  return (
    <React.Fragment>
      <Modal
        size="xl"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">{t("FRANCE")}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label={t("Close")}
          ></button>
        </div>

        <ModalBody>
          <Card>
            <CardHeader>
              <CardTitle>1. {t("PARAMETERS")}</CardTitle>
            </CardHeader>

            <CardBody>
              <Row>
                <Col xl={6} lg={6} md={6} sm={12}>
                  <div className="table-responsive mb-3">
                    <Table className="table table-hover nowrap w-100 mb-0">
                      <tbody>
                        <tr>
                          <td>
                            {t("DEDUCTION")} 10 ({t("MIN")})
                          </td>
                          <td className="text-end">{Number(DED_10_MIN).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>
                            {t("DEDUCTION")} 10 ({t("MAX")})
                          </td>
                          <td className="text-end">{Number(DED_10_MAX).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>{t("Working days")}</td>
                          <td className="text-end">{WORK_DAYS}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xl={6} lg={6} md={6} sm={12}>
                  <div className="table-responsive mb-3">
                    <Table className="table table-hover nowrap w-100 mb-0">
                      <tbody></tbody>
                    </Table>
                  </div>
                </Col>

                <Col xl={6} lg={6} md={6} sm={12}>
                  <div className="table-responsive mb-3">
                    <Table className="table table-hover nowrap w-100 mb-0">
                      <tbody></tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>2. {t("COMPUTATION")}</CardTitle>
            </CardHeader>

            <CardBody>
              <Row>
                <Col xl={6} lg={6} md={6} sm={12}>
                  <div className="table-responsive mb-3">
                    <Table className="table table-hover nowrap w-100 mb-0">
                      <tbody>
                        <tr>
                          <td>{t("GROSS")}</td>
                          <td className="text-end">{Number(D_GROSS_COUNTRY).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>{t("SOCIAL SECURITY")}</td>
                          <td className="text-end">{Number(D_SS_COUNTRY * -1).toFixed(2)}</td>
                        </tr>

                        <tr>
                          <td colSpan={2}></td>
                        </tr>

                        <tr>
                          <td>{t("NET TAXABLE")}</td>
                          <td className="text-end">{Number(C_NET_TAXABLE_COUNTRY).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>{t("DEDUCTION")} 10</td>
                          <td className="text-end">{Number(C_DEDUCTION_10).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>{t("TAXABLE")}</td>
                          <td className="text-end">{Number(C_TAXABLE_COUNTRY).toFixed(2)}</td>
                        </tr>

                        <tr>
                          <td colSpan={2}></td>
                        </tr>

                        <tr>
                          <td>{t("C-DAYS")}</td>
                          <td className="text-end">{Number(B24).toFixed(0)}</td>
                        </tr>
                        <tr>
                          <td>{t("DAILY INC")}</td>
                          <td className="text-end">{Number(C_DAILY_INC).toFixed(0)}</td>
                        </tr>

                        <tr>
                          <td colSpan={2}></td>
                        </tr>

                        <tr>
                          <td>
                            {t("TAX")} ({t("DAY")})
                          </td>
                          <td className="text-end">{Number(C_TAX_DAY).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>
                            {t("TAX")} ({t("YEAR")})
                          </td>
                          <td className="text-end">{Number(C_TAX_YEAR).toFixed(2)}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>

                <Col xl={6} lg={6} md={6} sm={12}>
                  <div className="table-responsive mb-3">
                    <Table className="table table-hover nowrap w-100 mb-0">
                      <tbody>
                        <tr>
                          <td>{t("GROSS")}</td>
                          <td className="text-end">{Number(D_GROSS_COUNTRY).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>{t("SOCIAL SECURITY")}</td>
                          <td className="text-end">{Number(D_SS_COUNTRY * -1).toFixed(2)}</td>
                        </tr>

                        <tr>
                          <td colSpan={2}></td>
                        </tr>

                        <tr>
                          <td>{t("NET TAXABLE")}</td>
                          <td className="text-end">{Number(C_NET_TAXABLE_COUNTRY).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>{t("DEDUCTION")} 10</td>
                          <td className="text-end">{Number(I21).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>{t("Deduction base")} RAS 0% & 12%</td>
                          <td className="text-end">{Number(I22).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>{t("TAXABLE")}</td>
                          <td className="text-end">{Number(I23).toFixed(2)}</td>
                        </tr>

                        <tr>
                          <td colSpan={2}></td>
                        </tr>

                        <tr>
                          <td>{t("Final tax correction")}</td>
                          <td className="text-end">{Number(I25).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>{t("(Less) RAS on 20% bracket")}</td>
                          <td className="text-end">{Number(I26).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>{t("Additional tax")}</td>
                          <td className="text-end">{Number(I27).toFixed(2)}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>

                <Col xl={6} lg={6} md={6} sm={12}>
                  <div className="table-responsive mb-3">
                    <Table className="table table-hover nowrap w-100 mb-0">
                      <tbody>
                        <tr>
                          <th></th>
                          <th className="text-end">{t("Tax")}</th>
                          <th className="text-end">{t("Basic")}</th>
                        </tr>
                        <tr>
                          <td>{t("Part")} 1</td>
                          <td className="text-end">{Number(PART_1_TAX).toFixed(2)}</td>
                          <td className="text-end">{Number(PART_1_BASIC).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>{t("Part")} 2</td>
                          <td className="text-end">{Number(PART_2_TAX).toFixed(2)}</td>
                          <td className="text-end">{Number(PART_2_BASIC).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>{t("Part")} 3</td>
                          <td className="text-end">{Number(PART_3_TAX).toFixed(2)}</td>
                          <td className="text-end">{Number(PART_3_BASIC).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <th>{t("Total")}</th>
                          <th className="text-end">
                            {(Number(PART_1_TAX) + Number(PART_2_TAX) + Number(PART_3_TAX)).toFixed(
                              2
                            )}
                          </th>
                          <th className="text-end">
                            {(
                              Number(PART_1_BASIC) +
                              Number(PART_2_BASIC) +
                              Number(PART_3_BASIC)
                            ).toFixed(2)}
                          </th>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </ModalBody>

        <ModalFooter>
          <Button color="danger" className="btn-block" type="reset" onClick={props.onCloseClick}>
            {t("Close")}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default SSSCModel_73;
