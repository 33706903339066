import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardHeader,
} from "reactstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Breadcrumbs from "../../components/Layout/Breadcrumb";
import DataTable from "../../components/Common/DataTable";
import {
  getDataTableQuery,
  getUserProfile,
  handleFilters,
} from "../../helpers/functions_helper";
import taskService from "../../services/tasks.service";
import issueReportingService from "../../services/issue.reporting.service";
import ReportIssueModal from "./ReportIssueModal";
import Filters from "./Filters";
import Drawer from "../../components/Drawer";
import CommentDrawal from "./CommentDrawal";

const AllIssue = (props: any) => {
  const { t } = useTranslation();
  document.title = `${t("All Incident")} - ${t("List view")} | ${process.env.REACT_APP_NAME}`;

  const IssueCategories = [
    { label: t('Report a bug'), value: 'Incident' },
    { label: t('Suggest an improvement - Change request'), value: 'CR-CR' },
  ];

  const incident_status = [
    { label: t('New'), value: '1' },
    { label: t('In-progress'), value: '2' },
    { label: t('Fixed'), value: '3' },
    { label: t('Hold'), value: '4' },
    { label: t('For Deployment'), value: '5' },
    { label: t('Complete'), value: '6' },
    { label: t('Cancelled'), value: '7' },
    { label: t('Rejected'), value: '8' },
  ];

  const Priority = [
    { label: t('Highest'), value: 'Highest' },
    { label: t('High'), value: 'High' },
    { label: t('Medium'), value: 'Medium' },
    { label: t('Low'), value: 'Low' },
    { label: t('Lowest'), value: 'Lowest' },
  ];



  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "#",
      label: t("Incident"),
      active: true,
    },
  ];

  const userProfile = getUserProfile("incident");
  const savedfilters = handleFilters("GET");
  const toastId = React.useRef(null) as any;
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingListData, setLoadingListData] = useState(true);
  const [datatableListData, setDatatableListData] = useState([]);
  const [deleteWhere, setDeleteWhere] = useState({
    id: "",
    incident_id: "",
  });
  const [issueTrackerModal, setIssueTrackerModal] = useState<boolean>(false);
  const [isEdit, setEdit] = useState<boolean>(false);
  const [incidentData, setIncidentData] = useState<any>({});
  const [openModel, setOpenModel] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const navigate = useNavigate();

  function renderRowHTML(rowContent: any, row: any) {
    return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
  }

  const editIncident = (data: any) => {
    setIncidentData(data);
    setIssueTrackerModal(true);
  }

  const openCommentModel = (data: any) => {
    setIncidentData(data);
    setOpenModel(true);
  }

  const columns = [
    {
      dataField: "DT_RowIndex",
      text: t("Sr. No."),
      sort: false,
      search: false,
      classes: "fix-col-left",
      headerClasses: "fix-col-left",
      headerStyle: { width: "155px" },
      style: { width: "155px" },
    },
    {
      dataField: "jira_id",
      text: t("Jira Ref"),
      sort: false,
      search: false,
      classes: "fix-col-left",
      headerClasses: "fix-col-left",
      headerStyle: { width: "155px" },
      style: { width: "155px" },
    },
    {
      dataField: "jira_url",
      text: t("Jira URL"),
      sort: false,
      search: false,
      classes: "fix-col-left",
      headerClasses: "fix-col-left",
      headerStyle: { width: "155px" },
      style: { width: "155px" },
      formatter: (rowContent: any, row: any): any => {
        return <a className="link" href={row.jira_url} target="_blank">{row.jira_id}</a>
      },
    },
    {
      dataField: "action",
      text: "Comment",
      sort: false,
      search: false,
      formatter: (rowContent: any, row: any): any => {
        return (
          <div className="d-flex text-center">
            <Link
              className="btn btn-pill btn-outline-primary btn-sm"
              to={"#"}
              onClick={() => openCommentModel(row)}
            >
              <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                forum
              </span>
            </Link>
          </div>
        );
      },
      footer: "",
    },
    {
      dataField: "title",
      text: t("Title"),
      sort: true,
      search: true,
      //formatter: renderRowHTML,
      formatter: (rowContent: any, row: any): any => {
        return <Link className="link" to={"/incident/view/" + row.id + "/" + row.issue_id}>{row.title}</Link>
      },
    },
    {
      dataField: "describe",
      text: t("Description"),
      sort: true,
      search: true,
      footer: "",
    },
    {
      dataField: "url",
      text: t("URL"),
      sort: true,
      search: true,
      footer: "",
    },
    {
      dataField: "company_name",
      text: t("Company"),
      sort: true,
      search: true,
      align: "right",
    },
    {
      dataField: "user_name",
      text: t("Reported User"),
      sort: true,
      search: true,
      align: "right",
    },
    {
      dataField: "priority",
      text: t("Priority"),
      sort: true,
      search: true,
      align: "right",
      formatter: (rowContent: any, row: any): any => {
        return Priority.find(item => item.value == row.priority)?.label
      },
    },
    {
      dataField: "status",
      text: t("Status"),
      sort: true,
      search: true,
      align: "right",
      formatter: (rowContent: any, row: any): any => {
        if (row.jira_status) {
          return row.jira_status;
        }
        return incident_status.find(item => item.value == row.status)?.label
      },
    },
    {
      dataField: "issue_type",
      text: t("Incident type"),
      sort: true,
      search: true,
      align: "right",
      formatter: (rowContent: any, row: any): any => {
        return IssueCategories.find(item => item.value == row.issue_type)?.label
      },
    },
    {
      dataField: "release_version",
      text: t("Release version"),
      align: "right",
      formatter: (rowContent: any, row: any): any => {
        return row.release_version;
      },
    },
    {
      dataField: "release_date",
      text: t("Release date"),
      align: "right",
      formatter: (rowContent: any, row: any): any => {
        return row.release_date ? <div className="row"><div className="col-12 d-flex"><span className="material-symbols-outlined fw-light">
          calendar_month
        </span>{"  "}<span style={{ paddingTop: '3px' }}>{moment(row.release_date).format('LL')}</span></div></div> : null
      },
    },
    {
      dataField: "attachment",
      text: t("Attachment"),
      align: "right",
      formatter: (rowContent: any, row: any): any => {
        if (row?.attachment?.length > 0 && typeof row.attachment != 'undefined' && row.attachment) {
          const list_link = row?.attachment.map((item: any, index: number) => <><a href={item} target="_blank" className="flex">{`Attachment-${index + 1}`}</a><br /></>)
          return list_link;
        }
        else {
          return '';
        }

      },
    },
    {
      dataField: "created_at",
      text: t("Created date"),
      align: "right",
      formatter: (rowContent: any, row: any): any => {
        return row.created_at;
      },
    },

    // {
    //   dataField: "action",
    //   text: "Action",
    //   sort: false,
    //   search: false,
    //   formatter: (rowContent: any, row: any): any => {
    //     return (
    //       <div className="d-flex gap-2 text-end">
    //         <Link
    //           className="btn btn-pill btn-outline-primary btn-sm"
    //           to={"#"}
    //           onClick={() => editIncident(row)}
    //         >
    //           <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
    //             edit
    //           </span>
    //           Edit
    //         </Link>
    //       </div>
    //     );
    //   },
    //   footer: "",
    // },
  ];

  const [datatableOptions, setDatatableOptions] = useState(
    savedfilters && savedfilters?.datatableOptions
      ? (savedfilters?.datatableOptions as any)
      : {
        draw: 1,
        page: 1,
        recordsTotal: 0,
        recordsFiltered: 0,
        sizePerPage: 20,
        searchText: "",
        sortField: "id",
        sortOrder: "desc",
      }
  );
  const [filters, setFilters] = useState(
    savedfilters && savedfilters?.filters
      ? (savedfilters?.filters as any)
      : {
        is_filtered: false,
        issue_type: "",
        user_id: 0,
        priority: "",
        status: "",
        created_date_from: "",
        created_date_to: "",
      }
  );

  const onTableChange = (type: any, newState: any) => {
    let draw = datatableOptions.draw + 1;
    let page = 1;
    if (type == "search") {
      page = 1;
    } else {
      page = newState.page;
    }
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: draw,
      page: page,
      sizePerPage: newState.sizePerPage,
      searchText: newState.searchText,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
    };
    setDatatableOptions(updatedDatatableOptions);
    setDatatableListData([]);
    setLoadingListData(true);
  };

  const clearFilter = () => {
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: 1,
      page: 1,
      sizePerPage: 20,
      searchText: "",
      sortField: "id",
      sortOrder: "desc",
    };
    setDatatableOptions(updatedDatatableOptions);

    const filter = {
      ...filters,
      is_filtered: false,
      issue_type: "",
      user_id: 0,
      priority: "",
      status: "",
      created_date_from: "",
      created_date_to: "",
    };
    setFilters(filter);

    setDatatableListData([]);
    setLoadingListData(true);
  };

  const loadingDatatable = async () => {
    try {
      const res = (await issueReportingService.getAll({
        ...getDataTableQuery(datatableOptions, columns),
        filters,
      })) as any;

      if (res.data.status === "success") {
        setDatatableListData(res.data.data);

        const updatedDatatableOptions = {
          ...datatableOptions,
          recordsTotal: res.data.recordsTotal,
          recordsFiltered: res.data.recordsFiltered,
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingListData(false);
      } else {
        setDatatableListData([]);
        setLoadingListData(false);
      }
    } catch (err) {
      setDatatableListData([]);
      setLoadingListData(false);
    }
  };

  useEffect(() => {
    if (loadingListData === true) {
      loadingDatatable();
    }
  }, [loadingListData]);

  // Filters -------------------------------------------------
  const handleFiltersChange = (obj: any) => {
    setFilters((state: any) => ({ ...state, ...obj }));
  };

  const handleApplyFilters = () => {
    setDatatableListData([]);
    setLoadingListData(true);
    setOpenFilter(false)
  };
  // Filters -------------------------------------------------
  const setIssueModal = (reload?: boolean) => {
    if (reload) {
      loadingDatatable();
    }
    setIssueTrackerModal(preState => (!preState));
    setIncidentData({});
  }


  useEffect(() => {
    setDatatableListData([]);
    setLoadingListData(true);
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={t("Incident Tracker")}
            breadcrumbItems={breadcrumbItems}
            endAdornment={
              <div className="d-flex gap-2 text-end">
                {userProfile.IS_INTERNAL ? (
                  <Link to="#" className="btn btn-primary btn-label btn-sm" onClick={() => setIssueModal()}>
                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                      bug_report
                    </span>{" "}
                    {t("Create incident")}
                  </Link>
                ) : null}
              </div>
            }
          />

          <Row>
            <Col xl="12">
              <Card>
                {userProfile.USER_COMPANY !== 0 ? (
                  <CardHeader className="bg-transparent border-bottom pb-0">
                    <div className="d-sm-flex flex-wrap">
                      {/* <NavItems /> */}
                    </div>
                  </CardHeader>
                ) : null}

                <CardBody>
                  <DataTable
                    columns={columns}
                    products={datatableListData}
                    datatableOptions={datatableOptions}
                    filters={filters}
                    onTableChange={onTableChange}
                    loadingListData={loadingListData}
                    clearFilter={clearFilter}
                    settingTableName="incident_tracker"
                    slug="incident"
                    customButtons={
                      userProfile.IS_INTERNAL ? (
                        <Button
                          title={t("Filter")}
                          outline={filters.is_filtered === true ? false : true}
                          color={
                            filters.is_filtered === true ? "secondary" : "light"
                          }
                          onClick={() => setOpenFilter((state) => !state)}
                        >
                          {filters.is_filtered === false ? (
                            <span className="align-middle material-symbols-outlined fw-light fs-4">
                              filter_alt
                            </span>
                          ) : (
                            <span className="align-middle material-symbols-outlined fw-light fs-4">
                              filter_alt_off
                            </span>
                          )}
                        </Button>
                      ) : null
                    }
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {issueTrackerModal ? <ReportIssueModal
        show={issueTrackerModal}
        data={incidentData}
        onCloseClick={(isReload: boolean) => setIssueModal(isReload)}
      /> : null}

      <Drawer
        title={incidentData?.title}
        open={openModel}
        onClose={() => setOpenModel((state) => !state)}
        footer={
          <Button
            color="primary"
            style={{ margin: "16px" }}
            onClick={() => setOpenModel((state) => !state)}
          >
            Cancel
          </Button>
        }
      >
        <CommentDrawal
          data={incidentData}
          onClose={() => setOpenModel((state) => !state)}
        />
      </Drawer>
      <Drawer
        title={t("Filters")}
        open={openFilter}
        onClose={() => setOpenFilter((state) => !state)}
        onReset={clearFilter}
        footer={
          <Button
            color="primary"
            style={{ margin: "16px" }}
            onClick={handleApplyFilters}
          >
            {t("Apply")}
          </Button>
        }
      >
        <Filters
          filters={filters}
          onApply={() => setOpenFilter(false)}
          onClear={() => { }}
          onChange={(obj: any) => handleFiltersChange(obj)}
        // hideCompany={company_id > 0 || user_id > 0 || task_id > 0}
        // loading={loadingListData}
        />
      </Drawer>
    </React.Fragment>
  );
};

export default AllIssue;
