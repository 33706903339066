import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalFooter } from "reactstrap";

const DeleteModal = ({
  show,
  modalTitle,
  recordName,
  customMessage = "",
  onDeleteClick,
  onCloseClick,
  loading = false,
}: DeleteModal) => {

  const { t } = useTranslation();
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title">
          <span className="align-middle material-symbols-outlined fw-light fs-4 me-1 text-danger">
            emergency_home
          </span>
          {t('Delete')} {modalTitle} ?
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={onCloseClick}
          aria-label="Close"
        ></button>
      </div>
      <ModalBody>
        <p>
          {t('Do you want to delete')}{" "}
          <b
            // style={{
            //   display: "block",
            //   overflow: "hidden",
            //   textOverflow: "ellipsis",
            // }}

            style={{
              wordBreak: "break-all",
            }}
          >
            {recordName} ?
          </b>
        </p>
        <p>
          {customMessage
            ? customMessage
            : t("All the related data will be deleted and cannot be undone!")}
        </p>
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn btn-primary btn-md ms-2"
          onClick={onDeleteClick}
          disabled={loading}
        >
          {t('Delete')}
        </button>
        <button
          type="button"
          className="btn btn-outline-danger btn-md ms-2"
          onClick={onCloseClick}
        >
          {t('Cancel')}
        </button>
      </ModalFooter>
    </Modal>
  );
};

interface DeleteModal {
  show: boolean;
  modalTitle: string;
  recordName: string;
  customMessage?: string;
  onCloseClick: React.MouseEventHandler<HTMLButtonElement>;
  onDeleteClick: React.MouseEventHandler<HTMLButtonElement>;
  loading: boolean;
}

export default DeleteModal;
