import React, { RefObject, useEffect, useState } from 'react';
import { AttachmentTypes } from '../entities';
import { Image } from '../containers/Image';
import { Drawing } from '../containers/Drawing';
import {Text} from '../containers/Text';

interface Props {
  attachments: Attachment[];
  pdfName: string;
  pageDimensions: Dimensions;
  removeAttachment: (index: number, pageIndex: any) => void;
  updateAttachment: (attachment: Partial<Attachment>) => void;
  canvasRef: RefObject<HTMLCanvasElement>;
  pageIndex?: number,
  width?: number,
  height?: number,
  x?: number,
  y?: number,
  setDimensions: any;
  style?: any;
}

export const Attachments: React.FC<Props> = ({
  attachments,
  pdfName,
  pageDimensions,
  removeAttachment,
  updateAttachment,
  canvasRef,
  pageIndex,
  setDimensions,
  style
}) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [text, setText] = useState("");
  const [positions, setPositions] = useState({ x: 0, y: 0 });
  const [newattachment, setNewAttachment] = useState<any>(null);
  const [newIndex, setNewIndex] = useState<any>(null);
  // const handleAttachmentUpdate = (index: number, pageIndex: any, attachment: any) =>{
  //   //setNewAttachment(attachment);
  //   setNewIndex(index);
  //   setNewPageIndex(pageIndex);
  // };

  const handleAttachmentUpdate = (index: number, attachment: any) => (
    attachment: Partial<Attachment>
  ) => {
    const attachmentUpdate = {
      ...attachment,
      pageIndex: pageIndex,
      index: index
    }
    updateAttachment(attachmentUpdate);
  };


  const remove_attachment = (index: number, pageIndex: any) => () => {
    removeAttachment(index, pageIndex)
  }

  // useEffect(() => {
  //   if(text!="" && text !="Enter Text Here"){
  //     const attachmentUpdate = {
  //       ...newattachment,
  //       width: width,
  //       height: height,
  //       x: positions.x,
  //       y: positions.y,
  //       text: text || "",
  //       pageIndex: pageIndex,
  //       index:newIndex
  //     }
  //     console.log(attachmentUpdate);
  //     updateAttachment(attachmentUpdate);
  //   }

  // }, [width, height, positions, text])

  return attachments ? (
    <>
      {attachments.length
        ? attachments.map((attachment, index) => {
          const key = `${pdfName}-${index}`;

          if (attachment.type === AttachmentTypes.IMAGE) {
            return (
              <Image
                {...(attachment as ImageAttachment)}
                setWidth={setWidth}
                setHeight={setHeight}
                setPositions={setPositions}
                setNewAttachment={setNewAttachment}
                setNewIndex={setNewIndex}
                attachment={attachment}
                keyIndex={index}
                updateImageAttachment={handleAttachmentUpdate(index, newattachment)}
                removeImage={remove_attachment(index, attachment.pageIndex)}
                data={attachment.data}
              />
            );
          }

          if (attachment.type === AttachmentTypes.DRAWING) {
            return (
              <Drawing
                key={key}
                pageWidth={pageDimensions.width}
                pageHeight={pageDimensions.height}
                removeDrawing={() => removeAttachment(index, pageIndex)}
                updateDrawingAttachment={() => { }}
                {...(attachment as DrawingAttachment)}
              />
            );
          }

          if (attachment.type === AttachmentTypes.TEXT) {
            return (
              <Text
                {...(attachment as TextAttachment)}
                // keyIndex={index}
                // setWidth={setWidth}
                // setHeight={setHeight}
                // setPositions={setPositions}
                // setText={setText}
                // setNewAttachment={setNewAttachment}
                // attachment={attachment}
                // setNewIndex={setNewIndex}
                updateTextAttachment={handleAttachmentUpdate(index, attachment)}
                removeImage={remove_attachment(index, attachment.pageIndex)}
                //style={style}
              />
            );
          }
          return null;
        })
        : null}
    </>
  ) : null;
};
