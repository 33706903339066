import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import LoadingSpinner from "../../components/Common/Loader";
import Breadcrumbs from "../../components/Layout/Breadcrumb";
import { getUserProfile } from "../../helpers/functions_helper";
import { dateFormat } from "../../utils/formats";
import ScrollUp from "../../components/ScrollUpDown/ScrollUp";
import ScrollDown from "../../components/ScrollUpDown/ScrollDown";
import broadcastService from "../../services/broadcast.service";
import moment from "moment";
import { renderRowHTML } from "../../utils/utils";

const BroadcastView = () => {
    const { t } = useTranslation();
    const userObj = getUserProfile('broadcast');
    const { id, broadcast_id } = useParams();
    document.title = t("View Broadcast ") + "| " + process.env.REACT_APP_NAME;
    const moduleHeading = t('Broadcast')
    const breadcrumbItems = useMemo(() => [
        {
            url: "/dashboard",
            label: t("Dashboard"),
            active: false,
        },
        {
            url: "/broadcast/all",
            label: moduleHeading,
            active: false,
        },
        {
            url: "#",
            label: moduleHeading,
            active: true,
        },
    ], [t]);



    const [data, setData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(true);

    const getData = async (id: number) => {
        setLoading(true)
        const result = await broadcastService.get(id)
        if (result) {
            setLoading(false)
            setData(result?.data)
        }
        setLoading(false)
    }

    useEffect(() => {
        id && getData(parseInt(id, 10));
    }, [id])


    return (
        <React.Fragment>
            <ScrollUp />
            <ScrollDown top={'100px'} />

            <div className={"page-content"}>
                <Container fluid>
                    <Breadcrumbs
                        breadcrumbTitle={moduleHeading}
                        breadcrumbItems={breadcrumbItems}
                    />
                    <Row md={8}>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <Row className="justify-content-center">
                                        <Col xl={8}>
                                            {loading ? <LoadingSpinner /> :
                                                <>
                                                    <div>
                                                        <h4 className="text-danger">{data?.title}</h4>
                                                        <p>{moment(data?.created_at).format('MMMM Do YYYY, h:mm:ss a')}</p>
                                                        <hr className="my-4" />
                                                    </div>
                                                    <div className="mt-4">
                                                        <div className="text-muted font-size-14" id="ContentDiv">
                                                            <p>{renderRowHTML(data?.description)}</p>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="row">
                                                        <div className="col-4">
                                                            <p>{t("DownTime")}:</p>
                                                            <p>{t("Start Date")}:{" "}{moment(data?.start_date).format('MMMM Do YYYY, h:mm A')}</p>
                                                            <p>{t("End Date")}:{" "}{moment(data?.end_date).format('MMMM Do YYYY, h:mm A')}</p>
                                                        </div>
                                                        <div className="col-4">
                                                            <p>{t("Applcation Version")}</p>
                                                            <p>{" "}{data?.application_version}</p>
                                                        </div>
                                                        <div className="col-4">
                                                            <p>{t("Severity")}</p>
                                                            <p>{" "}{data?.severity}</p>
                                                        </div>
                                                    </div>


                                                </>
                                            }
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default BroadcastView;