import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Label, Nav, NavItem, Row, UncontrolledAlert } from 'reactstrap'
import DataTable from '../../../components/Common/DataTable';
import DeleteModal from '../../../components/Common/DeleteModal';
import Breadcrumbs from '../../../components/Layout/Breadcrumb';
import { getDataTableQuery, handleFilters } from '../../../helpers/functions_helper';
import { QuestionnaireTabAction } from "../../../store/actions";
import { toast } from "react-toastify";
import Drawer from '../../../components/Drawer';
import Filters from './Filters';
import Select from "react-select";

// Import Breadcrumb

// Import Master Modal
import TabModal from "./Form";
import TabMenu from './TabMenu';
import commonService from '../../../services/common.service';
import { useTranslation } from 'react-i18next';


const Questionnaire = (props: any) => {
    const {t} = useTranslation();
    const breadcrumbItems = [
        {
            url: "/dashboard",
            label: t("Dashboard"),
            active: false,
        },
        {
            url: "#",
            label: t("Questionnaire tab"),
            active: true,
        },
    ];
    const dispatch = useDispatch();
    const savedfilters = handleFilters("GET");
    document.title = `${t("Questionnaire")} | ${t("All Questionnaire tab")} ${process.env.REACT_APP_NAME}`;

    /* set user Status */
    const [userStatusOption, setuserStatusOption] = useState([]);
    const [userstatusValue, setUserstatusValue] = useState();
    const [user_value, setUser_value] = useState();
    const [loadingUserStatus, setloadingUserStatus] = useState(true);
    const [loadingtablistData, setLoadingtablistData] = useState(true);
    const [orderChange, setOrderChange] = useState(false);

    useEffect(() => {
        setLoadingtablistData(true);
    }, [orderChange]);

    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [filters, setFilters] = useState(
        savedfilters && savedfilters?.filters
            ? (savedfilters?.filters as any)
            : {
                is_filtered: false,
                user_type: "",
                year: (new Date()).getFullYear(),
                questionnaire_type_id: 0,
            }
    );
    const handleFiltersChange = (obj: any) => {
        setFilters((state: any) => ({ ...state, ...obj }));
        setLoadingtablistData(true);
    };

    const handleApplyFilters = () => {
        setLoadingtablistData(true);
        setOpenFilter(false);
    };

    const getSelectOptionsOrder = async (tableName: string, fieldName: string) => {
        const response = await commonService.getSelectOptionsListOrder(
            tableName,
            fieldName
        );
        return response.data.data;
    };

    useEffect(() => {

        getSelectOptionsOrder("tbl_master_user_status", "user_status_value").then(function (
            options
        ) {
            setuserStatusOption(options);
            setloadingUserStatus(false);
            setUserstatusValue(options[0]);
            setUser_value(options[0].value);
        });

    }, [])


    // DataTable ----------------------------------------------
    function renderHeaderHTML(column: any, colIndex: any) {
        return <span dangerouslySetInnerHTML={{ __html: column.text }} />;
    }
    function renderRowHTML(rowContent: any) {
        return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
    }

    useEffect(()=>{
        setLoadingtablistData(true);
        settablistData([]);
      },[localStorage.getItem("I18N_LANGUAGE")])


    const columns = [
        {
            dataField: "DT_RowIndex",
            text: `${t('Sr. No.')}`,
            sort: true,
            search: false,
        },
        {
            dataField: "questionnaire_tab_value",
            text:  `${t('Tab')}`,
            sort: true,
            search: true,
            formatter: renderRowHTML,
        },
        {
            dataField: "user_status_value",
            text: `${t('User status')}`,
            sort: true,
            search: true,
            formatter: renderRowHTML,
        },
        {
            dataField: "questionnaire_type_value_html",
            text:  `${t('Questionnaire type')}`,
            sort: true,
            search: true,
            formatter: renderRowHTML,
        },
        {
            dataField: "year",
            text:  `${t('Year')}`,
            sort: true,
            search: true,
            formatter: renderRowHTML,
        },
        {
            dataField: "order",
            text: `${t('Order')}`,
            sort: true,
            search: true,
            formatter: renderRowHTML,
        },
        {
            dataField: "action",
            text: `${t('Action')}`,
            sort: false,
            search: false,
            formatter: (rowContent: any, row: any): any => {
                return (
                    <div className="d-flex gap-2 text-end">
                        <Link
                            className="btn btn-pill btn-outline-primary btn-sm"
                            to="#"
                            onClick={() => onClickDuplicate(row)}
                        >
                            <i className="fa-regular fa-copy"></i> {t("Duplicate")}
                        </Link>
                        <Link
                            className="btn btn-pill btn-outline-primary btn-sm"
                            to="#"
                            onClick={() => onClickEdit(row)}
                        >
                            <i className="fa-regular fa-pen-to-square"></i> {t("Edit")}
                        </Link>
                        <Link
                            className="btn btn-pill btn-outline-danger btn-sm"
                            to="#"
                            onClick={() => onClickDelete(row)}
                        >
                            <i className="fa-regular fa-trash-can"></i>{t("Delete")}
                        </Link>
                    </div>
                );
            },
        },
    ];



    const [datatableOptions, setDatatableOptions] = useState(
        savedfilters && savedfilters?.datatableOptions
            ? (savedfilters?.datatableOptions as any)
            : {
                draw: 1,
                page: 1,
                recordsTotal: 0,
                recordsFiltered: 0,
                sizePerPage: 20,
                searchText: "",
                sortField: "id",
                sortOrder: "desc",
            }
    );

    const [tablistData, settablistData] = useState<Array<any>>([]);


    // get store data
    let data = useSelector((state: any) => ({
        success: state.QuestionnaireTab.success,
        tablist: state.QuestionnaireTab.GET_TAB_LIST,
        error: state.QuestionnaireTab.error,
        isModel: state.QuestionnaireTab.isModel,
        loading: state.QuestionnaireTab.loading,
    }));



    const onTableChange = (type: any, newState: any) => {
        let draw = datatableOptions.draw + 1;
        let page = 1;
        if (type == "search") {
            page = 1;
        } else {
            page = newState.page;
        }

        const updatedDatatableOptions = {
            ...datatableOptions,
            draw: draw,
            page: page,
            sizePerPage: newState.sizePerPage,
            searchText: newState.searchText,
            sortField: newState.sortField,
            sortOrder: newState.sortOrder,
            scrollY: "300px",
            scrollX: true,
            scrollCollapse: true,
            fixedColumns: {
                left: 1,
                right: 1
            }
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingtablistData(true);
    };


    const clearFilter = () => {
        const updatedDatatableOptions = {
            ...datatableOptions,
            draw: 1,
            page: 1,
            sizePerPage: 20,
            searchText: "",
            sortField: "id",
            sortOrder: "desc",
        };
        setDatatableOptions(updatedDatatableOptions);

        const filter = {
            ...filters,
            is_filtered: false,
            user_type: "",
            year: (new Date()).getFullYear(),
            questionnaire_type_id: 0,
        }
        setFilters(filter);

        setLoadingtablistData(true);
    };
    // Datatable -------------------------------------------------

    // Master Form --------------------------------------------------------
    const [statusData, setStatusData] = useState({});
    const [masterFormModal, setMasterFormModal] = useState(false);
    const [masterFormModalTitle, setMasterFormModalTitle] =
        useState(t("Create Questionanire tab"));

    const onClickModal = () => {
        setStatusData("");
        setMasterFormModalTitle(t("Create Questionnaire tab"));
        setMasterFormModal(true);
    };

    const onClickEdit = (row: any) => {
        setStatusData(row);
        setMasterFormModalTitle(t("Update Questionnaire tab"));
        setMasterFormModal(true);
    };

    const onClickDuplicate = (row: any) => {
        setStatusData({ ...row, duplicate: true });
        setMasterFormModalTitle(t("Duplicate Questionnaire tab"));
        setMasterFormModal(true);
    }

    const handleSubmit = (values: any) => {
        if (values.id && values.questionnaire_tab_id && !values.duplicate) {
            dispatch(
                QuestionnaireTabAction.updateQuestionnaireTab(values, props.history)
            );
        } else {
            dispatch(
                QuestionnaireTabAction.addNewQuestionnaireTab(values, props.history)
            );
        }
    };

    // Master Form --------------------------------------------------------

    // Delete Form --------------------------------------------------------
    const [deleteData, setDeleteData] = useState({
        id: "",
        questionnaire_tab_id: "",
    });
    const [recordName, setRecordName] = useState("");
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (row: any) => {
        setDeleteModal(true);
        const deleteRowData = {
            ...deleteData,
            id: row.id,
            questionnaire_tab_id: row.questionnaire_tab_id,
        };
        setDeleteData(deleteRowData);
        setRecordName(renderRowHTML(row.questionnaire_tab_value) as any);
    };

    const handleDeleteData = () => {
        setDeleteModal(true);
        setLoadingtablistData(true);
        dispatch(
            QuestionnaireTabAction.deleteQuestionnaireTab(deleteData, props.history)
        );
    };
    // Delete Form --------------------------------------------------------

    useEffect(() => {
        if (data.success !== "") {
            toast(data.success, { type: toast.TYPE.SUCCESS })
            setMasterFormModal(false);
            setDeleteModal(false);
            setLoadingtablistData(true);
        }
        if (data.error !== "") {
            toast(data.error, { type: toast.TYPE.ERROR })
        }
    }, [data.success, data.error, dispatch]);

    useEffect(() => {
        if (loadingtablistData === true) {
            dispatch(
                QuestionnaireTabAction.getQuestionnaireTabs(
                    { ...getDataTableQuery(datatableOptions, columns), filters },
                    props.history
                )
            );
        }
    }, [loadingtablistData]);


    useEffect(() => {
        if (data.tablist?.data) {
            settablistData(data.tablist?.data);

            const updatedDatatableOptions = {
                ...datatableOptions,
                recordsTotal: data.tablist.recordsTotal,
                recordsFiltered: data.tablist.recordsFiltered,
            };
            setDatatableOptions(updatedDatatableOptions);

            setLoadingtablistData(false);
        }
    }, [data.tablist]);

    return (
        <React.Fragment>
            <div className="page-content">
                {deleteModal && (
                    <DeleteModal
                        recordName={recordName}
                        modalTitle={t("Questionnaire tab")}
                        show={deleteModal}
                        onDeleteClick={handleDeleteData}
                        onCloseClick={() => setDeleteModal(false)}
                        loading={false}
                    />
                )}

                {masterFormModal && (
                    <TabModal
                        modalTitle={masterFormModalTitle}
                        show={masterFormModal}
                        loading={data.loading}
                        statusData={statusData}
                        onSubmitClick={handleSubmit}
                        onCloseClick={() => setMasterFormModal(false)}
                    />
                )}
                <Container fluid>
                    <Breadcrumbs
                        breadcrumbTitle={t("Questionnaire")}
                        breadcrumbItems={breadcrumbItems}
                        endAdornment={
                            <Button
                                color="primary"
                                className="btn btn-primary btn-sm waves-light btn-label"
                                onClick={() => onClickModal()}
                            >
                                <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">add</span>
                                {t("Create Questionnaire tab")}
                            </Button>

                        }
                    />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardHeader className="bg-transparent border-bottom pb-0">
                                    <TabMenu type="tabs" />
                                </CardHeader>
                                <CardBody>
                                    <Row className='filter-form-row'>
                                        <Filters
                                            filters={filters}
                                            onApply={() => { }}
                                            onClear={() => { }}
                                            onChange={(obj: any) => handleFiltersChange({ ...obj, is_filtered: true })}
                                            filter_type="list"
                                        />
                                    </Row>
                                    <DataTable
                                        id={tablistData[0]?.id ? tablistData[0]?.id : 0}
                                        columns={columns}
                                        products={tablistData}
                                        datatableOptions={datatableOptions}
                                        filters={filters}
                                        onTableChange={onTableChange}
                                        loadingListData={loadingtablistData}
                                        tableName="tbl_master_questionnaire_tab"
                                        dependentField="questionnaire_type_id"
                                        dependentId={filters.questionnaire_type_id}
                                        clearFilter={clearFilter}
                                        setOrderChange={setOrderChange}
                                        settingTableName="tbl_master_questionnaire_tab"
                                        slug="all-questionnaires-tab"
                                        customButtons={
                                            <Button
                                                outline
                                                color={filters.is_filtered === true ? "secondary" : "light"}
                                                onClick={() => setOpenFilter((state) => !state)}
                                                title={t("Filter")}
                                            >
                                                {filters.is_filtered === false ? (
                                                    <span className="align-middle material-symbols-outlined fw-light fs-4">filter_alt</span>
                                                ) : (
                                                    <span className="align-middle material-symbols-outlined fw-light fs-4">filter_alt_off</span>
                                                )}
                                            </Button>
                                        } />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Drawer
                title={t("Filters")}
                open={openFilter}
                onReset={clearFilter}
                onClose={() => setOpenFilter((state) => !state)}
                footer={
                    <Button
                        color="primary"
                        style={{ margin: "16px" }}
                        onClick={handleApplyFilters}
                    >
                        {t("Apply")}
                    </Button>
                }
            >
                <Filters
                    filters={filters}
                    onApply={() => { }}
                    onClear={() => { }}
                    onChange={(obj: any) => handleFiltersChange({ ...obj, is_filtered: true })}
                    filter_type=""
                />
            </Drawer>
        </React.Fragment>
    )
}

export default Questionnaire