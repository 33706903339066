import React, { FC, useEffect, useState } from "react";
// import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../../../components/Common/Loader";
import { getUserProfile } from "../../../helpers/functions_helper";
import questionnaireService from "../../../services/questionnaire.service";
import { noData } from '../../../utils/data';
import { serialize } from "../../../utils/utils";

//import GraphFilters from './Filters';
// import GraphFilters from '../../../components/Common/GraphFilters';
import { currentMonth, currentYear } from "../../../common/staticdata";
import Chart from "../../../components/Chart";
import ChartFilters from "../../../components/Chart/Filters";

type MoreChartsProps = {
    hideHeading?: boolean;
    filters?: any;
}
const MoreCharts: FC<MoreChartsProps> = ({ hideHeading = false, filters = null }) => {

    const { t } = useTranslation();

    const userObj = getUserProfile();

    const inititalFilter = {
        week: null,
        quarter: null,
        month: currentMonth,
        year: currentYear,
        user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_ID : null
    };

    const [loading, setLoading] = useState<boolean>(true);
    const [query, setQuery] = useState<string | null>(serialize(inititalFilter));
    const [filter, setFilter] = useState<any>(inititalFilter);


    const [data, setData] = useState<any>({
        series: [],
        options: {
            chart: {
                width: 380,
                type: 'donut',
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        show: true,
                        position: 'left'
                    }
                }
            }],
            // stroke: {
            //     colors: ['#333'],
            //     width: 1
            // },
            noData: noData,
        },
    })

    const getChartData = async () => {
        const result = await questionnaireService.getMoreCharts(query);
        if (result.data.status === 'success') {
            setData((data: any) => ({
                ...data,
                series: result?.data?.data,
                options: {
                    ...data.options,
                    labels: result?.data?.xaxis?.map((category: any) => t(category)),
                    colors: ['#f1b44c', '#FF5733', '#1FE8CA'],
                }
            }))
        }
        setLoading(false);
    }

    const clearWorkAllocationFilter = () => {
        setFilter(inititalFilter);
    }

    useEffect(() => {
        loading && getChartData();
    }, [loading])

    useEffect(() => {
        setQuery(serialize(filter));
        setLoading(true);
    }, [filter])

    useEffect(() => {
        setLoading(true);
    }, [t])


    useEffect(() => {
        filters && setFilter(filters)
    }, [filters])

    return (
        <>
            <div className="d-sm-flex flex-wrap">
                <h4 className="card-title mb-4">{!hideHeading && t("Questionnaire") + " - " + t("Resident Vs Non-resident")}</h4>
                <div className="ms-auto">
                    <ChartFilters
                        filter={filter}
                        setFilter={setFilter}
                        clearFilter={clearWorkAllocationFilter}
                    />
                </div>
            </div>
            <div className="clearfix"></div>
            {loading ? <LoadingSpinner height={380} /> : <Chart options={data.options} series={data.series} type="donut" height={380} />}
        </>

    )
}

export default MoreCharts;