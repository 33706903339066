import { UPDATE_DASHBOARD, SET_DASHBOARD } from "./actionTypes"

const initialState = {
    widgets: [],
}

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SET_DASHBOARD:
            state = {
                ...state,
                widgets: action.payload
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default reducer;