import React, { useMemo } from 'react';
import {
  createColumnHelper,
  ColumnOrderState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Col, Row } from 'reactstrap';
import Toolbar from './Toolbar';
import './styles.css';
const DataTable = (props: any) => {

  function renderRowHTML(rowContent: any) {
    return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
  }

  const data = useMemo(() => props.products, []);

  const columnHelper = createColumnHelper<ColumnOrderState>();


  const [columnVisibility, setColumnVisibility] = React.useState({})
  const [columnOrder, setColumnOrder] = React.useState<any>([])


  const columns = useMemo(() => props.columns.map((item: any) =>
    columnHelper.accessor(item.dataField, {
      header: () => <span>{item.text}</span>,
    })
  ), [])

  console.log(columns);

  const table = useReactTable({
    columns,
    data,
    state: {
      columnVisibility,
      columnOrder,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    getCoreRowModel: getCoreRowModel(),
  });
  console.log(props)
  return (
    <Row>
      <Col className="col-12">
        <Row>
          <Col sm={2} className="flex d-inline">
            <span className="items-center gap-1">
              <input
                type="number"
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  table.setPageIndex(page)
                }}
                className="border p-1 rounded w-25"
              />
            </span>
            <select
              value={table.getState().pagination.pageSize}
              onChange={e => {
                table.setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </Col>
          <Col sm={6}>
            <div className="col-sm-6 col-md-7">
              <label htmlFor="search-bar-0" className="search-label">
                <span id="search-bar-0-label" className="sr-only">Search this table</span>
                <input id="search-bar-0" type="text" aria-labelledby="search-bar-0-label" className="form-control form-control data-table-search-input" placeholder="Search" value="" />
              </label>
              <button title="Clear search" className="btn btn-outline-light data-table-search-clear"><i className="fa-regular fa-circle-xmark"></i></button>
            </div>
          </Col>
          <Toolbar />
        </Row>
        <div className='react-bootstrap-table'>
          <table className='table table-striped table-bordered nowrap w-100'>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
            <tfoot>
              {table.getFooterGroups().map((footerGroup) => (
                <tr key={footerGroup.id}>
                  {footerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.footer,
                          header.getContext()
                        )}
                    </th>
                  ))}
                </tr>
              ))}
            </tfoot>
          </table>
        </div>
        <Row>
        <Col sm={4}>
            <span className="flex items-center gap-1">
              <div>Showing {table.getState().pagination.pageIndex + 1} to 10 of {table.getPageCount()} entries</div>
            </span>
          </Col>
          <Col>
            <button
              className="border rounded p-1"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              {'<<'}
            </button>
            <button
              className="border rounded p-1"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {'<'}
            </button>
            <button
              className="border rounded p-1"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {'>'}
            </button>
            <button
              className="border rounded p-1"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              {'>>'}
            </button>
          </Col>
          
        </Row>
      </Col>
    </Row>
  )
}

export default DataTable;