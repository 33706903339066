import { takeEvery, call, put } from "redux-saga/effects";
import accessRoleService from "../../../services/masters.access.role.service";
import { AnyAction } from "redux";

// AccessRoles Redux States
import {
  GET_ACCESS_ROLE,
  GET_ACCESS_ROLES_LIST,
  CREATE_ACCESS_ROLE,
  UPDATE_ACCESS_ROLE,
  DELETE_ACCESS_ROLE,
} from "./actionTypes";

import { 
    getAccessRoleSuccess, 
    getAccessRoleError,
    
    getAccessRolesListSuccess,
    getAccessRolesListError,

    createAccessRoleSuccess,
    createAccessRoleError,

    updateAccessRoleSuccess,
    updateAccessRoleError,

    deleteAccessRoleSuccess,
    deleteAccessRoleError,
} from "./actions";

function* getAccessRole({ payload: { accessroles, history } }: AnyAction): any {
  try {
    const response = yield call(accessRoleService.getAccessRole, accessroles);
    if (response.data && response.data.status === "success") {
      yield put(getAccessRoleSuccess(response.data));
    } else {
      yield put(getAccessRoleError(response.data));
    }
  } catch (error: any) {
    yield put(getAccessRoleError(error.response.data));
  }
}

function* getAccessRolesList({ payload: { query, history } }: AnyAction): any {
  try {
    const response = yield call(accessRoleService.getAccessRolesList, query);
    if (response.data && response.data.status === "success") {
      yield put(getAccessRolesListSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getAccessRolesListError(response.data));
    } else {
      yield put(getAccessRolesListError(response));
    }
  } catch (error: any) {
    yield put(getAccessRolesListError(error));
  }
}

function* createAccessRole({ payload: { accessroles, history } }: AnyAction): any {
  try {
    const response = yield call(accessRoleService.createAccessRole, accessroles);
    if (response.data && response.data.status === "success") {
      yield put(createAccessRoleSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(createAccessRoleError(response.data));
    } else {
      yield put(createAccessRoleError(response));
    }
  } catch (error: any) {
    yield put(createAccessRoleError(error));
  }
}

function* updateAccessRole({ payload: { accessroles, history } }: AnyAction): any {
  try {
    const response = yield call(accessRoleService.updateAccessRole, accessroles);
    if (response.data && response.data.status === "success") {
      yield put(updateAccessRoleSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(updateAccessRoleError(response.data));
    } else {
      yield put(updateAccessRoleError(response));
    }
  } catch (error: any) {
    yield put(updateAccessRoleError(error));
  }
}

function* deleteAccessRole({ payload: { accessroles, history } }: AnyAction): any {
  try {
    const response = yield call(accessRoleService.deleteAccessRole, accessroles);
    if (response.data && response.data.status === "success") {
      yield put(deleteAccessRoleSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(deleteAccessRoleError(response.data));
    } else {
      yield put(deleteAccessRoleError(response));
    }
  } catch (error: any) {
    yield put(deleteAccessRoleError(error));
  }
}

function* accessRolesSaga(): any {
  yield takeEvery(GET_ACCESS_ROLE, getAccessRole);
  yield takeEvery(GET_ACCESS_ROLES_LIST, getAccessRolesList);
  yield takeEvery(CREATE_ACCESS_ROLE, createAccessRole);
  yield takeEvery(UPDATE_ACCESS_ROLE, updateAccessRole);
  yield takeEvery(DELETE_ACCESS_ROLE, deleteAccessRole);
}

export default accessRolesSaga;
