import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";

const events = ["load", "mousemove", "mousedown", "click", "scroll", "keypress"];

const AppLogout = ({ children }: any) => {
  let navigate = useNavigate();
  const location = useLocation();
  const { instance } = useMsal();
  const isAzureAuthenticated = useIsAuthenticated();
  let timer: any;

  // this function sets the timer that logs out the user after 10 secs
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // logs out user
      logoutAction();
    }, 1000 * 30 * 60); // You can change the time.
  };

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  // when component mounts, it adds an event listeners to the window
  // each time any of the event is triggered, i.e on mouse move, click, scroll, keypress etc, the timer to logout user after 10 secs of inactivity resets.
  // However, if none of the event is triggered within 10 secs, that is app is inactive, the app automatically logs out.
  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);

  // logs out user by clearing out auth token in localStorage and redirecting url to /signin page.
  const logoutAction = () => {
    window.localStorage.removeItem("authUser");
    window.localStorage.removeItem("accessTokenSSO");
    window.localStorage.removeItem("refreshaccessTokenSSO");

    let bodyElement = document.getElementsByTagName("body")[0];
    bodyElement.removeAttribute("id");

    if (isAzureAuthenticated === true) {
      instance.logoutRedirect().catch((e) => {
        console.error(e);
      });
    }

    return navigate("/login?session=expired", { state: { from: location } });
  };

  return children;
};

export default AppLogout;
