import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  FormFeedback,
  Button,
  CardFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

// @ts-ignore
import CustomEditor from "ckeditor5-custom-build/build/ckeditor";
// @ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
import "ckeditor5-custom-build/build/translations/fr";
import "ckeditor5-custom-build/build/translations/nl";

import {
  ckEditorConfiguration,
  ckEditorI18,
  getUserProfile,
  handleAutoSave,
} from "../../helpers/functions_helper";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { dateFormat } from "../../utils/formats";
import { useTranslation } from "react-i18next";

import { initialValues } from "./helper";
import userManualService from "../../services/user.manual.service";
import { UserManualAction } from "../../store/actions";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt";
import Alert from "../../components/Common/Alert";
import NavigationAlert from "../../components/Common/NavigationAlert";
import { UsersAction } from "../../store/actions";

const UserManual = (props: any) => {
  const { t } = useTranslation();
  document.title = `${t("User manual")} | ` + process.env.REACT_APP_NAME;
  const dispatch = useDispatch();
  const userProfile = getUserProfile("all-user-manual");
  const [searchParams, setSearchParams] = useSearchParams();
  const filtersData: any = JSON.parse(searchParams.get("filters") as any);

  const [loading, setLoading] = useState(false);
  const [redirectCode, setRedirectCode] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [isEditMode, setIsEditMode] = useState(false);
  const localFormData = handleAutoSave("GET");
  const [autoSave, setAutoSave] = useState(false);
  const [autoSaveAlert, setAutoSaveAlert] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(autoSave);
  const [initialData, setInitialData] = useState<userManualTypes>(initialValues);

  const toastId = React.useRef(null) as any;
  let navigate = useNavigate();
  let { id, user_manual_id } = useParams();

  const handleRedirect = (isCancel = false) => {
    setAutoSave(false);
    handleAutoSave("CLEAR");
    if (isCancel === true) {
      return navigate("/user-manual/list/all");
    }

    if (redirectCode === 1) {
      return navigate("/user-manual/new-user-manual?redirect=true");
    } else if (redirectCode === 2) {
      return navigate("/user-manual/list/all?redirect=true");
    } else if (redirectCode === 3) {
      window.scrollTo(0, 0);
      return navigate(
        "/user-manual/edit-user-manual/" + id + "/" + user_manual_id + "?redirect=true"
      );
    } else {
      return navigate("/user-manual/list/all?redirect=true");
    }
  };

  useEffect(() => {
    if (redirectCode) {
      handleRedirect();
    }
  }, [redirectCode]);

  const handleChange = (fieldName: string, fieldValue: any) => {
    setInitialData({ ...initialData, [fieldName]: fieldValue });
    setAutoSave(true);
  };

  const CKinputHandler = (event: any, editor: any) => {
    setInitialData({ ...initialData, notes: editor.getData() });
    setAutoSave(true);
  };
  const CKinputHandlerFrench = (event: any, editor: any) => {
    setInitialData({ ...initialData, notes_french: editor.getData() });
    setAutoSave(true);
  };
  const CKinputHandlerDutch = (event: any, editor: any) => {
    setInitialData({ ...initialData, notes_dutch: editor.getData() });
    setAutoSave(true);
  };

  function updateInitialData(data: any) {
    const updatedInitialData = {
      ...initialData,
      id: data.id,
      user_manual_id: data.user_manual_id,
      title: data.title,
      title_french: data.title_french,
      title_dutch: data.title_dutch,
      module_url: data.module_url,
      user_manual_parent_id: data.user_manual_parent_id,
      status: data.status,

      created_by: data.created_by,
      created_by_first_name: data.created_by_first_name,
      created_by_last_name: data.created_by_last_name,
      created_at: data.created_at,
      updated_by_first_name: data.updated_by_first_name,
      updated_by_last_name: data.updated_by_last_name,
      updated_at: data.updated_at,
    };
    setInitialData(updatedInitialData);
    updateNotes(data);
  }

  function updateNotes(data: any) {
    let data_notes = "";
    if (data?.notes) {
      data_notes = data.notes;
    }

    let data_notes_french = "";
    if (data?.notes_french) {
      data_notes_french = data.notes_french;
    }

    let data_notes_dutch = "";
    if (data?.notes_dutch) {
      data_notes_dutch = data.notes_dutch;
    }

    setInitialData((prevData: any) => ({
      ...prevData,
      notes: data_notes,
      notes_french: data_notes_french,
      notes_dutch: data_notes_dutch,
    }));
  }

  const refresh = async () => {
    dispatch(UsersAction.getUserProfile());
  };

  const get = async () => {
    try {
      const res = (await userManualService.get({
        id,
        user_manual_id,
      })) as any;
      if (res.data.status === "success") {
        updateInitialData(res.data.data[0]);
        setLoading(false);
      } else {
        toast(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 5000,
          delay: 500,
        });
        setLoading(false);
        handleRedirect();
      }
    } catch (err: any) {
      toast(err?.message, {
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        delay: 500,
      });
      setLoading(false);
      handleRedirect();
    }
  };

  const create = async (values: userManualTypes) => {
    try {
      toastId.current = toast(`${t("Creating")} ${t("user manual")}...`, {
        autoClose: false,
        isLoading: true,
      });

      const res = (await userManualService.create(values)) as any;
      if (res.data.status === "success") {
        setRedirectCode(res.data.data.submit_btn_code);
        dispatch(UserManualAction.getUserManualListSuccess([]));
        refresh();

        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoading(false);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        if (res.data.data) {
          Object.keys(res.data.data).map((key) => {
            toast(res.data.data[key][0], {
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              delay: 500,
            });
          });
        }
        setLoading(false);
      }
    } catch (err: any) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }
  };

  const update = async (values: userManualTypes) => {
    try {
      toastId.current = toast(`${t("Updating")} ${t("user manual")}...`, {
        autoClose: false,
        isLoading: true,
      });

      const res = (await userManualService.update(values)) as any;
      if (res.data.status === "success") {
        setRedirectCode(res.data.data.submit_btn_code);
        dispatch(UserManualAction.getUserManualListSuccess([]));
        refresh();

        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoading(false);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        if (res.data.data) {
          Object.keys(res.data.data).map((key) => {
            toast(res.data.data[key][0], {
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              delay: 500,
            });
          });
        }
        setLoading(false);
      }
    } catch (err: any) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }
  };

  const handleSubmit = (values: userManualTypes) => {
    setLoading(true);
    if (id && user_manual_id) {
      update(values);
    } else {
      create(values);
    }
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().label(t("Title")).required(),
  });
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    if (id && user_manual_id) {
      if (localFormData && localFormData?.initialData) {
        updateInitialData(localFormData?.initialData);
        setAutoSaveAlert(true);
      } else {
        get();
      }
      setIsEditMode(true);
    } else {
      if (localFormData && localFormData?.initialData) {
        updateInitialData(localFormData?.initialData);
        setAutoSaveAlert(true);
      }
      if (filtersData) {
        setInitialData((prevData: any) => ({
          ...prevData,
          user_manual_parent_id: filtersData?.parent_id,
        }));
      }
      setIsEditMode(false);
    }
  }, []);

  // useEffect(() => {
  //   if (autoSave) {
  //     handleAutoSave("SET", initialData);
  //   }
  // }, [initialData, autoSave]);

  return (
    <React.Fragment>
      <Alert
        open={autoSaveAlert}
        title={t("Autosaved data restored!")}
        message={t(
          "Your last autosaved data has been restored from the session. In order to avoid the data loss, you need either save it to retain the data or Cancel to loose the data."
        )}
        onClickOk={() => setAutoSaveAlert(false)}
      />
      <NavigationAlert
        open={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />

      <Row>
        <Col className="col-12">
          <Card>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              autoComplete="off"
            >
              <CardBody>
                <Nav tabs>
                  <NavItem style={{ display: "inline-flex" }}>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={activeTab === 1 ? "active" : "inactive"}
                      onClick={() => {
                        setActiveTab(1);
                      }}
                    >
                      <span className="badge badge-soft-primary me-2">{t("English")}</span>{" "}
                      {t("User manual")}
                    </NavLink>

                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={activeTab === 2 ? "active" : "inactive"}
                      onClick={() => {
                        setActiveTab(2);
                      }}
                    >
                      <span className="badge badge-soft-info me-2">{t("French")}</span>{" "}
                      {t("User manual")}
                    </NavLink>

                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={activeTab === 3 ? "active" : "inactive"}
                      onClick={() => {
                        setActiveTab(3);
                      }}
                    >
                      <span className="badge badge-soft-danger me-2">{t("Dutch")}</span>{" "}
                      {t("User manual")}
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={activeTab} className="mt-3">
                  <TabPane tabId={1}>
                    <Row>
                      <Col className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Title")} <span className="text-warning is_required">*</span>
                          </Label>
                          <Input
                            id="title"
                            name="title"
                            placeholder={t("Title")}
                            type="text"
                            maxLength={200}
                            value={initialData.title}
                            autoFocus={true}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("title", e.target.value)}
                            invalid={validation.errors.title ? true : false}
                          />

                          {validation.errors.title ? (
                            <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-12">
                        <div className="mb-3">
                          <CKEditor
                            key={ckEditorI18()}
                            id="notes"
                            name="notes"
                            className="form-control"
                            editor={CustomEditor}
                            config={{ ...ckEditorConfiguration, language: ckEditorI18() }}
                            data={initialData.notes}
                            onBlur={CKinputHandler}
                          />
                        </div>
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane tabId={2}>
                    <Row>
                      <Col className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Title")} <span className="text-warning is_required">*</span>
                          </Label>
                          <Input
                            id="title_french"
                            name="title_french"
                            placeholder={t("Title")}
                            type="text"
                            maxLength={200}
                            value={initialData.title_french}
                            autoFocus={true}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("title_french", e.target.value)}
                            invalid={validation.errors.title_french ? true : false}
                          />

                          {validation.errors.title_french ? (
                            <FormFeedback type="invalid">
                              {validation.errors.title_french}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-12">
                        <div className="mb-3">
                          <CKEditor
                            key={ckEditorI18()}
                            id="notes_french"
                            name="notes_french"
                            className="form-control"
                            editor={CustomEditor}
                            config={{ ...ckEditorConfiguration, language: ckEditorI18() }}
                            data={initialData.notes_french}
                            onBlur={CKinputHandlerFrench}
                          />
                        </div>
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane tabId={3}>
                    <Row>
                      <Col className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Title")} <span className="text-warning is_required">*</span>
                          </Label>
                          <Input
                            id="title_dutch"
                            name="title_dutch"
                            placeholder={t("Title")}
                            type="text"
                            maxLength={200}
                            value={initialData.title_dutch}
                            autoFocus={true}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("title_dutch", e.target.value)}
                            invalid={validation.errors.title_dutch ? true : false}
                          />

                          {validation.errors.title_dutch ? (
                            <FormFeedback type="invalid">
                              {validation.errors.title_dutch}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-12">
                        <div className="mb-3">
                          <CKEditor
                            key={ckEditorI18()}
                            id="notes_dutch"
                            name="notes_dutch"
                            className="form-control"
                            editor={CustomEditor}
                            config={{ ...ckEditorConfiguration, language: ckEditorI18() }}
                            data={initialData.notes_dutch}
                            onBlur={CKinputHandlerDutch}
                          />
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>

                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">{t("Module URL")}</Label>
                      <Input
                        id="module_url"
                        name="module_url"
                        placeholder={t("Module URL")}
                        type="text"
                        maxLength={500}
                        value={initialData.module_url}
                        onBlur={validation.handleBlur}
                        onChange={(e) => handleChange("module_url", e.target.value)}
                        invalid={validation.errors.module_url ? true : false}
                      />

                      {validation.errors.module_url ? (
                        <FormFeedback type="invalid">{validation.errors.module_url}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </CardBody>

              <CardFooter className="d-sm-flex flex-wrap bg-transparent border-top">
                <Row>
                  <Col className="col-12 mt-2 mb-3">
                    {initialData &&
                    initialData.created_by_first_name &&
                    initialData.created_by_last_name ? (
                      <div>
                        <ul className="list-inline mb-1">
                          <li className="list-inline-item me-3">
                            <span className="text-muted">
                              <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                person
                              </span>
                              {t("Created by")} {initialData.created_by_first_name}{" "}
                              {initialData.created_by_last_name}
                            </span>
                          </li>
                          <li className="list-inline-item me-3">
                            <span className="text-muted">
                              <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                calendar_today
                              </span>
                              {dateFormat(initialData.created_at)}
                            </span>
                          </li>
                        </ul>
                      </div>
                    ) : null}

                    {initialData &&
                    initialData.updated_by_first_name &&
                    initialData.updated_by_last_name ? (
                      <div>
                        <ul className="list-inline">
                          <li className="list-inline-item me-3">
                            <span className="text-muted">
                              <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                person
                              </span>
                              {t("Last modified by")} {initialData.updated_by_first_name}{" "}
                              {initialData.updated_by_last_name}
                            </span>
                          </li>
                          <li className="list-inline-item me-3">
                            <span className="text-muted">
                              <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                calendar_today
                              </span>
                              {dateFormat(initialData.updated_at)}
                            </span>
                          </li>
                        </ul>
                      </div>
                    ) : null}
                  </Col>
                </Row>

                <div className="col-12"></div>

                <div className="d-flex gap-2"></div>

                <div className="ms-auto d-flex gap-2 justify-content-end">
                  {(userProfile.USER_ID === parseInt(initialData?.created_by) ||
                    userProfile.CAN_EDIT_ALL) &&
                    userProfile.CAN_CREATE && (
                      <Button
                        color="primary"
                        className="btn-block"
                        type="submit"
                        onClick={() => handleChange("submit_btn_code", "1")}
                        disabled={loading}
                      >
                        {loading ? (
                          <span>
                            <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                              hourglass_empty
                            </span>
                            <span>{t("Please wait...")}</span>
                          </span>
                        ) : (
                          <span>{isEditMode ? t("Update & Add new") : t("Save & Add new")}</span>
                        )}
                      </Button>
                    )}

                  {(userProfile.USER_ID === parseInt(initialData?.created_by) ||
                    userProfile.CAN_EDIT_ALL) && (
                    <Button
                      color="primary"
                      className="btn-block"
                      type="submit"
                      onClick={() => handleChange("submit_btn_code", "2")}
                      disabled={loading}
                    >
                      {loading ? (
                        <span>
                          <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                            hourglass_empty
                          </span>
                          <span>{t("Please wait...")}</span>
                        </span>
                      ) : (
                        <span>{isEditMode ? t("Update & Close") : t("Save & Close")}</span>
                      )}
                    </Button>
                  )}

                  {(userProfile.CAN_CREATE ||
                    userProfile.USER_ID === parseInt(initialData?.created_by) ||
                    userProfile.CAN_EDIT_ALL) && (
                    <Button
                      color="primary"
                      className="btn-block"
                      type="submit"
                      onClick={() => handleChange("submit_btn_code", "3")}
                      disabled={loading}
                    >
                      {loading ? (
                        <span>
                          <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                            hourglass_empty
                          </span>
                          <span>{t("Please wait...")}</span>
                        </span>
                      ) : (
                        <span>{isEditMode ? t("Update") : t("Save")}</span>
                      )}
                    </Button>
                  )}

                  <Button
                    color="danger"
                    className="btn-block"
                    type="reset"
                    onClick={() => handleRedirect(true)}
                    disabled={loading}
                  >
                    {loading ? (
                      <span>
                        <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                          hourglass_empty
                        </span>
                        <span>{t("Please wait...")}</span>
                      </span>
                    ) : (
                      <span>{t("Cancel")}</span>
                    )}
                  </Button>
                </div>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UserManual;
