import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Container, Nav, NavItem, Row } from "reactstrap"
import { useSelector } from "react-redux";
import './style.scss';
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Layout/Breadcrumb";
import RevenueStream from "./RevenueStream";
import TaskOriginated from "./TaskOriginated";
import WorkAllocation from "./WorkAllocation";
import StatusWiseTaskCount from "./StatusWiseTaskCount";
import TasksByCountry from "./TasksByCountry";
import TaskGeneratedByQuestionnaire from "./TaskGeneratedByQuestionnaire";
import InternalTaskVsExternalTask from "./InternalTaskVsExternalTask";
import InvoicePerTaskType from "../../Invoices/Charts/InvoicePerTaskType";
import GraphFilters from '../../../components/Chart/Filters';
import { getUserProfile, widgetAccess } from "../../../helpers/functions_helper";
import { useTranslation } from "react-i18next";
import { currentMonth, currentYear } from "../../../common/staticdata";



const GraphicalView = (props: any) => {
  const { t } = useTranslation();
  document.title = `${t("Tasks - Graphical view")} | ${process.env.REACT_APP_NAME}`;
  const userProfile = getUserProfile("all-tasks");
  const inititalFilter = {
    week: null,
    quarter: null,
    month: currentMonth,
    year: currentYear,
    user_id: !(userProfile.IS_PARTNER && userProfile.IS_SUPER_ADMIN) ? userProfile.USER_ID : null
  };

  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "#",
      label: t("Tasks"),
      active: true,
    },
  ];

  const [loading, setLoading] = useState<boolean>(true);
  const [filter, setFilter] = useState<any>(inititalFilter);

  const clearWorkAllocationFilter = () => {
    setFilter(inititalFilter);
  }


  //const [filter, setFilter] = useState<any[]>([inititalFilter]);

  // const clearWorkAllocationFilter = () => {
  //   setFilter([inititalFilter]);
  // }

  return (
    <React.Fragment>
      <>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              breadcrumbTitle={t("Tasks")}
              breadcrumbItems={breadcrumbItems}
              endAdornment={
                <div className="d-flex gap-2 text-end">
                  {userProfile?.IS_INTERNAL && (
                    <>
                      <Link
                        to="/tasks/all-tasks"
                        className={`btn btn-outline-secondary btn-label btn-sm`}
                      >
                        <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                          list_alt
                        </span>
                        {t("List view")}
                      </Link>

                      <Link
                        to="/tasks/kanban-view"
                        className={`btn btn-outline-secondary btn-label btn-sm`}
                      >
                        <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                          view_kanban
                        </span>
                        {t("Kanban view")}
                      </Link>
                    </>
                  )}

                  {userProfile?.IS_INTERNAL && userProfile?.CAN_VIEW_ALL && (
                    <Link to="/tasks/graph" className={`btn btn-info btn-label btn-sm`}>
                      <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                        insert_chart
                      </span>
                      {t("Graphical view")}
                    </Link>
                  )}

                  {userProfile.CAN_CREATE ? (
                    <Link to="/tasks/new-task" className="btn btn-primary btn-label btn-sm">
                      <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                        add
                      </span>{" "}
                      {t("Create task")}
                    </Link>
                  ) : null}
                </div>
              }
            />

            {widgetAccess('Task_Count') ? <StatusWiseTaskCount /> : null}
            <Row>
              <Col>
                <div className="d-sm-flex flex-wrap mt-1 mb-3">
                  <p></p>
                  <div className="ms-auto">
                    <GraphFilters
                      filter={filter}
                      setFilter={setFilter}
                      clearFilter={clearWorkAllocationFilter}
                      enableUser={true}
                      enableCompany={true}
                      slug="all-tasks"
                    //isCompareMerticsEnable
                    />
                  </div>
                </div>
              </Col>
            </Row>

            {widgetAccess('Work_Allocation') ? <Card className="border-0">
              <CardBody>
                <WorkAllocation
                  filters={filter}
                />
              </CardBody>
            </Card> : null}


            {widgetAccess('Task_originated_from_Task_types') ? <Card className="border-0">
              <CardBody>
                <TaskOriginated
                  filters={filter}
                />
              </CardBody>
            </Card> : null}

            <Row>

              {widgetAccess('Tasks_By_Country') ? <Col sm={6}>
                <Card className="border-0">
                  <CardBody style={{ height: '450px' }}>
                    <TasksByCountry
                      filters={filter}
                    />
                  </CardBody>
                </Card>
              </Col> : null}


              {widgetAccess('Revenue_Stream') ? <Col sm={6}>
                <Card className="border-0">
                  <CardBody style={{ height: '450px' }}>
                    <RevenueStream
                      filters={filter}
                    />
                  </CardBody>
                </Card>
              </Col> : null}




            </Row>

            {widgetAccess('Task_generated_by_questionnaire') ? <Card className="border-0">
              <CardBody>
                <TaskGeneratedByQuestionnaire
                  filters={filter}
                />
              </CardBody>
            </Card> : null}

            {widgetAccess('Invoice_per_task_type') ? <Card className="border-0">
              <CardBody>
                <InvoicePerTaskType
                //filters={filter}
                />
              </CardBody>
            </Card> : null}
            <Row>
              {widgetAccess('Internal_Task_Vs_External_Task') ? <Col sm={6}>
                <Card className="border-0">
                  <CardBody style={{ height: '450px' }}>
                    <InternalTaskVsExternalTask
                      filters={filter}
                    />
                  </CardBody>
                </Card>
              </Col> : null}
            </Row>
          </Container>
        </div>
      </>
    </React.Fragment >
  )
}


export default GraphicalView
