export const GET_COMPANY = "GET_COMPANY"
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS"
export const GET_COMPANY_ERROR = "GET_COMPANY_ERROR"

export const GET_COMPANY_LIST = "GET_COMPANY_LIST"
export const GET_COMPANY_LIST_SUCCESS = "GET_COMPANY_LIST_SUCCESS"
export const GET_COMPANY_LIST_ERROR = "GET_COMPANY_LIST_ERROR"
  
export const CREATE_COMPANY = "CREATE_COMPANY"
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS"
export const CREATE_COMPANY_ERROR = "CREATE_COMPANY_ERROR"

export const UPDATE_COMPANY = "UPDATE_COMPANY"
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS"
export const UPDATE_COMPANY_ERROR = "UPDATE_COMPANY_ERROR"

export const DELETE_COMPANY = "DELETE_COMPANY"
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS"
export const DELETE_COMPANY_ERROR = "DELETE_COMPANY_ERROR"

export const GET_COMPANY_GENERAL = "GET_COMPANY_GENERAL"
export const GET_COMPANY_GENERAL_SUCCESS = "GET_COMPANY_GENERAL_SUCCESS"
export const GET_COMPANY_GENERAL_ERROR = "GET_COMPANY_GENERAL_ERROR"
