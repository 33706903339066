import React, { FC, useState, useEffect } from 'react';
import { Col, Container, Input, Label, Row } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import commonService from '../../../services/common.service';
import { invoicePaymentStatuses, invoiceStatuses, YesNoMaster } from '../../../common/staticdata';
import { addIndividualInCompany, getUserProfile } from '../../../helpers/functions_helper';
import { useTranslation } from 'react-i18next';
import { CommonAction } from '../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import Select from '../../../components/Form/Select/Select';
import DatePicker from '../../../components/Form/DatePicker/DatePicker';


type FiltersProps = {
    filters: any;
    onApply: any;
    onClear: any;
    onChange?: any;
    hideCompany?: boolean;
    loading?: boolean;
}

const Filters: FC<FiltersProps> = ({ filters, onApply, onClear, onChange, hideCompany = false, loading = false }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const userObj = getUserProfile();

    const { getUserOptionsList } = useSelector((state: any) => ({
        getUserOptionsList: state.Common.GET_USER_OPTIONS_LIST_SUCCESS_NEW?.data?.filter((i: any) => i.function === 6)
    }))

    const [companyOption, setCompanyOption] = useState([]);
    const [contactPersonOption, setContactPersonOption] = useState([]);
    const [loadingCompany, setloadingCompanyOption] = useState(true);
    const [loadingContactPerson, setloadingContactPerson] = useState(true);
    const [query, setQuery] = useState("");
    const [company, setCompany] = useState<any>(null);

    const getSelectOptions = async (tableName: string, fieldName: string, otherField?: Array<string>) => {
        const response = await commonService.getSelectOptionsList(
            tableName,
            fieldName,
            "",
            otherField
        );
        return response.data.data;
    };

    const getDependentSelectOptions = async (
        tableName: string,
        fieldName: string,
        whereField: string,
        whereID: number
    ) => {
        const response = await commonService.getDependentSelectOptionsList(
            tableName,
            fieldName,
            whereField,
            whereID,
            query ? query : ""
        );
        return response.data.data;
    };


    useEffect(() => {
        getSelectOptions("tbl_master_company", "company_name", ['company_address', 'postal_code', 'city', 'TVW_Nr', 'company_name', 'invoice_payable_period', 'invoice_contact_person']).then(function (
            options
        ) {
            const item = addIndividualInCompany(options) as any;
            setCompanyOption(item);
            setloadingCompanyOption(false);
        });
    }, []);

    useEffect(() => {

        if (!loadingCompany && companyOption?.length > 0) {
            setloadingContactPerson(true);
            getDependentSelectOptions(
                "tbl_master_users",
                "first_name",
                "company",
                parseInt(filters.company_id)
            ).then(function (options) {
                if (filters?.company_id != 0) {
                    //@ts-ignore
                    setContactPersonOption(options.filter((cp: any) => cp.value == companyOption?.find((a: any) => a.value === filters?.company_id)?.invoice_contact_person));
                } else {
                    setContactPersonOption(options);
                }

                setloadingContactPerson(false);
            })
        }
    }, [filters.company_id, loadingCompany, companyOption])

    useEffect(() => {
        if (!getUserOptionsList) dispatch(CommonAction.getUserOptionsList_New());
    }, []);

    useEffect(() => {
        // setloadingCompanyOption(true)
    }, [filters?.is_filtered])

    return (
        <React.Fragment>
            {(userObj.USER_COMPANY === 1 && hideCompany === false) &&
                <>
                    <Row>
                        <Col>
                            <div className="mb-3">
                                <Select
                                    id="company_id"
                                    name="company_id"
                                    label='Client'
                                    className="is-invalid"
                                    options={companyOption}
                                    //@ts-ignore
                                    value={companyOption.find((o: any) => o.value === filters?.company_id)}
                                    isSearchable={true}
                                    isDisabled={loadingCompany}
                                    loading={loadingCompany || loading}
                                    onChange={(option: any) => {
                                        onChange({ is_filtered: true, company_id: option.value, contact_person_id: "" });
                                        setCompany(option);
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="mb-3">
                                <Select
                                    id="contact_person_id"
                                    name="contact_person_id"
                                    label="Contact Person"
                                    className="is-invalid"
                                    options={contactPersonOption}
                                    //@ts-ignore
                                    defaultValue={contactPersonOption.find((o: any) => o.value === filters?.contact_person_id)}
                                    isSearchable={true}
                                    isDisabled={loadingContactPerson}
                                    loading={loadingContactPerson}
                                    onChange={(option: any) => {
                                        onChange({ is_filtered: true, contact_person_id: option.value });
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </>
            }
            <Row>
                <Col>
                    <div className="mb-3">
                        <Row>
                            <Col>
                                <DatePicker
                                    id="invoice_date_from"
                                    name="invoice_date_from"
                                    label="Invoice Date"
                                    className="form-control d-block"
                                    type="text"
                                    placeholder="From"
                                    value={filters?.invoice_date_from}
                                    options={{
                                        altInput: true,
                                        altFormat: "d/m/Y",
                                        dateFormat: "Y-m-d H:i:S",
                                        defaultDate: filters.invoice_date_from,
                                        allowInput: true,
                                        onChange: function (
                                            selectedDates,
                                            dateStr,
                                            instance
                                        ) {
                                            onChange({ is_filtered: true, invoice_date_from: dateStr })
                                        },
                                        locale: { firstDayOfWeek: 1 }
                                    }}
                                />

                            </Col>
                            <Col>
                                <DatePicker
                                    id="invoice_date_to"
                                    name="invoice_date_to"
                                    label=' '
                                    className="form-control d-block"
                                    type="text"
                                    placeholder="To"
                                    value={filters?.invoice_date_to}
                                    options={{
                                        altInput: true,
                                        altFormat: "d/m/Y",
                                        dateFormat: "Y-m-d H:i:S",
                                        defaultDate: filters.invoice_date_to,
                                        allowInput: true,
                                        onChange: function (
                                            selectedDates,
                                            dateStr,
                                            instance
                                        ) {
                                            onChange({ is_filtered: true, invoice_date_to: dateStr })
                                        },
                                        locale: { firstDayOfWeek: 1 }
                                    }}
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            {(userObj.USER_COMPANY === 1 && hideCompany === false) &&
                <Row>
                    <Col>
                        <div className="mb-3">
                            <Select
                                id="status"
                                name="status"
                                label='Status'
                                className="is-invalid"
                                options={invoiceStatuses.map((o: any) => ({ ...o, label: t(o.label) }))}
                                //@ts-ignore
                                defaultValue={invoiceStatuses.find((o: any) => o.value === filters?.status)}
                                isSearchable={false}
                                isDisabled={loadingCompany}
                                loading={loadingCompany || loading}
                                onChange={(option: any) => {
                                    onChange({ is_filtered: true, status: option.value })
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            }
            <Row>
                <Col>
                    <div className="mb-3">
                        <Select
                            id="payment_status"
                            name="payment_status"
                            label='Payment status'
                            className="is-invalid"
                            options={invoicePaymentStatuses.map((o: any) => ({ ...o, label: t(o.label) }))}
                            //@ts-ignore
                            defaultValue={invoicePaymentStatuses.find((o: any) => o.value === filters?.payment_status)}
                            isSearchable={false}
                            isDisabled={loadingCompany}
                            loading={loadingCompany || loading}
                            onChange={(option: any) => {
                                onChange({ is_filtered: true, payment_status: option.value })
                            }}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="mb-3">
                        <Select
                            id="is_dispute"
                            name="is_dispute"
                            label='Disputes'
                            className="is-invalid"
                            options={YesNoMaster.map((o: any) => ({ ...o, label: t(o.label) }))}
                            //@ts-ignore
                            defaultValue={YesNoMaster.find((o: any) => o.value === filters?.is_dispute)}
                            isSearchable={true}
                            isDisabled={loadingCompany}
                            loading={loadingCompany || loading}
                            onChange={(option: any) => {
                                onChange({ is_filtered: true, is_dispute: option.value });
                            }}
                        />
                    </div>
                </Col>
            </Row>
            {userObj.IS_PARTNER &&
                <Row>
                    <Col>
                        <div className="mb-3">
                            <Select
                                id="partner"
                                name="partner"
                                label='Partner'
                                className="is-invalid"
                                options={getUserOptionsList}
                                //@ts-ignore
                                defaultValue={getUserOptionsList.find((o: any) => o.value === filters?.partner)}
                                isSearchable={false}
                                isDisabled={loadingCompany}
                                loading={loadingCompany || loading}
                                onChange={(option: any) => {
                                    onChange({ is_filtered: true, partner: option.value })
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            }
        </React.Fragment>

    )
}

export default Filters;