import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
  Alert,
} from "reactstrap";

// Actions
import { UserFunctionAction } from "../../../store/actions";

// Import Breadcrumb
import Breadcrumbs from "../../../components/Layout/Breadcrumb";

// Import DeleteModal
import UserFunctionModal from "./UserFunctionForm";

// Import DeleteModal
import DeleteModal from "../../../components/Common/DeleteModal";

// Import DataTable
import DataTable from "../../../components/Common/DataTable";

import { getDataTableQuery } from "../../../helpers/functions_helper";
import { useTranslation } from "react-i18next";

const AllUserFunction = (props: any) => {
  const {t} = useTranslation();
  document.title = t("User Function") + " | " + process.env.REACT_APP_NAME;
  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "#",
      label: t("User Function"),
      active: true,
    },
  ];
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();

  let {
    datatableData,
    datatableErrorMsg,
    createUserFunctionSuccessMsg,
    createUserFunctionErrorMsg,
    createUserFunctionErrorData,
    updateUserFunctionSuccessMsg,
    updateUserFunctionErrorMsg,
    updateUserFunctionErrorData,
    deleteUserFunctionSuccessMsg,
    deleteUserFunctionErrorMsg,
    deleteUserFunctionErrorData,
    getUserFunctionErrorMsg,
    loading,
  } = useSelector((state: any) => ({
    datatableData: state.UserFunction.GET_USER_FUNCTION_LIST_SUCCESS,
    datatableErrorMsg: state.UserFunction.GET_USER_FUNCTION_LIST_ERROR.message,

    createUserFunctionSuccessMsg:
      state.UserFunction.CREATE_USER_FUNCTION_SUCCESS.message,
    createUserFunctionErrorMsg:
      state.UserFunction.CREATE_USER_FUNCTION_ERROR.message,
    createUserFunctionErrorData:
      state.UserFunction.CREATE_USER_FUNCTION_ERROR.data,

    updateUserFunctionSuccessMsg:
      state.UserFunction.UPDATE_USER_FUNCTION_SUCCESS.message,
    updateUserFunctionErrorMsg:
      state.UserFunction.UPDATE_USER_FUNCTION_ERROR.message,
    updateUserFunctionErrorData:
      state.UserFunction.UPDATE_USER_FUNCTION_ERROR.data,

    deleteUserFunctionSuccessMsg:
      state.UserFunction.DELETE_USER_FUNCTION_SUCCESS.message,
    deleteUserFunctionErrorMsg:
      state.UserFunction.DELETE_USER_FUNCTION_ERROR.message,
    deleteUserFunctionErrorData:
      state.UserFunction.DELETE_USER_FUNCTION_ERROR.data,

    getUserFunctionErrorMsg: state.UserFunction.GET_USER_FUNCTION_ERROR.message,

    loading: state.UserFunction.loading,
  }));

  // DataTable ----------------------------------------------
  function renderHeaderHTML(column: any, colIndex: any) {
    return <span dangerouslySetInnerHTML={{ __html: column.text }} />;
  }
  function renderRowHTML(rowContent: any, row: any) {
    return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
  }

  useEffect(()=>{
    setLoadingListData(true);
    setUserFunctionListData([]);
  },[localStorage.getItem("I18N_LANGUAGE")])

  const columns = [
    {
      dataField: "DT_RowIndex",
      text: t("Sr. No."),
      sort: false,
      search: false,
    },
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: true,
    //   search: true,
    // },
    {
      dataField: "user_function_value_html",
      text: t("User function"),
      sort: true,
      search: true,
      formatter: renderRowHTML,
    },
    {
      dataField: "consultant_fees",
      text: t("Consultant fees"),
      sort: true,
      search: true,
      formatter: renderRowHTML,
    },
    {
      dataField: "action",
      text: t("Action"),
      sort: false,
      search: false,
      formatter: (rowContent: any, row: any): any => {
        return (
          <div className="d-flex gap-2 text-end">
            <Link
              className="btn btn-pill btn-outline-primary btn-sm"
              to="#"
              onClick={() => onClickEdit(row)}
            >
              <i className="fa-regular fa-pen-to-square"></i> {t("Edit")}
            </Link>
            {row.no_delete === 0 ? (
              <Link
                className="btn btn-pill btn-outline-danger btn-sm"
                to="#"
                onClick={() => onClickDelete(row)}
              >
                <i className="fa-regular fa-trash-can"></i> {t("Delete")}
              </Link>
            ) : null}
          </div>
        );
      },
    },
  ];

  const [loadingListData, setLoadingListData] = useState(true);
  const [datatableOptions, setDatatableOptions] = useState({
    draw: 1,
    page: 1,
    recordsTotal: 0,
    recordsFiltered: 0,
    sizePerPage: 20,
    searchText: "",
    sortField: "id",
    sortOrder: "desc",
  });

  useEffect(() => {
    if (loadingListData === true) {
      dispatch(
        UserFunctionAction.getUserFunctionList(
          getDataTableQuery(datatableOptions, columns),
          props.history
        )
      );
    }
  }, [loadingListData]);

  const [userFunctionListData, setUserFunctionListData] = useState([]);
  useEffect(() => {
    if (datatableData) {
      setUserFunctionListData(datatableData.data);

      const updatedDatatableOptions = {
        ...datatableOptions,
        recordsTotal: datatableData.recordsTotal,
        recordsFiltered: datatableData.recordsFiltered,
      };
      setDatatableOptions(updatedDatatableOptions);

      setLoadingListData(false);
      setLoader(false);
    }
  }, [datatableData]);

  const onTableChange = (type: any, newState: any) => {
    let draw = datatableOptions.draw + 1;
    let page = 1;
    if (type == "search") {
      page = 1;
    } else {
      page = newState.page;
    }
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: draw,
      page: page,
      sizePerPage: newState.sizePerPage,
      searchText: newState.searchText,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
    };
    setDatatableOptions(updatedDatatableOptions);
    setLoadingListData(true);
  };

  const clearFilter = () => {
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: 1,
      page: 1,
      sizePerPage: 20,
      searchText: "",
      sortField: "id",
      sortOrder: "desc",
    };
    setDatatableOptions(updatedDatatableOptions);
    setLoadingListData(true);
  };
  // Datatable -------------------------------------------------

  // Delete Form --------------------------------------------------------
  const [deleteUserFunction, setDeleteUserFunction] = useState({
    id: "",
    user_function_id: "",
  });
  const [recordName, setRecordName] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (row: any) => {
    const deleteUserFunctionData = {
      ...deleteUserFunction,
      id: row.id,
      user_function_id: row.user_function_id,
    };
    setDeleteUserFunction(deleteUserFunctionData);
    setRecordName(row.user_function_value);
    setDeleteModal(true);
  };

  const handleDeleteUserFunction = () => {
    dispatch(
      UserFunctionAction.deleteUserFunction(deleteUserFunction, props.history)
    );
  };
  // Delete Form --------------------------------------------------------

  // Master Form --------------------------------------------------------
  const [userFunctionData, setUserFunctionData] = useState({});

  const [masterFormModal, setMasterFormModal] = useState(false);
  const [masterFormModalTitle, setMasterFormModalTitle] = useState(
    t("Create user function")
  );

  const onClickModal = () => {
    setUserFunctionData("");
    setMasterFormModalTitle(t("Create user function"));
    setMasterFormModal(true);
  };

  const onClickEdit = (row: any) => {
    setUserFunctionData(row);
    setMasterFormModalTitle(t("Update user function"));
    setMasterFormModal(true);
  };

  const handleSubmitUserFunction = (values: any) => {
    if (values.id && values.user_function_id) {
      dispatch(UserFunctionAction.updateUserFunction(values, props.history));
    } else {
      dispatch(UserFunctionAction.createUserFunction(values, props.history));
    }
  };
  // Master Form --------------------------------------------------------

  // Messages -------------------------------------------------
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorData, setErrorData] = useState({ data: {} });

  useEffect(() => {
    if (createUserFunctionSuccessMsg) {
      setSuccessMsg(createUserFunctionSuccessMsg);
      setLoadingListData(true);
      window.scrollTo(0, 0);
      setErrorMsg("");
      setErrorData({ data: {} });
      dispatch(UserFunctionAction.createUserFunctionSuccess(""));
      setMasterFormModal(false);
    }
  }, [createUserFunctionSuccessMsg]);

  useEffect(() => {
    if (createUserFunctionErrorMsg) {
      setErrorMsg(createUserFunctionErrorMsg);
      setErrorData({ data: createUserFunctionErrorData });
      window.scrollTo(0, 0);
      setSuccessMsg("");
      dispatch(UserFunctionAction.createUserFunctionError(""));
    }
  }, [createUserFunctionErrorMsg]);

  useEffect(() => {
    if (updateUserFunctionSuccessMsg) {
      setSuccessMsg(updateUserFunctionSuccessMsg);
      setLoadingListData(true);
      window.scrollTo(0, 0);
      setErrorMsg("");
      setErrorData({ data: {} });
      dispatch(UserFunctionAction.updateUserFunctionSuccess(""));
      setMasterFormModal(false);
    }
  }, [updateUserFunctionSuccessMsg]);

  useEffect(() => {
    if (updateUserFunctionErrorMsg) {
      setErrorMsg(updateUserFunctionErrorMsg);
      setErrorData({ data: updateUserFunctionErrorData });
      window.scrollTo(0, 0);
      setSuccessMsg("");
      dispatch(UserFunctionAction.updateUserFunctionError(""));
    }
  }, [updateUserFunctionErrorMsg]);

  useEffect(() => {
    if (deleteUserFunctionSuccessMsg) {
      setSuccessMsg(deleteUserFunctionSuccessMsg);
      setLoadingListData(true);
      window.scrollTo(0, 0);
      setErrorMsg("");
      setErrorData({ data: {} });
      dispatch(UserFunctionAction.deleteUserFunctionSuccess(""));
      setDeleteModal(false);
    }
  }, [deleteUserFunctionSuccessMsg]);

  useEffect(() => {
    if (deleteUserFunctionErrorMsg) {
      setErrorMsg(deleteUserFunctionErrorMsg);
      setErrorData({ data: deleteUserFunctionErrorData });
      window.scrollTo(0, 0);
      setSuccessMsg("");
      dispatch(UserFunctionAction.deleteUserFunctionError(""));
      setDeleteModal(false);
    }
  }, [deleteUserFunctionErrorMsg]);

  useEffect(() => {
    if (getUserFunctionErrorMsg) {
      setErrorMsg(getUserFunctionErrorMsg);
      window.scrollTo(0, 0);
      setSuccessMsg("");
      dispatch(UserFunctionAction.getUserFunctionError(""));
    }
  }, [getUserFunctionErrorMsg]);
  // Messages -------------------------------------------------

  return (
    <React.Fragment>
      {deleteUserFunction && (
        <DeleteModal
          recordName={recordName}
          modalTitle="user function"
          show={deleteModal}
          onDeleteClick={handleDeleteUserFunction}
          onCloseClick={() => setDeleteModal(false)}
          loading={loading}
        />
      )}

      {masterFormModal && (
        <UserFunctionModal
          modalTitle={masterFormModalTitle}
          show={masterFormModal}
          loading={loading}
          userFunctionData={userFunctionData}
          onSubmitClick={handleSubmitUserFunction}
          onCloseClick={() => setMasterFormModal(false)}
          errorMsg={errorMsg}
          errorData={errorData}
        />
      )}

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={t("User function")}
            breadcrumbItems={breadcrumbItems}
          />

          <Row>
            <Col xl="12">
              {successMsg ? <Alert color="success">{successMsg}</Alert> : null}
              {errorMsg ? <Alert color="danger">{errorMsg}</Alert> : null}

              <Card>
                <CardHeader className="bg-transparent border-bottom">
                  <div className="d-sm-flex flex-wrap">
                    <CardTitle className="card-title text-uppercase">
                      {t("User function list")}
                    </CardTitle>
                    <div className="ms-auto d-flex gap-2">
                      <Link
                        to="#"
                        className="btn btn-primary btn-rounded waves-effect waves-light btn-label"
                        onClick={() => onClickModal()}
                      >
                        <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">add</span> {t("Create user function")}
                      </Link>
                      {/* <Button
                        color="primary"
                        className="btn-rounded waves-effect waves-light"
                      >
                        <i className="fa-solid fa-arrows-rotate"></i>
                      </Button> */}
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <DataTable
                    columns={columns}
                    products={userFunctionListData}
                    datatableOptions={datatableOptions}
                    filters={[]}
                    onTableChange={onTableChange}
                    loadingListData={loadingListData}
                    clearFilter={clearFilter}
                    slug="masters"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AllUserFunction;
