import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class AccessRolesService {
    async getAccessRole(DATA: any) {
        const response = await axios
            .get(SERVER_URL + url.GET_ACCESS_ROLE + "/" + DATA.id + "/" + DATA.access_role_id,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async getAccessRolesList(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_ACCESS_ROLES_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async createAccessRole(DATA: any) {
        const response = await axios
            .put(SERVER_URL + url.CREATE_ACCESS_ROLE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async updateAccessRole(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.UPDATE_ACCESS_ROLE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async deleteAccessRole(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.DELETE_ACCESS_ROLE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }
}

export default new AccessRolesService();