import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class AccessModulesService {
    async getAccessModule(DATA: any) {
        const response = await axios
            .get(SERVER_URL + url.GET_ACCESS_MODULE + "/" + DATA.id + "/" + DATA.access_module_id,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getAccessModulesList(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_ACCESS_MODULES_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async createAccessModule(DATA: any) {
        const response = await axios
            .put(SERVER_URL + url.CREATE_ACCESS_MODULE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            );
        return response;
    }

    async updateAccessModule(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.UPDATE_ACCESS_MODULE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            );
        return response;
    }

    async deleteAccessModule(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.DELETE_ACCESS_MODULE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            );
        return response;
    }

    async getAllModules() {
        const response = await axios
            .get(SERVER_URL + url.GET_ALL_MODULES,
                {
                    headers: getAxiosConfigWithToken()
                }
            );
        return response;
    }
}

export default new AccessModulesService();