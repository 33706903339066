import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class UserFunctionService {
    async getUserFunction(DATA: any) {
        const response = await axios
            .get(SERVER_URL + url.GET_USER_FUNCTION + "/" + DATA.id + "/" + DATA.user_function_id,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async getUserFunctionList(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_USER_FUNCTION_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async createUserFunction(DATA: any) {
        const response = await axios
            .put(SERVER_URL + url.CREATE_USER_FUNCTION, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async updateUserFunction(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.UPDATE_USER_FUNCTION, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async deleteUserFunction(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.DELETE_USER_FUNCTION, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }
}

export default new UserFunctionService();