import moment from 'moment';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, FormFeedback, Label, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { yearData } from '../../../helpers/functions_helper';
import { useFormik } from "formik";
import * as Yup from "yup";
import service from "../../../services/master.bank.holiday.service";
import { toast } from "react-toastify";
import Select from '../../../components/Form/Select/Select';
const UploadForm = (props: any) => {
    const { t } = useTranslation();
    const [intialData, setIntialData] = useState({
        year: moment('YYYY'),
    });
    const validationSchema = Yup.object().shape({
        year: Yup.string().nullable(true)
            .required("The year field is required."),
    });

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: intialData,
        validationSchema: validationSchema,
        onSubmit: (values, { setErrors }) => {
            props.onSubmitClick(values);
        },
    });



    const handleChange = (fieldName: string, fieldValue: any) => {
        setIntialData({ ...intialData, [fieldName]: fieldValue });
    };

    return (
        <React.Fragment>
            <Modal
                size="md"
                isOpen={props.show}
                toggle={props.onCloseClick}
                backdrop="static"
                keyboard={false}
                autoFocus={false}
            >
                <div className="modal-header">
                    <h5 className="modal-title">{t('Upload belgium bank holidays for year')}</h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={props.onCloseClick}
                        aria-label="Close"
                    ></button>
                </div>
                <Form
                    onSubmit={(e: any) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                    autoComplete="off"
                >
                    <ModalBody>
                        <Row>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <div>
                                        <Select
                                            id="year"
                                            name="year"
                                            label="Year"
                                            required
                                            options={yearData}
                                            isSearchable={true}
                                            value={yearData?.find((o: any) => intialData.year === o.value)}
                                            onChange={(option: any) => {
                                                handleChange("year", option.value)
                                            }}
                                            error={validation.errors.year}
                                            touched={validation.touched.year}
                                        />

                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <ul className="list-inline mb-1">
                                    <li className="list-inline-item me-3">
                                        <span className="text-muted">
                                            {t("1 January -New Year’s Day")}
                                        </span>
                                    </li>
                                    <li className="list-inline-item me-3">
                                        <span className="text-muted">
                                            {t("21 July -Belgian National Day")}
                                        </span>
                                    </li>
                                    <li className="list-inline-item me-3">
                                        <span className="text-muted">
                                            {t("15 August -Assumption Day")}
                                        </span>
                                    </li>
                                    <li className="list-inline-item me-3">
                                        <span className="text-muted">
                                            {t("1 November -All Saints’ Day")}
                                        </span>
                                    </li>
                                    <li className="list-inline-item me-3">
                                        <span className="text-muted">
                                            {t("11 November – Armistice Day")}
                                        </span>
                                    </li>
                                    <li className="list-inline-item me-3">
                                        <span className="text-muted">
                                            {t("25 December - Christmas Day")}
                                        </span>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            className="btn-block"
                            type="submit"
                            //onClick={handleSubmit}
                            disabled={props.loading}
                        >
                            {props.loading ? (
                                <span>
                                    <i className="fa-solid fa-spinner fa-spin"></i>
                                    <span>&nbsp; {t("Please wait...")}</span>
                                </span>
                            ) : (
                                <span>{t('Upload')}</span>
                            )}
                        </Button>
                        <Button
                            color="danger"
                            className="btn-block"
                            type="reset"
                            onClick={props.onCloseClick}
                            disabled={props.loading}
                        >
                            {t('Cancel')}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </React.Fragment >

    )
}

export default UploadForm