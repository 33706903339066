import React, { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Input, InputGroup, Label, Row } from "reactstrap";
import Select from "react-select";
// Actions
import Flatpickr from "react-flatpickr";
import { CommonAction } from "../../../../store/actions";
import { useTranslation } from "react-i18next";
import { filterCountryByLanguage, getSelectOptions, listingStatusOptions } from "../../../../helpers/functions_helper";
import { clientstatusOptions, YesNoMaster } from "../../../../common/staticdata";

type FiltersProps = {
  filters: any;
  onApply: any;
  onClear: any;
  onChange?: any;
};

const Filters: FC<FiltersProps> = ({ filters, onApply, onClear, onChange }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [loadingCompanyOptions, setLoadingCompanyOptions] = useState(true);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [loadingCountry, setLoadingCountry] = useState(true);
  const [countryOptions, setCountryOptions] = useState<Array<any>>([]);

  const [loadingCategory, setLoadingCategory] = useState(true);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [vatcatOptions, setVatcatOptions] = useState([]);
  const TVW_options = [
    {
      "label": t("Null"),
      "value": "null"
    },
    {
      "label": t("Not null"),
      "value": "not_null"
    },

  ]
  useEffect(() => {
    getSelectOptions("tbl_master_company_category", "category_value").then(
      function (options) {
        setCategoryOptions(options);
        setLoadingCategory(false);
      }
    );
  }, []);


  let { getCompanyOptionsList, loading } = useSelector((state: any) => ({
    getCompanyOptionsList: state.Common.GET_COMPANY_OPTIONS_LIST_SUCCESS,
    loading: state.Common.loading,
  }));

  // Get options list -------------------------------------------------
  useEffect(() => {
    if (!getCompanyOptionsList) dispatch(CommonAction.getCompanyOptionsList());
    getSelectOptions("tbl_master_country", "name", "", [
      "is_europian_country",
      "name",
      "name_french",
      "name_dutch",
    ]).then(function (options) {
      //let countryOptionsList = filterCountryByLanguage(options);
      setCountryOptions(options);
      setLoadingCountry(false);
    });
    getSelectOptions("tbl_vat_cat", "vatcat_name").then(function (
      options
    ) {
      setVatcatOptions(options);
    });
  }, []);

  useEffect(() => {
    if (getCompanyOptionsList.data) {
      setCompanyOptions(getCompanyOptionsList.data);
      setLoadingCompanyOptions(false);
    }
  }, [getCompanyOptionsList]);
  
  const [clientstatusList, setClientstatusList] = useState<any>([]);
  const [listingList, setListingList] = useState<any>([]);
  const [yesNoList, setYesNoList] = useState<any>([]);
  const [countryList, setCountryList] = useState<any>([]);
  useEffect(() => {
    if (clientstatusOptions) {
      const Options = clientstatusOptions?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setClientstatusList(Options);
    }

    if (listingStatusOptions) {
      const Options = listingStatusOptions?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setListingList(Options);
    }
    if(YesNoMaster){
      const Options = YesNoMaster?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setYesNoList(Options);
    }


   }, [])

   useEffect(()=>{
    if(countryOptions){
      const listopt = filterCountryByLanguage(countryOptions);
      setCountryList(listopt);
    }
   },[countryOptions])

  useEffect(() => {
    if (clientstatusOptions) {
      const Options = clientstatusOptions?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setClientstatusList(Options);
    }

    if (listingStatusOptions) {
      const Options = listingStatusOptions?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setListingList(Options);
    }

    if(YesNoMaster){
      const Options = YesNoMaster?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setYesNoList(Options);
    }

    if(countryOptions){
      const listopt = filterCountryByLanguage(countryOptions);
      setCountryList(listopt);
    }
  }, [localStorage.getItem("I18N_LANGUAGE")])

  return (
    <Container>
      <Row>
        <Col>
          <div className="mb-3">
            <Row>
              <Label>{t('Created date')}</Label>
              <Col>
                <Label>{t('From')}</Label>
                <div className="input-group is-invalid">
                  <Flatpickr
                    id="start_date"
                    name="start_date"
                    className="form-control d-block"
                    type="text"
                    placeholder={t("From")}
                    value={filters?.start_date}
                    options={{
                      altInput: true,
                      altFormat: "d/m/Y",
                      dateFormat: "Y-m-d H:i:S",
                      defaultDate: filters.start_date,
                      allowInput: true,
                      onChange: function (
                        selectedDates,
                        dateStr,
                        instance
                      ) {
                        onChange({ is_filtered: true, start_date: dateStr })
                      },
                      locale: { firstDayOfWeek: 1 }
                    }}
                  />
                  <span className="input-group-text">
                    <span className="align-middle material-symbols-outlined fw-light fs-4">calendar_today</span>
                  </span>
                </div>
              </Col>
              <Col>
                <Label>{t('To')}</Label>
                <div className="input-group is-invalid">
                  <Flatpickr
                    id="end_date"
                    name="end_date"
                    className="form-control d-block"
                    type="text"
                    placeholder={t("To")}
                    value={filters?.end_date}
                    options={{
                      altInput: true,
                      altFormat: "d/m/Y",
                      dateFormat: "Y-m-d H:i:S",
                      defaultDate: filters.end_date,
                      allowInput: true,
                      onChange: function (
                        selectedDates,
                        dateStr,
                        instance
                      ) {
                        onChange({ is_filtered: true, end_date: dateStr })
                      },
                      locale: { firstDayOfWeek: 1 }
                    }}
                  />
                  <span className="input-group-text">
                    <span className="align-middle material-symbols-outlined fw-light fs-4">calendar_today</span>
                  </span>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label">{t('TVA/BTW number')}</Label>
            <Select
              id="TVW_Nr"
              name="TVW_Nr"
              className="is-invalid"
              options={TVW_options}
              value={
                filters?.TVW_Nr
                  ? TVW_options.find(
                    (option: any) =>
                      option.value ==
                      filters.TVW_Nr
                  )
                  : ""
              }
              placeholder={t("Select...")}
              isSearchable={false}
              onChange={(option: any) => {
                onChange({ is_filtered: true, TVW_Nr: option.value });
              }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label">
              {t("VAT Category")}
            </Label>
            <Select
              id="vatcat"
              name="vatcat"
              className="is-invalid"
              options={vatcatOptions}
              value={
                filters?.vatcat
                  ? vatcatOptions?.find(
                    (option: any) =>
                      option.value ==
                      filters.vatcat
                  )
                  : ""
              }
              placeholder={t("Select...")}
              isSearchable={false}
              onChange={(option: any) => {
                onChange({ is_filtered: true, vatcat: option.value });
              }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label">
              {t("To Apply Vat")}
            </Label>
            <Select
              id="to_apply_vat"
              name="to_apply_vat"
              className="is-invalid"
              options={yesNoList}
              value={
                filters?.to_apply_vat
                  ? yesNoList?.find(
                    (option: any) =>
                      option.value ==
                      filters.to_apply_vat
                  )
                  : ""
              }
              placeholder={t("Select...")}
              isSearchable={false}
              onChange={(option: any) => {
                onChange({ is_filtered: true, to_apply_vat: option.value });
              }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <div className="mb-3">
            <Label className="form-label">{t('Client Status')}</Label>
            <Select
              id="client_status"
              name="client_status"
              options={clientstatusList}
              value={
                filters.client_status
                  ? clientstatusList?.find(
                    (option: any) =>
                      option.value == filters.client_status
                  )
                  : ""
              }
              placeholder={t("Select...")}
              isSearchable={false}
              onChange={(option: any) => {
                onChange({ is_filtered: true, client_status: option.value });
              }}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div className="mb-3">
            <Label className="form-label">{t('Listing status')}</Label>
            <Select
              id="listing_status_id"
              name="listing_status_id"
              className="is-invalid"
              options={listingList}
              value={
                filters.listing_status_id
                  ? listingList?.find(
                    (option: any) =>
                      option.value == filters.listing_status_id
                  )
                  : ""
              }
              placeholder={t("Select...")}
              isSearchable={false}
              onChange={(option: any) => {
                onChange({ is_filtered: true, listing_status_id: option.value });
              }}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label">
              {t('Country')}
            </Label>
            <Select
              id="country"
              name="country"
              className="is-invalid"
              options={countryList}
              value={
                filters.country
                  ? countryList.find(
                    (option: any) =>
                      option.value == filters.country
                  )
                  : ""
              }
              placeholder={t("Select...")}
              isSearchable={true}
              isDisabled={loadingCountry}
              isLoading={loadingCountry}
              onChange={(option: any) => {
                onChange({ is_filtered: true, country: option.value });
              }}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label">
              {t('Company category')}
            </Label>
            <Select
              id="company_category"
              name="company_category"
              options={categoryOptions}
              value={
                filters.company_category
                  ? categoryOptions.find(
                    (option: any) =>
                      option.value ==
                      filters.company_category
                  )
                  : ""
              }
              placeholder={t("Select...")}
              isSearchable={true}
              isLoading={loadingCategory}
              onChange={(option: any) => {
                onChange({ is_filtered: true, company_category: option.value });
              }}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label">{t('Company')}</Label>
            <Select
              options={companyOptions}
              value={
                filters?.company_id
                  ? companyOptions.find(
                    (option: any) => option.value == filters?.company_id
                  )
                  : ""
              }
              placeholder={t("Select...")}
              isSearchable={true}
              isDisabled={loadingCompanyOptions}
              isLoading={loadingCompanyOptions}
              onChange={(option: any) => {
                onChange({ is_filtered: true, company_id: option.value });
              }}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Filters;
