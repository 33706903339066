/* questions */
export const GET_QUESTIONNAIRE_QUESTIONS = "GET_QUESTIONNAIRE_QUESTIONS"
export const GET_QUESTIONNAIRE_QUESTIONS_SUCCESS = "GET_QUESTIONNAIRE_QUESTIONS_SUCCESS"
export const GET_QUESTIONNAIRE_QUESTIONS_FAIL = "GET_QUESTIONNAIRE_QUESTIONS_FAIL"


/*quetsions list*/
/* questions */
export const GET_QUESTIONS_LIST = "GET_QUESTIONS_LIST"
export const GET_QUESTIONS_LIST_SUCCESS = "GET_QUESTIONS_LIST_SUCCESS"
export const GET_QUESTIONS_LIST_FAIL = "GET_QUESTIONS_LIST_FAIL"

/* questions list with id */
export const GET_QUESTIONS_LIST_WITH_ID = "GET_QUESTIONS_LIST_WITH_ID"
export const GET_QUESTIONS_LIST_WITH_ID_SUCCESS = "GET_QUESTIONS_LIST_WITH_ID_SUCCESS"
export const GET_QUESTIONS_LIST_WITH_ID_FAIL = "GET_QUESTIONS_LIST_WITH_ID_FAIL"



/* questions DETAIL*/
export const GET_QUESTIONNAIRE_QUESTIONS_DETAIL = "GET_QUESTIONNAIRE_QUESTIONS_DETAIL"
export const GET_QUESTIONNAIRE_QUESTIONS_DETAIL_SUCCESS = "GET_QUESTIONNAIRE_QUESTIONS_DETAIL_SUCCESS"
export const GET_QUESTIONNAIRE_QUESTIONS_DETAIL_FAIL = "GET_QUESTIONNAIRE_QUESTIONS_DETAIL_FAIL"

/**
 * add questions
 */
export const ADD_NEW_QUESTIONNAIRE_QUESTIONS = "ADD_NEW_QUESTIONNAIRE_QUESTIONS"
export const ADD_QUESTIONNAIRE_QUESTIONS_SUCCESS = "ADD_QUESTIONNAIRE_QUESTIONS_SUCCESS"
export const ADD_QUESTIONNAIRE_QUESTIONS_FAIL = "ADD_QUESTIONNAIRE_QUESTIONS_FAIL"

/**
 * Edit questions
 */
export const UPDATE_QUESTIONNAIRE_QUESTIONS = "UPDATE_QUESTIONNAIRE_QUESTIONS"
export const UPDATE_QUESTIONNAIRE_QUESTIONS_SUCCESS = "UPDATE_QUESTIONNAIRE_QUESTIONS_SUCCESS"
export const UPDATE_QUESTIONNAIRE_QUESTIONS_FAIL = "UPDATE_QUESTIONNAIRE_QUESTIONS_FAIL"

/**
 * Delete questions
 */
export const DELETE_QUESTIONNAIRE_QUESTIONS = "DELETE_QUESTIONNAIRE_QUESTIONS"
export const DELETE_QUESTIONNAIRE_QUESTIONS_SUCCESS = "DELETE_QUESTIONNAIRE_QUESTIONS_SUCCESS"
export const DELETE_QUESTIONNAIRE_QUESTIONS_FAIL = "DELETE_QUESTIONNAIRE_QUESTIONS_FAIL"

/**
 * get question by id for edit
 */
export const GET_QUESTION = "GET_QUESTION"
export const GET_QUESTION_SUCCESS = "GET_QUESTION_SUCCESS"
export const GET_QUESTION_ERROR = "GET_QUESTION_ERROR"
