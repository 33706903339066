import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactApexChart from "react-apexcharts";
import { noData } from '../../../utils/data';
import LoadingSpinner from '../../../components/Common/Loader';
import { secondsToTime, serialize } from '../../../utils/utils';
import { formatCurrency } from '../../../utils/formats';
import GraphFilters from '../../../components/Common/GraphFilters';
import { getUserProfile } from '../../../helpers/functions_helper';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import splitPayrollService from '../../../services/split.payroll.service';
interface CompanyVsIndividualProps extends CartProps {
    filters?: any;
}

const CompanyVsIndividual: FC<CompanyVsIndividualProps> = ({ hideHeading, filters = null }) => {

    const { t } = useTranslation();

    const userObj = getUserProfile('all-split-payroll');
    const navigate = useNavigate();
    const inititalFilter = {
        week: null,
        quarter: null,
        month: null,
        year: null,
        user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_ID : null
    };

    const [loading, setLoading] = useState<boolean>(true);
    const [query, setQuery] = useState<string | null>(serialize(inititalFilter));
    const [filter, setFilter] = useState<any>(inititalFilter);
    const [selectedID, setSelectedID] = useState<any>({ id: null, event: null });

    const viewTaskList = (i: any) => {
        if (data.ids) {
            const x = data.ids[i];
            setSelectedID({ id: null, event: null });
            if (x !== -1) {
                let _filter = {
                    type: 'fees',
                    id: x,
                    label: 'taskOriginated'
                }
                //gfilters.setFilter(_filter);
                navigate('../tasks/all-tasks');
            }
        }
    }

    useEffect(() => {
        if (selectedID?.event === 'click') {
            viewTaskList(selectedID.id);
        }
    }, [selectedID])

    const [data, setData] = useState<any>({
        series: [0,0],
        options: {
            chart: {
                width: 380,
                type: 'pie',
                events: {
                    dataPointSelection: (event: any, chartContext: any, config: any) => {
                        //setSelectedID({ id: config.dataPointIndex, event: 'click' });
                    }
                }
            },
            // colors: [],
            labels: [t('Individual'), t('Company')],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                        height: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            legend: {
                show: true,
                position: 'right'
            },
            // dataLabels: {
            //     enabled: true,
            //     formatter: function (val:any) {
            //         return val + "%"
            //     },

            // },
            noData: noData
        },
    })

    const getChartData = async () => {
        try {
            const result = await splitPayrollService.getCompanyVsIndividual(query);
            if (result.data.status === 'success') {
                setData((data: any) => ({
                    ...data,
                    series: result?.data?.data,
                    //ids: result?.data?.data[0].ids || [],
                }))
            }
            setLoading(false);
        } catch (error: any) {
            toast(error)
        }
    }

    const clearWorkAllocationFilter = () => {
        setFilter(inititalFilter);
    }

    useEffect(() => {
        loading && getChartData();
    }, [query])

    useEffect(() => {
        setQuery(serialize(filter));
        setLoading(true);
    }, [filter])

    useEffect(() => {
        filters && setFilter(filters)
    }, [filters])

    useEffect(() => {
        setLoading(true);
      }, [localStorage.getItem("I18N_LANGUAGE")]);

    return (
        <>
            <div className="d-sm-flex flex-wrap">
                <h4 className="card-title mb-4">{!hideHeading && t("Company Vs Individual")}</h4>
                <div className="ms-auto">
                    {/* <ApplyFilter filter={filter} /> */}
                    <GraphFilters
                        filter={filter}
                        setFilter={setFilter}
                        clearWorkAllocationFilter={clearWorkAllocationFilter}
                        userObj={userObj}
                    />
                </div>
            </div>
            <div className="clearfix"></div>
            {loading ? <LoadingSpinner height={380} /> : <div style={{ height: '100%' }}><ReactApexChart options={data.options} series={data.series} type="pie" height={380} /></div>}
        </>
    )
}

export default CompanyVsIndividual;