import React, { FC, useState, memo } from 'react';
import moment from 'moment';
import Flatpickr from "react-flatpickr";
import { dateFormat } from '../../utils/formats';
import invoicesService from '../../services/invoices.service';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';


const Reminders: FC<InvoiceType> = ({
    id,
    due_date,
    reminders,
    payment_status
}) => {
    const { t } = useTranslation();

    const [remind, setRemind] = useState<any>(reminders);

    const handleEdit = (index: number) => {
        let temp = [...remind];
        temp[index]['status'] = 'EDIT';
        if (temp[index]['date'] === '') {
            temp[index]['date'] = moment(due_date).add((index + 1) * 10, 'days').format('YYYY-MM-DD');
        }
        setRemind(temp);
    }

    const handleSave = async (index: number) => {
        let temp = [...remind];
        temp[index]['status'] = 'PENDING';
        setRemind(temp);
        const res = await invoicesService.reminders({
            'invoice_id': id,
            'reminders': temp
        });
        if (res.status === 'success') {
            toast(res.message, { type: 'success' })
        }
    }

    const handleChange = (index: number, date: any) => {
        let temp = [...remind];
        temp[index]['date'] = date;
        setRemind(temp);
    }

    const remindarLabel = (label: string) => {
        const [text, count] = label.split(' ');
        return `${t(text)} ${count}`;
    }

    return (
        payment_status === 'Paid' ?
            <div className='small-text'>Already Paid</div>
            :
            <div className='small-text'>
                {remind && remind.map((reminder: any, index: number) => (
                    <div className={`${reminder?.status === 'SENT' ? 'text-success' : 'text-muted'} reminder-box`} key={index}>
                        <label>{remindarLabel(reminder.label)}:</label>
                        {reminder?.status === 'EDIT' ?
                            <>
                                <Flatpickr
                                    id="date"
                                    name="date"
                                    className="form-control d-block reminder-input"
                                    type="text"
                                    placeholder="DD/MM/YYYY"
                                    value={reminder?.date}
                                    options={{
                                        altInput: true,
                                        altFormat: "d/m/Y",
                                        dateFormat: "Y-m-d",
                                        defaultDate: reminder?.date,
                                        //Disable only for testing
                                        // minDate: due_date,
                                        allowInput: true,
                                        onChange: function (
                                            selectedDates,
                                            dateStr,
                                            instance
                                        ) {
                                            handleChange(index, dateStr);
                                        },
                                        locale: { firstDayOfWeek: 1 }
                                    }}

                                />
                                <button className='reminder-edit-btn' disabled={reminder?.date === ''} title='Save Reminder' onClick={() => handleSave(index)}>
                                    <i className='fa fa-sm fa-solid fa-save'></i>
                                </button>
                            </>
                            :
                            <>
                                {reminder?.date ? dateFormat(reminder?.date) : 'Not set'}
                                {reminder?.status === 'PENDING' && moment(reminder?.date) >= moment() ?
                                    <button className='reminder-edit-btn' title='Edit Reminder' onClick={() => handleEdit(index)}>
                                        <i className='fa fa-xs fa-solid fa-pen'></i>
                                    </button>
                                    : (reminder?.status === 'SENT' ? <button className='reminder-edit-btn'>
                                        <i className='fa fa-sm fa-solid fa-check text-success'></i>
                                    </button> : <button className='reminder-edit-btn'>
                                        <i className='fa fa-sm fa-solid fa-close text-danger'></i>
                                    </button>)
                                }
                            </>
                        }

                    </div>
                ))}
                {/* <button className='reminder-edit-btn' onClick={() => handleSave(1)}><i className='fa fa-sm fa-solid fa-plus'></i> Add Reminder</button> */}
            </div>
    )
}

export default memo(Reminders);