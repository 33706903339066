function Workbook() {
    //@ts-ignore
    if (!(this instanceof Workbook))
        //@ts-ignore
        return new Workbook()
    //@ts-ignore
    this.SheetNames = []
    //@ts-ignore
    this.Sheets = {}
}

const download = (url: any, name: any) => {
    let a = document.createElement('a')
    a.href = url
    a.download = name
    a.click()

    window.URL.revokeObjectURL(url)
}


function s2ab(s: any) {
    const buf = new ArrayBuffer(s.length)

    const view = new Uint8Array(buf)

    for (let i = 0; i !== s.length; ++i)
        view[i] = s.charCodeAt(i) & 0xFF

    return buf
}

export default (id: any, exportedFileName: string) => {
    import('xlsx').then(XLSX => {
        const table_elt = document.getElementById(id);
        // Extract Data (create a workbook object from the table)
        const workbook = XLSX.utils.table_to_book(table_elt);
        const ws = workbook.Sheets["Sheet1"];

        const wbout = XLSX.write(workbook, { bookType: 'xlsx', bookSST: true, type: 'binary' })

        const url = window.URL.createObjectURL(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }))

        download(url, exportedFileName + '.xlsx')
    })
}