import { takeEvery, call, put } from "redux-saga/effects";
import userManualService from "../../services/user.manual.service";
import { AnyAction } from "redux";

// Users Redux States
import {
  GET_USER_MANUAL_LIST,
  GET_USER_MANUAL,
} from "./actionTypes";

import {
  getUserManualListSuccess,
  getUserManualListError,

  getUserManualSuccess,
  getUserManualError,
} from "./actions";

function* getUserManualList({ payload: { history } }: AnyAction): any {
  try {
    const response = yield call(userManualService.getAll);
    if (response.data && response.data.status === "success") {
      yield put(getUserManualListSuccess(response.data));
    }
    else if (response.data && response.data.status === "error") {
      yield put(getUserManualListError(response.data));
    }
    else {
      yield put(getUserManualListError(response));
    }
  } catch (error: any) {
    yield put(getUserManualListError(error));
  }
}

function* getUserManual({ payload: { data, history } }: AnyAction): any {
  try {
    const response = yield call(userManualService.get, data);
    if (response.data && response.data.status === "success") {
      yield put(getUserManualSuccess(response.data));
    }
    else if (response.data && response.data.status === "error") {
      yield put(getUserManualError(response.data));
    }
    else {
      yield put(getUserManualError(response));
    }
  } catch (error: any) {
    yield put(getUserManualError(error));
  }
}

function* userManualSaga(): any {
  yield takeEvery(GET_USER_MANUAL_LIST, getUserManualList);
  yield takeEvery(GET_USER_MANUAL, getUserManual);
}

export default userManualSaga;
