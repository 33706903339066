import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { noData } from '../../../utils/data';
import timeSheetsService from '../../../services/time.sheets.service';
import LoadingSpinner from '../../../components/Common/Loader';
import { serialize } from '../../../utils/utils';
import { formatCurrency } from '../../../utils/formats';
import GraphFilters from '../../../components/Chart/Filters';
import { getUserProfile } from '../../../helpers/functions_helper';
import Chart from '../../../components/Chart';
import { currentMonth, currentYear } from '../../../common/staticdata';

interface FeeVsServiceChargeProps extends CartProps {
    filters?: any;
}

const FeeVsServiceCharge: FC<FeeVsServiceChargeProps> = ({ hideHeading = false, filters = null }) => {

    const { t } = useTranslation();

    const userObj: any = getUserProfile("all-time-sheets");

    const inititalFilter = {
        week: null,
        quarter: null,
        month: currentMonth,
        year: currentYear,
        user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_ID : null
    };

    const [loading, setLoading] = useState<boolean>(true);
    const [query, setQuery] = useState<string | null>(serialize(inititalFilter));
    const [filter, setFilter] = useState<any>(inititalFilter);

    const [data, setData] = useState<any>({
        series: [],
        options: {
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        show: true,
                        position: 'left'
                    }
                }
            }],
            // stroke: {
            //     colors: ['#333'],
            //     width: 1
            // },
            yaxis: {
                labels: {
                    formatter: function (value: any) {
                        return '' + formatCurrency(value);
                    }
                },
            },
            noData: noData,
        },
    })

    const getChartData = async () => {
        const result = await timeSheetsService.feeVsServiceCharge(query);
        if (result.data.status === 'success') {
            setData((data: any) => ({
                ...data,
                series: result?.data?.data?.amount && result?.data?.data?.service_charge ? [result?.data?.data?.amount, result?.data?.data?.service_charge] : [],
                options: {
                    ...data.options,
                    labels: [t('Total sum of task fee'), t('Total sum of service charges')],
                    // colors: ['#5d7afd', '#e67a00'],
                }
            }))
        }
        setLoading(false);
    }

    const clearWorkAllocationFilter = () => {
        setFilter(inititalFilter);
    }

    useEffect(() => {
        loading && getChartData();
    }, [loading])

    useEffect(() => {
        !loading && getChartData();
    }, [t])

    useEffect(() => {
        setQuery(serialize(filter));
        setLoading(true);
    }, [filter])

    useEffect(() => {
        filters && setFilter(filters)
    }, [filters])

    return (
        <>
            <div className="d-sm-flex flex-wrap">
                <h4 className="card-title mb-4">{!hideHeading && t("Task fee vs service charge")}</h4>
                <div className="ms-auto">
                    <GraphFilters
                        filter={filter}
                        setFilter={setFilter}
                        clearFilter={clearWorkAllocationFilter}
                        slug="all-time-sheets"
                    />
                </div>
            </div>
            <div className="clearfix"></div>
            <Chart loading={loading} options={data.options} series={data.series} type="donut" height={380} />
        </>
    )
}

export default FeeVsServiceCharge;