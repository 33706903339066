import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useTranslation } from "react-i18next";
import { Badge, ButtonGroup, Container, Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import Button from "../../components/Button/Button";
import invoicesService from "../../services/invoices.service";
import { getUserProfile } from "../../helpers/functions_helper";
import { dateFormat, formatCurrency } from "../../utils/formats";
import NoDataFound from "../../components/NoDataFound";
import logoIng from "../../assets/images/logos/ing_logo.png";
import saltedgeService from "../../services/saltedge.service";
import { useNavigate, useSearchParams } from "react-router-dom";
import { statusBadge } from "../../common/staticdata";
import LoadingSpinner from "../../components/Common/Loader";

const PayOnline = () => {

    const { t } = useTranslation();
    const user = getUserProfile();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [openQRcode, setOpenQRcode] = useState<boolean>(false);
    const [selectedInvoice, setSelectedInvoice] = useState<any>();
    const [invoices, setInvoices] = useState<any>([]);
    const [bankInfo, setBankInfo] = useState<any>();
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingSuccess, setLoadingSuccess] = useState<boolean>(false);
    const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);

    const end_to_end_id = searchParams.get('end_to_end_id');
    const customer_id = searchParams.get('customer_id');

    const getInvoices = async () => {
        const response = await invoicesService.getNotPaidInvoicesList({ contact_person_id: user.USER_ID });
        if (response.data.status === "success") {
            setInvoices(response.data.data);
            setBankInfo(response.data.bank_info);
            setLoading(false);
        }
    };


    const handleQRCode = (invoice: any) => {
        setSelectedInvoice(invoice);
        setOpenQRcode(true);
    }

    const handleClose = () => {
        setOpenSuccessModal(false);
        setLoading(true);
        navigate(window.location.pathname);
    }

    const getPayments = async (data: any) => {
        const res = (await saltedgeService.getPayments(data)) as any;
        if (res) {
            // console.log("getPayments===>", res.data.data?.data?.pop());
            const det = res.data.data?.data?.pop();
            const req = {
                invoice_number: det?.payment_attributes?.end_to_end_id,
                amount: det?.payment_attributes?.amount,
                payment_date: new Date().toJSON().slice(0, 10)
            }

            const result = await invoicesService.receivePayment(req);
            setLoadingSuccess(false)
        }

    }


    useEffect(() => {
        if (loading) {
            getInvoices();
        }
    }, [loading])
    useEffect(() => {
        console.log("searchParams", searchParams)

        if (customer_id && end_to_end_id) {
            setLoadingSuccess(true);
            setOpenSuccessModal(true);
            const data = {
                end_to_end_id: end_to_end_id, // searchParams.get("end_to_end_id") as any,
                customer_id: customer_id, // searchParams.get("customer_id") as any,
            };
            getPayments(data);
        }
    }, [end_to_end_id, customer_id])

    const PaymentBadge = (payment_status: any) => {
        const color = statusBadge.find((i: any) => i.status === payment_status)?.color || "primary";
        return (
            <Badge color={color} pill className={`badge_custome  badge-soft-${color}`}>{payment_status}</Badge>
        )
    }

    return (
        <div className="page-content">
            <Container fluid>
                {loading ?
                    <NoDataFound
                        icon="quick_reference_all"
                        message="Please wait, We are looking for the records!"
                    />
                    :

                    <Table className="table-striped">
                        <thead>
                            <tr>
                                <th>{t("Invoice Number")}</th>
                                <th>{t("Invoice Date")}</th>
                                <th>{t("Due Date")}</th>
                                <th className="right-align">{t("Paid Amount")}</th>
                                <th className="right-align">{t("Amount Due")}</th>
                                <th className="text-center">{t("Status")}</th>
                                <th className="text-center">{t("Pay Online")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoices.length > 0 ? invoices.map((inv: any) => (
                                <tr key={inv.id}>
                                    <td>{inv.invoice_number}</td>
                                    <td>{dateFormat(inv.invoice_date)}</td>
                                    <td className="center-align">{dateFormat(inv.due_date)}</td>
                                    <td className="right-align">{formatCurrency(inv.paid_amount)}</td>
                                    <td className="right-align">{formatCurrency(inv.amount_due)}</td>
                                    <td className="text-center">{PaymentBadge(inv.payment_status)}</td>
                                    <td className="text-center"><Button color="danger" disabled={inv.payment_status === 'Paid' || inv.payment_status === 'Over Paid'} size="sm" onClick={() => handleQRCode(inv)}>{t("Pay Online")}</Button></td>
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan={7}>
                                        <NoDataFound
                                            icon="quick_reference"
                                            message={t("No records found!")}
                                        />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                }
            </Container>
            <Modal isOpen={openQRcode} size="sm" >
                <ModalHeader toggle={() => setOpenQRcode((state: boolean) => !state)}>{t("Pay online")}</ModalHeader>
                <ModalBody>
                    <QRCodeComponent inv={selectedInvoice} bankInfo={bankInfo} />
                </ModalBody>
            </Modal>

            <Modal isOpen={openSuccessModal} size="md" >
                <ModalBody>
                    {loadingSuccess ? <LoadingSpinner /> :
                        <div className="text-center">
                            <div>
                                <span className="material-symbols-outlined text-success" style={{ fontSize: '150px' }}>
                                    check_circle
                                </span>
                            </div>
                            <div className="mt-1">
                                <span className="font-size-22 text-muted">{t("Thank you for payment")}</span>
                            </div>
                            <div className="my-4">
                                <Button outline size="lg" color="success" onClick={handleClose}>Close</Button>
                            </div>
                        </div>
                    }
                </ModalBody>
            </Modal>
        </div>
    )
}

export default PayOnline;



const QRCodeComponent = ({ inv, bankInfo }: any) => {
    const qrDataBNP = [
        "BCD",
        "001",
        "1",
        "SCT",
        "" + bankInfo?.bic,
        "Tax Consult",
        "" + bankInfo?.IBAN_Nr_prime,
        "EUR" + inv?.final_amount_due,
        "",
        inv.reference
    ];

    const qrDataKBC = [
        "BCD",
        "001",
        "1",
        "SCT",
        "GEBABEBB",
        "Tax Consult",
        "BE91735055783876",
        "EUR1",
        "",
        inv.reference
    ];

    // const qrData = [
    //     "BCD",
    //     "001",
    //     "1",
    //     "SCT",
    //     "BBRUBEBB",
    //     "Tax Consult",
    //     "BE29363194215864",
    //     "EUR" + inv.amount_due,
    //     "",
    //     inv.reference
    // ];

    const qrDataING = [
        "BCD",
        "001",
        "1",
        "SCT",
        "" + bankInfo?.bic,
        "Tax Consult",
        "" + bankInfo?.IBAN_Nr_prime,
        "EUR" + inv?.final_amount_due,
        "EUR" + inv.amount_due,
        "",
        inv.reference
    ];

    const [qrData, setQRData] = useState(qrDataING);
    const [activeBank, setActiveBank] = useState('ING');
    const [payUrl, setPayUrl] = useState(null)

    const initiatePayment = async () => {
        const data = {
            amount: "5",
            description: "Test payment",
            return_to: window.location.origin + window.location.pathname,
            end_to_end_id: inv.invoice_number,
            customer_id: '1063289485950716670',
        };

        // const ddd = await getPayments();
        const res = (await saltedgeService.initiatePayment(data)) as any;

        if (res?.data?.data?.data?.connect_url) {
            window.open(res?.data?.data?.data?.connect_url, "_self");
            // setPayUrl(res?.data?.data?.data?.connect_url)
        }
    };


    const handlePay = async () => {
        const res = await initiatePayment();
    }

    useEffect(() => {
        if (activeBank === 'KBC') {
            setQRData(qrDataKBC)
        }
        if (activeBank === 'ING') {
            setQRData(qrDataING)
        }

    }, [activeBank])
    return (
        payUrl ? <iframe src={payUrl} width={'100%'} height={800} /> :
            <div>
                {/* <div>
                <ButtonGroup size="lg" className="mb-3" style={{ width: '100%' }}>
                    <Button onClick={() => setActiveBank('KBC')} color="info">
                        KBC
                    </Button>
                    <Button onClick={() => setActiveBank('BNP')} color="success">BNP</Button>
                    <Button color="warning" disabled>ING</Button>
                </ButtonGroup>
            </div> */}
                <h1 className="text-center mb-3">{formatCurrency(inv.amount_due)}</h1>
                <QRCode value={qrData.join("\n")} />
                <p className="text-center mt-2 mb-1 font-size-16 text-muted">{qrData[6]}</p>
                <div className="text-center text-muted mb-3"><img src={logoIng} className="img-fluid" width={140} /></div>
                {/* <div className="text-center font-size-18 mb-1">OR</div>
                <div className="text-center">
                    <Button color="danger" fullWidth onClick={() => handlePay()}>Pay Online</Button>
                </div> */}
            </div>
    )
}