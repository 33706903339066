import { targetOptions } from "../../../../common/staticdata";
import { find } from 'lodash';
type TargetProps = {
    language?: number,
    language_id?: number,
    target: string,
}

const Target = (props: TargetProps) => {
    const { language, target } = props;
    const t_index = find(targetOptions, { value: target });
    switch (language) {
        case 1:
            return <div>Dear {t_index?.label}</div>
        case 2:
            return <div>Chère/Cher {t_index?.fr}</div>;
        case 3:
            return <div>Beste {t_index?.nl}</div>;
        default:
            return <div>Dear Sir / Madam</div>
    }
    // if(typeof t_index!=undefined){
    //     //return <div>Dear {t_index?.label}</div>;

    // }else{
    //     return <div>Dear Sir / Madam</div>
    // }

}


export default Target;