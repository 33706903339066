import React, { FC, useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardTitle } from "reactstrap";
import { formatCurrency } from "../../../utils/formats";
import invoicesService from "../../../services/invoices.service";
import { toast } from "react-toastify";
import { select2Styles } from "../../../helpers/functions_helper";
import { options, yearOptions, currentYear, languageCodes } from "../../../common/staticdata";
import LoadingSpinner from "../../../components/Common/Loader";
import { noData } from "../../../utils/data";
import Chart from "../../../components/Chart";
import i18next from "i18next";

type InvoiceGeneratedFromTimesheetProps = {
    hideHeading?: boolean;
}
const InvoiceGeneratedFromTimesheet: FC<InvoiceGeneratedFromTimesheetProps> = ({ hideHeading = false }) => {

    const { t } = useTranslation();

    const [loadingText, setLoadingText] = useState<string>('No data found');
    const [loading, setLoading] = useState<boolean>(true);
    const [labels, setLabels] = useState<any>([]);
    const [series, setSeries] = useState<any>([]);
    const [formValues, setFormValues] = useState<any>({
        year: currentYear
    })
    const [chartData, setChartData] = useState<any>({
        options: {
            chart: {
                type: 'line',
                stacked: false,
                toolbar: {
                    show: false
                }
            },
            stroke: {
                width: [0, 3],
                curve: 'smooth'
            },

            dataLabels: {
                enabled: false,
                enabledOnSeries: [1],
                formatter: function (value: any, opts: any) {
                    return formatCurrency(value);
                },
            },
            labels: [],
            colors: ["#2affb1", "#03354d"],

            yaxis: [
                {
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: '#2affb1'
                    },
                    labels: {
                        style: {
                            colors: '#2affb1',
                        },
                        formatter: function (value: any) {
                            return parseInt(value, 10);
                        }
                    },
                    title: {
                        text: t("Number of invoices"),
                        style: {
                            color: '#2affb1',
                        }
                    },
                    tooltip: {
                        enabled: true
                    }
                },
                {
                    seriesName: 'Revenue Generated',
                    opposite: true,
                    axisTicks: {
                        show: true,
                    },
                    axisBorder: {
                        show: true,
                        color: '#03354d'
                    },
                    labels: {
                        style: {
                            colors: '#03354d',
                        },
                        formatter: function (value: any) {
                            return formatCurrency(value);
                        }
                    },
                    title: {
                        text: t("Revenue Generated"),
                        style: {
                            color: '#03354d',
                        }
                    },
                },
            ],
            tooltip: {
                shared: true,
                intersect: false,
                custom: (opts: any) => {
                    const lang = languageCodes(i18next.language);
                    const x = opts?.w?.config?.series;
                    return `<div class="">
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item"><label>${t(x[0]?.name)}:</label> ${typeof x[0]?.data[opts?.dataPointIndex] !== "undefined" ? x[0]?.data[opts?.dataPointIndex] : null}</li>
                      <li class="list-group-item"><label>${t(x[1]?.name)}:</label> ${typeof x[1]?.data[opts?.dataPointIndex] !== "undefined" ? formatCurrency(x[1]?.data[opts?.dataPointIndex]) : null}</li>
                    </ul>
                  </div> `;
                },
            },
            plotOptions: {
                bar: {
                    columnWidth: "20%"
                },
            },
            noData: noData
        },
        series: [],
    });

    const getInvoiceGeneratedFromTimesheet = async (year: number) => {
        try {
            const res = await invoicesService.getInvoiceGeneratedFromTimesheet(year);
            if (res.status === 'success') {
                setLabels(res?.labels);
                setSeries(res?.data);
                setChartData((state: any) => (
                    {
                        ...state,
                        series: res?.data?.map((s: any) => ({ ...s, name: t(s.name) })),
                        options: {
                            ...state.options,
                            labels: res?.labels.map((a: any) => t(a)),
                        }
                    }));
            }
            setLoadingText(t("No data found"));
            setLoading(false);
        } catch (error: any) {
            toast(error)
        }
    }

    useEffect(() => {
        loading && getInvoiceGeneratedFromTimesheet(formValues.year);
    }, [loading])

    useEffect(() => {
        setChartData((state: any) => (
            {
                ...state,
                series: series.map((s: any) => ({ ...s, name: t(s.name) })),
                options: {
                    ...state.options,
                    labels: labels.map((a: any) => t(a)),
                    yaxis: [
                        {
                            axisTicks: {
                                show: true,
                            },
                            axisBorder: {
                                show: true,
                                color: '#2affb1'
                            },
                            labels: {
                                style: {
                                    colors: '#2affb1',
                                },
                                formatter: function (value: any) {
                                    return parseInt(value, 10);
                                }
                            },
                            title: {
                                text: t("Number of invoices"),
                                style: {
                                    color: '#2affb1',
                                }
                            },
                            tooltip: {
                                enabled: true
                            }
                        },
                        {
                            seriesName: 'Revenue Generated',
                            opposite: true,
                            axisTicks: {
                                show: true,
                            },
                            axisBorder: {
                                show: true,
                                color: '#03354d'
                            },
                            labels: {
                                style: {
                                    colors: '#03354d',
                                },
                                formatter: function (value: any) {
                                    return formatCurrency(value);
                                }
                            },
                            title: {
                                text: t("Revenue Generated"),
                                style: {
                                    color: '#03354d',
                                }
                            },
                        },
                    ],
                }
            }));
    }, [t])

    useEffect(() => {
        setLoading(true);
    }, [formValues.year])

    return (
        <>
            <div className="d-sm-flex flex-wrap">
                <CardTitle className="mb-3">{!hideHeading && t("Invoice generated direactly from timesheet")}</CardTitle>
                <div className="ms-auto">
                    <Select
                        id="year"
                        name="year"
                        className="form-select-sm myClassName"
                        styles={select2Styles}
                        value={yearOptions.find((i: any) => i.value === formValues.year)}
                        options={yearOptions}
                        onChange={(option: any) => {
                            setFormValues((state: any) => ({ ...state, year: option.value }))
                        }}
                    />
                </div>
            </div>
            <Chart loading={loading} options={chartData.options} series={chartData.series} type="bar" height={400} />
        </>

    )
}

export default InvoiceGeneratedFromTimesheet;