import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class TaskPriorityService {
    async getTaskPriority(DATA: any) {
        const response = await axios
            .get(SERVER_URL + url.GET_TASK_PRIORITY + "/" + DATA.id + "/" + DATA.task_priority_id,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async getTaskPriorityList(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_TASK_PRIORITY_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async createTaskPriority(DATA: any) {
        const response = await axios
            .put(SERVER_URL + url.CREATE_TASK_PRIORITY, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async updateTaskPriority(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.UPDATE_TASK_PRIORITY, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async deleteTaskPriority(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.DELETE_TASK_PRIORITY, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }
}

export default new TaskPriorityService();