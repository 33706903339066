import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class QuestionnaireTypeService {
    async getQuestionnaireType(DATA: any) {
        const response = await axios
            .get(SERVER_URL + url.GET_QUESTIONNAIRE_TYPE + "/" + DATA.id + "/" + DATA.tbl_master_questionnaire_tab,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async getQuestionnaireTypeDatatableList(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_QUESTIONNAIRE_TYPE_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            );
        return response;
    }

    async getQuestionnaireTypeList() {
        const response = await axios
            .get(SERVER_URL + url.GET_QUESTIONNAIRE_TYPES,
                {
                    headers: getAxiosConfigWithToken()
                }
            );
        return response;
    }

    async createQuestionnaireType(DATA: any) {
        const response = await axios
            .put(SERVER_URL + url.CREATE_QUESTIONNAIRE_TYPE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async updateQuestionnaireType(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.UPDATE_QUESTIONNAIRE_TYPE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async exportQuestionnaireType(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.EXPORT_QUESTIONNAIRE_TYPE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async deleteQuestionnaireType(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.DELETE_QUESTIONNAIRE_TYPE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async QuestionnaireTypePreview(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_QUESTIONNAIRE_PREVIEW, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }


    async QuestionnaireTypeActivate(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.ACTIVATE_QUESTIONNAIRE_TYPE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }
}

export default new QuestionnaireTypeService();