import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    Form,
    Label,
    FormFeedback,
    Alert,
    Card,
    CardBody,
    CardSubtitle,
    CardHeader,
    Table,
} from "reactstrap";
import Select from '../../../components/Form/Select/Select';
import Input from "../../../components/Form/Input";
import Button from "../../../components/Button/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import commonService from "../../../services/common.service";
import { useDispatch, useSelector } from "react-redux";
import { CommonAction } from "../../../store/actions";
import projectsService from "../../../services/projects.service";
import DatePicker from "../../../components/Form/DatePicker/DatePicker";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "../../../utils/formats";
import moment from "moment";
import LoadingSpinner from "../../../components/Common/Loader";
import { getUserProfile } from "../../../helpers/functions_helper";

type DocumentType = {
    id?: number | null;
    project_id?: string | null;
    company_id: number | null;
    account_id: number | null;
    partner_id: number | null;
    contact_person_id: number | null;
    name: string | null;
    from_date?: Date | string;
    to_date?: Date | string;
    po_number?: string | null;
    budget: number;
    purchase_orders?: any;
    invoiced: number;

};


const ModalForm = (props: any) => {
    let vatData = props.vatData;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const useObj = getUserProfile('projects');

    const {
        getUserOptionsList,
        getCompanyOptionsList,
        loading
    } = useSelector((state: any) => ({
        getUserOptionsList: state.Common.GET_USER_OPTIONS_LIST_SUCCESS_NEW,
        getCompanyOptionsList: state.Common.GET_COMPANY_OPTIONS_LIST_SUCCESS_NEW,
        loading: state.Tasks.loading,
    }))

    const [intialData, setIntialData] = useState<DocumentType>({
        company_id: null,
        account_id: null,
        partner_id: null,
        contact_person_id: null,
        name: null,
        po_number: '',
        budget: 0,
        from_date: "",
        to_date: "",
        invoiced: 0
    });

    const [accounts, setAccounts] = useState<Array<any>>([]);
    const [partnersOptions, setPartnerOptions] = useState<Array<any>>([]);
    const [companyOptions, setCompnayOptions] = useState<Array<any>>([]);
    const [contactPersonOption, setContactPersonOption] = useState<Array<any>>([]);
    const [query, setQuery] = useState("");
    const [loadingForm, setLoadingForm] = useState<boolean>(false);
    const [loadingContactPerson, setLoadingContactPErson] = useState<boolean>(false);
    const [projectData, setProjectData] = useState<DocumentType>({
        company_id: null,
        account_id: null,
        partner_id: null,
        contact_person_id: null,
        name: null,
        po_number: null,
        budget: 0,
        from_date: "",
        to_date: "",
        invoiced: 0
    })

    const minAmt = () => {
        if (projectData?.invoiced) {
            return Number(projectData?.invoiced) || 0
        } else {
            return 0;
        }
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().nullable().required("Project name field is required."),
        company_id: Yup.number().nullable().required("Company field is required."),
        account_id: Yup.number().nullable().required("Account field is required."),
        partner_id: Yup.number().nullable().required("Partner field is required."),
        contact_person_id: Yup.number().nullable().required("Contact person field is required."),
        budget: Yup.number().nullable().positive().min(minAmt(), 'Budget can not be less than remaining amount'),
        po_number: Yup.string().nullable().when('budget', {
            is: (budget: number) => budget > 0,//just an e.g. you can return a function
            then: Yup.string().nullable().required('Purchase order number field is required'),
            otherwise: Yup.string()
        }),
        from_date: Yup.string().when('budget', {
            is: (budget: number) => budget > 0,//just an e.g. you can return a function
            then: Yup.string().required('From date field is required'),
            otherwise: Yup.string()
        }),
        to_date: Yup.string().when('budget', {
            is: (budget: number) => budget > 0,//just an e.g. you can return a function
            then: Yup.string().required('To date field is required'),
            otherwise: Yup.string()
        }),
    });




    const handleChange = (fieldName: string, fieldValue: any) => {
        setIntialData({ ...intialData, [fieldName]: fieldValue });
    };

    const handleSubmit = (values: DocumentType, actions: any) => {
        props.onSubmitClick(values, actions);
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: intialData,
        validationSchema: validationSchema,
        onSubmit: (values, actions) => {
            handleSubmit(values, actions);
        },
    });

    const getSelectOptions = async (tableName: string, fieldName: string, otherField?: Array<string>) => {
        const response = await commonService.getSelectOptionsList(
            tableName,
            fieldName,
            query ? query : "",
            otherField
        );
        return response.data.data;
    };

    const getDependentSelectOptions = async (
        tableName: string,
        fieldName: string,
        whereField: string,
        whereID: number
    ) => {
        const response = await commonService.getDependentSelectOptionsList(
            tableName,
            fieldName,
            whereField,
            whereID,
            query ? query : ""
        );
        return response.data.data;
    };
    useEffect(() => {
        if (validation?.values?.company_id) {
            setLoadingContactPErson(true);
            getDependentSelectOptions(
                "tbl_master_users",
                "first_name",
                "company",
                validation?.values?.company_id
            ).then(function (options) {
                setContactPersonOption(options);
                setLoadingContactPErson(false);
            });
        }
    }, [validation?.values?.company_id]);

    useEffect(() => {
        if (contactPersonOption.length > 0) {
            handleChange('contact_person_id', companyOptions?.find((a: any) => a?.value === validation?.values?.company_id)?.hr_contact_person);
        }
    }, [contactPersonOption, validation?.values?.company_id])

    useEffect(() => {
        if (partnersOptions.length > 0) {
            handleChange('partner_id', companyOptions?.find((a: any) => a?.value === validation?.values?.company_id)?.signing_partner);
        }
    }, [partnersOptions, validation?.values?.company_id])

    useEffect(() => {
        getSelectOptions("tbl_master_account", "account_name").then(function (
            options
        ) {
            setAccounts(options);
        });
        if (!getUserOptionsList) dispatch(CommonAction.getUserOptionsList());
        if (!getCompanyOptionsList) dispatch(CommonAction.getCompanyOptionsList());
    }, []);

    const getProject = async (id: number) => {
        const res = await projectsService.get(id);
        if (res.status === "success") {
            setIntialData(res.data);
            setProjectData(res.data);
        }
        setLoadingForm(false);
    }
    useEffect(() => {
        if (vatData.account_id) {
            // setIntialData(vatData);
            setLoadingForm(true);
            getProject(vatData.id)
        } else {
            getProject(vatData.project)
        }
    }, [vatData])

    useEffect(() => {
        setPartnerOptions(getUserOptionsList?.data?.filter((i: any) => i.function === 6))
    }, [getUserOptionsList])

    useEffect(() => {
        if (props.isTab) {
            setCompnayOptions(getCompanyOptionsList?.data?.filter((i: any) => i.value === parseInt(props.isTab)) || []);
            handleChange("company_id", parseInt(props.isTab));
        } else {
            setCompnayOptions(getCompanyOptionsList?.data || [])
        }

    }, [getCompanyOptionsList, props.isTab])

    useEffect(() => {
        // alert(JSON.stringify(validation))
        if (!validation.isSubmitting) return;
        if (Object.keys(validation.errors).length > 0) {
            document
                .getElementById(Object.keys(validation.errors)[0])
                ?.scrollIntoView({ behavior: "smooth", block: "center" });
            document
                .getElementById(Object.keys(validation.errors)[0])
                ?.focus({ preventScroll: true });
        }
    }, [validation.isSubmitting, validation.errors]);


    return (
        <React.Fragment>
            <Modal
                size="xl"
                isOpen={props.show}
                toggle={props.onCloseClick}
                backdrop="static"
                keyboard={false}
                autoFocus={false}
            >
                <div className="modal-header">
                    <h5 className="modal-title">{props.modalTitle}</h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={props.onCloseClick}
                        aria-label="Close"
                    ></button>
                </div>

                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                    autoComplete="off"
                >
                    <ModalBody>
                        {loadingForm ? <LoadingSpinner height={450} /> :
                            <>
                                <Input
                                    id="id"
                                    name="id"
                                    type="hidden"
                                    value={validation.values.id || ""}
                                    onChange={validation.handleChange}
                                />
                                <Input
                                    id="project_id"
                                    name="project_id"
                                    type="hidden"
                                    value={validation.values.project_id || ""}
                                    onChange={validation.handleChange}
                                />
                                <Row>
                                    <Col md={5}>
                                        <Row>
                                            <Col className="col-12">
                                                <div className="mb-3">
                                                    <Select
                                                        id="company_id"
                                                        name="company_id"
                                                        label="Company"
                                                        required
                                                        className={validation.errors.company_id ? "is-invalid" : ""}
                                                        options={companyOptions}
                                                        //@ts-ignore
                                                        //defaultValue={partnersOptions.find((o: any) => o.value === validation?.values?.partners)}
                                                        value={companyOptions?.find((o: any) => validation?.values?.company_id === o.value)}
                                                        isSearchable={true}
                                                        // isDisabled={loadingCompany || isDisabled}
                                                        isLoading={loading}
                                                        onChange={(option: any) => {
                                                            handleChange("company_id", option.value);
                                                        }}
                                                        error={validation.errors.company_id}
                                                        touched={validation.touched.company_id}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-12">
                                                <div className="mb-3">
                                                    <Select
                                                        id="contact_person_id"
                                                        name="contact_person_id"
                                                        label="Contact person"
                                                        required
                                                        className={validation.errors.contact_person_id ? "is-invalid" : ""}
                                                        options={contactPersonOption}
                                                        //@ts-ignore
                                                        //defaultValue={partnersOptions.find((o: any) => o.value === validation?.values?.partners)}
                                                        value={contactPersonOption?.find((o: any) => validation?.values?.contact_person_id === o.value)}
                                                        isSearchable={true}
                                                        // isDisabled={loadingCompany || isDisabled}
                                                        isLoading={loading || loadingContactPerson}
                                                        onChange={(option: any) => {
                                                            handleChange("contact_person_id", option.value);

                                                        }}
                                                        error={validation.errors.contact_person_id}
                                                        touched={validation.touched.contact_person_id}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-12">
                                                <div className="mb-3">
                                                    <Input
                                                        id="name"
                                                        name="name"
                                                        label="Project name"
                                                        required
                                                        className="form-control"
                                                        placeholder={t("Project name")}
                                                        type="text"
                                                        value={validation.values.name || ""}
                                                        onBlur={validation.handleBlur}
                                                        onChange={(e) =>
                                                            handleChange("name", e.target.value)
                                                        }
                                                        invalid={
                                                            validation.touched.name &&
                                                                validation.errors.name
                                                                ? true
                                                                : false
                                                        }
                                                        error={validation.errors.name}
                                                        touched={validation.touched.name}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-12">
                                                <div className="mb-3">
                                                    <Select
                                                        id="partner_id"
                                                        name="partner_id"
                                                        label="Partner"
                                                        required
                                                        options={partnersOptions}
                                                        //@ts-ignore
                                                        //defaultValue={partnersOptions.find((o: any) => o.value === validation?.values?.partners)}
                                                        value={partnersOptions?.find((o: any) => validation?.values?.partner_id === o.value)}
                                                        isSearchable={true}
                                                        // isDisabled={loadingCompany || isDisabled}
                                                        onChange={(option: any) => {
                                                            handleChange("partner_id", option.value);

                                                        }}
                                                        showAvatar
                                                        error={validation.errors.partner_id}
                                                        touched={validation.touched.partner_id}
                                                    />
                                                </div>
                                            </Col>
                                            <Col className="col-12">
                                                <div className="mb-3">
                                                    <Select
                                                        id="account_id"
                                                        name="account_id"
                                                        label="Account"
                                                        required
                                                        options={accounts}
                                                        //@ts-ignore
                                                        //defaultValue={partnersOptions.find((o: any) => o.value === validation?.values?.partners)}
                                                        value={accounts?.find((o: any) => validation?.values?.account_id === o.value)}
                                                        isSearchable={true}
                                                        // isDisabled={loadingCompany || isDisabled}
                                                        onChange={(option: any) => {
                                                            handleChange("account_id", option.value);

                                                        }}
                                                        error={validation.errors.account_id}
                                                        touched={validation.touched.account_id}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Card>
                                            <CardHeader>{t("Purchase order details")}</CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col className="mb-3">
                                                        <DatePicker
                                                            id="from_date"
                                                            name="from_date"
                                                            label="From date"
                                                            className="form-control d-block"
                                                            type="text"
                                                            placeholder="DD/MM/YYYY"
                                                            value={validation.values?.from_date}
                                                            touched={true}
                                                            error={validation.errors.from_date}
                                                            options={{
                                                                altInput: true,
                                                                altFormat: "d/m/Y",
                                                                dateFormat: "Y-m-d",
                                                                defaultDate: new Date(),
                                                                allowInput: true,
                                                                onChange: function (
                                                                    selectedDates,
                                                                    dateStr,
                                                                    instance
                                                                ) {
                                                                    handleChange("from_date", dateStr)
                                                                },
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <DatePicker
                                                            id="to_date"
                                                            name="to_date"
                                                            label="To date"
                                                            className="form-control d-block"
                                                            type="text"
                                                            placeholder="DD/MM/YYYY"
                                                            value={validation.values?.to_date}
                                                            touched={true}
                                                            error={validation.errors.to_date}
                                                            options={{
                                                                altInput: true,
                                                                altFormat: "d/m/Y",
                                                                dateFormat: "Y-m-d",
                                                                defaultDate: new Date(),
                                                                minDate: validation.values?.from_date,
                                                                allowInput: true,
                                                                onChange: function (
                                                                    selectedDates,
                                                                    dateStr,
                                                                    instance
                                                                ) {
                                                                    handleChange("to_date", dateStr)
                                                                },
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <div className="mb-3">
                                                            <Input
                                                                id="po_number"
                                                                name="po_number"
                                                                label="Purchase order number"
                                                                className="form-control"
                                                                placeholder={t("Purchase order number")}
                                                                type="text"
                                                                value={validation.values.po_number || ""}
                                                                onBlur={validation.handleBlur}
                                                                onChange={(e) =>
                                                                    handleChange("po_number", e.target.value)
                                                                }
                                                                touched={true}
                                                                error={validation.errors.po_number}
                                                            />
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="mb-3">
                                                            <Input
                                                                id="budget"
                                                                name="budget"
                                                                label="Budget"
                                                                className="form-control"
                                                                placeholder={t("Budget")}
                                                                type="number"
                                                                min={0}
                                                                value={validation.values.budget || ""}
                                                                onBlur={validation.handleBlur}
                                                                onChange={(e) => {
                                                                    if (parseFloat(e.target.value) < 0)
                                                                        return false
                                                                    handleChange("budget", parseFloat(e.target.value))
                                                                }}
                                                                touched={true}
                                                                error={validation.errors.budget}
                                                            />
                                                        </div>
                                                        {validation.values?.invoiced && <div className="d-flex justify-content-between">
                                                            <div>{t("Invoiced")}: {formatCurrency(validation.values?.invoiced)}</div>
                                                            <div>{t("Balance")}: {formatCurrency(Number(projectData?.budget) - Number(projectData?.invoiced))}</div>
                                                        </div>}
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                        {validation?.values?.purchase_orders?.length > 0 &&
                                            <Row>
                                                <Col>
                                                    <Card>
                                                        <CardHeader>
                                                            {t("Purchase order history")}
                                                        </CardHeader>
                                                        <CardBody>
                                                            <Table striped bordered>
                                                                <thead className="thead-light">
                                                                    <tr>
                                                                        <th>{t("From date")}</th>
                                                                        <th>{t("To date")}</th>
                                                                        <th>{t("PO number")}</th>
                                                                        <th>{t("Budget")}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {validation?.values?.purchase_orders.map((po: any) => (
                                                                        <tr key={po.id}>
                                                                            <td>{moment(po.from_date).format('DD/MM/YYYY')}</td>
                                                                            <td>{moment(po.to_date).format('DD/MM/YYYY')}</td>
                                                                            <td>{po.po_number}</td>
                                                                            <td>{formatCurrency(po.budget)}</td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        }
                                    </Col>
                                </Row>
                            </>
                        }
                    </ModalBody>
                    {!props?.isWin &&
                        <ModalFooter>
                            <Button
                                color="primary"
                                className="btn-block"
                                type="submit"
                                disabled={validation.isSubmitting || loadingForm || !validation.isValid}
                                loading={validation.isSubmitting}
                            >
                                {t("Submit")}
                            </Button>
                            <Button
                                color="danger"
                                className="btn-block"
                                type="reset"
                                onClick={props.onCloseClick}
                                disabled={props.loading || loadingForm}
                            >
                                {t("Cancel")}
                            </Button>
                        </ModalFooter>
                    }
                </Form>
            </Modal>
        </React.Fragment>
    );
};

export default ModalForm;
