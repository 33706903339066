export const GET_EMAIL_TEMPLATE_CATEGORY = "GET_TASK_TYPE"
export const GET_EMAIL_TEMPLATE_CATEGORY_SUCCESS = "GET_EMAIL_TEMPLATE_CATEGORY_SUCCESS"
export const GET_EMAIL_TEMPLATE_CATEGORY_ERROR = "GET_EMAIL_TEMPLATE_CATEGORY_ERROR"

export const GET_EMAIL_TEMPLATE_CATEGORY_LIST = "GET_EMAIL_TEMPLATE_CATEGORY_LIST"
export const GET_EMAIL_TEMPLATE_CATEGORY_LIST_SUCCESS = "GET_EMAIL_TEMPLATE_CATEGORY_LIST_SUCCESS"
export const GET_EMAIL_TEMPLATE_CATEGORY_LIST_ERROR = "GET_EMAIL_TEMPLATE_CATEGORY_LIST_ERROR"
  
export const CREATE_EMAIL_TEMPLATE_CATEGORY = "CREATE_EMAIL_TEMPLATE_CATEGORY"
export const CREATE_EMAIL_TEMPLATE_CATEGORY_SUCCESS = "CREATE_EMAIL_TEMPLATE_CATEGORY_SUCCESS"
export const CREATE_EMAIL_TEMPLATE_CATEGORY_ERROR = "CREATE_EMAIL_TEMPLATE_CATEGORY_ERROR"


export const CREATE_DUPLICATE_EMAIL_TEMPLATE_CATEGORY = "CREATE_DUPLICATE_EMAIL_TEMPLATE_CATEGORY"
export const CREATE_DUPLICATE_EMAIL_TEMPLATE_CATEGORY_SUCCESS = "CREATE_DUPLICATE_EMAIL_TEMPLATE_CATEGORY_SUCCESS"
export const CREATE_DUPLICATE_EMAIL_TEMPLATE_CATEGORY_ERROR = "CREATE_DUPLICATE_EMAIL_TEMPLATE_CATEGORY_ERROR"

export const UPDATE_EMAIL_TEMPLATE_CATEGORY = "UPDATE_EMAIL_TEMPLATE_CATEGORY"
export const UPDATE_EMAIL_TEMPLATE_CATEGORY_SUCCESS = "UPDATE_EMAIL_TEMPLATE_CATEGORY_SUCCESS"
export const UPDATE_EMAIL_TEMPLATE_CATEGORY_ERROR = "UPDATE_EMAIL_TEMPLATE_CATEGORY_ERROR"

export const DELETE_EMAIL_TEMPLATE_CATEGORY = "DELETE_EMAIL_TEMPLATE_CATEGORY"
export const DELETE_EMAIL_TEMPLATE_CATEGORY_SUCCESS = "DELETE_EMAIL_TEMPLATE_CATEGORY_SUCCESS"
export const DELETE_EMAIL_TEMPLATE_CATEGORY_ERROR = "DELETE_EMAIL_TEMPLATE_CATEGORY_ERROR"