import {
    GET_QUESTIONNAIRE_TYPES,
    GET_QUESTIONNAIRE_TYPES_FAIL,
    GET_QUESTIONNAIRE_TYPES_SUCCESS,
    GET_QUESTIONNAIRE_TYPE_LIST,
    GET_QUESTIONNAIRE_TYPE_LIST_SUCCESS,
    GET_QUESTIONNAIRE_TYPE_LIST_FAIL,
    GET_QUESTIONNAIRE_TYPE_DETAIL,
    ADD_NEW_QUESTIONNAIRE_TYPE,
    ADD_QUESTIONNAIRE_TYPE_SUCCESS,
    ADD_QUESTIONNAIRE_TYPE_FAIL,
    UPDATE_QUESTIONNAIRE_TYPE,
    UPDATE_QUESTIONNAIRE_TYPE_SUCCESS,
    UPDATE_QUESTIONNAIRE_TYPE_FAIL,
    EXPORT_QUESTIONNAIRE_TYPE,
    EXPORT_QUESTIONNAIRE_TYPE_SUCCESS,
    EXPORT_QUESTIONNAIRE_TYPE_FAIL,
    DELETE_QUESTIONNAIRE_TYPE,
    DELETE_QUESTIONNAIRE_TYPE_SUCCESS,
    DELETE_QUESTIONNAIRE_TYPE_FAIL,
    GET_QUESTIONNAIRE_TYPE_DETAIL_FAIL,
    GET_QUESTIONNAIRE_TYPE_DETAIL_SUCCESS,
    GET_QUESTIONNAIRE_PREVIEW,
    GET_QUESTIONNAIRE_PREVIEW_SUCCESS,
    GET_QUESTIONNAIRE_PREVIEW_FAIL,
} from "./actionTypes"
import StatusModel from "./interface"


export const getQuestionnaireTypes = (query: any, history: any) => ({
    type: GET_QUESTIONNAIRE_TYPES,
    payload: { query, history },
})

export const getQuestionnaireTypesSuccess = (statuses: any) => ({
    type: GET_QUESTIONNAIRE_TYPES_SUCCESS,
    payload: statuses,
})

export const getQuestionnaireTypeList = () => ({
    type: GET_QUESTIONNAIRE_TYPE_LIST
})

export const getQuestionnaireTypeListSuccess = (Typelist: any) => ({
    type: GET_QUESTIONNAIRE_TYPE_LIST_SUCCESS,
    payload: Typelist,
})

export const getQuestionnaireTypeListFail = (error: any) => ({
    type: GET_QUESTIONNAIRE_TYPE_LIST_FAIL
})

export const addNewQuestionnaireType = (statusdata: StatusModel, history: any) => ({
    type: ADD_NEW_QUESTIONNAIRE_TYPE,
    payload: { statusdata, history },
})

export const addQuestionnaireTypesuccess = (statusdata: StatusModel) => ({
    type: ADD_QUESTIONNAIRE_TYPE_SUCCESS,
    payload: statusdata,
})

export const addQuestionnaireTypeFail = (error: any) => ({
    type: ADD_QUESTIONNAIRE_TYPE_FAIL,
    payload: error,
})

export const updateQuestionnaireType = (statusdata: StatusModel, history: any) => ({
    type: UPDATE_QUESTIONNAIRE_TYPE,
    payload: { statusdata, history },
})

export const updateQuestionnaireTypesuccess = (statusdata: StatusModel) => ({
    type: UPDATE_QUESTIONNAIRE_TYPE_SUCCESS,
    payload: statusdata,
})

export const updateQuestionnaireTypeFail = (error: any) => ({
    type: UPDATE_QUESTIONNAIRE_TYPE_FAIL,
    payload: error,
})


export const exportQuestionnaireType = (statusdata: StatusModel, history: any) => ({
    type: EXPORT_QUESTIONNAIRE_TYPE,
    payload: { statusdata, history },
})

export const exportQuestionnaireTypesuccess = (statusdata: StatusModel) => ({
    type: EXPORT_QUESTIONNAIRE_TYPE_SUCCESS,
    payload: statusdata,
})

export const exportQuestionnaireTypeFail = (error: any) => ({
    type: EXPORT_QUESTIONNAIRE_TYPE_FAIL,
    payload: error,
})

export const deleteQuestionnaireType = (statusdata: any, history: any) => ({
    type: DELETE_QUESTIONNAIRE_TYPE,
    payload: { statusdata, history },
})

export const deleteQuestionnaireTypesuccess = (statusdata: any) => ({
    type: DELETE_QUESTIONNAIRE_TYPE_SUCCESS,
    payload: statusdata,
})

export const deleteQuestionnaireTypeFail = (error: any) => ({
    type: DELETE_QUESTIONNAIRE_TYPE_FAIL,
    payload: error,
})

export const getQuestionnaireTypesFail = (error: any) => ({
    type: GET_QUESTIONNAIRE_TYPES_FAIL,
    payload: error,
})

export const getQuestionnaireTypeDetail = (statusId: any) => ({
    type: GET_QUESTIONNAIRE_TYPE_DETAIL,
    statusId,
})

export const getQuestionnaireTypeDetailSuccess = (statusDetails: StatusModel) => ({
    type: GET_QUESTIONNAIRE_TYPE_DETAIL_SUCCESS,
    payload: statusDetails,
})

export const getQuestionnaireTypeDetailFail = (error: any) => ({
    type: GET_QUESTIONNAIRE_TYPE_DETAIL_FAIL,
    payload: error,
})


// ------------------------------PREVIEW ------------------------------
export const getQuestionnairePreview = (data: any) => {
    return {
      type: GET_QUESTIONNAIRE_PREVIEW,
      payload: { data },
    };
  };
  
  export const getQuestionnairePreviewSuccess = (data: {}) => {
    return {
      type: GET_QUESTIONNAIRE_PREVIEW_SUCCESS,
      payload: data,
    };
  };
  
  export const getQuestionnairePreviewFail = (error: any) => {
    return {
      type: GET_QUESTIONNAIRE_PREVIEW_FAIL,
      payload: error,
    };
  };
  