import { useEffect, useState } from "react";
import { dateFormat } from "../../../utils/formats";
import { Button, Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import React from "react";
import DataTableBasic from "../../../components/Common/DataTableBasic";
import { datePickerI18, getDependentMultipleWhereSelectOptions, getUserProfile } from "../../../helpers/functions_helper";
import DatePicker from "../../../components/Form/DatePicker/DatePicker";
import { useTranslation } from "react-i18next";
import moment from "moment";
import FileUpload from "../../../components/Common/FileUpload";
import UploadDocModal from "../UploadDocModal";
import commonService from "../../../services/common.service";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import { Buffer } from "buffer";
import { intialData } from "../../Tasks/Charts/StatusWiseTaskCount";

type Type = {
    annex_status: number;
    annex_expiry_date: string;
    annex_due_date: string;
    annex_start_date: string;
    annex_end_date: string;
    annex_file: any;
};

const Annexture46 = (props: any) => {
    const { t } = useTranslation();
    const userProfile = getUserProfile("all-users");
    const { onChange } = props;
    const toastId = React.useRef(null) as any;

    const [annexStatus, setAnnexStatus] = useState<any>([]);
    const [initialData, setInitialData] = useState<Type>({
        annex_status: 0,
        annex_expiry_date: "",
        annex_due_date: "",
        annex_start_date: "",
        annex_end_date: "",
        annex_file: null
    });

    const statusOptions = [
        { value: "1", label: t("Pending") },
        { value: "2", label: t("Approved") },
        { value: "3", label: t("In-progress") },
        { value: "4", label: t("Recieved") },
        { value: "5", label: t("Cancelled") },
        { value: "6", label: t("Rejected") },
    ];


    const columns = [
        {
            dataField: "DT_RowIndex",
            text: `${t('Sr. No.')}`,
            formatter: (rowContent: any, row: any, idx: number): any => {
                return idx + 1;
            },
        },
        {
            dataField: "annexture_46_status",
            text: `${t('Annexture 46')}`,
            formatter: (rowContent: any, row: any, idx: number) => {
                return statusOptions?.find((option: any) => option.value == row.annex_status)
                    ?.label;
            },
        },
        {
            dataField: "annex_expiry_date",
            text: `${t('Expiry date')}`,
            formatter: (rowContent: any, row: any, idx: number): any => {
                return row.annex_expiry_date && dateFormat(row.annex_expiry_date);
            },
        },
        {
            dataField: "annex_due_date",
            text: `${t('Due date')}`,
            formatter: (rowContent: any, row: any, idx: number): any => {
                return row.annex_due_date && dateFormat(row.annex_due_date);
            },
        },

        {
            dataField: "annex_start_date",
            text: `${t('Start date')}`,
            formatter: (rowContent: any, row: any, idx: number): any => {
                return row.annex_start_date && dateFormat(row.annex_start_date);
            },
        },
        {
            dataField: "annex_end_date",
            text: `${t('End date')}`,
            formatter: (rowContent: any, row: any, idx: number): any => {
                return row.annex_end_date && dateFormat(row.annex_end_date);
            },
        },
        {
            dataField: "annex_file",
            text: `${t('File')}`,
            formatter: (rowContent: any, row: any, idx: number) => {
                const annex_file_data =
                    annexFile && annexFile.find((data: any) => data.id == row.annex_file);
                return (
                    <a className="text-primary d-block mt-1 mb-1" onClick={() => handleSingleDownload(5)}>
                        {annex_file_data?.file_name + "." + annex_file_data?.file_extension}
                    </a>
                );
            },
        },

        {
            dataField: "action",
            text: `${t('Action')}`,
            formatter: (rowContent: any, row: any, idx: number): any => {
                return (
                    <div className="d-flex gap-2">
                        <Button
                            color="primary"
                            outline={true}
                            className={`btn btn-pill btn-outline-primary btn-sm`}
                            type="button"
                            onClick={() => editRow(idx)}
                        >
                            <span className="align-middle material-symbols-outlined fw-light fs-4">edit</span>
                        </Button>

                        <Button
                            color="danger"
                            outline={true}
                            className={`btn btn-pill btn-outline-danger btn-sm`}
                            type="button"
                            onClick={() => removeRow(idx)}
                        >
                            <span className="align-middle material-symbols-outlined fw-light fs-4">delete</span>
                        </Button>
                    </div>
                );
            },
        },
    ];

    const [isEdit, setIsEdit] = useState(false);
    const [editIndex, setEditIndex] = useState<number | null>(null);
    const [addRowBtnDisabled, setAddRowBtnDisabled] = useState(true);
    const [data, setData] = useState<any>([]);
    const [dataList, setDataList] = useState<any>([]);
    const [fileData, setFileData] = useState<any>([]);
    const [uploadDocModal, setUploadDocModal] = useState<boolean>(false);
    const [uploadDocData, setUploadDocData] = useState<any>([]);
    const [annexFile, setAnnexFile] = useState<any>([]);
    const [datatableOptions, setDatatableOptions] = useState({
        draw: 1,
        page: 1,
        recordsTotal: 0,
        recordsFiltered: 0,
        sizePerPage: 5,
        searchText: "",
        sortField: "id",
        sortOrder: "desc",
    });
    const onTableChange = (type: any, newState: any) => {
        let draw = datatableOptions.draw + 1;
        let page = 1;
        if (type === "search") {
            page = 1;
        } else {
            page = newState.page;
        }
        setDatatableOptions((prevData: any) => ({
            ...prevData,
            draw: draw,
            page: page,
            sizePerPage: newState.sizePerPage,
            searchText: newState.searchText,
            sortField: newState.sortField,
            sortOrder: newState.sortOrder,
        }));

        const slicedArray = data.slice(newState.sizePerPage * (page - 1), newState.sizePerPage * page);
        setDataList(slicedArray);
    };

    const addRow = () => {
        setData((preState: any) => [
            ...preState,
            {
                annex_status: initialData?.annex_status,
                annex_expiry_date: initialData?.annex_expiry_date,
                annex_due_date: initialData?.annex_due_date,
                annex_start_date: initialData?.annex_start_date,
                annex_end_date: initialData?.annex_end_date,
                annex_file: initialData?.annex_file,
            },
        ]);

        clearForm();
        setAddRowBtnDisabled(true);
    };

    const updateRow = () => {
        if (editIndex !== null) {
            var rows: any = [...data];
            rows.map((item: any, index: number) => {
                if (index === editIndex) {
                    rows[index] = {
                        ...item,
                        annex_status: initialData?.annex_status,
                        annex_expiry_date: initialData?.annex_expiry_date,
                        annex_due_date: initialData?.annex_due_date,
                        annex_start_date: initialData?.annex_start_date,
                        annex_end_date: initialData?.annex_end_date,
                        annex_file: initialData?.annex_file
                    };
                }
            });
            setData(rows);

            clearForm();
            setIsEdit(false);
            setEditIndex(null);
        }
    };

    const removeRow = (idx: number) => {
        const rows = data.filter((item: any, index: number) => index !== idx);
        setData(rows);

        setIsEdit(false);
        setEditIndex(null);
    };

    const editRow = (idx: number) => {
        const row = data.find((item: any, index: number) => index === idx);
        setInitialData((preData: any) => ({
            ...preData,
            annex_status: row?.annex_status,
            annex_expiry_date: row?.annex_expiry_date,
            annex_due_date: row?.annex_due_date,
            annex_start_date: row?.annex_start_date,
            annex_end_date: row?.annex_end_date,
            annex_file: row?.annex_file,
        }));

        setIsEdit(true);
        setEditIndex(idx);
    };

    const clearForm = () => {
        setInitialData((preData: any) => ({
            ...preData,
            annex_status: 0,
            annex_expiry_date: "",
            annex_due_date: "",
            annex_start_date: "",
            annex_end_date: "",
            annex_file: null
        }));
    };



    const handleChange = (fieldName: string, fieldValue: any) => {
        setInitialData((preState) => ({ ...preState, [fieldName]: fieldValue }));
    };

    const handleChangeCheck = (fieldName: string, e: any) => {
        const pre_check_val = e.target.checked ? 1 : 0;
        setInitialData((preState) => ({ ...preState, [fieldName]: pre_check_val }));
    };

    useEffect(() => {
        if (initialData.annex_expiry_date) {
            const annex_due_date = moment(initialData.annex_expiry_date)
                .subtract(3, "months")
                .format("YYYY-M-D");

            const updatedIntialData = {
                ...initialData,
                annex_due_date: annex_due_date,
            };
            setInitialData(updatedIntialData);
        }
    }, [initialData.annex_expiry_date]);


    useEffect(() => {
        if (initialData.annex_expiry_date) {
            const annex_expiry_date = moment(initialData.annex_due_date)
                .add(3, "months")
                .format("YYYY-M-D");
            const updatedIntialData = {
                ...initialData,
                annex_expiry_date: annex_expiry_date
            };
            setInitialData(updatedIntialData);
        }
    }, [initialData.annex_due_date]);

    const handleUploadDocument = (type: string) => {
        const data = {
            type,
            id: props?.id,
            user_id: props?.user_id,
            title: type,
            year: initialData.annex_expiry_date
                ? moment(initialData.annex_expiry_date).format("YYYY")
                : moment().format("YYYY"),
            exp_date: initialData.annex_expiry_date,
            due_date: initialData.annex_due_date,
        };
        setUploadDocData(data);
        setUploadDocModal(true);
    };

    const handleSingleDownload = async (type: any) => {
        if (fileData) {
            console.log(type);
            const row = fileData.filter((dt: any) => dt.section_id == type)[0];
            if (row) {
                const result = await commonService.downloadAttachment({
                    id: Buffer.from(String(row.id)).toString("base64"),
                    attachment_id: Buffer.from(String(row.attachment_id)).toString("base64"),
                });
                if (result) {
                    fileDownload(result.data, row.file_name + "." + row.file_extension);
                }
            } else {
                toastId.current = toast(t("File is not available"), {
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                });
            }
        }
    };

    const onUploadSuccess = (id: any) => {
        getAttachmentList();
    };

    const getAttachmentList = async () => {
        const moduleData = {
            module_app_id: 11,
            module_main_id: props?.id,
            module_sub_id: props?.user_id,
            section_id: 5,
            file_path: "users",
        };
        const response = await commonService.getAttachmentList(moduleData);
        if (response.data.status === "success") {
            setFileData(response.data.data.original.data);
        }
    };

    useEffect(() => {
        if (fileData.length > 0) {
            const row_permit = fileData.filter((dt: any) => dt.section_id == 5);
            setAnnexFile(row_permit);

            setInitialData((preData: any) => ({
                ...preData,
                annex_file: row_permit && row_permit[0]?.id,
            }));
        }
    }, [fileData]);

    useEffect(() => {
        if (
            initialData.annex_status !== 0 &&
            initialData.annex_expiry_date !== "" &&
            initialData.annex_due_date !== "" &&
            initialData.annex_start_date !== "" &&
            initialData.annex_end_date !== "" &&
            initialData.annex_file !== null
        ) {
            setAddRowBtnDisabled(false);
        } else {
            setAddRowBtnDisabled(true);
        }
    }, [
        initialData.annex_status,
        initialData.annex_expiry_date,
        initialData.annex_due_date,
        initialData.annex_start_date,
        initialData.annex_end_date,
        initialData.annex_file
    ]);

    useEffect(() => {
        setData(props?.data);
    }, [props?.data]);

    useEffect(() => {
        setDatatableOptions((prevData: any) => ({
            ...prevData,
            draw: 1,
            page: 1,
            sizePerPage: 5,
            recordsTotal: data.length,
            recordsFiltered: data.length,
        }));

        const slicedArray = data.slice(0, 5);
        setDataList(slicedArray);

        onChange(data);
    }, [data]);

    useEffect(() => {
        getAttachmentList();
    }, []);

    return (
        <React.Fragment>
            {uploadDocModal && (
                <UploadDocModal
                    data={uploadDocData}
                    isOpen={uploadDocModal}
                    handleClose={() => setUploadDocModal(false)}
                    onSuccess={onUploadSuccess}
                    type="user"
                />
            )}

            <Row>

                <Col xl={2} lg={2} md={2} sm={12} id="annexure_46">
                    <div className="mb-3">
                        <Label className="form-label">{t("Status")} </Label>
                        {intialData.annex_status}
                        <Select
                            id="annex_status"
                            name="annex_status"
                            className="is-invalid"
                            options={statusOptions}
                            value={statusOptions.find((option: any) => option.value == intialData.annex_status)}
                            isSearchable={true}
                            onChange={(e: any) => {
                                handleChange("annex_status", e.value);
                                setAnnexStatus(e.value);
                            }}
                        />
                    </div>
                </Col>

                {(annexStatus == 2 || annexStatus == 4) &&
                    <>
                        <Col xl={7} lg={7} md={7} sm={12}>
                            <Row>
                                <Col xl={3} lg={3} md={3} sm={12}>
                                    <div className="mb-3">
                                        <DatePicker
                                            id="annexure_expiry_date"
                                            name="annexure_expiry_date"
                                            label={t("Expiry date")}
                                            required={true}
                                            value={initialData.annex_expiry_date}
                                            disabled={initialData.annex_status == 0 ? true : false}
                                            options={{
                                                altInput: true,
                                                altFormat: "d/m/Y",
                                                dateFormat: "Y-m-d H:i:S",
                                                allowInput: true,
                                                onChange: function (selectedDates, dateStr, instance) {
                                                    handleChange("annex_expiry_date", dateStr);
                                                },
                                                onClose: function (selectedDates, dateStr, instance) {
                                                    handleChange("annex_expiry_date", dateStr);
                                                },
                                                locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                                            }}
                                        />
                                    </div>
                                </Col>


                                <Col xl={3} lg={3} md={3} sm={12}>
                                    <div className="mb-3">
                                        <DatePicker
                                            id="annex_due_date"
                                            name="annex_due_date"
                                            label={t("Due date")}
                                            required={true}
                                            value={initialData.annex_due_date}
                                            disabled={initialData.annex_status == 0 ? true : false}
                                            options={{
                                                altInput: true,
                                                altFormat: "d/m/Y",
                                                dateFormat: "Y-m-d H:i:S",
                                                allowInput: true,
                                                onChange: function (selectedDates, dateStr, instance) {
                                                    handleChange("annex_due_date", dateStr);
                                                },
                                                onClose: function (selectedDates, dateStr, instance) {
                                                    handleChange("annex_due_date", dateStr);
                                                },
                                                locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                                            }}
                                        />
                                    </div>
                                </Col>


                                <Col xl={3} lg={3} md={3} sm={12}>
                                    <div className="mb-3">
                                        <DatePicker
                                            id="annex_start_date"
                                            name="annex_start_date"
                                            label={t("Start date")}
                                            required={true}
                                            value={initialData.annex_start_date}
                                            disabled={initialData.annex_status == 0 ? true : false}
                                            options={{
                                                altInput: true,
                                                altFormat: "d/m/Y",
                                                dateFormat: "Y-m-d H:i:S",
                                                allowInput: true,
                                                onChange: function (selectedDates, dateStr, instance) {
                                                    handleChange("annex_start_date", dateStr);
                                                },
                                                onClose: function (selectedDates, dateStr, instance) {
                                                    handleChange("annex_start_date", dateStr);
                                                },
                                                locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                                            }}
                                        />
                                    </div>
                                </Col>


                                <Col xl={3} lg={3} md={3} sm={12}>
                                    <div className="mb-3">
                                        <DatePicker
                                            id="annex_end_date"
                                            name="annex_end_date"
                                            label={t("End date")}
                                            required={true}
                                            value={initialData.annex_end_date}
                                            disabled={initialData.annex_status == 0 ? true : false}
                                            options={{
                                                altInput: true,
                                                altFormat: "d/m/Y",
                                                dateFormat: "Y-m-d H:i:S",
                                                allowInput: true,
                                                onChange: function (selectedDates, dateStr, instance) {
                                                    handleChange("annex_end_date", dateStr);
                                                },
                                                onClose: function (selectedDates, dateStr, instance) {
                                                    handleChange("annex_end_date", dateStr);
                                                },
                                                locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                                            }}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>

                        <Col xl={3} lg={3} md={3} sm={12}>
                            <Row>
                                <Col xl={9} lg={9} md={9} sm={11}>
                                    <Label className="form-label">{t("Upload file")}</Label>
                                    <FileUpload
                                        title={t("Upload document")}
                                        onClickHandler={() => handleUploadDocument("Annex")}
                                        onDownload={() => handleSingleDownload(5)}
                                        fileData={initialData.annex_status !== 0 && annexFile}
                                    //disabled={initialData.annex_status ? true : false}
                                    />
                                </Col>
                                {userProfile.IS_INTERNAL && (
                                    <Col xl={1} lg={1} md={1} sm={6}>
                                        <div className="mb-3">
                                            <Label className="form-label">
                                                <br />
                                            </Label>
                                            <div className="input-group">
                                                {isEdit === true ? (
                                                    <Button
                                                        color="success"
                                                        type="button"
                                                        className={`btn btn-sm btn-success`}
                                                        disabled={addRowBtnDisabled}
                                                        onClick={() => updateRow()}
                                                    >
                                                        <span className="align-middle material-symbols-outlined fw-light fs-4">
                                                            done
                                                        </span>
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        type="button"
                                                        className={`btn btn-sm ${addRowBtnDisabled ? "btn-primary" : "btn-info"}`}
                                                        disabled={addRowBtnDisabled}
                                                        onClick={() => addRow()}
                                                    >
                                                        <span className="align-middle material-symbols-outlined fw-light fs-4">
                                                            add
                                                        </span>
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </>
                }
            </Row>

            {dataList && dataList.length > 0 && (
                <DataTableBasic
                    columns={columns}
                    products={dataList}
                    datatableOptions={datatableOptions}
                    onTableChange={onTableChange}
                />
            )}
        </React.Fragment>
    );
};

export default Annexture46;
