import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
  Alert,
} from "reactstrap";

// Actions
import { UserStatusAction } from "../../../store/actions";

// Import Breadcrumb
import Breadcrumbs from "../../../components/Layout/Breadcrumb";

// Import DeleteModal
import UserStatusModal from "./UserStatusForm";

// Import DeleteModal
import DeleteModal from "../../../components/Common/DeleteModal";

// Import DataTable
import DataTable from "../../../components/Common/DataTable";

import { getDataTableQuery } from "../../../helpers/functions_helper";

const breadcrumbItems = [
  {
    url: "/dashboard",
    label: "Dashboard",
    active: false,
  },
  {
    url: "#",
    label: "User status",
    active: true,
  },
];

const AllUserStatus = (props: any) => {
  document.title = "User status | " + process.env.REACT_APP_NAME;
  
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();

  let {
    datatableData,
    datatableErrorMsg,
    createUserStatusSuccessMsg,
    createUserStatusErrorMsg,
    createUserStatusErrorData,
    updateUserStatusSuccessMsg,
    updateUserStatusErrorMsg,
    updateUserStatusErrorData,
    deleteUserStatusSuccessMsg,
    deleteUserStatusErrorMsg,
    deleteUserStatusErrorData,
    getUserStatusErrorMsg,
    loading,
  } = useSelector((state: any) => ({
    datatableData: state.UserStatus.GET_USER_STATUS_LIST_SUCCESS,
    datatableErrorMsg: state.UserStatus.GET_USER_STATUS_LIST_ERROR.message,

    createUserStatusSuccessMsg:
      state.UserStatus.CREATE_USER_STATUS_SUCCESS.message,
    createUserStatusErrorMsg:
      state.UserStatus.CREATE_USER_STATUS_ERROR.message,
    createUserStatusErrorData:
      state.UserStatus.CREATE_USER_STATUS_ERROR.data,

    updateUserStatusSuccessMsg:
      state.UserStatus.UPDATE_USER_STATUS_SUCCESS.message,
    updateUserStatusErrorMsg:
      state.UserStatus.UPDATE_USER_STATUS_ERROR.message,
    updateUserStatusErrorData:
      state.UserStatus.UPDATE_USER_STATUS_ERROR.data,

    deleteUserStatusSuccessMsg:
      state.UserStatus.DELETE_USER_STATUS_SUCCESS.message,
    deleteUserStatusErrorMsg:
      state.UserStatus.DELETE_USER_STATUS_ERROR.message,
    deleteUserStatusErrorData:
      state.UserStatus.DELETE_USER_STATUS_ERROR.data,

    getUserStatusErrorMsg: state.UserStatus.GET_USER_STATUS_ERROR.message,

    loading: state.UserStatus.loading,
  }));

  // DataTable ----------------------------------------------
  function renderHeaderHTML(column: any, colIndex: any) {
    return <span dangerouslySetInnerHTML={{ __html: column.text }} />;
  }
  function renderRowHTML(rowContent: any, row: any) {
    return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
  }

  const columns = [
    {
      dataField: "DT_RowIndex",
      text: "Sr. No.",
      sort: false,
      search: false,
    },
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: true,
    //   search: true,
    // },
    {
      dataField: "user_status_value_html",
      text: "User status",
      sort: true,
      search: true,
      formatter: renderRowHTML,
    },    
    {
      dataField: "action",
      text: "Action",
      sort: false,
      search: false,
      formatter: (rowContent: any, row: any): any => {
        return (
          <div className="d-flex gap-2 text-end">
            <Link 
              className="btn btn-pill btn-outline-primary btn-sm" 
              to="#"
              onClick={() => onClickEdit(row)}
            >
              <i className="fa-regular fa-pen-to-square"></i> Edit
            </Link>
            <Link
              className="btn btn-pill btn-outline-danger btn-sm"
              to="#"
              onClick={() => onClickDelete(row)}
            >
              <i className="fa-regular fa-trash-can"></i> Delete
            </Link>
          </div>
        );
      },
    },
  ];

  const [loadingListData, setLoadingListData] = useState(true);
  const [datatableOptions, setDatatableOptions] = useState({
    draw: 1,
    page: 1,
    recordsTotal: 0,
    recordsFiltered: 0,
    sizePerPage: 20,
    searchText: "",
    sortField: "id",
    sortOrder: "desc",
  });

  useEffect(() => {
    if (loadingListData === true) {
      dispatch(
        UserStatusAction.getUserStatusList(
          getDataTableQuery(datatableOptions, columns),
          props.history
        )
      );
    }
  }, [loadingListData]);

  const [userStatusListData, setUserStatusListData] = useState([]);
  useEffect(() => {
    if (datatableData) {
      setUserStatusListData(datatableData.data);

      const updatedDatatableOptions = {
        ...datatableOptions,
        recordsTotal: datatableData.recordsTotal,
        recordsFiltered: datatableData.recordsFiltered,
      };
      setDatatableOptions(updatedDatatableOptions);

      setLoadingListData(false);
      setLoader(false);
    }
  }, [datatableData]);

  const onTableChange = (type: any, newState: any) => {
    let draw = datatableOptions.draw + 1;
    let page = 1;
    if (type == "search") {
      page = 1;
    } else {
      page = newState.page;
    }
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: draw,
      page: page,
      sizePerPage: newState.sizePerPage,
      searchText: newState.searchText,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
    };
    setDatatableOptions(updatedDatatableOptions);
    setLoadingListData(true);
  };

  const clearFilter = () => {
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: 1,
      page: 1,
      sizePerPage: 20,
      searchText: "",
      sortField: "id",
      sortOrder: "desc",
    };
    setDatatableOptions(updatedDatatableOptions);
    setLoadingListData(true);
  };
  // Datatable -------------------------------------------------

  // Delete Form --------------------------------------------------------
  const [deleteUserStatus, setDeleteUserStatus] = useState({ 
    id: "", 
    user_status_id: "" 
  });
  const [recordName, setRecordName] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (row: any) => {
    const deleteUserStatusData = {
      ...deleteUserStatus,
      id: row.id,
      user_status_id: row.user_status_id,
    };
    setDeleteUserStatus(deleteUserStatusData);
    setRecordName(row.user_status_value);
    setDeleteModal(true);
  };
  
  const handleDeleteUserStatus = () => {
    dispatch(UserStatusAction.deleteUserStatus(deleteUserStatus, props.history));
  };
  // Delete Form --------------------------------------------------------

  // Master Form -------------------------------------------------------- 
  const [userStatusData, setUserStatusData] = useState({}); 

  const [masterFormModal, setMasterFormModal] = useState(false);
  const [masterFormModalTitle, setMasterFormModalTitle] = useState("Create user status");

  const onClickModal = () => {
    setUserStatusData('');
    setMasterFormModalTitle("Create user status");
    setMasterFormModal(true);
  };
  
  const onClickEdit = (row: any) => {
    setUserStatusData(row);
    setMasterFormModalTitle("Update user status");
    setMasterFormModal(true);
  }

  const handleSubmitUserStatus = (values: any) => {
    if(values.id && values.user_status_id){
      dispatch(UserStatusAction.updateUserStatus(values, props.history));
    }else{
      dispatch(UserStatusAction.createUserStatus(values, props.history));
    }
  };  
  // Master Form --------------------------------------------------------

  // Messages -------------------------------------------------
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorData, setErrorData] = useState({data: {}});

  useEffect(() => {
    if (createUserStatusSuccessMsg) {
      setSuccessMsg(createUserStatusSuccessMsg);
      setLoadingListData(true);
      window.scrollTo(0, 0);
      setErrorMsg("");
      setErrorData({data: {}});
      dispatch(UserStatusAction.createUserStatusSuccess(""));
      setMasterFormModal(false);
    }
  }, [createUserStatusSuccessMsg]);

  useEffect(() => {
    if (createUserStatusErrorMsg) {
      setErrorMsg(createUserStatusErrorMsg);
      setErrorData({data: createUserStatusErrorData});
      window.scrollTo(0, 0);
      setSuccessMsg("");
      dispatch(UserStatusAction.createUserStatusError(""));
    }
  }, [createUserStatusErrorMsg]);

  useEffect(() => {
    if (updateUserStatusSuccessMsg) {
      setSuccessMsg(updateUserStatusSuccessMsg);
      setLoadingListData(true);
      window.scrollTo(0, 0);
      setErrorMsg("");
      setErrorData({data: {}});
      dispatch(UserStatusAction.updateUserStatusSuccess(""));
      setMasterFormModal(false);
    }
  }, [updateUserStatusSuccessMsg]);

  useEffect(() => {
    if (updateUserStatusErrorMsg) {
      setErrorMsg(updateUserStatusErrorMsg);
      setErrorData({data: updateUserStatusErrorData});
      window.scrollTo(0, 0);
      setSuccessMsg("");
      dispatch(UserStatusAction.updateUserStatusError(""));
    }
  }, [updateUserStatusErrorMsg]);

  useEffect(() => {
    if (deleteUserStatusSuccessMsg) {
      setSuccessMsg(deleteUserStatusSuccessMsg);
      setLoadingListData(true);
      window.scrollTo(0, 0);
      setErrorMsg("");
      setErrorData({data: {}});
      dispatch(UserStatusAction.deleteUserStatusSuccess(""));
      setDeleteModal(false);
    }
  }, [deleteUserStatusSuccessMsg]);

  useEffect(() => {
    if (deleteUserStatusErrorMsg) {
      setErrorMsg(deleteUserStatusErrorMsg);
      setErrorData({data: deleteUserStatusErrorData});
      window.scrollTo(0, 0);
      setSuccessMsg("");
      dispatch(UserStatusAction.deleteUserStatusError(""));
      setDeleteModal(false);
    }
  }, [deleteUserStatusErrorMsg]);

  useEffect(() => {
    if (getUserStatusErrorMsg) {
      setErrorMsg(getUserStatusErrorMsg);
      window.scrollTo(0, 0);
      setSuccessMsg("");
      dispatch(UserStatusAction.getUserStatusError(""));
    }
  }, [getUserStatusErrorMsg]);
  // Messages -------------------------------------------------

  return (
    <React.Fragment>

      {deleteUserStatus && (
        <DeleteModal
          recordName={recordName}
          modalTitle="user status"
          show={deleteModal}
          onDeleteClick={handleDeleteUserStatus}
          onCloseClick={() => setDeleteModal(false)}
          loading={loading}
        />
      )}

      {masterFormModal && (
        <UserStatusModal
          modalTitle={masterFormModalTitle}
          show={masterFormModal}
          loading={loading}
          userStatusData={userStatusData}
          onSubmitClick={handleSubmitUserStatus}
          onCloseClick={() => setMasterFormModal(false)}
          errorMsg={errorMsg}
          errorData={errorData}
        />
      )}

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle="User status"
            breadcrumbItems={breadcrumbItems}
          />

          <Row>
            <Col xl="12">
              {successMsg ? <Alert color="success">{successMsg}</Alert> : null}
              {errorMsg ? <Alert color="danger">{errorMsg}</Alert> : null}

              <Card>
                <CardHeader className="bg-transparent border-bottom">
                  <div className="d-sm-flex flex-wrap">
                    <CardTitle className="card-title text-uppercase">
                      User status list
                    </CardTitle>
                    <div className="ms-auto d-flex gap-2">
                      <Link
                        to="#"
                        className="btn btn-primary btn-rounded waves-effect waves-light btn-label"
                        onClick={() => onClickModal()}
                      >
                        <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">add</span> Create user status
                      </Link>
                      {/* <Button
                        color="primary"
                        className="btn-rounded waves-effect waves-light"
                      >
                        <i className="fa-solid fa-arrows-rotate"></i>
                      </Button> */}
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <DataTable
                    columns={columns}
                    products={userStatusListData}
                    datatableOptions={datatableOptions}
                    filters={[]}
                    onTableChange={onTableChange}
                    loadingListData={loadingListData}
                    clearFilter={clearFilter}
                    slug="masters"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AllUserStatus;
