import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Popover } from "react-text-selection-popover";
import { Card, Col, Container, Row } from "reactstrap";
import { Letter } from 'react-letter';
import emailService from "../../services/email.service";
import './styles.scss';
import Drawer from "../../components/Drawer";
import NewCompany from "../Masters/Company/NewCompany";
import NewUser from "../Users/NewUser";
import MailReadButton from "./components/MailReadButton";
import Attachment from "./components/Attachment";
import { useSelector, useDispatch } from "react-redux";
import Breadcrumbs from "../../components/Layout/Breadcrumb";
import ComposeMail from "./components/ComposeMailOnPage";
import ContactPerson from "./components/ContactPerson";
import EditCompany from "../Masters/Company/EditCompany";
import EditUser from "../Users/EditUser";
import { setDraftMailForTask } from "../../store/mails/actions";
import { useTranslation } from "react-i18next";


const EmailRead = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [ref, setRef] = useState<HTMLElement>();
  const [selectText, setSelectedText] = useState('');
  const dispatch = useDispatch();
  const [openCompany, setOpenCompany] = useState<boolean>(false);
  const [openUser, setOpenUser] = useState<boolean>(false);

  const [openEditCompany, setOpenEditCompany] = useState<boolean>(false);
  const [openEditUser, setOpenEditUser] = useState<boolean>(false);

  const [attachmentList, setAttachmentList] = useState<any>([]);
  const [emailData, setEmailData] = useState<any>([]);


  const [mailComposeType, setMailComposeType] = useState<string>('');
  const [isComposeMail, setIsComposeMail] = useState<boolean>(false);
  const [contactPerson, setContactPerson] = useState<any>({});
  const [isPresentData, setIsPresentData] = useState<boolean>(false);
  const toastId = React.useRef(null) as any;
  const { t } = useTranslation();
  const params = useParams();
  let navigate = useNavigate();


  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: `/email/inbox`,
      label: t(`Inbox`),
      active: false,
    },
    {
      url: `/email/inbox/read`,
      label: t(`Read`),
      active: true,
    },
  ];
  const handleCreateTask = () => {
    let xdata = {};
    if (selectText)
      xdata = {
        title: emailData.subject,
        body: selectText
      }
    else {
      let parser = new DOMParser();
      let dom_document = parser.parseFromString(emailData.body.content, "text/html");
      let body_element = dom_document.getElementsByTagName("body")[0];
      xdata = {
        title: emailData.subject,
        body: body_element.innerHTML
      }
    }
    dispatch(setDraftMailForTask(xdata));
    return navigate("/tasks/new-task");
  }

  const handleCreateNote = () => {
    console.log(selectText);
  }

  const loadAttachment = async (id: string, data: any) => {
    const list_of_attachment = await emailService.getEmailAttachment(id);
    if (list_of_attachment.status === 200) {
      setAttachmentList(list_of_attachment.data.value);
      var email = data?.body?.content;
      list_of_attachment?.data?.value?.forEach((element: any) => {
        const find_str = `cid:${element.contentId}`;
        const b64 = element.contentBytes?.toString("base64");
        var dataURL = `data:${element.contentType};base64,` + b64
        email = email.replace(new RegExp("\\b" + find_str + "\\b"), dataURL);
      });
      setEmailData((preState:any)=>({...preState,body:{'content':email,'contentType':'html'}}))
  }
}

const getEmail = async (id: string) => {
  setLoading(false)
  const data = await emailService.getEmail(id);
  if (data.status === 200) {
    setLoading(false)
    setEmailData(data?.data);
    loadAttachment(data?.data?.id, data.data);
  }
}

useEffect(() => {
  if (params.id) {
    getEmail(params?.id)
  }
}, [params?.id]);

const handleDeleteMail = async () => {
  if (params.id) {
    const res = await emailService.deleteInboxMail(params.id);
    if (res.status === 204) {
      navigate("/email/inbox/");
    }
  }
}

const handleOnMailCompose = async (type: string) => {
  setIsComposeMail(true);
  setMailComposeType(type);
  const postData = {
    type: type,
    id: emailData?.id
  };
  const res = await emailService.addNewInboxMail(postData);
}


const getContactPerson = async (email: string) => {
  const res = await emailService.getContactPerson(email);
  if (res?.data?.status === "success" && res?.data?.data.length > 0) {
    setContactPerson(res?.data?.data[0]);
    setIsPresentData(true);
  } else {
    setIsPresentData(false);
  }
}

useEffect(() => {
  if (emailData?.sender?.emailAddress?.address) {
    getContactPerson(emailData?.sender?.emailAddress?.address);
  }
}, [emailData?.sender?.emailAddress?.address])


const handleUser = (op: string) => {
  if (op === 'new')
    setOpenUser(true);
  if (op === 'edit')
    setOpenEditUser(true);
}

const handleCompany = (op: string) => {
  if (op === 'new')
    setOpenCompany(true);
  if (op === 'edit')
    setOpenEditCompany(true);
}

const onCompanySave = (data: any) => {
  setOpenCompany(false);
}

const onUserSave = (data: any) => {
  setOpenUser(false);
}

const handleDownloadAttachment = async (row: any) => {
  if (typeof row === 'object') {
    var raw = window.atob(row.contentBytes);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));      // pass your byte response to this constructor
    for (var i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    var blob = new Blob([array], { type: row.contetType });
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = row.name;
    link.click();
  }
}

const rewriteSrc = (url: any) => {
  if (url.startsWith('cid:')) {
    const image = url.split(':')[1];
    const imageAtt = attachmentList.filter((a: any) => a.contentId === image);
    if (imageAtt.length > 0) {
      return `data:${imageAtt[0].contentType.type};base64, ${imageAtt.contentBytes?.toString("base64")}`
    }
  } else {
    return url;
  }
}

return (
  <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          breadcrumbTitle={``}
          breadcrumbItems={breadcrumbItems}
        />
        {!loading &&
          <Card style={{ marginBottom: '0px' }}>
            <Row className="shadow-sm">
              <Col>
                <div className="border-bottom p-2">
                  <div className="align-items-center">
                    <div className="flex-shrink-0">
                      <MailReadButton
                        handleCreateTask={handleCreateTask}
                        setOpenUser={setOpenUser}
                        setOpenCompany={setOpenCompany}
                        handleOnMailCompose={handleOnMailCompose}
                        handleDeleteMail={handleDeleteMail}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs="9" className="p-4 border-end" style={{ overflowY: 'scroll', minHeight: '700px',maxHeight:'950px' }}>
                {
                  isComposeMail &&
                  <ComposeMail
                    data={emailData}
                    composeType={mailComposeType}
                    handleChange={() => setIsComposeMail(false)}
                  />
                }
                <div className="mb-3" >
                  <h4 className="mt-0 font-size-16 mb-4">
                    {emailData?.subject}
                  </h4>
                  <div className="d-flex flex-grow-1 mb-2">
                    <div className="">
                      <span className="material-symbols-outlined fw-light" style={{ fontSize: '36px' }}>
                        account_circle
                      </span>
                    </div>
                    <div className="flex-grow-1">
                      <div className="text-muted"><h6 className="m-0">{emailData?.from?.emailAddress?.name}</h6>
                        <p className="mb-1">{emailData?.from?.emailAddress?.address}</p>
                      </div>
                    </div>
                  </div>
                  <div className="row p-2">
                    <div className="col p-0 ">
                      {(typeof attachmentList !== undefined && attachmentList.length > 0) && <h4 className="mt-0 font-size-12 mb-2">
                        {t("Attachment list")}
                      </h4>}
                      <div className="row">
                      {attachmentList && attachmentList?.map((item: any, i: number) => <div className="col-2"><Attachment
                        link={item?.contentId}
                        size={item?.size}
                        name={item?.name}
                        type={item?.contentType}
                        deleteOption={false}
                        downloadAttachment={() => handleDownloadAttachment(item)}
                        download={true}
                        key={i}
                        handleOnDelete={() => console.log('')}
                      /></div>)}
                      </div>
                      <div
                        className="p-2"
                        ref={(el) => el != null && setRef(el)}
                      >
                        <Letter html={emailData?.body?.content}
                          rewriteExternalResources={rewriteSrc}
                          allowedSchemas={['http', 'https', 'mailto', 'cid']}
                          className="p-2"
                        />
                      </div>
                    </div>

                  </div>

                  <Popover
                    mount={ref}
                    render={
                      ({ clientRect, isCollapsed, textContent }: any) => {
                        if (clientRect == null || isCollapsed) return null
                        setSelectedText(textContent);
                        const ConStyle = {
                          left: `${clientRect.left + clientRect.width / 2}px`,
                          top: `${clientRect.top - 150}px`,
                          marginLeft: '-120px',
                          color: '#52515E',
                        }

                        return <div className="shadow-lg p-2 bg-white rounded" style={{ position: 'absolute', ...ConStyle }}>
                          {/* <button type="button" aria-haspopup="true" aria-expanded="false" className="btn btn-sm" onClick={() => handleCreateNote()}>
                              <i className="fa-regular fa-pen-to-square fs-5"></i>{" "}Create note
                            </button> */}
                          <button type="button" aria-haspopup="true" aria-expanded="false" className="btn btn-sm" onClick={() => handleCreateTask()}>
                            <i className="fa-solid fa-check fs-5"></i>{" "}{t("Create task")}
                          </button>
                        </div>
                      }
                    }
                  />

                </div>
              </Col>
              {
                <div className="col-3 pe-3 pt-1">
                  <ContactPerson
                    contactPerson={contactPerson}
                    isPresentData={isPresentData}
                    handleUser={handleUser}
                    handleCompany={handleCompany}
                  />
                </div>
              }
            </Row>

          </Card>}

      </Container>

    </div>
    <Drawer
      title={t("Company")}
      open={openCompany}
      onClose={() => setOpenCompany((state) => !state)}
      fullWidth
    >
      <NewCompany inTab={true} onSave={(e: any) => onCompanySave(e)} email={emailData?.sender?.emailAddress?.address} />
    </Drawer>
    <Drawer
      title={t("User")}
      open={openUser}
      onClose={() => setOpenUser((state) => !state)}
      fullWidth
    >
      <NewUser inTab={true} onSave={(e: any) => onUserSave(e)} email={emailData?.sender?.emailAddress?.address} first_name={emailData?.sender?.emailAddress?.name} />
    </Drawer>
    <Drawer
      title={t("Company")}
      open={openEditCompany}
      onClose={() => setOpenEditCompany((state) => !state)}
      fullWidth
    >
      <EditCompany id={contactPerson?.company_id} company_id={contactPerson.company_code} type={1} isWindow={true} />
    </Drawer>

    <Drawer
      title={t("User")}
      open={openEditUser}
      onClose={() => setOpenEditUser((state) => !state)}
      fullWidth
    >
      <EditUser id={contactPerson?.id} user_id={contactPerson?.user_id} type={1} isWindow={true} />
    </Drawer>
  </React.Fragment>
);
}


export default EmailRead;
