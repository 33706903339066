import {
  GET_TASK_STATUS,
  GET_TASK_STATUS_SUCCESS,
  GET_TASK_STATUS_ERROR,

  GET_TASK_STATUS_LIST,
  GET_TASK_STATUS_LIST_SUCCESS,
  GET_TASK_STATUS_LIST_ERROR,

  CREATE_TASK_STATUS,
  CREATE_TASK_STATUS_SUCCESS,
  CREATE_TASK_STATUS_ERROR,

  UPDATE_TASK_STATUS,
  UPDATE_TASK_STATUS_SUCCESS,
  UPDATE_TASK_STATUS_ERROR,

  DELETE_TASK_STATUS,
  DELETE_TASK_STATUS_SUCCESS,
  DELETE_TASK_STATUS_ERROR,
} from "./actionTypes";

// ---------------------------------------------GET_TASK_STATUS
export const getTaskStatus = (tasktypes: any, history: any) => {
  return {
    type: GET_TASK_STATUS,
    payload: { tasktypes, history },
  };
};

export const getTaskStatusSuccess = (data: {}) => {
  return {
    type: GET_TASK_STATUS_SUCCESS,
    payload: data,
  };
};

export const getTaskStatusError = (error: {}) => {
  return {
    type: GET_TASK_STATUS_ERROR,
    payload: error,
  };
};

// ---------------------------------------------GET_TASK_STATUS_LIST
export const getTaskStatusList = (query: any, history: any) => {
  return {
    type: GET_TASK_STATUS_LIST,
    payload: {query, history},
  };
};

export const getTaskStatusListSuccess = (data: {}) => {
  return {
    type: GET_TASK_STATUS_LIST_SUCCESS,
    payload: data,
  };
};

export const getTaskStatusListError = (error: {}) => {
  return {
    type: GET_TASK_STATUS_LIST_ERROR,
    payload: error,
  };
};

// ---------------------------------------------CREATE_TASK_STATUS
export const createTaskStatus = (tasktypes: any, history: any) => {
  return {
    type: CREATE_TASK_STATUS,
    payload: { tasktypes, history },
  };
};

export const createTaskStatusSuccess = (tasktypes: {}) => {
  return {
    type: CREATE_TASK_STATUS_SUCCESS,
    payload: tasktypes,
  };
};

export const createTaskStatusError = (error: {}) => {
  return {
    type: CREATE_TASK_STATUS_ERROR,
    payload: error,
  };
};

// ---------------------------------------------UPDATE_TASK_STATUS
export const updateTaskStatus = (tasktypes: any, history: any) => {
  return {
    type: UPDATE_TASK_STATUS,
    payload: { tasktypes, history },
  };
};

export const updateTaskStatusSuccess = (tasktypes: {}) => {
  return {
    type: UPDATE_TASK_STATUS_SUCCESS,
    payload: tasktypes,
  };
};

export const updateTaskStatusError = (error: {}) => {
  return {
    type: UPDATE_TASK_STATUS_ERROR,
    payload: error,
  };
};

// ---------------------------------------------DELETE_TASK_STATUS
export const deleteTaskStatus = (tasktypes: any, history: any) => {
  return {
    type: DELETE_TASK_STATUS,
    payload: { tasktypes, history },
  };
};

export const deleteTaskStatusSuccess = (tasktypes: {}) => {
  return {
    type: DELETE_TASK_STATUS_SUCCESS,
    payload: tasktypes,
  };
};

export const deleteTaskStatusError = (error: {}) => {
  return {
    type: DELETE_TASK_STATUS_ERROR,
    payload: error,
  };
};
