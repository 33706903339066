import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Form,
  Label,
  Input,
  Button,
  FormFeedback,
  Spinner,
  InputGroup,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap";
import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";
import labelPlugin from "flatpickr/dist/plugins/labelPlugin/labelPlugin";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
// Static Data
import { taskStatusOptionsTrans, taskPriorityTrans } from "../../common/staticdata";

// Actions
import { QuestionnaireAction } from "../../store/actions";

// Service
import commonService from "../../services/common.service";

// Import Breadcrumb
import Breadcrumbs from "../../components/Layout/Breadcrumb";

import {
  filteredOptions,
  filteredByFunction,
  getOptionById,
  getOptionValueById,
  addIndividualInCompany,
  yearsBackword,
  yearsOnward,
  filterListNotInByKey,
  filterListInByKey,
  filterFeesTypeByLanguage,
  getDependentUserOptions_New,
  getUserProfile,
  filteredByStatus,
  datePickerI18,
  handleAutoSave,
} from "../../helpers/functions_helper";

import NewTimeSheetFormModal from "../TimeSheets/NewTimeSheetForm";
import MultiComments from "../../components/Common/MultiComments";
import Dropzone from "../../components/Common/Dropzone";

import ScrollUp from "../../components/ScrollUpDown/ScrollUp";
import ScrollDown from "../../components/ScrollUpDown/ScrollDown";

import Drawer from "../../components/Drawer";
import EditCompany from "../Masters/Company/EditCompany";
import EditUser from "../Users/EditUser";
import QuestionnaireModal from "./QuestionnaireModal";
import questionnaireService from "../../services/questionnaire.service";
import tasksService from "../../services/tasks.service";
import timeSheetsService from "../../services/time.sheets.service";
import PreliminaryForm from "./PreliminaryForm";
import { setDraftMailForTask } from "../../store/mails/actions";

import { useCallbackPrompt } from '../../hooks/useCallbackPrompt'
import Alert from "../../components/Common/Alert";
import NavigationAlert from "../../components/Common/NavigationAlert";

const NewTask = (props: any) => {
  const { t } = useTranslation();
  document.title = `${t("Create")} ${t("task")} | ` + process.env.REACT_APP_NAME;

  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "/tasks/kanban-view",
      label: t("Kanban view"),
      active: false,
    },
    {
      url: "/tasks/all-tasks",
      label: t("List view"),
      active: false,
    },
    {
      url: "#",
      label: `${t("Create")} ${t("task")}`,
      active: true,
    },
  ];

  const taskToast = React.useRef(null) as any;
  const timesheetToast = React.useRef(null) as any;
  const questionnaireToast = React.useRef(null) as any;
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const userProfile = getUserProfile("all-tasks");
  const userAccess = getUserProfile("all-users");
  const companyAccess = getUserProfile("all-company");
  const timesheetProfile = getUserProfile("all-time-sheets");
  // --------------------------------------------------------------------
  const [loading, setLoading] = useState(false);
  const [insertId, setInsertId] = useState(0);
  const [insertSubId, setInsertSubId] = useState("");
  const [redirectCode, setRedirectCode] = useState("");
  const [multiCommentsStatus, setMultiCommentsStatus] = useState(false);
  const [multiUploadStatus, setMultiUploadStatus] = useState(false);
  const [timesheetStatus, setTimesheetStatus] = useState(false);

  const multiCommentsSuccess = (isSuccess: boolean) => {
    if (isSuccess === true) {
      setMultiCommentsStatus(true);
    }
  };
  const multiUploadSuccess = (isSuccess: boolean) => {
    if (isSuccess === true) {
      setMultiUploadStatus(true);
    }
  };
  // --------------------------------------------------------------------

  const [preliminaryError, setPreliminaryError] = useState<string>("");
  const [preliminaryMin, setPreliminaryMin] = useState<number>(0);
  const [preliminaryMax, setPreliminaryMax] = useState<number | string>("");
  const [openPreliminaryModal, setOpenPreliminaryModal] = useState<boolean>(false);

  const localFormData = handleAutoSave("GET");
  const [autoSave, setAutoSave] = useState(false);
  const [autoSaveAlert, setAutoSaveAlert] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(autoSave);
  const [isPreliminarySubmit, setPreliminarySubmit] = useState<boolean>(false);

  const [initialData, setInitialData] = useState({
    id: "",
    task_id: "",
    code: "",
    custome_code: "",
    title: "",
    type: "",
    purchase_order_number: "",
    is_budget: 0,
    budget_amount: 0,
    preComment: "",
    fees_amount: 0,
    status: "1",
    preparer: userProfile.USER_ID || "",
    preparer_progress: 0,
    reviewer: "",
    reviewer_progress: 0,
    file_manager: "",
    file_manager_progress: 0,
    partner: "",
    partner_progress: 0,
    is_assistant_administrative: 0,
    assistant_administrative: "",
    assistant_administrative_progress: 0,
    assignee: "",
    priority: "2",
    start_date: moment().format("YYYY-MM-DD hh:mm:ss"),
    due_date: "",
    closing_date: "",
    company: "",
    project: "",
    contact_person: "",
    responsible_person: "",
    income_year: "",
    expense: 0,
    preliminary_billing: 0,
    is_preliminary_fixed_amount: 1,
    preliminary_percentage: 0,
    preliminary_details: null,
    sensitive: 0,
    tax_return_type_id: "",
    submit_btn_code: "save_add_new",
  });

  const fee_type_initialData = {
    id: 0,
    fee_id: "",
    category: "",
    category_of_fees: "",
    description: "",
    fees_amount: 0,
    fees_code: "",
    color_code: "",
    is_resident: 0,
    is_non_resident: 0,
    is_tax_return_type: 0,
  };

  const handleRedirect = (isCancel = false) => {
    setAutoSave(false);
    handleAutoSave("CLEAR");

    if (isCancel === true) {
      return navigate("/tasks/kanban-view");
    }

    if (redirectCode === "save_add_new") {
      // return navigate("/tasks/new-task?redirect=true");
      updateInitialData();

      setInsertId(0);
      setInsertSubId("");
      setMultiCommentsStatus(false);
      setMultiUploadStatus(false);
      setTimesheetStatus(false);

      window.scrollTo(0, 0);
    } else if (redirectCode === "save_close") {
      return navigate("/tasks/kanban-view?redirect=true");
    } else if (redirectCode === "save") {
      return navigate("/tasks/task-details/" + insertId + "/" + insertSubId + "?redirect=true");
    } else {
      return navigate("/tasks/kanban-view?redirect=true");
      // return navigate("/tasks/all-tasks?redirect=true");
    }
  };

  const handleFocus = (event: any) => event.target.select();

  const handlePreliminaryBilling = () => {
    if (initialData.preliminary_billing === 1) {
      setInitialData((preState) => ({
        ...preState,
        preliminary_billing: 0,
        is_preliminary_fixed_amount: 1,
        preliminary_percentage: 0,
      }));
    } else {
      setInitialData((preState) => ({ ...preState, preliminary_billing: 1 }));
      setOpenPreliminaryModal(true);
    }
  };

  const handleBudget = () => {
    if (initialData.is_budget === 1) {
      setInitialData((preState) => ({
        ...preState,
        is_budget: 0,
        budget_amount: 0,
      }));
    } else {
      setInitialData((preState) => ({ ...preState, is_budget: 1 }));
    }
  };

  const handleChange = (fieldName: string | any, fieldValue: any) => {
    setInitialData((preState) => ({ ...preState, [fieldName]: fieldValue }));
    setAutoSave(true);
  };

  // Drawer for company and user

  const [openCompany, setOpenCompany] = useState<boolean>(false);
  const [openUser, setOpenUser] = useState<boolean>(false);
  const [company, setCompany] = useState<any>([]);
  const [selectedUser, setSelectedUser] = useState<any>([]);

  const handleCompanyClick = () => {
    if (initialData.company) {
      const company_id = getOptionValueById(
        companyOptions,
        parseInt(initialData.company),
        "company_id"
      );
      setCompany({ company: initialData.company, company_id: company_id });
      setOpenCompany(true);
    }
  };

  const handleUserClick = (type: number) => {
    let user_id = 0;
    switch (type) {
      case 0:
        user_id = getOptionValueById(preparerOption, parseInt(initialData.preparer), "user_id");
        setSelectedUser({ id: initialData.preparer, user_id: user_id });
        setOpenUser(true);
        break;

      case 1:
        user_id = getOptionValueById(reviewerOption, parseInt(initialData.reviewer), "user_id");
        setSelectedUser({ id: initialData.reviewer, user_id: user_id });
        setOpenUser(true);
        break;

      //File manager
      case 2:
        user_id = getOptionValueById(
          fileManagerOption,
          parseInt(initialData.file_manager),
          "user_id"
        );
        setSelectedUser({ id: initialData.file_manager, user_id: user_id });
        setOpenUser(true);
        break;

      case 3:
        user_id = getOptionValueById(partnerOption, parseInt(initialData.partner), "user_id");
        setSelectedUser({ id: initialData.partner, user_id: user_id });
        setOpenUser(true);
        break;

      case 4:
        user_id = getOptionValueById(
          assistantAdministrativeOption,
          parseInt(initialData.assistant_administrative),
          "user_id"
        );
        setSelectedUser({
          id: initialData.assistant_administrative,
          user_id: user_id,
        });
        setOpenUser(true);
        break;

      case 5:
        user_id = getOptionValueById(
          contactPersonOption,
          parseInt(initialData.contact_person),
          "user_id"
        );
        setSelectedUser({ id: initialData.contact_person, user_id: user_id });
        setOpenUser(true);
        break;

      case 6:
        user_id = getOptionValueById(
          responsiblePersonOption,
          parseInt(initialData.responsible_person),
          "user_id"
        );
        setSelectedUser({
          id: initialData.responsible_person,
          user_id: user_id,
        });
        setOpenUser(true);
        break;
    }
  };

  const commentAttachmentIDs = [
    {
      value: initialData.preparer,
      label: t("Preparer"),
    },
    {
      value: initialData.reviewer,
      label: t("Reviewer"),
      isHidden: initialData.sensitive ? true : false,
    },
    {
      value: initialData.file_manager,
      label: userProfile.IS_INTERNAL ? t("Responsible to sign off") : t("File manager"),
      isHidden: initialData.sensitive ? true : false,
    },
    {
      value: initialData.partner,
      label: t("Partner"),
    },
    {
      value: initialData.assistant_administrative,
      label: t("Assistant administrative"),
      isHidden: initialData.sensitive ? true : false,
    },
    {
      value: initialData.contact_person,
      label: t("Contact person"),
      isHidden: initialData.sensitive ? true : false,
    },
    {
      value: initialData.responsible_person,
      label: t("Person concerned"),
      isHidden: initialData.sensitive ? true : false,
    },
  ];

  let { getUserOptionsList, getCompanyOptionsList, getFeesOptionsList, draftMailForTask } =
    useSelector((state: any) => ({
      getUserOptionsList: state.Common.GET_USER_OPTIONS_LIST_SUCCESS_NEW,
      getCompanyOptionsList: state.Common.GET_COMPANY_OPTIONS_LIST_SUCCESS_NEW,
      getFeesOptionsList: state.Common.GET_FEES_OPTIONS_LIST_SUCCESS_NEW,
      draftMailForTask: state.Mails.draftMailForTask,
    }));

  useEffect(() => {
    if (
      redirectCode !== "" &&
      multiCommentsStatus === true &&
      multiUploadStatus === true &&
      timesheetStatus === true
    ) {
      handleRedirect();
    }
  }, [redirectCode, multiCommentsStatus, multiUploadStatus, timesheetStatus]);

  const [isSubmit, setIsSubmit] = useState(false);
  Yup.setLocale({
    mixed: {
      required: "${label} " + t("field is required."),
    },
    string: {
      min: "${label} " + t("must be at least") + " ${min} " + t("characters."),
      max: "${label} " + t("may not be greater than") + " ${max} " + t("characters."),
    },
  });
  const validationSchema = Yup.object().shape({
    custome_code: Yup.string().label(t("Task ID")).required().min(3).max(15),
    title: Yup.string().label(t("Task title")).required().min(2).max(200),
    type: Yup.string().label(t("Task type")).required(),
    fees_amount: Yup.number().label(t("Task amount")).required(),
    status: Yup.string().label(t("Task status")).required(),
    preparer: Yup.string().label(t("Preparer")).required(),
    reviewer: Yup.string().label(t("Reviewer")).required(),
    file_manager: Yup.string().label(t("Responsible to sign off")).required(),
    partner: Yup.string().label(t("Partner")).required(),
    is_assistant_administrative: Yup.number().required(),
    assistant_administrative: Yup.string().when(["is_assistant_administrative", "sensitive"], {
      is: (is_assistant_administrative: number, sensitive: number) =>
        is_assistant_administrative === 1 && sensitive !== 1,
      then: Yup.string().label(t("Assistant administrative")).required(),
    }),
    start_date: Yup.string().label(t("Start date")).required(),
    due_date: Yup.string().label(t("Internal due date")).required(),
    closing_date: Yup.string().label(t("Due date")).required(),
    priority: Yup.string().label(t("Task priority")).required(),
    company: Yup.string().label(t("Client")).required(),
    contact_person: Yup.string()
      .label(t("Contact person"))
      .when(["sensitive"], {
        is: (sensitive: number) => sensitive !== 1,
        then: Yup.string().label(t("Contact person")).required(),
      }),
  });

  useEffect(() => {
    if (initialData.preliminary_billing) {
      if (initialData.is_preliminary_fixed_amount === 0) {
        setPreliminaryMin(10);
        setPreliminaryMax(100);

        if (Number(initialData.preliminary_percentage) < 10) {
          setPreliminaryError("The preliminary billing percentage should be a minimum of 10%.");
        } else if (Number(initialData.preliminary_percentage) > 100) {
          setPreliminaryError("The preliminary billing percentage should be a maximum of 100%.");
        } else {
          setPreliminaryError("");
        }
      } else if (
        initialData.is_preliminary_fixed_amount === 1 &&
        initialData.is_budget === 1 &&
        Number(initialData.budget_amount) > 0
      ) {
        setPreliminaryMin(0);
        setPreliminaryMax(initialData.budget_amount);

        if (Number(initialData.preliminary_percentage) > Number(initialData.budget_amount)) {
          setPreliminaryError(
            "The preliminary billing fixed amount should not be more than the Budget amount."
          );
        } else {
          setPreliminaryError("");
        }
      } else {
        setPreliminaryMin(0);
        setPreliminaryMax("");

        setPreliminaryError("");
      }
    } else {
      setPreliminaryMin(0);
      setPreliminaryMax("");

      setPreliminaryError("");
    }
  }, [
    initialData.preliminary_billing,
    initialData.preliminary_percentage,
    initialData.is_preliminary_fixed_amount,
    initialData.is_budget,
    initialData.budget_amount,
  ]);

  const create = async (values: newTaskPropsType) => {
    try {
      setLoading(true);
      taskToast.current = toast(`${t("Creating")} ${t("task")}...`, {
        autoClose: false,
        isLoading: true,
      });

      const res = (await tasksService.createTask(values)) as any;
      if (res.data.status === "success") {
        setInsertId(res.data.data.id);
        setInsertSubId(res.data.data.task_id);
        setRedirectCode(res.data.data.submit_btn_code);

        toast.update(taskToast.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoading(false);
      } else {
        toast.update(taskToast.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        if (res.data.data) {
          Object.keys(res.data.data).map((key) => {
            toast(res.data.data[key][0], {
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              delay: 500,
            });
          });
        }
        setLoading(false);
      }
    } catch (err: any) {
      toast.update(taskToast.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values, { setErrors }) => {
      if (timeSpentData.time_spent) {
        create(values);
      } else {
        setIsSubmit(true);

        setTimeSpentData({
          is_new: true,
          module_id: 2, // Task
          module_main_id: 0, // ID
          module_sub_id: "", // Task ID
          main_id: 0,
          title: initialData.title,
          company: initialData.company,
          project: initialData.project,
          contact_person: initialData.contact_person,
          responsible_person: initialData.responsible_person,
          fees_type: initialData.type,
          purchase_order_number: initialData.purchase_order_number,
          fees_amount: initialData.fees_amount,
          expense: initialData.expense,
          is_sensitive: initialData.sensitive,

          start_date: "",
          start_time: "",
          end_time: "",
          time_spent: "",
        });
        setNewTimeSpentFormModal(true);
      }
    },
  });

  /////////////////////// Questionnaire  //////////////////////
  // display questionnaire create button
  const [displayQuest, setDisplayQuest] = useState(false);
  const [questionnaireData, setQuestionnaireData] = useState([]);
  const [questionnaireModal, setQuestionnaireModal] = useState(false);
  const [responsiblePerson, setResponsiblePerson] = useState("");
  const [questionnaireIDs, setQuestionnaireIDs] = useState();

  const displayButton = async (taskId: any) => {
    if (responsiblePerson) {
      const user_id =
        initialData.company == "0" ? initialData.contact_person : initialData.responsible_person;
      const res = await questionnaireService.DisplayQuestionButton(taskId, user_id);
      if (res?.data?.status === "success") {
        if (res?.data?.data[0]?.id) {
          setDisplayQuest(true);
          setQuestionnaireData(res?.data?.data);
        } else {
          setDisplayQuest(false);
        }
      } else {
        setDisplayQuest(false);
      }
    }
  };

  useEffect(() => {
    if (initialData.type) {
      let user_id = "";
      if (initialData.company == "0") {
        user_id = initialData.contact_person;
      } else {
        user_id = initialData.responsible_person;
      }
      if (user_id) {
        displayButton(initialData.type);
      }
    }
  }, [initialData.type, responsiblePerson]);

  const onClickQuestionnaire = (e: any) => {
    e && e.preventDefault();
    if (initialData.responsible_person == "" && initialData.company != "0") {
      toast(t("Please select the person concerned name"), {
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        delay: 500,
      });
    } else if (initialData.contact_person == "" && initialData.company == "0") {
      toast(t("Please select the contact person name"), {
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        delay: 500,
      });
    } else {
      setQuestionnaireModal(true);
    }
  };

  const onYesClick = async (IDs: any) => {
    setQuestionnaireIDs(IDs);
    setQuestionnaireModal(false);
  };
  const onNoClick = () => {
    setQuestionnaireModal(false);
  };

  /////////////////////// Questionnaire  //////////////////////

  useEffect(() => {
    if (!validation.isSubmitting) return;
    if (Object.keys(validation.errors).length > 0) {
      document
        .getElementById(Object.keys(validation.errors)[0])
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
      document.getElementById(Object.keys(validation.errors)[0])?.focus({ preventScroll: true });
    }
  }, [validation.isSubmitting, validation.errors]);

  function updateInitialData() {
    const updatedInitialData = {
      ...initialData,
      id: "",
      task_id: "",
      code: "",
      custome_code: "",
      title: "",
      type: "",
      purchase_order_number: "",
      is_budget: 0,
      budget_amount: 0,
      preComment: "",
      fees_amount: 0,
      status: "1",
      preparer: userProfile.USER_ID || "",
      preparer_progress: 0,
      reviewer: "",
      reviewer_progress: 0,
      file_manager: "",
      file_manager_progress: 0,
      partner: "",
      partner_progress: 0,
      is_assistant_administrative: 0,
      assistant_administrative: "",
      assistant_administrative_progress: 0,
      assignee: "",
      priority: "2",
      start_date: moment().format("YYYY-MM-DD hh:mm:ss"),
      due_date: "",
      closing_date: "",
      company: "",
      project: "",
      contact_person: "",
      responsible_person: "",
      income_year: "",
      expense: 0,
      preliminary_billing: 0,
      is_preliminary_fixed_amount: 1,
      preliminary_percentage: 0,
      sensitive: 0,
      tax_return_type_id: "",
      submit_btn_code: "save_add_new",
    };
    setInitialData(updatedInitialData);

    const updatedFeesInitialData = {
      ...fee_type_initialData,
      id: 0,
      fee_id: "",
      category: "",
      category_of_fees: "",
      description: "",
      fees_amount: 0,
      fees_code: "",
      color_code: "",
      is_resident: 0,
      is_non_resident: 0,
      is_tax_return_type: 0,
    };
    setFeesAllTypeOption(updatedFeesInitialData);

    setTypeID(0);
    setCompanyID(null);
    setLoadingCode(true);
    setIsSubmit(false);
  }

  // Generate custome code API call -------------------------------------------------
  const [loadingCode, setLoadingCode] = useState(true);
  const getCustomeCode = async (tableName: string, moduleCode: string) => {
    const response = await commonService.getCustomeCode(tableName, moduleCode);
    return response.data.data;
  };
  useEffect(() => {
    if (loadingCode === true) {
      getCustomeCode("tbl_task", "TSK").then(function (result) {
        setInitialData((preState: any) => ({
          ...preState,
          code: result,
          custome_code: result,
        }));
        setLoadingCode(false);
      });
    }
  }, [loadingCode]);

  // Select options list API call -------------------------------------------------
  const [query, setQuery] = useState("");
  const getSelectOptions = async (tableName: string, fieldName: string) => {
    const response = await commonService.getSelectOptionsList(
      tableName,
      fieldName,
      query ? query : ""
    );
    return response.data.data;
  };

  const getDependentSelectOptions = async (
    tableName: string,
    fieldName: string,
    whereField: string,
    whereID: number
  ) => {
    const response = await commonService.getDependentSelectOptionsList(
      tableName,
      fieldName,
      whereField,
      whereID,
      query ? query : ""
    );
    return response.data.data;
  };

  /* Fees Type Option */
  const [isPreliminaryType, setPreliminaryType] = useState<boolean>(false);
  const [isTaxReturnType, setTaxReturnType] = useState<boolean>(false);
  const [isShowIncomeYear, setIsShowIncomeYear] = useState<boolean>(false);
  const [incomeYearOption, setIncomeYearOption] = useState<any>([]);
  const [taxReturnTypeOption, setTaxReturnTypeOption] = useState<any>([]);
  const [isAssistantAdministrative, setIsAssistantAdministrative] = useState<boolean>(false);
  const [typeOption, setTypeOption] = useState([]);
  const [loadingTypeOption, setloadingTypeOption] = useState(true);
  const [typeID, setTypeID] = useState<number>(0);
  useEffect(() => {
    if (getFeesOptionsList.data) {
      let feesOptionsList = filterFeesTypeByLanguage(getFeesOptionsList.data);

      if (initialData.sensitive == 1) {
        const sensitive_fee_type_list = filterListInByKey(feesOptionsList, "is_sensitive", 1) as
          | feesTypeProps
          | any;
        sensitive_fee_type_list.push({ value: "", label: t("-- Remove --") });
        setTypeOption(sensitive_fee_type_list);
      } else {
        const fee_type_list: any = filterListInByKey(feesOptionsList, "is_sensitive", 0);
        fee_type_list.push({ value: "", label: t("-- Remove --") });
        setTypeOption(fee_type_list);
      }
      //setTypeOption(feesOptionsList);
      setloadingTypeOption(false);
    }
  }, [getFeesOptionsList, initialData.sensitive, localStorage.getItem("I18N_LANGUAGE")]);
  /* End  Fees Type Option */

  /* Fees Type & Task Type Option */
  const [feesAllTypeOption, setFeesAllTypeOption] = useState<feesTypeProps>(fee_type_initialData);
  useEffect(() => {
    setFeesAllTypeOption(fee_type_initialData);
    if (typeID > 0) {
      if (getFeesOptionsList.data) {
        let feesOptionsList = filterFeesTypeByLanguage(getFeesOptionsList.data);

        const item = getOptionById(feesOptionsList, typeID) as feesTypeProps | any;
        setFeesAllTypeOption(item);
        initialData.fees_amount = item.fees_amount;
        // handleChange("fees_amount", item.fees_amount);

        setInitialData((prevData: any) => ({
          ...prevData,
          fees_amount: item.fees_amount,
        }));

        if (item.is_preliminary_billing) {
          setPreliminaryType(true);
        } else {
          setPreliminaryType(false);
        }

        if (item.is_tax_return_type) {
          setTaxReturnType(true);
          //handleChange("tax_return_type_id", "1");

          setInitialData((prevData: any) => ({
            ...prevData,
            tax_return_type_id: "1",
          }));

        } else {
          setTaxReturnType(false);
          // handleChange("tax_return_type_id", "");

          setInitialData((prevData: any) => ({
            ...prevData,
            tax_return_type_id: "",
          }));
        }

        // if (item?.is_sensitive == 1) {
        //   handleChange("sensitive", 1);
        //   // setSensitiveFromFeeMaster(true);
        // } else {
        //   handleChange("sensitive", 0);
        //  // setSensitiveFromFeeMaster(false);
        // }

        if (item.is_income_year || item.is_income_year_onward) {
          const curYear = new Date().getFullYear();
          if (item.is_income_year) {
            setIncomeYearOption(yearsBackword);

            initialData.income_year = String(curYear - 1);
            // handleChange("income_year", String(curYear - 1));

            setInitialData((prevData: any) => ({
              ...prevData,
              income_year: String(curYear - 1),
            }));

          } else if (item.is_income_year_onward) {
            setIncomeYearOption(yearsOnward);

            initialData.income_year = String(curYear);
            // handleChange("income_year", String(curYear));

            setInitialData((prevData: any) => ({
              ...prevData,
              income_year: String(curYear),
            }));
          }

          setIsShowIncomeYear(true);
        } else {
          // handleChange("income_year", "");

          setInitialData((prevData: any) => ({
            ...prevData,
            income_year: "",
          }));
          setIsShowIncomeYear(false);
        }

        if (item.is_assistant_administrative) {
          // handleChange("is_assistant_administrative", 1);

          setInitialData((prevData: any) => ({
            ...prevData,
            is_assistant_administrative: 1,
          }));

          setIsAssistantAdministrative(true);

          if (assistantAdministrativeOption[0] && assistantAdministrativeOption[0].value) {
            // handleChange("assistant_administrative", assistantAdministrativeOption[0].value);

            setInitialData((prevData: any) => ({
              ...prevData,
              assistant_administrative: assistantAdministrativeOption[0].value,
            }));
          } else {
            // handleChange("assistant_administrative", "");

            setInitialData((prevData: any) => ({
              ...prevData,
              assistant_administrative: "",
            }));
          }
        } else {
          // handleChange("is_assistant_administrative", 0);

          setInitialData((prevData: any) => ({
            ...prevData,
            is_assistant_administrative: 0,
          }));

          setIsAssistantAdministrative(false);
        }
      }
    }
  }, [typeID, getFeesOptionsList, localStorage.getItem("I18N_LANGUAGE")]);
  /* End  Fees Type Option */

  /* Task Status Option */
  const [taskStatusOption, settaskStatusOption] = useState([]);
  // const [loadingStatusOption, setloadingStatusOption] = useState(true);
  const [disabledStatusOption, setDisabledStatusOption] = useState(false);
  useEffect(() => {
    let items = filteredOptions(taskStatusOptionsTrans(t) as any, [1, 2, 3]) as any;
    settaskStatusOption(items);
  }, [localStorage.getItem("I18N_LANGUAGE")]);
  /* End  Task Status Option */

  const [taskPriority, setTaskPriority] = useState([]);
  useEffect(() => {
    setTaskPriority(taskPriorityTrans(t) as any);
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  /* Project Option */
  const [projectOption, setProjectOption] = useState([]);
  /* Reviewer Option */
  const [reviewerOption, setReviewerOption] = useState([]);
  /* FileManager Option */
  const [fileManagerOption, setFileManagerOption] = useState([]);
  /*Partner Option */
  const [partnerOption, setPartnerOption] = useState([]);
  /*Assistant Administrative Option */
  const [assistantAdministrativeOption, setAssistantAdministrativeOption] = useState<any>([]);

  /* Filter Value */
  const userListUpdate = (data: any) => {
    setIsPreparerValueSelected(true);

    // when sensitive option select
    if (initialData.sensitive == 1) {
      let user_partner_list = filteredByFunction(data, [6]) as any;

      setPreparerOption(user_partner_list);
      setReviewerOption(user_partner_list);
      setFileManagerOption(user_partner_list);
      setPartnerOption(user_partner_list);
      initialData.assistant_administrative = "";
      setIsPreparerValueSelected(false);

      let user_partner_list_dis_pre = filteredByFunction(data, [6]) as any;
      user_partner_list_dis_pre.map((item: any, index: number) => {
        if (item.value === initialData.preparer) {
          user_partner_list_dis_pre[index] = {
            label: item.label,
            value: item.value,
            isDisabled: true,
          };
        }
      });

      setReviewerOption(user_partner_list_dis_pre);
      if (initialData.preparer === initialData.reviewer) {
        initialData.reviewer = "";
      }

      setPartnerOption(user_partner_list_dis_pre);
      if (initialData.preparer === initialData.partner) {
        initialData.partner = "";
      }

      initialData.preparer = userProfile.USER_ID;
      return;
    }

    const userlist_without_assi = filterListNotInByKey(data, "function", 1) as any;
    setPreparerOption(userlist_without_assi);
    setReviewerOption(userlist_without_assi);
    setFileManagerOption(userlist_without_assi);

    let user_partner_list = filteredByFunction(userlist_without_assi, [6]) as any; // Partners
    setPartnerOption(user_partner_list);

    let userlist_assi = filteredByFunction(data, [1]) as any; // Assistant
    setAssistantAdministrativeOption(userlist_assi);

    if (initialData.preparer) {
      const userlist_without_assi_k = filterListNotInByKey(data, "function", 1) as any;
      userlist_without_assi_k.map((item: any, index: number) => {
        if (item.value === initialData.preparer) {
          userlist_without_assi_k[index] = {
            label: item.label,
            value: item.value,
            isDisabled: true,
          };
        }
      });

      setReviewerOption(userlist_without_assi_k);
      if (initialData.preparer === initialData.reviewer) {
        initialData.reviewer = "";
      }

      let items1 = filteredByFunction(userlist_without_assi_k, [6]) as any; // Partners
      setPartnerOption(items1);
      if (initialData.preparer === initialData.partner) {
        initialData.partner = "";
      }
    }

    setIsPreparerValueSelected(false);
  };

  /*End Filter Value */

  /* ROLE Preparer, Reviewer, FileManager, Partner, Assistant Administrative Option */
  const [preparerOption, setPreparerOption] = useState([]);
  const [loadingPreparer, setloadingPreparer] = useState(true);
  const [isPreparerValueSelected, setIsPreparerValueSelected] = useState<boolean>(true);
  useEffect(() => {
    if (getUserOptionsList.data) {
      userListUpdate(getUserOptionsList.data);
      setloadingPreparer(false);
    }
  }, [getUserOptionsList]);

  useEffect(() => {
    if (initialData.preparer !== "" && preparerOption.length !== 0) {
      if (getUserOptionsList.data) {
        userListUpdate(getUserOptionsList.data);
        setloadingPreparer(false);
      }

      if (userProfile.USER_ID !== parseInt(initialData.preparer)) {
        setInitialData((preState) => ({
          ...preState,
          status: "1",
          preparer_progress: 0,
        }));
        setDisabledStatusOption(true);
      } else {
        setDisabledStatusOption(false);
      }
    }
  }, [initialData.preparer]);
  /* End ROLE Preparer, Reviewer, FileManager, Partner, Assistant Administrative Option */

  /***
   * Sensitive Task
   */
  useEffect(() => {
    if (getUserOptionsList.data) {
      userListUpdate(getUserOptionsList.data);
      if (initialData.sensitive === 1) {
        setInitialData((preState) => ({
          ...preState,
          reviewer: "",
          file_manager: "",
          contact_person: "",
          preparer: userProfile.USER_ID,
          type: "",
          assistant_administrative: "",
          is_assistant_administrative: 0,
        }));
        setFeesAllTypeOption(fee_type_initialData);
        //initialData.preparer = userProfile.USER_ID;
      } else {
        setFeesAllTypeOption(fee_type_initialData);
        setInitialData((preState) => ({
          ...preState,
          reviewer: "",
          type: "",
          file_manager: "",
          is_assistant_administrative: isAssistantAdministrative ? 1 : 0,
        }));
      }
      setloadingPreparer(false);
    }
  }, [initialData.sensitive]);

  /* Company Master and User */
  const [loadingCompanyOptions, setLoadingCompanyOptions] = useState(true);
  const [loadingCompanyUserOption, setloadingCompanyUserOption] = useState(true);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyID, setCompanyID] = useState<any>(null);
  const [companyLanguage, setCompanyLanguage] = useState<any>(null);
  useEffect(() => {
    if (getCompanyOptionsList.data) {
      const item = addIndividualInCompany(getCompanyOptionsList.data) as any;
      setCompanyOptions(item);
      setLoadingCompanyOptions(false);
      setloadingCompanyUserOption(true);
    }
  }, [getCompanyOptionsList]);

  const [responsiblePersonOption, setResponsiblePersonOption] = useState([]);
  const [contactPersonOption, setContactPersonOption] = useState([]);

  useEffect(() => {
    setProjectOption([]);
    setResponsiblePersonOption([]);
    setContactPersonOption([]);
    setloadingCompanyUserOption(true);

    if (companyOptions.length > 0 && companyID) {
      const companyData = companyOptions.find((option: any) => option.value == companyID) as any;
      if (companyData) {
        setCompanyLanguage(
          companyData?.language === 1
            ? "EN"
            : companyData?.language === 2
              ? "FR"
              : companyData?.language === 3
                ? "NL"
                : null
        );
      }

      getDependentSelectOptions("tbl_projects", "name", "company_id", companyID).then(function (
        options
      ) {
        initialData.project = "";
        setProjectOption(options);
      });

      // if (companyOptions.length > 0) {
      //   const item = getOptionById(companyOptions, companyID) as any;
      //   setInitialData({
      //     ...initialData,
      //     // file_manager: initialData.preparer === item.file_manager ? "" : item.file_manager,
      //     file_manager: item.file_manager,
      //     partner:
      //       initialData.preparer === item.signing_partner
      //         ? ""
      //         : item.signing_partner,
      //   });
      // }

      getDependentUserOptions_New("tbl_master_users", "first_name", "company", companyID).then(
        function (options) {
          if (feesAllTypeOption?.is_resident === 1) {
            let items = filteredByStatus(options, 1) as any;
            setResponsiblePersonOption(items);
          } else if (feesAllTypeOption?.is_non_resident === 1) {
            let items = filteredByStatus(options, 2) as any;
            setResponsiblePersonOption(items);
          } else {
            setResponsiblePersonOption(options);
          }

          // Contact person ------------------------------------------
          if (parseInt(companyID) === 0) {
            setInitialData((preState) => ({
              ...preState,
              file_manager: "",
              partner: "",
              contact_person: "",
              responsible_person: "",
            }));
            setContactPersonOption(options);
          } else {
            const item = getOptionById(companyOptions, companyID) as any;

            let contactFilterArray = Array();
            contactFilterArray.push(parseInt(item?.contact_person));
            contactFilterArray.push(parseInt(item?.invoice_contact_person));
            contactFilterArray.push(parseInt(item?.hr_contact_person));

            let contact_persons = filteredOptions(options as any, contactFilterArray) as any;
            if (initialData.sensitive === 1) {
              setInitialData((preState) => ({
                ...preState,
                file_manager: "",
                partner:
                  initialData.preparer === item.signing_partner
                    ? ""
                    : item.signing_partner
                      ? item.signing_partner
                      : "",
                contact_person: "", //item.hr_contact_person == 0 ? "" : item.hr_contact_person,
                responsible_person: "",
              }));
            } else {
              setInitialData((preState) => ({
                ...preState,
                preparer: item.preparer ? item.preparer : userProfile.USER_ID,
                reviewer: item.reviewer ? item.reviewer : "",
                file_manager: item.file_manager ? item.file_manager : "",
                partner:
                  initialData.preparer === item.signing_partner
                    ? ""
                    : item.signing_partner
                      ? item.signing_partner
                      : "",
                contact_person:
                  item.hr_contact_person == 0
                    ? ""
                    : item.hr_contact_person === null
                      ? ""
                      : item.hr_contact_person,
                responsible_person: "",
              }));
            }

            contact_persons.push({ value: "", label: t("-- Remove --") });
            setContactPersonOption(contact_persons);
          }
          // Contact person ------------------------------------------

          setloadingCompanyUserOption(false);
        }
      );
    }
  }, [
    companyOptions,
    companyID,
    feesAllTypeOption?.is_resident,
    feesAllTypeOption?.is_non_resident,
  ]);

  const [contactPersonLanguage, setContactPersonLanguage] = useState<any>(null);
  useEffect(() => {
    if (parseInt(companyID) === 0 && initialData.contact_person) {
      const contactPersonData = contactPersonOption.find(
        (option: any) => option.value == initialData.contact_person
      ) as any;
      if (contactPersonData) {
        setContactPersonLanguage(
          contactPersonData?.language === 1
            ? "EN"
            : contactPersonData?.language === 2
              ? "FR"
              : contactPersonData?.language === 3
                ? "NL"
                : null
        );
      }

      const item = getOptionById(contactPersonOption, parseInt(initialData.contact_person)) as any;

      setInitialData((preState) => ({
        ...preState,
        preparer: item?.preparer ? item?.preparer : userProfile.USER_ID,
        reviewer: item?.reviewer ? item?.reviewer : "",
        file_manager: item?.file_manager ? item?.file_manager : "",
        partner:
          initialData?.preparer === item?.signing_partner
            ? ""
            : item?.signing_partner
              ? item?.signing_partner
              : "",
      }));
    } else {
      setContactPersonLanguage(null);
    }
  }, [contactPersonOption, companyID, initialData.contact_person]);

  /* End Company Master and User */

  useEffect(() => {
    getSelectOptions("tbl_master_tax_return_type", "tax_return_type_value").then(function (
      options
    ) {
      setTaxReturnTypeOption(options);
    });
  }, []);

  // Time Spent Model ----------------------------------------------------
  const [timeSpentData, setTimeSpentData] = useState<any>({});
  const [newTimeSpentFormModal, setNewTimeSpentFormModal] = useState(false);
  const onClickTimeSpent = () => {
    setTimeSpentData({
      is_new: true,
      module_id: 2, // Task
      module_main_id: 0,
      module_sub_id: "",
      main_id: 0,
      title: initialData.title,
      company: initialData.company,
      project: initialData.project,
      contact_person: initialData.contact_person,
      responsible_person: initialData.responsible_person,
      fees_type: initialData.type,
      purchase_order_number: initialData.purchase_order_number,
      fees_amount: initialData.fees_amount,
      expense: initialData.expense,
      is_sensitive: initialData.sensitive,

      start_date: "",
      start_time: "",
      end_time: "",
      time_spent: "",
    });
    setNewTimeSpentFormModal(true);
  };

  const handleSubmitTimeSpent = (values: any) => {
    const updatedTimeSpentData = {
      ...timeSpentData,
      title: values.title,
      start_date: values.start_date,
      start_time: values.start_time,
      end_time: values.end_time,
      time_spent: values.time_spent,
    };
    setTimeSpentData(updatedTimeSpentData);
    setNewTimeSpentFormModal(false);

    if (isSubmit === true) {
      validation.handleSubmit();
    }
  };

  useEffect(() => {
    if (insertId > 0 && insertSubId !== "") {
      const updatedTimeSpentData = {
        ...timeSpentData,
        module_main_id: insertId,
        module_sub_id: insertSubId,
        main_id: insertId,
      };
      setTimeSpentData(updatedTimeSpentData);
    }
  }, [insertId, insertSubId]);

  const createTimeSheet = async (values: any) => {
    try {
      timesheetToast.current = toast(`${t("Creating")} ${t("timesheet")}...`, {
        autoClose: false,
        isLoading: true,
      });

      const updatedTimeSpentData = {
        ...timeSpentData,
        time_spent: "",
      };
      setTimeSpentData(updatedTimeSpentData);

      const res = (await timeSheetsService.createTimeSheetForm(values)) as any;
      if (res.data.status === "success") {
        setTimesheetStatus(true);

        toast.update(timesheetToast.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoading(false);
      } else {
        toast.update(timesheetToast.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        if (res.data.data) {
          Object.keys(res.data.data).map((key) => {
            toast(res.data.data[key][0], {
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              delay: 500,
            });
          });
        }
        setLoading(false);
      }
    } catch (err: any) {
      toast.update(timesheetToast.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (timeSpentData.time_spent && timeSpentData.module_main_id > 0) {
      createTimeSheet(timeSpentData);
    }
  }, [timeSpentData]);
  // Time Spent Model ----------------------------------------------------

  // get email data -------------------------------------------------
  const getTaskDataFromEmail = () => {
    if (draftMailForTask) {
      const data = draftMailForTask;
      if (data) {
        const title = data.title.substring(0, 200);
        setInitialData((preState: any) => ({
          ...preState,
          title: title,
          preComment: data?.body || "",
        }));
        dispatch(setDraftMailForTask(null));
      }
    }
  };
  useEffect(() => {
    getTaskDataFromEmail();
  }, []);

  // get email data -------------------------------------------------

  // Questionnaires --------------------------------------------
  useEffect(() => {
    if (questionnaireIDs && insertId > 0) {
      questionnaireToast.current = toast("Sending Questionnaires...", {
        autoClose: false,
        isLoading: true,
      });
      const userID =
        initialData.company == "0" ? initialData.contact_person : initialData.responsible_person;
      dispatch(
        QuestionnaireAction.sendQuestionnaireFromTask({
          questionnaireIDs,
          userID,
          insertId,
        })
      );
    }
  }, [questionnaireIDs, insertId]);
  // Questionnaires --------------------------------------------

  useEffect(() => {
    if (initialData.sensitive && initialData.partner) {
      setInitialData((preState: any) => ({
        ...preState,
        reviewer: initialData.partner,
        file_manager: initialData.partner,
      }));
    }
  }, [initialData.sensitive, initialData.partner]);

  useEffect(() => {
    if (autoSave) { handleAutoSave("SET", initialData); }
  }, [initialData, autoSave]);

  useEffect(() => {
    if (initialData?.preliminary_details) {
      //@ts-ignore
      const tmp = initialData?.preliminary_details?.filter((a: any) => a.amount === "0" || a.date === "")
      setPreliminarySubmit(tmp.length > 0)
    }
  }, [initialData?.preliminary_details])

  return (
    <React.Fragment>
      <ScrollUp />
      <ScrollDown top={"100px"} />

      <Alert
        open={autoSaveAlert}
        title={t("Autosaved data restored!")}
        message={t("Your last autosaved data has been restored from the session. In order to avoid the data loss, you need either save it to retain the data or Cancel to loose the data.")}
        onClickOk={() => setAutoSaveAlert(false)}
      />
      <NavigationAlert
        open={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />

      {newTimeSpentFormModal && (
        <NewTimeSheetFormModal
          show={newTimeSpentFormModal}
          loading={loading}
          timeSpentData={timeSpentData}
          onSubmitClick={handleSubmitTimeSpent}
          onCloseClick={() => setNewTimeSpentFormModal(false)}
        />
      )}

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={`${t("Create")} ${t("task")}`}
            breadcrumbItems={breadcrumbItems}
          />

          <Row>
            <Col xl="12">
              <Card>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                  autoComplete="off"
                >
                  <Input id="id" name="id" type="hidden" />
                  <Input id="task_id" name="task_id" type="hidden" />
                  <Input id="code" name="code" type="hidden" value={initialData.custome_code} />

                  <CardBody>
                    <Row>
                      {userProfile.USER_FUNCTION === 6 && (
                        <Col className="mb-3 d-flex align-items-end">
                          <div className="mb-1 center-block ">
                            <FormGroup switch inline>
                              <Input
                                type="switch"
                                //disabled={isSensitiveFromFeeMaster}
                                checked={initialData.sensitive ? true : false}
                                onChange={(e) => {
                                  handleChange("sensitive", e.target.checked ? 1 : 0);
                                }}
                              />
                              <Label check>{t("Sensitive")}</Label>
                            </FormGroup>
                          </div>
                        </Col>
                      )}

                      <Col className="col-12"></Col>

                      <Col className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Task type")} <span className="text-warning is_required">*</span>
                          </Label>
                          <Select
                            id="type"
                            name="type"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={typeOption}
                            isDisabled={loadingTypeOption}
                            isLoading={loadingTypeOption}
                            value={
                              initialData.type
                                ? typeOption.find((option: any) => option.value == initialData.type)
                                : ""
                            }
                            isSearchable={true}
                            onBlur={() => validation.setFieldTouched("type", true)}
                            onChange={(e: any) => {
                              setTypeID(e.value);
                              handleChange("type", e.value);
                            }}
                          />
                          {validation.touched.type && validation.errors.type ? (
                            <FormFeedback type="invalid">{validation.errors.type}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label">{t("Category of task")}</Label>
                          <Input
                            id="category_of_fees"
                            name="category_of_fees"
                            className="form-control"
                            value={feesAllTypeOption.category_of_fees}
                            type="text"
                            readOnly
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label">{t("Task code")}</Label>
                          <Input
                            id="fees_code"
                            name="fees_code"
                            className="form-control"
                            type="text"
                            value={feesAllTypeOption.fees_code}
                            readOnly
                          />
                        </div>
                      </Col>

                      {userProfile?.CAN_VIEW_FINANCIAL ? (
                        <Col className="col-3">
                          <div className="mb-3">
                            <Label className="form-label">{t("Task amount")}</Label>
                            <Input
                              id="fees_amount"
                              name="fees_amount"
                              className="form-control"
                              type="number"
                              step=".01"
                              min={0}
                              value={initialData.fees_amount}
                              onBlur={validation.handleBlur}
                              onFocus={handleFocus}
                              onChange={(e) => {
                                handleChange("fees_amount", e.target.value);
                              }}
                              invalid={
                                validation.touched.fees_amount && validation.errors.fees_amount
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.fees_amount && validation.errors.fees_amount ? (
                              <FormFeedback type="invalid">
                                {validation.errors.fees_amount}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      ) : null}

                      {/* <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Task category")}{" "}
                          </Label>
                          <Input
                            id="category"
                            name="category"
                            className="form-control"
                            type="text"
                            value={feesAllTypeOption.category}
                            readOnly
                          />
                        </div>
                      </Col> */}

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label">{t("Expense")}</Label>
                          <Input
                            id="expense"
                            name="expense"
                            className="form-control"
                            type="number"
                            step=".01"
                            min={0}
                            value={initialData.expense}
                            onBlur={validation.handleBlur}
                            onFocus={handleFocus}
                            onChange={(e) => {
                              handleChange("expense", e.target.value);
                            }}
                            invalid={
                              validation.touched.expense && validation.errors.expense ? true : false
                            }
                          />
                        </div>
                      </Col>

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label">
                            {initialData.company &&
                              initialData.company !== "0" &&
                              companyAccess?.CAN_VIEW_ALL ? (
                              <Link
                                to="#"
                                onClick={() => handleCompanyClick()}
                                className="text-decoration-underline"
                              >
                                {t(`Client`)}
                              </Link>
                            ) : (
                              t(`Client`)
                            )}{" "}
                            {companyLanguage && (
                              <span
                                className="badge rounded-pill bg-primary"
                                title={t("Client's language")}
                              >
                                {companyLanguage}
                              </span>
                            )}
                            <span className="text-warning is_required">*</span>
                          </Label>
                          <Select
                            id="company"
                            name="company"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={companyOptions}
                            isDisabled={loadingCompanyOptions}
                            isLoading={loadingCompanyOptions}
                            value={
                              initialData.company
                                ? companyOptions.find(
                                  (option: any) => option.value == initialData.company
                                )
                                : ""
                            }
                            isSearchable={true}
                            onBlur={() => validation.setFieldTouched("company", true)}
                            onChange={(e: any) => {
                              setCompanyID(e.value);
                              handleChange("company", e.value);
                            }}
                          />
                          {validation.touched.company && validation.errors.company ? (
                            <FormFeedback type="invalid">{validation.errors.company}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label">{t("Project")}</Label>
                          <Select
                            id="project"
                            name="project"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={projectOption}
                            isDisabled={loadingCompanyUserOption || projectOption.length === 0}
                            value={
                              initialData.project
                                ? projectOption.find(
                                  (option: any) => option.value == initialData.project
                                )
                                : ""
                            }
                            isSearchable={true}
                            onBlur={() => validation.setFieldTouched("project", true)}
                            onChange={(e: any) => {
                              handleChange("project", e.value);
                              handleChange("purchase_order_number", e.po_number);
                              if (e.budget) {
                                handleChange("is_budget", 1);
                                handleChange("budget_amount", e.budget);
                              }
                            }}
                          />
                          {validation.touched.project && validation.errors.project ? (
                            <FormFeedback type="invalid">{validation.errors.project}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label">{t("Purchase order number")}</Label>
                          <Input
                            id="purchase_order_number"
                            name="purchase_order_number"
                            placeholder={t("Purchase order number")}
                            type="text"
                            maxLength={50}
                            value={initialData.purchase_order_number}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("purchase_order_number", e.target.value)}
                          />
                        </div>
                      </Col>

                      {initialData.sensitive == 0 && (
                        <Col className="col-3">
                          <div className="mb-3">
                            <Label className="form-label">
                              {initialData.contact_person && userAccess?.CAN_VIEW_ALL ? (
                                <Link
                                  to="#"
                                  onClick={() => handleUserClick(5)}
                                  className="text-decoration-underline"
                                >
                                  {t("Contact person")}
                                </Link>
                              ) : (
                                t("Contact person")
                              )}{" "}
                              {contactPersonLanguage && (
                                <span
                                  className="badge rounded-pill bg-primary"
                                  title="Contact person's language"
                                >
                                  {contactPersonLanguage}
                                </span>
                              )}
                              <span className="text-warning is_required">*</span>
                            </Label>
                            <Select
                              id="contact_person"
                              name="contact_person"
                              className="is-invalid"
                              placeholder={t("Select...")}
                              options={contactPersonOption}
                              value={
                                initialData.contact_person
                                  ? contactPersonOption.find(
                                    (option: any) => option.value == initialData.contact_person
                                  )
                                  : ""
                              }
                              isSearchable={true}
                              isDisabled={loadingCompanyUserOption}
                              onBlur={() => validation.setFieldTouched("contact_person", true)}
                              onChange={(e: any) => {
                                handleChange("contact_person", e.value);
                                if (initialData.company == "0") {
                                  setResponsiblePerson(e.label);
                                }
                              }}
                            />

                            {validation.touched.contact_person &&
                              validation.errors.contact_person ? (
                              <FormFeedback type="invalid">
                                {validation.errors.contact_person}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      )}

                      {initialData.sensitive == 0 && (
                        <Col className="col-3">
                          <div className="mb-3">
                            <Label className="form-label">
                              {initialData.responsible_person && userAccess?.CAN_VIEW_ALL ? (
                                <Link
                                  to="#"
                                  onClick={() => handleUserClick(6)}
                                  className="text-decoration-underline"
                                >
                                  {t("Person concerned")}
                                </Link>
                              ) : (
                                t("Person concerned")
                              )}{" "}
                            </Label>
                            <Select
                              id="responsible_person"
                              name="responsible_person"
                              className="is-invalid"
                              placeholder={t("Select...")}
                              options={responsiblePersonOption}
                              value={
                                initialData.responsible_person
                                  ? responsiblePersonOption.find(
                                    (option: any) =>
                                      option.value == initialData.responsible_person
                                  )
                                  : ""
                              }
                              isClearable={true}
                              isSearchable={true}
                              isDisabled={
                                parseInt(initialData.company) === 0
                                  ? true
                                  : loadingCompanyUserOption
                              }
                              onBlur={() => validation.setFieldTouched("responsible_person", true)}
                              onChange={(e: any) => {
                                if (e) {
                                  handleChange("responsible_person", e.value);
                                  if (initialData.company != "0") {
                                    setResponsiblePerson(e.label);
                                  }
                                } else {
                                  handleChange("responsible_person", "");
                                  setResponsiblePerson("");
                                }
                              }}
                            />
                            {validation.touched.responsible_person &&
                              validation.errors.responsible_person ? (
                              <FormFeedback type="invalid">
                                {validation.errors.responsible_person}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      )}

                      <Col className="col-12"></Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Task ID")} <span className="text-warning is_required">*</span>
                          </Label>
                          <Input
                            id="custome_code"
                            name="custome_code"
                            className="form-control"
                            placeholder="Task ID"
                            type="text"
                            value={initialData.custome_code}
                            readOnly={loadingCode}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("custome_code", e.target.value)}
                            invalid={
                              validation.touched.custome_code && validation.errors.custome_code
                                ? true
                                : false
                            }
                          />
                          {loadingCode ? <Spinner /> : ""}
                          {validation.touched.custome_code && validation.errors.custome_code ? (
                            <FormFeedback type="invalid">
                              {validation.errors.custome_code}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-9">
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Task title")} <span className="text-warning is_required">*</span>
                          </Label>
                          <Input
                            id="title"
                            name="title"
                            placeholder="Task title"
                            type="text"
                            maxLength={200}
                            // autoFocus={true}
                            value={initialData.title}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("title", e.target.value)}
                            invalid={
                              validation.touched.title && validation.errors.title ? true : false
                            }
                          />
                          {validation.touched.title && validation.errors.title ? (
                            <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label">
                            {initialData.preparer && userAccess?.CAN_VIEW_ALL ? (
                              <Link
                                to="#"
                                onClick={() => handleUserClick(0)}
                                className="text-decoration-underline"
                              >
                                {t("Preparer")}
                              </Link>
                            ) : (
                              t("Preparer")
                            )}{" "}
                            <span className="text-warning is_required">*</span>
                          </Label>
                          <Select
                            id="preparer"
                            name="preparer"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={preparerOption}
                            value={
                              initialData.preparer
                                ? preparerOption.find(
                                  (option: any) => option.value == initialData.preparer
                                )
                                : ""
                            }
                            isSearchable={true}
                            isDisabled={initialData.sensitive === 1 ? true : loadingPreparer}
                            onBlur={() => validation.setFieldTouched("preparer", true)}
                            onChange={(e: any) => {
                              handleChange("preparer", e.value);
                            }}
                          />
                          {validation.touched.preparer && validation.errors.preparer ? (
                            <FormFeedback type="invalid">{validation.errors.preparer}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      {initialData.sensitive === 0 && (
                        <>
                          <Col className="col-3">
                            <div className="mb-3">
                              <Label className="form-label">
                                {initialData.reviewer && userAccess?.CAN_VIEW_ALL ? (
                                  <Link
                                    to="#"
                                    onClick={() => handleUserClick(1)}
                                    className="text-decoration-underline"
                                  >
                                    {t("Reviewer")}
                                  </Link>
                                ) : (
                                  t("Reviewer")
                                )}{" "}
                                <span className="text-warning is_required">*</span>
                              </Label>

                              <Select
                                id="reviewer"
                                name="reviewer"
                                className="is-invalid"
                                placeholder={t("Select...")}
                                options={reviewerOption}
                                value={
                                  initialData.reviewer
                                    ? reviewerOption.find(
                                      (option: any) => option.value == initialData.reviewer
                                    )
                                    : ""
                                }
                                isSearchable={true}
                                isDisabled={isPreparerValueSelected}
                                onBlur={() => validation.setFieldTouched("reviewer", true)}
                                onChange={(e: any) => {
                                  handleChange("reviewer", e.value);
                                }}
                              />

                              {validation.touched.reviewer && validation.errors.reviewer ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.reviewer}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col className="col-3">
                            <div className="mb-3">
                              <Label className="form-label">
                                {initialData.file_manager && userAccess?.CAN_VIEW_ALL ? (
                                  <Link
                                    to="#"
                                    onClick={() => handleUserClick(2)}
                                    className="text-decoration-underline"
                                  >
                                    {userProfile.IS_INTERNAL
                                      ? t("Responsible to sign off")
                                      : t("File manager")}
                                  </Link>
                                ) : userProfile.IS_INTERNAL ? (
                                  t("Responsible to sign off")
                                ) : (
                                  t("File manager")
                                )}{" "}
                                <span className="text-warning is_required">*</span>
                              </Label>

                              <Select
                                id="file_manager"
                                name="file_manager"
                                className="is-invalid"
                                placeholder={t("Select...")}
                                options={fileManagerOption}
                                value={
                                  initialData.file_manager
                                    ? fileManagerOption.find(
                                      (option: any) => option.value == initialData.file_manager
                                    )
                                    : ""
                                }
                                isSearchable={true}
                                isDisabled={isPreparerValueSelected}
                                onBlur={() => validation.setFieldTouched("file_manager", true)}
                                onChange={(e: any) => {
                                  handleChange("file_manager", e.value);
                                }}
                              />

                              {validation.touched.file_manager && validation.errors.file_manager ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.file_manager}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </>
                      )}

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label">
                            {initialData.partner && userAccess?.CAN_VIEW_ALL ? (
                              <Link
                                to="#"
                                onClick={() => handleUserClick(3)}
                                className="text-decoration-underline"
                              >
                                {t("Partner")}
                              </Link>
                            ) : (
                              t("Partner")
                            )}{" "}
                            <span className="text-warning is_required">*</span>
                          </Label>

                          <Select
                            id="partner"
                            name="partner"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={partnerOption}
                            value={
                              initialData.partner
                                ? partnerOption.find(
                                  (option: any) => option.value == initialData.partner
                                )
                                : ""
                            }
                            isSearchable={true}
                            isDisabled={isPreparerValueSelected}
                            onBlur={() => validation.setFieldTouched("partner", true)}
                            onChange={(e: any) => {
                              handleChange("partner", e.value);
                            }}
                          />

                          {validation.touched.partner && validation.errors.partner ? (
                            <FormFeedback type="invalid">{validation.errors.partner}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      {isAssistantAdministrative && initialData.sensitive == 0 && (
                        <Col className="col-3">
                          <div className="mb-3">
                            <Label className="form-label">
                              {initialData.assistant_administrative && userAccess?.CAN_VIEW_ALL ? (
                                <Link
                                  to="#"
                                  onClick={() => handleUserClick(4)}
                                  className="text-decoration-underline"
                                >
                                  {t("Assistant administrative")}
                                </Link>
                              ) : (
                                t("Assistant administrative")
                              )}{" "}
                              <span className="text-warning is_required">*</span>
                            </Label>

                            <Select
                              id="assistant_administrative"
                              name="assistant_administrative"
                              className="is-invalid"
                              placeholder={t("Select...")}
                              options={assistantAdministrativeOption}
                              value={
                                initialData.assistant_administrative
                                  ? assistantAdministrativeOption.find(
                                    (option: any) =>
                                      option.value == initialData.assistant_administrative
                                  )
                                  : ""
                              }
                              isSearchable={true}
                              isDisabled={isPreparerValueSelected}
                              onBlur={() =>
                                validation.setFieldTouched("assistant_administrative", true)
                              }
                              onChange={(e: any) => {
                                handleChange("assistant_administrative", e.value);
                              }}
                            />

                            {validation.touched.assistant_administrative &&
                              validation.errors.assistant_administrative ? (
                              <FormFeedback type="invalid">
                                {validation.errors.assistant_administrative}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      )}

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <Col className="col-4">
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Start date")} <span className="text-warning is_required">*</span>
                          </Label>
                          <div className="input-group is-invalid">
                            <Flatpickr
                              id="start_date"
                              name="start_date"
                              className="form-control d-block"
                              type="text"
                              value={initialData.start_date}
                              placeholder="DD/MM/YYYY"
                              options={{
                                altInput: true,
                                altFormat: "d/m/Y",
                                dateFormat: "Y-m-d H:i:S",
                                minDate: "today",
                                allowInput: true,
                                plugins: [labelPlugin()],
                                onChange: function (selectedDates, dateStr, instance) {
                                  handleChange("start_date", dateStr);
                                },
                                onClose: function (selectedDates, dateStr, instance) {
                                  handleChange("start_date", dateStr);
                                },
                                locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                              }}
                            />
                            <span className="input-group-text">
                              <span className="align-middle material-symbols-outlined fw-light fs-4">
                                calendar_today
                              </span>
                            </span>
                          </div>
                          {validation.touched.start_date && validation.errors.start_date ? (
                            <FormFeedback type="invalid">
                              {validation.errors.start_date}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-4">
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Internal due date")}{" "}
                            <span className="text-warning is_required">*</span>
                          </Label>
                          <div className="input-group is-invalid">
                            <Flatpickr
                              id="due_date"
                              name="due_date"
                              className="form-control d-block"
                              type="text"
                              value={initialData.due_date}
                              placeholder="DD/MM/YYYY"
                              options={{
                                altInput: true,
                                altFormat: "d/m/Y",
                                dateFormat: "Y-m-d H:i:S",
                                minDate: "today",
                                plugins: [labelPlugin()],
                                allowInput: true,
                                onChange: function (selectedDates, dateStr, instance) {
                                  handleChange("due_date", dateStr);
                                },
                                onClose: function (selectedDates, dateStr, instance) {
                                  handleChange("due_date", dateStr);
                                },
                                locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                              }}
                            />
                            <span className="input-group-text">
                              <span className="align-middle material-symbols-outlined fw-light fs-4">
                                calendar_today
                              </span>
                            </span>
                          </div>
                          {validation.touched.due_date && validation.errors.due_date ? (
                            <FormFeedback type="invalid">{validation.errors.due_date}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-4">
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Due date")} <span className="text-warning is_required">*</span>
                          </Label>
                          <div className="input-group is-invalid">
                            <Flatpickr
                              id="closing_date"
                              name="closing_date"
                              className="form-control d-block"
                              type="text"
                              value={initialData.closing_date}
                              placeholder="DD/MM/YYYY"
                              options={{
                                altInput: true,
                                altFormat: "d/m/Y",
                                dateFormat: "Y-m-d H:i:S",
                                plugins: [labelPlugin()],
                                minDate: initialData.due_date ? initialData.due_date : "today",
                                allowInput: true,
                                onChange: function (selectedDates, dateStr, instance) {
                                  handleChange("closing_date", dateStr);
                                },
                                onClose: function (selectedDates, dateStr, instance) {
                                  handleChange("closing_date", dateStr);
                                },
                                locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                              }}
                            />
                            <span className="input-group-text">
                              <span className="align-middle material-symbols-outlined fw-light fs-4">
                                calendar_today
                              </span>
                            </span>
                          </div>
                          {validation.touched.closing_date && validation.errors.closing_date ? (
                            <FormFeedback type="invalid">
                              {validation.errors.closing_date}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-4">
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Task priority")} <span className="text-warning is_required">*</span>
                          </Label>
                          <Select
                            id="priority"
                            name="priority"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={taskPriority}
                            value={
                              initialData.priority
                                ? taskPriority.find(
                                  (option: any) => option.value == initialData.priority
                                )
                                : ""
                            }
                            formatOptionLabel={function (data: any) {
                              return (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: data.label,
                                  }}
                                />
                              );
                            }}
                            isSearchable={true}
                            onBlur={() => validation.setFieldTouched("priority", true)}
                            onChange={(e: any) => {
                              handleChange("priority", e.value);
                            }}
                          />
                          {validation.touched.priority && validation.errors.priority ? (
                            <FormFeedback type="invalid">{validation.errors.priority}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-4">
                        <div className="mb-3">
                          <Label className="form-label">
                            Task status <span className="text-warning is_required">*</span>
                          </Label>

                          <Select
                            id="status"
                            name="status"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={taskStatusOption}
                            value={
                              initialData.status
                                ? taskStatusOption.find(
                                  (option: any) => option.value == initialData.status
                                )
                                : ""
                            }
                            isSearchable={true}
                            // isLoading={loadingStatusOption}
                            isDisabled={disabledStatusOption}
                            onBlur={() => validation.setFieldTouched("status", true)}
                            onChange={(e: any) => {
                              handleChange("status", e.value);
                            }}
                          />
                          {validation.touched.status && validation.errors.status ? (
                            <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-4">
                        <div className="mb-3 float-start w-100">
                          <Label className="form-label">{t("Task progress")}</Label>
                          <InputGroup className="float-start">
                            <Input
                              id="preparer_progress"
                              name="preparer_progress"
                              className={
                                initialData.preparer_progress <= 25
                                  ? "form-control bg-danger"
                                  : initialData.preparer_progress > 25 &&
                                    initialData.preparer_progress <= 50
                                    ? "form-control bg-warning"
                                    : initialData.preparer_progress > 50 &&
                                      initialData.preparer_progress <= 75
                                      ? "form-control bg-primary"
                                      : initialData.preparer_progress > 75 &&
                                        initialData.preparer_progress <= 100
                                        ? "form-control bg-success"
                                        : "form-control"
                              }
                              type="range"
                              min={0}
                              max={100}
                              step={5}
                              value={initialData.preparer_progress}
                              disabled={disabledStatusOption}
                              onBlur={validation.handleBlur}
                              onChange={(e) => {
                                handleChange("preparer_progress", e.target.value);
                              }}
                              style={{
                                padding: "18px",
                                border: "1px solid #d6dbe1",
                                borderRight: "none",
                              }}
                            />
                            <div className="input-group-text">
                              {initialData.preparer_progress} %
                            </div>
                          </InputGroup>
                        </div>
                      </Col>

                      <Col className="col-12"></Col>

                      {isShowIncomeYear && (
                        <Col className="col-4">
                          <div className="mb-3">
                            <Label className="form-label">{t("Income year")} </Label>
                            <Select
                              id="income_year"
                              name="income_year"
                              className="is-invalid"
                              placeholder={t("Select...")}
                              options={incomeYearOption}
                              value={
                                initialData.income_year
                                  ? incomeYearOption.find(
                                    (option: any) => option.value == initialData.income_year
                                  )
                                  : ""
                              }
                              isSearchable={true}
                              onBlur={() => validation.setFieldTouched("income_year", true)}
                              onChange={(e: any) => {
                                handleChange("income_year", e.value);
                              }}
                            />
                          </div>
                        </Col>
                      )}

                      <Col className="col-4 align-items-center">
                        <div className="form-check float-start mb-2">
                          <input
                            id="is_budget"
                            name="is_budget"
                            className="form-check-input"
                            type="checkbox"
                            value={initialData.is_budget}
                            checked={initialData.is_budget == 1 ? true : false}
                            onBlur={validation.handleBlur}
                            onChange={handleBudget}
                          />
                          <label className="form-check-label" htmlFor="is_budget">
                            {t("Budget")}
                          </label>
                        </div>
                        <Input
                          id="budget_amount"
                          name="budget_amount"
                          className="form-control"
                          type="number"
                          step=".01"
                          min={0}
                          value={initialData.budget_amount}
                          readOnly={initialData.is_budget ? false : true}
                          onBlur={validation.handleBlur}
                          onFocus={handleFocus}
                          onChange={(e) => {
                            handleChange("budget_amount", e.target.value);
                          }}
                          invalid={
                            validation.touched.budget_amount && validation.errors.budget_amount
                              ? true
                              : false
                          }
                        />
                      </Col>

                      {isPreliminaryType && (
                        <Col className="col-4 align-items-center">
                          <div className="form-check float-start mb-2 w-100">
                            <input
                              id="preliminary_billing"
                              name="preliminary_billing"
                              className="form-check-input"
                              type="checkbox"
                              value={initialData.preliminary_billing}
                              checked={initialData.preliminary_billing === 1 ? true : false}
                              onBlur={validation.handleBlur}
                              onChange={handlePreliminaryBilling}
                            />
                            <label className="form-check-label" htmlFor="preliminary_billing">
                              {t("Preliminary billing")}{" "}
                              <Badge color="secondary" pill>
                                {
                                  //@ts-ignore
                                  initialData?.preliminary_details?.length
                                }
                              </Badge>
                            </label>

                            <FormGroup switch inline className="float-end me-0">
                              <Input
                                id="is_preliminary_fixed_amount"
                                type="switch"
                                checked={initialData.is_preliminary_fixed_amount ? true : false}
                                disabled={true}
                                onChange={(e) => {
                                  handleChange(
                                    "is_preliminary_fixed_amount",
                                    e.target.checked ? 1 : 0
                                  );
                                }}
                              />
                              <Label check>{t("Fixed amount")}</Label>
                            </FormGroup>
                          </div>
                          <InputGroup className="float-start">
                            <Input
                              id="preliminary_percentage"
                              name="preliminary_percentage"
                              className="form-control"
                              type="number"
                              step=".01"
                              min={preliminaryMin}
                              max={preliminaryMax}
                              //@ts-ignore
                              value={
                                initialData?.preliminary_details
                                  ? //@ts-ignore
                                  initialData?.preliminary_details?.filter(
                                    (a: any) => a.status === 0
                                  )[0]["amount"]
                                  : ""
                              }
                              // value={initialData.preliminary_percentage}
                              onFocus={handleFocus}
                              onBlur={validation.handleBlur}
                              onChange={(e) => {
                                handleChange("preliminary_percentage", e.target.value);
                              }}
                              readOnly={true}
                              invalid={
                                validation.touched.preliminary_percentage &&
                                  validation.errors.preliminary_percentage
                                  ? true
                                  : false
                              }
                            />
                            <div className="input-group-text">
                              {initialData.is_preliminary_fixed_amount ? (
                                <span className="align-middle material-symbols-outlined fw-light fs-5">
                                  euro
                                </span>
                              ) : (
                                <span className="align-middle material-symbols-outlined fw-light fs-5">
                                  percent
                                </span>
                              )}
                            </div>
                            <Button
                              type="button"
                              onClick={() => setOpenPreliminaryModal(true)}
                              disabled={
                                initialData.preliminary_billing === 0 ||
                                initialData?.preliminary_details === null
                              }
                            >
                              {
                                //@ts-ignore
                                t(initialData?.preliminary_details ? t("Show all") : t("Add more"))
                              }
                            </Button>
                          </InputGroup>
                          {preliminaryError && (
                            <FormFeedback type="invalid" className="mt-1 float-start">
                              {preliminaryError}
                            </FormFeedback>
                          )}
                        </Col>
                      )}

                      {isTaxReturnType && (
                        <Col className="col-4">
                          <div className="mb-3">
                            <Label className="form-label">{t("Tax return type")}</Label>
                            <Select
                              id="tax_return_type_id"
                              name="tax_return_type_id"
                              className="is-invalid"
                              placeholder={t("Select...")}
                              options={taxReturnTypeOption}
                              value={
                                initialData.tax_return_type_id
                                  ? taxReturnTypeOption.find(
                                    (option: any) =>
                                      option.value == initialData.tax_return_type_id
                                  )
                                  : ""
                              }
                              isSearchable={false}
                              onBlur={() => validation.setFieldTouched("tax_return_type_id", true)}
                              onChange={(e: any) => {
                                handleChange("tax_return_type_id", e.value);
                              }}
                            />
                          </div>
                        </Col>
                      )}

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <Col className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">{t("Comments")}</Label>
                          <MultiComments
                            moduleData={{
                              module_app_id: 2,
                              module_main_id: 0,
                              module_sub_id: "",
                              section_id: 0,
                            }}
                            commentForIDs={commentAttachmentIDs}
                            fetchData={false}
                            isOpen={true}
                            insertId={insertId}
                            insertSubId={insertSubId}
                            onSuccess={multiCommentsSuccess}
                            preComment={initialData.preComment}
                          />
                        </div>
                      </Col>

                      <Col className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">{t("Attachments")}</Label>
                          <Dropzone
                            moduleData={{
                              module_app_id: 2,
                              module_main_id: 0,
                              module_sub_id: "",
                              section_id: 0,
                              file_path: "task",
                            }}
                            attachmentForIDs={commentAttachmentIDs}
                            fetchData={false}
                            isOpen={true}
                            insertId={insertId}
                            insertSubId={insertSubId}
                            onSuccess={multiUploadSuccess}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>

                  <CardFooter className="d-sm-flex flex-wrap bg-transparent border-top">
                    <div className="d-flex gap-2">
                      {timesheetProfile.CAN_CREATE && (
                        <Button
                          color="success"
                          className="btn-block"
                          type="button"
                          onClick={onClickTimeSpent}
                          disabled={loading}
                        >
                          {loading ? (
                            <span>
                              <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                                hourglass_empty
                              </span>
                              <span>{t("Please wait...")}</span>
                            </span>
                          ) : (
                            <span>{`${t("Update")} ${t("time spent")}`}</span>
                          )}
                        </Button>
                      )}

                      {displayQuest && (
                        <Button
                          color="primary"
                          className="btn-block"
                          type="submit"
                          onClick={(e: any) => onClickQuestionnaire(e)}
                          disabled={loading}
                        >
                          {loading ? (
                            <span>
                              <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                                hourglass_empty
                              </span>
                              <span>{t("Please wait...")}</span>
                            </span>
                          ) : (
                            <span>{t("Questionnaire")}</span>
                          )}
                        </Button>
                      )}
                    </div>
                    <div className="ms-auto d-flex gap-2 justify-content-end">
                      {userProfile.CAN_CREATE ? (
                        <>
                          <Button
                            color="primary"
                            className="btn-block"
                            type="submit"
                            onClick={() => handleChange("submit_btn_code", "save_add_new")}
                            disabled={loading}
                          >
                            {loading ? (
                              <span>
                                <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                                  hourglass_empty
                                </span>
                                <span>{t("Please wait...")}</span>
                              </span>
                            ) : (
                              <span>{t("Save & Add new")}</span>
                            )}
                          </Button>

                          <Button
                            color="primary"
                            className="btn-block"
                            type="submit"
                            onClick={() => handleChange("submit_btn_code", "save_close")}
                            disabled={loading}
                          >
                            {loading ? (
                              <span>
                                <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                                  hourglass_empty
                                </span>
                                <span>{t("Please wait...")}</span>
                              </span>
                            ) : (
                              <span>{t("Save & Close")}</span>
                            )}
                          </Button>

                          <Button
                            color="primary"
                            className="btn-block"
                            type="submit"
                            onClick={() => handleChange("submit_btn_code", "save")}
                            disabled={loading}
                          >
                            {loading ? (
                              <span>
                                <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                                  hourglass_empty
                                </span>
                                <span>{t("Please wait...")}</span>
                              </span>
                            ) : (
                              <span>{t("Save")}</span>
                            )}
                          </Button>

                          <Button
                            color="danger"
                            className="btn-block"
                            type="reset"
                            onClick={() => {
                              handleRedirect(true);
                            }}
                            disabled={loading}
                          >
                            {loading ? (
                              <span>
                                <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                                  hourglass_empty
                                </span>
                                <span>{t("Please wait...")}</span>
                              </span>
                            ) : (
                              <span>{t("Cancel")}</span>
                            )}
                          </Button>
                        </>
                      ) : null}
                    </div>
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
        <>
          <Drawer
            title={t("Client")}
            open={openCompany}
            onClose={() => setOpenCompany((state) => !state)}
            fullWidth
          >
            <EditCompany
              id={company?.company}
              company_id={company?.company_id}
              type={1}
              isWindow={true}
            />
          </Drawer>
          <Drawer
            title={t("User")}
            open={openUser}
            onClose={() => setOpenUser((state) => !state)}
            fullWidth
          >
            <EditUser
              id={selectedUser?.id}
              user_id={selectedUser?.user_id}
              type={1}
              isWindow={true}
            />
          </Drawer>
        </>
        <>
          <Modal isOpen={openPreliminaryModal} onClosed={() => setOpenPreliminaryModal(false)}>
            <ModalBody>
              <PreliminaryForm
                initialData={initialData}
                handleChange={handleChange}
                setInitialData={setInitialData}
              />
            </ModalBody>
            <ModalFooter>
              <Button type="button" color="primary" disabled={isPreliminarySubmit} onClick={() => setOpenPreliminaryModal(false)}>
                {t("Submit")}
              </Button>
              <Button type="button" color="danger" disabled={isPreliminarySubmit} onClick={() => setOpenPreliminaryModal(false)}>
                {t("Cancel")}
              </Button>
            </ModalFooter>
          </Modal>
          {questionnaireModal && (
            <QuestionnaireModal
              questionnaireData={questionnaireData}
              responsiblePerson={responsiblePerson}
              show={questionnaireModal}
              onCloseClick={() => setQuestionnaireModal(false)}
              onYesClick={onYesClick}
              onNoClick={onNoClick}
            />
          )}
        </>
      </div>
    </React.Fragment>
  );
};

export default NewTask;
