import {
  GET_USER_TYPE,
  GET_USER_TYPE_SUCCESS,
  GET_USER_TYPE_ERROR,

  GET_USER_TYPE_LIST,
  GET_USER_TYPE_LIST_SUCCESS,
  GET_USER_TYPE_LIST_ERROR,

  CREATE_USER_TYPE,
  CREATE_USER_TYPE_SUCCESS,
  CREATE_USER_TYPE_ERROR,

  UPDATE_USER_TYPE,
  UPDATE_USER_TYPE_SUCCESS,
  UPDATE_USER_TYPE_ERROR,

  DELETE_USER_TYPE,
  DELETE_USER_TYPE_SUCCESS,
  DELETE_USER_TYPE_ERROR,
} from "./actionTypes";

// ---------------------------------------------GET_USER_TYPE
export const getUserType = (usertype: any, history: any) => {
  return {
    type: GET_USER_TYPE,
    payload: { usertype, history },
  };
};

export const getUserTypeSuccess = (data: {}) => {
  return {
    type: GET_USER_TYPE_SUCCESS,
    payload: data,
  };
};

export const getUserTypeError = (error: {}) => {
  return {
    type: GET_USER_TYPE_ERROR,
    payload: error,
  };
};

// ---------------------------------------------GET_USER_TYPE_LIST
export const getUserTypeList = (query: any, history: any) => {
  return {
    type: GET_USER_TYPE_LIST,
    payload: {query, history},
  };
};

export const getUserTypeListSuccess = (data: {}) => {
  return {
    type: GET_USER_TYPE_LIST_SUCCESS,
    payload: data,
  };
};

export const getUserTypeListError = (error: {}) => {
  return {
    type: GET_USER_TYPE_LIST_ERROR,
    payload: error,
  };
};

// ---------------------------------------------CREATE_USER_TYPE
export const createUserType = (usertype: any, history: any) => {
  return {
    type: CREATE_USER_TYPE,
    payload: { usertype, history },
  };
};

export const createUserTypeSuccess = (usertype: {}) => {
  return {
    type: CREATE_USER_TYPE_SUCCESS,
    payload: usertype,
  };
};

export const createUserTypeError = (error: {}) => {
  return {
    type: CREATE_USER_TYPE_ERROR,
    payload: error,
  };
};

// ---------------------------------------------UPDATE_USER_TYPE
export const updateUserType = (usertype: any, history: any) => {
  return {
    type: UPDATE_USER_TYPE,
    payload: { usertype, history },
  };
};

export const updateUserTypeSuccess = (usertype: {}) => {
  return {
    type: UPDATE_USER_TYPE_SUCCESS,
    payload: usertype,
  };
};

export const updateUserTypeError = (error: {}) => {
  return {
    type: UPDATE_USER_TYPE_ERROR,
    payload: error,
  };
};

// ---------------------------------------------DELETE_USER_TYPE
export const deleteUserType = (usertype: any, history: any) => {
  return {
    type: DELETE_USER_TYPE,
    payload: { usertype, history },
  };
};

export const deleteUserTypeSuccess = (usertype: {}) => {
  return {
    type: DELETE_USER_TYPE_SUCCESS,
    payload: usertype,
  };
};

export const deleteUserTypeError = (error: {}) => {
  return {
    type: DELETE_USER_TYPE_ERROR,
    payload: error,
  };
};
