import { Table } from "reactstrap";

const PrivacyPolicy = (props: any) => {
  return (
    <>
      <h4>1. About this Notice</h4>
      <p>
        1.1 This Privacy Notice ("<strong>Notice</strong>") explains how we (as defined below)
        collect, share and use any information that, alone or in combination with other information,
        relates to you ("<strong>Personal Data</strong>") in your capacity as a client or
        beneficiary of our services, or as a supplier (or as our contact person for these entities
        or representatives) ("<strong>you</strong>" and "<strong>your</strong>") of Tax Consult HR
        Tax & International Mobility ("
        <strong>Tax Consult</strong>", "<strong>we</strong>" and "<strong>our</strong>
        ").
      </p>
      <p>
        1.2 This Notice also sets out the rights that you have in relation to the Personal Data that
        we process about you and how you can exercise them.
      </p>
      <p>
        1.3 Tax Consult HR Tax & International Mobility treats compliance with its privacy
        obligations seriously. This is why we have developed this Notice, which describes the
        standards that Tax Consult applies to protect Personal Data.
      </p>
      <p>
        1.4 For the purposes of this Notice,{" "}
        <strong>Tax Consult HR Tax & International Mobility SRL</strong>, with which you have
        entered into a contract or which provides services to you as a beneficiary, acts as the data
        controller. Our offices are located in 1170 Brussels, avenue du Dirigeable 8 and we are
        registered with the Crossroads Bank for Enterprises under number 0740.763.660. As a data
        controller, Tax Consult is responsible for ensuring that the processing of Personal Data
        complies with applicable data protection law, and specifically with the General Data
        Protection Regulation.
      </p>
      <p>
        1.5 Please take the time to read this Notice carefully. If you have any questions or
        comments, please contact <a href="mailto:privacy@taxconsult.be">privacy@taxconsult.be</a>.
      </p>

      <h4>2. What Personal Data does Tax Consult collect and why?</h4>
      <p>
        The types of Personal Data that we collect about you, and the reasons why we process it, are
        the following:
      </p>

      <Table bordered={true}>
        <thead>
          <tr>
            <th>Why we collect it</th>
            <th>Types of Personal Data</th>
            <th>Legal basis</th>
          </tr>
          <tr>
            <th colSpan={3}>Processing relating to services to our clients and beneficiaries</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              To ensure compliance with our anti-money laundering obligations in relation to our
              clients and the recipients of our services
            </td>
            <td>
              Identification data, contact details, financial and economic data, household data,
              data on personal characteristics, lifestyle data, professional data, data on identity
              documents.
            </td>
            <td>Legal obligation to which we are subject.</td>
          </tr>
          <tr>
            <td>
              Preparation of personal income tax returns for resident and non-resident taxpayers
            </td>
            <td>The same categories of data as above.</td>
            <td>
              Contractual necessity when our client is a natural person; our legitimate interest in
              providing our services when our client is a legal person.
            </td>
          </tr>
          <tr>
            <td>
              To provide services related to tax and social security estimates, salaries, social
              charges due
            </td>
            <td>The same categories of data as above.</td>
            <td>
              Contractual necessity when our client is a natural person; our legitimate interest in
              providing our services when our client is a legal person.
            </td>
          </tr>
          <tr>
            <td>To carry out tax audits and administrative tax claims</td>
            <td>
              The same categories of data as above, depending on the specific tasks to carry out.
            </td>
            <td>
              Contractual necessity when our client is a natural person; our legitimate interest in
              providing our services when our client is a legal person.
            </td>
          </tr>
          <tr>
            <td>Consulting services, including special tax status and immigration issues</td>
            <td>
              The same categories of data as above, depending on the specific tasks to carry out.
            </td>
            <td>
              Contractual necessity when our client is a natural person; our legitimate interest in
              providing our services when our client is a legal person.
            </td>
          </tr>
          <tr>
            <td>
              For the completion of transactions with us, billing for customer services and recovery
              of customer debts.
            </td>
            <td>Identification data, contact details, financial data.</td>
            <td>Contractual necessity</td>
          </tr>
          <tr>
            <td>
              Client management (execution of the Client Acceptance Procedure, establishment and
              management of engagement letters, management of mandates towards the authorities and
              towards our accounting application IT providers).
            </td>
            <td>
              Identification data, contact details, financial data, household data, data on personal
              characteristics.
            </td>
            <td>Contractual necessity</td>
          </tr>
          <tr>
            <td>For the management of complaints and disputes with customers.</td>
            <td>
              Identification data, contact details, data included in the contract documentation.
            </td>
            <td>Our legitimate interest in safeguarding our rights and interests.</td>
          </tr>
          <tr>
            <td colSpan={3}>Processing relating to our suppliers</td>
          </tr>
          <tr>
            <td>For the management of suppliers and the execution of transactions with us.</td>
            <td>Identification data, contact details, financial data.</td>
            <td>Contractual necessity</td>
          </tr>
          <tr>
            <td>For the management of complaints and disputes with suppliers.</td>
            <td>
              Identification data, such as name, address (business or private), telephone number,
              etc.
            </td>
            <td>Our legitimate interest in safeguarding our rights and interests.</td>
          </tr>
        </tbody>
      </Table>
      <p>
        2.2 You may provide us directly with some of the Personal Data listed above. However, we
        also receive Personal Data about you from third party sources when we contract on a B2B
        basis with business clients to provide certain services for which you are the beneficiary
        (such as your employer or the company of which you are a shareholder or officer or an
        intermediary for which you are a beneficiary).
      </p>
      <p>
        The types of Personal Data we collect from third parties include your identification data
        (surname, first name), your contact details, your professional data, and the data necessary
        to provide the services for which you are the beneficiary.{" "}
      </p>
      <p>
        2.3 If necessary, we process Personal Data that may contain information relating to a
        person's ethnic origin, political opinions or religious beliefs, physical or mental health
        or condition or commission or alleged commission of criminal offences and any related legal
        actions and biometric information ("Special Personal Data"). We will minimise the amount of
        Sensitive Personal Data we process, and only where it is necessary to comply with our legal
        obligations, for example anti-money laundering, or where you have provided such data to us
        for the purpose of providing tax service.
      </p>
      <h4>3 .Who does Tax Consult share your Personal Data with?</h4>
      <p>3.1 We may disclose your Personal Data to the following categories of recipients:</p>
      <ul>
        <li>
          to our third party vendors and services providers who provide data processing services to
          us, or who otherwise process Personal Data for purposes that are described in this Notice
          or notified to you when we collect your Personal Data. These service providers provide us
          with services in the following areas: CRM software, tax and accounting software or
          programs, document management programs, IT services (including Admin Consult (Syneton).
          This includes Emasphere, Winbooks, IController, ETC Tool, Dynamic Flows, Tax Consult SA
          and our e-mail services.
        </li>
        <li>
          to any competent law enforcement body, regulatory, government agency, court or other third
          party where we believe disclosure is necessary (i) as a matter of applicable law or
          regulation, (ii) to exercise, establish or defend our legal rights, or (iii) to protect
          your vital interests or those of any other person. In addition, as part of the services we
          provide, we communicate certain Personal Data to the competent authorities (e.g. for tax
          purposes) or to other similar entities (e.g. the Moniteur Belge, or the Crossroads Bank
          for Enterprises);
        </li>
        <li>
          to our auditors, advisors, legal representatives and similar agents in connection with the
          advisory services they provide to us for legitimate business purposes and under
          contractual prohibition of using the Personal Data for any other purpose;{" "}
        </li>
        <li>
          to a potential buyer (and its agents and advisers) in connection with any proposed
          purchase, merger or acquisition of any part of our business, provided that we inform the
          buyer it must use your Personal Data only for the purposes disclosed in this Notice;
        </li>
        <li>to any other person if you have provided your prior consent to the disclosure.</li>
      </ul>

      <h4>4.How we protect your privacy </h4>
      <p>4.1.We will process Personal Data in accordance with this Notice, as follows:</p>
      <ul>
        <li>
          Fairness: We will process Personal Data fairly. This means that we are transparent about
          how we process Personal Data and that we will process it in accordance with applicable
          law.
        </li>
        <li>
          Purpose limitation: We will process Personal Data for specified and lawful purposes, and
          will not process it in a manner that is incompatible with those purposes.{" "}
        </li>
        <li>
          Proportionality: We will process Personal Data in a way that is proportionate to the
          purposes which the processing is intended to achieve.{" "}
        </li>
        <li>
          Data accuracy: We take appropriate measures to ensure that the Personal Data that we hold
          is accurate, complete and, where necessary, kept up to date. However, it is also your
          responsibility to ensure that your Personal Data is kept as accurate, complete and current
          as possible by informing Tax Consult of any changes or errors.{" "}
        </li>
        <li>
          Data security: We use appropriate technical and organisational measures to protect the
          Personal Data that we collect and process about you. The measures we use are designed to
          provide a level of security appropriate to the risk of processing your Personal Data.{" "}
        </li>
        <li>
          Data processors: We may engage third parties to process Personal Data for and on behalf of
          Tax Consult. We require such data processors to process Personal Data and act strictly on
          our instructions and to take appropriate steps to ensure that Personal Data remains
          protected.{" "}
        </li>
        <li>
          International data transfers: Our servers are located within the European Economic Area
          (EEA). However, we occasionally rely on external experts or consultants located outside
          the EEA. This means that when we collect your Personal Data, we may transfer it to the
          country where the expert or consultant is located. Such transfers are strictly necessary
          to provide you with the agreed contractual services and at your request (e.g. if your
          matter requires the intervention of an expert in a third country). Further information can
          be provided on request.
        </li>
        <li>
          Data Retention: We retain Personal Data we collect from you where we have an ongoing
          legitimate business need to do so (for example, to provide you with a service you have
          requested or to comply with applicable legal, tax or accounting requirements). <br />
          When we have no ongoing legitimate business need to process your Personal Data, we will
          either delete or anonymise it or, if this is not possible (for example, because your
          Personal Data has been stored in backup archives), then we will securely store your
          Personal Data and isolate it from any further processing until deletion is possible.
        </li>
      </ul>

      <h4>5. Your data protection rights</h4>
      <p>
        5.1 You have the following data protection rights which you can exercise at{" "}
        <a href="mailto:privacy@taxconsult.be">privacy@taxconsult.be</a> :
      </p>
      <ul>
        <li>
          The right to access, correct, update or request deletion of your Personal Data at any
          time.
        </li>
        <li>
          In addition, in certain circumstances, you can object to processing of your Personal Data,
          ask us to restrict processing of your Personal Data or request portability of your
          Personal Data.
        </li>
        <li>
          If we have collected and process your Personal Data with your consent, then you can
          withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness
          of any processing we conducted prior to your withdrawal, nor will it affect processing of
          your Personal Data conducted in reliance on lawful processing grounds other than consent.
        </li>
        <li>
          If you have a complaint or concern about how we are processing your Personal Data then we
          will endeavour to address such concern(s). If you feel we have not sufficiently addressed
          your complaint or concern, you have the right to complain to a data protection authority
          about our collection and use of your Personal Data. For more information, please contact
          your local data protection authority. (Contact details for data protection authorities in
          the European Economic Area are available here.)
        </li>
      </ul>

      <p>
        5.2 We respond to all requests we receive from individuals wishing to exercise their data
        protection rights in accordance with applicable data protection laws.
      </p>

      <h4>6. Updates to this Notice</h4>
      <p>
        6.1 We may update this Notice from time to time in response to changing legal, technical or
        business developments. When we update our Notice, we will take appropriate measures to
        inform you, consistent with the significance of the changes we make. We will obtain your
        consent to any material Notice changes if and where this is required by applicable data
        protection laws.{" "}
      </p>
      <p>
        6.2 You can see when this Privacy Notice was last updated by checking the “last updated”
        date displayed at the top of this Notice.{" "}
      </p>
    </>
  );
};

export default PrivacyPolicy;
