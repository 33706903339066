import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  Label,
  Input as FInput,
  Button,
  FormFeedback,
  Alert,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";


// Service
import commonService from "../../../../services/common.service";
import { useTranslation } from "react-i18next";
import Input from "../../../../components/Form/Input";

const EmailCategoryForm = (props: any) => {
  let emailTemplateCategoryData = props.EmailCategoryData;
  const {t} = useTranslation();
  const [moduleList, setModuleList] = useState([]);
  const [query, setQuery] = useState("");
  const [intialData, setIntialData] = useState({
    id: "",
    module_type_id: '',
    email_category_id:"",
    email_template_category_value: '',
    color_code: '',
    submit_btn_code: 'save'
  });

  const validationSchema = Yup.object().shape({
    module_type_id: Yup.string()
      .required(t("The module name field is required.")),
    email_template_category_value: Yup.string()
      .required(t("The email category field is required."))
      .min(2, t("The email category must be at least 2 characters."))
      .max(200, t("The email category may not be greater than 200 characters.")),
  });




  const handleSubmit = (values: {
    id: string;
    module_type_id: string;
    email_category_id:string;
    email_template_category_value: string;
    color_code: string;
  }) => {
    props.onSubmitClick(values);
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: intialData,
    validationSchema: validationSchema,
    onSubmit: (values, { setErrors }) => {
      handleSubmit(values);
    },
  });


  useEffect(() => {
    setIntialData(preState => ({
      ...preState,
      id: (emailTemplateCategoryData && emailTemplateCategoryData.id) || "",
      module_type_id: (emailTemplateCategoryData && emailTemplateCategoryData.module_category_id) || "",
      email_category_id: (emailTemplateCategoryData && emailTemplateCategoryData.email_category_id) || "",
      email_template_category_value: (emailTemplateCategoryData && emailTemplateCategoryData.email_template_category_value) || "",
      color_code: (emailTemplateCategoryData && emailTemplateCategoryData.color_code) || "#000000",
    }));
  }, [emailTemplateCategoryData])



  useEffect(() => {
    if (!validation.isSubmitting) return;
    if (Object.keys(validation.errors).length > 0) {
      document
        .getElementById(Object.keys(validation.errors)[0])
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
      document
        .getElementById(Object.keys(validation.errors)[0])
        ?.focus({ preventScroll: true });
    }
  }, [validation.isSubmitting, validation.errors]);

  const getSelectOptions = async (tableName: string, fieldName: string) => {
    const response = await commonService.getSelectOptionsList(
      tableName,
      fieldName,
      query ? query : ""
    );
    return response.data.data;
  };

  const handleChange = (fieldName: string, fieldValue: string) => {
    setIntialData(preState => ({ ...preState, [fieldName]: fieldValue }));
  };

  const loadModuleList = async () => {
    const module_list: any = await getSelectOptions('tbl_master_module', 'module_value');
    setModuleList(module_list);
  }
  useEffect(() => {
    loadModuleList();
  }, [])

 

  return (
    <React.Fragment>
      <Modal
        size="md"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">{props.modalTitle}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label="Close"
          ></button>
        </div>

        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          autoComplete="off"
        >
          <ModalBody>
            {props.errorMsg ?
              <Alert color="danger">
                {props.errorMsg}

                {props.errorData.data ?
                  Object.keys(props.errorData.data).map(key => (
                    <li color="danger" key={key}>{props.errorData.data[key]}</li>
                  )) : null
                }
              </Alert> : null
            }

            <Input
              id="id"
              name="id"
              type="hidden"
              value={validation.values.id || ""}
              onChange={validation.handleChange}
            />
            <Input
              id="module_type_id"
              name="module_type_id"
              type="hidden"
              value={validation.values.module_type_id || ""}
              onChange={validation.handleChange}
            />

            <Row>
              <Col className="col-12">
                <div className="mb-3">
                  <Label className="form-label">
                    {t("Module Name")}{" "}
                    <span className="text-warning is_required">*</span>
                  </Label>
                  <div className="color_palette_main_div">
                    <Select
                      id="module_type_id"
                      name="module_type_id"
                      className="is-invalid"
                      options={moduleList}
                      placeholder={t("Module Name")}
                      value={
                        intialData.module_type_id
                          ? moduleList.find(
                            (option: any) => option.value === intialData.module_type_id
                          )
                          : ""
                      }
                      isSearchable={false}
                      isDisabled={false}
                      onBlur={() => validation.setFieldTouched("module_type_id", true)}
                      onChange={(e: any) => {
                        handleChange("module_type_id", e.value);
                      }}
                    />
                    {validation.touched.module_type_id && validation.errors.module_type_id ? (
                      <FormFeedback type="invalid">{validation.errors.module_type_id}</FormFeedback>
                    ) : null}

                    {/* <div className="color_palette_div">
                      <Input
                        id="color_code"
                        name="color_code"
                        type="color"
                        value={validation.values.color_code || ""}
                        className="color_palette"
                        onChange={validation.handleChange}
                      />
                    </div> */}
                  </div>
                </div>
              </Col>
              <Col className="col-12">
                <div className="mb-3">
                  {/* <Label className="form-label">
                    {t("Email template category")}{" "}
                     <span className="text-warning is_required">*</span> 
                  </Label> */}
                  <div className="color_palette_main_div">
                    <Input
                      id="email_template_category_value"
                      name="email_template_category_value"
                      className="form-control"
                      required
                      placeholder={t("Email template category")}
                      type="text"
                      label={t("Email template category")}
                      value={validation.values.email_template_category_value || ""}
                      onKeyUp={validation.handleChange}
                      onChange={validation.handleChange}
                      invalid={
                        validation.touched.email_template_category_value &&
                          validation.errors.email_template_category_value
                          ? true
                          : false
                      }
                      error={validation.errors.email_template_category_value}
                    touched={validation.touched.email_template_category_value}
                    />
                    <div className="color_palette_div color_palette_div_cu">
                      <Input
                        id="color_code"
                        name="color_code"
                        type="color"
                        value={validation.values.color_code || ""}
                        className="color_palette"
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              className="btn-block"
              type="submit"
              disabled={props.loading}
            >
              {props.loading ? (
                <span>
                  <i className="fa-solid fa-spinner fa-spin"></i>
                  <span>&nbsp; {t("Please wait...")}</span>
                </span>
              ) : (
                <span>{t("Submit")}</span>
              )}
            </Button>
            <Button
              color="danger"
              className="btn-block"
              type="reset"
              onClick={props.onCloseClick}
              disabled={props.loading}
            >
              {t("Cancel")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default EmailCategoryForm;
