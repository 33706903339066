import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Alert,
  NavItem,
  Nav,
} from "reactstrap";
import { toast } from "react-toastify";

// Import Breadcrumb
import Breadcrumbs from "../../components/Layout/Breadcrumb";

// Import DataTable
import DataTable from "../../components/Common/DataTable";
import { getDataTableQuery, getUserProfile } from "../../helpers/functions_helper";

import notificationService from "../../services/notification.service";
import { CommonAction } from "../../store/actions";
import { useTranslation } from "react-i18next";

const AllNotifications = (props: any) => {
  const { t } = useTranslation();
  const userProfile = getUserProfile();
  document.title = t("Notifications") + " | " + process.env.REACT_APP_NAME;

  const dispatch = useDispatch();
  const toastId = React.useRef(null) as any;
  const [loader, setLoader] = useState(true);
  const [loadingReadAll, setLoadingReadAll] = useState(false);

  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "#",
      label: t("Notifications"),
      active: true,
    },
  ];

  // DataTable ----------------------------------------------
  function renderHeaderHTML(column: any, colIndex: any) {
    return <span dangerouslySetInnerHTML={{ __html: column.text }} />;
  }
  function renderRowHTML(rowContent: any, row: any) {
    return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
  }

  const columns = [
    {
      dataField: "DT_RowIndex",
      text: t("Sr. No."),
      sort: false,
      search: false,
    },
    // {
    //   dataField: "id",
    //   text: t("ID"),
    //   sort: true,
    //   search: true,
    // },
    {
      dataField: "notification",
      text: t("Notification"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return row.is_deleted === true ? (
          <>
            {row.notification}
            {row.action !== "DELETE" && (
              <div>
                <span className="badge rounded-pill badge-soft-danger">{t("Record deleted")}</span>
              </div>
            )}
          </>
        ) : (
          <>
            <Link
              className="text-decoration-underline"
              to={
                row?.custom_array && JSON.parse(row?.custom_array)[0]?.notification_url
                  ? JSON.parse(row?.custom_array)[0]?.notification_url
                  : row?.module_app_id === 4
                  ? `/users/comments/${userProfile.USER_ID}/${userProfile.USER_SUB_ID}`
                  : row?.module_app_id === 8 && row?.module_main_id === 0
                  ? `/${row?.url}?filters={"is_filtered":true,"company_id":"","user_id":"${row?.created_by}"}`
                  : `/${row?.url_edit}${row?.module_main_id}/${row?.module_sub_id}`
              }
              // to={
              //   row?.custom_array && JSON.parse(row?.custom_array)[0]?.notification_url
              //     ? JSON.parse(row?.custom_array)[0]?.notification_url
              //     : "/" + row.url_edit + row.module_main_id + "/" + row.module_sub_id
              // }
              onClick={() => setViewed(row.is_read)}
            >
              {row?.custom_array && JSON.parse(row?.custom_array)[0]?.notification_title
                ? t(JSON.parse(row?.custom_array)[0]?.notification_title)
                : t(row.notification)}
              {/* {row.is_read === false && (
                <div>
                  <span className="badge rounded-pill badge-soft-primary">
                    <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">mark_email_unread</span>
                    Unread
                  </span>
                </div>
              )} */}
            </Link>
            {/* {row.is_read === false && (
              <div>
                <span className="badge rounded-pill badge-soft-primary me-2">
                  <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                    mark_email_unread
                  </span>
                  {t("Unread")}
                </span>
                <span
                  className="badge rounded-pill bg-primary btn-outline-primary me-2"
                  onClick={() => setReadUnread(row, true)}
                >
                  <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                    mark_email_read
                  </span>
                  {t("Mark as Read")}
                </span>
              </div>
            )} */}
          </>
        );
      },
    },
    {
      dataField: "custom_message",
      text: t("Custom message"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return t(row.custom_message);
      },
    },
    // {
    //   dataField: "custom_array",
    //   text: t("Action"),
    //   sort: true,
    //   search: true,
    // },
    {
      dataField: "time_elapsed",
      text: t("Time elapsed"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return t(row.time_elapsed);
      },
    },    
    {
      dataField: "",
      text: t("Mark As"),
      sort: false,
      search: false,
      formatter: (rowContent: any, row: any): any => {
        return row.is_read === false ? (
          <div>
            <span className="btn btn-pill btn-outline-primary btn-sm" onClick={() => setReadUnread(row, true)}>
              <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                mark_email_read
              </span>
              Read
            </span>
          </div>
        ) : (
          <div>
            <span className="btn btn-pill btn-outline-danger btn-sm" onClick={() => setReadUnread(row, false)}>
              <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                mark_email_unread
              </span>
              Unread
            </span>
          </div>
        )
      }
    },
  ];

  const [loadingListData, setLoadingListData] = useState(true);
  const [datatableData, setDatatableData] = useState<any>([]);
  const [datatableOptions, setDatatableOptions] = useState({
    draw: 1,
    page: 1,
    recordsTotal: 0,
    recordsFiltered: 0,
    sizePerPage: 20,
    searchText: "",
    sortField: "id",
    sortOrder: "desc",
  });

  const loadingDatatable = async () => {
    try {
      const res = (await notificationService.getNotificationsList(
        getDataTableQuery(datatableOptions, columns)
      )) as any;

      if (res.data.status === "success") {
        setDatatableData(res.data.data);

        const updatedDatatableOptions = {
          ...datatableOptions,
          recordsTotal: res.data.recordsTotal,
          recordsFiltered: res.data.recordsFiltered,
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingListData(false);
      } else {
        setDatatableData([]);
        setLoadingListData(false);
      }
    } catch (err) {
      setDatatableData([]);
      setLoadingListData(false);
    }
  };

  const onTableChange = (type: any, newState: any) => {
    let draw = datatableOptions.draw + 1;
    let page = 1;
    if (type == "search") {
      page = 1;
    } else {
      page = newState.page;
    }
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: draw,
      page: page,
      sizePerPage: newState.sizePerPage,
      searchText: newState.searchText,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
    };
    setDatatableOptions(updatedDatatableOptions);
    setLoadingListData(true);
  };

  const clearFilter = () => {
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: 1,
      page: 1,
      sizePerPage: 20,
      searchText: "",
      sortField: "id",
      sortOrder: "desc",
    };
    setDatatableOptions(updatedDatatableOptions);
    setLoadingListData(true);
  };

  const { getNotificationcount } = useSelector((state: any) => ({
    getNotificationcount: state.Common.GET_NOTIFICATION_COUNT_SUCCESS,
  }));

  const setViewed = (is_read: boolean) => {
    if (is_read === false) {
      dispatch(CommonAction.getNotificationCountSuccess({ data: getNotificationcount.data - 1 }));
    }
  };

  const setReadUnread = async (data: any, is_read: boolean) => {
    toastId.current = toast(t("Updating notification..."), {
      autoClose: false,
      isLoading: true,
    });

    try {
      const res = (await notificationService.setNotificationReadUnread({
        id: data.id,
        is_read: is_read,
      })) as any;
      if (res.data.status === "success") {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        dispatch(CommonAction.setNotificationFetched(false));
        setLoadingListData(true);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        setLoadingListData(true);
      }
    } catch (err) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: t("Something went wrong, please try again"),
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });

      setLoadingListData(true);
    }
  };

  const setReadAll = async () => {
    setLoadingReadAll(true);
    toastId.current = toast(t("Updating notification..."), {
      autoClose: false,
      isLoading: true,
    });

    try {
      const res = (await notificationService.setNotificationReadAll()) as any;
      if (res.data.status === "success") {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        dispatch(CommonAction.setNotificationFetched(false));
        setLoadingListData(true);
        setLoadingReadAll(false);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        setLoadingListData(true);
        setLoadingReadAll(false);
      }
    } catch (err) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: t("Something went wrong, please try again"),
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });

      setLoadingListData(true);
      setLoadingReadAll(false);
    }
  };

  useEffect(() => {
    if (loadingListData === true) {
      loadingDatatable();
    }
  }, [loadingListData]);

  useEffect(() => {
    setDatatableData([]);
    setLoadingListData(true);
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbTitle={t("Notifications")} breadcrumbItems={breadcrumbItems} />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <DataTable
                    columns={columns}
                    products={datatableData}
                    datatableOptions={datatableOptions}
                    filters={[]}
                    onTableChange={onTableChange}
                    loadingListData={loadingListData}
                    clearFilter={clearFilter}
                    settingTableName="notifications_table"
                    slug="default"
                    customButtons={
                      <>
                        {getNotificationcount.data > 0 && (
                          <Button
                            title={t("Mark as Read all")}
                            outline={false}
                            color={"primary"}
                            onClick={() => {
                              setReadAll();
                            }}
                            disabled={loadingReadAll}
                          >
                            {loadingReadAll ? (
                              <span className="align-middle material-symbols-outlined fw-light fs-4 fa-spin">
                                hourglass_empty
                              </span>
                            ) : (
                              <span className="align-middle material-symbols-outlined fw-light fs-4">
                                mark_email_read
                              </span>
                            )}
                          </Button>
                        )}
                      </>
                    }
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AllNotifications;
