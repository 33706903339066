import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Button,
} from "reactstrap";
import { vLookup } from "../../helpers/functions_helper";
import { formatCurrency } from "../../utils/formats";
import { useTranslation } from "react-i18next";

const SSSCModel_171 = (props: any) => {
  const { t } = useTranslation();
  let spCountryData = props.spCountryData;
  const TAX_RATE = props.TAX_RATE;
  const EXCHANGE_RATE = props.EXCHANGE_RATE;
  const PERSONAL_ALLOWANCE_P = props.PERSONAL_ALLOWANCE_P;
  const TAX_RATE_P = props.TAX_RATE_P;
  const STANDARD_DEDUCTION = props.STANDARD_DEDUCTION;

  const [COUNTRY_IDX, setCOUNTRY_IDX] = useState<number>();
  const [D_EMP_DIR, setD_EMP_DIR] = useState<number>(1);
  const [D_GROSS_COUNTRY, setD_GROSS_COUNTRY] = useState<number>(0);
  const [D_SS_COUNTRY, setD_SS_COUNTRY] = useState<number>(0);
  const [C_TAXES_COUNTRY, setC_TAXES_COUNTRY] = useState<number>(0);
  const [PERSONAL_ALLOWANCE_P_CALCULATED, setPERSONAL_ALLOWANCE_P_CALCULATED] = useState([]);
  const [C14, setC14] = useState<number>(0);
  const [TAX_RATE_P_CALCULATED, setTAX_RATE_P_CALCULATED] = useState([]);
  const [D_GROSS_COUNTRY_PLN, setD_GROSS_COUNTRY_PLN] = useState<number>(0);
  const [B33, setB33] = useState<number>(0);
  const [C_NET_TAXABLE_COUNTRY, setC_NET_TAXABLE_COUNTRY] = useState<number>(0);
  const [C_TAX_POLAND_EMPL_PLZ, setC_TAX_POLAND_EMPL_PLZ] = useState<number>(0);
  const [C_TAX_POLAND_EMPL_EUR, setC_TAX_POLAND_EMPL_EUR] = useState<number>(0);

  useEffect(() => {
    if (spCountryData) {
      const COUNTRY_DATA = spCountryData.find((data: any) => data.country_id === 171);

      if (COUNTRY_DATA) {
        setCOUNTRY_IDX(spCountryData.findIndex((data: any) => data.country_id === 171));
        setD_EMP_DIR(spCountryData.find((data: any) => data.country_id === 171)?.emp_dir);
        setD_GROSS_COUNTRY(
          spCountryData.find((data: any) => data.country_id === 171)?.D_GROSS_COUNTRY
        );
        setD_SS_COUNTRY(spCountryData.find((data: any) => data.country_id === 171)?.D_SS_COUNTRY);
      }
    }
  }, [spCountryData]);

  useEffect(() => {
    setC_TAXES_COUNTRY(D_GROSS_COUNTRY * (TAX_RATE / 100));
  }, [D_GROSS_COUNTRY]);

  useEffect(() => {
    setD_GROSS_COUNTRY_PLN(Number(D_GROSS_COUNTRY) / Number(EXCHANGE_RATE));
  }, [D_GROSS_COUNTRY, EXCHANGE_RATE]);

  useEffect(() => {
    setB33(Number(D_SS_COUNTRY) / Number(EXCHANGE_RATE));
  }, [D_SS_COUNTRY, EXCHANGE_RATE]);

  useEffect(() => {
    // =MAX(0;B31-B20-B33)
    setC_NET_TAXABLE_COUNTRY(
      Math.max(0, Number(D_GROSS_COUNTRY_PLN) - Number(STANDARD_DEDUCTION) - Number(B33))
    );
  }, [D_GROSS_COUNTRY_PLN, STANDARD_DEDUCTION, B33]);

  useEffect(() => {
    if (PERSONAL_ALLOWANCE_P && PERSONAL_ALLOWANCE_P.length > 0) {
      var newData: any = [...PERSONAL_ALLOWANCE_P];
      newData.map((item: any, index: number) => {
        if (index !== 0) {
          newData[index][0] = newData[index - 1][1];
        }
      });

      // newData[0][3] = 0;

      // =834,88*(C_NET_TAXABLE_POLAND-C8)/5000
      newData[1][3] = 834.88 * ((Number(C_NET_TAXABLE_COUNTRY) - Number(newData[0][1])) / 5000);

      // newData[2][3] = 0;

      // =E10*(C_NET_TAXABLE_POLAND-C10)/41472
      newData[3][3] =
        Number(newData[2][3]) * ((Number(C_NET_TAXABLE_COUNTRY) - Number(newData[2][1])) / 41472);

      // newData[4][3] = 0;

      setPERSONAL_ALLOWANCE_P_CALCULATED(newData);
    }
  }, [PERSONAL_ALLOWANCE_P, C_NET_TAXABLE_COUNTRY]);

  useEffect(() => {
    if (TAX_RATE_P && TAX_RATE_P.length > 0) {
      var newData: any = [...TAX_RATE_P];
      newData[0][1] = C14;

      newData.map((item: any, index: number) => {
        if (index !== 0) {
          newData[index][0] = newData[index - 1][1];
        }
      });

      newData[0][3] = 0;

      // =(C16-B16)*D16
      newData[1][3] =
        (Number(newData[0][1]) - Number(newData[0][0])) * (Number(newData[0][2]) / 100);

      // =(C17-B17)*D17
      newData[2][3] =
        (Number(newData[1][1]) - Number(newData[1][0])) * (Number(newData[1][2]) / 100);

      setTAX_RATE_P_CALCULATED(newData);
    }
  }, [TAX_RATE_P, C14]);

  useEffect(() => {
    if (TAX_RATE_P && TAX_RATE_P.length > 0) {
      // =VLOOKUP(C_NET_TAXABLE_POLAND;B16:E18;4)+(C_NET_TAXABLE_POLAND-VLOOKUP(C_NET_TAXABLE_POLAND;B16:E18;1))*VLOOKUP(C_NET_TAXABLE_POLAND;B16:E18;3)
      const VLOOKUP_4 = vLookup(C_NET_TAXABLE_COUNTRY, TAX_RATE_P, 4);
      const VLOOKUP_3 = vLookup(C_NET_TAXABLE_COUNTRY, TAX_RATE_P, 3);
      const VLOOKUP_1 = vLookup(C_NET_TAXABLE_COUNTRY, TAX_RATE_P, 1);

      setC_TAX_POLAND_EMPL_PLZ(
        Number(VLOOKUP_4) +
          (Number(C_NET_TAXABLE_COUNTRY) - Number(VLOOKUP_1)) * (Number(VLOOKUP_3) / 100)
      );
    }
  }, [C_NET_TAXABLE_COUNTRY, TAX_RATE_P]);

  useEffect(() => {
    setC_TAX_POLAND_EMPL_EUR(Number(C_TAX_POLAND_EMPL_PLZ) * Number(EXCHANGE_RATE));
  }, [C_TAX_POLAND_EMPL_PLZ, EXCHANGE_RATE]);

  useEffect(() => {
    if (COUNTRY_IDX) {
      props.onCalculationUpdate({
        COUNTRY_IDX: COUNTRY_IDX,
        C_TAXES_COUNTRY: D_EMP_DIR === 1 ? C_TAX_POLAND_EMPL_EUR : C_TAXES_COUNTRY,
      });
    }
  }, [COUNTRY_IDX, D_EMP_DIR, C_TAXES_COUNTRY, C_TAX_POLAND_EMPL_EUR]);

  return (
    <React.Fragment>
      <Modal
        size="xl"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">{t("POLAND")}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label={t("Close")}
          ></button>
        </div>

        <ModalBody>
          <Card>
            <CardHeader>
              <CardTitle>1. {t("PARAMETERS")}</CardTitle>
            </CardHeader>

            <CardBody>
              <div className="table-responsive mb-3">
                <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr>
                      <td>{t("Tax rate for non-resident taxpayers")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(TAX_RATE).toFixed(2), false)}%
                      </td>
                      <td></td>
                    </tr>

                    <tr>
                      <td colSpan={3}></td>
                    </tr>

                    <tr>
                      <td>{t("Exchange rate")}: PLN 1 = EUR</td>
                      <td className="text-end">
                        {formatCurrency(Number(EXCHANGE_RATE).toFixed(4), false)}
                      </td>
                      <td>{t("Average exchange rate")} 01/01/2020 - 31/12/2020</td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              <div className="table-responsive mb-3">
                <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr className="align-bottom">
                      <th colSpan={4} className="text-center">
                        {t("Personal allowance")}
                      </th>
                    </tr>
                    {PERSONAL_ALLOWANCE_P_CALCULATED &&
                      PERSONAL_ALLOWANCE_P_CALCULATED.map((item: any, idx: number) => (
                        <tr key={idx} className="align-bottom">
                          <td className="text-end">
                            {formatCurrency(parseFloat(item[0]).toFixed(2), false)}
                          </td>
                          <td className="text-end">
                            {formatCurrency(parseFloat(item[1]).toFixed(2), false)}
                          </td>
                          <td className="text-end">
                            {formatCurrency(parseFloat(item[2]).toFixed(2), false)}%
                          </td>
                          <td className="text-end">
                            {formatCurrency(parseFloat(item[3]).toFixed(2), false)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>

              <div className="table-responsive mb-3">
                <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr>
                      <td>{t("Personal allowance according to current input")}</td>
                      <td className="text-end">{formatCurrency(Number(C14).toFixed(2), false)}</td>
                      <td>{t("NOT ENOUGH INFO PERSONAL ALLOWANCE PUT AT EUR 0")}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              <div className="table-responsive mb-3">
                <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr className="align-bottom">
                      <th colSpan={4} className="text-center">
                        {t("Polish tax")}
                      </th>
                    </tr>
                    {TAX_RATE_P_CALCULATED &&
                      TAX_RATE_P_CALCULATED.map((item: any, idx: number) => (
                        <tr key={idx} className="align-bottom">
                          <td className="text-end">
                            {formatCurrency(parseFloat(item[0]).toFixed(2), false)}
                          </td>
                          <td className="text-end">
                            {formatCurrency(parseFloat(item[1]).toFixed(2), false)}
                          </td>
                          <td className="text-end">
                            {formatCurrency(parseFloat(item[2]).toFixed(2), false)}%
                          </td>
                          <td className="text-end">
                            {formatCurrency(parseFloat(item[3]).toFixed(2), false)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>

              <div className="table-responsive mb-3">
                <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr>
                      <td>{t("Standard deduction")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(STANDARD_DEDUCTION).toFixed(2), false)}
                      </td>
                      <td>
                        {t(
                          "More than 1 employer + residence employee not where employer is located"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>2. {t("COMPUTATION FOR DIRECTORS")}</CardTitle>
            </CardHeader>

            <CardBody>
              <div className="table-responsive mb-3">
                <Table className="table table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr>
                      <td>
                        {t("GROSS")} {t("POLAND")}
                      </td>
                      <td className="text-end">
                        {formatCurrency(Number(D_GROSS_COUNTRY).toFixed(2), false)}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {t("TAX")} {t("POLAND")}
                      </td>
                      <td className="text-end">
                        {formatCurrency(Number(C_TAXES_COUNTRY).toFixed(2), false)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>3. {t("COMPUTATION FOR EMPLOYEES")}</CardTitle>
            </CardHeader>

            <CardBody>
              <div className="table-responsive mb-3">
                <Table className="table table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr>
                      <td>
                        {t("GROSS")} {t("POLAND")}
                      </td>
                      <td className="text-end">
                        {formatCurrency(Number(D_GROSS_COUNTRY).toFixed(2), false)}
                      </td>
                      <td>{t("Gross income in")} EUR</td>
                    </tr>
                    <tr>
                      <td>
                        {t("TAX")} {t("POLAND")}
                      </td>
                      <td className="text-end">
                        {formatCurrency(Number(D_GROSS_COUNTRY_PLN).toFixed(2), false)}
                      </td>
                      <td>{t("Gross income in")} PLN</td>
                    </tr>

                    <tr>
                      <td colSpan={3}></td>
                    </tr>

                    <tr>
                      <td>{t("SOCIAL SECURITY")}</td>
                      <td className="text-end">{formatCurrency(Number(B33).toFixed(2), false)}</td>
                      <td>{t("Social security on Polish part IN PLZ")}</td>
                    </tr>

                    <tr>
                      <td colSpan={3}></td>
                    </tr>

                    <tr>
                      <td>{t("NET TAXABLE")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_NET_TAXABLE_COUNTRY).toFixed(2), false)}
                      </td>
                      <td>{t("Taxable income")}</td>
                    </tr>

                    <tr>
                      <td colSpan={3}></td>
                    </tr>

                    <tr>
                      <td>{t("TAX")} EMPL</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_TAX_POLAND_EMPL_PLZ).toFixed(2), false)}
                      </td>
                      <td>{t("Tax in")} PLZ</td>
                    </tr>

                    <tr>
                      <td colSpan={3}></td>
                    </tr>

                    <tr>
                      <td>{t("TAX")} EMPL</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_TAX_POLAND_EMPL_EUR).toFixed(2), false)}
                      </td>
                      <td>{t("Tax")} in EUR</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </ModalBody>

        <ModalFooter>
          <Button color="danger" className="btn-block" type="reset" onClick={props.onCloseClick}>
            {t("Close")}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default SSSCModel_171;
