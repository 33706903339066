import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    Form,
    Button,
    FormFeedback,
    Alert,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import commonService from "../../../services/common.service";
import Input from "../../../components/Form/Input";
import Select from "../../../components/Form/Select/Select";
import { useTranslation } from "react-i18next";

type VatType = {
    id?: number | null;
    vat_id?: string | null;
    country_id: number | null;
    vat: number | null;
    special_rate: number | null;
    super_reduced_rate: number | null;
    zero_rate: number | null;
    parking_rate: number | null;
    reverse_charge?: number | null;
};


const VatForm = (props: any) => {

    const { t } = useTranslation();

    let vatData = props.vatData;

    const [intialData, setIntialData] = useState<VatType>({
        country_id: null,
        vat: 0,
        special_rate: 0,
        super_reduced_rate: 0,
        zero_rate: 0,
        parking_rate: 0,
        reverse_charge: 0
    });

    const [countries, setCountries] = useState([]);
    const [query, setQuery] = useState("");

    const validationSchema = Yup.object().shape({
        country_id: Yup.number().nullable().required("Country field is required."),
        vat: Yup.number().nullable().required("Standard rate field is required."),

    });


    const handleChange = (fieldName: string, fieldValue: string) => {
        setIntialData({ ...intialData, [fieldName]: fieldValue });
    };

    const handleSubmit = (values: VatType, actions: any) => {
        props.onSubmitClick(values, actions);
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: intialData,
        validationSchema: validationSchema,
        onSubmit: (values, actions) => {
            handleSubmit(values, actions);
        },
    });

    const getSelectOptions = async (tableName: string, fieldName: string, otherField?: Array<string>) => {
        const response = await commonService.getSelectOptionsList(
            tableName,
            fieldName,
            query ? query : "",
            otherField
        );
        return response.data.data;
    };

    useEffect(() => {
        getSelectOptions("tbl_master_country", "name").then(function (
            options
        ) {
            setCountries(options);
        });
    }, []);

    useEffect(() => {
        setIntialData(vatData);
    }, [vatData])

    // useEffect(() => {
    //     if (!validation.isSubmitting) return;
    //     if (Object.keys(validation.errors).length > 0) {
    //         document
    //             .getElementById(Object.keys(validation.errors)[0])
    //             ?.scrollIntoView({ behavior: "smooth", block: "center" });
    //         document
    //             .getElementById(Object.keys(validation.errors)[0])
    //             ?.focus({ preventScroll: true });
    //     }
    // }, [validation.isSubmitting, validation.errors]);

    return (
        <React.Fragment>
            <Modal
                size="md"
                isOpen={props.show}
                toggle={props.onCloseClick}
                backdrop="static"
                keyboard={false}
                autoFocus={false}
            >
                <div className="modal-header">
                    <h5 className="modal-title">{props.modalTitle}</h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={props.onCloseClick}
                        aria-label="Close"
                    ></button>
                </div>

                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                    autoComplete="off"
                >
                    <ModalBody>
                        {props.errorMsg ? (
                            <Alert color="danger">
                                {props.errorMsg}

                                {props.errorData.data
                                    ? Object.keys(props.errorData.data).map((key) => (
                                        <li color="danger" key={key}>
                                            {props.errorData.data[key]}
                                        </li>
                                    ))
                                    : null}
                            </Alert>
                        ) : null}

                        <Input
                            id="id"
                            name="id"
                            type="hidden"
                            value={validation.values.id || ""}
                            onChange={validation.handleChange}
                        />
                        <Input
                            id="fee_id"
                            name="fee_id"
                            type="hidden"
                            value={validation.values.vat_id || ""}
                            onChange={validation.handleChange}
                        />

                        <Row>
                            <Col className="col-12">
                                <div className="mb-3">
                                    <Select
                                        id="country_id"
                                        name="country_id"
                                        label="Country"
                                        required
                                        className={validation?.touched?.country_id && validation?.errors?.country_id ? "is-invalid" : ""}
                                        options={countries?.map((a: any) => ({ ...a, label: t(a.label) }))}
                                        //@ts-ignore
                                        defaultValue={countries.find((o: any) => o.value === validation?.values?.country_id)}
                                        value={countries.find((o: any) => o.value === validation?.values?.country_id)}
                                        isSearchable={true}
                                        // isDisabled={loadingCompany || isDisabled}
                                        onChange={(option: any) => {
                                            handleChange("country_id", option.value);

                                        }}
                                        touched={true}
                                        error={validation?.errors?.country_id}
                                    />
                                </div>
                            </Col>

                            <Col className="col-12">
                                <div className="mb-3">
                                    <Input
                                        id="vat"
                                        name="vat"
                                        label="Standard rate"
                                        required
                                        className="form-control right-align"
                                        placeholder="VAT %"
                                        type="text"
                                        value={validation.values.vat || ""}
                                        onBlur={validation.handleBlur}
                                        onChange={(e) =>
                                            handleChange("vat", e.target.value)
                                        }
                                        invalid={
                                            validation?.touched?.vat &&
                                                validation?.errors?.vat
                                                ? true
                                                : false
                                        }
                                        touched={true}
                                        error={validation?.errors?.vat}
                                    />
                                </div>
                            </Col>

                            <Col className="col-12">
                                <div className="mb-3">
                                    <Input
                                        id="special_rate"
                                        name="special_rate"
                                        label="Special rate"
                                        className="form-control right-align"
                                        placeholder="VAT %"
                                        type="text"
                                        value={validation.values.special_rate || ""}
                                        onBlur={validation.handleBlur}
                                        onChange={(e) =>
                                            handleChange("special_rate", e.target.value)
                                        }
                                        invalid={
                                            validation?.touched?.special_rate &&
                                                validation?.errors?.special_rate
                                                ? true
                                                : false
                                        }
                                        touched={true}
                                        error={validation?.errors?.special_rate}
                                    />
                                </div>
                            </Col>

                            <Col className="col-12">
                                <div className="mb-3">
                                    <Input
                                        id="super_reduced_rate"
                                        name="super_reduced_rate"
                                        label="Super-reduced rate"
                                        className="form-control right-align"
                                        placeholder="VAT %"
                                        type="text"
                                        value={validation.values.super_reduced_rate || ""}
                                        onBlur={validation.handleBlur}
                                        onChange={(e) =>
                                            handleChange("super_reduced_rate", e.target.value)
                                        }
                                        invalid={
                                            validation?.touched?.super_reduced_rate &&
                                                validation.errors?.super_reduced_rate
                                                ? true
                                                : false
                                        }
                                        touched={true}
                                        error={validation?.errors?.super_reduced_rate}
                                    />
                                </div>
                            </Col>

                            <Col className="col-12">
                                <div className="mb-3">
                                    <Input
                                        id="zero_rate"
                                        name="zero_rate"
                                        label="Zero rate"
                                        className="form-control right-align"
                                        placeholder="VAT %"
                                        type="text"
                                        value={validation.values.zero_rate || ""}
                                        onBlur={validation.handleBlur}
                                        onChange={(e) =>
                                            handleChange("zero_rate", e.target.value)
                                        }
                                        invalid={
                                            validation.touched?.zero_rate &&
                                                validation.errors?.zero_rate
                                                ? true
                                                : false
                                        }
                                        ttouched={true}
                                        error={validation?.errors?.zero_rate}
                                    />
                                </div>
                            </Col>

                            <Col className="col-12">
                                <div className="mb-3">
                                    <Input
                                        id="parking_rate"
                                        name="parking_rate"
                                        label="Parking rates"
                                        className="form-control right-align"
                                        placeholder="VAT %"
                                        type="text"
                                        value={validation.values.parking_rate || ""}
                                        onBlur={validation.handleBlur}
                                        onChange={(e) =>
                                            handleChange("parking_rate", e.target.value)
                                        }
                                        invalid={
                                            validation.touched?.parking_rate &&
                                                validation.errors?.parking_rate
                                                ? true
                                                : false
                                        }
                                        touched={true}
                                        error={validation?.errors?.parking_rate}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            className="btn-block"
                            type="submit"
                            disabled={validation.isSubmitting}
                        >
                            {validation.isSubmitting ? (
                                <span>
                                    <i className="fa-solid fa-spinner fa-spin"></i>
                                    <span>&nbsp; {t("Please wait...")}</span>
                                </span>
                            ) : (
                                <span>{t("Submit")}</span>
                            )}
                        </Button>
                        <Button
                            color="danger"
                            className="btn-block"
                            type="reset"
                            onClick={props.onCloseClick}
                            disabled={props.loading}
                        >
                            {t("Cancel")}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </React.Fragment>
    );
};

export default VatForm;
