import React from "react";
import { Row, Col } from "reactstrap";

import logo from "../../assets/images/tc-logo.png";

const Header = (props: any) => {
    return (
        <React.Fragment>
            <Row>
                <Col><img src={logo} alt='logo' width='120px' /></Col>
                <Col><h1 style={{ color: '#c8222a', fontSize: '28px', textAlign: 'right' }}>HR Tax & IM</h1></Col>
            </Row>
        </React.Fragment>
    );
};

export default Header;