interface Position {
  top: number;
  left: number;
}

export function ggID(): () => number {
  let id = 0;
  return function genId() {
    return id++;
  };
}

export const getMovePosition = (
  x: number,
  y: number,
  dragX: number,
  dragY: number,
  width: number,
  height: number,
  pageWidth: number,
  pageHeight: number
): Position => {
  const newPositionTop = y + dragY;
  const newPositionLeft = x + dragX;
  const newPositionRight = newPositionLeft + width;
  const newPositionBottom = newPositionTop + height;

  const top =
    newPositionTop < 0
      ? 0
      : newPositionBottom > pageHeight
      ? pageHeight - height
      : newPositionTop;
  const left =
    newPositionLeft < 0
      ? 0
      : newPositionRight > pageWidth
      ? pageWidth - width
      : newPositionLeft;

  return {
    top,
    left,
  };
};

export const base64ToFile = (base64Image: any, fileName: any, fileType = 'image/png') =>{
  // Remove the data URI scheme (data:image/png;base64,) from the base64 string
  const base64ImageWithoutHeader = base64Image.replace(/^data:image\/(png|jpeg|jpg);base64,/, '');

  // Convert the base64 string to a Blob object
  const byteCharacters = atob(base64ImageWithoutHeader);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: fileType });

  // Create a File object from the Blob
  const file = new File([blob], fileName, { type: fileType });

  return file;
}


export const base64ToBlob = (base64DataUrl: any)=>{
  const base64Data = base64DataUrl.split(",")[1];
  const binaryData = atob(base64Data);
  const arrayBuffer = new ArrayBuffer(binaryData.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }

  const mimeType = base64DataUrl.match(/:(.*?);/)[1];
  return new Blob([uint8Array], { type: mimeType });
}

export const removeDataURLPrefix = (base64String: any)=> {
  const dataURLPrefix = "data:";
  const commaIndex = base64String.indexOf(",");
  
  if (commaIndex !== -1 && base64String.startsWith(dataURLPrefix)) {
    return base64String.slice(commaIndex + 1);
  }
  
  return base64String;
}

export const createImageElementFromBase64 = async (base64String: any, altText = "Image")=> {
  const img = new Image();
  img.src = base64String;
  img.alt = altText;
  return img;
}


export async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {

  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, { type: 'image/png' });
}

export const normalize = (value: number): number =>
  parseFloat((value / 255).toFixed(1));
