import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import { map, findIndex, find, filter, debounce,some } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import {
  getStarredMails as onGetStarredMails,
  getImportantMails as onGetImportantMails,
  getFolderList as onGetFolderList,
  setDraftEmailData,
  setDraftMailForTask,
  getFolderMessage,
  clearFolderMessageFail
} from "../../store/mails/actions";
import { getUserProfile } from "../../helpers/functions_helper";

//Import Email Topbar
import EmailToolbar from "./EmailToolbar";
import Pagination from "./components/Pagination";

import { useIsAuthenticated } from "@azure/msal-react";
import { MaterialIcon } from "./components/styles";
import MailList from "./components/MailList";
import SearchMailList from "./components/SearchMailList";
import { MailFolder } from "./components/type";
import emailService from "../../services/email.service";
import "./styles.scss"

// Service
import commonService from "../../services/common.service";
import SimpleBar from "simplebar-react";
import Layout from "./Layout";

import { useTranslation } from "react-i18next";


const EmailInbox = (props: any) => {
  document.title = "Email | " + process.env.REACT_APP_NAME;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userProfile = getUserProfile("email");
  const [mailcount, setMailCount] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(10);
  const [pageN, setPageN] = useState<number>(1);
  const query = { query: `&top=10&skip=0` };
  const isAzureAuthenticated = useIsAuthenticated();
  const [mailFolder, setMailFolder] = useState(MailFolder);
  const [selectedMail, setSelectedMail] = useState<number>(0);
  const [sticky, setSticky] = useState("");

  const [activeTab, setactiveTab] = useState(1);
  const [message, setMessage] = useState<any>([]);
  const [search, setSearch] = useState<boolean>(false);
  const [folderlist, setFolderList] = useState<any>({ value: [] })
  const {
    error,
    mails,
  } = useSelector((state: any) => ({
    error: state.Mails.error,
    mails: state.Mails.message
  }));

  let navigate = useNavigate();

  const getMailFolder = async () => {
    const res = await emailService.getFolderList();
    if (res?.status === 200) {
      setFolderList(res?.data);
    }
  }
  useEffect(() => {
    getMailFolder();
  }, [])

  useEffect(() => {
    if (folderlist?.value.length > 0) {
      const item = find(folderlist?.value, (item) => item.displayName === 'Inbox');
      if (item) {
        setactiveTab(item?.id);
        setMailCount(item?.totalItemCount)
        setCount(item?.totalItemCount);
        const post_query = {
          folder_id: item?.id,
          query: `&top=10&skip=${page - 10}`
        };
        dispatch(clearFolderMessageFail({}))
        dispatch(getFolderMessage(post_query));
      }

    }
  }, [folderlist?.value])

  const getMessage = async (params: string) => {
    if (folderlist?.value.length > 0 && activeTab !== 1) {
      const post_query = {
        folder_id: activeTab,
        query: params
      };
      dispatch(getFolderMessage(post_query));
    }
  }

  useEffect(() => {
    console.log(error);
  }, [error])

  useEffect(() => {
    const top = `&top=10&skip=${page - 10}`;
    getMessage(top);
  }, [page])


  useEffect(() => {
    if (mails?.value) {
      let message = [];//receivedDateTime
      message = mails?.value?.map((item: any) => ({ ...item, checked: false }));
      const arrange = localStorage.getItem('reading_conf');
      if (arrange === '2') {
        message.sort((a: any, b: any) => {
          var dateA = new Date(a.receivedDateTime).getTime();
          var dateB = new Date(b.receivedDateTime).getTime();
          return dateA > dateB ? 1 : -1;
        });
      }
      setMessage(message);
    }
  }, [mails]);


  useEffect(() => {
    if (folderlist) {
      mailFolder.map((item, i) => {
        mailFolder[i].totalItemCount = folderlist?.value?.filter((item2: any) => item2?.displayName === item?.displayName)[0]?.totalItemCount;
        //mailFolder[i].id = folderlist?.value?.filter((item2: any) => item2?.displayName === item?.displayName)[0]?.id;
      });

      folderlist?.value?.map((item: any) => {
        const dataindex = findIndex(mailFolder, (i: any) => i.displayName === item.displayName);
        let item2 = item;
        if (item2?.displayName) {
          item2['query'] = item?.displayName?.split(' ')?.join('')?.toLowerCase();
        }
        if (dataindex === -1) {
          mailFolder.push(item2);
        }
      })
      setMailFolder(mailFolder);
    }
  }, [folderlist])

  // next mail
  function nextMail() {
    const page_count = Math.round(count / 10);
    console.log(page_count);
    if (page <= page_count) {
      setPageN(preState => preState + 1);
      setPage(preState => preState + 10);
    }
  }

  // previous mail
  function preMail() {
    const page_count = Math.round(count / 10);
    console.log(page_count);
    if (page <= page_count && (page - 10) > 0) {
      setPageN(preState => preState - 1);
      setPage(preState => preState - 10);
    }
  }

  //Searching email
  const searchEmail = async (keyword: string) => {
    const res: any = await emailService.searchMessage(keyword);
    if (res?.code === "InvalidAuthenticationToken") {
      //console.log(res?.code);
    } else {
      if (res?.data?.value) {
        setMessage(res.data.value[0]?.hitsContainers[0]?.hits);
      }
      setSearch(true);
    }
  }

  const debouncedResults = useMemo(() => {
    return debounce(searchEmail, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    }
  }, [])

  const NewEmail = () => {
    dispatch(setDraftEmailData({ openMailBox: 1 }));
  };

  const refreshMail = () => {
    setSearch(false);
    const top = `&top=10&skip=0`;
    getMessage(top);
  }

  const getMailFolderData = (folder: any) => {
    setactiveTab(folder.id);
    setCount(folder.totalItemCount);
    setSearch(false);
    setPage(10);
    setPageN(1);
    const post_query = {
      folder_id: folder.id,
      query: ''
    };
    setMessage({});
    dispatch(clearFolderMessageFail({}))
    dispatch(getFolderMessage(post_query));
  }

  const selectMail = (mail: any) => {
    let item = find(message, function (o: any) { return o.id === mail; });
    if (item?.checked) {
      item.checked = false;
      setSelectedMail(preState => preState - 1);
    } else {
      item.checked = true;
      setSelectedMail(preState => preState + 1);
    }
  }

  const selectAll = () => {
    const msg = map(message, (o) => {
      return {
        ...o, checked: (o?.checked == true) ? false : true
      }
    });
    setMessage(msg);

    let item = some(message, (m)=>m.checked==true);
    if (item) {
      setSelectedMail(preState => preState - 1);
    } else {
      setSelectedMail(preState => preState + 1);
    }
  }

  const handleDeleteMail = async () => {
    const mail_list = filter(message, (item) => item.checked === true);
    if (selectedMail > 0 && mail_list) {
      const request = map(mail_list, async (item) => {
        return emailService.deleteInboxMail(item.id);
      });
      const response = Promise.all(request).then(data => {
        setSelectedMail(0);
        refreshMail();
      }).catch(erro => {

      })
    }
  }

  const handleCreateTask = () => {
    if (selectedMail === 1) {
      const item = find(message, (item: any) => item.checked === true);
      const xdata = {
        title: item.subject,
        body: item.bodyPreview
      }
      dispatch(setDraftMailForTask(xdata));
      return navigate("/tasks/new-task");
    }
  }

  const handleReadUnread = async () => {
    if (selectedMail > 0) {
      var is_read: boolean = false;
      const mail_list = filter(message, (item) => item.checked === true);
      map(mail_list, (mail) => {
        if (mail.isRead === true) {
          is_read = false;
        }
        if (mail.isRead === false) {
          is_read = true;
        }
      });

      const request = map(mail_list, async (item) => {
        if (is_read === true) {
          const postData = {
            isRead: true, id: item.id
          }
          return emailService.readUnreadMail(postData);
        } else {
          const postData = {
            isRead: false, id: item.id
          }
          return emailService.readUnreadMail(postData);
        }
      });

      const response = Promise.all(request).then(data => {
        setSelectedMail(0);
        refreshMail();
      }).catch(erro => {

      })

    }
  }

  const handleMoveMessage = async (data: any) => {
    if (selectedMail > 0) {
      const mail_list = filter(message, (item) => item.checked === true);
      const request = map(mail_list, async (item: any, i: number) => {
        if (item) {
          const postData = {
            id: item.id,
            destinationId: data.id
          };
          return emailService.moveMessageToFolder(postData);
        }
      });

      const response = Promise.all(request).then(data => {
        setSelectedMail(0);
        refreshMail();
      }).catch(erro => {

      })

    }
  }

  // on render, set listener
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 66 ? "is-sticky" : "";
    setSticky(stickyClass);
  };

  /* Module */
  const [moduleOptions, setModuleOptions] = useState([]);
  const getSelectOptions = async (tableName: string, fieldName: string) => {
    const response = await commonService.getSelectOptionsList(
      tableName,
      fieldName,
      ""
    );
    return response.data.data;
  };

  useEffect(() => {
    getSelectOptions("tbl_master_module", "module_value").then(function (options) {
      setModuleOptions(options);
    });
  }, []);


  const searchKeyword = (keyword: any) => {
    searchEmail(keyword?.label);
  }

  const [isMoveTask, setIsMoveTask] = useState<boolean>(false);

  const readerConf = () => {
    refreshMail();
    setIsMoveTask(false)
  }

  return (
    <React.Fragment>
      {userProfile.CAN_VIEW ? <div className="page-content">
        <Container fluid>
          <Row className={`align-items-center ${sticky}`}>
            <Col sm={12} className="p-2 bg-white pe-3 ps-3">
              <EmailToolbar
                searchEmail={debouncedResults}
                composeMail={NewEmail}
                refreshMail={refreshMail}
                selectedMail={selectedMail}
                handleDeleteMail={handleDeleteMail}
                folderList={folderlist}
                handleCreateTask={handleCreateTask}
                handleReadUnread={handleReadUnread}
                handleMoveMessage={handleMoveMessage}
                handleSelectAll={selectAll}
                layoutFilter={() => setIsMoveTask(preState => !preState)}
                t={t}
              />
            </Col>
          </Row>
          <Row>

            <div className="col-3 bg-white p-2">
              <div>
                <div className="d-flex flex-wrap align-items-center"><h6 className="m-0">Folders</h6></div>
                <SimpleBar style={{ height: 400 }}>
                  {folderlist && map(mailFolder, (folder: any, i) => (
                    <div className={`d-flex flex-row mb-2 p-2 ${activeTab === folder?.id ? 'mail-lista' : ''} `}
                      onClick={() => getMailFolderData(folder)}
                      key={i}
                    >
                      <div className="p-1 flex-grow-1"><MaterialIcon size={20}>{folder?.icon ? folder?.icon : 'folder'}</MaterialIcon><span className="ms-2">{folder.displayName}</span></div>
                      <div className="p-1 text-align-end">{folder?.totalItemCount}</div>
                    </div>
                  ))}
                </SimpleBar>
              </div>

              <div className="mt-3">
                <div className="d-flex flex-wrap align-items-center"><h6 className="m-0">Labels</h6></div>
                <SimpleBar style={{ height: 300 }}>
                  <div className="mail-list mt-1 ms-2">
                    {moduleOptions && map(moduleOptions, (item: any, i: number) => <Link to="#" onClick={() => searchKeyword(item)} key={i}>
                      <i className="fa-solid fa-circle-play text-primary float-end"></i>{' '}
                      {item?.label}
                    </Link>)}
                  </div>
                </SimpleBar>
              </div>
            </div>

            {(error['code'] === 'InvalidAuthenticationToken' || isAzureAuthenticated === false || error['code'] === "ErrorInvalidIdMalformed") ?
              <div className="col-9 p-0" >
                <div>
                  <Card style={{ height: '70vh' }}>
                    <div className="error-sso">
                      <div className="text-center">
                        <span className="material-symbols-outlined fw-light" style={{ fontSize: '5rem' }}>
                          vpn_key_alert
                        </span>
                      </div>
                      {error['message'] || <p>{t("Token Expire OR Your are not login with azure account")}</p>}
                    </div>
                  </Card>
                </div>
              </div>
              :
              <div className="col-9 p-0">
                <TabContent activeTab={1}>
                  <TabPane tabId={1}>
                    {
                      search ?
                        <div className="row">
                          {(typeof message !== 'undefined' && Object.keys(message).length !== 0) && map(message, (inbox: any, i: number) => (
                            <SearchMailList
                              data={inbox}
                              handleChecked={selectMail}
                              key={i}
                            />
                          ))}
                        </div>
                        : <div className="row">
                          {(typeof message !== 'undefined' && Object.keys(message).length !== 0) ? map(message, (inbox: any, i: number) => (
                            <MailList
                              data={inbox}
                              handleChecked={selectMail}
                              key={i}
                            />
                          )) : null
                          }
                        </div>
                    }
                  </TabPane>
                </TabContent>
                {
                  <Pagination
                    count={count}
                    currentPage={pageN}
                    nextPage={() => preMail()}
                    prePage={() => nextMail()}
                  />}
              </div>
            }
          </Row>
        </Container >

      </div > : null}
      {isMoveTask && (
        <Layout
          show={isMoveTask}
          onCloseClick={() => setIsMoveTask(false)}
          onNoClick={() => setIsMoveTask(false)}
          onYesClick={() => readerConf()}
          t={t}
        />
      )}
    </React.Fragment >
  );
};

export default EmailInbox;
