import React, { useState, useRef, useEffect } from 'react';
import { Text as Component } from '../components/Text';
import { getMovePosition } from '../utils/helpers';
import { DragActions, TextMode } from '../entities';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

// @ts-ignore
import CustomEditor from "ckeditor5-custom-build/build/ckeditor";
// @ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { ckEditorConfiguration } from '../../../../helpers/functions_helper';
import { useTranslation } from 'react-i18next';
interface Props {
    // keyIndex: number;
    // setWidth: any;
    // setHeight: any;
    // setPositions: any;
    // setText: any;
    // setNewAttachment: any;
    // attachment: any;
    // width?: number;
    // height?: number;
    // x?: number;
    // y?: number;
    // setNewIndex: any;
    updateTextAttachment: (textObject: Partial<TextAttachment>) => void;
    removeImage: any;
    // style?: any;
}

export const Text = ({
    text,
    width,
    height,
    x,
    y,
    textOnly,
    index,
    // setWidth,
    // setHeight,
    // setPositions,
    // setNewAttachment,
    // attachment,
    // setText,
    // setNewIndex,
    updateTextAttachment,
    removeImage,
    // style,
}: TextAttachment & Props) => {
    const { t } = useTranslation();
    const inputRef = useRef<HTMLInputElement>(null);
    const [content, setContent] = useState(text || '');
    const [textMode, setTextMode] = useState<TextMode>(TextMode.COMMAND);
    const [newwidth, setNewWidth] = useState(width);
    const [newheight, setNewHeight] = useState(height);
    const [newPosition, setNewPosition] = useState({ x: x, y: y });
    const [showcontent, setShowcontent] = useState(false);

    const toggleEditMode = () => {
        setContent(content);
        // const input = inputRef.current;
        // const mode =
        //   textMode === TextMode.COMMAND ? TextMode.INSERT : TextMode.COMMAND;

        // setTextMode(mode);

        // if (input && mode === TextMode.INSERT) {
        //   input.focus();
        //   input.select();
        // } else {
        //   prepareTextAndUpdate();
        // }
    };

    const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;
        setContent(value);
    };

    const handleDrag = (e: any, d: any) => {
        const newPosition = { x: d.x, y: d.y };
        setNewPosition(newPosition);
    };


    const handleResize = (e: any, direction: any, ref: any, delta: any, position: any) => {
        setNewWidth(ref.offsetWidth);
        setNewHeight(ref.offsetHeight);
    };

    useEffect(() => {
        updateTextAttachment({
            width: newwidth,
            height: newheight,
            x: newPosition.x,
            y: newPosition.y,
            text: content
        })
    }, [newwidth, newheight, newPosition, content])

    const editImage = () => {
        setShowcontent(true);
    }

    const CKinputHandler = (event: any, editor: any) => {
        setContent(editor.getData());
        console.log(editor);
    };

    const useContent = () => {
        setShowcontent(false);
        updateTextAttachment({
            textOnly: false
        })
    }

    return (
        <>
            <Component
                text={content}
                width={width}
                x={x}
                y={y}
                height={height}
                mode={textMode}
                //size={size}
                //lineHeight={lineHeight}
                inputRef={inputRef}
                //fontFamily={style.fontFamily}
                onChangeText={onChangeText}
                toggleEditMode={toggleEditMode}
                handleDrag={handleDrag}
                handleResize={handleResize}
                removeImage={removeImage}
                editImage={editImage}
                showContent={showcontent}
                textOnly={textOnly}
                index={index}
                //attachment={attachment}
            />
            {
                showcontent &&
                <Modal size="lg" isOpen={showcontent} toggle={() => setShowcontent(!showcontent)} centered={true}>
                    <div className="modal-header">
                        <h5 className="modal-title">
                            {t("Edit Text")}
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => setShowcontent(!showcontent)}
                            aria-label="Close"
                        ></button>
                    </div>
                    <ModalBody>
                        <div className="mb-3">
                            <CKEditor
                                editor={CustomEditor}
                                config={ckEditorConfiguration}
                                data={content}
                                onChange={CKinputHandler}
                                id="description_editor"
                                name="description"
                                className="form-control"
                            // onBlur={() => validation.setFieldTouched("description", true)}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button"
                            className="btn btn-primary btn-md ms-2"
                            onClick={useContent}
                        >
                            {t("Submit")}
                        </button>

                    </ModalFooter>
                </Modal >
            }
        </>
    );
};