import {
  GET_COMPANY_CATEGORY,
  GET_COMPANY_CATEGORY_SUCCESS,
  GET_COMPANY_CATEGORY_ERROR,

  GET_COMPANY_CATEGORY_LIST,
  GET_COMPANY_CATEGORY_LIST_SUCCESS,
  GET_COMPANY_CATEGORY_LIST_ERROR,

  CREATE_COMPANY_CATEGORY,
  CREATE_COMPANY_CATEGORY_SUCCESS,
  CREATE_COMPANY_CATEGORY_ERROR,

  UPDATE_COMPANY_CATEGORY,
  UPDATE_COMPANY_CATEGORY_SUCCESS,
  UPDATE_COMPANY_CATEGORY_ERROR,

  DELETE_COMPANY_CATEGORY,
  DELETE_COMPANY_CATEGORY_SUCCESS,
  DELETE_COMPANY_CATEGORY_ERROR,
} from './actionTypes';

// ---------------------------------------------GET_COMPANY_CATEGORY
export const getCompanyCategory = (companycategory: any, history: any) => {
  return {
    type: GET_COMPANY_CATEGORY,
    payload: { companycategory, history },
  };
};

export const getCompanyCategorySuccess = (data: {}) => {
  return {
    type: GET_COMPANY_CATEGORY_SUCCESS,
    payload: data,
  };
};

export const getCompanyCategoryError = (error: {}) => {
  return {
    type: GET_COMPANY_CATEGORY_ERROR,
    payload: error,
  };
};

// ---------------------------------------------GET_COMPANY_CATEGORY_LIST
export const getCompanyCategoryList = (query: any, history: any) => {
  return {
    type: GET_COMPANY_CATEGORY_LIST,
    payload: { query, history },
  };
};

export const getCompanyCategoryListSuccess = (data: {}) => {
  return {
    type: GET_COMPANY_CATEGORY_LIST_SUCCESS,
    payload: data,
  };
};

export const getCompanyCategoryListError = (error: {}) => {
  return {
    type: GET_COMPANY_CATEGORY_LIST_ERROR,
    payload: error,
  };
};

// ---------------------------------------------CREATE_COMPANY_CATEGORY
export const createCompanyCategory = (companycategory: any, history: any) => {
  return {
    type: CREATE_COMPANY_CATEGORY,
    payload: { companycategory, history },
  };
};

export const createCompanyCategorySuccess = (companycategory: {}) => {
  return {
    type: CREATE_COMPANY_CATEGORY_SUCCESS,
    payload: companycategory,
  };
};

export const createCompanyCategoryError = (error: {}) => {
  return {
    type: CREATE_COMPANY_CATEGORY_ERROR,
    payload: error,
  };
};

// ---------------------------------------------UPDATE_COMPANY_CATEGORY
export const updateCompanyCategory = (companycategory: any, history: any) => {
  return {
    type: UPDATE_COMPANY_CATEGORY,
    payload: { companycategory, history },
  };
};

export const updateCompanyCategorySuccess = (companycategory: {}) => {
  return {
    type: UPDATE_COMPANY_CATEGORY_SUCCESS,
    payload: companycategory,
  };
};

export const updateCompanyCategoryError = (error: {}) => {
  return {
    type: UPDATE_COMPANY_CATEGORY_ERROR,
    payload: error,
  };
};

// ---------------------------------------------DELETE_COMPANY_CATEGORY
export const deleteCompanyCategory = (companycategory: any, history: any) => {
  return {
    type: DELETE_COMPANY_CATEGORY,
    payload: { companycategory, history },
  };
};

export const deleteCompanyCategorySuccess = (companycategory: {}) => {
  return {
    type: DELETE_COMPANY_CATEGORY_SUCCESS,
    payload: companycategory,
  };
};

export const deleteCompanyCategoryError = (error: {}) => {
  return {
    type: DELETE_COMPANY_CATEGORY_ERROR,
    payload: error,
  };
};