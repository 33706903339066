import React, { memo,useMemo } from 'react';
import { Link } from "react-router-dom"
import Tooltip from '../../components/Tooltip/Tooltip';
import { useTranslation } from 'react-i18next';


const TaskPriority = (value?: any) => {
const {t} = useTranslation();
  const status_value = useMemo(()=>value.value,[]);
    switch (status_value) {
      case 1:
        return (
          <Tooltip content={t(value.tip)} direction="top">
            <Link to="#">
              <span className="align-middle material-symbols-outlined fw-light fs-4 text-success padding-left">arrow_downward</span>
            </Link>
          </Tooltip>
        );
  
      case 2:
        return (
          <Tooltip content={t(value.tip)} direction="top">
            <Link to="#">
              <span className="align-middle material-symbols-outlined fw-light fs-4 padding-left" style={{color:'#e97f33'}}>arrow_upward</span>
            </Link>
          </Tooltip>
        );
  
      case 3:
        return (
          <Tooltip content={t(value.tip)} direction="top">
            <Link to="#">
              <span className="align-middle material-symbols-outlined fw-light fs-4 padding-left" style={{color:'#e9494a'}}>arrow_upward</span>
            </Link>
          </Tooltip>
        );
      default:
        return (
          <Tooltip content={t(value.tip)} direction="top">
            <Link to="#">
              <span className="align-middle material-symbols-outlined fw-light fs-4 padding-left" style={{color:'#cd1317'}}>arrow_upward</span>
            </Link>
          </Tooltip>
        );
    }
}
  
export default memo(TaskPriority);