const TermsConditions = (props: any) => {
  return (
    <>
      <h3>1. Toepassingsgebied</h3>
      <p>
        Deze algemene voorwaarden (de "<strong>Algemene Voorwaarden</strong>") zijn op iedereen van
        toepassing diensten verleend of te leveren door of namens Tax Consult HR Tax &amp;
        International Mobility (hierna &laquo; <strong>TC HR Tax &amp; IM</strong> &raquo;).
      </p>
      <p>
        Bij gebreke van enige specifieke overeenkomst ter zake in de opdrachtbevestiging, zal de
        overeenkomst komt tot stand en treedt in werking:
      </p>
      <ul>
        <li>
          ofwel wanneer de professional de door de opdrachtgever ondertekende opdrachtbevestiging
          ontvangt, en ondertekent het op zijn beurt;
        </li>
        <li>
          of wanneer de professional met de uitvoering van de opdracht begint, op verzoek van de
          opdrachtgever, wanneer deze voorstelling begint op een eerder tijdstip wegens
          uitzonderlijke of dringende omstandigheden.
        </li>
      </ul>
      <p>
        De aanvaarding van de door TC HR Tax &amp; IM impliceert de acceptatie van de Algemene
        Voorwaarden, inclusief hun toekomstige evoluties, voor alle huidige of toekomstig dossier
        behandeld door TC HR Tax &amp; IK BEN.
      </p>
      <p>
        Wanneer de professional de door de opdrachtgever ondertekende opdrachtbevestiging nog niet
        heeft ontvangen, alles professionele betrekkingen tussen partijen zijn onderworpen aan deze
        Algemene Voorwaarden &amp; Voorwaarden en de opdrachtbrief, mits deze contractuele
        documenten zijn meegedeeld aan de opdrachtgever.
      </p>
      <p>
        Waar de opdrachtbevestiging specifieke voorwaarden bevat of ernaar verwijst, zijn deze van
        toepassing parallel aan de Algemene Voorwaarden. Echter, in het geval van een conflict of
        een inconsistentie tussen de clausules van de twee reeksen voorwaarden, de specifieke
        voorwaarden prevaleren boven de Algemene Voorwaarden.
      </p>
      <h3>2.Onze samenwerking</h3>
      <h4>2.1.Voorwaarden voor samenwerking</h4>
      <p>
        TC HR Belasting &amp; IM, is een Belgisch bedrijf ingeschreven in het Belgische
        handelsregister onder nummer 0740.763.660 en is lid van het Instituut voor
        Belastingadviseurs en Accountants (ITAA), ingeschreven onder het nummer [50.752.723].
      </p>
      <h4>2.2.Beroepsgeheim - witwassen van geld - DAC6</h4>
      <p>
        Zowel TC HR Tax &amp; IM, evenals haar bestuurder(s), werknemer(s) of lasthebber(s) zijn
        hieraan gebonden beroepsgeheim, in overeenstemming met artikel 50 van de wet van 17 maart
        2019 betreffende de boekhoudkundige en fiscale beroepen en artikel 32 van het Koninklijk
        Besluit van 1 maart
        <sup>st</sup>, 1998 tot vaststelling van de beroepsregels voor registeraccountants en
        belastingadviseurs, echter onder voorbehoud van de toepassing van de bepalingen van de
        wetgeving en regelgeving met betrekking tot het voorkomen van het gebruik van het financiële
        stelsel voor de doel van het witwassen van geld en terrorismefinanciering.
      </p>
      <p>
        De opdrachtgever bevestigt dat hij op de hoogte is van het feit dat de beroepsbeoefenaar is
        onderworpen aan de wetgeving ter voorkoming van het gebruik van het financiële stelsel voor
        deze doeleinden van witwassen en terrorismefinanciering en verbindt zich ertoe hem
        onverwijld alle informatie en/of documenten vereist onder de genoemde wetgeving.
      </p>
      <p>
        Bovendien zijn TC A&A, haar directeur(en), werknemer(s) of agent(en) gebonden aan de wet van
        20 december 2019, ter omzetting van Richtlijn (EU) 2018/822 van de Raad van 25 mei 2018, tot
        wijziging van Richtlijn 2011/16/EU betreffende de automatische en verplichte uitwisseling
        van belastinginformatie in verband met meldingsplichtige grensoverschrijdende constructies
        (hierna DAC 6).
      </p>
      <p>
        Echter, in overeenstemming met de instructies van de ITAA, TC HR Tax &amp; IM informeert de
        opdrachtgever het beroepsgeheim hem verhindert enige DAC 6-aangifte te doen of te doen het
        informeren van tussenpersonen die betrokken zijn bij een regeling die onder de reikwijdte
        van DAC 6 valt.
      </p>
      <h4>2.3.TC HR Tax &amp; De reikwijdte van professionele verantwoordelijkheid van IM</h4>
      <p>
        Overeenkomstig het algemeen verbintenissenrecht is de aansprakelijkheid van TC HR Tax &amp;
        IM kan alleen zijn ingeschakeld voor opdrachten waarvan blijkt dat zij deze hebben aanvaard.
      </p>
      <p>
        Behalve met betrekking tot het naleven van wettelijke termijnen (mits de opdrachtgever aan
        al haar verplichtingen als bedoeld in artikel 4 van de Algemene Voorwaarden heeft voldaan en
        voorwaarden of in artikel 8.1 van de opdrachtbrief), de toezegging van TC HR Tax &amp; IK
        BEN jegens haar opdrachtgever een middelenverbintenis (<em>obligation de moyens</em>) is. TC
        HR Belasting &amp; IM zal de haar toevertrouwde opdrachten in volledige onafhankelijkheid en
        met de vereiste zorg volgens de normen, instructies of voorschriften van de ITAA.
      </p>
      <p>
        TC HR Belasting &amp; IM is afhankelijk van de opdrachtgever voor de juistheid van de
        informatie en/of door laatstgenoemde verstrekte documentatie. TC HR Belasting &amp; IM is
        niet verplicht om de juistheid en volledigheid van de informatie die hem door of namens hem
        is meegedeeld namens, evenals de betrouwbaarheid van alle akten, contracten, inventarissen,
        facturen en bewijsstukken van welke aard dan ook, die haar zouden worden toevertrouwd of
        door de opdrachtgever als bewijsstukken of om als zodanig te gebruiken.
      </p>
      <p>
        Het opsporen van fraude, onregelmatigheid of onrechtmatigheid bij handelingen, maar alleen
        de verificatie van de inhoud van de documenten die binnen onze missie zijn ontvangen, wat
        betreft hun volledigheid en geschiktheid in termen van de algemeen aanvaarde principes en
        normen door het beroep.
      </p>
      <p>
        TC HR Tax &amp; IM kan niet aansprakelijk worden gesteld voor de gevolgen van
        wetswijzigingen.
      </p>
      <p>
        TC HR Belasting &amp; IM kan niet aansprakelijk worden gesteld voor eventuele overtredingen,
        vergissingen of overtredingen voor zijn tussenkomst.
      </p>
      <p>
        TC HR Belasting &amp; IM is een onafhankelijk lid van Alliott Global Alliance, een
        internationaal samenwerkingsverband van onafhankelijke accountants-, advocatuur- en
        gespecialiseerde kantoren. Alliott Global Alliance en haar lidfirma's zijn juridisch
        gescheiden en afzonderlijke entiteiten. Deze entiteiten zijn niet en zal niet worden opgevat
        als in de relatie van een moederbedrijf, dochteronderneming, partner, joint venture, agent
        of een netwerk. Geen enkele firma die lid is van de Alliott Global Alliance heeft er een
        bevoegdheid (daadwerkelijk, schijnbaar, geïmpliceerd of anderszins) om Alliott Global te
        verplichten of te binden Alliance of enig ander bedrijf dat lid is van Alliott Global
        Alliance, op welke manier dan ook. Even, noch Alliott Global Alliance, noch enige andere
        lidfirma heeft enige bevoegdheid om te verplichten of binden TC HR Tax &amp; IM of een
        andere lidfirma. Alle leden van de Alliott Global Alliance zijn dat onafhankelijke
        bedrijven, en als zodanig leveren ze elk hun diensten volledig op zichzelf account
        (inclusief voordeel en risico). In verband met de opdrachtbevestiging heeft de generaal
        Algemene voorwaarden of andere diensten die van tijd tot tijd door TC HR Tax &amp; IK BEN,
        TC HR Belasting &amp; De IM kan advies inwinnen bij of het behoud van een Alliott Global
        aanbevelen Alliantie aangesloten firma. Alliott Global Alliance en haar andere lidfirma's
        hebben geen aansprakelijkheid voor door TC HR Tax &amp; IM, of een dergelijke geraadpleegde
        of ingehuurde Alliott Global Alliance lidfirma. Evenmin zal TC HR Tax &amp; IM is
        aansprakelijk voor gegeven adviezen door een van de andere bij de Alliott Global Alliance
        aangesloten firma's, zelfs indien geraadpleegd of aanbevolen aan u door TC HR Tax &amp; IK
        BEN.
      </p>
      <p>
        In ieder geval blijft de aansprakelijkheid van TC HR Tax &amp; IM voor diensten die door of
        namens u worden uitgevoerd van TC HR Tax &amp; IM of die rechtstreeks of onrechtstreeks
        verband houdt met een toevertrouwde opdracht naar TC HR Tax &amp; IM is beperkt tot het
        bedrag gedekt door de voorwaarden van de beroepsaansprakelijkheidsverzekering afgesloten
        door TC HR Tax &amp; IM via de Instituut voor Belastingadviseurs &amp; Accountants.
      </p>
      <p>
        Deze beperking geldt ook voor vorderingen die zouden worden gericht door het hoofd van de
        uitvoering van de opdracht tegen alle personen, vennoten, bestuurders en/of zelfstandigen
        medewerkers, die verzekerd zijn in de zin van de verzekeringsovereenkomst.
      </p>
      <p>
        Wanneer blijkt dat twee of meer schadegevallen het gevolg zijn van dezelfde storing, dan is
        dat ook zo worden beschouwd als slechts één geval van aansprakelijkheid, en
        aansprakelijkheid zal dat dus ook zijn beperkt tot het grootste aantal van de bedragen die
        van toepassing zijn op de betrokken missies of overeenkomsten.
      </p>
      <p>
        Er wordt uitdrukkelijk bepaald dat deze beperking van toepassing is in geval van grove
        nalatigheid gepleegd door TC HR Tax &amp; IM evenals in geval van aquilian aansprakelijkheid
        van TC HR Tax &amp; IK BEN.
      </p>
      <p>
        In ieder geval zal TC HR Tax &amp; IM is niet aansprakelijk voor indirecte of gevolgschade
        economisch verlies of schade (inclusief maar niet beperkt tot verlies van winst, omzet en
        clientèle) die de cliënt kan lijden als gevolg van de dienstverlening van TC HR Belasting
        &amp; IK BEN.
      </p>
      <p>
        Geen enkele aansprakelijkheid jegens een derde wordt aanvaard, tenzij TC HR Tax &amp; IM
        heeft het gegeven uitdrukkelijke schriftelijke toestemming.
      </p>
      <p>
        De beroepsregels machtigen TC HR Tax &amp; IM, met strikte eerbiediging van de professional
        geheimhouding, om zaken aan te nemen voor andere klanten wiens activiteiten kunnen
        concurreren met die van de cliënt.
      </p>
      <h3>3.Kosten</h3>
      <p>
        Onze tarieven en forfaitaire aanbiedingen zijn exclusief btw, buitenlandse belastingen en
        eventuele inhoudingen, verschotten en reiskosten.
      </p>
      <p>
        Ze worden jaarlijks geïndexeerd volgens de evolutie van de kosten van levensonderhoud. Elk
        tariefevolutie die niet binnen 8 dagen na de kennisgeving ervan wordt betwist, wordt
        verondersteld dat te zijn geaccepteerd.
      </p>
      <p>
        Uurtarieven variëren naargelang de ervaring van de adviseur en liggen tussen de 70 euro en
        420 euro.
      </p>
      <p>
        De forfaitaire aanbiedingen zijn beperkt tot de beschreven diensten en dekken geen andere
        diensten zoals maar niet beperkt tot specifieke belastingdiensten zoals diverse adviezen,
        reactie op a verzoek van de administratie of bijstand in het kader van een geschil met de
        administratie.
      </p>
      <p>
        Ze worden bepaald op basis van een schatting van de hoeveelheid werk die nodig is, de
        moeilijkheidsgraad van de opdracht, de aard van de werkzaamheden en de benodigde tijd en
        ervaring a professioneel.
      </p>
      <p>
        Mocht tijdens de werkzaamheden een van deze factoren zodanig wijzigen dat ons voorstel is
        niet meer passend, nemen wij direct contact met u op om een nieuw begrotingsvoorstel af te
        spreken.
      </p>
      <p>
        Om de operationele kosten van TC HR Tax &amp; IM wordt een percentage van 5% toegepast
        facturen (deze post dekt, dossierkosten, informatica, aangetekende zending met
        ontvangstbevestiging, secretariële en receptioniste kosten).{" "}
      </p>
      <p>
        De erelonen en kosten zijn verschuldigd naarmate de diensten ten behoeve van de
        opdrachtgever, ook als de opdracht niet noodzakelijkerwijs is voltooid.
      </p>
      <p>
        TC HR Belasting &amp; IM kan één of meerdere voorschotten vragen. Deze betalingen worden
        ingebracht rekening in de definitieve staat van kosten en erelonen.
      </p>
      <p>
        Alle kosten en erelonen van bezwaar moeten schriftelijk per aangetekende brief of per e-mail
        worden ingediend binnen 60 dagen na factuurdatum in gebreke zijn in hetgeen de afnemer
        geacht wordt te hebben overeengekomen met de diensten en de in rekening gebrachte bedragen.
      </p>
      <p>Facturen en/of declaraties zijn betaalbaar binnen 14 dagen na factuurdatum.</p>
      <p>
        Meer dan 15 dagen&rsquo; vertraging in de betaling, wordt automatisch en zonder voorafgaande
        kennisgeving van standaard verschuldigd:
      </p>
      <p>
        &middot;een compenserende intrest tegen het tarief voorzien in artikel 5 van de wet van 2
        augustus 2002 met betrekking tot te late betaling bij handelstransacties;
      </p>
      <p>
        &middot;een contractuele vergoeding vastgesteld op 10% van de onbetaalde bedragen, met een
        minimum van &euro; 250.00.
      </p>
      <h3>4.De klant gaat hierbij akkoord:</h3>
      <p>
        Met het oog op de aan TC HR Tax &amp; IM, de klant nauwgezet verbindt zich ertoe om samen te
        werken en alle documenten, gegevens en informatie die nodig is voor de uitvoering van de
        missie.
      </p>
      <p>De klant verbindt zich ook</p>
      <ul>
        <li>
          om alle informatie te verstrekken die TC HR Tax &amp; IM-verzoeken volgens de wetgeving en
          regelgeving met betrekking tot het voorkomen van het gebruik van het financiële stelsel
          voor de doel van witwassen en terrorismefinanciering;
        </li>
      </ul>
      <ul>
        <li>
          eventuele wijzigingen in hun contactgegevens onverwijld doorgeven aan TC HR Tax &amp; IM
          en elke wijziging in hun uiteindelijke begunstigden;
        </li>
      </ul>
      <ul>
        <li>
          om te voldoen aan het Belgische vennootschapsrecht en aan alle wetgeving die dit
          verhindert of daarmee verband houdt corruptie, strafbare feiten of fraude;
        </li>
      </ul>
      <ul>
        <li>
          ter beschikking te stellen aan TC HR Tax &amp; IM, tijdig, alle documenten, gegevens en
          informatie noodzakelijk voor de uitvoering van de missie;
        </li>
      </ul>
      <ul>
        <li>
          om de haar opgedragen werkzaamheden uit te voeren, voor zover van toepassing, in
          overeenstemming met de opdracht brief;
        </li>
      </ul>
      <ul>
        <li>
          onder de aandacht te brengen van TC HR Tax &amp; IM alle gegevens, gebeurtenissen of
          ontwikkelingen die waarschijnlijk zijn invloed uitoefenen op de uitvoering van de missie;
        </li>
      </ul>
      <ul>
        <li>
          Bij TC HR Tax &amp; IM&rsquo;verzoek, bevestig schriftelijk dat de documenten, verstrekte
          informatie en uitleg juist en volledig zijn;
        </li>
      </ul>
      <ul>
        <li>
          Controleer of de documenten en verklaringen van de professional overeenkomen met zijn
          verwachtingen en de door hem verstrekte informatie, en indien dit niet het geval is,
          hiervan onverwijld op de hoogte te stellen TC HR Belasting &amp; IK BEN;
        </li>
      </ul>
      <ul>
        <li>
          informeer TC HR Tax &amp; IM van elke wanbetaling, vanaf de eerste vervaldag, met
          betrekking tot welke administratieve, fiscale, sociale of welke andere schuldeiser dan
          ook.
        </li>
      </ul>
      <p>
        Indien dit niet het geval is, zal TC HR Tax &amp; IM zal gemachtigd zijn om de missie
        overeenkomstig te beëindigen aan artikel 6.3 hieronder en zijn ontheven van elke
        verantwoordelijkheid voor niet-naleving van de door wet- en regelgeving en overeenkomsten
        gestelde termijnen voor de uitvoering van fiscale, sociale, of andere formaliteiten die
        onder de dekmantel van zijn missie zouden vallen.
      </p>
      <h3>5.Plaats van uitvoering van de missie</h3>
      <p>
        Boeken en documenten verstrekt aan of opgesteld door TC HR Tax &amp; IM voor de uitvoering
        van de missie kan worden verplaatst naar andere plaatsen op TC HR Tax &amp; IM's discretie.
        De professional mag ze vasthouden gedurende de tijd die nodig is om zijn opdracht(en) uit te
        voeren. De klant heeft altijd de recht om ze te raadplegen, hetzij persoonlijk, hetzij door
        tussenkomst van een van haar vertegenwoordiger of gemachtigde voorzien van een schriftelijke
        volmacht, mits deze boeken en documenten zijn eigendom van de opdrachtgever.
      </p>
      <p>
        Het is de verantwoordelijkheid van de opdrachtgever om de boekhouding en bescheiden bij te
        houden en mee te nemen ze terug zodra de professional hem op de hoogte stelt van hun
        beschikbaarheid. De professional verbindt zich ertoe zijn boekhoudkundige documenten
        regelmatig ter beschikking te stellen van de cliënt.
      </p>
      <h3>6.Algemene voorwaarden voor het beëindigen van de samenwerking</h3>
      <h4>6.1.Terugkerende opdrachten</h4>
      <p>
        Terugkerende opdrachten zijn opdrachten die repetitief van aard zijn en niet bij elkaar
        passen de in artikel 6.2 genoemde definitie van &ldquo;eenmalige opdrachten&rdquo;.
      </p>
      <p>
        De overeenkomst betreffende terugkerende opdrachten wordt aangegaan voor onbepaalde tijd.
        Elk Partij heeft het recht om deze te beëindigen door de andere Partij op de hoogte te
        stellen van haar bereidheid dit te doen per aangetekende brief en met inachtneming van een
        termijn van drie maanden. opzegtermijn tenzij de Partijen komen anders overeen.
      </p>
      <p>
        Bij het niet naleven door de opdrachtgever van de opzegtermijn, zal TC HR Tax &amp; Ik mis
        recht op betaling van een schadevergoeding gelijk aan 50% van de gefactureerde erelonen
        tijdens de voorgaande 3 maanden.
      </p>
      <p>
        Gedurende de opzegtermijn gelden de bepalingen van de opdrachtbevestiging en deze Algemene
        Voorwaarden en Voorwaarden blijven van kracht.
      </p>
      <p>
        Tenzij partijen anders zijn overeengekomen, geldt de beëindiging van de opdracht, ook met
        inachtneming van een opzegtermijn leidt tot onmiddellijke beëindiging van de dienstverlening
        van de professional voor het opmaken van de jaarrekening over het boekjaar voorafgaand aan
        de datum van beëindiging.
      </p>
      <h4>6.2.Eenmalige opdrachten</h4>
      <p>
        Eenmalige opdrachten zijn eenmalige en niet-repetitieve opdrachten, het realiseren van
        waarmee een einde komt aan de opdracht.
      </p>
      <p>
        Behoudens tegenbewijs wordt de overeenkomst met betrekking tot een eenmalige opdracht geacht
        te zijn voor bepaalde tijd worden afgesloten.
      </p>
      <p>
        Het eindigt met de uitvoering van de opdracht en, indien dit met het oog op de aard van de
        opdracht, met de levering van de overeengekomen diensten.
      </p>
      <p>
        Met toepassing van artikel 1794 Burgerlijk Wetboek en desgevallend niettegenstaande artikel
        2004 BW heeft de opdrachtgever het recht de overeenkomst tussentijds te beëindigen, tegen
        betaling aan de professional van de kosten en erelonen die overeenkomen met reeds
        uitgevoerde werken.
      </p>
      <p>
        Deze vergoeding wordt reëel berekend, met een minimum van 50% van de erelonen verschuldigd
        zou zijn geweest bij volledige uitvoering van de opdracht.
      </p>
      <h4>6.3.Onmiddellijke beëindiging</h4>
      <p>
        TC HR Belasting &amp; IM kan de samenwerking op elk moment beëindigen, zonder voorafgaande
        kennisgeving schadevergoeding dan ook en zonder voorafgaande rechterlijke tussenkomst, in
        geval van onrechtmatige daad niet-nakoming van een van de verplichtingen van de klant die
        hij niet binnen acht (8) dagen na het versturen van een ingebrekestelling door TC HR Tax
        &amp; Ik beveel het te stoppen elke standaard en in een van de volgende situaties:
      </p>
      <ul>
        <li>elke omstandigheid die de onafhankelijkheid van TC HR Tax &amp; IM;</li>
      </ul>
      <ul>
        <li>
          elke omstandigheid die de uitvoering van de opdracht onmogelijk kan maken, overeenkomstig
          met professionele en ethische normen;
        </li>
      </ul>
      <ul>
        <li>
          het nalaten van de opdrachtgever om de benodigde documenten aan TC HR Tax &amp; Ik ben
          voor de uitvoering van haar missie;
        </li>
      </ul>
      <ul>
        <li>
          in geval van gerechtelijke reorganisatie, ontbinding, vereffening, faillissement van de
          opdrachtgever of een vergelijkbare procedure.{" "}
        </li>
      </ul>
      <p>
        Bij beëindiging van de overeenkomst is TC HR Tax &amp; IM informeert de opdrachtgever over
        de urgentie en noodzakelijke rechtshandelingen die ter vrijwaring van zijn rechten moeten
        worden verricht en voor welke TC HR Tax &amp; IM was toevertrouwd.
      </p>
      <p>
        De klant kan de overeenkomst op elk moment beëindigen, zonder opzegtermijn en zonder
        schadevergoeding, TC HR Belasting &amp; IM blijft kennelijk in gebreke met het nakomen van
        zijn verplichtingen, zoals omschreven in deze algemene voorwaarden en, indien van
        toepassing, in de opdrachtbevestiging. De klant zal een aanmaning sturen naar TC HR Tax
        &amp; IM om de standaard te identificeren en TC HR Tax op te leggen &amp; IM om het te
        stoppen.
      </p>
      <h4>6.4.Opvolging</h4>
      <p>
        Bij beëindiging van de opdracht worden alle boeken en bescheiden, eigendom van de
        opdrachtgever, opgemaakt beschikbaar voor hem of zijn vertegenwoordiger door middel van het
        opstellen van een gedateerde inventaris en ondertekend zoveel originele exemplaren als er
        partijen zijn.
      </p>
      <p>Het einde van de missie:</p>
      <ul>
        <li>
          houdt onmiddellijke intrekking in van elk mandaat dat is afgegeven voor het indienen van
          aangiften, ambtenaar documenten of formulieren zoals individuele belastingaangiften,
          aangiften vennootschapsbelasting, btw-aangiften, jaarrekening, UBO-register &hellip;
        </li>
        <li>
          eist van de cliënt dat hij onverwijld al zijn boekhoudkundige documenten van de
          professioneel.
        </li>
      </ul>
      <h4>6.5.Opschorting of uitstel van de opdracht</h4>
      <p>
        Bij niet, niet behoorlijk of niet tijdige nakoming door opdrachtgever, van een of meer van
        zijn verplichtingen, ook in geval van niet-betaling van vergoedingen of aanbetalingen
        betaling(en), conform artikel 3 hierboven, TC HR Tax &amp; IM is gerechtigd tot opschorting
        of de nakoming van haar verplichtingen opschorten totdat de opdrachtgever aan haar
        verplichtingen heeft voldaan.
      </p>
      <p>
        TC HR Belasting &amp; IM stelt de opdrachtgever schriftelijk op de hoogte en stelt de
        opdrachtgever op de hoogte van urgente en dringende zaken noodzakelijke werkzaamheden ter
        vrijwaring van de rechten van de cliënt die binnen drie uur moeten worden uitgevoerd maanden
        na aanvang van de schorsing of het uitstel van de opdracht.
      </p>
      <p>
        Alle kosten en lasten (inclusief boetes en schadevergoedingen) die voortvloeien uit de
        schorsing of uitstel zijn de verantwoordelijkheid van de opdrachtgever.
      </p>
      <p>
        TC HR Belasting &amp; IM heeft onder alle omstandigheden recht op betaling van erelonen en
        kosten met betrekking tot reeds verrichte diensten.
      </p>
      <h3>7.Privacy - gegevensverwerking</h3>
      <p>
        TC HR Belasting &amp; IM neemt zijn verplichtingen met betrekking tot de bescherming van de
        persoonlijke levenssfeer en de bescherming van persoonsgegevens zeer serieus.
      </p>
      <p>
        <em>Definities: </em>In deze clausule hebben de volgende termen de volgende betekenis:
      </p>
      <p>
        "<strong>verwerkingsverantwoordelijke</strong>", "<strong>verwerker</strong>", "
        <strong>betrokkene</strong>
        ", "<strong>persoonlijke</strong> <strong>gegevens</strong>" en "<strong>verwerking</strong>
        " (en "<strong>proces</strong>") heeft de betekenis die wordt gegeven in de toepasselijke
        wetgeving inzake gegevensbescherming;
      </p>
      <p>
        "<strong>Toepasselijke gegevensbeschermingswet</strong>" betekent alle wereldwijde
        gegevensbescherming en privacywet- en regelgeving die van toepassing is op de betreffende
        persoonsgegevens, inclusief waar toepasselijke EU-wetgeving inzake gegevensbescherming; En
      </p>
      <p>
        "<strong>EU-wetgeving inzake gegevensbescherming</strong>" betekent (i) Verordening 2016/679
        van de Europese Parlement en de Raad betreffende de bescherming van natuurlijke personen met
        betrekking tot de verwerking van persoonsgegevens en over het vrije verkeer van die gegevens
        (Algemene gegevensbescherming verordening) (de "<strong>AVG</strong>"); (ii) de EU
        e-privacyrichtlijn (Richtlijn 2002/58/EG); en (iii) alle toepasselijke nationale
        gegevensbeschermingswetten gemaakt onder of ingevolge (i) of (ii); in elk geval zoals van
        tijd tot tijd kan worden gewijzigd of vervangen.
      </p>
      <p>
        <em>Juridische kwalificatie van de partijen: </em>TC HR Tax &amp; IM en de klant zal
        verwerken persoonsgegevens (hierna de "<strong>Gegevens</strong>") overeenkomstig de
        Algemene Voorwaarden en Voorwaarden. De Partijen erkennen dat elke Partij de Gegevens
        verwerkt in haar bezit of controleren als een onafhankelijke verwerkingsverantwoordelijke
        voor hun eigen doel(en) (niet als gezamenlijke verwerkingsverantwoordelijken of als
        gegevensverwerker van de andere Partij).
      </p>
      <p>
        <em>Naleving van de wet:</em> Elke Partij zal voldoen aan haar verplichtingen op grond van
        Toepasselijk Wet Bescherming Persoonsgegevens en deze Algemene Voorwaarden bij de verwerking
        van de Gegevens. Geen van beide Partijen is verantwoordelijk voor de naleving door de andere
        Partij van de Toepasselijke Gegevens Beschermingswet. In het bijzonder is elke partij
        afzonderlijk verantwoordelijk voor het waarborgen dat de verwerking van de gegevens
        rechtmatig, eerlijk en transparant is en beschikbaar zal stellen aan betrokkenen een
        privacyverklaring die voldoet aan de vereisten van Toepasselijke gegevens Beschermingswet.
        Voor zover Tax Consult HR Tax &amp; IM staat niet in direct contact met de betrokkenen, zal
        de opdrachtgever een exemplaar van Tax Consult HR Tax &amp; IM-privacy beleid aan de
        betrokkenen onverwijld na de totstandkoming van deze Algemene Voorwaarden en Voorwaarden.
      </p>
      <h3>8. Deelbaarheid en jurisdictie</h3>
      <p>
        De Algemene Voorwaarden zijn van toepassing met uitsluiting van alle andere. Elke clausule
        of voorwaarde vormt een afzonderlijke en zelfstandige bepaling. Als een voorziening wordt
        overwogen nietig of niet-afdwingbaar zijn, blijven alle andere bepalingen van toepassing.
      </p>
      <p>
        De inzet van TC HR Tax &amp; IM zal worden beheerst en geïnterpreteerd in overeenstemming
        met Belgisch recht. Elk geschil of elke claim die hieruit voortvloeit, zal voorafgaand aan
        enige juridische procedure worden ingediend actie, tot een mediationtraject volgens het
        mediationreglement van bMediation, waarvan maatschappelijke zetel is gevestigd te 1050
        Brussel, Louizalaan 500. De bemiddeling zal aanvang uiterlijk vijftien (15) dagen na het
        door één kenbaar gemaakte verzoek tot bemiddeling partij aan de andere partij en de duur van
        de bemiddeling zal niet langer zijn dan vijftien (15) dagen, tenzij partijen uitdrukkelijk
        zijn overeengekomen.
      </p>
      <p>
        In geval van mislukking van de bemiddeling zijn de rechtbanken van Brussel exclusief
        jurisdictie, onverminderd de jurisdictie van de beroepsorganisaties waarin TC HR Belasting
        &amp; IM hangt ervan af.{" "}
      </p>
      <h3>9.Verbod op tippelen</h3>
      <p>
        Voor de gehele duur van de samenwerking, en voor een periode van 12 maanden na het einde van
        de samenwerking, ongeacht de reden van beëindiging van de relatie, de klant en TC HR Tax
        &amp; IM verbindt er zich uitdrukkelijk toe om geen personeel of zelfstandigen aan te nemen
        medewerkers van de wederpartij die rechtstreeks of direct bij de uitvoering van de
        overeenkomst zijn betrokken indirect (in het bijzonder via een rechtspersoon), noch om
        direct werkzaamheden te laten verrichten of indirect (in het bijzonder via een
        rechtspersoon), tenzij voorafgaand schriftelijk akkoord van de andere partij is verkregen.
      </p>
      <p>
        Elke inbreuk op dit verbod geeft aanleiding tot een forfaitaire schadevergoeding van 25% van
        de jaarlijkse bezoldigingskosten van het personeelslid die geacht worden de vergoedingen te
        dekken die zou worden toegepast door een headhunter om een gelijkwaardig profiel te vinden.
      </p>
      <br />
      <p>
        <strong>
          Al uw persoonlijke en professionele gegevens worden opgeslagen in een database die
          eigendom is van Tax Raadpleeg S.A..
        </strong>
      </p>
    </>
  );
};

export default TermsConditions;
