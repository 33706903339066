import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { map } from "lodash";

// i18n
import i18n from "../../../i18n";
import { useTranslation, withTranslation } from "react-i18next";

import languages from "../../../common/languages";

const LanguageDropdown = (props: any) => {
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useState("English");

  const toggle = React.useCallback(() => {
    setMenuOpen((menuOpen) => !menuOpen);
  }, []);

  useEffect(() => {
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE");
    i18n.changeLanguage(currentLanguage ? currentLanguage : "English");
    setSelectedLang(currentLanguage ? currentLanguage : "English");
  }, []);

  const changeLanguageAction = (lang: string, code: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("I18N_LANGUAGE", lang);
    localStorage.setItem("LANGUAGE_CODE", code);

    setSelectedLang(lang);
  };

  return (
    <React.Fragment>
      <Dropdown isOpen={menuOpen} toggle={toggle} className="d-inline-block" style={props?.style}>
        <DropdownToggle className="btn header-item" tag="button">
          <img
            src={languages[selectedLang].flag}
            alt={languages[selectedLang].label}
            height="16"
          />
        </DropdownToggle>
        <DropdownMenu className="language-switch dropdown-menu-end">
          {map(Object.keys(languages), (key) => (
            <DropdownItem
              key={key}
              onClick={() => changeLanguageAction(languages[key].label, languages[key].code)}
              className={`notify-item ${
                selectedLang === key ? "active" : "none"
              }`}
            >
              <img
                src={languages[key].flag}
                alt={languages[key].label}
                className="me-2"
                height="12"
              />
              <span className="align-middle">{t(languages[key].label)}</span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(LanguageDropdown);
