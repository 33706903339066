import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Modal, ModalBody, ModalFooter, Button, Alert } from "reactstrap";
import { mapValues } from "lodash";
import { getUserProfile } from "../../helpers/functions_helper";
import { useTranslation } from "react-i18next";

const SubTaskListModal = (props: any) => {
  const { t } = useTranslation();
  let subTaskList = props.subTaskList;
  const userProfile = getUserProfile("all-sub-task");
  const [errorMsg, setErrorMsg] = useState("");
  const [subTaskData, setSubTaskData] = useState<any>([]);
  const sub_task = props.subTaskList?.data || [];

  const columnData = [
    t("TO DO"),
    t("IN PREPARATION"),
    t("IN REVIEW"),
    t("RESPONSIBLE TO SIGN OFF"),
    t("TO BE SENT"),
    t("SENT"),
    t("CANCELLED"),
    t("INVOICED"),
    t("DONE"),
  ];
  const columnColor = [
    "#556ee6",
    "#34c38f",
    "#50a5f1",
    "#f1b44c",
    "#74788d",
    "#fd7e14",
    "#198754",
    "#20c997",
    "#0dcaf0",
  ];
  mapValues(sub_task, (item: any) => (item.checked = false));

  const checkUncheckAll = (e: any) => {
    var newData: any = [];
    subTaskData.map((item: any, index: number) => {
      newData.push({ ...item, checked: e });
    });
    setSubTaskData(newData);
  };

  const selectSubTask = (e: any, i: any) => {
    var newData: any = [...subTaskData];
    newData.map((item: any, index: number) => {
      if (index === i) {
        newData[index] = {
          ...item,
          checked: e,
        };
      }
    });
    setSubTaskData(newData);
  };

  const updateMultipleTaskStatus = () => {
    const listsub = subTaskData.filter((item: any) => item.checked);
    props.onUpdateSubTaskStatus(listsub);
  };

  useEffect(() => {
    setSubTaskData(sub_task);
  }, [sub_task]);

  return (
    <React.Fragment>
      <Modal
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
        size="lg"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title">{t("Subtask List")}</h5>

          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label="Close"
          ></button>
        </div>

        <ModalBody>
          <Row>
            <Col lg={12}>
              {subTaskList?.message ? <Alert color="danger">{subTaskList?.message}</Alert> : null}
            </Col>
            <Col lg={12}>
              <div className="table-responsive">
                <table className="table table-nowrap align-middle mb-0">
                  <tbody>
                    {subTaskData && subTaskData.length > 1 && (
                      <tr>
                        <td>
                          <div className="form-check font-size-16">
                            <input
                              type="checkbox"
                              id="check_all"
                              className="form-check-input"
                              onChange={(e: any) => checkUncheckAll(e.target.checked)}
                            />
                            <label className="form-check-label" htmlFor="check_all">
                              {t("Select all")}
                            </label>
                          </div>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    )}

                    {subTaskData.map((item: any, i: number) => (
                      <tr key={i}>
                        <td>
                          <div className="form-check font-size-16">
                            {userProfile.IS_INTERNAL &&
                              (userProfile.USER_ID === parseInt(subTaskData?.created_by) ||
                                userProfile.USER_ID === parseInt(subTaskData?.preparer) ||
                                userProfile.USER_ID === parseInt(subTaskData?.reviewer) ||
                                userProfile.USER_ID === parseInt(subTaskData?.file_manager) ||
                                userProfile.USER_ID === parseInt(subTaskData?.partner) ||
                                userProfile.USER_ID === parseInt(subTaskData?.assistant_administrative) ||
                                userProfile.USER_ID === parseInt(subTaskData?.assignee) ||
                                userProfile.CAN_EDIT_ALL) ? (                            
                              <>
                                <input
                                  type="checkbox"
                                  value={item.checked}
                                  checked={item.checked}
                                  className="form-check-input"
                                  id={item.id}
                                  onChange={(e: any) => selectSubTask(e.target.checked, i)}
                                />
                                <label className="form-check-label" htmlFor={item.id}>
                                  <span
                                    className="fs-6"
                                    dangerouslySetInnerHTML={{
                                      __html: item.sub_task_title,
                                    }}
                                  ></span>
                                </label>
                              </>
                            ) : (
                              <Link
                                to={"/tasks/subtask-details/" + item.id + "/" + item.subtask_id}
                                className="text-primary"
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.sub_task_title,
                                  }}
                                ></div>
                              </Link>
                            )}
                          </div>
                        </td>
                        <td>
                          {/* <Link to={"/tasks/subtask-details/" + item.id + "/" + item.subtask_id} className="text-primary">
                            <div dangerouslySetInnerHTML={{ __html: item.sub_task_title }}></div>
                          </Link> */}
                        </td>
                        <td>
                          <div className="text-center">
                            <span
                              className={"badge rounded-pill font-size-11"}
                              style={{
                                backgroundColor: columnColor[item.status],
                              }}
                            >
                              {item.task_status_value}
                            </span>
                          </div>
                        </td>
                        <td>
                          <div className="d-flex gap-2 text-end">
                            {userProfile.CAN_EDIT_ALL ? (
                              <Link
                                className="btn btn-pill btn-outline-primary btn-sm"
                                to={"/tasks/subtask-details/" + item.id + "/" + item.subtask_id}
                              >
                                <i className="fa-regular fa-pen-to-square"></i> {t("Edit")}
                              </Link>
                            ) : (
                              ""
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="outline-danger"
            className="btn-block"
            type="reset"
            onClick={() => updateMultipleTaskStatus()}
            disabled={props.loading}
          >
            {`${t("Change status to")} ${columnData[subTaskList.update_status - 1]}`}
          </Button>

          <Button
            color="outline-dark"
            className="btn-block"
            type="reset"
            onClick={props.onCloseClick}
            disabled={props.loading}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default SubTaskListModal;
