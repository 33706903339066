import {
  GET_USER_FUNCTION,
  GET_USER_FUNCTION_SUCCESS,
  GET_USER_FUNCTION_ERROR,

  GET_USER_FUNCTION_LIST,
  GET_USER_FUNCTION_LIST_SUCCESS,
  GET_USER_FUNCTION_LIST_ERROR,

  CREATE_USER_FUNCTION,
  CREATE_USER_FUNCTION_SUCCESS,
  CREATE_USER_FUNCTION_ERROR,

  UPDATE_USER_FUNCTION,
  UPDATE_USER_FUNCTION_SUCCESS,
  UPDATE_USER_FUNCTION_ERROR,

  DELETE_USER_FUNCTION,
  DELETE_USER_FUNCTION_SUCCESS,
  DELETE_USER_FUNCTION_ERROR,
} from "./actionTypes";

// ---------------------------------------------GET_USER_FUNCTION
export const getUserFunction = (userfunction: any, history: any) => {
  return {
    type: GET_USER_FUNCTION,
    payload: { userfunction, history },
  };
};

export const getUserFunctionSuccess = (data: {}) => {
  return {
    type: GET_USER_FUNCTION_SUCCESS,
    payload: data,
  };
};

export const getUserFunctionError = (error: {}) => {
  return {
    type: GET_USER_FUNCTION_ERROR,
    payload: error,
  };
};

// ---------------------------------------------GET_USER_FUNCTION_LIST
export const getUserFunctionList = (query: any, history: any) => {
  return {
    type: GET_USER_FUNCTION_LIST,
    payload: {query, history},
  };
};

export const getUserFunctionListSuccess = (data: {}) => {
  return {
    type: GET_USER_FUNCTION_LIST_SUCCESS,
    payload: data,
  };
};

export const getUserFunctionListError = (error: {}) => {
  return {
    type: GET_USER_FUNCTION_LIST_ERROR,
    payload: error,
  };
};

// ---------------------------------------------CREATE_USER_FUNCTION
export const createUserFunction = (userfunction: any, history: any) => {
  return {
    type: CREATE_USER_FUNCTION,
    payload: { userfunction, history },
  };
};

export const createUserFunctionSuccess = (userfunction: {}) => {
  return {
    type: CREATE_USER_FUNCTION_SUCCESS,
    payload: userfunction,
  };
};

export const createUserFunctionError = (error: {}) => {
  return {
    type: CREATE_USER_FUNCTION_ERROR,
    payload: error,
  };
};

// ---------------------------------------------UPDATE_USER_FUNCTION
export const updateUserFunction = (userfunction: any, history: any) => {
  return {
    type: UPDATE_USER_FUNCTION,
    payload: { userfunction, history },
  };
};

export const updateUserFunctionSuccess = (userfunction: {}) => {
  return {
    type: UPDATE_USER_FUNCTION_SUCCESS,
    payload: userfunction,
  };
};

export const updateUserFunctionError = (error: {}) => {
  return {
    type: UPDATE_USER_FUNCTION_ERROR,
    payload: error,
  };
};

// ---------------------------------------------DELETE_USER_FUNCTION
export const deleteUserFunction = (userfunction: any, history: any) => {
  return {
    type: DELETE_USER_FUNCTION,
    payload: { userfunction, history },
  };
};

export const deleteUserFunctionSuccess = (userfunction: {}) => {
  return {
    type: DELETE_USER_FUNCTION_SUCCESS,
    payload: userfunction,
  };
};

export const deleteUserFunctionError = (error: {}) => {
  return {
    type: DELETE_USER_FUNCTION_ERROR,
    payload: error,
  };
};
