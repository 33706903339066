import React, { useState, useEffect, FC } from 'react';
import { Row, Table } from 'reactstrap';
import invoicesService from '../../services/invoices.service';
import { dateFormat, formatCurrency } from '../../utils/formats';
import './style.scss';
import { useTranslation } from 'react-i18next';

type PaymentHistoryProps = {
    invoice: any;
};

const PaymentHistory: FC<PaymentHistoryProps> = ({ invoice }) => {
    const { t } = useTranslation();

    const [payments, setPayments] = useState<Array<any>>([]);
    const [deletedPayments, setDeletedPayments] = useState<Array<any>>([]);
    let total = 0;

    const getPayments = async (id: number) => {
        const res = await invoicesService.getPayments(id);
        if (res.status === 'success') {
            setPayments(res.data);
        }
    }

    const handleDelete = (id: any) => {
        let con = window.confirm(t('Are you sure, you want to delete this payment'));
        if (con) {
            setDeletedPayments((i: any) => [...i, id]);
        }
    }

    useEffect(() => {
        getPayments(invoice.id);
    }, [])

    return (
        <div className='tabel-responsive'>
            <h4 className='mb-3 align-center'>{t("Invoice Number")}: {invoice.invoice_number}</h4>
            <Row>
                <Table className='table align-middle table-nowrap table-check'>
                    <thead className='tabel-light'>
                        <tr>
                            <th>{t("Description")}</th>
                            <th className='rightAlign'>{t("Amount")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {payments.map((payment: any) => {
                            total += payment.amount;
                            return (
                                <tr
                                    key={payment.id}
                                    style={{
                                        background: payments.filter((i: any) => i?.payment_date === payment?.payment_date && i?.amount === payment?.amount)?.length > 1 ? '#f3f2f2' : '',
                                        color: payments.filter((i: any) => i?.payment_date === payment?.payment_date && i?.amount === payment?.amount)?.length > 1 ? '#eb2e96' : ''
                                    }}
                                    className={deletedPayments.includes(payment.id) ? 'strike-through' : ''}
                                >
                                    <td>{`${payment.amount > 0 ? t('Payment receiped on ') : t('Refund made on ')}${dateFormat(payment.payment_date)}`}</td>
                                    <td className='rightAlign'><span style={{ color: payment.amount > 0 ? 'green' : 'red' }}>{payment.amount > 0 && '+'}{formatCurrency(payment.amount)}</span></td>
                                    {payments.filter((i: any) => i?.payment_date === payment?.payment_date && i?.amount === payment?.amount)?.length > 1 ?
                                        <td className='rightAlign'><i className='fa fa-solid fa-trash' onClick={() => handleDelete(payment.id)}></i> </td> : null
                                    }
                                </tr>
                            )
                        }
                        )}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>{t("Total")}</th>
                            <th className='rightAlign'>{formatCurrency(total)}</th>
                        </tr>
                    </tfoot>
                </Table>
            </Row>
        </div>
    )
}

export default PaymentHistory;