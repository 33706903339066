import React, { FC, useEffect, useState } from 'react'
import { Col, Label, Row } from 'reactstrap';

import Select from "react-select";
import commonService from '../../../services/common.service';
import { getDependentSelectOptionsWithOrder, yearData } from '../../../helpers/functions_helper';
import { useTranslation } from 'react-i18next';
import { statusOptions } from '../../../common/staticdata';

type FiltersProps = {
    filters: any;
    onApply: any;
    onClear: any;
    onChange?: any;
    hideTab?: any;
}

const Filters: FC<FiltersProps> = ({ filters, onApply, onClear, onChange, hideTab = false }) => {
    const {t} = useTranslation();
   
    /* set questionnaire type */
    const [questionnaireTypeOption, setQuestionnaireTypeOption] = useState([]);
    const [questionnaireType, setQuestionnaireType] = useState({
        value: 0,
        label: t("Please select..."),
    });
    const [loadingQuestionnaireType, setLoadingQuestionnaireType] = useState(true);

    /* set tab*/
    const [loadingTab, setLoadingTab] = useState(true);
    const [tabOptions, setTabOptions] = useState([]);
    const [userType, setUserType] = useState<any>(filters?.userType);
    const [yearValue, setYearValue] = useState<any>(filters?.year);
    const [questionnaireTypeValue, setQuestionnaireTypeValue] = useState<any>(filters?.questionnaire_type_id);
    const [tab, setTab] = useState<any>(filters.tab_id);

    const getSelectOptionsOrder = async (tableName: string, fieldName: string) => {
        const response = await commonService.getSelectOptionsListOrder(
            tableName,
            fieldName
        );
        return response.data.data;
    };
    const [query, setQuery] = useState("");
    const getDependentSelectOptions = async (
        tableName: string,
        fieldName: string,
        whereField: string,
        whereID: number
    ) => {
        const response = await commonService.getDependentSelectOptionsList(
            tableName,
            fieldName,
            whereField,
            whereID,
            query ? query : ""
        );
        return response.data.data;
    };

    const getDependentMultipleWhereSelectOptions = async (
        tableName: string,
        fieldName: string,
        whereFields: any,
        whereIds: any
    ) => {
        const response = await commonService.getDependentMultipleWhereSelectOptionsList(
            tableName,
            fieldName,
            whereFields,
            whereIds,
            query ? query : ""
        );
        return response.data.data;
    };



    useEffect(() => {


        getDependentMultipleWhereSelectOptions(
            "tbl_master_questionnaire_type",
            "questionnaire_type",
            ["year"],
            [yearValue]
        ).then(function (options) {
            let opt: any = [];
            opt.push(
                {
                    value: 0,
                    label: t("Please select..."),
                },
                ...options
            );
            setQuestionnaireTypeOption(opt);
            setLoadingQuestionnaireType(false);
        });

    }, [])


    useEffect(() => {
        if (userType) {
            setQuestionnaireTypeOption([]);
            setLoadingQuestionnaireType(true);
            getDependentMultipleWhereSelectOptions(
                "tbl_master_questionnaire_type",
                "questionnaire_type",
                ["user_type", "year"],
                [userType, yearValue]
            ).then(function (options) {
                let opt: any = [];
                opt.push(
                    {
                        value: 0,
                        label: t("Please select..."),
                    },
                    ...options
                );
                setQuestionnaireTypeOption(opt);
                setLoadingQuestionnaireType(false);
            });
        } else if (yearValue) {
            setQuestionnaireTypeOption([]);
            setLoadingQuestionnaireType(true);
            getDependentMultipleWhereSelectOptions(
                "tbl_master_questionnaire_type",
                "questionnaire_type",
                ["year"],
                [yearValue]
            ).then(function (options) {
                let opt: any = [];
                opt.push(
                    {
                        value: 0,
                        label: t("Please select..."),
                    },
                    ...options
                );
                setQuestionnaireTypeOption(opt);
                setLoadingQuestionnaireType(false);
            });
        }
    }, [userType, yearValue]);

    useEffect(() => {
        if (questionnaireTypeValue) {
            setTabOptions([]);
            setLoadingTab(true);
            getDependentSelectOptionsWithOrder("tbl_master_questionnaire_tab", "questionnaire_tab_value", "questionnaire_type_id", questionnaireTypeValue, "order").then(function (options) {
                let opt: any = [];
                opt.push(
                    {
                        value: 0,
                        label: t("Please select..."),
                    },
                    ...options
                );
                setTabOptions(opt);
                setLoadingTab(false);
            });
        }


    }, [questionnaireTypeValue]);


    const [yearList, setYearList] = useState<any>([]);
    const [userStatusList, setUserStatusList] = useState<any>([]);
  
    useEffect(() => {
  
      if (statusOptions) {
        const options = statusOptions?.map((option: any) => ({
          label: t(option.label),
          value: option.value
        }));
        setUserStatusList(options);
      }
  
      if (yearData) {
        const options = yearData?.map((option: any) => ({
          label: t(option.label),
          value: option.value
        }));
        setYearList(options);
      }
  
    }, [localStorage.getItem("I18N_LANGUAGE"), statusOptions])


    return (
        <React.Fragment>
            <Col>
                <div className="mb-3">
                    <Label className="form-label">
                        {t("User type")}{" "}
                    </Label>
                    <Select
                        id="user_type"
                        name="user_type"
                        className="is-invalid"
                        placeholder={t("Select...")}
                        options={userStatusList}
                        //@ts-ignore
                        value={userStatusList?.find((o: any) => o.value === filters?.user_type)}
                        isSearchable={true}
                        onChange={(option: any) => {
                            setUserType(option.value);
                            onChange({ user_type: option.value })
                        }}
                    />

                </div>
            </Col>
            <Col>
                <div className="mb-3">
                    <Label className="form-label">
                    {t("Year")}{" "}
                    </Label>
                    <Select
                        id="year"
                        name="year"
                        className="is-invalid"
                        placeholder={t("Select...")}
                        options={yearList}
                        value={yearList?.find((opt: any) => opt.value === filters?.year)}
                        isSearchable={true}
                        onChange={(option: any) => {
                            setYearValue(option.value);
                            onChange({ year: option.value, questionnaire_type_id: 0, tab_id: 0 })
                        }}
                    />
                </div>
            </Col>
            <Col>
                <div className="mb-3">
                    <Label className="form-label">
                    {t("Questionnaire type")}{" "}
                    </Label>
                    <Select
                        id="questionnaire_type_id"
                        name="questionnaire_type_id"
                        className="is-invalid"
                        placeholder={t("Select...")}
                        options={questionnaireTypeOption}
                        //@ts-ignore
                        value={questionnaireTypeOption.find((o: any) => o.value === filters?.questionnaire_type_id)}
                        isSearchable={true}
                        isDisabled={loadingQuestionnaireType}
                        onChange={(option: any) => {
                            setQuestionnaireTypeValue(option.value);
                            if (questionnaireTypeValue != null)
                                onChange({ questionnaire_type_id: option.value, tab_id: 0 })
                        }}
                    />
                </div>
            </Col>
            {!!hideTab &&
                <Col>
                    <div className="mb-3">
                        <Label className="form-label">
                            {t("Tab")}{" "}
                            <span className="text-warning is_required">*</span>
                        </Label>
                        <Select
                            id="tab_id"
                            name="tab_id"
                            className="is-invalid"
                            options={tabOptions}
                            placeholder={t("Select...")}
                            //@ts-ignore
                            value={tabOptions.find((o: any) => o.value === filters?.tab_id)}
                            isSearchable={false}
                            isDisabled={loadingTab}
                            onChange={(option: any) => {
                                setTab(option.value);
                                if (tab != null)
                                    onChange({ tab_id: option.value })
                            }}
                        />
                    </div>
                </Col>
            }
        </React.Fragment>
    )
}

export default Filters