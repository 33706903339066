import React from "react";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../../components/Layout/Breadcrumb";
import CPOForm from "./CPOForm";

import ScrollUp from "../../components/ScrollUpDown/ScrollUp";
import ScrollDown from "../../components/ScrollUpDown/ScrollDown";
import { getUserProfile } from "../../helpers/functions_helper";

const ViewCPO = (props: any) => {
  const { t } = useTranslation();
  const userProfile = getUserProfile("all-cpo");
  document.title = `${t("View")} CPO | ` + process.env.REACT_APP_NAME;

  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
      hidden: userProfile.USER_TYPE === 3 ? true : false,
    },
    {
      url: "/cpo/list/all",
      label: t("CPO"),
      active: false,
    },
    {
      url: "#",
      label: `${t("View")} CPO`,
      active: true,
    },
  ];

  return (
    <React.Fragment>
      <ScrollUp />
      <ScrollDown top={"100px"} />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbTitle={`${t("View")} CPO`} breadcrumbItems={breadcrumbItems} />

          <CPOForm showReport={true} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewCPO;
