import React, { FC, useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Input, Label, Nav, NavItem, Row } from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import commonService from "../../../services/common.service";
import {
  addIndividualInCompany,
  getDependentMultipleWhereSelectOptions,
  getDependentSelectOptions,
  getSelectOptions,
  getUserProfile,
  listingStatusOptions,
  select2Styles,
  yearsBackword,
} from "../../../helpers/functions_helper";
// Actions
import { CommonAction } from "../../../store/actions";
import {
  AttributOptions,
  clientstatusOptions,
  statusOptions,
  taxRegimeMaster,
  typeOptionsTrans,
} from "../../../common/staticdata";
import { useTranslation } from "react-i18next";
import { Month } from "../../../utils/data";
import { Link } from "react-router-dom";

type FiltersProps = {
  filters: any;
  onApply: any;
  onClear: any;
  onChange?: any;
  company_id?: any;
};

const Filters: FC<FiltersProps> = ({ filters, onApply, onClear, onChange, company_id }) => {
  const dispatch = useDispatch();
  const userProfile = getUserProfile("all-users");
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const [loadingCompanyOptions, setLoadingCompanyOptions] = useState(true);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyID, setCompanyID] = useState<any>(filters?.company_id);
  const [disabledCompanyUserOption, setDisabledCompanyUserOption] = useState(true);
  const [loadingCompanyUserOption, setloadingCompanyUserOption] = useState(false);
  const [usersOptions, setUsersOptions] = useState([]);
  const [isConstom, setCostom] = useState<boolean>(false);
  const years = useMemo(() => yearsBackword(), []);
  const [quarters, setQuarters] = useState<any>([]);
  const [type, setType] = useState<string>(!company_id ? "details" : "general");
  const [taxReturnTypeOption, setTaxReturnTypeOption] = useState<any>([]);
  const YesNoMaster = [
    {
      label: t("Yes"),
      value: "1",
    },
    {
      label: t("No"),
      value: "0",
    },
  ];
  const [vatcatOptions, setVatcatOptions] = useState([]);
  const TVW_options = [
    {
      label: t("Null"),
      value: "null",
    },
    {
      label: t("Not null"),
      value: "not_null",
    },
  ];

  const activeOptions = [
    {
      label: t("Active"),
      value: 1,
    },
    {
      label: t("Non Active"),
      value: "0",
    },
  ]

  const WeekOption = [
    // { label: `Today`, value: `today` },
    { label: t(`Last Week`), value: `lastweek` },
    { label: t(`This Week`), value: `week` },
  ];

  useEffect(() => {
    if (!userProfile.CAN_VIEW_ALL) {
      setCompanyID(userProfile.USER_COMPANY);
    }
  }, []);

  useEffect(() => {
    setQuarters([
      { label: `Q1-${filters.year}`, value: `q1-${filters.year}` },
      { label: `Q2-${filters.year}`, value: `q2-${filters.year}` },
      { label: `Q3-${filters.year}`, value: `q3-${filters.year}` },
      { label: `Q4-${filters.year}`, value: `q4-${filters.year}` },
    ]);
  }, [filters.year]);

  let { getCompanyOptionsList, loading } = useSelector((state: any) => ({
    getCompanyOptionsList: state.Common.GET_COMPANY_OPTIONS_LIST_SUCCESS,
    loading: state.Common.loading,
  }));

  // Get options list -------------------------------------------------
  useEffect(() => {
    if (!getCompanyOptionsList) dispatch(CommonAction.getCompanyOptionsList());
    getSelectOptions("tbl_vat_cat", "vatcat_name").then(function (options) {
      const VatOptions = options?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setVatcatOptions(VatOptions);
    });
  }, []);

  useEffect(() => {
    if (getCompanyOptionsList.data) {
      const item = addIndividualInCompany(getCompanyOptionsList.data) as any;
      const companyOptions = item?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      if (userProfile.CAN_VIEW_ALL) {
        setCompanyOptions(companyOptions);
      } else if (!userProfile.CAN_VIEW_ALL && userProfile.CAN_VIEW) {
        getSelectOptions("tbl_master_company", "company_name", '', ["created_by"]).then(
          function (options) {
            const companyOptions = options?.map((option: any) => ({
              label: t(option.label),
              value: option.value
            }));
            const opt = companyOptions.filter((opt: any) => (opt.created_by === userProfile.USER_ID || opt.value === userProfile.USER_COMPANY));
            setCompanyOptions(opt);
          });
      } else {
        setCompanyOptions([]);
      }

      setLoadingCompanyOptions(false);
    }
  }, [getCompanyOptionsList]);

  useEffect(() => {
    if (filters.status) {
      getDependentSelectOptions(
        "tbl_master_tax_return_type",
        "tax_return_type_value",
        "questionnaire_type",
        filters.status,
        ''
      ).then(function (options) {
        setTaxReturnTypeOption(options);
      });
    }
  }, [filters.status]);

  useEffect(() => {
    if (companyID) {
      setUsersOptions([]);
      onChange({ user_id: "" });
      setDisabledCompanyUserOption(true);
      setloadingCompanyUserOption(true);
      getDependentMultipleWhereSelectOptions("tbl_master_users", "first_name", ["company"], [companyID], '', ["tbl_master_users.created_by"]).then(
        function (optio) {
          const options = optio?.map((option: any) => ({
            label: t(option.label),
            value: option.value
          }));
          if (userProfile.CAN_VIEW_ALL) {
            setUsersOptions(options);
          } else if (!userProfile.CAN_VIEW_ALL && userProfile.CAN_VIEW) {
            const opt = options.filter((opt: any) => (opt.created_by === userProfile.USER_ID || opt.value === userProfile.USER_ID));
            setUsersOptions(opt);
          } else {
            setUsersOptions([]);
          }
          setDisabledCompanyUserOption(false);
          setloadingCompanyUserOption(false);
        }
      );
    }
  }, [companyID]);

  const [attributeList, setAttributeList] = useState<any>([]);
  const [clientstatusList, setClientstatusList] = useState<any>([]);
  const [statusList, setStatusList] = useState<any>([]);
  const [taxList, setTaxList] = useState<any>([]);
  useEffect(() => {
    if (AttributOptions) {
      const Options = AttributOptions?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setAttributeList(Options);
    }

    if (clientstatusOptions) {
      const Options = clientstatusOptions?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setClientstatusList(Options);
    }

    if (statusOptions) {
      const Options = statusOptions?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setStatusList(Options);
    }

    if (taxRegimeMaster) {
      const Options = taxRegimeMaster?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setTaxList(Options);
    }
  }, [])
  useEffect(() => {
    if (AttributOptions) {
      const Options = AttributOptions?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setAttributeList(Options);
    }

    if (clientstatusOptions) {
      const Options = clientstatusOptions?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setClientstatusList(Options);
    }

    if (statusOptions) {
      const Options = statusOptions?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setStatusList(Options);
    }

    if (taxRegimeMaster) {
      const Options = taxRegimeMaster?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setTaxList(Options);
    }
  }, [localStorage.getItem("I18N_LANGUAGE")])

  return (
    <Container>
      {!company_id && (
        <Row>
          <Col className="col-12">
            <Nav tabs className="nav-tabs-custom">
              <Nav tabs>
                <NavItem>
                  <Link
                    style={{ cursor: "pointer" }}
                    to={"#"}
                    onClick={() => setType("details")}
                    className={type === "details" ? "nav-link active" : "nav-link"}
                  >
                    <span className="d-block d-sm-none">
                      <i className="fas fa-home"></i>
                    </span>
                    <span className="d-none d-sm-block">{t("Details")}</span>
                  </Link>
                </NavItem>
                <NavItem>
                  <Link
                    style={{ cursor: "pointer" }}
                    to={"#"}
                    onClick={() => setType("general")}
                    className={type === "general" ? "nav-link active" : "nav-link"}
                  >
                    <span className="d-block d-sm-none">
                      <i className="fas fa-home"></i>
                    </span>
                    <span className="d-none d-sm-block">{t("General")}</span>
                  </Link>
                </NavItem>
              </Nav>
            </Nav>
          </Col>
        </Row>
      )}
      {!company_id && type === "details" ? (
        <>
          {(userProfile.CAN_VIEW_ALL || userProfile.CAN_VIEW) &&
            <Row className="mt-3">
              <Col>
                <div className="mb-3">
                  <Label className="form-label">{t("Company")}</Label>
                  <Select
                    options={companyOptions}
                    value={
                      filters?.company_id
                        ? companyOptions.find((option: any) => option.value == filters?.company_id)
                        : ""
                    }
                    placeholder={t("Select...")}
                    isSearchable={true}
                    isDisabled={loadingCompanyOptions}
                    isLoading={loadingCompanyOptions}
                    onChange={(option: any) => {
                      setCompanyID(option.value);
                      onChange({
                        is_filtered: true,
                        company_id: option.value,
                        week: null,
                        quarter: null,
                        month: null,
                        year: null,
                        from_date: null,
                        to_date: null,
                      });
                    }}
                  />
                </div>
              </Col>
            </Row>
          }

          <Row>
            <Col>
              <div className={!(userProfile.CAN_VIEW_ALL || userProfile.CAN_VIEW) ? "mt-3 mb-3" : "mb-3"}>
                <Label className="form-label">{t("User")}</Label>
                <Select
                  options={usersOptions}
                  value={
                    filters?.user_id
                      ? usersOptions.find((option: any) => option.value == filters?.user_id)
                      : ""
                  }
                  placeholder={t("Select...")}
                  isSearchable={true}
                  isDisabled={disabledCompanyUserOption}
                  isLoading={loadingCompanyUserOption}
                  onChange={(option: any) => {
                    onChange({
                      is_filtered: true,
                      user_id: option.value,
                      week: null,
                      quarter: null,
                      month: null,
                      year: null,
                      from_date: null,
                      to_date: null,
                    });
                  }}
                />
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label">
                  {t('Single permit')}
                </Label>
                <Select
                  id="single_permit"
                  name="single_permit"
                  className="is-invalid"
                  options={YesNoMaster}
                  value={
                    filters?.single_permit
                      ? YesNoMaster.find(
                        (option: any) => option.value == filters?.single_permit
                      )
                      : ""
                  }
                  isSearchable={false}
                  onChange={(option: any) => {
                    onChange({
                      is_filtered: true,
                      single_permit: option.value,
                      week: null,
                      quarter: null,
                      month: null,
                      year: null,
                      from_date: null,
                      to_date: null,
                    });
                  }}
                />
              </div>
            </Col>
          </Row> */}
          {filters.company_id == 0 && (
            <>
              <Row>
                <Col>
                  <div className="mb-3">
                    <Label className="form-label">{t("TVA/BTW number")}</Label>
                    <Select
                      id="TVW_Nr"
                      name="TVW_Nr"
                      className="is-invalid"
                      options={TVW_options}
                      value={
                        filters?.TVW_Nr
                          ? TVW_options.find((option: any) => option.value == filters.TVW_Nr)
                          : ""
                      }
                      placeholder={t("Select...")}
                      isSearchable={false}
                      onChange={(option: any) => {
                        onChange({ is_filtered: true, TVW_Nr: option.value });
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="mb-3">
                    <Label className="form-label">{t("VAT Category")}</Label>
                    <Select
                      id="vatcat"
                      name="vatcat"
                      className="is-invalid"
                      options={vatcatOptions}
                      value={
                        filters?.vatcat
                          ? vatcatOptions.find((option: any) => option.value == filters.vatcat)
                          : ""
                      }
                      placeholder={t("Select...")}
                      isSearchable={false}
                      onChange={(option: any) => {
                        onChange({ is_filtered: true, vatcat: option.value });
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="mb-3">
                    <Label className="form-label">{t("To Apply Vat")}</Label>
                    <Select
                      id="to_apply_vat"
                      name="to_apply_vat"
                      className="is-invalid"
                      options={YesNoMaster}
                      value={
                        filters?.to_apply_vat
                          ? YesNoMaster.find((option: any) => option.value == filters.to_apply_vat)
                          : ""
                      }
                      placeholder={t("Select...")}
                      isSearchable={false}
                      onChange={(option: any) => {
                        onChange({ is_filtered: true, to_apply_vat: option.value });
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="mb-3">
                    <Label className="form-label">{t("Client Status")}</Label>
                    <Select
                      id="client_status"
                      name="client_status"
                      options={clientstatusList}
                      value={
                        filters.client_status
                          ? clientstatusList?.find(
                            (option: any) => option.value == filters.client_status
                          )
                          : ""
                      }
                      placeholder={t("Select...")}
                      isSearchable={false}
                      onChange={(option: any) => {
                        onChange({ is_filtered: true, client_status: option.value });
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}

          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label">{t("INPAT")}</Label>
                <Select
                  id="tax_regime"
                  name="tax_regime"
                  className="is-invalid"
                  options={taxList}
                  value={
                    filters?.tax_regime
                      ? taxList?.find((option: any) => option.value == filters?.tax_regime)
                      : ""
                  }
                  placeholder={t("Select...")}
                  isSearchable={false}
                  onChange={(option: any) => {
                    onChange({ is_filtered: true, tax_regime: option.value });
                  }}
                />
              </div>
            </Col>
          </Row>

          {(filters?.tax_regime == 2 || filters?.tax_regime == 3) && (
            <Row>
              <Col>
                <div className="mb-3">
                  <div className="d-flex flex-wrap">
                    <div className="toolbar d-flex flex-wrap gap-2 mb-2">
                      <button type="button" className="btn btn-light btn-sm">
                        <i className="fa-regular fa-calendar-plus"></i>
                      </button>
                      <button
                        type="button"
                        className={
                          "btn btn-sm " +
                          (filters?.date_type == "tax_regime_start_date"
                            ? "btn-primary"
                            : "btn-light")
                        }
                        onClick={(option: any) => {
                          onChange({
                            is_filtered: true,
                            date_type: "tax_regime_start_date",
                            week: null,
                            quarter: null,
                            month: null,
                            year: null,
                            from_date: null,
                            to_date: null,
                          });
                        }}
                      >
                        {t("Tax regime start date")}
                      </button>
                    </div>
                  </div>

                  <Row>
                    <Col>
                      <Label>{t("From")}</Label>
                      <div className="input-group is-invalid">
                        <Flatpickr
                          id="tax_start_date"
                          name="tax_start_date"
                          className="form-control d-block"
                          type="text"
                          placeholder={t("From")}
                          value={filters?.tax_start_date}
                          options={{
                            altInput: true,
                            altFormat: "d/m/Y",
                            dateFormat: "Y-m-d H:i:S",
                            defaultDate: filters.tax_start_date,
                            allowInput: true,
                            onChange: function (selectedDates, dateStr, instance) {
                              onChange({ is_filtered: true, tax_start_date: dateStr });
                            },
                            locale: { firstDayOfWeek: 1 },
                          }}
                        />
                        <span className="input-group-text">
                          <span className="align-middle material-symbols-outlined fw-light fs-4">
                            calendar_today
                          </span>
                        </span>
                      </div>
                    </Col>
                    <Col>
                      <Label>{t("To")}</Label>
                      <div className="input-group is-invalid">
                        <Flatpickr
                          id="tax_end_date"
                          name="tax_end_date"
                          className="form-control d-block"
                          type="text"
                          placeholder={t("To")}
                          value={filters?.tax_end_date}
                          options={{
                            altInput: true,
                            altFormat: "d/m/Y",
                            dateFormat: "Y-m-d H:i:S",
                            defaultDate: filters.tax_end_date,
                            allowInput: true,
                            onChange: function (selectedDates, dateStr, instance) {
                              onChange({ is_filtered: true, tax_end_date: dateStr });
                            },
                            locale: { firstDayOfWeek: 1 },
                          }}
                        />
                        <span className="input-group-text">
                          <i className="fa-solid fa-calendar"></i>
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          )}

          <Row>
            <Col>
              <div className="mb-3">
                <div className="d-flex flex-wrap">
                  <div className="toolbar d-flex flex-wrap gap-2 mb-2">
                    <button type="button" className="btn btn-light btn-sm">
                      <i className="fa-regular fa-calendar-plus"></i>
                    </button>
                    <button
                      type="button"
                      className={
                        "btn btn-sm " +
                        (filters?.date_type == "start_date" ? "btn-primary" : "btn-light")
                      }
                      onClick={(option: any) => {
                        onChange({
                          is_filtered: true,
                          date_type: "start_date",
                          week: null,
                          quarter: null,
                          month: null,
                          year: null,
                          from_date: null,
                          to_date: null,
                        });
                      }}
                    >
                      {t("Start date in application")}
                    </button>
                    <button
                      type="button"
                      className={
                        "btn btn-sm " +
                        (filters?.date_type == "end_date" ? "btn-primary" : "btn-light")
                      }
                      onClick={(option: any) => {
                        onChange({
                          is_filtered: true,
                          date_type: "end_date",
                          week: null,
                          quarter: null,
                          month: null,
                          year: null,
                          from_date: null,
                          to_date: null,
                        });
                      }}
                    >
                      {t("End date in application")}
                    </button>
                    {/* <button type="button"
                  className={"btn btn-sm " + (filters?.date_type == 'created_date' ? 'btn-primary' : 'btn-light')}
                  onClick={(option: any) => {
                    onChange({ is_filtered: true, date_type: "created_date" });
                  }}>Created date</button> */}

                    <button
                      type="button"
                      className={
                        "btn btn-sm " +
                        (filters?.date_type == "start_date_bel" ? "btn-primary" : "btn-light")
                      }
                      onClick={(option: any) => {
                        onChange({
                          is_filtered: true,
                          date_type: "start_date_bel",
                          week: null,
                          quarter: null,
                          month: null,
                          year: null,
                          from_date: null,
                          to_date: null,
                        });
                      }}
                    >
                      {t("Start date in Belgium")}
                    </button>

                    <button
                      type="button"
                      className={
                        "btn btn-sm " +
                        (filters?.date_type == "end_date_bel" ? "btn-primary" : "btn-light")
                      }
                      onClick={(option: any) => {
                        onChange({
                          is_filtered: true,
                          date_type: "end_date_bel",
                          week: null,
                          quarter: null,
                          month: null,
                          year: null,
                          from_date: null,
                          to_date: null,
                        });
                      }}
                    >
                      {t("Leave date from Belgium")}
                    </button>

                  </div>
                </div>

                <Row>
                  <Col>
                    <Label>{t("From")}</Label>
                    <div className="input-group is-invalid">
                      <Flatpickr
                        id="start_date"
                        name="start_date"
                        className="form-control d-block"
                        type="text"
                        placeholder="From"
                        value={filters?.start_date}
                        options={{
                          altInput: true,
                          altFormat: "d/m/Y",
                          dateFormat: "Y-m-d H:i:S",
                          defaultDate: filters.start_date,
                          allowInput: true,
                          onChange: function (selectedDates, dateStr, instance) {
                            onChange({
                              is_filtered: true,
                              start_date: dateStr,
                              week: null,
                              quarter: null,
                              month: null,
                              year: null,
                              from_date: null,
                              to_date: null,
                            });
                          },
                          locale: { firstDayOfWeek: 1 },
                        }}
                      />
                      <span className="input-group-text">
                        <span className="align-middle material-symbols-outlined fw-light fs-4">
                          calendar_today
                        </span>
                      </span>
                    </div>
                  </Col>
                  <Col>
                    <Label>{t("To")}</Label>
                    <div className="input-group is-invalid">
                      <Flatpickr
                        id="end_date"
                        name="end_date"
                        className="form-control d-block"
                        type="text"
                        placeholder="To"
                        value={filters?.end_date}
                        options={{
                          altInput: true,
                          altFormat: "d/m/Y",
                          dateFormat: "Y-m-d H:i:S",
                          defaultDate: filters.end_date,
                          allowInput: true,
                          onChange: function (selectedDates, dateStr, instance) {
                            onChange({
                              is_filtered: true,
                              end_date: dateStr,
                              week: null,
                              quarter: null,
                              month: null,
                              year: null,
                              from_date: null,
                              to_date: null,
                            });
                          },
                          locale: { firstDayOfWeek: 1 },
                        }}
                      />
                      <span className="input-group-text">
                        <span className="align-middle material-symbols-outlined fw-light fs-4">
                          calendar_today
                        </span>
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label">{t("Type")}</Label>
                <Select
                  id="type"
                  name="type"
                  className="is-invalid"
                  options={typeOptionsTrans(t)}
                  value={
                    filters?.type
                      ? typeOptionsTrans(t)?.find((option: any) => option.value == filters?.type)
                      : ""
                  }
                  placeholder={t("Select...")}
                  isSearchable={false}
                  onChange={(option: any) => {
                    onChange({ is_filtered: true, type: option.value });
                  }}
                />
              </div>
            </Col>
          </Row>
        </>
      ) : null}
      {type === "general" ? (
        <>
          <Row className="mt-3">
            <Col>
              <div className="mb-3">
                <Label className="form-label">{t("User type")}</Label>
                <Select
                  id="status"
                  name="status"
                  className="is-invalid"
                  options={statusList}
                  value={
                    filters?.status
                      ? statusList?.find((option: any) => option.value == filters?.status)
                      : ""
                  }
                  placeholder={t("Select...")}
                  isSearchable={false}
                  onChange={(option: any) => {
                    onChange({ is_filtered: true, status: option.value });
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label">{t("Select attribute")}</Label>
                <Select
                  id="select_attribute"
                  name="select_attribute"
                  className="is-invalid"
                  options={attributeList}
                  value={
                    filters?.select_attribute
                      ? attributeList?.find(
                        (option: any) => option.value == filters?.select_attribute
                      )
                      : ""
                  }
                  placeholder={t("Select...")}
                  isSearchable={false}
                  onChange={(option: any) => {
                    onChange({ is_filtered: true, select_attribute: option.value });
                  }}
                />
              </div>
            </Col>
          </Row>
          {filters?.select_attribute ? (
            <>


              {!isConstom ? (
                <>
                  <Row>
                    {filters?.select_attribute == "tax_return" ? (
                      <>
                        {((filters?.status != "") && (filters.active_status != "")) ? (
                          <Col>
                            <div className="mb-3">
                              <Label className="form-label">
                                {t('Tax return type')}
                              </Label>
                              <Select
                                id="tax_return_type"
                                name="tax_return_type"
                                className="is-invalid"
                                options={taxReturnTypeOption}
                                value={
                                  filters?.tax_return_type
                                    ? taxReturnTypeOption.find(
                                      (option: any) => option.value == filters?.tax_return_type
                                    )
                                    : ""
                                }
                                placeholder={t("Select...")}
                                isSearchable={false}
                                onChange={(option: any) => {
                                  onChange({ is_filtered: true, tax_return_type: option.value });
                                }}
                              />
                            </div>
                          </Col>
                        )
                          : null
                        }
                        <Col>
                          <div className="mb-3">
                            <Label className="form-label">
                              {t('Active/Inactive')}
                            </Label>
                            <Select
                              id="active_status"
                              name="active_status"
                              className="is-invalid"
                              options={activeOptions}
                              value={
                                filters?.active_status
                                  ? activeOptions.find(
                                    (option: any) => option.value == filters?.active_status
                                  )
                                  : ""
                              }
                              placeholder={t("Select...")}
                              isSearchable={false}
                              onChange={(option: any) => {
                                onChange({ is_filtered: true, active_status: option.value });
                              }}
                            />
                          </div>
                        </Col>
                      </>
                    ) : (
                      <Col>
                        <div className="mb-3">
                          <Label className="form-label">
                            {t('Yes/No')}
                          </Label>
                          <Select
                            id="active_status"
                            name="active_status"
                            className="is-invalid"
                            options={YesNoMaster}
                            value={
                              filters?.active_status
                                ? YesNoMaster.find(
                                  (option: any) => option.value == filters?.active_status
                                )
                                : ""
                            }
                            placeholder={t("Select...")}
                            isSearchable={false}
                            onChange={(option: any) => {
                              onChange({ is_filtered: true, active_status: option.value });
                            }}
                          />
                        </div>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Label className="form-label">{t("Due date range")}</Label>
                    <Col>
                      <Select
                        id="work_allocation_week_type"
                        name="work_allocation_week_type"
                        className="form-select-sm myClassName mb-2"
                        styles={select2Styles}
                        placeholder={t("Week")}
                        options={WeekOption}
                        //isDisabled={multiYear}
                        value={
                          filters.week
                            ? WeekOption.find((i: any) => i.value === filters.week)
                            : null
                        }
                        onChange={(option: any) => {
                          onChange({
                            is_filtered: true,
                            week: option?.value,
                            quarter: null,
                            month: null,
                            year: null,
                            from_date: null,
                            to_date: null,
                          });
                        }}
                      />
                    </Col>
                    <Col>
                      <Select
                        id="work_allocation_month_type"
                        name="work_allocation_month_type"
                        className="form-select-sm myClassName mb-3"
                        styles={select2Styles}
                        placeholder={t("Month")}
                        options={Month}
                        value={
                          filters.month ? Month.find((i: any) => i.value === filters.month) : null
                        }
                        isDisabled={!filters.year}
                        onChange={(option: any) => {
                          onChange({
                            is_filtered: true,
                            week: null,
                            quarter: null,
                            month: option?.value,
                            from_date: null,
                            to_date: null,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Select
                        id="work_allocation_quarter_type"
                        name="work_allocation_quarter_type"
                        className="form-select-sm myClassName"
                        styles={select2Styles}
                        placeholder="Quarter"
                        options={quarters}
                        value={
                          filters.quarter
                            ? quarters.find((i: any) => i.value === filters.quarter)
                            : null
                        }
                        isDisabled={!filters.year}
                        onChange={(option: any) => {
                          onChange({
                            is_filtered: true,
                            week: null,
                            quarter: option?.value,
                            month: null,
                            from_date: null,
                            to_date: null,
                          });
                        }}
                      />
                    </Col>
                    <Col>
                      <Select
                        id="work_allocation_year_type"
                        name="work_allocation_year_type"
                        className="form-select-sm myClassName mb-3"
                        styles={select2Styles}
                        placeholder={t("Year")}
                        options={years}
                        //isMulti={yearOnly}
                        //isOptionDisabled={() => filter.year.length >= 2}
                        value={
                          filters.year ? years.find((i: any) => i.value === filters.year) : null
                        }
                        onChange={(option: any) => {
                          onChange({
                            is_filtered: true,
                            week: null,
                            year: option.value,
                            from_date: null,
                            to_date: null,
                          });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center">
                      <Button color="link" onClick={() => setCostom(true)}>
                        {t("Custom date range")}
                      </Button>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row>
                    <Col xs="6">
                      <Input
                        id="from_date"
                        name="from_date"
                        className="form-control d-block form-control-sm"
                        type="date"
                        placeholder={t("From Date")}
                        value={filters?.from_date}
                        onChange={(e: any) =>
                          onChange({
                            is_filtered: true,
                            week: null,
                            quarter: null,
                            month: null,
                            year: null,
                            from_date: e.target.value,
                          })
                        }
                      />
                    </Col>
                    <Col xs="6">
                      <Input
                        id="to_date"
                        name="to_date"
                        className="form-control d-block form-control-sm"
                        type="date"
                        placeholder={t("To Date")}
                        value={filters?.to_date}
                        onChange={(e: any) =>
                          onChange({
                            is_filtered: true,
                            week: null,
                            quarter: null,
                            month: null,
                            year: null,
                            to_date: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center">
                      <Button color="link" onClick={() => setCostom(false)}>
                        {`< ${t("Go Back")}`}
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </>
          ) : null}
        </>
      ) : null}
    </Container>
  );
};

export default Filters;
