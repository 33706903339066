import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_QUESTIONNAIRE,GET_QUESTIONNAIRE_LIST, ADD_NEW_QUESTIONNAIRE, DELETE_QUESTIONNAIRE, GET_QUESTIONNAIRE_FULL_DATA, SEND_QUESTIONNAIRE_FROM_TASK } from "./actiontypes"
import {
    getQuestionnaireSuccess,
    getQuestionnaireFail,
    addQuestionnaireFail,
    addQuestionnaireSuccess,
    deleteQuestionnaireSuccess,
    deleteQuestionnaireError,
    getQuestionnairelistSuccess,
    getQuestionnairelistFail,
    getQuestionnaireFulldataSuccess,
    getQuestionnaireFulldataFail,
    sendQuestionnaireFromTaskSuccess,
    sendQuestionnaireFromTaskFail
} from "./actions"

import { AnyAction } from 'redux'
import QuestionnaireService from "../../services/questionnaire.service"
function* fetchQuestionnaire({ payload: { query, history } }: AnyAction): any {
    try {
        const response = yield call(QuestionnaireService.getQuestionnaireDatatableList, { ...query })
        yield put(getQuestionnaireSuccess(response))
    } catch (error) {
        yield put(getQuestionnaireFail(error))
    }
}


function* fetchQuestionnaireList({ payload:data}: AnyAction): any {
    try {
        const response = yield call(QuestionnaireService.getQuestionnaireList, data);
        if (response && response.data && response.data.status === 'success') {
            yield put(getQuestionnairelistSuccess(response.data))
        } else if (response && response.data.status === 'error') {
            yield put(getQuestionnairelistFail(response.data))
        } else {
            yield put(getQuestionnairelistFail(response.data))
        }
    } catch (error) {
        yield put(getQuestionnairelistFail(error))
    }
}


function* fetchQuestionnaireFullData({ payload:data}: AnyAction): any {
    try {
        const response = yield call(QuestionnaireService.getQuestionnaireFulldata, data);
        if (response && response.data && response.data.status === 'success') {
            yield put(getQuestionnaireFulldataSuccess(response.data))
        } else if (response && response.data.status === 'error') {
            yield put(getQuestionnaireFulldataFail(response.data))
        } else {
            yield put(getQuestionnaireFulldataFail(response.data))
        }
    } catch (error) {
        yield put(getQuestionnaireFulldataFail(error))
    }
}



function* onAddNewQuestionnaire({ payload: data }: AnyAction): any {
    try {
        const response = yield call(QuestionnaireService.createQuestionnaireStatus, data)
        if (response && response.data && response.data.status === 'success') {
            yield put(addQuestionnaireSuccess(response.data))
        } else if (response && response.data && response.data.status === 'error') {
            yield put(addQuestionnaireFail(response.data))
        } else {
            yield put(addQuestionnaireFail(response.data))
        }
    } catch (error) {
        yield put(addQuestionnaireFail(error))
    }
}


function* deleteQuestionnaire({ payload: { questionnaire, history } }: AnyAction): any {
    try {
        const response = yield call(QuestionnaireService.deleteQuestionnaire, questionnaire);
        if (response.data && response.data.status === "success") {
            yield put(deleteQuestionnaireSuccess(response.data));
        } else if (response.data && response.data.status === "error") {
            yield put(deleteQuestionnaireError(response.data));
        } else {
            yield put(deleteQuestionnaireError(response));
        }
    } catch (error: any) {
        yield put(deleteQuestionnaireError(error));
    }
}


function* sendQuestionnaireFromTask({ payload: { data } }: AnyAction): any {
    try {
        const response = yield call(QuestionnaireService.sendQuestionnaires, data);
        if (response.data && response.data.status === "success") {
            yield put(sendQuestionnaireFromTaskSuccess(response.data));
        } else if (response.data && response.data.status === "error") {
            yield put(sendQuestionnaireFromTaskFail(response.data));
        } else {
            yield put(sendQuestionnaireFromTaskFail(response));
        }
    } catch (error: any) {
        yield put(sendQuestionnaireFromTaskFail(error));
    }
}

function* questionnaireListSaga(): any {
    yield takeEvery(GET_QUESTIONNAIRE, fetchQuestionnaire)
    yield takeEvery(GET_QUESTIONNAIRE_LIST, fetchQuestionnaireList)
    yield takeEvery(ADD_NEW_QUESTIONNAIRE, onAddNewQuestionnaire)
    yield takeEvery(DELETE_QUESTIONNAIRE, deleteQuestionnaire)
    yield takeEvery(GET_QUESTIONNAIRE_FULL_DATA, fetchQuestionnaireFullData)
    yield takeEvery(SEND_QUESTIONNAIRE_FROM_TASK, sendQuestionnaireFromTask)
    
}

export default questionnaireListSaga
