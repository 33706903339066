import React, { FC, useState, useEffect } from 'react';
import { Col, Container, Input, Label, OffcanvasBody, Row } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import moment from 'moment';
import { toast } from 'react-toastify';
import { invoiceStatuses } from '../../../common/staticdata';
import { formatCurrency } from '../../../utils/formats';
import disputesService from '../../../services/disputes.services';
import invoiceService from '../../../services/invoices.service';
import Button from '../../../components/Button/Button';
import { useTranslation } from 'react-i18next';



type CreditNoteFormProps = {
    values: any;
    onApply?: any;
    onClose: any;
    onChange?: any;
}

interface CreditNoteFormType extends InvoiceType {
    dispute_id: number;
    credit_note_amount: number;
    isSubmitting: boolean;
}

const CreditNoteForm: FC<CreditNoteFormProps> = ({ values, onApply, onClose, onChange }) => {
    const toastId = React.useRef(null) as any;
    const { t } = useTranslation();

    const [formValues, setFormValues] = useState<CreditNoteFormType>({
        ...values,
        invoice_date: moment(new Date()).format('YYYY-MM-DD'),
        due_date: moment(new Date()).format('YYYY-MM-DD'),
        credit_note: `Dispute of ${formatCurrency(values?.credit_note_amount)} is raised by client.`,
        isSubmitting: false
    });

    const handleSubmit = async (values: CreditNoteFormType) => {
        toastId.current = toast("Submitting credit note...", { autoClose: false, isLoading: true });
        setFormValues((state: CreditNoteFormType) => ({ ...state, isSubmitting: true }))
        const result = await invoiceService.createCreditMemo({
            ...formValues,
            amount_due: formValues.amount_due - (formValues.credit_note_amount + ((formValues.tax_rate * formValues.credit_note_amount) / 100)),
            status: "Draft"

        });
        if (result.status === 'success') {
            const res = disputesService.creditNote(formValues.dispute_id, result.data.id);
            toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: 'Credit note submitted successfully', autoClose: 5000, isLoading: false, delay: 500 });
            onApply();
            onClose();
        }
        setFormValues((state: CreditNoteFormType) => ({ ...state, isSubmitting: false }))
    }

    return (
        <React.Fragment>
            <OffcanvasBody>

                {/* <Row>
                    <Col>
                        <div className="mb-3">
                            <Label className="form-label">
                                Paid Amount:
                            </Label>

                        </div>
                    </Col>
                    <Col>
                        <div className="mb-3  rightAlign">
                            <Label className="form-label">
                                {formatCurrency(values?.paid_amount)}
                            </Label>

                        </div>
                    </Col>
                </Row> */}
                <Row>
                    <Col>
                        <div className="mb-3">
                            <Label className="form-label">
                                {t("Date")}
                            </Label>
                            <Row>
                                <Col>
                                    <div className="input-group is-invalid">
                                        <Flatpickr
                                            id="invoice_date"
                                            name="invoice_date"
                                            className="form-control d-block"
                                            type="text"
                                            placeholder="DD/MM/YYYY"
                                            value={formValues?.invoice_date}
                                            readOnly
                                            disabled={true}
                                            options={{
                                                altInput: true,
                                                altFormat: "d/m/Y",
                                                dateFormat: "Y-m-d",
                                                defaultDate: values?.invoice_date,
                                                allowInput: true,
                                                onChange: function (
                                                    selectedDates,
                                                    dateStr,
                                                    instance
                                                ) {
                                                    setFormValues((state: CreditNoteFormType) => ({ ...state, invoice_date: dateStr }))
                                                },
                                                locale: { firstDayOfWeek: 1 }
                                            }}
                                        />
                                        <span className="input-group-text">
                                            <span className="align-middle material-symbols-outlined fw-light fs-4">calendar_today</span>
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>


                <Row>
                    <Col>
                        <div className="mb-3">
                            <Label className="form-label">
                                {t("Amount")}
                            </Label>
                            <Input
                                id={`credit_note_amount`}
                                name='credit_note_amount'
                                placeholder={'100'}
                                className='rightAlign'
                                value={formValues?.credit_note_amount || 0}
                                onChange={(e) => {
                                    setFormValues((state: CreditNoteFormType) => ({ ...state, credit_note_amount: parseFloat(e.target.value) }))
                                }}
                            />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="mb-3">
                            <Label className="form-label">
                                {t("Note")}
                            </Label>
                            <Input
                                id={`credit_note`}
                                name='credit_note'
                                type='textarea'
                                placeholder={'Enter reason for raising the credit note here'}
                                className=''
                                value={formValues?.credit_note || ''}
                                onChange={(e) => {
                                    setFormValues((state: CreditNoteFormType) => ({ ...state, credit_note: e.target.value }))
                                }}
                            />
                        </div>
                        <Button
                            color="primary"
                            style={{ margin: "16px", width: "calc(100% - 64px)", top: "calc(100vh - 70px)", position: "absolute" }}
                            onClick={() => handleSubmit(formValues)}
                            disabled={formValues.isSubmitting || formValues.credit_note_amount <= 0}
                            loading={formValues.isSubmitting}
                            fullWidth
                        >
                            {t("Submit")}
                        </Button>
                    </Col>
                </Row>

            </OffcanvasBody>

        </React.Fragment >
    )
}

export default CreditNoteForm;