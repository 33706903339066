import React, { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import commonService from "../../../services/common.service";
import {
  addIndividualInCompany,
  addAllUsersInUsers,
  getUserProfile,
} from "../../../helpers/functions_helper";
// Static Data
import { fileTypesSearchOptionsTrans } from "../../../common/staticdata";
// Actions
import { CommonAction } from "../../../store/actions";
import { useTranslation } from "react-i18next";

type FiltersProps = {
  filters: any;
  onApply: any;
  onClear: any;
  onChange?: any;
};

const Filters: FC<FiltersProps> = ({ filters, onApply, onClear, onChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userProfile = getUserProfile("file-system");
  const [query, setQuery] = useState("");
  const [loadingCompanyOptions, setLoadingCompanyOptions] = useState(true);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyID, setCompanyID] = useState<any>(null);
  const [disabledCompanyUserOption, setDisabledCompanyUserOption] = useState(true);
  const [loadingCompanyUserOption, setloadingCompanyUserOption] = useState(false);
  const [usersOptions, setUsersOptions] = useState([]);
  const [loadingSourceOptions, setLoadingSourceOptions] = useState(true);
  const [sourceOptions, setSourceOptions] = useState([]);
  const [sourceOptionsTrans, setSourceOptionsTrans] = useState([]);

  let { getCompanyOptionsList, loading } = useSelector((state: any) => ({
    getCompanyOptionsList: state.Common.GET_COMPANY_OPTIONS_LIST_SUCCESS,
    loading: state.Common.loading,
  }));

  // Select options list API call -------------------------------------------------
  const getSelectOptions = async (tableName: string, fieldName: string) => {
    const response = await commonService.getSelectOptionsList(
      tableName,
      fieldName,
      query ? query : ""
    );
    return response.data.data;
  };

  const getDependentSelectOptions = async (
    tableName: string,
    fieldName: string,
    whereField: string,
    whereID: number
  ) => {
    const response = await commonService.getDependentSelectOptionsList(
      tableName,
      fieldName,
      whereField,
      whereID,
      query ? query : ""
    );
    return response.data.data;
  };

  // Get options list -------------------------------------------------
  useEffect(() => {
    if (!getCompanyOptionsList) dispatch(CommonAction.getCompanyOptionsList());
  }, []);

  useEffect(() => {
    if (getCompanyOptionsList.data) {
      const item = addIndividualInCompany(getCompanyOptionsList.data) as any;
      setCompanyOptions(item);
      setLoadingCompanyOptions(false);
    }
  }, [getCompanyOptionsList]);

  useEffect(() => {
    if (companyID) {
      setUsersOptions([]);
      setDisabledCompanyUserOption(true);
      setloadingCompanyUserOption(true);

      getDependentSelectOptions("tbl_master_users", "first_name", "company", companyID).then(
        function (options) {
          setUsersOptions(options);
          setDisabledCompanyUserOption(false);
          setloadingCompanyUserOption(false);
        }
      );
    }
  }, [companyID]);

  useEffect(() => {
    getSelectOptions("tbl_master_module", "module_value").then(function (options) {
      setSourceOptions(options);
      setLoadingSourceOptions(false);
    });
  }, []);

  useEffect(() => {
    var newData: any = [];
    sourceOptions.map((item: any, index: number) => {
      newData.push({ ...item, label: t(item.label) });
    });
    setSourceOptionsTrans(newData);
  }, [sourceOptions, localStorage.getItem("I18N_LANGUAGE")]);

  return (
    <Container>
      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label">{t("File type")}</Label>
            <Select
              placeholder={t("Select...")}
              options={fileTypesSearchOptionsTrans(t)}
              value={
                filters?.file_types
                  ? fileTypesSearchOptionsTrans(t).find(
                      (option: any) => option.value == filters?.file_types
                    )
                  : ""
              }
              isSearchable={true}
              onChange={(option: any) => {
                onChange({ is_filtered: true, file_types: option.value });
              }}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label">{t("Uploaded on")}</Label>
            <Row>
              <Col>
                <div className="input-group is-invalid">
                  <Flatpickr
                    id="from_date"
                    name="from_date"
                    className="form-control d-block"
                    type="text"
                    placeholder="DD/MM/YYYY"
                    value={filters?.from_date}
                    options={{
                      altInput: true,
                      altFormat: "d/m/Y",
                      dateFormat: "Y-m-d H:i:S",
                      maxDate: "today",
                      allowInput: true,
                      onChange: function (selectedDates, dateStr, instance) {
                        onChange({ is_filtered: true, from_date: dateStr });
                      },
                      locale: { firstDayOfWeek: 1 },
                    }}
                  />
                  <span className="input-group-text">
                    <span className="align-middle material-symbols-outlined fw-light fs-4">
                      calendar_today
                    </span>
                  </span>
                </div>
              </Col>
              <Col>
                <div className="input-group is-invalid">
                  <Flatpickr
                    id="to_date"
                    name="to_date"
                    className="form-control d-block"
                    type="text"
                    placeholder="DD/MM/YYYY"
                    value={filters?.to_date}
                    options={{
                      altInput: true,
                      altFormat: "d/m/Y",
                      dateFormat: "Y-m-d H:i:S",
                      minDate: filters?.from_date,
                      maxDate: "today",
                      allowInput: true,
                      onChange: function (selectedDates, dateStr, instance) {
                        onChange({ is_filtered: true, to_date: dateStr });
                      },
                      locale: { firstDayOfWeek: 1 },
                    }}
                  />
                  <span className="input-group-text">
                    <span className="align-middle material-symbols-outlined fw-light fs-4">
                      calendar_today
                    </span>
                  </span>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      {userProfile.IS_INTERNAL ? (
        <>
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label">
                  {t("Uploaded by")} ({t("Client")})
                </Label>
                <Select
                  placeholder={t("Select...")}
                  options={companyOptions}
                  value={
                    filters?.company_id
                      ? companyOptions.find((option: any) => option.value == filters?.company_id)
                      : ""
                  }
                  isSearchable={true}
                  isDisabled={loadingCompanyOptions}
                  isLoading={loadingCompanyOptions}
                  onChange={(option: any) => {
                    setCompanyID(option.value);
                    onChange({ is_filtered: true, company_id: option.value });
                  }}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label">
                  {t("Uploaded by")} ({t("User")})
                </Label>
                <Select
                  placeholder={t("Select...")}
                  options={usersOptions}
                  value={
                    filters?.user_id
                      ? usersOptions.find((option: any) => option.value == filters?.user_id)
                      : ""
                  }
                  isSearchable={true}
                  isDisabled={disabledCompanyUserOption}
                  isLoading={loadingCompanyUserOption}
                  onChange={(option: any) => {
                    onChange({ is_filtered: true, user_id: option.value });
                  }}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label">{t("Source")}</Label>
                <Select
                  placeholder={t("Select...")}
                  options={sourceOptionsTrans}
                  value={
                    filters?.source_id
                      ? sourceOptionsTrans.find((option: any) => option.value == filters?.source_id)
                      : ""
                  }
                  isSearchable={true}
                  isDisabled={loadingSourceOptions}
                  isLoading={loadingSourceOptions}
                  onChange={(option: any) => {
                    onChange({ is_filtered: true, source_id: option.value });
                  }}
                />
              </div>
            </Col>
          </Row>
        </>
      ) : null}
    </Container>
  );
};

export default Filters;
