import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper";
import * as url from "../helpers/url_helper";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class AttachmentsService {
  async getAttachmentsList(DATA: any) {
    const response = await axios
      .post(SERVER_URL + url.GET_ATTACHMENTS_LIST, DATA, {
        headers: getAxiosConfigWithToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }
}

export default new AttachmentsService();
