import fileDownload from 'js-file-download';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { Container, Card, Spinner } from 'reactstrap';
import MultiComments from '../../components/Common/MultiComments';
import Drawer from '../../components/Drawer';
import Breadcrumbs from '../../components/Layout/Breadcrumb';
import Loader from '../../components/Layout/Loader';
import { getUserProfile } from '../../helpers/functions_helper';
import invoicesService from '../../services/invoices.service';
import ViewInvoice from './ViewInvoice';





const ViewInvoicePage = () => {

    const { id, type } = useParams();
    const { t } = useTranslation();
    const userObj = getUserProfile();
    const navigate = useNavigate();

    const breadcrumbItems = [
        {
            url: "/dashboard",
            label: t("Dashboard"),
            active: false,
        },
        {
            url: "/invoices-management/invoices/all",
            label: t("Invoices"),
            active: false,
        },
    ];

    const currentPage = {
        url: "#",
        label: t("View Invoice"),
        active: true,
    };

    const bLabel: any = {
        "all": t("All Invoices"),
        "notpaid": t("Not Paid Invoices"),
        "paid": t("Fully Paid Invoices"),
        "overdue": t("Over Due Invoices"),
        "creditnote": t("Credit Notes"),
        "followup": t("Follow Up")
    }


    const [breadCrumbs, setBreadCrumbs] = useState<any>(breadcrumbItems);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (type) {
            const item = {
                url: "/invoices-management/invoices/" + type,
                label: bLabel[type],
                active: false,
            };
            setBreadCrumbs([...breadcrumbItems, item, currentPage]);
        } else {
            setBreadCrumbs([...breadcrumbItems, currentPage]);
        }
    }, [id, type, t])

    const toastId = React.useRef(null) as any;

    const [invoice, setInvoice] = useState<InvoiceType>();
    const [openComment, setOpenComment] = useState<boolean>(false);

    const getInvoiceData = async (id: number) => {
        const res = await invoicesService.getInvoice(id);
        if (res.status === 'success') {
            setInvoice(res?.data);
        } else {
            navigate('/404')
        }
        setLoading(false);
    }

    const handleSend = async (id: number) => {
        toast.dismiss();
        toastId.current = toast(t("Sending an invoice..."), { autoClose: false, isLoading: true });
        try {
            const res = (await invoicesService.sendInvoice(id)) as any;
            if (res.status === "success") {
                toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: t('Invoice sent successfully'), autoClose: 5000, isLoading: false, delay: 500 });
                setLoading(true);
            } else {
                toast.update(toastId.current, { type: toast.TYPE.ERROR, render: t('Something went wrong.'), autoClose: 5000, isLoading: false, delay: 500 });
            }
        } catch (err: any) {
            toast.update(toastId.current, { type: toast.TYPE.ERROR, render: err, autoClose: 5000, isLoading: false, delay: 500 });
        }
    };

    const handleDownload = async (invoice: any) => {
        toastId.current = toast(t("Downloading invoice preview..."), {
            autoClose: false,
            isLoading: true,
        });
        // window.open(process.env.REACT_APP_SERVER_URL + `/invoices/download/${invoice.id}`, '_blank')
        const res = await invoicesService.downloadInvoice(invoice?.id);
        if (res.status === 200) {
            fileDownload(res.data, 'invoice-' + invoice.invoice_number + '.pdf');
            toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: t('Invoice downloaded successfully'), autoClose: 5000, isLoading: false, delay: 500 });
        } else {
            toast.update(toastId.current, { type: toast.TYPE.ERROR, render: t('Sorry, something went wrong!'), autoClose: 5000, isLoading: false, delay: 500 });
        }
    }

    useEffect(() => {
        //@ts-ignore
        loading && getInvoiceData(parseInt(id, 10));
    }, [id, loading])

    document.title = `${t("View Invoice")} | ${process.env.REACT_APP_NAME}`;

    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs
                    breadcrumbTitle={t(`View Invoice`)}
                    breadcrumbItems={breadCrumbs || []}
                    endAdornment={
                        <>
                            <button
                                className="btn btn-primary waves-light btn-label btn-sm me-1"
                                onClick={() => handleDownload(invoice)}
                            >
                                <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                    download
                                </span>
                                {t("Download")}
                            </button>

                            <button
                                className="btn btn-primary waves-light btn-label btn-sm me-1"
                                onClick={() => handleDownload(invoice)}
                            >
                                <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                    print
                                </span>
                                {t("Print")}
                            </button>

                            {(userObj.IS_ADMIN && userObj.USER_COMPANY === 1) && (invoice?.status === 'Draft' || invoice?.status === 'Sent') ?
                                <button
                                    className="btn btn-primary waves-light btn-label btn-sm me-1"
                                    onClick={() => handleSend(invoice?.id || 0)}
                                >
                                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                        send
                                    </span>
                                    {invoice.status === 'Sent' ? t('Resend') : t('Send')}
                                </button> : null}

                            {invoice?.invoice_type === "INVOICE" ?
                                <>
                                    {((userObj.IS_ADMIN && userObj.USER_COMPANY === 1) || userObj.IS_PARTNER) && invoice.status === 'Draft' ?
                                        <Link
                                            to={`/invoices-management/edit-invoice/${id}`}
                                            className="btn btn-primary waves-light btn-label btn-sm me-1"
                                        >
                                            <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                                edit
                                            </span>
                                            {t("Edit")}
                                        </Link> : null}

                                    {userObj.USER_COMPANY === 1 && <button
                                        onClick={() => setOpenComment(true)}
                                        className="btn btn-primary waves-light btn-label btn-sm me-1"
                                    >
                                        <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                            comment
                                        </span>
                                        {t("Comments")}
                                    </button>
                                    }

                                    {(userObj.IS_ADMIN || userObj.IS_PARTNER) && userObj.USER_COMPANY === 1 && invoice.status === 'Sent' && invoice.amount_due > 0 ?
                                        <Link
                                            to={`/invoices-management/new-credit-Note/${id}`}
                                            className="btn btn-primary waves-light btn-label btn-sm"
                                        >
                                            <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">add</span> {t("Create Credit Note")}
                                        </Link> : null}

                                </> : ""}
                        </>
                    }
                />
                <div style={{ padding: '10px 8%' }}>
                    {invoice ? <ViewInvoice {...invoice} /> : <Loader />}
                </div>
            </Container>
            <Drawer
                title={t("Comments")}
                open={openComment}
                onClose={() => setOpenComment((state) => !state)}
                style={{ width: '750px' }}
            >
                <MultiComments
                    moduleData={{
                        module_app_id: 5,
                        module_main_id: id,
                        module_sub_id: invoice?.invoice_id,
                        section_id: 0,
                    }}
                    commentForIDs={invoice?.status === 'Sent' ? [
                        {
                            value: invoice?.contact_person_details?.id,
                            label: invoice?.contact_person_details?.first_name + ' ' + invoice?.contact_person_details?.last_name,
                            isHidden: userObj.USER_COMPANY === 1 ? false : true,
                        },
                    ] : []}
                    fetchData={true}
                    isOpen={false}
                />
            </Drawer>
        </div>
    )
}

export default ViewInvoicePage;