import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactApexChart from "react-apexcharts";
import { getUserProfile } from '../../../../helpers/functions_helper';
import { serialize } from '../../../../utils/utils';
import { noData } from '../../../../utils/data';
import GraphFilters from './Filters';
import LoadingSpinner from '../../../../components/Common/Loader';
import mastersCompanyService from '../../../../services/masters.company.service';
import { currentMonth, currentYear } from '../../../../common/staticdata';
import ChartFilters from '../../../../components/Chart/Filters';
import Chart from '../../../../components/Chart';

interface QuarterCompanyProps extends CartProps {
    filters?: any;
}

const QuarterCompany: FC<QuarterCompanyProps> = ({ hideHeading, filters = null }) => {

    const { t } = useTranslation();

    const userObj = getUserProfile();

    const inititalFilter = {
        week: null,
        quarter: null,
        month: null,
        year: currentYear,
        user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_ID : null,
        graphview: 'quarter',
    };

    const [loading, setLoading] = useState<boolean>(true);
    const [query, setQuery] = useState<string | null>(serialize(inititalFilter));
    const [filter, setFilter] = useState<any>(inititalFilter);

    const [data, setData] = useState<any>({
        series: [],
        options: {
            plotOptions: {
                pie: {
                    donut: {
                        size: '50%',
                    },

                },
            },
            labels: ['Q1', 'Q2', 'Q3', 'Q4'],
            legend: {
                show: true,
                position: 'bottom',
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        show: true,
                        position: 'left'
                    }
                }
            }],

            noData: noData,
        },
    })

    const getChartData = async () => {
        const result = await mastersCompanyService.getCompaniesPerTask(query);
        if (result.status === 'success') {
            setData((data: any) => ({
                ...data,
                series: result?.data?.yaxis,
                options: {
                    ...data.options,
                    labels: result?.data?.xaxis,
                }

            }))
        }
        setLoading(false);
    }

    const clearWorkAllocationFilter = () => {
        setFilter(inititalFilter);
    }

    useEffect(() => {
        loading && getChartData();
    }, [loading])

    useEffect(() => {
        setQuery(serialize({ ...filter, graphview: 'quarter' }));
        setLoading(true);
    }, [filter])

    useEffect(() => {
        filters && setFilter(filters)
    }, [filters])

    return (
        <>
            <div className="d-sm-flex flex-wrap">
                <h4 className="card-title mb-4">{!hideHeading && t("Total number of companies created per quarter")}</h4>
                <div className="ms-auto">
                    <ChartFilters
                        filter={filter}
                        setFilter={setFilter}
                        clearFilter={clearWorkAllocationFilter}
                    />
                </div>
            </div>
            <div className="clearfix"></div>
            {loading ? <LoadingSpinner height={380} /> : <div style={{ height: '100%' }}><Chart options={data.options} series={data.series} type="pie" height={380} /></div>}
        </>
    )
}

export default QuarterCompany;