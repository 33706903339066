import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import ReactApexChart from "react-apexcharts";
import { noData } from '../../../utils/data';
import LoadingSpinner from '../../../components/Common/Loader';
import { serialize } from '../../../utils/utils';
import { formatCurrency } from '../../../utils/formats';
import GraphFilters from '../../../components/Chart/Filters';
import { getUserProfile, graph_i18 } from '../../../helpers/functions_helper';
import tasksService from "../../../services/tasks.service";
import { useNavigate } from 'react-router-dom';
import gfilters from "../filter";
import { toast } from 'react-toastify';
import Chart from "../../../components/Chart";
import { currentMonth, currentYear } from '../../../common/staticdata';
interface RevenueStreamProps extends CartProps {
    filters?: any;
}

const TasksByCountry: FC<RevenueStreamProps> = ({ hideHeading, filters = null }) => {

    const { t } = useTranslation();

    const userObj = getUserProfile('all-tasks');
    const navigate = useNavigate();
    const inititalFilter = {
        week: null,
        quarter: null,
        month: currentMonth,
        year: currentYear,
        user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_ID : null,
        user_name: null
    };

    const [loading, setLoading] = useState<boolean>(true);
    const [query, setQuery] = useState<string | null>(serialize(inititalFilter));
    const [filter, setFilter] = useState<any>(inititalFilter);
    const [selectedID, setSelectedID] = useState<any>({ id: null, event: null });

    const viewTaskList = (i: any) => {
        if (data.ids) {
            const x = data.ids[i];
            setSelectedID({ id: null, event: null });
            if (x !== -1) {
                let _filter = {
                    type: 'fees',
                    id: x,
                    label: 'taskOriginated'
                }
                gfilters.setFilter(_filter);
                navigate('../tasks/all-tasks');
            }
        }
    }

    useEffect(() => {
        if (selectedID?.event === 'click') {
            viewTaskList(selectedID.id);
        }
    }, [selectedID])

    const [data, setData] = useState<any>({
        series: [],
        options: {
            chart: {
                width: 380,
                type: 'pie',
            },
            labels: [],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                        height: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            legend: {
                show: true,
                position: 'right',
                width: 127,
                formatter: function (seriesName: any, opts: any) {
                    if (seriesName)
                        return seriesName.length > 7 ? seriesName.substr(0, 7) + '..' : seriesName;
                    else
                        return '';
                }
            },
            noData: noData
        },
    })

    const getChartData = async () => {
        try {
            const result = await tasksService.getTaskByCountry(query);
            const local_i18n = graph_i18();
            if (result.data.status === 'success') {
                setData((data: any) => ({
                    ...data,
                    series: result?.data?.data[0].data || [],
                    ids: result?.data?.data[0].ids || [],
                    options: {
                        ...data.options,
                        labels: result?.data?.data[0].label || [],
                        chart: {
                            ...data.options.chart,
                            locales: [local_i18n],
                            defaultLocale: local_i18n?.name,
                        }
                    }

                }))
            }
            setLoading(false);
        } catch (error: any) {
            toast(error)
        }

    }

    const clearWorkAllocationFilter = () => {
        setFilter(inititalFilter);
    }

    useEffect(() => {
        loading && getChartData();
    }, [loading])

    useEffect(() => {
        setQuery(serialize(filter));
        setLoading(true);
    }, [filter])

    useEffect(() => {
        filters && setFilter(filters)
    }, [filters])


    useEffect(() => {
        setLoading(true);
    }, [t])

    return (
        <div style={{ height: '370px' }}>
            <div className="d-sm-flex flex-wrap">
                <h4 className="card-title mb-4">{!hideHeading && t("Tasks By Country")}</h4>
                <div className="ms-auto">
                    <GraphFilters
                        filter={filter}
                        setFilter={setFilter}
                        clearFilter={clearWorkAllocationFilter}
                        enableUser={true}
                        enableCompany={true}
                        slug='all-tasks'
                    />
                </div>
            </div>
            <div className="clearfix"></div>
            {loading ? <LoadingSpinner height={380} /> : <div style={{ height: '100%' }}><Chart options={data.options} series={data.series} type="pie" height={380} /></div>}
        </div>
    )
}

export default TasksByCountry;