import React, { useEffect, useState } from "react";
import { Card, CardTitle, CardBody, Table, Button, Badge } from "reactstrap";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import ProgressBar from "../../components/Common/ProgressBar";
import tasksService from "../../services/tasks.service";
import invoicesService from "../../services/invoices.service";
import { dateFormat } from "../../utils/formats";
import { statusBadge } from "../../common/staticdata";
import NoDataFound from "../../components/NoDataFound";
import { useTranslation } from "react-i18next";

const InvoiceActivityList = (props: any) => {
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    // Get comment ------------------------------------------------------------
    const [invoices, setInvoices] = useState<any>([]);
    const [activeTab, toggleTab] = useState("1");
    const getTaskList = async () => {
        const response = await invoicesService.getInvoicesList({ type: activeTab });
        if (response.data.status === "success") {
            if (activeTab === '3')
                setInvoices(response.data.data?.filter((i: any) => !i.credit_note_details || i.credit_note_details?.amount_due !== 0));
            else
                setInvoices(response.data.data);

            setLoading(false);
        }
    };

    useEffect(() => {
        setLoading(true);
        setInvoices([]);
        getTaskList();
    }, [activeTab]);
    // Get comment ------------------------------------------------------------
    function createMarkup(val: any) {
        return { __html: val };
    }

    return (
        <React.Fragment>

            <div className="d-sm-flex flex-wrap">
                <CardTitle className="mb-3">{t("Invoices")}</CardTitle>
                <div className="ms-auto">
                    <Link className="btn-link" to="/invoices-management/invoices/all">
                        {t("View All")}
                    </Link>
                </div>
            </div>
            <div className="mb-3">
                <Button
                    size="sm"
                    color={`${activeTab === "1" ? "primary" : ""}`}
                    onClick={() => {
                        toggleTab("1");
                    }}
                >
                    {t("TO DO")}
                </Button>
                {/* <Button
                    size="sm"
                    color={`${activeTab === "2" ? "primary" : ""}`}
                    className={`ml-1`}
                    onClick={() => {
                        toggleTab("2");
                    }}
                >
                    Upcoming
                </Button> */}
                <Button
                    size="sm"
                    color={`${activeTab === "3" ? "primary" : ""}`}
                    className={`ml-1`}
                    onClick={() => {
                        toggleTab("3");
                    }}
                >
                    {t("Follow up")}
                </Button>
            </div>

            <SimpleBar style={{ height: 376 }}>
                <Table className="table table-wrap align-middle table-hover mb-0">
                    <thead>
                        <tr>
                            <th>{t("Invoice Number")}</th>
                            <th>{t("Company")}</th>
                            <th>{t("Status")}</th>
                            <th>{t("Date")}</th>
                            <th>{t("Due Date")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoices && invoices.length > 0 ? (
                            invoices.map((item: any, index: number) => {
                                const color = statusBadge.find((i: any) => i.status === item.status)?.color || "primary";
                                return (
                                    <tr key={index}>
                                        <td >
                                            <h5
                                                className="text-truncate font-size-14 mb-1"
                                            >

                                                <div className="">
                                                    <div>
                                                        <Link
                                                            to={
                                                                "/invoices-management/view-invoice/" +
                                                                item.id
                                                            }
                                                            className="text-decoration-underline"
                                                        >
                                                            {item.invoice_number}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </h5>
                                            {/* <div>
                                                <ProgressBar progress={item.task_progress} />
                                            </div> */}
                                        </td>
                                        <td>
                                            {`${item.company_name} ${item.company_id === 0 ? ' - ' + item?.contact_person : ''}`}
                                        </td>
                                        <td>
                                            <div className="text-muted mb-0 ms-auto" style={{ fontSize: '11px', fontWeight: 'normal', marginTop: '2px' }}>
                                                <Badge color={color} pill className={`badge_custome  badge-soft-${color}`}>
                                                    {t(item.status)}
                                                </Badge>
                                            </div>
                                        </td>
                                        <td>{item.status === 'Sent' ? dateFormat(item.invoice_date) : ' - '}</td>
                                        <td>
                                            {item.status === 'Sent' ? dateFormat(item.due_date) : ' - '}
                                        </td>
                                    </tr>
                                );
                                //}
                            })
                        ) : (
                            <tr>
                                {loading ? (
                                    <td>
                                        <div>
                                            <h5 className="font-size-14 mb-1 placeholder-glow">
                                                <span className="placeholder col-6"></span>
                                            </h5>
                                            <p className="text-muted mb-1 placeholder-glow">
                                                <span className="placeholder col-12"></span>
                                            </p>
                                            <p className="text-muted mb-0 placeholder-glow">
                                                <span className="placeholder col-12"></span>
                                            </p>
                                        </div>
                                    </td>
                                ) : (
                                    <td className="align-middle border-0" style={{ height: '200px' }} colSpan={5}>
                                        <NoDataFound icon="receipt_long" message={t("You have no invoice to view!")} />
                                    </td>
                                )}
                            </tr>
                        )}
                    </tbody>
                </Table>
            </SimpleBar>
        </React.Fragment>
    );
};

export default InvoiceActivityList;
