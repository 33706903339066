import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Form,
  Label,
  Input,
  Button,
  FormFeedback,
  Spinner,
  Table,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import labelPlugin from "flatpickr/dist/plugins/labelPlugin/labelPlugin";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import {
  getUserProfile,
  getCustomeCode,
  getSelectOptions,
  getDependentSelectOptions,
  getOptionById,
  getOptionValueById,
  addIndividualInCompany,
  getFirstErrorElement,
  // yearsBackword,
  select2Styles,
  getMonthlyDaysInGivenYear,
  getTotalDaysInGivenYear,
  vLookup,
  getDependentUserOptions_New,
  datePickerI18,
} from "../../helpers/functions_helper";
import { dateFormat, formatCurrency } from "../../utils/formats";
import {
  initialValues,
  personalInfoInitialValues,
  familyStatuses,
  yesNo,
  taxYear,
  // companyCarStatuses,
  companyCarPercentile,
  empDirIncome,
  // ssSocialSecurity,
  // P_CHILDREN,
  // P_TAXES_EXEMPT_AMOUNT,
  // P_Expenses,
  // P_TAXES,
  P_SSSC,
} from "./helper";
import Loader from "../../components/Layout/Loader";
import cpoService from "../../services/cpo.service";
import Drawer from "../../components/Drawer";
import EditCompany from "../Masters/Company/EditCompany";
import EditUser from "../Users/EditUser";
import PersonalInfoModel from "./PersonalInfoModel";
import FringeBenefitCompanyCarModel from "./FringeBenefitCompanyCarModel";
import EmployeeDirectorIncomeModel from "./EmployeeDirectorIncomeModel";

import CPOReport from "./CPOReport";

import SSSCLocalModel_21 from "./SSSCLocalModel_21";
import SSSCModel_21 from "./SSSCModel_21";
import { renderRowHTML } from "../../utils/utils";

const CPOForm = (props: any) => {
  const { t } = useTranslation();

  const userProfile = getUserProfile("all-cpo");
  const companyProfile = getUserProfile("all-company");
  const usersProfile = getUserProfile("all-users");
  const toastId = React.useRef(null) as any;
  const dispatch = useDispatch();
  let { id, cpo_id } = useParams();
  let navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [reportStatus, setReportStatus] = useState("DRAFT");
  const [selectedData, setSelectedData] = useState<any>();
  const [openCompany, setOpenCompany] = useState<boolean>(false);
  const [openUser, setOpenUser] = useState<boolean>(false);
  const [loadingCode, setLoadingCode] = useState(true);
  const [query, setQuery] = useState("");
  const [loadingCompanyOptions, setLoadingCompanyOptions] = useState(true);
  const [disabledCompanyOptions, setDisabledCompanyOptions] = useState(true);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [loadingCompanyUserOption, setLoadingCompanyUserOption] = useState(false);
  const [disabledCompanyUserOption, setDisabledCompanyUserOption] = useState(true);
  const [userOptions, setUserOption] = useState([]);
  const [disabledSpouseEarns, setDisabledSpouseEarns] = useState(true);
  const [loadingMunicipalOptions, setLoadingMunicipalOptions] = useState(true);
  const [disabledMunicipalOption, setDisabledMunicipalOption] = useState(true);
  const [municipalOptions, setMunicipalOption] = useState([]);

  const [loadingSPYearsData, setloadingSPYearsData] = useState(true);
  const [spYearsData, setSPYearsData] = useState<any[]>([]);
  const [spCountryData, setSPCountryData] = useState<any[]>([]);

  const [spFringeBenefitData, setSPFringeBenefitData] = useState<any[]>([]);
  const [disabledGrossSalary, setDisabledGrossSalary] = useState(false);
  const [disabledFringeBenefit, setDisabledFringeBenefit] = useState(true);
  const [initialData, setInitialData] = useState(initialValues);

  const [personalInfoInitialData, setPersonalInfoInitialData] = useState(personalInfoInitialValues);
  const [showPersonalInfoModel, setShowPersonalInfoModel] = useState(false);

  const [computationYearsData, setComputationYearsData] = useState<any[]>([]);
  const [daysInComputationYear, setDaysInComputationYear] = useState<number>(365);
  const [minBIKPerYear, setMinBIKPerYear] = useState<number>(
    personalInfoInitialValues.min_bik_per_year
  ); // MASTER VALUE

  const [runCalculation, setRunCalculation] = useState(false);
  const [showCompanyCarModel, setShowCompanyCarModel] = useState(false);
  const [fringeBenefitCalcTotalData, setFringeBenefitCalcTotalData] = useState<any>({});
  const [showEmployeeDirectorIncomeModel, setShowEmployeeDirectorIncomeModel] = useState(false);
  const [revaluationFactor, setRevaluationFactor] = useState<number>(
    personalInfoInitialValues.revaluation_factor
  ); // MASTER VALUE
  const [D_INCOME_TOTAL, setD_INCOME_TOTAL] = useState<any>({
    D_EMPLOYEE_INCOME_TOTAL: 0,
    D_DIRECTOR_INCOME_TOTAL: 0,
    SS_PREMIUM_SELF_EMPLOYED_TOTAL: 0,
  });
  const [LEGAL_PREMIUM, setLEGAL_PREMIUM] = useState<any>({});

  const [C_COM_TAX_BELGIUM, setC_COM_TAX_BELGIUM] = useState<number>(0); // CALCULATED VALUE
  const [C_TOTAL_TAX3, setC_TOTAL_TAX3] = useState<number>(0);
  const [C_SSSC, setC_SSSC] = useState<number>(0);
  const [C_SSSC_BE, setC_SSSC_BE] = useState<number>(0);
  const [C_TOTAL_TAX3_BE, setC_TOTAL_TAX3_BE] = useState<number>(0);
  const [showSSSCLocalModel_21, setShowSSSCLocalModel_21] = useState<boolean>(false);
  const [showSSSCModel, setShowSSSCModel] = useState<number>(0);

  // TOTAL
  const [C_GS_TOTAL, setC_GS_TOTAL] = useState<number>(0);
  const [C_BIK_TOTAL, setC_BIK_TOTAL] = useState<number>(0);
  const [C_SS_TOTAL, setC_SS_TOTAL] = useState<number>(0);
  const [C_TI_TOTAL, setC_TI_TOTAL] = useState<number>(0);
  const [C_SSSC_TOTAL, setC_SSSC_TOTAL] = useState<number>(0);
  const [C_GI_TOTAL, setC_GI_TOTAL] = useState<number>(0);
  const [C_NI_TOTAL, setC_NI_TOTAL] = useState<number>(0);

  const [yesNoList, setYesNoList] = useState([]);
  const [familyStatusesList, setFamilyStatusesList] = useState([]);
  const [empDirIncomeList, setEmpDirIncomeList] = useState([]);
  const [companyCarStatusesList, setCompanyCarStatusesList] = useState([]);

  let { getCompanyOptionsList, getCompanyOptionsList_New } = useSelector((state: any) => ({
    getCompanyOptionsList: state.Common.GET_COMPANY_OPTIONS_LIST_SUCCESS,
    getCompanyOptionsList_New: state.Common.GET_COMPANY_OPTIONS_LIST_SUCCESS_NEW,
  }));

  // const redirect = (submit_btn_code?: number, id?: number, cpo_id?: string) => {
  //   if (submit_btn_code == 1) {
  //     return navigate("/cpo/new-cpo");
  //   } else if (submit_btn_code == 2) {
  //     return navigate("/cpo/list/all");
  //   } else if (submit_btn_code == 3) {
  //     return navigate("/cpo/edit-cpo/" + id + "/" + cpo_id);
  //   } else if (submit_btn_code == 4) {
  //     return navigate("/cpo/view-cpo/" + id + "/" + cpo_id);
  //   } else {
  //     return navigate("/cpo/list/all");
  //   }
  // };

  function updateInitialData(data: any) {
    const updatedInitialData = {
      ...initialData,
      id: data.id,
      cpo_id: data.cpo_id,
      code: data.code,
      custome_code: data.custome_code,
      company_id: data.company_id,
      company_value: data.company_value,
      employee_id: data.employee_id,
      employee_value: data.employee_value,
      employee_email: data.email,
      family_status: data.family_status, // D_FAM_STAT
      spouse_earns: data.spouse_earns, // D_ATTRIBUTION
      children_total: data.children_total, // D_CHILDREN
      children_under_3: data.children_under_3, // D_CHILDREN_BELOW_3
      municipal_id: data.municipal_id === null ? "" : data.municipal_id,
      is_default_tax: data.is_default_tax,
      municipal_tax: Number(data.municipal_tax), // D_COM_TAX
      computation_year: Number(data.computation_year),
      worldwide_gross_salary: Number(data.worldwide_gross_salary),
      sp_country_data: data.sp_country_data,
      sp_fringe_benefit_data: data.sp_fringe_benefit_data,
      fringe_benefit: data.fringe_benefit,
      fringe_benefit_date: data.fringe_benefit_date, // D_DATE_CAR
      co_2_emission: Number(data.co_2_emission), // D_CO2_CAR
      catalogue_price: Number(data.catalogue_price), // D_PRICE_CAR
      sssc_application: data.sssc_application,
      employee_group_insurance_premiums: Number(data.employee_group_insurance_premiums), // D_GROUP_INS
      benefits_in_social_security: Number(data.benefits_in_social_security),

      created_by_first_name: data.created_by_first_name,
      created_by_last_name: data.created_by_last_name,
      created_at: data.created_at,
      updated_by_first_name: data.updated_by_first_name,
      updated_by_last_name: data.updated_by_last_name,
      updated_at: data.updated_at,
    };
    setInitialData(updatedInitialData);
    setSPCountryData(JSON.parse(data.sp_country_data));
    setSPFringeBenefitData(JSON.parse(data.sp_fringe_benefit_data));
    if (data.worldwide_gross_salary > 0) {
      setDisabledGrossSalary(true);
    } else {
      setDisabledGrossSalary(false);
    }
  }

  function updatePersonalInfoInitialData(data: any) {
    const updatedInitialData = {
      ...personalInfoInitialData,
      tax_free_amount_for_attribution_to_spouse: data.tax_free_amount_for_attribution_to_spouse, // P_TAXFREE_MARRIED
      tax_free_amount_for_children_less_3_year_old:
        data.tax_free_amount_for_children_less_3_year_old, // P_CHILDREN_BELOW_3
      tax_free_amount_for_single_parents: data.tax_free_amount_for_single_parents, // P_Single_Parent
      // tax_free_amount_for_taxpayer: data.tax_free_amount_for_taxpayer, // P_TAXFREE_TAXPAYER
      // tax_free_amount_for_spouse: data.tax_free_amount_for_spouse, // P_TAXFREE_SPOUSE
      business_expenses_director_percentage: data.business_expenses_director_percentage,
      business_expenses_director_maximum_amount: data.business_expenses_director_maximum_amount,
      P_Expenses: JSON.parse(data.P_Expenses),
      commuting_exemption: data.commuting_exemption, // P_COMM_EXEMPT
      attribution_to_spouse: data.attribution_to_spouse, // P_ATTRIBUTION
      min_bik_per_year: data.min_bik_per_year,
      revaluation_factor: data.revaluation_factor,

      P_TAXES_EXEMPT_AMOUNT: JSON.parse(data.P_TAXES_EXEMPT_AMOUNT),
      P_TAXES: JSON.parse(data.P_TAXES),
      P_CHILDREN: JSON.parse(data.P_CHILDREN),
      companyCarStatuses: JSON.parse(data.companyCarStatuses),
      SS_SOCIAL_SECURITY: JSON.parse(data.SS_SOCIAL_SECURITY),
    };
    setPersonalInfoInitialData(updatedInitialData);

    setMinBIKPerYear(data.min_bik_per_year);
    setRevaluationFactor(data.revaluation_factor);
  }

  const get = async () => {
    try {
      const res = (await cpoService.get({ id, cpo_id })) as any;
      if (res.data.status === "success") {
        updateInitialData(res.data.data[0]);
        setLoading(false);
      } else {
        toast(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 5000,
          delay: 500,
        });
        setLoading(false);
        handleRedirect();
      }
    } catch (err: any) {
      toast(err?.message, {
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        delay: 500,
      });
      setLoading(false);
      handleRedirect();
    }
  };

  const create = async (values: cpoTypes) => {
    try {
      toastId.current = toast(`${t("Creating")} CPO...`, {
        autoClose: false,
        isLoading: true,
      });

      const res = (await cpoService.create(values)) as any;
      if (res.data.status === "success") {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoading(false);
        handleRedirect();
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        if (res.data.data) {
          Object.keys(res.data.data).map((key) => {
            toast(res.data.data[key][0], {
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              delay: 500,
            });
          });
        }
        setLoading(false);
      }
    } catch (err: any) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }
  };

  const update = async (values: cpoTypes) => {
    try {
      toastId.current = toast(`${t("Updating")} CPO...`, {
        autoClose: false,
        isLoading: true,
      });

      const res = (await cpoService.update(values)) as any;
      if (res.data.status === "success") {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoading(false);
        handleRedirect();
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        if (res.data.data) {
          Object.keys(res.data.data).map((key) => {
            toast(res.data.data[key][0], {
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              delay: 500,
            });
          });
        }
        setLoading(false);
      }
    } catch (err: any) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }
  };

  const getSPYear = async () => {
    const res = (await cpoService.getSPYear()) as any;
    if (res.data.status === "success") {
      setSPYearsData(res.data.data);
      setloadingSPYearsData(false);
      setLoading(false);
    } else {
      setSPYearsData([]);
      setloadingSPYearsData(false);
      setLoading(false);
    }
  };

  const getSPCountry = async () => {
    if (spCountryData.length === 0) {
      const res = (await cpoService.getSPCountry()) as any;
      if (res.data.status === "success") {
        const spCountries = res.data.data;

        const spCountriesArray: any = [];
        // spCountries.forEach((item: any) => {
        const item = spCountries[0];
        spCountriesArray.push({
          country_id: item.country_id,
          country_name: item.country_name,
          tax_year_id: item.tax_year,
          tax_year_name: item.tax_year_option_name,
          gross_salary: 0,
          percentage: 0,
          is_foreign_tax_liable: item.is_foreign_tax_liable,
          foreign_tax_liability: 0,
          default_social_security_percentage: Number(item.default_social_security_percentage),
          social_security_percentage: Number(item.default_social_security_percentage),
          social_security_percentage_custome_value_edit: 0,
          social_security_percentage_custome_value: 0,
          social_security_fixed_amount: 0,
          social_security_fixed_amount_custome_value_edit: 0,
          social_security_fixed_amount_custome_value: 0,
          amount_without_social_security: 0,
          amount_without_social_security_custome_value_edit: 0,
          amount_without_social_security_custome_value: 0,
          emp_dir: 1,

          D_GROSS_COUNTRY: 0,
          D_COMP_CAR: 0, // For Belgium only idx === 0
          D_EMPLOYEE_INCOME: 0, // emp_dir === 1 ? D_GROSS_<COUNTRY> : 0
          D_DIRECTOR_INCOME: 0, // emp_dir === 2 ? D_GROSS_<COUNTRY> + D_COMP_CAR* : 0 // *For Belgium only idx === 0
          SS_PREMIUM_SELF_EMPLOYED: 0,
          D_SS_COUNTRY: 0,
          D_TTL_TAXABLE_INCOME: 0,
          C_TAXES_COUNTRY: 0,
          D_GROUP_INSURANCE: 0,
          D_TTL_NET_INCOME: 0,
        });
        // });
        setSPCountryData(spCountriesArray);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const getSPFringeBenefit = () => {
    var newData: any = [...personalInfoInitialData.companyCarStatuses];
    newData.forEach((item: any, idx: number) => {
      if (idx > 0) {
        const D_CO2_CAR = initialData.co_2_emission;
        let applicable_value = 0;
        if (item.basic + (D_CO2_CAR - item.co2) * item.increase > item.max) {
          applicable_value = item.max;
        } else {
          applicable_value = Math.max(
            item.min,
            item.basic + (D_CO2_CAR - item.co2) * item.increase
          );
        }

        const D_PRICE_CAR = initialData.catalogue_price;
        const cal_0_value = Math.max(
          minBIKPerYear,
          (6 / 7) *
            D_PRICE_CAR *
            (applicable_value / 100) *
            (companyCarPercentile[0]?.months_value / 100)
        );
        const cal_1_value = Math.max(
          minBIKPerYear,
          (6 / 7) *
            D_PRICE_CAR *
            (applicable_value / 100) *
            (companyCarPercentile[0]?.months_value / 100)
        );
        const cal_2_value = Math.max(
          minBIKPerYear,
          (6 / 7) *
            D_PRICE_CAR *
            (applicable_value / 100) *
            (companyCarPercentile[1]?.months_value / 100)
        );
        const cal_3_value = Math.max(
          minBIKPerYear,
          (6 / 7) *
            D_PRICE_CAR *
            (applicable_value / 100) *
            (companyCarPercentile[2]?.months_value / 100)
        );
        const cal_4_value = Math.max(
          minBIKPerYear,
          (6 / 7) *
            D_PRICE_CAR *
            (applicable_value / 100) *
            (companyCarPercentile[3]?.months_value / 100)
        );
        const cal_5_value = Math.max(
          minBIKPerYear,
          (6 / 7) *
            D_PRICE_CAR *
            (applicable_value / 100) *
            (companyCarPercentile[4]?.months_value / 100)
        );
        const cal_6_value = Math.max(
          minBIKPerYear,
          (6 / 7) *
            D_PRICE_CAR *
            (applicable_value / 100) *
            (companyCarPercentile[5]?.months_value / 100)
        );

        newData[idx] = {
          ...item,
          applicable: applicable_value,
          cal_0: cal_0_value,
          cal_1: cal_1_value,
          cal_2: cal_2_value,
          cal_3: cal_3_value,
          cal_4: cal_4_value,
          cal_5: cal_5_value,
          cal_6: cal_6_value,
        };
      }
    });
    setSPFringeBenefitData(newData);
  };

  const handleDefaultTax = () => {
    if (initialData.is_default_tax === 1) {
      setInitialData((prevData: any) => ({
        ...prevData,
        is_default_tax: 0,
      }));
    } else {
      setInitialData((prevData: any) => ({
        ...prevData,
        municipal_id: "",
        is_default_tax: 1,
        municipal_tax: 7,
      }));
    }
  };

  const handleRedirect = () => {
    navigate("/cpo/list/all");
  };

  const handleChange = (fieldName: string, fieldValue: string | number) => {
    setInitialData({ ...initialData, [fieldName]: fieldValue });
  };

  const handleChangeObject = (fieldObject: object) => {
    setInitialData({
      ...initialData,
      ...fieldObject,
    });
  };

  const handleUpdate = (fieldName: string, data: string | number, idx: number) => {
    var newData: any = [...spCountryData];
    newData[idx][fieldName] = data;
    setSPCountryData(newData);
  };

  const handleEdit = (fieldName: string, data: string | number, idx: number) => {
    var newData: any = [...spCountryData];
    newData[idx][fieldName + "_edit"] = 1;
    newData[idx][fieldName] = data;
    setSPCountryData(newData);
  };

  const handleCancelEdit = (fieldName: string, data: string | number, idx: number) => {
    var newData: any = [...spCountryData];
    newData[idx][fieldName + "_edit"] = 0;
    newData[idx][fieldName] = data;
    setSPCountryData(newData);
    setRunCalculation(true);
  };

  const handleFocus = (event: any) => event.target.select();

  const handleCompanyClick = (data: any) => {
    setSelectedData(data);
    setOpenCompany(true);
  };

  const handleUserClick = (data: any) => {
    setSelectedData(data);
    setOpenUser(true);
  };

  const updateCalculation = () => {
    let D_EMPLOYEE_INCOME_TOTAL = 0;
    let D_DIRECTOR_INCOME_TOTAL = 0;
    let SS_PREMIUM_SELF_EMPLOYED_TOTAL = 0;

    var newData: any = [...spCountryData];
    newData.map((item: any, idx: number) => {
      let GS_PERCENTAGE = item.percentage > 0 ? item.percentage : 0;
      let D_GROSS_SALARY =
        item.gross_salary > 0
          ? item.gross_salary
          : GS_PERCENTAGE > 0
          ? (GS_PERCENTAGE / 100) * initialData.worldwide_gross_salary
          : 0;
      let D_EMPLOYEE_INCOME = item.emp_dir === 1 ? Number(D_GROSS_SALARY) : 0;
      let D_DIRECTOR_INCOME = 0;
      if (idx === 0) {
        D_DIRECTOR_INCOME =
          item.emp_dir === 2 ? Number(D_GROSS_SALARY) + Number(item.D_COMP_CAR) : 0;
      } else {
        D_DIRECTOR_INCOME = item.emp_dir === 2 ? Number(D_GROSS_SALARY) : 0;
      }
      newData[idx] = {
        ...item,
        D_GROSS_COUNTRY: D_GROSS_SALARY,
        D_EMPLOYEE_INCOME: D_EMPLOYEE_INCOME,
        D_DIRECTOR_INCOME: D_DIRECTOR_INCOME,
      };
      D_EMPLOYEE_INCOME_TOTAL = D_EMPLOYEE_INCOME_TOTAL + D_EMPLOYEE_INCOME;
      D_DIRECTOR_INCOME_TOTAL = D_DIRECTOR_INCOME_TOTAL + D_DIRECTOR_INCOME;
    });

    // ------------------------------------------------------ Next function

    var newData2: any = [...newData];
    newData2.map((item: any, idx: number) => {
      let GS_PERCENTAGE = item.percentage > 0 ? item.percentage : 0;
      let H129 = LEGAL_PREMIUM?.LEGAL_PREMIUM_ANNUALLY_WITH_ADM_FEE;
      let G116 = D_INCOME_TOTAL?.D_DIRECTOR_INCOME_TOTAL;
      let VAR_113 = Number(item.D_DIRECTOR_INCOME);

      let SS_PREMIUM_SELF_EMPLOYED = 0;
      if (H129 && G116 && VAR_113) {
        SS_PREMIUM_SELF_EMPLOYED = (H129 / G116) * VAR_113;
      }

      let SS_PERCENTAGE =
        SS_PREMIUM_SELF_EMPLOYED > 0
          ? 0
          : item.social_security_percentage_custome_value_edit === 1
          ? Number(item.social_security_percentage_custome_value)
          : Number(item.default_social_security_percentage);
      let SS_FIXED_AMOUNT = SS_PREMIUM_SELF_EMPLOYED > 0 ? SS_PREMIUM_SELF_EMPLOYED : 0;

      let SS_WITHOUT_AMOUNT = 0;
      if (SS_PREMIUM_SELF_EMPLOYED > 0) {
        SS_WITHOUT_AMOUNT =
          item.amount_without_social_security_custome_value_edit === 1
            ? item.amount_without_social_security_custome_value
            : 0;
      } else {
        if (initialData.worldwide_gross_salary > 0) {
          SS_WITHOUT_AMOUNT =
            item.amount_without_social_security_custome_value_edit === 1
              ? item.amount_without_social_security_custome_value
              : initialData.worldwide_gross_salary * (GS_PERCENTAGE / 100 / 13.92) * 0.07;
        } else {
          SS_WITHOUT_AMOUNT =
            item.amount_without_social_security_custome_value_edit === 1
              ? item.amount_without_social_security_custome_value
              : (item.gross_salary / 13.92) * 0.07;
        }
      }

      let D_SS_COUNTRY = 0;
      let D_BENEFIT_SS = 0;
      if (idx === 0) {
        D_BENEFIT_SS = Number(initialData.benefits_in_social_security);
      }
      if (SS_FIXED_AMOUNT > 0) {
        D_SS_COUNTRY =
          item.social_security_fixed_amount_custome_value_edit === 1
            ? item.social_security_fixed_amount_custome_value
            : SS_FIXED_AMOUNT;
      } else {
        if (initialData.worldwide_gross_salary > 0) {
          D_SS_COUNTRY =
            item.social_security_fixed_amount_custome_value_edit === 1
              ? item.social_security_fixed_amount_custome_value
              : (initialData.worldwide_gross_salary * (GS_PERCENTAGE / 100) -
                  SS_WITHOUT_AMOUNT +
                  D_BENEFIT_SS) *
                (SS_PERCENTAGE / 100);
        } else {
          D_SS_COUNTRY =
            item.social_security_fixed_amount_custome_value_edit === 1
              ? item.social_security_fixed_amount_custome_value
              : (item.gross_salary - SS_WITHOUT_AMOUNT + D_BENEFIT_SS) * (SS_PERCENTAGE / 100);
        }
      }

      let D_GROUP_INSURANCE = 0;
      if (idx === 0) {
        D_GROUP_INSURANCE = Number(initialData.employee_group_insurance_premiums);
      }

      let D_TTL_TAXABLE_INCOME =
        Number(item.D_GROSS_COUNTRY) + Number(item.D_COMP_CAR) - D_SS_COUNTRY;

      let D_TTL_NET_INCOME = 0;
      if (idx === 0) {
        D_TTL_NET_INCOME =
          D_TTL_TAXABLE_INCOME - C_TOTAL_TAX3 - Number(item.D_COMP_CAR) - D_GROUP_INSURANCE;
      } else {
        D_TTL_NET_INCOME = D_TTL_TAXABLE_INCOME - Number(item.C_TAXES_COUNTRY);
      }

      newData[idx] = {
        ...item,
        social_security_percentage: SS_PERCENTAGE,
        social_security_fixed_amount: SS_FIXED_AMOUNT,
        amount_without_social_security: SS_WITHOUT_AMOUNT,
        SS_PREMIUM_SELF_EMPLOYED: SS_PREMIUM_SELF_EMPLOYED,
        D_SS_COUNTRY: D_SS_COUNTRY,
        D_TTL_TAXABLE_INCOME: D_TTL_TAXABLE_INCOME,
        D_GROUP_INSURANCE: D_GROUP_INSURANCE,
        D_TTL_NET_INCOME: D_TTL_NET_INCOME,
      };

      SS_PREMIUM_SELF_EMPLOYED_TOTAL = SS_PREMIUM_SELF_EMPLOYED_TOTAL + SS_PREMIUM_SELF_EMPLOYED;
    });

    // Update SPCountryData ------------------------------------------------------
    setSPCountryData(newData);
    setD_INCOME_TOTAL((prevData: any) => ({
      ...prevData,
      D_EMPLOYEE_INCOME_TOTAL: D_EMPLOYEE_INCOME_TOTAL,
      D_DIRECTOR_INCOME_TOTAL: D_DIRECTOR_INCOME_TOTAL,
      SS_PREMIUM_SELF_EMPLOYED_TOTAL: SS_PREMIUM_SELF_EMPLOYED_TOTAL,
    }));

    // TOTAL
    let C_GS_TOTAL_VAL = 0;
    let C_BIK_TOTAL_VAL = 0;
    let C_SS_TOTAL_VAL = 0;
    let C_TI_TOTAL_VAL = 0;
    let C_SSSC_TOTAL_VAL = 0;
    let C_GI_TOTAL_VAL = 0;
    let C_NI_TOTAL_VAL = 0;

    newData.map((item: any, idx: number) => {
      C_GS_TOTAL_VAL = C_GS_TOTAL_VAL + Number(item?.D_GROSS_COUNTRY);
      C_BIK_TOTAL_VAL = C_BIK_TOTAL_VAL + Number(item?.D_COMP_CAR);
      C_SS_TOTAL_VAL = C_SS_TOTAL_VAL + Number(item?.D_SS_COUNTRY);
      C_TI_TOTAL_VAL = C_TI_TOTAL_VAL + Number(item?.D_TTL_TAXABLE_INCOME);
      if (idx === 0) {
        C_SSSC_TOTAL_VAL = C_SSSC_TOTAL_VAL + C_TOTAL_TAX3;
      } else {
        C_SSSC_TOTAL_VAL = C_SSSC_TOTAL_VAL + Number(item?.C_TAXES_COUNTRY);
      }
      C_GI_TOTAL_VAL = C_GI_TOTAL_VAL + Number(item?.D_GROUP_INSURANCE);
      C_NI_TOTAL_VAL = C_NI_TOTAL_VAL + Number(item?.D_TTL_NET_INCOME);
    });
    setC_GS_TOTAL(C_GS_TOTAL_VAL);
    setC_BIK_TOTAL(C_BIK_TOTAL_VAL);
    setC_SS_TOTAL(C_SS_TOTAL_VAL);
    setC_TI_TOTAL(C_TI_TOTAL_VAL);
    setC_SSSC_TOTAL(C_SSSC_TOTAL_VAL);
    setC_GI_TOTAL(C_GI_TOTAL_VAL);
    setC_NI_TOTAL(C_NI_TOTAL_VAL);
  };

  const handleWorldWideSalary = () => {
    if (spCountryData.length > 0) {
      if (initialData.worldwide_gross_salary > 0) {
        var newData: any = [...spCountryData];
        newData.map((item: any, idx: number) => {
          if (idx === 0) {
            newData[0] = {
              ...item,
              gross_salary: 0,
              percentage: 100,
            };
          } else {
            newData[idx] = {
              ...item,
              gross_salary: 0,
              percentage: 0,
            };
          }
        });
        setSPCountryData(newData);
        setDisabledGrossSalary(true);
        setRunCalculation(true);
      } else {
        var newData: any = [...spCountryData];
        newData.map((item: any, idx: number) => {
          newData[idx] = {
            ...item,
            gross_salary: 0,
            percentage: 0,
          };
        });
        setSPCountryData(newData);
        setDisabledGrossSalary(false);
        setRunCalculation(true);
      }
    }
  };

  const handlePercentageChange = (data: number, idx: number) => {
    let other_bel_sal_per = 0;
    var newData: any = [...spCountryData];
    newData.map((item: any, index: number) => {
      if (index !== 0 && item.percentage > 0) {
        other_bel_sal_per = other_bel_sal_per + parseFloat(item.percentage);
      }
    });
    newData[0] = {
      ...newData[0],
      percentage: 100 - other_bel_sal_per,
    };
    setSPCountryData(newData);
    setRunCalculation(true);
  };

  const handleSSSCModel = (country_id: number) => {
    // BELGIUM - 21
    // ESTONIA - 67
    // FRANCE - 73
    // GERMANY - 80
    // LUXEMBOURG - 124
    // POLAND - 171
    // ROMANIA - 176
    // SPAIN - 199
    // NETHERLANDS - 150
    // UNITED KINGDOM - 225
    // UNITED STATES - 226

    setShowSSSCModel(country_id);
  };

  // const onClickCancel = (idx: number) => {
  //   const temp = spCountryData.filter((data: any, dataIdx: number) => dataIdx !== idx);
  //   setSPCountryData(temp);
  // };

  const handleSubmit = (values: cpoTypes) => {
    setLoading(true);
    if (id && cpo_id) {
      update(values);
    } else {
      create(values);
    }
  };

  Yup.setLocale({
    mixed: {
      required: "${label} " + t("field is required."),
    },
    string: {
      min: "${label} " + t("must be at least") + " ${min} " + t("characters."),
      max: "${label} " + t("may not be greater than") + " ${max} " + t("characters."),
    },
  });
  const validationSchema = Yup.object().shape({
    custome_code: Yup.string().label(t("CPO ID")).required().min(3).max(15),
    company_id: Yup.string().label(t("Client")).required(),
    employee_id: Yup.string().label(t("Employee field")).required(),
    family_status: Yup.string().label(t("Family status")).required(),
    is_default_tax: Yup.number().label(t("Default tax")).required(),
    municipal_id: Yup.string()
      .label(t("Municipal tax"))
      .when(["is_default_tax"], {
        is: (is_default_tax: number) => is_default_tax !== 1,
        then: Yup.string().label(t("Municipal tax")).required(),
      }),
    computation_year: Yup.string().label(t("Computation year")).required(),
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    if (id && cpo_id) {
      if (userProfile.USER_TYPE === 3 && !props.showReport) {
        toast(t("Access denied"), {
          type: toast.TYPE.ERROR,
          autoClose: 5000,
          delay: 500,
        });
        handleRedirect();
      }

      getSPYear();
      get();
      setIsEditMode(true);
      setReportStatus("FINAL");

      if (props && props.showReport) {
        setShowReport(props.showReport);
      }
    } else {
      getSPYear();
      getSPCountry();
      setIsEditMode(false);
      setReportStatus("DRAFT");
    }
  }, []);

  // useEffect(() => {
  //   if (!getCompanyOptionsList) dispatch(CommonAction.getCompanyOptionsList());
  // }, []);

  useEffect(() => {
    if (loadingCode === true && !id && !cpo_id) {
      getCustomeCode("tbl_cpo", "CPO").then(function (result) {
        const updatedInitialData = {
          ...initialData,
          code: result,
          custome_code: result,
          company_id: userProfile.USER_COMPANY !== 1 ? userProfile.USER_COMPANY : "",
          company_value: userProfile.USER_COMPANY !== 1 ? t("Individual") : "",
          employee_id: userProfile.USER_COMPANY !== 1 ? userProfile.USER_ID : "",
          employee_value:
            userProfile.USER_COMPANY !== 1
              ? userProfile.USER_NAME + " " + userProfile.USER_LAST_NAME
              : "",
        };
        setInitialData(updatedInitialData);
        setLoadingCode(false);
      });
    } else {
      setLoadingCode(false);
    }
  }, [loadingCode]);

  useEffect(() => {
    if (getCompanyOptionsList.data && getCompanyOptionsList_New.data) {
      let item = [];

      if (isEditMode === true) {
        if (userProfile.USER_COMPANY !== 1) {
          item = getCompanyOptionsList.data;
        } else {
          item = addIndividualInCompany(getCompanyOptionsList.data) as any;
        }
      } else {
        if (userProfile.USER_COMPANY !== 1) {
          item = getCompanyOptionsList_New.data;
        } else {
          item = addIndividualInCompany(getCompanyOptionsList_New.data) as any;
        }
      }

      setCompanyOptions(item);
      setLoadingCompanyOptions(false);
      setDisabledCompanyOptions(false);
    } else {
      setCompanyOptions([
        {
          value: "0",
          label: t("Individual"),
        },
      ] as any);
      setLoadingCompanyOptions(false);
    }
  }, [getCompanyOptionsList, getCompanyOptionsList_New, userProfile.USER_COMPANY, isEditMode]);

  useEffect(() => {
    if (initialData.company_id !== "") {
      setUserOption([]);
      setLoadingCompanyUserOption(true);
      setDisabledCompanyUserOption(true);

      if (isEditMode === true) {
        getDependentSelectOptions(
          "tbl_master_users",
          "first_name",
          "company",
          parseInt(initialData.company_id)
        ).then(function (options) {
          setUserOption(options);
          setLoadingCompanyUserOption(false);

          if (userProfile.USER_COMPANY === 1) {
            setDisabledCompanyUserOption(false);
          }
        });
      } else {
        getDependentUserOptions_New(
          "tbl_master_users",
          "first_name",
          "company",
          parseInt(initialData.company_id)
        ).then(function (options) {
          setUserOption(options);
          setLoadingCompanyUserOption(false);

          if (userProfile.USER_COMPANY === 1) {
            setDisabledCompanyUserOption(false);
          }
        });
      }
    }
  }, [initialData.company_id, isEditMode]);

  useEffect(() => {
    getSelectOptions("tbl_municipal_tax", "municipal_name", "", [
      "municipal_code",
      "municipal_tax",
    ]).then(function (options) {
      var newData: any = [];
      options.map((item: any) => {
        newData.push({
          ...item,
          label: item.municipal_code + " - " + item.label + " - (" + item.municipal_tax + "%)",
        });
      });

      setMunicipalOption(newData);
      setLoadingMunicipalOptions(false);
      setDisabledMunicipalOption(false);
    });
  }, []);

  useEffect(() => {
    if (parseInt(initialData.family_status) === 2) {
      setDisabledSpouseEarns(false);
    } else {
      setDisabledSpouseEarns(true);
      setInitialData((prevData: any) => ({
        ...prevData,
        spouse_earns: "2",
      }));
    }
  }, [initialData.family_status]);

  useEffect(() => {
    if (initialData.municipal_id !== "") {
      const item = getOptionById(municipalOptions, parseInt(initialData.municipal_id)) as any;
      if (item) {
        setInitialData((prevData: any) => ({
          ...prevData,
          municipal_tax: parseFloat(item?.municipal_tax),
        }));
      }
    }
  }, [initialData.municipal_id]);

  useEffect(() => {
    if (parseInt(initialData.fringe_benefit) === 1) {
      setInitialData((prevData: any) => ({
        ...prevData,
        fringe_benefit_date: "",
        co_2_emission: 0,
        catalogue_price: 0,
      }));
      setDisabledFringeBenefit(true);
      setFringeBenefitCalcTotalData({});
    } else {
      setDisabledFringeBenefit(false);
    }
  }, [initialData.fringe_benefit]);

  useEffect(() => {
    if (initialData.computation_year && initialData.computation_year > 0) {
      setComputationYearsData(getMonthlyDaysInGivenYear(initialData.computation_year));
      setDaysInComputationYear(getTotalDaysInGivenYear(initialData.computation_year));

      const item = getOptionById(spYearsData, initialData.computation_year) as any;
      if (item) {
        updatePersonalInfoInitialData(item);
      }
    }
  }, [initialData.computation_year, spYearsData]);

  // D_COMP_CAR
  useEffect(() => {
    if (spCountryData.length > 0) {
      let d_comp_car = 0;
      let d_comp_car_cal = 0;

      if (parseInt(initialData.fringe_benefit) === 2) {
        d_comp_car_cal =
          fringeBenefitCalcTotalData && fringeBenefitCalcTotalData?.bik_petrol_lpg_total > 0
            ? parseFloat(fringeBenefitCalcTotalData.bik_petrol_lpg_total)
            : 0;
      } else if (parseInt(initialData.fringe_benefit) === 3) {
        d_comp_car_cal =
          fringeBenefitCalcTotalData && fringeBenefitCalcTotalData?.bik_petrol_diesel_total > 0
            ? parseFloat(fringeBenefitCalcTotalData.bik_petrol_diesel_total)
            : 0;
      } else if (parseInt(initialData.fringe_benefit) === 4) {
        d_comp_car_cal =
          fringeBenefitCalcTotalData && fringeBenefitCalcTotalData?.bik_petrol_electric_total > 0
            ? parseFloat(fringeBenefitCalcTotalData.bik_petrol_electric_total)
            : 0;
      }
      d_comp_car = d_comp_car_cal + Number(initialData.benefits_in_social_security);

      var newData: any = [...spCountryData];
      newData[0] = {
        ...newData[0],
        D_COMP_CAR: d_comp_car,
      };
      setSPCountryData(newData);
      setRunCalculation(true);
    }
  }, [
    initialData.fringe_benefit,
    // initialData.fringe_benefit_date,
    // initialData.co_2_emission,
    // initialData.catalogue_price,
    initialData.benefits_in_social_security,
    fringeBenefitCalcTotalData,
  ]);

  useEffect(() => {
    if (runCalculation === true) {
      updateCalculation();
      setRunCalculation(false);
    }
  }, [runCalculation]);

  useEffect(() => {
    if (LEGAL_PREMIUM) {
      updateCalculation();
    }
  }, [LEGAL_PREMIUM]);

  useEffect(() => {
    updateCalculation();
  }, [C_TOTAL_TAX3, C_SSSC]);

  useEffect(() => {
    let P_TAXFREE_TAXPAYER = 0;
    const D_FAM_STAT = Number(initialData.family_status);
    const P_Single_Parent = Number(personalInfoInitialData.tax_free_amount_for_single_parents);
    const D_CHILDREN = Number(initialData.children_total);
    const P_CHILDREN_BELOW_3 = Number(
      personalInfoInitialData.tax_free_amount_for_children_less_3_year_old
    );
    const D_CHILDREN_BELOW_3 = Number(initialData.children_under_3);
    const P_TAXFREE_MARRIED = Number(
      personalInfoInitialData.tax_free_amount_for_attribution_to_spouse
    );

    if (D_FAM_STAT === 2) {
      P_TAXFREE_TAXPAYER = P_TAXFREE_TAXPAYER + P_TAXFREE_MARRIED;
    } else {
      P_TAXFREE_TAXPAYER = P_TAXFREE_TAXPAYER + P_TAXFREE_MARRIED;
    }

    P_TAXFREE_TAXPAYER =
      Number(P_TAXFREE_TAXPAYER) +
      Number(vLookup(D_CHILDREN, personalInfoInitialData.P_CHILDREN, 4)) +
      Number(P_CHILDREN_BELOW_3 * D_CHILDREN_BELOW_3);
    if (D_FAM_STAT !== 2 && D_CHILDREN !== 0) {
      P_TAXFREE_TAXPAYER = P_TAXFREE_TAXPAYER + P_Single_Parent;
    }

    setPersonalInfoInitialData((prevData: any) => ({
      ...prevData,
      tax_free_amount_for_taxpayer: P_TAXFREE_TAXPAYER,
    }));
  }, [
    personalInfoInitialData.P_CHILDREN,
    initialData.family_status,
    personalInfoInitialData.tax_free_amount_for_single_parents,
    initialData.children_total,
    personalInfoInitialData.tax_free_amount_for_children_less_3_year_old,
    initialData.children_under_3,
    personalInfoInitialData.tax_free_amount_for_attribution_to_spouse,
  ]);

  useEffect(() => {
    let P_TAXFREE_SPOUSE = 0;
    const D_FAM_STAT = parseInt(initialData.family_status);
    const P_TAXFREE_MARRIED = personalInfoInitialData.tax_free_amount_for_attribution_to_spouse;
    if (D_FAM_STAT === 2) {
      P_TAXFREE_SPOUSE = P_TAXFREE_MARRIED;
    }
    setPersonalInfoInitialData((prevData: any) => ({
      ...prevData,
      tax_free_amount_for_spouse: P_TAXFREE_SPOUSE,
    }));
  }, [
    initialData.family_status,
    personalInfoInitialData.tax_free_amount_for_attribution_to_spouse,
  ]);

  useEffect(() => {
    setInitialData((prevData: any) => ({
      ...prevData,
      sp_country_data: spCountryData,
    }));
  }, [spCountryData]);

  useEffect(() => {
    setInitialData((prevData: any) => ({
      ...prevData,
      sp_fringe_benefit_data: spFringeBenefitData,
    }));
  }, [spFringeBenefitData]);

  useEffect(() => {
    setLoader(false);
  }, []);

  useEffect(() => {
    if (!validation.isSubmitting) return;
    if (Object.keys(validation.errors).length > 0) {
      const element = getFirstErrorElement(validationSchema, validation);
      document.getElementById(element)?.scrollIntoView({ behavior: "smooth", block: "center" });
      document.getElementById(element)?.focus({ preventScroll: true });
    }
  }, [validation.isSubmitting, validation.errors]);

  useEffect(() => {
    setYesNoList(yesNo(t) as any);
    setFamilyStatusesList(familyStatuses(t) as any);
    setEmpDirIncomeList(empDirIncome(t) as any);

    var newData: any = [];
    personalInfoInitialData?.companyCarStatuses?.map((item: any, index: number) => {
      newData.push({ ...item, label: t(item.label) });
    });
    setCompanyCarStatusesList(newData);
  }, [personalInfoInitialData?.companyCarStatuses, localStorage.getItem("I18N_LANGUAGE")]);

  return (
    <React.Fragment>
      {loader ? <Loader /> : null}

      {personalInfoInitialData && (
        <PersonalInfoModel
          personalInfoInitialData={personalInfoInitialData}
          businessExpensesEmployee={personalInfoInitialData.P_Expenses}
          show={showPersonalInfoModel}
          onCloseClick={() => setShowPersonalInfoModel(false)}
        />
      )}

      {parseInt(initialData.fringe_benefit) > 1 &&
        initialData.fringe_benefit_date !== "" &&
        initialData.co_2_emission >= 0 &&
        initialData.catalogue_price >= 0 && (
          <FringeBenefitCompanyCarModel
            D_DATE_CAR={initialData.fringe_benefit_date}
            D_CO2_CAR={initialData.co_2_emission}
            D_PRICE_CAR={initialData.catalogue_price}
            fringeBenefitData={spFringeBenefitData}
            companyCarPercentile={companyCarPercentile}
            COMPUTATION_YEAR={initialData.computation_year}
            COMPUTATION_YEAR_DATA={computationYearsData}
            COMPUTATION_YEAR_DAYS={daysInComputationYear}
            onCalculationUpdate={(data: any) => setFringeBenefitCalcTotalData(data)}
            show={showCompanyCarModel}
            onCloseClick={() => setShowCompanyCarModel(false)}
          />
        )}

      {spCountryData.length > 0 && personalInfoInitialData && (
        <EmployeeDirectorIncomeModel
          spCountryData={spCountryData}
          D_INCOME_TOTAL={D_INCOME_TOTAL}
          revaluationFactor={revaluationFactor}
          SS_SOCIAL_SECURITY={personalInfoInitialData.SS_SOCIAL_SECURITY}
          onCalculationUpdate={(data: any) => setLEGAL_PREMIUM(data)}
          show={showEmployeeDirectorIncomeModel}
          onCloseClick={() => setShowEmployeeDirectorIncomeModel(false)}
        />
      )}

      {spCountryData.length > 0 && personalInfoInitialData && (
        <>
          <SSSCLocalModel_21
            spCountryData={spCountryData}
            D_CAR={parseInt(initialData.fringe_benefit)}
            P_COMM_EXEMPT={personalInfoInitialData.commuting_exemption}
            spInitialData={initialData}
            personalInfoInitialData={personalInfoInitialData}
            businessExpensesEmployee={personalInfoInitialData.P_Expenses}
            P_TAXES_EXEMPT_AMOUNT={personalInfoInitialData.P_TAXES_EXEMPT_AMOUNT}
            P_Expenses={personalInfoInitialData.P_Expenses}
            P_TAXES={personalInfoInitialData.P_TAXES}
            P_SSSC={P_SSSC}
            onCalculationUpdate={(data: any) => {
              setC_COM_TAX_BELGIUM(Number(data.C_COM_TAX_BELGIUM));
            }}
            show={showSSSCLocalModel_21}
            onCloseClick={() => setShowSSSCLocalModel_21(false)}
          />

          <SSSCModel_21
            spCountryData={spCountryData}
            D_CAR={parseInt(initialData.fringe_benefit)}
            P_COMM_EXEMPT={personalInfoInitialData.commuting_exemption}
            spInitialData={initialData}
            personalInfoInitialData={personalInfoInitialData}
            businessExpensesEmployee={personalInfoInitialData.P_Expenses}
            P_TAXES_EXEMPT_AMOUNT={personalInfoInitialData.P_TAXES_EXEMPT_AMOUNT}
            P_Expenses={personalInfoInitialData.P_Expenses}
            P_TAXES={personalInfoInitialData.P_TAXES}
            P_SSSC={P_SSSC}
            C_COM_TAX_BELGIUM={C_COM_TAX_BELGIUM}
            onCalculationUpdate={(data: any) => {
              setC_TOTAL_TAX3(data.C_TOTAL_TAX3);
              setC_SSSC(data.C_SSSC);
              setC_SSSC_BE(data.C_SSSC_BE);
              setC_TOTAL_TAX3_BE(data.C_TOTAL_TAX3_BE);
            }}
            show={showSSSCModel === 21 ? true : false}
            onCloseClick={() => setShowSSSCModel(0)}
          />
        </>
      )}

      {showReport === false ? (
        <Row>
          <Col xl="12">
            <Card>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                autoComplete="off"
              >
                <Input autoComplete="false" name="hidden" type="text" style={{ display: "none" }} />
                <Input id="id" name="id" type="hidden" value={initialData.id} />
                <Input id="cpo_id" name="cpo_id" type="hidden" value={initialData.cpo_id} />
                <Input id="code" name="code" type="hidden" value={initialData.code} />

                <CardHeader className="bg-transparent border-bottom border-primary mt-2 pb-0 d-sm-flex flex-wrap">
                  <CardTitle className="card-title text-uppercase d-flex">
                    {t("Personal information")}
                  </CardTitle>

                  <div className="ms-auto d-flex gap-2 justify-content-end">
                    {userProfile.USER_COMPANY === 1 && (
                      <div className="mb-3">
                        <button
                          type="button"
                          className="btn btn-outline-info btn-sm"
                          title={t("Personal info")}
                          onClick={() => setShowPersonalInfoModel(true)}
                        >
                          <span className="align-middle material-symbols-outlined fw-light fs-5">
                            info
                          </span>
                        </button>
                      </div>
                    )}
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {t("CPO ID")} <span className="text-warning is_required">*</span>
                        </Label>
                        <Input
                          id="custome_code"
                          name="custome_code"
                          type="text"
                          value={initialData.custome_code}
                          readOnly={loadingCode}
                          onBlur={validation.handleBlur}
                          onChange={(e) => handleChange("custome_code", e.target.value)}
                          invalid={!!validation.errors.custome_code}
                        />
                        {loadingCode ? <Spinner /> : ""}
                        {validation.errors.custome_code ? (
                          <FormFeedback type="invalid">
                            {validation.errors.custome_code}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col xl={4} lg={4} md={4} sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {initialData.company_id &&
                          initialData.company_id !== "0" &&
                          companyProfile.CAN_VIEW_ALL ? (
                            <Link
                              // to={`/masters/company/company-details/${getOptionValueById(
                              //   companyOptions,
                              //   parseInt(initialData.company_id),
                              //   "id"
                              // )}/${getOptionValueById(
                              //   companyOptions,
                              //   parseInt(initialData.company_id),
                              //   "company_id"
                              // )}`}
                              to="#"
                              className="text-decoration-underline"
                              onClick={() =>
                                handleCompanyClick({
                                  company_id: getOptionValueById(
                                    companyOptions,
                                    parseInt(initialData.company_id),
                                    "id"
                                  ),
                                  company_sub_id: getOptionValueById(
                                    companyOptions,
                                    parseInt(initialData.company_id),
                                    "company_id"
                                  ),
                                })
                              }
                            >
                              {t("Client")}
                            </Link>
                          ) : (
                            t("Client")
                          )}{" "}
                          <span className="text-warning is_required">*</span>
                        </Label>
                        <Select
                          id="company_id"
                          name="company_id"
                          className="is-invalid"
                          placeholder={t("Select...")}
                          options={companyOptions}
                          isDisabled={disabledCompanyOptions}
                          isLoading={loadingCompanyOptions}
                          value={
                            parseInt(initialData.company_id) >= 0
                              ? companyOptions.find(
                                  (option: any) => option.value == initialData.company_id
                                )
                              : ""
                          }
                          isSearchable={true}
                          onBlur={() => validation.setFieldTouched("company_id", true)}
                          onChange={(e: any) => {
                            // handleChange("company_id", e.value);
                            handleChangeObject({
                              company_id: e.value,
                              company_value: e.label,
                            });
                          }}
                        />
                        {validation.errors.company_id ? (
                          <FormFeedback type="invalid">{validation.errors.company_id}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col xl={4} lg={4} md={4} sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {initialData.employee_id &&
                          usersProfile.CAN_VIEW_ALL ? (
                            <Link
                              // to={`/users/user-details/${getOptionValueById(
                              //   userOptions,
                              //   parseInt(initialData.employee_id),
                              //   "id"
                              // )}/${getOptionValueById(
                              //   userOptions,
                              //   parseInt(initialData.employee_id),
                              //   "user_id"
                              // )}`}
                              to="#"
                              className="text-decoration-underline"
                              onClick={() =>
                                handleUserClick({
                                  employee_id: getOptionValueById(
                                    userOptions,
                                    parseInt(initialData.employee_id),
                                    "id"
                                  ),
                                  employee_sub_id: getOptionValueById(
                                    userOptions,
                                    parseInt(initialData.employee_id),
                                    "user_id"
                                  ),
                                })
                              }
                            >
                              {t("Name of the employee")}
                            </Link>
                          ) : (
                            t("Name of the employee")
                          )}{" "}
                          <span className="text-warning is_required">*</span>
                        </Label>
                        <Select
                          id="employee_id"
                          name="employee_id"
                          className="is-invalid"
                          placeholder={t("Select...")}
                          options={userOptions}
                          value={
                            initialData.employee_id
                              ? userOptions.length &&
                                userOptions?.find(
                                  (option: any) => option.value == initialData.employee_id
                                )
                              : ""
                          }
                          isSearchable={true}
                          isLoading={loadingCompanyUserOption}
                          isDisabled={disabledCompanyUserOption}
                          onBlur={() => validation.setFieldTouched("employee_id", true)}
                          onChange={(e: any) => {
                            // handleChange("employee_id", e.value);
                            handleChangeObject({
                              employee_id: e.value,
                              employee_value: e.label,
                            });
                          }}
                        />

                        {validation.errors.employee_id ? (
                          <FormFeedback type="invalid">
                            {validation.errors.employee_id}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col className="col-12"></Col>

                    <Col xl={4} lg={4} md={4} sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {t("Family status")} <span className="text-warning is_required">*</span>
                        </Label>
                        <Select
                          id="family_status"
                          name="family_status"
                          className="is-invalid"
                          placeholder={t("Select...")}
                          options={familyStatusesList}
                          isSearchable={false}
                          value={
                            initialData.family_status
                              ? familyStatusesList.find(
                                  (option: any) => option.value == initialData.family_status
                                )
                              : ""
                          }
                          onBlur={() => validation.setFieldTouched("family_status", true)}
                          onChange={(e: any) => {
                            handleChange("family_status", e.value);
                          }}
                        />

                        {validation.errors.family_status ? (
                          <FormFeedback type="invalid">
                            {validation.errors.family_status}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col xl={4} lg={4} md={4} sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {t("Spouse earns professional income")}{" "}
                          <span className="text-warning is_required">*</span>
                        </Label>
                        <Select
                          id="spouse_earns"
                          name="spouse_earns"
                          className="is-invalid"
                          placeholder={t("Select...")}
                          options={yesNoList}
                          value={
                            initialData.spouse_earns
                              ? yesNoList.find(
                                  (option: any) => option.value == initialData.spouse_earns
                                )
                              : ""
                          }
                          isDisabled={disabledSpouseEarns}
                          onBlur={() => validation.setFieldTouched("spouse_earns", true)}
                          onChange={(e: any) => {
                            handleChange("spouse_earns", e.value);
                          }}
                        />
                        {validation.errors.spouse_earns ? (
                          <FormFeedback type="invalid">
                            {validation.errors.spouse_earns}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col className="col-12"></Col>

                    <Col xl={4} lg={4} md={4} sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {t("Total number of children")}{" "}
                          <span className="text-warning is_required">*</span>
                          <br />
                          {t("(including children < 3 years old)")}
                        </Label>
                        <Input
                          id="children_total"
                          className="text-end"
                          name="children_total"
                          type="text"
                          value={initialData.children_total}
                          onBlur={validation.handleBlur}
                          onFocus={handleFocus}
                          onChange={(e: any) => {
                            if (e.target?.value >= 0) {
                              handleChange("children_total", e.target.value);
                            }
                          }}
                          invalid={!!validation.errors.children_total}
                        />
                        {validation.errors.children_total ? (
                          <FormFeedback type="invalid">
                            {validation.errors.children_total}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col xl={4} lg={4} md={4} sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {t("Number of dependent children")}{" "}
                          <span className="text-warning is_required">*</span>
                          <br />
                          {t("(under 3 years old)")}
                        </Label>
                        <Input
                          id="children_under_3"
                          className="text-end"
                          name="children_under_3"
                          type="text"
                          value={initialData.children_under_3}
                          onBlur={validation.handleBlur}
                          onFocus={handleFocus}
                          onChange={(e: any) => {
                            if (e.target?.value >= 0) {
                              handleChange("children_under_3", e.target.value);
                            }
                          }}
                          invalid={!!validation.errors.children_under_3}
                        />
                        {validation.errors.children_under_3 ? (
                          <FormFeedback type="invalid">
                            {validation.errors.children_under_3}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col xl={4} lg={4} md={4} sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          <a
                            href={`https://financien.belgium.be/sites/default/files/downloads/111-tarieven-gemeentebelasting-${new Date().getFullYear()}.pdf`}
                            target={"_blank"}
                            className="text-decoration-underline"
                          >
                            {t("Local / City tax rate in Belgium")}
                          </a>{" "}
                          <span className="text-warning is_required">*</span>
                          <br />
                          {t("ZIP Code (for municipal tax)")}
                        </Label>
                        <Select
                          id="municipal_id"
                          name="municipal_id"
                          className="is-invalid"
                          placeholder={t("Select...")}
                          options={municipalOptions}
                          value={
                            initialData.municipal_id
                              ? municipalOptions.find(
                                  (option: any) => option.value == initialData.municipal_id
                                )
                              : ""
                          }
                          isSearchable={true}
                          isLoading={loadingMunicipalOptions}
                          isDisabled={
                            initialData.is_default_tax === 1 ? true : disabledMunicipalOption
                          }
                          onBlur={() => validation.setFieldTouched("municipal_id", true)}
                          onChange={(e: any) => {
                            handleChange("municipal_id", e.value);
                          }}
                        />

                        {validation.errors.municipal_id ? (
                          <FormFeedback type="invalid">
                            {validation.errors.municipal_id}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check mb-3">
                        <input
                          id="is_default_tax"
                          name="is_default_tax"
                          className="form-check-input"
                          type="checkbox"
                          value={initialData.is_default_tax}
                          checked={initialData.is_default_tax === 1 ? true : false}
                          onBlur={validation.handleBlur}
                          onChange={handleDefaultTax}
                        />
                        <label className="form-check-label" htmlFor="is_default_tax">
                          {t("Use average")} (7%)
                        </label>
                      </div>
                    </Col>
                  </Row>
                </CardBody>

                <CardHeader className="bg-transparent border-bottom border-primary mt-2 pb-0">
                  <CardTitle className="card-title text-uppercase">
                    {t("Employment information")}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {t("Computation year")}{" "}
                          <span className="text-warning is_required">*</span>
                        </Label>
                        <Select
                          id="computation_year"
                          name="computation_year"
                          className="is-invalid"
                          placeholder={t("Select...")}
                          options={spYearsData}
                          value={
                            initialData.computation_year
                              ? spYearsData.find(
                                  (option: any) => option.value == initialData.computation_year
                                )
                              : ""
                          }
                          isLoading={loadingSPYearsData}
                          isDisabled={loadingSPYearsData}
                          onBlur={() => validation.setFieldTouched("computation_year", true)}
                          onChange={(e: any) => {
                            handleChange("computation_year", e.value);
                          }}
                        />
                      </div>
                    </Col>

                    <div className="table-responsive mb-3">
                      <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                        <thead>
                          <tr className="align-bottom">
                            <th colSpan={2} className="text-end">
                              {/* Worldwide salary <br />
                              (complete this only when <br />
                              percentages are used) */}
                            </th>
                            <th>
                              {/* <Input
                                id="worldwide_gross_salary"
                                className="text-end"
                                name="worldwide_gross_salary"
                                type="text"
                                value={initialData.worldwide_gross_salary}
                                onChange={(e: any) => {
                                  if (e.target?.value >= 0) {
                                    handleChange(
                                      "worldwide_gross_salary",
                                      (e.target.value)
                                    );
                                  }
                                }}
                                onBlur={async (e: any) => {
                                  if (e.target?.value >= 0) {
                                    handleWorldWideSalary();
                                  }
                                }}
                                onFocus={handleFocus}
                                invalid={!!validation.errors.worldwide_gross_salary}
                              /> */}
                            </th>
                            {/* <th></th>
                            <th></th> */}
                            <th colSpan={2} className="text-center">
                              {t("Social security status")}
                            </th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>

                          <tr>
                            <th>{t("Country")}</th>
                            <th>{t("Tax rates Year")}</th>
                            <th className="text-end">
                              {renderRowHTML(t("Gross <br/>salary"), null)}
                            </th>
                            {/* <th className="text-end">pct (%)</th>
                            <th className="text-end">
                              {renderRowHTML(t("Foreign <br/> tax <br/>liability"), null)}
                            </th> */}
                            <th className="text-end">pct (%)</th>
                            <th className="text-end">
                              {renderRowHTML(t("Fixed <br/>amount"), null)}
                            </th>
                            <th className="text-end">
                              {renderRowHTML(t("Amt w/o <br/>social <br/>security"), null)}
                            </th>
                            <th className="text-end">
                              {userProfile.USER_COMPANY === 1 && (
                                <div className="mb-3">
                                  <button
                                    type="button"
                                    className="btn btn-outline-info btn-sm"
                                    title={t("Employee or director income")}
                                    onClick={() => setShowEmployeeDirectorIncomeModel(true)}
                                  >
                                    <span className="align-middle material-symbols-outlined fw-light fs-5">
                                      info
                                    </span>
                                  </button>
                                </div>
                              )}
                            </th>
                            <th className="text-end"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {spCountryData &&
                            initialData.computation_year &&
                            initialData.computation_year > 0 &&
                            spCountryData.map((item: any, idx: number) => (
                              <tr key={idx}>
                                <td>{t(item.country_name)}</td>
                                <td>
                                  {initialData.computation_year &&
                                    taxYear(item.tax_year_id, initialData.computation_year)}
                                </td>
                                <td>
                                  <Input
                                    id={`gross_salary_${idx}`}
                                    className="text-end"
                                    name="gross_salary[]"
                                    type="text"
                                    value={item.gross_salary ? item.gross_salary : ""}
                                    disabled={disabledGrossSalary}
                                    onChange={(e: any) => {
                                      if (e.target?.value >= 0) {
                                        handleUpdate("gross_salary", e.target.value, idx);
                                      }
                                    }}
                                    onBlur={(e: any) => {
                                      if (e.target?.value >= 0) {
                                        setRunCalculation(true);
                                      }
                                    }}
                                    onFocus={handleFocus}
                                  />
                                </td>
                                {/* <td>
                                  <Input
                                    id={`percentage_${idx}`}
                                    className="text-end"
                                    name="percentage[]"
                                    type="text"
                                    style={{ width: "80px" }}
                                    value={item.percentage ? item.percentage : ""}
                                    disabled={idx === 0 ? true : !disabledGrossSalary}
                                    onChange={(e: any) => {
                                      if (e.target?.value >= 0) {
                                        handleUpdate("percentage", parseFloat(e.target.value), idx);
                                      }
                                    }}
                                    onBlur={(e: any) => {
                                      if (e.target?.value >= 0) {
                                        handlePercentageChange(parseFloat(e.target.value), idx);
                                      }
                                    }}
                                    onFocus={handleFocus}
                                  />
                                </td>
                                <td>
                                  {item.is_foreign_tax_liable === 1 && (
                                    <Input
                                      id={`foreign_tax_liability_${idx}`}
                                      className="text-end"
                                      name="foreign_tax_liability[]"
                                      type="text"
                                      value={
                                        item.foreign_tax_liability ? item.foreign_tax_liability : ""
                                      }
                                      disabled={true}
                                      onChange={(e: any) => {
                                        if (e.target?.value >= 0) {
                                          handleUpdate("foreign_tax_liability", e.target.value, idx);
                                        }
                                      }}
                                    />
                                  )}
                                </td> */}
                                <td>
                                  {item.social_security_percentage_custome_value_edit === 1 ? (
                                    <div className="input-group is-invalid">
                                      <Input
                                        id={`social_security_percentage_custome_value_${idx}`}
                                        className="text-end"
                                        name="social_security_percentage_custome_value[]"
                                        type="text"
                                        // style={{ width: "80px" }}
                                        value={item.social_security_percentage_custome_value}
                                        onChange={(e: any) => {
                                          if (e.target?.value >= 0) {
                                            handleUpdate(
                                              "social_security_percentage_custome_value",
                                              e.target.value,
                                              idx
                                            );
                                          }
                                        }}
                                        onBlur={(e: any) => {
                                          if (e.target?.value >= 0) {
                                            setRunCalculation(true);
                                          }
                                        }}
                                        onFocus={handleFocus}
                                      />
                                      {(item.gross_salary > 0 || item.percentage > 0) && (
                                        <button
                                          type="button"
                                          className="btn btn-outline-secondary btn-sm"
                                          title={t("Edit")}
                                          onClick={() =>
                                            handleCancelEdit(
                                              "social_security_percentage_custome_value",
                                              0,
                                              idx
                                            )
                                          }
                                          disabled={
                                            item.gross_salary > 0 || item.percentage > 0
                                              ? false
                                              : true
                                          }
                                        >
                                          <span className="align-middle material-symbols-outlined fw-light fs-5">
                                            close
                                          </span>
                                        </button>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="input-group is-invalid">
                                      <Input
                                        id={`social_security_percentage_${idx}`}
                                        className="text-end"
                                        name="social_security_percentage[]"
                                        type="text"
                                        // style={{ width: "80px" }}
                                        value={
                                          item.social_security_percentage
                                            ? formatCurrency(
                                                parseFloat(item.social_security_percentage).toFixed(
                                                  2
                                                ),
                                                false
                                              )
                                            : "0,00"
                                        }
                                        disabled={true}
                                      />
                                      {(item.gross_salary > 0 || item.percentage > 0) && (
                                        <button
                                          type="button"
                                          className="btn btn-outline-secondary btn-sm"
                                          title={t("Edit")}
                                          onClick={() =>
                                            handleEdit(
                                              "social_security_percentage_custome_value",
                                              Number(item.social_security_percentage).toFixed(2),
                                              idx
                                            )
                                          }
                                          disabled={
                                            item.gross_salary > 0 || item.percentage > 0
                                              ? false
                                              : true
                                          }
                                        >
                                          <span className="align-middle material-symbols-outlined fw-light fs-5">
                                            edit
                                          </span>
                                        </button>
                                      )}
                                    </div>
                                  )}
                                </td>
                                <td>
                                  {item.social_security_fixed_amount_custome_value_edit === 1 ? (
                                    <div className="input-group is-invalid">
                                      <Input
                                        id={`social_security_fixed_amount_custome_value_${idx}`}
                                        className="text-end"
                                        name="social_security_fixed_amount_custome_value[]"
                                        type="text"
                                        value={item.social_security_fixed_amount_custome_value}
                                        onChange={(e: any) => {
                                          if (e.target?.value >= 0) {
                                            handleUpdate(
                                              "social_security_fixed_amount_custome_value",
                                              e.target.value,
                                              idx
                                            );
                                          }
                                        }}
                                        onBlur={(e: any) => {
                                          if (e.target?.value >= 0) {
                                            setRunCalculation(true);
                                          }
                                        }}
                                        onFocus={handleFocus}
                                      />
                                      {(item.gross_salary > 0 || item.percentage > 0) && (
                                        <button
                                          type="button"
                                          className="btn btn-outline-secondary btn-sm"
                                          title="Edit"
                                          onClick={() =>
                                            handleCancelEdit(
                                              "social_security_fixed_amount_custome_value",
                                              0,
                                              idx
                                            )
                                          }
                                          disabled={
                                            item.gross_salary > 0 || item.percentage > 0
                                              ? false
                                              : true
                                          }
                                        >
                                          <span className="align-middle material-symbols-outlined fw-light fs-5">
                                            close
                                          </span>
                                        </button>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="input-group is-invalid">
                                      <Input
                                        id={`social_security_fixed_amount_${idx}`}
                                        className="text-end"
                                        name="social_security_fixed_amount[]"
                                        type="text"
                                        value={
                                          item.social_security_fixed_amount
                                            ? formatCurrency(
                                                parseFloat(
                                                  item.social_security_fixed_amount
                                                ).toFixed(2),
                                                false
                                              )
                                            : "0,00"
                                        }
                                        disabled={true}
                                      />
                                      {(item.gross_salary > 0 || item.percentage > 0) && (
                                        <button
                                          type="button"
                                          className="btn btn-outline-secondary btn-sm"
                                          title="Edit"
                                          onClick={() =>
                                            handleEdit(
                                              "social_security_fixed_amount_custome_value",
                                              Number(item.social_security_fixed_amount).toFixed(2),
                                              idx
                                            )
                                          }
                                          disabled={
                                            item.gross_salary > 0 || item.percentage > 0
                                              ? false
                                              : true
                                          }
                                        >
                                          <span className="align-middle material-symbols-outlined fw-light fs-5">
                                            edit
                                          </span>
                                        </button>
                                      )}
                                    </div>
                                  )}
                                </td>
                                <td>
                                  {item.amount_without_social_security_custome_value_edit === 1 ? (
                                    <div className="input-group is-invalid">
                                      <Input
                                        id={`amount_without_social_security_custome_value_${idx}`}
                                        className="text-end"
                                        name="amount_without_social_security_custome_value[]"
                                        type="text"
                                        value={item.amount_without_social_security_custome_value}
                                        onChange={(e: any) => {
                                          if (e.target?.value >= 0) {
                                            handleUpdate(
                                              "amount_without_social_security_custome_value",
                                              e.target.value,
                                              idx
                                            );
                                          }
                                        }}
                                        onBlur={(e: any) => {
                                          if (e.target?.value >= 0) {
                                            setRunCalculation(true);
                                          }
                                        }}
                                        onFocus={handleFocus}
                                      />
                                      {(item.gross_salary > 0 || item.percentage > 0) && (
                                        <button
                                          type="button"
                                          className="btn btn-outline-secondary btn-sm"
                                          title={t("Edit")}
                                          onClick={() =>
                                            handleCancelEdit(
                                              "amount_without_social_security_custome_value",
                                              0,
                                              idx
                                            )
                                          }
                                          disabled={
                                            item.gross_salary > 0 || item.percentage > 0
                                              ? false
                                              : true
                                          }
                                        >
                                          <span className="align-middle material-symbols-outlined fw-light fs-5">
                                            close
                                          </span>
                                        </button>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="input-group is-invalid">
                                      <Input
                                        id={`amount_without_social_security_${idx}`}
                                        className="text-end"
                                        name="amount_without_social_security[]"
                                        type="text"
                                        value={
                                          item.amount_without_social_security
                                            ? formatCurrency(
                                                parseFloat(
                                                  item.amount_without_social_security
                                                ).toFixed(2),
                                                false
                                              )
                                            : "0,00"
                                        }
                                        disabled={true}
                                      />
                                      {(item.gross_salary > 0 || item.percentage > 0) && (
                                        <button
                                          type="button"
                                          className="btn btn-outline-secondary btn-sm"
                                          title={t("Edit")}
                                          onClick={() =>
                                            handleEdit(
                                              "amount_without_social_security_custome_value",
                                              Number(item.amount_without_social_security).toFixed(
                                                2
                                              ),
                                              idx
                                            )
                                          }
                                          disabled={
                                            item.gross_salary > 0 || item.percentage > 0
                                              ? false
                                              : true
                                          }
                                        >
                                          <span className="align-middle material-symbols-outlined fw-light fs-5">
                                            edit
                                          </span>
                                        </button>
                                      )}
                                    </div>
                                  )}
                                </td>
                                <td style={{ minWidth: "120px" }}>
                                  <Select
                                    id="emp_dir"
                                    name="emp_dir"
                                    className="is-invalid"
                                    placeholder={t("Select...")}
                                    options={empDirIncomeList}
                                    isSearchable={false}
                                    value={
                                      item.emp_dir
                                        ? empDirIncomeList.find(
                                            (option: any) => option.value == item.emp_dir
                                          )
                                        : ""
                                    }
                                    onBlur={() => validation.setFieldTouched("emp_dir", true)}
                                    onChange={(e: any) => {
                                      handleUpdate("emp_dir", e.value, idx);
                                      setRunCalculation(true);
                                    }}
                                  />
                                </td>
                                <td>
                                  <div className="d-flex gap-2 text-end">
                                    {/* <Button
                                    outline={true}
                                    color="danger"
                                    size="sm"
                                    className="mt-1"
                                    onClick={() => onClickCancel(idx)}>
                                    <span className="align-middle material-symbols-outlined fw-light fs-5">
                                      close
                                    </span>
                                  </Button> */}

                                    {userProfile.USER_COMPANY === 1 && (
                                      <button
                                        type="button"
                                        className="btn btn-outline-info btn-sm"
                                        title={t("Computation of social security")}
                                        onClick={() => handleSSSCModel(item.country_id)}
                                      >
                                        <span className="align-middle material-symbols-outlined fw-light fs-5">
                                          info
                                        </span>
                                      </button>
                                    )}

                                    {userProfile.USER_COMPANY === 1 && idx === 0 && (
                                      <button
                                        type="button"
                                        className="btn btn-outline-info btn-sm"
                                        title={t("Computation of social security for Local Tax")}
                                        onClick={() => setShowSSSCLocalModel_21(true)}
                                      >
                                        <span className="align-middle material-symbols-outlined fw-light fs-5">
                                          info
                                        </span>
                                      </button>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Row>

                  <Row className="mt-3">
                    <Col xl={3} lg={3} md={3} sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {renderRowHTML(
                            t("Fringe benefit for the private use <br/>of a company car"),
                            null
                          )}
                        </Label>
                        <Select
                          id="fringe_benefit"
                          name="fringe_benefit"
                          className="is-invalid"
                          placeholder={t("Select...")}
                          options={companyCarStatusesList}
                          value={
                            initialData.fringe_benefit
                              ? companyCarStatusesList.find(
                                  (option: any) => option.value == initialData.fringe_benefit
                                )
                              : ""
                          }
                          onBlur={() => validation.setFieldTouched("fringe_benefit", true)}
                          onChange={(e: any) => {
                            handleChange("fringe_benefit", e.value);
                          }}
                        />

                        {validation.errors.fringe_benefit ? (
                          <FormFeedback type="invalid">
                            {validation.errors.fringe_benefit}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col xl={9} lg={9} md={9} sm={12}>
                      <Row>
                        <Col xl={3} lg={3} md={3} sm={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {renderRowHTML(t("First registration <br/>of the car"), null)}
                            </Label>
                            <div className="input-group is-invalid">
                              {!disabledFringeBenefit ? (
                                <Flatpickr
                                  id="fringe_benefit_date"
                                  name="fringe_benefit_date"
                                  className="form-control d-block"
                                  type="text"
                                  value={initialData.fringe_benefit_date}
                                  placeholder="DD/MM/YYYY"
                                  options={{
                                    altInput: true,
                                    altFormat: "d/m/Y",
                                    dateFormat: "Y-m-d H:i:S",
                                    maxDate: "today",
                                    allowInput: true,
                                    plugins: [labelPlugin()],
                                    onChange: function (selectedDates, dateStr, instance) {
                                      handleChange("fringe_benefit_date", dateStr);
                                    },
                                    onClose: function (selectedDates, dateStr, instance) {
                                      handleChange("fringe_benefit_date", dateStr);
                                    },
                                    locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                                  }}
                                />
                              ) : (
                                <Input
                                  id="fringe_benefit_date"
                                  name="fringe_benefit_date"
                                  className="form-control d-block"
                                  type="text"
                                  placeholder="DD/MM/YYYY"
                                  disabled={true}
                                />
                              )}
                              <span className="input-group-text">
                                <span className="align-middle material-symbols-outlined fw-light fs-4">
                                  calendar_today
                                </span>
                              </span>
                            </div>
                          </div>
                        </Col>

                        <Col xl={3} lg={3} md={3} sm={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {renderRowHTML(t("Co-2 emission <br/>(gr / km)"), null)}
                            </Label>
                            <Input
                              id="co_2_emission"
                              className="text-end"
                              name="co_2_emission"
                              type="text"
                              value={initialData.co_2_emission}
                              onChange={(e: any) => {
                                if (e.target?.value >= 0) {
                                  handleChange("co_2_emission", e.target.value);
                                }
                              }}
                              onBlur={(e: any) => {
                                if (e.target?.value >= 0) {
                                  getSPFringeBenefit();
                                }
                              }}
                              onFocus={handleFocus}
                              disabled={disabledFringeBenefit}
                              invalid={!!validation.errors.co_2_emission}
                            />
                          </div>
                        </Col>

                        <Col xl={3} lg={3} md={3} sm={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {renderRowHTML(t("Catalogue price <br/>(incl. options & VAT)"), null)}
                            </Label>
                            <Input
                              id="catalogue_price"
                              className="text-end"
                              name="catalogue_price"
                              type="text"
                              value={initialData.catalogue_price}
                              onChange={(e: any) => {
                                if (e.target?.value >= 0) {
                                  handleChange("catalogue_price", e.target.value);
                                }
                              }}
                              onBlur={(e: any) => {
                                if (e.target?.value >= 0) {
                                  getSPFringeBenefit();
                                }
                              }}
                              onFocus={handleFocus}
                              disabled={disabledFringeBenefit}
                              invalid={!!validation.errors.catalogue_price}
                            />
                          </div>
                        </Col>

                        <Col xl={2} lg={2} md={2} sm={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {renderRowHTML(t("Minimum BIK <br/>(per year)"), null)}
                            </Label>
                            <Input
                              id="minBIKPerYear"
                              className="text-end"
                              name="minBIKPerYear"
                              type="text"
                              value={formatCurrency(minBIKPerYear, false)}
                              disabled={true}
                            />
                          </div>
                        </Col>

                        {userProfile.USER_COMPANY === 1 && (
                          <Col xl={1} lg={1} md={1} sm={12}>
                            <div className="mb-3">
                              <Label className="form-label w-100">
                                <br />
                                <br />
                              </Label>
                              <button
                                type="button"
                                className="btn btn-outline-info btn-sm"
                                title={t("Fringe benefit company car")}
                                disabled={
                                  parseInt(initialData.fringe_benefit) > 1 &&
                                  initialData.fringe_benefit_date !== "" &&
                                  initialData.co_2_emission >= 0 &&
                                  initialData.catalogue_price >= 0
                                    ? false
                                    : true
                                }
                                onClick={() => setShowCompanyCarModel(true)}
                              >
                                <span className="align-middle material-symbols-outlined fw-light fs-5">
                                  info
                                </span>
                              </button>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </Col>

                    <Col className="col-12">
                      <hr />
                    </Col>

                    <Col xl={4} lg={4} md={4} sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {renderRowHTML(
                            t(
                              "Application of the special <br/>social security contribution (S.S.S.C.)"
                            ),
                            null
                          )}
                        </Label>
                        <Select
                          id="sssc_application"
                          name="sssc_application"
                          className="is-invalid"
                          placeholder={t("Select...")}
                          options={yesNoList}
                          value={
                            initialData.sssc_application
                              ? yesNoList.find(
                                  (option: any) => option.value == initialData.sssc_application
                                )
                              : ""
                          }
                          onBlur={() => validation.setFieldTouched("sssc_application", true)}
                          onChange={(e: any) => {
                            handleChange("sssc_application", e.value);
                          }}
                        />
                        {validation.errors.sssc_application ? (
                          <FormFeedback type="invalid">
                            {validation.errors.sssc_application}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col xl={4} lg={4} md={4} sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {renderRowHTML(t("Employee group insurance <br/>premiums"), null)}
                        </Label>
                        <Input
                          id="employee_group_insurance_premiums"
                          className="text-end"
                          name="employee_group_insurance_premiums"
                          type="text"
                          value={initialData.employee_group_insurance_premiums}
                          onChange={(e: any) => {
                            if (e.target?.value >= 0) {
                              handleChange("employee_group_insurance_premiums", e.target.value);
                            }
                          }}
                          onBlur={(e: any) => {
                            if (e.target?.value >= 0) {
                              setRunCalculation(true);
                            }
                          }}
                          onFocus={handleFocus}
                          invalid={!!validation.errors.employee_group_insurance_premiums}
                        />
                      </div>
                    </Col>

                    <Col xl={4} lg={4} md={4} sm={12}>
                      <div className="mb-3">
                        <Label className="form-label">
                          {renderRowHTML(
                            t("Benefits in kind <br/>subject to social security"),
                            null
                          )}
                        </Label>
                        <Input
                          id="benefits_in_social_security"
                          className="text-end"
                          name="benefits_in_social_security"
                          type="text"
                          value={initialData.benefits_in_social_security}
                          onChange={(e: any) => {
                            if (e.target?.value >= 0) {
                              handleChange("benefits_in_social_security", e.target.value);
                            }
                          }}
                          onBlur={(e: any) => {
                            if (e.target?.value >= 0) {
                              setRunCalculation(true);
                            }
                          }}
                          onFocus={handleFocus}
                          invalid={!!validation.errors.benefits_in_social_security}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>

                <CardFooter className="d-sm-flex flex-wrap bg-transparent border-top">
                  {initialData && initialData.created_at && (
                    <>
                      <Row>
                        <Col className="col-12 mt-2 mb-3">
                          {initialData &&
                          initialData.created_by_first_name &&
                          initialData.created_by_last_name ? (
                            <div>
                              <ul className="list-inline mb-1">
                                <li className="list-inline-item me-3">
                                  <span className="text-muted">
                                    <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                      person
                                    </span>
                                    {t("Created by")} {initialData.created_by_first_name}{" "}
                                    {initialData.created_by_last_name}
                                  </span>
                                </li>
                                <li className="list-inline-item me-3">
                                  <span className="text-muted">
                                    <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                      calendar_today
                                    </span>
                                    {dateFormat(initialData.created_at)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          ) : null}

                          {initialData &&
                          initialData.updated_by_first_name &&
                          initialData.updated_by_last_name ? (
                            <div>
                              <ul className="list-inline">
                                <li className="list-inline-item me-3">
                                  <span className="text-muted">
                                    <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                      person
                                    </span>
                                    {t("Last modified by")} {initialData.updated_by_first_name}{" "}
                                    {initialData.updated_by_last_name}
                                  </span>
                                </li>
                                <li className="list-inline-item me-3">
                                  <span className="text-muted">
                                    <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                      calendar_today
                                    </span>
                                    {dateFormat(initialData.updated_at)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          ) : null}
                        </Col>
                      </Row>

                      <div className="col-12"></div>
                    </>
                  )}

                  <div className="d-flex gap-2"></div>

                  <div className="ms-auto d-flex gap-2 justify-content-end">
                    {userProfile.USER_COMPANY === 1 ? (
                      <>
                        <Button
                          color="primary"
                          className="btn-block"
                          type="button"
                          onClick={() => setShowReport(true)}
                          disabled={loading}
                        >
                          {loading ? (
                            <span>
                              <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                                hourglass_empty
                              </span>
                              <span>{t("Please wait...")}</span>
                            </span>
                          ) : (
                            <span>{t("Preview")}</span>
                          )}
                        </Button>

                        <Button
                          color="primary"
                          className="btn-block"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? (
                            <span>
                              <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                                hourglass_empty
                              </span>
                              <span>{t("Please wait...")}</span>
                            </span>
                          ) : (
                            <span>{isEditMode ? t("Update") : t("Save")}</span>
                          )}
                        </Button>

                        <Button
                          color="danger"
                          className="btn-block"
                          type="reset"
                          onClick={handleRedirect}
                          disabled={loading}
                        >
                          {loading ? (
                            <span>
                              <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                                hourglass_empty
                              </span>
                              <span>{t("Please wait...")}</span>
                            </span>
                          ) : (
                            <span>{t("Cancel")}</span>
                          )}
                        </Button>
                      </>
                    ) : (
                      <Button
                        color="primary"
                        className="btn-block"
                        type="submit"
                        onClick={() => handleChange("submit_btn_code", "4")}
                        disabled={loading}
                      >
                        {loading ? (
                          <span>
                            <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                              hourglass_empty
                            </span>
                            <span>{t("Please wait...")}</span>
                          </span>
                        ) : (
                          <span>{isEditMode ? t("Update & compute") : t("Save & compute")}</span>
                        )}
                      </Button>
                    )}
                  </div>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      ) : (
        <CPOReport
          initialData={initialData}
          personalInfoInitialData={personalInfoInitialData}
          spCountryData={spCountryData}
          C_GS_TOTAL={C_GS_TOTAL}
          C_BIK_TOTAL={C_BIK_TOTAL}
          C_SS_TOTAL={C_SS_TOTAL}
          C_TOTAL_TAX3={C_TOTAL_TAX3}
          C_TOTAL_TAX3_BE={C_TOTAL_TAX3_BE}
          C_GI_TOTAL={C_GI_TOTAL}
          C_TI_TOTAL={C_TI_TOTAL}
          C_SSSC_TOTAL={C_SSSC_TOTAL}
          C_NI_TOTAL={C_NI_TOTAL}
          REPORT_STATUS={reportStatus}
          setShowReport={(data: boolean) => setShowReport(data)}
        />
      )}

      <Drawer
        title={t("Client")}
        open={openCompany}
        onClose={() => setOpenCompany((state) => !state)}
        fullWidth
      >
        <EditCompany
          id={selectedData?.company_id}
          company_id={selectedData?.company_sub_id}
          type={1}
          isWindow={true}
        />
      </Drawer>

      <Drawer
        title={t("User")}
        open={openUser}
        onClose={() => setOpenUser((state) => !state)}
        fullWidth
      >
        <EditUser
          id={selectedData?.employee_id}
          user_id={selectedData?.employee_sub_id}
          type={1}
          isWindow={true}
        />
      </Drawer>
    </React.Fragment>
  );
};

export default CPOForm;
