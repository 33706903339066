import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import { useTranslation } from "react-i18next";
import NoDataFound from "../NoDataFound";

const DataTableBasic = (props: any) => {
  const { t } = useTranslation();
  const {
    columns,
    products,
    loadingListData,
    onTableChange,
    datatableOptions,
    rowStyle,
    striped = true,
    bordered = true,
  } = props;
  const [noDataIndication, setNoDataIndication] = useState("LOADING");

  const customTotal = (from: number, to: number, size: number) => (
    <span>
      {t("Showing")} {from} {t("to")} {to} {t("of")} {size} {t("entries")}
    </span>
  );

  const pageOptions = {
    page: datatableOptions.page,
    sizePerPage: datatableOptions.sizePerPage,
    totalSize: datatableOptions.recordsFiltered,
    custom: true,
    alwaysShowAllBtns: true,
    showTotal: true,
    paginationTotalRenderer: customTotal,
  };

  useEffect(() => {
    if (loadingListData === true) {
      setNoDataIndication(t("LOADING"));
    } else {
      if (datatableOptions.recordsTotal === 0) {
        setNoDataIndication(t("NOT_FOUND"));
      } else if (datatableOptions.recordsFiltered === 0) {
        setNoDataIndication(t("NOT_MATCHED"));
      }
    }
  }, [loadingListData]);

  return (
    <>
      <Row>
        <Col className="col-12">
          <PaginationProvider pagination={paginationFactory(pageOptions)}>
            {({ paginationProps, paginationTableProps }: any) => (
              <ToolkitProvider keyField="id" columns={columns} data={products}>
                {(toolkitProps) => (
                  <React.Fragment>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive table-basic">
                          <BootstrapTable
                            responsive={true}
                            bordered={bordered}
                            striped={false}
                            rowStyle={rowStyle}
                            classes={"nowrap w-100"}
                            hover={true}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                            noDataIndication={
                              noDataIndication === "LOADING" ? (
                                <div className="data-notification mt-5">
                                  <NoDataFound
                                    icon="quick_reference_all"
                                    message={t("Please wait, We are looking for the records!")}
                                  />
                                </div>
                              ) : noDataIndication === "NOT_MATCHED" ? (
                                <div className="data-notification mt-5">
                                  <NoDataFound
                                    icon="quick_reference"
                                    message={t("You have no matching records to view!")}
                                  />
                                </div>
                              ) : noDataIndication === "NOT_FOUND" ? (
                                <div className="data-notification mt-5">
                                  <NoDataFound
                                    icon="quick_reference"
                                    message={t("You have no records to view!")}
                                  />
                                </div>
                              ) : (
                                <div className="data-notification mt-5">
                                  <NoDataFound
                                    icon="quick_reference"
                                    message={t("You have no records to view!")}
                                  />
                                </div>
                              )
                            }
                            loading={true}
                            remote={{
                              filter: true,
                              pagination: true,
                              sort: true,
                              cellEdit: false,
                            }}
                            onTableChange={onTableChange}
                          />
                        </div>
                      </Col>
                    </Row>

                    {datatableOptions?.recordsTotal > pageOptions?.sizePerPage && (
                      <Row className="align-items-md-center mt-3">
                        <Col className="inner-custom-pagination d-flex">
                          <div className="react-bootstrap-table-pagination-total">
                            <PaginationTotalStandalone {...paginationProps} />

                            {datatableOptions.recordsFiltered != datatableOptions.recordsTotal ? (
                              <span>
                                {" "}
                                ({t("filtered from")} {datatableOptions.recordsTotal}{" "}
                                {t("total entries")}){" "}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="text-md-right ms-auto">
                            <PaginationListStandalone keyField="id" {...paginationProps} />
                          </div>
                        </Col>
                      </Row>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </Col>
      </Row>
    </>
  );
};

export default DataTableBasic;
