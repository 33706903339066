import {
  GET_SUB_TASK,
  GET_SUB_TASK_SUCCESS,
  GET_SUB_TASK_ERROR,

  GET_SUB_TASKS_LIST,
  GET_SUB_TASKS_LIST_SUCCESS,
  GET_SUB_TASKS_LIST_ERROR,

  CREATE_SUB_TASK,
  CREATE_SUB_TASK_SUCCESS,
  CREATE_SUB_TASK_ERROR,

  UPDATE_SUB_TASK,
  UPDATE_SUB_TASK_SUCCESS,
  UPDATE_SUB_TASK_ERROR,

  DELETE_SUB_TASK,
  DELETE_SUB_TASK_SUCCESS,
  DELETE_SUB_TASK_ERROR,
} from "./actionTypes";

// ---------------------------------------------GET_SUB_TASK
export const getSubTask = (tasks: any, history: any) => {
  return {
    type: GET_SUB_TASK,
    payload: { tasks, history },
  };
};

export const getSubTaskSuccess = (data: {}) => {
  return {
    type: GET_SUB_TASK_SUCCESS,
    payload: data,
  };
};

export const getSubTaskError = (error: {}) => {
  return {
    type: GET_SUB_TASK_ERROR,
    payload: error,
  };
};

// ---------------------------------------------GET_SUB_TASKS_LIST
export const getSubTasksList = (query: any, history: any,id?:number) => {
  return {
    type: GET_SUB_TASKS_LIST,
    payload: {query, history,id},
  };
};

export const getSubTasksListSuccess = (data: {}) => {
  return {
    type: GET_SUB_TASKS_LIST_SUCCESS,
    payload: data,
  };
};

export const getSubTasksListError = (error: {}) => {
  return {
    type: GET_SUB_TASKS_LIST_ERROR,
    payload: error,
  };
};

// ---------------------------------------------CREATE_SUB_TASK
export const createSubTask = (tasks: any, history: any) => {
  return {
    type: CREATE_SUB_TASK,
    payload: { tasks, history },
  };
};

export const createSubTaskSuccess = (tasks: {}) => {
  return {
    type: CREATE_SUB_TASK_SUCCESS,
    payload: tasks,
  };
};

export const createSubTaskError = (error: {}) => {
  return {
    type: CREATE_SUB_TASK_ERROR,
    payload: error,
  };
};

// ---------------------------------------------UPDATE_SUB_TASK
export const updateSubTask = (tasks: any, history: any) => {
  return {
    type: UPDATE_SUB_TASK,
    payload: { tasks, history },
  };
};

export const updateSubTaskSuccess = (tasks: {}) => {
  return {
    type: UPDATE_SUB_TASK_SUCCESS,
    payload: tasks,
  };
};

export const updateSubTaskError = (error: {}) => {
  return {
    type: UPDATE_SUB_TASK_ERROR,
    payload: error,
  };
};

// ---------------------------------------------DELETE_SUB_TASK
export const deleteSubTask = (tasks: any, history: any) => {
  return {
    type: DELETE_SUB_TASK,
    payload: { tasks, history },
  };
};

export const deleteSubTaskSuccess = (tasks: {}) => {
  return {
    type: DELETE_SUB_TASK_SUCCESS,
    payload: tasks,
  };
};

export const deleteSubTaskError = (error: {}) => {
  return {
    type: DELETE_SUB_TASK_ERROR,
    payload: error,
  };
};
