import React, { Component } from "react";
import { Outlet } from "react-router-dom";
import LanguageDropdown from "../Layout/TopbarDropdown/LanguageDropdown";

class NonAuthLayout extends Component {
  render() {
    return (
      <React.Fragment>
        <LanguageDropdown style={{ position: "absolute", top: "10px", right: "10px" }} />
        <Outlet />
      </React.Fragment>
    );
  }
}

export default NonAuthLayout;
