import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import ReactApexChart from "react-apexcharts";
import Button from '../../../components/Button/Button';
import { getUserProfile, select2Styles, yearsOnward } from '../../../helpers/functions_helper';
import { Month, noData, QuarterData, WeekOption } from '../../../utils/data';
import timeSheetsService from '../../../services/time.sheets.service';
import LoadingSpinner from '../../../components/Common/Loader';
import { secondsToTime, serialize } from '../../../utils/utils';
import { formatCurrency } from '../../../utils/formats';
import WidgetBasic from '../../../components/Widgets/Basic';
import { Card, Col, Row } from 'reactstrap';
import { currentMonth, currentYear } from '../../../common/staticdata';

interface CountsProps extends CartProps {
    filters?: any;
    widgetId?: 'Total_time_spent' | 'Budget_and_or_An_Hourly_rate' | 'Total_expenses' | 'Consultant_service_charges' | 'All';
}
const years = yearsOnward();

const Counts: FC<CountsProps> = ({ hideHeading, filters = null, widgetId = 'All' }) => {

    const { t } = useTranslation();
    const userObj = getUserProfile();

    const inititalFilter = {
        week: null,
        quarter: null,
        month: currentMonth + '',
        year: currentYear,
        user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_ID : null
    };

    const [loading, setLoading] = useState<boolean>(true);
    const [query, setQuery] = useState<string | null>(serialize(inititalFilter));
    const [filter, setFilter] = useState<any>(inititalFilter);
    const [data, setData] = useState<any>({
        total_time: 0,
        task_amount: 0,
        expense: 0,
        service_charge: 0
    })

    const getChartData = async () => {
        const result = await timeSheetsService.getCounts(query);
        if (result.data.status === 'success') {
            setData((data: any) => ({
                ...data,
                total_time: result?.data?.data?.time_spent,
                task_amount: result?.data?.data?.fees_amount,
                expense: result?.data?.data?.expense,
                service_charge: result?.data?.data?.service_charge,
            }));
        }
        setLoading(false);
    }

    useEffect(() => {
        loading && getChartData();
    }, [loading])

    useEffect(() => {
        setQuery(serialize(filter));
        setLoading(true);
    }, [filter])

    useEffect(() => {
        filters && setFilter(filters)
    }, [filters])

    return (
        <>
            {(widgetId === 'All' || widgetId === 'Total_time_spent') &&
                <Col>
                    <WidgetBasic
                        variant='primary'
                        label={t("Grand total time spent")}
                        value={secondsToTime(data?.total_time.internal + data?.total_time.external)}
                        icon={<span className="material-symbols-outlined icon">schedule</span>}
                        loading={loading}
                        seconday={[
                            {
                                label: t('Internal'),
                                value: secondsToTime(data?.total_time.internal)
                            },
                            {
                                label: t('External'),
                                value: secondsToTime(data?.total_time.external)
                            }
                        ]}
                    />
                </Col>
            }
            {(widgetId === 'All' || widgetId === 'Budget_and_or_An_Hourly_rate') &&
                <Col>
                    <WidgetBasic
                        variant='warning'
                        label={t("Budget and/or An Hourly rate")}
                        value={formatCurrency(data?.task_amount?.internal + data?.task_amount?.external)}
                        seconday={[
                            {
                                label: t('Internal'),
                                value: formatCurrency(data?.task_amount?.internal)
                            },
                            {
                                label: t('External'),
                                value: formatCurrency(data?.task_amount?.external)
                            }
                        ]}
                        icon={<span className="material-symbols-outlined icon">euro_symbol</span>}
                        loading={loading}
                    />
                </Col>
            }
            {(widgetId === 'All' || widgetId === 'Total_expenses') &&
                <Col>
                    <WidgetBasic
                        variant='info'
                        label={t("Total expenses")}
                        value={formatCurrency(data?.expense.internal + data?.expense.external)}
                        seconday={[
                            {
                                label: t('Internal'),
                                value: formatCurrency(data?.expense.internal)
                            },
                            {
                                label: t('External'),
                                value: formatCurrency(data?.expense.external)
                            }
                        ]}
                        icon={<span className="material-symbols-outlined icon">euro_symbol</span>}
                        loading={loading}
                    />
                </Col>
            }
            {(widgetId === 'All' || widgetId === 'Consultant_service_charges') &&
                <Col>
                    <WidgetBasic
                        variant='success'
                        label={t("Consultant's service charges")}
                        value={formatCurrency(data?.service_charge.internal + data?.service_charge.external)}
                        seconday={[
                            {
                                label: t('Internal'),
                                value: formatCurrency(data?.service_charge.internal)
                            },
                            {
                                label: t('External'),
                                value: formatCurrency(data?.service_charge.external)
                            }
                        ]}
                        icon={<span className="material-symbols-outlined icon">euro_symbol</span>}
                        loading={loading}
                    />
                </Col>
            }
        </>
    )
}

export default Counts;