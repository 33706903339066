import React, { useState } from "react";
import AvatarEditor from "react-avatar-editor";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import AddSignature from "../../components/Common/AddSignature";
import { useTranslation } from "react-i18next";

const Signature = (props: any) => {
  const {t} = useTranslation();
  let { id, user_id } = props;
  const params = useParams();
  if(params){
    let id = params.id;
    let user_id = params.user_id;
  }
  const [signatureShow, setSignatureShow] = useState(false);
  return (
    <Card className="border-top-0 rounded-0 rounded-bottom">
      <CardHeader className="bg-transparent border-bottom border-primary mt-2 pb-0">
        <CardTitle className="card-title text-uppercase">{t("Signatures")}</CardTitle>
      </CardHeader>
      <CardBody>
        <AddSignature
          moduleData={{
            module_app_id: 1,
            module_main_id: id,
            module_sub_id: user_id,
            section_id: 0,
            file_path: "user",
          }}
          show={signatureShow}
          isDisabled={false}
          showAdd={false}
          handleSignatureData={() => {}}
        />
      </CardBody>
    </Card>
  );
};

export default Signature;
