import React, { Fragment, memo, useState } from "react";
import PropTypes from "prop-types";
import Select from "../Select";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { find, filter } from "lodash";
const propTypes = {
  label: PropTypes.string,
  moreUserList: PropTypes.array,
  updateUser: PropTypes.func.isRequired,
  selectUser: PropTypes.array.isRequired,
};

const MoreUser = ({ label, moreUserList, updateUser, selectUser }: any) => {
  const isPresent: boolean = selectUser.some((e: any) => {
    return find(moreUserList, (k) => k.value === e);
  });

  return (
    <Fragment>
      <Select
        variant="empty"
        dropdownWidth={343}
        withClearValue={false}
        morelabel={label}
        isUserPresent={isPresent}
        name="status"
        isMulti={true}
        options={moreUserList}
        selectedOptions={selectUser}
        onChange={(status: any) => updateUser(status)}
        renderOption={(value: any) => (
          <Row>
            <Col className="col-12">
              <FormGroup check>
                <Input
                  type="checkbox"
                  checked={selectUser.indexOf(value.value) == -1 ? false : true}
                />
                <Label check>{value.label}</Label>
              </FormGroup>
            </Col>
          </Row>
        )}
      />
    </Fragment>
  );
};

MoreUser.propTypes = propTypes;

export default memo(MoreUser);
