import { 
  GET_COMPANY,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_ERROR,

  GET_COMPANY_LIST,
  GET_COMPANY_LIST_SUCCESS,
  GET_COMPANY_LIST_ERROR,
  
  CREATE_COMPANY,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_ERROR, 

  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_ERROR,

  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_ERROR,

  GET_COMPANY_GENERAL,
  GET_COMPANY_GENERAL_SUCCESS,
  GET_COMPANY_GENERAL_ERROR,
} from "./actionTypes"
  
  const initialState = {
    GET_COMPANY_SUCCESS: "",
    GET_COMPANY_ERROR: "",
    GET_COMPANY_LIST_SUCCESS: "",
    GET_COMPANY_LIST_ERROR: "",
    CREATE_COMPANY_SUCCESS: "",
    CREATE_COMPANY_ERROR: "",
    UPDATE_COMPANY: "",
    UPDATE_COMPANY_SUCCESS: "",
    UPDATE_COMPANY_ERROR: "",
    DELETE_COMPANY: "",
    DELETE_COMPANY_SUCCESS: "",
    DELETE_COMPANY_ERROR: "",
    GET_COMPANY_GENERAL:"",
    GET_COMPANY_GENERAL_SUCCESS:"",
    GET_COMPANY_GENERAL_ERROR:"",
    loading: false,
  }
  
  const reducer = (state = initialState, action: any) => {
    switch (action.type) {
      // ---------------------------------------------GET_COMPANY
      case GET_COMPANY:
        state = {
          ...state,
          loading: true,
        }
        break

      case GET_COMPANY_SUCCESS:
        state = {
          ...state,
          GET_COMPANY_SUCCESS: action.payload,
          loading: false,
        }
        break

      case GET_COMPANY_ERROR:
        state = { 
          ...state, 
          GET_COMPANY_ERROR: action.payload, 
          loading: false 
        }
        break

      // ---------------------------------------------GET_COMPANY_LIST
      case GET_COMPANY_LIST:
        state = {
          ...state,
          loading: true,
        }
        break

      case GET_COMPANY_LIST_SUCCESS:
        state = {
          ...state,
          GET_COMPANY_LIST_SUCCESS: action.payload,
          loading: false,
        }
        break

      case GET_COMPANY_LIST_ERROR:
        state = { 
          ...state, 
          GET_COMPANY_LIST_ERROR: action.payload, 
          loading: false 
        }
        break
        
      // ---------------------------------------------CREATE_COMPANY
      case CREATE_COMPANY:
        state = {
          ...state,
          loading: true,
        }
        break

      case CREATE_COMPANY_SUCCESS:
        state = {
          ...state,
          CREATE_COMPANY_SUCCESS: action.payload,
          loading: false,
        }
        break

      case CREATE_COMPANY_ERROR:
        state = { 
          ...state, 
          CREATE_COMPANY_ERROR: action.payload, 
          loading: false 
        }
        break

      // ---------------------------------------------UPDATE_COMPANY
      case UPDATE_COMPANY:
        state = {
          ...state,
          loading: true,
        }
        break

      case UPDATE_COMPANY_SUCCESS:
        state = {
          ...state,
          UPDATE_COMPANY_SUCCESS: action.payload,
          loading: false,
        }
        break

      case UPDATE_COMPANY_ERROR:
        state = { 
          ...state, 
          UPDATE_COMPANY_ERROR: action.payload, 
          loading: false 
        }
        break

      // ---------------------------------------------DELETE_COMPANY
      case DELETE_COMPANY:
        state = {
          ...state,
          loading: true,
        }
        break

      case DELETE_COMPANY_SUCCESS:
        state = {
          ...state,
          DELETE_COMPANY_SUCCESS: action.payload,
          loading: false,
        }
        break

      case DELETE_COMPANY_ERROR:
        state = { 
          ...state, 
          DELETE_COMPANY_ERROR: action.payload, 
          loading: false 
        }
        break
      
    // --------------------------------------------- COMPANY GENERAL
    case GET_COMPANY_GENERAL:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_COMPANY_GENERAL_SUCCESS:
      state = {
        ...state,
        GET_COMPANY_GENERAL_SUCCESS: action.payload,
        loading: false,
      }
      break

    case GET_COMPANY_GENERAL_ERROR:
      state = { 
        ...state, 
        GET_COMPANY_GENERAL_ERROR: action.payload, 
        loading: false 
      }
      break
      

      // ---------------------------------------------DEFAULT
      default:
        state = { ...state }
        break
    }
    return state
  }
  
  export default reducer
  