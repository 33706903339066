import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Container, Nav, NavItem, Row } from "reactstrap";
import Breadcrumbs from "../../components/Layout/Breadcrumb";
import EventModal from "./EventModal";

import azureService from "../../services/azure.service";
import eventService from "../../services/event.service";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import BootstrapTheme from "@fullcalendar/bootstrap";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";

import { useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

import { ckEditorI18, getUserProfile } from "../../helpers/functions_helper";
import { useTranslation } from "react-i18next";

const EventCalendar = () => {
  const { t } = useTranslation();
  const userProfile = getUserProfile("all-events");

  const isAzureAuthenticated = useIsAuthenticated();

  document.title = t("Event calendar") + " | " + process.env.REACT_APP_NAME;
  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "#",
      label: t("Event calendar"),
      active: true,
    },
  ];

  const { type } = useParams();

  const [dateRange, setDateRange] = useState<any>([]);
  const [eventsData, setEventsData] = useState<any>([]);
  const [myEvents, setMyEvents] = useState(false);

  const [azureEventsData, setAzureEventsData] = useState<any>([]);
  const [taskEventsData, setTaskEventsData] = useState<any>([]);

  const [showEventModal, setShowEventModal] = useState(false);
  const [eventData, setEventData] = useState<any>([]);

  const lang = ckEditorI18();
  const refEventCalendar = useRef<any>();

  const getAzureCalendars = async (arg: any) => {
    const startDateTime = moment(arg?.start).format("YYYY-MM-DD");
    const endDateTime = moment(arg?.end).format("YYYY-MM-DD");

    const data = await azureService.getEvents(
      startDateTime + "T00:00:00.0000000",
      endDateTime + "T23:59:59.0000000"
    );
    if (data.status === 200) {
      setAzureEventsData(data.data.value);
    }
  };

  const getTaskDueDateCalendars = async (arg: any) => {
    const startDateTime = moment(arg?.start).format("YYYY-MM-DD");
    const endDateTime = moment(arg?.end).format("YYYY-MM-DD");

    const data = await eventService.getTaskEvents(
      startDateTime + " 00:00:00",
      endDateTime + " 23:59:59",
      myEvents
    );
    if (data.status === 200) {
      setTaskEventsData(data.data.data);
    }
  };

  useEffect(() => {
    setEventsData([]);
    setAzureEventsData([]);

    if (isAzureAuthenticated === true && type === "all" && Object.keys(dateRange).length > 0) {
      getAzureCalendars(dateRange);
    }
  }, [isAzureAuthenticated, dateRange, type]);

  useEffect(() => {
    setEventsData([]);
    setTaskEventsData([]);

    if (Object.keys(dateRange).length > 0) {
      getTaskDueDateCalendars(dateRange);
    }
  }, [myEvents, dateRange, type]);

  useEffect(() => {
    if (azureEventsData.length > 0) {
      const eventArray: any = [...eventsData];
      azureEventsData.forEach((element: any) => {
        eventArray.push({
          id: element?.id,
          title: element?.subject,
          details: element?.bodyPreview ? element?.bodyPreview : null,
          start: moment(element?.start?.dateTime).format("YYYY-MM-DD"),
          start_date_time: element?.start?.dateTime,
          end: moment(element?.end?.dateTime).format("YYYY-MM-DD"),
          end_date_time: element?.end?.dateTime,
          event_type: "AZURE_EVENTS",
          web_link: element?.webLink ? element?.webLink : null,
          organizer: element?.organizer ? element?.organizer : null,
          color: "#007bff",
        });
      });
      setEventsData(eventArray);
    }
  }, [azureEventsData]);

  useEffect(() => {
    if (taskEventsData.length > 0) {
      const eventArray: any = [...eventsData];
      taskEventsData.forEach((element: any) => {
        eventArray.push({
          id: element?.id + "-" + element?.task_id,
          title: element?.title,
          details: null,
          start: moment(element?.due_date).format("YYYY-MM-DD"),
          start_date_time: element?.due_date,
          end: moment(element?.due_date).format("YYYY-MM-DD"),
          end_date_time: element?.due_date,
          event_type: "TASK_EVENTS",
          web_link: "/tasks/task-details/" + element?.id + "/" + element?.task_id,
          organizer: {
            emailAddress: {
              name: element?.created_by_first_name + " " + element?.created_by_last_name,
              address: element?.created_by_email,
            },
          },
          color: "#28a745",
        });
      });
      setEventsData(eventArray);
    }
  }, [taskEventsData]);

  return (
    <React.Fragment>
      <EventModal
        show={showEventModal}
        eventData={eventData}
        onCloseClick={() => setShowEventModal(false)}
      />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbTitle={t("Event calendar")} breadcrumbItems={breadcrumbItems} />

          <Row>
            <Col xl="12">
              <Card>
                <CardHeader className="d-sm-flex flex-wrap bg-transparent border-bottom pb-0">
                  <div className="d-flex">
                    <Nav tabs className="nav-tabs-custom">
                      <NavItem>
                        <Link
                          style={{ cursor: "pointer" }}
                          to="/event-calendar/events/all"
                          className={`nav-link ${type === "all" ? "active" : ""}`}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home"></i>
                          </span>
                          <span className="d-none d-sm-block">{t("All Events")}</span>
                        </Link>
                      </NavItem>

                      <NavItem>
                        <Link
                          style={{ cursor: "pointer" }}
                          to="/event-calendar/events/tasks"
                          className={`nav-link ${type === "tasks" ? "active" : ""}`}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home"></i>
                          </span>
                          <span className="d-none d-sm-block">{t("Tasks")}</span>
                        </Link>
                      </NavItem>
                    </Nav>
                  </div>

                  <div className="ms-auto d-flex gap-2 justify-content-end">
                    {userProfile.CAN_VIEW_ALL ? (
                      <Button
                        title={t("My tasks")}
                        color={myEvents ? "secondary" : "primary"}
                        className="btn-label"
                        onClick={() => {
                          setMyEvents(!myEvents);
                        }}
                      >
                        <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                          account_circle
                        </span>
                        {t("My tasks")}
                      </Button>
                    ) : null}
                  </div>
                </CardHeader>

                <CardBody>
                  <FullCalendar
                    ref={refEventCalendar}
                    plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
                    handleWindowResize={true}
                    themeSystem="bootstrap"
                    headerToolbar={{
                      left: "prev,next today",
                      center: "title",
                      right: "dayGridMonth,dayGridWeek,dayGridDay",
                    }}
                    buttonText={{
                      today: t("Today"),
                      dayGridMonth: t("Month"),
                      dayGridWeek: t("Week"),
                      dayGridDay: t("Day"),
                    }}
                    buttonHints={{
                      today: t("This month"),
                      dayGridMonth: t("Month view"),
                      dayGridWeek: t("Week view"),
                      dayGridDay: t("Day view"),
                    }}
                    // defaultView="listYear"
                    events={eventsData}
                    dayMaxEvents={3}
                    editable={false}
                    droppable={false}
                    selectable={true}
                    firstDay={1}
                    datesSet={function (arg) {
                      setEventsData([]);
                      setDateRange(arg);
                    }}
                    // dateClick={}
                    eventClick={function (arg) {
                      const eventId = arg.event.id;
                      const eventData = eventsData.find((data: any) => data.id === eventId);

                      setEventData(eventData);
                      setShowEventModal(true);
                    }}
                    locale={lang}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EventCalendar;
