export const GET_TASK = "GET_TASK"
export const GET_TASK_SUCCESS = "GET_TASKS_SUCCESS"
export const GET_TASK_ERROR = "GET_TASKS_ERROR"

export const GET_TASKS_LIST = "GET_TASKS_LIST"
export const GET_TASKS_LIST_SUCCESS = "GET_TASKS_LIST_SUCCESS"
export const GET_TASKS_LIST_ERROR = "GET_TASKS_LIST_ERROR"

export const GET_ARCHIVE_TASKS_LIST = "GET_ARCHIVE_TASKS_LIST"
export const GET_ARCHIVE_TASKS_LIST_SUCCESS = "GET_ARCHIVE_TASKS_LIST_SUCCESS"
export const GET_ARCHIVE_TASKS_LIST_ERROR = "GET_ARCHIVE_TASKS_LIST_ERROR"
  
export const CREATE_TASK = "CREATE_TASK"
export const CREATE_TASK_SUCCESS = "CREATE_TASK_SUCCESS"
export const CREATE_TASK_ERROR = "CREATE_TASK_ERROR"

export const UPDATE_TASK = "UPDATE_TASK"
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS"
export const UPDATE_TASK_ERROR = "UPDATE_TASK_ERROR"

export const DELETE_TASK = "DELETE_TASK"
export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS"
export const DELETE_TASK_ERROR = "DELETE_TASK_ERROR"

