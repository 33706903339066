import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Button,
} from "reactstrap";
import { formatCurrency } from "../../utils/formats";
import { useTranslation } from "react-i18next";

const PersonalInfoModel = (props: any) => {
  const { t } = useTranslation();
  let personalInfoInitialData = props.personalInfoInitialData;
  let businessExpensesEmployee = props.businessExpensesEmployee;

  return (
    <React.Fragment>
      <Modal
        size="xl"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">{t("Personal Info")}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label={t("Close")}
          ></button>
        </div>

        <ModalBody>
          <Card>
            <CardHeader>
              <CardTitle>{t("Tax computation")}</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="table-responsive mb-3">
                <Table className="table table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr className="align-bottom">
                      <td>{t("Tax-free amount for attribution to spouse")}</td>
                      <td className="text-end">
                        {formatCurrency(
                          parseFloat(
                            personalInfoInitialData?.tax_free_amount_for_attribution_to_spouse
                          ).toFixed(2),
                          false
                        )}
                      </td>
                    </tr>
                    <tr className="align-bottom">
                      <td>{t("Tax-free amount for children < 3 years old")}</td>
                      <td className="text-end">
                        {formatCurrency(
                          parseFloat(
                            personalInfoInitialData?.tax_free_amount_for_children_less_3_year_old
                          ).toFixed(2),
                          false
                        )}
                      </td>
                    </tr>
                    <tr className="align-bottom">
                      <td>{t("Tax-free amount for single parents")}</td>
                      <td className="text-end">
                        {formatCurrency(
                          parseFloat(
                            personalInfoInitialData?.tax_free_amount_for_single_parents
                          ).toFixed(2),
                          false
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              <div className="table-responsive mb-3">
                <Table className="table table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr className="align-bottom">
                      <td></td>
                      <td className="text-end">{t("Amounts in split situation")}</td>
                    </tr>
                    <tr className="align-bottom">
                      <td>{t("Total tax-free amounts for taxpayer")}</td>
                      <td className="text-end">
                        {formatCurrency(
                          parseFloat(personalInfoInitialData?.tax_free_amount_for_taxpayer).toFixed(
                            2
                          ),
                          false
                        )}
                      </td>
                    </tr>
                    <tr className="align-bottom">
                      <td>{t("Total tax-free amounts for spouse")}</td>
                      <td className="text-end">
                        {formatCurrency(
                          parseFloat(personalInfoInitialData?.tax_free_amount_for_spouse).toFixed(
                            2
                          ),
                          false
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              <div className="table-responsive mb-3">
                <Table className="table table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr className="align-bottom">
                      <td></td>
                      <td className="text-end">
                        {t("Business expenses")}: {t("Director")}
                      </td>
                    </tr>
                    <tr className="align-bottom">
                      <td>{t("Percentage")}</td>
                      <td className="text-end">
                        {formatCurrency(
                          parseFloat(
                            personalInfoInitialData?.business_expenses_director_percentage
                          ).toFixed(2),
                          false
                        )}
                      </td>
                    </tr>
                    <tr className="align-bottom">
                      <td>{t("Maximum amount")}</td>
                      <td className="text-end">
                        {formatCurrency(
                          parseFloat(
                            personalInfoInitialData?.business_expenses_director_maximum_amount
                          ).toFixed(2),
                          false
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              <div className="table-responsive mb-3">
                <Table className="table table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr className="align-bottom">
                      <td colSpan={4} className="text-end">
                        {t("Business expenses")}: {t("Employee")}
                      </td>
                    </tr>
                    {businessExpensesEmployee &&
                      businessExpensesEmployee.map((item: any, idx: number) => (
                        <tr className="align-bottom" key={idx}>
                          <td className="text-end">
                            {formatCurrency(parseFloat(item[0]).toFixed(2), false)}
                          </td>
                          <td className="text-end">
                            {formatCurrency(parseFloat(item[1]).toFixed(2), false)}
                          </td>
                          <td className="text-end">
                            {formatCurrency(parseFloat(item[2]).toFixed(2), false)}
                          </td>
                          <td className="text-end">
                            {formatCurrency(parseFloat(item[3]).toFixed(2), false)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>{t("Commuting exemption")}</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="table-responsive mb-3">
                <Table className="table table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr className="align-bottom">
                      <td>{t("Commuting exemption")}</td>
                      <td className="text-end">
                        {formatCurrency(
                          parseFloat(personalInfoInitialData?.commuting_exemption).toFixed(2),
                          false
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>{t("Attribution to spouse")}</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="table-responsive mb-3">
                <Table className="table table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr className="align-bottom">
                      <td>{t("Attribution to spouse")}</td>
                      <td className="text-end">
                        {formatCurrency(
                          parseFloat(personalInfoInitialData?.attribution_to_spouse).toFixed(2),
                          false
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </ModalBody>

        <ModalFooter>
          <Button color="danger" className="btn-block" type="reset" onClick={props.onCloseClick}>
            {t("Close")}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default PersonalInfoModel;
