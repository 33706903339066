import {
    GET_QUESTIONNAIRE_STATUSES_FAIL,
    GET_QUESTIONNAIRE_STATUSES_SUCCESS,
    GET_QUESTIONNAIRE_STATUS_DETAIL_FAIL,
    GET_QUESTIONNAIRE_STATUS_DETAIL_SUCCESS,
    ADD_NEW_QUESTIONNAIRE_STATUS,
    ADD_QUESTIONNAIRE_STATUS_SUCCESS,
    ADD_QUESTIONNAIRE_STATUS_FAIL,
    UPDATE_QUESTIONNAIRE_STATUS,
    UPDATE_QUESTIONNAIRE_STATUS_SUCCESS,
    UPDATE_QUESTIONNAIRE_STATUS_FAIL,
    DELETE_QUESTIONNAIRE_STATUS_SUCCESS,
    DELETE_QUESTIONNAIRE_STATUS_FAIL,
    DELETE_QUESTIONNAIRE_STATUS,
    GET_QUESTIONNAIRE_STATUSES,
} from "./actionTypes"

const INIT_STATE = {
    statuslist:  {
        data: []
    },
    status: [],
    error: "",
    success: "",
    isModel: false,
    loading: false,
}

const QuestionnaireStatus = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GET_QUESTIONNAIRE_STATUSES:
            return {
                ...state,
                success: "",
                error: "",
                loading: true,
            }
        case GET_QUESTIONNAIRE_STATUSES_SUCCESS:
            return {
                ...state,
                statuslist: action.payload,
                loading: false,
            }

        case GET_QUESTIONNAIRE_STATUSES_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }

        case ADD_NEW_QUESTIONNAIRE_STATUS:
            return {
                ...state,
                success: "",
                error: "",
                loading: true,
            }
        case ADD_QUESTIONNAIRE_STATUS_SUCCESS:
            return {
                ...state,
                status: [...state.status, action.payload.data],
                success: action.payload.message,
                loading: false,
                isModel: false
            }

        case ADD_QUESTIONNAIRE_STATUS_FAIL:
            return {
                ...state,
                error: action.payload.message,
                isModel: true,
                loading: false,
            }

        case GET_QUESTIONNAIRE_STATUS_DETAIL_SUCCESS:
            return {
                ...state,
                statusDetail: action.payload,
                loading: false,
            }

        case UPDATE_QUESTIONNAIRE_STATUS:
            return {
                ...state,
                success: "",
                error: "",
                loading: true,
            }

        case UPDATE_QUESTIONNAIRE_STATUS_SUCCESS:
            return {
                ...state,
                status: [...state.status, action.payload.data],
                success: action.payload.message,
                loading: false,
                isModel: false
            }

        case UPDATE_QUESTIONNAIRE_STATUS_FAIL:
            return {
                ...state,
                error: action.payload.message,
                isModel: true,
                loading: false,
            }
        case DELETE_QUESTIONNAIRE_STATUS:
            return {
                ...state,
                success: '',
                error: '',
                loading: true,
            }

        case DELETE_QUESTIONNAIRE_STATUS_SUCCESS:
            return {
                ...state,
                success: action.payload.message,
                loading: false,
            }

        case DELETE_QUESTIONNAIRE_STATUS_FAIL:
            return {
                ...state,
                error: action.payload.message,
                loading: false,
            }


        case GET_QUESTIONNAIRE_STATUS_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }

        default:
            return state
    }
}

export default QuestionnaireStatus
