import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Table, Button, Input } from "reactstrap";
import Select from "react-select";
import Dropzone from "react-dropzone";
import fileDownload from "js-file-download";
import { Buffer } from "buffer";
import { toast } from "react-toastify";
import Flatpickr from "react-flatpickr";
import { useTranslation } from "react-i18next";

import axios from "axios";
import {
  getAxiosConfigWithToken,
  getAxiosConfigFileUploadWithToken,
  getUserProfile,
  isURL,
  getAzureAccessToken,
  getAxiosConfigWithAzureToken,
  getAxiosConfigWithAzureTokenOnly,
  datePickerI18,
} from "../../helpers/functions_helper";
import * as url from "../../helpers/url_helper";

import DeleteModal from "../../components/Common/DeleteModal";
import commonService from "../../services/common.service";
import azureService from "../../services/azure.service";
import { dateFormat, bytesToMegaBytes } from "../../utils/formats";
import ProgressBar from "../../components/Common/ProgressBar";
import moment from "moment";

import { useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { fileTypesOptions, getLangCode } from "../../common/staticdata";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const UPLOAD_MAX_FILESIZE = process.env.REACT_APP_UPLOAD_MAX_FILESIZE;

const DropzoneUpload = (props: any) => {
  const userProfile = getUserProfile();
  const { t } = useTranslation();
  const { instance, accounts, inProgress } = useMsal();
  const isAzureAuthenticated = useIsAuthenticated();

  const toastId = useRef(null) as any;
  const toastValidation = useRef(null) as any;
  const inputFile = useRef(null) as any;
  const [loading, setLoading] = useState(false);
  const [defaultSelected, setDefaultSelected] = useState(false);

  const {
    moduleData,
    fileTypes = "",
    attachmentForIDs = [],
    hiddenColumns = [],
    fetchData = false,
    isOpen = false,
    isDisabled = false,
    insertId = 0,
    insertSubId = "",
    onSuccess = false,
    onUpload,
    hideList = false,
    enableExpiryDate = false,
    isRequired = false,
    ValidationError = "",
    setAttachmentMandatory = () => { },
    linkBtn = true,
    isEdit = true,
    isDelete = true,
  } = props;

  const [attachmentsList, setAttachmentsList] = useState<any>([]);
  const [attachmentToIDs, setAttachmentToIDs] = useState([]);
  const [selAttachmentForIDs, setSelAttachmentForIDs] = useState("");
  const [attachmentForIDsFiltered, setAttachmentToIDsFiltered] = useState([]);
  const [successIdx, setSuccessIdx] = useState<Array<number>>([]);
  const [attachmentBlock, setAttachmentBlock] = useState(isOpen);
  const [linkBlock, setLinkBlock] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<FileProps[]>([]);
  const [selectedFilesData, setSelectedFilesData] = useState<any[]>([]);
  const [fileList, setFileList] = useState<any[]>([]);
  const [attachment, setAttachment] = useState({ id: "", attachment_id: "" });
  const [recordName, setRecordName] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [selAttachmentForEdit, setSelAttachmentForEdit] = useState<any>([]);
  const [uploadLinksSuccess, setUploadLinksSuccess] = useState(false);
  const [selectedLinksData, setSelectedLinksData] = useState<LinkProps[]>([]);
  const [folderName, setFolderName] = useState("ETC-3.0");
  const [driveID, setDriveID] = useState(null);

  const filteredOptions = (options: Array<[]>) => {
    var newData: any = [];
    var selData: any = [];

    options.map((item: any, index: number) => {
      if (
        item.value !== userProfile.USER_ID &&
        item.value !== "" &&
        item.value !== 0 &&
        item.value !== null &&
        item.isHidden !== true
      ) {
        newData.push({
          label: item.label,
          value: item.value,
        });

        if (item.selected === true) {
          selData.push({
            label: item.label,
            value: item.value,
          });
        }
      }
    });

    if (selData.length > 0 && defaultSelected === false) {
      setAttachmentToIDs(selData);
      setDefaultSelected(true);
    }
    return newData;
  };

  const getAttachmentList = async () => {
    setLoading(true);
    const response = await commonService.getAttachmentList(moduleData);
    if (response.data.status === "success") {
      setAttachmentsList(response.data.data.original.data);
      setLoading(false);
    }
  };

  const onClickSwitch = () => {
    setAttachmentBlock(!attachmentBlock);
    setLinkBlock(!linkBlock);
  };

  function handleAcceptedFiles(files: any) {
    setSelectedFiles((selectedFiles) => [...selectedFiles, ...files]);

    files.forEach((file: any) => {
      setSelectedFilesData((selectedFilesData) => [
        ...selectedFilesData,
        ...[
          {
            file_name: file.name,
            short_description: "",
            progress: 0,
            status: "",
            error_message: "",
          },
        ],
      ]);
    });
  }

  function handleRejectedFiles(files: any) {
    const FILENAMES: any = [];
    let errorMSg = "";
    files.forEach((file: any) => {
      errorMSg = file.errors[0].message;
      errorMSg = errorMSg.replace("File type must be ", "");
      const label = fileTypesOptions.filter(function (types) {
        return types.value === errorMSg;
      });
      errorMSg = label[0] ? label[0]?.label : errorMSg;
      FILENAMES.push(file.file.name);
    });

    if (errorMSg != "" && FILENAMES.length > 0) {
      const file_names = FILENAMES.join(",");
      toast(`${file_names}, ${t("Please upload")} ${errorMSg}`, {
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        delay: 500,
      });
    }
  }

  const handleChange = (
    fieldName: string,
    data: string | number,
    idx: number
  ) => {
    var newData: any = [...selectedFilesData];
    newData[idx][fieldName] = data;
    setSelectedFilesData(newData);
  };

  const handleLinkChange = (
    fieldName: string,
    data: string | number | boolean,
    idx: number
  ) => {
    var newData: any = [...selectedLinksData];
    newData[idx][fieldName] = data;
    setSelectedLinksData(newData);
  };

  const onClickCancel = (row: any, idx: number) => {
    const temp = selectedFiles.filter(
      (data: any, dataIdx: number) => dataIdx !== idx
    );
    setSelectedFiles(temp);

    const temp2 = selectedFilesData.filter(
      (data: any, dataIdx: number) => dataIdx !== idx
    );
    setSelectedFilesData(temp2);
  };

  const onClickLinkCancel = (row: any, idx: number) => {
    if (selectedLinksData.length > 1) {
      const temp = selectedLinksData.filter(
        (data: any, dataIdx: number) => dataIdx !== idx
      );
      setSelectedLinksData(temp);
    } else {
      resetLinkType(idx);
    }
  };
  const onClickLinkAdd = () => {
    setSelectedLinksData((selectedLinksData) => [
      ...selectedLinksData,
      ...[
        {
          file_name: "",
          file_extension: "",
          file_name_source: "",
          short_description: "",
          file_description: "",
          link_type: "lnk",
          progress: 0,
          status: "",
          error_message: "",
          is_loading: false,
        },
      ],
    ]);
  };

  const handleUpload = async () => {
    const files = Array.from(selectedFiles);

    if (files.length) {
      setLoading(true);
      toastId.current = toast(t("Uploading attachments..."), {
        autoClose: false,
        isLoading: true,
      });

      const uploadPromises = files.map((file, idx) => upload(file, idx));
      await Promise.all(uploadPromises).then((res: any) => {
        setUploadSuccess(true);
        if (typeof onSuccess == "function") {
          onSuccess(true);
          setAttachmentToIDs([]);
        }
        setLoading(false);
      });
    } else {
      if (typeof onSuccess == "function") {
        onSuccess(true);
        setAttachmentToIDs([]);
      }
    }
  };

  const upload = async (file: FileProps, idx: number) => {
    const FILESIZE = bytesToMegaBytes(file.size);
    const MAX_FILESIZE = UPLOAD_MAX_FILESIZE ? Number(UPLOAD_MAX_FILESIZE) : 2;
    if (FILESIZE > MAX_FILESIZE) {
      handleChange("status", "error", idx);
      handleChange("progress", 100, idx);
      handleChange(
        "error_message",
        `${t("File is too large to upload, Max")} ${MAX_FILESIZE}MB ${t("allowed")}`,
        idx
      );
      return false;
    }

    // Form data -------------------------------------------------------
    // const moduleData = {isNotify: true, ...moduleData};
    const formData = new FormData();
    formData.append("file_idx", idx as any);
    formData.append("module_app_id", moduleData.module_app_id);
    formData.append(
      "module_main_id",
      insertId !== 0 ? insertId : moduleData.module_main_id
    );
    formData.append(
      "module_sub_id",
      insertSubId !== "" ? insertSubId : moduleData.module_sub_id
    );
    formData.append("section_id", moduleData.section_id);
    formData.append("file_name", file as any);
    formData.append("file_path", moduleData.file_path);
    formData.append(
      "short_description",
      selectedFilesData[idx] && selectedFilesData[idx].short_description
        ? selectedFilesData[idx].short_description
        : ""
    );
    formData.append("attachment_for_ids", selAttachmentForIDs);
    formData.append("type", "file");
    formData.append("isNotify", typeof (moduleData.isNotify) === undefined ? true : moduleData.isNotify);
    // Form data -------------------------------------------------------

    // Submit form -----------------------------------------------------
    handleChange("status", "uploading", idx);
    handleChange("progress", 0, idx);

    await axios
      .post(SERVER_URL + url.UPLOAD_ATTACHMENT, formData, {
        headers: getAxiosConfigFileUploadWithToken(),
        onUploadProgress: (progressEvent) => {
          handleChange(
            "progress",
            (progressEvent.loaded / progressEvent.total) * 100,
            idx
          );
        },
      })
      .then(function (response: any) {
        if (response.message && response.message) {
          handleChange("status", "error", idx);
        }

        if (response.data.status === "success") {
          handleChange("status", "success", idx);
          setSuccessIdx((prevData: number[]) => [...prevData, idx]);
          setFileList((state: any) => [
            ...state,
            { ...response.data.data, ...selectedFilesData[idx] },
          ]);
        }

        if (response.data.status === "error") {
          handleChange("status", "error", idx);
          handleChange("error_message", response.data.message, idx);
        }
      })
      .catch(function (error: any) {
        handleChange("status", "error", idx);

        if (error.response.status === 413) {
          // handleChange("error_message", error.message, idx);
          handleChange("error_message", t("Request entity too large"), idx);
        } else if (error.response.status === 500) {
          handleChange("error_message", t("Something went wrong"), idx);
        } else if (error.request) {
          handleChange("error_message", error.request, idx);
        } else {
          handleChange("error_message", error.message, idx);
        }
      });
    // Submit form -----------------------------------------------------
  };

  const handleUploadLinks = async () => {
    const links = Array.from(selectedLinksData);

    if (links.length) {
      setLoading(true);
      toastId.current = toast(t("Uploading links..."), {
        autoClose: false,
        isLoading: true,
      });

      const uploadPromises = links.map((data, idx) => uploadLink(data, idx));
      await Promise.all(uploadPromises).then((res: any) => {
        setUploadLinksSuccess(true);
        if (typeof onSuccess == "function") {
          onSuccess(true);
          setAttachmentToIDs([]);
        }
        setLoading(false);
      });
    } else {
      if (typeof onSuccess == "function") {
        onSuccess(true);
        setAttachmentToIDs([]);
      }
    }
  };

  const uploadLink = async (data: any, idx: number) => {
    if (data.link_type === "lnk") {
      const IS_URL = isURL(data.file_name);
      if (!IS_URL) {
        handleLinkChange("status", "error", idx);
        handleLinkChange("progress", 100, idx);
        handleLinkChange("error_message", `Invalid link`, idx);
        return false;
      }
    }

    // Form data -------------------------------------------------------
    const formData = {
      file_idx: idx as any,
      module_app_id: moduleData.module_app_id,
      module_main_id: insertId !== 0 ? insertId : moduleData.module_main_id,
      module_sub_id:
        insertSubId !== "" ? insertSubId : moduleData.module_sub_id,
      section_id: moduleData.section_id,
      file_name: data.file_name,
      file_extension: data.file_extension,
      file_path: moduleData.file_path,
      file_name_source: data.file_name_source,
      short_description: data.short_description,
      file_description: data.file_description,
      attachment_for_ids: selAttachmentForIDs,
      type: "link",
    };
    // Form data -------------------------------------------------------

    // Submit form -----------------------------------------------------
    handleLinkChange("status", "uploading", idx);
    handleLinkChange("progress", 0, idx);

    await axios
      .post(SERVER_URL + url.UPLOAD_LINK, formData, {
        headers: getAxiosConfigWithToken(),
        onUploadProgress: (progressEvent) => {
          handleLinkChange(
            "progress",
            (progressEvent.loaded / progressEvent.total) * 100,
            idx
          );
        },
      })
      .then(function (response: any) {
        if (response.message && response.message) {
          handleLinkChange("status", "error", idx);
        }

        if (response.data.status === "success") {
          handleLinkChange("status", "success", idx);
          setSuccessIdx((prevData: number[]) => [...prevData, idx]);
          // setFileList((state: any) => [
          //   ...state,
          //   { ...response.data.data, ...selectedFilesData[idx] },
          // ]);
        }

        if (response.data.status === "error") {
          handleLinkChange("status", "error", idx);
          handleLinkChange("error_message", response.data.message, idx);
        }
      })
      .catch(function (error: any) {
        handleLinkChange("status", "error", idx);

        if (error.response.status === 413) {
          // handleLinkChange("error_message", error.message, idx);
          handleLinkChange("error_message", t("Request entity too large"), idx);
        } else if (error.response.status === 500) {
          handleLinkChange("error_message", t("Something went wrong"), idx);
        } else if (error.request) {
          handleLinkChange("error_message", error.request, idx);
        } else {
          handleLinkChange("error_message", error.message, idx);
        }
      });
    // Submit form -----------------------------------------------------
  };
  // Upload attachment -------------------------------------------------

  // Edit attachment ---------------------------------------------------
  const onClickEdit = (row: any) => {
    setSelAttachmentForEdit(row);
    inputFile.current.click();
  };

  const handleEditAttachment = async (event: any) => {
    const file = event.target.files[0];
    const old_file_name =
      selAttachmentForEdit?.file_name +
      "." +
      selAttachmentForEdit?.file_extension;
    const new_file_name = file.name;

    if (old_file_name !== new_file_name) {
      toast(t("File name must match with the old file name."), {
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        delay: 500,
      });
    } else {
      const FILESIZE = bytesToMegaBytes(file.size);
      const MAX_FILESIZE = UPLOAD_MAX_FILESIZE ? Number(UPLOAD_MAX_FILESIZE) : 2;
      if (FILESIZE > MAX_FILESIZE) {
        toast(`${t("File is too large to upload, Max")} ${MAX_FILESIZE}MB ${t("allowed")}`, {
          type: toast.TYPE.ERROR,
          autoClose: 5000,
          delay: 500,
        });
        return false;
      }
      const formData = new FormData();
      formData.append("id", selAttachmentForEdit?.id);
      formData.append("attachment_id", selAttachmentForEdit?.attachment_id);

      formData.append("file_idx", "0");
      formData.append("module_app_id", selAttachmentForEdit?.module_app_id);
      formData.append("module_main_id", selAttachmentForEdit?.module_main_id);
      formData.append("module_sub_id", selAttachmentForEdit?.module_sub_id);
      formData.append("section_id", selAttachmentForEdit?.section_id);
      formData.append("file_name", file as any);
      formData.append("file_path", selAttachmentForEdit?.file_path);
      formData.append(
        "short_description",
        selAttachmentForEdit?.short_description
          ? selAttachmentForEdit?.short_description
          : ""
      );
      formData.append(
        "attachment_for_ids",
        selAttachmentForEdit?.attachment_for_ids
          ? selAttachmentForEdit?.attachment_for_ids
          : ""
      );

      // Submit form -----------------------------------------------------
      setLoading(true);
      toastId.current = toast(t("Uploading attachments..."), {
        autoClose: false,
        isLoading: true,
      });
      await axios
        .post(SERVER_URL + url.UPLOAD_ATTACHMENT, formData, {
          headers: getAxiosConfigFileUploadWithToken(),
          onUploadProgress: (progressEvent) => {
            // console.log("progress", (progressEvent.loaded / progressEvent.total) * 100);
          },
        })
        .then(function (response: any) {
          if (response.message && response.message) {
            toast.update(toastId.current, {
              type: toast.TYPE.ERROR,
              render: t("Attachments not uploaded successfully, please try again"),
              autoClose: 5000,
              isLoading: false,
              delay: 500,
            });
            setLoading(false);
          }

          if (response.data.status === "success") {
            toast.update(toastId.current, {
              type: toast.TYPE.SUCCESS,
              render: t("Attachments uploaded successfully"),
              autoClose: 5000,
              isLoading: false,
              delay: 500,
            });

            getAttachmentList();
            setLoading(false);
          }

          if (response.data.status === "error") {
            toast.update(toastId.current, {
              type: toast.TYPE.ERROR,
              render: t("Attachments not uploaded successfully, please try again"),
              autoClose: 5000,
              isLoading: false,
              delay: 500,
            });
            setLoading(false);
          }
        })
        .catch(function (error: any) {
          let error_message = "";
          if (error.response.status === 413) {
            error_message = t("Request entity too large");
          } else if (error.response.status === 500) {
            error_message = t("Something went wrong");
          } else if (error.request) {
            error_message = error.request;
          } else {
            error_message = error.message;
          }
          toast.update(toastId.current, {
            type: toast.TYPE.ERROR,
            render: error_message,
            autoClose: 5000,
            isLoading: false,
            delay: 500,
          });
          setLoading(false);
        });
      // Submit form -----------------------------------------------------
    }
  };
  // Edit attachment ---------------------------------------------------

  // Delete attachment -------------------------------------------------
  const onClickDelete = (row: any) => {
    const deleteAttachmentData = {
      ...attachment,
      id: Buffer.from(String(row.id)).toString("base64"),
      attachment_id: Buffer.from(String(row.attachment_id)).toString("base64"),
    };
    setAttachment(deleteAttachmentData);
    setRecordName(row.file_name + "." + row.file_extension);
    setDeleteModal(true);
  };
  const handleDeleteAttachment = async () => {
    setLoading(true);
    toastId.current = toast(t("Deleting attachment..."), {
      autoClose: false,
      isLoading: true,
    });
    try {
      const res = (await commonService.deleteAttachment(attachment)) as any;
      if (res.data.status === "success") {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: t("Attachment deleted successfully"),
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        // Delete deleted row -----------------------------------------------------
        const temp = attachmentsList.filter(
          (data: any) => data.id != res.data.data.id
        );
        setAttachmentsList(temp);
        // Delete deleted row -----------------------------------------------------
        setLoading(false);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: t("Something went wrong."),
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoading(false);
      }
    } catch (err) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: t("Something went wrong."),
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }

    setDeleteModal(false);
  };
  // Delete attachment ------------------------------------------------------------

  // Download attachment ------------------------------------------------------------
  const onClickDownload = (row: any) => {
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const AUTH_USER = localStorage.getItem("authUser");
    const ACCESS_TOKEN = JSON.parse(String(AUTH_USER));
    axios({
      url:
        SERVER_URL +
        "/common/download-attachment/" +
        Buffer.from(String(row.id)).toString("base64") +
        "/" +
        Buffer.from(String(row.attachment_id)).toString("base64"),
      method: "GET",
      headers: {
        "Content-Type": "application/" + row.file_extension,
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + ACCESS_TOKEN.accessToken,
      },
      responseType: "blob",
    }).then((response: any) => {
      fileDownload(response.data, row.file_name + "." + row.file_extension);
    });
  };

  const onClickAzureExport = async (row: any, format: string) => {
    let data = JSON.parse(row.file_description);
    if (isAzureAuthenticated === true) {
      toastId.current = toast(t("Document exporting..."), {
        autoClose: false,
        isLoading: true,
      });
      const file = await azureService.getContent(data.id, format);
      if (file.status === 200) {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: t("Document successfully exported"),
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        fileDownload(file.data, data.name + "." + format);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: file.response.statusText + t(" - Sorry, something went wrong"),
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
      }
    } else {
      instance.loginPopup(loginRequest).catch((e: any) => {
        console.error(e);
      });
    }
  };

  const onClickAzureDownload = async (row: any, format: string) => {
    let data = JSON.parse(row.file_description);
    if (isAzureAuthenticated === true) {
      toastId.current = toast(t("Document downloading..."), {
        autoClose: false,
        isLoading: true,
      });
      const file = await azureService.downloadContent(data.id);
      if (file.status === 200) {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: t("Document successfully downloaded"),
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        fileDownload(file.data, data.name);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: file.response.statusText + t(" - Sorry, something went wrong"),
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
      }
    } else {
      instance.loginPopup(loginRequest).catch((e: any) => {
        console.error(e);
      });
    }
  };
  // Download attachment ------------------------------------------------------------

  const resetLinkType = (idx: number) => {
    handleLinkChange("file_name", "", idx);
    handleLinkChange("file_extension", "", idx);
    handleLinkChange("file_description", "", idx);
    handleLinkChange("link_type", "lnk", idx);
    handleLinkChange("is_loading", false, idx);
  };

  // const opneLink = () => {};

  const createSharePointFile = async (fileType: string, idx: number) => {
    if (isAzureAuthenticated === true) {
      if (selectedLinksData && selectedLinksData[idx].file_description) {
        const file_data = JSON.parse(selectedLinksData[idx].file_description);
        window.open(file_data.webUrl, "_blank");
        return false;
      }

      let documentNameGenerated = false;
      let documentName = selectedLinksData[idx].file_name;
      if (documentName === "") {
        documentName = "Document-" + moment().format("YYYYMMDDhhmmss");
        documentNameGenerated = true;
      }
      handleLinkChange("file_name", documentName, idx);
      handleLinkChange("file_extension", fileType, idx);
      handleLinkChange("is_loading", true, idx);

      toastId.current = toast(t("Document generating..."), {
        autoClose: false,
        isLoading: true,
      });

      if (driveID) {
        // Check file -----------------------------------
        let is_exist = false;
        if (documentNameGenerated === false) {
          const is_file_exist = await azureService.checkContent(
            driveID,
            folderName + "/" + moduleData.file_path,
            documentName + "." + fileType
          );

          if (is_file_exist.status === 200) {
            is_exist = true;
          }
        }
        if (is_exist === true) {
          toast.update(toastId.current, {
            type: toast.TYPE.ERROR,
            render: t("Document name is already exist."),
            autoClose: 5000,
            isLoading: false,
            delay: 500,
          });

          resetLinkType(idx);
          return false;
        }

        // Create file
        const formData = {
          fileName: documentName,
          fileType: fileType,
          filePath: moduleData.file_path,
          accessToken: await getAxiosConfigWithAzureTokenOnly() || localStorage.getItem("accessTokenSSO"),
          driveID: driveID,
        };
        const response = await azureService.createContent(formData);
        if (response.data.status === "success") {
          handleLinkChange("file_name_source", response.data.data.webUrl, idx);
          handleLinkChange("file_description", response.data.data, idx);
          handleLinkChange("link_type", fileType, idx);
          handleLinkChange("is_loading", false, idx);

          toast.update(toastId.current, {
            type: toast.TYPE.SUCCESS,
            render: response.data.message,
            autoClose: 5000,
            isLoading: false,
            delay: 500,
          });

          window.open(response.data.data.webUrl, "_blank");
        } else {
          toast.update(toastId.current, {
            type: toast.TYPE.ERROR,
            render: response.data.message,
            autoClose: 5000,
            isLoading: false,
            delay: 500,
          });

          resetLinkType(idx);
        }
        // Create file
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render:
            t("Access denide, You don't currently have permission to access this drive."),
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        resetLinkType(idx);
      }
    } else {
      instance.loginPopup(loginRequest).catch((e: any) => {
        console.error(e);
      });

      resetLinkType(idx);
    }
  };

  const getAzureDriveID = async () => {
    const driveID = await azureService.getDriveID();
    if (driveID.status === 200) {
      setDriveID(driveID.data.id);
    }
  };

  useEffect(() => {
    if (isAzureAuthenticated === true) {
      getAzureDriveID();
    }
  }, [isAzureAuthenticated]);

  useEffect(() => {
    if (moduleData !== null && fetchData === true) {
      getAttachmentList();
    }
  }, [fetchData]);

  useEffect(() => {
    if (isRequired && attachmentsList && attachmentsList.length == 0) {
      let validation_error =
        ValidationError == "" || ValidationError == null
          ? (t("Please upload attchements for") + ValidationError)
          : ValidationError;
      toastValidation.current = toast(validation_error, {
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
    if (attachmentsList) {
      setAttachmentMandatory(attachmentsList.length);
    }
  }, [attachmentsList, isRequired]);

  useEffect(() => {
    if (insertId !== 0 && insertSubId !== "") {
      if (attachmentBlock) handleUpload();

      if (linkBlock) handleUploadLinks();
    }
  }, [insertId, insertSubId]);

  useEffect(() => {
    if (uploadSuccess === true) {
      const errorFiles = selectedFilesData.filter(
        (data: any) => data.status === "error"
      );

      if (errorFiles.length === 0) {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: t("Attachments uploaded successfully"),
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        setSelectedFiles([]);
        setSelectedFilesData([]);

        if (isOpen === false) {
          setAttachmentBlock(false);
        }
      }

      if (selectedFiles.length !== errorFiles.length && errorFiles.length > 0) {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render:
            t("Only some attachments uploaded successfully, please try again"),
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        // Remove uploaded row -----------------------------------------------------
        const temp = selectedFiles.filter(
          (data: any, dataIdx: number) => !successIdx.includes(dataIdx)
        );
        setSelectedFiles(temp);

        const temp2 = selectedFilesData.filter(
          (data: any, dataIdx: number) => !successIdx.includes(dataIdx)
        );
        setSelectedFilesData(temp2);
        // Remove uploaded row -----------------------------------------------------
      }

      if (selectedFiles.length === errorFiles.length) {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: t("Attachments not uploaded successfully, please try again"),
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
      }

      getAttachmentList();
      setSuccessIdx([]);
      setUploadSuccess(false);
      onUpload && onUpload(fileList);
    }
  }, [uploadSuccess]);

  useEffect(() => {
    if (uploadLinksSuccess === true) {
      const errorFiles = selectedLinksData.filter(
        (data: any) => data.status === "error"
      );

      if (errorFiles.length === 0) {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: t("Links uploaded successfully"),
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        setSelectedLinksData([
          {
            file_name: "",
            file_extension: "",
            file_name_source: "",
            short_description: "",
            file_description: "",
            link_type: "lnk",
            progress: 0,
            status: "",
            error_message: "",
            is_loading: false,
          },
        ]);

        if (isOpen === false) {
          setLinkBlock(false);
        }
      }

      if (
        selectedLinksData.length !== errorFiles.length &&
        errorFiles.length > 0
      ) {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: t("Only some links uploaded successfully, please try again"),
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        // Remove uploaded row -----------------------------------------------------
        const temp = selectedLinksData.filter(
          (data: any, dataIdx: number) => !successIdx.includes(dataIdx)
        );
        setSelectedLinksData(temp);
        // Remove uploaded row -----------------------------------------------------
      }

      if (selectedLinksData.length === errorFiles.length) {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: t("Links not uploaded successfully, please try again"),
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
      }

      getAttachmentList();
      setSuccessIdx([]);
      setUploadLinksSuccess(false);
      // onUpload && onUpload(fileList);
    }
  }, [uploadLinksSuccess]);

  useEffect(() => {
    var newData: any = [];
    attachmentToIDs.map((item: any, index: number) => {
      if (item.value !== userProfile.USER_ID) {
        newData.push(item.value);
      }
    });
    setSelAttachmentForIDs(newData);
  }, [attachmentToIDs]);

  useEffect(() => {
    if (attachmentForIDs.length > 0) {
      setAttachmentToIDsFiltered(filteredOptions(attachmentForIDs));
    }
  }, [attachmentForIDs]);

  useEffect(() => {
    setSelectedLinksData((selectedLinksData) => [
      ...selectedLinksData,
      ...[
        {
          file_name: "",
          file_extension: "",
          file_name_source: "",
          short_description: "",
          file_description: "",
          link_type: "lnk",
          progress: 0,
          status: "",
          error_message: "",
          is_loading: false,
        },
      ],
    ]);
  }, []);

  //Drag File
  const onDragStart = (ev: any, data: any) => {
    ev.dataTransfer.setData("file", JSON.stringify(data));
  };

  return (
    <React.Fragment>
      {attachment && (
        <DeleteModal
          recordName={recordName}
          modalTitle={t("Attachment")}
          show={deleteModal}
          onDeleteClick={handleDeleteAttachment}
          onCloseClick={() => setDeleteModal(false)}
          loading={loading}
        />
      )}

      {!hideList && moduleData.module_main_id !== 0 ? (
        <Col xl="12">
          <div id="attachments_data_table" className="table-responsive mb-3">
            <Table className="table table-bordered nowrap w-100 mb-0">
              <thead className="thead-light">
                <tr>
                  {!hiddenColumns.includes(1) && <th>{t("Attachment")}</th>}
                  {!hiddenColumns.includes(2) && <th>{t("Info")}</th>}
                  {(moduleData.module_app_id === 1 ||
                    moduleData.module_app_id === 9) && <th>{t("Source")}</th>}
                  {!hiddenColumns.includes(4) && <th>{t("Action")}</th>}
                </tr>
              </thead>
              <tbody>
                {fetchData === true &&
                  attachmentsList &&
                  attachmentsList.length > 0 ? (
                  attachmentsList.map((data: any) => (
                    <tr key={data.id}>
                      {!hiddenColumns.includes(1) && (
                        <td onDragStart={(e) => onDragStart(e, data)} draggable>
                          <span
                            className="me-2"
                            dangerouslySetInnerHTML={{ __html: data.file_type }}
                          />

                          {data.type === "file" ? (
                            <span
                              className="link-primary"
                              title={data.file_name}
                              onClick={() => onClickDownload(data)}
                            >
                              {data.file_name.substring(0, 50)}
                              {data.file_name.length >= 50 && "..."}
                              {"."}
                              {data.file_extension}
                            </span>
                          ) : (
                            <>
                              <a
                                href={
                                  data.file_extension
                                    ? data.file_name_source
                                    : data.file_name
                                }
                                className="text-decoration-underline"
                                target="_blank"
                              >
                                {data.file_extension === "" ||
                                  data.file_extension === null ? (
                                  data.short_description ? (
                                    <>
                                      {data.short_description.substring(0, 50)}
                                      {data.short_description.length >= 50 &&
                                        "..."}
                                    </>
                                  ) : (
                                    <>
                                      {data.file_name.substring(0, 50)}
                                      {data.file_name.length >= 50 && "..."}
                                    </>
                                  )
                                ) : (
                                  <>
                                    {data.file_name.substring(0, 50)}
                                    {data.file_name.length >= 50 && "..."}
                                    {data.file_extension &&
                                      "." + data.file_extension}
                                  </>
                                )}
                              </a>
                            </>
                          )}

                          {data.version_count > 0 ? (
                            <span
                              className="badge bg-success ms-1"
                              title={t("Version")}
                            >
                              {data.version_count}
                            </span>
                          ) : null}

                          {(data.type === "link" && data.file_extension) ||
                            data.attachment_for_ids_array ? (
                            <div className="mt-2"></div>
                          ) : null}

                          {data.type === "link" && data.file_extension ? (
                            <>
                              <span className="badge_custome badge-soft-secondary badge bg-secondary rounded-pill me-2">
                                <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                                  grid_view
                                </span>
                                {t("SharePoint file")}
                              </span>
                            </>
                          ) : null}

                          {data.attachment_for_ids_array ? (
                            <>
                              {data.attachment_for_ids_array.map(
                                (user_data: any, idx: number) => (
                                  <span
                                    key={idx}
                                    title={`Shared with ${user_data.first_name +
                                      " " +
                                      user_data.last_name
                                      }`}
                                    className={`badge_custome badge ${user_data.id === userProfile.USER_ID ? "badge-soft-primary bg-primary" : "badge-soft-info bg-info"} rounded-pill me-2`}
                                  >
                                    <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                                      alternate_email
                                    </span>
                                    {user_data.first_name +
                                      " " +
                                      user_data.last_name}
                                  </span>
                                )
                              )}
                            </>
                          ) : null}
                        </td>
                      )}
                      {!hiddenColumns.includes(2) && (
                        <td>
                          {data.short_description &&
                            data.file_extension !== "" &&
                            data.file_extension !== null && (
                              <p
                                className="font-size-14 text-success mb-3"
                                style={{ whiteSpace: "normal", width: "300px" }}
                              >
                                {data.short_description}
                              </p>
                            )}
                          <ul className="list-inline mb-0">
                            <li className="mb-1">
                              <span className="text-muted">
                                <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                                  person
                                </span>
                                {t("Uploaded by")} {data.first_name}{" "}
                                {data.last_name}
                              </span>
                            </li>
                            <li className="mb-1">
                              <span className="text-muted">
                                <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                                  calendar_today
                                </span>
                                {dateFormat(
                                  data.created_at,
                                  {
                                    dateStyle: "long",
                                    timeStyle: "short",
                                    hour12: true,
                                  },
                                  getLangCode(localStorage.getItem("I18N_LANGUAGE"))
                                )}
                              </span>
                            </li>
                          </ul>
                        </td>
                      )}
                      {(moduleData.module_app_id === 1 ||
                        moduleData.module_app_id === 9) && (
                          <td>
                            <Link
                              className="btn btn-sm btn-outline-primary btn-rounded"
                              to={`/${data.url_edit}${data.module_main_id}/${data.module_sub_id}`}
                            >
                              {t(data.module_value)}
                            </Link>
                          </td>
                        )}
                      {!hiddenColumns.includes(4) && (
                        <td>
                          <div className="d-flex gap-2 text-end">
                            {data.type === "file" ? (
                              <Button
                                outline={true}
                                color="primary"
                                size="sm"
                                onClick={() => onClickDownload(data)}
                                title={t("Download file")}
                              >
                                <span className="align-middle material-symbols-outlined fw-light fs-5">
                                  download
                                </span>
                              </Button>
                            ) : isAzureAuthenticated === true &&
                              data.type === "link" &&
                              data.file_extension &&
                              userProfile.IS_COMPANY_ADMIN ? (
                              <Button
                                outline={true}
                                color="primary"
                                size="sm"
                                onClick={() =>
                                  onClickAzureDownload(
                                    data,
                                    data.file_extension
                                  )
                                }
                                title={t("Download file")}
                              >
                                <span className="align-middle material-symbols-outlined fw-light fs-5">
                                  cloud_download
                                </span>
                              </Button>
                            ) : (
                              <a
                                href={
                                  data.file_extension
                                    ? data.file_name_source
                                    : data.file_name
                                }
                                target="_blank"
                                className="btn btn-sm btn-outline-primary"
                                title={t("Open link")}
                              >
                                <span className="align-middle material-symbols-outlined fw-light fs-5">
                                  link
                                </span>
                              </a>
                            )}

                            {isAzureAuthenticated === true &&
                              data.type === "link" &&
                              data.file_extension &&
                              userProfile.IS_COMPANY_ADMIN ? (
                              <Button
                                outline={true}
                                color="danger"
                                size="sm"
                                onClick={() => onClickAzureExport(data, "pdf")}
                                title={t("Download PDF file")}
                              >
                                <span className="align-middle material-symbols-outlined fw-light fs-5">
                                  picture_as_pdf
                                </span>
                              </Button>
                            ) : null}

                            {(userProfile.IS_COMPANY_ADMIN ||
                              userProfile.USER_ID === data.created_by) &&
                              data.type === "file" &&
                              isDisabled === false  &&  isEdit === true ? (
                              <>
                                <Button
                                  outline={true}
                                  color="primary"
                                  size="sm"
                                  onClick={() => onClickEdit(data)}
                                  title={t("Re upload (Edited file)")}
                                >
                                  <span className="align-middle material-symbols-outlined fw-light fs-5">
                                    edit
                                  </span>
                                </Button>
                                <input
                                  type="file"
                                  id="file"
                                  ref={inputFile}
                                  onChange={handleEditAttachment}
                                  style={{ display: "none" }}
                                />
                              </>
                            ) : null}

                            {(userProfile.IS_COMPANY_ADMIN ||
                              userProfile.USER_ID === data.created_by) &&
                              isDisabled === false && isDelete === true ? (
                              <Button
                                outline={true}
                                color="danger"
                                size="sm"
                                onClick={() => onClickDelete(data)}
                                title={t("Delete file")}
                              >
                                <span className="align-middle material-symbols-outlined fw-light fs-5">
                                  delete
                                </span>
                              </Button>
                            ) : null}
                          </div>
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={
                        moduleData.module_app_id === 1 ||
                          moduleData.module_app_id === 9
                          ? 3 - hiddenColumns.length
                          : 4 - hiddenColumns.length
                      }
                    >
                      {loading ? t("Loading...") : t("No attachments found")}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Col>
      ) : null}

      {attachmentBlock === false &&
        linkBlock === false &&
        isDisabled === false ? (
        <Col xl="12">
          {/* {CAN_CREATE ? ( */}
          <Button
            color="primary"
            className="btn-block me-2 ms-2"
            type="button"
            onClick={() => {
              setAttachmentBlock(true);
              setLinkBlock(false);
            }}
            disabled={loading}
          >
            {t("Attachment")}
          </Button>

          <Button
            color="primary"
            className="btn-block me-2"
            type="button"
            onClick={() => {
              setLinkBlock(true);
              setAttachmentBlock(false);
            }}
            disabled={loading}
          >
            {t("Link")}
          </Button>
          {/* ) : null} */}
        </Col>
      ) : null}

      {(attachmentBlock === true || linkBlock === true) &&
        isDisabled === false ? (
        <>
          {/* {CAN_CREATE ? ( */}
          <>
            <Row>
              {moduleData.module_main_id === 0 && (
                <Col xl={4} lg={4} md={4} sm={12} className="mb-1">
                  {(linkBtn === true) && (
                    <>
                      <Button
                        outline={true}
                        color="primary"
                        size="sm"
                        className="btn-label mt-1 mb-1"
                        title={t("Remove")}
                        onClick={() => onClickSwitch()}
                      >
                        {attachmentBlock === true && (
                          <>
                            <span className="align-middle material-symbols-outlined fw-light fs-5 label-icon">
                              toggle_off
                            </span>
                            {t("Switch to Links")}
                          </>
                        )}
                        {linkBlock === true && (
                          <>
                            <span className="align-middle material-symbols-outlined fw-light fs-5 label-icon">
                              toggle_on
                            </span>
                            {t("Switch to Attachments")}
                          </>
                        )}
                      </Button>
                    </>
                  )}
                </Col>
              )}
            </Row>

            {attachmentBlock === true ? (
              <Col xl={12} lg={12} md={12} sm={12} className="mb-3">
                <div className="is-invalid">
                  <Dropzone
                    onDrop={(acceptedFiles, fileRejections) => {
                      handleAcceptedFiles(acceptedFiles);
                      handleRejectedFiles(fileRejections);
                    }}
                    accept={fileTypes}
                    disabled={loading}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick mt-2"
                          {...getRootProps()}
                        >
                          <input
                            {...getInputProps()}
                            name="file_name"
                            accept={fileTypes}
                            disabled={loading}
                          />
                          <div className="mb-3">
                            <span className="align-middle material-symbols-outlined fw-lighter display-4 text-muted">
                              cloud_upload
                            </span>
                          </div>
                          <h4>{t("Drop files here or click to upload.")}</h4>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
              </Col>
            ) : null}

            {selectedFiles &&
              selectedFiles.length > 0 &&
              attachmentBlock === true ? (
              <Col xl="12" className="mb-1">
                <div className="table-responsive">
                  <Table className="table table-bordered nowrap w-100">
                    <thead className="thead-light">
                      <tr>
                        <th>{t("File")}</th>
                        <th>{t("File description")}</th>
                        {enableExpiryDate ? (
                          <>
                            <th>{t("Expiry date")}</th>
                            <th>{t("Due date")}</th>
                          </>
                        ) : null}
                        <th>{t("Action")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedFiles && selectedFiles.length > 0
                        ? selectedFiles.map((data: any, idx: number) => (
                          <tr key={idx}>
                            <td>
                              <Link to="#">{data.name}</Link>

                              {selectedFilesData[idx] &&
                                selectedFilesData[idx].progress &&
                                selectedFilesData[idx].status !== "error" ? (
                                <div>
                                  <ProgressBar
                                    progress={selectedFilesData[idx].progress}
                                  />
                                </div>
                              ) : null}

                              {selectedFilesData[idx] &&
                                selectedFilesData[idx].status === "error" ? (
                                <span
                                  className="badge_custome badge-soft-danger badge bg-danger rounded-pill m-2"
                                  title={selectedFilesData[idx].error_message}
                                >
                                  <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                                    cloud_off
                                  </span>
                                  {t("Upload failed")}
                                </span>
                              ) : null}
                            </td>
                            <td>
                              <div className="mb-0">
                                <Input
                                  id={`short_description_${idx}`}
                                  name={`short_description_[${idx}]`}
                                  placeholder={t("File description")}
                                  type="text"
                                  maxLength={100}
                                  value={
                                    selectedFilesData[idx] &&
                                      selectedFilesData[idx].short_description
                                      ? selectedFilesData[idx]
                                        .short_description
                                      : ""
                                  }
                                  readOnly={
                                    selectedFilesData[idx] &&
                                      (selectedFilesData[idx].status ===
                                        "uploading" ||
                                        selectedFilesData[idx].status ===
                                        "success")
                                      ? true
                                      : loading
                                  }
                                  onChange={(e) =>
                                    handleChange(
                                      "short_description",
                                      e.target.value,
                                      idx
                                    )
                                  }
                                />
                              </div>
                            </td>
                            {enableExpiryDate ? (
                              <>
                                <td>
                                  <div className="input-group is-invalid">
                                    <Flatpickr
                                      id={`expiry_date_${idx}`}
                                      name={`expiry_date_[${idx}]`}
                                      className="form-control d-block"
                                      type="text"
                                      placeholder="DD/MM/YYYY"
                                      value={
                                        selectedFilesData[idx] &&
                                          selectedFilesData[idx].expiry_date
                                          ? selectedFilesData[idx].expiry_date
                                          : ""
                                      }
                                      readOnly={
                                        selectedFilesData[idx] &&
                                          (selectedFilesData[idx].status ===
                                            "uploading" ||
                                            selectedFilesData[idx].status ===
                                            "success")
                                          ? true
                                          : loading
                                      }
                                      options={{
                                        altInput: true,
                                        altFormat: "d/m/Y",
                                        dateFormat: "Y-m-d H:i:S",
                                        minDate: "today",
                                        allowInput: true,
                                        onChange: function (
                                          selectedDates,
                                          dateStr,
                                          instance
                                        ) {
                                          handleChange(
                                            "expiry_date",
                                            dateStr,
                                            idx
                                          );
                                          handleChange(
                                            "due_date",
                                            moment(dateStr)
                                              .subtract(60, "days")
                                              .format("YYYY-MM-DD"),
                                            idx
                                          );
                                        },
                                        locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                                      }}
                                    />
                                    <span className="input-group-text">
                                      <span className="align-middle material-symbols-outlined fw-light fs-5">
                                        calendar_today
                                      </span>
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div className="input-group is-invalid">
                                    <Flatpickr
                                      id={`due_date_${idx}`}
                                      name={`due_date_[${idx}]`}
                                      className="form-control d-block"
                                      type="text"
                                      placeholder="DD/MM/YYYY"
                                      value={
                                        selectedFilesData[idx] &&
                                          selectedFilesData[idx].due_date
                                          ? selectedFilesData[idx].due_date
                                          : ""
                                      }
                                      readOnly={
                                        selectedFilesData[idx] &&
                                          (selectedFilesData[idx].status ===
                                            "uploading" ||
                                            selectedFilesData[idx].status ===
                                            "success")
                                          ? true
                                          : loading
                                      }
                                      options={{
                                        altInput: true,
                                        altFormat: "d/m/Y",
                                        dateFormat: "Y-m-d H:i:S",
                                        allowInput: true,
                                        onChange: function (
                                          selectedDates,
                                          dateStr,
                                          instance
                                        ) {
                                          handleChange(
                                            "due_date",
                                            dateStr,
                                            idx
                                          );
                                        },
                                        locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                                      }}
                                    />
                                    <span className="input-group-text">
                                      <span className="align-middle material-symbols-outlined fw-light fs-5">
                                        calendar_today
                                      </span>
                                    </span>
                                  </div>
                                </td>
                              </>
                            ) : null}
                            <td>
                              <div className="d-flex gap-2 text-end">
                                <Button
                                  outline={true}
                                  color="danger"
                                  size="sm"
                                  className="mt-1"
                                  title={t("Remove")}
                                  disabled={
                                    selectedFilesData[idx] &&
                                      (selectedFilesData[idx].status ===
                                        "uploading" ||
                                        selectedFilesData[idx].status ===
                                        "success")
                                      ? true
                                      : loading
                                  }
                                  onClick={() => onClickCancel(data, idx)}
                                >
                                  <span className="align-middle material-symbols-outlined fw-light fs-5">
                                    close
                                  </span>
                                </Button>
                              </div>
                            </td>
                          </tr>
                        ))
                        : null}
                    </tbody>
                  </Table>
                </div>
              </Col>
            ) : null}

            {selectedLinksData &&
              selectedLinksData.length > 0 &&
              linkBlock === true ? (
              <Col xl="12" className="mb-1">
                <div className="table-responsive">
                  <Table className="table table-bordered nowrap w-100">
                    <thead className="thead-light">
                      <tr>
                        <th>{t("Link / Document name")}</th>
                        <th>{t("Description")}</th>
                        <th>{t("Action")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedLinksData && selectedLinksData.length > 0
                        ? selectedLinksData.map((data: any, idx: number) => (
                          <tr key={idx}>
                            <td>
                              <div className="input-group is-invalid">
                                <Input
                                  id={`file_name_${idx}`}
                                  name={`file_name_[${idx}]`}
                                  placeholder={t("Link / Document name")}
                                  type="text"
                                  maxLength={1500}
                                  value={data.file_name}
                                  disabled={
                                    data.link_type !== "lnk" ? true : false
                                  }
                                  onChange={(e) =>
                                    handleLinkChange(
                                      "file_name",
                                      e.target.value,
                                      idx
                                    )
                                  }
                                />

                                {/* <span
                                    className={`input-group-text`}
                                    title={t("Open")}
                                    onClick={() => opneLink()}>
                                    <span className="align-middle material-symbols-outlined fw-light fs-5">display_external_input</span>
                                  </span> */}

                                <span
                                  className={`input-group-text ${data.link_type == "lnk"
                                    ? "bg-primary text-white"
                                    : ""
                                    } ${data.file_description &&
                                      data.link_type !== "lnk"
                                      ? "pe-none text-muted"
                                      : ""
                                    }`}
                                  title={t("Link")}
                                  onClick={() => {
                                    resetLinkType(idx);
                                  }}
                                >
                                  <span className="align-middle material-symbols-outlined fw-light fs-5">
                                    link
                                  </span>
                                </span>

                                {isAzureAuthenticated === true &&
                                  userProfile.IS_INTERNAL && (
                                    <>
                                      <span
                                        className={`input-group-text ${data.link_type == "docx"
                                          ? "bg-primary text-white"
                                          : ""
                                          } ${data.is_loading ||
                                            (data.file_description &&
                                              data.link_type !== "docx")
                                            ? "pe-none text-muted"
                                            : ""
                                          }`}
                                        title={t("Word file")}
                                        onClick={() =>
                                          createSharePointFile("docx", idx)
                                        }
                                      >
                                        {data.is_loading &&
                                          data.link_type === "docx" ? (
                                          <span className="align-middle material-symbols-outlined fw-light fs-5">
                                            hourglass_empty
                                          </span>
                                        ) : (
                                          <i className="fa fa-file-word-o"></i>
                                        )}
                                      </span>
                                      <span
                                        className={`input-group-text ${data.link_type == "xlsx"
                                          ? "bg-primary text-white"
                                          : ""
                                          } ${data.is_loading ||
                                            (data.file_description &&
                                              data.link_type !== "xlsx")
                                            ? "pe-none text-muted"
                                            : ""
                                          }`}
                                        title={t("Excel file")}
                                        onClick={() =>
                                          createSharePointFile("xlsx", idx)
                                        }
                                      >
                                        {data.is_loading &&
                                          data.link_type === "xlsx" ? (
                                          <span className="align-middle material-symbols-outlined fw-light fs-5">
                                            hourglass_empty
                                          </span>
                                        ) : (
                                          <i className="fa fa-file-excel-o"></i>
                                        )}
                                      </span>
                                      <span
                                        className={`input-group-text ${data.link_type == "pptx"
                                          ? "bg-primary text-white"
                                          : ""
                                          } ${data.is_loading ||
                                            (data.file_description &&
                                              data.link_type !== "pptx")
                                            ? "pe-none text-muted"
                                            : ""
                                          }`}
                                        title={t("Powerpoint file")}
                                        onClick={() =>
                                          createSharePointFile("pptx", idx)
                                        }
                                      >
                                        {data.is_loading &&
                                          data.link_type === "pptx" ? (
                                          <span className="align-middle material-symbols-outlined fw-light fs-5">
                                            hourglass_empty
                                          </span>
                                        ) : (
                                          <i className="fa fa-file-powerpoint-o"></i>
                                        )}
                                      </span>
                                    </>
                                  )}
                              </div>

                              {data.progress && data.status !== "error" ? (
                                <div>
                                  <ProgressBar progress={data.progress} />
                                </div>
                              ) : null}

                              {data.status === "error" ? (
                                <span
                                  className="badge_custome badge-soft-danger badge bg-danger rounded-pill m-2"
                                  title={data.error_message}
                                >
                                  <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                                    cloud_off
                                  </span>
                                  {t("Upload failed")}
                                </span>
                              ) : null}
                            </td>
                            <td>
                              <div className="mb-0">
                                <Input
                                  id={`short_description_${idx}`}
                                  name={`short_description_[${idx}]`}
                                  placeholder={t("Description")}
                                  type="text"
                                  maxLength={100}
                                  value={data.short_description}
                                  onChange={(e) =>
                                    handleLinkChange(
                                      "short_description",
                                      e.target.value,
                                      idx
                                    )
                                  }
                                />
                              </div>
                            </td>
                            <td>
                              <div className="d-flex gap-2 text-end">
                                {(idx === 0 && data.file_name.length > 0) ||
                                  idx !== 0 ? (
                                  <Button
                                    outline={true}
                                    color="danger"
                                    size="sm"
                                    className="mt-1"
                                    title={t("Remove")}
                                    disabled={
                                      data.status === "uploading" ||
                                        data.status === "success"
                                        ? true
                                        : loading
                                    }
                                    onClick={() =>
                                      onClickLinkCancel(data, idx)
                                    }
                                  >
                                    <span className="align-middle material-symbols-outlined fw-light fs-5">
                                      close
                                    </span>
                                  </Button>
                                ) : null}

                                {selectedLinksData.length - 1 === idx ? (
                                  <Button
                                    outline={true}
                                    color="primary"
                                    size="sm"
                                    className="mt-1"
                                    title={t("Add new")}
                                    onClick={() => onClickLinkAdd()}
                                  >
                                    <span className="align-middle material-symbols-outlined fw-light fs-5">
                                      add
                                    </span>
                                  </Button>
                                ) : null}
                              </div>
                            </td>
                          </tr>
                        ))
                        : null}
                    </tbody>
                  </Table>
                </div>
              </Col>
            ) : null}

            <Col xl="12">
              <Row>
                <Col xl={4} lg={4} md={4} sm={12} className="mb-1">
                  {attachmentForIDsFiltered.length > 0 && (
                    <Select
                      id="attachment_for_ids"
                      name="attachment_for_ids"
                      className="is-invalid"
                      options={attachmentForIDsFiltered}
                      placeholder={
                        attachmentBlock === true
                          ? t("Documents are visible by...")
                          : t("Link / Documents are visible by...")
                      }
                      value={attachmentToIDs}
                      isMulti={true}
                      isSearchable={true}
                      isClearable={true}
                      isDisabled={loading}
                      onChange={(e: any) => {
                        setAttachmentToIDs(e);
                      }}
                    />
                  )}
                </Col>

                <Col xl={8} lg={8} md={8} sm={12} className="mb-1">
                  {moduleData.module_main_id !== 0 &&
                    attachmentBlock === true ? (
                    <div className="ms-auto d-flex gap-2 justify-content-end">
                      <Button
                        color="primary"
                        className="btn-block"
                        type="button"
                        onClick={() => handleUpload()}
                        disabled={loading}
                      >
                        {loading ? (
                          <span>
                            <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                              hourglass_empty
                            </span>
                            {t("Please wait...")}
                          </span>
                        ) : (
                          <span>{t("Upload")}</span>
                        )}
                      </Button>

                      <Button
                        color="outline-danger"
                        className="btn-block"
                        type="button"
                        onClick={() => setAttachmentBlock(false)}
                        disabled={loading}
                      >
                        {t("Cancel")}
                      </Button>
                    </div>
                  ) : moduleData.module_main_id !== 0 && linkBlock === true ? (
                    <div className="ms-auto d-flex gap-2 justify-content-end">
                      <Button
                        color="primary"
                        className="btn-block"
                        type="button"
                        onClick={() => handleUploadLinks()}
                        disabled={loading}
                      >
                        {loading ? (
                          <span>
                            <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                              hourglass_empty
                            </span>
                            {t("Please wait...")}
                          </span>
                        ) : (
                          <span>{t("Submit")}</span>
                        )}
                      </Button>

                      <Button
                        color="outline-danger"
                        className="btn-block"
                        type="button"
                        onClick={() => setLinkBlock(false)}
                        disabled={loading}
                      >
                        {t("Cancel")}
                      </Button>
                    </div>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </>
          {/* ) : null} */}
        </>
      ) : null}
    </React.Fragment>
  );
};

export default DropzoneUpload;
