import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_QUESTIONNAIRE_TABS, GET_QUESTIONNAIRE_TAB_DETAIL, ADD_NEW_QUESTIONNAIRE_TAB, DELETE_QUESTIONNAIRE_TAB, UPDATE_QUESTIONNAIRE_TAB, GET_QUESTIONNAIRE_TAB_LIST } from "./actionTypes"
import {
    getQuestionnaireTabsSuccess,
    getQuestionnaireTabsFail,
    getQuestionnaireTabDetailFail,
    addQuestionnaireTabFail,
    addQuestionnaireTabSuccess,
    updateQuestionnaireTabSuccess,
    updateQuestionnaireTabFail,
    deleteQuestionnaireTabSuccess,
    deleteQuestionnaireTabFail,
    getQuestionnaireTabListSuccess,
    getQuestionnaireTabListFail,
} from "./actions"

import { AnyAction } from 'redux'

import MastersQuestionnaireTabService from "../../../services/masters.questionnaire.tab.service"

function* fetchQuestionnaireTabs({ payload: { query, history } }: AnyAction): any {
    try {
        const response = yield call(MastersQuestionnaireTabService.getQuestionnaireTabDatatableList, query)
        if (response && response.data && response.data.status === 'success') {
            yield put(getQuestionnaireTabsSuccess(response.data))
        } else if (response && response.status === 'error') {
            yield put(getQuestionnaireTabsFail(response))
        } else {
            yield put(getQuestionnaireTabsFail(response))
        }
    } catch (error) {
        yield put(getQuestionnaireTabsFail(error))
    }
}

function* fetchQuestionnaireTabList(): any {
    try {
        let response: any = yield call(MastersQuestionnaireTabService.getQuestionnaireTabList)
        yield put(getQuestionnaireTabListSuccess(response.data))
    } catch (error) {
        yield put(getQuestionnaireTabListFail(error))
    }
}



function* fetchQuestionnaireTabDetail({ statusId }: AnyAction) {
    try {
        //  const response = yield call(getQuestionnaireTabsDetails, companyId)
        // yield put(getQuestionnaireTabDetailSuccess(response))
    } catch (error) {
        yield put(getQuestionnaireTabDetailFail(error))
    }
}

function* onUpdateQuestionnaireTab({ payload: { statusdata, history } }: AnyAction): any {
    try {
        const response = yield call(MastersQuestionnaireTabService.updateQuestionnaireTab, statusdata)
        if (response && response.data && response.data.status === 'success') {
            yield put(updateQuestionnaireTabSuccess(response.data))
        } else if (response && response.status === 'error') {
            yield put(updateQuestionnaireTabFail(response))
        } else {
            yield put(updateQuestionnaireTabFail(response))
        }
    } catch (error) {
        yield put(updateQuestionnaireTabFail(error))
    }
}

function* onDeleteQuestionnaireTab({ payload: { statusdata, history } }: AnyAction): any {
    try {
        const response = yield call(MastersQuestionnaireTabService.deleteQuestionnaireTab, statusdata)
        if (response && response.data && response.data.status === 'success') {
            yield put(deleteQuestionnaireTabSuccess(response.data))
        } else if (response && response.status === 'error') {
            yield put(deleteQuestionnaireTabFail(response))
        } else {
            yield put(deleteQuestionnaireTabFail(response))
        }

    } catch (error) {
        yield put(deleteQuestionnaireTabFail(error))
    }
}

function* onAddNewQuestionnaireTab({ payload: { statusdata, history } }: AnyAction): any {
    try {
        const response = yield call(MastersQuestionnaireTabService.createQuestionnaireTab, statusdata)
        if (response && response.data && response.data.status === 'success') {
            yield put(addQuestionnaireTabSuccess(response.data))
        } else if (response && response.status === 'error') {
            yield put(addQuestionnaireTabFail(response))
        } else {
            yield put(addQuestionnaireTabFail(response))
        }
    } catch (error) {
        yield put(addQuestionnaireTabFail(error))
    }
}

function* companysSaga(): any {
    yield takeEvery(GET_QUESTIONNAIRE_TABS, fetchQuestionnaireTabs)
    yield takeEvery(GET_QUESTIONNAIRE_TAB_LIST, fetchQuestionnaireTabList)
    yield takeEvery(GET_QUESTIONNAIRE_TAB_DETAIL, fetchQuestionnaireTabDetail)
    yield takeEvery(ADD_NEW_QUESTIONNAIRE_TAB, onAddNewQuestionnaireTab)
    yield takeEvery(UPDATE_QUESTIONNAIRE_TAB, onUpdateQuestionnaireTab)
    yield takeEvery(DELETE_QUESTIONNAIRE_TAB, onDeleteQuestionnaireTab)
}

export default companysSaga
