import * as pdfjsLib from 'pdfjs-dist';
import { jsPDF } from "jspdf";
// import JSZip from 'jszip';
// import cheerio from 'cheerio';
import packageJson from '../../../package.json';
import html2canvas from 'html2canvas';

import logo from "../../assets/images/tc-logo.png";
import AlliottLogo from "../../assets/images/alliott-logo.jpg";
import { HeaderFooter } from '../../pages/Masters/Email/data';
import { Col, Row } from 'reactstrap';
const pdfjsVersion = packageJson.dependencies['pdfjs-dist'];

//pdfjsLib.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`;
pdfjsLib.GlobalWorkerOptions.workerSrc =`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.6.172/pdf.worker.min.js`;
export function convertPDFToHTML(pdfData: any) {
    // const uint8Array = new Uint8Array(pdfData as ArrayBuffer);
    // Load the PDF data
    pdfjsLib.getDocument({ data: pdfData }).promise.then(function (pdf:any) {
        const maxPages = pdf.numPages;
        return maxPages;
        console.log(`Max pages: ${maxPages}`);
    });
}

interface TextWithStyle {
    text: string;
    style: {
        fontFamily: string;
        fontSize: number;
        fontWeight: string;
        fontStyle: string;
        textDecoration: string;
        color: string;
    };
}

// export const htmlStringToPdf = async (htmlString :any) => {
//     let iframe:any = document.createElement("iframe");
//     iframe.style.visibility = "hidden";
//     document.body.appendChild(iframe);
//     let iframedoc = iframe.contentDocument || iframe.contentWindow.document;
//     iframedoc.body.innerHTML = htmlString;

//     let canvas = await html2canvas(iframedoc.body, {});

//     // Convert the iframe into a PNG image using canvas.
//     let imgData = canvas.toDataURL("image/png");

//     // Create a PDF document and add the image as a page.
//     const doc = new jsPDF({
//       format: "a4",
//       unit: "mm",
//     });
//     doc.addImage(imgData, "PNG", 0, 0, 210, 297);

//     // Get the file as blob output.
//     let blob = doc.output("blob");
//     return blob;
//     // Remove the iframe from the document when the file is generated.
//     document.body.removeChild(iframe);
//   };
const Header = () => {
    return (
        <Row>
            <Col><img src={logo} alt='logo' width='120px' /></Col>
            <Col><h1 style={{ color: '#c8222a', fontSize: '28px', textAlign: 'right' }}>HR Tax & IM</h1></Col>
        </Row>
    )
}

const Footer = () => {
    return (
        <Row className='invoiceFooter'>
            <Col className='col-12 strong'><p>SRL Tax Consult HR Tax & International Mobility BV</p></Col>
            <Col className='col-9'>
                <p>Avenue du Dirigeable 8<br />1170 Brussels</p>
            </Col>
            <Col className='col-3 tel-no'>Tel. +32 2 675 50 05</Col>
            <Col className='col-9'>
                <p>T.V.A/B.T.W. BE 0740.763.660
                    <br /><br />Member of Alliott Group, a Worldwide Alliance of Independent Accounting, Law and Consulting Firms
                </p>
            </Col>
            <Col className='col-3'><img src={AlliottLogo} alt='logo' width='200px' /></Col>
        </Row>

    )
}

export const  blobToFiles = async (blob: any, fileName: any, fileType: any) => {
    const fileOptions = {
        type: fileType,
        lastModified: new Date().getTime()
    };

    const file = new File([blob], fileName, fileOptions);
    return file;
};

export const htmlStringToPdf = async (sections: any) => {

    // // const htmlElement = document.createElement('div');
    // // htmlElement.innerHTML = htmlString;
    // // let canvasWidths: any = htmlElement.scrollWidth;

    // // // Use html2canvas to convert the HTML content to a canvas
    // // let canvas = await html2canvas(iframedoc.body, {
    // //     width: canvasWidths || 1500

    // // });

    const div = document.createElement('div');
    div.innerHTML = "";
    let iframe = document.createElement("iframe");
    iframe.style.visibility = "hidden";
    iframe.style.width = "900px";
    iframe.style.height = "1200px";
    document.body.appendChild(iframe);
    let imgar=[];
    // const regex = /<div class="page-break"[\s\S]*?(?=<div class="page-break"|<\/div>)/g;
    // const sections = htmlString.split(regex);
    const doc = new jsPDF({
        orientation: "portrait", // or "landscape" for landscape orientation
        unit: "mm",
        format: "a4",
    });
    for (let pageNum = 1; pageNum <= sections.length; pageNum++) {
        const cnt = removeUnwantedElements(sections[pageNum - 1]);
        if (pageNum > 1) {
            doc.addPage();
        }
        const content_center = `<div style="margin:10%;">${cnt}</div>`;
        let iframedoc: any = iframe.contentDocument || iframe.contentWindow?.document;
        iframedoc.body.innerHTML = HeaderFooter[1].header + content_center;
        // Wait for the div to finish rendering
        // await new Promise(resolve => setTimeout(resolve, 100));

        // Use html2canvas to convert the div to a canvas
        const canvas = await html2canvas(iframedoc.body);


        // Determine the dimensions of the canvas and calculate the height and width of the PDF and image
        // let canvasWidth = 1200;
        // let canvasHeight = 842;
        // let pdfWidth, pdfHeight, imgWidth, imgHeight;

        // if (canvasWidth > canvasHeight) {
        //     // Landscape orientation
        //     pdfWidth = 842; // A4 width in points
        //     pdfHeight = pdfWidth * (canvasHeight / canvasWidth);
        //     imgWidth = pdfWidth * 0.4;
        //     imgHeight = imgWidth * (canvasHeight / canvasWidth);
        // } else {
        //     // Portrait orientation
        //     pdfHeight = 842; // A4 height in points
        //     pdfWidth = pdfHeight * (canvasWidth / canvasHeight);
        //     imgHeight = pdfHeight * 0.4;
        //     imgWidth = imgHeight * (canvasWidth / canvasHeight);
        // }

        // Convert the canvas to a PNG image
        let imgData = canvas.toDataURL("image/png");
        imgar.push(imgData);
        // Create a new jsPDF document and add the PNG image as a page

        //doc.addImage(imgData, "PNG", (pdfWidth - imgWidth) / 2, 10, imgWidth, imgHeight);
    }


    // Get the PDF as a blob and return it
    //let blob = doc.output("blob");
    return imgar;


    // Remove the iframe from the document when the file is generated.
    // document.body.removeChild(iframe);
};

export const splitHTMLByPageBreak = async (editorData: any) => {
    if (!editorData) return [];

    // Split the HTML content by the page break divs
    const pageBreakDivStartTag = '<div class="page-break" style="page-break-after:always;">';
    const pageBreakDivEndTag = '</div>';
    const startIndices = [];
    let index = editorData.indexOf(pageBreakDivStartTag);

    while (index !== -1) {
      startIndices.push(index);
      index = editorData.indexOf(pageBreakDivStartTag, index + 1);
    }

    const htmlFragments = [];
    let startIndex = 0;

    for (const startIdx of startIndices) {
      const endIdx = editorData.indexOf(pageBreakDivEndTag, startIdx) + pageBreakDivEndTag.length;
      const fragment = editorData.substring(startIndex, endIdx).trim();
      htmlFragments.push(fragment);
      startIndex = endIdx;
    }

    // Handle the remaining part of the content after the last page break
    const remainingFragment = editorData.substring(startIndex).trim();
    if (remainingFragment !== '') {
      htmlFragments.push(remainingFragment);
    }

    return htmlFragments;
  };

// export const htmlStringToPdf = async (sections:any) => {
//     // const doc = new jsPDF({
//     //   orientation: "portrait",
//     //   unit: "px",
//     //   format: "a4",
//     // });
//     let imgar:any=[];
//     for (let pageNum = 1; pageNum <= sections.length; pageNum++) {
//       const cnt = removeUnwantedElements(sections[pageNum - 1]);
//     //   if (pageNum > 1) {
//     //     doc.addPage();
//     //   }
   
//       // Create a temporary div element to render the content
//       const tempDiv = document.createElement('div');
//       tempDiv.innerHTML = cnt;
  
//       // Wait for the div to finish rendering
//       await new Promise(resolve => setTimeout(resolve, 100));
  
//       // Use html2canvas to convert the div to a canvas
//       const canvas = await html2canvas(tempDiv);
  
//       // Determine the dimensions of the canvas and calculate the height and width of the PDF and image
//       let canvasWidth = canvas.width;
//       let canvasHeight = canvas.height;
//       let pdfWidth, pdfHeight, imgWidth, imgHeight;
  
//       if (canvasWidth > canvasHeight) {
//         // Landscape orientation
//         pdfWidth = 842; // A4 width in points
//         pdfHeight = pdfWidth * (canvasHeight / canvasWidth);
//         imgWidth = pdfWidth * 0.75;
//         imgHeight = imgWidth * (canvasHeight / canvasWidth);
//       } else {
//         // Portrait orientation
//         pdfHeight = 842; // A4 height in points
//         pdfWidth = pdfHeight * (canvasWidth / canvasHeight);
//         imgHeight = pdfHeight * 0.75;
//         imgWidth = imgHeight * (canvasWidth / canvasHeight);
//       }
  
//       // Convert the canvas to a PNG image
//       let imgData = canvas.toDataURL("image/png");
//       imgar.push(imgData)
//       // Create a new jsPDF document and add the PNG image as a page
//       //doc.addImage(imgData, "PNG", (doc.internal.pageSize.getWidth() - pdfWidth) / 2, 10, pdfWidth, pdfHeight);
//     }

//     return imgar;
  
//     // Get the PDF as a blob and return it
//     // let blob = doc.output("blob");
//     // return blob;
//   };


const removeUnwantedElements = (html:any)=> {
    // Create a temporary element to parse the HTML string
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;
  
    // Select and remove the unwanted elements using DOM manipulation methods
    const unwantedElements = tempElement.querySelectorAll('.ck-widget__type-around__button_after, .ck-widget__type-around__fake-caret, .page-break,.ck-widget__type-around,.ck-widget__type-around__button_before');
    unwantedElements.forEach((element) => {
      element.remove();
    });
  
    // Return the cleaned HTML without the unwanted elements
    return tempElement.innerHTML;
  }

export const convertToPdf = async (wordBuffer: any) => {
    try {
        const wordArray = new Uint8Array(wordBuffer);

        // Parse the Word buffer
        // JSZip.loadAsync(wordArray).then((zip) => {
        //     // Extract the Word XML file
        //     const xml = zip.file('word/document.xml').async('string');
        //     xml.then((xmlString) => {
        //         // Convert the XML string to HTML
        //         asBlob(xmlString).then((htmlBlob:any) => {
        //             // Read the HTML blob as a string
        //             const reader = new FileReader();
        //             reader.readAsText(htmlBlob, 'utf-8');
        //             reader.onload = async (event:any) => {
        //                 const htmlString = event.target.result;

        //                 // Create a new PDF document
        //                 const pdfDoc = await PDFDocument.create();

        //                 // Add the HTML content as a new page
        //                 const page = pdfDoc.addPage();
        //                 page.drawHTML(htmlString);

        //                 // Generate the PDF array buffer
        //                 const pdfArrayBuffer = await pdfDoc.save();

        //                 // Do whatever you want with the PDF array buffer here
        //             };
        //         });
        //     });
        // });
    } catch (error) {
        // Handle the error
    }
}


export async function extractTextFromPdfBlob(file: any): Promise<TextWithStyle[]> {
    const pdfDoc = await pdfjsLib.getDocument(file).promise;
    let textWithStyle: TextWithStyle[] = [];
    for (let i = 1; i <= pdfDoc.numPages; i++) {
        const page = await pdfDoc.getPage(i);
        const textContent = await page.getTextContent();
        textContent.items.forEach((item: any) => {
            const item_span: any = {
                text: item.str,
                fontFamily: item.fontName ? item.fontName : "",
                fontSize: item.fontSize ? item.fontSize : "",
                fontWeight: item.fontWeight ? item.fontWeight : "",
                fontStyle: item.fontStyle ? item.fontStyle : "",
                textDecoration: item.textDecoration ? item.textDecoration : "",
                color: (item.color && item.color[0] && item.color[1] && item.color[2]) ? `rgb(${item.color[0]}, ${item.color[1]}, ${item.color[2]})` : "",
            };
            textWithStyle.push(item_span);
        });
    }
    return textWithStyle;
}

// export function readWordFile(arrayBuffer: ArrayBuffer): Promise<string> {
//     return new Promise((resolve, reject) => {
//         const zip = new JSZip();
//         zip.loadAsync(arrayBuffer).then(async (zip: any) => {
//             const content = await zip.file('word/document.xml').async('string');
//             const text: any = extractTextFromXml(content);
//             resolve(text);
//         }).catch((error: any) => {
//             reject(error);
//         });
//     });
// }

export function extractTextFromXml(xml: string): any[] {
    const parser = new DOMParser();
    const doc = parser.parseFromString(xml, 'application/xml');
    const nodes = doc.getElementsByTagName('w:r');
    const runs = [];
    for (let i = 0; i < nodes.length; i++) {
        const run: any = {
            text: '',
            bold: false,
            italic: false,
            underline: false,
            color: '',
            size: '',
            font: ''
        };
        const textNode = nodes[i].getElementsByTagName('w:t')[0];
        if (textNode) {
            run.text = textNode.textContent;
        }
        const propsNode = nodes[i].getElementsByTagName('w:rPr')[0];
        if (propsNode) {
            if (propsNode.getElementsByTagName('w:b').length > 0) {
                run.bold = true;
            }
            if (propsNode.getElementsByTagName('w:i').length > 0) {
                run.italic = true;
            }
            if (propsNode.getElementsByTagName('w:u').length > 0) {
                run.underline = true;
            }
            if (propsNode.getElementsByTagName('w:color').length > 0) {
                run.color = propsNode.getElementsByTagName('w:color')[0].getAttribute('w:val');
            }
            if (propsNode.getElementsByTagName('w:sz').length > 0) {
                run.size = propsNode.getElementsByTagName('w:sz')[0].getAttribute('w:val') + 'pt';
            }
            if (propsNode.getElementsByTagName('w:rFonts').length > 0) {
                run.font = propsNode.getElementsByTagName('w:rFonts')[0].getAttribute('w:ascii');
            }
        }
        runs.push(run);
    }
    return runs;
}


// export function splitHtmlContentToPages(htmlContent: any, charCount: any) {
//     const pages = []; // array of pages to render

//     // parse the HTML content and fix broken tags
//     const $ = cheerio.load(htmlContent);
//     $('*').each((index: any, element: any) => {
//         const html = $(element).html();
//         $(element).replaceWith(`<div>${html}</div>`);
//     });
//     const fixedHtmlContent = $.html();

//     // split the fixed HTML content into pages
//     let startIndex = 0;
//     let endIndex = charCount;
//     while (startIndex < fixedHtmlContent.length) {
//         pages.push(fixedHtmlContent.slice(startIndex, endIndex));
//         startIndex = endIndex;
//         endIndex += charCount;
//     }

//     return pages;
// }


export function htmlToUint8Array(htmlContent: any) {
    // Encode the HTML string as a Uint8Array
    const encoder = new TextEncoder();
    const htmlUint8Array = encoder.encode(htmlContent);

    // Convert the Uint8Array to a Blob
    const blob = new Blob([htmlUint8Array], { type: 'text/html' });

    // Convert the Blob to an ArrayBuffer
    const reader = new FileReader();
    reader.readAsArrayBuffer(blob);
    reader.onloadend = () => {
        const arrayBuffer = reader.result;
        return arrayBuffer;
    }
}



// export function extractTextFromPdfBlob(file: any): Promise<TextWithStyle[]> {

//     return new Promise<string>((resolve, reject) => {
//         pdfjsLib.getDocument(file).promise.then(function (pdf) {
//             const maxPages = pdf.numPages;
//             const textContent: string[] = [];
//             for (let pageNum = 1; pageNum <= maxPages; pageNum++) {
//                 pdf.getPage(pageNum).then(function (page) {
//                     page.getTextContent().then(function (content) {
//                         const pageText = content.items.map((item: any) => item.str).join('');
//                         textContent.push(pageText);
//                         if (textContent.length === maxPages) {
//                             resolve(textContent.join('\n'));
//                         }
//                     });
//                 });
//             }
//         }, reject);
//     });
// }
