export const GET_TIME_SHEET = "GET_TIME_SHEET"
export const GET_TIME_SHEET_SUCCESS = "GET_TIME_SHEETS_SUCCESS"
export const GET_TIME_SHEET_ERROR = "GET_TIME_SHEETS_ERROR"

export const GET_TIME_SHEETS_GROUP_LIST = "GET_TIME_SHEETS_GROUP_LIST"
export const GET_TIME_SHEETS_GROUP_LIST_SUCCESS = "GET_TIME_SHEETS_GROUP_LIST_SUCCESS"
export const GET_TIME_SHEETS_GROUP_LIST_ERROR = "GET_TIME_SHEETS_GROUP_LIST_ERROR"

export const GET_TIME_SHEETS_LIST = "GET_TIME_SHEETS_LIST"
export const GET_TIME_SHEETS_LIST_SUCCESS = "GET_TIME_SHEETS_LIST_SUCCESS"
export const GET_TIME_SHEETS_LIST_ERROR = "GET_TIME_SHEETS_LIST_ERROR"
  
export const CREATE_TIME_SHEET = "CREATE_TIME_SHEET"
export const CREATE_TIME_SHEET_SUCCESS = "CREATE_TIME_SHEET_SUCCESS"
export const CREATE_TIME_SHEET_ERROR = "CREATE_TIME_SHEET_ERROR"

export const GET_TIME_SHEET_FORM = "GET_TIME_SHEET_FORM"
export const GET_TIME_SHEET_FORM_SUCCESS = "GET_TIME_SHEET_FORM_SUCCESS"
export const GET_TIME_SHEET_FORM_ERROR = "GET_TIME_SHEET_FORM_ERROR"

export const CREATE_TIME_SHEET_FORM = "CREATE_TIME_SHEET_FORM"
export const CREATE_TIME_SHEET_FORM_SUCCESS = "CREATE_TIME_SHEET_FORM_SUCCESS"
export const CREATE_TIME_SHEET_FORM_ERROR = "CREATE_TIME_SHEET_FORM_ERROR"

export const UPDATE_TIME_SHEET = "UPDATE_TIME_SHEET"
export const UPDATE_TIME_SHEET_SUCCESS = "UPDATE_TIME_SHEET_SUCCESS"
export const UPDATE_TIME_SHEET_ERROR = "UPDATE_TIME_SHEET_ERROR"

export const DELETE_TIME_SHEET = "DELETE_TIME_SHEET"
export const DELETE_TIME_SHEET_SUCCESS = "DELETE_TIME_SHEET_SUCCESS"
export const DELETE_TIME_SHEET_ERROR = "DELETE_TIME_SHEET_ERROR"