export const GET_SUB_TASK = "GET_SUB_TASK"
export const GET_SUB_TASK_SUCCESS = "GET_SUB_TASKS_SUCCESS"
export const GET_SUB_TASK_ERROR = "GET_SUB_TASKS_ERROR"

export const GET_SUB_TASKS_LIST = "GET_SUB_TASKS_LIST"
export const GET_SUB_TASKS_LIST_SUCCESS = "GET_SUB_TASKS_LIST_SUCCESS"
export const GET_SUB_TASKS_LIST_ERROR = "GET_SUB_TASKS_LIST_ERROR"
  
export const CREATE_SUB_TASK = "CREATE_SUB_TASK"
export const CREATE_SUB_TASK_SUCCESS = "CREATE_SUB_TASK_SUCCESS"
export const CREATE_SUB_TASK_ERROR = "CREATE_SUB_TASK_ERROR"

export const UPDATE_SUB_TASK = "UPDATE_SUB_TASK"
export const UPDATE_SUB_TASK_SUCCESS = "UPDATE_SUB_TASK_SUCCESS"
export const UPDATE_SUB_TASK_ERROR = "UPDATE_SUB_TASK_ERROR"

export const DELETE_SUB_TASK = "DELETE_SUB_TASK"
export const DELETE_SUB_TASK_SUCCESS = "DELETE_SUB_TASK_SUCCESS"
export const DELETE_SUB_TASK_ERROR = "DELETE_SUB_TASK_ERROR"