import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL: any = process.env.REACT_APP_SERVER_URL;

class Service {

    async create(data: any) {
        const response = await axios
            .post(
                SERVER_URL + url.DOCUMENT_TEMPLATE.CREATE,
                data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async update(data: any) {
        const response = await axios
            .post(
                SERVER_URL + url.DOCUMENT_TEMPLATE.UPDATE,
                data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async delete(id: any) {
        const response = await axios
            .get(
                SERVER_URL + url.DOCUMENT_TEMPLATE.DELETE + '/' + id,
                {
                    headers: getAxiosConfigWithToken()
                }
            );
        return response.data;
    }


    async download(id: any) {
        const response = await axios
            .get(
                SERVER_URL + url.DOCUMENT_TEMPLATE.DOWNLOAD + '/' + id,
                {
                    headers: {
                        ...getAxiosConfigWithToken(),
                        "Content-Type": "application/pdf",
                        "Access-Control-Allow-Origin": "*",
                    },
                    responseType: 'blob',
                }
            );
        return response;
    }

    async getAll(data: any) {
        const response = await axios
            .post(SERVER_URL + url.DOCUMENT_TEMPLATE.GET_ALL,
                data,
                {
                    headers: getAxiosConfigWithToken(),
                }
            ).then(function (response: any) {
                return response;
            })
            .catch(function (error: any) {
                return error;
            });
        return response;
    }

    async getAllDocuments(data: any) {
        const response = await axios
            .post(SERVER_URL + url.DOCUMENT_SIGNATURE.GET_ALL,
                data,
                {
                    headers: getAxiosConfigWithToken(),
                }
            ).then(function (response: any) {
                return response;
            })
            .catch(function (error: any) {
                return error;
            });
        return response;
    }

    async getList() {
        const response = await axios
            .get(SERVER_URL + url.DOCUMENT_TEMPLATE.GET_LIST,
                {
                    headers: getAxiosConfigWithToken(),
                }
            ).then(function (response: any) {
                return response;
            })
            .catch(function (error: any) {
                return error;
            });
        return response;
    }

    async get(id: number, doc_id: string) {
        const response = await axios
            .get(
                SERVER_URL + url.DOCUMENT_TEMPLATE.GET + '/' + id + '/' + doc_id,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }
    
    async getTemplate(id: number, doc_id: string) {
        const response = await axios
            .get(
                SERVER_URL + url.DOCUMENT_TEMPLATE.GET_TEMPLATE + '/' + id + '/' + doc_id,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }
    

    async getDocument(id: number, doc_id: string) {
        const response = await axios
            .get(
                SERVER_URL + url.DOCUMENT_TEMPLATE.GET_DOC + '/' + id + '/' + doc_id,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }



    async changeStatusRefuse(data: any) {
        const response = await axios
            .post(SERVER_URL + url.DOCUMENT_SIGNATURE.CHANGE_STATUS,
                data,
                {
                    headers: getAxiosConfigWithToken(),
                }
            ).then(function (response: any) {
                return response;
            })
            .catch(function (error: any) {
                return error;
            });
        return response;
    }
    async searchTemplate(query: any) {
        const response = await axios
            .post(SERVER_URL + url.DOCUMENT_TEMPLATE.SEARCH_TEMPLATE, query,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async sendDocument(data: any) {
        const response = await axios
            .post(SERVER_URL + url.DOCUMENT_SIGNATURE.SEND, data,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async deleteDocument(id: any, doc_id: any) {
        const response = await axios
            .get(
                SERVER_URL + url.DOCUMENT_SIGNATURE.DELETE + '/' + id + '/' + doc_id,
                {
                    headers: getAxiosConfigWithToken()
                }
            );
        return response.data;
    }


    async sendNotificationEmail(data: any) {
        const response = await axios
            .post(SERVER_URL + url.DOCUMENT_SIGNATURE.SEND_NOTIFICATION, data,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }


    async getContents(id: any, doc_id: any) {
        const response = await axios
            .get(
                SERVER_URL + url.DOCUMENT_TEMPLATE.GET_CONTENT + '/' + id + '/' + doc_id,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async convertTopdf(data: any) {
        const response = await axios
            .post(
                SERVER_URL + url.DOCUMENT_TEMPLATE.CONVERT_TO_PDF, data,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });

        return response.data;
    }

}

export default new Service();