import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class DisputesService {

    async get(id: number) {
        const response = await axios
            .get(
                SERVER_URL + url.DISPUTE.GET + '/' + id,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async create(data: any) {
        const response = await axios
            .post(
                SERVER_URL + url.DISPUTE.CREATE,
                data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async update(data: any) {
        const response = await axios
            .put(
                SERVER_URL + url.DISPUTE.UPDATE,
                data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async accept(id: any) {
        const response = await axios
            .put(
                SERVER_URL + url.DISPUTE.ACCEPT + '/' + id,
                { id: id },
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async reject(id: any, data: any) {
        const response = await axios
            .put(
                SERVER_URL + url.DISPUTE.REJECT + '/' + id,
                { id: id, reason_to_reject: data?.reason_to_reject },
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async creditNote(id: any, cnID: any) {
        const response = await axios
            .put(
                SERVER_URL + url.DISPUTE.CREDIT_NOTE + '/' + id,
                { id: id, credit_note_id: cnID },
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async getAll(invoice_id: any) {
        const response = await axios
            .get(SERVER_URL + url.DISPUTE.GET_ALL + '/' + invoice_id,
                {
                    headers: getAxiosConfigWithToken(),
                }
            ).then(function (response: any) {
                return response;
            })
            .catch(function (error: any) {
                return error;
            });
        return response;
    }

    async delete(id: any) {
        const response = await axios
            .delete(
                SERVER_URL + url.DISPUTE.DELETE + id,
                {
                    headers: getAxiosConfigWithToken(),
                }
            );

        return response.data;
    }

    async validateCompany(data: any) {
        const response = await axios
            .post(
                SERVER_URL + url.DISPUTE.VALIDATE,
                data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async getDisputeCount() {
        const response = await axios
            .get(SERVER_URL + url.DISPUTE.GET_DISPUTE_COUNT,
                {
                    headers: getAxiosConfigWithToken(),
                }
            ).then(function (response: any) {
                return response;
            })
            .catch(function (error: any) {
                return error;
            });
        return response;
    }

}

export default new DisputesService();