import React, { useState, useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Form,
  Label,
  Input as InputNew,
  // Button,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Button from "../../../components/Button/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import labelPlugin from "flatpickr/dist/plugins/labelPlugin/labelPlugin";
import { toast } from "react-toastify";

// Static Data
import { languageOptions, vatReasonOptions, YesNoMaster } from "../../../common/staticdata";
import {
  archiveSetDisabled,
  calculateVatData,
  filterCountryByLanguage,
  getDependentSelectOptions,
  getSelectOptions,
  handleAddress,
  listingStatusOptions,
  statusOptions,
  datePickerI18,
  handleAutoSave
} from "../../../helpers/functions_helper";

// AvatarEditor
import AvatarEditor from "../../../components/Common/AvatarEditor";

// Service
import commonService from "../../../services/common.service";

// Import Breadcrumb
import Breadcrumbs from "../../../components/Layout/Breadcrumb";

import {
  filteredByFunction,
  getInitials,
  getOptionValueById,
  getUserProfile,
} from "../../../helpers/functions_helper";

import { dateFormat } from "../../../utils/formats";
import Loader from "../../../components/Layout/Loader";

import ScrollUp from "../../../components/ScrollUpDown/ScrollUp";
import ScrollDown from "../../../components/ScrollUpDown/ScrollDown";
import { useTranslation } from "react-i18next";

import Autocomplete from "react-google-autocomplete";

import NavItems from "./NavItems";
import Tooltip from "../../../components/Tooltip/Tooltip";
import mastersCompanyService from "../../../services/masters.company.service";

//Mail Action
import { setDraftEmailData } from "../../../store/mails/actions";
import { MaterialIcon } from "../../Email/components/styles";
import { draftmailProps } from "../../Email/components/type";
import Drawer from "../../../components/Drawer";
import EditUser from "../../Users/EditUser";
import NewUser from "../../Users/NewUser";
import { CommonAction } from "../../../store/actions";
import EmailButton from "../../Email/components/EmailButton";
import BanksData from "../../Users/Taxes/BanksData";
import Input from "../../../components/Form/Input";
import SelectNew from "react-select";
import Select from "../../../components/Form/Select/Select";
import Flatpickr from "../../../components/Form/Flatpickr";

import { useCallbackPrompt } from '../../../hooks/useCallbackPrompt'
import Alert from "../../../components/Common/Alert";
import NavigationAlert from "../../../components/Common/NavigationAlert";

const EditCompany = (props: any) => {
  const { t } = useTranslation();
  document.title = `${t("Update Company")} | ${process.env.REACT_APP_NAME}`;
  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "/masters/company",
      label: t("Company"),
      active: true,
    },
    {
      url: "#",
      label: t("Update Company"),
      active: true,
    },
  ];
  const phoneRegExp = /^(?:\+?\d{1}[ -]?[\d -][\d -]+)$/;
  const validationSchema = Yup.object().shape({
    custome_code: Yup.string()
      .required("Custome code field is required.")
      .min(3, "Custome code must be at least 3 characters.")
      .max(15, "Custome code may not be greater than 15 characters."),
    company_name: Yup.string()
      .required("Client name field is required.")
      .min(2, "Client name must be at least 2 characters.")
      .max(200, "Client name may not be greater than 200 characters."),
    email_address: Yup.string()
      .max(100, "Email may not be greater than 100 characters.")
      .email("Email must be a valid email address.")
      .nullable(),
    legal_entity: Yup.string()
      .max(100, "Legal entity not be greater than 100 characters.")
      .nullable(),
    phone_number_1: Yup.string()
      // .required("The phone number field is required.")
      .matches(phoneRegExp, "Contact number is not valid.")
      .min(8, "Contact number must be at least 8 digits.")
      .max(15, "Contact number field accept only 15 digits.")
      .nullable(),
    phone_number_2: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid.")
      .min(8, "Contact number (Alternative) must be at least 8 digits.")
      .max(15, "Contact number (Alternative) field accept only 15 digits.")
      .when(["phone_number_1"], {
        is: (phone_number_1: string) => phone_number_1 != "" && phone_number_1 != null,
        then: Yup.string().notOneOf(
          [Yup.ref("phone_number_1")],
          "Contact number (Alternative) and alternative can not be same."
        ),
      })
      .nullable(),
    file_manager: Yup.string().required("File manager field is required."),
    signing_partner: Yup.string().required("Partner field is required."),
    listing_status_id: Yup.string().required("Listing status field is required."),

    // company_address: Yup.string()
    //   .required("The company address field is required.")
    //   .max(100, "The company address not be greater than 100 characters."),
    // office_number: Yup.string()
    //   .required("The office number is required.")
    //   .max(10, "The office number may not be greater than 10 characters."),
    // box: Yup.string()
    //   .max(10, "The box number may not be greater than 10 characters.")
    //   .nullable(),
    // postal_code: Yup.string()
    //   .required("The postal code field is required.")
    //   .max(30, "The postal code may not be greater than 30 characters."),
    // city: Yup.string()
    //   .required("The city field is required.")
    //   .max(100, "The city may not be greater than 100 characters."),
    // country: Yup.string().required("The country field is required."),

    // inv_company_address: Yup.string()
    //   .required("The company address field is required.")
    //   .max(100, "The company address not be greater than 100 characters."),
    // inv_office_number: Yup.string()
    //   .required("The office number is required.")
    //   .max(10, "The office number may not be greater than 10 characters."),
    // inv_box: Yup.string()
    //   .max(10, "The box number may not be greater than 10 characters.")
    //   .nullable(),
    // inv_postal_code: Yup.string()
    //   .required("The postal code field is required.")
    //   .max(30, "The postal code may not be greater than 30 characters."),
    // inv_city: Yup.string()
    //   .required("The city field is required.")
    //   .max(100, "The city may not be greater than 100 characters."),
    // inv_country: Yup.string().required("The country field is required."),

    contact_person: Yup.string().required(t("The contact person (Company) field is required.")),
    invoice_contact_person: Yup.string().required(t("The contact person (Invoice) field is required.")),
    hr_contact_person: Yup.string().required(t("The contact person (HR) field is required.")),
    invoice_payable_period: Yup.number()
      .required("Payable period (Invoice) field is required.")
      .typeError("You must specify number of days")
      .integer()
      .min(1, "Payable period (Invoice) should be greater than 0.")
      .max(365, "Payable period (Invoice) field accept only up to 365 days."),

    short_description_activities: Yup.string()
      .max(500, "Short description of activities may not be greater than 500 characters.")
      .nullable(),
    stuation_of_the_belgian_entity: Yup.string()
      .max(500, "Situation of the Belgian entity within the international group may not be greater than 500 characters.")
      .nullable(),
    additional_info: Yup.string()
      .max(500, "Additional information on the company may not be greater than 500 characters.")
      .nullable(),

    number_Of_employees: Yup.number()
      .typeError("Number of employees must be a numeric.")
      .integer()
      .nullable(),
    TVW_Nr: Yup.string()
      .min(4, t("The TVA/BTW must be at least 4 characters."))
      .max(18, t("The TVA/BTW number may not be greater than 18 characters."))
      .nullable(),
  });

  const userProfile = getUserProfile("all-company");
  const toastId = React.useRef(null) as any;
  const questionnairetoastId = React.useRef(null) as any;
  const dispatch = useDispatch();
  let { id, company_id, type } = useParams();
  const { isWindow } = props;
  if (!id || !company_id) {
    id = props?.id;
    company_id = props?.company_id;
    type = props.type;
  }
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [openUser, setOpenUser] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any>([]);
  const [userCreateType, setUserCreateType] = useState<number>(0);
  const [userCreated, setUserCreated] = useState<boolean>(false);
  const [userCreatedData, setUserCreatedData] = useState<any>([]);
  const [openUserDrawer, setOpenUserDrawer] = useState<boolean>(false);
  const [redirectCode, setRedirectCode] = useState("");
  const [dataNotFound, setDataNotFound] = useState(false);
  const [bankData, setBankData] = useState<any>([]);

  const localFormData = handleAutoSave("GET");
  const [autoSave, setAutoSave] = useState(false);
  const [autoSaveAlert, setAutoSaveAlert] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(autoSave);

  const [initialData, setInitialData] = useState({
    id: "",
    company_id: "",
    code: "",
    custome_code: "",
    company_logo: "",
    company_name: "",
    color_code: "#000000",
    company_category: "",
    language: "1",
    email_address: "",
    legal_entity: "",
    phone_number_1: "",
    phone_number_2: "",
    fax_nr_company: "",

    preparer: "",
    reviewer: "",
    file_manager: "",
    signing_partner: "",
    assistant_administrative: "",

    category: "",
    account: [] as any[],
    status: "1",
    listing_status_id: "2",

    company_address: "",
    office_number: "",
    box: "",
    postal_code: "",
    city: "",
    region: "",
    country: "21",

    inv_like_company_address: 0,
    inv_company_address: "",
    inv_office_number: "",
    inv_box: "",
    inv_postal_code: "",
    inv_city: "",
    inv_region: "",
    inv_country: "21",

    contact_person: "",
    contact_function: "",
    invoice_contact_person: "",
    invoice_payable_period: 90,
    hr_contact_person: "",
    gen_contact_person: "",

    bank_data: [],
    bank_name_prime: "",
    bank_address_prime: "",
    bank_address_number: "",
    bank_address_box: "",
    bank_address_postal_code: "",
    bank_address_commune: "",
    bank_address_city: "",
    bank_address_country: "",
    IBAN_Nr_prime: "",
    bic: "",

    short_description_activities: "",
    stuation_of_the_belgian_entity: "",
    additional_info: "",
    website: "",
    external_web_link: "",

    number_Of_employees: "",
    TVW_Nr: "",
    vatcat: "",
    vat_percentage: 21,
    vat_code: "211400",
    to_apply_vat: 1,
    reason_for_vat: 1,
    company_profile: "",
    primary_industry_type: "",
    company_registration_id: "",
    is_archive: 0,
    start_date: "",
    end_date: "",
    process_trigger: type == "process" ? 1 : 0,

    created_by: "",
    created_by_first_name: "",
    created_by_last_name: "",
    created_at: "",
    updated_by_first_name: "",
    updated_by_last_name: "",
    updated_at: "",

    submit_btn_code: "update_add_new",
  });
  const [processModal, setProcessModal] = useState(false);
  const [questionnaireStatus, setQuestionnaireStatus] = useState<any>(null);

  const commentAttachmentIDs = [
    {
      value: initialData.preparer,
      label: t("Preparer"),
    },
    {
      value: initialData.reviewer,
      label: t("Reviewer"),
    },
    {
      value: initialData.file_manager,
      label: t("File manager"),
    },
    {
      value: initialData.signing_partner,
      label: t("Partner"),
      isHidden: userProfile.IS_INTERNAL ? false : true,
    },
    {
      value: initialData.assistant_administrative,
      label: t("Assistant administrative"),
      isHidden: userProfile.IS_INTERNAL ? false : true,
    },
    {
      value: initialData.contact_person,
      label: t("Contact person (Company)"),
    },
    {
      value: initialData.invoice_contact_person,
      label: t("Contact person (Invoice)"),
    },
    {
      value: initialData.hr_contact_person,
      label: t("Contact person (HR)"),
    },
    {
      value: initialData.gen_contact_person,
      label: t("Contact person (General)"),
    },
  ];

  const [disableInvCompanyAddress, setDisableInvCompanyAddress] = useState(false);

  const handleInvLikeCompanyAddress = () => {
    if (initialData.inv_like_company_address === 1) {
      setInitialData({
        ...initialData,
        inv_like_company_address: 0,
      });
      setDisableInvCompanyAddress(false);
    } else {
      setInitialData({
        ...initialData,
        inv_like_company_address: 1,
        inv_company_address: initialData.company_address,
        inv_office_number: initialData.office_number,
        inv_box: initialData.box,
        inv_postal_code: initialData.postal_code,
        inv_city: initialData.city,
        inv_region: initialData.region,
        inv_country: initialData.country,
      });
      setDisableInvCompanyAddress(true);
    }
  };

  useEffect(() => {
    if (initialData.inv_like_company_address === 1) {
      setInitialData({
        ...initialData,
        inv_company_address: initialData.company_address,
        inv_office_number: initialData.office_number,
        inv_box: initialData.box,
        inv_postal_code: initialData.postal_code,
        inv_city: initialData.city,
        inv_region: initialData.region,
        inv_country: initialData.country,
      });
    }
  }, [
    initialData.inv_like_company_address,
    initialData.company_address,
    initialData.office_number,
    initialData.box,
    initialData.postal_code,
    initialData.city,
    initialData.region,
    initialData.country,
  ]);

  const [listingStatus, setListingStatus] = useState<any>([]);
  const [statusList, setStatusList] = useState<any>([]);
  const [yesNoList, setYesNoList] = useState<any>([]);
  const [reasonsList, setReasonsList] = useState<any>([]);
  const [languageList, setLanguageList] = useState<any>([]);
  useEffect(() => {
    if (listingStatusOptions) {
      const listOptions = listingStatusOptions?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setListingStatus(listOptions);
    }
    if (languageOptions) {
      const options = languageOptions?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setLanguageList(options);
    }
    if (statusOptions) {
      const Options = statusOptions?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setStatusList(Options);
    }

    if (YesNoMaster) {
      const Options = YesNoMaster?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setYesNoList(Options);
    }

    if (vatReasonOptions) {
      const Options = vatReasonOptions?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setReasonsList(Options);
    }

  }, [localStorage.getItem("I18N_LANGUAGE")]);

  useEffect(() => {
    if (initialData.bank_data) {
      let bank_data: any = [];
      if (initialData?.bank_data?.length === 1) {
        bank_data = initialData?.bank_data[0];
      } else {
        bank_data = initialData?.bank_data?.find((x: any) => x?.is_prime === true);

        if (!bank_data) {
          bank_data = initialData?.bank_data[initialData?.bank_data?.length - 1];
        }
      }

      setInitialData({
        ...initialData,
        bank_name_prime: bank_data?.bank_name_prime,
        bank_address_prime: bank_data?.bank_address_prime,
        bank_address_number: bank_data?.bank_address_number,
        bank_address_box: bank_data?.bank_address_box,
        bank_address_postal_code: bank_data?.bank_address_postal_code,
        bank_address_commune: bank_data?.bank_address_commune,
        bank_address_city: bank_data?.bank_address_city,
        bank_address_country: bank_data?.bank_address_country,
        IBAN_Nr_prime: bank_data?.iban_number_prime,
        bic: bank_data?.bic_number_prime,
      });
    }
  }, [initialData.bank_data]);

  const handleRedirect = (isCancel = false) => {
    setAutoSave(false);
    handleAutoSave("CLEAR");

    if (isWindow) {
      return false;
    }
    if (isCancel === true) {
      return navigate("/masters/company");
    }

    if (redirectCode === "update_add_new") {
      return navigate("/masters/company/new-company?redirect=true");
    } else if (redirectCode === "update_close") {
      return navigate("/masters/company?redirect=true");
    } else if (redirectCode === "update") {
      // return navigate("/masters/company/company-details/" + insertId + "/" + insertSubId + "?redirect=true");
      window.scrollTo(0, 0);
    } else {
      return navigate("/masters/company?redirect=true");
    }
  };

  useEffect(() => {
    if (redirectCode !== "") {
      handleRedirect();
    }
  }, [redirectCode]);

  const handleChange = (fieldName: string, fieldValue: any) => {
    setInitialData({ ...initialData, [fieldName]: fieldValue });
    setAutoSave(true);
  };

  const handleAddressChange = async (data: any) => {
    const addressData = await handleAddress(data);
    setInitialData((prevData: any) => ({
      ...prevData,
      company_address: addressData?.address,
      office_number: addressData?.address_number.substring(0, 10),
      box: addressData?.box.substring(0, 10),
      postal_code: addressData?.postal_code,
      city: addressData?.commune,
      region: addressData?.city,
      country: addressData?.country,
    }));
  };
  const handleInvAddressChange = async (data: any) => {
    const addressData = await handleAddress(data);
    setInitialData((prevData: any) => ({
      ...prevData,
      inv_company_address: addressData?.address,
      inv_office_number: addressData?.address_number.substring(0, 10),
      inv_box: addressData?.box.substring(0, 10),
      inv_postal_code: addressData?.postal_code,
      inv_city: addressData?.commune,
      inv_region: addressData?.city,
      inv_country: addressData?.country,
    }));
  };

  const handleAddressChangeBank = async (data: any) => {
    const addressData = await handleAddress(data);
    setInitialData((prevData: any) => ({
      ...prevData,
      bank_address_prime: addressData?.address,
      bank_address_number: addressData?.address_number.substring(0, 10),
      bank_address_box: addressData?.box.substring(0, 10),
      bank_address_postal_code: addressData?.postal_code,
      bank_address_commune: addressData?.commune,
      bank_address_city: addressData?.city,
      bank_address_country: addressData?.country,
    }));
  };

  const handleArchive = async () => {
    toastId.current = toast(t("Updating archive status..."), {
      autoClose: false,
      isLoading: true,
    });

    const res = (await commonService.handleArchive({
      id: initialData.id,
      table_name: "tbl_master_company",
      field_name: "is_archive",
      is_archive: initialData.is_archive,
    })) as any;

    if (res.data.status === "success") {
      handleChange("is_archive", initialData.is_archive === 1 ? 0 : 1);

      dispatch(CommonAction.getCompanyOptionsListSuccess(""));
      dispatch(CommonAction.getCompanyOptionsListSuccess_New(""));

      toast.update(toastId.current, {
        type: toast.TYPE.SUCCESS,
        render: res.data.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    } else {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: res.data.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
  };

  const onProcessClick = () => {
    update({ ...initialData, process_trigger: 0 });
  };

  const update = async (values: companyPropsType) => {
    try {
      setLoading(true);
      toastId.current = toast(t("Updating company..."), {
        autoClose: false,
        isLoading: true,
      });

      const res = (await mastersCompanyService.updateCompany(values)) as any;
      if (res.data.status === "success") {
        if (res.data.questionnaire_status === "success") {
          setQuestionnaireStatus(res.data.questionnaire_status);
        }
        setRedirectCode(res.data.data.submit_btn_code);

        dispatch(CommonAction.getCompanyOptionsListSuccess(""));
        dispatch(CommonAction.getCompanyOptionsListSuccess_New(""));

        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setProcessModal(false);
        setLoading(false);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        if (res.data.data) {
          Object.keys(res.data.data).map((key) => {
            toast(res.data.data[key][0], {
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              delay: 500,
            });
          });
        }
        setLoading(false);
      }
    } catch (err: any) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      if (type == "process" && initialData.process_trigger == 1) {
        setProcessModal(true);
      } else {
        update(values);
      }
    },
  });

  useEffect(() => {
    if (!validation.isSubmitting) return;
    if (Object.keys(validation.errors).length > 0) {
      document
        .getElementById(Object.keys(validation.errors)[0])
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
      document.getElementById(Object.keys(validation.errors)[0])?.focus({ preventScroll: true });
    }
  }, [validation.isSubmitting, validation.errors]);

  // get and set Company API call -------------------------------------------------
  const get = async () => {
    try {
      setLoading(true);
      const res = (await mastersCompanyService.getCompany({
        id,
        company_id,
      })) as any;
      if (res.data.status === "success") {
        updateInitialData(res.data.data[0]);
        setLoading(false);
      } else {
        toast(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 5000,
          delay: 500,
        });
        setLoading(false);
        setDataNotFound(true);
        handleRedirect();
      }
    } catch (err: any) {
      toast(err?.message, {
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        delay: 500,
      });
      setLoading(false);
      handleRedirect();
    }
  };

  useEffect(() => {
    if (localFormData && localFormData?.initialData) {
      updateInitialData(localFormData?.initialData);
      setAutoSaveAlert(true);
    } else {
      get();
    }
  }, []);

  const [oldProfileImage, setOldProfileImage] = useState();

  function updateInitialData(data: any) {
    const updatedInitialData = {
      ...initialData,
      id: data.id,
      company_id: data.company_id,
      code: data.code,
      custome_code: data.custome_code,
      company_logo: data.company_logo,
      company_name: data.company_name,
      color_code: data.color_code == null ? "#000000" : data.color_code,
      company_category: data.company_category == null ? "" : data.company_category,
      language: data.language == null ? 1 : data.language,
      email_address: data.email_address == null ? "" : data.email_address,
      legal_entity: data.legal_entity == null ? "" : data.legal_entity,
      phone_number_1: data.phone_number_1 == null ? "" : data.phone_number_1,
      phone_number_2: data.phone_number_2 == null ? "" : data.phone_number_2,
      fax_nr_company: data.fax_nr_company == null ? "" : data.fax_nr_company,

      preparer: data.preparer == null ? "" : data.preparer,
      reviewer: data.reviewer == null ? "" : data.reviewer,
      file_manager: data.file_manager == null ? "" : data.file_manager,
      signing_partner: data.signing_partner == null ? "" : data.signing_partner,
      assistant_administrative:
        data.assistant_administrative == null ? "" : data.assistant_administrative,

      category: data.category == null ? "" : data.category,
      account: data.account,
      status: data.status == null ? "1" : data.status,
      listing_status_id: data.listing_status_id == null ? "2" : data.listing_status_id,

      company_address: data.company_address == null ? "" : data.company_address,
      office_number: data.office_number == null ? "" : data.office_number,
      box: data.box == null ? "" : data.box,
      postal_code: data.postal_code == null ? "" : data.postal_code,
      city: data.city == null ? "" : data.city,
      region: data.region == null ? "" : data.region,
      country: data.country == null ? "" : data.country,

      inv_like_company_address:
        data.inv_like_company_address == null ? "" : data.inv_like_company_address,
      inv_company_address: data.inv_company_address == null ? "" : data.inv_company_address,
      inv_office_number: data.inv_office_number == null ? "" : data.inv_office_number,
      inv_box: data.inv_box == null ? "" : data.inv_box,
      inv_postal_code: data.inv_postal_code == null ? "" : data.inv_postal_code,
      inv_city: data.inv_city == null ? "" : data.inv_city,
      inv_region: data.inv_region == null ? "" : data.inv_region,
      inv_country: data.inv_country == null ? "" : data.inv_country,

      contact_person: data.contact_person == null ? "" : data.contact_person,
      contact_function: data.contact_function == null ? "" : data.contact_function,
      invoice_contact_person:
        data.invoice_contact_person == null ? "" : data.invoice_contact_person,
      invoice_payable_period:
        data.invoice_payable_period == null ? 90 : data.invoice_payable_period,
      hr_contact_person: data.hr_contact_person == null ? "" : data.hr_contact_person,
      gen_contact_person: data.gen_contact_person == null ? "" : data.gen_contact_person,

      bank_data: (data?.bank_data && data?.bank_data?.length > 0 && JSON.parse(data?.bank_data)) || [],
      bank_name_prime: data.bank_name_prime,
      bank_address_prime: data.bank_address_prime,
      bank_address_number: data.bank_address_number,
      bank_address_box: data.bank_address_box,
      bank_address_postal_code: data.bank_address_postal_code,
      bank_address_commune: data.bank_address_commune,
      bank_address_city: data.bank_address_city,
      bank_address_country: data.bank_address_country,
      IBAN_Nr_prime: data.IBAN_Nr_prime,
      bic: data.bic,

      short_description_activities: data.short_description_activities,
      stuation_of_the_belgian_entity: data.stuation_of_the_belgian_entity,
      additional_info: data.additional_info,
      website: data.website,
      external_web_link: data.external_web_link,

      number_Of_employees: data.number_Of_employees,
      TVW_Nr: data.TVW_Nr,
      vatcat: data.vatcat,
      vat_percentage: data.vat_percentage,
      vat_code: data.vat_code,
      to_apply_vat: data.to_apply_vat,
      reason_for_vat: data.reason_for_vat,

      company_profile: data.company_profile,
      primary_industry_type: data.primary_industry_type,
      company_registration_id: data.company_registration_id,
      is_archive: data.is_archive,
      start_date: data.start_date,
      end_date: data.end_date,
      process_trigger: data.process_trigger,

      created_by: data.created_by,
      created_by_first_name: data.created_by_first_name,
      created_by_last_name: data.created_by_last_name,
      created_at: data.created_at,
      updated_by_first_name: data.updated_by_first_name,
      updated_by_last_name: data.updated_by_last_name,
      updated_at: data.updated_at,

      submit_btn_code: "update_add_new",
    };

    setInitialData(updatedInitialData);

    if (data.company_logo_data) {
      setOldProfileImage(data.company_logo_data);
    }

    if (data.inv_like_company_address === 1) {
      setDisableInvCompanyAddress(true);
    }

    if (data?.bank_data && data?.bank_data?.length > 0) {
      setBankData(JSON.parse(data?.bank_data));
    }

    window.scrollTo(0, 0);
  }

  // Get options list -------------------------------------------------
  const [loadingCountry, setLoadingCountry] = useState(true);
  const [countryOptions, setCountryOptions] = useState<Array<any>>([]);
  useEffect(() => {
    getSelectOptions("tbl_master_country", "name", "", [
      "is_europian_country",
      "name",
      "name_french",
      "name_dutch",
    ]).then(function (options) {
      let countryOptionsList = filterCountryByLanguage(options);
      setCountryOptions(countryOptionsList);
      setLoadingCountry(false);
    });
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  const [accountOptions, setAccountOptions] = useState([]);
  const [vatcatOptions, setVatcatOptions] = useState([]);

  useEffect(() => {
    getSelectOptions("tbl_master_account", "account_name").then(function (options) {
      setAccountOptions(options);
    });
    getSelectOptions("tbl_vat_cat", "vatcat_name").then(function (options) {
      setVatcatOptions(options);
    });
  }, []);

  const [loadingCategory, setLoadingCategory] = useState(true);
  const [categoryOptions, setCategoryOptions] = useState([]);
  useEffect(() => {
    getSelectOptions("tbl_master_company_category", "category_value").then(function (options) {
      setCategoryOptions(options);
      setLoadingCategory(false);
    });
  }, []);

  const [loadingProfile, setLoadingProfile] = useState(true);
  const [profileOptions, setProfileOptions] = useState([]);
  useEffect(() => {
    setLoadingProfile(true);

    let language = "";
    if (parseInt(initialData.language) === 2) {
      language = "_french";
    }
    if (parseInt(initialData.language) === 3) {
      language = "_dutch";
    }
    getSelectOptions("tbl_master_company_profile", "company_profile_value" + language).then(
      function (options) {
        setProfileOptions(options);
        setLoadingProfile(false);
      }
    );
  }, [initialData.language]);

  const [loadingCompanyUser, setLoadingCompanyUser] = useState(true);
  const [companyUserOptions, setCompanyUserOptions] = useState([]);
  useEffect(() => {
    if (id) {
      getDependentSelectOptions("tbl_master_users", "first_name", "company", parseInt(id)).then(
        function (options) {
          setCompanyUserOptions(options);
          setLoadingCompanyUser(false);

          if (userCreated && userCreatedData) {
            switch (userCreateType) {
              case 1:
                // handleChange("contact_person", userCreatedData?.id);

                setInitialData((prevData: any) => ({
                  ...prevData,
                  contact_person: userCreatedData?.id,
                }));
                break;
              case 2:
                // handleChange("invoice_contact_person", userCreatedData?.id);

                setInitialData((prevData: any) => ({
                  ...prevData,
                  invoice_contact_person: userCreatedData?.id,
                }));
                break;
              case 3:
                // handleChange("hr_contact_person", userCreatedData?.id);

                setInitialData((prevData: any) => ({
                  ...prevData,
                  hr_contact_person: userCreatedData?.id,
                }));
                break;
              case 4:
                // handleChange("gen_contact_person", userCreatedData?.id);

                setInitialData((prevData: any) => ({
                  ...prevData,
                  gen_contact_person: userCreatedData?.id,
                }));
                break;
              default:
                break;
            }

            setUserCreated(false);
            setUserCreatedData([]);
          }
        }
      );
    }
  }, [id, userCreated, userCreatedData]);

  let { getUserOptionsList } = useSelector((state: any) => ({
    getUserOptionsList: state.Common.GET_USER_OPTIONS_LIST_SUCCESS,
  }));

  useEffect(() => {
    if (!getUserOptionsList) dispatch(CommonAction.getUserOptionsList());
  }, []);

  const [preparerOptions, setPreparerOptions] = useState<Array<any>>([]);
  const [reviewerOptions, setReviewerOptions] = useState<Array<any>>([]);
  const [fileManagerOptions, setFileManagerOptions] = useState<Array<any>>([]);
  const [partnerOptions, setPartnerOptions] = useState<Array<any>>([]);
  const [assistantAdministrativeOptions, setAssistantAdministrativeOptions] = useState<Array<any>>(
    []
  );

  useEffect(() => {
    if (getUserOptionsList.data) {
      // Preparer
      let preparerData = archiveSetDisabled(getUserOptionsList.data, initialData.preparer) as any;
      setPreparerOptions(preparerData);

      // Reviewer
      let reviewerData = archiveSetDisabled(getUserOptionsList.data, initialData.reviewer) as any;
      setReviewerOptions(reviewerData);

      // File Manager
      let fileManagerData = archiveSetDisabled(
        getUserOptionsList.data,
        initialData.file_manager
      ) as any;
      setFileManagerOptions(fileManagerData);

      // Partners
      let partnerData = archiveSetDisabled(
        filteredByFunction(getUserOptionsList.data, [6]) as any,
        initialData.signing_partner
      );
      setPartnerOptions(partnerData);

      // Assistant Administrative
      let assistantData = archiveSetDisabled(
        filteredByFunction(getUserOptionsList.data, [1]) as any,
        initialData.assistant_administrative
      );
      setAssistantAdministrativeOptions(assistantData);
    }
  }, [
    getUserOptionsList,
    initialData.preparer,
    initialData.reviewer,
    initialData.file_manager,
    initialData.signing_partner,
    initialData.assistant_administrative,
  ]);

  useEffect(() => {
    if (questionnaireStatus == "success") {
      questionnairetoastId.current = toast(t("UBO and AML questionnaires sent successfully"), {
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
  }, [questionnaireStatus]);

  // Profile image ------------------------------------------------------
  const onAvatarChange = (base64Data: string) => {
    if (base64Data) {
      initialData.company_logo = base64Data;
    }
  };
  // Profile image ------------------------------------------------------

  //Validate vat number

  const [validateVat, setValidateVat] = useState<any>({
    valid: null,
    loading: false,
  });
  const handleValidate = async (vatNr: any) => {
    // alert(vatNr);
    setValidateVat((state: any) => ({ ...state, loading: true }));

    const res = await commonService.validateVatNr(vatNr);
    if (res?.data?.status === "success") {
      if (res?.data?.data?.valid) {
        setValidateVat((state: any) => ({ ...state, valid: true }));
      }
    } else {
      setValidateVat((state: any) => ({ ...state, valid: false }));
    }
    setValidateVat((state: any) => ({ ...state, loading: false }));
  };
  useEffect(() => {
    setValidateVat({
      valid: null,
      loading: false,
    });
  }, [initialData.TVW_Nr]);
  //Validate vat number

  useEffect(() => {
    if (partnerOptions?.length > 0 && initialData?.signing_partner) {
      const name =
        partnerOptions?.find((x: any) => x?.value === initialData?.signing_partner)?.label || " ";
      // handleChange("category", getInitials(name, false));

      setInitialData((prevData: any) => ({
        ...prevData,
        category: getInitials(name, false),
      }));
    }
  }, [initialData?.signing_partner]);

  //Vat rate calculation
  useEffect(() => {
    if (countryOptions?.length > 0 && initialData?.inv_country) {
      const isEuropean = countryOptions?.find(
        (x: any) => x?.value == initialData?.inv_country
      )?.is_europian_country;
      const vatData = calculateVatData(initialData?.inv_country, isEuropean, initialData.TVW_Nr);
      if (validateVat.valid !== false)
        setInitialData((intData: any) => ({
          ...intData,
          ...vatData,
        }));
    }
  }, [initialData.inv_country, validateVat.valid, countryOptions?.length]);

  //Email compose
  const composeMail = () => {
    const user = {
      label: `${initialData?.company_name}`,
      value: initialData?.email_address,
    };
    const attachment_filter = {
      module_app_id: "9",
      module_main_id: initialData?.id,
      module_sub_id: initialData?.company_id,
    };

    const draftMail: draftmailProps = {
      title: "New Message",
      filters: { attachment: attachment_filter },
      openMailBox: 1,
      to: [user],
    };
    dispatch(setDraftEmailData(draftMail));
  };

  const handleUserClick = (type: number) => {
    let user_id = 0;
    switch (type) {
      // Preparer
      case 1:
        user_id = getOptionValueById(preparerOptions, parseInt(initialData.preparer), "user_id");
        setSelectedUser({ id: initialData.preparer, user_id });
        setOpenUser(true);
        break;

      // Reviewer
      case 2:
        user_id = getOptionValueById(reviewerOptions, parseInt(initialData.reviewer), "user_id");
        setSelectedUser({ id: initialData.reviewer, user_id });
        setOpenUser(true);
        break;

      // File manager
      case 3:
        user_id = getOptionValueById(
          fileManagerOptions,
          parseInt(initialData.file_manager),
          "user_id"
        );
        setSelectedUser({ id: initialData.file_manager, user_id });
        setOpenUser(true);
        break;

      // Partner
      case 4:
        user_id = getOptionValueById(
          partnerOptions,
          parseInt(initialData.signing_partner),
          "user_id"
        );
        setSelectedUser({ id: initialData.signing_partner, user_id });
        setOpenUser(true);
        break;

      // Assistant administrative
      case 5:
        user_id = getOptionValueById(
          assistantAdministrativeOptions,
          parseInt(initialData.assistant_administrative),
          "user_id"
        );
        setSelectedUser({ id: initialData.assistant_administrative, user_id });
        setOpenUser(true);
        break;

      // Contact person (Company)
      case 6:
        user_id = getOptionValueById(
          companyUserOptions,
          parseInt(initialData.contact_person),
          "user_id"
        );
        setSelectedUser({ id: initialData.contact_person, user_id });
        setOpenUser(true);
        break;

      // Contact person (Invoice)
      case 7:
        user_id = getOptionValueById(
          companyUserOptions,
          parseInt(initialData.invoice_contact_person),
          "user_id"
        );
        setSelectedUser({ id: initialData.invoice_contact_person, user_id });
        setOpenUser(true);
        break;

      // Contact person (HR)
      case 8:
        user_id = getOptionValueById(
          companyUserOptions,
          parseInt(initialData.hr_contact_person),
          "user_id"
        );
        setSelectedUser({ id: initialData.hr_contact_person, user_id });
        setOpenUser(true);
        break;

      // Contact person (General)
      case 9:
        user_id = getOptionValueById(
          companyUserOptions,
          parseInt(initialData.gen_contact_person),
          "user_id"
        );
        setSelectedUser({ id: initialData.gen_contact_person, user_id });
        setOpenUser(true);
        break;
    }
  };

  // Create user
  const createUser = (type: number) => {
    setUserCreateType(type);
    setOpenUserDrawer(true);
  };

  const handleUserSave = (user: any) => {
    setUserCreated(true);
    setUserCreatedData(user);
    setOpenUserDrawer(false);
  };

  useEffect(() => {
    if (autoSave) { handleAutoSave("SET", initialData); }
  }, [initialData, autoSave]);

  return (
    <React.Fragment>
      <ScrollUp />
      <ScrollDown />

      <Alert
        open={autoSaveAlert}
        title={t("Autosaved data restored!")}
        message={t("Your last autosaved data has been restored from the session. In order to avoid the data loss, you need either save it to retain the data or Cancel to loose the data.")}
        onClickOk={() => setAutoSaveAlert(false)}
      />
      <NavigationAlert
        open={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />

      {!initialData?.id ? <Loader /> : null}
      <div className={isWindow ? "" : "page-content"}>
        <Container fluid>
          {!isWindow ? (
            <Breadcrumbs
              breadcrumbTitle={t("Update Company")}
              breadcrumbItems={breadcrumbItems}
              endAdornment={
                <>
                  <EmailButton handlerCompose={() => composeMail()} />
                </>
              }
            />
          ) : null}

          {!isWindow ? <NavItems id={id} company_id={company_id} /> : null}

          <Row>
            <Col xl="12">
              <Card className={`${!isWindow ? "border-top-0" : ""} rounded-0 rounded-bottom`}>
                <CardHeader className="d-none bg-transparent border-bottom">
                  <div className="d-sm-flex flex-wrap">
                    <CardTitle className="card-title text-uppercase">{t("Update Company")}</CardTitle>
                  </div>
                </CardHeader>

                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                  autoComplete="off"
                >
                  <Input id="id" name="id" type="hidden" value={initialData.id} />
                  <Input
                    id="company_id"
                    name="company_id"
                    type="hidden"
                    value={initialData.company_id}
                  />
                  <Input id="code" name="code" type="hidden" value={initialData.code} />

                  <CardBody>
                    <Row>
                      <Col xl={3} lg={3} md={3} sm={12}>
                        {AvatarEditor ? (
                          <AvatarEditor
                            profileImage={oldProfileImage}
                            onAvatarChange={onAvatarChange}
                          />
                        ) : null}
                      </Col>

                      <Col xl={9} lg={9} md={9} sm={12}>
                        <Row>
                          <Col xl={4} lg={4} md={4} sm={12}>
                            <div className="mb-3">
                              <Input
                                id="custome_code"
                                name="custome_code"
                                placeholder={t("Account ID")}
                                label="Account ID"
                                required
                                maxLength={10}
                                type="text"
                                value={initialData.custome_code}
                                onBlur={validation.handleBlur}
                                onChange={(e) => handleChange("custome_code", e.target.value)}
                                readOnly={!userProfile.IS_INTERNAL}
                                invalid={
                                  validation.touched.custome_code && validation.errors.custome_code
                                    ? true
                                    : false
                                }
                                error={validation.errors.custome_code}
                                touched={validation.touched.custome_code}
                              />
                            </div>
                          </Col>

                          <Col className="col-8">
                            <div className="mb-3">
                              <div className="color_palette_main_div">
                                <Input
                                  id="company_name"
                                  name="company_name"
                                  className="form-control"
                                  placeholder={t("Client name")}
                                  label="Client name"
                                  required
                                  type="text"
                                  value={initialData.company_name}
                                  autoFocus={true}
                                  onBlur={validation.handleBlur}
                                  onChange={(e) => handleChange("company_name", e.target.value)}
                                  invalid={
                                    validation.touched.company_name &&
                                      validation.errors.company_name
                                      ? true
                                      : false
                                  }
                                  error={validation.errors.company_name}
                                  touched={validation.touched.company_name}
                                />

                                <div className="color_palette_div">
                                  <Input
                                    id="color_code"
                                    name="color_code"
                                    type="color"
                                    value={initialData.color_code}
                                    className="color_palette"
                                    onChange={(e) => handleChange("color_code", e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>

                          <Col className="col-4">
                            <div className="mb-3">
                              <Select
                                id="company_category"
                                name="company_category"
                                className="is-invalid"
                                label="Client category"
                                options={categoryOptions}
                                value={
                                  initialData.company_category
                                    ? categoryOptions.find(
                                      (option: any) =>
                                        option.value == initialData.company_category
                                    )
                                    : ""
                                }
                                isSearchable={true}
                                isLoading={loadingCategory}
                                onBlur={() => validation.setFieldTouched("company_category", true)}
                                onChange={(e: any) => {
                                  handleChange("company_category", e.value);
                                }}
                                error={validation.errors.company_category}
                                touched={validation.touched.company_category}
                              />
                            </div>
                          </Col>

                          <Col className="col-4">
                            <div className="mb-3">
                              <Select
                                id="language"
                                name="language"
                                className="is-invalid"
                                label="Language"
                                required
                                options={languageList}
                                value={
                                  validation.values.language
                                    ? languageList?.find(
                                      (option: any) => option.value == validation.values.language
                                    )
                                    : ""
                                }
                                isSearchable={false}
                                onBlur={() => validation.setFieldTouched("language", true)}
                                onChange={(e: any) => {
                                  handleChange("language", e.value);
                                }}
                                error={validation.errors.language}
                                touched={validation.touched.language}
                              />
                            </div>
                          </Col>

                          <Col className="col-4">
                            <div className="mb-3">
                              <Input
                                id="email_address"
                                name="email_address"
                                placeholder={t("Email")}
                                label="Email"
                                type="email"
                                value={initialData.email_address}
                                onBlur={validation.handleBlur}
                                onChange={(e) => handleChange("email_address", e.target.value)}
                                invalid={
                                  validation.touched.email_address &&
                                    validation.errors.email_address
                                    ? true
                                    : false
                                }
                                error={validation.errors.email_address}
                                touched={validation.touched.email_address}
                              />
                            </div>
                          </Col>

                          <Col className="col-4">
                            <div className="mb-3">
                              <Input
                                id="legal_entity"
                                name="legal_entity"
                                placeholder={t("Legal entity")}
                                label="Legal entity"
                                type="text"
                                value={initialData.legal_entity}
                                onBlur={validation.handleBlur}
                                onChange={(e) => handleChange("legal_entity", e.target.value)}
                                invalid={
                                  validation.touched.legal_entity && validation.errors.legal_entity
                                    ? true
                                    : false
                                }
                                error={validation.errors.legal_entity}
                                touched={validation.touched.legal_entity}
                              />
                            </div>
                          </Col>

                          <Col className="col-4">
                            <div className="mb-3">
                              <Input
                                id="phone_number_1"
                                name="phone_number_1"
                                placeholder={t("Contact number")}
                                label="Contact number"
                                type="text"
                                value={initialData.phone_number_1}
                                onBlur={validation.handleBlur}
                                onChange={(e) => handleChange("phone_number_1", e.target.value)}
                                invalid={
                                  validation.touched.phone_number_1 &&
                                    validation.errors.phone_number_1
                                    ? true
                                    : false
                                }
                                error={validation.errors.phone_number_1}
                                touched={validation.touched.phone_number_1}
                              />
                            </div>
                          </Col>

                          <Col className="col-4">
                            <div className="mb-3">
                              <Input
                                id="phone_number_2"
                                name="phone_number_2"
                                placeholder={t("Contact number (Alternative)")}
                                label="Contact number (Alternative)"
                                type="text"
                                value={initialData.phone_number_2}
                                onBlur={validation.handleBlur}
                                onChange={(e) => handleChange("phone_number_2", e.target.value)}
                                invalid={
                                  validation.touched.phone_number_2 &&
                                    validation.errors.phone_number_2
                                    ? true
                                    : false
                                }
                                error={validation.errors.phone_number_2}
                                touched={validation.touched.phone_number_2}
                              />
                            </div>
                          </Col>

                          <Col className="col-4">
                            <div className="mb-3">
                              <Input
                                id="fax_nr_company"
                                name="fax_nr_company"
                                placeholder={t("FAX number")}
                                label="FAX number"
                                type="text"
                                value={initialData.fax_nr_company}
                                onBlur={validation.handleBlur}
                                onChange={(e) => handleChange("fax_nr_company", e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col className="col-4">
                            <div className="mb-3">
                              <Select
                                id="company_profile"
                                name="company_profile"
                                className="is-invalid"
                                label="Client profile"
                                options={profileOptions}
                                value={
                                  initialData.company_profile
                                    ? profileOptions.find(
                                      (option: any) => option.value == initialData.company_profile
                                    )
                                    : ""
                                }
                                isSearchable={true}
                                isLoading={loadingProfile}
                                isDisabled={loadingProfile}
                                onBlur={() => validation.setFieldTouched("company_profile", true)}
                                onChange={(e: any) => {
                                  handleChange("company_profile", e.value);
                                }}
                              />
                            </div>
                          </Col>

                          <Col className="col-4">
                            <div className="mb-3">
                              <Input
                                name="primary_industry_type"
                                className="form-control"
                                placeholder={t("Paritary committee")}
                                label="Paritary committee"
                                type="text"
                                value={initialData.primary_industry_type}
                                onBlur={validation.handleBlur}
                                onChange={(e) =>
                                  handleChange("primary_industry_type", e.target.value)
                                }
                              />
                            </div>
                          </Col>

                          <Col className="col-4">
                            <div className="mb-3">
                              <Input
                                id="company_registration_id"
                                name="company_registration_id"
                                className="form-control"
                                placeholder={t("Registration ID")}
                                label="Registration ID"
                                type="text"
                                maxLength={50}
                                value={initialData.company_registration_id}
                                onBlur={validation.handleBlur}
                                onChange={(e) =>
                                  handleChange("company_registration_id", e.target.value)
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label">
                            {initialData.preparer ? (
                              <Link
                                to="#"
                                onClick={() => handleUserClick(1)}
                                className="text-decoration-underline"
                              >
                                {t("Preparer")}
                              </Link>
                            ) : (
                              t("Preparer")
                            )}
                          </Label>

                          <Select
                            id="preparer"
                            name="preparer"
                            className="is-invalid"
                            options={preparerOptions}
                            value={
                              initialData.preparer
                                ? preparerOptions.find(
                                  (option: any) => option.value == initialData.preparer
                                )
                                : ""
                            }
                            isSearchable={true}
                            isDisabled={!userProfile.IS_INTERNAL}
                            onBlur={() => validation.setFieldTouched("preparer", true)}
                            onChange={(e: any) => {
                              handleChange("preparer", e.value);
                            }}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label">
                            {initialData.reviewer ? (
                              <Link
                                to="#"
                                onClick={() => handleUserClick(2)}
                                className="text-decoration-underline"
                              >
                                {t("Reviewer")}
                              </Link>
                            ) : (
                              t("Reviewer")
                            )}
                          </Label>

                          <Select
                            id="reviewer"
                            name="reviewer"
                            className="is-invalid"
                            options={reviewerOptions}
                            value={
                              initialData.reviewer
                                ? reviewerOptions.find(
                                  (option: any) => option.value == initialData.reviewer
                                )
                                : ""
                            }
                            isSearchable={true}
                            isDisabled={!userProfile.IS_INTERNAL}
                            onBlur={() => validation.setFieldTouched("reviewer", true)}
                            onChange={(e: any) => {
                              handleChange("reviewer", e.value);
                            }}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label">
                            {initialData.file_manager && userProfile.IS_INTERNAL ? (
                              <Link
                                to="#"
                                onClick={() => handleUserClick(3)}
                                className="text-decoration-underline"
                              >
                                {t("File manager")}
                              </Link>
                            ) : (
                              t("File manager")
                            )}{" "}
                            <span className="text-warning is_required">*</span>
                          </Label>

                          <Select
                            id="file_manager"
                            name="file_manager"
                            className="is-invalid"
                            options={fileManagerOptions}
                            value={
                              initialData.file_manager
                                ? fileManagerOptions.find(
                                  (option: any) => option.value == initialData.file_manager
                                )
                                : ""
                            }
                            isSearchable={true}
                            isDisabled={!userProfile.IS_INTERNAL}
                            onBlur={() => validation.setFieldTouched("file_manager", true)}
                            onChange={(e: any) => {
                              handleChange("file_manager", e.value);
                            }}
                            error={validation.errors.file_manager}
                            touched={validation.touched.file_manager}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label">
                            {initialData.signing_partner && userProfile.IS_INTERNAL ? (
                              <Link
                                to="#"
                                onClick={() => handleUserClick(4)}
                                className="text-decoration-underline"
                              >
                                {t("Partner")}
                              </Link>
                            ) : (
                              t("Partner")
                            )}{" "}
                            <span className="text-warning is_required">*</span>
                          </Label>

                          <Select
                            id="signing_partner"
                            name="signing_partner"
                            className="is-invalid"
                            options={partnerOptions}
                            value={
                              initialData.signing_partner
                                ? partnerOptions.find(
                                  (option: any) => option.value == initialData.signing_partner
                                )
                                : ""
                            }
                            isSearchable={true}
                            isDisabled={!userProfile.IS_INTERNAL}
                            onBlur={() => validation.setFieldTouched("signing_partner", true)}
                            onChange={(e: any) => {
                              handleChange("signing_partner", e.value);
                            }}
                            error={validation.errors.signing_partner}
                            touched={validation.touched.signing_partner}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label">
                            {initialData.assistant_administrative && userProfile.IS_INTERNAL ? (
                              <Link
                                to="#"
                                onClick={() => handleUserClick(5)}
                                className="text-decoration-underline"
                              >
                                {t("Assistant administrative")}
                              </Link>
                            ) : (
                              t("Assistant administrative")
                            )}
                          </Label>

                          <Select
                            id="assistant_administrative"
                            name="assistant_administrative"
                            className="is-invalid"
                            options={assistantAdministrativeOptions}
                            value={
                              initialData.assistant_administrative
                                ? assistantAdministrativeOptions.find(
                                  (option: any) =>
                                    option.value == initialData.assistant_administrative
                                )
                                : ""
                            }
                            isSearchable={true}
                            isDisabled={!userProfile.IS_INTERNAL}
                            onBlur={() =>
                              validation.setFieldTouched("assistant_administrative", true)
                            }
                            onChange={(e: any) => {
                              handleChange("assistant_administrative", e.value);
                            }}
                          />
                        </div>
                      </Col>

                      {userProfile.IS_INTERNAL && (
                        <Col className="col-3">
                          <div className="mb-3">
                            <Input
                              id="category"
                              name="category"
                              label="Category"
                              value={initialData.category}
                              readOnly
                              onBlur={() => validation.setFieldTouched("category", true)}
                            />
                          </div>
                        </Col>
                      )}
                      <Col className="col-3">
                        <div className="mb-3">
                          <Select
                            id="status"
                            name="status"
                            label="Status"
                            options={statusList}
                            value={
                              initialData.status
                                ? statusList?.find(
                                  (option: any) => option.value == initialData.status
                                )
                                : ""
                            }
                            isSearchable={false}
                            isDisabled={!userProfile.IS_INTERNAL}
                            onBlur={() => validation.setFieldTouched("status", true)}
                            onChange={(e: any) => {
                              handleChange("status", e.value);
                            }}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Select
                            id="listing_status_id"
                            name="listing_status_id"
                            className="is-invalid"
                            label="Listing status"
                            required
                            options={listingStatus}
                            value={
                              initialData.listing_status_id
                                ? listingStatus?.find(
                                  (option: any) => option.value == initialData.listing_status_id
                                )
                                : ""
                            }
                            isSearchable={false}
                            isDisabled={!userProfile.IS_INTERNAL}
                            onBlur={() => validation.setFieldTouched("listing_status_id", true)}
                            onChange={(e: any) => {
                              handleChange("listing_status_id", e.value);
                            }}
                            error={validation.errors.listing_status_id}
                            touched={validation.touched.listing_status_id}
                          />
                        </div>
                      </Col>

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <Col className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Client address")}
                            {/* {" "}
                                <span className="text-warning is_required">
                                  *
                                </span> */}
                          </Label>
                          {/* <Input
                                id="company_address"
                                name="company_address"
                                className="form-control"
                                placeholder="Company address"
                                type="textarea"
                                maxLength={200}
                                value={initialData.company_address}
                                onBlur={validation.handleBlur}
                                onChange={(e) =>
                                  handleChange(
                                    "company_address",
                                    e.target.value
                                  )
                                }
                                invalid={
                                  validation.touched.company_address &&
                                    validation.errors.company_address
                                    ? true
                                    : false
                                }
                              /> */}
                          <Autocomplete
                            id="company_address"
                            name="company_address"
                            className={"form-control"}
                            value={initialData.company_address}
                            onBlur={validation.handleBlur}
                            placeholder={t("Client address")}
                            onChange={(e: any) => handleChange("company_address", e.target.value)}
                            apiKey={process.env.REACT_APP_GOOGLE_ADDRESS_API_KEY!}
                            options={{
                              types: ["geocode", "establishment"],
                            }}
                            onPlaceSelected={(data: any) => {
                              handleAddressChange(data);
                            }}
                          />

                          {validation.touched.company_address &&
                            validation.errors.company_address ? (
                            <FormFeedback type="invalid">
                              {validation.errors.company_address}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Input
                            id="office_number"
                            name="office_number"
                            className="form-control"
                            placeholder={t("Office number")}
                            label="Office number"
                            type="text"
                            maxLength={10}
                            value={initialData.office_number}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("office_number", e.target.value)}
                            invalid={
                              validation.touched.office_number && validation.errors.office_number
                                ? true
                                : false
                            }
                            error={validation.errors.office_number}
                            touched={validation.touched.office_number}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Input
                            id="box"
                            name="box"
                            className="form-control"
                            placeholder={t("Box number")}
                            label="Box number"
                            type="text"
                            maxLength={10}
                            value={initialData.box}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("box", e.target.value)}
                            invalid={validation.touched.box && validation.errors.box ? true : false}
                            error={validation.errors.box}
                            touched={validation.touched.box}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Input
                            id="postal_code"
                            name="postal_code"
                            className="form-control"
                            placeholder={t("Postal code")}
                            label="Postal code"
                            type="text"
                            maxLength={30}
                            value={initialData.postal_code}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("postal_code", e.target.value)}
                            invalid={
                              validation.touched.postal_code && validation.errors.postal_code
                                ? true
                                : false
                            }
                            error={validation.errors.postal_code}
                            touched={validation.touched.postal_code}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Input
                            id="city"
                            name="city"
                            className="form-control"
                            placeholder={t("City")}
                            label="City"
                            type="text"
                            maxLength={100}
                            value={initialData.city}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("city", e.target.value)}
                            invalid={
                              validation.touched.city && validation.errors.city ? true : false
                            }
                            error={validation.errors.city}
                            touched={validation.touched.city}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Input
                            id="region"
                            name="region"
                            className="form-control"
                            placeholder={t("Region")}
                            label="Region"
                            type="text"
                            maxLength={100}
                            value={initialData.region}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("region", e.target.value)}
                            invalid={
                              validation.touched.region && validation.errors.region ? true : false
                            }
                            error={validation.errors.region}
                            touched={validation.touched.region}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Select
                            id="country"
                            name="country"
                            className="is-invalid"
                            label="Country"
                            options={countryOptions}
                            value={
                              initialData.country
                                ? countryOptions.find(
                                  (option: any) => option.value == initialData.country
                                )
                                : ""
                            }
                            isSearchable={true}
                            isDisabled={loadingCountry}
                            isLoading={loadingCountry}
                            onBlur={() => validation.setFieldTouched("country", true)}
                            onChange={(e: any) => {
                              handleChange("country", e.value);
                            }}
                            error={validation.errors.country}
                            touched={validation.touched.country}
                          />
                        </div>
                      </Col>

                      <Col className="col-12">
                        <br />
                      </Col>

                      <Col className="col-12">
                        <Label className="form-label">
                          <div className="form-check float-start">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="inv_like_company_address"
                              value={initialData.inv_like_company_address}
                              checked={initialData.inv_like_company_address === 1 ? true : false}
                              onBlur={validation.handleBlur}
                              onChange={handleInvLikeCompanyAddress}
                            />
                            <label className="form-check-label" htmlFor="inv_like_company_address">
                              {t("Same as a Client address")}
                            </label>
                          </div>
                        </Label>
                      </Col>

                      <Col className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Client address (Invoice)")}
                            {/* {" "}
                                <span className="text-warning is_required">
                                  *
                                </span> */}
                          </Label>
                          {/* <Input
                                id="inv_company_address"
                                name="inv_company_address"
                                className="form-control"
                                placeholder="Company address"
                                type="textarea"
                                maxLength={100}
                                value={initialData.inv_company_address}
                                onBlur={validation.handleBlur}
                                onChange={(e) =>
                                  handleChange(
                                    "inv_company_address",
                                    e.target.value
                                  )
                                }
                                readOnly={disableInvCompanyAddress}
                                invalid={
                                  validation.touched.inv_company_address &&
                                    validation.errors.inv_company_address
                                    ? true
                                    : false
                                }
                              /> */}
                          <Autocomplete
                            id="inv_company_address"
                            name="inv_company_address"
                            className={"form-control"}
                            value={initialData.inv_company_address}
                            onBlur={validation.handleBlur}
                            placeholder={t("Client address (Invoice)")}
                            onChange={(e: any) =>
                              handleChange("inv_company_address", e.target.value)
                            }
                            readOnly={disableInvCompanyAddress}
                            apiKey={process.env.REACT_APP_GOOGLE_ADDRESS_API_KEY!}
                            options={{
                              types: ["geocode", "establishment"],
                            }}
                            onPlaceSelected={(data: any) => {
                              handleInvAddressChange(data);
                            }}
                          />

                          {validation.touched.inv_company_address &&
                            validation.errors.inv_company_address ? (
                            <FormFeedback type="invalid">
                              {validation.errors.inv_company_address}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Input
                            id="inv_office_number"
                            name="inv_office_number"
                            className="form-control"
                            placeholder={t("Office number")}
                            label="Office number"
                            type="text"
                            maxLength={10}
                            value={initialData.inv_office_number}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("inv_office_number", e.target.value)}
                            readOnly={disableInvCompanyAddress}
                            invalid={
                              validation.touched.inv_office_number &&
                                validation.errors.inv_office_number
                                ? true
                                : false
                            }
                            error={validation.errors.inv_office_number}
                            touched={validation.touched.inv_office_number}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Input
                            id="inv_box"
                            name="inv_box"
                            className="form-control"
                            placeholder={t("Box number")}
                            label="Box number"
                            type="text"
                            maxLength={10}
                            value={initialData.inv_box}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("inv_box", e.target.value)}
                            readOnly={disableInvCompanyAddress}
                            invalid={
                              validation.touched.inv_box && validation.errors.inv_box ? true : false
                            }

                            error={validation.errors.inv_box}
                            touched={validation.touched.inv_box}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Input
                            id="inv_postal_code"
                            name="inv_postal_code"
                            className="form-control"
                            placeholder={t("Postal code")}
                            label="Postal code"
                            type="text"
                            maxLength={30}
                            value={initialData.inv_postal_code}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("inv_postal_code", e.target.value)}
                            readOnly={disableInvCompanyAddress}
                            invalid={
                              validation.touched.inv_postal_code &&
                                validation.errors.inv_postal_code
                                ? true
                                : false
                            }

                            error={validation.errors.inv_postal_code}
                            touched={validation.touched.inv_postal_code}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Input
                            id="inv_city"
                            name="inv_city"
                            className="form-control"
                            placeholder={t("City")}
                            label="City"
                            type="text"
                            maxLength={100}
                            value={initialData.inv_city}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("inv_city", e.target.value)}
                            readOnly={disableInvCompanyAddress}
                            invalid={
                              validation.touched.inv_city && validation.errors.inv_city
                                ? true
                                : false
                            }
                            error={validation.errors.inv_city}
                            touched={validation.touched.inv_city}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Input
                            id="inv_region"
                            name="inv_region"
                            className="form-control"
                            placeholder={t("Region")}
                            label="Region"
                            type="text"
                            maxLength={100}
                            value={initialData.inv_region}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("inv_region", e.target.value)}
                            readOnly={disableInvCompanyAddress}
                            invalid={
                              validation.touched.inv_region && validation.errors.inv_region ? true : false
                            }
                            error={validation.errors.inv_region}
                            touched={validation.touched.inv_region}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Select
                            id="inv_country"
                            name="inv_country"
                            className="is-invalid"
                            options={countryOptions}
                            label="Country"
                            value={
                              initialData.inv_country
                                ? countryOptions.find(
                                  (option: any) => option.value == initialData.inv_country
                                )
                                : ""
                            }
                            isSearchable={true}
                            isDisabled={loadingCountry ? loadingCountry : disableInvCompanyAddress}
                            isLoading={loadingCountry}
                            onBlur={() => validation.setFieldTouched("inv_country", true)}
                            onChange={(e: any) => {
                              handleChange("inv_country", e.value);
                            }}
                          />
                        </div>
                      </Col>

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label w-100">
                            {initialData.contact_person ? (
                              <Link
                                to="#"
                                onClick={() => handleUserClick(6)}
                                className="text-decoration-underline"
                              >
                                {t("Contact person (Company)")}
                              </Link>
                            ) : (
                              t("Contact person (Company)")
                            )}{" "}
                            <span className="text-warning is_required">*</span>
                          </Label>

                          <div className="input-group is-invalid">
                            <SelectNew
                              id="contact_person"
                              name="contact_person"
                              className="input-group-select is-invalid"
                              options={companyUserOptions}
                              value={
                                initialData.contact_person
                                  ? companyUserOptions.find(
                                    (option: any) => option.value == initialData.contact_person
                                  )
                                  : ""
                              }
                              isSearchable={true}
                              isDisabled={loadingCompanyUser}
                              isLoading={loadingCompanyUser}
                              onBlur={() => validation.setFieldTouched("contact_person", true)}
                              onChange={(e: any) => {
                                handleChange("contact_person", e.value);
                              }}

                            />
                            {userProfile.IS_INTERNAL && userProfile.CAN_CREATE ? (
                              <Button
                                color="primary"
                                className="input-group-text btn-sm"
                                title="Create user"
                                onClick={() => createUser(1)}
                              >
                                <span className="align-middle material-symbols-outlined fw-light fs-4">
                                  person_add
                                </span>
                              </Button>
                            ) : null}
                          </div>

                          {validation.touched.contact_person && validation.errors.contact_person ? (
                            <FormFeedback type="invalid">
                              {validation.errors.contact_person}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Input
                            id="contact_function"
                            name="contact_function"
                            className="form-control"
                            placeholder={t("Function")}
                            label="Function"
                            type="text"
                            value={initialData.contact_function}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("contact_function", e.target.value)}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label w-100">
                            {initialData.invoice_contact_person ? (
                              <Link
                                to="#"
                                onClick={() => handleUserClick(7)}
                                className="text-decoration-underline"
                              >
                                {t("Contact person (Invoice)")}
                              </Link>
                            ) : (
                              t("Contact person (Invoice)")
                            )}{" "}
                            <span className="text-warning is_required">*</span>
                          </Label>

                          <div className="input-group is-invalid">
                            <SelectNew
                              id="invoice_contact_person"
                              name="invoice_contact_person"
                              className="input-group-select is-invalid"
                              options={companyUserOptions}
                              value={
                                initialData.invoice_contact_person
                                  ? companyUserOptions.find(
                                    (option: any) =>
                                      option.value == initialData.invoice_contact_person
                                  )
                                  : ""
                              }
                              isSearchable={true}
                              isDisabled={loadingCompanyUser}
                              isLoading={loadingCompanyUser}
                              onBlur={() =>
                                validation.setFieldTouched("invoice_contact_person", true)
                              }
                              onChange={(e: any) => {
                                handleChange("invoice_contact_person", e.value);
                              }}
                            />
                            {userProfile.IS_INTERNAL && userProfile.CAN_CREATE ? (
                              <Button
                                color="primary"
                                className="input-group-text btn-sm"
                                title="Create user"
                                onClick={() => createUser(2)}
                              >
                                <span className="align-middle material-symbols-outlined fw-light fs-4">
                                  person_add
                                </span>
                              </Button>
                            ) : null}
                          </div>

                          {validation.touched.invoice_contact_person &&
                            validation.errors.invoice_contact_person ? (
                            <FormFeedback type="invalid">
                              {validation.errors.invoice_contact_person}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Input
                            id="invoice_payable_period"
                            name="invoice_payable_period"
                            className="form-control"
                            placeholder={t("Payable period (Invoice)")}
                            label="Payable period (Invoice)"
                            required
                            type="text"
                            value={initialData.invoice_payable_period}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("invoice_payable_period", e.target.value)}
                            invalid={
                              validation.touched.invoice_payable_period &&
                                validation.errors.invoice_payable_period
                                ? true
                                : false
                            }
                            error={validation.errors.invoice_payable_period}
                            touched={validation.touched.invoice_payable_period}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label w-100">
                            {initialData.hr_contact_person ? (
                              <Link
                                to="#"
                                onClick={() => handleUserClick(8)}
                                className="text-decoration-underline"
                              >
                                {t("Contact person (HR)")}
                              </Link>
                            ) : (
                              t("Contact person (HR)")
                            )}{" "}
                            <span className="text-warning is_required">*</span>
                          </Label>

                          <div className="input-group is-invalid">
                            <SelectNew
                              id="hr_contact_person"
                              name="hr_contact_person"
                              className="input-group-select is-invalid"
                              options={companyUserOptions}
                              value={
                                initialData.hr_contact_person
                                  ? companyUserOptions.find(
                                    (option: any) => option.value == initialData.hr_contact_person
                                  )
                                  : ""
                              }
                              isSearchable={true}
                              isDisabled={loadingCompanyUser}
                              isLoading={loadingCompanyUser}
                              onBlur={() => validation.setFieldTouched("hr_contact_person", true)}
                              onChange={(e: any) => {
                                handleChange("hr_contact_person", e.value);
                              }}
                            />
                            {userProfile.IS_INTERNAL && userProfile.CAN_CREATE ? (
                              <Button
                                color="primary"
                                className="input-group-text btn-sm"
                                title="Create user"
                                onClick={() => createUser(3)}
                              >
                                <span className="align-middle material-symbols-outlined fw-light fs-4">
                                  person_add
                                </span>
                              </Button>
                            ) : null}
                          </div>

                          {validation.touched.hr_contact_person &&
                            validation.errors.hr_contact_person ? (
                            <FormFeedback type="invalid">
                              {validation.errors.hr_contact_person}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label w-100">
                            {initialData.gen_contact_person ? (
                              <Link
                                to="#"
                                onClick={() => handleUserClick(9)}
                                className="text-decoration-underline"
                              >
                                {t("Contact person (General)")}
                              </Link>
                            ) : (
                              t("Contact person (General)")
                            )}
                          </Label>

                          <div className="input-group is-invalid">
                            <Select
                              id="gen_contact_person"
                              name="gen_contact_person"
                              className="input-group-select is-invalid"
                              options={companyUserOptions}
                              value={
                                initialData.gen_contact_person
                                  ? companyUserOptions.find(
                                    (option: any) =>
                                      option.value == initialData.gen_contact_person
                                  )
                                  : ""
                              }
                              isSearchable={true}
                              isDisabled={loadingCompanyUser}
                              isLoading={loadingCompanyUser}
                              onBlur={() => validation.setFieldTouched("gen_contact_person", true)}
                              onChange={(e: any) => {
                                handleChange("gen_contact_person", e.value);
                              }}
                            />
                            {userProfile.IS_INTERNAL && userProfile.CAN_CREATE ? (
                              <Button
                                color="primary"
                                className="input-group-text btn-sm"
                                title="Create user"
                                onClick={() => createUser(4)}
                              >
                                <span className="align-middle material-symbols-outlined fw-light fs-4">
                                  person_add
                                </span>
                              </Button>
                            ) : null}
                          </div>

                          {validation.touched.gen_contact_person &&
                            validation.errors.gen_contact_person ? (
                            <FormFeedback type="invalid">
                              {validation.errors.gen_contact_person}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <BanksData
                        data={bankData}
                        onChange={(data: any) =>
                          // handleChange("bank_data", data)

                          setInitialData((prevData: any) => ({
                            ...prevData,
                            bank_data: data,
                          }))
                        }
                      />

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <Col className="col-12">
                        <div className="mb-3">
                          <Input
                            id="short_description_activities"
                            name="short_description_activities"
                            className="form-control"
                            // placeholder="Short description of activities"
                            label="Short description of activities"
                            type="textarea"
                            value={initialData.short_description_activities}
                            onBlur={validation.handleBlur}
                            onChange={(e) =>
                              handleChange("short_description_activities", e.target.value)
                            }
                            invalid={
                              validation.touched.short_description_activities &&
                                validation.errors.short_description_activities
                                ? true
                                : false
                            }
                            error={validation.errors.short_description_activities}
                            touched={validation.touched.short_description_activities}
                          />
                        </div>
                      </Col>

                      <Col className="col-12">
                        <div className="mb-3">
                          <Input
                            id="stuation_of_the_belgian_entity"
                            name="stuation_of_the_belgian_entity"
                            className="form-control"
                            label="Situation of the Belgian entity within the international group"
                            // placeholder="Situation of the Belgian entity within the international group"
                            type="textarea"
                            value={initialData.stuation_of_the_belgian_entity}
                            onBlur={validation.handleBlur}
                            onChange={(e) =>
                              handleChange("stuation_of_the_belgian_entity", e.target.value)
                            }
                            invalid={
                              validation.touched.stuation_of_the_belgian_entity &&
                                validation.errors.stuation_of_the_belgian_entity
                                ? true
                                : false
                            }
                            error={validation.errors.stuation_of_the_belgian_entity}
                            touched={validation.touched.stuation_of_the_belgian_entity}
                          />
                        </div>
                      </Col>

                      <Col className="col-12">
                        <div className="mb-3">
                          <Input
                            id="additional_info"
                            name="additional_info"
                            className="form-control"
                            label="Additional information on the company"
                            // placeholder="Additional information on the company"
                            type="textarea"
                            value={initialData.additional_info}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("additional_info", e.target.value)}
                            invalid={
                              validation.touched.additional_info &&
                                validation.errors.additional_info
                                ? true
                                : false
                            }
                            error={validation.errors.additional_info}
                            touched={validation.touched.additional_info}
                          />
                        </div>
                      </Col>

                      <Col className="col-6">
                        <div className="mb-3">
                          <Input
                            id="website"
                            name="website"
                            className="form-control"
                            placeholder={t("Website")}
                            label="Website"
                            type="text"
                            value={initialData.website}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("website", e.target.value)}
                          />
                        </div>
                      </Col>

                      <Col className="col-6">
                        <div className="mb-3">
                          <Input
                            id="external_web_link"
                            name="external_web_link"
                            className="form-control"
                            placeholder={t("External web link")}
                            label="External web link"
                            type="text"
                            maxLength={500}
                            value={initialData.external_web_link}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("external_web_link", e.target.value)}
                          />
                        </div>
                      </Col>

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Input
                            id="number_Of_employees"
                            name="number_Of_employees"
                            className="form-control"
                            placeholder={t("Number of employees")}
                            label="Number of employees"
                            type="text"
                            value={initialData.number_Of_employees}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("number_Of_employees", e.target.value)}
                            invalid={
                              validation.touched.number_Of_employees &&
                                validation.errors.number_Of_employees
                                ? true
                                : false
                            }
                            error={validation.errors.number_Of_employees}
                            touched={validation.touched.number_Of_employees}
                          />
                        </div>
                      </Col>

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("TVA/BTW number")}&nbsp;
                            <Tooltip content={`${t("Format VAT")}: BE0123456789`} direction="top">
                              <i className="fa-regular fas fa-info-circle"></i>
                            </Tooltip>
                          </Label>
                          <InputGroup>
                            <InputNew
                              id="TVW_Nr"
                              name="TVW_Nr"
                              className="form-control"
                              placeholder="TVA/BTW number"
                              type="text"
                              mask="999-999-9999"
                              value={initialData.TVW_Nr}
                              valid={validateVat.valid}
                              onBlur={validation.handleBlur}
                              onChange={(e) => handleChange("TVW_Nr", e.target.value)}
                              invalid={
                                (validation.touched.TVW_Nr && validation.errors.TVW_Nr) ||
                                  validateVat.valid === false
                                  ? true
                                  : false
                              }
                            />
                            <Button
                              disabled={
                                initialData.TVW_Nr === "" ||
                                validateVat.loading ||
                                validateVat.valid
                              }
                              outline
                              onClick={() => handleValidate(initialData.TVW_Nr)}
                              color={validateVat.valid ? "success" : "secondary"}
                            >
                              {validateVat.loading
                                ? t("Loading")
                                : validateVat.valid
                                  ? t("Valid")
                                  : t("Validate")}
                            </Button>
                          </InputGroup>
                          {validation.touched.TVW_Nr && validation.errors.TVW_Nr ? (
                            <FormFeedback type="invalid">{validation.errors.TVW_Nr}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      {userProfile.IS_INTERNAL && (
                        <>
                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <Select
                                id="vatcat"
                                name="vatcat"
                                className="is-invalid"
                                placeholder={t("VAT Category")}
                                label="VAT Category"
                                options={vatcatOptions}
                                value={
                                  validation.values?.vatcat
                                    ? vatcatOptions.find(
                                      (option: any) => option.value == validation.values.vatcat
                                    )
                                    : ""
                                }
                                isSearchable={false}
                                onBlur={() => validation.setFieldTouched("vatcat", true)}
                                onChange={(e: any) => {
                                  handleChange("vatcat", e.value);
                                }}
                                error={validation.errors.vatcat}
                                touched={validation.touched.vatcat}
                              />
                            </div>
                          </Col>

                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <Input
                                id="vat_percentage"
                                name="vat_percentage"
                                label="Vat Percentage"
                                value={validation.values.vat_percentage}
                                readOnly
                                onChange={(e: any) => {
                                  handleChange("vat_percentage", e.target.value);
                                }}
                              />
                            </div>
                          </Col>
                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <Input
                                id="vat_code"
                                name="vat_code"
                                label="Vat Code"
                                value={initialData.vat_code}
                                readOnly
                                onChange={(e: any) => {
                                  handleChange("vat_code", e.target.value);
                                }}
                              />
                            </div>
                          </Col>
                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <Select
                                id="to_apply_vat"
                                name="to_apply_vat"
                                className="is-invalid"
                                label="To Apply Vat"
                                options={yesNoList}
                                value={
                                  validation.values?.to_apply_vat
                                    ? yesNoList?.find(
                                      (option: any) =>
                                        option.value == validation.values.to_apply_vat
                                    )
                                    : ""
                                }
                                isSearchable={false}
                                onBlur={() => validation.setFieldTouched("to_apply_vat", true)}
                                onChange={(e: any) => {
                                  handleChange("to_apply_vat", e.value);
                                }}

                                error={validation.errors.to_apply_vat}
                                touched={validation.touched.to_apply_vat}
                              />
                            </div>
                          </Col>
                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <Select
                                id="reason_for_vat"
                                name="reason_for_vat"
                                className="is-invalid"
                                options={reasonsList}
                                label="Reason"
                                value={
                                  validation.values?.reason_for_vat
                                    ? reasonsList?.find(
                                      (option: any) =>
                                        option.value == validation.values.reason_for_vat
                                    )
                                    : ""
                                }
                                isSearchable={false}
                                onBlur={() => validation.setFieldTouched("reason_for_vat", true)}
                                onChange={(e: any) => {
                                  handleChange("reason_for_vat", e.value);
                                }}
                                error={validation.errors.reason_for_vat}
                                touched={validation.touched.reason_for_vat}
                              />
                            </div>
                          </Col>
                        </>
                      )}

                      <Col className="col-12"></Col>

                      {/* {userProfile.IS_INTERNAL ? (
                        <Col xl={2} lg={2} md={3} sm={12}>
                          <div className="mb-3">
                            <Label className="form-label">Archive</Label>
                            <div className="form-check mb-2">
                              <Input
                                id="is_archive_yes"
                                name="is_archive"
                                value="1"
                                className="form-check-input"
                                type="radio"
                                checked={
                                  initialData.is_archive == 1 ? true : false
                                }
                                onChange={() => handleChange("is_archive", 1)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="is_archive_yes"
                              >
                                Yes
                              </label>
                            </div>

                            <div className="form-check mb-2">
                              <Input
                                id="is_archive_no"
                                name="is_archive"
                                value="0"
                                className="form-check-input"
                                type="radio"
                                checked={
                                  initialData.is_archive == 0 ? true : false
                                }
                                onChange={() => handleChange("is_archive", 0)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="is_archive_no"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </Col>
                      ) : null}

                      <Col className="col-12"></Col> */}

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <Col xl={6} lg={6} md={6} sm={12}>
                        <div className="mb-3">
                          <div className="input-group is-invalid">
                            {userProfile.IS_INTERNAL ? (
                              <Flatpickr
                                id="start_date"
                                name="start_date"
                                label="Start date"
                                required
                                className="form-control d-block"
                                type="text"
                                value={initialData.start_date}
                                placeholder="DD/MM/YYYY"
                                options={{
                                  altInput: true,
                                  altFormat: "d/m/Y",
                                  dateFormat: "Y-m-d H:i:S",
                                  // minDate: initialData.start_date,
                                  allowInput: true,
                                  plugins: [labelPlugin()],
                                  onChange: function (selectedDates, dateStr, instance) {
                                    handleChange("start_date", dateStr);
                                  },
                                  onClose: function (selectedDates, dateStr, instance) {
                                    handleChange("start_date", dateStr);
                                  },
                                  locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                                }}
                                error={validation.errors.start_date}
                                touched={validation.touched.start_date}
                              />
                            ) : (
                              <Input
                                id="start_date"
                                name="start_date"
                                className="form-control d-block"
                                type="text"
                                value={
                                  initialData.start_date
                                    ? moment(initialData.start_date).format("DD/MM/YYYY")
                                    : ""
                                }
                                placeholder="DD/MM/YYYY"
                                disabled={true}
                              />
                            )}
                          </div>
                        </div>
                      </Col>

                      <Col xl={6} lg={6} md={6} sm={12}>
                        <div className="mb-3">
                          <div className="input-group is-invalid">
                            {userProfile.IS_INTERNAL ? (
                              <Flatpickr
                                id="end_date"
                                name="end_date"
                                label="End date"
                                className="form-control d-block"
                                type="text"
                                value={initialData.end_date}
                                placeholder="DD/MM/YYYY"
                                options={{
                                  altInput: true,
                                  altFormat: "d/m/Y",
                                  dateFormat: "Y-m-d H:i:S",
                                  minDate: initialData.start_date,
                                  allowInput: true,
                                  plugins: [labelPlugin()],
                                  onChange: function (selectedDates, dateStr, instance) {
                                    handleChange("end_date", dateStr);
                                  },
                                  onClose: function (selectedDates, dateStr, instance) {
                                    handleChange("end_date", dateStr);
                                  },
                                  locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                                }}
                              />
                            ) : (
                              <Input
                                id="end_date"
                                name="end_date"
                                className="form-control d-block"
                                type="text"
                                value={
                                  initialData.end_date
                                    ? moment(initialData.end_date).format("DD/MM/YYYY")
                                    : ""
                                }
                                placeholder="DD/MM/YYYY"
                                disabled={true}
                              />
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>

                  {!dataNotFound && (
                    <CardFooter className="bg-transparent border-top">
                      <Row>
                        <Col className="col-12 mt-2 mb-3">
                          {initialData &&
                            initialData.created_by_first_name &&
                            initialData.created_by_last_name ? (
                            <div>
                              <ul className="list-inline mb-1">
                                <li className="list-inline-item me-3">
                                  <span className="text-muted">
                                    <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                      person
                                    </span>
                                    {t("Created by")} {initialData.created_by_first_name}{" "}
                                    {initialData.created_by_last_name}
                                  </span>
                                </li>
                                <li className="list-inline-item me-3">
                                  <span className="text-muted">
                                    <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                      calendar_today
                                    </span>
                                    {dateFormat(initialData.created_at)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          ) : null}

                          {initialData &&
                            initialData.updated_by_first_name &&
                            initialData.updated_by_last_name ? (
                            <div>
                              <ul className="list-inline">
                                <li className="list-inline-item me-3">
                                  <span className="text-muted">
                                    <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                      person
                                    </span>
                                    {t("Last modified by")} {initialData.updated_by_first_name}{" "}
                                    {initialData.updated_by_last_name}
                                  </span>
                                </li>
                                <li className="list-inline-item me-3">
                                  <span className="text-muted">
                                    <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                      calendar_today
                                    </span>
                                    {dateFormat(initialData.updated_at)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          ) : null}
                        </Col>
                      </Row>

                      <div className="d-sm-flex flex-wrap ">
                        <div className="d-flex gap-2">
                          {((userProfile.CAN_DELETE &&
                            userProfile.USER_ID === parseInt(initialData?.created_by)) ||
                            userProfile.CAN_DELETE_ALL) && (
                              <Button
                                color="outline-danger"
                                className="btn-block btn-label"
                                type="button"
                                onClick={handleArchive}
                              >
                                {initialData.is_archive == 0 ? (
                                  <>
                                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                      archive
                                    </span>
                                    {t("Archive")}
                                  </>
                                ) : (
                                  <>
                                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                      unarchive
                                    </span>
                                    {t("Unarchive")}
                                  </>
                                )}
                              </Button>
                            )}
                        </div>

                        <div className="ms-auto d-flex gap-2 justify-content-end">
                          {userProfile.IS_INTERNAL &&
                            (userProfile.USER_ID === parseInt(initialData?.created_by) ||
                              userProfile.CAN_EDIT_ALL) &&
                            userProfile.CAN_CREATE && (
                              <>
                                {!isWindow && userProfile.IS_INTERNAL ? (
                                  <Button
                                    color="primary"
                                    className="btn-block"
                                    type="submit"
                                    onClick={() =>
                                      handleChange("submit_btn_code", "update_add_new")
                                    }
                                    disabled={loading}
                                    loading={loading}
                                  >
                                    {t("Update & Add new")}
                                  </Button>
                                ) : null}
                              </>
                            )}

                          {userProfile.IS_INTERNAL &&
                            (userProfile.USER_ID === parseInt(initialData?.created_by) ||
                              userProfile.CAN_EDIT_ALL) && (
                              <>
                                {!isWindow ? (
                                  <Button
                                    color="primary"
                                    className="btn-block"
                                    type="submit"
                                    onClick={() => handleChange("submit_btn_code", "update_close")}
                                    disabled={loading}
                                    loading={loading}
                                  >
                                    {t("Update & Close")}
                                  </Button>
                                ) : null}

                                <Button
                                  color="primary"
                                  className="btn-block"
                                  type="submit"
                                  onClick={() => handleChange("submit_btn_code", "update")}
                                  disabled={loading}
                                  loading={loading}
                                >
                                  {t("Update")}
                                </Button>
                              </>
                            )}

                          {!isWindow ? (
                            <Button
                              color="danger"
                              className="btn-block"
                              type="reset"
                              onClick={() => {
                                handleRedirect(true);
                              }}
                              disabled={loading}
                              loading={loading}
                            >
                              {t("Cancel")}
                            </Button>
                          ) : null}
                        </div>
                      </div>
                    </CardFooter>
                  )}
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>

        <Drawer
          title={t("Create User")}
          open={openUserDrawer}
          onClose={() => setOpenUserDrawer(false)}
          fullWidth
        >
          <NewUser
            company={initialData?.id}
            inTab={true}
            onSave={(data: any) => handleUserSave(data)}
          />
        </Drawer>

        <Drawer
          title={t("User")}
          open={openUser}
          onClose={() => setOpenUser((state) => !state)}
          fullWidth
        >
          <EditUser
            id={selectedUser?.id}
            user_id={selectedUser?.user_id}
            type={1}
            isWindow={true}
          />
        </Drawer>
      </div>

      <Modal isOpen={processModal} toggle={() => setProcessModal(!processModal)} centered={true}>
        <div className="modal-header">
          <h5 className="modal-title">Process Data</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setProcessModal(false)}
            aria-label="Close"
          ></button>
        </div>
        <ModalBody>
          <p>{t("Do you want to process the data")}?</p>
        </ModalBody>
        <ModalFooter>
          <button
            type="submit"
            className="btn btn-primary btn-md ms-2"
            onClick={onProcessClick}
            disabled={loading}
          >
            {t("Process")}
          </button>
          <button
            type="button"
            className="btn btn-outline-danger btn-md ms-2"
            onClick={() => setProcessModal(false)}
          >
            {t("Cancel")}
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default EditCompany;
