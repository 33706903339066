import { FC, useState, useEffect } from "react";
import { Col, Container, Label, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import Select from "../../../components/Form/Select/Select";

type FiltersProps = {
  filters: any;
  onApply: any;
  onClear: any;
  onChange?: any;
};

const Filters: FC<FiltersProps> = ({ filters, onApply, onClear, onChange }) => {
  const { t } = useTranslation();

  const [typeOptions, setTypeOptions] = useState([
    { value: "0", label: "Individual" },
    { value: "1", label: "Company" },
  ]);

  const [statusOptions, setStatusOptions] = useState([
    { value: 1, label: "Allocated" },
    { value: 2, label: "Not allocated" },
  ]);

  const [statusForOptions, setStatusForOptions] = useState([
    { value: 1, label: "Preparer" },
    { value: 2, label: "Reviewer" },
    { value: 3, label: "File manager" },
    { value: 4, label: "Partner" },
    { value: 5, label: "Assistant administrative" },
  ]);

  return (
    <Container>
      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label">{t("Client type")}</Label>
            <Select
              placeholder={t("Select...")}
              options={typeOptions}
              value={
                filters?.type
                  ? typeOptions.find(
                      (option: any) => option.value == filters?.type
                    )
                  : ""
              }
              isSearchable={false}
              onChange={(option: any) => {
                onChange({ is_filtered: true, type: option.value });
              }}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label">{t("Client status")}</Label>
            <Select
              placeholder={t("Select...")}
              options={statusOptions}
              value={
                filters?.status
                  ? statusOptions.find(
                      (option: any) => option.value == filters?.status
                    )
                  : ""
              }
              isSearchable={false}
              onChange={(option: any) => {
                onChange({ is_filtered: true, status: option.value });
              }}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label">{t("Client status for")}</Label>
            <Select
              placeholder={t("Select...")}
              options={statusForOptions}
              value={
                filters?.status_for
                  ? statusForOptions.find(
                      (option: any) => option.value == filters?.status_for
                    )
                  : ""
              }
              isSearchable={false}
              isDisabled={filters?.status ? false : true}
              onChange={(option: any) => {
                onChange({ is_filtered: true, status_for: option.value });
              }}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Filters;
