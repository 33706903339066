import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { useBlocker } from './useBlocker'
import { handleAutoSave } from '../helpers/functions_helper'

export function useCallbackPrompt(when: boolean): (boolean | (() => void))[] {
    const navigate = useNavigate()
    const location = useLocation()
    const [showPrompt, setShowPrompt] = useState(false)
    const [lastLocation, setLastLocation] = useState<any>(null)
    const [confirmedNavigation, setConfirmedNavigation] = useState(false)

    const cancelNavigation = useCallback(() => {
        setShowPrompt(false)
        setLastLocation(null)
    }, [])

    const handleBlockedNavigation = useCallback(
        (nextLocation) => {
            if (nextLocation?.location?.search === "?session=expired" || nextLocation?.location?.search === "?redirect=true") {
                setLastLocation(nextLocation);
                setConfirmedNavigation(true);
                return false;
            }
            if (
                !confirmedNavigation &&
                nextLocation.location.pathname !== location.pathname
            ) {
                setShowPrompt(true)
                setLastLocation(nextLocation)
                return false
            }
            return true
        },
        [confirmedNavigation, location]
    )

    const confirmNavigation = useCallback(() => {
        handleAutoSave("CLEAR");
        setShowPrompt(false)
        setConfirmedNavigation(true)
    }, [])

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            navigate(lastLocation.location?.pathname)

            setConfirmedNavigation(false)
        }
    }, [confirmedNavigation, lastLocation])

    useBlocker(handleBlockedNavigation, when)

    return [showPrompt, confirmNavigation, cancelNavigation]
}