import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Card, CardBody, CardFooter, CardLink, CardText, CardTitle, Col, Row } from "reactstrap";
import Button from "../../../components/Button/Button";
import Drawer from "../../../components/Drawer";
import { getUserProfile } from "../../../helpers/functions_helper";
import { removeTags, renderRowHTML, subString } from "../../../utils/utils";
import { getNewsFeedList } from "./helper";
import LikeDislike from "./LikeDislike";
import NewsFeedForm from "./NewsFeedForm";
import './style.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NoDataFound from "../../../components/NoDataFound";

const NewsFeedWidget = () => {
    const { t } = useTranslation();
    const userProfile = getUserProfile('news-feed');
    const customeSlider = useRef(null);

    const settings = {
        dots: true,
        infinite: false,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 10000,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
        ]
    };

    const [newsFeeds, setNewsFeeds] = useState<Array<any>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [open, setOpen] = useState<boolean>(false);

    const getNewsFeeds = async () => {
        const result = await getNewsFeedList();
        if (result) {
            setNewsFeeds(result?.data?.data);
        }
        setLoading(false);
    }

    const handleSave = () => {
        setLoading(true);
        setOpen(false);
    }

    const gotoNext = () => {
        //@ts-ignore
        customeSlider?.current.slickNext()
    }

    const gotoPrev = () => {
        //@ts-ignore
        customeSlider?.current.slickPrev()
    }

    useEffect(() => {
        loading && getNewsFeeds();
    }, [loading])

    return (
        <>

            <Row>
                <div className="d-sm-flex flex-wrap flex-end mb-2">
                    <div className="card-title">{t("News Feed")}</div>
                    <div className="ms-auto  gap-2 right-controls ">
                        {userProfile.CAN_CREATE ? <><Button size="sm" color="light" onClick={() => setOpen(true)}>{t("Add News Feed")}</Button> &nbsp; </> : null}
                        {newsFeeds.length > 0 &&
                            <>
                                <Link className="btn btn-light btn-sm" to={'/news-feed/all'}>{t("View All")}</Link>

                                &nbsp;&nbsp;&nbsp;
                                <Button size="sm" color="light" onClick={() => gotoPrev()}><span className="align-middle material-symbols-outlined fw-light fs-4">arrow_back</span></Button>
                                &nbsp;
                                <Button size="sm" color="light" onClick={() => gotoNext()}><span className="align-middle material-symbols-outlined fw-light fs-4">arrow_forward</span></Button>
                            </>
                        }
                    </div>
                </div>
                <div style={{ padding: '0px' }}>
                    <Slider {...settings} ref={customeSlider}>
                        {loading ? [1, 2, 3].map((i: number) => (
                            <NewsFeedThumbnailSkeleton key={i} />
                        )) : (newsFeeds.length > 0 ? newsFeeds.map((newsFeed: NewsFeedType) => (
                            <Card className="news-feed-thnumb" key={newsFeed.id}>
                                <div className="news-feed-image-wrapper">
                                    {newsFeed?.attachment && <img src={newsFeed?.attachment} className="img-fluid card-img-top" />}
                                </div>
                                <CardBody>
                                    <CardTitle title={newsFeed?.news_feed_title}>{subString(removeTags(newsFeed?.news_feed_title), 27)}</CardTitle>
                                    <CardText style={{ minHeight: '180px' }}>{renderRowHTML(subString(removeTags(newsFeed.description), 180), '')}</CardText>
                                </CardBody>
                                <CardFooter className="newsfeed-footer" style={{ background: '#fff', borderRadius: '4px' }}>
                                    <div className="d-sm-flex flex-wrap flex-end mb-2">
                                        <CardLink className="btn btn-light btn-rounded btn-sm"><Link to={`/news-feed/view/${newsFeed?.id}/${newsFeed?.news_feed_id}`}>{t("Read More")}</Link></CardLink>
                                        <div className="ms-auto">
                                            <LikeDislike
                                                moduleData={{
                                                    module_app_id: 13,
                                                    module_main_id: newsFeed?.id,
                                                    module_sub_id: newsFeed?.news_feed_id,
                                                    section_id: 1,
                                                    liked: newsFeed?.liked,
                                                    disliked: newsFeed?.disliked,
                                                }}
                                            />
                                        </div>
                                    </div>
                                </CardFooter>
                            </Card>
                        )) : <div className="news-feed-thnumb align-box-middle mb-3"><NoDataFound icon="newspaper" message="You have no news feed to view!" /></div>)}
                    </Slider>
                </div>
            </Row>
            <Drawer
                title="News Feed"
                open={open}
                onClose={() => setOpen(false)}
                fullWidth
            >
                <NewsFeedForm onSave={handleSave} onCancel={() => setOpen(false)} />
            </Drawer>
        </>
    )

}

export default NewsFeedWidget;

const NewsFeedThumbnailSkeleton = () => {
    return (
        <Col>
            <Card className="news-feed-thnumb">
                <div className="news-feed-image-wrapper">

                </div>
                <CardBody>
                    <CardTitle><span className="placeholder col-12"></span></CardTitle>
                    <CardText className="text-muted">
                        <span className="placeholder col-12"></span>
                        <span className="placeholder col-12"></span>
                    </CardText>
                    <CardLink><span className="placeholder col-4"></span></CardLink>
                </CardBody>
            </Card>
        </Col>
    )
}