import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEnglish from "./locales/English/translation.json";
import translationFrench from "./locales/French/translation.json";
import translationDutch from "./locales/Dutch/translation.json";
const resources = {
  English: {
    translation: translationEnglish,
  },
  French: {
    translation: translationFrench,
  },
  Dutch: {
    translation: translationDutch,
  },
};

const language = localStorage.getItem("I18N_LANGUAGE");
if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "English");
  localStorage.setItem("LANGUAGE_CODE", "en");
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem("I18N_LANGUAGE") || "English",
    fallbackLng: "English",
    keySeparator: false,
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
