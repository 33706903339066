/* COMPANY */
export const GET_QUESTIONNAIRE_USER_DATA = "GET_QUESTIONNAIRE_USER_DATA"
export const GET_QUESTIONNAIRE_USER_DATA_SUCCESS = "GET_QUESTIONNAIRE_USER_DATA_SUCCESS"
export const GET_QUESTIONNAIRE_USER_DATA_FAIL = "GET_QUESTIONNAIRE_USER_DATA_FAIL"

/* COMPANY DETAIL*/
export const GET_QUESTIONNAIRE_USER_DATA_DETAIL = "GET_QUESTIONNAIRE_USER_DATA_DETAIL"
export const GET_QUESTIONNAIRE_USER_DATA_DETAIL_SUCCESS = "GET_QUESTIONNAIRE_USER_DATA_DETAIL_SUCCESS"
export const GET_QUESTIONNAIRE_USER_DATA_DETAIL_FAIL = "GET_QUESTIONNAIRE_USER_DATA_DETAIL_FAIL"

/**
 * add company
 */
export const ADD_NEW_QUESTIONNAIRE_USER_DATA = "ADD_NEW_QUESTIONNAIRE_USER_DATA"
export const ADD_QUESTIONNAIRE_USER_DATA_SUCCESS = "ADD_QUESTIONNAIRE_USER_DATA_SUCCESS"
export const ADD_QUESTIONNAIRE_USER_DATA_FAIL = "ADD_QUESTIONNAIRE_USER_DATA_FAIL"


export const SUBMIT_QUESTIONNAIRE_USER_DATA = "SUBMIT_QUESTIONNAIRE_USER_DATA"
export const SUBMIT_QUESTIONNAIRE_USER_DATA_SUCCESS = "SUBMIT_QUESTIONNAIRE_USER_DATA_SUCCESS"
export const SUBMIT_QUESTIONNAIRE_USER_DATA_FAIL = "SUBMIT_QUESTIONNAIRE_USER_DATA_FAIL"


export const INCOMPLETE_QUESTIONNAIRE_USER_DATA = "INCOMPLETE_QUESTIONNAIRE_USER_DATA"
export const INCOMPLETE_QUESTIONNAIRE_USER_DATA_SUCCESS = "INCOMPLETE_QUESTIONNAIRE_USER_DATA_SUCCESS"
export const INCOMPLETE_QUESTIONNAIRE_USER_DATA_FAIL = "INCOMPLETE_QUESTIONNAIRE_USER_DATA_FAIL"


export const UPDATE_QUESTIONNAIRE_USER_STATUS = "UPDATE_QUESTIONNAIRE_USER_STATUS"
export const UPDATE_QUESTIONNAIRE_USER_STATUS_SUCCESS = "UPDATE_QUESTIONNAIRE_USER_STATUS_SUCCESS"
export const UPDATE_QUESTIONNAIRE_USER_STATUS_FAIL = "UPDATE_QUESTIONNAIRE_USER_STATUS_FAIL"

/**
 * Edit company
 */
export const UPDATE_QUESTIONNAIRE_USER_DATA = "UPDATE_QUESTIONNAIRE_USER_DATA"
export const UPDATE_QUESTIONNAIRE_USER_DATA_SUCCESS = "UPDATE_QUESTIONNAIRE_USER_DATA_SUCCESS"
export const UPDATE_QUESTIONNAIRE_USER_DATA_FAIL = "UPDATE_QUESTIONNAIRE_USER_DATA_FAIL"

/**
 * Delete company
 */
export const DELETE_QUESTIONNAIRE_USER_DATA = "DELETE_QUESTIONNAIRE_USER_DATA"
export const DELETE_QUESTIONNAIRE_USER_DATA_SUCCESS = "DELETE_QUESTIONNAIRE_USER_DATA_SUCCESS"
export const DELETE_QUESTIONNAIRE_USER_DATA_FAIL = "DELETE_QUESTIONNAIRE_USER_DATA_FAIL"