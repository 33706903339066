import { takeEvery, call, put } from "redux-saga/effects";
import tasksService from "../../services/tasks.service";
import { AnyAction } from "redux";

// Tasks Redux States
import {
  GET_TASK,
  GET_TASKS_LIST,
  GET_ARCHIVE_TASKS_LIST,
  CREATE_TASK,
  UPDATE_TASK,
  DELETE_TASK,
} from "./actionTypes";

import { 
    getTaskSuccess, 
    getTaskError,
    
    getTasksListSuccess,
    getTasksListError,

    getArchiveTasksListSuccess,
    getArchiveTasksListError,

    createTaskSuccess,
    createTaskError,

    updateTaskSuccess,
    updateTaskError,

    deleteTaskSuccess,
    deleteTaskError,
} from "./actions";

function* getTask({ payload: { tasks, history } }: AnyAction): any {
  try {
    const response = yield call(tasksService.getTask, tasks);
    if (response.data && response.data.status === "success") {
      yield put(getTaskSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getTaskError(response.data));
    } else {
      yield put(getTaskError(response));
    }
  } catch (error: any) {
    yield put(getTaskError(error));
  }
}

function* getTasksList({ payload: { query, history } }: AnyAction): any {
  try {
    const response = yield call(tasksService.getTasksList, query);
    if (response.data && response.data.status === "success") {
      yield put(getTasksListSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getTasksListError(response.data));
    } else {
      yield put(getTasksListError(response));
    }
  } catch (error: any) {
    yield put(getTasksListError(error));
  }
}


function* getArchiveTasksList({ payload: { query, history } }: AnyAction): any {
  try {
    const response = yield call(tasksService.getTasksList, query);
    if (response.data && response.data.status === "success") {
      yield put(getArchiveTasksListSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getArchiveTasksListError(response.data));
    } else {
      yield put(getArchiveTasksListError(response));
    }
  } catch (error: any) {
    yield put(getArchiveTasksListError(error));
  }
}

function* createTask({ payload: { tasks, history } }: AnyAction): any {
  try {
    const response = yield call(tasksService.createTask, tasks);
    if (response.data && response.data.status === "success") {
      yield put(createTaskSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(createTaskError(response.data));
    } else {
      yield put(createTaskError(response));
    }
  } catch (error: any) {
    yield put(createTaskError(error));
  }
}

function* updateTask({ payload: { tasks, history } }: AnyAction): any {
  try {
    const response = yield call(tasksService.updateTask, tasks);
    if (response.data && response.data.status === "success") {
      yield put(updateTaskSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(updateTaskError(response.data));
    } else {
      yield put(updateTaskError(response));
    }
  } catch (error: any) {
    yield put(updateTaskError(error));
  }
}

function* deleteTask({ payload: { tasks, history } }: AnyAction): any {
  try {
    const response = yield call(tasksService.deleteTask, tasks);
    if (response.data && response.data.status === "success") {
      yield put(deleteTaskSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(deleteTaskError(response.data));
    } else {
      yield put(deleteTaskError(response));
    }
  } catch (error: any) {
    yield put(deleteTaskError(error));
  }
}

function* tasksSaga(): any {
  yield takeEvery(GET_TASK, getTask);
  yield takeEvery(GET_TASKS_LIST, getTasksList);
  yield takeEvery(GET_ARCHIVE_TASKS_LIST, getArchiveTasksList);
  yield takeEvery(CREATE_TASK, createTask);
  yield takeEvery(UPDATE_TASK, updateTask);
  yield takeEvery(DELETE_TASK, deleteTask);
}

export default tasksSaga;
