import React from "react"
import { Link } from "react-router-dom"
import {
  Card, CardBody, Col, DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap"
import './style.scss';
import TaskAssignee from "./TaskAssignee";
import TaskPriority from "./TaskPriority";
import { useTranslation } from "react-i18next";
import moment from "moment";


function renderHeaderHTML(contain: any) {
  return <span className="text-dark font-size-13" dangerouslySetInnerHTML={{ __html: contain }} />;
}

const CardTaskBox = (props: any) => {

  const { data, changeFlag, removeFlag, handleArchive } = props;
  const { t } = useTranslation();

  const getTaskDetails = (id: string, task_id: string) => {
    props.getTaskByID(id, task_id);
  }

  const addFlag = (task: any) => {
    changeFlag(task);
  }

  const updateFlag = (task: any) => {
    removeFlag(task)
  }

  return (
    <React.Fragment>
      {data.filter &&
        <Card className={`task-box ${data?.is_sub_task_filter && 'border-warning'} ${(data?.is_sub_task_filter && !data.is_sub_task) && 'border-4'} ${data?.flag && 'flag-bg'}`}>
          <CardBody className="borad-width" style={{ padding: '10px' }}>
            <UncontrolledDropdown className="float-end" >
              <DropdownToggle className="arrow-none" tag="a">
              {((parseInt(data?.status) === 6 || (parseInt(data?.status) === 8)) && !data.is_sub_task) ? null:         
                <span className="align-middle material-symbols-outlined fw-light fs-3 text-muted">more_horiz</span>
              }
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                {
                  parseInt(data?.status) < 6 && (
                    data.flag === 1 ?
                      <Link to="#" onClick={() => updateFlag(data)}>
                        <DropdownItem><span className="align-middle material-symbols-outlined fw-light fs-4 me-1 text-danger">flag</span>{` ${t("Remove flag")}`}</DropdownItem>
                      </Link> :
                      <Link to="#" onClick={() => addFlag(data)}>
                        <DropdownItem><span className="align-middle material-symbols-outlined fw-light fs-4 me-1 text-danger">flag</span>{` ${t("Add flag")}`}</DropdownItem>
                      </Link>)

                }
                {
                  parseInt(data?.status) < 6 && (
                    data.is_sub_task ?
                      <Link to={"/tasks/subtask-details/" + data.pid + "/" + data.subtask_id}>
                        <DropdownItem><span className="align-middle material-symbols-outlined fw-light fs-4 me-1 text-muted">edit</span>{` ${t("Edit")}`}</DropdownItem>
                      </Link>
                      :
                      <Link to={"/tasks/task-details/" + data.pid + "/" + data.task_id}>
                        <DropdownItem><span className="align-middle material-symbols-outlined fw-light fs-4 me-1 text-muted">edit</span>{` ${t("Edit")}`}</DropdownItem>
                      </Link>
                  )
                }

                {(parseInt(data?.status) === 7 || parseInt(data?.status) === 9) && (
                  <Link to="#" onClick={() => handleArchive(data)}>
                    <DropdownItem><span className="align-middle material-symbols-outlined fw-light fs-4 me-1 text-danger">archive</span>{` ${t("Archive")}`}</DropdownItem>
                  </Link>
                )}

                {
                  data.is_sub_task &&
                  <Link to={"/tasks/task-details/" + data.master_id + "/" + data.master_task_id}>
                    <DropdownItem><span className="align-middle material-symbols-outlined fw-light fs-4 me-1 text-muted">task</span>{` ${t("Parent Task")}`}</DropdownItem>
                  </Link>
                }

              </DropdownMenu>
            </UncontrolledDropdown>
            <div className="pb-4 col-11">
              {
                //TODO task details modal open when click task title
                // data.is_sub_task ?
                // <Link to="#"  onClick={() =>getTaskDetails(data.pid,data.subtask_id)}>
                //   <div className="text-dark font-size-13 text-truncate">
                //     {renderHeaderHTML(data.title_html)}
                //   </div>
                //   </Link>
                //   :
                //   <Link to="#" onClick={() =>getTaskDetails(data.pid,data.task_id)}>
                //   <div className="text-dark font-size-13 text-truncate" >
                //     {renderHeaderHTML(data.title_html)}
                //   </div>
                //   </Link>

                data.is_sub_task ?
                  <Link to={"/tasks/subtask-details/" + data.pid + "/" + data.subtask_id}>
                    <div className="text-dark font-size-13 text-truncate">
                      {renderHeaderHTML(data.title)}
                    </div>
                  </Link> :
                  <Link to={"/tasks/task-details/" + data.pid + "/" + data.task_id}>
                    <div className="text-dark font-size-13 text-truncate">
                      {renderHeaderHTML(data.title)}
                    </div>
                  </Link>
              }

            </div>
            <Row className="align-items-center ">
              <Col className="col-6">
                <Row>
                  <Col className="col-12 d-flex align-items-center">
                    <div className="badge" style={{ 'backgroundColor': data?.type_color_code ? data?.type_color_code : '#FF8FB1' }}>{data?.type_fee_code}</div>
                    &nbsp;
                    {
                      data.flag === 1 && <span className="align-middle material-symbols-outlined fw-light fs-4 text-danger">flag</span>
                    }&nbsp;{data.sensitive === 1 && <span className="align-middle material-symbols-outlined fw-light fs-4 text-danger">star</span>}
                    <div style={{ 'paddingLeft': '5px' }}>
                      {" "}<TaskPriority value={data?.priority} tip={data?.priority_value} />
                    </div>
                  </Col>
                  <Col className="col-12">
                    <div className="text-primary">
                      {data.is_sub_task && <span className="align-middle material-symbols-outlined fw-light fs-4 bg-info text-white rounded">done_all</span>}
                      {data.is_sub_task === false && <span className="align-middle material-symbols-outlined fw-light fs-4 bg-info text-white rounded">check</span>}
                      {" "}<span className="align-middle material-symbols-outlined fw-light fs-4 me-1">calendar_month</span>{" " + moment(data?.due_date).format('DD/MM/yy')}
                    </div>
                  </Col>

                </Row>
              </Col>

              <Col className="col-6">
                <Row>
                  <Col className="col-12 d-flex justify-content-center align-items-center p-0">
                    {
                      data?.sub_task_list_count > 0 ?
                        <div>
                          <Link to="#" onClick={() => props.onClickSubTaskList(data.pid)} data-for={`sub_task_list_` + data.pid}><span className="align-middle material-symbols-outlined fw-light fs-4">link</span>({data?.sub_task_list_count})</Link>
                        </div>
                        : null}
                    <div className="text-end">
                      {data.preparer_value !== null &&
                        <TaskAssignee assignee={data.preparer_value} user="p" status={data?.status} />}
                      {data.reviewer_value !== null &&
                        <TaskAssignee assignee={data.reviewer_value} user="r" status={data?.status} />}
                      {(data.file_manager_value !== null && data.file_manager_last_name !== null) &&
                        <TaskAssignee assignee={data.file_manager_value} user="fm" status={data?.status} />}
                      {data.partner_value !== null &&
                        <TaskAssignee assignee={data.partner_value} user="pr" status={data?.status} />}
                      {data.assistant_administrative_value !== null &&
                        <TaskAssignee assignee={data.assistant_administrative_value} user="aa" status={data?.status} />}
                      {data.assignee_value !== null &&
                        <TaskAssignee assignee={data.assignee_value} user="as" status={data?.status} />}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className="text-truncate">
                <div>
                  <span className="badge bg-primary">{data?.company != 0 ? data?.company_name : data?.contact_person_full_name}</span>{" "}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>}
    </React.Fragment>
  )
}

export default React.memo(CardTaskBox)
