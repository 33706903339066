import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody } from "reactstrap";
import saltedgeService from "../../services/saltedge.service";
import { toast } from "react-toastify";

const CreateLeads = (props: any) => {
  const { loading } = props;
  const { t } = useTranslation();
  const toastId = React.useRef(null) as any;

  const createLeads = async () => {
    toastId.current = toast(t("Creating lead..."), {
      autoClose: false,
      isLoading: true,
    });

    try {
      const res = (await saltedgeService.createLeads()) as any;
      if (res?.data?.status === "success") {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        props?.onSuccess(res?.data?.lead_data);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res?.data?.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
      }
    } catch (err: any) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="nodata d-flex align-items-center justify-content-center" style={{minHeight: "50vh"}}>
        <div>
          <div className="text-center mt-3 mb-3">
            <span
              className="align-middle material-symbols-outlined text-muted fw-lighter"
              style={{ fontSize: "80px" }}
            >
              {loading ? "person_search" : "person"}
            </span>
          </div>

          <div className="mt-1 mb-3 text-center">
            <h5 className="text-truncate text-muted fw-lighter">
              {loading
                ? t("Finding Connection lead or Customer...")
                : t("Connection lead or Customer not found!")}
            </h5>
          </div>

          {!loading && (
            <div className="mt-3 mb-3 text-center">
              <Button color="primary" className="btn btn-primary btn-label" onClick={createLeads}>
                <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                  person_add
                </span>
                {t("Create lead")}
              </Button>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateLeads;
