export const renderRowHTML = (rowContent: any, row?: any) => {
  return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
};

export const removeTags = (str: any) => {
  if (str === null || str === "") return str;
  else str = str.toString();
  return str.replace(/(<([^>]+)>)/gi, "");
};

export const subString = (str: string, length: number) => {
  if (str === null || str === "") return str;
  else if (str.length <= length) return str;
  else str = str.toString();
  return str.substr(0, length) + "...";
};

export const serialize = (obj: any) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p) && obj[p]) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};

export const timeToSeconds = (time: string) => {
  if (time === null || time === "" || typeof time === "undefined") {
    return 0;
  }
  const [hoursPart, minutesPart, secondsPart] = time.split(":");
  return Number(hoursPart) * 3600 + Number(minutesPart) * 60 + Number(secondsPart);
};
export const secondsToTime = (sec: number) => {
  let d = Number(sec);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "0 hr";
  var mDisplay = m > 0 ? m + (m == 1 ? " min " : " mins ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
  return hDisplay + mDisplay;
};

export const secondsToHours = (sec: number) => {
  const hrs = Math.round(sec / 3600);
  return `${hrs} ${hrs > 1 ? " hours" : " hour"}`;
};

export const masterValueWithColorCode = (master_value: string, color_code: string) => {
  return `<span class="badge rounded-pill badge_custome" style="color:#ffffff; background-color:${color_code}">${master_value}</span>`;
};
export const yesNoTag = (value: number, label: string) => {
  return `<span class="badge rounded-pill ${
    value === 1 ? "bg-warning" : "bg-danger"
  } badge_custome"> ${label} </span>`;
};
