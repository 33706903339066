import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { noData } from '../../../utils/data';
import LoadingSpinner from '../../../components/Common/Loader';
import GraphFilters from '../../../components/Chart/Filters';
import { getUserProfile, graph_i18 } from '../../../helpers/functions_helper';
import taskService from '../../../services/tasks.service';
import { serialize } from '../../../utils/utils';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import gfilters from "../filter";
import Chart from "../../../components/Chart";
import { currentMonth, currentYear } from '../../../common/staticdata';
interface WorkAllocationProps extends CartProps {
  filters?: any;
}

const WorkAllocation: FC<WorkAllocationProps> = ({ hideHeading, filters = null }) => {

  const { t } = useTranslation();

  const userObj = getUserProfile('all-tasks');
  const navigate = useNavigate();
  const [selectedID, setSelectedID] = useState<any>({ user_id: null, status_id: null, event: null });
  const graphRef = useRef(null);

  const inititalFilter = {
    week: null,
    quarter: null,
    month: currentMonth,
    year: currentYear,
    user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_ID : null,
    user_name: null
  };

  const viewTaskList = (i: any) => {
    if (i) {
      if (i.user_id && i.status_id) {
        let _filter = {
          type: 'work-allocation',
          data: i,
          label: 'work allocation'
        }
        gfilters.setFilter(_filter);
        navigate('../tasks/all-tasks');
      }
      setSelectedID({ user_id: null, status_id: null, event: null });
    }
  }

  useEffect(() => {
    if (selectedID?.event === 'click') {
      viewTaskList(selectedID);
    }
  }, [selectedID])

  const [loading, setLoading] = useState<boolean>(true);
  const [query, setQuery] = useState<string | null>(serialize(inititalFilter));
  const [filter, setFilter] = useState<any>(inititalFilter);

  const [data, setData] = useState<any>({
    series: [{
      name: t('Tasks'),
      data: []
    }],
    options: {
      chart: {
        type: 'bar',
        stacked: true,
        width: "100%",
        height: 380,
        // events: {
        //   dataPointSelection(event:any, chartContext:any, config:any) {
        //     setSelectedID({ user_id: config?.w?.config?.series[config.seriesIndex]?.user_id[config.dataPointIndex],status_id:config?.w?.config?.series[config?.seriesIndex]?.status_id, event: 'click' });
        //   }
        // },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            orientation: 'vertical',
            position: 'center' // bottom/center/top
          }
        },
      },
      // dataLabels: {
      //   enabled: true,
      //   //enabledOnSeries: [0],
      //   textAnchor: "center",
      //   formatter: function (_val: any, opt: any) {
      //     console.log(opt.w.config.series[0].data[opt.dataPointIndex], opt.dataPointIndex)
      //     // let series = opt.w.config.series?.data
      //     // let idx = opt.dataPointIndex
      //     // const total = series.reduce((total:any, self:any) => total + self.data[idx], 0)
      //     return opt.w.config.series[0].data[opt.dataPointIndex]===0 ? '0':opt.w.config.series[0].data[opt.dataPointIndex];
      //   },
      //   style: {
      //     colors: ["#000"]
      //   }
      // },
      xaxis: {
        categories: [],
        labels: {
          show: true,
          trim: true,
          rotate: -45,
        }
      },
      legend: {
        position: 'bottom',
        offsetY: 5,
        formatter: function (seriesName: any) {
          return [t(seriesName)]
        }
      },
      fill: {
        opacity: 1
      },
    },

  })

  const getChartData = async () => {
    try {
      setLoading(true);
      const result = await taskService.getTaskAllocationList(query);
      const local_i18n = graph_i18();
      if (result.data.status === 'success') {
        setData((data: any) => ({
          ...data,
          series: [{
            name: t('Tasks'),
            data: result?.data?.data[0] || []
          }],
          options: {
            ...data.options,
            xaxis: {
              categories: result?.data?.data[1] || [],
              labels: {
                show: true,
                trim: true,
                rotate: -45,
              }
            },
            chart: {
              ...data.options.chart,
              locales: [local_i18n],
              defaultLocale: local_i18n?.name,
            }
          }

        }));
        setLoading(false);
      } else {
        setLoading(false);
      }

    } catch (error: any) {
      setLoading(false);
      toast(error);
    }
  }

  const clearFilter = () => {
    setFilter(inititalFilter);
  }

  useEffect(() => {
    loading && getChartData();
  }, [query, loading])

  useEffect(() => {
    setQuery(serialize(filter));
    setLoading(true);
  }, [filter])

  useEffect(() => {
    filters && setFilter(filters)
  }, [filters])

  useEffect(() => {
    setLoading(false);
    getChartData();
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  return (
    <>
      <div className="d-sm-flex flex-wrap">
        <h4 className="card-title mb-4">{!hideHeading && t("Work Allocation")}</h4>&nbsp;
        <div className="ms-auto">
          <GraphFilters
            filter={filter}
            setFilter={setFilter}
            clearFilter={clearFilter}
            enableUser={true}
            enableCompany={true}
            slug='all-tasks'
          />
        </div>
      </div>
      <div className="clearfix"></div>
      {loading ? <LoadingSpinner height={380} /> : <div style={{ height: '100%' }}><Chart ref={graphRef} options={data.options} series={data.series[0].data} type="bar" height={350} /></div>}
    </>
  )
}

export default memo(WorkAllocation);