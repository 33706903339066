import React from "react";
import { useParams } from "react-router-dom";
import { Container, Nav, NavItem } from "reactstrap";
import Signature from "./Signature";
// Import Breadcrumb
import Breadcrumbs from "../../components/Layout/Breadcrumb";

import ScrollUp from "../../components/ScrollUpDown/ScrollUp";
import ScrollDown from "../../components/ScrollUpDown/ScrollDown";

import { useTranslation } from "react-i18next";

import NavItems from "./NavItems";

import { Row, Col, Card, CardBody } from "reactstrap";
const EditUserSignatures = (props: any) => {
  const { t } = useTranslation();
  document.title = `${t("User details")} - ${t("Signature")} | ${process.env.REACT_APP_NAME}`;
  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "/users/all-users",
      label: t("Users"),
      active: false,
    },
    {
      url: "#",
      label: t("Update user"),
      active: true,
    },
  ];
  
  const { id, user_id } = useParams();

  return (
    <React.Fragment>
      <ScrollUp />
      <ScrollDown />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={t("Update User")}
            breadcrumbItems={breadcrumbItems}
          />

          <NavItems id={id} user_id={user_id} />
          <Signature id={id} user_id={user_id} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditUserSignatures;
