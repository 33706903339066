import React, { useEffect, useState } from "react";
import DataTable from "../../../components/Common/DataTable";
import { getDataTableQuery } from "../../../helpers/functions_helper";

//redux
import { useDispatch, useSelector } from "react-redux";
import { QuestionnaireStatusAction } from "../../../store/actions";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  CardTitle,
  UncontrolledAlert,
  Container,
} from "reactstrap";

import { Link } from "react-router-dom";
import DeleteModal from "../../../components/Common/DeleteModal";
import Breadcrumbs from "../../../components/Layout/Breadcrumb";

import { toast } from "react-toastify";

// Import Master Modal
import StatusModal from "./Form";
import { useTranslation } from "react-i18next";

const QuestionnaireStatus = (props: any) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "#",
      label: t("Questionnaire status"),
      active: true,
    },
  ];
  document.title = `${t("Questionnaire")} | ${t("All Questionnaire status")} ${process.env.REACT_APP_NAME}`;

  // UserProfile ---------------------------------------------
  const [USER_ID, setUSER_ID] = useState();
  const [CAN_CREATE, setCAN_CREATE] = useState();
  const [CAN_EDIT, setCAN_EDIT] = useState();
  const [CAN_DELETE, setCAN_DELETE] = useState();

  // DataTable ----------------------------------------------
  function renderHeaderHTML(column: any, colIndex: any) {
    return <span dangerouslySetInnerHTML={{ __html: column.text }} />;
  }
  function renderRowHTML(rowContent: any, row: any) {
    return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
  }

  const columns = [
    {
      dataField: "DT_RowIndex",
      text: t("Sr. No."),
      sort: true,
      search: false,
    },
    {
      dataField: "questionnaire_status_value_html",
      text: t("Status"),
      sort: true,
      search: true,
      formatter: renderRowHTML,
    },
    {
      dataField: "action",
      text: t("Action"),
      sort: false,
      search: false,
      formatter: (rowContent: any, row: any): any => {
        return (
          <div className="d-flex gap-2 text-end">
            <Link
              className="btn btn-pill btn-outline-primary btn-sm"
              to="#"
              onClick={() => onClickEdit(row)}
            >
              <i className="fa-regular fa-pen-to-square"></i> Edit
            </Link>
            {/* <Link
              className="btn btn-pill btn-outline-danger btn-sm"
              to="#"
              onClick={() => onClickDelete(row)}
            >
              <i className="fa-regular fa-trash-can"></i> Delete
            </Link> */}
          </div>
        );
      },
    },
  ];

  const { getUserProfile } = useSelector((state: any) => ({
    getUserProfile: state.Users.GET_USER_PROFILE_SUCCESS,
  }));
  useEffect(() => {
    if (getUserProfile) {
      setUSER_ID(getUserProfile.data[0].id);
      setCAN_CREATE(getUserProfile.data[0].access_role[0].role_is_create);
      setCAN_EDIT(getUserProfile.data[0].access_role[0].role_is_edit);
      setCAN_DELETE(getUserProfile.data[0].access_role[0].role_is_delete);
    }
  }, [getUserProfile]);

  //get department list
  const [loadingStatusListData, setLoadingStatusListData] = useState(true);
  const [datatableOptions, setDatatableOptions] = useState({
    draw: 1,
    page: 1,
    recordsTotal: 0,
    recordsFiltered: 0,
    sizePerPage: 20,
    searchText: "",
    sortField: "id",
    sortOrder: "desc",
  });

  useEffect(() => {
    if (loadingStatusListData === true) {
      dispatch(
        QuestionnaireStatusAction.getQuestionnaireStatuses(
          getDataTableQuery(datatableOptions, columns),
          props.history
        )
      );
    }
  }, [dispatch, loadingStatusListData]);

  const [statusListData, setStatusListData] = useState([]);



  // get store data
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  let data = useSelector((state: any) => ({
    success: state.QuestionnaireStatus.success,
    statuslist: state.QuestionnaireStatus.statuslist.data,
    error: state.QuestionnaireStatus.error,
    isModel: state.QuestionnaireStatus.isModel,
    loading: state.QuestionnaireStatus.loading,
  }));

  useEffect(() => {
    if (data.statuslist?.data) {
      setStatusListData(data.statuslist.data);

      const updatedDatatableOptions = {
        ...datatableOptions,
        recordsTotal: data.statuslist.recordsTotal,
        recordsFiltered: data.statuslist.recordsFiltered,
      };
      setDatatableOptions(updatedDatatableOptions);
      setLoadingStatusListData(false);
    }
  }, [data.statuslist]);



  const onTableChange = (type: any, newState: any) => {
    let draw = datatableOptions.draw + 1;
    let page = 1;
    if (type == "search") {
      page = 1;
    } else {
      page = newState.page;
    }

    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: draw,
      page: page,
      sizePerPage: newState.sizePerPage,
      searchText: newState.searchText,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
      scrollY: "300px",
      scrollX: true,
      scrollCollapse: true,
      fixedColumns: {
        left: 1,
        right: 1
      }
    };
    setDatatableOptions(updatedDatatableOptions);
    setLoadingStatusListData(true);
  };
  const clearFilter = () => {
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: 1,
      page: 1,
      sizePerPage: 20,
      searchText: "",
      sortField: "id",
      sortOrder: "desc",
    };
    setDatatableOptions(updatedDatatableOptions);
    setLoadingStatusListData(true);
  };
  // Datatable -------------------------------------------------

  // Master Form --------------------------------------------------------
  const [statusData, setStatusData] = useState({});
  const [masterFormModal, setMasterFormModal] = useState(false);
  const [masterFormModalTitle, setMasterFormModalTitle] =
    useState(t("Create Questionanire status"));

  const onClickModal = () => {
    setStatusData("");
    setMasterFormModalTitle(t("Create Questionnaire status"));
    setMasterFormModal(true);
  };

  const onClickEdit = (row: any) => {
    setStatusData(row);
    setMasterFormModalTitle(t("Update Questionnaire status"));
    setMasterFormModal(true);
  };

  const handleSubmit = (values: any) => {
    if (values.id && values.questionnaire_status_id) {
      dispatch(
        QuestionnaireStatusAction.updateQuestionnaireStatus(values, props.history)
      );
    } else {
      dispatch(
        QuestionnaireStatusAction.addNewQuestionnaireStatus(values, props.history)
      );
    }
  };

  // Master Form --------------------------------------------------------

  // Delete Form --------------------------------------------------------
  const [deleteData, setDeleteData] = useState({
    id: "",
    questionnaire_status_id: "",
  });
  const [recordName, setRecordName] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (row: any) => {
    const deleteRowData = {
      ...deleteData,
      id: row.id,
      questionnaire_status_id: row.questionnaire_status_id,
    };
    setDeleteData(deleteRowData);
    setRecordName(row.questionnaire_status_value);
    setDeleteModal(true);
  };

  const handleDeleteData = () => {
    setDeleteModal(false);
    setLoadingStatusListData(true);
    dispatch(
      QuestionnaireStatusAction.deleteQuestionnaireStatus(deleteData, props.history)
    );
  };
  // Delete Form --------------------------------------------------------


  useEffect(() => {
    if (data.success !== "") {
      toast(data.success, { type: toast.TYPE.SUCCESS })
      setMasterFormModal(false);
      setDeleteModal(false);
      setLoadingStatusListData(true);
    }
    if (data.error !== "") {
      toast(data.error, { type: toast.TYPE.ERROR })
    }
  }, [data.success, data.error, dispatch]);


  useEffect(()=>{
    setLoadingStatusListData(true);
    setStatusListData([]);
  },[localStorage.getItem("I18N_LANGUAGE")])

  return (
    <React.Fragment>
      {statusData && (
        <DeleteModal
          recordName={recordName}
          modalTitle={t("Questionnaire status")}
          show={deleteModal}
          onDeleteClick={handleDeleteData}
          onCloseClick={() => setDeleteModal(false)}
          loading={false}
        />
      )}

      {masterFormModal && (
        <StatusModal
          modalTitle={masterFormModalTitle}
          show={masterFormModal}
          loading={data.loading}
          statusData={statusData}
          onSubmitClick={handleSubmit}
          onCloseClick={() => setMasterFormModal(false)}
          errorMsg={error}
        />
      )}

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={t("Questionnaire")}
            breadcrumbItems={breadcrumbItems}
            endAdornment={
              CAN_CREATE ? (
                <Button
                  color="primary"
                  className="btn btn-primary btn-sm waves-light btn-label"
                  onClick={() => onClickModal()}
                >
                  <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">add</span>
                  {t("Create Questionnaire status")}
                </Button>
              ) : (
                ""
              )
            }
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardHeader className="bg-transparent border-bottom">
                  <div className="d-sm-flex flex-wrap">
                    <CardTitle className="card-title text-uppercase">
                      {t("Questionnaire status list")}
                    </CardTitle>
                  </div>
                </CardHeader>
                <CardBody>
                  <DataTable
                    columns={columns}
                    products={statusListData}
                    datatableOptions={datatableOptions}
                    filters={[]}
                    onTableChange={onTableChange}
                    loadingListData={loadingStatusListData}
                    clearFilter={clearFilter}
                    slug="all-questionnaires"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  );
};
export default QuestionnaireStatus;