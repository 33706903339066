import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import { Container, Row, Col, CardFooter, Button, Table } from "reactstrap";

import { initialValues } from "./helper";

import Header from "../../components/Report/Header";
import Footer from "../../components/Report/Footer";
import { useTranslation } from "react-i18next";

import PrivacyPolicyEnglish from "./English/PrivacyPolicy";
import PrivacyPolicyFrench from "./French/PrivacyPolicy";
import PrivacyPolicyDutch from "./Dutch/PrivacyPolicy";

const PrivacyPolicy = (props: any) => {
  const { t } = useTranslation();
  document.title = t("Privacy policy") + " | " + process.env.REACT_APP_NAME;

  let navigate = useNavigate();
  const location = useLocation();

  const onClickAccept = () => {
    const registerUser = localStorage.getItem("registerUser");
    if (registerUser) {
      let registerUserObj = JSON.parse(registerUser);
      registerUserObj.is_privacy_policy_accepted = true;
      localStorage.setItem("registerUser", JSON.stringify(registerUserObj));
    } else {
      localStorage.setItem("registerUser", JSON.stringify(initialValues));
    }

    const prevLocation = location.state as any;
    if (prevLocation) {
      const prevPath: any = prevLocation?.from?.pathname;
      return navigate(prevPath);
    } else {
      return navigate("/login");
    }
  };

  return (
    <React.Fragment>
      <div style={{ padding: "10px 8%" }}>
        <Container>
          <div className="invoicePage">
            <Header />
            <div className="invoiceBody">
              <Row>
                <Col className="col-12">
                  <div className="invoiceHeading1">
                    <span>{t("Privacy policy")}</span>
                  </div>
                </Col>
                <Col className="col-12">
                  {localStorage.getItem("I18N_LANGUAGE") === "English" && <PrivacyPolicyEnglish />}
                  {localStorage.getItem("I18N_LANGUAGE") === "French" && <PrivacyPolicyFrench />}
                  {localStorage.getItem("I18N_LANGUAGE") === "Dutch" && <PrivacyPolicyDutch />}
                </Col>
              </Row>
            </div>
            <CardFooter className="d-sm-flex flex-wrap bg-transparent border-top pt-2">
              <div className="d-flex gap-2"></div>
              <div className="ms-auto d-flex gap-2 justify-content-end">
                <Button
                  color="primary"
                  className="btn-block"
                  type="button"
                  onClick={() => onClickAccept()}
                >
                  {t("Accept & close")}
                </Button>
              </div>
            </CardFooter>
            <Footer />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PrivacyPolicy;
