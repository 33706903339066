import store from "../store";
import commonService from "../services/common.service";
import moment from "moment";
import { loginRequest } from "../authConfig";
import { msalInstance } from '../index'
//Date picker
import { english } from 'flatpickr/dist/l10n/default.js';
import { Dutch } from 'flatpickr/dist/l10n/nl.js';
import { French } from 'flatpickr/dist/l10n/fr.js';
//calender
import enLocale from "@fullcalendar/core/locales/en-au";
import nlLocale from "@fullcalendar/core/locales/nl";
import frLocale from "@fullcalendar/core/locales/fr";
import { useTranslation } from "react-i18next";


// Axios config
export const getAxiosConfig = () => {
  const LANGUAGE_CODE = localStorage.getItem("LANGUAGE_CODE");

  return {
    "Content-Type": "application/json",
    "Retry-After": 3600,
    "Access-Control-Allow-Origin": "*",
    "Accept-Language": LANGUAGE_CODE ? LANGUAGE_CODE : "en",
  };
};

// Axios config with Token
export const getAxiosConfigWithToken = () => {
  const AUTH_USER = localStorage.getItem("authUser");
  const LANGUAGE_CODE = localStorage.getItem("LANGUAGE_CODE");

  if (AUTH_USER) {
    const ACCESS_TOKEN = JSON.parse(AUTH_USER);
    return {
      "Content-Type": "application/json",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      "Accept-Language": LANGUAGE_CODE ? LANGUAGE_CODE : "en",
      Authorization: "Bearer " + ACCESS_TOKEN.accessToken,
    };
  } else {
    return {
      "Content-Type": "application/json",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      "Accept-Language": LANGUAGE_CODE ? LANGUAGE_CODE : "en",
      Authorization: "Bearer ",
    };
  }
};

// Axios config with Azure Token
export const getAxiosConfigWithAzureToken = async (token?: boolean) => {
  const account: any = msalInstance.getActiveAccount();
  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account
  })
  const ACCESS_TOKEN = response.accessToken || localStorage.getItem("accessTokenSSO");
  if (ACCESS_TOKEN) {
    return {
      "Retry-After": 3600,
      Authorization: "Bearer " + ACCESS_TOKEN,
    };
  } else {
    return {
      "Retry-After": 3600,
      Authorization: "Bearer ",
    };
  }
};

export const getAxiosConfigWithAzureTokenOnly = async (token?: boolean) => {

  try {
    const account: any = msalInstance.getActiveAccount();

    if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account
    });
    return response.accessToken || localStorage.getItem("accessTokenSSO");
  } catch (error) {
    console.log(error);
    return localStorage.getItem("accessTokenSSO");
  }

};


// Axios config File upload with Token
export const getAxiosConfigFileUploadWithToken = () => {
  const AUTH_USER = localStorage.getItem("authUser");
  const LANGUAGE_CODE = localStorage.getItem("LANGUAGE_CODE");

  if (AUTH_USER) {
    const ACCESS_TOKEN = JSON.parse(AUTH_USER);
    return {
      "Content-Type": "multipart/form-data",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      "Accept-Language": LANGUAGE_CODE ? LANGUAGE_CODE : "en",
      Authorization: "Bearer " + ACCESS_TOKEN.accessToken,
    };
  } else {
    return {
      "Content-Type": "multipart/form-data",
      "Retry-After": 3600,
      "Access-Control-Allow-Origin": "*",
      "Accept-Language": LANGUAGE_CODE ? LANGUAGE_CODE : "en",
      Authorization: "Bearer ",
    };
  }
};

export const getAzureAccessToken = async (loginRequest: any, accounts: any, instance: any) => {
  const request = {
    ...loginRequest,
    account: accounts[0],
  };

  instance
    .acquireTokenSilent(request)
    .then((response: any) => {
      localStorage.setItem("accessTokenSSO", response.accessToken);
      localStorage.setItem("refreshaccessTokenSSO", response.refresh_token);
    })
    .catch((e: any) => {
      instance.acquireTokenPopup(request).then((response: any) => {
        localStorage.setItem("accessTokenSSO", response.accessToken);
        localStorage.setItem("refreshaccessTokenSSO", response.refresh_token);
      });
    });
};

export const ckEditorConfiguration = {
  toolbar: {
    items: [
      "heading",
      "|",
      "fontFamily",
      "fontSize",
      "|",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "subscript",
      "superscript",
      "alignment",
      "|",
      "highlight",
      "fontBackgroundColor",
      "fontColor",
      "removeFormat",
      "|",
      "bulletedList",
      "numberedList",
      "todoList",
      "outdent",
      "indent",
      "|",
      "|",
      "insertTable",
      "|",
      "imageUpload",
      "blockQuote",
      "link",
      "|",
      "undo",
      "redo",
    ],
  },
  language: "en",
  image: {
    toolbar: [
      "imageTextAlternative",
      "imageStyle:inline",
      "imageStyle:block",
      "imageStyle:side",
    ],
  },
  table: {
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableCellProperties",
      "tableProperties",
    ],
  },
};

export const ckEditorConfigurationEmail = {
  toolbar: {
    items: [
      "fontSize",
      "|",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "alignment",
      "|",
      "highlight",
      "fontColor",
      "bulletedList",
      "numberedList",
      "|",
      "insertTable",
      "|",
      "imageUpload",
    ],
  },
  language: "en",
  image: {
    toolbar: [
      "imageTextAlternative",
      "imageStyle:inline",
      "imageStyle:block",
      "imageStyle:side",
    ],
  },
  table: {
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableCellProperties",
      "tableProperties",
    ],
  },
};


export const getInitials = (name: string, fixedLength = true) => {
  const fullName = name.split(" ") as Array<any>;
  let initials = '';
  if (fixedLength) {
    initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
  } else {
    initials = fullName.map((x: any) => x.charAt(0)).join("");
  }

  return initials.toUpperCase();
};

export const widgetAccess = (widget_id = '') => {
  if (widget_id === '') {
    return false;
  }
  const getUserProfile: any = store.getState().Users.GET_USER_PROFILE_SUCCESS;
  if (getUserProfile?.data[0]?.function === 6) {
    return true;
  }
  if (getUserProfile?.data[0]?.widgets?.find((i: any) => i.widget_id === widget_id)) {
    return true
  } else {
    return false
  }
}

export const getUserProfile = (slug = '') => {

  const getUserProfile: any = store.getState().Users.GET_USER_PROFILE_SUCCESS;
  // console.log("getUserProfile", getUserProfile?.data[0]?.widgets?.filter((i: any) => i.slug === slug)?.length, slug);
  // console.log('-------------------------------------------')
  if (slug === 'default') {
    return {
      USER_ID: getUserProfile?.data[0]?.id,
      USER_FUNCTION: getUserProfile?.data[0]?.function,
      USER_TYPE: getUserProfile?.data[0]?.type,
      USER_SUB_ID: getUserProfile?.data[0]?.user_id,
      USER_NAME: getUserProfile?.data[0]?.first_name,
      USER_LAST_NAME: getUserProfile?.data[0]?.last_name,
      USER_PROFILE_IMAGE: getUserProfile?.data[0]?.user_image_data,
      JOB_TITLE: getUserProfile?.data[0]?.title,
      CAN_VIEW: true,
      CAN_VIEW_ALL: getUserProfile?.data[0]?.access_role[0]?.role_is_view_all,
      CAN_CREATE: getUserProfile?.data[0]?.access_role[0]?.role_is_create,
      CAN_EDIT: getUserProfile?.data[0]?.access_role[0]?.role_is_edit,
      CAN_EDIT_ALL: getUserProfile?.data[0]?.access_role[0]?.role_is_edit_all,
      CAN_DELETE: getUserProfile?.data[0]?.access_role[0]?.role_is_delete,
      CAN_DELETE_ALL: getUserProfile?.data[0]?.access_role[0]?.role_is_delete_all,
      CAN_PRINT: getUserProfile?.data[0]?.access_role[0]?.role_is_print,
      IS_ADMIN: getUserProfile?.data[0]?.access_role[0]?.role_is_admin,
      IS_SUPER_ADMIN: getUserProfile?.data[0]?.access_role[0]?.role_is_super_admin,
      USER_COMPANY: getUserProfile?.data[0]?.company,
      USER_COMPANY_SUB_ID: getUserProfile?.data[0]?.company_id,
      IS_INDIVIDUAL: getUserProfile?.data[0]?.company === 0,
      IS_TC_USER: getUserProfile?.data[0]?.company === 1,
      IS_INTERNAL: getUserProfile?.data[0]?.company === 1,
      IS_NON_TC_USER: getUserProfile?.data[0]?.company > 1,
      IS_COMPANY_ADMIN:
        getUserProfile?.data[0]?.access_role[0]?.role_is_admin &&
        getUserProfile?.data[0]?.company === 1,
      IS_COMPANY_HR:
        getUserProfile?.data[0]?.access_role[0]?.role_is_admin &&
        getUserProfile?.data[0]?.company !== 1 && getUserProfile?.data[0]?.company !== 0,
      IS_PARTNER: getUserProfile?.data[0]?.function === 6,
      ACCESS_ROLE_ID: getUserProfile?.data[0]?.access_role_id,
      ACCESS_MODULE_ID: getUserProfile?.data[0]?.access_module_id,
      WIDGETS: getUserProfile?.data[0]?.widgets?.map((i: any) => i.widget_id) || [],
      QUESTIONNAIRE_COUNTS: getUserProfile?.data[0]?.questionnaire_counts,
      USER_MANUALS: getUserProfile?.data[0]?.user_manual_data ? getUserProfile?.data[0]?.user_manual_data : [],
      CAN_DOWNLOAD_CSF_ACT: false,
      IS_GRAPHICAL_VIEW: getUserProfile?.data[0]?.function === 6 ? true : getUserProfile?.data[0]?.widgets?.filter((i: any) => i.slug === slug)?.length > 0,
    };
  } else if (getUserProfile.data[0]?.access_role_new === null) {
    return {
      USER_ID: getUserProfile?.data[0]?.id,
      USER_FUNCTION: getUserProfile?.data[0]?.function,
      USER_TYPE: getUserProfile?.data[0]?.type,
      USER_SUB_ID: getUserProfile?.data[0]?.user_id,
      USER_NAME: getUserProfile?.data[0]?.first_name,
      USER_LAST_NAME: getUserProfile?.data[0]?.last_name,
      USER_PROFILE_IMAGE: getUserProfile?.data[0]?.user_image_data,
      JOB_TITLE: getUserProfile?.data[0]?.title,
      CAN_VIEW: getUserProfile?.data[0]?.access_role[0]?.role_is_view,
      CAN_VIEW_ALL: getUserProfile?.data[0]?.access_role[0]?.role_is_view_all,
      CAN_CREATE: getUserProfile?.data[0]?.access_role[0]?.role_is_create,
      CAN_EDIT: getUserProfile?.data[0]?.access_role[0]?.role_is_edit,
      CAN_EDIT_ALL: getUserProfile?.data[0]?.access_role[0]?.role_is_edit_all,
      CAN_DELETE: getUserProfile?.data[0]?.access_role[0]?.role_is_delete,
      CAN_DELETE_ALL: getUserProfile?.data[0]?.access_role[0]?.role_is_delete_all,
      CAN_PRINT: getUserProfile?.data[0]?.access_role[0]?.role_is_print,
      IS_ADMIN: getUserProfile?.data[0]?.access_role[0]?.role_is_admin,
      IS_SUPER_ADMIN: getUserProfile?.data[0]?.access_role[0]?.role_is_super_admin,
      USER_COMPANY: getUserProfile?.data[0]?.company,
      USER_COMPANY_SUB_ID: getUserProfile?.data[0]?.company_id,
      IS_INDIVIDUAL: getUserProfile?.data[0]?.company === 0,
      IS_TC_USER: getUserProfile?.data[0]?.company === 1,
      IS_INTERNAL: getUserProfile?.data[0]?.company === 1,
      IS_NON_TC_USER: getUserProfile?.data[0]?.company > 1,
      IS_COMPANY_ADMIN:
        getUserProfile?.data[0]?.access_role[0]?.role_is_admin &&
        getUserProfile?.data[0]?.company === 1,
      IS_COMPANY_HR:
        getUserProfile?.data[0]?.access_role[0]?.role_is_admin &&
        getUserProfile?.data[0]?.company !== 1 && getUserProfile?.data[0]?.company !== 0,
      IS_PARTNER: getUserProfile?.data[0]?.function === 6,
      ACCESS_ROLE_ID: getUserProfile?.data[0]?.access_role_id,
      ACCESS_MODULE_ID: getUserProfile?.data[0]?.access_module_id,
      WIDGETS: getUserProfile?.data[0]?.widgets?.map((i: any) => i.widget_id) || [],
      QUESTIONNAIRE_COUNTS: getUserProfile?.data[0]?.questionnaire_counts,
      USER_MANUALS: getUserProfile?.data[0]?.user_manual_data ? getUserProfile?.data[0]?.user_manual_data : [],
      CAN_DOWNLOAD_CSF_ACT: false,
      IS_GRAPHICAL_VIEW: getUserProfile?.data[0]?.function === 6 ? true : getUserProfile?.data[0]?.widgets?.filter((i: any) => i.slug === slug)?.length > 0,
    };
  } else {
    const accessRoleNew = getUserProfile.data[0]?.access_role_new?.modules?.find((a: any) => a.module === slug);
    return {
      USER_ID: getUserProfile?.data[0]?.id,
      USER_FUNCTION: getUserProfile?.data[0]?.function,
      USER_TYPE: getUserProfile?.data[0]?.type,
      USER_SUB_ID: getUserProfile?.data[0]?.user_id,
      USER_NAME: getUserProfile?.data[0]?.first_name,
      USER_LAST_NAME: getUserProfile?.data[0]?.last_name,
      USER_PROFILE_IMAGE: getUserProfile?.data[0]?.user_image_data,
      JOB_TITLE: getUserProfile?.data[0]?.title,
      CAN_VIEW: accessRoleNew?.access.can_view,
      CAN_VIEW_ALL: accessRoleNew?.access.can_view_all,
      CAN_CREATE: accessRoleNew?.access.can_create,
      CAN_EDIT: accessRoleNew?.access.can_edit,
      CAN_EDIT_ALL: accessRoleNew?.access.can_edit_all,
      CAN_DELETE: accessRoleNew?.access.can_delete,
      CAN_DELETE_ALL: accessRoleNew?.access.can_delete_all,
      CAN_PRINT: accessRoleNew?.access.can_print,
      CAN_EXPORT: accessRoleNew?.access.can_export,
      CAN_VIEW_FINANCIAL: getUserProfile.data[0]?.access_role_new?.access_to_financial_data,
      IS_ADMIN: getUserProfile?.data[0]?.access_role[0]?.role_is_admin,
      IS_SUPER_ADMIN: getUserProfile?.data[0]?.access_role[0]?.role_is_super_admin,
      USER_COMPANY: getUserProfile?.data[0]?.company,
      USER_COMPANY_SUB_ID: getUserProfile?.data[0]?.company_id,
      IS_INDIVIDUAL: getUserProfile?.data[0]?.company === 0,
      IS_TC_USER: getUserProfile?.data[0]?.company === 1,
      IS_INTERNAL: getUserProfile?.data[0]?.company === 1,
      IS_NON_TC_USER: getUserProfile?.data[0]?.company > 1,
      IS_COMPANY_ADMIN:
        getUserProfile?.data[0]?.access_role[0]?.role_is_admin &&
        getUserProfile?.data[0]?.company === 1,
      IS_COMPANY_HR:
        getUserProfile?.data[0]?.access_role[0]?.role_is_admin &&
        getUserProfile?.data[0]?.company !== 1 && getUserProfile?.data[0]?.company !== 0,
      IS_PARTNER: getUserProfile?.data[0]?.function === 6,
      ACCESS_ROLE_ID: getUserProfile?.data[0]?.access_role_id,
      ACCESS_MODULE_ID: getUserProfile?.data[0]?.access_module_id,
      WIDGETS: getUserProfile?.data[0]?.widgets?.map((i: any) => i.widget_id) || [],
      QUESTIONNAIRE_COUNTS: getUserProfile?.data[0]?.questionnaire_counts,
      USER_MANUALS: getUserProfile?.data[0]?.user_manual_data ? getUserProfile?.data[0]?.user_manual_data : [],
      CAN_DOWNLOAD_CSF_ACT: getUserProfile.data[0]?.access_role_new?.download_csf_act,
      IS_GRAPHICAL_VIEW: getUserProfile?.data[0]?.function === 6 ? true : getUserProfile?.data[0]?.widgets?.filter((i: any) => i.slug === slug)?.length > 0,
    };
  }

};

// Custome Code
export const getCustomeCode = async (tableName: string, moduleCode: string) => {
  const response = await commonService.getCustomeCode(tableName, moduleCode);
  return response.data.data;
};

// Select Options
export const getSelectOptions = async (
  tableName: string,
  fieldName: string,
  query?: string,
  otherField?: Array<string>
) => {
  const response = await commonService.getSelectOptionsList(
    tableName,
    fieldName,
    query ? query : "",
    otherField
  );
  return response.data.data;
};

// Dependent Select Options
export const getDependentSelectOptions = async (
  tableName: string,
  fieldName: string,
  whereField: string,
  whereID: number,
  query?: string
) => {
  const response = await commonService.getDependentSelectOptionsList(
    tableName,
    fieldName,
    whereField,
    whereID,
    query ? query : ""
  );
  return response.data.data;
};

// Dependent User Options
export const getDependentUserOptions_New = async (
  tableName: string,
  fieldName: string,
  whereField: string,
  whereID: number,
  query?: string
) => {
  const response = await commonService.getDependentUserOptionsList_New(
    tableName,
    fieldName,
    whereField,
    whereID,
    query ? query : ""
  );
  return response.data.data;
};

// export const getSelectOptions = async (tableName: string, fieldName: string, otherField?: Array<string>, query?: string) => {
//   const response = await commonService.getSelectOptionsList(
//     tableName,
//     fieldName,
//     query ? query : "",
//     otherField
//   );
//   return response.data.data;
// };

// export const getDependentSelectOptions = async (
//   tableName: string,
//   fieldName: string,
//   whereField: string,
//   whereID: number,
//   query?: string
// ) => {
//   const response = await commonService.getDependentSelectOptionsList(
//     tableName,
//     fieldName,
//     whereField,
//     whereID,
//     query ? query : ""
//   );
//   return response.data.data;
// };

export const getDependentMultipleWhereSelectOptions = async (
  tableName: string,
  fieldName: string,
  whereFields: any,
  whereIds: any,
  query?: string,
  otherField?: Array<string>
) => {
  const response =
    await commonService.getDependentMultipleWhereSelectOptionsList(
      tableName,
      fieldName,
      whereFields,
      whereIds,
      query ? query : "",
      otherField
    );
  return response.data.data;
};

export const getDependentSelectOptionsWithOrder = async (
  tableName: string,
  fieldName: string,
  whereField: string,
  whereID: number,
  orderField: string,
  otherField?: Array<string>
) => {
  const response = await commonService.getDependentSelectOptionsListWithOrder(
    tableName,
    fieldName,
    whereField,
    whereID,
    orderField,
    otherField
  );
  return response.data?.data;
};


// Random key
export const getRandomKey = () => {
  return (Math.random() + 1).toString(36).substring(2);
};

// Random password
export const getRandomPassword = (len: number) => {
  var length = len ? len : 15;
  var string1 = "abcdefghijklmnopqrstuvwxyz";
  var string2 = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var numeric = "0123456789";
  var punctuation = "!@#$%^&*";
  var password = "";
  var character = "";
  var crunch = true;
  while (password.length < length) {
    let entity1 = Math.ceil(string1.length * Math.random() * Math.random());
    let entity2 = Math.ceil(string2.length * Math.random() * Math.random());
    let entity3 = Math.ceil(numeric.length * Math.random() * Math.random());
    let entity4 = Math.ceil(punctuation.length * Math.random() * Math.random());

    let hold1 = string1.charAt(entity1);
    hold1 = password.length % 2 == 0 ? hold1.toUpperCase() : hold1;

    let hold2 = string2.charAt(entity2);
    hold2 = password.length % 2 == 0 ? hold2.toLowerCase() : hold2;

    character += hold1;
    character += hold2;
    character += numeric.charAt(entity3);
    character += punctuation.charAt(entity4);
    password = character;
  }
  password = password
    .split("")
    .sort(function () {
      return 0.5 - Math.random();
    })
    .join("");
  return password.substr(0, len);
};

export const checkPasswordStrength = (password: string) => {
  // Initialize variables
  var strength = 0;
  var tips = "";

  // Check password length
  if (password.length < 8) {
    tips += "Make the password longer. ";
  } else {
    strength += 1;
  }

  // Check for mixed case
  if (password.match(/[a-z]/) && password.match(/[A-Z]/)) {
    strength += 1;
  } else {
    tips += "Use both lowercase and uppercase letters. ";
  }

  // Check for numbers
  if (password.match(/\d/)) {
    strength += 1;
  } else {
    tips += "Include at least one number. ";
  }

  // Check for special characters
  if (password.match(/[^a-zA-Z\d]/)) {
    strength += 1;
  } else {
    tips += "Include at least one special character. ";
  }

  // Return results
  if (strength < 2) {
    return "Easy to guess. " + tips;
  } else if (strength === 2) {
    return "Medium difficulty. " + tips;
  } else if (strength === 3) {
    return "Difficult. " + tips;
  } else {
    return "Extremely difficult. " + tips;
  }
}

// Datatable Query
export const getDataTableQuery = (
  options: any,
  columns: any,
  id?: string,
  customFilters?: any
) => {
  var start = 0;
  const page = options.page;
  const sizePerPage = options.sizePerPage;
  const searchText = options.searchText;
  var sortFieldId = 0;
  const sortField = options.sortField;
  const sortOrder = options.sortOrder;

  if (page == 1) {
    start = 0;
  } else {
    start = (page - 1) * sizePerPage;
  }

  var d = {
    draw: options.draw,
    columns: [] as any,
    order: [] as any,
    start: start,
    length: sizePerPage,
    search: {
      value: searchText,
      regex: false,
    },
    id: id,
  };

  const columnCount = columns.length;
  for (var i = 0; i < columnCount; i++) {
    var column = columns[i];

    d.columns.push({
      data: column.dataField,
      name: column.dataField,
      searchable: column.search,
      orderable: column.sort,
      search: {
        value: "",
        regex: false,
      },
    });

    if (column.dataField == sortField) {
      sortFieldId = i;
    }
  }

  d.order.push({
    column: sortFieldId,
    dir: sortOrder,
  });

  return d;
};

export const isActiveMenu = (path: string, slug: string) => {
  const paths = path.split("/");
  return paths.includes(slug);
};

export const getFirstErrorElement = (
  validationSchema: any,
  validationErrors: any
) => {
  const validation_schema = Object.keys(validationSchema.fields);
  const validation_error = Object.keys(validationErrors.errors);
  var newData: any = [];
  validation_schema.map((item: any, index: number) => {
    if (validation_error.find((option: any) => option == item)) {
      newData.push(item);
    }
  });
  return newData[0];
};

export const filteredDuplicate = (options: Array<[]>) => {
  return options.filter((v: any, i, a) => a.findIndex((v2: any) => (v2.value === v.value)) === i)
};

export const filteredOptions = (options: Array<[]>, data: any) => {
  var newData: any = [];
  options.map((item: any, index: number) => {
    if (data.includes(parseInt(item.value))) {
      newData.push(item);
    }
  });
  return newData;
};

export const filteredOptionsByTCUser = (
  options: Array<[]>,
  data: any
) => {
  var newData: any = [];
  options.map((item: any, index: number) => {
    if (
      item?.preparer == data ||
      item?.reviewer == data ||
      item?.file_manager == data ||
      item?.signing_partner == data ||
      item?.assistant_administrative == data
    ) {
      newData.push(item);
    }
  });
  return newData;
};

export const filteredOptionsByFileManager = (
  options: Array<[]>,
  data: any,
  admin: any
) => {
  var newData: any = [];
  options.map((item: any, index: number) => {
    if (item?.file_manager == data) {
      newData.push(item);
    }
  });
  return newData;
};

export const filteredByFunction = (options: Array<[]>, data: any) => {
  var newData: any = [];
  options.map((item: any, index: number) => {
    if (data.includes(item.function)) {
      newData.push(item);
    }
  });
  newData.push({ value: "", label: "-- Remove --", });
  return newData;
};

export const archiveSetDisabled = (options: Array<[]>, user?: any) => {
  var newData: any = [];
  options.map((item: any, index: number) => {
    if (item?.is_archive === 1) {
      if (user && item?.id === user) {
        newData.push({ ...item, isDisabled: true });
      }

      if (user === undefined) {
        newData.push({ ...item, isDisabled: true });
      }
    } else {
      newData.push(item);
    }
  });
  return newData;
};

export const filteredByStatus = (options: Array<[]>, status: number) => {
  var newData: any = [];
  options.map((item: any, index: number) => {
    if (parseInt(item?.status) === status) {
      newData.push(item);
    }
  });
  return newData;
};

export const filterOptionsSetDisabled = (options: Array<[]>, data: any) => {
  var newData: any = [...options];
  newData.map((item: any, index: number) => {
    if (!data.includes(item.value)) {
      newData[index] = {
        ...item,
        isDisabled: true,
      };
    }
  });
  return newData;
};

export const filterOptionsSetHiddenByValue = (options: Array<[]>, data: any) => {
  var newData: any = [...options];
  newData.map((item: any, index: number) => {
    if (data.includes(item.value)) {
      newData[index] = {
        ...item,
        isDisabled: true,
      };
    }
  });
  return newData;
};

export const filterOptionsSetHidden = (options: Array<[]>, data: any) => {
  var newData: any = [...options];
  newData.map((item: any, index: number) => {
    if (data.includes(index)) {
      newData[index] = {
        ...item,
        hidden: true,
      };
    }
  });
  return newData;
};

export const filterOptionsRemoveHidden = (options: Array<[]>) => {
  var newData: any = [];
  options.map((item: any, index: number) => {
    if (item.hidden !== true) {
      newData.push(item);
    }
  });
  return newData;
};

export const getOptionById = (options: Array<[]>, id: any) => {
  return options.find((option: any) => option.value == id);
};

export const getOptionValueById = (
  options: Array<[]>,
  id: number,
  field: string
) => {
  const option: any = options.find((option: any) => option.value == id);
  return option && option[field] ? option[field] : 0;
};

export const setCompanyToIndividual = (options: Array<[]>) => {
  var newData: any = [...options];
  newData.map((item: any, index: number) => {
    if (item.value === 1) {
      newData[index] = {
        value: "0",
        label: "Individual",
      };
    }
  });
  return newData;
};

export const addIndividualInCompany = (options: Array<[]>) => {
  var newData: any = [];
  newData.push(
    {
      value: "0",
      label: "Individual",
    },
    ...options
  );
  return newData;
};

export const addAllUsersInUsers = (options: Array<[]>) => {
  var newData: any = [];
  newData.push(
    {
      value: "0",
      label: "All users",
    },
    ...options
  );
  return newData;
};

export const yearsBackword = () => {
  const year = 2015;
  const curYear = new Date().getFullYear();
  let years = [] as any;
  for (let i = year; i <= parseInt(curYear as any); i++) {
    let temp = {
      label: i,
      value: i,
    };
    years.push(temp);
  }
  return years;
};

export const yearsBackword_Sort = () => {
  const year: any = 2015;
  const curYear = new Date().getFullYear();
  let years = [] as any;
  for (let i = curYear; i >= parseInt(year); i--) {
    let temp = {
      label: i,
      value: i,
    };
    years.push(temp);
  }
  return years;
};

export const yearsOnward = () => {
  const curYear = new Date().getFullYear();
  const year = new Date().getFullYear() + 5;
  let years = [] as any;
  for (let i = curYear; i <= parseInt(year as any); i++) {
    let temp = {
      label: i,
      value: i,
    };
    years.push(temp);
  }
  return years;
};

const yearList = () => {
  let list: any[] = [];
  for (let i = (new Date()).getFullYear(); i >= 2012; i--) {
    list = [...list, {
      value: i,
      label: i
    }]
  }
  return list;
}
export const yearOptions = yearList();

export const select2Styles = {
  control: (base: any) => ({
    ...base,
    minHeight: 22,
    heigth: 20,
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  clearIndicator: (base: any) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
};

export const getMonthlyDaysInGivenYear = (year: number) => {
  var newData: any = [];
  {
    [...Array(12)].map((x: any, idx: number) => {
      const month_no = idx + 1;
      const month_name = moment().month(idx).format("MMMM");
      const days_in_month = moment(
        year + "-" + (idx + 1),
        "YYYY-MM"
      ).daysInMonth();

      newData.push({
        month_no: month_no,
        month_name: month_name,
        days_in_month: days_in_month,
      });
    });
  }
  return newData;
};

export const getTotalDaysInGivenYear = (year: number) => {
  let days_in_year = 0;
  {
    [...Array(12)].map((x: any, idx: number) => {
      const days_in_month = moment(
        year + "-" + (idx + 1),
        "YYYY-MM"
      ).daysInMonth();
      days_in_year = days_in_year + days_in_month;
    });
  }
  return days_in_year;
};

//Module access
//@param slug: mapped with tbl_master_module slug column

export const moduleAccess = (slug: string) => {
  const getUserProfile: any = store.getState().Users.GET_USER_PROFILE_SUCCESS;

  let module_found = null;
  module_found = getUserProfile?.data[0]?.access_module?.find(
    (option: any) => option.module === slug && option.status === true
  );

  if (module_found || slug === "default") {
    return true;
  } else {
    return false;
  }
};

export function isURL(string: string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

export function onlyPositiveValue(e: any) {
  var charCode = e.which;
  if (
    (charCode > 31 &&
      (charCode < 48 || charCode > 57) &&
      !(charCode == 46 || charCode == 8)) ||
    charCode === 45
  ) {
    e.preventDefault();
    return false;
  } else {
    var len = e.target.value.length;
    var index = e.target.value.indexOf(".");
    if (index > 0 && charCode == 46) {
      e.preventDefault();
      return false;
    }
    if (index > 0) {
      var CharAfterdot = len + 1 - index;
      if (CharAfterdot > 3) {
        e.preventDefault();
        return false;
      }
    }
  }
}

export function onlyPercentageField(e: any) {
  var charCode = e.which;
  if (
    (charCode > 31 &&
      (charCode < 48 || charCode > 57) &&
      !(charCode === 46 || charCode === 8)) ||
    charCode === 45
  ) {
    e.preventDefault();
    return false;
  } else {
    var value = e.target.value;
    var len = value.length;
    var index = value.indexOf(".");
    if (value.length > 1 && charCode != 46 && index <= 0 && (value != 10 || charCode != 48)) {
      e.preventDefault();
      return false;
    } else if (index > 0 && charCode === 46) {
      e.preventDefault();
      return false;
    }
    else if (index > 0) {
      var CharAfterdot = len + 1 - index;
      if (CharAfterdot > 3) {
        e.preventDefault();
        return false;
      }
    }
  }
}

export function vLookup(
  value: any,
  range: Array<number[]>,
  column: number,
  match = false as boolean
) {
  let tempItem = 0;
  if (typeof value !== "number") {
    match = true;
  }
  range.map((item: any, index: number) => {
    if (match === true) {
      if (item[0] === value) {
        tempItem = item[column - 1];
      }
    } else {
      if (item[0] <= value) {
        tempItem = item[column - 1];
      }
    }
  });
  return tempItem;
}

export const filterListInByKey = (data: Array<any[]>, key: string, value: any) => data.filter((item: any) => item[key] === value);
export const filterListNotInByKey = (data: Array<any[]>, key: string, value: any) => data.filter((item: any) => item[key] !== value);

export async function handleAddress(data: any) {
  let route = "";
  let street_number = "";
  let subpremise = "";
  let locality = "";
  let administrative_area_level_3 = "";
  let administrative_area_level_1 = "";
  let country = "";
  let country_short_name = "";
  let postal_code = "";

  data.address_components &&
    data.address_components.map((item: any, idx: number) => {
      if (item.types[0] === "route") {
        route = item.long_name;
      } else {
        route = data?.formatted_address;
      }
      if (item.types[0] === "street_number") {
        street_number = item.long_name;
      }
      if (item.types[0] === "subpremise") {
        subpremise = item.long_name;
      }
      if (item.types[0] === "locality") {
        locality = item.long_name;
      }
      if (item.types[0] === "administrative_area_level_1") {
        administrative_area_level_1 = item.long_name;
      }
      if (item.types[0] === "administrative_area_level_3") {
        administrative_area_level_3 = item.long_name;
      }
      if (item.types[0] === "country") {
        country_short_name = item.short_name;
      }
      if (item.types[0] === "postal_code") {
        postal_code = item.long_name;
      }
    });

  if (country_short_name) {
    const country_data = await commonService.getCountryId(country_short_name) as any;
    if (country_data.data.status === 'success') {
      if (country_data.data.data[0]) {
        country = country_data.data.data[0].value;
      } else {
        country = "";
      }
    }
  }

  return {
    address: route,
    address_number: street_number,
    box: subpremise,
    postal_code: postal_code,
    commune: locality,
    city: administrative_area_level_3 ? administrative_area_level_3 : administrative_area_level_1,
    state: administrative_area_level_3 && administrative_area_level_1 ? administrative_area_level_1 : "",
    country: country,
  };
}

export const calculateVatData = (country: any, isEuropean: any, TVW_Nr: any) => {

  let vat_percentage = 0;
  let vatcat = 0;
  let vat_code = '';
  let to_apply_vat = '';
  let reason_for_vat = '';
  if (isEuropean) {
    if (country == '21') {
      if (TVW_Nr) {
        vatcat = 1;
      } else {
        vatcat = 3;
      }
      vat_percentage = 21;
      vat_code = '211400';
      to_apply_vat = '1';
      reason_for_vat = '1';
      // alert(vatPer);
    } else {
      if (TVW_Nr) {
        vat_percentage = 0;
        vatcat = 1;
        vat_code = '222000';
        to_apply_vat = '2';
        reason_for_vat = '2';
      } else {
        vat_percentage = 21;
        vatcat = 3;
        vat_code = '222000';
        to_apply_vat = '1';
        reason_for_vat = '1';
      }
    }
  } else {
    vat_percentage = 0;
    vatcat = 0;
    vat_code = '232000';
    to_apply_vat = '2';
    reason_for_vat = '3';
  }
  return {
    vat_percentage,
    vatcat,
    vat_code,
    to_apply_vat,
    reason_for_vat
  }
}

export const removeTags = (str: any) => {
  if ((str === null) || (str === ''))
    return false;
  else
    str = str.toString();
  return str.replace(/(<([^>]+)>)/ig, '');
}

export const statusOptions = [
  { value: "1", label: "LEAD" },
  { value: "2", label: "PROSPECT" },
  { value: "3", label: "CUSTOMER" },
];

export const listingStatusOptions = [
  { value: "1", label: "Listed" },
  { value: "2", label: "Non Listed" },
];
export const yearData = [
  {
    'label': ((new Date()).getFullYear() - 2),
    'value': ((new Date()).getFullYear() - 2)
  },
  {
    'label': ((new Date()).getFullYear() - 1),
    'value': ((new Date()).getFullYear() - 1)
  },
  {
    'label': ((new Date()).getFullYear()),
    'value': ((new Date()).getFullYear())
  },
  {
    'label': ((new Date()).getFullYear() + 1),
    'value': ((new Date()).getFullYear() + 1)
  },
  {
    'label': ((new Date()).getFullYear() + 2),
    'value': ((new Date()).getFullYear() + 2)
  },
  {
    'label': ((new Date()).getFullYear() + 3),
    'value': ((new Date()).getFullYear() + 3)
  },
  {
    'label': ((new Date()).getFullYear() + 4),
    'value': ((new Date()).getFullYear() + 4)
  },
  {
    'label': ((new Date()).getFullYear() + 5),
    'value': ((new Date()).getFullYear() + 5)
  },
  { label: "-- Remove --", value: "" }
]

export const yearDataPreOnly = [
  {
    'label': ((new Date()).getFullYear() - 1),
    'value': ((new Date()).getFullYear() - 1)
  },
  {
    'label': ((new Date()).getFullYear()),
    'value': ((new Date()).getFullYear())
  }];

export const handleFilters = (type: string, datatableOptions?: Object, filters?: Object) => {
  let localFilters = localStorage.getItem("filters");
  const localFiltersArray = JSON.parse(String(localFilters));

  if (type === "SET") {
    if (localFiltersArray && localFiltersArray?.length) {
      let newData = [] as any;
      localFiltersArray.map((item: any) => {
        if (item.url !== window.location.pathname) {
          newData.push(item);
        }
      })
      newData.push({
        url: window.location.pathname,
        datatableOptions: datatableOptions,
        filters: filters,
      });
      localStorage.setItem("filters", JSON.stringify(newData));
    } else {
      localStorage.setItem(
        "filters",
        JSON.stringify([
          {
            url: window.location.pathname,
            datatableOptions: datatableOptions,
            filters: filters,
          },
        ])
      );
    };
  } else {
    if (localFiltersArray && localFiltersArray?.length) {
      return localFiltersArray.find((option: any) => option.url === window.location.pathname);
    }
    return null;
  }
}
export const handleAutoSave = (type: string, initialData?: Object) => {
  let localFormData = localStorage.getItem("formsData");
  const localFormDataArray = JSON.parse(String(localFormData));

  if (type === "SET") {
    if (localFormDataArray && localFormDataArray?.length) {
      let newData = [] as any;
      localFormDataArray.map((item: any) => {
        if (item.url !== window.location.pathname) {
          newData.push(item);
        }
      })
      newData.push({
        url: window.location.pathname,
        initialData: initialData,
      });
      localStorage.setItem("formsData", JSON.stringify(newData));
    } else {
      localStorage.setItem(
        "formsData",
        JSON.stringify([
          {
            url: window.location.pathname,
            initialData: initialData,
          },
        ])
      );
    };
  } else if (type === "CLEAR") {
    if (localFormDataArray && localFormDataArray?.length) {
      let newData = [] as any;
      localFormDataArray.map((item: any) => {
        if (item.url !== window.location.pathname) {
          newData.push(item);
        }
      })
      localStorage.setItem("formsData", JSON.stringify(newData));
    }
  } else {
    if (localFormDataArray && localFormDataArray?.length) {
      return localFormDataArray.find((option: any) => option.url === window.location.pathname);
    }
    return null;
  }
}

export const filterFeesTypeByLanguage = (data: any) => {
  let localLanguage = localStorage.getItem("I18N_LANGUAGE");

  var newData: any = [];
  if (localLanguage === "English") {
    data.map((item: any, index: number) => {
      let list = {
        ...item,
        label: item?.fees_code + " - " + item?.description,
        category_of_fees: item?.category_of_fees,
        category: item?.category,
      }
      newData.push(list);
    });
  } else if (localLanguage === "French") {
    data.map((item: any, index: number) => {
      let list = {
        ...item,
        label: item?.fees_code + " - " + item?.description_french,
        category_of_fees: item?.category_of_fees_french,
        category: item?.category_french,
      }
      newData.push(list);
    });
  } else if (localLanguage === "Dutch") {
    data.map((item: any, index: number) => {
      let list = {
        ...item,
        label: item?.fees_code + " - " + item?.description_dutch,
        category_of_fees: item?.category_of_fees_dutch,
        category: item?.category_dutch,
      }
      newData.push(list);
    });
  } else {
    data.map((item: any, index: number) => {
      let list = {
        ...item,
        label: item?.fees_code + " - " + item?.description,
        category_of_fees: item?.category_of_fees,
        category: item?.category,
      }
      newData.push(list);
    });
  }
  return newData;
}

export const filterCountryByLanguage = (data: any, language = "") => {
  let localLanguage =language ? language: localStorage.getItem("I18N_LANGUAGE");

  var newData: any = [];
  if (localLanguage === "English") {
    data.map((item: any, index: number) => {
      let list = {
        ...item,
        label: item?.name,
      }
      newData.push(list);
    });
  } else if (localLanguage === "French") {
    data.map((item: any, index: number) => {
      let list = {
        ...item,
        label: item?.name_french,
      }
      newData.push(list);
    });
  } else if (localLanguage === "Dutch") {
    data.map((item: any, index: number) => {
      let list = {
        ...item,
        label: item?.name_dutch,
      }
      newData.push(list);
    });
  } else {
    data.map((item: any, index: number) => {
      let list = {
        ...item,
        label: item?.name,
      }
      newData.push(list);
    });
  }

  newData.sort((a: any, b: any) => a?.label?.localeCompare(b?.label));
  return newData;
}

export const strToSecond = (time: any) => {
  if (time) {
    var a = time.split(':'); // split it at the colons
    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    return ((+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2])) / 3600;
  } else {
    return 0;
  }

}

export const dateTimeString = () => {
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let hour = date.getHours();
  let minute = date.getMinutes();
  let second = date.getSeconds();
  return year.toString() + month.toString() + day.toString() + hour.toString() + minute.toString() + second.toString();
}

export const datePickerI18 = () => {
  let localLanguage = localStorage.getItem("I18N_LANGUAGE");
  switch (localLanguage) {
    case "English":
      return english;
    case "French":
      return French;
    case "Dutch":
      return Dutch;
    default:
      return english;
  }
}

export const calenderTranslate = () => {
  let localLanguage = localStorage.getItem("I18N_LANGUAGE");
  switch (localLanguage) {
    case "English":
      return enLocale;
    case "French":
      return frLocale;
    case "Dutch":
      return nlLocale;
    default:
      return enLocale;
  }
}

export const ckEditorI18 = () => {
  let localLanguage = localStorage.getItem("I18N_LANGUAGE");
  switch (localLanguage) {
    case "English":
      return "en";
    case "French":
      return "fr";
    case "Dutch":
      return "nl";
    default:
      return "en";
  }
}

export const wssConfig = () => {
  const host = window.location.host;
  const protocol = window.location.protocol;
  if ((host === "localhost:3000" || host === "127.0.0.1:3000") && protocol != 'https:') {
    return {
      broadcaster: "pusher",
      key: "mykey",
      wsHost: window.location.hostname,
      wsPort: 6001,
      forceTLS: false,
    }
  } else {
    return {
      broadcaster: "pusher",
      key: "mykey",
      wsHost: window.location.hostname,
      wssPort: 6001,
      wsPort: 6001,
      forceTLS: true,
      encrypted: true,
    }
  }

}

export const graph_i18 = () => {
  let localLanguage = localStorage.getItem("I18N_LANGUAGE");
  switch (localLanguage) {
    case "English":
      return {
        "name": "en",
        "options": {
          "months": [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
          ],
          "shortMonths": [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
          ],
          "days": [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday"
          ],
          "shortDays": [
            "Sun",
            "Mon",
            "Tue",
            "Wed",
            "Thu",
            "Fri",
            "Sat"
          ],
          "toolbar": {
            "exportToSVG": "Download SVG",
            "exportToPNG": "Download PNG",
            "exportToCSV": "Download CSV",
            "menu": "Menu",
            "selection": "Selection",
            "selectionZoom": "Selection Zoom",
            "zoomIn": "Zoom In",
            "zoomOut": "Zoom Out",
            "pan": "Panning",
            "reset": "Reset Zoom"
          }
        }
      };
    case "French":
      return {
        "name": "fr",
        "options": {
          "months": [
            "janvier",
            "février",
            "mars",
            "avril",
            "mai",
            "juin",
            "juillet",
            "août",
            "septembre",
            "octobre",
            "novembre",
            "décembre"
          ],
          "shortMonths": [
            "janv.",
            "févr.",
            "mars",
            "avr.",
            "mai",
            "juin",
            "juill.",
            "août",
            "sept.",
            "oct.",
            "nov.",
            "déc."
          ],
          "days": [
            "dimanche",
            "lundi",
            "mardi",
            "mercredi",
            "jeudi",
            "vendredi",
            "samedi"
          ],
          "shortDays": [
            "dim.",
            "lun.",
            "mar.",
            "mer.",
            "jeu.",
            "ven.",
            "sam."
          ],
          "toolbar": {
            "exportToSVG": "Télécharger au format SVG",
            "exportToPNG": "Télécharger au format PNG",
            "exportToCSV": "Télécharger au format CSV",
            "menu": "Menu",
            "selection": "Sélection",
            "selectionZoom": "Sélection et zoom",
            "zoomIn": "Zoomer",
            "zoomOut": "Dézoomer",
            "pan": "Navigation",
            "reset": "Réinitialiser le zoom"
          }
        }
      };
    case "Dutch":
      return {
        "name": "nl",
        "options": {
          "months": [
            "Januari",
            "Februari",
            "Maart",
            "April",
            "Mei",
            "Juni",
            "Juli",
            "Augustus",
            "September",
            "Oktober",
            "November",
            "December"
          ],
          "shortMonths": [
            "Jan",
            "Feb",
            "Mrt",
            "Apr",
            "Mei",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Okt",
            "Nov",
            "Dec"
          ],
          "days": [
            "Zondag",
            "Maandag",
            "Dinsdag",
            "Woensdag",
            "Donderdag",
            "Vrijdag",
            "Zaterdag"
          ],
          "shortDays": [
            "Zo",
            "Ma",
            "Di",
            "Wo",
            "Do",
            "Vr",
            "Za"
          ],
          "toolbar": {
            "exportToSVG": "Download SVG",
            "exportToPNG": "Download PNG",
            "exportToCSV": "Download CSV",
            "menu": "Menu",
            "selection": "Selectie",
            "selectionZoom": "Zoom selectie",
            "zoomIn": "Zoom in",
            "zoomOut": "Zoom out",
            "pan": "Verplaatsen",
            "reset": "Standaardwaarden"
          }
        }
      };
  }
}

export const autoBreak = (label: any, max_length: any) => {
  const maxLength = max_length ? max_length : 50;
  const lines = [];

  for (let word of label.split(" ")) {
    if (lines.length == 0) {
      lines.push(word);
    } else {
      const i: any = lines.length - 1
      const line: any = lines[i]

      if (line.length + 1 + word.length <= maxLength) {
        lines[i] = `${line} ${word}`
      } else {
        lines.push(word)
      }
    }
  }

  return lines;
}
export const ckEditorConfigurationTemplate = {
  toolbar: {
    items: [
      "heading",
      "|",
      "fontFamily",
      "fontSize",
      "|",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "subscript",
      "superscript",
      "alignment",
      "pageBreak",
      "|",
      "highlight",
      "fontBackgroundColor",
      "fontColor",
      "removeFormat",
      "|",
      "bulletedList",
      "numberedList",
      "todoList",
      "outdent",
      "indent",
      "|",
      "|",
      "insertTable",
      "|",
      "imageUpload",
      "blockQuote",
      "link",
      "|",
      "undo",
      "redo",
    ],
  },
  language: "en",
  image: {
    toolbar: [
      "imageTextAlternative",
      "imageStyle:inline",
      "imageStyle:block",
      "imageStyle:side",
    ],
  },
  table: {
    contentToolbar: [
      "tableColumn",
      "tableRow",
      "mergeTableCells",
      "tableCellProperties",
      "tableProperties",
    ],
  },
  htmlSupport: {
    allow: [
      {
        name: /.*/,
        attributes: true,
        classes: true,
        styles: true
      }
    ]
  }

};

export const timeNow = () => {
  return new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
}

export const quarterOfYear = (date = new Date()) => `Q${Math.ceil((date.getMonth() + 1) / 3)}-${date.getFullYear()}`;
