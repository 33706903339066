const TENANT_ID = process.env.REACT_APP_TENANT_ID;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;

export const msalConfig = {
  auth: {
    clientId: CLIENT_ID || "",
    authority: "https://login.microsoftonline.com/" + TENANT_ID,
    redirectUri: REDIRECT_URI,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  // scopes: ["User.Read", "AllSites.Read", "AllSites.Write", "Mail.Read", "Mail.ReadBasic", "Mail.ReadWrite"],
  scopes: ["User.Read", "offline_access", "Mail.Read", "Mail.ReadBasic", "Mail.ReadWrite", "Mail.Send"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
