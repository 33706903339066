import React, { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { Badge, Col, Dropdown, DropdownMenu, DropdownToggle, Input, Row } from "reactstrap";
import Flatpickr from "react-flatpickr";
import Button from '../../components/Button/Button';
import { addIndividualInCompany, getUserProfile, select2Styles, yearsOnward, yearsBackword, yearsBackword_Sort } from '../../helpers/functions_helper';
import { CommonAction } from "../../store/actions";
import { Month } from '../../utils/data';
import { dateFormat } from "../../utils/formats";
import commonService from "../../services/common.service";
import DatePicker from "../../components/Form/DatePicker/DatePicker";
import { currentMonth, currentYear } from "../../common/staticdata";

interface GraphFiltersProps {
    clearFilter: any;
    setFilter: any;
    filter: any;
    enableMyTimesheet?: boolean;
    enableUser?: boolean;
    enableCompany?: boolean;
    multiYearSelection?: boolean;
    slug?: string;
}


const ChartFilters: FC<GraphFiltersProps> = ({ setFilter, clearFilter, filter, enableMyTimesheet = false, enableUser = true, enableCompany = true, multiYearSelection = false, slug = '' }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const WeekOption = [
        // { label: `Today`, value: `today` },
        { label: t(`Last Week`), value: `lastweek` },
        { label: t(`This Week`), value: `week` },
    ]

    const userObj = getUserProfile(slug);

    const { getCompanyOptionsList, getUserOptionsList, getContactPersonOptionsList } = useSelector((state: any) => ({
        getCompanyOptionsList: state.Common.GET_COMPANY_OPTIONS_LIST_SUCCESS_NEW?.data,
        getUserOptionsList: state.Common.GET_USER_OPTIONS_LIST_SUCCESS_NEW?.data?.filter((i: any) => !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? i.value === userObj.USER_ID : i),
        getContactPersonOptionsList: state.Common.GET_USER_OPTIONS_LIST_SUCCESS_NEW?.data
    }))


    const [companyOption, setCompanyOption] = useState([]);
    const [contactPersonOption, setContactPersonOption] = useState([]);
    const [users, setUsers] = useState([]);

    const years = useMemo(() => yearsBackword_Sort(), []);
    const [quarters, setQuarters] = useState<any>([]);
    const [open, setOpen] = useState<boolean>(false);
    const [isConstom, setCostom] = useState<boolean>(false);
    const [query, setQuery] = useState("");


    useEffect(() => {
        setQuarters([
            { label: `Q1`, value: `q1-${filter.year}` },
            { label: `Q2`, value: `q2-${filter.year}` },
            { label: `Q3`, value: `q3-${filter.year}` },
            { label: `Q4`, value: `q4-${filter.year}` },
        ])
    }, [filter.year])

    useEffect(() => {
        if (getCompanyOptionsList && getCompanyOptionsList?.length > 0) {
            const item = addIndividualInCompany(getCompanyOptionsList) as any;
            setCompanyOption(item);
        }
    }, [getCompanyOptionsList])

    useEffect(() => {
        setUsers(getUserOptionsList);
    }, [filter.company_id])

    const getDependentSelectOptions = async (
        tableName: string,
        fieldName: string,
        whereField: string,
        whereID: number
    ) => {
        const response = await commonService.getDependentSelectOptionsList(
            tableName,
            fieldName,
            whereField,
            whereID,
            query ? query : ""
        );
        return response.data.data;
    };



    useEffect(() => {
        // setloadingContactPerson(true);
        getDependentSelectOptions(
            "tbl_master_users",
            "first_name",
            "company",
            parseInt(filter.company_id)
        ).then(function (options) {
            setContactPersonOption(options);
            // setloadingContactPerson(false);
        })

    }, [filter.company_id])

    // useEffect(() => {
    //     console.log("getContactPersonOptionsList", getContactPersonOptionsList)
    // }, [getContactPersonOptionsList])


    const badges = useMemo(() => {
        return Object.entries(filter)?.map((f: any) => (
            f[1] && (
                ((userObj.CAN_VIEW_ALL || userObj.USER_ID !== f[1]) && f[0] !== 'graphview') &&
                <Badge className="rounded-pill badge-soft-secondary font-size-12 mx-1 px-1 py-1 align-vertical">
                    <span className="align-middle material-symbols-outlined fw-light font-size-17 label-icon mx-1">
                        {f[0] === 'user_id' || f[0] === 'contact_person' ? 'person' : null}
                        {f[0] === 'company_id' ? 'apartment' : null}
                        {f[0] === 'week' ? 'view_week' : null}
                        {f[0] === 'month' ? 'calendar_month' : null}
                        {f[0] === 'quarter' ? 'calendar_month' : null}
                        {f[0] === 'year' ? 'calendar_month' : null}
                        {f[0] === 'to_date' ? 'calendar_month' : null}
                        {f[0] === 'from_date' ? 'calendar_month' : null}
                        {f[0] === 'graphview' ? 'view_week' : null}
                    </span>
                    <span className="align-middle">
                        {f[0] === 'year' ? f[1] : null}
                        {f[0] === 'quarter' ? quarters.find((a: any) => a.value === f[1])?.label : null}
                        {f[0] === 'month' ? Month.find((a: any) => a.value === f[1])?.label : null}
                        {f[0] === 'week' ? WeekOption.find((a: any) => a.value === f[1])?.label : null}
                        {getUserOptionsList && f[0] === 'user_id' ? getUserOptionsList?.find((a: any) => a.value === f[1])?.label : null}
                        {
                            //@ts-ignore
                            contactPersonOption && f[0] === 'contact_person' ? contactPersonOption?.find((a: any) => a.value === f[1])?.label : null}
                        {
                            //@ts-ignore
                            companyOption && f[0] === 'company_id' ? companyOption.find((a: any) => a.value === f[1])?.label : null}
                        {f[0] === 'from_date' ? dateFormat(f[1]) : null}
                        {f[0] === 'to_date' ? dateFormat(f[1]) : null}
                    </span>

                    <span className="align-middle material-symbols-outlined fw-light font-size-17 label-icon mx-1 cursor-pointer" title={t("Remove")} onClick={() => setFilter((filter: any) => ({
                        ...filter,
                        [f[0]]: null
                    }))}>
                        {t("close")}
                    </span>
                </Badge>
            )
        ))
    }, [filter]
    )

    useEffect(() => {
        setFilter((filter: any) => ({
            ...filter,
            year: currentYear,
        }));

        if (!getCompanyOptionsList) dispatch(CommonAction.getCompanyOptionsList_New());
        if (!getUserOptionsList) dispatch(CommonAction.getUserOptionsList_New());
    }, []);

    return (
        <>
            <ul className="nav nav-pills">
                <li className="align-middle mx-2">
                    {badges}
                    {/* {JSON.stringify(filter)} */}
                </li>
                {enableMyTimesheet &&
                    <li className="nav-item">
                        {userObj.CAN_VIEW_ALL &&
                            <div className="mx-2">
                                <Button
                                    color={filter?.user_id === userObj.USER_ID ? 'secondary' : "primary"}
                                    size="sm"
                                    className="btn-label"
                                    disabled={filter?.user_id === userObj.USER_ID}
                                    onClick={(e: any) => {
                                        setFilter((filter: any) => ({
                                            ...filter,
                                            user_id: userObj.USER_ID
                                        }));
                                    }}
                                >
                                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">account_circle</span>{t("My Timehseet")}
                                </Button>
                            </div>
                        }
                    </li>
                }
                <li className="nav-item">
                    <div className="ms-auto">
                        <Dropdown isOpen={open} toggle={() => setOpen((state: boolean) => true)}>
                            <DropdownToggle
                                tag='span'
                            >
                                <Button
                                    title="Filter"
                                    size="sm"
                                    color={(filter?.company_id || filter?.user_id || filter?.month || filter?.week || filter?.year || filter?.quarter) ? "secondary" : "primary"}
                                >
                                    {(filter?.company_id || filter?.user_id || filter?.month || filter?.week || filter?.year || filter?.quarter) ? (
                                        <span className="align-middle material-symbols-outlined fw-light fs-5">filter_alt_off</span>
                                    ) : (
                                        <span className="align-middle material-symbols-outlined fw-light fs-5">filter_alt</span>
                                    )}
                                </Button>
                                {/* <Button color="primary" size="sm">
                                    <i className={`fa ${(filter?.company_id || filter?.user_id || filter?.month || filter?.week || filter?.year || filter?.quarter) ? "fa-filter-circle-xmark" : "fa-filter"}`}></i>
                                </Button> */}
                            </DropdownToggle>
                            <DropdownMenu>
                                <div style={{ width: '320px' }} className="p-3 pb-0 pt-1">
                                    <Row>
                                        <Col>
                                            <h5>{t('Filter')}</h5>
                                        </Col>
                                        <Col className="mb-3 d-flex">
                                            <Button className="ms-auto p-0" size="sm" outline color="danger" onClick={() => setOpen(false)}>
                                                <span className="align-middle material-symbols-outlined fw-light fs-4">close</span>
                                            </Button>
                                        </Col>
                                    </Row>
                                    {enableCompany &&
                                        <>
                                            <Row>
                                                {!!getCompanyOptionsList &&
                                                    <Col>
                                                        <Select
                                                            id="company"
                                                            name="company"
                                                            className="form-select-sm myClassName mb-3"
                                                            styles={select2Styles}
                                                            placeholder={t("Select client")}
                                                            options={companyOption}
                                                            isLoading={!getCompanyOptionsList}
                                                            value={filter.company_id ? companyOption.find((i: any) => i.value === filter.company_id) : null}
                                                            onChange={(option: any) => {
                                                                setFilter((filter: any) => ({
                                                                    ...filter,
                                                                    company_id: option?.value
                                                                }));
                                                            }}
                                                        />
                                                    </Col>

                                                }
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Select
                                                        id="contact_person"
                                                        name="contact_person"
                                                        className="form-select-sm myClassName mb-3"
                                                        styles={select2Styles}
                                                        placeholder="Select Contact Person"
                                                        options={contactPersonOption}
                                                        isDisabled={!getUserOptionsList || !filter.company_id}
                                                        value={filter.contact_person ? contactPersonOption?.find((i: any) => i.value === filter.contact_person) : null}
                                                        onChange={(option: any) => {
                                                            setFilter((filter: any) => ({
                                                                ...filter,
                                                                contact_person: option?.value
                                                            }));
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                    {((userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) && enableUser) &&
                                        <Row>
                                            <Col>
                                                <Select
                                                    id="user_id"
                                                    name="user_id"
                                                    className="form-select-sm myClassName mb-3"
                                                    styles={select2Styles}
                                                    placeholder={t("Select user")}
                                                    options={users}
                                                    isDisabled={!getUserOptionsList}
                                                    value={filter.user_id ? users?.find((i: any) => i.value === filter.user_id) : null}
                                                    onChange={(option: any) => {
                                                        setFilter((filter: any) => ({
                                                            ...filter,
                                                            user_id: option?.value
                                                        }));
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    }
                                    {!isConstom ? (
                                        <>
                                            <Row>
                                                <Col>
                                                    <Select
                                                        id="week"
                                                        name="week"
                                                        className="form-select-sm myClassName mb-2"
                                                        styles={select2Styles}
                                                        placeholder={t("Week")}
                                                        options={WeekOption}
                                                        value={filter.week ? WeekOption.find((i: any) => i.value === filter.week) : null}
                                                        onChange={(option: any) => {
                                                            setFilter((filter: any) => ({
                                                                ...filter,
                                                                week: option?.value,
                                                                quarter: null,
                                                                month: null,
                                                                year: null
                                                            }));
                                                        }}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Select
                                                        id="month"
                                                        name="month"
                                                        className="form-select-sm myClassName mb-3"
                                                        styles={select2Styles}
                                                        placeholder={t("Month")}
                                                        options={Month}
                                                        value={filter.month ? Month.find((i: any) => i.value === filter.month) : null}
                                                        isDisabled={!filter.year}
                                                        onChange={(option: any) => {
                                                            setFilter((filter: any) => ({
                                                                ...filter,
                                                                week: null,
                                                                quarter: null,
                                                                month: option?.value
                                                            }))
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Select
                                                        id="quarter"
                                                        name="quarter"
                                                        className="form-select-sm myClassName"
                                                        styles={select2Styles}
                                                        placeholder={t("Quarter")}
                                                        options={quarters}
                                                        value={filter.quarter ? quarters.find((i: any) => i.value === filter.quarter) : null}
                                                        isDisabled={!filter.year}
                                                        onChange={(option: any) => {
                                                            setFilter((filter: any) => ({
                                                                ...filter,
                                                                week: null,
                                                                quarter: option?.value,
                                                                month: null
                                                            }))
                                                        }}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Select
                                                        id="year"
                                                        name="year"
                                                        className="form-select-sm myClassName mb-1"
                                                        styles={select2Styles}
                                                        placeholder={t("Year")}
                                                        options={years}
                                                        // isMulti={multiYearSelection}
                                                        value={filter.year ? years.find((i: any) => i.value === filter.year) : null}
                                                        onChange={(option: any) => {
                                                            setFilter((filter: any) => ({
                                                                ...filter,
                                                                week: null,
                                                                year: multiYearSelection ? option : option.value
                                                            }))
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="text-center">
                                                    <Button
                                                        color="link"
                                                        onClick={() => setCostom(true)}
                                                    >
                                                        {t('Custom date range')}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </>) : (
                                        <>
                                            <Row>
                                                <Col xs="6">
                                                    {/* <Input
                                                        id="from_date"
                                                        name="from_date"
                                                        className="form-control d-block form-control-sm"
                                                        type="date"
                                                        placeholder={t("From Date")}
                                                        value={filter?.from_date}
                                                        onChange={(e: any) =>
                                                            setFilter((filter: any) => ({
                                                                ...filter,
                                                                week: null,
                                                                quarter: null,
                                                                month: null,
                                                                year: null,
                                                                from_date: e.target.value
                                                            }))
                                                        }
                                                    /> */}
                                                    <DatePicker
                                                        id="from_date"
                                                        name="from_date"
                                                        className="form-control-sm"
                                                        type="text"
                                                        placeholder="From Date"
                                                        value={filter?.from_date}
                                                        sm
                                                        options={{
                                                            altInput: true,
                                                            altFormat: "d/m/Y",
                                                            dateFormat: "Y-m-d",
                                                            allowInput: true,
                                                            // mode: "range",
                                                            onChange: function (selectedDates, dateStr, instance) {
                                                                setFilter((filter: any) => ({
                                                                    ...filter,
                                                                    week: null,
                                                                    quarter: null,
                                                                    month: null,
                                                                    year: null,
                                                                    from_date: dateStr
                                                                }));
                                                            },
                                                            locale: { firstDayOfWeek: 1 }
                                                        }}
                                                    />
                                                </Col>
                                                <Col xs="6">
                                                    {/* <Input
                                                        id="to_date"
                                                        name="to_date"
                                                        className="form-control d-block form-control-sm"
                                                        type="date"
                                                        placeholder={t("To Date")}
                                                        value={filter?.to_date}
                                                        onChange={(e: any) =>
                                                            setFilter((filter: any) => ({
                                                                ...filter,
                                                                week: null,
                                                                quarter: null,
                                                                month: null,
                                                                year: null,
                                                                to_date: e.target.value
                                                            }))
                                                        }
                                                    /> */}
                                                    <DatePicker
                                                        id="to_date"
                                                        name="to_date"
                                                        className="form-control d-block form-control-sm"
                                                        type="text"
                                                        placeholder="To Date"
                                                        value={filter?.to_date}
                                                        sm
                                                        options={{
                                                            altInput: true,
                                                            altFormat: "d/m/Y",
                                                            dateFormat: "Y-m-d H:i:S",
                                                            minDate: filter?.from_date,
                                                            allowInput: true,
                                                            onChange: function (selectedDates, dateStr, instance) {
                                                                setFilter((filter: any) => ({
                                                                    ...filter,
                                                                    week: null,
                                                                    quarter: null,
                                                                    month: null,
                                                                    year: null,
                                                                    to_date: dateStr
                                                                }));
                                                            },
                                                            locale: { firstDayOfWeek: 1 }
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="text-center">
                                                    <Button
                                                        color="link"
                                                        onClick={() => setCostom(false)}
                                                    >
                                                        {t('< Go Back')}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </div>
                            </DropdownMenu>

                        </Dropdown>

                    </div>
                </li>
                <li className="nav-item" style={{ 'marginLeft': '5px' }}>
                    <Button color="primary" size="sm" onClick={() => clearFilter()}>
                        <span className="align-middle material-symbols-outlined fw-light fs-5">autorenew</span>
                    </Button>
                </li>
            </ul>
        </>
    )
}

export default ChartFilters;