import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { prepareAssets } from './utils/prepareAssets';
import { getDocumentByID } from '../DocSignature/helper';
import DocEdit from './DocEdit';
import { getUserProfile } from '../../../helpers/functions_helper';
import { Card, CardBody, Col, Container, Navbar, NavbarBrand, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const EditTemplate = () => {
    const { id, doc_id } = useParams();
    const { t } = useTranslation();
    const moduleHeading = t('Edit document');
    document.title = `${moduleHeading} | ` + process.env.REACT_APP_NAME;
    const [templateData, setTemplateData] = useState<any>([]);
    useEffect(() => {
        if (id && doc_id) {
            getDocument(id, doc_id);
            prepareAssets();
        }
    }, []);

    const getDocument = async (id: any, doc_id: any) => {
        const result = await getDocumentByID(id, doc_id);
        if (result.status == "success" && result.data) {
            setTemplateData(result?.data);
        }
    }

    return (

        <div className={"page-content"}>
            <Container fluid>
                <Row>
                    <Col xl="12">
                        <Card>
                            <CardBody>
                                <Navbar light expand="md">
                                    <NavbarBrand href="/">{t("Edit document")}</NavbarBrand>
                                </Navbar>
                                <DocEdit id={id} template_id={doc_id} templateData={templateData?.data} attachmentData={templateData?.template_data} type="document" />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div >
    )
}

export default EditTemplate