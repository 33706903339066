import React, { useState, useEffect, useRef } from 'react';
import { DragActions } from '../entities';
import { getMovePosition } from '../utils/helpers';
import { Image as ImageComponent } from '../components/Image';
import { position } from 'html2canvas/dist/types/css/property-descriptors/position';
import moment from 'moment';

const IMAGE_MAX_SIZE = 300;

interface Props {
  keyIndex: number;
  width: number;
  height: number;
  x: number;
  y: number;
  setWidth: any;
  setHeight: any;
  setPositions: any;
  setNewAttachment: any;
  attachment: any;
  setNewIndex: any;
  updateImageAttachment: (imageObject: Partial<ImageAttachment>) => void;
  removeImage: any;
  data: any;
}

export const Image = ({
  keyIndex,
  img,
  width,
  height,
  x,
  y,
  setWidth,
  setHeight,
  setPositions,
  setNewAttachment,
  attachment,
  setNewIndex,
  updateImageAttachment,
  removeImage,
  data
}: ImageAttachment & Props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [dimmerActive, setDimmerActive] = useState(false);
  const [newwidth, setNewWidth] = useState(width);
  const [newheight, setNewHeight] = useState(height);
  const [newPosition, setNewPosition] = useState({ x: x, y: y });
  useEffect(() => {
    const renderImage = (img: HTMLImageElement) => {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const context = canvas.getContext('2d');
      if (!context) return;

      context.drawImage(img, 0, 0, width, height - 70);
      if (data?.user_first_name) {
        const text = data.user_first_name + ' ' + data.user_last_name;
        context.fillStyle = 'black';
        context.font = '14px Arial';
        const textWidth = context.measureText(text).width;
        const x = (canvas.width - textWidth) / 2;
        const y = canvas.height - 50;
        context.fillText(text, x, y);
        const text2 = moment(data?.created_at).format("DD/MM/Y HH:mm:ss");
        context.fillStyle = 'black';
        context.font = '14px Arial';
        const textWidth2 = context.measureText(text2).width;
        const x2 = (canvas.width - textWidth2) / 2;
        const y2 = canvas.height - 30;
        context.fillText(text2, x2, y2);
        const text3 = "IP:" + data?.ip_address;
        context.fillStyle = 'black';
        context.font = '14px Arial';
        const textWidth3 = context.measureText(text3).width;
        const x3 = (canvas.width - textWidth3) / 2;
        const y3 = canvas.height - 10;
        context.fillText(text3, x3, y3);
      }


      canvas.toBlob((blob) => {
        setNewAttachment({
          ...attachment,
          index: keyIndex,
          file: blob as File,
          width: width - 30,
          height: height,
          x: newPosition.x,
          y: newPosition.y
        })
      });
    };
    renderImage(img);
  }, []);

  const handleClick = () => setDimmerActive(true);
  const onCancelDelete = () => setDimmerActive(false);

  const deleteImage = () => {
    onCancelDelete();
    //removeImage();
  };

  const handleDrag = (e: any, d: any) => {
    const newPosition = { x: d.x, y: d.y };
    setNewPosition(newPosition);
  };


  const handleResize = (e: any, direction: any, ref: any, delta: any, position: any) => {
    const newSize = { width: ref.offsetWidth, height: ref.offsetHeight };
    setNewWidth(newSize.width);
    setNewHeight(newSize.height);
  };


  useEffect(() => {
    setWidth(newwidth);
    setHeight(newheight);
    setPositions(newPosition);
    setNewIndex(keyIndex);
    updateImageAttachment({
      width: newwidth,
      height: newheight,
      x: newPosition.x,
      y: newPosition.y,
      index: keyIndex,
    });
  }, [newwidth, newheight, newPosition])

  return (
    <ImageComponent
      onClick={handleClick}
      dimmerActive={dimmerActive}
      cancelDelete={onCancelDelete}
      deleteImage={deleteImage}
      x={newPosition.x}
      y={newPosition.y}
      canvasRef={canvasRef}
      width={newwidth}
      height={newheight}
      handleDrag={handleDrag}
      handleResize={handleResize}
      removeImage={removeImage}
    />
  );
};
