import React, { useEffect, useState } from "react";
//import { Link } from "react-router-dom";
//import Dropzone from "react-dropzone";
import {
  Button,
  Col,
  Form,
  Label,
  Row,
  FormFeedback,
  UncontrolledAlert,
  Modal,
  ModalBody,
  ModalFooter,

} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
//redux
import { useSelector } from "react-redux";
import Input from "../../../components/Form/Input";
import { useTranslation } from "react-i18next";

// Import Breadcrumb
//import Breadcrumbs from "../../../components/Layout/Breadcrumb";
const QuestionnaireForm = (props: any) => {
  const {t} = useTranslation();
  let statusData = props.statusData;

  // for setting color picker value
  const [color, setColor] = useState('');
  const handlecolor = (e: any) => {
    setColor(e.target.value)
  }

  const [error, setError] = useState('');

  let data = useSelector((state: any) => ({
    success: state.QuestionnaireStatus.success,
    error: state.QuestionnaireStatus.error,
    isModel: state.QuestionnaireStatus.isModel,
  }));


  useEffect(() => {
    if (data.error !== error) setError(data.error);
  }, [data.error]);

  const validationSchema = Yup.object().shape({
    questionnaire_status_value: Yup.string()
      .required("Questionnaire status field is required.")
      .min(2, "Questionnaire status must be greater than 2 characters.")
      .max(100, "Questionnaire status may not be greater than 100 characters.")

  });

  const validation = useFormik({
    initialValues: {
      id: (statusData && statusData.id) || '',
      questionnaire_status_id: (statusData && statusData.questionnaire_status_id) || '',
      questionnaire_status_value: (statusData && statusData.questionnaire_status_value) || '',
      color_code: (statusData && statusData.color_code) || "#000000"
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      props.onSubmitClick(values);
    },
  });


  return (
    <React.Fragment>
      <Modal
        size="md"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">{props.modalTitle}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label="Close"
          ></button>
        </div>
        <Row>
          <Col lg="12">
            {error && data.isModel && error !== '' ? (
              <UncontrolledAlert color="danger" role="alert">
                {error}
              </UncontrolledAlert>
            ) : null}
            <Form
              onSubmit={(e: any) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              onChange={() => setError('')}
            >
              <ModalBody>
                <Col lg="12">
                  <div className="mb-3">
                    <div className='color_palette_main_div'>
                    <Input
                      name="questionnaire_status_value"
                      className="form-control"
                      placeholder="Questionnaire status"
                      label="Questionnaire status"
                      required
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.questionnaire_status_value || ""}
                      invalid={
                        validation.touched.questionnaire_status_value &&
                          validation.errors.questionnaire_status_value
                          ? true
                          : false
                      }
                      error={validation.errors.questionnaire_status_value}
                      touched={validation.touched.questionnaire_status_value}
                    />
                      <div className="color_palette_div">
                        <input
                          name="color_code"
                          className="color_palette form-control"
                          type="color"
                          id="example-color-input"
                          onChange={e => { handlecolor(e); validation.handleChange(e) }}
                          onBlur={validation.handleBlur}
                          value={validation.values.color_code || ""}

                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <ModalFooter>
                  <Button
                    color="primary"
                    className="btn-block"
                    type="submit"
                    disabled={props.loading}
                  >
                    {props.loading ? (
                      <span>
                        <i className="fa-solid fa-spinner fa-spin"></i>
                        <span>&nbsp; {t("Please wait...")}</span>
                      </span>
                    ) : (
                      <span>{t("Submit")}</span>
                    )}
                  </Button>
                  <Button
                    color="danger"
                    className="btn-block"
                    type="reset"
                    onClick={props.onCloseClick}
                    disabled={props.loading}
                  >
                    {t("Cancel")}
                  </Button>
                </ModalFooter>
              </ModalBody>
            </Form>
          </Col>
        </Row>
      </Modal>
    </React.Fragment >
  );
};
export default QuestionnaireForm;
