import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class EmailServiceCategory {

    async getEmailCategory(DATA: any) {
        const response = await axios
            .get(SERVER_URL + url.EMAIL_CATEGORY.GET + "/" + DATA.id + "/" + DATA.fee_id,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async getEmailCategoryList(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.EMAIL_CATEGORY.GET_ALL, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async createEmailCategory(DATA: any) {
        const response = await axios
            .put(SERVER_URL + url.EMAIL_CATEGORY.CREATE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async createDuplicateEmailCategory(DATA: any) {
        const response = await axios
            .put(SERVER_URL + url.EMAIL_CATEGORY.DUPLICATE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async updateEmailCategory(DATA: any) {
        const response = await axios
            .put(SERVER_URL + url.EMAIL_CATEGORY.UPDATE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async deleteEmailCategory(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.EMAIL_CATEGORY.DELETE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }



}

export default new EmailServiceCategory();