import React, { FC, useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Input, Label, Nav, NavItem, Row, Spinner } from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import issueReportingService from "../../services/issue.reporting.service";
import { toast } from "react-toastify";
// @ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
// @ts-ignore
import CustomEditor from "ckeditor5-custom-build/build/ckeditor";
import { getUserProfile, ckEditorConfiguration } from "../../helpers/functions_helper";
import { useTranslation } from "react-i18next";
import { map } from "lodash";
import moment from "moment";
import SimpleBar from "simplebar-react";
import { renderRowHTML } from "../../utils/utils";
const CommentDrawal: FC<any> = (props) => {
  const { data, onClose } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const toastId = React.useRef(null) as any;
  const [initialValues, setInitialValues] = useState({
    comment: ''
  })
  const [commentList, setCommentList] = useState<any>([]);
  const handleChange = (fieldName: string, fieldValue: string) => {
    setInitialValues(preState => ({ ...preState, [fieldName]: fieldValue }));
  };

  const postComment = async () => {
    toast.dismiss();

    toastId.current = toast(t("Updating comment..."), {
      autoClose: false,
      isLoading: true,
    });
    const postData = {
      jira_id: data.jira_id,
      comment: initialValues.comment
    }
    const res = await issueReportingService.postComment(postData);
    if (res.status === 'success') {
      toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: t('Comment posted successfully'), autoClose: 5000, isLoading: false, delay: 500 });
      if (data.jira_id) {
        getComment(data.jira_id);
        setInitialValues({ comment: '' });
        onClose();
      }
    }
    else {
      toast.error('error');
    }
  }

  const getComment = async (id: string) => {
    setLoading(true);
    const res = await issueReportingService.getComment(id);
    if (res.status === 'success') {
      setLoading(false);
      if (res?.data?.body?.comments)
        setCommentList(res?.data?.body?.comments);
    }
    else {

      setLoading(false);
      toast.error('error');
    }
  }

  const getMediaThum =async (id: string) => {
    const res = await issueReportingService.getThumbnail(data.jira_id);
    console.log(res);
    return res;
  }

  const downloadMediaFile =async (id: string) => {
    
  }


  useEffect(() => {
    if (data.jira_id) {
      getComment(data.jira_id);
    }
  }, [data.jira_id])


  return (
    <Container>
      <Row>

        <Col xl={12} lg={12} md={12} sm={12}>
          <div id="email_ckeditor" className="mb-3">
            <Label className="form-label">
              {t("Comment")}<span className="text-warning is_required">*</span>
            </Label>
            <Input
              type="textarea"
              style={{ height: '100px' }}
              data={initialValues.comment}
              onChange={(e) => {
                handleChange("comment", e.target.value);
              }}
              id="comment"
              name="comment"
              className="form-control"
            />
          </div>
        </Col>
        <Col className="d-flex justify-content-end">
          <Button
            color="primary"
            onClick={() => postComment()}
          >
            Save
          </Button>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col xl={12} lg={12} md={12} sm={12}>
          <Label className="form-label fw-bold">
            {t("Comments")}
          </Label>
        </Col>
        <Col>
          <SimpleBar style={{ height: '500px' }}>
            <ul className="list-unstyled">
              {
                (commentList && !loading) ? map(commentList, (item: any, index) =>
                  <li className="list-group-item p-2 border-top border-secondary" key={index}>
                    <div className="d-flex">
                      {/* <div className="flex-shrink-0 me-3">
                        <div className="avatar-xs me-3">
                          <span className="avatar-title rounded-circle bg-light">
                            <img src={item?.author.avatarUrls['48x48']} height={18} />
                          </span>
                        </div>
                      </div> */}
                      <div className="flex-grow-1">
                        {/* <h5 className="font-size-14">Donec vitae sapien ut</h5> */}
                        <p className="text-muted text-justify">
                        {renderRowHTML(item?.renderedBody)}
                        </p>
                        <div className="float-end">
                          <p className="mb-0 d-flex text-primary">
                            <span className="material-symbols-outlined fs-5">
                              person
                            </span> {item?.author.displayName}</p>
                        </div><p className="mb-0 text-primary">{moment(item?.created).format("dd, MMMM Do YYYY, h:mm")}</p>
                      </div>
                    </div>
                  </li>) :
                  <div className="d-flex justify-content-center">
                    <Spinner
                      color="primary"
                      size="sm"
                      type="grow"
                    >
                      Loading...
                    </Spinner>
                  </div>
              }
            </ul>
          </SimpleBar>
        </Col>

      </Row>
    </Container>
  );
};

export default CommentDrawal;
