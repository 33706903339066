import React from "react";
import { Nav, NavItem } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getUserProfile } from "../../../helpers/functions_helper";

const NavItems = (props: any) => {
  const { t } = useTranslation();
  const userProfile = getUserProfile("all-company");

  return (
    <React.Fragment>
      <Nav tabs>
        <NavItem>
          <Link
            style={{ cursor: "pointer" }}
            to={`/masters/company/company-details/${props.id}/${props.company_id}`}
            className={`${
              window.location.pathname ===
              "/masters/company/company-details/" +
                props.id +
                "/" +
                props.company_id
                ? "active"
                : ""
            }  nav-link`}
          >
            {t("Company details")}
          </Link>
        </NavItem>

        <NavItem>
          <Link
            style={{ cursor: "pointer" }}
            to={`/masters/company/general/${props.id}/${props.company_id}`}
            className={`${
              window.location.pathname ===
              "/masters/company/general/" + props.id + "/" + props.company_id
                ? "active"
                : ""
            }  nav-link`}
          >
            {t("General")}
          </Link>
        </NavItem>

        <NavItem>
          <Link
            style={{ cursor: "pointer" }}
            to={`/masters/company/attachments/${props.id}/${props.company_id}`}
            className={`${
              window.location.pathname ===
              "/masters/company/attachments/" +
                props.id +
                "/" +
                props.company_id
                ? "active"
                : ""
            }  nav-link`}
          >
            {t("Permanent documents")}
          </Link>
        </NavItem>

        <NavItem>
          <Link
            style={{ cursor: "pointer" }}
            to={`/masters/company/documents/${props.id}/${props.company_id}`}
            className={`${
              window.location.pathname ===
              "/masters/company/documents/" + props.id + "/" + props.company_id
                ? "active"
                : ""
            }  nav-link`}
          >
            {t("Yearly documents")}
          </Link>
        </NavItem>

        {userProfile.USER_COMPANY === 1 ? (
          <NavItem>
            <Link
              style={{ cursor: "pointer" }}
              to={`/masters/company/projects/${props.id}/${props.company_id}`}
              className={`${
                window.location.pathname ===
                "/masters/company/projects/" + props.id + "/" + props.company_id
                  ? "active"
                  : ""
              }  nav-link`}
            >
              {t("Projects")}
            </Link>
          </NavItem>
        ) : null}

        <NavItem>
          <Link
            style={{ cursor: "pointer" }}
            to={`/masters/company/employees/${props.id}/${props.company_id}`}
            className={`${
              window.location.pathname ===
              "/masters/company/employees/" + props.id + "/" + props.company_id
                ? "active"
                : ""
            }  nav-link`}
          >
            {t("Employees")}
          </Link>
        </NavItem>

        <NavItem>
          <Link
            style={{ cursor: "pointer" }}
            to={`/masters/company/questionnaires/${props.id}/${props.company_id}`}
            className={`${
              window.location.pathname ===
              "/masters/company/questionnaires/" +
                props.id +
                "/" +
                props.company_id
                ? "active"
                : ""
            }  nav-link`}
          >
            {t("Questionnaires")}
          </Link>
        </NavItem>

        {userProfile.IS_ADMIN ||
        userProfile.IS_COMPANY_ADMIN ||
        userProfile.IS_PARTNER ? (
          <NavItem>
            <Link
              style={{ cursor: "pointer" }}
              to={`/masters/company/invoices/${props.id}/${props.company_id}`}
              className={`${
                window.location.pathname ===
                "/masters/company/invoices/" + props.id + "/" + props.company_id
                  ? "active"
                  : ""
              }  nav-link`}
            >
              {t("Invoices")}
            </Link>
          </NavItem>
        ) : null}
      </Nav>
    </React.Fragment>
  );
};

export default NavItems;
