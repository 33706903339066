import { useEffect, useState } from "react";
import { dateFormat } from "../../../utils/formats";
import { Button, CardBody, CardHeader, CardTitle, Col, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import React from "react";
import DataTableBasic from "../../../components/Common/DataTableBasic";
import { getUserProfile, yearsBackword } from "../../../helpers/functions_helper";
import { useTranslation } from "react-i18next";

type Type = {
  saldo_year: string;
  saldo_employer: number;
  saldo_employee: number;
  saldo_total: number;
};

const FinalTaxLiability = (props: any) => {
  const { t } = useTranslation();
  const userProfile = getUserProfile();
  const { onChange } = props;

  const [initialData, setInitialData] = useState<Type>({
    saldo_year: "",
    saldo_employer: 0,
    saldo_employee: 0,
    saldo_total: 0,
  });

  const columns = [
    {
      dataField: "DT_RowIndex",
      text: t("Sr. No."),
      formatter: (rowContent: any, row: any, idx: number): any => {
        return idx + 1;
      },
    },
    {
      dataField: "saldo_year",
      text: t("Income year"),
    },
    {
      dataField: "saldo_employer",
      text: t("Employer"),
    },
    {
      dataField: "saldo_employee",
      text: t("Employee"),
    },
    {
      dataField: "saldo_total",
      text: t("Total"),
    },
    {
      dataField: "action",
      text: t("Action"),
      formatter: (rowContent: any, row: any, idx: number): any => {
        return (
          <div className="d-flex gap-2">
            <Button
              color="primary"
              outline={true}
              className={`btn btn-pill btn-outline-primary btn-sm`}
              type="button"
              onClick={() => editRow(idx)}
            >
              <span className="align-middle material-symbols-outlined fw-light fs-4">edit</span>
            </Button>

            <Button
              color="danger"
              outline={true}
              className={`btn btn-pill btn-outline-danger btn-sm`}
              type="button"
              onClick={() => removeRow(idx)}
            >
              <span className="align-middle material-symbols-outlined fw-light fs-4">delete</span>
            </Button>
          </div>
        );
      },
    },
  ];

  const [isEdit, setIsEdit] = useState(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [addRowBtnDisabled, setAddRowBtnDisabled] = useState(true);
  const [data, setData] = useState<any>([]);
  const [dataList, setDataList] = useState<any>([]);
  const [datatableOptions, setDatatableOptions] = useState({
    draw: 1,
    page: 1,
    recordsTotal: 0,
    recordsFiltered: 0,
    sizePerPage: 5,
    searchText: "",
    sortField: "id",
    sortOrder: "desc",
  });

  const onTableChange = (type: any, newState: any) => {
    let draw = datatableOptions.draw + 1;
    let page = 1;
    if (type === "search") {
      page = 1;
    } else {
      page = newState.page;
    }
    setDatatableOptions((prevData: any) => ({
      ...prevData,
      draw: draw,
      page: page,
      sizePerPage: newState.sizePerPage,
      searchText: newState.searchText,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
    }));

    const slicedArray = data.slice(newState.sizePerPage * (page - 1), newState.sizePerPage * page);
    setDataList(slicedArray);
  };

  const addRow = () => {
    setData((preState: any) => [
      ...preState,
      {
        saldo_year: initialData?.saldo_year,
        saldo_employer: initialData?.saldo_employer,
        saldo_employee: initialData?.saldo_employee,
        saldo_total: initialData?.saldo_total,
      },
    ]);

    clearForm();
    setAddRowBtnDisabled(true);
  };

  const updateRow = () => {
    if (editIndex !== null) {
      var rows: any = [...data];
      rows.map((item: any, index: number) => {
        if (index === editIndex) {
          rows[index] = {
            ...item,
            saldo_year: initialData?.saldo_year,
            saldo_employer: initialData?.saldo_employer,
            saldo_employee: initialData?.saldo_employee,
            saldo_total: initialData?.saldo_total,
          };
        }
      });
      setData(rows);

      clearForm();
      setIsEdit(false);
      setEditIndex(null);
    }
  };

  const removeRow = (idx: number) => {
    const rows = data.filter((item: any, index: number) => index !== idx);
    setData(rows);

    setIsEdit(false);
    setEditIndex(null);
  };

  const editRow = (idx: number) => {
    const row = data.find((item: any, index: number) => index === idx);
    setInitialData((preData: any) => ({
      ...preData,
      saldo_year: row?.saldo_year,
      saldo_employer: row?.saldo_employer,
      saldo_employee: row?.saldo_employee,
      saldo_total: row?.saldo_total,
    }));

    setIsEdit(true);
    setEditIndex(idx);
  };

  const clearForm = () => {
    setInitialData((preData: any) => ({
      ...preData,
      saldo_year: "",
      saldo_employer: 0,
      saldo_employee: 0,
      saldo_total: 0,
    }));
  };

  const handleChange = (fieldName: string, fieldValue: any) => {
    setInitialData((preState) => ({ ...preState, [fieldName]: fieldValue }));
  };

  useEffect(() => {
    let saldo_total = Number(initialData.saldo_employer) + Number(initialData.saldo_employee);
    handleChange("saldo_total", saldo_total);

    if (initialData.saldo_year !== "") {
      setAddRowBtnDisabled(false);
    } else {
      setAddRowBtnDisabled(true);
    }
  }, [initialData.saldo_year, initialData.saldo_employer, initialData.saldo_employee]);

  useEffect(() => {
    setData(props?.data);
  }, [props?.data]);

  useEffect(() => {
    setDatatableOptions((prevData: any) => ({
      ...prevData,
      draw: 1,
      page: 1,
      sizePerPage: 5,
      recordsTotal: data.length,
      recordsFiltered: data.length,
    }));

    const slicedArray = data.slice(0, 5);
    setDataList(slicedArray);

    onChange(data);
  }, [data]);

  return (
    <React.Fragment>
      
        <Row>
          <Col xl={2} lg={2} md={2} sm={6}>
            <div className="mb-3">
              <Label className="form-label">
                {t("Income year")} <span className="text-warning is_required">*</span>
              </Label>
              <Select
                id="saldo_year"
                name="saldo_year"
                className="is-invalid"
                placeholder={t("Select...")}
                menuPlacement={"top"}
                options={yearsBackword()}
                value={
                  initialData.saldo_year
                    ? yearsBackword().find((option: any) => option.value == initialData.saldo_year)
                    : ""
                }
                isSearchable={true}
                isDisabled={!userProfile.IS_INTERNAL}
                onChange={(e: any) => {
                  handleChange("saldo_year", e.value);
                }}
              />
            </div>
          </Col>

          <Col xl={2} lg={2} md={2} sm={12}>
            <div className="mb-3">
              <Label className="form-label">{t("Expected saldo for employer")}</Label>
              <Input
                id="saldo_employer"
                name="saldo_employer"
                placeholder={t("Expected saldo for employer")}
                type="number"
                readOnly={!userProfile.IS_INTERNAL}
                value={initialData.saldo_employer}
                onChange={(e) => handleChange("saldo_employer", e.target.value)}
              />
            </div>
          </Col>

          <Col xl={2} lg={2} md={2} sm={12}>
            <div className="mb-3">
              <Label className="form-label">{t("Expected saldo for employee")}</Label>
              <Input
                id="saldo_employee"
                name="saldo_employee"
                placeholder={t("Expected saldo for employee")}
                type="number"
                readOnly={!userProfile.IS_INTERNAL}
                value={initialData.saldo_employee}
                onChange={(e) => handleChange("saldo_employee", e.target.value)}
              />
            </div>
          </Col>

          <Col xl={2} lg={2} md={2} sm={12}>
            <div className="mb-3">
              <Label className="form-label">{t("Expected saldo total")}</Label>
              <Input
                id="saldo_total"
                name="saldo_total"
                placeholder={t("Expected saldo total")}
                type="text"
                readOnly={true}
                value={initialData.saldo_total}
              />
            </div>
          </Col>

          {userProfile.IS_INTERNAL && (
            <Col xl={1} lg={1} md={1} sm={6}>
              <div className="mb-3">
                <Label className="form-label">
                  <br />
                </Label>
                <div className="input-group">
                  {isEdit === true ? (
                    <Button
                      color="success"
                      type="button"
                      className={`btn btn-sm btn-success`}
                      disabled={addRowBtnDisabled}
                      onClick={() => updateRow()}
                    >
                      <span className="align-middle material-symbols-outlined fw-light fs-4">
                        done
                      </span>
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      className={`btn btn-sm ${addRowBtnDisabled ? "btn-primary" : "btn-info"}`}
                      disabled={addRowBtnDisabled}
                      onClick={() => addRow()}
                    >
                      <span className="align-middle material-symbols-outlined fw-light fs-4">
                        add
                      </span>
                    </Button>
                  )}
                </div>
              </div>
            </Col>
          )}
        </Row>

        {dataList && dataList.length > 0 && (
          <DataTableBasic
            columns={columns}
            products={dataList}
            datatableOptions={datatableOptions}
            onTableChange={onTableChange}
          />
        )}
    </React.Fragment>
  );
};

export default FinalTaxLiability;
