import React, { useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardFooter,
  Button,
  Table,
  Container,
} from "reactstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { familyStatusesList, taxYear } from "./helper";
import { formatCurrency } from "../../utils/formats";
import fileDownload from "js-file-download";

import Header from "../../components/Report/Header";
import Footer from "../../components/Report/Footer";

import splitPayrollService from "../../services/split.payroll.service";

//Mail Action
import { setDraftEmailData } from "../../store/mails/actions";
import { useDispatch } from "react-redux";
import { draftmailProps } from "../Email/components/type";
import EmailButton from "../Email/components/EmailButton";
import { renderRowHTML } from "../../utils/utils";
import { getUserProfile } from "../../helpers/functions_helper";

const SplitPayrollReport = (props: any) => {
  const { t } = useTranslation();
  const toastId = React.useRef(null) as any;
  const dispatch = useDispatch();
  const userProfile = getUserProfile("all-split-payroll");
  const initialData = props.initialData;
  const personalInfoInitialData = props.personalInfoInitialData;
  const spCountryData = props.spCountryData;
  const C_GS_TOTAL = props.C_GS_TOTAL;
  const C_BIK_TOTAL = props.C_BIK_TOTAL;
  const C_SS_TOTAL = props.C_SS_TOTAL;
  const C_TOTAL_TAX3 = props.C_TOTAL_TAX3;
  const C_TOTAL_TAX3_BE = props.C_TOTAL_TAX3_BE;
  const C_GI_TOTAL = props.C_GI_TOTAL;
  const C_TI_TOTAL = props.C_TI_TOTAL;
  const C_SSSC_TOTAL = props.C_SSSC_TOTAL;
  const C_NI_TOTAL = props.C_NI_TOTAL;
  const REPORT_STATUS = props.REPORT_STATUS;

  const handleExport = async (for_mail?: boolean) => {
    toastId.current = toast("Exporting report...", {
      autoClose: false,
      isLoading: true,
    });
    const res = await splitPayrollService.exportReport({
      initialData: initialData,
      familyStatuses: familyStatusesList,
      personalInfoInitialData: personalInfoInitialData,
      spCountryData: spCountryData,
      C_GS_TOTAL: C_GS_TOTAL,
      C_BIK_TOTAL: C_BIK_TOTAL,
      C_SS_TOTAL: C_SS_TOTAL,
      C_TOTAL_TAX3: C_TOTAL_TAX3,
      C_TOTAL_TAX3_BE: C_TOTAL_TAX3_BE,
      C_GI_TOTAL: C_GI_TOTAL,
      C_TI_TOTAL: C_TI_TOTAL,
      C_SSSC_TOTAL: C_SSSC_TOTAL,
      C_NI_TOTAL: C_NI_TOTAL,
      REPORT_STATUS: REPORT_STATUS,
    });
    if (res.status === 200) {
      if (for_mail) {
        let file = new File(
          [res.data],
          `cpo-${initialData.computation_year}-${initialData.custome_code}.pdf`,
          { type: "application/pdf", lastModified: new Date().getTime() }
        );
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: t("Report successfully exported"),
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        return file;
      }
      fileDownload(
        res.data,
        `split-payroll-situation-${initialData.computation_year}-${initialData.custome_code}.pdf`
      );
      toast.update(toastId.current, {
        type: toast.TYPE.SUCCESS,
        render: t("Report successfully exported"),
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    } else {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: t("Sorry, something went wrong!"),
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //Email compose
  const composeMail = async () => {
    const user = {
      label: `${initialData?.employee_value}`,
      value: initialData?.employee_email,
    };
    console.log(user);

    const file: any = (await handleExport(true)) || [];
    const draftMail: draftmailProps = {
      title: `Split payroll-${initialData?.employee_value}`,
      filters: {},
      openMailBox: 1,
      subject: `Split payroll-${initialData?.employee_value}`,
      to: [user],
      files: [file],
    };
    dispatch(setDraftEmailData(draftMail));
  };

  return (
    <React.Fragment>
      <Row>
        <Col xl="12">
          <Card>
            <CardHeader className="bg-transparent border-bottom">
              <div className="d-sm-flex flex-wrap">
                <CardTitle className="card-title text-uppercase">{REPORT_STATUS}</CardTitle>
                <div className="ms-auto d-flex gap-2">
                  {REPORT_STATUS === "FINAL" && userProfile.CAN_EXPORT && (
                    <Button color="outline-primary" onClick={() => handleExport()}>
                      <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                        download
                      </span>
                      {t("Export")}
                    </Button>
                  )}

                  <Button color="outline-primary" onClick={() => props.setShowReport(false)}>
                    <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                      chevron_left
                    </span>
                    {t("Back")}
                  </Button>
                  <EmailButton handlerCompose={() => composeMail()} />
                </div>
              </div>
            </CardHeader>

            <div style={{ padding: "10px 8%" }}>
              <Container>
                <div className="invoicePage">
                  <Header />
                  <div className="invoiceBody">
                    <Row>
                      <Col className="col-12">
                        <div className="invoiceHeading1">
                          <span>{t("Split Payroll Situation")}</span>
                          <span>{t("Estimation of Tax and Social Security")}</span>
                        </div>
                      </Col>

                      <Col className="col-12">
                        <div className="table-responsive mb-3">
                          <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                            <tbody>
                              <tr>
                                <th colSpan={9}></th>
                              </tr>
                              <tr>
                                <th colSpan={9}>{t("PERSONAL INFORMATION")}</th>
                              </tr>
                              <tr>
                                <th colSpan={9}></th>
                              </tr>

                              <tr className="align-bottom">
                                <td colSpan={3}>{t("Name of the employee")}</td>
                                <td colSpan={6}>{initialData.employee_value}</td>
                              </tr>
                              <tr className="align-bottom">
                                <td colSpan={3}>{t("Family status")}</td>
                                <td colSpan={6}>
                                  {t(
                                    familyStatusesList.find(
                                      (option: any) => option.value == initialData.family_status
                                    )?.label || ""
                                  )}
                                  {parseInt(initialData.family_status) === 2 &&
                                  parseInt(initialData.spouse_earns) === 2
                                    ? ` ${t("(spouse has no professional income)")}`
                                    : parseInt(initialData.family_status) === 2 &&
                                      parseInt(initialData.spouse_earns) === 1
                                    ? ` ${t("(spouse earns professional income)")}`
                                    : null}
                                </td>
                              </tr>
                              <tr className="align-bottom">
                                <td colSpan={3}>{t("Total number of children")}</td>
                                <td colSpan={6}>
                                  {parseInt(initialData.children_total) === 0
                                    ? t("No dependent children")
                                    : parseInt(initialData.children_under_3) === 0
                                    ? `${initialData.children_total} ${t("dependent children")}`
                                    : `${initialData.children_total} ${t(
                                        "dependent children"
                                      )} (${t("of which")} ${initialData.children_under_3} ${t(
                                        "below 3 years old"
                                      )})`}
                                </td>
                              </tr>
                              <tr className="align-bottom">
                                <td colSpan={3}>{t("Local / City tax rate in Belgium")}</td>
                                <td colSpan={6}>{initialData.municipal_tax}%</td>
                              </tr>
                              <tr className="align-bottom">
                                <td colSpan={3}>{t("Belgian resident taxpayer")}</td>
                                <td colSpan={6}></td>
                              </tr>
                              <tr className="align-bottom">
                                <td colSpan={3}>
                                  {t("Non-resident taxpayer in the other countries")}
                                </td>
                                <td colSpan={6}></td>
                              </tr>

                              <tr>
                                <th colSpan={9}></th>
                              </tr>
                              <tr>
                                <th colSpan={9}>{t("EMPLOYMENT INFORMATION")}</th>
                              </tr>
                              <tr>
                                <th colSpan={9}></th>
                              </tr>

                              {spCountryData &&
                                initialData.computation_year > 0 &&
                                spCountryData.map((item: any, idx: number) => (
                                  <tr key={idx} className="align-bottom">
                                    <td colSpan={3}>
                                      {t("Remuneration in")} {item.country_name}{" "}
                                      {item.emp_dir === 2 ? (
                                        <span>({t("Director function")})</span>
                                      ) : (
                                        <span>({t("Employee function")})</span>
                                      )}
                                    </td>
                                    <td colSpan={2} className="text-end">
                                      {formatCurrency(item.D_GROSS_COUNTRY)}
                                    </td>
                                    <td colSpan={4}></td>
                                  </tr>
                                ))}

                              <tr>
                                <th colSpan={9}></th>
                              </tr>

                              <tr>
                                <td colSpan={3}>
                                  {t("Employee group insurance premiums (annual)")}
                                </td>
                                <td colSpan={2} className="text-end">
                                  {formatCurrency(initialData.employee_group_insurance_premiums)}
                                </td>
                                <td colSpan={4}></td>
                              </tr>

                              <tr>
                                <th colSpan={9}></th>
                              </tr>

                              <tr>
                                <td colSpan={9}>
                                  {spCountryData[0]?.D_COMP_CAR === 0
                                    ? t("No company car")
                                    : `${t(
                                        "Fringe benefit for the private use of a company car computed on the basis of"
                                      )} ${
                                        initialData.fringe_benefit === 1
                                          ? `0 - 12 ${t("months")}`
                                          : `> 60 ${t("months")}`
                                      } 
                                      - ${
                                        personalInfoInitialData.companyCarStatuses.find(
                                          (option: any) =>
                                            option.value == initialData.fringe_benefit
                                        )?.label
                                      }`}
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={9}>
                                  {initialData.sssc_application === 1
                                    ? t(
                                        "Application of the special social security contribution (S.S.S.C.)."
                                      )
                                    : t(
                                        "No application of the special social security contribution (S.S.S.C.)."
                                      )}
                                </td>
                              </tr>

                              <tr>
                                <th colSpan={9}></th>
                              </tr>
                              <tr>
                                <th colSpan={9}>
                                  {t("DETERMINATION OF THE NET SPENDABLE INCOME")}
                                </th>
                              </tr>
                              <tr>
                                <th colSpan={9}></th>
                              </tr>

                              <tr className="align-bottom">
                                <th>{t("Country")}</th>
                                <th className="text-end">
                                  {renderRowHTML(t("Gross <br/>salary"), null)}
                                </th>
                                <th className="text-end">
                                  {renderRowHTML(t("Company <br/>car"), null)}
                                </th>
                                <th className="text-end">
                                  {renderRowHTML(t("Social <br/>security"), null)}
                                </th>
                                <th className="text-end">
                                  {renderRowHTML(t("Taxable <br/>income"), null)}
                                </th>
                                <th className="text-end">
                                  {renderRowHTML(t("Taxes <br/>(incl. S.S.S.C.)"), null)}
                                </th>
                                <th className="text-end">
                                  {renderRowHTML(t("Company <br/>car"), null)}
                                </th>
                                <th className="text-end">
                                  {renderRowHTML(t("Group <br/>insurance"), null)}
                                </th>
                                <th className="text-end">
                                  {renderRowHTML(t("Net <br/>income"), null)}
                                </th>
                              </tr>

                              {spCountryData &&
                                initialData.computation_year > 0 &&
                                spCountryData.map((item: any, idx: number) => (
                                  <tr key={idx}>
                                    <td>
                                      {item.country_name} (
                                      {taxYear(item.tax_year_id, initialData.computation_year)})
                                    </td>
                                    <td className="text-end">
                                      {item.D_GROSS_COUNTRY
                                        ? formatCurrency(
                                            parseFloat(item.D_GROSS_COUNTRY).toFixed(2),
                                            false
                                          )
                                        : "0,00"}
                                    </td>
                                    <td className="text-end">
                                      {item.D_COMP_CAR
                                        ? formatCurrency(
                                            parseFloat(item.D_COMP_CAR).toFixed(2),
                                            false
                                          )
                                        : "0,00"}
                                    </td>
                                    <td className="text-end">
                                      {item.D_SS_COUNTRY
                                        ? `-${formatCurrency(
                                            parseFloat(item.D_SS_COUNTRY).toFixed(2),
                                            false
                                          )}`
                                        : "0,00"}
                                    </td>
                                    <td className="text-end">
                                      {item.D_TTL_TAXABLE_INCOME
                                        ? formatCurrency(
                                            parseFloat(item.D_TTL_TAXABLE_INCOME).toFixed(2),
                                            false
                                          )
                                        : "0,00"}
                                    </td>
                                    <td className="text-end">
                                      {idx === 0
                                        ? formatCurrency((C_TOTAL_TAX3 * -1).toFixed(2), false)
                                        : item.C_TAXES_COUNTRY > 0
                                        ? `-${formatCurrency(
                                            parseFloat(item.C_TAXES_COUNTRY).toFixed(2),
                                            false
                                          )}`
                                        : "0,00"}
                                    </td>
                                    <td className="text-end">
                                      {item.D_COMP_CAR
                                        ? `-${formatCurrency(
                                            parseFloat(item.D_COMP_CAR).toFixed(2),
                                            false
                                          )}`
                                        : "0,00"}
                                    </td>
                                    <td className="text-end">
                                      {item.D_GROUP_INSURANCE
                                        ? `-${formatCurrency(
                                            parseFloat(item.D_GROUP_INSURANCE).toFixed(2),
                                            false
                                          )}`
                                        : "0,00"}
                                    </td>
                                    <td className="text-end">
                                      {item.D_TTL_NET_INCOME
                                        ? formatCurrency(
                                            parseFloat(item.D_TTL_NET_INCOME).toFixed(2),
                                            false
                                          )
                                        : "0,00"}
                                    </td>
                                  </tr>
                                ))}

                              <tr>
                                <th>{t("Total")}</th>
                                <th className="text-end">
                                  {formatCurrency(C_GS_TOTAL.toFixed(2), false)}
                                </th>
                                <th className="text-end">
                                  {formatCurrency(C_BIK_TOTAL.toFixed(2), false)}
                                </th>
                                <th className="text-end">
                                  {formatCurrency((C_SS_TOTAL * -1).toFixed(2), false)}
                                </th>
                                <th className="text-end">
                                  {formatCurrency(C_TI_TOTAL.toFixed(2), false)}
                                </th>
                                <th className="text-end">
                                  {formatCurrency((C_SSSC_TOTAL * -1).toFixed(2), false)}
                                </th>
                                <th className="text-end">
                                  {formatCurrency((C_BIK_TOTAL * -1).toFixed(2), false)}
                                </th>
                                <th className="text-end">
                                  {formatCurrency((C_GI_TOTAL * -1).toFixed(2), false)}
                                </th>
                                <th className="text-end">
                                  {formatCurrency(C_NI_TOTAL.toFixed(2), false)}
                                </th>
                              </tr>

                              <tr>
                                <th colSpan={9}></th>
                              </tr>
                              <tr>
                                <th colSpan={9}>{t("RESULT WITHOUT SPLIT PAYROLL SITUATION")}</th>
                              </tr>
                              <tr>
                                <th colSpan={9}></th>
                              </tr>

                              <tr className="align-bottom">
                                <th>{t("Country")}</th>
                                <th className="text-end">
                                  {renderRowHTML(t("Gross <br/>salary"), null)}
                                </th>
                                <th className="text-end">
                                  {renderRowHTML(t("Company <br/>car"), null)}
                                </th>
                                <th className="text-end">
                                  {renderRowHTML(t("Social <br/>security"), null)}
                                </th>
                                <th className="text-end">
                                  {renderRowHTML(t("Taxable <br/>income"), null)}
                                </th>
                                <th className="text-end">
                                  {renderRowHTML(t("Taxes <br/>(incl. S.S.S.C.)"), null)} (2)
                                </th>
                                <th className="text-end">
                                  {renderRowHTML(t("Company <br/>car"), null)}
                                </th>
                                <th className="text-end">
                                  {renderRowHTML(t("Group <br/>insurance"), null)}
                                </th>
                                <th className="text-end">
                                  {renderRowHTML(t("Net <br/>income"), null)}
                                </th>
                              </tr>

                              <tr>
                                <th>{t("Belgium")}</th>
                                <th className="text-end">
                                  {formatCurrency(C_GS_TOTAL.toFixed(2), false)}
                                </th>
                                <th className="text-end">
                                  {formatCurrency(C_BIK_TOTAL.toFixed(2), false)}
                                </th>
                                <th className="text-end">
                                  {formatCurrency((C_SS_TOTAL * -1).toFixed(2), false)}
                                </th>
                                <th className="text-end">
                                  {formatCurrency(
                                    (C_GS_TOTAL + C_BIK_TOTAL - C_SS_TOTAL).toFixed(2),
                                    false
                                  )}
                                </th>
                                <th className="text-end">
                                  {formatCurrency((C_TOTAL_TAX3_BE * -1).toFixed(2), false)}
                                </th>
                                <th className="text-end">
                                  {formatCurrency((C_BIK_TOTAL * -1).toFixed(2), false)}
                                </th>
                                <th className="text-end">
                                  {formatCurrency((C_GI_TOTAL * -1).toFixed(2), false)}
                                </th>
                                <th className="text-end">
                                  {formatCurrency(
                                    (
                                      C_GS_TOTAL +
                                      C_BIK_TOTAL -
                                      C_SS_TOTAL -
                                      C_TOTAL_TAX3_BE -
                                      C_BIK_TOTAL -
                                      C_GI_TOTAL
                                    ).toFixed(2),
                                    false
                                  )}
                                </th>
                              </tr>

                              <tr>
                                <th colSpan={9}></th>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <Footer />
                </div>
              </Container>
            </div>

            <CardFooter className="d-sm-flex flex-wrap bg-transparent border-top">
              <div className="d-flex gap-2"></div>
              <div className="ms-auto d-flex gap-2 justify-content-end">
                <Button color="outline-primary" onClick={() => props.setShowReport(false)}>
                  <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                    chevron_left
                  </span>
                  {t("Back")}
                </Button>
              </div>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SplitPayrollReport;
