import React, { FC, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Offcanvas, OffcanvasBody, OffcanvasHeader, UncontrolledDropdown } from 'reactstrap';
import Button from '../Button/Button';

type DrawerProps = {
    open?: boolean;
    direction?: 'start' | 'end';
    title: string;
    onClose?: () => void;
    children: ReactNode;
    footer?: any;
    withoutBody?: boolean;
    style?: any;
    fullWidth?: boolean;
    onReset?: () => void;
};

const Drawer: FC<DrawerProps> = ({ open, title, children, footer, direction = 'end', withoutBody = false, onClose, style, fullWidth = false, onReset }) => {

    const { t } = useTranslation();

    const { sidebarcollpsed } = useSelector((state: any) => ({
        sidebarcollpsed: state.Common.sidebarcollpsed
    }));

    const handleReset = () => {
        onReset && onReset();
        // onClose && onClose();
    }

    useEffect(() => {
        function handleEscapeKey(event: KeyboardEvent) {
            if (event.code === "Escape" && open === true) {
            onClose && onClose();
            }
        }

        if (open === true) {
            document.addEventListener("keydown", handleEscapeKey);
            return () => document.removeEventListener("keydown", handleEscapeKey);
        }
    }, [open]);


    return (
        <Offcanvas
            style={fullWidth ? { ...style, width: `calc( 100% - ${sidebarcollpsed ? '70px' : '250px'})` } : style}
            isOpen={open}
            direction={direction}
            unmountOnClose
        >
            <OffcanvasHeader toggle={onClose}>
                {t(title)} {onReset && <Button size='sm' color='light' onClick={handleReset}><span className="align-middle material-symbols-outlined fw-light fs-4" style={{ color: 'red' }}>
                    autorenew
                </span></Button>}
            </OffcanvasHeader>
            {withoutBody ? children :
                <OffcanvasBody>
                    {children}
                </OffcanvasBody>
            }
            {footer ? footer : null}
        </Offcanvas>
    )
}

export default Drawer;