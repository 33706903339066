import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Button,
    Alert,
    Input,
    Label,
    FormGroup,
} from "reactstrap";

// Actions
import { FeesAction, CommonAction } from "../../../store/actions";

// Import Breadcrumb
import Breadcrumbs from "../../../components/Layout/Breadcrumb";

// Import DeleteModal
import Form from "./Form";

// Import DeleteModal
import DeleteModal from "../../../components/Common/DeleteModal";

// Import DataTable
import DataTable from "../../../components/Common/DataTable";

import { getDataTableQuery, getUserProfile } from "../../../helpers/functions_helper";
import service from "../../../services/country.service";
import { toast } from "react-toastify";
import Avatar from "../../../components/Avatar";
import { useTranslation } from "react-i18next";



type CountryProps = {
    company?: any;
}
const Country: FC<CountryProps> = ({ company }) => {
    const {t} = useTranslation();
    const breadcrumbItems = [
        {
            url: "/dashboard",
            label: t("Dashboard"),
            active: false,
        },
        {
            url: "#",
            label: t("Country"),
            active: true,
        },
    ];
    document.title = `${"Country"} | `  + process.env.REACT_APP_NAME;

    const [loader, setLoader] = useState(true);
    const dispatch = useDispatch();

    const useObj = getUserProfile();

    const [loadingListData, setLoadingListData] = useState(false);
    const [datatableOptions, setDatatableOptions] = useState({
        draw: 1,
        page: 1,
        recordsTotal: 0,
        recordsFiltered: 0,
        sizePerPage: 20,
        searchText: "",
        sortField: "id",
        sortOrder: "desc",
    });
    const [vatList, setVatList] = useState([]);

    useEffect(()=>{
        setLoadingListData(true);
        setVatList([]);
      },[localStorage.getItem("I18N_LANGUAGE")])

    const columns = [
        {
            dataField: "DT_RowIndex",
            text: t("Sr. Nr."),
            sort: false,
            search: false,
        },
        // {
        //   dataField: "id",
        //   text: "ID",
        //   sort: true,
        //   search: true,
        // },
        {
            dataField: "nicename",
            text: t("Country name"),
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => {
                let localLanguage = localStorage.getItem("I18N_LANGUAGE");

                if (localLanguage === "English") {
                    return row.nicename;
                } else if (localLanguage === "French") {
                    return row.nicename_french;
                } else if (localLanguage === "Dutch") {
                    return row.nicename_dutch;
                } else {
                    return row.nicename;
                }
            },
        },
        {
            dataField: "iso",
            text: t("ISO Code"),
            sort: true,
            search: true,
        },
        {
            dataField: "iso3",
            text: t("ISO3 Code"),
            sort: true,
            search: true,
        },
        {
            dataField: "phonecode",
            text: t("Phone Code"),
            sort: true,
            search: true,
        },
        {
            dataField: "partner.is_europian_country",
            text: t("European country"),
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => {
                return (
                    <div className="mb-1 center-block ">
                        <Label className="form-label mb-3"></Label>
                        <FormGroup switch inline>
                            <Input
                                type="switch"
                                dis
                                checked={row?.is_europian_country}
                                onChange={(e) => {
                                    handleEuropianCountry(row, e.target.checked ? 1 : 0);
                                }}
                            />
                            <Label check>
                                {row?.is_europian_country ? t('Yes') : t('No')}
                            </Label>
                        </FormGroup>
                    </div >

                );
            },
        },
        {
            dataField: "worked_country",
            text: t("Worked country"),
            sort: true,
            search: true,
            headerFormatter: (column: any, colIndex: any, ColumnSortValue: any): any => {
                return (
                    <>
                        <span onClick={() => handleSort(ColumnSortValue)}>{t("Worked country")}</span>
                        <span className="order">
                            {sortWorked == "desc" || sortWorked == "" ? <><span className="dropdown"><span className="caret"></span></span></> : null}
                            {sortWorked == "asc" || sortWorked == "" ? <><span className="dropup"><span className="caret"></span></span></> : null}
                        </span>
                        <div>
                            <Label className="mx-3">{t("All")}</Label>
                            <FormGroup switch inline>
                                <Input
                                    type="switch"
                                    onChange={(e: any) => handleToggleAllCountry(e.target.checked ? 1 : 0)}
                                />
                                <Label check>
                                    {toggleAll ? t("Yes") : t("No")}
                                </Label>
                            </FormGroup>
                        </div >
                    </>
                );
            },
            formatter: (rowContent: any, row: any): any => {
                return (
                    <div className="mb-1 center-block ">
                        <Label className="form-label mb-3"></Label>
                        <FormGroup switch inline>
                            <Input
                                type="switch"
                                dis
                                checked={row?.worked_country}
                                onChange={(e) => {
                                    handleWorkedCountry(row, e.target.checked ? 1 : 0);
                                }}
                            />
                            <Label check>
                                {row?.worked_country ? t('Yes') : t('No')}
                            </Label>
                        </FormGroup>
                    </div >

                );
            },
        },
        // {
        //     dataField: "action",
        //     text: "Action",
        //     sort: false,
        //     search: false,
        //     width: 200,
        //     hidden: !(useObj.USER_COMPANY === 1),
        //     formatter: (rowContent: any, row: any): any => {
        //         return (
        //             <div className="d-flex gap-2 text-end">
        //                 <Link
        //                     className="btn btn-pill btn-outline-primary btn-sm"
        //                     to="#"
        //                     onClick={() => onClickEdit(row)}
        //                 >
        //                     <i className="fa-regular fa-pen-to-square"></i> Edit
        //                 </Link>
        //                 <Link
        //                     className="btn btn-pill btn-outline-danger btn-sm"
        //                     to="#"
        //                     onClick={() => onClickDelete(row)}
        //                 >
        //                     <i className="fa-regular fa-trash-can"></i> Delete
        //                 </Link>
        //             </div>
        //         );
        //     },
        // },
    ];
    const [sortWorked, setSortWorked] = useState("");
    const [toggleAll, setToggleAll] = useState(false);
    const handleToggleAllCountry = async (data: any) => {
        const res = await service.toggleAll({ toggle: data });
    }

    const handleSort = (data: any) => {
        if (data?.sortElement?.props) {
            setSortWorked(data?.sortElement?.props?.order);
        } else {
            setSortWorked("");
        }
    }

    const getList = async () => {
        const res = await service.getAll({ ...getDataTableQuery(datatableOptions, columns), company_id: company?.id });
        if (res.data.status === 'success') {
            setVatList(res.data.data);
            const updatedDatatableOptions = {
                ...datatableOptions,
                recordsTotal: res.data.recordsTotal,
                recordsFiltered: res.data.recordsFiltered,
            };
            setDatatableOptions(updatedDatatableOptions);
            setLoadingListData(false);
            setLoader(false);
            setToggleAll(res.data.toggle_all == 0 ? true : false);
        }
    }

    useEffect(() => {
        setLoadingListData(true);
    }, [company?.id])

    useEffect(() => {
        loadingListData && getList();
    }, [loadingListData])


    const onTableChange = (type: any, newState: any) => {
        let draw = datatableOptions.draw + 1;
        let page = 1;
        if (type == "search") {
            page = 1;
        } else {
            page = newState.page;
        }
        const updatedDatatableOptions = {
            ...datatableOptions,
            draw: draw,
            page: page,
            sizePerPage: newState.sizePerPage,
            searchText: newState.searchText,
            sortField: newState.sortField,
            sortOrder: newState.sortOrder,
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingListData(true);
    };
    // Datatable -------------------------------------------------

    // Delete Form --------------------------------------------------------
    const [deleteRec, setDeleteRec] = useState({
        id: "",
        project_id: ""
    });
    const [recordName, setRecordName] = useState("");
    const [deleteModal, setDeleteModal] = useState(false);
    const [vatData, setVatData] = useState({});

    const [masterFormModal, setMasterFormModal] = useState(false);
    const [masterFormModalTitle, setMasterFormModalTitle] = useState(t("Create Country"));

    const onClickDelete = (row: any) => {
        const deleteData = {
            ...deleteRec,
            id: row.id,
            project_id: row.project_id,
        };
        setDeleteRec(deleteData);
        setRecordName(row.name);
        setDeleteModal(true);
    };

    const handleDelete = async () => {
        const res = await service.delete(deleteRec.id);
        if (res.status === 'success') {
            toast(res.message, { type: toast.TYPE.SUCCESS })
            setLoadingListData(true);
        }
        setDeleteModal(false)
    };
    // Delete Form --------------------------------------------------------

    // Master Form -------------------------------------------------------- 


    const onClickModal = () => {
        setVatData({ company_id: company?.id });
        setMasterFormModalTitle(t("Create Country"));
        setMasterFormModal(true);
    };

    const onClickEdit = (row: any) => {
        setVatData(row);
        setMasterFormModalTitle(t("Update Country"));
        setMasterFormModal(true);
    }

    const handleSubmit = async (values: any, actions: any) => {
        actions.setSubmitting(true);
        try {
            if (values?.id && values?.project_id) {
                const res = await service.update(values);
                if (res.status === 'success') {
                    toast(res.message, { type: toast.TYPE.SUCCESS, onClose: () => setMasterFormModal(false) })
                    setLoadingListData(true);
                } else {
                    actions.setErrors(res.errors)
                }
            } else {
                const res = await service.create(values);
                if (res.status === 'success') {
                    toast(res.message, { type: toast.TYPE.SUCCESS, onClose: () => setMasterFormModal(false) })
                    setLoadingListData(true);
                } else {
                    actions.setErrors(res.errors)
                }
            }

        } catch (err: any) {
            toast(t('Something went wrong'), { type: toast.TYPE.ERROR })
        }
        actions.setSubmitting(false);
    };

    const clearFilter = () => {
        const updatedDatatableOptions = {
            ...datatableOptions,
            draw: 1,
            page: 1,
            sizePerPage: 20,
            searchText: "",
            sortField: "id",
            sortOrder: "desc",
        };
        setDatatableOptions(updatedDatatableOptions);

        setLoadingListData(true);
    };

    const handleEuropianCountry = async (country: any, is_europian: number) => {
        const res = await service.update(({ ...country, is_europian_country: is_europian }));
        if (res) {
            toast.dismiss();
            setLoadingListData(true);
            toast(res.message, { type: toast.TYPE.SUCCESS })
        }
    }
    const handleWorkedCountry = async (country: any, worked_country: number) => {
        const res = await service.update(({ ...country, worked_country: worked_country }));
        if (res) {
            toast.dismiss();
            setLoadingListData(true);
            toast(res.message, { type: toast.TYPE.SUCCESS })
        }
    }


    return (
        <React.Fragment>

            {deleteRec && (
                <DeleteModal
                    recordName={recordName}
                    modalTitle={t("Countries")}
                    show={deleteModal}
                    onDeleteClick={handleDelete}
                    onCloseClick={() => setDeleteModal(false)}
                    loading={false}
                />
            )}

            {masterFormModal && (
                <Form
                    modalTitle={masterFormModalTitle}
                    show={masterFormModal}
                    loading={loader}
                    vatData={vatData}
                    onSubmitClick={handleSubmit}
                    onCloseClick={() => setMasterFormModal(false)}
                    errorMsg={''}
                    errorData={''}
                    isTab={company?.id}
                />
            )}

            <div className={company?.id ? "" : "page-content"}>
                <Container fluid>
                    <Breadcrumbs
                        breadcrumbTitle={t("Countries")}
                        breadcrumbItems={company?.id ? [] : breadcrumbItems}
                    // endAdornment={
                    //     useObj?.USER_COMPANY === 1 && <Link
                    //         to="#"
                    //         className="btn btn-primary btn-sm waves-effect waves-light btn-label"
                    //         onClick={() => onClickModal()}
                    //     >
                    //         <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">add</span> Create Country
                    //     </Link>
                    // }
                    />

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <DataTable
                                        columns={columns}
                                        products={vatList}
                                        datatableOptions={datatableOptions}
                                        filters={[]}
                                        onTableChange={onTableChange}
                                        loadingListData={loadingListData}
                                        clearFilter={clearFilter}
                                        slug="masters"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Country;
