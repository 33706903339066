export const GET_FEE = "GET_FEE"
export const GET_FEE_SUCCESS = "GET_FEES_SUCCESS"
export const GET_FEE_ERROR = "GET_FEES_ERROR"

export const GET_FEES_LIST = "GET_FEES_LIST"
export const GET_FEES_LIST_SUCCESS = "GET_FEES_LIST_SUCCESS"
export const GET_FEES_LIST_ERROR = "GET_FEES_LIST_ERROR"
  
export const CREATE_FEE = "CREATE_FEE"
export const CREATE_FEE_SUCCESS = "CREATE_FEE_SUCCESS"
export const CREATE_FEE_ERROR = "CREATE_FEE_ERROR"

export const UPDATE_FEE = "UPDATE_FEE"
export const UPDATE_FEE_SUCCESS = "UPDATE_FEE_SUCCESS"
export const UPDATE_FEE_ERROR = "UPDATE_FEE_ERROR"

export const DELETE_FEE = "DELETE_FEE"
export const DELETE_FEE_SUCCESS = "DELETE_FEE_SUCCESS"
export const DELETE_FEE_ERROR = "DELETE_FEE_ERROR"