import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Button,
} from "reactstrap";
import { vLookup } from "../../helpers/functions_helper";
import { formatCurrency } from "../../utils/formats";
import { useTranslation } from "react-i18next";

const SSSCModel_225 = (props: any) => {
  const { t } = useTranslation();
  let spCountryData = props.spCountryData;
  const EXCHANGE_RATE = props.EXCHANGE_RATE;
  const PERS_ALL_SINGLE = props.PERS_ALL_SINGLE;
  const B7 = props.PERS_ALL_DEDUCTION;
  const F7 = props.GBP_OVER_GBP;
  const PERS_ALL_MARRIED = props.PERS_ALL_MARRIED;
  const TAX_RATE_UK = props.TAX_RATE_UK;
  const D_FAM_STAT = props.D_FAM_STAT;

  const [COUNTRY_IDX, setCOUNTRY_IDX] = useState<number>();
  const [D_EMP_DIR, setD_EMP_DIR] = useState<number>(1);
  const [D_GROSS_COUNTRY, setD_GROSS_COUNTRY] = useState<number>(0);
  const [D_GROSS_GBP, setD_GROSS_GBP] = useState<number>(0);
  const [B23, setB23] = useState<number>(0);
  const [C_NET_TAXABLE_COUNTRY, setC_NET_TAXABLE_COUNTRY] = useState<number>(0);
  const [C_TAX_GBP, setC_TAX_GBP] = useState<number>(0);
  const [TAX_CREDIT_MARRIED, setTAX_CREDIT_MARRIED] = useState<number>(0);
  const [C_TAX_EUR, setC_TAX_EUR] = useState<number>(0);

  useEffect(() => {
    if (spCountryData) {
      const COUNTRY_DATA = spCountryData.find((data: any) => data.country_id === 225);

      if (COUNTRY_DATA) {
        setCOUNTRY_IDX(spCountryData.findIndex((data: any) => data.country_id === 225));
        setD_GROSS_COUNTRY(
          spCountryData.find((data: any) => data.country_id === 225)?.D_GROSS_COUNTRY
        );
        setD_EMP_DIR(spCountryData.find((data: any) => data.country_id === 225)?.emp_dir);
      }
    }
  }, [spCountryData]);

  useEffect(() => {
    setD_GROSS_GBP(Number(D_GROSS_COUNTRY) / Number(EXCHANGE_RATE));
  }, [D_GROSS_COUNTRY, EXCHANGE_RATE]);

  useEffect(() => {
    // =IF(D_GROSS_GBP<F7;PERS_ALL_SINGLE;MAX(0;PERS_ALL_SINGLE-(D_GROSS_GBP-F7)/2*B7))
    if (Number(D_GROSS_GBP) < Number(F7)) {
      setB23(Number(PERS_ALL_SINGLE));
    } else {
      setB23(
        Math.max(0, Number(PERS_ALL_SINGLE) - ((Number(D_GROSS_GBP) - Number(F7)) / 2) * Number(B7))
      );
    }
  }, [D_GROSS_GBP, F7, PERS_ALL_SINGLE, B7]);

  useEffect(() => {
    // =IF(D_GROSS_GBP-B23<0;0; D_GROSS_GBP-B23)
    if (Number(D_GROSS_GBP) - Number(B23) < 0) {
      setC_NET_TAXABLE_COUNTRY(0);
    } else {
      setC_NET_TAXABLE_COUNTRY(Number(D_GROSS_GBP) - Number(B23));
    }
  }, [D_GROSS_GBP, B23]);

  useEffect(() => {
    if (TAX_RATE_UK && TAX_RATE_UK.length > 0) {
      // =VLOOKUP(C_NET_TAXABLE_UK;B12:E15;4)+(C_NET_TAXABLE_UK-VLOOKUP(C_NET_TAXABLE_UK;B12:E15;1))*VLOOKUP(C_NET_TAXABLE_UK;B12:E15;3)
      const VLOOKUP_4 = vLookup(C_NET_TAXABLE_COUNTRY, TAX_RATE_UK, 4);
      const VLOOKUP_3 = vLookup(C_NET_TAXABLE_COUNTRY, TAX_RATE_UK, 3);
      const VLOOKUP_1 = vLookup(C_NET_TAXABLE_COUNTRY, TAX_RATE_UK, 1);

      setC_TAX_GBP(
        Number(VLOOKUP_4) +
          (Number(C_NET_TAXABLE_COUNTRY) - Number(VLOOKUP_1)) * (Number(VLOOKUP_3) / 100)
      );
    }
  }, [C_NET_TAXABLE_COUNTRY, TAX_RATE_UK]);

  useEffect(() => {
    // =IF(D_FAM_STAT=2;PERS_ALL_MARRIED;0)*0,2
    if (parseInt(D_FAM_STAT) === 2) {
      setTAX_CREDIT_MARRIED(Number(PERS_ALL_MARRIED));
    }
    {
      setTAX_CREDIT_MARRIED(0);
    }
  }, [PERS_ALL_MARRIED, D_FAM_STAT]);

  useEffect(() => {
    // =IF(ISERROR(ROUND((C_TAX_GBP-TAX_CREDIT_MARRIED)*C4;2));0;ROUND((C_TAX_GBP-TAX_CREDIT_MARRIED)*C4;2))
    setC_TAX_EUR((Number(C_TAX_GBP) - Number(TAX_CREDIT_MARRIED)) * Number(EXCHANGE_RATE));
  }, [C_TAX_GBP, TAX_CREDIT_MARRIED, EXCHANGE_RATE]);

  useEffect(() => {
    if (COUNTRY_IDX) {
      props.onCalculationUpdate({
        COUNTRY_IDX: COUNTRY_IDX,
        C_TAXES_COUNTRY: C_TAX_EUR,
      });
    }
  }, [COUNTRY_IDX, C_TAX_EUR]);

  return (
    <React.Fragment>
      <Modal
        size="xl"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">{t("UNITED KINGDOM")}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label={t("Close")}
          ></button>
        </div>

        <ModalBody>
          <Card>
            <CardHeader>
              <CardTitle>1. {t("PARAMETERS")}</CardTitle>
            </CardHeader>

            <CardBody>
              <div className="table-responsive mb-3">
                <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr>
                      <td>{t("Exchange rate")}: GBP 1 = EUR</td>
                      <td className="text-end">
                        {formatCurrency(Number(EXCHANGE_RATE).toFixed(4), false)}
                      </td>
                      <td>{t("Average exchange rate")} 01/01/2021 - 31/12/2021</td>
                    </tr>
                    <tr>
                      <td>
                        {t("PERSONAL ALLOWANCE")} ({t("SINGLE")})
                      </td>
                      <td className="text-end">
                        {formatCurrency(Number(PERS_ALL_SINGLE).toFixed(4), false)}
                      </td>
                      <td>({t("personal allowance for single & married taxpayers")})</td>
                    </tr>
                    <tr>
                      <td>{t("Deduction personal allowance")}</td>
                      <td className="text-end">{formatCurrency(Number(B7).toFixed(4), false)}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>{t("For every GBP 2 over GBP")}</td>
                      <td className="text-end">{formatCurrency(Number(F7).toFixed(4), false)}</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td colSpan={3}></td>
                    </tr>

                    <tr>
                      <td>
                        {t("PERSONAL ALLOWANCE")} ({t("MARRIED")})
                        <br />({t("only for people > 75 years old")})
                      </td>
                      <td className="text-end">
                        {formatCurrency(Number(PERS_ALL_MARRIED).toFixed(4), false)}
                      </td>
                      <td>
                        ({t("minimum additional allowance for married taxpayers granted at 20%")})
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              <div className="table-responsive mb-3">
                <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr className="align-bottom">
                      <th colSpan={4} className="text-center">
                        {t("Tax rates")} - {t("April")} 6, 2021 - {t("April")} 5, 2022
                      </th>
                    </tr>
                    {TAX_RATE_UK &&
                      TAX_RATE_UK.map((item: any, idx: number) => (
                        <tr key={idx} className="align-bottom">
                          <td className="text-end">
                            {formatCurrency(parseFloat(item[0]).toFixed(2), false)}
                          </td>
                          <td className="text-end">
                            {formatCurrency(parseFloat(item[1]).toFixed(2), false)}
                          </td>
                          <td className="text-end">
                            {formatCurrency(parseFloat(item[2]).toFixed(2), false)}%
                          </td>
                          <td className="text-end">
                            {formatCurrency(parseFloat(item[3]).toFixed(2), false)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>2. {t("COMPUTATION")}</CardTitle>
            </CardHeader>

            <CardBody>
              <div className="table-responsive mb-3">
                <Table className="table table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr>
                      <td>
                        {t("GROSS")} {t("UNITED KINGDOM")}
                      </td>
                      <td className="text-end">
                        {formatCurrency(Number(D_GROSS_COUNTRY).toFixed(2), false)}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("GROSS")} GBP</td>
                      <td className="text-end">
                        {formatCurrency(Number(D_GROSS_GBP).toFixed(2), false)}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={2}></td>
                    </tr>

                    <tr>
                      <td>
                        {t("PERSONAL ALLOWANCE")} ({t("SINGLE")})
                      </td>
                      <td className="text-end">{formatCurrency(Number(B23).toFixed(2), false)}</td>
                    </tr>

                    <tr>
                      <td colSpan={2}></td>
                    </tr>

                    <tr>
                      <td>{t("NET TAXABLE")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_NET_TAXABLE_COUNTRY).toFixed(2), false)}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={2}></td>
                    </tr>

                    <tr>
                      <td>{t("TAX")} GBP</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_TAX_GBP).toFixed(2), false)}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={2}></td>
                    </tr>

                    <tr>
                      <td>
                        {t("TAX CREDIT")} ({t("MARRIED")})
                      </td>
                      <td className="text-end">
                        {formatCurrency(Number(TAX_CREDIT_MARRIED).toFixed(2), false)}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={2}></td>
                    </tr>

                    <tr>
                      <td>{t("TAX")} EUR</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_TAX_EUR).toFixed(2), false)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </ModalBody>

        <ModalFooter>
          <Button color="danger" className="btn-block" type="reset" onClick={props.onCloseClick}>
            {t("Close")}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default SSSCModel_225;
