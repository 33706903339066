import { Modal, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment";
import { Link } from "react-router-dom";

const EventModal = ({ show, eventData, onCloseClick }: EventModal) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title">My calendar</h5>
        <button
          type="button"
          className="btn-close"
          onClick={onCloseClick}
          aria-label="Close"
        ></button>
      </div>
      <ModalBody>
        <h5
          className="font-weight-bold"
          style={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {eventData?.title}
        </h5>
        <p>
          {eventData?.event_type === "TASK_EVENTS"
            ? moment(eventData?.start_date_time).format("dddd, MMMM Do YYYY")
            : moment(eventData?.start_date_time).format(
                "dddd, MMMM Do YYYY, h:mm:ss a"
              )}
        </p>
        <p>{eventData?.details}</p>

        <div className="border-top pt-2 d-flex gap-2">
          {eventData?.web_link && (
            <>
              <div className="d-sm-flex flex-wrap">
                <span className="align-middle material-symbols-outlined fw-light">
                  link
                </span>
              </div>
              <div
                className="justify-content-start"
                style={{ overflow: "hidden" }}
              >
                <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                  {eventData?.event_type === "TASK_EVENTS" ? (
                    <Link to={eventData?.web_link}>{eventData?.web_link}</Link>
                  ) : (
                    <a href={eventData?.web_link} target="_blank">
                      {eventData?.web_link}
                    </a>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </ModalBody>
      <ModalFooter className="d-sm-flex flex-wrap">
        <div className="d-flex gap-2">
          {eventData?.organizer && (
            <>
              <div className="d-sm-flex flex-wrap">
                <span className="align-middle material-symbols-outlined fw-light">
                  person
                </span>
              </div>
              <div className="ms-auto justify-content-end">
                <div>{eventData?.organizer?.emailAddress?.name}</div>
                <div className="text-muted">
                  {eventData?.organizer?.emailAddress?.address}
                </div>
              </div>
            </>
          )}
        </div>

        <div className="ms-auto d-flex gap-2 justify-content-end">
          <button
            type="button"
            className="btn btn-outline-danger btn-md ms-2"
            onClick={onCloseClick}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

interface EventModal {
  show: boolean;
  eventData: any;
  onCloseClick: React.MouseEventHandler<HTMLButtonElement>;
}

export default EventModal;
