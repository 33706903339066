import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Form,
  Label,
  FormFeedback,
  Table,
} from "reactstrap";
import Button from "../../../components/Button/Button";
import labelPlugin from "flatpickr/dist/plugins/labelPlugin/labelPlugin";
import { useFormik } from "formik";
import { initialValues } from "./helper";
import { validationSchema } from "./validation.schema";
import { getFirstErrorElement } from "../../../helpers/functions_helper";
import { useTranslation } from "react-i18next";
import Select from "../../../components/Form/Select/Select";
import Flatpickr from "../../../components/Form/Flatpickr";
import Input from "../../../components/Form/Input";

const TaxReturnTypeForm = (props: any) => {
  const {t} = useTranslation();
  const masterName = t("Tax return type");
  const formData = props.formData;
  const [initialData, setInitialData] = useState(initialValues);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = (values: formTypes_TaxReturnType) => {
    props.onSubmitClick(values);
  };

  const handleChange = (fieldName: string, fieldValue: any) => {
    setInitialData({ ...initialData, [fieldName]: fieldValue });
  };

  useEffect(() => {
    if (formData) {
      setInitialData((prevData: any) => ({
        ...prevData,
        id: formData.id,
        questionnaire_type:formData.questionnaire_type,
        tax_return_type_id: formData.tax_return_type_id,
        tax_return_type_value: formData.tax_return_type_value,
        external_due_date: formData.external_due_date,
        questionnaire_sending_date: formData.questionnaire_sending_date,
        color_code: formData.color_code,
      }));
    }
  }, [formData]);

  useEffect(() => {
    if (!validation.isSubmitting) return;
    if (Object.keys(validation.errors).length > 0) {
      const element = getFirstErrorElement(validationSchema, validation);
      document
        .getElementById(element)
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
      document.getElementById(element)?.focus({ preventScroll: true });
    }
  }, [validation.isSubmitting, validation.errors]);
  
  const questionnaireTypeOptions = [
    { value: "1", label: t("IPP Tax return") },
    { value: "2", label: t("INR Tax return") },
  ];
  return (
    <React.Fragment>
      <Modal
        size="md"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">{`${props.id ? t("Update") : t("Create")
            } ${masterName}`}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label="Close"
          ></button>
        </div>

        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          autoComplete="off"
        >
          <ModalBody>
            <Input id="id" name="id" type="hidden" value={initialData.id} />
            <Input
              id="tax_return_type_id"
              name="tax_return_type_id"
              type="hidden"
              value={initialData.tax_return_type_id}
            />

            <Row>
              <Col className="col-12">
                <div className="mb-3">
                  <Select
                    id="questionnaire_type"
                    name="questionnaire_type"
                    label="Questionnaire type"
                    required
                    className="is-invalid"
                    isSearchable={true}
                    options={questionnaireTypeOptions}
                    value={
                      initialData.questionnaire_type
                        ? questionnaireTypeOptions.find(
                          (option: any) =>
                            option.value == initialData.questionnaire_type
                        )
                        : ""
                    }
                    onBlur={() =>
                      validation.setFieldTouched("questionnaire_type", true)
                    }
                    onChange={(e: any) => {
                      handleChange("questionnaire_type", e.value);
                    }}
                    error={validation.errors.questionnaire_type}
                    touched={validation.touched.questionnaire_type}
                  />
                </div>
              </Col>
              <Col className="col-12">
                <div className="mb-3">
                  <div className="color_palette_main_div">
                    <Input
                      id="tax_return_type_value"
                      name="tax_return_type_value"
                      label="Tax return type"
                      placeholder={t("Tax return type")}
                      required
                      className="form-control"
                      type="text"
                      autoFocus={true}
                      value={initialData.tax_return_type_value || ""}
                      onBlur={validation.handleBlur}
                      onChange={(e) =>
                        handleChange("tax_return_type_value", e.target.value)
                      }
                      invalid={
                        validation.errors.tax_return_type_value ? true : false
                      }
                      error={validation.errors.tax_return_type_value}
                      touched={validation.touched.tax_return_type_value}
                    />
                    <Row>
                      <Col className="col-6">
                        <div className="mt-3">
                          <Label className="form-label">{t("Questionnaire sending date")}
                          <span className="text-warning is_required">*</span></Label>
                          <div className="input-group is-invalid">
                            <Flatpickr
                              id="questionnaire_sending_date"
                              name="questionnaire_sending_date"
                              className="form-control d-block"
                              type="text"
                              value={initialData.questionnaire_sending_date}
                              placeholder="DD/MM/YYYY"
                              options={{
                                altInput: true,
                                altFormat: "d/m/Y",
                                dateFormat: "Y-m-d H:i:S",
                                allowInput: true,
                                plugins: [labelPlugin()],
                                onChange: function (
                                  selectedDates,
                                  dateStr,
                                  instance
                                ) {
                                  handleChange("questionnaire_sending_date", dateStr);
                                },
                                onClose: function (
                                  selectedDates,
                                  dateStr,
                                  instance
                                ) {
                                  handleChange("questionnaire_sending_date", dateStr);
                                },
                                locale: { firstDayOfWeek: 1 },
                              }}
                              error={validation.errors.questionnaire_sending_date}
                              touched={validation.touched.questionnaire_sending_date}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col className="col-6">
                        <div className="mt-3">
                          <Label className="form-label">{t("Official due date")}
                          <span className="text-warning is_required">*</span></Label>
                          <div className="input-group is-invalid">
                            <Flatpickr
                              id="external_due_date"
                              name="external_due_date"
                              className="form-control d-block"
                              type="text"
                              value={initialData.external_due_date}
                              placeholder="DD/MM/YYYY"
                              options={{
                                altInput: true,
                                altFormat: "d/m/Y",
                                dateFormat: "Y-m-d H:i:S",
                                allowInput: true,
                                plugins: [labelPlugin()],
                                onChange: function (
                                  selectedDates,
                                  dateStr,
                                  instance
                                ) {
                                  handleChange("external_due_date", dateStr);
                                },
                                onClose: function (
                                  selectedDates,
                                  dateStr,
                                  instance
                                ) {
                                  handleChange("external_due_date", dateStr);
                                },
                                locale: { firstDayOfWeek: 1 },
                              }}
                              error={validation.errors.external_due_date}
                              touched={validation.touched.external_due_date}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {/* <div className="color_palette_div">
                      <Input
                        id="color_code"
                        name="color_code"
                        type="color"
                        value={initialData.color_code || "#000000"}
                        className="color_palette"
                        onChange={(e) =>
                          handleChange("color_code", e.target.value)
                        }
                      />
                    </div> */}
                  </div>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              className="btn-block"
              type="submit"
              disabled={props.loading}
              loading={props.loading}
            >
              {t("Submit")}
            </Button>
            <Button
              color="danger"
              className="btn-block"
              type="reset"
              onClick={props.onCloseClick}
              disabled={props.loading}
              loading={props.loading}
            >
              {t("Cancel")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default TaxReturnTypeForm;
