import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper";
import * as url from "../helpers/url_helper";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class SaltEdgeService {
    async getProviders(data: any) {
        const response = await axios
            .get(SERVER_URL + url.SALTEDGE.GET_PROVIDERS + "/" + data?.country_code, {
                headers: getAxiosConfigWithToken(),
            })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async createLeads() {
        const response = await axios
            .get(SERVER_URL + url.SALTEDGE.CREATE_LEADS, {
                headers: getAxiosConfigWithToken(),
            })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getLeads() {
        const response = await axios
            .get(SERVER_URL + url.SALTEDGE.GET_LEADS, {
                headers: getAxiosConfigWithToken(),
            })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async createLeadSessions() {
        const response = await axios
            .get(SERVER_URL + url.SALTEDGE.CREATE_LEADS_SESSIONS, {
                headers: getAxiosConfigWithToken(),
            })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async reconnectLeadSessions(connection_id: any) {
        const response = await axios
            .get(SERVER_URL + url.SALTEDGE.RECONNECT_LEADS_SESSIONS + "/" + connection_id, {
                headers: getAxiosConfigWithToken(),
            })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getConnections(data: any) {
        const response = await axios
            .post(SERVER_URL + url.SALTEDGE.GET_CONNECTIONS, data, {
                headers: getAxiosConfigWithToken(),
            })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getTransactions(data: any) {
        const response = await axios
            .post(SERVER_URL + url.SALTEDGE.GET_TRANSACTIONS, data,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async initiatePayment(data: any) {
        const response = await axios
            .post(SERVER_URL + url.SALTEDGE.INITIATE_PAYMENT, data,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getPayments(data: any) {
        const response = await axios
            .get(SERVER_URL + url.SALTEDGE.GET_PAYMENTS + "/" + data?.end_to_end_id + "/" + data?.customer_id, {
                headers: getAxiosConfigWithToken(),
            })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }
}

export default new SaltEdgeService();