import React from "react";
import { useParams } from "react-router-dom";
import { Container, Nav, NavItem } from "reactstrap";
import InvoiceTabs from "../Invoices/invoiceTabs";
// Import Breadcrumb
import Breadcrumbs from "../../components/Layout/Breadcrumb";

import ScrollUp from "../../components/ScrollUpDown/ScrollUp";
import ScrollDown from "../../components/ScrollUpDown/ScrollDown";

import { useTranslation } from "react-i18next";

import NavItems from "./NavItems";

import { Row, Col, Card, CardBody } from "reactstrap";

const EditUserNotifications = (props: any) => {
  const { t } = useTranslation();
  document.title = `${t("User details")} - ${t("Invoices")} | ${process.env.REACT_APP_NAME}`;
  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "/users/all-users",
      label: t("Users"),
      active: false,
    },
    {
      url: "#",
      label: t("Update user"),
      active: true,
    },
  ];
  
  const { id, user_id } = useParams();

  return (
    <React.Fragment>
      <ScrollUp />
      <ScrollDown />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={t("Update user")}
            breadcrumbItems={breadcrumbItems}
          />

          <NavItems id={id} user_id={user_id} />

          <Row>
            <Col xl="12">
              <Card className="border-top-0 rounded-0 rounded-bottom">
                <CardBody>
                  <Row>
                    <InvoiceTabs user_id={id} />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditUserNotifications;
