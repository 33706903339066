import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper";
import * as url from "../helpers/url_helper";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class ShortcutService {
  async create(data: any) {
    const response = await axios
      .post(SERVER_URL + url.SHORTCUT.CREATE, data, {
        headers: getAxiosConfigWithToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async delete(data: any) {
    const response = await axios
      .post(SERVER_URL + url.SHORTCUT.DELETE, data, {
        headers: getAxiosConfigWithToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async getAll() {
    const response = await axios
      .get(SERVER_URL + url.SHORTCUT.GET_ALL, {
        headers: getAxiosConfigWithToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }
}

export default new ShortcutService();
