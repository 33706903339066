import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactApexChart from "react-apexcharts";
import { noData } from '../../../utils/data';
import LoadingSpinner from '../../../components/Common/Loader';
import GraphFilters from '../../../components/Common/GraphFilters';
import { getUserProfile } from '../../../helpers/functions_helper';
import taskService from '../../../services/tasks.service';
import { serialize } from '../../../utils/utils';
import ApplyFilter from './ApplyFilter';
import { toast } from "react-toastify";
import { Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import splitPayrollService from '../../../services/split.payroll.service';
//import gfilters from "../filter";
interface SplitPayRollProps extends CartProps {
  filters?: any;
}

const SplitPayrollCreatedbyCompany: FC<SplitPayRollProps> = ({ hideHeading, filters = null }) => {

  const { t } = useTranslation();

  const userObj = getUserProfile('all-split-payroll');
  const navigate = useNavigate();
  const [selectedID, setSelectedID] = useState<any>({ user_id: null,status_id:null, event: null });
  const inititalFilter = {
    week: null,
    quarter: null,
    month: null,
    year: null,
    user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_ID : null
  };

  const viewTaskList = (i: any) => {
    if (i) {
      if (i.user_id && i.status_id) {
        let _filter = {
          type: 'work-allocation',
          data: i,
          label: 'work allocation'
        }
        navigate('../tasks/all-tasks');
      }
      setSelectedID({ user_id: null,status_id:null, event: null  });
    }
  }

  useEffect(() => {
    if (selectedID?.event === 'click') {
      viewTaskList(selectedID);
    }
  }, [selectedID])

  const [loading, setLoading] = useState<boolean>(true);
  const [query, setQuery] = useState<string | null>(serialize(inititalFilter));
  const [filter, setFilter] = useState<any>(inititalFilter);


  const [data, setData] = useState<any>({

    series: [
      {
        name: t("Website Users"),
        data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6, 2.5, 2.8, 3.8, 4.6]
      },
    ],
    options: {
    chart: {
      height: 350,
      type: "bar",
      stacked: false
    },
    dataLabels: {
      enabled: false
    },
    colors: ["#34c38f", "#556ee6"],
    
    stroke: {
      width: [2, 2]
    },
    plotOptions: {
      bar: {
        columnWidth: "20%"
      }
    },
    xaxis: {
      categories: [t('Jan'), t('Feb'), t('Mar'), t('Apr'), t('May'), t('June'), t('July'), t('Aug'), t('Sep'), t('Oct'), t('Nov'), t('Dec')]
    },
    yaxis: [
      {
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: true,
          color: "#FF1654"
        },
        labels: {
          style: {
            colors: "#FF1654"
          }
        },
        title: {
          text: t("Number of split payroll created by company"),
          style: {
            color: "#FF1654"
          }
        }
      },
    ],
    tooltip: {
      y: {
        formatter: function (val:number) {
          return `${t("Total split payroll")} :  ${val}` 
        }
      }
    },
    legend: {
      horizontalAlign: "left",
      offsetX: 40
    }
    }}
  )

  const getChartData = async () => {
    try {
      const result = await splitPayrollService.getSplitPayrollCreatedByCompany(query);
      if (result.data.status === 'success') {
        //console.log(result);
        setData((data: any) => ({
          ...data,
          series: [{
            name: t('Company'),
            data: result?.data?.data?.count || []
          }],
          options: {
            ...data.options,
            xaxis: {
              categories: result?.data?.data?.company || [],
              labels: {
                show: true,
                trim: true,
                rotate: -45,
              }
            }
          }
  
        }))
      }
      setLoading(false);
    } catch (error:any) {
      toast(error);
    }
  }

  const clearWorkAllocationFilter = () => {
    setFilter(inititalFilter);
  }

  useEffect(() => {
    loading && getChartData();
  }, [loading])

  useEffect(() => {
    setQuery(serialize(filter));
    setLoading(true);
  }, [filter])

  useEffect(() => {
    filters && setFilter(filters)
  }, [filters])

  useEffect(() => {
    setLoading(true);
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  return (
    <>
      <div className="d-sm-flex flex-wrap">
        <h4 className="card-title mb-4">{!hideHeading && t("SplitPayroll Created by Company")}</h4>&nbsp;
        <ApplyFilter filter={filter} />
        <div className="ms-auto">
          <GraphFilters
            filter={filter}
            setFilter={setFilter}
            clearWorkAllocationFilter={clearWorkAllocationFilter}
            userObj={userObj}
          />
        </div>
      </div>
      <div className="clearfix"></div>
      {loading ? <LoadingSpinner height={380} /> : <div style={{ height: '100%' }}><ReactApexChart options={data.options} series={data.series} type="bar" height={350} /></div>}
    </>
  )
}

export default SplitPayrollCreatedbyCompany;