import React, { Component, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Import AuthMiddleware
import AuthMiddleware from "./routes/route";
// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";

// layouts
import Layout from "./components/Layout/";
import NonAuthLayout from "./components/NonAuthLayout/";
// 404 - Not Found
import NotFound from "./pages/NotFound/Page404";

// Import scss / common css
import "./assets/scss/theme.scss";
import "./assets/css/common.css";

// Import FontAwesome 6.0
import "./assets/plugins/FontAwesome6.0/fontawesome.min.css";
import { moduleAccess } from "./helpers/functions_helper";

const App = (props: any) => {

  useEffect(() => {
    let bodyElement = document.getElementsByTagName("body")[0];
    bodyElement.setAttribute("data-sidebar", "dark");
    bodyElement.setAttribute("data-topbar", "light");
  }, [])


  return (
    <React.Fragment>
      <Router>
          <Routes >
            <Route key="authmiddleware" element={<AuthMiddleware isAuthProtected={false}  />}>
              {publicRoutes.map((route, index: number) => (
                <Route key={`nonauthlayout_${index}`} element={<NonAuthLayout />}>
                  <Route
                    path={route.path}
                    key={route.key}
                    element={route.element}
                  />
                </Route>
              ))}
            </Route>

            <Route key="authmiddleware" element={<AuthMiddleware isAuthProtected={true} />}>
              {authProtectedRoutes.map((route, index: number) => (
                <Route key={`layout_${index}`} element={<Layout {...route} />}>
                  <Route
                    path={route.path}
                    key={route.key}
                    element={route.element}
                  // exact={true}
                  />
                </Route>
              ))}
            </Route>

            {/* 404 - Not Found */}
            <Route path="*" key="notfound" element={<NotFound />} />
          </Routes>
      </Router>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

    </React.Fragment>
  );
}


export default App;
