import fileDownload from 'js-file-download';
import React, { useState, useEffect, FC } from 'react';
import { toast } from 'react-toastify';
import { Table } from 'reactstrap';
import invoicesService from '../../services/invoices.service';
import { dateFormat, formatCurrency } from '../../utils/formats';
import './style.scss';
import { useTranslation } from 'react-i18next';
import NoDataFound from '../../components/NoDataFound';

type ReminderHistoryProps = {
    invoice: any;
};

const ReminderHistory: FC<ReminderHistoryProps> = ({ invoice }) => {
    const { t } = useTranslation();

    const toastId = React.useRef(null) as any;
    const [reminders, setReminders] = useState<Array<any>>([]);
    const [deletedPayments, setDeletedPayments] = useState<Array<any>>([]);
    let total = 0;

    const getReminders = async (id: number) => {
        const res = await invoicesService.getReminder(id);
        if (res.status === 'success') {
            setReminders(res.data);
        }
    }

    const handleDelete = (id: any) => {
        let con = window.confirm(t('Are you sure, you want to delete this payment'));
        if (con) {
            setDeletedPayments((i: any) => [...i, id]);
        }
    }

    const handleDownload = async (invoice: any) => {
        toastId.current = toast(t("Downloading invoice..."), {
            autoClose: false,
            isLoading: true,
        });
        // window.open(process.env.REACT_APP_SERVER_URL + `/invoices/download/${invoice.id}`, '_blank')
        const res = await invoicesService.downloadInvoice(invoice?.id);
        if (res.status === 200) {
            fileDownload(res.data, 'invoice.pdf');
            toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: t('Invoice downloaded successfully'), autoClose: 5000, isLoading: false, delay: 500 });
        } else {
            toast.update(toastId.current, { type: toast.TYPE.ERROR, render: t('Sorry, something went wrong!'), autoClose: 5000, isLoading: false, delay: 500 });
        }
    }

    useEffect(() => {
        getReminders(invoice.id);
    }, [])

    return (
        <div className='tabel-responsive'>
            <h4 className='mb-3 align-center'>{t("Invoice Number")}: {invoice.invoice_number}</h4>
            <Table className='table align-middle table-nowrap table-check'>
                <thead className='tabel-light'>
                    <tr>
                        <th>{t("Reminder")}</th>
                        <th>{t("Date")}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {reminders?.length > 0 ? reminders.map((payment: any) => {
                        total += payment.amount;
                        return (
                            <tr
                                key={payment.id}
                            >
                                <td>{payment.reminder_type}</td>
                                <td>{dateFormat(payment.date)}</td>
                                <td className='rightAlign'>{<i className='fa fa-solid fa-download' onClick={() => handleDownload(invoice)}></i>}</td>
                            </tr>
                        )
                    }
                    ) : (
                        <tr
                        >
                            <td colSpan={3}>
                                <NoDataFound
                                    icon="quick_reference"
                                    message={t("You have no records to view!")}
                                />
                            </td>
                        </tr>
                    )}
                </tbody>

            </Table>
        </div>
    )
}

export default ReminderHistory;