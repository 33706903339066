import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Classifications = (props: any) => {
  const { t } = useTranslation();

  const setClassification = (type: string, data: any) => {
    // setTransactions(data);
    // setOpenModal(true);
  };

  return (
    <React.Fragment>
      <div className="d-flex gap-2 text-end">
        <Link
          title={t("Add as a new expenses")}
          className="btn btn-pill btn-outline-primary btn-sm"
          to="#"
          onClick={() => setClassification("1", props.row)}
        >
          <span className="align-middle material-symbols-outlined fw-light fs-5">add</span>
        </Link>

        <Link
          title={t("Link to an expenses")}
          className="btn btn-pill btn-outline-primary btn-sm"
          to="#"
          onClick={() => setClassification("2", props.row)}
        >
          <span className="align-middle material-symbols-outlined fw-light fs-5">link</span>
        </Link>

        <Link
          title={t("Mark as personal")}
          className="btn btn-pill btn-outline-primary btn-sm"
          to="#"
          onClick={() => setClassification("3", props.row)}
        >
          <span className="align-middle material-symbols-outlined fw-light fs-5">person</span>
        </Link>

        <Link
          title={t("More options")}
          className="btn btn-pill btn-outline-primary btn-sm"
          to="#"
          onClick={() => setClassification("4", props.row)}
        >
          <span className="align-middle material-symbols-outlined fw-light fs-5">more_vert</span>
        </Link>
      </div>
    </React.Fragment>
  );
};

export default Classifications;
