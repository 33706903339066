import React, { FC, useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardTitle, Col, Label, Row } from "reactstrap";
import { formatCurrency } from "../../../utils/formats";
import invoicesService from "../../../services/invoices.service";
import { toast } from "react-toastify";
import { select2Styles } from "../../../helpers/functions_helper";
import { options, yearOptions, currentYear, languageCodes } from "../../../common/staticdata";
import LoadingSpinner from "../../../components/Common/Loader";
import { noData } from "../../../utils/data";
import Chart from "../../../components/Chart";
import i18next from "i18next";

type InvoiceGeneratedFromTimesheetProps = {
    hideHeading?: boolean;
}
const TodaysTrends: FC<InvoiceGeneratedFromTimesheetProps> = ({ hideHeading = false }) => {

    const { t } = useTranslation();

    const [loadingText, setLoadingText] = useState<string>('No data found');
    const [loading, setLoading] = useState<boolean>(true);
    const [labels, setLabels] = useState<any>([]);
    const [series, setSeries] = useState<any>([]);
    const [formValues, setFormValues] = useState<any>({
        year: currentYear
    })
    const d = new Date();
    const hour = d.getHours().toString();

    const [chartData, setChartData] = useState<any>({
        options: {
            chart: {
                type: 'area',
                stacked: false,
                toolbar: {
                    show: false
                }
            },
            stroke: {
                width: [2, 2, 3],
                curve: 'smooth'
            },
            markers: {
                size: 5,
                colors: ["#FFFFFF"],
                strokeColor: "#008ffb",
                strokeWidth: 3,
                hover: {
                    size: 8
                }
            },
            fill: {
                type: 'gradient'
            },
            yaxis: {
                size: [-10, 10]
            },
            annotations: {
                xaxis: [{
                    x: hour + '',
                    borderColor: '#000',
                    yAxisIndex: 0,
                    label: {
                        show: false,
                        text: '',
                        style: {
                            color: "#fff",
                            background: '#775DD0'
                        }
                    }
                }]
            },
            dataLabels: {
                enabled: false,
                enabledOnSeries: [1],
                formatter: function (value: any, opts: any) {
                    return formatCurrency(value);
                },
            },
            labels: [],
            colors: ["#008ffb", "#CCCCCC"],
            tooltip: {
                shared: true,
                intersect: false,
                custom: (opts: any) => {
                    const lang = languageCodes(i18next.language);
                    const x = opts?.w?.config?.series;
                    return `<div class="">
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item"><label>${t(x[0]?.name)}:</label> ${typeof x[0]?.data[opts?.dataPointIndex] !== "undefined" ? x[0]?.data[opts?.dataPointIndex] : null}</li>
                      <li class="list-group-item"><label>${t(x[1]?.name)}:</label> ${typeof x[1]?.data[opts?.dataPointIndex] !== "undefined" ? x[1]?.data[opts?.dataPointIndex] : null}</li>
                    </ul>
                  </div> `;
                },
            },
            noData: noData
        },
        series: [],
    });

    const getInvoiceGeneratedFromTimesheet = async (year: number) => {
        try {
            const res = await invoicesService.getTodaysTrends();
            if (res.status === 'success') {
                setLabels(res?.labels);
                setSeries(res?.data);
                setChartData((state: any) => (
                    {
                        ...state,
                        series: res?.data?.map((s: any) => ({ ...s, name: t(s.name) })),
                        options: {
                            ...state.options,
                            labels: res?.labels.map((a: any) => t(a)),
                        }
                    }));
            }
            setLoadingText(t("No data found"));
            setLoading(false);
        } catch (error: any) {
            toast(error)
        }
    }

    useEffect(() => {
        loading && getInvoiceGeneratedFromTimesheet(formValues.year);
    }, [loading])

    useEffect(() => {
        setChartData((state: any) => (
            {
                ...state,
                series: series.map((s: any) => ({ ...s, name: t(s.name) })),
                options: {
                    ...state.options,
                    labels: labels.map((a: any) => t(a)),
                }
            }));
    }, [t])

    useEffect(() => {
        setLoading(true);
    }, [formValues.year])

    return (
        <>
            <div className="d-sm-flex flex-wrap">
                <CardTitle className="mb-3">{!hideHeading && t("Invoice Trends")}</CardTitle>
                <div className="ms-auto">
                    <Select
                        id="year"
                        name="year"
                        className="form-select-sm myClassName"
                        styles={select2Styles}
                        value={yearOptions.find((i: any) => i.value === formValues.year)}
                        options={yearOptions}
                        onChange={(option: any) => {
                            setFormValues((state: any) => ({ ...state, year: option.value }))
                        }}
                    />
                </div>
            </div>
            <Row>
                <Col md={7}>
                    <Chart loading={loading} options={chartData.options} series={chartData.series} type="area" height={400} />
                </Col>
                <Col className="px-5 py-5">
                    <Row>
                        <Col>
                            <GraphWidget label="Created" value={10} />
                        </Col>
                        <Col>
                            <GraphWidget label="Sent" value={5} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <GraphWidget label="Average time spent on task" value={'5 m'} />
                        </Col>
                        <Col>
                            <GraphWidget label="Top task type of the day" value={'HR05'} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <GraphWidget label="Total time spent of the task" value={'10%'} />
                        </Col>
                    </Row>
                </Col >
            </Row >

        </>

    )
}

export default TodaysTrends;

type GraphWidgetProps = {
    label: string,
    value: any
}
const GraphWidget: FC<GraphWidgetProps> = ({ label, value }) => {
    const { t } = useTranslation();
    return (
        <div className="d-flex py-1 my-2 mx-2">
            <div className="vr"></div>
            <div className="px-3 py-1">
                <Label>{t(label)}</Label>
                <div className="font-size-20" style={{ color: "#AAA" }}>{value}</div>
            </div>
        </div>
    )
}

