import {
  GET_COMPANY,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_ERROR,

  GET_COMPANY_LIST,
  GET_COMPANY_LIST_SUCCESS,
  GET_COMPANY_LIST_ERROR,

  CREATE_COMPANY,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_ERROR,

  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_ERROR,

  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_ERROR,

  GET_COMPANY_GENERAL,
  GET_COMPANY_GENERAL_SUCCESS,
  GET_COMPANY_GENERAL_ERROR,
} from "./actionTypes";

// ---------------------------------------------GET_COMPANY
export const getCompany = (company: any, history: any) => {
  return {
    type: GET_COMPANY,
    payload: { company, history },
  };
};

export const getCompanySuccess = (data: {}) => {
  return {
    type: GET_COMPANY_SUCCESS,
    payload: data,
  };
};

export const getCompanyError = (error: {}) => {
  return {
    type: GET_COMPANY_ERROR,
    payload: error,
  };
};

// ---------------------------------------------GET_COMPANY_LIST
export const getCompanyList = (query: any, history: any) => {
  return {
    type: GET_COMPANY_LIST,
    payload: {query, history},
  };
};

export const getCompanyListSuccess = (data: {}) => {
  return {
    type: GET_COMPANY_LIST_SUCCESS,
    payload: data,
  };
};

export const getCompanyListError = (error: {}) => {
  return {
    type: GET_COMPANY_LIST_ERROR,
    payload: error,
  };
};

// ---------------------------------------------CREATE_COMPANY
export const createCompany = (company: any, history: any) => {
  return {
    type: CREATE_COMPANY,
    payload: { company, history },
  };
};

export const createCompanySuccess = (company: {}) => {
  return {
    type: CREATE_COMPANY_SUCCESS,
    payload: company,
  };
};

export const createCompanyError = (error: {}) => {
  return {
    type: CREATE_COMPANY_ERROR,
    payload: error,
  };
};

// ---------------------------------------------UPDATE_COMPANY
export const updateCompany = (company: any, history: any) => {
  return {
    type: UPDATE_COMPANY,
    payload: { company, history },
  };
};

export const updateCompanySuccess = (company: {}) => {
  return {
    type: UPDATE_COMPANY_SUCCESS,
    payload: company,
  };
};

export const updateCompanyError = (error: {}) => {
  return {
    type: UPDATE_COMPANY_ERROR,
    payload: error,
  };
};

// ---------------------------------------------DELETE_COMPANY
export const deleteCompany = (company: any, history: any) => {
  return {
    type: DELETE_COMPANY,
    payload: { company, history },
  };
};

export const deleteCompanySuccess = (company: {}) => {
  return {
    type: DELETE_COMPANY_SUCCESS,
    payload: company,
  };
};

export const deleteCompanyError = (error: {}) => {
  return {
    type: DELETE_COMPANY_ERROR,
    payload: error,
  };
};

export const getCompanyGeneral = (company_general: {}) => {
  return {
    type: GET_COMPANY_GENERAL,
    payload: company_general
  }
};

export const getCompanyGeneralSuccess = (data: {}) => {
  return {
    type: GET_COMPANY_GENERAL_SUCCESS,
    payload: data
  }
};

export const getCompanyGeneralError = (error: {}) => {
  return {
    type: GET_COMPANY_GENERAL_ERROR,
    payload: error,
  };
};
