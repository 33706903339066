import {
    GET_QUESTIONNAIRE_FAIL,
    GET_QUESTIONNAIRE_SUCCESS,
    GET_QUESTIONNAIRE_DETAIL_FAIL,
    GET_QUESTIONNAIRE_DETAIL_SUCCESS,
    ADD_NEW_QUESTIONNAIRE,
    ADD_QUESTIONNAIRE_SUCCESS,
    ADD_QUESTIONNAIRE_FAIL,
    UPDATE_QUESTIONNAIRE,
    UPDATE_QUESTIONNAIRE_SUCCESS,
    UPDATE_QUESTIONNAIRE_FAIL,
    DELETE_QUESTIONNAIRE_SUCCESS,
    DELETE_QUESTIONNAIRE_FAIL,
    DELETE_QUESTIONNAIRE,
    GET_QUESTIONNAIRE,
    GET_QUESTIONNAIRE_LIST,
    GET_QUESTIONNAIRE_LIST_SUCCESS,
    GET_QUESTIONNAIRE_LIST_FAIL,
    GET_QUESTIONNAIRE_FULL_DATA,
    GET_QUESTIONNAIRE_FULL_DATA_SUCCESS,
    GET_QUESTIONNAIRE_FULL_DATA_FAIL,
    SEND_QUESTIONNAIRE_FROM_TASK,
    SEND_QUESTIONNAIRE_FROM_TASK_SUCCESS,
    SEND_QUESTIONNAIRE_FROM_TASK_ERROR,
} from "./actiontypes"

const INIT_STATE = {
    GET_QUESTIONNAIRE_LIST: [],
    GET_QUESTIONNAIRE_LIST_SINGLE: {},
    GET_QUESTIONS_FULL_DATA: [],
    SEND_QUESTIONNAIRE_FROM_TASK_SUCCESS : "",
    SEND_QUESTIONNAIRE_FROM_TASK_ERROR:"",
    error: "",
    success: "",
    isModel: false,
    loading: false,
    tasksuccess: "",
}

const Questionnaire = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GET_QUESTIONNAIRE:
            return {
                ...state,
                // success: "",
                // error: "",
                loading: true,
                GET_QUESTIONNAIRE_LIST:[]
            }
        case GET_QUESTIONNAIRE_SUCCESS:
            return {
                ...state,
                GET_QUESTIONNAIRE_LIST: action.payload.data,
                loading: false,
            }

        case GET_QUESTIONNAIRE_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
                GET_QUESTIONNAIRE_LIST:[]
            }


        case GET_QUESTIONNAIRE_LIST:
            return {
                ...state,
                success: "",
                error: "",
                loading: true,
            }
        case GET_QUESTIONNAIRE_LIST_SUCCESS:
            return {
                ...state,
                GET_QUESTIONNAIRE_LIST_SINGLE: action.payload,
                loading: false,
            }

        case GET_QUESTIONNAIRE_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }

        case ADD_NEW_QUESTIONNAIRE:
            return {
                ...state,
                success: "",
                error: "",
                tasksuccess:"",
                loading: true,
            }
        case ADD_QUESTIONNAIRE_SUCCESS:
            return {
                ...state,
                error: "",
                success: action.payload.message,
                tasksuccess: action.payload.task_created,
                isModel: false,
                loading: false,
            }

        case ADD_QUESTIONNAIRE_FAIL:
            return {
                ...state,
                success: "",
                tasksuccess:"",
                error: action.payload.message,
                isModel: true,
                loading: false,
            }

        case GET_QUESTIONNAIRE_DETAIL_SUCCESS:
            return {
                ...state,
                statusDetail: action.payload,
                loading: false,
            }

        case UPDATE_QUESTIONNAIRE:
            return {
                ...state,
                success: "",
                error: "",
                tasksuccess:"",
                loading: true,
            }

        case UPDATE_QUESTIONNAIRE_SUCCESS:
            return {
                ...state,
                success: action.payload.message,
                loading: false,
            }

        case UPDATE_QUESTIONNAIRE_FAIL:
            return {
                ...state,
                error: action.payload.message,
                isModel: true,
                loading: false,
            }
        case DELETE_QUESTIONNAIRE:
            return {
                ...state,
                success: '',
                error: '',
                isModel: false,
                loading: true,
            }

        case DELETE_QUESTIONNAIRE_SUCCESS:
            return {
                ...state,
                success: action.payload.message,
                loading: false,
            }

        case DELETE_QUESTIONNAIRE_FAIL:
            return {
                ...state,
                error: action.payload.message,
                loading: false,
            }


        case GET_QUESTIONNAIRE_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }


        // GET_QUESTIONNAIRE FULL DATA

        case GET_QUESTIONNAIRE_FULL_DATA:
            return {
                ...state,
                success: action.payload.message,
                loading: true,
            }

        case GET_QUESTIONNAIRE_FULL_DATA_SUCCESS:
            return {
                ...state,
                GET_QUESTIONS_FULL_DATA: action.payload,
                loading: false,
            }


        case GET_QUESTIONNAIRE_FULL_DATA_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }

        // GET_QUESTIONNAIRE FULL DATA

        //SEND QUESTIONNAIRE FROM TASK
        case SEND_QUESTIONNAIRE_FROM_TASK:
            return {
                ...state,
                success: action.payload.message,
                loading: true,
            }

        case SEND_QUESTIONNAIRE_FROM_TASK_SUCCESS:
            return {
                ...state,
                SEND_QUESTIONNAIRE_FROM_TASK_SUCCESS: action.payload,
                loading: false,
            }


        case SEND_QUESTIONNAIRE_FROM_TASK_ERROR:
            return {
                ...state,
                SEND_QUESTIONNAIRE_FROM_TASK_ERROR: action.payload,
                loading: false,
            }


        default:
            return state
    }
}

export default Questionnaire
