import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    Form,
    Label,
    Button,
    FormFeedback,
    Alert,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Input from "../../../components/Form/Input";
import Select from "../../../components/Form/Select/Select";

type DocumentType = {
    id?: number | null;
    document_type_id?: string | null;
    document_type: string | null;
    map_master: number | null;
    map_master_name?: string | null;
};

const DcoumentTypeForm = (props: any) => {
    const {t} = useTranslation();
    const mapMasterOptions = [
        {
            value: 1,
            label: t('Company Document type Master')
        },
        {
            value: 2,
            label: t('User Document type Master')
        }
    ];
    let DcoumentTypeData = props.vatData;
    const dispatch = useDispatch();

    const { getUserOptionsList } = useSelector((state: any) => ({ getUserOptionsList: state.Common.GET_USER_OPTIONS_LIST_SUCCESS }))

    const [intialData, setIntialData] = useState<DocumentType>({
        document_type: null,
        map_master: null,
        map_master_name: null,
    });

    const [countries, setCountries] = useState([]);
    const [partnersOptions, setPartnerOptions] = useState<Array<any>>([]);
    const [query, setQuery] = useState("");

    const validationSchema = Yup.object().shape({
        document_type: Yup.string().nullable().required("Document type field is required."),
        map_master: Yup.number().nullable().required("Map master field is required."),
    });


    const handleChange = (fieldName: string, fieldValue: any) => {
        setIntialData({ ...intialData, [fieldName]: fieldValue });
    };

    const handleSubmit = (values: DocumentType, actions: any) => {
        const data = { ...values, map_master_name: mapMasterOptions?.find((o: any) => validation?.values?.map_master === o.value)?.label }
        props.onSubmitClick(data, actions);
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: intialData,
        validationSchema: validationSchema,
        onSubmit: (values, actions) => {
            handleSubmit(values, actions);
        },
    });


    useEffect(() => {
        DcoumentTypeData && setIntialData(DcoumentTypeData);
    }, [DcoumentTypeData])

    useEffect(() => {
        setPartnerOptions(getUserOptionsList?.data?.filter((i: any) => i.function === 6))
    }, [getUserOptionsList])

    useEffect(() => {
        if (!validation.isSubmitting) return;
        if (Object.keys(validation.errors).length > 0) {
            document
                .getElementById(Object.keys(validation.errors)[0])
                ?.scrollIntoView({ behavior: "smooth", block: "center" });
            document
                .getElementById(Object.keys(validation.errors)[0])
                ?.focus({ preventScroll: true });
        }
    }, [validation.isSubmitting, validation.errors]);



    return (
        <React.Fragment>
            <Modal
                size="md"
                isOpen={props.show}
                toggle={props.onCloseClick}
                backdrop="static"
                keyboard={false}
                autoFocus={false}
            >
                <div className="modal-header">
                    <h5 className="modal-title">{props.modalTitle}</h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={props.onCloseClick}
                        aria-label={t("Close")}
                    ></button>
                </div>

                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                    autoComplete="off"
                >
                    <ModalBody>
                        {props.errorMsg ? (
                            <Alert color="danger">
                                {props.errorMsg}

                                {props.errorData.data
                                    ? Object.keys(props.errorData.data).map((key) => (
                                        <li color="danger" key={key}>
                                            {props.errorData.data[key]}
                                        </li>
                                    ))
                                    : null}
                            </Alert>
                        ) : null}
                        <Input
                            id="id"
                            name="id"
                            type="hidden"
                            value={validation.values.id || ""}
                            onChange={validation.handleChange}
                        />
                        <Input
                            id="document_type_id"
                            name="document_type_id"
                            type="hidden"
                            value={validation.values.document_type_id || ""}
                            onChange={validation.handleChange}
                        />


                        <Row>
                            <Col className="col-12">
                                <div className="mb-3">
                                    <Input
                                        id="document_type"
                                        name="document_type"
                                        className="form-control"
                                        label="Document type"
                                        required
                                        placeholder={t("Example: LIMOSA")}
                                        type="text"
                                        value={validation.values.document_type || ""}
                                        onBlur={validation.handleBlur}
                                        onChange={(e) =>
                                            handleChange("document_type", e.target.value)
                                        }
                                        invalid={
                                            validation.touched.document_type &&
                                                validation.errors.document_type
                                                ? true
                                                : false
                                        }
                                        error={validation.errors.document_type}
                                        touched={validation.touched.document_type}
                                    />
                                </div>
                            </Col>
                            <Col className="col-12">
                                <div className="mb-3">
                                    <Select
                                        id="map_master"
                                        name="map_master"
                                        label="Map master"
                                        required
                                        className={validation.errors.map_master ? "is-invalid" : ""}
                                        options={mapMasterOptions}
                                        value={mapMasterOptions?.find((o: any) => validation?.values?.map_master === o.value)}
                                        isSearchable={true}
                                        onChange={(option: any) => {
                                            handleChange("map_master", option.value);
                                        }}
                                        error={validation.errors.map_master}
                                        touched={validation.touched.map_master}
                                    />
                                </div>
                            </Col>

                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            className="btn-block"
                            type="submit"
                            disabled={validation.isSubmitting}
                        >
                            {validation.isSubmitting ? (
                                <span>
                                    <i className="fa-solid fa-spinner fa-spin"></i>
                                    <span>&nbsp; {t("Please wait...")}</span>
                                </span>
                            ) : (
                                <span>{t("Submit")}</span>
                            )}
                        </Button>
                        <Button
                            color="danger"
                            className="btn-block"
                            type="reset"
                            onClick={props.onCloseClick}
                            disabled={props.loading}
                        >
                            {t("Cancel")}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </React.Fragment>
    );
};

export default DcoumentTypeForm;
