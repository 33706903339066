import React, { FC, useState, useEffect } from 'react';
import { Badge, Card, CardBody, CardFooter, CardText, CardTitle, Col, Container, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, OffcanvasBody, Row } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import moment from 'moment';
import { toast } from 'react-toastify';
import { getLangCode, invoiceStatuses, langCodes } from '../../../common/staticdata';
import { dateFormat, formatCurrency } from '../../../utils/formats';
import disputesService from '../../../services/disputes.services';
import Button from '../../../components/Button/Button';
import LoadingSpinner from '../../../components/Common/Loader';
import { Link, useNavigate } from 'react-router-dom';
import CreditNoteForm from './CreditNoteForm';
import { useTranslation } from 'react-i18next';
import DisputeForm from './Form';
import { getUserProfile } from '../../../helpers/functions_helper';
import TransferInvoiceForm from './TransferInvoiceForm';
import MultiComments from '../../../components/Common/MultiComments';
import DropzoneUpload from '../../../components/Common/Dropzone';
import { renderRowHTML } from '../../../utils/utils';
import NoDataFound from '../../../components/NoDataFound';
import invoicesService from '../../../services/invoices.service';
import Drawer from '../../../components/Drawer';
import NewUser from '../../Users/NewUser';
import NewCompany from '../../Masters/Company/NewCompany';
import { useDispatch } from 'react-redux';
import { CommonAction } from '../../../store/actions';
import commonService from '../../../services/common.service';



type DisputeFormProps = {
    values: any;
    onApply?: any;
    onClose: any;
    onChange?: any;
}

type DisputeRejectFormType = {
    id: number;
    reason_to_reject: string;
    isSubmitting: boolean
}

const DisputeList: FC<DisputeFormProps> = ({ values, onApply, onClose, onChange }) => {
    const toastId = React.useRef(null) as any;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const userObj = getUserProfile();

    const [disputes, setDisputes] = useState<Array<any>>([]);
    const [invoice, setInvoice] = useState<any>();
    const [creditNoteForm, setCreditNoteForm] = useState<boolean>(false);
    const [invoiceTransferForm, setInvoiceTransferForm] = useState<boolean>(false);
    const [rejectForm, setRejectForm] = useState<boolean>(false);
    const [openDisputeForm, setOpenDisputeForm] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [disputeID, setDisputeId] = useState<any>(0);
    const [formValues, setFormValues] = useState<DisputeRejectFormType>({
        id: 0,
        reason_to_reject: "",
        isSubmitting: false
    });
    const [validateCode, setValidateCode] = useState<number>(0);
    const [selectedDispute, setSelectedDispute] = useState<any>();
    const [validateData, setValidateData] = useState<any>();
    const [openConfirmationBox, setOpenConfirmationBox] = useState<boolean>(false);
    const [openCompanyDrawer, setOpenCompanyDrawer] = useState<boolean>(false);
    const [openUserDrawer, setOpenUserDrawer] = useState<boolean>(false);
    const [validateVat, setValidateVat] = useState<any>({
        valid: null,
        loading: false,
    });

    const handleValidate = async (vatNr: any) => {
        setValidateVat((state: any) => ({ ...state, loading: true }));

        const res = await commonService.validateVatNr(vatNr);
        if (res?.data?.status === "success") {
            if (res?.data?.data?.valid) {
                setValidateVat((state: any) => ({ ...state, valid: true }));
            }
        } else {
            setValidateVat((state: any) => ({ ...state, valid: false }));
        }
        setValidateVat((state: any) => ({ ...state, loading: false }));
    };

    const getDiputes = async () => {
        const result = await disputesService.getAll(values.id);
        if (result.data.status === 'success') {
            setDisputes(result.data.data);
            setRejectForm(false);
        }
    }

    const handleAccept = async (id: any) => {
        const result = await disputesService.accept(id);
        if (result.status === 'success') {
            setLoading(true);
            onApply();
        }
    }

    const handleReject = async (id: any) => {
        setDisputeId(id);
        setRejectForm(true);
    }

    const handleRejectSubmit = async (id: any) => {
        // setLoading(true);
        setFormValues((state: DisputeRejectFormType) => ({ ...state, id: id }))
        const result = await disputesService.reject(id, formValues);
        if (result.status === 'success') {
            setLoading(true);
            onApply();
        }
    }

    const handleCreateCreditNote = (dispute: any) => {
        setInvoice({ ...values, credit_note_amount: dispute.amount, dispute_id: dispute.id })
        setCreditNoteForm(true)
    }

    const handleTransferInvoice = async (dispute: any) => {
        // setInvoice({ ...values, credit_note_amount: dispute.amount, dispute_id: dispute.id })
        toastId.current = toast(t("Submitting credit note..."), { autoClose: false, isLoading: true });
        // setFormValues((state: InvoiceTransferFormType) => ({ ...state, isSubmitting: true }))
        const result = await invoicesService.createCreditMemo({
            ...values,
            invoice_date: moment(new Date()).format('YYYY-MM-DD'),
            due_date: moment(new Date()).format('YYYY-MM-DD'),
            credit_note_amount: values.total,
            amount_due: values.amount_due - (values.total + ((values.tax_rate * values.total) / 100)),
            company_id: values.company_id,
            content: '<p>Dear Sir,</p>',
            company_details: null,
            contact_person_details: null,
            contact_person: null,
            contact_person_id: values.contact_person_id,
            credit_note: `Dispute of ${formatCurrency(values?.total)} is raised by client.`,
            status: "Draft"
        });
        if (result.status === 'success') {
            toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: 'Credit note submitted successfully', autoClose: 5000, isLoading: false, delay: 500 });
            toastId.current = toast("Creating new invoice...", { autoClose: false, isLoading: true });
            let data = {};
            // alert(JSON.stringify(selectedDispute));
            if (selectedDispute?.recipient?.company_type === 2) {
                data = {
                    company_id: 0,
                    tax_rate: validateData?.contact_person?.vat_percentage,
                    tax_amount: ((validateData?.contact_person?.vat_percentage * (values.total - values.paid_amount)) / 100),
                    amount_due: (values.sub_total + ((validateData?.contact_person?.vat_percentage * (values.total - values.paid_amount)) / 100)) - values.paid_amount,
                    contact_person_id: validateData?.contact_person.id,
                    company_details: null,
                    contact_person_details: null,
                    contact_person: validateData?.contact_person?.first_name + ' ' + validateData?.contact_person?.last_name,
                    company_name: 'Individual'
                }
            } else {
                data = {
                    company_id: validateData?.company.id,
                    tax_rate: validateData?.company?.vat_percentage,
                    tax_amount: ((validateData?.company?.vat_percentage * (values.total - values.paid_amount)) / 100),
                    amount_due: (values.sub_total + ((validateData?.company?.vat_percentage * (values.total - values.paid_amount)) / 100)) - values.paid_amount,
                    contact_person_id: validateData?.contact_person.id,
                    company_details: null,
                    contact_person_details: null,
                    contact_person: validateData?.contact_person?.first_name + ' ' + validateData?.contact_person?.last_name,
                }
            }

            const inv = await invoicesService.createInvoice({
                ...values,
                invoice_date: moment(new Date()).format('YYYY-MM-DD'),
                due_date: moment(new Date()).format('YYYY-MM-DD'),
                content: '<div><p>Dear Sir, Dear Madam,</p><p>We have the pleasure to provide you here below with our invoice for services rendered up to day.</p></div>',
                status: "Draft",
                ...data
            });
            if (inv.status === 'success') {
                const res = disputesService.creditNote(selectedDispute.id, result.data.id);
                toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: 'Invoice created', autoClose: 5000, isLoading: false, delay: 500 });
                onApply();
                onClose();
            }
        }
        setInvoiceTransferForm(true)
    }

    const handleValidateCompany = async (dispute: any) => {
        setSelectedDispute(dispute);
        const result = await disputesService.validateCompany(dispute.recipient);
        if (result) {
            setValidateData(result.data);
            setValidateCode(result.code);
            setOpenConfirmationBox(true);
        }
    }

    const handleCompanySave = (company: any) => {

        if (company) {
            setValidateData((state: any) => ({ ...state, company: company }));
            setValidateCode(1);
            dispatch(CommonAction.getCompanyOptionsList());
            setOpenCompanyDrawer(false);
        }
    }

    const handleUserSave = (user: any) => {
        setValidateData((state: any) => ({ ...state, contact_person: user }));
        setValidateCode(2);
        setOpenUserDrawer(false);
    }


    const creditNoteFormClose = () => {
        setCreditNoteForm(false);
        setLoading(true);
    }

    const transferInvoiceFormClose = () => {
        setInvoiceTransferForm(false);
        setLoading(true);
    }

    useEffect(() => {
        if (loading) {
            getDiputes();
            setLoading(false);
        }
    }, [loading]);


    return (
        <React.Fragment>
            <Col>
                <Row>
                    <Col>
                        <div className="mb-3">
                            <h5 className="invoice-number">
                                {t("Invoice Number")}: {values?.invoice_number}
                            </h5>

                        </div>
                    </Col>
                </Row>
                {openDisputeForm ?
                    <Row>
                        <DisputeForm values={values} onClose={() => setOpenDisputeForm((state) => !state)} onApply={() => setLoading(true)} />
                    </Row>
                    : <>
                        {userObj.USER_COMPANY !== 1 &&
                            <Button
                                color='info'
                                fullWidth
                                className='mb-3'
                                onClick={() => setOpenDisputeForm(true)}
                                disabled={disputes?.length > 0}
                            >
                                {t("Raise a Dispute")}
                            </Button>
                        }
                        {invoiceTransferForm && <Row>
                            <TransferInvoiceForm
                                values={invoice}
                                onClose={transferInvoiceFormClose}
                                onApply={onApply}
                                onChange={() => setLoading(true)}
                            />
                        </Row>}
                        {creditNoteForm && (
                            <Row>
                                <CreditNoteForm
                                    values={invoice}
                                    onClose={creditNoteFormClose}
                                    onApply={onApply}
                                    onChange={() => setLoading(true)}
                                />
                            </Row>
                        )}
                        {(!creditNoteForm && !invoiceTransferForm) &&
                            <>
                                {loading ? <LoadingSpinner /> : disputes?.length > 0 ? (
                                    disputes.map((dispute: any) => (
                                        <Card color='default' key={dispute.id}>
                                            <CardBody>
                                                <CardTitle>
                                                    {t("Dispute Amount")} : {formatCurrency(dispute.amount)}
                                                    <div className='text-muted font-size-12'>
                                                        <i className='font-size-12 text-muted fa fa-calendar'></i> {dateFormat(dispute.created_at, { dateStyle: 'medium', timeStyle: 'medium' }, getLangCode(i18n.languages))}
                                                    </div>
                                                </CardTitle>
                                                <p>
                                                    {dispute.status === 0 && <Badge color='warning'>Pending</Badge>}
                                                    {dispute.status === 1 && <Badge color='success'>Accepted</Badge>}
                                                    {dispute.status === 2 && <Badge color='danger'>Rejected</Badge>}
                                                    {(dispute.status === 3 && (dispute?.credit_note?.status === 'Sent' || ((userObj.IS_ADMIN || userObj.IS_PARTNER) && userObj.USER_COMPANY === 1))) && <Link className='badge bg-success' to={"/invoices-management/view-credit-note/" + dispute?.credit_note?.id} target="_blank">
                                                        {`Credit note #${dispute?.credit_note?.invoice_number} is created`}
                                                        &nbsp;
                                                        <i className='fa fa-external-link'></i>
                                                    </Link>}
                                                </p>
                                                <CardText>
                                                    {(dispute.raise_for === 2 && dispute?.deleted_items) &&
                                                        <table className='table table-striped'>
                                                            <thead className='thead-light'>
                                                                <tr>
                                                                    <th>{t("Timesheet Name")}</th>
                                                                    <th className='right-align'>{t("Qty")}</th>
                                                                    <th className='right-align'>{t("Price")}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {values.details?.map((d: any) => (
                                                                    <tr key={d.id} className={dispute?.deleted_items.filter((x: ItemType) => x.qty === d.quantity).map((i: ItemType) => i.id).includes(d.id) ? 'deleted' : ''}>
                                                                        <td> <h5 className='font-size-14'>{d.timesheet_code}</h5>
                                                                            <div className='text-muted'>{renderRowHTML(d.timesheet_name, '')}</div>
                                                                        </td>
                                                                        <td className='right-align font-size-14'>
                                                                            {
                                                                                dispute?.deleted_items.find((x: ItemType) => x.id === d.id)?.qty ?
                                                                                    `- ${dispute?.deleted_items.find((x: ItemType) => x.id === d.id)?.qty}` :
                                                                                    '- 0'
                                                                            }</td>
                                                                        <td className='right-align font-size-14'> {formatCurrency(d.price * dispute?.deleted_items.find((x: ItemType) => x.id === d.id)?.qty)}</td>

                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    }
                                                    {(dispute.raise_for === 3 && dispute?.recipient) &&
                                                        <>
                                                            {dispute?.recipient?.company_type === 1 &&
                                                                <Row className='mb-3'>
                                                                    <Label>{t("Company")}:</Label>
                                                                    <Col>
                                                                        <div className='input-box'>{dispute?.recipient?.company}</div>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                            {dispute?.recipient?.company_type === 1 &&
                                                                <Row className='mb-3'>
                                                                    <Label>{t("Address")}:</Label>
                                                                    <Col>
                                                                        <div className='input-box'>{dispute?.recipient?.address}</div>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                            <Row className='mb-3'>
                                                                <Label>{t("Contact person name")}:</Label>
                                                                <Col>
                                                                    <div className='input-box'>{dispute?.recipient?.contact_person_name}</div>
                                                                </Col>
                                                            </Row>
                                                            <Row className='mb-3'>
                                                                <Label>{t("Contact person email")}:</Label>
                                                                <Col>
                                                                    <div className='input-box'>{dispute?.recipient?.contact_person_email}</div>
                                                                </Col>
                                                            </Row>
                                                            {dispute?.recipient?.company_type === 2 &&
                                                                <Row className='mb-3'>
                                                                    <Label>{t("Address")}:</Label>
                                                                    <Col>
                                                                        <div className='input-box'>{dispute?.recipient?.address}</div>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                            <Row className='mb-3'>
                                                                <Label>{t("TVA/BTW number")}:</Label>
                                                                <Col>
                                                                    <div className='input-box'>
                                                                        {dispute?.recipient?.tva_number}
                                                                        {userObj.USER_COMPANY === 1 && (userObj.IS_ADMIN || userObj.IS_PARTNER) ? <>
                                                                            {(dispute?.recipient?.tva_number && dispute.status === 1) &&
                                                                                <Button
                                                                                    size='sm'
                                                                                    className='ms-auto'
                                                                                    color={validateVat.valid ? "success" : "secondary"}
                                                                                    outline
                                                                                    onClick={() => handleValidate(dispute?.recipient?.tva_number)}>
                                                                                    {validateVat.loading
                                                                                        ? t("Loading")
                                                                                        : validateVat.valid
                                                                                            ? t("Valid")
                                                                                            : t("Validdate")}
                                                                                </Button>
                                                                            }
                                                                        </> : null
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                    }
                                                </CardText>
                                                <CardText>
                                                    {dispute.status === 2 && (
                                                        <>
                                                            <Label>{t("Reason to reject the dispute")}: </Label>
                                                            <p>{dispute.reason_to_reject}</p>
                                                            <hr />
                                                        </>
                                                    )}
                                                </CardText>

                                                <CardText>
                                                    {dispute.comment}
                                                </CardText>

                                                <CardText className='text-muted font-size-12'>
                                                    {t("Raised by")} {`${dispute.user.first_name} ${dispute.user.last_name}`}
                                                </CardText>


                                            </CardBody>
                                            {userObj.USER_COMPANY === 1 && (userObj.IS_ADMIN || userObj.IS_PARTNER) ? <>
                                                {(dispute.status === 0 && !rejectForm) &&
                                                    <CardFooter style={{ display: 'flex', justifyContent: "space-between" }}>
                                                        <Button color='success' onClick={() => handleAccept(dispute.id)}>
                                                            {t("Accept")}
                                                        </Button>
                                                        <Button color='danger' className='ml-auto' onClick={() => handleReject(dispute.id)}>
                                                            {t("Reject")}
                                                        </Button>
                                                    </CardFooter>
                                                }
                                                {(rejectForm && disputeID === dispute?.id) && (
                                                    <CardFooter>
                                                        <div className="mb-3">
                                                            <Label className="form-label">
                                                                {t("Reason to reject the dispute")}
                                                            </Label>
                                                            <Input
                                                                id={`reason_to_reject`}
                                                                name='reason_to_reject'
                                                                type='textarea'
                                                                placeholder={'Enter reason for rejecting the dispute here'}
                                                                className=''
                                                                onChange={(e) => {
                                                                    setFormValues((state: DisputeRejectFormType) => ({ ...state, reason_to_reject: e.target.value }))
                                                                }}
                                                            />
                                                        </div>
                                                        <Button color='primary' fullWidth onClick={() => handleRejectSubmit(dispute.id)}>
                                                            {t("Submit")}
                                                        </Button>
                                                    </CardFooter>
                                                )}

                                                {(dispute.status === 1 && dispute.raise_for !== 3) && <CardFooter>
                                                    <Button fullWidth color='primary' onClick={() => handleCreateCreditNote(dispute)}>
                                                        {t("Create Credit Note")}
                                                    </Button>
                                                </CardFooter>}
                                                {/* {(validateCode === 2 && dispute.raise_for === 3) && <CardFooter>
                                                    <Button fullWidth color='primary' onClick={() => handleTransferInvoice(dispute)}>
                                                        {t("Transfer invoice")}
                                                    </Button>
                                                </CardFooter>} */}
                                                {(dispute.status === 1 && dispute.raise_for === 3) && <CardFooter>
                                                    <Button
                                                        fullWidth
                                                        color='primary'
                                                        onClick={() => handleValidateCompany(dispute)}
                                                    // disabled={!validateVat.valid && dispute?.recipient?.tva_number}
                                                    >
                                                        {t("Validate")}
                                                    </Button>
                                                </CardFooter>}
                                            </> : null}
                                            {(dispute.status === 2) && <CardFooter className='p-0'>
                                                <Row className='mb-3'>
                                                    <MultiComments
                                                        moduleData={{
                                                            module_app_id: 17,
                                                            module_main_id: dispute?.id,
                                                            module_sub_id: dispute?.dispute_id,
                                                            section_id: 0,
                                                        }}
                                                        commentForIDs={userObj?.USER_COMPANY === 0 ? [
                                                            {
                                                                value: 1,
                                                                label: 'Partner',
                                                                selected: true,
                                                            }
                                                        ] : [
                                                            {
                                                                value: dispute.user.id,
                                                                label: 'Contact Person',
                                                                selected: true,
                                                            }
                                                        ]}
                                                        fetchData={true}
                                                        isOpen={false}
                                                    />
                                                </Row>
                                                <Row className='mb-3'>
                                                    <DropzoneUpload
                                                        moduleData={{
                                                            module_app_id: 17,
                                                            module_main_id: dispute?.id,
                                                            module_sub_id: dispute?.dispute_id,
                                                            section_id: 0,
                                                            file_path: "disputes",
                                                        }}
                                                        hideList={false}
                                                        fetchData={true}
                                                        isOpen={false}
                                                        attachmentForIDs={userObj?.USER_COMPANY === 0 ? [
                                                            {
                                                                value: 1,
                                                                label: 'Partner',
                                                                selected: true,
                                                            }
                                                        ] : [
                                                            {
                                                                value: dispute.user.id,
                                                                label: 'Contact Person',
                                                                selected: true,
                                                            }
                                                        ]}
                                                    />
                                                </Row>
                                            </CardFooter>}
                                        </Card>
                                    ))
                                ) : (
                                    <div className='align-box-middle'>
                                        <NoDataFound message='You have no disputes to view!' />
                                    </div>
                                )}
                            </>}
                    </>}
            </Col>
            <Modal
                isOpen={openConfirmationBox}
            >
                <ModalHeader toggle={() => setOpenConfirmationBox(false)}>Transfer invoice</ModalHeader>
                {validateCode === 2 &&
                    <ModalBody>
                        {selectedDispute?.recipient?.company_type === 1 ?
                            <p>
                                Company {selectedDispute?.recipient?.company} and contact person {selectedDispute?.recipient?.contact_person_name} is verified. Do you want to create new invoice to this company?
                            </p> : <p>
                                Individual user {selectedDispute?.recipient?.contact_person_name} is verified. Do you want to create new invoice to this user?
                            </p>
                        }
                    </ModalBody>
                }
                {validateCode === 1 &&
                    <ModalBody>
                        {selectedDispute?.recipient?.company_type === 1 ?
                            <p>
                                Company {selectedDispute?.recipient?.company} is verified but contact person {selectedDispute?.recipient?.contact_person_name} is not availble for this company. Do you want to create new contact person for this company?
                            </p> : <p>
                                Individual user {selectedDispute?.recipient?.contact_person_name} is not availble. Do you want to create new user?
                            </p>
                        }
                    </ModalBody>
                }
                {validateCode === 0 &&
                    <ModalBody>
                        Company {selectedDispute?.recipient?.company} and contact person {selectedDispute?.recipient?.contact_person_name} is not availble. Do you want to create new Company and contact person for this company?
                    </ModalBody>
                }
                {validateCode === 0 &&
                    <ModalFooter>
                        <Button color="primary" onClick={() => setOpenCompanyDrawer(true)}>Yes</Button>{' '}
                        <Button color="secondary" onClick={() => setOpenConfirmationBox(false)}>No</Button>
                    </ModalFooter>
                }
                {validateCode === 1 &&
                    <ModalFooter>
                        <Button color="primary" onClick={() => setOpenUserDrawer(true)}>Yes</Button>{' '}
                        <Button color="secondary" onClick={() => setOpenConfirmationBox(false)}>No</Button>
                    </ModalFooter>
                }
                {validateCode === 2 &&
                    <ModalFooter>
                        <Button color="primary" onClick={() => handleTransferInvoice(selectedDispute)}>Yes</Button>{' '}
                        <Button color="secondary" onClick={() => setOpenConfirmationBox(false)}>No</Button>
                    </ModalFooter>
                }
            </Modal>
            <Drawer
                title='Create a Company'
                open={openCompanyDrawer}
                onClose={() => setOpenCompanyDrawer(false)}
                fullWidth
            >
                <NewCompany
                    company={selectedDispute?.recipient?.company}
                    address={selectedDispute?.recipient?.address}
                    TVW_Nr={selectedDispute?.recipient?.tva_number}
                    inTab={true}
                    onSave={(data: any) => handleCompanySave(data)}
                />
            </Drawer>
            <Drawer
                title='Create a User'
                open={openUserDrawer}
                onClose={() => setOpenUserDrawer(false)}
                fullWidth
            >
                <NewUser
                    first_name={selectedDispute?.recipient?.contact_person_name?.split(" ")[0]}
                    last_name={selectedDispute?.recipient?.contact_person_name?.split(" ")?.pop()}
                    email={selectedDispute?.recipient?.contact_person_email}
                    company={validateData?.company?.id}
                    user_type={selectedDispute?.recipient?.company_type === 1 ? 2 : 1}
                    address={selectedDispute?.recipient?.company_type === 1 ? '' : selectedDispute?.recipient?.address}
                    TVW_Nr={selectedDispute?.recipient?.company_type === 1 ? '' : selectedDispute?.recipient?.tva_number}
                    inTab={true}
                    onSave={(data: any) => handleUserSave(data)}
                />
            </Drawer>
        </React.Fragment >
    )
}

export default DisputeList;