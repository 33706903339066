import React, { useState, useEffect } from 'react';
import DataTable from "../../components/ReactTable/Table";
import {
    Card,
    CardBody,
    Container,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getDataTableQuery } from "../../helpers/functions_helper";
// Actions
import { TasksAction } from "../../store/actions";

//Mail Component Test
import NewEmail from '../Email/components/NewEmail';


const columns = [
    {
        dataField: "DT_RowIndex",
        text: "Sr. No.",
        sort: false,
        search: false,
        classes: "fix-col-left",
        headerClasses: "fix-col-left",
        headerStyle: { width: "155px" },
        style: { width: "155px" },
    },
    {
        dataField: "custome_code",
        text: "Task ID",
        sort: true,
        search: true,
    },
    {
        dataField: "company_name",
        text: "Company",
        sort: true,
        search: true,
    },
    {
        dataField: "project_name",
        text: "Project",
        sort: true,
        search: true,
    },
    {
        dataField: "title",
        text: "Task title",
        sort: true,
        search: true,
    },
    {
        dataField: "type_value_html",
        text: "Task type",
        sort: true,
        search: true,
    },
    {
        dataField: "category_of_fees",
        text: "Category of task",
        sort: true,
        search: true,
    },
    {
        dataField: "fees_code",
        text: "Task code",
        sort: true,
        search: true,
    },
    {
        dataField: "fees_amount",
        text: "Task amount",
        sort: true,
        search: true,
        align: "right",
    },
    {
        dataField: "category",
        text: "Task category",
        sort: true,
        search: true,
    },
    {
        dataField: "preparer_value",
        text: "Preparer",
        sort: true,
        search: true,
    },
    {
        dataField: "reviewer_value",
        text: "Reviewer",
        sort: true,
        search: true,
    },
    {
        dataField: "file_manager_value",
        text: "File manager",
        sort: true,
        search: true,
    },
    {
        dataField: "partner_value",
        text: "Partner",
        sort: true,
        search: true,
    },
    {
        dataField: "assistant_administrative_value",
        text: "Assistant Administrative",
        sort: true,
        search: true,
    },
    {
        dataField: "start_date_value",
        text: "Start date",
        sort: true,
        search: true,
    },
    {
        dataField: "due_date_value",
        text: "Internal due date",
        sort: true,
        search: true,
    },
    {
        dataField: "closing_date_value",
        text: "External due date",
        sort: true,
        search: true,
    },
    {
        dataField: "priority_value_html",
        text: "Task priority",
        sort: true,
        search: true,
    },
    {
        dataField: "status_value_html",
        text: "Task status",
        sort: true,
        search: true,
    },
    {
        dataField: "progress_value_html",
        text: "Task progress",
        sort: true,
        search: true,
    },
    {
        dataField: "contact_person_value",
        text: "Contact person",
        sort: true,
        search: true,
    },
    {
        dataField: "responsible_person_value",
        text: "Person concerned",
        sort: true,
        search: true,
    },
    {
        dataField: "income_year",
        text: "Income year",
        sort: true,
        search: true,
        align: "right",
    },
    {
        dataField: "expense",
        text: "Expense",
        sort: true,
        search: true,
        align: "right",
    },
    {
        dataField: "preliminary_billing_html",
        text: "Preliminary billing",
        sort: true,
        search: true,
    },
    {
        dataField: "preliminary_percentage",
        text: "Preliminary billing %",
        sort: true,
        search: true,
        align: "right",
    },
    {
        dataField: "action",
        text: "Action",
        sort: false,
        search: false,
    },
];



const ReactTablePage = (props: any) => {

    const [loader, setLoader] = useState(true);
    const dispatch = useDispatch();

    let {
        datatableData,
        getTasksListErrorMsg,
        createTaskSuccessMsg,
        updateTaskSuccessMsg,
        deleteTaskSuccessMsg,
        deleteTaskErrorMsg,
        getTaskErrorMsg,
        loading,
    } = useSelector((state: any) => ({
        datatableData: state.Tasks.GET_TASKS_LIST_SUCCESS,
        getTasksListErrorMsg: state.Tasks.GET_TASKS_LIST_ERROR.message,

        createTaskSuccessMsg: state.Tasks.CREATE_TASK_SUCCESS.message,

        updateTaskSuccessMsg: state.Tasks.UPDATE_TASK_SUCCESS.message,

        deleteTaskSuccessMsg: state.Tasks.DELETE_TASK_SUCCESS.message,
        deleteTaskErrorMsg: state.Tasks.DELETE_TASK_ERROR.message,

        getTaskErrorMsg: state.Tasks.GET_TASK_ERROR.message,

        loading: state.Tasks.loading,
    }));



    const [tasksListData, setTasksListData] = useState([]);
    const [loadingListData, setLoadingListData] = useState(true);
    const [datatableOptions, setDatatableOptions] = useState({
        draw: 1,
        page: 1,
        recordsTotal: 0,
        recordsFiltered: 0,
        sizePerPage: 20,
        searchText: "",
        sortField: "id",
        sortOrder: "desc",
    });

    useEffect(() => {
        if (loadingListData === true) {
            dispatch(
                TasksAction.getTasksList(getDataTableQuery(datatableOptions, columns), props.history)
            );
        }
    }, [loadingListData]);
    useEffect(() => {
        if (datatableData) {
            setTasksListData(datatableData.data);

            const updatedDatatableOptions = {
                ...datatableOptions,
                recordsTotal: datatableData.recordsTotal,
                recordsFiltered: datatableData.recordsFiltered,
            };
            setDatatableOptions(updatedDatatableOptions);

            setLoadingListData(false);
            setLoader(false);
        }
        if (getTasksListErrorMsg) {
            setTasksListData([]);

            setLoadingListData(false);
            setLoader(false);
        }
    }, [datatableData, getTasksListErrorMsg]);

    const onTableChange = (type: any, newState: any) => {
        let draw = datatableOptions.draw + 1;
        let page = 1;
        if (type == "search") {
            page = 1;
        } else {
            page = newState.page;
        }
        const updatedDatatableOptions = {
            ...datatableOptions,
            draw: draw,
            page: page,
            sizePerPage: newState.sizePerPage,
            searchText: newState.searchText,
            sortField: newState.sortField,
            sortOrder: newState.sortOrder,
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingListData(true);
    };

    const clearFilter = () => {
        const updatedDatatableOptions = {
            ...datatableOptions,
            draw: 1,
            page: 1,
            sizePerPage: 20,
            searchText: "",
            sortField: "id",
            sortOrder: "desc",
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingListData(true);
    };

    return (
        <div className="page-content">
            <div>
                {/* <EmailCompose /> */}
                <NewEmail />
            </div>
           
            <Container fluid>
                <Card>
                    <CardBody>

                        <DataTable
                            columns={columns}
                            products={tasksListData}
                            datatableOptions={datatableOptions}
                            filters={[]}
                            onTableChange={onTableChange}
                            loadingListData={loadingListData}
                            clearFilter={clearFilter}
                            settingTableName="tasks_table"
                        />
                    </CardBody>
                </Card>

            </Container>
        </div>
    )
}

export default ReactTablePage;