import { 
  GET_SUB_TASK,
  GET_SUB_TASK_SUCCESS,
  GET_SUB_TASK_ERROR,

  GET_SUB_TASKS_LIST,
  GET_SUB_TASKS_LIST_SUCCESS,
  GET_SUB_TASKS_LIST_ERROR,
  
  CREATE_SUB_TASK,
  CREATE_SUB_TASK_SUCCESS,
  CREATE_SUB_TASK_ERROR, 

  UPDATE_SUB_TASK,
  UPDATE_SUB_TASK_SUCCESS,
  UPDATE_SUB_TASK_ERROR,

  DELETE_SUB_TASK,
  DELETE_SUB_TASK_SUCCESS,
  DELETE_SUB_TASK_ERROR,
} from "./actionTypes"
  
  const initialState = {
    GET_SUB_TASK_SUCCESS: "",
    GET_SUB_TASK_ERROR: "",
    GET_SUB_TASKS_LIST_SUCCESS: "",
    GET_SUB_TASKS_LIST_ERROR: "",
    CREATE_SUB_TASK_SUCCESS: "",
    CREATE_SUB_TASK_ERROR: "",
    UPDATE_SUB_TASK: "",
    UPDATE_SUB_TASK_SUCCESS: "",
    UPDATE_SUB_TASK_ERROR: "",
    DELETE_SUB_TASK: "",
    DELETE_SUB_TASK_SUCCESS: "",
    DELETE_SUB_TASK_ERROR: "",
    loading: false,
  }
  
  const reducer = (state = initialState, action: any) => {
    switch (action.type) {
      // ---------------------------------------------GET_SUB_TASK
      case GET_SUB_TASK:
        state = {
          ...state,
          loading: true,
        }
        break

      case GET_SUB_TASK_SUCCESS:
        state = {
          ...state,
          GET_SUB_TASK_SUCCESS: action.payload,
          loading: false,
        }
        break

      case GET_SUB_TASK_ERROR:
        state = { 
          ...state, 
          GET_SUB_TASK_ERROR: action.payload, 
          loading: false 
        }
        break

      // ---------------------------------------------GET_SUB_TASKS_LIST
      case GET_SUB_TASKS_LIST:
        state = {
          ...state,
          loading: true,
        }
        break

      case GET_SUB_TASKS_LIST_SUCCESS:
        state = {
          ...state,
          GET_SUB_TASKS_LIST_SUCCESS: action.payload,
          loading: false,
        }
        break

      case GET_SUB_TASKS_LIST_ERROR:
        state = { 
          ...state, 
          GET_SUB_TASKS_LIST_ERROR: action.payload, 
          loading: false 
        }
        break
        
      // ---------------------------------------------CREATE_SUB_TASK
      case CREATE_SUB_TASK:
        state = {
          ...state,
          loading: true,
        }
        break

      case CREATE_SUB_TASK_SUCCESS:
        state = {
          ...state,
          CREATE_SUB_TASK_SUCCESS: action.payload,
          loading: false,
        }
        break

      case CREATE_SUB_TASK_ERROR:
        state = { 
          ...state, 
          CREATE_SUB_TASK_ERROR: action.payload, 
          loading: false 
        }
        break

      // ---------------------------------------------UPDATE_SUB_TASK
      case UPDATE_SUB_TASK:
        state = {
          ...state,
          loading: true,
        }
        break

      case UPDATE_SUB_TASK_SUCCESS:
        state = {
          ...state,
          UPDATE_SUB_TASK_SUCCESS: action.payload,
          loading: false,
        }
        break

      case UPDATE_SUB_TASK_ERROR:
        state = { 
          ...state, 
          UPDATE_SUB_TASK_ERROR: action.payload, 
          loading: false 
        }
        break

      // ---------------------------------------------DELETE_SUB_TASK
      case DELETE_SUB_TASK:
        state = {
          ...state,
          loading: true,
        }
        break

      case DELETE_SUB_TASK_SUCCESS:
        state = {
          ...state,
          DELETE_SUB_TASK_SUCCESS: action.payload,
          loading: false,
        }
        break

      case DELETE_SUB_TASK_ERROR:
        state = { 
          ...state, 
          DELETE_SUB_TASK_ERROR: action.payload, 
          loading: false 
        }
        break

      // ---------------------------------------------DEFAULT
      default:
        state = { ...state }
        break
    }
    return state
  }
  
  export default reducer
  