import { 
  GET_USER_FUNCTION,
  GET_USER_FUNCTION_SUCCESS,
  GET_USER_FUNCTION_ERROR,

  GET_USER_FUNCTION_LIST,
  GET_USER_FUNCTION_LIST_SUCCESS,
  GET_USER_FUNCTION_LIST_ERROR,
  
  CREATE_USER_FUNCTION,
  CREATE_USER_FUNCTION_SUCCESS,
  CREATE_USER_FUNCTION_ERROR, 

  UPDATE_USER_FUNCTION,
  UPDATE_USER_FUNCTION_SUCCESS,
  UPDATE_USER_FUNCTION_ERROR,

  DELETE_USER_FUNCTION,
  DELETE_USER_FUNCTION_SUCCESS,
  DELETE_USER_FUNCTION_ERROR,
} from "./actionTypes"
  
  const initialState = {
    GET_USER_FUNCTION_SUCCESS: "",
    GET_USER_FUNCTION_ERROR: "",
    GET_USER_FUNCTION_LIST_SUCCESS: "",
    GET_USER_FUNCTION_LIST_ERROR: "",
    CREATE_USER_FUNCTION_SUCCESS: "",
    CREATE_USER_FUNCTION_ERROR: "",
    UPDATE_USER_FUNCTION: "",
    UPDATE_USER_FUNCTION_SUCCESS: "",
    UPDATE_USER_FUNCTION_ERROR: "",
    DELETE_USER_FUNCTION: "",
    DELETE_USER_FUNCTION_SUCCESS: "",
    DELETE_USER_FUNCTION_ERROR: "",
    loading: false,
  }
  
  const reducer = (state = initialState, action: any) => {
    switch (action.type) {
      // ---------------------------------------------GET_USER_FUNCTION
      case GET_USER_FUNCTION:
        state = {
          ...state,
          loading: true,
        }
        break

      case GET_USER_FUNCTION_SUCCESS:
        state = {
          ...state,
          GET_USER_FUNCTION_SUCCESS: action.payload,
          loading: false,
        }
        break

      case GET_USER_FUNCTION_ERROR:
        state = { 
          ...state, 
          GET_USER_FUNCTION_ERROR: action.payload, 
          loading: false 
        }
        break

      // ---------------------------------------------GET_USER_FUNCTION_LIST
      case GET_USER_FUNCTION_LIST:
        state = {
          ...state,
          loading: true,
        }
        break

      case GET_USER_FUNCTION_LIST_SUCCESS:
        state = {
          ...state,
          GET_USER_FUNCTION_LIST_SUCCESS: action.payload,
          loading: false,
        }
        break

      case GET_USER_FUNCTION_LIST_ERROR:
        state = { 
          ...state, 
          GET_USER_FUNCTION_LIST_ERROR: action.payload, 
          loading: false 
        }
        break
        
      // ---------------------------------------------CREATE_USER_FUNCTION
      case CREATE_USER_FUNCTION:
        state = {
          ...state,
          loading: true,
        }
        break

      case CREATE_USER_FUNCTION_SUCCESS:
        state = {
          ...state,
          CREATE_USER_FUNCTION_SUCCESS: action.payload,
          loading: false,
        }
        break

      case CREATE_USER_FUNCTION_ERROR:
        state = { 
          ...state, 
          CREATE_USER_FUNCTION_ERROR: action.payload, 
          loading: false 
        }
        break

      // ---------------------------------------------UPDATE_USER_FUNCTION
      case UPDATE_USER_FUNCTION:
        state = {
          ...state,
          loading: true,
        }
        break

      case UPDATE_USER_FUNCTION_SUCCESS:
        state = {
          ...state,
          UPDATE_USER_FUNCTION_SUCCESS: action.payload,
          loading: false,
        }
        break

      case UPDATE_USER_FUNCTION_ERROR:
        state = { 
          ...state, 
          UPDATE_USER_FUNCTION_ERROR: action.payload, 
          loading: false 
        }
        break

      // ---------------------------------------------DELETE_USER_FUNCTION
      case DELETE_USER_FUNCTION:
        state = {
          ...state,
          loading: true,
        }
        break

      case DELETE_USER_FUNCTION_SUCCESS:
        state = {
          ...state,
          DELETE_USER_FUNCTION_SUCCESS: action.payload,
          loading: false,
        }
        break

      case DELETE_USER_FUNCTION_ERROR:
        state = { 
          ...state, 
          DELETE_USER_FUNCTION_ERROR: action.payload, 
          loading: false 
        }
        break

      // ---------------------------------------------DEFAULT
      default:
        state = { ...state }
        break
    }
    return state
  }
  
  export default reducer
  