import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class TaskTypesService {
    async getTaskType(DATA: any) {
        const response = await axios
            .get(SERVER_URL + url.GET_TASK_TYPE + "/" + DATA.id + "/" + DATA.task_type_id,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async getTaskTypesList(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_TASK_TYPES_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async createTaskType(DATA: any) {
        const response = await axios
            .put(SERVER_URL + url.CREATE_TASK_TYPE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async updateTaskType(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.UPDATE_TASK_TYPE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async deleteTaskType(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.DELETE_TASK_TYPE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }
}

export default new TaskTypesService();