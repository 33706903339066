import {
  GET_TASK_PRIORITY,
  GET_TASK_PRIORITY_SUCCESS,
  GET_TASK_PRIORITY_ERROR,

  GET_TASK_PRIORITY_LIST,
  GET_TASK_PRIORITY_LIST_SUCCESS,
  GET_TASK_PRIORITY_LIST_ERROR,

  CREATE_TASK_PRIORITY,
  CREATE_TASK_PRIORITY_SUCCESS,
  CREATE_TASK_PRIORITY_ERROR,

  UPDATE_TASK_PRIORITY,
  UPDATE_TASK_PRIORITY_SUCCESS,
  UPDATE_TASK_PRIORITY_ERROR,

  DELETE_TASK_PRIORITY,
  DELETE_TASK_PRIORITY_SUCCESS,
  DELETE_TASK_PRIORITY_ERROR,
} from "./actionTypes";

// ---------------------------------------------GET_TASK_PRIORITY
export const getTaskPriority = (taskpriority: any, history: any) => {
  return {
    type: GET_TASK_PRIORITY,
    payload: { taskpriority, history },
  };
};

export const getTaskPrioritySuccess = (data: {}) => {
  return {
    type: GET_TASK_PRIORITY_SUCCESS,
    payload: data,
  };
};

export const getTaskPriorityError = (error: {}) => {
  return {
    type: GET_TASK_PRIORITY_ERROR,
    payload: error,
  };
};

// ---------------------------------------------GET_TASK_PRIORITY_LIST
export const getTaskPriorityList = (query: any, history: any) => {
  return {
    type: GET_TASK_PRIORITY_LIST,
    payload: {query, history},
  };
};

export const getTaskPriorityListSuccess = (data: {}) => {
  return {
    type: GET_TASK_PRIORITY_LIST_SUCCESS,
    payload: data,
  };
};

export const getTaskPriorityListError = (error: {}) => {
  return {
    type: GET_TASK_PRIORITY_LIST_ERROR,
    payload: error,
  };
};

// ---------------------------------------------CREATE_TASK_PRIORITY
export const createTaskPriority = (taskpriority: any, history: any) => {
  return {
    type: CREATE_TASK_PRIORITY,
    payload: { taskpriority, history },
  };
};

export const createTaskPrioritySuccess = (taskpriority: {}) => {
  return {
    type: CREATE_TASK_PRIORITY_SUCCESS,
    payload: taskpriority,
  };
};

export const createTaskPriorityError = (error: {}) => {
  return {
    type: CREATE_TASK_PRIORITY_ERROR,
    payload: error,
  };
};

// ---------------------------------------------UPDATE_TASK_PRIORITY
export const updateTaskPriority = (taskpriority: any, history: any) => {
  return {
    type: UPDATE_TASK_PRIORITY,
    payload: { taskpriority, history },
  };
};

export const updateTaskPrioritySuccess = (taskpriority: {}) => {
  return {
    type: UPDATE_TASK_PRIORITY_SUCCESS,
    payload: taskpriority,
  };
};

export const updateTaskPriorityError = (error: {}) => {
  return {
    type: UPDATE_TASK_PRIORITY_ERROR,
    payload: error,
  };
};

// ---------------------------------------------DELETE_TASK_PRIORITY
export const deleteTaskPriority = (taskpriority: any, history: any) => {
  return {
    type: DELETE_TASK_PRIORITY,
    payload: { taskpriority, history },
  };
};

export const deleteTaskPrioritySuccess = (taskpriority: {}) => {
  return {
    type: DELETE_TASK_PRIORITY_SUCCESS,
    payload: taskpriority,
  };
};

export const deleteTaskPriorityError = (error: {}) => {
  return {
    type: DELETE_TASK_PRIORITY_ERROR,
    payload: error,
  };
};
