
import { useEffect } from "react";
import { isActiveMenu } from "../../helpers/functions_helper";
declare global {
    interface Window {
        grecaptcha: any;
    }
}

export default class reCAPTCHA {
    siteKey: string;
    action: string;

    constructor(siteKey: string, action: string) {
        loadReCaptcha(siteKey);
        this.siteKey = siteKey;
        this.action = action;
    }

    async getToken(): Promise<[{}]> {
        try {
            let token = "";
            await window.grecaptcha.execute(this.siteKey, { action: this.action })
                .then((res: string) => {
                    token = res;
                })
            return [{ status: "success", token: token }];
        } catch (error: any) {
            return [{ status: "error", data: error }];
        }
    }

}

const loadReCaptcha = (siteKey: string) => {
    const recaptchaScript = document.getElementById('recaptcha-script');
    if (!recaptchaScript) {
        const script = document.createElement('script');
        script.src = `https://www.recaptcha.net/recaptcha/api.js?render=${siteKey}`;
        script.id = 'recaptcha-script';
        script.async = true;

        document.body.appendChild(script);
    }
}
export const removeReCaptcha = () => {
    const recaptchaScript = document.getElementById('recaptcha-script');
    if (recaptchaScript) {
        recaptchaScript.remove();
    }
    const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
    if (recaptchaElems.length) {
        recaptchaElems[0].remove();
    }
}