import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Form,
  Label,
  Input,
  // Button,
  FormFeedback,
} from "reactstrap";
import Button from "../../components/Button/Button";
import Flatpickr from "react-flatpickr";
import labelPlugin from "flatpickr/dist/plugins/labelPlugin/labelPlugin";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useTranslation, Trans } from "react-i18next";

import MultiComments from "../../components/Common/MultiComments";
import Breadcrumbs from "../../components/Layout/Breadcrumb";
import Loader from "../../components/Layout/Loader";
import commonService from "../../services/common.service";
import {
  getOptionById,
  filteredOptions,
  addIndividualInCompany,
  filterFeesTypeByLanguage,
  getUserProfile,
  datePickerI18,
  handleAutoSave,
} from "../../helpers/functions_helper";

import ScrollUp from "../../components/ScrollUpDown/ScrollUp";
import ScrollDown from "../../components/ScrollUpDown/ScrollDown";
import { dateFormat } from "../../utils/formats";
import timeSheetsService from "../../services/time.sheets.service";

//Mail Action
import { setDraftEmailData } from "../../store/mails/actions";
import { draftmailProps } from "../Email/components/type";
import EmailButton from "../Email/components/EmailButton";

import { useCallbackPrompt } from '../../hooks/useCallbackPrompt'
import Alert from "../../components/Common/Alert";
import NavigationAlert from "../../components/Common/NavigationAlert";

const EditTimeSheet = (props: any) => {
  const { t } = useTranslation();
  document.title = `${t("Update")} ${t("time sheet")} | ` + process.env.REACT_APP_NAME;

  const toastId = React.useRef(null) as any;
  const { id } = useParams();
  const { time_sheet_id } = useParams();
  const userProfile = getUserProfile("all-time-sheets");
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(true);
  const [redirectCode, setRedirectCode] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [isInvoiced, setIsInvoiced] = useState(false);

  const localFormData = handleAutoSave("GET");
  const [autoSave, setAutoSave] = useState(false);
  const [autoSaveAlert, setAutoSaveAlert] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(autoSave);

  const [initialData, setInitialData] = useState({
    id: "",
    time_sheet_id: "",
    code: "",
    module_id: "",
    module_main_id: "",
    module_sub_id: "",
    main_id: "",
    task_subtask_id: "",
    custome_code: "",
    title: "",
    company: "",
    project: "",
    contact_person: "",
    contact_person_email: "",
    contact_person_name: "",
    responsible_person: "",
    fees_type: "",
    purchase_order_number: "",
    fees_amount: 0,
    expense: 0,
    start_date: moment().format("YYYY-MM-DD 00:00:00"),
    start_time: "",
    end_time: "",
    time_spent: "",
    is_send_invoice: 0,
    is_sensitive: 0,
    submit_btn_code: "update_add_new",

    created_by_first_name: "",
    created_by_last_name: "",
    created_at: "",
    updated_by_first_name: "",
    updated_by_last_name: "",
    updated_at: "",
  });

  const feesInitialData = {
    category: "",
    category_of_fees: "",
    fees_amount: 0,
    fees_code: "",
  };

  const [calcTimeSpentEnabled, setCalcTimeSpentEnabled] = useState(false);
  useEffect(() => {
    if (
      (initialData.start_time == "" || initialData.start_time == null) &&
      (initialData.end_time == "" || initialData.end_time == null)
    ) {
      setCalcTimeSpentEnabled(false);
    } else {
      setCalcTimeSpentEnabled(true);

      let start_time = "00:00:00";
      let end_time = "00:00:00";
      if (initialData.start_time != "" && initialData.start_time != "NaN:NaN") {
        start_time = initialData.start_time;
      }
      if (initialData.end_time != "" && initialData.end_time != "NaN:NaN") {
        end_time = initialData.end_time;
      }

      let startTime = moment(start_time, "h:mm");
      let endTime = moment(end_time, "h:mm");

      let duration = moment.duration(endTime.diff(startTime));
      let hours = Math.trunc(duration.asHours());
      let minutes = Number(duration.asMinutes()) % 60;

      // handleChange("time_spent", hours + ":" + minutes);

      setInitialData((prevData: any) => ({
        ...prevData,
        time_spent: hours + ":" + minutes,
      }));
    }
  }, [initialData.start_time, initialData.end_time]);

  useEffect(() => {
    if (initialData.start_time != "" && initialData.start_time != null) {
      if (initialData.end_time == "" || initialData.end_time == null) {
        setInitialData((prevData: any) => ({
          ...prevData,
          end_time: initialData.start_time,
        }));
      }
    }
    if (initialData.end_time != "" && initialData.end_time != null) {
      if (initialData.start_time == "" || initialData.start_time == null) {
        setInitialData((prevData: any) => ({
          ...prevData,
          start_time: "00:00:00",
        }));
      }
    }
  }, [initialData.start_time, initialData.end_time]);

  const handleRedirect = (isCancel = false) => {
    setAutoSave(false);
    handleAutoSave("CLEAR");

    if (isCancel === true) {
      return navigate("/time-sheets/all-time-sheets");
    }

    if (redirectCode === "update_add_new") {
      return navigate("/time-sheets/new-time-sheet?redirect=true");
    } else if (redirectCode === "update_close") {
      return navigate("/time-sheets/all-time-sheets?redirect=true");
    } else if (redirectCode === "update") {
      // return navigate("/time-sheets/edit-time-sheet/" + insertId + "/" + insertSubId + "?redirect=true");
      window.scrollTo(0, 0);
    } else {
      return navigate("/time-sheets/all-time-sheets?redirect=true");
    }
  };

  const handleChange = (fieldName: string, fieldValue: string) => {
    setInitialData({ ...initialData, [fieldName]: fieldValue });
    setAutoSave(true);
  };

  let { getCompanyOptionsList, getFeesOptionsList } = useSelector((state: any) => ({
    getCompanyOptionsList: state.Common.GET_COMPANY_OPTIONS_LIST_SUCCESS,
    getFeesOptionsList: state.Common.GET_FEES_OPTIONS_LIST_SUCCESS,
  }));

  useEffect(() => {
    if (redirectCode !== "") {
      handleRedirect();
    }
  }, [redirectCode]);

  Yup.setLocale({
    mixed: {
      required: "${label} " + t("field is required."),
      notOneOf: "${label} " + t("can not be same."),
    },
    string: {
      min: "${label} " + t("must be at least") + " ${min} " + t("characters."),
      max: "${label} " + t("may not be greater than") + " ${max} " + t("characters."),
    },
  });
  const validationSchema = Yup.object().shape({
    custome_code: Yup.string().label(t("Time sheet ID")).required().min(3).max(15),
    title: Yup.string().label(t("Timesheet description")).required().min(2).max(200),
    company: Yup.string().label(t("Client")).required(),
    contact_person: Yup.string().label(t("Contact person")).required(),
    fees_type: Yup.string().label(t("Task type")).required(),
    fees_amount: Yup.number().label(t("Task amount")).required(),
    expense: Yup.number().label(t("Expense")).required(),
    start_date: Yup.string().label(t("Start date")).required(),
    start_time: Yup.string().label(t("Start time")).nullable(),
    end_time: Yup.string()
      .label(t("End time"))
      .nullable()
      .when(["start_time"], {
        is: (start_time: string) => start_time != "" && start_time != null,
        then: Yup.string()
          .label(t("End time"))
          .notOneOf([Yup.ref("start_time")])
          .required(),
      }),
    time_spent: Yup.string().label(t("Time spent")).required(),
  });

  type feesTypeProps = {
    category: string;
    category_of_fees: string;
    fees_amount: number;
    fees_code: string;
  };

  const update = async (values: timeSheetPropsType) => {
    try {
      setLoading(true);
      toastId.current = toast(`${t("Updating")} ${t("timesheet")}...`, {
        autoClose: false,
        isLoading: true,
      });

      const res = (await timeSheetsService.updateTimeSheet(values)) as any;
      if (res.data.status === "success") {
        setRedirectCode(res.data.data.submit_btn_code);

        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoading(false);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        if (res.data.data) {
          Object.keys(res.data.data).map((key) => {
            toast(res.data.data[key][0], {
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              delay: 500,
            });
          });
        }
        setLoading(false);
      }
    } catch (err: any) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      update(values);
    },
  });

  useEffect(() => {
    if (!validation.isSubmitting) return;
    if (Object.keys(validation.errors).length > 0) {
      document
        .getElementById(Object.keys(validation.errors)[0])
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
      document.getElementById(Object.keys(validation.errors)[0])?.focus({ preventScroll: true });
    }
  }, [validation.isSubmitting, validation.errors]);

  // get and set TimeSheet API call -------------------------------------------------
  const get = async () => {
    try {
      setLoading(true);
      const res = (await timeSheetsService.getTimeSheet({
        id,
        time_sheet_id,
      })) as any;
      if (res.data.status === "success") {
        updateInitialData(res.data.data[0]);
        setLoading(false);
      } else {
        toast(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 5000,
          delay: 500,
        });
        setLoading(false);
        handleRedirect();
      }
    } catch (err: any) {
      toast(err?.message, {
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        delay: 500,
      });
      setLoading(false);
      handleRedirect();
    }
  };

  useEffect(() => {
    if (localFormData && localFormData?.initialData) {
      updateInitialData(localFormData?.initialData);
      setAutoSaveAlert(true);
    } else {
      get();
    }
  }, []);

  const [feesAmount, setFeesAmount] = useState("");
  const [feesAmountUsed, setFeesAmountUsed] = useState(false);

  function updateInitialData(data: any) {
    const updatedInitialData = {
      ...initialData,
      id: data.id,
      time_sheet_id: data.time_sheet_id,
      code: data.code,
      module_id: data.module_id,
      module_main_id: data.module_main_id,
      module_sub_id: data.module_sub_id,
      main_id: data.main_id,
      task_subtask_id: data.module_id + "-" + data.module_main_id,
      custome_code: data.custome_code,
      title: data.title,
      company: data.company,
      project: data.project,
      contact_person: data.contact_person == null ? "" : data.contact_person,
      contact_person_email: data.contact_person_email == null ? "" : data.contact_person_email,
      contact_person_name: data.contact_person_name == null ? "" : data.contact_person_name,
      responsible_person: data.responsible_person == null ? "" : data.responsible_person,
      fees_type: data.fees_type,
      purchase_order_number: data.purchase_order_number,
      fees_amount: data.fees_amount,
      expense: data.expense,
      start_date: data.start_date,
      start_time: data.start_time == "NaN:NaN" || data.start_time == null ? "" : data.start_time,
      end_time: data.end_time == "NaN:NaN" || data.end_time == null ? "" : data.end_time,
      time_spent: data.time_spent,
      is_send_invoice: data.is_send_invoice,
      is_sensitive: data.is_sensitive,

      created_by_first_name: data.created_by_first_name,
      created_by_last_name: data.created_by_last_name,
      created_at: data.created_at,
      updated_by_first_name: data.updated_by_first_name,
      updated_by_last_name: data.updated_by_last_name,
      updated_at: data.updated_at,
    };
    setInitialData(updatedInitialData);

    setFeesAmount(data.fees_amount);
    setCompanyID(data.company);
    setFeesTypeID(data.fees_type);

    setIsDisabled(
      parseInt(data.module_id) !== 4
        ? true
        : parseInt(data.module_id) === 4 &&
          data.is_send_invoice !== 0 &&
          data.is_send_invoice !== null
          ? true
          : false
    );
    setIsInvoiced(
      parseInt(data.module_id) === 4 && data.is_send_invoice !== 0 && data.is_send_invoice !== null
        ? true
        : false
    );

    window.scrollTo(0, 0);
    setLoader(false);
  }

  // Select options list API call -------------------------------------------------
  const [query, setQuery] = useState("");
  const getDependentSelectOptions = async (
    tableName: string,
    fieldName: string,
    whereField: string,
    whereID: number
  ) => {
    const response = await commonService.getDependentSelectOptionsList(
      tableName,
      fieldName,
      whereField,
      whereID,
      query ? query : ""
    );
    return response.data.data;
  };

  const [loadingCompany, setLoadingCompany] = useState(true);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyID, setCompanyID] = useState<any>(null);
  useEffect(() => {
    if (getCompanyOptionsList.data) {
      const item = addIndividualInCompany(getCompanyOptionsList.data) as any;
      setCompanyOptions(item);
      setLoadingCompany(false);
    }
  }, [getCompanyOptionsList]);

  const [loadingContactPerson, setLoadingContactPerson] = useState(false);
  const [disabledContactPerson, setDisabledContactPerson] = useState(true);
  const [contactPersonOptions, setContactPersonOptions] = useState([]);

  const [loadingResponsiblePerson, setLoadingResponsiblePerson] = useState(false);
  const [disabledResponsiblePerson, setDisabledResponsiblePerson] = useState(true);
  const [responsiblePersonOptions, setResponsiblePersonOptions] = useState([]);

  const [loadingProjectOption, setLoadingProjectOption] = useState(false);
  const [disabledProjectOption, setDisabledProjectOption] = useState(true);
  const [projectOption, setProjectOption] = useState([]);
  const [contactPersonUsed, setContactPersonUsed] = useState(false);
  useEffect(() => {
    if (companyOptions.length > 0 && String(companyID) !== "") {
      // initialData.project = "";
      setProjectOption([]);
      setLoadingProjectOption(true);
      setDisabledProjectOption(true);
      getDependentSelectOptions("tbl_projects", "name", "company_id", companyID).then(function (
        options
      ) {
        setProjectOption(options);
        setLoadingProjectOption(false);
        setDisabledProjectOption(false);
      });

      // initialData.contact_person = "";
      setContactPersonOptions([]);
      setLoadingContactPerson(true);
      setDisabledContactPerson(true);

      // initialData.responsible_person = "";
      setResponsiblePersonOptions([]);
      setLoadingResponsiblePerson(true);
      setDisabledResponsiblePerson(true);

      getDependentSelectOptions("tbl_master_users", "first_name", "company", companyID).then(
        function (options) {
          if (companyID == 0) {
            if (contactPersonUsed === false) {
              setContactPersonUsed(true);
            } else {
              setInitialData((preState) => ({
                ...preState,
                contact_person: "",
                responsible_person: "",
              }));
            }

            setContactPersonOptions(options);
            setLoadingContactPerson(false);
            setDisabledContactPerson(false);

            setResponsiblePersonOptions([]);
            setLoadingResponsiblePerson(false);
            setDisabledResponsiblePerson(true);
          } else {
            const item = getOptionById(companyOptions, companyID) as any;
            let contact_persons = filteredOptions(options as any, [
              item?.contact_person && item?.contact_person,
              item?.invoice_contact_person && item?.invoice_contact_person,
              item?.hr_contact_person && item?.hr_contact_person,
            ]) as any;
            contact_persons.push({ value: "", label: t("-- Remove --") });
            setContactPersonOptions(contact_persons);
            setLoadingContactPerson(false);
            setDisabledContactPerson(false);

            setResponsiblePersonOptions(options);
            setLoadingResponsiblePerson(false);
            setDisabledResponsiblePerson(false);
          }
        }
      );
    }
  }, [companyOptions, companyID]);

  const [loadingFeesTypeOptions, setLoadingFeesTypeOptions] = useState(true);
  const [disabledFeesTypeOptions, setDisabledFeesTypeOptions] = useState(true);
  const [feesTypeOptions, setFeesTypeOptions] = useState([]);
  const [feesTypeID, setFeesTypeID] = useState<number>(0);
  useEffect(() => {
    if (getFeesOptionsList.data) {
      let feesOptionsList = filterFeesTypeByLanguage(getFeesOptionsList.data);
      feesOptionsList.push({ value: "", label: t("-- Remove --") });
      setFeesTypeOptions(feesOptionsList);
      setLoadingFeesTypeOptions(false);
      setDisabledFeesTypeOptions(false);
    }
  }, [getFeesOptionsList, localStorage.getItem("I18N_LANGUAGE")]);

  const [feesData, setFeesData] = useState<feesTypeProps>(feesInitialData);
  useEffect(() => {
    if (feesTypeID > 0) {
      if (getFeesOptionsList.data) {
        let feesOptionsList = filterFeesTypeByLanguage(getFeesOptionsList.data);

        const item = getOptionById(feesOptionsList, feesTypeID) as feesTypeProps | any;
        setFeesData(item);
        if (feesAmountUsed === false) {
          // handleChange("fees_amount", feesAmount);

          setInitialData((prevData: any) => ({
            ...prevData,
            fees_amount: feesAmount,
          }));

          setFeesAmountUsed(true);
        } else {
          // handleChange("fees_amount", item.fees_amount);

          setInitialData((prevData: any) => ({
            ...prevData,
            fees_amount: item.fees_amount,
          }));
        }
      }
    }
  }, [feesTypeID, getFeesOptionsList, localStorage.getItem("I18N_LANGUAGE")]);

  const [loadingTaskSubtaskOption, setLoadingTaskSubtaskOption] = useState(false);
  const [disabledTaskSubtaskOption, setDisabledTaskSubtaskOption] = useState(true);
  const [taskSubtaskOption, setTaskSubtaskOption] = useState([]);

  const getTaskSubtask = async (data: any) => {
    try {
      setLoadingTaskSubtaskOption(true);
      // setDisabledTaskSubtaskOption(true);
      const res = (await commonService.getTaskSubtask(data)) as any;
      if (res.data.status === "success") {
        setTaskSubtaskOption(res.data.data);

        setLoadingTaskSubtaskOption(false);
        // setDisabledTaskSubtaskOption(false);
      } else {
        setTaskSubtaskOption([]);
        setLoadingTaskSubtaskOption(false);
        // setDisabledTaskSubtaskOption(true);
      }
    } catch (err: any) {
      setTaskSubtaskOption([]);
      setLoadingTaskSubtaskOption(false);
      // setDisabledTaskSubtaskOption(true);
    }
  };

  useEffect(() => {
    // if (initialData.company != "") {
    getTaskSubtask({
      company: initialData.company,
      project: initialData.project,
      contact_person: initialData.contact_person,
      responsible_person: initialData.responsible_person,
    });
    // } else {
    //   setTaskSubtaskOption([]);
    //   setLoadingTaskSubtaskOption(false);
    //   setDisabledTaskSubtaskOption(true);
    // }
  }, [
    initialData.company,
    initialData.project,
    initialData.contact_person,
    initialData.responsible_person,
  ]);

  //Email compose
  const composeMail = async () => {
    const user = {
      label: `${initialData?.contact_person_name}`,
      value: initialData?.contact_person_email,
    };
    const draftMail: draftmailProps = {
      title: `TimeSheet-${initialData?.contact_person_name}`,
      filters: {},
      openMailBox: 1,
      subject: "",
      to: [user],
    };
    dispatch(setDraftEmailData(draftMail));
  };

  useEffect(() => {
    if (autoSave) { handleAutoSave("SET", initialData); }
  }, [initialData, autoSave]);

  return (
    <React.Fragment>
      {loader ? <Loader /> : null}

      <ScrollUp />
      <ScrollDown top={"100px"} />

      <Alert
        open={autoSaveAlert}
        title={t("Autosaved data restored!")}
        message={t("Your last autosaved data has been restored from the session. In order to avoid the data loss, you need either save it to retain the data or Cancel to loose the data.")}
        onClickOk={() => setAutoSaveAlert(false)}
      />
      <NavigationAlert
        open={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={t("Update time sheet")}
            breadcrumbItems={[
              {
                url: "/dashboard",
                label: t("Dashboard"),
                active: false,
              },
              {
                url: "/time-sheets/all-time-sheets",
                label: t("Time sheets"),
                active: false,
              },
              {
                url: "#",
                label: t("Update time sheet"),
                active: true,
              },
            ]}
            endAdornment={
              <div style={{ marginRight: "42px" }}>
                <EmailButton handlerCompose={() => composeMail()} />
              </div>
            }
          />

          <Row>
            <Col xl="12">
              <Card>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                  autoComplete="off"
                >
                  <Input id="id" name="id" type="hidden" value={initialData.id} />
                  <Input
                    id="time_sheet_id"
                    name="time_sheet_id"
                    type="hidden"
                    value={initialData.time_sheet_id}
                  />
                  <Input id="code" name="code" type="hidden" value={initialData.code} />

                  <CardBody>
                    <Row>
                      <Input
                        id="module_id"
                        name="module_id"
                        type="hidden"
                        value={initialData.module_id || ""}
                      />

                      <Input
                        id="module_main_id"
                        name="module_main_id"
                        type="hidden"
                        value={initialData.module_main_id || ""}
                      />

                      <Input
                        id="module_sub_id"
                        name="module_sub_id"
                        type="hidden"
                        value={initialData.module_sub_id || ""}
                      />

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Client")} <span className="text-warning is_required">*</span>
                          </Label>
                          <Select
                            id="company"
                            name="company"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={companyOptions}
                            value={
                              parseInt(initialData.company) >= 0
                                ? companyOptions.find(
                                  (option: any) => option.value == initialData.company
                                )
                                : ""
                            }
                            isSearchable={true}
                            // isClearable={true}
                            isDisabled={isDisabled ? isDisabled : loadingCompany}
                            onBlur={() => validation.setFieldTouched("company", true)}
                            onChange={(e: any) => {
                              handleChange("company", e.value);
                              setCompanyID(e.value);
                            }}
                          />
                          {validation.touched.company && validation.errors.company ? (
                            <FormFeedback type="invalid">{validation.errors.company}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">{t("Project")}</Label>
                          <Select
                            id="project"
                            name="project"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={projectOption}
                            value={
                              initialData.project
                                ? projectOption.find(
                                  (option: any) => option.value == initialData.project
                                )
                                : ""
                            }
                            isSearchable={true}
                            isLoading={loadingProjectOption}
                            isDisabled={isDisabled ? isDisabled : disabledProjectOption}
                            onBlur={() => validation.setFieldTouched("project", true)}
                            onChange={(e: any) => {
                              // handleChange("project", e.value);
                              // handleChange("purchase_order_number", e.po_number);
                              setInitialData((state: any) => ({ ...state, project: e.value, purchase_order_number: e.po_number }))
                            }}
                          />
                          {validation.touched.project && validation.errors.project ? (
                            <FormFeedback type="invalid">{validation.errors.project}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">{t("Purchase order number")}</Label>
                          <Input
                            id="purchase_order_number"
                            name="purchase_order_number"
                            placeholder={t("Purchase order number")}
                            type="text"
                            maxLength={50}
                            value={initialData.purchase_order_number}
                            disabled={isDisabled}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("purchase_order_number", e.target.value)}
                          />
                        </div>
                      </Col>

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Contact person")}{" "}
                            <span className="text-warning is_required">*</span>
                          </Label>
                          <Select
                            id="contact_person"
                            name="contact_person"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={contactPersonOptions}
                            value={
                              initialData.contact_person
                                ? contactPersonOptions.find(
                                  (option: any) => option.value == initialData.contact_person
                                )
                                : ""
                            }
                            isSearchable={true}
                            // isClearable={true}
                            isLoading={loadingContactPerson}
                            isDisabled={isDisabled ? isDisabled : disabledContactPerson}
                            onBlur={() => validation.setFieldTouched("contact_person", true)}
                            onChange={(e: any) => {
                              handleChange("contact_person", e.value);
                            }}
                          />
                          {validation.touched.contact_person && validation.errors.contact_person ? (
                            <FormFeedback type="invalid">
                              {validation.errors.contact_person}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">{t("Person concerned")}</Label>
                          <Select
                            id="responsible_person"
                            name="responsible_person"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={responsiblePersonOptions}
                            value={
                              initialData.responsible_person
                                ? responsiblePersonOptions.find(
                                  (option: any) => option.value == initialData.responsible_person
                                )
                                : ""
                            }
                            isSearchable={true}
                            // isClearable={true}
                            isLoading={loadingResponsiblePerson}
                            isDisabled={isDisabled ? isDisabled : disabledResponsiblePerson}
                            onBlur={() => validation.setFieldTouched("responsible_person", true)}
                            onChange={(e: any) => {
                              handleChange("responsible_person", e.value);
                            }}
                          />
                          {validation.touched.responsible_person &&
                            validation.errors.responsible_person ? (
                            <FormFeedback type="invalid">
                              {validation.errors.responsible_person}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col xl={9} lg={9} md={9} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">{`${t("Task")} / ${t("Subtask")} ${t(
                            "title"
                          )}`}</Label>
                          <Select
                            id="task_subtask_id"
                            name="task_subtask_id"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={taskSubtaskOption}
                            value={
                              initialData.task_subtask_id
                                ? taskSubtaskOption.find(
                                  (option: any) => option.value == initialData.task_subtask_id
                                )
                                : ""
                            }
                            isSearchable={true}
                            isLoading={loadingTaskSubtaskOption}
                            isDisabled={disabledTaskSubtaskOption}
                            onBlur={() => validation.setFieldTouched("task_subtask_id", true)}
                            onChange={(e: any) => {
                              handleChange("task_subtask_id", e.value);
                            }}
                          />
                        </div>
                      </Col>

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <Col xl={12} lg={12} md={12} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Task type")} <span className="text-warning is_required">*</span>
                          </Label>
                          <Select
                            id="fees_type"
                            name="fees_type"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={feesTypeOptions}
                            value={
                              initialData.fees_type
                                ? feesTypeOptions.find(
                                  (option: any) => option.value == initialData.fees_type
                                )
                                : ""
                            }
                            isSearchable={true}
                            isLoading={loadingFeesTypeOptions}
                            isDisabled={isDisabled ? isDisabled : disabledFeesTypeOptions}
                            onBlur={() => validation.setFieldTouched("fees_type", true)}
                            onChange={(e: any) => {
                              handleChange("fees_type", e.value);
                              setFeesTypeID(e.value);
                            }}
                          />
                          {validation.touched.fees_type && validation.errors.fees_type ? (
                            <FormFeedback type="invalid">
                              {validation.errors.fees_type}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">{t("Category of task")}</Label>
                          <Input
                            placeholder={t("Category of task")}
                            type="text"
                            value={feesData.category_of_fees}
                            readOnly
                          />
                        </div>
                      </Col>

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">{t("Task code")}</Label>
                          <Input
                            placeholder={t("Task code")}
                            type="text"
                            value={feesData.fees_code}
                            readOnly
                          />
                        </div>
                      </Col>

                      {userProfile.USER_FUNCTION === 6 ? (
                        <Col xl={3} lg={3} md={3} sm={12}>
                          <div className="mb-3">
                            <Label className="form-label">{t("Task amount")}</Label>
                            <Input
                              id="fees_amount"
                              name="fees_amount"
                              placeholder={t("Task amount")}
                              type="number"
                              min={0}
                              value={initialData.fees_amount}
                              disabled={isDisabled}
                              onBlur={validation.handleBlur}
                              onChange={(e) => {
                                handleChange("fees_amount", e.target.value);
                              }}
                            />
                          </div>
                        </Col>
                      ) : null}

                      {/* <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Task category")}
                          </Label>
                          <Input
                            placeholder={t("Task category")}
                            type="text"
                            value={feesData.category}
                            readOnly
                          />
                        </div>
                      </Col>

                      <Col className="col-12">
                        <hr></hr>
                      </Col> */}

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">{t("Expense")}</Label>
                          <Input
                            id="expense"
                            name="expense"
                            placeholder={t("Expense")}
                            type="number"
                            min={0}
                            value={initialData.expense}
                            disabled={isDisabled}
                            onBlur={validation.handleBlur}
                            onChange={(e) => {
                              handleChange("expense", e.target.value);
                            }}
                          />
                        </div>
                      </Col>

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Time sheet ID")} <span className="text-warning is_required">*</span>
                          </Label>
                          <Input
                            id="custome_code"
                            name="custome_code"
                            placeholder={t("Time sheet ID")}
                            type="text"
                            value={initialData.custome_code || ""}
                            disabled={isDisabled}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("custome_code", e.target.value)}
                            invalid={
                              validation.touched.custome_code && validation.errors.custome_code
                                ? true
                                : false
                            }
                          />
                          {validation.touched.custome_code && validation.errors.custome_code ? (
                            <FormFeedback type="invalid">
                              {validation.errors.custome_code}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col xl={9} lg={9} md={9} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Timesheet description")}{" "}
                            <span className="text-warning is_required">*</span>
                          </Label>
                          <Input
                            id="title"
                            name="title"
                            placeholder={t("Timesheet description")}
                            type="text"
                            // autoFocus={true}
                            value={initialData.title || ""}
                            disabled={isDisabled}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("title", e.target.value)}
                          />
                        </div>
                      </Col>

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <Col xl={4} lg={4} md={4} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Start date")} <span className="text-warning is_required">*</span>
                          </Label>
                          <div className="input-group is-invalid">
                            {isInvoiced === false ? (
                              <Flatpickr
                                id="start_date"
                                name="start_date"
                                className="form-control d-block"
                                type="text"
                                value={initialData.start_date}
                                placeholder="DD/MM/YYYY"
                                options={{
                                  altInput: true,
                                  altFormat: "d/m/Y",
                                  dateFormat: "Y-m-d H:i:S",
                                  maxDate: new Date(),
                                  allowInput: true,
                                  plugins: [labelPlugin()],
                                  onChange: function (selectedDates, dateStr, instance) {
                                    handleChange("start_date", dateStr);
                                  },
                                  onClose: function (selectedDates, dateStr, instance) {
                                    handleChange("start_date", dateStr);
                                  },
                                  locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                                }}
                              />
                            ) : (
                              <Input
                                id="start_date"
                                name="start_date"
                                className="form-control d-block"
                                type="text"
                                value={
                                  initialData.start_date
                                    ? moment(initialData.start_date).format("DD/MM/YYYY")
                                    : ""
                                }
                                placeholder="DD/MM/YYYY"
                                disabled={true}
                              />
                            )}

                            <span className="input-group-text">
                              <span className="align-middle material-symbols-outlined fw-light fs-4">
                                calendar_today
                              </span>
                            </span>
                          </div>
                          {validation.touched.start_date && validation.errors.start_date ? (
                            <FormFeedback type="invalid">
                              {validation.errors.start_date}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-12"></Col>

                      <Col xl={4} lg={4} md={4} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">{t("Start time")}</Label>
                          <div className="input-group is-invalid">
                            {isInvoiced === false ? (
                              <Flatpickr
                                id="start_time"
                                name="start_time"
                                className="form-control d-block"
                                type="text"
                                value={initialData.start_time || ""}
                                placeholder="HH:MM"
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  dateFormat: "H:i:S",
                                  time_24hr: true,
                                  allowInput: true,
                                  plugins: [labelPlugin()],
                                  maxTime: initialData.end_time,
                                  defaultHour: 8,
                                  onChange: function (selectedDates, dateStr, instance) {
                                    handleChange("start_time", dateStr);
                                  },
                                  onClose: function (selectedDates, dateStr, instance) {
                                    handleChange("start_time", dateStr);
                                  },
                                  locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                                }}
                              />
                            ) : (
                              <Input
                                id="start_time"
                                name="start_time"
                                className="form-control d-block"
                                type="text"
                                value={
                                  initialData.start_time
                                    ? moment(initialData.start_time, "HH:mm:ss").format("HH:mm:ss")
                                    : ""
                                }
                                placeholder="HH:MM"
                                disabled={true}
                              />
                            )}
                            <span className="input-group-text">
                              <span className="align-middle material-symbols-outlined fw-light fs-4">
                                schedule
                              </span>
                            </span>
                          </div>

                          {validation.touched.start_time && validation.errors.start_time ? (
                            <FormFeedback type="invalid">
                              {validation.errors.start_time}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col xl={4} lg={4} md={4} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">{t("End time")}</Label>
                          <div className="input-group is-invalid">
                            {isInvoiced === false ? (
                              <Flatpickr
                                id="end_time"
                                name="end_time"
                                className="form-control d-block"
                                type="text"
                                value={initialData.end_time || ""}
                                placeholder="HH:MM"
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  dateFormat: "H:i:S",
                                  time_24hr: true,
                                  allowInput: true,
                                  plugins: [labelPlugin()],
                                  minTime: initialData.start_time,
                                  defaultHour: 8,
                                  onChange: function (selectedDates, dateStr, instance) {
                                    handleChange("end_time", dateStr);
                                  },
                                  onClose: function (selectedDates, dateStr, instance) {
                                    handleChange("end_time", dateStr);
                                  },
                                  locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                                }}
                              />
                            ) : (
                              <Input
                                id="end_time"
                                name="end_time"
                                className="form-control d-block"
                                type="text"
                                value={
                                  initialData.end_time
                                    ? moment(initialData.end_time, "HH:mm:ss").format("HH:mm:ss")
                                    : ""
                                }
                                placeholder="HH:MM"
                                disabled={true}
                              />
                            )}
                            <span className="input-group-text">
                              <span className="align-middle material-symbols-outlined fw-light fs-4">
                                schedule
                              </span>
                            </span>
                          </div>

                          {validation.touched.end_time && validation.errors.end_time ? (
                            <FormFeedback type="invalid">{validation.errors.end_time}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col xl={4} lg={4} md={4} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Time spent")} <span className="text-warning is_required">*</span>
                          </Label>
                          <div className="input-group is-invalid">
                            {isInvoiced === false ? (
                              <Flatpickr
                                id="time_spent"
                                name="time_spent"
                                className="form-control d-block"
                                type="text"
                                value={initialData.time_spent || ""}
                                placeholder="HH:MM"
                                disabled={calcTimeSpentEnabled}
                                options={{
                                  enableTime: true,
                                  noCalendar: true,
                                  dateFormat: "H:i:S",
                                  time_24hr: true,
                                  allowInput: true,
                                  plugins: [labelPlugin()],
                                  defaultHour: 0,
                                  defaultMinute: 0,
                                  onChange: function (selectedDates, dateStr, instance) {
                                    handleChange("time_spent", dateStr);
                                  },
                                  onClose: function (selectedDates, dateStr, instance) {
                                    handleChange("time_spent", dateStr);
                                  },
                                  locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                                }}
                              />
                            ) : (
                              <Input
                                id="time_spent"
                                name="time_spent"
                                className="form-control d-block"
                                type="text"
                                value={
                                  initialData.time_spent
                                    ? moment(initialData.time_spent, "HH:mm:ss").format("HH:mm:ss")
                                    : ""
                                }
                                placeholder="HH:MM"
                                disabled={true}
                              />
                            )}
                            <span className="input-group-text">
                              <span className="align-middle material-symbols-outlined fw-light fs-4">
                                schedule
                              </span>
                            </span>
                          </div>

                          {validation.touched.time_spent && validation.errors.time_spent ? (
                            <FormFeedback type="invalid">
                              {validation.errors.time_spent}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <Col className="col-12">
                        <div className="mb-0">
                          <Label className="form-label">{t("Comments")}</Label>
                          <MultiComments
                            moduleData={{
                              module_app_id: 4,
                              module_main_id: id,
                              module_sub_id: time_sheet_id,
                              section_id: 0,
                            }}
                            commentForIDs={
                              [
                                // {
                                //   value: initialData.contact_person,
                                //   label: "Contact person",
                                // },
                                // {
                                //   value: initialData.responsible_person,
                                //   label: "Person concerned",
                                // },
                              ]
                            }
                            fetchData={true}
                            isOpen={false}
                            isDisabled={isInvoiced}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>

                  <CardFooter className="bg-transparent border-top">
                    <Row>
                      <Col className="col-12 mt-2 mb-3">
                        {initialData &&
                          initialData.created_by_first_name &&
                          initialData.created_by_last_name ? (
                          <div>
                            <ul className="list-inline mb-1">
                              <li className="list-inline-item me-3">
                                <span className="text-muted">
                                  <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                    person
                                  </span>
                                  {t("Created by")} {initialData.created_by_first_name}{" "}
                                  {initialData.created_by_last_name}
                                </span>
                              </li>
                              <li className="list-inline-item me-3">
                                <span className="text-muted">
                                  <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                    calendar_today
                                  </span>
                                  {dateFormat(initialData.created_at)}
                                </span>
                              </li>
                            </ul>
                          </div>
                        ) : null}

                        {initialData &&
                          initialData.updated_by_first_name &&
                          initialData.updated_by_last_name ? (
                          <div>
                            <ul className="list-inline">
                              <li className="list-inline-item me-3">
                                <span className="text-muted">
                                  <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                    person
                                  </span>
                                  {t("Last modified by")} {initialData.updated_by_first_name}{" "}
                                  {initialData.updated_by_last_name}
                                </span>
                              </li>
                              <li className="list-inline-item me-3">
                                <span className="text-muted">
                                  <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                    calendar_today
                                  </span>
                                  {dateFormat(initialData.updated_at)}
                                </span>
                              </li>
                            </ul>
                          </div>
                        ) : null}
                      </Col>
                    </Row>

                    <div className="d-flex gap-2"></div>

                    <div className="ms-auto d-flex gap-2 justify-content-end">
                      {userProfile.CAN_CREATE && initialData.is_send_invoice !== 1 ? (
                        <>
                          <Button
                            color="primary"
                            className="btn-block"
                            type="submit"
                            onClick={() => handleChange("submit_btn_code", "update_add_new")}
                            disabled={loading}
                            loading={loading}
                          >
                            {t("Update & Add new")}
                          </Button>

                          <Button
                            color="primary"
                            className="btn-block"
                            type="submit"
                            onClick={() => handleChange("submit_btn_code", "update_close")}
                            disabled={loading}
                            loading={loading}
                          >
                            {t("Update & Close")}
                          </Button>

                          <Button
                            color="primary"
                            className="btn-block"
                            type="submit"
                            onClick={() => handleChange("submit_btn_code", "update")}
                            disabled={loading}
                            loading={loading}
                          >
                            {t("Update")}
                          </Button>

                          <Button
                            color="danger"
                            className="btn-block"
                            type="reset"
                            onClick={() => {
                              handleRedirect(true);
                            }}
                            disabled={loading}
                            loading={loading}
                          >
                            {t("Cancel")}
                          </Button>
                        </>
                      ) : null}
                    </div>
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditTimeSheet;
