import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  Label,
  Button,
  FormFeedback,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { datePickerI18, getSelectOptions, yearData } from "../../../helpers/functions_helper";
import { useTranslation } from "react-i18next";
import labelPlugin from "flatpickr/dist/plugins/labelPlugin/labelPlugin";
import Select from "../../../components/Form/Select/Select";
import Flatpickr from "../../../components/Form/Flatpickr";
import Input from "../../../components/Form/Input";

const BankHolidayForm = (props: any) => {
  const { t } = useTranslation();
  let holidayData = props.holidayData;

  const [countryOptions, setCountryOptions] = useState([]);
  const [intialData, setIntialData] = useState({
    id: (holidayData && holidayData.id) || "",
    country: (holidayData && holidayData.country) || 21,
    year: (holidayData && holidayData.year) || moment().format('YYYY'),
    holiday_date: (holidayData && holidayData.holiday_date) || "",
    holiday_name: (holidayData && holidayData.holiday_name) || "",
  });

  const validationSchema = Yup.object().shape({
    country: Yup.number()
      .required("Country field is required."),
    year: Yup.number()
      .required("Year field is required."),
    holiday_date: Yup.string()
      .required("Date field is required."),
    holiday_name: Yup.string()
      .required("Holiday field is required.")
  });


  const handleChange = (fieldName: string, fieldValue: any) => {
    setIntialData({ ...intialData, [fieldName]: fieldValue });
  };


  const validation = useFormik({
    enableReinitialize: true,
    initialValues: intialData,
    validationSchema: validationSchema,
    onSubmit: (values, { setErrors }) => {
      props.onSubmitClick(values);
    },
  });



  useEffect(() => {
    getSelectOptions(
      "tbl_master_country",
      "name",
    ).then(function (options) {
      setCountryOptions(options);
      //setWorkCntryUser(options);
    });
  }, [])


  useEffect(() => {
    if (!validation.isSubmitting) return;
    if (Object.keys(validation.errors).length > 0) {
      document
        .getElementById(Object.keys(validation.errors)[0])
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
      document
        .getElementById(Object.keys(validation.errors)[0])
        ?.focus({ preventScroll: true });
    }
  }, [validation.isSubmitting, validation.errors]);

  const handleDelete =()=>{
    props.handleClickDelete(intialData);
  }

  return (
    <React.Fragment>
      <Modal
        size="md"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">{props.modalTitle}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label="Close"
          ></button>
        </div>

        <Form
          onSubmit={(e: any) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          autoComplete="off"
        >
          <ModalBody>
            <Row>
              <Col lg={12}>
                <div className="mb-3">
                  <div>
                    <Select
                      id="country"
                      name="country"
                      label="Country"
                      required
                      options={countryOptions}
                      isSearchable={true}
                      onChange={(option: any) => {
                        handleChange("country", option.value)
                      }}
                      value={countryOptions?.find((o: any) => intialData.country === o.value)}
                      error={validation.errors.country}
                      touched={validation.touched.country}
                      isDisabled={true}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <div>
                    <Select
                      id="year"
                      name="year"
                      label="Year"
                      options={yearData}
                      isSearchable={true}
                      onChange={(option: any) => {
                        handleChange("year", option.value)
                      }}
                      value={yearData?.find((o: any) => intialData.year === o.value)}
                      error={validation.errors.year}
                      touched={validation.touched.year}
                    />
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <div className="input-group is-invalid">
                    <Flatpickr
                      id="holiday_date"
                      name="holiday_date"
                      label="Date"
                      required
                      className="form-control d-block"
                      type="text"
                      value={intialData.holiday_date}
                      placeholder="DD/MM/YYYY"
                      options={{
                        altInput: true,
                        altFormat: "d/m/Y",
                        dateFormat: "Y-m-d H:i:S",
                        minDate: `${intialData.year}-01-01`,
                        maxDate: `${intialData.year}-12-31`,
                        allowInput: true,
                        plugins: [labelPlugin()],
                        onChange: function (selectedDates, dateStr, instance) {
                          handleChange("holiday_date", dateStr);
                        },
                        onClose: function (selectedDates, dateStr, instance) {
                          handleChange("holiday_date", dateStr);
                        },
                        locale: { ...datePickerI18(),firstDayOfWeek: 1 }
                      }}
                      error={validation.errors.holiday_date}
                      touched={validation.touched.holiday_date}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <div className="mb-3">
                <Input
                  id="holiday_name"
                  name="holiday_name"
                  placeholder={t("Holiday")}
                  label="Holiday"
                  required
                  type="text"
                  autoFocus={true}
                  value={intialData.holiday_name}
                  onBlur={validation.handleBlur}
                  onChange={(e) => handleChange("holiday_name", e.target.value)}
                  invalid={
                    validation.touched.holiday_name && validation.errors.holiday_name
                      ? true
                      : false
                  }
                  error={validation.errors.holiday_name}
                   touched={validation.touched.holiday_name}
                />
              </div>
            </Row>
          </ModalBody>
          <ModalFooter>
            {!!props.enableDelete &&
              <Button
                color="warning"
                className="btn-block"
                type="button"
                onClick={handleDelete}
                disabled={props.loading}
              >
                {props.loading ? (
                  <span>
                    <i className="fa-solid fa-spinner fa-spin"></i>
                    <span>&nbsp; {t("Please wait...")}</span>
                  </span>
                ) : (
                  <span>{t("Delete")}</span>
                )}
              </Button>
            }
            <Button
              color="primary"
              className="btn-block"
              type="submit"
              disabled={props.loading}
            >
              {props.loading ? (
                <span>
                  <i className="fa-solid fa-spinner fa-spin"></i>
                  <span>&nbsp; {t("Please wait...")}</span>
                </span>
              ) : (
                <span>{t("Submit")}</span>
              )}
            </Button>
            <Button
              color="danger"
              className="btn-block"
              type="reset"
              onClick={props.onCloseClick}
              disabled={props.loading}
            >
              {t("Cancel")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default BankHolidayForm;
