import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  Label,
  Button,
  FormFeedback,
  Alert,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

import Input from "../../../components/Form/Input";
import { useTranslation } from "react-i18next";

const UserFunctionForm = (props: any) => {
  const {t}= useTranslation();
  let userFunctionData = props.userFunctionData;

  const validationSchema = Yup.object().shape({
    user_function_value: Yup.string()
      .required("User function field is required.")
      .min(2, "User function must be at least 2 characters.")
      .max(200, "User function may not be greater than 200 characters."),
    consultant_fees: Yup.number().integer()
      .required("Consultant fees field is required.")
      .typeError("you must specify a number")
      .min(1, "Consultant fees must be at least 1.")
      .max(1000, "Consultant fees may not be greater than 1000."),
  });

  const handleSubmit = (values: {
    id: string;
    user_function_id: string;
    user_function_value: string;
    color_code: string;
    consultant_fees: number;
  }) => {
    props.onSubmitClick(values);
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: (userFunctionData && userFunctionData.id) || "",
      user_function_id: (userFunctionData && userFunctionData.user_function_id) || "",
      user_function_value: (userFunctionData && userFunctionData.user_function_value) || "",
      color_code: (userFunctionData && userFunctionData.color_code) || "#000000",
      consultant_fees: (userFunctionData && userFunctionData.consultant_fees) || "0",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setErrors }) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    if (!validation.isSubmitting) return;
    if (Object.keys(validation.errors).length > 0) {
      document
        .getElementById(Object.keys(validation.errors)[0])
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
      document
        .getElementById(Object.keys(validation.errors)[0])
        ?.focus({ preventScroll: true });
    }
  }, [validation.isSubmitting, validation.errors]);

  return (
    <React.Fragment>
      <Modal
        size="md"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">{props.modalTitle}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label={t("Close")}
          ></button>
        </div>

        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          autoComplete="off"
        >
          <ModalBody>
            {props.errorMsg ? 
              <Alert color="danger">
                {props.errorMsg}

                {props.errorData.data ?
                  Object.keys(props.errorData.data).map(key => (
                    <li color="danger" key={key}>{props.errorData.data[key]}</li>
                  )) : null
                }
              </Alert> : null
            }

            <Input
              id="id"
              name="id"
              type="hidden"
              value={validation.values.id || ""}
              onChange={validation.handleChange}
            />
            <Input
              id="user_function_id"
              name="user_function_id"
              type="hidden"
              value={validation.values.user_function_id || ""}
              onChange={validation.handleChange}
            />

            <Row>
              <Col className="col-12">
                <div className="mb-3">
                  <div className="color_palette_main_div">
                    <Input
                      id="user_function_value"
                      name="user_function_value"
                      className="form-control"
                      label="User function"
                      required
                      placeholder={t("User function")}
                      type="text"
                      value={validation.values.user_function_value || ""}
                      autoFocus={true}
                      onKeyUp={validation.handleChange}
                      onChange={validation.handleChange}
                      invalid={
                        validation.touched.user_function_value &&
                        validation.errors.user_function_value
                          ? true
                          : false
                      }
                      error={validation.errors.user_function_value}
                      touched={validation.touched.user_function_value}
                    />

                    <div className="color_palette_div">
                      <Input
                        id="color_code"
                        name="color_code"
                        type="color"
                        value={validation.values.color_code || ""}
                        className="color_palette"
                        onChange={validation.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </Col>

              <Col className="col-12">
                <div className="mb-3">
                  <Input
                      id="consultant_fees"
                      name="consultant_fees"
                      className="form-control"
                      label="Consultant fees"
                      required
                      placeholder={t("Consultant fees")}
                      type="text"
                      value={validation.values.consultant_fees || ""}
                      onKeyUp={validation.handleChange}
                      onChange={validation.handleChange}
                      invalid={
                        validation.touched.consultant_fees &&
                        validation.errors.consultant_fees
                          ? true
                          : false
                      }
                      error={validation.errors.consultant_fees}
                      touched={validation.touched.consultant_fees}
                    />
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              className="btn-block"
              type="submit"
              disabled={props.loading}
            >
              {props.loading ? (
                <span>
                  <i className="fa-solid fa-spinner fa-spin"></i>
                  <span>&nbsp; {t("Please wait...")}</span>
                </span>
              ) : (
                <span>{t("Submit")}</span>
              )}
            </Button>
            <Button
              color="danger"
              className="btn-block"
              type="reset"
              onClick={props.onCloseClick}
              disabled={props.loading}
            >
              {t("Cancel")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default UserFunctionForm;
