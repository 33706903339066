import React, { FC, useEffect } from 'react';
import i18n from "i18next";
import { Col, Container, Row, Table } from 'reactstrap';
import logo from "../../assets/images/tc-logo.png";
import AlliottLogo from "../../assets/images/alliott-logo.jpg";
import { dateFormat, formatCurrency } from '../../utils/formats';
import './style.scss';
import { renderRowHTML } from '../../utils/utils';
import { useTranslation } from 'react-i18next';
import { languageCodes } from '../../common/staticdata';
import moment from 'moment';

const ViewInvoice: FC<InvoiceType> = ({ id = 0, invoice_number = 22030005, sub_total, total, paid_amount, credit_note_amount, tax_rate, tax_amount, amount_due, expenses, company, company_details, invoice_date, reference, term, details, content, invoice_type, company_id, contact_person_details, status, cr_dispute = null, cn_invoice = null, created_by_user, is_preview = false, is_hourly_rate = 0, timesheets, tasks, po_number, invoice_history, bank_info }) => {

    const { t, i18n } = useTranslation();
    const changeLanguageHandler = (lang: string) => {
        // alert(i18n.language)
        // alert(lang)
        // i18n.changeLanguage(lang)
    }
    const invoiceDetails = invoice_type === 'CREDIT_MEMO' ? cn_invoice?.details : details;
    const companyDetails = company_details ? company_details : company;

    function unescape(s: string) {
        return s.replace(/&amp;/g, "&")
            .replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">")
            .replace(/&#39;/g, "'")
            .replace(/&quot;/g, '"');
    }

    useEffect(() => {
        changeLanguageHandler('fr')
        console.log("contact_person_details", contact_person_details);
    }, [contact_person_details.language])

    return (
        <Container>
            <div className='invoicePage'>
                <Header />
                <div className='invoiceBody'>
                    <Row>
                        <Col>
                            {company_id === 0 ? (
                                <address>
                                    {`${contact_person_details?.last_name?.toUpperCase()} ${contact_person_details?.first_name}`},<br />
                                    {contact_person_details?.address || ''}<br />
                                    {`${contact_person_details?.postal_code || ''} ${contact_person_details?.city || ''}`}<br />
                                </address>
                            ) : (
                                <address>
                                    <span className='bold'>{companyDetails?.company_name}</span>,<br />
                                    {companyDetails?.company_address}<br />
                                    {`${companyDetails?.postal_code} ${companyDetails?.city}`}<br />
                                </address>
                            )}
                        </Col>
                        <Col className='right-align'>
                            {po_number ? <span className='bold font-size-14'>{t("PO") + ": " + po_number}</span> : ""}
                            {status !== 'Draft' && <Col><p className='mt-3'>Bruxelles, le {dateFormat(invoice_date, { dateStyle: 'long' }, languageCodes(i18n.language))}</p></Col>}
                        </Col>
                    </Row>
                    <Row>
                        <Col>{t("VAT")}: {company_id === 0 ? contact_person_details?.TVW_Nr : companyDetails?.TVW_Nr} <br /><br /></Col>
                    </Row>
                    <Row>
                        <Col>
                            {content ? renderRowHTML(unescape(content), '') :
                                <>
                                    {t("Dear")} {t(contact_person_details?.honorific)}
                                    <br /><br />
                                    {t("We have the pleasure to provide you here below with our invoice for services rendered up to day.")}
                                </>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className='invoiceHeading1 sm'>
                                <span>{invoice_type === 'CREDIT_MEMO' ? t('Credit note') : t('Invoice')} n° </span>  <span>{invoice_number}</span>
                            </div>
                        </Col>
                    </Row>
                    {invoice_type === 'CREDIT_MEMO' ? (credit_note_amount !== null && credit_note_amount !== undefined) &&
                        <Row>
                            <Col>
                                <table style={{ width: '60%', margin: 'auto' }} cellPadding={10}>
                                    <tr>
                                        <td>{t("Professional fees")}</td>
                                        <td style={{ textAlign: 'right' }}> - {formatCurrency(credit_note_amount - ((credit_note_amount * tax_rate) / 100))}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ textAlign: 'right' }}></td>
                                    </tr>
                                    <tr>
                                        <td>{("VAT")} ({tax_rate}%)</td>
                                        <td style={{ textAlign: 'right' }}> - {formatCurrency((credit_note_amount * tax_rate) / 100)}</td>
                                    </tr>

                                    <tr>
                                        <td></td>
                                        <td style={{ textAlign: 'right' }}></td>
                                    </tr>

                                    <tr>
                                        <td></td>
                                        <td style={{ textAlign: 'right' }}></td>
                                    </tr>
                                    <tr style={{ backgroundColor: '#f4f4f4', fontWeight: 'bold' }}>
                                        <td>{t("Total")}</td>
                                        <td style={{ textAlign: 'right' }}> - {formatCurrency(credit_note_amount)}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ textAlign: 'right' }}></td>
                                    </tr>
                                    <tr>
                                        <td>{t("Reference")} </td>
                                        <td style={{ textAlign: 'right' }}>{reference}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ textAlign: 'right' }}></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ textAlign: 'right' }}></td>
                                    </tr>
                                </table>
                            </Col>
                        </Row>
                        :
                        <Row>
                            <Col>
                                <table style={{ width: '60%', margin: 'auto' }} cellPadding={10}>
                                    <tr>
                                        <td>{t("Services rendered")}</td>
                                        <td style={{ textAlign: 'right' }}>{formatCurrency(sub_total)}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("Expenses")}</td>
                                        <td style={{ textAlign: 'right' }}>{formatCurrency(expenses)}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ textAlign: 'right' }}></td>
                                    </tr>
                                    <tr>
                                        <td>{t("Subtotal")}</td>
                                        <td style={{ textAlign: 'right' }}>{formatCurrency(total)}</td>
                                    </tr>

                                    <tr>
                                        <td>{t("VAT")} ({tax_rate}%)</td>
                                        <td style={{ textAlign: 'right' }}>{formatCurrency(tax_amount)}</td>
                                    </tr>

                                    {paid_amount ?
                                        <>
                                            <tr style={{ fontWeight: 'bold' }}>
                                                <td>{t("Total")}</td>
                                                <td style={{ textAlign: 'right' }}>{formatCurrency(total + tax_amount)}</td>
                                            </tr>
                                            <tr>
                                                <td>{t("Paid amount")}</td>
                                                <td style={{ textAlign: 'right' }}> - {formatCurrency(paid_amount)}</td>
                                            </tr>

                                        </> : null
                                    }
                                    {credit_note_amount ?
                                        <>
                                            <tr style={{ fontWeight: 'bold' }}>
                                                <td>{t("Total")}</td>
                                                <td style={{ textAlign: 'right' }}>{formatCurrency(total + tax_amount)}</td>
                                            </tr>
                                            <tr>
                                                <td>{t("Credit note amount")}</td>
                                                <td style={{ textAlign: 'right' }}>- {formatCurrency(credit_note_amount)}</td>
                                            </tr>
                                        </> : null
                                    }
                                    <tr>
                                        <td></td>
                                        <td style={{ textAlign: 'right' }}></td>
                                    </tr>
                                    <tr style={{ backgroundColor: '#f4f4f4', fontWeight: 'bold' }}>
                                        <td>{t("Total due")}</td>
                                        <td style={{ textAlign: 'right' }}>{formatCurrency(amount_due)}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td style={{ textAlign: 'right' }}></td>
                                    </tr>
                                    <tr>
                                        <td>{t("Reference")} </td>
                                        <td style={{ textAlign: 'right' }}>{reference}</td>
                                    </tr>
                                    <tr>
                                        <td>{t("Bank account")}</td>
                                        <td style={{ textAlign: 'right' }}>ING BE29 3631 9421 5864</td>
                                    </tr>
                                    <tr>
                                        <td>{t("BIC")}</td>
                                        <td style={{ textAlign: 'right' }}>BBRU BEBB</td>
                                    </tr>
                                </table>
                            </Col>
                        </Row>
                    }
                    {company_id === 0 ? (
                        contact_person_details?.reason_for_vat === '2' ?
                            <Row>
                                <Col>
                                    <br />
                                    <p className='note'>
                                        {t("Reverse Charge Mechanism / Services located at the place where the client is established according to Article 44 EU VAT Directive 2006/112/CE and Article 21 Belgian VAT code")}
                                    </p>
                                </Col>
                            </Row>
                            : null
                    ) : (
                        companyDetails?.reason_for_vat === '2' ?
                            <Row>
                                <Col>
                                    <br />
                                    <p className='note'>
                                        {t("Reverse Charge Mechanism / Services located at the place where the client is established according to Article 44 EU VAT Directive 2006/112/CE and Article 21 Belgian VAT code")}
                                    </p>
                                </Col>
                            </Row>
                            : null
                    )
                    }
                    {company_id === 0 ? (
                        contact_person_details?.reason_for_vat === '3' ?
                            <Row>
                                <Col>
                                    <br />
                                    <p className='note'>
                                        {t("Services located at the place where the client is established according to Article 44 EU VAT Directive 2006/112/CE and Article 21 Belgian VAT code")}
                                    </p>
                                </Col>
                            </Row>
                            : null
                    ) : (
                        companyDetails?.reason_for_vat === '3' ?
                            <Row>
                                <Col>
                                    <br />
                                    <p className='note'>
                                        {t("Services located at the place where the client is established according to Article 44 EU VAT Directive 2006/112/CE and Article 21 Belgian VAT code")}
                                    </p>
                                </Col>
                            </Row>
                            : null
                    )
                    }
                    {invoice_type === 'INVOICE' ?
                        <Row>
                            <Col>
                                <br />
                                <p >{t("We thank you for your trust.  Our invoice are payable within the  days", { term: term })}.</p>
                                <p>{t("After days an interest rate of 1% per month started is automatically due.", { term: term })}</p>
                            </Col>
                        </Row> : null
                    }
                    <Row>
                        <Col>
                            <br /><br />
                            <p >{t("Yours very truly")},</p>
                        </Col>
                    </Row>

                    <Row>
                        <Col className='col-10'>
                            <br /><br /><br />
                            {/* <p>Alexandre DE MUNCK</p>
                            <p>Partner</p> */}
                        </Col>
                        <Col className='col-2'>
                            <br /><br /><br />
                            <p><br /></p>
                            <p></p>
                        </Col>
                    </Row>

                </div>
                <Footer bank_info={bank_info} />
            </div>
            <div className='invoicePage rows-print-as-pages'>
                <Header />
                <div className='invoiceBody'>
                    <Row>
                        <Col>
                            <div className='invoiceHeading1'>
                                <span>{t("Details of the note of charges")} </span>  <span>{invoice_number}</span>
                            </div>
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col><p>{t('Détails de la note de crédit / Details of the credit slip n° 22920053')}</p></Col>
                    </Row> */}
                    {invoice_type === 'INVOICE' ?
                        <Row>
                            <Col>{companyDetails ? companyDetails?.company_name : contact_person_details?.last_name + ' ' + contact_person_details?.first_name}</Col>
                            {/* <Col className='rightAlign'>Total excl. VAT</Col> */}

                            <br />
                            <br />
                        </Row>
                        :
                        <Row>
                            {!is_preview &&
                                <Col>
                                    <Table>
                                        <thead className='head-light'>
                                            <tr>
                                                <th><span className='font-size-14'>{t("Description")}</span></th>
                                                <th className='right-align'>{t("Total")} HTVA / Excl. total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span className='font-size-15 bold'>HR : {contact_person_details?.last_name?.toUpperCase() + ' ' + contact_person_details?.first_name}</span>

                                                    <br />
                                                    <i>{cn_invoice ?
                                                        <span className='font-size-12'>{moment(invoice_date).format("DD/MM/YYYY")} Credit note on invoice Nr. {cn_invoice?.invoice_number} of {moment(cn_invoice?.invoice_date).format("DD/MM/YYYY")}</span>
                                                        : null
                                                    }
                                                    </i>
                                                </td>
                                                <td className='right-align'>-{formatCurrency(credit_note_amount)}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            }
                        </Row>
                    }

                    <Row>
                        <Col>
                            {is_hourly_rate === 2 ?
                                <Table cellPadding={5} bordered={false}>
                                    <thead className='font-size-15'>
                                        <th style={{ width: '100px' }}>{t("Date")}</th>
                                        <th style={{ width: '40%' }}>{t("Description")}</th>
                                        <th style={{ width: '100px' }}>{t("Time Spent")}</th>
                                        <th style={{ width: '20%' }}>{t("Responsible Person")}</th>
                                        <th>{t("Collaborator")}</th>
                                        <th className='rightAlign'>{t("Total")} HTVA</th>
                                    </thead>
                                    <tbody>
                                        {invoiceDetails?.length && invoiceDetails?.map((i: ProductType, index: number) => {
                                            let rowTotal = 0;
                                            if (is_hourly_rate === 2) {
                                                rowTotal += !i?.is_hourly ? i.subtotal : 0;
                                            }
                                            return (<React.Fragment key={index}>
                                                {i.timesheet_id ?
                                                    <>
                                                        <tr>
                                                            {/* <td style={{ width: '100px' }}>{i.timesheet_code}</td> */}
                                                            <td colSpan={3} className='bold'>{renderRowHTML(i.timesheet_name, '')} </td>
                                                            <td>{!i?.is_hourly ? i?.responsible_person : null}</td>
                                                            <td></td>
                                                            <td className='rightAlign'>{!i?.is_hourly ? formatCurrency(i.subtotal) : ''}</td>
                                                        </tr>
                                                        {i?.description ?
                                                            <tr>
                                                                <td colSpan={6}>{renderRowHTML(i?.description, '')}<br /></td>
                                                            </tr> : null
                                                        }
                                                    </>
                                                    : null
                                                }
                                                {(i?.timesheets?.length > 0 && (i?.is_hourly)) ?
                                                    <>
                                                        {i?.timesheets?.map((ts: any) => {
                                                            const index = timesheets?.findIndex((a: any) => a.id === ts.id);
                                                            if (index > -1) {
                                                                rowTotal += ts.service_charge;
                                                            }
                                                            return (index > -1 && <tr key={ts.id}>
                                                                <td>{moment(ts?.start_date).format('DD/MM/YYYY')}</td>
                                                                <td>{renderRowHTML(ts?.title, '')} </td>
                                                                <td>{`${ts?.time_spend}`} </td>
                                                                <td>{ts?.responsible_person}</td>
                                                                <td>{ts?.collaborator}</td>
                                                                <td className='rightAlign'>
                                                                    {formatCurrency(ts.service_charge)}
                                                                </td>
                                                            </tr>
                                                            )
                                                        })}
                                                        {/* <tr>
                                                            <td colSpan={4}>
                                                                <div style={{ marginRight: '-8px' }} className='border border-1 border-secondary rounded rounded-3 col-8 ms-auto d-flex justify-content-between p-2'>
                                                                    <div className=''>{t("Group sub total")}</div>
                                                                    <div className='rightAlign'>{formatCurrency(rowTotal)}</div>
                                                                </div>
                                                            </td>
                                                        </tr> */}
                                                    </> : null
                                                }
                                                {rowTotal > 0 &&
                                                    <tr>
                                                        <td colSpan={6}>
                                                            <div style={{ marginRight: '-8px' }} className='border border-1 border-secondary rounded rounded-3 col-8 ms-auto d-flex justify-content-between p-2'>
                                                                <div className=''>{t("Group sub total")}</div>
                                                                <div className='rightAlign'>{formatCurrency(rowTotal)}</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                            </React.Fragment>)
                                        })}
                                    </tbody>
                                </Table>
                                :
                                <Table cellPadding={5} bordered={false}>
                                    <thead className='font-size-15'>
                                        <th><span>{t("Description")}</span></th>
                                        <th>{t("Responsible Person")}</th>
                                        <th className='rightAlign'>{t("Total")} HTVA</th>
                                    </thead>

                                    {invoiceDetails?.length && invoiceDetails?.map((i: ProductType, index: number) => {
                                        let rowTotal = 0;
                                        if (i?.sub_tasks && JSON.parse(unescape(i.sub_tasks))?.length) {
                                            rowTotal = 0;
                                        } else {
                                            rowTotal += i.subtotal;
                                        }
                                        return (
                                            <>
                                                {i?.sub_tasks && JSON.parse(unescape(i.sub_tasks))?.length ?
                                                    <React.Fragment key={index} >
                                                        <tr style={{ fontWeight: 'bold' }}>
                                                            {/* <td style={{ width: '100px' }}>{i.timesheet_code}</td> */}
                                                            <td>{renderRowHTML(i.timesheet_name, '')}</td>
                                                            <td>{i?.responsible_person}</td>
                                                            <td className='rightAlign'></td>
                                                        </tr>
                                                        {i?.description ?
                                                            <tr>
                                                                <td>{renderRowHTML(i?.description, '')}<br /><br /></td>
                                                                <td></td>
                                                                <td style={{ width: '200px' }}></td>
                                                            </tr> : null
                                                        }
                                                        {i?.sub_tasks ?
                                                            JSON.parse(unescape(i.sub_tasks))?.map((sb: any) => {
                                                                rowTotal += i.price;
                                                                return (<tr>
                                                                    {/* <td></td> */}
                                                                    <td>{renderRowHTML(sb?.sub_task_title, '')}</td>
                                                                    <td>{sb?.responsible_person_details ? renderRowHTML(sb?.responsible_person_details?.first_name + ' ' + sb?.responsible_person_details?.last_name, '') : ""}</td>
                                                                    <td className='rightAlign'>
                                                                        {cr_dispute && cr_dispute?.deleted_items?.includes(sb.id) ? '- ' : ''}
                                                                        {formatCurrency(i.price)}
                                                                    </td>
                                                                </tr>
                                                                )
                                                            }) : null
                                                        }

                                                        {/* <tr> */}
                                                        {/* <td></td> */}
                                                        {/* <td>Total<br /><br /></td>
                                                    <td className='rightAlign'>{formatCurrency(rowTotal)}</td>
                                                </tr> */}
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment key={index} >
                                                        {cr_dispute ? cr_dispute && cr_dispute?.deleted_items?.filter((x: ItemType) => x.qty).map((a: ItemType) => a.id).includes(i.id) && <>
                                                            <tr>
                                                                {/* <td style={{ width: '100px' }}>{i.timesheet_code}</td> */}
                                                                <td>{renderRowHTML(i.timesheet_name, '')} - {t('Quantity')}: {cr_dispute?.deleted_items?.find((x: ItemType) => x.id === i.id)?.qty}</td>
                                                                <td>{i?.responsible_person}</td>
                                                                <td className='rightAlign'>{cr_dispute && cr_dispute?.deleted_items?.includes(i.id) ? '- ' : '-'} {formatCurrency(i.price * cr_dispute?.deleted_items?.find((x: ItemType) => x.id === i.id)?.qty)}</td>
                                                            </tr>
                                                            {i?.description ?
                                                                <tr>
                                                                    <td>{renderRowHTML(i?.description, '')}<br /><br /></td>
                                                                    <td></td>
                                                                    <td style={{ width: '200px' }}></td>
                                                                </tr> : null
                                                            }
                                                        </>
                                                            :
                                                            <>
                                                                {i.timesheet_id ?
                                                                    <>

                                                                        <tr>
                                                                            {/* <td style={{ width: '100px' }}>{i.timesheet_code}</td> */}
                                                                            <td className='bold'>{renderRowHTML(i.timesheet_name, '')} </td>
                                                                            <td>{i?.responsible_person}</td>
                                                                            <td className='rightAlign'>{formatCurrency(i.subtotal)}</td>
                                                                        </tr>
                                                                        {i?.description ?
                                                                            <tr>
                                                                                <td>{renderRowHTML(i?.description, '')}<br /><br /></td>
                                                                                <td></td>
                                                                                <td style={{ width: '200px' }}></td>
                                                                            </tr> : null
                                                                        }
                                                                    </>
                                                                    : null
                                                                }

                                                            </>
                                                        }

                                                    </React.Fragment>
                                                }
                                                {rowTotal > 0 &&
                                                    <tr>
                                                        <td colSpan={3}>
                                                            <div style={{ marginRight: '-8px' }} className='border border-1 border-secondary rounded rounded-3 col-8 ms-auto d-flex justify-content-between p-2 mb-3'>
                                                                <div className=''>{t("Group sub total")}</div>
                                                                <div className='rightAlign'>{formatCurrency(rowTotal)}</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }
                                            </>

                                        )

                                    })}
                                    {/* {invoice_type === 'CREDIT_MEMO' &&
                                    <tr>
                                        <td></td>
                                        <td width={500}>
                                            <Table cellSpacing={10} cellPadding={10}>
                                                <tr className='bordered-box'>
                                                    <td>Sub-total of the group</td>
                                                    <td className='right-align'>-{formatCurrency(credit_note_amount)}</td>
                                                </tr>
                                                <tr className='bordered-box'>
                                                    <td>Sub-total of the project</td>
                                                    <td className='right-align'>-{formatCurrency(credit_note_amount)}</td>
                                                </tr>
                                                <tr className='bordered-box'>
                                                    <td>Sub-total of the company</td>
                                                    <td className='right-align'>-{formatCurrency(credit_note_amount)}</td>
                                                </tr>
                                            </Table>
                                        </td>
                                    </tr>
                                } */}


                                </Table>
                            }
                        </Col>
                    </Row>
                </div>
                <Footer bank_info={bank_info} />
            </div>

            {((tasks && tasks[0]?.task?.invoices?.filter((ii: any) => ii?.invoice?.id < id)?.length > 0) || (invoice_history && invoice_history.filter((ii: any) => ii.id < id)?.length > 0)) &&
                <div className='invoicePage'>
                    <Header />
                    <div className='invoiceBody'>
                        <Row>
                            <Col>
                                <div className='invoiceHeading1'>
                                    <span>{t("Invoiced")} </span>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>{t("Invoice Number")}</th>
                                            <th className='align-center'>{t("Date of invoice")}</th>
                                            <th className='right-align'>{t("Total")} HTVA</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            invoice_history ?
                                                (invoice_history.filter((ii: any) => ii.id < id)?.map((inv: any) => (
                                                    <tr>
                                                        <td>{inv?.invoice_number}</td>
                                                        <td>{moment(inv?.invoice_date).format('DD/MM/YYYY')}</td>
                                                        <td className='right-align'>{formatCurrency(inv?.sub_total + inv?.expenses)}</td>
                                                    </tr>
                                                )))
                                                :
                                                (tasks && tasks?.map((t: any) => (
                                                    t?.task?.invoices?.filter((ii: any) => ii?.invoice?.id < id)?.map((inv: any) => (
                                                        <tr>
                                                            <td>{inv?.invoice?.invoice_number}</td>
                                                            <td>{moment(inv?.invoice?.invoice_date).format('DD/MM/YYYY')}</td>
                                                            <td className='right-align'>{formatCurrency(inv?.invoice?.sub_total + inv?.invoice?.expenses)}</td>
                                                        </tr>
                                                    ))
                                                )))
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </div>
                    <Footer bank_info={bank_info} />
                </div>
            }
        </Container >
    )

}

const Header = () => {
    return (
        <Row>
            <Col><img src={logo} alt='logo' width='120px' /></Col>
            <Col><h1 style={{ color: '#c8222a', fontSize: '28px', textAlign: 'right' }}>HR Tax & IM</h1></Col>
        </Row>
    )
}

type FooterProps = {
    bank_info: any;
}
const Footer: FC<FooterProps> = ({ bank_info }) => {
    const { t } = useTranslation();
    return (
        <Row className='invoiceFooter'>
            <Col className='col-12 strong'><p>SRL Tax Consult HR Tax & International Mobility BV</p></Col>
            <Col className='col-9'>
                <p>{bank_info?.company_address} {bank_info?.office_number}<br />{bank_info?.postal_code} {bank_info?.region}</p>
            </Col>
            <Col className='col-3 tel-no'>{t('Tel.')} {bank_info?.phone_number_1}</Col>
            <Col className='col-9'>
                <p>{t('VAT')} {bank_info?.TVW_Nr}
                    <br /><br />Member of Alliott Group, a Worldwide Alliance of Independent Accounting, Law and Consulting Firms
                </p>
            </Col>
            <Col className='col-3'><img src={AlliottLogo} alt='logo' width='200px' /></Col>
        </Row>

    )
}

export default ViewInvoice;