import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    Form,
    Label,
    Button,
    FormFeedback,
    Alert,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import { useFormik } from "formik";
import * as Yup from "yup";
import commonService from "../../../services/common.service";
import { useDispatch, useSelector } from "react-redux";
import { CommonAction } from "../../../store/actions";
import { useTranslation } from "react-i18next";
import Input from "../../../components/Form/Input";
import Select from "../../../components/Form/Select/Select";

type AccountType = {
    id?: number | null;
    account_id?: string | null;
    account_name: string | null;
    account_code: string | null;
    partners: any[];
};


const AccountForm = (props: any) => {
    const { t } = useTranslation();

    let vatData = props.vatData;
    const dispatch = useDispatch();

    const { getUserOptionsList } = useSelector((state: any) => ({ getUserOptionsList: state.Common.GET_USER_OPTIONS_LIST_SUCCESS }))

    const [intialData, setIntialData] = useState<AccountType>({
        account_name: null,
        account_code: null,
        partners: [],
    });

    const [countries, setCountries] = useState([]);
    const [partnersOptions, setPartnerOptions] = useState<Array<any>>([]);
    const [query, setQuery] = useState("");

    const validationSchema = Yup.object().shape({
        account_name: Yup.string().nullable().required("Account name field is required."),
        account_code: Yup.string().nullable().required("Account code field is required."),
        partners: Yup.array().min(1).required("Please select partner."),
    });


    const handleChange = (fieldName: string, fieldValue: any) => {
        setIntialData({ ...intialData, [fieldName]: fieldValue });
    };

    const handleSubmit = (values: AccountType, actions: any) => {
        props.onSubmitClick(values, actions);
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: intialData,
        validationSchema: validationSchema,
        onSubmit: (values, actions) => {
            handleSubmit(values, actions);
        },
    });

    const getSelectOptions = async (tableName: string, fieldName: string, otherField?: Array<string>) => {
        const response = await commonService.getSelectOptionsList(
            tableName,
            fieldName,
            query ? query : "",
            otherField
        );
        return response.data.data;
    };

    useEffect(() => {
        // getSelectOptions("tbl_master_users", "first_name").then(function (
        //     options
        // ) {
        //     setCountries(options);
        // });
        if (!getUserOptionsList) dispatch(CommonAction.getUserOptionsList());
    }, []);

    useEffect(() => {
        setIntialData(vatData);
    }, [vatData])

    useEffect(() => {
        setPartnerOptions(getUserOptionsList?.data?.filter((i: any) => i.function === 6))
    }, [getUserOptionsList])

    useEffect(() => {
        if (!validation.isSubmitting) return;
        if (Object.keys(validation.errors).length > 0) {
            document
                .getElementById(Object.keys(validation.errors)[0])
                ?.scrollIntoView({ behavior: "smooth", block: "center" });
            document
                .getElementById(Object.keys(validation.errors)[0])
                ?.focus({ preventScroll: true });
        }
    }, [validation.isSubmitting, validation.errors]);



    return (
        <React.Fragment>
            <Modal
                size="md"
                isOpen={props.show}
                toggle={props.onCloseClick}
                backdrop="static"
                keyboard={false}
                autoFocus={false}
            >
                <div className="modal-header">
                    <h5 className="modal-title">{props.modalTitle}</h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={props.onCloseClick}
                        aria-label="Close"
                    ></button>
                </div>

                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                    autoComplete="off"
                >
                    <ModalBody>
                        {props.errorMsg ? (
                            <Alert color="danger">
                                {props.errorMsg}

                                {props.errorData.data
                                    ? Object.keys(props.errorData.data).map((key) => (
                                        <li color="danger" key={key}>
                                            {props.errorData.data[key]}
                                        </li>
                                    ))
                                    : null}
                            </Alert>
                        ) : null}
                        <Input
                            id="id"
                            name="id"
                            type="hidden"
                            value={validation.values.id || ""}
                            onChange={validation.handleChange}
                        />
                        <Input
                            id="account_id"
                            name="account_id"
                            type="hidden"
                            value={validation.values.account_id || ""}
                            onChange={validation.handleChange}
                        />

                        <Row>
                            <Col className="col-12">
                                <div className="mb-3">
                                    <Input
                                        id="account_name"
                                        name="account_name"
                                        className="form-control"
                                        label="Account name"
                                        placeholder={t("Example") + ": HR"}
                                        required
                                        type="text"
                                        value={validation.values.account_name || ""}
                                        onBlur={validation.handleBlur}
                                        onChange={(e) =>
                                            handleChange("account_name", e.target.value)
                                        }
                                        invalid={
                                            validation.touched.account_name &&
                                                validation.errors.account_name
                                                ? true
                                                : false
                                        }
                                        touched={validation.touched.account_name}
                                        error={validation.errors.account_name}
                                    />
                                </div>
                            </Col>
                            <Col className="col-12">
                                <div className="mb-3">
                                    <Input
                                        id="account_code"
                                        name="account_code"
                                        className="form-control"
                                        label="Account code"
                                        placeholder={t("Example") + ": 700100"}
                                        type="text"
                                        value={validation.values.account_code || ""}
                                        onBlur={validation.handleBlur}
                                        onChange={(e) =>
                                            handleChange("account_code", e.target.value)
                                        }
                                        invalid={
                                            validation.touched.account_code &&
                                                validation.errors.account_code
                                                ? true
                                                : false
                                        }
                                        touched={validation.touched.account_code}
                                        error={validation.errors.account_code}
                                    />
                                </div>
                            </Col>
                            <Col className="col-12">
                                <div className="mb-3">
                                    <Select
                                        id="partners"
                                        name="partners"
                                        label="Partners"
                                        required
                                        className={validation.touched.partners && validation.errors.partners ? "is-invalid" : ""}
                                        options={partnersOptions}
                                        isMulti={true}
                                        //@ts-ignore
                                        //defaultValue={partnersOptions.find((o: any) => o.value === validation?.values?.partners)}
                                        value={partnersOptions?.filter((o: any) => validation?.values?.partners?.includes(o.value))}
                                        isSearchable={true}
                                        // isDisabled={loadingCompany || isDisabled}
                                        onChange={(option: any) => {
                                            handleChange("partners", option.map((i: any) => i.value));

                                        }}
                                    />
                                    {validation.touched.partners && validation.errors.partners ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.partners}
                                        </FormFeedback>
                                    ) : null}
                                </div>
                            </Col>

                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            className="btn-block"
                            type="submit"
                            disabled={validation.isSubmitting}
                        >
                            {validation.isSubmitting ? (
                                <span>
                                    <i className="fa-solid fa-spinner fa-spin"></i>
                                    <span>&nbsp; {t("Please wait...")}</span>
                                </span>
                            ) : (
                                <span>{t("Submit")}</span>
                            )}
                        </Button>
                        <Button
                            color="danger"
                            className="btn-block"
                            type="reset"
                            onClick={props.onCloseClick}
                            disabled={props.loading}
                        >
                            {t("Cancel")}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </React.Fragment>
    );
};

export default AccountForm;
