import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  Button,
  FormFeedback,
  Alert,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";

// Import images
import logoDark from "../../assets/images/logos/tc-logo-red-block-with-name.png";
import { getRandomPassword, checkPasswordStrength } from "../../helpers/functions_helper";
import loginService from "../../services/auth.service";

import reCAPTCHA from "../../components/Common/reCAPTCHA";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../../components/Form/ErrorMessage";
const recaptcha = new reCAPTCHA(process.env.REACT_APP_RECAPTCHA_SITE_KEY!, "resetpassword");

const ResetPassword = (props: any) => {
  const { t } = useTranslation();
  document.title = t("Reset password") + " | " + process.env.REACT_APP_NAME;

  const { token } = useParams();
  const { email } = useParams();

  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [redirectLogin, setRedirectLogin] = useState(false);
  const [redirectReset, setRedirectReset] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorData, setErrorData] = useState<any>({ data: {} });
  const [initialData, setInitialData] = useState({
    token: token || "",
    email: email || "",
    password: "",
    password_confirmation: "",
    re_captcha_token: "",
  });

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password field is required.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password must contain 8 characters, one uppercase, one lowercase, one number and one special case character from !, @, #, $, %, ^, & and * (other special charectors are not supported)"
      ),
    password_confirmation: Yup.string()
      .required("Confirm password field is required.")
      .oneOf([Yup.ref("password"), null], "Confirm password must match with password"),
  });

  const passwordAutoGenerate = () => {
    let new_password = getRandomPassword(15);
    let password_strength = checkPasswordStrength(new_password);

    setInitialData((prevData: any) => ({
      ...prevData,
      password: new_password,
      password_confirmation: new_password,
    }));

    navigator.clipboard.writeText(new_password);

    toast(
      "Password successfully generated, It's strength is " +
        password_strength +
        " and copied to clipboard also!",
      {
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
        delay: 500,
      }
    );
  };

  const handleResetPassword = async (values: {
    token: string;
    email: string;
    password: string;
    password_confirmation: string;
    re_captcha_token: any;
  }) => {
    try {
      const res = (await loginService.reset(values)) as any;
      if (res.data && res.data.status === "success") {
        setSuccessMsg(res.data.message);
        setErrorMsg("");
        setErrorData({ data: {} });
        setRedirectLogin(true);

        setTimeout(() => {
          return navigate("/login");
        }, 5000);
      } else if (res.data && res.data.status === "error") {
        setErrorMsg(res.data.message);
        setErrorData({ data: res.data.data });
        setSuccessMsg("");

        if (res.data.status_code && res.data.status_code === "token_expired") {
          setRedirectReset(true);

          setTimeout(() => {
            return navigate("/forgot-password");
          }, 5000);
        } else {
          setLoading(false);
        }
      } else {
        setErrorMsg(res.message);
        setSuccessMsg("");
        setLoading(false);
      }
    } catch (err) {
      setErrorMsg(err as any);
      setSuccessMsg("");
      setLoading(false);
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      let response: any = await recaptcha.getToken();
      if (response[0].status === "success") {
        var newData: any = { ...values, re_captcha_token: response[0].token };
        handleResetPassword(newData);
      } else {
        setLoading(false);
        setErrorMsg(
          t(
            "Could not connect to the reCAPTCHA service. Please check your internet connection and reload to get a reCAPTCHA challenge."
          )
        );
      }
    },
  });

  const handleChange = (fieldName: string, fieldValue: string) => {
    setInitialData({ ...initialData, [fieldName]: fieldValue });
  };

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-white" style={{ borderBottom: "1px solid #c71e26" }}>
                  <Row>
                    <Col className="col-8">
                      <div className="p-4">
                        <h5 style={{ color: "#c71e26" }}>{t("Reset password")} !</h5>
                        <p style={{ color: "#c71e26" }}>{process.env.REACT_APP_NAME}</p>
                      </div>
                    </Col>
                    <Col className="col-4 p-4 align-self-end">
                      <img src={logoDark} className="img-fluid" width={80} />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2 mt-3">
                    {successMsg ? <Alert color="success">{successMsg}</Alert> : null}

                    {errorMsg ? (
                      <Alert color="danger">
                        {errorMsg}

                        {errorData.data
                          ? Object.keys(errorData.data).map((key) => (
                              <li color="danger" key={key}>
                                {errorData.data[key]}
                              </li>
                            ))
                          : null}
                      </Alert>
                    ) : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">
                          {t("New password")} <span className="text-warning is_required">*</span>
                        </Label>
                        <div className="input-group">
                          <Input
                            name="password"
                            className="form-control"
                            placeholder={t("New password")}
                            type={passwordShow ? "text" : "password"}
                            value={initialData.password}
                            onChange={(e) => handleChange("password", e.target.value)}
                            onBlur={validation.handleBlur}
                            disabled={loading}
                            invalid={
                              validation.touched.password && validation.errors.password
                                ? true
                                : false
                            }
                          />
                          <span
                            className="input-group-text btn-outline-primary"
                            style={{
                              border: "1px solid #ced4da",
                              cursor: "pointer",
                            }}
                            title={t("Auto generate password")}
                            onClick={() => passwordAutoGenerate()}
                          >
                            <span className="align-middle material-symbols-outlined fw-light fs-4">
                              enhanced_encryption
                            </span>
                          </span>
                          <span
                            className="input-group-text btn-outline-primary"
                            style={{
                              border: "1px solid #ced4da",
                              cursor: "pointer",
                            }}
                            title={t("Show / Hide password")}
                            onClick={() => setPasswordShow(!passwordShow)}
                          >
                            {!passwordShow ? (
                              <span className="align-middle material-symbols-outlined fw-light fs-4">
                                visibility
                              </span>
                            ) : (
                              <span className="align-middle material-symbols-outlined fw-light fs-4">
                                visibility_off
                              </span>
                            )}
                          </span>
                        </div>
                        <ErrorMessage error={validation.errors.password} touched={validation.touched.password} label={"New password"} />
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">
                          {t("Confirm password")}{" "}
                          <span className="text-warning is_required">*</span>
                        </Label>
                        <Input
                          name="password_confirmation"
                          className="form-control"
                          placeholder={t("Re-type password")}
                          type="password"
                          value={initialData.password_confirmation}
                          onChange={(e) => handleChange("password_confirmation", e.target.value)}
                          onBlur={validation.handleBlur}
                          disabled={loading}
                          invalid={
                            validation.touched.password_confirmation &&
                            validation.errors.password_confirmation
                              ? true
                              : false
                          }
                        />
                        <ErrorMessage error={validation.errors.password_confirmation} touched={validation.touched.password_confirmation} label={"Confirm password"} />
                      </div>

                      <div className="mt-3 d-grid">
                        <Button
                          color="primary"
                          className="btn-block"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? (
                            <span>
                              <span className="align-middle material-symbols-outlined fw-light fs-4 me-1 fa-spin">
                                hourglass_empty
                              </span>

                              {loading && <span>{t("Please wait...")}</span>}
                              {redirectLogin && (
                                <span>
                                  <br />
                                  {t("we are redirecting to login page...")}
                                </span>
                              )}
                              {redirectReset && (
                                <span>
                                  <br />
                                  {t("we are redirecting to reset password page...")}
                                </span>
                              )}
                            </span>
                          ) : (
                            <span>
                              <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                lock_open
                              </span>
                              <span>{t("Reset")}</span>
                            </span>
                          )}
                        </Button>
                      </div>
                    </Form>

                    <div className="mt-4 text-center">
                      <Link to="/login" className="text-muted">
                        <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                          login
                        </span>
                        {t("Back to login")}
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-5 text-center">
                <p>
                  {t("Don't have an account ?")}
                  <Link to="/register-user" className="fw-medium text-primary ms-1">
                    {t("Signup Now")}
                  </Link>
                </p>
                <p>
                  © {new Date().getFullYear()}{" "}
                  <strong>Tax Consult HR Tax & International Mobility</strong>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
