import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
const Button = styled.button`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50px;
  height: 35px;
  line-height: 0;
  user-select: none;
  color: white;
  background-color: #564ab1;
  border-radius: 0 3px 3px 0;
  border:1px solid #564ab1;
  z-index:1;
  font-family: arial, sans-serif;
  font-size: 1rem;
  font-weight: 800;
  button svg {
    width: auto;
    height: 50%; }
`;
const UploadContainer = styled.div`
  display: grid;
  grid-gap: 0px;
  position: relative;
  z-index: 1;
`
const Label = styled.label`
    display: flex;
    align-items: center;
    color: setColor(primary, 0.5);
    background: setColor(white);
    transition: .4s ease;
    font-family: arial, sans-serif;
    font-size: .75em;
    font-weight: regular;
`
const Input = styled.input`
  position: relative;
  z-index: 1;
  padding: 0 gap(m);
  width: 100%;
  height: 35px;
  border: 1px solid #564ab1;
  border-radius: 3px;
  font-family: arial, sans-serif;
  font-size: 1rem;
  user-select: none;
  cursor: pointer;
  font-weight: regular; 
   input[type="text"] {
    padding: 0 gap(m); }
     input[type="file"]::-webkit-file-upload-button {
      visibility: hidden;
      margin-left: 10px;
      padding: 0;
      height: 50px;
      width: 0; }
`


const FileUploader = (props: any) => {
  const hiddenFileInput: any = React.useRef(null);
  const { t } = useTranslation();
  const [fileText, setFileText] = useState<string>(t('No file chosen'))
  const handleClick = (event: any) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event: any) => {
    props.handleFile(event);
    if(event.target.files.length>1){
      setFileText(`${event.target?.files[0]?.name} +`);
    }else{
      if(event.target?.files[0]?.name){
        setFileText(event.target?.files[0]?.name)
      }
    }
  };
  return (
    <UploadContainer>
      <label>{t("Upload File")}</label>
      <Input type="text" value={fileText} readOnly />
      <Button onClick={handleClick} type='button'>
        +
      </Button>
      <input
        type="file"
        id="issueFile"
        name="attachment[]"
        multiple
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </UploadContainer>
  );
};
export default FileUploader;