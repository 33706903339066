import { useEffect, useState } from "react";
import { dateFormat } from "../../../utils/formats";
import { Button, CardBody, CardHeader, CardTitle, Col, Label, Row } from "reactstrap";
import Select from "react-select";
import React from "react";
import DataTableBasic from "../../../components/Common/DataTableBasic";
import { getUserProfile, yearsBackword } from "../../../helpers/functions_helper";
import { taxClaimStatusMaster } from "../../../common/staticdata";
import DatePicker from "../../../components/Form/DatePicker/DatePicker";
import { useTranslation } from "react-i18next";

type Type = {
  tax_claim_year: string;
  tax_claim_status: string;
  tax_claim_filing_date: string;
  tax_claim_due_date: string;
};

const TaxClaim = (props: any) => {
  const { t } = useTranslation();
  const userProfile = getUserProfile("all-users");
  const { onChange } = props;

  const [initialData, setInitialData] = useState<Type>({
    tax_claim_year: "",
    tax_claim_status: "",
    tax_claim_filing_date: "",
    tax_claim_due_date: "",
  });

  const columns = [
    {
      dataField: "DT_RowIndex",
      text: t("Sr. No."),
      formatter: (rowContent: any, row: any, idx: number): any => {
        return idx + 1;
      },
    },
    {
      dataField: "tax_claim_year",
      text: t("Income year"),
    },
    {
      dataField: "tax_claim_status",
      text: t("Status"),
    },
    {
      dataField: "tax_claim_filing_date",
      text: t("Filing date"),
      formatter: (rowContent: any, row: any, idx: number): any => {
        return row.tax_claim_filing_date && dateFormat(row.tax_claim_filing_date);
      },
    },
    {
      dataField: "tax_claim_due_date",
      text: t("Due date"),
      formatter: (rowContent: any, row: any, idx: number): any => {
        return row.tax_claim_due_date && dateFormat(row.tax_claim_due_date);
      },
    },
    {
      dataField: "action",
      text: t("Action"),
      formatter: (rowContent: any, row: any, idx: number): any => {
        return (
          <div className="d-flex gap-2">
            <Button
              color="primary"
              outline={true}
              className={`btn btn-pill btn-outline-primary btn-sm`}
              type="button"
              onClick={() => editRow(idx)}
            >
              <span className="align-middle material-symbols-outlined fw-light fs-4">edit</span>
            </Button>

            <Button
              color="danger"
              outline={true}
              className={`btn btn-pill btn-outline-danger btn-sm`}
              type="button"
              onClick={() => removeRow(idx)}
            >
              <span className="align-middle material-symbols-outlined fw-light fs-4">delete</span>
            </Button>
          </div>
        );
      },
    },
  ];

  const [isEdit, setIsEdit] = useState(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [addRowBtnDisabled, setAddRowBtnDisabled] = useState(true);
  const [data, setData] = useState<any>([]);
  const [dataList, setDataList] = useState<any>([]);
  const [datatableOptions, setDatatableOptions] = useState({
    draw: 1,
    page: 1,
    recordsTotal: 0,
    recordsFiltered: 0,
    sizePerPage: 5,
    searchText: "",
    sortField: "id",
    sortOrder: "desc",
  });

  const onTableChange = (type: any, newState: any) => {
    let draw = datatableOptions.draw + 1;
    let page = 1;
    if (type === "search") {
      page = 1;
    } else {
      page = newState.page;
    }
    setDatatableOptions((prevData: any) => ({
      ...prevData,
      draw: draw,
      page: page,
      sizePerPage: newState.sizePerPage,
      searchText: newState.searchText,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
    }));

    const slicedArray = data.slice(newState.sizePerPage * (page - 1), newState.sizePerPage * page);
    setDataList(slicedArray);
  };

  const addRow = () => {
    setData((preState: any) => [
      ...preState,
      {
        tax_claim_year: initialData?.tax_claim_year,
        tax_claim_status: initialData?.tax_claim_status,
        tax_claim_filing_date: initialData?.tax_claim_filing_date,
        tax_claim_due_date: initialData?.tax_claim_due_date,
      },
    ]);

    clearForm();
    setAddRowBtnDisabled(true);
  };

  const updateRow = () => {
    if (editIndex !== null) {
      var rows: any = [...data];
      rows.map((item: any, index: number) => {
        if (index === editIndex) {
          rows[index] = {
            ...item,
            tax_claim_year: initialData?.tax_claim_year,
            tax_claim_status: initialData?.tax_claim_status,
            tax_claim_filing_date: initialData?.tax_claim_filing_date,
            tax_claim_due_date: initialData?.tax_claim_due_date,
          };
        }
      });
      setData(rows);

      clearForm();
      setIsEdit(false);
      setEditIndex(null);
    }
  };

  const removeRow = (idx: number) => {
    const rows = data.filter((item: any, index: number) => index !== idx);
    setData(rows);

    setIsEdit(false);
    setEditIndex(null);
  };

  const editRow = (idx: number) => {
    const row = data.find((item: any, index: number) => index === idx);
    setInitialData((preData: any) => ({
      ...preData,
      tax_claim_year: row?.tax_claim_year,
      tax_claim_status: row?.tax_claim_status,
      tax_claim_filing_date: row?.tax_claim_filing_date,
      tax_claim_due_date: row?.tax_claim_due_date,
    }));

    setIsEdit(true);
    setEditIndex(idx);
  };

  const clearForm = () => {
    setInitialData((preData: any) => ({
      ...preData,
      tax_claim_year: "",
      tax_claim_status: "",
      tax_claim_filing_date: "",
      tax_claim_due_date: "",
    }));
  };

  const handleChange = (fieldName: string, fieldValue: any) => {
    setInitialData((preState) => ({ ...preState, [fieldName]: fieldValue }));
  };

  useEffect(() => {
    if (
      initialData.tax_claim_year !== "" &&
      initialData.tax_claim_status !== "" &&
      initialData.tax_claim_filing_date !== "" &&
      initialData.tax_claim_due_date !== ""
    ) {
      setAddRowBtnDisabled(false);
    } else {
      setAddRowBtnDisabled(true);
    }
  }, [
    initialData.tax_claim_year,
    initialData.tax_claim_status,
    initialData.tax_claim_filing_date,
    initialData.tax_claim_due_date,
  ]);

  useEffect(() => {
    setData(props?.data);
  }, [props?.data]);

  useEffect(() => {
    setDatatableOptions((prevData: any) => ({
      ...prevData,
      draw: 1,
      page: 1,
      sizePerPage: 5,
      recordsTotal: data.length,
      recordsFiltered: data.length,
    }));

    const slicedArray = data.slice(0, 5);
    setDataList(slicedArray);

    onChange(data);
  }, [data]);

  return (
    <React.Fragment>
      <Row>
        <Col xl={2} lg={2} md={2} sm={6}>
          <div className="mb-3">
            <Label className="form-label">
              {t("Income year")} <span className="text-warning is_required">*</span>
            </Label>
            <Select
              id="tax_claim_year"
              name="tax_claim_year"
              className="is-invalid"
              placeholder={t("Select...")}
              options={yearsBackword()}
              value={
                initialData.tax_claim_year
                  ? yearsBackword().find(
                      (option: any) => option.value == initialData.tax_claim_year
                    )
                  : ""
              }
              isSearchable={true}
              isDisabled={!userProfile.IS_INTERNAL}
              onChange={(e: any) => {
                handleChange("tax_claim_year", e.value);
              }}
            />
          </div>
        </Col>

        <Col xl={2} lg={2} md={2} sm={6}>
          <div className="mb-3">
            <Label className="form-label">
              {t("Status")} <span className="text-warning is_required">*</span>
            </Label>
            <Select
              id="tax_claim_status"
              name="tax_claim_status"
              className="is-invalid"
              placeholder={t("Select...")}
              options={taxClaimStatusMaster}
              value={
                initialData.tax_claim_status
                  ? taxClaimStatusMaster.find(
                      (option: any) => option.value == initialData.tax_claim_status
                    )
                  : ""
              }
              isSearchable={true}
              isDisabled={!userProfile.IS_INTERNAL}
              onChange={(e: any) => {
                handleChange("tax_claim_status", e.value);
              }}
            />
          </div>
        </Col>

        <Col xl={2} lg={2} md={2} sm={12}>
          <div className="mb-3">
            <DatePicker
              id="tax_claim_filing_date"
              name="tax_claim_filing_date"
              label={t("Filing date")}
              required={true}
              value={initialData.tax_claim_filing_date}
              disabled={!userProfile.IS_INTERNAL}
              options={{
                altInput: true,
                altFormat: "d/m/Y",
                dateFormat: "Y-m-d H:i:S",
                allowInput: true,
                onChange: function (selectedDates, dateStr, instance) {
                  handleChange("tax_claim_filing_date", dateStr);
                },
                onClose: function (selectedDates, dateStr, instance) {
                  handleChange("tax_claim_filing_date", dateStr);
                },
                locale: { firstDayOfWeek: 1 },
              }}
            />
          </div>
        </Col>

        <Col xl={2} lg={2} md={2} sm={12}>
          <div className="mb-3">
            <DatePicker
              id="tax_claim_due_date"
              name="tax_claim_due_date"
              label={t("Due date")}
              required={true}
              value={initialData.tax_claim_due_date}
              disabled={!userProfile.IS_INTERNAL}
              options={{
                altInput: true,
                altFormat: "d/m/Y",
                dateFormat: "Y-m-d H:i:S",
                allowInput: true,
                onChange: function (selectedDates, dateStr, instance) {
                  handleChange("tax_claim_due_date", dateStr);
                },
                onClose: function (selectedDates, dateStr, instance) {
                  handleChange("tax_claim_due_date", dateStr);
                },
                locale: { firstDayOfWeek: 1 },
              }}
            />
          </div>
        </Col>

        {userProfile.IS_INTERNAL && (
          <Col xl={1} lg={1} md={1} sm={6}>
            <div className="mb-3">
              <Label className="form-label">
                <br />
              </Label>
              <div className="input-group">
                {isEdit === true ? (
                  <Button
                    color="success"
                    type="button"
                    className={`btn btn-sm btn-success`}
                    disabled={addRowBtnDisabled}
                    onClick={() => updateRow()}
                  >
                    <span className="align-middle material-symbols-outlined fw-light fs-4">
                      done
                    </span>
                  </Button>
                ) : (
                  <Button
                    type="button"
                    className={`btn btn-sm ${addRowBtnDisabled ? "btn-primary" : "btn-info"}`}
                    disabled={addRowBtnDisabled}
                    onClick={() => addRow()}
                  >
                    <span className="align-middle material-symbols-outlined fw-light fs-4">
                      add
                    </span>
                  </Button>
                )}
              </div>
            </div>
          </Col>
        )}
      </Row>

      {dataList && dataList.length > 0 && (
        <DataTableBasic
          columns={columns}
          products={dataList}
          datatableOptions={datatableOptions}
          onTableChange={onTableChange}
        />
      )}
    </React.Fragment>
  );
};

export default TaxClaim;
