import React, { useState, useEffect, useRef, useCallback } from "react";
import { Row, Col, Badge } from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
  CSVExport,
  ColumnToggle,
} from "react-bootstrap-table2-toolkit";

import "../../assets/scss/datatables.scss";
import exportToExcel from "./ExportToExcel";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import {
  filterOptionsSetHidden,
  filterOptionsRemoveHidden,
  handleFilters,
  getUserProfile,
} from "../../helpers/functions_helper";
import DataTableSettingsModal from "../../components/Common/DataTableSettingsModal";
import datatableSettingsService from "../../services/datatable.settings.service";
import DataTableSearchBar from "./DataTableSearchBar";
import DataTableOrderSettingsModal from "./DataTableOrderSettingsModal";
import NoDataFound from "../NoDataFound";
import { useDispatch, useSelector } from "react-redux";

//Mail Action
import { setDataTableID } from "../../store/mails/actions";


const DataTable = (props: any) => {
  const { t } = useTranslation();
  const {
    columns,
    products,
    loadingListData,
    onTableChange,
    datatableOptions,
    filters,
    clearFilter,
    rowStyle,
    striped = true,
    bordered = true,
    mainButtons,
    customButtons,
    exportedFileName = "export",
    settingTableName = "",
    dependentField = "",
    setOrderChange,
    slug = '',
    inputFocus = true,
    hideToolbar = false
  } = props;
  const { SearchBar } = Search;
  const { ToggleList } = ColumnToggle;
  const { ExportCSVButton } = CSVExport;

  const uid = useRef(new Date().valueOf());
  const userObj = getUserProfile(slug);

  const formatCols = useCallback((clms: Array<any>) => clms.map((col: any, i: any) => ({
    ...col,
    id: col.id ? col.id : i,
    text: <span className="draggable-header" draggable id={col.dataField}>
      <span className="align-middle material-symbols-outlined fw-light fs-4">
        drag_indicator
      </span> {col.text}
    </span>
  })), []);


  const [loading, setLoading] = useState<boolean>(false);
  const [noDataIndication, setNoDataIndication] = useState("LOADING");
  const [dbsModal, setDBSModal] = useState(false);
  const [dbsOrderModal, setDBSOrderModal] = useState(false);
  const [datatableColumns, setDatatableColumns] = useState<any>(columns);
  const [hiddenColumns, setHiddenColumns] = useState<Array<number>>([]);
  const [sortColumns, setSortColumns] = useState<Array<number>>([]);
  const [columnChange, setColumnChange] = useState(false);
  const [orderIdx, setOrderIdx] = useState({ src: 4, dest: 5 })
  const dispatch = useDispatch();

  function printData(tableID: any) {
    var divToPrint = document.getElementById(tableID) as any;
    let newWin = window.open("") as any;
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }

  function copyToClipboard(el: any) {
    {
      const urlField = document.getElementById(el);

      // create a Range object
      let range = document.createRange();
      // set the Node to select the "range"
      //@ts-ignore
      range.selectNode(urlField);
      // add the Range to the set of window selections

      window.getSelection()?.addRange(range);
      //@ts-ignore
      // copy(urlField)
      // execute 'copy', can't 'cut' in this case
      if (document.execCommand("copy")) {
        toast("Copied to clipboard!", { type: toast.TYPE.SUCCESS });
      }
      window.getSelection()?.removeRange(range);
    }
  }

  async function getDatatableSettings() {
    try {
      const res = (await datatableSettingsService.getDatatableSettings({
        table_name: settingTableName,
      })) as any;
      if (res.data.status === "success") {
        // console.log('headers', 'result', res)
        if (res.data.data[0].hidden_columns) {
          const hidden_columns = res.data.data[0].hidden_columns;
          setHiddenColumns(JSON.parse(hidden_columns));
        }
        if (res.data.data[0].sort_columns) {
          const sort_columns = res.data.data[0].sort_columns;
          setSortColumns(JSON.parse(sort_columns));
        }
      } else {
      }
    } catch (err) { }
  }

  async function updateDatatableSettings(data: DataTableSettingType) {
    // console.log('headers', 'data', data);
    try {
      setLoading(true);
      const res = (await datatableSettingsService.updateDatatableSettings({
        table_name: settingTableName,
        hidden_columns: data?.hidden_columns,
        sort_columns: data?.sort_columns,
        // show_entries: 10,
      })) as any;
      if (res.data.status === "success") {
        setHiddenColumns(data?.hidden_columns);
        setSortColumns(data?.sort_columns || []);
        setLoading(false);
        setDBSModal(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  }

  async function resetDatatableSettings() {
    try {
      setLoading(true);
      const res = (await datatableSettingsService.resetDatatableSettings({
        table_name: settingTableName,
      })) as any;
      if (res.data.status === "success") {
        setHiddenColumns([]);
        setSortColumns([]);
        setLoading(false);
        setDBSModal(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  }

  // const defaultSorted = [
  //   {
  //     dataField: "id",
  //     order: "asc",
  //   },
  // ];

  // Select All Button operation
  // const selectRow = {
  //   mode: "checkbox",
  // };

  // Custom Pagination Toggle
  const sizePerPageList = [
    { key: "10", text: "10", value: 10 },
    { key: "20", text: "20", value: 20 },
    { key: "50", text: "50", value: 50 },
    { key: "100", text: "100", value: 100 },
    // { key: "500", text: "500", value: 500 },
    // { key: "1000", text: "1000", value: 1000 },
    // {
    //   key: "All",
    //   text: "All",
    //   value: datatableOptions.recordsTotal ? datatableOptions.recordsTotal : 0,
    // },
  ];

  const customTotal = (from: number, to: number, size: number) => (
    <span>
      {t("Showing")} {from} {t("to")} {to} {t("of")} {size} {t("entries")}
    </span>
  );

  const pageOptions = {
    page: datatableOptions.page,
    sizePerPage: datatableOptions.sizePerPage,
    totalSize: datatableOptions.recordsFiltered,
    custom: true,
    sizePerPageList: sizePerPageList,
    alwaysShowAllBtns: true,
    showTotal: true,
    paginationTotalRenderer: customTotal,
  };

  const handleDataTableSettings = () => {
    setDBSModal(true);
  };

  const handleDataTableOrderSettings = () => {
    setDBSOrderModal(true);
  };

  useEffect(() => {
    if (settingTableName) {
      getDatatableSettings();
    }
  }, []);

  function sortFunc(a: any, b: any) {
    return sortColumns.indexOf(a.id) - sortColumns.indexOf(b.id);
  }

  useEffect(() => {

    if (hiddenColumns.length > 0 || sortColumns.length > 0) {
      const filteredColumns = filterOptionsSetHidden(columns, hiddenColumns);
      const filteredHiddenColumns = filterOptionsRemoveHidden(filteredColumns);

      if (sortColumns.length > 0) filteredHiddenColumns.sort(sortFunc);

      if (filteredHiddenColumns.length) {
        // console.log('headers', 'sortColumns', filteredHiddenColumns)
        setDatatableColumns(filteredHiddenColumns);
      } else {
        setDatatableColumns([
          {
            dataField: "",
            text: "",
          },
        ]);
      }
    } else {
      // console.log('headers', 'sortColumns', 'else')
      setDatatableColumns(columns);
    }
  }, [columns, hiddenColumns, sortColumns]);

  useEffect(() => {
    if (loadingListData === true) {
      setNoDataIndication(t("LOADING"));
    } else {
      if (datatableOptions.recordsTotal === 0) {
        setNoDataIndication(t("NOT_FOUND"));
      } else if (datatableOptions.recordsFiltered === 0) {
        setNoDataIndication(t("NOT_MATCHED"));
      }
    }
  }, [loadingListData]);

  const onCloseClick = () => {
    setDBSModal(false);
  };

  useEffect(() => {
    if (columnChange) setOrderChange(true);
  }, [columnChange]);

  useEffect(() => {
    handleFilters("SET", datatableOptions, filters);
  }, [datatableOptions, filters]);

  useEffect(() => {
    if (uid.current)
      dispatch(setDataTableID(uid.current))
  }, [uid])

  const reorder = (list: any, startIndex: string, endIndex: string) => {
    // console.log('headers', 'reorder', list, startIndex, endIndex);

    const result = Array.from(list);
    const SI = result.findIndex((a: any) => a.dataField === startIndex);
    const EI = result.findIndex((a: any) => a.dataField === endIndex);
    const [removed] = result.splice(SI, 1);
    result.splice(EI, 0, removed);
    console.log('headers', 'reorder', startIndex, SI, endIndex, EI);
    // console.log('headers', 'reorder', list.map((s: any) => s.dataField));
    return result;
  };

  let sdata = [] as any;

  const mouseDownHandler = (e: any) => {
    setOrderIdx((state: any) => ({ ...state, src: 3 }));
    sdata[0] = e.currentTarget.id;
    // console.log("headers", 'handleDragstart => ', parseInt(e.currentTarget.id, 10), orderIdx)
    e.currentTarget.style.opacity = '0.4';
    e.currentTarget.classList.add('over');
  }


  const handleDragEnd = async (e: any) => {
    // console.log("headers", 'items => ', hiddenColumns, sortColumns)
    // setOrderIdx((state: any) => ({ ...state, dest: e.currentTarget.id }));
    if (sdata.length === 2) {
      // const cols = formatCols(datatableColumns);
      const items: any = reorder(datatableColumns, sdata[0], sdata[1]);

      const sortCol = items.map((key: any, i: number) => ({ idx: i, dataField: key.dataField }));
      // console.log("headers", 'items => ', sdata, items, sortCol)
      // setDatatableColumns(items)
      e.currentTarget.style.opacity = '1';
      e.currentTarget.classList.remove('over');
      // setOrderIdx((state: any) => ({ ...state, src: -1, dest: -1 }));
      sdata = [];
      // await updateDatatableSettings({ hidden_columns: hiddenColumns, sort_columns: sortCol })
    }
  }

  const handleDragEnter = async (e: any) => {
    await setOrderIdx((state: any) => ({ ...state, dest: 1 }));
    sdata[1] = e.currentTarget.id;
    // console.log("headers", 'handleDragEnter => ', parseInt(e.currentTarget.id, 10), orderIdx)
    e.currentTarget.classList.add('ready-to-drop');
  }

  const handleDragLeave = (e: any) => {
    e.currentTarget.classList.remove('ready-to-drop');
  }

  useEffect(() => {
    const headers = Array.prototype.slice.call(
      document.querySelectorAll(".draggable-header")
    );

    console.log("headers", headers)

    headers.forEach((header: any, i: number) => {
      header.addEventListener('dragstart', mouseDownHandler);
      header.addEventListener('dragenter', handleDragEnter);
      header.addEventListener('dragleave', handleDragLeave);
      header.addEventListener('dragend', handleDragEnd);
      // header.addEventListener('drop', handleDrop);
    })

    return () => {
      headers.forEach((header: any, i: number) => {
        header.removeEventListener('dragstart', mouseDownHandler);
        header.removeEventListener('dragenter', handleDragEnter);
        header.removeEventListener('dragleave', handleDragLeave);
        header.removeEventListener('dragend', handleDragEnd);
        // header.removeEventListener('drop', handleDrop);
      })
    }

  }, [])

  const icons = [
    {
      id: 'company_id',
      icon: 'apartment'
    },
    {
      id: 'contact_person_id',
      icon: 'person'
    },
    {
      id: 'payment_status',
      icon: 'label_important'
    }
  ]
  const filterBadge = (filter: any) => {
    return (
      (filter[1] !== "" && filter[1] !== null && filter[0] !== 'is_filtered') ? <Badge color="primary" pill className="badge-soft-primary font-size-12 mx-1 px-1 py-1 align-vertical">
        <span className="align-middle material-symbols-outlined fw-light font-size-17 label-icon mx-1">
          {/* {icons.find((a: any) => a.id === filter[0])?.icon ? icons.find((a: any) => a.id === filter[0])?.icon : 'beenhere'} */}
        </span>
        {filter[0]} ::
        {filter[1]}
        <span className="align-middle material-symbols-outlined fw-light font-size-17 label-icon mx-1 cursor-pointer" title="Remove">
          {t("close")}
        </span>
      </Badge> : null
    )
  }


  return (
    <>
      <DataTableSettingsModal
        show={dbsModal}
        columns={columns}
        settingTableName={settingTableName}
        hiddenColumnsData={hiddenColumns}
        sortColumnsData={sortColumns}
        onSubmitClick={(data: any) => updateDatatableSettings(data)}
        onResetClick={() => resetDatatableSettings()}
        onCloseClick={onCloseClick}
        loading={loading}
      />

      <DataTableOrderSettingsModal
        show={dbsOrderModal}
        loadingListData={loadingListData}
        settingTableName={settingTableName}
        tableData={products}
        dependentField={dependentField}
        onCloseClick={() => setDBSOrderModal(false)}
        loading={loading}
        setColumnChange={setColumnChange}
      />

      <Row>
        <Col className="col-12">
          <PaginationProvider pagination={paginationFactory(pageOptions)}>
            {({ paginationProps, paginationTableProps }: any) => (
              <ToolkitProvider
                keyField="id"
                columns={datatableColumns}
                data={products}
                search={
                  { defaultSearch: datatableOptions.searchText }
                  // datatableOptions.search && datatableOptions.search == false ? false : true
                }
                exportCSV={{ fileName: exportedFileName + ".csv" }}
              >
                {(toolkitProps) => (
                  <React.Fragment>

                    <Row className="mb-2">
                      <Col sm="12" md="3">
                        <div className="d-inline">
                          {t("Show")}{" "}
                          <SizePerPageDropdownStandalone
                            {...paginationProps}
                            className="dt_show_entries"
                          />{" "}
                          {t("entries")}
                        </div>
                      </Col>
                      {!hideToolbar &&
                        <Col sm="12" md="9">
                          <Row>
                            <Col sm="6" md="6">
                              {datatableOptions.search !== false ? (
                                <DataTableSearchBar
                                  {...toolkitProps.searchProps}
                                  loadingListData={loadingListData}
                                  inputFocus={inputFocus}
                                />
                              ) : null}
                            </Col>

                            <Col sm="6" md="6" className="text-end">
                              {mainButtons ? mainButtons : null}

                              <div className="dt-buttons btn-group">
                                <button
                                  title={t("Copy to clipboard")}
                                  className="btn btn-outline-light"
                                  onClick={() =>
                                    copyToClipboard(`dataTable_${uid.current}`)
                                  }
                                >
                                  <span className="align-middle material-symbols-outlined fw-light fs-4">
                                    file_copy
                                  </span>
                                </button>
                                {userObj.CAN_EXPORT ?
                                  <button
                                    title={t("Export to excel")}
                                    className="btn btn-outline-light"
                                    onClick={() =>
                                      exportToExcel(
                                        `dataTable_${uid.current}`,
                                        exportedFileName
                                      )
                                    }
                                  >

                                    <span className="align-middle material-symbols-outlined fw-light fs-4">
                                      table_view
                                    </span>
                                  </button> : null
                                }
                                {userObj.CAN_EXPORT ?
                                  <ExportCSVButton
                                    {...toolkitProps.csvProps}
                                    className="btn-outline-light"
                                  >
                                    <span
                                      className="align-middle material-symbols-outlined fw-light fs-4"
                                      title={t("Export to CSV")}
                                    >
                                      description
                                    </span>
                                  </ExportCSVButton> : null
                                }
                                {userObj.CAN_PRINT ?
                                  <button
                                    title={t("Print")}
                                    className="btn btn-outline-light"
                                    onClick={() => printData(`dataTable_${uid.current}`)}
                                  >
                                    <span className="align-middle material-symbols-outlined fw-light fs-4">
                                      print
                                    </span>
                                  </button> : null
                                }
                                {customButtons ? customButtons : null}
                              </div>

                              <div className="dt-buttons btn-group ms-2">
                                {settingTableName && (
                                  <button
                                    title={t("Settings")}
                                    className="btn btn-outline-light"
                                    style={{ borderColor: "#ced4da" }}
                                    onClick={handleDataTableSettings}
                                    disabled={loadingListData}
                                  >
                                    <span className="align-middle material-symbols-outlined fw-light fs-4">
                                      settings
                                    </span>
                                  </button>
                                )}

                                {dependentField && (
                                  <button
                                    title={t("Order Settings")}
                                    className="btn btn-outline-light"
                                    style={{ borderColor: "#ced4da" }}
                                    onClick={handleDataTableOrderSettings}
                                  >
                                    <span className="align-middle material-symbols-outlined fw-light fs-4">
                                      low_priority
                                    </span>
                                  </button>
                                )}

                                <button
                                  title={t("Clear filter")}
                                  className="btn btn-outline-light"
                                  style={{ borderColor: "#ced4da" }}
                                  onClick={async () => {
                                    clearFilter();
                                  }}
                                >
                                  <span className="align-middle material-symbols-outlined fw-light fs-4">
                                    autorenew
                                  </span>
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      }
                    </Row>
                    {/* <Row>
                      <Col>
                        {filters.is_filtered ? Object.entries(filters).map((f: any) => filterBadge(f)) : null}
                      </Col>
                    </Row> */}

                    <Row>
                      <Col xl="12">
                        <div className="table-responsive table-advanced">
                          <BootstrapTable
                            id={`dataTable_${uid.current}`}
                            // keyField={"id"}
                            responsive={true}
                            bordered={bordered}
                            striped={false}
                            // bootstrap4={true}
                            // defaultSorted={defaultSorted}
                            // selectRow={selectRow}
                            rowStyle={rowStyle}
                            classes={"nowrap w-100"}
                            hover={true}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                            noDataIndication={
                              noDataIndication === "LOADING" ? (
                                <div className="data-notification mt-5">
                                  <NoDataFound
                                    icon="quick_reference_all"
                                    message={t("Please wait, We are looking for the records!")}
                                  />
                                </div>
                              ) : noDataIndication === "NOT_MATCHED" ? (
                                <div className="data-notification mt-5">
                                  <NoDataFound
                                    icon="quick_reference"
                                    message={t("You have no matching records to view!")}
                                  />
                                </div>
                              ) : noDataIndication === "NOT_FOUND" ? (
                                <div className="data-notification mt-5">
                                  <NoDataFound
                                    icon="quick_reference"
                                    message={t("You have no records to view!")}
                                  />
                                </div>
                              ) : (
                                <div className="data-notification mt-5">
                                  <NoDataFound
                                    icon="quick_reference"
                                    message={t("You have no records to view!")}
                                  />
                                </div>
                              )
                            }
                            loading={true}
                            remote={{
                              filter: true,
                              pagination: true,
                              sort: true,
                              cellEdit: false,
                            }}
                            onTableChange={onTableChange}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row className="align-items-md-center mt-3">
                      <Col className="inner-custom-pagination d-flex">
                        <div className="react-bootstrap-table-pagination-total">
                          <PaginationTotalStandalone {...paginationProps} />

                          {datatableOptions.recordsFiltered !=
                            datatableOptions.recordsTotal ? (
                            <span>
                              {" "}
                              ({t("filtered from")}{" "}
                              {datatableOptions.recordsTotal}{" "}
                              {t("total entries")}){" "}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="text-md-right ms-auto">
                          <PaginationListStandalone
                            keyField="id"
                            {...paginationProps}
                          />
                        </div>
                      </Col>
                    </Row>
                  </React.Fragment>
                )}
              </ToolkitProvider>
            )}
          </PaginationProvider>
        </Col>
      </Row>

    </>
  );
};

export default DataTable;
