import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { QuestionnaireTypeAction } from '../../../store/actions';
// Import Loader
import Loader from "../../../components/Layout/Loader";
import { Card, CardBody, Col, Row } from 'reactstrap';
import QuestionView from '../../Questionnaire/QuestionView';
import moment from 'moment';
import commonService from '../../../services/common.service';
import "../../Questionnaire/style.scss";
import MultiInput from '../../Questionnaire/MultiInput';
import TableMultiInput from '../../Questionnaire/multiinput/Table';
import { useTranslation } from 'react-i18next';

const PreviewType = (props: any) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [typeId, setTypeId] = useState(props.typeId);
  const [loadingPreview, setLoadingPreview] = useState(true);
  const [questionList, setQuestionList] = useState<any>([]);
  const [tabList, setTabList] = useState<any>([]);
  const [activeTab, setActiveTab] = useState<any>();
  const [intialData, setIntialData] = useState<any>({});
  const [multicheckname, setMulticheckname] = useState("");
  const [multicheck, setMulticheck] = useState<any>([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [questionPreviewData, setQuestionPreviewData] = useState<any>([]);


  // get store data
  let data = useSelector((state: any) => ({
    success: state.QuestionnaireType.success,
    questionslist: state.QuestionnaireType.GET_QUESTIONS_LIST,
    error: state.QuestionnaireType.error
  }));


  useEffect(() => {
    if (typeId) {
      dispatch(
        QuestionnaireTypeAction.getQuestionnairePreview(typeId)
      );
    }
  }, [typeId])

  useEffect(() => {
    if (data && data.questionslist && data.questionslist?.data) {
      setQuestionList(data.questionslist.data.questions_data);
      setQuestionPreviewData(data.questionslist.data.questions_data);
      setTabList(data.questionslist.data.questionnaire_tab_data);
      setLoadingPreview(false);
      setActiveTab(data.questionslist.data.questionnaire_tab_data[0].id);
    }
  }, [data.questionslist]);

  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  const scrollToTop = (id: string) => {
    if (id) {
      var element: any = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  }

  const [activeTabname, setActiveTabname] = useState<any>("");
  const [activeTabnames, setActiveTabnames] = useState<any>([]);
  document.querySelectorAll('.activeTabItem').forEach((i) => {
    if (i) {
      const observer = new IntersectionObserver((entries) => {
        observerCallback(entries, observer, i)
      },
        { threshold: .9 });
      observer.observe(i);
    }
  });

  const observerCallback = (entries: any, observer: any, header: any) => {
    entries.forEach((entry: any, i: any) => {
      if (entry.target.id !== activeTabname && entry.isIntersecting && !activeTabnames.includes(entry.target.id)) {
        setActiveTabname(entry.target.id);
      }
    });
  };

  useEffect(() => {
    const currentab: any = tabList.find(
      (tab: any) =>
        tab.questionnaire_tab_value == activeTabname
    );
    setActiveTab(currentab?.id);
    if (!activeTabnames.includes(activeTabname)) {
      setActiveTabnames([...activeTabnames, activeTabname]);
    }

  }, [activeTabname]);

  function renderRowHTML(rowContent: any) {
    return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
  }

  function handleMultiInputChange(e: any, ref: any) {
    //console.log(e, intialData, ref);
    setIntialData((preState: any) => ({ ...preState, [ref]: JSON.stringify(e.data) }));
  }


  const handleChangecheck = (e: any) => {
    let obj: any = {};
    type ObjectKey = keyof typeof obj;
    const pre_check = e.target.name as ObjectKey;
    const pre_check_val = intialData[pre_check];
    if (pre_check_val !== undefined) {
      setIntialData({ ...intialData, [e.target.name]: !pre_check_val });
    } else {
      setIntialData({ ...intialData, [e.target.name]: e.target.value });
    }

  }


  const handleChange = (e: any) => {
    setIntialData((intialData: any) => {
      return {
        ...intialData,
        [e.target.name]: e.target.value
      };
    });
  }

  const handleChangeMulti = (e: any) => {
    setIntialData({ ...intialData, [e.target.name]: e.target.value });
  }

  const handleChangeCountry = (name: any, value: any) => {
    setIntialData((intialData: any) => {
      return {
        ...intialData,
        [name]: value
      };
    });
  }


  const handleChangeMulticheckbox = (e: any, answer: any) => {
    setMulticheckname(e.target.name);
    if (e.currentTarget.checked) {
      setMulticheck([...answer, e.target.value]);
    } else {
      var index = answer.indexOf(e.target.value);
      if (index !== -1) {
        answer.splice(index, 1);
      }
      setMulticheck(answer);
    }
  }

  const handlecalender = (name: any, dateval: any) => {
    setIntialData((intialData: any) => {
      return {
        ...intialData,
        [name]: moment(dateval).format("YYYY-MM-DD hh:mm:ss")
      };
    });
  }

  const handleKeyDown = (e: any) => {
    // setIntialData({ ...intialData, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    if (multicheckname != "") {
      setIntialData({ ...intialData, [multicheckname]: multicheck });
    }
  }, [multicheck, multicheckname]);

  const [query, setQuery] = useState("");
  const getSelectOptions = async (tableName: string, fieldName: string) => {
    const response = await commonService.getSelectOptionsList(
      tableName,
      fieldName,
      query ? query : ""
    );
    return response.data.data;
  };

  useEffect(() => {
    getSelectOptions("tbl_master_country", "name").then(function (options: any) {
      setCountryOptions(options);
    });
  }, []);

  useEffect(() => {
    if (data.success !== "") {
      setLoadingPreview(false);
    }
    if (data.error !== "") {
      setLoadingPreview(false);
    }
  }, [data.success, data.error, dispatch]);

  useEffect(() => {
    if (questionList.length) {
      const questionAr: any = questionList;
      for (const [key, value] of Object.entries(intialData)) {
        questionAr.forEach((question: any) => {
          if (question.reference_name == key) {
            question.user_data_answer = value;
          }
        })
      }
      updateQuestionAr(questionAr);
    }
  }, [intialData])

  const updateQuestionAr = async (questionDataAr: any) => {
    let questionAr: any = [];
    let obj: any = {};
    questionDataAr.forEach((question: any) => {
      obj = { ...obj, [question.reference_name]: question.user_data_answer };
    });

    const condtionObj = Object.keys(obj);
    const condtionAns = Object.values(obj);
    if (questionDataAr) {
      questionDataAr.forEach((question: any) => {
        let checkcondition = false
        if ((question.condition_reference_question == null) || (!condtionObj.includes(question.condition_reference_question) && !condtionObj.includes(question.condition_reference_question_2))) {
          questionAr.push(question);
        } else if (condtionObj.includes(question.condition_reference_question) || condtionObj.includes(question.condition_reference_question_2)) {
          if (condtionObj.includes(question.condition_reference_question)) {
            const answer_label = question.condition_answer_label;
            const index = condtionObj.indexOf(question.condition_reference_question);
            if (answer_label !== null && question.condition_input_type != 2) {
              const answAr = answer_label.split(',');
              if (answAr.includes(condtionAns[index])) {
                questionAr.push(question);
              }
            }
            else if (condtionAns[index] == '1' && question.condition_answer_label == "Yes") {
              questionAr.push(question);
            }
            else if (condtionAns[index] == '0' && question.condition_answer_label == "No") {
              questionAr.push(question);
            }
          }
        }
      })
      setQuestionPreviewData(questionAr);
    }
  }



  return (
    <React.Fragment>
      {loadingPreview ? <Loader /> : null}
      <Row>
        <Col>
          <h3 className='text-center text-primary'>
            {!!tabList.length && tabList[0]?.questionnaire_type}
          </h3>
        </Col>
      </Row>
      <Row className='questionnaire scroll-questionnaire'>
        <Col lg="3" className="items">
          <div className="items-head">
            <p>{t("QUESTIONNAIRE")}</p>
          </div>
          <div className="items-body">
            {!!tabList.length && (
              <>
                {
                  tabList.map((item: any, key: any) => {
                    return (
                      <div className={activeTab === item.id ? 'items-body-content active' : 'items-body-content'} onClick={() => {
                        toggleTab(item.id)
                      }} key={key}>
                        <span onClick={() => scrollToTop(item.id)}>{renderRowHTML(item.questionnaire_tab_value)}</span>
                        <i className="fa fa-angle-right"></i>
                      </div>

                    )
                  })
                }
              </>
            )
            }

          </div>

        </Col>

        <Col lg="9" className='fixed-top-preview'>
          <Card>
            <CardBody>
              <Row>
                {questionPreviewData && questionPreviewData.map((questionrow: any, key: number) => (
                  <React.Fragment key={key}>

                    {((key > 0) && (questionPreviewData[key - 1] && questionPreviewData[key - 1].tab_name) && (questionPreviewData[key - 1].tab_name !== questionrow.tab_name)) ?
                      <>
                        <Row id={questionrow.tab_id} className="mt-3 mb-3 activeTabItem"><h4 className="text-primary text-center" id={questionrow.tab_name}>{renderRowHTML(questionrow.tab_name)}</h4></Row>
                      </>
                      : null}
                    {(key == 0) ? <>
                      <Row id={questionrow.tab_id} className="mt-3 mb-3 activeTabItem">
                        <h4 className="text-primary text-center">{renderRowHTML(questionrow.tab_name)}</h4>
                      </Row>
                    </>
                      : null}
                    {questionrow.input_type === 9 || questionrow.input_type === 21 ?
                      <Row className="mt-3">
                        {questionrow.input_type === 9 ?
                          <MultiInput
                            label={questionrow.question}
                            multi_input_data={questionrow.multi_input_data}
                            reference_name={questionrow.reference_name}
                            handleMultiInputChange={(e: any, ref: any) => handleMultiInputChange(e, ref)}
                            user_data_answer={questionrow.user_data_answer}
                            is_disabled={false}
                            input_type={questionrow.input_type}
                          />
                          :
                          <TableMultiInput
                            label={questionrow.question}
                            multi_input_data={questionrow.multi_input_data}
                            reference_name={questionrow.reference_name}
                            handleMultiInputChange={(e: any, ref: any) => handleMultiInputChange(e, ref)}
                            user_data_answer={questionrow.user_data_answer}
                            is_disabled={false}
                            input_type={questionrow.input_type}
                          />
                        }
                      </Row>
                      :
                      <Col lg={questionrow.field_width ? questionrow.field_width : 12}>
                        <QuestionView questionrow={questionrow} input_type={questionrow.input_type} field_width={questionrow.field_width} question={questionrow.question} reference_name={questionrow.reference_name} placeholder={questionrow.placeholder} user_data_answer={questionrow.user_data_answer} countryOptions={countryOptions}
                          answer_option_list={questionrow.answer_option_list} handleChange={handleChange} handleKeyDown={handleKeyDown} handleChangeMulti={handleChangeMulti} handleMultiInputChange={(e: any, ref: any) => handleMultiInputChange(e, ref)}
                          handleChangeMulticheckbox={handleChangeMulticheckbox} handleChangeCountry={handleChangeCountry} handlecalender={handlecalender} handleChangecheck={handleChangecheck} adminview={true} questionnaireID={null} questionnaireSubID={null} section_id={questionrow.question_id} answerOnly={undefined}
                          multi_input_data={questionrow.multi_input_data}
                          activeTabnames={null} required={false} />
                      </Col>
                    }

                  </React.Fragment>
                ))}
              </Row>
            </CardBody>


          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default PreviewType