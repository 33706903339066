import React from 'react'
import { Input, InputGroup, Label } from 'reactstrap';
import { onlyPositiveValue } from '../../helpers/functions_helper';

const InputGroupField = (props: any) => {
    let { label, name, placeholder, value, handleChange, disabled, answer_only, labelClick, autoFocusBol, handleKeyDown, type, isDisabled, field_width_input, title, space_input, space_input_value } = props;

    return (
        <div className="mt-3">
            <Label for={name} onClick={labelClick} className={answer_only ? "pointer questionview" : "questionview"}>
                {label}
                {title ? (
                    <span className='tooltip-txt'>
                        < i className="fa-regular fas fa-info-circle"></i><span className="tooltiptext">{title}</span>
                    </span>
                ) : null}
            </Label>
            <div className={(space_input && space_input_value) ? `${space_input}-${space_input_value}` : ''}>
                <InputGroup className={field_width_input ? `w-${field_width_input} float-start` : 'float-start'}>
                    <Input
                        name={name}
                        className="form-control"
                        type="number"
                        step={type == "amount" ? "1" : ".01"}
                        min={0}
                        max={type == "amount" ? 10000000000 : 100}
                        placeholder={placeholder}
                        defaultValue={value ? value : null}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        autoFocus={autoFocusBol ? true : false}
                        disabled={isDisabled}
                        onKeyPress={(e) => onlyPositiveValue(e)}
                    //readOnly={true}
                    />
                     <div className="input-group-text">{type == "amount" ? "€" : "%"}</div>
                       
                </InputGroup>
            </div>
        </div >
    )
}

export default InputGroupField