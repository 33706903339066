import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import i18n from "i18next";
import { languageCodes } from "../../../common/staticdata";
import Button from "../../../components/Button/Button";
import LoadingSpinner from "../../../components/Common/Loader";
import MultiComments from "../../../components/Common/MultiComments";
import Breadcrumbs from "../../../components/Layout/Breadcrumb";
import { getUserProfile } from "../../../helpers/functions_helper";
import { dateFormat } from "../../../utils/formats";
import { renderRowHTML } from "../../../utils/utils";
import { getNewsFeed } from "./helper";
import LikeDislike from "./LikeDislike";

import ScrollUp from "../../../components/ScrollUpDown/ScrollUp";
import ScrollDown from "../../../components/ScrollUpDown/ScrollDown";

const NewsFeedView = () => {
    const { t } = useTranslation();
    const { id, news_feed_id } = useParams();

    document.title = t("View News Feed ") + "| " + process.env.REACT_APP_NAME;

    const moduleHeading = t('View News Feed')

    const breadcrumbItems = useMemo(() => [
        {
            url: "/dashboard",
            label: t("Dashboard"),
            active: false,
        },
        {
            url: "#",
            label: moduleHeading,
            active: true,
        },
    ], [t]);

    const userObj = getUserProfile('news-feed');

    const [data, setData] = useState<NewsFeedType>();
    const [loading, setLoading] = useState<boolean>(true);

    const getData = async (id: number) => {
        const result = await getNewsFeed(id)
        if (result) {
            setData(result?.data)
        }
        setLoading(false)
    }

    const scrollToBottom = () => {
        window.scrollTo(0, document.body.scrollHeight);
    }

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    const addTagetToAnchor = (inputString: any) => {
        const escapeRE = new RegExp(/<(a)([^>]+)>/);
        return inputString?.replace(escapeRE, "<$1 target=\"_blank\" $2>");
    }

    useEffect(() => {
        id && getData(parseInt(id, 10));
    }, [id])

    return (
        <React.Fragment>
            <ScrollUp />
            <ScrollDown top={'100px'} />

            <div className={"page-content"}>
                <Container fluid>
                    <Breadcrumbs
                        breadcrumbTitle={moduleHeading}
                        breadcrumbItems={breadcrumbItems}
                    />
                    <Row md={8}>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <Row className="justify-content-center">
                                        <Col xl={8}>
                                            {loading ? <LoadingSpinner /> :
                                                <>
                                                    <div className="text-center">
                                                        <h4>{data?.news_feed_title}</h4>
                                                        <hr className="my-4" />
                                                        <Row>
                                                            <Col>
                                                                {data?.user ? <p className="text-muted"><i className="fa fa-user me-1"></i> {data?.user?.first_name + ' ' + data?.user?.last_name}</p> : null}
                                                            </Col>
                                                            <Col>
                                                                {data?.created_at ? <p className="text-muted mb-3"><span className="align-middle material-symbols-outlined fw-light fs-4 me-1">calendar_today</span>{dateFormat(data?.created_at, { dateStyle: 'medium', timeStyle: 'short', hourCycle: 'h12' }, languageCodes(i18n.language))}</p> : null}
                                                            </Col>
                                                            <Col>
                                                                {data && <LikeDislike
                                                                    moduleData={{
                                                                        module_app_id: 13,
                                                                        module_main_id: data?.id,
                                                                        module_sub_id: data?.news_feed_id,
                                                                        section_id: 1,
                                                                    }}
                                                                />}
                                                            </Col>
                                                        </Row>

                                                        <hr />
                                                        <div className="my-5">
                                                            <img src={data?.attachment} width={700} />
                                                        </div>
                                                        <hr />
                                                    </div>
                                                    <div className="mt-4">
                                                        <div className="text-muted font-size-14" id="ContentDiv">
                                                            <p>{renderRowHTML(addTagetToAnchor(data?.description), '')}</p>
                                                        </div>
                                                    </div>

                                                    <div className="mt-4">
                                                        <div className="text-muted font-size-12">
                                                            <label>{t("Source")}:&nbsp;</label><a href={data?.sources} target="_blank">{data?.sources}</a>
                                                        </div>
                                                    </div>

                                                    <div className="my-5">
                                                        <hr />
                                                    </div>
                                                    {(data && userObj.USER_COMPANY === 1) && <MultiComments
                                                        moduleData={{
                                                            module_app_id: 13,
                                                            module_main_id: data?.id,
                                                            module_sub_id: data?.news_feed_id,
                                                            section_id: 0,
                                                        }}
                                                        fetchData={true}
                                                        isOpen={false}
                                                    />}
                                                </>
                                            }
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default NewsFeedView;