import React, { useEffect, useState } from "react";
import { Card, CardTitle, CardBody, Table, Button, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import ProgressBar from "../../components/Common/ProgressBar";
import tasksService from "../../services/tasks.service";
import NoDataFound from "../../components/NoDataFound";
import TaskPriority from "../KanbanBoard/TaskPriority";
import moment from "moment";
import TaskAssignee from "../KanbanBoard/TaskAssignee";
import { useTranslation } from "react-i18next";

const UserTaskList = (props: any) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);

  // Get comment ------------------------------------------------------------
  const [taskList, setTaskList] = useState<any>([]);
  const [activeTab, toggleTab] = useState("1");
  const [page, setPage] = useState<number>(0);
  const [endpage, setEndPage] = useState<number>(0);

  const getUserTaskListUpComming = async () => {
    const response = await tasksService.getUserTaskListUpComming({ page });
    if (response.data.status === "success") {
      setTaskList(response.data.data);
      setLoading(false);
    }
  };

  const getUserTaskListInProcess = async () => {
    const response = await tasksService.getUserTaskListInProcess({ page });
    if (response.data.status === "success") {
      setTaskList(response.data.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    setTaskList([]);
    if (activeTab === "1") {
      setPage(0)
      getUserTaskListInProcess();
    } else {
      setPage(0)
      getUserTaskListUpComming();
    }
  }, [activeTab]);

  useEffect(() => {
    setLoading(true);
    setTaskList([]);
    if (activeTab === "1") {
      getUserTaskListInProcess();
    } else {
      getUserTaskListUpComming();
    }
  }, [page]);

  const pagination = (c: number) => {
    setLoading(true);
    c === 1 ? setPage(page => page - 10) : setPage(page => page + 10)
  }

  // Get comment ------------------------------------------------------------
  function createMarkup(val: any) {
    return { __html: val };
  }


  return (
    <React.Fragment>
      <div className="d-sm-flex flex-wrap">
        <CardTitle className="mb-3">{t("Tasks")}</CardTitle>
        <div className="ms-auto">
          <Link className="btn-link" to="/tasks/all-tasks">
            {t("View All")}
          </Link>
        </div>
      </div>
      <div className="d-sm-flex flex-wrap flex-end mb-2">
        <div className="mb-3">
          <Button
            size="sm"
            color={`${activeTab === "1" ? "primary" : ""}`}
            onClick={() => {
              toggleTab("1");
            }}
          >
            {t("In Process")}
          </Button>
          <Button
            size="sm"
            color={`${activeTab === "2" ? "primary" : ""}`}
            className={`ml-1`}
            onClick={() => {
              toggleTab("2");
            }}
          >
            {t("Upcoming")}
          </Button>
        </div>
        <div className="ms-auto  gap-2 right-controls ">
          <button disabled={page == 0 || loading} type="button" className="btn btn-light btn-sm" style={{ width: "auto" }} onClick={() => pagination(1)}>
            <span className="align-middle material-symbols-outlined fw-light fs-4">arrow_back</span>
          </button>
          &nbsp;
          <button type="button" disabled={taskList.length == 0 || loading} className="btn btn-light btn-sm" style={{ width: "auto" }} onClick={() => pagination(2)}>
            <span className="align-middle material-symbols-outlined fw-light fs-4">arrow_forward</span>
          </button>
        </div>
      </div>

      <SimpleBar style={{ height: 376 }}>
        {taskList && taskList.length > 0 ? (
          taskList.map((item: any, index: number) => {
            return (
              <Card className="mb-3" key={index}>
                <CardBody>
                  <h5 className="text-truncate font-size-14 mb-1">
                    {item?.is_subtask ? (
                      <>
                        <Link
                          to={
                            "/tasks/subtask-details/" +
                            item.id +
                            "/" +
                            item.task_id
                          }
                          className="text-decoration-underline"
                        >
                          <span
                            dangerouslySetInnerHTML={createMarkup(item.title)}
                          ></span>
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link
                          to={
                            "/tasks/task-details/" +
                            item.id +
                            "/" +
                            item.task_id
                          }
                          className="text-decoration-underline"
                        >
                          <span
                            dangerouslySetInnerHTML={createMarkup(item.title)}
                          ></span>
                        </Link>
                      </>
                    )}
                  </h5>

                  <Row className="align-items-center mt-3 mb-1">
                    <Col className="col-12 d-flex align-items-center">
                      <div
                        className="badge"
                        style={{
                          backgroundColor: item?.type_color_code
                            ? item?.type_color_code
                            : "#FF8FB1",
                        }}
                      >
                        {item?.type_fee_code}
                      </div>

                      {item?.need_more_info === 1 && (
                        <span className="align-middle material-symbols-outlined fw-light fs-4 text-danger ms-2">
                          flag
                        </span>
                      )}

                      {item?.sensitive === 1 && (
                        <span className="align-middle material-symbols-outlined fw-light fs-4 text-danger ms-2">
                          star
                        </span>
                      )}

                      <TaskPriority
                        value={item?.priority}
                        tip={item?.priority_value}
                        className="ms-2"
                      />

                      <div className="text-primary">
                        {item?.is_subtask && (
                          <span className="align-middle material-symbols-outlined fw-light fs-4 bg-info text-white rounded ms-2">
                            done_all
                          </span>
                        )}
                        {!item?.is_subtask && (
                          <span className="align-middle material-symbols-outlined fw-light fs-4 bg-info text-white rounded ms-2">
                            check
                          </span>
                        )}
                        <span className="align-middle material-symbols-outlined fw-light fs-4 ms-2">
                          calendar_month
                        </span>
                        {" " + moment(item?.due_date).format("DD/MM/yy")}
                      </div>

                      <div className="badge bg-primary ms-2">
                        {item?.company != 0
                          ? item?.company_name
                          : item?.contact_person_full_name}
                      </div>

                      <div className="text-end ms-2">
                        {item?.preparer_value !== null && (
                          <TaskAssignee
                            assignee={item?.preparer_value}
                            user="p"
                            status={item?.status}
                          />
                        )}
                        {item?.reviewer_value !== null && (
                          <TaskAssignee
                            assignee={item?.reviewer_value}
                            user="r"
                            status={item?.status}
                          />
                        )}
                        {item?.file_manager_value !== null &&
                          item?.file_manager_last_name !== null && (
                            <TaskAssignee
                              assignee={item?.file_manager_value}
                              user="fm"
                              status={item?.status}
                            />
                          )}
                        {item?.partner_value !== null && (
                          <TaskAssignee
                            assignee={item?.partner_value}
                            user="pr"
                            status={item?.status}
                          />
                        )}
                        {item?.partner_value !== null && (
                          <TaskAssignee
                            assignee={item?.partner_value}
                            user="pr"
                            status={item?.status}
                          />
                        )}
                        {item?.assistant_administrative_value !== null && (
                          <TaskAssignee
                            assignee={item?.assistant_administrative_value}
                            user="aa"
                            status={item?.status}
                          />
                        )}
                        {item?.assignee_value !== null && (
                          <TaskAssignee
                            assignee={item?.assignee_value}
                            user="as"
                            status={item?.status}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>

                  <div className="mt-2">
                    <ProgressBar progress={item.task_progress} />
                  </div>
                </CardBody>
              </Card>
            );
          })
        ) : (
          <>
            {loading ? (
              <Card className="mb-3">
                <CardBody>
                  <h5 className="font-size-14 mb-1 placeholder-glow">
                    <span className="placeholder col-6"></span>
                  </h5>
                  <p className="text-muted mb-1 placeholder-glow">
                    <span className="placeholder col-12"></span>
                  </p>
                  <p className="text-muted mb-0 placeholder-glow">
                    <span className="placeholder col-12"></span>
                  </p>
                </CardBody>
              </Card>
            ) : (
              <div className="mt-5">
                <NoDataFound icon="task" message="You have no tasks to do!" />
              </div>
            )}
          </>
        )}
      </SimpleBar>
    </React.Fragment>
  );
};

export default UserTaskList;
