import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper";
import * as url from "../helpers/url_helper";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class SplitPayrollCountry {
  async create(data: any) {
    const response = await axios
      .put(SERVER_URL + url.SPLIT_PAYROLL_COUNTRY.CREATE, data, {
        headers: getAxiosConfigWithToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async update(data: any) {
    const response = await axios
      .post(SERVER_URL + url.SPLIT_PAYROLL_COUNTRY.UPDATE, data, {
        headers: getAxiosConfigWithToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async delete(data: any) {
    const response = await axios
      .post(SERVER_URL + url.SPLIT_PAYROLL_COUNTRY.DELETE, data, {
        headers: getAxiosConfigWithToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async getAll(data: any) {
    const response = await axios
      .post(SERVER_URL + url.SPLIT_PAYROLL_COUNTRY.GET_ALL, data, {
        headers: getAxiosConfigWithToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }
}

export default new SplitPayrollCountry();
