import React, { FC, useState, useEffect, memo } from "react";
import { UncontrolledTooltip } from "reactstrap";

type AvatarProps = {
  id?: number;
  name: string;
  src?: string;
  size?: "lg" | "md" | "sm" | "xs";
};

const Avatar: FC<AvatarProps> = ({ id, name, src, size = "xs" }) => {
  const [config, setConfig] = useState<any>({
    color: "#ffffff",
    backgroundColor: "#FF0000",
    backgroundImage:
      "linear-gradient(to right, rgb(214, 245, 214) , rgb(179 234 179))",
  });

  const indentifier = id;
  const getInitials = (name: string) => {
    if (name) {
      let initials = name;
      const fullName = name.split(" ") as Array<any>;
      if (fullName?.length > 1) {
        initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
      } else {
        initials = fullName.shift().charAt(0);
      }
      return initials.toUpperCase();
    } else {
      return "";
    }
  };

  const stringToHslColor = (str: string, s: number, l: number) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    let h = hash % 360;
    return "hsl(" + h + ", " + s + "%, " + l + "%)";
  };

  useEffect(() => {
    const s = 60;
    const l = 90;
    const color = stringToHslColor(name, s, 40);
    const backgroundColor = stringToHslColor(name, s, l);
    const backgroundImage = `linear-gradient(to bottom right, ${backgroundColor} , ${stringToHslColor(
      name,
      s,
      80
    )}`;
    setConfig((conf: any) => ({
      ...conf,
      color,
      backgroundColor,
      backgroundImage,
    }));
  }, []);

  return (
    <>
      {src ? (
        <img
          src={src}
          className={`me-3 rounded-circle avatar-${size}`}
          alt={name ? getInitials(name) : ""}
          id={`avatar_${indentifier}`}
          style={{ border: "2px solid #e4e4e4" }}
        />
      ) : (
        name && (
          <div
            className={`me-3 rounded-circle avatar-${size}`}
            id={`avatar_${indentifier}`}
          >
            <span
              className="avatar-title rounded-circle font-size-14"
              style={config}
            >
              {name ? getInitials(name) : null}
            </span>
          </div>
        )
      )}
      {name && (
        <UncontrolledTooltip placement="bottom" target={`avatar_${indentifier}`}>
          {name}
        </UncontrolledTooltip>
      )}
    </>
  );
};

export default memo(Avatar);
