import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserProfile, select2Styles, yearsOnward } from '../../../helpers/functions_helper';
import { Month, noData, QuarterData, WeekOption } from '../../../utils/data';
import LoadingSpinner from '../../../components/Common/Loader';
import { secondsToTime, serialize } from '../../../utils/utils';
import { formatCurrency } from '../../../utils/formats';
import WidgetBasic from '../../../components/Widgets/Basic';
import { Card, Col, Row } from 'reactstrap';
import usersService from '../../../services/users.service';
import { useNavigate } from 'react-router-dom';

interface CountsProps extends CartProps {
    filters?: any;
    widgetId?: 'active_inactive' | 'resident_nonresident' | 'individual_company' | 'user_resident' | 'All';
}
const years = yearsOnward();

const Counts: FC<CountsProps> = ({ hideHeading, filters = null, widgetId = 'All' }) => {

    const { t } = useTranslation();
    const userObj = getUserProfile();
    const navigate = useNavigate();

    const inititalFilter = {
        week: null,
        quarter: null,
        month: null,
        year: null,
        user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_ID : null
    };

    const [loading, setLoading] = useState<boolean>(true);
    const [query, setQuery] = useState<string | null>(serialize(inititalFilter));
    const [filter, setFilter] = useState<any>(inititalFilter);
    const [data, setData] = useState<any>({
        active: 0,
        inactive: 0,
        resident: 0,
        non_resident: 0,
        individual: 0,
        company: 0,
        archieve: 0,
        expired: 0,
        non_expired: 0,
    })

    const getChartData = async () => {
        const result = await usersService.getChartData(query);
        if (result.data.status === 'success') {
            setData((data: any) => ({
                ...data,
                active: result?.data?.data?.active,
                inactive: result?.data?.data?.inactive,
                resident: result?.data?.data?.resident,
                non_resident: result?.data?.data?.non_resident,
                individual: result?.data?.data?.individual,
                company: result?.data?.data?.company,
                archieve: result?.data?.data?.archieve,
                expired: result?.data?.data?.expired,
                non_expired: result?.data?.data?.non_expired,
            }));
        }
        setLoading(false);
    }

    useEffect(() => {
        loading && getChartData();
    }, [loading])

    useEffect(() => {
        setQuery(serialize(filter));
        setLoading(true);
    }, [filter])

    useEffect(() => {
        filters && setFilter(filters)
    }, [filters])


    const viewUserList= (filter: any, type: any) => {
        let _filter ={
            type:'status',
            id:filter.id,
            label:filter.status
        }
        filters.setFilter(_filter);
        navigate('../masters/company/');
    }


    return (
        <>
            {(widgetId === 'All' || widgetId === 'active_inactive') &&
                <Col>
                    <WidgetBasic
                        variant='primary'
                        label={t("All users")}
                        value={(data?.active + data?.inactive)}
                        icon={<i className="fa fa-solid fa-file-invoice"></i>}
                        loading={loading}
                        seconday={[
                            {
                                label: `${t("Active")}`,
                                value: (data?.active)
                            },
                            {
                                label: `${t("Archieved")}`,
                                value: (data?.inactive)
                            }
                        ]}
                    />
                </Col>
            }
            {(widgetId === 'All' || widgetId === 'resident_nonresident') &&
                <Col>
                    <WidgetBasic
                        variant='warning'
                        label={t("All users")}
                        value={(data?.resident + data?.non_resident)}
                        icon={<i className="fa fa-solid fa-clipboard-check"></i>}
                        loading={loading}
                        seconday={[
                            {
                                label: `${t('Resident')}`,
                                value: (data?.resident)
                            },
                            {
                                label:  `${t('Non-resident')}`,
                                value: (data?.non_resident)
                            }
                        ]}
                    />
                </Col>
            }
            {(widgetId === 'All' || widgetId === 'individual_company') &&
                <Col>
                    <WidgetBasic
                        variant='info'
                        label={t("All users")}
                        value={(data?.individual + data?.company)}
                        seconday={[
                            {
                                label: `${t('Individual')}`,
                                value: (data?.individual)
                            },
                            {
                                label: `${t('Company')}`,
                                value: (data?.company)
                            }
                        ]}
                        icon={<span className="material-symbols-outlined icon">euro_symbol</span>}
                        loading={loading}
                    />
                </Col>
            }
            {(widgetId === 'All' || widgetId === 'user_resident') &&
                <Col>
                    <WidgetBasic
                        variant='success'
                        label={t("All users")}
                        value={(data?.expired + data?.non_expired)}
                        seconday={[
                            {
                                label: `${t('Expired')}`,
                                value: (data?.expired)
                            },
                            {
                                label: `${t('Non-expired')}`,
                                value: (data?.non_expired)
                            }
                        ]}
                        icon={<i className="fa fa-solid fa-calendar-check"></i>}
                        loading={loading}
                    />
                </Col>
            }
        </>
    )
}

export default Counts;