import {
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_ERROR,  

  GET_USER_PROFILE,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_ERROR,

  GET_USERS_LIST,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_ERROR,

  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,

  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,

  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,

  GET_USER_GENERAL,
  GET_USER_GENERAL_SUCCESS,
  GET_USER_GENERAL_ERROR,
} from "./actionTypes";

// ---------------------------------------------GET_USER
export const getUser = (users: any, history: any) => {
  return {
    type: GET_USER,
    payload: { users, history },
  };
};

export const getUserSuccess = (data: {}) => {
  return {
    type: GET_USER_SUCCESS,
    payload: data,
  };
};

export const getUserError = (error: {}) => {
  return {
    type: GET_USER_ERROR,
    payload: error,
  };
};

// ---------------------------------------------GET_USER_PROFILE
export const getUserProfile = () => {
  return {
    type: GET_USER_PROFILE,
    payload: {},
  };
};

export const getUserProfileSuccess = (data: {}) => {
  return {
    type: GET_USER_PROFILE_SUCCESS,
    payload: data,
  };
};

export const getUserProfileError = (error: {}) => {
  return {
    type: GET_USER_PROFILE_ERROR,
    payload: error,
  };
};

// ---------------------------------------------GET_USERS_LIST
export const getUsersList = (query: any, history: any) => {
  return {
    type: GET_USERS_LIST,
    payload: {query, history},
  };
};

export const getUsersListSuccess = (data: {}) => {
  return {
    type: GET_USERS_LIST_SUCCESS,
    payload: data,
  };
};

export const getUsersListError = (error: {}) => {
  return {
    type: GET_USERS_LIST_ERROR,
    payload: error,
  };
};

// ---------------------------------------------CREATE_USER
export const createUser = (users: any, history: any) => {
  return {
    type: CREATE_USER,
    payload: { users, history },
  };
};

export const createUserSuccess = (users: {}) => {
  return {
    type: CREATE_USER_SUCCESS,
    payload: users,
  };
};

export const createUserError = (error: {}) => {
  return {
    type: CREATE_USER_ERROR,
    payload: error,
  };
};

// ---------------------------------------------UPDATE_USER
export const updateUser = (users: any, history: any) => {
  return {
    type: UPDATE_USER,
    payload: { users, history },
  };
};

export const updateUserSuccess = (users: {}) => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: users,
  };
};

export const updateUserError = (error: {}) => {
  return {
    type: UPDATE_USER_ERROR,
    payload: error,
  };
};

// ---------------------------------------------DELETE_USER
export const deleteUser = (users: any, history: any) => {
  return {
    type: DELETE_USER,
    payload: { users, history },
  };
};

export const deleteUserSuccess = (users: {}) => {
  return {
    type: DELETE_USER_SUCCESS,
    payload: users,
  };
};

export const deleteUserError = (error: {}) => {
  return {
    type: DELETE_USER_ERROR,
    payload: error,
  };
};

// ------------------------------ --------------- USER GENERAL

export const getUserGeneral = (user_general: {}) => {
  return {
    type: GET_USER_GENERAL,
    payload: user_general
  }
};

export const getUserGeneralSuccess = (data: {}) => {
  return {
    type: GET_USER_GENERAL_SUCCESS,
    payload: data
  }
};

export const getUserGeneralError = (error: {}) => {
  return {
    type: GET_USER_GENERAL_ERROR,
    payload: error,
  };
};