import {
  GET_INVOICES_LIST,
  GET_INVOICES_LIST_SUCCESS,
  GET_INVOICES_LIST_ERROR,
} from "./actionTypes";

// ---------------------------------------------GET_INVOICES_LIST
export const getAllInvoices = (query: any, history: any) => {
  return {
    type: GET_INVOICES_LIST,
    payload: { query, history },
  };
};


export const setAllInvoices = (data: any) => {
  return {
    type: GET_INVOICES_LIST_SUCCESS,
    payload: data,
  };
};