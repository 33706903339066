import {
  GET_ACCESS_MODULE,
  GET_ACCESS_MODULE_SUCCESS,
  GET_ACCESS_MODULE_ERROR,

  GET_ACCESS_MODULES_LIST,
  GET_ACCESS_MODULES_LIST_SUCCESS,
  GET_ACCESS_MODULES_LIST_ERROR,

  CREATE_ACCESS_MODULE,
  CREATE_ACCESS_MODULE_SUCCESS,
  CREATE_ACCESS_MODULE_ERROR,

  UPDATE_ACCESS_MODULE,
  UPDATE_ACCESS_MODULE_SUCCESS,
  UPDATE_ACCESS_MODULE_ERROR,

  DELETE_ACCESS_MODULE,
  DELETE_ACCESS_MODULE_SUCCESS,
  DELETE_ACCESS_MODULE_ERROR,
} from "./actionTypes";

// ---------------------------------------------GET_ACCESS_MODULE
export const getAccessModule = (accessroles: any, history: any) => {
  return {
    type: GET_ACCESS_MODULE,
    payload: { accessroles, history },
  };
};

export const getAccessModuleSuccess = (data: {}) => {
  return {
    type: GET_ACCESS_MODULE_SUCCESS,
    payload: data,
  };
};

export const getAccessModuleError = (error: {}) => {
  return {
    type: GET_ACCESS_MODULE_ERROR,
    payload: error,
  };
};

// ---------------------------------------------GET_ACCESS_MODULES_LIST
export const getAccessModulesList = (query: any, history: any) => {
  return {
    type: GET_ACCESS_MODULES_LIST,
    payload: {query, history},
  };
};

export const getAccessModulesListSuccess = (data: {}) => {
  return {
    type: GET_ACCESS_MODULES_LIST_SUCCESS,
    payload: data,
  };
};

export const getAccessModulesListError = (error: {}) => {
  return {
    type: GET_ACCESS_MODULES_LIST_ERROR,
    payload: error,
  };
};

// ---------------------------------------------CREATE_ACCESS_MODULE
export const createAccessModule = (accessroles: any, history: any) => {
  return {
    type: CREATE_ACCESS_MODULE,
    payload: { accessroles, history },
  };
};

export const createAccessModuleSuccess = (accessroles: {}) => {
  return {
    type: CREATE_ACCESS_MODULE_SUCCESS,
    payload: accessroles,
  };
};

export const createAccessModuleError = (error: {}) => {
  return {
    type: CREATE_ACCESS_MODULE_ERROR,
    payload: error,
  };
};

// ---------------------------------------------UPDATE_ACCESS_MODULE
export const updateAccessModule = (accessroles: any, history: any) => {
  return {
    type: UPDATE_ACCESS_MODULE,
    payload: { accessroles, history },
  };
};

export const updateAccessModuleSuccess = (accessroles: {}) => {
  return {
    type: UPDATE_ACCESS_MODULE_SUCCESS,
    payload: accessroles,
  };
};

export const updateAccessModuleError = (error: {}) => {
  return {
    type: UPDATE_ACCESS_MODULE_ERROR,
    payload: error,
  };
};

// ---------------------------------------------DELETE_ACCESS_MODULE
export const deleteAccessModule = (accessroles: any, history: any) => {
  return {
    type: DELETE_ACCESS_MODULE,
    payload: { accessroles, history },
  };
};

export const deleteAccessModuleSuccess = (accessroles: {}) => {
  return {
    type: DELETE_ACCESS_MODULE_SUCCESS,
    payload: accessroles,
  };
};

export const deleteAccessModuleError = (error: {}) => {
  return {
    type: DELETE_ACCESS_MODULE_ERROR,
    payload: error,
  };
};
