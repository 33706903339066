import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../components/Layout/Breadcrumb';
import { getNewsFeed } from './helper';
import NewsFeedForm from './NewsFeedForm';

const EditNewsFeed = () => {
    const { t } = useTranslation();
    const navigite = useNavigate();
    const { id } = useParams();

    document.title = t("Edit News Feed ") + "| " + process.env.REACT_APP_NAME;

    const moduleHeading = t('Edit News Feed');

    const [data, setData] = useState<NewsFeedType>();

    const breadcrumbItems = useMemo(() => [
        {
            url: "/dashboard",
            label: t("Dashboard"),
            active: false,
        },
        {
            url: "/masters/news-feed",
            label: t("News Feed"),
            active: false,
        },
        {
            url: "#",
            label: moduleHeading,
            active: true,
        },
    ], [t]);

    const getData = async (id: number) => {
        const result = await getNewsFeed(id)
        if (result) {
            setData(result?.data)
        }
    }

    useEffect(() => {
        id && getData(parseInt(id, 10));
    }, [id])

    return (
        <div className={"page-content"}>
            <Container fluid>
                <Breadcrumbs
                    breadcrumbTitle={moduleHeading}
                    breadcrumbItems={breadcrumbItems}
                />
                <Row>
                    <Col xl="12">
                        <Card>
                            <CardBody>
                                <NewsFeedForm data={data} onSave={() => navigite('/masters/news-feed')} onCancel={() => navigite('/masters/news-feed')} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default EditNewsFeed;