import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  Label,
  Input as FInput,
  Button,
  FormFeedback,
  FormGroup,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import { getUserProfile } from "../../helpers/functions_helper";
import issueReportingService from "../../services/issue.reporting.service";
import { toast } from "react-toastify";
import html2canvas from "html2canvas";
import ImageHighlighterModal from "./ImageHighlighterModal";
import Compressor from 'compressorjs';
//import CustomSelect from "../../components/Common/CustomSelect";
import FileUploader from "./FileUploader";
import Input from "../../components/Form/Input";
import Tooltip from "../../components/Tooltip/Tooltip";
import { IssueCategories, Priority, incident_status } from "../../common/staticdata";

const ReportIssueModal = (props: any) => {
  const { t } = useTranslation();
  const issueReport: any = {};
  const userProfile = getUserProfile('incident');
  const [loading, setLoading] = useState<boolean>(false);
  const toastId = React.useRef(null) as any;
  const pageElement = document.getElementById('root') as HTMLElement | null;
  const [imageEdit, setImageEdit] = useState<boolean>(false);
  const [image, setImage] = useState<any>();
  const [files, setFiles] = useState<any>();
  const [imgScreenShot, setImgScreenShot] = useState<any>();







  const [initialValues, setInitialValues] = useState({
    id: '',
    incident_id: '',
    title: "",
    describe: '',
    priority: 'Highest',
    issue_type: 'Incident',
    fk_company_id: userProfile.USER_COMPANY,
    company_name: userProfile.USER_COMPANY,
    fk_user_id: userProfile.USER_ID,
    user_name: `${userProfile.USER_NAME} ${userProfile?.USER_LAST_NAME}`,
    email_id: '',
    url: window.location.href,
    history: '',
    status: 1,
    contact_me: '',
  });

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required("The title field is required")
      .min(10, "The title must be at least 10 characters")
      .max(100, "The title may not be greater than 100 characters"),
    describe: Yup.string()
      .required("The description field is required")
      .min(10, "The description must be at least 10 characters"),
    issue_type: Yup.string().required("The issue type field is required"),
  });

  const handleChange = (fieldName: string, fieldValue: any) => {
    setInitialValues((preState) => ({ ...preState, [fieldName]: fieldValue }));
  };

  const loadUser = async () => {
    const res = await issueReportingService.getUser();
    if (res.status === 'success') {
      setInitialValues(preState => ({
        ...preState,
        fk_user_id: res?.data?.user_id,
        user_name: res?.data?.full_name,
        email_id: res?.data?.email,
        fk_company_id: res?.data?.company_id,
        company_name: res?.data?.company_name,
      }))
    }
  }
  useEffect(() => { loadUser() }, [])


  function dataURLtoFile(dataurl: any, filename: any) {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  }


  const getScreenShot = () => {
    const box = document.querySelector('#root') as HTMLDivElement | null;
    html2canvas(box as any, { scale: 3 }).then(canvas => {
      let dataURL = canvas.toDataURL('image/jpg');

      setImageEdit(true);
      setImage(dataURL);
    });
  }

  const handleSubmit = async (values: {
    describe: string;
    title: string;
    priority: string;
    issue_type: string;
    fk_company_id: string,
    company_name: string,
    fk_user_id: string,
    user_name: string,
    email_id: string;
    url: string,
    history: string,
    status: number,
    contact_me: string
  }) => {

    try {
      toast.dismiss();

      toastId.current = toast(t("Creating incident..."), {
        autoClose: false,
        isLoading: true,
      });
      //setLoading(true);

      let fromData = new FormData();
      for (const [key, value] of Object.entries(values)) {
        fromData.append(key, value as any);
      }

      if (files) {
        //console.log(files);
        for (const file of files) {
          fromData.append("attachment[]", file);
        }
      }

      if (imgScreenShot) {
        fromData.append("attachment[]", imgScreenShot);
      }

      const res = await issueReportingService.create(fromData);

      if (res.status === "success") {
        toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: t('Incident created successfully'), autoClose: 5000, isLoading: false, delay: 500 });
        setLoading(false);
        setInitialValues({
          id: '',
          incident_id: '',
          title: '',
          describe: '',
          priority: t('Highest'),
          issue_type: t('Incident'),
          fk_company_id: userProfile?.USER_COMPANY,
          company_name: userProfile?.USER_COMPANY,
          fk_user_id: userProfile?.USER_ID,
          user_name: `${userProfile.USER_NAME} ${userProfile?.USER_LAST_NAME}`,
          email_id: userProfile?.USER_ID,
          url: window.location.href,
          history: '',
          status: 1,
          contact_me: '',
        });
        props.onCloseClick(true);
      }
      else { toast.update(toastId.current, { type: toast.TYPE.ERROR, render: 'Error', autoClose: 5000, isLoading: false, delay: 500 }); }

    } catch (error: any) {
      toast(error);
    }
  };

  function handleFileChange({ target: { files } }: any) {
    const cancel = !files.length;
    if (cancel) return;
    setFiles(files);
  }


  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    if (!validation.isSubmitting) return;
    if (Object.keys(validation.errors).length > 0) {
      document
        .getElementById(Object.keys(validation.errors)[0])
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
      document
        .getElementById(Object.keys(validation.errors)[0])
        ?.focus({ preventScroll: true });
    }
  }, [validation.isSubmitting, validation.errors]);


  const loadIncident = async (data: any) => {
    setInitialValues(preState => ({
      ...preState,
      issue_type: data?.issue_type,
      priority: data?.priority,
      title: data?.title,
      describe: data?.describe,
      company: data?.company_name,
      fk_company_id: data?.fk_company_id,
      fk_user_id: data?.fk_user_id,
      user_name: data?.user_name,
      email_id: data?.email_id,
    }))
  }

  // useEffect(() => {
  //   if (props.data) {
  //     console.log(props.data);
  //     //loadIncident(props.data);
  //   }
  // }, [props.data])


  const addIntoAttachment = (blob: any) => {
    let file = new File([blob], "attachment.jpg", { type: "image/jpeg", lastModified: new Date().getTime() });
    if (file)
      setImgScreenShot(file);
  }

  const clearScreenShot = () => {
    setImgScreenShot(null)
  }

  return (
    <React.Fragment>
      <ImageHighlighterModal
        isOpen={imageEdit}
        onCloseClick={() => setImageEdit(preState => !preState)}
        image={image}
        onUpdate={(imgfile: any) => addIntoAttachment(imgfile)}
      />
      <Modal
        size="md"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">{`${t('Give feedback for issue')}  ${t(window.location.pathname)}`}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label="Close"
          ></button>
        </div>

        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          autoComplete="off"
        >
          <ModalBody>
            <div>
              <p className="pl-1 mb-1">{t('Let us know how we can improve this experience.')}</p>
              <p className="pl-1">{t('If you’re having technical issues, contact our support team.')}</p>
            </div>
            <Row>
              <Col className="col-12 mb-1">
                <FInput
                  value={`${window.location.href}`}
                  disabled
                />
              </Col>
              <Col className="col-6 mb-1">
                <FInput
                  value={initialValues.user_name}
                  disabled
                />
              </Col>
              <Col className="col-6 mb-1">
                <FInput
                  value={initialValues.company_name}
                  disabled
                />
              </Col>
              <Col className="col-12 mb-3">
                <FInput
                  value={initialValues.email_id}
                  disabled
                />
              </Col>
            </Row>
            <Row>
              <Row style={{ paddingRight: 0 }}>
                <Col className="col-12" style={{ paddingRight: 0 }}>
                  <Label className="form-label">{t("Issue type")}{" "}<span className="text-warning is_required">*</span></Label>
                  <div className="mb-3">
                    <Select
                      id="issue_categories_id"
                      name="issue_categories_id"
                      placeholder={t("Select an option")}
                      className="is-invalid"
                      options={IssueCategories?.map((a: any) => ({ ...a, label: t(a.label) }))}
                      value={
                        initialValues.issue_type
                          ? IssueCategories.find(
                            (option: any) => option.value == initialValues.issue_type
                          )
                          : ""
                      }
                      isSearchable={true}
                      onChange={(e: any) => {
                        handleChange("issue_type", e.value);
                      }}
                    />

                    {validation.touched.issue_type && validation.errors.issue_type ? (
                      <FormFeedback type="invalid">
                        {validation.errors.issue_type}
                      </FormFeedback>
                    ) : null}
                  </div>
                </Col>
                <Col className="col-6" style={{ paddingRight: 0 }}>
                  <Label className="form-label">{t("Priority")}{" "}<span className="text-warning is_required">*</span></Label>
                  <div className="mb-3">
                    <Select
                      id="priority"
                      name="priority"
                      placeholder={t("Select an option")}
                      className="is-invalid"
                      options={Priority?.map((a: any) => ({ ...a, label: t(a.label) }))}
                      //components={{ Option: CustomSelect, }}
                      value={
                        initialValues.priority
                          ? Priority.find(
                            (option: any) => option.value == initialValues.priority
                          )
                          : ""
                      }
                      isSearchable={true}
                      onChange={(e: any) => {
                        handleChange("priority", e.value);
                      }}
                    />
                  </div>
                </Col>
                <Col className="col-6" style={{ paddingRight: 0 }}>
                  <Label className="form-label">{t("Status")}{" "}</Label>
                  <div className="mb-3">
                    <Select
                      id="priority"
                      name="priority"
                      placeholder={t("Select an option")}
                      className="is-invalid"
                      options={incident_status?.map((a: any) => ({ ...a, label: t(a.label) }))}
                      isDisabled
                      value={
                        initialValues.status
                          ? incident_status.find(
                            (option: any) => option.value == initialValues.status
                          )
                          : ""
                      }

                      isSearchable={true}
                      onChange={(e: any) => {
                        handleChange("priority", e.value);
                      }}
                    />
                  </div>
                </Col>
              </Row>
              <Col className="col-12 mb-3">
                <Input
                  id="title"
                  name="title"
                  className="form-control"
                  placeholder={t("Title")}
                  label={t("Title")}
                  type="text"
                  maxLength={100}
                  required
                  value={validation.values.title || ""}
                  autoFocus={true}
                  onKeyUp={validation.handleChange}
                  onChange={(e: any) => {
                    handleChange("title", e.target.value);
                  }}
                  invalid={
                    validation.touched.title &&
                      validation.errors.title
                      ? true
                      : false
                  }
                  error={validation.errors.title}
                  touched={validation.touched.title}
                />
              </Col>
              <Col className="col-12 mb-3">
                <Input
                  id="describe"
                  name="describe"
                  className="form-control"
                  placeholder={t("Description")}
                  label={t("Description")}
                  type="textarea"
                  required
                  value={validation.values.describe || ""}
                  autoFocus={true}
                  onKeyUp={validation.handleChange}
                  onChange={(e: any) => {
                    handleChange("describe", e.target.value);
                  }}
                  invalid={
                    validation.touched.describe &&
                      validation.errors.describe
                      ? true
                      : false
                  }
                  error={validation.errors.describe}
                  touched={validation.touched.describe}
                />
              </Col>
            </Row>
            <Row className="justify-content-center align-items-center">
              <Col sm="10">
                <FormGroup>
                  <FileUploader handleFile={handleFileChange} />
                </FormGroup>
              </Col>
              <Col style={{ paddingTop: '11px', textAlign: 'right' }}>
                <Tooltip content={t("Take Screenshot")} direction="bottom">
                  <Button
                    color="light"
                    className="btn-block btn-sm"
                    onClick={() => getScreenShot()}
                    style={{ height: '35px' }}
                  >
                    <span className="material-symbols-outlined">
                      add_a_photo
                    </span>
                  </Button>
                </Tooltip>
              </Col>
            </Row>
            <Row>
              <Col>
                {
                  imgScreenShot ? <><h5 className="font-size-13 text-truncate">{t("Screenshot")}</h5>
                    <a className="py-2 rounded bg-opacity-50 d-block mb-2 d-flex" href="#"><div className="flex-grow-1 d-flex"><span className="material-symbols-outlined fw-light">
                      image
                    </span>{imgScreenShot.name}</div><span className="material-symbols-outlined fw-light me-2 float-end" onClick={() => clearScreenShot()}>
                        delete
                      </span></a>
                  </> : null

                }
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup check>
                  <FInput
                    id="exampleCheck"
                    name="check"
                    type="checkbox"
                    onChange={(e: any) => {
                      handleChange("contact_me", e.target.checked ? 1 : 0);
                    }
                    }
                  />
                  <Label
                    check
                    for="exampleCheck"
                  >
                    {t("HR TAX ONE can contact me about this feedback")}
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              className="btn-block"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <span>
                  <i className="fa-solid fa-spinner fa-spin"></i>
                  <span>&nbsp; {t("Please wait...")}</span>
                </span>
              ) : (
                <span>{t("Submit")}</span>
              )}
            </Button>
            <Button
              color="outline-danger"
              className="btn-block"
              type="reset"
              onClick={props.onCloseClick}
              disabled={loading}
            >
              {t("Cancel")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default ReportIssueModal;
