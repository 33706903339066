import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import { boolean } from 'yup';
import './style.scss';

type MenusProps = {
    label: string;
    icon?: any;
    onClick?: any;
    disabled?: boolean;
    hidden?: boolean;
}

type KebabMenuProps = {
    label?: string;
    menus?: Array<MenusProps>

};

const KebabMenu: FC<KebabMenuProps> = ({ label, menus }) => {

    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

    const toggle = () => {
        setDropdownOpen((state: boolean) => !state);
    }

    return (
        <Dropdown group size="sm" direction='end' isOpen={dropdownOpen} toggle={toggle}>
            {label ? (
                <DropdownToggle caret tag="button" className='kebab-menu-button'>
                    {label}
                </DropdownToggle>
            ) : (
                <DropdownToggle caret tag="button" className='kebab-menu-button'>
                    <span className="align-middle material-symbols-outlined fw-light fs-4 text-muted">more_vert</span>
                </DropdownToggle>
            )}
            {dropdownOpen ? (menus ? (
                <DropdownMenu end>
                    {menus.map((menu: MenusProps, i: number) => (
                        !menu.hidden && <DropdownItem
                            key={i}
                            disabled={menu?.disabled || false}
                            onClick={() => {
                                menu?.onClick && menu?.onClick()
                            }}
                        >
                            {menu?.icon} {menu.label}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            ) : null)
                : null
            }

        </Dropdown>
    )
}

export default KebabMenu;
