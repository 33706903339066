import React, { RefObject, useState } from 'react';
import { TextMode } from '../entities';
//@ts-ignore
import { Rnd } from "react-rnd";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {
  index?: number;
  inputRef: RefObject<HTMLInputElement>;
  text?: string;
  mode: string;
  width: number;
  size?: number;
  height: number;
  lineHeight?: number;
  fontFamily?: string;
  toggleEditMode: () => void;
  onChangeText: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleResize: any;
  handleDrag: any;
  x?: any;
  y?: any;
  removeImage: any;
  editImage?: any;
  showContent?: any;
  attachment?:any;
  textOnly?:any;
}

export const Text: React.FC<Props> = ({
  text,
  index,
  width,
  height,
  inputRef,
  mode,
  size,
  fontFamily,
  onChangeText,
  toggleEditMode,
  lineHeight,
  handleResize,
  handleDrag,
  x,
  y,
  removeImage,
  editImage,
  showContent,
  textOnly
}) => {
  const { t } = useTranslation();
  const [editOpen, setEditOpen] = useState(false);
  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px dashed rgb(107, 117, 171)",
    background: "white"
  } as const;

  function renderRowHTML(rowContent: any) {
    return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
  }

  return (
    <Rnd
      style={style}
      default={{
        x: x,
        y: y,
        width: width,
        height: height,
      }}

      onDragStop={handleDrag}
      onResizeStop={handleResize}
      bounds="parent"
    >
      <Dropdown className="edit-attach" isOpen={editOpen} toggle={() => setEditOpen(!editOpen)}>
        <DropdownToggle className="arrow-none " tag="a">
          <span className="align-middle material-symbols-outlined fw-light fs-3 text-muted ">more_horiz</span>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={editImage}>
            <i className="fa-regular fa-pen-to-square"></i> {t("Edit")}
          </DropdownItem>
          <DropdownItem onClick={removeImage}>
            <i className="fa-regular fa-trash-can"></i> {t("Delete")}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      {!textOnly ?
        <div
          ref={inputRef}
          //contentEditable={true}
          className={`text-image-${index}`}
          onInput={onChangeText}
        >
          {renderRowHTML(text)}
        </div>
        :
        <input
          type="text"
          ref={inputRef}
          onChange={onChangeText}
          onClick={toggleEditMode}
          placeholder='Enter text here'
          style={{
            width: '100%',
            borderStyle: 'none',
            borderWidth: 0,
            fontFamily,
            fontSize: Math.min(width / 2, height / 2),
            outline: 'none',
            padding: 0,
            boxSizing: 'border-box',
            lineHeight,
            height: '100%',
            margin: 0,
            backgroundColor: 'transparent',
            cursor: mode === TextMode.COMMAND ? 'move' : 'text',
          }}
          value={text}
        />
      }
    </Rnd >
  );
};
