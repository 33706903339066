import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import DataTable from '../../../components/Common/DataTable';
import DeleteModal from '../../../components/Common/DeleteModal';
import Breadcrumbs from '../../../components/Layout/Breadcrumb';
import { getDataTableQuery, getSelectOptions, handleFilters } from '../../../helpers/functions_helper';
import { QuestionnaireTypeAction } from "../../../store/actions";
import service from "../../../services/masters.questionnaire.type.service";
import { toast } from "react-toastify";
// Import Breadcrumb

// Import Master Modal
import TabModal from "./Form";
import TabMenu from '../QuestionnaireTab/TabMenu';
import Drawer from '../../../components/Drawer';
import Filters from './Filters';
import ExportModel from './ExportModel';
import PreviewType from './PreviewType';
import { questionnaireTypes } from '../../../common/staticdata';
import { useTranslation } from 'react-i18next';
import { yesNoTag } from '../../../utils/utils';



const QuestionnaireType = (props: any) => {
    const {t} = useTranslation();
    const breadcrumbItems = [
        {
            url: "/dashboard",
            label: t("Dashboard"),
            active: false,
        },
        {
            url: "#",
            label: t("Questionnaire Type"),
            active: true,
        },
    ];
    const dispatch = useDispatch();
    const savedfilters = handleFilters("GET");
    document.title = `${t("Questionnaire")} | ${t("All Questionnaire type")} ${process.env.REACT_APP_NAME}`;
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [filters, setFilters] = useState(
        savedfilters && savedfilters?.filters
            ? (savedfilters?.filters as any)
            : {
                is_filtered: false,
                user_type: 0,
                year: "",
            }
    );
    const [loadingtablistData, setLoadingtablistData] = useState(true);
    const handleFiltersChange = (obj: any) => {
        setFilters((state: any) => ({ ...state, ...obj }));
    };
    const [previewTitle, setPreviewTitle] = useState(t("Preview questions"));

    const handleApplyFilters = () => {
        setLoadingtablistData(true);
        setOpenFilter(false);
    };

    // DataTable ----------------------------------------------
    function renderHeaderHTML(column: any, colIndex: any) {
        return <span dangerouslySetInnerHTML={{ __html: column.text }} />;
    }
    function renderRowHTML(rowContent: any, row: any) {
        return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
    }
    const [lng, setLng] = useState(localStorage.getItem("I18N_LANGUAGE"));
    useEffect(()=>{
        setLng(localStorage.getItem("I18N_LANGUAGE"));
        setLoadingtablistData(true);
        settablistData([]);
      },[localStorage.getItem("I18N_LANGUAGE")])


      const [taskTypeOptions, setTaskTypeOptions] =  useState<Array<any>>([]);
      useEffect(() => {
        getSelectOptions("tbl_master_fees", "description", "", [
          "description_french",
          "description_dutch",
          "description"
        ]).then(function (options) {
          setTaskTypeOptions(options);
        });
      }, []);
      

    const columns = [
        {
            dataField: "DT_RowIndex",
            text: t("Sr. No."),
            sort: true,
            search: false,
            classes: 'fix-col-left',
            headerClasses: 'fix-col-left',
            headerStyle: { width: '155px' },
            style: { width: '155px' },
        },
        {
            dataField: "questionnaire_type_value_html",
            text: `${t('Questionnaire type')}`,
            sort: true,
            search: true,
            classes: 'fix-col-left',
            headerClasses: 'fix-col-left',
            style: { left: '90px' },
            headerStyle: { left: '90px' },
            formatter: renderRowHTML,
        },
        {
            dataField: "task_type_value",
            text:`${t('Task type')}`,
            sort: true,
            search: true,
            formatter: renderRowHTML,
        },
        {
            dataField: "user_status_value",
            text: `${t('User status')}`,
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => {
                return t(row.user_status_value);
              },
        },
        {
            dataField: "language_value",
            text:  `${t('Language')}`,
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => {
                return t(row.language_value);
            },
        },
        {
            dataField: "year",
            text:  `${t('Year')}`,
            sort: true,
            search: true,
            formatter: renderRowHTML,
        },
        {
            dataField: "region",
            text:  `${t('Region')}`,
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => {
                return t(row.region);
            },
        },
        {
            dataField: "admin_template_html",
            text: `${t('One view template')}`,
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => {
                return renderRowHTML(
                  yesNoTag(row?.admin_template_html, row?.admin_template_html === 1 ? t("Yes") : t("No")),
                  null
                );
            },
        },
        {
            dataField: "view_all_html",
            text: `${t('View all')}`,
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => {
                return renderRowHTML(
                  yesNoTag(row?.view_all_html, row?.view_all_html === 1 ? t("Yes") : t("No")),
                  null
                );
            },
        },
        {
            dataField: "type",
            text: `${t('Type')}`,
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => {
                const filteredItem = questionnaireTypes.filter((item: any) => item.value === row.type)[0];
                if (filteredItem) {
                    return filteredItem.label;
                }
                return '';
            },
        },
        {
            dataField: "company_html",
            text: `${t('Company')}`,
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => {
                return renderRowHTML(
                  yesNoTag(row?.company_html, row?.company_html === 1 ? t("Yes") : t("No")),
                  null
                );
            },
        },
        {
            dataField: "individual_html",
            text:  `${t('Individual')}`,
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => {
                return renderRowHTML(
                  yesNoTag(row?.individual_html, row?.individual_html === 1 ? t("Yes") : t("No")),
                  null
                );
            },
        },
        {
            dataField: "active_html",
            text:  `${t('Status')}`,
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => {
                return (
                    <div className="d-flex gap-2 text-end">

                        {row.active == 1 ?
                            <span><span className="badge rounded-pill bg-primary badge_custome">{t('Active')}</span></span>
                            :
                            <span><span className="badge rounded-pill bg-warning badge_custome">{t('Inactive')}</span></span>
                        }
                    </div>
                );
            },
        },
        {
            dataField: "action",
            text:  `${t('Action')}`,
            sort: false,
            search: false,
            formatter: (rowContent: any, row: any): any => {
                return (
                    <div className="d-flex gap-2 text-end">
                        <Link
                            className="btn btn-pill btn-outline-primary btn-sm"
                            to="#"
                            onClick={() => onClickPreview(row)}
                        >
                            <i className="fa-regular fa-copy"></i> {t('Preview')}
                        </Link>
                        <Link
                            className="btn btn-pill btn-outline-primary btn-sm"
                            to="#"
                            onClick={() => onClickExport(row)}
                        >
                            <i className="fa-regular fa-copy"></i> {t('Export')}
                        </Link>
                        <Link
                            className="btn btn-pill btn-outline-primary btn-sm"
                            to="#"
                            onClick={() => onClickEdit(row)}
                        >
                            <i className="fa-regular fa-pen-to-square"></i> {t('Edit')}
                        </Link>
                        {row.active == 2 ?
                            <Link
                                className="btn btn-pill btn-outline-success btn-sm"
                                to="#"
                                onClick={() => onClickActivate(row, 1)}
                            >
                                <i className="fa-regular fa-copy"></i> {t('Activate')}
                            </Link>
                            :
                            <Link
                                className="btn btn-pill btn-outline-danger btn-sm"
                                to="#"
                                onClick={() => onClickActivate(row, 2)}
                            >
                                <i className="fa-regular fa-copy"></i> {t('Deactivate')}
                            </Link>
                        }
                        <Link
                            className="btn btn-pill btn-outline-danger btn-sm"
                            to="#"
                            onClick={() => onClickDelete(row)}
                        >
                            <i className="fa-regular fa-trash-can"></i> {t('Delete')}
                        </Link>
                    </div>
                );
            },
        },
    ];


    const [datatableOptions, setDatatableOptions] = useState(
        savedfilters && savedfilters?.datatableOptions
            ? (savedfilters?.datatableOptions as any)
            : {
                draw: 1,
                page: 1,
                recordsTotal: 0,
                recordsFiltered: 0,
                sizePerPage: 20,
                searchText: "",
                sortField: "id",
                sortOrder: "desc",
            }
    );

    const [tablistData, settablistData] = useState<Array<any>>([]);



    // get store data
    let data = useSelector((state: any) => ({
        success: state.QuestionnaireType.success,
        typelist: state.QuestionnaireType.GET_TYPE_LIST,
        questionslist: state.QuestionnaireQuestions.GET_QUESTIONS_LIST,
        error: state.QuestionnaireType.error,
        isModel: state.QuestionnaireType.isModel,
        loading: state.QuestionnaireType.loading,
    }));



    useEffect(() => {
        if (data.typelist?.data) {
            settablistData(data.typelist.data);

            const updatedDatatableOptions = {
                ...datatableOptions,
                recordsTotal: data.typelist.recordsTotal,
                recordsFiltered: data.typelist.recordsFiltered,
            };
            setDatatableOptions(updatedDatatableOptions);

            setLoadingtablistData(false);
        }
    }, [data.typelist]);

    const onTableChange = (type: any, newState: any) => {
        let draw = datatableOptions.draw + 1;
        let page = 1;
        if (type == "search") {
            page = 1;
        } else {
            page = newState.page;
        }

        const updatedDatatableOptions = {
            ...datatableOptions,
            draw: draw,
            page: page,
            sizePerPage: newState.sizePerPage,
            searchText: newState.searchText,
            sortField: newState.sortField,
            sortOrder: newState.sortOrder,
            scrollY: "300px",
            scrollX: true,
            scrollCollapse: true,
            fixedColumns: {
                left: 1,
                right: 1
            }
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingtablistData(true);
    };


    const clearFilter = () => {
        const updatedDatatableOptions = {
            ...datatableOptions,
            draw: 1,
            page: 1,
            sizePerPage: 20,
            searchText: "",
            sortField: "id",
            sortOrder: "desc",
        };
        setDatatableOptions(updatedDatatableOptions);

        const filter = {
            ...filters,
            is_filtered: false,
            user_type: 0,
            year: "",
        }
        setFilters(filter);

        setLoadingtablistData(true);
    };
    // Datatable -------------------------------------------------

    // Master Form --------------------------------------------------------
    const [statusData, setStatusData] = useState<any>();
    const [masterFormModal, setMasterFormModal] = useState(false);
    const [masterFormModalTitle, setMasterFormModalTitle] =
        useState<any>(t("Create Questionnaire type"));

    const onClickModal = () => {
        setStatusData({});
        setMasterFormModalTitle(t("Create Questionnaire type"));
        setMasterFormModal(true);
        setType("edit");
    };

    const [type, setType] = useState("");
    const onClickEdit = (row: any) => {
        setStatusData(row);
        setMasterFormModalTitle(t("Update Questionnaire type"));
        setMasterFormModal(true);
        setType("edit");
    };

    const onClickExport = (row: any) => {
        setExportModal(true);
        setStatusData(row);
        setType("export");
    };

    const onClickActivate = async (row: any, status: any) => {
        try {
            const res = (await service.QuestionnaireTypeActivate({ id: row.id,status:status })) as any;
            if (res.data.status === "success") {
            const messg = `${t("The questionnaire type is")} ${status==1? t("activated"): t("deactivated")} ${t("successfully")}`
                toast(messg, { type: toast.TYPE.SUCCESS })
                setLoadingtablistData(true);
            } else {
                toast(t('The questionnaire type is not activated successfully'), { type: toast.TYPE.ERROR })
                setLoadingtablistData(false);
            }
        } catch (err) {
            toast(t('The questionnaire type is not activated successfully'), { type: toast.TYPE.ERROR })
            setLoadingtablistData(false);
        }
    };

    const [previewModel, setPreviewModel] = useState(false);
    const onClickPreview = (row: any) => {
        setStatusData(row);
        setPreviewModel(true);
    };


    const handleSubmit = (values: any) => {
        if (values.id && values.questionnaire_type_id) {
            dispatch(
                QuestionnaireTypeAction.updateQuestionnaireType(values, props.history)
            );
        } else {
            dispatch(
                QuestionnaireTypeAction.addNewQuestionnaireType(values, props.history)
            );
        }
    };

    const handleExport = (values: any) => {
        if (values.id && values.questionnaire_type_id) {
            dispatch(
                QuestionnaireTypeAction.exportQuestionnaireType(values, props.history)
            );
        }
    };

    // Master Form --------------------------------------------------------

    // Delete Form --------------------------------------------------------
    const [deleteData, setDeleteData] = useState({
        id: "",
        questionnaire_type_id: "",
    });
    const [recordName, setRecordName] = useState("");
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (row: any) => {
        setDeleteModal(true);
        const deleteRowData = {
            ...deleteData,
            id: row.id,
            questionnaire_type_id: row.questionnaire_type_id,
        };
        setDeleteData(deleteRowData);
        setRecordName(row.questionnaire_type);
    };

    const handleDeleteData = () => {
        setLoadingtablistData(true);
        setDeleteModal(true);
        dispatch(
            QuestionnaireTypeAction.deleteQuestionnaireType(deleteData, props.history)
        );
    };
    // Delete Form --------------------------------------------------------

    useEffect(() => {
        if (data.success !== "") {
            toast(data.success, { type: toast.TYPE.SUCCESS })
            setMasterFormModal(false);
            setDeleteModal(false);
            setLoadingtablistData(true);
        }
        if (data.error !== "") {
            toast(data.error, { type: toast.TYPE.ERROR })
        }
    }, [data.success, data.error, dispatch]);

    useEffect(() => {
        if (loadingtablistData === true) {
            dispatch(
                QuestionnaireTypeAction.getQuestionnaireTypes(
                    { ...getDataTableQuery(datatableOptions, columns), filters },
                    props.history
                )
            );
        }
    }, [loadingtablistData]);

    //export
    const [exportModal, setExportModal] = useState(false);
    const onYesClick = () => {
        setExportModal(false);
        setMasterFormModalTitle(t("Export Questionnaire type"));
        setMasterFormModal(true);

    };
    const onNoClick = () => {
        setExportModal(false);
    };


    return (
        <React.Fragment>
            <div className="page-content">
                {deleteModal && (
                    <DeleteModal
                        recordName={recordName}
                        modalTitle={t("Questionnaire type")}
                        show={deleteModal}
                        onDeleteClick={handleDeleteData}
                        onCloseClick={() => setDeleteModal(false)}
                        loading={false}
                    />
                )}

                {masterFormModal && (
                    <TabModal
                        modalTitle={masterFormModalTitle}
                        show={masterFormModal}
                        loading={data.loading}
                        statusData={statusData}
                        onSubmitClick={handleSubmit}
                        onExportClick={handleExport}
                        onCloseClick={() => setMasterFormModal(false)}
                        type={type}
                    />
                )}
                {exportModal &&
                    <ExportModel
                        show={exportModal}
                        onCloseClick={() => setExportModal(false)}
                        onYesClick={onYesClick}
                        onNoClick={onNoClick}
                    />
                }
                <Container fluid>
                    <Breadcrumbs
                        breadcrumbTitle={t("Questionnaire")}
                        breadcrumbItems={breadcrumbItems}
                        endAdornment={
                                <Button
                                    color="primary"
                                    className="btn btn-primary btn-sm waves-light btn-label"
                                    onClick={() => onClickModal()}
                                >
                                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">add</span>
                                    {t("Create Questionnaire type")}
                                </Button>
                        }
                    />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardHeader className="bg-transparent border-bottom pb-0">
                                    <TabMenu type="type" />
                                </CardHeader>
                                <CardBody>
                                    <DataTable
                                        columns={columns}
                                        products={tablistData}
                                        datatableOptions={datatableOptions}
                                        filters={filters}
                                        onTableChange={onTableChange}
                                        loadingListData={loadingtablistData}
                                        tableName="tbl_master_questionnaire_tab"
                                        dependentField=""
                                        dependentId=""
                                        clearFilter={clearFilter}
                                        settingTableName="questionnaire_type"
                                        slug="all-questionnaires"
                                        customButtons={
                                            <Button
                                                outline
                                                color={filters.is_filtered === true ? "secondary" : "light"}
                                                onClick={() => setOpenFilter((state) => !state)}
                                                title={t("Filter")}
                                            >
                                                {filters.is_filtered === false ? (
                                                    <span className="align-middle material-symbols-outlined fw-light fs-4">filter_alt</span>
                                                ) : (
                                                    <span className="align-middle material-symbols-outlined fw-light fs-4">filter_alt_off</span>
                                                )}
                                            </Button>
                                        } />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Drawer
                title="Filters"
                open={openFilter}
                onClose={() => setOpenFilter((state) => !state)}
                onReset={clearFilter}
                footer={
                    <Button
                        color="primary"
                        style={{ margin: "16px" }}
                        onClick={handleApplyFilters}
                    >
                         {t("Apply")}
                    </Button>
                }
            >
                <Filters
                    filters={filters}
                    onApply={() => { }}
                    onClear={() => { }}
                    onChange={(obj: any) => handleFiltersChange({ ...obj, is_filtered: true })}
                />
            </Drawer>


            <Drawer
                title={t("Questionnaire Preview")}
                open={previewModel}
                onClose={() => setPreviewModel(false)}
                fullWidth
            >
                <PreviewType typeId={statusData?.id} />
            </Drawer>

        </React.Fragment>
    )
}

export default QuestionnaireType