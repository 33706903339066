import axios from "axios";
import { getAxiosConfigWithToken, getAxiosConfigFileUploadWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class CommonService {
    async getNotificationCount() {
        const response = await axios
            .get(SERVER_URL + url.GET_NOTIFICATION_COUNT,
                {
                    headers: getAxiosConfigWithToken(),
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getSelectOptionsList(tableName: string, fieldName: string, query?: string, otherFields?: Array<string> | null, condition?: Array<any> | null) {
        const response = await axios
            .get(SERVER_URL + url.GET_SELECT_OPTIONS_LIST + '/' + tableName + '/' + fieldName + '/' + query,
                {
                    headers: getAxiosConfigWithToken(),
                    params: { otherFields: otherFields, condition: condition }
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getSelectMapOptionsList() {
        const response = await axios
            .get(SERVER_URL + url.GET_SELECT_MAP_OPTIONS_LIST,
                {
                    headers: getAxiosConfigWithToken(),
                }
            ).then(function (response: any) {
                return response;
            })
            .catch(function (error: any) {
                return error;
            });
        return response;
    }

    async getSelectOptionsListOrder(tableName: string, fieldName: string) {
        const response = await axios
            .get(SERVER_URL + url.GET_SELECT_OPTIONS_LIST_ORDER + '/' + tableName + '/' + fieldName,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }


    async getSelectOptionsListWithOrder(tableName: string, fieldName: string, order_field?: string) {
        const response = await axios
            .get(SERVER_URL + url.GET_SELECT_OPTIONS_LIST_ORDER + '/' + tableName + '/' + fieldName + '/' + order_field,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getCustomeCode(tableName: string, module_code: string, length = 10) {
        const response = await axios
            .get(SERVER_URL + url.GET_CUSTOME_CODE + '/' + tableName + '/' + module_code + '/' + length,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getBinaryStream(fileName: string) {
        const response = await axios
            .get(SERVER_URL + url.GET_BINARY_STREAM + '/' + fileName,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getDependentSelectOptionsListWithOrder(tableName: string, fieldName: string, whereField: string, whereID: number, order_field: string, otherFields?: Array<string> | null) {
        const response = await axios
            .get(SERVER_URL + url.GET_DEPENDENT_SELECT_OPTIONS_LIST_WITH_ORDER + '/' + tableName + '/' + fieldName + '/' + whereField + '/' + whereID + '/' + order_field,
                {
                    headers: getAxiosConfigWithToken(),
                    params: { otherFields: otherFields }
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }


    async getDependentSelectOptionsList(tableName: string, fieldName: string, whereField: string, whereID: number, query: string) {
        const response = await axios
            .get(SERVER_URL + url.GET_DEPENDENT_SELECT_OPTIONS_LIST + '/' + tableName + '/' + fieldName + '/' + whereField + '/' + whereID + '/' + query,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }
    async getDependentUserOptionsList_New(tableName: string, fieldName: string, whereField: string, whereID: number, query: string) {
        const response = await axios
            .get(SERVER_URL + url.GET_DEPENDENT_USER_OPTIONS_LIST_NEW + '/' + tableName + '/' + fieldName + '/' + whereField + '/' + whereID + '/' + query,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getDependentAllFieldList(tableName: string, fieldName: string, whereField: string, whereID: any, query: string) {
        const response = await axios
            .get(SERVER_URL + url.GET_DEPENDENT_ALL_FIELD_SELECT_OPTIONS_LIST + '/' + tableName + '/' + fieldName + '/' + whereField + '/' + whereID + '/' + query,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }


    async getDependentWhereInSelectOptionsList(tableName: string, fieldName: string, whereField: string, whereId: number, whereField2: string, whereId2: number, whereinField: string, whereIds: number[], query: string) {
        const response = await axios
            .get(SERVER_URL + url.GET_DEPENDENT_WHERE_IN_SELECT_OPTIONS_LIST + '/' + tableName + '/' + fieldName + '/' + whereField + '/' + whereId + '/' + whereField2 + '/' + whereId2 + '/' + whereinField + '/' + whereIds + '/' + query,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }


    async getDependentMultipleWhereSelectOptionsList(tableName: string, fieldName: string, whereFields: any[], where_ids: any[], query: string, otherFields?: Array<string> | null,) {
        const response = await axios
            .get(SERVER_URL + url.GET_DEPENDENT_MULTIPLE_WHERE_SELECT_OPTIONS_LIST + '/' + tableName + '/' + fieldName + '/' + whereFields + '/' + where_ids + '/' + query,
                {
                    headers: getAxiosConfigWithToken(),
                    params: { otherFields: otherFields, where_ids: where_ids }
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getTaskByCompany(data: any) {
        const response = await axios
            .get(SERVER_URL + url.GET_TASK_BY_COMPANY,
                {
                    headers: getAxiosConfigWithToken(),
                    params: { ...data }
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }


    async changeDragPosition(tableName: string, fromPosition: number, toPosition: number, dependentField: string, depedentId: any) {
        let urlstring = '/' + tableName + '/' + fromPosition + '/' + toPosition + "/0/0";
        if (dependentField && depedentId) {
            urlstring = '/' + tableName + '/' + fromPosition + '/' + toPosition + '/' + dependentField + '/' + depedentId;
        }
        const response = await axios
            .get(SERVER_URL + url.CHANGE_TABLE_ORDER_ON_DRAG + urlstring,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getAttachmentList(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_ATTACHMENT_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async uploadAttachment(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.UPLOAD_ATTACHMENT, DATA,
                {
                    headers: getAxiosConfigFileUploadWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async uploadSignature(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.UPLOAD_SIGNATURE_MASTER, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }


    async getSignature(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_SIGNATURE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async uploadLink(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.UPLOAD_LINK, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async deleteAttachment(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.DELETE_ATTACHMENT, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getCommentList(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_COMMENT_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async createComment(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.CREATE_COMMENT, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async deleteComment(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.DELETE_COMMENT, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getUserOptionsList(otherFields?: Array<string>) {
        const response = await axios
            .get(SERVER_URL + url.GET_USER_OPTIONS_LIST,
                {
                    headers: getAxiosConfigWithToken(),
                    params: { otherFields: otherFields }
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }
    async getUserOptionsList_New(otherFields?: Array<string>) {
        const response = await axios
            .get(SERVER_URL + url.GET_USER_OPTIONS_LIST_NEW,
                {
                    headers: getAxiosConfigWithToken(),
                    params: { otherFields: otherFields }
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getCompanyOptionsList(otherFields?: Array<string>) {
        const response = await axios
            .get(SERVER_URL + url.GET_COMPANY_OPTIONS_LIST,
                {
                    headers: getAxiosConfigWithToken(),
                    params: { otherFields: otherFields }
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }
    async getCompanyOptionsList_New(otherFields?: Array<string>) {
        const response = await axios
            .get(SERVER_URL + url.GET_COMPANY_OPTIONS_LIST_NEW,
                {
                    headers: getAxiosConfigWithToken(),
                    params: { otherFields: otherFields }
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getFeesOptionsList() {
        const response = await axios
            .get(SERVER_URL + url.GET_FEES_OPTIONS_LIST,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }
    async getFeesOptionsList_New() {
        const response = await axios
            .get(SERVER_URL + url.GET_FEES_OPTIONS_LIST_NEW,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async uploadDocument(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.CREATE_DOCUMENT, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async downloadDocument(module_main_id: any, document_type_id: any, file_extension: any) {
        const AUTH_USER = localStorage.getItem("authUser");
        const ACCESS_TOKEN = JSON.parse(String(AUTH_USER));

        const response = await axios
            .get(SERVER_URL + url.DOWNLOAD_DOCUMENT + '/' + module_main_id + '/' + document_type_id,
                {
                    headers: {
                        "Content-Type": "application/" + file_extension,
                        "Access-Control-Allow-Origin": "*",
                        Authorization: "Bearer " + ACCESS_TOKEN.accessToken,
                    },
                    responseType: "blob",
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async downloadAttachment(attachment: any) {
        const AUTH_USER = localStorage.getItem("authUser");
        const ACCESS_TOKEN = JSON.parse(String(AUTH_USER));

        const response = await axios
            .get(SERVER_URL + url.DOWNLOAD_ATTACHMENT + '/' + attachment?.id + '/' + attachment?.attachment_id,
                {
                    headers: {
                        "Content-Type": "application/" + attachment?.file_extension,
                        "Access-Control-Allow-Origin": "*",
                        Authorization: "Bearer " + ACCESS_TOKEN.accessToken,
                    },
                    responseType: "blob",
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async deleteDocument(module_main_id: any, document_type_id: any, year: any) {
        const response = await axios
            .delete(SERVER_URL + url.DELETE_DOCUMENT + '/' + module_main_id + '/' + document_type_id + '/' + year,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async validateVatNr(vatNr: any) {
        const response = await axios
            .get(SERVER_URL + url.GET_VALIDATE_VAT_NR + '/' + vatNr,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async updateDashbaord(data: { user_id: number, widgets: string[] }) {
        const response = await axios
            .put(SERVER_URL + url.UPDATE_DASHBOARD, data,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getDashboard(userId: number) {
        const response = await axios
            .get(SERVER_URL + url.GET_DASHBOARD + '/' + userId,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getDashboardWidgets() {
        const response = await axios
            .get(SERVER_URL + url.GET_DASHBOARD_WIDGETS,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getCommuneName(pincode: string) {
        const response = await axios
            .get(SERVER_URL + url.GET_COMMUNE_NAME + '/' + pincode,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getCountryId(short_name: string) {
        const response = await axios
            .get(SERVER_URL + url.GET_COUNTRY_ID + '/' + short_name,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async handleArchive(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.HANDLE_ARCHIVE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getTaskSubtask(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_TASKS_SUBTASKS_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getUsersByMultipleCompanies(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_USERS_BY_MULTIPLE_COMPANIES_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }
}

export default new CommonService();