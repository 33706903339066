import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap";
import fileDownload from "js-file-download";
import { Buffer } from "buffer";
import axios from "axios";

// Import Breadcrumb
import Breadcrumbs from "../../components/Layout/Breadcrumb";

// Import DataTable
import DataTable from "../../components/Common/DataTable";
// Import Filters
import Filters from "./Filters";

import { getDataTableQuery, getUserProfile, handleFilters } from "../../helpers/functions_helper";
import { dateFormat } from "../../utils/formats";
import attachmentsService from "../../services/attachments.service";
import Drawer from "../../components/Drawer";
import EditUser from "../Users/EditUser";
import { useTranslation } from "react-i18next";
//Mail Action
import { setDraftEmailData } from "../../store/mails/actions";
import { useDispatch, useSelector } from "react-redux";
import EmailButton from "../Email/components/EmailButton";
import { getLangCode } from "../../common/staticdata";

const AllAttachments = (props: any) => {
  const { t, i18n } = useTranslation();
  document.title = `${t("File system")} | ` + process.env.REACT_APP_NAME;

  const userProfile = getUserProfile("file-system");
  const userAccess = getUserProfile("all-users");
  const savedfilters = handleFilters("GET");
  const [loader, setLoader] = useState(true);
  const [openUser, setOpenUser] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any>([]);
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "#",
      label: t("File system"),
      active: true,
    },
  ];

  // DataTable ----------------------------------------------
  function renderHeaderHTML(column: any, colIndex: any) {
    return <span dangerouslySetInnerHTML={{ __html: column.text }} />;
  }
  function renderRowHTML(rowContent: any, row: any) {
    return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
  }
  const dispatch = useDispatch();
  const onDragStart = (ev: any, data: any) => {
    ev.dataTransfer.setData("file", JSON.stringify(data));
  };

  const columns = [
    {
      dataField: "DT_RowIndex",
      text: t("Sr. No."),
      sort: false,
      search: false,
    },
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: true,
    //   search: true,
    // },
    {
      dataField: "file_name",
      text: t("File name"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return (
          <>
            <span
              id={row.file_name}
              onDragStart={(e) => onDragStart(e, row)}
              draggable
              className="me-2"
              dangerouslySetInnerHTML={{ __html: row.file_type }}
            />

            {row.type === "file" ? (
              <span
                className="link-primary"
                title={row.file_name}
                onClick={() => onClickDownload(row)}
              >
                {row.file_name.substring(0, 50)}
                {row.file_name.length >= 50 && "..."}
                {"."}
                {row.file_extension}
              </span>
            ) : (
              <a
                href={row.file_extension ? row.file_name_source : row.file_name}
                className="text-decoration-underline"
                target="_blank"
              >
                {row.file_extension === "" || row.file_extension === null ? (
                  row.short_description ? (
                    <>
                      {row.short_description.substring(0, 50)}
                      {row.short_description.length >= 50 && "..."}
                    </>
                  ) : (
                    <>
                      {row.file_name.substring(0, 50)}
                      {row.file_name.length >= 50 && "..."}
                    </>
                  )
                ) : (
                  <>
                    {row.file_name.substring(0, 50)}
                    {row.file_name.length >= 50 && "..."}
                    {row.file_extension && "." + row.file_extension}
                  </>
                )}
              </a>
            )}

            {row.version_count > 0 ? (
              <span className="badge bg-success ms-1" title="Version">
                {row.version_count}
              </span>
            ) : null}
          </>
        );
      },
    },
    {
      dataField: "short_description",
      text: t("Short description"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return row.file_extension !== "" && row.file_extension !== null
          ? renderRowHTML(row.short_description_html, null)
          : "";
      },
    },
    {
      dataField: "created_at",
      text: t("Uploaded on"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return dateFormat(
          row.created_at,
          {
            dateStyle: "long",
            timeStyle: "short",
            hour12: true,
          },
          getLangCode(localStorage.getItem("I18N_LANGUAGE"))
        );
      },
    },
    {
      dataField: "created_by_value",
      text: t("Uploaded by"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return userAccess.CAN_VIEW_ALL || userAccess.USER_ID === row.created_by_id ? (
          <div
            className="link-primary"
            onClick={() =>
              handleUserClick({ id: row.created_by_id, user_id: row.created_by_user_id })
            }
          >
            {row.created_by_value}
          </div>
        ) : (
          row.created_by_value
        );
      },
    },
    {
      dataField: "module_info_html",
      text: t("Source info"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return renderRowHTML(row.module_info_html, null);
      },
    },
    {
      dataField: "module_value",
      text: t("Source"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return (row.module_app_id === 1 || row.module_app_id === 11) &&
          (userAccess.CAN_VIEW_ALL || userAccess.USER_ID === row.created_by_id) ? (
          <Link
            className="btn btn-sm btn-outline-primary btn-rounded"
            to={`/${row.url_edit}${row.module_main_id}/${row.module_sub_id}${
              row.tab ? "?tab=" + row.tab : ""
            }`}
          >
            {t(row.module_value)}
          </Link>
        ) : row.module_app_id !== 1 && row.module_app_id !== 11 ? (
          <Link
            className="btn btn-sm btn-outline-primary btn-rounded"
            to={`/${row.url_edit}${row.module_main_id}/${row.module_sub_id}${
              row.tab ? "?tab=" + row.tab : ""
            }`}
          >
            {t(row.module_value)}
          </Link>
        ) : (
          t(row.module_value)
        );
      },
    },
    {
      dataField: "module_value",
      text: t("Action"),
      formatter: (rowContent: any, row: any): any => {
        return <EmailButton handlerCompose={() => composeMail(row)} />;
      },
    },
  ];

  const [loadingListData, setLoadingListData] = useState(true);
  const [datatableData, setDatatableData] = useState<any>([]);
  const [datatableOptions, setDatatableOptions] = useState(
    savedfilters && savedfilters?.datatableOptions
      ? (savedfilters?.datatableOptions as any)
      : {
          draw: 1,
          page: 1,
          recordsTotal: 0,
          recordsFiltered: 0,
          sizePerPage: 20,
          searchText: "",
          sortField: "id",
          sortOrder: "desc",
        }
  );
  const [filters, setFilters] = useState(
    savedfilters && savedfilters?.filters
      ? (savedfilters?.filters as any)
      : {
          is_filtered: false,
          file_types: "",
          from_date: "",
          to_date: "",
          company_id: "",
          user_id: "",
          source_id: "",
        }
  );

  const loadingDatatable = async () => {
    try {
      const res = (await attachmentsService.getAttachmentsList({
        ...getDataTableQuery(datatableOptions, columns),
        filters,
      })) as any;

      if (res.data.status === "success") {
        setDatatableData(res.data.data);

        const updatedDatatableOptions = {
          ...datatableOptions,
          recordsTotal: res.data.recordsTotal,
          recordsFiltered: res.data.recordsFiltered,
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingListData(false);
      } else {
        setDatatableData([]);

        const updatedDatatableOptions = {
          ...datatableOptions,
          recordsTotal: 0,
          recordsFiltered: 0,
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingListData(false);
      }
    } catch (err) {
      setDatatableData([]);

      const updatedDatatableOptions = {
        ...datatableOptions,
        recordsTotal: 0,
        recordsFiltered: 0,
      };
      setDatatableOptions(updatedDatatableOptions);
      setLoadingListData(false);
    }
  };

  useEffect(() => {
    if (loadingListData === true) {
      loadingDatatable();
    }
  }, [loadingListData]);

  useEffect(() => {
    setDatatableData([]);
    setLoadingListData(true);
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  const onTableChange = (type: any, newState: any) => {
    let draw = datatableOptions.draw + 1;
    let page = 1;
    if (type == "search") {
      page = 1;
    } else {
      page = newState.page;
    }
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: draw,
      page: page,
      sizePerPage: newState.sizePerPage,
      searchText: newState.searchText,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
    };
    setDatatableOptions(updatedDatatableOptions);
    setLoadingListData(true);
  };

  const clearFilter = () => {
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: 1,
      page: 1,
      sizePerPage: 20,
      searchText: "",
      sortField: "id",
      sortOrder: "desc",
    };
    setDatatableOptions(updatedDatatableOptions);

    const filter = {
      ...filters,
      is_filtered: false,
      file_types: "",
      from_date: "",
      to_date: "",
      company_id: "",
      user_id: "",
      source_id: "",
    };
    setFilters(filter);

    setLoadingListData(true);
  };
  // Datatable -------------------------------------------------

  // Download attachment ------------------------------------------------------------
  const onClickDownload = (row: any) => {
    const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const AUTH_USER = localStorage.getItem("authUser");
    const ACCESS_TOKEN = JSON.parse(String(AUTH_USER));
    axios({
      url:
        SERVER_URL +
        "/common/download-attachment/" +
        Buffer.from(String(row.id)).toString("base64") +
        "/" +
        Buffer.from(String(row.attachment_id)).toString("base64"),
      method: "GET",
      headers: {
        "Content-Type": "application/" + row.file_extension,
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + ACCESS_TOKEN.accessToken,
      },
      responseType: "blob",
    }).then((response: any) => {
      fileDownload(response.data, row.file_name + "." + row.file_extension);
    });
  };
  // Download attachment ------------------------------------------------------------

  const handleUserClick = (obj: any) => {
    setSelectedUser(obj);
    setOpenUser(true);
  };

  // Filters -------------------------------------------------
  const handleFiltersChange = (obj: any) => {
    setFilters((state: any) => ({ ...state, ...obj }));
  };

  const handleApplyFilters = () => {
    setLoadingListData(true);
    setOpenFilter(false);
  };
  // Filters -------------------------------------------------

  //Email compose
  const composeMail = (data: any) => {
    const filex = {
      id: data?.id,
      attachment_id: data?.attachment_id,
      file_extension: data?.file_extension,
      file_path: data?.file_path,
      label: data?.file_name,
      type: data?.type,
    };
    const draftMail = {
      title: t("New message"),
      filters: {},
      openMailBox: 1,
      file_system_attachment: [filex],
    };
    dispatch(setDraftEmailData(draftMail));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbTitle={t("File system")} breadcrumbItems={breadcrumbItems} />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <DataTable
                    columns={columns}
                    products={datatableData}
                    datatableOptions={datatableOptions}
                    filters={filters}
                    onTableChange={onTableChange}
                    loadingListData={loadingListData}
                    clearFilter={clearFilter}
                    settingTableName="attachments_table"
                    slug="file-system"
                    customButtons={
                      <Button
                        title={t("Filter")}
                        outline={filters.is_filtered === true ? false : true}
                        color={filters.is_filtered === true ? "secondary" : "light"}
                        onClick={() => setOpenFilter((state) => !state)}
                      >
                        {filters.is_filtered === false ? (
                          <span className="align-middle material-symbols-outlined fw-light fs-4">
                            filter_alt
                          </span>
                        ) : (
                          <span className="align-middle material-symbols-outlined fw-light fs-4">
                            filter_alt_off
                          </span>
                        )}
                      </Button>
                    }
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <>
          <Drawer
            title={t("User")}
            open={openUser}
            onClose={() => setOpenUser((state) => !state)}
            fullWidth
          >
            <EditUser
              id={selectedUser?.id}
              user_id={selectedUser?.user_id}
              type={1}
              isWindow={true}
            />
          </Drawer>
        </>
      </div>

      <Drawer
        title={t("Filters")}
        open={openFilter}
        onClose={() => setOpenFilter((state) => !state)}
        onReset={clearFilter}
        footer={
          <Button color="primary" style={{ margin: "16px" }} onClick={handleApplyFilters}>
            {t("Apply")}
          </Button>
        }
      >
        <Filters
          filters={filters}
          onApply={() => {}}
          onClear={() => {}}
          onChange={(obj: any) => handleFiltersChange(obj)}
        />
      </Drawer>
    </React.Fragment>
  );
};

export default AllAttachments;
