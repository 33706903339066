import React, { useState, useEffect } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
// import Select from "react-select";
import Select from "../../components/Form/Select/Select";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Form,
  Label,
  Input as InputNew,
  // Button,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  InputGroup,
  ModalFooter,
  Modal,
  ModalBody,
} from "reactstrap";
import Button from "../../components/Button/Button";
import labelPlugin from "flatpickr/dist/plugins/labelPlugin/labelPlugin";
import DatePicker from "../../components/Form/DatePicker/DatePicker";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Autocomplete from "react-google-autocomplete";
import InputMask from "react-input-mask";

// Static Data
import {
  honorificOptions,
  genderOptions,
  languageOptions,
  userTypeOptions,
  typeOptions,
  statusOptions,
  clientstatusOptions,
  civil_status_master,
  taxRegimeMaster,
  vatReasonOptions,
  YesNoMaster,
  YesNoMasters,
} from "../../common/staticdata";

import {
  calculateVatData,
  filteredByFunction,
  getInitials,
  getOptionValueById,
  getRandomPassword,
  checkPasswordStrength,
  getUserProfile,
  handleAddress,
  filteredOptions,
  archiveSetDisabled,
  filterCountryByLanguage,
  getSelectOptions,
  moduleAccess,
  datePickerI18,
  handleAutoSave,
} from "../../helpers/functions_helper";

// AvatarEditor
import AvatarEditor from "../../components/Common/AvatarEditor";

// Actions
import { CommonAction, UsersAction } from "../../store/actions";

// Service
import usersService from "../../services/users.service";
import commonService from "../../services/common.service";

// Import Breadcrumb
import Breadcrumbs from "../../components/Layout/Breadcrumb";

import { dateFormat } from "../../utils/formats";

import ScrollUp from "../../components/ScrollUpDown/ScrollUp";
import ScrollDown from "../../components/ScrollUpDown/ScrollDown";
import { useTranslation } from "react-i18next";
import NavItems from "./NavItems";

import azureService from "../../services/azure.service";
import { useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";

import { getAzureAccessToken } from "../../helpers/functions_helper";
import Tooltip from "../../components/Tooltip/Tooltip";

//Mail Action
import { setDraftEmailData } from "../../store/mails/actions";
import { draftmailProps } from "../Email/components/type";
import Dependents from "./Dependents";
import EmailButton from "../Email/components/EmailButton";
import Input from "../../components/Form/Input";
import Flatpickr from "../../components/Form/Flatpickr";
import { useCallbackPrompt } from '../../hooks/useCallbackPrompt'
import Alert from "../../components/Common/Alert";
import NavigationAlert from "../../components/Common/NavigationAlert";

const EditUser = (props: any) => {
  const { instance, accounts, inProgress } = useMsal();
  const isAzureAuthenticated = useIsAuthenticated();

  const { t } = useTranslation();
  document.title = `${t("User details")} | ${process.env.REACT_APP_NAME}`;
  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "/users/all-users",
      label: t("Users"),
      active: false,
    },
    {
      url: "#",
      label: t("Update user"),
      active: true,
    },
  ];

  const userProfile = getUserProfile("all-users");
  const toastId = React.useRef(null) as any;

  let { id, user_id, type } = useParams();
  const { isWindow } = props;
  if (!id || !user_id) {
    id = props?.id;
    user_id = props?.user_id;
  }

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [redirectCode, setRedirectCode] = useState("");
  const [dataNotFound, setDataNotFound] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [processModal, setProcessModal] = useState(false);
  const [azureModal, setAzureModal] = useState(false);
  const [companyId, setCompanyId] = useState(null);
  const [dependentsData, setDependentsData] = useState<any>([]);
  const localFormData = handleAutoSave("GET");
  const [autoSave, setAutoSave] = useState(false);
  const [autoSaveAlert, setAutoSaveAlert] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(autoSave);
  const [initialData, setInitialData] = useState<any>({
    id: "",
    user_id: "",
    code: "",
    custome_code: "",
    honorific: "",
    first_name: "",
    last_name: "",
    user_image: "",

    belgium_start_date: "",
    belgium_leave_date: "",
    social_security_number: "",

    title: "",
    is_disabled: "0",
    gender: "",
    language: "",
    email: "",
    email_alt: "",
    email_notification: 1,
    mobile: "",
    mobile_alt: "",

    news_letter_subscriber: 1,

    dependents: 0,
    dependents_count: 0,
    civil_status: "",
    spouse_first_name: "",
    spouse_last_name: "",
    spouse_dob: "",
    spouse_national_number: "",
    married_from: "",
    status_change: "",
    dependents_users: [],
    date_of_birth: "",
    birth_place: "",

    address: "",
    address_number: "",
    box: "",
    postal_code: "",
    commune: "",
    city: "",

    country: "",
    origin: "",
    nationality: "",

    user_type: "",
    company: "",
    type: "",
    function: "",
    status: "",
    client_status: "",

    preparer: "",
    reviewer: "",
    file_manager: "",
    signing_partner: "",
    assistant_administrative: "",

    category: "",
    TVW_Nr: "",
    vatcat: "",
    vat_percentage: 21,
    vat_code: "211400",
    to_apply_vat: 1,
    reason_for_vat: 1,

    password: "",
    password_confirmation: "",
    // password_strength: "",
    access_role: "",
    // access_module: "",
    is_archive: 0,
    start_date: "",
    end_date: "",

    tax_regime: 1,
    tax_regime_trigger: 0,
    tax_regime_start_date: "",
    // tax_regime_end_date: "",
    // tax_regime_due_date: "",
    tax_regime_internal_due_date_original: "",
    tax_regime_external_due_date_original: "",
    tax_regime_filing_date_original: "",
    tax_regime_approval_date_original: "",
    tax_regime_end_date_original: "",
    tax_regime_internal_due_date_extension: "",
    tax_regime_external_due_date_extension: "",
    tax_regime_filing_date_extension: "",
    tax_regime_approval_date_extension: "",
    tax_regime_end_date_extension: "",

    process_trigger: type == "process" ? 1 : 0,
    azure_invited: 0,

    created_by_first_name: "",
    created_by_last_name: "",
    created_at: "",
    updated_by_first_name: "",
    updated_by_last_name: "",
    updated_at: "",

    submit_btn_code: "update_add_new",
  });

  const handleRedirect = (isCancel = false) => {
    setAutoSave(false);
    handleAutoSave("CLEAR");

    if (isWindow) {
      return false;
    }
    if (isCancel === true) {
      return navigate("/users/all-users");
    }

    if (redirectCode === "update_add_new") {
      return navigate("/users/new-user?redirect=true");
    } else if (redirectCode === "update_close") {
      return navigate("/users/all-users?redirect=true");
    } else if (redirectCode === "update") {
      // return navigate("/users/user-details/" + insertId + "/" + insertSubId + "?redirect=true");
      window.scrollTo(0, 0);
    } else {
      return navigate("/users/all-users?redirect=true");
    }
  };

  useEffect(() => {
    if (redirectCode !== "") {
      handleRedirect();
    }
  }, [redirectCode]);

  const handleChange = (fieldName: string, fieldValue: any) => {
    if (initialData?.[fieldName] != fieldValue) {
      setInitialData({ ...initialData, [fieldName]: fieldValue });
      setAutoSave(true);
    }
  };

  const handleAddressChange = async (data: any) => {
    const addressData = await handleAddress(data);
    setInitialData((prevData: any) => ({
      ...prevData,
      address: addressData?.address,
      address_number: addressData?.address_number.substring(0, 10),
      box: addressData?.box.substring(0, 10),
      postal_code: addressData?.postal_code,
      commune: addressData?.commune,
      city: addressData?.city,
      country: addressData?.country,
    }));
  };

  const handleArchive = async () => {
    toastId.current = toast(t("Updating archive status..."), {
      autoClose: false,
      isLoading: true,
    });

    const res = (await commonService.handleArchive({
      id: initialData.id,
      table_name: "tbl_master_users",
      field_name: "is_archive",
      is_archive: initialData.is_archive,
    })) as any;

    if (res.data.status === "success") {
      handleChange("is_archive", initialData.is_archive === 1 ? 0 : 1);

      dispatch(CommonAction.getUserOptionsListSuccess(""));
      dispatch(CommonAction.getUserOptionsListSuccess_New(""));

      toast.update(toastId.current, {
        type: toast.TYPE.SUCCESS,
        render: res.data.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    } else {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: res.data.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
  };

  let { getCompanyOptionsList, getUserOptionsList } = useSelector((state: any) => ({
    getCompanyOptionsList: state.Common.GET_COMPANY_OPTIONS_LIST_SUCCESS,
    getUserOptionsList: state.Common.GET_USER_OPTIONS_LIST_SUCCESS,
  }));

  useEffect(() => {
    if (!getCompanyOptionsList) dispatch(CommonAction.getCompanyOptionsList());
    if (!getUserOptionsList) dispatch(CommonAction.getUserOptionsList());
  }, []);

  const phoneRegExp = /^(?:\+?\d{1}[ -]?[\d -][\d -]+)$/;
  const validationSchema = Yup.object().shape({
    custome_code: Yup.string()
      .required("Custome code field is required.")
      .min(3, "Custome code must be at least 3 characters.")
      .max(15, "Custome code may not be greater than 15 characters."),
    honorific: Yup.string().required("Title field is required."),
    first_name: Yup.string()
      .required("First name field is required.")
      .min(3, "First name must be at least 3 characters.")
      .max(25, "First name may not be greater than 25 characters."),
    belgium_start_date: Yup.string().required("Start date in Belgium field is required."),
    last_name: Yup.string()
      .required("Last name field is required.")
      .min(3, "Last name must be at least 3 characters.")
      .max(25, "Last name may not be greater than 25 characters."),
    gender: Yup.string().required("Gender field is required."),
    language: Yup.string().required("Language field is required."),
    civil_status: Yup.string().required("Civil status field is required."),
    email: Yup.string()
      .required("Email field is required.")
      .max(100, "Email may not be greater than 100 characters.")
      .email("Email must be a valid email address."),
    email_alt: Yup.string()
      .nullable()
      .max(100, "Alternative e-mail may not be greater than 100 characters.")
      .email("Alternative e-mail must be a valid email address.")
      .when(["email"], {
        is: (email: string) => email != "" && email != null,
        then: Yup.string().notOneOf(
          [Yup.ref("email")],
          "The email and alternative can not be same."
        ),
      }),
    mobile: Yup.string()
      .nullable()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(8, "Phone number must be at least 8 digits.")
      .max(15, "Phone number field accept only 15 digits."),
    mobile_alt: Yup.string()
      .nullable()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(8, "Phone number must be at least 8 digits.")
      .max(15, "Phone number field accept only 15 digits.")
      .when(["mobile"], {
        is: (mobile: string) => mobile != "" && mobile != null,
        then: Yup.string().notOneOf(
          [Yup.ref("mobile")],
          "Phone number and alternative can not be same."
        ),
      }),
    user_type: Yup.string().required("User type field is required."),
    company: Yup.string().when("user_type", {
      is: "2",
      then: Yup.string().required("Company field is required."),
    }),
    type: Yup.string().required("Type field is required."),
    function: Yup.string().when(["type", "company"], {
      is: (company: any, type: any) => company == "1" && type == "1",
      then: Yup.string().required("Function field is required."),
    }),
    status: Yup.string().when(["type", "company"], {
      is: (company: any, type: any) => company != "1" || (company == "1" && type != "1"),
      then: Yup.string().required("Status field is required."),
    }),

    file_manager: Yup.string().when("user_type", {
      is: "1",
      then: Yup.string().required("File manager field is required."),
    }),
    signing_partner: Yup.string().when("user_type", {
      is: "1",
      then: Yup.string().required("Partner field is required."),
    }),
    TVW_Nr: Yup.string()
      .min(4, "The TVA/BTW must be at least 4 characters.")
      .max(20, "The TVA/BTW number may not be greater than 15 characters.")
      .nullable(),
    bic_number_prime: Yup.string()
      // .required("BIC number prime field is required.")
      .matches(
        /^(?=(?:.{8}|.{11})$)[A-Z0-9]*$/,
        "BIC number prime must be 8 or 11 characters (CAP Alpha only)"
      )
      .nullable(),

    password: Yup.string()
      .nullable()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password must contain 8 characters, one uppercase, one lowercase, one number and one special case character from !, @, #, $, %, ^, & and * (other special charactors are not supported)"
      ),
    password_confirmation: Yup.string()
      .nullable()
      .oneOf([Yup.ref("password"), null], "Confirm password must match with password"),
    access_role: Yup.string().required("Access role field is required."),
    // access_module: Yup.string().required("The access module field is required."),
    start_date: Yup.string().required("Start date in application field is required."),
  });
  const onProcessClick = () => {
    update({ ...initialData, process_trigger: 0, submit_btn_code: "update" });
  };

  const onAzureClick = () => {
    setInitialData((prevData: any) => ({
      ...prevData,
      azure_invited: 1,
      submit_btn_code: "update",
    }));
    update({ ...initialData, azure_invited: 1, submit_btn_code: "update" });
    setProcessModal(true);
  };

  const update = async (values: usersPropsType) => {
    try {
      setLoading(true);
      toastId.current = toast(t("Updating user..."), {
        autoClose: false,
        isLoading: true,
      });

      const res = (await usersService.updateUser(values)) as any;
      if (res.data.status === "success") {
        setRedirectCode(res.data.data.submit_btn_code);

        dispatch(CommonAction.getUserOptionsListSuccess(""));
        dispatch(CommonAction.getUserOptionsListSuccess_New(""));

        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setProcessModal(false);
        setLoading(false);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        if (res.data.data) {
          Object.keys(res.data.data).map((key) => {
            toast(res.data.data[key][0], {
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              delay: 500,
            });
          });
        }
        setLoading(false);
      }
    } catch (err: any) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      if (type == "process" && isAzureAuthenticated === true && initialData.azure_invited === 0) {
        setAzureModal(true);
      } else if (type == "process" && initialData.process_trigger === 1) {
        setProcessModal(true);
      } else {
        update(values);
      }
    },
  });

  const [disabledTaxRegime, setDisabledTaxRegime] = useState<boolean>(true);
  useEffect(() => {
    if (initialData.belgium_start_date != "" && (parseInt(initialData.tax_regime) === 2 || parseInt(initialData.tax_regime) === 3)) {
      const tax_regime_start_date = moment(initialData.belgium_start_date).format("YYYY-MM-DD");
      setInitialData((prevData: any) => ({
        ...prevData,
        tax_regime_start_date: tax_regime_start_date,
      }));
      setDisabledTaxRegime(false);
    } else {
      setInitialData((prevData: any) => ({
        ...prevData,
        tax_regime_start_date: "",
        tax_regime_internal_due_date_original: "",
        tax_regime_external_due_date_original: "",
        tax_regime_filing_date_original: "",
        tax_regime_approval_date_original: "",
        tax_regime_end_date_original: "",
        tax_regime_internal_due_date_extension: "",
        tax_regime_external_due_date_extension: "",
        tax_regime_filing_date_extension: "",
        tax_regime_approval_date_extension: "",
        tax_regime_end_date_extension: "",
      }));
      setDisabledTaxRegime(true);
    }
  }, [initialData.tax_regime, initialData.belgium_start_date]);

  const [genderList, setGenderList] = useState<any>([]);
  const [userTypeList, setUserTypeList] = useState<any>([]);
  const [typeList, setTypeList] = useState<any>([]);
  const [statusList, setStatusList] = useState<any>([]);
  const [clientstatusList, setClientStatusList] = useState<any>([]);
  const [civilstatusList, setCivilStatusList] = useState<any>([]);
  const [taxRegimeList, setTaxRegimeList] = useState<any>([]);
  const [reasonsList, setReasonsList] = useState<any>([]);
  const [yesNoList, setYesNoList] = useState<any>([]);
  const [yesNoList2, setYesNoList2] = useState<any>([]);
  const [languageList, setLanguageList] = useState<any>([]);
  useEffect(() => {
    if (genderOptions) {
      const options = genderOptions?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setGenderList(options);
    }

    if (languageOptions) {
      const options = languageOptions?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setLanguageList(options);
    }

    if (userTypeOptions) {
      const options = userTypeOptions?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setUserTypeList(options);
    }

    if (typeOptions) {
      const options = typeOptions?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setTypeList(options);
    }

    if (statusOptions) {
      const Options = statusOptions?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setStatusList(Options);
    }

    if (clientstatusOptions) {
      const Options = clientstatusOptions?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setClientStatusList(Options);
    }

    if (civil_status_master) {
      const options = civil_status_master?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setCivilStatusList(options);
    }

    if (taxRegimeMaster) {
      const options = taxRegimeMaster?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setTaxRegimeList(options);
    }

    if (vatReasonOptions) {
      const Options = vatReasonOptions?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setReasonsList(Options);
    }

    if (YesNoMaster) {
      const Options = YesNoMaster?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setYesNoList(Options);
    }

    if (YesNoMasters) {
      const Options = YesNoMasters?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setYesNoList2(Options);
    }



  }, [localStorage.getItem("I18N_LANGUAGE")]);

  useEffect(() => {
    filterTypeOptions([3]);
  }, [typeList])


  const [taxRegimeUpdate, setTaxRegimeUpdate] = useState(false);
  useEffect(() => {
    if (initialData.tax_regime_start_date != "" && taxRegimeUpdate === true) {
      const tax_regime_internal_due_date_original = moment(initialData.tax_regime_start_date)
        .add(2, "months")
        .format("YYYY-MM-DD");
      const tax_regime_external_due_date_original = moment(initialData.tax_regime_start_date)
        .add(3, "months")
        .format("YYYY-MM-DD");
      const tax_regime_end_date_original = moment(initialData.tax_regime_start_date)
        .add(5, "years")
        .subtract(1, "day")
        .format("YYYY-MM-DD");

      const tax_regime_internal_due_date_extension = moment(initialData.tax_regime_start_date)
        .add(5, "years")
        .subtract(1, "day")
        .format("YYYY-MM-DD");
      const tax_regime_external_due_date_extension = moment(initialData.tax_regime_start_date)
        .add(5, "years")
        .subtract(1, "day")
        .add(3, "months")
        .format("YYYY-MM-DD");
      const tax_regime_end_date_extension = moment(initialData.tax_regime_start_date)
        .add(8, "years")
        .subtract(1, "day")
        .format("YYYY-MM-DD");

      setInitialData((prevData: any) => ({
        ...prevData,
        tax_regime_internal_due_date_original: tax_regime_internal_due_date_original,
        tax_regime_external_due_date_original: tax_regime_external_due_date_original,
        // tax_regime_filing_date_original: "",
        // tax_regime_approval_date_original: "",
        tax_regime_end_date_original: tax_regime_end_date_original,

        tax_regime_internal_due_date_extension: tax_regime_internal_due_date_extension,
        tax_regime_external_due_date_extension: tax_regime_external_due_date_extension,
        // tax_regime_filing_date_extension: "",
        // tax_regime_approval_date_extension: "",
        tax_regime_end_date_extension: tax_regime_end_date_extension,
      }));
    }
  }, [initialData.tax_regime_start_date, taxRegimeUpdate]);

  //Validate vat number

  //civil status change field
  const [isMarried, setIsMarried] = useState(false);
  const [isDivorced, setIsDivorced] = useState(false);
  const [isDepentdent, setIsDependent] = useState(false);
  useEffect(() => {
    if ([2, 3, 4].includes(initialData.civil_status)) {
      setIsMarried(true);
      setIsDivorced(false);
    } else if ([5, 6, 7].includes(initialData.civil_status)) {
      setIsDivorced(true);
      setIsMarried(false);
    } else {
      setIsMarried(false);
      setIsDivorced(false);
    }
  }, [initialData.civil_status]);
  //civil status change field
  // add depenents fields
  useEffect(() => {
    if (initialData.dependents_count > 0 && initialData.dependents == 1) {
      setIsDependent(true);
    } else {
      setIsDependent(false);
    }
  }, [initialData.dependents_count, initialData.dependents]);

  const [validateVat, setValidateVat] = useState<any>({
    valid: null,
    loading: false,
  });
  const handleValidate = async (vatNr: any) => {
    setValidateVat((state: any) => ({ ...state, loading: true }));

    const res = await commonService.validateVatNr(vatNr);
    if (res?.data?.status === "success") {
      if (res?.data?.data?.valid) {
        setValidateVat((state: any) => ({ ...state, valid: true }));
      }
    } else {
      setValidateVat((state: any) => ({ ...state, valid: false }));
    }
    setValidateVat((state: any) => ({ ...state, loading: false }));
  };
  useEffect(() => {
    setValidateVat({
      valid: null,
      loading: false,
    });
  }, [initialData.TVW_Nr]);
  //Validate vat number

  useEffect(() => {
    if (!validation.isSubmitting) return;
    if (Object.keys(validation.errors).length > 0) {
      document
        .getElementById(Object.keys(validation.errors)[0])
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
      document.getElementById(Object.keys(validation.errors)[0])?.focus({ preventScroll: true });
    }
  }, [validation.isSubmitting, validation.errors]);

  // get and set User API call -------------------------------------------------
  const get = async () => {
    try {
      setLoading(true);
      const res = (await usersService.getUser({
        id,
        user_id,
      })) as any;
      if (res.data.status === "success") {
        updateInitialData(res.data.data[0]);
        setLoading(false);
      } else {
        toast(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 5000,
          delay: 500,
        });
        setLoading(false);
        setDataNotFound(true);
        handleRedirect();
      }
    } catch (err: any) {
      toast(err?.message, {
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        delay: 500,
      });
      setLoading(false);
      handleRedirect();
    }
  };

  useEffect(() => {
    if (localFormData && localFormData?.initialData) {
      updateInitialData(localFormData?.initialData);
      setAutoSaveAlert(true);
    } else {
      get();
    }
  }, []);

  const [oldProfileImage, setOldProfileImage] = useState();

  function updateInitialData(data: any) {
    const updatedInitialData = {
      ...initialData,
      id: data.id,
      user_id: data.user_id,
      code: data.code,
      custome_code: data.custome_code,
      honorific: data.honorific == null ? "" : data.honorific,
      first_name: data.first_name,
      last_name: data.last_name,
      user_image: data.user_image,
      belgium_start_date: data?.belgium_start_date == null ? "" : data.belgium_start_date,
      belgium_leave_date: data?.belgium_leave_date == null ? "" : data.belgium_leave_date,
      social_security_number: data?.social_security_number == null ? "" : data.social_security_number,
      title: data.title,
      is_disabled: data.is_disabled == null || data.is_disabled == "" ? "0" : data.is_disabled,
      gender: data.gender,
      language: data.language,
      email: data.email,
      email_alt: data.email_alt == null ? "" : data.email_alt,
      email_notification: data.email_notification,
      news_letter_subscriber: data?.news_letter_subscriber,
      mobile: data.mobile == null ? "" : data.mobile,
      mobile_alt: data.mobile_alt == null ? "" : data.mobile_alt,

      dependents: data.dependents == null ? "" : data.dependents,
      dependents_count: data.dependents_count == null ? "" : data.dependents_count,
      civil_status: data.civil_status == null ? "" : data.civil_status,
      spouse_first_name: data.spouse_first_name == null ? "" : data.spouse_first_name,
      spouse_last_name: data.spouse_last_name == null ? "" : data.spouse_last_name,
      spouse_dob: data.spouse_dob == null ? "" : data.spouse_dob,
      spouse_national_number:
        data.spouse_national_number == null ? "" : data.spouse_national_number,
      married_from: data.married_from == null ? "" : data.married_from,
      status_change_from: data.status_change_from == null ? "" : data.status_change_from,
      dependents_users: data.dependents_users == null ? [] : data.dependents_users,
      date_of_birth: data.date_of_birth == null ? "" : data.date_of_birth,
      birth_place: data.birth_place == null ? "" : data.birth_place,

      address: data.address == null ? "" : data.address,
      address_number: data.address_number == null ? "" : data.address_number,
      box: data.box == null ? "" : data.box,
      postal_code: data.postal_code == null ? "" : data.postal_code,
      city: data.city == null ? "" : data.city,
      commune: data.commune == null ? "" : data.commune,

      country: data.country,
      origin: data.origin,
      nationality: data.nationality,

      user_type: data.user_type,
      company: data.company == null ? "" : data.company,
      type: data.type == null ? "" : data.type,
      function: data.function == null ? "" : data.function,
      status: data.status == null ? "" : data.status,
      client_status: data.client_status == null ? "1" : data.client_status,

      preparer: data.preparer == null ? "" : data.preparer,
      reviewer: data.reviewer == null ? "" : data.reviewer,
      file_manager: data.file_manager == null ? "" : data.file_manager,
      signing_partner: data.signing_partner == null ? "" : data.signing_partner,
      assistant_administrative:
        data.assistant_administrative == null ? "" : data.assistant_administrative,

      category: data.category == null ? "" : data.category,
      TVW_Nr: data.TVW_Nr == null ? "" : data.TVW_Nr,
      vatcat: data.vatcat == null ? "" : data.vatcat,
      vat_percentage: data?.vat_percentage === null ? 0 : data?.vat_percentage,
      vat_code: data?.vat_code === null ? 0 : data?.vat_code,
      to_apply_vat: data?.to_apply_vat === null ? 0 : data?.to_apply_vat,
      reason_for_vat: data?.reason_for_vat === null ? 0 : data?.reason_for_vat,

      // password: data.password,
      // password_confirmation: data.password_confirmation,
      access_role: data.access_role,
      // access_module: data.access_module,
      is_archive: data.is_archive,
      start_date: data.start_date,
      end_date: data.end_date,

      tax_regime: data.tax_regime == null ? "" : data.tax_regime,
      tax_regime_trigger: data.tax_regime_trigger == null ? "" : data.tax_regime_trigger,
      tax_regime_start_date: data.tax_regime_start_date == null ? "" : data.tax_regime_start_date,
      // tax_regime_end_date: data.tax_regime_end_date == null ? "" : data.tax_regime_end_date,
      // tax_regime_due_date: data.tax_regime_due_date == null ? "" : data.tax_regime_due_date,
      tax_regime_internal_due_date_original:
        data.tax_regime_internal_due_date_original == null
          ? ""
          : data.tax_regime_internal_due_date_original,
      tax_regime_external_due_date_original:
        data.tax_regime_external_due_date_original == null
          ? ""
          : data.tax_regime_external_due_date_original,
      tax_regime_filing_date_original:
        data.tax_regime_filing_date_original == null ? "" : data.tax_regime_filing_date_original,
      tax_regime_approval_date_original:
        data.tax_regime_approval_date_original == null
          ? ""
          : data.tax_regime_approval_date_original,
      tax_regime_end_date_original:
        data.tax_regime_end_date_original == null ? "" : data.tax_regime_end_date_original,
      tax_regime_internal_due_date_extension:
        data.tax_regime_internal_due_date_extension == null
          ? ""
          : data.tax_regime_internal_due_date_extension,
      tax_regime_external_due_date_extension:
        data.tax_regime_external_due_date_extension == null
          ? ""
          : data.tax_regime_external_due_date_extension,
      tax_regime_filing_date_extension:
        data.tax_regime_filing_date_extension == null ? "" : data.tax_regime_filing_date_extension,
      tax_regime_approval_date_extension:
        data.tax_regime_approval_date_extension == null
          ? ""
          : data.tax_regime_approval_date_extension,
      tax_regime_end_date_extension:
        data.tax_regime_end_date_extension == null ? "" : data.tax_regime_end_date_extension,

      process_trigger: data.process_trigger,
      azure_invited: data.azure_invited,

      created_by_first_name: data.created_by_first_name,
      created_by_last_name: data.created_by_last_name,
      created_at: data.created_at,
      updated_by_first_name: data.updated_by_first_name,
      updated_by_last_name: data.updated_by_last_name,
      updated_at: data.updated_at,
    };
    setInitialData(updatedInitialData);
    setCompanyId(data.company);

    if (data.user_image_data) {
      setOldProfileImage(data.user_image_data);
    }
    data?.dependents_users && setDependentsData(data?.dependents_users);
    window.scrollTo(0, 0);
  }

  // Get options list -------------------------------------------------

  const [loadingCountry, setLoadingCountry] = useState(true);
  const [countryOptions, setCountryOptions] = useState<Array<any>>([]);
  useEffect(() => {
    getSelectOptions("tbl_master_country", "name", "", [
      "is_europian_country",
      "name",
      "name_french",
      "name_dutch",
    ]).then(function (options) {
      let countryOptionsList = filterCountryByLanguage(options);
      setCountryOptions(countryOptionsList);
      setLoadingCountry(false);
    });
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  const [vatcatOptions, setVatcatOptions] = useState([]);
  useEffect(() => {
    getSelectOptions("tbl_vat_cat", "vatcat_name").then(function (options) {
      setVatcatOptions(options);
    });
  }, [initialData.country]);

  const [loadingCompany, setLoadingCompany] = useState(true);
  const [companyOptions, setCompanyOptions] = useState([]);
  useEffect(() => {
    if (getCompanyOptionsList.data) {
      setCompanyOptions(getCompanyOptionsList.data);
    }
  }, [getCompanyOptionsList]);

  const [preparerOptions, setPreparerOptions] = useState<Array<any>>([]);
  const [reviewerOptions, setReviewerOptions] = useState<Array<any>>([]);
  const [fileManagerOptions, setFileManagerOptions] = useState<Array<any>>([]);
  const [partnerOptions, setPartnerOptions] = useState<Array<any>>([]);
  const [assistantAdministrativeOptions, setAssistantAdministrativeOptions] = useState<Array<any>>(
    []
  );

  useEffect(() => {
    if (getUserOptionsList.data) {
      // Preparer
      let preparerData = archiveSetDisabled(getUserOptionsList.data, initialData.preparer) as any;
      setPreparerOptions(preparerData);

      // Reviewer
      let reviewerData = archiveSetDisabled(getUserOptionsList.data, initialData.reviewer) as any;
      setReviewerOptions(reviewerData);

      // File Manager
      let fileManagerData = archiveSetDisabled(
        getUserOptionsList.data,
        initialData.file_manager
      ) as any;
      setFileManagerOptions(fileManagerData);

      // Partners
      let partnerData = archiveSetDisabled(
        filteredByFunction(getUserOptionsList.data, [6]) as any,
        initialData.signing_partner
      );
      setPartnerOptions(partnerData);

      // Assistant Administrative
      let assistantData = archiveSetDisabled(
        filteredByFunction(getUserOptionsList.data, [1]) as any,
        initialData.assistant_administrative
      );
      setAssistantAdministrativeOptions(assistantData);
    }
  }, [
    getUserOptionsList,
    initialData.preparer,
    initialData.reviewer,
    initialData.file_manager,
    initialData.signing_partner,
    initialData.assistant_administrative,
  ]);

  const [typeFilteredOptions, setTypeFilteredOptions] = useState([]);
  useEffect(() => {
    filterTypeOptions([3]);
    dispatch(UsersAction.getUserGeneralSuccess(""));
  }, []);

  useEffect(() => {
    if (initialData.user_type == "2") {
      setLoadingCompany(false);

      filterTypeOptions([3]);

      initialData.preparer = "";
      initialData.reviewer = "";
      initialData.file_manager = "";
      initialData.signing_partner = "";
      initialData.TVW_Nr = "";
    } else {
      initialData.company = "";
      setLoadingCompany(true);

      // initialData.type = "2";
      filterTypeOptions([1, 3]);
    }
  }, [initialData.user_type, localStorage.getItem("I18N_LANGUAGE")]);

  const filterTypeOptions = (data: any) => {
    if (typeList) {
      var newData: any = [...typeList];
      newData.map((item: any, index: number) => {
        data.map((data_option: any) => {
          if (item.value == data_option) {
            newData[index] = {
              label: t(item.label),
              value: item.value,
              isDisabled: true,
            };
          }
        });
      });
      setTypeFilteredOptions(newData);
    }
  };

  const [loadingFunction, setLoadingFunction] = useState(true);
  const [functionOptions, setFunctionOptions] = useState([]);
  useEffect(() => {
    if (initialData.function != "" || initialData.company == "1") {
      if (functionOptions.length === 0) {
        getSelectOptions("tbl_master_user_function", "user_function_value").then(function (
          options
        ) {
          setFunctionOptions(options);
        });
      }
    }
  }, [initialData.function, initialData.company]);

  const [loadingStatus, setLoadingStatus] = useState(true);
  useEffect(() => {
    if (initialData.company == "1" && initialData.type == "1") {
      setLoadingFunction(false);
      initialData.status = "";
      setLoadingStatus(true);
    } else {
      initialData.function = "";
      setLoadingFunction(true);
      setLoadingStatus(false);
    }
  }, [initialData.type, initialData.company]);

  const [loadingAccessRole, setLoadingAccessRole] = useState(true);
  const [access_roleOptions, setAccessRoleOptions] = useState([]);
  useEffect(() => {
    getSelectOptions("tbl_access_roles", "name", "", ['applicable_for']).then(function (options) {
      setAccessRoleOptions(options);
    });
  }, []);

  const [new_access_roleOptions, setNewAccessRoleOptions] = useState<Array<any>>([]);
  useEffect(() => {
    setLoadingAccessRole(true);

    if (access_roleOptions.length > 0) {
      if (userProfile.USER_COMPANY !== 1) {
        let items = access_roleOptions.filter((a: any) => a.applicable_for != 1).map((o: any) => ({ ...o, label: t(o.label) }));
        setNewAccessRoleOptions(items);
        setLoadingAccessRole(false);
      } else {
        if (parseInt(initialData.company) === 1) {
          let items = access_roleOptions.filter((a: any) => a.applicable_for == 1).map((o: any) => ({ ...o, label: t(o.label) }));
          setNewAccessRoleOptions(items);
          setLoadingAccessRole(false);
        } else {
          let items = access_roleOptions.filter((a: any) => a.applicable_for != 1).map((o: any) => ({ ...o, label: t(o.label) }));
          setNewAccessRoleOptions(items);
          setLoadingAccessRole(false);
        }
      }
    }
  }, [
    initialData.company,
    userProfile.USER_COMPANY,
    userProfile.ACCESS_ROLE_ID,
    access_roleOptions,
    t
  ]);

  // const [loadingAccessModule, setLoadingAccessModule] = useState(true);
  // const [access_moduleOptions, setAccessModuleOptions] = useState([]);
  // useEffect(() => {
  //   getSelectOptions("tbl_master_access_module", "access_module_value").then(function (options) {
  //     setAccessModuleOptions(options);
  //   });
  // }, []);

  // const [new_access_moduleOptions, setNewAccessModuleOptions] = useState([]);
  // useEffect(() => {
  //   if (userProfile.USER_COMPANY !== 1) {
  //     let items = filteredOptions(access_moduleOptions, [userProfile.ACCESS_MODULE_ID]) as any;
  //     setNewAccessModuleOptions(items);
  //   } else {
  //     if (parseInt(initialData.company) === 1) {
  //       setNewAccessModuleOptions(access_moduleOptions);
  //     } else {
  //       var newData: any = [];
  //       access_moduleOptions.map((item: any, index: number) => {
  //         if (item.value !== 1) {
  //           newData.push(item);
  //         }
  //       });

  //       if (parseInt(initialData.access_module) === 1) {
  //         setInitialData((prevData: any) => ({
  //           ...prevData,
  //           access_module: "",
  //         }));
  //       }

  //       setNewAccessModuleOptions(newData);
  //     }
  //   }
  //   setLoadingAccessModule(false);
  // }, [
  //   initialData.company,
  //   userProfile.USER_COMPANY,
  //   userProfile.ACCESS_MODULE_ID,
  //   access_moduleOptions,
  // ]);

  // Profile image ------------------------------------------------------
  const onAvatarChange = (base64Data: string) => {
    if (base64Data) {
      initialData.user_image = base64Data;
    }
  };
  // Profile image ------------------------------------------------------

  const passwordAutoGenerate = () => {
    let new_password = getRandomPassword(15);
    let password_strength = checkPasswordStrength(new_password);

    setInitialData((prevData: any) => ({
      ...prevData,
      password: new_password,
      password_confirmation: new_password,
    }));

    navigator.clipboard.writeText(new_password);

    toast(
      t("Password successfully generated, It's strength is ") +
      password_strength +
      t(" and copied to clipboard also!"),
      {
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
        delay: 500,
      }
    );
  };

  // useEffect(() => {
  //   let password_strength = checkPasswordStrength(initialData.password);
  //   setInitialData((prevData: any) => ({
  //     ...prevData,
  //     password_strength: password_strength,
  //   }));
  // }, [initialData.password]);

  const handleAzureInvite = async () => {
    try {
      toastId.current = toast(t("Sending azure invitation..."), {
        autoClose: false,
        isLoading: true,
      });

      const res = (await azureService.inviteUser({
        email: initialData.email,
        accessToken: localStorage.getItem("accessTokenSSO"),
      })) as any;
      if (res.data.status === "success") {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        onAzureClick();
        setAzureModal(false);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
      }
    } catch (err: any) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
  };

  const dependents_count = Array.from(Array(100), (_, x) => {
    return { value: (x + 1).toString(), label: (x + 1).toString() };
  });

  useEffect(() => {
    if (partnerOptions?.length > 0 && initialData?.signing_partner) {
      const name =
        partnerOptions?.find((x: any) => x?.value === initialData?.signing_partner)?.label || " ";
      // handleChange("category", getInitials(name, false));

      setInitialData((prevData: any) => ({
        ...prevData,
        category: getInitials(name, false),
      }));
    }
  }, [initialData?.signing_partner]);

  useEffect(() => {
    if (countryOptions?.length > 0 && initialData?.country) {
      const isEuropean = countryOptions?.find(
        (x: any) => x?.value == initialData?.country
      )?.is_europian_country;
      const vatData = calculateVatData(initialData?.country, isEuropean, initialData.TVW_Nr);
      if (validateVat.valid !== false)
        setInitialData((intData: any) => ({
          ...intData,
          ...vatData,
        }));
    }
  }, [initialData.country, validateVat.valid, countryOptions?.length]);

  //Email compose
  const composeMail = () => {
    const user = {
      label: `${initialData?.first_name} ${initialData?.last_name}`,
      value: initialData?.email,
    };
    // const attachment_filter = {
    //   module_app_id
    //   module_main_id
    //   module_sub_id
    //   file_extension
    //   file_path
    //   type
    //   short_description
    //   file_description
    //   attachment_for_ids
    //   attachment_for_company_ids
    // }

    const draftMail: draftmailProps = {
      title: "New Message",
      filters: {},
      openMailBox: 1,
      to: [user],
    };
    dispatch(setDraftEmailData(draftMail));
  };


  useEffect(() => {
    const params = window.location.hash.substring(1);
    const boxes: any = document.getElementById(
      params
    );
    if (boxes) {
      boxes.scrollIntoView({ behavior: "smooth", block: "center" });
      boxes.focus();
    }
  }, [loading]);

  useEffect(() => {
    if (autoSave && !isWindow) { handleAutoSave("SET", initialData); }
  }, [initialData, autoSave, isWindow]);

  return (
    <React.Fragment>
      <ScrollUp />
      <ScrollDown />

      <Alert
        open={autoSaveAlert}
        title={t("Autosaved data restored!")}
        message={t("Your last autosaved data has been restored from the session. In order to avoid the data loss, you need either save it to retain the data or Cancel to loose the data.")}
        onClickOk={() => setAutoSaveAlert(false)}
      />
      <NavigationAlert
        open={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />

      <div className={isWindow ? "" : "page-content"}>
        <Container fluid>
          {!isWindow ? (
            <Breadcrumbs
              breadcrumbTitle={t("Update user")}
              breadcrumbItems={breadcrumbItems}
              endAdornment={
                isAzureAuthenticated === true ? (
                  <>
                    {companyId !== null && companyId !== 1 ? (
                      initialData.azure_invited === 0 ? (
                        <Button
                          onClick={handleAzureInvite}
                          color={"primary"}
                          className="btn btn-primary btn-sm waves-effect waves-light btn-label me-2"
                        >
                          <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 label-icon">
                            grid_view
                          </span>
                          {t("Azure AD - Invite")}
                        </Button>
                      ) : (
                        <Button
                          onClick={handleAzureInvite}
                          color={"secondary"}
                          className="btn btn-secondary btn-sm waves-effect waves-light btn-label me-2"
                        >
                          <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 label-icon">
                            grid_view
                          </span>
                          {t("Azure AD - Invited")}
                        </Button>
                      )
                    ) : null}

                    <EmailButton handlerCompose={() => composeMail()} />
                  </>
                ) : null
              }
            />
          ) : null}

          {!isWindow ? <NavItems id={id} user_id={user_id} /> : null}

          <Row>
            <Col xl="12">
              <Card className={`${!isWindow ? "border-top-0" : ""} rounded-0 rounded-bottom`}>
                <CardHeader className="d-none bg-transparent border-bottom">
                  <div className="d-sm-flex flex-wrap">
                    <CardTitle className="card-title text-uppercase">{t("Update user")}</CardTitle>
                  </div>
                </CardHeader>

                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                  autoComplete="off"
                >
                  <Input
                    autoComplete="false"
                    name="hidden"
                    type="text"
                    style={{ display: "none" }}
                  />
                  <Input id="id" name="id" type="hidden" value={initialData.id} />
                  <Input id="user_id" name="user_id" type="hidden" value={initialData.user_id} />
                  <Input id="code" name="code" type="hidden" value={initialData.code} />

                  <CardBody>
                    <Row>
                      <Col xl={3} lg={3} md={3} sm={12}>
                        {AvatarEditor ? (
                          <AvatarEditor
                            profileImage={oldProfileImage}
                            onAvatarChange={onAvatarChange}
                          />
                        ) : null}
                      </Col>

                      <Col xl={9} lg={9} md={9} sm={12}>
                        <Row>
                          <Col xl={2} lg={2} md={2} sm={12}>
                            <div className="mb-3">
                              <Input
                                id="custome_code"
                                name="custome_code"
                                placeholder={t("Account ID")}
                                label="Account ID"
                                required
                                type="text"
                                value={initialData.custome_code}
                                disabled={!userProfile.IS_INTERNAL ? true : false}
                                onBlur={validation.handleBlur}
                                onChange={(e) => handleChange("custome_code", e.target.value)}
                                invalid={
                                  validation.touched.custome_code && validation.errors.custome_code
                                    ? true
                                    : false
                                }
                                error={validation.errors.custome_code}
                                touched={validation.touched.custome_code}
                              />
                            </div>
                          </Col>

                          <Col xl={2} lg={2} md={2} sm={12}>
                            <div className="mb-3">
                              <Select
                                id="honorific"
                                name="honorific"
                                className="is-invalid"
                                placeholder={t("Title")}
                                label="Title"
                                required
                                options={honorificOptions}
                                value={
                                  initialData.honorific
                                    ? honorificOptions.find(
                                      (option: any) => option.value == initialData.honorific
                                    )
                                    : ""
                                }
                                isSearchable={true}
                                onBlur={() => validation.setFieldTouched("honorific", true)}
                                onChange={(e: any) => {
                                  handleChange("honorific", e.value);
                                }}
                                error={validation.errors.honorific}
                                touched={validation.touched.honorific}
                              />
                            </div>
                          </Col>

                          <Col xl={4} lg={4} md={4} sm={12}>
                            <div className="mb-3">
                              <Input
                                id="first_name"
                                name="first_name"
                                placeholder={t("First name")}
                                label="First name"
                                required
                                type="text"
                                autoFocus={true}
                                value={initialData.first_name}
                                onBlur={validation.handleBlur}
                                onChange={(e) => handleChange("first_name", e.target.value)}
                                invalid={
                                  validation.touched.first_name && validation.errors.first_name
                                    ? true
                                    : false
                                }
                                error={validation.errors.first_name}
                                touched={validation.touched.first_name}
                              />
                            </div>
                          </Col>

                          <Col xl={4} lg={4} md={4} sm={12}>
                            <div className="mb-3">
                              <Input
                                id="last_name"
                                name="last_name"
                                placeholder={t("Last name")}
                                label="Last name"
                                required
                                type="text"
                                value={initialData.last_name}
                                onBlur={validation.handleBlur}
                                onChange={(e) => handleChange("last_name", e.target.value)}
                                invalid={
                                  validation.touched.last_name && validation.errors.last_name
                                    ? true
                                    : false
                                }
                                error={validation.errors.last_name}
                                touched={validation.touched.last_name}
                              />
                            </div>
                          </Col>

                          <Col xl={4} lg={4} md={4} sm={12}>
                            <div className="mb-3">
                              <DatePicker
                                id="belgium_start_date"
                                name="belgium_start_date"
                                label={t("Start date in Belgium")}
                                required={true}
                                value={initialData.belgium_start_date}
                                error={validation.errors.belgium_start_date}
                                touched={validation.errors.belgium_start_date}
                                disabled={!userProfile.IS_INTERNAL}
                                options={{
                                  altInput: true,
                                  altFormat: "d/m/Y",
                                  dateFormat: "Y-m-d H:i:S",
                                  allowInput: true,
                                  plugins: [labelPlugin()],
                                  onChange: function (selectedDates, dateStr, instance) {
                                    handleChange("belgium_start_date", dateStr);
                                    setTaxRegimeUpdate(true);
                                  },
                                  onClose: function (selectedDates, dateStr, instance) {
                                    handleChange("belgium_start_date", dateStr);
                                  },
                                  locale: { firstDayOfWeek: 1 },
                                }}
                              />
                            </div>
                          </Col>

                          <Col xl={4} lg={4} md={4} sm={12}>
                            <div className="mb-3">
                              <DatePicker
                                id="belgium_leave_date"
                                name="belgium_leave_date"
                                label={t("Leave date from Belgium")}
                                required={false}
                                value={initialData.belgium_leave_date}
                                error={validation.errors.belgium_leave_date}
                                touched={validation.errors.belgium_leave_date}
                                disabled={!userProfile.IS_INTERNAL}
                                options={{
                                  altInput: true,
                                  altFormat: "d/m/Y",
                                  dateFormat: "Y-m-d H:i:S",
                                  allowInput: true,
                                  plugins: [labelPlugin()],
                                  onChange: function (selectedDates, dateStr, instance) {
                                    handleChange("belgium_leave_date", dateStr);
                                    setTaxRegimeUpdate(true);
                                  },
                                  onClose: function (selectedDates, dateStr, instance) {
                                    handleChange("belgium_leave_date", dateStr);
                                  },
                                  locale: { firstDayOfWeek: 1 },
                                }}
                              />
                            </div>
                          </Col>

                          <Col xl={4} lg={4} md={4} sm={12}>
                            <div className="mb-3">
                              <Label className="form-label">{t("National Number (TIN)")}</Label>
                              <InputMask
                                id="social_security_number"
                                name="social_security_number"
                                className="form-control"
                                placeholder="National Number (TIN)"
                                type="text"
                                mask="99.99.99-999.99"
                                value={initialData.social_security_number}
                                disabled={!userProfile.IS_INTERNAL}
                                onBlur={validation.handleBlur}
                                onChange={(e) =>
                                  handleChange("social_security_number", e.target.value)
                                }
                              />
                            </div>
                          </Col>

                          <Col xl={4} lg={4} md={4} sm={12}>
                            <div className="mb-3">
                              <Input
                                id="title"
                                name="title"
                                placeholder={t("Job title")}
                                label="Job title"
                                type="text"
                                value={initialData.title || ""}
                                onBlur={validation.handleBlur}
                                onChange={(e) => handleChange("title", e.target.value)}
                              />
                            </div>
                          </Col>

                          <Col xl={2} lg={2} md={2} sm={4}>
                            <div className="mb-3">
                              <Select
                                id="is_disabled"
                                name="is_disabled"
                                className="is-invalid"
                                label="Accessibility"
                                required
                                options={yesNoList2}
                                value={
                                  initialData.is_disabled
                                    ? yesNoList2?.find((option: any) => option.value == initialData.is_disabled)
                                    : ""
                                }
                                isSearchable={true}
                                onChange={(e: any) => {
                                  handleChange("is_disabled", e.value);
                                }}
                                error={validation.errors.is_disabled}
                                touched={validation.touched.is_disabled}
                              />
                            </div>
                          </Col>

                          <Col xl={2} lg={2} md={2} sm={12}>
                            <div className="mb-3">
                              <Select
                                id="gender"
                                name="gender"
                                className="is-invalid"
                                options={genderList}
                                label="Gender"
                                required
                                value={
                                  initialData.gender
                                    ? genderList?.find(
                                      (option: any) => option.value == initialData.gender
                                    )
                                    : ""
                                }
                                isSearchable={false}
                                onBlur={() => validation.setFieldTouched("gender", true)}
                                onChange={(e: any) => {
                                  handleChange("gender", e.value);
                                }}
                                error={validation.errors.gender}
                                touched={validation.touched.gender}
                              />
                            </div>
                          </Col>

                          <Col xl={4} lg={4} md={4} sm={12}>
                            <div className="mb-3">
                              <Select
                                id="language"
                                name="language"
                                className="is-invalid"
                                options={languageList}
                                label="Language"
                                required
                                value={
                                  initialData.language
                                    ? languageList?.find(
                                      (option: any) => option.value == initialData.language
                                    )
                                    : ""
                                }
                                isSearchable={false}
                                onBlur={() => validation.setFieldTouched("language", true)}
                                onChange={(e: any) => {
                                  handleChange("language", e.value);
                                }}
                                error={validation.errors.language}
                                touched={validation.touched.language}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col className="col-12">
                        <hr />
                      </Col>
                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <div className="input-group is-invalid">
                            <Flatpickr
                              id="date_of_birth"
                              name="date_of_birth"
                              className="form-control d-block"
                              type="text"
                              value={initialData.date_of_birth}
                              label="Date of Birth"
                              placeholder="DD/MM/YYYY"
                              options={{
                                altInput: true,
                                altFormat: "d/m/Y",
                                dateFormat: "Y-m-d H:i:S",
                                maxDate: "today",
                                allowInput: true,
                                plugins: [labelPlugin()],
                                onChange: function (selectedDates, dateStr, instance) {
                                  handleChange("date_of_birth", dateStr);
                                },
                                onClose: function (selectedDates, dateStr, instance) {
                                  handleChange("date_of_birth", dateStr);
                                },
                                locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                              }}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Input
                            id="birth_place"
                            name="birth_place"
                            placeholder={t("Birth place")}
                            label="Birth place"
                            type="text"
                            value={initialData.birth_place}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("birth_place", e.target.value)}
                          />
                        </div>
                      </Col>

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Select
                            id="civil_status"
                            name="civil_status"
                            className="is-invalid"
                            options={civilstatusList}
                            label="Civil status"
                            required
                            value={
                              initialData.civil_status
                                ? civilstatusList?.find(
                                  (option: any) => option.value == initialData.civil_status
                                )
                                : ""
                            }
                            isSearchable={false}
                            onChange={(e: any) => {
                              handleChange("civil_status", e.value);
                            }}
                            error={validation.errors.civil_status}
                            touched={validation.touched.civil_status}
                          />
                        </div>
                      </Col>
                      {!!isMarried && (
                        <>
                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <div className="input-group is-invalid">
                                <Flatpickr
                                  id="married_from"
                                  name="married_from"
                                  className="form-control d-block"
                                  label="Civil status legally from"
                                  type="text"
                                  value={initialData.married_from}
                                  placeholder="DD/MM/YYYY"
                                  options={{
                                    altInput: true,
                                    altFormat: "d/m/Y",
                                    dateFormat: "Y-m-d H:i:S",
                                    maxDate: "today",
                                    allowInput: true,
                                    plugins: [labelPlugin()],
                                    onChange: function (selectedDates, dateStr, instance) {
                                      handleChange("married_from", dateStr);
                                    },
                                    onClose: function (selectedDates, dateStr, instance) {
                                      handleChange("married_from", dateStr);
                                    },
                                    locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <Input
                                id="spouse_first_name"
                                name="spouse_first_name"
                                placeholder={t("Spouse's first name")}
                                label="Spouse's first name"
                                type="text"
                                value={initialData.spouse_first_name}
                                onBlur={validation.handleBlur}
                                onChange={(e) => handleChange("spouse_first_name", e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <Input
                                id="spouse_last_name"
                                name="spouse_last_name"
                                placeholder={t("Spouse's last name")}
                                label="Spouse's last name"
                                type="text"
                                value={initialData.spouse_last_name}
                                onBlur={validation.handleBlur}
                                onChange={(e) => handleChange("spouse_last_name", e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <div className="input-group is-invalid">
                                <Flatpickr
                                  id="spouse_dob"
                                  name="spouse_dob"
                                  className="form-control d-block"
                                  type="text"
                                  value={initialData.spouse_dob}
                                  placeholder="DD/MM/YYYY"
                                  label="Spouse's date of birth"
                                  options={{
                                    altInput: true,
                                    altFormat: "d/m/Y",
                                    dateFormat: "Y-m-d H:i:S",
                                    maxDate: "today",
                                    allowInput: true,
                                    plugins: [labelPlugin()],
                                    onChange: function (selectedDates, dateStr, instance) {
                                      handleChange("spouse_dob", dateStr);
                                    },
                                    onClose: function (selectedDates, dateStr, instance) {
                                      handleChange("spouse_dob", dateStr);
                                    },
                                    locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                                  }}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <Label className="form-label">{t("Spouse's national number")}</Label>
                              <InputMask
                                id="spouse_national_number"
                                name="spouse_national_number"
                                className="form-control"
                                placeholder={t("Spouse's national number")}
                                type="text"
                                mask="99.99.99-999.99"
                                value={initialData.spouse_national_number}
                                onBlur={validation.handleBlur}
                                onChange={(e) =>
                                  handleChange("spouse_national_number", e.target.value)
                                }
                              />
                            </div>
                          </Col>
                        </>
                      )}
                      {!!isDivorced && (
                        <Col xl={3} lg={3} md={3} sm={12}>
                          <div className="mb-3">
                            <div className="input-group is-invalid">
                              <Flatpickr
                                id="status_change"
                                name="status_change"
                                label="Civil status change from"
                                className="form-control d-block"
                                type="text"
                                value={initialData.status_change}
                                placeholder="DD/MM/YYYY"
                                options={{
                                  altInput: true,
                                  altFormat: "d/m/Y",
                                  dateFormat: "Y-m-d H:i:S",
                                  maxDate: "today",
                                  allowInput: true,
                                  plugins: [labelPlugin()],
                                  onChange: function (selectedDates, dateStr, instance) {
                                    handleChange("status_change", dateStr);
                                  },
                                  onClose: function (selectedDates, dateStr, instance) {
                                    handleChange("status_change", dateStr);
                                  },
                                  locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                                }}
                              />
                            </div>
                          </div>
                        </Col>
                      )}

                      <Col className="col-12">
                        <Row>
                          <Col xl={3} lg={3} md={3} sm={12}>
                            <Row>
                              <Col sm={4}>
                                <Label className="form-label">{t("Dependents")}</Label>

                                <div className="form-check mb-2">
                                  <Input
                                    id="dependents_yes"
                                    name="dependents"
                                    value="1"
                                    className="form-check-input"
                                    type="radio"
                                    checked={initialData.dependents == 1 ? true : false}
                                    onChange={() => handleChange("dependents", "1")}
                                  />
                                  <label className="form-check-label" htmlFor="dependents_yes">
                                    {t("Yes")}
                                  </label>
                                </div>

                                <div className="form-check mb-2">
                                  <Input
                                    id="dependents_no"
                                    name="dependents"
                                    value="0"
                                    className="form-check-input"
                                    type="radio"
                                    checked={initialData.dependents == 0 ? true : false}
                                    onChange={() => handleChange("dependents", "0")}
                                  />
                                  <label className="form-check-label" htmlFor="dependents_no">
                                    {t("No")}
                                  </label>
                                </div>
                              </Col>

                              {initialData.dependents == 1 && (
                                <Col sm={8}>
                                  <Select
                                    id="dependents_count"
                                    name="dependents_count"
                                    label="Dependents count"
                                    options={dependents_count}
                                    value={
                                      initialData.dependents_count
                                        ? dependents_count.find(
                                          (option: any) =>
                                            option.value == initialData.dependents_count
                                        )
                                        : ""
                                    }
                                    isSearchable={false}
                                    onChange={(e: any) => {
                                      handleChange("dependents_count", e.value);
                                    }}
                                  />
                                </Col>
                              )}
                            </Row>
                          </Col>

                          {!!isDepentdent && (
                            <Dependents
                              data={dependentsData}
                              dependent_count={initialData.dependents_count}
                              onChange={(data: any) => handleChange("dependents_users", data)}
                            />
                          )}
                        </Row>
                      </Col>

                      <Col className="col-12">
                        <hr />
                      </Col>

                      <Col className="col-12"></Col>

                      <Col xl={6} lg={6} md={6} sm={12}>
                        <div className="mb-3">
                          <Input
                            id="email"
                            name="email"
                            placeholder={t("Email")}
                            label="Email"
                            required
                            // disabled
                            type="email"
                            autoComplete="off"
                            value={initialData.email}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("email", e.target.value)}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                            error={validation.errors.email}
                            touched={validation.touched.email}
                          />
                        </div>
                      </Col>

                      <Col xl={6} lg={6} md={6} sm={12}>
                        <div className="mb-3">
                          <Input
                            id="email_alt"
                            name="email_alt"
                            placeholder={t("Alternative e-mail")}
                            label="Alternative e-mail"
                            type="email"
                            value={initialData.email_alt || ""}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("email_alt", e.target.value)}
                            invalid={
                              validation.touched.email_alt && validation.errors.email_alt
                                ? true
                                : false
                            }
                            error={validation.errors.email_alt}
                            touched={validation.touched.email_alt}
                          />
                        </div>
                      </Col>

                      {userProfile.IS_INTERNAL ? (
                        <>
                          <Col xl={6} lg={6} md={6} sm={12}>
                            <div className="mb-3">
                              <Label className="form-label">{t("Email notification")}</Label>
                              <div className="form-check mb-2">
                                <Input
                                  id="email_notification_yes"
                                  name="email_notification"
                                  value="1"
                                  className="form-check-input"
                                  type="radio"
                                  checked={initialData.email_notification == 1 ? true : false}
                                  onChange={() => handleChange("email_notification", "1")}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="email_notification_yes"
                                >
                                  {t("Yes")}
                                </label>
                              </div>

                              <div className="form-check mb-2">
                                <Input
                                  id="email_notification_no"
                                  name="email_notification"
                                  value="0"
                                  className="form-check-input"
                                  type="radio"
                                  checked={initialData.email_notification == 0 ? true : false}
                                  onChange={() => handleChange("email_notification", "0")}
                                />
                                <label className="form-check-label" htmlFor="email_notification_no">
                                  {t("No")}
                                </label>
                              </div>
                            </div>
                          </Col>
                          <Col xl={6} lg={6} md={6} sm={12}>
                            <div className="mb-3">
                              <Label className="form-label">{t("News letter")}</Label>
                              <div className="form-check mb-2">
                                <Input
                                  id="news_letter_subscriber_yes"
                                  name="news_letter_subscriber"
                                  value="1"
                                  className="form-check-input"
                                  type="radio"
                                  checked={initialData.news_letter_subscriber == 1 ? true : false}
                                  onChange={() => handleChange("news_letter_subscriber", "1")}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="news_letter_subscriber_yes"
                                >
                                  {t("Subscribe")}
                                </label>
                              </div>

                              <div className="form-check mb-2">
                                <Input
                                  id="news_letter_subscriber_no"
                                  name="news_letter_subscriber"
                                  value="0"
                                  className="form-check-input"
                                  type="radio"
                                  checked={initialData.news_letter_subscriber == 0 ? true : false}
                                  onChange={() => handleChange("news_letter_subscriber", "0")}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="news_letter_subscriber_no"
                                >
                                  {t("Un-subscribe")}
                                </label>
                              </div>
                            </div>
                          </Col>
                        </>
                      ) : null}

                      <Col className="col-12"></Col>

                      <Col xl={6} lg={6} md={6} sm={12}>
                        <div className="mb-3">
                          <Input
                            id="mobile"
                            name="mobile"
                            placeholder={t("Phone number")}
                            label="Phone number"
                            type="tel"
                            value={initialData.mobile || ""}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("mobile", e.target.value)}
                            invalid={
                              validation.touched.mobile && validation.errors.mobile ? true : false
                            }
                            error={validation.errors.mobile}
                            touched={validation.touched.mobile}
                          />
                        </div>
                      </Col>

                      <Col xl={6} lg={6} md={6} sm={12}>
                        <div className="mb-3">
                          <Input
                            id="mobile_alt"
                            name="mobile_alt"
                            placeholder={t("Alternative phone number")}
                            label="Alternative phone number"
                            type="tel"
                            value={initialData.mobile_alt || ""}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("mobile_alt", e.target.value)}
                            invalid={
                              validation.touched.mobile_alt && validation.errors.mobile_alt
                                ? true
                                : false
                            }

                            error={validation.errors.mobile_alt}
                            touched={validation.touched.mobile_alt}
                          />
                        </div>
                      </Col>

                      <Col className="col-12"></Col>

                      <Col className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">{t("Address")}</Label>
                          {/* <Input
                                id="address"
                                name="address"
                                className="form-control"
                                placeholder="Address"
                                type="textarea"
                                value={initialData.address}
                                onBlur={validation.handleBlur}
                                onChange={(e) => handleChange("address", e.target.value)}
                                invalid={
                                  validation.touched.address && validation.errors.address
                                    ? true
                                    : false
                                }
                              /> */}
                          <Autocomplete
                            id="address"
                            name="address"
                            className={"form-control"}
                            value={initialData.address}
                            onBlur={validation.handleBlur}
                            onChange={(e: any) => handleChange("address", e.target.value)}
                            apiKey={process.env.REACT_APP_GOOGLE_ADDRESS_API_KEY!}
                            options={{
                              types: ["geocode", "establishment"],
                            }}
                            onPlaceSelected={(data: any) => {
                              handleAddressChange(data);
                            }}
                          />

                          {validation.touched.address && validation.errors.address ? (
                            <FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Input
                            id="address_number"
                            name="address_number"
                            className="form-control"
                            placeholder={t("Number")}
                            label="Number"
                            type="text"
                            maxLength={10}
                            value={initialData.address_number}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("address_number", e.target.value)}
                            invalid={
                              validation.touched.address_number && validation.errors.address_number
                                ? true
                                : false
                            }

                            error={validation.errors.address_number}
                            touched={validation.touched.address_number}
                          />
                        </div>
                      </Col>

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Input
                            id="box"
                            name="box"
                            className="form-control"
                            placeholder={t("Box number")}
                            label="Box number"
                            type="text"
                            maxLength={10}
                            value={initialData.box}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("box", e.target.value)}
                            invalid={validation.touched.box && validation.errors.box ? true : false}
                            error={validation.errors.box}
                            touched={validation.touched.box}
                          />
                        </div>
                      </Col>

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Input
                            id="postal_code"
                            name="postal_code"
                            className="form-control"
                            placeholder={t("Postal code")}
                            label="Postal code"
                            type="text"
                            maxLength={30}
                            value={initialData.postal_code}
                            onBlur={(e) => {
                              // getCommuneName();
                              validation.handleBlur(e);
                            }}
                            onChange={(e) => handleChange("postal_code", e.target.value)}
                            invalid={
                              validation.touched.postal_code && validation.errors.postal_code
                                ? true
                                : false
                            }
                            error={validation.errors.postal_code}
                            touched={validation.touched.postal_code}
                          />

                        </div>
                      </Col>

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          {/* <Select
                                id="commune"
                                name="commune"
                                className="form-control"
                                placeholder="Commune"
                                type="text"
                                maxLength={100}
                                value={initialData.commune}
                                onChange={(e) => handleChange("commune", e.target.value)}
                              /> */}
                          <Input
                            id="commune"
                            name="commune"
                            className="form-control"
                            placeholder={t("City")}
                            label="City"
                            type="text"
                            value={initialData.commune}
                            disabled={true}
                          />
                        </div>
                      </Col>

                      <Col className="col-12"></Col>
                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Input
                            id="city"
                            name="city"
                            className="form-control"
                            placeholder={t("Region")}
                            label="Region"
                            type="text"
                            maxLength={100}
                            value={initialData.city}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("city", e.target.value)}
                            invalid={
                              validation.touched.city && validation.errors.city ? true : false
                            }
                            error={validation.errors.city}
                            touched={validation.touched.city}
                          />
                        </div>
                      </Col>

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Select
                            id="country"
                            name="country"
                            className="is-invalid"
                            label="Country"
                            options={countryOptions}
                            value={
                              initialData.country
                                ? countryOptions.find(
                                  (option: any) => option.value == initialData.country
                                )
                                : ""
                            }
                            isSearchable={true}
                            isDisabled={loadingCountry}
                            isLoading={loadingCountry}
                            onBlur={() => validation.setFieldTouched("country", true)}
                            onChange={(e: any) => {
                              handleChange("country", e.value);
                            }}
                          />
                        </div>
                      </Col>

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Select
                            id="origin"
                            name="origin"
                            label="Country of origin"
                            className="is-invalid"
                            options={countryOptions}
                            value={
                              initialData.origin
                                ? countryOptions.find(
                                  (option: any) => option.value == initialData.origin
                                )
                                : ""
                            }
                            isSearchable={true}
                            isDisabled={loadingCountry}
                            isLoading={loadingCountry}
                            onBlur={() => validation.setFieldTouched("origin", true)}
                            onChange={(e: any) => {
                              handleChange("origin", e.value);
                            }}
                          />
                        </div>
                      </Col>

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Select
                            id="nationality"
                            name="nationality"
                            label="Nationality"
                            className="is-invalid"
                            options={countryOptions}
                            value={
                              initialData.nationality
                                ? countryOptions.find(
                                  (option: any) => option.value == initialData.nationality
                                )
                                : ""
                            }
                            isSearchable={true}
                            isDisabled={loadingCountry}
                            isLoading={loadingCountry}
                            onBlur={() => validation.setFieldTouched("nationality", true)}
                            onChange={(e: any) => {
                              handleChange("nationality", e.value);
                            }}
                          />
                        </div>
                      </Col>

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <Col xl={4} lg={4} md={4} sm={12}>
                        <div className="mb-3">
                          <Select
                            id="user_type"
                            name="user_type"
                            label="User type"
                            required
                            className="is-invalid"
                            options={userTypeList}
                            value={
                              initialData.user_type
                                ? userTypeList?.find(
                                  (option: any) => option.value == initialData.user_type
                                )
                                : ""
                            }
                            isSearchable={false}
                            isDisabled={!userProfile.IS_PARTNER ? true : false}
                            onBlur={() => validation.setFieldTouched("user_type", true)}
                            onChange={(e: any) => {
                              handleChange("user_type", e.value);
                            }}
                            error={validation.errors.user_type}
                            touched={validation.touched.user_type}
                          />
                        </div>
                      </Col>

                      {userProfile.USER_COMPANY !== 0 && (
                        <Col xl={4} lg={4} md={4} sm={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {initialData.company && userProfile.IS_INTERNAL ? (
                                <Link
                                  to={`/masters/company/company-details/${getOptionValueById(
                                    companyOptions,
                                    parseInt(initialData.company),
                                    "id"
                                  )}/${getOptionValueById(
                                    companyOptions,
                                    parseInt(initialData.company),
                                    "company_id"
                                  )}`}
                                  className="text-decoration-underline"
                                >
                                  {t("Client")}
                                </Link>
                              ) : (
                                "Client"
                              )}{" "}
                              {initialData.user_type == "2" ? (
                                <span className="text-warning is_required">*</span>
                              ) : (
                                ""
                              )}
                            </Label>
                            <Select
                              id="company"
                              name="company"
                              className="is-invalid"
                              options={companyOptions}
                              value={
                                initialData.company
                                  ? companyOptions.find(
                                    (option: any) => option.value == initialData.company
                                  )
                                  : ""
                              }
                              isSearchable={true}
                              isDisabled={!userProfile.IS_PARTNER ? true : loadingCompany}
                              onBlur={() => validation.setFieldTouched("company", true)}
                              onChange={(e: any) => {
                                handleChange("company", e.value);
                              }}
                              error={validation.errors.company}
                              touched={validation.touched.company}
                            />
                          </div>
                        </Col>
                      )}

                      {parseInt(initialData.user_type) === 1 && (
                        <Col xl={4} lg={4} md={4} sm={12}>
                          <div className="mb-3">
                            <Select
                              id="client_status"
                              name="client_status"
                              label="Client Status"
                              options={clientstatusList}
                              value={
                                initialData.client_status
                                  ? clientstatusList?.find(
                                    (option: any) => option.value == initialData.client_status
                                  )
                                  : ""
                              }
                              isSearchable={false}
                              isDisabled={!userProfile.IS_INTERNAL}
                              onBlur={() => validation.setFieldTouched("client_status", true)}
                              onChange={(e: any) => {
                                handleChange("client_status", e.value);
                              }}
                            />
                          </div>
                        </Col>
                      )}

                      <Col xl={4} lg={4} md={4} sm={12}>
                        <div className="mb-3">
                          <Select
                            id="type"
                            name="type"
                            className="is-invalid"
                            label="Type"
                            required
                            options={typeFilteredOptions}
                            value={
                              initialData.type
                                ? typeFilteredOptions.find(
                                  (option: any) => option.value == initialData.type
                                )
                                : ""
                            }
                            isSearchable={false}
                            isDisabled={!userProfile.IS_PARTNER ? true : false}
                            onBlur={() => validation.setFieldTouched("type", true)}
                            onChange={(e: any) => {
                              handleChange("type", e.value);
                            }}
                            error={validation.errors.type}
                            touched={validation.touched.type}
                          />
                        </div>
                      </Col>

                      <Col xl={4} lg={4} md={4} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Function")}{" "}
                            {initialData.company == "1" && initialData.type == "1" ? (
                              <span className="text-warning is_required">*</span>
                            ) : (
                              ""
                            )}
                          </Label>
                          <Select
                            id="function"
                            name="function"
                            className="is-invalid"
                            options={functionOptions}
                            value={
                              initialData.function
                                ? functionOptions.find(
                                  (option: any) => option.value == initialData.function
                                )
                                : ""
                            }
                            isSearchable={true}
                            isDisabled={!userProfile.IS_PARTNER ? true : loadingFunction}
                            onBlur={() => validation.setFieldTouched("function", true)}
                            onChange={(e: any) => {
                              handleChange("function", e.value);
                            }}
                            error={validation.errors.function}
                            touched={validation.touched.function}
                          />
                        </div>
                      </Col>

                      <Col xl={4} lg={4} md={4} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Status")}{" "}
                            {initialData.company != "1" ||
                              (initialData.company == "1" && initialData.type != "1") ? (
                              <span className="text-warning is_required">*</span>
                            ) : null}
                          </Label>
                          <Select
                            id="status"
                            name="status"
                            className="is-invalid"
                            options={statusList}
                            value={
                              initialData.status
                                ? statusList?.find(
                                  (option: any) => option.value == initialData.status
                                )
                                : ""
                            }
                            isSearchable={false}
                            isDisabled={
                              !userProfile.IS_INTERNAL
                                ? true
                                : initialData.company == "1" && initialData.type == "1"
                                  ? true
                                  : loadingStatus
                            }
                            onBlur={() => validation.setFieldTouched("status", true)}
                            onChange={(e: any) => {
                              handleChange("status", e.value);
                            }}
                            error={validation.errors.status}
                            touched={validation.touched.status}
                          />
                        </div>
                      </Col>

                      {(initialData.company !== 1 && initialData.company !== "") && (
                      <>
                      <Col className="col-12">
                        <hr />
                      </Col>

                      {/* TAX REGIME */}
                      <Col xl={3} lg={3} md={3} sm={12} id="tax_regime">
                        <div className="mb-3">
                          <Label className="form-label">{t("INPAT")}</Label>
                          <Select
                            id="tax_regime"
                            name="tax_regime"
                            className="is-invalid"
                            options={taxRegimeList}
                            value={
                              initialData.tax_regime
                                ? taxRegimeList?.find(
                                  (option: any) => option.value == initialData.tax_regime
                                )
                                : ""
                            }
                            isSearchable={false}
                            isDisabled={!userProfile.IS_INTERNAL}
                            onBlur={() => validation.setFieldTouched("tax_regime", true)}
                            onChange={(e: any) => {
                              handleChange("tax_regime", e.value);
                            }}
                            error={validation.errors.tax_regime}
                            touched={validation.touched.tax_regime}
                          />
                        </div>
                      </Col>

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <DatePicker
                            id="tax_regime_start_date"
                            name="tax_regime_start_date"
                            label={t("INPAT start date")}
                            required={!disabledTaxRegime}
                            value={initialData.tax_regime_start_date}
                            error={validation.errors.tax_regime_start_date}
                            touched={validation.errors.tax_regime_start_date}
                            disabled={!userProfile.IS_INTERNAL ? true : disabledTaxRegime}
                            options={{
                              altInput: true,
                              altFormat: "d/m/Y",
                              dateFormat: "Y-m-d H:i:S",
                              allowInput: true,
                              plugins: [labelPlugin()],
                              onChange: function (selectedDates, dateStr, instance) {
                                handleChange("tax_regime_start_date", dateStr);
                                setTaxRegimeUpdate(true);
                              },
                              onClose: function (selectedDates, dateStr, instance) {
                                handleChange("tax_regime_start_date", dateStr);
                              },
                              locale: { firstDayOfWeek: 1 },
                            }}
                          />
                        </div>
                      </Col>

                      {!disabledTaxRegime && (
                        <>
                          <Col className="col-12">
                            <span className="text-primary text-decoration-underline">
                              {t("Original application")}
                            </span>
                          </Col>

                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <DatePicker
                                id="tax_regime_internal_due_date_original"
                                name="tax_regime_internal_due_date_original"
                                label={t("Internal due date for filing")}
                                required={!disabledTaxRegime}
                                value={initialData.tax_regime_internal_due_date_original}
                                error={validation.errors.tax_regime_internal_due_date_original}
                                touched={validation.errors.tax_regime_internal_due_date_original}
                                disabled={!userProfile.IS_INTERNAL ? true : disabledTaxRegime}
                                options={{
                                  altInput: true,
                                  altFormat: "d/m/Y",
                                  dateFormat: "Y-m-d H:i:S",
                                  allowInput: true,
                                  plugins: [labelPlugin()],
                                  onChange: function (selectedDates, dateStr, instance) {
                                    handleChange("tax_regime_internal_due_date_original", dateStr);
                                  },
                                  onClose: function (selectedDates, dateStr, instance) {
                                    handleChange("tax_regime_internal_due_date_original", dateStr);
                                  },
                                  locale: { firstDayOfWeek: 1 },
                                }}
                              />
                            </div>
                          </Col>

                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <DatePicker
                                id="tax_regime_external_due_date_original"
                                name="tax_regime_external_due_date_original"
                                label={t("External due date")}
                                required={!disabledTaxRegime}
                                value={initialData.tax_regime_external_due_date_original}
                                error={validation.errors.tax_regime_external_due_date_original}
                                touched={validation.errors.tax_regime_external_due_date_original}
                                disabled={!userProfile.IS_INTERNAL ? true : disabledTaxRegime}
                                options={{
                                  altInput: true,
                                  altFormat: "d/m/Y",
                                  dateFormat: "Y-m-d H:i:S",
                                  allowInput: true,
                                  plugins: [labelPlugin()],
                                  onChange: function (selectedDates, dateStr, instance) {
                                    handleChange("tax_regime_external_due_date_original", dateStr);
                                  },
                                  onClose: function (selectedDates, dateStr, instance) {
                                    handleChange("tax_regime_external_due_date_original", dateStr);
                                  },
                                  locale: { firstDayOfWeek: 1 },
                                }}
                              />
                            </div>
                          </Col>

                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <DatePicker
                                id="tax_regime_filing_date_original"
                                name="tax_regime_filing_date_original"
                                label={t("Filing date (Official sending date)")}
                                value={initialData.tax_regime_filing_date_original}
                                error={validation.errors.tax_regime_filing_date_original}
                                touched={validation.errors.tax_regime_filing_date_original}
                                disabled={!userProfile.IS_INTERNAL ? true : disabledTaxRegime}
                                options={{
                                  altInput: true,
                                  altFormat: "d/m/Y",
                                  dateFormat: "Y-m-d H:i:S",
                                  allowInput: true,
                                  plugins: [labelPlugin()],
                                  onChange: function (selectedDates, dateStr, instance) {
                                    handleChange("tax_regime_filing_date_original", dateStr);
                                  },
                                  onClose: function (selectedDates, dateStr, instance) {
                                    handleChange("tax_regime_filing_date_original", dateStr);
                                  },
                                  locale: { firstDayOfWeek: 1 },
                                }}
                              />
                            </div>
                          </Col>

                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <DatePicker
                                id="tax_regime_approval_date_original"
                                name="tax_regime_approval_date_original"
                                label={t("Approval date")}
                                value={initialData.tax_regime_approval_date_original}
                                error={validation.errors.tax_regime_approval_date_original}
                                touched={validation.errors.tax_regime_approval_date_original}
                                disabled={!userProfile.IS_INTERNAL ? true : disabledTaxRegime}
                                options={{
                                  altInput: true,
                                  altFormat: "d/m/Y",
                                  dateFormat: "Y-m-d H:i:S",
                                  allowInput: true,
                                  plugins: [labelPlugin()],
                                  onChange: function (selectedDates, dateStr, instance) {
                                    handleChange("tax_regime_approval_date_original", dateStr);
                                  },
                                  onClose: function (selectedDates, dateStr, instance) {
                                    handleChange("tax_regime_approval_date_original", dateStr);
                                  },
                                  locale: { firstDayOfWeek: 1 },
                                }}
                              />
                            </div>
                          </Col>

                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <DatePicker
                                id="tax_regime_end_date_original"
                                name="tax_regime_end_date_original"
                                label={t("End date of regime")}
                                required={!disabledTaxRegime}
                                value={initialData.tax_regime_end_date_original}
                                error={validation.errors.tax_regime_end_date_original}
                                touched={validation.errors.tax_regime_end_date_original}
                                disabled={!userProfile.IS_INTERNAL ? true : disabledTaxRegime}
                                options={{
                                  altInput: true,
                                  altFormat: "d/m/Y",
                                  dateFormat: "Y-m-d H:i:S",
                                  allowInput: true,
                                  plugins: [labelPlugin()],
                                  onChange: function (selectedDates, dateStr, instance) {
                                    handleChange("tax_regime_end_date_original", dateStr);
                                  },
                                  onClose: function (selectedDates, dateStr, instance) {
                                    handleChange("tax_regime_end_date_original", dateStr);
                                  },
                                  locale: { firstDayOfWeek: 1 },
                                }}
                              />
                            </div>
                          </Col>

                          <Col className="col-12">
                            <span className="text-primary text-decoration-underline">
                              {t("Extension application")}
                            </span>
                          </Col>

                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <DatePicker
                                id="tax_regime_internal_due_date_extension"
                                name="tax_regime_internal_due_date_extension"
                                label={t("Internal due date for filing")}
                                required={!disabledTaxRegime}
                                value={initialData.tax_regime_internal_due_date_extension}
                                error={validation.errors.tax_regime_internal_due_date_extension}
                                touched={validation.errors.tax_regime_internal_due_date_extension}
                                disabled={!userProfile.IS_INTERNAL ? true : disabledTaxRegime}
                                options={{
                                  altInput: true,
                                  altFormat: "d/m/Y",
                                  dateFormat: "Y-m-d H:i:S",
                                  allowInput: true,
                                  plugins: [labelPlugin()],
                                  onChange: function (selectedDates, dateStr, instance) {
                                    handleChange("tax_regime_internal_due_date_extension", dateStr);
                                  },
                                  onClose: function (selectedDates, dateStr, instance) {
                                    handleChange("tax_regime_internal_due_date_extension", dateStr);
                                  },
                                  locale: { firstDayOfWeek: 1 },
                                }}
                              />
                            </div>
                          </Col>

                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <DatePicker
                                id="tax_regime_external_due_date_extension"
                                name="tax_regime_external_due_date_extension"
                                label={t("External due date")}
                                required={!disabledTaxRegime}
                                value={initialData.tax_regime_external_due_date_extension}
                                error={validation.errors.tax_regime_external_due_date_extension}
                                touched={validation.errors.tax_regime_external_due_date_extension}
                                disabled={!userProfile.IS_INTERNAL ? true : disabledTaxRegime}
                                options={{
                                  altInput: true,
                                  altFormat: "d/m/Y",
                                  dateFormat: "Y-m-d H:i:S",
                                  allowInput: true,
                                  plugins: [labelPlugin()],
                                  onChange: function (selectedDates, dateStr, instance) {
                                    handleChange("tax_regime_external_due_date_extension", dateStr);
                                  },
                                  onClose: function (selectedDates, dateStr, instance) {
                                    handleChange("tax_regime_external_due_date_extension", dateStr);
                                  },
                                  locale: { firstDayOfWeek: 1 },
                                }}
                              />
                            </div>
                          </Col>

                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <DatePicker
                                id="tax_regime_filing_date_extension"
                                name="tax_regime_filing_date_extension"
                                label={t("Filing date (Official sending date)")}
                                value={initialData.tax_regime_filing_date_extension}
                                error={validation.errors.tax_regime_filing_date_extension}
                                touched={validation.errors.tax_regime_filing_date_extension}
                                disabled={!userProfile.IS_INTERNAL ? true : disabledTaxRegime}
                                options={{
                                  altInput: true,
                                  altFormat: "d/m/Y",
                                  dateFormat: "Y-m-d H:i:S",
                                  allowInput: true,
                                  plugins: [labelPlugin()],
                                  onChange: function (selectedDates, dateStr, instance) {
                                    handleChange("tax_regime_filing_date_extension", dateStr);
                                  },
                                  onClose: function (selectedDates, dateStr, instance) {
                                    handleChange("tax_regime_filing_date_extension", dateStr);
                                  },
                                  locale: { firstDayOfWeek: 1 },
                                }}
                              />
                            </div>
                          </Col>

                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <DatePicker
                                id="tax_regime_approval_date_extension"
                                name="tax_regime_approval_date_extension"
                                label={t("Approval date")}
                                value={initialData.tax_regime_approval_date_extension}
                                error={validation.errors.tax_regime_approval_date_extension}
                                touched={validation.errors.tax_regime_approval_date_extension}
                                disabled={!userProfile.IS_INTERNAL ? true : disabledTaxRegime}
                                options={{
                                  altInput: true,
                                  altFormat: "d/m/Y",
                                  dateFormat: "Y-m-d H:i:S",
                                  allowInput: true,
                                  plugins: [labelPlugin()],
                                  onChange: function (selectedDates, dateStr, instance) {
                                    handleChange("tax_regime_approval_date_extension", dateStr);
                                  },
                                  onClose: function (selectedDates, dateStr, instance) {
                                    handleChange("tax_regime_approval_date_extension", dateStr);
                                  },
                                  locale: { firstDayOfWeek: 1 },
                                }}
                              />
                            </div>
                          </Col>

                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <DatePicker
                                id="tax_regime_end_date_extension"
                                name="tax_regime_end_date_extension"
                                label={t("End date of extended regime")}
                                required={!disabledTaxRegime}
                                value={initialData.tax_regime_end_date_extension}
                                error={validation.errors.tax_regime_end_date_extension}
                                touched={validation.errors.tax_regime_end_date_extension}
                                disabled={!userProfile.IS_INTERNAL ? true : disabledTaxRegime}
                                options={{
                                  altInput: true,
                                  altFormat: "d/m/Y",
                                  dateFormat: "Y-m-d H:i:S",
                                  allowInput: true,
                                  plugins: [labelPlugin()],
                                  onChange: function (selectedDates, dateStr, instance) {
                                    handleChange("tax_regime_end_date_extension", dateStr);
                                  },
                                  onClose: function (selectedDates, dateStr, instance) {
                                    handleChange("tax_regime_end_date_extension", dateStr);
                                  },
                                  locale: { firstDayOfWeek: 1 },
                                }}
                              />
                            </div>
                          </Col>
                        </>
                      )}
                      {/* TAX REGIME */}
                      </>
                      )}
                      
                      <Col className="col-12">
                        <hr />
                      </Col>

                      {parseInt(initialData.user_type) === 1 && (
                        <>
                          <Col className="col-12"></Col>

                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <Select
                                id="preparer"
                                name="preparer"
                                label="Preparer"
                                className="is-invalid"
                                options={preparerOptions}
                                value={
                                  initialData.preparer
                                    ? preparerOptions.find(
                                      (option: any) => option.value == initialData.preparer
                                    )
                                    : ""
                                }
                                isSearchable={true}
                                isDisabled={userProfile.IS_INTERNAL ? false : true}
                                onBlur={() => validation.setFieldTouched("preparer", true)}
                                onChange={(e: any) => {
                                  handleChange("preparer", e.value);
                                }}
                              />
                            </div>
                          </Col>

                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <Select
                                id="reviewer"
                                name="reviewer"
                                className="is-invalid"
                                label="Reviewer"
                                options={reviewerOptions}
                                value={
                                  initialData.reviewer
                                    ? reviewerOptions.find(
                                      (option: any) => option.value == initialData.reviewer
                                    )
                                    : ""
                                }
                                isSearchable={true}
                                isDisabled={userProfile.IS_INTERNAL ? false : true}
                                onBlur={() => validation.setFieldTouched("reviewer", true)}
                                onChange={(e: any) => {
                                  handleChange("reviewer", e.value);
                                }}
                              />
                            </div>
                          </Col>

                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <Select
                                id="file_manager"
                                name="file_manager"
                                className="is-invalid"
                                label="File manager"
                                required
                                options={fileManagerOptions}
                                value={
                                  initialData.file_manager
                                    ? fileManagerOptions.find(
                                      (option: any) => option.value == initialData.file_manager
                                    )
                                    : ""
                                }
                                isSearchable={true}
                                isDisabled={userProfile.IS_INTERNAL ? false : true}
                                onBlur={() => validation.setFieldTouched("file_manager", true)}
                                onChange={(e: any) => {
                                  handleChange("file_manager", e.value);
                                }}
                                error={validation.errors.file_manager}
                                touched={validation.touched.file_manager}
                              />
                            </div>
                          </Col>

                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <Select
                                id="signing_partner"
                                name="signing_partner"
                                className="is-invalid"
                                label="Partner"
                                required
                                options={partnerOptions}
                                value={
                                  initialData.signing_partner
                                    ? partnerOptions.find(
                                      (option: any) => option.value == initialData.signing_partner
                                    )
                                    : ""
                                }
                                isSearchable={true}
                                isDisabled={userProfile.IS_INTERNAL ? false : true}
                                onBlur={() => validation.setFieldTouched("signing_partner", true)}
                                onChange={(e: any) => {
                                  handleChange("signing_partner", e.value);
                                }}
                                error={validation.errors.signing_partner}
                                touched={validation.touched.signing_partner}
                              />
                            </div>
                          </Col>

                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <Select
                                id="assistant_administrative"
                                name="assistant_administrative"
                                label="Assistant administrative"
                                className="is-invalid"
                                options={assistantAdministrativeOptions}
                                value={
                                  initialData.assistant_administrative
                                    ? assistantAdministrativeOptions.find(
                                      (option: any) =>
                                        option.value == initialData.assistant_administrative
                                    )
                                    : ""
                                }
                                isSearchable={true}
                                isDisabled={userProfile.IS_INTERNAL ? false : true}
                                onBlur={() =>
                                  validation.setFieldTouched("assistant_administrative", true)
                                }
                                onChange={(e: any) => {
                                  handleChange("assistant_administrative", e.value);
                                }}
                              />
                            </div>
                          </Col>

                          {userProfile.USER_COMPANY === 1 && (
                            <Col xl={3} lg={3} md={3} sm={12}>
                              <div className="mb-3">
                                <Input
                                  id="category"
                                  name="category"
                                  label="Category"
                                  value={initialData.category}
                                  readOnly
                                  onBlur={() => validation.setFieldTouched("category", true)}
                                />
                              </div>
                            </Col>
                          )}

                          <Col className="col-12">
                            <hr />
                          </Col>

                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <Label className="form-label">
                                {t("TVA/BTW number")}{" "}
                                <Tooltip content={"Format VAT: BE0123456789"} direction="top">
                                  <i className="fa-regular fas fa-info-circle"></i>
                                </Tooltip>
                              </Label>
                              <InputGroup>
                                <InputNew
                                  id="TVW_Nr"
                                  name="TVW_Nr"
                                  className="form-control"
                                  placeholder={t("TVA/BTW number")}
                                  type="text"
                                  value={initialData.TVW_Nr}
                                  valid={validateVat.valid}
                                  disabled={!userProfile.IS_INTERNAL}
                                  onBlur={validation.handleBlur}
                                  onChange={(e) => handleChange("TVW_Nr", e.target.value)}
                                  invalid={
                                    (validation.touched.TVW_Nr && validation.errors.TVW_Nr) ||
                                      validateVat.valid === false
                                      ? true
                                      : false
                                  }
                                />
                                <Button
                                  disabled={
                                    initialData.TVW_Nr === "" ||
                                    validateVat.loading ||
                                    validateVat.valid
                                  }
                                  outline
                                  onClick={() => handleValidate(initialData.TVW_Nr)}
                                  color={validateVat.valid ? "success" : "secondary"}
                                >
                                  {validateVat.loading
                                    ? t("Loading")
                                    : validateVat.valid
                                      ? t("Valid")
                                      : t("Validate")}
                                </Button>
                              </InputGroup>
                              {validation.touched.TVW_Nr && validation.errors.TVW_Nr ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.TVW_Nr}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          {userProfile.USER_COMPANY === 1 && (
                            <>
                              <Col xl={3} lg={3} md={3} sm={12}>
                                <div className="mb-3">
                                  <Label className="form-label">{t("VAT Category")}</Label>
                                  <Select
                                    id="vatcat"
                                    name="Vat Cat"
                                    className="is-invalid"
                                    label="VAT Category"
                                    options={vatcatOptions}
                                    value={
                                      validation.values?.vatcat
                                        ? vatcatOptions.find(
                                          (option: any) =>
                                            option.value == validation.values.vatcat
                                        )
                                        : ""
                                    }
                                    isSearchable={false}
                                    isDisabled={!userProfile.IS_INTERNAL}
                                    onBlur={() => validation.setFieldTouched("vatcat", true)}
                                    onChange={(e: any) => {
                                      handleChange("vatcat", e.value);
                                    }}
                                    error={validation.errors.vatcat}
                                    touched={validation.touched.vatcat}
                                  />
                                </div>
                              </Col>
                              <Col xl={3} lg={3} md={3} sm={12}>
                                <div className="mb-3">
                                  <Input
                                    id="vat_percentage"
                                    name="vat_percentage"
                                    label="Vat Percentage"
                                    value={initialData.vat_percentage}
                                    readOnly
                                    onChange={(e: any) => {
                                      handleChange("vat_percentage", e.target.value);
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col xl={3} lg={3} md={3} sm={12}>
                                <div className="mb-3">
                                  <Input
                                    id="vat_code"
                                    name="vat_code"
                                    label="Vat Code"
                                    value={initialData.vat_code}
                                    readOnly
                                    onChange={(e: any) => {
                                      handleChange("vat_code", e.target.value);
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col xl={3} lg={3} md={3} sm={12}>
                                <div className="mb-3">
                                  <Select
                                    id="to_apply_vat"
                                    name="to_apply_vat"
                                    className="is-invalid"
                                    label="To Apply Vat"
                                    options={yesNoList}
                                    value={
                                      validation.values?.to_apply_vat
                                        ? yesNoList?.find(
                                          (option: any) =>
                                            option.value == validation.values.to_apply_vat
                                        )
                                        : ""
                                    }
                                    isSearchable={false}
                                    isDisabled={!userProfile.IS_INTERNAL}
                                    onBlur={() => validation.setFieldTouched("to_apply_vat", true)}
                                    onChange={(e: any) => {
                                      handleChange("to_apply_vat", e.value);
                                    }}
                                    error={validation.errors.to_apply_vat}
                                    touched={validation.touched.to_apply_vat}
                                  />
                                </div>
                              </Col>
                              <Col xl={3} lg={3} md={3} sm={12}>
                                <div className="mb-3">
                                  <Select
                                    id="reason_for_vat"
                                    name="reason_for_vat"
                                    className="is-invalid"
                                    label="Reason"
                                    options={reasonsList}
                                    value={
                                      validation.values?.reason_for_vat
                                        ? reasonsList?.find(
                                          (option: any) =>
                                            option.value == validation.values.reason_for_vat
                                        )
                                        : ""
                                    }
                                    isSearchable={false}
                                    isDisabled={!userProfile.IS_INTERNAL}
                                    onBlur={() =>
                                      validation.setFieldTouched("reason_for_vat", true)
                                    }
                                    onChange={(e: any) => {
                                      handleChange("reason_for_vat", e.value);
                                    }}
                                    error={validation.errors.reason_for_vat}
                                    touched={validation.touched.reason_for_vat}
                                  />
                                </div>
                              </Col>
                            </>
                          )}

                          <Col className="col-12">
                            <hr></hr>
                          </Col>
                        </>
                      )}

                      <Col className="col-12"></Col>

                      {userProfile.USER_ID === initialData.id && (
                      <>
                        <Col xl={4} lg={4} md={4} sm={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {t("Password")} <span className="text-warning is_required">*</span>
                            </Label>
                            <div className="input-group is-invalid">
                              <InputNew
                                id="password"
                                name="password"
                                placeholder={t("Password")}
                                type={passwordShow ? "text" : "password"}
                                autoComplete="new-password"
                                value={initialData.password}
                                onBlur={validation.handleBlur}
                                onChange={(e) => handleChange("password", e.target.value)}
                                invalid={
                                  validation.touched.password && validation.errors.password
                                    ? true
                                    : false
                                }
                              />
                              <span
                                className="input-group-text btn-outline-primary"
                                style={{
                                  border: "1px solid #ced4da",
                                  cursor: "pointer",
                                }}
                                title={t("Auto generate password")}
                                onClick={() => passwordAutoGenerate()}
                              >
                                <span className="align-middle material-symbols-outlined fw-light fs-4">
                                  enhanced_encryption
                                </span>
                              </span>
                              <span
                                className="input-group-text btn-outline-primary"
                                style={{
                                  border: "1px solid #ced4da",
                                  cursor: "pointer",
                                }}
                                title={t("Show / Hide password")}
                                onClick={() => setPasswordShow(!passwordShow)}
                              >
                                {!passwordShow ? (
                                  <span className="align-middle material-symbols-outlined fw-light fs-4">
                                    visibility
                                  </span>
                                ) : (
                                  <span className="align-middle material-symbols-outlined fw-light fs-4">
                                    visibility_off
                                  </span>
                                )}
                              </span>
                            </div>
                            {/* {initialData.password_strength
                              ? initialData.password_strength
                              : null} */}

                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col xl={4} lg={4} md={4} sm={12}>
                          <div className="mb-3">
                            <Input
                              id="password_confirmation"
                              name="password_confirmation"
                              placeholder={t("Re-type password")}
                              label="Confirm password"
                              required
                              type="password"
                              autoComplete="off"
                              value={initialData.password_confirmation}
                              onBlur={validation.handleBlur}
                              onChange={(e) => handleChange("password_confirmation", e.target.value)}
                              invalid={
                                validation.touched.password_confirmation &&
                                  validation.errors.password_confirmation
                                  ? true
                                  : false
                              }
                              error={validation.errors.password_confirmation}
                              touched={validation.touched.password_confirmation}
                            />
                          </div>
                        </Col>
                      </>
                      )}

                      <Col xl={4} lg={4} md={4} sm={12}>
                        <div className="mb-3">
                          <Select
                            id="access_role"
                            name="access_role"
                            className="is-invalid"
                            label="Access role"
                            required
                            options={new_access_roleOptions}
                            value={
                              initialData.access_role
                                ? new_access_roleOptions.find(
                                  (option: any) => option.value == initialData.access_role
                                )
                                : ""
                            }
                            isSearchable={true}
                            loading={loadingAccessRole}
                            isDisabled={!(userProfile.IS_PARTNER || moduleAccess('access-role')) ? true : loadingAccessRole}
                            onBlur={() => validation.setFieldTouched("access_role", true)}
                            onChange={(e: any) => {
                              handleChange("access_role", e.value);
                            }}
                            error={validation.errors.access_role}
                            touched={validation.touched.access_role}
                          />
                        </div>
                      </Col>

                      {/* <Col xl={6} lg={6} md={6} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            Access module <span className="text-warning is_required">*</span>
                          </Label>
                          <Select
                            id="access_module"
                            name="access_module"
                            className="is-invalid"
                            options={new_access_moduleOptions}
                            value={
                              initialData.access_module
                                ? new_access_moduleOptions.find(
                                  (option: any) => option.value == initialData.access_module
                                )
                                : ""
                            }
                            isSearchable={true}
                            isDisabled={!userProfile.IS_COMPANY_ADMIN ? true : loadingAccessModule}
                            onBlur={() => validation.setFieldTouched("access_module", true)}
                            onChange={(e: any) => {
                              handleChange("access_module", e.value);
                            }}
                          />
                          {validation.touched.access_module && validation.errors.access_module ? (
                            <FormFeedback type="invalid">
                              {validation.errors.access_module}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col> */}

                      {/* <Col className="col-12"></Col> */}

                      {/* {userProfile.IS_COMPANY_ADMIN ? (
                        <Col xl={6} lg={6} md={6} sm={12}>
                          <div className="mb-3">
                            <Label className="form-label">Archive</Label>
                            <div className="form-check mb-2">
                              <Input
                                id="is_archive_yes"
                                name="is_archive"
                                value="1"
                                className="form-check-input"
                                type="radio"
                                checked={
                                  initialData.is_archive == 1 ? true : false
                                }
                                onChange={() => handleChange("is_archive", 1)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="is_archive_yes"
                              >
                                Yes
                              </label>
                            </div>

                            <div className="form-check mb-2">
                              <Input
                                id="is_archive_no"
                                name="is_archive"
                                value="0"
                                className="form-check-input"
                                type="radio"
                                checked={
                                  initialData.is_archive == 0 ? true : false
                                }
                                onChange={() => handleChange("is_archive", 0)}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="is_archive_no"
                              >
                                No
                              </label>
                            </div>
                          </div>
                        </Col>
                      ) : null}

                      <Col className="col-12"></Col> */}

                      <Col xl={4} lg={4} md={4} sm={12}>
                        <div className="mb-3">
                          <div className="input-group">
                            <DatePicker
                              id="start_date"
                              name="start_date"
                              label="Start date in application"
                              required
                              className="form-control d-block"
                              type="text"
                              value={initialData.start_date}
                              placeholder="DD/MM/YYYY"
                              disabled={!userProfile.IS_INTERNAL}
                              options={{
                                altInput: true,
                                altFormat: "d/m/Y",
                                dateFormat: "Y-m-d H:i:S",
                                // minDate: initialData.start_date,
                                allowInput: true,
                                plugins: [labelPlugin()],
                                onChange: function (selectedDates, dateStr, instance) {
                                  handleChange("start_date", dateStr);
                                },
                                onClose: function (selectedDates, dateStr, instance) {
                                  handleChange("start_date", dateStr);
                                },
                                locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                              }}
                              error={validation.errors.start_date}
                              touched={validation.touched.start_date}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col xl={4} lg={4} md={4} sm={12}>
                        <div className="mb-3">
                          <div className="input-group">
                            <DatePicker
                              id="user_end_date"
                              name="end_date"
                              label="End date in application"

                              className="form-control d-block"
                              type="text"
                              value={initialData.end_date}
                              placeholder="DD/MM/YYYY"
                              disabled={!userProfile.IS_INTERNAL}
                              options={{
                                altInput: true,
                                altFormat: "d/m/Y",
                                dateFormat: "Y-m-d H:i:S",
                                minDate: initialData.start_date,
                                allowInput: true,
                                plugins: [labelPlugin()],
                                onChange: function (selectedDates, dateStr, instance) {
                                  handleChange("end_date", dateStr);
                                },
                                onClose: function (selectedDates, dateStr, instance) {
                                  handleChange("end_date", dateStr);
                                },
                                locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>

                  {!dataNotFound && (
                    <CardFooter className="bg-transparent border-top">
                      <Row>
                        <Col className="col-12 mt-2 mb-3">
                          {initialData &&
                            initialData.created_by_first_name &&
                            initialData.created_by_last_name ? (
                            <div>
                              <ul className="list-inline mb-1">
                                <li className="list-inline-item me-3">
                                  <span className="text-muted">
                                    <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                      person
                                    </span>
                                    {t("Created by")} {initialData.created_by_first_name}{" "}
                                    {initialData.created_by_last_name}
                                  </span>
                                </li>
                                <li className="list-inline-item me-3">
                                  <span className="text-muted">
                                    <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                      calendar_today
                                    </span>
                                    {dateFormat(initialData.created_at)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          ) : null}

                          {initialData &&
                            initialData.updated_by_first_name &&
                            initialData.updated_by_last_name ? (
                            <div>
                              <ul className="list-inline">
                                <li className="list-inline-item me-3">
                                  <span className="text-muted">
                                    <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                      person
                                    </span>
                                    {t("Last modified by")} {initialData.updated_by_first_name}{" "}
                                    {initialData.updated_by_last_name}
                                  </span>
                                </li>
                                <li className="list-inline-item me-3">
                                  <span className="text-muted">
                                    <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                      calendar_today
                                    </span>
                                    {dateFormat(initialData.updated_at)}
                                  </span>
                                </li>
                              </ul>
                            </div>
                          ) : null}
                        </Col>
                      </Row>

                      <div className="d-sm-flex flex-wrap ">
                        <div className="d-flex gap-2">
                          {(userProfile.IS_INTERNAL && userProfile.CAN_DELETE_ALL && userProfile.USER_ID !== initialData.id) ? (
                            <Button
                              color="success"
                              className="btn-block btn-label"
                              type="button"
                              onClick={handleArchive}
                            >
                              {initialData.is_archive == 0 ? (
                                <>
                                  <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                    archive
                                  </span>
                                  {t("Archive")}
                                </>
                              ) : (
                                <>
                                  <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                    unarchive
                                  </span>
                                  {t("Unarchive")}
                                </>
                              )}
                            </Button>
                          ) : null}
                        </div>

                        <div className="ms-auto d-flex gap-2 justify-content-end">
                          {!isWindow && userProfile.CAN_CREATE &&
                            (userProfile.CAN_EDIT &&
                              (
                                userProfile.USER_ID === parseInt(initialData?.created_by) ||
                                userProfile.USER_ID === parseInt(initialData.id)
                              ) || userProfile.CAN_EDIT_ALL) ? (
                            <Button
                              color="primary"
                              className="btn-block"
                              type="submit"
                              onClick={() => handleChange("submit_btn_code", "update_add_new")}
                              disabled={loading}
                              loading={loading}
                            >
                              {t("Update & Add new")}
                            </Button>
                          ) : null}

                          {!isWindow &&
                            (userProfile.CAN_EDIT &&
                              (
                                userProfile.USER_ID === parseInt(initialData?.created_by) ||
                                userProfile.USER_ID === parseInt(initialData.id)
                              ) || userProfile.CAN_EDIT_ALL) ? (
                            <>
                              <Button
                                color="primary"
                                className="btn-block"
                                type="submit"
                                onClick={() => handleChange("submit_btn_code", "update_close")}
                                disabled={loading}
                                loading={loading}
                              >
                                {t("Update & Close")}
                              </Button>
                            </>
                          ) : null}

                          {(userProfile.CAN_EDIT &&
                            (
                              userProfile.USER_ID === parseInt(initialData?.created_by) ||
                              userProfile.USER_ID === parseInt(initialData.id)
                            ) || userProfile.CAN_EDIT_ALL) ? (
                            <>
                              <Button
                                color="primary"
                                className="btn-block"
                                type="submit"
                                onClick={() => handleChange("submit_btn_code", "update")}
                                disabled={loading}
                                loading={loading}
                              >
                                {t("Update")}
                              </Button>
                            </>
                          ) : null}

                          {!isWindow ? (
                            <Button
                              color="danger"
                              className="btn-block"
                              type="reset"
                              onClick={() => {
                                handleRedirect(true);
                              }}
                              disabled={loading}
                              loading={loading}
                            >
                              {t("Cancel")}
                            </Button>
                          ) : null}
                        </div>
                      </div>
                    </CardFooter>
                  )}
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal isOpen={processModal} toggle={() => setProcessModal(!processModal)} centered={true}>
        <div className="modal-header">
          <h5 className="modal-title">{t('Process Data')}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setProcessModal(false)}
            aria-label="Close"
          ></button>
        </div>
        <ModalBody>
          <p>{t('Do you want to process the data')}</p>
        </ModalBody>
        <ModalFooter>
          <button
            type="submit"
            className="btn btn-primary btn-md ms-2"
            onClick={onProcessClick}
            disabled={loading}
          >
            {t("Process")}
          </button>
          <button
            type="button"
            className="btn btn-outline-danger btn-md ms-2"
            onClick={() => setProcessModal(false)}
          >
            {t("Cancel")}
          </button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={azureModal} toggle={() => setAzureModal(!azureModal)} centered={true}>
        <div className="modal-header">
          <h5 className="modal-title"> {t("Send Invite")}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setAzureModal(false)}
            aria-label="Close"
          ></button>
        </div>
        <ModalBody>
          <p>{t("Click to invite the new user to access application with Azure AD- invite")}</p>
        </ModalBody>
        <ModalFooter>
          {isAzureAuthenticated === true ? (
            <Button
              onClick={handleAzureInvite}
              color={"primary"}
              className="btn btn-primary btn-sm waves-effect waves-light btn-label me-2"
            >
              <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 label-icon">
                grid_view
              </span>
              {t("Azure AD - Invite")}
            </Button>
          ) : null}
          <button
            type="button"
            className="btn btn-outline-danger btn-md ms-2"
            onClick={() => setAzureModal(false)}
          >
            {t("Cancel")}
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default EditUser;
