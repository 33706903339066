import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Nav, NavItem, Row } from "reactstrap";
import styled from 'styled-components';
import Breadcrumbs from "../../components/Layout/Breadcrumb";
import { getUserProfile, widgetAccess } from "../../helpers/functions_helper";
import { serialize } from "../../utils/utils";
import Counts from "./Charts/Counts";
import ExternalVsInternal from "./Charts/ExternalVsInternal";
import FeeVsServiceCharge from "./Charts/FeeVsServiceCharge";
import PerUserPerTaskSummary from "./Charts/PerUserPerTaskSummary";
import PerUserPerTimeSpent from "./Charts/PerUserPerTimeSpent";
import PerUserTaskTime from "./Charts/PerUserTaskTime";
import TimePerTask from "./Charts/TimePerTask";
import TimePerTaskPerUser from "./Charts/TimePerTaskPerUser";
import TimesheetVsAccruedAmount from "./Charts/TimesheetVsAccruedAmount";
import GraphFilters from '../../components/Chart/Filters';
import NoDataFound from "../../components/NoDataFound";
import { currentMonth, currentYear } from "../../common/staticdata";

const CustomCard = styled(Card)`
    height: calc(100% - 24px);
`;

const TimesheetGraphicalView = () => {

    const userObj = getUserProfile("all-time-sheets");
    const { t } = useTranslation();

    document.title = `${t("Time sheets")} - ${t("Graphical view")} | ${process.env.REACT_APP_NAME}`;


    const breadcrumbItems = [
        {
            url: "/dashboard",
            label: t("Dashboard"),
            active: false,
        },
        {
            url: "#",
            label: t("Time sheets"),
            active: true,
        },
    ];

    const inititalFilter = {
        week: null,
        quarter: null,
        month: currentMonth,
        year: currentYear,
        user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_ID : null
    };

    const [loading, setLoading] = useState<boolean>(true);
    const [filter, setFilter] = useState<any>(inititalFilter);

    const clearWorkAllocationFilter = () => {
        setFilter(inititalFilter);
    }

    return (
        <>
            <div className="page-content">

                <Container fluid>
                    <Breadcrumbs
                        breadcrumbTitle={t("Time sheets")}
                        breadcrumbItems={breadcrumbItems}
                        endAdornment={
                            <div className="d-flex gap-2 text-end">
                                {userObj?.IS_INTERNAL && (
                                    <Link to="/time-sheets/all-time-sheets" className={`btn btn-outline-secondary btn-label btn-sm`}>
                                        <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                            list_alt
                                        </span>
                                        {t('List view')}
                                    </Link>
                                )}

                                {userObj?.IS_GRAPHICAL_VIEW && (
                                    <Link
                                        to="/time-sheets/graph"
                                        className={`btn btn-info btn-label btn-sm`}
                                    >
                                        <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                            insert_chart
                                        </span>
                                        {t("Graphical view")}
                                    </Link>
                                )}

                                {userObj.CAN_CREATE ? (
                                    <Link to="/time-sheets/new-time-sheet" className="btn btn-primary btn-label btn-sm">
                                        <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                            add
                                        </span>{" "}
                                        {t("Create time sheet")}
                                    </Link>
                                ) : null}
                            </div>
                        }
                    />
                    <Row>
                        <Col>
                            <div className="d-sm-flex flex-wrap mt-3">
                                <p></p>
                                <div className="ms-auto">
                                    <GraphFilters
                                        filter={filter}
                                        setFilter={setFilter}
                                        clearFilter={clearWorkAllocationFilter}
                                        enableMyTimesheet
                                        slug="all-time-sheets"
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {widgetAccess('Timesheet_Counts') ? <Row>
                        <Col>
                            <div className="mt-3">
                                <Row>
                                    <Counts filters={filter} />
                                </Row>
                            </div>
                        </Col>
                    </Row> : null}
                    {widgetAccess('Per_user_per_task_summary') ? <Row>
                        <Col xl="12">
                            <CustomCard>
                                <CardBody>
                                    <PerUserPerTaskSummary filters={filter} />
                                </CardBody>
                            </CustomCard>
                        </Col>
                    </Row> : null}
                    {userObj.IS_PARTNER && (
                        <Row>
                            <Col xl="6">
                                <CustomCard>
                                    <CardBody>
                                        <TimesheetVsAccruedAmount filters={filter} />
                                    </CardBody>
                                </CustomCard>
                            </Col>
                            <Col xl="6">
                                <CustomCard>
                                    <CardBody>
                                        <FeeVsServiceCharge filters={filter} />
                                    </CardBody>
                                </CustomCard>
                            </Col>
                        </Row>
                    )}
                    {widgetAccess('Per_user_total_task_time_spent') ? <Row>
                        <Col xl="12">
                            <CustomCard>
                                <CardBody>
                                    <PerUserTaskTime filters={filter} />
                                </CardBody>
                            </CustomCard>
                        </Col>
                        {/* <Col xl="6">
                            <Card>
                                <CardBody>
                                    <PerUserPerTaskSummary />
                                </CardBody>
                            </Card>
                        </Col> */}
                    </Row> : null}


                    <Row>
                        {widgetAccess('Percentage_of_time_spent_per_task') ? <Col xl="6">
                            <CustomCard>
                                <CardBody>
                                    <TimePerTask filters={filter} />
                                </CardBody>
                            </CustomCard>
                        </Col> : null}
                        {widgetAccess('External_timesheet_vs_internal_timesheet') ? <Col xl="6">
                            <CustomCard>
                                <CardBody>
                                    <ExternalVsInternal filters={filter} />
                                </CardBody>
                            </CustomCard>
                        </Col> : null}
                    </Row>
                    <Row>
                        {widgetAccess('Per_user_total_task_time_spent') ? <Col xl="12">
                            <CustomCard>
                                <CardBody>
                                    <PerUserPerTimeSpent filters={filter} />
                                </CardBody>
                            </CustomCard>
                        </Col> : null}
                        {/* <Col xl="6">
                            <Card>
                                <CardBody>
                                    <PerUserPerTaskSummary />
                                </CardBody>
                            </Card>
                        </Col> */}
                    </Row>
                    <Row>
                        {/* <Col xl="12">
                            <CustomCard>
                                <CardBody>
                                    <TimePerTaskPerUser filters={filter} />
                                </CardBody>
                            </CustomCard>
                        </Col> */}
                        {/* <Col xl="6">
                            <Card>
                                <CardBody>
                                    <PerUserPerTaskSummary />
                                </CardBody>
                            </Card>
                        </Col> */}
                    </Row>
                </Container>

            </div>
        </>
    )
}

export default TimesheetGraphicalView;