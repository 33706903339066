import commonService from "../services/common.service";

export const Month = [
    { label: 'Jan', value: '1' },
    { label: 'Feb', value: '2' },
    { label: 'Mar', value: '3' },
    { label: 'Apr', value: '4' },
    { label: 'May', value: '5' },
    { label: 'June', value: '6' },
    { label: 'July', value: '7' },
    { label: 'Aug', value: '8' },
    { label: 'Sept', value: '9' },
    { label: 'Oct', value: '10' },
    { label: 'Nov', value: '11' },
    { label: 'Dec', value: '12' },
];

export const MonthL = [
    { label: 'January', value: 'january' },
    { label: 'February', value: 'february' },
    { label: 'March', value: 'march' },
    { label: 'April', value: 'april' },
    { label: 'May', value: 'may' },
    { label: 'June', value: 'june' },
    { label: 'July', value: 'july' },
    { label: 'August', value: 'august' },
    { label: 'September', value: 'september' },
    { label: 'October', value: 'october' },
    { label: 'November', value: 'november' },
    { label: 'December', value: 'december' },
];

const d = new Date();
export const QuarterData = [
    { label: `Q1-${d.getFullYear()}`, value: `q1-${d.getFullYear()}` },
    { label: `Q2-${d.getFullYear()}`, value: `q2-${d.getFullYear()}` },
    { label: `Q3-${d.getFullYear()}`, value: `q3-${d.getFullYear()}` },
    { label: `Q4-${d.getFullYear()}`, value: `q4-${d.getFullYear()}` },
]

export const WeekOption = [
    // { label: `Today`, value: `today` },
    { label: `Week`, value: `week` },
    { label: `Next Week`, value: `nextweek` },
]


export const getSelectOptions = async (tableName: string, fieldName: string, query?: any, otherFields?: Array<string> | null, condition?: any | null) => {
    const response = await commonService.getSelectOptionsList(
        tableName,
        fieldName,
        query ? query : "",
        otherFields ? otherFields : null,
        condition ? condition : null
    );
    if (response.data.status === "success")
        return response.data.data;
    else
        return [];
};

export const noData = {
    ax: '<span class="material-symbols-outlined">check</span>',
    text: "No data available. Please try again!",
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
        color: "#74788d",
        fontSize: '1.5em',
        fontWeight: '400',
        lineHeight: 1.2,
        fontFamily: 'inherit'
    }
}


export const TaskStatus = ['TO DO', 'IN PREPARATION', 'IN REVIEW', 'RESPONSIBLE TO SIGN OFF', 'TO BE SENT', 'SENT', 'CANCELLED', 'INVOICED', 'DONE'];