import React, { useCallback, useMemo, useRef, useState, useEffect, Suspense } from 'react';
// @ts-ignore
import mjml from 'mjml-browser';
import {
  EmailEditor,
  EmailEditorProvider,
  IEmailTemplate,
  Stack,
} from 'easy-email-editor';
import 'easy-email-editor/lib/style.css';
import { BlockMarketManager, BlockMaskWrapper } from 'easy-email-extensions';


import { useImportTemplate } from './hooks/useImportTemplate';
import { useExportTemplate } from './hooks/useExportTemplate';
import { copy } from './urils/clipboard';
import { AdvancedType, BasicType, BlockManager, IBlockData, JsonToMjml } from 'easy-email-core';
import { ExtensionProps, SimpleLayout, StandardLayout } from 'easy-email-extensions';
import { FormApi } from 'final-form';
import 'easy-email-extensions/lib/style.css';
import '@arco-themes/react-easy-email-theme-purple/css/arco.css';
import { useWindowSize } from 'react-use'

//import './CustomBlocks';
import { Button, Col, Nav, NavItem, Row } from 'reactstrap';
import newsLetterService from '../../../services/news.letter.service';
import SaveNewsLetter from './SaveModal';
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { Link, useParams } from 'react-router-dom';
import { cloneDeep } from 'lodash';
import Breadcrumbs from "../../../components/Layout/Breadcrumb";
import localesi18n from './locales/locales.json';
import { ckEditorI18 } from '../../../helpers/functions_helper';
import { useTranslation } from 'react-i18next';


const fontList = [
  'Arial',
  'Tahoma',
  'Verdana',
  'Times New Roman',
  'Courier New',
  'Georgia',
  'Lato',
  'Montserrat',
].map(item => ({ value: item, label: item }));

BlockMarketManager.addCategories([
  {
    title: 'Custom',
    name: 'custom',
    blocks: [
      {
        type: BasicType.TEXT,
        title: 'Text',
        description: 'This block allows you to display text in your email.',
        component: () => {
          return (
            <BlockMaskWrapper
              type={BasicType.TEXT}
              payload={{
                attributes: {
                  'font-size': '20px',
                  align: 'center',
                  padding: '0px 0px 0px 0px',
                  color: '#4A90E2',
                },
                data: {
                  value: {
                    content: '20px',
                  },
                },
              }}
            >
              <div style={{ fontSize: 20, width: '100%', paddingLeft: 20 }}>20px</div>
            </BlockMaskWrapper>
          );
        },
      },
    ],
  },
]);





export default function EditNewsLetter() {
  const toastId = useRef(null) as any;
  const [downloadFileName, setDownloadName] = useState('download.mjml');
  const [template, setTemplate] = useState<IEmailTemplate['content']>(BlockManager.getBlockByType(BasicType.PAGE)!.create({}));
  const [initialValues, setInitialValues] = useState<IEmailTemplate | null>({ subject: 'Tax Consult', subTitle: '', content: BlockManager.getBlockByType(BasicType.PAGE)!.create({}) });
  const { importTemplate } = useImportTemplate();
  const { exportTemplate } = useExportTemplate();
  const [openSave, setOpenSave] = useState<boolean>(false);
  const [newsLetter, setNewsLetter] = useState<any>({});
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const smallScene = width < 1400;
  const { id, news_letter_id } = useParams();
  const [postData, setPostData] = useState([]);
  const [htmlContain, setHtmlContain] = useState([])
  const {t} =useTranslation();

  console.log(id, news_letter_id);

  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "/masters/news-letter/list",
      label: t("News Letter List"),
      active: false,
    },
    {
      url: "#",
      label: t("Edit News Letter"),
      active: true,
    }
  ];
  

  const getNewsLetter = async (id: any, news_letter_id: any) => {
    const res: any = await newsLetterService.get(id, news_letter_id);
    if (res?.status === 'success') {
      console.log(res);
      setPostData(res?.data);
      setInitialValues((preState: any) => ({ ...preState, ...res?.data?.template }))
    }
  }

  useEffect(() => {
    if (id && news_letter_id) {
      getNewsLetter(id, news_letter_id);
    }
  }, [id, news_letter_id])

  const onSubmit = useCallback(
    async (
      values: IEmailTemplate,
      form: FormApi<IEmailTemplate, Partial<IEmailTemplate>>
    ) => {
      console.log('values', values);

      const html = mjml(JsonToMjml({
        data: values.content,
        mode: 'production',
        context: values.content
      }), {
        beautify: true,
        validationLevel: 'soft',
      }).html;
      setHtmlContain(html);
      setNewsLetter(values);
      setOpenSave(true);
    },
    []
  );

  console.log(htmlContain);
  const redirectPage = () => {
    return navigate("/masters/news-letter/list");
    // if (intialData.submit_btn_code === "save_add_new") {
    //     return navigate("/masters/email-template/new");
    // }
    // if (intialData.submit_btn_code === "save_close") {
    //     return navigate("/masters/email-template/list");
    // }
    // if (intialData.submit_btn_code === "save") {
    //     return navigate("/masters/email-template/edit/" + intialData.id + "/" + intialData.template_id);
    // }
  }


  const saveNewsLetter = async (data: any) => {
    toastId.current = toast("Updating news letter...", {
      autoClose: false,
      isLoading: true,
    });
    const postData = {
      mjml: { ...newsLetter },
      ...data,
      id,
      html: htmlContain,
      news_letter_id
    }
    const res = await newsLetterService.update(postData);
    if (res?.status === "success") {
      toast.update(toastId.current, {
        type: toast.TYPE.SUCCESS,
        render: "News letter update successfully",
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      redirectPage();
    }
    console.log(res);
  }



  const onUploadImage = async (blob: any) => {
    var formData = new FormData()
    formData.append('file', blob);
    return newsLetterService.uploadImage(formData);
  };

  if (!initialValues) return null;

  return (
    <div>
      <EmailEditorProvider
        dashed={false}
        data={initialValues}
        height={'calc(100vh - 85px)'}
        onUploadImage={onUploadImage}
        autoComplete
        fontList={fontList}
        onSubmit={onSubmit}
        locale={localesi18n[ckEditorI18()]}
      >
        {({ values }, { submit }) => {
          return (
            <>
              <Row style={{ marginTop: '60px', background: '#fff', borderTop: '1px solid #ebebeb' }} >
                <Col className='m-1'>
                  {/* <Button onClick={() => onCopyHtml(values)}>
                    Copy Html
                  </Button>
                  <Button onClick={() => onExportMjml(values)}>
                    Export Template
                  </Button>
                  <Button onClick={onImportMjml}>
                    import Template
                  </Button> */}
                  <Breadcrumbs
                    breadcrumbTitle=""
                    breadcrumbItems={breadcrumbItems}
                    endAdornment={
                      <>
                        <Nav className="nav justify-content-end">
                          <NavItem>
                            <Link
                              to="#"
                              onClick={() => submit()}
                              className="btn btn-primary btn-label btn-sm"
                            >
                              <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">save</span> Save
                            </Link>
                          </NavItem>
                        </Nav>
                      </>
                    }
                  />

                </Col>
              </Row>
              <SimpleLayout
                showSourceCode
                //defaultShowLayer={B}
              > <Suspense fallback={<>Loading...</>}>
                  <EmailEditor
                  />
                </Suspense>
              </SimpleLayout>
            </>
          );
        }}
      </EmailEditorProvider>
      {openSave &&
        <SaveNewsLetter
          show={openSave}
          onCloseClick={() => setOpenSave(false)}
          data={postData}
          onSubmitClick={saveNewsLetter}
        />}
    </div>
  );
}
