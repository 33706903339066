import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Form,
  Label,
  Input,
  Button,
  FormFeedback,
  Spinner,
  InputGroup,
  FormGroup,
} from "reactstrap";
import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";
import labelPlugin from "flatpickr/dist/plugins/labelPlugin/labelPlugin";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
// Static Data
import { taskStatusOptionsTrans, taskPriorityTrans } from "../../common/staticdata";

// Service
import commonService from "../../services/common.service";
import subtasksService from "../../services/subtasks.service";

// Import Breadcrumb
import Breadcrumbs from "../../components/Layout/Breadcrumb";

import {
  filteredOptions,
  filteredByFunction,
  getOptionById,
  getOptionValueById,
  yearsBackword,
  yearsOnward,
  filterFeesTypeByLanguage,
  getDependentUserOptions_New,
  getUserProfile,
  filteredByStatus,
  datePickerI18,
  handleAutoSave,
} from "../../helpers/functions_helper";

import NewTimeSheetFormModal from "../TimeSheets/NewTimeSheetForm";

import MultiComments from "../../components/Common/MultiComments";
import Dropzone from "../../components/Common/Dropzone";

import ScrollUp from "../../components/ScrollUpDown/ScrollUp";
import ScrollDown from "../../components/ScrollUpDown/ScrollDown";

import Drawer from "../../components/Drawer";
import EditCompany from "../Masters/Company/EditCompany";
import EditUser from "../Users/EditUser";
import tasksService from "../../services/tasks.service";
import timeSheetsService from "../../services/time.sheets.service";

import { useCallbackPrompt } from '../../hooks/useCallbackPrompt'
import Alert from "../../components/Common/Alert";
import NavigationAlert from "../../components/Common/NavigationAlert";

// const breadcrumbItems = [
//   {
//     url: "/dashboard",
//     label: "Dashboard",
//     active: false,
//   },
//   {
//     url: "/tasks/task-details/",
//     label: "Subtasks",
//     active: false,
//   },
//   {
//     url: "#",
//     label: "Create Subtask",
//     active: true,
//   },
// ];

const NewSubTask = (props: any) => {
  document.title = "Create Subtask | " + process.env.REACT_APP_NAME;

  const { id, task_id } = useParams();
  const { t } = useTranslation();
  const userProfile = getUserProfile("all-sub-task");
  const userAccess = getUserProfile("all-users");
  const companyAccess = getUserProfile("all-company");
  const timesheetProfile = getUserProfile("all-time-sheets");
  const subtaskToast = React.useRef(null) as any;
  const timesheetToast = React.useRef(null) as any;
  let navigate = useNavigate();

  // --------------------------------------------------------------------
  const [loading, setLoading] = useState(true);
  const [insertId, setInsertId] = useState(0);
  const [insertSubId, setInsertSubId] = useState("");
  const [redirectCode, setRedirectCode] = useState("");
  const [multiCommentsStatus, setMultiCommentsStatus] = useState(false);
  const [multiUploadStatus, setMultiUploadStatus] = useState(false);
  const [timesheetStatus, setTimesheetStatus] = useState(false);

  const multiCommentsSuccess = (isSuccess: boolean) => {
    if (isSuccess === true) {
      setMultiCommentsStatus(true);
    }
  };
  const multiUploadSuccess = (isSuccess: boolean) => {
    if (isSuccess === true) {
      setMultiUploadStatus(true);
    }
  };
  // --------------------------------------------------------------------

  const localFormData = handleAutoSave("GET");
  const [autoSave, setAutoSave] = useState(false);
  const [autoSaveAlert, setAutoSaveAlert] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(autoSave);

  const [initialData, setInitialData] = useState({
    task_id: "",
    task_sub_id: "",
    task_custome_code: "",
    task_title: "",

    id: "",
    sub_task_id: "",
    code: "",
    custome_code: "",
    title: "",
    type: "",
    task_type: "",
    fees_amount: 0,
    category: "",
    category_of_fees: "",
    fees_code: "",
    is_preliminary_billing: 0,
    is_income_year: 0,
    is_income_year_onward: 0,
    is_assistant_administrative: 0,
    is_resident: 0,
    is_non_resident: 0,
    is_tax_return_type: 0,
    status: "1",
    preparer: "",
    preparer_progress: 0,
    reviewer: "",
    reviewer_progress: 0,
    file_manager: "",
    file_manager_progress: 0,
    partner: "",
    partner_progress: 0,
    assistant_administrative: "",
    assistant_administrative_progress: 0,
    priority: "",
    start_date: "",
    due_date: "",
    closing_date: "",
    company: "",
    company_id: "",
    company_name: "",
    company_language: null,
    project: "",
    contact_person_id: "",
    invoice_contact_person_id: "",
    hr_contact_person_id: "",
    contact_person: "",
    responsible_person: "",
    income_year: "",
    expense: 0,
    sensitive: 0,
    preliminary_billing: 0,
    is_preliminary_fixed_amount: 0,
    preliminary_percentage: 0,
    submit_btn_code: "save_add_new",
  });

  //Drawal for company and user
  const [openCompany, setOpenCompany] = useState<boolean>(false);
  const [openUser, setOpenUser] = useState<boolean>(false);
  const [company, setCompany] = useState<any>([]);
  const [selectedUser, setSelectedUser] = useState<any>([]);

  const handleCompanyClick = () => {
    if (initialData.company) {
      setCompany({
        company: initialData.company,
        company_id: initialData.company_id,
      });
      setOpenCompany(true);
    }
  };

  const handleUserClick = (type: number) => {
    let user_id = 0;
    switch (type) {
      case 0:
        user_id = getOptionValueById(preparerOption, parseInt(initialData.preparer), "user_id");
        setSelectedUser({ id: initialData.preparer, user_id });
        setOpenUser(true);
        break;

      case 1:
        user_id = getOptionValueById(reviewerOption, parseInt(initialData.reviewer), "user_id");
        setSelectedUser({ id: initialData.reviewer, user_id });
        setOpenUser(true);
        break;

      //File manager
      case 2:
        user_id = getOptionValueById(
          fileManagerOption,
          parseInt(initialData.file_manager),
          "user_id"
        );
        setSelectedUser({ id: initialData.file_manager, user_id });
        setOpenUser(true);
        break;

      case 3:
        user_id = getOptionValueById(partnerOption, parseInt(initialData.partner), "user_id");
        setSelectedUser({ id: initialData.partner, user_id });
        setOpenUser(true);
        break;

      case 4:
        user_id = getOptionValueById(
          assistantAdministrativeOption,
          parseInt(initialData.assistant_administrative),
          "user_id"
        );
        setSelectedUser({ id: initialData.assistant_administrative, user_id });
        setOpenUser(true);
        break;

      case 5:
        user_id = getOptionValueById(
          contactPersonOption,
          parseInt(initialData.contact_person),
          "user_id"
        );
        setSelectedUser({ id: initialData.contact_person, user_id });
        setOpenUser(true);
        break;

      case 6:
        user_id = getOptionValueById(
          responsiblePersonOption,
          parseInt(initialData.responsible_person),
          "user_id"
        );
        setSelectedUser({ id: initialData.responsible_person, user_id });
        setOpenUser(true);
        break;
    }
  };

  const handleRedirect = (isCancel = false) => {
    setAutoSave(false);
    handleAutoSave("CLEAR");

    if (isCancel === true) {
      return navigate("/tasks/subtasks/" + id + "/" + task_id);
    }

    if (redirectCode === "save_add_new") {
      // return navigate("/tasks/new-sub-task/" + id + "/" + task_id + "?redirect=true");
      getTask();
      getResponsiblePersonList({
        company_id: initialData.company,
        task_id: id,
      });

      setInsertId(0);
      setInsertSubId("");
      setMultiCommentsStatus(false);
      setMultiUploadStatus(false);
      setTimesheetStatus(false);

      window.scrollTo(0, 0);
    } else if (redirectCode === "save_close") {
      return navigate("/tasks/subtasks/" + id + "/" + task_id + "?redirect=true");
    } else if (redirectCode === "save") {
      return navigate("/tasks/subtask-details/" + insertId + "/" + insertSubId + "?redirect=true");
    } else {
      return navigate("/tasks/subtasks/" + id + "/" + task_id + "?redirect=true");
    }
  };

  const handleFocus = (event: any) => event.target.select();

  const handleChange = (fieldName: string, fieldValue: string) => {
    setInitialData({ ...initialData, [fieldName]: fieldValue });
    setAutoSave(true);
  };

  const commentAttachmentIDs = [
    {
      value: initialData.preparer,
      label: t("Preparer"),
    },
    {
      value: initialData.reviewer,
      label: t("Reviewer"),
    },
    {
      value: initialData.file_manager,
      label: userProfile.IS_INTERNAL ? t("Responsible to sign off") : t("File manager"),
    },
    {
      value: initialData.partner,
      label: t("Partner"),
    },
    {
      value: initialData.assistant_administrative,
      label: t("Assistant Administrative"),
    },
    {
      value: initialData.contact_person,
      label: t("Contact person"),
    },
    {
      value: initialData.responsible_person,
      label: t("Person concerned"),
    },
  ];

  let {
    getUserOptionsList,
    // getCompanyOptionsList,
    getFeesOptionsList,
  } = useSelector((state: any) => ({
    getUserOptionsList: state.Common.GET_USER_OPTIONS_LIST_SUCCESS_NEW,
    // getCompanyOptionsList: state.Common.GET_COMPANY_OPTIONS_LIST_SUCCESS_NEW,
    getFeesOptionsList: state.Common.GET_FEES_OPTIONS_LIST_SUCCESS_NEW,
  }));

  // Generate custome code API call -------------------------------------------------
  const [loadingCode, setLoadingCode] = useState(false);
  const getCustomeCode = async (tableName: string, moduleCode: string) => {
    const response = await commonService.getCustomeCode(tableName, moduleCode);
    return response.data.data;
  };
  useEffect(() => {
    if (loadingCode === true) {
      getCustomeCode("tbl_subtask", "STSK").then(function (result) {
        const updatedInitialData = {
          ...initialData,
          code: result,
          custome_code: result,
        };
        setInitialData(updatedInitialData);
        setLoadingCode(false);
      });
    }
  }, [loadingCode]);

  useEffect(() => {
    if (
      redirectCode !== "" &&
      multiCommentsStatus === true &&
      multiUploadStatus === true &&
      timesheetStatus === true
    ) {
      handleRedirect();
    }
  }, [redirectCode, multiCommentsStatus, multiUploadStatus, timesheetStatus]);

  const [isSubmit, setIsSubmit] = useState(false);
  Yup.setLocale({
    mixed: {
      required: "${label} " + t("field is required."),
    },
    string: {
      min: "${label} " + t("must be at least") + " ${min} " + t("characters."),
      max: "${label} " + t("may not be greater than") + " ${max} " + t("characters."),
    },
  });
  const validationSchema = Yup.object().shape({
    custome_code: Yup.string().label(t("Subtask ID")).required().min(3).max(15),
    title: Yup.string().label(t("Subtask title")).required().min(2).max(200),
    status: Yup.string().label(t("Subtask status")).required(),
    preparer: Yup.string().label(t("Preparer")).required(),
    reviewer: Yup.string().label(t("Reviewer")).required(),
    file_manager: Yup.string().label(t("Responsible to sign off")).required(),
    partner: Yup.string().label(t("Partner")).required(),
    start_date: Yup.string().label(t("Start date")).required(),
    due_date: Yup.string().label(t("Internal due date")).required(),
    closing_date: Yup.string().label(t("Due date")).required(),
    priority: Yup.string().label(t("Subtask priority")).required(),
    contact_person: Yup.string()
      .label(t("Contact person"))
      .when(["sensitive"], {
        is: (sensitive: number) => sensitive !== 1,
        then: Yup.string().label(t("Contact person")).required(),
      }),
    responsible_person: Yup.string()
      .label(t("Person concerned"))
      .nullable()
      .when(["sensitive"], {
        is: (sensitive: number) => sensitive !== 1,
        then: Yup.string().label(t("Person concerned")).nullable().required(),
      }),
  });

  const create = async (values: newSubTaskPropsType) => {
    try {
      setLoading(true);
      subtaskToast.current = toast(`${t("Creating")} ${t("subtask")}...`, {
        autoClose: false,
        isLoading: true,
      });

      const res = (await subtasksService.createSubTask(values)) as any;
      if (res.data.status === "success") {
        setInsertId(res.data.data.id);
        setInsertSubId(res.data.data.subtask_id);
        setRedirectCode(res.data.data.submit_btn_code);

        toast.update(subtaskToast.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoading(false);
      } else {
        toast.update(subtaskToast.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        if (res.data.data) {
          Object.keys(res.data.data).map((key) => {
            toast(res.data.data[key][0], {
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              delay: 500,
            });
          });
        }
        setLoading(false);
      }
    } catch (err: any) {
      toast.update(subtaskToast.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values, { setErrors }) => {
      if (timeSpentData.time_spent) {
        create(values);
      } else {
        setIsSubmit(true);

        setTimeSpentData({
          is_new: true,
          module_id: 7, // Subtask
          module_main_id: 0,
          module_sub_id: "",
          main_id: id,
          title: initialData.title,
          company: initialData.company,
          project: initialData.project,
          contact_person: initialData.contact_person,
          responsible_person: initialData.responsible_person,
          fees_type: initialData.type,
          fees_amount: initialData.fees_amount,
          expense: initialData.expense,
          is_sensitive: initialData.sensitive,

          start_date: "",
          start_time: "",
          end_time: "",
          time_spent: "",
        });
        setNewTimeSpentFormModal(true);
      }
    },
  });

  useEffect(() => {
    if (!validation.isSubmitting) return;
    if (Object.keys(validation.errors).length > 0) {
      document
        .getElementById(Object.keys(validation.errors)[0])
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
      document.getElementById(Object.keys(validation.errors)[0])?.focus({ preventScroll: true });
    }
  }, [validation.isSubmitting, validation.errors]);

  // get and set Task API call -------------------------------------------------

  const getTask = async () => {
    try {
      setLoading(true);
      const res = (await tasksService.getTask({
        id,
        task_id,
      })) as any;
      if (res.data.status === "success") {
        updateInitialData(res.data.data[0]);
        setLoading(false);
      } else {
        toast(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 5000,
          delay: 500,
        });
        setLoading(false);
      }
    } catch (err: any) {
      toast(err?.message, {
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        delay: 500,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getTask();
  }, []);

  // const [feesAmount, setFeesAmount] = useState("");
  // const [feesAmountUsed, setFeesAmountUsed] = useState(false);

  function updateInitialData(data: any) {
    const updatedInitialData = {
      ...initialData,
      task_id: id || "",
      task_sub_id: task_id || "",
      task_custome_code: data.custome_code,
      task_title: data.title,

      id: "",
      subtask_id: "",
      code: "",
      custome_code: "",
      title: "",
      type: data.type,
      task_type: data.task_type,
      fees_amount: data.fees_amount,
      category: data.category,
      category_of_fees: data.category_of_fees,
      fees_code: data.fees_code,
      is_preliminary_billing: data.is_preliminary_billing,
      is_income_year: data.is_income_year,
      is_income_year_onward: data.is_income_year_onward,
      is_assistant_administrative: data.is_assistant_administrative,
      is_resident: data.is_resident,
      is_non_resident: data.is_non_resident,
      is_tax_return_type: data.is_tax_return_type,
      status: "1",
      preparer: data.preparer,
      preparer_progress: 0,
      reviewer: data.reviewer,
      // reviewer_progress: data.reviewer_progress,
      file_manager: data.file_manager,
      // file_manager_progress: data.file_manager_progress,
      partner: data.partner,
      // partner_progress: data.partner_progress,
      assistant_administrative: data.assistant_administrative,
      // assistant_administrative_progress: data.assistant_administrative_progress,
      priority: data.priority,
      start_date: moment().format("YYYY-MM-DD hh:mm:ss"),
      due_date: data.due_date,
      closing_date: data.closing_date,
      company: data.company,
      company_id: data.company_id,
      company_name: data.company_name,
      company_language: data.company_language,
      project: data.project,
      contact_person_id: data.contact_person_id,
      invoice_contact_person_id: data.invoice_contact_person_id,
      hr_contact_person_id: data.hr_contact_person_id,
      contact_person: data.hr_contact_person_id,
      responsible_person: "",
      income_year: data.income_year == null ? "" : data.income_year,
      expense: 0,
      sensitive: data.sensitive,
      preliminary_billing: data.preliminary_billing,
      is_preliminary_fixed_amount: data.is_preliminary_fixed_amount,
      preliminary_percentage: data.preliminary_percentage,
      submit_btn_code: "save_add_new",
    };
    setInitialData(updatedInitialData);

    setLoadingCode(true);
    // setFeesAmount(data.fees_amount);
    // setTypeID(data.type);
    setIsSubmit(false);
    window.scrollTo(0, 0);
  }

  // Select options list API call -------------------------------------------------
  const [query, setQuery] = useState("");
  const getSelectOptions = async (tableName: string, fieldName: string) => {
    const response = await commonService.getSelectOptionsList(
      tableName,
      fieldName,
      query ? query : ""
    );
    return response.data.data;
  };

  const getDependentSelectOptions = async (
    tableName: string,
    fieldName: string,
    whereField: string,
    whereID: number
  ) => {
    const response = await commonService.getDependentSelectOptionsList(
      tableName,
      fieldName,
      whereField,
      whereID,
      query ? query : ""
    );
    return response.data.data;
  };

  /* Fees Type Option */
  const [isPreliminaryType, setPreliminaryType] = useState<boolean>(false);
  useEffect(() => {
    if (initialData.is_preliminary_billing) {
      setPreliminaryType(true);
    } else {
      setPreliminaryType(false);
    }
  }, [initialData.is_preliminary_billing]);

  const [isShowIncomeYear, setIsShowIncomeYear] = useState<boolean>(false);
  const [incomeYearOption, setIncomeYearOption] = useState<any>([]);
  useEffect(() => {
    if (initialData.is_income_year || initialData.is_income_year_onward) {
      if (initialData.is_income_year) {
        setIncomeYearOption(yearsBackword);
      } else if (initialData.is_income_year_onward) {
        setIncomeYearOption(yearsOnward);
      }
      setIsShowIncomeYear(true);
    } else {
      setIsShowIncomeYear(false);
    }
  }, [initialData.is_income_year, initialData.is_income_year_onward]);

  const [isAssistantAdministrative, setIsAssistantAdministrative] = useState<boolean>(false);
  useEffect(() => {
    if (initialData.is_assistant_administrative) {
      setIsAssistantAdministrative(true);
    } else {
      setIsAssistantAdministrative(false);
    }
  }, [initialData.is_assistant_administrative]);

  // const [typeOption, setTypeOption] = useState([]);
  // const [loadingTypeOption, setloadingTypeOption] = useState(true);
  // const [typeID, setTypeID] = useState<number>(0);
  // useEffect(() => {
  //   if (initialData.type !== "") {
  //     if (getFeesOptionsList.data) {
  //       setTypeOption(getFeesOptionsList.data);
  //       // setloadingTypeOption(false);
  //     }
  //   }
  // }, [initialData.type, getFeesOptionsList]);
  /* End  Fees Type Option */

  useEffect(() => {
    if (getFeesOptionsList.data && initialData.type) {
      let feesOptionsList = getOptionById(
        filterFeesTypeByLanguage(getFeesOptionsList.data),
        parseInt(initialData.type)
      ) as any;
      setInitialData({
        ...initialData,
        task_type: feesOptionsList.label,
        category_of_fees: feesOptionsList.category_of_fees,
        category: feesOptionsList.category,
      });
    }
  }, [
    getFeesOptionsList,
    initialData.type,
    initialData.task_type,
    localStorage.getItem("I18N_LANGUAGE"),
  ]);

  /* Task Status Option */
  const [taskStatusOption, settaskStatusOption] = useState([]);
  // const [loadingStatusOption, setloadingStatusOption] = useState(true);
  const [disabledStatusOption, setDisabledStatusOption] = useState(false);
  useEffect(() => {
    let items = filteredOptions(taskStatusOptionsTrans(t) as any, [1, 2, 3]) as any;
    settaskStatusOption(items);
  }, [localStorage.getItem("I18N_LANGUAGE")]);
  /* End  Task Status Option */

  const [taskPriority, setTaskPriority] = useState([]);
  useEffect(() => {
    setTaskPriority(taskPriorityTrans(t) as any);
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  /* Reviewer Option */
  const [reviewerOption, setReviewerOption] = useState([]);
  /* FileManager Option */
  const [fileManagerOption, setFileManagerOption] = useState([]);
  /*Partner Option */
  const [partnerOption, setPartnerOption] = useState([]);
  /*Assistant Administrative Option */
  const [assistantAdministrativeOption, setAssistantAdministrativeOption] = useState([]);

  /* Filter Value */
  const filterValueForSelect = (prepareValue: any) => {
    setIsPreparerValueSelected(true);

    var newData: any = [...preparerOption];
    newData.map((item: any, index: number) => {
      if (item.value === prepareValue) {
        newData[index] = {
          label: item.label,
          value: item.value,
          isDisabled: true,
        };
      }
    });

    setReviewerOption(newData);
    if (initialData.preparer === initialData.reviewer) {
      initialData.reviewer = "";
    }

    setFileManagerOption(preparerOption);
    // setFileManagerOption(newData);
    // if (initialData.preparer === initialData.file_manager) {
    //   initialData.file_manager = "";
    // }

    let items1 = filteredByFunction(newData, [6]) as any; // Partners
    setPartnerOption(items1);
    if (initialData.preparer === initialData.partner) {
      initialData.partner = "";
    }

    let items2 = filteredByFunction(newData, [1]) as any; // Assistant
    // let items2 = filteredByFunction(preparerOption, [1]) as any; // Assistant Administrative
    setAssistantAdministrativeOption(items2);
    // initialData.assistant_administrative = "";

    setIsPreparerValueSelected(false);
  };
  /*End Filter Value */

  /* ROLE Preparer, Reviewer, FileManager, Partner, Assistant Administrative Option */
  const [preparerOption, setPreparerOption] = useState([]);
  const [loadingPreparer, setloadingPreparer] = useState(true);
  const [isPreparerValueSelected, setIsPreparerValueSelected] = useState<boolean>(true);
  useEffect(() => {
    if (getUserOptionsList.data) {
      setPreparerOption(getUserOptionsList.data);
      setloadingPreparer(false);
    }
  }, [getUserOptionsList]);

  useEffect(() => {
    if (initialData.preparer !== "" && preparerOption.length !== 0) {
      filterValueForSelect(initialData.preparer);

      if (userProfile.USER_ID != initialData.preparer) {
        setInitialData({
          ...initialData,
          status: "1",
          preparer_progress: 0,
        });
        setDisabledStatusOption(true);
      } else {
        setDisabledStatusOption(false);
      }
    }
  }, [initialData.preparer, preparerOption]);
  /* End ROLE Preparer, Reviewer, FileManager, Partner, Assistant Administrative Option */

  /***
   * Sensitive Task
   */
  useEffect(() => {
    if (initialData.sensitive == 1) {
      const newData = [...preparerOption];
      let items1 = filteredByFunction(newData, [6]) as any;
      setPartnerOption(items1);
      setPreparerOption(items1);
      setReviewerOption(items1);
      setFileManagerOption(items1);
    } else {
      if (getUserOptionsList.data) {
        setPreparerOption(getUserOptionsList.data);
        setloadingPreparer(false);
      }
    }
  }, [initialData.sensitive]);

  /* Company Master and User */
  const [loadingCompanyUserOption, setloadingCompanyUserOption] = useState(true);
  const [companyLanguage, setCompanyLanguage] = useState<any>(null);

  useEffect(() => {
    setCompanyLanguage(
      initialData.company_language === 1
        ? "EN"
        : initialData.company_language === 2
        ? "FR"
        : initialData.company_language === 3
        ? "NL"
        : null
    );
  }, [initialData.company_language]);

  const [responsiblePersonOption, setResponsiblePersonOption] = useState([]);
  const [contactPersonOption, setContactPersonOption] = useState([]);
  const [projectOption, setProjectOption] = useState([]);

  const getResponsiblePersonList = async (data: any) => {
    const res = await subtasksService.getSubTasksResposiblePersonList(data);
    if (res.data.status === "success") {
      let resPerson: any = [...responsiblePersonOption];
      //if task already create for that user then disable user
      if (res.data.data?.length > 0 && resPerson.length > 0) {
        if (resPerson.length > 0)
          res.data.data.map((u: any, index: number) => {
            resPerson.map((item: any, i: number) => {
              if (item.value === u) {
                resPerson[i] = {
                  label: item.label,
                  value: item.value,
                  isDisabled: true,
                };
              }
            });
          });
        setResponsiblePersonOption(resPerson);
      }
    }
  };

  useEffect(() => {
    getResponsiblePersonList({ company_id: initialData.company, task_id: id });
  }, [responsiblePersonOption.length > 0]);

  useEffect(() => {
    if (initialData.company) {
      setloadingCompanyUserOption(true);

      setResponsiblePersonOption([]);
      setContactPersonOption([]);

      getDependentSelectOptions(
        "tbl_projects",
        "name",
        "company_id",
        parseInt(initialData.company)
      ).then(function (options) {
        setProjectOption(options);
      });

      getDependentUserOptions_New(
        "tbl_master_users",
        "first_name",
        "company",
        parseInt(initialData.company)
      ).then(function (options) {
        if (initialData?.is_resident === 1) {
          let items = filteredByStatus(options, 1) as any;
          setResponsiblePersonOption(items);
        } else if (initialData?.is_non_resident === 1) {
          let items = filteredByStatus(options, 2) as any;
          setResponsiblePersonOption(items);
        } else {
          setResponsiblePersonOption(options);
        }

        // Contact person ------------------------------------------
        if (parseInt(initialData.company) === 0) {
          setContactPersonOption(options);
        } else {
          let contactFilterArray = Array();
          contactFilterArray.push(parseInt(initialData?.contact_person_id));
          contactFilterArray.push(parseInt(initialData?.invoice_contact_person_id));
          contactFilterArray.push(parseInt(initialData?.hr_contact_person_id));

          let contact_persons = filteredOptions(options as any, contactFilterArray) as any;
          contact_persons.push({ value: "", label: "-- Remove --" });
          setContactPersonOption(contact_persons);
        }
        // Contact person ------------------------------------------

        setloadingCompanyUserOption(false);
      });
    }
  }, [initialData.company, initialData?.is_resident, initialData?.is_non_resident]);
  /* End Company Master and User */

  // Time Spent Model ----------------------------------------------------
  const [timeSpentData, setTimeSpentData] = useState<any>({});
  const [newTimeSpentFormModal, setNewTimeSpentFormModal] = useState(false);
  const onClickTimeSpent = () => {
    setTimeSpentData({
      is_new: true,
      module_id: 7, // Subtask
      module_main_id: 0,
      module_sub_id: "",
      main_id: id,
      title: initialData.title,
      company: initialData.company,
      project: initialData.project,
      contact_person: initialData.contact_person,
      responsible_person: initialData.responsible_person,
      fees_type: initialData.type,
      fees_amount: initialData.fees_amount,
      expense: initialData.expense,
      is_sensitive: initialData.sensitive,

      start_date: "",
      start_time: "",
      end_time: "",
      time_spent: "",
    });
    setNewTimeSpentFormModal(true);
  };

  const handleSubmitTimeSpent = (values: any) => {
    const updatedTimeSpentData = {
      ...timeSpentData,
      title: values.title,
      start_date: values.start_date,
      start_time: values.start_time,
      end_time: values.end_time,
      time_spent: values.time_spent,
    };
    setTimeSpentData(updatedTimeSpentData);
    setNewTimeSpentFormModal(false);

    if (isSubmit === true) {
      validation.handleSubmit();
    }
  };

  useEffect(() => {
    if (insertId > 0 && insertSubId !== "") {
      const updatedTimeSpentData = {
        ...timeSpentData,
        module_main_id: insertId,
        module_sub_id: insertSubId,
      };
      setTimeSpentData(updatedTimeSpentData);
    }
  }, [insertId, insertSubId]);

  const createTimeSheet = async (values: any) => {
    try {
      timesheetToast.current = toast(`${t("Creating")} ${t("timesheet")}...`, {
        autoClose: false,
        isLoading: true,
      });

      const updatedTimeSpentData = {
        ...timeSpentData,
        time_spent: "",
      };
      setTimeSpentData(updatedTimeSpentData);

      const res = (await timeSheetsService.createTimeSheetForm(values)) as any;
      if (res.data.status === "success") {
        setTimesheetStatus(true);

        toast.update(timesheetToast.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoading(false);
      } else {
        toast.update(timesheetToast.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        if (res.data.data) {
          Object.keys(res.data.data).map((key) => {
            toast(res.data.data[key][0], {
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              delay: 500,
            });
          });
        }
        setLoading(false);
      }
    } catch (err: any) {
      toast.update(timesheetToast.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (timeSpentData.time_spent && timeSpentData.module_main_id > 0) {
      createTimeSheet(timeSpentData);
    }
  }, [timeSpentData]);
  // Time Spent Model ----------------------------------------------------

  useEffect(() => {
    if (autoSave) { handleAutoSave("SET", initialData); }
  }, [initialData, autoSave]);

  return (
    <React.Fragment>
      <ScrollUp />
      <ScrollDown top={"100px"} />

      <Alert 
        open={autoSaveAlert} 
        title={t("Autosaved data restored!")} 
        message={t("Your last autosaved data has been restored from the session. In order to avoid the data loss, you need either save it to retain the data or Cancel to loose the data.")} 
        onClickOk={() => setAutoSaveAlert(false)} 
      />
      <NavigationAlert
        open={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />

      {newTimeSpentFormModal && (
        <NewTimeSheetFormModal
          show={newTimeSpentFormModal}
          loading={loading}
          timeSpentData={timeSpentData}
          onSubmitClick={handleSubmitTimeSpent}
          onCloseClick={() => setNewTimeSpentFormModal(false)}
        />
      )}

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={`${t("Update")} ${t("subtask")}`}
            breadcrumbItems={[
              {
                url: "/dashboard",
                label: t("Dashboard"),
                active: false,
              },
              {
                url: "/tasks/all-tasks",
                label: t("Tasks"),
                active: false,
              },
              {
                url: "/tasks/subtasks/" + id + "/" + task_id,
                label: t("Subtasks"),
                active: false,
              },
              {
                url: "#",
                label: `${t("Create")} ${t("subtask")}`,
                active: true,
              },
            ]}
          />

          <Row>
            <Col xl="12">
              <Card>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                  autoComplete="off"
                >
                  <Input id="id" name="id" type="hidden" />
                  <Input id="subtask_id" name="subtask_id" type="hidden" />
                  <Input id="code" name="code" type="hidden" value={initialData.custome_code} />

                  <CardBody>
                    <Row>
                      {userProfile.USER_FUNCTION === 6 && initialData.sensitive == 1 && (
                        <Col lg={2} className="mb-3 d-flex align-items-end">
                          <div className="mb-1 center-block ">
                            <Label className="form-label mb-3"></Label>
                            <FormGroup switch inline>
                              <Input
                                type="switch"
                                disabled={initialData.sensitive == 1 && true}
                                checked={initialData.sensitive == 1 ? true : false}
                                onChange={(e) => {
                                  handleChange("sensitive", e.target.checked ? "1" : "0");
                                }}
                              />
                              <Label check>{t("Sensitive")}</Label>
                            </FormGroup>
                          </div>
                        </Col>
                      )}

                      <Col className="col-12"></Col>

                      <Col className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Subtask type")} <span className="text-warning is_required">*</span>
                          </Label>
                          <Input
                            id="type"
                            name="type"
                            className="form-control"
                            type="text"
                            value={initialData.task_type}
                            readOnly={true}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label">{t("Category of subtask")}</Label>
                          <Input
                            id="category_of_fees"
                            name="category_of_fees"
                            className="form-control"
                            type="text"
                            value={initialData.category_of_fees}
                            readOnly={true}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label">{t("Subtask code")}</Label>
                          <Input
                            id="fees_code"
                            name="fees_code"
                            className="form-control"
                            type="text"
                            value={initialData.fees_code}
                            readOnly={true}
                          />
                        </div>
                      </Col>

                      {userProfile?.CAN_VIEW_FINANCIAL ? (
                        <Col className="col-3">
                          <div className="mb-3">
                            <Label className="form-label">{t("Subtask amount")}</Label>
                            <Input
                              id="fees_amount"
                              name="fees_amount"
                              className="form-control"
                              type="number"
                              step=".01"
                              min={0}
                              value={initialData.fees_amount}
                              disabled={true}
                            />
                          </div>
                        </Col>
                      ) : null}

                      {/* <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label">{t("Subtask category")}</Label>
                          <Input
                            id="category"
                            name="category"
                            className="form-control"
                            type="text"
                            value={initialData.category}
                            readOnly={true}
                          />
                        </div>
                      </Col>

                      <Col className="col-12">
                        <hr></hr>
                      </Col> */}

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label">{t("Expense")}</Label>
                          <Input
                            id="expense"
                            name="expense"
                            className="form-control"
                            type="number"
                            step=".01"
                            min={0}
                            value={initialData.expense}
                            onBlur={() => validation.setFieldTouched("expense", true)}
                            onFocus={handleFocus}
                            onChange={(e: any) => {
                              handleChange("expense", e.target.value);
                            }}
                          />
                        </div>
                      </Col>

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label">
                            {" "}
                            {initialData.company &&
                            initialData.company !== "0" &&
                            companyAccess.CAN_VIEW_ALL ? (
                              <Link
                                to="#"
                                onClick={() => handleCompanyClick()}
                                className="text-decoration-underline"
                              >
                                {t(`Client`)}
                              </Link>
                            ) : (
                              t(`Client`)
                            )}{" "}
                            {companyLanguage && (
                              <span
                                className="badge rounded-pill bg-primary"
                                title={t("Client's language")}
                              >
                                {companyLanguage}
                              </span>
                            )}
                          </Label>
                          <Input
                            id="company_name"
                            name="company_name"
                            className="form-control"
                            type="text"
                            value={initialData.company_name}
                            readOnly={true}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label">{t("Project")}</Label>
                          <Select
                            id="project"
                            name="project"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={projectOption}
                            value={
                              initialData.project
                                ? projectOption.find(
                                    (option: any) => option.value == initialData.project
                                  )
                                : ""
                            }
                            isSearchable={true}
                            isDisabled={true}
                            onBlur={() => validation.setFieldTouched("project", true)}
                            onChange={(e: any) => {
                              handleChange("project", e.value);
                            }}
                          />

                          {validation.touched.project && validation.errors.project ? (
                            <FormFeedback type="invalid">{validation.errors.project}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      {initialData.sensitive == 0 && (
                        <Col className="col-3">
                          <div className="mb-3">
                            <Label className="form-label">
                              {initialData.contact_person && userAccess.CAN_VIEW_ALL ? (
                                <Link
                                  to="#"
                                  onClick={() => handleUserClick(5)}
                                  className="text-decoration-underline"
                                >
                                  {t("Contact person")}
                                </Link>
                              ) : (
                                t("Contact person")
                              )}{" "}
                              <span className="text-warning is_required">*</span>
                            </Label>
                            <Select
                              id="contact_person"
                              name="contact_person"
                              className="is-invalid"
                              placeholder={t("Select...")}
                              options={contactPersonOption}
                              value={
                                initialData.contact_person
                                  ? contactPersonOption.find(
                                      (option: any) => option.value == initialData.contact_person
                                    )
                                  : ""
                              }
                              isSearchable={true}
                              isDisabled={loadingCompanyUserOption}
                              onBlur={() => validation.setFieldTouched("contact_person", true)}
                              onChange={(e: any) => {
                                handleChange("contact_person", e.value);
                              }}
                            />

                            {validation.touched.contact_person &&
                            validation.errors.contact_person ? (
                              <FormFeedback type="invalid">
                                {validation.errors.contact_person}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      )}

                      {initialData.sensitive == 0 && (
                        <Col className="col-3">
                          <div className="mb-3">
                            <Label className="form-label">
                              {initialData.responsible_person && userAccess.CAN_VIEW_ALL ? (
                                <Link
                                  to="#"
                                  onClick={() => handleUserClick(6)}
                                  className="text-decoration-underline"
                                >
                                  {t("Person concerned")}
                                </Link>
                              ) : (
                                t("Person concerned")
                              )}{" "}
                              <span className="text-warning is_required">*</span>
                            </Label>
                            <Select
                              id="responsible_person"
                              name="responsible_person"
                              className="is-invalid"
                              placeholder={t("Select...")}
                              options={responsiblePersonOption}
                              value={
                                initialData.responsible_person
                                  ? responsiblePersonOption.find(
                                      (option: any) =>
                                        option.value == initialData.responsible_person
                                    )
                                  : ""
                              }
                              isSearchable={true}
                              isDisabled={loadingCompanyUserOption}
                              onBlur={() => validation.setFieldTouched("responsible_person", true)}
                              onChange={(e: any) => {
                                handleChange("responsible_person", e.value);
                              }}
                            />

                            {validation.touched.responsible_person &&
                            validation.errors.responsible_person ? (
                              <FormFeedback type="invalid">
                                {validation.errors.responsible_person}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      )}

                      <Col className="col-12"></Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label">{t("Task ID")}</Label>
                          <Input
                            id="task_custome_code"
                            name="task_custome_code"
                            className="form-control"
                            type="text"
                            value={initialData.task_custome_code}
                            readOnly={true}
                          />
                        </div>
                      </Col>

                      <Col className="col-9">
                        <div className="mb-3">
                          <Label className="form-label">{t("Task title")}</Label>
                          <Input
                            className="form-control"
                            placeholder="Task title"
                            type="text"
                            value={initialData.task_title}
                            readOnly={true}
                          />
                        </div>
                      </Col>

                      <Col className="col-12"></Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Subtask ID")} <span className="text-warning is_required">*</span>
                          </Label>
                          <Input
                            id="custome_code"
                            name="custome_code"
                            className="form-control"
                            placeholder="Subtask ID"
                            type="text"
                            value={initialData.custome_code}
                            readOnly={loadingCode}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("custome_code", e.target.value)}
                            invalid={
                              validation.touched.custome_code && validation.errors.custome_code
                                ? true
                                : false
                            }
                          />
                          {loadingCode ? <Spinner /> : ""}
                          {validation.touched.custome_code && validation.errors.custome_code ? (
                            <FormFeedback type="invalid">
                              {validation.errors.custome_code}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-9">
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Subtask title")} <span className="text-warning is_required">*</span>
                          </Label>
                          <Input
                            id="title"
                            name="title"
                            placeholder={t("Subtask title")}
                            type="text"
                            maxLength={200}
                            // autoFocus={true}
                            value={initialData.title}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("title", e.target.value)}
                            invalid={
                              validation.touched.title && validation.errors.title ? true : false
                            }
                          />
                          {validation.touched.title && validation.errors.title ? (
                            <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label">
                            {initialData.preparer && userAccess.CAN_VIEW_ALL ? (
                              <Link
                                to="#"
                                onClick={() => handleUserClick(0)}
                                className="text-decoration-underline"
                              >
                                {t("Preparer")}
                              </Link>
                            ) : (
                              t("Preparer")
                            )}{" "}
                            <span className="text-warning is_required">*</span>
                          </Label>
                          <Select
                            id="preparer"
                            name="preparer"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={preparerOption}
                            value={
                              initialData.preparer
                                ? preparerOption.find(
                                    (option: any) => option.value == initialData.preparer
                                  )
                                : ""
                            }
                            isSearchable={true}
                            isDisabled={initialData.sensitive === 1 ? true : loadingPreparer}
                            onBlur={() => validation.setFieldTouched("preparer", true)}
                            onChange={(e: any) => {
                              handleChange("preparer", e.value);
                            }}
                          />
                          {validation.touched.preparer && validation.errors.preparer ? (
                            <FormFeedback type="invalid">{validation.errors.preparer}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      {initialData.sensitive === 0 && (
                        <>
                          <Col className="col-3">
                            <div className="mb-3">
                              <Label className="form-label">
                                {initialData.reviewer && userAccess.CAN_VIEW_ALL ? (
                                  <Link
                                    to="#"
                                    onClick={() => handleUserClick(1)}
                                    className="text-decoration-underline"
                                  >
                                    {t("Reviewer")}
                                  </Link>
                                ) : (
                                  t("Reviewer")
                                )}{" "}
                                <span className="text-warning is_required">*</span>
                              </Label>

                              <Select
                                id="reviewer"
                                name="reviewer"
                                className="is-invalid"
                                placeholder={t("Select...")}
                                options={reviewerOption}
                                value={
                                  initialData.reviewer
                                    ? reviewerOption.find(
                                        (option: any) => option.value == initialData.reviewer
                                      )
                                    : ""
                                }
                                isSearchable={true}
                                isDisabled={isPreparerValueSelected}
                                onBlur={() => validation.setFieldTouched("reviewer", true)}
                                onChange={(e: any) => {
                                  handleChange("reviewer", e.value);
                                }}
                              />

                              {validation.touched.reviewer && validation.errors.reviewer ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.reviewer}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col className="col-3">
                            <div className="mb-3">
                              <Label className="form-label">
                                {initialData.file_manager && userAccess.CAN_VIEW_ALL ? (
                                  <Link
                                    to="#"
                                    onClick={() => handleUserClick(2)}
                                    className="text-decoration-underline"
                                  >
                                    {userProfile.IS_INTERNAL
                                      ? t("Responsible to sign off")
                                      : t("File manager")}
                                  </Link>
                                ) : userProfile.IS_INTERNAL ? (
                                  t("Responsible to sign off")
                                ) : (
                                  t("File manager")
                                )}{" "}
                                <span className="text-warning is_required">*</span>
                              </Label>

                              <Select
                                id="file_manager"
                                name="file_manager"
                                className="is-invalid"
                                placeholder={t("Select...")}
                                options={fileManagerOption}
                                value={
                                  initialData.file_manager
                                    ? fileManagerOption.find(
                                        (option: any) => option.value == initialData.file_manager
                                      )
                                    : ""
                                }
                                isSearchable={true}
                                isDisabled={isPreparerValueSelected}
                                onBlur={() => validation.setFieldTouched("file_manager", true)}
                                onChange={(e: any) => {
                                  handleChange("file_manager", e.value);
                                }}
                              />

                              {validation.touched.file_manager && validation.errors.file_manager ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.file_manager}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </>
                      )}

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label">
                            {initialData.partner && userAccess.CAN_VIEW_ALL ? (
                              <Link
                                to="#"
                                onClick={() => handleUserClick(3)}
                                className="text-decoration-underline"
                              >
                                {t("Partner")}
                              </Link>
                            ) : (
                              t("Partner")
                            )}{" "}
                            <span className="text-warning is_required">*</span>
                          </Label>

                          <Select
                            id="partner"
                            name="partner"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={partnerOption}
                            value={
                              initialData.partner
                                ? partnerOption.find(
                                    (option: any) => option.value == initialData.partner
                                  )
                                : ""
                            }
                            isSearchable={true}
                            isDisabled={isPreparerValueSelected}
                            onBlur={() => validation.setFieldTouched("partner", true)}
                            onChange={(e: any) => {
                              handleChange("partner", e.value);
                            }}
                          />

                          {validation.touched.partner && validation.errors.partner ? (
                            <FormFeedback type="invalid">{validation.errors.partner}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      {isAssistantAdministrative && initialData.sensitive == 0 && (
                        <Col className="col-3">
                          <div className="mb-3">
                            <Label className="form-label">
                              {initialData.assistant_administrative && userAccess.CAN_VIEW_ALL ? (
                                <Link
                                  to="#"
                                  onClick={() => handleUserClick(4)}
                                  className="text-decoration-underline"
                                >
                                  {t("Assistant administrative")}
                                </Link>
                              ) : (
                                t("Assistant administrative")
                              )}{" "}
                              <span className="text-warning is_required">*</span>
                            </Label>

                            <Select
                              id="assistant_administrative"
                              name="assistant_administrative"
                              className="is-invalid"
                              placeholder={t("Select...")}
                              options={assistantAdministrativeOption}
                              value={
                                initialData.assistant_administrative
                                  ? assistantAdministrativeOption.find(
                                      (option: any) =>
                                        option.value == initialData.assistant_administrative
                                    )
                                  : ""
                              }
                              isSearchable={true}
                              isDisabled={isPreparerValueSelected}
                              onBlur={() =>
                                validation.setFieldTouched("assistant_administrative", true)
                              }
                              onChange={(e: any) => {
                                handleChange("assistant_administrative", e.value);
                              }}
                            />

                            {validation.touched.assistant_administrative &&
                            validation.errors.assistant_administrative ? (
                              <FormFeedback type="invalid">
                                {validation.errors.assistant_administrative}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      )}

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <Col className="col-4">
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Start date")} <span className="text-warning is_required">*</span>
                          </Label>
                          <div className="input-group is-invalid">
                            <Flatpickr
                              id="start_date"
                              name="start_date"
                              className="form-control d-block"
                              type="text"
                              value={initialData.start_date}
                              placeholder="DD/MM/YYYY"
                              options={{
                                altInput: true,
                                altFormat: "d/m/Y",
                                dateFormat: "Y-m-d H:i:S",
                                minDate: "today",
                                allowInput: true,
                                plugins: [labelPlugin()],
                                onChange: function (selectedDates, dateStr, instance) {
                                  handleChange("start_date", dateStr);
                                },
                                onClose: function (selectedDates, dateStr, instance) {
                                  handleChange("start_date", dateStr);
                                },
                                locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                              }}
                            />
                            <span className="input-group-text">
                              <span className="align-middle material-symbols-outlined fw-light fs-4">
                                calendar_today
                              </span>
                            </span>
                          </div>
                          {validation.touched.start_date && validation.errors.start_date ? (
                            <FormFeedback type="invalid">
                              {validation.errors.start_date}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-4">
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Internal due date")}{" "}
                            <span className="text-warning is_required">*</span>
                          </Label>
                          <div className="input-group is-invalid">
                            <Flatpickr
                              id="due_date"
                              name="due_date"
                              className="form-control d-block"
                              type="text"
                              value={initialData.due_date}
                              placeholder="DD/MM/YYYY"
                              options={{
                                altInput: true,
                                altFormat: "d/m/Y",
                                dateFormat: "Y-m-d H:i:S",
                                minDate: "today",
                                allowInput: true,
                                plugins: [labelPlugin()],
                                onChange: function (selectedDates, dateStr, instance) {
                                  handleChange("due_date", dateStr);
                                },
                                onClose: function (selectedDates, dateStr, instance) {
                                  handleChange("due_date", dateStr);
                                },
                                locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                              }}
                            />
                            <span className="input-group-text">
                              <span className="align-middle material-symbols-outlined fw-light fs-4">
                                calendar_today
                              </span>
                            </span>
                          </div>
                          {validation.touched.due_date && validation.errors.due_date ? (
                            <FormFeedback type="invalid">{validation.errors.due_date}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-4">
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Due date")} <span className="text-warning is_required">*</span>
                          </Label>
                          <div className="input-group is-invalid">
                            <Flatpickr
                              id="closing_date"
                              name="closing_date"
                              className="form-control d-block"
                              type="text"
                              value={initialData.closing_date}
                              placeholder="DD/MM/YYYY"
                              options={{
                                altInput: true,
                                altFormat: "d/m/Y",
                                dateFormat: "Y-m-d H:i:S",
                                minDate: initialData.due_date ? initialData.due_date : "today",
                                allowInput: true,
                                plugins: [labelPlugin()],
                                onChange: function (selectedDates, dateStr, instance) {
                                  handleChange("closing_date", dateStr);
                                },
                                onClose: function (selectedDates, dateStr, instance) {
                                  handleChange("closing_date", dateStr);
                                },
                                locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                              }}
                            />
                            <span className="input-group-text">
                              <span className="align-middle material-symbols-outlined fw-light fs-4">
                                calendar_today
                              </span>
                            </span>
                          </div>
                          {validation.touched.closing_date && validation.errors.closing_date ? (
                            <FormFeedback type="invalid">
                              {validation.errors.closing_date}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-4">
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Subtask priority")}{" "}
                            <span className="text-warning is_required">*</span>
                          </Label>
                          <Select
                            id="priority"
                            name="priority"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={taskPriority}
                            value={
                              initialData.priority
                                ? taskPriority.find(
                                    (option: any) => option.value == initialData.priority
                                  )
                                : ""
                            }
                            formatOptionLabel={function (data: any) {
                              return (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: data.label,
                                  }}
                                />
                              );
                            }}
                            isSearchable={true}
                            onBlur={() => validation.setFieldTouched("priority", true)}
                            onChange={(e: any) => {
                              handleChange("priority", e.value);
                            }}
                          />
                          {validation.touched.priority && validation.errors.priority ? (
                            <FormFeedback type="invalid">{validation.errors.priority}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-4">
                        <div className="mb-3">
                          <Label className="form-label">{t("Subtask status")}</Label>
                          <Select
                            id="status"
                            name="status"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={taskStatusOption}
                            value={
                              initialData.status
                                ? taskStatusOption.find(
                                    (option: any) => option.value == initialData.status
                                  )
                                : ""
                            }
                            isSearchable={true}
                            // isLoading={loadingStatusOption}
                            isDisabled={disabledStatusOption}
                            onBlur={() => validation.setFieldTouched("status", true)}
                            onChange={(e: any) => {
                              handleChange("status", e.value);
                            }}
                          />
                          {validation.touched.status && validation.errors.status ? (
                            <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-4">
                        <div className="mb-3 float-start w-100">
                          <Label className="form-label">{t("Subtask progress")}</Label>
                          <InputGroup className="float-start">
                            <Input
                              id="preparer_progress"
                              name="preparer_progress"
                              className={
                                initialData.preparer_progress <= 25
                                  ? "form-control bg-danger"
                                  : initialData.preparer_progress > 25 &&
                                    initialData.preparer_progress <= 50
                                  ? "form-control bg-warning"
                                  : initialData.preparer_progress > 50 &&
                                    initialData.preparer_progress <= 75
                                  ? "form-control bg-primary"
                                  : initialData.preparer_progress > 75 &&
                                    initialData.preparer_progress <= 100
                                  ? "form-control bg-success"
                                  : "form-control"
                              }
                              type="range"
                              min={0}
                              max={100}
                              step={5}
                              value={initialData.preparer_progress}
                              disabled={disabledStatusOption}
                              onBlur={validation.handleBlur}
                              onChange={(e) => {
                                handleChange("preparer_progress", e.target.value);
                              }}
                              style={{
                                padding: "18px",
                                border: "1px solid #d6dbe1",
                                borderRight: "none",
                              }}
                            />
                            <div className="input-group-text">
                              {initialData.preparer_progress} %
                            </div>
                          </InputGroup>
                        </div>
                      </Col>

                      <Col className="col-12">
                        <br></br>
                      </Col>

                      {isShowIncomeYear && (
                        <Col className="col-4">
                          <div className="mb-3">
                            <Label className="form-label">{t("Income year")}</Label>
                            <Select
                              id="income_year"
                              name="income_year"
                              className="is-invalid"
                              placeholder={t("Select...")}
                              options={incomeYearOption}
                              value={
                                initialData.income_year
                                  ? incomeYearOption.find(
                                      (option: any) => option.value == initialData.income_year
                                    )
                                  : ""
                              }
                              isDisabled={true}
                            />
                          </div>
                        </Col>
                      )}

                      {isPreliminaryType && (
                        <Col className="col-4 align-items-center">
                          <div className="form-check float-start mb-2 w-100">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value="1"
                              id="preliminary_billing"
                              checked={initialData.preliminary_billing == 1 ? true : false}
                              disabled={true}
                            />
                            <label className="form-check-label" htmlFor="preliminary_billing">
                              {t("Preliminary billing")}
                            </label>

                            <FormGroup switch inline className="float-end me-0">
                              <Input
                                type="switch"
                                checked={initialData.is_preliminary_fixed_amount ? true : false}
                                disabled={true}
                              />
                              <Label check>{t("Fixed amount")}</Label>
                            </FormGroup>
                          </div>
                          <InputGroup className="float-start">
                            <Input
                              id="preliminary_percentage"
                              name="preliminary_percentage"
                              className="form-control"
                              type="number"
                              step=".01"
                              min={0}
                              max={100}
                              value={initialData.preliminary_percentage}
                              readOnly={true}
                            />
                            <div className="input-group-text">
                              {initialData.is_preliminary_fixed_amount ? (
                                <span className="align-middle material-symbols-outlined fw-light fs-5">
                                  euro
                                </span>
                              ) : (
                                <span className="align-middle material-symbols-outlined fw-light fs-5">
                                  percent
                                </span>
                              )}
                            </div>
                          </InputGroup>
                        </Col>
                      )}

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <Col className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">{t("Comments")}</Label>
                          <MultiComments
                            moduleData={{
                              module_app_id: 7,
                              module_main_id: 0,
                              module_sub_id: "",
                              section_id: 0,
                            }}
                            commentForIDs={commentAttachmentIDs}
                            fetchData={false}
                            isOpen={true}
                            insertId={insertId}
                            insertSubId={insertSubId}
                            onSuccess={multiCommentsSuccess}
                          />
                        </div>
                      </Col>

                      <Col className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">{t("Attachments")}</Label>
                          <Dropzone
                            moduleData={{
                              module_app_id: 7,
                              module_main_id: 0,
                              module_sub_id: "",
                              section_id: 0,
                              file_path: "subtask",
                            }}
                            attachmentForIDs={commentAttachmentIDs}
                            fetchData={false}
                            isOpen={true}
                            insertId={insertId}
                            insertSubId={insertSubId}
                            onSuccess={multiUploadSuccess}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>

                  <CardFooter className="d-sm-flex flex-wrap bg-transparent border-top">
                    <div className="d-flex gap-2">
                      {timesheetProfile.CAN_CREATE && (
                        <Button
                          color="success"
                          className="btn-block"
                          type="button"
                          onClick={onClickTimeSpent}
                          disabled={loading}
                        >
                          {loading ? (
                            <span>
                              <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                                hourglass_empty
                              </span>
                              <span>{t("Please wait...")}</span>
                            </span>
                          ) : (
                            <span>{`${t("Update")} ${t("time spent")}`}</span>
                          )}
                        </Button>
                      )}
                    </div>
                    <div className="ms-auto d-flex gap-2 justify-content-end">
                      {userProfile.CAN_CREATE ? (
                        <>
                          <Button
                            color="primary"
                            className="btn-block"
                            type="submit"
                            onClick={() => handleChange("submit_btn_code", "save_add_new")}
                            disabled={loading}
                          >
                            {loading ? (
                              <span>
                                <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                                  hourglass_empty
                                </span>
                                <span>{t("Please wait...")}</span>
                              </span>
                            ) : (
                              <span>{t("Save & Add new")}</span>
                            )}
                          </Button>

                          <Button
                            color="primary"
                            className="btn-block"
                            type="submit"
                            onClick={() => handleChange("submit_btn_code", "save_close")}
                            disabled={loading}
                          >
                            {loading ? (
                              <span>
                                <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                                  hourglass_empty
                                </span>
                                <span>{t("Please wait...")}</span>
                              </span>
                            ) : (
                              <span>{t("Save & Close")}</span>
                            )}
                          </Button>

                          <Button
                            color="primary"
                            className="btn-block"
                            type="submit"
                            onClick={() => handleChange("submit_btn_code", "save")}
                            disabled={loading}
                          >
                            {loading ? (
                              <span>
                                <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                                  hourglass_empty
                                </span>
                                <span>{t("Please wait...")}</span>
                              </span>
                            ) : (
                              <span>{t("Save")}</span>
                            )}
                          </Button>

                          <Button
                            color="danger"
                            className="btn-block"
                            type="reset"
                            onClick={() => {
                              handleRedirect(true);
                            }}
                            disabled={loading}
                          >
                            {loading ? (
                              <span>
                                <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                                  hourglass_empty
                                </span>
                                <span>{t("Please wait...")}</span>
                              </span>
                            ) : (
                              <span>{t("Cancel")}</span>
                            )}
                          </Button>
                        </>
                      ) : null}
                    </div>
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
        <>
          <Drawer
            title={t("Client")}
            open={openCompany}
            onClose={() => setOpenCompany((state) => !state)}
            fullWidth
          >
            <EditCompany
              id={initialData?.company}
              company_id={initialData?.company_id}
              type={1}
              isWindow={true}
            />
          </Drawer>
          <Drawer
            title={t("User")}
            open={openUser}
            onClose={() => setOpenUser((state) => !state)}
            fullWidth
          >
            <EditUser
              id={selectedUser?.id}
              user_id={selectedUser?.user_id}
              type={1}
              isWindow={true}
            />
          </Drawer>
        </>
      </div>
    </React.Fragment>
  );
};

export default NewSubTask;
