import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Button from '../Button/Button';

type NavigationAlertProps = {
    open: any,
    confirmNavigation: any,
    cancelNavigation: any,
}

const NavigationAlert: FC<NavigationAlertProps> = ({ open = false, confirmNavigation, cancelNavigation }) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={open} modalTransition={{ timeout: 200 }} backdrop>
            <ModalHeader>
                <span className="material-symbols-outlined align-middle text-warning">
                    report
                </span>
                <span className='mx-2 font-size-16 bold'>
                    {t("Warning")}!
                </span>
            </ModalHeader>
            <ModalBody className='font-size-15'>{t("This page is asking you to confirm that you want to leave — information you’ve entered may not be saved.")}</ModalBody>
            <ModalFooter>
                <Button color='primary' onClick={confirmNavigation}>{t("Leave page")}</Button>
                <Button color='danger' onClick={cancelNavigation}>{t("Stay on page")}</Button>
            </ModalFooter>
        </Modal>
    )
}

export default NavigationAlert;