import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { noData } from '../../../utils/data';
import LoadingSpinner from '../../../components/Common/Loader';
import { secondsToTime, serialize } from '../../../utils/utils';
import GraphFilters from '../../../components/Chart/Filters';
import { getUserProfile, select2Styles, yearOptions } from '../../../helpers/functions_helper';
import ApplyFilter from '../../../components/Common/ApplyFilter';
import { useNavigate } from 'react-router-dom';
import gfilters from "../filter";
import { toast } from 'react-toastify';
import invoicesService from '../../../services/invoices.service';
import { currentMonth, currentYear, languageCodes } from "../../../common/staticdata";
import i18next from 'i18next';
import Select from "react-select";
import Chart from '../../../components/Chart';
interface RevenueStreamProps extends CartProps {
    filters?: any;
}

const RevenueStream: FC<RevenueStreamProps> = ({ hideHeading, filters = null }) => {

    const { t } = useTranslation();

    const userObj = getUserProfile('all-tasks');
    const navigate = useNavigate();
    const inititalFilter = {
        week: null,
        quarter: null,
        month: currentMonth,
        year: currentYear,
        user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_ID : null,
        user_name: null
    };

    const [loading, setLoading] = useState<boolean>(true);
    const [query, setQuery] = useState<string | null>(serialize(inititalFilter));
    const [filter, setFilter] = useState<any>(inititalFilter);
    const [selectedID, setSelectedID] = useState<any>({ id: null, event: null });
    const [formValues, setFormValues] = useState<any>(currentYear)


    const [data, setData] = useState<any>({
        series: [],
        information: [],
        options: {
            chart: {
                width: 380,
                type: 'pie',
                // events: {
                //     dataPointSelection: (event: any, chartContext: any, config: any) => {
                //         setSelectedID({ id: config.dataPointIndex, event: 'click' });
                //     }
                // }
            },
            labels: [],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                        height: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            legend: {
                show: true,
                position: 'right',
                width: 127,
                fontWeight: 10,
            },
            xaxis: {
                labels: {
                    trim: true
                }
            },

            tooltip: {
                custom: function (opts: any) {
                    const lang = languageCodes(i18next.language);
                    const type = opts?.w?.config?.information[lang][opts?.seriesIndex];
                    const count = opts?.series[opts?.seriesIndex] + ' €';
                    const code_name = opts?.w?.globals?.labels[opts?.seriesIndex];
                    const bgcolor = opts?.w?.globals?.colors[opts?.seriesIndex];
                    return `<div class="">
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item"><span class="font-size-15">${type}</span><span class="badge bg-warning ms-2" style="background-color:${bgcolor}">${code_name}</span></li>
                      <li class="list-group-item"><label>${t("Total amount")}:</label> ${typeof count !== "undefined" ? count : null}</li>
                    </ul>
                  </div> `;
                },
            },
            noData: noData
        },
    })

    const getChartData = async () => {
        try {
            const res = await invoicesService.getInvoicePerTask(formValues);
            if (res.status === 'success') {
                setData((data: any) => ({
                    ...data,
                    series: res?.tasksData[0]?.data || [],
                    options: {
                        ...data.options,
                        labels: res?.labels,
                        information: res?.tasksData[0]?.description,
                    }

                }))
            }
            setLoading(false);
        } catch (error: any) {
            toast(error)
        }

        // try {
        //     const result = await tasksService.getOriginatedTaskTypeCount(query);
        //     if (result.data.status === 'success') {
        //         setData((data: any) => ({
        //             ...data,
        //             series: result?.data?.data[0].data,
        //             //information:result?.data?.data[0].type,
        //             ids: result?.data?.data[0].ids || [],
        //             options: {
        //                 ...data.options,
        //                 labels: result?.data?.data[0].label,
        //                 information:result?.data?.data[0].type,
        //             }

        //         }))
        //     }
        //     setLoading(false);
        // } catch (error: any) {
        //     toast(error)
        // }
        //console.log(query);

    }

    const clearWorkAllocationFilter = () => {
        setFilter(inititalFilter);
    }

    useEffect(() => {
        loading && getChartData();
    }, [loading])

    useEffect(() => {
        setQuery(serialize(filter));
        setLoading(true);
    }, [filter])

    useEffect(() => {
        filters && setFilter(filters)
    }, [filters])

    useEffect(() => {
        setLoading(true);
    }, [formValues])

    useEffect(() => {
        setLoading(true);
    }, [t])

    return (
        <div style={{ height: '370px' }}>
            <div className="d-sm-flex flex-wrap">
                <h4 className="card-title mb-4">{!hideHeading && t("Revenue Stream")}</h4>
                <div className="ms-auto">
                    <GraphFilters
                        filter={filter}
                        setFilter={setFilter}
                        clearFilter={clearWorkAllocationFilter}
                        enableUser={true}
                        enableCompany={true}
                        slug='all-tasks'
                    />
                    {/* <Select
                        id="invoice_per_task_type"
                        name="invoice_per_task_type"
                        className="form-select-sm myClassName"
                        styles={select2Styles}
                        value={yearOptions.find((i: any) => i.value === formValues)}
                        options={yearOptions}
                        onChange={(option: any) => {
                            setFormValues(option.value)
                        }}
                    /> */}
                </div>
            </div>
            <div className="clearfix"></div>
            {loading ? <LoadingSpinner height={380} /> : <div style={{ height: '100%' }}><Chart options={data.options} series={data.series} type="pie" height={380} /></div>}
        </div>
    )
}

export default RevenueStream;