import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container } from "reactstrap";

// Import Breadcrumb
import Breadcrumbs from "../../components/Layout/Breadcrumb";
import AllTimeSheets from "../TimeSheets/AllTimeSheets";

import ScrollUp from "../../components/ScrollUpDown/ScrollUp";
import ScrollDown from "../../components/ScrollUpDown/ScrollDown";

import { useTranslation } from "react-i18next";

import NavItems from "./NavItemsSubTask";
import subtasksService from "../../services/subtasks.service";
import { toast } from "react-toastify";

const EditSubTaskTimesheetList = (props: any) => {
  const { t } = useTranslation();
  document.title =
    `${t("Update")} ${t("task")} | ${t("Timesheet")} | ` + process.env.REACT_APP_NAME;
  const { id, subtask_id } = useParams();

  const [loading, setLoading] = useState(true);
  const [initialData, setInitialData] = useState({
    task_id: "",
    task_sub_id: "",
  });

  const get = async () => {
    try {
      const res = (await subtasksService.getSubTask({
        id,
        subtask_id,
      })) as any;
      if (res.data.status === "success") {
        setInitialData(res.data.data[0]);
        setLoading(false);
      } else {
        toast(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 5000,
          delay: 500,
        });
        setLoading(false);
      }
    } catch (err: any) {
      toast(err?.message, {
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        delay: 500,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    get();
  }, []);

  return (
    <React.Fragment>
      <ScrollUp />
      <ScrollDown />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={`${t("Update")} ${t("subtask")}`}
            breadcrumbItems={[
              {
                url: "/dashboard",
                label: t("Dashboard"),
                active: false,
              },
              {
                url: "/tasks/all-tasks",
                label: t("Tasks"),
                active: false,
              },
              {
                url: "/tasks/subtasks/" + initialData.task_id + "/" + initialData.task_sub_id,
                label: t("Subtasks"),
                active: false,
              },
              {
                url: "#",
                label: `${t("Update")} ${t("subtask")}`,
                active: true,
              },
            ]}
          />

          <NavItems id={id} subtask_id={subtask_id} />
          <AllTimeSheets task_id={{ id, task_id: subtask_id }} type="list" />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditSubTaskTimesheetList;
