import { useEffect, useState } from "react";
import { dateFormat } from "../../../utils/formats";
import {
  Button,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";
import React from "react";
import DataTableBasic from "../../../components/Common/DataTableBasic";
import {
  filterCountryByLanguage,
  getSelectOptions,
  getUserProfile,
  handleAddress,
  yearsBackword,
} from "../../../helpers/functions_helper";
import Autocomplete from "react-google-autocomplete";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";

type Type = {
  is_prime: boolean;
  bank_name_prime: string;
  bank_address_number: string;
  bank_address_box: string;
  bank_address_postal_code: string;
  bank_address_commune: string;
  bank_address_city: string;
  bank_address_country: string;
  bank_address_prime: string;
  iban_number_prime: string;
  bic_number_prime: string;
};

const BanksData = (props: any) => {
  const { t } = useTranslation();
  const userProfile = getUserProfile("all-users");
  const { onChange } = props;

  const [initialData, setInitialData] = useState<Type>({
    is_prime: false,
    bank_name_prime: "",
    bank_address_prime: "",
    bank_address_number: "",
    bank_address_box: "",
    bank_address_postal_code: "",
    bank_address_commune: "",
    bank_address_city: "",
    bank_address_country: "",
    iban_number_prime: "",
    bic_number_prime: "",
  });

  const columns = [
    {
      dataField: "DT_RowIndex",
      text: t("Sr. No."),
      formatter: (rowContent: any, row: any, idx: number): any => {
        return idx + 1;
      },
    },
    {
      dataField: "bank_name_prime",
      text: t("Bank name"),
      formatter: (rowContent: any, row: any, idx: number): any => {
        return (
          <>
            {row.bank_name_prime}

            {row.is_prime && (
              <span className="badge rounded-pill badge-soft-primary ms-1">Prime</span>
            )}
          </>
        );
      },
    },
    {
      dataField: "bank_address_prime",
      text: t("Bank address"),
    },
    {
      dataField: "bank_address_number",
      text: t("Address number"),
    },
    {
      dataField: "bank_address_box",
      text: t("Box number"),
    },
    {
      dataField: "bank_address_postal_code",
      text: t("Postal code"),
    },
    {
      dataField: "bank_address_commune",
      text: t("Commune"),
    },
    {
      dataField: "bank_address_city",
      text: t("City"),
    },
    {
      dataField: "bank_address_country",
      text: t("Country"),
    },
    {
      dataField: "iban_number_prime",
      text: t("IBAN number"),
    },
    {
      dataField: "bic_number_prime",
      text: "BIC number",
    },

    {
      dataField: "action",
      text: "Action",
      formatter: (rowContent: any, row: any, idx: number): any => {
        return (
          <div className="d-flex gap-2">
            <Button
              color="primary"
              outline={true}
              className={`btn btn-pill btn-outline-primary btn-sm`}
              type="button"
              onClick={() => editRow(idx)}
            >
              <span className="align-middle material-symbols-outlined fw-light fs-4">edit</span>
            </Button>

            <Button
              color="danger"
              outline={true}
              className={`btn btn-pill btn-outline-danger btn-sm`}
              type="button"
              onClick={() => removeRow(idx)}
            >
              <span className="align-middle material-symbols-outlined fw-light fs-4">delete</span>
            </Button>
          </div>
        );
      },
    },
  ];

  const [isEdit, setIsEdit] = useState(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [addRowBtnDisabled, setAddRowBtnDisabled] = useState(true);
  const [data, setData] = useState<any>([]);
  const [dataList, setDataList] = useState<any>([]);
  const [loadingCountry, setLoadingCountry] = useState(true);
  const [countryOptions, setCountryOptions] = useState<Array<any>>([]);
  const [datatableOptions, setDatatableOptions] = useState({
    draw: 1,
    page: 1,
    recordsTotal: 0,
    recordsFiltered: 0,
    sizePerPage: 5,
    searchText: "",
    sortField: "id",
    sortOrder: "desc",
  });

  const onTableChange = (type: any, newState: any) => {
    let draw = datatableOptions.draw + 1;
    let page = 1;
    if (type === "search") {
      page = 1;
    } else {
      page = newState.page;
    }
    setDatatableOptions((prevData: any) => ({
      ...prevData,
      draw: draw,
      page: page,
      sizePerPage: newState.sizePerPage,
      searchText: newState.searchText,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
    }));

    const slicedArray = data.slice(newState.sizePerPage * (page - 1), newState.sizePerPage * page);
    setDataList(slicedArray);
  };

  const addRow = () => {
    setData((preState: any) => [
      ...preState,
      {
        is_prime: initialData?.is_prime,
        bank_name_prime: initialData?.bank_name_prime,
        bank_address_prime: initialData?.bank_address_prime,
        bank_address_number: initialData?.bank_address_number,
        bank_address_box: initialData?.bank_address_box,
        bank_address_postal_code: initialData?.bank_address_postal_code,
        bank_address_commune: initialData?.bank_address_commune,
        bank_address_city: initialData?.bank_address_city,
        bank_address_country: initialData?.bank_address_country,
        iban_number_prime: initialData?.iban_number_prime,
        bic_number_prime: initialData?.bic_number_prime,
      },
    ]);

    clearForm();
    setAddRowBtnDisabled(true);
  };

  const updateRow = () => {
    if (editIndex !== null) {
      var rows: any = [...data];
      rows.map((item: any, index: number) => {
        if (index === editIndex) {
          rows[index] = {
            ...item,
            is_prime: initialData?.is_prime,
            bank_name_prime: initialData?.bank_name_prime,
            bank_address_prime: initialData?.bank_address_prime,
            bank_address_number: initialData?.bank_address_number,
            bank_address_box: initialData?.bank_address_box,
            bank_address_postal_code: initialData?.bank_address_postal_code,
            bank_address_commune: initialData?.bank_address_commune,
            bank_address_city: initialData?.bank_address_city,
            bank_address_country: initialData?.bank_address_country,
            iban_number_prime: initialData?.iban_number_prime,
            bic_number_prime: initialData?.bic_number_prime,
          };
        }
      });
      setData(rows);

      clearForm();
      setIsEdit(false);
      setEditIndex(null);
    }
  };

  const removeRow = (idx: number) => {
    const rows = data.filter((item: any, index: number) => index !== idx);
    setData(rows);

    setIsEdit(false);
    setEditIndex(null);
  };

  const editRow = (idx: number) => {
    const row = data.find((item: any, index: number) => index === idx);
    setInitialData((preData: any) => ({
      ...preData,
      is_prime: row?.is_prime,
      bank_name_prime: row?.bank_name_prime,
      bank_address_prime: row?.bank_address_prime,
      bank_address_number: row?.bank_address_number,
      bank_address_box: row?.bank_address_box,
      bank_address_postal_code: row?.bank_address_postal_code,
      bank_address_commune: row?.bank_address_commune,
      bank_address_city: row?.bank_address_city,
      bank_address_country: row?.bank_address_country,
      iban_number_prime: row?.iban_number_prime,
      bic_number_prime: row?.bic_number_prime,
    }));

    setIsEdit(true);
    setEditIndex(idx);
  };

  const clearForm = () => {
    setInitialData((preData: any) => ({
      ...preData,
      is_prime: false,
      bank_name_prime: "",
      bank_address_prime: "",
      bank_address_number: "",
      bank_address_box: "",
      bank_address_postal_code: "",
      bank_address_commune: "",
      bank_address_city: "",
      bank_address_country: "",
      iban_number_prime: "",
      bic_number_prime: "",
    }));
  };

  const handleChange = (fieldName: string, fieldValue: any) => {
    setInitialData((preState) => ({ ...preState, [fieldName]: fieldValue }));
  };

  const handleAddressChangeBank = async (data: any) => {
    const addressData = await handleAddress(data);
    setInitialData((prevData: any) => ({
      ...prevData,
      bank_address_prime: addressData?.address,
      bank_address_number: addressData?.address_number.substring(0, 10),
      bank_address_box: addressData?.box.substring(0, 10),
      bank_address_postal_code: addressData?.postal_code,
      bank_address_commune: addressData?.commune,
      bank_address_city: addressData?.city,
      bank_address_country: addressData?.country,
    }));
  };

  useEffect(() => {
    if (
      initialData.bank_name_prime !== "" &&
      initialData.bank_address_prime !== "" &&
      initialData.iban_number_prime !== "" &&
      initialData.bic_number_prime !== ""
    ) {
      setAddRowBtnDisabled(false);
    } else {
      setAddRowBtnDisabled(true);
    }
  }, [
    initialData.bank_name_prime,
    initialData.bank_address_prime,
    initialData.iban_number_prime,
    initialData.bic_number_prime,
  ]);

  useEffect(() => {
    setData(props?.data);
  }, [props?.data]);

  useEffect(() => {
    setDatatableOptions((prevData: any) => ({
      ...prevData,
      draw: 1,
      page: 1,
      sizePerPage: 5,
      recordsTotal: data.length,
      recordsFiltered: data.length,
    }));

    const slicedArray = data.slice(0, 5);
    setDataList(slicedArray);

    onChange(data);
  }, [data]);

  useEffect(() => {
    getSelectOptions("tbl_master_country", "name", "", [
      "is_europian_country",
      "name",
      "name_french",
      "name_dutch",
    ]).then(function (options) {
      let countryOptionsList = filterCountryByLanguage(options);
      setCountryOptions(countryOptionsList);
      setLoadingCountry(false);
    });
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <div className="mb-3">
            <Label className="form-label">
              {t("Bank name")} <span className="text-warning is_required">*</span>
            </Label>

            <FormGroup switch inline className="float-end me-0">
              <Input
                id="is_prime"
                type="switch"
                readOnly={!userProfile.IS_INTERNAL}
                checked={initialData.is_prime ? true : false}
                onChange={(e) => {
                  handleChange("is_prime", e.target.checked ? true : false);
                }}
              />
              <Label check>{t("Prime")}</Label>
            </FormGroup>

            <Input
              id="bank_name_prime"
              name="bank_name_prime"
              className="form-control"
              placeholder={t("Bank name")}
              type="text"
              readOnly={!userProfile.IS_INTERNAL}
              maxLength={50}
              value={initialData.bank_name_prime}
              onChange={(e) => handleChange("bank_name_prime", e.target.value)}
            />
          </div>
        </Col>

        <Col className="col-12">
          <div className="mb-3">
            <Label className="form-label">
              {t("Bank address")} <span className="text-warning is_required">*</span>
            </Label>
            <Autocomplete
              id="bank_address_prime"
              name="bank_address_prime"
              placeholder={t("Bank address")}
              readOnly={!userProfile.IS_INTERNAL}
              className={"form-control"}
              value={initialData.bank_address_prime}
              onChange={(e: any) => handleChange("bank_address_prime", e.target.value)}
              apiKey={process.env.REACT_APP_GOOGLE_ADDRESS_API_KEY!}
              options={{
                types: ["geocode", "establishment"],
              }}
              onPlaceSelected={(data: any) => {
                handleAddressChangeBank(data);
              }}
            />
          </div>
        </Col>

        <Col xl={3} lg={3} md={3} sm={12}>
          <div className="mb-3">
            <Label className="form-label">{t("Address number")}</Label>
            <Input
              id="bank_address_number"
              name="bank_address_number"
              className="form-control"
              placeholder={t("Number")}
              type="text"
              readOnly={!userProfile.IS_INTERNAL}
              maxLength={10}
              value={initialData.bank_address_number}
              onChange={(e) => handleChange("bank_address_number", e.target.value)}
            />
          </div>
        </Col>

        <Col xl={3} lg={3} md={3} sm={12}>
          <div className="mb-3">
            <Label className="form-label">{t("Box number")}</Label>
            <Input
              id="bank_address_box"
              name="bank_address_box"
              className="form-control"
              placeholder={t("Box number")}
              type="text"
              readOnly={!userProfile.IS_INTERNAL}
              maxLength={10}
              value={initialData.bank_address_box}
              onChange={(e) => handleChange("bank_address_box", e.target.value)}
            />
          </div>
        </Col>

        <Col xl={3} lg={3} md={3} sm={12}>
          <div className="mb-3">
            <Label className="form-label">{t("Postal code")}</Label>
            <Input
              id="bank_address_postal_code"
              name="bank_address_postal_code"
              className="form-control"
              placeholder={t("Postal code")}
              type="text"
              readOnly={!userProfile.IS_INTERNAL}
              maxLength={30}
              value={initialData.bank_address_postal_code}
              onChange={(e) => handleChange("bank_address_postal_code", e.target.value)}
            />
          </div>
        </Col>

        <Col xl={3} lg={3} md={3} sm={12}>
          <div className="mb-3">
            <Label className="form-label">{t("Commune")}</Label>
            <Input
              id="bank_address_commune"
              name="bank_address_commune"
              className="form-control"
              placeholder={t("Commune")}
              type="text"
              readOnly={!userProfile.IS_INTERNAL}
              value={initialData.bank_address_commune}
              disabled={true}
            />
          </div>
        </Col>

        <Col className="col-12"></Col>

        <Col xl={3} lg={3} md={3} sm={12}>
          <div className="mb-3">
            <Label className="form-label">{t("City")}</Label>
            <Input
              id="bank_address_city"
              name="bank_address_city"
              className="form-control"
              placeholder={t("City")}
              type="text"
              readOnly={!userProfile.IS_INTERNAL}
              maxLength={100}
              value={initialData.bank_address_city}
              onChange={(e) => handleChange("bank_address_city", e.target.value)}
            />
          </div>
        </Col>

        <Col xl={3} lg={3} md={3} sm={12}>
          <div className="mb-3">
            <Label className="form-label">{t("Country")}</Label>
            <Select
              id="bank_address_country"
              name="bank_address_country"
              className="is-invalid"
              placeholder={t("Select...")}
              options={countryOptions}
              value={
                initialData.bank_address_country
                  ? countryOptions.find(
                      (option: any) => option.value == initialData.bank_address_country
                    )
                  : ""
              }
              isSearchable={true}
              isDisabled={loadingCountry ? loadingCountry : !userProfile.IS_INTERNAL}
              isLoading={loadingCountry}
              onChange={(e: any) => {
                handleChange("bank_address_country", e.value);
              }}
            />
          </div>
        </Col>

        <Col className="col-3">
          <div className="mb-3">
            <Label className="form-label">
              {t("IBAN number")} <span className="text-warning is_required">*</span>
            </Label>
            {parseInt(initialData.bank_address_country) === 21 ? (
              <InputMask
                id="iban_number_prime"
                name="iban_number_prime"
                className="form-control"
                placeholder={t("IBAN number")}
                type="text"
                mask="BE99 9999 9999 9999"
                value={initialData.iban_number_prime}
                disabled={!userProfile.IS_INTERNAL}
                onChange={(e) => handleChange("iban_number_prime", e.target.value)}
              />
            ) : (
              <Input
                id="iban_number_prime"
                name="iban_number_prime"
                className="form-control"
                placeholder={t("IBAN number")}
                type="text"
                readOnly={!userProfile.IS_INTERNAL}
                maxLength={34}
                value={initialData.iban_number_prime}
                onChange={(e) => handleChange("iban_number_prime", e.target.value)}
              />
            )}
          </div>
        </Col>

        <Col className="col-2">
          <div className="mb-3">
            <Label className="form-label">
              {t("BIC number")} <span className="text-warning is_required">*</span>
            </Label>
            <Input
              id="bic_number_prime"
              name="bic_number_prime"
              className="form-control"
              placeholder={t("BIC number")}
              type="text"
              readOnly={!userProfile.IS_INTERNAL}
              maxLength={11}
              value={initialData.bic_number_prime}
              onChange={(e) => handleChange("bic_number_prime", e.target.value)}
            />
          </div>
        </Col>

        {userProfile.IS_INTERNAL && (
          <Col xl={1} lg={1} md={1} sm={6}>
            <div className="mb-3">
              <Label className="form-label">
                <br />
              </Label>
              <div className="input-group">
                {isEdit === true ? (
                  <Button
                    color="success"
                    type="button"
                    className={`btn btn-sm btn-success`}
                    disabled={addRowBtnDisabled}
                    onClick={() => updateRow()}
                  >
                    <span className="align-middle material-symbols-outlined fw-light fs-4">
                      done
                    </span>
                  </Button>
                ) : (
                  <Button
                    type="button"
                    className={`btn btn-sm ${addRowBtnDisabled ? "btn-primary" : "btn-info"}`}
                    disabled={addRowBtnDisabled}
                    onClick={() => addRow()}
                  >
                    <span className="align-middle material-symbols-outlined fw-light fs-4">
                      add
                    </span>
                  </Button>
                )}
              </div>
            </div>
          </Col>
        )}
      </Row>

      {dataList && dataList.length > 0 && (
        <DataTableBasic
          columns={columns}
          products={dataList}
          datatableOptions={datatableOptions}
          onTableChange={onTableChange}
        />
      )}
    </React.Fragment>
  );
};

export default BanksData;
