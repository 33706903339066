import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button, Navbar, NavbarBrand, NavbarToggler, Collapse, Nav, NavItem, NavLink, Row, Card } from 'reactstrap';
import { getUserProfile } from '../../../../helpers/functions_helper';
import AddSignature from '../../DocSignature/AddSignature';
import Select from '../../../../components/Form/Select/Select';
import { FontsList } from '../../../../common/staticdata';

//@ts-ignore
// import FontPicker  from "font-picker-react";
interface Props {
  uploadNewPdf: () => void;
  addText: () => void;
  createText?: () => void;
  addImage: () => void;
  addDrawing: () => void;
  isPdfLoaded: boolean;
  savingPdfStatus: boolean;
  // savePdf?: () => void;
  addComposeMail: (type: any) => void;
  isAdmin: boolean;
  addRefuseModal?: any;
  id?: any;
  template_id?: any;
  downloadImage?: any;
  signatureAdded?: (siganture: any, data: any) => void;
  submitDocument?: () => void;
  finalizeDocument?: () => void;
  curStatus?: number | null;
  setDocStatus?: any;
  template?: any;
  submitTemplate?: () => void;
  viewOnly?: false;
  getComments?: () => void;
  updateStyle?: (FontFamily: any) => void;
  templateData?:any;
  enableSubmit?:any;
}

export const MenuBar: React.FC<Props> = ({
  uploadNewPdf,
  addDrawing,
  addText,
  createText,
  addImage,
  isPdfLoaded,
  savingPdfStatus,
  // savePdf,
  addComposeMail,
  isAdmin,
  addRefuseModal,
  id,
  template_id,
  downloadImage,
  signatureAdded,
  submitDocument,
  finalizeDocument,
  curStatus,
  setDocStatus,
  template,
  submitTemplate,
  viewOnly,
  getComments,
  updateStyle,
  templateData,
  enableSubmit
}) => {
  const { t } = useTranslation();
  const userProfile = getUserProfile();
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [signatureModal, setSignatureModal] = useState(false);
  const [isText, setIsText] = useState(false);
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const [activeFontFamily, setActiveFontFamily] = useState("Open Sans");
  useEffect(() => {
    console.log(activeFontFamily);
  }, [activeFontFamily])

  return (
    <>{!viewOnly ? (
      <Card className='pt-3 py-3 px-2'>
        <Row>
          <div className="d-flex justify-content-end gap-2">
            {template ?
              <>
                {!viewOnly ? (
                  <>
                    {isPdfLoaded && (

                      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                        <DropdownToggle className='btn btn-pill btn-primary btn-sm'>
                          <i className="fa fa-edit"></i>{t("Edit")}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={() => { addText(); }}>{t("Add text")}</DropdownItem>
                          <DropdownItem onClick={addImage}>{t("Add image")}</DropdownItem>
                          {/* <DropdownItem divider /> */}
                          {/* <DropdownItem onClick={addDrawing}>Add Drawing</DropdownItem> */}
                        </DropdownMenu>
                      </Dropdown>
                    )}
                    <Link
                      className="btn btn-pill btn-primary btn-sm"
                      to='#'
                      onClick={submitTemplate}
                    >
                      {t("Submit")}
                    </Link>
                    <Link
                      className="btn btn-pill btn-outline-primary btn-sm"
                      to="#"
                      onClick={downloadImage}
                    >
                      <i className="fa-regular fas-download"></i> {t("Download")}
                    </Link>
                  </>
                ) : ""}

              </>
              :
              <>

                {isAdmin &&
                  <>

                    {(isPdfLoaded && (curStatus !== 3 && templateData?.status !== 2)) && (
                      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                        <DropdownToggle className='btn btn-pill btn-primary btn-sm'>
                          <i className="fa fa-edit"></i>{t("Edit")}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={() => { addText(); setIsText(true) }}>{t("Add text")}</DropdownItem>
                          <DropdownItem onClick={addImage}>{t("Add image")}</DropdownItem>
                          {/* <DropdownItem divider /> */}
                          {/* <DropdownItem onClick={addDrawing}>Add Drawing</DropdownItem> */}
                        </DropdownMenu>
                      </Dropdown>
                    )}

                    {(curStatus !== 3 && templateData?.status !== 2) &&
                      <>
                        <AddSignature
                          moduleData={{
                            module_app_id: 14,
                            module_main_id: userProfile.USER_ID,
                            module_sub_id: userProfile.USER_SUB_ID,
                            section_id: userProfile.USER_COMPANY,
                            file_path: "User_document",
                          }}
                          showlist={false}
                          show={signatureModal}
                          isDisabled={false}
                          showAdd={false}
                          hideSign={true}
                          signatureAdded={signatureAdded}
                          getList={false}
                        />
                        <Link
                          className="btn btn-pill btn-primary btn-sm"
                          to='#'
                          onClick={() => { addComposeMail(2); setDocStatus(2) }}
                        > {t("Send")}
                        </Link>
                        <Link
                          className="btn btn-pill btn-primary btn-sm"
                          to='#'
                          onClick={() => { addComposeMail(1); setDocStatus(1); }}
                        >
                          {t("Save")}
                        </Link>
                      </>
                    }
                    {curStatus == 3 &&
                      <Link
                        className="btn btn-pill btn-primary btn-sm"
                        to='#'
                        onClick={finalizeDocument}
                      >
                        <i className="fa-regular fa-pen-to-square"></i> {t("Finalize")}
                      </Link>
                    }
                  </>
                }
                {!isAdmin &&
                  <>

                    {/* <Link
                    className="btn btn-pill btn-primary btn-sm"
                    to='#'
                    onClick={() => setSignatureModal(!signatureModal)}
                  >
                    <i className="fa-regular fa-pen-to-square"></i> {t("Sign")}
                  </Link> */}
                    <Link
                      className="btn btn-pill btn-danger btn-sm"
                      to='#'
                      onClick={addRefuseModal}
                    >
                      {t("Refuse")}
                    </Link>
                    <Link
                      className={enableSubmit? "btn btn-pill btn-primary btn-sm": "btn btn-pill btn-primary btn-sm disabled"}
                      to='#'
                      onClick={enableSubmit? submitDocument : undefined}
                    >
                      <i className="fa-regular fa-pen-to-square"></i> {t("Submit")}
                    </Link>


                    <AddSignature
                      moduleData={{
                        module_app_id: 14,
                        module_main_id: id,
                        module_sub_id: template_id,
                        section_id: userProfile.USER_COMPANY,
                        file_path: "user_document",
                      }}
                      showlist={false}
                      show={signatureModal}
                      isDisabled={false}
                      showAdd={false}
                      hideSign={true}
                      signatureAdded={signatureAdded}
                      getList={false}
                      enableSubmit={enableSubmit}
                    />
                  </>
                }
                <Link
                  className="btn btn-pill btn-outline-primary btn-sm"
                  to="#"
                  onClick={downloadImage}
                >
                  <i className="fa-regular fas-download"></i> {t("Download")}
                </Link>
                <Link
                  className="btn btn-pill btn-outline-primary btn-sm"
                  to="#"
                  onClick={getComments}
                >
                  <i className="fa-regular fas-comment"></i> {t("Comments")}
                </Link>
              </>
            }


          </div>
        </Row>
      </Card>
    ) : null}</>
    // <nav className="navbar navbar-light bg-light">
    //   <span className="navbar-brand">PDF Editor</span>
    //   <div className="ml-auto">
    //     {isPdfLoaded && (
    //       <>
    //         <Dropdown 
    //           isOpen={false} // You can set this to a state to control the open/close state
    //           toggle={() => {}} // You can set this to a function to control the open/close state
    //         >
    //           <DropdownToggle caret>
    //             <i className="fa fa-edit"></i>
    //           </DropdownToggle>
    //           <DropdownMenu>
    //             <DropdownItem onClick={addText}>Add Text</DropdownItem>
    //             <DropdownItem onClick={addImage}>Add Image</DropdownItem>
    //             <DropdownItem onClick={addDrawing}>Add Drawing</DropdownItem>
    //           </DropdownMenu>
    //         </Dropdown>
    //         <Button
    //           color="primary"
    //           className="ml-2"
    //           disabled={savingPdfStatus}
    //           onClick={savePdf}
    //         >
    //           {savingPdfStatus ? 'Saving...' : 'Save'}
    //         </Button>
    //         <Button
    //           color="primary"
    //           className="ml-2"
    //           onClick={uploadNewPdf}
    //         >
    //           Upload New
    //         </Button>
    //       </>
    //     )}
    //     <Button color="primary" className="ml-2" onClick={openHelp}>
    //       <i className="fa fa-question-circle"></i>
    //     </Button>
    //   </div>
    // </nav>
  );
};
