import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import GraphFilters from "../../../components/Common/GraphFilters";
import ApplyFilter from "../../../components/Common/ApplyFilter";
import { getUserProfile } from "../../../helpers/functions_helper";
import { serialize } from "../../../utils/utils";
import { toast } from "react-toastify";
import cpoService from "../../../services/cpo.service";
import WidgetBasic from "../../../components/Widgets/Basic";
interface WorkAllocationProps extends CartProps {
  filters?: any;
}

const CPOHitsCount = ({ hideHeading, filters = null }: WorkAllocationProps) => {
  const { t } = useTranslation();
  const userObj = getUserProfile('all-cpo');
  const navigate = useNavigate();
  const [cpoData, setCPOData] = useState<any>({
    total: {seconday:[]},
    company: {seconday:[]},
    individual: {seconday:[]},
    website: {seconday:[]},
  });
  const [selectedID, setSelectedID] = useState<any>({
    user_id: null,
    status_id: null,
    event: null,
  });
  const [loading, setLoading] = useState<boolean>(true);
  const inititalFilter = {
    week: null,
    quarter: null,
    month: null,
    year: null,
    user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN)
      ? userObj.USER_ID
      : null,
  };
  const [query, setQuery] = useState<string | null>(serialize(inititalFilter));
  const [filter, setFilter] = useState<any>(inititalFilter);

  const [options, setOption] = useState<any>({
    chart: { sparkline: { enabled: !0 } },
    stroke: { curve: "smooth", width: 2 },
    colors: ["#6200ee"],
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: !1,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [25, 100, 100, 100],
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    tooltip: { fixed: { enabled: !1 }, x: { show: !1 }, marker: { show: !1 } },
  });

  const viewTaskList = (i: any) => {
    if (i) {
      if (i.user_id && i.status_id) {
        let _filter = {
          type: "work-allocation",
          data: i,
          label: "work allocation",
        };
        navigate("../tasks/all-tasks");
      }
      setSelectedID({ user_id: null, status_id: null, event: null });
    }
  };

  const getChartData = async () => {
    try {
      const result = await cpoService.getCPOIndividualHitsCount(query);
      if (result?.data?.status === "success") {
        setCPOData((preState: any) => ({
          ...preState,
          total: result?.data?.data.total,
          company: result?.data?.data.company,
          individual: result?.data?.data.individual,
          website: result?.data?.data.website,
        }));
        setOption((preState: any) => ({
          ...preState,
          xaxis: {
            categories: result?.data?.data?.monthly?.categories,
          },
        }));
      }
      setLoading(false);
    } catch (error: any) {
      toast(error);
    }
  };

  useEffect(() => {
    loading && getChartData();
  }, [loading]);

  useEffect(() => {
    setQuery(serialize(filter));
    setLoading(true);
  }, [filter]);

  useEffect(() => {
    filters && setFilter(filters);
  }, [filters]);

  // Individual (check from user master if user type = individual) then use this
  // Company - Employees (check from user master if user is linked to company then its employee)
  // Website user : check the user type = website user
  return (
    <React.Fragment>
      <Row>
        {cpoData.total && (
          <Col className="col-3 " onClick={() => viewTaskList(cpoData.total)}>
            <WidgetBasic
              to={"#"}
              variant="primary"
              loading={loading}
              label={t("Total CPO count")}
              value={cpoData?.total?.total}
              icon={
                <span className="material-symbols-outlined icon">
                  calculate
                </span>
              }
              seconday={cpoData?.total?.seconday}
            />
          </Col>
        )}
        <Col
          className="col-3"
          onClick={() => viewTaskList(cpoData?.individual)}
        >
          <WidgetBasic
            to={"#"}
            variant="secondary"
            loading={loading}
            label={t("Total CPO Individual count")}
            value={cpoData?.individual?.total}
            icon={
              <span className="material-symbols-outlined icon">person</span>
            }
            seconday={cpoData?.individual?.seconday}
          />
        </Col>
        <Col
          className="col-3"
          onClick={() => viewTaskList(cpoData?.individual)}
        >
          <WidgetBasic
            to={"#"}
            variant="info"
            loading={loading}
            label={t("Total CPO company count")}
            value={cpoData?.company?.total}
            icon={<span className="material-symbols-outlined">apartment</span>}
            seconday={cpoData?.company?.seconday}
          />
        </Col>
        <Col
          className="col-3"
          onClick={() => viewTaskList(cpoData?.website)}
        >
          <WidgetBasic
            to={"#"}
            variant="success"
            loading={loading}
            label={t("Total CPO website count")}
            value={cpoData?.website?.total}
            icon={
              <span className="material-symbols-outlined">captive_portal</span>
            }
            seconday={cpoData?.website?.seconday}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default CPOHitsCount;
