import React, { FC, useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardSubtitle, CardTitle, Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import { formatCurrency } from "../../../utils/formats";
import invoicesService from "../../../services/invoices.service";
import { toast } from "react-toastify";
import { quarterOfYear, select2Styles } from "../../../helpers/functions_helper";
import { yearOptions } from "../../../helpers/functions_helper";
import { options } from "../../../common/staticdata";
import { noData } from "../../../utils/data";
import LoadingSpinner from "../../../components/Common/Loader";
import Chart from "../../../components/Chart";

type TaskAmountVsServiceChargeProps = {
    hideHeading?: boolean;
}
const TaskAmountVsServiceCharge: FC<TaskAmountVsServiceChargeProps> = ({ hideHeading = false }) => {

    const { t } = useTranslation();

    const [loadingText, setLoadingText] = useState<string>('Loading...');
    const [loading, setLoading] = useState<boolean>(true);
    const [formValues, setFormValues] = useState<any>({
        service_charge: quarterOfYear(),
    })

    const [d2Chart, setD2Chart] = useState<any>({
        options: {
            plotOptions: {
                pie: {
                    donut: {
                        size: '65%'
                    },

                }
            },
            colors: ['#564ab1', '#f1b44c'],
            labels: [t('Task amount'), t('Service charge')],
            legend: {
                show: true,
                position: 'bottom',
            },
            yaxis: {
                labels: {
                    formatter: function (value: any) {
                        return formatCurrency(value);
                    }
                },
            },

        },
        series: [44, 55],

    })


    const getTotalVsService = async (range: any) => {
        setLoadingText("Loading...");
        try {
            const res = await invoicesService.getTotalVsService(range);
            if (res.status === 'success') {
                setD2Chart((state: any) => ({
                    ...state,
                    series: res.data.total ? [res.data.total || 0, res.data.service_charge || 0] : [],
                    options: {
                        ...state.options,
                        labels: [t('Task amount'), t('Service charge')],
                        noData: noData
                    }
                }))
            }
            setLoadingText("No data found");
            setLoading(false);
        } catch (error: any) {
            toast(error)
        }
    }

    useEffect(() => {
        setLoading(true);
    }, [formValues.service_charge])

    useEffect(() => {
        loading && getTotalVsService(formValues.service_charge);
    }, [loading])

    useEffect(() => {
        setD2Chart((state: any) => ({
            ...state,
            options: {
                ...state.options,
                labels: [t('Task amount'), t('Service charge')],
            }
        }))
    }, [t])


    return (
        <div style={{ height: '355px' }}>
            <div className="d-sm-flex flex-wrap">
                <div>
                    {!hideHeading && <CardTitle className="mb-3">{t("Task amount vs service charge incurred")}</CardTitle>}
                </div>
                <div className="ms-auto">
                    <Select
                        id="service_charge"
                        name="service_charge"
                        className="form-select-sm myClassName"
                        styles={select2Styles}
                        value={options.find((i: any) => i.value === formValues.service_charge)}
                        options={options}
                        onChange={(option: any) => {
                            setFormValues((state: any) => ({ ...state, service_charge: option.value }))
                        }}
                    />
                </div>
            </div>
            <Chart loading={loading} options={d2Chart.options} series={d2Chart.series} type="donut" height={330} />
        </div>

    )
}

export default TaskAmountVsServiceCharge;