import {
    GET_QUESTIONNAIRE_STATUSES,
    GET_QUESTIONNAIRE_STATUSES_FAIL,
    GET_QUESTIONNAIRE_STATUSES_SUCCESS,
    GET_QUESTIONNAIRE_STATUS_DETAIL,
    ADD_NEW_QUESTIONNAIRE_STATUS,
    ADD_QUESTIONNAIRE_STATUS_SUCCESS,
    ADD_QUESTIONNAIRE_STATUS_FAIL,
    UPDATE_QUESTIONNAIRE_STATUS,
    UPDATE_QUESTIONNAIRE_STATUS_SUCCESS,
    UPDATE_QUESTIONNAIRE_STATUS_FAIL,
    DELETE_QUESTIONNAIRE_STATUS,
    DELETE_QUESTIONNAIRE_STATUS_SUCCESS,
    DELETE_QUESTIONNAIRE_STATUS_FAIL,
    GET_QUESTIONNAIRE_STATUS_DETAIL_FAIL,
    GET_QUESTIONNAIRE_STATUS_DETAIL_SUCCESS
} from "./actionTypes"
import StatusModel from "./interface"


export const getQuestionnaireStatuses = (query: any, history: any) => ({
    type: GET_QUESTIONNAIRE_STATUSES,
    payload: {query, history},
})

export const getQuestionnaireStatusesSuccess = (statuses : StatusModel[]) => ({
    type: GET_QUESTIONNAIRE_STATUSES_SUCCESS,
    payload: statuses,
})

export const addNewQuestionnaireStatus= (statusdata : StatusModel, history : any) => ({
    type: ADD_NEW_QUESTIONNAIRE_STATUS,
    payload: { statusdata, history },
})

export const addQuestionnaireStatusSuccess = (statusdata : StatusModel) => ({
    type: ADD_QUESTIONNAIRE_STATUS_SUCCESS,
    payload: statusdata,
})

export const addQuestionnaireStatusFail = (error: any )=> ({
    type: ADD_QUESTIONNAIRE_STATUS_FAIL,
    payload: error,
})

export const updateQuestionnaireStatus = (statusdata: StatusModel, history : any) => ({
    type: UPDATE_QUESTIONNAIRE_STATUS,
    payload: { statusdata, history },
})

export const updateQuestionnaireStatusSuccess = (statusdata: StatusModel)  => ({
    type: UPDATE_QUESTIONNAIRE_STATUS_SUCCESS,
    payload: statusdata,
})

export const updateQuestionnaireStatusFail = (error: any) => ({
    type: UPDATE_QUESTIONNAIRE_STATUS_FAIL,
    payload: error,
})

export const deleteQuestionnaireStatus = (statusdata: any, history: any) => ({
    type: DELETE_QUESTIONNAIRE_STATUS,
    payload: { statusdata, history },
})

export const deleteQuestionnaireStatusSuccess = (statusdata: any) => ({
    type: DELETE_QUESTIONNAIRE_STATUS_SUCCESS,
    payload: statusdata,
})

export const deleteQuestionnaireStatusFail = (error: any) => ({
    type: DELETE_QUESTIONNAIRE_STATUS_FAIL,
    payload: error,
})

export const getQuestionnaireStatusesFail = (error: any) => ({
    type: GET_QUESTIONNAIRE_STATUSES_FAIL,
    payload: error,
})

export const getQuestionnaireStatusDetail = (statusId: any) => ({
    type: GET_QUESTIONNAIRE_STATUS_DETAIL,
    statusId,
})

export const getQuestionnaireStatusDetailSuccess = (statusDetails: StatusModel) => ({
    type: GET_QUESTIONNAIRE_STATUS_DETAIL_SUCCESS,
    payload: statusDetails,
})

export const getQuestionnaireStatusDetailFail = (error:any) => ({
    type: GET_QUESTIONNAIRE_STATUS_DETAIL_FAIL,
    payload: error,
})
