import React, { FC, useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Select from '../../../components/Form/Select/Select';
import { Col, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button/Button';
import FormInput from '../../../components/Form/Input';
import ErrorMessage from '../../../components/Form/ErrorMessage';
import TextEditor from '../../../components/Form/TextEditor';
import DatePicker from '../../../components/Form/DatePicker/DatePicker';
import { validationSchema, publicationOptions, priorityOptions, intialValues, createNewsFeed, updateNewsFeed } from './helper';
import { bytesToMegaBytes } from '../../../utils/formats';
import { UPLOAD_MAX_FILESIZE } from '../../../common/staticdata';
import { getAxiosConfigFileUploadWithToken } from '../../../helpers/functions_helper';
import * as url from "../../../helpers/url_helper";
import axios from 'axios';
import AvatarEditor from '../../../components/Common/AvatarEditor';

const NewsFeedForm: FC<NewsFeedFormType> = ({ onSave, onCancel, data }) => {

    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(false);
    const [intialData, setIntialData] = useState<NewsFeedType>(intialValues);
    const [oldImage, setoldImage] = useState(null);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: intialData,
        validationSchema: validationSchema,
        onSubmit: (values, actions) => {
            handleSubmit(values, actions);
        },
    });

    const handleSubmit = async (values: NewsFeedType, actions?: any) => {
        actions.setSubmitting(true);
        if (values?.id) {
            const result = await updateNewsFeed(values);
            if (result) {
                setIntialData(intialValues)
                onSave && onSave();
            }
        } else {
            const result = await createNewsFeed(values);
            if (result) {
                setIntialData(intialValues)
                onSave && onSave();
            }
        }
        actions.setSubmitting(false);
    };


    const uploadAttachment = async (file: any) => {

        const FILESIZE = bytesToMegaBytes(file.size);
        const MAX_FILESIZE = UPLOAD_MAX_FILESIZE ? Number(UPLOAD_MAX_FILESIZE) : 2;
        if (FILESIZE > MAX_FILESIZE) {
            formik.setFieldError('attachment', `File is too large to upload, Max ${MAX_FILESIZE}MB allowed`);
            return false;
        } else {
            const img = URL.createObjectURL(file);
            var reader = new FileReader();
            reader.addEventListener("load", function () {
                const bas = this.result;
                formik.setFieldValue('attachment', bas);
            }, false);
            reader.readAsDataURL(file);
        }
    }

    const onAvatarChange = useCallback((base64Data: string) => {
        if (base64Data) {
            formik.setFieldValue('attachment', base64Data);
        }
    }, []);

    useEffect(() => {
        if (data) {
            setIntialData(data);
            setoldImage(data?.attachment)
        }
    }, [data])

    return (
        <>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                }}
                autoComplete="off"
            >
                <Row>
                    <Col className="col-12">
                        <div className="mb-3">
                            <FormInput
                                id="news_feed_title"
                                label="News Feed Title"
                                required
                                name="news_feed_title"
                                className="form-control"
                                placeholder={t("News Feed Title")}
                                type="text"
                                maxLength={200}
                                value={formik.values.news_feed_title || ""}
                                onBlur={formik.handleBlur}
                                onChange={(e) =>
                                    formik.setFieldValue("news_feed_title", e.target.value)
                                }
                                touched={formik.touched.news_feed_title}
                                error={formik.errors.news_feed_title}
                            />
                        </div>
                    </Col>
                    <Col className="col-12">
                        <div className="mb-3">
                            <TextEditor
                                id="description"
                                name="description"
                                label="Description"
                                required
                                value={formik.values.description}
                                onChange={(e: any, editor: any) => formik.setFieldValue('description', editor.getData())}
                                className="form-control"
                                onBlur={() => formik.setFieldTouched("description", true)}
                                touched={formik.touched.description}
                                error={formik.errors.description}
                            />
                        </div>
                    </Col>
                    <Col sm={6} xs={12}>
                        <div className="mb-3">
                            <AvatarEditor
                                label={t('Attachment')}
                                profileImage={oldImage}
                                profileReset={false}
                                width={200}
                                height={150}
                                borderRadius={0}
                                onAvatarChange={(image: string) => onAvatarChange(image)}
                            />
                            {/* 
                            <FormInput
                                id="attachment"
                                name="attachment"
                                label="Attachment"
                                className="form-control"
                                placeholder={t("Attachment")}
                                type="file"
                                accept="image/*"
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                    e.target.files && uploadAttachment(e.target.files[0])
                                    // e.target.files && formik.setFieldValue("attachment", 'test')
                                }
                                }
                                touched={formik.touched.attachment}
                                error={formik.errors.attachment}
                            />
                            <img src={formik.values.attachment} height="60" /> */}
                        </div>
                    </Col>
                    <Col sm={6} xs={12}>
                        <div className="mb-3">
                            <Select
                                id="publication"
                                name="publication"
                                label="Publication"
                                className="is-invalid"
                                required
                                options={publicationOptions.map((a: any) => ({ ...a, label: t(a.label) }))}
                                value={
                                    formik.values.publication
                                        ? publicationOptions.find(
                                            (option: any) =>
                                                option.value == formik.values.publication
                                        )
                                        : ""
                                }
                                isSearchable={false}
                                onBlur={formik.handleBlur}
                                onChange={(e: any) => {
                                    formik.setFieldValue("publication", e.value);
                                }}
                                error={formik.errors.publication}
                                touched={formik.touched.publication}
                            />
                        </div>
                    </Col>
                    <Col className="col-12">
                        <div className="mb-3">
                            <FormInput
                                id="hyperlink"
                                label="Hyperlink"
                                name="hyperlink"
                                className="form-control"
                                placeholder={t("Hyperlink")}
                                type="text"
                                value={formik.values.hyperlink || ""}
                                onBlur={formik.handleBlur}
                                onChange={(e) =>
                                    formik.setFieldValue("hyperlink", e.target.value)
                                }
                                touched={formik.touched.hyperlink}
                                error={formik.errors.hyperlink}
                            />
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className="mb-3">
                            <DatePicker
                                id="news_feed_creation_date"
                                name="news_feed_creation_date"
                                label="News feed creation date"
                                className="form-control d-block"
                                type="text"
                                placeholder="DD/MM/YYYY"
                                value={formik.values?.news_feed_creation_date}
                                touched={formik.touched.news_feed_creation_date}
                                error={formik.errors.news_feed_creation_date}
                                options={{
                                    altInput: true,
                                    altFormat: "d/m/Y",
                                    dateFormat: "Y-m-d",
                                    defaultDate: new Date(),
                                    maxDate: 'today',
                                    allowInput: true,
                                    onChange: function (
                                        selectedDates,
                                        dateStr,
                                        instance
                                    ) {
                                        formik.setFieldValue(
                                            "news_feed_creation_date",
                                            dateStr
                                        );
                                    },
                                }}
                            />
                        </div>
                    </Col>
                    <Col sm={6} xs={12}>
                        <div className="mb-3">
                            <Select
                                id="priority"
                                name="priority"
                                label="Priority"
                                className="is-invalid"
                                required
                                options={priorityOptions.map((a: any) => ({ ...a, label: t(a.label) }))}
                                value={
                                    formik.values.priority
                                        ? priorityOptions.find(
                                            (option: any) =>
                                                option.value == formik.values.priority
                                        )
                                        : ""
                                }
                                isSearchable={false}
                                onBlur={formik.handleBlur}
                                onChange={(e: any) => {
                                    formik.setFieldValue("priority", e.value);
                                }}
                                error={formik.errors.priority}
                                touched={formik.touched.priority}
                            />
                        </div>
                    </Col>
                    <Col className="col-12">
                        <div className="mb-3">
                            <FormInput
                                id="sources"
                                name="sources"
                                label="Sources"
                                className="form-control"
                                placeholder={t("Sources")}
                                type="text"
                                value={formik.values.sources || ""}
                                onBlur={formik.handleBlur}
                                onChange={(e) =>
                                    formik.setFieldValue("sources", e.target.value)
                                }
                                touched={formik.touched.sources}
                                error={formik.errors.sources}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="ms-auto d-flex gap-2 justify-content-end">
                            <Button color='primary' type='submit' size='lg' loading={formik.isSubmitting} >{t('Save')}</Button>
                            <Button type='button' color='danger' size='lg' disabled={formik.isSubmitting} onClick={() => { onCancel && onCancel() }} >{t('Cancel')}</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default NewsFeedForm;