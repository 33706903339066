import React, { FC, useState, useEffect } from 'react';
import { Col, Container, Input, Label, OffcanvasBody, Row } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import moment from 'moment';
import { toast } from 'react-toastify';
import { invoiceStatuses } from '../../../common/staticdata';
import { formatCurrency } from '../../../utils/formats';
import disputesService from '../../../services/disputes.services';
import invoiceService from '../../../services/invoices.service';
import Button from '../../../components/Button/Button';
import { addIndividualInCompany } from '../../../helpers/functions_helper';
import Select from '../../../components/Form/Select/Select';
import commonService from '../../../services/common.service';



type TransferInvoiceFormProps = {
    values: any;
    onApply?: any;
    onClose: any;
    onChange?: any;
}

interface InvoiceTransferFormType extends InvoiceType {
    dispute_id: number;
    company_id: number;
    contact_person_id: number;
    company: any;
    isSubmitting: boolean;
}

const TransferInvoiceForm: FC<TransferInvoiceFormProps> = ({ values, onApply, onClose, onChange }) => {
    const toastId = React.useRef(null) as any;
    const [companyOption, setCompanyOption] = useState([]);
    const [contactPersonOption, setContactPersonOption] = useState([]);
    const [loadingCompany, setloadingCompanyOption] = useState(true);
    const [loadingContactPerson, setloadingContactPerson] = useState(true);
    const [query, setQuery] = useState("");
    const [formValues, setFormValues] = useState<InvoiceTransferFormType>({
        ...values,
        company_id: 0,
        contact_person_id: 0,
        company: null,
        isSubmitting: false
    });

    const getSelectOptions = async (tableName: string, fieldName: string, otherField?: Array<string>) => {
        const response = await commonService.getSelectOptionsList(
            tableName,
            fieldName,
            query ? query : "",
            otherField
        );
        return response.data.data;
    };

    const getDependentSelectOptions = async (
        tableName: string,
        fieldName: string,
        whereField: string,
        whereID: number
    ) => {
        const response = await commonService.getDependentSelectOptionsList(
            tableName,
            fieldName,
            whereField,
            whereID,
            query ? query : ""
        );
        return response.data.data;
    };


    const handleSubmit = async (data: InvoiceTransferFormType) => {
        toastId.current = toast("Submitting credit note...", { autoClose: false, isLoading: true });
        setFormValues((state: InvoiceTransferFormType) => ({ ...state, isSubmitting: true }))
        const result = await invoiceService.createCreditMemo({
            ...data,
            invoice_date: moment(new Date()).format('YYYY-MM-DD'),
            due_date: moment(new Date()).format('YYYY-MM-DD'),
            credit_note_amount: data.total,
            amount_due: data.amount_due - (data.total + ((data.tax_rate * data.total) / 100)),
            company_id: values.company_id,
            contact_person_id: values.contact_person_id,
            credit_note: `Dispute of ${formatCurrency(data?.total)} is raised by client.`,
            status: "Draft"
        });
        if (result.status === 'success') {
            const inv = await invoiceService.createInvoice({
                ...data,
                invoice_date: moment(new Date()).format('YYYY-MM-DD'),
                due_date: moment(new Date()).format('YYYY-MM-DD'),
                company_id: formValues.company_id,
                tax_rate: data?.company?.vat_percentage,
                tax_amount: ((data.tax_rate * data.total) / 100),
                amount_due: data.amount_due - (data.total + ((data?.company?.vat_percentage * data.total) / 100)),
                contact_person_id: formValues.contact_person_id,
                status: "Draft"
            });
            if (inv.status === 'success') {
                const res = disputesService.creditNote(data.dispute_id, result.data.id);
                toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: 'Credit note submitted successfully', autoClose: 5000, isLoading: false, delay: 500 });
                onApply();
                onClose();
            }
        }
        setFormValues((state: InvoiceTransferFormType) => ({ ...state, isSubmitting: false }))
    }

    useEffect(() => {
        getSelectOptions("tbl_master_company", "company_name", ['company_address', 'postal_code', 'city', 'country', 'vatcat', 'vat_percentage', 'TVW_Nr', 'company_name', 'invoice_payable_period', 'invoice_contact_person']).then(function (
            options
        ) {
            const item = addIndividualInCompany(options) as any;
            setCompanyOption(item);
            setloadingCompanyOption(false);
        });
        // console.log("values", values);
    }, []);

    useEffect(() => {
        setloadingContactPerson(true);
        getDependentSelectOptions(
            "tbl_master_users",
            "first_name",
            "company",
            formValues.company_id
        ).then(function (options) {
            // console.log('options', options);
            if (formValues?.company_id != 0) {
                setContactPersonOption(options.filter((cp: any) => cp.value == formValues?.company?.invoice_contact_person));
                setFormValues((state: InvoiceTransferFormType) => ({ ...state, contact_person_id: formValues?.company?.invoice_contact_person }))
            } else {
                setContactPersonOption(options);
            }
            setloadingContactPerson(false)
        })

    }, [formValues.company_id])

    return (
        <React.Fragment>
            <div style={{ height: 'calc(100vh - 250px)' }}>
                <Col>
                    <Row>
                        <div className="mb-3">
                            <Label className="form-label">
                                Date
                            </Label>
                            <div className="input-group is-invalid">
                                <Flatpickr
                                    id="invoice_date"
                                    name="invoice_date"
                                    className="form-control d-block"
                                    type="text"
                                    placeholder="DD/MM/YYYY"
                                    value={formValues?.invoice_date}
                                    readOnly
                                    disabled={true}
                                    options={{
                                        altInput: true,
                                        altFormat: "d/m/Y",
                                        dateFormat: "Y-m-d",
                                        defaultDate: values?.invoice_date,
                                        allowInput: true,
                                        onChange: function (
                                            selectedDates,
                                            dateStr,
                                            instance
                                        ) {
                                            setFormValues((state: InvoiceTransferFormType) => ({ ...state, invoice_date: dateStr }))
                                        },
                                        locale: { firstDayOfWeek: 1 }
                                    }}
                                />
                                <span className="input-group-text">
                                    <span className="align-middle material-symbols-outlined fw-light fs-4">calendar_today</span>
                                </span>
                            </div>
                        </div>
                    </Row>


                    <Row>
                        <div className="mb-3">
                            <Select
                                id="company_id"
                                name="company_id"
                                label='Company'
                                className="is-invalid"
                                options={companyOption}
                                //@ts-ignore
                                defaultValue={companyOption.find((o: any) => o.value === values?.company_id)}
                                isSearchable={true}
                                isDisabled={loadingCompany}
                                onChange={(option: any) => {
                                    setFormValues((state: InvoiceTransferFormType) => ({ ...state, company_id: option.value }));
                                    setFormValues((state: InvoiceTransferFormType) => ({ ...state, company: option }));
                                    // alert(JSON.stringify(option));
                                    // setFieldValue("company_id", option.value);
                                    //@ts-ignore
                                    // setFieldValue("due_date", moment(values?.invoice_date).add(option.invoice_payable_period, 'days').format("YYYY-MM-DD hh:mm:ss"));
                                    // setFieldValue("tax_rate", option.vat_percentage);

                                }}
                            />
                        </div>
                        <div className="mb-3">
                            <Select
                                id="contact_person_id"
                                name="contact_person_id"
                                label='Contact Person'
                                className="is-invalid"
                                options={contactPersonOption}
                                //@ts-ignore
                                defaultValue={contactPersonOption.find((o: any) => o.value === values?.contact_person_id)}
                                isSearchable={true}
                                isDisabled={loadingCompany}
                                onChange={(option: any) => {
                                    setFormValues((state: InvoiceTransferFormType) => ({ ...state, contact_person_id: option.value }));
                                    // setFieldValue("contact_person_id", option.value);
                                    if (option?.company === 0) {
                                        // setFieldValue("tax_rate", option.vat_percentage);
                                    }
                                }}
                            />
                        </div>
                    </Row>
                </Col>
            </div>
            <Col>
                <Row>
                    {/* <div className="mb-3">
                            <Label className="form-label">
                                Note
                            </Label>
                            <Input
                                id={`credit_note`}
                                name='credit_note'
                                type='textarea'
                                placeholder={'Enter reason for raising the credit note here'}
                                className=''
                                value={formValues?.credit_note || ''}
                                onChange={(e) => {
                                    setFormValues((state: InvoiceTransferFormType) => ({ ...state, credit_note: e.target.value }))
                                }}
                            />
                        </div> */}
                    <Button
                        color="primary"
                        style={{ margin: "16px", width: "calc(100% - 64px)", top: "calc(100vh - 70px)", position: "absolute" }}
                        onClick={() => handleSubmit(formValues)}
                        disabled={formValues.isSubmitting}
                        loading={formValues.isSubmitting}
                        fullWidth
                    >
                        Submit
                    </Button>
                </Row>
            </Col>
        </React.Fragment>
    )
}

export default TransferInvoiceForm;