import { 
  GET_FEE,
  GET_FEE_SUCCESS,
  GET_FEE_ERROR,

  GET_FEES_LIST,
  GET_FEES_LIST_SUCCESS,
  GET_FEES_LIST_ERROR,
  
  CREATE_FEE,
  CREATE_FEE_SUCCESS,
  CREATE_FEE_ERROR, 

  UPDATE_FEE,
  UPDATE_FEE_SUCCESS,
  UPDATE_FEE_ERROR,

  DELETE_FEE,
  DELETE_FEE_SUCCESS,
  DELETE_FEE_ERROR,
} from "./actionTypes"
  
  const initialState = {
    GET_FEE_SUCCESS: "",
    GET_FEE_ERROR: "",
    GET_FEES_LIST_SUCCESS: "",
    GET_FEES_LIST_ERROR: "",
    CREATE_FEE_SUCCESS: "",
    CREATE_FEE_ERROR: "",
    UPDATE_FEE: "",
    UPDATE_FEE_SUCCESS: "",
    UPDATE_FEE_ERROR: "",
    DELETE_FEE: "",
    DELETE_FEE_SUCCESS: "",
    DELETE_FEE_ERROR: "",
    loading: false,
  }
  
  const reducer = (state = initialState, action: any) => {
    switch (action.type) {
      // ---------------------------------------------GET_FEE
      case GET_FEE:
        state = {
          ...state,
          loading: true,
        }
        break

      case GET_FEE_SUCCESS:
        state = {
          ...state,
          GET_FEE_SUCCESS: action.payload,
          loading: false,
        }
        break

      case GET_FEE_ERROR:
        state = { 
          ...state, 
          GET_FEE_ERROR: action.payload, 
          loading: false 
        }
        break

      // ---------------------------------------------GET_FEES_LIST
      case GET_FEES_LIST:
        state = {
          ...state,
          loading: true,
        }
        break

      case GET_FEES_LIST_SUCCESS:
        state = {
          ...state,
          GET_FEES_LIST_SUCCESS: action.payload,
          loading: false,
        }
        break

      case GET_FEES_LIST_ERROR:
        state = { 
          ...state, 
          GET_FEES_LIST_ERROR: action.payload, 
          loading: false 
        }
        break
        
      // ---------------------------------------------CREATE_FEE
      case CREATE_FEE:
        state = {
          ...state,
          loading: true,
        }
        break

      case CREATE_FEE_SUCCESS:
        state = {
          ...state,
          CREATE_FEE_SUCCESS: action.payload,
          loading: false,
        }
        break

      case CREATE_FEE_ERROR:
        state = { 
          ...state, 
          CREATE_FEE_ERROR: action.payload, 
          loading: false 
        }
        break

      // ---------------------------------------------UPDATE_FEE
      case UPDATE_FEE:
        state = {
          ...state,
          loading: true,
        }
        break

      case UPDATE_FEE_SUCCESS:
        state = {
          ...state,
          UPDATE_FEE_SUCCESS: action.payload,
          loading: false,
        }
        break

      case UPDATE_FEE_ERROR:
        state = { 
          ...state, 
          UPDATE_FEE_ERROR: action.payload, 
          loading: false 
        }
        break

      // ---------------------------------------------DELETE_FEE
      case DELETE_FEE:
        state = {
          ...state,
          loading: true,
        }
        break

      case DELETE_FEE_SUCCESS:
        state = {
          ...state,
          DELETE_FEE_SUCCESS: action.payload,
          loading: false,
        }
        break

      case DELETE_FEE_ERROR:
        state = { 
          ...state, 
          DELETE_FEE_ERROR: action.payload, 
          loading: false 
        }
        break

      // ---------------------------------------------DEFAULT
      default:
        state = { ...state }
        break
    }
    return state
  }
  
  export default reducer
  