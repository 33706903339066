import { takeEvery, call, put } from "redux-saga/effects";
import subTaskServices from "../../services/subtasks.service";
import { AnyAction } from "redux";

// Tasks Redux States
import {
  GET_SUB_TASK,
  GET_SUB_TASKS_LIST,
  CREATE_SUB_TASK,
  UPDATE_SUB_TASK,
  DELETE_SUB_TASK,
} from "./actionTypes";

import { 
    getSubTaskSuccess, 
    getSubTaskError,
    
    getSubTasksListSuccess,
    getSubTasksListError,

    createSubTaskSuccess,
    createSubTaskError,

    updateSubTaskSuccess,
    updateSubTaskError,

    deleteSubTaskSuccess,
    deleteSubTaskError,
} from "./actions";

function* getSubTask({ payload: { tasks, history } }: AnyAction): any {
  try {
    const response = yield call(subTaskServices.getSubTask, tasks);
    if (response.data && response.data.status === "success") {
      yield put(getSubTaskSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getSubTaskError(response.data));
    } else {
      yield put(getSubTaskError(response));
    }
  } catch (error: any) {
    yield put(getSubTaskError(error));
  }
}

function* getSubTasksList({ payload: { query, history,id } }: AnyAction): any {
  try {
    const response = yield call(subTaskServices.getSubTasksList, query);
    if (response.data && response.data.status === "success") {
      yield put(getSubTasksListSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getSubTasksListError(response.data));
    } else {
      yield put(getSubTasksListError(response));
    }
  } catch (error: any) {
    yield put(getSubTasksListError(error));
  }
}

function* createSubTask({ payload: { tasks, history } }: AnyAction): any {
  try {
    const response = yield call(subTaskServices.createSubTask, tasks);
    if (response.data && response.data.status === "success") {
      yield put(createSubTaskSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(createSubTaskError(response.data));
    } else {
      yield put(createSubTaskError(response));
    }
  } catch (error: any) {
    yield put(createSubTaskError(error));
  }
}

function* updateSubTask({ payload: { tasks, history } }: AnyAction): any {
  try {
    const response = yield call(subTaskServices.updateSubTask, tasks);
    if (response.data && response.data.status === "success") {
      yield put(updateSubTaskSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(updateSubTaskError(response.data));
    } else {
      yield put(updateSubTaskError(response));
    }
  } catch (error: any) {
    yield put(updateSubTaskError(error));
  }
}

function* deleteSubTask({ payload: { tasks, history } }: AnyAction): any {
  try {
    const response = yield call(subTaskServices.deleteSubTask, tasks);
    if (response.data && response.data.status === "success") {
      yield put(deleteSubTaskSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(deleteSubTaskError(response.data));
    } else {
      yield put(deleteSubTaskError(response));
    }
  } catch (error: any) {
    yield put(deleteSubTaskError(error));
  }
}

function* tasksSaga(): any {
  yield takeEvery(GET_SUB_TASK, getSubTask);
  yield takeEvery(GET_SUB_TASKS_LIST, getSubTasksList);
  yield takeEvery(CREATE_SUB_TASK, createSubTask);
  yield takeEvery(UPDATE_SUB_TASK, updateSubTask);
  yield takeEvery(DELETE_SUB_TASK, deleteSubTask);
}

export default tasksSaga;
