import {
  GET_TIME_SHEET,
  GET_TIME_SHEET_SUCCESS,
  GET_TIME_SHEET_ERROR,

  GET_TIME_SHEETS_GROUP_LIST,
  GET_TIME_SHEETS_GROUP_LIST_SUCCESS,
  GET_TIME_SHEETS_GROUP_LIST_ERROR,
  
  GET_TIME_SHEETS_LIST,
  GET_TIME_SHEETS_LIST_SUCCESS,
  GET_TIME_SHEETS_LIST_ERROR,

  CREATE_TIME_SHEET,
  CREATE_TIME_SHEET_SUCCESS,
  CREATE_TIME_SHEET_ERROR,

  GET_TIME_SHEET_FORM,
  GET_TIME_SHEET_FORM_SUCCESS,
  GET_TIME_SHEET_FORM_ERROR,

  CREATE_TIME_SHEET_FORM,
  CREATE_TIME_SHEET_FORM_SUCCESS,
  CREATE_TIME_SHEET_FORM_ERROR,

  UPDATE_TIME_SHEET,
  UPDATE_TIME_SHEET_SUCCESS,
  UPDATE_TIME_SHEET_ERROR,

  DELETE_TIME_SHEET,
  DELETE_TIME_SHEET_SUCCESS,
  DELETE_TIME_SHEET_ERROR,
} from "./actionTypes";

// ---------------------------------------------GET_TIME_SHEET
export const getTimeSheet = (timesheets: any, history: any) => {
  return {
    type: GET_TIME_SHEET,
    payload: { timesheets, history },
  };
};

export const getTimeSheetSuccess = (data: {}) => {
  return {
    type: GET_TIME_SHEET_SUCCESS,
    payload: data,
  };
};

export const getTimeSheetError = (error: {}) => {
  return {
    type: GET_TIME_SHEET_ERROR,
    payload: error,
  };
};

// ---------------------------------------------GET_TIME_SHEETS_GROUP_LIST
export const getTimeSheetsGroupList = (query: any, history: any) => {
  return {
    type: GET_TIME_SHEETS_GROUP_LIST,
    payload: {query, history},
  };
};

export const getTimeSheetsGroupListSuccess = (data: {}) => {
  return {
    type: GET_TIME_SHEETS_GROUP_LIST_SUCCESS,
    payload: data,
  };
};

export const getTimeSheetsGroupListError = (error: {}) => {
  return {
    type: GET_TIME_SHEETS_GROUP_LIST_ERROR,
    payload: error,
  };
};

// ---------------------------------------------GET_TIME_SHEETS_LIST
export const getTimeSheetsList = (query: any, history: any) => {
  return {
    type: GET_TIME_SHEETS_LIST,
    payload: {query, history},
  };
};

export const getTimeSheetsListSuccess = (data: {}) => {
  return {
    type: GET_TIME_SHEETS_LIST_SUCCESS,
    payload: data,
  };
};

export const getTimeSheetsListError = (error: {}) => {
  return {
    type: GET_TIME_SHEETS_LIST_ERROR,
    payload: error,
  };
};

// ---------------------------------------------CREATE_TIME_SHEET
export const createTimeSheet = (timesheets: any, history: any) => {
  return {
    type: CREATE_TIME_SHEET,
    payload: { timesheets, history },
  };
};

export const createTimeSheetSuccess = (timesheets: {}) => {
  return {
    type: CREATE_TIME_SHEET_SUCCESS,
    payload: timesheets,
  };
};

export const createTimeSheetError = (error: {}) => {
  return {
    type: CREATE_TIME_SHEET_ERROR,
    payload: error,
  };
};

// ---------------------------------------------GET_TIME_SHEET_FORM
export const getTimeSheetForm = (timesheets: any, history: any) => {
  return {
    type: GET_TIME_SHEET_FORM,
    payload: { timesheets, history },
  };
};

export const getTimeSheetFormSuccess = (timesheets: {}) => {
  return {
    type: GET_TIME_SHEET_FORM_SUCCESS,
    payload: timesheets,
  };
};

export const getTimeSheetFormError = (error: {}) => {
  return {
    type: GET_TIME_SHEET_FORM_ERROR,
    payload: error,
  };
};

// ---------------------------------------------CREATE_TIME_SHEET_FORM
export const createTimeSheetForm = (timesheets: any, history: any) => {
  return {
    type: CREATE_TIME_SHEET_FORM,
    payload: { timesheets, history },
  };
};

export const createTimeSheetFormSuccess = (timesheets: {}) => {
  return {
    type: CREATE_TIME_SHEET_FORM_SUCCESS,
    payload: timesheets,
  };
};

export const createTimeSheetFormError = (error: {}) => {
  return {
    type: CREATE_TIME_SHEET_FORM_ERROR,
    payload: error,
  };
};

// ---------------------------------------------UPDATE_TIME_SHEET
export const updateTimeSheet = (timesheets: any, history: any) => {
  return {
    type: UPDATE_TIME_SHEET,
    payload: { timesheets, history },
  };
};

export const updateTimeSheetSuccess = (timesheets: {}) => {
  return {
    type: UPDATE_TIME_SHEET_SUCCESS,
    payload: timesheets,
  };
};

export const updateTimeSheetError = (error: {}) => {
  return {
    type: UPDATE_TIME_SHEET_ERROR,
    payload: error,
  };
};

// ---------------------------------------------DELETE_TIME_SHEET
export const deleteTimeSheet = (timesheets: any, history: any) => {
  return {
    type: DELETE_TIME_SHEET,
    payload: { timesheets, history },
  };
};

export const deleteTimeSheetSuccess = (timesheets: {}) => {
  return {
    type: DELETE_TIME_SHEET_SUCCESS,
    payload: timesheets,
  };
};

export const deleteTimeSheetError = (error: {}) => {
  return {
    type: DELETE_TIME_SHEET_ERROR,
    payload: error,
  };
};
