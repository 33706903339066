import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Button,
} from "reactstrap";

// Import Breadcrumb
import Breadcrumbs from "../../../components/Layout/Breadcrumb";

// Import DeleteModal
import Form from "./Form";

// Import DeleteModal
import DeleteModal from "../../../components/Common/DeleteModal";

// Import DataTable
import DataTable from "../../../components/Common/DataTable";

import { getDataTableQuery, getUserProfile } from "../../../helpers/functions_helper";
import service from "../../../services/master.bank.holiday.service";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import UploadForm from "./UploadForm";
import { useTranslation } from "react-i18next";
import { dateFormat } from "../../../utils/formats";


type BankHolidayProps = {
    company?: any;
    year?: number;
    holiday_date?: string;
    holiday_name?: string;
}
const BankHoliday: FC<BankHolidayProps> = ({ company }) => {
    const { t } = useTranslation();
    document.title = `${t("Bank holiday")} | ` + process.env.REACT_APP_NAME;

    const breadcrumbItems = [
        {
            url: "/dashboard",
            label: t("Dashboard"),
            active: false,
        },
        {
            url: "#",
            label: t("Bank holiday"),
            active: true,
        },
    ];
    const [loader, setLoader] = useState(true);
    const dispatch = useDispatch();

    const useObj = getUserProfile();

    const [loadingListData, setLoadingListData] = useState(false);
    const [datatableOptions, setDatatableOptions] = useState({
        draw: 1,
        page: 1,
        recordsTotal: 0,
        recordsFiltered: 0,
        sizePerPage: 20,
        searchText: "",
        sortField: "id",
        sortOrder: "desc",
    });
    const [holidayList, setHolidayList] = useState([]);
    useEffect(() => {
        setLoadingListData(true);
        setHolidayList([]);
    }, [localStorage.getItem("I18N_LANGUAGE")])
    const columns = [
        {
            dataField: "DT_RowIndex",
            text: t("Sr. Nr."),
            sort: false,
            search: false,
        },
        {
            dataField: "country_name",
            text: t("Country"),
            sort: true,
            search: true,
        },
        {
            dataField: "year",
            text: t("Year"),
            sort: true,
            search: true,
        },
        {
            dataField: "holiday_date",
            text: t("Date"),
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => {
                return row.holiday_date && dateFormat(row.holiday_date);
            },
        },
        {
            dataField: "holiday_name",
            text: t("Holiday"),
            sort: true,
            search: true,
        },
        {
            dataField: "action",
            text: t("Action"),
            sort: false,
            search: false,
            width: 200,
            hidden: !(useObj.USER_COMPANY === 1),
            formatter: (rowContent: any, row: any): any => {
                return (
                    <div className="d-flex gap-2 text-end">
                        <Link
                            className="btn btn-pill btn-outline-primary btn-sm"
                            to="#"
                            onClick={() => onClickEdit(row)}
                        >
                            <i className="fa-regular fa-pen-to-square"></i> {t("Edit")}
                        </Link>
                        <Link
                            className="btn btn-pill btn-outline-danger btn-sm"
                            to="#"
                            onClick={() => onClickDelete(row)}
                        >
                            <i className="fa-regular fa-trash-can"></i> {t("Delete")}
                        </Link>
                    </div>
                );
            },
        },
    ];

    const getList = async () => {
        const res = await service.getAll({ ...getDataTableQuery(datatableOptions, columns) });
        if (res.data.status === 'success') {
            setHolidayList(res.data.data);
            const updatedDatatableOptions = {
                ...datatableOptions,
                recordsTotal: res.data.recordsTotal,
                recordsFiltered: res.data.recordsFiltered,
            };
            setDatatableOptions(updatedDatatableOptions);
            setLoadingListData(false);
            setLoader(false);
        }
    }

    useEffect(() => {
        setLoadingListData(true);
    }, [company?.id])

    useEffect(() => {
        loadingListData && getList();
    }, [loadingListData])


    const onTableChange = (type: any, newState: any) => {
        let draw = datatableOptions.draw + 1;
        let page = 1;
        if (type == "search") {
            page = 1;
        } else {
            page = newState.page;
        }
        const updatedDatatableOptions = {
            ...datatableOptions,
            draw: draw,
            page: page,
            sizePerPage: newState.sizePerPage,
            searchText: newState.searchText,
            sortField: newState.sortField,
            sortOrder: newState.sortOrder,
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingListData(true);
    };
    // Datatable -------------------------------------------------

    // Delete Form --------------------------------------------------------
    const [deleteRec, setDeleteRec] = useState("");
    const [recordName, setRecordName] = useState("");
    const [deleteModal, setDeleteModal] = useState(false);
    const [holidayData, setHolidayData] = useState({});

    const [masterFormModal, setMasterFormModal] = useState(false);
    const [masterFormModalTitle, setMasterFormModalTitle] = useState(t("Create bank holiday"));
    const [uploadModal, setUploadModal] = useState(false);

    const onClickDelete = (row: any) => {
        setDeleteRec(row.id);
        setRecordName(row.holiday_name);
        setDeleteModal(true);
    };

    const handleDelete = async () => {
        const res = await service.delete(deleteRec);
        if (res.status === 'success') {
            toast(res.message, { type: toast.TYPE.SUCCESS })
            setLoadingListData(true);
        }
        setDeleteModal(false)
    };
    // Delete Form --------------------------------------------------------

    // Master Form -------------------------------------------------------- 


    const onClickModal = () => {
        setMasterFormModalTitle(t("Create bank holiday"));
        setMasterFormModal(true);
        setHolidayData([]);
    };

    const onClickEdit = (row: any) => {
        setMasterFormModalTitle(t("Update bank holiday"));
        setMasterFormModal(true);
        setHolidayData(row);
    }

    const handleSubmit = async (values: any) => {
        try {
            if (values?.id && values?.country) {
                const res = await service.update(values);
                if (res.status === 'success') {
                    toast(res.message, { type: toast.TYPE.SUCCESS, onClose: () => setMasterFormModal(false) })
                    setLoadingListData(true);
                    setMasterFormModal(false);
                }
            } else {
                const res = await service.create(values);
                if (res.status === 'success') {
                    toast(res.message, { type: toast.TYPE.SUCCESS, onClose: () => setMasterFormModal(false) })
                    setLoadingListData(true);
                    setMasterFormModal(false);
                }
            }

        } catch (err: any) {
            toast(t('Something went wrong'), { type: toast.TYPE.ERROR })
        }
    };

    const clearFilter = () => {
        const updatedDatatableOptions = {
            ...datatableOptions,
            draw: 1,
            page: 1,
            sizePerPage: 20,
            searchText: "",
            sortField: "id",
            sortOrder: "desc",
        };
        setDatatableOptions(updatedDatatableOptions);

        setLoadingListData(true);
    };


    const handleBulkSubmit = async (values: any) => {
        try {
            const res = await service.bulkcreate(values);
            if (res.status === 'success') {
                toast(res.message, { type: toast.TYPE.SUCCESS });
                setUploadModal(false);
            } else {
                toast(res.message, { type: toast.TYPE.ERROR });
                setUploadModal(false);
            }

        } catch (err: any) {
            toast(t('Something went wrong'), { type: toast.TYPE.ERROR })
        }
    };

    return (
        <React.Fragment>

            {deleteRec && (
                <DeleteModal
                    recordName={recordName}
                    modalTitle={t("Countries")}
                    show={deleteModal}
                    onDeleteClick={handleDelete}
                    onCloseClick={() => setDeleteModal(false)}
                    loading={false}
                />
            )}

            {masterFormModal && (
                <Form
                    modalTitle={masterFormModalTitle}
                    show={masterFormModal}
                    loading={loader}
                    holidayData={holidayData}
                    onSubmitClick={handleSubmit}
                    onCloseClick={() => setMasterFormModal(false)}
                    errorMsg={''}
                    errorData={''}
                />
            )}

            {uploadModal && (
                <UploadForm
                    show={uploadModal}
                    onCloseClick={() => setUploadModal(false)}
                    onSubmitClick={handleBulkSubmit}
                />
            )}

            <div className={company?.id ? "" : "page-content"}>
                <Container fluid>
                    <Breadcrumbs
                        breadcrumbTitle={t("Bank Holidays")}
                        breadcrumbItems={company?.id ? [] : breadcrumbItems}
                        endAdornment={
                            <div className="d-flex gap-2 text-end">
                                {useObj?.USER_COMPANY === 1 &&
                                    <>
                                    
                                    <Link
                                            to="/masters/bank-holiday"
                                            className={`btn btn-info btn-label btn-sm`}
                                        >
                                            <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">list</span> {t("List view")}
                                        </Link>
                                        <Link
                                            to="/masters/bank-holiday/calender"
                                            className={`btn btn-outline-secondary btn-label btn-sm`}
                                        >
                                            <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">calendar_month</span> {t("Calender view")}
                                        </Link>
                                        <Link
                                            to="#"
                                            className="btn btn-outline-secondary btn-label btn-sm"
                                            onClick={() => onClickModal()}
                                        >
                                            <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">add</span> {t("Create bank holiday")}
                                        </Link>
                                    </>
                                }
                            </div>
                        }
                    />

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <DataTable
                                        columns={columns}
                                        products={holidayList}
                                        datatableOptions={datatableOptions}
                                        filters={[]}
                                        onTableChange={onTableChange}
                                        loadingListData={loadingListData}
                                        clearFilter={clearFilter}
                                        slug="masters"
                                        customButtons={
                                            useObj?.USER_COMPANY === 1 ?
                                                <Button
                                                    outline
                                                    color="light"
                                                    onClick={() => setUploadModal(true)}
                                                    title={t("Upload belgium holidays")}
                                                >
                                                    <span className="align-middle material-symbols-outlined fw-light fs-4">upload</span>
                                                </Button> : <></>
                                        }
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default BankHoliday;
