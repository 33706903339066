import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  Row,
} from "reactstrap";
import DataTable from "../../components/Common/DataTable";
import Breadcrumbs from "../../components/Layout/Breadcrumb";
import {
  handleFilters,
  getDataTableQuery,
  filteredByFunction,
} from "../../helpers/functions_helper";
import clientAllocationService from "../../services/client.allocation.service";
import { useDispatch, useSelector } from "react-redux";
import { CommonAction } from "../../store/actions";
import Drawer from "../../components/Drawer";
import EditUser from "../Users/EditUser";
import EditCompanyEmployees from "../Masters/Company/EditCompanyEmployees";
import { Link } from "react-router-dom";

const ClientAllocationUsersList = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const moduleName = t("Client allocation");
  document.title = moduleName + " | " + process.env.REACT_APP_NAME;

  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "#",
      label: moduleName,
      active: true,
    },
  ];

  const savedfilters = handleFilters("GET");

  const [loadingListData, setLoadingListData] = useState(true);
  const [datatableListData, setDatatableListData] = useState([]);
  const [selectedData, setSelectedData] = useState<any>();
  const [selectedType, setSelectedType] = useState<any>();
  const [openUser, setOpenUser] = useState<boolean>(false);

  const columns = [
    {
      dataField: "DT_RowIndex",
      text: t("Sr. No."),
      sort: false,
      search: false,
      classes: "fix-col-left",
      headerClasses: "fix-col-left",
      headerStyle: { width: "65px" },
      style: { width: "65px" },
    },
    {
      dataField: "user_name",
      text: t("User name"),
      sort: true,
      search: true,
      // formatter: (rowContent: any, row: any): any => {
      //   return (
      //     <div
      //       className="link-primary text-decoration-underline"
      //       onClick={() => handleUserClick(row)}
      //     >
      //       {row.user_name}
      //     </div>
      //   );
      // },
      formatter: (rowContent: any, row: any): any => {
        return row.active_client_count > 0 ? (
          <Link
            className="text-decoration-underline"
            to={`/client-allocation/clients?filters={"user_id": ${row.id}}`}
          >
            {row.user_name}
          </Link>
        ) : (
          <>
            {row.user_name}
            <span
              className="badge_custome badge-soft-info badge rounded-pill ms-2"
              title={t("Not allocated")}
            >
              {t("Not allocated")}
            </span>
          </>
        );
      },
    },
    {
      dataField: "preparer_count",
      text: t("Preparer"),
      sort: true,
      search: false,
      formatter: (rowContent: any, row: any): any => {
        return row.preparer_count > 0 ? (
          <Link
            className="text-decoration-underline"
            to={`/client-allocation/clients?filters={"user_id": ${row.id}, "is_preparer": true}`}
          >
            {row.preparer_count}
          </Link>
        ) : (
          row.preparer_count
        );
      },
    },
    {
      dataField: "reviewer_count",
      text: t("Reviewer"),
      sort: true,
      search: false,
      formatter: (rowContent: any, row: any): any => {
        return row.reviewer_count > 0 ? (
          <Link
            className="text-decoration-underline"
            to={`/client-allocation/clients?filters={"user_id": ${row.id}, "is_reviewer": true}`}
          >
            {row.reviewer_count}
          </Link>
        ) : (
          row.reviewer_count
        );
      },
    },
    {
      dataField: "file_manager_count",
      text: t("File manager"),
      sort: true,
      search: false,
      formatter: (rowContent: any, row: any): any => {
        return row.file_manager_count > 0 ? (
          <Link
            className="text-decoration-underline"
            to={`/client-allocation/clients?filters={"user_id": ${row.id}, "is_file_manager": true}`}
          >
            {row.file_manager_count}
          </Link>
        ) : (
          row.file_manager_count
        );
      },
    },
    {
      dataField: "signing_partner_count",
      text: t("Partner"),
      sort: true,
      search: false,
      formatter: (rowContent: any, row: any): any => {
        return row.signing_partner_count > 0 ? (
          <Link
            className="text-decoration-underline"
            to={`/client-allocation/clients?filters={"user_id": ${row.id}, "is_partner": true}`}
          >
            {row.signing_partner_count}
          </Link>
        ) : (
          row.signing_partner_count
        );
      },
    },
    {
      dataField: "assistant_administrative_count",
      text: t("Assistant administrative"),
      sort: true,
      search: false,
      formatter: (rowContent: any, row: any): any => {
        return row.assistant_administrative_count > 0 ? (
          <Link
            className="text-decoration-underline"
            to={`/client-allocation/clients?filters={"user_id": ${row.id}, "is_assistant_administrative": true}`}
          >
            {row.assistant_administrative_count}
          </Link>
        ) : (
          row.assistant_administrative_count
        );
      },
    },
    {
      dataField: "active_client_count",
      text: t("Active clients"),
      sort: true,
      search: false,
      formatter: (rowContent: any, row: any): any => {
        return row.active_client_count > 0 ? (
          <Link
            className="text-decoration-underline"
            to={`/client-allocation/clients?filters={"user_id": ${row.id}}`}
          >
            {row.active_client_count}
          </Link>
        ) : (
          row.active_client_count
        );
      },
    },
    {
      dataField: "active_employee_count",
      text: t("Person concerned"),
      sort: true,
      search: false,
      formatter: (rowContent: any, row: any): any => {
        return (
          <>
            {parseInt(row?.active_employee_count) > 0 ? (
              <>
                {row?.active_employee_count}

                {parseInt(row.company_active_employee_count) > 0 ? (
                  <span
                    className="badge_custome badge-soft-primary badge rounded-pill ms-2"
                    title={t("Employees")}
                    // onClick={() => handleEmployeesClick(row, "all")}
                  >
                    {row.company_active_employee_count} - {t("Employees")}
                  </span>
                ) : null}

                {parseInt(row?.individual_active_employee_count) > 0 ? (
                  <span
                    className="badge_custome badge-soft-info badge rounded-pill ms-2"
                    title={t("Individual")}
                    // onClick={() => handleEmployeesClick(row, "archive")}
                  >
                    {row.individual_active_employee_count} - {t("Individual")}
                  </span>
                ) : null}
              </>
            ) : (
              row.active_employee_count
            )}
          </>
        );
      },
    },
  ];

  const [datatableOptions, setDatatableOptions] = useState(
    savedfilters && savedfilters?.datatableOptions
      ? (savedfilters?.datatableOptions as any)
      : {
          draw: 1,
          page: 1,
          recordsTotal: 0,
          recordsFiltered: 0,
          sizePerPage: 20,
          searchText: "",
          sortField: "client_name",
          sortOrder: "asc",
        }
  );
  const [filters, setFilters] = useState(
    savedfilters && savedfilters?.filters
      ? (savedfilters?.filters as any)
      : {
          is_filtered: false,
        }
  );

  const onTableChange = (type: any, newState: any) => {
    let draw = datatableOptions.draw + 1;
    let page = 1;
    if (type == "search") {
      page = 1;
    } else {
      page = newState.page;
    }
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: draw,
      page: page,
      sizePerPage: newState.sizePerPage,
      searchText: newState.searchText,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
    };
    setDatatableOptions(updatedDatatableOptions);
    setDatatableListData([]);
    setLoadingListData(true);
  };

  const clearFilter = () => {
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: 1,
      page: 1,
      sizePerPage: 20,
      searchText: "",
      sortField: "id",
      sortOrder: "desc",
    };
    setDatatableOptions(updatedDatatableOptions);

    const filter = {
      ...filters,
      is_filtered: false,
    };
    setFilters(filter);

    setDatatableListData([]);
    setLoadingListData(true);
  };

  const loadingDatatable = async () => {
    try {
      const res = (await clientAllocationService.getAllUsers({
        ...getDataTableQuery(datatableOptions, columns),
        filters,
      })) as any;

      if (res.data.status === "success") {
        setDatatableListData(res.data.data);

        const updatedDatatableOptions = {
          ...datatableOptions,
          recordsTotal: res.data.recordsTotal,
          recordsFiltered: res.data.recordsFiltered,
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingListData(false);
      } else {
        setDatatableListData([]);

        const updatedDatatableOptions = {
          ...datatableOptions,
          recordsTotal: 0,
          recordsFiltered: 0,
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingListData(false);
      }
    } catch (err) {
      setDatatableListData([]);

      const updatedDatatableOptions = {
        ...datatableOptions,
        recordsTotal: 0,
        recordsFiltered: 0,
      };
      setDatatableOptions(updatedDatatableOptions);
      setLoadingListData(false);
    }
  };

  const handleUserClick = (data: any) => {
    setSelectedData(data);
    setOpenUser(true);
  };

  useEffect(() => {
    if (loadingListData === true) {
      loadingDatatable();
    }
  }, [loadingListData]);

  useEffect(() => {
    setDatatableListData([]);
    setLoadingListData(true);
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={moduleName}
            breadcrumbItems={breadcrumbItems}
          />

          <Row>
            <Col xl="12">
              <Card>
                <CardHeader className="d-sm-flex flex-wrap bg-transparent border-bottom pb-0">
                  <div className="d-flex">
                    <Nav tabs className="nav-tabs-custom">
                      <NavItem>
                        <Link
                          style={{ cursor: "pointer" }}
                          to="/client-allocation/internal-users"
                          className={`nav-link ${
                            window.location.pathname ===
                            "/client-allocation/internal-users"
                              ? "active"
                              : ""
                          }`}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home"></i>
                          </span>
                          <span className="d-none d-sm-block">
                            {t("Users")}
                          </span>
                        </Link>
                      </NavItem>
                      <NavItem>
                        <Link
                          style={{ cursor: "pointer" }}
                          to="/client-allocation/clients"
                          className={`nav-link ${
                            window.location.pathname ===
                            "/client-allocation/clients"
                              ? "active"
                              : ""
                          }`}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home"></i>
                          </span>
                          <span className="d-none d-sm-block">
                            {t("Clients")}
                          </span>
                        </Link>
                      </NavItem>
                    </Nav>
                  </div>
                </CardHeader>

                <CardBody>
                  <DataTable
                    columns={columns}
                    products={datatableListData}
                    datatableOptions={datatableOptions}
                    filters={filters}
                    onTableChange={onTableChange}
                    loadingListData={loadingListData}
                    clearFilter={clearFilter}
                    settingTableName="client_allocation_table"
                    slug="client-allocation"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Drawer
        title="User"
        open={openUser}
        onClose={() => setOpenUser((state) => !state)}
        fullWidth
      >
        <EditUser
          id={selectedData?.id}
          user_id={selectedData?.user_id}
          type={1}
          isWindow={true}
        />
      </Drawer>
    </React.Fragment>
  );
};

export default ClientAllocationUsersList;
