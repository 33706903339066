import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class TasksKanbanService {
    filter:any=[];
    async getKanbanTask(DATA: any) {
        const response = await axios
            .get(SERVER_URL + url.GET_TASK + "/" + DATA.id + "/" + DATA.task_id,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getKanbanTasksList(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_KANBAN_TASKS_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async createKanbanTask(DATA: any) {
        const response = await axios
            .put(SERVER_URL + url.CREATE_TASK, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async updateKanbanTask(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.UPDATE_KANBAN_TASK, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async deleteKanbanTask(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.DELETE_TASK, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getKanbanSearch(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_KANBAN_SEARCH, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getTaskAllocationByID(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_TASK_ALLOCATION, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getTaskAllocationList(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_TASKS_ALLOCATION_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getStatusWiseTaskCount() {
        const response = await axios
            .post(SERVER_URL + url.GET_STATUS_WISE_TASK_COUNT, {},
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getOriginatedTaskTypeCount() {
        const response = await axios
            .post(SERVER_URL + url.GET_ORIGINATED_WISE_TASK_LIST, {},
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }


    setFilter(filter: any) {
        this.filter = filter;
    }
    getFilter() {
        return this.filter;
    }

}

export default new TasksKanbanService();