import { 
  GET_KANBAN_TASK,
  GET_KANBAN_TASK_SUCCESS,
  GET_KANBAN_TASK_ERROR,

  GET_KANBAN_TASKS_LIST,
  GET_KANBAN_TASKS_LIST_SUCCESS,
  GET_KANBAN_TASKS_LIST_ERROR,
  
  CREATE_KANBAN_TASK,
  CREATE_KANBAN_TASK_SUCCESS,
  CREATE_KANBAN_TASK_ERROR, 

  UPDATE_KANBAN_TASK,
  UPDATE_KANBAN_TASK_SUCCESS,
  UPDATE_KANBAN_TASK_ERROR,

  DELETE_KANBAN_TASK,
  DELETE_KANBAN_TASK_SUCCESS,
  DELETE_KANBAN_TASK_ERROR,
  GET_KANBAN_SEARCH,
  GET_KANBAN_SEARCH_SUCCESS,
  GET_KANBAN_SEARCH_ERROR,

  GET_TASK_ALLOCATION,
  GET_TASK_ALLOCATION_SUCCESS,
  GET_TASK_ALLOCATION_ERROR,
  GET_TASK_ALLOCATION_LIST,
  GET_TASK_ALLOCATION_LIST_SUCCESS,
  GET_TASK_ALLOCATION_LIST_ERROR,


} from "./actionTypes"
  
  const initialState = {
    GET_KANBAN_TASK_SUCCESS: "",
    GET_KANBAN_TASK_ERROR: "",
    GET_KANBAN_TASKS_LIST_SUCCESS: "",
    GET_KANBAN_TASKS_LIST_ERROR: "",
    CREATE_KANBAN_TASK_SUCCESS: "",
    CREATE_KANBAN_TASK_ERROR: "",
    UPDATE_KANBAN_TASK: "",
    UPDATE_KANBAN_TASK_SUCCESS: "",
    UPDATE_KANBAN_TASK_ERROR: "",
    DELETE_KANBAN_TASK: "",
    DELETE_KANBAN_TASK_SUCCESS: "",
    DELETE_KANBAN_TASK_ERROR: "",
    GET_KANBAN_SEARCH_SUCCESS:"",
    GET_KANBAN_SEARCH_ERROR:"",
   
    GET_TASK_ALLOCATION:[],
    GET_TASK_ALLOCATION_SUCCESS:"",
    GET_TASK_ALLOCATION_ERROR:"",
    GET_TASK_ALLOCATION_LIST:[],
    GET_TASK_ALLOCATION_LIST_SUCCESS:"",
    GET_TASK_ALLOCATION_LIST_ERROR:"",

    loading: false,
  }
  
  const reducer = (state = initialState, action: any) => {
    switch (action.type) {
      // ---------------------------------------------GET_KANBAN_TASK
      case GET_KANBAN_TASK:
        state = {
          ...state,
          loading: true,
        }
        break

      case GET_KANBAN_TASK_SUCCESS:
        state = {
          ...state,
          GET_KANBAN_TASK_SUCCESS: action.payload,
          loading: false,
        }
        break

      case GET_KANBAN_TASK_ERROR:
        state = { 
          ...state, 
          GET_KANBAN_TASK_ERROR: action.payload, 
          loading: false 
        }
        break

      // ---------------------------------------------GET_KANBAN_TASKS_LIST
      case GET_KANBAN_TASKS_LIST:
        state = {
          ...state,
          loading: true,
        }
        break

      case GET_KANBAN_TASKS_LIST_SUCCESS:
        state = {
          ...state,
          GET_KANBAN_TASKS_LIST_SUCCESS: action.payload,
          loading: false,
        }
        break

      case GET_KANBAN_TASKS_LIST_ERROR:
        state = { 
          ...state, 
          GET_KANBAN_TASKS_LIST_ERROR: action.payload, 
          loading: false 
        }
        break
        
      // ---------------------------------------------CREATE_KANBAN_TASK
      case CREATE_KANBAN_TASK:
        state = {
          ...state,
          loading: true,
        }
        break

      case CREATE_KANBAN_TASK_SUCCESS:
        state = {
          ...state,
          CREATE_KANBAN_TASK_SUCCESS: action.payload,
          loading: false,
        }
        break

      case CREATE_KANBAN_TASK_ERROR:
        state = { 
          ...state, 
          CREATE_KANBAN_TASK_ERROR: action.payload, 
          loading: false 
        }
        break

      // ---------------------------------------------UPDATE_KANBAN_TASK
      case UPDATE_KANBAN_TASK:
        state = {
          ...state,
          loading: true,
        }
        break

      case UPDATE_KANBAN_TASK_SUCCESS:
        state = {
          ...state,
          UPDATE_KANBAN_TASK_SUCCESS: action.payload,
          loading: false,
        }
        break

      case UPDATE_KANBAN_TASK_ERROR:
        state = { 
          ...state, 
          UPDATE_KANBAN_TASK_ERROR: action.payload, 
          loading: false 
        }
        break

      // ---------------------------------------------DELETE_KANBAN_TASK
      case DELETE_KANBAN_TASK:
        state = {
          ...state,
          loading: true,
        }
        break

      case DELETE_KANBAN_TASK_SUCCESS:
        state = {
          ...state,
          DELETE_KANBAN_TASK_SUCCESS: action.payload,
          loading: false,
        }
        break

      case DELETE_KANBAN_TASK_ERROR:
        state = { 
          ...state, 
          DELETE_KANBAN_TASK_ERROR: action.payload, 
          loading: false 
        }
        break

       // ---------------------------------------------GET_KANBAN_TASK
      case GET_KANBAN_SEARCH:
        state = {
          ...state,
          loading: true,
        }
        break

      case GET_KANBAN_SEARCH_SUCCESS:
        state = {
          ...state,
          GET_KANBAN_SEARCH_SUCCESS: action.payload,
          loading: false,
        }
        break

      case GET_KANBAN_SEARCH_ERROR:
        state = { 
          ...state, 
          GET_KANBAN_SEARCH_ERROR: action.payload, 
          loading: false 
        }
        break

         // ---------------------------------------------GET_TASK_ALLOCATION
      case GET_TASK_ALLOCATION:
        state = {
          ...state,
          loading: true,
        }
        break

      case GET_TASK_ALLOCATION_SUCCESS:
        state = {
          ...state,
          GET_TASK_ALLOCATION_SUCCESS: action.payload,
          loading: false,
        }
        break

      case GET_TASK_ALLOCATION_ERROR:
        state = { 
          ...state, 
          GET_TASK_ALLOCATION_ERROR: action.payload, 
          loading: false 
        }
        break

      // ---------------------------------------------GET_TASK_ALLOCATION_LIST
      case GET_TASK_ALLOCATION_LIST:
        state = {
          ...state,
          loading: true,
        }
        break

      case GET_TASK_ALLOCATION_LIST_SUCCESS:
        state = {
          ...state,
          GET_TASK_ALLOCATION_LIST_SUCCESS: action.payload,
          loading: false,
        }
        break

      case GET_TASK_ALLOCATION_LIST_ERROR:
        state = { 
          ...state, 
          GET_TASK_ALLOCATION_LIST_ERROR: action.payload, 
          loading: false 
        }
        break

      // ---------------------------------------------DEFAULT
      default:
        state = { ...state }
        break
    }
    return state
  }
  
  export default reducer
  