import React, { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import Select from "react-select";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Form,
  Label,
  Input,
  // Button,
  FormFeedback,
  Spinner,
} from "reactstrap";
import Button from "../../components/Button/Button";
import Flatpickr from "react-flatpickr";
import labelPlugin from "flatpickr/dist/plugins/labelPlugin/labelPlugin";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useTranslation, Trans } from "react-i18next";

import MultiComments from "../../components/Common/MultiComments";
import { TimeSheetsAction, CommonAction } from "../../store/actions";
import commonService from "../../services/common.service";
import Breadcrumbs from "../../components/Layout/Breadcrumb";
import {
  getOptionById,
  filteredOptions,
  addIndividualInCompany,
  getUserProfile,
  filterFeesTypeByLanguage,
  getDependentUserOptions_New,
  datePickerI18,
  handleAutoSave,
} from "../../helpers/functions_helper";

import ScrollUp from "../../components/ScrollUpDown/ScrollUp";
import ScrollDown from "../../components/ScrollUpDown/ScrollDown";
import timeSheetsService from "../../services/time.sheets.service";

import { useCallbackPrompt } from '../../hooks/useCallbackPrompt'
import Alert from "../../components/Common/Alert";
import NavigationAlert from "../../components/Common/NavigationAlert";

const NewTimeSheet = (props: any) => {
  const { t } = useTranslation();

  document.title = `${t("Create")} ${t("time sheet")} | ` + process.env.REACT_APP_NAME;
  const userProfile = getUserProfile("all-time-sheets");

  const toastId = React.useRef(null) as any;
  const dispatch = useDispatch();
  let navigate = useNavigate();

  // const [searchParams, setSearchParams] = useSearchParams();
  // const filtersData: FiltersProps = JSON.parse(
  //   searchParams.get("filters") as any
  // );

  // --------------------------------------------------------------------
  const [loading, setLoading] = useState(false);
  const [insertId, setInsertId] = useState(0);
  const [insertSubId, setInsertSubId] = useState("");
  const [redirectCode, setRedirectCode] = useState("");
  const [multiCommentsStatus, setMultiCommentsStatus] = useState(false);

  const multiCommentsSuccess = (isSuccess: boolean) => {
    if (isSuccess === true) {
      setMultiCommentsStatus(true);
    }
  };
  // --------------------------------------------------------------------

  const localFormData = handleAutoSave("GET");
  const [autoSave, setAutoSave] = useState(false);
  const [autoSaveAlert, setAutoSaveAlert] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(autoSave);

  const [initialData, setInitialData] = useState({
    id: "",
    time_sheet_id: "",
    code: "",
    module_id: "4",
    module_main_id: "",
    module_sub_id: "",
    main_id: "",
    task_subtask_id: "",
    custome_code: "",
    title: "",
    company: "",
    project: "",
    contact_person: "",
    responsible_person: "",
    fees_type: "",
    purchase_order_number: "",
    fees_amount: 0,
    expense: 0,
    // start_date: filtersData?.from_date
    //   ? filtersData?.from_date
    //   : moment().format("YYYY-MM-DD 00:00:00"),
    start_date: moment().format("YYYY-MM-DD 00:00:00"),
    start_time: "",
    end_time: "",
    time_spent: "",
    is_send_invoice: 0,
    is_sensitive: 0,
    submit_btn_code: "save_add_new",
  });

  const feesInitialData = {
    category: "",
    category_of_fees: "",
    fees_amount: 0,
    fees_code: "",
  };

  const [calcTimeSpentEnabled, setCalcTimeSpentEnabled] = useState(false);
  useEffect(() => {
    if (initialData.start_time == "" && initialData.end_time == "") {
      setCalcTimeSpentEnabled(false);
    } else {
      setCalcTimeSpentEnabled(true);

      let start_time = "00:00:00";
      let end_time = "00:00:00";
      if (initialData.start_time != "" && initialData.start_time != "NaN:NaN") {
        start_time = initialData.start_time;
      }
      if (initialData.end_time != "" && initialData.end_time != "NaN:NaN") {
        end_time = initialData.end_time;
      }

      let startTime = moment(start_time, "h:mm");
      let endTime = moment(end_time, "h:mm");

      let duration = moment.duration(endTime.diff(startTime));
      let hours = Math.trunc(duration.asHours());
      let minutes = Number(duration.asMinutes()) % 60;

      // handleChange("time_spent", hours + ":" + minutes);

      setInitialData((prevData: any) => ({
        ...prevData,
        time_spent: hours + ":" + minutes,
      }));
    }
  }, [initialData.start_time, initialData.end_time]);

  useEffect(() => {
    if (initialData.start_time != "" && initialData.start_time != null) {
      if (initialData.end_time == "" || initialData.end_time == null) {
        setInitialData((prevData: any) => ({
          ...prevData,
          end_time: initialData.start_time,
        }));
      }
    }
    if (initialData.end_time != "" && initialData.end_time != null) {
      if (initialData.start_time == "" || initialData.start_time == null) {
        setInitialData((prevData: any) => ({
          ...prevData,
          start_time: "00:00:00",
        }));
      }
    }
  }, [initialData.start_time, initialData.end_time]);

  const handleRedirect = (isCancel = false) => {
    setAutoSave(false);
    handleAutoSave("CLEAR");

    if (isCancel === true) {
      return navigate("/time-sheets/all-time-sheets");
    }

    if (redirectCode === "save_add_new") {
      // return navigate("/time-sheets/new-time-sheet?redirect=true");
      updateInitialData();

      setInsertId(0);
      setInsertSubId("");
      setMultiCommentsStatus(false);

      window.scrollTo(0, 0);
    } else if (redirectCode === "save_close") {
      return navigate("/time-sheets/all-time-sheets?redirect=true");
    } else if (redirectCode === "save") {
      return navigate("/time-sheets/edit-time-sheet/" + insertId + "/" + insertSubId + "?redirect=true");
    } else {
      return navigate("/time-sheets/all-time-sheets?redirect=true");
    }
  };

  const handleChange = (fieldName: string, fieldValue: string) => {
    setInitialData({ ...initialData, [fieldName]: fieldValue });
    setAutoSave(true);
  };

  let { getCompanyOptionsList, getFeesOptionsList } = useSelector((state: any) => ({
    getCompanyOptionsList: state.Common.GET_COMPANY_OPTIONS_LIST_SUCCESS_NEW,
    getFeesOptionsList: state.Common.GET_FEES_OPTIONS_LIST_SUCCESS_NEW,
  }));

  useEffect(() => {
    if (redirectCode !== "" && multiCommentsStatus === true) {
      handleRedirect();
    }
  }, [redirectCode, multiCommentsStatus]);

  Yup.setLocale({
    mixed: {
      required: "${label} " + t("field is required."),
      notOneOf: "${label} " + t("can not be same."),
    },
    string: {
      min: "${label} " + t("must be at least") + " ${min} " + t("characters."),
      max: "${label} " + t("may not be greater than") + " ${max} " + t("characters."),
    },
  });
  const validationSchema = Yup.object().shape({
    custome_code: Yup.string().label(t("Time sheet ID")).required().min(3).max(15),
    title: Yup.string().label(t("Timesheet description")).required().min(2).max(200),
    company: Yup.string().label(t("Client")).required(),
    contact_person: Yup.string().label(t("Contact person")).required(),
    fees_type: Yup.string().label(t("Task type")).required(),
    fees_amount: Yup.number().label(t("Task amount")).required(),
    expense: Yup.number().label(t("Expense")).required(),
    start_date: Yup.string().label(t("Start date")).required(),
    start_time: Yup.string().label(t("Start time")).nullable(),
    end_time: Yup.string()
      .label(t("End time"))
      .nullable()
      .when(["start_time"], {
        is: (start_time: string) => start_time != "" && start_time != null,
        then: Yup.string()
          .label(t("End time"))
          .notOneOf([Yup.ref("start_time")])
          .required(),
      }),
    time_spent: Yup.string().label(t("Time spent")).required(),
  });

  type feesTypeProps = {
    category: string;
    category_of_fees: string;
    fees_amount: number;
    fees_code: string;
  };

  const create = async (values: timeSheetPropsType) => {
    try {
      setLoading(true);
      toastId.current = toast(`${t("Creating")} ${t("timesheet")}...`, {
        autoClose: false,
        isLoading: true,
      });

      const res = (await timeSheetsService.createTimeSheet(values)) as any;
      if (res.data.status === "success") {
        setInsertId(res.data.data.id);
        setInsertSubId(res.data.data.time_sheet_id);
        setRedirectCode(res.data.data.submit_btn_code);

        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoading(false);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        if (res.data.data) {
          Object.keys(res.data.data).map((key) => {
            toast(res.data.data[key][0], {
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              delay: 500,
            });
          });
        }
        setLoading(false);
      }
    } catch (err: any) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      create(values);
    },
  });

  useEffect(() => {
    if (!validation.isSubmitting) return;
    if (Object.keys(validation.errors).length > 0) {
      document
        .getElementById(Object.keys(validation.errors)[0])
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
      document.getElementById(Object.keys(validation.errors)[0])?.focus({ preventScroll: true });
    }
  }, [validation.isSubmitting, validation.errors]);

  function updateInitialData() {
    const updatedInitialData = {
      ...initialData,
      id: "",
      time_sheet_id: "",
      code: "",
      module_id: "4",
      module_main_id: "",
      module_sub_id: "",
      main_id: "",
      task_subtask_id: "",
      custome_code: "",
      title: "",
      company: "",
      project: "",
      contact_person: "",
      responsible_person: "",
      fees_type: "",
      purchase_order_number: "",
      fees_amount: 0,
      expense: 0,
      start_date: moment().format("YYYY-MM-DD hh:mm:ss"),
      start_time: "",
      end_time: "",
      time_spent: "",
      is_send_invoice: 0,
      is_sensitive: 0,
      submit_btn_code: "save_add_new",
    };
    setInitialData(updatedInitialData);
    setFeesData(feesInitialData);
    setCompanyID(null);
    setLoadingCode(true);
    window.scrollTo(0, 0);
  }

  // Generate custome code API call -------------------------------------------------
  const [loadingCode, setLoadingCode] = useState(true);
  const getCustomeCode = async (tableName: string, moduleCode: string) => {
    const response = await commonService.getCustomeCode(tableName, moduleCode);
    return response.data.data;
  };
  useEffect(() => {
    if (loadingCode === true) {
      getCustomeCode("tbl_time_sheet", "TMS").then(function (result) {
        const updatedInitialData = {
          ...initialData,
          code: result,
          custome_code: result,
        };
        setInitialData(updatedInitialData);
        setLoadingCode(false);
      });
    }
  }, [loadingCode]);

  // Select options list API call -------------------------------------------------
  const [query, setQuery] = useState("");
  const getDependentSelectOptions = async (
    tableName: string,
    fieldName: string,
    whereField: string,
    whereID: number
  ) => {
    const response = await commonService.getDependentSelectOptionsList(
      tableName,
      fieldName,
      whereField,
      whereID,
      query ? query : ""
    );
    return response.data.data;
  };

  const [loadingCompany, setLoadingCompany] = useState(true);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyID, setCompanyID] = useState<any>(null);
  const [companyLanguage, setCompanyLanguage] = useState<any>(null);
  useEffect(() => {
    if (getCompanyOptionsList.data) {
      const item = addIndividualInCompany(getCompanyOptionsList.data) as any;
      setCompanyOptions(item);
      setLoadingCompany(false);
    }
  }, [getCompanyOptionsList]);

  const [loadingContactPerson, setLoadingContactPerson] = useState(false);
  const [disabledContactPerson, setDisabledContactPerson] = useState(true);
  const [contactPersonOption, setContactPersonOption] = useState([]);

  const [loadingResponsiblePerson, setLoadingResponsiblePerson] = useState(false);
  const [disabledResponsiblePerson, setDisabledResponsiblePerson] = useState(true);
  const [responsiblePersonOptions, setResponsiblePersonOptions] = useState([]);

  const [loadingProjectOption, setLoadingProjectOption] = useState(false);
  const [disabledProjectOption, setDisabledProjectOption] = useState(true);
  const [projectOption, setProjectOption] = useState([]);

  useEffect(() => {
    setProjectOption([]);
    setDisabledProjectOption(true);

    setContactPersonOption([]);
    setDisabledContactPerson(true);

    setResponsiblePersonOptions([]);
    setDisabledResponsiblePerson(true);

    if (companyOptions.length > 0 && companyID) {
      const companyData = companyOptions.find((option: any) => option.value == companyID) as any;
      if (companyData) {
        setCompanyLanguage(
          companyData?.language === 1
            ? "EN"
            : companyData?.language === 2
              ? "FR"
              : companyData?.language === 3
                ? "NL"
                : null
        );
      }

      initialData.project = "";
      setLoadingProjectOption(true);
      getDependentSelectOptions("tbl_projects", "name", "company_id", companyID).then(function (
        options
      ) {
        setProjectOption(options);
        setLoadingProjectOption(false);
        setDisabledProjectOption(false);
      });

      initialData.contact_person = "";
      setLoadingContactPerson(true);
      initialData.responsible_person = "";
      setLoadingResponsiblePerson(true);

      getDependentUserOptions_New("tbl_master_users", "first_name", "company", companyID).then(
        function (options) {
          if (companyID == 0) {
            setInitialData((preState) => ({
              ...preState,
              contact_person: "",
              responsible_person: "",
            }));
            setContactPersonOption(options);
            setLoadingContactPerson(false);
            setDisabledContactPerson(false);

            setResponsiblePersonOptions([]);
            setLoadingResponsiblePerson(false);
            setDisabledResponsiblePerson(true);
          } else {
            const item = getOptionById(companyOptions, companyID) as any;
            let contact_persons = filteredOptions(options as any, [
              item.contact_person && item.contact_person,
              item.invoice_contact_person && item.invoice_contact_person,
              item.hr_contact_person && item.hr_contact_person,
            ]) as any;
            setInitialData((preState) => ({
              ...preState,
              contact_person:
                item.hr_contact_person == 0
                  ? ""
                  : item.hr_contact_person === null
                    ? ""
                    : item.hr_contact_person,
              responsible_person: "",
            }));
            contact_persons.push({ value: "", label: t("-- Remove --") });
            setContactPersonOption(contact_persons);
            setLoadingContactPerson(false);
            setDisabledContactPerson(false);

            setResponsiblePersonOptions(options);
            setLoadingResponsiblePerson(false);
            setDisabledResponsiblePerson(false);
          }
        }
      );
    }
  }, [companyOptions, companyID]);

  const [contactPersonLanguage, setContactPersonLanguage] = useState<any>(null);
  useEffect(() => {
    if (parseInt(companyID) === 0 && initialData.contact_person) {
      const contactPersonData = contactPersonOption.find(
        (option: any) => option.value == initialData.contact_person
      ) as any;
      if (contactPersonData) {
        setContactPersonLanguage(
          contactPersonData?.language === 1
            ? "EN"
            : contactPersonData?.language === 2
              ? "FR"
              : contactPersonData?.language === 3
                ? "NL"
                : null
        );
      }
    } else {
      setContactPersonLanguage(null);
    }
  }, [companyID, initialData.contact_person]);

  const [loadingFeesTypeOptions, setLoadingFeesTypeOptions] = useState(true);
  const [disabledFeesTypeOptions, setDisabledFeesTypeOptions] = useState(true);
  const [feesTypeOptions, setFeesTypeOptions] = useState([]);
  const [feesTypeID, setFeesTypeID] = useState<number>(0);
  useEffect(() => {
    if (getFeesOptionsList.data) {
      let feesOptionsList = filterFeesTypeByLanguage(getFeesOptionsList.data);
      feesOptionsList.push({ value: "", label: t("-- Remove --") });
      setFeesTypeOptions(feesOptionsList);
      setLoadingFeesTypeOptions(false);
      setDisabledFeesTypeOptions(false);
    }
  }, [getFeesOptionsList, localStorage.getItem("I18N_LANGUAGE")]);

  const [feesData, setFeesData] = useState<feesTypeProps>(feesInitialData);
  useEffect(() => {
    if (feesTypeID > 0) {
      if (getFeesOptionsList.data) {
        let feesOptionsList = filterFeesTypeByLanguage(getFeesOptionsList.data);

        const item = getOptionById(feesOptionsList, feesTypeID) as feesTypeProps | any;
        setFeesData(item);
        // handleChange("fees_amount", item.fees_amount);

        setInitialData((prevData: any) => ({
          ...prevData,
          fees_amount: item.fees_amount,
        }));
      }
    }
  }, [feesTypeID, getFeesOptionsList, localStorage.getItem("I18N_LANGUAGE")]);

  const [loadingTaskSubtaskOption, setLoadingTaskSubtaskOption] = useState(false);
  const [disabledTaskSubtaskOption, setDisabledTaskSubtaskOption] = useState(true);
  const [taskSubtaskOption, setTaskSubtaskOption] = useState([]);

  const getTaskSubtask = async (data: any) => {
    try {
      setLoadingTaskSubtaskOption(true);
      setDisabledTaskSubtaskOption(true);
      const res = (await commonService.getTaskSubtask(data)) as any;
      if (res.data.status === "success") {
        setTaskSubtaskOption(res.data.data);

        setLoadingTaskSubtaskOption(false);
        setDisabledTaskSubtaskOption(false);
      } else {
        setTaskSubtaskOption([]);
        setLoadingTaskSubtaskOption(false);
        setDisabledTaskSubtaskOption(true);
      }
    } catch (err: any) {
      setTaskSubtaskOption([]);
      setLoadingTaskSubtaskOption(false);
      setDisabledTaskSubtaskOption(true);
    }
  };

  useEffect(() => {
    if (initialData.company != "") {
      getTaskSubtask({
        company: initialData.company,
        project: initialData.project,
        contact_person: initialData.contact_person,
        responsible_person: initialData.responsible_person,
      });
    } else {
      setTaskSubtaskOption([]);
      setLoadingTaskSubtaskOption(false);
      setDisabledTaskSubtaskOption(true);
    }
  }, [
    initialData.company,
    initialData.project,
    initialData.contact_person,
    initialData.responsible_person,
  ]);

  useEffect(() => {
    if (initialData.task_subtask_id !== "") {
      const item = getOptionById(taskSubtaskOption, initialData.task_subtask_id) as any;
      setInitialData((prevData: any) => ({
        ...prevData,
        module_id: item?.module_id,
        module_main_id: item?.module_main_id,
        module_sub_id: item?.module_sub_id,
        main_id: item?.main_id,
        fees_type: item?.type,
      }));

      setFeesTypeID(item?.type);
      setDisabledFeesTypeOptions(true);
    } else {
      setInitialData((prevData: any) => ({
        ...prevData,
        module_id: "4",
        module_main_id: "",
        module_sub_id: "",
        main_id: "",
      }));

      setDisabledFeesTypeOptions(false);
    }
  }, [initialData.task_subtask_id]);

  useEffect(() => {
    if (localFormData && localFormData?.initialData) {
      setInitialData(localFormData?.initialData);
      setAutoSaveAlert(true);
    }
  }, []);

  useEffect(() => {
    if (autoSave) { handleAutoSave("SET", initialData); }
  }, [initialData, autoSave]);

  return (
    <React.Fragment>
      <ScrollUp />
      <ScrollDown top={"100px"} />

      <Alert
        open={autoSaveAlert}
        title={t("Autosaved data restored!")}
        message={t("Your last autosaved data has been restored from the session. In order to avoid the data loss, you need either save it to retain the data or Cancel to loose the data.")}
        onClickOk={() => setAutoSaveAlert(false)}
      />
      <NavigationAlert
        open={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={t("Create time sheet")}
            breadcrumbItems={[
              {
                url: "/dashboard",
                label: t("Dashboard"),
                active: false,
              },
              {
                url: "/time-sheets/all-time-sheets",
                label: t("Time sheets"),
                active: false,
              },
              {
                url: "#",
                label: t("Create time sheet"),
                active: true,
              },
            ]}
          />

          <Row>
            <Col xl="12">
              <Card>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                  autoComplete="off"
                >
                  <Input id="id" name="id" type="hidden" />
                  <Input id="time_sheet_id" name="time_sheet_id" type="hidden" />
                  <Input id="code" name="code" type="hidden" value={initialData.code} />

                  <CardBody>
                    <Row>
                      <Input
                        id="module_id"
                        name="module_id"
                        type="hidden"
                        value={initialData.module_id}
                      />

                      <Input
                        id="module_main_id"
                        name="module_main_id"
                        type="hidden"
                        value={initialData.module_main_id}
                      />

                      <Input
                        id="module_sub_id"
                        name="module_sub_id"
                        type="hidden"
                        value={initialData.module_sub_id}
                      />

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Client")}{" "}
                            {companyLanguage && (
                              <span
                                className="badge rounded-pill bg-primary"
                                title={t("Client's language")}
                              >
                                {companyLanguage}
                              </span>
                            )}
                            <span className="text-warning is_required">*</span>
                          </Label>
                          <Select
                            id="company"
                            name="company"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={companyOptions}
                            value={
                              initialData.company
                                ? companyOptions.find(
                                  (option: any) => option.value == initialData.company
                                )
                                : ""
                            }
                            isSearchable={true}
                            // isClearable={true}
                            isDisabled={loadingCompany}
                            onBlur={() => validation.setFieldTouched("company", true)}
                            onChange={(e: any) => {
                              handleChange("company", e.value);
                              setCompanyID(e.value);
                            }}
                          />
                          {validation.touched.company && validation.errors.company ? (
                            <FormFeedback type="invalid">{validation.errors.company}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">{t("Project")}</Label>
                          <Select
                            id="project"
                            name="project"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={projectOption}
                            value={
                              initialData.project
                                ? projectOption.find(
                                  (option: any) => option.value == initialData.project
                                )
                                : ""
                            }
                            isSearchable={true}
                            isLoading={loadingProjectOption}
                            isDisabled={disabledProjectOption}
                            onBlur={() => validation.setFieldTouched("project", true)}
                            onChange={(e: any) => {
                              // handleChange("project", e.value);
                              // handleChange("purchase_order_number", e.po_number);
                              setInitialData((state: any) => ({ ...state, project: e.value, purchase_order_number: e.po_number }))
                            }}
                          />
                          {validation.touched.project && validation.errors.project ? (
                            <FormFeedback type="invalid">{validation.errors.project}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">{t("Purchase order number")}</Label>
                          <Input
                            id="purchase_order_number"
                            name="purchase_order_number"
                            placeholder={t("Purchase order number")}
                            type="text"
                            maxLength={50}
                            value={initialData.purchase_order_number}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("purchase_order_number", e.target.value)}
                          />
                        </div>
                      </Col>

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Contact person")}{" "}
                            {contactPersonLanguage && (
                              <span
                                className="badge rounded-pill bg-primary"
                                title="Contact person's language"
                              >
                                {contactPersonLanguage}
                              </span>
                            )}
                            <span className="text-warning is_required">*</span>
                          </Label>
                          <Select
                            id="contact_person"
                            name="contact_person"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={contactPersonOption}
                            value={
                              initialData.contact_person
                                ? contactPersonOption.find(
                                  (option: any) => option.value == initialData.contact_person
                                )
                                : ""
                            }
                            isSearchable={true}
                            // isClearable={true}
                            isLoading={loadingContactPerson}
                            isDisabled={disabledContactPerson}
                            onBlur={() => validation.setFieldTouched("contact_person", true)}
                            onChange={(e: any) => {
                              handleChange("contact_person", e.value);
                            }}
                          />
                          {validation.touched.contact_person && validation.errors.contact_person ? (
                            <FormFeedback type="invalid">
                              {validation.errors.contact_person}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">{t("Person concerned")}</Label>
                          <Select
                            id="responsible_person"
                            name="responsible_person"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={responsiblePersonOptions}
                            value={
                              initialData.responsible_person
                                ? responsiblePersonOptions.find(
                                  (option: any) => option.value == initialData.responsible_person
                                )
                                : ""
                            }
                            isSearchable={true}
                            // isClearable={true}
                            isLoading={loadingResponsiblePerson}
                            isDisabled={disabledResponsiblePerson}
                            onBlur={() => validation.setFieldTouched("responsible_person", true)}
                            onChange={(e: any) => {
                              handleChange("responsible_person", e.value);
                            }}
                          />
                          {validation.touched.responsible_person &&
                            validation.errors.responsible_person ? (
                            <FormFeedback type="invalid">
                              {validation.errors.responsible_person}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col xl={9} lg={9} md={9} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">{`${t("Task")} / ${t("Subtask")} ${t(
                            "title"
                          )}`}</Label>
                          <Select
                            id="task_subtask_id"
                            name="task_subtask_id"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={taskSubtaskOption}
                            value={
                              initialData.task_subtask_id
                                ? taskSubtaskOption.find(
                                  (option: any) => option.value == initialData.task_subtask_id
                                )
                                : ""
                            }
                            isSearchable={true}
                            isClearable={true}
                            isLoading={loadingTaskSubtaskOption}
                            isDisabled={disabledTaskSubtaskOption}
                            onBlur={() => validation.setFieldTouched("task_subtask_id", true)}
                            onChange={(e: any) => {
                              if (e) {
                                handleChange("task_subtask_id", e.value);
                              } else {
                                handleChange("task_subtask_id", "");
                              }
                            }}
                          />
                        </div>
                      </Col>

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <Col xl={12} lg={12} md={12} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Task type")} <span className="text-warning is_required">*</span>
                          </Label>
                          <Select
                            id="fees_type"
                            name="fees_type"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={feesTypeOptions}
                            value={
                              initialData.fees_type
                                ? feesTypeOptions.find(
                                  (option: any) => option.value == initialData.fees_type
                                )
                                : ""
                            }
                            isSearchable={true}
                            isLoading={loadingFeesTypeOptions}
                            isDisabled={disabledFeesTypeOptions}
                            onBlur={() => validation.setFieldTouched("fees_type", true)}
                            onChange={(e: any) => {
                              handleChange("fees_type", e.value);
                              setFeesTypeID(e.value);
                            }}
                          />
                          {validation.touched.fees_type && validation.errors.fees_type ? (
                            <FormFeedback type="invalid">
                              {validation.errors.fees_type}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">{t("Category of task")}</Label>
                          <Input
                            placeholder={t("Category of task")}
                            type="text"
                            value={feesData.category_of_fees}
                            readOnly
                          />
                        </div>
                      </Col>

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">{t("Task code")}</Label>
                          <Input
                            placeholder={t("Task code")}
                            type="text"
                            value={feesData.fees_code}
                            readOnly
                          />
                        </div>
                      </Col>

                      {userProfile.IS_PARTNER ? (
                        <Col xl={3} lg={3} md={3} sm={12}>
                          <div className="mb-3">
                            <Label className="form-label">{t("Task amount")}</Label>
                            <Input
                              id="fees_amount"
                              name="fees_amount"
                              placeholder={t("Task amount")}
                              type="number"
                              min={0}
                              value={initialData.fees_amount}
                              onBlur={validation.handleBlur}
                              onChange={(e) => {
                                handleChange("fees_amount", e.target.value);
                              }}
                            />
                          </div>
                        </Col>
                      ) : null}

                      {/* <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Task category")}
                          </Label>
                          <Input
                            placeholder={t("Task category")}
                            type="text"
                            value={feesData.category}
                            readOnly
                          />
                        </div>
                      </Col>

                      <Col className="col-12">
                        <hr></hr>
                      </Col> */}

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">{t("Expense")}</Label>
                          <Input
                            id="expense"
                            name="expense"
                            placeholder={t("Expense")}
                            type="number"
                            min={0}
                            value={initialData.expense}
                            onBlur={validation.handleBlur}
                            onChange={(e) => {
                              handleChange("expense", e.target.value);
                            }}
                          />
                        </div>
                      </Col>

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Time sheet ID")} <span className="text-warning is_required">*</span>
                          </Label>
                          <Input
                            id="custome_code"
                            name="custome_code"
                            placeholder={t("Time sheet ID")}
                            type="text"
                            value={initialData.custome_code}
                            readOnly={loadingCode}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("custome_code", e.target.value)}
                            invalid={
                              validation.touched.custome_code && validation.errors.custome_code
                                ? true
                                : false
                            }
                          />
                          {loadingCode ? <Spinner /> : ""}
                          {validation.touched.custome_code && validation.errors.custome_code ? (
                            <FormFeedback type="invalid">
                              {validation.errors.custome_code}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col xl={9} lg={9} md={9} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Timesheet description")}{" "}
                            <span className="text-warning is_required">*</span>
                          </Label>
                          <Input
                            id="title"
                            name="title"
                            placeholder={t("Timesheet description")}
                            type="text"
                            // autoFocus={true}
                            value={initialData.title}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("title", e.target.value)}
                            invalid={
                              validation.touched.title && validation.errors.title ? true : false
                            }
                          />
                          {validation.touched.title && validation.errors.title ? (
                            <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <Col xl={4} lg={4} md={4} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Start date")} <span className="text-warning is_required">*</span>
                          </Label>
                          <div className="input-group is-invalid">
                            <Flatpickr
                              id="start_date"
                              name="start_date"
                              className="form-control d-block"
                              type="text"
                              value={initialData.start_date}
                              placeholder="DD/MM/YYYY"
                              options={{
                                altInput: true,
                                altFormat: "d/m/Y",
                                dateFormat: "Y-m-d H:i:S",
                                maxDate: new Date(),
                                allowInput: true,
                                plugins: [labelPlugin()],
                                onChange: function (selectedDates, dateStr, instance) {
                                  handleChange("start_date", dateStr);
                                },
                                onClose: function (selectedDates, dateStr, instance) {
                                  handleChange("start_date", dateStr);
                                },
                                locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                              }}
                            />
                            <span className="input-group-text">
                              <span className="align-middle material-symbols-outlined fw-light fs-4">
                                calendar_today
                              </span>
                            </span>
                          </div>
                          {validation.touched.start_date && validation.errors.start_date ? (
                            <FormFeedback type="invalid">
                              {validation.errors.start_date}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-12"></Col>

                      <Col xl={4} lg={4} md={4} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">{t("Start time")}</Label>
                          <div className="input-group is-invalid">
                            <Flatpickr
                              id="start_time"
                              name="start_time"
                              className="form-control d-block"
                              type="text"
                              value={initialData.start_time}
                              placeholder="HH:MM"
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: "H:i:S",
                                time_24hr: true,
                                allowInput: true,
                                plugins: [labelPlugin()],
                                maxTime: initialData.end_time,
                                defaultHour: 8,
                                onChange: function (selectedDates, dateStr, instance) {
                                  handleChange("start_time", dateStr);
                                },
                                onClose: function (selectedDates, dateStr, instance) {
                                  handleChange("start_time", dateStr);
                                },
                                locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                              }}
                            />
                            <span className="input-group-text">
                              <span className="align-middle material-symbols-outlined fw-light fs-4">
                                schedule
                              </span>
                            </span>
                          </div>
                          {validation.touched.start_time && validation.errors.start_time ? (
                            <FormFeedback type="invalid">
                              {validation.errors.start_time}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col xl={4} lg={4} md={4} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">{t("End time")}</Label>
                          <div className="input-group is-invalid">
                            <Flatpickr
                              id="end_time"
                              name="end_time"
                              className="form-control d-block"
                              type="text"
                              value={initialData.end_time}
                              placeholder="HH:MM"
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: "H:i:S",
                                time_24hr: true,
                                allowInput: true,
                                plugins: [labelPlugin()],
                                minTime: initialData.start_time,
                                defaultHour: 8,
                                onChange: function (selectedDates, dateStr, instance) {
                                  handleChange("end_time", dateStr);
                                },
                                onClose: function (selectedDates, dateStr, instance) {
                                  handleChange("end_time", dateStr);
                                },
                                locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                              }}
                            />
                            <span className="input-group-text">
                              <span className="align-middle material-symbols-outlined fw-light fs-4">
                                schedule
                              </span>
                            </span>
                          </div>
                          {validation.touched.end_time && validation.errors.end_time ? (
                            <FormFeedback type="invalid">{validation.errors.end_time}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col xl={4} lg={4} md={4} sm={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Time spent")} <span className="text-warning is_required">*</span>
                          </Label>
                          <div className="input-group is-invalid">
                            <Flatpickr
                              id="time_spent"
                              name="time_spent"
                              className="form-control d-block"
                              type="text"
                              value={initialData.time_spent}
                              placeholder="HH:MM"
                              disabled={calcTimeSpentEnabled}
                              options={{
                                enableTime: true,
                                noCalendar: true,
                                dateFormat: "H:i:S",
                                time_24hr: true,
                                allowInput: true,
                                plugins: [labelPlugin()],
                                defaultHour: 0,
                                defaultMinute: 0,
                                onChange: function (selectedDates, dateStr, instance) {
                                  handleChange("time_spent", dateStr);
                                },
                                onClose: function (selectedDates, dateStr, instance) {
                                  handleChange("time_spent", dateStr);
                                },
                                locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                              }}
                            />
                            <span className="input-group-text">
                              <span className="align-middle material-symbols-outlined fw-light fs-4">
                                schedule
                              </span>
                            </span>
                          </div>
                          {validation.touched.time_spent && validation.errors.time_spent ? (
                            <FormFeedback type="invalid">
                              {validation.errors.time_spent}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <Col className="col-12">
                        <div className="mb-0">
                          <Label className="form-label">{t("Comments")}</Label>
                          <MultiComments
                            moduleData={{
                              module_app_id: 4,
                              module_main_id: 0,
                              module_sub_id: "",
                              section_id: 0,
                            }}
                            commentForIDs={
                              [
                                // {
                                //   value: initialData.contact_person,
                                //   label: t("Contact person"),
                                // },
                                // {
                                //   value: initialData.responsible_person,
                                //   label: t("Person concerned"),
                                // },
                              ]
                            }
                            fetchData={false}
                            isOpen={true}
                            insertId={insertId}
                            insertSubId={insertSubId}
                            onSuccess={multiCommentsSuccess}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>

                  {userProfile.IS_INTERNAL && (
                    <CardFooter className="d-sm-flex flex-wrap bg-transparent border-top">
                      <div className="d-flex gap-2"></div>

                      <div className="ms-auto d-flex gap-2 justify-content-end">
                        {userProfile.CAN_CREATE && userProfile.IS_INTERNAL ? (
                          <>
                            <Button
                              color="primary"
                              className="btn-block"
                              type="submit"
                              onClick={() => handleChange("submit_btn_code", "save_add_new")}
                              disabled={loading}
                              loading={loading}
                            >
                              {t("Save & Add new")}
                            </Button>

                            <Button
                              color="primary"
                              className="btn-block"
                              type="submit"
                              onClick={() => handleChange("submit_btn_code", "save_close")}
                              disabled={loading}
                              loading={loading}
                            >
                              {t("Save & Close")}
                            </Button>

                            <Button
                              color="primary"
                              className="btn-block"
                              type="submit"
                              onClick={() => handleChange("submit_btn_code", "save")}
                              disabled={loading}
                              loading={loading}
                            >
                              {t("Save")}
                            </Button>

                            <Button
                              color="danger"
                              className="btn-block"
                              type="reset"
                              onClick={() => {
                                handleRedirect(true);
                              }}
                              disabled={loading}
                              loading={loading}
                            >
                              {t("Cancel")}
                            </Button>
                          </>
                        ) : null}
                      </div>
                    </CardFooter>
                  )}
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NewTimeSheet;
