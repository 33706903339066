import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardFooter,
  Form,
  Input,
  Button,
  CardHeader,
  CardTitle,
  CardBody,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import userGeneralService from "../../../services/users.general.service";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import BanksData from "./BanksData";
import TaxClaim from "./TaxClaim";
import FinalTaxLiability from "./FinalTaxLiability";

type GeneralType = {
  id: string;
  user_id: string;
  bank_data: any;
  tax_claim_data: any;
  saldo_data: any;
  submit_btn_code: string;
};

const UsersTaxes = (props: any) => {
  let navigate = useNavigate();
  const toastId = React.useRef(null) as any;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [redirectCode, setRedirectCode] = useState("");

  let { user_id } = props;

  const [intialData, setIntialData] = useState<GeneralType>({
    id: "",
    user_id: user_id || "",
    bank_data: [],
    tax_claim_data: [],
    saldo_data: [],
    submit_btn_code: "update",
  });
  const [bankData, setBankData] = useState<any>([]);
  const [taxClaimData, setTaxClaimData] = useState<any>([]);
  const [saldoData, setSaldoData] = useState<any>([]);

  const handleChange = (fieldName: string, fieldValue: any) => {
    setIntialData((preState) => ({ ...preState, [fieldName]: fieldValue }));
  };

  const handleRedirect = () => {
    if (redirectCode === "update_close") {
      return navigate("/users/all-users");
    } else if (redirectCode === "update") {
      window.scrollTo(0, 0);
    } else {
      return navigate("/users/all-users");
    }
  };
  useEffect(() => {
    if (redirectCode !== "") {
      handleRedirect();
    }
  }, [redirectCode]);

  const validationSchema = Yup.object().shape({});

  const handleUsersTaxes = async (values: GeneralType) => {
    toast.dismiss();
    toastId.current = toast(t("Updating Users taxes..."), {
      autoClose: false,
      isLoading: true,
    });

    try {
      const res = (await userGeneralService.updateUsersTaxes(values)) as any;
      if (res?.data?.status === "success") {
        setRedirectCode(intialData.submit_btn_code);

        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        if (res.data.data) {
          Object.keys(res.data.data).map((key) => {
            toast(res.data.data[key][0], {
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              delay: 500,
            });
          });
        }
      }
    } catch (err: any) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
  };

  const updateInitialData = (data: GeneralType) => {
    setIntialData((preData: GeneralType) => ({
      ...preData,
      id: data?.id,
      user_id: data?.user_id || user_id,
      bank_data: (data?.bank_data && JSON.parse(data?.bank_data)) || [],
      tax_claim_data: (data?.tax_claim_data && JSON.parse(data?.tax_claim_data)) || [],
      saldo_data: (data?.saldo_data && JSON.parse(data?.saldo_data)) || [],
    }));

    data?.bank_data && setBankData(JSON.parse(data?.bank_data));
    data?.tax_claim_data && setTaxClaimData(JSON.parse(data?.tax_claim_data));
    data?.saldo_data && setSaldoData(JSON.parse(data?.saldo_data));
  };

  const get = async () => {
    setLoading(true);
    try {
      const res = (await userGeneralService.getUsersGeneral(user_id)) as any;
      if (res.data.status === "success") {
        updateInitialData(res.data.data[0]);
        setLoading(false);
      } else {
        toast.update(toastId.current, {
          render: res?.data?.message,
          type: toast.TYPE.ERROR,
          isLoading: false,
          autoClose: 5000,
        });
        setLoading(false);
        handleRedirect();
      }
    } catch (err: any) {
      toast.update(toastId.current, {
        render: err?.data?.message,
        type: toast.TYPE.ERROR,
        isLoading: false,
        autoClose: 5000,
      });
      setLoading(false);
      handleRedirect();
    }
  };

  useEffect(() => {
    get();
  }, []);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: intialData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleUsersTaxes(values);
    },
  });

  useEffect(() => {
    if (!validation.isSubmitting) return;
    if (Object.keys(validation.errors).length > 0) {
      document
        .getElementById(Object.keys(validation.errors)[0])
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
      document.getElementById(Object.keys(validation.errors)[0])?.focus({ preventScroll: true });
    }
  }, [validation.isSubmitting, validation.errors]);

  return (
    <React.Fragment>
      <Row>
        <Col xl="12">
          <Card className="border-top-0 rounded-0 rounded-bottom">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              autoComplete="off"
            >
              <Input id="id" name="id" type="hidden" value={intialData.id} />
              <Input id="user_id" name="user_id" type="hidden" value={intialData.user_id} />

              <CardHeader className="bg-transparent border-bottom border-primary pb-0">
                <CardTitle className="card-title text-uppercase">{t("Bank details")}</CardTitle>
              </CardHeader>

              <CardBody>
                <BanksData
                  data={bankData}
                  onChange={(data: any) => handleChange("bank_data", data)}
                />
              </CardBody>

              <CardHeader className="bg-transparent border-bottom border-primary pb-0">
                <CardTitle className="card-title text-uppercase">{t("Tax claim")}</CardTitle>
              </CardHeader>

              <CardBody>
                <TaxClaim
                  data={taxClaimData}
                  onChange={(data: any) => handleChange("tax_claim_data", data)}
                />
              </CardBody>

              <CardHeader className="bg-transparent border-bottom border-primary pb-0">
                <CardTitle className="card-title text-uppercase">{t("Final tax liability")}</CardTitle>
              </CardHeader>

              <CardBody>
                <FinalTaxLiability
                  data={saldoData}
                  onChange={(data: any) => handleChange("saldo_data", data)}
                />
              </CardBody>

              <CardFooter className="bg-transparent border-top">
                <div className="d-sm-flex flex-wrap ">
                  <div className="d-flex gap-2"></div>

                  <div className="ms-auto d-flex gap-2 justify-content-end">
                    <Button
                      color="primary"
                      className="btn-block"
                      type="submit"
                      onClick={() => handleChange("submit_btn_code", "update_close")}
                      disabled={loading}
                      loading={loading}
                    >
                      {t("Update & Close")}
                    </Button>

                    <Button
                      color="primary"
                      className="btn-block"
                      type="submit"
                      onClick={() => handleChange("submit_btn_code", "update")}
                      disabled={loading}
                      loading={loading}
                    >
                      {t("Update")}
                    </Button>

                    <Button
                      color="danger"
                      className="btn-block"
                      type="reset"
                      onClick={handleRedirect}
                      disabled={loading}
                      loading={loading}
                    >
                      {t("Cancel")}
                    </Button>
                  </div>
                </div>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UsersTaxes;
