import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, FormGroup, Row, Table } from 'reactstrap';
import { JsxElement } from 'typescript';
import { number } from 'yup';
import Button from '../../../components/Button/Button';
import Drawer from '../../../components/Drawer';
import Input from '../../../components/Form/Input';
import Select from '../../../components/Form/Select/Select';
import Breadcrumbs from '../../../components/Layout/Breadcrumb';
import { getUserProfile } from '../../../helpers/functions_helper';
import accessroleService from '../../../services/accessrole.service';
import commonService from '../../../services/common.service';
import AccessModulesService from '../../../services/masters.access.module.service';
import AccessRoleService from '../../../services/accessrole.service';
import { toast } from 'react-toastify';
import DeleteModal from '../../../components/Common/DeleteModal';
import AccessRoleForm from './Form';
import { subString } from '../../../utils/utils';
import KebabMenu from '../../../components/KebabMenu';

type AccessRoleProps = {

}

const AccessRole: FC<AccessRoleProps> = () => {

    document.title = "Access Role | " + process.env.REACT_APP_NAME;

    const { t } = useTranslation();
    const userProfile = getUserProfile('access-role');
    const toastId = React.useRef(null) as any;

    const options = [
        {
            'label': 'Tax Consult',
            'value': 1
        },
        {
            'label': 'Clients',
            'value': 2
        }
    ];

    const [accessRoles, setAccessRoles] = useState<Array<any>>([]);
    const [modules, setModules] = useState<Array<any>>([]);
    const [widgets, setWidgets] = useState<Array<any>>([]);
    const [selectedWidgets, setSelectedWidgets] = useState<Array<any>>([]);
    const [formValues, setFormValues] = useState<any>({ name: '', applicable_for: 0, vip_access: null, modules: [], widgets: [], isSubmitting: false });
    const [selectedRole, setSelectedRole] = useState<any>();
    const [searchKey, setSearchKey] = useState<any>({
        name: null,
        applicable_for: null
    });
    const [errors, setErrors] = useState<any>({
        name: null,
        applicable_for: null
    });
    const [touched, setTouched] = useState<any>({
        name: false,
        applicable_for: false
    });
    const [openWidget, setOpenWidget] = useState<boolean>(false);
    const [loadingList, setLoadingList] = useState<boolean>(true);
    const [isView, setView] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);

    const breadcrumbItems = [
        {
            url: "/dashboard",
            label: t("Dashboard"),
            active: false,
        },
        {
            url: "#",
            label: t("Access Role"),
            active: true,
        },
    ];

    const handleChange = (e: any) => {
        setFormValues((state: any) => ({ ...state, [e.target.name]: e.target.value }))
    }

    const handleOpenWidget = (widgets: any) => {
        setOpenWidget(true);
        setSelectedWidgets(widgets);
    }

    const handleSubmit = async (values: any) => {
        setTouched({
            name: true,
            applicable_for: true
        });
        if (values?.id) {
            toastId.current = toast("Updating access role...", { autoClose: false, isLoading: true });
            setFormValues((state: any) => ({ ...state, isSubmitting: true }));
            const res = await AccessRoleService.update(values);
            if (res?.status === 'success') {
                setLoadingList(true)
                setFormValues((state: any) => ({ ...state, isSubmitting: false }));
                toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: 'Access role updated successfully', autoClose: 5000, isLoading: false, delay: 500 });
            }
        } else {
            toastId.current = toast("Creating access role...", { autoClose: false, isLoading: true });
            setFormValues((state: any) => ({ ...state, isSubmitting: true }));
            const res = await AccessRoleService.create(values);
            if (res?.status === 'success') {
                setLoadingList(true)
                setFormValues((state: any) => ({ ...state, isSubmitting: false }));
                toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: 'Access role created successfully', autoClose: 5000, isLoading: false, delay: 500 });
            }
        }

    }

    const handleDelete = (data: any) => {
        // alert(data.id);
        setSelectedRole(data);
        setDeleteModal(true)
    }

    const handleEdit = (data: any) => {
        setView(false);
        setFormValues(data);
    }

    const handleView = (data: any) => {
        setView(true);
        setFormValues(data);
    }

    const deleteRole = async () => {
        const res = await AccessRoleService.delete(selectedRole?.id);
        if (res?.status === 'success') {
            setDeleteModal(false);
            setLoadingList(true)
        }
    }

    const getModules = async () => {
        const res = await AccessModulesService.getAllModules();
        if (res?.data?.status === 'success') {
            setModules(res?.data?.data);
        }
    }

    const getAccessRoles = async () => {
        const res = await AccessRoleService.getList();
        if (res?.status === 'success') {
            setAccessRoles(res?.data);
            setLoadingList(false)
        }
    }

    const getDashboardWidgets = async () => {
        const res = await commonService.getDashboardWidgets();
        if (res?.data?.status === 'success') {
            setWidgets(res?.data?.data);
        }
    }

    const handleHCreateRole = () => {
        setTouched({
            name: false,
            applicable_for: false
        });
        setView(false);
        let tmp: any = [];
        modules.forEach((a: any, i: number) => (
            tmp.push({
                module: a.slug,
                status: false,
                access: {
                    can_view: false,
                    can_create: false,
                    can_edit: false,
                    can_delete: false,
                    can_print: false,
                    can_export: false,
                },
            })
        ))
        setFormValues((state: any) => ({ name: '', applicable_for: 0, modules: tmp, widgets: [], isSubmitting: false }))
    }

    const handleSearch = () => {

    }

    const handleAccessChange = (e: any, slug: string) => {
        if (Object.values(errors).some((item: any) => item !== null)) {
            setTouched({
                name: true,
                applicable_for: true
            });
            return false;
        }

        let tempAccess = {
            ...formValues,
            modules: [
                ...formValues.modules.filter((a: any) => a.module !== slug),
                {
                    module: slug,
                    access: {
                        ...formValues.modules.find((a: any) => a.module === slug).access,
                        [e.target.name]: e.target.checked
                    }
                }
            ]
        }
        setFormValues((state: any) => ({
            ...state,
            modules: [
                ...state.modules.filter((a: any) => a.module !== slug),
                {
                    module: slug,
                    status: Object.values(tempAccess.modules.find((a: any) => a.module === slug).access).some((item: any) => item === true),
                    access: {
                        ...state.modules.find((a: any) => a.module === slug).access,
                        [e.target.name]: e.target.checked
                    }
                }
            ]
        }))
    }

    // useEffect(() => {
    //     setFormValues((state: any) => ({
    //         ...state,
    //         modules: state?.modules?.map((m: any) => ({
    //             ...m,
    //             // status: Object.values(m?.access).some((item: any) => item === true)
    //         }))
    //     }))
    // }, [formValues?.modules])

    useEffect(() => {
        getModules();
        getDashboardWidgets();
    }, [])

    useEffect(() => {
        // handleHCreateRole();
    }, [modules])

    useEffect(() => {
        if (formValues?.name === '') {
            setErrors((state: any) => ({ ...state, name: 'Please enter role name' }))
        } else {
            setErrors((state: any) => ({ ...state, name: null }))
        }
        if (formValues?.applicable_for === 0) {
            setErrors((state: any) => ({ ...state, applicable_for: 'Please select applicable for' }))
        } else {
            setErrors((state: any) => ({ ...state, applicable_for: null }))
        }
        console.log("formValues", formValues)
    }, [formValues])

    useEffect(() => {
        if (loadingList) {
            getAccessRoles();
        }
    }, [loadingList])

    useEffect(() => {
        if (accessRoles.length > 0) {
            setFormValues(accessRoles[0])
            setView(true)
        }
    }, [accessRoles])

    useEffect(() => {
        if (searchKey.name || searchKey.applicable_for) {
            const tmp = [...accessRoles];
            // setAccessRoles((state: any) => tmp.filter((a: any) => a.applicable_for == searchKey.applicable_for))
        }
    }, [searchKey])


    return (
        <div className="page-content">
            <Container fluid>
                <Breadcrumbs
                    breadcrumbTitle={t("Access Role")}
                    breadcrumbItems={breadcrumbItems}
                    endAdornment={
                        userProfile.CAN_CREATE ? (
                            <Link
                                to="#"
                                className="btn btn-primary btn-sm waves-effect waves-light btn-label"
                                onClick={handleHCreateRole}>
                                <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">add</span> {t("Create access role")}
                            </Link>
                        ) : null
                    }
                />
                <Row>
                    <Col xl="4">
                        <Card>
                            <CardBody>
                                {/* <Row>
                                    <Col>
                                        <Input
                                            label=''
                                            name="name"
                                            id="name"
                                            placeholder='Access role name'
                                            className='mb-3'
                                            onChange={(e: any) => setSearchKey((state: any) => ({ ...state, name: e.target.value }))}
                                        />
                                    </Col>
                                    <Col>
                                        <Select
                                            label=''
                                            name="applicable_for"
                                            id="applicable_for"
                                            options={options}
                                            onChange={(option: any) => setSearchKey((state: any) => ({ ...state, applicable_for: option.value }))}
                                        />
                                    </Col>
                                </Row> */}
                                <div className='table-responsive table-advanced'>
                                    <div className='react-bootstrap-table' style={{ height: '72vh', maxHeight: '72vh' }}>
                                        <Table table>
                                            <thead className='thead-light'>
                                                <tr>
                                                    <th>{t("Access role name")}</th>
                                                    <th>{t("Applicable for")}</th>
                                                    <th className='text-center'>{t("Action")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {accessRoles.length > 0 && accessRoles?.map((role: any) => (
                                                    <tr key={role.id} className={role.id === formValues?.id ? 'table-info' : ''}>
                                                        <td onClick={() => handleView(role)} title={role.name}>{subString(role.name, 30)}</td>
                                                        <td onClick={() => handleView(role)}>{options?.find((x: any) => x.value == role.applicable_for)?.label}</td>
                                                        <td className='rightAlign' width={130}>
                                                            <KebabMenu
                                                                menus={[
                                                                    {
                                                                        label: t('View'),
                                                                        icon: <span className="align-middle material-symbols-outlined fw-light fs-4">visibility</span>,
                                                                        onClick: () => handleView(role),
                                                                        disabled: !userProfile.CAN_VIEW
                                                                    },
                                                                    {
                                                                        label: t('Edit'),
                                                                        icon: <span className="align-middle material-symbols-outlined fw-light fs-4">edit</span>,
                                                                        onClick: () => handleEdit(role),
                                                                        hidden: role.no_edit,
                                                                        disabled: !userProfile.CAN_EDIT
                                                                    },
                                                                    {
                                                                        label: t('Delete'),
                                                                        icon: <span className="align-middle material-symbols-outlined fw-light fs-4">delete</span>,
                                                                        onClick: () => handleDelete(role),
                                                                        hidden: role.no_delete,
                                                                        disabled: !userProfile.CAN_DELETE
                                                                    },
                                                                ]}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xl="8">
                        <Card>
                            <CardBody>
                                <AccessRoleForm formValues={formValues} setFormValues={setFormValues} handleSubmit={handleSubmit} isView={isView} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <DeleteModal
                    recordName={selectedRole?.name}
                    modalTitle="Access Role"
                    show={deleteModal}
                    onDeleteClick={deleteRole}
                    onCloseClick={() => setDeleteModal(false)}
                    loading={false}
                />
            </Container>
        </div>
    )
}

export default AccessRole;