import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper";
import * as url from "../helpers/url_helper";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class DatatableSettings {
  async getDatatableSettings(DATA: any) {
    const response = await axios
      .post(SERVER_URL + url.GET_DATATABLE_SETTINGS, DATA, {
        headers: getAxiosConfigWithToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async updateDatatableSettings(DATA: any) {
    const response = await axios
      .post(SERVER_URL + url.UPDATE_DATATABLE_SETTINGS, DATA, {
        headers: getAxiosConfigWithToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async resetDatatableSettings(DATA: any) {
    const response = await axios
      .post(SERVER_URL + url.RESET_DATATABLE_SETTINGS, DATA, {
        headers: getAxiosConfigWithToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async updateDatatableOrderSettings(DATA: any) {
    const response = await axios
      .post(SERVER_URL + url.UPDATE_DATATABLE_ORDER_SETTINGS, DATA, {
        headers: getAxiosConfigWithToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

}

export default new DatatableSettings();
