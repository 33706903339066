import React from "react";
import { useNavigate, useLocation } from "react-router";
import {
  Container,
  Row,
  Col,
  CardFooter,
  Button,
} from "reactstrap";

import { initialValues } from "./helper";

import Header from "../../components/Report/Header";
import Footer from "../../components/Report/Footer";
import { useTranslation } from "react-i18next";

const GDPRPolicy = (props: any) => {
  const { t } = useTranslation();
  document.title = t("General Terms & Conditions") + " | " + process.env.REACT_APP_NAME;
  
  let navigate = useNavigate();
  const location = useLocation();

  const onClickAccept = () => {
    const registerUser = localStorage.getItem('registerUser');
    if (registerUser) {
      let registerUserObj = JSON.parse(registerUser);
      registerUserObj.is_gdpr_policy_accepted = true;
      localStorage.setItem('registerUser', JSON.stringify(registerUserObj));
    } else {
      localStorage.setItem('registerUser', JSON.stringify(initialValues));
    }

    const prevLocation = location.state as any;
    if (prevLocation) {
      const prevPath: any = prevLocation?.from?.pathname;
      return navigate(prevPath);
    } else {
      return navigate("/login");
    }
  }

  return (
    <React.Fragment>
      <div style={{ padding: '10px 8%' }}>
        <Container>
          <div className='invoicePage'>
            <Header />
            <div className='invoiceBody'>
              <Row>
                <Col className="col-12">
                  <div className='invoiceHeading1'>
                    <span>General Terms & Conditions</span>
                  </div>
                </Col>
                <Col className="col-12">
                  <h3>1.Scope</h3>
                  <p>These general conditions (the "<strong>General Terms and Conditions</strong>") apply to all services provided or to be provided by or on behalf of Tax Consult HR Tax &amp; International Mobility (hereafter &laquo; <strong>TC HR Tax &amp; IM</strong> &raquo;).</p>
                  <p>In the absence of any specific agreement in this matter in the engagement letter, the agreement is concluded and takes effect:</p>
                  <ul>
                    <li>either when the professional receives the engagement letter, signed by the client, and countersigns it in his turn;</li>
                    <li>or when the professional begins to perform the assignment, at the client's request, when this performance begins at an earlier time due to exceptional or urgent circumstances.</li>
                  </ul>
                  <p>The acceptance of the engagement letter issued by TC HR Tax &amp; IM implies the acceptance of the General Terms and Conditions, including their future evolutions, for any current or future file handled by TC HR Tax &amp; IM.</p>
                  <p>When the professional has not yet received the engagement letter signed by the client, all professional relations between the parties are subject to these General Terms &amp; Conditions and the engagement letter, provided that these contractual documents have been communicated to the client.</p>
                  <p>Where the engagement letter includes or refers to specific terms and conditions, these apply in parallel to the General Terms and Conditions. However, in the event of a conflict or an inconsistency between the clauses of the two sets of terms and conditions, the specific terms and conditions prevail over the General Terms and Conditions.</p>
                  <h3>2.Our collaboration</h3>
                  <h4>2.1.Terms of collaboration</h4>
                  <p>TC HR Tax &amp; IM, is a Belgian company registered with the Belgian company register under number 0740.763.660 and is member of the Institute for Tax Advisors and Accountants (ITAA), registered under the number [50.752.723].</p>
                  <h4>2.2.Professional secrecy &ndash; money laundering &ndash; DAC6</h4>
                  <p>Both TC HR Tax &amp; IM, as well as its director(s), employee(s) or agent(s) are bound by professional secrecy, in accordance with article 50 of the law of March 17, 2019 relating to the accounting and tax professions and article 32 of the Royal Decree of March 1<sup>st</sup>, 1998 laying down the rules of professional conduct for certified accountants and tax advisers, subject however to the application of the provisions of the legislation and regulations relating to the prevention of the use of the financial system for the purpose of money laundering and terrorist financing.</p>
                  <p>The client confirms that he is aware of the fact that the professional is subject to the legislation relating to the prevention of the use of the financial system for the purposes of money loundering and terrorist financing and undertakes to deliver to him, without delay, any information and/or document required under the said legislation.</p>
                  <p>In addition, TC A&amp;A, its director(s), employee(s), or agent(s) are bound by the law of December 20, 2019, transposing the Council Directive (EU) 2018/822 dated May 25, 2018, amending Directive 2011/16/EU on the automatic and mandatory exchange of tax information in relation to reportable cross-border arrangements (hereinafter DAC 6).</p>
                  <p>However, in accordance with the instructions of the ITAA, TC HR Tax &amp; IM informs the client the professional secrecy prevents it from making any DAC 6 declaration or from informing intermediaries concerned by an arrangement falling in the scope of DAC 6.</p>
                  <h4>2.3.TC HR Tax &amp; IM's scope of professional responsibility</h4>
                  <p>In accordance with general contract law, the liability of TC HR Tax &amp; IM can only be engaged for assignments which it is shown to have accepted.</p>
                  <p>Except with respect to compliance with legal deadlines (provided that the client has complied with all its obligations as referred to in article 4 of the General Terms and Conditions or in article 8.1 of the engagement letter), the commitment of TC HR Tax &amp; IM vis-&agrave;-vis its client is an obligation of means (<em>obligation de moyens</em>). TC HR Tax &amp; IM will carry out the missions entrusted to it in complete independency and with the required care according to the standards, instructions, or regulation of the ITAA.</p>
                  <p>TC HR Tax &amp; IM depends on the client for the accuracy of the information and/or documentation provided by the latter. TC HR Tax &amp; IM is not obliged to verify the correctness and completeness of the information communicated to it by the client or on his behalf, as well as the reliability of all deeds, contracts, inventories, invoices and supporting documents of any nature, which would be entrusted to it or presented by the client as probative documents or to be used as such.</p>
                  <p>Our mission does not include the detection of fraud, irregularity, or illegality in actions, but only the verification of the content of the documents received within our mission, as to their completeness and adequacy in terms of the principles and standards generally accepted by the profession.</p>
                  <p>TC HR Tax &amp; IM cannot be held liable for the consequences of legislative changes.</p>
                  <p>TC HR Tax &amp; IM cannot be held liable for any breaches, mistakes or offences committed before its intervention.</p>
                  <p>TC HR Tax &amp; IM is an independent member firm of Alliott Global Alliance, which is an international alliance of independent accounting, law, and specialist firms. Alliott Global Alliance and its member firms are legally distinct and separate entities. These entities are not and shall not be construed to be in the relationship of a parent firm, subsidiary, partner, joint venture, agent, or a network. No Alliott Global Alliance member firm has any authority (actual, apparent, implied or otherwise) to obligate or bind Alliott Global Alliance or any other Alliott Global Alliance member firm in any manner whatsoever. Equally, neither Alliott Global Alliance nor any other member firm has any authority to obligate or bind TC HR Tax &amp; IM or any other member firm. All Alliott Global Alliance members are independent firms, and as such, they each render their services entirely on their own account (including benefit and risk). In connection with the engagement letter, the General Terms and Conditions or any other services from time to time provided by TC HR Tax &amp; IM, TC HR Tax &amp; IM may seek advice from or may recommend the retention of an Alliott Global Alliance member firm. Alliott Global Alliance and its other member firms shall have no liability for advice rendered by TC HR Tax &amp; IM, or such consulted or retained Alliott Global Alliance member firm. Nor shall TC HR Tax &amp; IM have liability for advice rendered by any of the other Alliott Global Alliance member firms, even if consulted or recommended to you by TC HR Tax &amp; IM.</p>
                  <p>In any case, the liability of TC HR Tax &amp; IM for any services performed by or on behalf of TC HR Tax &amp; IM or which is directly or indirectly linked to an assignment entrusted to TC HR Tax &amp; IM is limited to the amount covered by virtue of the conditions of the professional civil liability insurance program taken out by TC HR Tax &amp; IM through the Institute for Tax Advisors &amp; Accountants.</p>
                  <p>This limitation also applies to claims that would be directed by the head of the performance of the assignment against all persons, partners, managers and / or independent collaborators, who are insured within the meaning of the insurance contract.</p>
                  <p>When it appears that two or more cases of damage result from the same fault, they will be considered as constituting only one case of liability, and liability will therefore be limited to the greater amount of those applicable to missions or agreements concerned.</p>
                  <p>It is expressly stipulated that this limitation is applicable in case of gross negligence committed by TC HR Tax &amp; IM as well as in case of aquilian liability of TC HR Tax &amp; IM.</p>
                  <p>In any event, TC HR Tax &amp; IM shall not be liable for any indirect or consequential economic loss or damage (including without limitation loss of profit, turnover, and clientele) that the client may suffer as a result of the provision of the services of TC HR Tax &amp; IM.</p>
                  <p>No liability towards a third party is accepted unless TC HR Tax &amp; IM has given its express written consent.</p>
                  <p>The professional rules authorize TC HR Tax &amp; IM, in the strict respect of professional secrecy, to accept business for other clients whose activities may compete with those of the client.</p>
                  <h3>3.Fees</h3>
                  <p>Our rates and flat-rate offers are exclusive of VAT, foreign taxes and any deductions, disbursements, and travel expenses.</p>
                  <p>They are subject to annual indexation according to the evolution of the cost of living. Any rate evolution not contested within 8 days of its notification will be presumed to be accepted.</p>
                  <p>Hourly rates vary according to the experience of the consultant and are between 70 euros and 420 euros.</p>
                  <p>The flat-rate offers are limited to the services described and do not cover other services such as but not limited to specific tax services such as various advices, response to a request from the administration or assistance in the context of a dispute with the administration.</p>
                  <p>They are determined based on an estimate of the volume of work required, the difficulty of the assignment, the nature of the activity and the time and experience required by a professional.</p>
                  <p>If, during the work, any of these factors should change in such a way that our proposal is no longer appropriate, we will immediately contact you to agree on a new budget proposal.</p>
                  <p>To cover the operating costs of TC HR Tax &amp; IM, a percentage of 5% will be applied on invoices (this item covers, file costs, IT, registered mail with acknowledgement of receipt, secretarial and receptionist costs). </p>
                  <p>The fees and costs are due as and when the services are provided for the benefit of the client, even if the assignment is not necessarily completed.</p>
                  <p>TC HR Tax &amp; IM can request one or more advance payments. These payments are brought into account in the final statement of costs and fees.</p>
                  <p>All costs and fees of objections must be made in writing by registered letter or by email within 60 days of the date of the invoice default to what the customer is deemed to have agreed with the services and amounts charged.</p>
                  <p>Invoices and/or fee notes are payable within 14 days of the invoice date.</p>
                  <p>Over 15 days&rsquo; delay in payment, will be automatically and without any prior notice of default due:</p>
                  <p>&middot;a compensatory interest at the rate provided for in Article 5 of the Law of 2 August 2002 relating to late payment in commercial transactions;</p>
                  <p>&middot;a contractual indemnity fixed at 10% of the unpaid amounts, with a minimum of &euro; 250.00.</p>
                  <h3>4.The customer agrees hereby:</h3>
                  <p>In view of the assignment entrusted to TC HR Tax &amp; IM, the client scrupulously undertakes to collaborate and provide precisely and on time all the documents, data, and information necessary for the execution of the mission.</p>
                  <p>The client undertakes also</p>
                  <ul>
                    <li>to provide any information that TC HR Tax &amp; IM requests according to the legislation and regulations relating to the prevention of the use of the financial system for the purpose of money laundering and terrorist financing;</li>
                  </ul>
                  <ul>
                    <li>communicate without delay any change in their contact details to TC HR Tax &amp; IM and any change in their beneficial owners;</li>
                  </ul>
                  <ul>
                    <li>to comply with Belgian company law and to any legislation preventing or relating to corruption, criminal offense, or fraud;</li>
                  </ul>
                  <ul>
                    <li>to make available to TC HR Tax &amp; IM, timely, all documents, data, and information necessary for the execution of the mission;</li>
                  </ul>
                  <ul>
                    <li>to perform the work assigned to it, as appropriate, in accordance with the engagement letter;</li>
                  </ul>
                  <ul>
                    <li>to bring to the attention of TC HR Tax &amp; IM any data, events, or developments likely to influence the execution of the mission;</li>
                  </ul>
                  <ul>
                    <li>Upon TC HR Tax &amp; IM&rsquo;s request, confirm in writing that the documents, information, and explanations provided are accurate and complete;</li>
                  </ul>
                  <ul>
                    <li>Check whether the documents and statements produced by professional match its expectations and the information provided by him, and if this is not the case, to inform without delay TC HR Tax &amp; IM;</li>
                  </ul>
                  <ul>
                    <li>inform TC HR Tax &amp; IM of any default in payment, from the first due date, regarding any administration, fiscal, social, or any other creditor whatsoever.</li>
                  </ul>
                  <p>Failing to do so, TC HR Tax &amp; IM will be authorized to terminate the mission according to article 6.3 below and will be released from any responsibility for non-compliance with the deadlines set by laws, regulations, and agreements for the execution of fiscal, social, or other formalities, which would fall under the cover of its mission.</p>
                  <h3>5.Place of execution of the mission</h3>
                  <p>Books and documents provided to or prepared by TC HR Tax &amp; IM for the execution of the mission can be moved to other places upon TC HR Tax &amp; IM's discretion. The professional may hold them for the time necessary to carry out his mission(s). The client always has the right to consult them, either personally, or through the intermediary of one of its representative or agent bearing a written power of attorney, provided that these books and documents are the property of the client.</p>
                  <p>It is the client's responsibility to keep the accounting records and documents and to take them back as soon as the professional notifies him of their availability. The professional undertakes to make his accounting documents available to the client on a regular basis.</p>
                  <h3>6.Terms and conditions for the end of collaboration</h3>
                  <h4>6.1.Recurrent assignments</h4>
                  <p>Recurrent assignments are assignments which are repetitive in nature, and which do not meet the definition referred to in article 6.2 of &ldquo;non-recurrent assignments&rdquo;.</p>
                  <p>The contract relating to recurrent assignments is concluded for an indefinite period. Each Party shall have the right to terminate it by notifying the other Party of its willingness to do so by registered letter and observing a three-months&rsquo; notice period unless the Parties agree otherwise.</p>
                  <p>In the event of non-compliance by the client with the notice period, TC HR Tax &amp; IM is entitled to obtain the payment of a compensation corresponding to 50% of the fees invoiced during the previous 3 months.</p>
                  <p>During the notice period, the provisions of the engagement letter and these General Terms and Conditions remain in force.</p>
                  <p>Unless otherwise agreed by the parties, the termination of the mission, even with observance of a notice period, results in the immediate termination of the professional's services for the preparation of the annual accounts for the financial year preceding the date of termination.</p>
                  <h4>6.2.Non-recurrent assignments</h4>
                  <p>Non-recurrent assignments are one-off and non-repetitive assignments, the achievement of which puts an end to the assignment.</p>
                  <p>Unless otherwise proven, the agreement relating to a non-recurring assignment is deemed to be concluded for a fixed period.</p>
                  <p>It ends with the performance of the assignment and, if this is applicable in view of the nature of the assignment, with the delivery of the agreed services.</p>
                  <p>In application of article 1794 Civil Code and, where applicable, notwithstanding article 2004 Civil Code, the client has the right to terminate the agreement early, upon payment to the professional of the costs and fees corresponding to work already carried out.</p>
                  <p>This compensation is calculated on a real basis, with a minimum of 50% of the fees that would have been due in the event of full performance of the assignment.</p>
                  <h4>6.3.Immediate termination</h4>
                  <p>TC HR Tax &amp; IM may terminate the collaboration, at any time, without notice nor any indemnity whatsoever and without prior judicial intervention, in case of wrongful non-fulfilment of one of the client's obligations which it has not remedied within eight (8) days following the sending of a formal notice by TC HR Tax &amp; IM enjoining it to cease any default as well as in one of the following situations:</p>
                  <ul>
                    <li>any circumstance likely to jeopardize the independency of TC HR Tax &amp; IM;</li>
                  </ul>
                  <ul>
                    <li>any circumstance likely to render impossible the execution of the mission, in accordance with professional and ethical standards;</li>
                  </ul>
                  <ul>
                    <li>the failure of the client to communicate the documents necessary to TC HR Tax &amp; IM for the execution of its mission;</li>
                  </ul>
                  <ul>
                    <li>in case of judicial reorganization, dissolution, liquidation, bankruptcy of the client or any similar procedure. </li>
                  </ul>
                  <p>When terminating the agreement, TC HR Tax &amp; IM informs the client of the urgent and necessary legal acts which, for the safeguard of his rights, must be carried out and for which TC HR Tax &amp; IM had been entrusted.</p>
                  <p>The client can terminate the agreement, at any time, without notice and without indemnity, TC HR Tax &amp; IM remains manifestly in default of fulfilling his obligations, as described in these general conditions and, where applicable, in the engagement letter. The client will send a formal notice to TC HR Tax &amp; IM identifying the default and enjoining TC HR Tax &amp; IM to cease it.</p>
                  <h4>6.4.Follow up</h4>
                  <p>Upon termination of the mission, all books and documents, property of the client, are made available to him or to f his representative by means of the drafting of an inventory dated and signed in as many original copies as there are parties.</p>
                  <p>The end of the mission:</p>
                  <ul>
                    <li>entails immediate withdrawal of any mandate issued for the filing of any returns, official documents or forms such as individual tax returns, corporate tax returns, VAT tax returns, annual accounts, UBO register &hellip;</li>
                    <li>requires the client to take back without delay all his accounting documents from the professional.</li>
                  </ul>
                  <h4>6.5.Suspension or postponing of the assignment</h4>
                  <p>In the event of non-performance, improper performance, or late performance, by the client, of one or more of its obligations, including in the event of non-payment of fees or down payment(s), in accordance with article 3 above, TC HR Tax &amp; IM is entitled to suspend or defer the performance of its obligations until the client has satisfied hers.</p>
                  <p>TC HR Tax &amp; IM informs the client in writing and notifies the client of any urgent and necessary work to safeguard the client&rsquo;s rights that must be carried out within three months after the start of the suspension or postponement of the assignment.</p>
                  <p>All costs and charges (including fines and compensation) resulting from the suspension or postponement are the responsibility of the client.</p>
                  <p>TC HR Tax &amp; IM is entitled in all circumstances to the payment of fees and costs relating to services already performed.</p>
                  <h3>7.Privacy - data processing</h3>
                  <p>TC HR Tax &amp; IM takes its obligations with regard to the protection of privacy and the protection of personal data very seriously.</p>
                  <p><em>Definitions: </em>In this clause, the following terms shall have the following meanings:</p>
                  <p>"<strong>controller</strong>", "<strong>processor</strong>", "<strong>data subject</strong>", "<strong>personal</strong> <strong>data</strong>" and "<strong>processing</strong>" (and "<strong>process</strong>") shall have the meanings given in Applicable Data Protection Law;</p>
                  <p>"<strong>Applicable Data Protection Law</strong>" means all worldwide data protection and privacy laws and regulations applicable to the personal data in question, including, where applicable, EU Data Protection Law; and</p>
                  <p>"<strong>EU Data Protection Law</strong>" means (i) Regulation 2016/679 of the European Parliament and of the Council on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (General Data Protection Regulation) (the "<strong>GDPR</strong>"); (ii) the EU e-Privacy Directive (Directive 2002/58/EC); and (iii) any and all applicable national data protection laws made under or pursuant to (i) or (ii); in each case as may be amended or superseded from time to time.</p>
                  <p><em>Legal qualification of the Parties: </em>TC HR Tax &amp; IM and the client will process personal data (hereinafter, the "<strong>Data</strong>") pursuant to the General Terms and Conditions. The Parties acknowledge that each Party processes the Data in its possession or control as an independent data controller for their own purpose(s) (not as joint controllers or as the data processor of the other Party).</p>
                  <p><em>Compliance with law:</em> Each Party shall comply with its obligations under Applicable Data Protection Law, and these General Terms and Conditions, when processing the Data. Neither Party shall be responsible for the other Party's compliance with Applicable Data Protection Law. In particular, each Party shall be individually responsible for ensuring that its processing of the Data is lawful, fair and transparent, and shall make available to data subjects a privacy statement that fulfils the requirements of Applicable Data Protection Law. To the extent that Tax Consult HR Tax &amp; IM is not in direct contact with the data subjects, the client shall provide a copy of Tax Consult HR Tax &amp; IM's privacy policy to the data subjects without delay after the conclusion of these General Terms and Conditions.</p>
                  <h3>8.Divisibility and jurisdiction</h3>
                  <p>The General Terms and Conditions are applicable to the exclusion of all others. Any clause or condition constitutes a separate and independent provision. If a provision is considered to be null and void or unenforceable, all other provisions shall continue to apply.</p>
                  <p>The engagement of TC HR Tax &amp; IM will be governed and interpreted in accordance with Belgian law. Any dispute or claim arising therefrom will be submitted, prior to any legal action, to a mediation process in accordance with the mediation rules of bMediation, whose registered office is established at 1050 Brussels, avenue Louise 500. The mediation shall commence no later than fifteen (15) days after the request for mediation notified by one party to the other party and the duration of the mediation shall not exceed fifteen (15) days, unless expressly agreed by the parties.</p>
                  <p>In the event of failure of the mediation, the courts of Brussels shall have exclusive jurisdiction, without prejudice to the jurisdiction of the professional bodies on which TC HR Tax &amp; IM depends. </p>
                  <h3>9.Prohibition on soliciting</h3>
                  <p>For the entire duration of the collaboration, and for a period of 12 months after the end of the collaboration, whatever the reason for the termination of the relationship, the client and TC HR Tax &amp; IM expressly undertake not to take on any staff or independent collaborators of the other party involved in the execution of the agreement, directly or indirectly (in particular through a legal entity), nor to have them carry out work, directly or indirectly (in particular through a legal entity), unless the prior written agreement of the other party has been obtained.</p>
                  <p>Any infringement of this prohibition will give rise to a lump-sum compensation of 25% of the annual remuneration costs of the member of the staff which is deemed to cover the fees that would be applied by a head-hunter to find an equivalent profile.</p>
                  <br />
                  <p><strong>All your personal and professional data will be stored in a database that is owned by Tax Consult S.A..</strong></p>
                </Col>
              </Row>
            </div>
            <CardFooter className="d-sm-flex flex-wrap bg-transparent border-top pt-2">
              <div className="d-flex gap-2"></div>
              <div className="ms-auto d-flex gap-2 justify-content-end">
                <Button
                  color="primary"
                  className="btn-block"
                  type="button"
                  onClick={() => onClickAccept()}
                >
                  {t("Accept & close")}
                </Button>
              </div>
            </CardFooter>
            <Footer />
          </div>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default GDPRPolicy;
