import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Nav, NavItem } from "reactstrap";
import Notification from "./Notification";
import { getUserProfile } from "../../helpers/functions_helper";
// Import Breadcrumb
import Breadcrumbs from "../../components/Layout/Breadcrumb";

import ScrollUp from "../../components/ScrollUpDown/ScrollUp";
import ScrollDown from "../../components/ScrollUpDown/ScrollDown";

import { useTranslation } from "react-i18next";

import NavItems from "./NavItems";

import { Row, Col, Card, CardBody } from "reactstrap";

const EditUserNotifications = (props: any) => {
  const { t } = useTranslation();
  document.title = `${t("User details")} - ${t("Notifications")} | ${process.env.REACT_APP_NAME}`;
  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "/users/all-users",
      label: t("Users"),
      active: false,
    },
    {
      url: "#",
      label: t("Update user"),
      active: true,
    },
  ];
  let navigate = useNavigate();
  const userProfile = getUserProfile("all-users");
  const { id, user_id } = useParams();

  useEffect(() => {
    if (!userProfile.IS_COMPANY_ADMIN) {
      return navigate("/dashboard");
    }
  }, [userProfile]);

  return (
    <React.Fragment>
      <ScrollUp />
      <ScrollDown />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={t("Update user")}
            breadcrumbItems={breadcrumbItems}
          />

          <NavItems id={id} user_id={user_id} />
          <Notification id={id} user_id={user_id} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditUserNotifications;
