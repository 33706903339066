import {useRef,useState,useEffect,} from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from "react-redux";
import { setDraftEmailData } from '../../store/mails/actions';
function copyStyles(src:any, dest:any) {
  Array.from(src.styleSheets).forEach((styleSheet:any) => {
      dest.head.appendChild(styleSheet.ownerNode.cloneNode(true))
  })
  Array.from(src.fonts).forEach(font => dest.fonts.add(font))
}

const WindowPortal=(props:any)=> {
   
    const [container, setContainer] = useState<HTMLDivElement>();
    const newWindow = useRef<any>();
    const dispatch = useDispatch();
    useEffect(() => {
      // Create container element on client-side
      setContainer(document.createElement("div"));
    }, []);
  
    useEffect(() => {
      // When container is ready
      if (container) {
        // Create window
        newWindow.current = window.open(
          "",
          "",
          "width=600,height=500,left=200,top=200"
        );
        // Append container
        newWindow.current.document?.body.appendChild(container);
        // Save reference to window for cleanup
        const curWindow = newWindow.current;
        copyStyles(document, newWindow.current.document);
        // Return cleanup function

        return () => {
          curWindow.close();
          dispatch(setDraftEmailData({ openMailBox: 0 }));
        }
      }
    }, [container]);
  
    return container ? createPortal(props.children, container):null;
  }

  export default WindowPortal;