import React, { FC, useEffect, useState } from 'react'
import { Col, Input, Label, Row } from 'reactstrap';

import Select from "react-select";
import commonService from '../../services/common.service';
import { addAllUsersInUsers, addIndividualInCompany, getDependentSelectOptionsWithOrder, getUserProfile, yearData } from '../../helpers/functions_helper';
import { useTranslation } from 'react-i18next';
import { statusOptions } from '../../common/staticdata';
type FiltersProps = {
    filters: any;
    onApply: any;
    onClear: any;
    onChange?: any;
    company_id?: any;
}

const Filters: FC<FiltersProps> = ({ filters, onApply, onClear, onChange, company_id }) => {
    const { t } = useTranslation();
    const userProfile = getUserProfile();
    const [companyOption, setCompanyOption] = useState([]);
    const [loadingCompany, setloadingCompanyOption] = useState(true);
    const [loadingCompanyList, setloadingCompanyList] = useState(true);
    const [companyID, setCompanyID] = useState<any>(filters?.company_id);

    /* set questionnaire type */
    const [questionnaireTypeOption, setQuestionnaireTypeOption] = useState([]);
    const [questionnaireType, setQuestionnaireType] = useState({
        value: 0,
        label: t("Please select..."),
    });
    const [loadingQuestionnaireType, setLoadingQuestionnaireType] = useState(true);

    /* set questionnaire Status */
    const [questStatusOption, setquestStatusOption] = useState([]);
    const [loadingquestStatus, setloadingquestStatus] = useState(true);

    const [userType, setUserType] = useState<any>(filters?.userType);
    const [yearValue, setYearValue] = useState<any>(filters?.year);
    const [yearList, setYearList] = useState<any>([]);
    const [statusList, setStatusList] = useState<any>([]);
    useEffect(() => {
        if (yearData) {
            const Options = yearData?.map((option: any) => ({
                label: t(option.label),
                value: option.value
            }));
            setYearList(Options);
        }

        if (statusOptions) {
            const Options = statusOptions?.map((option: any) => ({
                label: t(option.label),
                value: option.value
            }));
            setStatusList(Options);
        }
    }, [])
    useEffect(() => {
        if (yearData) {
            const Options = yearData?.map((option: any) => ({
                label: t(option.label),
                value: option.value
            }));
            setYearList(Options);
        }

        if (statusOptions) {
            const Options = statusOptions?.map((option: any) => ({
                label: t(option.label),
                value: option.value
            }));
            setStatusList(Options);
        }
    }, [localStorage.getItem("I18N_LANGUAGE")])


    const getSelectOptions = async (tableName: string, fieldName: string, otherField?: Array<string>) => {
        const response = await commonService.getSelectOptionsList(
            tableName,
            fieldName,
            "",
            otherField
        );
        return response.data.data;
    };


    const getSelectOptionsOrder = async (tableName: string, fieldName: string) => {
        const response = await commonService.getSelectOptionsListOrder(
            tableName,
            fieldName
        );
        return response.data.data;
    };

    const [query, setQuery] = useState("");
    const getDependentMultipleWhereSelectOptions = async (
        tableName: string,
        fieldName: string,
        whereFields: any,
        whereIds: any
    ) => {
        const response = await commonService.getDependentMultipleWhereSelectOptionsList(
            tableName,
            fieldName,
            whereFields,
            whereIds,
            query ? query : ""
        );
        return response.data.data;
    };


    useEffect(() => {
        getSelectOptions("tbl_master_company", "company_name").then(function (
            options
        ) {
            const item = addIndividualInCompany(options) as any;
            if (item) {
                const options = item?.map((option: any) => ({
                    label: t(option.label),
                    value: option.value
                }));
                setCompanyOption(options);
            }
            setloadingCompanyOption(false);
            if (userProfile.USER_COMPANY != 1) {
                filters.company_id = userProfile.USER_COMPANY;
                setCompanyID(userProfile.USER_COMPANY);
                setloadingCompanyList(true);

            } else {
                setloadingCompanyList(false);
            }
        });

        getSelectOptionsOrder("tbl_master_questionnaire_status", "questionnaire_status_value").then(function (
            options
        ) {
            setquestStatusOption(options);
            setloadingquestStatus(false);
        });

    }, [])

    useEffect(() => {
        setQuestionnaireTypeOption([]);
        setLoadingQuestionnaireType(true);

        getDependentSelectOptionsWithOrder("tbl_master_questionnaire_type", "questionnaire_type", "active", 1, "year").then(function (options) {
            setQuestionnaireTypeOption(options);
            setLoadingQuestionnaireType(false);
        });
    }, []);
    const [loadingUsers, setLoadingUsers] = useState(true);
    const [usersOptions, setUsersOptions] = useState([]);
    const getDependentSelectOptions = async (
        tableName: string,
        fieldName: string,
        whereField: string,
        whereID: number
    ) => {
        const response = await commonService.getDependentSelectOptionsList(
            tableName,
            fieldName,
            whereField,
            whereID,
            query ? query : ""
        );
        return response.data.data;
    };

    useEffect(() => {
        if (companyID) {
            getDependentSelectOptions("tbl_master_users", "first_name", "company", companyID).then(function (
                options
            ) {
                const item = addAllUsersInUsers(options) as any;
                setUsersOptions(item);
                setLoadingUsers(false);
            });
        } else {
            const company_id = userProfile.USER_COMPANY;
            getDependentSelectOptions("tbl_master_users", "first_name", "company", company_id).then(function (
                options
            ) {
                const item = addAllUsersInUsers(options) as any;
                setUsersOptions(item);
                setLoadingUsers(false);
            });
        }
    }, [companyID]);

    const [usersList, setUsersList] = useState<any>([]);
    useEffect(() => {
        if (usersOptions) {
            const Options = usersOptions?.map((option: any) => ({
                label: t(option.label),
                value: option.value
            }));
            setUsersList(Options);
        }
    }, [usersOptions, localStorage.getItem("I18N_LANGUAGE")])

    return (
        <React.Fragment>
            {!!userProfile.IS_COMPANY_ADMIN &&
                <Row>
                    <Col>
                        <div className="mb-3">
                            <Label className="form-label">
                                {t("Client")}
                            </Label>
                            {loadingCompany ? <Input id={`ph_cmp`} disabled value={t('Loading...')} /> :
                                <Select
                                    id="company_id"
                                    name="company_id"
                                    className="is-invalid"
                                    options={companyOption}
                                    placeholder={t("Select...")}
                                    value={
                                        filters?.company_id
                                            ? companyOption.find((option: any) => option.value == filters?.company_id)
                                            : ""
                                    }
                                    isSearchable={true}
                                    isDisabled={loadingCompanyList}
                                    onChange={(option: any) => {
                                        onChange({ is_filtered: true, company_id: option.value })
                                        setCompanyID(option.value);
                                    }}
                                />
                            }
                        </div>
                    </Col>
                </Row>
            }
            <Row>
                <Col>
                    <div className="mb-3">
                        <Label className="form-label">
                            {t("User")}
                        </Label>
                        <Select
                            options={usersList}
                            value={
                                filters?.user_id
                                    ? usersList?.find((option: any) => option.value == filters?.user_id)
                                    : ""
                            }
                            placeholder={t("Select...")}
                            isSearchable={true}
                            isDisabled={loadingUsers}
                            isLoading={loadingUsers}
                            onChange={(option: any) => {
                                onChange({ is_filtered: true, user_id: option.value });
                            }}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="mb-3">
                        <Label className="form-label">
                            {t("User type")}{" "}
                        </Label>
                        <Select
                            id="user_type"
                            name="user_type"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={statusList}
                            value={
                                filters?.user_type
                                    ? statusList?.find((option: any) => option.value == filters?.user_type)
                                    : ""
                            }
                            isSearchable={true}
                            onChange={(option: any) => {
                                onChange({ is_filtered: true, user_type: option.value })
                            }}
                        />

                    </div>
                </Col>
                <Col>
                    <div className="mb-3">
                        <Label className="form-label">
                            {t("Year")}{" "}
                        </Label>
                        <Select
                            id="year"
                            name="year"
                            className="is-invalid"
                            options={yearList}
                            placeholder={t("Select...")}
                            value={
                                filters?.year
                                    ? yearList.find((option: any) => option.value == filters?.year)
                                    : ""
                            }
                            isSearchable={true}
                            onChange={(option: any) => {
                                setYearValue(option.value);
                                onChange({ is_filtered: true, year: option.value })
                            }}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="mb-3">
                        <Label className="form-label">
                            {t("Questionnaire Type")}{" "}
                        </Label>
                        {loadingQuestionnaireType ? <Input id={`ph_cmp`} disabled value={t('Loading...')} /> :
                            <Select
                                id="questionnaire_type_id"
                                name="questionnaire_type_id"
                                options={questionnaireTypeOption}
                                placeholder={t("Select...")}
                                value={
                                    filters?.questionnaire_type_id
                                        ? questionnaireTypeOption.find((option: any) => option.value == filters?.questionnaire_type_id)
                                        : ""
                                }
                                isSearchable={true}
                                isDisabled={loadingQuestionnaireType}
                                onChange={(option: any) => {
                                    setQuestionnaireType(option);
                                    onChange({ is_filtered: true, questionnaire_type_id: option.value })
                                }}
                            />
                        }
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="mb-3">
                        <Label className="form-label">
                            {t("Questionnaire Status")}{" "}
                        </Label>
                        <Select
                            id="status"
                            name="status"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={questStatusOption}
                            value={
                                filters?.status
                                    ? questStatusOption.find((option: any) => option.value == filters?.status)
                                    : ""
                            }
                            isSearchable={true}
                            isDisabled={loadingquestStatus}
                            onChange={(option: any) => {
                                onChange({ is_filtered: true, status: option.value })
                            }}
                        />

                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default Filters