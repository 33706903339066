export const GET_USER_TYPE = "GET_USER_TYPE"
export const GET_USER_TYPE_SUCCESS = "GET_USER_TYPE_SUCCESS"
export const GET_USER_TYPE_ERROR = "GET_USER_TYPE_ERROR"

export const GET_USER_TYPE_LIST = "GET_USER_TYPE_LIST"
export const GET_USER_TYPE_LIST_SUCCESS = "GET_USER_TYPE_LIST_SUCCESS"
export const GET_USER_TYPE_LIST_ERROR = "GET_USER_TYPE_LIST_ERROR"
  
export const CREATE_USER_TYPE = "CREATE_USER_TYPE"
export const CREATE_USER_TYPE_SUCCESS = "CREATE_USER_TYPE_SUCCESS"
export const CREATE_USER_TYPE_ERROR = "CREATE_USER_TYPE_ERROR"

export const UPDATE_USER_TYPE = "UPDATE_USER_TYPE"
export const UPDATE_USER_TYPE_SUCCESS = "UPDATE_USER_TYPE_SUCCESS"
export const UPDATE_USER_TYPE_ERROR = "UPDATE_USER_TYPE_ERROR"

export const DELETE_USER_TYPE = "DELETE_USER_TYPE"
export const DELETE_USER_TYPE_SUCCESS = "DELETE_USER_TYPE_SUCCESS"
export const DELETE_USER_TYPE_ERROR = "DELETE_USER_TYPE_ERROR"