import React, { useEffect, useState } from "react";
import { CardTitle, Button } from "reactstrap";
import { Link } from "react-router-dom";
import alertService from "../../services/alert.service";

import { useTranslation } from "react-i18next";
import { dateFormat } from "../../utils/formats";
import DataTable from "../../components/Common/DataTable";
import { getDataTableQuery, getUserProfile, handleFilters } from "../../helpers/functions_helper";
import Drawer from "../../components/Drawer";
import ExpiryFilters from "./ExpiryFilters";

const ExpiryReminder = (props: any) => {
    const { t } = useTranslation();
    const userProfile = getUserProfile("all-users");
    const [loading, setLoading] = useState(true);

    const [dataList, setDataList] = useState<any>([]);
    useEffect(() => {
        setLoading(true);
        setDataList([]);
    }, [localStorage.getItem("I18N_LANGUAGE")])


    const columns = [
        {
            dataField: "DT_RowIndex",
            text: `${t('Sr. No.')}`,
            sort: true,
            formatter: (rowContent: any, row: any, idx: number): any => {
                return idx + 1;
            },
        },
        {
            dataField: "company",
            text: t('Company') + '/' + t('User'),
            sort: true,
            formatter: (rowContent: any, row: any, idx: number): any => {
                return (
                    <div className="d-flex gap-2">
                        {row.company != 0 ?
                            <Link
                                target="_blank"
                                className="text-decoration-underline"
                                to={
                                    "/masters/company/company-details/" +
                                    row.company_id +
                                    "/" +
                                    row.company_sub_id
                                }
                            >
                                {row.company_name}
                            </Link>
                            :
                            <span>Individual</span>
                        }

                    </div>
                );
            },
        },
        {
            dataField: "field_name",
            sort: true,
            text: `${t('Type')}`,
        },
        {
            dataField: "user_name",
            text: `${t('User')}`,
            sort: true,
            formatter: (rowContent: any, row: any, idx: number): any => {
                if (row?.id_link?.includes(["user_end_date", "tax_regime_external_due_date_original", "tax_regime_end_date_original"])) {
                    return (
                        <Link
                            target="_blank"
                            to={
                                "/users/user-details/" +
                                row.user_id +
                                "/" +
                                row.user_sub_id
                            }
                        >
                            {`${row.first_name} ${row.last_name}`}
                        </Link>
                    );
                } else {
                    return (
                        <Link
                            target="_blank"
                            to={
                                "/users/general/" +
                                row.user_id +
                                "/" +
                                row.user_sub_id +
                                "#" + row.id_link
                            }
                        >
                            {`${row.first_name} ${row.last_name}`}
                        </Link>
                    );
                }
            },
        },
        {
            dataField: "end_date",
            text: `${t('Date')}`,
            sort: true,
            formatter: (rowContent: any, row: any, idx: number): any => {
                return row.end_date && dateFormat(row.end_date);
            },
        },
        {
            dataField: "action",
            text: `${t('Action')}`,
            formatter: (rowContent: any, row: any, idx: number): any => {
                if (row.id_link == "user_end_date" || row.id_link == "tax_regime_external_due_date_original" || row.id_link == "tax_regime_end_date_original") {
                    return (
                        <div className="d-flex gap-2">
                            <Link
                                target="_blank"
                                className="btn btn-pill btn-outline-primary btn-sm"
                                to={
                                    "/users/user-details/" +
                                    row.user_id +
                                    "/" +
                                    row.user_sub_id +
                                    "#" + row.id_link
                                }
                            >
                                {t('Process')}
                            </Link>
                        </div>
                    );
                } else {
                    return (
                        <div className="d-flex gap-2">
                            <Link
                                target="_blank"
                                className="btn btn-pill btn-outline-primary btn-sm"
                                to={
                                    "/users/general/" +
                                    row.user_id +
                                    "/" +
                                    row.user_sub_id +
                                    "#" + row.id_link
                                }
                            >
                                {t('Process')}
                            </Link>
                        </div>
                    );
                }
            }
        },
    ];


    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const savedfilters = handleFilters("GET");
    const [datatableOptions, setDatatableOptions] = useState(
        savedfilters && savedfilters?.datatableOptions
            ? (savedfilters?.datatableOptions as any)
            : {
                draw: 1,
                page: 1,
                recordsTotal: 0,
                recordsFiltered: 0,
                sizePerPage: 10,
                searchText: "",
                sortField: "end_date",
                sortOrder: "asc",
            }
    );


    const [filters, setFilters] = useState(
        savedfilters && savedfilters?.filters
            ? (savedfilters?.filters as any)
            : {
                is_filtered: false,
                company_id: 0,
                type: "",
                user_id: 0,
                start_date: "",
                end_date: ""
            }
    );

    const onTableChange = (type: any, newState: any) => {
        let draw = datatableOptions.draw + 1;
        let page = 1;
        if (type === "search") {
            page = 1;
        } else {
            page = newState.page;
        }
        setDatatableOptions((prevData: any) => ({
            ...prevData,
            draw: draw,
            page: page,
            sizePerPage: newState.sizePerPage,
            searchText: newState.searchText,
            sortField: newState.sortField,
            sortOrder: newState.sortOrder,
        }));
        setDataList([]);
        setLoading(true);
    };

    const clearFilter = () => {
        const filter = {
            ...filters,
            is_filtered: false,
            company_id: 0,
            type: "",
            user_id: 0,
            start_date: "",
            end_date: ""
        };
        setFilters(filter);
        const updatedDatatableOptions = {
            ...datatableOptions,
            draw: 1,
            page: 1,
            sizePerPage: 10,
            searchText: "",
            sortField: "end_date",
            sortOrder: "asc",
        };
        setDatatableOptions(updatedDatatableOptions);
        setDataList([]);
        setLoading(true);
    };


    // Filters -------------------------------------------------
    const handleFiltersChange = (obj: any) => {
        setFilters((state: any) => ({ ...state, ...obj }));
    };

    const handleApplyFilters = () => {
        setDataList([]);
        setLoading(true);
        setOpenFilter(false);
    };
    // Filters -------------------------------------------------

    useEffect(() => {
        if (loading === true) {
            loadingDatatable();
        }
    }, [loading]);

    const loadingDatatable = async () => {
        try {
            const res = (await alertService.getUserCompanyReminders({
                ...getDataTableQuery(datatableOptions, columns),
                filters,
            })) as any;

            if (res.data.status === "success") {
                setDataList(res.data.data?.original?.data);

                const updatedDatatableOptions = {
                    ...datatableOptions,
                    recordsTotal: res.data.data?.original?.recordsTotal,
                    recordsFiltered: res.data.data?.original?.recordsFiltered,
                };
                setDatatableOptions(updatedDatatableOptions);
                setLoading(false);
            } else {
                setDataList([]);

                const updatedDatatableOptions = {
                    ...datatableOptions,
                    recordsTotal: 0,
                    recordsFiltered: 0,
                };
                setDatatableOptions(updatedDatatableOptions);
                setLoading(false);
            }
        } catch (err) {
            setDataList([]);

            const updatedDatatableOptions = {
                ...datatableOptions,
                recordsTotal: 0,
                recordsFiltered: 0,
            };
            setDatatableOptions(updatedDatatableOptions);
            setLoading(false);
        }
    };
    return (
        <React.Fragment>
            <div className="d-sm-flex flex-wrap">
                {!props.hideHeading &&
                    <CardTitle className="mb-3">
                       {t('Expiry reminder')}
                    </CardTitle>
                }
                <Drawer
                    title="Filters"
                    open={openFilter}
                    onClose={() => setOpenFilter((state) => !state)}
                    onReset={clearFilter}
                    footer={
                        <Button color="primary" style={{ margin: "16px" }} onClick={handleApplyFilters}>
                            {t("Apply")}
                        </Button>
                    }
                >
                    <ExpiryFilters
                        filters={filters}
                        onApply={() => { }}
                        onClear={() => { }}
                        onChange={(obj: any) => handleFiltersChange(obj)}
                    />
                </Drawer>
            </div>
            <DataTable
                columns={columns}
                products={dataList}
                datatableOptions={datatableOptions}
                onTableChange={onTableChange}
                bordered={false}
                clearFilter={clearFilter}
                loadingListData={loading}
                settingTableName="reminder_table"
                slug="all-reminders"
                inputFocus={false}
                customButtons={
                    userProfile.IS_INTERNAL ? (
                        <Button outline color="light" onClick={() => setOpenFilter((state) => !state)}>
                            {filters.is_filtered === false ? (
                                <span className="align-middle material-symbols-outlined fw-light fs-4">filter_alt</span>
                            ) : (
                                <span className="align-middle material-symbols-outlined fw-light fs-4">filter_alt_off</span>
                            )}
                        </Button>
                    ) : null
                }
            />

        </React.Fragment>
    );
};

export default ExpiryReminder;
