import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormFeedback, Label } from 'reactstrap';
// @ts-ignore
import CustomEditor from "ckeditor5-custom-build/build/ckeditor";
// @ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { ckEditorConfiguration, ckEditorI18 } from "../../../helpers/functions_helper";
import "ckeditor5-custom-build/build/translations/fr";
import "ckeditor5-custom-build/build/translations/nl";

interface TextEditorType {
    label?: string;
    id: string;
    name: string;
    value: any;
    className?: any;
    onChange: any;
    onBlur?: any;
    required?: boolean;
    loading?: boolean;
    touched?: any;
    error?: any;
    config?: any;
}

const TextEditor: FC<TextEditorType> = ({ label, id, name, value, className, onChange, onBlur, required, loading, touched = false, error, config }) => {
    const { t } = useTranslation();
    console.log('textarea =>', touched, error);

    return (
        <div className='text-editor'>
            {label ?
                <Label className="form-label">
                    {t(label)}
                    {required ? <span className="text-warning is_required">&nbsp; *</span> : null}
                </Label>
                : null
            }
            <div className={`${error && touched ? "isInvalid" : ""}`}>
                <CKEditor
                    key={ckEditorI18()}
                    editor={CustomEditor}
                    config={{ ...ckEditorConfiguration, language: ckEditorI18() }}
                    data={value}
                    onChange={onChange}
                    id={id}
                    name={name}
                    onBlur={onBlur}
                />
            </div>
            <div>
                <FormFeedback type="invalid">
                    {label && error?.indexOf(label) > -1 ? t(label) + ' ' + t(error.replace(label + ' ', '')) : t(error)}
                </FormFeedback>
            </div>
        </div>
    )
}

export default TextEditor;