import { takeEvery, call, put } from "redux-saga/effects";
import taskPriorityService from "../../../services/masters.task.priority.service";
import { AnyAction } from "redux";

// TaskPriority Redux States
import {
  GET_TASK_PRIORITY,
  GET_TASK_PRIORITY_LIST,
  CREATE_TASK_PRIORITY,
  UPDATE_TASK_PRIORITY,
  DELETE_TASK_PRIORITY,
} from "./actionTypes";

import { 
    getTaskPrioritySuccess, 
    getTaskPriorityError,
    
    getTaskPriorityListSuccess,
    getTaskPriorityListError,

    createTaskPrioritySuccess,
    createTaskPriorityError,

    updateTaskPrioritySuccess,
    updateTaskPriorityError,

    deleteTaskPrioritySuccess,
    deleteTaskPriorityError,
} from "./actions";

function* getTaskPriority({ payload: { taskpriority, history } }: AnyAction): any {
  try {
    const response = yield call(taskPriorityService.getTaskPriority, taskpriority);
    if (response.data && response.data.status === "success") {
      yield put(getTaskPrioritySuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getTaskPriorityError(response.data));
    } else {
      yield put(getTaskPriorityError(response));
    }
  } catch (error: any) {
    yield put(getTaskPriorityError(error));
  }
}

function* getTaskPriorityList({ payload: { query, history } }: AnyAction): any {
  try {
    const response = yield call(taskPriorityService.getTaskPriorityList, query);
    if (response.data && response.data.status === "success") {
      yield put(getTaskPriorityListSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getTaskPriorityListError(response.data));
    } else {
      yield put(getTaskPriorityListError(response));
    }
  } catch (error: any) {
    yield put(getTaskPriorityListError(error));
  }
}

function* createTaskPriority({ payload: { taskpriority, history } }: AnyAction): any {
  try {
    const response = yield call(taskPriorityService.createTaskPriority, taskpriority);
    if (response.data && response.data.status === "success") {
      yield put(createTaskPrioritySuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(createTaskPriorityError(response.data));
    } else {
      yield put(createTaskPriorityError(response));
    }
  } catch (error: any) {
    yield put(createTaskPriorityError(error));
  }
}

function* updateTaskPriority({ payload: { taskpriority, history } }: AnyAction): any {
  try {
    const response = yield call(taskPriorityService.updateTaskPriority, taskpriority);
    if (response.data && response.data.status === "success") {
      yield put(updateTaskPrioritySuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(updateTaskPriorityError(response.data));
    } else {
      yield put(updateTaskPriorityError(response));
    }
  } catch (error: any) {
    yield put(updateTaskPriorityError(error));
  }
}

function* deleteTaskPriority({ payload: { taskpriority, history } }: AnyAction): any {
  try {
    const response = yield call(taskPriorityService.deleteTaskPriority, taskpriority);
    if (response.data && response.data.status === "success") {
      yield put(deleteTaskPrioritySuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(deleteTaskPriorityError(response.data));
    } else {
      yield put(deleteTaskPriorityError(response));
    }
  } catch (error: any) {
    yield put(deleteTaskPriorityError(error));
  }
}

function* userFunctionSaga(): any {
  yield takeEvery(GET_TASK_PRIORITY, getTaskPriority);
  yield takeEvery(GET_TASK_PRIORITY_LIST, getTaskPriorityList);
  yield takeEvery(CREATE_TASK_PRIORITY, createTaskPriority);
  yield takeEvery(UPDATE_TASK_PRIORITY, updateTaskPriority);
  yield takeEvery(DELETE_TASK_PRIORITY, deleteTaskPriority);
}

export default userFunctionSaga;
