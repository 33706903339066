import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class Service {

    async create(data: any) {
        const response = await axios
            .post(
                SERVER_URL + url.CREATE_DOCUMENT_TYPE,
                data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async update(data: any) {
        const response = await axios
            .put(
                SERVER_URL + url.UPDATE_DOCUMENT_TYPE,
                data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async delete(id: any) {
        const response = await axios
            .delete(
                SERVER_URL + url.DELETE_DOCUMENT_TYPE + '/' + id,
                {
                    headers: getAxiosConfigWithToken()
                }
            );
        return response.data;
    }

    async getAll(data: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_All_DOCUMENT_TYPE,
                data,
                {
                    headers: getAxiosConfigWithToken(),
                }
            ).then(function (response: any) {
                return response;
            })
            .catch(function (error: any) {
                return error;
            });
        return response;
    }


    async getAllList(query: string) {
        const response = await axios
            .get(SERVER_URL + url.GET_All_DOCUMENT_TYPE_LIST + query,
                {
                    headers: getAxiosConfigWithToken(),
                }
            ).then(function (response: any) {
                return response;
            })
            .catch(function (error: any) {
                return error;
            });
        return response;
    }




    async get(id: number) {
        const response = await axios
            .get(
                SERVER_URL + url.GET_DOCUMENT_TYPE + '/' + id,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

}

export default new Service();