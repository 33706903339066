import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  Label,
  Input,
  Button,
  FormFeedback,
  Alert,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import labelPlugin from "flatpickr/dist/plugins/labelPlugin/labelPlugin";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation, Trans } from "react-i18next";

// Actions
import { TimeSheetsAction } from "../../store/actions";
import { datePickerI18 } from "../../helpers/functions_helper";

const TimeSheetForm = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let timeSpentData = props.timeSpentData;

  let { getTimeSheetFormData, getTimeSheetFormErrorData, loading } = useSelector((state: any) => ({
    getTimeSheetFormData: state.TimeSheets.GET_TIME_SHEET_FORM_SUCCESS,
    getTimeSheetFormErrorData: state.TimeSheets.GET_TIME_SHEET_FORM_ERROR,
    loading: state.TimeSheets.loading,
  }));

  useEffect(() => {
    if (timeSpentData.is_new === false) {
      const module_id = timeSpentData.module_id;
      const module_main_id = timeSpentData.module_main_id;
      const module_sub_id = timeSpentData.module_sub_id;
      dispatch(
        TimeSheetsAction.getTimeSheetForm(
          { module_id, module_main_id, module_sub_id },
          props.history
        )
      );
    }
  }, []);

  const [intialData, setIntialData] = useState({
    module_id: timeSpentData.module_id,
    module_main_id: timeSpentData.module_main_id,
    module_sub_id: timeSpentData.module_sub_id,
    main_id: timeSpentData.main_id,
    title: timeSpentData.title,
    company: timeSpentData.company,
    project: timeSpentData.project,
    contact_person: timeSpentData.contact_person,
    responsible_person: timeSpentData.responsible_person,
    fees_type: timeSpentData.fees_type,
    purchase_order_number: timeSpentData.purchase_order_number,
    fees_amount: timeSpentData.fees_amount,
    expense: timeSpentData.expense,
    is_sensitive: timeSpentData.is_sensitive,
    start_date: moment().format("YYYY-MM-DD 00:00:00"),
    start_time: "",
    end_time: "",
    time_spent: "",
  });

  const [totalTimeSpent, setTotalTimeSpent] = useState("");
  useEffect(() => {
    if (getTimeSheetFormData && getTimeSheetFormData.data[0]) {
      let timeSpentCalc = 0;
      getTimeSheetFormData.data.map((data: any) => {
        const totalMin = moment
          .duration(moment(data.time_spent, "HH:mm:ss").format("HH:mm"))
          .asMinutes();
        timeSpentCalc += Number(totalMin);

        const totalHoursMin = Math.floor(timeSpentCalc / 60) + ":" + (timeSpentCalc % 60);
        setTotalTimeSpent(totalHoursMin);
      });

      dispatch(TimeSheetsAction.getTimeSheetFormSuccess(""));
    }

    if (getTimeSheetFormErrorData) {
      setTotalTimeSpent("");
      dispatch(TimeSheetsAction.getTimeSheetFormError(""));
    }
  }, [getTimeSheetFormData, getTimeSheetFormErrorData]);

  const [calcTimeSpentEnabled, setCalcTimeSpentEnabled] = useState(false);
  useEffect(() => {
    if (intialData.start_time == "" && intialData.end_time == "") {
      setCalcTimeSpentEnabled(false);
    } else {
      setCalcTimeSpentEnabled(true);

      let start_time = "00:00:00";
      let end_time = "00:00:00";
      if (intialData.start_time != "" && intialData.start_time != "NaN:NaN") {
        start_time = intialData.start_time;
      }
      if (intialData.end_time != "" && intialData.end_time != "NaN:NaN") {
        end_time = intialData.end_time;
      }

      let startTime = moment(start_time, "h:mm");
      let endTime = moment(end_time, "h:mm");

      let duration = moment.duration(endTime.diff(startTime));
      let hours = Math.trunc(duration.asHours());
      let minutes = Number(duration.asMinutes()) % 60;

      handleChange("time_spent", hours + ":" + minutes);
    }
  }, [intialData.start_time, intialData.end_time]);

  useEffect(() => {
    if (intialData.start_time != "" && intialData.start_time != null) {
      if (intialData.end_time == "" || intialData.end_time == null) {
        setIntialData((prevData: any) => ({
          ...prevData,
          end_time: intialData.start_time,
        }));
      }
    }
    if (intialData.end_time != "" && intialData.end_time != null) {
      if (intialData.start_time == "" || intialData.start_time == null) {
        setIntialData((prevData: any) => ({
          ...prevData,
          start_time: "00:00:00",
        }));
      }
    }
  }, [intialData.start_time, intialData.end_time]);

  const handleChange = (fieldName: string, fieldValue: string) => {
    setIntialData({ ...intialData, [fieldName]: fieldValue });
  };

  Yup.setLocale({
    mixed: {
      required: "${label} " + t("field is required."),
      notOneOf: "${label} " + t("can not be same."),
    },
    string: {
      min: "${label} " + t("must be at least") + " ${min} " + t("characters."),
      max: "${label} " + t("may not be greater than") + " ${max} " + t("characters."),
    },
  });
  const validationSchema = Yup.object().shape({
    title: Yup.string().label(t("Timesheet description")).required().min(2).max(200),
    start_date: Yup.string().label(t("Start date")).required(),
    start_time: Yup.string().label(t("Start time")).nullable(),
    end_time: Yup.string()
      .label(t("End time"))
      .nullable()
      .when(["start_time"], {
        is: (start_time: string) => start_time != "" && start_time != null,
        then: Yup.string()
          .label(t("End time"))
          .notOneOf([Yup.ref("start_time")])
          .required(),
      }),
    time_spent: Yup.string().label(t("Time spent")).required(),
  });

  const handleSubmit = (values: {
    module_id: string;
    module_main_id: string;
    module_sub_id: string;
    main_id: string;
    title: string;
    company: string;
    project: string;
    contact_person: string;
    responsible_person: string;
    fees_type: string;
    purchase_order_number: string;
    fees_amount: number;
    expense: number;
    is_sensitive: number;
    start_date: string;
    start_time: string;
    end_time: string;
    time_spent: string;
  }) => {
    props.onSubmitClick(values);
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: intialData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    if (!validation.isSubmitting) return;
    if (Object.keys(validation.errors).length > 0) {
      document
        .getElementById(Object.keys(validation.errors)[0])
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
      document.getElementById(Object.keys(validation.errors)[0])?.focus({ preventScroll: true });
    }
  }, [validation.isSubmitting, validation.errors]);

  return (
    <React.Fragment>
      <Modal
        size="md"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">{t("Time sheet")}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label="Close"
          ></button>
        </div>

        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          autoComplete="off"
        >
          <ModalBody>
            <Input id="module_id" name="module_id" type="hidden" value={intialData.module_id} />
            <Input
              id="module_main_id"
              name="module_main_id"
              type="hidden"
              value={intialData.module_main_id}
            />
            <Input
              id="module_sub_id"
              name="module_sub_id"
              type="hidden"
              value={intialData.module_sub_id}
            />

            <Input id="company" name="company" type="hidden" value={intialData.company} />

            <Input id="project" name="project" type="hidden" value={intialData.project} />

            <Input
              id="contact_person"
              name="contact_person"
              type="hidden"
              value={intialData.contact_person}
            />

            <Input
              id="responsible_person"
              name="responsible_person"
              type="hidden"
              value={intialData.responsible_person}
            />

            <Input id="fees_type" name="fees_type" type="hidden" value={intialData.fees_type} />

            <Input
              id="purchase_order_number"
              name="purchase_order_number"
              type="hidden"
              value={intialData.purchase_order_number}
            />

            <Input
              id="fees_amount"
              name="fees_amount"
              type="hidden"
              value={intialData.fees_amount}
            />

            <Input id="expense" name="expense" type="hidden" value={intialData.expense} />

            <Row>
              {totalTimeSpent ? (
                <>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">{t("Total time spent")} | HH:MM</Label>
                      <Input
                        id="total_time_spent"
                        name="total_time_spent"
                        type="text"
                        value={totalTimeSpent}
                        readOnly={true}
                      />
                    </div>
                  </Col>

                  <Col className="col-12">
                    <hr />
                  </Col>
                </>
              ) : (
                ""
              )}

              <Col className="col-12">
                <div className="mb-3">
                  <Label className="form-label">
                    {t("Timesheet description")} <span className="text-warning is_required">*</span>
                  </Label>
                  <Input
                    id="title"
                    name="title"
                    placeholder={t("Timesheet description")}
                    maxLength={200}
                    value={intialData.title}
                    onChange={(e) => handleChange("title", e.target.value)}
                    invalid={validation.touched.title && validation.errors.title ? true : false}
                  />
                  {validation.touched.title && validation.errors.title ? (
                    <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col className="col-12">
                <div className="mb-3">
                  <Label className="form-label">
                    {t("Start date")} <span className="text-warning is_required">*</span>
                  </Label>
                  <div className="input-group is-invalid">
                    <Flatpickr
                      id="start_date"
                      name="start_date"
                      className="form-control d-block"
                      type="text"
                      value={intialData.start_date}
                      placeholder="DD/MM/YYYY"
                      options={{
                        altInput: true,
                        altFormat: "d/m/Y",
                        dateFormat: "Y-m-d H:i:S",
                        maxDate: new Date(),
                        allowInput: true,
                        plugins: [labelPlugin()],
                        onChange: function (selectedDates, dateStr, instance) {
                          handleChange("start_date", dateStr);
                        },
                        onClose: function (selectedDates, dateStr, instance) {
                          handleChange("start_date", dateStr);
                        },
                        locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                      }}
                    />
                    <span className="input-group-text">
                      <span className="align-middle material-symbols-outlined fw-light fs-4">
                        calendar_today
                      </span>
                    </span>
                  </div>
                  {validation.touched.start_date && validation.errors.start_date ? (
                    <FormFeedback type="invalid">{validation.errors.start_date}</FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col className="col-4">
                <div className="mb-3">
                  <Label className="form-label">{t("Start time")}</Label>
                  <div className="input-group is-invalid">
                    <Flatpickr
                      id="start_time"
                      name="start_time"
                      className="form-control d-block"
                      type="text"
                      value={intialData.start_time}
                      placeholder="HH:MM"
                      options={{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: "H:i:S",
                        time_24hr: true,
                        allowInput: true,
                        plugins: [labelPlugin()],
                        maxTime: intialData.end_time,
                        defaultHour: 8,
                        onChange: function (selectedDates, dateStr, instance) {
                          handleChange("start_time", dateStr);
                        },
                        onClose: function (selectedDates, dateStr, instance) {
                          handleChange("start_time", dateStr);
                        },
                        locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                      }}
                    />
                  </div>
                  {validation.touched.start_time && validation.errors.start_time ? (
                    <FormFeedback type="invalid">{validation.errors.start_time}</FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col className="col-4">
                <div className="mb-3">
                  <Label className="form-label">{t("End time")}</Label>
                  <div className="input-group is-invalid">
                    <Flatpickr
                      id="end_time"
                      name="end_time"
                      className="form-control d-block"
                      type="text"
                      value={intialData.end_time}
                      placeholder="HH:MM"
                      options={{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: "H:i:S",
                        time_24hr: true,
                        allowInput: true,
                        plugins: [labelPlugin()],
                        minTime: intialData.start_time,
                        defaultHour: 8,
                        onChange: function (selectedDates, dateStr, instance) {
                          handleChange("end_time", dateStr);
                        },
                        onClose: function (selectedDates, dateStr, instance) {
                          handleChange("end_time", dateStr);
                        },
                        locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                      }}
                    />
                  </div>
                  {validation.touched.end_time && validation.errors.end_time ? (
                    <FormFeedback type="invalid">{validation.errors.end_time}</FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col className="col-1">
                <div className="mb-3">
                  <Label className="form-label"></Label>
                  {t("OR")}
                </div>
              </Col>

              <Col className="col-3">
                <div className="mb-3">
                  <Label className="form-label">
                    {t("Time spent")} <span className="text-warning is_required">*</span>
                  </Label>
                  <div className="input-group is-invalid">
                    <Flatpickr
                      id="time_spent"
                      name="time_spent"
                      className="form-control d-block"
                      type="text"
                      value={intialData.time_spent}
                      placeholder="HH:MM"
                      disabled={calcTimeSpentEnabled}
                      options={{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: "H:i:S",
                        time_24hr: true,
                        allowInput: true,
                        plugins: [labelPlugin()],
                        defaultHour: 0,
                        defaultMinute: 0,
                        onChange: function (selectedDates, dateStr, instance) {
                          handleChange("time_spent", dateStr);
                        },
                        onClose: function (selectedDates, dateStr, instance) {
                          handleChange("time_spent", dateStr);
                        },
                        locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                      }}
                    />
                  </div>
                  {validation.touched.time_spent && validation.errors.time_spent ? (
                    <FormFeedback type="invalid">{validation.errors.time_spent}</FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" className="btn-block" type="submit" disabled={loading}>
              {loading ? (
                <span>
                  <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                    hourglass_empty
                  </span>
                  <span>{t("Please wait...")}</span>
                </span>
              ) : (
                <span>{t("Submit")}</span>
              )}
            </Button>
            <Button
              color="outline-danger"
              className="btn-block"
              type="reset"
              onClick={props.onCloseClick}
              disabled={loading}
            >
              {t("Cancel")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default TimeSheetForm;
