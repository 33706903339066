import axios from "axios";
import { getAxiosConfigWithToken,getAxiosConfigFileUploadWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class Service {

    async create(data: any) {
        const response = await axios
            .post(
                SERVER_URL + url.ISSUE_REPORTING.CREATE,
                data,
                {
                    headers: getAxiosConfigFileUploadWithToken()
                }
            );

        return response.data;
    }

    async update(data: any) {
        const response = await axios
            .put(
                SERVER_URL + url.ISSUE_REPORTING.UPDATE,
                data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async delete(id: any) {
        const response = await axios
            .delete(
                SERVER_URL + url.ISSUE_REPORTING.DELETE + '/' + id,
                {
                    headers: getAxiosConfigWithToken()
                }
            );
        return response.data;
    }

    async getAll(data: any, company_id?: number) {
        const query = company_id ? '?company_id=' + company_id : '';
        const response = await axios
            .post(SERVER_URL + url.ISSUE_REPORTING.GET_ALL + query,
                data,
                {
                    headers: getAxiosConfigWithToken(),
                }
            ).then(function (response: any) {
                return response;
            })
            .catch(function (error: any) {
                return error;
            });
        return response;
    }

    async get(id: number, incident_id: string) {
        const response = await axios
            .get(
                SERVER_URL + url.ISSUE_REPORTING.GET + '/' + id + '/' + incident_id,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }



    async getUser() {
        const response = await axios
            .get(
                SERVER_URL + url.ISSUE_REPORTING.GET_USER,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }


    async postComment(data:any) {
        const response = await axios
            .post(
                SERVER_URL + url.ISSUE_REPORTING.POST_COMMENT,
                data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );
        return response.data;
    }


    async getComment(id:string) {
        const response = await axios
            .get(
                SERVER_URL + url.ISSUE_REPORTING.GET_COMMENTS+id,
                {
                    headers: getAxiosConfigWithToken()
                }
            );
        return response.data;
    }

    async getThumbnail(id:string) {
        const response = await axios
            .get(
                SERVER_URL + url.ISSUE_REPORTING.GET_TH+id,
                {
                    headers: getAxiosConfigWithToken()
                }
            );
        return response.data;
    }

    async getFile(id:string) {
        const response = await axios
            .get(
                SERVER_URL + url.ISSUE_REPORTING.GET_FILE+id,
                {
                    headers: getAxiosConfigWithToken(),
                    responseType: 'blob',
                },
                
            );
        return response.data;
    }

}

export default new Service();