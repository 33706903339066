import React, { Component } from "react";
import logoDark from "../../assets/images/logos/tc-logo-red-block-with-name.png";
class Loader extends Component {
  render() {
    return (
      <React.Fragment>
        <div id="ajax_loader">
            <div id="ajax_loader_status">
                <div className="loader_body">
                    <img src={logoDark} height="60" />
                    <br/><br/><br/>
                    <div className="loader"><span className="dot"></span><div className="dots"><span></span><span></span><span></span></div></div>
                </div>
            </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Loader;
