import {
  GET_TASK,
  GET_TASK_SUCCESS,
  GET_TASK_ERROR,

  GET_TASKS_LIST,
  GET_TASKS_LIST_SUCCESS,
  GET_TASKS_LIST_ERROR,

  GET_ARCHIVE_TASKS_LIST,
  GET_ARCHIVE_TASKS_LIST_SUCCESS,
  GET_ARCHIVE_TASKS_LIST_ERROR,

  CREATE_TASK,
  CREATE_TASK_SUCCESS,
  CREATE_TASK_ERROR,

  UPDATE_TASK,
  UPDATE_TASK_SUCCESS,
  UPDATE_TASK_ERROR,

  DELETE_TASK,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_ERROR,
} from "./actionTypes"

const initialState = {
  GET_TASK_SUCCESS: "",
  GET_TASK_ERROR: "",
  GET_TASKS_LIST_SUCCESS: "",
  GET_TASKS_LIST_ERROR: "",
  GET_ARCHIVE_TASKS_LIST_SUCCESS: "",
  GET_ARCHIVE_TASKS_LIST_ERROR: "",
  CREATE_TASK_SUCCESS: "",
  CREATE_TASK_ERROR: "",
  UPDATE_TASK: "",
  UPDATE_TASK_SUCCESS: "",
  UPDATE_TASK_ERROR: "",
  DELETE_TASK: "",
  DELETE_TASK_SUCCESS: "",
  DELETE_TASK_ERROR: "",
  loading: false,
}

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    // ---------------------------------------------GET_TASK
    case GET_TASK:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_TASK_SUCCESS:
      state = {
        ...state,
        GET_TASK_SUCCESS: action.payload,
        loading: false,
      }
      break

    case GET_TASK_ERROR:
      state = {
        ...state,
        GET_TASK_ERROR: action.payload,
        loading: false
      }
      break

    // ---------------------------------------------GET_TASKS_LIST
    case GET_TASKS_LIST:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_TASKS_LIST_SUCCESS:
      state = {
        ...state,
        GET_TASKS_LIST_SUCCESS: action.payload,
        loading: false,
      }
      break

    case GET_TASKS_LIST_ERROR:
      state = {
        ...state,
        GET_TASKS_LIST_ERROR: action.payload,
        loading: false
      }
      break


       // ---------------------------------------------GET_ARCHIVE_TASKS_LIST
    case GET_ARCHIVE_TASKS_LIST:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_ARCHIVE_TASKS_LIST_SUCCESS:
      state = {
        ...state,
        GET_ARCHIVE_TASKS_LIST_SUCCESS: action.payload,
        loading: false,
      }
      break

    case GET_ARCHIVE_TASKS_LIST_ERROR:
      state = {
        ...state,
        GET_ARCHIVE_TASKS_LIST_ERROR: action.payload,
        loading: false
      }
      break

    // ---------------------------------------------CREATE_TASK
    case CREATE_TASK:
      state = {
        ...state,
        loading: true,
      }
      break

    case CREATE_TASK_SUCCESS:
      state = {
        ...state,
        CREATE_TASK_SUCCESS: action.payload,
        loading: false,
      }
      break

    case CREATE_TASK_ERROR:
      state = {
        ...state,
        CREATE_TASK_ERROR: action.payload,
        loading: false
      }
      break

    // ---------------------------------------------UPDATE_TASK
    case UPDATE_TASK:
      state = {
        ...state,
        loading: true,
      }
      break

    case UPDATE_TASK_SUCCESS:
      state = {
        ...state,
        UPDATE_TASK_SUCCESS: action.payload,
        loading: false,
      }
      break

    case UPDATE_TASK_ERROR:
      state = {
        ...state,
        UPDATE_TASK_ERROR: action.payload,
        loading: false
      }
      break

    // ---------------------------------------------DELETE_TASK
    case DELETE_TASK:
      state = {
        ...state,
        loading: true,
      }
      break

    case DELETE_TASK_SUCCESS:
      state = {
        ...state,
        DELETE_TASK_SUCCESS: action.payload,
        loading: false,
      }
      break

    case DELETE_TASK_ERROR:
      state = {
        ...state,
        DELETE_TASK_ERROR: action.payload,
        loading: false
      }
      break

    // ---------------------------------------------DEFAULT
    default:
      state = { ...state }
      break
  }
  return state
}

export default reducer
