import React from 'react'
import { Input, Label } from 'reactstrap';
import Autocomplete from "react-google-autocomplete";

const Textfield = (props: any) => {
    let { label, name, placeholder, value, handleChange, disabled, answer_only, labelClick, autoFocusBol, handleKeyDown, maxLength, isDisabled, field_width_input, title, enable_google, handleAddressChange, handleChangeGoogle, space_input, space_input_value } = props;
    return (
        <div className="mt-3">
            <Label for={name} onClick={labelClick} className={answer_only ? "pointer questionview" : "questionview"}>
                {label}
                {!!title && (
                    <span className='tooltip-txt'>
                        < i className="fa-regular fas fa-info-circle"></i><span className="tooltiptext">{title}</span>
                    </span>
                )}
            </Label>
            {enable_google ?
                <div className={(space_input && space_input_value) ? `${space_input}-${space_input_value}` : ''}>
                    <Autocomplete
                        id={name}
                        name={name}
                        className={field_width_input ? `w-${field_width_input} form-control` : 'form-control'}
                        value={value}
                        onChange={handleChange}
                        apiKey={process.env.REACT_APP_GOOGLE_ADDRESS_API_KEY!}
                        options={{
                            types: ["geocode", "establishment"],
                        }}
                        onPlaceSelected={(data: any) => {
                            handleAddressChange(data);
                        }}
                        disabled={isDisabled}
                    />
                </div>

                :
                <div className={(space_input && space_input_value) ? `${space_input}-${space_input_value}` : ''}>
                    <Input
                        type="text"
                        name={name}
                        className={field_width_input ? `w-${field_width_input} form-control` : 'form-control'}
                        id="basicpill-lastname-input2"
                        placeholder={placeholder}
                        value={value ? value : ''}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        autoFocus={autoFocusBol ? true : false}
                        maxLength={maxLength ? maxLength : ''}
                        disabled={isDisabled}
                    />
                </div>
            }
        </div>
    )
}

export default Textfield