export const GET_TASK_STATUS = "GET_TASK_STATUS"
export const GET_TASK_STATUS_SUCCESS = "GET_TASK_STATUS_SUCCESS"
export const GET_TASK_STATUS_ERROR = "GET_TASK_STATUS_ERROR"

export const GET_TASK_STATUS_LIST = "GET_TASK_STATUS_LIST"
export const GET_TASK_STATUS_LIST_SUCCESS = "GET_TASK_STATUS_LIST_SUCCESS"
export const GET_TASK_STATUS_LIST_ERROR = "GET_TASK_STATUS_LIST_ERROR"
  
export const CREATE_TASK_STATUS = "CREATE_TASK_STATUS"
export const CREATE_TASK_STATUS_SUCCESS = "CREATE_TASK_STATUS_SUCCESS"
export const CREATE_TASK_STATUS_ERROR = "CREATE_TASK_STATUS_ERROR"

export const UPDATE_TASK_STATUS = "UPDATE_TASK_STATUS"
export const UPDATE_TASK_STATUS_SUCCESS = "UPDATE_TASK_STATUS_SUCCESS"
export const UPDATE_TASK_STATUS_ERROR = "UPDATE_TASK_STATUS_ERROR"

export const DELETE_TASK_STATUS = "DELETE_TASK_STATUS"
export const DELETE_TASK_STATUS_SUCCESS = "DELETE_TASK_STATUS_SUCCESS"
export const DELETE_TASK_STATUS_ERROR = "DELETE_TASK_STATUS_ERROR"