import React from "react";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../../components/Layout/Breadcrumb";
import SplitPayrollForm from "./SplitPayrollForm";

import ScrollUp from "../../components/ScrollUpDown/ScrollUp";
import ScrollDown from "../../components/ScrollUpDown/ScrollDown";

const CreateSplitPayroll = (props: any) => {
  const { t } = useTranslation();
  document.title = t("Create split payroll") + " | " + process.env.REACT_APP_NAME;

  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "/split-payroll/list/all",
      label: t("Split payroll"),
      active: false,
    },
    {
      url: "#",
      label: t("Create split payroll"),
      active: true,
    },
  ];

  return (
    <React.Fragment>
      <ScrollUp />
      <ScrollDown top={"100px"} />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={t("Create split payroll")}
            breadcrumbItems={breadcrumbItems}
          />

          <SplitPayrollForm />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateSplitPayroll;
