import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import SelectTwo, { Props } from 'react-select';
import { Col, Label, Row } from 'reactstrap';
import Avatar from '../../Avatar';
import ErrorMessage from '../ErrorMessage';
import Input from '../Input';

interface SelectProps extends Props {
    id?: string;
    name?: string;
    label?: string;
    required?: boolean;
    className?: string;
    loading?: boolean;
    placeholder?: string;
    touched?: any;
    error?: any;
    showAvatar?: boolean
}

const Select: FC<SelectProps> = ({ id, name, label, required, loading, placeholder, touched, error, showAvatar = false, ...props }) => {

    const { t } = useTranslation();

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            borderColor: error && touched ? "#f46a6a" : "#ced4da",
            zIndex: 0
        }),
        menu: (styles: any, state: any) => {
            return {
                ...styles,
                zIndex: 9
            };
        },
        option: (styles: any, state: any) => {
            return {
                ...styles,
                borderBottom: '1px solid #F4F4F4',
                zIndex: 9
            };
        },
    }

    // Example for formatOptionLabel
    const formatOptionLabel = ({ value, label }: any) => (
        showAvatar ?
            <Row>
                <Col className='d-flex align-items-center'>
                    <Avatar size='xs' name={label} />
                    <div className='mx-1 align-middle'>{label}</div>
                </Col>
            </Row>
            : label
    );

    return (
        <>
            {label ?
                <Label className="form-label" for={id}>
                    {t(label)}
                    {required ? <span className="text-warning is_required">&nbsp; *</span> : null}
                </Label>
                : null
            }
            {loading ?
                <Input id={id} disabled value={t('Loading...')} /> :
                <SelectTwo id={id} {...props} styles={customStyles} isLoading={loading || props.isLoading} isDisabled={loading || props.isDisabled} escapeClearsValue={true} placeholder={placeholder ? t(placeholder) : t("Select...")} />
            }
            {error && touched ? (
                <ErrorMessage error={error} label={label} touched={touched}/>
            ) : null}
            {/* {label && error?.indexOf(label) > -1 ?
                (touched && <ErrorMessage error={t(label) + ' ' + t(error.replace(label + ' ', ''))} touched={touched} label={label}/>)
                : (touched && <ErrorMessage error={error} />)

            } */}
            {/* {touched && <ErrorMessage error={error} />} */}
        </>
    )
}

export default React.memo(Select);