import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    Form,
    Label,
    Button,
    FormFeedback,
    Alert,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import commonService from "../../../services/common.service";
import { useDispatch, useSelector } from "react-redux";
import { CommonAction } from "../../../store/actions";
import projectsService from "../../../services/projects.service";
import Input from "../../../components/Form/Input";
import Select from "../../../components/Form/Select/Select";
import { useTranslation } from "react-i18next";

type DocumentType = {
    id?: number | null;
    project_id?: string | null;
    company_id: number | null;
    account_id: number | null;
    partner_id: number | null;
    contact_person_id: number | null;
    name: string | null;
};


const ModalForm = (props: any) => {
    const {t}= useTranslation();
    let vatData = props.vatData;
    const dispatch = useDispatch();

    const {
        getUserOptionsList,
        getCompanyOptionsList,
        loading
    } = useSelector((state: any) => ({
        getUserOptionsList: state.Common.GET_USER_OPTIONS_LIST_SUCCESS,
        getCompanyOptionsList: state.Common.GET_COMPANY_OPTIONS_LIST_SUCCESS,
        loading: state.Tasks.loading,
    }))

    const [intialData, setIntialData] = useState<DocumentType>({
        company_id: null,
        account_id: null,
        partner_id: null,
        contact_person_id: null,
        name: null
    });

    const [accounts, setAccounts] = useState<Array<any>>([]);
    const [partnersOptions, setPartnerOptions] = useState<Array<any>>([]);
    const [companyOptions, setCompnayOptions] = useState<Array<any>>([]);
    const [contactPersonOption, setContactPersonOption] = useState<Array<any>>([]);
    const [query, setQuery] = useState("");

    const validationSchema = Yup.object().shape({
        name: Yup.string().nullable().required("Project name field is required."),
        company_id: Yup.number().nullable().required("Company field is required."),
        account_id: Yup.number().nullable().required("Account field is required."),
        partner_id: Yup.number().nullable().required("Partner field is required."),
        contact_person_id: Yup.number().nullable().required("Contact person field is required."),
    });


    const handleChange = (fieldName: string, fieldValue: any) => {
        setIntialData({ ...intialData, [fieldName]: fieldValue });
    };

    const handleSubmit = (values: DocumentType, actions: any) => {
        props.onSubmitClick(values, actions);
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: intialData,
        validationSchema: validationSchema,
        onSubmit: (values, actions) => {
            handleSubmit(values, actions);
        },
    });

    const getSelectOptions = async (tableName: string, fieldName: string, otherField?: Array<string>) => {
        const response = await commonService.getSelectOptionsList(
            tableName,
            fieldName,
            query ? query : "",
            otherField
        );
        return response.data.data;
    };

    const getDependentSelectOptions = async (
        tableName: string,
        fieldName: string,
        whereField: string,
        whereID: number
    ) => {
        const response = await commonService.getDependentSelectOptionsList(
            tableName,
            fieldName,
            whereField,
            whereID,
            query ? query : ""
        );
        return response.data.data;
    };
    useEffect(() => {
        getDependentSelectOptions(
            "tbl_master_users",
            "first_name",
            "company",
            validation?.values?.company_id || 0
        ).then(function (options) {
            setContactPersonOption(options);
        });
    }, [validation?.values?.company_id]);

    useEffect(() => {
        getSelectOptions("tbl_master_account", "account_name").then(function (
            options
        ) {
            setAccounts(options);
        });
        if (!getUserOptionsList) dispatch(CommonAction.getUserOptionsList());
        if (!getCompanyOptionsList) dispatch(CommonAction.getCompanyOptionsList());
    }, []);

    const getProject = async (id: number) => {
        const res = await projectsService.get(id);
        if (res.status === "success") {
            setIntialData(res.data);
        }
    }
    useEffect(() => {
        if (vatData.account_id) {
            setIntialData(vatData);
        } else {
            getProject(vatData.project)
        }
    }, [vatData])

    useEffect(() => {
        setPartnerOptions(getUserOptionsList?.data?.filter((i: any) => i.function === 6))
    }, [getUserOptionsList])

    useEffect(() => {
        if (props.isTab) {
            setCompnayOptions(getCompanyOptionsList?.data?.filter((i: any) => i.value === props.isTab) || [])
        } else {
            setCompnayOptions(getCompanyOptionsList?.data || [])
        }

    }, [getCompanyOptionsList, props.isTab])

    useEffect(() => {
        // alert(JSON.stringify(validation))
        if (!validation.isSubmitting) return;
        if (Object.keys(validation.errors).length > 0) {
            document
                .getElementById(Object.keys(validation.errors)[0])
                ?.scrollIntoView({ behavior: "smooth", block: "center" });
            document
                .getElementById(Object.keys(validation.errors)[0])
                ?.focus({ preventScroll: true });
        }
    }, [validation.isSubmitting, validation.errors]);


    return (
        <React.Fragment>
            <Modal
                size="md"
                isOpen={props.show}
                toggle={props.onCloseClick}
                backdrop="static"
                keyboard={false}
                autoFocus={false}
            >
                <div className="modal-header">
                    <h5 className="modal-title">{props.modalTitle}</h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={props.onCloseClick}
                        aria-label="Close"
                    ></button>
                </div>

                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                    autoComplete="off"
                >
                    <ModalBody>
                        <Input
                            id="id"
                            name="id"
                            type="hidden"
                            value={validation.values.id || ""}
                            onChange={validation.handleChange}
                        />
                        <Input
                            id="project_id"
                            name="project_id"
                            type="hidden"
                            value={validation.values.project_id || ""}
                            onChange={validation.handleChange}
                        />


                        <Row>
                            <Col className="col-12">
                                <div className="mb-3">
                                    <Select
                                        id="company_id"
                                        name="company_id"
                                        label="Company"
                                        required
                                        className={validation.errors.company_id ? "is-invalid" : ""}
                                        options={companyOptions}
                                        //@ts-ignore
                                        //defaultValue={partnersOptions.find((o: any) => o.value === validation?.values?.partners)}
                                        value={companyOptions?.find((o: any) => validation?.values?.company_id === o.value)}
                                        isSearchable={true}
                                        // isDisabled={loadingCompany || isDisabled}
                                        isLoading={loading}
                                        onChange={(option: any) => {
                                            handleChange("company_id", option.value);

                                        }}
                                        error={validation.errors.company_id}
                                        touched={validation.touched.company_id}
                                    />
                                </div>
                            </Col>
                            <Col className="col-12">
                                <div className="mb-3">
                                    <Select
                                        id="contact_person_id"
                                        name="contact_person_id"
                                        label="Contact person"
                                        required
                                        className={validation.errors.contact_person_id ? "is-invalid" : ""}
                                        options={contactPersonOption}
                                        //@ts-ignore
                                        //defaultValue={partnersOptions.find((o: any) => o.value === validation?.values?.partners)}
                                        value={contactPersonOption?.find((o: any) => validation?.values?.contact_person_id === o.value)}
                                        isSearchable={true}
                                        // isDisabled={loadingCompany || isDisabled}
                                        isLoading={loading}
                                        onChange={(option: any) => {
                                            handleChange("contact_person_id", option.value);

                                        }}
                                        error={validation.errors.contact_person_id}
                                        touched={validation.touched.contact_person_id}
                                    />
                                </div>
                            </Col>
                            <Col className="col-12">
                                <div className="mb-3">
                                    <Input
                                        id="name"
                                        name="name"
                                        className="form-control"
                                        placeholder="Project name"
                                        label="Project name"
                                        required
                                        type="text"
                                        value={validation.values.name || ""}
                                        onBlur={validation.handleBlur}
                                        onChange={(e) =>
                                            handleChange("name", e.target.value)
                                        }
                                        invalid={
                                            validation.touched.name &&
                                                validation.errors.name
                                                ? true
                                                : false
                                        }
                                        error={validation.errors.name}
                                        touched={validation.touched.name}
                                    />
                                </div>
                            </Col>
                            <Col className="col-12">
                                <div className="mb-3">
                                    <Select
                                        id="partner_id"
                                        name="partner_id"
                                        label="Partner"
                                        required
                                        className={validation.errors.partner_id ? "is-invalid" : ""}
                                        options={partnersOptions}
                                        //@ts-ignore
                                        //defaultValue={partnersOptions.find((o: any) => o.value === validation?.values?.partners)}
                                        value={partnersOptions?.find((o: any) => validation?.values?.partner_id === o.value)}
                                        isSearchable={true}
                                        // isDisabled={loadingCompany || isDisabled}
                                        onChange={(option: any) => {
                                            handleChange("partner_id", option.value);

                                        }}
                                        error={validation.errors.partner_id}
                                        touched={validation.touched.partner_id}
                                    />
                                </div>
                            </Col>
                            <Col className="col-12">
                                <div className="mb-3">
                                    <Select
                                        id="account_id"
                                        name="account_id"
                                        label="Account"
                                        required
                                        className={validation.errors.account_id ? "is-invalid" : ""}
                                        options={accounts}
                                        //@ts-ignore
                                        //defaultValue={partnersOptions.find((o: any) => o.value === validation?.values?.partners)}
                                        value={accounts?.find((o: any) => validation?.values?.account_id === o.value)}
                                        isSearchable={true}
                                        // isDisabled={loadingCompany || isDisabled}
                                        onChange={(option: any) => {
                                            handleChange("account_id", option.value);

                                        }}
                                        error={validation.errors.account_id}
                                        touched={validation.touched.account_id}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            className="btn-block"
                            type="submit"
                            disabled={validation.isSubmitting}
                        >
                            {validation.isSubmitting ? (
                                <span>
                                    <i className="fa-solid fa-spinner fa-spin"></i>
                                    <span>&nbsp; {t("Please wait...")}</span>
                                </span>
                            ) : (
                                <span>{t("Submit")}</span>
                            )}
                        </Button>
                        <Button
                            color="danger"
                            className="btn-block"
                            type="reset"
                            onClick={props.onCloseClick}
                            disabled={props.loading}
                        >
                            {t("Cancel")}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </React.Fragment>
    );
};

export default ModalForm;
