import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class CompaniesService {
    async getCompany(DATA: any) {
        const response = await axios
            .get(SERVER_URL + url.GET_COMPANY + "/" + DATA.id + "/" + DATA.company_id,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getCompanyList(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_COMPANY_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getCompanyExport(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_COMPANY_EXPORT, DATA,
                {
                    headers: getAxiosConfigWithToken(),
                    responseType: "blob",
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }
    async getCompanyExportCSV(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_COMPANY_EXPORT_CSV, DATA,
                {
                    headers: getAxiosConfigWithToken(),
                    responseType: "blob",
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async createCompany(DATA: any) {
        const response = await axios
            .put(SERVER_URL + url.CREATE_COMPANY, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async updateCompany(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.UPDATE_COMPANY, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async deleteCompany(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.DELETE_COMPANY, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }
    async downloadCsf() {
        const response = await axios
            .get(SERVER_URL + url.DOWNLOAD_CSF,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getCsfCount() {
        const response = await axios
            .get(SERVER_URL + url.CSF_COUNT,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async companyDatapPerYear(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(SERVER_URL + url.GET_YEARDATA_PER_YEAR + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getCompaniesPerTask(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(
                SERVER_URL + url.GET_COMPANIES_PER_QUARTER + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async activeVsNonActive(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(SERVER_URL + url.ACTIVE_VS_NON_ACTIVE_COMPANY + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async companyVsArchieved(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(SERVER_URL + url.COMPANY_VS_ARCHIEVE_COMPANY + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getChartData(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(SERVER_URL + url.COMPANY_CHART_DATA + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }
    
    async CompanyVsEmployee(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(SERVER_URL + url.COMPANY_VS_EMPLOYEE + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }
}

export default new CompaniesService();