import React, { useEffect, useState } from "react";
import { Row, Col, Modal, ModalBody } from "reactstrap";
import MultiComments from "../../../components/Common/MultiComments";
import tasksService from "../../../services/tasks.service";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getUserProfile } from "../../../helpers/functions_helper";

const FlagModal = (props: any) => {
  const { t } = useTranslation();
  const { task, onCloseClick, show, success } = props;
  const userProfile = getUserProfile("all-tasks");
  const toastId = React.useRef(null) as any;

  const commentAttachmentIDs = [
    {
      value: task.preparer,
      label: t("Preparer"),
      isHidden: userProfile.IS_INTERNAL ? false : true,
    },
    {
      value: task.reviewer,
      label: t("Reviewer"),
      isHidden: task.sensitive ? true : userProfile.IS_INTERNAL ? false : true,
    },
    {
      value: task.file_manager,
      label: userProfile.IS_INTERNAL ? t("Responsible to sign off") : t("File manager"),
      isHidden: task.sensitive ? true : false,
    },
    {
      value: task.partner,
      label: t("Partner"),
      isHidden: userProfile.IS_INTERNAL ? false : true,
    },
    {
      value: task.assistant_administrative,
      label: t("Assistant administrative"),
      isHidden: task.sensitive ? true : userProfile.IS_INTERNAL ? false : true,
    },
    {
      value: task.contact_person,
      label: t("Contact person"),
      isHidden: task.sensitive ? true : false,
    },
    {
      value: task.responsible_person,
      label: t("Person concerned"),
      isHidden: task.sensitive ? true : false,
    },
  ];

  const multiCommentsSuccess = (isSuccess: boolean) => {
    if (isSuccess === true) {
      updateFlag();
    }
  };

  const updateFlag = async () => {
    toast.dismiss();
    toastId.current = toast(t("Updating flag..."), {
      autoClose: false,
      isLoading: true,
    });
    const postData = {
      id: task.pid,
      task_id: task.is_sub_task ? task.subtask_id : task.task_id,
      is_sub_task: task.is_sub_task,
    };
    const res = await tasksService.addFlag(postData);
    if (res.data.status === "success") {
      toast.update(toastId.current, {
        type: toast.TYPE.SUCCESS,
        render: t("Flag added successfully"),
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      success(task);
      onCloseClick();
    } else {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: t("Something went wrong."),
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={show}
        toggle={onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
        size="lg"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title">
            {t("Add flag")} - {task.title}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={onCloseClick}
            aria-label="Close"
          ></button>
        </div>
        <ModalBody>
          <Row>
            <Col>
              <MultiComments
                moduleData={{
                  module_app_id: task.is_sub_task ? 7 : 2,
                  module_main_id: task.pid,
                  module_sub_id: task.is_sub_task ? task.subtask_id : task.task_id,
                  section_id: 0,
                }}
                commentForIDs={commentAttachmentIDs}
                fetchData={true}
                isOpen={true}
                onSuccess={multiCommentsSuccess}
              />
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default FlagModal;
