export const GET_ACCESS_ROLE = "GET_ACCESS_ROLE"
export const GET_ACCESS_ROLE_SUCCESS = "GET_ACCESS_ROLES_SUCCESS"
export const GET_ACCESS_ROLE_ERROR = "GET_ACCESS_ROLES_ERROR"

export const GET_ACCESS_ROLES_LIST = "GET_ACCESS_ROLES_LIST"
export const GET_ACCESS_ROLES_LIST_SUCCESS = "GET_ACCESS_ROLES_LIST_SUCCESS"
export const GET_ACCESS_ROLES_LIST_ERROR = "GET_ACCESS_ROLES_LIST_ERROR"
  
export const CREATE_ACCESS_ROLE = "CREATE_ACCESS_ROLE"
export const CREATE_ACCESS_ROLE_SUCCESS = "CREATE_ACCESS_ROLE_SUCCESS"
export const CREATE_ACCESS_ROLE_ERROR = "CREATE_ACCESS_ROLE_ERROR"

export const UPDATE_ACCESS_ROLE = "UPDATE_ACCESS_ROLE"
export const UPDATE_ACCESS_ROLE_SUCCESS = "UPDATE_ACCESS_ROLE_SUCCESS"
export const UPDATE_ACCESS_ROLE_ERROR = "UPDATE_ACCESS_ROLE_ERROR"

export const DELETE_ACCESS_ROLE = "DELETE_ACCESS_ROLE"
export const DELETE_ACCESS_ROLE_SUCCESS = "DELETE_ACCESS_ROLE_SUCCESS"
export const DELETE_ACCESS_ROLE_ERROR = "DELETE_ACCESS_ROLE_ERROR"