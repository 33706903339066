import React, { useEffect, useMemo, useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import DataTable from '../../../components/Common/DataTable';
import DeleteModal from '../../../components/Common/DeleteModal';
import { getDataTableQuery, getUserProfile } from '../../../helpers/functions_helper';
import projectsService from '../../../services/projects.service';
import { dateFormat } from '../../../utils/formats';
import { renderRowHTML, subString } from '../../../utils/utils';
import { getAllNewsFeeds, deleteNewsFeed } from './helper';

const NewsFeedList: FC<NewsFeedListProps> = ({ loadingData = false }) => {
    const { t } = useTranslation();
    const { type } = useParams();
    const userObj = getUserProfile('news-feed');
    const [loadingListData, setLoadingListData] = useState<boolean>(false);
    const [deleteRec, setDeleteRec] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<NewsFeedType>();
    const [datatableOptions, setDatatableOptions] = useState({
        draw: 1,
        page: 1,
        recordsTotal: 0,
        recordsFiltered: 0,
        sizePerPage: 20,
        searchText: "",
        sortField: type ? "priority" : "id",
        sortOrder: type ? "Hight, Medium, Low" : "desc",
    });
    const [list, setList] = useState([]);

    const columns = useMemo(() => [
        {
            dataField: "DT_RowIndex",
            text: t("Sr. No."),
            sort: false,
            search: false,
        },
        {
            dataField: "id",
            text: "ID",
            sort: true,
            search: false,
            hidden: true,
        },
        {
            dataField: "news_feed_title",
            text: t("News Feed Title"),
            sort: true,
            search: true,
            classes: 'table-cell-text-wrap',
            formatter: (rowContent: any, row: any): any => (
                <Link to={`/news-feed/view/${row.id}/${row.news_feed_id}`}>
                    {subString(rowContent, 100)}
                </Link>
            )
        },
        {
            dataField: "description",
            text: t("Description"),
            sort: true,
            search: true,
            classes: 'table-cell-text-wrap',
            formatter: renderRowHTML,
        },
        {
            dataField: "attachment",
            text: t("Attachment"),
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => row?.attachment ? <img src={rowContent} height="50" /> : ""
        },
        {
            dataField: "hyperlink",
            text: t("Hyperlink"),
            sort: true,
            search: true,
            hidden: !!type,
            formatter: (rowContent: any, row: any): any => (
                rowContent ? <a href={rowContent} className="btn btn-sm btn-outline-primary" target='_blank'>
                    {t("View")}&nbsp;  <i className='fa fa-solid fa-external-link'></i>
                </a> : ''
            )
        },
        {
            dataField: "news_feed_creation_date",
            text: t("News feed creation date"),
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => dateFormat(rowContent)
        },
        {
            dataField: "priority",
            text: t("Priority"),
            sort: true,
            search: true,
            hidden: !(userObj.IS_SUPER_ADMIN || userObj.IS_PARTNER)
        },
        {
            dataField: "publication",
            text: t("Publication"),
            sort: true,
            search: true,
            hidden: !(userObj.IS_SUPER_ADMIN || userObj.IS_PARTNER)
        },
        {
            dataField: "sources",
            text: t("Sources"),
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => (
                rowContent ? <a href={rowContent} className="btn btn-sm btn-outline-primary" target='_blank'>
                    {t("View source")}&nbsp; <i className='fa fa-solid fa-external-link'></i>
                </a> : ''
            )
        },
        {
            dataField: "action",
            text: t("Action"),
            sort: false,
            search: false,
            hidden: !(userObj.CAN_EDIT || userObj.CAN_DELETE || userObj.CAN_DELETE_ALL || userObj.CAN_EDIT_ALL),
            width: 200,
            formatter: (rowContent: any, row: any): any => {
                return (
                    <div className="d-flex gap-2 text-end">
                        {((userObj.CAN_EDIT && row.created_by === userObj.USER_ID) || userObj.CAN_EDIT_ALL) &&
                            <Link
                                className="btn btn-pill btn-outline-primary btn-sm"
                                to={"/masters/news-feed/edit/" + row?.id}
                            >
                                <i className="fa-regular fa-pen-to-square"></i> {t("Edit")}
                            </Link>
                        }
                        {((userObj.CAN_DELETE && row.created_by === userObj.USER_ID) || userObj.CAN_DELETE_ALL) &&
                            <Link
                                className="btn btn-pill btn-outline-danger btn-sm"
                                to="#"
                                onClick={() => onDeleteClick(row)}
                            >
                                <i className="fa-regular fa-trash-can"></i> {t("Delete")}
                            </Link>
                        }
                    </div>
                );
            },
        },
    ], [t, type]);

    const onTableChange = (type: any, newState: any) => {
        let draw = datatableOptions.draw + 1;
        let page = 1;
        if (type == "search") {
            page = 1;
        } else {
            page = newState.page;
        }
        const updatedDatatableOptions = {
            ...datatableOptions,
            draw: draw,
            page: page,
            sizePerPage: newState.sizePerPage,
            searchText: newState.searchText,
            sortField: newState.sortField,
            sortOrder: newState.sortOrder,
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingListData(true);
    };

    const clearFilter = () => {
        const updatedDatatableOptions = {
            ...datatableOptions,
            draw: 1,
            page: 1,
            sizePerPage: 20,
            searchText: "",
            sortField: "id",
            sortOrder: "desc",
        };
        setDatatableOptions(updatedDatatableOptions);

        setLoadingListData(true);
    };

    const getList = async () => {
        const res = await getAllNewsFeeds({ ...getDataTableQuery(datatableOptions, columns), type: type });
        if (res) {
            setList(res.data.data);
            const updatedDatatableOptions = {
                ...datatableOptions,
                recordsTotal: res.data.recordsTotal,
                recordsFiltered: res.data.recordsFiltered,
            };
            setDatatableOptions(updatedDatatableOptions);
            setLoadingListData(false);
        }
    }

    const onDeleteClick = (row: NewsFeedType) => {
        setDeleteRec(true)
        setSelectedRow(row);
    }

    const handleDelete = async (id: number) => {
        const res = await deleteNewsFeed(id);
        if (res) {
            setDeleteRec(false);
            setLoadingListData(true);
        }
    }

    useEffect(() => {
        setLoadingListData(loadingData);
    }, [loadingData])

    useEffect(() => {
        setList([]);
        setLoadingListData(loadingData);
    }, [t])


    useEffect(() => {
        if (loadingListData === true)
            getList();
    }, [loadingListData])

    return (
        <>
            <DataTable
                columns={columns}
                products={list}
                datatableOptions={datatableOptions}
                filters={[]}
                onTableChange={onTableChange}
                loadingListData={loadingListData}
                clearFilter={clearFilter}
                exportedFileName="news-feed"
                slug="masters"
            />
            {deleteRec && (
                <DeleteModal
                    recordName={selectedRow?.news_feed_title || ''}
                    modalTitle={t("News Feed")}
                    show={deleteRec}
                    onDeleteClick={() => handleDelete(selectedRow?.id || 0)}
                    onCloseClick={() => setDeleteRec(false)}
                    loading={false}
                />
            )}
        </>
    )
}

export default NewsFeedList;