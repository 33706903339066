import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

const Logout = (porps: any) => {
  let navigate = useNavigate();
  const { instance } = useMsal();

  useEffect(() => {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });

    window.localStorage.clear();
    return navigate("/login");
  }, []);

  return (
    <>
      <div>Please wait....</div>
    </>
  );
};

export default Logout;
