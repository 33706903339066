import {
  GET_FEE,
  GET_FEE_SUCCESS,
  GET_FEE_ERROR,

  GET_FEES_LIST,
  GET_FEES_LIST_SUCCESS,
  GET_FEES_LIST_ERROR,

  CREATE_FEE,
  CREATE_FEE_SUCCESS,
  CREATE_FEE_ERROR,

  UPDATE_FEE,
  UPDATE_FEE_SUCCESS,
  UPDATE_FEE_ERROR,

  DELETE_FEE,
  DELETE_FEE_SUCCESS,
  DELETE_FEE_ERROR,
} from "./actionTypes";

// ---------------------------------------------GET_FEE
export const getFee = (fees: any, history: any) => {
  return {
    type: GET_FEE,
    payload: { fees, history },
  };
};

export const getFeeSuccess = (data: {}) => {
  return {
    type: GET_FEE_SUCCESS,
    payload: data,
  };
};

export const getFeeError = (error: {}) => {
  return {
    type: GET_FEE_ERROR,
    payload: error,
  };
};

// ---------------------------------------------GET_FEES_LIST
export const getFeesList = (query: any, history: any) => {
  return {
    type: GET_FEES_LIST,
    payload: {query, history},
  };
};

export const getFeesListSuccess = (data: {}) => {
  return {
    type: GET_FEES_LIST_SUCCESS,
    payload: data,
  };
};

export const getFeesListError = (error: {}) => {
  return {
    type: GET_FEES_LIST_ERROR,
    payload: error,
  };
};

// ---------------------------------------------CREATE_FEE
export const createFee = (fees: any, history: any) => {
  return {
    type: CREATE_FEE,
    payload: { fees, history },
  };
};

export const createFeeSuccess = (fees: {}) => {
  return {
    type: CREATE_FEE_SUCCESS,
    payload: fees,
  };
};

export const createFeeError = (error: {}) => {
  return {
    type: CREATE_FEE_ERROR,
    payload: error,
  };
};

// ---------------------------------------------UPDATE_FEE
export const updateFee = (fees: any, history: any) => {
  return {
    type: UPDATE_FEE,
    payload: { fees, history },
  };
};

export const updateFeeSuccess = (fees: {}) => {
  return {
    type: UPDATE_FEE_SUCCESS,
    payload: fees,
  };
};

export const updateFeeError = (error: {}) => {
  return {
    type: UPDATE_FEE_ERROR,
    payload: error,
  };
};

// ---------------------------------------------DELETE_FEE
export const deleteFee = (fees: any, history: any) => {
  return {
    type: DELETE_FEE,
    payload: { fees, history },
  };
};

export const deleteFeeSuccess = (fees: {}) => {
  return {
    type: DELETE_FEE_SUCCESS,
    payload: fees,
  };
};

export const deleteFeeError = (error: {}) => {
  return {
    type: DELETE_FEE_ERROR,
    payload: error,
  };
};
