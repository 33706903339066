export const GET_ACCESS_MODULE = "GET_ACCESS_MODULE"
export const GET_ACCESS_MODULE_SUCCESS = "GET_ACCESS_MODULES_SUCCESS"
export const GET_ACCESS_MODULE_ERROR = "GET_ACCESS_MODULES_ERROR"

export const GET_ACCESS_MODULES_LIST = "GET_ACCESS_MODULES_LIST"
export const GET_ACCESS_MODULES_LIST_SUCCESS = "GET_ACCESS_MODULES_LIST_SUCCESS"
export const GET_ACCESS_MODULES_LIST_ERROR = "GET_ACCESS_MODULES_LIST_ERROR"
  
export const CREATE_ACCESS_MODULE = "CREATE_ACCESS_MODULE"
export const CREATE_ACCESS_MODULE_SUCCESS = "CREATE_ACCESS_MODULE_SUCCESS"
export const CREATE_ACCESS_MODULE_ERROR = "CREATE_ACCESS_MODULE_ERROR"

export const UPDATE_ACCESS_MODULE = "UPDATE_ACCESS_MODULE"
export const UPDATE_ACCESS_MODULE_SUCCESS = "UPDATE_ACCESS_MODULE_SUCCESS"
export const UPDATE_ACCESS_MODULE_ERROR = "UPDATE_ACCESS_MODULE_ERROR"

export const DELETE_ACCESS_MODULE = "DELETE_ACCESS_MODULE"
export const DELETE_ACCESS_MODULE_SUCCESS = "DELETE_ACCESS_MODULE_SUCCESS"
export const DELETE_ACCESS_MODULE_ERROR = "DELETE_ACCESS_MODULE_ERROR"