export const GET_COMPANY_CATEGORY = "GET_COMPANY_CATEGORY"
export const GET_COMPANY_CATEGORY_SUCCESS = "GET_COMPANY_CATEGORY_SUCCESS"
export const GET_COMPANY_CATEGORY_ERROR = "GET_COMPANY_CATEGORY_ERROR"

export const GET_COMPANY_CATEGORY_LIST = "GET_COMPANY_CATEGORY_LIST"
export const GET_COMPANY_CATEGORY_LIST_SUCCESS = "GET_COMPANY_CATEGORY_LIST_SUCCESS"
export const GET_COMPANY_CATEGORY_LIST_ERROR = "GET_COMPANY_CATEGORY_LIST_ERROR"

export const CREATE_COMPANY_CATEGORY = "CREATE_COMPANY_CATEGORY"
export const CREATE_COMPANY_CATEGORY_SUCCESS = "CREATE_COMPANY_CATEGORY_SUCCESS"
export const CREATE_COMPANY_CATEGORY_ERROR = "CREATE_COMPANY_CATEGORY_ERROR"

export const UPDATE_COMPANY_CATEGORY = "UPDATE_COMPANY_CATEGORY"
export const UPDATE_COMPANY_CATEGORY_SUCCESS = "UPDATE_COMPANY_CATEGORY_SUCCESS"
export const UPDATE_COMPANY_CATEGORY_ERROR = "UPDATE_COMPANY_CATEGORY_ERROR"

export const DELETE_COMPANY_CATEGORY = "DELETE_COMPANY_CATEGORY"
export const DELETE_COMPANY_CATEGORY_SUCCESS = "DELETE_COMPANY_CATEGORY_SUCCESS"
export const DELETE_COMPANY_CATEGORY_ERROR = "DELETE_COMPANY_CATEGORY_ERROR"