import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
// import AsyncSelect from "react-select/async";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Dropzone from "react-dropzone";
import { formatCurrency } from '../../utils/formats';
import Alert from '../../components/Common/Alert';

import "../../App.css";

import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    CardFooter,
    Form,
    Label,
    Input,
    Button,
    FormFeedback,
    Spinner,
    Table
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
// Actions
import { TasksAction } from "../../store/actions";

// Service
import commonService from "../../services/common.service";

// Import Breadcrumb
import Breadcrumbs from "../../components/Layout/Breadcrumb";

// Import Loader
import Loader from "../../components/Layout/Loader";
import { string } from "yup/lib/locale";
import StateManagedSelect from "react-select";
import invoicesService from "../../services/invoices.service";
import ViewInvoice from "./ViewInvoice";
import InvoiceForm from "./InvoiceForm";
import { toast } from "react-toastify";
import Drawer from "../../components/Drawer";
import { useTranslation } from "react-i18next";
import { getUserProfile } from "../../helpers/functions_helper";
import NotFound from '../NotFound/Page404';





const emptyProduct = {
    id: 0,
    is_hourly: 0,
    timesheet_id: 0,
    timesheet_code: "",
    timesheet_name: "",
    quantity: 1,
    price: 0,
    discount: 0,
    taxes: 21,
    subtotal: 0,
    description: '',
    isDisc: false
}
const initialValues = {
    company_id: 0,
    invoice_number: null,
    invoice_date: moment().format("YYYY-MM-DD hh:mm:ss"),
    reference: "",
    due_date: moment(new Date()).add(90, 'days').format("YYYY-MM-DD hh:mm:ss"),
    term: 90,
    details: [emptyProduct],
    tax_rate: 21,
    tax_amount: 0,
    sub_total: 0,
    total: 0,
    paid_amount: 0,
    amount_due: 0,
    total_due: 0,
    expenses: 0,
    submit_btn_code: "save_add_new",
    company: {},
    content: '<div><p>Dear Sir, Dear Madam,</p><p>We have the pleasure to provide you here below with our invoice for services rendered up to day.</p></div>',
    credit_note_amount: null,
    credit_note: null,
    tasks: null,
    is_hourly_rate: 2,
}

const EditInvoice = (props: any) => {
    const { isCreditNote } = props;
    const { t } = useTranslation();
    const userObj = getUserProfile('all-invoices');

    const breadcrumbItems = [
        {
            url: "/dashboard",
            label: t("Dashboard"),
            active: false,
        },
        {
            url: `/invoices-management/invoices/${isCreditNote ? 'creditnote' : 'all'}`,
            label: `${isCreditNote ? t('Credite Notes') : t('Invoices')}`,
            active: false,
        },
        {
            url: "#",
            label: `${isCreditNote ? t('Edit Credite Note') : t('Edit Invoice')}`,
            active: true,
        },
    ];


    document.title = `Edit ${isCreditNote ? t('Credite Note') : t('Invoice')} | ` + process.env.REACT_APP_NAME;

    const { id } = useParams()
    const toastId = React.useRef(null) as any;


    const [loader, setLoader] = useState(false);
    const [preview, setPreview] = useState<boolean>(false);
    const [openInvoice, setOpenInvoice] = useState<boolean>(false);
    const [alertObj, setAlertObj] = useState<any>({
        open: false,
        title: '',
        message: ''
    })

    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [intialData, setIntialData] = useState(initialValues);

    let {
        createTaskData,
        createTaskSuccessMsg,
        createTaskErrorMsg,
        updateTaskSuccessMsg,
        loading,
    } = useSelector((state: any) => ({
        createTaskData: state.Tasks.CREATE_TASK_SUCCESS,
        createTaskSuccessMsg: state.Tasks.CREATE_TASK_SUCCESS.message,
        createTaskErrorMsg: state.Tasks.CREATE_TASK_ERROR.message,
        updateTaskSuccessMsg: state.Tasks.UPDATE_TASK_SUCCESS.message,
        loading: state.Tasks.loading,
    }));


    const validationSchema = Yup.object().shape({
        // company_id: Yup.number().positive('Please select company').required("Company field is required."),
        sub_total: Yup.number().positive('Subtotal is required').required("Subtotal is required."),
        reference: Yup.string().required("Reference field is required."),
        invoice_date: Yup.string().required("The invoice date field is required."),
        due_date: Yup.string().required("The due date field is required."),

    });

    const getInvoiceData = async (id: number) => {
        const res = await invoicesService.getInvoice(id);
        if (res.status === 'success') {
            setIntialData(res?.data);
        } else {
            navigate('/404')
        }
        setLoader(false)
    }

    const getReferenceeNo = async (invoice_number: number) => {
        const res = await invoicesService.getReferenceeNumber(invoice_number);
        setIntialData((state: any) => ({ ...state, reference: res }));
    }

    const getInvoiceNo = async () => {
        const res = await invoicesService.getInvoiceNumber(isCreditNote ? "CREDIT_MEMO" : "INVOICE");
        setIntialData((state: any) => ({ ...state, invoice_number: res }));
        if (res) {
            await getReferenceeNo(res);
        }
        setLoader(false)
        // alert(JSON.stringify(res));
    }

    const reloadData = (id: any) => {
        getInvoiceData(id)
    }


    useEffect(() => {
        if (id) {
            setLoader(true)
            //@ts-ignore
            getInvoiceData(id)
        }
        if (!userObj.CAN_EDIT) {
            navigate('/404');
        }
    }, [id])



    const handleSubmit = async (values: any, actions: any) => {
        if (values.tasks) {
            if (values?.tasks[0]?.task?.is_budget) {
                const budget = values?.tasks[0]?.task?.budget_amount - values?.tasks[0]?.task?.invoices?.reduce((tot: any, acc: any) => tot + parseFloat(acc?.invoice?.total), 0);
                // alert(budget);
                if (budget < values?.sub_total + values?.expenses) {
                    setAlertObj({
                        open: true,
                        title: 'Alert',
                        message: t('The invoice amount exceeds the budget amount field. In order to generate the invoice adjust the invoice amount to match the budget amount')
                    })
                    // alert('The invoice amount exceeds the budget amount field. In order to generate the invoice adjust the invoice amount to match the budget amount');
                    actions.setSubmitting(false);
                    return false;
                }
            }
        }
        toast.dismiss();
        toastId.current = toast(t(`Updateting an invoice # {{invoice_number}}`, { invoice_number: values?.invoice_number }), { autoClose: false, isLoading: true });
        const next_activity = "Email";
        const payment_status = "Not Paid";
        const status = "Draft";
        const tax_excluded = 0;
        const data = {
            ...values,
            contact_person_id: 1,
            next_activity,
            payment_status,
            status,
            tax_excluded
        }
        if (isCreditNote && id) {
            const res = await invoicesService.createCreditMemo(data) as any;
            if (res?.status === 'success') {
                navigate(`/invoices-management/invoices/creditnote`)
            } else {
                actions.setErrors(res.data)
                actions.setSubmitting(false);
            }
        }
        else if (id) {
            const res = await invoicesService.updateInvoice(data) as any;
            if (res?.status === 'success') {
                toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: t(`Invoice #{{invoice_number}} is updated successfully`, { invoice_number: values?.invoice_number }), autoClose: 5000, isLoading: false, delay: 500 });
                navigate(`/invoices-management/invoices/all`, { state: { id: values?.id } })
            }
        } else {
            const res = await invoicesService.createInvoice(data) as any;
            if (res?.status === 'success') {
                navigate(`/invoices-management/invoices/all`)
            }
        }
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: intialData,
        validationSchema: validationSchema,
        onSubmit: (values, actions) => {
            handleSubmit(values, actions);
        },
    });


    useEffect(() => {
        if (!validation.isSubmitting) return;
        if (Object.keys(validation.errors).length > 0) {
            document
                .getElementById(Object.keys(validation.errors)[0])
                ?.scrollIntoView({ behavior: "smooth", block: "center" });
            document
                .getElementById(Object.keys(validation.errors)[0])
                ?.focus({ preventScroll: true });
        }
    }, [validation.isSubmitting, validation.errors]);


    // Select options list API call -------------------------------------------------
    const [query, setQuery] = useState("");

    useEffect(() => {
        if (!id) {
            getInvoiceNo();
        }
        if (isCreditNote) {
            getInvoiceNo();
        }
    }, []);


    return (
        userObj.CAN_EDIT ?
            <React.Fragment>
                <Alert open={alertObj.open} title={alertObj.title} message={alertObj.message} onClickOk={() => setAlertObj((state: any) => ({ ...state, open: false }))} />
                {loader ? <Loader /> :

                    <div className="page-content">
                        <Container fluid>
                            <Breadcrumbs
                                breadcrumbTitle={`${isCreditNote ? t('Edit Credite Note') : t('Edit Invoice')}`}
                                breadcrumbItems={breadcrumbItems}
                            />

                            <Row>
                                <Col xl="12">
                                    {preview ? <Card>
                                        <CardHeader className="bg-transparent border-bottom">
                                            <div className="d-sm-flex flex-wrap">
                                                <CardTitle className="card-title text-uppercase">
                                                    {t("Draft")}
                                                </CardTitle>
                                                <div className="ms-auto d-flex gap-2">
                                                    <Button color="outline-primary" className="btn btn-outline-primary btn-sm" onClick={() => setPreview(false)}><i className="fa-solid fa-arrow-left me-2"></i>{t("Back")}</Button>
                                                </div>
                                            </div>

                                        </CardHeader>
                                        <div style={{ padding: '10px 8%' }}>
                                            <ViewInvoice {...validation?.values} />
                                        </div>
                                    </Card> :
                                        <Card>
                                            <CardHeader className="bg-transparent border-bottom">
                                                <div className="d-sm-flex flex-wrap">
                                                    <CardTitle className="card-title text-uppercase">
                                                        {t("Draft")} ({isCreditNote ? t('Credite Note Number') : t('Invoice Number')} : {validation?.values?.invoice_number})
                                                    </CardTitle>
                                                </div>
                                            </CardHeader>

                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                                autoComplete="off"
                                            >
                                                <CardBody>
                                                    <InvoiceForm form={validation} emptyProduct={emptyProduct} isCreditNote={isCreditNote} reloadData={() => reloadData(id)} />
                                                </CardBody>

                                                <CardFooter className="d-flex flex-wrap gap-2 bg-transparent border-top justify-content-end">
                                                    <Input
                                                        id="submit_btn_code"
                                                        name="submit_btn_code"
                                                        type="hidden"
                                                        onChange={validation.handleChange}
                                                    />

                                                    {!isCreditNote &&
                                                        <Button
                                                            color="primary"
                                                            className="btn-block"
                                                            type="button"
                                                            onClick={() =>
                                                                // setPreview(true)
                                                                setOpenInvoice(true)
                                                            }
                                                            disabled={loading || !validation?.isValid || validation.isSubmitting}
                                                        >
                                                            {t("Preview")}
                                                        </Button>
                                                    }
                                                    <Button
                                                        color="primary"
                                                        className="btn-block"
                                                        type="submit"
                                                        disabled={loading || !validation?.isValid || validation.isSubmitting}
                                                    >
                                                        {validation.isSubmitting ? (
                                                            <span>
                                                                <i className="fa-solid fa-spinner fa-spin"></i>
                                                                <span>&nbsp; {t("Please wait")}...</span>
                                                            </span>
                                                        ) : (
                                                            <span>{t("Save")}</span>
                                                        )}
                                                    </Button>

                                                    <Button
                                                        color="danger"
                                                        className="btn-block"
                                                        type="reset"
                                                        onClick={() => navigate('/invoices-management/invoices/all')}
                                                        disabled={loading || !validation?.isValid || validation.isSubmitting}
                                                    >
                                                        {t("Cancel")}
                                                    </Button>
                                                </CardFooter>
                                            </Form>
                                        </Card>}
                                </Col>
                            </Row>
                        </Container>
                    </div>
                }
                <Drawer
                    title={"Invoice preview"}
                    open={openInvoice}
                    onClose={() => setOpenInvoice((state: boolean) => !state)}
                    fullWidth
                >
                    <ViewInvoice {...validation?.values} is_preview={true} />
                </Drawer>
            </React.Fragment>
            : <NotFound />
    );
};

export default EditInvoice;
