import React, { useEffect } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
//@ts-ignore
import CanvasDraw from "react-canvas-draw";
import { useTranslation } from "react-i18next";

const ImageHighlighterModal = (props: any) => {
    const imgRef = React.createRef<any>();
    const { t } = useTranslation();
    const combineDrawing = (canvasRef: any) => {
        const width = canvasRef.current.props.canvasWidth;
        const height = canvasRef.current.props.canvasHeight;
        const background = canvasRef.current.canvasContainer.children[0];
        const drawing = canvasRef.current.canvasContainer.children[1];
        const canvas = document.createElement('canvas') as any;
        canvas.width = width;
        canvas.height = height;

        // composite now
        canvas.getContext('2d').drawImage(background, 0, 0);
        canvas.getContext('2d').globalAlpha = 1.0;
        canvas.getContext('2d').drawImage(drawing, 0, 0);

        const dataUri = canvas.toDataURL('image/jpeg', 1.0);
        const data = dataUri.split(',')[1];
        const mimeType = dataUri.split(';')[0].slice(5);

        const bytes = window.atob(data);
        const buf = new ArrayBuffer(bytes.length);
        const arr = new Uint8Array(buf);

        for (let i = 0; i < bytes.length; i++) {
            arr[i] = bytes.charCodeAt(i);
        }

        const blob = new Blob([arr], { type: mimeType });
        return { blob: blob, dataUri: dataUri };
    }
    const saveImage = (blob: any, filename: any) => {
        const a = document.createElement('a') as any;
        document.body.appendChild(a);
        a.style = 'display: none';

        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
    }


    const downloadImage = () => {
        if (imgRef.current != null) {
            const { blob, dataUri } = combineDrawing(imgRef);
            imgRef.current.resetView();
            saveImage(blob, 'screenshot.jpg')
        }
    }

    const addIntoAttachment = () => {
        if (imgRef.current != null) {
            const { blob, dataUri } = combineDrawing(imgRef);
            imgRef.current.resetView();
            props.onUpdate(blob);
            props.onCloseClick(false);
        }
    }

    const clearHighlight = () => {
        if (imgRef.current != null) {
            imgRef.current.eraseAll();
        }
    }

    const onClickReset = () => {
        if (imgRef.current != null) {
            imgRef.current.resetView();
        }
    }

    return (
        <Modal
            size="xl"
            isOpen={props.isOpen}
            toggle={props.onCloseClick}
            backdrop="static"
            keyboard={false}
            autoFocus={false}
            scrollable
        >
            <ModalHeader toggle={props.onCloseClick}>{t("Image Highlighter")}</ModalHeader>
            <ModalBody>
                <div>
                    <CanvasDraw
                        imgSrc={props.image}
                        brushColor="rgba(252, 244, 3,0.3)"
                        enablePanAndZoom={true}
                        canvasWidth={1230}
                        canvasHeight={600}
                        immediateLoading
                        ref={imgRef}
                    />
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="mt-4">
                    <a className="btn btn-light waves-effect waves-light btn-sm me-1" href="#" onClick={() => clearHighlight()}>{t("Clear")}</a>
                    <a className="btn btn-light waves-effect waves-light btn-sm me-4" href="#" onClick={() => onClickReset()}>{t("Reset View")}</a>
                    <a className="btn btn-secondary waves-effect waves-light btn-sm me-1" href="#" onClick={() => downloadImage()}>{t("Download Image")}</a>
                    <a className="btn btn-danger waves-effect waves-light btn-sm me-1" href="#" onClick={() => props.onCloseClick(false)}>{t("Cancel")}</a>
                    <a className="btn btn-primary waves-effect waves-light btn-sm" href="#" onClick={() => addIntoAttachment()}>{t("Save")}</a>
                </div>
            </ModalFooter>
        </Modal>)
}

export default ImageHighlighterModal;