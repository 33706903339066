import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class SentimentService {

    async updateSentiment(data: any) {
        const response = await axios
            .put(
                SERVER_URL + url.SENTiMENTS.UPDATE,
                data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async getSentiment(module_app_id: number, module_main_id: any, module_sub_id: any, section_id: number) {
        const response = await axios
            .get(
                SERVER_URL + url.SENTiMENTS.GET + '/' + module_app_id + '/' + module_main_id + '/' + module_sub_id + '/' + section_id,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

}

export default new SentimentService();