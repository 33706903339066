import React, { Suspense, useCallback, useMemo, useRef, useState } from 'react';
// @ts-ignore
import mjml from 'mjml-browser';
import {
  EmailEditor,
  EmailEditorProvider,
  IEmailTemplate,
} from 'easy-email-editor';
import 'easy-email-editor/lib/style.css';
import { BasicType, BlockManager, JsonToMjml } from 'easy-email-core';
import { SimpleLayout, StandardLayout } from 'easy-email-extensions';
import { FormApi } from 'final-form';
import { useWindowSize } from 'react-use';

import { Button, Col, Nav, NavItem, Row } from 'reactstrap';
import { templateData } from './template'
import { toast } from "react-toastify";
import 'easy-email-extensions/lib/style.css';
import '@arco-themes/react-easy-email-theme-purple/css/arco.css';

//import './CustomBlocks';
import newsLetterService from '../../../services/news.letter.service';
import SaveNewsLetter from './SaveModal';
import { useNavigate } from "react-router";
import Breadcrumbs from '../../../components/Layout/Breadcrumb';
import { Link } from 'react-router-dom';
import localesi18n from './locales/locales.json';
import { ckEditorI18 } from '../../../helpers/functions_helper';
import { useTranslation } from 'react-i18next';

const fontList = [
  'Arial',
  'Tahoma',
  'Verdana',
  'Times New Roman',
  'Courier New',
  'Georgia',
  'Lato',
  'Montserrat',
].map(item => ({ value: item, label: item }));


const pageBlock = BlockManager.getBlockByType(BasicType.PAGE)!;


export default function NewNewsLetter() {
  const toastId = useRef(null) as any;
  const [openSave, setOpenSave] = useState<boolean>(false);
  const [newsLetter, setNewsLetter] = useState<any>({});
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const smallScene = width < 1400;
  const [template, setTemplate] = useState<IEmailTemplate['content']>(templateData as any);
  const [htmlContain, setHtmlContain] = useState([])
  const {t} = useTranslation();
  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "/masters/news-letter/list",
      label: t("News Letter List"),
      active: false,
    },
    {
      url: "#",
      label: t("Create News Letter"),
      active: true,
    }
  ];
  const xsubscription = {
    "type": "text",
    "data": {
      "value": {
        "content": "<p style=\"margin: 10px 0;\"><br></p><p style=\"margin: 10px 0;\">C<a target=\"_blank\" rel=\"noopener noreferrer\" style=\"color:inherit; text-decoration:none\" href=\"{{var::href}}\" tabindex=\"-1\">lick <span style=\"color:#DE110B\"><u>here</u></span> to unsubscribe</a>.<br><br></p>"
      }
    },
    "attributes": {
      "align": "center",
      "color": "#797e82",
      "font-family": "Open Sans, Helvetica, Arial, sans-serif",
      "font-size": "11px",
      "line-height": "22px",
      "padding": "0px 20px 0px 20px"
    },
    "children": []
  };

  const onSubmit = useCallback(
    async (
      values: IEmailTemplate,
      form: FormApi<IEmailTemplate, Partial<IEmailTemplate>>
    ) => {

      values?.content?.children?.push(xsubscription);
      const html = mjml(JsonToMjml({
        data: values.content,
        mode: 'production',
        context: values.content
      }), {
        beautify: true,
        validationLevel: 'soft',
      }).html;
      setHtmlContain(html);
      setNewsLetter(values);
      setOpenSave(true);
    },
    []
  );

  const redirectPage = () => {
    return navigate("/masters/news-letter/list");
  }


  const saveNewsLetter = async (data: any) => {
    toastId.current = toast(t("Creating news letter") + "...", {
      autoClose: false,
      isLoading: true,
    });
    const postData = {
      mjml: { ...newsLetter },
      ...data,
      html: htmlContain,
    }
    const res = await newsLetterService.create(postData);
    if (res?.status === "success") {
      toast.update(toastId.current, {
        type: toast.TYPE.SUCCESS,
        render: t("News letter created successfully"),
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      redirectPage();
    }
  }


  const initialValues: IEmailTemplate | null = useMemo(() => {
    return {
      subject: 'Tax Consult',
      subTitle: '',
      content: BlockManager.getBlockByType(BasicType.PAGE)!.create({}),
    };
  }, []);

  const onUploadImage = async (blob: any) => {
    var formData = new FormData()
    formData.append('file', blob);
    return newsLetterService.uploadImage(formData);
  };


  const onClickCancel = () => {
    navigate("/masters/news-letter/list")
    // let text;
    // if (confirm("Are you sure cancel") == true) {
    //   ;
    // } else {
    //   text = "You canceled!";
    // }
  }

  if (!initialValues) return null;

  return (
    <div>
      <EmailEditorProvider
        dashed={false}
        data={initialValues}
        height={'calc(100vh - 85px)'}
        onUploadImage={onUploadImage}
        autoComplete
        fontList={fontList}
        onSubmit={onSubmit}
        locale={localesi18n[ckEditorI18()]}

      >
        {({ values }, { submit }) => {
          return (
            <>
              <Row style={{ marginTop: '60px', background: '#fff', borderTop: '1px solid #ebebeb' }} >
                <Col className='m-1'>
                  <Breadcrumbs
                    breadcrumbTitle=""
                    breadcrumbItems={breadcrumbItems}
                    endAdornment={
                      <>
                        <Nav className="nav justify-content-end">
                          <NavItem>
                            <Link
                              to="#"
                              onClick={() => submit()}
                              className="btn btn-primary btn-label btn-sm mx-2"
                            >
                              <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon ">save</span>{t("Save")}
                            </Link>
                            <Button

                              onClick={() => onClickCancel()}
                              className="btn btn-danger btn-label btn-sm"
                            >
                              <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">close</span>{t("Cancel")}
                            </Button>
                          </NavItem>
                        </Nav>
                      </>
                    }
                  />

                </Col>
              </Row>
              <SimpleLayout
                showSourceCode
              > <Suspense fallback={<>Loading...</>}>
                  <EmailEditor
                  />
                </Suspense>
              </SimpleLayout>
            </>
          );
        }}
      </EmailEditorProvider>
      {openSave &&
        <SaveNewsLetter
          show={openSave}
          onCloseClick={() => setOpenSave(false)}
          data={newsLetter}
          onSubmitClick={saveNewsLetter}
        />}
    </div>
  );
}
