import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactApexChart from "react-apexcharts";
import { noData } from '../../../utils/data';
import LoadingSpinner from '../../../components/Common/Loader';
import { serialize } from '../../../utils/utils';
//import GraphFilters from './Filters';
import GraphFilters from '../../../components/Common/GraphFilters';
import { getUserProfile } from '../../../helpers/functions_helper';
import questionnaireService from '../../../services/questionnaire.service';
import { currentMonth, currentYear } from '../../../common/staticdata';
import ChartFilters from '../../../components/Chart/Filters';
import Chart from '../../../components/Chart';

interface FeeVsServiceChargeProps extends CartProps {
    filters?: any;
}

const YearData: FC<FeeVsServiceChargeProps> = ({ hideHeading = false, filters = null }) => {

    const { t } = useTranslation();

    const userObj = getUserProfile();

    const inititalFilter = {
        week: null,
        quarter: null,
        month: currentMonth,
        year: currentYear,
        user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_ID : null
    };

    const [loading, setLoading] = useState<boolean>(true);
    const [query, setQuery] = useState<string | null>(serialize(inititalFilter));
    const [filter, setFilter] = useState<any>(inititalFilter);

    const [data, setData] = useState<any>({
        series: [],
        options: {
            chart: {
                width: 380,
                type: 'line',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: true
                }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            plotOptions: {
                bar: {
                    horizontal: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                categories: [],
                labels: {
                    show: true,
                    trim: true,
                    rotate: -45,
                }
            },
            legend: {
                position: 'bottom',
                offsetY: 5
            },
            fill: {
                opacity: 1
            },
            noData: noData,
        },
    })

    const getChartData = async () => {
        const result = await questionnaireService.getYearData(query);
        if (result.data.status === 'success') {
            setData((data: any) => ({
                ...data,
                series: result?.data?.data,
                options: {
                    ...data.options,
                    xaxis: {
                        ...data.options.xaxis,
                        categories: result?.data?.xaxis,
                    },
                }
            }))
        }
        setLoading(false);
    }

    const clearWorkAllocationFilter = () => {
        setFilter(inititalFilter);
    }

    useEffect(() => {
        loading && getChartData();
    }, [loading])

    useEffect(() => {
        setQuery(serialize(filter));
        setLoading(true);
    }, [filter])

    useEffect(() => {
        filters && setFilter(filters)
    }, [filters])

    return (
        <>
            <div className="d-sm-flex flex-wrap">
                <h4 className="card-title mb-4">{!hideHeading && t("Questionnaire type per year")}</h4>
                <div className="ms-auto">
                    <ChartFilters
                        filter={filter}
                        setFilter={setFilter}
                        clearFilter={clearWorkAllocationFilter}
                    />
                </div>
            </div>
            <div className="clearfix"></div>
            {loading ? <LoadingSpinner height={380} /> : <Chart options={data.options} series={data.series} type="line" height={380} />}
        </>
    )
}

export default YearData;