import React, { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Nav, NavItem, Row, NavLink } from 'reactstrap';
import { getUserProfile } from '../../helpers/functions_helper';
import InvoiceList from './InvoiceList';


type InvoiceTabsProps = {
    company_id?: any;
    user_id?: any;
}

const InvoiceTabs: FC<InvoiceTabsProps> = ({ company_id = 0, user_id = 0 }) => {

    const { t } = useTranslation();
    const userObj = getUserProfile();

    const [invoiceType, setInvoiceType] = useState<string>('all');

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Nav tabs className="nav-tabs-custom mb-4">
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={invoiceType === 'all' ? "active" : "inactive"}
                                onClick={() => {
                                    setInvoiceType('all');
                                }}
                            >
                                {t("All Invoices")}
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={invoiceType === 'overdue' ? "active" : "inactive"}
                                onClick={() => {
                                    setInvoiceType('overdue');
                                }}
                            >
                                {t("Over Due Invoices")}
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={invoiceType === 'notpaid' ? "active" : "inactive"}
                                onClick={() => {
                                    setInvoiceType('notpaid');
                                }}
                            >
                                {t("Not Paid Invoices")}
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={invoiceType === 'paid' ? "active" : "inactive"}
                                onClick={() => {
                                    setInvoiceType('paid');
                                }}
                            >
                                {t("Fully Paid Invoices")}
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={invoiceType === 'creditnote' ? "active" : "inactive"}
                                onClick={() => {
                                    setInvoiceType('creditnote');
                                }}
                            >
                                {t("Credit Notes")}
                            </NavLink>
                        </NavItem>
                        {userObj.USER_COMPANY === 1 &&
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={invoiceType === 'followup' ? "active" : "inactive"}
                                    onClick={() => {
                                        setInvoiceType('followup');
                                    }}
                                >
                                    {t("Follow Up")}
                                </NavLink>
                            </NavItem>
                        }
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={invoiceType === 'dispute' ? "active" : "inactive"}
                                onClick={() => {
                                    setInvoiceType('dispute');
                                }}
                            >
                                {t("Disputes")}
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <InvoiceList company_id={company_id} user_id={user_id} type={invoiceType} />
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default InvoiceTabs;