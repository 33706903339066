import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Button,
} from "reactstrap";
import { formatCurrency } from "../../utils/formats";
import { useTranslation } from "react-i18next";

const SSSCModel_176 = (props: any) => {
  const { t } = useTranslation();
  let spCountryData = props.spCountryData;
  const TAX_RATE = props.TAX_RATE;
  const [COUNTRY_IDX, setCOUNTRY_IDX] = useState<number>();
  const [D_GROSS_COUNTRY, setD_GROSS_COUNTRY] = useState<number>(0);
  const [C_TAXES_COUNTRY, setC_TAXES_COUNTRY] = useState<number>(0);

  useEffect(() => {
    if (spCountryData) {
      const COUNTRY_DATA = spCountryData.find((data: any) => data.country_id === 176);

      if (COUNTRY_DATA) {
        setCOUNTRY_IDX(spCountryData.findIndex((data: any) => data.country_id === 176));
        setD_GROSS_COUNTRY(
          spCountryData.find((data: any) => data.country_id === 176)?.D_GROSS_COUNTRY
        );
      }
    }
  }, [spCountryData]);

  useEffect(() => {
    setC_TAXES_COUNTRY(D_GROSS_COUNTRY * (TAX_RATE / 100));
  }, [D_GROSS_COUNTRY]);

  useEffect(() => {
    if (COUNTRY_IDX) {
      props.onCalculationUpdate({ COUNTRY_IDX: COUNTRY_IDX, C_TAXES_COUNTRY: C_TAXES_COUNTRY });
    }
  }, [COUNTRY_IDX, C_TAXES_COUNTRY]);

  return (
    <React.Fragment>
      <Modal
        size="xl"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">{t("ROMANIA")}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label={t("Close")}
          ></button>
        </div>

        <ModalBody>
          <Card>
            <CardHeader>
              <CardTitle>1. {t("PARAMETERS")}</CardTitle>
            </CardHeader>

            <CardBody>
              <div className="table-responsive mb-3">
                <Table className="table table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr>
                      <td>{t("Tax rate for non-resident taxpayers")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(TAX_RATE).toFixed(2), false)}%
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>2. {t("COMPUTATION")}</CardTitle>
            </CardHeader>

            <CardBody>
              <div className="table-responsive mb-3">
                <Table className="table table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr>
                      <td>
                        {t("GROSS")} {t("ROMANIA")}
                      </td>
                      <td className="text-end">
                        {formatCurrency(Number(D_GROSS_COUNTRY).toFixed(2), false)}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {t("GROSS")} {t("ROMANIA")}
                      </td>
                      <td className="text-end">
                        {formatCurrency(Number(C_TAXES_COUNTRY).toFixed(2), false)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </ModalBody>

        <ModalFooter>
          <Button color="danger" className="btn-block" type="reset" onClick={props.onCloseClick}>
            {t("Close")}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default SSSCModel_176;
