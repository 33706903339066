import {
  SET_SIDEBAR_COLLAPSED,

  GET_NOTIFICATION_COUNT,
  GET_NOTIFICATION_COUNT_SUCCESS,
  GET_NOTIFICATION_COUNT_ERROR,

  SET_NOTIFICATION_FETCHED,

  GET_USER_OPTIONS_LIST,
  GET_USER_OPTIONS_LIST_SUCCESS,
  GET_USER_OPTIONS_LIST_ERROR,
  GET_USER_OPTIONS_LIST_NEW,
  GET_USER_OPTIONS_LIST_SUCCESS_NEW,
  GET_USER_OPTIONS_LIST_ERROR_NEW,

  GET_COMPANY_OPTIONS_LIST,
  GET_COMPANY_OPTIONS_LIST_SUCCESS,
  GET_COMPANY_OPTIONS_LIST_ERROR,
  GET_COMPANY_OPTIONS_LIST_NEW,
  GET_COMPANY_OPTIONS_LIST_SUCCESS_NEW,
  GET_COMPANY_OPTIONS_LIST_ERROR_NEW,

  GET_FEES_OPTIONS_LIST,
  GET_FEES_OPTIONS_LIST_SUCCESS,
  GET_FEES_OPTIONS_LIST_ERROR,
  GET_FEES_OPTIONS_LIST_NEW,
  GET_FEES_OPTIONS_LIST_SUCCESS_NEW,
  GET_FEES_OPTIONS_LIST_ERROR_NEW,

  CHANGE_TABLE_ORDER_ON_DRAG,
  CHANGE_TABLE_ORDER_ON_DRAG_SUCCESS,
  CHANGE_TABLE_ORDER_ON_DRAG_ERROR,
} from "./actionTypes";

// ---------------------------------------------SET_SIDEBAR_COLLAPSED
export const setSidebarcollpsed = (sidebarcollpsed: boolean) => {
  return {
    type: SET_SIDEBAR_COLLAPSED,
    payload: sidebarcollpsed,
  };
}

// ---------------------------------------------GET_NOTIFICATION_COUNT
export const getNotificationCount = () => {
  return {
    type: GET_NOTIFICATION_COUNT,
  };
}

export const getNotificationCountSuccess = (data: {}) => {
  return {
    type: GET_NOTIFICATION_COUNT_SUCCESS,
    payload: data,
  };
};

export const getNotificationCountError = (error: {}) => {
  return {
    type: GET_NOTIFICATION_COUNT_ERROR,
    payload: error,
  };
};

// ---------------------------------------------SET_NOTIFICATION_FETCHED
export const setNotificationFetched = (notificationfetched: boolean) => {
  return {
    type: SET_NOTIFICATION_FETCHED,
    payload: notificationfetched,
  };
}

// ---------------------------------------------GET_USER_OPTIONS_LIST
export const getUserOptionsList = (otherFields?: Array<string>) => {
  return {
    type: GET_USER_OPTIONS_LIST,
    payload: {otherFields},
  };
};

export const getUserOptionsListSuccess = (data: {}) => {
  return {
    type: GET_USER_OPTIONS_LIST_SUCCESS,
    payload: data,
  };
};

export const getUserOptionsListError = (error: {}) => {
  return {
    type: GET_USER_OPTIONS_LIST_ERROR,
    payload: error,
  };
};
// ---------------------------------------------GET_USER_OPTIONS_LIST_NEW
export const getUserOptionsList_New = (otherFields?: Array<string>) => {
  return {
    type: GET_USER_OPTIONS_LIST_NEW,
    payload: {otherFields},
  };
};

export const getUserOptionsListSuccess_New = (data: {}) => {
  return {
    type: GET_USER_OPTIONS_LIST_SUCCESS_NEW,
    payload: data,
  };
};

export const getUserOptionsListError_New = (error: {}) => {
  return {
    type: GET_USER_OPTIONS_LIST_ERROR_NEW,
    payload: error,
  };
};

// ---------------------------------------------GET_COMPANY_OPTIONS_LIST
export const getCompanyOptionsList = (otherFields?: Array<string>) => {
  return {
    type: GET_COMPANY_OPTIONS_LIST,
    payload: {otherFields},
  };
};

export const getCompanyOptionsListSuccess = (data: {}) => {
  return {
    type: GET_COMPANY_OPTIONS_LIST_SUCCESS,
    payload: data,
  };
};

export const getCompanyOptionsListError = (error: {}) => {
  return {
    type: GET_COMPANY_OPTIONS_LIST_ERROR,
    payload: error,
  };
};
// ---------------------------------------------GET_COMPANY_OPTIONS_LIST_NEW
export const getCompanyOptionsList_New = (otherFields?: Array<string>) => {
  return {
    type: GET_COMPANY_OPTIONS_LIST_NEW,
    payload: {otherFields},
  };
};

export const getCompanyOptionsListSuccess_New = (data: {}) => {
  return {
    type: GET_COMPANY_OPTIONS_LIST_SUCCESS_NEW,
    payload: data,
  };
};

export const getCompanyOptionsListError_New = (error: {}) => {
  return {
    type: GET_COMPANY_OPTIONS_LIST_ERROR_NEW,
    payload: error,
  };
};

// ---------------------------------------------GET_FEES_OPTIONS_LIST
export const getFeesOptionsList = () => {
  return {
    type: GET_FEES_OPTIONS_LIST,
    payload: {},
  };
};

export const getFeesOptionsListSuccess = (data: {}) => {
  return {
    type: GET_FEES_OPTIONS_LIST_SUCCESS,
    payload: data,
  };
};

export const getFeesOptionsListError = (error: {}) => {
  return {
    type: GET_FEES_OPTIONS_LIST_ERROR,
    payload: error,
  };
};
// ---------------------------------------------GET_FEES_OPTIONS_LIST_NEW
export const getFeesOptionsList_New = () => {
  return {
    type: GET_FEES_OPTIONS_LIST_NEW,
    payload: {},
  };
};

export const getFeesOptionsListSuccess_New = (data: {}) => {
  return {
    type: GET_FEES_OPTIONS_LIST_SUCCESS_NEW,
    payload: data,
  };
};

export const getFeesOptionsListError_New = (error: {}) => {
  return {
    type: GET_FEES_OPTIONS_LIST_ERROR_NEW,
    payload: error,
  };
};

// ---------------------------------------------CHANGE ORDER ON DRAGE DATATABLE --------------------------------
export const changeOrderonDrag = (tableName: string, fromPosition: number, toPosition: number, dependentField : string, dependentId : Number) => {
  return {
    type: CHANGE_TABLE_ORDER_ON_DRAG,
    payload: {tableName, fromPosition, toPosition, dependentField , dependentId},
  };
};

export const changeOrderonDragSuccess = (data: {}) => {
  return {
    type: CHANGE_TABLE_ORDER_ON_DRAG_SUCCESS,
    payload: data,
  };
};

export const changeOrderonDragError = (error: {}) => {
  return {
    type: CHANGE_TABLE_ORDER_ON_DRAG_ERROR,
    payload: error,
  };
};


