import React from "react";
import { Row, Col } from "reactstrap";

import AlliottLogo from "../../assets/images/alliott-logo.jpg";

const Footer = (props: any) => {
  return (
    <React.Fragment>
      <Row className="invoiceFooter">
        <Col className="col-12 strong">
          <p>SRL Tax Consult HR Tax & International Mobility BV</p>
        </Col>

        <Col className="col-3">
          <p>
            Avenue du Dirigeable 8<br />
            1170 Brussels
          </p>
        </Col>
        <Col className="col-3">
          <p>
            Tel. +32 2 675 50 05
            <br />
            info@taxconsult.be
            <br />
            www.taxconsult.be
          </p>
        </Col>
        <Col className="col-3">
          <p>
            Banque / Bank
            <br />
            ING BE29 3631 9421 5864 - BIC: BBRUBEBB
          </p>
        </Col>

        <Col className="col-12"></Col>

        <Col className="col-9">
          <p>
            T.V.A/B.T.W. BE 0740.763.660
            <br />
            <br />
            Member of Alliott Group, a Worldwide Alliance of Independent Accounting, Law and
            Consulting Firms
          </p>
        </Col>
        <Col className="col-3">
          <img src={AlliottLogo} alt="logo" width="200px" />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Footer;
