import {
  GET_TASK,
  GET_TASK_SUCCESS,
  GET_TASK_ERROR,

  GET_TASKS_LIST,
  GET_TASKS_LIST_SUCCESS,
  GET_TASKS_LIST_ERROR,

  GET_ARCHIVE_TASKS_LIST,
  GET_ARCHIVE_TASKS_LIST_SUCCESS,
  GET_ARCHIVE_TASKS_LIST_ERROR,

  CREATE_TASK,
  CREATE_TASK_SUCCESS,
  CREATE_TASK_ERROR,

  UPDATE_TASK,
  UPDATE_TASK_SUCCESS,
  UPDATE_TASK_ERROR,

  DELETE_TASK,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_ERROR,
} from "./actionTypes";

// ---------------------------------------------GET_TASK
export const getTask = (tasks: any, history: any) => {
  return {
    type: GET_TASK,
    payload: { tasks, history },
  };
};

export const getTaskSuccess = (data: {}) => {
  return {
    type: GET_TASK_SUCCESS,
    payload: data,
  };
};

export const getTaskError = (error: {}) => {
  return {
    type: GET_TASK_ERROR,
    payload: error,
  };
};

// ---------------------------------------------GET_TASKS_LIST
export const getTasksList = (query: any, history: any) => {
  return {
    type: GET_TASKS_LIST,
    payload: {query, history},
  };
};

export const getTasksListSuccess = (data: {}) => {
  return {
    type: GET_TASKS_LIST_SUCCESS,
    payload: data,
  };
};

export const getTasksListError = (error: {}) => {
  return {
    type: GET_TASKS_LIST_ERROR,
    payload: error,
  };
};


// ---------------------------------------------GET_ARCHIVE_TASKS_LIST
export const getArchiveTasksList = (query: any, history: any) => {
  return {
    type: GET_ARCHIVE_TASKS_LIST,
    payload: {query, history},
  };
};

export const getArchiveTasksListSuccess = (data: {}) => {
  return {
    type: GET_ARCHIVE_TASKS_LIST_SUCCESS,
    payload: data,
  };
};

export const getArchiveTasksListError = (error: {}) => {
  return {
    type: GET_ARCHIVE_TASKS_LIST_ERROR,
    payload: error,
  };
};

// ---------------------------------------------CREATE_TASK
export const createTask = (tasks: any, history: any) => {
  return {
    type: CREATE_TASK,
    payload: { tasks, history },
  };
};

export const createTaskSuccess = (tasks: {}) => {
  return {
    type: CREATE_TASK_SUCCESS,
    payload: tasks,
  };
};

export const createTaskError = (error: {}) => {
  return {
    type: CREATE_TASK_ERROR,
    payload: error,
  };
};

// ---------------------------------------------UPDATE_TASK
export const updateTask = (tasks: any, history: any) => {
  return {
    type: UPDATE_TASK,
    payload: { tasks, history },
  };
};

export const updateTaskSuccess = (tasks: {}) => {
  return {
    type: UPDATE_TASK_SUCCESS,
    payload: tasks,
  };
};

export const updateTaskError = (error: {}) => {
  return {
    type: UPDATE_TASK_ERROR,
    payload: error,
  };
};

// ---------------------------------------------DELETE_TASK
export const deleteTask = (tasks: any, history: any) => {
  return {
    type: DELETE_TASK,
    payload: { tasks, history },
  };
};

export const deleteTaskSuccess = (tasks: {}) => {
  return {
    type: DELETE_TASK_SUCCESS,
    payload: tasks,
  };
};

export const deleteTaskError = (error: {}) => {
  return {
    type: DELETE_TASK_ERROR,
    payload: error,
  };
};
