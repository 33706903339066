import moment from "moment"
import { Link } from "react-router-dom"
import { FormGroup, Input, Label } from "reactstrap"
import { MaterialIcon } from "./styles"
import './style.scss';
const SearchMailList = (props: any) => {
  return (
    <div className="col-md-12 col-lg-12 " id={props?.data?.hitId}>
      <div className={`bg-white text-dark border-bottom border-light `}>
        <div className={`p-2 ${props?.data?.resource?.isRead === false && 'is-read'}`}>
          <div className="d-flex flex-start">
            <FormGroup className="pe-4">
              <Input
                id={props?.data?.hitId}
                type="checkbox"
                onChange={()=>props?.handleChecked(props?.data?.hitId)}
                checked={props?.data?.resource?.checked}
              />
              {' '}
              <Label for={props?.data?.hitId}>
              </Label>
            </FormGroup>
            <div className="flex-grow-1">
              <Link to={`./${props?.data?.hitId}`} className="link-muted  "><h6 className={`mb-1  ${props?.data?.resource?.isRead === false ? 'text-dark fw-bold':'text-primary'}`}>{props?.data?.resource?.sender?.emailAddress?.name}</h6></Link>
              <Link to={`./${props?.data?.hitId}`} className={`link-muted ${props?.data?.resource?.isRead === false ? 'text-primary fw-bold':'text-dark'}`}>
                {props?.data?.resource?.subject ? <p className="mb-0" dangerouslySetInnerHTML={{
                  __html: props?.data?.resource?.subject,
                }}>
                </p> : <p>(No Subject)</p>}
              </Link>
              <Link to={`./${props?.data?.hitId}`} className="text-dark link-muted fw-light">
                <p className="mb-0" dangerouslySetInnerHTML={{
                  __html: props?.data?.resource?.bodyPreview,
                }}>
                </p>
              </Link>
            </div>
            <div className="ms-auto" style={{width:'145px'}}>
              <div className="align-items-center text-end mb-1">
                {props?.data?.resource?.hasAttachments && <MaterialIcon>attach_file</MaterialIcon>}
                <p className="mb-0">
                  {moment(props?.data?.resource?.receivedDateTime).format('MMM Do YY')}
                  {/* <span className="badge bg-primary">Pending</span> */}
                </p>
                {/* <Button color="link"><MaterialIcon>reply</MaterialIcon></Button> */}
                {/* <a href="#!" className="link-muted"><i className="fas fa-redo-alt ms-2"></i></a>
                                    <a href="#!" className="link-muted"><i className="fas fa-heart ms-2"></i></a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchMailList;