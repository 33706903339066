import moment from "moment";
import { toast } from "react-toastify";
import * as Yup from "yup";
import documentService from "../../../services/document.template.service";

export const publicationOptions = [
    {
        value: 'Internal',
        label: 'Internal'
    },
    {
        value: 'External',
        label: 'External'
    },
    {
        value: 'All recipients',
        label: 'All recipients'
    },
];

export const priorityOptions = [
    {
        value: 'High',
        label: 'High'
    },
    {
        value: 'Medium',
        label: 'Medium'
    },
    {
        value: 'Low',
        label: 'Low'
    },
]

export const validationSchema = Yup.object().shape({
    type: Yup.string().nullable().required("Type field is required."),
    document_name: Yup.string().nullable().required("Document name field is required."),
    expiry_date: Yup.string().nullable().required("Expiry date field is required."),
    due_date: Yup.string().nullable().required("Due date field is required."),
});

export const intialValues = {
    type: "",
    document_name: "",
    expiry_date: "",
    due_date: "",
    status: "",
}

export const getAllDocument = async (data: any) => {
    try {
        const result = await documentService.getAllDocuments(data);
        if (result.data.status === 'success') {
            return result;
        } else {
            toast(result.data.message, { type: toast.TYPE.ERROR })
        }
    } catch (error: any) {
        toast(error?.message, { type: toast.TYPE.ERROR })
    }
}


export const createDocumentTemplate = async (values: NewsFeedType) => {
    try {
        const result = await documentService.create(values);
        if (result.status === 'success') {
            toast(result.message, { type: toast.TYPE.SUCCESS })
            return result;
        } else {
            toast(result.message, { type: toast.TYPE.ERROR })
        }
    } catch (error: any) {
        toast(error?.message, { type: toast.TYPE.ERROR })
    }
}

export const updateTemplate = async (values: NewsFeedType) => {
    try {
        const result = await documentService.update(values);
        if (result.status === 'success') {
            toast(result.message, { type: toast.TYPE.SUCCESS })
            return result;
        } else {
            toast(result.message, { type: toast.TYPE.ERROR })
        }
    } catch (error: any) {
        toast(error?.message, { type: toast.TYPE.ERROR })
    }
}

export const getAllDocumentTemplates = async (data: any) => {
    try {
        const result = await documentService.getAll(data);
        if (result.data.status === 'success') {
            return result;
        } else {
            toast(result.data.message, { type: toast.TYPE.ERROR })
        }
    } catch (error: any) {
        toast(error?.message, { type: toast.TYPE.ERROR })
    }
}

export const getTemplateByID = async (id: number, doc_id : string) => {
    try {
        const result = await documentService.getTemplate(id, doc_id);
        if (result.status === 'success') {
            return result;
        } else {
            toast(result.message, { type: toast.TYPE.ERROR })
        }
    } catch (error: any) {
        toast(error?.message, { type: toast.TYPE.ERROR })
    }
}

export const getDocumentByID = async (id: number, doc_id: string) => {
    try {
        const result = await documentService.getDocument(id,doc_id);
        if (result.status === 'success') {
            return result;
        } else {
            toast(result.message, { type: toast.TYPE.ERROR })
        }
    } catch (error: any) {
        toast(error?.message, { type: toast.TYPE.ERROR })
    }
}


export const changeStatusRefuse = async (data: any) => {
    try {
        const result = await documentService.changeStatusRefuse(data);
        if (result.data.status === 'success') {
            return result;
        } else {
            toast(result.data.message, { type: toast.TYPE.ERROR })
        }
    } catch (error: any) {
        toast(error?.message, { type: toast.TYPE.ERROR })
    }
}


export const deleteTemplate = async (id: number) => {
    try {
        const result = await documentService.delete(id);
        if (result.status === 'success') {
            toast(result.message, { type: toast.TYPE.SUCCESS })
            return result;
        } else {
            toast(result.message, { type: toast.TYPE.ERROR })
        }
    } catch (error: any) {
        toast(error?.message, { type: toast.TYPE.ERROR })
    }
}
