import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody } from "reactstrap";
import saltedgeService from "../../services/saltedge.service";
import { toast } from "react-toastify";

const CreateLeadSessions = (props: any) => {
  const { loading } = props;
  const { t } = useTranslation();
  const toastId = React.useRef(null) as any;

  const createLeadSessions = async () => {
    toastId.current = toast(t("Creating lead session..."), {
      autoClose: false,
      isLoading: true,
    });

    try {
      const res = (await saltedgeService.createLeadSessions()) as any;
      if (res?.data?.status === "success") {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        if (res?.data?.data?.data?.redirect_url) {
          window.open(res?.data?.data?.data?.redirect_url, "_self");
        }
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res?.data?.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
      }
    } catch (err: any) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
  };

  return (
    <React.Fragment>
      <div className="nodata d-flex align-items-center justify-content-center" style={{minHeight: "50vh"}}>
        <div>
          <div className="text-center mt-3 mb-3">
            <span
              className="align-middle material-symbols-outlined text-muted fw-lighter"
              style={{ fontSize: "80px" }}
            >
              {loading ? "hub" : "account_balance"}
            </span>
          </div>

          <div className="mt-1 mb-3 text-center">
            <h5 className="text-truncate text-muted fw-lighter">
              {loading ? t("Finding Connections...") : t("Connections not found!")}
            </h5>
          </div>

          {!loading && (
            <div className="mt-3 mb-3 text-center">
              <Button
                color="primary"
                className="btn btn-primary btn-label"
                onClick={createLeadSessions}
              >
                <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                  account_balance
                </span>
                {t("Create connections")}
              </Button>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateLeadSessions;
