import { GET_DASHBOARD, UPDATE_DASHBOARD, UPDATE_DASHBOARD_ERROR, SET_DASHBOARD } from "./actionTypes";

export const updateDashboard = (data: { user_id: number, widgets: any[] }) => {
    return {
        type: UPDATE_DASHBOARD,
        payload: data,
    };
}

export const setDashboard = (widgets: any[]) => {
    return {
        type: SET_DASHBOARD,
        payload: widgets,
    };
}

export const getDashboard = (user_id: number) => {
    return {
        type: GET_DASHBOARD,
        payload: user_id,
    };
};

export const updateDashboardError = (error: any) => {
    return {
        type: UPDATE_DASHBOARD_ERROR,
        payload: error,
    };
}