import { takeEvery, call, put } from "redux-saga/effects";
import timeSheetService from "../../services/time.sheets.service";
import { AnyAction } from "redux";

// TimeSheets Redux States
import {
  GET_TIME_SHEET,
  GET_TIME_SHEETS_GROUP_LIST,
  GET_TIME_SHEETS_LIST,
  CREATE_TIME_SHEET,
  GET_TIME_SHEET_FORM,
  CREATE_TIME_SHEET_FORM,
  UPDATE_TIME_SHEET,
  DELETE_TIME_SHEET,
} from "./actionTypes";

import { 
    getTimeSheetSuccess, 
    getTimeSheetError,
    
    getTimeSheetsGroupListSuccess,
    getTimeSheetsGroupListError,

    getTimeSheetsListSuccess,
    getTimeSheetsListError,

    createTimeSheetSuccess,
    createTimeSheetError,

    getTimeSheetFormSuccess,
    getTimeSheetFormError,

    createTimeSheetFormSuccess,
    createTimeSheetFormError,

    updateTimeSheetSuccess,
    updateTimeSheetError,

    deleteTimeSheetSuccess,
    deleteTimeSheetError,
} from "./actions";

function* getTimeSheet({ payload: { timesheets, history } }: AnyAction): any {
  try {
    const response = yield call(timeSheetService.getTimeSheet, timesheets);
    if (response.data && response.data.status === "success") {
      yield put(getTimeSheetSuccess(response.data));
    } 
    else if (response.data && response.data.status === "error") {
      yield put(getTimeSheetError(response.data));
    }
    else {
      yield put(getTimeSheetError(response));
    }
  } catch (error: any) {
    yield put(getTimeSheetError(error));
  }
}

function* getTimeSheetsGroupList({ payload: { query, history } }: AnyAction): any {
  try {
    const response = yield call(timeSheetService.getTimeSheetsGroupList, query);
    if (response.data && response.data.status === "success") {
      yield put(getTimeSheetsGroupListSuccess(response.data));
    }
    else if (response.data && response.data.status === "error") {
      yield put(getTimeSheetsGroupListError(response.data));
    }
    else {
      yield put(getTimeSheetsGroupListError(response));
    }
  } catch (error: any) {
    yield put(getTimeSheetsGroupListError(error));
  }
}

function* getTimeSheetsList({ payload: { query, history } }: AnyAction): any {
  try {
    const response = yield call(timeSheetService.getTimeSheetsList, query);
    if (response.data && response.data.status === "success") {
      yield put(getTimeSheetsListSuccess(response.data));
    }
    else if (response.data && response.data.status === "error") {
      yield put(getTimeSheetsListError(response.data));
    }
    else {
      yield put(getTimeSheetsListError(response));
    }
  } catch (error: any) {
    yield put(getTimeSheetsListError(error));
  }
}

function* createTimeSheet({ payload: { timesheets, history } }: AnyAction): any {
  try {
    const response = yield call(timeSheetService.createTimeSheet, timesheets);
    if (response.data && response.data.status === "success") {
      yield put(createTimeSheetSuccess(response.data));
    }
    else if (response.data && response.data.status === "error") {
      yield put(createTimeSheetError(response.data));
    }
    else {
      yield put(createTimeSheetError(response));
    }
  } catch (error: any) {
    yield put(createTimeSheetError(error));
  }
}

function* getTimeSheetForm({ payload: { timesheets, history } }: AnyAction): any {
  try {
    const response = yield call(timeSheetService.getTimeSheetForm, timesheets);
    if (response.data && response.data.status === "success") {
      yield put(getTimeSheetFormSuccess(response.data));
    }
    else if (response.data && response.data.status === "error") {
      yield put(getTimeSheetFormError(response.data));
    }
    else {
      yield put(getTimeSheetFormError(response));
    }
  } catch (error: any) {
    yield put(getTimeSheetFormError(error));
  }
}

function* createTimeSheetForm({ payload: { timesheets, history } }: AnyAction): any {
  try {
    const response = yield call(timeSheetService.createTimeSheetForm, timesheets);
    if (response.data && response.data.status === "success") {
      yield put(createTimeSheetFormSuccess(response.data));
    }
    else if (response.data && response.data.status === "error") {
      yield put(createTimeSheetFormError(response.data));
    }
    else {
      yield put(createTimeSheetFormError(response));
    }
  } catch (error: any) {
    yield put(createTimeSheetFormError(error));
  }
}

function* updateTimeSheet({ payload: { timesheets, history } }: AnyAction): any {
  try {
    const response = yield call(timeSheetService.updateTimeSheet, timesheets);
    if (response.data && response.data.status === "success") {
      yield put(updateTimeSheetSuccess(response.data));
    }
    else if (response.data && response.data.status === "error") {
      yield put(updateTimeSheetError(response.data));
    }
    else {
      yield put(updateTimeSheetError(response));
    }
  } catch (error: any) {
    yield put(updateTimeSheetError(error));
  }
}

function* deleteTimeSheet({ payload: { timesheets, history } }: AnyAction): any {
  try {
    const response = yield call(timeSheetService.deleteTimeSheet, timesheets);
    if (response.data && response.data.status === "success") {
      yield put(deleteTimeSheetSuccess(response.data));
    }
    else if (response.data && response.data.status === "error") {
      yield put(deleteTimeSheetError(response.data));
    }
    else {
      yield put(deleteTimeSheetError(response));
    }
  } catch (error: any) {
    yield put(deleteTimeSheetError(error));
  }
}

function* timesheetsSaga(): any {
  yield takeEvery(GET_TIME_SHEET, getTimeSheet);
  yield takeEvery(GET_TIME_SHEETS_GROUP_LIST, getTimeSheetsGroupList);
  yield takeEvery(GET_TIME_SHEETS_LIST, getTimeSheetsList);
  yield takeEvery(CREATE_TIME_SHEET, createTimeSheet);
  yield takeEvery(GET_TIME_SHEET_FORM, getTimeSheetForm);
  yield takeEvery(CREATE_TIME_SHEET_FORM, createTimeSheetForm);
  yield takeEvery(UPDATE_TIME_SHEET, updateTimeSheet);
  yield takeEvery(DELETE_TIME_SHEET, deleteTimeSheet);
}

export default timesheetsSaga;
