export const GET_KANBAN_TASK = "GET_KANBAN_TASK"
export const GET_KANBAN_TASK_SUCCESS = "GET_KANBAN_TASKS_SUCCESS"
export const GET_KANBAN_TASK_ERROR = "GET_KANBAN_TASKS_ERROR"

export const GET_KANBAN_TASKS_LIST = "GET_KANBAN_TASKS_LIST"
export const GET_KANBAN_TASKS_LIST_SUCCESS = "GET_KANBAN_TASKS_LIST_SUCCESS"
export const GET_KANBAN_TASKS_LIST_ERROR = "GET_KANBAN_TASKS_LIST_ERROR"
  
export const CREATE_KANBAN_TASK = "CREATE_KANBAN_TASK"
export const CREATE_KANBAN_TASK_SUCCESS = "CREATE_KANBAN_TASK_SUCCESS"
export const CREATE_KANBAN_TASK_ERROR = "CREATE_KANBAN_TASK_ERROR"

export const UPDATE_KANBAN_TASK = "UPDATE_KANBAN_TASK"
export const UPDATE_KANBAN_TASK_SUCCESS = "UPDATE_KANBAN_TASK_SUCCESS"
export const UPDATE_KANBAN_TASK_ERROR = "UPDATE_KANBAN_TASK_ERROR"

export const DELETE_KANBAN_TASK = "DELETE_KANBAN_TASK"
export const DELETE_KANBAN_TASK_SUCCESS = "DELETE_KANBAN_TASK_SUCCESS"
export const DELETE_KANBAN_TASK_ERROR = "DELETE_KANBAN_TASK_ERROR"

export const GET_KANBAN_SEARCH = "GET_KANBAN_SEARCH"
export const GET_KANBAN_SEARCH_SUCCESS = "GET_KANBAN_SEARCH_SUCCESS"
export const GET_KANBAN_SEARCH_ERROR = "GET_KANBAN_SEARCH_ERROR"


export const GET_TASK_ALLOCATION = "GET_TASK_ALLOCATION"
export const GET_TASK_ALLOCATION_SUCCESS = "GET_TASK_ALLOCATION_SUCCESS"
export const GET_TASK_ALLOCATION_ERROR = "GET_TASK_ALLOCATION_ERROR"

export const GET_TASK_ALLOCATION_LIST = "GET_TASK_ALLOCATION_LIST"
export const GET_TASK_ALLOCATION_LIST_SUCCESS = "GET_TASK_ALLOCATION_LIST_SUCCESS"
export const GET_TASK_ALLOCATION_LIST_ERROR = "GET_TASK_ALLOCATION_LIST_ERROR"