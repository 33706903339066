import React, { useState, FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, CardHeader, Col } from 'reactstrap';

type WidgetType = {
    title: string;
    children: ReactNode;
    size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    noHeader?: boolean;
    draggable?: boolean;
    height?: string;
    onDragStart?: any;
    onDragEnter?: any;
    onDragEnd?: any;
    onClose?: (id: string) => void;
    hideCard?: boolean;
};

const Widget: FC<WidgetType> = ({ title, children, size, noHeader = false, draggable = true, onDragStart, onDragEnter, onDragEnd, onClose, height = '450px', hideCard = false }) => {
    const { t } = useTranslation();
    const [minimize, setMinimize] = useState<boolean>(false);
    const [close, setClose] = useState<boolean>(false);

    const handleClose = () => {
        onClose && onClose(title.split(' ').join('_'))
    }

    return (
        close ? null :
            <Col
                sm={size}
                xs={12}
                draggable
                onDragStart={onDragStart}
                onDragEnter={onDragEnter}
                onDragEnd={onDragEnd}
                className="widget"
            >
                {hideCard ?
                    <div>
                        <div className='widget-close-btn'>
                            <Button color='danger' size='sm' className='ms-2' outline onClick={handleClose} title={t("Close")}>
                                <i className="fa fa-window-close"></i>
                            </Button>
                        </div>
                        {children}
                    </div> :
                    <Card>
                        {!noHeader && <CardHeader>
                            <div className='d-sm-flex flex-wrap'>
                                <h4 className='card-title'>{t(title)}</h4>
                                <div className='ms-auto'>
                                    <Button color='light' size='sm' outline onClick={() => setMinimize((state: boolean) => !state)} title={t("Minimize")}>
                                        <i className="fa fa-window-minimize"></i>
                                    </Button>
                                    <Button color='light' size='sm' className='ms-2' outline onClick={handleClose} title={t("Close")}>
                                        <i className="fa fa-window-close"></i>
                                    </Button>
                                </div>
                            </div>
                        </CardHeader>}
                        {
                            !minimize ?
                                <CardBody style={{ height: height }}>
                                    {children}
                                </CardBody>
                                : null
                        }
                    </Card>
                }
            </Col>
    )
}

export default Widget;