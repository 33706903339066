import React from "react";

// Authentication
import Login from "../pages/Authentication/Login";
import AzureADCallback from "../pages/Authentication/AzureAD/Callback";
import AzureADLogout from "../pages/Authentication/AzureAD/Logout";
import ForgotPassword from "../pages/Authentication/ForgotPassword";
import ResetPassword from "../pages/Authentication/ResetPassword";

import NewWebsiteUser from "../pages/UsersRegistration/NewUser";
import GDPRPolicy from "../pages/UsersRegistration/GDPRPolicy";
import PrivacyPolicy from "../pages/UsersRegistration/PrivacyPolicy";
import TermsConditions from "../pages/UsersRegistration/TermsConditions";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
// Audit Logs
import AuditLogs from "../pages/AuditLog/AllAuditLogs";
// Notifications
import Notifications from "../pages/Notification/AllNotifications";
// Attachments
import Attachments from "../pages/Attachments/AllAttachments";

// Users
import AllUsers from "../pages/Users/AllUsers";
import NewUser from "../pages/Users/NewUser";
import EditUser from "../pages/Users/EditUser";

import EditUserGeneral from "../pages/Users/EditUserGeneral";
import EditUserTaxes from "../pages/Users/EditUserTaxes";
import EditUserComments from "../pages/Users/EditUserComments";
import EditUserAttachments from "../pages/Users/EditUserAttachments";
import EditUserDocuments from "../pages/Users/EditUserDocuments";
import EditUserQuestionnaires from "../pages/Users/EditUserQuestionnaires";
import EditUserSignatures from "../pages/Users/EditUserSignatures";
import EditUserNotifications from "../pages/Users/EditUserNotifications";
import EditUserInvoices from "../pages/Users/EditUserInvoices";

// Tasks
import AllTasks from "../pages/Tasks/AllTasks";
import NewTask from "../pages/Tasks/NewTask";
import EditTask from "../pages/Tasks/EditTask";
import EditTaskSubtaskList from "../pages/Tasks/EditTaskSubtaskList";
import EditTaskTimesheetList from "../pages/Tasks/EditTaskTimesheetList";

// Time Sheet
import AllTimeSheets from "../pages/TimeSheets/AllTimeSheets";
import NewTimeSheet from "../pages/TimeSheets/NewTimeSheet";
import EditTimeSheet from "../pages/TimeSheets/EditTimeSheet";

// Access Role
// import AccessRole from "../pages/Masters/AccessRole";
import AccessRole from "../pages/Masters/AccessRole/AccessRole";

// Access Module
import AccessModule from "../pages/Masters/AccessModule";

// Masters
import UserFunction from "../pages/Masters/UserFunction";
import UserType from "../pages/Masters/UserType";
import UserStatus from "../pages/Masters/UserStatus";

import AllCompany from "../pages/Masters/Company/AllCompany";
import NewCompany from "../pages/Masters/Company/NewCompany";
import EditCompany from "../pages/Masters/Company/EditCompany";

import EditCompanyGeneral from "../pages/Masters/Company/EditCompanyGeneral";
import EditCompanyAttachments from "../pages/Masters/Company/EditCompanyAttachments";
import EditCompanyDocuments from "../pages/Masters/Company/EditCompanyDocuments";
import EditCompanyProjects from "../pages/Masters/Company/EditCompanyProjects";
import EditCompanyEmployees from "../pages/Masters/Company/EditCompanyEmployees";
import EditCompanyQuestionnaires from "../pages/Masters/Company/EditCompanyQuestionnaires";
import EditCompanyInvoices from "../pages/Masters/Company/EditCompanyInvoices";

import Fees from "../pages/Masters/Fees";
import CompanyCategory from "../pages/Masters/CompanyCategory";
import CompanyProfile from "../pages/Masters/CompanyProfile";
import TaskType from "../pages/Masters/TaskType";
import TaskStatus from "../pages/Masters/TaskStatus";
// import TaskPriority from "../pages/Masters/TaskPriority";
import QuestionnaireMaster from "../pages/Masters/QuestionnaireTab";
import QuestionnaireQuestions from "../pages/Masters/QuestionnaireQuestions/Questions";
import QuestionnaireList from "../pages/Questionnaire/List";
import QuestionnaireStatus from "../pages/Masters/QuestionnaireStatus";
import AllSubTasks from "../pages/Tasks/AllSubTasks";
import NewSubTask from "../pages/Tasks/NewSubTask";
import EditSubTask from "../pages/Tasks/EditSubTask";
import EditSubTaskTimesheetList from "../pages/Tasks/EditSubTaskTimesheetList";
import AllInvoices from "../pages/Invoices/AllInvoices";
import NewInvoice from "../pages/Invoices/NewInvoice";
import ViewInvoicePage from "../pages/Invoices/ViewInvoicePage";
import CreateCreditNode from "../pages/Invoices/CreateCreditNote";
// import CpoList from "../pages/ETC1/CPO/CpoList";
// import CreateCpo from "../pages/ETC1/CPO/CreateCpo";
// import EditCpo from "../pages/ETC1/CPO/EditCpo";
// import CpoCompute from "../pages/ETC1/CPO/CpoCompute";
import QuestionnaireTemplate from "../pages/Questionnaire/Template";
import TaskGraphicalView from "../pages/Tasks/Charts/index";
import KanbanBoard from "../pages/KanbanBoard/KanbanBoard";
import EditInvoice from "../pages/Invoices/EditInvoice";
import ViewCreditNotePage from "../pages/Invoices/ViewCreditNotePage";
import VatMaster from "../pages/Masters/Vat";
import Account from "../pages/Masters/Account";
import DocumentType from "../pages/Masters/DocumentType";
import Project from "../pages/Masters/Project";
import QuestionnaireType from "../pages/Masters/QuestionnaireType";

import SplitPayrollList from "../pages/SplitPayroll/SplitPayrollList";
import CreateSplitPayroll from "../pages/SplitPayroll/CreateSplitPayroll";
import UpdateSplitPayroll from "../pages/SplitPayroll/UpdateSplitPayroll";

import CPOList from "../pages/CPO/CPOList";
import CreateCPO from "../pages/CPO/CreateCPO";
import UpdateCPO from "../pages/CPO/UpdateCPO";
import ViewCPO from "../pages/CPO/ViewCPO";

import NewsFeed from "../pages/Masters/NewsFeed";
import SplitPayrollCountry from "../pages/Masters/SplitPayrollCountry";
import SplitPayrollYear from "../pages/Masters/SplitPayrollYear";
import CreateNewsFeed from "../pages/Masters/NewsFeed/CreateNewsFeed";
import EditNewsFeed from "../pages/Masters/NewsFeed/EditNewsFeed";
import NewsFeedView from "../pages/Masters/NewsFeed/NewsFeedView";
import TimesheetGraphicalView from "../pages/TimeSheets/GraphicalView";
import DocSignature from "../pages/ClientOnboarding/DocSignature";
import QuestionnaireGraphicalView from "../pages/Questionnaire/GraphicalView";

//TODO React Table
import ReactTablePage from "../pages/ReactTable/ReactTablePage";

//TODO email
import EmailInbox from "../pages/Email/";
import ArchiveTasks from "../pages/Tasks/ArchiveTasks";
import EmailTemplate from "../pages/Masters/Email/EmailTemplate";
import EmailTemplateList from "../pages/Masters/Email";
import VatCat from "../pages/Masters/VatCat";
import EditEmailTemplate from "../pages/Masters/Email/EditEmailTemplate";
import QuestionnaireCreate from "../pages/Questionnaire/QuestionnaireCreate";
import QuestionsForm from "../pages/Masters/QuestionnaireQuestions/QuestionsForm";
import EditQuestionsForm from "../pages/Masters/QuestionnaireQuestions/EditForm";
import UserGraphicalView from "../pages/Users/GraphicalView";
import GraphicalView from "../pages/Masters/Company/GraphicalView";
import Editor from "../pages/Masters/NewsLetter";
import NewNewsLetter from "../pages/Masters/NewsLetter/NewsLetter";

// User Manual
import UserManualList from "../pages/UserManual/UserManualList";
import CreateUserManual from "../pages/UserManual/CreateUserManual";
import UpdateUserManual from "../pages/UserManual/UpdateUserManual";

import Country from "../pages/Masters/Country";

import EmailCategory from "../pages/Masters/Email/EmailCategory";
import EditWorkdayCalender from "../pages/Users/EditWorkdayCalender";
import BankHoliday from "../pages/Masters/BankHoliday";
import Calender from "../pages/Masters/BankHoliday/Calender";

// Event
import EventCalendar from "../pages/Events/EventCalendar";
import EditForm from "../pages/Masters/QuestionnaireQuestions/EditForm";
import Alert from "../pages/Alert";

import EmailRead from "../pages/Email/EmailRead";
import EditTaskInvoicesList from "../pages/Tasks/EditTaskInvoicesList";
import TaxReturnType from "../pages/Masters/TaxReturnType";
import EditNewsLetter from "../pages/Masters/NewsLetter/EditNewsLetter";

// Client Allocation
import ClientAllocationUsersList from "../pages/ClientAllocation/ClientAllocationUsersList";
import ClientAllocationClientsList from "../pages/ClientAllocation/ClientAllocationClientsList";
import PayOnline from "../pages/Invoices/PayOnline";
import AccessRights from "../pages/Users/AccessRights";
import CPOGraphicalView from "../pages/CPO/Graph";
import SplitPayrollGraphicalView from "../pages/SplitPayroll/Graph";
import Page404 from "../pages/NotFound/Page404";
import AllIncident from "../pages/Issue/AllIssue";

// Banking PSD2
// import ConnectionList from "../pages/Banking/ConnectionList";
// import BankTransaction from "../pages/Banking/BankTransaction";
// import BankingINGCallback from "../pages/Banking/PSD2/ING/Callback";

// Banking SaltEdge
import Connections from "../pages/BankingV1/Connections";
import Transactions from "../pages/BankingV1/Transactions";

//Region
import AllRegion from "../pages/Masters/Region";
import AllBroadcast from "../pages/Broadcast";
import BroadcastView from "../pages/Broadcast/BroadcastView";
import IncidentView from "../pages/Issue/IncidentView";
import DocTemplates from "../pages/ClientOnboarding/Templates/DocTemplates";
import FileUpload from "../components/Common/FileUpload";
import DocTemplateList from "../pages/ClientOnboarding/Templates/DocTemplateList";
import EditTemplate from "../pages/ClientOnboarding/Templates/EditTemplate";
import EditSign from "../pages/ClientOnboarding/Templates/EditDocument";
import TemplateForm from "../pages/ClientOnboarding/Templates/TemplateForm";

const authProtectedRoutes = [
  // Dashboard
  {
    path: "/",
    key: "default",
    slug: "default",
    element: <Dashboard />,
  },
  {
    path: "/dashboard",
    key: "dashboard",
    slug: "default",
    element: <Dashboard />,
  },

  {
    path: "/dashboard/audit-logs",
    key: "audit.logs",
    slug: "default",
    element: <AuditLogs />,
  },
  {
    path: "/dashboard/notifications",
    key: "notifications",
    slug: "default",
    element: <Notifications />,
  },
  {
    path: "/file-system",
    key: "file.system",
    slug: "file-system",
    element: <Attachments />,
  },

  // Users
  {
    path: "/users/all-users",
    key: "users",
    slug: "all-users",
    element: <AllUsers />,
  },
  {
    path: "/users/all-users/:type",
    key: "users.archive",
    slug: "all-users",
    element: <AllUsers />,
  },
  {
    path: "/users/new-user",
    key: "user.new",
    slug: "all-users",
    element: <NewUser />,
  },
  {
    path: "/users/new-user/:id/:user_id",
    key: "user.duplicate",
    slug: "all-users",
    element: <NewUser />,
  },
  {
    path: "/users/user-details/:id/:user_id",
    key: "user.edit",
    slug: "all-users",
    element: <EditUser />,
  },
  {
    path: "/users/user-details/:id/:user_id/:type",
    key: "user.edit",
    slug: "all-users",
    element: <EditUser />,
  },

  {
    path: "/users/general/:id/:user_id",
    key: "user.general",
    slug: "all-users",
    element: <EditUserGeneral />,
  },
  {
    path: "/users/taxes/:id/:user_id",
    key: "user.taxes",
    slug: "all-users",
    element: <EditUserTaxes />,
  },
  {
    path: "/users/comments/:id/:user_id",
    key: "user.comments",
    slug: "all-users",
    element: <EditUserComments />,
  },
  {
    path: "/users/attachments/:id/:user_id",
    key: "user.attachments",
    slug: "all-users",
    element: <EditUserAttachments />,
  },
  {
    path: "/users/documents/:id/:user_id",
    key: "user.documents",
    slug: "all-users",
    element: <EditUserDocuments />,
  },
  {
    path: "/users/questionnaires/:id/:user_id",
    key: "user.questionnaires",
    slug: "all-users",
    element: <EditUserQuestionnaires />,
  },
  {
    path: "/users/signatures/:id/:user_id",
    key: "user.signatures",
    slug: "all-users",
    element: <EditUserSignatures />,
  },
  {
    path: "/users/notifications/:id/:user_id",
    key: "user.notifications",
    slug: "all-users",
    element: <EditUserNotifications />,
  },
  {
    path: "/users/invoices/:id/:user_id",
    key: "user.invoices",
    slug: "all-users",
    element: <EditUserInvoices />,
  },
  {
    path: "/users/workday-calender/:id/:user_id",
    key: "user.workday.calender",
    slug: "all-users",
    element: <EditWorkdayCalender />,
  },
  {
    path: "/users/access/:id/:user_id",
    key: "user.access",
    slug: "all-users",
    element: <AccessRights />,
  },

  // Bank holiday
  {
    path: "/masters/bank-holiday",
    key: "masters.bank.holiday",
    slug: "masters",
    element: <BankHoliday />,
  },
  {
    path: "/masters/bank-holiday/calender",
    key: "masters.bank.holiday.calender",
    slug: "masters",
    element: <Calender />,
  },

  {
    path: "/users/graph",
    key: "user.graph",
    slug: "all-users",
    element: <UserGraphicalView />,
  },

  // Tasks
  {
    path: "/tasks/all-tasks",
    key: "tasks",
    slug: "all-tasks",
    element: <AllTasks />,
  },
  {
    path: "/tasks/archive-task",
    key: "tasks.archive",
    slug: "all-tasks",
    element: <ArchiveTasks />,
  },
  {
    path: "/tasks/new-task",
    key: "task.new",
    slug: "all-tasks",
    element: <NewTask />,
  },
  {
    path: "/tasks/task-details/:id/:task_id",
    key: "task.edit",
    slug: "all-tasks",
    element: <EditTask />,
  },
  {
    path: "/tasks/subtasks/:id/:task_id",
    key: "task.subtasks.list",
    slug: "all-tasks",
    element: <EditTaskSubtaskList />,
  },
  {
    path: "/tasks/timesheet/:id/:task_id",
    key: "task.timesheet.list",
    slug: "all-tasks",
    element: <EditTaskTimesheetList />,
  },
  {
    path: "/tasks/invoices/:id/:task_id",
    key: "task.invoices.list",
    slug: "all-tasks",
    element: <EditTaskInvoicesList />,
  },
  {
    path: "/tasks/kanban-view",
    key: "task.kanban.view",
    slug: "all-tasks",
    element: <KanbanBoard />,
  },
  {
    path: "/tasks/graph",
    key: "task.allocation",
    slug: "all-tasks",
    element: <TaskGraphicalView />,
  },

  // SubTasks
  {
    path: "/tasks/all-sub-tasks",
    key: "subtask",
    slug: "all-sub-task",
    element: <AllSubTasks />,
  },
  {
    path: "/tasks/new-sub-task/:id/:task_id",
    key: "subtask.new",
    slug: "all-sub-task",
    element: <NewSubTask />,
  },
  {
    path: "/tasks/subtask-details/:id/:subtask_id",
    key: "subtask.edit",
    slug: "all-sub-task",
    element: <EditSubTask />,
  },
  {
    path: "/tasks/subtask/timesheet/:id/:subtask_id",
    key: "task.subtask.timesheet.list",
    slug: "all-sub-task",
    element: <EditSubTaskTimesheetList />,
  },

  // Invoices
  {
    path: "/invoices-management/all-invoices",
    key: "invoices",
    slug: "all-invoices",
    element: <AllInvoices />,
  },
  {
    path: "/invoices-management/invoices/:type",
    key: "invoices",
    slug: "all-invoices",
    element: <AllInvoices />,
  },
  {
    path: "/invoices-management/invoices/:type/:invoice_id/:dispute",
    key: "invoices",
    slug: "all-invoices",
    element: <AllInvoices />,
  },
  {
    path: "/invoices-management/new-invoice",
    key: "invoice.new",
    slug: "all-invoices",
    element: <NewInvoice />,
  },
  {
    path: "/invoices-management/new-credit-note/:id",
    key: "credit.note.new",
    slug: "all-invoices",
    element: <NewInvoice isCreditNote={true} />,
  },
  {
    path: "/invoices-management/edit-invoice/:id",
    key: "invoice.edit",
    slug: "all-invoices",
    element: <EditInvoice />,
  },
  {
    path: "/invoices-management/edit-invoice/:id/:invoice_id",
    key: "invoice.edit_1",
    slug: "all-invoices",
    element: <EditInvoice />,
  },
  {
    path: "/invoices-management/edit-credit-note/:id",
    key: "credit_note.edit",
    slug: "all-invoices",
    element: <EditInvoice isCreditNote={true} />,
  },
  {
    path: "/invoices-management/view-invoice/:id",
    key: "invoice.view",
    slug: "all-invoices",
    element: <ViewInvoicePage />,
  },
  {
    path: "/invoices-management/view-invoice/:id/:type",
    key: "invoice.view",
    slug: "all-invoices",
    element: <ViewInvoicePage />,
  },
  {
    path: "/invoices-management/view-credit-note/:id",
    key: "credit_note.view",
    slug: "all-invoices",
    element: <ViewCreditNotePage />,
  },
  {
    path: "/pay",
    key: "pay",
    slug: "all-invoices",
    element: <PayOnline />,
  },

  // CPO
  {
    path: "/cpo/list/:type",
    key: "cpo.list",
    slug: "all-cpo",
    element: <CPOList />,
  },
  {
    path: "/cpo/new-cpo",
    key: "cpo.new",
    slug: "all-cpo",
    element: <CreateCPO />,
  },
  {
    path: "/cpo/view-cpo/:id/:cpo_id",
    key: "cpo.view",
    slug: "all-cpo",
    element: <ViewCPO />,
  },
  {
    path: "/cpo/edit-cpo/:id/:cpo_id",
    key: "cpo.edit",
    slug: "all-cpo",
    element: <UpdateCPO />,
  },
  {
    path: "/cpo/graph",
    key: "cpo.graph",
    slug: "all-cpo",
    element: <CPOGraphicalView />,
  },

  // Split Payroll
  {
    path: "/split-payroll/list/:type",
    key: "split.payroll.list",
    slug: "all-split-payroll",
    element: <SplitPayrollList />,
  },
  {
    path: "/split-payroll/new-split-payroll",
    key: "split.payroll.new",
    slug: "all-split-payroll",
    element: <CreateSplitPayroll />,
  },
  {
    path: "/split-payroll/edit-split-payroll/:id/:split_payroll_id",
    key: "split.payroll.edit",
    slug: "all-split-payroll",
    element: <UpdateSplitPayroll />,
  },
  {
    path: "/split-payroll/graph",
    key: "split.payroll.graph",
    slug: "all-split-payroll",
    element: <SplitPayrollGraphicalView />,
  },

  // Time Sheets
  {
    path: "/time-sheets/all",
    key: "time.sheets.all",
    slug: "all-time-sheets",
    element: <AllTimeSheets />,
  },
  {
    path: "/time-sheets/all-time-sheets",
    key: "time.sheets.group",
    slug: "all-time-sheets",
    element: <AllTimeSheets />,
  },
  {
    path: "/time-sheets/graph",
    key: "time.sheets.graph",
    slug: "all-time-sheets",
    element: <TimesheetGraphicalView />,
  },
  {
    path: "/time-sheets/all-time-sheets/:type",
    key: "time.sheets.list",
    slug: "all-time-sheets",
    element: <AllTimeSheets />,
  },
  {
    path: "/time-sheets/all-time-sheets/:module_id/:module_main_id/:module_sub_id",
    key: "time.sheets.list",
    slug: "all-time-sheets",
    element: <AllTimeSheets />,
  },
  {
    path: "/time-sheets/new-time-sheet",
    key: "time.sheet.new",
    slug: "all-time-sheets",
    element: <NewTimeSheet />,
  },
  {
    path: "/time-sheets/edit-time-sheet/:id/:time_sheet_id",
    key: "time.sheet.edit",
    slug: "all-time-sheets",
    element: <EditTimeSheet />,
  },

  // Questionnaire
  {
    path: "/questionnaires/all-questionnaires",
    key: "questionnaires",
    slug: "all-questionnaires",
    element: <QuestionnaireList />,
  },
  {
    path: "/questionnaires/all-questionnaires/:status",
    key: "questionnaires.status",
    slug: "all-questionnaires",
    element: <QuestionnaireList />,
  },
  {
    path: "/questionnaires/edit-questionnaire/:id/:questionnaire_id",
    key: "questionnaire.edit",
    slug: "all-questionnaires",
    element: <QuestionnaireTemplate />,
  },
  {
    path: "/questionnaires/create-questionnaire",
    key: "questionnaire.create",
    slug: "all-questionnaires",
    element: <QuestionnaireCreate />,
  },
  {
    path: "/masters/questionnaire/create-questions",
    key: "questions.create",
    slug: "masters",
    element: <QuestionsForm />,
  },
  {
    path: "/masters/questionnaire/edit-question/:id/:question_id",
    key: "questions.edit",
    slug: "masters",
    element: <EditForm />,
  },
  {
    path: "/masters/questionnaire/duplicate-question/:id/:question_id/:duplicate",
    key: "questions.duplicate",
    slug: "masters",
    element: <EditForm />,
  },
  {
    path: "/questionnaires/graph",
    key: "questionnaires.graph",
    slug: "all-questionnaires",
    element: <QuestionnaireGraphicalView />,
  },

  // Masters ------------------------------------------------------------------------------------
  // User Function
  {
    path: "/masters/user-function",
    key: "masters.user.function",
    slug: "masters",
    element: <UserFunction />,
  },

  // User Type
  {
    path: "/masters/user-type",
    key: "masters.user.type",
    slug: "masters",
    element: <UserType />,
  },

  // User Status
  {
    path: "/masters/user-status",
    key: "masters.user.status",
    slug: "masters",
    element: <UserStatus />,
  },

  // Access Role
  {
    path: "/users/access-role",
    key: "users.access.role",
    slug: "access-role",
    element: <AccessRole />,
  },

  // Access Role
  // {
  //   path: "/masters/access-role-new",
  //   key: "masters.access.role.new",
  //   slug: "masters",
  //   element: <AccessRoleNew />,
  // },

  // Access Module
  // {
  //   path: "/masters/access-module",
  //   key: "masters.access.module",
  //   slug: "masters",
  //   element: <AccessModule />,
  // },

  // Company
  {
    path: "/masters/company",
    key: "masters.company",
    slug: "all-company",
    element: <AllCompany />,
  },
  {
    path: "/masters/company/:type",
    key: "masters.company.archive",
    slug: "all-company",
    element: <AllCompany />,
  },
  {
    path: "/masters/company/new-company",
    key: "masters.company.new",
    slug: "all-company",
    element: <NewCompany />,
  },
  {
    path: "/masters/company/company-details/:id/:company_id",
    key: "masters.company.edit",
    slug: "all-company",
    element: <EditCompany />,
  },
  {
    path: "/masters/company/company-details/:id/:company_id/:type",
    key: "masters.company.edit",
    slug: "all-company",
    element: <EditCompany />,
  },
  {
    path: "/masters/company/general/:id/:company_id",
    key: "masters.company.general",
    slug: "all-company",
    element: <EditCompanyGeneral />,
  },
  {
    path: "/masters/company/attachments/:id/:company_id",
    key: "masters.company.attachments",
    slug: "all-company",
    element: <EditCompanyAttachments />,
  },
  {
    path: "/masters/company/documents/:id/:company_id",
    key: "masters.company.documents",
    slug: "all-company",
    element: <EditCompanyDocuments />,
  },
  {
    path: "/masters/company/projects/:id/:company_id",
    key: "masters.company.projects",
    slug: "all-company",
    element: <EditCompanyProjects />,
  },
  {
    path: "/masters/company/employees/:id/:company_id",
    key: "masters.company.employees",
    slug: "all-company",
    element: <EditCompanyEmployees />,
  },
  {
    path: "/masters/company/questionnaires/:id/:company_id",
    key: "masters.company.questionnaires",
    slug: "all-company",
    element: <EditCompanyQuestionnaires />,
  },
  {
    path: "/masters/company/invoices/:id/:company_id",
    key: "masters.company.invoices",
    slug: "all-company",
    element: <EditCompanyInvoices />,
  },

  // {
  //   path: "/masters/company/documents/:id/:company_id",
  //   key: "masters.company.edit",
  //   slug: "all-company",
  //   element: <EditCompany type={5} />,
  // },
  // {
  //   path: "/masters/company/company-details/:id/:company_id/:type",
  //   key: "masters.company.edit.type",
  //   slug: "all-company",
  //   element: <EditCompany />,
  // },

  {
    path: "/masters/company/graph",
    key: "masters.company.graph",
    slug: "all-company",
    element: <GraphicalView />,
  },

  {
    path: "/masters/company-category",
    key: "masters.company.category",
    slug: "masters",
    element: <CompanyCategory />,
  },

  {
    path: "/masters/company-profile",
    key: "masters.company.profile",
    slug: "masters",
    element: <CompanyProfile />,
  },

  // Fees
  {
    path: "/masters/fees",
    key: "masters.fees",
    slug: "masters",
    element: <Fees />,
  },

  // Account
  {
    path: "/masters/account",
    key: "masters.account",
    slug: "masters",
    element: <Account />,
  },

  // Project
  {
    path: "/masters/projects",
    key: "masters.projects",
    slug: "projects",
    element: <Project />,
  },

  // Country
  {
    path: "/masters/country",
    key: "masters.country",
    slug: "masters",
    element: <Country />,
  },

  // Document type
  {
    path: "/masters/document-type",
    key: "masters.document_type",
    slug: "masters",
    element: <DocumentType />,
  },

  // Vat
  {
    path: "/masters/vat-master",
    key: "masters.vat",
    slug: "masters",
    element: <VatMaster />,
  },

  // VatCat
  {
    path: "/masters/vatcat",
    key: "masters.vatcat",
    slug: "masters",
    element: <VatCat />,
  },

  // Task Type
  {
    path: "/masters/task-type",
    key: "masters.task.type",
    slug: "masters",
    element: <TaskType />,
  },

  // Task Status
  {
    path: "/masters/task-status",
    key: "masters.task.status",
    slug: "masters",
    element: <TaskStatus />,
  },

  // Task Priority
  // {
  //   path: "/masters/task-priority",
  //   key: "masters.task.priority",
  //   slug: "masters",
  //   element: <TaskPriority />,
  // },

  // Questionnaire
  {
    path: "/masters/questionnaire-type",
    key: "masters.questionnaire.type",
    slug: "masters",
    element: <QuestionnaireType />,
  },
  {
    path: "/masters/questionnaire-tab",
    key: "masters.questionnaire.tab",
    slug: "masters",
    element: <QuestionnaireMaster />,
  },
  {
    path: "/masters/questionnaire-questions",
    key: "masters.questionnaire.questions",
    slug: "masters",
    element: <QuestionnaireQuestions />,
  },

  // Status
  {
    path: "/masters/questionnaire-status",
    key: "masters.questionnaire.status",
    slug: "masters",
    element: <QuestionnaireStatus />,
  },

  // News Feed
  {
    path: "/masters/news-feed",
    key: "masters.news.feed",
    slug: "news-feed",
    element: <NewsFeed />,
  },
  {
    path: "/news-feed/:type",
    key: "masters.news.feed.all",
    slug: "default",
    element: <NewsFeed />,
  },
  {
    path: "/masters/news-feed/create",
    key: "masters.news.feed.create",
    slug: "news-feed",
    element: <CreateNewsFeed />,
  },
  {
    path: "/masters/news-feed/edit/:id",
    key: "masters.news.feed.edit",
    slug: "news-feed",
    element: <EditNewsFeed />,
  },
  {
    path: "/news-feed/view/:id/:news_feed_id",
    key: "masters.news.feed.view",
    slug: "default",
    element: <NewsFeedView />,
  },

  // Client onboarding - Doc Sign
  
  {
    path: "/client-onboarding/doc-signature/list",
    key: "client.onboarding.doc.signature.list",
    slug: "doc-signature",
    element: <DocSignature />,
  },
  
  {
    path: "/client-onboarding/doc-signature/:id/:doc_id",
    key: "client.onboarding.doc.signature.edit",
    slug: "doc-signature",
    element: <EditSign />,
  },
  {
    path: "/client-onboarding/templates",
    key: "client.onboarding.template",
    slug: "doc-signature",
    element: <DocTemplates />,
  },
  {
    path: "/client-onboarding/template/list",
    key: "client.onboarding.template.list",
    slug: "doc-signature",
    element: <DocTemplateList />,
  },
  {
    path: "/client-onboarding/template/create",
    key: "client.onboarding.template.create",
    slug: "doc-signature",
    element: <TemplateForm />,
  },
  
  {
    path: "/client-onboarding/edit-template/:id/:template_id",
    key: "client.onboarding.template.edit",
    slug: "doc-signature",
    element: <TemplateForm />,
  },
  
  {
    path: "/client-onboarding/get-template/:id/:template_id",
    key: "client.onboarding.edit.template",
    slug: "doc-signature",
    element: <EditTemplate />,
  },
  // {
  //   path: "/client-onboarding/doc-signature/edit-template/:id/:template_id",
  //   key: "client.onboarding.doc.signature.edit",
  //   slug: "doc-signature",
  //   element: <DocumentForm />,
  // },
  // {
  //   path: "/client-onboarding/doc-signature/edit/:id/:doc_id",
  //   key: "client.onboarding.doc.signature.edit",
  //   slug: "doc-signature",
  //   element: <SignDocument />,
  // },

  {
    path: "/client-onboarding/doc-template/create",
    key: "client.onboarding.doc.template.create",
    slug: "doc-template",
    element: <FileUpload />,
  },
  
  // Split Payroll Country
  {
    path: "/masters/split-payroll-country",
    key: "masters.split.payroll.country",
    slug: "masters",
    element: <SplitPayrollCountry />,
  },
  {
    path: "/masters/split-payroll-year",
    key: "masters.split.payroll.year",
    slug: "masters",
    element: <SplitPayrollYear />,
  },

  //TODO React Table
  {
    path: "/react-table/table",
    key: "react.table.todo",
    slug: "masters",
    element: <ReactTablePage />,
  },

  //Email
  {
    path: "/email/inbox",
    key: "email.inbox",
    slug: "email",
    element: <EmailInbox />,
  },
  {
    path: "/alerts",
    key: "alerts",
    slug: "masters",
    element: <Alert />,
  },

  //Email Template
  {
    path: "/masters/email-template/new",
    key: "masters.email.template.new",
    slug: "masters",
    element: <EmailTemplate />,
  },
  {
    path: "/masters/email-template/edit/:id/:template_id",
    key: "masters.email.template.edit",
    slug: "masters",
    element: <EditEmailTemplate />,
  },
  {
    path: "/masters/email-template/list",
    key: "masters.email.template.list",
    slug: "masters",
    element: <EmailTemplateList />,
  },
  {
    path: "/masters/email-category/list",
    key: "masters.email.category.list",
    slug: "masters",
    element: <EmailCategory />,
  },

  {
    path: "/masters/news-letter/list",
    key: "masters.news.letter.new",
    slug: "news-letter",
    element: <Editor />,
  },
  {
    path: "/masters/news-letter/new",
    key: "masters.news.letter.new",
    slug: "news-letter",
    element: <NewNewsLetter />,
  },
  {
    path: "/masters/news-letter/edit/:id/:news_letter_id",
    key: "masters.news.letter.edit",
    slug: "news-letter",
    element: <EditNewsLetter />,
  },

  // User Manual
  {
    path: "/user-manual/list/:type",
    key: "user-manual.list",
    slug: "all-user-manual",
    element: <UserManualList />,
  },
  {
    path: "/user-manual/list/:id/:user_manual_id",
    key: "user-manual.list.manual",
    slug: "all-user-manual",
    element: <UserManualList />,
  },
  {
    path: "/user-manual/new-user-manual",
    key: "user-manual.new",
    slug: "all-user-manual",
    element: <CreateUserManual />,
  },
  // {
  //   path: "/user-manual/view-user-manual/:id/:user_manual_id",
  //   key: "user-manual.view",
  //   slug: "all-user-manual",
  //   element: <ViewUserManual />
  // },
  {
    path: "/user-manual/edit-user-manual/:id/:user_manual_id",
    key: "user-manual.edit",
    slug: "all-user-manual",
    element: <UpdateUserManual />,
  },

  // Event
  {
    path: "/event-calendar/events/:type",
    key: "event-calendar.events.all",
    slug: "all-events",
    element: <EventCalendar />,
  },

  // Email Read
  {
    path: "/email/inbox/:id",
    key: "email.inbox.read",
    slug: "email",
    element: <EmailRead />,
  },

  // Tax Return Type
  {
    path: "/masters/tax-return-type/list",
    key: "tax.return.type",
    slug: "masters",
    element: <TaxReturnType />,
  },

  // Region
  {
    path: "/masters/region/all",
    key: "region",
    slug: "masters",
    element: <AllRegion />,
  },

  // Client Allocation
  {
    path: "/client-allocation/clients",
    key: "client.allocation.clients",
    slug: "client-allocation",
    element: <ClientAllocationClientsList />,
  },
  {
    path: "/client-allocation/internal-users",
    key: "client.allocation.users",
    slug: "client-allocation",
    element: <ClientAllocationUsersList />,
  },
  {
    path: "/404",
    key: "404",
    element: <Page404 />,
  },
  {
    path: "/incident/list",
    key: "incident.tracking.list",
    slug: "incident",
    element: <AllIncident />,
  },
  {
    path: "/incident/view/:id/:incident_id",
    key: "incident.tracking.list",
    slug: "incident",
    element: <IncidentView />,
  },

  // Banking PSD2
  // {
  //   path: "/banking/all",
  //   key: "banking.list",
  //   slug: "banking",
  //   element: <ConnectionList />,
  // },
  // {
  //   path: "/banking/transactions/:id/:connection_id/:bank_id",
  //   key: "banking.transactions",
  //   slug: "banking",
  //   element: <BankTransaction />,
  // },
  // {
  //   path: "/banking/psd2/ing/callback",
  //   key: "banking.psd2.ing/callback",
  //   slug: "banking",
  //   element: <BankingINGCallback />,
  // },

  // Banking SaltEdge
  {
    path: "/banking/connections",
    key: "banking.connections",
    slug: "banking",
    element: <Connections />,
  },
  {
    path: "/banking/transactions/:customer_id/:connection_id/:account_id/",
    key: "banking.transactions",
    slug: "banking",
    element: <Connections />,
  },

  //Broadcast
  {
    path: "/broadcast/all",
    key: "broadcast.list",
    slug: "broadcast",
    element: <AllBroadcast />,
  },
  {
    path: "/broadcast/view/:id/:broadcast_id",
    key: "broadcast.view",
    slug: "broadcast",
    element: <BroadcastView />,
  },
];

const publicRoutes = [
  // Authentication
  {
    path: "/login",
    key: "login",
    element: <Login />,
  },
  {
    path: "/oauth/aad/callback",
    key: "oauth.aad.callback",
    element: <AzureADCallback />,
  },
  {
    path: "/oauth/aad/logout",
    key: "oauth.aad.logout",
    element: <AzureADLogout />,
  },
  {
    path: "/forgot-password",
    key: "forgot.password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password/:token/:email",
    key: "reset.password",
    element: <ResetPassword />,
  },
  {
    path: "/register-user",
    key: "login.register",
    element: <NewWebsiteUser />,
  },
  {
    path: "/register-user/gdpr-policy",
    key: "login.register.gdpr.policy",
    element: <GDPRPolicy />,
  },
  {
    path: "/register-user/privacy-policy",
    key: "login.register.privacy.policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/register-user/terms-conditions",
    key: "login.register.terms.conditions",
    element: <TermsConditions />,
  },
];

export { authProtectedRoutes, publicRoutes };
