import {
  SET_SIDEBAR_COLLAPSED,
  
  GET_NOTIFICATION_COUNT,
  GET_NOTIFICATION_COUNT_SUCCESS,
  GET_NOTIFICATION_COUNT_ERROR,
  SET_NOTIFICATION_FETCHED,

  GET_COMPANY_OPTIONS_LIST,
  GET_COMPANY_OPTIONS_LIST_SUCCESS,
  GET_COMPANY_OPTIONS_LIST_ERROR,
  GET_COMPANY_OPTIONS_LIST_NEW,
  GET_COMPANY_OPTIONS_LIST_SUCCESS_NEW,
  GET_COMPANY_OPTIONS_LIST_ERROR_NEW,

  GET_FEES_OPTIONS_LIST,
  GET_FEES_OPTIONS_LIST_SUCCESS,
  GET_FEES_OPTIONS_LIST_ERROR,
  GET_FEES_OPTIONS_LIST_NEW,
  GET_FEES_OPTIONS_LIST_SUCCESS_NEW,
  GET_FEES_OPTIONS_LIST_ERROR_NEW,

  GET_USER_OPTIONS_LIST,
  GET_USER_OPTIONS_LIST_SUCCESS,
  GET_USER_OPTIONS_LIST_ERROR,
  GET_USER_OPTIONS_LIST_NEW,
  GET_USER_OPTIONS_LIST_SUCCESS_NEW,
  GET_USER_OPTIONS_LIST_ERROR_NEW,

  CHANGE_TABLE_ORDER_ON_DRAG,
  CHANGE_TABLE_ORDER_ON_DRAG_SUCCESS,
  CHANGE_TABLE_ORDER_ON_DRAG_ERROR

} from "./actionTypes";

const initialState = {  
  GET_NOTIFICATION_COUNT_SUCCESS: "",
  GET_NOTIFICATION_COUNT_ERROR: "",

  GET_COMPANY_OPTIONS_LIST_SUCCESS: "",
  GET_COMPANY_OPTIONS_LIST_ERROR: "",
  GET_COMPANY_OPTIONS_LIST_SUCCESS_NEW: "",
  GET_COMPANY_OPTIONS_LIST_ERROR_NEW: "",

  GET_FEES_OPTIONS_LIST_SUCCESS: "",
  GET_FEES_OPTIONS_LIST_ERROR: "",
  GET_FEES_OPTIONS_LIST_SUCCESS_NEW: "",
  GET_FEES_OPTIONS_LIST_ERROR_NEW: "",

  GET_USER_OPTIONS_LIST_SUCCESS: "",
  GET_USER_OPTIONS_LIST_ERROR: "",
  GET_USER_OPTIONS_LIST_SUCCESS_NEW: "",
  GET_USER_OPTIONS_LIST_ERROR_NEW: "",

  sidebarcollpsed: false,
  notificationfetched: false,

  CHANGE_TABLE_ORDER_ON_DRAG_SUCCESS: "",
  CHANGE_TABLE_ORDER_ON_DRAG_ERROR: "",

  loading: false,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    // ---------------------------------------------SET_SIDEBAR_COLLAPSED
    case SET_SIDEBAR_COLLAPSED:
      state = {
        ...state,
        sidebarcollpsed: !action.payload
      };
      break;

    // ---------------------------------------------GET_NOTIFICATION_COUNT
    case GET_NOTIFICATION_COUNT:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_NOTIFICATION_COUNT_SUCCESS:
      state = {
        ...state,
        GET_NOTIFICATION_COUNT_SUCCESS: action.payload,
        loading: false,
      };
      break;

    case GET_NOTIFICATION_COUNT_ERROR:
      state = {
        ...state,
        GET_NOTIFICATION_COUNT_ERROR: action.payload,
        loading: false,
      };
      break;

    // ---------------------------------------------SET_NOTIFICATION_FETCHED
    case SET_NOTIFICATION_FETCHED:
      state = {
        ...state,
        notificationfetched: action.payload
      };
      break;

    // ---------------------------------------------GET_USER_OPTIONS_LIST
    case GET_USER_OPTIONS_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_USER_OPTIONS_LIST_SUCCESS:
      state = {
        ...state,
        GET_USER_OPTIONS_LIST_SUCCESS: action.payload,
        loading: false,
      };
      break;

    case GET_USER_OPTIONS_LIST_ERROR:
      state = {
        ...state,
        GET_USER_OPTIONS_LIST_ERROR: action.payload,
        loading: false,
      };
      break;
    // ---------------------------------------------GET_USER_OPTIONS_LIST_NEW
    case GET_USER_OPTIONS_LIST_NEW:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_USER_OPTIONS_LIST_SUCCESS_NEW:
      state = {
        ...state,
        GET_USER_OPTIONS_LIST_SUCCESS_NEW: action.payload,
        loading: false,
      };
      break;

    case GET_USER_OPTIONS_LIST_ERROR_NEW:
      state = {
        ...state,
        GET_USER_OPTIONS_LIST_ERROR_NEW: action.payload,
        loading: false,
      };
      break;

    // ---------------------------------------------GET_COMPANY_OPTIONS_LIST
    case GET_COMPANY_OPTIONS_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_COMPANY_OPTIONS_LIST_SUCCESS:
      state = {
        ...state,
        GET_COMPANY_OPTIONS_LIST_SUCCESS: action.payload,
        loading: false,
      };
      break;

    case GET_COMPANY_OPTIONS_LIST_ERROR:
      state = {
        ...state,
        GET_COMPANY_OPTIONS_LIST_ERROR: action.payload,
        loading: false,
      };
      break;
    // ---------------------------------------------GET_COMPANY_OPTIONS_LIST_NEW
    case GET_COMPANY_OPTIONS_LIST_NEW:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_COMPANY_OPTIONS_LIST_SUCCESS_NEW:
      state = {
        ...state,
        GET_COMPANY_OPTIONS_LIST_SUCCESS_NEW: action.payload,
        loading: false,
      };
      break;

    case GET_COMPANY_OPTIONS_LIST_ERROR_NEW:
      state = {
        ...state,
        GET_COMPANY_OPTIONS_LIST_ERROR_NEW: action.payload,
        loading: false,
      };
      break;

    // ---------------------------------------------GET_FEES_OPTIONS_LIST
    case GET_FEES_OPTIONS_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_FEES_OPTIONS_LIST_SUCCESS:
      state = {
        ...state,
        GET_FEES_OPTIONS_LIST_SUCCESS: action.payload,
        loading: false,
      };
      break;

    case GET_FEES_OPTIONS_LIST_ERROR:
      state = {
        ...state,
        GET_FEES_OPTIONS_LIST_ERROR: action.payload,
        loading: false,
      };
      break;
    // ---------------------------------------------GET_FEES_OPTIONS_LIST_NEW
    case GET_FEES_OPTIONS_LIST_NEW:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_FEES_OPTIONS_LIST_SUCCESS_NEW:
      state = {
        ...state,
        GET_FEES_OPTIONS_LIST_SUCCESS_NEW: action.payload,
        loading: false,
      };
      break;

    case GET_FEES_OPTIONS_LIST_ERROR_NEW:
      state = {
        ...state,
        GET_FEES_OPTIONS_LIST_ERROR_NEW: action.payload,
        loading: false,
      };
      break;


    // ---------------------------------------------CHANGE_TABLE_ORDER_ON_DRAG
     case CHANGE_TABLE_ORDER_ON_DRAG:
      state = {
        ...state,
        loading: true,
        CHANGE_TABLE_ORDER_ON_DRAG_SUCCESS:"",
        CHANGE_TABLE_ORDER_ON_DRAG_ERROR:""
      };
      break;

    case CHANGE_TABLE_ORDER_ON_DRAG_SUCCESS:
      state = {
        ...state,
        CHANGE_TABLE_ORDER_ON_DRAG_SUCCESS: action.payload,
        loading: false,
      };
      break;

    case CHANGE_TABLE_ORDER_ON_DRAG_ERROR:
      state = {
        ...state,
        CHANGE_TABLE_ORDER_ON_DRAG_ERROR: action.payload,
        loading: false,
      };
      break;

    // ---------------------------------------------DEFAULT
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default reducer;
