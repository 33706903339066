import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, CardHeader, Col, Container, Nav, NavItem, Row } from "reactstrap";
import Breadcrumbs from "../../components/Layout/Breadcrumb";
import { getUserProfile } from "../../helpers/functions_helper";
import Transactions from "./Transactions";
import NoDataFound from "../../components/NoDataFound";
import CreateLeads from "./CreateLeads";
import saltedgeService from "../../services/saltedge.service";
import { useDispatch, useSelector } from "react-redux";
import { SaltEdgeAction } from "../../store/actions";
import CreateLeadSessions from "./CreateLeadSessions";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import NavConnections from "./NavConnections";

const Connections = () => {
  const { t } = useTranslation();
  const toastId = React.useRef(null) as any;

  const { customer_id, connection_id, account_id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const userProfile = getUserProfile("banking");
  document.title = t("Bank connections") + " | " + process.env.REACT_APP_NAME;
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "#",
      label: t("Banking"),
      active: true,
    },
  ];

  let { providersData, connectionsData } = useSelector((state: any) => ({
    providersData: state.SaltEdge.GET_PROVIDERS_SUCCESS,
    connectionsData: state.SaltEdge.GET_CONNECTIONS_SUCCESS,
  }));

  const [bankData, setBankData] = useState<any>([]);

  const [loadingLeadsData, setLoadingLeadsData] = useState(true);
  const [leadsData, setLeadsData] = useState<any>([]);

  const [loadingConnections, setLoadingConnections] = useState(false);
  // const [connectionsData, setConnectionsData] = useState<any>([]);

  const getProviders = async (data: any) => {
    if (!providersData) dispatch(SaltEdgeAction.getProviders(data, {}));
  };

  const getLeads = async () => {
    const res = (await saltedgeService.getLeads()) as any;
    if (res?.data?.status === "success") {
      setLeadsData(res?.data?.data);
      setLoadingLeadsData(false);
      setLoadingConnections(true);
    } else {
      setLeadsData([]);
      setLoadingLeadsData(false);
    }
  };

  const getConnections = async (data: any) => {
    if (!connectionsData) dispatch(SaltEdgeAction.getConnections(data, {}));
  };

  const createLeadSessions = async () => {
    toastId.current = toast(t("Creating new connection..."), {
      autoClose: false,
      isLoading: true,
    });

    try {
      const res = (await saltedgeService.createLeadSessions()) as any;
      if (res?.data?.status === "success") {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        if (res?.data?.data?.data?.redirect_url) {
          window.open(res?.data?.data?.data?.redirect_url, "_self");
        }
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res?.data?.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
      }
    } catch (err: any) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
  };

  const reconnectLeadSessions = async (connection_id: any) => {
    toastId.current = toast(t("Reconnecting connection..."), {
      autoClose: false,
      isLoading: true,
    });

    try {
      const res = (await saltedgeService.reconnectLeadSessions(connection_id)) as any;
      if (res?.data?.status === "success") {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        if (res?.data?.data?.data?.redirect_url) {
          window.open(res?.data?.data?.data?.redirect_url, "_self");
        }
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res?.data?.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
      }
    } catch (err: any) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
  };

  // const initiatePayment = async () => {
  //   const data = {
  //     amount: "1",
  //     description: "Test payment",
  //     return_to: window.location.origin + window.location.pathname,
  //     end_to_end_id: "0000012345678910",
  //     customer_id: "1063289485950716670",
  //   };
  //   const res = (await saltedgeService.initiatePayment(data)) as any;

  //   if (res?.data?.data?.data?.connect_url) {
  //     window.open(res?.data?.data?.data?.connect_url, "_self");
  //   }
  // };

  // const getPayments = async () => {
  //   const data = {
  //     end_to_end_id: "00001333434", // searchParams.get("end_to_end_id") as any,
  //     customer_id: "1063289485950716670", // searchParams.get("customer_id") as any,
  //   };

  //   const res = (await saltedgeService.getPayments(data)) as any;
  //   console.log(res);
  // }

  useEffect(() => {
    getProviders({
      country_code: "BE",
    });
  }, []);

  useEffect(() => {
    if (providersData && providersData?.data) {
      var newData: any = [];
      providersData?.data?.map((data: any) => {
        newData.push({
          bank_id: data?.data?.id,
          bank_name: data?.data?.name,
          bank_logo: data?.data?.logo_url,
        });
      });
      setBankData(newData);
    }
  }, [providersData]);

  useEffect(() => {
    if (loadingLeadsData && !connection_id && !account_id) getLeads();
  }, [loadingLeadsData, connection_id, account_id]);

  useEffect(() => {
    if (loadingConnections || (connection_id && account_id)) {
      getConnections({
        customer_id: leadsData?.customer_id || customer_id,
        connection_id: searchParams.get("connection_id") as any,
      });
    }
  }, [loadingConnections, connection_id, account_id]);

  useEffect(() => {
    if (
      connectionsData &&
      connectionsData?.data?.length > 0 &&
      connectionsData?.data[0]?.connection_id &&
      (leadsData?.customer_id || customer_id)
    ) {
      return navigate(
        `/banking/transactions/${leadsData?.customer_id || customer_id}/${
          connectionsData?.data[0]?.connection_id
        }/${connectionsData?.data[0]?.account_id}`
      );
    }
  }, [connectionsData, leadsData?.customer_id, customer_id]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={t("Connections")}
            breadcrumbItems={breadcrumbItems}
            endAdornment={
              <div className="d-flex gap-2 text-end">
                {userProfile.CAN_CREATE &&
                (leadsData?.customer_id || customer_id) &&
                connectionsData?.data?.length > 0 ? (
                  <>
                    <Button
                      color="primary "
                      className="btn btn-label btn-sm"
                      onClick={() => createLeadSessions()}
                    >
                      <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                        add
                      </span>{" "}
                      {t("Create new connection")}
                    </Button>

                    <Button
                      color="primary "
                      className="btn btn-label btn-sm"
                      onClick={() => reconnectLeadSessions(connection_id)}
                    >
                      <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                        cloud_sync
                      </span>{" "}
                      {t("Reconnect connection")}
                    </Button>

                    {/* <Button
                      color="primary "
                      className="btn btn-label btn-sm"
                      onClick={() => initiatePayment()}
                    >
                      <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                        payments
                      </span>{" "}
                      {t("Payment initiate")}
                    </Button> */}

                    {/* <Button
                      color="primary "
                      className="btn btn-label btn-sm"
                      onClick={() => getPayments()}
                    >
                      <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                        payments
                      </span>{" "}
                      {t("Payment status")}
                    </Button> */}
                  </>
                ) : null}
              </div>
            }
          />

          <Row>
            <Col xl="12">
              <Card>
                {connectionsData &&
                  connectionsData?.data?.length > 0 &&
                  (leadsData?.customer_id || customer_id) && (
                    <NavConnections
                      customer_id={leadsData?.customer_id || customer_id}
                      connectionsData={connectionsData}
                      bankData={bankData}
                    />
                  )}

                <CardBody style={{ minHeight: "60vh" }}>
                  {!leadsData?.customer_id && !connection_id && !account_id && (
                    <CreateLeads
                      loading={loadingLeadsData}
                      onSuccess={(data: any) => setLeadsData(data)}
                    />
                  )}

                  {leadsData?.customer_id && connectionsData?.data?.length === 0 && (
                    <CreateLeadSessions loading={loadingConnections} />
                  )}

                  {(leadsData?.customer_id || customer_id) &&
                    connection_id &&
                    account_id &&
                    connectionsData?.data?.length > 0 && (
                      <Transactions
                        customer_id={leadsData?.customer_id || customer_id}
                        connection_id={connection_id}
                        account_id={account_id}
                      />
                    )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Connections;
