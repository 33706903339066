import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import useOnOutsideClick from '../shared/onOutsideClick';
import { KeyCodes } from '../shared/keyCodes';

import Dropdown from './Dropdown';
import {
  StyledSelect,
 } from './Styles';
import { More } from '../MoreUser/Styles';

const propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['normal', 'empty']),
  dropdownWidth: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.any,
  placeholder: PropTypes.string,
  invalid: PropTypes.bool,
  options: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  onCreate: PropTypes.func,
  isMulti: PropTypes.bool,
  withClearValue: PropTypes.bool,
  renderValue: PropTypes.func,
  renderOption: PropTypes.func,
  morelabel:PropTypes.string.isRequired,
  isUserPresent:PropTypes.bool.isRequired
};

const defaultProps = {
  className: undefined,
  variant: 'normal',
  dropdownWidth: undefined,
  name: undefined,
  value: undefined,
  defaultValue: undefined,
  placeholder: 'Select',
  invalid: false,
  onCreate: undefined,
  isMulti: false,
  withClearValue: true,
  renderValue: undefined,
  renderOption: undefined,
  morelabel:'',
  isUserPresent:false,
};

const SelectD = ({
  className,
  variant,
  dropdownWidth,
  name,
  value: propsValue,
  defaultValue,
  options,
  selectedOptions,
  onChange,
  onCreate,
  isMulti,
  withClearValue,
  renderValue: propsRenderValue,
  renderOption: propsRenderOption,
  morelabel,
  isUserPresent,
}:any) => {
  const [stateValue, setStateValue] = useState(defaultValue || (isMulti ? [] : null));
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const isControlled = propsValue !== undefined;
  const value = isControlled ? propsValue : stateValue;

  const $selectRef:any = useRef();
  const $inputRef:any = useRef();

  const activateDropdown = () => {
    if (isDropdownOpen) {
      $inputRef.current.focus();
    } else {
      options.sort((a:any, b:any) => selectedOptions.indexOf(b.value) - selectedOptions.indexOf(a.value))
      setDropdownOpen(true);
    }
  };

  const deactivateDropdown = () => {
    setDropdownOpen(false);
    setSearchValue('');
    $selectRef.current.focus();
  };

  useOnOutsideClick($selectRef, isDropdownOpen, deactivateDropdown);

  const preserveValueType = (newValue:any) => {
    
    const areOptionValuesNumbers = options.some((option:any) => typeof option.value === 'number');

    if (areOptionValuesNumbers) {
      if (isMulti) {
        return newValue.map(Number);
      }
      if (newValue) {
        return Number(newValue);
      }
    }
    return newValue;
  };

  const handleChange = (newValue:any) => {
    // if (!isControlled) {
    //   setStateValue(preserveValueType(newValue));
    //   console.log(preserveValueType(newValue));
    // }
    onChange(newValue[0]);
  };

  const handleFocusedSelectKeydown = (event:any) => {
    if (isDropdownOpen) return;

    if (event.keyCode === KeyCodes.ENTER) {
      event.preventDefault();
    }
    if (event.keyCode !== KeyCodes.ESCAPE && event.keyCode !== KeyCodes.TAB && !event.shiftKey) {
      setDropdownOpen(true);
    }
  };

  const getOption = (optionValue:any) => options.find((option:any) => option.value === optionValue);

  const isValueEmpty = isMulti ? !value.length : !getOption(value);
  
  return (
    <StyledSelect
      className={className}
      variant={variant}
      ref={$selectRef}
      onKeyDown={handleFocusedSelectKeydown}
    >
      <More 
      className={`avatar-title rounded-circle bg-info text-white avatar-kanban-group ${isUserPresent ? 'active-border':''}`}  
      onClick={activateDropdown}
      data-testid={name ? `select:${name}` : 'select'}
      >{morelabel}</More>

      {isDropdownOpen && (
        <Dropdown
          dropdownWidth={dropdownWidth}
          value={value}
          isValueEmpty={isValueEmpty}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          $selectRef={$selectRef}
          $inputRef={$inputRef}
          deactivateDropdown={deactivateDropdown}
          options={options}
          onChange={handleChange}
          isMulti={isMulti}
          withClearValue={withClearValue}
          propsRenderOption={propsRenderOption}
        />
      )}
    </StyledSelect>
  );
};

SelectD.propTypes = propTypes;
SelectD.defaultProps = defaultProps;

export default SelectD;
