import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class UsersGeneralService {

    async getUsersGeneral(DATA: any) {
        const response = await axios
            .get(SERVER_URL + url.USER_GENERAL.GET + "/" + DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async updateUsersGeneral(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.USER_GENERAL.UPDATE_GENERAL, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async updateUsersTaxes(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.USER_GENERAL.UPDATE_TAXES, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getSelectOptionsList(tableName: string, fieldName: string, fieldOrderName: string, orderBy: string, condition?: any | null) {
        const response = await axios
            .get(SERVER_URL + url.USER_GENERAL.GETOPTION + '/' + tableName + '/' + fieldName + '/' + fieldOrderName + '/' + orderBy,
                {
                    headers: getAxiosConfigWithToken(),
                    params: {condition: condition }
                }
            ).then(function (response) {
                return response.data.data;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async sendQuestionnaires(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.USER_GENERAL.SEND_QUESTIONNAIRE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

}

export default new UsersGeneralService();