import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CardFooter, Col } from 'reactstrap';
import Loader from '../../components/Layout/Loader';
import Drawer from '../../components/Drawer';
import WorkdayCalender from '../../components/WorkdayCalender/WorkdayCalender';
import moment from 'moment';
import { getUserProfile } from '../../helpers/functions_helper';

const WorkingCalender = (props: any) => {
    const userProfile = getUserProfile("all-users");
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [calenderView, setCalenderView] = useState(false);
    const user_id = props.user_id ? props.user_id : userProfile.USER_ID;
    const thisYear = props.year ? props.year : moment('YYYY');
    const onnexthandle = () => {
        setCalenderView(false);
        props.onClicknext();
    }

    const onnextsave = () => {
        setCalenderView(false);
        props.onSaveClick();
    }


    return (
        <React.Fragment>
            {loading ? <Loader /> : null}
            <Col xl={4} sm={3} className="mt-3">
                <Button color="primary" size="md" className="btn-block" onClick={() => setCalenderView(true)}>  {t('Work day Calendar')}</Button>
            </Col>
            {!!calenderView &&
                <Drawer
                    title={`Workday Calender ${thisYear}`}
                    open={calenderView}
                    onClose={() => setCalenderView((state) => !state)}
                    fullWidth
                >
                    <WorkdayCalender user_id={user_id} thisYear={thisYear} questionnaire={true} setCalenderView={setCalenderView} />
                    <div className="wizard clearfix">
                        <CardFooter className="d-flex flex-wrap gap-2 bg-transparent justify-content-end">
                            <Button
                                className="next"
                                onClick={onnextsave}
                                color="primary"
                                type='submit'>
                                {t("Save")}
                            </Button>
                            <Button
                                className="next"
                                onClick={onnexthandle}
                                color="primary"
                                type='submit'>
                                {t("Next")}
                            </Button>
                        </CardFooter>
                    </div>
                </Drawer>
            }
        </React.Fragment>
    )
}

export default WorkingCalender