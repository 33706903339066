import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Button,
} from "reactstrap";
import { vLookup } from "../../helpers/functions_helper";
import { formatCurrency } from "../../utils/formats";
import { useTranslation } from "react-i18next";

const SSSCLocalModel_21 = (props: any) => {
  const { t } = useTranslation();
  const spCountryData = props.spCountryData;
  const D_CAR = props.D_CAR;
  const P_COMM_EXEMPT = props.P_COMM_EXEMPT;
  const spInitialData = props.spInitialData;
  const personalInfoInitialData = props.personalInfoInitialData;
  const P_TAXES_EXEMPT_AMOUNT = props.P_TAXES_EXEMPT_AMOUNT;
  const P_Expenses = props.P_Expenses;
  const P_ATTRIBUTION = props.personalInfoInitialData.attribution_to_spouse;
  const P_TAXES = props.P_TAXES;
  const P_SSSC = props.P_SSSC;

  const [C_TOTAL_GROSS, setC_TOTAL_GROSS] = useState<number>(0);
  const [C_TOTAL_GROSS_D19, setC_TOTAL_GROSS_D19] = useState<number>(0);
  const [C_SS_TOTAL, setC_SS_TOTAL] = useState<number>(0);
  const [C_SS_TOTAL_D21, setC_SS_TOTAL_D21] = useState<number>(0);
  const [C_NET_TAXABLE, setC_NET_TAXABLE] = useState<number>(0);
  const [C_NET_TAXABLE_D40, setC_NET_TAXABLE_D40] = useState<number>(0);
  const [C_COMM_EXEMPT, setC_COMM_EXEMPT] = useState<number>(0);
  const [C_COMM_EXEMPT_D41, setC_COMM_EXEMPT_D41] = useState<number>(0);
  const [C_NET_TAXABLE2, setC_NET_TAXABLE2] = useState<number>(0);
  const [C_NET_TAXABLE2_D43, setC_NET_TAXABLE2_D43] = useState<number>(0);
  const [C_EXPENSES, setC_EXPENSES] = useState<number>(0);
  const [C_EXPENSES_D44, setC_EXPENSES_D44] = useState<number>(0);
  const [C_NET_TAXABLE3, setC_NET_TAXABLE3] = useState<number>(0);
  const [C_NET_TAXABLE3_WITHOUT_SP, setC_NET_TAXABLE3_WITHOUT_SP] = useState<number>(0);
  const [J49, setJ49] = useState<number>(0);
  const [K49, setK49] = useState<number>(0);
  const [C_ATTRIBUTION, setC_ATTRIBUTION] = useState<number>(0);
  const [C_GLOBAL_TAXABLE, setC_GLOBAL_TAXABLE] = useState<number>(0);
  const [C_GLOBAL_TAXES, setC_GLOBAL_TAXES] = useState<number>(0);
  const [C_GLOBAL_TAXES_J54, setC_GLOBAL_TAXES_J54] = useState<number>(0);
  const [C_TAXES_TAXFREE_AMOUNTS, setC_TAXES_TAXFREE_AMOUNTS] = useState<number>(0);
  const [C_TAXES_TAXFREE_CHILDREN, setC_TAXES_TAXFREE_CHILDREN] = useState<number>(0);
  const [C_PERCENT_GROUP_INS, setC_PERCENT_GROUP_INS] = useState<number>(30);
  const [C_CREDIT_GROUP_INS, setC_CREDIT_GROUP_INS] = useState<number>(0);
  const [C_CREDIT_GROUP_INS_J59, setC_CREDIT_GROUP_INS_J59] = useState<number>(0);
  const [C_TAXES_TAXPAYER, setC_TAXES_TAXPAYER] = useState<number>(0);
  const [C_TAXES_TAXPAYER_J61, setC_TAXES_TAXPAYER_J61] = useState<number>(0);
  const [C_NET_FOREIGN, setC_NET_FOREIGN] = useState<number>(0);
  const [C_FOREIGN_EXPENSES, setC_FOREIGN_EXPENSES] = useState<number>(0);
  const [C_FOREIGN_INCOME_DEDUCTION, setC_FOREIGN_INCOME_DEDUCTION] = useState<number>(0);
  const [C_TELLER, setC_TELLER] = useState<number>(0);
  const [C_DEDUCTION, setC_DEDUCTION] = useState<number>(0);
  const [C_GLOBAL_TAXES_SPOUSE, setC_GLOBAL_TAXES_SPOUSE] = useState<number>(0);
  const [C_TAXES_TAXFREE_AMOUNTS_SPOUSE, setC_TAXES_TAXFREE_AMOUNTS_SPOUSE] = useState<number>(0);
  const [C_TAXES_SPOUSE, setC_TAXES_SPOUSE] = useState<number>(0);
  const [C_TELLER_SPOUSE, setC_TELLER_SPOUSE] = useState<number>(0);
  const [C_DEDUCTION_SPOUSE, setC_DEDUCTION_SPOUSE] = useState<number>(0);
  const [C_DEDUCTION_B96, setC_DEDUCTION_B96] = useState<number>(0);
  const [C_DEDUCTION_SPOUSE_B96, setC_DEDUCTION_SPOUSE_B97] = useState<number>(0);
  const [C_TOTAL_TAX, setC_TOTAL_TAX] = useState<number>(0);
  const [C_COM_TAX, setC_COM_TAX] = useState<number>(0);
  const [C_COM_TAX_BELGIUM, setC_COM_TAX_BELGIUM] = useState<number>(0);
  const [C_TOTAL_TAX2, setC_TOTAL_TAX2] = useState<number>(0);
  const [C_SSSC, setC_SSSC] = useState<number>(0);
  const [C_TOTAL_TAX3, setC_TOTAL_TAX3] = useState<number>(0);
  const [C_TAXES_TAXPAYER_B109, setC_TAXES_TAXPAYER_B109] = useState<number>(0);
  const [C_TOTAL_TAX_BE, setC_TOTAL_TAX_BE] = useState<number>(0);
  const [C_COM_TAX_BE, setC_COM_TAX_BE] = useState<number>(0);
  const [C_TOTAL_TAX2_BE, setC_TOTAL_TAX2_BE] = useState<number>(0);
  const [C_SSSC_BE, setC_SSSC_BE] = useState<number>(0);
  const [C_TOTAL_TAX3_BE, setC_TOTAL_TAX3_BE] = useState<number>(0);

  useEffect(() => {
    if (spCountryData.length > 0) {
      let C_TOTAL_GROSS_VALUE = 0;
      let C_TOTAL_GROSS_D19_VALUE = 0;
      let C_SS_TOTAL_VALUE = 0;
      let C_SS_TOTAL_D21_VALUE = 0;
      spCountryData.map((item: any, idx: number) => {
        if (item?.emp_dir === 1) {
          C_TOTAL_GROSS_VALUE =
            C_TOTAL_GROSS_VALUE + Number(item?.D_GROSS_COUNTRY) + Number(item?.D_COMP_CAR);
          C_SS_TOTAL_VALUE = C_SS_TOTAL_VALUE + Number(item?.D_SS_COUNTRY);
        }
        if (item?.emp_dir === 2) {
          C_TOTAL_GROSS_D19_VALUE =
            C_TOTAL_GROSS_D19_VALUE + Number(item?.D_GROSS_COUNTRY) + Number(item?.D_COMP_CAR);
          C_SS_TOTAL_D21_VALUE = C_SS_TOTAL_D21_VALUE + Number(item?.D_SS_COUNTRY);
        }
      });
      setC_TOTAL_GROSS(C_TOTAL_GROSS_VALUE);
      setC_TOTAL_GROSS_D19(C_TOTAL_GROSS_D19_VALUE);
      setC_SS_TOTAL(C_SS_TOTAL_VALUE);
      setC_SS_TOTAL_D21(C_SS_TOTAL_D21_VALUE);
    }
  }, [spCountryData]);

  useEffect(() => {
    if (spCountryData.length > 0) {
      let C_NET_TAXABLE_VALUE = 0;
      let C_NET_TAXABLE_D40_VALUE = 0;
      spCountryData.map((item: any, idx: number) => {
        if (item?.emp_dir === 1) {
          if (idx !== 0) {
            C_NET_TAXABLE_VALUE = Number(C_NET_TAXABLE_VALUE) - Number(item?.C_TAXES_COUNTRY);
          }
        }
        if (item?.emp_dir === 2) {
          if (idx !== 0) {
            C_NET_TAXABLE_D40_VALUE =
              Number(C_NET_TAXABLE_D40_VALUE) - Number(item?.C_TAXES_COUNTRY);
          }
        }
      });
      setC_NET_TAXABLE(Number(C_NET_TAXABLE_VALUE) + Number(C_TOTAL_GROSS) - Number(C_SS_TOTAL));
      setC_NET_TAXABLE_D40(
        Number(C_NET_TAXABLE_D40_VALUE) + Number(C_TOTAL_GROSS_D19) - Number(C_SS_TOTAL_D21)
      );
    }
  }, [spCountryData, C_TOTAL_GROSS, C_TOTAL_GROSS_D19, C_SS_TOTAL, C_SS_TOTAL_D21]);

  useEffect(() => {
    if (D_CAR === 1) {
      setC_COMM_EXEMPT(0);
    } else {
      if (spCountryData[0]?.emp_dir === 1) {
        if (P_COMM_EXEMPT < spCountryData[0]?.D_COMP_CAR) {
          setC_COMM_EXEMPT(parseFloat(P_COMM_EXEMPT));
        } else {
          setC_COMM_EXEMPT(parseFloat(spCountryData[0]?.D_COMP_CAR));
        }
      } else {
        setC_COMM_EXEMPT(0);
      }
    }
  }, [D_CAR, spCountryData]);

  useEffect(() => {
    setC_NET_TAXABLE2(C_NET_TAXABLE - C_COMM_EXEMPT);
  }, [C_NET_TAXABLE, C_COMM_EXEMPT]);

  useEffect(() => {
    setC_NET_TAXABLE2_D43(C_NET_TAXABLE_D40 - C_COMM_EXEMPT_D41);
  }, [C_NET_TAXABLE_D40, C_COMM_EXEMPT_D41]);

  useEffect(() => {
    const VLOOKUP_4 = vLookup(C_NET_TAXABLE2, P_Expenses, 4);
    const VLOOKUP_3 = vLookup(C_NET_TAXABLE2, P_Expenses, 3);
    const VLOOKUP_1 = vLookup(C_NET_TAXABLE2, P_Expenses, 1);

    setC_EXPENSES(Math.round(VLOOKUP_4 + (C_NET_TAXABLE2 - VLOOKUP_1) * VLOOKUP_3));
  }, [C_NET_TAXABLE2, P_Expenses]);

  useEffect(() => {
    const B41 = Number(personalInfoInitialData.business_expenses_director_percentage);
    const B42 = Number(personalInfoInitialData.business_expenses_director_maximum_amount);
    const D43 = Number(C_NET_TAXABLE_D40) - Number(C_COMM_EXEMPT_D41);
    setC_EXPENSES_D44(Math.min(B42, D43 * B41));
  }, [
    personalInfoInitialData.business_expenses_director_percentage,
    personalInfoInitialData.business_expenses_director_maximum_amount,
    C_NET_TAXABLE_D40,
    C_COMM_EXEMPT_D41,
  ]);

  useEffect(() => {
    setC_NET_TAXABLE3(C_NET_TAXABLE2 - C_EXPENSES + C_NET_TAXABLE2_D43 - C_EXPENSES_D44);
  }, [C_NET_TAXABLE2, C_EXPENSES, C_NET_TAXABLE2_D43, C_EXPENSES_D44]);

  useEffect(() => {
    const SUM_J45_J47 = C_TOTAL_GROSS - C_SS_TOTAL - C_COMM_EXEMPT;

    const VLOOKUP_4 = vLookup(SUM_J45_J47, P_Expenses, 4);
    const VLOOKUP_3 = vLookup(SUM_J45_J47, P_Expenses, 3);
    const VLOOKUP_1 = vLookup(SUM_J45_J47, P_Expenses, 1);

    setJ49(SUM_J45_J47 - VLOOKUP_4 + (SUM_J45_J47 - VLOOKUP_1) * VLOOKUP_3);
  }, [C_TOTAL_GROSS, C_SS_TOTAL, C_COMM_EXEMPT, P_Expenses]);

  useEffect(() => {
    const B41 = personalInfoInitialData.business_expenses_director_percentage;
    const B42 = personalInfoInitialData.business_expenses_director_maximum_amount;
    const SUM_K45_K47 = C_TOTAL_GROSS_D19 - C_SS_TOTAL_D21;

    setK49(SUM_K45_K47 - Math.min(B42, SUM_K45_K47 * B41));
  }, [
    personalInfoInitialData.business_expenses_director_percentage,
    personalInfoInitialData.business_expenses_director_maximum_amount,
    C_TOTAL_GROSS_D19,
    C_SS_TOTAL_D21,
  ]);

  useEffect(() => {
    const D_FAM_STAT = parseInt(spInitialData.family_status);
    const D_ATTRIBUTION = parseInt(spInitialData.spouse_earns);
    const C_NET_TAXABLE3_30_PER = (C_NET_TAXABLE3 * 30) / 100;

    if (D_FAM_STAT === 2 && D_ATTRIBUTION === 2) {
      if (C_NET_TAXABLE3_30_PER > P_ATTRIBUTION) {
        setC_ATTRIBUTION(parseFloat(P_ATTRIBUTION));
      } else {
        setC_ATTRIBUTION(C_NET_TAXABLE3_30_PER);
      }
    } else {
      setC_ATTRIBUTION(0);
    }
  }, [spInitialData.family_status, spInitialData.spouse_earns, C_NET_TAXABLE3, P_ATTRIBUTION]);

  useEffect(() => {
    setC_GLOBAL_TAXABLE(C_NET_TAXABLE3 - C_ATTRIBUTION);
  }, [C_NET_TAXABLE3, C_ATTRIBUTION]);

  useEffect(() => {
    const VLOOKUP_4 = vLookup(C_GLOBAL_TAXABLE, P_TAXES, 4);
    const VLOOKUP_3 = vLookup(C_GLOBAL_TAXABLE, P_TAXES, 3);
    const VLOOKUP_1 = vLookup(C_GLOBAL_TAXABLE, P_TAXES, 1);
    setC_GLOBAL_TAXES(VLOOKUP_4 + (C_GLOBAL_TAXABLE - VLOOKUP_1) * VLOOKUP_3);
  }, [C_GLOBAL_TAXABLE, P_TAXES]);

  useEffect(() => {
    const J52 = J49 + K49 - C_ATTRIBUTION;
    const VLOOKUP_4 = vLookup(J52, P_TAXES, 4);
    const VLOOKUP_3 = vLookup(J52, P_TAXES, 3);
    const VLOOKUP_1 = vLookup(J52, P_TAXES, 1);
    setC_GLOBAL_TAXES_J54(VLOOKUP_4 + (J52 - VLOOKUP_1) * VLOOKUP_3);
  }, [J49, K49, C_ATTRIBUTION, P_TAXES]);

  useEffect(() => {
    const P_TAXFREE_TAXPAYER = Number(personalInfoInitialData.tax_free_amount_for_taxpayer);
    const P_INFO_I14 =
      P_TAXES_EXEMPT_AMOUNT[0] && P_TAXES_EXEMPT_AMOUNT[0][2] ? P_TAXES_EXEMPT_AMOUNT[0][2] : 0;
    setC_TAXES_TAXFREE_AMOUNTS(P_TAXFREE_TAXPAYER * P_INFO_I14);
  }, [personalInfoInitialData.tax_free_amount_for_taxpayer, P_TAXES_EXEMPT_AMOUNT]);

  useEffect(() => {
    const D_FAM_STAT = parseInt(spInitialData.family_status);
    const P_TAXFREE_TAXPAYER = personalInfoInitialData.tax_free_amount_for_taxpayer;

    if (D_FAM_STAT === 2) {
      setC_TAXES_TAXFREE_CHILDREN(
        vLookup(P_TAXFREE_TAXPAYER, P_TAXES_EXEMPT_AMOUNT, 4) +
          (P_TAXFREE_TAXPAYER - vLookup(P_TAXFREE_TAXPAYER, P_TAXES_EXEMPT_AMOUNT, 1)) *
            vLookup(P_TAXFREE_TAXPAYER, P_TAXES_EXEMPT_AMOUNT, 3) -
          C_TAXES_TAXFREE_AMOUNTS
      );
    } else {
      setC_TAXES_TAXFREE_CHILDREN(
        vLookup(P_TAXFREE_TAXPAYER, P_TAXES_EXEMPT_AMOUNT, 4) +
          (P_TAXFREE_TAXPAYER - vLookup(P_TAXFREE_TAXPAYER, P_TAXES_EXEMPT_AMOUNT, 1)) *
            vLookup(P_TAXFREE_TAXPAYER, P_TAXES_EXEMPT_AMOUNT, 3) -
          C_TAXES_TAXFREE_AMOUNTS
      );
    }
  }, [
    spInitialData.family_status,
    personalInfoInitialData.tax_free_amount_for_taxpayer,
    C_TAXES_TAXFREE_AMOUNTS,
    P_TAXES_EXEMPT_AMOUNT,
  ]);

  useEffect(() => {
    const D_GROUP_INS = spInitialData.employee_group_insurance_premiums;
    setC_CREDIT_GROUP_INS(D_GROUP_INS * (C_PERCENT_GROUP_INS / 100));
    setC_CREDIT_GROUP_INS_J59(D_GROUP_INS * (C_PERCENT_GROUP_INS / 100));
  }, [spInitialData.employee_group_insurance_premiums, C_PERCENT_GROUP_INS]);

  useEffect(() => {
    setC_TAXES_TAXPAYER(
      Math.max(
        C_GLOBAL_TAXES - C_TAXES_TAXFREE_AMOUNTS - C_TAXES_TAXFREE_CHILDREN - C_CREDIT_GROUP_INS,
        0
      )
    );
  }, [C_GLOBAL_TAXES, C_TAXES_TAXFREE_AMOUNTS, C_TAXES_TAXFREE_CHILDREN, C_CREDIT_GROUP_INS]);

  useEffect(() => {
    setC_TAXES_TAXPAYER_J61(
      Math.max(
        C_GLOBAL_TAXES_J54 -
          C_TAXES_TAXFREE_AMOUNTS -
          C_TAXES_TAXFREE_CHILDREN -
          C_CREDIT_GROUP_INS,
        0
      )
    );
  }, [C_GLOBAL_TAXES_J54, C_TAXES_TAXFREE_AMOUNTS, C_TAXES_TAXFREE_CHILDREN, C_CREDIT_GROUP_INS]);

  useEffect(() => {
    // ESTONIA - 67
    // LUXEMBOURG - 124
    // POLAND - 171
    // ROMANIA - 176
    // SPAIN - 199
    // UNITED STATES - 226

    let C_NET_FOREIGN_TOTAL = 0;
    if (spCountryData) {
      spCountryData.map((item: any, idx: number) => {
        if (
          parseInt(item?.country_id) === 67 ||
          parseInt(item?.country_id) === 124 ||
          parseInt(item?.country_id) === 171 ||
          parseInt(item?.country_id) === 176 ||
          parseInt(item?.country_id) === 199 ||
          parseInt(item?.country_id) === 226
        ) {
          C_NET_FOREIGN_TOTAL =
            C_NET_FOREIGN_TOTAL +
            Number(item?.D_GROSS_COUNTRY) -
            Number(item?.D_SS_COUNTRY) -
            Number(item?.C_TAXES_COUNTRY);
        }
      });
    }
    setC_NET_FOREIGN(C_NET_FOREIGN_TOTAL);
  }, [spCountryData]);

  useEffect(() => {
    let C_SS_TOTAL_VALUE = 0;
    let C_TAXES_TOTAL = 0;
    let SUM_B7_B16 = 0;
    let SUM_D7_D16 = 0;

    if (spCountryData) {
      spCountryData.map((item: any, idx: number) => {
        C_SS_TOTAL_VALUE = C_SS_TOTAL_VALUE + item?.D_SS_COUNTRY;
        if (idx !== 0) {
          C_TAXES_TOTAL = C_TAXES_TOTAL + item?.C_TAXES_COUNTRY;

          if (item?.emp_dir === 1) {
            SUM_B7_B16 = SUM_B7_B16 + item?.D_GROSS_COUNTRY;
          }
          if (item?.emp_dir === 2) {
            SUM_D7_D16 = SUM_D7_D16 + item?.D_GROSS_COUNTRY;
          }
        }
      });
    }

    // Foreign employee and director
    const G70 =
      ((C_EXPENSES + C_EXPENSES_D44) /
        (C_TOTAL_GROSS + C_TOTAL_GROSS_D19 + -C_SS_TOTAL + -C_TAXES_TOTAL - C_COMM_EXEMPT)) *
      C_NET_FOREIGN;

    // Only foreign employee
    const I70 =
      (C_EXPENSES / (C_TOTAL_GROSS + -C_SS_TOTAL + -C_TAXES_TOTAL - C_COMM_EXEMPT)) * C_NET_FOREIGN;

    // Only foreign director
    const K70 =
      (C_EXPENSES_D44 / (C_TOTAL_GROSS_D19 + -C_TAXES_TOTAL - C_COMM_EXEMPT)) * C_NET_FOREIGN;

    if (SUM_B7_B16 > 0 && SUM_D7_D16 > 0) {
      setC_FOREIGN_EXPENSES(G70);
    } else if (SUM_B7_B16 > 0) {
      setC_FOREIGN_EXPENSES(I70);
    } else if (SUM_D7_D16 > 0) {
      setC_FOREIGN_EXPENSES(K70);
    } else {
      setC_FOREIGN_EXPENSES(0);
    }
  }, [
    spCountryData,
    C_EXPENSES,
    C_EXPENSES_D44,
    C_TOTAL_GROSS,
    C_TOTAL_GROSS_D19,
    C_COMM_EXEMPT,
    C_NET_FOREIGN,
  ]);

  useEffect(() => {
    setC_FOREIGN_INCOME_DEDUCTION(C_NET_FOREIGN - C_FOREIGN_EXPENSES);
  }, [C_NET_FOREIGN, C_FOREIGN_EXPENSES]);

  useEffect(() => {
    let C_TELLER_VALUE =
      ((C_NET_FOREIGN - C_FOREIGN_EXPENSES) / (C_GLOBAL_TAXABLE + C_ATTRIBUTION)) *
      C_GLOBAL_TAXABLE;

    if (!isNaN(C_TELLER_VALUE)) {
      setC_TELLER(C_TELLER_VALUE);
    } else {
      setC_TELLER(0);
    }
  }, [C_NET_FOREIGN, C_FOREIGN_EXPENSES, C_GLOBAL_TAXABLE, C_ATTRIBUTION]);

  useEffect(() => {
    let C_DEDUCTION_VALUE =
      (C_TAXES_TAXPAYER / C_GLOBAL_TAXABLE) *
      (((C_NET_FOREIGN - C_FOREIGN_EXPENSES) / (C_GLOBAL_TAXABLE + C_ATTRIBUTION)) *
        C_GLOBAL_TAXABLE);

    if (!isNaN(C_DEDUCTION_VALUE)) {
      setC_DEDUCTION(C_DEDUCTION_VALUE);
    } else {
      setC_DEDUCTION(0);
    }
  }, [C_TAXES_TAXPAYER, C_GLOBAL_TAXABLE, C_NET_FOREIGN, C_FOREIGN_EXPENSES, C_ATTRIBUTION]);

  useEffect(() => {
    setC_GLOBAL_TAXES_SPOUSE(
      vLookup(C_ATTRIBUTION, P_TAXES, 4) +
        (C_ATTRIBUTION - vLookup(C_ATTRIBUTION, P_TAXES, 1)) * vLookup(C_ATTRIBUTION, P_TAXES, 3)
    );
  }, [C_ATTRIBUTION, P_TAXES]);

  useEffect(() => {
    const P_INFO_I14 = P_TAXES_EXEMPT_AMOUNT[0] && P_TAXES_EXEMPT_AMOUNT[0][2];
    const D_FAM_STAT = parseInt(spInitialData.family_status);
    const D_ATTRIBUTION = parseInt(spInitialData.spouse_earns);
    const P_TAXFREE_MARRIED = personalInfoInitialData.tax_free_amount_for_attribution_to_spouse;

    if (D_FAM_STAT === 2 && D_ATTRIBUTION === 2) {
      setC_TAXES_TAXFREE_AMOUNTS_SPOUSE(P_TAXFREE_MARRIED * P_INFO_I14);
    } else {
      setC_TAXES_TAXFREE_AMOUNTS_SPOUSE(0);
    }
  }, [
    P_TAXES_EXEMPT_AMOUNT,
    spInitialData.family_status,
    spInitialData.spouse_earns,
    personalInfoInitialData.tax_free_amount_for_attribution_to_spouse,
  ]);

  useEffect(() => {
    setC_TAXES_SPOUSE(Math.max(0, C_GLOBAL_TAXES_SPOUSE - C_TAXES_TAXFREE_AMOUNTS_SPOUSE));
  }, [C_GLOBAL_TAXES_SPOUSE, C_TAXES_TAXFREE_AMOUNTS_SPOUSE]);

  useEffect(() => {
    let C_TELLER_SPOUSE_VALUE =
      ((C_NET_FOREIGN - C_FOREIGN_EXPENSES) / (C_GLOBAL_TAXABLE + C_ATTRIBUTION)) * C_ATTRIBUTION;

    if (!isNaN(C_TELLER_SPOUSE_VALUE)) {
      setC_TELLER_SPOUSE(C_TELLER_SPOUSE_VALUE);
    } else {
      setC_TELLER_SPOUSE(0);
    }
  }, [C_NET_FOREIGN, C_FOREIGN_EXPENSES, C_GLOBAL_TAXABLE, C_ATTRIBUTION]);

  useEffect(() => {
    const D_FAM_STAT = parseInt(spInitialData.family_status);
    const D_ATTRIBUTION = parseInt(spInitialData.spouse_earns);

    if (D_FAM_STAT === 2 && D_ATTRIBUTION === 2) {
      setC_DEDUCTION_SPOUSE((C_TAXES_SPOUSE / C_ATTRIBUTION) * C_TELLER_SPOUSE);
    } else {
      setC_DEDUCTION_SPOUSE(0);
    }
  }, [
    spInitialData.family_status,
    spInitialData.spouse_earns,
    C_TAXES_SPOUSE,
    C_ATTRIBUTION,
    C_TELLER_SPOUSE,
  ]);

  useEffect(() => {
    let SUM_B7_B16 = 0;
    let SUM_D7_D16 = 0;

    if (spCountryData) {
      spCountryData.map((item: any, idx: number) => {
        if (idx !== 0) {
          if (item?.emp_dir === 1) {
            SUM_B7_B16 = SUM_B7_B16 + item?.D_GROSS_COUNTRY;
          }
          if (item?.emp_dir === 2) {
            SUM_D7_D16 = SUM_D7_D16 + item?.D_GROSS_COUNTRY;
          }
        }
      });
    }

    if (SUM_B7_B16 === 0 && SUM_D7_D16 === 0) {
      setC_DEDUCTION_B96(0);
      setC_DEDUCTION_SPOUSE_B97(0);
    } else {
      setC_DEDUCTION_B96(C_DEDUCTION);
      setC_DEDUCTION_SPOUSE_B97(C_DEDUCTION_SPOUSE);
    }
  }, [spCountryData, C_DEDUCTION, C_DEDUCTION_SPOUSE]);

  useEffect(() => {
    setC_TOTAL_TAX(C_TAXES_TAXPAYER + C_TAXES_SPOUSE - C_DEDUCTION_B96 - C_DEDUCTION_SPOUSE_B96);
  }, [C_TAXES_TAXPAYER, C_TAXES_SPOUSE, C_DEDUCTION_B96, C_DEDUCTION_SPOUSE_B96]);

  useEffect(() => {
    const D_COM_TAX = spInitialData.municipal_tax;
    setC_COM_TAX(Number(C_TOTAL_TAX) * (Number(D_COM_TAX) / 100));
  }, [C_TOTAL_TAX, spInitialData.municipal_tax]);

  useEffect(() => {
    setC_TOTAL_TAX2(C_TOTAL_TAX + C_COM_TAX);
  }, [C_TOTAL_TAX, C_COM_TAX]);

  useEffect(() => {
    if (C_TOTAL_GROSS > 0) {
      setC_SSSC(
        vLookup(C_NET_TAXABLE3 - C_FOREIGN_INCOME_DEDUCTION, P_SSSC, 2) +
          vLookup(C_NET_TAXABLE3 - C_FOREIGN_INCOME_DEDUCTION, P_SSSC, 3) *
            (C_NET_TAXABLE3 -
              C_FOREIGN_INCOME_DEDUCTION -
              vLookup(C_NET_TAXABLE3 - C_FOREIGN_INCOME_DEDUCTION, P_SSSC, 4))
      );
    } else {
      setC_SSSC(0);
    }
  }, [C_TOTAL_GROSS, C_NET_TAXABLE3, C_FOREIGN_INCOME_DEDUCTION, P_SSSC]);

  useEffect(() => {
    const P_INFO_C63 = parseInt(spInitialData.sssc_application);
    if (P_INFO_C63 === 1) {
      setC_TOTAL_TAX3(C_TOTAL_TAX2 + C_SSSC);
    } else {
      setC_TOTAL_TAX3(C_TOTAL_TAX2);
    }
  }, [spInitialData.sssc_application, C_TOTAL_TAX2, C_SSSC]);

  useEffect(() => {
    setC_TAXES_TAXPAYER_B109(
      Math.max(
        C_GLOBAL_TAXES_J54 -
          C_TAXES_TAXFREE_AMOUNTS -
          C_TAXES_TAXFREE_CHILDREN -
          C_CREDIT_GROUP_INS_J59,
        0
      )
    );
  }, [
    C_GLOBAL_TAXES_J54,
    C_TAXES_TAXFREE_AMOUNTS,
    C_TAXES_TAXFREE_CHILDREN,
    C_CREDIT_GROUP_INS_J59,
  ]);

  useEffect(() => {
    setC_TOTAL_TAX_BE(C_TAXES_TAXPAYER_B109 + C_TAXES_SPOUSE);
  }, [C_TAXES_TAXPAYER_B109, C_TAXES_SPOUSE]);

  useEffect(() => {
    const D_COM_TAX = parseFloat(spInitialData.municipal_tax);
    setC_COM_TAX_BE(C_TOTAL_TAX_BE * (D_COM_TAX / 100));
  }, [spInitialData.municipal_tax, C_TOTAL_TAX_BE]);

  useEffect(() => {
    setC_TOTAL_TAX2_BE(C_TOTAL_TAX_BE + C_COM_TAX_BE);
  }, [C_TOTAL_TAX_BE, C_COM_TAX_BE]);

  useEffect(() => {
    if (J49 > 0) {
      const SUM_J49_K49 = J49 + K49;
      setC_SSSC_BE(
        vLookup(SUM_J49_K49, P_SSSC, 2) +
          vLookup(SUM_J49_K49, P_SSSC, 3) * (SUM_J49_K49 - vLookup(SUM_J49_K49, P_SSSC, 4))
      );
    } else {
      setC_SSSC_BE(0);
    }
  }, [J49, K49, P_SSSC]);

  useEffect(() => {
    const P_INFO_C63 = parseInt(spInitialData.sssc_application);
    if (P_INFO_C63 === 1) {
      setC_TOTAL_TAX3_BE(C_TOTAL_TAX2_BE + C_SSSC_BE);
    } else {
      setC_TOTAL_TAX3_BE(C_TOTAL_TAX2_BE);
    }
  }, [spInitialData.sssc_application, C_TOTAL_TAX2_BE, C_SSSC_BE]);

  useEffect(() => {
    props.onCalculationUpdate({ C_COM_TAX_BELGIUM: C_COM_TAX });
  }, [C_COM_TAX]);

  return (
    <React.Fragment>
      <Modal
        size="xl"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">
            {t("BELGIUM")}
            <br />
            {t(
              "THIS IS A COPY OF THE BELGIAN TAX COMPUTATION FOR THE LOCAL TAX CALC IN CASE OF GERMANY - HOLLAND - FRANCE - UK"
            )}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label={t("Close")}
          ></button>
        </div>

        <ModalBody>
          <Card>
            <CardHeader>
              <CardTitle>1. {t("COMPUTATION OF SOCIAL SECURITY")}</CardTitle>
            </CardHeader>

            <CardBody>
              <div className="table-responsive mb-3">
                <Table className="table table-hover nowrap w-100 mb-0">
                  <thead>
                    <tr className="align-bottom">
                      <th></th>
                      <th className="text-end">{t("Employee")}</th>
                      <th className="text-end">{t("Director")}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {spCountryData &&
                      spCountryData.map((item: any, idx: number) => (
                        <tr key={idx}>
                          <td>
                            {t("GROSS")} {item?.country_name}
                          </td>
                          <td className="text-end">
                            {item?.emp_dir === 1
                              ? formatCurrency(parseFloat(item?.D_GROSS_COUNTRY).toFixed(2), false)
                              : "0,00"}
                          </td>
                          <td className="text-end">
                            {item?.emp_dir === 2
                              ? formatCurrency(parseFloat(item?.D_GROSS_COUNTRY).toFixed(2), false)
                              : "0,00"}
                          </td>
                          <td>
                            Annual gross salary in {item?.country_name}
                            {idx === 0 ? t(" (fringe benefit not included)") : null}
                          </td>
                        </tr>
                      ))}
                    <tr>
                      <td>{t("Fringe benefit")}</td>
                      <td className="text-end">
                        {spCountryData[0]?.emp_dir === 1
                          ? formatCurrency(
                              parseFloat(spCountryData[0]?.D_COMP_CAR).toFixed(2),
                              false
                            )
                          : "0,00"}
                      </td>
                      <td className="text-end">
                        {spCountryData[0]?.emp_dir === 2
                          ? formatCurrency(
                              parseFloat(spCountryData[0]?.D_COMP_CAR).toFixed(2),
                              false
                            )
                          : "0,00"}
                      </td>
                      <td>{t("Company car & other benefits in kind")}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th colSpan={4}></th>
                    </tr>
                    <tr>
                      <th>{t("Gross total")}</th>
                      <th className="text-end">
                        {formatCurrency(C_TOTAL_GROSS.toFixed(2), false)}
                      </th>
                      <th className="text-end">
                        {formatCurrency(C_TOTAL_GROSS_D19.toFixed(2), false)}
                      </th>
                      <th>{t("Total gross income")}</th>
                    </tr>
                    <tr>
                      <th colSpan={4}></th>
                    </tr>
                    <tr>
                      <th>{t("Social security total")}</th>
                      <th className="text-end">
                        {formatCurrency((C_SS_TOTAL * -1).toFixed(2), false)}
                      </th>
                      <th className="text-end">
                        {formatCurrency((C_SS_TOTAL_D21 * -1).toFixed(2), false)}
                      </th>
                      <th>{t("Total amount of social security")}</th>
                    </tr>
                  </tfoot>
                </Table>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>2. {t("BELGIAN TAX COMPUTATION")}</CardTitle>
            </CardHeader>

            <CardBody>
              <Card>
                <CardHeader>
                  <CardTitle>2.1. {t("TAXPAYER")}</CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive mb-3">
                    <Table className="table table-hover nowrap w-100 mb-0">
                      <thead>
                        <tr className="align-bottom">
                          <th></th>
                          <th className="text-end">{t("Employee")}</th>
                          <th className="text-end">{t("Director")}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {spCountryData &&
                          spCountryData.map((item: any, idx: number) => (
                            <React.Fragment key={idx}>
                              {idx === 0 ? (
                                <tr key={idx}>
                                  <td>
                                    {t("TOTAL GROSS")} {item?.country_name}
                                  </td>
                                  <td className="text-end">
                                    {formatCurrency(C_TOTAL_GROSS.toFixed(2), false)}
                                  </td>
                                  <td className="text-end">
                                    {formatCurrency(C_TOTAL_GROSS_D19.toFixed(2), false)}
                                  </td>
                                  <td>{t("Total gross income")}</td>
                                </tr>
                              ) : (
                                <tr key={idx}>
                                  <td>
                                    {t("TAXES")} {item?.country_name}
                                  </td>
                                  <td className="text-end">
                                    {item?.emp_dir === 1 ? (
                                      item?.C_TAXES_COUNTRY ? (
                                        <>
                                          {"-"}
                                          {formatCurrency(
                                            parseFloat(item?.C_TAXES_COUNTRY).toFixed(2),
                                            false
                                          )}
                                        </>
                                      ) : (
                                        "0,00"
                                      )
                                    ) : (
                                      "0,00"
                                    )}
                                  </td>
                                  <td className="text-end">
                                    {item?.emp_dir === 2 ? (
                                      item?.C_TAXES_COUNTRY ? (
                                        <>
                                          {"-"}
                                          {formatCurrency(
                                            parseFloat(item?.C_TAXES_COUNTRY).toFixed(2),
                                            false
                                          )}
                                        </>
                                      ) : (
                                        "0,00"
                                      )
                                    ) : (
                                      "0,00"
                                    )}
                                  </td>
                                  <td>
                                    {t("Taxes in")} {item?.country_name} {t("in EUR")}
                                  </td>
                                </tr>
                              )}

                              {idx === 0 ? (
                                <tr>
                                  <td>{t("SOCIAL SECURITY TOTAL")}</td>
                                  <td className="text-end">
                                    {formatCurrency((C_SS_TOTAL * -1).toFixed(2), false)}
                                  </td>
                                  <td className="text-end">
                                    {formatCurrency((C_SS_TOTAL_D21 * -1).toFixed(2), false)}
                                  </td>
                                  <td>{t("Total amount of social security")}</td>
                                </tr>
                              ) : null}
                            </React.Fragment>
                          ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colSpan={4}></th>
                        </tr>
                        <tr>
                          <th>{t("NET TAXABLE")} (1)</th>
                          <th className="text-end">
                            {formatCurrency(C_NET_TAXABLE.toFixed(2), false)}
                          </th>
                          <th className="text-end">
                            {formatCurrency(C_NET_TAXABLE_D40.toFixed(2), false)}
                          </th>
                          <th>{t("Net taxable income")}</th>
                        </tr>
                        <tr>
                          <th>{t("COMM EXEMPT")}</th>
                          <th className="text-end">
                            {formatCurrency(C_COMM_EXEMPT.toFixed(2), false)}
                          </th>
                          <th className="text-end">
                            {formatCurrency(C_COMM_EXEMPT_D41.toFixed(2), false)}
                          </th>
                          <th>{t("Commuting Exemption (not for directors)")}</th>
                        </tr>

                        <tr>
                          <th colSpan={4}></th>
                        </tr>
                        <tr>
                          <th>{t("NET TAXABLE")} (2)</th>
                          <th className="text-end">
                            {formatCurrency(C_NET_TAXABLE2.toFixed(2), false)}
                          </th>
                          <th className="text-end">
                            {formatCurrency(C_NET_TAXABLE2_D43.toFixed(2), false)}
                          </th>
                          <th>{t("Net taxable income after deduction of commuting exemption")}</th>
                        </tr>
                        <tr>
                          <th>{t("EXPENSES")}</th>
                          <th className="text-end">
                            {formatCurrency(C_EXPENSES.toFixed(2), false)}
                          </th>
                          <th className="text-end">
                            {formatCurrency(C_EXPENSES_D44.toFixed(2), false)}
                          </th>
                          <th>{t("Lump-sum business expenses")}</th>
                        </tr>
                      </tfoot>
                    </Table>
                  </div>

                  <div>
                    <hr />
                  </div>

                  <div className="table-responsive mb-3">
                    <Table className="table table-hover nowrap w-100 mb-0">
                      <thead>
                        <tr className="align-bottom">
                          <th>{t("With split payroll situation")}</th>
                          <th></th>
                          <th></th>
                          <th>{t("Without split")}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{t("NET TAXABLE")} (3)</td>
                          <td className="text-end">
                            {formatCurrency(C_NET_TAXABLE3.toFixed(2), false)}
                          </td>
                          <td>
                            {t("Net taxable income after deduction of lump-sum business expenses")}
                          </td>
                          <td className="text-end">{formatCurrency(J49.toFixed(2), false)}</td>
                          <td className="text-end">{formatCurrency(K49.toFixed(2), false)}</td>
                        </tr>
                        <tr>
                          <td>{t("ATTRIBUTION")}</td>
                          <td className="text-end">
                            {formatCurrency(C_ATTRIBUTION.toFixed(2), false)}
                          </td>
                          <td>{t("Attribution to spouse")}</td>
                          <td className="text-end">
                            {formatCurrency(C_ATTRIBUTION.toFixed(2), false)}
                          </td>
                          <td className="text-end"></td>
                        </tr>
                        <tr>
                          <td colSpan={5}></td>
                        </tr>
                        <tr>
                          <td>{t("GLOBAL TAXABLE")}</td>
                          <td className="text-end">
                            {formatCurrency(C_GLOBAL_TAXABLE.toFixed(2), false)}
                          </td>
                          <td>{t("Global taxable income")}</td>
                          <td className="text-end">
                            {formatCurrency((J49 + K49 - C_ATTRIBUTION).toFixed(2), false)}
                          </td>
                          <td className="text-end"></td>
                        </tr>
                        <tr>
                          <td colSpan={5}></td>
                        </tr>
                        <tr>
                          <td>{t("GLOBAL TAXES")}</td>
                          <td className="text-end">
                            {formatCurrency(C_GLOBAL_TAXES.toFixed(2), false)}
                          </td>
                          <td>{t("Base tax on global taxable income")}</td>
                          <td className="text-end">
                            {formatCurrency(C_GLOBAL_TAXES_J54.toFixed(2), false)}
                          </td>
                          <td className="text-end"></td>
                        </tr>
                        <tr>
                          <td>{t("TAXES TAXFREE AMOUNTS")}</td>
                          <td className="text-end">
                            {formatCurrency(C_TAXES_TAXFREE_AMOUNTS.toFixed(2), false)}
                          </td>
                          <td>{t("Taxes on tax-free amounts")}</td>
                          <td className="text-end">
                            {formatCurrency(C_TAXES_TAXFREE_AMOUNTS.toFixed(2), false)}
                          </td>
                          <td className="text-end"></td>
                        </tr>
                        <tr>
                          <td>{t("TAXES TAXFREE CHILDREN")}</td>
                          <td className="text-end">
                            {formatCurrency(C_TAXES_TAXFREE_CHILDREN.toFixed(2), false)}
                          </td>
                          <td>{t("Deduction for children")}</td>
                          <td className="text-end">
                            {formatCurrency(C_TAXES_TAXFREE_CHILDREN.toFixed(2), false)}
                          </td>
                          <td className="text-end"></td>
                        </tr>
                        <tr>
                          <td colSpan={5}></td>
                        </tr>
                        <tr>
                          <td>{t("PERCENT GROUP INS (FIXED FROM '13)")}</td>
                          <td className="text-end">
                            {formatCurrency(C_PERCENT_GROUP_INS.toFixed(2), false)}
                            {"%"}
                          </td>
                          <td>{t("Average tax rate for Long-Term Savings")} (1)</td>
                          <td className="text-end">
                            {formatCurrency(C_PERCENT_GROUP_INS.toFixed(2), false)}
                            {"%"}
                          </td>
                          <td className="text-end"></td>
                        </tr>
                        <tr>
                          <td>{t("CREDIT GROUP INS")}</td>
                          <td className="text-end">
                            {formatCurrency(C_CREDIT_GROUP_INS.toFixed(2), false)}
                          </td>
                          <td>{t("Long-Term savings")}</td>
                          <td className="text-end">
                            {formatCurrency(C_CREDIT_GROUP_INS_J59.toFixed(2), false)}
                          </td>
                          <td className="text-end"></td>
                        </tr>
                        <tr>
                          <td colSpan={5}></td>
                        </tr>
                        <tr>
                          <td>{t("TAXES TAXPAYER")}</td>
                          <td className="text-end">
                            {formatCurrency(C_TAXES_TAXPAYER.toFixed(2), false)}
                          </td>
                          <td>{t("Taxes on taxpayer")}</td>
                          <td className="text-end">
                            {formatCurrency(C_TAXES_TAXPAYER_J61.toFixed(2), false)}
                          </td>
                          <td className="text-end"></td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>

                  <Card>
                    <CardHeader>
                      <CardTitle>2.1.1. {t("DEDUCTION FOREIGN INCOME TAXPAYER")}</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="table-responsive mb-3">
                        <Table className="table table-hover nowrap w-100 mb-0">
                          <tbody>
                            <tr>
                              <td>{t("TAXES TAXPAYER")}</td>
                              <td className="text-end">
                                {formatCurrency(C_TAXES_TAXPAYER.toFixed(2), false)}
                              </td>
                              <td>{t("Taxes on taxpayer")}</td>
                            </tr>
                            <tr>
                              <td>{t("NET FOREIGN")}</td>
                              <td className="text-end">
                                {formatCurrency(C_NET_FOREIGN.toFixed(2), false)}
                              </td>
                              <td>{t("Net taxable foreign income")}</td>
                            </tr>
                            <tr>
                              <td>{t("GLOBAL TAXABLE")}</td>
                              <td className="text-end">
                                {formatCurrency(C_GLOBAL_TAXABLE.toFixed(2), false)}
                              </td>
                              <td>{t("Global taxable income")}</td>
                            </tr>
                            <tr>
                              <td>{t("ATTRIBUTION")}</td>
                              <td className="text-end">
                                {formatCurrency(C_ATTRIBUTION.toFixed(2), false)}
                              </td>
                              <td>{t("Attribution to spouse")}</td>
                            </tr>
                            <tr>
                              <td>{t("EXPENSES")}</td>
                              <td className="text-end">
                                {formatCurrency((C_EXPENSES + C_EXPENSES_D44).toFixed(2), false)}
                              </td>
                              <td>{t("Total expenses")}</td>
                            </tr>
                            <tr>
                              <td>{t("FOREIGN EXPENSES")}</td>
                              <td className="text-end">
                                {formatCurrency(C_FOREIGN_EXPENSES.toFixed(2), false)}
                              </td>
                              <td>{t("Expenses on net taxable foreign income")}</td>
                            </tr>
                            <tr>
                              <td>{t("FOREIGN INCOME DEDUCTION")}</td>
                              <td className="text-end">
                                {formatCurrency(C_FOREIGN_INCOME_DEDUCTION.toFixed(2), false)}
                              </td>
                              <td>{t("Foreign income for deduction")}</td>
                            </tr>
                            <tr>
                              <td>{t("TELLER")}</td>
                              <td className="text-end">
                                {formatCurrency(C_TELLER.toFixed(2), false)}
                              </td>
                              <td>{t("Teller")}</td>
                            </tr>
                            <tr>
                              <td>{t("DEDUCTION")}</td>
                              <td className="text-end">
                                {formatCurrency(C_DEDUCTION.toFixed(2), false)}
                              </td>
                              <td>{t("Deduction foreign income")}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle>2.2. {t("SPOUSE")}</CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive mb-3">
                    <Table className="table table-hover nowrap w-100 mb-0">
                      <tbody>
                        <tr>
                          <td>{t("ATTRIBUTION")}</td>
                          <td className="text-end">
                            {formatCurrency(C_ATTRIBUTION.toFixed(2), false)}
                          </td>
                          <td>{t("Attribution to spouse")}</td>
                        </tr>
                        <tr>
                          <td>{t("GLOBAL TAXES SPOUSE")}</td>
                          <td className="text-end">
                            {formatCurrency(C_GLOBAL_TAXES_SPOUSE.toFixed(2), false)}
                          </td>
                          <td>{t("Base tax on global taxable income")}</td>
                        </tr>
                        <tr>
                          <td>{t("TAXES TAXFREE AMOUNTS SPOUSE")}</td>
                          <td className="text-end">
                            {formatCurrency(C_TAXES_TAXFREE_AMOUNTS_SPOUSE.toFixed(2), false)}
                          </td>
                          <td>{t("Taxes on tax-free amounts")}</td>
                        </tr>
                        <tr>
                          <td colSpan={3}></td>
                        </tr>
                        <tr>
                          <td>{t("TAXES SPOUSE")}</td>
                          <td className="text-end">
                            {formatCurrency(C_TAXES_SPOUSE.toFixed(2), false)}
                          </td>
                          <td>{t("Taxes on spouse")}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>

                  <Card>
                    <CardHeader>
                      <CardTitle>2.2.1. {t("DEDUCTION FOREIGN INCOME SPOUSE")}</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="table-responsive mb-3">
                        <Table className="table table-hover nowrap w-100 mb-0">
                          <tbody>
                            <tr>
                              <td>{t("ATTRIBUTION")}</td>
                              <td className="text-end">
                                {formatCurrency(C_ATTRIBUTION.toFixed(2), false)}
                              </td>
                              <td>{t("Attribution to spouse")}</td>
                            </tr>
                            <tr>
                              <td>{t("TAXES SPOUSE")}</td>
                              <td className="text-end">
                                {formatCurrency(C_TAXES_SPOUSE.toFixed(2), false)}
                              </td>
                              <td>{t("Taxes on spouse")}</td>
                            </tr>
                            <tr>
                              <td>{t("TELLER SPOUSE")}</td>
                              <td className="text-end">
                                {formatCurrency(C_TELLER_SPOUSE.toFixed(2), false)}
                              </td>
                              <td>{t("Teller")}</td>
                            </tr>
                            <tr>
                              <td colSpan={3}></td>
                            </tr>
                            <tr>
                              <td>{t("DEDUCTION SPOUSE")}</td>
                              <td className="text-end">
                                {formatCurrency(C_DEDUCTION_SPOUSE.toFixed(2), false)}
                              </td>
                              <td>{t("Deduction for foreign income spouse")}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle>2.3. {t("TOTAL TAXES (with split payroll)")}</CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive mb-3">
                    <Table className="table table-hover nowrap w-100 mb-0">
                      <tbody>
                        <tr>
                          <td>{t("TAXES TAXPAYER")}</td>
                          <td className="text-end">
                            {formatCurrency(C_TAXES_TAXPAYER.toFixed(2), false)}
                          </td>
                          <td>{t("Taxes on taxpayer")}</td>
                        </tr>
                        <tr>
                          <td>{t("TAXES SPOUSE")}</td>
                          <td className="text-end">
                            {formatCurrency(C_TAXES_SPOUSE.toFixed(2), false)}
                          </td>
                          <td>{t("Taxes on spouse")}</td>
                        </tr>
                        <tr>
                          <td>{t("DEDUCTION")}</td>
                          <td className="text-end">
                            {formatCurrency(C_DEDUCTION_B96.toFixed(2), false)}
                          </td>
                          <td>{t("Deduction foreign income taxpayer")}</td>
                        </tr>
                        <tr>
                          <td>{t("DEDUCTION SPOUSE")}</td>
                          <td className="text-end">
                            {formatCurrency(C_DEDUCTION_SPOUSE_B96.toFixed(2), false)}
                          </td>
                          <td>{t("Deduction foreign income taxpayer")}</td>
                        </tr>
                        <tr>
                          <td colSpan={3}></td>
                        </tr>
                        <tr>
                          <td>{t("TOTAL TAX")}</td>
                          <td className="text-end">
                            {formatCurrency(C_TOTAL_TAX.toFixed(2), false)}
                          </td>
                          <td>{t("Total taxes")}</td>
                        </tr>
                        <tr>
                          <td>{t("COM TAX")}</td>
                          <td className="text-end">
                            {formatCurrency(C_COM_TAX.toFixed(2), false)}
                          </td>
                          <td>{t("Communal tax")}</td>
                        </tr>
                        <tr>
                          <td colSpan={3}></td>
                        </tr>
                        <tr>
                          <td>{t("TOTAL TAX")} (2)</td>
                          <td className="text-end">
                            {formatCurrency(C_TOTAL_TAX2.toFixed(2), false)}
                          </td>
                          <td>{t("Total taxes (all inclusive)")}</td>
                        </tr>
                        <tr>
                          <td>{t("SSSC")}</td>
                          <td className="text-end">{formatCurrency(C_SSSC.toFixed(2), false)}</td>
                          <td>{t("S.S.S.C.")}</td>
                        </tr>
                        <tr>
                          <td colSpan={3}></td>
                        </tr>
                        <tr>
                          <td>{t("TOTAL TAX")} (3)</td>
                          <td className="text-end">
                            {formatCurrency(C_TOTAL_TAX3.toFixed(2), false)}
                          </td>
                          <td>{t("Total taxes (including S.S.S.C. - IF APPLICABLE)")}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle>2.4. {t("TOTAL TAXES (100% in Belgium)")}</CardTitle>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive mb-3">
                    <Table className="table table-hover nowrap w-100 mb-0">
                      <tbody>
                        <tr>
                          <td>{t("TAXES TAXPAYER")}</td>
                          <td className="text-end">
                            {formatCurrency(C_TAXES_TAXPAYER_B109.toFixed(2), false)}
                          </td>
                          <td>{t("Taxes on taxpayer")}</td>
                        </tr>
                        <tr>
                          <td>{t("TAXES SPOUSE")}</td>
                          <td className="text-end">
                            {formatCurrency(C_TAXES_SPOUSE.toFixed(2), false)}
                          </td>
                          <td>{t("Taxes on spouse")}</td>
                        </tr>
                        <tr>
                          <td colSpan={3}></td>
                        </tr>
                        <tr>
                          <td>{t("TOTAL TAX BE")} (1)</td>
                          <td className="text-end">
                            {formatCurrency(C_TOTAL_TAX_BE.toFixed(2), false)}
                          </td>
                          <td>{t("Total taxes")}</td>
                        </tr>
                        <tr>
                          <td>{t("COM TAX BE")}</td>
                          <td className="text-end">
                            {formatCurrency(C_COM_TAX_BE.toFixed(2), false)}
                          </td>
                          <td>{t("Communal tax")}</td>
                        </tr>
                        <tr>
                          <td colSpan={3}></td>
                        </tr>
                        <tr>
                          <td>{t("TOTAL TAX BE")} (2)</td>
                          <td className="text-end">
                            {formatCurrency(C_TOTAL_TAX2_BE.toFixed(2), false)}
                          </td>
                          <td>{t("Total taxes (all inclusive)")}</td>
                        </tr>
                        <tr>
                          <td>{t("SSSC BE")}</td>
                          <td className="text-end">
                            {formatCurrency(C_SSSC_BE.toFixed(2), false)}
                          </td>
                          <td>{t("S.S.S.C")}.</td>
                        </tr>
                        <tr>
                          <td colSpan={3}></td>
                        </tr>
                        <tr>
                          <td>{t("TOTAL TAX BE")} (3)</td>
                          <td className="text-end">
                            {formatCurrency(C_TOTAL_TAX3_BE.toFixed(2), false)}
                          </td>
                          <td>{t("Total taxes (including S.S.S.C. - IF APPLICABLE)")}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </CardBody>
          </Card>
        </ModalBody>

        <ModalFooter>
          <Button color="danger" className="btn-block" type="reset" onClick={props.onCloseClick}>
            {t("Close")}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default SSSCLocalModel_21;
