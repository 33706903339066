import React, { memo, useState } from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import PropTypes from 'prop-types';
import {map} from 'lodash';
import { MaterialIcon } from './styles';
function FileMenu({ direction, documents,attachDoc,handleFileOpener,handleFileSystemModel,t, ...args }: any) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    return (
        <div className="d-flex">
            <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={direction}>
                <DropdownToggle caret color='light' size='sm'>
                    <MaterialIcon>attach_file</MaterialIcon>
                </DropdownToggle>
                <DropdownMenu {...args}>
                    {documents && map(documents,(item,key)=><DropdownItem key={key} onClick={()=>attachDoc(item)}>{item.type==='link' ? <span className="align-middle material-symbols-outlined fw-light fs-4 me-1" style={{color:'#19959b'}}>grid_view</span> :<i className="fa-solid fa-file"></i>}{' '}{`${item.file_name}.${item.file_extension}`}</DropdownItem>)}
                    <DropdownItem onClick={handleFileSystemModel}><MaterialIcon size={20}>file_present</MaterialIcon>{' '}{t("Search from file system")}</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={handleFileOpener}><i className="fa-solid fa-laptop"></i>{' '}{t("Browse from computer")}</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    );
}

FileMenu.propTypes = {
    direction: PropTypes.string,
    documents: PropTypes.array,
    attachDoc:PropTypes.func,
    handleFileOpener:PropTypes.func,
    handleFileSystemModel:PropTypes.func,
    t:PropTypes.func
    
};

export default memo(FileMenu);