import React, { FC } from "react";
import ApexChart, { Props } from 'react-apexcharts';
import LoadingSpinner from "../Common/Loader";
import NoDataFound from "../NoDataFound";

type NoDataProps = {
    icon: string | null,
    message: string | null,
}
interface CartProps extends Props {
    loading?: boolean;
    height?: number;
    noData?: NoDataProps
}

const Chart: FC<CartProps> = ({ series, height = 300, loading = false, noData, ...props }) => {
    const icon = noData?.icon || 'monitoring';
    const message = noData?.message || 'No data available. Please try again!'
    // const { icon = 'bar_chart', message } = noData;
    return (
        <div style={{ height: height + 'px' }}>
            {/* <hr className="hr m-0 p-0" /> */}
            {loading ? <LoadingSpinner height={height} /> : (series && series?.length < 1 ? <NoDataFound icon={icon} message={message} /> : <ApexChart {...props} series={series} height={height} />)}
        </div>
    )
}

export default React.memo(Chart);