import { readAsArrayBuffer } from './asyncReader';
import { getAsset } from './prepareAssets';
import { base64ToBlob, normalize } from './helpers';
import moment from 'moment';
import html2canvas from "html2canvas";
export async function save(
  pdfFile: File,
  objects: Attachments[],
  name: string
) {
  const PDFLib = await getAsset('PDFLib');
  const download = await getAsset('download');
  let pdfDoc: {
    getPages: () => any[];
    embedFont: (arg0: unknown) => any;
    embedJpg: (arg0: unknown) => any;
    embedPng: (arg0: unknown) => any;
    embedPdf: (arg0: any) => [any] | PromiseLike<[any]>;
    save: () => any;
  };

  try {
    pdfDoc = await PDFLib.PDFDocument.load(await readAsArrayBuffer(pdfFile));
  } catch (e) {
    console.log('Failed to load PDF.');
    throw e;
  }

  const getImage = async (htmlContent: any, page: any, x: any, y: any) => {
    const container = document.querySelector('.text-image') as HTMLDivElement;
    container.innerHTML = htmlContent;

    await html2canvas(container, { scale: 1 }).then((canvas) => {
      const dataURL = canvas.toDataURL('image/png');
      const img = new Image();
      img.src = dataURL;
      const file = base64ToBlob(dataURL);
      console.log(file);
      return img;
      // img.onload = function () {
      //   const width = 200;
      //   const height = (img.height / img.width) * width;

      //   return page.drawImage(img, {
      //     x: x,
      //     y: y,
      //     width: width,
      //     height: height
      //   });
      // };
    });
  };
  const pagesProcesses = pdfDoc.getPages().map(async (page, pageIndex) => {
    const pageObjects = objects[pageIndex];
    // 'y' starts from bottom in PDFLib, use this to calculate y
    const pageHeight = page.getHeight();
    const pageWidth = page.getWidth();
    let countIndex = 0;
    const embedProcesses = pageObjects.map(async (object: Attachment) => {
      if (object.type === 'image') {
        const { file, x, y, width, height, data } = object as ImageAttachment;
        let img: any;
        try {
          if (file.type === 'image/jpeg') {
            img = await pdfDoc.embedJpg(await readAsArrayBuffer(file));
          } else {
            img = await pdfDoc.embedPng(await readAsArrayBuffer(file));
          }
          const pdfFont = await pdfDoc.embedFont("Times-Roman");


          return () => {
            page.drawImage(img, {
              x: (x/1.5 -40),
              y: ((pageHeight - (y / 1.50)) - (height / 1.50))+50,
              width: (width / 1.5),
              height: (height / 1.5)-50,
            });
            if (data?.user_first_name) {
              const text = data.user_first_name + ' ' + data.user_last_name;
              const text2 = moment(data?.created_at).format("DD/MM/Y HH:mm:s");
              const text3 = "IP :" + data.ip_address;
              page.drawText(text, {
                maxWidth: width,
                font: pdfFont,
                size: 14/1.5,
                lineHeight: 1,
                x: x / 1.5,
                y: (pageHeight - (y / 1.50)) - (height / 1.50) +50,
              });
              page.drawText(text2, {
                maxWidth: width,
                font: pdfFont,
                size: 14/1.5,
                lineHeight: 1,
                x: x / 1.5,
                y: (pageHeight - (y / 1.50)) - (height / 1.50) +30,
              });

              page.drawText(text3, {
                maxWidth: width,
                font: pdfFont,
                size: 14/1.5,
                lineHeight: 1,
                x: x / 1.5,
                y: (pageHeight - (y / 1.50)) - (height / 1.50) +10,
              });
            }

          }
        } catch (e) {
          console.log('Failed to embed image.', e);
          throw e;
        }
      } else if (object.type === 'text') {
        const {
          x,
          y,
          text,
          size,
          lineHeight,
          fontFamily,
          width,
          height,
          textOnly
        } = object as TextAttachment;
        if (textOnly) {
          countIndex = countIndex + 1;
          const pdfFont = await pdfDoc.embedFont(fontFamily);
          return () =>
            page.drawText(text, {
              maxWidth: width,
              font: pdfFont,
              size,
              lineHeight,
              x: (x / 1.5),
              y: (pageHeight - size! - (y / 1.50)),
            });
        } else {
          const container = document.querySelector(`.text-image-${countIndex}`) as HTMLDivElement;
          if (container) {
            container.innerHTML = text;
            const contentWidth = container.clientWidth;
            const contentHeight = container.clientHeight;
            const canvas = await html2canvas(container, { scale: 3 });
            const dataURL: any = canvas.toDataURL('image/png');
            const file = await base64ToBlob(dataURL) as File;
            const image = await pdfDoc.embedPng(await readAsArrayBuffer(file));
            countIndex = countIndex + 1;
            return () => page.drawImage(image, {
              x: (x / 1.5),
              y: pageHeight - size! - (y / 1.50),
              width: contentWidth,
              height: contentHeight,
            });
          }
        }

      } else if (object.type === 'drawing') {
        const {
          x,
          y,
          path,
          scale,
          stroke,
          strokeWidth,
        } = object as DrawingAttachment;
        const {
          pushGraphicsState,
          setLineCap,
          popGraphicsState,
          setLineJoin,
          LineCapStyle,
          LineJoinStyle,
          rgb,
        } = PDFLib;
        return () => {
          page.pushOperators(
            pushGraphicsState(),
            setLineCap(LineCapStyle.Round),
            setLineJoin(LineJoinStyle.Round)
          );

          const color = window.w3color(stroke!).toRgb();

          page.drawSvgPath(path, {
            borderColor: rgb(
              normalize(color.r),
              normalize(color.g),
              normalize(color.b)
            ),
            borderWidth: strokeWidth,
            scale,
            x: (x / 1.5),
            y: (pageHeight - (y / 1.50)),
          });
          page.pushOperators(popGraphicsState());
        };
      }
    });
    // embed objects in order
    const drawProcesses: any[] = await Promise.all(embedProcesses);
    drawProcesses.forEach((p) => p());
  });
  await Promise.all(pagesProcesses);
  try {
    const pdfBytes = await pdfDoc.save();
    const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
    return pdfBlob;
    //download(pdfBytes, name, 'application/pdf');
  } catch (e) {
    console.log('Failed to save PDF.');
    throw e;
  }
}
