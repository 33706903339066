import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Nav, NavItem, Row } from 'reactstrap';
import Breadcrumbs from '../../components/Layout/Breadcrumb';
import { getUserProfile, widgetAccess } from '../../helpers/functions_helper';
import GraphFilters from './Charts/Filters';
import styled from 'styled-components';
import ActiveVsNonActive from './Charts/ActiveVsNonActive';
import IndividualVsCompany from './Charts/IndividualVsCompany';
import UserVsExpired from './Charts/UserVsExpired';
import UsersVsArchive from './Charts/UsersVsArchive';
import Counts from './Charts/Counts';
import ImpatriatesData from './Charts/ImpatriatesData';
import ResidentVsNonResident from './Charts/ResidentVsNonResident';
import UserperQuarter from './Charts/UserperQuarter';
import { currentMonth, currentYear } from '../../common/staticdata';
import ChartFilters from '../../components/Chart/Filters';
const CustomCard = styled(Card)`
    height: calc(100% - 24px);
`;

const UserGraphicalView = () => {
    const userProfile = getUserProfile("all-users");
    const { t } = useTranslation();
    document.title = `${t("Users")} - ${t("Graphical view")} | ${process.env.REACT_APP_NAME}`;
    const breadcrumbItems = [
        {
            url: "/dashboard",
            label: t("Dashboard"),
            active: false,
        },
        {
            url: "#",
            label: t("Users"),
            active: true,
        },
    ];

    const inititalFilter = {
        week: null,
        quarter: null,
        month: null,
        year: null,
        user_id: !(userProfile.IS_PARTNER && userProfile.IS_SUPER_ADMIN) ? userProfile.USER_ID : null,
        graphview: 'month',
    };

    const [loading, setLoading] = useState<boolean>(true);
    const [filter, setFilter] = useState<any>(inititalFilter);

    const clearWorkAllocationFilter = () => {
        setFilter(inititalFilter);
    }
    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        breadcrumbTitle={t("Users")}
                        breadcrumbItems={breadcrumbItems}
                        endAdornment={
                            <div className="d-flex gap-2 text-end">
                                <Link to="/users/all-users" className={`btn btn-outline-secondary btn-label btn-sm`}>
                                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                        list_alt
                                    </span>
                                    {t("List view")}
                                </Link>

                                <Link to="/users/graph" className={`btn btn-info btn-label btn-sm`}>
                                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                        insert_chart
                                    </span>
                                    {t("Graphical view")}
                                </Link>

                                {userProfile.CAN_CREATE ? (
                                    <Link to="/users/new-user" className="btn btn-primary btn-label btn-sm">
                                        <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                            add
                                        </span>{" "}
                                        {t("Create user")}
                                    </Link>
                                ) : null}
                            </div>
                        }
                    />
                    <Row>
                        <Col>
                            <div className="mt-3">
                                <Row>
                                    <Counts filters={filter} />
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Col>
                        <div className="d-sm-flex flex-wrap mt-1 mb-3">
                            <p></p>
                            <div className="ms-auto">
                                <ChartFilters
                                    filter={filter}
                                    setFilter={setFilter}
                                    clearFilter={clearWorkAllocationFilter}
                                />
                            </div>
                        </div>
                    </Col>
                    {widgetAccess('INPAT') &&
                        <Row>
                            <Col xl="12">
                                <CustomCard>
                                    <CardBody>
                                        <ImpatriatesData filters={filter} />
                                    </CardBody>
                                </CustomCard>
                            </Col>
                        </Row>
                    }
                    <Row>
                        {widgetAccess('Company_vs_Individual_User') &&
                            <Col xl="8">
                                <CustomCard>
                                    <CardBody>
                                        <IndividualVsCompany filters={filter} />
                                    </CardBody>
                                </CustomCard>
                            </Col>
                        }
                        {widgetAccess('Resident_Vs_Non_resident_users') &&
                            <Col xl="4">
                                <CustomCard>
                                    <CardBody>
                                        <ResidentVsNonResident filters={filter} />
                                    </CardBody>
                                </CustomCard>
                            </Col>
                        }
                    </Row>
                    <Row>
                        {widgetAccess('Resident_Vs_Non_resident_users') &&
                            <Col xl="4">
                                <CustomCard>
                                    <CardBody>
                                        <ActiveVsNonActive filters={filter} />
                                    </CardBody>
                                </CustomCard>
                            </Col>
                        }
                        {widgetAccess('Total_user_statistics') &&
                            <Col xl="8">
                                <CustomCard>
                                    <CardBody>
                                        <UserperQuarter filters={filter} />
                                    </CardBody>
                                </CustomCard>
                            </Col>
                        }
                    </Row>
                    <Row>
                        {widgetAccess('Expired_user_vs_Non_expired_user') &&
                            <Col xl="4">
                                <CustomCard>
                                    <CardBody>
                                        {/* <UsersVsArchive filters={filter} /> */}
                                        <UserVsExpired filters={filter} />
                                    </CardBody>
                                </CustomCard>
                            </Col>
                        }
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default UserGraphicalView