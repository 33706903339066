import { memo } from 'react';
const FooterButton = (props: any) => {
    return (
        <div style={{ textAlign: 'center','padding':'2px' }}>
            <a href="#" target="_blank"
                rel="noopener noreferrer"
                data-auth="NotApplicable"
                style={{
                    textDecoration: 'none',
                    borderRadius: '10rem',
                    letterSpacing: '0.03em',
                    fontSize: '0.9125rem',
                    minWidth: '2.375rem',
                    color: '#fff',
                    backgroundColor: '#c71e26',
                    display: 'inline-block',
                    fontWeight: '400',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    verticalAlign: 'middle',
                    padding: '10px 15px'
                }}
                data-linkindex="1">
                {props.title}
            </a>
        </div>
    )
}
export default memo(FooterButton);