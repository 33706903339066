export type attachmentProps = {
    module_app_id?: string,
    module_main_id?: string,
    module_sub_id?: string,
    file_extension?: string,
    file_path?: string,
    type?: string,
    short_description?: string,
    file_description?: string,
    attachment_for_ids?: string,
    attachment_for_company_ids?: string,
}

type mailFilterProps = {
    company?: {},
    attachment?: attachmentProps,
    user?: {},
    task?:{},
    comment?:{},

}

//type mailBoxOpen = 1 | 2 | 3 | 4 | 5;
//TODO
//1 open
//2 minimum
//3 maximum
//4 save draft mail
//5 close and clear box

export type draftmailProps = {
    title?: string;
    subject?: string;
    body?: string;
    attachment?: string;
    to?: Array<any>;
    cc?: Array<any>;
    bcc?: Array<any>;
    files?: Array<any>;
    signature?: number;
    filters?: mailFilterProps;
    openMailBox?: number;
    file_system_attachment?: Array<any>;
    send_type?: string;
    id?: string;
    button?: string;
    is_include_template?: boolean;
}

export const MailFolder = [
    {
        displayName: "Inbox",
        totalItemCount:0,
        query:'inbox',
        unreadItemCount:0,
        icon:'inbox',
        id:'inbox',
        
    },
    {
        displayName: "Drafts",
        totalItemCount:0,
        query:'drafts',
        unreadItemCount:0,
        icon:'edit_note',
        id:'drafts',
    },
    {
        displayName: "Sent Items",
        totalItemCount:0,
        query:'sentitems',
        unreadItemCount:0,
        icon:'send',
        id:'sentitems',
    },
    {
        displayName: "Deleted Items",
        totalItemCount:0,
        query:'deleteditems',
        unreadItemCount:0,
        icon:'delete',
        id:'deleteditems',
    },
    {
        displayName: "Junk Email",
        totalItemCount:0,
        query:'junkemail',
        unreadItemCount:0,
        icon:'folder_delete',
        id:'junkemail',
    },
    {
        displayName: "Archive",
        totalItemCount:0,
        query:'archive',
        unreadItemCount:0,
        icon:'inventory_2',
        id:'archive',
    },
    {
        displayName: "ETC",
        totalItemCount:0,
        query:'ETC',
        unreadItemCount:0,
        icon:'rss_feed',
        id:'etc',
    },
]

