import React from "react";
import { Nav, NavItem } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getUserProfile, moduleAccess } from "../../helpers/functions_helper";
import { useSelector } from "react-redux";
import { useIsAuthenticated } from "@azure/msal-react";

const NavItems = (props: any) => {
  const { t } = useTranslation();
  const userProfile = getUserProfile("all-users");
  const isAzureAuthenticated = useIsAuthenticated();
  const {
    getUserData
  } = useSelector((state: any) => ({
    getUserData: state.Users.GET_USER_SUCCESS
  }));

  return (
    <React.Fragment>
      {/* {JSON.stringify(getUserData?.data[0]?.company)} */}
      <Nav tabs>
        <NavItem>
          <Link
            style={{ cursor: "pointer" }}
            to={`/users/user-details/${props.id}/${props.user_id}`}
            className={`${window.location.pathname ===
              "/users/user-details/" + props.id + "/" + props.user_id
              ||
              window.location.pathname ===
              "/users/user-details/" + props.id + "/" + props.user_id + "/process"
              ? "active"
              : ""
              }  nav-link`}
          >
            {t("User details")}
          </Link>
        </NavItem>

        <NavItem>
          <Link
            style={{ cursor: "pointer" }}
            to={`/users/general/${props.id}/${props.user_id}`}
            className={`${window.location.pathname ===
              "/users/general/" + props.id + "/" + props.user_id
              ? "active"
              : ""
              }  nav-link`}
          >
            {t("General")}
          </Link>
        </NavItem>

        <NavItem>
          <Link
            style={{ cursor: "pointer" }}
            to={`/users/taxes/${props.id}/${props.user_id}`}
            className={`${window.location.pathname ===
              "/users/taxes/" + props.id + "/" + props.user_id
              ? "active"
              : ""
              }  nav-link`}
          >
            {t("Taxes")}
          </Link>
        </NavItem>

        <NavItem>
          <Link
            style={{ cursor: "pointer" }}
            to={`/users/comments/${props.id}/${props.user_id}`}
            className={`${window.location.pathname ===
              "/users/comments/" + props.id + "/" + props.user_id
              ? "active"
              : ""
              }  nav-link`}
          >
            {t("Comments")}
          </Link>
        </NavItem>

        <NavItem>
          <Link
            style={{ cursor: "pointer" }}
            to={`/users/attachments/${props.id}/${props.user_id}`}
            className={`${window.location.pathname ===
              "/users/attachments/" + props.id + "/" + props.user_id
              ? "active"
              : ""
              }  nav-link`}
          >
            {t("Permanent documents")}
          </Link>
        </NavItem>

        <NavItem>
          <Link
            style={{ cursor: "pointer" }}
            to={`/users/documents/${props.id}/${props.user_id}`}
            className={`${window.location.pathname ===
              "/users/documents/" + props.id + "/" + props.user_id
              ? "active"
              : ""
              }  nav-link`}
          >
            {t("Yearly documents")}
          </Link>
        </NavItem>

        {userProfile.IS_ADMIN || userProfile.IS_COMPANY_ADMIN ? (
          <NavItem>
            <Link
              style={{ cursor: "pointer" }}
              to={`/users/questionnaires/${props.id}/${props.user_id}`}
              className={`${window.location.pathname ===
                "/users/questionnaires/" + props.id + "/" + props.user_id
                ? "active"
                : ""
                }  nav-link`}
            >
              {t("Questionnaires")}
            </Link>
          </NavItem>
        ) : null}

        {!!((userProfile.USER_ID == props.id) && (isAzureAuthenticated)) && (
          <NavItem>
            <Link
              style={{ cursor: "pointer" }}
              to={`/users/signatures/${props.id}/${props.user_id}`}
              className={`${window.location.pathname ===
                "/users/signatures/" + props.id + "/" + props.user_id
                ? "active"
                : ""
                }  nav-link`}
            >
              {t("Signatures")}
            </Link>
          </NavItem>
        )}

        {userProfile.IS_COMPANY_ADMIN ? (
          <NavItem>
            <Link
              style={{ cursor: "pointer" }}
              to={`/users/notifications/${props.id}/${props.user_id}`}
              className={`${window.location.pathname ===
                "/users/notifications/" + props.id + "/" + props.user_id
                ? "active"
                : ""
                }  nav-link`}
            >
              {t("Notifications")}
            </Link>
          </NavItem>
        ) : null}

        {userProfile.IS_ADMIN ||
          userProfile.IS_COMPANY_ADMIN ||
          userProfile.IS_PARTNER || userProfile.IS_INDIVIDUAL
          ? (
            (getUserData?.data && getUserData?.data[0]?.company === 0) &&
            <NavItem>
              <Link
                style={{ cursor: "pointer" }}
                to={`/users/invoices/${props.id}/${props.user_id}`}
                className={`${window.location.pathname ===
                  "/users/invoices/" + props.id + "/" + props.user_id
                  ? "active"
                  : ""
                  }  nav-link`}
              >
                {t("Invoices")}
              </Link>
            </NavItem>
          ) : null}
        <NavItem>
          <Link
            style={{ cursor: "pointer" }}
            to={`/users/workday-calender/${props.id}/${props.user_id}`}
            className={`${window.location.pathname ===
              "/users/workday-calender/" + props.id + "/" + props.user_id
              ? "active"
              : ""
              }  nav-link`}
          >
            {t("Workday calender")}
          </Link>
        </NavItem>
        {(userProfile.IS_PARTNER || moduleAccess('access-role')) ? (
          <NavItem>
            <Link
              style={{ cursor: "pointer" }}
              to={`/users/access/${props.id}/${props.user_id}`}
              className={`${window.location.pathname ===
                "/users/access/" + props.id + "/" + props.user_id
                ? "active"
                : ""
                }  nav-link`}
            >
              {t("Access Rights")}
            </Link>
          </NavItem>
        ) : null}
      </Nav>
    </React.Fragment>
  );
};

export default NavItems;
