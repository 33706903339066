import moment from "moment";

export const initialValues: userManualTypes = {
  id: "",
  user_manual_id: "",
  title: "",
  notes: "",
  title_french: "",
  notes_french: "",
  title_dutch: "",
  notes_dutch: "",
  module_url: "",
  user_manual_parent_id: 0,
  status: 0,

  created_by: "",
  created_by_first_name: "",
  created_by_last_name: "",
  created_at: "",
  updated_by_first_name: "",
  updated_by_last_name: "",
  updated_at: "",

  submit_btn_code: 1,
};
