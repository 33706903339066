import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  Label,
  Input,
  Button,
  FormFeedback,
  Alert,
} from "reactstrap";

import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation, Trans } from "react-i18next";

const SaveNewsLetter = (props: any) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const {data} = props;
  console.log(data);
  

  const [initialData, setInitialData] = useState({
    title: '',
    to:'',
    from:'no-reply@taxconsult.be',
  });

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required("The title field is required.")
      .min(2, "The title must be at least 2 characters.")
      .max(200, "The title may not be greater than 200 characters."),
  });

  const handleSubmit = (values: {
    title: string;
  }) => {
    props.onSubmitClick(values);
  };
  const handleChange = (fieldName: string, fieldValue: string) => {
    setInitialData({ ...initialData, [fieldName]: fieldValue });
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    if (!validation.isSubmitting) return;
    if (Object.keys(validation.errors).length > 0) {
      document
        .getElementById(Object.keys(validation.errors)[0])
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
      document
        .getElementById(Object.keys(validation.errors)[0])
        ?.focus({ preventScroll: true });
    }
  }, [validation.isSubmitting, validation.errors]);


  useEffect(() => {
    if(data){
    setInitialData((preState:any)=>({...preState,title:data?.title}))
    }
  }, []);


  return (
    <React.Fragment>
      <Modal
        size="md"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">News Letter</h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label="Close"
          ></button>
        </div>

        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          autoComplete="off"
        >
          <ModalBody>
            <Row>
              <Col className="col-12">
                <div className="mb-3">
                  <Label className="form-label">
                    {t("Subject")}{" "}
                    <span className="text-warning is_required">*</span>
                  </Label>
                  <Input
                    id="title"
                    name="title"
                    placeholder={t("Subject")}
                    maxLength={200}
                    value={initialData.title}
                    onChange={(e) => handleChange("title", e.target.value)}
                    invalid={
                      validation.touched.title && validation.errors.title
                        ? true
                        : false
                    }
                  />
                  {validation.touched.title && validation.errors.title ? (
                    <FormFeedback type="invalid">
                      {validation.errors.title}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              {/* <Col className="col-12">
                <div className="mb-3">
                  <Label className="form-label">
                    {t("To")}{" "}
                  </Label>
                  <Input
                    id="to"
                    name="to"
                    placeholder={t("To")}
                    maxLength={200}
                    value={initialData.to}
                    onChange={(e) => handleChange("to", e.target.value)}
                  />
                </div>
              </Col> */}
              <Col className="col-12">
                <div className="mb-3">
                  <Label className="form-label">
                    {t("From")}{" "}
                  </Label>
                  <Input
                    id="from"
                    name="from"
                    placeholder={t("From")}
                    maxLength={200}
                    value={initialData.from}
                    onChange={(e) => handleChange("from", e.target.value)}
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              className="btn-block"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <span>
                  <i className="fa-solid fa-spinner fa-spin"></i>
                  <span>&nbsp; Please wait...</span>
                </span>
              ) : (
                <span>Submit</span>
              )}
            </Button>
            <Button
              color="outline-danger"
              className="btn-block"
              type="reset"
              onClick={props.onCloseClick}
              disabled={loading}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default SaveNewsLetter;
