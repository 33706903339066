import React, { FC, useState, useEffect } from 'react';
import { Button, Card, CardBody, Col, Input, Label, Modal, ModalBody, Row, Table } from 'reactstrap';
import Dropzone from '../../components/Common/Dropzone';
import api from '../../services/master.documentType.service';
import CommonService from '../../services/common.service';
import { yearOptions } from '../../helpers/functions_helper';
import { count } from 'console';
import { getSelectOptions } from '../../utils/data';
import { dateFormat } from '../../utils/formats';
import { useTranslation } from 'react-i18next';
import commonService from '../../services/common.service';
import fileDownload from 'js-file-download';
import { toast } from 'react-toastify';
// Import DeleteModal
import DeleteModal from "../../components/Common/DeleteModal";
import { getUserProfile } from '../../helpers/functions_helper';
import Select from '../Form/Select/Select';
import { Buffer } from "buffer";

type DocumentsTypes = {
    data?: any;
    type?: 'User' | 'Company';
}

const styles = {
    control: (base: any) => ({
        ...base,
        minHeight: 22,
        heigth: 20
    }),
    dropdownIndicator: (base: any) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    clearIndicator: (base: any) => ({
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
    }),
};

const intlErrors = { document_type_id: null, year: null };

const Documents: FC<DocumentsTypes> = ({ data, type = 'User' }) => {
    const { t } = useTranslation();
    const userObj = getUserProfile();

    const [documentList, setDocumentList] = useState<Array<any>>([]);
    const [documentTypeOptions, setDocumentTypeOptions] = useState<Array<any>>([]);
    const [uploadModal, setUploadModal] = useState<boolean>(false);
    const [year, setYear] = useState<number>((new Date()).getFullYear());
    const [selectedDocumentType, setSelectedDocumentType] = useState<any>();
    const [selectedRecord, setSelectedRecord] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [recordName, setRecordName] = useState("");
    const [deleteModal, setDeleteModal] = useState(false);
    const [errors, setErrors] = useState<any>(intlErrors);

    const getDocuments = async (year: number) => {
        setLoading(true);
        const res = await api.getAllList(`?year=${year}&module_main_id=${data?.id}&map_master=${type === 'User' ? 2 : 1}`);
        if (res.data.status === 'success') {
            setDocumentList(res.data.data)
        }
        setLoading(false);
    }

    const handleUploadDocument = async (files: any) => {
        if (!selectedDocumentType) {
            setErrors((state: any) => ({ ...state, document_type_id: t('Please select document type') }))
            return false;
        } else {
            await files.map(async (file: any) => {
                const req = {
                    'attachment_id': file?.id,
                    'document_type_id': selectedDocumentType,
                    'year': year,
                    'module_main_id': data?.id,
                    'module_sub_id': data?.user_id,
                    'document_name': file?.short_description ? file?.short_description : file?.file_name,
                    'expiry_date': file?.expiry_date,
                    'due_date': file?.due_date,
                }
                const res = await CommonService.uploadDocument(req);
            });
        }
        getDocuments(year);
        setUploadModal(false)
    }

    const handleUpload = (data: any) => {
        setSelectedDocumentType(data?.id);
        setUploadModal(true);
        setErrors(intlErrors);
    }

    const handleDownload = async (row: any) => {
        const file_extension = 'gzip';
        const result = await commonService.downloadDocument(data?.id, row?.id, file_extension);
        if (row.documents.length > 1) {
            fileDownload(result.data, 'documents.zip');
        } else {
            fileDownload(result.data, row.documents[0].attachment.file_name + "." + row.documents[0].attachment.file_extension);
        }
    }

    const handleSingleDownload = async (row: any) => {
        const result = await commonService.downloadAttachment({ id: Buffer.from(String(row.id)).toString("base64"), attachment_id: Buffer.from(String(row.attachment_id)).toString("base64") });
        if (result) {
            fileDownload(result.data, row.file_name + "." + row.file_extension);
        }
    }

    const onClickDelete = (row: any) => {
        setSelectedRecord(row);
        setRecordName(row?.document_type);
        setDeleteModal(true);
    };

    const handleDelete = async () => {
        const result = await commonService.deleteDocument(data?.id, selectedRecord?.id, year);
        if (result.data.status === 'success') {
            getDocuments(year);
            toast.success(result.data.message);
            setDeleteModal(false);
        }
    }


    useEffect(() => {
        getSelectOptions(
            'tbl_master_document_types',
            'document_type',
            "",
            [],
            { key: 'map_master', value: type === 'User' ? 2 : 1 }
        ).then((options: any) => setDocumentTypeOptions(options))
    }, [])

    useEffect(() => {
        if (data?.id)
            getDocuments(year);
    }, [year, data?.id])

    useEffect(() => {
        if (selectedDocumentType) {
            setErrors((state: any) => ({ ...state, document_type_id: null }))
        }
    }, [selectedDocumentType])


    //Drag File
    const onDragStart = (ev: any, data: any) => {
        const UserDocument = {
            attachment_id: data?.attachment?.attachment_id,
            file_extension: data?.attachment?.file_extension,
            file_path: data?.attachment?.file_path,
            id: data?.attachment?.id,
            file_name: data?.attachment?.file_name,
            type: data?.attachment?.type,
            location: 'document',
        }
        console.log(UserDocument)
        ev.dataTransfer.setData("file", JSON.stringify(UserDocument));
    };

    return (
        <div>
            <Row>
                <div className="bg-transparent mb-2">
                    <div className="d-sm-flex flex-wrap">
                        <div className="card-title text-uppercase">
                            {/* {t("Documents")} */}
                        </div>
                        <div className="ms-auto d-flex gap-2">
                            <Select
                                id="year"
                                name="year"
                                placeholder={t("Year")}
                                label=''
                                styles={styles}
                                options={yearOptions}
                                //@ts-ignore
                                defaultValue={yearOptions.find((o: any) => o.value === year)}
                                value={yearOptions.find((o: any) => o.value === year)}
                                isSearchable={true}
                                isDisabled={false}
                                onChange={(option: any) => {
                                    setYear(option.value);
                                }}
                            />
                            <button className='btn btn-primary btn-sm'><i className='fa fa-solid fa-arrows-rotate'></i></button>
                            <Button color='primary' size='sm' onClick={() => handleUpload({})}><span className="align-middle material-symbols-outlined fw-light fs-4 me-1">upload</span>{t("Upload document")}</Button>
                        </div>
                    </div>
                </div>
            </Row>
            {/* <Row>
                <Col>{t("Documents")}</Col>
                <Col sm="2">
                    <Row>
                        <Col>
                            <Select
                                id="year"
                                name="year"
                                className="form-select-sm myClassName"
                                placeholder="Year"
                                styles={styles}
                                options={yearOptions}
                                //@ts-ignore
                                defaultValue={yearOptions.find((o: any) => o.value === year)}
                                value={yearOptions.find((o: any) => o.value === year)}
                                isSearchable={true}
                                isDisabled={false}
                                onChange={(option: any) => {
                                    setYear(option.value);
                                }}
                            />
                        </Col>
                        <Col sm="3">
                            <button className='btn btn-primary btn-sm'><i className='fa fa-solid fa-arrows-rotate'></i></button>
                        </Col>
                    </Row>
                </Col>
                <Col sm="2">
                    <Button color='primary' size='sm' onClick={() => handleUpload({})}><span className="align-middle material-symbols-outlined fw-light fs-4 me-1">upload</span>{t("Upload document")}</Button>
                </Col>
            </Row> */}
            <Row>
                <Col>
                    <Table>
                        <thead>
                            <tr>
                                <th>{t("SI Nr")}</th>
                                <th>{t("Type of document")}</th>
                                <th>{t("Name of the document")}</th>
                                <th>{t("Expiry date")}</th>
                                <th>{t("Due date")}</th>
                                <th>{t("Year")}</th>
                                <th>{t("Count")}</th>
                                <th>{t("Actions")}</th>
                                {/* <th>{t("Upload")}</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan={7}>{t("Loading")}</td>
                                </tr>
                            ) :
                                documentList.length === 0 ? (
                                    <tr>
                                        <td colSpan={7}>{t("No documents found")}</td>
                                    </tr>
                                ) :
                                    documentList?.map((doc: any, i: number) => (
                                        <tr key={i}>
                                            <td>{i + 1}</td>
                                            <td>{doc.document_type}</td>
                                            <td >
                                                {doc?.documents?.map((d: any) => <div onDragStart={(e) => onDragStart(e, d)} draggable className='link-primary' onClick={() => handleSingleDownload(d?.attachment)}>{d?.document_name}</div>)}
                                            </td>
                                            <td>
                                                {doc?.documents?.map((d: any) => <div>{d?.expiry_date ? dateFormat(d?.expiry_date) : '-'}</div>)}
                                            </td>
                                            <td>
                                                {doc?.documents?.map((d: any) => <div>{d?.due_date ? dateFormat(d?.due_date) : '-'}</div>)}
                                            </td>
                                            <td><div>{doc?.documents[0]?.year}</div></td>
                                            <td>{doc?.documents?.length}</td>
                                            <td>
                                                <div className='d-flex gap-2 text-end'>
                                                    <Button
                                                        color='primary'
                                                        outline
                                                        size='sm'
                                                        disabled={doc?.documents?.length === 0}
                                                        onClick={() => handleDownload(doc)}
                                                    >
                                                        <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">download</span>{t("Download")}
                                                    </Button>
                                                    {(userObj.USER_COMPANY === 1 || userObj.USER_ID === doc.documents[0]?.attachment?.created_by) &&
                                                        <Button
                                                            color='danger'
                                                            outline
                                                            size='sm'
                                                            disabled={doc?.documents?.length === 0}
                                                            onClick={() => onClickDelete(doc)}
                                                        >
                                                            <i className='fa-solid fa-trash'></i> {t("Delete")}
                                                        </Button>
                                                    }
                                                </div>
                                            </td>
                                            {/* <td>
                                                <Button color='primary' outline size='sm' onClick={() => handleUpload(doc)}>
                                                    <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">upload</span>{t("Upload")}
                                                </Button>
                                            </td> */}
                                        </tr>
                                    ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            {deleteModal && (
                <DeleteModal
                    recordName={recordName}
                    modalTitle="Document"
                    show={deleteModal}
                    onDeleteClick={handleDelete}
                    onCloseClick={() => setDeleteModal(false)}
                    loading={loading}
                />
            )}

            {uploadModal && (
                <Modal
                    size="xl"
                    isOpen={uploadModal}
                    toggle={() => setUploadModal(false)}
                    backdrop="static"
                    keyboard={false}
                    autoFocus={false}
                >
                    <div className="modal-header">
                        <h5 className="modal-title">{t("Upload document")}</h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => setUploadModal(false)}
                            aria-label="Close"
                        ></button>
                    </div>
                    <ModalBody>
                        <>
                            <Row>
                                <Col>
                                    <div className='mb-3'>
                                        <Select
                                            id="document_type_id"
                                            name="document_type_id"
                                            placeholder="Select"
                                            label={t("Type of document")}
                                            required
                                            options={documentTypeOptions}
                                            value={documentTypeOptions.find((o: any) => o.value === selectedDocumentType)}
                                            isSearchable={true}
                                            isDisabled={false}
                                            error={errors.document_type_id}
                                            touched={true}
                                            onChange={(option: any) => {
                                                setSelectedDocumentType(option.value);
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col>
                                    <Select
                                        id="year"
                                        name="year"
                                        placeholder={t("Year")}
                                        options={yearOptions}
                                        label={t("Year")}
                                        required
                                        //@ts-ignore
                                        defaultValue={yearOptions.find((o: any) => o.value === year)}
                                        value={yearOptions.find((o: any) => o.value === year)}
                                        isSearchable={true}
                                        isDisabled={false}
                                        onChange={(option: any) => {
                                            setYear(option.value);
                                        }}
                                    />
                                </Col>
                                {/* <Col>
                                    <Label>{t("dd")}</Label>
                                    <Input type='text' />
                                </Col> */}
                            </Row>
                            <Row>
                                <Col>
                                    <Dropzone
                                        moduleData={{
                                            module_app_id: type === 'User' ? 11 : 12,
                                            module_main_id: data?.id,
                                            module_sub_id: type === 'User' ? data?.user_id : data?.company_id,
                                            section_id: selectedDocumentType,
                                            file_path: type === 'User' ? "users" : "company",
                                        }}
                                        hideList={true}
                                        fetchData={true}
                                        isOpen={true}
                                        enableExpiryDate={true}
                                        onUpload={(files: any) => handleUploadDocument(files)}
                                    />
                                </Col>
                            </Row>
                        </>
                    </ModalBody>

                </Modal>
            )}
        </div>
    )
}

export default Documents;