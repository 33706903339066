import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Select from "react-select";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import QuestionnairesList from "./QuestionnairList";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Form,
  Label,
  Input,
  Button,
  FormFeedback,
  InputGroup,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap";
import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";
import labelPlugin from "flatpickr/dist/plugins/labelPlugin/labelPlugin";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

// Static Data
import { taskStatusOptionsTrans, taskPriorityTrans } from "../../common/staticdata";

// Actions
import { QuestionnaireAction } from "../../store/actions";

//Mail Action
import { setDraftEmailData } from "../../store/mails/actions";

// Service
import commonService from "../../services/common.service";

// Import Breadcrumb
import Breadcrumbs from "../../components/Layout/Breadcrumb";
import ProgressBar from "../../components/Common/ProgressBar";

import { dateFormat, formatCurrency } from "../../utils/formats";

import {
  filteredOptions,
  filteredByFunction,
  getOptionById,
  getOptionValueById,
  addIndividualInCompany,
  yearsBackword,
  yearsOnward,
  filterListInByKey,
  filterListNotInByKey,
  filterFeesTypeByLanguage,
  getUserProfile,
  filteredByStatus,
  archiveSetDisabled,
  datePickerI18,
  handleAutoSave,
} from "../../helpers/functions_helper";

import NewTimeSheetFormModal from "../TimeSheets/NewTimeSheetForm";
import SubTaskListModal from "./SubTaskListModal";
import MultiComments from "../../components/Common/MultiComments";
import Dropzone from "../../components/Common/Dropzone";
import QuestionnaireModal from "./QuestionnaireModal";

// Import Loader
import Loader from "../../components/Layout/Loader";
import SubTasksService from "../../services/subtasks.service";

import ScrollUp from "../../components/ScrollUpDown/ScrollUp";
import ScrollDown from "../../components/ScrollUpDown/ScrollDown";
import { useTranslation } from "react-i18next";
import questionnaireService from "../../services/questionnaire.service";

import NavItems from "./NavItemsTask";
import Drawer from "../../components/Drawer";
import EditCompany from "../Masters/Company/EditCompany";
import EditUser from "../Users/EditUser";
import tasksService from "../../services/tasks.service";
import timeSheetsService from "../../services/time.sheets.service";
import PreliminaryForm from "./PreliminaryForm";
import EmailButton from "../Email/components/EmailButton";

import { useCallbackPrompt } from '../../hooks/useCallbackPrompt'
import Alert from "../../components/Common/Alert";
import NavigationAlert from "../../components/Common/NavigationAlert";

const EditTask = (props: any) => {
  const { t } = useTranslation();
  document.title = `${t("Update")} ${t("task")} | ` + process.env.REACT_APP_NAME;

  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "/tasks/kanban-view",
      label: t("Kanban view"),
      active: false,
    },
    {
      url: "/tasks/all-tasks",
      label: t("List view"),
      active: false,
    },
    {
      url: "#",
      label: `${t("Update")} ${t("task")}`,
      active: true,
    },
  ];

  const breadcrumbItemsExternalUser = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "/tasks/all-tasks",
      label: t("Tasks"),
      active: false,
    },
    {
      url: "#",
      label: `${t("Update")} ${t("task")}`,
      active: true,
    },
  ];

  const taskToast = React.useRef(null) as any;
  const timesheetToast = React.useRef(null) as any;
  const questionnaireToast = React.useRef(null) as any;
  const archiveToast = React.useRef(null) as any;
  const flagToast = React.useRef(null) as any;
  const { id, task_id } = useParams();
  const refId = { id, task_id };
  const userProfile = getUserProfile("all-tasks");
  const userAccess = getUserProfile("all-users");
  const companyAccess = getUserProfile("all-company");
  const subtaskAccess = getUserProfile("all-sub-task");
  const questionerAccess = getUserProfile("all-questionnaires");
  const timesheetProfile = getUserProfile("all-time-sheets");
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(true);
  const [redirectCode, setRedirectCode] = useState("");
  const [modal, setmodal] = useState(false);

  const [invoicedTaskAmount, setInvoicedTaskAmount] = useState<number>(0);
  const [openPreliminaryModal, setOpenPreliminaryModal] = useState<boolean>(false);

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [isSensitiveTask, setIsSensitiveTask] = useState<number>(0);
  const [editBudgetAmount, setEditBudgetAmount] = useState<boolean>(false);
  const [taskData, setTaskData] = useState<any>();

  const localFormData = handleAutoSave("GET");
  const [autoSave, setAutoSave] = useState(false);
  const [autoSaveAlert, setAutoSaveAlert] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(autoSave);

  const [initialData, setInitialData] = useState({
    id: "",
    task_id: "",
    code: "",
    custome_code: "",
    title: "",
    type: "",
    timesheet_count: 0,
    purchase_order_number: "",
    is_budget: 0,
    budget_amount: 0,
    fees_amount: 0,
    status: "1",
    preparer: "",
    preparer_progress: 0,
    reviewer: "",
    reviewer_progress: 0,
    file_manager: "",
    file_manager_progress: 0,
    partner: "",
    partner_progress: 0,
    is_assistant_administrative: 0,
    assistant_administrative: "",
    assistant_administrative_progress: 0,
    assignee: "",
    priority: "",
    start_date: moment().format("YYYY-MM-DD hh:mm:ss"),
    due_date: "",
    closing_date: "",
    company: "",
    company_name: "",
    project: "",
    contact_person_id: "",
    invoice_contact_person_id: "",
    hr_contact_person_id: "",
    contact_person: "",
    responsible_person: "",
    income_year: "",
    expense: 0,
    sensitive: 0,
    need_more_info: 0,
    tagging_user_name: "",
    preliminary_billing: 0,
    is_preliminary_fixed_amount: 1,
    automatic_trigger: 0,
    preliminary_percentage: 0,
    preliminary_details: null,
    tax_return_type_id: "",
    submit_btn_code: "update_add_new",
    created_by: "",

    created_by_first_name: "",
    created_by_last_name: "",
    created_at: "",
    updated_by_first_name: "",
    updated_by_last_name: "",
    updated_at: "",
    is_archive: 0,
  });

  const fee_type_initialData = {
    id: 0,
    fee_id: "",
    category: "",
    category_of_fees: "",
    description: "",
    fees_amount: 0,
    fees_code: "",
    color_code: "",
    is_resident: 0,
    is_non_resident: 0,
    is_tax_return_type: 0,
  };

  const handleRedirect = (isCancel = false) => {
    setAutoSave(false);
    handleAutoSave("CLEAR");

    if (isCancel === true) {
      return navigate("/tasks/kanban-view");
    }

    if (redirectCode === "update_add_new") {
      return navigate("/tasks/new-task?redirect=true");
    } else if (redirectCode === "update_close") {
      return navigate("/tasks/kanban-view?redirect=true");
    } else if (redirectCode === "update") {
      get();
      window.scrollTo(0, 0);
    } else {
      if (userProfile.IS_INTERNAL) {
        return navigate("/tasks/kanban-view?redirect=true");
      } else {
        return navigate("/tasks/all-tasks?redirect=true");
      }
    }
  };

  const handleFocus = (event: any) => event.target.select();

  const handlePreliminaryBilling = () => {
    if (initialData.preliminary_billing === 1) {
      setInitialData((preState) => ({
        ...preState,
        preliminary_billing: 0,
        is_preliminary_fixed_amount: 1,
        preliminary_percentage: 0,
      }));
    } else {
      setInitialData((preState) => ({ ...preState, preliminary_billing: 1 }));
    }
  };

  const handleBudget = () => {
    if (initialData.is_budget === 1) {
      setInitialData((preState) => ({
        ...preState,
        is_budget: 0,
        budget_amount: 0,
      }));
    } else {
      setInitialData((preState) => ({ ...preState, is_budget: 1 }));
    }
  };

  const handleEditBudget = () => { };

  const handleChange = (fieldName: string, fieldValue: any) => {
    setInitialData((preState) => ({ ...preState, [fieldName]: fieldValue }));
    setAutoSave(true);
  };

  const handleArchive = async () => {
    archiveToast.current = toast(t("Updating archive status..."), {
      autoClose: false,
      isLoading: true,
    });

    const res = (await commonService.handleArchive({
      id: initialData.id,
      table_name: "tbl_task",
      field_name: "is_archive",
      is_archive: initialData.is_archive,
    })) as any;

    if (res.data.status === "success") {
      toast.update(archiveToast.current, {
        type: toast.TYPE.SUCCESS,
        render: res.data.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });

      // handleChange("is_archive", initialData.is_archive === 1 ? 0 : 1);

      setInitialData((prevData: any) => ({
        ...prevData,
        is_archive: initialData.is_archive === 1 ? 0 : 1,
      }));

    } else {
      toast.update(archiveToast.current, {
        type: toast.TYPE.ERROR,
        render: res.data.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
  };

  const commentAttachmentIDs = [
    {
      value: initialData.preparer,
      label: t("Preparer"),
      isHidden: userProfile.IS_INTERNAL ? false : true,
    },
    {
      value: initialData.reviewer,
      label: t("Reviewer"),
      isHidden: initialData.sensitive ? true : userProfile.IS_INTERNAL ? false : true,
    },
    {
      value: initialData.file_manager,
      label: userProfile.IS_INTERNAL ? t("Responsible to sign off") : t("File manager"),
      isHidden: initialData.sensitive ? true : false,
    },
    {
      value: initialData.partner,
      label: t("Partner"),
      isHidden: userProfile.IS_INTERNAL ? false : true,
    },
    {
      value: initialData.assistant_administrative,
      label: t("Assistant administrative"),
      isHidden: initialData.sensitive ? true : userProfile.IS_INTERNAL ? false : true,
    },
    {
      value: initialData.contact_person,
      label: t("Contact person"),
      isHidden: initialData.sensitive ? true : false,
    },
    {
      value: initialData.responsible_person,
      label: t("Person concerned"),
      isHidden: initialData.sensitive ? true : false,
    },
  ];

  const handlePreparer = (fieldValue: any) => {
    if (fieldValue === initialData.reviewer) {
      toast.error(t("Preparer can not be same as a reviewer!"));
    } else if (fieldValue === initialData.file_manager) {
      toast.error(t("Preparer can not be same as a responsible to sign off!"));
    } else if (fieldValue === initialData.partner) {
      toast.error(t("Preparer can not be same as a partner!"));
    } else {
      // handleChange("preparer", fieldValue);

      setInitialData((prevData: any) => ({
        ...prevData,
        preparer: fieldValue,
      }));
    }
  };

  let {
    getUserOptionsList,
    getCompanyOptionsList,
    getFeesOptionsList,

    questionnaireSuccessMsg,
    questionnaireErrorMsg,
  } = useSelector((state: any) => ({
    getUserOptionsList: state.Common.GET_USER_OPTIONS_LIST_SUCCESS,
    getCompanyOptionsList: state.Common.GET_COMPANY_OPTIONS_LIST_SUCCESS,
    getFeesOptionsList: state.Common.GET_FEES_OPTIONS_LIST_SUCCESS,

    questionnaireSuccessMsg: state.Questionnaire.SEND_QUESTIONNAIRE_FROM_TASK_SUCCESS.message,
    questionnaireErrorMsg: state.Questionnaire.SEND_QUESTIONNAIRE_FROM_TASK_ERROR.message,
  }));

  useEffect(() => {
    if (redirectCode !== "") {
      handleRedirect();
    }
  }, [redirectCode]);

  const [isSubmit, setIsSubmit] = useState(false);
  Yup.setLocale({
    mixed: {
      required: "${label} " + t("field is required."),
    },
    string: {
      min: "${label} " + t("must be at least") + " ${min} " + t("characters."),
      max: "${label} " + t("may not be greater than") + " ${max} " + t("characters."),
    },
  });
  const validationSchema = Yup.object().shape({
    custome_code: Yup.string().label(t("Task ID")).required().min(3).max(15),
    title: Yup.string().label(t("Task title")).required().min(2).max(200),
    type: Yup.string().label(t("Task type")).required(),
    fees_amount: Yup.number().label(t("Task amount")).required(),
    status: Yup.string().label(t("Task status")).required(),
    preparer: Yup.string().label(t("Preparer")).required(),
    reviewer: Yup.string().label(t("Reviewer")).required(),
    file_manager: Yup.string().label(t("Responsible to sign off")).required(),
    partner: Yup.string().label(t("Partner")).required(),
    start_date: Yup.string().label(t("Start date")).required(),
    due_date: Yup.string().label(t("Internal due date")).required(),
    closing_date: Yup.string().label(t("Due date")).required(),
    priority: Yup.string().label(t("Task priority")).required(),
    contact_person: Yup.string()
      .label(t("Contact person"))
      .when(["sensitive"], {
        is: (sensitive: number) => sensitive !== 1,
        then: Yup.string().label(t("Contact person")).required(),
      }),
    assignee: Yup.string()
      .label(t("Responsible person for sending"))
      .when(["status"], {
        is: (status: string) => parseInt(status) === 5,
        then: Yup.string().label(t("Responsible person for sending")).required(),
      }),
  });

  const update = async (values: newTaskPropsType) => {
    try {
      setLoading(true);
      taskToast.current = toast(`${t("Updating")} ${t("task")}...`, {
        autoClose: false,
        isLoading: true,
      });

      const res = (await tasksService.updateTask(values)) as any;
      if (res.data.status === "success") {
        setRedirectCode(res.data.data.submit_btn_code);

        toast.update(taskToast.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoading(false);
        setTimeSpent(false);
      } else {
        toast.update(taskToast.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        if (res.data.data) {
          Object.keys(res.data.data).map((key) => {
            toast(res.data.data[key][0], {
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              delay: 500,
            });
          });
        }

        if (res.data.flag === 1) {
          setSubTaskListData(res.data);
          setIsSubTaskList(true);
        }

        setLoading(false);
      }
    } catch (err: any) {
      toast.update(taskToast.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values, { setErrors }) => {
      if (timeSpent) {
        update(values);
      } else {
        setIsSubmit(true);

        setTimeSpentData({
          is_new: false,
          module_id: 2, // Task
          module_main_id: id,
          module_sub_id: task_id,
          main_id: id,
          title: initialData.title,
          company: initialData.company,
          project: initialData.project,
          contact_person: initialData.contact_person,
          responsible_person: initialData.responsible_person,
          fees_type: initialData.type,
          purchase_order_number: initialData.purchase_order_number,
          fees_amount: initialData.fees_amount,
          expense: inputExpence, // initialData.expense,
          is_sensitive: initialData.sensitive,

          start_date: "",
          start_time: "",
          end_time: "",
          time_spent: "",
        });
        setNewTimeSpentFormModal(true);
      }
    },
  });

  useEffect(() => {
    if (!validation.isSubmitting) return;
    if (Object.keys(validation.errors).length > 0) {
      document
        .getElementById(Object.keys(validation.errors)[0])
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
      document.getElementById(Object.keys(validation.errors)[0])?.focus({ preventScroll: true });
    }
  }, [validation.isSubmitting, validation.errors]);

  // get and set Task API call -------------------------------------------------
  const get = async () => {
    try {
      setLoading(true);
      const res = (await tasksService.getTask({
        id,
        task_id,
      })) as any;
      if (res.data.status === "success") {
        updateInitialData(res.data.data[0]);
        setTaskData(res.data.data[0]);
        setLoading(false);

        if (res.data?.data[0]?.questionnaire_id && res.data?.data[0]?.questionnaire_id !== null) {
          setQuestionnairesID(res.data?.data[0]?.questionnaire_id);
          let data = {
            id: res.data?.data[0]?.q_id,
            questionnaire_id: res.data?.data[0]?.q_questionnaire_id,
            title: res.data?.data[0]?.q_title,
            year: res.data?.data[0]?.q_year,
            status: res.data?.data[0]?.q_status_value,
            color: res.data?.data[0]?.q_status_color,
            status_id: res.data?.data[0]?.q_status_id,
          };
          setQuestionnairesData(data);
        }

        if (res.data?.data[0]?.workday_id) {
          setWorkDayLink(res.data?.data[0]?.workday_id);
        }
      } else {
        toast(res.data.message, {
          type: toast.TYPE.ERROR,
          autoClose: 5000,
          delay: 500,
        });
        setLoading(false);
        handleRedirect();
      }
    } catch (err: any) {
      toast(err?.message, {
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        delay: 500,
      });
      setLoading(false);
      handleRedirect();
    }
  };

  useEffect(() => {
    if (localFormData && localFormData?.initialData) {
      updateInitialData(localFormData?.initialData);
      setAutoSaveAlert(true);
    } else {
      setLoader(true);
      get();
    }
  }, [id, task_id]);

  const [subtaskCount, setSubtaskCount] = useState(0);
  const [invoiceCount, setInvoiceCount] = useState(0);
  const [taskStatus, setTaskStatus] = useState("");
  const [feesAmount, setFeesAmount] = useState("");
  const [feesAmountUsed, setFeesAmountUsed] = useState(false);

  function updateInitialData(data: any) {
    setPreExpence(data.expense);
    setInputExpence(0);
    setInitialData((preState) => ({
      ...preState,
      id: data.id,
      task_id: data.task_id,
      code: data.code,
      custome_code: data.custome_code,
      title: data.title,
      type: data.type,
      timesheet_count: data.timesheet_count,
      purchase_order_number: data.purchase_order_number,
      is_budget: data.is_budget,
      budget_amount: data.budget_amount,
      fees_amount: data.fees_amount,
      status: data.status,
      preparer: data.preparer == null ? "" : data.preparer,
      preparer_progress: data.preparer_progress,
      reviewer: data.reviewer == null ? "" : data.reviewer,
      reviewer_progress: data.reviewer_progress,
      file_manager: data.file_manager == null ? "" : data.file_manager,
      file_manager_progress: data.file_manager_progress,
      partner: data.partner == null ? "" : data.partner,
      partner_progress: data.partner_progress,
      is_assistant_administrative: data.is_assistant_administrative,
      assistant_administrative:
        data.assistant_administrative == null ? "" : data.assistant_administrative,
      assistant_administrative_progress: data.assistant_administrative_progress,
      assignee: data.assignee == null ? "" : data.assignee,
      priority: data.priority,
      start_date: data.start_date,
      due_date: data.due_date,
      closing_date: data.closing_date,
      company: data.company,
      company_name: data.company_name ? data.company_name : "Individual",
      project: data.project,
      contact_person_id: data.contact_person_id == null ? "" : data.contact_person_id,
      invoice_contact_person_id:
        data.invoice_contact_person_id == null ? "" : data.invoice_contact_person_id,
      hr_contact_person_id: data.hr_contact_person_id == null ? "" : data.hr_contact_person_id,
      contact_person: data.contact_person == null ? "" : data.contact_person,
      responsible_person:
        data?.subtask_count > 0
          ? ""
          : data.responsible_person == null
            ? ""
            : data.responsible_person,
      income_year: data.income_year == null ? "" : data.income_year,
      expense: data.expense,
      sensitive: data.sensitive,
      need_more_info: data.need_more_info,
      tagging_user_name: data.tagging_user_name,
      preliminary_billing: data.preliminary_billing,
      is_preliminary_fixed_amount: data.is_preliminary_fixed_amount,
      preliminary_percentage: data.preliminary_percentage,
      preliminary_details: data.preliminary_details,
      automatic_trigger: data.automatic_trigger,
      tax_return_type_id: data.tax_return_type_id == null ? "" : data.tax_return_type_id,
      created_by: data.created_by,

      created_by_first_name: data.created_by_first_name,
      created_by_last_name: data.created_by_last_name,
      created_at: data.created_at,
      updated_by_first_name: data.updated_by_first_name,
      updated_by_last_name: data.updated_by_last_name,
      updated_at: data.updated_at,
      is_archive: data.is_archive,
    }));

    setSubtaskCount(data?.subtask_count);
    setInvoiceCount(data?.invoice_count);
    setInvoicedTaskAmount(data?.invoice_total);
    setTaskStatus(data.status);
    setFeesAmount(data.fees_amount);
    // setCompanyID(data.company);
    setTypeID(data.type);
    setIsSensitiveTask(data.sensitive);
    setIsSubmit(false);
    window.scrollTo(0, 0);
  }

  // Select options list API call -------------------------------------------------
  const [query, setQuery] = useState("");
  const getSelectOptions = async (tableName: string, fieldName: string) => {
    const response = await commonService.getSelectOptionsList(
      tableName,
      fieldName,
      query ? query : ""
    );
    return response.data.data;
  };

  const getDependentSelectOptions = async (
    tableName: string,
    fieldName: string,
    whereField: string,
    whereID: number
  ) => {
    const response = await commonService.getDependentSelectOptionsList(
      tableName,
      fieldName,
      whereField,
      whereID,
      query ? query : ""
    );
    return response.data.data;
  };

  /* Fees Type Option */
  const [typeOption, setTypeOption] = useState([]);
  const [loadingTypeOption, setloadingTypeOption] = useState(true);
  const [disabledTypeOption, setDisabledTypeOption] = useState(true);
  const [typeID, setTypeID] = useState<number>(0);
  useEffect(() => {
    if (getFeesOptionsList.data) {
      let feesOptionsList = filterFeesTypeByLanguage(getFeesOptionsList.data);

      if (initialData.sensitive == 1) {
        const sensitive_fee_type_list: any = filterListInByKey(feesOptionsList, "is_sensitive", 1);
        sensitive_fee_type_list.push({ value: "", label: t("-- Remove --") });
        setTypeOption(sensitive_fee_type_list);
      } else {
        const fee_type_list: any = filterListInByKey(feesOptionsList, "is_sensitive", 0);
        fee_type_list.push({ value: "", label: t("-- Remove --") });
        setTypeOption(fee_type_list);
      }
      //setTypeOption(feesOptionsList);
      setloadingTypeOption(false);

      if (
        (userProfile.USER_FUNCTION === 6 ||
          userProfile.USER_ID === parseInt(initialData.created_by)) &&
        parseInt(initialData.status) < 6
      ) {
        setDisabledTypeOption(false);
      }
    }
  }, [
    getFeesOptionsList,
    userProfile.USER_ID,
    userProfile.USER_FUNCTION,
    initialData.sensitive,
    localStorage.getItem("I18N_LANGUAGE"),
  ]);
  /* End  Fees Type Option */

  /* Fees Type & Task Type Option */
  const [isPreliminaryType, setPreliminaryType] = useState<boolean>(false);
  const [isTaxReturnType, setTaxReturnType] = useState<boolean>(false);
  const [isShowIncomeYear, setIsShowIncomeYear] = useState<boolean>(false);
  const [incomeYearOption, setIncomeYearOption] = useState<any>([]);
  const [taxReturnTypeOption, setTaxReturnTypeOption] = useState<any>([]);
  const [isAssistantAdministrative, setIsAssistantAdministrative] = useState<boolean>(false);
  const [feesAllTypeOption, setFeesAllTypeOption] = useState<feesTypeProps>(fee_type_initialData);
  useEffect(() => {
    if (typeID > 0) {
      if (getFeesOptionsList.data) {
        let feesOptionsList = filterFeesTypeByLanguage(getFeesOptionsList.data);

        const item = getOptionById(feesOptionsList, typeID) as feesTypeProps | any;
        setFeesAllTypeOption(item);
        if (feesAmountUsed === false) {
          // handleChange("fees_amount", feesAmount);

          setInitialData((prevData: any) => ({
            ...prevData,
            fees_amount: feesAmount,
          }));

          setFeesAmountUsed(true);
        } else {
          // handleChange("fees_amount", item?.fees_amount);

          setInitialData((prevData: any) => ({
            ...prevData,
            fees_amount: item?.fees_amount,
          }));
        }

        if (item?.is_preliminary_billing) {
          setPreliminaryType(true);
        } else {
          setPreliminaryType(false);
        }

        if (item?.is_tax_return_type && userProfile.IS_INTERNAL) {
          setTaxReturnType(true);
        } else {
          setTaxReturnType(false);
        }

        if (item?.is_income_year || item?.is_income_year_onward) {
          if (item?.is_income_year) {
            setIncomeYearOption(yearsBackword);
          } else if (item?.is_income_year_onward) {
            setIncomeYearOption(yearsOnward);
          }
          setIsShowIncomeYear(true);
        } else {
          setIsShowIncomeYear(false);
        }

        if (item?.is_assistant_administrative) {
          setIsAssistantAdministrative(true);
        } else {
          setIsAssistantAdministrative(false);
        }
      }
    }
  }, [typeID, getFeesOptionsList, localStorage.getItem("I18N_LANGUAGE")]);
  /* End  Fees Type Option */

  /* Task Status Option */
  const [taskStatusOption, settaskStatusOption] = useState([]);
  // const [loadingStatusOption, setloadingStatusOption] = useState(true);
  const [disabledStatusOption, setDisabledStatusOption] = useState(false);
  useEffect(() => {
    settaskStatusOption(taskStatusOptionsTrans(t) as any);
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  const [taskPriority, setTaskPriority] = useState([]);
  useEffect(() => {
    setTaskPriority(taskPriorityTrans(t) as any);
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  const [filteredTaskStatusOption, setFilteredTaskStatusOption] = useState([]);
  useEffect(() => {
    if (userProfile.USER_ID && taskStatusOption.length > 0) {
      let items = [] as any;
      if (
        userProfile.USER_ID === parseInt(initialData.preparer) &&
        (parseInt(initialData.status) === 1 || parseInt(initialData.status) === 2)
      ) {
        items = filteredOptions(taskStatusOption, [1, 2, 3, 7]);
        setFilteredTaskStatusOption(items);
        setDisabledStatusOption(false);
      } else if (
        userProfile.USER_ID === parseInt(initialData.reviewer) &&
        parseInt(initialData.status) === 3
      ) {
        items = filteredOptions(taskStatusOption, [2, 3, 4, 7]);
        setFilteredTaskStatusOption(items);
        setDisabledStatusOption(false);
      } else if (
        userProfile.USER_ID === parseInt(initialData.file_manager) &&
        parseInt(initialData.status) === 4
      ) {
        items = filteredOptions(taskStatusOption, [3, 4, 5, 6, 7]);
        setFilteredTaskStatusOption(items);
        setDisabledStatusOption(false);
      } else if (
        userProfile.USER_ID === parseInt(initialData.partner) &&
        parseInt(initialData.status) === 5
      ) {
        // if(initialData.is_assistant_administrative === 1){
        //   items = filteredOptions(taskStatusOption, [4, 5]);
        // }else{
        //   items = filteredOptions(taskStatusOption, [4, 5, 6]);
        // }

        items = filteredOptions(taskStatusOption, [1, 2, 3, 4, 5, 6, 7]);
        setFilteredTaskStatusOption(items);
        setDisabledStatusOption(false);
      } else if (
        (userProfile.USER_ID === parseInt(initialData.assistant_administrative) ||
          userProfile.USER_ID === parseInt(initialData.assignee)) &&
        parseInt(initialData.status) === 5
      ) {
        items = filteredOptions(taskStatusOption, [5, 6]);
        setFilteredTaskStatusOption(items);
        setDisabledStatusOption(false);
      } else {
        setFilteredTaskStatusOption(taskStatusOption);
        setDisabledStatusOption(true);
      }

      if (userProfile.USER_FUNCTION === 6) {
        if (parseInt(initialData.status) >= 6) {
          setFilteredTaskStatusOption(taskStatusOption);
          setDisabledStatusOption(true);
        } else {
          items = filteredOptions(taskStatusOption, [1, 2, 3, 4, 5, 6, 7]);
          setFilteredTaskStatusOption(items);
          setDisabledStatusOption(false);
        }
      }
    }
  }, [userProfile.USER_ID, taskStatusOption, taskStatus, redirectCode]);
  /* End  Task Status Option */

  /* Preparer Option */
  const [preparerOption, setPreparerOption] = useState([]);
  const [loadingPreparer, setloadingPreparer] = useState(true);
  const [disabledPreparer, setDisabledPreparer] = useState(true);
  /* Reviewer Option */
  const [reviewerOption, setReviewerOption] = useState([]);
  const [disabledReviewer, setDisabledReviewer] = useState(true);
  /* FileManager Option */
  const [fileManagerOption, setFileManagerOption] = useState([]);
  const [disabledFileManager, setDisabledFileManager] = useState(true);
  /* Partner Option */
  const [partnerOption, setPartnerOption] = useState([]);
  const [disabledPartner, setDisabledPartner] = useState(true);
  /* Assistant administrative Option */
  const [assistantAdministrativeOption, setAssistantAdministrativeOption] = useState([]);
  const [disabledAdministrative, setDisabledAdministrative] = useState(true);
  /* Assignee */
  const [assigneeOption, setAssigneeOption] = useState([]);

  /* Filter Value */
  const userListUpdate = (data: any) => {
    // if task is sensitive
    if (initialData.sensitive == 1) {
      let user_partner_list = filteredByFunction(data, [6]) as any;
      setPartnerOption(archiveSetDisabled(user_partner_list, initialData.partner));
      setPreparerOption(archiveSetDisabled(user_partner_list, initialData.preparer));
      setReviewerOption(archiveSetDisabled(user_partner_list, initialData.reviewer));
      setFileManagerOption(archiveSetDisabled(user_partner_list, initialData.file_manager));
      setAssigneeOption(archiveSetDisabled(user_partner_list, initialData.assignee));
      initialData.assistant_administrative = "";

      let user_partner_list_dis_pre = filteredByFunction(data, [6]) as any;
      user_partner_list_dis_pre.map((item: any, index: number) => {
        if (item.value === initialData.preparer) {
          user_partner_list_dis_pre[index] = {
            label: item.label,
            value: item.value,
            isDisabled: true,
          };
        }
      });

      setReviewerOption(archiveSetDisabled(user_partner_list_dis_pre, initialData.reviewer));
      if (initialData.preparer === initialData.reviewer) {
        initialData.reviewer = "";
      }

      setPartnerOption(archiveSetDisabled(user_partner_list_dis_pre, initialData.partner));
      if (initialData.preparer === initialData.partner) {
        initialData.partner = "";
      }

      if (userProfile.IS_INTERNAL && parseInt(initialData.status) < 6) {
        setDisabledReviewer(false);
      }

      if (userProfile.IS_INTERNAL && parseInt(initialData.status) < 6) {
        setDisabledPartner(false);
      }

      if (userProfile.IS_INTERNAL && parseInt(initialData.status) < 6) {
        setDisabledFileManager(false);
      }

      if (userProfile.IS_INTERNAL && parseInt(initialData.status) < 6) {
        setDisabledAdministrative(false);
      }

      return;
    }

    const userlist_without_assi = filterListNotInByKey(data, "function", 1) as any;
    setPreparerOption(archiveSetDisabled(userlist_without_assi, initialData.preparer));
    setReviewerOption(archiveSetDisabled(userlist_without_assi, initialData.reviewer));
    setFileManagerOption(archiveSetDisabled(userlist_without_assi, initialData.file_manager));
    setAssigneeOption(archiveSetDisabled(data, initialData.assignee));

    let user_partner_list = filteredByFunction(userlist_without_assi, [6]) as any; // Partners
    setPartnerOption(archiveSetDisabled(user_partner_list, initialData.partner));

    let userlist_assi = archiveSetDisabled(
      filteredByFunction(data, [1]) as any,
      initialData.assistant_administrative
    ); // Assistant
    setAssistantAdministrativeOption(userlist_assi);

    if (initialData.preparer) {
      const userlist_without_assi_k = filterListNotInByKey(data, "function", 1) as any;
      userlist_without_assi_k.map((item: any, index: number) => {
        if (item.value === initialData.preparer) {
          userlist_without_assi_k[index] = {
            label: item.label,
            value: item.value,
            isDisabled: true,
          };
        }
      });

      setReviewerOption(archiveSetDisabled(userlist_without_assi_k, initialData.reviewer));
      if (initialData.preparer === initialData.reviewer) {
        initialData.reviewer = "";
      }

      let items1 = filteredByFunction(userlist_without_assi_k, [6]) as any; // Partners
      setPartnerOption(archiveSetDisabled(items1, initialData.partner));
      if (initialData.preparer === initialData.partner) {
        initialData.partner = "";
      }
    }

    if (userProfile.IS_INTERNAL && parseInt(initialData.status) < 6) {
      setDisabledReviewer(false);
    }

    if (userProfile.IS_INTERNAL && parseInt(initialData.status) < 6) {
      setDisabledFileManager(false);
    }

    if (userProfile.IS_INTERNAL && parseInt(initialData.status) < 6) {
      setDisabledPartner(false);
    }

    if (userProfile.IS_INTERNAL && parseInt(initialData.status) < 6) {
      setDisabledAdministrative(false);
    }
  };
  /*End Filter Value */

  /* ROLE Preparer, Reviewer, FileManager, Partner, Assistant administrative Option */
  useEffect(() => {
    if (getUserOptionsList.data) {
      userListUpdate(getUserOptionsList.data);
      setloadingPreparer(false);

      if (userProfile.IS_INTERNAL && parseInt(initialData.status) < 6) {
        setDisabledPreparer(false);
      }
    }
  }, [getUserOptionsList, userProfile.USER_COMPANY]);

  useEffect(() => {
    if (initialData.preparer !== "" && preparerOption.length !== 0) {
      userListUpdate(getUserOptionsList.data);
    }
  }, [initialData.preparer]);
  /* ROLE Preparer, Reviewer, FileManager, Partner, Assistant administrative Option */

  /////////////////////// Questionnaire  //////////////////////
  // display questionnaire create button
  const [displayQuest, setDisplayQuest] = useState(false);
  const [questionnaireData, setQuestionnaireData] = useState([]);
  const [questionnaireModal, setQuestionnaireModal] = useState(false);
  const [responsiblePerson, setResponsiblePerson] = useState("");
  const [questionnaireIDs, setQuestionnaireIDs] = useState();

  const displayButton = async (taskId: any) => {
    const user_id =
      initialData.company == "0" ? initialData.contact_person : initialData.responsible_person;
    const res = await questionnaireService.DisplayQuestionButton(taskId, user_id);
    if (res?.data?.status === "success") {
      if (res?.data?.data[0]?.id) {
        setDisplayQuest(true);
        setQuestionnaireData(res?.data?.data);
      } else {
        setDisplayQuest(false);
      }
    } else {
      setDisplayQuest(false);
    }
  };

  useEffect(() => {
    if (initialData.type) {
      let user_id = "";
      if (initialData.company == "0") {
        user_id = initialData.contact_person;
      } else {
        user_id = initialData.responsible_person;
      }
      if (user_id) {
        questionerAccess.CAN_VIEW && displayButton(initialData.type);
      }
    }
  }, [initialData.type, responsiblePerson]);

  const onClickQuestionnaire = (e: any) => {
    e.preventDefault();
    if (initialData.responsible_person == "" && initialData.company != "0") {
      toast(t("Please select the person concerned name"), {
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        delay: 500,
      });
    } else if (initialData.contact_person == "" && initialData.company == "0") {
      toast(t("Please select the contact person name"), {
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        delay: 500,
      });
    } else {
      setQuestionnaireModal(true);
    }
  };

  const onYesClick = async (IDs: any) => {
    setQuestionnaireIDs(IDs);
    setQuestionnaireModal(false);
  };
  const onNoClick = () => {
    setQuestionnaireModal(false);
  };

  useEffect(() => {
    if (questionnaireIDs) {
      questionnaireToast.current = toast(t("Sending Questionnaires..."), {
        autoClose: false,
        isLoading: true,
      });
      const userID =
        initialData.company == "0" ? initialData.contact_person : initialData.responsible_person;
      dispatch(
        QuestionnaireAction.sendQuestionnaireFromTask({
          questionnaireIDs,
          userID,
          insertId: id,
        })
      );
    }
  }, [questionnaireIDs]);

  useEffect(() => {
    if (questionnaireSuccessMsg) {
      get();
      toast.update(questionnaireToast.current, {
        type: toast.TYPE.SUCCESS,
        render: questionnaireSuccessMsg,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
  }, [questionnaireSuccessMsg]);

  useEffect(() => {
    if (questionnaireErrorMsg) {
      toast.update(questionnaireToast.current, {
        type: toast.TYPE.ERROR,
        render: questionnaireErrorMsg,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
  }, [questionnaireErrorMsg]);

  /////////////////////// Questionnaire  //////////////////////

  // Sensitive Task
  //  useEffect(() => {
  //   if (getUserOptionsList.data) {
  //     userListUpdate(getUserOptionsList.data);
  //     if (initialData.sensitive === 1) {
  //       setInitialData(preState => ({
  //         ...preState,
  //         reviewer: "",
  //         file_manager: "",
  //         contact_person: "",
  //         preparer: "",
  //         type:"",
  //         assistant_administrative: "",
  //         is_assistant_administrative: 0,
  //       }));
  //       setFeesAllTypeOption(fee_type_initialData);
  //     } else {
  //       setFeesAllTypeOption(fee_type_initialData);
  //       setInitialData(preState => ({
  //         ...preState,
  //         reviewer: "",
  //         type:"",
  //         file_manager: "",
  //         is_assistant_administrative: isAssistantAdministrative ? 1 : 0,
  //       }))
  //     }
  //     setloadingPreparer(false);
  //   }
  // }, [initialData.sensitive])

  /* Company Master and User */
  // const [loadingCompanyOptions, setLoadingCompanyOptions] = useState(true);
  const [loadingCompanyUserOption, setloadingCompanyUserOption] = useState(true);
  const [companyOptions, setCompanyOptions] = useState([]);
  // const [companyID, setCompanyID] = useState<any>(null);
  const [companyLanguage, setCompanyLanguage] = useState<any>(null);
  useEffect(() => {
    if (getCompanyOptionsList.data) {
      const item = addIndividualInCompany(getCompanyOptionsList.data) as any;
      setCompanyOptions(item);
      // setLoadingCompanyOptions(false);
      // setloadingCompanyUserOption(true);
    }
  }, [getCompanyOptionsList]);

  const [disabledResponsiblePersonOption, setDisabledResponsiblePersonOption] = useState(true);
  const [responsiblePersonOption, setResponsiblePersonOption] = useState([]);
  const [disabledContactPersonOption, setDisabledContactPersonOption] = useState(true);
  const [contactPersonOption, setContactPersonOption] = useState([]);
  const [projectOption, setProjectOption] = useState([]);

  useEffect(() => {
    if (companyOptions.length > 0 && parseInt(initialData.company) > 0) {
      const companyData = companyOptions.find(
        (option: any) => option.value == initialData.company
      ) as any;
      if (companyData) {
        setCompanyLanguage(
          companyData?.language === 1
            ? "EN"
            : companyData?.language === 2
              ? "FR"
              : companyData?.language === 3
                ? "NL"
                : null
        );
      }
    }

    if (initialData.company || initialData.company == "0") {
      setloadingCompanyUserOption(true);

      setResponsiblePersonOption([]);
      setContactPersonOption([]);

      getDependentSelectOptions(
        "tbl_projects",
        "name",
        "company_id",
        parseInt(initialData.company)
      ).then(function (options) {
        setProjectOption(options);
      });

      getDependentSelectOptions(
        "tbl_master_users",
        "first_name",
        "company",
        parseInt(initialData.company)
      ).then(function (options) {
        if (feesAllTypeOption?.is_resident === 1) {
          let items = filteredByStatus(options, 1) as any;
          setResponsiblePersonOption(items);
        } else if (feesAllTypeOption?.is_non_resident === 1) {
          let items = filteredByStatus(options, 2) as any;
          setResponsiblePersonOption(items);
        } else {
          setResponsiblePersonOption(options);
        }

        const responsible_person = options.find(
          (opt: any) => opt.value === initialData.responsible_person
        );
        if (responsible_person) {
          setResponsiblePerson(responsible_person.label);
        }

        // Contact person ------------------------------------------
        if (parseInt(initialData.company) === 0) {
          setContactPersonOption(options);
          const contact_person = options.find(
            (opt: any) => opt.value === initialData.contact_person
          );
          if (contact_person) {
            setResponsiblePerson(contact_person.label);
          }
        } else {
          let contactFilterArray = Array();
          contactFilterArray.push(parseInt(initialData?.contact_person_id));
          contactFilterArray.push(parseInt(initialData?.invoice_contact_person_id));
          contactFilterArray.push(parseInt(initialData?.hr_contact_person_id));

          let contact_persons = filteredOptions(options as any, contactFilterArray) as any;
          contact_persons.push({ value: "", label: "-- Remove --" });
          setContactPersonOption(contact_persons);
        }
        // Contact person ------------------------------------------

        setloadingCompanyUserOption(false);

        if (
          (userProfile.USER_FUNCTION === 6 ||
            userProfile.USER_ID === parseInt(initialData.created_by)) &&
          parseInt(initialData.status) < 6
        ) {
          setDisabledContactPersonOption(false);
          setDisabledResponsiblePersonOption(false);
        }

        setLoader(false);
      });
    }
  }, [
    companyOptions,
    initialData.company,
    feesAllTypeOption?.is_resident,
    feesAllTypeOption?.is_non_resident,
  ]);

  const [contactPersonLanguage, setContactPersonLanguage] = useState<any>(null);
  useEffect(() => {
    if (parseInt(initialData.company) === 0 && initialData.contact_person) {
      const contactPersonData = contactPersonOption.find(
        (option: any) => option.value == initialData.contact_person
      ) as any;
      if (contactPersonData) {
        setContactPersonLanguage(
          contactPersonData?.language === 1
            ? "EN"
            : contactPersonData?.language === 2
              ? "FR"
              : contactPersonData?.language === 3
                ? "NL"
                : null
        );
      }
    } else {
      setContactPersonLanguage(null);
    }
  }, [contactPersonOption, initialData.company, initialData.contact_person]);

  /* End Company Master and User */

  useEffect(() => {
    getSelectOptions("tbl_master_tax_return_type", "tax_return_type_value").then(function (
      options
    ) {
      setTaxReturnTypeOption(options);
    });
  }, []);

  // Time Spent Model ----------------------------------------------------
  const [timeSpent, setTimeSpent] = useState(false);
  const [timeSpentData, setTimeSpentData] = useState<any>({});
  const [newTimeSpentFormModal, setNewTimeSpentFormModal] = useState(false);
  const onClickTimeSpent = () => {
    setTimeSpentData({
      is_new: false,
      module_id: 2, // Task
      module_main_id: id,
      module_sub_id: task_id,
      main_id: id,
      title: initialData.title,
      company: initialData.company,
      project: initialData.project,
      contact_person: initialData.contact_person,
      responsible_person: initialData.responsible_person,
      fees_type: initialData.type,
      purchase_order_number: initialData.purchase_order_number,
      fees_amount: initialData.fees_amount,
      expense: inputExpence, // initialData.expense,
      is_sensitive: initialData.sensitive,

      start_date: "",
      start_time: "",
      end_time: "",
      time_spent: "",
    });
    setNewTimeSpentFormModal(true);
  };

  const handleSubmitTimeSpent = (values: any) => {
    setNewTimeSpentFormModal(false);
    createTimeSheet(values);
  };

  const createTimeSheet = async (values: any) => {
    try {
      timesheetToast.current = toast(`${t("Creating")} ${t("timesheet")}...`, {
        autoClose: false,
        isLoading: true,
      });

      const res = (await timeSheetsService.createTimeSheetForm(values)) as any;
      if (res.data.status === "success") {
        toast.update(timesheetToast.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoading(false);
        setTimeSpent(true);

        if (isSubmit === true) {
          validation.handleSubmit();
        }
      } else {
        toast.update(timesheetToast.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        if (res.data.data) {
          Object.keys(res.data.data).map((key) => {
            toast(res.data.data[key][0], {
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              delay: 500,
            });
          });
        }
        setLoading(false);
      }
    } catch (err: any) {
      toast.update(timesheetToast.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }
  };
  // Time Spent Model ----------------------------------------------------

  // Sub Task List Model
  /**
   * TODO
   * SUB LIST DISPLAY WHEN USER UPDATE TASK SATUS SEND AND SUBTASK STATUS != SEND
   */
  const [subTaskListData, setSubTaskListData] = useState<any>({});
  const [isSubTaskList, setIsSubTaskList] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    if (initialData.status == "6") {
      if (initialData.file_manager === userProfile.USER_ID) {
        setIsEdit(false);
      } else {
        setIsEdit(true);
      }
    } else {
      setIsEdit(false);
    }
  }, [userProfile.USER_ID]);

  /**
   * change multiple sub task status SEND Or CANCEL
   */

  const updateMultipleTaskStatus = async (filterData: any) => {
    const updateSubTaskList = (await SubTasksService.updateMultipleSubTaskStatus({
      data: filterData,
      status: subTaskListData.update_status,
    })) as any;

    if ((updateSubTaskList.data.status = "success")) {
      toast.success("Subtask successfully updated");
      setIsSubTaskList(false);
    }
  };

  //expence pre expence and total expence field added
  const [preExpence, setPreExpence] = useState(0);
  const [inputExpence, setInputExpence] = useState(0);
  const expenceCalc = (val: number) => {
    if (isNaN(val) !== true) {
      const totalExpence = preExpence + val;
      // handleChange("expense", totalExpence);

      setInitialData((prevData: any) => ({
        ...prevData,
        expense: totalExpence,
      }));
    } else {
      // handleChange("expense", preExpence);

      setInitialData((prevData: any) => ({
        ...prevData,
        expense: preExpence,
      }));
    }
  };

  //Questionnaires
  const [questionnairesID, setQuestionnairesID] = useState<number>(0);
  const [questionnairesData, setQuestionnairesData] = useState<any>({});
  const [workDayLink, setWorkDayLink] = useState<any>("");

  //HandleSubmit
  const handlerSubmit = (button_code: string, fu: string) => {
    if (initialData.need_more_info == 1) {
      const messg = t(
        `${t("The Task/Subtask is blocked by")} ${initialData.tagging_user_name}. ${t(
          "The status cannot be changed until flagged OFF."
        )}`
      );
      toast.dismiss();
      toast.warn(messg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (questionnairesData && Object.keys(questionnairesData).length !== 0) {
      if (
        questionnairesData.status_id !== 6 &&
        questionnairesData.status_id !== 5 &&
        initialData.status === "6"
      ) {
        const messg = t(
          `${t(
            "Task/Subtask status cannot be changed to sent as Questionnaire status is not yet finalized."
          )}`
        );
        toast.dismiss();
        toast.warn(messg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      } else if (questionnairesData.status_id !== 9 && initialData.status === "7") {
        const messg = t(
          `${t(
            "You cannot cancel the Task/Subtask until the Questionnaire linked to this Task/Subtask is terminated."
          )}`
        );
        toast.dismiss();
        toast.warn(messg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      } else {
        // handleChange(button_code, fu);

        setInitialData((prevData: any) => ({
          ...prevData,
          button_code: fu,
        }));

        validation.handleSubmit();
      }
    } else {
      // handleChange(button_code, fu);

      setInitialData((prevData: any) => ({
        ...prevData,
        button_code: fu,
      }));

      validation.handleSubmit();
    }
  };

  const flagCheck = () => { };

  const handleSubtaskResponsible = () => {
    toast.dismiss();
    toast.warn(
      t(
        "Subtask cannot be created for this task, to create subtask, 'Person concerned' field in the task should be empty."
      ),
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
    return;
  };

  // Drawer for company and user

  const [openCompany, setOpenCompany] = useState<boolean>(false);
  const [openUser, setOpenUser] = useState<boolean>(false);
  const [company, setCompany] = useState<any>([]);
  const [selectedUser, setSelectedUser] = useState<any>([]);

  const handleCompanyClick = () => {
    if (initialData.company) {
      const company_id = getOptionValueById(
        companyOptions,
        parseInt(initialData.company),
        "company_id"
      );
      setCompany({ company: initialData.company, company_id: company_id });
      setOpenCompany(true);
    }
  };

  const handleUserClick = (type: number) => {
    let user_id = 0;
    switch (type) {
      case 0:
        user_id = getOptionValueById(preparerOption, parseInt(initialData.preparer), "user_id");
        setSelectedUser({ id: initialData.preparer, user_id: user_id });
        setOpenUser(true);
        break;

      case 1:
        user_id = getOptionValueById(reviewerOption, parseInt(initialData.reviewer), "user_id");
        setSelectedUser({ id: initialData.reviewer, user_id: user_id });
        setOpenUser(true);
        break;

      // File manager
      case 2:
        user_id = getOptionValueById(
          fileManagerOption,
          parseInt(initialData.file_manager),
          "user_id"
        );
        setSelectedUser({ id: initialData.file_manager, user_id: user_id });
        setOpenUser(true);
        break;

      case 3:
        user_id = getOptionValueById(partnerOption, parseInt(initialData.partner), "user_id");
        setSelectedUser({ id: initialData.partner, user_id: user_id });
        setOpenUser(true);
        break;

      case 4:
        user_id = getOptionValueById(
          assistantAdministrativeOption,
          parseInt(initialData.assistant_administrative),
          "user_id"
        );
        setSelectedUser({
          id: initialData.assistant_administrative,
          user_id: user_id,
        });
        setOpenUser(true);
        break;

      case 5:
        user_id = getOptionValueById(
          contactPersonOption,
          parseInt(initialData.contact_person),
          "user_id"
        );
        setSelectedUser({ id: initialData.contact_person, user_id: user_id });
        setOpenUser(true);
        break;

      case 6:
        user_id = getOptionValueById(
          responsiblePersonOption,
          parseInt(initialData.responsible_person),
          "user_id"
        );
        setSelectedUser({
          id: initialData.responsible_person,
          user_id: user_id,
        });
        setOpenUser(true);
        break;

      case 7:
        user_id = getOptionValueById(assigneeOption, parseInt(initialData.assignee), "user_id");
        setSelectedUser({
          id: initialData.assignee,
          user_id: user_id,
        });
        setOpenUser(true);
        break;
    }
  };

  const removeFlag = async () => {
    toast.dismiss();
    flagToast.current = toast(t("Updating flag..."), {
      autoClose: false,
      isLoading: true,
    });
    const postData = {
      id: initialData.id,
      task_id: initialData.task_id,
    };
    const res = await tasksService.removeFlag(postData);
    if (res.data.status === "success") {
      toast.update(flagToast.current, {
        type: toast.TYPE.SUCCESS,
        render: t("Flag removed successfully."),
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setInitialData((preState) => ({
        ...preState,
        need_more_info: 0,
      }));
    } else {
      toast.update(flagToast.current, {
        type: toast.TYPE.ERROR,
        render: t("Something went wrong."),
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
  };

  // Mail
  const composeMailFrom = () => {
    const filter = {
      module_app_id: 2, //module id from
      module_main_id: id || "", //primary key
      module_sub_id: task_id || "", //secodary key
      section_id: 0,
      file_path: "task",
      company: initialData.company,
    };
    const draftMail = {
      title: "Invoke from task",
      subject: initialData.title,
      filters: { task: filter, document: filter, comment: filter },
      openMailBox: 1,
    };
    dispatch(setDraftEmailData(draftMail));
  };

  const NewEmail = () => {
    dispatch(setDraftEmailData({ openMailBox: 1 }));
  };

  // Preliminary billing validation
  const [isPreliminarySubmit, setPreliminarySubmit] = useState<boolean>(false);
  useEffect(() => {
    if (initialData?.preliminary_details) {
      //@ts-ignore
      const tmp = initialData?.preliminary_details?.filter((a: any) => a.amount === "0" || a.date === "")
      setPreliminarySubmit(tmp.length > 0)
    }
  }, [initialData?.preliminary_details])

  // Preliminary billing validation


  return (
    <React.Fragment>
      {loader ? <Loader /> : null}
      <ScrollUp />
      <ScrollDown />

      <Alert
        open={autoSaveAlert}
        title={t("Autosaved data restored!")}
        message={t("Your last autosaved data has been restored from the session. In order to avoid the data loss, you need either save it to retain the data or Cancel to loose the data.")}
        onClickOk={() => setAutoSaveAlert(false)}
      />
      <NavigationAlert
        open={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />

      {newTimeSpentFormModal && (
        <NewTimeSheetFormModal
          show={newTimeSpentFormModal}
          loading={loading}
          timeSpentData={timeSpentData}
          onSubmitClick={handleSubmitTimeSpent}
          onCloseClick={() => setNewTimeSpentFormModal(false)}
        />
      )}

      {isSubTaskList && (
        <SubTaskListModal
          subTaskList={subTaskListData}
          show={isSubTaskList}
          onCloseClick={() => setIsSubTaskList(false)}
          onUpdateSubTaskStatus={(data: any) => updateMultipleTaskStatus(data)}
        />
      )}

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={`${t("Update")} ${t("task")}`}
            breadcrumbItems={
              userProfile.IS_INTERNAL ? breadcrumbItems : breadcrumbItemsExternalUser
            }
            endAdornment={
              <div className="d-flex gap-2 text-end">
                {initialData.need_more_info ? (
                  <Link
                    to="#"
                    className="btn btn-warning btn-label btn-sm"
                    onClick={() => removeFlag()}
                  >
                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                      flag
                    </span>
                    {t("Remove flag")}
                  </Link>
                ) : null}

                {userProfile.CAN_CREATE ? (
                  <Link to="/tasks/new-task" className="btn btn-outline-primary btn-label btn-sm">
                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                      add
                    </span>{" "}
                    {t("Create task")}
                  </Link>
                ) : null}

                {parseInt(taskStatus) < 6 &&
                  parseInt(initialData.company) !== 0 &&
                  subtaskAccess.CAN_CREATE && (
                    <>
                      {parseInt(initialData.responsible_person) > 0 ? (
                        <Link
                          to="#"
                          className="btn btn-primary btn-label btn-sm"
                          onClick={handleSubtaskResponsible}
                        >
                          <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                            add
                          </span>{" "}
                          {t("Create subtask")}
                        </Link>
                      ) : (
                        <Link
                          to={"/tasks/new-sub-task/" + id + "/" + task_id}
                          className="btn btn-primary btn-label btn-sm"
                        >
                          <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                            add
                          </span>{" "}
                          {t("Create subtask")}
                        </Link>
                      )}
                    </>
                  )}

                <EmailButton
                  t={t}
                  is_menu={true}
                  handelInvokefrom={() => composeMailFrom()}
                  handlerCompose={() => NewEmail()}
                />
              </div>
            }
          />

          <NavItems
            id={id}
            task_id={task_id}
            company={initialData.company}
            subtask_count={subtaskCount}
            invoice_count={invoiceCount}
            loading={loading}
          />

          <Row>
            <Col xl="12">
              <Card className="border-top-0 rounded-0 rounded-bottom">
                <fieldset disabled={isEdit}>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handlerSubmit("v", "k");
                      //validation.handleSubmit();
                      return false;
                    }}
                    autoComplete="off"
                  >
                    <Input id="id" name="id" type="hidden" value={initialData.id} />
                    <Input id="task_id" name="task_id" type="hidden" value={initialData.task_id} />
                    <Input id="code" name="code" type="hidden" value={initialData.code} />

                    <CardBody>
                      <Row>
                        {userProfile.USER_FUNCTION === 6 && (
                          <Col className="mb-3 d-flex align-items-end">
                            {isSensitiveTask === 1 && (
                              <div className="mb-1 center-block ">
                                <Label className="form-label mb-3"></Label>
                                <FormGroup switch inline>
                                  <Input
                                    type="switch"
                                    dis
                                    checked={true}
                                    onChange={(e) => {
                                      handleChange("sensitive", e.target.checked ? 1 : 0);
                                    }}
                                    disabled={true}
                                  />
                                  <Label check>{t("Sensitive")}</Label>
                                </FormGroup>
                              </div>
                            )}
                          </Col>
                        )}

                        <Col className="col-12"></Col>

                        {userProfile.IS_INTERNAL && (
                          <>
                            <Col className="col-12">
                              <div className="mb-3">
                                <Label className="form-label">
                                  {t("Task type")}{" "}
                                  <span className="text-warning is_required">*</span>
                                </Label>

                                <Select
                                  id="type"
                                  name="type"
                                  className="is-invalid"
                                  placeholder={t("Select...")}
                                  options={typeOption}
                                  isDisabled={
                                    initialData.timesheet_count > 0 ? true : disabledTypeOption
                                  }
                                  isLoading={loadingTypeOption}
                                  value={
                                    initialData.type
                                      ? typeOption.find(
                                        (option: any) => option.value == initialData.type
                                      )
                                      : ""
                                  }
                                  isSearchable={true}
                                  onBlur={() => validation.setFieldTouched("type", true)}
                                  onChange={(e: any) => {
                                    setTypeID(e.value);
                                    handleChange("type", e.value);
                                  }}
                                />

                                {validation.touched.type && validation.errors.type ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.type}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>
                          </>
                        )}

                        {userProfile.IS_INTERNAL && (
                          <Col className="col-3">
                            <div className="mb-3">
                              <Label className="form-label">{t("Category of task")}</Label>
                              <Input
                                id="category_of_fees"
                                name="category_of_fees"
                                className="form-control"
                                value={feesAllTypeOption?.category_of_fees}
                                type="text"
                                readOnly
                              />
                            </div>
                          </Col>
                        )}

                        {userProfile.IS_INTERNAL && (
                          <Col className="col-3">
                            <div className="mb-3">
                              <Label className="form-label">{t("Task code")}</Label>
                              <Input
                                id="fees_code"
                                name="fees_code"
                                className="form-control"
                                type="text"
                                value={feesAllTypeOption?.fees_code}
                                readOnly
                              />
                            </div>
                          </Col>
                        )}

                        {userProfile?.CAN_VIEW_FINANCIAL ? (
                          <Col className="col-3">
                            <div className="mb-3">
                              <Label className="form-label">{t("Task amount")} </Label>
                              <Input
                                id="fees_amount"
                                name="fees_amount"
                                className="form-control"
                                type="number"
                                step=".01"
                                min={0}
                                value={initialData.fees_amount}
                                readOnly={
                                  (userProfile.USER_FUNCTION === 6 ||
                                    userProfile.USER_ID === parseInt(initialData.created_by)) &&
                                    parseInt(taskStatus) < 6
                                    ? false
                                    : true
                                }
                                onBlur={validation.handleBlur}
                                onFocus={handleFocus}
                                onChange={(e) => {
                                  handleChange("fees_amount", e.target.value);
                                }}
                                invalid={
                                  validation.touched.fees_amount && validation.errors.fees_amount
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.fees_amount && validation.errors.fees_amount ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.fees_amount}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        ) : null}

                        {/* {userProfile.IS_INTERNAL && (
                          <Col className="col-3">
                            <div className="mb-3">
                              <Label className="form-label">
                                {t("Task category")}{" "}
                              </Label>
                              <Input
                                id="category"
                                name="category"
                                className="form-control"
                                type="text"
                                value={feesAllTypeOption?.category}
                                readOnly
                              />
                            </div>
                          </Col>
                        )}

                        <Col className="col-12">
                          <hr></hr>
                        </Col> */}

                        {userProfile.IS_INTERNAL && (
                          <Col className="col-3">
                            <div className="mb-3">
                              <Label className="form-label">{t("Expense")}</Label>
                              <Input
                                id="expense"
                                name="expense"
                                className="form-control"
                                type="number"
                                step=".01"
                                min={0}
                                placeholder="Expense"
                                value={inputExpence}
                                readOnly={
                                  (userProfile.USER_FUNCTION === 6 ||
                                    userProfile.USER_ID === parseInt(initialData.created_by)) &&
                                    parseInt(taskStatus) < 6
                                    ? false
                                    : true
                                }
                                onBlur={validation.handleBlur}
                                onFocus={handleFocus}
                                onChange={(e) => {
                                  //handleChange("expense", e.target.value);
                                  expenceCalc(parseFloat(e.target.value));
                                  setInputExpence(parseFloat(e.target.value));
                                }}
                                invalid={
                                  validation.touched.expense && validation.errors.expense
                                    ? true
                                    : false
                                }
                              />
                            </div>
                            <ul className="list-inline mb-1">
                              <li className="text-muted list-inline-item me-3">
                                {t("Prev Expense")}: {formatCurrency(preExpence)}
                              </li>
                              <li className="text-muted list-inline-item me-3">
                                {t("Total")}: {formatCurrency(initialData.expense)}
                              </li>
                            </ul>
                          </Col>
                        )}

                        <Col className="col-12">
                          <hr></hr>
                        </Col>

                        <Col className="col-3">
                          <div className="mb-3">
                            <Label className="form-label">
                              {initialData.company &&
                                initialData.company !== "0" &&
                                companyAccess.CAN_VIEW_ALL ? (
                                <Link
                                  to="#"
                                  onClick={() => handleCompanyClick()}
                                  className="text-decoration-underline"
                                >
                                  {t(`Client`)}
                                </Link>
                              ) : (
                                t(`Client`)
                              )}{" "}
                              {companyLanguage && (
                                <span
                                  className="badge rounded-pill bg-primary"
                                  title={t("Client's language")}
                                >
                                  {companyLanguage}
                                </span>
                              )}
                              <span className="text-warning is_required">*</span>
                            </Label>
                            <Input
                              id="company_name"
                              name="company_name"
                              className="form-control"
                              type="text"
                              value={initialData.company_name}
                              readOnly={true}
                            />
                          </div>
                        </Col>

                        {userProfile.IS_INTERNAL && (
                          <>
                            <Col className="col-3">
                              <div className="mb-3">
                                <Label className="form-label">{t("Project")}</Label>
                                <Select
                                  id="project"
                                  name="project"
                                  className="is-invalid"
                                  placeholder={t("Select...")}
                                  options={projectOption}
                                  value={
                                    initialData.project
                                      ? projectOption.find(
                                        (option: any) => option.value == initialData.project
                                      )
                                      : ""
                                  }
                                  isSearchable={true}
                                  isLoading={loadingCompanyUserOption}
                                  isDisabled={
                                    disabledContactPersonOption || projectOption.length === 0
                                  }
                                  onBlur={() => validation.setFieldTouched("project", true)}
                                  onChange={(e: any) => {
                                    handleChange("project", e.value);
                                    handleChange("purchase_order_number", e.po_number);
                                    if (e.budget) {
                                      handleChange("is_budget", 1);
                                      handleChange("budget_amount", e.budget);
                                    }
                                  }}
                                />

                                {validation.touched.project && validation.errors.project ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.project}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col className="col-3">
                              <div className="mb-3">
                                <Label className="form-label">{t("Purchase order number")}</Label>
                                <Input
                                  id="purchase_order_number"
                                  name="purchase_order_number"
                                  placeholder={t("Purchase order number")}
                                  type="text"
                                  disabled={
                                    disabledContactPersonOption || projectOption.length === 0
                                  }
                                  maxLength={50}
                                  value={initialData.purchase_order_number}
                                  onBlur={validation.handleBlur}
                                  onChange={(e) =>
                                    handleChange("purchase_order_number", e.target.value)
                                  }
                                />
                              </div>
                            </Col>
                          </>
                        )}

                        {initialData.sensitive == 0 && (
                          <Col className="col-3">
                            <div className="mb-3">
                              <Label className="form-label">
                                {initialData.contact_person && userAccess.CAN_VIEW_ALL ? (
                                  <Link
                                    to="#"
                                    onClick={() => handleUserClick(5)}
                                    className="text-decoration-underline"
                                  >
                                    {t("Contact person")}
                                  </Link>
                                ) : (
                                  t("Contact person")
                                )}{" "}
                                {contactPersonLanguage && (
                                  <span
                                    className="badge rounded-pill bg-primary"
                                    title="Contact person's language"
                                  >
                                    {contactPersonLanguage}
                                  </span>
                                )}
                                <span className="text-warning is_required">*</span>
                              </Label>
                              <Select
                                id="contact_person"
                                name="contact_person"
                                className="is-invalid"
                                placeholder={t("Select...")}
                                options={contactPersonOption}
                                value={
                                  initialData.contact_person
                                    ? contactPersonOption.find(
                                      (option: any) => option.value == initialData.contact_person
                                    )
                                    : ""
                                }
                                isSearchable={true}
                                isLoading={loadingCompanyUserOption}
                                isDisabled={disabledContactPersonOption}
                                onBlur={() => validation.setFieldTouched("contact_person", true)}
                                onChange={(e: any) => {
                                  handleChange("contact_person", e.value);
                                  if (initialData.company == "0") {
                                    setResponsiblePerson(e.label);
                                  }
                                }}
                              />

                              {validation.touched.contact_person &&
                                validation.errors.contact_person ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.contact_person}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        )}

                        {initialData.sensitive == 0 && (
                          <Col className="col-3">
                            <div className="mb-3">
                              <Label className="form-label">
                                {initialData.responsible_person && userAccess.CAN_VIEW_ALL ? (
                                  <Link
                                    to="#"
                                    onClick={() => handleUserClick(6)}
                                    className="text-decoration-underline"
                                  >
                                    {t("Person concerned")}
                                  </Link>
                                ) : (
                                  t("Person concerned")
                                )}{" "}
                              </Label>
                              <Select
                                id="responsible_person"
                                name="responsible_person"
                                className="is-invalid"
                                placeholder={t("Select...")}
                                options={responsiblePersonOption}
                                value={
                                  initialData.responsible_person
                                    ? responsiblePersonOption.find(
                                      (option: any) =>
                                        option.value == initialData.responsible_person
                                    )
                                    : ""
                                }
                                isClearable={true}
                                isSearchable={true}
                                isLoading={loadingCompanyUserOption}
                                isDisabled={
                                  subtaskCount > 0
                                    ? true
                                    : parseInt(initialData.company) === 0
                                      ? true
                                      : disabledResponsiblePersonOption
                                }
                                onBlur={() =>
                                  validation.setFieldTouched("responsible_person", true)
                                }
                                onChange={(e: any) => {
                                  if (e) {
                                    handleChange("responsible_person", e.value);
                                    if (initialData.company != "0") {
                                      setResponsiblePerson(e.label);
                                    }
                                  } else {
                                    handleChange("responsible_person", "");
                                    setResponsiblePerson("");
                                  }
                                }}
                              />
                              {validation.touched.responsible_person &&
                                validation.errors.responsible_person ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.responsible_person}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        )}

                        <Col className="col-12"></Col>

                        {userProfile.IS_INTERNAL && (
                          <Col className="col-3">
                            <div className="mb-3">
                              <Label className="form-label">
                                {t("Task ID")} <span className="text-warning is_required">*</span>
                              </Label>
                              <Input
                                id="custome_code"
                                name="custome_code"
                                className="form-control"
                                placeholder="Task ID"
                                type="text"
                                value={initialData.custome_code}
                                readOnly={true}
                                onBlur={validation.handleBlur}
                                onChange={(e) => handleChange("custome_code", e.target.value)}
                                invalid={
                                  validation.touched.custome_code && validation.errors.custome_code
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.custome_code && validation.errors.custome_code ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.custome_code}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        )}

                        <Col className={userProfile.IS_INTERNAL ? "col-9" : "col-12"}>
                          <div className="mb-3">
                            <Label className="form-label">
                              {t("Task title")} <span className="text-warning is_required">*</span>
                            </Label>
                            <Input
                              id="title"
                              name="title"
                              placeholder="Task title"
                              type="text"
                              maxLength={200}
                              // autoFocus={true}
                              value={initialData.title}
                              readOnly={
                                (userProfile.USER_FUNCTION === 6 ||
                                  userProfile.USER_ID === parseInt(initialData.created_by)) &&
                                  parseInt(taskStatus) < 6
                                  ? false
                                  : true
                              }
                              onBlur={validation.handleBlur}
                              onChange={(e) => handleChange("title", e.target.value)}
                              invalid={
                                validation.touched.title && validation.errors.title ? true : false
                              }
                            />
                            {validation.touched.title && validation.errors.title ? (
                              <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        <Col className="col-12">
                          <hr></hr>
                        </Col>

                        {userProfile.IS_INTERNAL && (
                          <Col className="col-3">
                            <div className="mb-3">
                              <Label className="form-label">
                                {initialData.preparer && userAccess.CAN_VIEW_ALL ? (
                                  <Link
                                    to="#"
                                    onClick={() => handleUserClick(0)}
                                    className="text-decoration-underline"
                                  >
                                    {t("Preparer")}
                                  </Link>
                                ) : (
                                  t("Preparer")
                                )}{" "}
                                <span className="text-warning is_required">*</span>
                              </Label>
                              <Select
                                id="preparer"
                                name="preparer"
                                className="is-invalid"
                                placeholder={t("Select...")}
                                options={preparerOption}
                                value={
                                  initialData.preparer
                                    ? preparerOption.find(
                                      (option: any) => option.value == initialData.preparer
                                    )
                                    : ""
                                }
                                isSearchable={true}
                                isLoading={loadingPreparer}
                                isDisabled={initialData.sensitive === 1 ? true : disabledPreparer}
                                onBlur={() => validation.setFieldTouched("preparer", true)}
                                onChange={(e: any) => {
                                  handlePreparer(e.value);
                                  // handleChange("preparer", e.value);
                                }}
                              />
                              {validation.touched.preparer && validation.errors.preparer ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.preparer}
                                </FormFeedback>
                              ) : null}

                              <div>
                                <ProgressBar progress={initialData.preparer_progress} />
                              </div>
                            </div>
                          </Col>
                        )}

                        {initialData.sensitive === 0 && userProfile.IS_INTERNAL && (
                          <Col className="col-3">
                            <div className="mb-3">
                              <Label className="form-label">
                                {initialData.reviewer && userAccess.CAN_VIEW_ALL ? (
                                  <Link
                                    to="#"
                                    onClick={() => handleUserClick(1)}
                                    className="text-decoration-underline"
                                  >
                                    {t("Reviewer")}
                                  </Link>
                                ) : (
                                  t("Reviewer")
                                )}{" "}
                                <span className="text-warning is_required">*</span>
                              </Label>

                              <Select
                                id="reviewer"
                                name="reviewer"
                                className="is-invalid"
                                placeholder={t("Select...")}
                                options={reviewerOption}
                                value={
                                  initialData.reviewer
                                    ? reviewerOption.find(
                                      (option: any) => option.value == initialData.reviewer
                                    )
                                    : ""
                                }
                                isSearchable={true}
                                isDisabled={disabledReviewer}
                                onBlur={() => validation.setFieldTouched("reviewer", true)}
                                onChange={(e: any) => {
                                  handleChange("reviewer", e.value);
                                }}
                              />

                              {validation.touched.reviewer && validation.errors.reviewer ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.reviewer}
                                </FormFeedback>
                              ) : null}

                              <div>
                                <ProgressBar progress={initialData.reviewer_progress} />
                              </div>
                            </div>
                          </Col>
                        )}

                        {initialData.sensitive === 0 && (
                          <Col className="col-3">
                            <div className="mb-3">
                              <Label className="form-label">
                                {initialData.file_manager && userAccess.CAN_VIEW_ALL ? (
                                  <Link
                                    to="#"
                                    onClick={() => handleUserClick(2)}
                                    className="text-decoration-underline"
                                  >
                                    {userProfile.IS_INTERNAL
                                      ? t("Responsible to sign off")
                                      : t("File manager")}
                                  </Link>
                                ) : userProfile.IS_INTERNAL ? (
                                  t("Responsible to sign off")
                                ) : (
                                  t("File manager")
                                )}{" "}
                                <span className="text-warning is_required">*</span>
                              </Label>

                              <Select
                                id="file_manager"
                                name="file_manager"
                                className="is-invalid"
                                placeholder={t("Select...")}
                                options={fileManagerOption}
                                value={
                                  initialData.file_manager
                                    ? fileManagerOption.find(
                                      (option: any) => option.value == initialData.file_manager
                                    )
                                    : ""
                                }
                                isSearchable={true}
                                isDisabled={disabledFileManager}
                                onBlur={() => validation.setFieldTouched("file_manager", true)}
                                onChange={(e: any) => {
                                  handleChange("file_manager", e.value);
                                }}
                              />

                              {validation.touched.file_manager && validation.errors.file_manager ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.file_manager}
                                </FormFeedback>
                              ) : null}

                              {userProfile.IS_INTERNAL && (
                                <div>
                                  <ProgressBar progress={initialData.file_manager_progress} />
                                </div>
                              )}
                            </div>
                          </Col>
                        )}

                        {userProfile.IS_INTERNAL && (
                          <Col className="col-3">
                            <div className="mb-3">
                              <Label className="form-label">
                                {initialData.partner && userAccess.CAN_VIEW_ALL ? (
                                  <Link
                                    to="#"
                                    onClick={() => handleUserClick(3)}
                                    className="text-decoration-underline"
                                  >
                                    {t("Partner")}
                                  </Link>
                                ) : (
                                  t("Partner")
                                )}{" "}
                                <span className="text-warning is_required">*</span>
                              </Label>

                              <Select
                                id="partner"
                                name="partner"
                                className="is-invalid"
                                placeholder={t("Select...")}
                                options={partnerOption}
                                value={
                                  initialData.partner
                                    ? partnerOption.find(
                                      (option: any) => option.value == initialData.partner
                                    )
                                    : ""
                                }
                                isSearchable={true}
                                isDisabled={disabledPartner}
                                onBlur={() => validation.setFieldTouched("partner", true)}
                                onChange={(e: any) => {
                                  handleChange("partner", e.value);
                                }}
                              />

                              {validation.touched.partner && validation.errors.partner ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.partner}
                                </FormFeedback>
                              ) : null}

                              <div>
                                <ProgressBar progress={initialData.partner_progress} />
                              </div>
                            </div>
                          </Col>
                        )}

                        {userProfile.IS_INTERNAL &&
                          isAssistantAdministrative &&
                          isSensitiveTask != 1 && (
                            <Col className="col-3">
                              <div className="mb-3">
                                <Label className="form-label">
                                  {initialData.assistant_administrative &&
                                    userAccess.CAN_VIEW_ALL ? (
                                    <Link
                                      to="#"
                                      onClick={() => handleUserClick(4)}
                                      className="text-decoration-underline"
                                    >
                                      {t("Assistant administrative")}
                                    </Link>
                                  ) : (
                                    t("Assistant administrative")
                                  )}{" "}
                                  <span className="text-warning is_required">*</span>
                                </Label>

                                <Select
                                  id="assistant_administrative"
                                  name="assistant_administrative"
                                  className="is-invalid"
                                  placeholder={t("Select...")}
                                  options={assistantAdministrativeOption}
                                  value={
                                    initialData.assistant_administrative
                                      ? assistantAdministrativeOption.find(
                                        (option: any) =>
                                          option.value == initialData.assistant_administrative
                                      )
                                      : ""
                                  }
                                  isSearchable={true}
                                  isDisabled={disabledAdministrative}
                                  onBlur={() =>
                                    validation.setFieldTouched("assistant_administrative", true)
                                  }
                                  onChange={(e: any) => {
                                    handleChange("assistant_administrative", e.value);
                                  }}
                                />

                                {validation.touched.assistant_administrative &&
                                  validation.errors.assistant_administrative ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.assistant_administrative}
                                  </FormFeedback>
                                ) : null}

                                <div>
                                  <ProgressBar
                                    progress={initialData.assistant_administrative_progress}
                                  />
                                </div>
                              </div>
                            </Col>
                          )}

                        <Col className="col-12">
                          <hr></hr>
                        </Col>

                        <Col className="col-4">
                          <div className="mb-3">
                            <Label className="form-label">
                              {t("Start date")} <span className="text-warning is_required">*</span>
                            </Label>
                            <div className="input-group is-invalid">
                              {(userProfile.USER_FUNCTION === 6 ||
                                userProfile.USER_ID === parseInt(initialData.created_by)) &&
                                parseInt(taskStatus) < 6 ? (
                                <Flatpickr
                                  id="start_date"
                                  name="start_date"
                                  className="form-control d-block"
                                  type="text"
                                  value={initialData.start_date}
                                  placeholder="DD/MM/YYYY"
                                  options={{
                                    altInput: true,
                                    altFormat: "d/m/Y",
                                    dateFormat: "Y-m-d H:i:S",
                                    minDate: initialData.start_date,
                                    allowInput: true,
                                    onChange: function (selectedDates, dateStr, instance) {
                                      handleChange("start_date", dateStr);
                                    },
                                    onClose: function (selectedDates, dateStr, instance) {
                                      handleChange("start_date", dateStr);
                                    },
                                    locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                                  }}
                                />
                              ) : (
                                <Input
                                  id="start_date"
                                  name="start_date"
                                  className="form-control d-block"
                                  type="text"
                                  value={
                                    initialData.start_date
                                      ? moment(initialData.start_date).format("DD/MM/YYYY")
                                      : ""
                                  }
                                  placeholder="DD/MM/YYYY"
                                  disabled={true}
                                />
                              )}
                              <span className="input-group-text">
                                <span className="align-middle material-symbols-outlined fw-light fs-4">
                                  calendar_today
                                </span>
                              </span>
                            </div>
                            {validation.touched.start_date && validation.errors.start_date ? (
                              <FormFeedback type="invalid">
                                {validation.errors.start_date}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        {userProfile.IS_INTERNAL && (
                          <Col className="col-4">
                            <div className="mb-3">
                              <Label className="form-label">
                                {t("Internal due date")}{" "}
                                <span className="text-warning is_required">*</span>
                              </Label>
                              <div className="input-group is-invalid">
                                {(userProfile.USER_FUNCTION === 6 ||
                                  userProfile.USER_ID === parseInt(initialData.created_by)) &&
                                  parseInt(taskStatus) < 6 ? (
                                  <Flatpickr
                                    id="due_date"
                                    name="due_date"
                                    className="form-control d-block"
                                    type="text"
                                    value={initialData.due_date}
                                    placeholder="DD/MM/YYYY"
                                    options={{
                                      altInput: true,
                                      altFormat: "d/m/Y",
                                      dateFormat: "Y-m-d H:i:S",
                                      minDate: initialData.due_date,
                                      allowInput: true,
                                      onChange: function (selectedDates, dateStr, instance) {
                                        handleChange("due_date", dateStr);
                                      },
                                      onClose: function (selectedDates, dateStr, instance) {
                                        handleChange("due_date", dateStr);
                                      },
                                      locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                                    }}
                                  />
                                ) : (
                                  <Input
                                    id="due_date"
                                    name="due_date"
                                    className="form-control d-block"
                                    type="text"
                                    value={
                                      initialData.due_date
                                        ? moment(initialData.due_date).format("DD/MM/YYYY")
                                        : ""
                                    }
                                    placeholder="DD/MM/YYYY"
                                    disabled={true}
                                  />
                                )}
                                <span className="input-group-text">
                                  <span className="align-middle material-symbols-outlined fw-light fs-4">
                                    calendar_today
                                  </span>
                                </span>
                              </div>
                              {validation.touched.due_date && validation.errors.due_date ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.due_date}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        )}

                        <Col className="col-4">
                          <div className="mb-3">
                            <Label className="form-label">
                              {t("Due date")} <span className="text-warning is_required">*</span>
                            </Label>
                            <div className="input-group is-invalid">
                              {(userProfile.USER_FUNCTION === 6 ||
                                userProfile.USER_ID === parseInt(initialData.created_by)) &&
                                parseInt(taskStatus) < 6 ? (
                                <Flatpickr
                                  id="closing_date"
                                  name="closing_date"
                                  className="form-control d-block"
                                  type="text"
                                  value={initialData.closing_date}
                                  placeholder="DD/MM/YYYY"
                                  options={{
                                    altInput: true,
                                    altFormat: "d/m/Y",
                                    dateFormat: "Y-m-d H:i:S",
                                    minDate: initialData.closing_date,
                                    allowInput: true,
                                    onChange: function (selectedDates, dateStr, instance) {
                                      handleChange("closing_date", dateStr);
                                    },
                                    onClose: function (selectedDates, dateStr, instance) {
                                      handleChange("closing_date", dateStr);
                                    },
                                    locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                                  }}
                                />
                              ) : (
                                <Input
                                  id="closing_date"
                                  name="closing_date"
                                  className="form-control d-block"
                                  type="text"
                                  value={
                                    initialData.closing_date
                                      ? moment(initialData.closing_date).format("DD/MM/YYYY")
                                      : ""
                                  }
                                  placeholder="DD/MM/YYYY"
                                  disabled={true}
                                />
                              )}
                              <span className="input-group-text">
                                <span className="align-middle material-symbols-outlined fw-light fs-4">
                                  calendar_today
                                </span>
                              </span>
                            </div>
                            {validation.touched.closing_date && validation.errors.closing_date ? (
                              <FormFeedback type="invalid">
                                {validation.errors.closing_date}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        {userProfile.IS_INTERNAL && (
                          <Col className="col-4">
                            <div className="mb-3">
                              <Label className="form-label">
                                {t("Task priority")}{" "}
                                <span className="text-warning is_required">*</span>
                              </Label>
                              <Select
                                id="priority"
                                name="priority"
                                className="is-invalid"
                                placeholder={t("Select...")}
                                options={taskPriority}
                                value={
                                  initialData.priority
                                    ? taskPriority.find(
                                      (option: any) => option.value == initialData.priority
                                    )
                                    : ""
                                }
                                formatOptionLabel={function (data: any) {
                                  return (
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: data.label,
                                      }}
                                    />
                                  );
                                }}
                                isSearchable={true}
                                isDisabled={
                                  (userProfile.USER_FUNCTION === 6 ||
                                    userProfile.USER_ID === parseInt(initialData.created_by)) &&
                                    parseInt(taskStatus) < 6
                                    ? false
                                    : true
                                }
                                onBlur={() => validation.setFieldTouched("priority", true)}
                                onChange={(e: any) => {
                                  handleChange("priority", e.value);
                                }}
                              />
                              {validation.touched.priority && validation.errors.priority ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.priority}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        )}

                        <Col className="col-4">
                          <div className="mb-3">
                            <Label className="form-label">
                              {t("Task status")} <span className="text-warning is_required">*</span>
                            </Label>

                            <Select
                              id="status"
                              name="status"
                              className="is-invalid"
                              placeholder={t("Select...")}
                              options={filteredTaskStatusOption}
                              value={
                                initialData.status
                                  ? filteredTaskStatusOption.find(
                                    (option: any) => option.value == initialData.status
                                  )
                                  : ""
                              }
                              isSearchable={true}
                              // isLoading={loadingStatusOption}
                              isDisabled={disabledStatusOption}
                              onBlur={() => validation.setFieldTouched("status", true)}
                              onChange={(e: any) => {
                                handleChange("status", e.value);
                              }}
                            />
                            {validation.touched.status && validation.errors.status ? (
                              <FormFeedback type="invalid">{validation.errors.status}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>

                        {userProfile.IS_INTERNAL && parseInt(initialData.status) >= 5 && (
                          <Col className="col-4">
                            <div className="mb-3">
                              <Label className="form-label">
                                {initialData.assignee && userAccess.CAN_VIEW_ALL ? (
                                  <Link
                                    to="#"
                                    onClick={() => handleUserClick(7)}
                                    className="text-decoration-underline"
                                  >
                                    {t("Responsible person for sending")}
                                  </Link>
                                ) : (
                                  t("Responsible person for sending")
                                )}{" "}
                                <span className="text-warning is_required">*</span>
                              </Label>
                              <Select
                                id="assignee"
                                name="assignee"
                                className="is-invalid"
                                placeholder={t("Select...")}
                                options={assigneeOption}
                                value={
                                  initialData.assignee
                                    ? assigneeOption.find(
                                      (option: any) => option.value == initialData.assignee
                                    )
                                    : ""
                                }
                                isSearchable={true}
                                isDisabled={parseInt(initialData.status) !== 5}
                                onBlur={() => validation.setFieldTouched("assignee", true)}
                                onChange={(e: any) => {
                                  handleChange("assignee", e.value);
                                }}
                              />
                              {validation.errors.assignee ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.assignee}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        )}

                        <Col className="col-4">
                          <div className="mb-3 float-start w-100">
                            {userProfile.USER_ID === parseInt(initialData.preparer) &&
                              (parseInt(taskStatus) === 1 || parseInt(taskStatus) === 2) && (
                                <>
                                  <Label className="form-label">{t("Task progress")}</Label>
                                  <InputGroup className="float-start">
                                    <Input
                                      id="preparer_progress"
                                      name="preparer_progress"
                                      className={
                                        initialData.preparer_progress <= 25
                                          ? "form-control bg-danger"
                                          : initialData.preparer_progress > 25 &&
                                            initialData.preparer_progress <= 50
                                            ? "form-control bg-warning"
                                            : initialData.preparer_progress > 50 &&
                                              initialData.preparer_progress <= 75
                                              ? "form-control bg-primary"
                                              : initialData.preparer_progress > 75 &&
                                                initialData.preparer_progress <= 100
                                                ? "form-control bg-success"
                                                : "form-control"
                                      }
                                      type="range"
                                      min={0}
                                      max={100}
                                      step={5}
                                      value={initialData.preparer_progress}
                                      disabled={disabledStatusOption}
                                      onBlur={validation.handleBlur}
                                      onChange={(e) => {
                                        handleChange("preparer_progress", e.target.value);
                                      }}
                                      style={{
                                        padding: "18px",
                                        border: "1px solid #d6dbe1",
                                        borderRight: "none",
                                      }}
                                    />
                                    <div className="input-group-text">
                                      {initialData.preparer_progress} %
                                    </div>
                                  </InputGroup>
                                </>
                              )}

                            {userProfile.USER_ID === parseInt(initialData.reviewer) &&
                              parseInt(taskStatus) === 3 && (
                                <>
                                  <Label className="form-label">{t("Task progress")}</Label>
                                  <InputGroup className="float-start">
                                    <Input
                                      id="reviewer_progress"
                                      name="reviewer_progress"
                                      className={
                                        initialData.reviewer_progress <= 25
                                          ? "form-control bg-danger"
                                          : initialData.reviewer_progress > 25 &&
                                            initialData.reviewer_progress <= 50
                                            ? "form-control bg-warning"
                                            : initialData.reviewer_progress > 50 &&
                                              initialData.reviewer_progress <= 75
                                              ? "form-control bg-primary"
                                              : initialData.reviewer_progress > 75 &&
                                                initialData.reviewer_progress <= 100
                                                ? "form-control bg-success"
                                                : "form-control"
                                      }
                                      type="range"
                                      min={0}
                                      max={100}
                                      step={5}
                                      value={initialData.reviewer_progress}
                                      disabled={disabledStatusOption}
                                      onBlur={validation.handleBlur}
                                      onChange={(e) => {
                                        handleChange("reviewer_progress", e.target.value);
                                      }}
                                      style={{
                                        padding: "18px",
                                        border: "1px solid #d6dbe1",
                                        borderRight: "none",
                                      }}
                                    />
                                    <div className="input-group-text">
                                      {initialData.reviewer_progress} %
                                    </div>
                                  </InputGroup>
                                </>
                              )}

                            {userProfile.USER_ID === parseInt(initialData.file_manager) &&
                              parseInt(taskStatus) === 4 && (
                                <>
                                  <Label className="form-label">{t("Task progress")}</Label>
                                  <InputGroup className="float-start">
                                    <Input
                                      id="file_manager_progress"
                                      name="file_manager_progress"
                                      className={
                                        initialData.file_manager_progress <= 25
                                          ? "form-control bg-danger"
                                          : initialData.file_manager_progress > 25 &&
                                            initialData.file_manager_progress <= 50
                                            ? "form-control bg-warning"
                                            : initialData.file_manager_progress > 50 &&
                                              initialData.file_manager_progress <= 75
                                              ? "form-control bg-primary"
                                              : initialData.file_manager_progress > 75 &&
                                                initialData.file_manager_progress <= 100
                                                ? "form-control bg-success"
                                                : "form-control"
                                      }
                                      type="range"
                                      min={0}
                                      max={100}
                                      step={5}
                                      value={initialData.file_manager_progress}
                                      disabled={disabledStatusOption}
                                      onBlur={validation.handleBlur}
                                      onChange={(e) => {
                                        handleChange("file_manager_progress", e.target.value);
                                      }}
                                      style={{
                                        padding: "18px",
                                        border: "1px solid #d6dbe1",
                                        borderRight: "none",
                                      }}
                                    />
                                    <div className="input-group-text">
                                      {initialData.file_manager_progress} %
                                    </div>
                                  </InputGroup>
                                </>
                              )}

                            {userProfile.USER_ID === parseInt(initialData.partner) &&
                              parseInt(taskStatus) === 5 && (
                                <>
                                  <Label className="form-label">{t("Task progress")}</Label>
                                  <InputGroup className="float-start">
                                    <Input
                                      id="partner_progress"
                                      name="partner_progress"
                                      className={
                                        initialData.partner_progress <= 25
                                          ? "form-control bg-danger"
                                          : initialData.partner_progress > 25 &&
                                            initialData.partner_progress <= 50
                                            ? "form-control bg-warning"
                                            : initialData.partner_progress > 50 &&
                                              initialData.partner_progress <= 75
                                              ? "form-control bg-primary"
                                              : initialData.partner_progress > 75 &&
                                                initialData.partner_progress <= 100
                                                ? "form-control bg-success"
                                                : "form-control"
                                      }
                                      type="range"
                                      min={0}
                                      max={100}
                                      step={5}
                                      value={initialData.partner_progress}
                                      disabled={disabledStatusOption}
                                      onBlur={validation.handleBlur}
                                      onChange={(e) => {
                                        handleChange("partner_progress", e.target.value);
                                      }}
                                      style={{
                                        padding: "18px",
                                        border: "1px solid #d6dbe1",
                                        borderRight: "none",
                                      }}
                                    />
                                    <div className="input-group-text">
                                      {initialData.partner_progress} %
                                    </div>
                                  </InputGroup>
                                </>
                              )}

                            {userProfile.USER_ID ===
                              parseInt(initialData.assistant_administrative) && (
                                <>
                                  <Label className="form-label">{t("Task progress")}</Label>
                                  <InputGroup className="float-start">
                                    <Input
                                      id="assistant_administrative_progress"
                                      name="assistant_administrative_progress"
                                      className={
                                        initialData.assistant_administrative_progress <= 25
                                          ? "form-control bg-danger"
                                          : initialData.assistant_administrative_progress > 25 &&
                                            initialData.assistant_administrative_progress <= 50
                                            ? "form-control bg-warning"
                                            : initialData.assistant_administrative_progress > 50 &&
                                              initialData.assistant_administrative_progress <= 75
                                              ? "form-control bg-primary"
                                              : initialData.assistant_administrative_progress > 75 &&
                                                initialData.assistant_administrative_progress <= 100
                                                ? "form-control bg-success"
                                                : "form-control"
                                      }
                                      type="range"
                                      min={0}
                                      max={100}
                                      step={5}
                                      value={initialData.assistant_administrative_progress}
                                      disabled={disabledStatusOption}
                                      onBlur={validation.handleBlur}
                                      onChange={(e) => {
                                        handleChange(
                                          "assistant_administrative_progress",
                                          e.target.value
                                        );
                                      }}
                                      style={{
                                        padding: "18px",
                                        border: "1px solid #d6dbe1",
                                        borderRight: "none",
                                      }}
                                    />
                                    <div className="input-group-text">
                                      {initialData.assistant_administrative_progress} %
                                    </div>
                                  </InputGroup>
                                </>
                              )}
                          </div>
                        </Col>

                        <Col className="col-12"></Col>

                        {userProfile.IS_INTERNAL && isShowIncomeYear && (
                          <Col className="col-4">
                            <div className="mb-3">
                              <Label className="form-label">{t("Income year")} </Label>
                              <Select
                                id="income_year"
                                name="income_year"
                                className="is-invalid"
                                placeholder={t("Select...")}
                                options={incomeYearOption}
                                value={
                                  initialData.income_year
                                    ? incomeYearOption.find(
                                      (option: any) => option.value == initialData.income_year
                                    )
                                    : ""
                                }
                                isDisabled={
                                  (userProfile.USER_FUNCTION === 6 ||
                                    userProfile.USER_ID === parseInt(initialData.created_by)) &&
                                    parseInt(taskStatus) < 6
                                    ? false
                                    : true
                                }
                                isSearchable={true}
                                onBlur={() => validation.setFieldTouched("income_year", true)}
                                onChange={(e: any) => {
                                  handleChange("income_year", e.value);
                                }}
                              />
                            </div>
                          </Col>
                        )}

                        {userProfile.IS_INTERNAL ? (
                          <Col className="col-4 align-items-center">
                            <div className="form-check float-start mb-2">
                              <input
                                id="is_budget"
                                name="is_budget"
                                className="form-check-input"
                                type="checkbox"
                                value={initialData.is_budget}
                                checked={initialData.is_budget == 1 ? true : false}
                                disabled={
                                  (userProfile.USER_FUNCTION === 6 ||
                                    userProfile.USER_ID === parseInt(initialData.created_by)) &&
                                    parseInt(taskStatus) < 6 &&
                                    initialData.is_budget !== 1
                                    ? false
                                    : true
                                }
                                onBlur={validation.handleBlur}
                                onChange={handleBudget}
                              />
                              <label className="form-check-label" htmlFor="is_budget">
                                {t("Budget")}
                              </label>
                            </div>
                            <div className="input-group mb-3">
                              <Input
                                id="budget_amount"
                                name="budget_amount"
                                className="form-control"
                                type="number"
                                step=".01"
                                min={0}
                                value={initialData.budget_amount}
                                readOnly={initialData.is_budget && editBudgetAmount ? false : true}
                                onBlur={validation.handleBlur}
                                onFocus={handleFocus}
                                onChange={(e) => {
                                  handleChange("budget_amount", e.target.value);
                                }}
                                invalid={
                                  validation.touched.budget_amount &&
                                    validation.errors.budget_amount
                                    ? true
                                    : false
                                }
                              />
                              <button
                                type="button"
                                className="btn btn-outline-secondary btn-sm"
                                title="Edit"
                                disabled={
                                  (userProfile.USER_FUNCTION === 6 ||
                                    userProfile.USER_ID === parseInt(initialData.created_by)) &&
                                    parseInt(taskStatus) < 6 &&
                                    initialData.is_budget === 1
                                    ? false
                                    : true
                                }
                                onClick={() => setEditBudgetAmount(!editBudgetAmount)}
                                style={{ zIndex: "0" }}
                              >
                                <span className="align-middle material-symbols-outlined fw-light fs-5">
                                  {editBudgetAmount ? "close" : "edit"}
                                </span>
                              </button>
                            </div>
                            {initialData.is_budget && initialData.budget_amount > 0 ? (
                              <ul className="list-inline mb-3">
                                <li className="text-muted list-inline-item me-3">
                                  {t("Invoiced")}: {formatCurrency(invoicedTaskAmount)}
                                </li>
                                <li className="text-muted list-inline-item me-3">
                                  {t("Remaining")}:{" "}
                                  {formatCurrency(
                                    Number(initialData.budget_amount) - Number(invoicedTaskAmount)
                                  )}
                                </li>
                              </ul>
                            ) : null}
                          </Col>
                        ) : null}

                        {userProfile.IS_INTERNAL && isPreliminaryType && (
                          <Col className="col-4 align-items-center">
                            <div className="form-check float-start mb-2 w-100">
                              <input
                                id="preliminary_billing"
                                name="preliminary_billing"
                                className="form-check-input"
                                type="checkbox"
                                value={initialData.preliminary_billing}
                                disabled={initialData.automatic_trigger ? false : true}
                                checked={initialData.preliminary_billing == 1 ? true : false}
                                onBlur={validation.handleBlur}
                                onChange={handlePreliminaryBilling}
                              />
                              <label className="form-check-label" htmlFor="preliminary_billing">
                                {t("Preliminary billing")}{" "}
                                <Badge color="secondary" pill>
                                  {
                                    //@ts-ignore
                                    initialData?.preliminary_details?.length
                                  }
                                </Badge>
                              </label>

                              <FormGroup switch inline className="float-end me-0">
                                <Input
                                  id="is_preliminary_fixed_amount"
                                  type="switch"
                                  checked={initialData.is_preliminary_fixed_amount ? true : false}
                                  disabled={initialData.automatic_trigger ? false : true}
                                  onChange={(e) => {
                                    handleChange(
                                      "is_preliminary_fixed_amount",
                                      e.target.checked ? 1 : 0
                                    );
                                  }}
                                />
                                <Label check>{t("Fixed amount")}</Label>
                              </FormGroup>
                            </div>
                            <InputGroup className="float-start">
                              <Input
                                id="preliminary_percentage"
                                name="preliminary_percentage"
                                className="form-control"
                                type="number"
                                step=".01"
                                min={initialData.is_preliminary_fixed_amount ? 0 : 10}
                                max={initialData.is_preliminary_fixed_amount ? "" : 100}
                                //@ts-ignore
                                value={
                                  initialData?.preliminary_details
                                    ? //@ts-ignore
                                    initialData?.preliminary_details?.filter(
                                      (a: any) => a.status === 0
                                    )?.length > 0
                                      ? //@ts-ignore
                                      initialData?.preliminary_details?.filter(
                                        (a: any) => a.status === 0
                                      )[0]["amount"]
                                      : 0
                                    : ""
                                }
                                readOnly={initialData.automatic_trigger ? false : true}
                                onFocus={handleFocus}
                                onBlur={validation.handleBlur}
                                onChange={(e) => {
                                  handleChange("preliminary_percentage", e.target.value);
                                }}
                                invalid={
                                  validation.touched.preliminary_percentage &&
                                    validation.errors.preliminary_percentage
                                    ? true
                                    : false
                                }
                              />
                              <div className="input-group-text">
                                {initialData.is_preliminary_fixed_amount ? (
                                  <span className="align-middle material-symbols-outlined fw-light fs-5">
                                    euro
                                  </span>
                                ) : (
                                  <span className="align-middle material-symbols-outlined fw-light fs-5">
                                    percent
                                  </span>
                                )}
                              </div>
                              <Button
                                type="button"
                                onClick={() => setOpenPreliminaryModal(true)}
                                disabled={
                                  initialData.preliminary_billing === 0 ||
                                  initialData.preliminary_details === null
                                }
                              >
                                {
                                  //@ts-ignore
                                  t(initialData?.preliminary_details ? "Show all" : "Add more")
                                }
                              </Button>
                            </InputGroup>
                          </Col>
                        )}

                        {isTaxReturnType && (
                          <Col className="col-4">
                            <div className="mb-3">
                              <Label className="form-label">{t("Tax return type")} </Label>
                              <Select
                                id="tax_return_type_id"
                                name="tax_return_type_id"
                                className="is-invalid"
                                placeholder={t("Select...")}
                                options={taxReturnTypeOption}
                                value={
                                  initialData.tax_return_type_id
                                    ? taxReturnTypeOption.find(
                                      (option: any) =>
                                        option.value == initialData.tax_return_type_id
                                    )
                                    : ""
                                }
                                isSearchable={false}
                                onChange={(e: any) => {
                                  handleChange("tax_return_type_id", e.value);
                                }}
                              />
                            </div>
                          </Col>
                        )}

                        <Col className="col-12">
                          <hr></hr>
                        </Col>

                        <Col className="col-12">
                          <div className="mb-3">
                            <Label className="form-label">{t("Comments")}</Label>
                            <MultiComments
                              moduleData={{
                                module_app_id: 2,
                                module_main_id: id,
                                module_sub_id: task_id,
                                section_id: 0,
                              }}
                              commentForIDs={commentAttachmentIDs}
                              fetchData={true}
                              isOpen={false}
                              isDisabled={parseInt(initialData.status) < 6 ? false : true}
                            />
                          </div>
                        </Col>

                        <Col className="col-12">
                          <hr></hr>
                        </Col>

                        <Col className="col-12">
                          <div className="mb-3">
                            <Label className="form-label">{t("Attachments")}</Label>
                            <Dropzone
                              moduleData={{
                                module_app_id: 2,
                                module_main_id: id,
                                module_sub_id: task_id,
                                section_id: 0,
                                file_path: "task",
                              }}
                              attachmentForIDs={commentAttachmentIDs}
                              fetchData={true}
                              isOpen={false}
                              isDisabled={parseInt(initialData.status) < 6 ? false : true}
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>

                    {questionnairesID !== 0 &&
                      questionnairesData?.questionnaire_id &&
                      displayQuest && (
                        <CardFooter className="bg-transparent border-top">
                          <Row className="mt-3">
                            <Col>
                              <QuestionnairesList id={questionnairesID} data={questionnairesData} />
                            </Col>
                          </Row>
                        </CardFooter>
                      )}

                    {workDayLink !== "" && workDayLink != 0 && workDayLink.length > 0 && (
                      <CardFooter className="bg-transparent border-top">
                        <Row className="mt-3">
                          <Col>
                            <Link
                              target="_blank"
                              to={`/users/workday-calender/${workDayLink}`}
                              className="btn btn-primary btn-label btn-sm"
                            >
                              <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                edit
                              </span>
                              {t("Work Day calender of the user")}
                            </Link>
                          </Col>
                        </Row>
                      </CardFooter>
                    )}

                    <CardFooter className="bg-transparent border-top">
                      {userProfile.IS_INTERNAL && (
                        <Row>
                          <Col className="col-12 mt-2 mb-3">
                            {initialData &&
                              initialData.created_by_first_name &&
                              initialData.created_by_last_name ? (
                              <div>
                                <ul className="list-inline mb-1">
                                  <li className="list-inline-item me-3">
                                    <span className="text-muted">
                                      <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                        person
                                      </span>
                                      {t("Created by")} {initialData.created_by_first_name}{" "}
                                      {initialData.created_by_last_name}
                                    </span>
                                  </li>
                                  <li className="list-inline-item me-3">
                                    <span className="text-muted">
                                      <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                        calendar_today
                                      </span>
                                      {dateFormat(initialData.created_at)}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            ) : null}

                            {initialData &&
                              initialData.updated_by_first_name &&
                              initialData.updated_by_last_name ? (
                              <div>
                                <ul className="list-inline">
                                  <li className="list-inline-item me-3">
                                    <span className="text-muted">
                                      <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                        person
                                      </span>
                                      {t("Last modified by")} {initialData.updated_by_first_name}{" "}
                                      {initialData.updated_by_last_name}
                                    </span>
                                  </li>
                                  <li className="list-inline-item me-3">
                                    <span className="text-muted">
                                      <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                        calendar_today
                                      </span>
                                      {dateFormat(initialData.updated_at)}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                      )}

                      <div className="d-sm-flex flex-wrap ">
                        <div className="d-flex gap-2">
                          {userProfile.CAN_DELETE && (parseInt(initialData.status) === 7 || parseInt(initialData.status) === 9) ? (
                            <Button
                              color="success"
                              className="btn-block btn-label"
                              type="button"
                              onClick={handleArchive}
                            >
                              {initialData.is_archive == 0 ? (
                                <>
                                  <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                    archive
                                  </span>
                                  {t("Archive")}
                                </>
                              ) : (
                                <>
                                  <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                    unarchive
                                  </span>
                                  {t("Unarchive")}
                                </>
                              )}
                            </Button>
                          ) : null}

                          {/* -- Remove ---------------------- */}
                          {/* {parseInt(initialData.status) <= 6 && initialData.is_archive == 1 ? (
                            <Button
                              color="success"
                              className="btn-block btn-label"
                              type="button"
                              onClick={handleArchive}
                            >
                              <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                unarchive
                              </span>
                              {t("Unarchive")}
                            </Button>
                          ) : null} */}
                          {/* -- Remove ---------------------- */}

                          {userProfile.CAN_CREATE &&
                            userProfile.IS_INTERNAL &&
                            parseInt(initialData.status) < 6 && (
                              <>
                                {timesheetProfile.CAN_CREATE && (
                                  <Button
                                    color="success"
                                    className="btn-block"
                                    type="button"
                                    onClick={onClickTimeSpent}
                                    disabled={loading}
                                  >
                                    {loading ? (
                                      <span>
                                        <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                                          hourglass_empty
                                        </span>
                                        <span>{t("Please wait...")}</span>
                                      </span>
                                    ) : (
                                      <span>{`${t("Update")} ${t("time spent")}`}</span>
                                    )}
                                  </Button>
                                )}

                                {displayQuest && (
                                  <Button
                                    color="primary"
                                    className="btn-block"
                                    type="submit"
                                    onClick={(e: any) => onClickQuestionnaire(e)}
                                    disabled={loading || questionnairesID !== 0}
                                  >
                                    {loading ? (
                                      <span>
                                        <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                                          hourglass_empty
                                        </span>
                                        <span>{t("Please wait...")}</span>
                                      </span>
                                    ) : (
                                      <span>{t("Questionnaire")}</span>
                                    )}
                                  </Button>
                                )}
                              </>
                            )}
                        </div>

                        <div className="ms-auto d-flex gap-2 justify-content-end">
                          <>
                            {parseInt(taskStatus) < 6 ? (
                              <>
                                {userProfile.IS_INTERNAL &&
                                  (userProfile.USER_ID === parseInt(initialData?.created_by) ||
                                    userProfile.USER_ID === parseInt(initialData?.preparer) ||
                                    userProfile.USER_ID === parseInt(initialData?.reviewer) ||
                                    userProfile.USER_ID === parseInt(initialData?.file_manager) ||
                                    userProfile.USER_ID === parseInt(initialData?.partner) ||
                                    userProfile.USER_ID ===
                                    parseInt(initialData?.assistant_administrative) ||
                                    userProfile.USER_ID === parseInt(initialData?.assignee) ||
                                    userProfile.CAN_EDIT_ALL) &&
                                  userProfile.CAN_CREATE && (
                                    <Button
                                      color="primary"
                                      className="btn-block"
                                      type="submit"
                                      onClick={() =>
                                        handleChange("submit_btn_code", "update_add_new")
                                      }
                                      disabled={loading}
                                    >
                                      {loading ? (
                                        <span>
                                          <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                                            hourglass_empty
                                          </span>
                                          <span>{t("Please wait...")}</span>
                                        </span>
                                      ) : (
                                        <span>{t("Update & Add new")}</span>
                                      )}
                                    </Button>
                                  )}

                                {userProfile.IS_INTERNAL &&
                                  (userProfile.USER_ID === parseInt(initialData?.created_by) ||
                                    userProfile.USER_ID === parseInt(initialData?.preparer) ||
                                    userProfile.USER_ID === parseInt(initialData?.reviewer) ||
                                    userProfile.USER_ID === parseInt(initialData?.file_manager) ||
                                    userProfile.USER_ID === parseInt(initialData?.partner) ||
                                    userProfile.USER_ID ===
                                    parseInt(initialData?.assistant_administrative) ||
                                    userProfile.USER_ID === parseInt(initialData?.assignee) ||
                                    userProfile.CAN_EDIT_ALL) && (
                                    <>
                                      <Button
                                        color="primary"
                                        className="btn-block"
                                        type="submit"
                                        onClick={() =>
                                          handleChange("submit_btn_code", "update_close")
                                        }
                                        disabled={loading}
                                      >
                                        {loading ? (
                                          <span>
                                            <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                                              hourglass_empty
                                            </span>
                                            <span>{t("Please wait...")}</span>
                                          </span>
                                        ) : (
                                          <span>{t("Update & Close")}</span>
                                        )}
                                      </Button>

                                      <Button
                                        color="primary"
                                        className="btn-block"
                                        type="submit"
                                        onClick={() => handleChange("submit_btn_code", "update")}
                                        disabled={loading}
                                      >
                                        {loading ? (
                                          <span>
                                            <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                                              hourglass_empty
                                            </span>
                                            <span>{t("Please wait...")}</span>
                                          </span>
                                        ) : (
                                          <span>{t("Update")}</span>
                                        )}
                                      </Button>
                                    </>
                                  )}

                                <Button
                                  color="danger"
                                  className="btn-block"
                                  type="reset"
                                  onClick={() => {
                                    handleRedirect(true);
                                  }}
                                  disabled={loading}
                                >
                                  {loading ? (
                                    <span>
                                      <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                                        hourglass_empty
                                      </span>
                                      <span>{t("Please wait...")}</span>
                                    </span>
                                  ) : (
                                    <span>{t("Cancel")}</span>
                                  )}
                                </Button>
                              </>
                            ) : null}
                          </>
                        </div>
                      </div>
                    </CardFooter>
                  </Form>
                </fieldset>
              </Card>
            </Col>
          </Row>
        </Container>
        <>
          <Drawer
            title={t("Client")}
            open={openCompany}
            onClose={() => setOpenCompany((state) => !state)}
            fullWidth
          >
            <EditCompany
              id={company?.company}
              company_id={company?.company_id}
              type={1}
              isWindow={true}
            />
          </Drawer>
          <Drawer
            title={t("User")}
            open={openUser}
            onClose={() => setOpenUser((state) => !state)}
            fullWidth
          >
            <EditUser
              id={selectedUser?.id}
              user_id={selectedUser?.user_id}
              type={1}
              isWindow={true}
            />
          </Drawer>
        </>
        <>
          {questionnaireModal && (
            <QuestionnaireModal
              questionnaireData={questionnaireData}
              responsiblePerson={responsiblePerson}
              show={questionnaireModal}
              onCloseClick={() => setQuestionnaireModal(false)}
              onYesClick={onYesClick}
              onNoClick={onNoClick}
            />
          )}
        </>
      </div>
      <Modal isOpen={openPreliminaryModal} onClosed={() => setOpenPreliminaryModal(false)}>
        <ModalBody>
          <PreliminaryForm
            initialData={initialData}
            handleChange={handleChange}
            setInitialData={setInitialData}
          />
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="primary" disabled={isPreliminarySubmit} onClick={() => setOpenPreliminaryModal(false)}>
            {t("Submit")}
          </Button>
          <Button
            type="button"
            color="danger"
            onClick={() => {
              handleChange("preliminary_details", taskData?.preliminary_details);
              setOpenPreliminaryModal(false);
            }}
          >
            {t("Cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default EditTask;
