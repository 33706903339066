import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, FormGroup, Input, InputGroup, Label, Row } from 'reactstrap';
import { cloneDeep } from "lodash";
import commonService from '../../services/common.service';
import CheckboxMultiInput from './multiinput/CheckboxMultiInput';
//import Dropzone from "../../components/Common/Dropzone";
import RadioInput from './RadioInput';
import { filterCountryByLanguage, getSelectOptions, onlyPositiveValue } from '../../helpers/functions_helper';
import Select from "react-select";

import './multiinput/styles.scss';
import DatePicker from '../../components/Form/DatePicker/DatePicker';


const MultiInput = (props: any) => {
  const { input_type, label, handleMultiInputChange, reference_name, user_data_answer, is_disabled, displayview, mode, multi_input_data, country_options } = props;
  const [formRows, setFormRows] = useState([{ id: 1 }]);
  const [countryOptions, setCountryOptions] = useState([]);

  useEffect(() => {
    getSelectOptions("tbl_master_country", "name", "", [
      "is_europian_country",
      "name",
      "name_french",
      "name_dutch",
    ]).then(function (options: any) {
      let countryOptionsList = filterCountryByLanguage(options);
      setCountryOptions(countryOptionsList);
    });
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  const d_view = typeof displayview == 'undefined' ? 1 : displayview;
  const rawData = useRef([]);
  function renderRowHTML(rowContent: any) {
    return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
  }
  function htmlDecode(input: any) {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }

  const [rowsinput, setrowsinput] = useState<any>({ data: [] });

  useEffect(() => {
    if (multi_input_data != null) {
      rawData.current = cloneDeep(multi_input_data);
      if (Array.isArray(user_data_answer) && user_data_answer?.length > 0) {
        setrowsinput({ data: user_data_answer });
      } else {
        setrowsinput({ data: [multi_input_data] });
      }
    }
  }, [multi_input_data])


  function handleAddRowQuestionNested() {
    let data = cloneDeep(rawData.current);
    setrowsinput((prevState: any) => ({ data: [...prevState.data, data] }))
  }

  function handleRemoveRowQuestion(formrow: any) {
    var modifiedRows: any = { ...rowsinput };
    modifiedRows.data.splice(formrow, 1);
    setrowsinput(modifiedRows);
    handleMultiInputChange(modifiedRows, reference_name);
  }

  function handleinput(e: any, type: any, key: any, masterkey: number) {
    var modifiedRows: any = { ...rowsinput };
    if (type.input_type === 16 && type.input_type === 3) {
      modifiedRows.data[masterkey][key].value = e.target.checked;
    }
    else if (type.input_type === 14) {
      modifiedRows.data[masterkey][key].value = e.value;
    } else if (type.input_type === 16) {
      modifiedRows.data[masterkey][key].value = e.target.checked;
    }
    else {
      modifiedRows.data[masterkey][key].value = e.target.value;
    }
    //setrowsinput(modifiedRows);
    handleMultiInputChange(modifiedRows, reference_name);
  }

  return (
    <Col xs={12}>
      <Label>
        {renderRowHTML(htmlDecode(label))}
      </Label>
      <div>
        <div className='position-relative'>
          {
            rowsinput['data']?.length > 0 &&
            (rowsinput['data'] || []).map((_item: any, indefx: number) => {
              return (
                <div key={indefx}>
                  <Row className='border border-primary mb-1 p-3 position-relative'>
                    {typeof _item == 'object' &&
                      (_item || []).map((formRow: any, key: number) =>
                      (

                        <Col sm={formRow.input_type == 3 || formRow.input_type == 6 || formRow.input_type == 2 || input_type == 21 ? 12 : 6} className={`mx_${formRow.input_type}`} key={key}>
                          {formRow.input_type == 1 &&
                            <FormGroup className={input_type == 21 ? 'd-flex flex-row border px-4 py-1 input_table' : ''}>
                              <Label for={formRow.reference} className={input_type == 21 ? 'w-75' : ''}>
                                {formRow.question}
                              </Label>
                              <Input
                                type="text"
                                bsSize="sm"
                                id="name"
                                name={formRow.reference}
                                className={input_type == 21 ? 'w-25 form-control' : 'form-control'}
                                value={rowsinput['data'][indefx][key].value}
                                placeholder={formRow.question}
                                onChange={e => {
                                  handleinput(e, formRow, key, indefx);
                                }}
                                disabled={is_disabled}
                              />
                            </FormGroup>
                          }

                          {formRow.input_type == 2 &&
                            <FormGroup className={input_type == 21 ? 'd-flex flex-row border px-4 py-1 input_table radio_field' : ''}>
                              <RadioInput
                                label={renderRowHTML(formRow.question)}
                                name={formRow.reference_name}
                                handleChangeMulti={(e: any) => handleinput(e, formRow, key, indefx)}
                                value={formRow.value ? formRow.value : ''}
                                isDisabled={is_disabled}
                              //answer_only={formRow.value}
                              />
                            </FormGroup>
                          }

                          {formRow.input_type == 3 &&
                            <FormGroup className={input_type == 21 ? 'd-flex flex-row border px-4 py-1 input_table' : ''}>
                              <InputGroup className="float-start">
                                <div className="mb-3" style={{ 'flex': '0 0 100%' }}>
                                  <CheckboxMultiInput
                                    label={renderRowHTML(htmlDecode(formRow.question))}
                                    options={typeof formRow.options == 'object' && Object.keys(formRow.options).map(k => formRow.options[k].value)}
                                    name={formRow?.reference_name}
                                    handleChangeMulticheckbox={(e: any) => handleinput(e, formRow, key, indefx)}
                                    value={formRow?.value ? formRow.value : ''}
                                    isMulti={false}
                                    //fullwidth={field_width}
                                    isDisabled={is_disabled}
                                    answer_only={is_disabled}
                                  />
                                </div>
                              </InputGroup>
                            </FormGroup>
                          }


                          {formRow.input_type == 4 &&
                            <FormGroup className={input_type == 21 ? 'd-flex flex-row border px-4 py-1 input_table' : ''}>
                              <Label for="exampleEmail">
                                {formRow.question}
                              </Label>
                              <InputGroup className="float-start">
                                <DatePicker
                                  id="marriage_date"
                                  name={formRow.reference}
                                  className="form-control d-block width-input"
                                  type="text"
                                  value={rowsinput['data'][indefx][key].value}
                                  onChange={(e) => {
                                    let xObject = { target: { value: e[0] } };
                                    handleinput(xObject, formRow, key, indefx)
                                  }
                                  }
                                  placeholder={formRow.question}
                                  options={{
                                    altInput: true,
                                    altFormat: "d/m/Y",
                                    dateFormat: "Y-m-d H:i:S",
                                    maxDate: "today",
                                    allowInput: true,
                                    locale: { firstDayOfWeek: 1 }
                                  }}
                                  disabled={is_disabled}
                                />
                                {/* <span className="input-group-text">
                                  <span className="align-middle material-symbols-outlined fw-light fs-4">calendar_today</span>
                                </span> */}
                              </InputGroup>

                            </FormGroup>
                          }
                          {
                            formRow.input_type == 5 &&
                            <FormGroup className={input_type == 21 ? 'd-flex flex-row border px-4 py-1 input_table' : ''}>
                              <Label>
                                {formRow.question}
                              </Label>
                              <InputGroup className="float-start">
                                <div className="mb-3" style={{ 'flex': '0 0 100%' }}>
                                  <Input
                                    type="select"
                                    className="form-control"
                                    style={{ 'minWidth': '100px' }}
                                    value={rowsinput['data'][indefx][key].value}
                                    onChange={(e) => handleinput(e, formRow, key, indefx)}
                                    disabled={is_disabled}
                                  >
                                    {typeof formRow.options == 'object' && formRow.options.map((_item: any, k: number) => <option value={_item.value} key={k}>{_item.label}</option>)}
                                  </Input>
                                </div>
                              </InputGroup>
                            </FormGroup>
                          }

                          {
                            formRow.input_type == 6 &&
                            <>

                              <CheckboxMultiInput
                                label={renderRowHTML(htmlDecode(formRow.question))}
                                options={typeof formRow.options == 'object' && Object.keys(formRow.options).map(k => formRow.options[k].value)}
                                name={formRow.reference_name}
                                handleChangeMulticheckbox={(e: any) => handleinput(e, formRow, key, indefx)}
                                value={formRow.value ? formRow.value : ''}
                                isMulti
                                //fullwidth={field_width}
                                isDisabled={is_disabled}
                              //answer_only={answerOnly} 
                              />
                            </>

                          }

                          {
                            formRow.input_type == 7 &&
                            <Row>
                              <p className='text-left text-primary'>{formRow.question ? renderRowHTML(formRow.question) : ""}</p>
                            </Row >
                          }
                          {
                            formRow.input_type == 8 &&
                            <Row>
                              <Col className="col-12">
                                <div className="mb-3">
                                  <Label className="form-label">{renderRowHTML(htmlDecode(formRow.question))}</Label>
                                  {/* <Dropzone
                                      moduleData={{
                                        module_app_id: 6,
                                        module_main_id: questionnaireID,
                                        module_sub_id: questionnaireSubID,
                                        section_id: section_id,
                                        file_path: "questionnaire",
                                      }}
                                      fetchData={true}
                                      isOpen={false}
                                      isDisabled={!adminview}
                                    /> */}
                                </div >
                              </Col >
                            </Row >
                          }
                          {
                            formRow.input_type == 10 &&
                            <Row>
                              <b><p className='text-left '>{renderRowHTML(htmlDecode(formRow.question))}</p></b>
                            </Row >
                          }
                          {
                            formRow.input_type == 11 &&
                            <FormGroup className={input_type == 21 ? 'd-flex flex-row border px-4 py-1 input_table' : ''}>
                              <Label for="exampleEmail">
                                {formRow.question}
                              </Label>
                              <InputGroup className="float-start">
                                <Input
                                  name={formRow.reference}
                                  className="form-control"
                                  type="number"
                                  bsSize="sm"
                                  step={"1"}
                                  value={rowsinput['data'][indefx][key].value}
                                  onChange={(e) => handleinput(e, formRow, key, indefx)}
                                  min={0}
                                  max={10000000000}
                                  placeholder={formRow.question}
                                  onKeyPress={(e) => onlyPositiveValue(e)}
                                  disabled={is_disabled}
                                />
                                <div className="input-group-text">€</div>
                              </InputGroup>
                            </FormGroup>
                          }
                          {formRow.input_type == 12 &&
                            <FormGroup className={input_type == 21 ? 'd-flex flex-row border px-4 py-1 input_table' : ''}>
                              <Label for="exampleEmail">
                                {formRow.question}
                              </Label>
                              <InputGroup className="float-start">
                                <Input
                                  name={formRow.reference}
                                  className="form-control"
                                  type="number"
                                  step={".01"}
                                  value={rowsinput['data'][indefx][key].value}
                                  onChange={(e) => handleinput(e, formRow, key, indefx)}
                                  min={0}
                                  max={100}
                                  placeholder={formRow.question}
                                  disabled={is_disabled}
                                />
                                <div className="input-group-text">€</div>
                              </InputGroup>
                            </FormGroup>
                          }

                          {
                            formRow.input_type == 13 &&
                            <div className={input_type == 21 ? 'd-flex flex-row border px-4 py-1 input_table' : 'mb-3'}>
                              <Label htmlFor="commentmessage-input">
                                {renderRowHTML(htmlDecode(formRow.question))}
                              </Label>
                              <textarea
                                className="form-control"
                                id="commentmessage-input"
                                placeholder={formRow.question}
                                name={formRow.reference_name}
                                onChange={(e) => handleinput(e, formRow, key, indefx)}
                                value={rowsinput['data'][indefx][key].value}
                                disabled={is_disabled}
                              ></textarea>
                            </div>
                          }

                          {
                            formRow.input_type == 14 &&
                            <div className="mb-3">
                              <Label className="form-label">
                                {renderRowHTML(htmlDecode(formRow.question))}
                              </Label>
                              <Select
                                id="country"
                                name={formRow.reference_name}
                                className="is-invalid"
                                options={countryOptions}
                                value={
                                  user_data_answer
                                    ? countryOptions.find(
                                      (option: any) =>
                                        option.value == rowsinput['data'][indefx][key].value
                                    )
                                    : ""
                                }
                                isSearchable={true}
                                onChange={(e: any) => {
                                  handleinput(e, formRow, key, indefx);
                                }}
                                isDisabled={is_disabled}
                              />
                            </div>
                          }
                          {
                            formRow.input_type == 15 &&
                            <Row>
                              <p className='text-left '>{renderRowHTML(htmlDecode(formRow.question))}</p>
                            </Row>
                          }
                          {
                            formRow.input_type == 16 &&
                            <Col lg={12}>
                              <div className={input_type == 21 ? 'd-flex flex-row border px-4 py-1 form-check form-check-primary input_table' : 'form-check form-check-primary mb-3'}>
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id={formRow.reference_name}
                                  name={formRow.reference_name}
                                  onChange={(e) => handleinput(e, formRow, key, indefx)}
                                  checked={rowsinput['data'][indefx][key].value ? true : false}
                                  disabled={is_disabled}
                                />

                                <label
                                  className="form-check-label"
                                  htmlFor={formRow.reference_name}
                                >
                                  {renderRowHTML(htmlDecode(formRow.question))}
                                </label>
                              </div>
                            </Col>
                          }

                          {
                            formRow.input_type == 17 &&
                            <>
                              <Col lg={12} className={input_type == 21 ? 'd-flex flex-row border px-4 py-1 input_table' : 'mb-3'}>
                                <Label htmlFor="commentmessage-input">
                                  Address
                                </Label>
                                <textarea
                                  className="form-control"
                                  id="commentmessage-input"
                                  name={`address_${formRow.reference_name}`}
                                  onChange={(e) => handleinput(e, formRow, key, indefx)}
                                  value={rowsinput['data'][indefx][key].value ? rowsinput['data'][indefx][key].value : ''}
                                  disabled={is_disabled}
                                ></textarea>
                              </Col>

                            </>
                          }
                          {
                            formRow.input_type == 18 &&
                            <Col lg={12}>
                              <Row className="mt-3">
                                <p className='text-left form-label'>{renderRowHTML(formRow.question)}</p>
                              </Row>
                            </Col>
                          }
                        </Col >
                      ))
                    }

                    {
                      indefx != 0 && <div className="position-absolute top-0" style={{ 'width': '25px', 'right': '18px' }}>
                        <Button
                          color="danger"
                          className="btn btn-primary btn-sm waves-light btn-close-question"
                          size='sm'
                          onClick={e => {
                            handleRemoveRowQuestion(indefx);
                          }}
                          disabled={is_disabled}
                        >
                          <i className="fas fa-close label-icon"></i>
                        </Button>
                      </div>
                    }
                  </Row >
                </div >
              )

            })
          }
          {!!(input_type != '21') ?
            <Button
              color="warning"
              className="btn btn-primary btn-sm waves-light mt-2 mb-2 ms-2 float-end"
              onClick={e => {
                handleAddRowQuestionNested()
              }}
              disabled={is_disabled}
            >Add more{` `}<span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">add</span>
            </Button>
            : null}
        </div >
      </div >
    </Col >

  )
}

export default MultiInput