import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    Form,
    Button,
    FormFeedback,
    Alert,
} from "reactstrap";
import Select from 'react-select';
import Flatpickr from "react-flatpickr";
import { useFormik } from "formik";
import * as Yup from "yup";
import commonService from "../../../services/common.service";
import { useDispatch, useSelector } from "react-redux";
import { CommonAction } from "../../../store/actions";
import vatcatService from "../../../services/masters.vatcat.service";
import { useTranslation } from "react-i18next";
import Input from "../../../components/Form/Input";

type VatCatType = {
    id?: number | null;
    vatcat_id?: string | null;
    vatcat_name: string | null;
};


const ModalForm = (props: any) => {
    const { t } = useTranslation();
    let vatData = props.vatData;
    const dispatch = useDispatch();

    const {
        getUserOptionsList,
        getCompanyOptionsList,
        loading
    } = useSelector((state: any) => ({
        getUserOptionsList: state.Common.GET_USER_OPTIONS_LIST_SUCCESS,
        getCompanyOptionsList: state.Common.GET_COMPANY_OPTIONS_LIST_SUCCESS,
        loading: state.Tasks.loading,
    }))

    const [intialData, setIntialData] = useState<VatCatType>({
        vatcat_name: null
    });

    const [accounts, setAccounts] = useState<Array<any>>([]);
    const [partnersOptions, setPartnerOptions] = useState<Array<any>>([]);
    const [companyOptions, setCompnayOptions] = useState<Array<any>>([]);
    const [contactPersonOption, setContactPersonOption] = useState<Array<any>>([]);
    const [query, setQuery] = useState("");

    const validationSchema = Yup.object().shape({
        vatcat_name: Yup.string().nullable().required("VAT Cat Name field is required."),
    });


    const handleChange = (fieldName: string, fieldValue: any) => {
        setIntialData({ ...intialData, [fieldName]: fieldValue });
    };

    const handleSubmit = (values: VatCatType, actions: any) => {
        props.onSubmitClick(values, actions);
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: intialData,
        validationSchema: validationSchema,
        onSubmit: (values, actions) => {
            handleSubmit(values, actions);
        },
    });

    const getVatCat = async (id: number) => {
        const res = await vatcatService.get(id);
        if (res.status === "success") {
            setIntialData(res.data);
        }
    }
    useEffect(() => {
        setIntialData(vatData);
    }, [vatData])

    useEffect(() => {
        setPartnerOptions(getUserOptionsList?.data?.filter((i: any) => i.function === 6))
    }, [getUserOptionsList])

    useEffect(() => {
        if (props.isTab) {
            setCompnayOptions(getCompanyOptionsList?.data?.filter((i: any) => i.value === props.isTab) || [])
        } else {
            setCompnayOptions(getCompanyOptionsList?.data || [])
        }

    }, [getCompanyOptionsList, props.isTab])

    useEffect(() => {
        // alert(JSON.stringify(validation))
        if (!validation.isSubmitting) return;
        if (Object.keys(validation.errors).length > 0) {
            document
                .getElementById(Object.keys(validation.errors)[0])
                ?.scrollIntoView({ behavior: "smooth", block: "center" });
            document
                .getElementById(Object.keys(validation.errors)[0])
                ?.focus({ preventScroll: true });
        }
    }, [validation.isSubmitting, validation.errors]);


    return (
        <React.Fragment>
            <Modal
                size="md"
                isOpen={props.show}
                toggle={props.onCloseClick}
                backdrop="static"
                keyboard={false}
                autoFocus={false}
            >
                <div className="modal-header">
                    <h5 className="modal-title">{props.modalTitle}</h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={props.onCloseClick}
                        aria-label="Close"
                    ></button>
                </div>

                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                    autoComplete="off"
                >
                    <ModalBody>
                        <Input
                            id="id"
                            name="id"
                            type="hidden"
                            value={validation.values.id || ""}
                            onChange={validation.handleChange}
                        />
                        <Input
                            id="vatcat_id"
                            name="vatcat_id"
                            type="hidden"
                            value={validation.values.vatcat_id || ""}
                            onChange={validation.handleChange}
                        />


                        <Row>
                            <Col className="col-12">
                                <div className="mb-3">
                                    <Input
                                        id="vatcat_name"
                                        name="vatcat_name"
                                        label="VAT Cat Name"
                                        required
                                        className="form-control"
                                        placeholder="Vat Cat Name"
                                        type="text"
                                        value={validation.values.vatcat_name || ""}
                                        onBlur={validation.handleBlur}
                                        onChange={(e) =>
                                            handleChange("vatcat_name", e.target.value)
                                        }
                                        invalid={
                                            validation.touched.vatcat_name &&
                                                validation.errors.vatcat_name
                                                ? true
                                                : false
                                        }
                                        touched={validation.touched.vatcat_name}
                                        error={validation.errors.vatcat_name}
                                    />
                                </div>
                            </Col>

                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            className="btn-block"
                            type="submit"
                            disabled={validation.isSubmitting}
                        >
                            {validation.isSubmitting ? (
                                <span>
                                    <i className="fa-solid fa-spinner fa-spin"></i>
                                    <span>&nbsp; {t("Please wait...")}</span>
                                </span>
                            ) : (
                                <span>{t("Submit")}</span>
                            )}
                        </Button>
                        <Button
                            color="danger"
                            className="btn-block"
                            type="reset"
                            onClick={props.onCloseClick}
                            disabled={props.loading}
                        >
                            {t("Cancel")}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </React.Fragment>
    );
};

export default ModalForm;
