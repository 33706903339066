import { takeEvery, call, put } from "redux-saga/effects";
import userTypeService from "../../../services/masters.user.type.service";
import { AnyAction } from "redux";

// UserType Redux States
import {
  GET_USER_TYPE,
  GET_USER_TYPE_LIST,
  CREATE_USER_TYPE,
  UPDATE_USER_TYPE,
  DELETE_USER_TYPE,
} from "./actionTypes";

import { 
    getUserTypeSuccess, 
    getUserTypeError,
    
    getUserTypeListSuccess,
    getUserTypeListError,

    createUserTypeSuccess,
    createUserTypeError,

    updateUserTypeSuccess,
    updateUserTypeError,

    deleteUserTypeSuccess,
    deleteUserTypeError,
} from "./actions";

function* getUserType({ payload: { usertype, history } }: AnyAction): any {
  try {
    const response = yield call(userTypeService.getUserType, usertype);
    if (response.data && response.data.status === "success") {
      yield put(getUserTypeSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getUserTypeError(response.data));
    } else {
      yield put(getUserTypeError(response));
    }
  } catch (error: any) {
    yield put(getUserTypeError(error));
  }
}

function* getUserTypeList({ payload: { query, history } }: AnyAction): any {
  try {
    const response = yield call(userTypeService.getUserTypeList, query);
    if (response.data && response.data.status === "success") {
      yield put(getUserTypeListSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getUserTypeListError(response.data));
    } else {
      yield put(getUserTypeListError(response));
    }
  } catch (error: any) {
    yield put(getUserTypeListError(error));
  }
}

function* createUserType({ payload: { usertype, history } }: AnyAction): any {
  try {
    const response = yield call(userTypeService.createUserType, usertype);
    if (response.data && response.data.status === "success") {
      yield put(createUserTypeSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(createUserTypeError(response.data));
    } else {
      yield put(createUserTypeError(response));
    }
  } catch (error: any) {
    yield put(createUserTypeError(error));
  }
}

function* updateUserType({ payload: { usertype, history } }: AnyAction): any {
  try {
    const response = yield call(userTypeService.updateUserType, usertype);
    if (response.data && response.data.status === "success") {
      yield put(updateUserTypeSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(updateUserTypeError(response.data));
    } else {
      yield put(updateUserTypeError(response));
    }
  } catch (error: any) {
    yield put(updateUserTypeError(error));
  }
}

function* deleteUserType({ payload: { usertype, history } }: AnyAction): any {
  try {
    const response = yield call(userTypeService.deleteUserType, usertype);
    if (response.data && response.data.status === "success") {
      yield put(deleteUserTypeSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(deleteUserTypeError(response.data));
    } else {
      yield put(deleteUserTypeError(response));
    }
  } catch (error: any) {
    yield put(deleteUserTypeError(error));
  }
}

function* userTypeSaga(): any {
  yield takeEvery(GET_USER_TYPE, getUserType);
  yield takeEvery(GET_USER_TYPE_LIST, getUserTypeList);
  yield takeEvery(CREATE_USER_TYPE, createUserType);
  yield takeEvery(UPDATE_USER_TYPE, updateUserType);
  yield takeEvery(DELETE_USER_TYPE, deleteUserType);
}

export default userTypeSaga;
