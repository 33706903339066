import React, { useEffect, useRef, useState } from "react";
import { getFirstErrorElement } from "../../../helpers/functions_helper";
// @ts-ignore
import CustomEditor from "ckeditor5-custom-build/build/ckeditor";
// @ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { ckEditorConfiguration } from "../../../helpers/functions_helper";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  Label,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import emailService from "../../../services/email.service";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";



const Signature = (props: any) => {
  const [initialValues, setInitialValues] = useState({
    id: "",
    signature_id: "",
    title: "",
    description: "",
    for_new_messages: "",
    for_replies_forwards: "",
  });
  const toastId = useRef(null) as any;
  const [signatureList, setSignature] = useState([]);
  const [loading, setLoading] = useState(true);
  const {t} = useTranslation();
  const getSignature = async () => {
    try {
      const res = await emailService.getsignaturelist();
      if (res?.data?.status === 'success') {
        setSignature(res.data.data);
      }
    } catch (error: any) {
      toast(error);
    }
  }
  useEffect(() => {
    getSignature();
  }, [])

  const newSignature = () => {
    setInitialValues({
      id: "",
      signature_id: "",
      title: "",
      description: "",
      for_new_messages: "",
      for_replies_forwards: "",
    })
  }

  const handleSelectSignature = (value: any) => {
    const item = signatureList.find((item: any) => item?.value === value) as any;
    if (item) {
      setInitialValues((preState: any) => ({ ...preState, title: item?.label, description: item.signature, id: item.value, signature_id: item.signature_id }))
    }
  }

  const updateSignatureFor = async (id: string, value: string) => {
    try {
      const res = await emailService.updateSignatureForMail({id,value});
      
    } catch (error: any) {
      toast(error);
    }
  }

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required(t("Signature name field is required."))
      .min(2, t("Signature name must be at least 2 characters.")),
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const onSubmit = async (value: any) => {
    toastId.current = toast(t("Creating Signature"), {
      autoClose: false,
      isLoading: true,
    });
    try {
      const res = await emailService.signature(value);
      if (res.data.status === "success") {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        getSignature();
        newSignature();
        //props.onCloseClick();
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoading(false);
      }

    } catch (error: any) {
      toast(error);
    }

  }


  const handleChange = (fieldName: string, fieldValue: string) => {
    setInitialValues((preState) => ({ ...preState, [fieldName]: fieldValue }));
  };

  const handleSubmit = (values: {
    id: string;
    signature_id: string;
    title: string;
    description: string;
    for_new_messages: string;
    for_replies_forwards: string;
  }) => {
    onSubmit(values);
  };



  useEffect(() => {
    if (!validation.isSubmitting) return;
    if (Object.keys(validation.errors).length > 0) {
      const element = getFirstErrorElement(validationSchema, validation);
      document
        .getElementById(element)
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
      document.getElementById(element)?.focus({ preventScroll: true });
    }
  }, [validation.isSubmitting, validation.errors]);

  return (
    <React.Fragment>
      <Modal
        size="xl"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">{t('Create and edit signatures')}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label="Close"
          ></button>
        </div>

        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          autoComplete="off"
        >
          <ModalBody>
            <Row>
              <Col className="col-12">
                <Row>
                  <Col xl={12} lg={12} md={12} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">
                      {t('Select signature for edit')}   OR<button
                          type="button"
                          color="link"
                          className="btn "
                          aria-label="New signature"
                          onClick={() => newSignature()}
                        > +{t('New signature')}</button>
                      </Label>
                      <Select
                        id="type"
                        name="type"
                        className="is-invalid"
                        options={signatureList}
                        isSearchable={true}
                        isDisabled={false}
                        onChange={(e: any) => {
                          handleSelectSignature(e.value);
                        }}
                      />
                    </div>
                  </Col>
                  <hr />
                  <Col xl={12} lg={12} md={12} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">
                        {t("Edit signature name")}
                        <span className="text-warning is_required">*</span>
                      </Label>
                      <Input
                        id="category"
                        name="category"
                        className="form-control"
                        placeholder={t("Edit signature name")}
                        type="text"
                        value={initialValues?.title || ""}
                        autoFocus={true}
                        onBlur={validation.handleBlur}
                        onChange={(e) =>
                          handleChange("title", e.target.value)
                        }
                        invalid={validation.errors.title ? true : false}
                      />
                      {validation.errors.title ? (
                        <FormFeedback type="invalid">
                          {validation.errors.title}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col xl={12} lg={12} md={12} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">
                      {t("Description")}{" "}
                      </Label>
                      <CKEditor
                        editor={CustomEditor}
                        config={ckEditorConfiguration}
                        data={initialValues?.description || ''}
                        id="comment"
                        name="comment"
                        className="form-control"
                        onChange={(e: any, editor: any) => {
                          handleChange("description", editor.getData());
                        }}
                      />
                    </div>
                  </Col>
                  <hr />
                  <Col xl={12} lg={12} md={12} sm={12}>
                    <div className="m-0">
                      <Label className="form-label">
                      {t("Select default signatures")}
                      </Label>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col className="col-12">
                <Row>
                  <Col xl={6} lg={6} md={6} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">
                      {t("For New Messages")}:
                      </Label>
                      <Select
                        id="type"
                        name="type"
                        className="is-invalid"
                        options={signatureList}
                        isSearchable={true}
                        isDisabled={false}
                        onChange={(e: any) => {
                          updateSignatureFor(e.value, 'new');
                        }}
                      />
                    </div>
                  </Col>

                  <Col xl={6} lg={6} md={6} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">
                      {t("For Replies/Forwards")}:
                      </Label>
                      <Select
                        id="type"
                        name="type"
                        className="is-invalid"
                        options={signatureList}
                        isSearchable={true}
                        isDisabled={false}
                        onChange={(e: any) => {
                          updateSignatureFor(e.value, 'reply');
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              className="btn-block"
              type="submit"
              disabled={props.loading}
            >
              {props.loading ? (
                <span>
                  <i className="fa-solid fa-spinner fa-spin"></i>
                  <span>&nbsp; Please wait...</span>
                </span>
              ) : (
                <span>{t("Submit")}</span>
              )}
            </Button>
            <Button
              color="danger"
              className="btn-block"
              type="reset"
              onClick={props.onCloseClick}
              disabled={props.loading}
            >
              {t("Cancel")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default Signature;
