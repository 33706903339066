import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Label, Modal, ModalBody, Row } from 'reactstrap';
import Dropzone from '../../components/Common/Dropzone';
import fileDownload from 'js-file-download';
import { toast } from 'react-toastify';
import Select from 'react-select';
import api from '../../services/master.documentType.service';
import CommonService from '../../services/common.service';
import UserGeneralService from '../../services/users.general.service';
import { getSelectOptions } from '../../utils/data';
const UploadDocModal = (props: any) => {

    const { t } = useTranslation();
    const [uploadModal, setUploadModal] = useState<boolean>(false);
    const { data, handleClose, isOpen, title, onSuccess, type } = props;
    const [documentTypeOptions, setDocumentTypeOptions] = useState<Array<any>>([]);
    const [selectedDocumentType, setSelectedDocumentType] = useState<any>();

    const moduleData = {
        module_app_id: type == "company" ? 12 : 11,
        module_main_id: data?.id,
        module_sub_id: data?.user_id,
        section_id: (data.type == "Passport" ? 1 : (data.type == "Residence") ? 2 : (data.type == "Tax") ? 3 : (data.type == "Single permit")? 4 :  (data.type == "Annex")? 5: 0),
        file_path: type == "company" ? "company" : "users",
    };

    // const getDocuments = async (year: number) => {
    //     setLoading(true);
    //     const res = await api.getAllList(`?year=${year}&module_main_id=${data?.id}&map_master=${type === 'User' ? 2 : 1}`);
    //     if (res.data.status === 'success') {
    //         setDocumentList(res.data.data)
    //     }
    //     setLoading(false);
    // }

    const handleUploadDocument = async (files: any) => {
        await files.map(async (file: any) => {
            const req = {
                'attachment_id': file?.id,
                'document_type_id': selectedDocumentType,
                'year': data?.year,
                'module_main_id': data?.id,
                'document_name': file?.short_description ? file?.short_description : file?.file_name,
                'expiry_date': file?.expiry_date,
                'due_date': file?.due_date,
            }
            const res = await CommonService.uploadDocument(req);
            onSuccess(file?.id);
            handleClose(false);
        });
    }

    // useEffect(() => {
    //     getSelectOptions(
    //         'tbl_master_document_types',
    //         'document_type',
    //         "",
    //         [],
    //         ['map_master',2]
    //     ).then((options: any) =>{
    //         setDocumentTypeOptions(options)
    //     } )
    // }, [])

    async function getDocumentsOption() {
        const options = await UserGeneralService.getSelectOptionsList(
            'tbl_master_document_types',
            'document_type',
            'document_type',
            'asc',
            { key: 'map_master', 'value': 2 }
        );
        //console.log(options);
        if (typeof options==='object')
            setDocumentTypeOptions(options)
        else
            setDocumentTypeOptions([])
    }

    useEffect(() => {
        if (data.type && documentTypeOptions.length > 0) {
            const pre_type = documentTypeOptions.filter((doc: any) => {
                let type = data.type;
                if (data.type == "Tax") {
                    type = "Tax declaration";
                }
                const regex = new RegExp(type, "i");
                return doc.label.match(regex) !== null;

            });
            if (pre_type.length > 0) {
                setSelectedDocumentType(pre_type[0].value);
            }
        }
    }, [documentTypeOptions]);


    useEffect(() => {
        getDocumentsOption();
        // getSelectOptions(
        //     'tbl_master_document_types',
        //     'document_type',
        //     "",
        //     [],
        //     ['map_master',2]
        // ).then((options: any) =>{
        //     setDocumentTypeOptions(options)
        // } )
    }, [])



    // useEffect(() => {
    //     if (data?.id)
    //         getDocuments(year);
    // }, [year, data?.id])



    return (
        <Modal
            size="xl"
            isOpen={isOpen}
            toggle={() => handleClose(false)}
            backdrop="static"
            keyboard={false}
            autoFocus={false}
        >
            <div className="modal-header">
                <h5 className="modal-title">{t(`${data.title} Upload document`)}</h5>
                <button
                    type="button"
                    className="btn-close"
                    onClick={() => handleClose(false)}
                    aria-label="Close"
                ></button>
            </div>
            <ModalBody>
                <>
                    <Row>
                        <Col>
                            <div className='mb-3'>
                                <Label>{t("Type of document")}</Label>
                                <Select
                                    id="document_type_id"
                                    name="document_type_id"
                                    placeholder="Select"
                                    options={documentTypeOptions}
                                    value={documentTypeOptions.find((o: any) => o.value === selectedDocumentType)}
                                    isSearchable={true}
                                    isDisabled={false}
                                    onChange={(option: any) => {
                                        setSelectedDocumentType(option.value);
                                    }}
                                />
                            </div>
                        </Col>
                        {/* <Col>
                            <Label>{t("Year")}</Label>
                            <Select
                                id="year"
                                name="year"
                                placeholder="Year"
                                options={yearOptions}
                                //@ts-ignore
                                defaultValue={yearOptions.find((o: any) => o.value === year)}
                                value={yearOptions.find((o: any) => o.value === year)}
                                isSearchable={true}
                                isDisabled={false}
                                onChange={(option: any) => {
                                    setYear(option.value);
                                }}
                            />
                        </Col> */}

                    </Row>
                    <Row>
                        <Col>
                            <Dropzone
                                moduleData={moduleData}
                                hideList={true}
                                fetchData={true}
                                isOpen={true}
                                enableExpiryDate={true}
                                onUpload={(files: any) => handleUploadDocument(files)}
                            />
                        </Col>
                    </Row>
                </>
            </ModalBody>

        </Modal>
    )
}

export default UploadDocModal;