import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  Label,
  ModalBody,
  Nav,
  NavItem,
  Row,
} from "reactstrap";
import { components, ValueContainerProps } from "react-select";
// Actions
import { CommonAction, QuestionnaireAction } from "../../store/actions";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getDependentMultipleWhereSelectOptions,
  getSelectOptions,
  yearData,
  addIndividualInCompany,
  getUserProfile,
  filteredOptionsByTCUser,
  datePickerI18
} from "../../helpers/functions_helper";

import labelPlugin from "flatpickr/dist/plugins/labelPlugin/labelPlugin";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Select from "../../components/Form/Select/Select";
import Flatpickr from "../../components/Form/Flatpickr";
import DatePicker from "../../components/Form/DatePicker/DatePicker";

const QuestionnaireCreate = (props: any) => {
  const { t } = useTranslation();
  document.title = t("Create Questionnaire") + " | " + process.env.REACT_APP_NAME;
  window.history.replaceState(
    null,
    document.title,
    "/questionnaires/create-questionnaire"
  );

  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "/questionnaires/all-questionnaires",
      label: t("Questionnaire"),
      active: false,
    },
    {
      url: "#",
      label: t("Create Questionnaire"),
      active: true,
    },
  ];

  const userProfile = getUserProfile("all-questionnaires");
  const dispatch = useDispatch();
  const toastId = React.useRef(null) as any;
  const toastTask = React.useRef(null) as any;
  let navigate = useNavigate();

  /* Company Master and User and user status */
  const [companyOption, setCompanyOption] = useState([]);
  const [companyValue, setCompanyValue] = useState<any>();
  const [loadingCompany, setloadingCompanyOption] = useState(true);
  const [companyID, setCompanyID] = useState<number>();

  const [userStatusOption, setuserStatusOption] = useState<any>([]);
  const [userstatusValue, setUserstatusValue] = useState();
  const [loadingUserStatus, setloadingUserStatus] = useState(true);

  const [typeOption, setTypeOption] = useState<any>([]);
  const [typeOptionfilter, setTypeOptionFilter] = useState<any>([]);
  const [loadingTypeOption, setloadingTypeOption] = useState(true);
  const [companyList, setCompanyList] = useState([]);
  const [isTaxReturnType, setIsTaxReturnType] = useState(false);
  const [taxReturnTypeOption, setTaxReturnTypeOption] = useState<any>([]);
  const [regionOptions, setRegionOptions] = useState<any>([])
  let { getCompanyOptionsList } = useSelector((state: any) => ({
    getCompanyOptionsList: state.Common.GET_COMPANY_OPTIONS_LIST_SUCCESS,
  }));


  /* set questionnaire type */
  const [questionnaireTypeOption, setQuestionnaireTypeOption] = useState([]);
  const [questionnaireType, setQuestionnaireType] = useState<any>({
    value: 0,
    label: t("Please select..."),
  });
  const [questionnaireTypeValue, setQuestionnaireTypeValue] = useState("");
  const [loadingQuestionnaireType, setLoadingQuestionnaireType] =
    useState(true);

  // Get options list -------------------------------------------------
  useEffect(() => {
    if (!getCompanyOptionsList) {
      dispatch(CommonAction.getCompanyOptionsList());
    } else {
      setCompanyList(getCompanyOptionsList);
    }
  }, []);

  useEffect(() => {
    if (userstatusValue) {
      getDependentMultipleWhereSelectOptions(
        "tbl_master_tax_return_type",
        "tax_return_type_value",
        ["questionnaire_type"],
        [userstatusValue],
        "",
        ['external_due_date', 'questionnaire_sending_date', "questionnaire_type"]
      ).then(function (options) {
        setTaxReturnTypeOption(options);
      });
    }
  }, [userstatusValue]);


  useEffect(() => {
    if (getCompanyOptionsList && getCompanyOptionsList.data.length) {
      const company_data =  getCompanyOptionsList.data.filter((item: any) => !item.is_archive);
      if (!userProfile.CAN_CREATE) {
        const options = filteredOptionsByTCUser(
          company_data,
          userProfile.USER_ID
        ) as any;
        const item = addIndividualInCompany(options) as any;
        setCompanyOption(item);
        setloadingCompanyOption(false);
        setloadingCompanyUserOption(true);
      } else {
        const options = company_data;
        const item = addIndividualInCompany(options) as any;
        if (item) {
          const options = item?.map((option: any) => ({
            label: t(option.label),
            value: option.value
          }));
          setCompanyOption(options);
        }
        setloadingCompanyOption(false);
        setloadingCompanyUserOption(true);
      }
    }
  }, [getCompanyOptionsList, localStorage.getItem("I18N_LANGUAGE")]);


  useEffect(() => {
    getSelectOptions("tbl_master_user_status", "user_status_value").then(
      function (options) {
        const statusOptions = options?.map((option: any) => ({
          label: t(option.label),
          value: option.value
        }));
        setuserStatusOption(statusOptions);
        setUserStatusFirstOption(options);
        setloadingUserStatus(false);
      }
    );
  }, []);


  const [regionFirstOptions, setRegionFirstOptions] = useState<any>([]);
  const [userStatusFirstOption, setUserStatusFirstOption] = useState<any>([]);
  useEffect(() => {
    if (regionFirstOptions) {
      const Options = regionFirstOptions?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setRegionOptions(Options);
    }

    if (userStatusFirstOption) {
      const status = userStatusFirstOption?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setuserStatusOption(status);
    }
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  useEffect(() => {
    if (!getCompanyOptionsList) dispatch(CommonAction.getCompanyOptionsList());
    getSelectOptions("tbl_master_region", "region").then(function (
      options
    ) {
      setRegionFirstOptions(options);
      const regionOptions = options?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setRegionOptions(regionOptions);
    });
  }, []);

  const [companyUserOption, setCompanyUserOption] = useState<any>([]);
  const [loadingCompanyUserOption, setloadingCompanyUserOption] =
    useState(true);
  const [companyUserValue, setCompanyUserValue] = useState([]);
  const [yearValue, setYearValue] = useState(new Date().getFullYear());

  const [intialData, setIntialData] = useState({
    region_id: 0,
    company_id: "",
    year: new Date().getFullYear(),
    user_type_flag: false,
    user_status: "",
    employee: [],
    task_type: 0,
    due_date: moment().add(1, "month").format("YYYY-MM-DD"),
    expiry_date: moment().add(2, "month").format("YYYY-MM-DD"),
    questionnaire_type_id: "",
    tax_return_type: "",
  });

  const updateInitialData = () => {
    const initialData = {
      ...intialData,
      company_id: "",
      year: new Date().getFullYear(),
      user_status: "",
      employee: [],
      task_type: 0,
      due_date: moment().add(1, "month").format("YYYY-MM-DD"),
      expiry_date: moment().add(2, "month").format("YYYY-MM-DD"),
      questionnaire_type_id: "",
      tax_return_type: "",
      region_id: 0,
      user_type_flag: false
    };
    setCompanyUserValue([]);
    setIntialData(initialData);
  };

  const handleChange = (fieldName: string, fieldValue: any) => {
    setIntialData({ ...intialData, [fieldName]: fieldValue });
  };

  const handleUserTypeFlag = (e: any) => {
    setIntialData({
      ...intialData,
      ["user_type_flag"]: !intialData.user_type_flag,
    });
    getDependentMultipleWhereSelectOptions(
      "tbl_master_questionnaire_type",
      "questionnaire_type",
      ["year"],
      [yearValue],
      "",
      ["type", "task_type", "user_type", "region_id"]
    ).then(function (options) {
      if (e.target.checked) {
        options = options.filter((opt: any) => opt.user_type !== null);
      } else {
        options = options.filter((opt: any) => opt.user_type == null);
      }
      options = getQuestionnaireTypeByRegion(intialData.region_id, options);
      setQuestionnaireTypeOption(options);
      setLoadingQuestionnaireType(false);
    });
  };

  const validationSchema = Yup.object().shape({
    company_id: Yup.number().required("Client field is required."),
    year: Yup.number().required("Year field is required."),
    employee: Yup.array().required("Employees field is required.")
      .min(1, 'Employees field is required.'),
    task_type: Yup.number()
      .nullable(true)
      .required("Task type field is required."),
    expiry_date: Yup.string().required("Expiry date field is required."),
    due_date: Yup.string().required("Due date field is required."),
    questionnaire_type_id: Yup.number().required("Questionnaire type field is required."),
    // tax_return_type: Yup.string().test(
    //     'is-required-if-premium',
    //     'Tax return type is required',
    //     (value: any, { options }: any) => {
    //         return isTaxReturnType;
    //     }
    // ),
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: intialData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });


  useEffect(() => {
    const update_data: any = {
      questionnaire_type_id: "",
      task_type: "",
      tax_return_type: ""
    };
    setIsTaxReturnType(false);
    setIntialData({ ...intialData, ...update_data })
  }, [userstatusValue, yearValue, intialData.user_type_flag]);

  const [taxReturnType, setTaxReturnType] = useState<any>(0);
  useEffect(() => {
    /* get the tax return type*/
    if (questionnaireType?.type) {
      let type_val = 0;
      if (questionnaireType?.type == "tax_return_IPP") {
        type_val = 1;
      } else if (questionnaireType?.type == "tax_return_INR") {
        type_val = 2;
      } else { }
      setTaxReturnType(type_val);
    }

    handleChange("task_type", questionnaireType?.task_type);
  }, [questionnaireType]);


  useEffect(() => {
    if (intialData.task_type && typeOption) {
      const option: any = typeOption.filter(
        (data: any) => data.value === intialData.task_type
      );
      if (option[0]) {
        option[0].is_tax_return_type
          ? setIsTaxReturnType(true)
          : setIsTaxReturnType(false);
      } else {
        setIsTaxReturnType(false);
      }
    }
  }, [taxReturnType, typeOption]);

  useEffect(() => {
    if (isTaxReturnType) {
      const tax_type = taxReturnTypeOption.find(
        (option: any) =>
          option.label == "Simple"
      );
      handleChange('tax_return_type', tax_type?.value);
    }
  }, [isTaxReturnType])

  useEffect(() => {
    if (intialData.tax_return_type) {
      const tax_type = taxReturnTypeOption.find(
        (option: any) =>
          option.value == intialData.tax_return_type
      );
      if (tax_type?.value && tax_type?.external_due_date) {
        setIntialData({ ...intialData, ["tax_return_type"]: tax_type?.value, ['expiry_date']: moment(tax_type?.external_due_date).format("YYYY-MM-DD"), ['due_date']: moment(tax_type?.external_due_date).subtract(1, "month").format("YYYY-MM-DD") });
      }
    }
  }, [intialData.tax_return_type])
  useEffect(() => {
    setTypeOptionFilter([]);
    getDependentMultipleWhereSelectOptions(
      "tbl_master_fees",
      "description",
      ["is_questionnaire"],
      [1],
      "",
      ["description", "is_questionnaire", "is_tax_return_type"]
    ).then(function (options) {
      setTypeOption(options);
      setloadingTypeOption(false);
    });
  }, [intialData.questionnaire_type_id])

  useEffect(() => {
    if (companyID && companyID > 0 && userstatusValue) {
      setloadingCompanyUserOption(false);
      setCompanyUserOption([]);
      setCompanyUserValue([]);
      getDependentMultipleWhereSelectOptions(
        "tbl_master_users",
        "first_name",
        ["company", "status"],
        [companyID, userstatusValue],
        "",
        ["contact_person", "hr_contact_person"]
      ).then(function (options) {
        if (options.length > 0) {
          options = [
            { label: t("Select All"), value: "all", isDisabled: false },
            ...options,
          ];
          if (
            questionnaireTypeValue == "ubo_aml" ||
            questionnaireTypeValue == "ubo"
          ) {
            options = options.filter(
              (option: any) =>
                option.value == option.contact_person ||
                option.value == option.hr_contact_person
            );
          } else {
            options = options.filter(
              (option: any) =>
                option.value != option.contact_person &&
                option.value != option.hr_contact_person
            );
          }
          setCompanyUserOption(options);
        }
        setloadingCompanyOption(false);
      });
    } else if (companyID && companyID == 0 && userstatusValue) {
      setCompanyUserOption([]);
      setCompanyUserValue([]);
      getDependentMultipleWhereSelectOptions(
        "tbl_master_users",
        "first_name",
        ["company", "status"],
        [0, userstatusValue]
      ).then(function (options) {
        setCompanyUserOption(options);
        setloadingCompanyUserOption(false);
      });
    } else if (companyID) {
      setloadingCompanyUserOption(false);
      setCompanyUserOption([]);
      setCompanyUserValue([]);
      getDependentMultipleWhereSelectOptions(
        "tbl_master_users",
        "first_name",
        ["company"],
        [companyID],
        "",
        ["contact_person", "hr_contact_person"]
      ).then(function (options) {
        if (options.length > 0) {
          options = [
            { label: t("Select All"), value: "all", isDisabled: false },
            ...options,
          ];
          if (
            questionnaireTypeValue == "ubo_aml" ||
            questionnaireTypeValue == "ubo"
          ) {
            options = options.filter(
              (option: any) =>
                option.value == option.contact_person ||
                option.value == option.hr_contact_person
            );
          } else {
            options = options.filter(
              (option: any) =>
                option.value != option.contact_person &&
                option.value != option.hr_contact_person
            );
          }
          setCompanyUserOption(options);
        }
        setloadingCompanyOption(false);
      });
    } else {
      setCompanyUserOption([]);
      setCompanyUserValue([]);
    }
  }, [companyID, userstatusValue, questionnaireTypeValue]);

  useEffect(() => {
    getDependentMultipleWhereSelectOptions(
      "tbl_master_questionnaire_type",
      "questionnaire_type",
      ["year"],
      [yearValue],
      "",
      ["type", "task_type", "user_type", "region_id"]
    ).then(function (options) {
      options = options.filter((opt: any) => opt.user_type == null);
      options = getQuestionnaireTypeByRegion(intialData.region_id, options);
      setQuestionnaireTypeOption(options);
      setLoadingQuestionnaireType(false);
    });
  }, []);

  useEffect(() => {
    if (companyValue) {
      handleChange('region_id', companyValue?.region_id);
    }
  }, [companyValue])

  useEffect(() => {
    handleChange("questionnaire_type_id", "");
    if (userstatusValue) {
      setQuestionnaireTypeOption([]);
      setLoadingQuestionnaireType(true);
      setQuestionnaireType({
        value: 0,
        label: t("Please select..."),
      });

      getDependentMultipleWhereSelectOptions(
        "tbl_master_questionnaire_type",
        "questionnaire_type",
        ["user_type", "year"],
        [userstatusValue, yearValue],
        "",
        ["type", "task_type", "region_id"]
      ).then(function (options) {
        options = getQuestionnaireTypeByRegion(intialData.region_id, options);
        setQuestionnaireTypeOption(options);
        setLoadingQuestionnaireType(false);
      });
    } else if (yearValue) {
      setQuestionnaireTypeOption([]);
      setLoadingQuestionnaireType(true);
      setQuestionnaireType({
        value: 0,
        label: t("Please select..."),
      });

      getDependentMultipleWhereSelectOptions(
        "tbl_master_questionnaire_type",
        "questionnaire_type",
        ["year"],
        [yearValue],
        "",
        ["type", "task_type", "user_type", "region_id"]
      ).then(function (options) {
        options = options.filter((opt: any) => opt.user_type == null);
        options = getQuestionnaireTypeByRegion(intialData.region_id, options);
        setQuestionnaireTypeOption(options);
        setLoadingQuestionnaireType(false);
      });
    }
  }, [userstatusValue, yearValue, intialData.region_id]);

  useEffect(() => {
    if (intialData?.task_type && questionnaireType?.task_type && typeOption.length) {
      const typeOptionFiltered = typeOption.filter((opt: any) => opt.value === intialData?.task_type);
      setTypeOptionFilter(typeOptionFiltered);
    } else {
      setTypeOptionFilter([]);
    }
  }, [intialData?.task_type])

  const getQuestionnaireTypeByRegion = (region_id: any, options: any) => {
    let item = options;
    if (region_id != "") {
      item = options.filter((opt: any) => opt.region_id == region_id || opt.region_id == null);
      if (region_id == 4) {
        item = options.filter((opt: any) => opt.region_id == null || opt.region_id == 4)
      }
    }
    return item;
  }




  // useEffect(()=>{
  //   if (getCompanyOptionsList && getCompanyOptionsList.data.length) {
  //     if (!userProfile.CAN_CREATE) {
  //       const options = filteredOptionsByTCUser(
  //         getCompanyOptionsList.data,
  //         userProfile.USER_ID
  //       ) as any;
  //       const item = addIndividualInCompany(options) as any;
  //       setCompanyOption(item);
  //       setloadingCompanyOption(false);
  //       setloadingCompanyUserOption(true);
  //     } else {
  //       const options = getCompanyOptionsList.data;
  //        const item = addIndividualInCompany(options) as any;
  //       setCompanyOption(item);
  //       setloadingCompanyOption(false);
  //       setloadingCompanyUserOption(true);
  //     }
  //   }
  // },[intialData.region_id])



  let data = useSelector((state: any) => ({
    success: state.Questionnaire.success,
    taskMsg: state.Questionnaire.tasksuccess,
    error: state.Questionnaire.error,
  }));

  useEffect(() => {
    if (data.success !== "") {
      toast.update(toastId.current, {
        type: toast.TYPE.SUCCESS,
        render: data.success,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
    if (data.error !== "") {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: data.error,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
    closeToast();
  }, [data.success, data.error]);


  useEffect(() => {
    if (data.success !== "" && data.taskMsg !== "") {
      toast.update(
        toastTask.current,
        {
          render: t("Task created for questionnaire"),
          type: toast.TYPE.SUCCESS,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        }
      );
    } else {
      closeToast();
    }

  }, [data.taskMsg, data.success])

  const closeToast = () => {
    if (toastTask.current && toastTask.current.close) {
      toastTask.current.close(); // Call the close method on the toast reference if it exists
    }
  };

  const handleSubmit = (values: any) => {
    toastId.current = toast(t("Sending questionnaire..."), {
      autoClose: false,
      isLoading: true,
    });
    dispatch(QuestionnaireAction.addNewQuestionnaire(values));
    updateInitialData();
  };

  return (
    <React.Fragment>
      <div className="page-content create-questionnaire"><Container fluid>
        <div className="col-12 mb-2">
          <Nav className="nav justify-content-start">
            <NavItem className="breadcrumb-item">
              <Link to="/dashboard">&nbsp; {t("Dashboard")}&nbsp; </Link>
            </NavItem>
            <NavItem className="breadcrumb-item">
              <Link
                to="/questionnaires/all-questionnaires"
              > &nbsp; {t("Questionnaires")}&nbsp; </Link>
            </NavItem>
            <NavItem className="breadcrumb-item active">
              <Link to="#">&nbsp; {t("Create")}</Link>
            </NavItem>
          </Nav>
        </div>
        <Card>
          <CardHeader className="bg-transparent border-bottom mt-3">
            <div className="d-sm-flex flex-wrap">
              <CardTitle className="card-title text-uppercase">
                {t("Create Questionnaire")}
              </CardTitle>
            </div>
          </CardHeader>
          <CardBody>
            <Row className="questionnaire-form-modle">
              <Col lg={12}>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                  className="row gy-2 gx-3 align-items-center"
                >
                  <Row>
                    <div className="col-md-4 mb-3">
                      <Select
                        id="company"
                        name="company_id"
                        className="is-invalid"
                        options={companyOption}
                        label="Client"
                        required
                        value={
                          intialData.company_id
                            ? companyOption.find(
                              (option: any) =>
                                option.value ==
                                intialData.company_id
                            )
                            : ""
                        }
                        isSearchable={true}
                        isDisabled={loadingCompany}
                        onBlur={() =>
                          validation.setFieldTouched(
                            "company_id",
                            true
                          )
                        }
                        onChange={(option: any) => {
                          setCompanyValue(option);
                          setCompanyID(option.value);
                          handleChange(
                            "company_id",
                            option.value
                          );
                        }}
                        error={validation.errors.company_id}
                        touched={validation.touched.company_id}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <Select
                        id="region_id"
                        name="region_id"
                        className="is-invalid"
                        options={regionOptions}
                        label="Region"
                        value={
                          intialData.region_id
                            ? regionOptions.find(
                              (option: any) =>
                                option.value ==
                                intialData.region_id
                            )
                            : ""
                        }
                        isSearchable={true}
                        isDisabled={loadingCompany}
                        onChange={(option: any) => {
                          handleChange("region_id", option.value);
                        }}
                        error={validation.errors.region_id}
                        touched={validation.touched.region_id}
                      />
                    </div>
                    <div className="col-md-2 mb-3">
                      <Select
                        id="year"
                        name="year"
                        className="is-invalid"
                        options={yearData}
                        label="Year"
                        required
                        value={
                          intialData.year
                            ? yearData.find(
                              (option: any) =>
                                option.value ==
                                intialData.year
                            )
                            : ""
                        }
                        isSearchable={true}
                        isDisabled={loadingCompany}
                        // onBlur={() =>
                        //     validation.setFieldTouched("company", true)
                        // }
                        onChange={(option: any) => {
                          setYearValue(option.value);
                          handleChange("year", option.value);
                        }}
                        error={validation.errors.year}
                        touched={validation.touched.year}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      {intialData.user_type_flag}
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheckcolor1"
                        name="user_type_flag"
                        onChange={(e: any) =>
                          handleUserTypeFlag(e)
                        }
                        onBlur={validation.handleBlur}
                        checked={intialData.user_type_flag}
                      />
                      <Label className="form-label">
                        &nbsp; {t("User type")}
                      </Label>
                      <Select
                        id="user_status"
                        name="user_status"
                        className="is-invalid"
                        options={userStatusOption}
                        value={
                          intialData.user_status
                            ? userStatusOption.find(
                              (option: any) =>
                                option.value ==
                                intialData.user_status
                            )
                            : ""
                        }
                        isSearchable={true}
                        isDisabled={!intialData.user_type_flag}
                        onChange={(option: any) => {
                          setUserstatusValue(option.value);
                          handleChange(
                            "user_status",
                            option.value
                          );
                        }}
                        error={validation.errors.user_status}
                        touched={validation.touched.user_status}
                      />
                    </div>
                  </Row>
                  <Row>
                    <div className="col-md-4 mb-3">
                      <Select
                        id="questionnaire_type_id"
                        name="questionnaire_type_id"
                        className="is-invalid"
                        label="Questionnaire type"
                        required
                        maxMenuHeight={250}
                        options={questionnaireTypeOption}
                        value={
                          intialData.questionnaire_type_id
                            ? questionnaireTypeOption.find(
                              (option: any) =>
                                option.value ==
                                intialData.questionnaire_type_id
                            )
                            : ""
                        }
                        isSearchable={true}
                        isDisabled={loadingQuestionnaireType}
                        onChange={(option: any) => {
                          setQuestionnaireType(option);
                          setQuestionnaireTypeValue(
                            option.type
                          );
                          handleChange(
                            "questionnaire_type_id",
                            option.value
                          );
                        }}
                        error={validation.errors.questionnaire_type_id}
                        touched={validation.touched.questionnaire_type_id}
                      />
                    </div>

                    <div className="col-md-4 mb-3">
                      <Select
                        id="questionanire_task_type"
                        name="task_type"
                        className="is-invalid"
                        label="Task type"
                        required
                        options={(typeOptionfilter.length) ? typeOptionfilter : typeOption}
                        value={
                          intialData.task_type
                            ? typeOption.find(
                              (option: any) =>
                                option.value ==
                                intialData.task_type
                            )
                            : ""
                        }
                        isSearchable={true}
                        isDisabled={loadingTypeOption}
                        maxMenuHeight={250}
                        // onBlur={() =>
                        //     validation.setFieldTouched("type", true)
                        // }
                        onChange={(option: any) => {
                          handleChange(
                            "task_type",
                            option.value
                          );
                        }}
                        error={validation.errors.task_type}
                        touched={validation.touched.task_type}
                      />
                    </div>
                    {!!isTaxReturnType && (
                      <Col className="col-md-4 mb-3">
                        <Select
                          id="tax_return_type"
                          name="tax_return_type"
                          className="is-invalid"
                          options={taxReturnTypeOption}
                          label="Tax return type"
                          value={
                            intialData.tax_return_type
                              ? taxReturnTypeOption.find(
                                (option: any) =>
                                  option.value ==
                                  intialData.tax_return_type
                              )
                              : isTaxReturnType
                                ? taxReturnTypeOption.find(
                                  (option: any) =>
                                    option.label == "Simple"
                                )
                                : ""
                          }
                          isSearchable={true}
                          onBlur={() =>
                            validation.setFieldTouched(
                              "tax_return_type",
                              true
                            )
                          }
                          onChange={(e: any) => {
                            handleChange(
                              "tax_return_type",
                              e.value
                            );
                          }}
                          error={validation.errors.tax_return_type}
                          touched={validation.touched.tax_return_type}
                        />
                      </Col>
                    )}

                    <div className="col-md-4 mb-3">
                      <Select
                        id="employee"
                        name="employee"
                        className="is-invalid"
                        label="Employees"
                        required
                        isMulti={true}
                        isSearchable
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        options={companyUserOption}
                        value={companyUserValue}
                        maxMenuHeight={250}
                        isDisabled={loadingCompanyUserOption}
                        components={{
                          ValueContainer,
                        }}
                        onBlur={() =>
                          validation.setFieldTouched(
                            "employee",
                            true
                          )
                        }
                        onChange={(option: any) => {
                          if (
                            option &&
                            option.find(
                              (opt: any) => opt.value === "all"
                            )
                          ) {
                            companyUserOption[0].isDisabled =
                              true;
                            setCompanyUserValue(
                              companyUserOption.slice(1)
                            );
                            setCompanyUserOption(
                              companyUserOption
                            );
                            handleChange(
                              "employee",
                              companyUserOption.slice(1)
                            );
                          } else {
                            companyUserOption[0].isDisabled =
                              false;
                            setCompanyUserOption(
                              companyUserOption
                            );
                            setCompanyUserValue(option);
                            handleChange("employee", option);
                          }
                        }}
                        error={validation.errors.employee}
                        touched={validation.touched.employee}
                      />
                    </div>

                    <Col className="col-4">
                      <div className="mb-3">
                        <DatePicker
                          id="due_date"
                          name="due_date"
                          label="Due date"
                          required
                          className="form-control d-block"
                          type="text"
                          value={intialData.due_date}
                          placeholder="DD/MM/YYYY"
                          options={{
                            altInput: true,
                            altFormat: "d/m/Y",
                            dateFormat: "Y-m-d H:i:S",
                            minDate: "today",
                            plugins: [labelPlugin()],
                            allowInput: true,
                            onChange: function (
                              selectedDates,
                              dateStr,
                              instance
                            ) {
                              handleChange(
                                "due_date",
                                dateStr
                              );
                            },
                            onClose: function (
                              selectedDates,
                              dateStr,
                              instance
                            ) {
                              handleChange(
                                "due_date",
                                dateStr
                              );
                            },
                            locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                          }}
                          error={validation.errors.due_date}
                          touched={validation.touched.due_date}
                        />
                      </div>
                    </Col>

                    <Col className="col-4">
                      <div className="mb-3">
                        <DatePicker
                          id="expiry_date"
                          name="expiry_date"
                          label="Expiry date"
                          required
                          className="form-control d-block"
                          type="text"
                          value={intialData.expiry_date}
                          placeholder="DD/MM/YYYY"
                          options={{
                            altInput: true,
                            altFormat: "d/m/Y",
                            dateFormat: "Y-m-d H:i:S",
                            plugins: [labelPlugin()],
                            minDate: intialData.expiry_date
                              ? intialData.expiry_date
                              : "today",
                            allowInput: true,
                            onChange: function (
                              selectedDates,
                              dateStr,
                              instance
                            ) {
                              handleChange(
                                "expiry_date",
                                dateStr
                              );
                            },
                            onClose: function (
                              selectedDates,
                              dateStr,
                              instance
                            ) {
                              handleChange(
                                "expiry_date",
                                dateStr
                              );
                            },
                            locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                          }}
                          error={validation.errors.expiry_date}
                          touched={validation.touched.expiry_date}
                        />
                      </div>
                    </Col>

                    {/* <div className="col-md-2 mt-4">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" id="autoSizingCheck" name="task" />
                                                        <label className="form-check-label" htmlFor="autoSizingCheck">
                                                            Create task
                                                        </label>
                                                    </div>
                                                </div> */}
                  </Row>
                  <CardFooter className="d-flex flex-wrap gap-2 bg-transparent border-top justify-content-end">
                    {/* <Button
                                                                            color="outline-danger"
                                                                            className="btn-block"
                                                                            type="button"
                                                                            onClick={() => updateInitialData()}
                                                                        >
                                                                            <span>{t('Reset')}</span>
                                                                        </Button> */}
                    <Button
                      color="primary"
                      className="btn-block"
                      type="submit"
                    >
                      <span>{t("Send")}</span>
                    </Button>
                    <Link
                      to="/questionnaires/all-questionnaires"
                    >
                      <Button
                        color="danger"
                        className="btn-block"
                        type="button"
                      >

                        {t("Cancel")}
                      </Button>
                    </Link>
                  </CardFooter>
                </Form>
              </Col>
            </Row>

          </CardBody>
        </Card>
      </Container></div >
    </React.Fragment >
  );
};

export default QuestionnaireCreate;

const ValueContainer = ({ children, ...props }: ValueContainerProps<any>) => {
  let [values, input] = children as any;

  if (Array.isArray(values)) {
    const val = (i: number) => values[i].props.children;
    const { length } = values;

    switch (length) {
      case 1:
        values = `${val(0)}`;
        break;
      case 2:
        values = `${val(0)} and ${val(1)}`;
        break;
      case 3:
        values = `${val(0)}, ${val(1)} and ${val(2)}`;
        break;
      default:
        const plural = values.length === 3 + 1 ? "" : "s";
        const otherCount = length - 3;
        values = `${val(0)}, ${val(1)}, ${val(
          2
        )} ${t("and")} ${otherCount} ${t("others selected")}`;
        break;
    }
  }

  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};
