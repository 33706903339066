import React, { FC, useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardSubtitle, CardTitle, Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import { formatCurrency } from "../../../utils/formats";
import invoicesService from "../../../services/invoices.service";
import LoadingSpinner from "../../../components/Common/Loader";
import Chart from "../../../components/Chart";

type InvoiceVsPaidProps = {
    hideHeading?: boolean;
}
const InvoiceVsPaid: FC<InvoiceVsPaidProps> = ({ hideHeading = false }) => {

    const { t } = useTranslation();

    const [loadingText, setLoadingText] = useState<string>('Loading...');
    const [loading, setLoading] = useState<boolean>(true);

    const [data, setData] = useState({
        amount_due: 0,
        paid_amount: 0,
        tax_amount: 0,
        total: 0,
        subtotal: 0
    })

    const [cData, setCData] = useState<any>({
        series: [70],
        options: {
            chart: {
                offsetY: -20,
                width: 130,
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        size: '60%',
                        margin: 1,
                        imageHeight: 80,
                        background: '#fff',
                    },
                    track: {
                        background: '#cccccc',
                        strokeWidth: '100%',
                        margin: 0, // margin is in pixels
                    },
                    dataLabels: {
                        show: false,
                    },
                },
            },
            colors: ['#564ab1', '#f1b44c'],
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'dark',
                    shadeIntensity: 0.15,
                    inverseColors: false,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 50, 65, 91]
                },
            },
            labels: ['C'],
        },
    })
    const [c2Data, setC2Data] = useState<any>({
        series: [70],
        options: {
            chart: {
                offsetY: -20,
                width: 130,
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        size: '60%',
                        margin: 1,
                        imageHeight: 80,
                    },
                    track: {
                        background: '#cccccc',
                        strokeWidth: '100%',
                        margin: 0, // margin is in pixels
                    },
                    dataLabels: {
                        show: false,
                    },
                },
            },
            colors: ['#f1b44c'],
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'dark',
                    shadeIntensity: 0.15,
                    inverseColors: false,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 50, 65, 91]
                },
            },
            labels: ['C'],
        },
    })
    const [c3Data, setC3Data] = useState<any>({
        series: [70],
        options: {
            chart: {
                offsetY: -20,
                width: 130,
            },
            plotOptions: {
                radialBar: {
                    hollow: {
                        size: '60%',
                        margin: 1,
                        imageHeight: 80,
                    },
                    track: {
                        background: '#cccccc',
                        strokeWidth: '100%',
                        margin: 0, // margin is in pixels
                    },
                    dataLabels: {
                        show: false,
                    },
                },
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'dark',
                    shadeIntensity: 0.15,
                    inverseColors: false,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 50, 65, 91]
                },
            },
            labels: ['C'],
        },
    })

    const getAnalyticsData = async () => {
        setLoadingText(t("Loading..."));
        setLoading(true);
        const res = await invoicesService.getAnalytics();
        if (res.status === 'success') {

            setData(res.data);

            setCData((state: any) => ({ ...state, series: [(res.data.paid_amount / res.data.total) * 100] }))
            setC2Data((state: any) => ({ ...state, series: [(res.data.tax_amount / res.data.total) * 100] }))
            setC3Data((state: any) => ({ ...state, series: [(res.data.amount_due / res.data.total) * 100] }))
        }
        setLoadingText(t("No data found"));
        setLoading(false);

    }

    useEffect(() => {
        getAnalyticsData()
    }, [])

    return (
        loading ? <LoadingSpinner height={355} /> :
            <>
                <ListGroup className="list-group-flush" style={{ height: '355px' }}>
                    <ListGroupItem className="chart-list-item">
                        <Row>
                            <Col className="col-7">
                                <CardTitle tag="h5" className="mb-3">{t("Invoiced vs Paid")}</CardTitle>
                                <CardSubtitle className="text-muted mb-1">{t("Invoiced amount")}: {formatCurrency(data.total)}</CardSubtitle>
                                <CardSubtitle className="text-muted mb-1">{t("Paid amount")}: {formatCurrency(data.paid_amount)}</CardSubtitle>
                            </Col>
                            <Col className="col-3">
                                <Chart options={cData.options} series={cData.series} type="radialBar" height={140} />
                            </Col>
                            <Col className="col-2">
                                <p className="text-muted mt-3 mb-1">{t("Paid")}</p>
                                <h5 className="mb-0">{isNaN(cData.series[0]) ? 0 : parseInt(cData.series[0], 10)}%</h5>
                            </Col>
                        </Row>
                    </ListGroupItem>
                    <ListGroupItem className="chart-list-item">
                        <Row>
                            <Col className="col-7">
                                <CardTitle tag="h5" className="mb-3">{t("Total amount vs VAT")}</CardTitle>
                                <CardSubtitle className="text-muted mb-1">{t("Total amount")}: {formatCurrency(data.total)}</CardSubtitle>
                                <CardSubtitle className="text-muted mb-1">{t("Total VAT")}: {formatCurrency(data.tax_amount)}</CardSubtitle>
                            </Col>
                            <Col className="col-3">
                                <Chart options={c2Data.options} series={c2Data.series} type="radialBar" height={140} />
                            </Col>
                            <Col className="col-2">
                                <p className="text-muted mt-3 mb-1">{t("VAT")}</p>
                                <h5 className="mb-0">{isNaN(c2Data.series[0]) ? 0 : parseInt(c2Data.series[0], 10)}%</h5>
                            </Col>
                        </Row>
                    </ListGroupItem>
                    <ListGroupItem className="chart-list-item">
                        <Row>
                            <Col className="col-7">
                                <CardTitle tag="h5" className="mb-3">{t("Invoiced vs Amount due")}</CardTitle>
                                <CardSubtitle className="text-muted mb-1">{t("Invoiced amount")}: {formatCurrency(data.total)}</CardSubtitle>
                                <CardSubtitle className="text-muted mb-1">{t("Amount due")}: {formatCurrency(data.amount_due)}</CardSubtitle>
                            </Col>
                            <Col className="col-3">
                                <Chart options={c3Data.options} series={c3Data.series} type="radialBar" height={140} />
                            </Col>
                            <Col className="col-2">
                                <p className="text-muted mt-3 mb-1">{t("Due")}</p>
                                <h5 className="mb-0">{isNaN(c3Data.series[0]) ? 0 : parseInt(c3Data.series[0], 10)}%</h5>
                            </Col>
                        </Row>
                    </ListGroupItem>
                </ListGroup>
            </>
    )
}

export default InvoiceVsPaid;