import { takeEvery, call, put } from "redux-saga/effects";
import commonService from "../../services/common.service";
import { AnyAction } from "redux";

// Users Redux States
import {
  GET_NOTIFICATION_COUNT,
  GET_USER_OPTIONS_LIST,
  GET_USER_OPTIONS_LIST_NEW,
  GET_COMPANY_OPTIONS_LIST,
  GET_COMPANY_OPTIONS_LIST_NEW,
  GET_FEES_OPTIONS_LIST,
  GET_FEES_OPTIONS_LIST_NEW,
  CHANGE_TABLE_ORDER_ON_DRAG,
} from "./actionTypes";

import {
  getNotificationCountSuccess,
  getNotificationCountError,
  getUserOptionsListSuccess,
  getUserOptionsListError,
  getUserOptionsListSuccess_New,
  getUserOptionsListError_New,
  getCompanyOptionsListSuccess,
  getCompanyOptionsListError,
  getCompanyOptionsListSuccess_New,
  getCompanyOptionsListError_New,
  getFeesOptionsListSuccess,
  getFeesOptionsListError,
  getFeesOptionsListSuccess_New,
  getFeesOptionsListError_New,
  changeOrderonDragSuccess,
  changeOrderonDragError
} from "./actions";

function* getNotificationCount(): any {
  try {
    const response = yield call(commonService.getNotificationCount);
    if (response.data && response.data.status === "success") {
      yield put(getNotificationCountSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getNotificationCountError(response.data));
    } else {
      yield put(getNotificationCountError(response));
    }
  } catch (error: any) {
    yield put(getNotificationCountError(error));
  }
}

function* getUserOptionsList({ payload: { otherFields, history } }: AnyAction): any {
  try {
    const response = yield call(commonService.getUserOptionsList, otherFields);
    if (response.data && response.data.status === "success") {
      yield put(getUserOptionsListSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getUserOptionsListError(response.data));
    } else {
      yield put(getUserOptionsListError(response));
    }
  } catch (error: any) {
    yield put(getUserOptionsListError(error));
  }
}
function* getUserOptionsList_New({ payload: { otherFields, history } }: AnyAction): any {
  try {
    const response = yield call(commonService.getUserOptionsList_New, otherFields);
    if (response.data && response.data.status === "success") {
      yield put(getUserOptionsListSuccess_New(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getUserOptionsListError_New(response.data));
    } else {
      yield put(getUserOptionsListError_New(response));
    }
  } catch (error: any) {
    yield put(getUserOptionsListError_New(error));
  }
}

function* getCompanyOptionsList({ payload: { otherFields, history } }: AnyAction): any {
  try {
    const response = yield call(commonService.getCompanyOptionsList, otherFields);
    if (response.data && response.data.status === "success") {
      yield put(getCompanyOptionsListSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getCompanyOptionsListError(response.data));
    } else {
      yield put(getCompanyOptionsListError(response));
    }
  } catch (error: any) {
    yield put(getCompanyOptionsListError(error));
  }
}
function* getCompanyOptionsList_New({ payload: { otherFields, history } }: AnyAction): any {
  try {
    const response = yield call(commonService.getCompanyOptionsList_New, otherFields);
    if (response.data && response.data.status === "success") {
      yield put(getCompanyOptionsListSuccess_New(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getCompanyOptionsListError_New(response.data));
    } else {
      yield put(getCompanyOptionsListError_New(response));
    }
  } catch (error: any) {
    yield put(getCompanyOptionsListError_New(error));
  }
}

function* getFeesOptionsList(): any {
  try {
    const response = yield call(commonService.getFeesOptionsList);
    if (response.data && response.data.status === "success") {
      yield put(getFeesOptionsListSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getFeesOptionsListError(response.data));
    } else {
      yield put(getFeesOptionsListError(response));
    }
  } catch (error: any) {
    yield put(getFeesOptionsListError(error));
  }
}
function* getFeesOptionsList_New(): any {
  try {
    const response = yield call(commonService.getFeesOptionsList_New);
    if (response.data && response.data.status === "success") {
      yield put(getFeesOptionsListSuccess_New(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getFeesOptionsListError_New(response.data));
    } else {
      yield put(getFeesOptionsListError_New(response));
    }
  } catch (error: any) {
    yield put(getFeesOptionsListError_New(error));
  }
}

function* changeOrderonDrag({ payload: {tableName, fromPosition, toPosition, dependentField, dependentId} }: AnyAction): any {
  try {
    const response = yield call(commonService.changeDragPosition, tableName, fromPosition, toPosition, dependentField, dependentId);
    if (response.data && response.data.status === "success") {
      yield put(changeOrderonDragSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(changeOrderonDragError(response.data));
    } else {
      yield put(changeOrderonDragError(response));
    }
  } catch (error: any) {
    yield put(getFeesOptionsListError(error));
  }
}

function* usersSaga(): any {
  yield takeEvery(GET_NOTIFICATION_COUNT, getNotificationCount);
  yield takeEvery(GET_USER_OPTIONS_LIST, getUserOptionsList);
  yield takeEvery(GET_USER_OPTIONS_LIST_NEW, getUserOptionsList_New);
  yield takeEvery(GET_COMPANY_OPTIONS_LIST, getCompanyOptionsList);
  yield takeEvery(GET_COMPANY_OPTIONS_LIST_NEW, getCompanyOptionsList_New);
  yield takeEvery(GET_FEES_OPTIONS_LIST, getFeesOptionsList);
  yield takeEvery(GET_FEES_OPTIONS_LIST_NEW, getFeesOptionsList_New);
  yield takeEvery(CHANGE_TABLE_ORDER_ON_DRAG, changeOrderonDrag);
}

export default usersSaga;
