import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper";
import * as url from "../helpers/url_helper";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class NotificationService {
  async getNotificationCount() {
    const response = await axios
      .get(SERVER_URL + url.GET_NOTIFICATION_COUNT, {
        headers: getAxiosConfigWithToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async getNotificationList(DATA: any) {
    const response = await axios
      .post(SERVER_URL + url.GET_NOTIFICATION_LIST, DATA, {
        headers: getAxiosConfigWithToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async getNotificationsList(DATA: any) {
    const response = await axios
      .post(SERVER_URL + url.GET_NOTIFICATIONS_LIST, DATA, {
        headers: getAxiosConfigWithToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async setNotificationReadUnread(DATA: any) {
    const response = await axios
      .get(
        SERVER_URL +
          url.SET_NOTIFICATION_READ_UNREAD +
          "/" +
          DATA.id +
          "/" +
          DATA.is_read,
        {
          headers: getAxiosConfigWithToken(),
        }
      )
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async setNotificationReadAll() {
    const response = await axios
      .get(
        SERVER_URL +
          url.SET_NOTIFICATION_READ_ALL,
        {
          headers: getAxiosConfigWithToken(),
        }
      )
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async updateNotificationStatus(DATA: any) {
    const response = await axios
      .get(
        SERVER_URL +
          url.UPDATE_NOTIFICATION_STATUS +
          "/" +
          DATA.module_app_id +
          "/" +
          DATA.module_main_id +
          "/" +
          DATA.module_sub_id,
        {
          headers: getAxiosConfigWithToken(),
        }
      )
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }
}

export default new NotificationService();
