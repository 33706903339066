import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  Label,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { initialValues } from "./helper";
import {
  getSelectOptions,
  getFirstErrorElement,
  filterCountryByLanguage,
} from "../../../helpers/functions_helper";
import { useTranslation } from "react-i18next";

const SplitPayrollCountryForm = (props: any) => {
  const { t } = useTranslation();
  const formData = props.formData;
  const [initialData, setInitialData] = useState(initialValues);
  const [loadingCountry, setLoadingCountry] = useState(true);
  const [countryOptions, setCountryOptions] = useState([]);
  const [loadingTaxYear, setLoadingTaxYear] = useState(true);
  const [taxYearOptions, setTaxYearOptions] = useState([]);

  Yup.setLocale({
    mixed: {
      required: "${label} " + t("field is required."),
    },
  });
  const validationSchema = Yup.object().shape({
    country_id: Yup.string().label(t("Country")).required(),
    tax_year: Yup.string().label(t("Tax year")).required(),
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleChange = (fieldName: string, fieldValue: string) => {
    setInitialData({ ...initialData, [fieldName]: fieldValue });
  };

  const handleForeignTaxLiability = () => {
    if (initialData.is_foreign_tax_liable === 1) {
      setInitialData((prevData: any) => ({
        ...prevData,
        is_foreign_tax_liable: 0,
      }));
    } else {
      setInitialData((prevData: any) => ({
        ...prevData,
        is_foreign_tax_liable: 1,
      }));
    }
  };

  const handleSubmit = (values: formTypes_SplitPayrollCountry) => {
    props.onSubmitClick(values);
  };

  useEffect(() => {
    setInitialData(formData);
  }, [formData]);

  useEffect(() => {
    getSelectOptions("tbl_master_country", "name", "", ["name", "name_french", "name_dutch"]).then(
      function (options) {
        let countryOptionsList = filterCountryByLanguage(options);
        setCountryOptions(countryOptionsList);
        setLoadingCountry(false);
      }
    );

    getSelectOptions("tbl_split_payroll_tax_year_option", "tax_year_option_name").then(function (
      options
    ) {
      if (options) {
        const lov = options?.map((option: any) => ({
          label: t(option.label),
          value: option.value,
        }));
        setTaxYearOptions(lov);
        setLoadingTaxYear(false);
      }
    });
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  useEffect(() => {
    if (!validation.isSubmitting) return;
    if (Object.keys(validation.errors).length > 0) {
      const element = getFirstErrorElement(validationSchema, validation);
      document.getElementById(element)?.scrollIntoView({ behavior: "smooth", block: "center" });
      document.getElementById(element)?.focus({ preventScroll: true });
    }
  }, [validation.isSubmitting, validation.errors]);

  return (
    <React.Fragment>
      <Modal
        size="md"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">{`${props.id ? t("Update") : t("Create")} ${t(
            "split payroll"
          )} ${t("country")}`}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label={t("Close")}
          ></button>
        </div>

        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          autoComplete="off"
        >
          <ModalBody>
            <Input id="id" name="id" type="hidden" value={initialData.id || ""} />
            <Input
              id="split_payroll_country_id"
              name="split_payroll_country_id"
              type="hidden"
              value={initialData.split_payroll_country_id || ""}
            />

            <Row>
              <Col xl={12} lg={12} md={12} sm={12}>
                <div className="mb-3">
                  <Label className="form-label">
                    {t("Country")} <span className="text-warning is_required">*</span>
                  </Label>
                  <Select
                    id="country_id"
                    name="country_id"
                    className="is-invalid"
                    options={countryOptions}
                    value={
                      initialData.country_id
                        ? countryOptions.find(
                            (option: any) => option.value == initialData.country_id
                          )
                        : ""
                    }
                    isSearchable={true}
                    isDisabled={loadingCountry}
                    isLoading={loadingCountry}
                    onBlur={() => validation.setFieldTouched("country_id", true)}
                    onChange={(e: any) => {
                      handleChange("country_id", e.value);
                    }}
                  />
                  {validation.errors.country_id ? (
                    <FormFeedback type="invalid">{validation.errors.country_id}</FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col xl={12} lg={12} md={12} sm={12}>
                <div className="mb-3">
                  <Label className="form-label">
                    {t("Tax year")} <span className="text-warning is_required">*</span>
                  </Label>
                  <Select
                    id="tax_year"
                    name="tax_year"
                    className="is-invalid"
                    options={taxYearOptions}
                    value={
                      initialData.tax_year
                        ? taxYearOptions.find((option: any) => option.value == initialData.tax_year)
                        : ""
                    }
                    isSearchable={true}
                    isDisabled={loadingTaxYear}
                    isLoading={loadingTaxYear}
                    onBlur={() => validation.setFieldTouched("tax_year", true)}
                    onChange={(e: any) => {
                      handleChange("tax_year", e.value);
                    }}
                  />
                  {validation.errors.tax_year ? (
                    <FormFeedback type="invalid">{validation.errors.tax_year}</FormFeedback>
                  ) : null}
                </div>
              </Col>

              <Col xl={12} lg={12} md={12} sm={12}>
                <div className="form-check mt-2 mb-3">
                  <input
                    id="is_foreign_tax_liable"
                    name="is_foreign_tax_liable"
                    className="form-check-input"
                    type="checkbox"
                    value={initialData.is_foreign_tax_liable}
                    checked={initialData.is_foreign_tax_liable === 1 ? true : false}
                    onBlur={validation.handleBlur}
                    onChange={handleForeignTaxLiability}
                  />
                  <label className="form-check-label" htmlFor="is_foreign_tax_liable">
                    {t("Foreign tax liability")}
                  </label>
                </div>
              </Col>

              <Col xl={12} lg={12} md={12} sm={12}>
                <div className="mb-3">
                  <Label className="form-label">{t("Default social security %")}</Label>
                  <Input
                    id="default_social_security_percentage"
                    name="default_social_security_percentage"
                    className="form-control"
                    type="number"
                    value={initialData.default_social_security_percentage || ""}
                    min={0}
                    max={100}
                    step={0.01}
                    onBlur={validation.handleBlur}
                    onChange={(e) =>
                      handleChange("default_social_security_percentage", e.target.value)
                    }
                    invalid={validation.errors.default_social_security_percentage ? true : false}
                  />
                  {validation.errors.default_social_security_percentage ? (
                    <FormFeedback type="invalid">
                      {validation.errors.default_social_security_percentage}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" className="btn-block" type="submit" disabled={props.loading}>
              {props.loading ? (
                <span>
                  <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                    hourglass_empty
                  </span>
                  <span>{t("Please wait...")}</span>
                </span>
              ) : (
                <span>{t("Submit")}</span>
              )}
            </Button>
            <Button
              color="danger"
              className="btn-block"
              type="reset"
              onClick={props.onCloseClick}
              disabled={props.loading}
            >
              {t("Cancel")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default SplitPayrollCountryForm;
