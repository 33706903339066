import { SEND_QUESTIONNAIRES_TASK } from "../../helpers/url_helper"
import {
    GET_QUESTIONNAIRE,
    GET_QUESTIONNAIRE_FAIL,
    GET_QUESTIONNAIRE_SUCCESS,
    ADD_NEW_QUESTIONNAIRE,
    ADD_QUESTIONNAIRE_SUCCESS,
    ADD_QUESTIONNAIRE_FAIL,
    DELETE_QUESTIONNAIRE,
    DELETE_QUESTIONNAIRE_SUCCESS,
    DELETE_QUESTIONNAIRE_FAIL,
    GET_QUESTIONNAIRE_LIST,
    GET_QUESTIONNAIRE_LIST_SUCCESS,
    GET_QUESTIONNAIRE_LIST_FAIL,
    GET_QUESTIONNAIRE_FULL_DATA,
    GET_QUESTIONNAIRE_FULL_DATA_SUCCESS,
    GET_QUESTIONNAIRE_FULL_DATA_FAIL,
    SEND_QUESTIONNAIRE_FROM_TASK_SUCCESS,
    SEND_QUESTIONNAIRE_FROM_TASK_ERROR,
    SEND_QUESTIONNAIRE_FROM_TASK,
} from "./actiontypes"


export const getQuestionnaire = (query: any, history: any) => ({
    type: GET_QUESTIONNAIRE,
    payload: {query, history},
})

export const getQuestionnaireSuccess = (data : any) => ({
    type: GET_QUESTIONNAIRE_SUCCESS,
    payload: data,
})

export const getQuestionnaireFail = (data : any) => ({
    type: GET_QUESTIONNAIRE_FAIL,
    payload: data,
})


export const getQuestionnairelist = (data: any) => ({
  type: GET_QUESTIONNAIRE_LIST,
  payload: data,
})

export const getQuestionnairelistSuccess = (data : any) => ({
  type: GET_QUESTIONNAIRE_LIST_SUCCESS,
  payload: data,
})

export const getQuestionnairelistFail = (data : any) => ({
  type: GET_QUESTIONNAIRE_LIST_FAIL,
  payload: data,
})


export const addNewQuestionnaire= (data : any) => ({
    type: ADD_NEW_QUESTIONNAIRE,
    payload: data,
})

export const addQuestionnaireSuccess = (data : any) => ({
    type: ADD_QUESTIONNAIRE_SUCCESS,
    payload: data,
})

export const addQuestionnaireFail = (error: any )=> ({
    type: ADD_QUESTIONNAIRE_FAIL,
    payload: error,
})



// ---------------------------------------------DELETE_COMPANY
export const deleteQuestionnaire = (questionnaire: any, history: any) => {
    return {
      type: DELETE_QUESTIONNAIRE,
      payload: { questionnaire, history },
    };
  };
  
  export const deleteQuestionnaireSuccess = (questionnaire: {}) => {
    return {
      type: DELETE_QUESTIONNAIRE_SUCCESS,
      payload: questionnaire,
    };
  };
  
  export const deleteQuestionnaireError = (error: {}) => {
    return {
      type: DELETE_QUESTIONNAIRE_FAIL,
      payload: error,
    };
  };
  

// Questionnaire full data
export const getQuestionnaireFulldata = (data: any) => {
  return {
    type: GET_QUESTIONNAIRE_FULL_DATA,
    payload: { data },
  };
};

export const getQuestionnaireFulldataSuccess = (data: {}) => {
  return {
    type: GET_QUESTIONNAIRE_FULL_DATA_SUCCESS,
    payload: data,
  };
};

export const getQuestionnaireFulldataFail = (error: any) => {
  return {
    type: GET_QUESTIONNAIRE_FULL_DATA_FAIL,
    payload: error,
  };
};

// Send Questionnaire from Task
export const sendQuestionnaireFromTask = (data: any) => {
  return {
    type: SEND_QUESTIONNAIRE_FROM_TASK,
    payload: { data },
  };
};

export const sendQuestionnaireFromTaskSuccess = (data: {}) => {
  return {
    type: SEND_QUESTIONNAIRE_FROM_TASK_SUCCESS,
    payload: data,
  };
};

export const sendQuestionnaireFromTaskFail = (error: any) => {
  return {
    type: SEND_QUESTIONNAIRE_FROM_TASK_ERROR,
    payload: error,
  };
};
