import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Button, CardHeader } from "reactstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../../components/Layout/Breadcrumb";
import DataTable from "../../components/Common/DataTable";
import DeleteModal from "../../components/Common/DeleteModal";

import Filters from "./Filters";
import Drawer from "../../components/Drawer";
import EditCompany from "../Masters/Company/EditCompany";
import EditUser from "../Users/EditUser";

import {
  dateTimeString,
  getDataTableQuery,
  getUserProfile,
  handleFilters,
} from "../../helpers/functions_helper";
import taskService from "../../services/tasks.service";
import NavItems from "./NavItemsList";
import { formatCurrency } from "../../utils/formats";
import {
  masterValueWithColorCode,
  secondsToTime,
  timeToSeconds,
  yesNoTag,
} from "../../utils/utils";
import fileDownload from "js-file-download";

const ArchiveTasks = (props: any) => {
  const { t } = useTranslation();
  document.title = `${t("Archived task")} - ${t("List view")} | ${process.env.REACT_APP_NAME}`;

  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "#",
      label: t("Tasks"),
      active: true,
    },
  ];

  const userProfile = getUserProfile("all-tasks");
  const timesheetProfile = getUserProfile("all-time-sheets");
  const savedfilters = handleFilters("GET");
  const toastId = React.useRef(null) as any;
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingListData, setLoadingListData] = useState(true);
  const [loadingExport, setLoadingExport] = useState(false);
  const [datatableListData, setDatatableListData] = useState([]);
  const [deleteWhere, setDeleteWhere] = useState({
    id: "",
    task_id: "",
  });
  const [recordName, setRecordName] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedData, setSelectedData] = useState<any>();
  const [openCompany, setOpenCompany] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any>([]);
  const [openUser, setOpenUser] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const onClickDelete = (row: any) => {
    const data = {
      ...deleteWhere,
      id: row.id,
      task_id: row.task_id,
    };
    setDeleteWhere(data);
    setRecordName(row.title);
    setDeleteModal(true);
  };

  const handleDelete = () => {
    toastId.current = toast(`${t("Deleting")} ${t("task")}...`, {
      autoClose: false,
      isLoading: true,
    });
    deleteRecord(deleteWhere);
    setDeleteModal(false);
  };

  const deleteRecord = async (data: any) => {
    try {
      const res = (await taskService.deleteTask(data)) as any;
      if (res.data.status === "success") {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoadingListData(true);
        setLoading(false);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoading(false);
      }
    } catch (err: any) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }
  };

  const handleCompanyClick = (data: any) => {
    setSelectedData(data);
    setOpenCompany(true);
  };

  const handleUserClick = (data: any, type: number) => {
    switch (type) {
      case 1:
        setSelectedUser({
          id: data.contact_person,
          user_id: data.contact_person_user_id,
        });
        setOpenUser(true);
        break;

      case 2:
        setSelectedUser({
          id: data.responsible_person,
          user_id: data.responsible_user_id,
        });
        setOpenUser(true);
        break;
    }
  };

  function renderRowHTML(rowContent: any, row: any) {
    return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
  }
  const columns = [
    {
      dataField: "DT_RowIndex",
      text: t("Sr. No."),
      sort: false,
      search: false,
      classes: "fix-col-left",
      headerClasses: "fix-col-left",
      headerStyle: { width: "155px" },
      style: { width: "155px" },
      footer: t("Total"),
    },
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: true,
    //   search: true,
    //   footer: "",
    // },
    {
      dataField: "type_value_html",
      text: t("Task type"),
      sort: true,
      search: true,
      formatter: renderRowHTML,
      hidden: !userProfile.IS_INTERNAL,
      footer: "",
    },
    {
      dataField: "category_of_fees",
      text: t("Category of task"),
      sort: true,
      search: true,
      hidden: !userProfile.IS_INTERNAL,
      footer: "",
    },
    {
      dataField: "fees_code",
      text: t("Task code"),
      sort: true,
      search: true,
      hidden: !userProfile.IS_INTERNAL,
      footer: "",
    },
    {
      dataField: "fees_amount",
      text: t("Task amount"),
      sort: true,
      search: true,
      align: "right",
      hidden: true,
      formatter: (rowContent: any, row: any): any => {
        return formatCurrency(row.fees_amount);
      },
      footer: (columnData: any) =>
        formatCurrency(
          columnData.reduce((total: number, fees_amount: number) => total + Number(fees_amount), 0)
        ),
      footerAlign: (column: any, colIndex: number) => "right",
    },
    {
      dataField: "subtask_fees_amount",
      text: t("Total subtask amount"),
      sort: true,
      search: true,
      align: "right",
      hidden: true,
      formatter: (rowContent: any, row: any): any => {
        return formatCurrency(row.subtask_fees_amount);
      },
      footer: (columnData: any) =>
        formatCurrency(
          columnData.reduce(
            (total: number, subtask_fees_amount: number) => total + Number(subtask_fees_amount),
            0
          )
        ),
      footerAlign: (column: any, colIndex: number) => "right",
    },
    {
      dataField: "total_amount",
      text: t("Total amount"),
      sort: true,
      search: true,
      align: "right",
      hidden: !userProfile.CAN_VIEW_FINANCIAL,
      formatter: (rowContent: any, row: any): any => {
        return formatCurrency(row.total_amount);
      },
      footer: (columnData: any) =>
        formatCurrency(
          columnData.reduce(
            (total: number, total_amount: number) => total + Number(total_amount),
            0
          )
        ),
      footerAlign: (column: any, colIndex: number) => "right",
    },
    // {
    //   dataField: "category",
    //   text: t("Task category"),
    //   sort: true,
    //   search: true,
    //   hidden: !userProfile.IS_INTERNAL,
    //   footer: "",
    // },
    {
      dataField: "expense",
      text: t("Task expense"),
      sort: true,
      search: true,
      align: "right",
      hidden: true, // !userProfile.IS_INTERNAL,
      formatter: (rowContent: any, row: any): any => {
        return formatCurrency(row.expense);
      },
      footer: (columnData: any) =>
        formatCurrency(
          columnData.reduce((total: number, expense: number) => total + Number(expense), 0)
        ),
      footerAlign: (column: any, colIndex: number) => "right",
    },
    {
      dataField: "subtask_expense",
      text: t("Subtask expense"),
      sort: true,
      search: true,
      align: "right",
      hidden: true, // !userProfile.IS_INTERNAL,
      formatter: (rowContent: any, row: any): any => {
        return formatCurrency(row.subtask_expense);
      },
      footer: (columnData: any) =>
        formatCurrency(
          columnData.reduce(
            (total: number, subtask_expense: number) => total + Number(subtask_expense),
            0
          )
        ),
      footerAlign: (column: any, colIndex: number) => "right",
    },
    {
      dataField: "total_expense",
      text: t("Total expense"),
      sort: true,
      search: true,
      align: "right",
      hidden: !userProfile.IS_INTERNAL,
      formatter: (rowContent: any, row: any): any => {
        return formatCurrency(row.total_expense);
      },
      footer: (columnData: any) =>
        formatCurrency(
          columnData.reduce(
            (total: number, total_expense: number) => total + Number(total_expense),
            0
          )
        ),
      footerAlign: (column: any, colIndex: number) => "right",
    },
    {
      dataField: "time_spent",
      text: t("Total time spent"),
      sort: true,
      search: true,
      align: "right",
      hidden: userProfile.IS_INTERNAL && timesheetProfile.CAN_VIEW ? false : true,
      footer: (columnData: any) => {
        return secondsToTime(
          columnData.reduce(
            (total: number, time_spent: string) => total + timeToSeconds(time_spent),
            0
          )
        );
      },
      footerAlign: (column: any, colIndex: number) => "right",
    },
    {
      dataField: "service_charge",
      text: t("Consultant's service Charges"),
      sort: false,
      search: false,
      align: "right",
      hidden: !userProfile.CAN_VIEW_FINANCIAL,
      formatter: (rowContent: any, row: any): any => {
        return formatCurrency(row.service_charge);
      },
      footer: (columnData: any) =>
        formatCurrency(
          columnData.reduce((total: number, service_charge: number) => total + service_charge, 0)
        ),
      footerAlign: (column: any, colIndex: number) => "right",
    },
    {
      dataField: "company_name",
      text: t("Client"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return !userProfile.IS_INTERNAL ? (
          <span>{row.company_name}</span>
        ) : (
          <>
            {row.company === 0 ? (
              <span>{row.company_name}</span>
            ) : (
              <span className="link-primary" onClick={() => handleCompanyClick(row)}>
                {row.company_name}
              </span>
            )}
          </>
        );
      },
      footer: "",
    },
    {
      dataField: "project_name",
      text: t("Project"),
      sort: true,
      search: true,
      hidden: !userProfile.IS_INTERNAL,
      formatter: (rowContent: any, row: any): any => {
        return (
          <span>
            {userProfile.USER_COMPANY === 1 && userProfile.IS_ADMIN === 1 ? (
              <Link
                className="link-primary"
                to={"/masters/company/projects/" + row.company + "/" + row.company_id}
              >
                {row?.project_name}
              </Link>
            ) : (
              row?.project_name
            )}
          </span>
        );
      },
      footer: "",
    },
    {
      dataField: "contact_person_value",
      text: t("Contact person"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return !userProfile.IS_INTERNAL ? (
          <span>{row.contact_person_value}</span>
        ) : (
          <div className="link-primary" onClick={() => handleUserClick(row, 1)}>
            {row.contact_person_value}
          </div>
        );
      },
      footer: "",
    },
    {
      dataField: "responsible_person_value",
      text: t("Person concerned"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return (
          <div className="link-primary" onClick={() => handleUserClick(row, 2)}>
            {row.responsible_person_value}
          </div>
        );
      },
      footer: "",
    },
    {
      dataField: "custome_code",
      text: t("Task ID"),
      sort: true,
      search: true,
      hidden: !userProfile.IS_INTERNAL,
      footer: "",
    },
    {
      dataField: "title",
      text: t("Task title"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return (
          <span>
            <Link
              className="text-decoration-underline"
              to={"/tasks/task-details/" + row.id + "/" + row.task_id}
            >
              {renderRowHTML(row.title_html, null)}
            </Link>
          </span>
        );
      },
      footer: "",
    },
    {
      dataField: "preparer_value",
      text: t("Preparer"),
      sort: true,
      search: true,
      hidden: !userProfile.IS_INTERNAL,
      footer: "",
    },
    {
      dataField: "reviewer_value",
      text: t("Reviewer"),
      sort: true,
      search: true,
      hidden: !userProfile.IS_INTERNAL,
      footer: "",
    },
    {
      dataField: "file_manager_value",
      text: userProfile.USER_COMPANY === 1 ? t("Responsible to sign off") : t("File manager"),
      sort: true,
      search: true,
      footer: "",
    },
    {
      dataField: "partner_value",
      text: t("Partner"),
      sort: true,
      search: true,
      hidden: !userProfile.IS_INTERNAL,
      footer: "",
    },
    {
      dataField: "assistant_administrative_value",
      text: t("Assistant administrative"),
      sort: true,
      search: true,
      hidden: !userProfile.IS_INTERNAL,
      footer: "",
    },
    {
      dataField: "assignee_value",
      text: t("Responsible person for sending"),
      sort: true,
      search: true,
      hidden: !userProfile.IS_INTERNAL,
      footer: "",
    },
    {
      dataField: "start_date_value",
      text: t("Start date"),
      sort: true,
      search: true,
      hidden: !userProfile.IS_INTERNAL,
      footer: "",
    },
    {
      dataField: "due_date_value",
      text: t("Internal due date"),
      sort: true,
      search: true,
      hidden: !userProfile.IS_INTERNAL,
      footer: "",
    },
    {
      dataField: "closing_date_value",
      text: t("Due date"),
      sort: true,
      search: true,
      hidden: !userProfile.IS_INTERNAL,
      footer: "",
    },
    {
      dataField: "priority_value",
      text: t("Task priority"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return renderRowHTML(
          masterValueWithColorCode(t(row?.priority_value), row?.priority_color_code),
          null
        );
      },
      hidden: !userProfile.IS_INTERNAL,
      footer: "",
    },
    {
      dataField: "status_value",
      text: t("Task status"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return renderRowHTML(
          masterValueWithColorCode(t(row?.status_value), row?.status_color_code),
          null
        );
      },
      hidden: !userProfile.IS_INTERNAL,
      footer: "",
    },
    {
      dataField: "progress_value_html",
      text: t("Task progress"),
      sort: true,
      search: true,
      formatter: renderRowHTML,
      hidden: !userProfile.IS_INTERNAL,
      footer: "",
    },

    {
      dataField: "income_year",
      text: t("Income year"),
      sort: true,
      search: true,
      align: "right",
      footer: "",
    },
    {
      dataField: "preliminary_billing",
      text: t("Preliminary billing"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return renderRowHTML(
          yesNoTag(row?.preliminary_billing, row?.preliminary_billing === 1 ? t("Yes") : t("No")),
          null
        );
      },
      hidden: !userProfile.IS_INTERNAL,
      footer: "",
    },
    {
      dataField: "preliminary_percentage",
      text: `${t("Preliminary billing")} %`,
      sort: true,
      search: true,
      align: "right",
      hidden: !userProfile.IS_INTERNAL,
      footer: "",
    },
    {
      dataField: "action",
      text: t("Action"),
      sort: false,
      search: false,
      formatter: (rowContent: any, row: any): any => {
        return (
          <div className="d-flex gap-2 text-end">
            {userProfile.IS_INTERNAL &&
            (userProfile.USER_ID === parseInt(row?.created_by) ||
              userProfile.USER_ID === parseInt(row?.preparer) ||
              userProfile.USER_ID === parseInt(row?.reviewer) ||
              userProfile.USER_ID === parseInt(row?.file_manager) ||
              userProfile.USER_ID === parseInt(row?.partner) ||
              userProfile.USER_ID === parseInt(row?.assistant_administrative) ||
              userProfile.USER_ID === parseInt(row?.assignee) ||
              userProfile.CAN_EDIT_ALL) ? (
              <Link
                className="btn btn-pill btn-outline-primary btn-sm"
                to={"/tasks/task-details/" + row.id + "/" + row.task_id}
              >
                <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                  edit
                </span>
                {t("Edit")}
              </Link>
            ) : (
              <Link
                className="btn btn-pill btn-outline-primary btn-sm"
                to={"/tasks/task-details/" + row.id + "/" + row.task_id}
              >
                <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                  visibility
                </span>
                {t("View")}
              </Link>
            )}
            {((userProfile.CAN_DELETE && userProfile.USER_ID === parseInt(row.created_by)) ||
              userProfile.CAN_DELETE_ALL) &&
            (row.status === 1 || row.status === 7) &&
            parseInt(row?.preliminary_billing) != 1 ? ( // TO DO or CANCELLED
              <Link
                className="btn btn-pill btn-outline-danger btn-sm"
                to="#"
                onClick={() => onClickDelete(row)}
              >
                <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                  delete
                </span>
                {t("Delete")}
              </Link>
            ) : null}
          </div>
        );
      },
      footer: "",
    },
  ];

  const [datatableOptions, setDatatableOptions] = useState(
    savedfilters && savedfilters?.datatableOptions
      ? (savedfilters?.datatableOptions as any)
      : {
          draw: 1,
          page: 1,
          recordsTotal: 0,
          recordsFiltered: 0,
          sizePerPage: 20,
          searchText: "",
          sortField: "id",
          sortOrder: "desc",
        }
  );
  const [filters, setFilters] = useState(
    savedfilters && savedfilters?.filters
      ? (savedfilters?.filters as any)
      : {
          is_filtered: false,
          company_id: [],
          user_id: [],
          internal_user_id: [],
          type: [],
          status: [],
          is_archive: 1,
          start_date: "",
          due_date: "",
          closing_date: "",
          created_at_from: "",
          created_at_to: "",
        }
  );

  const onTableChange = (type: any, newState: any) => {
    let draw = datatableOptions.draw + 1;
    let page = 1;
    if (type == "search") {
      page = 1;
    } else {
      page = newState.page;
    }
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: draw,
      page: page,
      sizePerPage: newState.sizePerPage,
      searchText: newState.searchText,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
    };
    setDatatableOptions(updatedDatatableOptions);
    setDatatableListData([]);
    setLoadingListData(true);
  };

  const clearFilter = () => {
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: 1,
      page: 1,
      sizePerPage: 20,
      searchText: "",
      sortField: "id",
      sortOrder: "desc",
    };
    setDatatableOptions(updatedDatatableOptions);

    const filter = {
      ...filters,
      is_filtered: false,
      company_id: [],
      user_id: [],
      internal_user_id: [],
      type: [],
      status: [],
      is_archive: 1,
      start_date: "",
      due_date: "",
      closing_date: "",
      created_at_from: "",
      created_at_to: "",
    };
    setFilters(filter);

    setDatatableListData([]);
    setLoadingListData(true);
  };

  const loadingDatatable = async () => {
    try {
      const res = (await taskService.getTasksList({
        ...getDataTableQuery(datatableOptions, columns),
        filters,
      })) as any;

      if (res.data.status === "success") {
        setDatatableListData(res.data.data);

        const updatedDatatableOptions = {
          ...datatableOptions,
          recordsTotal: res.data.recordsTotal,
          recordsFiltered: res.data.recordsFiltered,
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingListData(false);
      } else {
        setDatatableListData([]);
        setLoadingListData(false);
      }
    } catch (err) {
      setDatatableListData([]);
      setLoadingListData(false);
    }
  };

  const exportAll = async () => {
    try {
      setLoadingExport(true);
      const res = (await taskService.getTasksExport({
        ...getDataTableQuery(datatableOptions, columns),
        filters,
      })) as any;

      if (res) {
        fileDownload(res.data, `archive-task-${dateTimeString()}-export.xlsx`);
        setLoadingExport(false);
      }
    } catch (err) {
      setLoadingExport(false);
    }
  };

  useEffect(() => {
    if (loadingListData === true) {
      loadingDatatable();
    }
  }, [loadingListData]);

  useEffect(() => {
    setDatatableListData([]);
    setLoadingListData(true);
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  // Filters -------------------------------------------------
  const handleFiltersChange = (obj: any) => {
    setFilters((state: any) => ({ ...state, ...obj }));
  };

  const handleApplyFilters = () => {
    setDatatableListData([]);
    setLoadingListData(true);
    setOpenFilter(false);
  };
  // Filters -------------------------------------------------

  return (
    <React.Fragment>
      {deleteWhere && (
        <DeleteModal
          recordName={recordName}
          modalTitle={t("Tasks")}
          customMessage={t(
            "Deleting a Task/Subtask will delete the linked timesheet for all participants. Do you want to proceed."
          )}
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
          loading={loading}
        />
      )}

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={t("Tasks")}
            breadcrumbItems={breadcrumbItems}
            endAdornment={
              <div className="d-flex gap-2 text-end">
                {userProfile?.IS_INTERNAL && (
                  <>
                    <Link to="/tasks/all-tasks" className={`btn btn-info btn-label btn-sm`}>
                      <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                        list_alt
                      </span>
                      {t("List view")}
                    </Link>

                    <Link
                      to="/tasks/kanban-view"
                      className={`btn btn-outline-secondary btn-label btn-sm`}
                    >
                      <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                        view_kanban
                      </span>
                      {t("Kanban view")}
                    </Link>
                  </>
                )}

                {userProfile?.IS_INTERNAL && userProfile?.CAN_VIEW_ALL && (
                  <Link to="/tasks/graph" className={`btn btn-outline-secondary btn-label btn-sm`}>
                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                      insert_chart
                    </span>
                    {t("Graphical view")}
                  </Link>
                )}

                {userProfile.CAN_CREATE ? (
                  <Link to="/tasks/new-task" className="btn btn-primary btn-label btn-sm">
                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                      add
                    </span>{" "}
                    {t("Create task")}
                  </Link>
                ) : null}
              </div>
            }
          />

          <Row>
            <Col xl="12">
              <Card>
                {userProfile.USER_COMPANY !== 0 ? (
                  <CardHeader className="bg-transparent border-bottom pb-0">
                    <div className="d-sm-flex flex-wrap">
                      <NavItems />
                    </div>
                  </CardHeader>
                ) : null}

                <CardBody>
                  <DataTable
                    columns={columns}
                    products={datatableListData}
                    datatableOptions={datatableOptions}
                    filters={filters}
                    onTableChange={onTableChange}
                    loadingListData={loadingListData}
                    clearFilter={clearFilter}
                    settingTableName="task_archive_table"
                    slug="all-tasks"
                    customButtons={
                      <>
                        {userProfile.IS_INTERNAL ? (
                          <Button
                            title={t("Filter")}
                            outline={filters.is_filtered === true ? false : true}
                            color={filters.is_filtered === true ? "secondary" : "light"}
                            onClick={() => setOpenFilter((state) => !state)}
                          >
                            {filters.is_filtered === false ? (
                              <span className="align-middle material-symbols-outlined fw-light fs-4">
                                filter_alt
                              </span>
                            ) : (
                              <span className="align-middle material-symbols-outlined fw-light fs-4">
                                filter_alt_off
                              </span>
                            )}
                          </Button>
                        ) : null}

                        {userProfile.CAN_EXPORT ? (
                          <Button
                            title={t("Export all")}
                            outline={true}
                            color={"light"}
                            onClick={() => {
                              exportAll();
                            }}
                            disabled={loadingExport}
                          >
                            {loadingExport ? (
                              <span className="align-middle material-symbols-outlined fw-light fs-4 fa-spin">
                                hourglass_empty
                              </span>
                            ) : (
                              <span className="align-middle material-symbols-outlined fw-light fs-4">
                                export_notes
                              </span>
                            )}
                          </Button>
                        ) : null}
                      </>
                    }
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Drawer
        title={t("Filters")}
        open={openFilter}
        onClose={() => setOpenFilter((state) => !state)}
        onReset={clearFilter}
        footer={
          <Button color="primary" style={{ margin: "16px" }} onClick={handleApplyFilters}>
            {t("Apply")}
          </Button>
        }
      >
        <Filters
          filters={filters}
          onApply={() => {}}
          onClear={() => {}}
          onChange={(obj: any) => handleFiltersChange(obj)}
        />
      </Drawer>

      <Drawer
        title={t("Client")}
        open={openCompany}
        onClose={() => setOpenCompany((state) => !state)}
        fullWidth
      >
        <EditCompany
          id={selectedData?.company}
          company_id={selectedData?.company_id}
          type={1}
          isWindow={true}
        />
      </Drawer>

      <Drawer
        title={t("User")}
        open={openUser}
        onClose={() => setOpenUser((state) => !state)}
        fullWidth
      >
        <EditUser id={selectedUser?.id} user_id={selectedUser?.user_id} type={1} isWindow={true} />
      </Drawer>
    </React.Fragment>
  );
};

export default ArchiveTasks;
