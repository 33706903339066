import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class CountryService {

    async get(id: number) {
        const response = await axios
            .get(
                SERVER_URL + url.COUNTRY.GET + '/' + id,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async create(data: any) {
        const response = await axios
            .post(
                SERVER_URL + url.COUNTRY.CREATE,
                data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async update(data: any) {
        const response = await axios
            .put(
                SERVER_URL + url.COUNTRY.UPDATE,
                data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async getAll(data: any) {
        const response = await axios
            .post(SERVER_URL + url.COUNTRY.GET_ALL, data,
                {
                    headers: getAxiosConfigWithToken(),
                }
            ).then(function (response: any) {
                return response;
            })
            .catch(function (error: any) {
                return error;
            });
        return response;
    }

    async delete(id: any) {
        const response = await axios
            .delete(
                SERVER_URL + url.COUNTRY.DELETE + id,
                {
                    headers: getAxiosConfigWithToken(),
                }
            );

        return response.data;
    }


    async toggleAll(data: any) {
        const response = await axios
            .post(SERVER_URL + url.COUNTRY.TOGGLE_ON_OFF, data,
                {
                    headers: getAxiosConfigWithToken(),
                }
            ).then(function (response: any) {
                return response;
            })
            .catch(function (error: any) {
                return error;
            });
        return response;
    }

}

export default new CountryService();