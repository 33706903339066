import {
    GET_QUESTIONNAIRE_TABS_FAIL,
    GET_QUESTIONNAIRE_TABS_SUCCESS,
    GET_QUESTIONNAIRE_TAB_DETAIL_FAIL,
    GET_QUESTIONNAIRE_TAB_DETAIL_SUCCESS,
    GET_QUESTIONNAIRE_TAB_LIST,
    GET_QUESTIONNAIRE_TAB_LIST_SUCCESS,
    GET_QUESTIONNAIRE_TAB_LIST_FAIL,
    ADD_NEW_QUESTIONNAIRE_TAB,
    ADD_QUESTIONNAIRE_TAB_SUCCESS,
    ADD_QUESTIONNAIRE_TAB_FAIL,
    UPDATE_QUESTIONNAIRE_TAB,
    UPDATE_QUESTIONNAIRE_TAB_SUCCESS,
    UPDATE_QUESTIONNAIRE_TAB_FAIL,
    DELETE_QUESTIONNAIRE_TAB_SUCCESS,
    DELETE_QUESTIONNAIRE_TAB_FAIL,
    DELETE_QUESTIONNAIRE_TAB,
    GET_QUESTIONNAIRE_TABS,
} from "./actionTypes"

const INIT_STATE = {
    GET_TAB_LIST: [],
    error: "",
    success: "",
    isModel: false,
    loading: false,
}

const QuestionnaireTab = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GET_QUESTIONNAIRE_TABS:
            return {
                ...state,
                success: "",
                error: "",
                loading: true,
            }
        case GET_QUESTIONNAIRE_TABS_SUCCESS:
            return {
                ...state,
                GET_TAB_LIST: action.payload,
                loading: false,
            }

        case GET_QUESTIONNAIRE_TABS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
            case GET_QUESTIONNAIRE_TAB_LIST:
                return {
                    ...state,
                    success: "",
                    error: "",
                    loading: true,
                }
            case GET_QUESTIONNAIRE_TAB_LIST_SUCCESS:
                return {
                    ...state,
                    GET_TAB_LIST: action.payload,
                    loading: false,
                }
    
            case GET_QUESTIONNAIRE_TAB_LIST_FAIL:
                return {
                    ...state,
                    error: action.payload,
                    loading: false,
                }

        case ADD_NEW_QUESTIONNAIRE_TAB:
            return {
                ...state,
                success: "",
                error: "",
                loading: true,
            }
        case ADD_QUESTIONNAIRE_TAB_SUCCESS:
            return {
                ...state,
               // status: [...state.status, action.payload.data],
                success: action.payload.message,
                loading: false,
                isModel: false
            }

        case ADD_QUESTIONNAIRE_TAB_FAIL:
            return {
                ...state,
                error: action.payload.message,
                isModel: true,
                loading: false,
            }

        case GET_QUESTIONNAIRE_TAB_DETAIL_SUCCESS:
            return {
                ...state,
                statusDetail: action.payload,
                loading: false,
            }

        case UPDATE_QUESTIONNAIRE_TAB:
            return {
                ...state,
                success: "",
                error: "",
                loading: true,
            }

        case UPDATE_QUESTIONNAIRE_TAB_SUCCESS:
            return {
                ...state,
               // status: [...state.status, action.payload.data],
                success: action.payload.message,
                loading: false,
                isModel: false
            }

        case UPDATE_QUESTIONNAIRE_TAB_FAIL:
            return {
                ...state,
                error: action.payload.message,
                isModel: true,
                loading: false,
            }
        case DELETE_QUESTIONNAIRE_TAB:
            return {
                ...state,
                success: '',
                error: '',
                loading: true,
            }

        case DELETE_QUESTIONNAIRE_TAB_SUCCESS:
            return {
                ...state,
                success: action.payload.message,
                loading: false,
            }

        case DELETE_QUESTIONNAIRE_TAB_FAIL:
            return {
                ...state,
                error: action.payload.message,
                loading: false,
            }


        case GET_QUESTIONNAIRE_TAB_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }

        default:
            return state
    }
}

export default QuestionnaireTab
