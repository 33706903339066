import {
  GET_PROVIDERS,
  GET_PROVIDERS_SUCCESS,
  GET_PROVIDERS_ERROR,

  GET_CONNECTIONS,
  GET_CONNECTIONS_SUCCESS,
  GET_CONNECTIONS_ERROR,
} from "./actionTypes";

// ---------------------------------------------GET_PROVIDERS
export const getProviders = (data: any, history: any) => {
  return {
    type: GET_PROVIDERS,
    payload: { data, history },
  };
};

export const getProvidersSuccess = (data: {}) => {
  return {
    type: GET_PROVIDERS_SUCCESS,
    payload: data,
  };
};

export const getProvidersError = (error: {}) => {
  return {
    type: GET_PROVIDERS_ERROR,
    payload: error,
  };
};

// ---------------------------------------------GET_CONNECTIONS
export const getConnections = (data: any, history: any) => {
  return {
    type: GET_CONNECTIONS,
    payload: { data, history },
  };
};

export const getConnectionsSuccess = (data: {}) => {
  return {
    type: GET_CONNECTIONS_SUCCESS,
    payload: data,
  };
};

export const getConnectionsError = (error: {}) => {
  return {
    type: GET_CONNECTIONS_ERROR,
    payload: error,
  };
};
