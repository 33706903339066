import axios from "axios";
import { getAxiosConfig, getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class EventService {
    async getTaskEvents(startDateTime: any, endDateTime: any, myEvents: boolean) {
        const response = await axios
            .get(
                SERVER_URL + url.EVENT.TASK + '/' + startDateTime + '/' + endDateTime + '/' + myEvents,
                {
                    headers: getAxiosConfigWithToken(),
                }
            )
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }
}

export default new EventService();