import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalFooter, Button, Col } from 'reactstrap'

const QuestionnaireModal = (props: any) => {
    const responsiblePerson = props.responsiblePerson;
    const questionnaireData = props.questionnaireData;
    const { t } = useTranslation();

    const [checkAr, setCheckAr] = useState<any>([]);
    const addcheckedquestionnaire = (e: any, value: any) => {
        if (e.target.value == 1) {
            setCheckAr((checkAr: any) => {
                return [
                    ...checkAr, value
                ];
            });
        } else {
            var array = [...checkAr];
            var index = array.indexOf(value);
            if (index !== -1) {
                array.splice(index, 1);
                setCheckAr(array);
            }
        }
    }

    useEffect(()=>{
        if(questionnaireData.length ==1){
            setCheckAr([questionnaireData[0].id]);
        }
    },[questionnaireData])

    return (
        <React.Fragment>
            <Modal
                isOpen={props.show}
                toggle={props.onCloseClick}
                backdrop="static"
                keyboard={false}
                autoFocus={false}
                centered
            >
                <div className="modal-header">
                    <h5>{t("Send Questionnaire")}</h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={props.onCloseClick}
                        aria-label={t("Close")}
                    ></button>
                </div>
                <ModalBody>
                    {questionnaireData.length > 1 ?
                        <h6 className="modal-title text-wrap"> <span className="text-wrap"> {t('Do you want to send Questionnaires to')} {responsiblePerson}</span></h6>
                        :
                        <h6 className="modal-title text-wrap"> <span className="text-wrap">{t('Do you want to send')} {questionnaireData[0]?.questionnaire_type} {t('to ')} {responsiblePerson}</span></h6>
                    }
                    {questionnaireData && questionnaireData.length > 1 &&
                        questionnaireData.map((item: any, idx: number) => (
                            <Col xl={12} lg={12} md={12} sm={12}>
                                <div className="form-check mt-2 mb-3" key={`questionnaire_${idx}`}>
                                    <input
                                        id={item.questionnaire_type}
                                        name="questionnaire_radio"
                                        className="form-check-input"
                                        type="radio"
                                        value={(item.id && checkAr.includes(item.id)) ? 0 : 1}
                                        checked={(item.id && checkAr.includes(item.id)) ? true : false}
                                        onChange={(e: any) => addcheckedquestionnaire(e, item.id)}
                                    />
                                    <label className="form-check-label" htmlFor={item.questionnaire_type}>
                                        {item.questionnaire_type}
                                    </label>
                                </div>
                            </Col>
                        ))}

                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={() => props.onYesClick(checkAr)}
                    >
                        {t("Send")}
                    </Button>
                    {' '}
                    <Button onClick={props.onNoClick}>
                        {t("Cancel")}
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )
}

export default QuestionnaireModal