import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

import FeesService from "../../../services/masters.fees.service";
import { CommonAction } from "../../../store/actions";

// Import Breadcrumb
import Breadcrumbs from "../../../components/Layout/Breadcrumb";

// Import DeleteModal
import FeeModal from "./FeesForm";

// Import DeleteModal
import DeleteModal from "../../../components/Common/DeleteModal";

// Import DataTable
import DataTable from "../../../components/Common/DataTable";

import { getDataTableQuery } from "../../../helpers/functions_helper";
import { useTranslation } from "react-i18next";
import { masterValueWithColorCode, yesNoTag } from "../../../utils/utils";

const AllFees = (props: any) => {
  const { t } = useTranslation();
  document.title = `${t("Fees")} | ${process.env.REACT_APP_NAME}`;

  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "#",
      label: t("Fees"),
      active: true,
    },
  ];

  const toastId = useRef(null) as any;
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [loadingListData, setLoadingListData] = useState(true);
  const [feesListData, setFeesListData] = useState([]);
  const [deleteFee, setDeleteFee] = useState({
    id: "",
    fee_id: "",
  });
  const [recordName, setRecordName] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [feeData, setFeeData] = useState({});
  const [masterFormModal, setMasterFormModal] = useState(false);
  const [masterFormModalTitle, setMasterFormModalTitle] = useState("");

  // DataTable ----------------------------------------------
  function renderRowHTML(rowContent: any, row: any) {
    return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
  }

  const columns = [
    {
      dataField: "DT_RowIndex",
      text: t("Sr. No."),
      sort: false,
      search: false,
      classes: "fix-col-left",
      headerClasses: "fix-col-left",
      headerStyle: { width: "155px" },
      style: { width: "155px" },
    },
    // {
    //   dataField: "id",
    //   text: t("ID"),
    //   sort: true,
    //   search: true,
    // },
    {
      dataField: "category",
      text: t("Category (Object)"),
      sort: true,
      search: true,
    },
    {
      dataField: "category_of_fees",
      text: t("Category of fees"),
      sort: true,
      search: true,
    },
    {
      dataField: "description",
      text: t("Description of the service"),
      sort: true,
      search: true,
    },
    {
      dataField: "fees_amount",
      text: t("Fees amount"),
      sort: true,
      search: true,
    },
    {
      dataField: "fees_code",
      text: t("Fees code"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return renderRowHTML(
          masterValueWithColorCode(t(row?.fees_code), row?.color_code),
          null
        );
      },
    },
    {
      dataField: "estimated_time",
      text: t("Estimated time"),
      sort: true,
      search: true,
    },
    {
      dataField: "is_preliminary_billing",
      text: t("Preliminary billing"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return renderRowHTML(
          yesNoTag(
            row?.is_preliminary_billing,
            row?.is_preliminary_billing === 1 ? t("Yes") : t("No")
          ),
          null
        );
      },
    },
    {
      dataField: "is_income_year",
      text: t("Income year"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return renderRowHTML(
          yesNoTag(row?.is_income_year, row?.is_income_year === 1 ? t("Yes") : t("No")),
          null
        );
      },
    },
    {
      dataField: "is_income_year_onward",
      text: t("Income year onward"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return renderRowHTML(
          yesNoTag(
            row?.is_income_year_onward,
            row?.is_income_year_onward === 1 ? t("Yes") : t("No")
          ),
          null
        );
      },
    },
    {
      dataField: "is_assistant_administrative",
      text: t("Assistant administrative"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return renderRowHTML(
          yesNoTag(
            row?.is_assistant_administrative,
            row?.is_assistant_administrative === 1 ? t("Yes") : t("No")
          ),
          null
        );
      },
    },
    {
      dataField: "is_internal",
      text: t("Internal"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return renderRowHTML(
          yesNoTag(row?.is_internal, row?.is_internal === 1 ? t("Yes") : t("No")),
          null
        );
      },
    },
    {
      dataField: "is_questionnaire",
      text: t("Questionnaire"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return renderRowHTML(
          yesNoTag(row?.is_questionnaire, row?.is_questionnaire === 1 ? t("Yes") : t("No")),
          null
        );
      },
    },
    {
      dataField: "is_sensitive",
      text: t("Sensitive"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return renderRowHTML(
          yesNoTag(row?.is_sensitive, row?.is_sensitive === 1 ? t("Yes") : t("No")),
          null
        );
      },
    },
    {
      dataField: "is_resident",
      text: t("Resident"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return renderRowHTML(
          yesNoTag(row?.is_resident, row?.is_resident === 1 ? t("Yes") : t("No")),
          null
        );
      },
    },
    {
      dataField: "is_non_resident",
      text: t("Non-Resident"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return renderRowHTML(
          yesNoTag(row?.is_non_resident, row?.is_non_resident === 1 ? t("Yes") : t("No")),
          null
        );
      },
    },
    {
      dataField: "is_tax_return_type",
      text: t("Tax return type"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return renderRowHTML(
          yesNoTag(row?.is_tax_return_type, row?.is_tax_return_type === 1 ? t("Yes") : t("No")),
          null
        );
      },
    },
    {
      dataField: "is_ubo_aml",
      text: `UBO ${t("or")} AML`,
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return renderRowHTML(
          yesNoTag(row?.is_ubo_aml, row?.is_ubo_aml === 1 ? t("Yes") : t("No")),
          null
        );
      },
    },
    {
      dataField: "action",
      text: t("Action"),
      sort: false,
      search: false,
      formatter: (rowContent: any, row: any): any => {
        return (
          <div className="d-flex gap-2 text-end">
            <Link
              className="btn btn-pill btn-outline-primary btn-sm"
              to="#"
              onClick={() => onClickEdit(row)}
            >
              <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                edit
              </span>
              {t("Edit")}
            </Link>
            <Link
              className="btn btn-pill btn-outline-danger btn-sm"
              to="#"
              onClick={() => onClickDelete(row)}
            >
              <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                delete
              </span>
              {t("Delete")}
            </Link>
          </div>
        );
      },
    },
  ];

  const [datatableOptions, setDatatableOptions] = useState({
    draw: 1,
    page: 1,
    recordsTotal: 0,
    recordsFiltered: 0,
    sizePerPage: 20,
    searchText: "",
    sortField: "id",
    sortOrder: "desc",
  });

  const onTableChange = (type: any, newState: any) => {
    let draw = datatableOptions.draw + 1;
    let page = 1;
    if (type == "search") {
      page = 1;
    } else {
      page = newState.page;
    }
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: draw,
      page: page,
      sizePerPage: newState.sizePerPage,
      searchText: newState.searchText,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
    };
    setDatatableOptions(updatedDatatableOptions);
    setLoadingListData(true);
  };

  const clearFilter = () => {
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: 1,
      page: 1,
      sizePerPage: 20,
      searchText: "",
      sortField: "id",
      sortOrder: "desc",
    };
    setDatatableOptions(updatedDatatableOptions);
    setLoadingListData(true);
  };

  const getFeesListData = async () => {
    const res = await FeesService.getFeesList(getDataTableQuery(datatableOptions, columns));
    if (res.data.status === "success") {
      setFeesListData(res.data.data);
      const updatedDatatableOptions = {
        ...datatableOptions,
        recordsTotal: res.data.recordsTotal,
        recordsFiltered: res.data.recordsFiltered,
      };
      setDatatableOptions(updatedDatatableOptions);
      setLoadingListData(false);
      setLoading(false);
    } else {
      setFeesListData([]);
      setLoadingListData(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loadingListData === true) {
      getFeesListData();
    }
  }, [loadingListData]);

  useEffect(() => {
    setFeesListData([]);
    setLoadingListData(true);
  }, [localStorage.getItem("I18N_LANGUAGE")]);
  // Datatable -------------------------------------------------

  // Delete Form --------------------------------------------------------
  const onClickDelete = (row: any) => {
    const deleteFeeData = {
      ...deleteFee,
      id: row.id,
      fee_id: row.fee_id,
    };
    setDeleteFee(deleteFeeData);
    setRecordName(row.fees_code);
    setDeleteModal(true);
  };

  const handleDeleteFee = async () => {
    setLoading(true);
    toastId.current = toast(`${t("Deleting")} ${t("fees")}...`, {
      autoClose: false,
      isLoading: true,
    });
    try {
      const res = (await FeesService.deleteFee(deleteFee)) as any;
      if (res.data.status === "success") {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res?.data?.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        dispatch(CommonAction.getFeesOptionsListSuccess(""));
        setLoadingListData(true);
        setDeleteModal(false);
        setLoading(false);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res?.data?.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setDeleteModal(false);
        setLoading(false);
      }
    } catch (err) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: t("Something went wrong."),
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setDeleteModal(false);
      setLoading(false);
    }
  };
  // Delete Form --------------------------------------------------------

  // Master Form --------------------------------------------------------
  const onClickModal = () => {
    setFeeData("");
    setMasterFormModalTitle(t("Create fees"));
    setMasterFormModal(true);
  };

  const onClickEdit = (row: any) => {
    setFeeData(row);
    setMasterFormModalTitle(t("Update fees"));
    setMasterFormModal(true);
  };

  const handleSubmitFee = async (values: any) => {
    setLoading(true);
    if (values.id && values.fee_id) {
      toastId.current = toast(`${t("Updating")} ${t("fees")}...`, {
        autoClose: false,
        isLoading: true,
      });
      try {
        const res = (await FeesService.updateFee(values)) as any;
        if (res.data.status === "success") {
          toast.update(toastId.current, {
            type: toast.TYPE.SUCCESS,
            render: res.data.message,
            autoClose: 5000,
            isLoading: false,
            delay: 500,
          });
          dispatch(CommonAction.getFeesOptionsListSuccess(""));
          setLoadingListData(true);
          setMasterFormModal(false);
          setLoading(false);
        } else {
          toast.update(toastId.current, {
            type: toast.TYPE.ERROR,
            render: res.data.message,
            autoClose: 5000,
            isLoading: false,
            delay: 500,
          });
          setLoading(false);
        }
      } catch (err) {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: t("Something went wrong."),
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoading(false);
      }
    } else {
      toastId.current = toast(`${t("Creating")} ${t("fees")}...`, {
        autoClose: false,
        isLoading: true,
      });
      try {
        const res = (await FeesService.createFee(values)) as any;
        if (res.data.status === "success") {
          toast.update(toastId.current, {
            type: toast.TYPE.SUCCESS,
            render: res.data.message,
            autoClose: 5000,
            isLoading: false,
            delay: 500,
          });
          dispatch(CommonAction.getFeesOptionsListSuccess(""));
          setLoadingListData(true);
          setMasterFormModal(false);
          setLoading(false);
        } else {
          toast.update(toastId.current, {
            type: toast.TYPE.ERROR,
            render: res.data.message,
            autoClose: 5000,
            isLoading: false,
            delay: 500,
          });
          setLoading(false);
        }
      } catch (err) {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: t("Something went wrong."),
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoading(false);
      }
    }
  };
  // Master Form --------------------------------------------------------

  return (
    <React.Fragment>
      {deleteFee && (
        <DeleteModal
          recordName={recordName}
          modalTitle={masterFormModalTitle}
          show={deleteModal}
          onDeleteClick={handleDeleteFee}
          onCloseClick={() => setDeleteModal(false)}
          loading={loading}
        />
      )}

      {masterFormModal && (
        <FeeModal
          modalTitle={masterFormModalTitle}
          show={masterFormModal}
          loading={loading}
          feeData={feeData}
          onSubmitClick={handleSubmitFee}
          onCloseClick={() => setMasterFormModal(false)}
        />
      )}

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={masterFormModalTitle}
            breadcrumbItems={breadcrumbItems}
            endAdornment={
              <Link
                to="#"
                className="btn btn-primary btn-sm waves-effect waves-light btn-label"
                onClick={() => onClickModal()}
              >
                <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                  add
                </span>
                {t("Create fees")}
              </Link>
            }
          />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <DataTable
                    columns={columns}
                    products={feesListData}
                    datatableOptions={datatableOptions}
                    filters={[]}
                    onTableChange={onTableChange}
                    loadingListData={loadingListData}
                    clearFilter={clearFilter}
                    settingTableName="fees_table"
                    slug="masters"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AllFees;
