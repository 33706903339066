import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { Container, Row, Col, Card, CardBody, Badge } from "reactstrap";

// Import Breadcrumb
import Breadcrumbs from "../../components/Layout/Breadcrumb";

// Import DataTable
import DataTable from "../../components/Common/DataTable";
import {
  getDataTableQuery,
  getUserProfile,
} from "../../helpers/functions_helper";
import { dateFormat } from "../../utils/formats";
import auditLogService from "../../services/audit.log.service";
import { useTranslation } from "react-i18next";
import { getLangCode } from "../../common/staticdata";

const AllAuditLogs = (props: any) => {
  const { t } = useTranslation();
  document.title = t("Audit Logs") + " | " + process.env.REACT_APP_NAME;

  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "#",
      label: t("Audit Logs"),
      active: true,
    },
  ];

  const userProfile = getUserProfile("default");
  let navigate = useNavigate();

  const [loader, setLoader] = useState(true);

  // DataTable ----------------------------------------------
  function renderHeaderHTML(column: any, colIndex: any) {
    return <span dangerouslySetInnerHTML={{ __html: column.text }} />;
  }
  function renderRowHTML(rowContent: any, row: any) {
    return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
  }

  const columns = [
    {
      dataField: "DT_RowIndex",
      text: t("Sr. No."),
      sort: false,
      search: false,
    },
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: true,
    //   search: true,
    // },
    {
      dataField: "action_value",
      text: t("Log details"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return row.is_deleted === true ? (
          <>
            {row.action_value}
            {row.action !== "DELETE" && (
              <div>
                <span className="badge rounded-pill badge-soft-danger">
                  {t("Record deleted")}
                </span>
              </div>
            )}
          </>
        ) : (
          <>
            {row.module_main_id === 0 ? (
              row?.custom_array &&
                JSON.parse(row?.custom_array)[0]?.notification_title
                ? JSON.parse(row?.custom_array)[0]
                  ?.notification_title : row.action_value
            ) : (
              <Link
                className="text-decoration-underline"
                to={row?.custom_array &&
                  JSON.parse(row?.custom_array)[0]?.notification_url
                  ? JSON.parse(row?.custom_array)[0]
                    ?.notification_url
                  : "/" +
                  row.url_edit +
                  row.module_main_id +
                  "/" +
                  row.module_sub_id
                }
              >
                {row?.custom_array &&
                  JSON.parse(row?.custom_array)[0]?.notification_title
                  ? JSON.parse(row?.custom_array)[0]
                    ?.notification_title : row.action_value}
              </Link>
            )}
          </>
        );
      },
    },
    {
      dataField: "created_at",
      text: t("Action date"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return dateFormat(
          row.created_at,
          {
            dateStyle: "long",
            timeStyle: "short",
            hour12: true,
          },
          getLangCode(localStorage.getItem("I18N_LANGUAGE"))
        );
      },
    },
    {
      dataField: "created_by_value",
      text: t("User"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return row?.created_by !== 0 ? row.created_by_value : <span title={t("Created by cron job")} className="text-danger">{row?.created_by_value}</span>
      },
    },
    {
      dataField: "module_value",
      text: t("Module"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return (
          <Link
            className="btn btn-sm btn-outline-primary btn-rounded"
            to={"/" + row.url}
          >
            {t(row.module_value)}
          </Link>
        );
      },
    },
  ];

  const [loadingListData, setLoadingListData] = useState(true);
  const [datatableData, setDatatableData] = useState<any>([]);
  const [datatableOptions, setDatatableOptions] = useState({
    draw: 1,
    page: 1,
    recordsTotal: 0,
    recordsFiltered: 0,
    sizePerPage: 20,
    searchText: "",
    sortField: "id",
    sortOrder: "desc",
  });

  const loadingDatatable = async () => {
    try {
      const res = (await auditLogService.getAuditLogList(
        getDataTableQuery(datatableOptions, columns)
      )) as any;

      if (res.data.status === "success") {
        setDatatableData(res.data.data);

        const updatedDatatableOptions = {
          ...datatableOptions,
          recordsTotal: res.data.recordsTotal,
          recordsFiltered: res.data.recordsFiltered,
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingListData(false);
      } else {
        setDatatableData([]);
        setLoadingListData(false);
      }
    } catch (err) {
      setDatatableData([]);
      setLoadingListData(false);
    }
  };

  useEffect(() => {
    if (userProfile?.USER_TYPE === 3) {
      return navigate("/cpo/list/all");
    }
  }, []);

  useEffect(() => {
    if (loadingListData === true) {
      loadingDatatable();
    }
  }, [loadingListData]);

  useEffect(() => {
    setDatatableData([]);
    setLoadingListData(true);
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  const onTableChange = (type: any, newState: any) => {
    let draw = datatableOptions.draw + 1;
    let page = 1;
    if (type == "search") {
      page = 1;
    } else {
      page = newState.page;
    }
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: draw,
      page: page,
      sizePerPage: newState.sizePerPage,
      searchText: newState.searchText,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
    };
    setDatatableOptions(updatedDatatableOptions);
    setLoadingListData(true);
  };

  const clearFilter = () => {
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: 1,
      page: 1,
      sizePerPage: 20,
      searchText: "",
      sortField: "id",
      sortOrder: "desc",
    };
    setDatatableOptions(updatedDatatableOptions);
    setLoadingListData(true);
  };
  // Datatable -------------------------------------------------

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={t("Audit Logs")}
            breadcrumbItems={breadcrumbItems}
          />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <DataTable
                    columns={columns}
                    products={datatableData}
                    datatableOptions={datatableOptions}
                    filters={[]}
                    onTableChange={onTableChange}
                    loadingListData={loadingListData}
                    clearFilter={clearFilter}
                    slug="default"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AllAuditLogs;
