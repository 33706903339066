import {
  GET_EMAIL_TEMPLATE_CATEGORY,
  GET_EMAIL_TEMPLATE_CATEGORY_SUCCESS,
  GET_EMAIL_TEMPLATE_CATEGORY_ERROR,

  GET_EMAIL_TEMPLATE_CATEGORY_LIST,
  GET_EMAIL_TEMPLATE_CATEGORY_LIST_SUCCESS,
  GET_EMAIL_TEMPLATE_CATEGORY_LIST_ERROR,

  CREATE_EMAIL_TEMPLATE_CATEGORY,
  CREATE_EMAIL_TEMPLATE_CATEGORY_SUCCESS,
  CREATE_EMAIL_TEMPLATE_CATEGORY_ERROR,

  CREATE_DUPLICATE_EMAIL_TEMPLATE_CATEGORY,
  CREATE_DUPLICATE_EMAIL_TEMPLATE_CATEGORY_SUCCESS,
  CREATE_DUPLICATE_EMAIL_TEMPLATE_CATEGORY_ERROR,

  UPDATE_EMAIL_TEMPLATE_CATEGORY,
  UPDATE_EMAIL_TEMPLATE_CATEGORY_SUCCESS,
  UPDATE_EMAIL_TEMPLATE_CATEGORY_ERROR,

  DELETE_EMAIL_TEMPLATE_CATEGORY,
  DELETE_EMAIL_TEMPLATE_CATEGORY_SUCCESS,
  DELETE_EMAIL_TEMPLATE_CATEGORY_ERROR,
} from "./actionTypes"

const initialState = {
  GET_EMAIL_TEMPLATE_CATEGORY_SUCCESS: "",
  GET_EMAIL_TEMPLATE_CATEGORY_ERROR: "",

  GET_EMAIL_TEMPLATE_CATEGORY_LIST_SUCCESS: "",
  GET_EMAIL_TEMPLATE_CATEGORY_LIST_ERROR: "",

  CREATE_EMAIL_TEMPLATE_CATEGORY_SUCCESS: "",
  CREATE_EMAIL_TEMPLATE_CATEGORY_ERROR: "",

  CREATE_DUPLICATE_EMAIL_TEMPLATE_CATEGORY_SUCCESS: "",
  CREATE_DUPLICATE_EMAIL_TEMPLATE_CATEGORY_ERROR: "",

  UPDATE_EMAIL_TEMPLATE_CATEGORY: "",
  UPDATE_EMAIL_TEMPLATE_CATEGORY_SUCCESS: "",
  UPDATE_EMAIL_TEMPLATE_CATEGORY_ERROR: "",

  DELETE_EMAIL_TEMPLATE_CATEGORY: "",
  DELETE_EMAIL_TEMPLATE_CATEGORY_SUCCESS: "",
  DELETE_EMAIL_TEMPLATE_CATEGORY_ERROR: "",
  loading: false,
}

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    // ---------------------------------------------GET_TASK_TYPE
    case GET_EMAIL_TEMPLATE_CATEGORY:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_EMAIL_TEMPLATE_CATEGORY_SUCCESS:
      state = {
        ...state,
        GET_EMAIL_TEMPLATE_CATEGORY_SUCCESS: action.payload,
        loading: false,
      }
      break

    case GET_EMAIL_TEMPLATE_CATEGORY_ERROR:
      state = {
        ...state,
        GET_EMAIL_TEMPLATE_CATEGORY_ERROR: action.payload,
        loading: false
      }
      break

    // ---------------------------------------------GET_CATEGORY_LIST
    case GET_EMAIL_TEMPLATE_CATEGORY_LIST:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_EMAIL_TEMPLATE_CATEGORY_LIST_SUCCESS:
      state = {
        ...state,
        GET_EMAIL_TEMPLATE_CATEGORY_LIST_SUCCESS: action.payload,
        loading: false,
      }
      break

    case GET_EMAIL_TEMPLATE_CATEGORY_LIST_ERROR:
      state = {
        ...state,
        GET_EMAIL_TEMPLATE_CATEGORY_LIST_ERROR: action.payload,
        loading: false
      }
      break

    // ---------------------------------------------CREATE_EMAIL_CATEGORY
    case CREATE_EMAIL_TEMPLATE_CATEGORY:
      state = {
        ...state,
        loading: true,
      }
      break

    case CREATE_EMAIL_TEMPLATE_CATEGORY_SUCCESS:
      state = {
        ...state,
        CREATE_EMAIL_TEMPLATE_CATEGORY_SUCCESS: action.payload,
        loading: false,
      }
      break

    case CREATE_EMAIL_TEMPLATE_CATEGORY_ERROR:
      state = {
        ...state,
        CREATE_EMAIL_TEMPLATE_CATEGORY_ERROR: action.payload,
        loading: false
      }
      break

    // ---------------------------------------------CREATE_DUPLICATE_EMAIL_CATEGORY
    case CREATE_DUPLICATE_EMAIL_TEMPLATE_CATEGORY:
      state = {
        ...state,
        loading: true,
      }
      break

    case CREATE_DUPLICATE_EMAIL_TEMPLATE_CATEGORY_SUCCESS:
      state = {
        ...state,
        CREATE_DUPLICATE_EMAIL_TEMPLATE_CATEGORY_SUCCESS: action.payload,
        loading: false,
      }
      break

    case CREATE_DUPLICATE_EMAIL_TEMPLATE_CATEGORY_ERROR:
      state = {
        ...state,
        CREATE_DUPLICATE_EMAIL_TEMPLATE_CATEGORY_ERROR: action.payload,
        loading: false
      }
      break


    // ---------------------------------------------UPDATE_TASK_TYPE
    case UPDATE_EMAIL_TEMPLATE_CATEGORY:
      state = {
        ...state,
        loading: true,
      }
      break

    case UPDATE_EMAIL_TEMPLATE_CATEGORY_SUCCESS:
      state = {
        ...state,
        UPDATE_EMAIL_TEMPLATE_CATEGORY_SUCCESS: action.payload,
        loading: false,
      }
      break

    case UPDATE_EMAIL_TEMPLATE_CATEGORY_ERROR:
      state = {
        ...state,
        UPDATE_EMAIL_TEMPLATE_CATEGORY_ERROR: action.payload,
        loading: false
      }
      break

    // ---------------------------------------------DELETE_TASK_TYPE
    case DELETE_EMAIL_TEMPLATE_CATEGORY:
      state = {
        ...state,
        loading: true,
      }
      break

    case DELETE_EMAIL_TEMPLATE_CATEGORY_SUCCESS:
      state = {
        ...state,
        DELETE_EMAIL_TEMPLATE_CATEGORY_SUCCESS: action.payload,
        loading: false,
      }
      break

    case DELETE_EMAIL_TEMPLATE_CATEGORY_ERROR:
      state = {
        ...state,
        DELETE_EMAIL_TEMPLATE_CATEGORY_ERROR: action.payload,
        loading: false
      }
      break

    // ---------------------------------------------DEFAULT
    default:
      state = { ...state }
      break
  }
  return state
}

export default reducer
