import React, { useRef, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router";
import { useTranslation, Trans } from "react-i18next";
import { Container, Row, Col } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../components/Layout/Breadcrumb";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import AuditLogTable from "./AuditLogTable";
import UserTaskList from "./UserTaskList";
import Widget from "../../components/Widgets/Widget";
import NewsFeedWidget from "../Masters/NewsFeed/NewsFeedWidget";
import NewsFeedList from "../Masters/NewsFeed/NewsFeedList";
import WorkAllocation from "../Tasks/Charts/WorkAllocation";
import InvoicePerTaskType from "../Invoices/Charts/InvoicePerTaskType";
import InvoiceVsPaid from "../Invoices/Charts/InvoiceVsPaid";
import SalesReport from "../Invoices/Charts/SalesReport";
import { getUserProfile, moduleAccess } from "../../helpers/functions_helper";
import TimesheetVsAccruedAmount from "../TimeSheets/Charts/TimesheetVsAccruedAmount";
import ExternalVsInternal from "../TimeSheets/Charts/ExternalVsInternal";
import PerUserTaskTime from "../TimeSheets/Charts/PerUserTaskTime";
import TimePerTaskPerUser from "../TimeSheets/Charts/TimePerTaskPerUser";
import Button from "../../components/Button/Button";
import Drawer from "../../components/Drawer";
import { JsxElement } from "typescript";
import { useDispatch, useSelector } from "react-redux";
import { updateDashboard } from "../../store/dashboard/actions";
import { DashboardAction } from "../../store/actions";
import { toast } from "react-toastify";
import Counts from "../TimeSheets/Charts/Counts";
import TaskAmountVsServiceCharge from "../Invoices/Charts/TaskAmountVsServiceCharge";
import PerUserPerTaskSummary from "../TimeSheets/Charts/PerUserPerTaskSummary";
import TimePerTask from "../TimeSheets/Charts/TimePerTask";
import FeeVsServiceCharge from "../TimeSheets/Charts/FeeVsServiceCharge";
import InvoiceCount from "../Invoices/Charts/InvoiceCount";
import InvoiceType from "../Invoices/Charts/InvoiceType";
import RevenueStream from "../Tasks/Charts/RevenueStream";
import TaskOriginated from "../Tasks/Charts/TaskOriginated";
import LoadingSpinner from "../../components/Common/Loader";
import InvoiceActivityList from "../Invoices/InvoiceActivityList";
import UserAlertList from "./UserAlertList";
import CompanyUserAlert from "./CompanyUserAlert";
import TasksByCountry from "../Tasks/Charts/TasksByCountry";
import AllTypes from "../Questionnaire/Charts/AllTypes";
import CountsQuestionnaire from "../Questionnaire/Charts/Counts";
import Status from "../Questionnaire/Charts/Status";
import MoreCharts from "../Questionnaire/Charts/MoreCharts";
import YearData from "../Questionnaire/Charts/YearData";
//User Graph
import UserCount from '../Users/Charts/Counts';
import INPAT from '../Users/Charts/ImpatriatesData';
import CompanyVsIndividual from '../Users/Charts/IndividualVsCompany';
import ResidentVsNoResident from '../Users/Charts/ResidentVsNonResident';
import ActiveVsNonActiveUser from '../Users/Charts/ActiveVsNonActive';
import TotalUserStatatics from '../Users/Charts/UserperQuarter'
import ExpiredUserVsNonExpiredUser from '../Users/Charts/UserVsExpired';

//Company Graph
import CompanyCount from '../Masters/Company/Charts/Counts';
import Totalcompany from "../Masters/Company/Charts/TotalCompany";
import CompanyVsArchive from "../Masters/Company/Charts/CompanyVsArchive";
import ActiveVsNonActive from "../Masters/Company/Charts/ActiveVsNonActive";
import CompanyPerQuarter from "../Masters/Company/Charts/CompanyPerQuarter";
import CompanyVsEmployee from "../Masters/Company/Charts/CompanyVsEmployee";
import InternalTaskVsExternalTask from "../Tasks/Charts/InternalTaskVsExternalTask";
import TaskGeneratedByQuestionnaire from "../Tasks/Charts/TaskGeneratedByQuestionnaire";
import StatusWiseTaskCount from "../Tasks/Charts/StatusWiseTaskCount";

import QuarterCompany from "../Masters/Company/Charts/QuarterCompany";

import ReminderPaymentDue from "../Invoices/Charts/ReminderPaymentDue";
import InvoiceGeneratedFromGeneralAccount from "../Invoices/Charts/InvoiceGeneratedFromGeneralAccount";
import CashFlow from "../Invoices/Charts/CashFlow";
import TopClients from "../Invoices/Charts/TopClients";
import ScheduleOfPaymentsClientHistory from "../Invoices/Charts/ScheduleOfPaymentsClientHistory";
import Loader from "../../components/Layout/Loader";



//CPO
import CPOHitsCount from "../CPO/Graph/CPOHitsCount";
import CPOWebsiteUserVsCustomer from '../CPO/Graph/WebsiteUsersVsCustomer';
import CPOWebsiteUserBecomeCustomer from '../CPO/Graph/WebsiteUserBecomeCustomer';
import CPOHits from "../CPO/Graph/CPOHits";

//Split Payroll
import SplitPayrollDailyCount from "../SplitPayroll/Graph/SplitPayRollDailyCounter";
import SplitPayrollCompanyVsIndividual from '../SplitPayroll/Graph/CompanyVsIndividual';
import SplitCreatedByCompany from '../SplitPayroll/Graph/SplitPayrollCreatedbyCompany';
import ExpiryReminder from "./ExpiryReminder";
import TodaysTrends from "../Invoices/Charts/TodaysTrends";
import InvoiceGeneratedFromTimesheet from "../Invoices/Charts/InvoiceGeneratedFromTimesheet";
import ActivityBoard from "../../components/ActivityBoard";
import ScrollUp from "../../components/ScrollUpDown/ScrollUp";
import Input from "../../components/Form/Input";

const Dashboard = (props: any) => {
  const { t } = useTranslation();
  const userObj = getUserProfile();
  let navigate = useNavigate();
  const abortController = new AbortController();
  document.title = t("Dashboard") + " | " + process.env.REACT_APP_NAME;

  const breadcrumbItems = [
    {
      url: "#",
      label: t("Dashboard"),
      active: true,
    },
  ];

  const dispatch = useDispatch();

  const widgets = useSelector((state: any) => state.Dashboard.widgets) || [];

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingList, setLoadingList] = useState<boolean>(false);
  const [widgetsList, setWidgetList] = useState<Array<any>>(userObj.WIDGETS);

  const dragItem = useRef();
  const dragOverItem = useRef();

  const swap = function (nodeA: any, nodeB: any) {
    const parentA = nodeA.parentNode;
    const siblingA = nodeA.nextSibling === nodeB ? nodeA : nodeA.nextSibling;
    nodeB.parentNode.insertBefore(nodeA, nodeB);
    parentA.insertBefore(nodeB, siblingA);
  };

  const isAbove = function (nodeA: any, nodeB: any) {
    const rectA = nodeA.getBoundingClientRect();
    const rectB = nodeB.getBoundingClientRect();
    return rectA.top + rectA.height / 2 < rectB.top + rectB.height / 2;
  };

  const dragStart = (e: any, position: any) => {
    // alert(position)
    let dragEle = e.target;
    dragItem.current = position;
    // let cloneEle = dragEle.cloneNode(true);
    // cloneEle.classList.add('opacity');
    dragEle.classList.add('drag')

    // dragEle.insertAdjacentElement('afterend', cloneEle);
    const rect = dragEle.getBoundingClientRect();
    let x = e.pageX - rect.left + window.scrollX;
    let y = e.pageY - rect.top + window.scrollY;

    // let placeholder = document.createElement('div');
    // placeholder.classList.add('placeholder');
    // dragEle.parentNode.insertBefore(placeholder, dragEle.nextSibling);


    // dragEle.style.top = `${e.pageY - y}px`;
    // dragEle.style.left = `${e.pageX - x}px`;

    // const prevEle = dragEle.previousElementSibling;
    // const nextEle = placeholder.nextElementSibling;

    // if (prevEle && isAbove(dragEle, prevEle)) {
    //   swap(placeholder, dragEle);
    //   swap(placeholder, prevEle);
    //   return;
    // }

    // if (nextEle && isAbove(nextEle, dragEle)) {
    //   swap(nextEle, placeholder);
    //   swap(nextEle, dragEle);
    // }
  };

  const dragEnter = (e: any, position: any) => {
    dragOverItem.current = position;
  };

  const drop = (e: any) => {
    e.target.classList.remove('drag')
    setLoadingList(true);
    const copyListItems = [...widgets];
    //@ts-ignore
    const dragItemContent = copyListItems[dragItem.current];
    //@ts-ignore
    copyListItems.splice(dragItem.current, 1);
    //@ts-ignore
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    //@ts-ignore
    dragItem.current = null;
    //@ts-ignore
    dragOverItem.current = null;
    // setList(copyListItems);
    dispatch(updateDashboard({ user_id: userObj?.USER_ID, widgets: [...copyListItems] }));
  };

  const onClose = (id: string) => {
    // setList((list: string[]) => list?.filter((i: string) => i !== id))
    dispatch(updateDashboard({ user_id: userObj?.USER_ID, widgets: widgets?.filter((i: string) => i.toUpperCase() !== id.toUpperCase()) }))
  }

  const onAdd = (id: string) => {
    // setList((list: string[]) => [...list, id])
    setLoading(true);
    dispatch(updateDashboard({ user_id: userObj?.USER_ID, widgets: [...widgets, id] }));
    toast.success(t(`Widget '{{widget_id}}' added to dashboard`, { widget_id: t(id?.split('_')?.join(' ')) }))
  }

  const handleWidgetSearch = useCallback((searchKey: string) => {
    const temp = userObj.WIDGETS.filter((item: any) => item.split('_').join(' ').toLowerCase().indexOf(searchKey.toLowerCase()) != -1);
    setWidgetList(temp)
  }, [])

  useEffect(() => {
    if (userObj?.USER_TYPE === 3) {
      return navigate("/cpo/list/all");
    }
  }, [])

  useEffect(() => {
    dispatch(
      DashboardAction.getDashboard(
        userObj?.USER_ID
      )
    );
  }, [])

  useEffect(() => {
    setLoading(false);
    setLoadingList(false);
    return () => abortController.abort()
  }, [widgets])

  return (
    <React.Fragment>

      <div className="page-content">
        <Container fluid>
          <ScrollUp />
          <Breadcrumbs
            breadcrumbTitle={t("Dashboard")}
            breadcrumbItems={[]}
            endAdornment={
              userObj.WIDGETS.length > 0 ?
                <Button color="primary" size="sm" className="btn-rounded btn-label" onClick={() => setOpen(true)}>
                  <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">settings</span> {t("Manage widget")}
                </Button> : <></>
            }
          />

          {loadingList ? <Loader /> : null}
          <Row className="dndDashboard">
            {/* <Col xl={12}>
              <>
                {
                  list &&
                  list.map((item, index) => (
                    <div
                    >
                      {item}
                    </div>
                  ))}
              </>
            </Col> */}
            {/* <Col xl="4">
              <WelcomeComp />
            </Col> */}


            {(moduleAccess('all-tasks') && userObj.USER_COMPANY === 1) ?
              <Widget title='Tasks' height='500px' size={6} noHeader>
                <UserTaskList />
              </Widget> : <Widget title='Activity Board' height='500px' size={6} noHeader>
                {/* <WelcomeComp /> */}
                <ActivityBoard />
              </Widget>
            }

            <Widget title='News Feed' size={(moduleAccess('all-tasks') && userObj.USER_COMPANY === 1) ? 6 : 6} height='500px' noHeader>
              <NewsFeedWidget />
            </Widget>

            {widgets &&
              widgets.map((item: string, index: number) => (
                userObj.WIDGETS.find((a: any, i: number) => a === item) &&
                <LoadWidget
                  id={item}
                  onDragStart={(e: any) => dragStart(e, index)}
                  onDragEnter={(e: any) => dragEnter(e, index)}
                  onDragEnd={drop}
                  onClose={(id: string) => onClose(id)}
                  key={index}
                  draggable
                />
              ))}




          </Row>

        </Container>
      </div>
      <Drawer
        open={open}
        onClose={() => setOpen(false)}
        title={t("Widgets")}
      >
        {loading ? <LoadingSpinner /> :
          <div>
            <div className="mb-3">
              <Input
                onChange={(e: any) => handleWidgetSearch(e.target.value)}
                placeholder={t('Search')}
                startAdornment={
                  <span className="align-middle material-symbols-outlined fw-light fs-4">
                    search
                  </span>
                } />
            </div>
            <div className="widgets-wrapper">
              {
                widgetsList &&
                widgetsList.map((item: string, index: number) => (
                  <div key={index}>

                    <div
                      className="widget-image-wrapper mb-3"
                      style={{ position: "relative", border: "1px solid #ccc" }}
                      key={index}
                    >
                      <div className="widget-title">{t(item.split('_').join(' '))}</div>
                      <Button
                        color="primary"
                        onClick={() => onAdd(item)}
                        disabled={widgets.includes(item)}
                        className="position-center"
                      >
                        {t("Add widget")}
                      </Button>
                      <img src={'/widgets/' + item + '.png'} width="100%" height="100%" />
                      {item.split('_').join(" ")}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        }
      </Drawer>
    </React.Fragment>
  );
};

export default Dashboard;

const LoadWidget = ({ id, ...props }: any): any => {
  const userObj = getUserProfile('default');

  switch (id) {
    case 'Work_Allocation':
      return ((moduleAccess('all-tasks')) &&
        <Widget title='Work Allocation' size={12} {...props}>
          <WorkAllocation hideHeading />
        </Widget>
      );
      break;
    case 'Revenue_Stream':
      return ((moduleAccess('all-tasks')) &&
        <Widget title='Revenue Stream' size={6} {...props}>
          <RevenueStream hideHeading />
        </Widget>
      );
      break;
    case 'Task_originated_from_Task_types':
      return ((moduleAccess('all-tasks')) &&
        <Widget title='Task originated from Task types' size={12} {...props} height="auto">
          <TaskOriginated hideHeading />
        </Widget>
      );
      break;
    case 'Invoice_per_task_type':
      return ((moduleAccess('all-invoices')) &&
        <Widget title='Invoice per task type'  {...props}>
          <InvoicePerTaskType hideHeading />
        </Widget>
      );
      break;
    case 'Invoiced_vs_Paid':
      return ((moduleAccess('all-invoices')) &&
        <Widget title='Invoiced vs Paid' size={6} {...props}>
          <InvoiceVsPaid hideHeading />
        </Widget>
      );
      break;
    case 'Invoice_Type':
      return ((moduleAccess('all-invoices')) &&
        <Widget title='Invoice Type' size={6} {...props} >
          <InvoiceType hideHeading />
        </Widget>
      );
      break;
    case 'Invoice_Counts':
      return ((moduleAccess('all-invoices')) &&
        <Widget title='Invoice Counts' size={12} {...props} height='auto' hideCard>
          <Row>
            <InvoiceCount />
          </Row>
        </Widget>
      );
      break;
    case 'Sales_report':
      return ((moduleAccess('all-invoices')) &&
        <Widget title='Sales Report' size={6} {...props} >
          <SalesReport hideHeading />
        </Widget>
      );
      break;
    case 'Total_Paid':
      return ((moduleAccess('all-invoices')) &&
        <Widget title='Total_Paid' size={3} {...props} hideCard>
          <InvoiceCount widgetId="Total_Paid" />
        </Widget>
      );
      break;
    case 'Not_Paid':
      return ((moduleAccess('all-invoices')) &&
        <Widget title='Not Paid' size={3} {...props} hideCard>
          <InvoiceCount widgetId="Not_Paid" />
        </Widget>
      );
      break;
    case 'Partial_Paid':
      return ((moduleAccess('all-invoices')) &&
        <Widget title='Partial_Paid' size={3} {...props} hideCard>
          <InvoiceCount widgetId="Partial_Paid" />
        </Widget>
      );
      break;
    case 'Cancelled':
      return ((moduleAccess('all-invoices')) &&
        <Widget title='Cancelled' size={3} {...props} hideCard>
          <InvoiceCount widgetId="Cancelled" />
        </Widget>
      );
      break;
    case 'Credit_Notes':
      return ((moduleAccess('all-invoices')) &&
        <Widget title='Credit_notes' size={3} {...props} hideCard>
          <InvoiceCount widgetId="Credit_Notes" />
        </Widget>
      );
      break;
    case 'Time_spent_per_task_type_per_user':
      return (moduleAccess('all-time-sheets') &&
        <Widget title='Time spent per task type per user' size={12} height="auto" {...props}>
          <TimePerTaskPerUser hideHeading />
        </Widget>
      );
      break;
    case 'Time_sheet_vs_accrued_amount':
      return ((moduleAccess('all-time-sheets')) &&
        <Widget title='Time sheet vs accrued amount' size={6} {...props}>
          <TimesheetVsAccruedAmount hideHeading />
        </Widget>
      );
      break;
    case 'External_timesheet_vs_internal_timesheet':
      return (moduleAccess('all-time-sheets') &&
        <Widget title='External timesheet vs internal timesheet' size={6} {...props}>
          <ExternalVsInternal hideHeading />
        </Widget>
      );
      break;
    case 'Per_users_total_time_spent':
      return (moduleAccess('all-time-sheets') &&
        <Widget title='Per users total time spent' size={12} {...props}>
          <PerUserTaskTime hideHeading />
        </Widget>
      );
      break;
    case 'Timesheet_Counts':
      return (moduleAccess('all-time-sheets') &&
        <Widget title='Timesheet Counts' size={12} {...props} hideCard>
          <Row>
            <Counts />
          </Row>
        </Widget>
      );
      break;
    case 'Total_expenses':
      return (moduleAccess('all-time-sheets') &&
        <Widget title='Total expenses' size={3} {...props} hideCard>
          <Counts widgetId="Total_expenses" />
        </Widget>
      );
      break;
    case 'Budget_and_or_An_Hourly_rate':
      return (moduleAccess('all-time-sheets') &&
        <Widget title='Budget_and_or_An_Hourly_rate' size={3} {...props} hideCard>
          <Counts widgetId="Budget_and_or_An_Hourly_rate" />
        </Widget>
      );
      break;
    case 'Total_time_spent':
      return (moduleAccess('all-time-sheets') &&
        <Widget title='Total_time_spent' size={3} {...props} hideCard>
          <Counts widgetId="Total_time_spent" />
        </Widget>
      );
      break;
    case 'Consultant_service_charges':
      return (moduleAccess('all-time-sheets') &&
        <Widget title='Consultant_service_charges' size={3} {...props} hideCard>
          <Counts widgetId="Consultant_service_charges" />
        </Widget>
      );
      break;
    case 'Task_amount_vs_service_charge_incurred':
      return (moduleAccess('all-time-sheets') &&
        <Widget title='Task amount vs service charge incurred' size={6} {...props}>
          <TaskAmountVsServiceCharge hideHeading />
        </Widget>
      );
      break;
    case 'Per_user_per_task_summary':
      return (moduleAccess('all-time-sheets') &&
        <Widget title='Per user per task summary' size={12} {...props} height="auto">
          <PerUserPerTaskSummary hideHeading />
        </Widget>
      );
      break;
    case 'Per_user_total_task_time_spent':
      return (moduleAccess('all-time-sheets') &&
        <Widget title='Per user total task time spent' size={12} {...props}>
          <PerUserTaskTime hideHeading />
        </Widget>
      );
      break;
    case 'Percentage_of_time_spent_per_task':
      return (moduleAccess('all-time-sheets') &&
        <Widget title='Percentage of time spent per task' size={6} {...props}>
          <TimePerTask hideHeading />
        </Widget>
      );
      break;
    case 'Task_fee_vs_service_charge':
      return (moduleAccess('all-time-sheets') &&
        <Widget title='Task fee vs service charge' size={6} {...props}>
          <FeeVsServiceCharge hideHeading />
        </Widget>
      );
      break;
    case 'Tasks_By_Country':
      return (moduleAccess('all-tasks') &&
        <Widget title='Tasks By Country' size={6} {...props}>
          <TasksByCountry hideHeading />
        </Widget>
      );
      break;
    case 'Internal_Task_Vs_External_Task':
      return (moduleAccess('all-tasks') &&
        <Widget title='Internal Task Vs External Task' size={6} {...props}>
          <InternalTaskVsExternalTask hideHeading />
        </Widget>
      );
      break;
    case 'Task_generated_by_questionnaire':
      return (moduleAccess('all-tasks') &&
        <Widget title='Task generated by questionnaire' size={12} {...props}>
          <TaskGeneratedByQuestionnaire hideHeading />
        </Widget>
      );
      break;
    case 'Task_Count':
      return (moduleAccess('all-tasks') &&
        <Widget title='Task Count' size={12} {...props} height='auto' hideCard>
          <StatusWiseTaskCount hideHeading />
        </Widget>
      );
      break;

    case 'User_Count':
      return (moduleAccess('all-users') &&
        <Widget title='User Count' size={12} {...props} height='auto' hideCard>
          <Row><UserCount hideHeading /></Row>
        </Widget>
      );
      break;
    case 'INPAT':
      return (moduleAccess('all-users') &&
        <Widget title='INPAT' size={6} {...props}>
          <INPAT hideHeading />
        </Widget>
      );
      break;
    case 'Company_vs_Individual_User':
      return (moduleAccess('all-users') &&
        <Widget title='Company vs Individual User' size={6} {...props}>
          <CompanyVsIndividual hideHeading />
        </Widget>
      );
      break;
    case 'Resident_Vs_Non_resident_users':
      return (moduleAccess('all-users') &&
        <Widget title='Resident Vs Non resident users' size={6} {...props}>
          <ResidentVsNoResident hideHeading />
        </Widget>
      );
      break;
    case 'Active_vs_Non_active':
      return (moduleAccess('all-users') &&
        <Widget title='Active vs Non active' size={6} {...props}>
          <ActiveVsNonActiveUser hideHeading />
        </Widget>
      );
      break;
    case 'Total_user_statistics':
      return (moduleAccess('all-users') &&
        <Widget title='Total user statistics' size={6} {...props}>
          <TotalUserStatatics hideHeading />
        </Widget>
      );
      break;
    case 'Expired_user_vs_Non_expired_user':
      return (moduleAccess('all-users') &&
        <Widget title='Expired user vs Non expired user' size={6} {...props}>
          <ExpiredUserVsNonExpiredUser hideHeading />
        </Widget>
      );
      break;
    case 'Company_Count':
      return (moduleAccess('all-company') &&
        <Widget title='Company Count' size={12} {...props} hideCard>
          <Row><CompanyCount hideHeading /></Row>
        </Widget>
      );
      break;
    case 'Total_number_of_company':
      return (moduleAccess('all-company') &&
        <Widget title='Total number of company' size={6} {...props}>
          <Totalcompany hideHeading />
        </Widget>
      );
      break;
    case 'Active_Vs_Archived_company':
      return (moduleAccess('all-company') &&
        <Widget title='Active Vs Archived company' size={6} {...props}>
          <CompanyVsArchive hideHeading />
        </Widget>
      );
      break;
    case 'Total_number_of_companies_created_per_quarter':
      return (moduleAccess('all-company') &&
        <Widget title='Total number of companies created' size={6} {...props}>
          <CompanyPerQuarter hideHeading />
        </Widget>
      );
      break;
    case 'Company_Vs_Taxconsult_user':
      return (moduleAccess('all-company') &&
        <Widget title='Company Vs Taxconsult user' size={6} {...props}>
          <CompanyVsEmployee hideHeading />
        </Widget>
      );
      break;
    case 'Total_number_of_companies_created_per_quarter_pie':
      return (moduleAccess('all-company') &&
        <Widget title='Total number of companies created per quarter' size={6} {...props}>
          <QuarterCompany hideHeading />
        </Widget>
      );
      break;
    case 'CPO_Count':
      return (moduleAccess('all-cpo') &&
        <Widget title='CPO Count' size={12} {...props} hideCard>
          <CPOHitsCount hideHeading />
        </Widget>
      );
      break;
    case 'Website_users_Vs_Customer':
      return (moduleAccess('all-cpo') &&
        <Widget title='Website users Vs Customer' size={6} {...props}>
          <CPOWebsiteUserVsCustomer hideHeading />
        </Widget>
      );
      break;
    case 'CPO_Hits':
      return (moduleAccess('all-cpo') &&
        <Widget title='CPO Hits' size={6} {...props}>
          <CPOHits hideHeading />
        </Widget>
      );
      break;
    case 'Website_user_become_customer':
      return (moduleAccess('all-cpo') &&
        <Widget title='Website user become customer' size={6} {...props}>
          <CPOWebsiteUserBecomeCustomer hideHeading />
        </Widget>
      );
      break;
    case 'Split_Payroll_Count':
      return (moduleAccess('all-split-payroll') &&
        <Widget title='Split Payroll Count' size={12} {...props} hideCard>
          <SplitPayrollDailyCount hideHeading />
        </Widget>
      );
      break;
    case 'Company_Vs_Individual':
      return (moduleAccess('all-split-payroll') &&
        <Widget title='Split Payroll Company Vs Individual' size={6} {...props}>
          <SplitPayrollCompanyVsIndividual hideHeading />
        </Widget>
      );
      break;
    case 'SplitPayroll_Created_by_Company':
      return (moduleAccess('all-split-payroll') &&
        <Widget title='Split Payroll created by company' size={6} {...props}>
          <SplitCreatedByCompany hideHeading />
        </Widget>
      );
      break;
    case 'Questionnaire_Counts':
      return (moduleAccess('all-questionnaires') &&
        <Widget title='Questionnaire Count' size={6} {...props}>
          <CountsQuestionnaire hideHeading />
        </Widget>
      );
      break;
    case 'Questionnaire_Type':
      return (moduleAccess('all-questionnaires') &&
        <Widget title='Questionnaire Type' size={12} {...props}>
          <AllTypes hideHeading />
        </Widget>
      );
      break;
    case 'Sent_Vs_Finalized':
      return (moduleAccess('all-questionnaires') &&
        <Widget title='Sent Vs Finalized' size={6} {...props}>
          <Status hideHeading />
        </Widget>
      );
    case 'Resident_Vs_Non_resident':
      return (moduleAccess('all-questionnaires') &&
        <Widget title='Resident Vs Non-resident' size={6} {...props}>
          <MoreCharts hideHeading />
        </Widget>
      );
      break;
    case 'Questionnaire_type_per_year':
      return (moduleAccess('all-questionnaires') &&
        <Widget title='Questionnaire type per year' size={6} {...props}>
          <YearData hideHeading />
        </Widget>
      );
      break;
    case 'Reminder_sent_for_payment_due':
      return (moduleAccess('all-invoices') &&
        <Widget title='Reminder sent for payment due' size={6} {...props}>
          <ReminderPaymentDue hideHeading />
        </Widget>
      );
      break;
    case 'Invoice_Generated_from_General_Account':
      return (moduleAccess('all-invoices') &&
        <Widget title='Invoice Generated from General Account' size={6} {...props}>
          <InvoiceGeneratedFromGeneralAccount hideHeading />
        </Widget>
      );
      break;
    case 'Cash_Flow':
      return (moduleAccess('all-invoices') &&
        <Widget title='Cash Flow' size={6} {...props}>
          <CashFlow hideHeading />
        </Widget>
      );
      break;
    case 'Top_Clients':
      return (moduleAccess('all-invoices') &&
        <Widget title='Top Clients' size={6} {...props}>
          <TopClients hideHeading />
        </Widget>
      );
      break;
    case 'Schedule_of_Payment_clients_History':
      return (moduleAccess('all-invoices') &&
        <Widget title='Schedule of Payment clients History' size={6} {...props}>
          <ScheduleOfPaymentsClientHistory hideHeading />
        </Widget>
      );
      break;
    case 'Invoices':
      return (
        <Widget title='Invoices' size={6} {...props}>
          <InvoiceActivityList hideHeading />
        </Widget>
      );
      break;
    case 'New_Clients':
      return (
        <Widget title='New Clients' size={6} {...props}>
          <CompanyUserAlert hideHeading />
        </Widget>
      );
      break;
    case 'Expiry_reminder':
      return (
        <Widget title='Expiry reminder' size={12} {...props} height="auto">
          <ExpiryReminder hideHeading />
        </Widget>
      );
      break;
    case 'Invoice_Trends':
      return (
        <Widget title='Invoice Trends' size={12} {...props}>
          <TodaysTrends hideHeading />
        </Widget>
      );
      break;
    case 'Invoice_generated_direactly_from_timesheet':
      return (
        <Widget title='Invoice Trends' size={12} {...props}>
          <InvoiceGeneratedFromTimesheet hideHeading />
        </Widget>
      );
      break;
    default: return (<div></div>)

  }
}