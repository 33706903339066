import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  Row,
} from "reactstrap";
import DataTable from "../../components/Common/DataTable";
import Select from "react-select";
import Breadcrumbs from "../../components/Layout/Breadcrumb";
import {
  handleFilters,
  getDataTableQuery,
  filteredByFunction,
  select2Styles,
  archiveSetDisabled,
} from "../../helpers/functions_helper";
import clientAllocationService from "../../services/client.allocation.service";
import { useDispatch, useSelector } from "react-redux";
import { CommonAction } from "../../store/actions";
import { dateFormat } from "../../utils/formats";
import Drawer from "../../components/Drawer";
import EditCompany from "../Masters/Company/EditCompany";
import EditUser from "../Users/EditUser";
import EditCompanyEmployees from "../Masters/Company/EditCompanyEmployees";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../components/Button/Button";
import Filters from "./Filters";

const ClientAllocationClientsList = (props: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const toastId = React.useRef(null) as any;

  const moduleName = t("Client allocation");
  document.title = moduleName + " | " + process.env.REACT_APP_NAME;

  const [searchParams, setSearchParams] = useSearchParams();
  const filtersData = JSON.parse(searchParams.get("filters") as any);

  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "#",
      label: moduleName,
      active: true,
    },
  ];

  const savedfilters = handleFilters("GET");

  const [loading, setLoading] = useState(false);
  const [loadingListData, setLoadingListData] = useState(true);
  const [datatableListData, setDatatableListData] = useState<any>([]);
  const [companyUsersOptions, setCompanyUsersOptions] = useState<Array<any>>(
    []
  );
  const [companyPartnerOptions, setCompanyPartnerOptions] = useState<
    Array<any>
  >([]);
  const [companyAssistantOptions, setCompanyAssistantOptions] = useState<
    Array<any>
  >([]);
  const [selectedData, setSelectedData] = useState<any>();
  const [selectedType, setSelectedType] = useState<any>();
  const [openCompany, setOpenCompany] = useState<boolean>(false);
  const [openUser, setOpenUser] = useState<boolean>(false);
  const [openEmployeesList, setOpenEmployeesList] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const columns = [
    {
      dataField: "DT_RowIndex",
      text: t("Sr. No."),
      sort: false,
      search: false,
      classes: "fix-col-left",
      headerClasses: "fix-col-left",
      headerStyle: { width: "65px" },
      style: { width: "65px" },
    },
    {
      dataField: "client_name",
      text: t("Client name"),
      sort: true,
      search: true,
      classes: "fix-col-left",
      headerClasses: "fix-col-left",
      headerStyle: { left: "65px" },
      style: { left: "65px" },
      formatter: (rowContent: any, row: any): any => {
        return (
          <>
            <span
              className="link-primary text-decoration-underline"
              onClick={() =>
                row?.client_type === 1
                  ? handleCompanyClick(row)
                  : handleUserClick(row)
              }
            >
              {row.client_name}
            </span>

            {!row.preparer &&
              !row.reviewer &&
              !row.file_manager &&
              !row.signing_partner &&
              !row.assistant_administrative && (
                <span
                  className="badge_custome badge-soft-info badge rounded-pill ms-2"
                  title={t("Not allocated")}
                >
                  {t("Not allocated")}
                </span>
              )}
          </>
        );
      },
    },
    {
      dataField: "client_type",
      text: t("Client type"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return row?.client_type === 1 ? (
          <span
            className="badge_custome badge-soft-primary badge rounded-pill"
            title="Company"
          >
            {t("Company")}
          </span>
        ) : (
          <span
            className="badge_custome badge-soft-info badge rounded-pill"
            title="Individual"
          >
            {t("Individual")}
          </span>
        );
      },
    },
    // {
    //   dataField: "client_status",
    //   text: t("Status"),
    //   sort: true,
    //   search: true,
    //   formatter: (rowContent: any, row: any): any => {
    //     return row.is_archive === 1 ? (
    //       <span
    //         className="badge_custome badge-soft-danger badge rounded-pill"
    //         title="Archived"
    //       >
    //         {row.client_status}
    //       </span>
    //     ) : (
    //       <span
    //         className="badge_custome badge-soft-primary badge rounded-pill"
    //         title="Active"
    //       >
    //         {row.client_status}
    //       </span>
    //     );
    //   },
    // },
    {
      dataField: "preparer_value",
      text: t("Preparer"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any, idx: number): any => {
        return (
          <div style={{ width: "200px" }}>
            <Select
              id={`preparer_${row.id}`}
              name="preparer[]"
              placeholder={t("Select...")}
              styles={select2Styles}
              options={companyUsersOptions}
              defaultValue={
                row.preparer
                  ? companyUsersOptions.find(
                      (option: any) => option.value == row.preparer
                    )
                  : ""
              }
              isSearchable={true}
              onChange={(e: any) => {
                handleChange("preparer", e.value, idx, row);
              }}
            />
          </div>
        );
      },
    },
    {
      dataField: "reviewer_value",
      text: t("Reviewer"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any, idx: number): any => {
        return (
          <div style={{ width: "200px" }}>
            <Select
              id={`reviewer_${row.id}`}
              name="reviewer[]"
              placeholder={t("Select...")}
              styles={select2Styles}
              options={companyUsersOptions}
              defaultValue={
                row.reviewer
                  ? companyUsersOptions.find(
                      (option: any) => option.value == row.reviewer
                    )
                  : ""
              }
              isSearchable={true}
              onChange={(e: any) => {
                handleChange("reviewer", e.value, idx, row);
              }}
            />
          </div>
        );
      },
    },
    {
      dataField: "file_manager_value",
      text: t("File manager"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any, idx: number): any => {
        return (
          <div style={{ width: "200px" }}>
            <Select
              id={`file_manager_${row.id}`}
              name="file_manager[]"
              placeholder={t("Select...")}
              styles={select2Styles}
              options={companyUsersOptions}
              defaultValue={
                row.file_manager
                  ? companyUsersOptions.find(
                      (option: any) => option.value == row.file_manager
                    )
                  : ""
              }
              isSearchable={true}
              onChange={(e: any) => {
                handleChange("file_manager", e.value, idx, row);
              }}
            />
          </div>
        );
      },
    },
    {
      dataField: "signing_partner_value",
      text: t("Partner"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any, idx: number): any => {
        return (
          <div style={{ width: "200px" }}>
            <Select
              id={`partner_${row.id}`}
              name="partner[]"
              placeholder={t("Select...")}
              styles={select2Styles}
              options={companyPartnerOptions}
              defaultValue={
                row.signing_partner
                  ? companyPartnerOptions.find(
                      (option: any) => option.value == row.signing_partner
                    )
                  : ""
              }
              isSearchable={true}
              onChange={(e: any) => {
                handleChange("signing_partner", e.value, idx, row);
              }}
            />
          </div>
        );
      },
    },
    {
      dataField: "assistant_administrative_value",
      text: t("Assistant administrative"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any, idx: number): any => {
        return (
          <div style={{ width: "200px" }}>
            <Select
              id={`assistant_administrative_${row.id}`}
              name="assistant_administrative[]"
              placeholder={t("Select...")}
              styles={select2Styles}
              options={companyAssistantOptions}
              defaultValue={
                row.assistant_administrative
                  ? companyAssistantOptions.find(
                      (option: any) =>
                        option.value == row.assistant_administrative
                    )
                  : ""
              }
              isSearchable={true}
              onChange={(e: any) => {
                handleChange("assistant_administrative", e.value, idx, row);
              }}
            />
          </div>
        );
      },
    },
    // {
    //   dataField: "client_count",
    //   text: "Count of Employees",
    //   sort: true,
    //   search: true,
    //   formatter: (rowContent: any, row: any): any => {
    //     return (
    //       <>
    //         {parseInt(row?.client_count) > 0 ? (
    //           <>
    //             {row?.client_count}

    //             {Number(row.client_count) - Number(row.archive_count) > 0 ? (
    //               <span
    //                 className="badge_custome badge-soft-primary badge rounded-pill ms-2"
    //                 title="Active user"
    //                 onClick={() => handleEmployeesClick(row, "all")}
    //               >
    //                 {Number(row.client_count) - Number(row.archive_count)} -
    //                 ACTIVE
    //               </span>
    //             ) : null}

    //             {parseInt(row?.archive_count) > 0 ? (
    //               <span
    //                 className="badge_custome badge-soft-danger badge rounded-pill ms-2"
    //                 title="Archived user"
    //                 onClick={() => handleEmployeesClick(row, "archive")}
    //               >
    //                 {row.archive_count} - ARCHIVED
    //               </span>
    //             ) : null}
    //           </>
    //         ) : (
    //           row.client_count
    //         )}
    //       </>
    //     );
    //   },
    // },
    {
      dataField: "client_count",
      text: t("Count of Employees"),
      sort: true,
      search: false,
      formatter: (rowContent: any, row: any): any => {
        return parseInt(row?.client_count) > 0 ? (
          <span
            className="link-primary text-decoration-underline"
            onClick={() => handleEmployeesClick(row, "all")}
          >
            {row?.client_count}
          </span>
        ) : (
          row?.client_count
        );
      },
    },
    {
      dataField: "contact_person_value",
      text: t("Contact person (Company)"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return (
          <>
            {row?.client_type === 1 ? (
              <div
                className="link-primary"
                onClick={() =>
                  handleUserClick({
                    id: row.contact_person,
                    client_id: row.contact_person_id,
                  })
                }
              >
                {row.contact_person_value}
              </div>
            ) : (
              row.contact_person_value
            )}
          </>
        );
      },
    },
    {
      dataField: "invoice_contact_person_value",
      text: t("Contact person (Invoice)"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return (
          <>
            {row?.client_type === 1 ? (
              <div
                className="link-primary"
                onClick={() =>
                  handleUserClick({
                    id: row.invoice_contact_person,
                    client_id: row.invoice_contact_person_id,
                  })
                }
              >
                {row.invoice_contact_person_value}
              </div>
            ) : (
              row.invoice_contact_person_value
            )}
          </>
        );
      },
    },
    {
      dataField: "hr_contact_person_value",
      text: t("Contact person (HR)"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return (
          <>
            {row?.client_type === 1 ? (
              <div
                className="link-primary"
                onClick={() =>
                  handleUserClick({
                    id: row.hr_contact_person,
                    client_id: row.hr_contact_person_id,
                  })
                }
              >
                {row.hr_contact_person_value}
              </div>
            ) : (
              row.hr_contact_person_value
            )}
          </>
        );
      },
    },
    {
      dataField: "gen_contact_person_value",
      text: t("Contact person (General)"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return (
          <>
            {row?.client_type === 1 ? (
              <div
                className="link-primary"
                onClick={() =>
                  handleUserClick({
                    id: row.gen_contact_person,
                    client_id: row.gen_contact_person_id,
                  })
                }
              >
                {row.gen_contact_person_value}
              </div>
            ) : (
              row.gen_contact_person_value
            )}
          </>
        );
      },
    },
    // {
    //   dataField: "created_by_value",
    //   text: t("Created by"),
    //   sort: true,
    //   search: true,
    // },
    // {
    //   dataField: "created_at",
    //   text: t("Created on"),
    //   sort: true,
    //   search: true,
    //   formatter: (rowContent: any, row: any): any => {
    //     return dateFormat(
    //       row.created_at,
    //       {
    //         dateStyle: "long",
    //         timeStyle: "short",
    //         hour12: true,
    //       },
    //       "en-US"
    //     );
    //   },
    // },
    // {
    //   dataField: "updated_by_value",
    //   text: t("Updated by"),
    //   sort: true,
    //   search: true,
    // },
    // {
    //   dataField: "updated_at",
    //   text: t("Updated on"),
    //   sort: true,
    //   search: true,
    //   formatter: (rowContent: any, row: any): any => {
    //     return row.updated_by
    //       ? dateFormat(
    //           row.created_at,
    //           {
    //             dateStyle: "long",
    //             timeStyle: "short",
    //             hour12: true,
    //           },
    //           "en-US"
    //         )
    //       : null;
    //   },
    // },
  ];

  const [datatableOptions, setDatatableOptions] = useState(
    // savedfilters && savedfilters?.datatableOptions
    //   ? (savedfilters?.datatableOptions as any)
    //   : 
      {
          draw: 1,
          page: 1,
          recordsTotal: 0,
          recordsFiltered: 0,
          sizePerPage: 20,
          searchText: "",
          sortField: "client_name",
          sortOrder: "asc",
        }
  );
  const [filters, setFilters] = useState(
    // savedfilters && savedfilters?.filters
    //   ? (savedfilters?.filters as any)
    //   : 
      {
          is_filtered: false,
          user_id: "",
          is_preparer: "",
          is_reviewer: "",
          is_file_manager: "",
          is_partner: "",
          is_assistant_administrative: "",
          type: "",
          status: "",
          status_for: "",
        }
  );

  const onTableChange = (type: any, newState: any) => {
    let draw = datatableOptions.draw + 1;
    let page = 1;
    if (type == "search") {
      page = 1;
    } else {
      page = newState.page;
    }
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: draw,
      page: page,
      sizePerPage: newState.sizePerPage,
      searchText: newState.searchText,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
    };
    setDatatableOptions(updatedDatatableOptions);
    setDatatableListData([]);
    setLoadingListData(true);
  };

  const clearFilter = () => {
    if (filtersData) {
      navigate("/client-allocation/clients");
    }

    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: 1,
      page: 1,
      sizePerPage: 20,
      searchText: "",
      sortField: "id",
      sortOrder: "desc",
    };
    setDatatableOptions(updatedDatatableOptions);

    const filter = {
      ...filters,
      is_filtered: false,
      user_id: "",
      is_preparer: "",
      is_reviewer: "",
      is_file_manager: "",
      is_partner: "",
      is_assistant_administrative: "",
      type: "",
      status: "",
      status_for: "",
    };
    setFilters(filter);

    setDatatableListData([]);
    setLoadingListData(true);
  };

  const loadingDatatable = async () => {
    try {
      const newFilters: any = { ...filters };
      if (filtersData && filtersData?.user_id) {
        newFilters.user_id = filtersData?.user_id;
      } else {
        newFilters.user_id = "";
      }
      if (filtersData && filtersData?.is_preparer) {
        newFilters.is_preparer = filtersData?.is_preparer;
      } else {
        newFilters.is_preparer = "";
      }
      if (filtersData && filtersData?.is_reviewer) {
        newFilters.is_reviewer = filtersData?.is_reviewer;
      } else {
        newFilters.is_reviewer = "";
      }
      if (filtersData && filtersData?.is_file_manager) {
        newFilters.is_file_manager = filtersData?.is_file_manager;
      } else {
        newFilters.is_file_manager = "";
      }
      if (filtersData && filtersData?.is_partner) {
        newFilters.is_partner = filtersData?.is_partner;
      } else {
        newFilters.is_partner = "";
      }
      if (filtersData && filtersData?.is_assistant_administrative) {
        newFilters.is_assistant_administrative =
          filtersData?.is_assistant_administrative;
      } else {
        newFilters.is_assistant_administrative = "";
      }

      const res = (await clientAllocationService.getAllClients({
        ...getDataTableQuery(datatableOptions, columns),
        newFilters,
      })) as any;

      if (res.data.status === "success") {
        setDatatableListData(res.data.data);

        const updatedDatatableOptions = {
          ...datatableOptions,
          recordsTotal: res.data.recordsTotal,
          recordsFiltered: res.data.recordsFiltered,
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingListData(false);
      } else {
        setDatatableListData([]);

        const updatedDatatableOptions = {
          ...datatableOptions,
          recordsTotal: 0,
          recordsFiltered: 0,
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingListData(false);
      }
    } catch (err) {
      setDatatableListData([]);

      const updatedDatatableOptions = {
        ...datatableOptions,
        recordsTotal: 0,
        recordsFiltered: 0,
      };
      setDatatableOptions(updatedDatatableOptions);
      setLoadingListData(false);
    }
  };

  const handleCompanyClick = (data: any) => {
    setSelectedData(data);
    setOpenCompany(true);
  };

  const handleUserClick = (data: any) => {
    setSelectedData(data);
    setOpenUser(true);
  };

  const handleEmployeesClick = (data: any, type: string) => {
    setSelectedData(data);
    setSelectedType(type);
    setOpenEmployeesList(true);
  };

  const update = async (values: any) => {
    try {
      setLoading(true);
      toast.dismiss();

      toastId.current = toast(t("Updating client..."), {
        autoClose: false,
        isLoading: true,
      });

      const res = (await clientAllocationService.updateClient(values)) as any;
      if (res.data.status === "success") {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoading(false);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        if (res.data.data) {
          Object.keys(res.data.data).map((key) => {
            toast(res.data.data[key][0], {
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              delay: 500,
            });
          });
        }
        setLoading(false);
      }
    } catch (err: any) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }
  };

  const handleChange = (
    fieldName: string,
    fieldValue: any,
    fieldIdx: number,
    rowData: any
  ) => {
    // console.log("fieldName", fieldName);
    // console.log("fieldValue", fieldValue);
    // console.log("fieldIdx", fieldIdx);
    // console.log("rowData", rowData);

    update({
      id: rowData.id,
      client_type: rowData.client_type,
      field_name: fieldName,
      field_value: fieldValue,
    });
  };

  let { getUserOptionsList } = useSelector((state: any) => ({
    getUserOptionsList: state.Common.GET_USER_OPTIONS_LIST_SUCCESS,
  }));

  // Filters -------------------------------------------------
  const handleFiltersChange = (obj: any) => {
    setFilters((state: any) => ({ ...state, ...obj }));
  };

  const handleApplyFilters = () => {
    setDatatableListData([]);
    setLoadingListData(true);
    setOpenFilter(false);
  };
  // Filters -------------------------------------------------

  useEffect(() => {
    if (!getUserOptionsList) dispatch(CommonAction.getUserOptionsList());
  }, []);

  useEffect(() => {
    if (getUserOptionsList.data) {
      // All
      let allData = archiveSetDisabled(getUserOptionsList.data) as any;
      setCompanyUsersOptions(allData);

      // Partners
      let partnerData = archiveSetDisabled(
        filteredByFunction(getUserOptionsList.data, [6]) as any
      );
      setCompanyPartnerOptions(partnerData);

      // Assistant Administrative
      let assistantData = archiveSetDisabled(
        filteredByFunction(getUserOptionsList.data, [1]) as any
      );
      setCompanyAssistantOptions(assistantData);
    }
  }, [getUserOptionsList]);

  useEffect(() => {
    if (loadingListData === true && getUserOptionsList.data) {
      loadingDatatable();
    }
  }, [loadingListData, getUserOptionsList]);

  useEffect(() => {
    setDatatableListData([]);
    setLoadingListData(true);
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={moduleName}
            breadcrumbItems={breadcrumbItems}
          />

          <Row>
            <Col xl="12">
              <Card>
                <CardHeader className="d-sm-flex flex-wrap bg-transparent border-bottom pb-0">
                  <div className="d-flex">
                    <Nav tabs className="nav-tabs-custom">
                      <NavItem>
                        <Link
                          style={{ cursor: "pointer" }}
                          to="/client-allocation/internal-users"
                          className={`nav-link ${
                            window.location.pathname ===
                            "/client-allocation/internal-users"
                              ? "active"
                              : ""
                          }`}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home"></i>
                          </span>
                          <span className="d-none d-sm-block">
                            {t("Users")}
                          </span>
                        </Link>
                      </NavItem>
                      <NavItem>
                        <Link
                          style={{ cursor: "pointer" }}
                          to="/client-allocation/clients"
                          className={`nav-link ${
                            window.location.pathname ===
                            "/client-allocation/clients"
                              ? "active"
                              : ""
                          }`}
                        >
                          <span className="d-block d-sm-none">
                            <i className="fas fa-home"></i>
                          </span>
                          <span className="d-none d-sm-block">
                            {t("Clients")}
                          </span>
                        </Link>
                      </NavItem>
                    </Nav>
                  </div>
                </CardHeader>

                <CardBody>
                  <DataTable
                    columns={columns}
                    products={datatableListData}
                    datatableOptions={datatableOptions}
                    filters={filters}
                    onTableChange={onTableChange}
                    loadingListData={loadingListData}
                    clearFilter={clearFilter}
                    settingTableName="client_allocation_table"
                    slug="client-allocation"
                    customButtons={
                      <Button
                        title="Filter"
                        outline={filters.is_filtered === true ? false : true}
                        color={
                          filters.is_filtered === true ? "secondary" : "light"
                        }
                        onClick={() => setOpenFilter((state) => !state)}
                      >
                        {filters.is_filtered === false ? (
                          <span className="align-middle material-symbols-outlined fw-light fs-4">
                            filter_alt
                          </span>
                        ) : (
                          <span className="align-middle material-symbols-outlined fw-light fs-4">
                            filter_alt_off
                          </span>
                        )}
                      </Button>
                    }
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Drawer
        title="Filters"
        open={openFilter}
        onClose={() => setOpenFilter((state) => !state)}
        onReset={clearFilter}
        footer={
          <Button color="primary" className="m-3" onClick={handleApplyFilters}>
            {t("Apply")}
          </Button>
        }
      >
        <Filters
          filters={filters}
          onApply={() => {}}
          onClear={() => {}}
          onChange={(obj: any) => handleFiltersChange(obj)}
        />
      </Drawer>

      <Drawer
        title="Company"
        open={openCompany}
        onClose={() => setOpenCompany((state) => !state)}
        fullWidth
      >
        <EditCompany
          id={selectedData?.id}
          company_id={selectedData?.client_id}
          type={1}
          isWindow={true}
          onUpdate={() => setLoadingListData(true)}
        />
      </Drawer>

      <Drawer
        title="User"
        open={openUser}
        onClose={() => setOpenUser((state) => !state)}
        fullWidth
      >
        <EditUser
          id={selectedData?.id}
          user_id={selectedData?.client_id}
          type={1}
          isWindow={true}
        />
      </Drawer>

      <Drawer
        title="Employees"
        open={openEmployeesList}
        onClose={() => setOpenEmployeesList((state) => !state)}
        fullWidth
      >
        <EditCompanyEmployees
          id={selectedData?.id}
          company_id={selectedData?.client_id}
          type={selectedType}
          isWindow={true}
        />
      </Drawer>
    </React.Fragment>
  );
};

export default ClientAllocationClientsList;
