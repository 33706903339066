import React, { FC, useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { CardTitle } from "reactstrap";
import { noData } from "../../../../utils/data";
import { toast } from "react-toastify";
import { currentMonth, currentYear, graphviewOptions, options } from "../../../../common/staticdata";
import { getUserProfile, select2Styles } from "../../../../helpers/functions_helper";
import LoadingSpinner from "../../../../components/Common/Loader";
import mastersCompanyService from "../../../../services/masters.company.service";
import { serialize } from "../../../../utils/utils";
import GraphFilters from "./Filters";
import ChartFilters from "../../../../components/Chart/Filters";
import Chart from "../../../../components/Chart";
type CompanyPerQuarterProps = {
    hideHeading?: boolean;
    filters?: any;
}
const CompanyPerQuarter: FC<CompanyPerQuarterProps> = ({ hideHeading = false, filters = null }) => {

    const { t } = useTranslation();
    const userObj = getUserProfile();
    const inititalFilter = {
        week: null,
        quarter: null,
        month: null,
        year: currentYear,
        user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_ID : null,
        graphview: 'quarter',
    };

    const [loading, setLoading] = useState<boolean>(true);
    const [query, setQuery] = useState<string | null>(serialize(inititalFilter));
    const [filter, setFilter] = useState<any>(inititalFilter);
    const [multiYear, setMultiYear] = useState(false);

    const [graphOptions, setGraphOptions] = useState([]);

    useEffect(() => {
        if (graphviewOptions) {
            const graphOptions = graphviewOptions?.map((option: any) => ({
                label: t(option.label),
                value: option.value
            }));
            setGraphOptions(graphOptions);
        }
        clearWorkAllocationFilter();
    }, [localStorage.getItem("I18N_LANGUAGE")]);

    const [chartData, setChartData] = useState<any>({
        options: {
            chart: {
                id: 'apexchart-example',
                toolbar: {
                    show: false
                }
            },
            dataLabels: {
                enabled: false,
                enabledOnSeries: undefined,
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            yaxis: {
                labels: {
                    // formatter: function (value: any) {
                    //     return formatCurrency(value);
                    // }
                },
            },
            noData: noData
        },
        series: [{
            name: 'Total Count',
            data: []
        }]
    });

    const getChartData = async () => {
        setLoading(true)
        try {
            const res = await mastersCompanyService.getCompaniesPerTask(query);
            if (res.status === 'success') {
                setChartData((data: any) => ({
                    ...data,
                    series: res?.data?.yaxis.reduce((accumulator: number, currentValue: number) => {
                        return accumulator + currentValue
                    }, 0) > 0 ? [{ name: t('Company'), data: res?.data?.yaxis }] : [],
                    options: {
                        xaxis: {
                            type: 'string',
                            categories: res?.data?.xaxis, labels: { show: true }
                        },
                        tooltip: {
                            enabled: true,

                        },
                    }
                }))
            }
            setLoading(false);
        } catch (error: any) {
            toast(error)
        }
    }


    const clearWorkAllocationFilter = () => {
        setFilter(inititalFilter);
        setMultiYear(false);
    }

    useEffect(() => {
        loading && getChartData();
    }, [loading])


    const change_comma_string = (data: any) => {
        let ar: any = [];
        if (Array.isArray(data)) {
            (data.length > 1) ? setMultiYear(true) : setMultiYear(false);
            data.map((item: any) => {
                ar.push(item.value);
            })
        }
        return ar.join(',');
    }

    useEffect(() => {
        if (filter.year) {
            filter.year = change_comma_string(filter.year);
        }
        // if(!filter.graphview){
        //     filter.graphview = "quarter";
        // }
        setQuery(serialize(filter));
        setLoading(true);
    }, [filter])

    useEffect(() => {
        filters && setFilter(filters)
    }, [filters])

    return (
        <>
            <div className="d-sm-flex flex-wrap">
                <h4 className="card-title mb-4">{!hideHeading && t("Total number of companies created")}</h4>
                <div className="d-sm-flex ms-auto">
                    <Select
                        id="graphview"
                        name="graphview"
                        className="form-select-sm myClassName mx-1"
                        styles={select2Styles}
                        value={graphOptions?.find((i: any) => i.value === filter.graphview)}
                        options={graphOptions}
                        onChange={(option: any) => {
                            setFilter((state: any) => ({ ...state, graphview: option.value }))
                        }}
                        isDisabled={multiYear}
                    />
                    <ChartFilters
                        filter={filter}
                        setFilter={setFilter}
                        clearFilter={clearWorkAllocationFilter}
                    // yearOnly={true}
                    // multiYear={multiYear}
                    />
                </div>
            </div>
            <div className="clearfix"></div>
            {loading ? <LoadingSpinner height={320} /> : <Chart options={chartData.options} series={chartData.series} type="bar" height={380} />}
        </>

    )
}

export default CompanyPerQuarter;