import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, CardBody, CardFooter, CardTitle, Col, Label, Row, Form, FormFeedback } from 'reactstrap';
import newsLetterService from '../../../services/news.letter.service';
import { useTranslation } from 'react-i18next';
import Flatpickr from "react-flatpickr";
//import AsyncCreatableSelect from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import commonService from '../../../services/common.service';
import { debounce, map } from 'lodash';
import { toast } from "react-toastify";
import emailService from "../../../services/email.service";
import Select from "react-select";
import { typeOptions3 } from "../../../common/staticdata";
import SimpleBar from "simplebar-react";
import moment from 'moment'
import { useFormik } from "formik";
import * as Yup from "yup";
import { getFirstErrorElement, getUserProfile, timeNow } from "../../../helpers/functions_helper";
import CustomeDropDownOption from '../../../components/Common/CustomeDropDownOption';

const isSameOrBeforeTime = (publicationTime: any, datee: any) => {
    if (datee?.parent?.estimated_date) {
        if (moment(moment().format('Y-m-d')).isSame(datee?.parent?.estimated_date)) {
            console.log(moment().format('HH:mm'), publicationTime)
            return moment(moment().format('HH:mm')).isBefore(publicationTime)
        }
    }
    else
        return true
}

const Schedule: FC<any> = ({ data }) => {
    const { t } = useTranslation();
    const [initialValues, setInitialValues] = useState<any>({ estimated_time: '', estimated_date: '', applies_to: '', target: [] });
    const [scheduleHistory, setScheduleHistory] = useState<any>([]);
    const [preHistory, setPreHistory] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilter] = useState('');
    const [query, setQuery] = useState("");
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const toastId = useRef(null) as any;
    const userProfile = getUserProfile("news-letter");
    const handleChange = (fieldName: string, fieldValue: string) => {
        setInitialValues((preState: any) => ({ ...preState, [fieldName]: fieldValue }));
    };

    const validationSchema = Yup.object().shape({
        estimated_date: Yup.string()
            .required("Estimated date field is required."),
        estimated_time: Yup.string()
            .required("Estimated time field is required.")
            .test("estimated_time", "Estimated time should be future", function (value, dateContext) {
                if (moment(dateContext.parent.estimated_date).isSame(moment(), 'day') && value) {
                    let e_time = moment(value, "HH:mm A");
                    if (e_time.isAfter()) {
                        return true;
                    } else {
                        return false
                    }
                }
                return false;
            }),
        applies_to: Yup.string()
            .required("Applies to field is required."),
    });

    const validation = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            createSchedule(values);
        },
    });

    const loadOptions = async (keyword: string) => {
        const filter = {
            search: keyword,
            extra: filters,
            news_letter_subscription: true
        };
        const user_email = await emailService.searchUser(filter) as any;
        return user_email?.data?.data || [];
    };
    const loadSuggestedOptions = useCallback(
        debounce((inputValue, callback) => {
            loadOptions(inputValue).then(options => callback(options))
        }, 500), []);


    const createSchedule = async (value?: any) => {
        console.log(value);
        const postData = {
            FK_id: data.id,
            news_letter_id: data.news_letter_id,
            ...initialValues
        }
        toastId.current = toast("Creating news letter schedule...", {
            autoClose: false,
            isLoading: true,
        });
        const res = await newsLetterService.createNewsLetterSchedule(postData);
        if (res.status === "success") {
            setInitialValues(res?.data);
            toast.update(toastId.current, {
                type: toast.TYPE.SUCCESS,
                render: "News letter schedule created successfully",
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });
            getSchedule();
        } else {
            toast.update(toastId.current, {
                type: toast.TYPE.ERROR,
                render: "Error",
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });
        }
    }


    const updateSchedule = async () => {
        const postData = {
            FK_id: data.id,
            news_letter_id: data.news_letter_id,
            ...initialValues
        }
        toastId.current = toast("Updating news letter schedule...", {
            autoClose: false,
            isLoading: true,
        });
        const res = await newsLetterService.updateNewsLetterSchedule(postData);
        if (res.status === "success") {
            setInitialValues(res?.data);
            toast.update(toastId.current, {
                type: toast.TYPE.SUCCESS,
                render: "News letter schedule updated successfully",
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });
            getSchedule();
        } else {
            toast.update(toastId.current, {
                type: toast.TYPE.ERROR,
                render: "Error",
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });
        }
    }


    const getSchedule = async () => {
        const res = await newsLetterService.getNewsLetterSchedule(data.id, data.news_letter_id)
        if (res.status === "success") {
            setScheduleHistory(res?.data?.data);
            setPreHistory(res?.data?.history);
        }
    }

    useEffect(() => { getSchedule() }, [])

    const editSchedule = (data: any) => {
        const iData = {
            estimated_time: data?.time,
            estimated_date: data?.schedule_date,
            applies_to: data?.user_type,
            target: data?.user_ids,
            id: data?.id,
            news_letter_schedule_id: data?.news_letter_schedule_id,
        }
        setInitialValues(iData);
        setIsEdit(true);
    }

    const clearEdit = () => {
        const iData = {
            estimated_time: "",
            estimated_date: '',
            applies_to: '',
            target: '',
        }
        setInitialValues(iData);
        setIsEdit(false);
    }

    const removeSchedule = async (data: any) => {
        toastId.current = toast("Delete news letter schedule...", {
            autoClose: false,
            isLoading: true,
        });
        const res = await newsLetterService.deleteNewsLetterSchedule(data?.id);
        if (res.status === "success") {
            getSchedule();
            toast.update(toastId.current, {
                type: toast.TYPE.SUCCESS,
                render: "News letter schedule delete successfully",
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });
        } else {
            toast.update(toastId.current, {
                type: toast.TYPE.ERROR,
                render: res?.message || 'Error',
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });
        }
    }

    useEffect(() => {
        if (!validation.isSubmitting) return;
        if (Object.keys(validation.errors).length > 0) {
            const element = getFirstErrorElement(validationSchema, validation);
            document
                .getElementById(element)
                ?.scrollIntoView({ behavior: "smooth", block: "center" });
            document.getElementById(element)?.focus({ preventScroll: true });
        }
    }, [validation.isSubmitting, validation.errors]);


    return (
        <React.Fragment>
            <Row>
                <Col xl={12} >
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                        autoComplete="off"
                    >
                        <Card>
                            <CardBody className='p-2'>
                                <CardTitle tag="h5">
                                    Set custom date and time
                                </CardTitle>
                            </CardBody>

                            <CardBody className='p-2'>
                                <div className='row'>
                                    <div className='col-6'>
                                        <div className="mb-3">
                                            <Label className="form-label">
                                                Published date{" "}
                                            </Label>
                                            <Flatpickr
                                                id="estimated_date"
                                                name="estimated_date"
                                                className="form-control d-block"
                                                type="text"
                                                value={initialValues.estimated_date}
                                                placeholder="DD/MM/YYYY"
                                                options={{
                                                    altInput: true,
                                                    altFormat: "d/m/Y",
                                                    dateFormat: "Y-m-d",
                                                    minDate: "today",
                                                    allowInput: true,
                                                    onChange: function (
                                                        selectedDates,
                                                        dateStr,
                                                        instance
                                                    ) {
                                                        handleChange("estimated_date", dateStr);
                                                    },
                                                    onClose: function (
                                                        selectedDates,
                                                        dateStr,
                                                        instance
                                                    ) {
                                                        handleChange("estimated_date", dateStr);
                                                    },
                                                    locale: { firstDayOfWeek: 1 },
                                                }}
                                            />
                                            {validation.errors.estimated_date ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.estimated_date}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className='col-6'>
                                        <div className="mb-3">
                                            <Label className="form-label">
                                                Published time{" "}
                                            </Label>
                                            <Flatpickr
                                                id="estimated_time"
                                                name="estimated_time"
                                                className="form-control"
                                                type="text"
                                                placeholder='HH:MM'
                                                value={initialValues.estimated_time || ""}
                                                options={{
                                                    enableTime: true,
                                                    noCalendar: true,
                                                    dateFormat: "h:i K",
                                                    time_24hr: false,
                                                    allowInput: true,
                                                    minTime: timeNow(),
                                                    onChange: function (selectedDates, dateStr, instance) {
                                                        handleChange("estimated_time", dateStr);
                                                    },
                                                    onClose: function (selectedDates, dateStr, instance) {
                                                        handleChange("estimated_time", dateStr);
                                                    },
                                                    locale: { firstDayOfWeek: 1 },
                                                }}
                                            />
                                            {validation.errors.estimated_time ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.estimated_time}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <Col xl={12} lg={12} md={12} sm={12}>
                                        <div className="mb-3">
                                            <Label className="form-label">
                                                Applies to
                                            </Label>
                                            <Select
                                                id="type"
                                                name="type"
                                                className="is-invalid"
                                                options={typeOptions3}
                                                value={
                                                    initialValues.applies_to
                                                        ? typeOptions3.find(
                                                            (option: any) => option.value == initialValues.applies_to
                                                        )
                                                        : ""
                                                }
                                                isSearchable={false}
                                                isDisabled={false}
                                                onChange={(e: any) => {
                                                    handleChange("applies_to", e.value);
                                                }}
                                            />
                                            {validation.errors.applies_to ? (
                                                <FormFeedback type="invalid">
                                                    {validation.errors.applies_to}
                                                </FormFeedback>
                                            ) : null}
                                        </div>
                                    </Col>
                                    {
                                        initialValues.applies_to === '8' &&
                                        <Col xl={12} lg={12} md={12} sm={12}>
                                            <div className="mb-3">
                                                <Label className="form-label">
                                                    To (Emails)
                                                </Label>
                                                <AsyncSelect
                                                    cacheOptions
                                                    loadOptions={loadSuggestedOptions}
                                                    defaultOptions
                                                    placeholder="To (Emails)"
                                                    isMulti
                                                    isClearable={false}
                                                    components={{
                                                        DropdownIndicator: () => null,
                                                        IndicatorSeparator: () => null,
                                                        Option: CustomeDropDownOption,
                                                    }}
                                                    onChange={(e: any) => handleChange('target', e)}
                                                    value={initialValues.target}
                                                    noOptionsMessage={() => t('User not found')}
                                                />
                                            </div>
                                        </Col>}
                                </div>


                            </CardBody>
                            <CardFooter>
                                {!isEdit ? <Button
                                    color="primary"
                                    size="sm"
                                    type="submit"
                                    className='float-end'
                                //onClick={() => createSchedule()}
                                >
                                    <i className="fa fa-plus" aria-hidden="true"></i>{" "}Add
                                </Button> : <>


                                    <Button
                                        color="primary"
                                        size="sm"
                                        className='float-end '
                                        onClick={() => updateSchedule()}
                                    >
                                        <i className="fa fa-floppy-o" aria-hidden="true"></i>{" "}Update
                                    </Button>
                                    <Button
                                        color="primary"
                                        size="sm"
                                        className='float-end me-2'
                                        onClick={() => clearEdit()}
                                    >
                                        <i className="fa fa-eraser" aria-hidden="true"></i>{" "}Clear
                                    </Button>

                                </>}
                            </CardFooter>
                        </Card>
                    </Form>
                    <div className="row p-2">
                        <div className="col-12 p-0"><h4 className="card-title mb-2">Schedule</h4></div>
                        <SimpleBar>
                            {scheduleHistory && map(scheduleHistory, (item, i) =>
                                <div className={`shadow-sm p-1 mb-2 ${item?.send_status === '1' && 'border-start border-success'}`} key={i}>
                                    <div className='row m-0 p-0 align-items-center'>
                                        <div className="col-9 p-0 m-0">
                                            <p className='d-flex m-1'>
                                                <span className="material-symbols-outlined fw-light" style={{ fontSize: '16px' }}>
                                                    calendar_month
                                                </span>
                                                <span className='ms-1'>{moment(item?.schedule_date).format('ll')}</span>
                                                <span className="material-symbols-outlined fw-light ms-2" style={{ fontSize: '16px' }}>
                                                    schedule
                                                </span><span className='ms-1'>{item?.time}</span>
                                            </p>
                                            <p style={{ fontSize: '12px' }} className="p-0 m-0 ms-2">Updated {moment(item?.updated_at).format('LLL')}</p>
                                        </div>
                                        <div className="col-3 p-0 m-0 d-flex">
                                            <p className='d-flex m-1 ' style={{ fontSize: '14px' }}>
                                                <span className={`material-symbols-outlined fw-light ${item?.send_status === 1 && 'text-success'}`} style={{ fontSize: '19px', paddingTop: '2px' }}>
                                                    {item?.send_status === 1 ? 'check_circle' : 'update'}
                                                </span>
                                            </p>
                                            {
                                                //(userProfile.CAN_EDIT && userProfile.USER_ID === parseInt(item.created_by) || userProfile.USER_FUNCTION===6) 
                                                userProfile.USER_FUNCTION === 6 ?
                                                    <a href='#' className='d-flex m-1' style={{ fontSize: '14px' }} onClick={() => editSchedule(item)}>
                                                        <span className="material-symbols-outlined fw-light text-primary" style={{ fontSize: '19px' }}>
                                                            edit_square
                                                        </span>
                                                    </a> : null}
                                            {
                                                //(userProfile.CAN_DELETE && userProfile.USER_ID === parseInt(item.created_by) || userProfile.CAN_DELETE_ALL) 
                                                userProfile.USER_FUNCTION === 6 ?
                                                    <a href='#' className='d-flex m-1' style={{ fontSize: '14px' }} onClick={() => removeSchedule(item)}>
                                                        <span className="material-symbols-outlined fw-light text-danger" style={{ fontSize: '19px', paddingTop: '2px' }}>
                                                            delete
                                                        </span>
                                                    </a>
                                                    : null}

                                        </div>
                                    </div>
                                </div>
                            )}
                        </SimpleBar>
                    </div>

                    <div className="row p-2">
                        <div className="col-12 p-0"><h4 className="card-title mb-2">History</h4></div>
                        <SimpleBar>
                            {preHistory && map(preHistory, (item, i) =>
                                <div className={`shadow-sm p-1 mb-2 ${item?.send_status === '1' && 'border-start border-success'}`} key={i}>
                                    <div className='row m-0 p-0 align-items-center'>
                                        <div className="col-9 p-0 m-0">
                                            <p className='d-flex m-1'>
                                                <span className="material-symbols-outlined fw-light" style={{ fontSize: '16px' }}>
                                                    calendar_month
                                                </span>
                                                <span className='ms-1'>{moment(item?.schedule_date).format('ll')}</span>
                                                <span className="material-symbols-outlined fw-light ms-2" style={{ fontSize: '16px' }}>
                                                    schedule
                                                </span><span className='ms-1'>{item?.time}</span>
                                            </p>
                                            <p style={{ fontSize: '12px' }} className="p-0 m-0 ms-2">Updated {moment(item?.updated_at).format('LLL')}</p>
                                        </div>
                                        <div className="col-3 p-0 m-0 d-flex">
                                            <p className='d-flex m-1 ' style={{ fontSize: '14px' }}>
                                                <span className={`material-symbols-outlined fw-light ${item?.send_status === 1 && 'text-success'}`} style={{ fontSize: '19px', paddingTop: '2px' }}>
                                                    {item?.send_status === 1 ? 'check_circle' : 'update'}
                                                </span>
                                            </p>
                                            {
                                                //(userProfile.CAN_DELETE && userProfile.USER_ID === parseInt(item.created_by) || userProfile.CAN_DELETE_ALL) 
                                                userProfile.USER_FUNCTION === 6 ?
                                                    <a href='#' className='d-flex m-1' style={{ fontSize: '14px' }} onClick={() => removeSchedule(item)}>
                                                        <span className="material-symbols-outlined fw-light text-danger" style={{ fontSize: '19px', paddingTop: '2px' }}>
                                                            delete
                                                        </span>
                                                    </a>
                                                    : null
                                            }

                                        </div>
                                    </div>
                                </div>
                            )}
                        </SimpleBar>
                    </div>
                </Col>
            </Row>
        </React.Fragment >

    )
}

export default Schedule;