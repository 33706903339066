export const SET_SIDEBAR_COLLAPSED = "SET_SIDEBAR_COLLAPSED"

export const GET_NOTIFICATION_COUNT = "GET_NOTIFICATION_COUNT"
export const GET_NOTIFICATION_COUNT_SUCCESS = "GET_NOTIFICATION_COUNT_SUCCESS"
export const GET_NOTIFICATION_COUNT_ERROR = "GET_NOTIFICATION_COUNT_ERROR"
export const SET_NOTIFICATION_FETCHED = "SET_NOTIFICATION_FETCHED"

export const GET_USER_OPTIONS_LIST = "GET_USER_OPTIONS_LIST"
export const GET_USER_OPTIONS_LIST_SUCCESS = "GET_USER_OPTIONS_LIST_SUCCESS"
export const GET_USER_OPTIONS_LIST_ERROR = "GET_USER_OPTIONS_LIST_ERROR"
export const GET_USER_OPTIONS_LIST_NEW = "GET_USER_OPTIONS_LIST_NEW"
export const GET_USER_OPTIONS_LIST_SUCCESS_NEW = "GET_USER_OPTIONS_LIST_SUCCESS_NEW"
export const GET_USER_OPTIONS_LIST_ERROR_NEW = "GET_USER_OPTIONS_LIST_ERROR_NEW"

export const GET_COMPANY_OPTIONS_LIST = "GET_COMPANY_OPTIONS_LIST"
export const GET_COMPANY_OPTIONS_LIST_SUCCESS = "GET_COMPANY_OPTIONS_LIST_SUCCESS"
export const GET_COMPANY_OPTIONS_LIST_ERROR = "GET_COMPANY_OPTIONS_LIST_ERROR"
export const GET_COMPANY_OPTIONS_LIST_NEW = "GET_COMPANY_OPTIONS_LIST_NEW"
export const GET_COMPANY_OPTIONS_LIST_SUCCESS_NEW = "GET_COMPANY_OPTIONS_LIST_SUCCESS_NEW"
export const GET_COMPANY_OPTIONS_LIST_ERROR_NEW = "GET_COMPANY_OPTIONS_LIST_ERROR_NEW"

export const GET_FEES_OPTIONS_LIST = "GET_FEES_OPTIONS_LIST"
export const GET_FEES_OPTIONS_LIST_SUCCESS = "GET_FEES_OPTIONS_LIST_SUCCESS"
export const GET_FEES_OPTIONS_LIST_ERROR = "GET_FEES_OPTIONS_LIST_ERROR"
export const GET_FEES_OPTIONS_LIST_NEW = "GET_FEES_OPTIONS_LIST_NEW"
export const GET_FEES_OPTIONS_LIST_SUCCESS_NEW = "GET_FEES_OPTIONS_LIST_SUCCESS_NEW"
export const GET_FEES_OPTIONS_LIST_ERROR_NEW = "GET_FEES_OPTIONS_LIST_ERROR_NEW"

export const CHANGE_TABLE_ORDER_ON_DRAG = "CHANGE_TABLE_ORDER_ON_DRAG"
export const CHANGE_TABLE_ORDER_ON_DRAG_SUCCESS = "CHANGE_TABLE_ORDER_ON_DRAG_SUCCESS"
export const CHANGE_TABLE_ORDER_ON_DRAG_ERROR = "CHANGE_TABLE_ORDER_ON_DRAG_ERROR"
