import React, { FC, useState, useEffect } from 'react';
import { Button, Col, Container, Label, OffcanvasBody, Row } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import moment from 'moment';
import { toast } from 'react-toastify';
import { invoiceStatuses } from '../../common/staticdata';
import { formatCurrency } from '../../utils/formats';
import invoicesService from '../../services/invoices.service';
import Input from '../../components/Form/Input';
import { useTranslation } from 'react-i18next';



type RefundFormProps = {
    values: any;
    onApply?: any;
    onClose: any;
    onChange?: any;
}

type RefundFormType = {
    invoice_id: number;
    refund_date: string | any;
    refund_type: 'full' | 'partial';
    amount: any;
    isSubmitting: boolean
}

const RefundForm: FC<RefundFormProps> = ({ values, onApply, onClose, onChange }) => {
    const { t } = useTranslation();

    const toastId = React.useRef(null) as any;
    const [formValues, setFormValues] = useState<RefundFormType>({
        invoice_id: values.id,
        refund_date: moment(new Date()).format('YYYY-MM-DD'),
        amount: values.paid_amount,
        refund_type: 'full',
        isSubmitting: false
    });

    const [errors, setErrors] = useState<any>({
        invoice_id: null,
        refund_date: null,
        amount: null,
        refund_type: null,
    });

    const handleSubmit = async (values: RefundFormType) => {
        toastId.current = toast(t("Submitting refund..."), { autoClose: false, isLoading: true });
        setFormValues((state: RefundFormType) => ({ ...state, isSubmitting: true }))
        const result = await invoicesService.refundInvoice(values);
        if (result.status === 'success') {
            onClose();
            toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: t('Refund submitted successfully'), autoClose: 5000, isLoading: false, delay: 500 });
        }
        setFormValues((state: RefundFormType) => ({ ...state, isSubmitting: false }))
    }

    useEffect(() => {
        if (values?.paid_amount < formValues?.amount) {
            setErrors((state: any) => ({ ...state, amount: t('Amount should be greater than equals to paid amount') }))
        } else {
            setErrors((state: any) => ({ ...state, amount: null }))
        }

    }, [formValues])

    return (
        <React.Fragment>
            <OffcanvasBody>
                <Row>
                    <Col>
                        <div className="mb-3">
                            <h5 className="invoice-number">
                                {t("Invoice Number")}: {values?.invoice_number}
                            </h5>

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="mb-3">
                            <Label className="form-label">
                                {t("Paid Amount")}:
                            </Label>

                        </div>
                    </Col>
                    <Col>
                        <div className="mb-3  rightAlign">
                            <Label className="form-label">
                                {formatCurrency(values?.paid_amount)}
                            </Label>

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="mb-3">
                            <Label className="form-label">
                                {t("Refund Date")}
                            </Label>
                            <Row>
                                <Col>
                                    <div className="input-group is-invalid">
                                        <Flatpickr
                                            id="refund_date"
                                            name="refund_date"
                                            className="form-control d-block"
                                            type="text"
                                            placeholder="DD/MM/YYYY"
                                            value={formValues?.refund_date}
                                            options={{
                                                altInput: true,
                                                altFormat: "d/m/Y",
                                                dateFormat: "Y-m-d",
                                                defaultDate: values?.refund_date,
                                                allowInput: true,
                                                onChange: function (
                                                    selectedDates,
                                                    dateStr,
                                                    instance
                                                ) {
                                                    setFormValues((state: RefundFormType) => ({ ...state, refund_date: dateStr }))
                                                },
                                                locale: { firstDayOfWeek: 1 }
                                            }}
                                        />
                                        <span className="input-group-text">
                                            <span className="align-middle material-symbols-outlined fw-light fs-4">calendar_today</span>
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="mb-2 d-sm-flex flex-wrap">
                            <div className="form-check">
                                <Label className="form-label">
                                    <Input
                                        type='radio'
                                        id={`refund_type`}
                                        name='refund_type'
                                        value={'full'}
                                        checked={formValues.refund_type === 'full'}
                                        onChange={(e) => {
                                            setFormValues((state: RefundFormType) => ({ ...state, refund_type: 'full' }));
                                            setFormValues((state: RefundFormType) => ({ ...state, amount: values.paid_amount }))
                                        }}
                                    />
                                    {t("Full Refund")}
                                </Label>
                            </div>
                            <div className="form-check mx-3">
                                <Label className="form-label">
                                    <Input
                                        type='radio'
                                        id={`refund_type`}
                                        name='refund_type'
                                        value={'partial'}
                                        checked={formValues.refund_type === 'partial'}
                                        onChange={(e) => {
                                            setFormValues((state: RefundFormType) => ({ ...state, refund_type: 'partial' }));
                                            setFormValues((state: RefundFormType) => ({ ...state, amount: 0 }));
                                        }}
                                    />
                                    {t("Partial Refund")}
                                </Label>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="mb-3">

                            <Input
                                id={`amount`}
                                label="Amount"
                                name='amount'
                                type='text'
                                placeholder={'100'}
                                className='rightAlign'
                                disabled={formValues.refund_type === 'full'}
                                value={formValues?.amount || ''}
                                error={errors?.amount}
                                onChange={(e) => {
                                    setFormValues((state: RefundFormType) => ({ ...state, amount: e.target.value }))
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            </OffcanvasBody>
            <Button
                color="primary"
                style={{ margin: "16px" }}
                onClick={() => handleSubmit(formValues)}
                disabled={formValues.isSubmitting || formValues.amount <= 0 || Object.values(errors).some((err: any) => err !== null)}
                loading={formValues.isSubmitting}
            >
                {t("Save")}
            </Button>
        </React.Fragment >
    )
}

export default RefundForm;