import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Button,
    Alert,
} from "reactstrap";

// Actions
import { FeesAction, CommonAction } from "../../../store/actions";

// Import Breadcrumb
import Breadcrumbs from "../../../components/Layout/Breadcrumb";

// Import DeleteModal
import VatForm from "./VatForm";

// Import DeleteModal
import DeleteModal from "../../../components/Common/DeleteModal";

// Import DataTable
import DataTable from "../../../components/Common/DataTable";

import { getDataTableQuery } from "../../../helpers/functions_helper";
import VatService from "../../../services/vat.service";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";



const VatMaster = (props: any) => {

    const { t } = useTranslation();

    document.title = t('VAT') + " | " + process.env.REACT_APP_NAME;

    const breadcrumbItems = [
        {
            url: "/dashboard",
            label: t("Dashboard"),
            active: false,
        },
        {
            url: "#",
            label: t("VAT"),
            active: true,
        },
    ];

    const [loader, setLoader] = useState(true);
    const dispatch = useDispatch();

    const [loadingListData, setLoadingListData] = useState(true);
    const [datatableOptions, setDatatableOptions] = useState({
        draw: 1,
        page: 1,
        recordsTotal: 0,
        recordsFiltered: 0,
        sizePerPage: 20,
        searchText: "",
        sortField: "id",
        sortOrder: "desc",
    });
    const [vatList, setVatList] = useState([]);

    const columns = useMemo(() => [
        {
            dataField: "DT_RowIndex",
            text: t("Sr. No."),
            sort: false,
            search: false,
        },
        // {
        //   dataField: "id",
        //   text: "ID",
        //   sort: true,
        //   search: true,
        // },
        {
            dataField: "country.nicename",
            text: t("Country"),
            sort: true,
            search: true,
        },
        {
            dataField: "vat",
            text: t('Standard rate') + " (%)",
            sort: true,
            search: true,
        },
        {
            dataField: "special_rate",
            text: t('Special rates') + " (%)",
            sort: true,
            search: true,
        },
        {
            dataField: "super_reduced_rate",
            text: t('Super-reduced rates') + " (%)",
            sort: true,
            search: true,
        },
        {
            dataField: "zero_rate",
            text: t('Zero rates') + " (%)",
            sort: true,
            search: true,
        },
        {
            dataField: "parking_rate",
            text: t('Parking rates') + " (%)",
            sort: true,
            search: true,
        },
        {
            dataField: "action",
            text: t("Action"),
            sort: false,
            search: false,
            formatter: (rowContent: any, row: any): any => {
                return (
                    <div className="d-flex gap-2 text-end">
                        <Link
                            className="btn btn-pill btn-outline-primary btn-sm"
                            to="#"
                            onClick={() => onClickEdit(row)}
                        >
                            <i className="fa-regular fa-pen-to-square"></i> {t("Edit")}
                        </Link>
                        {row.no_delete === 0 ? (
                            <Link
                                className="btn btn-pill btn-outline-danger btn-sm"
                                to="#"
                                onClick={() => onClickDelete(row)}
                            >
                                <i className="fa-regular fa-trash-can"></i> {t("Delete")}
                            </Link>
                        ) : null}
                    </div>
                );
            },
        },
    ], [t]);

    const getVatList = async () => {
        const res = await VatService.getAllVat(getDataTableQuery(datatableOptions, columns));
        if (res.data.status === 'success') {
            setVatList(res.data.data);
            const updatedDatatableOptions = {
                ...datatableOptions,
                recordsTotal: res.data.recordsTotal,
                recordsFiltered: res.data.recordsFiltered,
            };
            setDatatableOptions(updatedDatatableOptions);
            setLoadingListData(false);
            setLoader(false);
        }
    }

    useEffect(() => {
        if (loadingListData === true) {
            getVatList();
        }
    }, [loadingListData])

    useEffect(() => {
        setVatList([])
        setLoadingListData(true);
    }, [t])



    const onTableChange = (type: any, newState: any) => {
        let draw = datatableOptions.draw + 1;
        let page = 1;
        if (type == "search") {
            page = 1;
        } else {
            page = newState.page;
        }
        const updatedDatatableOptions = {
            ...datatableOptions,
            draw: draw,
            page: page,
            sizePerPage: newState.sizePerPage,
            searchText: newState.searchText,
            sortField: newState.sortField,
            sortOrder: newState.sortOrder,
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingListData(true);
    };
    // Datatable -------------------------------------------------

    // Delete Form --------------------------------------------------------
    const [deleteVat, setDeleteVat] = useState({
        id: "",
        vat_master_id: ""
    });
    const [recordName, setRecordName] = useState("");
    const [deleteModal, setDeleteModal] = useState(false);
    const [vatData, setVatData] = useState({});

    const [masterFormModal, setMasterFormModal] = useState(false);
    const [masterFormModalTitle, setMasterFormModalTitle] = useState("Create VAT");

    const onClickDelete = (row: any) => {
        const deleteData = {
            ...deleteVat,
            id: row.id,
            fee_id: row.fee_id,
        };
        setDeleteVat(deleteData);
        setRecordName(row.country.name);
        setDeleteModal(true);
    };

    const handleDelete = async () => {
        const res = await VatService.deleteVat(deleteVat.id);
        if (res.status === 'success') {
            toast(t(res.message), { type: toast.TYPE.SUCCESS })
            setLoadingListData(true);
        }
        setDeleteModal(false)
    };
    // Delete Form --------------------------------------------------------

    // Master Form -------------------------------------------------------- 


    const onClickModal = () => {
        setVatData('');
        setMasterFormModalTitle(t("Create VAT"));
        setMasterFormModal(true);
    };

    const onClickEdit = (row: any) => {
        setVatData(row);
        setMasterFormModalTitle(t("Update VAT"));
        setMasterFormModal(true);
    }

    const handleSubmit = async (values: any, actions: any) => {
        actions.setSubmitting(true);
        try {
            if (values?.id && values?.vat_master_id) {
                const res = await VatService.updateVat(values);
                if (res.status === 'success') {
                    setMasterFormModal(false)
                    toast(t(res.message), { type: toast.TYPE.SUCCESS, onClose: () => setMasterFormModal(false) })
                    setLoadingListData(true);
                } else {
                    actions.setErrors(res.errors)
                }
            } else {
                const res = await VatService.createVat(values);
                if (res.status === 'success') {
                    setMasterFormModal(false)
                    toast(t(res.message), { type: toast.TYPE.SUCCESS, onClose: () => setMasterFormModal(false) })
                    setLoadingListData(true);
                } else {
                    actions.setErrors(res.errors)
                }
            }

        } catch (err: any) {
            toast(t('Something went wrong'), { type: toast.TYPE.ERROR })
        }
        actions.setSubmitting(false);
    };

    const clearFilter = () => {
        const updatedDatatableOptions = {
            ...datatableOptions,
            draw: 1,
            page: 1,
            sizePerPage: 20,
            searchText: "",
            sortField: "id",
            sortOrder: "desc",
        };
        setDatatableOptions(updatedDatatableOptions);

        setLoadingListData(true);
    };

    return (
        <React.Fragment>

            {deleteVat && (
                <DeleteModal
                    recordName={recordName}
                    modalTitle={t("VAT")}
                    show={deleteModal}
                    onDeleteClick={handleDelete}
                    onCloseClick={() => setDeleteModal(false)}
                    loading={loader}
                />
            )}

            {masterFormModal && (
                <VatForm
                    modalTitle={masterFormModalTitle}
                    show={masterFormModal}
                    loading={loader}
                    vatData={vatData}
                    onSubmitClick={handleSubmit}
                    onCloseClick={() => setMasterFormModal(false)}
                    errorMsg={''}
                    errorData={''}
                />
            )}

            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        breadcrumbTitle={t("VAT")}
                        breadcrumbItems={breadcrumbItems}
                        endAdornment={
                            <Link
                                to="#"
                                className="btn btn-primary btn-sm waves-effect waves-light btn-label"
                                onClick={() => onClickModal()}
                            >
                                <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">add</span> {t("Create VAT")}
                            </Link>
                        }
                    />

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <DataTable
                                        columns={columns}
                                        products={vatList}
                                        datatableOptions={datatableOptions}
                                        filters={[]}
                                        onTableChange={onTableChange}
                                        loadingListData={loadingListData}
                                        clearFilter={clearFilter}
                                        slug="masters"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default VatMaster;
