import i18next from "i18next";

export const UPLOAD_MAX_FILESIZE = process.env.REACT_APP_UPLOAD_MAX_FILESIZE;

export const honorificOptions = [
  { value: "Madam", label: "Madam", gender: 2, language: 1 },
  { value: "Madame", label: "Madame", gender: 2, language: 2 },
  { value: "Mevrouw", label: "Mevrouw", gender: 2, language: 3 },
  { value: "Meneer", label: "Meneer", gender: 1, language: 3 },
  { value: "Miss", label: "Miss", gender: 2, language: 1 },
  { value: "Mister", label: "Mister", gender: 1, language: 1 },
  { value: "Monsieur", label: "Monsieur", gender: 1, language: 2 },
  { value: "Mr", label: "Mr", gender: 1, language: 1 },
  { value: "Mrs", label: "Mrs", gender: 2, language: 1 },
  { value: "Ms", label: "Ms", gender: 2, language: 1 },
];

export const genderOptions = [
  { value: "1", label: "Male" },
  { value: "2", label: "Female" },
  { value: "3", label: "Other" },
];

export const languageOptions = [
  { value: "1", label: "English" },
  { value: "2", label: "French" },
  { value: "3", label: "Dutch" },
];

export const civil_status_master = [
  { label: 'Single', value: 1 },
  { label: 'Married', value: 2 },
  { label: 'Living together', value: 3 },
  { label: 'Legally cohabiting', value: 4 },
  { label: 'Divorced', value: 5 },
  { label: 'Living Separately', value: 6 },
  { label: 'Widow or Widower', value: 7 },
  { label: "-- Remove --", value: "" }
];

export const userTypeOptions = [
  { value: "1", label: "Individual" },
  { value: "2", label: "Company" },
];

export const typeOptions = [
  { value: "1", label: "Internal user" },
  { value: "2", label: "External user" },
  { value: "3", label: "Website user" },
];
export const typeOptionsTrans = (t: any) => [
  { value: "1", label: t("Internal user") },
  { value: "2", label: t("External user") },
  { value: "3", label: t("Website user") },
];

export const typeOptions2 = [
  { value: "1", label: "Internal user" },
  { value: "2", label: "External user" },
  { value: "3", label: "Website user" },
  { value: "4", label: "All user" },
];


export const typeOptions3 = [
  { value: "1", label: "Internal user" },
  { value: "2", label: "External user" },
  { value: "3", label: "Website user" },
  { value: "4", label: "All user" },
  { value: "5", label: "Contact person (All)" },
  { value: "6", label: "Contact person (Invoice)" },
  { value: "7", label: "Contact person (HR)" },
  { value: "8", label: "Selected user" },
];

export const targetOptions = [
  { value: "1", label: "First Name", 'fr': 'Prénom', 'nl': 'Voornaam' },
  { value: "2", label: "Last Name", 'fr': 'Nom de famille', 'nl': 'Achternaam' },
  { value: "3", label: "First Name + Last Name", 'fr': 'Prénom + Nom de famille', 'nl': 'Voornaam + Achternaam' },
  { value: "4", label: "Sir / Madam", 'fr': 'Sir / Madam', 'nl': 'Meneer / Mevrouw' },
];

export const emailTypeOption = [
  { value: "1", label: "Automated" },
  { value: "2", label: "Manual" },
];

export const statusOptions = [
  { value: "1", label: "Resident" },
  { value: "2", label: "Non-Resident" },
];

export const AttributOptions = [
  { value: "passport", label: "Passport" },
  { value: "residence_card", label: "Residence card" },
  { value: "single_permit", label: "Work permit/Single permit" },
  { value: "mutuality", label: "Mutality" },
  //{ value: "questionnaire", label: "Questionnaire" },
  // { value: "tax_on_web", label: "Tax Claim" },
  { value: "tax_return", label: "Tax Return" },
];

export const taskStatusOptions = [
  { value: "1", label: "TO DO" },
  { value: "2", label: "IN PREPARATION" },
  { value: "3", label: "IN REVIEW" },
  { value: "4", label: "RESPONSIBLE TO SIGN OFF" },
  { value: "5", label: "TO BE SENT" },
  { value: "6", label: "SENT" },
  { value: "7", label: "CANCELLED" },
  { value: "8", label: "INVOICED" },
  { value: "9", label: "DONE" },
];

export const taskStatusOptionsTrans = (t: any) => {
  return [
    { value: "1", label: t("TO DO") },
    { value: "2", label: t("IN PREPARATION") },
    { value: "3", label: t("IN REVIEW") },
    { value: "4", label: t("RESPONSIBLE TO SIGN OFF") },
    { value: "5", label: t("TO BE SENT") },
    { value: "6", label: t("SENT") },
    { value: "7", label: t("CANCELLED") },
    { value: "8", label: t("INVOICED") },
    { value: "9", label: t("DONE") },
  ]
};

export const invoicePaymentStatuses = [
  { value: "Not Paid", label: "Not Paid" },
  { value: "Partial Paid", label: "Partial Paid" },
  { value: "Paid", label: "Paid" },
  { value: "Over Paid", label: "Over Paid" },
];

export const invoiceStatuses = [
  { value: "Draft", label: "Draft" },
  { value: "Sent", label: "Sent" },
  { value: "Cancelled", label: "Cancelled" },
];

export const clientstatusOptions = [
  { value: "1", label: "LEAD" },
  { value: "2", label: "PROSPECT" },
  { value: "3", label: "CUSTOMER" },
  {
    label: "-- Remove --",
    value: ""
  }
];

export const DocumentStatusMaster = [
  {
    label: 'Signed',
    value: "1",
  },
  {
    label: 'Not signed',
    value: "2",
  },
  {
    label: 'Refused',
    value: "3",
  },
  {
    label: 'Cancelled',
    value: "4",
  },
  {
    label: 'Expired',
    value: "5",
  },
  {
    label: "-- Remove --",
    value: ""
  }
];

export const StatusMaster = [
  {
    label: 'SENT',
    value: "3",
  },
  {
    label: 'SUBMITTED',
    value: "4",
  },
  {
    label: 'FINALISED',
    value: "5",
  },
  {
    label: "-- Remove --",
    value: ""
  }
];

export const EvaluationMaster = [
  {
    label: 'SENT',
    value: "1",
  },
  {
    label: 'ACCEPTED',
    value: "2",
  },
  {
    label: 'DECLINED',
    value: "3",
  },
  {
    label: "-- Remove --",
    value: ""
  }
];

export const taxRegimeMaster = [
  {
    label: 'No',
    value: "1",
  },
  {
    label: 'Yes',
    value: "2",
  },
  {
    label: 'Yes(R&D)',
    value: "3",
  },
  {
    label: "-- Remove --",
    value: ""
  }
];

export const YesNoMaster = [
  {
    label: 'Yes',
    value: "1",
  },
  {
    label: 'No',
    value: "2",
  },
  {
    label: "-- Remove --",
    value: ""
  }
];

export const YesNoMasters = [
  {
    label: "Yes",
    value: "1",
  },
  {
    label: "No",
    value: "0",
  },
  {
    label: "-- Remove --",
    value: ""
  }
];

export const taxClaimStatusMaster = [
  { value: "Filed", label: "Filed" },
  { value: "Closed", label: "Closed" },
  { value: "Follow up", label: "Follow up" },
  { value: "In progress", label: "In progress" },
  { value: "In draft", label: "In draft" },
];

export const fileTypesOptions = [
  { value: "application/*", label: "All files" },
  { value: "image/*", label: "Image files" },
  { value: ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document", label: "Word files" },
  { value: "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", label: "Excel files" },
  { value: "application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,.ppt,.pptx", label: "Powerpoint files" },
  { value: ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values", label: "CSV files" },
  { value: "application/pdf,application/PDF", label: "PDF files" },
  { value: "text/*", label: "Text files" },
  { value: "zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed", label: "ZIP files" },
  { value: "sql", label: "DB files" },
  { value: "other", label: "Other files" },
];

export const fileTypesSearchOptions = [
  { value: "all", label: "All files" },
  { value: "jpg,jpeg,png,svg,gif,bmp", label: "Image files" },
  { value: "doc,docx", label: "Word files" },
  { value: "xls,xlsx", label: "Excel files" },
  { value: "ppt,pptx", label: "Powerpoint files" },
  { value: "csv", label: "CSV files" },
  { value: "pdf", label: "PDF files" },
  { value: "txt", label: "Text files" },
  { value: "zip", label: "ZIP files" },
  { value: "sql", label: "DB files" },
  { value: "other", label: "Other files" },
];
export const fileTypesSearchOptionsTrans = (t: any) => {
  return [
    { value: "all", label: t("All files") },
    { value: "jpg,jpeg,png,svg,gif,bmp", label: t("Image files") },
    { value: "doc,docx", label: t("Word files") },
    { value: "xls,xlsx", label: t("Excel files") },
    { value: "ppt,pptx", label: t("Powerpoint files") },
    { value: "csv", label: t("CSV files") },
    { value: "pdf", label: t("PDF files") },
    { value: "txt", label: t("Text files") },
    { value: "zip", label: t("ZIP files") },
    { value: "sql", label: t("DB files") },
    { value: "other", label: t("Other files") },]
};

export const generalStatusOptions = [
  {
    value: "1",
    label: "Pending"
  },
  {
    value: "2",
    label: "In-progress"
  },
  {
    value: "3",
    label: "Received"
  },
  {
    value: "4",
    label: "Canceled"
  },
  {
    value: "5",
    label: "Rejected"
  },
  {
    value: "6",
    label: "Approved"
  },
  {
    value: "7",
    label: "Expired"
  },

  {
    label: "-- Remove --",
    value: ""
  }
];

export const options = [
  { value: 'Q1-2023', label: 'Q1-2023' },
  { value: 'Q2-2023', label: 'Q2-2023' },
  { value: 'Q3-2023', label: 'Q3-2023' },
  { value: 'Q4-2023', label: 'Q4-2023' },
];

export const languageCodes = (lang: any) => {
  switch (lang) {
    case 'English':
      return 'en';
      break;
    case 'French':
      return 'fr';
      break;
    case 'Dutch':
      return 'nl';
      break;
    default:
      return 'en';
      break;
  }
}


export const yearData = [
  {
    'label': ((new Date()).getFullYear() - 1),
    'value': ((new Date()).getFullYear() - 1)
  },
  {
    'label': ((new Date()).getFullYear()),
    'value': ((new Date()).getFullYear())
  },
  {
    'label': ((new Date()).getFullYear() + 1),
    'value': ((new Date()).getFullYear() + 1)
  },
  {
    'label': ((new Date()).getFullYear() + 2),
    'value': ((new Date()).getFullYear() + 2)
  },
  {
    'label': ((new Date()).getFullYear() + 3),
    'value': ((new Date()).getFullYear() + 3)
  },
  {
    'label': ((new Date()).getFullYear() + 4),
    'value': ((new Date()).getFullYear() + 4)
  },
  {
    'label': ((new Date()).getFullYear() + 5),
    'value': ((new Date()).getFullYear() + 5)
  },
]
export const currentYear = new Date().getFullYear();

export const yearOptions = [
  {
    'label': currentYear,
    'value': currentYear
  },
  {
    'label': currentYear - 1,
    'value': currentYear - 1
  },
  {
    'label': currentYear - 2,
    'value': currentYear - 2
  },
  {
    'label': currentYear - 3,
    'value': currentYear - 3
  },
  {
    'label': currentYear - 4,
    'value': currentYear - 4
  },
  {
    'label': currentYear - 5,
    'value': currentYear - 5
  },
]

export const durationOptions: any = [
  { "label": "One Month", "value": 1 },
  { "label": "Two Months", "value": 2 },
  { "label": "Three Months", "value": 3 },
  { "label": "Four Months", "value": 4 },
  { "label": "Five Months", "value": 5 },
  { "label": "Six Months", "value": 6 },
  { "label": "Seven Months", "value": 7 },
  { "label": "Eight Months", "value": 8 },
  { "label": "Nine Months", "value": 9 },
  { "label": "Ten Months", "value": 10 },
  { "label": "Eleven Months", "value": 11 },
  { "label": "One Year", "value": 12 },
]

export const graphviewOptions: any = [
  {
    "label": "Year View",
    "value": "year"
  },
  {
    "label": "Month View",
    "value": "month"
  },
  {
    "label": "Quarter View",
    "value": "quarter"
  },
]

export const vatReasonOptions: any = [
  {
    "label": "21% to be applied",
    "value": 1
  },
  {
    "label": "Reverse VAT rule",
    "value": 2
  },
  {
    "label": "Services outside Europ Union (0)",
    "value": 3
  },
];

export const statusBadge = [
  { status: "Paid", color: "success" },
  { status: "Not Paid", color: "danger" },
  { status: "Partial Paid", color: "warning" },
  { status: "Draft", color: "info" },
  { status: "Cancelled", color: "secondary" },
  { status: "Sent", color: "success" },
  { status: "Refunded", color: "secondary" },
];

export const langCodes = [
  {
    lang: "English",
    code: "en-US",
  },
  {
    lang: "France",
    code: "fr-BE",
  },
  {
    lang: "Dutch",
    code: "nl-BE",
  }
];

export const getLangCode = (lang: any) => langCodes.find((l: any) => l.lang == lang)?.code;

export const workCalender = [
  {
    "label": "Work day",
    "value": "W",
    "color": "rgb(255 189 187)"
  },
  {
    "label": "Sickness",
    "value": "S",
    "color": "#ffff66"
  },
  {
    "label": "Holidays",
    "value": "H",
    "color": "#ffd48a"
  },
  {
    "label": "Compensation",
    "value": "C",
    "color": "rgb(161 255 161)"
  },
  {
    "label": "Bank Holiday",
    "value": "BH",
    "color": "#b4bac0"
  },
];

export const taskPriority = [
  { value: "1", label: "<span class='align-middle material-symbols-outlined fw-light fs-5 text-white rounded me-1' style='background:#34c38f;'>keyboard_arrow_down</span>LOW" },
  { value: "2", label: "<span class='align-middle material-symbols-outlined fw-light fs-5 text-white rounded me-1' style='background:#50a5f1;'>equal</span>MEDIUM" },
  { value: "3", label: "<span class='align-middle material-symbols-outlined fw-light fs-5 text-white rounded me-1' style='background:#f1b44c;'>keyboard_arrow_up</span>HIGH" },
  { value: "4", label: "<span class='align-middle material-symbols-outlined fw-light fs-5 text-white rounded me-1' style='background:#f46a6a;'>keyboard_double_arrow_up</span>HIGHEST" }
];

export const taskPriorityTrans = (t: any) => {
  return [
    { value: "1", label: `<span class='align-middle material-symbols-outlined fw-light fs-5 text-white rounded me-1' style='background:#34c38f;'>keyboard_arrow_down</span>${t("LOW")}` },
    { value: "2", label: `<span class='align-middle material-symbols-outlined fw-light fs-5 text-white rounded me-1' style='background:#50a5f1;'>equal</span>${t("MEDIUM")}` },
    { value: "3", label: `<span class='align-middle material-symbols-outlined fw-light fs-5 text-white rounded me-1' style='background:#f1b44c;'>keyboard_arrow_up</span>${t("HIGH")}` },
    { value: "4", label: `<span class='align-middle material-symbols-outlined fw-light fs-5 text-white rounded me-1' style='background:#f46a6a;'>keyboard_double_arrow_up</span>${t("HIGHEST")}` }
  ]
};

export const questionnaireTypes = [
  {
    value: 'tax_return_IPP',
    label: "Tax return IPP"
  },
  {
    value: 'tax_return_INR',
    label: "Tax return INR"
  },
  {
    value: 'work_permit_within_EEA',
    label: "Work permit within EEA"
  },
  {
    value: 'single_permit',
    label: "Single permit"
  },
  {
    value: 'single_permit_outside',
    label: "Single permit outside"
  },
  {
    value: 'ubo_aml',
    label: "AML"
  },
  {
    value: 'ubo',
    label: "UBO"
  },
  {
    value: 'risque',
    label: "Risque/CAP"
  },
  {
    value: 'attestation_RSII',
    label: "Attestation RSII"
  },
  {
    value: 'attestation_RSICI',
    label: "Attestation RSICI"
  },
  {
    value: 'Formularie_RSII',
    label: "Formularie de demande RSII"
  },
  {
    value: 'Formularie_RSCII',
    label: "Formularie de demande RSCII"
  },
  {
    value: 'gdpr',
    label: "GDPR"
  },
  {
    value: 'other',
    label: "Other"
  },
]


export const FontsList = [
  {
    value: 'Times-Roman',
    label: "Times New Roman"
  },
  {
    value: 'Arial',
    label: "Arial"
  },
  {
    value: 'Helvetica',
    label: "Helvetica"
  },
  {
    value: 'Courier',
    label: "Courier New"
  },
  {
    value: 'Verdana',
    label: "Verdana"
  },
  {
    value: 'Georgia',
    label: "Georgia"
  },
  {
    value: 'TrebuchetMS',
    label: "Trebuchet MS"
  },
  {
    value: 'Palatino-Roman',
    label: "Palatino Linotype"
  },
  {
    value: 'BookAntiqua',
    label: "Book Antiqua"
  },
]

const date = new Date();
export const currentMonth = `${date.getMonth() + 1}`;

export const IssueCategories = [
  { label: 'Report a bug', value: 'Incident' },
  { label: 'Suggest an improvement - Change request', value: 'CR-CR' },
];

export const incident_status = [
  { label: 'New', value: '1' },
  { label: 'In-progress', value: '2' },
  { label: 'Fixed', value: '3' },
  { label: 'Hold', value: '4' },
  { label: 'For Deployment', value: '5' },
  { label: 'Complete', value: '6' },
  { label: 'Cancelled', value: '7' },
  { label: 'Rejected', value: '8' },
];

export const Priority = [
  { label: 'Highest', value: 'Highest' },
  { label: 'High', value: 'High' },
  { label: 'Medium', value: 'Medium' },
  { label: 'Low', value: 'Low' },
  { label: 'Lowest', value: 'Lowest' },
];