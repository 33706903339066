import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { noData } from '../../../utils/data';
import timeSheetsService from '../../../services/time.sheets.service';
import LoadingSpinner from '../../../components/Common/Loader';
import { secondsToTime, serialize } from '../../../utils/utils';
import { formatCurrency } from '../../../utils/formats';
import GraphFilters from '../../../components/Chart/Filters';
import { getUserProfile, graph_i18 } from '../../../helpers/functions_helper';
import { useNavigate } from 'react-router-dom';
import Chart from '../../../components/Chart';
import { currentMonth, currentYear } from '../../../common/staticdata';

interface TimePerTaskProps extends CartProps {
    filters?: any;
}

const TimePerTask: FC<TimePerTaskProps> = ({ hideHeading = false, filters = null }) => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const userObj = getUserProfile('all-time-sheets');

    const inititalFilter = {
        week: null,
        quarter: null,
        month: currentMonth,
        year: currentYear,
        user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_ID : null
    };

    const [loading, setLoading] = useState<boolean>(true);
    const [query, setQuery] = useState<string | null>(serialize(inititalFilter));
    const [filter, setFilter] = useState<any>(inititalFilter);

    const [data, setData] = useState<any>({
        series: [],
        options: {
            chart: {
                width: 380,
                type: 'pie',
            },
            // colors: [],
            labels: [],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        show: true,
                        position: 'left'
                    }
                }
            }],
            // stroke: {
            //     colors: ['#333'],
            //     width: 1
            // },
            yaxis: {
                labels: {
                    formatter: function (value: any) {
                        return secondsToTime(value);
                    }
                },
            },
            noData: noData,
        },
    })

    const handleClick = (fee_code: string) => {
        navigate(`/time-sheets/all-time-sheets/${fee_code}`)
    }

    const getChartData = async () => {
        const result = await timeSheetsService.timePerTask(query);
        const local_i18n = graph_i18();
        if (result.data.status === 'success') {

            setData((data: any) => ({
                ...data,
                series: result?.data?.data?.map((i: any) => i.time_spent ? parseInt(i.time_spent) : 0),
                options: {
                    ...data.options,
                    // colors: result?.data?.data?.map((i: any) => i.color),
                    labels: result?.data?.data?.map((i: any) => i.fees_code),
                    chart: {
                        ...data.options.chart,
                        events: {
                            dataPointSelection: (event: any, chartContext: any, config: any) => {
                                const temp = result?.data?.data?.map((i: any) => i.fee_id);
                                handleClick(temp[config.dataPointIndex])
                            }
                        },
                        locales: [local_i18n],
                        defaultLocale: local_i18n?.name,
                    }
                }

            }))
        }
        setLoading(false);
    }

    const clearWorkAllocationFilter = () => {
        setFilter(inititalFilter);
    }

    useEffect(() => {
        loading && getChartData();
    }, [loading])

    useEffect(() => {
        setQuery(serialize(filter));
        setLoading(true);
    }, [filter])

    useEffect(() => {
        filters && setFilter(filters)
    }, [filters])

    return (
        <>
            <div className="d-sm-flex flex-wrap">
                <h4 className="card-title mb-4">{!hideHeading && t("Percentage of time spent per task")}</h4>
                <div className="ms-auto">
                    <GraphFilters
                        filter={filter}
                        setFilter={setFilter}
                        clearFilter={clearWorkAllocationFilter}
                        slug='all-time-sheets'
                    />
                </div>
            </div>
            <div className="clearfix"></div>
            <Chart loading={loading} options={data.options} series={data.series} type="pie" height={380} />
        </>
    )
}

export default TimePerTask;