import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FormFeedback, Input as BTInput, InputGroup, InputGroupText, InputProps, Label } from 'reactstrap';
import Tooltip from '../../Tooltip/Tooltip';

interface InputType extends InputProps {
    label?: string;
    required?: boolean;
    loading?: boolean;
    touched?: any;
    error?: any;
    btn?: boolean;
    tooltip?: string;
    endAdornment?: ReactElement | string | undefined;
    startAdornment?: ReactElement | string | undefined;
}

const Input: FC<InputType> = ({ label, required, loading, touched = false, error, btn = false, tooltip, size, endAdornment = null, startAdornment = null, ...props }) => {
    const { t } = useTranslation();
    return (
        <div className={btn ? 'btn' : ''}>
            {(props?.type !== 'radio' && props?.type !== 'checkbox') ? label ?
                <Label for={props.id}>
                    {t(label)}
                    {required ? <span className="text-warning is_required">&nbsp; *</span> : null}
                    {
                        !!tooltip && <>
                            &nbsp;
                            <Tooltip content={t(tooltip)} direction="top">
                                < i className="fa-regular fas fa-info-circle"></i>
                            </Tooltip>
                        </>
                    }
                </Label>
                : null
                : null}
            {(endAdornment || startAdornment) ?
                <InputGroup className='d-flex'>
                    {startAdornment ? <InputGroupText>{startAdornment}</InputGroupText> : null}
                    <BTInput
                        {...props}
                        invalid={touched && error}
                    />
                    {endAdornment ? <InputGroupText>{endAdornment}</InputGroupText> : null}
                </InputGroup> : <BTInput
                    {...props}
                    invalid={touched && error}
                />
            }
            {(props?.type === 'radio' || props?.type === 'checkbox') ? label ?
                <Label for={props.id}>
                    &nbsp;{t(label)}
                    {required ? <span className="text-warning is_required">&nbsp; *</span> : null}
                </Label>
                : null
                : null}
            {error && touched ? (
                <FormFeedback type="invalid">
                    {label && error?.indexOf(label) > -1 ? t(label) + ' ' + t(error.replace(label + ' ', '')) : t(error)}
                </FormFeedback>
            ) : null}
        </div>
    )
}

export default Input;