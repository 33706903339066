import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../../components/Layout/Breadcrumb";
import DataTable from "../../components/Common/DataTable";
import DeleteModal from "../../components/Common/DeleteModal";

import Filters from "./Filters";
import Drawer from "../../components/Drawer";
import EditCompany from "../Masters/Company/EditCompany";
import EditUser from "../Users/EditUser";

import { getDataTableQuery, getUserProfile, handleFilters } from "../../helpers/functions_helper";
import { familyStatusesList } from "./helper";
import cpoService from "../../services/cpo.service";
import { yesNoTag } from "../../utils/utils";

const CPOList = (props: any) => {
  const { t } = useTranslation();
  document.title = t("CPO") + " | " + process.env.REACT_APP_NAME;
  const userProfile = getUserProfile("all-cpo");

  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
      hidden: userProfile.USER_TYPE === 3 ? true : false,
    },
    {
      url: "#",
      label: t("CPO"),
      active: true,
    },
  ];
  const savedfilters = handleFilters("GET");
  const toastId = React.useRef(null) as any;
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingListData, setLoadingListData] = useState(true);
  const [datatableListData, setDatatableListData] = useState([]);
  const [deleteWhere, setDeleteWhere] = useState({ id: "", cpo_id: "" });
  const [recordName, setRecordName] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedData, setSelectedData] = useState<any>();
  const [openCompany, setOpenCompany] = useState<boolean>(false);
  const [openUser, setOpenUser] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const filtersData: any = JSON.parse(searchParams.get("filters") as any);

  const onClickDelete = (row: any) => {
    const data = {
      ...deleteWhere,
      id: row.id,
      cpo_id: row.cpo_id,
    };
    setDeleteWhere(data);
    setRecordName(row.custome_code);
    setDeleteModal(true);
  };

  const handleDelete = () => {
    toastId.current = toast(`${t("Deleting")} ${t("cpo")}...`, {
      autoClose: false,
      isLoading: true,
    });
    deleteRecord(deleteWhere);
    setDeleteModal(false);
  };

  const deleteRecord = async (data: any) => {
    try {
      const res = (await cpoService.delete(data)) as any;
      if (res.data.status === "success") {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoadingListData(true);
        setLoading(false);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoading(false);
      }
    } catch (err: any) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }
  };

  const handleCompanyClick = (data: any) => {
    setSelectedData(data);
    setOpenCompany(true);
  };

  const handleUserClick = (data: any) => {
    setSelectedData(data);
    setOpenUser(true);
  };

  function renderRowHTML(rowContent: any, row: any) {
    return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
  }
  const columns = [
    {
      dataField: "DT_RowIndex",
      text: t("Sr. No."),
      sort: false,
      search: false,
      classes: "fix-col-left",
      headerClasses: "fix-col-left",
      headerStyle: { width: "65px" },
      style: { width: "65px" },
    },
    {
      dataField: "custome_code",
      text: t("CPO ID"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return (
          <>
            {userProfile.USER_COMPANY === 1 &&
            (userProfile.CAN_EDIT || userProfile.USER_ID === row.created_by) ? (
              <Link
                className="text-decoration-underline"
                to={`/cpo/edit-cpo/${row.id}/${row.cpo_id}`}
              >
                {row.custome_code}
              </Link>
            ) : userProfile.USER_ID === row.created_by ? (
              <Link
                className="text-decoration-underline"
                to={`/cpo/view-cpo/${row.id}/${row.cpo_id}`}
              >
                {row.custome_code}
              </Link>
            ) : (
              row.custome_code
            )}
          </>
        );
      },
    },
    {
      dataField: "company_name",
      text: t("Client"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return (
          <>
            {row.company_id === 0 ? (
              row.company_name
            ) : (
              <div className="link-primary" onClick={() => handleCompanyClick(row)}>
                {row.company_name}
              </div>
            )}
          </>
        );
      },
    },
    {
      dataField: "employee_name",
      text: t("Name of the employee"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return (
          <>
            {userProfile.USER_TYPE === 3 ? (
              row.employee_name
            ) : (
              <div className="link-primary" onClick={() => handleUserClick(row)}>
                {row.employee_name}
              </div>
            )}
          </>
        );
      },
    },
    {
      dataField: "family_status",
      text: t("Family status"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        const family_status =
          familyStatusesList.find((i: any) => i.value === row.family_status)?.label || "";
        return <>{t(family_status)}</>;
      },
    },
    {
      dataField: "spouse_earns",
      text: t("Spouse earns professional income"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return renderRowHTML(
          yesNoTag(row?.spouse_earns, row?.spouse_earns === 1 ? t("Yes") : t("No")),
          null
        );
      },
    },
    {
      dataField: "children_under_3",
      text: t("Children (under 3)"),
      sort: true,
      search: true,
    },
    {
      dataField: "children_total",
      text: t("Children (total)"),
      sort: true,
      search: true,
    },
    {
      dataField: "municipal_name",
      text: t("Municipal name"),
      sort: true,
      search: true,
    },
    {
      dataField: "municipal_tax",
      text: t("Municipal tax"),
      sort: true,
      search: true,
    },
    {
      dataField: "action",
      text: t("Action"),
      sort: false,
      search: false,
      formatter: (rowContent: any, row: any): any => {
        return (
          <div className="d-flex gap-2 text-end">
            {userProfile.USER_COMPANY === 1 &&
            (userProfile.CAN_EDIT_ALL || userProfile.USER_ID === row.created_by) ? (
              <Link
                className="btn btn-pill btn-outline-primary btn-sm"
                to={`/cpo/edit-cpo/${row.id}/${row.cpo_id}`}
              >
                <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                  edit
                </span>
                {t("Edit")}
              </Link>
            ) : (
              <Link
                className="btn btn-pill btn-outline-primary btn-sm"
                to={`/cpo/view-cpo/${row.id}/${row.cpo_id}`}
              >
                <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                  visibility
                </span>
                {t("View")}
              </Link>
            )}

            {userProfile.USER_COMPANY === 1 &&
            (userProfile.CAN_DELETE_ALL || userProfile.USER_ID === row.created_by) ? (
              <Link
                className="btn btn-pill btn-outline-danger btn-sm"
                to="#"
                onClick={() => onClickDelete(row)}
              >
                <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                  delete
                </span>
                {t("Delete")}
              </Link>
            ) : null}
          </div>
        );
      },
    },
  ];

  const [datatableOptions, setDatatableOptions] = useState(
    savedfilters && savedfilters?.datatableOptions
      ? (savedfilters?.datatableOptions as any)
      : {
          draw: 1,
          page: 1,
          recordsTotal: 0,
          recordsFiltered: 0,
          sizePerPage: 20,
          searchText: "",
          sortField: "id",
          sortOrder: "desc",
        }
  );
  const [filters, setFilters] = useState(
    savedfilters && savedfilters?.filters
      ? (savedfilters?.filters as any)
      : {
          is_filtered: filtersData?.is_filtered ? filtersData?.is_filtered : false,
          company_id: filtersData?.company_id ? filtersData?.company_id : "",
          user_id: filtersData?.user_id ? filtersData?.user_id : "",
        }
  );

  const onTableChange = (type: any, newState: any) => {
    let draw = datatableOptions.draw + 1;
    let page = 1;
    if (type == "search") {
      page = 1;
    } else {
      page = newState.page;
    }
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: draw,
      page: page,
      sizePerPage: newState.sizePerPage,
      searchText: newState.searchText,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
    };
    setDatatableOptions(updatedDatatableOptions);
    setDatatableListData([]);
    setLoadingListData(true);
  };

  const clearFilter = () => {
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: 1,
      page: 1,
      sizePerPage: 20,
      searchText: "",
      sortField: "id",
      sortOrder: "desc",
    };
    setDatatableOptions(updatedDatatableOptions);

    const filter = {
      ...filters,
      is_filtered: false,
      company_id: "",
      user_id: "",
    };
    setFilters(filter);

    setDatatableListData([]);
    setLoadingListData(true);
  };

  const loadingDatatable = async () => {
    try {
      const res = (await cpoService.getAll({
        ...getDataTableQuery(datatableOptions, columns),
        filters,
      })) as any;

      if (res.data.status === "success") {
        setDatatableListData(res.data.data);

        const updatedDatatableOptions = {
          ...datatableOptions,
          recordsTotal: res.data.recordsTotal,
          recordsFiltered: res.data.recordsFiltered,
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingListData(false);
      } else {
        setDatatableListData([]);
        setLoadingListData(false);
      }
    } catch (err) {
      setDatatableListData([]);
      setLoadingListData(false);
    }
  };

  useEffect(() => {
    if (loadingListData === true) {
      loadingDatatable();
    }
  }, [loadingListData]);

  useEffect(() => {
    setDatatableListData([]);
    setLoadingListData(true);
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  // Filters -------------------------------------------------
  const handleFiltersChange = (obj: any) => {
    setFilters((state: any) => ({ ...state, ...obj }));
  };

  const handleApplyFilters = () => {
    setDatatableListData([]);
    setLoadingListData(true);
    setOpenFilter(false);
  };
  // Filters -------------------------------------------------

  return (
    <React.Fragment>
      {deleteWhere && (
        <DeleteModal
          recordName={recordName}
          modalTitle={t("CPO")}
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
          loading={loading}
        />
      )}

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={t("CPO")}
            breadcrumbItems={breadcrumbItems}
            endAdornment={
              <div className="d-flex gap-2 text-end">
                {userProfile?.IS_INTERNAL && (
                  <Link to="/cpo/list/all" className={`btn btn-info btn-label btn-sm`}>
                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                      list_alt
                    </span>
                    {t("List view")}
                  </Link>
                )}

                {userProfile?.IS_INTERNAL && userProfile?.CAN_VIEW_ALL && (
                  <Link to="/cpo/graph" className={`btn btn-outline-secondary btn-label btn-sm`}>
                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                      insert_chart
                    </span>
                    {t("Graphical view")}
                  </Link>
                )}

                {userProfile.CAN_CREATE ? (
                  <Link to="/cpo/new-cpo" className="btn btn-primary btn-label btn-sm">
                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                      add
                    </span>
                    {t("Create")} CPO
                  </Link>
                ) : null}
              </div>
            }
          />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <DataTable
                    columns={columns}
                    products={datatableListData}
                    datatableOptions={datatableOptions}
                    filters={filters}
                    onTableChange={onTableChange}
                    loadingListData={loadingListData}
                    clearFilter={clearFilter}
                    settingTableName="cpo_table"
                    slug="all-cpo"
                    customButtons={
                      userProfile.IS_COMPANY_ADMIN ? (
                        <Button
                          title="Filter"
                          outline={filters.is_filtered === true ? false : true}
                          color={filters.is_filtered === true ? "secondary" : "light"}
                          onClick={() => setOpenFilter((state) => !state)}
                        >
                          {filters.is_filtered === false ? (
                            <span className="align-middle material-symbols-outlined fw-light fs-4">
                              filter_alt
                            </span>
                          ) : (
                            <span className="align-middle material-symbols-outlined fw-light fs-4">
                              filter_alt_off
                            </span>
                          )}
                        </Button>
                      ) : null
                    }
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Drawer
        title={t("Filters")}
        open={openFilter}
        onClose={() => setOpenFilter((state) => !state)}
        onReset={clearFilter}
        footer={
          <Button color="primary" style={{ margin: "16px" }} onClick={handleApplyFilters}>
            {t("Apply")}
          </Button>
        }
      >
        <Filters
          filters={filters}
          onApply={() => {}}
          onClear={() => {}}
          onChange={(obj: any) => handleFiltersChange(obj)}
        />
      </Drawer>

      <Drawer
        title={t("Client")}
        open={openCompany}
        onClose={() => setOpenCompany((state) => !state)}
        fullWidth
      >
        <EditCompany
          id={selectedData?.company_id}
          company_id={selectedData?.company_sub_id}
          type={1}
          isWindow={true}
        />
      </Drawer>

      <Drawer
        title={t("User")}
        open={openUser}
        onClose={() => setOpenUser((state) => !state)}
        fullWidth
      >
        <EditUser
          id={selectedData?.employee_id}
          user_id={selectedData?.employee_sub_id}
          type={1}
          isWindow={true}
        />
      </Drawer>
    </React.Fragment>
  );
};

export default CPOList;
