import React, { memo, useMemo, useState } from 'react';
import { useNavigate } from "react-router";
import { fromJS } from "immutable";
import TemplateModal from '../Questionnaire/TemplateDrawal/TemplateDrawer';
import Drawer from '../../components/Drawer';
import TemplateDrawer from '../Questionnaire/Template';
type questionnairesListProps = {
    id: number,
    data: any
}
const QuestionnairesList = ({ id, data }: questionnairesListProps) => {
    const [showDrawer, setShowDrawer] = useState<boolean>(false);

    const viewQuestionnnair = () => {
        setShowDrawer(true);
        //navigate(`/questionnaires/edit-questionnaire/${data.id}/${data.questionnaire_id}`)
    }
    return (
        <>

            <Drawer
                title="Questionnaire Preview"
                open={showDrawer}
                onClose={() => setShowDrawer(false)}
                fullWidth
            >
                <TemplateDrawer
                    data={data}
                />
            </Drawer>
            <table className="table table-striped table-bordered nowrap w-100">
                <thead className="thead-light">
                    <tr>
                        <th aria-label="Questionnaire Type sortable" className="sortable">Questionnaire Type<span className="order"><span className="dropdown"><span className="caret"></span></span><span className="dropup"><span className="caret"></span></span></span></th>
                        <th aria-label="Questionnaire Type sortable" className="sortable">Year<span className="order"><span className="dropdown"><span className="caret"></span></span><span className="dropup"><span className="caret"></span></span></span></th>
                        <th aria-label="Questionnaire Type sortable" className="sortable">Questionnaire Link<span className="order"><span className="dropdown"><span className="caret"></span></span><span className="dropup"><span className="caret"></span></span></span></th>
                        <th aria-label="Questionnaire Type sortable" className="sortable">Status<span className="order"><span className="dropdown"><span className="caret"></span></span><span className="dropup"><span className="caret"></span></span></span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {data.title}
                        </td>
                        <td>
                            {data.year}
                        </td>
                        <td>
                            <button type='button' onClick={() => viewQuestionnnair()} className="btn btn-sm"><span className="btn btn-pill btn-outline-primary btn-sm">View questionnaire<i className="fa-solid fa-arrow-up-right-from-square ms-2"></i></span></button>
                        </td>
                        <td><span><span className="badge rounded-pill badge_custome" style={{ color: '#ffffff', backgroundColor: data.color }}>{data.status}</span></span></td>
                    </tr>
                </tbody>
            </table>
        </>
    )
};
export default memo(QuestionnairesList);