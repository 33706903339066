import React from "react";
import { CardHeader, Nav, NavItem } from "reactstrap";
import Avatar from "../../components/Avatar";
import { Link } from "react-router-dom";

const NavConnections = (props: any) => {
  const { customer_id, connectionsData, bankData } = props;
  return (
    <React.Fragment>
      <CardHeader className="bg-transparent border-bottom pb-0">
        <div className="d-sm-flex flex-wrap">
          <Nav tabs className="nav-tabs-custom">
            {connectionsData &&
              connectionsData?.data?.length > 0 &&
              connectionsData?.data?.map((data: any, idx: number) => (
                <NavItem key={idx}>
                  <Link
                    style={{ cursor: "pointer" }}
                    to={`/banking/transactions/${customer_id}/${data?.connection_id}/${data?.account_id}`}
                    className={`${
                      window.location.pathname ===
                      `/banking/transactions/${customer_id}/${data?.connection_id}/${data?.account_id}`
                        ? "active"
                        : ""
                    }  nav-link`}
                  >
                    <span className="d-block d-sm-none">
                      <Avatar
                        name={
                          bankData.find((option: any) => option.bank_id == data?.provider_id)
                            ?.bank_name || "Unknown Bank"
                        }
                        src={
                          bankData.find((option: any) => option.bank_id == data?.provider_id)
                            ?.bank_logo
                        }
                      />
                    </span>
                    <span className="d-none d-sm-block">
                      <>
                        <Avatar
                          name={
                            bankData.find((option: any) => option.bank_id == data?.provider_id)
                              ?.bank_name || "Unknown Bank"
                          }
                          src={
                            bankData.find((option: any) => option.bank_id == data?.provider_id)
                              ?.bank_logo
                          }
                        />

                        {data?.client_name ? (
                          <span>{data?.client_name}</span>
                        ) : (
                          <span>
                            {bankData.find((option: any) => option.bank_id == data?.provider_id)
                              ?.bank_name || "Unknown Bank"}
                          </span>
                        )}
                      </>
                    </span>
                  </Link>
                </NavItem>
              ))}
          </Nav>
        </div>
      </CardHeader>
    </React.Fragment>
  );
};

export default NavConnections;
