import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Form,
  Label,
  Button,
  FormFeedback,
  CardSubtitle,
} from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import labelPlugin from "flatpickr/dist/plugins/labelPlugin/labelPlugin";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { datePickerI18, getDependentMultipleWhereSelectOptions, getSelectOptions, getUserProfile, handleAddress, handleAutoSave, yearsBackword } from "../../../helpers/functions_helper";
import { generalStatusOptions, DocumentStatusMaster, YesNoMaster, StatusMaster, EvaluationMaster } from "../../../common/staticdata";

import userGeneralService from '../../../services/users.general.service';
import DocStatus from './components/DocStatus';
import Beneficiary from "./components/Beneficiary";
import { useSelector, useDispatch } from 'react-redux';
import { CommonAction, CompanyAction } from "../../../store/actions";
import compnayGeneralService from "../../../services/compnay.general.service";
import FileUpload from "../../../components/Common/FileUpload";
import commonService from "../../../services/common.service";
import fileDownload from 'js-file-download';
import UploadDocModal from "../../Users/UploadDocModal";
import { Buffer } from "buffer";
import { useTranslation } from "react-i18next";
import DatePicker from "../../../components/Form/DatePicker/DatePicker";
import Autocomplete from "react-google-autocomplete";
import Input from "../../../components/Form/Input";
import Select from "../../../components/Form/Select/Select";
import Flatpickr from "../../../components/Form/Flatpickr";
import { useCallbackPrompt } from "../../../hooks/useCallbackPrompt";
import Alert from "../../../components/Common/Alert";
import NavigationAlert from "../../../components/Common/NavigationAlert";


type GeneralType = {
  id: string;
  company_id: string;
  ubo: number;
  ubo_due_date: string;
  ubo_expiry_date: string;
  ubo_notification: number;
  ubo_status: number;

  aml: number;
  aml_due_date: string;
  aml_expiry_date: string;
  aml_notification: number;
  aml_status: number;

  gdpr_status: number;
  gdpr_due_date: string;
  gdpr_expiry_date: string;
  gdpr_compliant_process: string;
  gdpr_notification: number;
  gdpr_status_t: number;

  evaluation: number;
  evaluation_due_date: string;
  evaluation_expiry_date: string;
  evaluation_status: number;

  news_letter: number;
  news_letter_activate_date: string;
  news_letter_expiry_date: string;

  e_poa: number;
  e_poa_year: string;
  e_poa_due_date: string;
  e_poa_expiry_date: string;
  e_poa_status: number;

  responsible_person: Array<number>;

  my_minfin: number;
  my_minfin_activate_date: string;
  my_minfin_expiry_date: string;
  my_minfin_status: string;

  research_center: string;
  control_and_cordi_office: string;

  beneficiary: Array<any>;
  tax_on_web_status: number;
  tax_on_web_expiry_date: string;
  tax_on_web_due_date: string;
  tax_on_web_document: string;

  proxy_tax_inspector: number,
  proxy_tow: number,
  proxy_filing_date: string,
  proxy_approval_date: string,
  proxy_inspector_address: string,

  submit_btn_code: string;
};

const CompanyGeneral = (props: any) => {

  const { id, company_id } = props;
  const userProfile = getUserProfile("all-company");
  const { t } = useTranslation();
  const toastId = React.useRef(null) as any;
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [minDueDate, setMinDueDate] = useState('');
  const [minExpDate, setExpDueDate] = useState('');
  const [generalData, setGeneralData] = useState([]);
  const [generalAddrData, setGeneralAddrData] = useState([]);
  const [addressData, setAddressData] = useState<any>([]);
  const [redirectCode, setRedirectCode] = useState("");

  let { userList, companygeneral } = useSelector((state: any) => ({
    userList: state.Common.GET_USER_OPTIONS_LIST_SUCCESS || { data: [] },
    companygeneral: state.Company.GET_COMPANY_GENERAL_SUCCESS,
  }));

  useEffect(() => {
    if (!userList) dispatch(CommonAction.getUserOptionsList());
  }, []);

  const [loading, setLoading] = useState(false);

  const localFormData = handleAutoSave("GET");
  const [autoSave, setAutoSave] = useState(false);
  const [autoSaveAlert, setAutoSaveAlert] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(autoSave);
  
  const [intialData, setIntialData] = useState<GeneralType>({
    id: "",
    company_id: id,
    ubo: 0,
    ubo_due_date: "",
    ubo_expiry_date: "",
    ubo_notification: 0,
    ubo_status: 0,

    aml: 0,
    aml_due_date: "",
    aml_expiry_date: "",
    aml_notification: 0,
    aml_status: 0,

    gdpr_status: 0,
    gdpr_due_date: "",
    gdpr_expiry_date: "",
    gdpr_compliant_process: "",
    gdpr_notification: 0,
    gdpr_status_t: 0,


    evaluation: 0,
    evaluation_due_date: "",
    evaluation_expiry_date: "",
    evaluation_status: 0,

    news_letter: 0,
    news_letter_activate_date: "",
    news_letter_expiry_date: "",

    e_poa: 0,
    e_poa_year: "",
    e_poa_due_date: "",
    e_poa_expiry_date: "",
    e_poa_status: 0,

    responsible_person: [],

    my_minfin: 0,
    my_minfin_activate_date: "",
    my_minfin_expiry_date: "",
    my_minfin_status: "",

    research_center: "",
    control_and_cordi_office: "",

    beneficiary: [],

    tax_on_web_status: 0,
    tax_on_web_expiry_date: "",
    tax_on_web_due_date: "",
    tax_on_web_document: "",

    proxy_tax_inspector: 0,
    proxy_tow: 0,
    proxy_filing_date: "",
    proxy_approval_date: "",
    proxy_inspector_address: "",

    submit_btn_code: "update",
  });

  const handleChange = (fieldName: string, fieldValue: string) => {
    setIntialData((preState => ({ ...preState, [fieldName]: fieldValue })));
    setAutoSave(true);
  };

  const [documentStatusList, setDocumentStatusList] = useState<any>([]);
  const [generalStatusList, setGeneralStatusList] = useState<any>([]);
  const [yesNoList, setYesNoList] = useState<any>([]); 
  const [statusList, setStatusList] = useState<any>([]);
  const [evaluationList, setEvaluationList]= useState<any>([]);

  useEffect(() => {
    if(DocumentStatusMaster){
     const Options = DocumentStatusMaster?.map((option: any) => ({
       label: t(option.label),
       value: option.value
     }));
     setDocumentStatusList(Options);
    }


    if(generalStatusOptions){
     const Options = generalStatusOptions?.map((option: any) => ({
       label: t(option.label),
       value: option.value
     }));
     setGeneralStatusList(Options);
    }

    if(YesNoMaster){
     const Options = YesNoMaster?.map((option: any) => ({
       label: t(option.label),
       value: option.value
     }));
     setYesNoList(Options);
    }

    if(StatusMaster){
     const Options = StatusMaster?.map((option: any) => ({
       label: t(option.label),
       value: option.value
     }));
     setStatusList(Options);
    }

    if(EvaluationMaster){
     const Options = EvaluationMaster?.map((option: any) => ({
       label: t(option.label),
       value: option.value
     }));
     setEvaluationList(Options);
    }
  
  }, [localStorage.getItem("I18N_LANGUAGE")])

  const handleRedirect = () => {
    setAutoSave(false);
    handleAutoSave("CLEAR");

    if (redirectCode === "update_close") {
      return navigate("/masters/company?redirect=true");
    } else if (redirectCode === "update") {
      window.scrollTo(0, 0);
    } else {
      return navigate("/masters/company?redirect=true");
    }
  };
  useEffect(() => {
    if (redirectCode !== "") {
      handleRedirect();
    }
  }, [redirectCode]);

  type dueorexpType = 'due' | 'exp';
  const syncDate = (type: string, xdate: any, dueorexp: dueorexpType) => {
    if (xdate) {
      const threeMonthAdd = moment(xdate).add(1, 'M').format('YYYY-M-D');
      const threeMonthSub = moment(xdate).subtract(1, 'M').format('YYYY-M-D');
      switch (type) {
        case 'ubo':
          if (dueorexp === 'due') {
            handleChange("ubo_expiry_date", threeMonthAdd);
          } else {
            handleChange("ubo_due_date", threeMonthSub);
          }
          break;
        case 'aml':
          if (dueorexp === 'due') {
            handleChange("aml_expiry_date", threeMonthAdd);
          } else {
            handleChange("aml_due_date", threeMonthSub);
          }
          break;
        case 'gdpr':
          if (dueorexp === 'due') {
            handleChange("gdpr_expiry_date", threeMonthAdd);
          } else {
            handleChange("gdpr_due_date", threeMonthSub);
          }
          break;
        case 'evaluation':
          if (dueorexp === 'due') {
            handleChange("evaluation_expiry_date", threeMonthAdd);
          } else {
            handleChange("evaluation_due_date", threeMonthSub);
          }
          break;
      }
    }
    //console.log(intialData);
  }

  const validationSchema = Yup.object().shape({
    ubo_due_date: Yup.string().nullable().when("ubo", {
      is: (ubo: any) => ubo > 0,
      then: Yup.string().nullable().required("UBO Due date field is required."),
    }),
    ubo_expiry_date: Yup.string().nullable().when("ubo", {
      is: (ubo: any) => ubo > 0,
      then: Yup.string().nullable().required("UBO Expiry date field is required."),
    }),

    aml_due_date: Yup.string().nullable().when("aml", {
      is: (aml: any) => aml > 0,
      then: Yup.string().nullable().required("AML Due date field is required."),
    }),
    aml_expiry_date: Yup.string().nullable().when("aml", {
      is: (aml: any) => aml > 0,
      then: Yup.string().nullable().required("AML Expiry date field is required."),
    }),

    gdpr_due_date: Yup.string().nullable().when("gdpr_status", {
      is: (gdpr_status: any) => gdpr_status > 0,
      then: Yup.string().nullable().required("GDPR due date field is required."),
    }),
    gdpr_expiry_date: Yup.string().nullable().when("gdpr_status", {
      is: (gdpr_status: any) => gdpr_status > 0,
      then: Yup.string().nullable().required("GDPR Expiry date field is required."),
    }),

    e_poa_year: Yup.string().nullable().when("e_poa", {
      is: "1",
      then: Yup.string().nullable().required("e-POA Year field is required."),
    }),
    e_poa_due_date: Yup.string().nullable().when("e_poa", {
      is: "1",
      then: Yup.string().nullable().required("e-POA Due date field is required."),
    }),
    e_poa_expiry_date: Yup.string().nullable().when("e_poa", {
      is: "1",
      then: Yup.string().nullable().required("e-POA Expiry date field is required."),
    }),
    tax_on_web_expiry_date: Yup.string().nullable().when("tax_on_web_status", {
      is: "1",
      then: Yup.string().nullable().required("Tax on web expiry date field is required."),
    }),
    tax_on_web_due_date: Yup.string().nullable().when("tax_on_web_status", {
      is: "1",
      then: Yup.string().nullable().required("Tax on web due date field is required."),
    }),

    proxy_filing_date: Yup.string().nullable().when("proxy_tax_inspector", {
      is: "1",
      then: Yup.string().nullable().required("Filing date field is required."),
    }),
    proxy_approval_date: Yup.string().nullable().when("proxy_tax_inspector", {
      is: "1",
      then: Yup.string().nullable().required("Approval date field is required."),
    }),
  });

  const handleUsersGeneral = async (values: GeneralType) => {
    toast.dismiss();
    toastId.current = toast(t("Updating Company general..."), {
      autoClose: false,
      isLoading: true,
    });

    try {
      const res = (await compnayGeneralService.updateCompanyGeneral(values)) as any;
      if (res.data.status === "success") {
        setRedirectCode(intialData.submit_btn_code);

        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: t("Company general information updated"),
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        updateInitialData(res.data.data[0]);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: t("Something went wrong."),
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
      }

    } catch (e) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: t("Something went wrong."),
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
  };

  const updateInitialData = (data: GeneralType) => {
    setIntialData((preData: GeneralType) => ({
      ...preData,
      'id': data.id,
      'company_id': data.company_id,
      'ubo': data.ubo,
      'ubo_due_date': data.ubo_due_date,
      'ubo_expiry_date': data.ubo_expiry_date,
      'ubo_notification': data.ubo_notification,
      'ubo_status': data.ubo_status,
      'aml': data.aml,
      'aml_due_date': data.aml_due_date,
      'aml_expiry_date': data.aml_expiry_date,
      'aml_notification': data.aml_notification,
      'aml_status': data.aml_status,
      'gdpr_due_date': data.gdpr_due_date,
      'gdpr_expiry_date': data.gdpr_expiry_date,
      'gdpr_compliant_process': data.gdpr_compliant_process,
      'gdpr_notification': data.gdpr_notification,
      'gdpr_status_t': data.gdpr_status_t,
      'evaluation': data.evaluation,
      'evaluation_due_date': data.evaluation_due_date,
      'evaluation_expiry_date': data.evaluation_expiry_date,
      'evaluation_status': data.evaluation_status,
      'news_letter': data.news_letter,
      'news_letter_activate_date': data.news_letter_activate_date,
      'news_letter_expiry_date': data.news_letter_expiry_date,
      'e_poa': data.e_poa,
      'year': data.e_poa_year,
      'e_poa_due_date': data.e_poa_due_date,
      'e_poa_expiry_date': data.e_poa_expiry_date,
      'e_poa_status': data.e_poa_status,
      'responsible_person': data.responsible_person,
      'my_minfin': data.my_minfin,
      'my_minfin_activate_date': data.my_minfin_activate_date,
      'my_minfin_expiry_date': data.my_minfin_expiry_date,
      'my_minfin_status': data.my_minfin_status,
      'research_center': data.research_center,
      'control_and_cordi_office': data.control_and_cordi_office,
      'beneficiary': data.beneficiary,

      'tax_on_web_status': data.tax_on_web_status || 0,
      'tax_on_web_expiry_date': data.tax_on_web_expiry_date || "",
      'tax_on_web_due_date': data.tax_on_web_due_date || "",
      'tax_on_web_document': data.tax_on_web_document || "",

      'proxy_tax_inspector': data.proxy_tax_inspector || 0,
      'proxy_tow': data.proxy_tow || 0,
      'proxy_filing_date': data.proxy_filing_date == null ? "" : data.proxy_filing_date,
      'proxy_approval_date': data.proxy_approval_date == null ? "" : data.proxy_approval_date,
      'proxy_inspector_address': data.proxy_inspector_address || "",
    }))
  }

  useEffect(() => {
    if (localFormData && localFormData?.initialData) {
      updateInitialData(localFormData?.initialData);
      setAutoSaveAlert(true);
    } else if (generalData.length == 0) {
      dispatch(CompanyAction.getCompanyGeneral({ id }));
    }
  }, [generalData, id]);

  useEffect(() => {
    if (companygeneral?.data && companygeneral?.data[0]) {
      setGeneralData(companygeneral.data[0]);
      setIntialData(companygeneral.data[0]);
      setGeneralAddrData(companygeneral.general_data[0])
      if (companygeneral.general_data[0]) {
        const cmp_add = `${companygeneral.general_data[0]?.company_address != null ? companygeneral.general_data[0]?.company_address : ""} ${companygeneral.general_data[0]?.street_number != null ? companygeneral.general_data[0]?.street_number : ""} ${companygeneral.general_data[0]?.office_number != null ? companygeneral.general_data[0]?.office_number : ""} ${companygeneral.general_data[0]?.box != null ? companygeneral.general_data[0]?.box : ""} ${companygeneral.general_data[0]?.postal_code != null ? companygeneral.general_data[0]?.postal_code : ""} ${companygeneral.general_data[0]?.city != null ? companygeneral.general_data[0]?.city : ""} ${companygeneral.general_data[0]?.address_country != null ? companygeneral.general_data[0]?.address_country : ""}`;
        const cmp_inv_add = `${companygeneral.general_data[0]?.inv_company_address != null ? companygeneral.general_data[0]?.inv_company_address : ""} ${companygeneral.general_data[0]?.inv_office_number != null ? companygeneral.general_data[0]?.inv_office_number : ""}  ${companygeneral.general_data[0]?.inv_box != null ? companygeneral.general_data[0]?.inv_box : ""} ${companygeneral.general_data[0]?.inv_postal_code != null ? companygeneral.general_data[0]?.inv_postal_code : ""} ${companygeneral.general_data[0]?.inv_city != null ? companygeneral.general_data[0]?.inv_city : ""} ${companygeneral.general_data[0]?.address_inv_country != null ? companygeneral.general_data[0]?.address_inv_country : ""}`;
        const cntryOpt: any = [
          {
            "label": cmp_add,
            "value": 1
          },
        ];
        if (!companygeneral.general_data[0].inv_like_company_address && companygeneral.general_data[0].inv_company_address != null) {
          cntryOpt.push({
            "label": cmp_inv_add,
            "value": 1
          })
        }
        setAddressData(cntryOpt);
      }
    }
  }, [companygeneral]);

  //Uploda Document 
  const [uploadDocModal, setUploadDocModal] = useState<boolean>(false);
  const [uploadDocData, setUploadDocData] = useState<any>([]);
  const [fileData, setFileData] = useState<any>([]);

  const handleUploadDocument = () => {
    const data = {
      type: "Tax",
      id: id,
      user_id: company_id,
      title: "Tax",
      year: intialData.tax_on_web_expiry_date ? moment(intialData.tax_on_web_expiry_date).format('YYYY') : moment().format('YYYY'),
      exp_date: intialData.tax_on_web_expiry_date,
      due_date: intialData.tax_on_web_due_date,
    };
    setUploadDocData(data);
    setUploadDocModal(true);
  }

  const handleSingleDownload = async () => {
    if (fileData) {
      const row = fileData[0];
      if (row) {
        const result = await commonService.downloadAttachment({ id: Buffer.from(String(row.id)).toString("base64"), attachment_id: Buffer.from(String(row.attachment_id)).toString("base64") });
        if (result) {
          fileDownload(result.data, row.file_name + "." + row.file_extension);
        }
      } else {
        toastId.current = toast(t("File is not available"), {
          type: toast.TYPE.ERROR,
          isLoading: false,
        });
      }

    }
  }

  // const get = async () => {
  //   try {
  //     const res = (await compnayGeneralService.getCompanyGeneral({ id })) as any;
  //     if (res.data.status === "success") {
  //       updateInitialData(res.data.data[0]);
  //     } else {
  //       toast.update(toastId.current, {
  //         render: res?.data?.message,
  //         type: toast.TYPE.ERROR,
  //         isLoading: false,
  //         autoClose: 5000
  //       })
  //     }
  //   } catch (err: any) {
  //     toast.update(toastId.current, {
  //       render: err?.data?.message,
  //       type: toast.TYPE.ERROR,
  //       isLoading: false,
  //       autoClose: 5000
  //     })
  //   }
  // };


  // useEffect(() => {
  //   get();
  // }, [])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: intialData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleUsersGeneral(values);
    },
  });


  useEffect(() => {
    //console.log(validation.isSubmitting,validation.errors)
    if (!validation.isSubmitting) return;
    if (Object.keys(validation.errors).length > 0) {
      document
        .getElementById(Object.keys(validation.errors)[0])
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
      document
        .getElementById(Object.keys(validation.errors)[0])
        ?.focus({ preventScroll: true });
    }
  }, [validation.isSubmitting, validation.errors]);

  useEffect(() => {
    if (intialData.news_letter == 0) {
      const updatedIntialData = {
        ...intialData,
        news_letter_activate_date: "",
        news_letter_expiry_date: "",
      };
      setIntialData(updatedIntialData);
    }
  }, [intialData.news_letter]);

  useEffect(() => {
    if (intialData.e_poa == 0) {
      const updatedIntialData = {
        ...intialData,
        e_poa_year: "",
        e_poa_due_date: "",
        e_poa_expiry_date: "",
        e_poa_status: 0
      };
      setIntialData(updatedIntialData);
    }
  }, [intialData.e_poa]);

  useEffect(() => {
    if (intialData.tax_on_web_expiry_date) {
      const tax_on_web_due_date_str = moment(intialData.tax_on_web_expiry_date).subtract(3, 'months').format('YYYY-M-D');
      const updatedIntialData = {
        ...intialData,
        tax_on_web_due_date: tax_on_web_due_date_str,
      };
      setIntialData(updatedIntialData);
    }
  }, [intialData.tax_on_web_expiry_date])

  useEffect(() => {
    getAttachmentList();
  }, [])

  const getAttachmentList = async () => {
    const moduleData = {
      module_app_id: 12,
      module_main_id: id,
      module_sub_id: company_id,
      section_id: "",
      file_path: 'company',
    }
    const response = await commonService.getAttachmentList(moduleData);
    if (response.data.status === "success") {
      setFileData(response.data.data.original.data);
    }
  };

  const onUploadSuccess = (id: any) => {
    getAttachmentList();
  }

  const handleAddressChange = async (data: any) => {
    const addressData = await handleAddress(data);
    setIntialData((prevData: any) => ({
      ...prevData,
      proxy_inspector_address: addressData?.address,
    }));
  };

  useEffect(() => {
    if (autoSave) { handleAutoSave("SET", intialData); }
  }, [intialData, autoSave]);

  return (
    <React.Fragment>
      <Alert
        open={autoSaveAlert}
        title={t("Autosaved data restored!")}
        message={t("Your last autosaved data has been restored from the session. In order to avoid the data loss, you need either save it to retain the data or Cancel to loose the data.")}
        onClickOk={() => setAutoSaveAlert(false)}
      />
      <NavigationAlert
        open={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />

      {uploadDocModal && (
        <UploadDocModal
          data={uploadDocData}
          isOpen={uploadDocModal}
          handleClose={() => setUploadDocModal(false)}
          onSuccess={onUploadSuccess}
          type="company"
        />
      )}

      <Row>
        <Col xl="12">
          <Card className="border-top-0 rounded-0 rounded-bottom">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        autoComplete="off">
        <Input id="id" name="id" type="hidden" value={intialData.id} />
        <Input id="company_id" name="company_id" type="hidden" value={intialData.company_id} />
        <CardHeader className="bg-transparent border-bottom border-primary mt-2 pb-0">
          <CardTitle className="card-title text-uppercase">{t("General information")}</CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col className="col-6">
              <CardSubtitle className="mb-2 text-muted" tag="h6">{t("KYC- Know your customer")}</CardSubtitle>
            </Col>

            <DocStatus value={intialData.ubo} status={intialData.ubo_status} due_date={intialData.ubo_due_date} e_date={intialData.ubo_expiry_date} />
            <Col xl={4} lg={4} md={4} sm={6}>
              <div className="mb-3">
                <Select
                  id="ubo"
                  name="ubo"
                  label="UBO"
                  className="is-invalid"
                  options={documentStatusList}
                  value={
                    intialData.ubo
                      ? documentStatusList?.find(
                        (option: any) => option.value == intialData.ubo
                      )
                      : ""
                  }
                  isSearchable={true}
                  isDisabled={!userProfile.IS_COMPANY_ADMIN}
                  onBlur={() => validation.setFieldTouched("ubo", true)}
                  onChange={(e: any) => {
                    handleChange("ubo", e.value);
                  }}
                  error={validation.errors.ubo}
                  touched={validation.touched.ubo}
                />
              </div>
            </Col>

            <Col xl={2} lg={2} md={2} sm={12}>
              <div className="mb-3">
                  <Flatpickr
                    id="ubo_due_date"
                    name="ubo_due_date"
                    label="UBO Due date"
                    required={intialData.ubo > 0 ? true : false}
                    className="form-control d-block"
                    type="text"
                    value={intialData.ubo_due_date}
                    placeholder="DD/MM/YYYY"
                    disabled={!userProfile.IS_COMPANY_ADMIN}
                    options={{
                      altInput: true,
                      altFormat: "d/m/Y",
                      dateFormat: "Y-m-d",
                      allowInput: true,
                      plugins: [labelPlugin()],
                      onChange: function (selectedDates, dateStr, instance) {
                        handleChange("ubo_due_date", dateStr);
                        syncDate('ubo', dateStr, 'due');
                      },
                      onClose: function (selectedDates, dateStr, instance) {
                        handleChange("ubo_due_date", dateStr);
                        syncDate('ubo', dateStr, 'due');
                      },
                      locale: { ...datePickerI18(),firstDayOfWeek: 1 }
                    }}
                    error={validation.errors.ubo_due_date}
                    touched={validation.touched.ubo_due_date}
                  />
              </div>
            </Col>

            <Col xl={2} lg={2} md={2} sm={12}>
              <div className="mb-3">
                  <Flatpickr
                    id="ubo_expiry_date"
                    name="ubo_expiry_date"
                    className="form-control d-block"
                    type="text"
                    label="UBO Expiry date"
                    required={intialData.ubo > 0 ? true : false}
                    value={intialData.ubo_expiry_date}
                    placeholder="DD/MM/YYYY"
                    disabled={!userProfile.IS_COMPANY_ADMIN}
                    error={validation.errors.ubo_expiry_date}
                    touched={validation.touched.ubo_expiry_date}
                    options={{
                      altInput: true,
                      altFormat: "d/m/Y",
                      dateFormat: "Y-m-d",
                      allowInput: true,
                      plugins: [labelPlugin()],
                      onChange: function (selectedDates, dateStr, instance) {
                        handleChange("ubo_expiry_date", dateStr);
                        syncDate('ubo', dateStr, 'exp');
                      },
                      onClose: function (selectedDates, dateStr, instance) {
                        handleChange("ubo_expiry_date", dateStr);
                        syncDate('ubo', dateStr, 'exp');
                      },
                      locale: { ...datePickerI18(),firstDayOfWeek: 1 }
                    }}
                  />
              </div>
            </Col>

            {/* <Col xl={2} lg={2} md={2} sm={6}>
              <div className="mb-3">
                <Label className="form-label">
                  UBO notification
                </Label>
                <Select
                  id="ubo_notification"
                  name="ubo_notification"
                  className="is-invalid"
                  options={YesNoMaster}
                  value={
                    intialData.ubo_notification
                      ? YesNoMaster.find(
                        (option: any) => option.value == intialData.ubo_notification
                      )
                      : ""
                  }
                  isSearchable={true}
                  onBlur={() => validation.setFieldTouched("ubo_notification", true)}
                  onChange={(e: any) => {
                    handleChange("ubo_notification", e.value);
                  }}
                />
                {validation.touched.ubo_notification && validation.errors.ubo_notification ? (
                  <FormFeedback type="invalid">{validation.errors.ubo_notification}</FormFeedback>
                ) : null}
              </div>
            </Col> */}

            <Col xl={2} lg={2} md={2} sm={6}>
              <div className="mb-3">
                <Select
                  id="ubo_status"
                  name="ubo_status"
                  label="Status"
                  className="is-invalid"
                  options={statusList}
                  isDisabled={!userProfile.IS_COMPANY_ADMIN}
                  value={
                    intialData.ubo_status
                      ? statusList?.find(
                        (option: any) => option.value == intialData.ubo_status
                      )
                      : ""
                  }
                  isSearchable={true}
                  onBlur={() => validation.setFieldTouched("ubo_status", true)}
                  onChange={(e: any) => {
                    handleChange("ubo_status", e.value);
                  }}
                  error={validation.errors.ubo_status}
                  touched={validation.touched.ubo_status}
                />
              </div>
            </Col>

            <Col className="col-12">
              <hr></hr>
            </Col>

            <DocStatus value={intialData.aml} status={intialData.aml_status} due_date={intialData.aml_due_date} e_date={intialData.aml_expiry_date} />
            <Col xl={4} lg={4} md={4} sm={6}>
              <div className="mb-3">
                <Select
                  id="aml"
                  name="aml"
                  label="AML"
                  className="is-invalid"
                  options={documentStatusList}
                  value={
                    intialData.aml
                      ? documentStatusList?.find(
                        (option: any) => option.value == intialData.aml
                      )
                      : ""
                  }
                  isSearchable={true}
                  isDisabled={!userProfile.IS_COMPANY_ADMIN}
                  onBlur={() => validation.setFieldTouched("aml", true)}
                  onChange={(e: any) => {
                    handleChange("aml", e.value);
                  }}
                  error={validation.errors.aml}
                  touched={validation.touched.aml}
                />
              </div>
            </Col>

            <Col xl={2} lg={2} md={2} sm={12}>
              <div className="mb-3">
                  <Flatpickr
                    id="aml_due_date"
                    name="aml_due_date"
                    label="AML Due date"
                    required={intialData.aml > 0 ? true : false}
                    className="form-control d-block"
                    type="text"
                    value={intialData.aml_due_date}
                    placeholder="DD/MM/YYYY"
                    disabled={!userProfile.IS_COMPANY_ADMIN}
                    options={{
                      altInput: true,
                      altFormat: "d/m/Y",
                      dateFormat: "Y-m-d",
                      allowInput: true,
                      plugins: [labelPlugin()],
                      onChange: function (selectedDates, dateStr, instance) {
                        handleChange("aml_due_date", dateStr);
                        syncDate('aml', dateStr, 'due');
                      },
                      onClose: function (selectedDates, dateStr, instance) {
                        handleChange("aml_due_date", dateStr);
                        syncDate('aml', dateStr, 'due');
                      },
                      locale: { ...datePickerI18(),firstDayOfWeek: 1 }
                    }}
                    error={validation.errors.aml_due_date}
                    touched={validation.touched.aml_due_date}
                  />
              </div>
            </Col>

            <Col xl={2} lg={2} md={2} sm={12}>
              <div className="mb-3">
                  <Flatpickr
                    id="aml_expiry_date"
                    name="aml_expiry_date"
                    label="AML Expiry date"
                    required={intialData.aml > 0 ? true : false}
                    className="form-control d-block"
                    type="text"
                    value={intialData.aml_expiry_date}
                    placeholder="DD/MM/YYYY"
                    disabled={!userProfile.IS_COMPANY_ADMIN}
                    options={{
                      altInput: true,
                      altFormat: "d/m/Y",
                      dateFormat: "Y-m-d",
                      allowInput: true,
                      plugins: [labelPlugin()],
                      onChange: function (selectedDates, dateStr, instance) {
                        handleChange("aml_expiry_date", dateStr);
                        syncDate('aml', dateStr, 'exp');
                      },
                      onClose: function (selectedDates, dateStr, instance) {
                        handleChange("aml_expiry_date", dateStr);
                        syncDate('aml', dateStr, 'exp');
                      },
                      locale: { ...datePickerI18(),firstDayOfWeek: 1 }
                    }}
                    error={validation.errors.aml_expiry_date}
                    touched={validation.touched.aml_expiry_date}
                  />
              </div>
            </Col>

            {/* <Col xl={2} lg={2} md={2} sm={6}>
              <div className="mb-3">
                <Label className="form-label">
                  AML notification
                </Label>
                <Select
                  id="aml_notification"
                  name="aml_notification"
                  className="is-invalid"
                  options={YesNoMaster}
                  value={
                    intialData.aml_notification
                      ? YesNoMaster.find(
                        (option: any) => option.value == intialData.aml_notification
                      )
                      : ""
                  }
                  isSearchable={true}
                  onBlur={() => validation.setFieldTouched("aml_notification", true)}
                  onChange={(e: any) => {
                    handleChange("aml_notification", e.value);
                  }}
                />
                {validation.touched.aml_notification && validation.errors.aml_notification ? (
                  <FormFeedback type="invalid">{validation.errors.aml_notification}</FormFeedback>
                ) : null}
              </div>
            </Col> */}

            <Col xl={2} lg={2} md={2} sm={6}>
              <div className="mb-3">
                <Select
                  id="aml_status"
                  name="aml_status"
                  className="is-invalid"
                  label="Status"
                  options={statusList}
                  value={
                    intialData.aml_status
                      ? statusList?.find(
                        (option: any) => option.value == intialData.aml_status
                      )
                      : ""
                  }
                  isSearchable={true}
                  isDisabled={!userProfile.IS_COMPANY_ADMIN}
                  onBlur={() => validation.setFieldTouched("aml_status", true)}
                  onChange={(e: any) => {
                    handleChange("aml_status", e.value);
                  }}
                  error={validation.errors.aml_status}
                  touched={validation.touched.aml_status}
                />
              </div>
            </Col>

             {!!userProfile.IS_COMPANY_ADMIN && 
             <>
              <Col className="col-12">
                <hr></hr>
              </Col>
              <DocStatus evaluation_value={intialData.evaluation} evaluation_status={intialData.evaluation_status} evaluation_due_date={intialData.evaluation_due_date} evaluation_e_date={intialData.evaluation_expiry_date} />
              <Col xl={2} lg={2} md={2} sm={6}>
                <div className="mb-3">
                  <Select
                    id="evaluation"
                    name="evaluation"
                    className="is-invalid"
                    label="Evaluation of Client"
                    options={documentStatusList}
                    value={
                      intialData.evaluation
                        ? documentStatusList?.find(
                          (option: any) => option.value == intialData.evaluation
                        )
                        : ""
                    }
                    isSearchable={true}
                    isDisabled={!userProfile.IS_COMPANY_ADMIN}
                    onBlur={() => validation.setFieldTouched("evaluation", true)}
                    onChange={(e: any) => {
                      handleChange("evaluation", e.value);
                    }}
                    error={validation.errors.evaluation}
                    touched={validation.touched.evaluation}
                  />
                </div>
              </Col>

              <Col xl={2} lg={2} md={2} sm={12}>
                <div className="mb-3">
                    <Flatpickr
                      id="evaluation_due_date"
                      name="evaluation_due_date"
                      label="Due date"
                      className="form-control d-block"
                      type="text"
                      value={intialData.evaluation_due_date}
                      placeholder="DD/MM/YYYY"
                      disabled={!userProfile.IS_COMPANY_ADMIN}
                      options={{
                        altInput: true,
                        altFormat: "d/m/Y",
                        dateFormat: "Y-m-d",
                        allowInput: true,
                        plugins: [labelPlugin()],
                        onChange: function (selectedDates, dateStr, instance) {
                          handleChange("evaluation_due_date", dateStr);
                          syncDate('evaluation', dateStr, 'due');
                        },
                        onClose: function (selectedDates, dateStr, instance) {
                          handleChange("evaluation_due_date", dateStr);
                          syncDate('evaluation', dateStr, 'due');
                        },
                        locale: { ...datePickerI18(),firstDayOfWeek: 1 }
                      }}
                      error={validation.errors.evaluation_due_date}
                      touched={validation.touched.evaluation_due_date}
                    />
                </div>
              </Col>

              <Col xl={2} lg={2} md={2} sm={12}>
                <div className="mb-3">
                    <Flatpickr
                      id="evaluation_expiry_date"
                      name="evaluation_expiry_date"
                      label="Expiry date"
                      className="form-control d-block"
                      type="text"
                      value={intialData.evaluation_expiry_date}
                      placeholder="DD/MM/YYYY"
                      disabled={!userProfile.IS_COMPANY_ADMIN}
                      options={{
                        altInput: true,
                        altFormat: "d/m/Y",
                        dateFormat: "Y-m-d",
                        allowInput: true,
                        plugins: [labelPlugin()],
                        onChange: function (selectedDates, dateStr, instance) {
                          handleChange("evaluation_expiry_date", dateStr);
                          syncDate('evaluation', dateStr, 'exp');
                        },
                        onClose: function (selectedDates, dateStr, instance) {
                          handleChange("evaluation_expiry_date", dateStr);
                          syncDate('evaluation', dateStr, 'exp');
                        },
                        locale: { ...datePickerI18(),firstDayOfWeek: 1 }
                      }}
                      error={validation.errors.evaluation_expiry_date}
                      touched={validation.touched.evaluation_expiry_date}
                    />
                </div>
              </Col>

              <Col xl={2} lg={2} md={2} sm={6}>
                <div className="mb-3">
                  <Select
                    id="evaluation_status"
                    name="evaluation_status"
                    label="Status"
                    className="is-invalid"
                    options={evaluationList}
                    isDisabled={!userProfile.IS_COMPANY_ADMIN}
                    value={
                      intialData.evaluation_status
                        ? evaluationList?.find(
                          (option: any) => option.value == intialData.evaluation_status
                        )
                        : ""
                    }
                    isSearchable={true}
                    onBlur={() => validation.setFieldTouched("evaluation_status", true)}
                    onChange={(e: any) => {
                      handleChange("evaluation_status", e.value);
                    }}
                    error={validation.errors.evaluation_status}
                    touched={validation.touched.evaluation_status}
                  />
                </div>
              </Col>
            </>
          }

            <Col className="col-12">
              <hr></hr>
            </Col>

            <DocStatus value={intialData.gdpr_status} status={intialData.gdpr_status_t} due_date={intialData.gdpr_due_date} e_date={intialData.gdpr_expiry_date} />
            <Col xl={2} lg={2} md={2} sm={6}>
              <div className="mb-3">
                <Select
                  id="gdpr_status"
                  name="gdpr_status"
                  label="GDPR status"
                  className="is-invalid"
                  options={documentStatusList}
                  value={
                    intialData.gdpr_status
                      ? documentStatusList?.find(
                        (option: any) => option.value == intialData.gdpr_status
                      )
                      : ""
                  }
                  isSearchable={true}
                  isDisabled={!userProfile.IS_COMPANY_ADMIN}
                  onBlur={() => validation.setFieldTouched("gdpr_status", true)}
                  onChange={(e: any) => {
                    handleChange("gdpr_status", e.value);
                  }}
                  error={validation.errors.gdpr_status}
                  touched={validation.touched.gdpr_status}
                />
              </div>
            </Col>

            <Col xl={2} lg={2} md={2} sm={12}>
              <div className="mb-3">
                  <Flatpickr
                    id="gdpr_due_date"
                    name="gdpr_due_date"
                    label="GDPR Due date"
                    required={intialData.gdpr_status >0 ?true :false}
                    className="form-control d-block"
                    type="text"
                    value={intialData.gdpr_due_date}
                    placeholder="DD/MM/YYYY"
                    disabled={!userProfile.IS_COMPANY_ADMIN}
                    options={{
                      altInput: true,
                      altFormat: "d/m/Y",
                      dateFormat: "Y-m-d",
                      allowInput: true,
                      plugins: [labelPlugin()],
                      onChange: function (selectedDates, dateStr, instance) {
                        handleChange("gdpr_due_date", dateStr);
                        syncDate('gdpr', dateStr, 'due');
                      },
                      onClose: function (selectedDates, dateStr, instance) {
                        handleChange("gdpr_due_date", dateStr);
                        syncDate('gdpr', dateStr, 'due');
                      },
                      locale: { ...datePickerI18(),firstDayOfWeek: 1 }
                    }}
                  error={validation.errors.gdpr_due_date}
                  touched={validation.touched.gdpr_due_date}
                  />
              </div>
            </Col>

            <Col xl={2} lg={2} md={2} sm={12}>
              <div className="mb-3">
                  <Flatpickr
                    id="gdpr_expiry_date"
                    name="gdpr_expiry_date"
                    label="GDPR Expiry date"
                    required={intialData.gdpr_status >0 ?true :false}
                    className="form-control d-block"
                    type="text"
                    value={intialData.gdpr_expiry_date}
                    placeholder="DD/MM/YYYY"
                    disabled={!userProfile.IS_COMPANY_ADMIN}
                    options={{
                      altInput: true,
                      altFormat: "d/m/Y",
                      dateFormat: "Y-m-d",
                      allowInput: true,
                      plugins: [labelPlugin()],
                      onChange: function (selectedDates, dateStr, instance) {
                        handleChange("gdpr_expiry_date", dateStr);
                        syncDate('gdpr', dateStr, 'exp');
                      },
                      onClose: function (selectedDates, dateStr, instance) {
                        handleChange("gdpr_expiry_date", dateStr);
                        syncDate('gdpr', dateStr, 'exp');
                      },
                      locale: { ...datePickerI18(),firstDayOfWeek: 1 }
                    }}
                    error={validation.errors.gdpr_expiry_date}
                    touched={validation.touched.gdpr_expiry_date}
                  />
              </div>
            </Col>

            <Col xl={2} lg={2} md={2} sm={6}>
              <div className="mb-3">
                <Select
                  id="gdpr_notification"
                  name="gdpr_notification"
                  className="is-invalid"
                  label="GDPR notification"
                  options={yesNoList}
                  value={
                    intialData.gdpr_notification
                      ? yesNoList?.find(
                        (option: any) => option.value == intialData.gdpr_notification
                      )
                      : ""
                  }
                  isSearchable={true}
                  isDisabled={!userProfile.IS_COMPANY_ADMIN}
                  onBlur={() => validation.setFieldTouched("gdpr_notification", true)}
                  onChange={(e: any) => {
                    handleChange("gdpr_notification", e.value);
                  }}
                  error={validation.errors.gdpr_notification}
                  touched={validation.touched.gdpr_notification}
                />
              </div>
            </Col>

            <Col xl={2} lg={2} md={2} sm={6}>
              <div className="mb-3">
                <Select
                  id="gdpr_compliant_process"
                  name="gdpr_compliant_process"
                  label="GDPR Compliant process"
                  className="is-invalid"
                  options={documentStatusList}
                  isDisabled={!userProfile.IS_COMPANY_ADMIN}
                  value={
                    intialData.gdpr_compliant_process
                      ? documentStatusList?.find(
                        (option: any) => option.value == intialData.gdpr_compliant_process
                      )
                      : ""
                  }
                  isSearchable={true}
                  onBlur={() => validation.setFieldTouched("gdpr_compliant_process", true)}
                  onChange={(e: any) => {
                    handleChange("gdpr_compliant_process", e.value);
                  }}
                  error={validation.errors.gdpr_compliant_process}
                  touched={validation.touched.gdpr_compliant_process}
                />
              </div>
            </Col>
            <Col xl={2} lg={2} md={2} sm={6}>
              <div className="mb-3">
                <Select
                  id="gdpr_status_t"
                  name="gdpr_status_t"
                  className="is-invalid"
                  label="Status"
                  options={statusList}
                  value={
                    intialData.gdpr_status_t
                      ? statusList?.find(
                        (option: any) => option.value == intialData.gdpr_status_t
                      )
                      : ""
                  }
                  isSearchable={true}
                  isDisabled={!userProfile.IS_COMPANY_ADMIN}
                  onBlur={() => validation.setFieldTouched("gdpr_status_t", true)}
                  onChange={(e: any) => {
                    handleChange("gdpr_status_t", e.value);
                  }}
                  error={validation.errors.gdpr_status_t}
                  touched={validation.touched.gdpr_status_t}
                />
              </div>
            </Col>
            <Col className="col-12">
              <hr></hr>
            </Col>

            <Col xl={2} lg={2} md={2} sm={6}>
              <div className="mb-3">
                <Label className="form-label">{t("Tax on web")}</Label>
                <div className="form-check mb-2">
                  <Input
                    id="tax_on_web_status_active"
                    name="tax_on_web_status"
                    value="1"
                    className="form-check-input"
                    type="radio"
                    checked={intialData.tax_on_web_status == 1 ? true : false}
                    onChange={() => handleChange("tax_on_web_status", "1")}
                  />
                  <label className="form-check-label" htmlFor="tax_on_web_status_active">
                  {t("Activated")}
                  </label>
                </div>

                <div className="form-check mb-2">
                  <Input
                    id="tax_on_web_status_nonactive"
                    name="tax_on_web_status"
                    value="0"
                    className="form-check-input"
                    type="radio"
                    checked={intialData.tax_on_web_status == 0 ? true : false}
                    onChange={() => handleChange("tax_on_web_status", "0")}
                  />
                  <label className="form-check-label" htmlFor="tax_on_web_status_nonactive">
                  {t("Non-activated")}
                  </label>
                </div>
              </div>
            </Col>

            <Col xl={2} lg={2} md={2} sm={12}>
                <div className="mb-3 input-group is-invalid">
                  {intialData.tax_on_web_status == 1 ? (
                    <Flatpickr
                      id="tax_on_web_expiry_date"
                      name="tax_on_web_expiry_date"
                      label="Tax on web expiry date"
                      required={intialData.tax_on_web_status ==1 ? true : false}
                      className="form-control d-block"
                      type="text"
                      value={intialData.tax_on_web_expiry_date}
                      placeholder="DD/MM/YYYY"
                      options={{
                        altInput: true,
                        altFormat: "d/m/Y",
                        dateFormat: "Y-m-d H:i:S",
                        allowInput: true,
                        plugins: [labelPlugin()],
                        minDate: "today",
                        onChange: function (selectedDates, dateStr, instance) {
                          handleChange("tax_on_web_expiry_date", dateStr);
                        },
                        onClose: function (selectedDates, dateStr, instance) {
                          handleChange("tax_on_web_expiry_date", dateStr);
                        },
                        locale: { ...datePickerI18(),firstDayOfWeek: 1 }
                      }}
                      error={validation.errors.tax_on_web_expiry_date}
                      touched={validation.touched.tax_on_web_expiry_date}
                    /> ) : (
                      <Input
                        id="tax_on_web_expiry_date"
                        name="tax_on_web_expiry_date"
                        label="Tax on web expiry date"
                        className="form-control d-block"
                        type="text"
                        placeholder="DD/MM/YYYY"
                        disabled={true}
                      />
                    )}
              </div>
            </Col>

            <Col xl={2} lg={2} md={2} sm={12}>
              <div className="mb-3 input-group is-invalid">
              {intialData.tax_on_web_status == 1 ? (
                    <Flatpickr
                      id="tax_on_web_due_date"
                      name="tax_on_web_due_date"
                      className="form-control d-block"
                      type="text"
                      label="Tax on web due date"
                      required={intialData.tax_on_web_status ==1 ? true : false}
                      value={intialData.tax_on_web_due_date}
                      placeholder="DD/MM/YYYY"
                      options={{
                        altInput: true,
                        altFormat: "d/m/Y",
                        dateFormat: "Y-m-d H:i:S",
                        allowInput: true,
                        plugins: [labelPlugin()],
                        //minDate: "today",
                        onChange: function (selectedDates, dateStr, instance) {
                          handleChange("tax_on_web_due_date", dateStr);
                        },
                        onClose: function (selectedDates, dateStr, instance) {
                          handleChange("tax_on_web_due_date", dateStr);
                        },
                        locale: { ...datePickerI18(),firstDayOfWeek: 1 }
                      }}
                      error={validation.errors.tax_on_web_due_date}
                      touched={validation.touched.tax_on_web_due_date}
                    />
                    ) : (
                      <Input
                        id="tax_on_web_due_date"
                        name="tax_on_web_due_date"
                        label="Tax on web due date"
                        className="form-control d-block"
                        type="text"
                        placeholder="DD/MM/YYYY"
                        disabled={true}
                      />
                    )}
              </div>
            </Col>


            <Col xl={3} lg={3} md={3} sm={3}>
              <div className="mb-3">
                <Label className="form-label">{t("Upload tax on web document")}</Label>
                <FileUpload
                  title={t("Upload tax on web document")}
                  onClickHandler={() => handleUploadDocument()}
                  onDownload={() => handleSingleDownload()}
                  fileData={fileData}
                />
              </div>
            </Col>

            <Col className="col-12">
            </Col>

            <Col xl={1} lg={1} md={1} sm={6}>
              <div className="mb-3">
                <Label className="form-label">{t("Tax inspector proxy")}</Label>
                <div className="form-check mb-2">
                  <Input
                    id="proxy_tax_inspector_yes"
                    name="proxy_tax_inspector"
                    value="1"
                    className="form-check-input"
                    type="radio"
                    disabled={intialData.tax_on_web_status == 0 ? true : false}
                    checked={intialData.proxy_tax_inspector == 1 ? true : false}
                    onChange={() => handleChange("proxy_tax_inspector", "1")}
                  />
                  <label className="form-check-label" htmlFor="proxy_tax_inspector_yes">
                  {t("Yes")} 
                  </label>
                </div>

                <div className="form-check mb-2">
                  <Input
                    id="proxy_tax_inspector_no"
                    name="proxy_tax_inspector"
                    value="0"
                    className="form-check-input"
                    type="radio"
                    disabled={intialData.tax_on_web_status == 0 ? true : false}
                    checked={intialData.proxy_tax_inspector == 0 ? true : false}
                    onChange={() => handleChange("proxy_tax_inspector", "0")}
                  />
                  <label className="form-check-label" htmlFor="proxy_tax_inspector_no">
                  {t("No")}  
                  </label>
                </div>
              </div>
            </Col>

            <Col xl={1} lg={1} md={1} sm={6}>
              <div className="mb-3">
                <Label className="form-label"> {t("Tow proxy")}  </Label>
                <div className="form-check mb-2">
                  <Input
                    id="proxy_tow_yes"
                    name="proxy_tow"
                    value="1"
                    className="form-check-input"
                    type="radio"
                    disabled={intialData.tax_on_web_status == 0 ? true : false}
                    checked={intialData.proxy_tow == 1 ? true : false}
                    onChange={() => handleChange("proxy_tow", "1")}
                  />
                  <label className="form-check-label" htmlFor="proxy_tow_yes">
                  {t("Yes")}  
                  </label>
                </div>

                <div className="form-check mb-2">
                  <Input
                    id="proxy_tow_no"
                    name="proxy_tow"
                    value="0"
                    className="form-check-input"
                    type="radio"
                    disabled={intialData.tax_on_web_status == 0 ? true : false}
                    checked={intialData.proxy_tow == 0 ? true : false}
                    onChange={() => handleChange("proxy_tow", "0")}
                  />
                  <label className="form-check-label" htmlFor="proxy_tow_no">
                  {t("No")}   
                  </label>
                </div>
              </div>
            </Col>
            
            <Col xl={2} lg={2} md={2} sm={12}>
              <div className="mb-3">
                <DatePicker
                  id="proxy_filing_date"
                  name="proxy_filing_date"
                  label={"Filing date"}
                  placeholder="DD/MM/YYYY"
                  required={intialData.proxy_tax_inspector==1? true: false}
                  disabled={intialData.tax_on_web_status == 0 ? true : false}
                  options={{
                    altInput: true,
                    altFormat: "d/m/Y",
                    dateFormat: "Y-m-d H:i:S",
                    allowInput: true,
                    onChange: function (selectedDates, dateStr, instance) {
                      handleChange("proxy_filing_date", dateStr);
                    },
                    onClose: function (selectedDates, dateStr, instance) {
                      handleChange("proxy_filing_date", dateStr);
                    },
                    locale: { ...datePickerI18(),firstDayOfWeek: 1 },
                  }}
                  
                  error={validation.errors.proxy_filing_date}
                  touched={validation.touched.proxy_filing_date}
                />
              </div>
            </Col>

            <Col xl={2} lg={2} md={2} sm={12}>
              <div className="mb-3">
                <DatePicker
                  id="proxy_approval_date"
                  name="proxy_approval_date"
                  label={"Approval date"}
                  placeholder="DD/MM/YYYY"
                  required={intialData.proxy_tax_inspector==1? true: false}
                  value={intialData.proxy_approval_date}
                  disabled={intialData.tax_on_web_status == 0 ? true : false}
                  options={{
                    altInput: true,
                    altFormat: "d/m/Y",
                    dateFormat: "Y-m-d H:i:S",
                    allowInput: true,
                    onChange: function (selectedDates, dateStr, instance) {
                      handleChange("proxy_approval_date", dateStr);
                    },
                    onClose: function (selectedDates, dateStr, instance) {
                      handleChange("proxy_approval_date", dateStr);
                    },
                    locale: { ...datePickerI18(),firstDayOfWeek: 1 },
                  }}
                  error={validation.errors.proxy_approval_date}
                  touched={validation.touched.proxy_approval_date}
                />
              </div>
            </Col>

            <Col xl={6} lg={6} md={6} sm={12}>
              <div className="mb-3">
                <Label className="form-label">{t("Tax inspector office address")}</Label>
                <Autocomplete
                  id="proxy_inspector_address"
                  name="proxy_inspector_address"
                  className={"form-control"}
                  placeholder={t("Tax inspector office address")}
                  value={intialData.proxy_inspector_address}
                  disabled={intialData.tax_on_web_status == 0 ? true : false}
                  onChange={(e: any) => handleChange("proxy_inspector_address", e.target.value)}
                  apiKey={process.env.REACT_APP_GOOGLE_ADDRESS_API_KEY!}
                  options={{
                    types: ["geocode", "establishment"],
                  }}
                  onPlaceSelected={(data: any) => {
                    handleAddressChange(data);
                  }}
                />
              </div>
            </Col>


            <Col className="col-12">
              <hr></hr>
            </Col>

            <Col xl={2} lg={2} md={2} sm={6}>
              <div className="mb-3">
                <Label className="form-label">{t("News letter")}</Label>
                <div className="form-check mb-2">
                  <Input
                    id="news_letter_yes"
                    name="news_letter"
                    value="1"
                    className="form-check-input"
                    type="radio"
                    checked={intialData.news_letter == 1 ? true : false}
                    onChange={() => handleChange("news_letter", "1")}
                  />
                  <label className="form-check-label" htmlFor="news_letter_yes">
                  {t("Yes")} 
                  </label>
                </div>

                <div className="form-check mb-2">
                  <Input
                    id="news_letter_no"
                    name="news_letter"
                    value="0"
                    className="form-check-input"
                    type="radio"
                    checked={intialData.news_letter == 0 ? true : false}
                    onChange={() => handleChange("news_letter", "0")}
                  />
                  <label className="form-check-label" htmlFor="news_letter_no">
                  {t("No")} 
                  </label>
                </div>
              </div>
            </Col>

            <Col xl={3} lg={3} md={3} sm={12}>
              <div className="mb-3">
                <Label className="form-label">
                {t("Activate date")} 
                </Label>
                {
                  intialData.news_letter == 1 ?
                    (<div className="input-group is-invalid">
                      <Flatpickr
                        id="news_letter_activate_date"
                        name="news_letter_activate_date"
                        className="form-control d-block"
                        type="text"
                        value={intialData.news_letter_activate_date}
                        placeholder="DD/MM/YYYY"
                        options={{
                          altInput: true,
                          altFormat: "d/m/Y",
                          dateFormat: "Y-m-d H:i:S",
                          allowInput: true,
                          plugins: [labelPlugin()],

                          onChange: function (selectedDates, dateStr, instance) {
                            handleChange("news_letter_activate_date", dateStr);
                          },
                          onClose: function (selectedDates, dateStr, instance) {
                            handleChange("news_letter_activate_date", dateStr);
                          },
                          locale: { ...datePickerI18(),firstDayOfWeek: 1 }
                        }}
                      />
                      <span className="input-group-text">
                        <span className="align-middle material-symbols-outlined fw-light fs-4">calendar_today</span>
                      </span>
                    </div>)
                    : (
                      <Input
                        id="news_letter_activate_date"
                        name="news_letter_activate_date"
                        className="form-control d-block"
                        type="text"
                        placeholder="DD/MM/YYYY"
                        disabled={true}
                      />
                    )}
              </div>
            </Col>

            <Col xl={3} lg={3} md={3} sm={12}>
              <div className="mb-3">
                <Label className="form-label">
                {t("Expiry date")}
                </Label>
                {
                  intialData.news_letter == 1 ?
                    (<div className="input-group is-invalid">
                      <Flatpickr
                        id="news_letter_expiry_date"
                        name="news_letter_expiry_date"
                        className="form-control d-block"
                        type="text"
                        value={intialData.news_letter_expiry_date}
                        placeholder="DD/MM/YYYY"
                        options={{
                          altInput: true,
                          altFormat: "d/m/Y",
                          dateFormat: "Y-m-d H:i:S",
                          allowInput: true,
                          plugins: [labelPlugin()],
                          onChange: function (selectedDates, dateStr, instance) {
                            handleChange("news_letter_expiry_date", dateStr);
                          },
                          onClose: function (selectedDates, dateStr, instance) {
                            handleChange("news_letter_expiry_date", dateStr);
                          },
                          locale: { ...datePickerI18(),firstDayOfWeek: 1 }
                        }}
                      />
                      <span className="input-group-text">
                        <span className="align-middle material-symbols-outlined fw-light fs-4">calendar_today</span>
                      </span>
                    </div>)
                    : (
                      <Input
                        id="news_letter_expiry_date"
                        name="news_letter_expiry_date"
                        className="form-control d-block"
                        type="text"
                        placeholder="DD/MM/YYYY"
                        disabled={true}
                      />
                    )}
              </div>
            </Col>


            <Col className="col-12">
              <hr></hr>
            </Col>



            <DocStatus value={intialData.e_poa} status={intialData.e_poa_status} due_date={intialData.e_poa_due_date} e_date={intialData.e_poa_expiry_date} />
            <Col xl={2} lg={2} md={2} sm={6}>
              <div className="mb-3">
                <Label className="form-label">{t("e-POA")}</Label>
                <div className="form-check mb-2">
                  <Input
                    id="e_poa_yes"
                    name="e_poa"
                    value="1"
                    className="form-check-input"
                    type="radio"
                    checked={intialData.e_poa == 1 ? true : false}
                    onChange={() => handleChange("e_poa", "1")}
                  />
                  <label className="form-check-label" htmlFor="e_poa_yes">
                  {t("Yes")}
                  </label>
                </div>

                <div className="form-check mb-2">
                  <Input
                    id="e_poa_no"
                    name="e_poa"
                    value="0"
                    className="form-check-input"
                    type="radio"
                    checked={intialData.e_poa == 0 ? true : false}
                    onChange={() => handleChange("e_poa", "0")}
                  />
                  <label className="form-check-label" htmlFor="e_poa_no">
                  {t("No")}
                  </label>
                </div>
              </div>
            </Col>
            <Col xl={3} lg={3} md={3} sm={6}>
              <div className="mb-3">
                <Select
                  id="e_poa_year"
                  name="e_poa_year"
                  className="is-invalid"
                  options={yearsBackword()}
                  label="e-POA Year"
                  required={intialData.e_poa ==1 ? true : false}
                  value={
                    intialData.e_poa_year
                      ? yearsBackword().find(
                        (option: any) => option.value == intialData.e_poa_year
                      )
                      : ""
                  }
                  isSearchable={true}
                  onBlur={() => validation.setFieldTouched("e_poa_year", true)}
                  isDisabled={intialData.e_poa == 0 ? true : false}
                  onChange={(e: any) => {
                    handleChange("e_poa_year", e.value);
                  }}
                  error={validation.errors.e_poa_year}
                  touched={validation.touched.e_poa_year}
                />
              </div>
            </Col>


            <Col xl={3} lg={3} md={3} sm={12}>
              <div className="mb-3">
              {intialData.e_poa == 1 ? (
                  <Flatpickr
                    id="e_poa_due_date"
                    name="e_poa_due_date"
                    className="form-control d-block"
                    type="text"
                    label="e-POA Due date"
                    required={intialData.e_poa ==1 ? true : false}
                    value={intialData.e_poa_due_date}
                    placeholder="DD/MM/YYYY"
                    options={{
                      altInput: true,
                      altFormat: "d/m/Y",
                      dateFormat: "Y-m-d H:i:S",
                      allowInput: true,
                      plugins: [labelPlugin()],
                      onChange: function (selectedDates, dateStr, instance) {
                        handleChange("e_poa_due_date", dateStr);
                      },
                      onClose: function (selectedDates, dateStr, instance) {
                        handleChange("e_poa_due_date", dateStr);
                      },
                      locale: { ...datePickerI18(),firstDayOfWeek: 1 }
                    }}
                    error={validation.errors.e_poa_due_date}
                    touched={validation.touched.e_poa_due_date}
                  />
                  )
                  : (
                    <Input
                      id="e_poa_due_date"
                      name="e_poa_due_date"
                      label="e-POA Due date"
                      className="form-control d-block"
                      type="text"
                      placeholder="DD/MM/YYYY"
                      disabled={true}
                      />
                  )}
              </div>
            </Col>

            <Col xl={3} lg={3} md={3} sm={12}>
              <div className="mb-3">
              {intialData.e_poa == 1 ?(
                  <Flatpickr
                    id="e_poa_expiry_date"
                    name="e_poa_expiry_date"
                    className="form-control d-block"
                    type="text"
                    value={intialData.e_poa_expiry_date}
                    label="e-POA Expiry date"
                    required={intialData.e_poa ==1 ? true : false}
                    placeholder="DD/MM/YYYY"
                    options={{
                      altInput: true,
                      altFormat: "d/m/Y",
                      dateFormat: "Y-m-d H:i:S",
                      allowInput: true,
                      plugins: [labelPlugin()],
                      onChange: function (selectedDates, dateStr, instance) {
                        handleChange("e_poa_expiry_date", dateStr);
                      },
                      onClose: function (selectedDates, dateStr, instance) {
                        handleChange("e_poa_expiry_date", dateStr);
                      },
                      locale: { ...datePickerI18(),firstDayOfWeek: 1 }
                    }}
                    error={validation.errors.e_poa_expiry_date}
                    touched={validation.touched.e_poa_expiry_date}
                  />
                  )
                  : (
                    <Input
                      id="news_letter_expiry_date"
                      name="news_letter_expiry_date"
                      className="form-control d-block"
                      label="e-POA Expiry date"
                      type="text"
                      placeholder="DD/MM/YYYY"
                      disabled={true}
                    />
                  )}
                </div>
            </Col>
            <Col className="col-12">
              <hr></hr>
            </Col>
            {/* <DocStatus value={intialData.my_minfin} status={intialData.my_minfin_status} due_date={intialData.my_minfin_activate_date} e_date={intialData.my_minfin_expiry_date} />
            <Col xl={3} lg={3} md={3} sm={6}>
              <div className="mb-3">
                <Label className="form-label">
                  Access to MyMinfin
                </Label>
                <Select
                  id="my_minfin"
                  name="my_minfin"
                  className="is-invalid"
                  options={StatusMaster}
                  value={
                    intialData.my_minfin
                      ? StatusMaster.find(
                        (option: any) => option.value == intialData.my_minfin
                      )
                      : ""
                  }
                  isSearchable={true}
                  disabled={intialData.ubo == 0 ? true : false}
                  onBlur={() => validation.setFieldTouched("my_minfin", true)}
                  onChange={(e: any) => {
                    handleChange("my_minfin", e.value);
                  }}
                />
                {validation.touched.my_minfin && validation.errors.my_minfin ? (
                  <FormFeedback type="invalid">{validation.errors.my_minfin}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col xl={3} lg={3} md={3} sm={12}>
              <div className="mb-3">
                <Label className="form-label">
                  Activate date
                </Label>
                <div className="input-group is-invalid">
                  <Flatpickr
                    id="my_minfin_activate_date"
                    name="my_minfin_activate_date"
                    className="form-control d-block"
                    type="text"
                    value={intialData.my_minfin_activate_date}
                    placeholder="DD/MM/YYYY"
                    options={{
                      altInput: true,
                      altFormat: "d/m/Y",
                      dateFormat: "Y-m-d H:i:S",
                      allowInput: true,
                      plugins: [labelPlugin()],
                      onChange: function (selectedDates, dateStr, instance) {
                        handleChange("my_minfin_activate_date", dateStr);
                      },
                      onClose: function (selectedDates, dateStr, instance) {
                        handleChange("my_minfin_activate_date", dateStr);
                      },
                      locale: { ...datePickerI18(),firstDayOfWeek: 1 }
                    }}
                  />
                  <span className="input-group-text">
                    <span className="align-middle material-symbols-outlined fw-light fs-4">calendar_today</span>
                  </span>
                </div>
                {validation.touched.my_minfin_activate_date && validation.errors.my_minfin_activate_date ? (
                  <FormFeedback type="invalid">{validation.errors.my_minfin_activate_date}</FormFeedback>
                ) : null}
              </div>
            </Col>

            <Col xl={3} lg={3} md={3} sm={12}>
              <div className="mb-3">
                <Label className="form-label">
                  Expire date
                </Label>
                <div className="input-group is-invalid">
                  <Flatpickr
                    id="my_minfin_expiry_date"
                    name="my_minfin_expiry_date"
                    className="form-control d-block"
                    type="text"
                    value={intialData.my_minfin_expiry_date}
                    placeholder="DD/MM/YYYY"
                    options={{
                      altInput: true,
                      altFormat: "d/m/Y",
                      dateFormat: "Y-m-d H:i:S",
                      allowInput: true,
                      plugins: [labelPlugin()],
                      onChange: function (selectedDates, dateStr, instance) {
                        handleChange("my_minfin_expiry_date", dateStr);
                      },
                      onClose: function (selectedDates, dateStr, instance) {
                        handleChange("my_minfin_expiry_date", dateStr);
                      },
                      locale: { ...datePickerI18(),firstDayOfWeek: 1 }
                    }}
                  />
                  <span className="input-group-text">
                    <span className="align-middle material-symbols-outlined fw-light fs-4">calendar_today</span>
                  </span>
                </div>
                {validation.touched.my_minfin_expiry_date && validation.errors.my_minfin_expiry_date ? (
                  <FormFeedback type="invalid">{validation.errors.my_minfin_expiry_date}</FormFeedback>
                ) : null}
              </div>
            </Col>

            <Col xl={2} lg={2} md={2} sm={6}>
              <div className="mb-3">
                <Label className="form-label">
                  Status
                </Label>
                <Select
                  id="my_minfin_status"
                  name="my_minfin_status"
                  className="is-invalid"
                  options={StatusMaster}
                  value={
                    intialData.my_minfin_status
                      ? StatusMaster.find(
                        (option: any) => option.value == intialData.my_minfin_status
                      )
                      : ""
                  }
                  isSearchable={true}
                  onBlur={() => validation.setFieldTouched("my_minfin_status", true)}
                  onChange={(e: any) => {
                    handleChange("my_minfin_status", e.value);
                  }}
                />
                {validation.touched.my_minfin_status && validation.errors.my_minfin_status ? (
                  <FormFeedback type="invalid">{validation.errors.my_minfin_status}</FormFeedback>
                ) : null}
              </div>
            </Col>

            <Col className="col-12">
              <hr></hr>
            </Col> */}

            <Col xl={6} lg={6} md={6} sm={6}>
              <div className="mb-3">
                <Label className="form-label">
                {t("Control and Co-ordination office address")}
                </Label>
                <Select
                  id="control_and_cordi_office"
                  name="control_and_cordi_office"
                  className="is-invalid"
                  options={addressData}
                  value={
                    intialData.control_and_cordi_office
                      ? addressData.find(
                        (option: any) => option.value == intialData.control_and_cordi_office
                      )
                      : ""
                  }
                  isSearchable={true}
                  //disabled={intialData.ubo == 0 ? true : false}
                  onBlur={() => validation.setFieldTouched("control_and_cordi_office", true)}
                  onChange={(e: any) => {
                    handleChange("control_and_cordi_office", e.value);
                  }}
                />
                {validation.touched.control_and_cordi_office && validation.errors.control_and_cordi_office ? (
                  <FormFeedback type="invalid">{validation.errors.control_and_cordi_office}</FormFeedback>
                ) : null}
              </div>
            </Col>

            <Col xl={6} lg={6} md={6} sm={6}>
              <div className="mb-3">
                <Label className="form-label">
                {t("Research center by the Belgium tax authorities")}
                </Label>
                <Input
                  id="research_center"
                  name="research_center"
                  className="form-control d-block"
                  type="text"
                  placeholder="Research center by the Belgium tax authorities"
                  value={intialData.research_center}
                  onChange={(e: any) => {
                    handleChange("research_center", e.target.value);
                  }}
                />
                {validation.touched.research_center && validation.errors.research_center ? (
                  <FormFeedback type="invalid">{validation.errors.research_center}</FormFeedback>
                ) : null}
              </div>
            </Col>

            <Col className="col-12">
              <hr></hr>
            </Col>
            <Beneficiary data={intialData?.beneficiary || []} onChangeUpdate={(data: any) => 
              // handleChange("beneficiary", data)

              setIntialData((prevData: any) => ({
                ...prevData,
                beneficiary: data,
              }))
            } />
          </Row>
        </CardBody>

        {/* <CardFooter className="bg-transparent border-top">
          <Row>
            <Col className="col-12 mt-2 mb-3"></Col>
          </Row>

          <div className="d-sm-flex flex-wrap">
            <div className="d-flex gap-2"></div>
            <div className="ms-auto d-flex gap-2 justify-content-end">
              <Button color="danger" className="btn-block" onClick={() => backToCompanyDetails()} disabled={loading}>
                <span>Cancel</span>
              </Button>
              <Button color="primary" className="btn-block" type="submit" disabled={loading}>
                {loading ? (
                  <span>
                    <i className="fa-solid fa-spinner fa-spin"></i>
                    <span>&nbsp; Please wait...</span>
                  </span>
                ) : (
                  <span>Update</span>
                )}
              </Button>
            </div>
          </div>
        </CardFooter> */}

        <CardFooter className="bg-transparent border-top">
          <div className="d-sm-flex flex-wrap ">
            <div className="d-flex gap-2"></div>

            <div className="ms-auto d-flex gap-2 justify-content-end">
              <Button
                color="primary"
                className="btn-block"
                type="submit"
                onClick={() =>
                  handleChange("submit_btn_code", "update_close")
                }
                disabled={loading}
                loading={loading}
              >
                {t("Update & Close")}
              </Button>

              <Button
                color="primary"
                className="btn-block"
                type="submit"
                onClick={() =>
                  handleChange("submit_btn_code", "update")
                }
                disabled={loading}
                loading={loading}
              >
                {t("Update")}
              </Button>
              
              <Button
                color="danger"
                className="btn-block"
                type="reset"
                onClick={ handleRedirect }
                disabled={loading}
                loading={loading}
              >
                {t("Cancel")}
              </Button>
            </div>
          </div>
        </CardFooter>
      </Form>
          </Card>
        </Col>
      </Row>

    </React.Fragment>
  );
};

export default CompanyGeneral;
