import React, { useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  Nav,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Button,
  FormGroup,
} from "reactstrap";
import { toast } from "react-toastify";
import Breadcrumbs from "../../components/Layout/Breadcrumb";
import DeleteModal from "../../components/Common/DeleteModal";
import DataTable from "../../components/Common/DataTable";
import Avatar from "../../components/Avatar";
import Filters from "./Filters";
import {
  dateTimeString,
  filterCountryByLanguage,
  getDataTableQuery,
  getSelectOptions,
  getUserProfile,
  handleFilters,
} from "../../helpers/functions_helper";
import { UsersAction } from "../../store/actions";
import usersService from "../../services/users.service";
import { useTranslation } from "react-i18next";
import commonService from "../../services/common.service";
import { dateFormat } from "../../utils/formats";
import Drawer from "../../components/Drawer";
import fileDownload from "js-file-download";
import NewUser from "./NewUser";
import Input from "../../components/Form/Input";
import { getLangCode } from "../../common/staticdata";
import { renderRowHTML, yesNoTag } from "../../utils/utils";

const AllUsers = (props: any) => {
  const { t } = useTranslation();
  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "#",
      label: t("Users"),
      active: false,
    },
  ];
  const userProfile = getUserProfile("all-users");
  const savedfilters = handleFilters("GET");
  let { type } = useParams();
  if (!type) {
    type = props?.type;
  }
  const company_id = props.company || null;
  const list_type = props.list_type || null;
  const toastId = React.useRef(null) as any;
  const dispatch = useDispatch();
  document.title = `${t("Users - List view")} (${type === "archive" ? t("archive") : t("all")}) | ${process.env.REACT_APP_NAME}`;

  const [loader, setLoader] = useState(true);
  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingExportCSV, setLoadingExportCSV] = useState(false);
  const [usersListData, setUsersListData] = useState([]);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [openUserDrawer, setOpenUserDrawer] = useState<boolean>(false);

  let {
    datatableData,
    getUsersListErrorMsg,
    createUserSuccessMsg,
    updateUserSuccessMsg,
    deleteUserSuccessMsg,
    deleteUserErrorMsg,
    getUserErrorMsg,
    loading,
  } = useSelector((state: any) => ({
    datatableData: state.Users.GET_USERS_LIST_SUCCESS,
    getUsersListErrorMsg: state.Users.GET_USERS_LIST_ERROR.message,

    createUserSuccessMsg: state.Users.CREATE_USER_SUCCESS.message,

    updateUserSuccessMsg: state.Users.UPDATE_USER_SUCCESS.message,

    deleteUserSuccessMsg: state.Users.DELETE_USER_SUCCESS.message,
    deleteUserErrorMsg: state.Users.DELETE_USER_ERROR.message,

    getUserErrorMsg: state.Users.GET_USER_ERROR.message,

    loading: state.Users.loading,
  }));

  // DataTable ----------------------------------------------

  const [countryOptions, setCountryOptions] = useState<Array<any>>([]);
  useEffect(() => {
    getSelectOptions("tbl_master_country", "name", "", [
      "is_europian_country",
      "name",
      "name_french",
      "name_dutch",
    ]).then(function (options) {
      let countryOptionsList = filterCountryByLanguage(options);
      setCountryOptions(countryOptionsList);
    });
  }, []);

  const [lng, setLng] = useState(localStorage.getItem("I18N_LANGUAGE"));
  useEffect(()=>{
    setLng(localStorage.getItem("I18N_LANGUAGE"));
    setLoadingListData(true);
    setUsersListData([]);
  },[localStorage.getItem("I18N_LANGUAGE")])


  const columns = [
    {
      dataField: "DT_RowIndex",
      text: `${t('Sr. No.')}`,
      sort: false,
      search: false,
      classes: "fix-col-left",
      headerClasses: "fix-col-left",
      headerStyle: { width: "155px" },
      style: { width: "155px" },
    },
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: true,
    //   search: true,
    // },
    {
      dataField: "user_logo_data",
      text: "",
      sort: false,
      search: false,
      formatter: (rowContent: any, row: any): any => {
        return (
          // row.user_logo_data && (
          //   <img
          //     src={row.user_logo_data}
          //     className="rounded-circle avatar-xs border"
          //   />
          // )
          <Avatar
            id={row.id}
            name={`${row.first_name} ${row.last_name}`}
            src={row.user_logo_data}
          />
        );
      },
    },
    {
      dataField: "custome_code",
      text: `${t('Account ID')}`,
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return (
          <>
            {userProfile.CAN_EDIT || userProfile.USER_ID === row.id ? (
              <Link
                className="text-decoration-underline"
                to={`/users/user-details/${row.id}/${row.user_id}`}
              >
                {row.custome_code}
              </Link>
            ) : (
              row.custome_code
            )}
          </>
        );
      },
    },
    {
      dataField: "company_name",
      text: `${t('Client')}`,
      sort: true,
      search: true,
      hidden: userProfile.IS_INDIVIDUAL,
      formatter: (rowContent: any, row: any): any => {
        return (
          <>
            {row.company_name}

            <div className="d-flex flex-wrap gap-2">
              {parseInt(row?.contact_person) === row.id ? (
                <span
                  className="badge_custome  badge-soft-info badge bg-info rounded-pill"
                  title="Contact person (Company)"
                >
                  CMP
                </span>
              ) : null}
              {parseInt(row?.invoice_contact_person) === row.id ? (
                <span
                  className="badge_custome  badge-soft-info badge bg-info rounded-pill"
                  title="Contact person (Invoice)"
                >
                  INV
                </span>
              ) : null}
              {parseInt(row?.hr_contact_person) === row.id ? (
                <span
                  className="badge_custome  badge-soft-info badge bg-info rounded-pill"
                  title="Contact person (HR)"
                >
                  HR
                </span>
              ) : null}
              {parseInt(row?.gen_contact_person) === row.id ? (
                <span
                  className="badge_custome  badge-soft-info badge bg-info rounded-pill"
                  title="Contact person (General)"
                >
                  GEN
                </span>
              ) : null}
            </div>
          </>
        );
      },
    },
    {
      dataField: "honorific",
      text: t("Title"),
      sort: true,
      search: true,
    },
    {
      dataField: "first_name",
      text: `${t('First name')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "last_name",
      text: `${t('Last name')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "belgium_start_date",
      text: `${t('Start date in Belgium')}`,
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return row.belgium_start_date && dateFormat(row.belgium_start_date);
      },
    },
    {
      dataField: "belgium_leave_date",
      text: `${t('End date in Belgium')}`,
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return row.belgium_leave_date && dateFormat(row.belgium_leave_date);
      },
    },
    {
      dataField: "title",
      text: `${t('Job title')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "gender_value",
      text: `${t('Gender')}`,
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return t(row.gender_value);
      },
    },
    {
      dataField: "language_value",
      text: `${t('Language')}`,
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return t(row.language_value);
      },
    },
    {
      dataField: "email",
      text: `${t('Email')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "email_alt",
      text: `${t('Alternative e-mail')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "email_notification_html",
      text: `${t('Email notification')}`,
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return renderRowHTML(
          yesNoTag(row?.email_notification, row?.email_notification === 1 ? t("Yes") : t("No")),
          null
        );
      },
    },
    {
      dataField: "mobile",
      text: `${t('Phone number')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "mobile_alt",
      text: `${t('Alternative phone number')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "country_value",
      text: `${t('Country')}`,
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        const selectedCountry = countryOptions.find(
          (option: any) => option.value === row.country
        );
        const label = (lng == "English")? "label" : ((lng=="French")? "name_french": "name_dutch");
        return selectedCountry ? selectedCountry[label] : '';
      },
    },
    {
      dataField: "origin_value",
      text: `${t('Country of origin')}`,
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        const selectedCountry = countryOptions.find(
          (option: any) => option.value === row.origin
        );
        const label = (lng == "English")? "label" : ((lng=="French")? "name_french": "name_dutch");
        return selectedCountry ? selectedCountry[label] : '';
      },
    },
    {
      dataField: "nationality_value",
      text: `${t('Nationality')}`,
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        const selectedCountry = countryOptions.find(
          (option: any) => option.value === row.nationality
        );
        const label = (lng == "English")? "label" : ((lng=="French")? "name_french": "name_dutch");
        return selectedCountry ? selectedCountry[label] : '';
      },
    },
    {
      dataField: "user_type_value_ic",
      text: `${t('User type')}`,
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return t(row.user_type_value_ic);
      },
    },
    {
      dataField: "user_type_value",
      text: `${t('Type')}`,
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return t(row.user_type_value);
      },
    },
    {
      dataField: "user_function_value",
      text: `${t('Function')}`,
      sort: true,
      search: true,
      hidden: userProfile.IS_INDIVIDUAL,
    },
    {
      dataField: "user_status_value",
      text: `${t('Status')}`,
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        if (row.status === 1) {
          return (
            <span className="badge_custome badge-soft-primary badge bg-primary rounded-pill">
              {t(row.user_status_value)}
            </span>
          );
        } else if (row.status === 2) {
          return (
            <span className="badge_custome badge-soft-danger badge bg-danger rounded-pill">
              {t(row.user_status_value)}
            </span>
          );
        }
      },
    },
    {
      dataField: "tax_regime_html",
      text: `${t('INPAT')}`,
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return renderRowHTML(
          yesNoTag(row?.tax_regime, row?.tax_regime === 1 ? t("Yes") : t("No")),
          null
        );
      },
    },
    {
      dataField: "tax_regime_start_date",
      text: `${t('INPAT start date')}`,
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return row.tax_regime_start_date && dateFormat(row.tax_regime_start_date);
      },
    },
    {
      dataField: "tax_regime_end_date_extension",
      text: `${t('INPAT end date')}`,
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return row.tax_regime_end_date_extension && dateFormat(row.tax_regime_end_date_extension);
      },
    },
    {
      dataField: "access_role_value",
      text: `${t('Access role')}`,
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return t(row.access_role_value);
      },
    },
    {
      dataField: "start_date",
      text: `${t('Start date')}`,
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return row.start_date && dateFormat(row.start_date);
      },
    },
    {
      dataField: "end_date",
      text: `${t('End date')}`,
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return row.end_date && dateFormat(row.end_date);
      },
    },
    {
      dataField: "is_exported_to_csf",
      text: `${t('Exported to CSF')}`,
      sort: true,
      search: false,
      hidden: !(userProfile.IS_PARTNER),
      formatter: (rowContent: any, row: any): any => {
        return (
          <>
            <FormGroup switch inline className="form-check-success">
              <Input
                type="switch"
                dis
                checked={row?.is_exported_to_csf}
                // onChange={() => handleAct(row.id)}
                label={row?.is_exported_to_csf ? t('Yes') : t('No')}
              />
            </FormGroup>
          </>
        );
      },
    },

    // -------------- CREATED - UPDATED
    {
      dataField: "created_by_value",
      text: `${t('Created by')}`,
      sort: true,
      search: true,
      hidden: !userProfile.IS_INTERNAL,
    },
    {
      dataField: "created_at",
      text: `${t('Created on')}`,
      sort: true,
      search: true,
      hidden: !userProfile.IS_INTERNAL,
      formatter: (rowContent: any, row: any): any => {
        return dateFormat(
          row?.created_at,
          {
            dateStyle: "long",
            timeStyle: "short",
            hour12: true,
          },
          getLangCode(localStorage.getItem("I18N_LANGUAGE"))
        );
      },
    },
    {
      dataField: "updated_by_value",
      text: `${t('Updated by')}`,
      sort: true,
      search: true,
      hidden: !userProfile.IS_INTERNAL,
    },
    {
      dataField: "updated_at",
      text: `${t('Updated on')}`,
      sort: true,
      search: true,
      hidden: !userProfile.IS_INTERNAL,
      formatter: (rowContent: any, row: any): any => {
        return dateFormat(
          row?.updated_at,
          {
            dateStyle: "long",
            timeStyle: "short",
            hour12: true,
          },
          getLangCode(localStorage.getItem("I18N_LANGUAGE"))
        );
      },
    },
    // -------------- CREATED - UPDATED

    {
      dataField: "action",
      text: `${t('Action')}`,
      sort: false,
      search: false,
      formatter: (rowContent: any, row: any): any => {
        return (
          <div className="d-flex gap-2 text-end">
            {userProfile.CAN_EDIT &&
              (
                userProfile.USER_ID === parseInt(row?.created_by) ||
                userProfile.USER_ID === row.id
              ) ||
              userProfile.CAN_EDIT_ALL ? (
              <Link
                className="btn btn-pill btn-outline-primary btn-sm"
                to={"/users/user-details/" + row.id + "/" + row.user_id}
              >
                <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                  edit
                </span>
                {t('Edit')}
              </Link>
            ) : (
              <Link
                className="btn btn-pill btn-outline-primary btn-sm"
                to={"/users/user-details/" + row.id + "/" + row.user_id}
              >
                <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                  visibility
                </span>
                {t('View')}
              </Link>
            )}

            {/* {userProfile.CAN_DELETE && userProfile.USER_ID !== row.id ? (
              <Link
                className="btn btn-pill btn-outline-danger btn-sm"
                to="#"
                onClick={() => onClickDelete(row)}
              >
                <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                  delete
                </span>
                Delete
              </Link>
            ) : null} */}

            {userProfile.IS_INTERNAL && userProfile.CAN_CREATE ? (
              <Link
                className="btn btn-pill btn-outline-primary btn-sm"
                to={"/users/new-user/" + row.id + "/" + row.user_id}
              >
                <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                  content_copy
                </span>
                {t('Duplicate')}
              </Link>
            ) : null}

            {userProfile.IS_INTERNAL &&
              userProfile.CAN_DELETE_ALL &&
              userProfile.USER_ID !== row.id ? (
              <Button
                id={`btnArchive_${row.id}`}
                className="btn btn-pill btn-outline-danger btn-sm"
                color="danger"
                outline={true}
                onClick={() => onClickArchive(row)}
              >
                {row.is_archive === 0 ? (
                  <>
                    <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                      archive
                    </span>
                    {t('Archive')}
                  </>
                ) : (
                  <>
                    <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                      unarchive
                    </span>
                    {t('Unarchive')}
                  </>
                )}
              </Button>
            ) : null}
          </div>
        );
      },
    },
  ];

  const [loadingListData, setLoadingListData] = useState(true);
  const [datatableOptions, setDatatableOptions] = useState(
    savedfilters && savedfilters?.datatableOptions
      ? (savedfilters?.datatableOptions as any)
      : {
        draw: 1,
        page: 1,
        recordsTotal: 0,
        recordsFiltered: 0,
        sizePerPage: 20,
        searchText: "",
        sortField: "last_name",
        sortOrder: "asc",
      }
  );
  const [filters, setFilters] = useState(
    savedfilters && savedfilters?.filters && !company_id
      ? (savedfilters?.filters as any)
      : {
        is_filtered: false,
        company_id: company_id,
        list_type: list_type,
        user_id: "",
        tax_regime: "",
        tax_start_date: "",
        tax_end_date: "",
        start_date: "",
        end_date: "",
        date_type: "",
        status: "",
        active: "",
        single_permit: null,
        is_archive: type === "archive" ? 1 : 0,
        select_attribute: "",
        tax_return_type: "",
        active_status: "",
        week: "",
        quarter: "",
        month: "",
        year: "",
        to_date: "",
        from_date: "",
        client_status: "",
        TVW_Nr: null,
        vatcat: "",
        to_apply_vat: "",
        type: "",
      }
  );

  const onTableChange = (type: any, newState: any) => {
    let draw = datatableOptions.draw + 1;
    let page = 1;
    if (type === "search") {
      page = 1;
    } else {
      page = newState.page;
    }
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: draw,
      page: page,
      sizePerPage: newState.sizePerPage,
      searchText: newState.searchText,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
    };
    setDatatableOptions(updatedDatatableOptions);
    setUsersListData([]);
    setLoadingListData(true);
  };

  useEffect(() => {
    setUsersListData([]);
    setLoadingListData(true);
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  const clearFilter = () => {
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: 1,
      page: 1,
      sizePerPage: 20,
      searchText: "",
      sortField: "last_name",
      sortOrder: "asc",
    };
    setDatatableOptions(updatedDatatableOptions);

    const filter = {
      ...filters,
      is_filtered: false,
      company_id: company_id,
      list_type: list_type,
      user_id: "",
      tax_regime: "",
      tax_start_date: "",
      tax_end_date: "",
      start_date: "",
      end_date: "",
      date_type: "",
      status: "",
      active: "",
      single_permit: null,
      select_attribute: "",
      tax_return_type: "",
      active_status: "",
      is_archive: type === "archive" ? 1 : 0,
      week: "",
      quarter: "",
      month: "",
      year: "",
      to_date: "",
      from_date: "",
      client_status: "",
      TVW_Nr: null,
      vatcat: "",
      to_apply_vat: "",
      type: "",
    };
    setFilters(filter);

    setUsersListData([]);
    setLoadingListData(true);
  };

  const loadingDatatable = async () => {
    try {
      const res = (await usersService.getUsersList({
        ...getDataTableQuery(datatableOptions, columns),
        filters,
      })) as any;

      if (res.data.status === "success") {
        setUsersListData(res.data.data);

        const updatedDatatableOptions = {
          ...datatableOptions,
          recordsTotal: res.data.recordsTotal,
          recordsFiltered: res.data.recordsFiltered,
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingListData(false);
      } else {
        setUsersListData([]);

        const updatedDatatableOptions = {
          ...datatableOptions,
          recordsTotal: 0,
          recordsFiltered: 0,
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingListData(false);
      }
    } catch (err) {
      setUsersListData([]);

      const updatedDatatableOptions = {
        ...datatableOptions,
        recordsTotal: 0,
        recordsFiltered: 0,
      };
      setDatatableOptions(updatedDatatableOptions);
      setLoadingListData(false);
    }
  };

  const exportAll = async () => {
    try {
      setLoadingExport(true);
      const res = (await usersService.getUsersExport({
        ...getDataTableQuery(datatableOptions, columns),
        filters,
      })) as any;

      if (res) {
        fileDownload(res.data, `users-${dateTimeString()}-export.xlsx`);
        setLoadingExport(false);
      }
    } catch (err) {
      setLoadingExport(false);
    }
  };
  const exportCSV = async () => {
    try {
      setLoadingExportCSV(true);
      const res = (await usersService.getUsersExportCSV({
        ...getDataTableQuery(datatableOptions, columns),
        filters,
      })) as any;

      if (res) {
        fileDownload(res.data, `users-${dateTimeString()}-export.csv`);
        setLoadingExportCSV(false);
      }
    } catch (err) {
      setLoadingExportCSV(false);
    }
  };

  useEffect(() => {
    if (loadingListData === true) {
      loadingDatatable();
    }
  }, [loadingListData]);

  // const handleClear = () => {
  //   setFilters({
  //     ...filters,
  //     is_filtered: false,
  //     company_id: company_id,
  //     list_type: list_type,
  //     user_id: "",
  //     tax_regime: "",
  //     tax_start_date: "",
  //     tax_end_date: "",
  //     start_date: "",
  //     end_date: "",
  //     date_type: "",
  //     status: "",
  //     active: "",
  //     single_permit: null,
  //     select_attribute: "",
  //     is_archive: type === "archive" ? 1 : 0,
  //     week: "",
  //     quarter: "",
  //     month: "",
  //     year: "",
  //     to_date: "",
  //     from_date: "",
  //     client_status: "",
  //     TVW_Nr: null,
  //     vatcat: "",
  //     to_apply_vat: "",
  //   });
  // };

  // const [newDBColumns, setNewDBColumns] = useState([{}]);
  // useEffect(() => {
  //   var newData: any = [];
  //   columns.map((item: any, index: number) => {
  //     newData.push(item);
  //   });
  //   setNewDBColumns(newData);
  // }, [USER_ID]);
  // Datatable -------------------------------------------------

  const onClickArchive = async (row: any) => {
    toastId.current = toast(t("Updating archive status..."), {
      autoClose: false,
      isLoading: true,
    });

    const res = (await commonService.handleArchive({
      id: row.id,
      table_name: "tbl_master_users",
      field_name: "is_archive",
      is_archive: row.is_archive,
    })) as any;

    if (res.data.status === "success") {
      toast.update(toastId.current, {
        type: toast.TYPE.SUCCESS,
        render: res.data.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });

      dispatch(UsersAction.getUserSuccess(""));
      setUsersListData([]);
      setLoadingListData(true);
    } else {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: res.data.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
  };

  // const [user, setUser] = useState({ id: "", user_id: "" });
  // const [recordName, setRecordName] = useState("");
  // const [deleteModal, setDeleteModal] = useState(false);
  // const onClickDelete = (row: any) => {
  //   const deleteUserData = {
  //     ...user,
  //     id: row.id,
  //     user_id: row.user_id,
  //   };
  //   setUser(deleteUserData);
  //   setRecordName(row.first_name + " " + row.last_name);
  //   setDeleteModal(true);
  // };
  // const handleDeleteUser = () => {
  //   toastId.current = toast("Deleting user...", {
  //     autoClose: false,
  //     isLoading: true,
  //   });

  //   dispatch(UsersAction.deleteUser(user, props.history));
  //   setDeleteModal(false);
  // };

  //passing uo to parent for email
  useEffect(() => {
    if (props.setFilterData) {
      const filter_data = { ...filters };
      if (filters.select_attribute) {
        const title = filters?.select_attribute;
      }
      props.setFilterData(filters);
    }
  }, [filters]);

  // Delete on messages -------------------------------------------------
  useEffect(() => {
    if (getUsersListErrorMsg) {
      dispatch(UsersAction.getUsersListError(""));

      toast(getUsersListErrorMsg, {
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        delay: 500,
      });
    }
  }, [getUsersListErrorMsg]);

  useEffect(() => {
    if (createUserSuccessMsg) {
      dispatch(UsersAction.createUserSuccess(""));

      toast.update(toastId.current, {
        type: toast.TYPE.SUCCESS,
        render: createUserSuccessMsg,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
  }, [createUserSuccessMsg]);

  useEffect(() => {
    if (updateUserSuccessMsg) {
      dispatch(UsersAction.updateUserSuccess(""));

      toast.update(toastId.current, {
        type: toast.TYPE.SUCCESS,
        render: updateUserSuccessMsg,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
  }, [updateUserSuccessMsg]);

  useEffect(() => {
    if (deleteUserSuccessMsg) {
      setUsersListData([]);
      setLoadingListData(true);
      dispatch(UsersAction.deleteUserSuccess(""));

      toast.update(toastId.current, {
        type: toast.TYPE.SUCCESS,
        render: deleteUserSuccessMsg,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
  }, [deleteUserSuccessMsg]);

  useEffect(() => {
    if (deleteUserErrorMsg) {
      dispatch(UsersAction.deleteUserError(""));

      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: deleteUserErrorMsg,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
  }, [deleteUserErrorMsg]);

  useEffect(() => {
    if (getUserErrorMsg) {
      dispatch(UsersAction.getUserError(""));

      toast(getUserErrorMsg, {
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        delay: 500,
      });
    }
  }, [getUserErrorMsg]);
  // Delete on messages -------------------------------------------------

  // Filters -------------------------------------------------
  const handleFiltersChange = (obj: any) => {
    setFilters((state: any) => ({ ...state, ...obj }));
  };

  const handleApplyFilters = () => {
    setUsersListData([]);
    setLoadingListData(true);
    setOpenFilter(false);
  };
  // Filters -------------------------------------------------

  const createUser = () => {
    setOpenUserDrawer(true);
  };

  const handleUserSave = (user: any) => {
    clearFilter();
    setOpenUserDrawer(false);
  };

  return (
    <React.Fragment>
      {/* {user && (
        <DeleteModal
          recordName={recordName}
          modalTitle="User"
          show={deleteModal}
          onDeleteClick={handleDeleteUser}
          onCloseClick={() => setDeleteModal(false)}
          loading={loading}
        />
      )} */}

      <div className={company_id ? "" : "page-content"}>
        {!company_id ? (
          <Container fluid>
            <Breadcrumbs
              breadcrumbTitle={t("Users")}
              breadcrumbItems={breadcrumbItems}
              endAdornment={
                <div className="d-flex gap-2 text-end">
                  {userProfile?.IS_INTERNAL && (
                    <Link to="/users/all-users" className={`btn btn-info btn-label btn-sm`}>
                      <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                        list_alt
                      </span>
                      {t('List view')}
                    </Link>
                  )}

                  {userProfile?.IS_INTERNAL && userProfile?.CAN_VIEW_ALL && (
                    <Link
                      to="/users/graph"
                      className={`btn btn-outline-secondary btn-label btn-sm`}
                    >
                      <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                        insert_chart
                      </span>
                      {t("Graphical view")}
                    </Link>
                  )}

                  {userProfile.CAN_CREATE ? (
                    <Link to="/users/new-user" className="btn btn-primary btn-label btn-sm">
                      <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                        add
                      </span>{" "}
                      {t("Create user")}
                    </Link>
                  ) : null}
                </div>
              }
            />

            <Row>
              <Col xl="12">
                <Card>
                  {userProfile.IS_INTERNAL ? (
                    <CardHeader className="bg-transparent border-bottom pb-0">
                      <div className="d-sm-flex flex-wrap">
                        <Nav tabs className="nav-tabs-custom">
                          <NavItem>
                            <Link
                              style={{ cursor: "pointer" }}
                              to="/users/all-users"
                              className={!type ? "nav-link active" : "nav-link"}
                            >
                              <span className="d-block d-sm-none">
                                <i className="fas fa-home"></i>
                              </span>
                              <span className="d-none d-sm-block">{t('All Users')}</span>
                            </Link>
                          </NavItem>
                          <NavItem>
                            <Link
                              style={{ cursor: "pointer" }}
                              to="/users/all-users/archive"
                              className={type === "archive" ? "nav-link active" : "nav-link"}
                            >
                              <span className="d-block d-sm-none">
                                <i className="fas fa-home"></i>
                              </span>
                              <span className="d-none d-sm-block">{t('Archive')}</span>
                            </Link>
                          </NavItem>
                        </Nav>
                      </div>
                    </CardHeader>
                  ) : null}

                  <CardBody>
                    <DataTable
                      columns={columns}
                      products={usersListData}
                      datatableOptions={datatableOptions}
                      filters={filters}
                      onTableChange={onTableChange}
                      loadingListData={loadingListData}
                      clearFilter={clearFilter}
                      settingTableName="users_table"
                      slug="all-users"
                      customButtons={
                        userProfile.IS_INTERNAL ? (
                          <>
                            <Button
                              title={t("Filter")}
                              outline={filters.is_filtered === true ? false : true}
                              color={filters.is_filtered === true ? "secondary" : "light"}
                              onClick={() => setOpenFilter((state) => !state)}
                            >
                              {filters.is_filtered === false ? (
                                <span className="align-middle material-symbols-outlined fw-light fs-4">
                                  filter_alt
                                </span>
                              ) : (
                                <span className="align-middle material-symbols-outlined fw-light fs-4">
                                  filter_alt_off
                                </span>
                              )}
                            </Button>

                            {userProfile.CAN_EXPORT ? (
                              <>
                                <Button
                                  title={t("Export all")}
                                  outline={true}
                                  color={"light"}
                                  onClick={() => {
                                    exportAll();
                                  }}
                                  disabled={loadingExport}
                                >
                                  {loadingExport ? (
                                    <span className="align-middle material-symbols-outlined fw-light fs-4 fa-spin">
                                      hourglass_empty
                                    </span>
                                  ) : (
                                    <span className="align-middle material-symbols-outlined fw-light fs-4">
                                      export_notes
                                    </span>
                                  )}
                                </Button>

                                {userProfile.CAN_VIEW_ALL ? (
                                  <Button
                                    title={`${t("Export all")} - CSV`}
                                    outline={true}
                                    color={"light"}
                                    onClick={() => {
                                      exportCSV();
                                    }}
                                    disabled={loadingExportCSV}
                                  >
                                    {loadingExportCSV ? (
                                      <span className="align-middle material-symbols-outlined fw-light fs-4 fa-spin">
                                        hourglass_empty
                                      </span>
                                    ) : (
                                      <span className="align-middle material-symbols-outlined fw-light fs-4">
                                        csv
                                      </span>
                                    )}
                                  </Button>
                                ) : null}
                              </>
                            ) : null}
                          </>
                        ) : null
                      }
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <DataTable
            columns={columns}
            products={usersListData}
            datatableOptions={datatableOptions}
            filters={filters}
            onTableChange={onTableChange}
            loadingListData={loadingListData}
            clearFilter={clearFilter}
            settingTableName="users_table"
            slug="all-users"
            mainButtons={
              userProfile.CAN_CREATE ? (
                <Button color="primary" className="btn-label me-2" onClick={() => createUser()}>
                  <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                    add
                  </span>
                  {t("Create user")}
                </Button>
              ) : null
            }
            customButtons={
              userProfile.IS_INTERNAL ? (
                <Button outline color="light" onClick={() => setOpenFilter((state) => !state)}>
                  <span className="align-middle material-symbols-outlined fw-light fs-4">
                    filter_alt
                  </span>
                </Button>
              ) : null
            }
          />
        )}
      </div>
      <Drawer
        title={t("Filters")}
        open={openFilter}
        onClose={() => setOpenFilter((state) => !state)}
        onReset={clearFilter}
        footer={
          <Button color="primary" style={{ margin: "16px" }} onClick={handleApplyFilters}>
            {t("Apply")}
          </Button>
        }
      >
        <Filters
          filters={filters}
          onApply={() => { }}
          onClear={() => { }}
          onChange={(obj: any) => handleFiltersChange(obj)}
          company_id={company_id}
        />
      </Drawer>

      <Drawer
        title={t("Create a User")}
        open={openUserDrawer}
        onClose={() => setOpenUserDrawer(false)}
        fullWidth
      >
        <NewUser company={company_id} inTab={true} onSave={(data: any) => handleUserSave(data)} />
      </Drawer>
    </React.Fragment>
  );
};

export default AllUsers;
