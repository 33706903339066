import React, { memo, useState } from "react"
import {
  Button, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown,
} from "reactstrap"
import { map } from 'lodash';
import { getUserProfile } from "../../helpers/functions_helper";
const EmailToolbar = (props: any) => {
  const userObj = getUserProfile('email');
  const { t,handleSelectAll, searchEmail, composeMail, refreshMail, selectedMail, handleDeleteMail, folderList, handleReadUnread, handleArchive, handleCreateTask, handleMoveMessage, layoutFilter } = props;
  return (
    <React.Fragment>
      <div className="row">
        <div className="col">
          <div className="row align-items-center shadow rounded">
            <div className="col-3">
              <div className="search-box">
                <div className="position-relative">
                  <input type="text" className="form-control bg-light border-light rounded" onChange={(e) => searchEmail(e.target.value)} placeholder={t("Search")} spellCheck="false" data-ms-editor="true" />
                  <i className="material-symbols-outlined search-icon">search</i>
                </div>
              </div>
            </div>
            <div className="col-6">

              <>
                <Button
                  color="primary"
                  outline
                  size="sm"
                  className="me-2"
                  onClick={() => handleSelectAll()}
                >
                  <i className="fa fa-list-check" aria-hidden="true"></i>{' '}
                </Button>
                {userObj.CAN_DELETE ? <Button
                  color="primary"
                  outline
                  size="sm"
                  className="me-2"
                  onClick={() => handleDeleteMail()}
                  disabled={selectedMail === 0}
                >
                  <i className="fa fa-trash" aria-hidden="true"></i>{' '}{t("Delete")}
                </Button> : null}

                {userObj.CAN_CREATE ? <><ButtonGroup className="my-2" size="sm" color="primary" >
                  <ButtonGroup size="sm" color="primary" >
                    <UncontrolledDropdown size="sm" >
                      <DropdownToggle caret color="primary" disabled={selectedMail === 0}
                        outline>
                        <i className="fa fa-arrow-right" aria-hidden="true"></i>{' '}{t("Move to")}
                      </DropdownToggle>
                      <DropdownMenu >
                        {folderList?.value && map(folderList?.value, (item, i: number) =>
                          <DropdownItem onClick={() => handleMoveMessage(item)} key={i}>
                            {item.displayName}
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </ButtonGroup>
                </ButtonGroup>
                  <Button
                    color="primary"
                    outline
                    size="sm"
                    className="me-2 ms-2"
                    onClick={() => handleReadUnread()}
                    disabled={selectedMail === 0}
                  >
                    <i className="fa fa-envelope-open-o" aria-hidden="true"></i>
                    {' '}{t("Read / Unread")}
                  </Button></>
                  : null}
              </>

              {userObj.CAN_CREATE ? <Button
                color="primary"
                outline
                size="sm"
                className="me-2 ms-2"
                onClick={() => handleCreateTask()}
                disabled={selectedMail === 0 || selectedMail > 1}
              >
                <i className="fa fa-check" aria-hidden="true"></i>
                {' '}{t("Create Task")}
              </Button> : null}
            </div>
            <div className="col-3 text-end">
              {userObj.CAN_CREATE ? <Button
                color="primary"
                outline
                size="sm"
                onClick={() => composeMail()}
                style={{ marginRight: '5px' }}
              >
                <i className="fa-regular fa-envelope ml-1"></i>{" "}
                {t("New mail")}
              </Button> : null}
              <Button
                color="primary"
                outline
                size="sm"
                className="me-2"
                onClick={() => refreshMail()}
              >
                <i className="fa fa-refresh" aria-hidden="true"></i>
              </Button>
              <Button
                color="primary"
                outline
                size="sm"
                className="me-2"
                onClick={() => layoutFilter()}
              >
                <i className="fa fa-list" aria-hidden="true"></i>
              </Button>
            </div>
          </div>
        </div>
        {"   "}
      </div>
    </React.Fragment>
  )
}


export default memo(EmailToolbar)
