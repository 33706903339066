import React, { useEffect, useState } from 'react'
import { Input, Modal, ModalBody, ModalFooter, Row, Table } from 'reactstrap';
//@ts-ignore
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Filters from '../../pages/Masters/QuestionnaireQuestions/Filters';
import { getDependentMultipleWhereSelectOptions, getDependentSelectOptionsWithOrder } from '../../helpers/functions_helper';
import datatableSettingsService from '../../services/datatable.settings.service';
import { toast } from "react-toastify";
const DataTableOrderSettingsModal = ({ show,
    columns,
    settingTableName,
    loadingListData,
    dependentField,
    onCloseClick,
    setColumnChange
}: any) => {
    const [tableData, setTableData] = useState<any>([]);
    const [originalData, setOriginalData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const onDragEnd = (result: any) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        reorder(result.source.index, result.destination.index);
    }

    const [filters, setFilters] = useState({
        is_filtered: false,
        user_type: 0,
        year: ((new Date()).getFullYear()),
        questionnaire_type_id: 0,
        tab_id: 0,
    });


    function renderRowHTML(rowContent: any) {
        return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
    }

    const reorder = (startIndex: any, endIndex: any) => {
        const removed = tableData.filter((dt: any) => dt.order == startIndex);
        const removeddata = tableData.filter((dt: any) => dt.order != startIndex);
        const insertedData: any = [];
        removeddata.forEach((element: any) => {
            if (startIndex < endIndex) {
                insertedData.push(element);
                if (element.order == endIndex) {
                    insertedData.push(removed[0]);
                }
            } else {
                if (element.order == endIndex) {
                    insertedData.push(removed[0]);
                }

                insertedData.push(element);
            }

        });
        setTableData(insertedData);
    };

    const getListStyle = (isDraggingOver: boolean) => ({
        background: isDraggingOver ? "lightblue" : "lightgrey",
    });

    const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
        userSelect: "none",
        background: isDragging ? "lightblue" : "grey",
        width: '100%',
        ...draggableStyle
    });

    const handleFiltersChange = (obj: any) => {
        setFilters((state) => ({ ...state, ...obj }));
    };

    function sortFunc(a: any, b: any) {
        return tableData.indexOf(a.order) - tableData.indexOf(b.order);
    }


    useEffect(() => {
        if (filters.questionnaire_type_id && settingTableName=="tbl_master_questionnaire_tab") {
            setTableData([]);
            getDependentSelectOptionsWithOrder(
                "tbl_master_questionnaire_tab",
                "questionnaire_tab_value",
                "questionnaire_type_id",
                filters.questionnaire_type_id,
                'order',
                ['order'],
            ).then(function (options) {
                const sortCol = options.sort(sortFunc);
                setTableData(sortCol);
                setOriginalData(sortCol);
            });
        }

        if (filters.tab_id) {
            setTableData([]);
            getDependentSelectOptionsWithOrder(
                "tbl_master_questionnaire_questions",
                "question",
                "tab_id",
                filters.tab_id,
                'order',
                ['order','input_type']
            ).then(function (options) {
                options.forEach((element:any) => {
                    if(element.input_type==21){
                        element.label = "Table";
                    }else if(element.input_type==20){
                        element.label ="Divider";
                    }
                });
                const sortCol = options.sort(sortFunc);
                setTableData(sortCol);
                setOriginalData(sortCol);
            });
        }


    }, [filters, loading])


    const handleSubmit = async () => {
        try {
            setLoading(true);
            setColumnChange(false);
            const res = (await datatableSettingsService.updateDatatableOrderSettings({
                data: tableData,
                table_name: settingTableName
            })) as any;

            if (res.data.status === "success") {
                setLoading(false);
                onCloseClick();
                toast("Order changed succesfully", { type: toast.TYPE.SUCCESS });
                setColumnChange(true);
                setLoading(false);
            } else {
                setLoading(false);
                toast("Please try again", { type: toast.TYPE.ERROR });
            }

        } catch (err) {
            setLoading(false);
        }
    }


    return (
        <Modal
            size="lg"
            isOpen={show}
            toggle={onCloseClick}
            backdrop="static"
            keyboard={false}
            autoFocus={false}
            centered={true}
        >
            <div className="modal-header">
                <h5 className="modal-title">Order settings</h5>
                <button
                    type="button"
                    className="btn-close"
                    onClick={onCloseClick}
                    aria-label="Close"
                ></button>
            </div>
            <ModalBody>
                <Row className='filter-form-row'>
                    <Filters
                        filters={filters}
                        onApply={() => { }}
                        onClear={() => { }}
                        onChange={(obj: any) => handleFiltersChange({ ...obj, is_filtered: true })}
                        hideTab={dependentField == "questionnaire_type_id" ? false : true}
                    />
                </Row>
                <Table
                    id="datatable_settings"
                    className="table table-bordered table-striped w-100 mb-0"
                >
                    <thead className="thead-light">
                        <tr>
                            <th>Fields</th>
                            <th>Order</th>
                        </tr>
                    </thead>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided: any, snapshot: any) => (
                                <tbody
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={getListStyle(
                                        snapshot.isDragging,
                                    )}
                                >
                                    {tableData && tableData.length > 0
                                        ? tableData.map((data: any, idx: number) =>
                                            data.hidden === true ? null : (
                                                <Draggable key={`col-${data.order}`} draggableId={`col-${data.order}`} index={data.order}>
                                                    {(provided: any, snapshot: any) => (
                                                        <tr key={data.order}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(
                                                                snapshot.isDragging,
                                                                provided.draggableProps.style
                                                            )}
                                                        >
                                                            <td>{renderRowHTML(data.label)}</td>
                                                            <td>{data.order}</td>
                                                        </tr>
                                                    )}
                                                </Draggable>

                                            )
                                        )
                                        : <tr>
                                            <td colSpan={2}> Please select {dependentField == "questionnaire_type_id" ? "questionanire type" : "questionnaire tab"}</td>
                                        </tr>}
                                </tbody>
                            )}
                        </Droppable>
                    </DragDropContext>
                </Table>
            </ModalBody>
            <ModalFooter>
                <button
                    type="button"
                    className="btn btn-primary btn-md ms-2"
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    {loading ? (
                        <span>
                            <i className="fa-solid fa-spinner fa-spin"></i>
                            <span>&nbsp; Please wait...</span>
                        </span>
                    ) : (
                        <span>Submit</span>
                    )}
                </button>

                <button
                    type="button"
                    className="btn btn-outline-danger btn-md ms-2"
                    onClick={() => setTableData(originalData)}
                    disabled={loading}
                >
                    {loading ? (
                        <span>
                            <i className="fa-solid fa-spinner fa-spin"></i>
                            <span>&nbsp; Please wait...</span>
                        </span>
                    ) : (
                        <span>Reset</span>
                    )}
                </button>

                <button
                    type="button"
                    className="btn btn-outline-danger btn-md ms-2"
                    onClick={onCloseClick}
                >
                    Cancel
                </button>
            </ModalFooter>
        </Modal>
    )
}

export default DataTableOrderSettingsModal