import React, { useCallback, useState } from "react";
import {
    Modal,
    ModalBody,
    Button,
    Card,
    CardBody,
    CardFooter,
} from "reactstrap";
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';
import documentTemplateService from "../../../services/document.template.service";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const TemplateListModel = (props: any) => {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const { filters, selectTemplate } = props;
    const [template, setTemplate] = useState<any>([]);
    const loadOptions = async (keyword: string) => {
        const filter = {
            search: keyword,
            extra: filters,

        };
        const templates = await documentTemplateService.searchTemplate(filter) as any;
        return templates?.data?.data || [];
    };
    const loadSuggestedOptions = useCallback(
        debounce((inputValue, callback) => {
            loadOptions(inputValue).then(options => callback(options))
        }, 500), []);

    
    const handleOnChange = (value: any) => {
        setTemplate(value);
    };

    const handleSelectTemplate = () => {
        selectTemplate(template);
        return navigate("/client-onboarding/get-template/"+template.value+"/"+template.template_id);
    }


    const onClickClose = () =>{
        setTemplate([]);
        props.onCloseClick();
    }

    return (
        <Modal
            size="lg"
            isOpen={props.show}
            toggle={onClickClose}
            backdrop="static"
            keyboard={false}
            autoFocus={false}>
            <div className="modal-header">
                <h5 className="modal-title">{props.title}</h5>
                <button
                    type="button"
                    className="btn-close"
                    onClick={onClickClose}
                    aria-label={t("Close")}></button>
            </div>
            <ModalBody>
                <Card>
                    <CardBody>
                        <div className="mb-3">
                            <AsyncSelect
                                cacheOptions
                                loadOptions={loadSuggestedOptions}
                                defaultOptions
                                placeholder={t("Search")}
                                isClearable={false}
                                components={{
                                    DropdownIndicator: () => null,
                                    IndicatorSeparator: () => null,
                                }}
                                onChange={handleOnChange}
                            />
                        </div>
                    </CardBody>
                    <CardFooter className="d-sm-flex flex-wrap bg-transparent border-top">
                        <div className="ms-auto d-flex gap-2 justify-content-end">
                            <Button
                                color="primary"
                                className="btn-block"
                                type="button"
                                onClick={() => handleSelectTemplate()}
                                disabled={template?.value? false: true}
                            >
                                <span>{t("Get")}</span>
                            </Button>
                        </div>
                    </CardFooter>
                </Card>
            </ModalBody>
        </Modal>
    )
}

export default TemplateListModel;