import { useEffect, useState } from "react";
import { dateFormat } from "../../../utils/formats";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import React from "react";
import DataTableBasic from "../../../components/Common/DataTableBasic";
import { datePickerI18, getDependentMultipleWhereSelectOptions, getUserProfile } from "../../../helpers/functions_helper";
import { generalStatusOptions } from "../../../common/staticdata";
import DatePicker from "../../../components/Form/DatePicker/DatePicker";
import { useTranslation } from "react-i18next";
import moment from "moment";
import FileUpload from "../../../components/Common/FileUpload";
import UploadDocModal from "../UploadDocModal";
import commonService from "../../../services/common.service";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import { Buffer } from "buffer";
import { intialData } from "../../Tasks/Charts/StatusWiseTaskCount";

type Type = {
    single_permit: number;
    questionnaire_type: number;
    single_permit_start_date: string;
    single_permit_end_date: string;
    single_permit_status: number;
    single_permit_filing_date: string;
    single_permit_due_date: string;
    single_permit_file: any;
    single_permit_auto_trigger: boolean;
};

const SinglePermit = (props: any) => {
    const { t } = useTranslation();
    const userProfile = getUserProfile("all-users");
    const { onChange } = props;
    const toastId = React.useRef(null) as any;
   
    const [statusList, setStatusList] = useState<any>([]);
    const [initialData, setInitialData] = useState<Type>({
        single_permit: 0,
        questionnaire_type: 0,
        single_permit_start_date: "",
        single_permit_end_date: "",
        single_permit_status: 0,
        single_permit_filing_date: "",
        single_permit_due_date: "",
        single_permit_file: null,
        single_permit_auto_trigger: false,
    });
    useEffect(() => {
        if(generalStatusOptions){
          const Options = generalStatusOptions?.map((option: any) => ({
            label: t(option.label),
            value: option.value
          }));
          setStatusList(Options);
         }
      },[]);

      useEffect(() => {
        if(generalStatusOptions){
          const Options = generalStatusOptions?.map((option: any) => ({
            label: t(option.label),
            value: option.value
          }));
          setStatusList(Options);
         }
      },[localStorage.getItem("I18N_LANGUAGE")]);

    const columns = [
        {
            dataField: "DT_RowIndex",
            text: `${t('Sr. No.')}`,
            formatter: (rowContent: any, row: any, idx: number): any => {
                return idx + 1;
            },
        },
        {
            dataField: "questionnaire_type",
            text: `${t('Questionnaire type')}`,
            formatter: (rowContent: any, row: any, idx: number) => {
                return questionnaireTypeOption?.find((option: any) => option.value == row.questionnaire_type)
                    ?.label;
            },
        },
        {
            dataField: "single_permit_start_date",
            text: `${t('Start date')}`,
            formatter: (rowContent: any, row: any, idx: number): any => {
                return row.single_permit_start_date && dateFormat(row.single_permit_start_date);
            },
        },
        {
            dataField: "single_permit_end_date",
            text: `${t('End date')}`,
            formatter: (rowContent: any, row: any, idx: number): any => {
                return row.single_permit_end_date && dateFormat(row.single_permit_end_date);
            },
        },
        {
            dataField: "single_permit_status",
            text: `${t('Status')}`,
            formatter: (rowContent: any, row: any, idx: number) => {
                return statusList?.find((option: any) => option.value == row.single_permit_status)
                    ?.label;
            },
        },
        {
            dataField: "single_permit_filing_date",
            text: `${t('Filling date')}`,
            formatter: (rowContent: any, row: any, idx: number): any => {
                return row.single_permit_filing_date && dateFormat(row.single_permit_filing_date);
            },
        },
        {
            dataField: "single_permit_due_date",
            text: `${t('Due date')}`,
            formatter: (rowContent: any, row: any, idx: number): any => {
                return row.single_permit_due_date && dateFormat(row.single_permit_due_date);
            },
        },
        {
            dataField: "single_permit_file",
            text: `${t('File')}`,
            formatter: (rowContent: any, row: any, idx: number) => {
                const single_permit_file_data =
                    permitFile && permitFile.find((data: any) => data.id == row.single_permit_file);
                return (
                    <a className="text-primary d-block mt-1 mb-1" onClick={() => handleSingleDownload(3)}>
                        {single_permit_file_data?.file_name + "." + single_permit_file_data?.file_extension}
                    </a>
                );
            },
        },
        {
            dataField: "to_check",
            text: t("Automate for next year"),
            sort: true,
            search: true,
            footer: "",
            formatter: (rowContent: any, row: any): any => {
                return (
                    <FormGroup switch inline className="form-check-info">
                        <Input
                            type="switch"
                            dis
                            checked={row?.single_permit_auto_trigger}
                            label={row?.single_permit_auto_trigger ? t('Yes') : t('No')}
                        />
                    </FormGroup>
                );
            },
        },
        {
            dataField: "action",
            text: `${t('Action')}`,
            formatter: (rowContent: any, row: any, idx: number): any => {
                return (
                    <div className="d-flex gap-2">
                        <Button
                            color="primary"
                            outline={true}
                            className={`btn btn-pill btn-outline-primary btn-sm`}
                            type="button"
                            onClick={() => editRow(idx)}
                        >
                            <span className="align-middle material-symbols-outlined fw-light fs-4">edit</span>
                        </Button>

                        <Button
                            color="danger"
                            outline={true}
                            className={`btn btn-pill btn-outline-danger btn-sm`}
                            type="button"
                            onClick={() => removeRow(idx)}
                        >
                            <span className="align-middle material-symbols-outlined fw-light fs-4">delete</span>
                        </Button>
                    </div>
                );
            },
        },
    ];

    const [isEdit, setIsEdit] = useState(false);
    const [editIndex, setEditIndex] = useState<number | null>(null);
    const [addRowBtnDisabled, setAddRowBtnDisabled] = useState(true);
    const [data, setData] = useState<any>([]);
    const [dataList, setDataList] = useState<any>([]);
    const [fileData, setFileData] = useState<any>([]);
    const [uploadDocModal, setUploadDocModal] = useState<boolean>(false);
    const [uploadDocData, setUploadDocData] = useState<any>([]);
    const [permitFile, setPermitFile] = useState<any>([]);
    const [datatableOptions, setDatatableOptions] = useState({
        draw: 1,
        page: 1,
        recordsTotal: 0,
        recordsFiltered: 0,
        sizePerPage: 5,
        searchText: "",
        sortField: "id",
        sortOrder: "desc",
    });
    const [questionnaireTypeOption, setQuestionnaireTypeOption] = useState<any>([]);

    useEffect(() => {
        getDependentMultipleWhereSelectOptions(
            "tbl_master_questionnaire_type",
            "questionnaire_type",
            ["year", "type"],
            [new Date().getFullYear(), "single_permit"],
            "",
            ["type", "task_type", "user_type", "region_id"]
        ).then(function (options: any) {
            setQuestionnaireTypeOption(options);
        });
    }, []);

    const onTableChange = (type: any, newState: any) => {
        let draw = datatableOptions.draw + 1;
        let page = 1;
        if (type === "search") {
            page = 1;
        } else {
            page = newState.page;
        }
        setDatatableOptions((prevData: any) => ({
            ...prevData,
            draw: draw,
            page: page,
            sizePerPage: newState.sizePerPage,
            searchText: newState.searchText,
            sortField: newState.sortField,
            sortOrder: newState.sortOrder,
        }));

        const slicedArray = data.slice(newState.sizePerPage * (page - 1), newState.sizePerPage * page);
        setDataList(slicedArray);
    };

    const addRow = () => {
        setData((preState: any) => [
            ...preState,
            {
                single_permit: initialData?.single_permit,
                questionnaire_type: initialData?.questionnaire_type,
                single_permit_start_date: initialData?.single_permit_start_date,
                single_permit_end_date: initialData?.single_permit_end_date,
                single_permit_status: initialData?.single_permit_status,
                single_permit_filing_date: initialData?.single_permit_filing_date,
                single_permit_due_date: initialData?.single_permit_due_date,
                single_permit_file: initialData?.single_permit_file,
                single_permit_auto_trigger: initialData?.single_permit_auto_trigger
            },
        ]);

        clearForm();
        setAddRowBtnDisabled(true);
    };

    const updateRow = () => {
        if (editIndex !== null) {
            var rows: any = [...data];
            rows.map((item: any, index: number) => {
                if (index === editIndex) {
                    rows[index] = {
                        ...item,
                        single_permit: initialData?.single_permit,
                        questionnaire_type: initialData?.questionnaire_type,
                        single_permit_start_date: initialData?.single_permit_start_date,
                        single_permit_end_date: initialData?.single_permit_end_date,
                        single_permit_status: initialData?.single_permit_status,
                        single_permit_filing_date: initialData?.single_permit_filing_date,
                        single_permit_due_date: initialData?.single_permit_due_date,
                        single_permit_file: initialData?.single_permit_file,
                        single_permit_auto_trigger: initialData?.single_permit_auto_trigger
                    };
                }
            });
            setData(rows);

            clearForm();
            setIsEdit(false);
            setEditIndex(null);
        }
    };

    const removeRow = (idx: number) => {
        const rows = data.filter((item: any, index: number) => index !== idx);
        setData(rows);

        setIsEdit(false);
        setEditIndex(null);
    };

    const editRow = (idx: number) => {
        const row = data.find((item: any, index: number) => index === idx);
        setInitialData((preData: any) => ({
            ...preData,
            single_permit: row?.single_permit,
            questionnaire_type: row?.questionnaire_type,
            single_permit_start_date: row?.single_permit_start_date,
            single_permit_end_date: row?.single_permit_end_date,
            single_permit_status: row?.single_permit_status,
            single_permit_filing_date: row?.single_permit_filing_date,
            single_permit_due_date: row?.single_permit_due_date,
            single_permit_file: row?.single_permit_file,
            single_permit_auto_trigger: row?.single_permit_auto_trigger
        }));

        setIsEdit(true);
        setEditIndex(idx);
    };

    const clearForm = () => {
        setInitialData((preData: any) => ({
            ...preData,
            single_permit: 0,
            questionnaire_type: 0,
            single_permit_start_date: "",
            single_permit_end_date: "",
            single_permit_status: 0,
            single_permit_filing_date: "",
            single_permit_due_date: "",
            single_permit_file: null,
            single_permit_auto_trigger: false
        }));
    };

   

    const handleChange = (fieldName: string, fieldValue: any) => {
        setInitialData((preState) => ({ ...preState, [fieldName]: fieldValue }));
    };

    const handleChangeCheck = (fieldName: string, e: any) => {
        const pre_check_val = e.target.checked ? 1 : 0;
        setInitialData((preState) => ({ ...preState, [fieldName]: pre_check_val}));
    };

    useEffect(() => {
        if (initialData.single_permit_start_date) {
          const single_permit_due_date = moment(initialData.single_permit_start_date)
            .subtract(3, "months")
            .format("YYYY-M-D");

        const single_permit_end_date = moment(initialData.single_permit_start_date)
            .add(1, "years")
            .format("YYYY-M-D");
          const updatedIntialData = {
            ...initialData,
            single_permit_due_date: single_permit_due_date,
            single_permit_end_date: single_permit_end_date
          };
          setInitialData(updatedIntialData);
        }
      }, [initialData.single_permit_start_date]);


      useEffect(() => {
        if (initialData.single_permit_due_date) {
          const single_permit_start_date = moment(initialData.single_permit_due_date)
            .add(3, "months")
            .format("YYYY-M-D");
          const updatedIntialData = {
            ...initialData,
            single_permit_start_date: single_permit_start_date
          };
          setInitialData(updatedIntialData);
        }
      }, [initialData.single_permit_due_date]);

    const handleUploadDocument = (type: string) => {
        const data = {
            type,
            id: props?.id,
            user_id: props?.user_id,
            title: type,
            year: initialData.single_permit_end_date
                ? moment(initialData.single_permit_end_date).format("YYYY")
                : moment().format("YYYY"),
            exp_date: initialData.single_permit_end_date,
            due_date: initialData.single_permit_due_date,
        };
        setUploadDocData(data);
        setUploadDocModal(true);
    };

    const handleSingleDownload = async (type: any) => {
        if (fileData) {
            console.log(type);
            const row = fileData.filter((dt: any) => dt.section_id == type)[0];
            if (row) {
                const result = await commonService.downloadAttachment({
                    id: Buffer.from(String(row.id)).toString("base64"),
                    attachment_id: Buffer.from(String(row.attachment_id)).toString("base64"),
                });
                if (result) {
                    fileDownload(result.data, row.file_name + "." + row.file_extension);
                }
            } else {
                toastId.current = toast(t("File is not available"), {
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                });
            }
        }
    };

    const onUploadSuccess = (id: any) => {
        getAttachmentList();
    };

    const getAttachmentList = async () => {
        const moduleData = {
            module_app_id: 11,
            module_main_id: props?.id,
            module_sub_id: props?.user_id,
            section_id: 4,
            file_path: "users",
        };
        const response = await commonService.getAttachmentList(moduleData);
        if (response.data.status === "success") {
            setFileData(response.data.data.original.data);
        }
    };

    useEffect(() => {
        if (fileData.length > 0) {
            const row_permit = fileData.filter((dt: any) => dt.section_id == 4);
            setPermitFile(row_permit);

            setInitialData((preData: any) => ({
                ...preData,
                single_permit_file: row_permit && row_permit[0]?.id,
            }));
        }
    }, [fileData]);

    useEffect(() => {
        if (
            initialData.single_permit !== 0 &&
            initialData.questionnaire_type !== 0 &&
            initialData.single_permit_start_date !== "" &&
            initialData.single_permit_end_date !== "" &&
            initialData.single_permit_status !== 0 &&
            initialData.single_permit_filing_date !== "" &&
            initialData.single_permit_due_date !== "" &&
            initialData.single_permit_file !== null
        ) {
            setAddRowBtnDisabled(false);
        } else {
            setAddRowBtnDisabled(true);
        }
    }, [
        initialData.single_permit,
        initialData.questionnaire_type,
        initialData.single_permit_start_date,
        initialData.single_permit_end_date,
        initialData.single_permit_status,
        initialData.single_permit_filing_date,
        initialData.single_permit_due_date,
        initialData.single_permit_file,
    ]);

    useEffect(() => {
        setData(props?.data);
    }, [props?.data]);

    useEffect(() => {
        setDatatableOptions((prevData: any) => ({
            ...prevData,
            draw: 1,
            page: 1,
            sizePerPage: 5,
            recordsTotal: data.length,
            recordsFiltered: data.length,
        }));

        const slicedArray = data.slice(0, 5);
        setDataList(slicedArray);

        onChange(data);
    }, [data]);

    useEffect(() => {
        getAttachmentList();
    }, []);

    return (
        <React.Fragment>
            {uploadDocModal && (
                <UploadDocModal
                    data={uploadDocData}
                    isOpen={uploadDocModal}
                    handleClose={() => setUploadDocModal(false)}
                    onSuccess={onUploadSuccess}
                    type="user"
                />
            )}

            <Row>
                <Col xl={2} lg={2} md={2} sm={6}>
                    <div className="mb-3">
                        <Label className="form-label">{t("Single Permit")}</Label>
                        <div className="form-check mb-2">
                            <Input
                                id="single_permit_yes"
                                name="single_permit"
                                value="1"
                                className="form-check-input"
                                type="radio"
                                checked={initialData.single_permit == 1 ? true : false}
                                onChange={() => handleChange("single_permit", "1")}
                            />
                            <label className="form-check-label" htmlFor="single_permit_yes">
                                {t("Yes")}
                            </label>
                        </div>

                        <div className="form-check mb-2">
                            <Input
                                id="single_permit_no"
                                name="single_permit"
                                value="0"
                                className="form-check-input"
                                type="radio"
                                checked={initialData.single_permit == 0 ? true : false}
                                onChange={() => handleChange("single_permit", "0")}
                            />
                            <label className="form-check-label" htmlFor="single_permit_no">
                                {t("No")}
                            </label>
                        </div>
                    </div>
                </Col>

                <Col xl={2} lg={2} md={2} sm={6}>
                    <div className="mb-3">
                        <Label className="form-label">
                            {t("Questionnaire type")} <span className="text-warning is_required">*</span>
                        </Label>
                        <Select
                            id="questionnaire_type"
                            name="questionnaire_type"
                            className="is-invalid"
                            options={questionnaireTypeOption}
                            value={
                                initialData.questionnaire_type
                                    ? questionnaireTypeOption?.find(
                                        (option: any) => option.value == initialData.questionnaire_type
                                    )
                                    : ""
                            }
                            isSearchable={true}
                            isDisabled={initialData.single_permit == 0 ? true : false}
                            onChange={(e: any) => {
                                handleChange("questionnaire_type", e.value);
                            }}
                        />
                    </div>
                </Col>

                <Col xl={2} lg={2} md={2} sm={12}>
                    <div className="mb-3">
                        <DatePicker
                            id="single_permit_start_date"
                            name="single_permit_start_date"
                            label={t("Start date")}
                            required={true}
                            value={initialData.single_permit_start_date}
                            disabled={initialData.single_permit == 0 ? true : false}
                            options={{
                                altInput: true,
                                altFormat: "d/m/Y",
                                dateFormat: "Y-m-d H:i:S",
                                allowInput: true,
                                onChange: function (selectedDates, dateStr, instance) {
                                    handleChange("single_permit_start_date", dateStr);
                                },
                                onClose: function (selectedDates, dateStr, instance) {
                                    handleChange("single_permit_start_date", dateStr);
                                },
                                locale: {  ...datePickerI18(),firstDayOfWeek: 1 },
                            }}
                        />
                    </div>
                </Col>


                <Col xl={2} lg={2} md={2} sm={12}>
                    <div className="mb-3">
                        <DatePicker
                            id="single_permit_end_date"
                            name="single_permit_end_date"
                            label={t("End date")}
                            required={true}
                            value={initialData.single_permit_end_date}
                            disabled={initialData.single_permit == 0 ? true : false}
                            options={{
                                altInput: true,
                                altFormat: "d/m/Y",
                                dateFormat: "Y-m-d H:i:S",
                                allowInput: true,
                                onChange: function (selectedDates, dateStr, instance) {
                                    handleChange("single_permit_end_date", dateStr);
                                },
                                onClose: function (selectedDates, dateStr, instance) {
                                    handleChange("single_permit_end_date", dateStr);
                                },
                                locale: {  ...datePickerI18(),firstDayOfWeek: 1 },
                            }}
                        />
                    </div>
                </Col>



                <Col xl={2} lg={2} md={2} sm={6}>
                    <div className="mb-3">
                        <Label className="form-label">
                            {t("Status")} <span className="text-warning is_required">*</span>
                        </Label>
                        <Select
                            id="single_permit_status"
                            name="single_permit_status"
                            className="is-invalid"
                            options={statusList}
                            value={
                                initialData.single_permit_status
                                    ? statusList?.find(
                                        (option: any) => option.value == initialData.single_permit_status
                                    )
                                    : ""
                            }
                            isSearchable={true}
                            isDisabled={
                                initialData.single_permit == 1 && userProfile.IS_INTERNAL === true
                                    ? false
                                    : true
                            }
                            onChange={(e: any) => {
                                handleChange("single_permit_status", e.value);
                            }}
                        />
                    </div>
                </Col>

                <Col xl={2} lg={2} md={2} sm={12}>
                    <div className="mb-3">
                        <DatePicker
                            id="single_permit_filing_date"
                            name="single_permit_filing_date"
                            label={t("Filling date")}
                            required={true}
                            value={initialData.single_permit_filing_date}
                            disabled={initialData.single_permit == 0 ? true : false}
                            options={{
                                altInput: true,
                                altFormat: "d/m/Y",
                                dateFormat: "Y-m-d H:i:S",
                                allowInput: true,
                                onChange: function (selectedDates, dateStr, instance) {
                                    handleChange("single_permit_filing_date", dateStr);
                                },
                                onClose: function (selectedDates, dateStr, instance) {
                                    handleChange("single_permit_filing_date", dateStr);
                                },
                                locale: {  ...datePickerI18(),firstDayOfWeek: 1 },
                            }}
                        />
                    </div>
                </Col>

                <Col xl={2} lg={2} md={2} sm={12}></Col>

                <Col xl={2} lg={2} md={2} sm={12}>
                    <div className="mb-3">
                        <DatePicker
                            id="single_permit_due_date"
                            name="single_permit_due_date"
                            label={t("Due date")}
                            required={true}
                            value={initialData.single_permit_due_date}
                            disabled={initialData.single_permit == 0 ? true : false}
                            options={{
                                altInput: true,
                                altFormat: "d/m/Y",
                                dateFormat: "Y-m-d H:i:S",
                                allowInput: true,
                                onChange: function (selectedDates, dateStr, instance) {
                                    handleChange("single_permit_due_date", dateStr);
                                },
                                onClose: function (selectedDates, dateStr, instance) {
                                    handleChange("single_permit_due_date", dateStr);
                                },
                                locale: {  ...datePickerI18(),firstDayOfWeek: 1 },
                            }}
                        />
                    </div>
                </Col>

                <Col xl={2} lg={2} md={2} sm={12}>
                    <Label className="form-label mb-3">{t("Automate for next year")}</Label>
                    <div className="mb-3">
                        <FormGroup switch inline className="form-check-info">
                            <Input
                                type="switch"
                                dis
                                checked={intialData?.single_permit_auto_trigger}
                                onChange={(e:any) => handleChangeCheck("single_permit_auto_trigger", e)}
                            />
                        </FormGroup>
                    </div>
                </Col>


                <Col xl={3} lg={3} md={3} sm={12}>
                    <Row>
                        <Col xl={10} lg={10} md={10} sm={10}>
                            <Label className="form-label">{t("Upload file")}</Label>
                            <FileUpload
                                title={t("Upload single permit")}
                                onClickHandler={() => handleUploadDocument("Single permit")}
                                onDownload={() => handleSingleDownload(4)}
                                fileData={initialData.single_permit == 1 && permitFile}
                                disabled={initialData.single_permit == 0 ? true : false}
                            />
                        </Col>
                        {userProfile.IS_INTERNAL && (
                            <Col xl={1} lg={1} md={1} sm={6}>
                                <div className="mb-3">
                                    <Label className="form-label">
                                        <br />
                                    </Label>
                                    <div className="input-group">
                                        {isEdit === true ? (
                                            <Button
                                                color="success"
                                                type="button"
                                                className={`btn btn-sm btn-success`}
                                                disabled={addRowBtnDisabled}
                                                onClick={() => updateRow()}
                                            >
                                                <span className="align-middle material-symbols-outlined fw-light fs-4">
                                                    done
                                                </span>
                                            </Button>
                                        ) : (
                                            <Button
                                                type="button"
                                                className={`btn btn-sm ${addRowBtnDisabled ? "btn-primary" : "btn-info"}`}
                                                disabled={addRowBtnDisabled}
                                                onClick={() => addRow()}
                                            >
                                                <span className="align-middle material-symbols-outlined fw-light fs-4">
                                                    add
                                                </span>
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        )}
                    </Row>
                </Col>

            </Row>

            {dataList && dataList.length > 0 && (
                <DataTableBasic
                    columns={columns}
                    products={dataList}
                    datatableOptions={datatableOptions}
                    onTableChange={onTableChange}
                />
            )}
        </React.Fragment>
    );
};

export default SinglePermit;
