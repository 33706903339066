import React, { useEffect, useRef, useState } from "react";
import Select from "../../../components/Form/Select/Select";
import { Button, Card, Col, Container, Label, Nav, NavItem, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import Dropzone from "../../../components/Common/Dropzone";
import { toast } from "react-toastify";
import { getDependentSelectOptions, getUserProfile } from "../../../helpers/functions_helper";
import { Link, useNavigate } from "react-router-dom";
import TemplateListModel from "../DocSignature/TemplateListModal";
import Breadcrumbs from "../../../components/Layout/Breadcrumb";
import ScrollUp from "../../../components/ScrollUpDown/ScrollUp";
import ScrollDown from "../../../components/ScrollUpDown/ScrollDown";
import documentTemplateService from "../../../services/document.template.service";
import { intialValues } from "../DocSignature/helper";
const DocTemplates = () => {
  const { t } = useTranslation();

  const moduleHeading = t('Templates');

  document.title = `${moduleHeading} | ETC 3.0`;
  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: "Dashboard",
      active: false,
    },
    {
      url: "#",
      label: moduleHeading,
      active: true,
    },
  ];
  const userProfile = getUserProfile();
  let navigate = useNavigate();
  const toastId = useRef(null) as any;
  const [loading, setLoading] = useState<boolean>(false);
  const [intialData, setIntialData] = useState<any>(intialValues);
  const [oldImage, setoldImage] = useState(null);
  const [showTemplateModel, setShowTemplateModel] = useState(false);
  const [documentTypeOptions, setDocumentTypeOptions] = useState([]);
  const [docType, setDocType] = useState();

  const [insertId, setInsertId] = useState(0);
  const [insertSubId, setInsertSubId] = useState("");

  useEffect(() => {
    getDependentSelectOptions(
      'tbl_master_document_types',
      'document_type',
      "map_master",
      2
    ).then((options: any) => setDocumentTypeOptions(options))
  }, [])


  const handleUploadDocument = (document: any) => {
    toastId.current = toast(t("Creating template..."), {
      autoClose: false,
      isLoading: true,
    });
    if (document) {
      for (let i = 0; i < document.length; i++) {
        const values = {
          type: docType,
          doc_type: 2,
          document_name: document[0].short_description,
          expiry_date: document[0].expiry_date,
          due_date: document[0].due_date,
          description: '',
          attachment_id: document[0].attachment_id,
        }
        console.log(values);
        create(values);
      }
    }
    toast.update(toastId.current, {
      type: toast.TYPE.SUCCESS,
      render: t("Template created successfully"),
      autoClose: 5000,
      isLoading: false,
      delay: 500,
    });
    setLoading(false);
    return navigate("/client-onboarding/doc-signature/list");
  }

  const create = async (values: any) => {
    try {
      const res = (await documentTemplateService.create(values)) as any;
      if (res.status === "success") {
        return true;
      } else {
        return false;
      }
    } catch (err: any) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   if (data) {
  //     setIntialData(data);
  //     setoldImage(data?.attachment);
  //   }
  // }, [data]);

  const handleSelectedTemplate = (temp: any) => {
    // message.message.body.content = temp;
    //setMessage((preState)=>({...preState,preState.message.body:{preState.message.body,content:temp}})
  }

  return (
    <React.Fragment>
      <ScrollUp />
      <ScrollDown top={'100px'} />

      <div className={"page-content"}>
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={moduleHeading}
            breadcrumbItems={breadcrumbItems}
            endAdornment={
              <Nav className="nav justify-content-end">
                <NavItem>
                  <Link
                    to="/client-onboarding/template/list"
                    className={`btn btn-info btn-label btn-sm`}>
                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">list_alt</span> {t("Template list view")}
                  </Link>
                </NavItem>
              </Nav>
            }
          />

          <Row>
            <Col xl="12">
              <Card>
                <Row>
                  <Col className="col-12">
                    <div className='mx-5 my-3 text-center g-5'>
                      <Button color='primary' onClick={() => setShowTemplateModel(true)}>{t("Get templates")}</Button>
                      <Link className="btn btn-block btn-primary mx-3" to={"/client-onboarding/doc-signature/create-template"}>
                        {t("Create Template")}
                      </Link>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-12">
                    <hr></hr>
                  </Col>
                  <Col className="col-12 text-center"><b>{t("OR")}</b></Col>
                  <Col className="col-12">
                    <hr></hr>
                  </Col>
                </Row>
                <Row>
                  <Col xl={12} lg={12} md={12} sm={12}>
                    <div className="mx-5">
                      <Col className="col-12">
                        <Label className="form-label">
                          {t("Type of document")}{" "}
                        </Label>
                        <Select
                          id="document_type_id"
                          name="document_type_id"
                          placeholder="Select"
                          options={documentTypeOptions}
                          value={documentTypeOptions.find(
                            (o: any) => o.value === intialData.type
                          )}
                          isSearchable={true}
                          isDisabled={false}
                          onChange={(option: any) => {
                            setDocType(option.value);
                          }}
                        />
                      </Col>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mx-5 my-5 text-center g-5">
                      <Col className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">{t("Attachments")}</Label>
                          <Dropzone
                            moduleData={{
                              module_app_id: 14,
                              module_main_id: 0,
                              module_sub_id: "",
                              section_id: 0,
                              file_path: "user_documents",
                            }}
                            //attachmentForIDs={commentAttachmentIDs}
                            fetchData={false}
                            isOpen={true}
                            insertId={insertId}
                            insertSubId={insertSubId}
                            onUpload={true}
                          //onUpload={(files: any) => handleUploadDocument(files)}
                          //onSuccess={multiUploadSuccess}
                          />
                          {/* <Dropzone
                            moduleData={{
                              module_app_id: 14,
                              module_main_id: userProfile.USER_ID,
                              module_sub_id: userProfile.USER_SUB_ID,
                              section_id: userProfile.USER_COMPANY,
                              file_path: "User_document",
                            }}
                            hideList={true}
                            fetchData={true}
                            isOpen={true}
                            enableExpiryDate={true}
                            onUpload={(files: any) => handleUploadDocument(files)}
                          /> */}
                        </div>
                      </Col>
                    </div>
                  </Col>
                </Row>
                <TemplateListModel
                  title={t("Template Search")}
                  show={showTemplateModel}
                  selectTemplate={handleSelectedTemplate}
                  onCloseClick={() => setShowTemplateModel(false)}
                />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DocTemplates;
