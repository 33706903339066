import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody, Container } from 'reactstrap'
import Breadcrumbs from '../../components/Layout/Breadcrumb'
import AdminView from './AdminView';
import CustomerView from './CustomerView';
import QuestionnaireService from '../../services/questionnaire.service';

// Import Loader
import Loader from "../../components/Layout/Loader";
import { getUserProfile } from '../../helpers/functions_helper';
import { QuestionnaireUserDataAction } from '../../store/actions';
import { useTranslation } from 'react-i18next';
import NoAccess from '../NotFound/NoAccess';
const Template = (props: any) => {
    const { t } = useTranslation();
    const breadcrumbItems = [
        {
            url: "/dashboard",
            label: t("Dashboard"),
            active: false,
        },
        {
            url: "/questionnaires/all-questionnaires",
            label: t("Questionnaire"),
            active: true,
        },
    ];
    let navigate = useNavigate();
    let params = useParams();
    if (props?.data) {
        params = props?.data;
    }
    const dispatch = useDispatch();
    const userProfile = getUserProfile('all-questionnaires');
    document.title = "Edit Questionnaire| " + process.env.REACT_APP_NAME;

    const [questionnaireStatus, setQuestionnaireStatus] = useState(0);
    const [user_id, setUser_id] = useState();
    const [questionnaireData, setQuestionnaireData] = useState<any>([]);
    const [adminview, setAdminView] = useState<any>();
    const [fileManager, setFileManager] = useState(false);
    const [admin, setAdmin] = useState(false);
    const [user, setUser] = useState(false);
    const [usertype, setUsertype] = useState("");
    const [isAccess, setIsAccess] = useState(true);

    let data = useSelector((state: any) => ({
        success: state.QuestionnaireUserData.success,
        error: state.QuestionnaireUserData.error,
        loading: state.QuestionnaireUserData.loading,
        questionnairelist: state.Questionnaire.GET_QUESTIONNAIRE_LIST_SINGLE.data,
    }))


    // Questionnaire data
    useEffect(() => {
        loadquestionnaireData(params.id)
    }, []);

    const loadquestionnaireData = async (id: any) => {
        if (id) {
            const res = await QuestionnaireService.getQuestionnaireList({ id });
            if (res.data && res.data.data && res.data?.status == "success") {
                setIsAccess(true);
                let file_manager = res.data.data.company_file_manager;
                if (res.data.data.company_id == 0) {
                    let file_manager = res.data.data.user_file_manager;
                }
                if (file_manager == userProfile.USER_ID && res.data.data.employee !== userProfile.USER_ID) {
                    setFileManager(true);
                    setUsertype('fileManager');
                } else if (res.data.data.employee !== userProfile.USER_ID) {
                    // else if (userProfile.IS_COMPANY_ADMIN && res.data.data.employee !== userProfile.USER_ID) {
                    setAdmin(true);
                    setUsertype('admin');
                } else {
                    setUser(true);
                    setUsertype('user');
                }
                setQuestionnaireData(res.data.data);
                setQuestionnaireStatus(res.data.data.status);
            } else if (res?.data?.status === 'error' && res?.data?.data && res?.data?.data?.ERROR_CODE == "CAN_VIEW") {
                return navigate("/404");
            }

            else {
                setQuestionnaireStatus(1);
                setIsAccess(false);
            }
        }

    };

    useEffect(() => {
        if (usertype != "") {
            dispatch(QuestionnaireUserDataAction.updateQuestionnaireUserStatus({ usertype: admin ? 'admin' : fileManager ? 'filemanager' : 'user', "questionnaire_id": params.id }));
        }

    }, [usertype]);


    useEffect(() => {
        if (questionnaireStatus) {
            if (questionnaireData.view_all == 1 && !user) {
                setAdminView(true);
            }
            else if ((admin || fileManager) && !user) {
                setAdminView(true);
            }
            else if ((questionnaireStatus == 1 || questionnaireStatus == 4) && questionnaireData.admin_template != 1) {
                setAdminView(false);
            } else {
                setAdminView(true);
            }
            setUser_id(questionnaireData.employee);
        }

    }, [questionnaireStatus, questionnaireData])

    return (
        <div className="page-content" style={{ height: adminview ? '92vh' : 'auto' }}>
            {!props?.data ?
                <Breadcrumbs
                    breadcrumbItems={breadcrumbItems}
                />
                : null}
            {!questionnaireStatus ?
                <Loader />
                :
                <>
                    {isAccess ?
                        <>
                            {adminview ? <AdminView user_id={user_id} usertype={usertype} questionnaireData={params} />
                                : <CustomerView user_id={user_id} usertype={"user"} questionnaireData={params} />
                            }
                        </>
                        : <NoAccess />
                    }

                </>
            }


        </div>
    )
}

export default Template