import React, { useState } from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';


function MoreMenu(props: any) {
    const { direction } = props;
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const openTemp = () => props.OpenTemplateModel();
    const openSign = () => props.OpenSignatureModel();
    return (
        <div className="d-flex float-end">
            <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={direction}>
                <DropdownToggle caret color='light' size='sm'>
                <i className="fa-solid fa-swatchbook text-primary"></i>{" "}Template{" "}<i className="fa-solid fa-ellipsis"></i>
                </DropdownToggle>
                <DropdownMenu >
                    <DropdownItem  onClick={()=>openSign()}><i className="fa-solid fa-file-signature"></i>{' '}Insert signature</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                    onClick={()=>openTemp()}
                    ><i className="fa-solid fa-swatchbook text-primary"></i>{' '}My template</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    );
}



export default MoreMenu;