const ProgressBar = (props: any) => {
  const { title = "", progress = 0 } = props;

  return (
    <div className="py-2">
      <h5 className="font-size-14">
        {title && title}
        <span className="float-end ms-2">{parseInt(progress)}%</span>
      </h5>
      <div className="progress animated-progess progress-sm">
        <div
          className={
            parseInt(progress) <= 25
              ? "progress-bar bg-danger"
              : parseInt(progress) > 25 && parseInt(progress) <= 50
              ? "progress-bar bg-warning"
              : parseInt(progress) > 50 && parseInt(progress) <= 75
              ? "progress-bar bg-primary"
              : parseInt(progress) > 75 && parseInt(progress) <= 100
              ? "progress-bar bg-success"
              : "progress-bar"
          }
          style={{ width: parseInt(progress) + "%" }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
