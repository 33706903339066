import axios from "axios";
import { getAxiosConfigFileUploadWithToken, getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class RegionService {
    async getRegionDatatableList(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_REGION_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            );
        return response;
    }

    async createRegion(DATA: any) {
        var form_data = new FormData();
        for ( var key in DATA) {
            form_data.append(key, DATA[key]);
        }
        const response = await axios
            .post(SERVER_URL + url.CREATE_REGION, form_data,
                {
                    headers: getAxiosConfigFileUploadWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async updateRegion(DATA: any) {
        var form_data = new FormData();

        for ( var key in DATA) {
            form_data.append(key, DATA[key]);
        }
        const response = await axios
            .post(SERVER_URL + url.UPDATE_REGION, form_data,
                {
                    headers: getAxiosConfigFileUploadWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async deleteRegion(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.DELETE_REGION, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

}

export default new RegionService();