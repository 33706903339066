import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Nav, NavItem, Row } from 'reactstrap';
import styled from 'styled-components';
import { getUserProfile, widgetAccess } from '../../../helpers/functions_helper';
import Breadcrumbs from '../../../components/Layout/Breadcrumb';
import TotalCompany from './Charts/TotalCompany';
import CompanyPerQuarter from './Charts/CompanyPerQuarter';
import QuarterCompany from './Charts/QuarterCompany';
import ActiveVsNonActive from './Charts/ActiveVsNonActive';
import Counts from './Charts/Counts';
import CompanyVsTCuser from './Charts/CompanyVsEmployee';
import GraphFilters from '../../../components/Common/GraphFilters';
import { currentMonth, currentYear } from '../../../common/staticdata';
import ChartFilters from '../../../components/Chart/Filters';
const CustomCard = styled(Card)`
    height: calc(100% - 24px);
`;

const UserGraphicalView = () => {
  const userProfile = getUserProfile("all-company");
  const { t } = useTranslation();

  document.title = "Company | " + process.env.REACT_APP_NAME;

  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "#",
      label: t("Company"),
      active: true,
    },
  ];

  const inititalFilter = {
    week: null,
    quarter: null,
    month: null,
    year: currentYear,
    user_id: !(userProfile.IS_PARTNER && userProfile.IS_SUPER_ADMIN) ? userProfile.USER_ID : null,
    graphview: null,
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [filter, setFilter] = useState<any>(inititalFilter);

  const clearWorkAllocationFilter = () => {
    setFilter(inititalFilter);
  }
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={t("Company")}
            breadcrumbItems={breadcrumbItems}
            endAdornment={
              <div className="d-flex gap-2 text-end">
                {userProfile?.IS_GRAPHICAL_VIEW && (
                  <Link to="/masters/company" className={`btn btn-outline-secondary btn-label btn-sm`}>
                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                      list_alt
                    </span>
                    List view
                  </Link>
                )}

                {userProfile?.IS_GRAPHICAL_VIEW && (
                  <Link
                    to="/masters/company/graph"
                    className={`btn btn-info btn-label btn-sm`}
                  >
                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                      insert_chart
                    </span>
                    {t("Graphical view")}
                  </Link>
                )}

                {userProfile.CAN_CREATE ? (
                  <Link to="/masters/company/new-company" className="btn btn-primary btn-label btn-sm">
                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                      add
                    </span>{" "}
                    {t("Create company")}
                  </Link>
                ) : null}
              </div>
            }
          />
          {widgetAccess('Company_Count') ? <Row>
            <Col>
              <div className="mt-3">
                <Row>
                  <Counts filters={filter} />
                </Row>
              </div>
            </Col>
          </Row> : null}
          <Row>
            <Col>
              <div className="d-sm-flex flex-wrap mt-1 mb-3">
                <p></p>
                <div className="ms-auto">
                  <ChartFilters
                    filter={filter}
                    setFilter={setFilter}
                    clearFilter={clearWorkAllocationFilter}
                    enableCompany={false}
                    enableUser={false}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {widgetAccess('Total_number_of_company') &&
              <Col xl="8">
                <CustomCard>
                  <CardBody>
                    <TotalCompany filters={filter} />
                  </CardBody>
                </CustomCard>
              </Col>
            }
            {widgetAccess('Active_Vs_Archived_company') &&
              <Col xl="4">
                <CustomCard>
                  <CardBody>
                    <ActiveVsNonActive filters={filter} />
                  </CardBody>
                </CustomCard>
              </Col>
            }
          </Row>
          <Row>
            {widgetAccess('Total_number_of_companies_created_per_quarter') &&
              <Col xl="4">
                <CustomCard>
                  <CardBody>
                    <QuarterCompany filters={filter} />
                  </CardBody>
                </CustomCard>
              </Col>
            }
            {widgetAccess('Total_number_of_companies_created') &&
              <Col xl="8">
                <CustomCard>
                  <CardBody>
                    <CompanyPerQuarter filters={filter} />
                  </CardBody>
                </CustomCard>
              </Col>
            }
          </Row>
          <Row>
            {widgetAccess('Company_Vs_Taxconsult_user') &&
              <Col xl="6">
                <CustomCard>
                  <CardBody>
                    <CompanyVsTCuser filters={filter} />
                    {/* <CompanyVsArchive filters={filter} /> */}
                  </CardBody>
                </CustomCard>
              </Col>
            }
          </Row>
        </Container>
      </div>
    </>
  )
}

export default UserGraphicalView