import React, { useEffect, useState } from "react";
import { Row, Col, Modal, ModalBody, ModalFooter, Card, CardBody, Table, Button } from "reactstrap";
import moment from "moment";
import { useTranslation } from "react-i18next";

const FringeBenefitCompanyCarModel = (props: any) => {
  const { t } = useTranslation();
  let D_DATE_CAR = props.D_DATE_CAR;
  let D_CO2_CAR = props.D_CO2_CAR;
  let D_PRICE_CAR = props.D_PRICE_CAR;
  let fringeBenefitData = props.fringeBenefitData;
  let companyCarPercentile = props.companyCarPercentile;
  let COMPUTATION_YEAR = props.COMPUTATION_YEAR;

  const AGE_OF_CAR =
    COMPUTATION_YEAR && D_DATE_CAR
      ? parseInt(COMPUTATION_YEAR) - parseInt(moment(D_DATE_CAR).format("YYYY"))
      : 0;
  const AGE_OF_CAR_FINAL = AGE_OF_CAR > 6 ? 6 : AGE_OF_CAR;
  const BENEFIT_PETROL_LPG = fringeBenefitData && fringeBenefitData[1]?.["cal_" + AGE_OF_CAR_FINAL];
  const BENEFIT_PETROL_DIESEL =
    fringeBenefitData && fringeBenefitData[2]?.["cal_" + AGE_OF_CAR_FINAL];
  const BENEFIT_PETROL_ELECTRIC =
    fringeBenefitData && fringeBenefitData[3]?.["cal_" + AGE_OF_CAR_FINAL];

  const AGE_OF_CAR_1 =
    COMPUTATION_YEAR && D_DATE_CAR
      ? parseInt(COMPUTATION_YEAR) - parseInt(moment(D_DATE_CAR).format("YYYY")) + 1
      : 1;
  const AGE_OF_CAR_FINAL_1 = AGE_OF_CAR_1 > 6 ? 6 : AGE_OF_CAR_1;
  const BENEFIT_PETROL_LPG_1 =
    fringeBenefitData && fringeBenefitData[1]?.["cal_" + AGE_OF_CAR_FINAL_1];
  const BENEFIT_PETROL_DIESEL_1 =
    fringeBenefitData && fringeBenefitData[2]?.["cal_" + AGE_OF_CAR_FINAL_1];
  const BENEFIT_PETROL_ELECTRIC_1 =
    fringeBenefitData && fringeBenefitData[3]?.["cal_" + AGE_OF_CAR_FINAL_1];

  const YEARS_DATA = props.COMPUTATION_YEAR_DATA;
  const TOTAL_DAYS_IN_YEAR = props.COMPUTATION_YEAR_DAYS;

  const FORMATED_D_DATE_CAR = moment(D_DATE_CAR).format("DD/MM/YYYY");
  const MONTH_D_DATE_CAR = moment(D_DATE_CAR).format("MM");
  const MONTH_D_DATE_CAR_1 = parseInt(moment(D_DATE_CAR).format("MM")) - 1;
  const YEAR_D_DATE_CAR = moment(D_DATE_CAR).format("YYYY");

  const [fringeBenefitCalcData, setFringeBenefitCalcData] = useState<any[]>([]);
  const [fringeBenefitCalcTotalData, setFringeBenefitCalcTotalData] = useState<any>({});

  useEffect(() => {
    if (YEARS_DATA.length > 0) {
      var bik_petrol_lpg_total = 0;
      var bik_petrol_diesel_total = 0;
      var bik_petrol_electric_total = 0;

      var newData: any = [...YEARS_DATA];
      newData.map((item: any, idx: number) => {
        var bik_petrol_lpg = 0;
        if (MONTH_D_DATE_CAR_1 > item.month_no) {
          bik_petrol_lpg = (BENEFIT_PETROL_LPG / TOTAL_DAYS_IN_YEAR) * item.days_in_month;
        } else {
          bik_petrol_lpg = (BENEFIT_PETROL_LPG_1 / TOTAL_DAYS_IN_YEAR) * item.days_in_month;
        }
        bik_petrol_lpg_total = bik_petrol_lpg_total + bik_petrol_lpg;

        var bik_petrol_diesel = 0;
        if (MONTH_D_DATE_CAR_1 > item.month_no) {
          bik_petrol_diesel = (BENEFIT_PETROL_DIESEL / TOTAL_DAYS_IN_YEAR) * item.days_in_month;
        } else {
          bik_petrol_diesel = (BENEFIT_PETROL_DIESEL_1 / TOTAL_DAYS_IN_YEAR) * item.days_in_month;
        }
        bik_petrol_diesel_total = bik_petrol_diesel_total + bik_petrol_diesel;

        var bik_petrol_electric = 0;
        if (MONTH_D_DATE_CAR_1 > item.month_no) {
          bik_petrol_electric = (BENEFIT_PETROL_ELECTRIC / TOTAL_DAYS_IN_YEAR) * item.days_in_month;
        } else {
          bik_petrol_electric =
            (BENEFIT_PETROL_ELECTRIC_1 / TOTAL_DAYS_IN_YEAR) * item.days_in_month;
        }
        bik_petrol_electric_total = bik_petrol_electric_total + bik_petrol_electric;

        newData[idx] = {
          ...item,
          bik_petrol_lpg: bik_petrol_lpg,
          bik_petrol_diesel: bik_petrol_diesel,
          bik_petrol_electric: bik_petrol_electric,
        };
      });
      setFringeBenefitCalcData(newData);
      setFringeBenefitCalcTotalData({
        bik_petrol_lpg_total: bik_petrol_lpg_total,
        bik_petrol_diesel_total: bik_petrol_diesel_total,
        bik_petrol_electric_total: bik_petrol_electric_total,
      });
      props.onCalculationUpdate({
        bik_petrol_lpg_total: bik_petrol_lpg_total,
        bik_petrol_diesel_total: bik_petrol_diesel_total,
        bik_petrol_electric_total: bik_petrol_electric_total,
      });
    }
  }, [
    YEARS_DATA,
    TOTAL_DAYS_IN_YEAR,
    MONTH_D_DATE_CAR_1,
    BENEFIT_PETROL_LPG,
    BENEFIT_PETROL_LPG_1,
    BENEFIT_PETROL_DIESEL,
    BENEFIT_PETROL_DIESEL_1,
    BENEFIT_PETROL_ELECTRIC,
    BENEFIT_PETROL_ELECTRIC_1,
  ]);

  return (
    <React.Fragment>
      <Modal
        size="xl"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">Fringe benefit company car</h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label="Close"
          ></button>
        </div>

        <ModalBody>
          <Card>
            <CardBody>
              <div className="table-responsive mb-3">
                <Table className="table table-hover nowrap w-100 mb-0">
                  <thead>
                    <tr className="align-bottom">
                      <th></th>
                      <th>{t("Type of company car")}</th>
                      <th className="text-end">{t("CO2")}</th>
                      <th className="text-end">{t("Basic")} %</th>
                      <th className="text-end">{t("Increase")} %</th>
                      <th className="text-end">{t("Min")} %</th>
                      <th className="text-end">{t("Max")} %</th>
                      <th className="text-end">{t("Applicable")} %</th>
                      <th className="text-end">0</th>
                      <th className="text-end">1</th>
                      <th className="text-end">2</th>
                      <th className="text-end">3</th>
                      <th className="text-end">4</th>
                      <th className="text-end">5</th>
                      <th className="text-end">6</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fringeBenefitData &&
                      fringeBenefitData.map((item: any, idx: number) => (
                        <tr key={idx}>
                          <td>{item.value}</td>
                          <td>{item.label}</td>
                          <td className="text-end">{item.co2}</td>
                          <td className="text-end">{parseFloat(item.basic).toFixed(2)} %</td>
                          <td className="text-end">{parseFloat(item.increase).toFixed(2)} %</td>
                          <td className="text-end">{parseFloat(item.min).toFixed(2)} %</td>
                          <td className="text-end">{parseFloat(item.max).toFixed(2)} %</td>
                          <td className="text-end">{parseFloat(item.applicable).toFixed(0)} %</td>
                          <td className="text-end">{parseFloat(item.cal_0).toFixed(2)}</td>
                          <td className="text-end">{parseFloat(item.cal_1).toFixed(2)}</td>
                          <td className="text-end">{parseFloat(item.cal_2).toFixed(2)}</td>
                          <td className="text-end">{parseFloat(item.cal_3).toFixed(2)}</td>
                          <td className="text-end">{parseFloat(item.cal_4).toFixed(2)}</td>
                          <td className="text-end">{parseFloat(item.cal_5).toFixed(2)}</td>
                          <td className="text-end">{parseFloat(item.cal_6).toFixed(2)}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>

          {/* FINAL CALCULATION */}
          <Card>
            <CardBody>
              <Row>
                <Col xl={6} lg={6} md={6} sm={12}>
                  <div className="table-responsive mb-3">
                    <Table className="table table-hover nowrap w-100 mb-0">
                      <thead>
                        <tr>
                          <th style={{ width: "200px" }}>{t("Months range")}</th>
                          <th>%</th>
                        </tr>
                      </thead>
                      <tbody>
                        {companyCarPercentile &&
                          companyCarPercentile.map((item: any, idx: number) => (
                            <tr key={idx}>
                              <td style={{ width: "200px" }}>{item.months_range}</td>
                              <td>{item.months_value} %</td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Row>
                <Col xl={4} lg={4} md={4} sm={12}>
                  <div className="table-responsive mb-3">
                    <Table className="table table-hover nowrap w-100 mb-0">
                      <tbody>
                        <tr>
                          <td className="font-weight-600" style={{ width: "200px" }}>
                            {t("List price of the car")}
                          </td>
                          <td className="text-end">{D_PRICE_CAR}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-600" style={{ width: "200px" }}>
                            {t("CO2")}
                          </td>
                          <td className="text-end">{D_CO2_CAR}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-600" style={{ width: "200px" }}>
                            {t("First registration of car")}
                          </td>
                          <td className="text-end">{FORMATED_D_DATE_CAR}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-600" style={{ width: "200px" }}>
                            {t("Month of first registration of car")}
                          </td>
                          <td className="text-end">{MONTH_D_DATE_CAR}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-600" style={{ width: "200px" }}>
                            {t("Month of first registration of car")} - 1
                          </td>
                          <td className="text-end">{MONTH_D_DATE_CAR_1}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-600" style={{ width: "200px" }}>
                            {t("Year of first registration of car")}
                          </td>
                          <td className="text-end">{YEAR_D_DATE_CAR}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>

                <Col xl={4} lg={4} md={4} sm={12}>
                  <div className="table-responsive mb-3">
                    <Table className="table table-hover nowrap w-100 mb-0">
                      <tbody>
                        <tr>
                          <td className="font-weight-600" style={{ width: "200px" }}>
                            {t("Age of the car")}
                          </td>
                          <td className="text-end">{AGE_OF_CAR}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-600" style={{ width: "200px" }}>
                            {t("Related benefit in kind Petrol/LPG")}
                          </td>
                          <td className="text-end">{parseFloat(BENEFIT_PETROL_LPG).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-600" style={{ width: "200px" }}>
                            {t("Related benefit in kind Diesel")}
                          </td>
                          <td className="text-end">
                            {parseFloat(BENEFIT_PETROL_DIESEL).toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td className="font-weight-600" style={{ width: "200px" }}>
                            {t("Related benefit in kind Electric")}
                          </td>
                          <td className="text-end">
                            {parseFloat(BENEFIT_PETROL_ELECTRIC).toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>

                <Col xl={4} lg={4} md={4} sm={12}>
                  <div className="table-responsive mb-3">
                    <Table className="table table-hover nowrap w-100 mb-0">
                      <tbody>
                        <tr>
                          <td className="font-weight-600" style={{ width: "200px" }}>
                            {t("Age of the car")} + 1
                          </td>
                          <td className="text-end">{AGE_OF_CAR_1}</td>
                        </tr>
                        <tr>
                          <td className="font-weight-600" style={{ width: "200px" }}>
                            {t("Related benefit in kind Petrol/LPG")}
                          </td>
                          <td className="text-end">
                            {parseFloat(BENEFIT_PETROL_LPG_1).toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td className="font-weight-600" style={{ width: "200px" }}>
                            {t("Related benefit in kind Diesel")}
                          </td>
                          <td className="text-end">
                            {parseFloat(BENEFIT_PETROL_DIESEL_1).toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <td className="font-weight-600" style={{ width: "200px" }}>
                            {t("Related benefit in kind Electric")}
                          </td>
                          <td className="text-end">
                            {parseFloat(BENEFIT_PETROL_ELECTRIC_1).toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <div className="table-responsive mb-3">
                <Table className="table table-hover nowrap w-100 mb-0">
                  <thead>
                    <tr>
                      <th rowSpan={2}></th>
                      <th rowSpan={2}>{t("Month")}</th>
                      <th rowSpan={2} className="text-end">
                        {t("Calendar days")}
                      </th>
                      <th colSpan={3} className="text-center">
                        {t("BIK per month")}
                      </th>
                    </tr>
                    <tr>
                      <th className="text-end">{t("Petrol / LPG")}</th>
                      <th className="text-end">{t("Diesel")}</th>
                      <th className="text-end">{t("Electric")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fringeBenefitCalcData &&
                      fringeBenefitCalcData.map((item: any, idx: number) => {
                        return (
                          <tr key={idx}>
                            <td>{item?.month_no}</td>
                            <td>{item?.month_name}</td>
                            <td className="text-end">{item?.days_in_month}</td>
                            <td className="text-end">
                              {parseFloat(item?.bik_petrol_lpg).toFixed(2)}
                            </td>
                            <td className="text-end">
                              {parseFloat(item?.bik_petrol_diesel).toFixed(2)}
                            </td>
                            <td className="text-end">
                              {parseFloat(item?.bik_petrol_electric).toFixed(2)}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th colSpan={2}>{t("Total")}</th>
                      <th className="text-end">{TOTAL_DAYS_IN_YEAR}</th>
                      <th className="text-end">
                        {fringeBenefitCalcTotalData &&
                          parseFloat(fringeBenefitCalcTotalData?.bik_petrol_lpg_total).toFixed(2)}
                      </th>
                      <th className="text-end">
                        {fringeBenefitCalcTotalData &&
                          parseFloat(fringeBenefitCalcTotalData?.bik_petrol_diesel_total).toFixed(
                            2
                          )}
                      </th>
                      <th className="text-end">
                        {fringeBenefitCalcTotalData &&
                          parseFloat(fringeBenefitCalcTotalData?.bik_petrol_electric_total).toFixed(
                            2
                          )}
                      </th>
                    </tr>
                  </tfoot>
                </Table>
              </div>
            </CardBody>
          </Card>
        </ModalBody>

        <ModalFooter>
          <Button color="danger" className="btn-block" type="reset" onClick={props.onCloseClick}>
            {t("Close")}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default FringeBenefitCompanyCarModel;
