export const GET_USER = "GET_USER"
export const GET_USER_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USER_ERROR = "GET_USERS_ERROR"

export const GET_USER_PROFILE = "GET_USER_PROFILE"
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILES_SUCCESS"
export const GET_USER_PROFILE_ERROR = "GET_USER_PROFILES_ERROR"

export const GET_USERS_LIST = "GET_USERS_LIST"
export const GET_USERS_LIST_SUCCESS = "GET_USERS_LIST_SUCCESS"
export const GET_USERS_LIST_ERROR = "GET_USERS_LIST_ERROR"
  
export const CREATE_USER = "CREATE_USER"
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS"
export const CREATE_USER_ERROR = "CREATE_USER_ERROR"

export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR"

export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_ERROR = "DELETE_USER_ERROR"

export const GET_USER_GENERAL = "GET_USER_GENERAL"
export const GET_USER_GENERAL_SUCCESS = "GET_USER_GENERAL_SUCCESS"
export const GET_USER_GENERAL_ERROR = "GET_USER_GENERAL_ERROR"
