export const GET_USER_FUNCTION = "GET_USER_FUNCTION"
export const GET_USER_FUNCTION_SUCCESS = "GET_USER_FUNCTION_SUCCESS"
export const GET_USER_FUNCTION_ERROR = "GET_USER_FUNCTION_ERROR"

export const GET_USER_FUNCTION_LIST = "GET_USER_FUNCTION_LIST"
export const GET_USER_FUNCTION_LIST_SUCCESS = "GET_USER_FUNCTION_LIST_SUCCESS"
export const GET_USER_FUNCTION_LIST_ERROR = "GET_USER_FUNCTION_LIST_ERROR"
  
export const CREATE_USER_FUNCTION = "CREATE_USER_FUNCTION"
export const CREATE_USER_FUNCTION_SUCCESS = "CREATE_USER_FUNCTION_SUCCESS"
export const CREATE_USER_FUNCTION_ERROR = "CREATE_USER_FUNCTION_ERROR"

export const UPDATE_USER_FUNCTION = "UPDATE_USER_FUNCTION"
export const UPDATE_USER_FUNCTION_SUCCESS = "UPDATE_USER_FUNCTION_SUCCESS"
export const UPDATE_USER_FUNCTION_ERROR = "UPDATE_USER_FUNCTION_ERROR"

export const DELETE_USER_FUNCTION = "DELETE_USER_FUNCTION"
export const DELETE_USER_FUNCTION_SUCCESS = "DELETE_USER_FUNCTION_SUCCESS"
export const DELETE_USER_FUNCTION_ERROR = "DELETE_USER_FUNCTION_ERROR"