import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'reactstrap';
import WidgetBasic from '../../../../components/Widgets/Basic';
import { getUserProfile, yearsOnward } from '../../../../helpers/functions_helper';
import mastersCompanyService from '../../../../services/masters.company.service';
import { serialize } from '../../../../utils/utils';
interface CountsProps extends CartProps {
    filters?: any;
    widgetId?: 'active_inactive' | 'company_year_data' | 'company_quarter_data' | 'archieve_company' | 'All';
}
const years = yearsOnward();

const Counts: FC<CountsProps> = ({ hideHeading, filters = null, widgetId = 'All' }) => {

    const { t } = useTranslation();
    const userObj = getUserProfile();

    const inititalFilter = {
        week: null,
        quarter: null,
        month: null,
        year: null,
        user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_ID : null
    };

    const [loading, setLoading] = useState<boolean>(true);
    const [query, setQuery] = useState<string | null>(serialize(inititalFilter));
    const [filter, setFilter] = useState<any>(inititalFilter);
    const [data, setData] = useState<any>({
        active: 0,
        inactive: 0,
        archieve: 0,
        non_archieve:0,
        company_quarter_data:[],
        company_year_data:[],
    })

    const getChartData = async () => {
        const result = await mastersCompanyService.getChartData(query);
        if (result.data.status === 'success') {
            setData((data: any) => ({
                ...data,
                active: result?.data?.data?.active,
                inactive: result?.data?.data?.inactive,
                archieve: result?.data?.data?.archieve,
                non_archieve: result?.data?.data?.non_archieve,
                company_quarter_data: result?.data?.data?.company_quarter_data,
                company_year_data: result?.data?.data?.company_year_data,
            }));
        }
        setLoading(false);
    }

    useEffect(() => {
        loading && getChartData();
    }, [loading])

    useEffect(() => {
        setQuery(serialize(filter));
        setLoading(true);
    }, [filter])

    useEffect(() => {
        filters && setFilter(filters)
    }, [filters])

    return (
        <>
            {/* {(widgetId === 'All' || widgetId === 'active_inactive') &&
                <Col>
                    <WidgetBasic
                        variant='primary'
                        label={t("All company")}
                        value={(data?.active + data?.inactive)}
                        icon={<i className="fa fa-solid fa-file-invoice"></i>}
                        loading={loading}
                        seconday={[
                            {
                                label: `${t("Active")}`,
                                value: (data?.active)
                            },
                            {
                                label: `${t("Non-active")}`,
                                value: (data?.inactive)
                            }
                        ]}
                    />
                </Col>
            } */}
            {(widgetId === 'All' || widgetId === 'company_year_data') &&
                <Col>
                    <WidgetBasic
                        variant='warning'
                        label={t("Total company per year")}
                        value={(data?.active + data?.inactive)}
                        icon={<i className="fa fa-solid fa-clipboard-check"></i>}
                        loading={loading}
                        seconday={data?.company_year_data}
                    />
                </Col>
            }
            {(widgetId === 'All' || widgetId === 'company_quarter_data') &&
                <Col>
                    <WidgetBasic
                        variant='info'
                        label={t("Created company per quarter")}
                        value={(data?.active + data?.inactive)}
                        seconday={data?.company_quarter_data}
                        icon={<span className="material-symbols-outlined icon">schedule</span>}
                        loading={loading}
                    />
                </Col>
            }
            {(widgetId === 'All' || widgetId === 'archieve_company') &&
                <Col>
                    <WidgetBasic
                        variant='success'
                        label={t("All company")}
                        value={(data?.archieve + data?.non_archieve)}
                        seconday={[
                            {
                                label: `${t('Archieved')}`,
                                value: (data?.archieve)
                            },
                            {
                                label: `${t('Non-Archieved')}`,
                                value: (data?.non_archieve)
                            }
                        ]}
                        icon={<i className="fa fa-solid fa-calendar-check"></i>}
                        loading={loading}
                    />
                </Col>
            }
        </>
    )
}

export default Counts;