import {
  GET_TASK_TYPE,
  GET_TASK_TYPE_SUCCESS,
  GET_TASK_TYPE_ERROR,

  GET_TASK_TYPES_LIST,
  GET_TASK_TYPES_LIST_SUCCESS,
  GET_TASK_TYPES_LIST_ERROR,

  CREATE_TASK_TYPE,
  CREATE_TASK_TYPE_SUCCESS,
  CREATE_TASK_TYPE_ERROR,

  UPDATE_TASK_TYPE,
  UPDATE_TASK_TYPE_SUCCESS,
  UPDATE_TASK_TYPE_ERROR,

  DELETE_TASK_TYPE,
  DELETE_TASK_TYPE_SUCCESS,
  DELETE_TASK_TYPE_ERROR,
} from "./actionTypes";

// ---------------------------------------------GET_TASK_TYPE
export const getTaskType = (tasktypes: any, history: any) => {
  return {
    type: GET_TASK_TYPE,
    payload: { tasktypes, history },
  };
};

export const getTaskTypeSuccess = (data: {}) => {
  return {
    type: GET_TASK_TYPE_SUCCESS,
    payload: data,
  };
};

export const getTaskTypeError = (error: {}) => {
  return {
    type: GET_TASK_TYPE_ERROR,
    payload: error,
  };
};

// ---------------------------------------------GET_TASK_TYPES_LIST
export const getTaskTypesList = (query: any, history: any) => {
  return {
    type: GET_TASK_TYPES_LIST,
    payload: {query, history},
  };
};

export const getTaskTypesListSuccess = (data: {}) => {
  return {
    type: GET_TASK_TYPES_LIST_SUCCESS,
    payload: data,
  };
};

export const getTaskTypesListError = (error: {}) => {
  return {
    type: GET_TASK_TYPES_LIST_ERROR,
    payload: error,
  };
};

// ---------------------------------------------CREATE_TASK_TYPE
export const createTaskType = (tasktypes: any, history: any) => {
  return {
    type: CREATE_TASK_TYPE,
    payload: { tasktypes, history },
  };
};

export const createTaskTypeSuccess = (tasktypes: {}) => {
  return {
    type: CREATE_TASK_TYPE_SUCCESS,
    payload: tasktypes,
  };
};

export const createTaskTypeError = (error: {}) => {
  return {
    type: CREATE_TASK_TYPE_ERROR,
    payload: error,
  };
};

// ---------------------------------------------UPDATE_TASK_TYPE
export const updateTaskType = (tasktypes: any, history: any) => {
  return {
    type: UPDATE_TASK_TYPE,
    payload: { tasktypes, history },
  };
};

export const updateTaskTypeSuccess = (tasktypes: {}) => {
  return {
    type: UPDATE_TASK_TYPE_SUCCESS,
    payload: tasktypes,
  };
};

export const updateTaskTypeError = (error: {}) => {
  return {
    type: UPDATE_TASK_TYPE_ERROR,
    payload: error,
  };
};

// ---------------------------------------------DELETE_TASK_TYPE
export const deleteTaskType = (tasktypes: any, history: any) => {
  return {
    type: DELETE_TASK_TYPE,
    payload: { tasktypes, history },
  };
};

export const deleteTaskTypeSuccess = (tasktypes: {}) => {
  return {
    type: DELETE_TASK_TYPE_SUCCESS,
    payload: tasktypes,
  };
};

export const deleteTaskTypeError = (error: {}) => {
  return {
    type: DELETE_TASK_TYPE_ERROR,
    payload: error,
  };
};
