import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Nav, NavItem } from "reactstrap";
import { Link } from "react-router-dom";

// Import Breadcrumb
import Breadcrumbs from "../../components/Layout/Breadcrumb";
import UsersTaxes from "./Taxes";

import ScrollUp from "../../components/ScrollUpDown/ScrollUp";
import ScrollDown from "../../components/ScrollUpDown/ScrollDown";

import { useTranslation } from "react-i18next";
import { getUserProfile } from "../../helpers/functions_helper";

import NavItems from "./NavItems";
const EditUserTaxes = (props: any) => {
  const {t} = useTranslation();
  document.title = `${t("User details")} - ${t("Taxes")} | ${process.env.REACT_APP_NAME}`;
  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "/users/all-users",
      label: t("Users"),
      active: false,
    },
    {
      url: "#",
      label: t("Update user"),
      active: true,
    },
  ];
  
  const { id, user_id } = useParams();

  return (
    <React.Fragment>
      <ScrollUp />
      <ScrollDown />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={t("Update user")}
            breadcrumbItems={breadcrumbItems}
          />

          <NavItems id={id} user_id={user_id} />
          <UsersTaxes
            user_id={id}
            user_id_code={user_id}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditUserTaxes;
