import React, { useEffect, useState } from "react";
import { Row, Col, Modal, ModalBody, ModalFooter, Card, CardBody, Table, Button } from "reactstrap";
import { vLookup } from "../../helpers/functions_helper";
import { formatCurrency } from "../../utils/formats";
import { useTranslation } from "react-i18next";
import { renderRowHTML } from "../../utils/utils";

const EmployeeDirectorIncomeModel = (props: any) => {
  const { t } = useTranslation();
  let spCountryData = props.spCountryData;
  let D_INCOME_TOTAL = props.D_INCOME_TOTAL;
  let revaluationFactor = props.revaluationFactor;
  let SS_SOCIAL_SECURITY = props.SS_SOCIAL_SECURITY;

  let D_DIRECTOR_INCOME_TOTAL_SS = D_INCOME_TOTAL?.D_DIRECTOR_INCOME_TOTAL * revaluationFactor;

  const [LEGAL_PREMIUM_ANNUALLY, setLEGAL_PREMIUM_ANNUALLY] = useState<number>(0); // G129
  const [LEGAL_PREMIUM_QUARTERLY, setLEGAL_PREMIUM_QUARTERLY] = useState<number>(0); // G130
  const [LEGAL_PREMIUM_ANNUALLY_WITH_ADM_FEE, setLEGAL_PREMIUM_ANNUALLY_WITH_ADM_FEE] =
    useState<number>(0); // H129 // TO SEND BACK FOR CALCULATION
  const [LEGAL_PREMIUM_QUARTERLY_WITH_ADM_FEE, setLEGAL_PREMIUM_QUARTERLY_WITH_ADM_FEE] =
    useState<number>(0); // H130

  useEffect(() => {
    if (D_DIRECTOR_INCOME_TOTAL_SS === 0) {
      setLEGAL_PREMIUM_ANNUALLY(0);
      setLEGAL_PREMIUM_QUARTERLY(0);
      setLEGAL_PREMIUM_ANNUALLY_WITH_ADM_FEE(0);
      setLEGAL_PREMIUM_QUARTERLY_WITH_ADM_FEE(0);
    } else {
      let VLOOKUP_4 = vLookup(D_DIRECTOR_INCOME_TOTAL_SS, SS_SOCIAL_SECURITY, 4);
      let VLOOKUP_3 = vLookup(D_DIRECTOR_INCOME_TOTAL_SS, SS_SOCIAL_SECURITY, 3) / 100;
      let VLOOKUP_1 = vLookup(D_DIRECTOR_INCOME_TOTAL_SS, SS_SOCIAL_SECURITY, 1);

      let VLOOKUP_1_CALC = 0;
      if (D_DIRECTOR_INCOME_TOTAL_SS < SS_SOCIAL_SECURITY[2][0]) {
        VLOOKUP_1_CALC = D_DIRECTOR_INCOME_TOTAL_SS - VLOOKUP_1;
      } else {
        VLOOKUP_1_CALC = D_DIRECTOR_INCOME_TOTAL_SS;
      }

      let G129 = VLOOKUP_4 + VLOOKUP_3 * VLOOKUP_1_CALC;
      let G130 = (VLOOKUP_4 + VLOOKUP_3 * VLOOKUP_1_CALC) / 4;
      setLEGAL_PREMIUM_ANNUALLY(G129);
      setLEGAL_PREMIUM_QUARTERLY(G130);

      const F122 = SS_SOCIAL_SECURITY[0][3];
      if (G129 === F122) {
        setLEGAL_PREMIUM_ANNUALLY_WITH_ADM_FEE(G129);
        setLEGAL_PREMIUM_QUARTERLY_WITH_ADM_FEE(G130);
      } else {
        setLEGAL_PREMIUM_ANNUALLY_WITH_ADM_FEE(G129 * 1.0305);
        setLEGAL_PREMIUM_QUARTERLY_WITH_ADM_FEE(G130 * 1.0305);
      }
    }
  }, [D_DIRECTOR_INCOME_TOTAL_SS]);

  useEffect(() => {
    props.onCalculationUpdate({
      LEGAL_PREMIUM_ANNUALLY_WITH_ADM_FEE: LEGAL_PREMIUM_ANNUALLY_WITH_ADM_FEE,
    });
  }, [LEGAL_PREMIUM_ANNUALLY_WITH_ADM_FEE]);

  return (
    <React.Fragment>
      <Modal
        size="xl"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">{t("Employee or director income")}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label={t("Close")}
          ></button>
        </div>

        <ModalBody>
          <Card>
            <CardBody>
              <div className="table-responsive mb-3">
                <Table className="table table-hover nowrap w-100 mb-0">
                  <thead>
                    <tr>
                      <th></th>
                      {spCountryData &&
                        spCountryData.map((item: any, idx: number) => (
                          <th key={`country_name_${idx}`} className="text-end">
                            {item?.country_name}
                          </th>
                        ))}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="font-weight-600">Output</td>
                      {spCountryData &&
                        spCountryData.map((item: any, idx: number) => (
                          <td key={`emp_dir_${idx}`} className="text-end">
                            {item?.emp_dir}
                          </td>
                        ))}
                      <td></td>
                    </tr>
                    <tr>
                      <td className="font-weight-600">{t("Employee income")}</td>
                      {spCountryData &&
                        spCountryData.map((item: any, idx: number) => (
                          <td key={`D_EMPLOYEE_INCOME_${idx}`} className="text-end">
                            {formatCurrency(parseFloat(item?.D_EMPLOYEE_INCOME).toFixed(2), false)}
                          </td>
                        ))}
                      <td className="text-end">
                        {formatCurrency(
                          parseFloat(D_INCOME_TOTAL?.D_EMPLOYEE_INCOME_TOTAL).toFixed(2),
                          false
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-weight-600">{t("Director income")}</td>
                      {spCountryData &&
                        spCountryData.map((item: any, idx: number) => (
                          <td key={`D_DIRECTOR_INCOME_${idx}`} className="text-end">
                            {formatCurrency(parseFloat(item?.D_DIRECTOR_INCOME).toFixed(2), false)}
                          </td>
                        ))}
                      <td className="text-end">
                        {formatCurrency(
                          parseFloat(D_INCOME_TOTAL?.D_DIRECTOR_INCOME_TOTAL).toFixed(2),
                          false
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-weight-600">{t("SS premium self-employed")}</td>
                      {spCountryData &&
                        spCountryData.map((item: any, idx: number) => (
                          <td key={`SS_PREMIUM_SELF_EMPLOYED_${idx}`} className="text-end">
                            {formatCurrency(
                              parseFloat(item?.SS_PREMIUM_SELF_EMPLOYED).toFixed(2),
                              false
                            )}
                          </td>
                        ))}
                      <td className="text-end">
                        {formatCurrency(
                          parseFloat(D_INCOME_TOTAL?.SS_PREMIUM_SELF_EMPLOYED_TOTAL).toFixed(2),
                          false
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              <div className="table-responsive mb-3">
                <Table className="table table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr>
                      <td className="font-weight-600">
                        {t("Total employee inc.-subject to SS (without car)")}
                      </td>
                      <td className="text-end">
                        {formatCurrency(
                          parseFloat(D_INCOME_TOTAL?.D_EMPLOYEE_INCOME_TOTAL).toFixed(2),
                          false
                        )}
                      </td>

                      <td></td>

                      <td className="font-weight-600">{t("Total director income (with car)")}</td>
                      <td className="text-end">
                        {formatCurrency(
                          parseFloat(D_INCOME_TOTAL?.D_DIRECTOR_INCOME_TOTAL).toFixed(2),
                          false
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="font-weight-600">
                        {t("Total director income (revalorised for SS)")}
                      </td>
                      <td className="text-end">
                        {formatCurrency(D_DIRECTOR_INCOME_TOTAL_SS.toFixed(2), false)}
                      </td>

                      <td></td>

                      <td className="font-weight-600">{t("Revaluation coefficient")}</td>
                      <td className="text-end">
                        {formatCurrency(parseFloat(revaluationFactor), false)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Row>
                <Col className="col-12 mb-3">
                  {t(
                    "The premium of social security in this spreadsheet is computed on the basis of the gross income and should therefore be considered as an estimate. In practice, this self-employed social security premium is computed on the taxable income (gross income less social security less business expeneses) of 3 years ago."
                  )}
                </Col>

                <Col xl={6} lg={6} md={6} sm={12}>
                  <div className="table-responsive mb-3">
                    <Table className="table table-hover nowrap w-100 mb-0">
                      <tbody>
                        {SS_SOCIAL_SECURITY &&
                          SS_SOCIAL_SECURITY.map((item: any, idx: number) => (
                            <tr className="align-bottom" key={idx}>
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[0]).toFixed(2), false)}
                              </td>
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[1]).toFixed(2), false)}
                              </td>
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[2]).toFixed(2), false)} %
                              </td>
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[3]).toFixed(2), false)}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>

                <Col xl={6} lg={6} md={6} sm={12}>
                  <div className="table-responsive mb-3">
                    <Table className="table table-hover nowrap w-100 mb-0">
                      <thead>
                        <tr>
                          <th></th>
                          <th className="text-end">
                            {renderRowHTML(t("Legal <br/>premium"), null)}
                          </th>
                          <th className="text-end">
                            {renderRowHTML(t("With <br/>admin fee"), null)}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {renderRowHTML(
                              t("Social security as self-employed <br/>(annually)"),
                              null
                            )}
                          </td>
                          <td className="text-end">
                            {formatCurrency(LEGAL_PREMIUM_ANNUALLY.toFixed(2), false)}
                          </td>
                          <td className="text-end">
                            {formatCurrency(LEGAL_PREMIUM_ANNUALLY_WITH_ADM_FEE.toFixed(2), false)}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {renderRowHTML(
                              t("Social security as self-employed <br/>(quarterly)"),
                              null
                            )}
                          </td>
                          <td className="text-end">
                            {formatCurrency(LEGAL_PREMIUM_QUARTERLY.toFixed(2), false)}
                          </td>
                          <td className="text-end">
                            {formatCurrency(LEGAL_PREMIUM_QUARTERLY_WITH_ADM_FEE.toFixed(2), false)}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </ModalBody>

        <ModalFooter>
          <Button color="danger" className="btn-block" type="reset" onClick={props.onCloseClick}>
            {t("Close")}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default EmployeeDirectorIncomeModel;
