import React, { useEffect, useRef, useState } from 'react'
import { cloneDeep } from "lodash";
import { Col, FormGroup, Input, InputGroup, Label, Row, Table } from 'reactstrap';
import RadioInput from '../RadioInput';
import CheckboxMultiInput from './CheckboxMultiInput';
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { getSelectOptions, onlyPositiveValue } from '../../../helpers/functions_helper';
import { civil_status_master, genderOptions, languageOptions } from '../../../common/staticdata';
import AddSignature from '../../../components/Common/AddSignature';
const TableMultiInput = (props: any) => {
    const { input_type, label, handleMultiInputChange, reference_name, user_data_answer, is_disabled, multi_input_data, country_options, user_id, user_code, section_id, showSign, questionnaireID, questionnaireSubID, questionrow, adminview, handleSignatureData } = props;
    const [rowsinput, setrowsinput] = useState<any>({ data: [] });
    const rawData = useRef([]);
    const [countryOptions, setCountryOptions] = useState([]);
    const [signatureShow, setSignatureShow] = useState(false);
    useEffect(() => {
        getSelectOptions("tbl_master_country", "name").then(function (options: any) {
            setCountryOptions(options);
        });
    }, []);
    useEffect(() => {
        if (multi_input_data != null) {
            rawData.current = cloneDeep(multi_input_data);
            if (user_data_answer && user_data_answer?.length > 0 && Array.isArray(user_data_answer)) {
                setrowsinput({ data: user_data_answer });
            }
            else if (multi_input_data && multi_input_data.length > 0 && multi_input_data[0][0] !== undefined) {
                setrowsinput({ data: multi_input_data });
            }
            else {
                let row_data: any = [[]];
                if (multi_input_data.length > 0) {
                    multi_input_data.forEach((dt: any, index: any) => {
                        if (!row_data[dt.array_row]) {
                            row_data[dt.array_row] = [];
                        }
                        row_data[dt.array_row].push(dt);
                    });
                    setrowsinput({ data: row_data });
                }
            }
        }
    }, [multi_input_data])

    function renderRowHTML(rowContent: any) {
        return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
    }

    function htmlDecode(input: any) {
        var doc = new DOMParser().parseFromString(input, "text/html");
        return doc.documentElement.textContent;
    }

    function handleAddRowQuestionNested() {
        let data = cloneDeep(rawData.current);
        setrowsinput((prevState: any) => ({ data: [...prevState.data, data] }))
    }

    function handleRemoveRowQuestion(formrow: any) {
        var modifiedRows: any = { ...rowsinput };
        modifiedRows.data.splice(formrow, 1);
        setrowsinput(modifiedRows);
        handleMultiInputChange(modifiedRows, reference_name);
    }

    function handleinput(e: any, type: any, key: any, masterkey: number) {
        var modifiedRows: any = { ...rowsinput };
        if (type.input_type === 16 && type.input_type === 3) {
            modifiedRows.data[masterkey][key].value = e.target.checked;
        }
        else if (type.input_type === 14) {
            modifiedRows.data[masterkey][key].value = e.value;
        }
        else if (type.input_type === 16) {
            modifiedRows.data[masterkey][key].value = e.target.checked;
        }
        else {
            modifiedRows.data[masterkey][key].value = e.target.value;
        }
        //setrowsinput(modifiedRows);
        handleMultiInputChange(modifiedRows, reference_name);
    }

    return (
        <Col xs={12} className={is_disabled ? "multiinput-table disabled-table mt-4": "multiinput-table"}>
            <Label>
             {renderRowHTML(label)}
            </Label>
            <Table className="table table-bordered w-100">
                <tbody>
                    {
                        rowsinput['data']?.length > 0 &&
                        (rowsinput['data'] || []).map((_item: any, indefx: number) => {
                            return (
                                <tr>
                                    {typeof _item == 'object' &&
                                        (_item || []).map((formRow: any, key: number) =>
                                        (
                                            <td className={(formRow.input_type == 0 && (formRow.question == "" || formRow.question == null)) ? "table-null-val" : (_item[key + 1] && _item[key + 1].input_type == 0 && (_item[key + 1].question == "" || _item[key + 1].question == null)) ? 'pre-null-val' : ""}>
                                                <Col sm={formRow.input_type == 3 || formRow.input_type == 6 || formRow.input_type == 2 || input_type == 21 ? 12 : 6} className={`mx_${formRow.input_type}`} key={key}>
                                                    {formRow.input_type == 0 &&
                                                        <Col lg={12}>
                                                            <Row className="mt-3">
                                                                <p className='text-left form-label'>{renderRowHTML(formRow.question)}</p>
                                                            </Row>
                                                        </Col>
                                                    }
                                                    {formRow.input_type == 1 &&
                                                        <FormGroup>
                                                            <Input
                                                                type="text"
                                                                id="name"
                                                                name={formRow.reference}
                                                                className='form-control'
                                                                value={rowsinput['data'][indefx][key].value}
                                                                onChange={e => {
                                                                    handleinput(e, formRow, key, indefx);
                                                                }}
                                                                disabled={is_disabled}
                                                            />
                                                        </FormGroup>
                                                    }

                                                    {formRow.input_type == 2 &&
                                                        <FormGroup>
                                                            <RadioInput
                                                                label={null}
                                                                name={formRow.reference_name+'_'+key+'_'+indefx}
                                                                handleChangeMulti={(e: any) => handleinput(e, formRow, key, indefx)}
                                                                value={formRow.value ? formRow.value : ''}
                                                                isDisabled={is_disabled}
                                                            //answer_only={formRow.value}
                                                            />
                                                        </FormGroup>
                                                    }

                                                    {formRow.input_type == 3 &&
                                                        <FormGroup>
                                                            <InputGroup className="float-start">
                                                                <div style={{ 'flex': '0 0 100%' }}>
                                                                    <CheckboxMultiInput
                                                                        label={null}
                                                                        options={typeof formRow.options == 'object' && Object.keys(formRow.options).map(k => formRow.options[k].value)}
                                                                        name={formRow?.reference_name}
                                                                        handleChangeMulticheckbox={(e: any) => handleinput(e, formRow, key, indefx)}
                                                                        value={formRow?.value ? formRow.value : ''}
                                                                        isMulti={false}
                                                                        //fullwidth={field_width}
                                                                        isDisabled={is_disabled}
                                                                        answer_only={is_disabled}
                                                                    />
                                                                </div>
                                                            </InputGroup>
                                                        </FormGroup>
                                                    }


                                                    {formRow.input_type == 4 &&
                                                        <FormGroup>
                                                            <InputGroup className="float-start">
                                                                <Flatpickr
                                                                    id="marriage_date"
                                                                    name={formRow.reference}
                                                                    className="form-control d-block width-input"
                                                                    type="text"
                                                                    value={rowsinput['data'][indefx][key].value}
                                                                    onChange={(e) => {
                                                                        let xObject = { target: { value: e[0] } };
                                                                        handleinput(xObject, formRow, key, indefx)
                                                                    }
                                                                    }
                                                                    options={{
                                                                        altInput: true,
                                                                        altFormat: "d/m/Y",
                                                                        dateFormat: "Y-m-d H:i:S",
                                                                        maxDate: "today",
                                                                        allowInput: true,
                                                                        locale: { firstDayOfWeek: 1 }
                                                                    }}
                                                                    disabled={is_disabled}
                                                                />
                                                                <span className="input-group-text">
                                                                    <span className="align-middle material-symbols-outlined fw-light fs-4">calendar_today</span>
                                                                </span>
                                                            </InputGroup>

                                                        </FormGroup>
                                                    }
                                                    {
                                                        formRow.input_type == 5 &&
                                                        <FormGroup>
                                                            <InputGroup className="float-start">
                                                                <div style={{ 'flex': '0 0 100%' }}>
                                                                    <Input
                                                                        type="select"
                                                                        className="form-control"
                                                                        style={{ 'minWidth': '100px' }}
                                                                        value={rowsinput['data'][indefx][key].value}
                                                                        onChange={(e) => handleinput(e, formRow, key, indefx)}
                                                                        disabled={is_disabled}
                                                                    >
                                                                        {typeof formRow.options == 'object' && formRow.options.map((_item: any, k: number) => <option value={_item.value} key={k}>{_item.label}</option>)}
                                                                    </Input>
                                                                </div>
                                                            </InputGroup>
                                                        </FormGroup>
                                                    }

                                                    {
                                                        formRow.input_type == 6 &&
                                                        <>

                                                            <CheckboxMultiInput
                                                                label={null}
                                                                options={typeof formRow.options == 'object' && Object.keys(formRow.options).map(k => formRow.options[k].value)}
                                                                name={formRow.reference_name}
                                                                handleChangeMulticheckbox={(e: any) => handleinput(e, formRow, key, indefx)}
                                                                value={formRow.value ? formRow.value : ''}
                                                                isMulti
                                                                //fullwidth={field_width}
                                                                isDisabled={is_disabled}
                                                            //answer_only={answerOnly} 
                                                            />
                                                        </>

                                                    }

                                                    {
                                                        formRow.input_type == 7 &&
                                                        <Row>
                                                            <p className='text-left text-primary'>{formRow.question ? renderRowHTML(formRow.question) : ""}</p>
                                                        </Row >
                                                    }
                                                    {
                                                        formRow.input_type == 8 &&
                                                        <Row>
                                                            <Col className="col-12">
                                                                <div className="mb-3">
                                                                    {/* <Dropzone
                                      moduleData={{
                                        module_app_id: 6,
                                        module_main_id: questionnaireID,
                                        module_sub_id: questionnaireSubID,
                                        section_id: section_id,
                                        file_path: "questionnaire",
                                      }}
                                      fetchData={true}
                                      isOpen={false}
                                      isDisabled={!adminview}
                                    /> */}
                                                                </div >
                                                            </Col >
                                                        </Row >
                                                    }
                                                    {
                                                        formRow.input_type == 10 &&
                                                        <Row>
                                                            <b><p className='text-left '>{renderRowHTML(htmlDecode(formRow.question))}</p></b>
                                                        </Row >
                                                    }
                                                    {
                                                        formRow.input_type == 11 &&
                                                        <FormGroup>
                                                            <InputGroup className="float-start">
                                                                <Input
                                                                    name={formRow.reference}
                                                                    className="form-control"
                                                                    type="number"
                                                                    bsSize="sm"
                                                                    step={"1"}
                                                                    value={rowsinput['data'][indefx][key].value}
                                                                    onChange={(e) => handleinput(e, formRow, key, indefx)}
                                                                    min={0}
                                                                    max={10000000000}
                                                                    onKeyPress={(e) => onlyPositiveValue(e)}
                                                                    disabled={is_disabled}
                                                                />
                                                                <div className="input-group-text">€</div>
                                                            </InputGroup>
                                                        </FormGroup>
                                                    }
                                                    {formRow.input_type == 12 &&
                                                        <FormGroup>
                                                            <InputGroup className="float-start">
                                                                <Input
                                                                    name={formRow.reference}
                                                                    className="form-control"
                                                                    type="number"
                                                                    step={".01"}
                                                                    value={rowsinput['data'][indefx][key].value}
                                                                    onChange={(e) => handleinput(e, formRow, key, indefx)}
                                                                    min={0}
                                                                    max={100}
                                                                    disabled={is_disabled}
                                                                />
                                                                <div className="input-group-text">€</div>
                                                            </InputGroup>
                                                        </FormGroup>
                                                    }

                                                    {
                                                        formRow.input_type == 13 &&
                                                        <div className='mb-3'>
                                                            <textarea
                                                                className="form-control"
                                                                id="commentmessage-input"
                                                                name={formRow.reference_name}
                                                                onChange={(e) => handleinput(e, formRow, key, indefx)}
                                                                value={rowsinput['data'][indefx][key].value}
                                                                disabled={is_disabled}
                                                            ></textarea>
                                                        </div>
                                                    }

                                                    {
                                                        formRow.input_type == 14 &&
                                                        <div className="mb-3">
                                                            <Select
                                                                id="country"
                                                                name={formRow.reference_name}
                                                                className="select-dropdown"
                                                                options={countryOptions}
                                                                value={
                                                                    user_data_answer
                                                                        ? countryOptions && countryOptions.find(
                                                                            (option: any) =>
                                                                                option.value == rowsinput['data'][indefx][key].value
                                                                        )
                                                                        : ""
                                                                }
                                                                isSearchable={true}
                                                                onChange={(e: any) => {
                                                                    handleinput(e, formRow, key, indefx);
                                                                }}
                                                                isDisabled={is_disabled}
                                                            />
                                                        </div>
                                                    }
                                                    {
                                                        formRow.input_type == 15 &&
                                                        <Row>
                                                            <p className='text-left '>{renderRowHTML(htmlDecode(formRow.question))}</p>
                                                        </Row>
                                                    }
                                                    {
                                                        formRow.input_type == 16 &&
                                                        <Col lg={12}>
                                                            <div className='form-check form-check-primary mb-3'>
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id={formRow.reference_name}
                                                                    name={formRow.reference_name}
                                                                    onChange={(e) => handleinput(e, formRow, key, indefx)}
                                                                    checked={rowsinput['data'][indefx][key].value ? true : false}
                                                                    disabled={is_disabled}
                                                                />

                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor={formRow.reference_name}
                                                                >
                                                                    {renderRowHTML(htmlDecode(formRow.question))}
                                                                </label>
                                                            </div>
                                                        </Col>
                                                    }

                                                    {
                                                        formRow.input_type == 17 &&
                                                        <>
                                                            <Col lg={12} className='mb-3'>
                                                                <textarea
                                                                    className="form-control"
                                                                    id="commentmessage-input"
                                                                    name={`address_${formRow.reference_name}`}
                                                                    onChange={(e) => handleinput(e, formRow, key, indefx)}
                                                                    value={rowsinput['data'][indefx][key].value ? rowsinput['data'][indefx][key].value : ''}
                                                                    disabled={is_disabled}
                                                                ></textarea>
                                                            </Col>

                                                        </>
                                                    }
                                                    {
                                                        formRow.input_type == 18 &&
                                                        <Col lg={12}>
                                                            <Row className="mt-3">
                                                                <p className='text-left form-label'>{renderRowHTML(formRow.question)}</p>
                                                            </Row>
                                                        </Col>
                                                    }
                                                    {
                                                        !!(formRow.input_type === 22) &&
                                                        <Col lg={12}>
                                                            <div className="mt-3">
                                                                <Select
                                                                    id="gender"
                                                                    className='select-dropdown'
                                                                    name={`gender_${formRow.reference_name}`}
                                                                    options={genderOptions}
                                                                    value={
                                                                        rowsinput['data'][indefx][key].value
                                                                            ? genderOptions.find(
                                                                                (option: any) =>
                                                                                    option.value == rowsinput['data'][indefx][key].value
                                                                            )
                                                                            : ""
                                                                    }
                                                                    isSearchable={true}
                                                                    isDisabled={is_disabled}
                                                                    onChange={(e: any) => {
                                                                        handleinput(e, formRow, key, indefx);
                                                                    }}
                                                                />
                                                            </div>

                                                        </Col>
                                                    }
                                                    {
                                                        !!(formRow.input_type === 23) &&
                                                        <Col lg={12}>
                                                            <div className="mt-3">
                                                                <Select
                                                                    id="language"
                                                                    className='select-dropdown'
                                                                    name={`language${formRow.reference_name}`}
                                                                    options={languageOptions}
                                                                    value={
                                                                        rowsinput['data'][indefx][key].value
                                                                            ? languageOptions.find(
                                                                                (option: any) =>
                                                                                    option.value == rowsinput['data'][indefx][key].value
                                                                            )
                                                                            : ""
                                                                    }
                                                                    isSearchable={true}
                                                                    isDisabled={is_disabled}
                                                                    onChange={(e: any) => {
                                                                        handleinput(e, formRow, key, indefx);
                                                                    }}
                                                                />
                                                            </div>

                                                        </Col>
                                                    }
                                                    {
                                                        !!(formRow.input_type === 24) &&
                                                        <Col lg={12}>
                                                            <Input
                                                                type="number"
                                                                name={formRow.reference}
                                                                className='form-control'
                                                                value={rowsinput['data'][indefx][key].value}
                                                                onChange={e => {
                                                                    handleinput(e, formRow, key, indefx);
                                                                }}
                                                                disabled={is_disabled}
                                                            />
                                                        </Col>
                                                    }
                                                    {
                                                        !!(formRow.input_type === 25) &&
                                                        <Col lg={12}>
                                                            <div className="mt-3">
                                                                <Select
                                                                    id="civil_status"
                                                                    className='select-dropdown'
                                                                    name={`civil_status_${formRow.reference_name}`}
                                                                    options={civil_status_master}
                                                                    value={
                                                                        rowsinput['data'][indefx][key].value
                                                                            ? civil_status_master.find(
                                                                                (option: any) =>
                                                                                    option.value == rowsinput['data'][indefx][key].value
                                                                            )
                                                                            : ""
                                                                    }
                                                                    isSearchable={true}
                                                                    isDisabled={is_disabled}
                                                                    onChange={(e: any) => {
                                                                        handleinput(e, formRow, key, indefx);
                                                                    }}
                                                                />
                                                            </div>

                                                        </Col>
                                                    }
                                                    {
                                                        !!(formRow.input_type === 26) &&
                                                        (
                                                            <div>
                                                                <AddSignature
                                                                    moduleData={{
                                                                        module_app_id: 6,
                                                                        module_main_id: questionnaireID,
                                                                        module_sub_id: questionnaireSubID,
                                                                        module_main_user_id: questionrow?.user_id,
                                                                        module_sub_user_id: questionrow?.user_id_code,
                                                                        section_id: questionnaireID,
                                                                        file_path: "user",
                                                                    }}
                                                                    show={signatureShow} isDisabled={is_disabled} showAdd={showSign}
                                                                    handleSignatureData={handleSignatureData} />
                                                            </div>
                                                        )
                                                    }
                                                </Col >
                                            </td>
                                        ))
                                    }
                                </tr>
                            )

                        })
                    }
                </tbody>
            </Table>
        </Col >
    )
}

export default TableMultiInput