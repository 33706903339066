import React, { useEffect, useRef, useState } from 'react'
import { Alert, Button, Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row, Spinner, Table } from 'reactstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CompanyAction, QuestionnaireUserDataAction } from '../../store/actions'
import QuestionnaireService from '../../services/questionnaire.service';
import { toast } from "react-toastify";
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import MultiComments from "../../components/Common/MultiComments";
import QuestionView from './QuestionView';
import Dropzone from "../../components/Common/Dropzone";
import MultiInput from './MultiInput';
import { filterCountryByLanguage, getSelectOptions, getUserProfile, handleAutoSave, removeTags } from '../../helpers/functions_helper';
// Import Loader
import Loader from "../../components/Layout/Loader";
import TableMultiInput from './multiinput/Table';

//Mail Action
import { setDraftEmailData } from "../../store/mails/actions";
import { draftmailProps } from "../Email/components/type";
import EmailButton from '../Email/components/EmailButton'
import { civil_status_master } from '../../common/staticdata'


import { useCallbackPrompt } from '../../hooks/useCallbackPrompt'
import InfoAlert from "../../components/Common/Alert";
import NavigationAlert from "../../components/Common/NavigationAlert";
import emailService from '../../services/email.service'

const AdminView = (props: any) => {
    let params = useParams();
    if (props?.questionnaireData) {
        params = props?.questionnaireData;
    }

    const { t } = useTranslation('common');
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const userProfile = getUserProfile("all-questionnaires");
    const toastId = React.useRef(null) as any;
    const toast_user = React.useRef(null) as any;
    const toastId_user_general = React.useRef(null) as any;
    const toastId_company = React.useRef(null) as any;
    const toastId_company_general = React.useRef(null) as any;
    const toastId_company_status = React.useRef(null) as any;
    const toastId_user_status = React.useRef(null) as any;
    const [tab_id, setTabId] = useState();
    const questionnaireFullData = useRef<any>({ questions_data: [], questionnaire_data: [], file_data: [], questionnaire_tab_data: [], answered_tabs: [] });
    const [questionPreviewData, setQuestionPreviewData] = useState<any>([]);
    const [questionnaireData, setQuestionnaireData] = useState<any>([]);
    const [questionnaireID, setQuestionnaireID] = useState(params.id);
    const [questionnaireSubID, setQuestionnaireSubID] = useState(params.questionnaire_id);
    const [user_id, setUser_id] = useState();
    const [activeNextTab, setactiveNextTab] = useState(1);

    const localFormData = handleAutoSave("GET");
    const [autoSave, setAutoSave] = useState(false);
    const [autoSaveAlert, setAutoSaveAlert] = useState(false);
    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(autoSave);
    const [intialData, setIntialData] = useState<any>({});

    const [title, setTitle] = useState("");
    const [template, setTemplate] = useState(false);
    const [mainTitle, setMainTitle] = useState("");
    const [fileManager, setFileManager] = useState(false);

    const [tabs, setTabs] = useState<any>([]);
    const [activeTab, setactiveTab] = useState(1);
    const [totalMenu, setTotalMenu] = useState(0);

    const [questionsData, setquestionsData] = useState<any>([]);

    const [questionnairestatus, setQuestionnaireStatus] = useState<any>();
    const [questionnaireActive, setQuestionnaireActive] = useState();
    const [questionnaireViewAll, setQuestionnaireViewAll] = useState();
    const [adminview, setAdminView] = useState(false);
    const [multicheck, setMulticheck] = useState<any>([]);
    const [multicheckname, setMulticheckname] = useState("");
    const [userType, setUserType] = useState(props.usertype);
    const [questionLoading, setQuestionLoading] = useState<boolean>(true);
    const [getMultiCommentsData, setMultiCommentsData] = useState(false);
    const [filevalue, setFileValue] = useState({});
    const [loader, setLoader] = useState(true);
    const [userAddress, setUserAddress] = useState<any>([]);
    const [companyAddress, setCompanyAddress] = useState<any>([]);
    const [companyInvAddress, setCompanyInvAddress] = useState<any>([]);
    const [questionnaireLang, setQuestionnaireLang] = useState(1);
    const [language, setLanguage] = useState("English");
    const [tabData, setTabData] = useState<any>([]);


    // fetch data from 
    let data = useSelector((state: any) => ({
        questionnaireFullData: state.Questionnaire.GET_QUESTIONS_FULL_DATA.data,
        isQuestionLoad: state.Questionnaire.loading,
        getQuestionnaireMsg: state.Questionnaire.GET_QUESTIONS_FULL_DATA,
        getQuestionnaireTab: state.QuestionnaireTab.GET_TAB_LIST.data,
        success: state.QuestionnaireUserData.success,
        error: state.QuestionnaireUserData.error,
        questionnairestatus: state.QuestionnaireUserData.questionnaire_status,
        user_status_change: state.QuestionnaireUserData.user_status_change,
        company_status_change: state.QuestionnaireUserData.company_status_change,
        user_success: state.QuestionnaireUserData.user_success,
        user_general_success: state.QuestionnaireUserData.user_general_success,
        company_success: state.QuestionnaireUserData.company_success,
        company_general_success: state.QuestionnaireUserData.company_general_success,
        loading: state.QuestionnaireUserData.loading,
        getCompanyOptionsList: state.Common.GET_COMPANY_OPTIONS_LIST_SUCCESS.data,
    }));

    const [loadingCountry, setLoadingCountry] = useState(true);
    const [countryOptions, setCountryOptions] = useState<Array<any>>([]);
    const [loadingNationality, setLoadingNationality] = useState(true);
    const [nationalityOptions, setNationalityOptions] = useState([]);
    // useEffect(() => {
    //     getSelectOptions("tbl_master_country", "name").then(function (options: any) {
    //         setCountryOptions(options);
    //         setLoadingCountry(false);
    //         setNationalityOptions(options);
    //         setLoadingNationality(false);
    //     });
    // }, []);

    useEffect(() => {
      getSelectOptions("tbl_master_country", "name", "", [
        "is_europian_country",
        "name",
        "name_french",
        "name_dutch",
      ]).then(function (options) {
        let countryOptionsList = filterCountryByLanguage(options,language);
        setCountryOptions(countryOptionsList);
        setLoadingCountry(false);
        setNationalityOptions(options);
        setLoadingNationality(false);
      });
    }, [language]);

    //attachement api call only for activetabs
    const [activeTabname, setActiveTabname] = useState<any>("");
    const [activeTabnames, setActiveTabnames] = useState<any>([]);
    document.querySelectorAll('.activeTabItem').forEach((i) => {
        if (i) {
            const observer = new IntersectionObserver((entries) => {
                observerCallback(entries, observer, i)
            },
                { threshold: .9 });
            observer.observe(i);
        }
    });

    const observerCallback = (entries: any, observer: any, header: any) => {
        entries.forEach((entry: any, i: any) => {
            if (entry.target.id !== activeTabname && entry.isIntersecting && !activeTabnames.includes(entry.target.id)) {
                setActiveTabname(entry.target.id);
            }
        });
    };

    const [isQuestionLoad, setIsQuestionLoad] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [errorData, setErrorData] = useState<any>({ message: "", status: "" });
    const [errorMsg, setErrorMsg] = useState(false);

    // fiel upload data
    const [fileAnswerData, setFileAnswerData] = useState([]);
    const [partnerQuestionnaire, setPartnerQuestionnaire] = useState("");
    const [questionsList, setQuestionsList] = useState([]);
    const [openQuestionnaire, setOpenQuestionnaire] = useState(false);
    const [userID, setUserID] = useState();
    const [userIDCode, setUserIDCode] = useState();

    // dealing with sign add btn
    const [showSignAdd, setShowSignAdd] = useState(false);
    const [year, setYear] = useState(null) as any;

    useEffect(() => {
        if (questionnaireFullData.current && questionnaireFullData.current.questionnaire_data.length > 0) {
            //updateQuestionAr(data.questionnaireFullData.questionnaire_data);
            //setQuestionnaireFullData(questionnaireFullData);

            setTabData(JSON.parse(questionnaireFullData.current.answered_tabs));
            setQuestionnaireData(questionnaireFullData.current.questionnaire_data[0]);
            setquestionsData(questionnaireFullData.current.questions_data);
            setFileAnswerData(questionnaireFullData.current.file_data);
            setQuestionsList(questionnaireFullData.current.questionnaire_list);
            setQuestionnaireStatus(questionnaireFullData.current.questionnaire_data[0].status);
            setQuestionnaireActive(questionnaireFullData.current.questionnaire_data[0].questionnaire_active);
            setQuestionnaireViewAll(questionnaireFullData.current.questionnaire_data[0].questionnaire_view_all);
            setPartnerQuestionnaire(questionnaireFullData.current.questionnaire_data[0].static_type);
            setUserID(questionnaireFullData.current.questionnaire_data[0].user_id);
            setUserIDCode(questionnaireFullData.current.questionnaire_data[0].user_id_code);
            setYear(questionnaireFullData.current.questionnaire_data[0].year);
            setQuestionnaireLang(questionnaireFullData.current.questionnaire_data[0].questionnaire_lang);
            const civil_status = questionnaireFullData.current.questionnaire_data[0].user_civil_status || null;
            if (props.usertype == "user" && [2, 3, 4, 7].includes(civil_status)) {
                setShowSignAdd(true);
            }
            if (questionnaireFullData && questionnaireFullData.current.questionnaire_data && questionnaireFullData.current.questionnaire_data[0] && questionnaireFullData.current.questionnaire_data[0].file_manager == userProfile.USER_ID) {
                setFileManager(true);
            }
            setTabs(questionnaireFullData.current.questionnaire_tab_data);
            if (questionnaireFullData.current.questionnaire_tab_data[0]) {
                setMainTitle(questionnaireFullData.current.questionnaire_tab_data[0].questionnaire_type);
                setTemplate(questionnaireFullData.current.questionnaire_tab_data[0].admin_template);
                setTitle(questionnaireFullData.current.questionnaire_tab_data[0].questionnaire_tab_value);
                setactiveTab(questionnaireFullData.current.questionnaire_tab_data[0].id);
                setIntialData({ ...intialData, tab_id: questionnaireFullData.current.questionnaire_tab_data[0].id });
            }
            if (questionnaireFullData.current.questionnaire_tab_data[1]) {
                setactiveNextTab(questionnaireFullData.current.questionnaire_tab_data[1].id);
            }
            if (questionnaireFullData.current.questionnaire_tab_data.length - 1) {
                setTotalMenu(questionnaireFullData.current.questionnaire_tab_data[questionnaireFullData.current.questionnaire_tab_data.length - 1].id);
            }
            dispatch(QuestionnaireUserDataAction.SubmitQuestionnaireUserDataFail(""));
            //dispatch(QuestionnaireAction.getQuestionnaireFulldataSuccess(""))
        }
    }, [isQuestionLoad == true]);


    useEffect(() => {
        const currentab: any = tabs.find(
            (tab: any) =>
                tab.questionnaire_tab_value == activeTabname
        );
        setactiveTab(currentab?.id);
        if (!activeTabnames.includes(activeTabname)) {
            setActiveTabnames([...activeTabnames, activeTabname]);
        }

    }, [activeTabname]);

    useEffect(() => {
        if (fileAnswerData.length > 0) {
            fileAnswerData.forEach((fileone: any) => {
                setFileValue({ ...filevalue, ["sent_file_" + fileone.tab_id]: fileone.file });
            });
        }
    }, [fileAnswerData, activeTab]);

    const loadFullQuestionData = async (id: any) => {
        let userData = { data: { id } };
        setIsQuestionLoad(false);
        const res = (await QuestionnaireService.getQuestionnaireFulldata(userData)) as any;
        if (res.data.status === 'success') {
            setLoader(false);
            setIsQuestionLoad(true);
            questionnaireFullData.current = res.data.data;
        } else {
            setIsError(true);
            setErrorData(res.data);
            setLoader(false);
        }
    };


    useEffect(() => {
        if (questionnaireLang == 1) {
            setLanguage("English");
        } else if (questionnaireLang == 2) {
            setLanguage("French");
        } else if (questionnaireLang == 3) {
            setLanguage("Dutch");
        } else {
            setLanguage("English");
        }
    }, [questionnaireLang])

    //Initial dispatch questionnaire
    useEffect(() => {
        // if (localFormData && localFormData?.initialData) {
        //     setIntialData(localFormData?.initialData);
        //     setAutoSaveAlert(true);
        //     setLoader(false);
        //     setIsQuestionLoad(false);
        //     setIsError(false);
        // } else {            
            loadFullQuestionData(params.id);
        // }
    }, []);


    useEffect(() => {
        setUser_id(props.user_id);
    }, [props])

    const [mandatory, setMandatory] = useState(false);
    const [validationErrorMsg, setValidationErrorMsg] = useState(false);
    const getAnswerAr = async () => {
        let questionArray: any = [];
        for (const [key_file, value_file] of Object.entries(filevalue)) {
            const tab_id_file = key_file.split("_")[2];
            questionArray.push({
                user_id: user_id,
                tab_id: tab_id_file,
                file: (value_file == 1 ? true : false),
                questionnaire_id: params.id,
                question_id: "",
                answer: "",
                question: ""
            });
        };


        await questionsData.forEach((question: any, key: any) => {
            for (const [key, value] of Object.entries(intialData)) {
                if (question.reference_name == key) {
                    questionArray.push({
                        user_id: user_id,
                        tab_id: question.tab_id,
                        file: 0,
                        questionnaire_id: params.id,
                        question_id: question.question_id,
                        answer: typeof value === 'object' ? JSON.stringify(value) : value,
                        question: question.question
                    });
                }
            }
        })
        return questionArray;
    }

    const onClickTerminate = async (row: any) => {
        const deleteRowData: any = {
            id: questionnaireID,
            questionnaire_id: questionnaireSubID,
        };
        const res = (await QuestionnaireService.onClickTerminate(deleteRowData)) as any;
        if (res.status == "success") {
            const messg = t(
                `Questionnaire status is updated to terminated`
            );
            toast.dismiss();
            toast.success(messg, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            loadFullQuestionData(questionnaireID);
        }
    }

    const onSubmitClose = async () => {
        const answers = await getAnswerAr();
        const messg = t(
            `Saving data...`
        );
        toastId.current = toast(messg, {
            autoClose: false,
            isLoading: true,
        });
        dispatch(QuestionnaireUserDataAction.addNewQuestionnaireUserData({ questionAr: answers, usertype: props.usertype, "questionnaire_id": params.id }));
    }


    const onClickIncomplete = async () => {
        const answers = await getAnswerAr();
        const messg = t(
            `Saving data...`
        );
        toastId.current = toast(messg, {
            autoClose: false,
            isLoading: true,
        });
        dispatch(QuestionnaireUserDataAction.IncompleteQuestionnaireUserData({ questionAr: answers, usertype: props.usertype, "questionnaire_id": params.id }));
    };

    useEffect(() => {
        if (mandatory) {
            const boxes = document.getElementsByClassName(
                'invalid-question-label',
            );
            if (boxes[0]) {
                boxes[0].scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }
    }, [mandatory]);


    useEffect(() => {
        if (validationErrorMsg) {
            const boxes = document.getElementsByClassName(
                'invalid-question',
            );
            if (boxes[0]) {
                boxes[0].scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }
    }, [validationErrorMsg]);

    const onSubmitQuestionnaire = async () => {
        const answers = await getAnswerAr();
        dispatch(QuestionnaireUserDataAction.SubmitQuestionnaireUserDataFail(""));
        setMandatory(false);
        setValidationErrorMsg(false);
        const messg = t(
            `Please validate all tab answers before submit`
        );
        if (activeTabnames.length < tabs.length) {
            toastId.current = toast(
                messg,
                {
                    type: toast.TYPE.ERROR,
                    autoClose: 5000,
                    isLoading: false,
                    delay: 500,
                }
            );
        }
        else if (errorMsg) {
            setMandatory(true);
        } else {
            const messg = t(
                `Saving data...`
            );
            toastId.current = toast(messg, {
                autoClose: false,
                isLoading: true,
            });

            dispatch(QuestionnaireUserDataAction.SubmitQuestionnaireUserData({ questionAr: answers, usertype: props.usertype, "questionnaire_id": params.id }));
        }
    }

    const [scrollActive, setScrollActive] = useState(0);
    const handleScroll = (e: any, tab: any) => {
        if (scrollActive !== tab) {
            setScrollActive(tab);
        }
    }


    //SUBMIT / UPDATE QUESTIONNAIRE
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    useEffect(() => {
        if (data.success) {
            toast.update(toastId.current, {
                type: toast.TYPE.SUCCESS,
                render: data.success,
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });
            setTimeout(() => {
                dispatch(QuestionnaireUserDataAction.SubmitQuestionnaireUserDataSuccess(""));
            }, 5000)
            return navigate("/questionnaires/all-questionnaires");
        }
        else if (error !== data.error) {

            toast.update(toastId.current, {
                type: toast.TYPE.ERROR,
                render: data.error,
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });
        }

    }, [data.success, data.error, navigate])


    useEffect(() => {
        if (data.user_success == "success") {
            const messg = t(
                `Saving data...`
            );
            toast_user.current = toast(messg, {
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });
        } else if (data.user_success != "" && data.user_success != 0 && data.user_success != 1 && data.user_success != 0 && data.user_success != undefined && data.user_success != 'error') {
            for (const [key, value] of Object.entries(data.user_success)) {
                if (questionsData) {
                    questionsData.forEach((question: any) => {
                        if (question.map_field_name == key) { // show validation error if the value not matched with mapped data field
                            question.validation_error = value
                        }
                    })
                }
            }
            if (Object.keys(data.user_success).length) {
                setValidationErrorMsg(true);
                setquestionsData(questionsData);
            }
        }
    }, [data.user_success]);

    useEffect(() => {
        if (data.company_success == "success") {
            const messg = t(
                `Updated company details`
            );
            toastId_company.current = toast(messg, {
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });
        } else if (data.company_success != "" && data.company_success != 0 && data.company_success != 1 && data.company_success != 0 && data.company_success != undefined && data.company_success != 'error') {
            for (const [key, value] of Object.entries(data.company_success)) {
                if (questionsData) {
                    questionsData.forEach((question: any) => {
                        if (question.map_field_name == key) { // show validation error if the value not matched with mapped data field
                            question.validation_error = value
                        }
                    })
                }
            }
            if (Object.keys(data.company_success).length) {
                setValidationErrorMsg(true);
                setquestionsData(questionsData);
            }
        }
    }, [data.company_success]);

    useEffect(() => {
        if (data.user_general_success == "success") {
            const messg = t(
                `Updated user general details`
            );
            toastId_user_general.current = toast(messg, {
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });
        } else if (data.user_general_success != "" && data.user_general_success != 0 && data.user_general_success != 1 && data.user_general_success != 0 && data.user_general_success != undefined && data.user_general_success != 'error') {
            for (const [key, value] of Object.entries(data.user_general_success)) {
                if (questionsData) {
                    questionsData.forEach((question: any) => {
                        if (question.map_field_name == key) { // show validation error if the value not matched with mapped data field
                            question.validation_error = value
                        }
                    })
                }
            }
            if (Object.keys(data.user_general_success).length) {
                setValidationErrorMsg(true);
                setquestionsData(questionsData);
            }
        }
    }, [data.user_general_success]);

    useEffect(() => {
        if (data.company_general_success == "success") {
            const messg = t(
                `Updated company general details`
            );
            toastId_company_general.current = toast(messg, {
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });
        } else if (data.company_general_success != "" && data.company_general_success != 0 && data.company_general_success != 1 && data.company_general_success != 0 && data.company_general_success != undefined && data.company_general_success != 'error') {
            for (const [key, value] of Object.entries(data.company_general_success)) {
                if (questionsData) {
                    questionsData.forEach((question: any) => {
                        if (question.map_field_name == key) { // show validation error if the value not matched with mapped data field
                            question.validation_error = value
                        }
                    })
                }
            }
            if (Object.keys(data.company_general_success).length) {
                setValidationErrorMsg(true);
                setquestionsData(questionsData);
            }
        }
    }, [data.company_general_success]);

    useEffect(() => {
        if (data.questionnairestatus && data.questionnairestatus.data) {
            const messg = `${data.questionnairestatus.data.questionnaire_type} ${t("sent successfully to partner.")}`;
            toastId.current = toast(messg, {
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });
        }
    }, [data.questionnairestatus]);

    useEffect(() => {
        if (data.company_status_change) {
            const messg = t(
                `Updated company status to Customer`
            );
            toastId_company_status.current = toast(messg, {
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });
            dispatch(CompanyAction.getCompanySuccess(""));
        }
    }, [data.company_status_change]);

    useEffect(() => {
        if (data.user_status_change) {
            const messg = t(
                `Updated company status to Customer`
            );
            toastId_user_status.current = toast(messg, {
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });
        }
    }, [data.user_status_change]);

    const [mappedDates, setMappedDates] = useState<any>([]);

    useEffect(() => {
        if (questionsData.length > 0) {
            let objcop = {};
            let obj_map: any = {};
            questionsData.forEach((question: any) => {
                // if (question.map_field_name != null && question.map_field_type != null && question.map_field_name !== "risque_date" && question.map_field_name !== "risque_status" && question.map_field_name !== "aml_expiry_date" && question.map_field_name !== "ubo_expiry_date" && question.map_field_name !== "signed_date" && (((questionnairestatus == 5 || questionnairestatus == 6) && (question.user_data_answer == null)))) {
                if (question.map_field_name != null && question.map_field_type != null && question.map_field_name !== "risque_date" && question.map_field_name !== "risque_status" && question.map_field_name !== "aml_expiry_date" && question.map_field_name !== "ubo_expiry_date" && question.map_field_name !== "signed_date" && (question.user_data_answer == null)) {
                    type ObjectKey = keyof typeof obj_map;
                    const map_type = question.map_field_type == null ? 'user' : question.map_field_type;
                    const map_field_name = map_type + '_' + question.map_field_name as ObjectKey;
                    question.user_data_answer = questionnaireData[map_field_name];
                    if (question.map_field_name == "civil_status" && question.input_type == 3) {
                        const civil_data = civil_status_master.filter((mst: any) => mst.value == questionnaireData[map_field_name]);
                        if (civil_data.length) {
                            question.user_data_answer = civil_data[0].label;
                        }

                    }
                }

                if (question.map_field_name == "risque_date" || question.map_field_name == "aml_expiry_date" || question.map_field_name == "ubo_expiry_date" || question.map_field_name == "signed_date") {
                    setMappedDates([...mappedDates, { key: question.map_field_name, value: question.reference_name }]);
                }
                let obj = { [question.reference_name]: question.user_data_answer };
                objcop = Object.assign(objcop, obj);
            });
            setIntialData((intialData: any) => {
                return {
                    ...intialData,
                    ...objcop
                };
            });
        }
    }, [questionsData]);

    useEffect(() => {
        if (questionnaireFullData.current.questions_data) {
            const questionAr: any = questionnaireFullData.current.questions_data;
            for (const [key, value] of Object.entries(intialData)) {
                questionAr.forEach((question: any) => {
                    if (question.reference_name == key) {
                        question.user_data_answer = value;
                    }
                    if (value != "" && value != null) {
                        const percentage_field: any = questionsData.filter((data: any) => data.question_id == question.perentage_depend_id);
                        if (percentage_field.length > 0 && percentage_field[0] && (percentage_field[0]?.user_data_answer == null || percentage_field[0]?.user_data_answer == "")) {
                            const val_per = (100 - question.user_data_answer);
                            if (val_per != 100) {
                                percentage_field[0].user_data_answer = val_per;
                            }
                        }

                    }
                })
            }
            setErrorMsg(false);
            setMandatory(false);
            setValidationErrorMsg(false);
            updateQuestionAr(questionAr);
        }
    }, [intialData])


    useEffect(() => {
        if (questionnaireFullData.current.questions_data) {
            let userData = {};
            const questionAr: any = questionnaireFullData.current.questions_data;
            questionAr.forEach((question: any) => {
                if (userAddress?.address) {
                    if (question.map_field_name == "address" && question.map_field_type == "user") {
                        userData = ({ ...userData, [question.reference_name]: userAddress?.address });
                    }
                    if (question.map_field_name == "address_number" && question.map_field_type == "user") {
                        userData = ({ ...userData, [question.reference_name]: userAddress?.address_number });
                    }
                    if (question.map_field_name == "box" && question.map_field_type == "user") {
                        userData = ({ ...userData, [question.reference_name]: userAddress?.box });
                    }
                    if (question.map_field_name == "postal_code" && question.map_field_type == "user") {
                        userData = ({ ...userData, [question.reference_name]: userAddress?.postal_code });
                    }
                    if (question.map_field_name == "city" && question.map_field_type == "user") {
                        userData = ({ ...userData, [question.reference_name]: userAddress?.city });
                    }
                    if (question.map_field_name == "commune" && question.map_field_type == "user") {
                        userData = ({ ...userData, [question.reference_name]: userAddress?.commune });
                    }
                    if (question.map_field_name == "country" && question.map_field_type == "user") {
                        userData = ({ ...userData, [question.reference_name]: userAddress?.country });
                    }
                }
                if (companyAddress?.address) {
                    if (question.map_field_name == "company_address" && question.map_field_type == "company") {
                        userData = ({ ...userData, [question.reference_name]: companyAddress?.address });
                    }
                    if (question.map_field_name == "office_number" && question.map_field_type == "company") {
                        userData = ({ ...userData, [question.reference_name]: companyAddress?.address_number });
                    }
                    if (question.map_field_name == "box" && question.map_field_type == "company") {
                        userData = ({ ...userData, [question.reference_name]: companyAddress?.box });
                    }
                    if (question.map_field_name == "postal_code" && question.map_field_type == "company") {
                        userData = ({ ...userData, [question.reference_name]: companyAddress?.postal_code });
                    }
                    if (question.map_field_name == "city" && question.map_field_type == "company") {
                        userData = ({ ...userData, [question.reference_name]: companyAddress?.city });
                    }
                    if (question.map_field_name == "commune" && question.map_field_type == "company") {
                        userData = ({ ...userData, [question.reference_name]: companyAddress?.commune });
                    }
                    if (question.map_field_name == "country" && question.map_field_type == "company") {
                        userData = ({ ...userData, [question.reference_name]: companyAddress?.country });
                    }
                }
                if (companyInvAddress?.address) {
                    if (question.map_field_name == "inv_company_address" && question.map_field_type == "company") {
                        userData = ({ ...userData, [question.reference_name]: companyAddress?.address });
                    }
                    if (question.map_field_name == "inv_office_number" && question.map_field_type == "company") {
                        userData = ({ ...userData, [question.reference_name]: companyAddress?.address_number });
                    }
                    if (question.map_field_name == "inv_box" && question.map_field_type == "company") {
                        userData = ({ ...userData, [question.reference_name]: companyAddress?.box });
                    }
                    if (question.map_field_name == "inv_postal_code" && question.map_field_type == "company") {
                        userData = ({ ...userData, [question.reference_name]: companyAddress?.postal_code });
                    }
                    if (question.map_field_name == "inv_city" && question.map_field_type == "company") {
                        userData = ({ ...userData, [question.reference_name]: companyAddress?.city });
                    }
                    if (question.map_field_name == "commune" && question.map_field_type == "company") {
                        userData = ({ ...userData, [question.reference_name]: companyAddress?.commune });
                    }
                    if (question.map_field_name == "inv_country" && question.map_field_type == "company") {
                        userData = ({ ...userData, [question.reference_name]: companyAddress?.country });
                    }
                }
            })
            setIntialData((intialData: any) => {
                return {
                    ...intialData,
                    ...userData
                };
            });
        }
    }, [userAddress, companyAddress, companyInvAddress])

    const updateQuestionAr = async (questionDataAr: any) => {
        let questionAr: any = [];
        // let obj: any = {};
        // questionDataAr.forEach((question: any) => {
        //     if (question.map_field_name != "" && question.user_data_answer == null) {
        //         type ObjectKey = keyof typeof obj;
        //         const map_type = question.map_field_type == null ? 'user' : question.map_field_type;
        //         const map_field_name = map_type + '_' + question.map_field_name as ObjectKey;
        //         question.user_data_answer = questionnaireData[map_field_name];
        //     }
        //     obj = { ...obj, [question.reference_name]: question.user_data_answer };
        // });

        const condtionObj = Object.keys(intialData);
        const condtionAns = Object.values(intialData);
        if (questionDataAr) {
            questionDataAr.forEach((question: any) => {
                let checkcondition = false
                if ((question.condition_reference_question == null) || (!condtionObj.includes(question.condition_reference_question) && !condtionObj.includes(question.condition_reference_question_2))) {
                    questionAr.push(question);
                } else if (condtionObj.includes(question.condition_reference_question) || condtionObj.includes(question.condition_reference_question_2)) {
                    if (condtionObj.includes(question.condition_reference_question)) {
                        const answer_label = question.condition_answer_label;
                        const index = condtionObj.indexOf(question.condition_reference_question);
                        if (answer_label !== null && question.condition_input_type != 2) {
                            const answAr = answer_label.split(',');
                            // if (answAr.includes(condtionAns[index]) && (!condtionObj.includes(question.condition_reference_question_2) || question.condition_reference_question_2 == null)) {
                            //     questionAr.push(question);
                            // }
                            // else
                            if (answAr.includes(condtionAns[index])) {
                                questionAr.push(question);
                            }
                        }
                        // else if (condtionAns[index] == '1' && question.condition_answer_label == "Yes" && (!condtionObj.includes(question.condition_reference_question_2) || question.condition_reference_question_2 == null)) {
                        //     questionAr.push(question);
                        // }
                        // else if (condtionAns[index] == '0' && question.condition_answer_label == "No" && (!condtionObj.includes(question.condition_reference_question_2) || question.condition_reference_question_2 == null)) {
                        //     questionAr.push(question);
                        // }

                        else if (condtionAns[index] == '1' && question.condition_answer_label == "Yes") {
                            //checkcondition = true;
                            questionAr.push(question);
                        }
                        else if (condtionAns[index] == '0' && question.condition_answer_label == "No") {
                            //checkcondition = true;
                            questionAr.push(question);
                        }
                    }

                    // if (condtionObj.includes(question.condition_reference_question_2)) {
                    //     const answer_label_2 = question.condition_answer_label_2;
                    //     const index_2 = condtionObj.indexOf(question.condition_reference_question_2);
                    //     if (answer_label_2 !== null && question.condition_input_type_2 != 2) {
                    //         const answAr_2 = answer_label_2.split(',');
                    //         if (answAr_2.includes(condtionAns[index_2]) && (!condtionObj.includes(question.condition_reference_question) || question.condition_reference_question == null)) {
                    //             questionAr.push(question);
                    //         }
                    //         else if ((answAr_2.includes(condtionAns[index_2]) || checkcondition) && question.condition_or) {
                    //             questionAr.push(question);
                    //         }
                    //         else if (answAr_2.includes(condtionAns[index_2]) && checkcondition) {
                    //             questionAr.push(question);
                    //         }

                    //     }


                    //     else if (((condtionAns[index_2] == 1 && question.condition_answer_label_2 == "Yes") || checkcondition) && question.condition_or) {
                    //         questionAr.push(question);
                    //     }
                    //     else if (((condtionAns[index_2] == 0 && question.condition_answer_label_2 == "No") || checkcondition) && question.condition_or) {
                    //         questionAr.push(question);
                    //     }
                    //     else if (condtionAns[index_2] == 1 && question.condition_answer_label_2 == "Yes" && checkcondition) {
                    //         questionAr.push(question);
                    //     }
                    //     else if (condtionAns[index_2] == 0 && question.condition_answer_label_2 == "No" && checkcondition) {
                    //         questionAr.push(question);
                    //     }

                    // }

                }
            })

            //change indexval
            const array_val = questionAr;
            let count = 1;
            await questionAr.forEach((question: any, key: any) => {
                question.indexval = count;
                array_val.forEach((qu: any, key1: any) => {
                    if (qu.id == question.same_page_with && question.same_page_with != null) {
                        question.indexval = qu.indexval;
                        count--;
                    }
                })
                count++;

                if ((question.user_data_answer == null || question.user_data_answer == undefined || question.user_data_answer == "") && question.required_field) {
                    setErrorMsg(true);
                } else if ((question.user_data_answer == null || question.user_data_answer == undefined || question.user_data_answer == "" || question.user_data_answer == 0) && question.required_field && question.input_type == 8) {
                    setErrorMsg(true);
                }
                question.user_id = userID;
                question.user_id_code = userIDCode;
            })

            setQuestionPreviewData(questionAr);
        }
    }

    // useEffect(() => {
    //     if (questionsData) {
    //         let questionArray: any = [];
    //         for (const [key_file, value_file] of Object.entries(filevalue)) {
    //             const tab_id_file = key_file.split("_")[2];
    //             questionArray.push({
    //                 user_id: user_id,
    //                 tab_id: tab_id_file,
    //                 file: (value_file == 1 ? true : false),
    //                 questionnaire_id: params.id,
    //                 question_id: "",
    //                 answer: "",
    //                 question: ""
    //             });
    //         };
    //         questionsData.forEach((question: any, key: any) => {
    //             for (const [key, value] of Object.entries(intialData)) {
    //                 if (question.reference_name == key) {
    //                     questionArray.push({
    //                         user_id: user_id,
    //                         tab_id: question.tab_id,
    //                         file: 0,
    //                         questionnaire_id: params.id,
    //                         question_id: question.question_id,
    //                         answer: value,
    //                         question: question.question
    //                     });
    //                 }
    //             }
    //         })
    //         setAnswersAr(questionArray);
    //     }
    // }, [intialData])


    useEffect(() => {
        setQuestionLoading(false);
    }, [questionPreviewData.length > 0])


    function renderRowHTML(rowContent: any) {
        return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
    }

    // useEffect(() => {
    //     if(data.getQuestionnaireMsg){
    //         if(data.questionnaireFullData && data.questionnaireFullData.data && data.questionnaireFullData.data.questionnaire_data[0].id == params.id){
    //             setQuestionnaireFullData(data.questionnaireFullData);
    //             setQuestionnaireData(data.questionnaireFullData.questionnaire_data[0]);
    //             //setQuestionPreviewData((questionPreviewData: any ) => ([...questionPreviewData, ...data.questionnaireFullData.questions_data]));
    //             setQuestionPreviewData(data.questionnaireFullData.questions_data);
    //             const userID = data.questionnaireFullData.questionnaire_data[0].employee;
    //             const questionnaire_status = data.questionnaireFullData.questionnaire_data[0].status;
    //             setLoader(false);
    //             setUser_id(userID);
    //             setLoader(false);
    //         }else{
    //             dispatch(QuestionnaireAction.getQuestionnaireFulldataSuccess(""));
    //         }
    //     }else{
    //         dispatch(QuestionnaireAction.getQuestionnaireFulldata({ id: params.id }));
    //     }
    //     // setLoader(true);
    //     // dispatch(QuestionnaireAction.getQuestionnaireFulldata({ id: params.id }));
    //     // if(data.questionnaireFullData){
    //     //     setQuestionnaireFullData(data.questionnaireFullData);
    //     //     setQuestionnaireData(data.questionnaireFullData.questionnaire_data[0]);
    //     //     //setQuestionPreviewData((questionPreviewData: any ) => ([...questionPreviewData, ...data.questionnaireFullData.questions_data]));
    //     //     setQuestionPreviewData(data.questionnaireFullData.questions_data);
    //     //     const userID = data.questionnaireFullData.questionnaire_data[0].employee;
    //     //     const questionnaire_status = data.questionnaireFullData.questionnaire_data[0].status;
    //     //     setLoader(false);
    //     //     setUser_id(userID);
    //     //     setLoader(false);
    //     // }
    // },[params, data.getQuestionnaireMsg]);

    useEffect(() => {
        if (!questionnaireActive || (questionnairestatus == 9)) {
            setAdminView(false);
        } else if (questionnaireViewAll) {
            if (userProfile.CAN_EDIT_ALL && userType != "user" && questionnairestatus != 6) {
                setAdminView(true);
            } else if (fileManager && questionnairestatus != 6 && questionnairestatus != 5) {
                setAdminView(true);
            } else if (questionnairestatus != 6 && questionnairestatus != 5 && questionnairestatus != 3) {
                setAdminView(true);
            } else {
                setAdminView(false);
            }

        }
        else {
            if (questionnairestatus) {
                if (userType == "user" && (questionnairestatus == 1 || questionnairestatus == 4 || questionnairestatus == 8)) {
                    setAdminView(true);
                }
                else if ((userProfile.CAN_EDIT_ALL) && userType != "user" && (questionnairestatus != 1 && questionnairestatus != 4 && questionnairestatus != 6 && questionnairestatus != 8)) {
                    setAdminView(true);
                } else if (fileManager && (questionnairestatus != 1 && questionnairestatus != 4 && questionnairestatus != 5 && questionnairestatus != 6 && questionnairestatus != 8)) {
                    setAdminView(true);
                } else {
                    setAdminView(false);
                }
            }
        }


    }, [questionnairestatus, questionnaireActive, fileManager, questionnaireViewAll])
    /// file upload
    const [multiUploadStatus, setMultiUploadStatus] = useState(false);
    const multiUploadSuccess = (isSuccess: boolean) => {
        if (isSuccess === true) {
            setMultiUploadStatus(true);
        }
    };

    const toggleTab = (tab: any) => {
        if (tab_id !== tab) {
            setTabId(tab);
            setactiveTab(tab);
            questionnaireFullData.current.questionnaire_tab_data.forEach((questionnaireTab: any, key: number) => {

                if (questionnaireTab.id === tab) {
                    //setTitle(data.getQuestionnaireTab[key].questionnaire_tab_value);
                    setTitle(questionnaireTab.questionnaire_tab_value);
                    if (questionnaireFullData.current.questionnaire_tab_data[key + 1]) {
                        setactiveNextTab(questionnaireFullData.current.questionnaire_tab_data[key + 1].id);
                    }
                }
            })
            setIntialData({ ...intialData, tab_id: tab });
            //setneedfile("");
        }
    }

    const scrollToTop = (id: string) => {
        if (id) {
            var element: any = document.getElementById(id);
            if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }
    }

    const handleChangecheck = (e: any) => {
        const pre_check_val = e.target.checked ? 1 : 0;
        setIntialData((intialData: any) => {
            return {
                ...intialData,
                [e.target.name]: pre_check_val
            };
        });

    }


    const handleChange = (e: any) => {
        setIntialData((intialData: any) => {
            return {
                ...intialData,
                [e.target.name]: e.target.value
            };
        });

        // setAutoSave(true);
    }

    const handleChangeMulti = (e: any) => {
        setIntialData((intialData: any) => {
            return {
                ...intialData,
                [e.target.name]: e.target.value
            };
        });
    }
    function handleMultiInputChange(e: any, ref: any) {
        setIntialData((preState: any) => ({ ...preState, [ref]: e.data }));
    }


    const handleChangeCountry = (name: any, value: any) => {
        setIntialData((intialData: any) => {
            return {
                ...intialData,
                [name]: value
            };
        });
    }


    const handleChangeMulticheckbox = (e: any, answer: any) => {
        setMulticheckname(e.target.name);
        if (e.currentTarget.checked) {
            setMulticheck([...answer, e.target.value]);
        } else {
            var index = answer.indexOf(e.target.value);
            if (index !== -1) {
                answer.splice(index, 1);
            }
            setMulticheck(answer);
        }
    }

    const handlecalender = (name: any, dateval: any) => {
        if (dateval == "" || dateval == null) {
            setIntialData((intialData: any) => {
                return {
                    ...intialData,
                    [name]: ""
                };
            });
        } else {
            setIntialData((intialData: any) => {
                return {
                    ...intialData,
                    [name]: moment(dateval).format("YYYY-MM-DD hh:mm:ss")
                };
            });
        }
    }

    const handleKeyDown = (e: any) => {
        // setIntialData({ ...intialData, [e.target.name]: e.target.value });
    }

    useEffect(() => {
        if (multicheckname != "") {
            setIntialData({ ...intialData, [multicheckname]: multicheck });
        }
    }, [multicheck, multicheckname]);



    //Email compose
    const composeMail = async () => {
        const user = {
            label: `${questionnaireData?.user_first_name} ${questionnaireData?.user_last_name}`,
            value: questionnaireData?.user_email,
        };
        const contact_person = {
            label: `${questionnaireData?.company_hr_contact_person_name}`,
            value: questionnaireData?.company_hr_contact_person_email,
        };
        // const box = document.querySelector('#question_list_pdf') as HTMLDivElement | null;
        // const postData = {
        //     html:box?.innerHTML,
        //     name:`${user.label}-${questionnaireData?.questionnaire_type_html}.pdf`
        // };
        // const res = await emailService.getHTMLToPdf(postData);
        // let file = new File([res.data],`${user.label}-${questionnaireData?.questionnaire_type_html}.pdf`,{ type: "application/pdf", lastModified: new Date().getTime() });
        const draftMail: draftmailProps = {
            title: "New Message",
            filters: {},
            openMailBox: 1,
            subject: `${user.label}-${questionnaireData?.questionnaire_type_html}`,
            to: [user],
            cc: [contact_person],
            //files: file ? [file] : [],
        };
        dispatch(setDraftEmailData(draftMail));
    };


    const handleSignature = (data: any) => {
        if (mappedDates && mappedDates.length > 0) {
            mappedDates.forEach((value: any, key: any) => {
                if (value) {
                    handleChangeCountry(value.value, data?.created_at);
                }
            });
        }
    }

    useEffect(() => {
        if (autoSave) { handleAutoSave("SET", intialData); }
      }, [intialData, autoSave]);

    return (
        <React.Fragment>
            
            <InfoAlert
                open={autoSaveAlert}
                title={t("Autosaved data restored!")}
                message={t("Your last autosaved data has been restored from the session. In order to avoid the data loss, you need either save it to retain the data or Cancel to loose the data.")}
                onClickOk={() => setAutoSaveAlert(false)}
            />
            <NavigationAlert
                open={showPrompt}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
            />

            <Card>
                <CardBody>
                    {loader ? <Loader /> : null}
                    {
                        !isError ?
                            <Row className='questionnaire scroll-questionnaire'>
                                {!isQuestionLoad ?

                                    <div className='justify-content-center align-self-center text-center'>
                                        <div className='text-primary'>Please wait...</div>
                                        <div><Spinner size="sm" color="primary" style={{ 'position': 'relative', 'right': '-5px', 'top': '5px', 'borderColor': '#564AB1', 'borderRightColor': '#ccc', 'margin': '0 auto' }} /></div>
                                    </div>
                                    :
                                    <>
                                        <Row>
                                            <Col lg="2"></Col>
                                            <Col lg="8" className='align-center'> <h3 className='text-center text-primary'>
                                                {mainTitle != "" ? mainTitle : null}
                                            </h3></Col>
                                            <Col lg="2" className='pull-right text-end'>

                                                {questionnaireActive ?
                                                    <h5 className='text-right'>
                                                        <span className="badge rounded-pill badge_custome" style={{ color: "#ffffff", backgroundColor: questionnaireData.questionnaire_status_color_code }}>
                                                            {userType == "user" && (questionnaireData.questionnaire_status_value == "SENT") ? "YET TO START" : ((userType == "user" && questionnaireData.questionnaire_status_value == "IN REVIEW") ? "SUBMITTED" : questionnaireData.questionnaire_status_value)}
                                                        </span>
                                                    </h5>
                                                    : null
                                                }
                                                <EmailButton handlerCompose={() => composeMail()} />
                                            </Col>
                                        </Row>
                                        <Col lg="3" className="items items-admin">
                                            <div className="items-head">
                                                <p>{t('QUESTIONNAIRE', { lng: language })}</p>
                                            </div>
                                            <div className="items-body">
                                                {tabs &&
                                                    <>
                                                        {
                                                            tabs.map((item: any, key: any) => {
                                                                return (
                                                                    <div className={activeTab === item.id ? 'items-body-content active ' + item.id : 'items-body-content ' + item.id} onClick={() => {
                                                                        toggleTab(item.id)
                                                                    }} key={key}>
                                                                        <span className={(item.is_answered || ([1, 4, 7, 8, 9].includes(questionnairestatus) || template)) ? "tab_name" : "tab_name non_applicable"}><span onClick={() => scrollToTop(item.questionnaire_tab_value)}>{removeTags(item.questionnaire_tab_value)}</span>
                                                                            {(item.is_answered || ([1, 4, 7, 8, 9].includes(questionnairestatus) || template)) ? (
                                                                                null
                                                                            ) : <i className='far fa-eye-slash' title="Non applicable"></i>}

                                                                        </span>
                                                                        <i className="fa fa-angle-right"></i>
                                                                    </div>

                                                                )
                                                            })
                                                        }
                                                    </>
                                                }

                                            </div>

                                        </Col>

                                        <Col lg="9" className='fixed-top-adminview' >

                                            <Card>
                                                <CardBody id="question_list_pdf">
                                                    {questionnairestatus == 8 ?
                                                        <Row>
                                                            <Col xl="12" className='mt-3'>
                                                                <CardHeader className="d-none bg-transparent border-bottom">
                                                                    <div className="d-sm-flex flex-wrap">
                                                                        <CardTitle className="card-title text-uppercase">
                                                                            {t('Comments')}
                                                                        </CardTitle>
                                                                    </div>
                                                                </CardHeader>
                                                                {!!questionnaireData &&
                                                                    <Row>
                                                                        <MultiComments
                                                                            moduleData={{
                                                                                module_app_id: 6,
                                                                                module_main_id: Number(questionnaireID),
                                                                                module_sub_id: questionnaireSubID,
                                                                                section_id: 0,
                                                                            }}
                                                                            commentForIDs={[
                                                                                {
                                                                                    value: questionnaireData.user_id,
                                                                                    label:
                                                                                        questionnaireData.user_first_name +
                                                                                        " " +
                                                                                        questionnaireData.user_last_name,
                                                                                    selected: false,
                                                                                },
                                                                                {
                                                                                    value: questionnaireData.file_manager_id,
                                                                                    label:
                                                                                        questionnaireData.file_manager_first_name +
                                                                                        " " +
                                                                                        questionnaireData.file_manager_last_name,
                                                                                    selected: false,
                                                                                },
                                                                            ]}
                                                                            fetchData={true}
                                                                            isOpen={false}
                                                                            isDisabled={!adminview}
                                                                        />
                                                                    </Row>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        : null
                                                    }
                                                    {(partnerQuestionnaire == "risque") && questionsList && questionsList.length ?
                                                        <Row>
                                                            <Col xl="12" className="mb-1">
                                                                <div>
                                                                    <Table className="table table-bordered nowrap w-100">
                                                                        <thead className="thead-light">
                                                                            <tr>
                                                                                <th>Sr. No.</th>
                                                                                <th>Questionnaire Type</th>
                                                                                <th>Year</th>
                                                                                <th>Questionnaire Link</th>
                                                                            </tr>
                                                                            {questionsList && questionsList.map((list: any, key: any) => (
                                                                                <tr>
                                                                                    <td>{key + 1}</td>
                                                                                    <td>{list.questionnaire_type}</td>
                                                                                    <td>{list.year}</td>
                                                                                    <td>
                                                                                        <a
                                                                                            href={`${window.location.origin}/questionnaires/edit-questionnaire/${list.id_questionnaire}/${list.questionnaire_id}`}
                                                                                            className="medium"
                                                                                        >
                                                                                            <span className="btn btn-pill btn-outline-primary btn-sm">
                                                                                                View questionnaire
                                                                                                <i className="fa-solid fa-arrow-up-right-from-square ms-2"></i>
                                                                                            </span>
                                                                                        </a>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                            )}
                                                                        </thead>
                                                                        <tbody>

                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        : null
                                                    }
                                                    <Row>
                                                        {questionPreviewData && questionPreviewData.map((questionrow: any, key: number) => (
                                                            <React.Fragment key={key}>

                                                                {((key > 0) && (questionPreviewData[key - 1] && questionPreviewData[key - 1].tab_name) && (questionPreviewData[key - 1].tab_name !== questionrow.tab_name)) ?
                                                                    <>
                                                                        <Row id={questionrow.tab_name} className="mt-3 mb-3 activeTabItem" onScroll={(e) => handleScroll(e, questionrow.tab_id)}><h4 className="text-primary text-center" id={questionrow.tab_name}>{renderRowHTML(questionrow.tab_name)}</h4></Row>
                                                                        {!template &&
                                                                            <Row>
                                                                                <Dropzone
                                                                                    moduleData={{
                                                                                        module_app_id: 6,
                                                                                        module_main_id: Number(questionnaireID),
                                                                                        module_sub_id: questionnaireID,
                                                                                        section_id: questionrow.tab_id,
                                                                                        file_path: "questionnaire",
                                                                                    }}
                                                                                    fetchData={activeTabnames.includes(questionrow.tab_name) ? true : false}
                                                                                    isOpen={false}
                                                                                    isDisabled={!adminview} />
                                                                            </Row>
                                                                        }
                                                                    </>
                                                                    : null}
                                                                {(key == 0) ? <>
                                                                    <Row id={questionrow.tab_name} className="mt-3 mb-3 activeTabItem" onScroll={(e) => handleScroll(e, questionrow.tab_id)}>
                                                                        <h4 className="text-primary text-center" id="top_questionnaire">{renderRowHTML(questionrow.tab_name)}</h4>
                                                                    </Row>
                                                                    {!template &&
                                                                        <Row className="mb-3">
                                                                            <Dropzone
                                                                                moduleData={{
                                                                                    module_app_id: 6,
                                                                                    module_main_id: Number(questionnaireID),
                                                                                    module_sub_id: questionnaireSubID,
                                                                                    section_id: questionrow.tab_id,
                                                                                    file_path: "questionnaire",
                                                                                }}
                                                                                fetchData={activeTabnames.includes(questionrow.tab_name) ? true : false}
                                                                                isOpen={false}
                                                                                isDisabled={!adminview} />
                                                                        </Row>
                                                                    }
                                                                </>
                                                                    : null}
                                                                {questionrow.input_type === 9 || questionrow.input_type === 21 ?
                                                                    <Row className="mt-3">
                                                                        {questionrow.input_type === 9 ?
                                                                            <MultiInput
                                                                                label={questionrow.question}
                                                                                multi_input_data={questionrow.multi_input_data}
                                                                                reference_name={questionrow.reference_name}
                                                                                handleMultiInputChange={(e: any, ref: any) => handleMultiInputChange(e, ref)}
                                                                                user_data_answer={questionrow.user_data_answer}
                                                                                is_disabled={!adminview}
                                                                                input_type={questionrow.input_type}
                                                                                country_options={countryOptions}
                                                                            />
                                                                            :
                                                                            <TableMultiInput
                                                                                label={questionrow.question}
                                                                                multi_input_data={questionrow.multi_input_data}
                                                                                reference_name={questionrow.reference_name}
                                                                                handleMultiInputChange={(e: any, ref: any) => handleMultiInputChange(e, ref)}
                                                                                user_data_answer={questionrow.user_data_answer}
                                                                                is_disabled={!adminview}
                                                                                input_type={questionrow.input_type}
                                                                                country_options={countryOptions}
                                                                                questionnaireID={questionnaireID}
                                                                                questionnaireSubID={questionnaireSubID}
                                                                                questionrow={questionrow}
                                                                                adminview={adminview}
                                                                                handleSignatureData={handleSignature}
                                                                                language={language}
                                                                            />
                                                                        }
                                                                    </Row>
                                                                    :
                                                                    <Col lg={questionrow.field_width ? questionrow.field_width : 12}>
                                                                        <QuestionView questionrow={questionrow} input_type={questionrow.input_type} field_width={questionrow.field_width} question={questionrow.question} reference_name={questionrow.reference_name} placeholder={questionrow.placeholder} user_data_answer={questionrow.user_data_answer} countryOptions={countryOptions}
                                                                            answer_option_list={questionrow.answer_option_list} handleChange={handleChange} handleKeyDown={handleKeyDown} handleChangeMulti={handleChangeMulti} handleMultiInputChange={(e: any, ref: any) => handleMultiInputChange(e, ref)}
                                                                            handleChangeMulticheckbox={handleChangeMulticheckbox} handleChangeCountry={handleChangeCountry} handlecalender={handlecalender} handleChangecheck={handleChangecheck} adminview={adminview} questionnaireID={questionnaireID} questionnaireSubID={questionnaireSubID} section_id={questionrow.question_id} answerOnly={undefined}
                                                                            multi_input_data={questionrow.multi_input_data}
                                                                            activeTabnames={activeTabnames} required={mandatory}
                                                                            mapped_dates={mappedDates}
                                                                            user_type={userType}
                                                                            showSign={showSignAdd}
                                                                            year={year}
                                                                            setUserAddress={setUserAddress}
                                                                            setCompanyAddress={setCompanyAddress}
                                                                            setCompanyInvAddress={setCompanyInvAddress}

                                                                        />
                                                                    </Col>
                                                                }

                                                            </React.Fragment>
                                                        ))}
                                                    </Row>
                                                    {questionnairestatus != 8 ?
                                                        <Row>
                                                            <Col xl="12" className='mt-3'>
                                                                <CardHeader className="d-none bg-transparent border-bottom">
                                                                    <div className="d-sm-flex flex-wrap">
                                                                        <CardTitle className="card-title text-uppercase">
                                                                            {t('Comments')}
                                                                        </CardTitle>
                                                                    </div>
                                                                </CardHeader>
                                                                {!!questionnaireData &&
                                                                    <Row>
                                                                        <MultiComments
                                                                            moduleData={{
                                                                                module_app_id: 6,
                                                                                module_main_id: Number(questionnaireID),
                                                                                module_sub_id: questionnaireSubID,
                                                                                section_id: 0,
                                                                            }}
                                                                            commentForIDs={[
                                                                                {
                                                                                    value: questionnaireData.user_id,
                                                                                    label:
                                                                                        questionnaireData.user_first_name +
                                                                                        " " +
                                                                                        questionnaireData.user_last_name,
                                                                                    selected: false,
                                                                                },
                                                                                {
                                                                                    value: questionnaireData.file_manager_id,
                                                                                    label:
                                                                                        questionnaireData.file_manager_first_name +
                                                                                        " " +
                                                                                        questionnaireData.file_manager_last_name,
                                                                                    selected: false,
                                                                                },
                                                                            ]}
                                                                            fetchData={true}
                                                                            isOpen={false}
                                                                            isDisabled={!adminview}
                                                                        />

                                                                    </Row>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        : null
                                                    }
                                                    <Row className='questionnaire-footer' id="questionnaire-footer">
                                                        <CardFooter className="d-flex flex-wrap gap-2 bg-transparent justify-content-end">
                                                            <Button
                                                                color="primary"
                                                                className="btn-block"
                                                                type="submit"
                                                                onClick={() => onSubmitQuestionnaire()}
                                                                disabled={data.loading || !adminview}
                                                            >
                                                                {data.loading ? (
                                                                    <span>
                                                                        <i className="fa-solid fa-spinner fa-spin"></i>
                                                                        <span>&nbsp; {t("Please wait", { lng: language })}...</span>
                                                                    </span>
                                                                ) : (
                                                                    <span>{userProfile.CAN_EDIT_ALL && userType !== "user" ? t('FINALIZE BY ADMIN') : (fileManager ? t('FINALIZE') : t('SUBMIT'))} </span>
                                                                )}
                                                            </Button>
                                                            {!!(userType !== "user" && questionnairestatus == 1) && (
                                                                <Button
                                                                    color="warning"
                                                                    className="btn-block"
                                                                    type="submit"
                                                                    onClick={onClickTerminate}
                                                                >
                                                                    {data.loading ? (
                                                                        <span>
                                                                            <i className="fa-solid fa-spinner fa-spin"></i>
                                                                            <span>&nbsp; {t("Please wait", { lng: language })}...</span>
                                                                        </span>
                                                                    ) : (
                                                                        <span>{t('TERMINATE')}</span>
                                                                    )}

                                                                </Button>
                                                            )}
                                                            {userType !== "user" &&
                                                                <Button
                                                                    color="primary"
                                                                    className="btn-block"
                                                                    type="submit"
                                                                    disabled={data.loading || !adminview}
                                                                    onClick={onClickIncomplete}
                                                                >
                                                                    {data.loading ? (
                                                                        <span>
                                                                            <i className="fa-solid fa-spinner fa-spin"></i>
                                                                            <span>&nbsp; {t("Please wait", { lng: language })}...</span>
                                                                        </span>
                                                                    ) : (
                                                                        <span>{t('INCOMPLETE')}</span>
                                                                    )}

                                                                </Button>
                                                            }
                                                            <Button
                                                                color="primary"
                                                                className="btn-block"
                                                                type="submit"
                                                                onClick={onSubmitClose}
                                                                disabled={data.loading || !adminview}
                                                            >
                                                                {data.loading ? (
                                                                    <span>
                                                                        <i className="fa-solid fa-spinner fa-spin"></i>
                                                                        <span>&nbsp; {t("Please wait", { lng: language })}...</span>
                                                                    </span>
                                                                ) : (
                                                                    <span>{t('SAVE')} </span>
                                                                )}
                                                            </Button>
                                                            <Link
                                                                className="btn btn-block btn-outline-danger"
                                                                to={"/questionnaires/all-questionnaires"}
                                                            >
                                                                {t('Cancel')} 
                                                            </Link>
                                                        </CardFooter>
                                                    </Row>
                                                    {loader ? null :
                                                        <>
                                                            <div id="btnScrollUp" className='btn btn-primary btn-floating btn-sm' onClick={(e) => scrollToTop("top_questionnaire")}><i className="fas fa-arrow-up"></i></div>
                                                            <div id="btnScrollDown" className='btn btn-primary btn-floating btn-sm' onClick={(e) => scrollToTop("questionnaire-footer")}><i className="fas fa-arrow-down"></i></div>
                                                        </>
                                                    }
                                                </CardBody>


                                            </Card>
                                        </Col>
                                    </>
                                }
                            </Row>
                            : <div><Alert color="warning">{errorData.message}</Alert></div>}
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default AdminView