import React, { FC, useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardTitle } from "reactstrap";
import { formatCurrency } from "../../../utils/formats";
import invoicesService from "../../../services/invoices.service";
import { toast } from "react-toastify";
import { select2Styles } from "../../../helpers/functions_helper";
import { options, yearOptions, currentYear } from "../../../common/staticdata";
import LoadingSpinner from "../../../components/Common/Loader";
import { noData } from "../../../utils/data";
import Chart from "../../../components/Chart";

type InvoicePerTaskTypeProps = {
    hideHeading?: boolean;
}
const ScheduleOfPaymentsClientHistory: FC<InvoicePerTaskTypeProps> = ({ hideHeading = false }) => {

    const { t } = useTranslation();

    const [loadingText, setLoadingText] = useState<string>('No data found');
    const [loading, setLoading] = useState<boolean>(true);
    const [formValues, setFormValues] = useState<any>({
        invoice_per_task_type: currentYear
    })
    const [categories, setCategories] = useState<any>([]);
    const [series, setSeries] = useState<any>([]);
    const [chartData, setChartData] = useState<any>({
        series: [{
            name: t('Not Due'),
            data: [44, 55, 41, -67, 22, 43, 10],
            color: '#00b57c'
        }, {
            name: '< 30d',
            data: [13, 23, 20, 8, -13, 27],
            color: '#f7d341'
        }, {
            name: '< 60d',
            data: [11, 17, 15, 15, 21, -14],
            color: '#fd9122'
        }, {
            name: '< 90d',
            data: [21, 7, 25, 13, -22, 8],
            color: '#ff7970'
        }, {
            name: '> 90d',
            data: [-21, -7, -25, -13,],
            color: '#f03637'
        }],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                },
            },
            legend: {
                position: 'top',
                offsetX: -10,
                offsetY: 0
            },
            // responsive: [{
            //     breakpoint: 480,
            //     options: {
            //         legend: {
            //             position: 'top',
            //             offsetX: -10,
            //             offsetY: 0
            //         }
            //     }
            // }],
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 5,
                    columnWidth: "50%",
                    dataLabels: {
                        total: {
                            enabled: false,
                            style: {
                                fontSize: '13px',
                                fontWeight: 900
                            }
                        }
                    }
                },
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                categories: [],
            },
            fill: {
                opacity: 1,
            }
        },
    });

    const getInvoicePerTask = async (range: any) => {
        setLoadingText("Loading...");
        try {
            const res = await invoicesService.getScheduleOfPaymentClientsHistory(range);
            if (res.status === 'success') {
                setCategories(res.labels.map((a: any) => a));
                setSeries(res.tasksData.map((a: any) => ({ ...a, name: a?.name })));
                setChartData((state: any) => ({
                    ...state,
                    series: res.tasksData?.map((s: any) => ({ ...s, name: t(s.name) })),
                    options: {
                        ...state.options,
                        labels: res.labels.map((l: any) => t(l))
                    }
                }));
            }
            setLoadingText("No data found");
            setLoading(false);
        } catch (error: any) {
            toast(error)
        }
    }

    useEffect(() => {
        setLoading(true)
    }, [formValues.invoice_per_task_type])

    useEffect(() => {
        loading && getInvoicePerTask(formValues.invoice_per_task_type);
    }, [loading])

    useEffect(() => {
        setChartData((state: any) => ({
            ...state,
            series: series.map((a: any) => ({ ...a, name: t(a?.name) })),
            options: {
                ...state.options,
                labels: categories.map((a: any) => t(a))
            }
        }));
    }, [t])

    return (
        <>
            <div className="d-sm-flex flex-wrap">
                <CardTitle className="mb-3">{!hideHeading && t("Schedule of Payment clients History")}</CardTitle>
                <div className="ms-auto">
                    <Select
                        id="invoice_per_task_type"
                        name="invoice_per_task_type"
                        className="form-select-sm myClassName"
                        styles={select2Styles}
                        value={yearOptions.find((i: any) => i.value === formValues.invoice_per_task_type)}
                        options={yearOptions}
                        onChange={(option: any) => {
                            setFormValues((state: any) => ({ ...state, invoice_per_task_type: option.value }))
                        }}
                    />
                </div>
            </div>
            <Chart loading={loading} options={chartData.options} series={chartData.series} type="bar" height={335} />
        </>

    )
}

export default ScheduleOfPaymentsClientHistory;