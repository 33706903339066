import React, { useState, FC, useEffect } from "react";
import Button from "../../../components/Button/Button";
import { getUserProfile } from "../../../helpers/functions_helper";
import sentimentService from "../../../services/sentiment.service";

type LikeDislikeProps = {
    moduleData: {
        module_app_id: number,
        module_main_id: any,
        module_sub_id?: any,
        section_id?: any,
        liked?: number,
        disliked?: number,
    }
}

type SentimentsType = {
    liked: number;
    disliked: number;
    users?: any;
    is_liked?: boolean;
    is_disliked?: boolean;
}

const LikeDislike: FC<LikeDislikeProps> = ({ moduleData }) => {

    const { module_app_id, module_main_id, module_sub_id, section_id = 0, liked = 0, disliked = 0 } = moduleData;

    const userObj = getUserProfile();

    const [sentiments, setSentiments] = useState<SentimentsType>({ liked: 0, disliked: 0, users: [], is_liked: false, is_disliked: false });

    const getSentimet = async () => {
        const res = await sentimentService.getSentiment(module_app_id, module_main_id, module_sub_id, section_id);
        if (res.status === 'success') {
            setSentiments({ ...res.data });
        }
    }

    const updateSentimet = async (data: any) => {
        const res = await sentimentService.updateSentiment(data);
        if (res.status === 'success') {
            setSentiments({ ...res.data });
        }
    }

    const handleLikeClick = () => {
        // setLikes((like: number) => like + 1)
        // setDisLikes((dislike: number) => dislike - 1)
        const data = {
            ...moduleData,
            liked: 1,
            disliked: 0,
        }
        updateSentimet(data);
    }

    const handleDisLikeClick = () => {
        // setDisLikes((dislike: number) => dislike + 1)
        // setLikes((like: number) => like - 1)
        const data = {
            ...moduleData,
            liked: 0,
            disliked: 1,
        }
        updateSentimet(data);
    }

    useEffect(() => {
        // getSentimet();
        setSentiments((prevData: any) => ({
            ...prevData,
            liked: liked,
            disliked: disliked,
            is_liked: liked > 0 ? true : false,
            is_disliked: disliked > 0 ? true : false,
        }));
    }, [])

    return (
        <div className="gap-1">
            <Button size="sm" color="light" className="btn-rounded mx-2" onClick={handleLikeClick}>
                <i className={`fa-${sentiments?.is_liked ? 'solid' : 'regular'} fa-thumbs-up font-size-14 text-success`}></i> {sentiments.liked || 0}
            </Button>
            <Button size="sm" color="light" className="btn-rounded" onClick={handleDisLikeClick}>
                <i className={`fa-${sentiments?.is_disliked ? 'solid' : 'regular'} fa-thumbs-down font-size-14 fa-flip-horizontal text-danger`}></i> {sentiments.disliked || 0}
            </Button>
        </div >
    )
}

export default LikeDislike;