import moment from "moment";

export const initialValues: cpoTypes = {
  id: "",
  cpo_id: "",
  code: "",
  custome_code: "",
  company_id: "",
  company_value: "",
  employee_id: "",
  employee_value: "",
  family_status: "",
  spouse_earns: "2",
  children_total: 0,
  children_under_3: 0,
  municipal_id: "",
  is_default_tax: 1,
  municipal_tax: 7,
  // computation_year: parseInt(moment().format("YYYY")),
  computation_year: null,
  worldwide_gross_salary: 0,
  sp_country_data: [],
  sp_fringe_benefit_data: [],
  fringe_benefit: "1",
  fringe_benefit_date: "",
  co_2_emission: 0,
  catalogue_price: 0,
  sssc_application: "2",
  employee_group_insurance_premiums: 0,
  benefits_in_social_security: 0,

  created_by_first_name: "",
  created_by_last_name: "",
  created_at: "",
  updated_by_first_name: "",
  updated_by_last_name: "",
  updated_at: "",

  submit_btn_code: 1,
};

export const personalInfoInitialValues: personalInfoCPOTypes = {
  tax_free_amount_for_attribution_to_spouse: 0, // MASTER VALUE
  tax_free_amount_for_children_less_3_year_old: 0, // MASTER VALUE
  tax_free_amount_for_single_parents: 0, // MASTER VALUE
  tax_free_amount_for_taxpayer: 0, // CALCULATED VALUE
  tax_free_amount_for_spouse: 0, // CALCULATED VALUE
  business_expenses_director_percentage: 0, // MASTER VALUE
  business_expenses_director_maximum_amount: 0, // MASTER VALUE
  P_Expenses: [], // MASTER VALUE
  commuting_exemption: 0, // MASTER VALUE
  attribution_to_spouse: 0, // MASTER VALUE
  min_bik_per_year: 0,
  revaluation_factor: 0,
  P_TAXES_EXEMPT_AMOUNT: [],
  P_TAXES: [],
  P_CHILDREN: [],
  companyCarStatuses: [],
  SS_SOCIAL_SECURITY: [],
};

export const familyStatuses = (t: any) => {
  return [
    { value: 1, label: t("Single") },
    { value: 2, label: t("Married / Legal cohabitant") },
    { value: 3, label: t("Divorced") },
  ]
};

export const familyStatusesList = [
  { value: 1, label: "Single" },
  { value: 2, label: "Married / Legal cohabitant" },
  { value: 3, label: "Divorced" },
];

export const yesNo = (t: any) => {
  return [
    { value: 1, label: t("Yes") },
    { value: 2, label: t("No") },
  ]
};

export const empDirIncome = (t: any) => {
  return [
    { value: 1, label: t("Employee") },
    { value: 2, label: t("Director") },
  ]
};

export const taxYear = (id: number, year: number) => {
  const currentYear = year;
  const previousYear = currentYear - 1;
  let taxYearValue = "";

  if (id === 1) {
    taxYearValue = String(currentYear);
  }
  if (id === 2) {
    taxYearValue = String(previousYear);
  }
  if (id === 3) {
    taxYearValue = String(previousYear + "/" + currentYear);
  }
  if (id === 4) {
    taxYearValue = "Double tax treaty";
  }
  return taxYearValue;
};

// export const companyCarStatuses = [
//   {
//     value: 1,
//     label: "No Company car",
//     co2: 0, // MASTER VALUE
//     basic: 0,
//     increase: 0,
//     min: 0,
//     max: 0,
//     applicable: 0,
//     cal_0: 0,
//     cal_1: 0,
//     cal_2: 0,
//     cal_3: 0,
//     cal_4: 0,
//     cal_5: 0,
//     cal_6: 0,
//   },
//   {
//     value: 2,
//     label: "Company car: Petrol / LPG",
//     co2: 0, // MASTER VALUE
//     basic: 5.5,
//     increase: 0.1,
//     min: 4,
//     max: 18,
//     applicable: 0,
//     cal_0: 0,
//     cal_1: 0,
//     cal_2: 0,
//     cal_3: 0,
//     cal_4: 0,
//     cal_5: 0,
//     cal_6: 0,
//   },
//   {
//     value: 3,
//     label: "Company car: Diesel",
//     co2: 0, // MASTER VALUE
//     basic: 5.5,
//     increase: 0.1,
//     min: 4,
//     max: 18,
//     applicable: 0,
//     cal_0: 0,
//     cal_1: 0,
//     cal_2: 0,
//     cal_3: 0,
//     cal_4: 0,
//     cal_5: 0,
//     cal_6: 0,
//   },
//   {
//     value: 4,
//     label: "Company car: Electric",
//     co2: 0, // MASTER VALUE
//     basic: 4,
//     increase: 0,
//     min: 4,
//     max: 4,
//     applicable: 0,
//     cal_0: 0,
//     cal_1: 0,
//     cal_2: 0,
//     cal_3: 0,
//     cal_4: 0,
//     cal_5: 0,
//     cal_6: 0,
//   },
// ];

export const companyCarPercentile = [
  {
    months_range: "0 - 12 months",
    months_value: 100,
  },
  {
    months_range: "13 - 24 months",
    months_value: 94,
  },
  {
    months_range: "25 - 36 months",
    months_value: 88,
  },
  {
    months_range: "37 - 48 months",
    months_value: 82,
  },
  {
    months_range: "49 - 60 months",
    months_value: 76,
  },
  {
    months_range: "> 60 months",
    months_value: 70,
  },
];

// export const ssSocialSecurity = [
//   {
//     C122: 0,
//     C123: 0,
//     C124: 0,
//     C125: 0,

//     D122: 14658.44, // MASTER VALUE
//     D123: 63297.86, // MASTER VALUE
//     D124: 93281.01, // MASTER VALUE
//     D125: 0,

//     E122: 0,
//     E123: 20.5,
//     E124: 14.16,
//     E125: 0,

//     F122: 3123.68, // MASTER VALUE
//     F123: 0,
//     F124: 0,
//     F125: 0,
//   },
// ];

// MASTER VALUE
// export const P_TAXES_EXEMPT_AMOUNT = [
//   [0, 9750, 0.25, 0],
//   [9750, 13870, 0.3, 2437.5],
//   [13870, 23120, 0.4, 3673.5],
//   [23120, 42370, 0.45, 7373.5],
//   [42370, 0, 0.5, 16036],
// ];
// export const P_TAXES = [
//   [0, 13870, 0.25, 0],
//   [13870, 24480, 0.4, 3467.5],
//   [24480, 42370, 0.45, 7711.5],
//   [42370, 0, 0.5, 15762],
// ];
// export const P_CHILDREN = [
//   [0, 0, 0, 0],
//   [1, 0, 0, 1690],
//   [2, 0, 0, 4340],
//   [3, 0, 0, 9730],
//   [4, 0, 0, 15740],
//   [5, 6010, 0, 21750],
//   [6, 0, 0, 27760],
// ];
// export const P_Expenses = [
//   [0, 16799.98, 0.3, 0],
//   [16799.98, 0, 0, 5040],
// ];
export const P_SSSC = [
  [0, 0, 0, 0],
  [18592.02, 0, 0.09, 18592.02],
  [21070.97, 223.1, 0.013, 21070.96],
  [60161.86, 731.28, 0, 0],
];
// export const SS_SOCIAL_SECURITY = [
//   [0, 14658.44, 0, 3123.68],
//   [14658.44, 63297.86, 20.5, 3123.68],
//   [63297.86, 93281.01, 14.16, 13094.76],
//   [93281.01, 0, 0, 17340.38],
// ];
