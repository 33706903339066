import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Button } from "reactstrap";

import SimpleBar from "simplebar-react";
import { toast } from "react-toastify";
import shortcutService from "../../../services/shortcut.service";
import { useTranslation } from "react-i18next";

const CreateShortcuts = (props: any) => {
  const { t } = useTranslation();
  const toastId = React.useRef(null) as any;

  const [menu, setMenu] = useState<boolean>(false);
  const [shortcutData, setShortcutData] = useState([]);
  const [shortcutExist, setShortcutExist] = useState(false);

  const createShortcut = async () => {
    let title = document?.title?.split("|")[0]?.trim();
    let url = window.location.pathname;

    try {
      toastId.current = toast(`${t("Creating")} ${t("shortcut")}...`, {
        autoClose: false,
        isLoading: true,
      });

      const res = (await shortcutService.create({
        url: url,
        title: title,
      })) as any;

      if (res.data.status === "success") {
        getAll();
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
      }
    } catch (err) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: t("Something went wrong."),
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
  };

  const deleteShortcut = async () => {
    let url = window.location.pathname;

    try {
      toastId.current = toast(`${t("Deleteing")} ${t("shortcut")}...`, {
        autoClose: false,
        isLoading: true,
      });

      const res = (await shortcutService.delete({
        url: url,
      })) as any;

      if (res.data.status === "success") {
        getAll();
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
      }
    } catch (err) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: t("Something went wrong."),
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
  };

  const getAll = async () => {
    try {
      const res = (await shortcutService.getAll()) as any;

      if (res.data.status === "success") {
        setShortcutData(res.data.data);
      } else {
        setShortcutData([]);
      }
    } catch (err) {
      setShortcutData([]);
    }
  };

  useEffect(() => {
    getAll();
  }, []);

  useEffect(() => {
    let shortcut_exist = shortcutData.find(
      (data: any) => data.url === window.location.pathname
    );
    if (shortcut_exist) {
      setShortcutExist(true);
    } else {
      setShortcutExist(false);
    }
  }, [shortcutData, window.location.pathname]);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon"
          tag="button"
          id="page-header-shortcut-dropdown"
          title={t("Shortcuts")}
        >
          <span className="align-middle material-symbols-outlined fw-light">
            dashboard_customize
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <SimpleBar style={{ maxHeight: "230px" }}>
            <div className="px-lg-2">
              <div className="no-gutters row">
                {shortcutData && shortcutData.length > 0
                  ? shortcutData.map((data: any, index: number) => (
                    <div key={index} className="col-4">
                      <Link to={`${data.url}`} className="dropdown-icon-item" style={{ lineHeight: "25px" }}>
                        <span
                          className="align-middle material-symbols-outlined text-muted fw-lighter"
                          style={{ fontSize: "40px" }}
                        >
                          {data.icon_class}
                        </span>
                        {data.title.indexOf('-') === -1 ? t(data.title) : t(data.title.split(' - ')[0]) + ' ' + t(data.title.split(' - ')[1])}
                      </Link>
                    </div>
                  ))
                  : null}
              </div>
            </div>
          </SimpleBar>
          {!shortcutExist && (
            <div className="p-2 border-top d-grid">
              <Button
                className="btn btn-sm font-size-12 text-center"
                onClick={createShortcut}
              >
                <span className="align-middle me-1 material-symbols-outlined fw-light fs-4">
                  add_circle
                </span>
                {t("Add shortcut")}
              </Button>
            </div>
          )}
          {shortcutExist && (
            <div className="p-2 border-top d-grid">
              <Button
                className="btn btn-sm font-size-12 text-center"
                onClick={deleteShortcut}
              >
                <span className="align-middle me-1 material-symbols-outlined fw-light fs-4">
                  cancel
                </span>
                {t("Remove shortcut")}
              </Button>
            </div>
          )}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default CreateShortcuts;
