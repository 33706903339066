import React, { useEffect, useState } from "react";
import DataTable from "../../../components/Common/DataTable";

//redux
import { useDispatch } from "react-redux";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  CardTitle,
  UncontrolledAlert,
  Container,
} from "reactstrap";

import { Link } from "react-router-dom";
import DeleteModal from "../../../components/Common/DeleteModal";
import Breadcrumbs from "../../../components/Layout/Breadcrumb";

import { toast } from "react-toastify";

// Import Master Modal
import StatusModal from "./Form";
import mastersRegionService from "../../../services/masters.region.service";
import { getDataTableQuery } from "../../../helpers/functions_helper";
import { useTranslation } from "react-i18next";

const breadcrumbItems = [
  {
    url: "/dashboard",
    label: "Dashboard",
    active: false,
  },
  {
    url: "#",
    label: "Region",
    active: true,
  },
];

const Region = (props: any) => {
  document.title = "Region | All regions | " + process.env.REACT_APP_NAME;
  const toastId = React.useRef(null) as any;
  const { t } = useTranslation();
  // DataTable ----------------------------------------------
  function renderHeaderHTML(column: any, colIndex: any) {
    return <span dangerouslySetInnerHTML={{ __html: column.text }} />;
  }
  function renderRowHTML(rowContent: any, row: any) {
    return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
  }

  const columns = [
    {
      dataField: "DT_RowIndex",
      text: "Sr. No.",
      sort: true,
      search: false,
    },
    {
      dataField: "region",
      text: "Region",
      sort: true,
      search: true,
      formatter: renderRowHTML,
    },
    {
      dataField: "action",
      text: "Action",
      sort: false,
      search: false,
      formatter: (rowContent: any, row: any): any => {
        return (
          <div className="d-flex gap-2 text-end">
            <Link
              className="btn btn-pill btn-outline-primary btn-sm"
              to="#"
              onClick={() => onClickEdit(row)}
            >
              <i className="fa-regular fa-pen-to-square"></i> Edit
            </Link>
            <Link
              className="btn btn-pill btn-outline-danger btn-sm"
              to="#"
              onClick={() => onClickDelete(row)}
            >
              <i className="fa-regular fa-trash-can"></i> Delete
            </Link>
          </div>
        );
      },
    },
  ];

  //get department list
  const [loadingRegionData, setLoadingRegionData] = useState(true);
  const [datatableOptions, setDatatableOptions] = useState({
    draw: 1,
    page: 1,
    recordsTotal: 0,
    recordsFiltered: 0,
    sizePerPage: 20,
    searchText: "",
    sortField: "id",
    sortOrder: "desc",
  });

  useEffect(() => {
    if (loadingRegionData === true) {
      loadingDatatable();
    }
  }, [loadingRegionData]);

  const [regionListData, setRegionListData] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadingDatatable = async () => {
    try {
      const res = (await mastersRegionService.getRegionDatatableList({
        ...getDataTableQuery(datatableOptions, columns),
      })) as any;

      if (res.data.status === "success") {
        setRegionListData(res.data.data);

        const updatedDatatableOptions = {
          ...datatableOptions,
          recordsTotal: res.data.recordsTotal,
          recordsFiltered: res.data.recordsFiltered,
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingRegionData(false);
      } else {
        setRegionListData([]);

        const updatedDatatableOptions = {
          ...datatableOptions,
          recordsTotal: 0,
          recordsFiltered: 0,
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingRegionData(false);
      }
    } catch (err) {
      setRegionListData([]);

      const updatedDatatableOptions = {
        ...datatableOptions,
        recordsTotal: 0,
        recordsFiltered: 0,
      };
      setDatatableOptions(updatedDatatableOptions);
      setLoadingRegionData(false);
    }
  };



  // get store data
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  // useEffect(() => {
  //   if (data.statuslist?.data) {
  //     setRegionListData(data.statuslist.data);

  //     const updatedDatatableOptions = {
  //       ...datatableOptions,
  //       recordsTotal: data.statuslist.recordsTotal,
  //       recordsFiltered: data.statuslist.recordsFiltered,
  //     };
  //     setDatatableOptions(updatedDatatableOptions);
  //     setLoadingRegionData(false);
  //   }
  // }, [data.statuslist]);



  const onTableChange = (type: any, newState: any) => {
    let draw = datatableOptions.draw + 1;
    let page = 1;
    if (type == "search") {
      page = 1;
    } else {
      page = newState.page;
    }

    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: draw,
      page: page,
      sizePerPage: newState.sizePerPage,
      searchText: newState.searchText,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
      scrollY: "300px",
      scrollX: true,
      scrollCollapse: true,
      fixedColumns: {
        left: 1,
        right: 1
      }
    };
    setDatatableOptions(updatedDatatableOptions);
    setLoadingRegionData(true);
  };
  const clearFilter = () => {
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: 1,
      page: 1,
      sizePerPage: 20,
      searchText: "",
      sortField: "id",
      sortOrder: "desc",
    };
    setDatatableOptions(updatedDatatableOptions);
    setLoadingRegionData(true);
  };
  // Datatable -------------------------------------------------

  // Master Form --------------------------------------------------------
  const [regionData, setRegionData] = useState({});
  const [masterFormModal, setMasterFormModal] = useState(false);
  const [masterFormModalTitle, setMasterFormModalTitle] =
    useState("Create Region");

  const onClickModal = () => {
    setRegionData("");
    setMasterFormModalTitle("Create Region");
    setMasterFormModal(true);
  };

  const onClickEdit = (row: any) => {
    setRegionData(row);
    setMasterFormModalTitle("Update Region");
    setMasterFormModal(true);
  };

  const handleSubmit = (values: any) => {
      if (values.id && values.region_id) {
        update(values);
      }else{
        create(values);
      }
  };



  const create = async (values: any) => {
    try {
      setLoading(true);
      toastId.current = toast(t("Creating region..."), {
        autoClose: false,
        isLoading: true,
      });
      const res = (await mastersRegionService.createRegion(values)) as any;
      if (res?.data.status === "success") {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoading(false);
        setLoadingRegionData(true);
        setMasterFormModal(false);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        if (res.data.data) {
          Object.keys(res.data.data).map((key) => {
            toast(res.data.data[key][0], {
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              delay: 500,
            });
          });
        }
        setLoading(false);
      }
    } catch (err: any) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }
  };

  const update = async (values: any) => {
    try {
      setLoading(true);
      toastId.current = toast(t("Updating region..."), {
        autoClose: false,
        isLoading: true,
      });
      
      const res = (await mastersRegionService.updateRegion(values)) as any;
      if (res?.data.status === "success") {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoading(false);
        setLoadingRegionData(true);
        setMasterFormModal(false);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        if (res.data.data) {
          Object.keys(res.data.data).map((key) => {
            toast(res.data.data[key][0], {
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              delay: 500,
            });
          });
        }
        setLoading(false);
      }
    } catch (err: any) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }
  };


  const deleteRecord = async (id:any, region_id:any) => {
    try {
      setLoading(true);
      toastId.current = toast(t("Deleting region..."), {
        autoClose: false,
        isLoading: true,
      });
      
      const res = (await mastersRegionService.deleteRegion({id, region_id})) as any;
      if (res?.data.status === "success") {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoading(false);
        setLoadingRegionData(true);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        if (res.data.data) {
          Object.keys(res.data.data).map((key) => {
            toast(res.data.data[key][0], {
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              delay: 500,
            });
          });
        }
        setLoading(false);
      }
    } catch (err: any) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }
  };



  // Master Form --------------------------------------------------------

  // Delete Form --------------------------------------------------------
  const [deleteData, setDeleteData] = useState({
    id: "",
    region_id: "",
  });
  const [recordName, setRecordName] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (row: any) => {
    const deleteRowData = {
      ...deleteData,
      id: row.id,
      region_id: row.region_id,
    };
    setDeleteData(deleteRowData);
    setRecordName(row.region);
    setDeleteModal(true);
  };

  const handleDeleteData = () => {
    setDeleteModal(false);
    setLoadingRegionData(true);
    deleteRecord(deleteData.id, deleteData.region_id)
  };
  // Delete Form --------------------------------------------------------


  // useEffect(() => {
  //   if (data.success !== "") {
  //     toast(data.success, { type: toast.TYPE.SUCCESS })
  //     setMasterFormModal(false);
  //     setDeleteModal(false);
  //     setLoadingRegionData(true);
  //   }
  //   if (data.error !== "") {
  //     toast(data.error, { type: toast.TYPE.ERROR })
  //   }
  // }, [data.success, data.error, dispatch]);

  return (
    <React.Fragment>
      {regionData && (
        <DeleteModal
          recordName={recordName}
          modalTitle="Region"
          show={deleteModal}
          onDeleteClick={handleDeleteData}
          onCloseClick={() => setDeleteModal(false)}
          loading={false}
        />
      )}

      {masterFormModal && (
        <StatusModal
          modalTitle={masterFormModalTitle}
          show={masterFormModal}
          loading={loading}
          regionData={regionData}
          onSubmitClick={handleSubmit}
          onCloseClick={() => setMasterFormModal(false)}
          errorMsg={error}
        />
      )}

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle="Regions"
            breadcrumbItems={breadcrumbItems}
            endAdornment={
              <Button
                color="primary"
                className="btn btn-primary btn-sm waves-light btn-label"
                onClick={() => onClickModal()}
              >
                <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">add</span>
                Create Region
              </Button>
            }
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardHeader className="bg-transparent border-bottom">
                  <div className="d-sm-flex flex-wrap">
                    <CardTitle className="card-title text-uppercase">
                      Region
                    </CardTitle>
                  </div>
                </CardHeader>
                <CardBody>
                  <DataTable
                    columns={columns}
                    products={regionListData}
                    datatableOptions={datatableOptions}
                    filters={[]}
                    onTableChange={onTableChange}
                    loadingListData={loadingRegionData}
                    clearFilter={clearFilter}
                    slug="all-regions"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  );
};
export default Region;