import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container, Label, Nav, NavItem, Row, Table, UncontrolledAlert } from 'reactstrap'
import DeleteModal from '../../../components/Common/DeleteModal';
import Breadcrumbs from '../../../components/Layout/Breadcrumb';
import { getDataTableQuery, getDependentSelectOptionsWithOrder, handleFilters } from '../../../helpers/functions_helper';
import { QuestionnaireQuestionAction } from "../../../store/actions";

import DataTable from "../../../components/Common/DataTable"

import PreviewModal from "./Preview";
import TabMenu from '../QuestionnaireTab/TabMenu';
import commonService from '../../../services/common.service';
import { toast } from "react-toastify";

import Drawer from '../../../components/Drawer';
import Filters from './Filters';
import { useTranslation } from 'react-i18next';


const Questions = (props: any) => {
    const {t} = useTranslation();
    const breadcrumbItems = [
        {
            url: "/dashboard",
            label: t("Dashboard"),
            active: false,
        },
        {
            url: "#",
            label: t("Questions"),
            active: true,
        },
    ];
    const dispatch = useDispatch();
    const savedfilters = handleFilters("GET");
    document.title = "Questionnaire | All Questionnaire questions | " + process.env.REACT_APP_NAME;


    /* set tab*/
    const [loadingTab, setLoadingTab] = useState(true);
    const [tabOptions, setTabOptions] = useState([]);
    const [conditionData, setConditionData] = useState({});
    const [tabId, setTabID] = useState(0);
    const [tab, setTab] = useState({
        value: 0,
        label: t("Please select..."),
    });
    const [tabData, setTabData] = useState({
        id: "",
    });


    /* set user Status */
    const [user_value, setUser_value] = useState<any>("");



    // Select options list API call -------------------------------------------------
    const [order, setOrder] = useState("");


    const [query, setQuery] = useState("");
    const getSelectOptions = async (tableName: string, fieldName: string) => {
        const response = await commonService.getSelectOptionsList(
            tableName,
            fieldName,
            query ? query : ""
        );
        return response.data.data;
    };

   


    useEffect(() => {
        if (user_value) {
            getDependentSelectOptionsWithOrder("tbl_master_questionnaire_tab", "questionnaire_tab_value", "user_type", user_value, "order").then(function (options) {
                setOrder("order");

                setTabOptions(options);
                setLoadingTab(false);
                if (options[0]) {
                    setTab(options[0]);
                    setTabID(options[0].value);
                }
                else {
                    setTab({
                        value: 0,
                        label: t("Please select..."),
                    });
                    setTabID(0);
                }

            });
        }

    }, [user_value]);




    //Question modal



    // get store data
    let data = useSelector((state: any) => ({
        success: state.QuestionnaireQuestions.success,
        questionslist: state.QuestionnaireQuestions.GET_QUESTIONS_LIST,
        error: state.QuestionnaireQuestions.error,
        isModel: state.QuestionnaireQuestions.isModel,
        loading: state.QuestionnaireQuestions.loading,
        successDrag: state.Common.CHANGE_TABLE_ORDER_ON_DRAG_SUCCESS.message,
        errorDrag: state.Common.CHANGE_TABLE_ORDER_ON_DRAG_ERROR.message,
    }))

    const [questionsListData, setQuestionsListData] = useState<Array<any>>([]);
    const [loadingquestionslist, setLoadingquestionslist] = useState(true);
    const [questionsData, setQuestionsData] = useState({});
    const [masterFormModal, setMasterFormModal] = useState(false);
    const [masterFormModalTitle, setMasterFormModalTitle] = useState<any>(t("Create Questions"));
    const [previewModel, setPreviewModel] = useState(false);
    const [previewTitle, setPreviewTitle] = useState(t("Preview questions"));

    useEffect(() => {
        if (data.successDrag) {
            setLoadingquestionslist(true);
            window.scrollTo(0, 0);
        }
    }, [data.successDrag, data.errorDrag]);

    useEffect(() => {
        if (data.errorDrag) {
            setLoadingquestionslist(true);
        }
    }, [data.errorDrag]);

    useEffect(() => {
        if (data.success !== "" && deleteModal) {
            toast(data.success, { type: toast.TYPE.SUCCESS })
            setMasterFormModal(false);
            setDeleteModal(false);
            setLoadingquestionslist(true);
        }
        if (data.error !== "" && deleteModal) {
            toast(data.error, { type: toast.TYPE.ERROR })
        }
    }, [data.success, data.error, dispatch]);

    useEffect(() => {
        if (loadingquestionslist === true) {
            dispatch(
                QuestionnaireQuestionAction.getQuestionnaireQuestions(
                    { ...getDataTableQuery(datatableOptions, columns), filters },
                    props.history
                )
            );
        }
    }, [loadingquestionslist]);



    useEffect(() => {
        if (data.questionslist?.data) {
            setQuestionsListData(data.questionslist.data);
            const updatedDatatableOptions = {
                ...datatableOptions,
                recordsTotal: data.questionslist.recordsTotal,
                recordsFiltered: data.questionslist.recordsFiltered,
            };
            setDatatableOptions(updatedDatatableOptions);
            setLoadingquestionslist(false);
        }
    }, [data.questionslist]);

    const onClickModal = () => {
        setQuestionsData('');
        setMasterFormModalTitle(t("Create Question"));
        setMasterFormModal(true);
    };

    const onClickModalPreview = () => {
        setPreviewModel(true);
    };

    //datatable with drag and drop functionality
    function renderHeaderHTML(column: any, colIndex: any) {
        return (
            <span dangerouslySetInnerHTML={{ __html: column.text }} />
        );
    }
    function renderRowHTML(rowContent: any) {
        return (
            <span dangerouslySetInnerHTML={{ __html: rowContent }} />
        );
    }

    useEffect(()=>{
        setLoadingquestionslist(true);
        setQuestionsListData([]);
      },[localStorage.getItem("I18N_LANGUAGE")])

    /* filter*/
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [filters, setFilters] = useState(
        savedfilters && savedfilters?.filters
            ? (savedfilters?.filters as any)
            : {
                is_filtered: false,
                user_type: "",
                year: ((new Date()).getFullYear()),
                questionnaire_type_id: 0,
                tab_id: 0,
            }
    );
    const handleFiltersChange = (obj: any) => {
        setFilters((state: any) => ({ ...state, ...obj }));
        setLoadingquestionslist(true);
    };

    const handleApplyFilters = () => {
        setLoadingquestionslist(true);
        setOpenFilter(false);
    };




    const columns = [
        {
            dataField: "DT_RowIndex",
            text: `${t('Sr. No.')}`,
            sort: true,
            search: false,
            classes: 'fix-col-left',
            headerClasses: 'fix-col-left',
            headerStyle: { width: '155px' },
            style: { width: '155px' },
        },

        {
            dataField: 'question_value_html',
            text: `${t('Question')}`,
            sort: true,
            search: true,
            classes: 'fix-col-left',
            headerClasses: 'fix-col-left',
            style: { left: '90px' },
            headerStyle: { left: '90px' },
            formatter: renderRowHTML,
        },
        {
            dataField: 'input_type_name',
            text: `${t('Input type')}`,
            sort: true,
            search: true,
            formatter: renderRowHTML,
        },
        {
            dataField: 'type_name',
            text: `${t('Questionnaire type')}`,
            sort: true,
            search: true,
            formatter: renderRowHTML,
        },
        {
            dataField: 'tab_name',
            text: `${t('Tab')}`,
            sort: true,
            search: true,
            formatter: renderRowHTML,
        },
        {
            dataField: 'answers_option_value',
            text: `${t('Answers')}`,
            sort: true,
            search: true,
            formatter: renderRowHTML,
        },
        {
            dataField: 'condition_reference_question_html',
            text: `${t('1.Display this question when')}`,
            sort: true,
            search: true,
            formatter: renderRowHTML,
        },
        {
            dataField: 'condition_answer_label_html',
            text: `${t('1.Display this question when answer')}`,
            sort: true,
            search: true,
            formatter: renderRowHTML,
        },
        // {
        //     dataField: 'condition_reference_question_2_html',
        //     text: '2.Display this question when',
        //     sort: true,
        //     search: true,
        //     formatter: renderRowHTML,
        // },
        // {
        //     dataField: 'condition_answer_label_2_html',
        //     text: '2.Display this question when answer',
        //     sort: true,
        //     search: true,
        //     formatter: renderRowHTML,
        // },
        {
            dataField: 'same_page_question_question_text',
            text: `${t('Display samepage which contains')}`,
            sort: true,
            search: true,
            formatter: renderRowHTML,
        },
        {
            dataField: 'required_field_value',
            text: `${t('Mandatory')}`,
            sort: true,
            search: true,
            formatter: renderRowHTML,

        },
        {
            dataField: 'order',
            text: `${t('Order')}`,
            sort: true,
            search: true,
            formatter: renderRowHTML,
        },
        // {
        //     dataField: 'info_html',
        //     text: 'Information',
        //     sort: true,
        //     search: true,
        //     formatter: renderRowHTML,
        // },

        {
            dataField: "action",
            text: `${t('Action')}`,
            sort: false,
            search: false,
            formatter: (rowContent: any, row: any): any => {
                return (
                    <div className="d-flex gap-2 text-end">
                        <Link
                            className="btn btn-pill btn-outline-primary btn-sm"
                            to={"/masters/questionnaire/duplicate-question/" + row.id + "/" + row.question_id + "/1"}
                        >
                            <i className="fa-regular fa-clone"></i> {t('Duplicate')}
                        </Link>
                        <Link
                            className="btn btn-pill btn-outline-primary btn-sm"
                            to={"/masters/questionnaire/edit-question/" + row.id + "/" + row.question_id}
                        >
                            <i className="fa-regular fa-pen-to-square"></i> {t('Edit')}
                        </Link>
                        {/* <Link
                            className="btn btn-pill btn-outline-primary btn-sm"
                            to="#"
                            onClick={() => onClickEdit(row)}
                        >
                            <i className="fa-regular fa-pen-to-square"></i> Edit
                        </Link> */}
                        <Link
                            className="btn btn-pill btn-outline-danger btn-sm"
                            to="#"
                            onClick={() => onClickDelete(row)}
                        >
                            <i className="fa-regular fa-trash-can"></i> {t('Delete')}
                        </Link>
                    </div>
                );
            },
        },
    ];

    const [datatableOptions, setDatatableOptions] = useState(
        savedfilters && savedfilters?.datatableOptions
            ? (savedfilters?.datatableOptions as any)
            : {
                draw: 1,
                page: 1,
                recordsTotal: 0,
                recordsFiltered: 0,
                sizePerPage: 20,
                searchText: "",
                sortField: "id",
                sortOrder: "desc",
            }
    );

    const [orderChange, setOrderChange] = useState(false);
    useEffect(() => {
        setLoadingquestionslist(true);
    }, [orderChange]);

    const onTableChange = (type: any, newState: any) => {
        let draw = datatableOptions.draw + 1;
        let page = 1;
        if (type == "search") {
            page = 1;
        } else {
            page = newState.page;
        }

        const updatedDatatableOptions = {
            ...datatableOptions,
            draw: draw,
            page: page,
            sizePerPage: newState.sizePerPage,
            searchText: newState.searchText,
            sortField: newState.sortField,
            sortOrder: newState.sortOrder,
            scrollY: "300px",
            scrollX: true,
            scrollCollapse: true,
            fixedColumns: {
                left: 1,
                right: 1
            }
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingquestionslist(true);
    };


    const clearFilter = () => {
        const updatedDatatableOptions = {
            ...datatableOptions,
            draw: 1,
            page: 1,
            sizePerPage: 20,
            searchText: "",
            sortField: "id",
            sortOrder: "desc",
        };
        setDatatableOptions(updatedDatatableOptions);

        const filter = {
            ...filters,
            is_filtered: false,
            user_type: "",
            year: ((new Date()).getFullYear()),
            questionnaire_type_id: 0,
            tab_id: 0,
        }
        setFilters(filter);

        setLoadingquestionslist(true);
    };
    // Datatable -------------------------------------------------

    // edit
    const [duplicateData, setDuplicateData] = useState(false);
    const onClickEdit = (row: any) => {
        setDuplicateData(false);
        setQuestionsData(row);
        setMasterFormModalTitle(t("Update Question"));
        setMasterFormModal(true);
    }

    const onClickDuplicate = (row: any) => {
        setDuplicateData(true);
        setQuestionsData(row);
        setMasterFormModalTitle(t("Duplicate Question"));
        setMasterFormModal(true);
    }

    // Delete Form --------------------------------------------------------
    const [deleteData, setDeleteData] = useState({
        id: "",
        question_id: ""
    });
    const [recordName, setRecordName] = useState("");
    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = (row: any) => {
        setDeleteModal(true);
        const deleteRowData = {
            ...deleteData,
            id: row.id,
            question_id: row.question_id,
        };
        setDeleteData(deleteRowData);
        const quest: any = renderRowHTML(row.question_html);
        setRecordName(quest);
    };


    const handleDeleteData = () => {
        dispatch(QuestionnaireQuestionAction.deleteQuestionnaireQuestions(deleteData, props.history));
    };

    return (
        <React.Fragment>
            <div className="page-content questionnaire-list">
                {deleteModal && (
                    <DeleteModal
                        recordName={recordName}
                        modalTitle={t("Questionnaire questions")}
                        show={deleteModal}
                        onDeleteClick={handleDeleteData}
                        onCloseClick={() => setDeleteModal(false)}
                        loading={false}
                    />
                )}


                {previewModel && (
                    <PreviewModal
                        modalTitle={previewTitle}
                        show={previewModel}
                        tab={tabId}
                        tabname={tab.label}
                        loading={data.loading}
                        questionsData={questionsListData}
                        onCloseClick={() => setPreviewModel(false)}
                    />
                )}
                <Container fluid>
                    <Breadcrumbs
                        breadcrumbTitle={t("Questions")}
                        breadcrumbItems={breadcrumbItems}
                        endAdornment={
                            <Link
                                to="/masters/questionnaire/create-questions"
                                className="btn btn-primary btn-sm waves-effect waves-light btn-label">
                                <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">add</span> {t("Create Questions")}
                            </Link>
                        }
                    />
                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardHeader className="bg-transparent border-bottom pb-0">
                                    <TabMenu type="questions" onClickModalPreview={onClickModalPreview} />
                                </CardHeader>
                                <CardBody>
                                    <Row className='filter-form-row'>
                                        <Filters
                                            filters={filters}
                                            onApply={() => { }}
                                            onClear={() => { }}
                                            onChange={(obj: any) => handleFiltersChange({ ...obj, is_filtered: true })}
                                            hideTab={true}
                                        />
                                    </Row>
                                    {questionsListData &&
                                        <DataTable
                                            columns={columns}
                                            products={questionsListData}
                                            datatableOptions={datatableOptions}
                                            filters={filters}
                                            onTableChange={onTableChange}
                                            clearFilter={clearFilter}
                                            loadingListData={loadingquestionslist}
                                            tableName="tbl_master_questionnaire_questions"
                                            dependentField="tab_id"
                                            dependentId={tabId}
                                            settingTableName="tbl_master_questionnaire_questions"
                                            setOrderChange={setOrderChange}
                                            slug="all-questionnaires-question"
                                            customButtons={
                                                <Button
                                                    outline
                                                    color={filters.is_filtered === true ? "secondary" : "light"}
                                                    onClick={() => setOpenFilter((state) => !state)}
                                                    title={t("Filter")}
                                                >
                                                    {filters.is_filtered === false ? (
                                                        <span className="align-middle material-symbols-outlined fw-light fs-4">filter_alt</span>
                                                    ) : (
                                                        <span className="align-middle material-symbols-outlined fw-light fs-4">filter_alt_off</span>
                                                    )}
                                                </Button>
                                            }
                                        />
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Drawer
                title={t("Filters")}
                open={openFilter}
                onClose={() => setOpenFilter((state) => !state)}
                footer={
                    <Button
                        color="primary"
                        style={{ margin: "16px" }}
                        onClick={handleApplyFilters}
                    >
                        {t("Apply")}
                    </Button>
                }
            >
                <Filters
                    filters={filters}
                    onApply={() => { }}
                    onClear={() => { }}
                    onChange={(obj: any) => handleFiltersChange({ ...obj, is_filtered: true })}
                    hideTab={true}
                />
            </Drawer>
        </React.Fragment>
    )
}

export default Questions