import React, { useEffect, useState } from 'react'
import { Button, Col, Modal, ModalBody, Row, Progress } from 'reactstrap'
import { Link } from 'react-router-dom';
import commonService from '../../../services/common.service';
import moment from 'moment';
import MultiInput from '../../Questionnaire/MultiInput';
import QuestionView from '../../Questionnaire/QuestionView';
import TableMultiInput from '../../Questionnaire/multiinput/Table';
import { useTranslation } from 'react-i18next';

const Preview = (props: any) => {
    const {t} = useTranslation();
    //get questions
    const [questionPreviewData, setQuestionPreviewData] = useState(props.questionsData);
    const [activeSubTab, setactiveSubTab] = useState(1);
    const [totaltab, settotaltab] = useState(props.questionsData.length > 1 ? props.questionsData[props.questionsData.length - 1].indexval : 0);

    const [intialData, setIntialData] = useState<any>({});
    /// file upload
    const [multiUploadStatus, setMultiUploadStatus] = useState(false);
    const multiUploadSuccess = (isSuccess: boolean) => {
        if (isSuccess === true) {
            setMultiUploadStatus(true);
        }
    };

    const [query, setQuery] = useState("");
    const getSelectOptions = async (tableName: string, fieldName: string) => {
        const response = await commonService.getSelectOptionsList(
            tableName,
            fieldName,
            query ? query : ""
        );
        return response.data.data;
    };
    const [loadingCountry, setLoadingCountry] = useState(true);
    const [countryOptions, setCountryOptions] = useState([]);
    const [loadingNationality, setLoadingNationality] = useState(true);
    const [nationalityOptions, setNationalityOptions] = useState([]);
    const [multicheck, setMulticheck] = useState<any>([]);
    const [multicheckname, setMulticheckname] = useState("");
    useEffect(() => {
        getSelectOptions("tbl_master_country", "name").then(function (options: any) {
            setCountryOptions(options);
            setLoadingCountry(false);
            setNationalityOptions(options);
            setLoadingNationality(false);
        });
    }, []);

    useEffect(() => {
        if (props.questionsData) {
            updateQuestionAr(props.questionsData);
        }
    }, [intialData])


    const updateQuestionAr = (questionDataAr: any) => {
        let questionAr: any = [];
        const condtionObj = Object.keys(intialData);
        const condtionAns = Object.values(intialData);
        if (questionDataAr) {
            questionDataAr.forEach((question: any) => {
                let checkcondition = false
                if ((question.condition_reference_question == null) || (!condtionObj.includes(question.condition_reference_question) && !condtionObj.includes(question.condition_reference_question_2))) {
                    questionAr.push(question);
                } else if (condtionObj.includes(question.condition_reference_question)) {
                    const answer_label = question.condition_answer_label;
                    const index = condtionObj.indexOf(question.condition_reference_question);
                    if (answer_label !== null && question.condition_input_type != 2) {
                        const answAr = answer_label.split(',');
                        if (answAr.includes(condtionAns[index]) && (!condtionObj.includes(question.condition_reference_question_2) || question.condition_reference_question_2 == null)) {
                            questionAr.push(question);
                        } else if (answAr.includes(condtionAns[index])) {
                            checkcondition = true;
                        }
                    }

                    else if (condtionAns[index] == '1' && question.condition_answer_label == "Yes") {
                        questionAr.push(question);
                    }
                    else if (condtionAns[index] == '0' && question.condition_answer_label == "No") {
                        questionAr.push(question);
                    }

                }
                if (condtionObj.includes(question.condition_reference_question_2)) {
                    const answer_label_2 = question.condition_answer_label_2;
                    const index_2 = condtionObj.indexOf(question.condition_reference_question_2);
                    if (answer_label_2 !== null && question.condition_input_type_2 != 2) {
                        const answAr_2 = answer_label_2.split(',');
                        console.log(checkcondition, question.condition_or);
                        if (answAr_2.includes(condtionAns[index_2]) && (!condtionObj.includes(question.condition_reference_question) || question.condition_reference_question == null)) {
                            questionAr.push(question);
                        }
                        else if ((answAr_2.includes(condtionAns[index_2]) || checkcondition) && question.condition_or) {
                            questionAr.push(question);
                        }
                        else if (answAr_2.includes(condtionAns[index_2]) && checkcondition) {
                            questionAr.push(question);
                        }

                    }
                    else if (((condtionAns[index_2] == '1' && question.condition_answer_label_2 == "Yes") || checkcondition) && question.condition_or) {
                        questionAr.push(question);
                    }
                    else if (((condtionAns[index_2] == '0' && question.condition_answer_label_2 == "No") || checkcondition) && question.condition_or) {
                        questionAr.push(question);
                    }
                    else if (condtionAns[index_2] == '1' && question.condition_answer_label_2 == "Yes" && checkcondition) {
                        questionAr.push(question);
                    }
                    else if (condtionAns[index_2] == '0' && question.condition_answer_label_2 == "No" && checkcondition) {
                        questionAr.push(question);
                    }

                }
            })

            //change indexval
            const array_val = questionAr;
            let count = 1;
            questionAr.forEach((question: any, key: any) => {
                question.indexval = count;
                array_val.forEach((qu: any, key1: any) => {
                    if (qu.id == question.same_page_with && question.same_page_with != null) {
                        question.indexval = qu.indexval;
                        count--;
                    }
                })
                count++;
            })


            //console.log(questionAr);
            if (questionAr && questionAr[questionAr.length - 1] && questionAr[questionAr.length - 1].indexval) {
                settotaltab(questionAr[questionAr.length - 1].indexval);
            }
            setQuestionPreviewData(questionAr);
        }
    }

    const handleChangeMulti = (e: any, togglenext: boolean) => {
        setIntialData({ ...intialData, [e.target.name]: e.target.value });

    }


    const handleKeyDown = (e: any) => {
        if (e.keyCode === 13 && activeSubTab != totaltab) {
            setactiveSubTab(activeSubTab + 1);
        }
        // setIntialData({ ...intialData, [e.target.name]: e.target.value });
    }

    const handleChangeMulticheckbox = (e: any, answer: any) => {
        setMulticheckname(e.target.name);
        let answerar = typeof answer === 'string' ? answer.split(',') : [];
        if (e.currentTarget.checked) {
            setMulticheck([...multicheck, ...answerar, e.target.value]);
        } else {
            var index = multicheck.indexOf(e.target.value);
            if (index !== -1) {
                multicheck.splice(index, 1);
            }
            setMulticheck(multicheck);
        }
    }


    const handleChange = (e: any) => {
        setIntialData({ ...intialData, [e.target.name]: e.target.value });
    }

    const handleChangeCountry = (name: any, value: any) => {
        setIntialData({ ...intialData, [name]: value });
    }

    const handlecalender = (e: any, name: string) => {
        if (e[0]) {
            setIntialData({ ...intialData, [name]: moment(e[0]).format("YYYY-MM-DD hh:mm:ss") });
        }
    }

    function handleMultiInputChange(e: any, ref: any) {
        console.log(e, intialData, ref);
    }
    function renderRowHTML(rowContent: any) {
        return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
    }

    return (
        <React.Fragment>
            <Modal
                size="lg"
                isOpen={props.show}
                toggle={props.onCloseClick}
                backdrop="static"
                keyboard={false}
                autoFocus={false}
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title">{props.modalTitle}</h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={props.onCloseClick}
                        aria-label="Close"
                    ></button>
                </div>
                <Row>
                    <Col lg="12" className="questionnaire">
                        <ModalBody>
                            <Col lg="12" className='questionnaire-form'>
                                {questionPreviewData && questionPreviewData.length > 0 &&
                                    <div>
                                        <div id="top-wizard">
                                            <Progress color="primary" value={activeSubTab * 100 / totaltab}></Progress>
                                            <span id="location">{activeSubTab} {t("of")} {totaltab} {t("completed")}</span>

                                        </div>
                                        <div id="wizard_container">
                                            <Row>
                                                {questionPreviewData && questionPreviewData.map((questionrow: any, key: any) => (
                                                    <React.Fragment key={key}>
                                                        {questionrow.indexval === activeSubTab &&
                                                            <>
                                                                {questionrow.input_type === 9 || questionrow.input_type === 21 ?
                                                                    <>
                                                                        {questionrow.input_type === 9 ?
                                                                            <MultiInput
                                                                                label={questionrow.question_html}
                                                                                multi_input_data={questionrow.multi_input_data}
                                                                                reference_name={questionrow.reference_name}
                                                                                handleMultiInputChange={(e: any, ref: any) => handleMultiInputChange(e, ref)}
                                                                                user_data_answer={questionrow.user_data_answer}
                                                                                input_type={questionrow.input_type}
                                                                            />
                                                                            :
                                                                            <TableMultiInput
                                                                                label={questionrow.question_html}
                                                                                multi_input_data={questionrow.multi_input_data}
                                                                                reference_name={questionrow.reference_name}
                                                                                handleMultiInputChange={(e: any, ref: any) => handleMultiInputChange(e, ref)}
                                                                                user_data_answer={questionrow.user_data_answer}
                                                                                input_type={questionrow.input_type}
                                                                                user_id={questionrow.user_id}
                                                                                user_code={questionrow.user_id_code}
                                                                                section_id={undefined}
                                                                                showSign={false}
                                                                            />
                                                                        }
                                                                    </>
                                                                    :
                                                                    <Col lg={questionrow.field_width ? questionrow.field_width : 12}>
                                                                        <QuestionView questionrow={questionrow} input_type={questionrow.input_type} field_width={questionrow.field_width} question={questionrow.question_html} reference_name={questionrow.reference_name} placeholder={questionrow.placeholder} user_data_answer={questionrow.user_data_answer} countryOptions={countryOptions}
                                                                            answer_option_list={questionrow.answer_option_list} handleChange={handleChange} handleKeyDown={handleKeyDown} handleChangeMulti={handleChangeMulti}
                                                                            handleChangeMulticheckbox={handleChangeMulticheckbox} handleChangeCountry={handleChangeCountry} handlecalender={handlecalender} handleChangecheck={undefined} section_id={questionrow.question_id}
                                                                            handleMultiInputChange={(e: any, ref: any) => handleMultiInputChange(e, ref)}
                                                                            multi_input_data={questionrow.multi_input_data}
                                                                            adminview={true} questionnaireID={undefined} questionnaireSubID={undefined} answerOnly={undefined} activeTabnames={undefined} required={undefined} />
                                                                    </Col>
                                                                }

                                                            </>
                                                        }
                                                    </React.Fragment>
                                                ))}
                                            </Row>
                                            <br />
                                            <div className="wizard clearfix">
                                                <div className="actions clearfix">
                                                    <ul>
                                                        <li
                                                            className={
                                                                (activeSubTab == 1) ? "previous disabled" : "previous"
                                                            }
                                                        >
                                                            <Link
                                                                to="#"
                                                                onClick={() => {
                                                                    setactiveSubTab(activeSubTab - 1);
                                                                }}
                                                            >
                                                                {t("Previous")}
                                                            </Link>
                                                        </li>

                                                        <li>
                                                            <Button
                                                                className={
                                                                    (activeSubTab == totaltab) ? "next disabled" : "next"
                                                                }
                                                                onClick={() => {
                                                                    setactiveSubTab(activeSubTab + 1);

                                                                }}
                                                                color="primary"
                                                                type='submit'>
                                                               {t("Next")}
                                                            </Button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </Col>
                        </ModalBody>
                    </Col>
                </Row>
            </Modal>
        </React.Fragment>
    )
}

export default Preview