import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class InvoicesService {

    async getInvoice(id: number) {
        const response = await axios
            .get(
                SERVER_URL + url.GET_INVOICE + '/' + id,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async getInvoiceNumber(type = "INVOICE") {
        const response = await axios
            .get(
                SERVER_URL + url.GET_INVOICE_NO + '/' + type,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async getReferenceeNumber(invoice_number: number) {
        const response = await axios
            .get(
                SERVER_URL + url.GET_REFERENCE_NO + '/' + invoice_number,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async sendInvoice(id: number) {
        const response = await axios
            .put(
                SERVER_URL + url.SEND_INVOICE + '/' + id,
                {},
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async uncheckInvoice(id: number) {
        const response = await axios
            .put(
                SERVER_URL + url.UNCHECK_INVOICE + '/' + id,
                {},
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async updateEditing(id: number, invoice_id: number, is_editing: number) {
        const response = await axios
            .put(
                SERVER_URL + url.EDIT_INVOICE + '/' + id + '/' + invoice_id + '/' + is_editing,
                {},
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async addToACT(id: number) {
        const response = await axios
            .put(
                SERVER_URL + url.ADD_TO_ACT + '/' + id,
                {},
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async createInvoice(data: any) {
        const response = await axios
            .post(
                SERVER_URL + url.CREATE_INVOICE,
                data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async createCreditMemo(data: any) {
        const response = await axios
            .post(
                SERVER_URL + url.CREATE_CREDIT_NOTE,
                data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async updateInvoice(data: any) {
        const response = await axios
            .put(
                SERVER_URL + url.UPDATE_INVOICE,
                data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async getAllInvoices(data: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_INVOICES_LIST,
                data,
                {
                    headers: getAxiosConfigWithToken(),
                }
            ).then(function (response: any) {
                return response;
            })
            .catch(function (error: any) {
                return error;
            });
        return response;
    }

    async getInvoicesList(data: any) {
        const response = await axios
            .get(SERVER_URL + url.GET_INVOICES,
                {
                    headers: getAxiosConfigWithToken(),
                    params: data
                }
            ).then(function (response: any) {
                return response;
            })
            .catch(function (error: any) {
                return error;
            });
        return response;
    }

    async getNotPaidInvoicesList(data: any) {
        const response = await axios
            .get(SERVER_URL + url.GET_NOT_PAID_INVOICES,
                {
                    headers: getAxiosConfigWithToken(),
                    params: data
                }
            ).then(function (response: any) {
                return response;
            })
            .catch(function (error: any) {
                return error;
            });
        return response;
    }

    async receivePayment(data: any) {
        const response = await axios
            .post(
                SERVER_URL + url.RECEIVE_PAYMENT,
                data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async refundInvoice(data: any) {
        const response = await axios
            .post(
                SERVER_URL + url.REFUND,
                data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async reminders(data: any) {
        const response = await axios
            .put(
                SERVER_URL + url.REMINDERS,
                data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async cancelInvoice(data: any) {
        const response = await axios
            .post(
                SERVER_URL + url.CANCEL_INVOICE,
                data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }


    async getPayments(id: number) {
        const response = await axios
            .get(
                SERVER_URL + url.GET_PAYMENTS + '/' + id,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async getReminder(id: number) {
        const response = await axios
            .get(
                SERVER_URL + url.GET_REMINDERS + '/' + id,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async getAnalytics() {
        const response = await axios
            .get(
                SERVER_URL + url.GET_ANALYTICS,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async getInvoicePerTask(range: any) {
        const response = await axios
            .get(
                SERVER_URL + url.GET_INVOICE_PER_TYPE + '/2023' + '?' + range,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async getInvoiceGeneratedFromTimesheet(year: number) {
        const response = await axios
            .get(
                SERVER_URL + url.GET_INVOICE_GENERATED_FROM_TIMESHEET + '/' + year,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async getTodaysTrends() {
        const response = await axios
            .get(
                SERVER_URL + url.GET_INVOICE_TRENDS,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async getScheduleOfPaymentClientsHistory(range: any) {
        const response = await axios
            .get(
                SERVER_URL + url.GET_SCHEDULE_OF_PAYMENT_CLIENTS_HISTORY + '/' + range,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async getCashFlow(range: any) {
        const response = await axios
            .get(
                SERVER_URL + url.GET_CASH_FLOW + '/' + range,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async getInvoiceType(range: any) {
        const response = await axios
            .get(
                SERVER_URL + url.GET_INVOICE_TYPE + '/' + range,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async getTotalVsService(range: any) {
        const response = await axios
            .get(
                SERVER_URL + url.GET_TOTAL_VS_SERVICE_CHARGE + '/' + range,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async getSalesReport(year: any) {
        const response = await axios
            .get(
                SERVER_URL + url.GET_SALES_REPORT + '/' + year,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async getReminders(year: any) {
        const response = await axios
            .get(
                SERVER_URL + url.GET_REMINDERS_DATA + '/' + year,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async getAccount(year: any) {
        const response = await axios
            .get(
                SERVER_URL + url.GET_ACCOUNT_DATA + '/' + year,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async getTopClients(year: any) {
        const response = await axios
            .get(
                SERVER_URL + url.GET_TOP_CLIENTS + '/' + year,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }


    async downloadAct(data?: any) {
        const response = await axios
            .get(
                SERVER_URL + url.DOWNLOAD_ACT,
                {
                    headers: getAxiosConfigWithToken(),
                    params: data
                }
            );

        return response.data;
    }

    async downloadInvoice(invoice_id: any) {
        const response = await axios
            .get(
                SERVER_URL + url.DOWNLOAD_INVOICE + '/' + invoice_id,
                {
                    headers: {
                        ...getAxiosConfigWithToken(),
                        "Content-Type": "application/pdf",
                        "Access-Control-Allow-Origin": "*",
                    },
                    responseType: 'blob',
                }
            );

        return response;
    }

    async uploadCsv(data: any, headers: any) {
        const response = await axios
            .post(
                SERVER_URL + url.UPLOAD_CSV,
                data,
                {
                    headers: { ...getAxiosConfigWithToken(), ...headers }
                }
            );

        return response.data;
    }

    async uploadAct(data: any, headers: any) {
        const response = await axios
            .post(
                SERVER_URL + url.UPLOAD_ACT,
                data,
                {
                    headers: { ...getAxiosConfigWithToken(), ...headers }
                }
            );

        return response.data;
    }

    async carryForward(data: any) {
        const response = await axios
            .post(
                SERVER_URL + url.CARRY_FORWARD_INVOICE,
                data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );
        return response.data;
    }

}

export default new InvoicesService();