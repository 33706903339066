import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Button,
} from "reactstrap";
import { vLookup } from "../../helpers/functions_helper";
import { formatCurrency } from "../../utils/formats";
import { useTranslation } from "react-i18next";

const SSSCModel_199 = (props: any) => {
  const { t } = useTranslation();
  let spCountryData = props.spCountryData;
  const TAX_RATE = props.TAX_RATE;
  const TAX_RATE_S = props.TAX_RATE_S;
  const EMPLOYMENT_REDUCTION = props.EMPLOYMENT_REDUCTION;
  const [COUNTRY_IDX, setCOUNTRY_IDX] = useState<number>();
  const [TAX_RATE_S_CALCULATED, setTAX_RATE_S_CALCULATED] = useState([]);
  const [EMPLOYMENT_REDUCTION_CALCULATED, setEMPLOYMENT_REDUCTION_CALCULATED] = useState([]);

  const [D_GROSS_COUNTRY, setD_GROSS_COUNTRY] = useState<number>(0);
  const [D_SS_COUNTRY, setD_SS_COUNTRY] = useState<number>(0);
  const [D_TAX_OPTION_COUNTRY, setD_TAX_OPTION_COUNTRY] = useState<number>(1);
  const [C_TAX_EXPAT, setC_TAX_EXPAT] = useState<number>(0);
  const [C_NET_TAXABLE_COUNTRY, setC_NET_TAXABLE_COUNTRY] = useState<number>(0);
  const [C_EMPLOYMENT_RED, setC_EMPLOYMENT_RED] = useState<number>(0);
  const [C_NET_TAXABLE_COUNTRY2, setC_NET_TAXABLE_COUNTRY2] = useState<number>(0);
  const [C_PROG_TAX, setC_PROG_TAX] = useState<number>(0);
  const [C_TAX_COUNTRY, setC_TAX_COUNTRY] = useState<number>(0);

  useEffect(() => {
    if (spCountryData) {
      const COUNTRY_DATA = spCountryData.find((data: any) => data.country_id === 199);

      if (COUNTRY_DATA) {
        setCOUNTRY_IDX(spCountryData.findIndex((data: any) => data.country_id === 199));
        setD_GROSS_COUNTRY(
          spCountryData.find((data: any) => data.country_id === 199)?.D_GROSS_COUNTRY
        );
        setD_SS_COUNTRY(spCountryData.find((data: any) => data.country_id === 199)?.D_SS_COUNTRY);
        setD_TAX_OPTION_COUNTRY(
          spCountryData.find((data: any) => data.country_id === 199)?.country_tax_option
        );
      }
    }
  }, [spCountryData]);

  useEffect(() => {
    if (TAX_RATE_S && TAX_RATE_S.length > 0) {
      var newData: any = [...TAX_RATE_S];
      newData.map((item: any, index: number) => {
        if (index !== 0) {
          newData[index][0] = newData[index - 1][1];
        }
      });

      // newData[0][3] = 0;

      // =(B13-B12)*D12+E12
      newData[1][3] =
        (Number(newData[1][0]) - Number(newData[0][0])) * (Number(newData[0][2]) / 100) +
        Number(newData[0][3]);

      // =(B14-B13)*D13+E13
      newData[2][3] =
        (Number(newData[2][0]) - Number(newData[1][0])) * (Number(newData[1][2]) / 100) +
        Number(newData[1][3]);

      // =(B15-B14)*D14+E14
      newData[3][3] =
        (Number(newData[3][0]) - Number(newData[2][0])) * (Number(newData[2][2]) / 100) +
        Number(newData[2][3]);

      // =(B16-B15)*D15+E15
      newData[4][3] =
        (Number(newData[4][0]) - Number(newData[3][0])) * (Number(newData[3][2]) / 100) +
        Number(newData[3][3]);

      // =(B17-B17)*D16+E16
      newData[5][3] =
        (Number(newData[5][0]) - Number(newData[4][0])) * (Number(newData[4][2]) / 100) +
        Number(newData[4][3]);

      setTAX_RATE_S_CALCULATED(newData);
    }
  }, [TAX_RATE_S]);

  useEffect(() => {
    if (EMPLOYMENT_REDUCTION && EMPLOYMENT_REDUCTION.length > 0) {
      var newData: any = [...EMPLOYMENT_REDUCTION];
      newData.map((item: any, index: number) => {
        if (index !== 0) {
          newData[index][0] = newData[index - 1][1];
        }
      });

      // newData[0][3] = 0;

      // =(B21-B20)*D20+E20
      newData[1][3] =
        (Number(newData[1][0]) - Number(newData[0][0])) * Number(newData[0][2]) +
        Number(newData[0][3]);

      // newData[2][3] = 0;

      setEMPLOYMENT_REDUCTION_CALCULATED(newData);
    }
  }, [EMPLOYMENT_REDUCTION]);

  useEffect(() => {
    setC_TAX_EXPAT(Number(D_GROSS_COUNTRY) * (Number(TAX_RATE) / 100));
  }, [D_GROSS_COUNTRY]);

  useEffect(() => {
    setC_NET_TAXABLE_COUNTRY(Number(D_GROSS_COUNTRY) + Number(D_SS_COUNTRY) * -1);
  }, [D_GROSS_COUNTRY, D_SS_COUNTRY]);

  useEffect(() => {
    if (EMPLOYMENT_REDUCTION && EMPLOYMENT_REDUCTION.length > 0) {
      // =IF(C_NET_TAXABLE_SPAIN<0,0000001;0;IF(C_NET_TAXABLE_SPAIN>B22;E22;IF(C_NET_TAXABLE_SPAIN<B21;E20;E21-(D21*(C_NET_TAXABLE_SPAIN-B21)))))
      if (EMPLOYMENT_REDUCTION_CALCULATED.length) {
        let B21 = EMPLOYMENT_REDUCTION_CALCULATED[1][0];
        let B22 = EMPLOYMENT_REDUCTION_CALCULATED[2][0];
        let D21 = EMPLOYMENT_REDUCTION_CALCULATED[1][2];
        let E20 = EMPLOYMENT_REDUCTION_CALCULATED[0][3];
        let E21 = EMPLOYMENT_REDUCTION_CALCULATED[1][3];
        let E22 = EMPLOYMENT_REDUCTION_CALCULATED[2][3];

        if (Number(C_NET_TAXABLE_COUNTRY) < 0.0000001) {
          setC_EMPLOYMENT_RED(0);
        } else if (Number(C_NET_TAXABLE_COUNTRY) > Number(B22)) {
          setC_EMPLOYMENT_RED(Number(E22));
        } else if (Number(C_NET_TAXABLE_COUNTRY) < Number(B21)) {
          setC_EMPLOYMENT_RED(Number(E20));
        } else {
          setC_EMPLOYMENT_RED(
            Number(E21) - Number(D21) * (Number(C_NET_TAXABLE_COUNTRY) - Number(B21))
          );
        }
      } else {
        setC_EMPLOYMENT_RED(0);
      }
    }
  }, [C_NET_TAXABLE_COUNTRY, EMPLOYMENT_REDUCTION_CALCULATED]);

  useEffect(() => {
    setC_NET_TAXABLE_COUNTRY2(Number(C_NET_TAXABLE_COUNTRY) - Number(C_EMPLOYMENT_RED));
  }, [C_NET_TAXABLE_COUNTRY, C_EMPLOYMENT_RED]);

  useEffect(() => {
    if (TAX_RATE_S_CALCULATED && TAX_RATE_S_CALCULATED.length > 0) {
      // =ROUND((C_NET_TAXABLE_SPAIN2-VLOOKUP(C_NET_TAXABLE_SPAIN2;B12:E17;1))*VLOOKUP(C_NET_TAXABLE_SPAIN2;B12:E17;3)+VLOOKUP(C_NET_TAXABLE_SPAIN2;B12:E17;4);2)
      const VLOOKUP_4 = vLookup(C_NET_TAXABLE_COUNTRY2, TAX_RATE_S_CALCULATED, 4);
      const VLOOKUP_3 = vLookup(C_NET_TAXABLE_COUNTRY2, TAX_RATE_S_CALCULATED, 3);
      const VLOOKUP_1 = vLookup(C_NET_TAXABLE_COUNTRY2, TAX_RATE_S_CALCULATED, 1);

      setC_PROG_TAX(
        (Number(C_NET_TAXABLE_COUNTRY2) - Number(VLOOKUP_1)) * (Number(VLOOKUP_3) / 100) +
          Number(VLOOKUP_4)
      );
    }
  }, [C_NET_TAXABLE_COUNTRY2, TAX_RATE_S_CALCULATED]);

  useEffect(() => {
    // =IF(B7=1;C_TAX_EXPAT;C_PROG_TAX)
    if (Number(D_TAX_OPTION_COUNTRY) === 1) {
      setC_TAX_COUNTRY(Number(C_TAX_EXPAT));
    } else {
      setC_TAX_COUNTRY(Number(C_PROG_TAX));
    }
  }, [D_TAX_OPTION_COUNTRY, C_TAX_EXPAT, C_PROG_TAX]);

  useEffect(() => {
    if (COUNTRY_IDX) {
      props.onCalculationUpdate({
        COUNTRY_IDX: COUNTRY_IDX,
        C_TAXES_COUNTRY: C_TAX_COUNTRY,
      });
    }
  }, [COUNTRY_IDX, C_TAX_COUNTRY]);

  return (
    <React.Fragment>
      <Modal
        size="xl"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">{t("SPAIN")}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label={t("Close")}
          ></button>
        </div>

        <ModalBody>
          <Card>
            <CardHeader>
              <CardTitle>1. {t("PARAMETERS")}</CardTitle>
            </CardHeader>

            <CardBody>
              <div className="table-responsive mb-3">
                <Table className="table table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr>
                      <td>{t("Tax rate for expatriates")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(TAX_RATE).toFixed(2), false)}%
                      </td>
                      <td>
                        {t(
                          "(on first € 600.000 - 47% on the rest of the income which is not in formula)"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              <div className="table-responsive mb-3">
                <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr className="align-bottom">
                      <th colSpan={4} className="text-center">
                        {t("Tax rates")}
                      </th>
                    </tr>
                    {TAX_RATE_S_CALCULATED &&
                      TAX_RATE_S_CALCULATED.map((item: any, idx: number) => (
                        <tr key={idx} className="align-bottom">
                          <td className="text-end">
                            {formatCurrency(parseFloat(item[0]).toFixed(2), false)}
                          </td>
                          <td className="text-end">
                            {formatCurrency(parseFloat(item[1]).toFixed(2), false)}
                          </td>
                          <td className="text-end">
                            {formatCurrency(parseFloat(item[2]).toFixed(2), false)}%
                          </td>
                          <td className="text-end">
                            {formatCurrency(parseFloat(item[3]).toFixed(2), false)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>

              <div className="table-responsive mb-3">
                <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr className="align-bottom">
                      <th colSpan={4} className="text-center">
                        {t("Employment reduction")}
                      </th>
                    </tr>
                    {EMPLOYMENT_REDUCTION_CALCULATED &&
                      EMPLOYMENT_REDUCTION_CALCULATED.map((item: any, idx: number) => (
                        <tr key={idx} className="align-bottom">
                          <td className="text-end">
                            {formatCurrency(parseFloat(item[0]).toFixed(2), false)}
                          </td>
                          <td className="text-end">
                            {formatCurrency(parseFloat(item[1]).toFixed(2), false)}
                          </td>
                          <td className="text-end">
                            {formatCurrency(parseFloat(item[2]).toFixed(4), false)}
                          </td>
                          <td className="text-end">
                            {formatCurrency(parseFloat(item[3]).toFixed(2), false)}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>2. {t("COMPUTATION (EXPATRIATE)")}</CardTitle>
            </CardHeader>

            <CardBody>
              <div className="table-responsive mb-3">
                <Table className="table table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr>
                      <td>
                        {t("GROSS")} {t("SPAIN")}
                      </td>
                      <td className="text-end">
                        {formatCurrency(Number(D_GROSS_COUNTRY).toFixed(2), false)}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("TAX EXPAT")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_TAX_EXPAT).toFixed(2), false)}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={3} style={{ whiteSpace: "unset" }}>
                        {t(
                          "An individual who is assigned to work and live in Spain may opt to be taxed as a non-resident for the first six years of the assignment. Such an individual is taxed at a flat rate of 24% but no deductions or allowances are available. To qualify for non-resident taxation, the individual must have not been a tax resident in Spain for the previous ten years, must work in Spain for a Spanish tax-resident company or a PE of a non-Spanish tax-resident company, and the individual’s income must not be tax-exempt in Spain under the Spanish non-resident income tax law."
                        )}
                        "
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>3. {t("COMPUTATION (NORMAL TAX COMPUTATION)")}</CardTitle>
            </CardHeader>

            <CardBody>
              <div className="table-responsive mb-3">
                <Table className="table table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr>
                      <td>
                        {t("GROSS")} {t("SPAIN")}
                      </td>
                      <td className="text-end">
                        {formatCurrency(Number(D_GROSS_COUNTRY).toFixed(2), false)}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={2}></td>
                    </tr>

                    <tr>
                      <td>{t("SOCIAL SECURITY")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(D_SS_COUNTRY * -1).toFixed(2), false)}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={2}></td>
                    </tr>

                    <tr>
                      <td>{t("NET TAXABLE")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_NET_TAXABLE_COUNTRY).toFixed(2), false)}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={2}></td>
                    </tr>

                    <tr>
                      <td>{t("EMPLOYMENT")} RED</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_EMPLOYMENT_RED).toFixed(2), false)}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={2}></td>
                    </tr>

                    <tr>
                      <td>{t("NET TAXABLE")} (2)</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_NET_TAXABLE_COUNTRY2).toFixed(2), false)}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={2}></td>
                    </tr>

                    <tr>
                      <td>{t("PROG TAX")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_PROG_TAX).toFixed(2), false)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>4. {t("FINAL TAX (DEPENDING ON CHOSEN METHOD)")}</CardTitle>
            </CardHeader>

            <CardBody>
              <div className="table-responsive mb-3">
                <Table className="table table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr>
                      <td>{t("TAX")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_TAX_COUNTRY).toFixed(2), false)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </ModalBody>

        <ModalFooter>
          <Button color="danger" className="btn-block" type="reset" onClick={props.onCloseClick}>
            {t("Close")}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default SSSCModel_199;
