import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_QUESTIONNAIRE_STATUSES, GET_QUESTIONNAIRE_STATUS_DETAIL, ADD_NEW_QUESTIONNAIRE_STATUS, DELETE_QUESTIONNAIRE_STATUS, UPDATE_QUESTIONNAIRE_STATUS } from "./actionTypes"
import {
    getQuestionnaireStatusesSuccess,
    getQuestionnaireStatusesFail,
    getQuestionnaireStatusDetailFail,
    addQuestionnaireStatusFail,
    addQuestionnaireStatusSuccess,
    updateQuestionnaireStatusSuccess,
    updateQuestionnaireStatusFail,
    deleteQuestionnaireStatusSuccess,
    deleteQuestionnaireStatusFail,
} from "./actions"

import { AnyAction } from 'redux'

import MastersQuestionnaireStatusService from "../../../services/masters.questionnaire.status.service"

function* fetchQuestionnaireStatuses({ payload: { query, history } }: AnyAction): any {
    try {
        const response = yield call(MastersQuestionnaireStatusService.getQuestionnaireStatusList, query)
        yield put(getQuestionnaireStatusesSuccess(response))
    } catch (error) {
        yield put(getQuestionnaireStatusesFail(error))
    }
}

function* fetchQuestionnaireStatusDetail({ statusId }: AnyAction) {
    try {
        //  const response = yield call(getQuestionnaireStatusesDetails, companyId)
        // yield put(getQuestionnaireStatusDetailSuccess(response))
    } catch (error) {
        yield put(getQuestionnaireStatusDetailFail(error))
    }
}

function* onUpdateQuestionnaireStatus({ payload: {statusdata, history} }: AnyAction): any {
    try {
        const response = yield call(MastersQuestionnaireStatusService.updateQuestionnaireStatus, statusdata)
        if (response && response.data && response.data.status === 'success') {
            yield put(updateQuestionnaireStatusSuccess(response.data))
        } else if (response && response.status === 'error') {
            yield put(updateQuestionnaireStatusFail(response))
        }else {
            yield put(updateQuestionnaireStatusFail(response))
        }
    } catch (error) {
        yield put(updateQuestionnaireStatusFail(error))
    }
}

function* onDeleteQuestionnaireStatus({ payload: {statusdata, history} }: AnyAction): any {
    try {
        const response = yield call(MastersQuestionnaireStatusService.deleteQuestionnaireStatus, statusdata)
        if (response && response.status === 'success') {
            yield put(deleteQuestionnaireStatusSuccess(response))
        }else if (response && response.status === 'error') {
            yield put(deleteQuestionnaireStatusFail(response))
        } else {
            yield put(deleteQuestionnaireStatusFail(response))
        }

    } catch (error) {
        yield put(deleteQuestionnaireStatusFail(error))
    }
}

function* onAddNewQuestionnaireStatus({ payload: {statusdata, history} }: AnyAction): any {
    try {
        const response = yield call(MastersQuestionnaireStatusService.createQuestionnaireStatus, statusdata)
        if (response && response.data && response.data.status === 'success') {
            yield put(addQuestionnaireStatusSuccess(response.data))
        } else if (response && response.status === 'error') {
            yield put(addQuestionnaireStatusFail(response))
        }else {
            yield put(addQuestionnaireStatusFail(response))
        }
    } catch (error) {
        yield put(addQuestionnaireStatusFail(error))
    }
}

function* companysSaga(): any {
    yield takeEvery(GET_QUESTIONNAIRE_STATUSES, fetchQuestionnaireStatuses)
    yield takeEvery(GET_QUESTIONNAIRE_STATUS_DETAIL, fetchQuestionnaireStatusDetail)
    yield takeEvery(ADD_NEW_QUESTIONNAIRE_STATUS, onAddNewQuestionnaireStatus)
    yield takeEvery(UPDATE_QUESTIONNAIRE_STATUS, onUpdateQuestionnaireStatus)
    yield takeEvery(DELETE_QUESTIONNAIRE_STATUS, onDeleteQuestionnaireStatus)
}

export default companysSaga
