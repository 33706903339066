import React, { FC, useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardSubtitle, CardTitle, Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import { formatCurrency } from "../../../utils/formats";
import invoicesService from "../../../services/invoices.service";
import { toast } from "react-toastify";
import { select2Styles } from "../../../helpers/functions_helper";
import { yearOptions } from "../../../helpers/functions_helper";
import { noData } from "../../../utils/data";
import LoadingSpinner from "../../../components/Common/Loader";
import NoDataFound from "../../../components/NoDataFound";
import Chart from "../../../components/Chart";
import { currentYear } from "../../../common/staticdata";

type SalesReportProps = {
    hideHeading?: boolean;
}
const ReminderPaymentDue: FC<SalesReportProps> = ({ hideHeading = false }) => {

    const { t } = useTranslation();

    const [loadingText, setLoadingText] = useState<string>('Loading...');
    const [loading, setLoading] = useState<boolean>(true);
    const [formValues, setFormValues] = useState<any>({
        sale_report_year: currentYear
    })
    const [categories, setCategories] = useState<any>([]);
    const [lineChart, setLineChart] = useState<any>({
        series: [{
            name: 'Reminder 1',
            data: [10, 20, 10, 20, 3, 12, 1],
            color: "#62549f"
        }, {
            name: 'Reminder 2',
            data: [1, 3, 4, 5, 2, 3, 2, 3, 2],
            color: "#45cefd"
        }],
        options: {
            chart: {
                height: 200,
                type: 'area',
                toolbar: {
                    show: false
                }
            },
            legend: {
                show: false,
                floating: false,
                fontSize: '16px',
                position: 'left',
                offsetX: 160,
                offsetY: 15,
                labels: {
                    useSeriesColors: true,
                },
            },
            noData: noData,
            dataLabels: {
                enabled: false,
                enabledOnSeries: undefined,

            },
            yaxis: {
                type: 'string',
                labels: {

                },
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'string',
                categories: [
                    t("January"),
                    t("February"),
                    t("March"),
                    t("April"),
                    t("March"),
                    t("June"),
                    t("July"),
                    t("August"),
                    t("September"),
                    t("October"),
                    t("November"),
                    t("December"),
                ]
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy HH:mm'
                },
            },
        },


    }
    )
    const getSalesReport = async (year: any) => {
        try {
            const res = await invoicesService.getReminders(year);
            if (res.status === 'success') {
                setCategories(res?.data?.map((i: any) => i.month_name));
                res?.reminders?.length > 0 ?
                    setLineChart((state: any) => ({
                        ...state,
                        series: [{
                            name: t('Reminder') + ' 1',
                            data: res?.data?.map((i: any) => i.reminder_1 ? i.reminder_1 : 0)
                        }, {
                            name: t('Reminder') + ' 2',
                            data: res?.data?.map((i: any) => i.reminder_2 ? i.reminder_2 : 0)
                        }],
                        options: {
                            chart: {
                                height: 200,
                                type: 'area',
                                toolbar: {
                                    show: false
                                }
                            },
                            xaxis: {
                                type: 'string',
                                categories: res?.data?.map((i: any) => t(i.month_name))
                            },
                        }
                    })) : setLineChart((state: any) => ({
                        ...state,
                        series: [],
                        options: {
                            noData: noData
                        }
                    }))
            }
            setLoading(false);
        } catch (error: any) {
            toast(error)
        }
    }

    useEffect(() => {
        setLoading(true);
    }, [formValues.sale_report_year])

    useEffect(() => {
        loading && getSalesReport(formValues.sale_report_year);
    }, [loading])

    useEffect(() => {
        setLineChart((state: any) => ({
            ...state,
            series: [{
                name: t('Reminder') + ' 1',
                data: state.series[0]?.data
            }, {
                name: t('Reminder') + ' 2',
                data: state.series[1]?.data
            }],
            options: {
                ...state.options,
                xaxis: {
                    type: 'string',
                    categories: categories?.map((i: any) => t(i))
                },
            }
        }))
    }, [t])

    return (
        <>
            <div className="d-sm-flex flex-wrap">
                <CardTitle className="mb-3">{!hideHeading && t("Reminder sent for payment due")}</CardTitle>
                <div className="ms-auto">
                    <Select
                        id="sale_report_year"
                        name="sale_report_year"
                        className="form-select-sm myClassName"
                        styles={select2Styles}
                        value={yearOptions.find((i: any) => i.value === formValues.sale_report_year)}
                        options={yearOptions}
                        onChange={(option: any) => {
                            setFormValues((state: any) => ({ ...state, sale_report_year: option.value }))
                        }}
                    />
                </div>
            </div>
            <Chart loading={loading} options={lineChart.options} series={lineChart.series} type="area" height={315} />
        </>

    )
}

export default ReminderPaymentDue;