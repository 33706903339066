export const GET_TASK_TYPE = "GET_TASK_TYPE"
export const GET_TASK_TYPE_SUCCESS = "GET_TASK_TYPES_SUCCESS"
export const GET_TASK_TYPE_ERROR = "GET_TASK_TYPES_ERROR"

export const GET_TASK_TYPES_LIST = "GET_TASK_TYPES_LIST"
export const GET_TASK_TYPES_LIST_SUCCESS = "GET_TASK_TYPES_LIST_SUCCESS"
export const GET_TASK_TYPES_LIST_ERROR = "GET_TASK_TYPES_LIST_ERROR"
  
export const CREATE_TASK_TYPE = "CREATE_TASK_TYPE"
export const CREATE_TASK_TYPE_SUCCESS = "CREATE_TASK_TYPE_SUCCESS"
export const CREATE_TASK_TYPE_ERROR = "CREATE_TASK_TYPE_ERROR"

export const UPDATE_TASK_TYPE = "UPDATE_TASK_TYPE"
export const UPDATE_TASK_TYPE_SUCCESS = "UPDATE_TASK_TYPE_SUCCESS"
export const UPDATE_TASK_TYPE_ERROR = "UPDATE_TASK_TYPE_ERROR"

export const DELETE_TASK_TYPE = "DELETE_TASK_TYPE"
export const DELETE_TASK_TYPE_SUCCESS = "DELETE_TASK_TYPE_SUCCESS"
export const DELETE_TASK_TYPE_ERROR = "DELETE_TASK_TYPE_ERROR"