import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
  Alert,
  Badge,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Nav,
  NavItem,
  Progress,
  Label,
  Modal,
  ModalBody,
  FormGroup,
} from "reactstrap";

import { toast } from 'react-toastify';


// Actions
import { InvoicesAction } from "../../store/actions";

// Import Breadcrumb
import Breadcrumbs from "../../components/Layout/Breadcrumb";

// Import Loader
import Loader from "../../components/Layout/Loader";

// Import DeleteModal
import DeleteModal from "../../components/Common/DeleteModal";

// Import DataTable
import DataTable from "../../components/Common/DataTable";

import { getDataTableQuery, getUserProfile } from "../../helpers/functions_helper";
import { invoicesList } from "./data";
import { formatCurrency, dateFormat } from "../../utils/formats";
import moment from "moment";
import "./style.scss";
import invoicesService from "../../services/invoices.service";
import Filters from "./Filters";
import KebabMenu from "../../components/KebabMenu";
import PaymentForm from "./PaymentForm";
import Drawer from "../../components/Drawer";
import PaymentHistory from "./PaymentHistory";
import MultiComments from "../../components/Common/MultiComments";
import WidgetBasic from "../../components/Widgets/Basic";
import InvoiceAnalytics from "./InvoiceAnalytics";
import RefundForm from "./RefundForm";
import CancelInvoice from "./CancelInvoice";
import Reminders from "./Reminders";
import GeneralAccount from "./GeneralAccount";
import NewCompany from "../Masters/Company/NewCompany";
import EditCompany from "../Masters/Company/EditCompany";
import EditUser from "../Users/EditUser";
import fileDownload from "js-file-download";
import LoadingSpinner from "../../components/Common/Loader";
import Input from "../../components/Form/Input";
import { useTranslation } from "react-i18next";
import Dropzone from "react-dropzone";
import DisputeForm from "./Disputes/Form";
import DisputeList from "./Disputes/View";
import ReminderHistory from "./ReminderHistory";
import InvoiceList from "./InvoiceList";
import NewInvoice from "./NewInvoice";


const statusBadge = [
  { status: "Paid", color: "success" },
  { status: "Not Paid", color: "danger" },
  { status: "Partial Paid", color: "warning" },
  { status: "Draft", color: "info" },
  { status: "Cancelled", color: "secondary" },
  { status: "Sent", color: "success" },
  { status: "Refunded", color: "secondary" },
];

const AllInvoices = (props: any) => {
  document.title = "All Invoices | " + process.env.REACT_APP_NAME;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { type, invoice_id } = useParams();
  const navigate = useNavigate();
  const location = useLocation() as any;

  const [loader, setLoader] = useState(true);
  const [openCreateInvoice, setOpenCreateInvoice] = useState<boolean>(false);

  document.title = `${t("Invoices")} - ${type === "graph" ? t("Graphical view") : t("List view (" + type + ")")} | ` + process.env.REACT_APP_NAME;

  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "#",
      label: t("Invoices"),
      active: true,
    },
  ];


  // UserProfile ---------------------------------------------

  const toastId = React.useRef(null) as any;

  const userObj = getUserProfile('all-invoices');


  return (
    <React.Fragment>
      {/* {loader && (
        <Loader />
      )} */}

      <div className={props?.inTab ? "" : "page-content"}>
        <Container fluid>
          {props?.inTab ? null :
            <Breadcrumbs
              breadcrumbTitle={t("Invoices")}
              breadcrumbItems={breadcrumbItems}
              endAdornment={
                <div>
                  {/* {userObj.USER_COMPANY === 1 ? */}
                  <Nav className="nav justify-content-end">
                    <NavItem>
                      <Link
                        to="/invoices-management/invoices/all"
                        className={`btn ${type !== 'graph' ? 'btn-info' : 'btn-outline-secondary'} btn-label btn-sm me-1`}
                      >
                        <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">list_alt</span>{t("List view")}
                      </Link>
                      {userObj.IS_GRAPHICAL_VIEW ? (
                        <Link
                          className={`btn ${type === 'graph' ? 'btn-info' : 'btn-outline-secondary'} btn-label btn-sm me-1`}
                          to="/invoices-management/invoices/graph"
                        >
                          <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">insert_chart</span>{t("Graphical view")}
                        </Link>
                      ) : null}

                      {userObj.CAN_CREATE ? (
                        <Link
                          to="/invoices-management/new-invoice"
                          // to="#"
                          className="btn btn-primary btn-sm waves-light btn-label"
                        // onClick={() => setOpenCreateInvoice(true)}
                        >
                          <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">add</span>{t("Create Invoice")}
                        </Link>
                      ) : null}
                    </NavItem>
                  </Nav>
                  {/* : null } */}
                </div>
              }
            />
          }
          {type === 'graph' ? <InvoiceAnalytics /> :
            <Row>
              <Col xl="12">
                <Card>
                  <CardHeader className="bg-transparent border-bottom pb-0">
                    <div className="d-sm-flex flex-wrap">
                      <Nav tabs className="nav-tabs-custom">
                        <NavItem>
                          <Link
                            style={{ cursor: "pointer" }}
                            to="/invoices-management/invoices/all"
                            className={type === "all" ? "nav-link active" : "nav-link"}
                          >
                            <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                            <span className="d-none d-sm-block">{t("All Invoices")}</span>
                          </Link>
                        </NavItem>
                        <NavItem>
                          <Link
                            style={{ cursor: "pointer" }}
                            to="/invoices-management/invoices/overdue"
                            className={type === "overdue" ? "nav-link active" : "nav-link"}
                          >
                            <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                            <span className="d-none d-sm-block">{t("Over Due Invoices")}</span>
                          </Link>
                        </NavItem>
                        <NavItem>
                          <Link
                            style={{ cursor: "pointer" }}
                            to="/invoices-management/invoices/notpaid"
                            className={type === "notpaid" ? "nav-link active" : "nav-link"}
                          >
                            <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                            <span className="d-none d-sm-block">{t("Not Paid Invoices")}</span>
                          </Link>
                        </NavItem>
                        <NavItem>
                          <Link
                            style={{ cursor: "pointer" }}
                            to="/invoices-management/invoices/partialPaid"
                            className={type === "partialPaid" ? "nav-link active" : "nav-link"}
                          >
                            <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                            <span className="d-none d-sm-block">{t("Partially Paid Invoices")}</span>
                          </Link>
                        </NavItem>
                        <NavItem>
                          <Link
                            style={{ cursor: "pointer" }}
                            to="/invoices-management/invoices/overPaid"
                            className={type === "overPaid" ? "nav-link active" : "nav-link"}
                          >
                            <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                            <span className="d-none d-sm-block">{t("Over Paid Invoices")}</span>
                          </Link>
                        </NavItem>
                        <NavItem>
                          <Link
                            style={{ cursor: "pointer" }}
                            to="/invoices-management/invoices/paid"
                            className={type === "paid" ? "nav-link active" : "nav-link"}
                          >
                            <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                            <span className="d-none d-sm-block">{t("Fully Paid Invoices")}</span>
                          </Link>
                        </NavItem>

                        <NavItem>
                          <Link
                            style={{ cursor: "pointer" }}
                            to="/invoices-management/invoices/creditnote"
                            className={type === "creditnote" ? "nav-link active" : "nav-link"}
                          >
                            <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                            <span className="d-none d-sm-block">{t("Credit Notes")}</span>
                          </Link>
                        </NavItem>

                        {userObj.USER_COMPANY === 1 ?
                          <NavItem>
                            <Link
                              style={{ cursor: "pointer" }}
                              to="/invoices-management/invoices/followup"
                              className={type === "followup" ? "nav-link active" : "nav-link"}
                            >
                              <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                              <span className="d-none d-sm-block">{t("Follow up")}</span>
                            </Link>
                          </NavItem>
                          : null}
                        <NavItem>
                          <Link
                            style={{ cursor: "pointer" }}
                            to="/invoices-management/invoices/dispute"
                            className={type === "dispute" ? "nav-link active" : "nav-link"}
                          >
                            <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                            <span className="d-none d-sm-block">{t("Disputes")}</span>
                          </Link>
                        </NavItem>
                      </Nav>
                      <div className="ms-auto gap-2">
                        {userObj.USER_COMPANY !== 1 ?
                          <Link
                            to="/pay"
                            className="btn btn-danger btn-sm waves-light btn-label"
                          >
                            <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon fs-4">euro</span> {t("Pay My Dues")}
                          </Link> : null
                        }
                        {/* {CAN_CREATE ? (
                          <Link
                            to="/invoices-management/new-invoice"
                            className="btn btn-primary btn-sm waves-light btn-label"
                          >
                            <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">add</span> Create Invoice
                          </Link>
                        ) : (
                          ""
                        )} */}
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    {userObj.USER_COMPANY === 1 ?
                      <InvoiceList type={type} invoice_id={invoice_id ? parseInt(invoice_id) : 0} />
                      :
                      <InvoiceList type={type} company_id={userObj.USER_COMPANY} user_id={userObj.USER_ID} />
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
        </Container>
      </div>
      <Drawer
        title="Create Invoice"
        open={openCreateInvoice}
        onClose={() => setOpenCreateInvoice((state) => !state)}
        fullWidth
      >
        <NewInvoice />
      </Drawer>
    </React.Fragment>
  );
};

export default AllInvoices;
