import React, { useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  Label,
  Button,
  FormFeedback,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { getUserProfile } from "../../helpers/functions_helper";
import { toast } from "react-toastify";
import emailService from "../../services/email.service";
import { debounce, map } from 'lodash';
import AsyncCreatableSelect from 'react-select/async-creatable';
import CustomeDropDownOption from "../../components/Common/CustomeDropDownOption";
import broadcastService from "../../services/broadcast.service";
import DatePicker from "../../components/Form/DatePicker/DatePicker";
import Input from "../../components/Form/Input";
// @ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
// @ts-ignore
import CustomEditor from "ckeditor5-custom-build/build/ckeditor";

import { ckEditorConfigurationEmail } from "../../helpers/functions_helper";

export const Client_Master = [
  {
    label: 'Tax Consult',
    value: '1'
  },
  {
    label: 'Tax Consult HR Tax One',
    value: '2'
  },
];

export const Project_Master = [
  {
    label: 'ETC3.0',
    value: '1'
  },
  {
    label: 'HR ONE TAX',
    value: '2'
  },
];

const BroadcastFormModal = (props: any) => {
  const { t } = useTranslation();
  const userProfile = getUserProfile('all-broadcast');
  const [loading, setLoading] = useState<boolean>(false);
  const toastId = React.useRef(null) as any;
  const [filters, setFilter] = useState('');
  const [query, setQuery] = useState("");
  const { editdata } = props;


  const Enviroment_Master = [
    {
      label: t('Test'),
      value: '1'
    },
    {
      label: t('Acceptance'),
      value: '2'
    },
    {
      label: t('Production'),
      value: '3'
    },
  ];

  const Severity = [
    {
      label: t('Highest'),
      value: '1',
    },
    {
      label: t('Medium'),
      value: '2',
    },
    {
      label: t('Low'),
      value: '3',
    },
  ];

  const [initialValues, setInitialValues] = useState({
    id: '',
    broadcast_id: '',
    client: {
      label: 'Tax Consult',
      value: '1'
    },
    project: {
      label: 'ETC3.0',
      value: '1'
    },
    title: '',
    description: '',
    enviornment: {
      label: t('Test'),
      value: '1'
    },
    start_date: '',
    end_date: '',
    application_version: '',
    severity: {
      label: t('Highest'),
      value: '1',
    },
    emails: [],
  });

  const validationSchema = Yup.object().shape({
    client: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    }),
    project: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    }),
    title: Yup.string()
      .required("The title field is required")
      .min(10, "The title must be at least 10 characters")
      .max(100, "The title may not be greater than 100 characters"),
    enviornment: Yup.object().shape({
      label: Yup.string().required(),
      value: Yup.string().required(),
    }),
    start_date: Yup.string().required('Start date field required'),
    end_date: Yup.string().required('End date field required'),
    application_version: Yup.string().required("Application version field is required")

  });

  const handleChange = (fieldName: string, fieldValue: any) => {
    setInitialValues((preState) => ({ ...preState, [fieldName]: fieldValue }));
  };



  const handleSubmit = async (values: {
    id: string;
    broadcast_id: string;
    client: {
      label: string;
      value: string;
    };
    project: {
      label: string;
      value: string;
    };
    title: string;
    description: string;
    enviornment: {
      label: string;
      value: string;
    };
    start_date: string;
    end_date: string;
    application_version: string;
    severity: {
      label: string;
      value: string;
    };
    emails: string[];
  }) => {

    try {
      setLoading(true);
      toast.dismiss();
      const mess = initialValues.id ? t('Updating Broadcast') : t("Creating Broadcast");
      toastId.current = toast(mess, {
        autoClose: false,
        isLoading: true,
      });

      const res = await broadcastService.create(values);
      if (res.status === "success") {
        toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: res.message, autoClose: 5000, isLoading: false, delay: 500 });
        setLoading(false);
        setInitialValues({
          id: '',
          broadcast_id: '',
          client: {
            label: 'Tax Consult',
            value: '1'
          },
          project: {
            label: 'ETC3.0',
            value: '1'
          },
          title: '',
          description: '',
          enviornment: {
            label: t('Test'),
            value: '1'
          },
          start_date: '',
          end_date: '',
          application_version: '',
          severity: {
            label: t('Highest'),
            value: '1',
          },
          emails: [],
        });
        props.onCloseClick(true);
      }
      else { toast.update(toastId.current, { type: toast.TYPE.ERROR, render: 'Error', autoClose: 5000, isLoading: false, delay: 500 }); setLoading(false); }

    } catch (error: any) {
      setLoading(false);
      toast(error);
    }
  };


  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    if (!validation.isSubmitting) return;
    if (Object.keys(validation.errors).length > 0) {
      document
        .getElementById(Object.keys(validation.errors)[0])
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
      document
        .getElementById(Object.keys(validation.errors)[0])
        ?.focus({ preventScroll: true });
    }
  }, [validation.isSubmitting, validation.errors]);


  const loadBroadcast = async (data: any) => {
    const envData = Enviroment_Master.find((option: any) => option.value == data?.enviroment) as {
      label: string;
      value: string;
    };
    setInitialValues(preState => ({
      ...preState,
      id: data?.id,
      broadcast_id: data?.broadcast_id,
      client: {
        label: 'Tax Consult',
        value: '1'
      },
      project: {
        label: 'ETC3.0',
        value: '1'
      },
      title: data?.title,
      description: data?.description,
      enviornment: envData,
      start_date: data?.start_date,
      end_date: data?.end_date,
      application_version: data?.application_version,
      severity: { value: data?.fk_severity_id, label: data?.severity },
      emails: data?.email_id,
    }))
  }

  useEffect(() => {
    if (props.isEditMode && editdata) {
      loadBroadcast(editdata);
    } else {
      setInitialValues({
        id: '',
        broadcast_id: '',
        client: {
          label: 'Tax Consult',
          value: '1'
        },
        project: {
          label: 'ETC3.0',
          value: '1'
        },
        title: '',
        description: '',
        enviornment: {
          label: t('Test'),
          value: '1'
        },
        start_date: '',
        end_date: '',
        application_version: '',
        severity: {
          label: t('Highest'),
          value: '1',
        },
        emails: [],
      });
    }
  }, [props.isEditMode, editdata])


  const loadOptions = async (keyword: string) => {
    const filter = {
      search: keyword,
      extra: filters,
    };
    const user_email = await emailService.searchUser(filter) as any;
    return user_email?.data?.data || [];
  };

  const loadSuggestedOptions = useCallback(
    debounce((inputValue, callback) => {
      loadOptions(inputValue).then(options => callback(options))
    }, 500), []);


  return (
    <React.Fragment>
      <Modal
        size="lg"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">{t(`New Broadcast`)}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label="Close"
          ></button>
        </div>

        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          autoComplete="off"
        >
          <ModalBody>
            <Row>

              {/* <Col className="col-6 mb-2">
                <Label className="form-label">{t("Client")}{" "}<span className="text-warning is_required">*</span></Label>
                <Select
                  id="client_id"
                  name="client_id"
                  placeholder="Select client"
                  className="is-invalid"
                  options={Client_Master}
                  value={
                    initialValues.client
                      ? Client_Master.find(
                        (option: any) => option.value == initialValues.client
                      )
                      : ""
                  }
                  isSearchable={true}
                  onChange={(e: any) => {
                    handleChange("client", Client_Master.find((option: any) => option.value == e.value));
                  }}
                />
                 {validation.touched.client && validation.errors.client ? (
                    <FormFeedback type="invalid">
                      {validation.errors.client}
                    </FormFeedback>
                  ) : null}
              </Col> */}

              {/* <Col className="col-6 mb-2">
                <Label className="form-label">{t("Project")}{" "}<span className="text-warning is_required">*</span></Label>
                <Select
                  id="project_id"
                  name="project_id"
                  placeholder="Select project"
                  className="is-invalid"
                  options={Project_Master}
                  value={
                    initialValues.project
                      ? Project_Master.find(
                        (option: any) => option.value == initialValues.project
                      )
                      : ""
                  }
                  isSearchable={true}
                  onChange={(e: any) => {
                    handleChange("project", Project_Master.find((option: any) => option.value == e.value));
                  }}
                />
                 {validation.touched.project && validation.errors.project ? (
                    <FormFeedback type="invalid">
                      {validation.errors.project}
                    </FormFeedback>
                  ) : null}
              </Col> */}

              <Col className="col-12 mb-2">
                <Input
                  id="title"
                  name="title"
                  className="form-control"
                  placeholder={t("Title")}
                  label="Title"
                  required
                  type="text"
                  maxLength={100}
                  value={validation.values.title || ""}
                  autoFocus={true}
                  onKeyUp={validation.handleChange}
                  onChange={(e: any) => {
                    handleChange("title", e.target.value);
                  }}
                  invalid={
                    validation.touched.title &&
                      validation.errors.title
                      ? true
                      : false
                  }
                  error={validation.errors.title}
                  touched={validation.touched.title}
                />
              </Col>

              <Col className="col-12 mb-2">
                {/* <Input
                  id="description"
                  name="description"
                  className="form-control"
                  placeholder={t("Description")}
                  label="Description"
                  type="textarea"
                  value={validation.values.description || ""}
                  onKeyUp={validation.handleChange}
                  onChange={(e: any) => {
                    handleChange("description", e.target.value);
                  }}
                /> */}
                <CKEditor
                  editor={CustomEditor}
                  config={CustomEditor}
                  data={initialValues.description}
                  onChange={(e: any, editor: any) => {
                    handleChange("description", editor.getData());
                  }}
                  id="description"
                  name="description"
                  className="form-control"
                  onBlur={() => validation.setFieldTouched("description", true)}
                />
              </Col>

              <Col className="col-12 mb-2">
                <Label className="form-label">{t("Environment")}{" "}<span className="text-warning is_required">*</span></Label>
                <Select
                  id="enviornment_id"
                  name="enviornment_id"
                  placeholder="Select enviornment"
                  className="is-invalid"
                  options={Enviroment_Master}
                  value={
                    initialValues.enviornment
                      ? Enviroment_Master.find(
                        (option: any) => option.value == initialValues.enviornment.value
                      )
                      : ""
                  }
                  isSearchable={true}
                  onChange={(e: any) => {
                    handleChange("enviornment", Enviroment_Master.find((option: any) => option.value == e.value));
                  }}
                />
                {validation.touched.enviornment && validation.errors.enviornment ? (
                  <FormFeedback type="invalid">
                    {validation.errors.enviornment}
                  </FormFeedback>
                ) : null}
              </Col>


              <Col className="col-6 mb-2">

                <DatePicker
                  id="start_date"
                  name="start_date"
                  label="Start Date"
                  required
                  className="form-control d-block"
                  type="text"
                  placeholder="DD/MM/YYYY"
                  value={initialValues.start_date}
                  options={{
                    enableTime: true,
                    altInput: true,
                    altFormat: "d/m/Y H:i",
                    dateFormat: "Y-m-d H:i",
                    allowInput: true,
                    onChange: function (
                      selectedDates,
                      dateStr,
                      instance
                    ) {
                      handleChange("start_date", dateStr);
                    },
                    onClose: function (
                      selectedDates,
                      dateStr,
                      instance
                    ) {
                      handleChange("start_date", dateStr);
                    },
                  }}
                  error={validation.errors.start_date}
                  touched={validation.touched.start_date}
                />
              </Col>
              <Col className="col-6 mb-2">
                <DatePicker
                  id="end_date"
                  name="end_date"
                  label="End Date"
                  required
                  className="form-control d-block"
                  type="text"
                  placeholder="DD/MM/YYYY"
                  value={initialValues.end_date}
                  options={{
                    altInput: true,
                    enableTime: true,
                    altFormat: "d/m/Y H:i",
                    dateFormat: "Y-m-d H:i",
                    allowInput: true,
                    onChange: function (
                      selectedDates,
                      dateStr,
                      instance
                    ) {
                      handleChange("end_date", dateStr);
                    },
                    onClose: function (
                      selectedDates,
                      dateStr,
                      instance
                    ) {
                      handleChange("end_date", dateStr);
                    },
                  }}
                  error={validation.errors.end_date}
                  touched={validation.touched.end_date}
                />
              </Col>


              <Col className="col-6 mb-2">
                <Input
                  id="application_version"
                  name="application_version"
                  className="form-control"
                  placeholder={t("Application Version")}
                  label="Application Version"
                  type="text"
                  maxLength={100}
                  required
                  value={validation.values.application_version || ""}
                  autoFocus={true}
                  onKeyUp={validation.handleChange}
                  onChange={(e: any) => {
                    handleChange("application_version", e.target.value);
                  }}
                  invalid={
                    validation.touched.application_version &&
                      validation.errors.application_version
                      ? true
                      : false
                  }
                  error={validation.errors.application_version}
                  touched={validation.touched.application_version}
                />
              </Col>

              <Col className="col-6 mb-1">
                <Label className="form-label">{t("Severity")}{" "}</Label>
                <Select
                  id="severity_id"
                  name="severity_id"
                  placeholder="Select severity"
                  className="is-invalid"
                  options={Severity}
                  value={
                    initialValues.severity
                      ? Severity.find(
                        (option: any) => option.value == initialValues.severity.value
                      )
                      : ""
                  }
                  isSearchable={true}
                  onChange={(e: any) => {
                    handleChange("severity", Severity.find((option: any) => option.value == e.value));
                  }}
                />
              </Col>

              <Col className="col-12 mb-1">
                <Label className="form-label">{t("Email")}{" "}</Label>
                <AsyncCreatableSelect
                  cacheOptions
                  loadOptions={loadSuggestedOptions}
                  defaultOptions
                  defaultValue={initialValues.emails}
                  placeholder={t("Email")}
                  isMulti
                  isClearable={false}
                  formatCreateLabel={userInput => `${t('Add new email id')} ${userInput}`}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                    Option: CustomeDropDownOption,
                  }}
                  onChange={(e: any) => handleChange('emails', e)}
                  value={initialValues.emails}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              className="btn-block"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <span>
                  <i className="fa-solid fa-spinner fa-spin"></i>
                  <span>&nbsp; {t("Please wait")}...</span>
                </span>
              ) : (
                <span>{t("Submit")}</span>
              )}
            </Button>
            <Button
              color="outline-danger"
              className="btn-block"
              type="reset"
              onClick={props.onCloseClick}
              disabled={loading}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default BroadcastFormModal;
