import { takeEvery, call, put } from "redux-saga/effects";
import commonService from "../../services/common.service";
import { AnyAction } from "redux";

import {
    GET_DASHBOARD,
    UPDATE_DASHBOARD
} from "./actionTypes";

import {
    updateDashboard,
    updateDashboardError,
    setDashboard
} from "./actions";


function* getDashboardWidget({ payload }: AnyAction): any {
    try {
        const response = yield call(commonService.getDashboard, payload);

        if (response.data && response.data.status === "success") {
            yield put(setDashboard(response.data?.data?.widgets));
        }
    } catch (error: any) {
        // yield put(getTasksListError(error));
    }
}

function* updateDashboardWidget({ payload }: AnyAction): any {
    try {
        const response = yield call(commonService.updateDashbaord, payload);
        if (response.data && response.data.status === "success") {
            // yield put(setDashboard(response.data?.widgets));
            const response = yield call(commonService.getDashboard, payload.user_id);

            if (response.data && response.data.status === "success") {
                yield put(setDashboard(response.data?.data?.widgets));
            }
        }
    } catch (error: any) {
        yield put(updateDashboardError(error));
    }
}


function* dashboardSaga(): any {
    yield takeEvery(GET_DASHBOARD, getDashboardWidget);
    yield takeEvery(UPDATE_DASHBOARD, updateDashboardWidget);
}

export default dashboardSaga;
