import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class UserStatusService {
    async getUserStatus(DATA: any) {
        const response = await axios
            .get(SERVER_URL + url.GET_USER_STATUS + "/" + DATA.id + "/" + DATA.user_status_id,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async getUserStatusList(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_USER_STATUS_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async createUserStatus(DATA: any) {
        const response = await axios
            .put(SERVER_URL + url.CREATE_USER_STATUS, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async updateUserStatus(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.UPDATE_USER_STATUS, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async deleteUserStatus(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.DELETE_USER_STATUS, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }
}

export default new UserStatusService();