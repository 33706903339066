import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Dropzone from "../../components/Common/Dropzone";
// Import Breadcrumb
import Breadcrumbs from "../../components/Layout/Breadcrumb";

import ScrollUp from "../../components/ScrollUpDown/ScrollUp";
import ScrollDown from "../../components/ScrollUpDown/ScrollDown";

import { useTranslation } from "react-i18next";
import NavItems from "./NavItems";
import usersService from "../../services/users.service";

const EditUserAttachments = (props: any) => {
  const { t } = useTranslation();
  document.title = `${t("User details")} - ${t("Permanent documents")} | ${process.env.REACT_APP_NAME}`;
  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "/users/all-users",
      label: t("Users"),
      active: false,
    },
    {
      url: "#",
      label: t("Update user"),
      active: true,
    },
  ];
  const { id, user_id } = useParams();

  const [commentToIDs, setCommentToIDs] = useState<any>([]);

  const getUser = async () => {
    try {
      const res = (await usersService.getUser({
        id: id,
        user_id: user_id,
      })) as any;

      if (res?.data?.status === "success") {
        let userData = res.data.data[0];
        setCommentToIDs([
          {
            value: userData.id,
            label: userData?.first_name + " " + userData?.last_name,
            selected: true,
          },
          {
            value:
              userData.company === 0
                ? userData.file_manager
                : userData.company_file_manager,
            label: t("File manager"),
          },
          {
            value:
              userData.company === 0 ? null : userData.company_contact_person,
            label: t("Contact person (HR)"),
          },
        ]);
      } else {
      }
    } catch (err) {}
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <React.Fragment>
      <ScrollUp />
      <ScrollDown />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={t("Update user")}
            breadcrumbItems={breadcrumbItems}
          />

          <NavItems id={id} user_id={user_id} />

          <Row>
            <Col xl="12">
              <Card className="border-top-0 rounded-0 rounded-bottom">
                <CardBody>
                  <Row>
                    <Dropzone
                      moduleData={{
                        module_app_id: 1,
                        module_main_id: id,
                        module_sub_id: user_id,
                        section_id: 0,
                        file_path: "users",
                      }}
                      attachmentForIDs={commentToIDs}
                      fetchData={true}
                      isOpen={false}
                    />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditUserAttachments;
