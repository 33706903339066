import React, { FC, useState, useEffect } from 'react';
import { Button, Col, Container, Input, Label, OffcanvasBody, Row } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import moment from 'moment';
import { toast } from 'react-toastify';
import { invoiceStatuses } from '../../common/staticdata';
import { formatCurrency } from '../../utils/formats';
import invoicesService from '../../services/invoices.service';
import { useTranslation } from 'react-i18next';



type PaymentFormProps = {
    values: any;
    onApply: any;
    onClose: any;
    onChange?: any;
}

type PaymentFormType = {
    invoice_id: number;
    payment_date: string | any;
    amount: number | null;
    amount_due: number;
    isSubmitting: boolean
}

const PaymentForm: FC<PaymentFormProps> = ({ values, onApply, onClose, onChange }) => {
    const { t } = useTranslation();

    const toastId = React.useRef(null) as any;
    const [formValues, setFormValues] = useState<PaymentFormType>({
        invoice_id: values.id,
        payment_date: moment(new Date()).format('YYYY-MM-DD'),
        amount: null,
        amount_due: values.amount_due,
        isSubmitting: false
    });

    const handleSubmit = async (values: PaymentFormType) => {
        toastId.current = toast(t("Submitting payment..."), { autoClose: false, isLoading: true });
        setFormValues((state: PaymentFormType) => ({ ...state, isSubmitting: true }))
        const result = await invoicesService.receivePayment(values);
        if (result.status === 'success') {
            onClose();
            toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: t('Payment submitted successfully'), autoClose: 5000, isLoading: false, delay: 500 });
        }
        setFormValues((state: PaymentFormType) => ({ ...state, isSubmitting: false }))
    }

    return (
        <React.Fragment>
            <OffcanvasBody>
                <Row>
                    <Col>
                        <div className="mb-3">
                            <h3 className="invoice-number">
                                {t("Invoice Number")}: {values?.invoice_number}
                            </h3>

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="mb-3">
                            <Label className="form-label">
                                {t("Total")}:
                            </Label>

                        </div>
                    </Col>
                    <Col>
                        <div className="mb-3  rightAlign">
                            <Label className="form-label">
                                {formatCurrency(values?.amount_due)}
                            </Label>

                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="mb-3">
                            <Label className="form-label">
                                {t("Payment Date")}
                            </Label>
                            <Row>
                                <Col>
                                    <div className="input-group is-invalid">
                                        <Flatpickr
                                            id="payment_date"
                                            name="payment_date"
                                            className="form-control d-block"
                                            type="text"
                                            placeholder="DD/MM/YYYY"
                                            value={formValues?.payment_date}
                                            options={{
                                                altInput: true,
                                                altFormat: "d/m/Y",
                                                dateFormat: "Y-m-d",
                                                defaultDate: values?.payment_date,
                                                allowInput: true,
                                                onChange: function (
                                                    selectedDates,
                                                    dateStr,
                                                    instance
                                                ) {
                                                    setFormValues((state: PaymentFormType) => ({ ...state, payment_date: dateStr }))
                                                },
                                                locale: { firstDayOfWeek: 1 }
                                            }}
                                        />
                                        <span className="input-group-text">
                                            <span className="align-middle material-symbols-outlined fw-light fs-4">calendar_today</span>
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="mb-3">
                            <Label className="form-label">
                                {t("Amount")}
                            </Label>
                            <Input
                                id={`amount`}
                                name='amount'
                                placeholder={'100'}
                                className='rightAlign'
                                //value={formValues?.amount || null}
                                onChange={(e) => {
                                    setFormValues((state: PaymentFormType) => ({ ...state, amount: parseFloat(e.target.value), amount_due: values.amount_due - parseFloat(e.target.value) }))
                                }}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="mb-3">
                            <Label className="form-label">
                                {t("Due Amount")}:
                            </Label>

                        </div>
                    </Col>
                    <Col>
                        <div className="mb-3  rightAlign">
                            <Label className="form-label">
                                {formatCurrency(formValues?.amount_due)}
                            </Label>

                        </div>
                    </Col>
                </Row>
            </OffcanvasBody>

            <Button
                color="primary"
                style={{ margin: "16px" }}
                onClick={() => handleSubmit(formValues)}
                disabled={formValues.isSubmitting}
            >
                {t("Save")}
            </Button>
        </React.Fragment>

    )
}

export default PaymentForm;