import React from 'react'
import { Col, Label, Row } from 'reactstrap';

const CheckboxMultiInput = (props: any) => {
    let { label, name, placeholder, options, value, handleChangeMulti, handleChangeMulticheckbox, disabled, answer_only, handleKeyDown, handleChange, tabnotnext, fullwidth, isMulti, isDisabled, field_width_input, title, space_input, space_input_value } = props;
    return (
        <div className={answer_only ? "checkbox_answer mt-3" : "mt-3"}>
            <Label for="basicpill-lastname-input2 questionview" className={answer_only ? "answer_only" : ""}>
                {label}
                {!!title && (
                    <span className='tooltip-txt'>
                        < i className="fa-regular fas fa-info-circle"></i><span className="tooltiptext">{title}</span>
                    </span>
                )}
                {!!options &&
                    <>
                        {
                            options.map((item: any, key: any) => (
                                <>
                                    {answer_only && value.includes(item) &&
                                        <div className="form-group" key={key}>
                                            <label className="container_check version_2">{item}
                                                <input type={isMulti ? "checkbox" : "radio"} name={name} value={item} className="required" checked
                                                    disabled={isDisabled} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    }
                                </>
                            ))
                        }
                    </>
                }
            </Label>
            {!!options &&
                <Row className={answer_only ? "answer_only_row" : ""}>
                    {
                        options.map((item: any, key: any) => (
                            <>
                                {/* {answer_only && value.includes(item) &&
                                    <div className="form-group" key={key}>
                                        <label className="container_check version_2">{item}
                                            <input type={isMulti ? "checkbox" : "radio"} name={name} value={item} className="required" checked
                                                disabled={isDisabled} />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                } */}
                                {Math.abs(key % 2) === 0 && !answer_only &&
                                    <div className={field_width_input ? `w-${field_width_input}` : ''} key={key}>
                                        <div className={(space_input && space_input_value) ? `${space_input}-${space_input_value} form-group` : 'form-group'} key={key}>
                                            <label className="container_check version_2">{item}
                                                <input type={isMulti ? "checkbox" : "radio"} name={name} value={item} className="required"
                                                    defaultChecked={
                                                        Array.isArray(value) && value.includes(item)
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={tabnotnext ? handleChange : isMulti ? handleChangeMulticheckbox : handleChangeMulti}
                                                    disabled={isDisabled} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                }
                                {Math.abs(key % 2) === 1 && !answer_only &&
                                    <div className={field_width_input ? `w-${field_width_input}` : ''} key={key}>
                                        <div className={(space_input && space_input_value) ? `${space_input}-${space_input_value} form-group` : 'form-group'} key={key}>
                                            <label className="container_check version_2">{item}
                                                <input type={isMulti ? "checkbox" : "radio"} name={name} value={item} className="required"
                                                    defaultChecked={
                                                        Array.isArray(value) && value.includes(item)
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={tabnotnext ? handleChange : isMulti ? handleChangeMulticheckbox : handleChangeMulti}
                                                    disabled={isDisabled} />
                                                <span className="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                }
                            </>
                        ))
                    }
                </Row>
            }
        </div>
    )
}

export default CheckboxMultiInput