import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GraphFilters from './Filters';
import { getUserProfile, select2Styles } from '../../../helpers/functions_helper';
import usersService from '../../../services/users.service';
import { serialize } from '../../../utils/utils';
import LoadingSpinner from '../../../components/Common/Loader';
import { noData } from '../../../utils/data';
import Select from "react-select";
import { currentMonth, currentYear, graphviewOptions } from '../../../common/staticdata';
import ChartFilters from '../../../components/Chart/Filters';
import Chart from '../../../components/Chart';

interface ImpatriatesDataProps extends CartProps {
    filters?: any;
}

const ImpatriatesData: FC<ImpatriatesDataProps> = ({ hideHeading, filters = null }) => {

    const { t } = useTranslation();

    const userObj = getUserProfile();

    const inititalFilter = {
        week: null,
        quarter: null,
        month: null,
        year: currentYear,
        user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_ID : null,
        graphview: 'month',
    };


    const [graphOptions, setGraphOptions] = useState([]);

    useEffect(() => {
        if (graphviewOptions) {
            const graphOptions = graphviewOptions?.map((option: any) => ({
                label: t(option.label),
                value: option.value
            }));
            setGraphOptions(graphOptions);
        }
        clearWorkAllocationFilter();
    }, [localStorage.getItem("I18N_LANGUAGE")]);

    const [loading, setLoading] = useState<boolean>(true);
    const [query, setQuery] = useState<string | null>(serialize(inititalFilter));
    const [filter, setFilter] = useState<any>(inititalFilter);
    const [multiYear, setMultiYear] = useState(false);
    const handleBarClick = ({ clientX, clientY }: any, chartContext: any, dataPointIndex: any) => {
        //console.log(clientX, clientY, chartContext, dataPointIndex)
        // setAreDetailsVisible(areVisible => {
        //   if (!areVisible) {
        //     setDetailsStyle({
        //       ...detailsDefaultStyle,
        //       left: clientX,
        //       top: clientY,
        //     });
        //     setCurrentDataPointIndex(dataPointIndex);
        //   }
        //   return !areVisible;
        // });
    };



    const [data, setData] = useState<any>({
        series: [],
        options: {
            chart: {
                width: 380,
                type: 'column',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: true
                },
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            plotOptions: {
                bar: {
                    horizontal: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                categories: [],
                labels: {
                    show: true,
                    trim: true,
                    rotate: -45,
                }
            },
            yaxis: {
                labels: {
                    formatter: (value: any) => {
                        return Math.floor(value);
                    },
                }
            },
            legend: {
                position: 'bottom',
                offsetY: 5
            },
            fill: {
                opacity: 1
            },
            noData: noData,
        },
    })


    const getChartData = async () => {
        const result = await usersService.impatriatesData(query);
        if (result.data.status === 'success') {
            console.log('xaxis', result?.data?.data?.yaxis)
            setData((data: any) => ({
                ...data,
                series: result?.data?.data?.yaxis.map((y: any) => ({ ...y, name: t(y.name) })),
                options: {
                    //...data.options,
                    xaxis: {
                        // ...data.options.xaxis,
                        type: 'string',
                        categories: result?.data?.data?.xaxis?.map((category: any) => t(category)) || [0],
                    },
                }
            }))
        }
        setLoading(false);
    }

    const clearWorkAllocationFilter = () => {
        setFilter(inititalFilter);
        setMultiYear(false);
    }

    useEffect(() => {
        loading && getChartData();
    }, [loading])

    const change_comma_string = (data: any) => {
        let ar: any = [];
        if (Array.isArray(data)) {
            (data.length > 1) ? setMultiYear(true) : setMultiYear(false);
            data.map((item: any) => {
                ar.push(item.value);
            })
        }
        return ar.join(',');
    }

    useEffect(() => {
        if (filter.year) {
            filter.year = change_comma_string(filter.year);
        }
        setQuery(serialize(filter));
        setLoading(true);
    }, [filter])

    useEffect(() => {
        setLoading(true);
    }, [t])

    useEffect(() => {
        filters && setFilter(filters)
    }, [filters])

    return (
        <>
            <div className="d-sm-flex flex-wrap">
                <h4 className="card-title mb-4">{!hideHeading && t("INPAT")}</h4>
                <div className="d-sm-flex ms-auto">
                    <Select
                        id="graphview"
                        name="graphview"
                        className="form-select-sm myClassName mx-1"
                        styles={select2Styles}
                        value={graphOptions?.find((i: any) => i.value === filter.graphview)}
                        options={graphOptions}
                        onChange={(option: any) => {
                            setFilter((state: any) => ({ ...state, graphview: option.value }))
                        }}
                        isDisabled={multiYear}
                    />
                    <ChartFilters
                        filter={filter}
                        setFilter={setFilter}
                        clearFilter={clearWorkAllocationFilter}
                    // yearOnly={true}
                    // multiYear={multiYear}
                    />
                </div>
            </div>
            <div className="clearfix"></div>
            {loading ? <LoadingSpinner height={380} /> : <div style={{ height: '100%' }}><Chart options={data.options} type="bar" series={data.series} height={380} /></div>}
        </>
    )
}

export default ImpatriatesData;