import { takeEvery, call, put } from "redux-saga/effects";
import userFunctionService from "../../../services/masters.user.function.service";
import { AnyAction } from "redux";

// UserFunction Redux States
import {
  GET_USER_FUNCTION,
  GET_USER_FUNCTION_LIST,
  CREATE_USER_FUNCTION,
  UPDATE_USER_FUNCTION,
  DELETE_USER_FUNCTION,
} from "./actionTypes";

import { 
    getUserFunctionSuccess, 
    getUserFunctionError,
    
    getUserFunctionListSuccess,
    getUserFunctionListError,

    createUserFunctionSuccess,
    createUserFunctionError,

    updateUserFunctionSuccess,
    updateUserFunctionError,

    deleteUserFunctionSuccess,
    deleteUserFunctionError,
} from "./actions";

function* getUserFunction({ payload: { userfunction, history } }: AnyAction): any {
  try {
    const response = yield call(userFunctionService.getUserFunction, userfunction);
    if (response.data && response.data.status === "success") {
      yield put(getUserFunctionSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getUserFunctionError(response.data));
    } else {
      yield put(getUserFunctionError(response));
    }
  } catch (error: any) {
    yield put(getUserFunctionError(error));
  }
}

function* getUserFunctionList({ payload: { query, history } }: AnyAction): any {
  try {
    const response = yield call(userFunctionService.getUserFunctionList, query);
    if (response.data && response.data.status === "success") {
      yield put(getUserFunctionListSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getUserFunctionListError(response.data));
    } else {
      yield put(getUserFunctionListError(response));
    }
  } catch (error: any) {
    yield put(getUserFunctionListError(error));
  }
}

function* createUserFunction({ payload: { userfunction, history } }: AnyAction): any {
  try {
    const response = yield call(userFunctionService.createUserFunction, userfunction);
    if (response.data && response.data.status === "success") {
      yield put(createUserFunctionSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(createUserFunctionError(response.data));
    } else {
      yield put(createUserFunctionError(response));
    }
  } catch (error: any) {
    yield put(createUserFunctionError(error));
  }
}

function* updateUserFunction({ payload: { userfunction, history } }: AnyAction): any {
  try {
    const response = yield call(userFunctionService.updateUserFunction, userfunction);
    if (response.data && response.data.status === "success") {
      yield put(updateUserFunctionSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(updateUserFunctionError(response.data));
    } else {
      yield put(updateUserFunctionError(response));
    }
  } catch (error: any) {
    yield put(updateUserFunctionError(error));
  }
}

function* deleteUserFunction({ payload: { userfunction, history } }: AnyAction): any {
  try {
    const response = yield call(userFunctionService.deleteUserFunction, userfunction);
    if (response.data && response.data.status === "success") {
      yield put(deleteUserFunctionSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(deleteUserFunctionError(response.data));
    } else {
      yield put(deleteUserFunctionError(response));
    }
  } catch (error: any) {
    yield put(deleteUserFunctionError(error));
  }
}

function* userFunctionSaga(): any {
  yield takeEvery(GET_USER_FUNCTION, getUserFunction);
  yield takeEvery(GET_USER_FUNCTION_LIST, getUserFunctionList);
  yield takeEvery(CREATE_USER_FUNCTION, createUserFunction);
  yield takeEvery(UPDATE_USER_FUNCTION, updateUserFunction);
  yield takeEvery(DELETE_USER_FUNCTION, deleteUserFunction);
}

export default userFunctionSaga;
