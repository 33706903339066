import axios from "axios";
import { getAxiosConfig, getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class AuthService {
    async login(data: any) {
        const response = await axios
            .post(SERVER_URL + url.LOGIN,
                {
                    email: data.email,
                    password: data.password,
                    re_captcha_token: data.re_captcha_token,
                },
                {
                    headers: getAxiosConfig()
                }
            )
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async logout() {
        await axios
            .post(SERVER_URL + url.LOGOUT,
                {
                },
                {
                    headers: getAxiosConfigWithToken()
                }
            )
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });

        localStorage.clear();
        window.location.href = "/login";
    }

    async forgot(data: any) {
        const response = await axios
            .post(SERVER_URL + url.FORGOT_PASSWORD,
                {
                    email: data.email,
                    re_captcha_token: data.re_captcha_token,
                },
                {
                    headers: getAxiosConfig()
                }
            )
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async reset(data: any) {
        const response = await axios
            .post(SERVER_URL + url.RESET_PASSWORD,
                {
                    token: data.token,
                    email: data.email,
                    password: data.password,
                    password_confirmation: data.password_confirmation,
                    re_captcha_token: data.re_captcha_token,
                },
                {
                    headers: getAxiosConfig()
                }
            )
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async sso_login(data: any) {
        const response = await axios
            .post(SERVER_URL + url.SSO_LOGIN,
                {
                    email: data.email,
                    accessToken: data.accessToken
                },
                {
                    headers: getAxiosConfig()
                }
            )
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }
}

export default new AuthService();