import { useEffect, useState } from "react";
import { dateFormat } from "../../../utils/formats";
import { Button, CardBody, CardHeader, CardTitle, Col, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import React from "react";
import DataTableBasic from "../../../components/Common/DataTableBasic";
import { datePickerI18, getUserProfile, yearsBackword } from "../../../helpers/functions_helper";
import { generalStatusOptions, taxClaimStatusMaster } from "../../../common/staticdata";
import DatePicker from "../../../components/Form/DatePicker/DatePicker";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import moment from "moment";
import FileUpload from "../../../components/Common/FileUpload";
import UploadDocModal from "../UploadDocModal";
import commonService from "../../../services/common.service";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import { Buffer } from "buffer";

type Type = {
  residence_card: number;
  residence_card_type: string;
  residence_card_number: string;
  residence_card_id: string;
  residence_card_status: string;
  residence_card_exp_date: string;
  residence_card_due_date: string;
  residence_card_file: any;
};

const ResidenceCard = (props: any) => {
  const { t } = useTranslation();
  const userProfile = getUserProfile("all-users");
  const { onChange } = props;
  const toastId = React.useRef(null) as any;
  const [statusList, setStatusList] = useState<any>([]);

  const [initialData, setInitialData] = useState<Type>({
    residence_card: 0,
    residence_card_type: "",
    residence_card_number: "",
    residence_card_id: "",
    residence_card_status: "",
    residence_card_exp_date: "",
    residence_card_due_date: "",
    residence_card_file: null,
  });

  useEffect(() => {
    if(generalStatusOptions){
      const Options = generalStatusOptions?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setStatusList(Options);
     }
  },[]);

  useEffect(() => {
    if(generalStatusOptions){
      const Options = generalStatusOptions?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setStatusList(Options);
     }
  },[localStorage.getItem("I18N_LANGUAGE")]);

  const columns = [
    {
      dataField: "DT_RowIndex",
      text: `${t('Sr. No.')}`,
      formatter: (rowContent: any, row: any, idx: number): any => {
        return idx + 1;
      },
    },
    {
      dataField: "residence_card_type",
      text: `${t('Residence type')}`,
    },
    {
      dataField: "residence_card_number",
      text: `${t('Residence / National No.')}`,
    },
    {
      dataField: "residence_card_id",
      text: `${t('Card ID')}`,
    },
    {
      dataField: "residence_card_status",
      text: `${t('Status')}`,
      formatter: (rowContent: any, row: any, idx: number) => {
        return statusList?.find((option: any) => option.value == row.residence_card_status)
          ?.label;
      },
    },
    {
      dataField: "residence_card_file",
      text: `${t('File')}`,
      formatter: (rowContent: any, row: any, idx: number) => {
        const residence_card_file_data =
          residenceFile && residenceFile.find((data: any) => data.id == row.residence_card_file);
        return (
          <a className="text-primary d-block mt-1 mb-1" onClick={() => handleSingleDownload(2)}>
            {residence_card_file_data?.file_name + "." + residence_card_file_data?.file_extension}
          </a>
        );
      },
    },
    {
      dataField: "residence_card_exp_date",
      text: `${t('Expiry date')}`,
      formatter: (rowContent: any, row: any, idx: number): any => {
        return row.residence_card_exp_date && dateFormat(row.residence_card_exp_date);
      },
    },
    {
      dataField: "residence_card_due_date",
      text: `${t('Due date')}`,
      formatter: (rowContent: any, row: any, idx: number): any => {
        return row.residence_card_due_date && dateFormat(row.residence_card_due_date);
      },
    },
    {
      dataField: "action",
      text: `${t('Action')}`,
      formatter: (rowContent: any, row: any, idx: number): any => {
        return (
          <div className="d-flex gap-2">
            <Button
              color="primary"
              outline={true}
              className={`btn btn-pill btn-outline-primary btn-sm`}
              type="button"
              onClick={() => editRow(idx)}
            >
              <span className="align-middle material-symbols-outlined fw-light fs-4">edit</span>
            </Button>

            <Button
              color="danger"
              outline={true}
              className={`btn btn-pill btn-outline-danger btn-sm`}
              type="button"
              onClick={() => removeRow(idx)}
            >
              <span className="align-middle material-symbols-outlined fw-light fs-4">delete</span>
            </Button>
          </div>
        );
      },
    },
  ];

  const [isEdit, setIsEdit] = useState(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [addRowBtnDisabled, setAddRowBtnDisabled] = useState(true);
  const [data, setData] = useState<any>([]);
  const [dataList, setDataList] = useState<any>([]);
  const [fileData, setFileData] = useState<any>([]);
  const [uploadDocModal, setUploadDocModal] = useState<boolean>(false);
  const [uploadDocData, setUploadDocData] = useState<any>([]);
  const [residenceFile, setResidenceFile] = useState<any>([]);
  const [datatableOptions, setDatatableOptions] = useState({
    draw: 1,
    page: 1,
    recordsTotal: 0,
    recordsFiltered: 0,
    sizePerPage: 5,
    searchText: "",
    sortField: "id",
    sortOrder: "desc",
  });

  const onTableChange = (type: any, newState: any) => {
    let draw = datatableOptions.draw + 1;
    let page = 1;
    if (type === "search") {
      page = 1;
    } else {
      page = newState.page;
    }
    setDatatableOptions((prevData: any) => ({
      ...prevData,
      draw: draw,
      page: page,
      sizePerPage: newState.sizePerPage,
      searchText: newState.searchText,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
    }));

    const slicedArray = data.slice(newState.sizePerPage * (page - 1), newState.sizePerPage * page);
    setDataList(slicedArray);
  };

  const addRow = () => {
    setData((preState: any) => [
      ...preState,
      {
        residence_card: initialData?.residence_card,
        residence_card_type: initialData?.residence_card_type,
        residence_card_number: initialData?.residence_card_number,
        residence_card_id: initialData?.residence_card_id,
        residence_card_status: initialData?.residence_card_status,
        residence_card_exp_date: initialData?.residence_card_exp_date,
        residence_card_due_date: initialData?.residence_card_due_date,
        residence_card_file: initialData?.residence_card_file,
      },
    ]);

    clearForm();
    setAddRowBtnDisabled(true);
  };

  const updateRow = () => {
    if (editIndex !== null) {
      var rows: any = [...data];
      rows.map((item: any, index: number) => {
        if (index === editIndex) {
          rows[index] = {
            ...item,
            residence_card: initialData?.residence_card,
            residence_card_type: initialData?.residence_card_type,
            residence_card_number: initialData?.residence_card_number,
            residence_card_id: initialData?.residence_card_id,
            residence_card_status: initialData?.residence_card_status,
            residence_card_exp_date: initialData?.residence_card_exp_date,
            residence_card_due_date: initialData?.residence_card_due_date,
            residence_card_file: initialData?.residence_card_file,
          };
        }
      });
      setData(rows);

      clearForm();
      setIsEdit(false);
      setEditIndex(null);
    }
  };

  const removeRow = (idx: number) => {
    const rows = data.filter((item: any, index: number) => index !== idx);
    setData(rows);

    setIsEdit(false);
    setEditIndex(null);
  };

  const editRow = (idx: number) => {
    const row = data.find((item: any, index: number) => index === idx);
    setInitialData((preData: any) => ({
      ...preData,
      residence_card: row?.residence_card,
      residence_card_type: row?.residence_card_type,
      residence_card_number: row?.residence_card_number,
      residence_card_id: row?.residence_card_id,
      residence_card_status: row?.residence_card_status,
      residence_card_exp_date: row?.residence_card_exp_date,
      residence_card_due_date: row?.residence_card_due_date,
      residence_card_file: row?.residence_card_file,
    }));

    setIsEdit(true);
    setEditIndex(idx);
  };

  const clearForm = () => {
    setInitialData((preData: any) => ({
      ...preData,
      residence_card: 0,
      residence_card_type: "",
      residence_card_number: "",
      residence_card_id: "",
      residence_card_status: "",
      residence_card_exp_date: "",
      residence_card_due_date: "",
      residence_card_file: null,
    }));
  };

  const handleChange = (fieldName: string, fieldValue: any) => {
    setInitialData((preState) => ({ ...preState, [fieldName]: fieldValue }));
  };

  const handleUploadDocument = (type: string) => {
    const data = {
      type,
      id: props?.id,
      user_id: props?.user_id,
      title: type,
      year: initialData.residence_card_exp_date
        ? moment(initialData.residence_card_exp_date).format("YYYY")
        : moment().format("YYYY"),
      exp_date: initialData.residence_card_exp_date,
      due_date: initialData.residence_card_due_date,
    };
    setUploadDocData(data);
    setUploadDocModal(true);
  };

  const handleSingleDownload = async (type: any) => {
    if (fileData) {
      const row = fileData.filter((dt: any) => dt.section_id == type)[0];
      if (row) {
        const result = await commonService.downloadAttachment({
          id: Buffer.from(String(row.id)).toString("base64"),
          attachment_id: Buffer.from(String(row.attachment_id)).toString("base64"),
        });
        if (result) {
          fileDownload(result.data, row.file_name + "." + row.file_extension);
        }
      } else {
        toastId.current = toast(t("File is not available"), {
          type: toast.TYPE.ERROR,
          isLoading: false,
        });
      }
    }
  };

  const onUploadSuccess = (id: any) => {
    getAttachmentList();
  };

  const getAttachmentList = async () => {
    const moduleData = {
      module_app_id: 11,
      module_main_id: props?.id,
      module_sub_id: props?.user_id,
      section_id: "2",
      file_path: "users",
    };
    const response = await commonService.getAttachmentList(moduleData);
    if (response.data.status === "success") {
      setFileData(response.data.data.original.data);
    }
  };

  useEffect(() => {
    if (fileData.length > 0) {
      const row_residence = fileData.filter((dt: any) => dt.section_id == 2);
      setResidenceFile(row_residence);

      setInitialData((preData: any) => ({
        ...preData,
        residence_card_file: row_residence && row_residence[0]?.id,
      }));
    }
  }, [fileData]);

  useEffect(() => {
    if (
      initialData.residence_card !== 0 &&
      initialData.residence_card_type !== "" &&
      initialData.residence_card_number !== "" &&
      initialData.residence_card_status !== "" &&
      initialData.residence_card_exp_date !== "" &&
      initialData.residence_card_due_date !== ""
    ) {
      setAddRowBtnDisabled(false);
    } else {
      setAddRowBtnDisabled(true);
    }
  }, [
    initialData.residence_card,
    initialData.residence_card_type,
    initialData.residence_card_number,
    initialData.residence_card_status,
    initialData.residence_card_exp_date,
    initialData.residence_card_due_date,
  ]);

  useEffect(() => {
    setData(props?.data);
  }, [props?.data]);

  useEffect(() => {
    setDatatableOptions((prevData: any) => ({
      ...prevData,
      draw: 1,
      page: 1,
      sizePerPage: 5,
      recordsTotal: data.length,
      recordsFiltered: data.length,
    }));

    const slicedArray = data.slice(0, 5);
    setDataList(slicedArray);

    onChange(data);
  }, [data]);

  useEffect(() => {
    getAttachmentList();
  }, []);

  return (
    <React.Fragment>
      {uploadDocModal && (
        <UploadDocModal
          data={uploadDocData}
          isOpen={uploadDocModal}
          handleClose={() => setUploadDocModal(false)}
          onSuccess={onUploadSuccess}
          type="user"
        />
      )}

      <Row>
        <Col xl={2} lg={2} md={2} sm={6}>
          <div className="mb-3">
            <Label className="form-label">{t("Residence card")}</Label>
            <div className="form-check mb-2">
              <Input
                id="residence_card_yes"
                name="residence_card"
                value="1"
                className="form-check-input"
                type="radio"
                checked={initialData.residence_card == 1 ? true : false}
                onChange={() => handleChange("residence_card", "1")}
              />
              <label className="form-check-label" htmlFor="residence_card_yes">
                {t("Yes")}
              </label>
            </div>

            <div className="form-check mb-2">
              <Input
                id="residence_card_no"
                name="residence_card"
                value="0"
                className="form-check-input"
                type="radio"
                checked={initialData.residence_card == 0 ? true : false}
                onChange={() => handleChange("residence_card", "0")}
              />
              <label className="form-check-label" htmlFor="residence_card_no">
                {t("No")}
              </label>
            </div>
          </div>
        </Col>

        <Col xl={2} lg={2} md={2} sm={6}>
          <div className="mb-3">
            <Label className="form-label">
              {t("Residence type")} <span className="text-warning is_required">*</span>
            </Label>
            <Input
              id="residence_card_type"
              name="residence_card_type"
              placeholder={t("Type")}
              type="text"
              maxLength={5}
              value={initialData.residence_card_type}
              readOnly={initialData.residence_card == 0 ? true : false}
              onChange={(e) => handleChange("residence_card_type", e.target.value)}
            />
          </div>
        </Col>

        <Col xl={2} lg={2} md={2} sm={6}>
          <div className="mb-3">
            <Label className="form-label">
              {t("Residence / National No.")} <span className="text-warning is_required">*</span>
            </Label>
            <InputMask
              id="residence_card_number"
              name="residence_card_number"
              className="form-control"
              placeholder="Residence / National No."
              type="text"
              mask="99.99.99-999.99"
              value={initialData.residence_card_number}
              readOnly={initialData.residence_card == 0 ? true : false}
              onChange={(e) => handleChange("residence_card_number", e.target.value)}
            />
          </div>
        </Col>

        <Col xl={2} lg={2} md={2} sm={6}>
          <div className="mb-3">
            <Label className="form-label">
              {t("Card ID")}
            </Label>
            <Input
              id="residence_card_id"
              name="residence_card_id"
              className="form-control"
              placeholder="Card ID"
              type="text"
              maxLength={50}
              value={initialData.residence_card_id}
              readOnly={initialData.residence_card == 0 ? true : false}
              onChange={(e) => handleChange("residence_card_id", e.target.value)}
            />
          </div>
        </Col>

        <Col xl={2} lg={2} md={2} sm={6}>
          <div className="mb-3">
            <Label className="form-label">
              {t("Status")} <span className="text-warning is_required">*</span>
            </Label>
            <Select
              id="residence_card_status"
              name="residence_card_status"
              className="is-invalid"
              options={statusList.map((item: { label: any; }, index: number) => {
                return {
                  label: item.label,
                  value: (index + 1).toString(),
                };
              })}
              value={
                initialData.residence_card_status
                  ? statusList?.find(
                    (option: any) => option.value == initialData.residence_card_status
                  )
                  : ""
              }
              isSearchable={true}
              isDisabled={initialData.residence_card == 0 ? true : false}
              onChange={(e: any) => {
                handleChange("residence_card_status", e.value);
              }}
            />
          </div>
        </Col>

        <Col xl={2} lg={2} md={2} sm={12}>
          <div className="mb-3">
            <DatePicker
              id="residence_card_exp_date"
              name="residence_card_exp_date"
              label={t("Expiry date")}
              required={true}
              value={initialData.residence_card_exp_date}
              disabled={initialData.residence_card == 0 ? true : false}
              options={{
                altInput: true,
                altFormat: "d/m/Y",
                dateFormat: "Y-m-d H:i:S",
                allowInput: true,
                onChange: function (selectedDates, dateStr, instance) {
                  handleChange("residence_card_exp_date", dateStr);
                },
                onClose: function (selectedDates, dateStr, instance) {
                  handleChange("residence_card_exp_date", dateStr);
                },
                locale: {  ...datePickerI18(),firstDayOfWeek: 1 },
              }}
            />
          </div>
        </Col>

        <Col xl={2} lg={2} md={2} sm={12}></Col>

        <Col xl={2} lg={2} md={2} sm={12}>
          <div className="mb-3">
            <DatePicker
              id="residence_card_due_date"
              name="residence_card_due_date"
              label={t("Due date")}
              required={true}
              value={initialData.residence_card_due_date}
              disabled={initialData.residence_card == 0 ? true : false}
              options={{
                altInput: true,
                altFormat: "d/m/Y",
                dateFormat: "Y-m-d H:i:S",
                allowInput: true,
                onChange: function (selectedDates, dateStr, instance) {
                  handleChange("residence_card_due_date", dateStr);
                },
                onClose: function (selectedDates, dateStr, instance) {
                  handleChange("residence_card_due_date", dateStr);
                },
                locale: {  ...datePickerI18(),firstDayOfWeek: 1 },
              }}
            />
          </div>
        </Col>

        <Col xl={3} lg={3} md={3} sm={12}>
          <Row>
            <Col xl={10} lg={10} md={10} sm={10}>
            <Label className="form-label">{t("Upload card")}</Label>
            <FileUpload
              title={t("Upload residence card")}
              onClickHandler={() => handleUploadDocument("Residence")}
              onDownload={() => handleSingleDownload(2)}
              fileData={initialData.residence_card == 1 && residenceFile}
              disabled={initialData.residence_card == 0 ? true : false}
            />
          </Col>
          {userProfile.IS_INTERNAL && (
            <Col xl={1} lg={1} md={1} sm={6}>
              <div className="mb-3">
                <Label className="form-label">
                  <br />
                </Label>
                <div className="input-group">
                  {isEdit === true ? (
                    <Button
                      color="success"
                      type="button"
                      className={`btn btn-sm btn-success`}
                      disabled={addRowBtnDisabled}
                      onClick={() => updateRow()}
                    >
                      <span className="align-middle material-symbols-outlined fw-light fs-4">
                        done
                      </span>
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      className={`btn btn-sm ${addRowBtnDisabled ? "btn-primary" : "btn-info"}`}
                      disabled={addRowBtnDisabled}
                      onClick={() => addRow()}
                    >
                      <span className="align-middle material-symbols-outlined fw-light fs-4">
                        add
                      </span>
                    </Button>
                  )}
                </div>
              </div>
            </Col>
          )}
          </Row>
        </Col>


      </Row>

      {dataList && dataList.length > 0 && residenceFile && residenceFile.length > 0 && (
        <DataTableBasic
          columns={columns}
          products={dataList}
          datatableOptions={datatableOptions}
          onTableChange={onTableChange}
        />
      )}
    </React.Fragment>
  );
};

export default ResidenceCard;
