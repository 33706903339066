import {
    GET_QUESTIONNAIRE_USER_DATA_FAIL,
    GET_QUESTIONNAIRE_USER_DATA_SUCCESS,
    GET_QUESTIONNAIRE_USER_DATA_DETAIL_FAIL,
    GET_QUESTIONNAIRE_USER_DATA_DETAIL_SUCCESS,
    ADD_NEW_QUESTIONNAIRE_USER_DATA,
    ADD_QUESTIONNAIRE_USER_DATA_SUCCESS,
    ADD_QUESTIONNAIRE_USER_DATA_FAIL,
    UPDATE_QUESTIONNAIRE_USER_DATA,
    UPDATE_QUESTIONNAIRE_USER_DATA_SUCCESS,
    UPDATE_QUESTIONNAIRE_USER_DATA_FAIL,
    DELETE_QUESTIONNAIRE_USER_DATA_SUCCESS,
    DELETE_QUESTIONNAIRE_USER_DATA_FAIL,
    DELETE_QUESTIONNAIRE_USER_DATA,
    GET_QUESTIONNAIRE_USER_DATA,
    SUBMIT_QUESTIONNAIRE_USER_DATA,
    SUBMIT_QUESTIONNAIRE_USER_DATA_SUCCESS,
    SUBMIT_QUESTIONNAIRE_USER_DATA_FAIL,
    INCOMPLETE_QUESTIONNAIRE_USER_DATA,
    INCOMPLETE_QUESTIONNAIRE_USER_DATA_SUCCESS,
    INCOMPLETE_QUESTIONNAIRE_USER_DATA_FAIL,
    UPDATE_QUESTIONNAIRE_USER_STATUS,
    UPDATE_QUESTIONNAIRE_USER_STATUS_SUCCESS,
    UPDATE_QUESTIONNAIRE_USER_STATUS_FAIL
} from "./actiontypes"

const INIT_STATE = {
    GET_QUESTIONNAIRE_USER_DATA_LIST: [],
    error: "",
    success: "",
    isModel: false,
    loading: false,
    questionnaire_status: "",
    company_status_change: "",
    user_status_change: "",
    user_success: "",
    user_general_success: "",
    company_success: "",
    company_general_success: "",
}

const QuestionnaireUserData = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GET_QUESTIONNAIRE_USER_DATA:
            return {
                ...state,
                success: "",
                error: "",
                loading: true,
            }
        case GET_QUESTIONNAIRE_USER_DATA_SUCCESS:
            return {
                ...state,
                success: "",
                error: "",
                GET_QUESTIONNAIRE_USER_DATA_LIST: action.payload,
                loading: false,
            }

        case GET_QUESTIONNAIRE_USER_DATA_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }

        case ADD_NEW_QUESTIONNAIRE_USER_DATA:
            return {
                ...state,
                success: "",
                error: "",
                loading: true,
            }
        case ADD_QUESTIONNAIRE_USER_DATA_SUCCESS:
            return {
                ...state,
                success: action.payload.message,
                loading: false,
            }

        case ADD_QUESTIONNAIRE_USER_DATA_FAIL:
            return {
                ...state,
                error: action.payload.message,
                isModel: true,
                loading: false,
            }


        case SUBMIT_QUESTIONNAIRE_USER_DATA:
            return {
                ...state,
                success: "",
                error: "",
                loading: true,
            }
        case SUBMIT_QUESTIONNAIRE_USER_DATA_SUCCESS:
            return {
                ...state,
                success: action.payload.message,
                questionnaire_status: action.payload.questionnaire_status,
                company_status_change: action.payload.company_status_change,
                user_status_change: action.payload.user_status_change,
                user_success: action.payload.user_success,
                user_general_success: action.payload.user_general_success,
                company_success: action.payload.company_success,
                company_general_success: action.payload.company_general_success,
                loading: false,
            }

        case SUBMIT_QUESTIONNAIRE_USER_DATA_FAIL:
            return {
                ...state,
                error: action.payload.message,
                isModel: true,
                loading: false,
                user_success: action.payload.user_success,
                user_general_success: action.payload.user_general_success,
                company_success: action.payload.company_success,
                company_general_success: action.payload.company_general_success
            }
        case INCOMPLETE_QUESTIONNAIRE_USER_DATA:
            return {
                ...state,
                success: "",
                error: "",
                loading: true,
            }
        case INCOMPLETE_QUESTIONNAIRE_USER_DATA_SUCCESS:
            return {
                ...state,
                success: action.payload.message,
                loading: false,
            }

        case INCOMPLETE_QUESTIONNAIRE_USER_DATA_FAIL:
            return {
                ...state,
                error: action.payload.message,
                isModel: true,
                loading: false,
            }

        case UPDATE_QUESTIONNAIRE_USER_STATUS:
            return {
                ...state,
                success: "",
                error: "",
                loading: false,
            }
        case UPDATE_QUESTIONNAIRE_USER_STATUS_SUCCESS:
            return {
                ...state,
                success: action.payload.message,
                loading: false,
            }

        case UPDATE_QUESTIONNAIRE_USER_STATUS_FAIL:
            return {
                ...state,
                error: action.payload.message,
                isModel: true,
                loading: false,
            }

        case GET_QUESTIONNAIRE_USER_DATA_DETAIL_SUCCESS:
            return {
                ...state,
                statusDetail: action.payload,
                loading: false,
            }

        case UPDATE_QUESTIONNAIRE_USER_DATA:
            return {
                ...state,
                success: "",
                error: "",
                loading: true,
            }

        case UPDATE_QUESTIONNAIRE_USER_DATA_SUCCESS:
            return {
                ...state,
                success: action.payload.message,
                loading: false,
            }

        case UPDATE_QUESTIONNAIRE_USER_DATA_FAIL:
            return {
                ...state,
                error: action.payload.message,
                isModel: true,
                loading: false,
            }
        case DELETE_QUESTIONNAIRE_USER_DATA:
            return {
                ...state,
                success: '',
                error: '',
                loading: true,
            }

        case DELETE_QUESTIONNAIRE_USER_DATA_SUCCESS:
            return {
                ...state,
                success: action.payload.message,
                loading: false,
            }

        case DELETE_QUESTIONNAIRE_USER_DATA_FAIL:
            return {
                ...state,
                error: action.payload.message,
                loading: false,
            }


        case GET_QUESTIONNAIRE_USER_DATA_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }

        default:
            return state
    }
}

export default QuestionnaireUserData
