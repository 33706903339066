import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import LoadingSpinner from "../../components/Common/Loader";
import Breadcrumbs from "../../components/Layout/Breadcrumb";
import { getUserProfile } from "../../helpers/functions_helper";
import { dateFormat } from "../../utils/formats";
import ScrollUp from "../../components/ScrollUpDown/ScrollUp";
import ScrollDown from "../../components/ScrollUpDown/ScrollDown";
import issueReportingService from "../../services/issue.reporting.service";
import moment from "moment";
import { map } from 'lodash'
import SimpleBar from "simplebar-react";
import { renderRowHTML } from "../../utils/utils";
import { toast } from "react-toastify";
var fileDownload = require('js-file-download');

const IncidentView = () => {
    const { t } = useTranslation();
    const userObj = getUserProfile('incident');
    const { id, incident_id } = useParams();
    document.title = t("Incident ") + "| " + process.env.REACT_APP_NAME;
    const moduleHeading = t('Incident')
    const toastId = React.useRef(null) as any;
    const breadcrumbItems = useMemo(() => [
        {
            url: "/dashboard",
            label: t("Dashboard"),
            active: false,
        },
        {
            url: "/incident/list",
            label: t('Incident List'),
            active: false,
        },
        {
            url: "#",
            label: moduleHeading,
            active: true,
        },
    ], [t]);



    const [data, setData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(true);

    const getData = async (id: number) => {
        setLoading(true)
        const result = await issueReportingService.get(id, incident_id as any);

        if (result?.status === 'success') {
            console.log(result.data)
            setLoading(false)
            setData(result?.data)
        }
        setLoading(false)
    }

    const downloadFile = async (url: string,file_name:string) => {
        toast.dismiss();
        toastId.current = toast(t("Please wait..."), {
          autoClose: false,
          isLoading: true,
        });
        const result = await issueReportingService.getFile(url);
        if(result){
            toast.dismiss();
            fileDownload(result, file_name);
        }
    }

    useEffect(() => {
        id && getData(parseInt(id, 10));
    }, [id])


    return (
        <React.Fragment>
            <ScrollUp />
            <ScrollDown top={'100px'} />

            <div className={"page-content"}>
                <Container fluid>
                    <Breadcrumbs
                        breadcrumbItems={breadcrumbItems}
                    />
                    <Row>
                        <Col xl={8} sm={12}>
                            <Card>
                                <CardBody>
                                    {loading ? <LoadingSpinner /> :
                                        <>
                                            <div>
                                                <h4 className="text-dark">{data?.incident?.title}</h4>
                                                <p>{moment(data?.incident?.created_at).format('MMMM Do YYYY, h:mm:ss a')}</p>

                                            </div>
                                            <div className="row">
                                                {(data?.jira_attachment && !loading) ? map(data?.jira_attachment, (item: any, index) =>

                                                    <div className="col-2 p-2" key={index}>
                                                        <a href="#" onClick={() => downloadFile(item?.content,item?.filename)} >
                                                            <div className="d-flex">
                                                                <div className="avatar-xs align-self-center me-2">
                                                                    <div className="avatar-title rounded bg-transparent text-success font-size-20">
                                                                        <span className="material-symbols-outlined">
                                                                            {item?.mimeType === "image/png" ? 'image' : 'draft'}
                                                                        </span>
                                                                    </div>
                                                                </div><div className="overflow-hidden me-auto">
                                                                    <h5 className="font-size-13 text-truncate mb-1">{item?.filename}</h5>
                                                                    <p className="text-muted text-truncate mb-0">{`${item?.size / 1000}kb`}</p>
                                                                </div>
                                                            </div></a>
                                                    </div>) : null}
                                            </div>
                                            <hr className="my-4" />
                                            <div className="mt-4">
                                                <div className="text-muted font-size-14" id="ContentDiv">
                                                    <p>{data?.incident?.describe}</p>

                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-2">
                                                    <p className="p-0 m-0">{t("Jira Status")}:</p>
                                                    <p className="fw-bold">{" "}{data?.incident?.jira_status}</p>
                                                </div>
                                                <div className="col-2">
                                                    <p className="p-0 m-0">{t("Priority")}</p>
                                                    <p className="fw-bold">{" "}{data?.incident?.priority}</p>
                                                </div>
                                                <div className="col-2">
                                                    <p className="p-0 m-0">{t("Release Date")}</p>
                                                    <p className="fw-bold">{" "}{data?.incident?.release_date}</p>
                                                </div>
                                                <div className="col-2">
                                                    <p className="p-0 m-0">{t("Release Version")}</p>
                                                    <p className="fw-bold">{" "}{data?.incident?.release_version}</p>
                                                </div>
                                                <div className="col-2">
                                                    <p className="p-0 m-0">{t("User")}</p>
                                                    <p className="fw-bold">{" "}{data?.incident?.user_name}</p>
                                                </div>
                                                <div className="col-2">
                                                    <p className="p-0 m-0">{t("Incident Link")}</p>
                                                    <p className="fw-bold">{" "}<a className="link" href={data?.incident?.url}>Link</a></p>
                                                </div>
                                            </div>


                                        </>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={4} sm={12} className="border-left">
                        <SimpleBar style={{ height: '500px' }}>
                            <Card>
                                <CardBody>
                                    {loading ? <LoadingSpinner /> :
                                        <>
                                            <div className="mb-4 card-title">Comments</div>
                                            <ul className="list-unstyled">
                                                {(data?.jira_comment && !loading) ? map(data?.jira_comment, (item: any, index) =>
                                                    <li className="list-group-item p-2 border-top border-secondary" key={index}>
                                                        <div className="d-flex">
                                                            {/* <div className="flex-shrink-0 me-3">
                                                                <div className="avatar-xs me-3">
                                                                    <span className="avatar-title rounded-circle bg-light">
                                                                        <img src={item?.author.avatarUrls['48x48']} height={18} />
                                                                    </span>
                                                                </div>
                                                            </div> */}
                                                            <div className="flex-grow-1" style={{overflow:'hidden'}}>
                                                                <p className="text-muted text-justify">
                                                                    {renderRowHTML(item?.renderedBody)}
                                                                </p>
                                                                <div className="float-end">
                                                                    <p className="mb-0 d-flex text-primary">
                                                                        <span className="material-symbols-outlined fs-5">
                                                                            person
                                                                        </span> {item?.author.displayName}</p>
                                                                </div><p className="mb-0 text-primary">{moment(item?.created).format("dd, MMMM Do YYYY, h:mm")}</p>
                                                            </div>
                                                        </div>
                                                    </li>) : null}

                                            </ul></>
                                    }
                                </CardBody>
                            </Card>
                            </SimpleBar>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    )
}

export default IncidentView;