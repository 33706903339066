import React, { FC, useState, useEffect } from 'react';
import { ButtonGroup, Col, Container, FormGroup, Label, Nav, OffcanvasBody, Row } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import moment from 'moment';
import { toast } from 'react-toastify';
import { invoiceStatuses } from '../../../common/staticdata';
import { formatCurrency } from '../../../utils/formats';
import disputesService from '../../../services/disputes.services';
import Button from '../../../components/Button/Button';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/Form/Input';
import { renderRowHTML } from '../../../utils/utils';
import e from 'express';
import Select from '../../../components/Form/Select/Select';



type WrongRecipientFormProps = {
    values: any;
    formValues: any;
    setFormValues: any;
    errors?: any;
    onApply?: any;
    onClose: any;
    onChange?: any;
}


const WrongRecipientForm: FC<WrongRecipientFormProps> = ({ values, formValues, setFormValues, errors, onApply, onClose, onChange }) => {
    const toastId = React.useRef(null) as any;
    const { t } = useTranslation();

    const [invoice, setInvoice] = useState<any>(values);
    const [deletedItems, setDeletedItems] = useState<Array<ItemType>>([]);
    const [details, setDetails] = useState<Array<any>>(invoice.details || []);

    const handleDelete = (id: number, price: any, code: string, subtotal: any, qty: number) => {

        // setInvoice((s: any) => ({ ...s, details: s?.details?.filter((x: any) => x.id !== id) }))
        // const a = 
        setDeletedItems((items: Array<ItemType>) => [...items.filter((a: ItemType) => a.id !== id), { id: id, qty: qty, price: price, code: code }]);
    }

    const getOpts = (num: number) => {
        let a: Array<any> = [];
        for (let i = num; i >= 0; i--) {
            a = [...a, { label: i, value: i }]
        }
        return a;
    }

    useEffect(() => {
        if (deletedItems.length > 0) {
            let comment = t("Please remove ") + deletedItems.map((x: ItemType) => x.code).join(', ');
            const amt = deletedItems.map((x: ItemType) => x.price * x.qty).reduce((a, b) => a + b)
            setFormValues((state: any) => ({ ...state, amount: amt, comment: comment, deleted_items: deletedItems }));
        }
    }, [deletedItems])

    return (
        <React.Fragment>
            <Row>
                <Col>
                    {/* <div>{JSON.stringify(formValues)}</div> */}
                    <div className="mb-3">
                        <Row>
                            <Col className='mb-2'>
                                <Nav>
                                    <Col>
                                        <Input
                                            label='Company'
                                            id='company_type'
                                            name='company_type'
                                            type='radio'
                                            checked={formValues?.recipient?.company_type === 1}
                                            onChange={(e: any) => setFormValues((state: any) => ({ ...state, recipient: { ...state.recipient, company_type: 1 } }))}
                                        />
                                    </Col>
                                    <Col>
                                        <Input
                                            label='Individual'
                                            id='company_type'
                                            name="company_type"
                                            type='radio'
                                            checked={formValues?.recipient?.company_type === 2}
                                            onChange={(e: any) => setFormValues((state: any) => ({ ...state, recipient: { ...state.recipient, company_type: 2 } }))}
                                        />
                                    </Col>
                                </Nav>
                            </Col>
                        </Row>
                        {formValues?.recipient?.company_type === 1 ?
                            <>
                                <Row>
                                    <Col className='mb-3'>
                                        <Input
                                            label='Company'
                                            id='company'
                                            required
                                            placeholder={t('Enter company name')}
                                            error={errors?.company}
                                            touched={errors?.company}
                                            onChange={(e: any) => setFormValues((state: any) => ({ ...state, recipient: { ...state.recipient, company: e.target.value } }))}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='mb-3'>
                                        <Input
                                            label='Address'
                                            id='address'
                                            type='textarea'
                                            placeholder={t('Enter address')}
                                            error={errors?.address}
                                            touched={errors?.address}
                                            onChange={(e: any) => setFormValues((state: any) => ({ ...state, recipient: { ...state.recipient, address: e.target.value } }))}
                                        />
                                    </Col>
                                </Row>
                            </> : null
                        }
                        <Row>
                            <Col className='mb-3'>
                                <Input
                                    label='Contact person name'
                                    id='contact_person_name'
                                    placeholder={t('Enter contact person name')}
                                    error={errors?.contact_person_name}
                                    touched={errors?.contact_person_name}
                                    onChange={(e: any) => setFormValues((state: any) => ({ ...state, recipient: { ...state.recipient, contact_person_name: e.target.value } }))}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className='mb-3'>
                                <Input
                                    label='Contact person email'
                                    id='contact_person_email'
                                    placeholder={t('Enter contact person email')}
                                    error={errors?.contact_person_email}
                                    touched={errors?.contact_person_email}
                                    onChange={(e: any) => setFormValues((state: any) => ({ ...state, recipient: { ...state.recipient, contact_person_email: e.target.value } }))}
                                />
                            </Col>
                        </Row>
                        {formValues?.recipient?.company_type === 2 ?
                            <>
                                <Row>
                                    <Col className='mb-3'>
                                        <Input
                                            label='Address'
                                            id='address'
                                            type='textarea'
                                            placeholder={t('Enter address')}
                                            error={errors?.address}
                                            touched={errors?.address}
                                            onChange={(e: any) => setFormValues((state: any) => ({ ...state, recipient: { ...state.recipient, address: e.target.value } }))}
                                        />
                                    </Col>
                                </Row>
                            </> : null
                        }
                        <Row>
                            <Col className='mb-3'>
                                <Input
                                    label='VAT number'
                                    id='tva_number'
                                    placeholder={t('Enter VAT number')}
                                    error={errors?.tva_number}
                                    touched={errors?.tva_number}
                                    tooltip={t('Format VAT: BE0123456789')}
                                    onChange={(e: any) => setFormValues((state: any) => ({ ...state, recipient: { ...state.recipient, tva_number: e.target.value } }))}
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

        </React.Fragment >
    )
}

export default WrongRecipientForm;