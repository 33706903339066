import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactApexChart from "react-apexcharts";
import { noData } from '../../../utils/data';
import LoadingSpinner from '../../../components/Common/Loader';
import GraphFilters from '../../../components/Common/GraphFilters';
import ApplyFilter from '../../../components/Common/ApplyFilter';
import { getUserProfile } from '../../../helpers/functions_helper';
import taskService from '../../../services/tasks.service';
import { serialize } from '../../../utils/utils';
import { useNavigate } from 'react-router-dom';

import { toast } from "react-toastify";
import cpoService from '../../../services/cpo.service';

interface TaskOriginatedProps extends CartProps {
    filters?: any;
}

const CPOHits: FC<TaskOriginatedProps> = ({ hideHeading, filters = null }) => {

    const { t } = useTranslation();

    const userObj = getUserProfile('all-cpo');
    const navigate = useNavigate();
    const [selectedID, setSelectedID] = useState<any>({ id: null, event: null });
    const inititalFilter = {
        week: null,
        quarter: null,
        month: null,
        year: null,
        user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_ID : null
    };

    const viewTaskList = (i: any) => {
        if (data.ids) {
            const x = data.ids[i];
            setSelectedID({ id: null, event: null });
            if (x !== -1) {
                let _filter = {
                    type: 'fees',
                    id: x,
                    label: 'taskOriginated'
                }
                //gfilters.setFilter(_filter);
                navigate('../tasks/all-tasks');
            }
        }
    }

    useEffect(() => {
        if (selectedID?.event === 'click') {
            viewTaskList(selectedID.id);
        }
    }, [selectedID])

    const [loading, setLoading] = useState<boolean>(true);
    const [query, setQuery] = useState<string | null>(serialize(inititalFilter));
    const [filter, setFilter] = useState<any>(inititalFilter);
    const [data, setData] = useState<any>({
        series: [
            {
                name: "Series B",
                data: [20, 29, 37, 36, 44, 45, 50, 58, 44, 3, 50, 58]
            }
        ],
        ids: [],
        type: [],
        options: {
            chart: {
                height: 350,
                type: "line",
                stacked: false
            },
            stroke: {
                curve: 'straight'
            },
            dataLabels: {
                enabled: false
            },

            plotOptions: {
                bar: {
                    columnWidth: "20%"
                }
            },
            xaxis: {
                categories: [t('Jan'), t('Feb'), t('Mar'), t('Apr'), t('May'), t('Jun'), t('Jul'), t('Aug'), t('Sep'), t('Oct'), t('Nov'), t('Dec')]
            },
            yaxis: [
                {
                    axisTicks: {
                        show: false
                    },
                    axisBorder: {
                        show: false,
                        color: "#FF1654"
                    },
                    labels: {
                        style: {
                            colors: "#FF1654"
                        }
                    },
                    title: {
                        text: t("Count"),
                        style: {
                            color: "#FF1654"
                        }
                    }
                },
            ],
            legend: {
                horizontalAlign: "left",
                offsetX: 40
            }
        },
    })


    const getChartData = async () => {
        try {
            const result = await cpoService.getCPOHits(query);
            if (result.data.status === 'success') {
                setData((preState: any) => ({
                    ...preState,
                    series: [{
                        data: result?.data?.data?.monthly?.data || [0],
                        name: 'Total',
                    }],
                    options: {
                        ...preState.options,
                        xaxis: {
                            categories: [t('Jan'), t('Feb'), t('Mar'), t('Apr'), t('May'), t('Jun'), t('Jul'), t('Aug'), t('Sep'), t('Oct'), t('Nov'), t('Dec')]
                        },
                    }
                }))
            }
            setLoading(false);
        } catch (error: any) {
            toast(error)
        }

    }

    const clearWorkAllocationFilter = () => {
        setFilter(inititalFilter);
    }



    useEffect(() => {
        loading && getChartData();
    }, [loading])

    useEffect(() => {
        setQuery(serialize(filter));
        setLoading(true);
    }, [filter])

    useEffect(() => {
        filters && setFilter(filters)
    }, [filters])

    useEffect(() => {
        setLoading(true);
    }, [t])

    return (
        <>
            <div className="d-sm-flex flex-wrap">
                <h4 className="card-title mb-4">{!hideHeading && t("CPO Hits")}</h4>&nbsp;

                <div className="ms-auto">
                    <GraphFilters
                        filter={filter}
                        setFilter={setFilter}
                        clearWorkAllocationFilter={clearWorkAllocationFilter}
                        userObj={userObj}
                    />
                </div>
            </div>
            <div className="clearfix"></div>
            {loading ? <LoadingSpinner height={380} /> : <div style={{ height: '100%' }}><ReactApexChart options={data.options} series={data.series} type="line" height={350} /></div>}
        </>
    )
}

export default CPOHits;