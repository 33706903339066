import { draftmailProps } from "../../pages/Email/components/type"
import {
  GET_INBOX_MAILS,
  GET_INBOX_MAILS_FAIL,
  GET_INBOX_MAILS_SUCCESS,
  ADD_NEW_INBOX_MAIL,
  ADD_INBOX_MAIL_SUCCESS,
  ADD_INBOX_MAIL_FAIL,
  DELETE_INBOX_MAIL,
  DELETE_INBOX_MAIL_SUCCESS,
  DELETE_INBOX_MAIL_FAIL,
  GET_STARRED_MAILS,
  GET_STARRED_MAILS_FAIL,
  GET_STARRED_MAILS_SUCCESS,
  GET_IMPORTANT_MAILS,
  GET_IMPORTANT_MAILS_FAIL,
  GET_IMPORTANT_MAILS_SUCCESS,
  GET_TRASH_MAILS,
  GET_TRASH_MAILS_FAIL,
  GET_TRASH_MAILS_SUCCESS,
  GET_JUNK_MAILS,
  GET_JUNK_MAILS_FAIL,
  GET_JUNK_MAILS_SUCCESS,
  GET_DRAFT_MAILS,
  GET_DRAFT_MAILS_FAIL,
  GET_DRAFT_MAILS_SUCCESS,
  GET_SENT_MAILS,
  GET_SENT_MAILS_FAIL,
  GET_SENT_MAILS_SUCCESS,
  IS_EMAIL_DIALOG_BOX_OPEN,
  GET_DRAFT_EMAIL_DATA,
  GET_FOLDER_LIST,
  GET_FOLDER_LIST_FAIL,
  GET_FOLDER_LIST_SUCCESS,
  SET_READ_UNREAD,
  SET_READ_UNREAD_SUCCESS,
  SET_READ_UNREAD_FAIL,
  GET_ARCHIVE_MAIL,
  GET_ARCHIVE_MAIL_SUCCESS,
  GET_ARCHIVE_MAIL_FAIL,
  GET_FOLDER_MESSAGE,
  GET_FOLDER_MESSAGE_SUCCESS,
  GET_FOLDER_MESSAGE_FAIL,
  SET_DATA_TABLE_ID,
  SET_DRAFT_MAIL,
  CLEAR_ERROR_DATA
} from "./actionTypes"

export const getInboxMails = (data:any) => ({
  type: GET_INBOX_MAILS,
  payload:data,
})

export const getInboxMailsSuccess = (inboxmails:any) => ({
  type: GET_INBOX_MAILS_SUCCESS,
  payload: inboxmails,
})

export const getInboxMailsFail = (error:any) => ({
  type: GET_INBOX_MAILS_FAIL,
  payload: error,
})

// GET FOLDER LIST
export const getFolderList = (data:any) => ({
  type: GET_FOLDER_LIST,
  payload:data,
})

export const getFolderListSuccess = (FolderList:any) => ({
  type: GET_FOLDER_LIST_SUCCESS,
  payload: FolderList,
})

export const getFolderListFail = (error:any) => ({
  type: GET_FOLDER_LIST_FAIL,
  payload: error,
})


export const addNewInboxMail = (inboxmail:any) => ({
  type: ADD_NEW_INBOX_MAIL,
  payload: inboxmail,
})

export const addInboxMailSuccess = (inboxmail:any) => ({
  type: ADD_INBOX_MAIL_SUCCESS,
  payload: inboxmail,
})

export const addInboxMailFail = (error:any) => ({
  type: ADD_INBOX_MAIL_FAIL,
  payload: error,
})

export const deleteInboxMail = (inboxmail:any) => ({
  type: DELETE_INBOX_MAIL,
  payload: inboxmail,
})

export const deleteInboxMailSuccess = (inboxmail:any) => ({
  type: DELETE_INBOX_MAIL_SUCCESS,
  payload: inboxmail,
})

export const deleteInboxMailFail = (error:any) => ({
  type: DELETE_INBOX_MAIL_FAIL,
  payload: error,
})

export const getStarredMails = () => ({
  type: GET_STARRED_MAILS,
})

export const getStarredMailsSuccess = (starredmail:any) => ({
  type: GET_STARRED_MAILS_SUCCESS,
  payload: starredmail,
})

export const getStarredMailsFail = (error:any) => ({
  type: GET_STARRED_MAILS_FAIL,
  payload: error,
})

export const getImportantMails = () => ({
  type: GET_IMPORTANT_MAILS,
})

export const getImportantMailsSuccess = (importantmail:any) => ({
  type: GET_IMPORTANT_MAILS_SUCCESS,
  payload: importantmail,
})

export const getImportantMailsFail = (error:any) => ({
  type: GET_IMPORTANT_MAILS_FAIL,
  payload: error,
})

export const getDraftMails = (query:any) => ({
  type: GET_DRAFT_MAILS,
  payload: query,
})

export const getDraftMailsSuccess = (draftmail:any) => ({
  type: GET_DRAFT_MAILS_SUCCESS,
  payload: draftmail,
})

export const getDraftMailsFail = (error:any) => ({
  type: GET_DRAFT_MAILS_FAIL,
  payload: error,
})

export const getSentMails = (query:any) => ({
  type: GET_SENT_MAILS,
  payload: query,
})

export const getSentMailsSuccess = (sentmail :any)=> ({
  type: GET_SENT_MAILS_SUCCESS,
  payload: sentmail,
})

export const getSentMailsFail = (error:any) => ({
  type: GET_SENT_MAILS_FAIL,
  payload: error,
})

export const getTrashMails = (query:any) => ({
  type: GET_TRASH_MAILS,
  payload: query,
})

export const getTrashMailsSuccess = (trashmail:any) => ({
  type: GET_TRASH_MAILS_SUCCESS,
  payload: trashmail,
})

export const getTrashMailsFail = (error:any) => ({
  type: GET_TRASH_MAILS_FAIL,
  payload: error,
})


export const getJunkMails = (query:any) => ({
  type: GET_JUNK_MAILS,
  payload: query,
})

export const getJunkMailsSuccess = (Junkmail:any) => ({
  type: GET_JUNK_MAILS_SUCCESS,
  payload: Junkmail,
})

export const getJunkMailsFail = (error:any) => ({
  type: GET_JUNK_MAILS_FAIL,
  payload: error,
})



export const getArchiveMail = (query:any) => ({
  type: GET_ARCHIVE_MAIL,
  payload: query,
})

export const getArchiveMailSuccess = (Junkmail:any) => ({
  type: GET_ARCHIVE_MAIL_SUCCESS,
  payload: Junkmail,
})

export const getArchiveMailFail = (error:any) => ({
  type: GET_ARCHIVE_MAIL_FAIL,
  payload: error,
})



export const setReadUnread = (query:any) => ({
  type: SET_READ_UNREAD,
  payload: query,
})

export const setReadUnreadSuccess = (Junkmail:any) => ({
  type: SET_READ_UNREAD_SUCCESS,
  payload: Junkmail,
})

export const setReadUnreadFail = (error:any) => ({
  type: SET_READ_UNREAD_FAIL,
  payload: error,
})


export const getFolderMessage = (query:any) => ({
  type: GET_FOLDER_MESSAGE,
  payload: query,
})

export const getFolderMessageSuccess = (message:any) => ({
  type: GET_FOLDER_MESSAGE_SUCCESS,
  payload: message,
})

export const getFolderMessageFail = (error:any) => ({
  type: GET_FOLDER_MESSAGE_FAIL,
  payload: error,
})

export const clearFolderMessageFail = (error:any) => ({
  type: CLEAR_ERROR_DATA,
  payload: {},
})


//EMAIL DIALLOGBOX
type propsDiaglogBox = {
  operate:number
}
export const setDialogBox = (operate:propsDiaglogBox) =>({
  type:IS_EMAIL_DIALOG_BOX_OPEN,
  payload:operate
})

export const setDraftMailForTask = (draftEmail:any)=>({
  type:SET_DRAFT_MAIL,
  payload:draftEmail
})

export const setDraftEmailData = (draftmail:draftmailProps) =>({
  type:GET_DRAFT_EMAIL_DATA,
  payload:draftmail
})

export const setDataTableID=(id:number)=>({
  type:SET_DATA_TABLE_ID,
  payload:id,
})