/* TYPE */
export const GET_QUESTIONNAIRE_TYPES = "GET_QUESTIONNAIRE_TYPES"
export const GET_QUESTIONNAIRE_TYPES_SUCCESS = "GET_QUESTIONNAIRE_TYPE_SUCCESS"
export const GET_QUESTIONNAIRE_TYPES_FAIL = "GET_QUESTIONNAIRE_TYPES_FAIL"

/* TYPE list*/
export const GET_QUESTIONNAIRE_TYPE_LIST = "GET_QUESTIONNAIRE_TYPE_LIST"
export const GET_QUESTIONNAIRE_TYPE_LIST_SUCCESS = "GET_QUESTIONNAIRE_TYPE_LIST_SUCCESS"
export const GET_QUESTIONNAIRE_TYPE_LIST_FAIL = "GET_QUESTIONNAIRE_TYPE_LIST_FAIL"

/* TYPE DETAIL*/
export const GET_QUESTIONNAIRE_TYPE_DETAIL = "GET_QUESTIONNAIRE_TYPE_DETAIL"
export const GET_QUESTIONNAIRE_TYPE_DETAIL_SUCCESS = "GET_QUESTIONNAIRE_TYPE_DETAIL_SUCCESS"
export const GET_QUESTIONNAIRE_TYPE_DETAIL_FAIL = "GET_QUESTIONNAIRE_TYPE_DETAIL_FAIL"

/**
 * add TYPE
 */
export const ADD_NEW_QUESTIONNAIRE_TYPE = "ADD_NEW_QUESTIONNAIRE_TYPE"
export const ADD_QUESTIONNAIRE_TYPE_SUCCESS = "ADD_QUESTIONNAIRE_TYPE_SUCCESS"
export const ADD_QUESTIONNAIRE_TYPE_FAIL = "ADD_QUESTIONNAIRE_TYPE_FAIL"

/**
 * Edit TYPE
 */
export const UPDATE_QUESTIONNAIRE_TYPE = "UPDATE_QUESTIONNAIRE_TYPE"
export const UPDATE_QUESTIONNAIRE_TYPE_SUCCESS = "UPDATE_QUESTIONNAIRE_TYPE_SUCCESS"
export const UPDATE_QUESTIONNAIRE_TYPE_FAIL = "UPDATE_QUESTIONNAIRE_TYPE_FAIL"

/* Export */

export const EXPORT_QUESTIONNAIRE_TYPE = "EXPORT_QUESTIONNAIRE_TYPE"
export const EXPORT_QUESTIONNAIRE_TYPE_SUCCESS = "EXPORT_QUESTIONNAIRE_TYPE_SUCCESS"
export const EXPORT_QUESTIONNAIRE_TYPE_FAIL = "EXPORT_QUESTIONNAIRE_TYPE_FAIL"

/**
 * Delete TYPE
 */
export const DELETE_QUESTIONNAIRE_TYPE = "DELETE_QUESTIONNAIRE_TYPE"
export const DELETE_QUESTIONNAIRE_TYPE_SUCCESS = "DELETE_QUESTIONNAIRE_TYPE_SUCCESS"
export const DELETE_QUESTIONNAIRE_TYPE_FAIL = "DELETE_QUESTIONNAIRE_TYPE_FAIL"


/****************Questionnaire preview**************** */
export const GET_QUESTIONNAIRE_PREVIEW = "GET_QUESTIONNAIRE_PREVIEW "
export const GET_QUESTIONNAIRE_PREVIEW_SUCCESS = "GET_QUESTIONNAIRE_PREVIEW_SUCCESS"
export const GET_QUESTIONNAIRE_PREVIEW_FAIL = "GET_QUESTIONNAIRE_PREVIEW_FAIL"