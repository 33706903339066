import {
    GET_QUESTIONNAIRE_TABS,
    GET_QUESTIONNAIRE_TABS_FAIL,
    GET_QUESTIONNAIRE_TABS_SUCCESS,
    GET_QUESTIONNAIRE_TAB_LIST,
    GET_QUESTIONNAIRE_TAB_LIST_SUCCESS,
    GET_QUESTIONNAIRE_TAB_LIST_FAIL,
    GET_QUESTIONNAIRE_TAB_DETAIL,
    ADD_NEW_QUESTIONNAIRE_TAB,
    ADD_QUESTIONNAIRE_TAB_SUCCESS,
    ADD_QUESTIONNAIRE_TAB_FAIL,
    UPDATE_QUESTIONNAIRE_TAB,
    UPDATE_QUESTIONNAIRE_TAB_SUCCESS,
    UPDATE_QUESTIONNAIRE_TAB_FAIL,
    DELETE_QUESTIONNAIRE_TAB,
    DELETE_QUESTIONNAIRE_TAB_SUCCESS,
    DELETE_QUESTIONNAIRE_TAB_FAIL,
    GET_QUESTIONNAIRE_TAB_DETAIL_FAIL,
    GET_QUESTIONNAIRE_TAB_DETAIL_SUCCESS
} from "./actionTypes"
import StatusModel from "./interface"


export const getQuestionnaireTabs = (query: any, history: any) => ({
    type: GET_QUESTIONNAIRE_TABS,
    payload: {query, history},
})

export const getQuestionnaireTabsSuccess = (statuses : any) => ({
    type: GET_QUESTIONNAIRE_TABS_SUCCESS,
    payload: statuses,
})

export const getQuestionnaireTabList = () => ({
    type: GET_QUESTIONNAIRE_TAB_LIST
})

export const getQuestionnaireTabListSuccess = (tablist : any) => ({
    type: GET_QUESTIONNAIRE_TAB_LIST_SUCCESS,
    payload: tablist,
})

export const getQuestionnaireTabListFail = (error: any) => ({
    type: GET_QUESTIONNAIRE_TAB_LIST_FAIL
})

export const addNewQuestionnaireTab= (statusdata : StatusModel, history : any) => ({
    type: ADD_NEW_QUESTIONNAIRE_TAB,
    payload: { statusdata, history },
})

export const addQuestionnaireTabSuccess = (statusdata : StatusModel) => ({
    type: ADD_QUESTIONNAIRE_TAB_SUCCESS,
    payload: statusdata,
})

export const addQuestionnaireTabFail = (error: any )=> ({
    type: ADD_QUESTIONNAIRE_TAB_FAIL,
    payload: error,
})

export const updateQuestionnaireTab = (statusdata: StatusModel, history : any) => ({
    type: UPDATE_QUESTIONNAIRE_TAB,
    payload: { statusdata, history },
})

export const updateQuestionnaireTabSuccess = (statusdata: StatusModel)  => ({
    type: UPDATE_QUESTIONNAIRE_TAB_SUCCESS,
    payload: statusdata,
})

export const updateQuestionnaireTabFail = (error: any) => ({
    type: UPDATE_QUESTIONNAIRE_TAB_FAIL,
    payload: error,
})

export const deleteQuestionnaireTab = (statusdata: any, history: any) => ({
    type: DELETE_QUESTIONNAIRE_TAB,
    payload: { statusdata, history },
})

export const deleteQuestionnaireTabSuccess = (statusdata: any) => ({
    type: DELETE_QUESTIONNAIRE_TAB_SUCCESS,
    payload: statusdata,
})

export const deleteQuestionnaireTabFail = (error: any) => ({
    type: DELETE_QUESTIONNAIRE_TAB_FAIL,
    payload: error,
})

export const getQuestionnaireTabsFail = (error: any) => ({
    type: GET_QUESTIONNAIRE_TABS_FAIL,
    payload: error,
})

export const getQuestionnaireTabDetail = (statusId: any) => ({
    type: GET_QUESTIONNAIRE_TAB_DETAIL,
    statusId,
})

export const getQuestionnaireTabDetailSuccess = (statusDetails: StatusModel) => ({
    type: GET_QUESTIONNAIRE_TAB_DETAIL_SUCCESS,
    payload: statusDetails,
})

export const getQuestionnaireTabDetailFail = (error:any) => ({
    type: GET_QUESTIONNAIRE_TAB_DETAIL_FAIL,
    payload: error,
})
