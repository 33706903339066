import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { serialize } from '../../../utils/utils';
import { getUserProfile } from '../../../helpers/functions_helper';
import LoadingSpinner from '../../../components/Common/Loader';
import GraphFilters from '../../../components/Chart/Filters';
import tasksService from "../../../services/tasks.service";
import Chart from "../../../components/Chart";
import { currentMonth, currentYear } from '../../../common/staticdata';
interface RevenueStreamProps extends CartProps {
    filters?: any;
}

const InternalTaskVsExternalTask: FC<RevenueStreamProps> = ({ hideHeading, filters = null }) => {

    const { t } = useTranslation();
    const userObj = getUserProfile('all-tasks');
    const navigate = useNavigate();
    const inititalFilter = {
        week: null,
        quarter: null,
        month: currentMonth,
        year: currentYear,
        user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_ID : null,
        user_name: null
    };

    const [loading, setLoading] = useState<boolean>(true);
    const [query, setQuery] = useState<string | null>(serialize(inititalFilter));
    const [filter, setFilter] = useState<any>(inititalFilter);

    const [data, setData] = useState<any>({
        series: [],
        options: {
            chart: {
                width: 380,
                type: 'pie',
            },
            labels: [],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200,
                        height: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            legend: {
                show: true,
                position: 'right',
                width: 200,
            },
        },
    })

    const getChartData = async () => {
        try {
            const result = await tasksService.getInternalTaskVsExternalTask(query);
            if (result.data.status === 'success') {
                setData((data: any) => ({
                    ...data,
                    series: result?.data?.data[0].data || [],
                    ids: result?.data?.data[0].ids || [],
                    options: {
                        ...data.options,
                        labels: result?.data?.data[0].label,
                    }

                }))
            }
            setLoading(false);
        } catch (error: any) {
            toast(error)
        }

    }

    const clearWorkAllocationFilter = () => {
        setFilter(inititalFilter);
    }

    useEffect(() => {
        loading && getChartData();
    }, [loading])

    useEffect(() => {
        setQuery(serialize(filter));
        setLoading(true);
    }, [filter])

    useEffect(() => {
        filters && setFilter(filters)
    }, [filters])

    useEffect(() => { setLoading(true) }, [t])


    return (
        <div style={{ height: '370px' }}>
            <div className="d-sm-flex flex-wrap">
                <h4 className="card-title mb-4">{!hideHeading && t("Internal Task Vs External Task")}</h4>
                <div className="ms-auto">
                    <GraphFilters
                        filter={filter}
                        setFilter={setFilter}
                        clearFilter={clearWorkAllocationFilter}
                        slug='all-tasks'
                    // graphComponent={InternalTaskVsExternalTask}
                    // isCompareMerticsEnable={true}
                    />
                </div>
            </div>
            <div className="clearfix"></div>
            {loading ? <LoadingSpinner height={380} /> : <div style={{ height: '100%' }}><Chart options={data.options} series={data.series} type="pie" height={380} /></div>}
        </div>
    )
}

export default InternalTaskVsExternalTask;