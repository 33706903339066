import React, { RefObject } from 'react';
//@ts-ignore
import { Rnd } from "react-rnd";


const ADJUSTERS_DIMENSIONS = 20;

interface Props {
  dimmerActive: boolean;
  cancelDelete: () => void;
  deleteImage: () => void;
  width: number;
  height: number;
  canvasRef: RefObject<HTMLCanvasElement>;
  onClick: DragEventListener<HTMLDivElement>;
  handleResize: any;
  handleDrag: any;
  x: any;
  y: any;
  removeImage: any;
}

export const Image: React.FC<Props> = ({
  canvasRef,
  width,
  height,
  dimmerActive,
  cancelDelete,
  deleteImage,
  onClick,
  handleResize,
  handleDrag,
  x,
  y,
  removeImage
}) => {
  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px dashed rgb(107, 117, 171)",
    background: "white"
  } as const;
  return (
    <Rnd
      style={style}
      default={{
        x: x,
        y: y,
        width: width,
        height: height,
      }}

      onDragStop={handleDrag}
      onResizeStop={handleResize}
      bounds="parent"
    >
      <span className="material-symbols-outlined delete-attach" onClick={removeImage}>
        cancel
      </span>
      <canvas
        ref={canvasRef}
        style={{
          width: '100%',
          height: '100%',
        }}
      />

    </Rnd>
  );
};
