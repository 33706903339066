import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Nav, NavItem, Row } from "reactstrap";
import { useSelector } from "react-redux";
import "./style.scss";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../components/Layout/Breadcrumb";
import WebsiteUserBecomeCustomer from "./WebsiteUserBecomeCustomer";
import CPOHits from "./CPOHits";
import WebsiteUsersVsCustomer from "./WebsiteUsersVsCustomer";
import CPOIndividualHitsCount from "./CPOHitsCount";

import { useTranslation } from "react-i18next";
import {
  getUserProfile,
  widgetAccess,
} from "../../../helpers/functions_helper";
import GraphFilters from "../../../components/Common/GraphFilters";

const CPOGraphicalView = (props: any) => {
  const { t } = useTranslation();
  document.title = `${t("CPO")} - ${t("Graphical view")} | ${process.env.REACT_APP_NAME}`;
  const userProfile = getUserProfile("all-cpo");
  const inititalFilter = {
    week: null,
    quarter: null,
    month: null,
    year: null,
    user_id: !(userProfile.CAN_VIEW && userProfile.IS_COMPANY_ADMIN)
      ? userProfile.USER_ID
      : null,
  };
  const [filter, setFilter] = useState<any>(inititalFilter);

  const clearFilter = () => {
    setFilter(inititalFilter);
  };

  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "#",
      label: t("CPO"),
      active: true,
    },
  ];

  return (
    <React.Fragment>
      <>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              breadcrumbTitle={t("CPO")}
              breadcrumbItems={breadcrumbItems}
              endAdornment={
                <div className="d-flex gap-2 text-end">
                  <Link
                    to="/cpo/list/all"
                    className={`btn btn-outline-secondary btn-label btn-sm`}
                  >
                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                      list_alt
                    </span>
                    {t("List view")}
                  </Link>

                  <Link
                    to="/cpo/graph"
                    className={`btn btn-info btn-label btn-sm`}
                  >
                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                      insert_chart
                    </span>
                    {t("Graphical view")}
                  </Link>

                  {userProfile.CAN_CREATE ? (
                    <Link
                      to="/cpo/new-cpo"
                      className="btn btn-primary btn-label btn-sm"
                    >
                      <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                        add
                      </span>{" "}
                      {t("Create")} CPO
                    </Link>
                  ) : null}
                </div>
              }
            />
            {widgetAccess("CPO_Count") ? <CPOIndividualHitsCount /> : null}

            <div className="d-flex justify-content-end mb-2">
              <h5 className="card-title me-2">{t("Global Filter")}</h5>
              <div className="ms-2">
                <GraphFilters
                  filter={filter}
                  setFilter={setFilter}
                  clearWorkAllocationFilter={clearFilter}
                  userObj={userProfile}
                />
              </div>
            </div>
            {widgetAccess("Website_users_Vs_Customer") ? (
              <Card className="border-0">
                <CardBody>
                  <WebsiteUsersVsCustomer filters={filter} />
                </CardBody>
              </Card>
            ) : null}

            {widgetAccess("CPO_Hits") ? (
              <Card className="border-0">
                <CardBody>
                  <CPOHits filters={filter} />
                </CardBody>
              </Card>
            ) : null}

            {widgetAccess("Website_user_become_customer") ? (
              <Row>
                <Col sm={6}>
                  <Card className="border-0">
                    <CardBody>
                      <WebsiteUserBecomeCustomer filters={filter} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : null}
          </Container>
        </div>
      </>
    </React.Fragment>
  );
};

export default CPOGraphicalView;
