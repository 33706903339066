import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserProfile } from '../../../../helpers/functions_helper';
import { serialize } from '../../../../utils/utils';
import GraphFilters from './Filters';
import LoadingSpinner from '../../../../components/Common/Loader';
import { noData } from '../../../../utils/data';
import mastersCompanyService from '../../../../services/masters.company.service';

import { currentMonth, currentYear } from '../../../../common/staticdata';
import ChartFilters from '../../../../components/Chart/Filters';
import Chart from '../../../../components/Chart';

interface ActiveVsNonActiveProps extends CartProps {
    filters?: any;
}

const ActiveVsNonActive: FC<ActiveVsNonActiveProps> = ({ hideHeading, filters = null }) => {

    const { t } = useTranslation();

    const userObj = getUserProfile();

    const inititalFilter = {
        week: null,
        quarter: null,
        month: null,
        year: currentYear,
        user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_ID : null
    };

    const [loading, setLoading] = useState<boolean>(true);
    const [query, setQuery] = useState<string | null>(serialize(inititalFilter));
    const [filter, setFilter] = useState<any>(inititalFilter);
    useEffect(() => {
        clearWorkAllocationFilter();
    }, [localStorage.getItem("I18N_LANGUAGE")]);
    const [data, setData] = useState<any>({
        options: {
            // colors: ['#564ab1', '#d92626'],
            plotOptions: {
                pie: {
                    donut: {
                        size: '50%',
                    },

                },
            },
            labels: [t('Active'), t('Archived')],
            legend: {
                show: true,
                position: 'bottom',
            },
            noData: noData
        },
        series: [],

    })

    const getChartData = async () => {
        const result = await mastersCompanyService.activeVsNonActive(query);
        if (result.data.status === 'success') {
            setData((data: any) => ({
                ...data,
                series: result?.data?.data?.active || result?.data?.data?.inactive ? [result?.data?.data?.active, result?.data?.data?.inactive] : [],
                options: {
                    ...data.options,
                    labels: [t("Active"), t('Archived')],
                }

            }))
        }
        setLoading(false);
    }

    const clearWorkAllocationFilter = () => {
        setFilter(inititalFilter);
        setData((state: any) => ({
            ...state,
            options: {
                ...state.options,
                labels: [t('Active'), t('Archived')],
            }
        }));
    }

    useEffect(() => {
        loading && getChartData();
    }, [loading])

    useEffect(() => {
        setQuery(serialize(filter));
        setLoading(true);
    }, [filter])

    useEffect(() => {
        filters && setFilter(filters)
    }, [filters])

    return (
        <>
            <div className="d-sm-flex flex-wrap">
                <h4 className="card-title mb-4">{!hideHeading && t("Active Vs Nonactive company")}</h4>
                <div className="ms-auto">
                    <ChartFilters
                        filter={filter}
                        setFilter={setFilter}
                        clearFilter={clearWorkAllocationFilter}
                    />
                </div>
            </div>
            <div className="clearfix"></div>
            {loading ? <LoadingSpinner height={335} /> : <Chart options={data.options} series={data.series} type="pie" height={335} />}
        </>
    )
}

export default ActiveVsNonActive;