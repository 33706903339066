import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Input,
  Label,
  Card,
  Table,
  FormGroup,
} from "reactstrap";
import NoDataFound from "../../components/NoDataFound";
import Tooltip from "../../components/Tooltip/Tooltip";
import { getUserProfile } from "../../helpers/functions_helper";
import usersService from "../../services/users.service";

const Notification = (props: any) => {
  const { t } = useTranslation();
  document.title = `${t("Update user")} - ${t("Notifications")} | ${process.env.REACT_APP_NAME}`;

  const userProfile = getUserProfile("all-users");
  
  let { id, user_id } = useParams();

  const [loadingListData, setLoadingListData] = useState(true);
  const [intialData, setIntialData] = useState<any>({
    is_email_notification: 1, // Email notification field from User

    user_end_date_notification: 1,
    inpatriate_notification : 1,
    inpatriate_email: 1,
    civil_status_notification: 1,
    dependents_notification: 1,

    user_aml_notification: 1,
    user_aml_email: 1,
    user_cap_notification: 1,
    user_cap_email: 1,
    passport_notification: 1,
    residence_card_notification: 1,
    residence_card_email: 1,
    tax_on_web_notification: 1,
    single_permit_notification: 1,
    single_permit_email: 1,

    questionnaire_notification: 1,
    questionnaire_email: 1,

    company_ubo_notification: 1,
    company_ubo_email: 1,
    company_aml_notification: 1,
    company_aml_email: 1,
    company_cap_notification: 1,
    company_cap_email: 1,

    // task_creation_notification: 1,
    // task_creation_email: 1,

    user_app_notification: 1,
    user_email_notification: 1,
    users_documents_app_notification: 1,
    users_documents_email_notification: 1,
    company_app_notification: 1,
    company_email_notification: 1,
    company_documents_app_notification: 1,
    company_documents_email_notification: 1,
    task_app_notification: 1,
    task_email_notification: 1,
    subtask_app_notification: 1,
    subtask_email_notification: 1,
    time_sheet_app_notification: 1,
    time_sheet_email_notification: 1,
    invoice_app_notification: 1,
    invoice_email_notification: 1,
    questionnaire_app_notification: 1,
    questionnaire_email_notification: 1,
    document_signature_app_notification: 1,
    document_signature_email_notification: 1,
    cpo_app_notification: 1,
    cpo_email_notification: 1,
    split_payroll_app_notification: 1,
    split_payroll_email_notification: 1,
    news_feed_app_notification: 1,
    news_feed_email_notification: 1,
  });

  const notificationData = [
    // User
    {
      notification_for: t("User"),
      notification_on: "app service",
      icon: "account_circle",
      app_notification_field: "user_app_notification",
      app_notification_disabled: false,
      email_notification_field: "user_email_notification",
      email_notification_disabled: false,
    },
    {
      notification_for: t("User - End date"),
      notification_on: "cronjob",
      icon: "group",
      app_notification_field: "user_end_date_notification",
      app_notification_disabled: false,
      email_notification_field: null,
      email_notification_disabled: false,
    },
    {
      notification_for: t("User - Civil status"),
      notification_on: "app service",
      icon: "group",
      app_notification_field: "civil_status_notification",
      app_notification_disabled: false,
      email_notification_field: null,
      email_notification_disabled: false,
    },
    {
      notification_for: t("User - Dependents"),
      notification_on: "app service",
      icon: "group",
      app_notification_field: "dependents_notification",
      app_notification_disabled: false,
      email_notification_field: null,
      email_notification_disabled: false,
    },
    {
      notification_for: t("User - AML"),
      notification_on: "app service",
      icon: "group",
      app_notification_field: "user_aml_notification",
      app_notification_disabled: !userProfile.IS_COMPANY_ADMIN ? true : false,
      email_notification_field: "user_aml_email",
      email_notification_disabled: false,
    },
    {
      notification_for: t("User - CAP"),
      notification_on: "app service",
      icon: "group",
      app_notification_field: "user_cap_notification",
      app_notification_disabled: !userProfile.IS_COMPANY_ADMIN ? true : false,
      email_notification_field: "user_cap_email",
      email_notification_disabled: false,
    },
    {
      notification_for: t("User - Passport"),
      notification_on: "cronjob",
      icon: "group",
      app_notification_field: "passport_notification",
      app_notification_disabled: false,
      email_notification_field: null,
      email_notification_disabled: false,
    },
    {
      notification_for: t("User - Residence card"),
      notification_on: "cronjob",
      icon: "group",
      app_notification_field: "residence_card_notification",
      app_notification_disabled: false,
      email_notification_field: "residence_card_email",
      email_notification_disabled: !userProfile.IS_COMPANY_ADMIN ? true : false,
    },
    {
      notification_for: t("User - Tax on web"),
      notification_on: "cronjob",
      icon: "group",
      app_notification_field: "tax_on_web_notification",
      app_notification_disabled: false,
      email_notification_field: "tax_on_web_email",
      email_notification_disabled: false,
    },
    {
      notification_for: t("User - Single permit"),
      notification_on: "cronjob",
      icon: "group",
      app_notification_field: "single_permit_notification",
      app_notification_disabled: false,
      email_notification_field: "single_permit_email",
      email_notification_disabled: !userProfile.IS_COMPANY_ADMIN ? true : false,
    },

    {
      notification_for: t("User -Inpatriate Date"),
      notification_on: "cronjob",
      icon: "group",
      app_notification_field: "inpatriate_notification",
      app_notification_disabled: false,
      email_notification_field: "inpatriate_email",
      email_notification_disabled: false,
    },

    // User - Documents
    {
      notification_for: t("User - Documents"),
      notification_on: "app service",
      icon: "contact_page",
      app_notification_field: "users_documents_app_notification",
      app_notification_disabled: false,
      email_notification_field: "users_documents_email_notification",
      email_notification_disabled: false,
    },

    // Company
    {
      notification_for: t("Company"),
      notification_on: "app service",
      icon: "domain",
      app_notification_field: "company_app_notification",
      app_notification_disabled: !userProfile.IS_COMPANY_ADMIN ? true : false,
      email_notification_field: "company_email_notification",
      email_notification_disabled: !userProfile.IS_COMPANY_ADMIN ? true : false,
    },
    {
      notification_for: t("Company - UBO"),
      notification_on: "cronjob",
      icon: "domain",
      app_notification_field: "company_ubo_notification",
      app_notification_disabled: !userProfile.IS_COMPANY_ADMIN ? true : false,
      email_notification_field: "company_ubo_email",
      email_notification_disabled: !userProfile.IS_COMPANY_ADMIN ? true : false,
    },
    {
      notification_for: t("Company - AML"),
      notification_on: "cronjob",
      icon: "domain",
      app_notification_field: "company_aml_notification",
      app_notification_disabled: !userProfile.IS_COMPANY_ADMIN ? true : false,
      email_notification_field: "company_aml_email",
      email_notification_disabled: !userProfile.IS_COMPANY_ADMIN ? true : false,
    },
    {
      notification_for: t("Company - CAP"),
      notification_on: "cronjob",
      icon: "domain",
      app_notification_field: "company_cap_notification",
      app_notification_disabled: !userProfile.IS_COMPANY_ADMIN ? true : false,
      email_notification_field: "company_cap_email",
      email_notification_disabled: !userProfile.IS_COMPANY_ADMIN ? true : false,
    },

    // Company - Documents
    {
      notification_for: t("Company - Documents"),
      notification_on: "app service",
      icon: "lab_profile",
      app_notification_field: "company_documents_app_notification",
      app_notification_disabled: false,
      email_notification_field: "company_documents_email_notification",
      email_notification_disabled: false,
    },

    // Task
    {
      notification_for: t("Task"),
      notification_on: "app service",
      icon: "task",
      app_notification_field: "task_app_notification",
      app_notification_disabled: !userProfile.IS_COMPANY_ADMIN ? true : false,
      email_notification_field: "task_email_notification",
      email_notification_disabled: !userProfile.IS_COMPANY_ADMIN ? true : false,
    },

    // Subtask
    {
      notification_for: t("Subtask"),
      notification_on: "app service",
      icon: "task",
      app_notification_field: "subtask_app_notification",
      app_notification_disabled: !userProfile.IS_COMPANY_ADMIN ? true : false,
      email_notification_field: "subtask_email_notification",
      email_notification_disabled: !userProfile.IS_COMPANY_ADMIN ? true : false,
    },

    // Time sheet
    {
      notification_for: t("Time sheet"),
      notification_on: "app service",
      icon: "pending_actions",
      app_notification_field: "time_sheet_app_notification",
      app_notification_disabled: false,
      email_notification_field: "time_sheet_email_notification",
      email_notification_disabled: false,
    },

    // Invoice
    {
      notification_for: t("Invoice"),
      notification_on: "app service",
      icon: "receipt_long",
      app_notification_field: "invoice_app_notification",
      app_notification_disabled: false,
      email_notification_field: "invoice_email_notification",
      email_notification_disabled: false,
    },

    // Questionnaire
    {
      notification_for: t("Questionnaire"),
      notification_on: "cronjob",
      icon: "unknown_document",
      app_notification_field: "questionnaire_notification",
      app_notification_disabled: !userProfile.IS_COMPANY_ADMIN ? true : false,
      email_notification_field: "questionnaire_email",
      email_notification_disabled: !userProfile.IS_COMPANY_ADMIN ? true : false,
    },

    {
      notification_for: t("Questionnaire"),
      notification_on: "app service",
      icon: "unknown_document",
      app_notification_field: "questionnaire_app_notification",
      app_notification_disabled: false,
      email_notification_field: "questionnaire_email_notification",
      email_notification_disabled: false,
    },

    // Document signature
    {
      notification_for:  t("Document signature"),
      notification_on: "app service",
      icon: "draw",
      app_notification_field: "document_signature_app_notification",
      app_notification_disabled: false,
      email_notification_field: "document_signature_email_notification",
      email_notification_disabled: false,
    },

    // CPO
    {
      notification_for: t("CPO"),
      notification_on: "app service",
      icon: "calculate",
      app_notification_field: "cpo_app_notification",
      app_notification_disabled: false,
      email_notification_field: "cpo_email_notification",
      email_notification_disabled: false,
    },

    // Split payroll
    {
      notification_for: t("Split payroll"),
      notification_on: "app service",
      icon: "request_quote",
      app_notification_field: "split_payroll_app_notification",
      app_notification_disabled: false,
      email_notification_field: "split_payroll_email_notification",
      email_notification_disabled: false,
    },

    // News feed
    {
      notification_for: t("News feed"),
      notification_on: "app service",
      icon: "newspaper",
      app_notification_field: "news_feed_app_notification",
      app_notification_disabled: false,
      email_notification_field: "news_feed_email_notification",
      email_notification_disabled: false,
    },
  ] as any;

  const handleChange = (fieldName: string, fieldValue: any) => {
    setIntialData({ ...intialData, [fieldName]: fieldValue });
    updateNotification(fieldName, fieldValue);
  };

  const updateNotification = async (fieldName: any, fieldValue: any) => {
    await usersService.notificationUpdate({ id, fieldName, fieldValue });
  };

  const getNotificationData = async () => {
    const result = await usersService.getNotificationData({ id });
    if (result?.data?.status === "success") {
      setIntialData(result.data?.data[0]);
    } else if (result?.data?.status === "error") {
      setIntialData((prevData: any) => ({
        ...prevData,
        is_email_notification: result.data?.data[0]?.is_email_notification,
      }));
    }

    setLoadingListData(false);
  };

  useEffect(() => {
    getNotificationData();
  }, []);

  return (
    // <Row>
    //   <Col xl="12">
    //     <Card className="border-top-0 rounded-0 rounded-bottom">
    //       <CardHeader className="bg-transparent border-bottom border-primary mt-2 pb-0">
    //         <CardTitle className="card-title text-uppercase">
    //           {t("Notification")}
    //         </CardTitle>
    //       </CardHeader>
    //       <CardBody>
    //         <Row>
    //           <Row>
    //             <h5>{t("User")}</h5>
    //           </Row>

    //           <Col xl={3} lg={3} md={3} sm={12}>
    //             <Label className="form-label">
    //               {t("User end date in application")}
    //             </Label>
    //             <Col>
    //               <Label className="form-label me-3">{t("Notification")}</Label>
    //               <div className="form-check form-check-inline mb-2">
    //                 <Input
    //                   id="user_end_date_notification_yes"
    //                   name="user_end_date_notification"
    //                   value="1"
    //                   className="form-check-input"
    //                   type="radio"
    //                   checked={
    //                     intialData.user_end_date_notification == 1
    //                       ? true
    //                       : false
    //                   }
    //                   // disabled={!userProfile.IS_COMPANY_ADMIN ? true : false}
    //                   onChange={() =>
    //                     handleChange("user_end_date_notification", 1)
    //                   }
    //                 />
    //                 <label
    //                   className="form-check-label"
    //                   htmlFor="user_end_date_notification_yes"
    //                 >
    //                   Yes
    //                 </label>
    //               </div>

    //               <div className="form-check form-check-inline  mb-2">
    //                 <Input
    //                   id="user_end_date_notification_no"
    //                   name="user_end_date_notification"
    //                   value="0"
    //                   className="form-check-input"
    //                   type="radio"
    //                   checked={
    //                     intialData.user_end_date_notification == 0
    //                       ? true
    //                       : false
    //                   }
    //                   // disabled={!userProfile.IS_COMPANY_ADMIN ? true : false}
    //                   onChange={() =>
    //                     handleChange("user_end_date_notification", 0)
    //                   }
    //                 />
    //                 <label
    //                   className="form-check-label"
    //                   htmlFor="user_end_date_notification_no"
    //                 >
    //                   No
    //                 </label>
    //               </div>
    //             </Col>
    //           </Col>
    //           <Col xl={3} lg={3} md={3} sm={12}>
    //             <Label className="form-label">{t("User civil status")}</Label>
    //             <Col>
    //               <Label className="form-label me-3">{t("Notification")}</Label>
    //               <div className="form-check form-check-inline mb-2">
    //                 <Input
    //                   id="civil_status_notification_yes"
    //                   name="civil_status_notification"
    //                   value="1"
    //                   className="form-check-input"
    //                   type="radio"
    //                   checked={
    //                     intialData.civil_status_notification == 1 ? true : false
    //                   }
    //                   //disabled={!userProfile.IS_COMPANY_ADMIN ? true : false}
    //                   onChange={() =>
    //                     handleChange("civil_status_notification", 1)
    //                   }
    //                 />
    //                 <label
    //                   className="form-check-label"
    //                   htmlFor="civil_status_notification_yes"
    //                 >
    //                   Yes
    //                 </label>
    //               </div>

    //               <div className="form-check form-check-inline mb-2">
    //                 <Input
    //                   id="civil_status_notification_no"
    //                   name="civil_status_notification"
    //                   value="0"
    //                   className="form-check-input"
    //                   type="radio"
    //                   checked={
    //                     intialData.civil_status_notification == 0 ? true : false
    //                   }
    //                   //disabled={!userProfile.IS_COMPANY_ADMIN ? true : false}
    //                   onChange={() =>
    //                     handleChange("civil_status_notification", 0)
    //                   }
    //                 />
    //                 <label
    //                   className="form-check-label"
    //                   htmlFor="civil_status_notification_no"
    //                 >
    //                   No
    //                 </label>
    //               </div>
    //             </Col>
    //           </Col>
    //           <Col xl={3} lg={3} md={3} sm={12}>
    //             <Label className="form-label">{t("Dependents")}</Label>
    //             <Col>
    //               <Label className="form-label me-3">{t("Notification")}</Label>
    //               <div className="form-check form-check-inline mb-2">
    //                 <Input
    //                   id="dependents_notification_yes"
    //                   name="dependents_notification"
    //                   value="1"
    //                   className="form-check-input"
    //                   type="radio"
    //                   checked={
    //                     intialData.dependents_notification == 1 ? true : false
    //                   }
    //                   //disabled={!userProfile.IS_COMPANY_ADMIN ? true : false}
    //                   onChange={() =>
    //                     handleChange("dependents_notification", 1)
    //                   }
    //                 />
    //                 <label
    //                   className="form-check-label"
    //                   htmlFor="dependents_notification_yes"
    //                 >
    //                   Yes
    //                 </label>
    //               </div>

    //               <div className="form-check form-check-inline mb-2">
    //                 <Input
    //                   id="dependents_notification_no"
    //                   name="dependents_notification"
    //                   value="0"
    //                   className="form-check-input"
    //                   type="radio"
    //                   checked={
    //                     intialData.dependents_notification == 0 ? true : false
    //                   }
    //                   // disabled={!userProfile.IS_COMPANY_ADMIN ? true : false}
    //                   onChange={() =>
    //                     handleChange("dependents_notification", 0)
    //                   }
    //                 />
    //                 <label
    //                   className="form-check-label"
    //                   htmlFor="dependents_notification_no"
    //                 >
    //                   No
    //                 </label>
    //               </div>
    //             </Col>
    //           </Col>
    //           <Col className="col-12">
    //             <hr></hr>
    //           </Col>
    //           <Row>
    //             <h5>{t("User general")}</h5>
    //           </Row>
    //           <Col xl={2} lg={2} md={2} sm={12}>
    //             <Label className="form-label">{t("AML")}</Label>
    //             <Col>
    //               <Label className="form-label me-3">{t("Notification")}</Label>
    //               <div className="form-check form-check-inline mb-2">
    //                 <Input
    //                   id="user_aml_notification_yes"
    //                   name="user_aml_notification"
    //                   value="1"
    //                   className="form-check-input"
    //                   type="radio"
    //                   checked={
    //                     intialData.user_aml_notification == 1 ? true : false
    //                   }
    //                   // disabled={!userProfile.IS_COMPANY_ADMIN ? true : false}
    //                   onChange={() => handleChange("user_aml_notification", 1)}
    //                 />
    //                 <label
    //                   className="form-check-label"
    //                   htmlFor="user_aml_notification_yes"
    //                 >
    //                   Yes
    //                 </label>
    //               </div>

    //               <div className="form-check form-check-inline  mb-2">
    //                 <Input
    //                   id="user_aml_notification_no"
    //                   name="user_aml_notification"
    //                   value="0"
    //                   className="form-check-input"
    //                   type="radio"
    //                   checked={
    //                     intialData.user_aml_notification == 0 ? true : false
    //                   }
    //                   disabled={!userProfile.IS_COMPANY_ADMIN ? true : false}
    //                   onChange={() => handleChange("user_aml_notification", 0)}
    //                 />
    //                 <label
    //                   className="form-check-label"
    //                   htmlFor="user_aml_notification_no"
    //                 >
    //                   No
    //                 </label>
    //               </div>
    //             </Col>
    //             <Col>
    //               <Label className="form-label me-3">{t("Email")}</Label>
    //               <div className="form-check form-check-inline mb-2">
    //                 <Input
    //                   id="user_aml_email_yes"
    //                   name="user_aml_email"
    //                   value="1"
    //                   className="form-check-input"
    //                   type="radio"
    //                   checked={intialData.user_aml_email == 1 ? true : false}
    //                   // disabled={!userProfile.IS_COMPANY_ADMIN ? true : false}
    //                   onChange={() => handleChange("user_aml_email", 1)}
    //                 />
    //                 <label
    //                   className="form-check-label"
    //                   htmlFor="user_aml_email_yes"
    //                 >
    //                   Yes
    //                 </label>
    //               </div>

    //               <div className="form-check form-check-inline  mb-2">
    //                 <Input
    //                   id="user_aml_email_no"
    //                   name="user_aml_email"
    //                   value="0"
    //                   className="form-check-input"
    //                   type="radio"
    //                   checked={intialData.user_aml_email == 0 ? true : false}
    //                   //disabled={!userProfile.IS_COMPANY_ADMIN ? true : false}
    //                   onChange={() => handleChange("user_aml_email", 0)}
    //                 />
    //                 <label
    //                   className="form-check-label"
    //                   htmlFor="user_aml_email_no"
    //                 >
    //                   No
    //                 </label>
    //               </div>
    //             </Col>
    //           </Col>
    //           <Col xl={2} lg={2} md={2} sm={12}>
    //             <Label className="form-label">{t("Passport")}</Label>
    //             <Col>
    //               <Label className="form-label me-3">{t("Notification")}</Label>
    //               <div className="form-check form-check-inline mb-2">
    //                 <Input
    //                   id="passport_notification_yes"
    //                   name="passport_notification"
    //                   value="1"
    //                   className="form-check-input"
    //                   type="radio"
    //                   checked={
    //                     intialData.passport_notification == 1 ? true : false
    //                   }
    //                   //disabled={!userProfile.IS_COMPANY_ADMIN ? true : false}
    //                   onChange={() => handleChange("passport_notification", 1)}
    //                 />
    //                 <label
    //                   className="form-check-label"
    //                   htmlFor="passport_notification_yes"
    //                 >
    //                   Yes
    //                 </label>
    //               </div>

    //               <div className="form-check form-check-inline mb-2">
    //                 <Input
    //                   id="passport_notification_no"
    //                   name="passport_notification"
    //                   value="0"
    //                   className="form-check-input"
    //                   type="radio"
    //                   checked={
    //                     intialData.passport_notification == 0 ? true : false
    //                   }
    //                   //disabled={!userProfile.IS_COMPANY_ADMIN ? true : false}
    //                   onChange={() => handleChange("passport_notification", 0)}
    //                 />
    //                 <label
    //                   className="form-check-label"
    //                   htmlFor="passport_notification_no"
    //                 >
    //                   No
    //                 </label>
    //               </div>
    //             </Col>
    //           </Col>
    //           <Col xl={2} lg={2} md={2} sm={12}>
    //             <Label className="form-label">{t("Residence card")}</Label>
    //             <Col>
    //               <Label className="form-label me-3">{t("Notification")}</Label>
    //               <div className="form-check form-check-inline mb-2">
    //                 <Input
    //                   id="residence_card_notification_yes"
    //                   name="residence_card_notification"
    //                   value="1"
    //                   className="form-check-input"
    //                   type="radio"
    //                   checked={
    //                     intialData.residence_card_notification == 1
    //                       ? true
    //                       : false
    //                   }
    //                   //disabled={!userProfile.IS_COMPANY_ADMIN ? true : false}
    //                   onChange={() =>
    //                     handleChange("residence_card_notification", 1)
    //                   }
    //                 />
    //                 <label
    //                   className="form-check-label"
    //                   htmlFor="residence_card_notification_yes"
    //                 >
    //                   Yes
    //                 </label>
    //               </div>

    //               <div className="form-check form-check-inline mb-2">
    //                 <Input
    //                   id="residence_card_notification_no"
    //                   name="residence_card_notification"
    //                   value="0"
    //                   className="form-check-input"
    //                   type="radio"
    //                   checked={
    //                     intialData.residence_card_notification == 0
    //                       ? true
    //                       : false
    //                   }
    //                   //disabled={!userProfile.IS_COMPANY_ADMIN ? true : false}
    //                   onChange={() =>
    //                     handleChange("residence_card_notification", 0)
    //                   }
    //                 />
    //                 <label
    //                   className="form-check-label"
    //                   htmlFor="residence_card_notification_no"
    //                 >
    //                   No
    //                 </label>
    //               </div>
    //             </Col>
    //           </Col>
    //           <Col xl={2} lg={2} md={2} sm={12}>
    //             <Label className="form-label">{t("Tax on web")}</Label>
    //             <Col>
    //               <Label className="form-label me-3">{t("Notification")}</Label>
    //               <div className="form-check form-check-inline mb-2">
    //                 <Input
    //                   id="tax_on_web_notification_yes"
    //                   name="tax_on_web_notification"
    //                   value="1"
    //                   className="form-check-input"
    //                   type="radio"
    //                   checked={
    //                     intialData.tax_on_web_notification == 1 ? true : false
    //                   }
    //                   //disabled={!userProfile.IS_COMPANY_ADMIN ? true : false}
    //                   onChange={() =>
    //                     handleChange("tax_on_web_notification", 1)
    //                   }
    //                 />
    //                 <label
    //                   className="form-check-label"
    //                   htmlFor="tax_on_web_notification_yes"
    //                 >
    //                   Yes
    //                 </label>
    //               </div>

    //               <div className="form-check form-check-inline mb-2">
    //                 <Input
    //                   id="tax_on_web_notification_no"
    //                   name="tax_on_web_notification"
    //                   value="0"
    //                   className="form-check-input"
    //                   type="radio"
    //                   checked={
    //                     intialData.tax_on_web_notification == 0 ? true : false
    //                   }
    //                   //disabled={!userProfile.IS_COMPANY_ADMIN ? true : false}
    //                   onChange={() =>
    //                     handleChange("tax_on_web_notification", 0)
    //                   }
    //                 />
    //                 <label
    //                   className="form-check-label"
    //                   htmlFor="tax_on_web_notification_no"
    //                 >
    //                   No
    //                 </label>
    //               </div>
    //             </Col>
    //           </Col>
    //           <Col xl={2} lg={2} md={2} sm={12}>
    //             <Label className="form-label">{t("Single permit")}</Label>
    //             <Col>
    //               <Label className="form-label me-3">{t("Notification")}</Label>
    //               <div className="form-check form-check-inline mb-2">
    //                 <Input
    //                   id="single_permit_notification_yes"
    //                   name="single_permit_notification"
    //                   value="1"
    //                   className="form-check-input"
    //                   type="radio"
    //                   checked={
    //                     intialData.single_permit_notification == 1
    //                       ? true
    //                       : false
    //                   }
    //                   //  disabled={!userProfile.IS_COMPANY_ADMIN ? true : false}
    //                   onChange={() =>
    //                     handleChange("single_permit_notification", 1)
    //                   }
    //                 />
    //                 <label
    //                   className="form-check-label"
    //                   htmlFor="single_permit_notification_yes"
    //                 >
    //                   Yes
    //                 </label>
    //               </div>

    //               <div className="form-check form-check-inline mb-2">
    //                 <Input
    //                   id="single_permit_notification_no"
    //                   name="single_permit_notification"
    //                   value="0"
    //                   className="form-check-input"
    //                   type="radio"
    //                   checked={
    //                     intialData.single_permit_notification == 0
    //                       ? true
    //                       : false
    //                   }
    //                   //disabled={!userProfile.IS_COMPANY_ADMIN ? true : false}
    //                   onChange={() =>
    //                     handleChange("single_permit_notification", 0)
    //                   }
    //                 />
    //                 <label
    //                   className="form-check-label"
    //                   htmlFor="single_permit_notification_no"
    //                 >
    //                   No
    //                 </label>
    //               </div>
    //             </Col>
    //             <Col>
    //               <Label className="form-label me-3">{t("Email")}</Label>
    //               <div className="form-check form-check-inline mb-2">
    //                 <Input
    //                   id="single_permit_email_yes"
    //                   name="single_permit_email"
    //                   value="1"
    //                   className="form-check-input"
    //                   type="radio"
    //                   checked={
    //                     intialData.single_permit_email == 1 ? true : false
    //                   }
    //                   disabled={!userProfile.IS_COMPANY_ADMIN ? true : false}
    //                   onChange={() => handleChange("single_permit_email", 1)}
    //                 />
    //                 <label
    //                   className="form-check-label"
    //                   htmlFor="single_permit_email_yes"
    //                 >
    //                   Yes
    //                 </label>
    //               </div>

    //               <div className="form-check form-check-inline mb-2">
    //                 <Input
    //                   id="single_permit_email_no"
    //                   name="single_permit_email"
    //                   value="0"
    //                   className="form-check-input"
    //                   type="radio"
    //                   checked={
    //                     intialData.single_permit_email == 0 ? true : false
    //                   }
    //                   disabled={!userProfile.IS_COMPANY_ADMIN ? true : false}
    //                   onChange={() => handleChange("single_permit_email", 0)}
    //                 />
    //                 <label
    //                   className="form-check-label"
    //                   htmlFor="single_permit_email_no"
    //                 >
    //                   No
    //                 </label>
    //               </div>
    //             </Col>
    //           </Col>
    //           {userProfile.IS_COMPANY_ADMIN ? (
    //             <Col xl={3} lg={3} md={3} sm={12}>
    //               <Label className="form-label">{t("Questionnaire")}</Label>
    //               <Col>
    //                 <Label className="form-label me-3">
    //                   {t("Notification")}
    //                 </Label>
    //                 <div className="form-check form-check-inline mb-2">
    //                   <Input
    //                     id="questionnaire_notification_yes"
    //                     name="questionnaire_notification"
    //                     value="1"
    //                     className="form-check-input"
    //                     type="radio"
    //                     checked={
    //                       intialData.questionnaire_notification == 1
    //                         ? true
    //                         : false
    //                     }
    //                     disabled={!userProfile.IS_COMPANY_ADMIN ? true : false}
    //                     onChange={() =>
    //                       handleChange("questionnaire_notification", 1)
    //                     }
    //                   />
    //                   <label
    //                     className="form-check-label"
    //                     htmlFor="questionnaire_notification_yes"
    //                   >
    //                     Yes
    //                   </label>
    //                 </div>

    //                 <div className="form-check form-check-inline mb-2">
    //                   <Input
    //                     id="questionnaire_notification_no"
    //                     name="questionnaire_notification"
    //                     value="0"
    //                     className="form-check-input"
    //                     type="radio"
    //                     checked={
    //                       intialData.questionnaire_notification == 0
    //                         ? true
    //                         : false
    //                     }
    //                     disabled={!userProfile.IS_COMPANY_ADMIN ? true : false}
    //                     onChange={() =>
    //                       handleChange("questionnaire_notification", 0)
    //                     }
    //                   />
    //                   <label
    //                     className="form-check-label"
    //                     htmlFor="questionnaire_notification_no"
    //                   >
    //                     No
    //                   </label>
    //                 </div>
    //               </Col>
    //               <Col>
    //                 <Label className="form-label me-3">{t("Email")}</Label>
    //                 <div className="form-check form-check-inline mb-2">
    //                   <Input
    //                     id="questionnaire_email_yes"
    //                     name="questionnaire_email"
    //                     value="1"
    //                     className="form-check-input"
    //                     type="radio"
    //                     checked={
    //                       intialData.questionnaire_email == 1 ? true : false
    //                     }
    //                     disabled={!userProfile.IS_COMPANY_ADMIN ? true : false}
    //                     onChange={() => handleChange("questionnaire_email", 1)}
    //                   />
    //                   <label
    //                     className="form-check-label"
    //                     htmlFor="questionnaire_email_yes"
    //                   >
    //                     Yes
    //                   </label>
    //                 </div>

    //                 <div className="form-check form-check-inline mb-2">
    //                   <Input
    //                     id="questionnaire_email_no"
    //                     name="questionnaire_email"
    //                     value="0"
    //                     className="form-check-input"
    //                     type="radio"
    //                     checked={
    //                       intialData.questionnaire_email == 0 ? true : false
    //                     }
    //                     disabled={!userProfile.IS_COMPANY_ADMIN ? true : false}
    //                     onChange={() => handleChange("questionnaire_email", 0)}
    //                   />
    //                   <label
    //                     className="form-check-label"
    //                     htmlFor="questionnaire_email_no"
    //                   >
    //                     No
    //                   </label>
    //                 </div>
    //               </Col>
    //             </Col>
    //           ) : null}
    //           {userProfile.IS_COMPANY_ADMIN ? (
    //             <>
    //               <Col className="col-12">
    //                 <hr></hr>
    //               </Col>
    //               <Row>
    //                 <h5>{t("Company")}</h5>
    //               </Row>
    //               <Col xl={2} lg={2} md={2} sm={12}>
    //                 <Label className="form-label">{t("UBO")}</Label>
    //                 <Col>
    //                   <Label className="form-label me-3">
    //                     {t("Notification")}
    //                   </Label>
    //                   <div className="form-check form-check-inline mb-2">
    //                     <Input
    //                       id="company_ubo_notification_yes"
    //                       name="company_ubo_notification"
    //                       value="1"
    //                       className="form-check-input"
    //                       type="radio"
    //                       checked={
    //                         intialData.company_ubo_notification == 1
    //                           ? true
    //                           : false
    //                       }
    //                       disabled={
    //                         !userProfile.IS_COMPANY_ADMIN ? true : false
    //                       }
    //                       onChange={() =>
    //                         handleChange("company_ubo_notification", 1)
    //                       }
    //                     />
    //                     <label
    //                       className="form-check-label"
    //                       htmlFor="company_ubo_notification_yes"
    //                     >
    //                       Yes
    //                     </label>
    //                   </div>

    //                   <div className="form-check form-check-inline  mb-2">
    //                     <Input
    //                       id="company_ubo_notification_no"
    //                       name="company_ubo_notification"
    //                       value="0"
    //                       className="form-check-input"
    //                       type="radio"
    //                       checked={
    //                         intialData.company_ubo_notification == 0
    //                           ? true
    //                           : false
    //                       }
    //                       disabled={
    //                         !userProfile.IS_COMPANY_ADMIN ? true : false
    //                       }
    //                       onChange={() =>
    //                         handleChange("company_ubo_notification", 0)
    //                       }
    //                     />
    //                     <label
    //                       className="form-check-label"
    //                       htmlFor="company_ubo_notification_no"
    //                     >
    //                       No
    //                     </label>
    //                   </div>
    //                 </Col>
    //                 <Col>
    //                   <Label className="form-label me-3">{t("Email")}</Label>
    //                   <div className="form-check form-check-inline mb-2">
    //                     <Input
    //                       id="company_ubo_email_yes"
    //                       name="company_ubo_email"
    //                       value="1"
    //                       className="form-check-input"
    //                       type="radio"
    //                       checked={
    //                         intialData.company_ubo_email == 1 ? true : false
    //                       }
    //                       disabled={
    //                         !userProfile.IS_COMPANY_ADMIN ? true : false
    //                       }
    //                       onChange={() => handleChange("company_ubo_email", 1)}
    //                     />
    //                     <label
    //                       className="form-check-label"
    //                       htmlFor="company_ubo_email_yes"
    //                     >
    //                       Yes
    //                     </label>
    //                   </div>

    //                   <div className="form-check form-check-inline  mb-2">
    //                     <Input
    //                       id="company_ubo_email_no"
    //                       name="company_ubo_email"
    //                       value="0"
    //                       className="form-check-input"
    //                       type="radio"
    //                       checked={
    //                         intialData.company_ubo_email == 0 ? true : false
    //                       }
    //                       disabled={
    //                         !userProfile.IS_COMPANY_ADMIN ? true : false
    //                       }
    //                       onChange={() => handleChange("company_ubo_email", 0)}
    //                     />
    //                     <label
    //                       className="form-check-label"
    //                       htmlFor="company_ubo_email_no"
    //                     >
    //                       No
    //                     </label>
    //                   </div>
    //                 </Col>
    //               </Col>
    //               <Col xl={2} lg={2} md={2} sm={12}>
    //                 <Label className="form-label">{t("AML")}</Label>
    //                 <Col>
    //                   <Label className="form-label me-3">
    //                     {t("Notification")}
    //                   </Label>
    //                   <div className="form-check form-check-inline mb-2">
    //                     <Input
    //                       id="company_aml_notification_yes"
    //                       name="company_aml_notification"
    //                       value="1"
    //                       className="form-check-input"
    //                       type="radio"
    //                       checked={
    //                         intialData.company_aml_notification == 1
    //                           ? true
    //                           : false
    //                       }
    //                       //disabled={!userProfile.IS_COMPANY_ADMIN ? true : false}
    //                       onChange={() =>
    //                         handleChange("company_aml_notification", 1)
    //                       }
    //                     />
    //                     <label
    //                       className="form-check-label"
    //                       htmlFor="company_aml_notification_yes"
    //                     >
    //                       Yes
    //                     </label>
    //                   </div>

    //                   <div className="form-check form-check-inline mb-2">
    //                     <Input
    //                       id="company_aml_notification_no"
    //                       name="company_aml_notification"
    //                       value="0"
    //                       className="form-check-input"
    //                       type="radio"
    //                       checked={
    //                         intialData.company_aml_notification == 0
    //                           ? true
    //                           : false
    //                       }
    //                       disabled={
    //                         !userProfile.IS_COMPANY_ADMIN ? true : false
    //                       }
    //                       onChange={() =>
    //                         handleChange("company_aml_notification", 0)
    //                       }
    //                     />
    //                     <label
    //                       className="form-check-label"
    //                       htmlFor="company_aml_notification_no"
    //                     >
    //                       No
    //                     </label>
    //                   </div>
    //                 </Col>
    //                 <Col>
    //                   <Label className="form-label me-3">{t("Email")}</Label>
    //                   <div className="form-check form-check-inline mb-2">
    //                     <Input
    //                       id="company_aml_email_yes"
    //                       name="company_aml_email"
    //                       value="1"
    //                       className="form-check-input"
    //                       type="radio"
    //                       checked={
    //                         intialData.company_aml_email == 1 ? true : false
    //                       }
    //                       //disabled={!userProfile.IS_COMPANY_ADMIN ? true : false}
    //                       onChange={() => handleChange("company_aml_email", 1)}
    //                     />
    //                     <label
    //                       className="form-check-label"
    //                       htmlFor="company_aml_email_yes"
    //                     >
    //                       Yes
    //                     </label>
    //                   </div>

    //                   <div className="form-check form-check-inline mb-2">
    //                     <Input
    //                       id="company_aml_email_no"
    //                       name="company_aml_email"
    //                       value="0"
    //                       className="form-check-input"
    //                       type="radio"
    //                       checked={
    //                         intialData.company_aml_email == 0 ? true : false
    //                       }
    //                       disabled={
    //                         !userProfile.IS_COMPANY_ADMIN ? true : false
    //                       }
    //                       onChange={() => handleChange("company_aml_email", 0)}
    //                     />
    //                     <label
    //                       className="form-check-label"
    //                       htmlFor="company_aml_email_no"
    //                     >
    //                       No
    //                     </label>
    //                   </div>
    //                 </Col>
    //               </Col>
    //               <Col className="col-12">
    //                 <hr></hr>
    //               </Col>
    //             </>
    //           ) : null}

    //           {userProfile.IS_COMPANY_ADMIN ? (
    //             <>
    //               <Row>
    //                 <h5>{t("Task")}</h5>
    //               </Row>
    //               <Col xl={2} lg={2} md={2} sm={12}>
    //                 {/* <Label className="form-label">{t('Creation')}</Label> */}
    //                 <Col>
    //                   <Label className="form-label me-3">
    //                     {t("Notification")}
    //                   </Label>
    //                   <div className="form-check form-check-inline mb-2">
    //                     <Input
    //                       id="task_creation_notification_yes"
    //                       name="task_creation_notification"
    //                       value="1"
    //                       className="form-check-input"
    //                       type="radio"
    //                       checked={
    //                         intialData.task_creation_notification == 1
    //                           ? true
    //                           : false
    //                       }
    //                       disabled={
    //                         !userProfile.IS_COMPANY_ADMIN ? true : false
    //                       }
    //                       onChange={() =>
    //                         handleChange("task_creation_notification", 1)
    //                       }
    //                     />
    //                     <label
    //                       className="form-check-label"
    //                       htmlFor="task_creation_notification_yes"
    //                     >
    //                       Yes
    //                     </label>
    //                   </div>

    //                   <div className="form-check form-check-inline  mb-2">
    //                     <Input
    //                       id="task_creation_notification_no"
    //                       name="task_creation_notification"
    //                       value="0"
    //                       className="form-check-input"
    //                       type="radio"
    //                       checked={
    //                         intialData.task_creation_notification == 0
    //                           ? true
    //                           : false
    //                       }
    //                       disabled={
    //                         !userProfile.IS_COMPANY_ADMIN ? true : false
    //                       }
    //                       onChange={() =>
    //                         handleChange("task_creation_notification", 0)
    //                       }
    //                     />
    //                     <label
    //                       className="form-check-label"
    //                       htmlFor="task_creation_notification_no"
    //                     >
    //                       No
    //                     </label>
    //                   </div>
    //                 </Col>
    //                 <Col>
    //                   <Label className="form-label me-3">{t("Email")}</Label>
    //                   <div className="form-check form-check-inline mb-2">
    //                     <Input
    //                       id="task_creation_email_yes"
    //                       name="task_creation_email"
    //                       value="1"
    //                       className="form-check-input"
    //                       type="radio"
    //                       checked={
    //                         intialData.task_creation_email == 1 ? true : false
    //                       }
    //                       disabled={
    //                         !userProfile.IS_COMPANY_ADMIN ? true : false
    //                       }
    //                       onChange={() =>
    //                         handleChange("task_creation_email", 1)
    //                       }
    //                     />
    //                     <label
    //                       className="form-check-label"
    //                       htmlFor="task_creation_email_yes"
    //                     >
    //                       Yes
    //                     </label>
    //                   </div>

    //                   <div className="form-check form-check-inline  mb-2">
    //                     <Input
    //                       id="task_creation_email_no"
    //                       name="task_creation_email"
    //                       value="0"
    //                       className="form-check-input"
    //                       type="radio"
    //                       checked={
    //                         intialData.task_creation_email == 0 ? true : false
    //                       }
    //                       disabled={
    //                         !userProfile.IS_COMPANY_ADMIN ? true : false
    //                       }
    //                       onChange={() =>
    //                         handleChange("task_creation_email", 0)
    //                       }
    //                     />
    //                     <label
    //                       className="form-check-label"
    //                       htmlFor="task_creation_email_no"
    //                     >
    //                       No
    //                     </label>
    //                   </div>
    //                 </Col>
    //               </Col>
    //             </>
    //           ) : null}
    //         </Row>
    //       </CardBody>
    //     </Card>
    //   </Col>
    // </Row>

    <Card className="border-top-0 rounded-0 rounded-bottom">
      <CardHeader className="bg-transparent border-bottom border-primary mt-2 pb-0">
        <CardTitle className="card-title text-uppercase">
          {t("Notifications")}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <div id="notifications_data_table" className="table-responsive mb-3">
          <Table className="table table-bordered table-hover nowrap w-100 mb-0">
            <thead>
              <tr>
                <th>{t("Sr. No.")}</th>
                <th>{t("Notification for")}</th>
                <th>
                  {t("App notification")}
                  {/* <span className="align-middle material-symbols-outlined fw-light fs-4 ms-2 text-muted">
                    notifications
                  </span> */}
                </th>
                <th>
                  {t("Email notification")}
                  {/* <span className="align-middle material-symbols-outlined fw-light fs-4 ms-2 text-muted">
                    email
                  </span> */}
                  {intialData.is_email_notification === 0 ? (
                    <Tooltip
                      content={
                        "Email notification from User details is not active."
                      }
                      direction="left"
                    >
                      <span className="align-middle material-symbols-outlined fw-light fs-4 ms-2">
                        {t("info")}
                      </span>
                    </Tooltip>
                  ) : null}
                </th>
              </tr>
            </thead>

            {loadingListData === true ? (
              <tbody>
                <tr>
                  <td colSpan={4}>
                    <div className="data-notification mt-5">
                      <NoDataFound
                        icon="quick_reference_all"
                        message={t("Please wait, We are looking for the records!")}
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {notificationData &&
                  notificationData.map((item: any, idx: number) => (
                    <React.Fragment key={idx}>
                      <tr>
                        <td>{idx + 1}</td>
                        <td>
                          <span className="align-middle material-symbols-outlined fw-light fs-4 me-2">
                            {item.icon}
                          </span>
                          {item.notification_for}

                          {item.notification_on === "app service" ? (
                            <span className="badge_custome badge-soft-primary badge bg-primary rounded-pill ms-2">
                              <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                check_circle
                              </span>
                              {item.notification_on}
                            </span>
                          ) : (
                            <span className="badge_custome badge-soft-info badge bg-info rounded-pill ms-2">
                              <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">
                                captive_portal
                              </span>
                              {item.notification_on}
                            </span>
                          )}
                        </td>
                        <td>
                          {item.app_notification_field !== null ? (
                            <FormGroup
                              switch
                              inline
                              className="form-check-info"
                            >
                              <Input
                                type="switch"
                                dis
                                checked={
                                  intialData[item.app_notification_field] === 1
                                    ? true
                                    : false
                                }
                                disabled={item.app_notification_disabled}
                                onChange={() =>
                                  handleChange(
                                    item.app_notification_field,
                                    intialData[item.app_notification_field] ===
                                      1
                                      ? 0
                                      : 1
                                  )
                                }
                                label={
                                  intialData[item.app_notification_field] === 1
                                    ? "Yes"
                                    : "No"
                                }
                              />
                            </FormGroup>
                          ) : null}
                        </td>
                        <td>
                          {intialData.is_email_notification === 1 ? (
                            item.email_notification_field !== null ? (
                              <FormGroup
                                switch
                                inline
                                className="form-check-info"
                              >
                                <Input
                                  type="switch"
                                  dis
                                  checked={
                                    intialData[
                                      item.email_notification_field
                                    ] === 1
                                      ? true
                                      : false
                                  }
                                  disabled={item.email_notification_disabled}
                                  onChange={() =>
                                    handleChange(
                                      item.email_notification_field,
                                      intialData[
                                        item.email_notification_field
                                      ] === 1
                                        ? 0
                                        : 1
                                    )
                                  }
                                  label={
                                    intialData[
                                      item.email_notification_field
                                    ] === 1
                                      ? "Yes"
                                      : "No"
                                  }
                                />
                              </FormGroup>
                            ) : null
                          ) : (
                            <FormGroup
                              switch
                              inline
                              className="form-check-info"
                            >
                              <Input
                                type="switch"
                                dis
                                checked={false}
                                disabled={true}
                              />
                            </FormGroup>
                          )}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            )}
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

export default Notification;
