import {
    GET_QUESTIONNAIRE_QUESTIONS_FAIL,
    GET_QUESTIONNAIRE_QUESTIONS_SUCCESS,
    GET_QUESTIONNAIRE_QUESTIONS_DETAIL_FAIL,
    GET_QUESTIONNAIRE_QUESTIONS_DETAIL_SUCCESS,
    ADD_NEW_QUESTIONNAIRE_QUESTIONS,
    ADD_QUESTIONNAIRE_QUESTIONS_SUCCESS,
    ADD_QUESTIONNAIRE_QUESTIONS_FAIL,
    UPDATE_QUESTIONNAIRE_QUESTIONS,
    UPDATE_QUESTIONNAIRE_QUESTIONS_SUCCESS,
    UPDATE_QUESTIONNAIRE_QUESTIONS_FAIL,
    DELETE_QUESTIONNAIRE_QUESTIONS_SUCCESS,
    DELETE_QUESTIONNAIRE_QUESTIONS_FAIL,
    DELETE_QUESTIONNAIRE_QUESTIONS,
    GET_QUESTIONNAIRE_QUESTIONS,
    GET_QUESTIONS_LIST,
    GET_QUESTIONS_LIST_SUCCESS,
    GET_QUESTIONS_LIST_FAIL,
    GET_QUESTION,
    GET_QUESTION_SUCCESS,
    GET_QUESTION_ERROR,
} from "./actionTypes"

const INIT_STATE = {
    GET_QUESTIONS_LIST: [],
    GET_QUESTIONS_PREVIEW: [],
    GET_QUESTION_SUCCESS :[],
    GET_QUESTION_ERROR :[],
    error: "",
    success: "",
    isModel: false,
    loading: false,
}

const QuestionnaireStatus = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GET_QUESTIONNAIRE_QUESTIONS:
            return {
                ...state,
                success: "",
                error: "",
                loading: true,
            }
        case GET_QUESTIONNAIRE_QUESTIONS_SUCCESS:
            return {
                ...state,
                GET_QUESTIONS_LIST: action.payload,
                // success: action.payload.message,
                loading: false,
            }

        case GET_QUESTIONNAIRE_QUESTIONS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }

        case GET_QUESTIONS_LIST:
            return {
                ...state,
                // success: "",
                //error: "",
                loading: true,
            }
        case GET_QUESTIONS_LIST_SUCCESS:
            return {
                ...state,
                GET_QUESTIONS_PREVIEW: action.payload,
                // success: action.payload.message,
                loading: false,
            }

        case GET_QUESTIONS_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }

        case ADD_NEW_QUESTIONNAIRE_QUESTIONS:
            return {
                ...state,
                success: "",
                error: "",
                loading: true,
            }
        case ADD_QUESTIONNAIRE_QUESTIONS_SUCCESS:
            return {
                ...state,
                // data:action.payload,
                success: action.payload.message,
                loading: false,
                isModel: false
            }

        case ADD_QUESTIONNAIRE_QUESTIONS_FAIL:
            return {
                ...state,
                error: action.payload.message,
                isModel: true,
                loading: false,
            }

        case GET_QUESTIONNAIRE_QUESTIONS_DETAIL_SUCCESS:
            return {
                ...state,
                statusDetail: action.payload,
                loading: false,
            }

        case UPDATE_QUESTIONNAIRE_QUESTIONS:
            return {
                ...state,
                success: "",
                error: "",
                loading: true,
            }

        case UPDATE_QUESTIONNAIRE_QUESTIONS_SUCCESS:
            return {
                ...state,
                // status: [...state.status, action.payload.data],
                success: action.payload.message,
                loading: false,
                isModel: false
            }

        case UPDATE_QUESTIONNAIRE_QUESTIONS_FAIL:
            return {
                ...state,
                error: action.payload.message,
                isModel: true,
                loading: false,
            }
        case DELETE_QUESTIONNAIRE_QUESTIONS:
            return {
                ...state,
                success: '',
                error: '',
                loading: true,
            }

        case DELETE_QUESTIONNAIRE_QUESTIONS_SUCCESS:
            return {
                ...state,
                success: action.payload.data.message,
                loading: false,
            }

        case DELETE_QUESTIONNAIRE_QUESTIONS_FAIL:
            return {
                ...state,
                error: action.payload.message,
                loading: false,
            }


        case GET_QUESTIONNAIRE_QUESTIONS_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }


        case GET_QUESTION:
            return  {
                ...state,
                loading: true,
            }

        case GET_QUESTION_SUCCESS:
            return {
                ...state,
                GET_QUESTION_SUCCESS: action.payload,
                loading: false,
            }

        case GET_QUESTION_ERROR:
            return {
                ...state,
                GET_QUESTION_ERROR: action.payload,
                loading: false
            }
        default:
            return state
    }
}

export default QuestionnaireStatus
