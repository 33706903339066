import { takeEvery, call, put } from "redux-saga/effects";
import commonService from "../../services/common.service";
import { AnyAction } from "redux";

import {
    GET_ALERTS
} from "./actionTypes";

import {
    getAlertFail,
    getAlerts, getAlertsSuccess,

} from "./actions";
import alertService from "../../services/alert.service";

function* getAlertsList({ payload: { query, history } }: AnyAction): any {
    try {
        const response = yield call(alertService.getUserAlertList, { ...query })
        if (response && response.data && response.data.status === 'success') {
            yield put(getAlertsSuccess(response.data))
        } else if (response && response.status === 'error') {
            yield put(getAlertFail(response))
        } else {
            yield put(getAlertFail(response))
        }
    } catch (error) {
        yield put(getAlertFail(error))
    }
}


function* AlertSaga(): any {
    yield takeEvery(GET_ALERTS, getAlertsList);
}

export default AlertSaga;
