import { takeEvery, call, put } from "redux-saga/effects";
import taskStatusService from "../../../services/masters.task.status.service";
import { AnyAction } from "redux";

// TaskStatus Redux States
import {
  GET_TASK_STATUS,
  GET_TASK_STATUS_LIST,
  CREATE_TASK_STATUS,
  UPDATE_TASK_STATUS,
  DELETE_TASK_STATUS,
} from "./actionTypes";

import { 
    getTaskStatusSuccess, 
    getTaskStatusError,
    
    getTaskStatusListSuccess,
    getTaskStatusListError,

    createTaskStatusSuccess,
    createTaskStatusError,

    updateTaskStatusSuccess,
    updateTaskStatusError,

    deleteTaskStatusSuccess,
    deleteTaskStatusError,
} from "./actions";

function* getTaskStatus({ payload: { tasktypes, history } }: AnyAction): any {
  try {
    const response = yield call(taskStatusService.getTaskStatus, tasktypes);
    if (response.data && response.data.status === "success") {
      yield put(getTaskStatusSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getTaskStatusError(response.data));
    } else {
      yield put(getTaskStatusError(response));
    }
  } catch (error: any) {
    yield put(getTaskStatusError(error));
  }
}

function* getTaskStatusList({ payload: { query, history } }: AnyAction): any {
  try {
    const response = yield call(taskStatusService.getTaskStatusList, query);
    if (response.data && response.data.status === "success") {
      yield put(getTaskStatusListSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getTaskStatusListError(response.data));
    } else {
      yield put(getTaskStatusListError(response));
    }
  } catch (error: any) {
    yield put(getTaskStatusListError(error));
  }
}

function* createTaskStatus({ payload: { tasktypes, history } }: AnyAction): any {
  try {
    const response = yield call(taskStatusService.createTaskStatus, tasktypes);
    if (response.data && response.data.status === "success") {
      yield put(createTaskStatusSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(createTaskStatusError(response.data));
    } else {
      yield put(createTaskStatusError(response));
    }
  } catch (error: any) {
    yield put(createTaskStatusError(error));
  }
}

function* updateTaskStatus({ payload: { tasktypes, history } }: AnyAction): any {
  try {
    const response = yield call(taskStatusService.updateTaskStatus, tasktypes);
    if (response.data && response.data.status === "success") {
      yield put(updateTaskStatusSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(updateTaskStatusError(response.data));
    } else {
      yield put(updateTaskStatusError(response));
    }
  } catch (error: any) {
    yield put(updateTaskStatusError(error));
  }
}

function* deleteTaskStatus({ payload: { tasktypes, history } }: AnyAction): any {
  try {
    const response = yield call(taskStatusService.deleteTaskStatus, tasktypes);
    if (response.data && response.data.status === "success") {
      yield put(deleteTaskStatusSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(deleteTaskStatusError(response.data));
    } else {
      yield put(deleteTaskStatusError(response));
    }
  } catch (error: any) {
    yield put(deleteTaskStatusError(error));
  }
}

function* taskStatusSaga(): any {
  yield takeEvery(GET_TASK_STATUS, getTaskStatus);
  yield takeEvery(GET_TASK_STATUS_LIST, getTaskStatusList);
  yield takeEvery(CREATE_TASK_STATUS, createTaskStatus);
  yield takeEvery(UPDATE_TASK_STATUS, updateTaskStatus);
  yield takeEvery(DELETE_TASK_STATUS, deleteTaskStatus);
}

export default taskStatusSaga;
