import { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Label, Row } from "reactstrap";
import Select from "react-select";
import commonService from "../../../services/common.service";
import { addIndividualInCompany } from "../../../helpers/functions_helper";
// Actions
import { CommonAction } from "../../../store/actions";
import { useTranslation } from "react-i18next";

type FiltersProps = {
  filters: any;
  onApply: any;
  onClear: any;
  onChange?: any;
};

const Filters: FC<FiltersProps> = ({ filters, onApply, onClear, onChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [query, setQuery] = useState("");
  const [loadingCompanyOptions, setLoadingCompanyOptions] = useState(true);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyID, setCompanyID] = useState<any>(filters?.company_id);
  const [disabledCompanyUserOption, setDisabledCompanyUserOption] =
    useState(true);
  const [loadingCompanyUserOption, setloadingCompanyUserOption] =
    useState(false);
  const [usersOptions, setUsersOptions] = useState([]);

  let { getCompanyOptionsList, loading } = useSelector((state: any) => ({
    getCompanyOptionsList: state.Common.GET_COMPANY_OPTIONS_LIST_SUCCESS,
    loading: state.Common.loading,
  }));

  // Select options list API call -------------------------------------------------
  const getDependentSelectOptions = async (
    tableName: string,
    fieldName: string,
    whereField: string,
    whereID: number
  ) => {
    const response = await commonService.getDependentSelectOptionsList(
      tableName,
      fieldName,
      whereField,
      whereID,
      query ? query : ""
    );
    return response.data.data;
  };

  // Get options list -------------------------------------------------
  useEffect(() => {
    if (!getCompanyOptionsList) dispatch(CommonAction.getCompanyOptionsList());
  }, []);

  useEffect(() => {
    if (getCompanyOptionsList.data) {
      const item = addIndividualInCompany(getCompanyOptionsList.data) as any;
      setCompanyOptions(item);
      setLoadingCompanyOptions(false);
    }
  }, [getCompanyOptionsList]);

  useEffect(() => {
    if (companyID) {
      setUsersOptions([]);
      onChange({ user_id: "" });
      setDisabledCompanyUserOption(true);
      setloadingCompanyUserOption(true);

      getDependentSelectOptions(
        "tbl_master_users",
        "first_name",
        "company",
        companyID
      ).then(function (options) {
        setUsersOptions(options);
        setDisabledCompanyUserOption(false);
        setloadingCompanyUserOption(false);
      });
    }
  }, [companyID]);

  return (
    <Container>
      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label">{t("Client")}</Label>
            <Select
              placeholder={t("Select...")}
              options={companyOptions}
              value={
                filters?.company_id
                  ? companyOptions.find(
                      (option: any) => option.value == filters?.company_id
                    )
                  : ""
              }
              isSearchable={true}
              isDisabled={loadingCompanyOptions}
              isLoading={loadingCompanyOptions}
              onChange={(option: any) => {
                setCompanyID(option.value);
                onChange({ is_filtered: true, company_id: option.value });
              }}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label">{t("User")}</Label>
            <Select
              placeholder={t("Select...")}
              options={usersOptions}
              value={
                filters?.user_id
                  ? usersOptions.find(
                      (option: any) => option.value == filters?.user_id
                    )
                  : ""
              }
              isSearchable={true}
              isDisabled={disabledCompanyUserOption}
              isLoading={loadingCompanyUserOption}
              onChange={(option: any) => {
                onChange({ is_filtered: true, user_id: option.value });
              }}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Filters;
