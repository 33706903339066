import React from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { memo, useState } from "react";
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { MaterialIcon } from "./styles";
import { getUserProfile } from "../../../helpers/functions_helper";
import { useTranslation } from "react-i18next";
const EmailButton = (props: any) => {
    const userObj = getUserProfile('email');
    const { is_header_bar, is_menu, handlerCompose, handelInvokefrom } = props;
    const [menu, setMenu] = useState(false);
    const isAzureAuthenticated = useIsAuthenticated();
    const {t} = useTranslation();
    if (is_menu && isAzureAuthenticated && userObj.CAN_CREATE)
        return (
            <React.Fragment>
                <Dropdown
                    isOpen={menu}
                    toggle={() => setMenu(!menu)}
                    className="d-inline-block"
                >
                    <DropdownToggle
                        className="btn btn-primary btn-sm ml-1"
                        id="page-header-user-dropdown"
                        tag="button"
                    >
                        <span className="align-middle material-symbols-outlined fw-light fs-4">mail</span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem tag="a" onClick={() => handelInvokefrom()} className="d-flex lh-lg">
                            {" "}
                            <span className="material-symbols-outlined fw-light">
                                forward
                            </span>
                            {t("Invoke from")}{" "}
                        </DropdownItem>
                        <DropdownItem tag="a" onClick={() => handlerCompose()}>
                            <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">drafts</span>
                            {t("New Email")}
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        )

    if (is_header_bar && isAzureAuthenticated && userObj.CAN_CREATE)
        return <button
            type="button"
            onClick={() => handlerCompose()}
            className="btn header-item noti-icon"
            data-toggle="new email"
            title={t("New Email")}
        >
            <span className="align-middle material-symbols-outlined fw-light">
                email
            </span>
        </button>

    return (isAzureAuthenticated && userObj.CAN_CREATE) ? <Button
        className="btn btn-primary btn-sm ms-2 mb-1"
        color={"primary"}
        onClick={() => handlerCompose()}
    >
        <MaterialIcon color="#fff">email</MaterialIcon>
    </Button> : null
}

export default memo(EmailButton);