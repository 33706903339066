import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
  Alert,
} from "reactstrap";

// Actions
import { TaskTypeAction } from "../../../store/actions";

// Import Breadcrumb
import Breadcrumbs from "../../../components/Layout/Breadcrumb";

// Import DeleteModal
import TaskTypeModal from "./TaskTypeForm";

// Import DeleteModal
import DeleteModal from "../../../components/Common/DeleteModal";

// Import DataTable
import DataTable from "../../../components/Common/DataTable";

import { getDataTableQuery } from "../../../helpers/functions_helper";

const breadcrumbItems = [
  {
    url: "/dashboard",
    label: "Dashboard",
    active: false,
  },
  {
    url: "#",
    label: "Task Type",
    active: true,
  },
];

const AllTaskType = (props: any) => {
  document.title = "Task Type | " + process.env.REACT_APP_NAME;
  
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();

  let {
    datatableData,
    datatableErrorMsg,
    createTaskTypeSuccessMsg,
    createTaskTypeErrorMsg,
    createTaskTypeErrorData,
    updateTaskTypeSuccessMsg,
    updateTaskTypeErrorMsg,
    updateTaskTypeErrorData,
    deleteTaskTypeSuccessMsg,
    deleteTaskTypeErrorMsg,
    deleteTaskTypeErrorData,
    getTaskTypeErrorMsg,
    loading,
  } = useSelector((state: any) => ({
    datatableData: state.TaskType.GET_TASK_TYPES_LIST_SUCCESS,
    datatableErrorMsg: state.TaskType.GET_TASK_TYPES_LIST_ERROR.message,

    createTaskTypeSuccessMsg:
      state.TaskType.CREATE_TASK_TYPE_SUCCESS.message,
    createTaskTypeErrorMsg:
      state.TaskType.CREATE_TASK_TYPE_ERROR.message,
    createTaskTypeErrorData:
      state.TaskType.CREATE_TASK_TYPE_ERROR.data,

    updateTaskTypeSuccessMsg:
      state.TaskType.UPDATE_TASK_TYPE_SUCCESS.message,
    updateTaskTypeErrorMsg:
      state.TaskType.UPDATE_TASK_TYPE_ERROR.message,
    updateTaskTypeErrorData:
      state.TaskType.UPDATE_TASK_TYPE_ERROR.data,

    deleteTaskTypeSuccessMsg:
      state.TaskType.DELETE_TASK_TYPE_SUCCESS.message,
    deleteTaskTypeErrorMsg:
      state.TaskType.DELETE_TASK_TYPE_ERROR.message,
    deleteTaskTypeErrorData:
      state.TaskType.DELETE_TASK_TYPE_ERROR.data,

    getTaskTypeErrorMsg: state.TaskType.GET_TASK_TYPE_ERROR.message,

    loading: state.TaskType.loading,
  }));

  // DataTable ----------------------------------------------
  function renderHeaderHTML(column: any, colIndex: any) {
    return <span dangerouslySetInnerHTML={{ __html: column.text }} />;
  }
  function renderRowHTML(rowContent: any, row: any) {
    return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
  }

  const columns = [
    {
      dataField: "DT_RowIndex",
      text: "Sr. No.",
      sort: false,
      search: false,
    },
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: true,
    //   search: true,
    // },
    {
      dataField: "task_type_value_html",
      text: "Task type",
      sort: true,
      search: true,
      formatter: renderRowHTML,
    },    
    {
      dataField: "action",
      text: "Action",
      sort: false,
      search: false,
      formatter: (rowContent: any, row: any): any => {
        return (
          <div className="d-flex gap-2 text-end">
            <Link 
              className="btn btn-pill btn-outline-primary btn-sm" 
              to="#"
              onClick={() => onClickEdit(row)}
            >
              <i className="fa-regular fa-pen-to-square"></i> Edit
            </Link>
            <Link
              className="btn btn-pill btn-outline-danger btn-sm"
              to="#"
              onClick={() => onClickDelete(row)}
            >
              <i className="fa-regular fa-trash-can"></i> Delete
            </Link>
          </div>
        );
      },
    },
  ];

  const [loadingListData, setLoadingListData] = useState(true);
  const [datatableOptions, setDatatableOptions] = useState({
    draw: 1,
    page: 1,
    recordsTotal: 0,
    recordsFiltered: 0,
    sizePerPage: 20,
    searchText: "",
    sortField: "id",
    sortOrder: "desc",
  });

  useEffect(() => {
    if (loadingListData === true) {
      dispatch(
        TaskTypeAction.getTaskTypesList(
          getDataTableQuery(datatableOptions, columns),
          props.history
        )
      );
    }
  }, [loadingListData]);

  const [taskTypeListData, setTaskTypeListData] = useState([]);
  useEffect(() => {
    if (datatableData) {
      setTaskTypeListData(datatableData.data);

      const updatedDatatableOptions = {
        ...datatableOptions,
        recordsTotal: datatableData.recordsTotal,
        recordsFiltered: datatableData.recordsFiltered,
      };
      setDatatableOptions(updatedDatatableOptions);

      setLoadingListData(false);
      setLoader(false);
    }
  }, [datatableData]);

  const onTableChange = (type: any, newState: any) => {
    let draw = datatableOptions.draw + 1;
    let page = 1;
    if (type == "search") {
      page = 1;
    } else {
      page = newState.page;
    }
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: draw,
      page: page,
      sizePerPage: newState.sizePerPage,
      searchText: newState.searchText,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
    };
    setDatatableOptions(updatedDatatableOptions);
    setLoadingListData(true);
  };

  const clearFilter = () => {
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: 1,
      page: 1,
      sizePerPage: 20,
      searchText: "",
      sortField: "id",
      sortOrder: "desc",
    };
    setDatatableOptions(updatedDatatableOptions);
    setLoadingListData(true);
  };
  // Datatable -------------------------------------------------

  // Delete Form --------------------------------------------------------
  const [deleteTaskType, setDeleteTaskType] = useState({ 
    id: "", 
    task_type_id: "" 
  });
  const [recordName, setRecordName] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (row: any) => {
    const deleteTaskTypeData = {
      ...deleteTaskType,
      id: row.id,
      task_type_id: row.task_type_id,
    };
    setDeleteTaskType(deleteTaskTypeData);
    setRecordName(row.task_type_value);
    setDeleteModal(true);
  };
  
  const handleDeleteTaskType = () => {
    dispatch(TaskTypeAction.deleteTaskType(deleteTaskType, props.history));
  };
  // Delete Form --------------------------------------------------------

  // Master Form -------------------------------------------------------- 
  const [taskTypeData, setTaskTypeData] = useState({}); 

  const [masterFormModal, setMasterFormModal] = useState(false);
  const [masterFormModalTitle, setMasterFormModalTitle] = useState("Create task type");

  const onClickModal = () => {
    setTaskTypeData('');
    setMasterFormModalTitle("Create task type");
    setMasterFormModal(true);
  };
  
  const onClickEdit = (row: any) => {
    setTaskTypeData(row);
    setMasterFormModalTitle("Update task type");
    setMasterFormModal(true);
  }

  const handleSubmitTaskType = (values: any) => {
    if(values.id && values.task_type_id){
      dispatch(TaskTypeAction.updateTaskType(values, props.history));
    }else{
      dispatch(TaskTypeAction.createTaskType(values, props.history));
    }
  };  
  // Master Form --------------------------------------------------------

  // Messages -------------------------------------------------
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorData, setErrorData] = useState({data: {}});

  useEffect(() => {
    if (createTaskTypeSuccessMsg) {
      setSuccessMsg(createTaskTypeSuccessMsg);
      setLoadingListData(true);
      window.scrollTo(0, 0);
      setErrorMsg("");
      setErrorData({data: {}});
      dispatch(TaskTypeAction.createTaskTypeSuccess(""));
      setMasterFormModal(false);
    }
  }, [createTaskTypeSuccessMsg]);

  useEffect(() => {
    if (createTaskTypeErrorMsg) {
      setErrorMsg(createTaskTypeErrorMsg);
      setErrorData({data: createTaskTypeErrorData});
      window.scrollTo(0, 0);
      setSuccessMsg("");
      dispatch(TaskTypeAction.createTaskTypeError(""));
    }
  }, [createTaskTypeErrorMsg]);

  useEffect(() => {
    if (updateTaskTypeSuccessMsg) {
      setSuccessMsg(updateTaskTypeSuccessMsg);
      setLoadingListData(true);
      window.scrollTo(0, 0);
      setErrorMsg("");
      setErrorData({data: {}});
      dispatch(TaskTypeAction.updateTaskTypeSuccess(""));
      setMasterFormModal(false);
    }
  }, [updateTaskTypeSuccessMsg]);

  useEffect(() => {
    if (updateTaskTypeErrorMsg) {
      setErrorMsg(updateTaskTypeErrorMsg);
      setErrorData({data: updateTaskTypeErrorData});
      window.scrollTo(0, 0);
      setSuccessMsg("");
      dispatch(TaskTypeAction.updateTaskTypeError(""));
    }
  }, [updateTaskTypeErrorMsg]);

  useEffect(() => {
    if (deleteTaskTypeSuccessMsg) {
      setSuccessMsg(deleteTaskTypeSuccessMsg);
      setLoadingListData(true);
      window.scrollTo(0, 0);
      setErrorMsg("");
      setErrorData({data: {}});
      dispatch(TaskTypeAction.deleteTaskTypeSuccess(""));
      setDeleteModal(false);
    }
  }, [deleteTaskTypeSuccessMsg]);

  useEffect(() => {
    if (deleteTaskTypeErrorMsg) {
      setErrorMsg(deleteTaskTypeErrorMsg);
      setErrorData({data: deleteTaskTypeErrorData});
      window.scrollTo(0, 0);
      setSuccessMsg("");
      dispatch(TaskTypeAction.deleteTaskTypeError(""));
      setDeleteModal(false);
    }
  }, [deleteTaskTypeErrorMsg]);

  useEffect(() => {
    if (getTaskTypeErrorMsg) {
      setErrorMsg(getTaskTypeErrorMsg);
      window.scrollTo(0, 0);
      setSuccessMsg("");
      dispatch(TaskTypeAction.getTaskTypeError(""));
    }
  }, [getTaskTypeErrorMsg]);
  // Messages -------------------------------------------------

  return (
    <React.Fragment>

      {deleteTaskType && (
        <DeleteModal
          recordName={recordName}
          modalTitle="task type"
          show={deleteModal}
          onDeleteClick={handleDeleteTaskType}
          onCloseClick={() => setDeleteModal(false)}
          loading={loading}
        />
      )}

      {masterFormModal && (
        <TaskTypeModal
          modalTitle={masterFormModalTitle}
          show={masterFormModal}
          loading={loading}
          taskTypeData={taskTypeData}
          onSubmitClick={handleSubmitTaskType}
          onCloseClick={() => setMasterFormModal(false)}
          errorMsg={errorMsg}
          errorData={errorData}
        />
      )}

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle="Task type"
            breadcrumbItems={breadcrumbItems}
          />

          <Row>
            <Col xl="12">
              {successMsg ? <Alert color="success">{successMsg}</Alert> : null}
              {errorMsg ? <Alert color="danger">{errorMsg}</Alert> : null}

              <Card>
                <CardHeader className="bg-transparent border-bottom">
                  <div className="d-sm-flex flex-wrap">
                    <CardTitle className="card-title text-uppercase">
                      Task type list
                    </CardTitle>
                    <div className="ms-auto d-flex gap-2">
                      <Link
                        to="#"
                        className="btn btn-primary btn-rounded waves-effect waves-light btn-label"
                        onClick={() => onClickModal()}
                      >
                        <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">add</span> Create task type
                      </Link>
                      {/* <Button
                        color="primary"
                        className="btn-rounded waves-effect waves-light"
                      >
                        <i className="fa-solid fa-arrows-rotate"></i>
                      </Button> */}
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <DataTable
                    columns={columns}
                    products={taskTypeListData}
                    datatableOptions={datatableOptions}
                    filters={[]}
                    onTableChange={onTableChange}
                    loadingListData={loadingListData}
                    clearFilter={clearFilter}
                    slug="masters"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AllTaskType;
