import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Container, Row, Col, Card, CardBody, Button, Input, Label, FormGroup } from "reactstrap";

import EmailService from "../../../services/email.service";

// Import Breadcrumb
import Breadcrumbs from "../../../components/Layout/Breadcrumb";
// Import DeleteModal
import DeleteModal from "../../../components/Common/DeleteModal";
// Import DataTable
import DataTable from "../../../components/Common/DataTable";
import { getDataTableQuery, handleFilters, getUserProfile } from "../../../helpers/functions_helper";
import Drawer from "../../../components/Drawer";
import ViewTemplate from "./components/ViewTemplate";
import { useTranslation } from "react-i18next";




const AllEmailTemplate = (props: any) => {
  const {t}=useTranslation();
  document.title = t("Email Template List") +"| " + process.env.REACT_APP_NAME;
  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "#",
      label: t("Email Template List"),
      active: true,
    },
  ];
  const userProfile = getUserProfile('email-template')
  const savedfilters = handleFilters("GET");
  const toastId = useRef(null) as any;

  const [loading, setLoading] = useState(true);
  const [loadingListData, setLoadingListData] = useState(true);
  const [emailTemplateListData, setEmailTemplateListData] = useState([]);
  const [deleteEmailTemplate, setDeleteEmailTemplate] = useState({
    id: "",
    template_id: "",
  });
  const [recordName, setRecordName] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [openTemplateViewer, setOpenTemplateViewer] = useState(false);
  const [templateID, setTemplateID] = useState<number>(0);

  // DataTable ----------------------------------------------
  function renderHeaderHTML(column: any, colIndex: any) {
    return <span dangerouslySetInnerHTML={{ __html: column.text }} />;
  }
  function renderRowHTML(rowContent: any, row: any) {
    return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
  }

  function viewTemplate(data: any) {
    setOpenTemplateViewer(true);
    setTemplateID(data.id);
  }

  const attachmentRequiredUpdate = async (attachment: any, data: any) => {
    const postData = {
      attachment,
      id: data.id,
      template_id: data.template_id
    }
    toastId.current = toast(`${t("Updating")}...`, {
      autoClose: false,
      isLoading: true,
    });
    const res = await EmailService.updateEmailTemplateAttachmentStatus(postData);
    if (res.status === 200 && res.data.status === "success") {
      toast.update(toastId.current, {
        type: toast.TYPE.SUCCESS,
        render: t("Email template update successfully"),
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoadingListData(true);
      setDeleteModal(false);
      setLoading(false);
    } else {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: "Error",
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoadingListData(true);
      setDeleteModal(false);
      setLoading(false);
    }
  }

  const columns = [
    {
      dataField: "DT_RowIndex",
      text: t("Sr. No."),
      sort: false,
      search: false,
      classes: "fix-col-left",
      headerClasses: "fix-col-left",
      headerStyle: { width: "155px" },
      style: { width: "155px" },
    },
    {
      dataField: "attachment",
      text: t("Attachment"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return <Input type="switch" checked={row.attachment || false} onChange={(e) => attachmentRequiredUpdate(e.target.checked, row)} />;
      }
    },
    {
      dataField: "title",
      text: t("Name"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return <Link className="text-decoration-underline" to={`/masters/email-template/edit/${row.id}/${row.template_id}`}>{row.title}</Link>;
      }
    },
    {
      dataField: "module_value",
      text: t("Module"),
      headerFormatter: renderHeaderHTML,
      sort: true,
      search: true,
      formatter: renderRowHTML,
    },
    {
      dataField: "email_template_category_value",
      text: t("Category"),
      headerFormatter: renderHeaderHTML,
      sort: true,
      search: true,
      formatter: renderRowHTML,
    },
    {
      dataField: "user_type_value",
      text: t("Applies to"),
      headerFormatter: renderHeaderHTML,
      sort: true,
      search: true,
      formatter: renderRowHTML,
    },
    // {
    //   dataField: "from",
    //   text: "From",
    //   headerFormatter: renderHeaderHTML,
    //   sort: true,
    //   search: true,
    // },
    // {
    //   dataField: "to",
    //   text: "To",
    //   headerFormatter: renderHeaderHTML,
    //   sort: true,
    //   formatter: (rowContent: any, row: any): any => {
    //     return (
    //       <>
    //         {row.to && row.to.map((item: any) => <a className="badge bg-primary bg-soft text-primary font-size-11 me-1" href="#">{item.value}</a>)}
    //       </>
    //     )
    //   }
    // },
    {
      dataField: "action",
      text: t("Action"),
      sort: false,
      search: false,
      formatter: (rowContent: any, row: any): any => {
        return (
          <div className="d-flex gap-2 text-end">
            {userProfile.CAN_VIEW ? <Link
              className="btn btn-pill btn-outline-primary btn-sm"
              to="#"
              onClick={() => viewTemplate(row)}>
              <span className="align-middle material-symbols-outlined fw-light fs-4">
                preview
              </span>
            </Link> : null}
            {userProfile.USER_FUNCTION === 6 ? <Link
              className="btn btn-pill btn-outline-primary btn-sm"
              to="#"
              onClick={() => handleDuplicateEmailTemplate(row)}>
              <i className="fa-regular fa-copy"></i> {t("Duplicate")}
            </Link> : null}
            {userProfile.USER_FUNCTION === 6  ? <Link
              className="btn btn-pill btn-outline-primary btn-sm"
              to={`/masters/email-template/edit/${row.id}/${row.template_id}`}
            >
              <i className="fa-regular fa-pen-to-square"></i> {t("Edit")}
            </Link> : null}
            {/* <Link
              className="btn btn-pill btn-outline-danger btn-sm"
              to="#"
              onClick={() => onClickDelete(row)}>
              <i className="fa-regular fa-trash-can"></i> Delete
            </Link> */}
          </div>
        );
      },
    },
  ];

  const [datatableOptions, setDatatableOptions] = useState(
    savedfilters && savedfilters?.datatableOptions
      ? (savedfilters?.datatableOptions as any)
      : {
        draw: 1,
        page: 1,
        recordsTotal: 0,
        recordsFiltered: 0,
        sizePerPage: 20,
        searchText: "",
        sortField: "id",
        sortOrder: "desc",
      }
  );

  const onTableChange = (type: any, newState: any) => {
    let draw = datatableOptions.draw + 1;
    let page = 1;
    if (type == "search") {
      page = 1;
    } else {
      page = newState.page;
    }
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: draw,
      page: page,
      sizePerPage: newState.sizePerPage,
      searchText: newState.searchText,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
    };
    setDatatableOptions(updatedDatatableOptions);
    setLoadingListData(true);
  };

  const clearFilter = () => {
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: 1,
      page: 1,
      sizePerPage: 20,
      searchText: "",
      sortField: "id",
      sortOrder: "desc",
    };
    setDatatableOptions(updatedDatatableOptions);
    setLoadingListData(true);
  };

  const getEmailTemplateListData = async () => {
    const res = await EmailService.getEmailTemplateList(getDataTableQuery(datatableOptions, columns));
    if (res.data.status === "success") {
      setEmailTemplateListData(res.data.data);
      const updatedDatatableOptions = {
        ...datatableOptions,
        recordsTotal: res.data.recordsTotal,
        recordsFiltered: res.data.recordsFiltered,
      };
      setDatatableOptions(updatedDatatableOptions);
      setLoadingListData(false);
      setLoading(false);
    } else {
      setEmailTemplateListData([]);
      setLoadingListData(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loadingListData === true) {
      getEmailTemplateListData();
    }
  }, [loadingListData]);
  // Datatable -------------------------------------------------

  // Delete Form --------------------------------------------------------
  const onClickDelete = (row: any) => {
    console.log(row);
    const deleteEmailTemplateR = {
      ...deleteEmailTemplate,
      id: row.id,
      template_id: row.template_id,
    };
    setDeleteEmailTemplate(deleteEmailTemplateR);
    setRecordName(row.title);
    setDeleteModal(true);
  };

  const handleDeleteEmailTemplate = async () => {
    setLoading(true);
    toastId.current = toast("Deleting email template...", {
      autoClose: false,
      isLoading: true,
    });
    try {
      const res = (await EmailService.deleteEmailTemplate(deleteEmailTemplate)) as any;
      if (res.data.status === "success") {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: t("Email template deleted successfully"),
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoadingListData(true);
        setDeleteModal(false);
        setLoading(false);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: t("Something went wrong."),
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setDeleteModal(false);
        setLoading(false);
      }
    } catch (err) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: t("Something went wrong."),
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setDeleteModal(false);
      setLoading(false);
    }
  };
  // Delete Form --------------------------------------------------------

  // Master Form --------------------------------------------------------
  const handleDuplicateEmailTemplate = async (data: any) => {
    setLoading(true);
    toastId.current = toast(t("Creating duplicate email template")+"...", {
      autoClose: false,
      isLoading: true,
    });
    try {
      const res = (await EmailService.duplicateEmailTemplate(data)) as any;
      if (res.data.status === "success") {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: t("Duplicate email template created successfully"),
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoadingListData(true);
        setLoading(false);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: t("Something went wrong."),
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setDeleteModal(false);
        setLoading(false);
      }
    } catch (err) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: t("Something went wrong."),
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setDeleteModal(false);
      setLoading(false);
    }
  };
  // duplicate Form --------------------------------------------------------

  useEffect(() => {
    setEmailTemplateListData([]);
    setLoadingListData(true);
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  return (
    <React.Fragment>
      {deleteEmailTemplate && (
        <DeleteModal
          recordName={recordName}
          modalTitle={t("Email Template")}
          show={deleteModal}
          onDeleteClick={handleDeleteEmailTemplate}
          onCloseClick={() => setDeleteModal(false)}
          loading={loading}
        />
      )}
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle=""
            breadcrumbItems={breadcrumbItems}
            endAdornment={
              userProfile.CAN_CREATE ?<Link
                to="../masters/email-template/new"
                className="btn btn-primary btn-sm waves-effect waves-light btn-label"
              >
                <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">add</span>{t("Create New Email Template")}
              </Link>:null
            }
          />

          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <DataTable
                    columns={columns}
                    products={emailTemplateListData}
                    datatableOptions={datatableOptions}
                    filters={[]}
                    onTableChange={onTableChange}
                    loadingListData={loadingListData}
                    clearFilter={clearFilter}
                    settingTableName="email_table"
                    slug="masters"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Drawer
          title={t("Template List")}
          open={openTemplateViewer}
          onClose={() => setOpenTemplateViewer((state) => !state)}
          footer={
            <Button
              color="primary"
              style={{ margin: "16px" }}
              onClick={() => setOpenTemplateViewer((state) => !state)}
            >
              Close
            </Button>
          }
        >
          <ViewTemplate
            id={templateID}
          />
        </Drawer>
      </div>
    </React.Fragment>
  );
};

export default AllEmailTemplate;
