import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GraphFilters from './Filters';
import { getUserProfile, select2Styles } from '../../../helpers/functions_helper';
import usersService from '../../../services/users.service';
import { serialize } from '../../../utils/utils';
import LoadingSpinner from '../../../components/Common/Loader';
import { noData } from '../../../utils/data';
import Select from "react-select";
import { currentMonth, currentYear, graphviewOptions } from '../../../common/staticdata';

import Chart from 'react-apexcharts';
import ChartFilters from '../../../components/Chart/Filters';
interface UserperQuarterProps extends CartProps {
    filters?: any;
}

const UserperQuarter: FC<UserperQuarterProps> = ({ hideHeading, filters = null }) => {

    const { t } = useTranslation();

    const userObj = getUserProfile();

    const inititalFilter = {
        week: null,
        quarter: null,
        month: null,
        year: currentYear,
        user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_ID : null,
        graphview: 'quarter',
    };

    const [loading, setLoading] = useState<boolean>(true);
    const [query, setQuery] = useState<string | null>(serialize(inititalFilter));
    const [filter, setFilter] = useState<any>(inititalFilter);
    const [multiYear, setMultiYear] = useState(false);
    const [graphOptions, setGraphOptions] = useState([]);

    useEffect(() => {
        if (graphviewOptions) {
            const graphOptions = graphviewOptions?.map((option: any) => ({
                label: t(option.label),
                value: option.value
            }));
            setGraphOptions(graphOptions);
        }
        clearWorkAllocationFilter();
    }, [localStorage.getItem("I18N_LANGUAGE")]);

    const [data, setData] = useState<any>({
        series: [],
        options: {
            chart: {
                height: 350,
                type: 'area',
                toolbar: {
                    show: false
                }
            },
            legend: {
                show: false,
                floating: false,
                fontSize: '16px',
                position: 'left',
                offsetX: 160,
                offsetY: 15,
                labels: {
                    useSeriesColors: true,
                },
            },
            noData: noData,
            dataLabels: {
                enabled: false
            },
            // stroke: {
            //     curve: 'smooth'
            // },
            xaxis: {
                type: 'string',
                //categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
            },
            yaxis: {
                // labels: {
                //     formatter: function (value: any) {
                //         return formatCurrency(value);
                //     }
                // },
            },
            tooltip: {
                // x: {
                //     format: 'dd/MM/yy HH:mm'
                // },
            },
        },
    })

    const getChartData = async () => {
        const res = await usersService.totalUserStatics(query);
        if (res?.data?.status === 'success') {
            setData((state: any) => ({
                ...state,
                series: res?.data?.data?.quarterAr,
                options: {
                    chart: {
                        toolbar: {
                            show: false
                        },
                    },
                    xaxis: {
                        type: 'string',
                        categories: res?.data?.data?.quarterx?.map((category: any) => t(category)) || [0],
                    },
                }
            }))
        }
        setLoading(false);
    }

    const clearWorkAllocationFilter = () => {
        setFilter(inititalFilter);
        setMultiYear(false);
    }

    useEffect(() => {
        loading && getChartData();
    }, [loading])

    const change_comma_string = (data: any) => {
        let ar: any = [];
        if (Array.isArray(data)) {
            (data.length > 1) ? setMultiYear(true) : setMultiYear(false);
            data.map((item: any) => {
                ar.push(item.value);
            })
        }
        return ar.join(',');
    }

    useEffect(() => {
        if (filter.year) {
            filter.year = change_comma_string(filter.year);
        }
        setQuery(serialize(filter));
        setLoading(true);
    }, [filter])

    useEffect(() => {
        filters && setFilter(filters)
    }, [filters])

    return (
        <>
            <div className="d-sm-flex flex-wrap">
                <h4 className="card-title mb-4">{!hideHeading && t("Total user statistics")}</h4>
                <div className="d-sm-flex ms-auto">
                    <Select
                        id="graphview"
                        name="graphview"
                        className="form-select-sm myClassName mx-1"
                        styles={select2Styles}
                        value={graphOptions?.find((i: any) => i.value === filter.graphview)}
                        options={graphOptions}
                        onChange={(option: any) => {
                            setFilter((state: any) => ({ ...state, graphview: option.value }))
                        }}
                        isDisabled={multiYear}
                    />
                    <ChartFilters
                        filter={filter}
                        setFilter={setFilter}
                        clearFilter={clearWorkAllocationFilter}
                        enableCompany={false}
                        enableUser={false}
                    // yearOnly={true}
                    // multiYear={multiYear}
                    />
                </div>
            </div>
            <div className="clearfix"></div>
            {loading ? <LoadingSpinner height={380} /> : <Chart options={data.options} series={data.series} type="area" height={380} />}
        </>
    )
}

export default UserperQuarter;