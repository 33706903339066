import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
    CardHeader,
    CardTitle,
    CardBody,
    Row,
    Col,
    Input,
    Label,
    Card,
    Table,
    FormGroup,
    Container,
} from "reactstrap";
import { getUserProfile } from "../../helpers/functions_helper";
import AccessRoleForm from "../Masters/AccessRole/Form";
import ScrollUp from "../../components/ScrollUpDown/ScrollUp";
import ScrollDown from "../../components/ScrollUpDown/ScrollDown";
import NavItems from "./NavItems";
import Breadcrumbs from "../../components/Layout/Breadcrumb";
import accessroleService from "../../services/accessrole.service";
import { toast } from "react-toastify";
import LoadingSpinner from "../../components/Common/Loader";

const AccessRights = (props: any) => {
    const {t} = useTranslation();
    document.title = `${t("Update user")} - (${t("Access Rights")}) | ${process.env.REACT_APP_NAME}`;
    const breadcrumbItems = [
        {
        url: "/dashboard",
        label: t("Dashboard"),
        active: false,
        },
        {
        url: "/users/all-users",
        label: t("Users"),
        active: false,
        },
        {
        url: "#",
        label: t("Update user"),
        active: true,
        },
    ];

    const userProfile = getUserProfile("all-users");
    const toastId = React.useRef(null) as any;
    let { id = '', user_id } = useParams();

    const [loadingList, setLoadingList] = useState<boolean>(true);
    const [formValues, setFormValues] = useState<any>({ name: '', applicable_for: null, vip_access: null, modules: [], widgets: [], isSubmitting: false });

    const getAccessRoles = async () => {
        const res = await accessroleService.getUserAccess(parseInt(id));
        if (res?.status === 'success') {
            setFormValues(res?.data);
            setLoadingList(false)
        }
    }

    const handleSubmit = async (values: any) => {
        if (values?.id) {
            toastId.current = toast(t("Updating access role..."), { autoClose: false, isLoading: true });
            setFormValues((state: any) => ({ ...state, isSubmitting: true }));
            const res = await accessroleService.updateUserAccess({ ...values, user_id: parseInt(id) });
            if (res?.status === 'success') {
                setLoadingList(true)
                setFormValues((state: any) => ({ ...state, isSubmitting: false }));
                toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: t('Access role updated successfully'), autoClose: 5000, isLoading: false, delay: 500 });
            }
        }

    }

    useEffect(() => {
        if (loadingList)
            getAccessRoles();
    }, [loadingList]);

    return (
        <React.Fragment>
            <ScrollUp />
            <ScrollDown />

            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        breadcrumbTitle={t("Update user")}
                        breadcrumbItems={breadcrumbItems}
                    />
                    <NavItems id={id} user_id={user_id} />
                    <Card className="border-top-0 rounded-0 rounded-bottom">
                        <CardBody>
                            <AccessRoleForm formValues={formValues} setFormValues={setFormValues} handleSubmit={handleSubmit} isView={false} isTab />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AccessRights;
