import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, CardBody, CardFooter, Col, Container, Modal, ModalBody, Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane } from 'reactstrap';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas'
import commonService from '../../services/common.service';
import moment from 'moment';
import { getUserProfile } from '../../helpers/functions_helper';
import Dropzone from 'react-dropzone';
import { useNavigate } from 'react-router-dom';

const AddSignature = ({
    moduleData,
    showlist = true,
    show = true, // hide button signature
    showAdd, // hide add button 
    isDisabled = false,  // disable the button
    hideSign = false, // disable sign
    handleSignatureData, // handle signa date
    className,
    signatureAdded= () =>{},
    getList= true,
}: any) => {
    
    let navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(1);
    const [disable, setDisable] = useState(true);
    const [signature, setSignature] = useState("Signature");
    const [activeSign, setActiveSign] = useState();
    const [signatureList, setSignatureList] = useState([]);
    const [signaturestyle, setSignatureStyle] = useState("sign_1");
    const [signData, setSignData] = useState<any>([]);
    const [sectionData, setSectionData] = useState<any>([]);
    const [cardset, setCardset] = useState(false);
    const [isSignEnable, setIsSignEnable] = useState(true);
    const [userSignData, setUserSignData] = useState<any>([]);
    const [intialSignData, setInitialSignData] = useState<any>([]);


    const toastId = React.useRef(null) as any;
    const { t } = useTranslation();
    const sigCanvas = useRef({}) as React.MutableRefObject<any>;
    const userProfile = getUserProfile();

    useEffect(() => {
        setDisable(true);
        setActiveTab(1);
        setSignature("Signature");
        getSignature();
        if (moduleData.module_app_id != 1) {
            getSignatureUser();
        }
    }, []);

    useEffect(() => {
        if (show) {
            setShowModal(true);
        }
    }, [show]);

    const clear = () => {
        setDisable(true);
        sigCanvas.current?.clear();
    };

    const formatIntoPng = async () => {
        if (sigCanvas.current) {
            const dataURL = sigCanvas.current.toDataURL();
            AddSignature(1, dataURL);
        }
    };

    // const saveImage = async (dataURL: any) => {
    //     const data_url = dataURL.replace("data:png;base64,", "data:image/png;base64,");
    //     const res = await questionnaireService.uploadSignature({ data: data_url });
    //     if (res.status === "success") {
    //         toastId.current = toast(res.message, {
    //             type: toast.TYPE.SUCCESS,
    //             autoClose: 5000,
    //             isLoading: false,
    //             delay: 500,
    //         });
    //         AddSignature(1, res.data);
    //     } else {
    //         toastId.current = toast(res.message, {
    //             type: toast.TYPE.ERROR,
    //             autoClose: 5000,
    //             isLoading: false,
    //             delay: 500,
    //         });
    //     }
    // }

    const handleChange = (e: any) => {
        if (e.target.value == "") {
            setSignature("Signature");
        } else {
            setSignature(e.target.value);
        }
    }


    const AddSignature = async (type: any, signature: any) => {
        const request = {
            id: signID,
            module_app_id: moduleData.module_app_id,
            module_main_id: moduleData.module_main_id,
            module_sub_id: moduleData.module_sub_id,
            section_id: moduleData.section_id,
            type: type,
            signature: signature
        };
        const res = await commonService.uploadSignature(request);
        if (res?.data?.status == "success") {
            signatureAdded(signature);
            getSignature();
            setShowModal(false);
            setCardset(false);
            if (moduleData?.module_main_user_id && moduleData?.module_sub_user_id && moduleData.module_app_id != "1") {
                uploadSIgnatureUser(type, signature);
            }
        }
    }


    const uploadSIgnatureUser = async (type: any, signature: any) => {
        const request = {
            module_app_id: 1,
            module_main_id: userProfile.USER_ID,
            module_sub_id: userProfile.USER_SUB_ID,
            section_id: 0,
            type: type,
            signature: signature
        };
        const res = await commonService.uploadSignature(request);
        if (res?.data?.status == "success") {
            signatureAdded(signature);
            //console.log(res?.data?.status);
        }
    }


    const getSignature = async () => {
        if(getList){
            const request = {
                module_app_id: moduleData.module_app_id,
                module_main_id: moduleData.module_main_id,
                module_sub_id: moduleData.module_sub_id,
                //section_id: moduleData.section_id,
            };
            const res = await commonService.getSignature(request);
            if (res?.data?.status == "success") {
                setSignatureList(res.data.data);
                if (res?.data?.data) {
                    if (res?.data?.data[0]) {
                        handleSignatureData(res?.data?.data[0]);
                    }
    
                    if (moduleData.section_id != 0) {
                        let data_singed_by_section = res?.data?.data.filter((dt: any) => dt.section_id == moduleData.section_id);
                        setSectionData(data_singed_by_section);
                    } else if (res?.data?.data[0]) {
                        let section_ar: any = [];
                        section_ar.push(res?.data?.data[0]);
                        setSectionData(section_ar);
                    }
                    let data_singed_by_user = res?.data?.data.filter((dt: any) => dt.created_by_id == userProfile.USER_ID);
                    setSignData(data_singed_by_user[0]);
                }
            }else if(res?.data?.status === 'error' &&res?.data?.data && res?.data?.data?.ERROR_CODE =="CAN_VIEW"){
                return navigate("/404");
            }else{
    
            }
        }
       
        setLoading(false);
    }


    const getSignatureUser = async () => {
        const request = {
            module_app_id: 1,
            module_main_id: userProfile.USER_ID,
            module_sub_id: userProfile.USER_SUB_ID,
        };
        const res = await commonService.getSignature(request);
        if (res?.data?.status == "success") {
            setUserSignData(res.data.data);
            setInitialSignData(res.data.initial_data);
        }
    }



    const handleUploadDocument = async (files: any) => {
        await files.map(async (file: any) => {
            const request = {
                id: signID,
                module_app_id: moduleData.module_app_id,
                module_main_id: moduleData.module_main_id,
                module_sub_id: moduleData.module_sub_id,
                section_id: moduleData.section_id,
                type: 2,
                signature: file.file_name_source
            };
            const res = await commonService.uploadSignature(request);
            if (res?.data?.status == "success") {
                signatureAdded(request.signature);
                setShowModal(false);
                getSignature();
            }
        });
    }

    function clearCanvas() {
        let cnv = document.getElementById("canvas") as HTMLCanvasElement | null;
        if (cnv) {

            var ctx = cnv?.getContext('2d');     // gets reference to canvas context
            ctx?.beginPath();    // clear existing drawing paths
            ctx?.save();         // store the current transformation matrix

            // Use the identity matrix while clearing the canvas
            ctx?.setTransform(1, 0, 0, 1, 0, 0);
            ctx?.clearRect(0, 0, cnv.width, cnv.height);

            ctx?.restore();        // restore the transform

        }
    }


    const handleSignText = () => {
        clearCanvas();
        if (signature == "Signature" || signature == "") {
            toastId.current = toast("Please type your signature", {
                type: toast.TYPE.ERROR,
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });
        } else {
            let canvas = document.getElementById("canvas") as HTMLCanvasElement | null;
            let context = canvas?.getContext("2d");
            if (context && canvas) {
                let width = context.measureText(signature).width;
                canvas.style.width = width + 'px';
                if (signaturestyle == "sign_1") {
                    context.font = "30px Brush Script MT";
                } else if (signaturestyle == "sign_2") {
                    context.font = "30px Copperplate, Papyrus, fantasy";
                }
                else if (signaturestyle == "sign_3") {
                    context.font = "30px Pacifico, cursive";
                }
                else if (signaturestyle == "sign_4") {
                    context.font = "30px Fredoka,fantasy, cursive";
                }
                //context.font = "30px Brush Script MT";
                context.fillStyle = "black";
                context.textAlign = "center";
                context.fillText(signature, canvas.width / 2, canvas.height / 2);
                AddSignature(3, context.canvas.toDataURL());
            }
        }
    }

    const [showModal, setShowModal] = useState<any>(show);
    const [signID, setSignId] = useState<any>("");
    const [loading, setLoading] = useState(true);
    const handleEditsign = (item: any) => {
        setShowModal(true);
        setSignId(item.id);
    }

    const handleAddsign = (item: any) => {
        setShowModal(true);
        setSignId("");
    }

    const SignatureCard = () => {
        if (userSignData[0]?.created_by_id) {
            setCardset(true);
        } else {
            setShowModal(true);
        }
    }

    useEffect(() => {
        if (signData?.created_by_id) {
            setIsSignEnable(false);
        } else {
            setIsSignEnable(true);
        }
    }, [signData])


    // useEffect(() => {
    //     if (userSignData?.created_by_id) {
    //         setCardset(true);
    //     } else {
    //         setCardset(false);
    //     }
    // }, [userSignData])

    return (
        <React.Fragment>
            {loading ?
                <div className='align-self-center text-left'>
                    <div className='text-primary'>{t("Please wait...")}</div>
                    <div><Spinner size="sm" color="primary" style={{ 'position': 'relative', 'right': '-5px', 'top': '5px', 'borderColor': '#564AB1', 'borderRightColor': '#ccc', 'margin': '0 auto' }} /></div>
                </div>
                :
                <>
                    {(sectionData.length > 0 && showlist) &&
                        (
                            <Row>
                                {sectionData.map((data: any, idx: number) => (
                                    <div className="col-md-4 mt-3">
                                        <img src={data?.signature_image} width={200} height={100} />
                                        <Row>
                                            <span>{t("Signed by")}{data?.user_first_name} {data?.user_last_name}</span>
                                            <span>{moment(data?.created_at).format("D/M/Y HH:mm:ss")}</span>
                                        </Row>
                                        <div className='mt-1'>
                                            {data?.created_by_id == userProfile.USER_ID && (
                                            <Button color="warning" size="sm" className='ms-3' title="Edit" onClick={() => handleEditsign(data)}> <i className="fa-solid fa-lg fa-edit"></i>{t("Edit")}</Button>
                                            )}
                                            {!!(showAdd && sectionData.length < 2 && data?.created_by_id == userProfile.USER_ID) && (
                                                <Button color="primary" size="sm" title="Add partner sign" className='ms-1' onClick={() => handleAddsign(data)}> <i className="fa-solid fa-lg fa-plus"></i>{t("Add")}</Button>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </Row>
                        )
                    }

                    <Row>
                        <Col sm={5} className="mt-3">
                            {isSignEnable && !hideSign &&(
                                <Button color="primary" size="md" onClick={SignatureCard} disabled={isDisabled}> <i className="fa-solid fa-lg fa-signature"></i>{t("Sign")}</Button>
                            )}
                            {!!cardset && (
                                <div className="card position-absolute" style={{ width: "18rem", zIndex: "999" }}>
                                    <ul className="list-group list-group-flush">
                                        {!!userSignData && (
                                            <li className="list-group-item">
                                                <div className="col-md-4 mt-3" style={{ "cursor": "pointer" }} onClick={() => AddSignature(1, userSignData[0]?.signature_image)}><img src={userSignData[0]?.signature_image} width={200} height={100} />      </div>
                                            </li>
                                        )}
                                        <li className="list-group-item" onClick={() => setShowModal(true)}>
                                            <div className="col-md-12 mt-3 text-center"><Button color="primary"><i className='fa fas fa-plus'></i></Button></div>
                                        </li>

                                    </ul>
                                </div>
                            )}
                        </Col>
                    </Row>


                </>
            }
            <Modal
                size="lg"
                isOpen={showModal}
                toggle={() => setShowModal(!showModal)}
                backdrop="static"
                keyboard={false}
                autoFocus={false}
            >
                <div className="modal-header">
                    <h5 className="modal-title">{t("Add Signature")}</h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => setShowModal(false)}
                        aria-label="Close"
                    ></button>
                </div>
                <ModalBody>
                    <Container fluid>
                        <Nav tabs>
                            <NavItem style={{ "display": "inline-flex" }}>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={activeTab === 1 ? "active" : "inactive"}
                                    onClick={() => {
                                        setActiveTab(1);
                                    }}>
                                    {t("Draw")}
                                </NavLink>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={activeTab === 2 ? "active" : "inactive"}
                                    onClick={() => {
                                        setActiveTab(2);
                                    }}>
                                    {t("Image")}
                                </NavLink>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={activeTab === 3 ? "active" : "inactive"}
                                    onClick={() => {
                                        setActiveTab(3);
                                    }}>
                                   {t("Type")}
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab} >
                            <TabPane tabId={1} className="border">
                                <Row>
                                    <Col sm="12">
                                        <SignatureCanvas penColor='black'
                                            ref={sigCanvas}
                                            canvasProps={{ width: 550, height: 250, className: 'sigCanvas' }}
                                            onEnd={() => setDisable(false)}
                                        />
                                    </Col>
                                    <Col sm="12">
                                        <h6 className='text-center'>{t("Sign here")}</h6>
                                    </Col>
                                    <CardFooter className="d-flex flex-wrap gap-2 bg-transparent justify-content-end">
                                        <Button color="danger" size="md" onClick={clear}
                                            className="btn-block">
                                            {t("Clear")}
                                        </Button>
                                        <Button color="primary" size="md" onClick={formatIntoPng}
                                            className="btn-block" disabled={disable}>
                                            {t("Done")}
                                        </Button>
                                    </CardFooter>
                                </Row>
                            </TabPane>
                            <TabPane tabId={2}>
                                <Row>
                                    <Card body>
                                        <CardBody>
                                            <Row>
                                                <Dropzone
                                                    onDrop={(acceptedFiles, fileRejections) => {
                                                        const reader = new FileReader();
                                                        reader.readAsDataURL(acceptedFiles[0]);
                                                        reader.onloadend = () => {
                                                            const base64data = reader.result;
                                                            AddSignature(1, base64data);
                                                        }
                                                    }}
                                                    multiple={false}
                                                    accept=".jpeg,.jpg,.png,.gif"
                                                    disabled={loading}>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <div className="dropzone">
                                                            <div className="dz-message needsclick mt-2" {...getRootProps()}>
                                                                <input
                                                                    {...getInputProps()}
                                                                    name="file_name"
                                                                    accept=".jpeg,.jpg,.png,.gif"
                                                                    disabled={loading}
                                                                />
                                                                <div className="mb-3">
                                                                    <i className="display-4 text-muted fa-solid fa-cloud-arrow-up" />
                                                                </div>
                                                                <h4>{t("Drop files here or click to upload.")}</h4>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Dropzone>
                                                {/* <Dropzone
                                                    moduleData={{
                                                        module_app_id: moduleData.module_app_id,
                                                        module_main_id: moduleData.module_main_id,
                                                        module_sub_id: moduleData.module_sub_id,
                                                        section_id: moduleData.section_id,
                                                        file_path: "questionnaire/sign",
                                                    }}
                                                    hideList={true}
                                                    fetchData={true}
                                                    isOpen={true}
                                                    enableExpiryDate={false}
                                                    onUpload={(files: any) => handleUploadDocument(files)}
                                                /> */}
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Row>
                            </TabPane>
                            <TabPane tabId={3}>
                                <Row className='sign-question_fonts'>
                                    <Col sm="12">
                                        <Card body>
                                            <Row>
                                                <Col sm={12} className="sign-question">
                                                    <input type="text" name="name" placeholder={t("Signature")}
                                                        className={`question ${signaturestyle}`} id="nme" required onChange={handleChange} />
                                                    <label htmlFor="nme"><span>{t("Signature")}</span></label>
                                                    <canvas id="canvas" width="200" height="200" style={{ "display": "none" }}></canvas>
                                                </Col>
                                                <Col sm="12">
                                                    <h6 className='text-left'>{t("Type here")}</h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={6} className="sign_1 mt-5 ml-2 mb-5">
                                                    <input type="radio" className='radio_font' name={signature} onClick={() => setSignatureStyle("sign_1")} />{signature}
                                                </Col>
                                                <Col sm={6} className="sign_2 mt-5 ml-2 mb-5">
                                                    <input type="radio" className='radio_font' name={signature} onClick={() => setSignatureStyle("sign_2")} />{signature}
                                                </Col>
                                                <Col sm={6} className="sign_3 mt-5 ml-2 mb-5">
                                                    <input type="radio" className='radio_font' name={signature} onClick={() => setSignatureStyle("sign_3")} />{signature}
                                                </Col>
                                                <Col sm={6} className="sign_4 mt-5 ml-2 mb-5">
                                                    <input type="radio" className='radio_font' name={signature} onClick={() => setSignatureStyle("sign_4")} />{signature}
                                                </Col>
                                            </Row>
                                            <CardFooter className="d-flex flex-wrap gap-2 bg-transparent justify-content-end">
                                                <Button color='primary' size='md' onClick={handleSignText} type="submit">{t("Save")}</Button>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </Container>
                </ModalBody>

            </Modal>
        </React.Fragment >
    )
}

export default AddSignature