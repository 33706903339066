import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

// Import Breadcrumb
import Breadcrumbs from "../../../components/Layout/Breadcrumb";
import InvoiceTabs from "../../Invoices/invoiceTabs";

import ScrollUp from "../../../components/ScrollUpDown/ScrollUp";
import ScrollDown from "../../../components/ScrollUpDown/ScrollDown";

import { useTranslation } from "react-i18next";
import { getUserProfile } from "../../../helpers/functions_helper";

import NavItems from "./NavItems";

const EditCompanyInvoices = (props: any) => {
  const { t } = useTranslation();
  document.title = `${t("Update Company")} | ${process.env.REACT_APP_NAME}`;
  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "/masters/company",
      label: t("Company"),
      active: true,
    },
    {
      url: "#",
      label: t("Update Company"),
      active: true,
    },
  ];
  const dispatch = useDispatch();
  const userProfile = getUserProfile("all-invoices");
  const { id, company_id } = useParams();

  return (
    <React.Fragment>
      <ScrollUp />
      <ScrollDown />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={t("Update Company")}
            breadcrumbItems={breadcrumbItems}
          />

          <NavItems id={id} company_id={company_id} />

          <Row>
            <Col xl="12">
              <Card className="border-top-0 rounded-0 rounded-bottom">
                <CardBody>
                  <Row>
                    <InvoiceTabs company_id={id} />
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditCompanyInvoices;
