import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  Label,
  FormFeedback,
} from "reactstrap";
import Button from "../../../components/Button/Button";
import { useFormik } from "formik";
import { initialValues } from "./helper";
import { validationSchema } from "./validation.schema";
import { getFirstErrorElement } from "../../../helpers/functions_helper";
import Input from "../../../components/Form/Input";
import { useTranslation } from "react-i18next";

const CompanyProfileTypeForm = (props: any) => {
  const {t} = useTranslation();
  const masterName = t("Company profile");
  const formData = props.formData;
  const [initialData, setInitialData] = useState(initialValues);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = (values: formTypes_CompanyProfileType) => {
    props.onSubmitClick(values);
  };

  const handleChange = (fieldName: string, fieldValue: any) => {
    setInitialData({ ...initialData, [fieldName]: fieldValue });
  };

  useEffect(() => {
    if (formData) {
      setInitialData((prevData: any) => ({
        ...prevData,
        id: formData.id,
        company_profile_id: formData.company_profile_id,
        company_profile_value: formData.company_profile_value,
        company_profile_value_french: formData.company_profile_value_french,
        company_profile_value_dutch: formData.company_profile_value_dutch,
        color_code: formData.color_code,
      }));
    }
  }, [formData]);

  useEffect(() => {
    if (!validation.isSubmitting) return;
    if (Object.keys(validation.errors).length > 0) {
      const element = getFirstErrorElement(validationSchema, validation);
      document.getElementById(element)?.scrollIntoView({ behavior: "smooth", block: "center" });
      document.getElementById(element)?.focus({ preventScroll: true });
    }
  }, [validation.isSubmitting, validation.errors]);

  return (
    <React.Fragment>
      <Modal
        size="md"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">{`${props.id ? "Update" : "Create"} ${masterName}`}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label="Close"
          ></button>
        </div>

        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          autoComplete="off"
        >
          <ModalBody>
            <Input id="id" name="id" type="hidden" value={initialData.id} />
            <Input
              id="company_profile_id"
              name="company_profile_id"
              type="hidden"
              value={initialData.company_profile_id}
            />

            <Row>
              <Col className="col-12">
                <div className="mb-3">
                  <Label className="form-label">
                    <span className="badge badge-soft-primary me-2">English</span>
                    Company profile <span className="text-warning is_required">*</span>
                  </Label>
                  <div className="color_palette_main_div">
                    <Input
                      id="company_profile_value"
                      name="company_profile_value"
                      className="form-control"
                      type="text"
                      autoFocus={true}
                      value={initialData.company_profile_value || ""}
                      onBlur={validation.handleBlur}
                      onChange={(e) => handleChange("company_profile_value", e.target.value)}
                      invalid={validation.errors.company_profile_value ? true : false}
                      error={validation.errors.company_profile_value}
                      touched={validation.touched.company_profile_value}
                    />
                    {/* <div className="color_palette_div">
                      <Input
                        id="color_code"
                        name="color_code"
                        type="color"
                        value={initialData.color_code || "#000000"}
                        className="color_palette"
                        onChange={(e) =>
                          handleChange("color_code", e.target.value)
                        }
                      />
                    </div> */}
                  </div>
                </div>
              </Col>

              <Col className="col-12">
                <div className="mb-3">
                  <Label className="form-label">
                    <span className="badge badge-soft-info me-2">Français</span>
                    Profil de l'entreprise <span className="text-warning is_required">*</span>
                  </Label>
                  <Input
                    id="company_profile_value_french"
                    name="company_profile_value_french"
                    className="form-control"
                    type="text"
                    value={initialData.company_profile_value_french || ""}
                    onBlur={validation.handleBlur}
                    onChange={(e) => handleChange("company_profile_value_french", e.target.value)}
                    invalid={validation.errors.company_profile_value_french ? true : false}
                    error={validation.errors.company_profile_value_french}
                    touched={validation.touched.company_profile_value_french}
                  />
                </div>
              </Col>

              <Col className="col-12">
                <div className="mb-3">
                  <Label className="form-label">
                    <span className="badge badge-soft-danger me-2">Nederlands</span>
                    Vennootschapsprofiel <span className="text-warning is_required">*</span>
                  </Label>
                  <Input
                    id="company_profile_value_dutch"
                    name="company_profile_value_dutch"
                    className="form-control"
                    type="text"
                    value={initialData.company_profile_value_dutch || ""}
                    onBlur={validation.handleBlur}
                    onChange={(e) => handleChange("company_profile_value_dutch", e.target.value)}
                    invalid={validation.errors.company_profile_value_dutch ? true : false}
                    error={validation.errors.company_profile_value_dutch}
                    touched={validation.touched.company_profile_value_dutch}
                  />
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              className="btn-block"
              type="submit"
              disabled={props.loading}
              loading={props.loading}
            >
              {t("Submit")}
            </Button>
            <Button
              color="danger"
              className="btn-block"
              type="reset"
              onClick={props.onCloseClick}
              disabled={props.loading}
              loading={props.loading}
            >
              {t("Cancel")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default CompanyProfileTypeForm;
