import React, { useEffect, useState } from 'react';

type floatingProps = {
    children:React.ReactNode,
    position?:'static' | 'relative' | 'absolute' | 'sticky' | 'fixed',
    bottom?:number,
    left?:number,
    right?:number,
    is_mini?:number,
    is_maxi?:number
}

const FloatingComponent = ({children,position='fixed',bottom=0,right=56,is_maxi,is_mini}:floatingProps) => {
   return (
    <div style={{position,bottom,right,zIndex:1050,overflow: 'hidden'}}>
      {children}
    </div>
  );
};

export default FloatingComponent;