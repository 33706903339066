import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class Service {

    async create(data: any) {
        const response = await axios
            .post(
                SERVER_URL + url.ACCESS_ROLE.CREATE,
                data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async update(data: any) {
        const response = await axios
            .put(
                SERVER_URL + url.ACCESS_ROLE.UPDATE,
                data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async delete(id: any) {
        const response = await axios
            .delete(
                SERVER_URL + url.ACCESS_ROLE.DELETE + '/' + id,
                {
                    headers: getAxiosConfigWithToken()
                }
            );
        return response.data;
    }

    async getAll(data: any, company_id?: number) {
        const query = company_id ? '?company_id=' + company_id : '';
        const response = await axios
            .post(SERVER_URL + url.ACCESS_ROLE.GET_ALL + query,
                data,
                {
                    headers: getAxiosConfigWithToken(),
                }
            ).then(function (response: any) {
                return response;
            })
            .catch(function (error: any) {
                return error;
            });
        return response;
    }

    async get(id: number) {
        const response = await axios
            .get(
                SERVER_URL + url.ACCESS_ROLE.GET + '/' + id,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async getList() {
        const response = await axios
            .get(
                SERVER_URL + url.ACCESS_ROLE.GET_LIST,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async getUserAccess(user_id: number) {
        const response = await axios
            .get(
                SERVER_URL + url.ACCESS_ROLE.GET_USER_ACCESS + '/' + user_id,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async updateUserAccess(data: any) {
        const response = await axios
            .put(
                SERVER_URL + url.ACCESS_ROLE.UPDATE_USER_ACCESS,
                data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

}

export default new Service();