import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../components/Layout/Breadcrumb';
import NewsFeedForm from './NewsFeedForm';

const CreateNewsFeed = () => {
    const { t } = useTranslation();
    const navigite = useNavigate();

    document.title = t("Create News Feed ") + "| " + process.env.REACT_APP_NAME;

    const moduleHeading = t('Create News Feed')

    const breadcrumbItems = useMemo(() => [
        {
            url: "/dashboard",
            label: t("Dashboard"),
            active: false,
        },
        {
            url: "/masters/news-feed",
            label: t("News Feed"),
            active: false,
        },
        {
            url: "#",
            label: moduleHeading,
            active: true,
        },
    ], [t]);

    return (
        <div className={"page-content"}>
            <Container fluid>
                <Breadcrumbs
                    breadcrumbTitle={moduleHeading}
                    breadcrumbItems={breadcrumbItems}
                />
                <Row>
                    <Col xl="12">
                        <Card>
                            <CardBody>
                                <NewsFeedForm onSave={() => navigite('/masters/news-feed')} onCancel={() => navigite('/masters/news-feed')} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default CreateNewsFeed;