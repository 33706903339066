import {
    GET_QUESTIONNAIRE_TYPES_FAIL,
    GET_QUESTIONNAIRE_TYPES_SUCCESS,
    GET_QUESTIONNAIRE_TYPE_DETAIL_FAIL,
    GET_QUESTIONNAIRE_TYPE_DETAIL_SUCCESS,
    GET_QUESTIONNAIRE_TYPE_LIST,
    GET_QUESTIONNAIRE_TYPE_LIST_SUCCESS,
    GET_QUESTIONNAIRE_TYPE_LIST_FAIL,
    ADD_NEW_QUESTIONNAIRE_TYPE,
    ADD_QUESTIONNAIRE_TYPE_SUCCESS,
    ADD_QUESTIONNAIRE_TYPE_FAIL,
    UPDATE_QUESTIONNAIRE_TYPE,
    UPDATE_QUESTIONNAIRE_TYPE_SUCCESS,
    UPDATE_QUESTIONNAIRE_TYPE_FAIL,
    EXPORT_QUESTIONNAIRE_TYPE,
    EXPORT_QUESTIONNAIRE_TYPE_SUCCESS,
    EXPORT_QUESTIONNAIRE_TYPE_FAIL,
    DELETE_QUESTIONNAIRE_TYPE_SUCCESS,
    DELETE_QUESTIONNAIRE_TYPE_FAIL,
    DELETE_QUESTIONNAIRE_TYPE,
    GET_QUESTIONNAIRE_TYPES,
    GET_QUESTIONNAIRE_PREVIEW,
    GET_QUESTIONNAIRE_PREVIEW_SUCCESS,
    GET_QUESTIONNAIRE_PREVIEW_FAIL,
} from "./actionTypes"

const INIT_STATE = {
    GET_TYPE_LIST: [],
    GET_QUESTIONS_LIST: [],
    error: "",
    success: "",
    isModel: false,
    loading: false,
}

const QuestionnaireType = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case GET_QUESTIONNAIRE_TYPES:
            return {
                ...state,
                success: "",
                error: "",
                loading: true,
            }
        case GET_QUESTIONNAIRE_TYPES_SUCCESS:
            return {
                ...state,
                GET_TYPE_LIST: action.payload,
                loading: false,
            }

        case GET_QUESTIONNAIRE_TYPES_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case GET_QUESTIONNAIRE_TYPE_LIST:
            return {
                ...state,
                success: "",
                error: "",
                loading: true,
            }
        case GET_QUESTIONNAIRE_TYPE_LIST_SUCCESS:
            return {
                ...state,
                GET_TYPE_LIST: action.payload,
                loading: false,
            }

        case GET_QUESTIONNAIRE_TYPE_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }

        case ADD_NEW_QUESTIONNAIRE_TYPE:
            return {
                ...state,
                success: "",
                error: "",
                loading: true,
            }
        case ADD_QUESTIONNAIRE_TYPE_SUCCESS:
            return {
                ...state,
                // status: [...state.status, action.payload.data],
                success: action.payload.message,
                loading: false,
                isModel: false
            }

        case ADD_QUESTIONNAIRE_TYPE_FAIL:
            return {
                ...state,
                error: action.payload.message,
                isModel: true,
                loading: false,
            }

        case GET_QUESTIONNAIRE_TYPE_DETAIL_SUCCESS:
            return {
                ...state,
                statusDetail: action.payload,
                loading: false,
            }

        case UPDATE_QUESTIONNAIRE_TYPE:
            return {
                ...state,
                success: "",
                error: "",
                loading: true,
            }

        case UPDATE_QUESTIONNAIRE_TYPE_SUCCESS:
            return {
                ...state,
                // status: [...state.status, action.payload.data],
                success: action.payload.message,
                loading: false,
                isModel: false
            }

        case UPDATE_QUESTIONNAIRE_TYPE_FAIL:
            return {
                ...state,
                error: action.payload.message,
                isModel: true,
                loading: false,
            }

        case EXPORT_QUESTIONNAIRE_TYPE:
            return {
                ...state,
                success: "",
                error: "",
                loading: true,
            }

        case EXPORT_QUESTIONNAIRE_TYPE_SUCCESS:
            return {
                ...state,
                // status: [...state.status, action.payload.data],
                success: action.payload.message,
                loading: false,
                isModel: false
            }

        case EXPORT_QUESTIONNAIRE_TYPE_FAIL:
            return {
                ...state,
                error: action.payload.message,
                isModel: true,
                loading: false,
            }
        case DELETE_QUESTIONNAIRE_TYPE:
            return {
                ...state,
                success: '',
                error: '',
                loading: true,
            }

        case DELETE_QUESTIONNAIRE_TYPE_SUCCESS:
            return {
                ...state,
                success: action.payload.message,
                loading: false,
            }

        case DELETE_QUESTIONNAIRE_TYPE_FAIL:
            return {
                ...state,
                error: action.payload.message,
                loading: false,
            }


        case GET_QUESTIONNAIRE_TYPE_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }

        case GET_QUESTIONNAIRE_PREVIEW:
            return {
                ...state,
                success: "",
                error: "",
                loading: true,
            }
        case GET_QUESTIONNAIRE_PREVIEW_SUCCESS:
            return {
                ...state,
                GET_QUESTIONS_LIST: action.payload,
                loading: false,
            }

        case GET_QUESTIONNAIRE_PREVIEW_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }

        default:
            return state
    }
}

export default QuestionnaireType
