import React, { FC, ReactNode } from 'react';
import { Button as BTButton, ButtonProps } from 'reactstrap';

interface ButtonType extends ButtonProps {
    loading?: boolean;
    children: ReactNode;
    fullWidth?: boolean;
}
const Button: FC<ButtonType> = ({ loading, children, disabled, fullWidth = false, ...props }) => {

    return (
        <BTButton {...props} disabled={loading || disabled} style={{ width: fullWidth ? '100%' : 'auto' }}>
            {loading ? <span><i className="fa-solid fa-spinner fa-spin"></i>&nbsp;  </span> : null}
            {children}
        </BTButton>
    )
}

export default Button;