import styled from 'styled-components';
import { Button as sButton } from "reactstrap";

interface MaterialProps {
    color?: string;
    size?:number
  }

export const MaterialIcon = styled.span.attrs({ className: 'material-symbols-outlined fw-light' })<MaterialProps>`
    font-size: ${props => `${props.size || 18}px`};
    float:left;
    color:${props => props.color || '#000'}
`;

export const Button = styled(sButton)`
line-height: 20px;
`;