import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button, Col, Nav, NavItem, Row } from 'reactstrap'

const TabMenu = (props: any) => {
    const {t} = useTranslation();
    const { type } = props;
    return (
        <Row>
            <Col md={10}>
                <Nav tabs className="nav-tabs-custom border-0">
                    <NavItem>
                        <Link
                            style={{ cursor: "pointer" }}
                            to="/masters/questionnaire-type"
                            className={type === "type" ? "nav-link active" : "nav-link"}
                        >
                            <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                            <span className="d-none d-sm-block">{t('Questionnaire Type')}</span>
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link
                            style={{ cursor: "pointer" }}
                            to="/masters/questionnaire-tab"
                            className={type === "tabs" ? "nav-link active" : "nav-link"}
                        >
                            <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                            <span className="d-none d-sm-block">{t('Questionnaire Tab')}</span>
                        </Link>
                    </NavItem>
                    <NavItem>
                        <Link
                            style={{ cursor: "pointer" }}
                            to="/masters/questionnaire-questions"
                            className={type === "questions" ? "nav-link active" : "nav-link"}
                        >
                            <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                            <span className="d-none d-sm-block">{t('Questions')}</span>
                        </Link>
                    </NavItem>
                </Nav>
            </Col>
            {props.onClickModalPreview &&
                <Col md={2}>
                    <Button
                        color="primary"
                        className="btn btn-primary btn-sm waves-light btn-label preview-btn mt-0"
                        onClick={props.onClickModalPreview}
                    >
                        <i className="fas fa-eye label-icon"></i>
                        {t('Preview')}
                    </Button>
                </Col>
            }
        </Row>
    )
}

export default TabMenu