import { takeEvery, call, put } from "redux-saga/effects";
import usersService from "../../services/users.service";
import { AnyAction } from "redux";

// Users Redux States
import {
  GET_USER,
  GET_USER_PROFILE,
  GET_USERS_LIST,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  GET_USER_GENERAL,
} from "./actionTypes";

import { 
    getUserSuccess, 
    getUserError,

    getUserProfileSuccess,
    getUserProfileError,
    
    getUsersListSuccess,
    getUsersListError,

    createUserSuccess,
    createUserError,

    updateUserSuccess,
    updateUserError,

    deleteUserSuccess,
    deleteUserError,

    getUserGeneralSuccess,
    getUserGeneralError,
} from "./actions";
import usersGeneralService from "../../services/users.general.service";

function* getUser({ payload: { users, history } }: AnyAction): any {
  try {
    const response = yield call(usersService.getUser, users);
    if (response.data && response.data.status === "success") {
      yield put(getUserSuccess(response.data));
    } 
    else if (response.data && response.data.status === "error") {
      yield put(getUserError(response.data));
    }
    else {
      yield put(getUserError(response));
    }
  } catch (error: any) {
    yield put(getUserError(error));
  }
}

function* getUserProfile({ payload: { users, history } }: AnyAction): any {
  try {
    const response = yield call(usersService.getUserProfile);
    if (response.data && response.data.status === "success") {
      yield put(getUserProfileSuccess(response.data));
    } 
    else if (response.data && response.data.status === "error") {
      yield put(getUserProfileError(response.data));
    }
    else {
      yield put(getUserProfileError(response));
    }
  } catch (error: any) {
    yield put(getUserProfileError(error));
  }
}

function* getUsersList({ payload: { query, history } }: AnyAction): any {
  try {
    const response = yield call(usersService.getUsersList, query);
    if (response.data && response.data.status === "success") {
      yield put(getUsersListSuccess(response.data));
    }
    else if (response.data && response.data.status === "error") {
      yield put(getUsersListError(response.data));
    }
    else {
      yield put(getUsersListError(response));
    }
  } catch (error: any) {
    yield put(getUsersListError(error));
  }
}

function* createUser({ payload: { users, history } }: AnyAction): any {
  try {
    const response = yield call(usersService.createUser, users);
    if (response.data && response.data.status === "success") {
      yield put(createUserSuccess(response.data));
    }
    else if (response.data && response.data.status === "error") {
      yield put(createUserError(response.data));
    }
    else {
      yield put(createUserError(response));
    }
  } catch (error: any) {
    yield put(createUserError(error));
  }
}

function* updateUser({ payload: { users, history } }: AnyAction): any {
  try {
    const response = yield call(usersService.updateUser, users);
    if (response.data && response.data.status === "success") {
      yield put(updateUserSuccess(response.data));
    }
    else if (response.data && response.data.status === "error") {
      yield put(updateUserError(response.data));
    }
    else {
      yield put(updateUserError(response));
    }
  } catch (error: any) {
    yield put(updateUserError(error));
  }
}

function* deleteUser({ payload: { users, history } }: AnyAction): any {
  try {
    const response = yield call(usersService.deleteUser, users);
    if (response.data && response.data.status === "success") {
      yield put(deleteUserSuccess(response.data));
    }
    else if (response.data && response.data.status === "error") {
      yield put(deleteUserError(response.data));
    }
    else {
      yield put(deleteUserError(response));
    }
  } catch (error: any) {
    yield put(deleteUserError(error));
  }
}


function* getUserGeneral({ payload: { id } }: AnyAction): any {
  try {
    const response = yield call( usersGeneralService.getUsersGeneral, id);
    if (response && response.data && response.data.status === "success") {
      yield put(getUserGeneralSuccess(response.data));
    } else if (response && response.data && response.data.status === "error") {
      yield put(getUserGeneralError(response.data));
    } else {
      yield put(getUserGeneralError(response));
    }
  } catch (error: any) {
    yield put(getUserGeneralError(error));
  }
}

function* usersSaga(): any {
  yield takeEvery(GET_USER, getUser);
  yield takeEvery(GET_USER_PROFILE, getUserProfile);
  yield takeEvery(GET_USERS_LIST, getUsersList);
  yield takeEvery(CREATE_USER, createUser);
  yield takeEvery(UPDATE_USER, updateUser);
  yield takeEvery(DELETE_USER, deleteUser);
  yield takeEvery(GET_USER_GENERAL,getUserGeneral);
}

export default usersSaga;
