import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Input, Label, Row } from 'reactstrap';
import DataTable from '../../../components/Common/DataTable';
import Drawer from '../../../components/Drawer';
import Breadcrumbs from '../../../components/Layout/Breadcrumb';
import { getDataTableQuery, getUserProfile, handleFilters } from '../../../helpers/functions_helper';
import Dropzone from "../../../components/Common/Dropzone";
import { getAllDocument } from './helper';
import { dateFormat } from '../../../utils/formats';
import commonService from '../../../services/common.service';
import fileDownload from 'js-file-download';
import { Buffer } from "buffer";
import TemplateListModel from './TemplateListModal';
import documentTemplateService from '../../../services/document.template.service';
import MultiComments from '../../../components/Common/MultiComments';
import EditCompany from '../../Masters/Company/EditCompany';
import EditUser from '../../Users/EditUser';
import DeleteModal from '../../../components/Common/DeleteModal';
import KebabMenu from '../../../components/KebabMenu';
import DocEdit from '../Templates/DocEdit';
import Loader from '../../../components/Layout/Loader';
import Filters from './filters';
import { prepareAssets } from '../Templates/utils/prepareAssets';

const DocSignature = () => {

    const { t } = useTranslation();

    const [showTemplateModel, setShowTemplateModel] = useState(false);
    const [refuseModal, setRefuseModal] = useState(false);
    const [dataRow, setDataRow] = useState<any>([]);
    const userProfile = getUserProfile("doc-signature");
    const moduleHeading = t('Document signature')
    document.title = `${moduleHeading} | ` + process.env.REACT_APP_NAME;

    const savedfilters = handleFilters("GET");

    const { type } = useParams();
    const navigate = useNavigate();
    const userObj = getUserProfile();
    const breadcrumbItems = [
        {
            url: "/dashboard",
            label: t("Dashboard"),
            active: false,
        },
        {
            url: "#",
            label: moduleHeading,
            active: true,
        },
    ];

    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    useEffect(() => {
        prepareAssets();
    }, [])
    const handleSave = () => {
        setLoading(false);
        setOpenModal(false);
    }

    useEffect(() => {
        !openModal && setLoading(!openModal);
    }, [openModal])

    const [loadingListData, setLoadingListData] = useState<boolean>(true);
    const [deleteRec, setDeleteRec] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<any>();
    const [list, setList] = useState([]);
    const [showAttach, setShowAttach] = useState(false);
    const handleSelectedTemplate = (temp: any) => {
        // message.message.body.content = temp;
        //setMessage((preState)=>({...preState,preState.message.body:{preState.message.body,content:temp}})
    }

    const [clientData, setClientData] = useState<any>([]);
    const [openCompany, setOpenCompany] = useState<boolean>(false);
    const [openUser, setOpenUser] = useState<boolean>(false);
    const [attachmentView, setAttachmentView] = useState<boolean>(false);
    const [templateData, setTemplateData] = useState<any>([]);
    const [openFilter, setOpenFilter] = useState<boolean>(false);
    const [datatableOptions, setDatatableOptions] = useState(
        savedfilters && savedfilters?.datatableOptions
            ? (savedfilters?.datatableOptions as any)
            : {
                draw: 1,
                page: 1,
                recordsTotal: 0,
                recordsFiltered: 0,
                sizePerPage: 20,
                searchText: "",
                sortField: "id",
                sortOrder: "desc",
            }
    );
    const [filters, setFilters] = useState(
        savedfilters && savedfilters?.filters
            ? (savedfilters?.filters as any)
            : {
                is_filtered: false,
                company_id: "",
                user_id: "",
                file_name: "",
                status: null,
                document_status: null,
                expiry_date_from: "",
                expiry_date_to: "",
                due_date_from: "",
                due_date_to: ""
            }
    );
    const handleCompanyClick = (data: any) => {
        setClientData(data);
        setOpenCompany(true);
    }

    const handleUserClick = (dt: any) => {
        setClientData(dt);
        setOpenUser(true);
    }

    const handleApplyFilters = () => {
        setLoadingListData(true);
        setOpenFilter(false);
    };


    const clearFilter = () => {
        const updatedDatatableOptions = {
            ...datatableOptions,
            draw: 1,
            page: 1,
            sizePerPage: 20,
            searchText: "",
            sortField: "last_name",
            sortOrder: "asc",
        };
        setDatatableOptions(updatedDatatableOptions);

        const filter = {
            ...filters,
            is_filtered: false,
            company_id: "",
            user_id: "",
            file_name: "",
            status: null,
            document_status: null,
            expiry_date_from: "",
            expiry_date_to: "",
            due_date_from: "",
            due_date_to: ""
        };
        setFilters(filter);


        setDatatableOptions(updatedDatatableOptions);

        setLoadingListData(true);
    };


    const handleFiltersChange = (obj: any) => {
        setFilters((state: any) => ({ ...state, ...obj }));
    };

    useEffect(() => {
        setLoadingListData(true);
        setList([]);
      }, [localStorage.getItem("I18N_LANGUAGE")])


    const columns = [
        {
            dataField: "DT_RowIndex",
            text: t("Sr. No."),
            sort: false,
            search: false,
        },
        {
            dataField: "company_name",
            text: t("Client name"),
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => {
                if (row.company_id && !(userProfile.IS_NON_TC_USER && !userProfile.CAN_VIEW_ALL)) {
                    return (
                        <div className="link-primary" onClick={() => handleCompanyClick(row)}>
                            {row.company_name}
                        </div>
                    );
                } else {
                    return row.company_name;
                }
            },
            hidden: !userProfile.IS_INTERNAL
            //formatter: (rowContent: any, row: any): any => dateFormat(row.news_feed_creation_date)
        },
        {
            dataField: "contact_person",
            text: t("Contact"),
            sort: true,
            search: true,
            formatter: (rowContent: any, row: any): any => {
                return (
                    <div className="link-primary" onClick={() => handleUserClick(row)}>
                        {row.contact_person}
                    </div>

                );
            },
            hidden: !userProfile.IS_INTERNAL
        },
        {
            dataField: "filename",
            text: t("Document name"),
            sort: true,
            search: true,
            classes: 'table-cell-text-wrap',
            formatter: (rowContent: any, row: any): any => {
                return (
                    <div className="link-primary" onClick={() => handleView(row)}>
                        {row.filename}
                    </div>

                );
            }
        },
        {
            dataField: "document_status",
            text: t("Document status"),
            sort: true,
            search: true,
            classes: 'table-cell-text-wrap',
            formatter: (rowContent: any, row: any): any => {
                return (row.document_status == 1) ? t("Draft") : t("Sent");
            },
            hidden: !userProfile.IS_INTERNAL
        },
        {
            dataField: "status",
            text: t("Status"),
            sort: true,
            search: true,
            classes: 'table-cell-text-wrap',
            formatter: (rowContent: any, row: any): any => {
                return (row.status == 5) ? t("Expired") : ((row.status == 4) ? t("Finalized") : (((row.status == 3) ? t("Submitted") : ((row.status == 2) ? t("Refused") : t("To do")))));
            },
        },

        {
            dataField: "sent_by_person",
            text: t("Sent by"),
            sort: true,
            search: true,
            //formatter: (rowContent: any, row: any): any => dateFormat(rowContent)
        },
        {
            dataField: "due_date_value",
            text: t("Due date"),
            sort: true,
            search: true
        },
        {
            dataField: "expiry_date_value",
            text: t("Expiry date"),
            sort: true,
            search: true
        },
        {
            dataField: "created_at",
            text: t("Created on"),
            sort: true,
            search: true,
            hidden: !userProfile.IS_INTERNAL,
            formatter: (rowContent: any, row: any): any => dateFormat(rowContent)
        },
        {
            dataField: "updated_at",
            text: t("Updated on"),
            sort: true,
            search: true,
            hidden: !userProfile.IS_INTERNAL,
            formatter: (rowContent: any, row: any): any => dateFormat(rowContent)
        },
        {
            dataField: "created_by_value",
            text: t("Created by"),
            sort: true,
            search: true,
            hidden: !userProfile.IS_INTERNAL,
        },
        {
            dataField: "updated_by_value",
            text: t("Updated by"),
            sort: true,
            search: true,
            hidden: !userProfile.IS_INTERNAL,
        },
        {
            dataField: "action",
            text: t('Action'),
            sort: false,
            search: false,
            csvExport: false,
            width: '60px',
            classes: 'fix-col-right',
            headerClasses: 'fix-col-right',
            formatter: (rowContent: any, row: any): any => {
                return (
                    <div className="d-flex gap-2 text-end">
                        <KebabMenu menus={[
                            {
                                label: t('View'),
                                icon: <i className="fas fa-file-code"></i>,
                                onClick: () => handleView(row),
                            },
                            {
                                label: t('Attachments'),
                                icon: <i className="fas fa-file-code"></i>,
                                onClick: () => handleAttachments(row),
                            },
                            {
                                label: t('Comments'),
                                icon: <i className="fa-solid fa-comment"></i>,
                                onClick: () => getComments(row),
                            },
                            {
                                label: t('Edit'),
                                icon: <i className="fa-regular fa-pen-to-square"></i>,
                                onClick: () => navigate("/client-onboarding/doc-signature/" + row?.id + "/" + row.doc_signature_id),
                                //disabled: row.status !== "Draft",
                                hidden: !((userProfile.USER_ID != row.contact_person_id) && userProfile.IS_INTERNAL && (row.document_status == 1))
                            },
                            {
                                label: t('Send'),
                                icon: <i className="fa-regular fa-pen-to-square"></i>,
                                onClick: () => navigate("/client-onboarding/doc-signature/" + row?.id + "/" + row.doc_signature_id),
                                // disabled: row.status !== "Draft",
                                hidden: !((userProfile.USER_ID != row.contact_person_id) && userProfile.IS_INTERNAL && (row.document_status == 1))
                            },
                            {
                                label: t('Finalize'),
                                icon: <i className="fa-regular fa-pen-to-square"></i>,
                                onClick: () => navigate("/client-onboarding/doc-signature/" + row?.id + "/" + row.doc_signature_id),
                                // disabled: row.status !== "Draft",
                                hidden: !((userProfile.USER_ID != row.contact_person_id) && userProfile.IS_INTERNAL && (row.status == 3))
                            },
                            {
                                label: t('Sign'),
                                icon: <i className="fa-regular fa-pen-to-square"></i>,
                                onClick: () => navigate("/client-onboarding/doc-signature/" + row?.id + "/" + row.doc_signature_id),
                                //disabled: row.status !== "Draft",
                                hidden: !((userProfile.USER_ID == row.contact_person_id) && (row.status == 1))
                            },
                            {
                                label: t('Delete'),
                                icon: <i className="fa-regular fa-trash-can"></i>,
                                onClick: () => onDeleteClick(row),
                                hidden: !((userProfile.USER_ID != row.contact_person_id) && (row.document_status == 1))
                            },
                            {
                                label: t('Download'),
                                icon: <i className="fa-solid fa-download"></i>,
                                onClick: () => handleSingleDownload(row),
                                //hidden: !((userProfile.USER_ID != row.contact_person_id) && (row.document_status == 1))
                            },
                        ]} />
                    </div>
                );
            },
        },
    ];



    const onDeleteClick = (row: any) => {
        setDeleteRec(true)
        setSelectedRow(row);
    }

    const handleDelete = async (id: number, doc_signature_id: any) => {
        const res = await documentTemplateService.deleteDocument(id, doc_signature_id);
        if (res) {
            setDeleteRec(false);
            setLoadingListData(true);
        }
    }

    const getComments = (row: any) => {
        setDataRow(row);
        setRefuseModal(true);
    }

    const handleSingleDownload = async (row: any) => {
        const result = await commonService.downloadAttachment({ id: Buffer.from(String(row.attachment_id)).toString("base64"), attachment_id: Buffer.from(String(row.attachment_sub_id)).toString("base64") });
        if (result) {
            fileDownload(result.data, row.filename + ".pdf");
        }
    }


    const onTableChange = (type: any, newState: any) => {
        let draw = datatableOptions.draw + 1;
        let page = 1;
        if (type == "search") {
            page = 1;
        } else {
            page = newState.page;
        }
        const updatedDatatableOptions = {
            ...datatableOptions,
            draw: draw,
            page: page,
            sizePerPage: newState.sizePerPage,
            searchText: newState.searchText,
            sortField: newState.sortField,
            sortOrder: newState.sortOrder,
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingListData(true);
    };

    const getList = async () => {
        const res = await getAllDocument({ ...getDataTableQuery(datatableOptions, columns), filters });
        if (res) {
            setList(res.data.data);
            const updatedDatatableOptions = {
                ...datatableOptions,
                recordsTotal: res.data.recordsTotal,
                recordsFiltered: res.data.recordsFiltered,
            };
            setDatatableOptions(updatedDatatableOptions);
            setLoadingListData(false);
        }
    }

    const [attachment, setAttachment] = useState<any>([]);
    const handleAttachments = (row: any) => {
        setShowAttach(true);
        setAttachment(row);
    }


    const handleView = (row: any) => {
        setAttachmentView(true);
        setTemplateData(row);
    }

    useEffect(() => {
        if (loadingListData === true)
            getList();
    }, [loadingListData])

    const rowStyle2 = (row: any, rowIndex: number) => {
        const style = {} as any;

        if (new Date(row.due_date) <= new Date() && row.status === 'Sent') {
            style.backgroundColor = "#FFF0F6";
            style.color = "#EB2F96";
        } else {
            style.backgroundColor = "#FFF";
        }

        return style;
    };

    return (
        <div className={"page-content"}>
            <Container fluid>
                <Breadcrumbs
                    breadcrumbTitle={moduleHeading}
                    breadcrumbItems={breadcrumbItems}
                    endAdornment={
                        <div className="d-flex gap-2 text-end">
                            {(userObj.USER_COMPANY == 1) ? <>
                                <Link to="#" className={`btn btn-outline-secondary btn-label btn-sm`} onClick={() => setShowTemplateModel(true)}>
                                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                        list_alt
                                    </span>
                                    {t("Get templates")}
                                </Link>
                                <Link to="/client-onboarding/doc-signature/list" className="btn btn-primary btn-sm waves-effect waves-light btn-label">
                                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                        list_alt
                                    </span>
                                    {t("List view")}
                                </Link>
                            </>
                                : null
                            }
                        </div>
                    }
                />
                <Row>
                    <Col xl="12">
                        <Card>
                            <CardBody>
                                <DataTable
                                    columns={columns}
                                    products={list}
                                    datatableOptions={datatableOptions}
                                    filters={filters}
                                    onTableChange={onTableChange}
                                    loadingListData={loadingListData}
                                    clearFilter={clearFilter}
                                    slug="doc-signature"
                                    rowStyle={rowStyle2}
                                    bordered={false}
                                    striped={false}
                                    customButtons={
                                        <>
                                            {userProfile.CAN_VIEW_ALL ? (
                                                <Button
                                                    title={t("Filter")}
                                                    outline={filters.is_filtered === true ? false : true}
                                                    color={filters.is_filtered === true ? "secondary" : "light"}
                                                    onClick={() => setOpenFilter((state) => !state)}>
                                                    {filters.is_filtered === false ? (
                                                        <span className="align-middle material-symbols-outlined fw-light fs-4">filter_alt</span>
                                                    ) : (
                                                        <span className="align-middle material-symbols-outlined fw-light fs-4">filter_alt_off</span>
                                                    )}
                                                </Button>
                                            ) : null}
                                        </>
                                    }
                                />
                                {deleteRec && (
                                    <DeleteModal
                                        recordName={selectedRow?.filename || ''}
                                        modalTitle={t("Document Template")}
                                        show={deleteRec}
                                        onDeleteClick={() => handleDelete(selectedRow?.id, selectedRow?.doc_signature_id)}
                                        onCloseClick={() => setDeleteRec(false)}
                                        loading={false}
                                    />
                                )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <TemplateListModel
                    title={t("Template Search")}
                    show={showTemplateModel}
                    selectTemplate={handleSelectedTemplate}
                    onCloseClick={() => setShowTemplateModel(false)}
                />
                {refuseModal &&
                    <Drawer
                        title={t('Comments')}
                        open={refuseModal}
                        onClose={() => { setRefuseModal((state) => !state); }}
                        fullWidth={false}
                    >
                        <Col xl={12} lg={12} md={12} sm={12}>
                            <MultiComments
                                moduleData={{
                                    module_app_id: 14,
                                    module_main_id: dataRow?.id,
                                    module_sub_id: dataRow?.doc_signature_id,
                                    section_id: 0,
                                }}
                                fetchData={true}
                                isOpen={false}
                            />
                        </Col>
                    </Drawer>
                }
                {(showAttach && attachment) &&
                    <Drawer
                        title={t('Attachments')}
                        open={showAttach}
                        onClose={() => { setShowAttach((state) => !state); }}
                        fullWidth={false}
                    >

                        <Dropzone
                            moduleData={{
                                module_app_id: 14,
                                module_main_id: attachment.id,
                                module_sub_id: attachment.doc_signature_id,
                                section_id: 0,
                                file_path: "user_document",
                            }}
                            //attachmentForIDs={commentToIDs}
                            fetchData={true}
                            isOpen={false}
                            isEdit={false}
                            isDelete={false}
                        />
                    </Drawer>
                }

                {
                    clientData?.company_details?.id &&
                    <Drawer
                        title={t('Client')}
                        open={openCompany}
                        onClose={() => { setOpenCompany((state) => !state); setLoadingListData(true); }}
                        fullWidth
                    >
                        <EditCompany id={clientData?.company_details?.id} company_id={clientData?.company_details?.company_id} type={1} isWindow={true} />
                    </Drawer>
                }
                {
                    clientData?.user_data?.id &&
                    <Drawer
                        title={t('User')}
                        open={openUser}
                        onClose={() => { setOpenUser((state) => !state); setLoadingListData(true); }}
                        fullWidth
                    >
                        <EditUser id={clientData?.user_data?.id} user_id={clientData?.user_data?.user_id} type={1} isWindow={true} />
                    </Drawer>
                }
                {
                    attachmentView &&
                    <Drawer
                        title={t("View Dcoument")}
                        open={attachmentView}
                        onClose={() => setAttachmentView(false)}
                        fullWidth
                    >
                        <DocEdit id={templateData.id} template_id={templateData.doc_signature_id} templateData={templateData} attachmentData={[]} type="template" template={true} viewOnly={true} popup={true} />
                    </Drawer>
                }

                <Drawer
                    title={t("Filters")}
                    open={openFilter}
                    onClose={() => setOpenFilter((state) => !state)}
                    onReset={clearFilter}
                    footer={
                        <Button color="primary" style={{ margin: "16px" }} onClick={handleApplyFilters}>
                            {t("Apply")}
                        </Button>
                    }
                >
                    <Filters
                        filters={filters}
                        onApply={() => { }}
                        onClear={() => { }}
                        onChange={(obj: any) => handleFiltersChange(obj)}
                    />
                </Drawer>
            </Container>
        </div>
    );
}

export default DocSignature;