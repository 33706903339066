import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Button,
} from "reactstrap";
import { formatCurrency } from "../../utils/formats";
import { useTranslation } from "react-i18next";

const SSSCModel_124 = (props: any) => {
  const { t } = useTranslation();
  let spCountryData = props.spCountryData;
  const [COUNTRY_IDX, setCOUNTRY_IDX] = useState<number>();
  const [D_EMP_DIR, setD_EMP_DIR] = useState<number>(1);
  const [D_GROSS_COUNTRY, setD_GROSS_COUNTRY] = useState<number>(0);
  const [C_TAXES_COUNTRY, setC_TAXES_COUNTRY] = useState<number>(0);
  const [FOREIGN_TAX_LIABILITY, setFOREIGN_TAX_LIABILITY] = useState<number>(0);

  useEffect(() => {
    if (spCountryData) {
      const COUNTRY_DATA = spCountryData.find((data: any) => data.country_id === 124);

      if (COUNTRY_DATA) {
        setCOUNTRY_IDX(spCountryData.findIndex((data: any) => data.country_id === 124));
        setD_GROSS_COUNTRY(
          spCountryData.find((data: any) => data.country_id === 124)?.D_GROSS_COUNTRY
        );
        setD_EMP_DIR(spCountryData.find((data: any) => data.country_id === 124)?.emp_dir);
      }
    }
  }, [spCountryData]);

  useEffect(() => {
    if (D_EMP_DIR === 1) {
      setC_TAXES_COUNTRY(0);
      setFOREIGN_TAX_LIABILITY(0);
    } else {
      setC_TAXES_COUNTRY(Number(D_GROSS_COUNTRY) * 0.2);
      setFOREIGN_TAX_LIABILITY(Number(D_GROSS_COUNTRY) * 0.2);
    }
  }, [D_EMP_DIR, D_GROSS_COUNTRY]);

  useEffect(() => {
    if (COUNTRY_IDX) {
      props.onCalculationUpdate({
        COUNTRY_IDX: COUNTRY_IDX,
        C_TAXES_COUNTRY: C_TAXES_COUNTRY,
        FOREIGN_TAX_LIABILITY: FOREIGN_TAX_LIABILITY,
      });
    }
  }, [COUNTRY_IDX, C_TAXES_COUNTRY, FOREIGN_TAX_LIABILITY]);

  return (
    <React.Fragment>
      <Modal
        size="xl"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">{t("LUXEMBOURG")}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label={t("Close")}
          ></button>
        </div>

        <ModalBody>
          <Card>
            <CardHeader>
              <CardTitle>1. {t("PARAMETERS")}</CardTitle>
            </CardHeader>

            <CardBody></CardBody>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>2. {t("COMPUTATION")}</CardTitle>
            </CardHeader>

            <CardBody>
              <div className="table-responsive mb-3">
                <Table className="table table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr>
                      <td>
                        {t("GROSS")} {t("LUXEMBOURG")}
                      </td>
                      <td className="text-end">
                        {formatCurrency(Number(D_GROSS_COUNTRY).toFixed(2), false)}
                      </td>
                    </tr>
                    <tr>
                      <td>{t("FOREIGN TAX LIABILITY")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(FOREIGN_TAX_LIABILITY).toFixed(2), false)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </ModalBody>

        <ModalFooter>
          <Button color="danger" className="btn-block" type="reset" onClick={props.onCloseClick}>
            {t("Close")}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default SSSCModel_124;
