import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import WidgetBasic from '../../../components/Widgets/Basic';
import questionnaireService from '../../../services/questionnaire.service';
interface CountsProps extends CartProps {
    filters?: any;
    widgetId?: 'sent' | 'inprogress' | 'in_review' | 'finalized' | 'incomplete' | 'in_review' | 'submitted' | 'finalized_by_admin' | 'All';
}
const Counts: FC<CountsProps> = ({ hideHeading, filters = null, widgetId = 'All' }) => {
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(true);
    const [counts, setCounts] = useState<any>({
        sent: 0,
        yet_to_start: 0,
        inprogress: 0,
        finalized: 0,
        incomplete: 0,
        in_review: 0,
        submitted: 0,
        finalized_by_admin: 0,
    })

    const getAnalyticsData = async () => {
        const res = await questionnaireService.getAnalytics("");
        if (res.status === 'success') {
            let sent = res.yaxis[0];
            setCounts((state: any) => ({
                ...state,
                sent: sent || 0,
                yet_to_start: res.data.sent || 0,
                inprogress: res.data.inprogress || 0,
                finalized: res.data.finalized || 0,
                incomplete: res.data.incomplete || 0,
                in_review: res.data.in_review || 0,
                submitted: res.data.submitted || 0,
                finalized_by_admin: res.data.finalized_by_admin || 0,
            }));

        }
        setLoading(false)
    }

    useEffect(() => {
        getAnalyticsData()
    }, [])

    return (
        <>
            <Row>
                {(widgetId === 'All' || widgetId === 'sent') &&
                    <Col>
                        <WidgetBasic variant="primary" loading={loading} label={t("Total sent")} value={counts.sent} icon={<i className="fa fa-solid fa-file-invoice"></i>}  to="/questionnaires/all-questionnaires/all"/>
                    </Col>
                }
                {(widgetId === 'All' || widgetId === 'sent') &&
                    <Col>
                        <WidgetBasic variant="danger" loading={loading} label={t("Sent")} value={counts.yet_to_start} icon={<i className="fa fa-solid fa-pen-ruler"></i>} to="/questionnaires/all-questionnaires/1"/>
                    </Col>
                }
                {(widgetId === 'All' || widgetId === 'inprogress') &&
                    <Col>
                        <WidgetBasic variant="warning" loading={loading} label={t("Inprogress")} value={counts.inprogress} icon={<i className="fa fa-solid fa-list"></i>} to="/questionnaires/all-questionnaires/4"/>
                    </Col>
                }
                {(widgetId === 'All' || widgetId === 'finalized') &&
                    <Col>
                        <WidgetBasic variant="info" loading={loading} label={t("Finalized")} value={counts.finalized} icon={<i className="fa fa-solid fa-list"></i>} to="/questionnaires/all-questionnaires/5"/>
                    </Col>
                }
            </Row>
            <Row>
                {(widgetId === 'All' || widgetId === 'incomplete') &&
                    <Col>
                        <WidgetBasic variant="secondary" loading={loading} label={t("Incomplete")} value={counts.incomplete} icon={<i className="fa fa-solid fa-receipt"></i>} to="/questionnaires/all-questionnaires/8"/>
                    </Col>
                }
                {(widgetId === 'All' || widgetId === 'in_review') &&
                    <Col>
                        <WidgetBasic variant="success" loading={loading} label={t("In review")} value={counts.in_review} icon={<i className="fa fa-solid fa-clipboard"></i>} to="/questionnaires/all-questionnaires/3"/>
                    </Col>
                }
                {(widgetId === 'All' || widgetId === 'submitted') &&
                    <Col>
                        <WidgetBasic variant="warning" loading={loading} label={t("Submitted")} value={counts.submitted} icon={<i className="fa fa-solid fa-clipboard"></i>} to="/questionnaires/all-questionnaires/2"/>
                    </Col>
                }
                {(widgetId === 'All' || widgetId === 'finalized_by_admin') &&
                    <Col>
                        <WidgetBasic variant="info" loading={loading} label={t("Finalized by admin")} value={counts.finalized_by_admin} icon={<i className="fa fa-solid fa-clipboard"></i>} to="/questionnaires/all-questionnaires/6"/>
                    </Col>
                }
            </Row>
        </>
    )
}

export default Counts