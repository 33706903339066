import { Button } from "reactstrap";

const ReloadData = (props: any) => {
  const { title, count, handleSync, handleClose } = props;

  return (
    <div className="data-reload-notification d-flex">
      <Button
        color="primary"
        className="btn-rounded btn-soft waves-effect waves-light"
        onClick={handleSync}
      >
        <span className="align-middle material-symbols-outlined fw-light fs-2">sync</span>
      </Button>
      <div className="flex-fill text-center font-size-16 p-2">{count}{" "}{title}</div>
      <Button
        color="light"
        className="btn-rounded btn-soft waves-effect waves-light"
        onClick={handleClose}
      >
        <span className="align-middle material-symbols-outlined fw-light fs-2">cancel</span>
      </Button>
    </div>
  );
};

export default ReloadData;
