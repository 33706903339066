import {
  GET_KANBAN_TASK,
  GET_KANBAN_TASK_SUCCESS,
  GET_KANBAN_TASK_ERROR,

  GET_KANBAN_TASKS_LIST,
  GET_KANBAN_TASKS_LIST_SUCCESS,
  GET_KANBAN_TASKS_LIST_ERROR,

  CREATE_KANBAN_TASK,
  CREATE_KANBAN_TASK_SUCCESS,
  CREATE_KANBAN_TASK_ERROR,

  UPDATE_KANBAN_TASK,
  UPDATE_KANBAN_TASK_SUCCESS,
  UPDATE_KANBAN_TASK_ERROR,

  DELETE_KANBAN_TASK,
  DELETE_KANBAN_TASK_SUCCESS,
  DELETE_KANBAN_TASK_ERROR,
  GET_KANBAN_SEARCH,
  GET_KANBAN_SEARCH_SUCCESS,
  GET_KANBAN_SEARCH_ERROR,
  GET_TASK_ALLOCATION_LIST_ERROR,
  GET_TASK_ALLOCATION,
  GET_TASK_ALLOCATION_ERROR,
  GET_TASK_ALLOCATION_LIST,
  GET_TASK_ALLOCATION_LIST_SUCCESS,
  GET_TASK_ALLOCATION_SUCCESS,
} from "./actionTypes";

// ---------------------------------------------GET_KANBAN_TASK
export const getKanbanTask = (tasks: any, history: any) => {
  return {
    type: GET_KANBAN_TASK,
    payload: { tasks, history },
  };
};

export const getKanbanTaskSuccess = (data: {}) => {
  return {
    type: GET_KANBAN_TASK_SUCCESS,
    payload: data,
  };
};

export const getKanbanTaskError = (error: {}) => {
  return {
    type: GET_KANBAN_TASK_ERROR,
    payload: error,
  };
};

// ---------------------------------------------GET_KANBAN_TASKS_LIST
export const getKanbanTasksList = (query: any, history: any) => {
  return {
    type: GET_KANBAN_TASKS_LIST,
    payload: {query, history},
  };
};

export const getKanbanTasksListSuccess = (data: {}) => {
  return {
    type: GET_KANBAN_TASKS_LIST_SUCCESS,
    payload: data,
  };
};

export const getKanbanTasksListError = (error: {}) => {
  return {
    type: GET_KANBAN_TASKS_LIST_ERROR,
    payload: error,
  };
};

// ---------------------------------------------CREATE_KANBAN_TASK
export const createKanbanTask = (tasks: any, history: any) => {
  return {
    type: CREATE_KANBAN_TASK,
    payload: { tasks, history },
  };
};

export const createKanbanTaskSuccess = (tasks: {}) => {
  return {
    type: CREATE_KANBAN_TASK_SUCCESS,
    payload: tasks,
  };
};

export const createKanbanTaskError = (error: {}) => {
  return {
    type: CREATE_KANBAN_TASK_ERROR,
    payload: error,
  };
};

// ---------------------------------------------UPDATE_KANBAN_TASK
export const updateKanbanTask = (tasks: any, history: any) => {
  return {
    type: UPDATE_KANBAN_TASK,
    payload: { tasks, history },
  };
};

export const updateKanbanTaskSuccess = (tasks: {}) => {
  return {
    type: UPDATE_KANBAN_TASK_SUCCESS,
    payload: tasks,
  };
};

export const updateKanbanTaskError = (error: {}) => {
  return {
    type: UPDATE_KANBAN_TASK_ERROR,
    payload: error,
  };
};

// ---------------------------------------------DELETE_KANBAN_TASK
export const deleteKanbanTask = (tasks: any, history: any) => {
  return {
    type: DELETE_KANBAN_TASK,
    payload: { tasks, history },
  };
};

export const deleteKanbanTaskSuccess = (tasks: {}) => {
  return {
    type: DELETE_KANBAN_TASK_SUCCESS,
    payload: tasks,
  };
};

export const deleteKanbanTaskError = (error: {}) => {
  return {
    type: DELETE_KANBAN_TASK_ERROR,
    payload: error,
  };
};


// ---------------------------------------------GET_KANBAN_SEARCH
export const getKanbanSearch = (tasks: any, history: any) => {
  return {
    type: GET_KANBAN_SEARCH,
    payload: { tasks, history },
  };
};

export const getKanbanSearchSuccess = (data: {}) => {
  return {
    type: GET_KANBAN_SEARCH_SUCCESS,
    payload: data,
  };
};

export const getKanbanSearchError = (error: {}) => {
  return {
    type: GET_KANBAN_SEARCH_ERROR,
    payload: error,
  };
};


// ---------------------------------------------GET_TASK_ALLOCATION
export const getTaskAllocation = (tasks: any, history: any) => {
  return {
    type: GET_TASK_ALLOCATION,
    payload: { tasks, history },
  };
};

export const getTaskAllocationSuccess = (data: {}) => {
  return {
    type: GET_TASK_ALLOCATION_SUCCESS,
    payload: data,
  };
};

export const getTaskAllocationError = (error: {}) => {
  return {
    type: GET_TASK_ALLOCATION_ERROR,
    payload: error,
  };
};

// ---------------------------------------------GET_TASK_ALLOCATION_LIST
export const getTaskAllocationList = (tasks: any, history: any) => {
  return {
    type: GET_TASK_ALLOCATION_LIST,
    payload: { tasks, history },
  };
};

export const getTaskAllocationListSuccess = (data: {}) => {
  return {
    type: GET_TASK_ALLOCATION_LIST_SUCCESS,
    payload: data,
  };
};

export const getTaskAllocationListError = (error: {}) => {
  return {
    type: GET_TASK_ALLOCATION_LIST_ERROR,
    payload: error,
  };
};