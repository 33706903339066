import { combineReducers } from "redux"

// Common
import Common from "./common/reducer"

// Tasks
import Tasks from "./tasks/reducer"

// Invoices
import Invoices from "./invoices/reducer"

// Users
import Users from "./users/reducer"

// Time Sheets
import TimeSheets from "./timesheets/reducer"

// Access Role
import AccessRoles from "./masters/accessrole/reducer"

// Access Module
import AccessModules from "./masters/accessmodule/reducer"

// Company
import Company from "./masters/company/reducer"

//CompanyCategory
import CompanyCategory from "./masters/companycategory/reducer"

// Fees
import Fees from "./masters/fees/reducer"

// Task Type
import TaskType from "./masters/tasktype/reducer"

// Task Status
import TaskStatus from "./masters/taskstatus/reducer"

// Quetsionnaire Status
import QuestionnaireStatus from "./masters/questionnairestatus/reducer"

//Questionnaire Tab
import QuestionnaireTab from "./masters/questionnairetab/reducer"

//Questionnaire Type
import QuestionnaireType from "./masters/questionnairetype/reducer"

//Questionnaire questions
import QuestionnaireQuestions from "./masters/questionnairequestions/reducer"


//Questionnaire userdata
import QuestionnaireUserData from "./quesionuserdata/reducer"

// Quetsionnaire
import Questionnaire from "./questionnaire/reducer"

// Task Priority
import TaskPriority from "./masters/taskpriority/reducer"

// User Function
import UserFunction from "./masters/userfunction/reducer"

// User Type
import UserType from "./masters/usertype/reducer"

// User Status
import UserStatus from "./masters/userstatus/reducer"

// Kanban Task
import KanbanTask from "./kanbantask/reducer"

// Subtask 
import SubTask from "./subtasks/reducer";


// Dashboard 
import Dashboard from "./dashboard/reducer";

// Mail
import Mails from "./mails/reducer";

// User Manual
import UserManual from "./usermanual/reducer";

// Email Category
import EmailCategory from "./masters/emailtemplatecategory/reducer";

import Alert from "./alert/reducer";

// SaltEdge
import SaltEdge from "./saltedge/reducer";

const rootReducer = combineReducers({
  Common,
  Tasks,
  Users,
  TimeSheets,
  AccessRoles,
  AccessModules,
  Company,
  CompanyCategory,
  Fees,
  TaskType,
  TaskStatus,
  QuestionnaireStatus,
  QuestionnaireTab,
  QuestionnaireType,
  QuestionnaireQuestions,
  Questionnaire,
  QuestionnaireUserData,
  TaskPriority,
  UserFunction,
  UserType,
  UserStatus,
  KanbanTask,
  SubTask,
  Invoices,
  Dashboard,
  Mails,
  UserManual,
  EmailCategory,
  Alert,
  SaltEdge
})

export default rootReducer
