import { Table } from "reactstrap";

const PrivacyPolicy = (props: any) => {
  return (
    <>
      <h4>1. A propos de cet Avis</h4>
      <p>
        1.1 Cet Avis de confidentialité (l'"Avis") décrit comment nous collectons, partageons et
        utilisons, toute information qui, utilisée seule ou en combinaison avec d'autres
        informations, se rapporte à vous ("Données personnelles") en votre qualité de client ou
        bénéficiaire de nos services, ou en votre qualité de fournisseur (ou comme notre personne de
        contact pour ces entités ou représentants) ("vous" et "votre") de Tax Consult HR Tax &
        International Mobility ("Tax Consult", "nous" ou "notre").
      </p>
      <p>
        1.2 Le présent Avis énonce également les droits dont vous jouissez et qui sont relatifs aux
        Données personnelles que nous traitons à votre sujet, ainsi que la manière dont vous pouvez
        les exercer.
      </p>
      <p>
        1.3 Tax Consult HR Tax & International Mobility treats compliance with its privacy
        obligations seriously. This is why we have developed this Notice, which describes the
        standards that Tax Consult applies to protect Personal Data.
      </p>
      <p>
        1.4 Aux fins du présent Avis, la SRL Tax Consult HR Tax & International Mobility avec
        laquelle vous avez conclu un contrat agit qui fournit des services dont vous êtes le
        bénéficiaire en qualité de responsable de traitement de vos Données personnelles. Nous avons
        notre siège social à 1170 Bruxelles, avenue du Dirigeable 8 et sommes inscrit à la
        Banque-Carrefour des Entreprises sous le numéro 0740.763.660. En sa qualité de responsable
        de traitement, Tax Consult est responsable de veiller à ce que le traitement des Données
        personnelles soit conforme à la législation sur la protection des données en vigueur, et
        plus particulièrement au Règlement Général sur la Protection des Données.
      </p>
      <p>
        1.5 Veuillez prendre le temps de lire attentivement cet Avis. Si vous avez des questions ou
        commentaires, veuillez contacter{" "}
        <a href="mailto:privacy@taxconsult.be">privacy@taxconsult.be</a>.
      </p>

      <h4>2. Quelles Données personnelles sont collectées par Tax Consult et pourquoi?</h4>
      <p>
        2.1 Les types de Données personnelles que nous collectons, et les raisons pour lesquelles
        nous les traitons sont les suivantes :
      </p>

      <Table bordered={true}>
        <thead>
          <tr>
            <th>Pourquoi nous les collectons?</th>
            <th>Types de Données personnelles</th>
            <th>Base légale</th>
          </tr>
          <tr>
            <th colSpan={3}>
              Traitements relatifs aux services envers nos clients et bénéficiaires
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Pour assurer notre conformité à nos obligations en matière d'anti-blanchiment
              concernant nos clients et les bénéficiaires de nos services
            </td>
            <td>
              Données d'identification, coordonnées, données financières et économiques, données
              concernant le foyer, données sur les caractéristiques personnelles et sur votre mode
              de vie, données professionnelles et sur votre parcours, données sur les documents
              d'identité.
            </td>
            <td>Obligation légale à laquelle nous sommes soumis.</td>
          </tr>
          <tr>
            <td>
              Préparation des déclarations d'impôt sur le revenu des particuliers pour les
              contribuables résidents et non-résidents
            </td>
            <td>Mêmes catégories de données que ci-dessus.</td>
            <td>
              Lors que nous contractons avec une personne morale: notre intérêt légitime à fournir
              nos services. Lors que nous contractons avec une personne physique: nécessité
              contractuelle.
            </td>
          </tr>
          <tr>
            <td>
              Afin de fournir des services relatifs aux estimations fiscales et de sécurité sociale,
              salaires, charges sociales à payer
            </td>
            <td>Mêmes catégories de données que ci-dessus.</td>
            <td>
              Lors que nous contractons avec une personne morale: notre intérêt légitime à fournir
              nos services. Lors que nous contractons avec une personne physique: nécessité
              contractuelle.
            </td>
          </tr>
          <tr>
            <td>
              Afin d'effectuer des audits en matière fiscale et des réclamations fiscales
              administratives
            </td>
            <td>Mêmes catégories de données que ci-dessus, en fonction du périmètre de l'audit.</td>
            <td>
              Lors que nous contractons avec une personne morale: notre intérêt légitime à fournir
              nos services. Lors que nous contractons avec une personne physique: nécessité
              contractuelle.
            </td>
          </tr>
          <tr>
            <td>
              Missions de consulting, notamment en matière de statut fiscal spécial et en matière
              d'immigration
            </td>
            <td>
              Mêmes catégories de données que ci-dessus, en fonction du périmètre des missions.
            </td>
            <td>
              Lors que nous contractons avec une personne morale: notre intérêt légitime à fournir
              nos services. Lors que nous contractons avec une personne physique: nécessité
              contractuelle.
            </td>
          </tr>
          <tr>
            <td>
              - Pour l'exécution des transactions conclues avec nous, facturation pour les services
              aux clients et le recouvrement des dettes clients.
            </td>
            <td>Données d'identification, coordonnées, données financières.</td>
            <td>La nécessité contractuelle.</td>
          </tr>
          <tr>
            <td>
              - Gestion des clients (exécution de la Client Acceptation Procedure, établissement et
              gestion des lettres de missions, gestion des mandats envers les autorités et envers
              nos fournisseurs IT d'application comptables).
            </td>
            <td>
              Données d'identification, coordonnées, données financières, données concernant le
              foyer, données sur les caractéristiques personnelles.
            </td>
            <td>La nécessité contractuelle.</td>
          </tr>
          <tr>
            <td>- Pour la gestion des réclamations et des différends.</td>
            <td>
              Données d'identification, coordonnées, données comprises dans la documentation
              contractuelle.
            </td>
            <td>Notre intérêt légitime: la sauvegarde de nos droits et intérêts.</td>
          </tr>
          <tr>
            <td colSpan={3}>Traitements relatifs à nos fournisseurs</td>
          </tr>
          <tr>
            <td>
              Pour la gestion des fournisseurs et l'exécution des transactions conclues avec nous.
            </td>
            <td>Données d'identification, coordonnées, données financières.</td>
            <td>Nécessité contractuelle</td>
          </tr>
          <tr>
            <td>Pour la gestion des réclamations et des différends.</td>
            <td>
              Les données d'identification, telles que le nom, l'adresse (professionnelle ou
              privée), les numéros de téléphone, etc.
            </td>
            <td>Notre intérêt légitime: la sauvegarde de nos droits et intérêts.</td>
          </tr>
        </tbody>
      </Table>
      <p>
        2.2 Vous pouvez nous fournir directement certaines des Données personnelles listées
        ci-dessus. Cependant, nous pouvons également recevoir de tierces parties des Données
        personnelles qui vous concernent lorsque nous contractons en B2B avec des sociétés clientes
        afin de fournir certains services dont vous êtes le bénéficiaire (comme votre employeur ou
        la société dont vous êtes actionnaire ou dirigeant ou un intermédiaire dont vous êtes
        bénéficiaire).
      </p>
      <p>
        Les catégories de Données personnelles que nous collectons de tierces parties incluent vos
        données d'identification (nom, prénom), vos coordonnées, vos données professionnelles, et
        les données nécessaires à la fourniture des services dont vous êtes le bénéficiaire, telles
        que listées dans le tableau ci-dessus.
      </p>
      <p>
        2.3 Si nécessaire, les Données personnelles que nous traitons concernant les bénéficiaires
        de nos services peuvent contenir des informations sur les opinions politiques ou les
        convictions religieuses, la santé, l'appartenance syndicale, la commission ou la commission
        présumée d'infractions pénales et toutes actions légales et informations connexes (« Données
        personnelles Sensibles »). Nous minimiserons la quantité de Données personnelles Sensibles
        que nous traitons, et uniquement lorsqu'il est nécessaire pour nous conformer à nos
        obligations légales, par exemple anti-blanchiment, ou lorsque vous nous avez communiqué ces
        données en vue de fournir notre service fiscal.
      </p>
      <h4>3. Avec qui Tax Consult partage-t-il vos Données personnelles?</h4>
      <p>
        3.1 Nous pouvons transmettre vos Données personnelles aux catégories suivantes de
        destinataires :
      </p>
      <ul>
        <li>
          à nos fournisseurs tiers, et prestataires de services qui nous fournissent un service de
          traitement de données, ou qui traitent les Données personnelles à des fins décrites dans
          cet Avis, ou qui vous sont notifiées lors de la collecte de vos Données personnelles. Ces
          prestataires nous fournissent des services dans les domaines suivants : logiciel CRM,
          logiciels ou programmes fiscaux et comptables, programme de gestion des documents et
          services informatiques (notamment Admin Consult (Syneton). Cela inclut Emasphere,
          Winbooks, IController, ETC Tool, Dynamic Flows, Tax Consult SA, nos messageries
          électroniques).
        </li>
        <li>
          à tout organisme d'application de la loi compétent, régulateur, agence gouvernementale,
          tribunal, ou autre tiers, lorsque nous estimons que la divulgation est nécessaire (i) en
          vertu des lois ou règlements applicables, (ii) afin d'établir ou de défendre nos droits,
          ou (iii) afin de protéger vos intérêts vitaux ou ceux de toute autre personne. De plus,
          dans le cadre des services que nous fournissons, nous communiquons certaines Données
          personnelles aux autorités compétentes (par exemple en matière fiscale) ou à d'autres
          entités similaires (comme par exemple le Moniteur Belge, ou la Banque Carrefour des
          Entreprises);
        </li>
        <li>
          à nos auditeurs, conseillers, représentants légaux et agents similaires dans le cadre des
          services de conseil qu'ils nous fournissent à des fins commerciales légitimes et sous
          couvert d'une interdiction contractuelle d'utiliser les Données personnelles pour d'autres
          finalités ;
        </li>
        <li>
          à un acheteur potentiel (et à ses agents et conseillers), dans le cadre d'un projet
          d'achat, de fusion ou d'acquisition d'une partie de notre entreprise, à condition que nous
          informions l'acheteur qu'il ne doit utiliser vos Données personnelles que pour les
          finalités décrites dans cet Avis ;
        </li>
        <li>
          à toute autre personne dès lors que vous avez donné votre consentement préalable à la
          divulgation.{" "}
        </li>
      </ul>

      <h4>4. Comment nous protégeons votre vie privée </h4>
      <p>4.1 Conformément à cet Avis, nous traiterons les Données personnelles comme suit:</p>
      <ul>
        <li>
          Loyauté: Nous traiterons vos Données personnelles de manière loyale. Cela signifie que
          nous sommes transparents sur la façon dont nous traitons les Données personnelles et que
          nous les traiterons conformément aux lois applicables.
        </li>
        <li>
          Limitation de la finalité: Nous traiterons les Données personnelles pour des finalités
          déterminées et licites, et nous ne les traiterons pas d'une manière incompatible avec ces
          finalités.
        </li>
        <li>
          Proportionnalité: Nous traiterons les Données personnelles d'une manière proportionnée aux
          finalités auxquelles le traitement est censé aboutir.
        </li>
        <li>
          Exactitude des données: Nous prenons les mesures appropriées pour nous assurer que les
          Données personnelles que nous détenons sont exactes, complètes et, si nécessaire, tenues à
          jour. Cependant, il est également de votre responsabilité de veiller à ce que vos Données
          personnelles soient aussi précises, complètes et à jour que possible en informant Tax
          Consult de tout changement ou erreur.
        </li>
        <li>
          Sécurité des données: Nous utilisons des mesures techniques et organisationnelles
          appropriées pour protéger les Données personnelles que nous collectons et traitons à votre
          sujet. Les mesures que nous utilisons sont conçues pour offrir un niveau de sécurité
          adapté au risque de traitement de vos Données personnelles.
        </li>
        <li>
          Sous-traitants: Nous pouvons engager des tiers pour traiter les Données personnelles pour
          le compte et au nom de Tax Consult. Nous exigeons de ces sous-traitants qu'ils traitent
          les Données personnelles et agissent strictement selon nos instructions et qu'ils prennent
          les mesures appropriées pour garantir que les Données personnelles restent protégées.
        </li>
        <li>
          Transferts internationaux de données: Nos serveurs sont situés au sein de l'Espace
          Economique Européen (EEE). Cependant, nous faisons ponctuellement appel à des experts ou
          consultants externes établis en dehors de l'EEE. Cela signifie que lorsque nous collectons
          vos Données personnelles, nous pouvons les transférer dans le pays où est localisé
          l'expert ou le consultant auquel nous faisons appel. De tels transferts sont strictement
          nécessaires pour vous fournir les services contractuels convenus et à votre demande (par
          exemple, si votre dossier nécessite l'intervention d'un expert dans un pays tiers). De
          plus amples informations peuvent être fournies sur demande.
        </li>
        <li>
          Conservation des données: Nous conservons vos Données lorsque nous avons un besoin
          commercial légitime (par exemple afin de vous fournir un service que vous avez demandé ou
          pour nous conformer aux exigences légales, fiscales ou comptables applicables). Lorsque
          nous n'avons plus de besoin commercial légitime pour traiter vos Données personnelles,
          nous les supprimons ou les rendons anonymes, ou si ce n'est pas possible (par exemple, vos
          Données personnelles ont été stockées dans des archives de sauvegarde), nous les stockons
          en toute sécurité et les isolons de tout autre traitement jusqu'à ce que la surpression
          soit possible.
        </li>
      </ul>

      <h4>5. Vos droits à la protection des données</h4>
      <p>
        5.1 Vous disposez des droits à la protection des données suivants, que vous pouvez exercer à
        l'adresse <a href="mailto:privacy@taxconsult.be">privacy@taxconsult.be</a> :
      </p>
      <ul>
        <li>
          Vous pouvez accéder, corriger, mettre à jour ou demander la suppression de vos Données
          personnelles à tout moment ;
        </li>
        <li>
          En outre, dans certaines circonstances, vous pouvez vous opposer au traitement de vos
          Données personnelles, nous demander de limiter le traitement de vos Données personnelles,
          ou demander la portabilité de vos Données personnelles.
        </li>
        <li>
          Si nous avons collecté et traité vos Données personnelles avec votre consentement, vous
          pouvez retirer votre consentement à tout moment. Le retrait de votre consentement
          n'affectera pas la légalité de tout traitement que nous avons effectué avant ce retrait et
          n'affectera pas le traitement de vos Données personnelles effectué sur la base de
          fondements légaux autres que le consentement.
        </li>
        <li>
          Si vous avez une plainte ou une préoccupation concernant la façon dont nous traitons vos
          Données personnelles, nous nous efforcerons de répondre à ces préoccupations. Si vous
          estimez que nous n'avons pas suffisamment pris en compte votre plainte ou préoccupation,
          vous avez le droit de vous plaindre auprès d'une autorité de protection des données à
          propos la collecte et de l'utilisation que nous faisons de vos Données personnelles. Pour
          plus d'informations, veuillez contacter votre autorité locale de protection des données.
          (Les coordonnées des autorités de protection des données de l'Espace économique européen,
          de la Suisse et de certains pays non européens (y compris les États-Unis et le Canada)
          sont disponibles au lien suivant.)
        </li>
      </ul>

      <p>
        5.2 Nous répondons à toutes les demandes que nous recevons de personnes souhaitant exercer
        les droits relatifs à la protection de leurs Données personnelles conformément aux lois
        applicables en matière de protection des données.
      </p>

      <h4>6. Mise à jour du présent Avis</h4>
      <p>
        6.1 Nous pouvons mettre à jour cet Avis de temps à autre en réponse à l'évolution des
        développements juridiques, techniques ou commerciaux. Lorsque nous mettrons à jour notre
        Avis, nous prendrons les mesures appropriées pour vous en informer, compte tenu de
        l'importance des changements que nous apporterons. Nous obtiendrons votre consentement à
        tout changement important apporté à cet Avis si cela est requis par les lois sur la
        protection des données applicables.
      </p>
      <p>
        6.2 Vous pouvez voir la date de dernière mise à jour de cet Avis en vous reportant à la date
        de la "dernière mise à jour" affichée en haut de cet Avis.
      </p>
    </>
  );
};

export default PrivacyPolicy;
