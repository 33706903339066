import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_QUESTIONNAIRE_USER_DATA, ADD_NEW_QUESTIONNAIRE_USER_DATA, SUBMIT_QUESTIONNAIRE_USER_DATA, INCOMPLETE_QUESTIONNAIRE_USER_DATA, UPDATE_QUESTIONNAIRE_USER_STATUS } from "./actiontypes"
import {
    getQuestionnaireUserDataSuccess,
    getQuestionnaireUserDataFail,
    addQuestionnaireUserDataFail,
    addQuestionnaireUserDataSuccess,
    SubmitQuestionnaireUserDataSuccess,
    SubmitQuestionnaireUserDataFail,
    IncompleteQuestionnaireUserDataSuccess, 
    IncompleteQuestionnaireUserDataFail,
    updateQuestionnaireStatusUserSuccess,
    updateQuestionnaireStatusUserFail,
} from "./actions"

import { AnyAction } from 'redux'
import QuestionnaireUserDataService from "../../services/questionnaire.user.data.service";

function* fetchQuestionnaireUserData({ payload: { user_data, history } }: AnyAction): any {
    try {
        const response = yield call(QuestionnaireUserDataService.getQuestionnaireUserDataList, user_data)
        if (response && response.data && response.data.status === 'success') {
            yield put(getQuestionnaireUserDataSuccess(response.data))
        } else if (response && response.status === 'error') {
            yield put(getQuestionnaireUserDataFail(response))
        }else {
            yield put(getQuestionnaireUserDataFail(response))
        }
    } catch (error) {
        yield put(addQuestionnaireUserDataFail(error))
    }
}


function* onAddNewQuestionnaireUserData({ payload: data , history}: AnyAction): any {
    try {
        const response = yield call(QuestionnaireUserDataService.createQuestionnaireUserData, data)
        if (response && response.data && response.data.status === 'success') {
            yield put(addQuestionnaireUserDataSuccess(response.data))
        } else if (response && response.data && response.data.status === 'error') {
            yield put(addQuestionnaireUserDataFail(response.data))
        }else {
            yield put(addQuestionnaireUserDataFail(response))
        }
    } catch (error) {
        yield put(addQuestionnaireUserDataFail(error))
    }
}


function* onSubmitQuestionnaireUserData({ payload: data }: AnyAction): any {
    try {
        const response = yield call(QuestionnaireUserDataService.onSubmitQuestionnaireUserData, data)
        if (response && response.data && response.data.status === 'success') {
            yield put(SubmitQuestionnaireUserDataSuccess(response.data))
        } else if (response && response.data && response.data.status === 'error') {
            yield put(SubmitQuestionnaireUserDataFail(response.data))
        }else {
            yield put(SubmitQuestionnaireUserDataFail(response))
        }
    } catch (error) {
        yield put(SubmitQuestionnaireUserDataFail(error))
    }
}

function* onIncompleteQuestionnaireUserData({ payload: data }: AnyAction): any {
    try {
        const response = yield call(QuestionnaireUserDataService.incompleteQuestionnaireUserData, data)
        if (response && response.data && response.data.status === 'success') {
            yield put(IncompleteQuestionnaireUserDataSuccess(response.data))
        } else if (response && response.data && response.data.status === 'error') {
            yield put(IncompleteQuestionnaireUserDataFail(response.data))
        }else {
            yield put(IncompleteQuestionnaireUserDataFail(response))
        }
    } catch (error) {
        yield put(IncompleteQuestionnaireUserDataFail(error))
    }
}


function* onUpdateQuestionnaireUserStatus({ payload: data }: AnyAction): any {
    try {
        const response = yield call(QuestionnaireUserDataService.updateQuestionnaireUserStatus, data)
        if (response && response.data && response.data.status === 'success') {
           // yield put(updateQuestionnaireStatusUserSuccess(response.data))
        } else if (response && response.data && response.data.status === 'error') {
           // yield put(updateQuestionnaireStatusUserFail(response.data))
        }else {
            //yield put(updateQuestionnaireStatusUserFail(response))
        }
    } catch (error) {
       // yield put(updateQuestionnaireStatusUserFail(error))
    }
}



function* questionnaireUserdataSaga(): any {
    yield takeEvery(GET_QUESTIONNAIRE_USER_DATA, fetchQuestionnaireUserData)
    yield takeEvery(ADD_NEW_QUESTIONNAIRE_USER_DATA, onAddNewQuestionnaireUserData)
    yield takeEvery(SUBMIT_QUESTIONNAIRE_USER_DATA, onSubmitQuestionnaireUserData)
    yield takeEvery(INCOMPLETE_QUESTIONNAIRE_USER_DATA, onIncompleteQuestionnaireUserData)
    yield takeEvery(UPDATE_QUESTIONNAIRE_USER_STATUS, onUpdateQuestionnaireUserStatus)
}

export default questionnaireUserdataSaga
