import React, { useEffect, useState } from 'react'
import { Button, CardFooter, Col, Container, Form, Label, Row } from 'reactstrap'
import { addAllUsersInUsers, addIndividualInCompany, getDependentSelectOptions, getUserProfile } from '../../../helpers/functions_helper';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import labelPlugin from "flatpickr/dist/plugins/labelPlugin/labelPlugin";
import { useFormik } from 'formik';

import * as Yup from "yup";
import Drawer from '../../../components/Drawer';
import NewUser from '../../Users/NewUser';
import { CommonAction } from '../../../store/actions';
import { useDispatch, useSelector } from "react-redux";
import Select from '../../../components/Form/Select/Select';
import Flatpickr from '../../../components/Form/Flatpickr';
import { intialData } from '../../Tasks/Charts/StatusWiseTaskCount';
import Input from '../../../components/Form/Input';
import SelectNew from "react-select";
const SendDocument = (props: any) => {
    const { t } = useTranslation();
    const templateData = props.templateData;
    const [loadingUsers, setLoadingUsers] = useState(true);
    const [usersOptions, setUsersOptions] = useState([]);
    const userProfile = getUserProfile();
    const [companyID, setCompanyID] = useState();
    const dispatch = useDispatch();

    const [openUserDrawer, setOpenUserDrawer] = useState<boolean>(false);
    const [initialData, setInitialData] = useState({
        company_id: "",
        user_id: null,
        doc_signature_id: templateData?.id,
        document_name: templateData.document_name,
        template_id: templateData?.id,
        due_date: "",
        expiry_date: "",
        document_status: props.type,
        contact_person_id: "",
        doc_status: props.type,
        multiple_user_id: templateData?.multiple_user_id,
        user_id_cc: templateData?.user_id_cc,
    });
    useEffect(() => {
        if (props.initialData?.id) {
            setInitialData(props.initialData);
            const values = props.initialData;
            handleChange('document_name', values.filename);
            handleChange('user_id', values.contact_person_id);
            handleChange('contact_person_id', values.contact_person_id);
            setCompanyID(props.initialData.company_id);
        }
    }, [props.initialData])

    let { getCompanyOptionsList, getUserOptionsList } = useSelector((state: any) => ({
        getCompanyOptionsList: state.Common.GET_COMPANY_OPTIONS_LIST_SUCCESS_NEW,
        getUserOptionsList: state.Common.GET_USER_OPTIONS_LIST_SUCCESS_NEW,
    }));

    useEffect(() => {
        if (!getCompanyOptionsList) dispatch(CommonAction.getCompanyOptionsList_New());
        if (!getUserOptionsList) dispatch(CommonAction.getUserOptionsList_New());
    }, []);

    const [loadingCompany, setLoadingCompany] = useState(true);
    const [companyOption, setCompanyOptions] = useState([]);
    useEffect(() => {
        if (getCompanyOptionsList.data) {
            const item = addIndividualInCompany(getCompanyOptionsList.data) as any;
            setCompanyOptions(item);
            setLoadingCompany(false);
        }
    }, [getCompanyOptionsList]);

    useEffect(() => {
        if (companyID) {
            getDependentSelectOptions("tbl_master_users", "first_name", "company", companyID).then(function (
                options
            ) {
                const item = addAllUsersInUsers(options) as any;
                setUsersOptions(item);
                setLoadingUsers(false);
            });
        } else {
            const company_id = userProfile.USER_COMPANY;
            getDependentSelectOptions("tbl_master_users", "first_name", "company", company_id).then(function (
                options
            ) {
                const item = addAllUsersInUsers(options) as any;
                setUsersOptions(item);
                setLoadingUsers(false);
            });
        }
    }, [companyID, openUserDrawer]);

    const [usersList, setUsersList] = useState<any>([]);
    useEffect(() => {
        if (usersOptions) {
            const Options = usersOptions?.map((option: any) => ({
                label: t(option.label),
                value: option.value
            }));
            setUsersList(Options);
        }
    }, [usersOptions, localStorage.getItem("I18N_LANGUAGE")])

    const handleChange = (fieldName: string | any, fieldValue: any) => {
        setInitialData((preState) => ({ ...preState, [fieldName]: fieldValue }));
    };


    const validationSchema = Yup.object().shape({
        company_id: Yup.number().required("Client field is required."),
        user_id: Yup.number().nullable().required("To field is required."),
        document_name: Yup.string().required("Document name field is required."),
        expiry_date: Yup.string().required("Expiry date field is required."),
        due_date: Yup.string().required("Due date field is required."),
    });

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: initialData,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            props.create(values);
        },
    });

    // Create user
    const createUser = (type: number) => {
        setOpenUserDrawer(true);
    };


    const handleUserSave = (user: any) => {
        setOpenUserDrawer(false);
    };

    return (
        <Container fluid>
            <Form
                onSubmit={(e: any) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
                className="row gy-2 gx-3 align-items-center"
            >
                <Row>

                    <Col>
                        <div className="mb-3">
                            {loadingCompany ? <Input id={`ph_cmp`} disabled value={'Loading...'} /> :
                                <Select
                                    id="company_id"
                                    name="company_id"
                                    className="is-invalid"
                                    options={companyOption}
                                    required
                                    label='Client'
                                    value={
                                        initialData.company_id
                                            ? companyOption.find(
                                                (option: any) => option.value == initialData.company_id
                                            )
                                            : ""
                                    }
                                    isSearchable={true}
                                    isDisabled={loadingCompany}
                                    onBlur={() => validation.setFieldTouched("company_id", true)}
                                    onChange={(option: any) => {
                                        setCompanyID(option.value);
                                        handleChange('company_id', option.value);
                                    }}
                                    error={validation.errors.company_id}
                                    touched={validation.touched.company_id}
                                />
                            }
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="mb-3">
                            <Select
                                options={usersList}
                                name="user_id"
                                required
                                label='To'
                                value={companyOption.find((o: any) => o.value === initialData?.user_id)}
                                placeholder={t("Select...")}
                                isSearchable={true}
                                //isMulti={true}
                                isDisabled={loadingUsers}
                                isLoading={loadingUsers}
                                onChange={(option: any) => {
                                    handleChange('user_id', option.value);
                                }}
                                error={validation.errors.user_id}
                                touched={validation.touched.user_id}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="mb-3">
                            <Label className="form-label w-100">{t('Cc')}</Label>
                            <div className="input-group is-invalid">
                                <SelectNew
                                    options={usersList}
                                    name="user_id_cc"
                                    className="input-group-select is-invalid"
                                    value={companyOption.find((o: any) => o.value === initialData?.user_id_cc)}
                                    placeholder={t("Select...")}
                                    isSearchable={true}
                                    isMulti={true}
                                    isDisabled={loadingUsers}
                                    isLoading={loadingUsers}
                                    onChange={(option: any) => {
                                        handleChange('user_id_cc', option);
                                    }}
                                />
                                <Button
                                    color="primary"
                                    className="input-group-text btn-sm"
                                    title={t("Create user")}
                                    onClick={() => createUser(3)}
                                >
                                    <span className="align-middle material-symbols-outlined fw-light fs-4">
                                        person_add
                                    </span>
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="mb-3">
                            <Input
                                type="text"
                                name="document_name"
                                label="Document name"
                                required
                                value={initialData.document_name}
                                className="form-control"
                                placeholder="Subject"
                                onChange={(e) => handleChange('document_name', e.target.value)}
                                error={validation.errors.document_name}
                                touched={validation.touched.document_name}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6} lg={6} md={6} sm={12}>
                        <div className="input-group is-invalid">
                            <Flatpickr
                                id="due_date"
                                name="due_date"
                                label='Due date'
                                className="form-control d-block"
                                type="text"
                                value={initialData.due_date}
                                placeholder="DD/MM/YYYY"
                                required
                                options={{
                                    altInput: true,
                                    altFormat: "d/m/Y",
                                    dateFormat: "Y-m-d H:i:S",
                                    minDate: "today",
                                    allowInput: true,
                                    plugins: [labelPlugin()],
                                    onChange: function (
                                        selectedDates,
                                        dateStr,
                                        instance
                                    ) {
                                        handleChange("due_date", dateStr);
                                    },
                                    onClose: function (
                                        selectedDates,
                                        dateStr,
                                        instance
                                    ) {
                                        handleChange("due_date", dateStr);
                                    },
                                    locale: { firstDayOfWeek: 1 },
                                }}
                                error={validation.errors.due_date}
                                touched={validation.touched.due_date}
                            />
                        </div>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12}>
                        <div className="input-group is-invalid">
                            <Flatpickr
                                id="expiry_date"
                                name="expiry_date"
                                className="form-control d-block"
                                type="text"
                                label='Expiry date'
                                value={initialData.expiry_date}
                                placeholder="DD/MM/YYYY"
                                required
                                options={{
                                    altInput: true,
                                    altFormat: "d/m/Y",
                                    dateFormat: "Y-m-d H:i:S",
                                    allowInput: true,
                                    minDate: intialData.due_date ? initialData.due_date : "today",
                                    plugins: [labelPlugin()],
                                    onChange: function (
                                        selectedDates,
                                        dateStr,
                                        instance
                                    ) {
                                        handleChange("expiry_date", dateStr);
                                    },
                                    onClose: function (
                                        selectedDates,
                                        dateStr,
                                        instance
                                    ) {
                                        handleChange("expiry_date", dateStr);
                                    },
                                    locale: { firstDayOfWeek: 1 },
                                }}
                                error={validation.errors.expiry_date}
                                touched={validation.touched.expiry_date}
                            />
                        </div>
                    </Col>

                </Row>
                <CardFooter className="d-flex flex-wrap gap-2 bg-transparent border-top justify-content-end">
                    <Button
                        color="primary"
                        className="btn-block mt-3"
                        type="submit"
                    >
                        <span>{props.title}</span>
                    </Button>
                    <Link
                        to="#"
                        onClick={() => props.setIsComposeMail(false)}
                    >
                        <Button
                            color="danger"
                            className="btn-block mt-3"
                            type="button"
                        >

                            {t("Cancel")}
                        </Button>
                    </Link>
                </CardFooter>
            </Form>
            {/* <ComposeMail
                data={emailData}
                composeType={mailComposeType}
                handleChange={() => setIsComposeMail(false)}
            /> */}


            <Drawer
                title={t("Create User")}
                open={openUserDrawer}
                onClose={() => setOpenUserDrawer(false)}
                fullWidth
            >
                <NewUser company={initialData?.company_id} inTab={true} onSave={(data: any) => handleUserSave(data)} />
            </Drawer>
        </Container>
    )
}

export default SendDocument