import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class FeesService {
    async getFee(DATA: any) {
        const response = await axios
            .get(SERVER_URL + url.GET_FEE + "/" + DATA.id + "/" + DATA.fee_id,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async getFeesList(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_FEES_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async createFee(DATA: any) {
        const response = await axios
            .put(SERVER_URL + url.CREATE_FEE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async updateFee(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.UPDATE_FEE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async deleteFee(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.DELETE_FEE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }
}

export default new FeesService();