import {
  GET_EMAIL_TEMPLATE_CATEGORY,
  GET_EMAIL_TEMPLATE_CATEGORY_SUCCESS,
  GET_EMAIL_TEMPLATE_CATEGORY_ERROR,

  GET_EMAIL_TEMPLATE_CATEGORY_LIST,
  GET_EMAIL_TEMPLATE_CATEGORY_LIST_SUCCESS,
  GET_EMAIL_TEMPLATE_CATEGORY_LIST_ERROR,

  CREATE_EMAIL_TEMPLATE_CATEGORY,
  CREATE_EMAIL_TEMPLATE_CATEGORY_SUCCESS,
  CREATE_EMAIL_TEMPLATE_CATEGORY_ERROR,

  CREATE_DUPLICATE_EMAIL_TEMPLATE_CATEGORY,
  CREATE_DUPLICATE_EMAIL_TEMPLATE_CATEGORY_SUCCESS,
  CREATE_DUPLICATE_EMAIL_TEMPLATE_CATEGORY_ERROR,


  UPDATE_EMAIL_TEMPLATE_CATEGORY,
  UPDATE_EMAIL_TEMPLATE_CATEGORY_SUCCESS,
  UPDATE_EMAIL_TEMPLATE_CATEGORY_ERROR,

  DELETE_EMAIL_TEMPLATE_CATEGORY,
  DELETE_EMAIL_TEMPLATE_CATEGORY_SUCCESS,
  DELETE_EMAIL_TEMPLATE_CATEGORY_ERROR,
} from "./actionTypes";

// ---------------------------------------------GET_TASK_TYPE
export const getEmailCategory = (emailCategory: any, history: any) => {
  return {
    type: GET_EMAIL_TEMPLATE_CATEGORY,
    payload: { emailCategory, history },
  };
};

export const getEmailCategorySuccess = (data: {}) => {
  return {
    type: GET_EMAIL_TEMPLATE_CATEGORY_SUCCESS,
    payload: data,
  };
};

export const getEmailCategoryError = (error: {}) => {
  return {
    type: GET_EMAIL_TEMPLATE_CATEGORY_ERROR,
    payload: error,
  };
};

// ---------------------------------------------GET_TASK_TYPES_LIST
export const getEmailCategoryList = (query: any, history: any) => {
  return {
    type: GET_EMAIL_TEMPLATE_CATEGORY_LIST,
    payload: {query, history},
  };
};

export const getEmailCategoryListSuccess = (data: {}) => {
  return {
    type: GET_EMAIL_TEMPLATE_CATEGORY_LIST_SUCCESS,
    payload: data,
  };
};

export const getEmailCategoryListError = (error: {}) => {
  return {
    type: GET_EMAIL_TEMPLATE_CATEGORY_LIST_ERROR,
    payload: error,
  };
};

// ---------------------------------------------CREATE_EMAIL_CATEGORY
export const createEmailCategory = (emailCategory: any, history: any) => {
  console.log(emailCategory);
  return {
    type: CREATE_EMAIL_TEMPLATE_CATEGORY,
    payload: { emailCategory, history },
  };
};

export const createEmailCategorySuccess = (emailCategory: {}) => {
  return {
    type: CREATE_EMAIL_TEMPLATE_CATEGORY_SUCCESS,
    payload: emailCategory,
  };
};

export const createEmailCategoryError = (error: {}) => {
  return {
    type: CREATE_EMAIL_TEMPLATE_CATEGORY_ERROR,
    payload: error,
  };
};


// ---------------------------------------------CREATE_DUPLICATE_EMAIL_CATEGORY
export const createDuplicateEmailCategory = (emailCategory: any, history: any) => {
  return {
    type: CREATE_DUPLICATE_EMAIL_TEMPLATE_CATEGORY,
    payload: { emailCategory, history },
  };
};

export const createDuplicateEmailCategorySuccess = (emailCategory: {}) => {
  return {
    type: CREATE_DUPLICATE_EMAIL_TEMPLATE_CATEGORY_SUCCESS,
    payload: emailCategory,
  };
};

export const createDuplicateEmailCategoryError = (error: {}) => {
  return {
    type: CREATE_DUPLICATE_EMAIL_TEMPLATE_CATEGORY_ERROR,
    payload: error,
  };
};

// ---------------------------------------------UPDATE_TASK_TYPE
export const updateEmailCategory = (emailCategory: any, history: any) => {
  return {
    type: UPDATE_EMAIL_TEMPLATE_CATEGORY,
    payload: { emailCategory, history },
  };
};

export const updateEmailCategorySuccess = (emailCategory: {}) => {
  return {
    type: UPDATE_EMAIL_TEMPLATE_CATEGORY_SUCCESS,
    payload: emailCategory,
  };
};

export const updateEmailCategoryError = (error: {}) => {
  return {
    type: UPDATE_EMAIL_TEMPLATE_CATEGORY_ERROR,
    payload: error,
  };
};

// ---------------------------------------------DELETE_TASK_TYPE
export const deleteEmailCategory = (emailCategory: any, history: any) => {
  return {
    type: DELETE_EMAIL_TEMPLATE_CATEGORY,
    payload: { emailCategory, history },
  };
};

export const deleteEmailCategorySuccess = (emailCategory: {}) => {
  return {
    type: DELETE_EMAIL_TEMPLATE_CATEGORY_SUCCESS,
    payload: emailCategory,
  };
};

export const deleteEmailCategoryError = (error: {}) => {
  return {
    type: DELETE_EMAIL_TEMPLATE_CATEGORY_ERROR,
    payload: error,
  };
};
