import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Nav, NavItem, Row } from "reactstrap";
import styled from 'styled-components';
import Breadcrumbs from "../../components/Layout/Breadcrumb";
import { getUserProfile, widgetAccess } from "../../helpers/functions_helper";
import QuestionModal from "./QuestionnaireCreate";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { QuestionnaireAction } from "../../store/actions";
import Counts from "./Charts/Counts";
import AllTypes from "./Charts/AllTypes";
import Status from "./Charts/Status";
import YearData from "./Charts/YearData";
import MoreCharts from "./Charts/MoreCharts";
import GraphFilters from '../../components/Common/GraphFilters';
const CustomCard = styled(Card)`
    height: calc(100% - 24px);
`;

const QuestionnaireGraphicalView = () => {

    const userProfile = getUserProfile("all-questionnaires");
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const toastId = React.useRef(null) as any;
    document.title = "Questionnaire - Graphical view | " + process.env.REACT_APP_NAME;


    const breadcrumbItems = [
        {
            url: "/dashboard",
            label: t("Dashboard"),
            active: false,
        },
        {
            url: "/questionnaires/all-questionnaires",
            label: t("Questionnaires"),
            active: true,
        },
    ];

    const inititalFilter = {
        week: null,
        quarter: null,
        month: null,
        year: null,
        user_id: !(userProfile.IS_PARTNER && userProfile.IS_SUPER_ADMIN) ? userProfile.USER_ID : null
    };

    const [loading, setLoading] = useState<boolean>(true);
    const [filter, setFilter] = useState<any>(inititalFilter);

    const clearWorkAllocationFilter = () => {
        setFilter(inititalFilter);
    }

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        breadcrumbTitle={t("Questionnaire")}
                        breadcrumbItems={breadcrumbItems}
                        endAdornment={
                            <div className="d-flex gap-2 text-end">
                                <Link to="/questionnaires/all-questionnaires" className={`btn btn-outline-secondary btn-label btn-sm`}>
                                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                        list_alt
                                    </span>
                                    List view
                                </Link>

                                <Link to="/questionnaires/graph" className={`btn btn-info btn-label btn-sm`}>
                                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                        insert_chart
                                    </span>
                                    {t("Graphical view")}
                                </Link>

                                {userProfile.CAN_CREATE ? (
                                    <Link to="/questionnaires/create-questionnaire" className="btn btn-primary btn-label btn-sm">
                                        <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                            add
                                        </span>{" "}
                                        {t("Create questionnaire")}
                                    </Link>
                                ) : null}
                            </div>
                        }
                    />
                    {widgetAccess('Questionnaire_Counts') &&
                        <Row>
                            <Col>
                                <div className="mt-3">
                                    <Row>
                                        <Counts filters={filter} />
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    }
                       <Row>
              <Col>
                <div className="d-sm-flex flex-wrap mt-1 mb-3">
                  <p></p>
                  <div className="ms-auto">
                    <GraphFilters
                      filter={filter}
                      setFilter={setFilter}
                      clearWorkAllocationFilter={clearWorkAllocationFilter}
                      enableUser={true}
                      enableCompany={true}
                      userObj={userProfile}
                    //isCompareMerticsEnable
                    />
                  </div>
                </div>
              </Col>
            </Row>
                    <Row>
                        {widgetAccess('Questionnaire_Type') &&
                            <Col xl="12">
                                <CustomCard>
                                    <CardBody>
                                        <AllTypes 
                                        filters={filter}
                                        />
                                    </CardBody>
                                </CustomCard>
                            </Col>
                        }
                    </Row>
                    <Row>
                        {widgetAccess('Sent_Vs_Finalized') &&
                            <Col xl="6">
                                <CustomCard>
                                    <CardBody>
                                        <Status 
                                         filters={filter}
                                        />
                                    </CardBody>
                                </CustomCard>
                            </Col>
                        }
                        {widgetAccess('Resident_Vs_Non-resident') &&
                            <Col xl="6">
                                <CustomCard>
                                    <CardBody>
                                        <MoreCharts 
                                         filters={filter}
                                        />
                                    </CardBody>
                                </CustomCard>
                            </Col>
                        }
                    </Row>

                    <Row>
                        {widgetAccess('Questionnaire_type_per_year') &&
                            <Col xl="12">
                                <CustomCard>
                                    <CardBody>
                                        <YearData 
                                        filters={filter}
                                        />
                                    </CardBody>
                                </CustomCard>
                            </Col>
                        }
                    </Row>

                </Container>
            </div>
        </>
    )
}

export default QuestionnaireGraphicalView;