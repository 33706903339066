import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class SubTasksService {
    async getSubTask(DATA: any) {
        const response = await axios
            .get(SERVER_URL + url.GET_SUB_TASK + "/" + DATA.id + "/" + DATA.subtask_id,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async getSubTasksList(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_SUB_TASKS_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async createSubTask(DATA: any) {
        const response = await axios
            .put(SERVER_URL + url.CREATE_SUB_TASK, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async updateSubTask(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.UPDATE_SUB_TASK, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async deleteSubTask(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.DELETE_SUB_TASK, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async updateMultipleSubTaskStatus(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.UPDATE_MUTIPLE_SUB_TASK_STATUS, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }


    async getSubTasksResposiblePersonList(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_SUB_TASK_LIST_R_PERSON, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async getSubtasksExport(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_SUB_TASKS_EXPORT, DATA,
                {
                    headers: getAxiosConfigWithToken(),
                    responseType: "blob",
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

}

export default new SubTasksService();