import React, { FC, useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Input, Label, Nav, NavItem, Row } from "reactstrap";
import Flatpickr from "react-flatpickr";
import {
  addIndividualInCompany,
  getDependentMultipleWhereSelectOptions,
  getDependentSelectOptions,
  getSelectOptions,
  getUserProfile,
  select2Styles,
  yearsBackword,
} from "../../../helpers/functions_helper";
// Actions
import { CommonAction } from "../../../store/actions";
import {
  AttributOptions,
  IssueCategories,
  Priority,
  clientstatusOptions,
  incident_status,
  statusOptions,
  taxRegimeMaster,
} from "../../../common/staticdata";
import { useTranslation } from "react-i18next";
import { Month } from "../../../utils/data";
import { Link } from "react-router-dom";
import Select from "../../../components/Form/Select/Select";
import DatePicker from "../../../components/Form/DatePicker/DatePicker";

type FiltersProps = {
  filters: any;
  onApply: any;
  onClear: any;
  onChange?: any;
  company_id?: any;
};

const Filters: FC<FiltersProps> = ({ filters, onApply, onClear, onChange, company_id }) => {
  const dispatch = useDispatch();
  const userProfile = getUserProfile("all-users");
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const [loadingCompanyOptions, setLoadingCompanyOptions] = useState(true);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [companyID, setCompanyID] = useState<any>(filters?.company_id);
  const [disabledCompanyUserOption, setDisabledCompanyUserOption] = useState(true);
  const [loadingCompanyUserOption, setloadingCompanyUserOption] = useState(false);
  const [usersOptions, setUsersOptions] = useState([]);
  const [isConstom, setCostom] = useState<boolean>(false);
  const years = useMemo(() => yearsBackword(), []);
  const [quarters, setQuarters] = useState<any>([]);
  const [type, setType] = useState<string>(!company_id ? "details" : "general");
  const [taxReturnTypeOption, setTaxReturnTypeOption] = useState<any>([]);
  const YesNoMaster = [
    {
      label: "Yes",
      value: "1",
    },
    {
      label: "No",
      value: "0",
    },
  ];
  const [vatcatOptions, setVatcatOptions] = useState([]);
  const TVW_options = [
    {
      label: "Null",
      value: "null",
    },
    {
      label: "Not null",
      value: "not_null",
    },
  ];

  const activeOptions = [
    {
      label: "Active",
      value: 1,
    },
    {
      label: "Non Active",
      value: "0",
    },
  ]

  const WeekOption = [
    // { label: `Today`, value: `today` },
    { label: `Last Week`, value: `lastweek` },
    { label: `This Week`, value: `week` },
  ];

  useEffect(() => {
    if (!userProfile.CAN_VIEW_ALL) {
      setCompanyID(userProfile.USER_COMPANY);
    }
  }, []);

  useEffect(() => {
    setQuarters([
      { label: `Q1-${filters.year}`, value: `q1-${filters.year}` },
      { label: `Q2-${filters.year}`, value: `q2-${filters.year}` },
      { label: `Q3-${filters.year}`, value: `q3-${filters.year}` },
      { label: `Q4-${filters.year}`, value: `q4-${filters.year}` },
    ]);
  }, [filters.year]);

  let { getCompanyOptionsList, loading } = useSelector((state: any) => ({
    getCompanyOptionsList: state.Common.GET_COMPANY_OPTIONS_LIST_SUCCESS,
    loading: state.Common.loading,
  }));

  // Get options list -------------------------------------------------
  useEffect(() => {
    if (!getCompanyOptionsList) dispatch(CommonAction.getCompanyOptionsList());
    getSelectOptions("tbl_vat_cat", "vatcat_name").then(function (options) {
      setVatcatOptions(options);
    });
  }, []);

  useEffect(() => {
    if (getCompanyOptionsList.data) {
      const item = addIndividualInCompany(getCompanyOptionsList.data) as any;
      if (userProfile.CAN_VIEW_ALL) {
        setCompanyOptions(item);
      } else if (!userProfile.CAN_VIEW_ALL && userProfile.CAN_VIEW) {
        getSelectOptions("tbl_master_company", "company_name", '', ["created_by"]).then(
          function (options) {
            const opt = options.filter((opt: any) => (opt.created_by === userProfile.USER_ID || opt.value === userProfile.USER_COMPANY));
            setCompanyOptions(opt);
          });
      } else {
        setCompanyOptions([]);
      }

      setLoadingCompanyOptions(false);
    }
  }, [getCompanyOptionsList]);

  useEffect(() => {
    if (filters.status) {
      getDependentSelectOptions(
        "tbl_master_tax_return_type",
        "tax_return_type_value",
        "questionnaire_type",
        filters.status,
        ''
      ).then(function (options) {
        setTaxReturnTypeOption(options);
      });
    }
  }, [filters.status]);

  useEffect(() => {
    setUsersOptions([]);
    // onChange({ user_id: "" });
    setDisabledCompanyUserOption(true);
    setloadingCompanyUserOption(true);
    getDependentMultipleWhereSelectOptions("tbl_master_users", "first_name", ["company"], [1], '', ["tbl_master_users.created_by"]).then(
      function (options) {
        if (userProfile.CAN_VIEW_ALL) {
          setUsersOptions(options);
        } else if (!userProfile.CAN_VIEW_ALL && userProfile.CAN_VIEW) {
          const opt = options.filter((opt: any) => (opt.created_by === userProfile.USER_ID || opt.value === userProfile.USER_ID));
          setUsersOptions(opt);
        } else {
          setUsersOptions([]);
        }
        setDisabledCompanyUserOption(false);
        setloadingCompanyUserOption(false);
      }
    );
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <Select
            name={t("issue_type")}
            id={t("issue_type")}
            label={t("Issue type")}
            className="mb-3"
            options={IssueCategories?.map((a: any) => ({ ...a, label: t(a.label) }))}
            value={
              filters.issue_type
                ? IssueCategories.find(
                  (option: any) => option.value == filters.issue_type
                )
                : ""
            }
            onChange={(option: any) => {
              onChange({
                is_filtered: true,
                issue_type: option.value,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          {/* {JSON.stringify(filters)} */}
          <Select
            options={usersOptions}
            label={t("Reported user")}
            value={
              filters?.user_id
                ? usersOptions.find((option: any) => option.value == filters?.user_id)
                : ""
            }
            isSearchable={true}
            loading={loadingCompanyUserOption || usersOptions.length <= 0}
            onChange={(option: any) => {
              onChange({
                is_filtered: true,
                user_id: option.value,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            name={"priority"}
            id={"priority"}
            label={t("Priority")}
            className="mb-3"
            options={Priority?.map((a: any) => ({ ...a, label: t(a.label) }))}
            value={
              filters.priority
                ? Priority.find(
                  (option: any) => option.value == filters.priority
                )
                : ""
            }
            isSearchable={true}
            onChange={(option: any) => {
              onChange({
                is_filtered: true,
                priority: option.value,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Select
            options={incident_status?.map((a: any) => ({ ...a, label: t(a.label) }))}
            label={t("Status")}
            name="status"
            id="status"
            className="mb-3"
            value={
              filters?.status
                ? incident_status.find((option: any) => option.value == filters?.status)
                : ""
            }
            isSearchable={true}
            onChange={(option: any) => {
              onChange({
                is_filtered: true,
                status: option.value,
              });
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <DatePicker
            id="created_date_from"
            name="created_date_from"
            label={t("Created from")}
            className="form-control d-block"
            type="text"
            placeholder={t("Created date")}
            value={filters?.created_date_from}
            options={{
              altInput: true,
              altFormat: "d/m/Y",
              dateFormat: "Y-m-d H:i:S",
              defaultDate: filters.created_date_from,
              allowInput: true,
              onChange: function (selectedDates, dateStr, instance) {
                onChange({ is_filtered: true, created_date_from: dateStr });
              },
              locale: { firstDayOfWeek: 1 },
            }}
          />
        </Col>
        <Col>
          <DatePicker
            id="created_date_to"
            name="created_date_to"
            label={t("to")}
            className="form-control d-block"
            type="text"
            placeholder={t("Created date")}
            value={filters?.created_date_to}
            options={{
              altInput: true,
              altFormat: "d/m/Y",
              dateFormat: "Y-m-d H:i:S",
              defaultDate: filters.created_date_to,
              allowInput: true,
              onChange: function (selectedDates, dateStr, instance) {
                onChange({ is_filtered: true, created_date_to: dateStr });
              },
              locale: { firstDayOfWeek: 1 },
            }}
          />
        </Col>
      </Row>


    </Container>
  );
};

export default Filters;
