import React, { Component, ReactNode } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Breadcrumb, BreadcrumbItem, Button } from "reactstrap";

class Breadcrumbs extends Component<any, any> {
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col xs="12" mb={2}>
            <Breadcrumb listClassName="p-0 m-0">
              {this.props.breadcrumbItems.map(
                (breadcrumbItem: any, idx: number) =>
                  !breadcrumbItem?.hidden && (
                    <BreadcrumbItem key={idx} active={breadcrumbItem.active}>
                      <Link to={breadcrumbItem.url}> {breadcrumbItem.label}</Link>
                    </BreadcrumbItem>
                  )
              )}
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col xs="12">
            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
              <h4 className="mb-0 font-size-18">{this.props.breadcrumbTitle}</h4>
              <div className="page-title-right">{this.props?.endAdornment}</div>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

interface Breadcrumbs {
  breadcrumbTitle: string;
  breadcrumbItems: any;
  endAdornment?: any | ReactNode;
}

export default Breadcrumbs;
