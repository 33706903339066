import moment from "moment";
import { toast } from "react-toastify";
import * as Yup from "yup";
import newsFeedService from "../../../services/newsFeed.service";

export const publicationOptions = [
    {
        value: 'Internal',
        label: 'Internal'
    },
    {
        value: 'External',
        label: 'External'
    },
    {
        value: 'All recipients',
        label: 'All recipients'
    },
];

export const priorityOptions = [
    {
        value: 'High',
        label: 'High'
    },
    {
        value: 'Medium',
        label: 'Medium'
    },
    {
        value: 'Low',
        label: 'Low'
    },
]

export const validationSchema = Yup.object().shape({
    news_feed_title: Yup.string().nullable().required("News Feed Title field is required."),
    description: Yup.string().nullable().required("Description field is required."),
    publication: Yup.string().nullable().required("Publication field is required."),
    priority: Yup.string().nullable().required("Priority field is required."),
    hyperlink: Yup.string().nullable().matches(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
        'Enter valid url!'
    ),
    sources: Yup.string().nullable().matches(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
        'Enter valid url!'
    ),
});

export const intialValues = {
    news_feed_title: "",
    description: "",
    attachment: null,
    attachment_id: 0,
    publication: "",
    hyperlink: "",
    news_feed_creation_date: moment(new Date()).format('YYYY-MM-DD'),
    priority: "",
    sources: "",
    created_by: 0,
}

export const createNewsFeed = async (values: NewsFeedType) => {
    try {
        const result = await newsFeedService.create(values);
        if (result.status === 'success') {
            toast(result.message, { type: toast.TYPE.SUCCESS })
            return result;
        } else {
            toast(result.message, { type: toast.TYPE.ERROR })
        }
    } catch (error: any) {
        toast(error?.message, { type: toast.TYPE.ERROR })
    }
}

export const updateNewsFeed = async (values: NewsFeedType) => {
    try {
        const result = await newsFeedService.update(values);
        if (result.status === 'success') {
            toast(result.message, { type: toast.TYPE.SUCCESS })
            return result;
        } else {
            toast(result.message, { type: toast.TYPE.ERROR })
        }
    } catch (error: any) {
        toast(error?.message, { type: toast.TYPE.ERROR })
    }
}

export const getAllNewsFeeds = async (data: any) => {
    try {
        const result = await newsFeedService.getAll(data);
        if (result.data.status === 'success') {
            return result;
        } else {
            toast(result.data.message, { type: toast.TYPE.ERROR })
        }
    } catch (error: any) {
        toast(error?.message, { type: toast.TYPE.ERROR })
    }
}

export const getNewsFeedList = async () => {
    try {
        const result = await newsFeedService.getList();
        if (result.data.status === 'success') {
            return result;
        } else {
            toast(result.data.message, { type: toast.TYPE.ERROR })
        }
    } catch (error: any) {
        toast(error?.message, { type: toast.TYPE.ERROR })
    }
}

export const getNewsFeed = async (id: number) => {
    try {
        const result = await newsFeedService.get(id);
        if (result.status === 'success') {
            return result;
        } else {
            toast(result.message, { type: toast.TYPE.ERROR })
        }
    } catch (error: any) {
        toast(error?.message, { type: toast.TYPE.ERROR })
    }
}

export const deleteNewsFeed = async (id: number) => {
    try {
        const result = await newsFeedService.delete(id);
        if (result.status === 'success') {
            toast(result.message, { type: toast.TYPE.SUCCESS })
            return result;
        } else {
            toast(result.message, { type: toast.TYPE.ERROR })
        }
    } catch (error: any) {
        toast(error?.message, { type: toast.TYPE.ERROR })
    }
}
