import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Badge,
  Nav,
  NavItem,
} from "reactstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Flatpickr from "react-flatpickr";

import { dateFormat, formatCurrency } from "../../utils/formats";
import {
  timeToSeconds,
  secondsToTime,
  subString,
  masterValueWithColorCode,
} from "../../utils/utils";
import Breadcrumbs from "../../components/Layout/Breadcrumb";
import DeleteModal from "../../components/Common/DeleteModal";
import DataTable from "../../components/Common/DataTable";
import Filters from "./Filters";
import {
  datePickerI18,
  dateTimeString,
  getDataTableQuery,
  getUserProfile,
  handleFilters,
  moduleAccess,
  wssConfig,
} from "../../helpers/functions_helper";
import timeSheetsService from "../../services/time.sheets.service";
import Drawer from "../../components/Drawer";
import EditCompany from "../Masters/Company/EditCompany";
import EditUser from "../Users/EditUser";
import ModalForm from "../Masters/Project/Form";
import fileDownload from "js-file-download";
import { getLangCode } from "../../common/staticdata";

import Echo from "laravel-echo";
import ReloadData from "../../components/Common/ReloadData";
declare global {
  interface Window {
    Pusher: any;
    Echo: any;
  }
}

const AllTimeSheets = (props: any) => {
  const { t } = useTranslation();
  document.title = `${t("Time sheets")} - ${t("List view")} | ` + process.env.REACT_APP_NAME;

  window.Echo = new Echo(wssConfig());
  const channel = window.Echo.channel("notification");

  const userProfile = getUserProfile("all-time-sheets");
  const savedfilters = handleFilters("GET");
  let { module_id, module_main_id, module_sub_id, type } = useParams();
  if (props?.type) {
    type = props?.type;
  }

  const toastId = React.useRef(null) as any;
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [timeSheetsListData, setTimeSheetsListData] = useState([]);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [openCompany, setOpenCompany] = useState<boolean>(false);
  const [openUser, setOpenUser] = useState<boolean>(false);
  const [openContactPerson, setOpenContactPerson] = useState<boolean>(false);
  const [openResponsiblePerson, setOpenResponsiblePerson] = useState<boolean>(false);
  const [openProject, setOpenProject] = useState<boolean>(false);
  const [selectedTimesheet, setSelectedTimesheet] = useState<any>();
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const [showReloadData, setShowReloadData] = useState(false);

  const connectNotificationLive = () => {
    channel
      .subscribed(() => {
        // console.log("subscribe");
      })
      .listen(".notification", (e: any) => {
        const moduleAppID = e.notification.module_app_id;
        if (moduleAppID === 4 && userProfile.CAN_VIEW_ALL) {
          setNotificationCount(notificationCount + 1);
          setShowReloadData(true);
        }
      })
      .error((e: any) => {
        // console.log(e, "error");
      });
  };

  // DataTable ----------------------------------------------
  function renderHeaderHTML(column: any, colIndex: any) {
    return <span dangerouslySetInnerHTML={{ __html: column.text }} />;
  }
  function renderRowHTML(rowContent: any, row: any) {
    return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
  }

  const handleOpenCompany = (data: any) => {
    setSelectedTimesheet(data);
    setOpenCompany(true);
  };

  const handleOpenUser = (data: any) => {
    setSelectedTimesheet(data);
    setOpenUser(true);
  };

  const handleOpenContactPerson = (data: any) => {
    setSelectedTimesheet(data);
    setOpenContactPerson(true);
  };

  const handleOpenResponsiblePerson = (data: any) => {
    setSelectedTimesheet(data);
    setOpenResponsiblePerson(true);
  };

  const handleOpenProject = (data: any) => {
    setSelectedTimesheet(data);
    setOpenProject(true);
  };

  const columns = [
    {
      dataField: "DT_RowIndex",
      text: t("Sr. No."),
      footer: "Total",
      sort: false,
      search: false,
      classes: "fix-col-left",
      headerClasses: "fix-col-left",
      headerStyle: { width: "155px" },
      footerClasses: "fix-col-left",
      style: { width: "155px" },
    },
    // {
    //   dataField: "id",
    //   text: "ID",
    //   footer: "",
    //   sort: true,
    //   search: true,
    // },
    {
      dataField: "module_value",
      text: t("Module"),
      footer: "",
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return renderRowHTML(masterValueWithColorCode(t(row?.module_value), row?.color_code), null);
      },
    },
    {
      dataField: "user_value",
      text: t("User"),
      footer: "",
      sort: true,
      search: true,
      hidden: !userProfile.CAN_VIEW_ALL,
      formatter: (rowContent: any, row: any): any => {
        return (
          <span className="link-primary" onClick={() => handleOpenUser(row)}>
            {row.user_value}
          </span>
        );
      },
    },
    {
      dataField: "fees_type_html",
      text: t("Task type"),
      footer: "",
      sort: true,
      search: true,
      formatter: renderRowHTML,
    },
    {
      dataField: "company_name",
      text: t("Client"),
      footer: "",
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return (
          <>
            {row.company === 0 ? (
              <span>{row.company_name}</span>
            ) : (
              <span className="link-primary" onClick={() => handleOpenCompany(row)}>
                {row.company_name}
              </span>
            )}
          </>
        );
      },
    },
    {
      dataField: "project_name",
      text: t("Project"),
      footer: "",
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return moduleAccess("projects") ? (
          <span className="link-primary" onClick={() => handleOpenProject(row)}>
            {row.project_name}
          </span>
        ) : (
          row.project_name
        );
      },
    },
    {
      dataField: "contact_person_value",
      text: t("Contact person"),
      footer: "",
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return (
          <span className="link-primary" onClick={() => handleOpenContactPerson(row)}>
            {row.contact_person_value}
          </span>
        );
      },
    },
    {
      dataField: "responsible_person_value",
      text: t("Person concerned"),
      footer: "",
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return (
          <span className="link-primary" onClick={() => handleOpenResponsiblePerson(row)}>
            {row.responsible_person_value}
          </span>
        );
      },
    },
    {
      dataField: "custome_code",
      text: t("Time sheet ID"),
      footer: "",
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return row.count === 1 ? row.custome_code : null;
      },
    },
    {
      dataField: "title",
      text: t("Timesheet description"),
      footer: "",
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return (
          <span>
            {userProfile.CAN_EDIT && row.count === 1 ? (
              <Link
                className="text-decoration-underline"
                to={`/time-sheets/edit-time-sheet/${row.id}/${row.time_sheet_id
                  }?filters=${JSON.stringify(filters)}`}
              >
                {subString(row.title, 50)}
              </Link>
            ) : userProfile.CAN_EDIT && row.count > 1 ? (
              <Link
                className="text-decoration-underline"
                to={`/time-sheets/all-time-sheets/${row.module_id}/${row.module_main_id}/${row.module_sub_id}`}
              >
                {subString(row.title, 50)}
                <span className="badge bg-success ms-1">{row.count}</span>
              </Link>
            ) : (
              row.title
            )}
          </span>
        );
      },
    },
    {
      dataField: "fees_amount",
      text: t("Task amount"),
      footer: "",
      sort: true,
      search: true,
      align: "right",
      hidden: !userProfile.CAN_VIEW_FINANCIAL,
      formatter: (rowContent: any, row: any): any => {
        if (row.module_id === 4) {
          return formatCurrency(row.fees_amount);
        } else {
          return "N/A";
        }
      },
      // footer: (columnData: any) => {
      //   if ((module_id && module_main_id && module_sub_id) || type) {
      //     return "";
      //   } else {
      //     return formatCurrency(
      //       columnData.reduce(
      //         (total: number, fees_amount: number) => total + fees_amount,
      //         0
      //       )
      //     );
      //   }
      // },
      // footerAlign: (column: any, colIndex: number) => "right",
    },
    {
      dataField: "expense",
      text: t("Expense"),
      sort: true,
      search: true,
      align: "right",
      hidden: !userProfile.CAN_VIEW_FINANCIAL,
      formatter: (rowContent: any, row: any): any => {
        return formatCurrency(row.expense);
      },
      footer: (columnData: any) =>
        formatCurrency(columnData.reduce((total: number, expense: number) => total + expense, 0)),
      footerAlign: (column: any, colIndex: number) => "right",
    },
    {
      dataField: "start_date_value",
      text: t("Start date"),
      footer: "",
      sort: true,
      search: true,
      align: "right",
      formatter: (rowContent: any, row: any): any => {
        return row.count === 1 ? row.start_date_value : null;
      },
    },
    {
      dataField: "start_time",
      text: t("Start time"),
      footer: "",
      sort: true,
      search: true,
      align: "right",
      formatter: (rowContent: any, row: any): any => {
        return row.count === 1 ? row.start_time : null;
      },
    },
    {
      dataField: "end_time",
      text: t("End time"),
      footer: "",
      sort: true,
      search: true,
      align: "right",
      formatter: (rowContent: any, row: any): any => {
        return row.count === 1 ? row.end_time : null;
      },
    },
    {
      dataField: "time_spent",
      text: t("Time spent"),
      sort: true,
      search: true,
      align: "right",
      footer: (columnData: any) => {
        return secondsToTime(
          columnData.reduce(
            (total: number, time_spent: string) => total + timeToSeconds(time_spent),
            0
          )
        );
      },
      footerAlign: (column: any, colIndex: number) => "right",
    },
    {
      dataField: "service_charge",
      text: t("Consultant's service Charges"),
      // headerFormatter: renderHeaderHTML,
      sort: false,
      search: false,
      align: "right",
      hidden: !userProfile.CAN_VIEW_FINANCIAL,
      formatter: (rowContent: any, row: any): any => {
        return formatCurrency(row.service_charge);
      },
      footer: (columnData: any) =>
        formatCurrency(
          columnData.reduce((total: number, service_charge: number) => total + service_charge, 0)
        ),
      footerAlign: (column: any, colIndex: number) => "right",
    },
    // {
    //   dataField: "service_billing",
    //   text: t("Service billing amount"),
    //   footer: "",
    //   headerFormatter: renderHeaderHTML,
    //   sort: false,
    //   search: false,
    //   align: "right",
    //   hidden: !userProfile.CAN_VIEW_FINANCIAL,
    // },
    {
      dataField: "is_send_invoice",
      text: t("Billing status"),
      footer: "",
      // headerFormatter: renderHeaderHTML,
      sort: false,
      search: false,
      formatter: (rowContent: any, row: any): any => {
        return (row.module_id === null || row.module_id === 4) && row.is_internal !== 1 ? (
          row.is_send_invoice === null || row.is_send_invoice === 0 ? (
            <h5>
              <Badge color="info" pill className="badge badge_custome badge-soft-info rounded-pill">
                {t("Draft")}
              </Badge>
            </h5>
          ) : row.is_send_invoice === 1 ? (
            <h5>
              <Badge
                color="success"
                pill
                className="badge badge_custome badge-soft-success rounded-pill"
              >
                {t("Invoiced")}
              </Badge>
            </h5>
          ) : (
            "N/A"
          )
        ) : row.is_send_invoice === 1 ? (
          <h5>
            <Badge
              color="success"
              pill
              className="badge badge_custome badge-soft-success rounded-pill"
            >
              {t("Invoiced")}
            </Badge>
          </h5>
        ) : row.is_send_invoice === 1 ? (
          <h5>
            <Badge
              color="success"
              pill
              className="badge badge_custome badge-soft-success rounded-pill"
            >
              {t("Invoiced")}
            </Badge>
          </h5>
        ) : (
          <h5>
            <Badge color="info" pill className="badge badge_custome badge-soft-info rounded-pill">
              {t("Draft")}
            </Badge>
          </h5>
        );
      },
    },

    // -------------- CREATED - UPDATED
    {
      dataField: "created_by_value",
      text: t("Created by"),
      sort: true,
      search: true,
    },
    {
      dataField: "created_at",
      text: t("Created on"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return dateFormat(
          row?.created_at,
          {
            dateStyle: "long",
            timeStyle: "short",
            hour12: true,
          },
          getLangCode(localStorage.getItem("I18N_LANGUAGE"))
        );
      },
    },
    {
      dataField: "updated_by_value",
      text: t("Updated by"),
      sort: true,
      search: true,
    },
    {
      dataField: "updated_at",
      text: t("Updated on"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return row?.updated_by
          ? dateFormat(
            row?.created_at,
            {
              dateStyle: "long",
              timeStyle: "short",
              hour12: true,
            },
            getLangCode(localStorage.getItem("I18N_LANGUAGE"))
          )
          : null;
      },
    },
    // -------------- CREATED - UPDATED

    {
      dataField: "action",
      text: t("Action"),
      footer: "",
      sort: false,
      search: false,
      formatter: (rowContent: any, row: any): any => {
        return (
          <div className="d-flex gap-2 text-end">
            {(userProfile.CAN_VIEW && userProfile.USER_ID === row.created_by) ||
              userProfile.CAN_VIEW_ALL ? (
              <Link
                className="btn btn-pill btn-outline-primary btn-sm"
                to={"/time-sheets/edit-time-sheet/" + row.id + "/" + row.time_sheet_id}
              >
                {row.is_send_invoice === 1 ? (
                  <>
                    <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                      visibility
                    </span>
                    {t("View")}
                  </>
                ) : (
                  <>
                    {(userProfile.CAN_EDIT && userProfile.USER_ID === row.created_by) ||
                      userProfile.CAN_EDIT_ALL ? (
                      <>
                        <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                          edit
                        </span>
                        {t("Edit")}
                      </>
                    ) : (
                      <>
                        <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                          visibility
                        </span>
                        {t("View")}
                      </>
                    )}
                  </>
                )}
              </Link>
            ) : null}

            {row.is_send_invoice !== 1 &&
              ((userProfile.CAN_DELETE && userProfile.USER_ID === row.created_by) ||
                userProfile.CAN_DELETE_ALL) ? (
              <Link
                className="btn btn-pill btn-outline-danger btn-sm"
                to="#"
                onClick={() => onClickDelete(row)}
              >
                <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                  delete
                </span>
                {t("Delete")}
              </Link>
            ) : null}
          </div>
        );
      },
    },
  ];

  const [loadingListData, setLoadingListData] = useState(true);
  const [datatableOptions, setDatatableOptions] = useState(
    savedfilters && savedfilters?.datatableOptions
      ? (savedfilters?.datatableOptions as any)
      : {
        draw: 1,
        page: 1,
        recordsTotal: 0,
        recordsFiltered: 0,
        sizePerPage: 20,
        searchText: "",
        sortField: "id",
        sortOrder: "desc",
      }
  );
  const [filters, setFilters] = useState(
    savedfilters && savedfilters?.filters
      ? (savedfilters?.filters as any)
      : {
        is_external_filtere: props.task_id && true,
        is_filtered: !props.task_id && true,
        company_id: "",
        user_id: "",
        contact_person_id: "",
        status: "",
        from_date:
          !props.task_id && !module_id && !module_main_id && !module_sub_id
            ? moment().format("YYYY-MM-DD")
            : "",
        to_date:
          !props.task_id && !module_id && !module_main_id && !module_sub_id
            ? moment().format("YYYY-MM-DD")
            : "",
        fee_type: type !== "list" ? type : null,
        is_send_invoice: 0,
        is_archive: 0,
      }
  );

  // const loadingGroupDatatable = async () => {
  //   try {
  //     const res = (await timeSheetsService.getTimeSheetsGroupList({
  //       ...getDataTableQuery(datatableOptions, columns),
  //       filters,
  //     })) as any;

  //     if (res.data.status === "success") {
  //       setTimeSheetsListData(res.data.data);

  //       const updatedDatatableOptions = {
  //         ...datatableOptions,
  //         recordsTotal: res.data.recordsTotal,
  //         recordsFiltered: res.data.recordsFiltered,
  //       };
  //       setDatatableOptions(updatedDatatableOptions);
  //       setLoadingListData(false);
  //     } else {
  //       setTimeSheetsListData([]);
  //       setLoadingListData(false);
  //     }
  //   } catch (err) {
  //     setTimeSheetsListData([]);
  //     setLoadingListData(false);
  //   }
  // };

  const loadingListDatatable = async () => {
    try {
      const newFilters: any = { ...filters };
      if (module_id && module_main_id && module_sub_id) {
        newFilters.module_id = module_id;
        newFilters.module_main_id = module_main_id;
        newFilters.module_sub_id = module_sub_id;
        newFilters.fee_type = "";
        newFilters.is_archive = 0;
      } else if (props.task_id) {
        newFilters.module_id = "";
        newFilters.module_main_id = props.task_id?.id;
        newFilters.module_sub_id = props.task_id?.task_id;
        newFilters.fee_type = "";
        newFilters.is_archive = 0;
      } else if (props.company_id || props.contact_person_id) {
        newFilters.module_id = "";
        newFilters.module_main_id = "";
        newFilters.module_sub_id = "";
        newFilters.company_id = props.company_id;
        newFilters.contact_person_id = props.company_id === 0 ? props.contact_person_id : "";
        newFilters.status = "0";
        newFilters.from_date = "";
        newFilters.to_date = "";
        newFilters.fee_type = "";
        newFilters.is_archive = 0;
      } else {
        newFilters.module_id = "";
        newFilters.module_main_id = "";
        newFilters.module_sub_id = "";
        newFilters.fee_type = "";
        newFilters.is_archive = 0;
        newFilters.contact_person_id = "";
        newFilters.status = "";
      }

      const res = (await timeSheetsService.getTimeSheetsList({
        ...getDataTableQuery(datatableOptions, columns),
        newFilters,
      })) as any;

      if (res.data.status === "success") {
        setTimeSheetsListData(res.data.data);

        const updatedDatatableOptions = {
          ...datatableOptions,
          recordsTotal: res.data.recordsTotal,
          recordsFiltered: res.data.recordsFiltered,
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingListData(false);
      } else {
        setTimeSheetsListData([]);
        setLoadingListData(false);
      }
    } catch (err) {
      setTimeSheetsListData([]);
      setLoadingListData(false);
    }
  };

  const exportAll = async () => {
    try {
      const newFilters: any = { ...filters };
      if (module_id && module_main_id && module_sub_id) {
        newFilters.module_id = module_id;
        newFilters.module_main_id = module_main_id;
        newFilters.module_sub_id = module_sub_id;
        newFilters.fee_type = "";
        newFilters.is_archive = 0;
      } else if (props.task_id) {
        newFilters.module_id = "";
        newFilters.module_main_id = props.task_id?.id;
        newFilters.module_sub_id = props.task_id?.task_id;
        newFilters.fee_type = "";
        newFilters.is_archive = 0;
      } else if (props.company_id || props.contact_person_id) {
        newFilters.module_id = "";
        newFilters.company_id = props.company_id;
        newFilters.user_id = props.contact_person_id;
        newFilters.fee_type = "";
        newFilters.from_date = "";
        newFilters.to_date = "";
        newFilters.is_archive = 0;
      } else {
        newFilters.module_id = "";
        newFilters.module_main_id = "";
        newFilters.module_sub_id = "";
        newFilters.fee_type = "";
        newFilters.is_archive = 0;
        newFilters.contact_person_id = "";
        newFilters.status = "";
      }

      setLoadingExport(true);
      const res = (await timeSheetsService.getTimeSheetsExport({
        ...getDataTableQuery(datatableOptions, columns),
        newFilters,
      })) as any;

      if (res) {
        fileDownload(res.data, `timesheet-${dateTimeString()}-export.xlsx`);
        setLoadingExport(false);
      }
    } catch (err) {
      setLoadingExport(false);
    }
  };

  useEffect(() => {
    if (loadingListData === true) {
      if ((module_id && module_main_id && module_sub_id) || type || props.task_id) {
        loadingListDatatable();
      } else {
        // loadingGroupDatatable();
        loadingListDatatable();
      }
    }
  }, [loadingListData]);

  useEffect(() => {
    setTimeSheetsListData([]);
    setLoadingListData(true);
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  const onTableChange = (type: any, newState: any) => {
    let draw = datatableOptions.draw + 1;
    let page = 1;
    if (type == "search") {
      page = 1;
    } else {
      page = newState.page;
    }
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: draw,
      page: page,
      sizePerPage: newState.sizePerPage,
      searchText: newState.searchText,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
    };
    setDatatableOptions(updatedDatatableOptions);
    setTimeSheetsListData([]);
    setLoadingListData(true);
  };

  const clearFilter = () => {
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: 1,
      page: 1,
      sizePerPage: 20,
      searchText: "",
      sortField: "id",
      sortOrder: "desc",
    };
    setDatatableOptions(updatedDatatableOptions);

    const filter = {
      ...filters,
      is_filtered: false,
      company_id: "",
      user_id: "",
      contact_person_id: "",
      status: "",
      from_date: "",
      to_date: "",
      fee_type: type,
      is_send_invoice: 0,
      is_archive: 0,
    };
    setFilters(filter);

    setTimeSheetsListData([]);
    setLoadingListData(true);
  };
  // Datatable -------------------------------------------------

  const [deleteWhere, setDeleteWhere] = useState({ id: "", time_sheet_id: "" });
  const [recordName, setRecordName] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const onClickDelete = (row: any) => {
    const deleteTimeSheetData = {
      ...deleteWhere,
      id: row.id,
      time_sheet_id: row.time_sheet_id,
    };
    setDeleteWhere(deleteTimeSheetData);
    setRecordName(`the timesheet created by '${row.created_by_value}' for ${row.title}`);
    setDeleteModal(true);
  };

  const handleDelete = () => {
    toastId.current = toast(`${t("Deleting")} ${t("timesheet")}...`, {
      autoClose: false,
      isLoading: true,
    });
    deleteRecord(deleteWhere);
    setDeleteModal(false);
  };

  const deleteRecord = async (data: any) => {
    try {
      const res = (await timeSheetsService.deleteTimeSheet(data)) as any;
      if (res.data.status === "success") {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoadingListData(true);
        setLoading(false);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoading(false);
      }
    } catch (err: any) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }
  };

  const onPrevDayClick = () => {
    const currentDate = moment().format("YYYY-MM-DD");
    let newDate = null;
    if (filters.from_date) {
      newDate = moment(filters.from_date, "YYYY-MM-DD").subtract(1, "days");
    } else {
      newDate = moment(currentDate, "YYYY-MM-DD").subtract(1, "days");
    }

    handleFiltersChange({
      is_filtered: true,
      from_date: moment(newDate).format("YYYY-MM-DD"),
      to_date: moment(newDate).format("YYYY-MM-DD"),
    });
    setLoadingListData(true);
  };
  const onNextDayClick = () => {
    const currentDate = moment().format("YYYY-MM-DD");
    let newDate = null;
    if (filters.from_date) {
      newDate = moment(filters.from_date, "YYYY-MM-DD").add(1, "days");
    } else {
      newDate = moment(currentDate, "YYYY-MM-DD").add(0, "days");
    }

    handleFiltersChange({
      is_filtered: true,
      from_date: moment(newDate).format("YYYY-MM-DD"),
      to_date: moment(newDate).format("YYYY-MM-DD"),
    });
    setLoadingListData(true);
  };

  // Filters -------------------------------------------------
  const handleFiltersChange = (obj: any) => {
    setFilters((state: any) => ({ ...state, ...obj }));
  };

  const handleApplyFilters = () => {
    setLoadingListData(true);
    setOpenFilter(false);
  };
  // Filters -------------------------------------------------

  useEffect(() => {
    if (type !== "list") {
      setFilters((filter: any) => ({ ...filter, fee_type: type }));
      setLoadingListData(true);
    }
  }, [type]);

  useEffect(() => {
    connectNotificationLive();
  }, []);

  return (
    <React.Fragment>
      {showReloadData && (
        <ReloadData
          title={t("Timesheet updated")}
          count={notificationCount}
          handleSync={() => {
            setLoadingListData(true);
            setNotificationCount(0);
            setShowReloadData(false);
          }}
          handleClose={() => setShowReloadData(false)}
        />
      )}

      {deleteWhere && (
        <DeleteModal
          recordName={recordName}
          modalTitle={t("Time sheet")}
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
          loading={loading}
        />
      )}

      {props.type !== "list" ? (
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              breadcrumbTitle={t("Time sheets")}
              breadcrumbItems={[
                {
                  url: "/dashboard",
                  label: t("Dashboard"),
                  active: false,
                },
                {
                  url: "#",
                  label: t("Time sheets"),
                  active: true,
                },
              ]}
              endAdornment={
                <div className="d-flex gap-2 text-end">
                  {userProfile?.IS_INTERNAL && (
                    <Link
                      to="/time-sheets/all-time-sheets"
                      className={`btn btn-info btn-label btn-sm`}
                    >
                      <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                        list_alt
                      </span>
                      {t("List view")}
                    </Link>
                  )}

                  {userProfile?.IS_GRAPHICAL_VIEW && (
                    <Link
                      to="/time-sheets/graph"
                      className={`btn btn-outline-secondary btn-label btn-sm`}
                    >
                      <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                        insert_chart
                      </span>
                      {t("Graphical view")}
                    </Link>
                  )}

                  {userProfile.CAN_CREATE ? (
                    <Link
                      to="/time-sheets/new-time-sheet"
                      className="btn btn-primary btn-label btn-sm"
                    >
                      <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                        add
                      </span>{" "}
                      {t("Create time sheet")}
                    </Link>
                  ) : null}
                </div>
              }
            />

            <Row>
              <Col xl="12">
                <Card>
                  <CardHeader className="d-sm-flex flex-wrap bg-transparent border-bottom pb-0">
                    <div className="d-flex">
                      {/* <Nav tabs className="nav-tabs-custom">
                        <NavItem>
                          <Link
                            style={{ cursor: "pointer" }}
                            to="/time-sheets/all-time-sheets"
                            className={`nav-link ${!module_id &&
                              !module_main_id &&
                              !module_sub_id &&
                              !type &&
                              "active"
                              }`}
                          >
                            <span className="d-block d-sm-none">
                              <i className="fas fa-home"></i>
                            </span>
                            <span className="d-none d-sm-block">
                              {t("Group")}
                            </span>
                          </Link>
                        </NavItem>
                        <NavItem>
                          <Link
                            style={{ cursor: "pointer" }}
                            to="/time-sheets/all-time-sheets/list"
                            className={`nav-link ${((module_id && module_main_id && module_sub_id) ||
                              type) &&
                              "active"
                              }`}
                          >
                            <span className="d-block d-sm-none">
                              <i className="fas fa-home"></i>
                            </span>
                            <span className="d-none d-sm-block">
                              {t("List")}
                            </span>
                          </Link>
                        </NavItem>
                      </Nav> */}
                    </div>

                    <div className="ms-auto d-flex gap-2 justify-content-end">
                      <div className="dt-buttons btn-group mb-2">
                        <button
                          title={t("Previous day")}
                          className="btn btn-outline-light"
                          onClick={() => onPrevDayClick()}
                        >
                          <span className="align-middle material-symbols-outlined fw-light fs-4">
                            chevron_left
                          </span>
                        </button>

                        <div className="input-group is-invalid" style={{ width: "220px" }}>
                          <Flatpickr
                            id="from_date"
                            name="from_date"
                            className="form-control d-block"
                            type="text"
                            placeholder="DD/MM/YYYY"
                            value={filters?.from_date}
                            options={{
                              altInput: true,
                              altFormat: "d/m/Y",
                              dateFormat: "Y-m-d H:i:S",
                              maxDate: "today",
                              allowInput: true,
                              onChange: function (selectedDates, dateStr, instance) {
                                handleFiltersChange({
                                  is_filtered: true,
                                  from_date: dateStr,
                                });
                                setLoadingListData(true);
                              },
                              locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                            }}
                          />
                          <span className="input-group-text">
                            <span className="align-middle material-symbols-outlined fw-light fs-4">
                              calendar_today
                            </span>
                          </span>
                        </div>

                        <div className="input-group is-invalid" style={{ width: "220px" }}>
                          <Flatpickr
                            id="to_date"
                            name="to_date"
                            className="form-control d-block"
                            type="text"
                            placeholder="DD/MM/YYYY"
                            value={filters?.to_date}
                            options={{
                              altInput: true,
                              altFormat: "d/m/Y",
                              dateFormat: "Y-m-d H:i:S",
                              minDate: filters?.from_date,
                              maxDate: "today",
                              allowInput: true,
                              onChange: function (selectedDates, dateStr, instance) {
                                handleFiltersChange({
                                  is_filtered: true,
                                  to_date: dateStr,
                                });
                                setLoadingListData(true);
                              },
                              locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                            }}
                          />
                          <span className="input-group-text">
                            <span className="align-middle material-symbols-outlined fw-light fs-4">
                              calendar_today
                            </span>
                          </span>
                        </div>

                        {moment().format("YYYY-MM-DD") !==
                          moment(filters?.from_date).format("YYYY-MM-DD") && (
                            <button
                              title={t("Next day")}
                              className="btn btn-outline-light"
                              onClick={() => onNextDayClick()}
                            >
                              <span className="align-middle material-symbols-outlined fw-light fs-4">
                                chevron_right
                              </span>
                            </button>
                          )}
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <DataTable
                      columns={columns}
                      products={timeSheetsListData}
                      datatableOptions={datatableOptions}
                      filters={filters}
                      onTableChange={onTableChange}
                      loadingListData={loadingListData}
                      clearFilter={clearFilter}
                      settingTableName="time_sheets_table"
                      exportedFileName="Timesheet"
                      slug="all-time-sheets"
                      customButtons={
                        <>
                          {userProfile.CAN_VIEW_ALL ? (
                            <Button
                              title={t("My time sheet")}
                              outline={
                                filters.is_filtered === true &&
                                  filters.user_id === userProfile.USER_ID
                                  ? false
                                  : true
                              }
                              color={
                                filters.is_filtered === true &&
                                  filters.user_id === userProfile.USER_ID
                                  ? "secondary"
                                  : "light"
                              }
                              onClick={() => {
                                handleFiltersChange({
                                  is_filtered:
                                    filters.is_filtered === true &&
                                      filters.user_id === userProfile.USER_ID &&
                                      filters.from_date === "" &&
                                      filters.to_date === ""
                                      ? false
                                      : true,
                                  user_id:
                                    filters.user_id === userProfile.USER_ID
                                      ? ""
                                      : userProfile.USER_ID,
                                });
                                handleApplyFilters();
                              }}
                            >
                              <span className="align-middle material-symbols-outlined fw-light fs-4">
                                account_circle
                              </span>
                            </Button>
                          ) : null}

                          {userProfile.CAN_EXPORT ? (
                            <Button
                              title={t("Export all")}
                              outline={true}
                              color={"light"}
                              onClick={() => {
                                exportAll();
                              }}
                              disabled={loadingExport}
                            >
                              {loadingExport ? (
                                <span className="align-middle material-symbols-outlined fw-light fs-4 fa-spin">
                                  hourglass_empty
                                </span>
                              ) : (
                                <span className="align-middle material-symbols-outlined fw-light fs-4">
                                  export_notes
                                </span>
                              )}
                            </Button>
                          ) : null}

                          <Button
                            title="Filter"
                            outline={filters.is_filtered === true ? false : true}
                            color={filters.is_filtered === true ? "secondary" : "light"}
                            onClick={() => setOpenFilter((state) => !state)}
                          >
                            {filters.is_filtered === false ? (
                              <span className="align-middle material-symbols-outlined fw-light fs-4">
                                filter_alt
                              </span>
                            ) : (
                              <span className="align-middle material-symbols-outlined fw-light fs-4">
                                filter_alt_off
                              </span>
                            )}
                          </Button>
                        </>
                      }
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <Row>
          <Col xl="12">
            <Card className="border-top-0 rounded-0 rounded-bottom">
              <CardBody>
                <DataTable
                  columns={columns}
                  products={timeSheetsListData}
                  datatableOptions={datatableOptions}
                  filters={filters}
                  onTableChange={onTableChange}
                  loadingListData={loadingListData}
                  clearFilter={clearFilter}
                  settingTableName="time_sheets_table"
                  slug="all-time-sheets"
                  hideToolbar={!!props?.contact_person_id}
                  customButtons={
                    <>
                      {userProfile.CAN_VIEW_ALL ? (
                        <Button
                          title={t("My time sheet")}
                          outline
                          color={
                            filters.is_filtered === true && filters.user_id === userProfile.USER_ID
                              ? "secondary"
                              : "light"
                          }
                          onClick={() => {
                            handleFiltersChange({
                              is_filtered:
                                filters.is_filtered === true &&
                                  filters.user_id === userProfile.USER_ID &&
                                  filters.from_date === "" &&
                                  filters.to_date === ""
                                  ? false
                                  : true,
                              user_id:
                                filters.user_id === userProfile.USER_ID ? "" : userProfile.USER_ID,
                            });
                            handleApplyFilters();
                          }}
                        >
                          <span className="align-middle material-symbols-outlined fw-light fs-4">
                            account_circle
                          </span>
                        </Button>
                      ) : null}
                      <Button
                        title="Filter"
                        outline={filters.is_filtered === true ? false : true}
                        color={filters.is_filtered === true ? "secondary" : "light"}
                        onClick={() => setOpenFilter((state) => !state)}
                      >
                        {filters.is_filtered === false ? (
                          <span className="align-middle material-symbols-outlined fw-light fs-4">
                            filter_alt
                          </span>
                        ) : (
                          <span className="align-middle material-symbols-outlined fw-light fs-4">
                            filter_alt_off
                          </span>
                        )}
                      </Button>
                    </>
                  }
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      <Drawer
        title={t("Filters")}
        open={openFilter}
        onClose={() => setOpenFilter((state) => !state)}
        onReset={clearFilter}
        footer={
          <Button color="primary" style={{ margin: "16px" }} onClick={handleApplyFilters}>
            {t("Apply")}
          </Button>
        }
      >
        <Filters
          filters={filters}
          onApply={() => { }}
          onClear={() => { }}
          onChange={(obj: any) => handleFiltersChange(obj)}
        />
      </Drawer>

      <Drawer
        title={t("Client")}
        open={openCompany}
        onClose={() => setOpenCompany((state) => !state)}
        fullWidth
      >
        <EditCompany
          id={selectedTimesheet?.company}
          company_id={selectedTimesheet?.company_id}
          type={1}
          isWindow={true}
        />
      </Drawer>

      <Drawer
        title={t("User")}
        open={openUser}
        onClose={() => setOpenUser((state) => !state)}
        fullWidth
      >
        <EditUser
          id={selectedTimesheet?.user_id}
          user_id={selectedTimesheet?.user_unique_id}
          type={1}
          isWindow={true}
        />
      </Drawer>

      <Drawer
        title={t("Contact person")}
        open={openContactPerson}
        onClose={() => setOpenContactPerson((state) => !state)}
        fullWidth
      >
        <EditUser
          id={selectedTimesheet?.contact_person}
          user_id={selectedTimesheet?.contact_person_id}
          type={1}
          isWindow={true}
        />
      </Drawer>

      <Drawer
        title={t("Person concerned")}
        open={openResponsiblePerson}
        onClose={() => setOpenResponsiblePerson((state) => !state)}
        fullWidth
      >
        <EditUser
          id={selectedTimesheet?.responsible_person}
          user_id={selectedTimesheet?.responsible_person_id}
          type={1}
          isWindow={true}
        />
      </Drawer>

      {/* <Drawer
        title="Project"
        open={openProject}
        onClose={() => setOpenProject((state) => !state)}
        fullWidth
      >
        <ModalForm id={selectedTimesheet?.project} project_id={selectedTimesheet?.project_id} type={1} isWindow={true} />
      </Drawer> */}
      {openProject && (
        <ModalForm
          modalTitle={t("Project")}
          show={openProject}
          loading={loader}
          vatData={selectedTimesheet}
          id={selectedTimesheet?.project}
          onSubmitClick={() => { }}
          onCloseClick={() => setOpenProject(false)}
          errorMsg={""}
          errorData={""}
          isWin={true}
        // isTab={selectedTimesheet?.company}
        />
      )}
    </React.Fragment>
  );
};

export default AllTimeSheets;
