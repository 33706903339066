import { takeEvery, call, put } from "redux-saga/effects";
import feesService from "../../../services/masters.fees.service";
import { AnyAction } from "redux";

// Fees Redux States
import {
  GET_FEE,
  GET_FEES_LIST,
  CREATE_FEE,
  UPDATE_FEE,
  DELETE_FEE,
} from "./actionTypes";

import { 
    getFeeSuccess, 
    getFeeError,
    
    getFeesListSuccess,
    getFeesListError,

    createFeeSuccess,
    createFeeError,

    updateFeeSuccess,
    updateFeeError,

    deleteFeeSuccess,
    deleteFeeError,
} from "./actions";

function* getFee({ payload: { fees, history } }: AnyAction): any {
  try {
    const response = yield call(feesService.getFee, fees);
    if (response.data && response.data.status === "success") {
      yield put(getFeeSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getFeeError(response.data));
    } else {
      yield put(getFeeError(response));
    }
  } catch (error: any) {
    yield put(getFeeError(error));
  }
}

function* getFeesList({ payload: { query, history } }: AnyAction): any {
  try {
    const response = yield call(feesService.getFeesList, query);
    if (response.data && response.data.status === "success") {
      yield put(getFeesListSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getFeesListError(response.data));
    } else {
      yield put(getFeesListError(response));
    }
  } catch (error: any) {
    yield put(getFeesListError(error));
  }
}

function* createFee({ payload: { fees, history } }: AnyAction): any {
  try {
    const response = yield call(feesService.createFee, fees);
    if (response.data && response.data.status === "success") {
      yield put(createFeeSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(createFeeError(response.data));
    } else {
      yield put(createFeeError(response));
    }
  } catch (error: any) {
    yield put(createFeeError(error));
  }
}

function* updateFee({ payload: { fees, history } }: AnyAction): any {
  try {
    const response = yield call(feesService.updateFee, fees);
    if (response.data && response.data.status === "success") {
      yield put(updateFeeSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(updateFeeError(response.data));
    } else {
      yield put(updateFeeError(response));
    }
  } catch (error: any) {
    yield put(updateFeeError(error));
  }
}

function* deleteFee({ payload: { fees, history } }: AnyAction): any {
  try {
    const response = yield call(feesService.deleteFee, fees);
    if (response.data && response.data.status === "success") {
      yield put(deleteFeeSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(deleteFeeError(response.data));
    } else {
      yield put(deleteFeeError(response));
    }
  } catch (error: any) {
    yield put(deleteFeeError(error));
  }
}

function* feesSaga(): any {
  yield takeEvery(GET_FEE, getFee);
  yield takeEvery(GET_FEES_LIST, getFeesList);
  yield takeEvery(CREATE_FEE, createFee);
  yield takeEvery(UPDATE_FEE, updateFee);
  yield takeEvery(DELETE_FEE, deleteFee);
}

export default feesSaga;
