import React, { FC, useState } from 'react';
import { toast } from 'react-toastify';
import { Input, Label, Modal, ModalBody, ModalFooter } from "reactstrap";
import invoicesService from '../../services/invoices.service';
import { useTranslation } from 'react-i18next';

interface CancelInvoiceProps {
    show: boolean,
    invoice: any,
    onCloseClick: () => void,
    onSubmit?: () => void,
}

type CancelFormType = {
    reason: string;
    isSubmitting: boolean;
}

const CancelInvoice: FC<CancelInvoiceProps> = ({
    show,
    invoice,
    onCloseClick,
    onSubmit
}) => {
    const { t } = useTranslation();
    const toastId = React.useRef(null) as any;

    const [showForm, setShowForm] = useState<boolean>(false);
    const [formValues, setFormValues] = useState<CancelFormType>({
        reason: '',
        isSubmitting: false
    });

    const handleSubmit = async (values: CancelFormType) => {
        toastId.current = toast(t("Cancelling invoice..."), { autoClose: false, isLoading: true });
        setFormValues((state: CancelFormType) => ({ ...state, isSubmitting: true }))
        const result = await invoicesService.cancelInvoice({
            invoice_id: invoice?.id,
            reason_for_cancellation: values.reason
        });
        if (result.status === 'success') {
            setShowForm(false);
            onSubmit && onSubmit();
            toast.update(toastId.current, { type: toast.TYPE.SUCCESS, render: t('invoice is successfully cancelled'), autoClose: 5000, isLoading: false, delay: 500 });
        }
        setFormValues((state: CancelFormType) => ({ ...state, isSubmitting: false }))
    }

    return (
        <Modal isOpen={show} toggle={onCloseClick} centered={true}>
            <div className="modal-header">
                <h5 className="modal-title">
                    {t("Cancel invoice")}
                </h5>
            </div>
            {showForm ?
                <>
                    <ModalBody>
                        <div className="mb-3">
                            <Label className="form-label">
                                {t("Reason for cancellation")}
                            </Label>
                            <Input
                                type='textarea'
                                id={`reason`}
                                name='reason'
                                placeholder={t('Enter reason')}
                                value={formValues?.reason}
                                onChange={(e) => {
                                    setFormValues((state: CancelFormType) => ({ ...state, reason: e.target.value }))
                                }}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button"
                            className="btn btn-primary btn-md ms-2"
                            disabled={formValues.isSubmitting || formValues?.reason === ""}
                            onClick={() => handleSubmit(formValues)}
                        >
                            {t("Submit")}
                        </button>

                    </ModalFooter>
                </>
                :
                <>
                    <ModalBody>
                        <p>
                            {t("Do you want to cancel the invoice")} <b>#{invoice?.invoice_number}</b>?
                        </p>

                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button"
                            className="btn btn-primary btn-md ms-2"
                            onClick={() => setShowForm(true)}
                        >
                            {t("Yes")}
                        </button>
                        <button
                            type="button"
                            className="btn btn-outline-danger btn-md ms-2"
                            onClick={onCloseClick}
                        >
                            {t("Cancel")}
                        </button>
                    </ModalFooter>
                </>
            }
        </Modal >
    );
};



export default CancelInvoice;
