import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class QuestionnaireUserDataService {
    async getQuestionnaireUserData(DATA: any) {
        const response = await axios
            .get(SERVER_URL + url.GET_QUESTIONNAIRE_TAB + "/" + DATA.id + "/" + DATA.tbl_master_questionnaire_tab,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async getQuestionnaireUserDataDatatableList(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_QUESTIONNAIRE_TAB_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            );
        return response;
    }

    async getQuestionnaireUserDataList(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_QUESTIONNAIRE_USER_DATA_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            );
        return response;
    }

    async createQuestionnaireUserData(DATA: any) {
        const response = await axios
            .put(SERVER_URL + url.CREATE_QUESTIONNAIRE_USER_DATA, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async onSubmitQuestionnaireUserData(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.SUBMIT_QUESTIONNAIRE_USER_DATA, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async incompleteQuestionnaireUserData(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.INCOMPLETE_QUESTIONNAIRE_USER_DATA, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async updateQuestionnaireUserStatus(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.UPDATE_QUESTIONNAIRE_USER_STATUS, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }


    async updateQuestionnaireUserData(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.UPDATE_QUESTIONNAIRE_TAB, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async deleteQuestionnaireUserData(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.DELETE_QUESTIONNAIRE_TAB, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }
}

export default new QuestionnaireUserDataService();