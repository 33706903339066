import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, FormGroup, Label, Row, Table } from 'reactstrap';
import { JsxElement } from 'typescript';
import { number } from 'yup';
import Button from '../../../components/Button/Button';
import Drawer from '../../../components/Drawer';
import Input from '../../../components/Form/Input';
import Select from '../../../components/Form/Select/Select';
import Breadcrumbs from '../../../components/Layout/Breadcrumb';
import { getUserProfile } from '../../../helpers/functions_helper';
import accessroleService from '../../../services/accessrole.service';
import commonService from '../../../services/common.service';
import AccessModulesService from '../../../services/masters.access.module.service';
import AccessRoleService from '../../../services/accessrole.service';
import { toast } from 'react-toastify';
import DeleteModal from '../../../components/Common/DeleteModal';
import LoadingSpinner from '../../../components/Common/Loader';

type AccessRoleFormProps = {
    formValues: any,
    setFormValues: any,
    handleSubmit: any,
    isView: boolean,
    isTab?: boolean
}

const AccessRoleForm: FC<AccessRoleFormProps> = ({ formValues, setFormValues, handleSubmit, isView = false, isTab = false }) => {

    const { t } = useTranslation();
    const userProfile = getUserProfile('access-role');
    const toastId = React.useRef(null) as any;

    const options = [
        {
            'label': t("--Select--"),
            'value': 0
        },
        {
            'label': 'Tax Consult',
            'value': 1
        },
        {
            'label': t("Clients"),
            'value': 2
        }
    ];

    const [modules, setModules] = useState<Array<any>>([]);
    const [widgets, setWidgets] = useState<Array<any>>([]);
    const [selectedWidgets, setSelectedWidgets] = useState<Array<any>>([]);
    const [errors, setErrors] = useState<any>({
        name: null,
        applicable_for: null
    });
    const [touched, setTouched] = useState<any>({
        name: false,
        applicable_for: false
    });
    const [openWidget, setOpenWidget] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);

    const handleChange = (e: any) => {
        setFormValues((state: any) => ({ ...state, [e.target.name]: e.target.value }))
    }

    const handleOpenWidget = (widgets: any) => {
        setOpenWidget(true);
        setSelectedWidgets(widgets);
    }

    const getModules = async () => {
        const res = await AccessModulesService.getAllModules();
        if (res?.data?.status === 'success') {
            setModules(res?.data?.data);
            setLoading(false);
        }
    }

    const getDashboardWidgets = async () => {
        const res = await commonService.getDashboardWidgets();
        if (res?.data?.status === 'success') {
            setWidgets(res?.data?.data);
        }
    }

    const handleAccessChange = (e: any, slug: string) => {
        // alert(slug);
        if (Object.values(errors).some((item: any) => item !== null)) {
            setTouched({
                name: true,
                applicable_for: true
            });
            return false;
        }
        let tempAccess: any = {};
        if (slug === 'broadcast') {
            tempAccess = {
                ...formValues,
                modules: [
                    ...formValues.modules.filter((a: any) => a.module !== slug),
                    {
                        module: slug,
                        access: {
                            ...formValues.modules.find((a: any) => a.module === slug)?.access,
                            can_view: e.target.name === 'can_view_all' ? e.target.checked : formValues.modules.find((a: any) => a.module === slug)?.access?.can_view,
                            [e.target.name]: e.target.checked
                        }
                    }
                ]
            }
        } else if (slug === 'all-tasks' && e.target.checked) {
            tempAccess = {
                ...formValues,
                modules: [
                    ...formValues.modules.filter((a: any) => a.module !== slug && a.module !== 'all-time-sheets'),
                    {
                        module: slug,
                        access: {
                            ...formValues.modules.find((a: any) => a.module === slug)?.access,
                            [e.target.name]: e.target.checked
                        }
                    },
                    {
                        module: 'all-time-sheets',
                        status: e.target.checked,
                        access: {
                            ...formValues.modules.find((a: any) => a.module === 'all-time-sheets')?.access,
                            can_view: e.target.checked,
                            can_create: e.target.checked,
                            can_edit: e.target.checked,
                        }
                    }
                ]
            }
        } else {
            tempAccess = {
                ...formValues,
                modules: [
                    ...formValues.modules.filter((a: any) => a.module !== slug),
                    {
                        module: slug,
                        access: {
                            ...formValues.modules.find((a: any) => a.module === slug)?.access,
                            [e.target.name]: e.target.checked
                        }
                    }
                ]
            }
        }
        console.log('tempAccess', tempAccess)
        setFormValues((state: any) => ({
            ...state,
            modules: [
                ...tempAccess.modules.filter((a: any) => a.module !== slug),
                {
                    module: slug,
                    status: Object.values(tempAccess.modules.find((a: any) => a.module === slug)?.access).some((item: any) => item === true),
                    access: {
                        ...tempAccess.modules.find((a: any) => a.module === slug)?.access,
                        [e.target.name]: e.target.checked
                    }
                }
            ]
        }))
    }

    useEffect(() => {
        getModules();
        getDashboardWidgets();
        setTouched({
            name: false,
            applicable_for: false
        });
    }, [])

    useEffect(() => {
        setTouched({
            name: false,
            applicable_for: false
        });
    }, [isView])

    useEffect(() => {
        if (formValues?.name === '') {
            setErrors((state: any) => ({ ...state, name: 'Please enter role name' }))
        } else {
            setErrors((state: any) => ({ ...state, name: null }))
        }
        if (formValues?.applicable_for === 0) {
            setErrors((state: any) => ({ ...state, applicable_for: 'Please select applicable for' }))
        } else {
            setErrors((state: any) => ({ ...state, applicable_for: null }))
        }
    }, [formValues])

    return (
        <div>
            {!isTab &&
                <Row>
                    <Col className='mb-3'>
                        <Input
                            label={t('Access role name')}
                            name="name"
                            id="name"
                            maxLength={30}
                            value={formValues?.name}
                            onChange={handleChange}
                            error={errors?.name}
                            touched={touched?.name}
                            readOnly={isView}
                        />
                    </Col>
                    <Col>
                        <Select
                            label={t('Applicable for')}
                            name="applicable_for"
                            id="applicable_for"
                            value={options?.find((option: any) => option.value == formValues?.applicable_for)}
                            onChange={(option: any) => setFormValues((state: any) => ({ ...state, applicable_for: option.value }))}
                            options={options}
                            touched={touched?.applicable_for}
                            error={errors?.applicable_for}
                            isDisabled={isView}
                        />
                    </Col>
                </Row>
            }
            <Row>
                <Col>
                    <FormGroup switch inline className={"form-check-primary mx-2"}>
                        <Input
                            type="switch"
                            name={'status'}
                            id={'access_to_financial_data'}
                            checked={formValues?.access_to_financial_data}
                            onChange={(e: any) => setFormValues((state: any) => ({
                                ...state,
                                access_to_financial_data: e.target.checked
                            }))}
                            label={t('Access to financial reports')}
                            disabled={isView || !formValues.modules.find((a: any) => a.module === 'all-invoices')?.status}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup switch inline className={"form-check-primary mx-2"}>
                        <Input
                            type="switch"
                            name={'vip_access'}
                            id={'vip_access'}
                            checked={formValues?.vip_access}
                            onChange={(e: any) => setFormValues((state: any) => ({
                                ...state,
                                vip_access: e.target.checked
                            }))}
                            label={t('VIP Access')}
                            disabled={isView}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup switch inline className={"form-check-primary mx-2"}>
                        <Input
                            type="switch"
                            name={'download_csf_act'}
                            id={'download_csf_act'}
                            checked={formValues?.download_csf_act}
                            onChange={(e: any) => setFormValues((state: any) => ({
                                ...state,
                                download_csf_act: e.target.checked
                            }))}
                            label={t('Download ACT and CSF file')}
                            disabled={isView}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className='table-responsive table-advanced'>
                        <div className='react-bootstrap-table'>
                            <Table striped bordered hover>
                                <thead className='thead-light'>
                                    <tr>
                                        <th>{t("Module")}</th>
                                        <th>{t("View")} </th>
                                        <th>{t("Create")}</th>
                                        <th>{t("Edit")}</th>
                                        <th>{t("Delete")}</th>
                                        <th>{t("Print")}</th>
                                        <th>{t("Export")}</th>
                                        <th>{t("View All")} <span className="cursor-pointer align-middle material-symbols-outlined fs-4 fw-light" title='Can view record created by others'>info</span></th>
                                        <th>{t("Edit All")} <span className="cursor-pointer align-middle material-symbols-outlined fs-4 fw-light" title='Can edit record created by others'>info</span></th>
                                        <th>{t("Delete All")} <span className="cursor-pointer align-middle material-symbols-outlined fs-4 fw-light" title='Can delete record created by others'>info</span></th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {loading ? <tr><td colSpan={10}><LoadingSpinner /></td></tr> : modules?.map((module: any, i: number) => (
                                        <React.Fragment key={i}>
                                            <tr>
                                                <td>
                                                    <div style={{ display: 'flex' }}>
                                                        <div>
                                                            <Label for={'status_' + module.id}>
                                                                <span className="align-middle material-symbols-outlined fw-light">{module.icon_class}</span>
                                                                &nbsp;
                                                                {module?.module_value}
                                                                &nbsp;
                                                            </Label>

                                                            <FormGroup switch inline className={"form-check-primary mx-2"}>
                                                                <Input
                                                                    type="switch"
                                                                    name={'status'}
                                                                    id={'status_' + module.id}
                                                                    checked={formValues.modules.find((a: any) => a.module === module.slug)?.status}
                                                                    onChange={(e: any) => setFormValues((state: any) => ({
                                                                        ...state,
                                                                        modules: [
                                                                            ...state.modules.filter((a: any) => a.module !== module.slug),
                                                                            {
                                                                                module: module.slug,
                                                                                status: e.target.checked,
                                                                                access: {
                                                                                    ...state.modules.find((a: any) => a.module === module.slug)?.access,
                                                                                    can_view: e.target.checked,
                                                                                    can_create: e.target.checked,
                                                                                    can_edit: e.target.checked,
                                                                                    can_delete: e.target.checked,
                                                                                    can_print: e.target.checked,
                                                                                    can_export: e.target.checked,
                                                                                    can_view_all: e.target.checked,
                                                                                    can_edit_all: e.target.checked,
                                                                                    can_delete_all: e.target.checked,
                                                                                }
                                                                            }
                                                                        ]
                                                                    }))}
                                                                    label={' '}
                                                                    disabled={isView}
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                        {widgets.filter((w: any) => w?.module_id === module.id)?.length > 0 ?
                                                            <Button
                                                                size='sm'
                                                                color='secondary'
                                                                className='ms-auto btn-label'
                                                                onClick={() => handleOpenWidget(widgets.filter((w: any) => w?.module_id === module.id))}
                                                                disabled={!formValues.modules.find((a: any) => a.module === module.slug)?.status}
                                                            >
                                                                <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">add</span>
                                                                {t("Widgets")}
                                                            </Button> : null
                                                        }
                                                    </div>
                                                </td>
                                                <td>
                                                    {module.slug !== "broadcast" && (
                                                        <FormGroup switch inline className="form-check-success">
                                                            <Input
                                                                type="switch"
                                                                name={'can_view'}
                                                                checked={formValues.modules.find((a: any) => a.module === module.slug)?.access?.can_view}
                                                                onChange={(e: any) => handleAccessChange(e, module?.slug)}
                                                                label={''}
                                                                disabled={isView}
                                                            />
                                                        </FormGroup>
                                                    )}
                                                </td>
                                                <td>
                                                    {module.id !== 20 && (
                                                        <FormGroup switch inline className="form-check-success">
                                                            <Input
                                                                type="switch"
                                                                name={'can_create'}
                                                                checked={formValues.modules.find((a: any) => a.module === module.slug)?.access?.can_create}
                                                                onChange={(e: any) => handleAccessChange(e, module?.slug)}
                                                                label={''}
                                                                disabled={isView}
                                                            />
                                                        </FormGroup>
                                                    )}
                                                </td>
                                                <td>
                                                    {module.id !== 20 && (
                                                        <FormGroup switch inline className="form-check-success">
                                                            <Input
                                                                type="switch"
                                                                name={'can_edit'}
                                                                checked={formValues.modules.find((a: any) => a.module === module.slug)?.access?.can_edit}
                                                                onChange={(e: any) => handleAccessChange(e, module?.slug)}
                                                                label={''}
                                                                disabled={isView}
                                                            />
                                                        </FormGroup>
                                                    )}
                                                </td>
                                                <td>
                                                    {module.id !== 20 && (
                                                        <FormGroup switch inline className="form-check-success">
                                                            <Input
                                                                type="switch"
                                                                name={'can_delete'}
                                                                checked={formValues.modules.find((a: any) => a.module === module.slug)?.access?.can_delete}
                                                                onChange={(e: any) => handleAccessChange(e, module?.slug)}
                                                                label={''}
                                                                disabled={isView}
                                                            />
                                                        </FormGroup>
                                                    )}
                                                </td>
                                                <td>
                                                    <FormGroup switch inline className="form-check-success">
                                                        <Input
                                                            type="switch"
                                                            name={'can_print'}
                                                            checked={formValues.modules.find((a: any) => a.module === module.slug)?.access?.can_print}
                                                            onChange={(e: any) => handleAccessChange(e, module?.slug)}
                                                            label={''}
                                                            disabled={isView}
                                                        />
                                                    </FormGroup>
                                                </td>

                                                <td>
                                                    <FormGroup switch inline className="form-check-success">
                                                        <Input
                                                            type="switch"
                                                            name={'can_export'}
                                                            checked={formValues.modules.find((a: any) => a.module === module.slug)?.access?.can_export}
                                                            onChange={(e: any) => handleAccessChange(e, module?.slug)}
                                                            label={''}
                                                            disabled={isView}
                                                        />
                                                    </FormGroup>
                                                </td>
                                                <td>
                                                    <FormGroup switch inline className="form-check-success">
                                                        <Input
                                                            type="switch"
                                                            name={'can_view_all'}
                                                            checked={formValues.modules.find((a: any) => a.module === module.slug)?.access?.can_view_all && formValues.modules.find((a: any) => a.module === module.slug)?.access?.can_view}
                                                            onChange={(e: any) => handleAccessChange(e, module?.slug)}
                                                            label={''}
                                                            disabled={(isView || !formValues.modules.find((a: any) => a.module === module.slug)?.access?.can_view) && module.slug !== 'broadcast'}
                                                        />
                                                    </FormGroup>
                                                </td>
                                                <td>
                                                    {module.id !== 20 && (
                                                        <FormGroup switch inline className="form-check-success">
                                                            <Input
                                                                type="switch"
                                                                name={'can_edit_all'}
                                                                checked={formValues.modules.find((a: any) => a.module === module.slug)?.access?.can_edit_all && formValues.modules.find((a: any) => a.module === module.slug)?.access?.can_edit}
                                                                onChange={(e: any) => handleAccessChange(e, module?.slug)}
                                                                label={''}
                                                                disabled={isView || !formValues.modules.find((a: any) => a.module === module.slug)?.access?.can_edit}
                                                            />
                                                        </FormGroup>
                                                    )}
                                                </td>
                                                <td>
                                                    {module.id !== 20 && (
                                                        <FormGroup switch inline className="form-check-success">
                                                            <Input
                                                                type="switch"
                                                                name={'can_delete_all'}
                                                                checked={formValues.modules.find((a: any) => a.module === module.slug)?.access?.can_delete_all && formValues.modules.find((a: any) => a.module === module.slug)?.access?.can_delete}
                                                                onChange={(e: any) => handleAccessChange(e, module?.slug)}
                                                                label={''}
                                                                disabled={isView || !formValues.modules.find((a: any) => a.module === module.slug)?.access?.can_delete}
                                                            />
                                                        </FormGroup>
                                                    )}
                                                </td>

                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Col>
            </Row>
            {!isView ?
                <Row>
                    <Col>
                        <Button
                            color="primary"
                            style={{ margin: "16px", width: "calc(100% - 64px)", top: "calc(100vh - 70px)", position: "absolute" }}
                            onClick={() => handleSubmit(formValues)}
                            disabled={formValues?.isSubmitting || Object.values(errors).some((err: any) => err !== null)}
                            loading={formValues?.isSubmitting}
                            fullWidth
                        >
                            {t("Submit")}
                        </Button>
                    </Col>
                </Row> : null
            }
            <Drawer
                title='Widgets'
                open={openWidget}
                onClose={() => setOpenWidget(false)}
                footer={<Button color='primary' onClick={() => setOpenWidget(false)}>{t("Apply")}</Button>}
            >
                <div>
                    <Table striped hover>
                        <tbody>
                            {selectedWidgets?.length > 0 && selectedWidgets.map((w: any) => (
                                <tr key={w.id}>
                                    <td>
                                        <Input
                                            type="checkbox"
                                            name={`widget_${w.id}`}
                                            id={`widget_${w.id}`}
                                            checked={formValues?.widgets?.includes(w.id)}
                                            onChange={(e: any) => setFormValues((state: any) => ({
                                                ...state,
                                                widgets: state?.widgets?.includes(w.id) ? state?.widgets?.filter((a: number) => a !== w.id) : [...state.widgets, w.id]
                                            }))}
                                            label={w?.widget_name}
                                            disabled={isView}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </Drawer>
        </div>
    )
}

export default AccessRoleForm;