import React, { useEffect, useState } from "react";
import { handleFilters } from "../../helpers/functions_helper";
import { useTranslation } from "react-i18next";

const DataTableSearchBar = (props: any) => {

  const { t } = useTranslation();

  let input: any;

  const savedfilters = handleFilters("GET");
  const [initialData, setInitialData] = useState({
    searchText: "",
  });

  const handleKey = (e: any) => {
    if (e.key === "Enter") {
      props.onSearch(input.value);
    }

    if (e.key === "Escape") {
      props.onSearch("");
      handleChange("searchText", "");
    }
  };
  const handleSearch = () => {
    props.onSearch(input.value);
  };
  const handleClear = () => {
    props.onSearch("");
    handleChange("searchText", "");
  };

  const handleChange = (fieldName: string, fieldValue: any) => {
    setInitialData({ ...initialData, [fieldName]: fieldValue });
  };

  useEffect(() => {
    if (props?.searchText === "") {
      handleChange("searchText", "");
    }
  }, [props?.searchText]);

  useEffect(() => {
    if (props?.loadingListData === false && props?.inputFocus === true) {
      input.focus();
    }
  }, [props?.loadingListData]);

  useEffect(() => {
    if (savedfilters?.datatableOptions?.searchText) {
      handleChange("searchText", savedfilters?.datatableOptions?.searchText);
    }
    if (savedfilters?.datatableOptions?.searchText === "") {
      handleChange("searchText", "");
    }
  }, [savedfilters?.datatableOptions?.searchText]);

  return (
    <>
      <div className="input-group mb-1">
        <input
          ref={(n) => (input = n)}
          type="text"
          className="form-control data-table-search-input"
          placeholder={t("Search")}
          value={initialData?.searchText}
          disabled={props?.loadingListData}
          onChange={(e) => handleChange("searchText", e.target.value)}
          onKeyDown={handleKey}
        />

        <button
          title={t("Search")}
          className="btn btn-outline-light data-table-search-button"
          disabled={props?.loadingListData}
          onClick={handleSearch}
        >
          <span className="align-middle material-symbols-outlined fw-light fs-4">
            search
          </span>
        </button>

        <button
          title={t("Clear")}
          className="btn btn-outline-light data-table-search-clear"
          disabled={props?.loadingListData}
          onClick={handleClear}
        >
          <span className="align-middle material-symbols-outlined fw-light fs-4">
            close
          </span>
        </button>
      </div>
    </>
  );
};

export default React.memo(DataTableSearchBar);
