import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper";
import * as url from "../helpers/url_helper";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class UserManual {
  async create(data: any) {
    const response = await axios
      .put(SERVER_URL + url.USER_MANUAL.CREATE, data, {
        headers: getAxiosConfigWithToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async update(data: any) {
    const response = await axios
      .post(SERVER_URL + url.USER_MANUAL.UPDATE, data, {
        headers: getAxiosConfigWithToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async delete(data: any) {
    const response = await axios
      .post(SERVER_URL + url.USER_MANUAL.DELETE, data, {
        headers: getAxiosConfigWithToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async get(data: any) {
    const response = await axios
      .get(SERVER_URL + url.USER_MANUAL.GET + "/" + data.id + "/" + data.user_manual_id, {
        headers: getAxiosConfigWithToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async getAll() {
    const response = await axios
      .get(SERVER_URL + url.USER_MANUAL.GET_ALL, {
        headers: getAxiosConfigWithToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }
}

export default new UserManual();
