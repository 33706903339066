import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  Button,
  Row,
  Col,
} from "reactstrap";
import { vLookup } from "../../helpers/functions_helper";
import { formatCurrency } from "../../utils/formats";
import { useTranslation } from "react-i18next";

const SSSCModel_150 = (props: any) => {
  const { t } = useTranslation();
  let spCountryData = props.spCountryData;
  let C_DED_BE_SS = props.C_DED_BE_SS;
  let GENERAL_REBATE = props.GENERAL_REBATE;
  let EMPLOYMENT_REBATE = props.EMPLOYMENT_REBATE;
  let TAX_RATE_N = props.TAX_RATE_N;
  let SS_RATE_N = props.SS_RATE_N;

  const [COUNTRY_IDX, setCOUNTRY_IDX] = useState<number>();
  const [D_GROSS_COUNTRY, setD_GROSS_COUNTRY] = useState<number>(0);
  const [D_SS_COUNTRY, setD_SS_COUNTRY] = useState<number>(0);
  const [D_SS_PER, setD_SS_PER] = useState<number>(0);
  const [D_TAX_OPTION_COUNTRY, setD_TAX_OPTION_COUNTRY] = useState<number>(1);

  const [B30, setB30] = useState<number>(0);
  const [B31, setB31] = useState<number>(0);
  const [B32, setB32] = useState<number>(0);
  const [D_SS_HOLLAND_DEDUCT, setD_SS_HOLLAND_DEDUCT] = useState<number>(0);
  const [C_NET_TAXABLE_COUNTRY, setC_NET_TAXABLE_COUNTRY] = useState<number>(0);
  const [C_30PERC_ALLOWANCE, setC_30PERC_ALLOWANCE] = useState<number>(0);
  const [C_NET_TAXABLE_COUNTRY2, setC_NET_TAXABLE_COUNTRY2] = useState<number>(0);
  const [E42, setE42] = useState<number>(0);
  const [C_BASE_TAX_COUNTRY, setC_BASE_TAX_COUNTRY] = useState<number>(0);
  const [C_GENERAL_REBATE, setC_GENERAL_REBATE] = useState<number>(0);
  const [E45, setE45] = useState<number>(0);
  const [C_EMPLOYMENT_REBATE, setC_EMPLOYMENT_REBATE] = useState<number>(0);
  const [C_TAX_HOLLAND, setC_TAX_HOLLAND] = useState<number>(0);

  useEffect(() => {
    if (spCountryData) {
      const COUNTRY_DATA = spCountryData.find((data: any) => data.country_id === 150);

      if (COUNTRY_DATA) {
        setCOUNTRY_IDX(spCountryData.findIndex((data: any) => data.country_id === 150));
        setD_GROSS_COUNTRY(
          spCountryData.find((data: any) => data.country_id === 150)?.D_GROSS_COUNTRY
        );
        setD_SS_COUNTRY(spCountryData.find((data: any) => data.country_id === 150)?.D_SS_COUNTRY);
        setD_SS_PER(
          spCountryData.find((data: any) => data.country_id === 150)?.social_security_percentage
        );
        setD_TAX_OPTION_COUNTRY(
          spCountryData.find((data: any) => data.country_id === 199)?.country_tax_option
        );
      }
    }
  }, [spCountryData]);

  useEffect(() => {
    if (TAX_RATE_N && TAX_RATE_N.length > 0 && SS_RATE_N && SS_RATE_N.length > 0) {
      // =D25/(D25+I25)
      let D25 = TAX_RATE_N[0][2];
      let I25 = SS_RATE_N[0][2];
      setB30((Number(D25) / (Number(D25) + Number(I25))) * 100);
    }
  }, [TAX_RATE_N, SS_RATE_N]);

  useEffect(() => {
    if (TAX_RATE_N && TAX_RATE_N.length > 0 && SS_RATE_N && SS_RATE_N.length > 0) {
      // =I25/(I25+D25)
      let I25 = SS_RATE_N[0][2];
      let D25 = TAX_RATE_N[0][2];
      setB31((Number(I25) / (Number(I25) + Number(D25))) * 100);
    }
  }, [TAX_RATE_N, SS_RATE_N]);

  useEffect(() => {
    setB32(Number(B30) + Number(B31));
  }, [B30, B31]);

  useEffect(() => {
    // =IF('Split payroll'!L48>0;(D_GROSS_HOLLAND*C_DED_BE_SS);-D_SS_HOLLAND)
    if (Number(D_SS_PER) > 0) {
      setD_SS_HOLLAND_DEDUCT(Number(D_GROSS_COUNTRY) * (Number(C_DED_BE_SS) / 100));
    } else {
      setD_SS_HOLLAND_DEDUCT(Number(D_SS_COUNTRY));
    }
  }, [D_SS_PER, D_GROSS_COUNTRY, C_DED_BE_SS, D_SS_COUNTRY]);

  useEffect(() => {
    // =D_GROSS_HOLLAND-D_SS_HOLLAND_DEDUCT+'Split payroll'!F74
    setC_NET_TAXABLE_COUNTRY(Number(D_GROSS_COUNTRY) - Number(D_SS_HOLLAND_DEDUCT));
  }, [D_GROSS_COUNTRY, D_SS_HOLLAND_DEDUCT]);

  useEffect(() => {
    // =IF(B7=1;ROUND((C_NET_TAXABLE_HOLLAND*0,3);2);0)
    if (Number(D_TAX_OPTION_COUNTRY) === 1) {
      setC_30PERC_ALLOWANCE(C_NET_TAXABLE_COUNTRY * 0.3);
    } else {
      setC_30PERC_ALLOWANCE(0);
    }
  }, [D_TAX_OPTION_COUNTRY, C_NET_TAXABLE_COUNTRY]);

  useEffect(() => {
    setC_NET_TAXABLE_COUNTRY2(Number(C_NET_TAXABLE_COUNTRY) - Number(C_30PERC_ALLOWANCE));
  }, [C_NET_TAXABLE_COUNTRY, C_30PERC_ALLOWANCE]);

  useEffect(() => {
    if (GENERAL_REBATE && GENERAL_REBATE.length > 0) {
      // =IF(C_NET_TAXABLE_HOLLAND2>B13;D13;IF(C_NET_TAXABLE_HOLLAND2>B12;D12+(C_NET_TAXABLE_HOLLAND2-B12)*E12;IF(C_NET_TAXABLE_HOLLAND2>0;D11;0)))
      let B13 = GENERAL_REBATE[2][0];
      let D13 = GENERAL_REBATE[2][2];
      let B12 = GENERAL_REBATE[1][0];
      let D12 = GENERAL_REBATE[1][2];
      let E12 = GENERAL_REBATE[1][3];
      let D11 = GENERAL_REBATE[0][2];

      if (Number(C_NET_TAXABLE_COUNTRY2) > Number(B13)) {
        setE42(Number(D13));
      } else if (Number(C_NET_TAXABLE_COUNTRY2) > Number(B12)) {
        setE42(Number(D12) + (Number(C_NET_TAXABLE_COUNTRY2) - Number(B12)) * (Number(E12) / 100));
      } else if (Number(C_NET_TAXABLE_COUNTRY2) > 0) {
        setE42(Number(D11));
      } else {
        setE42(0);
      }
    }
  }, [C_NET_TAXABLE_COUNTRY2, GENERAL_REBATE]);

  useEffect(() => {
    if (TAX_RATE_N && TAX_RATE_N.length > 0) {
      // =ROUND((C_NET_TAXABLE_HOLLAND2-VLOOKUP(C_NET_TAXABLE_HOLLAND2;B25:E28;1))*VLOOKUP(C_NET_TAXABLE_HOLLAND2;B25:E28;3)+VLOOKUP(C_NET_TAXABLE_HOLLAND2;B25:E28;4);0)
      const VLOOKUP_4 = vLookup(C_NET_TAXABLE_COUNTRY2, TAX_RATE_N, 4);
      const VLOOKUP_3 = vLookup(C_NET_TAXABLE_COUNTRY2, TAX_RATE_N, 3);
      const VLOOKUP_1 = vLookup(C_NET_TAXABLE_COUNTRY2, TAX_RATE_N, 1);

      setC_BASE_TAX_COUNTRY(
        (Number(C_NET_TAXABLE_COUNTRY2) - Number(VLOOKUP_1)) * (Number(VLOOKUP_3) / 100) +
          Number(VLOOKUP_4)
      );
    }
  }, [C_NET_TAXABLE_COUNTRY2, TAX_RATE_N]);

  useEffect(() => {
    // =IF(C_BASE_TAX_HOLLAND>0;ROUND((E42*B30);0);0)
    if (Number(C_BASE_TAX_COUNTRY) > 0) {
      setC_GENERAL_REBATE(Number(E42) * (Number(B30) / 100));
    } else {
      setC_GENERAL_REBATE(0);
    }
  }, [C_BASE_TAX_COUNTRY, E42, B30]);

  useEffect(() => {
    if (EMPLOYMENT_REBATE && EMPLOYMENT_REBATE.length > 0) {
      // =ROUND(IF(C_NET_TAXABLE_HOLLAND2<C18;C_NET_TAXABLE_HOLLAND2*E18;IF(C_NET_TAXABLE_HOLLAND2>B22;D22;IF(C_NET_TAXABLE_HOLLAND2<C19;D19+(C_NET_TAXABLE_HOLLAND2-B19)*E19;IF(C_NET_TAXABLE_HOLLAND2<C20;D20;D21+E21*(C_NET_TAXABLE_HOLLAND2-B21)))));0)
      let C18 = EMPLOYMENT_REBATE[0][1];
      let E18 = EMPLOYMENT_REBATE[0][3];
      let B22 = EMPLOYMENT_REBATE[4][0];
      let D22 = EMPLOYMENT_REBATE[4][2];
      let C19 = EMPLOYMENT_REBATE[1][1];
      let D19 = EMPLOYMENT_REBATE[1][2];
      let B19 = EMPLOYMENT_REBATE[1][0];
      let E19 = EMPLOYMENT_REBATE[1][3];
      let C20 = EMPLOYMENT_REBATE[2][1];
      let D20 = EMPLOYMENT_REBATE[2][2];
      let D21 = EMPLOYMENT_REBATE[3][2];
      let E21 = EMPLOYMENT_REBATE[3][3];
      let B21 = EMPLOYMENT_REBATE[3][0];

      if (Number(C_NET_TAXABLE_COUNTRY2) < Number(C18)) {
        setE45(Number(C_NET_TAXABLE_COUNTRY2) * (Number(E18) / 100));
      } else if (Number(C_NET_TAXABLE_COUNTRY2) > Number(B22)) {
        setE45(Number(D22));
      } else if (Number(C_NET_TAXABLE_COUNTRY2) < Number(C19)) {
        setE45(Number(D19) + (Number(C_NET_TAXABLE_COUNTRY2) - Number(B19)) * (Number(E19) / 100));
      } else if (Number(C_NET_TAXABLE_COUNTRY2) < Number(C20)) {
        setE45(Number(D20));
      } else {
        setE45(Number(D21) + (Number(E21) / 100) * (Number(C_NET_TAXABLE_COUNTRY2) - Number(B21)));
      }
    }
  }, [C_NET_TAXABLE_COUNTRY2, EMPLOYMENT_REBATE]);

  useEffect(() => {
    if (EMPLOYMENT_REBATE && EMPLOYMENT_REBATE.length > 0) {
      // =MIN(D21;ROUND(E45*$B$30;0))
      let D21 = EMPLOYMENT_REBATE[3][2];

      setC_EMPLOYMENT_REBATE(Math.min(Number(D21), Number(E45) * (Number(B30) / 100)));
    }
  }, [EMPLOYMENT_REBATE, E45, B30]);

  useEffect(() => {
    // =MAX(0;C_BASE_TAX_HOLLAND-C_GENERAL_REBATE-C_EMPLOYMENT_REBATE)
    setC_TAX_HOLLAND(
      Math.max(
        0,
        Number(C_BASE_TAX_COUNTRY) - Number(C_GENERAL_REBATE) - Number(C_EMPLOYMENT_REBATE)
      )
    );
  }, [C_BASE_TAX_COUNTRY, C_GENERAL_REBATE, C_EMPLOYMENT_REBATE]);

  useEffect(() => {
    if (COUNTRY_IDX) {
      props.onCalculationUpdate({
        COUNTRY_IDX: COUNTRY_IDX,
        C_TAXES_COUNTRY: C_TAX_HOLLAND,
      });
    }
  }, [COUNTRY_IDX, C_TAX_HOLLAND]);

  return (
    <React.Fragment>
      <Modal
        size="xl"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">{t("NETHERLANDS")}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label={t("Close")}
          ></button>
        </div>

        <ModalBody>
          <Card>
            <CardHeader>
              <CardTitle>1. {t("PARAMETERS")}</CardTitle>
            </CardHeader>

            <CardBody>
              <div className="table-responsive mb-3">
                <Table className="table table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr>
                      <td>{t("Deductible part of Belgian social security")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_DED_BE_SS).toFixed(4), false)}%
                      </td>
                      <td>(73% of 13,07%)</td>
                    </tr>
                  </tbody>
                </Table>
              </div>

              <Row>
                <Col>
                  <div className="table-responsive mb-3">
                    <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                      <tbody>
                        <tr className="align-bottom">
                          <th colSpan={4} className="text-center">
                            {t("Calculation of the general rebate (General tax credit)")}
                          </th>
                        </tr>
                        {GENERAL_REBATE &&
                          GENERAL_REBATE.map((item: any, idx: number) => (
                            <tr key={idx} className="align-bottom">
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[0]).toFixed(2), false)}
                              </td>
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[1]).toFixed(2), false)}
                              </td>
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[2]).toFixed(2), false)}
                              </td>
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[3]).toFixed(4), false)}%
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>

                <Col>
                  <div className="table-responsive mb-3">
                    <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                      <tbody>
                        <tr className="align-bottom">
                          <th colSpan={4} className="text-center">
                            {t("Calculation of the employment rebate (Labour discount)")}
                          </th>
                        </tr>
                        {EMPLOYMENT_REBATE &&
                          EMPLOYMENT_REBATE.map((item: any, idx: number) => (
                            <tr key={idx} className="align-bottom">
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[0]).toFixed(2), false)}
                              </td>
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[1]).toFixed(2), false)}
                              </td>
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[2]).toFixed(2), false)}
                              </td>
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[3]).toFixed(4), false)}%
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div className="table-responsive mb-3">
                    <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                      <tbody>
                        <tr className="align-bottom">
                          <th colSpan={4} className="text-center">
                            {t("Tax rates")}
                          </th>
                        </tr>
                        {TAX_RATE_N &&
                          TAX_RATE_N.map((item: any, idx: number) => (
                            <tr key={idx} className="align-bottom">
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[0]).toFixed(2), false)}
                              </td>
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[1]).toFixed(2), false)}
                              </td>
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[2]).toFixed(2), false)}%
                              </td>
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[3]).toFixed(2), false)}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
                <Col>
                  <div className="table-responsive mb-3">
                    <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                      <tbody>
                        <tr className="align-bottom">
                          <th colSpan={4} className="text-center">
                            {t("Social security rates")}
                          </th>
                        </tr>
                        {SS_RATE_N &&
                          SS_RATE_N.map((item: any, idx: number) => (
                            <tr key={idx} className="align-bottom">
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[0]).toFixed(2), false)}
                              </td>
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[1]).toFixed(2), false)}
                              </td>
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[2]).toFixed(2), false)}%
                              </td>
                              <td className="text-end">
                                {formatCurrency(parseFloat(item[3]).toFixed(2), false)}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>

              <div className="table-responsive mb-3">
                <Table className="table table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr>
                      <td>{t("Tax / (Tax+SS) in 1st bracket")}</td>
                      <td className="text-end">{formatCurrency(Number(B30).toFixed(4), false)}%</td>
                    </tr>
                    <tr>
                      <td>{t("SS / (Tax+SS) in 1st bracket")}</td>
                      <td className="text-end">{formatCurrency(Number(B31).toFixed(4), false)}%</td>
                    </tr>
                    <tr>
                      <th>{t("Total")}</th>
                      <th className="text-end">{formatCurrency(Number(B32).toFixed(4), false)}%</th>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <CardTitle>2. {t("COMPUTATION")}</CardTitle>
            </CardHeader>

            <CardBody>
              <div className="table-responsive mb-3">
                <Table className="table table-hover nowrap w-100 mb-0">
                  <tbody>
                    <tr>
                      <td>
                        {t("GROSS")} {t("NETHERLANDS")}
                      </td>
                      <td className="text-end">
                        {formatCurrency(Number(D_GROSS_COUNTRY).toFixed(2), false)}
                      </td>
                      <td colSpan={2}></td>
                    </tr>
                    <tr>
                      <td>{t("SS NETHERLANDS DEDUCT")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(D_SS_HOLLAND_DEDUCT).toFixed(2), false)}
                      </td>
                      <td colSpan={2}></td>
                    </tr>

                    <tr>
                      <td colSpan={4}></td>
                    </tr>

                    <tr>
                      <td>{t("NET TAXABLE")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_NET_TAXABLE_COUNTRY).toFixed(2), false)}
                      </td>
                      <td colSpan={2}></td>
                    </tr>
                    <tr>
                      <td>30 % {t("ALLOWANCE")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_30PERC_ALLOWANCE).toFixed(2), false)}
                      </td>
                      <td colSpan={2}></td>
                    </tr>

                    <tr>
                      <td colSpan={2}></td>
                      <td className="text-end">{t("Calculation general rebate")}</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td>{t("NET TAXABLE")} (2)</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_NET_TAXABLE_COUNTRY2).toFixed(2), false)}
                      </td>
                      <td className="text-end">{formatCurrency(Number(E42).toFixed(2), false)}</td>
                      <td></td>
                    </tr>

                    <tr>
                      <td colSpan={4}></td>
                    </tr>

                    <tr>
                      <td>{t("BASE TAX")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_BASE_TAX_COUNTRY).toFixed(2), false)}
                      </td>
                      <td className="text-end">{t("Calculation employment rebate")}</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>{t("GENERAL REBATE")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_GENERAL_REBATE).toFixed(0), false)}
                      </td>
                      <td className="text-end">{formatCurrency(Number(E45).toFixed(0), false)}</td>
                      <td>{t("Not yet multiplied with tax rate")}</td>
                    </tr>
                    <tr>
                      <td>{t("EMPLOYMENT REBATE")}</td>
                      <td className="text-end">
                        {formatCurrency(Number(C_EMPLOYMENT_REBATE).toFixed(0), false)}
                      </td>
                      <td colSpan={2}></td>
                    </tr>

                    <tr>
                      <td colSpan={4}></td>
                    </tr>

                    <tr>
                      <td>
                        {t("TAX")} {t("NETHERLANDS")}
                      </td>
                      <td className="text-end">
                        {formatCurrency(Number(C_TAX_HOLLAND).toFixed(0), false)}
                      </td>
                      <td colSpan={2}></td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </ModalBody>

        <ModalFooter>
          <Button color="danger" className="btn-block" type="reset" onClick={props.onCloseClick}>
            {t("Close")}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default SSSCModel_150;
