import React, { Component } from "react";
import { Link } from "react-router-dom";

import SidebarContent from "./SidebarContent";

import logoLight from "../../assets/images/logos/tc-logo-white-wide-with-name.png";
import logoDark from "../../assets/images/logos/tc-logo-red-wide-with-name.png";
import logoLightBlock from "../../assets/images/logos/tc-logo-white-block-without-name.png";
import logoDarkBlock from "../../assets/images/logos/tc-logo-red-block-without-name.png";

class Sidebar extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="vertical-menu">
          <div className="navbar-brand-box">
            <Link to="/dashboard" className="logo logo-dark">
              <span className="logo-sm">
                <img src={logoDarkBlock} alt="TC" height="36" />
              </span>
              <span className="logo-lg">
                <img src={logoDark} alt="Tax Consult" height="36" />
              </span>
            </Link>

            <Link to="/dashboard" className="logo logo-light">
              <span className="logo-sm">
                <img src={logoLightBlock} alt="TC" height="36" />
              </span>
              <span className="logo-lg">
                <img src={logoLight} alt="Tax Consult" height="36" />
              </span>
            </Link>
          </div>
          <div data-simplebar className="h-100">
            <SidebarContent />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Sidebar;
