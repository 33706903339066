import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class VatService {

    async createVat(data: any) {
        const response = await axios
            .post(
                SERVER_URL + url.CREATE_VAT,
                data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async updateVat(data: any) {
        const response = await axios
            .put(
                SERVER_URL + url.UPDATE_VAT,
                data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async deleteVat(id: any) {
        const response = await axios
            .delete(
                SERVER_URL + url.DELETE_VAT + '/' + id,
                {
                    headers: getAxiosConfigWithToken()
                }
            );
        return response.data;
    }

    async getAllVat(data: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_All_VAT,
                data,
                {
                    headers: getAxiosConfigWithToken(),
                }
            ).then(function (response: any) {
                return response;
            })
            .catch(function (error: any) {
                return error;
            });
        return response;
    }


    async getVat(id: number) {
        const response = await axios
            .get(
                SERVER_URL + url.GET_VAT + '/' + id,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

}

export default new VatService();