import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Form,
  Label,
  Input,
  Button,
  FormFeedback,
  Table,
} from "reactstrap";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { initialValues } from "./helper";
import { yearsBackword, getFirstErrorElement } from "../../../helpers/functions_helper";
import { useTranslation } from "react-i18next";

const SplitPayrollYearForm = (props: any) => {
  const { t } = useTranslation();
  const formData = props.formData;
  const [initialData, setInitialData] = useState(initialValues);
  const [initialP_TAXES_EXEMPT_AMOUNT, setInitialP_TAXES_EXEMPT_AMOUNT] = useState(
    initialValues.P_TAXES_EXEMPT_AMOUNT
  );
  const [initialP_TAXES, setInitialP_TAXES] = useState(initialValues.P_TAXES);
  const [initialP_CHILDREN, setInitialP_CHILDREN] = useState(initialValues.P_CHILDREN);
  const [initialP_Expenses, setInitialP_Expenses] = useState(initialValues.P_Expenses);
  const [initialCompanyCarStatuses, setInitialCompanyCarStatuses] = useState(
    initialValues.companyCarStatuses
  );
  const [initialSS_SOCIAL_SECURITY, setInitialSS_SOCIAL_SECURITY] = useState(
    initialValues.SS_SOCIAL_SECURITY
  );

  // FRANCE
  const [initialRAS, setInitialRAS] = useState(initialValues.RAS);
  const [initialFINAL_TAX, setInitialFINAL_TAX] = useState(initialValues.FINAL_TAX);

  // GERMANY
  const [initialTAX_RATE_G, setInitialTAX_RATE_G] = useState(initialValues.TAX_RATE_G);

  // POLAND
  const [initialPERSONAL_ALLOWANCE_P, setInitialPERSONAL_ALLOWANCE_P] = useState(
    initialValues.PERSONAL_ALLOWANCE_P
  );
  const [initialTAX_RATE_P, setInitialTAX_RATE_P] = useState(initialValues.TAX_RATE_P);

  // SPAIN-
  const [initialTAX_RATE_S, setInitialTAX_RATE_S] = useState(initialValues.TAX_RATE_S);
  const [initialEMPLOYMENT_REDUCTION, setInitialEMPLOYMENT_REDUCTION] = useState(
    initialValues.EMPLOYMENT_REDUCTION
  );

  // NETHERLANDS
  const [initialGENERAL_REBATE, setInitialGENERAL_REBATE] = useState(initialValues.GENERAL_REBATE);
  const [initialEMPLOYMENT_REBATE, setInitialEMPLOYMENT_REBATE] = useState(
    initialValues.EMPLOYMENT_REBATE
  );
  const [initialTAX_RATE_N, setInitialTAX_RATE_N] = useState(initialValues.TAX_RATE_N);
  const [initialSS_RATE_N, setInitialSS_RATE_N] = useState(initialValues.SS_RATE_N);

  // UNITED KINGDOM
  const [initialTAX_RATE_UK, setInitialTAX_RATE_UK] = useState(initialValues.TAX_RATE_UK);

  // UNITED STATES
  const [initialPERS_ALL_USA, setInitialPERS_ALL_USA] = useState(initialValues.PERS_ALL_USA);
  const [initialPERS_ALL_USA_MAR, setInitialPERS_ALL_USA_MAR] = useState(
    initialValues.PERS_ALL_USA_MAR
  );
  const [initialTAX_RATE_USA_SINGLE, setInitialTAX_RATE_USA_SINGLE] = useState(
    initialValues.TAX_RATE_USA_SINGLE
  );
  const [initialTAX_RATE_USA_MARRIED, setInitialTAX_RATE_USA_MARRIED] = useState(
    initialValues.TAX_RATE_USA_MARRIED
  );

  Yup.setLocale({
    mixed: {
      required: "${label} " + t("field is required."),
    },
  });
  const validationSchema = Yup.object().shape({
    computation_year: Yup.string().label(t("Computation year")).required(),
  });
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleChange = (fieldName: string, fieldValue: any) => {
    setInitialData({ ...initialData, [fieldName]: fieldValue });
  };

  const handleP_TAXES_EXEMPT_AMOUNT = (rowIndex: number, fieldValue: number, idx: number) => {
    var newData: any = [...initialP_TAXES_EXEMPT_AMOUNT];
    newData[idx][rowIndex] = fieldValue;
    newData[idx + 1][0] = Number(fieldValue);

    var newData2: any = [...newData];
    newData2.map((item: any, index: number) => {
      if (index !== 0) {
        newData2[index][3] =
          Number(newData2[index - 1][3]) +
          (Number(newData2[index - 1][1]) - Number(newData2[index - 1][0])) *
            Number(newData2[index - 1][2]);
      }
    });
    setInitialP_TAXES_EXEMPT_AMOUNT(newData2);
    handleChange("P_TAXES_EXEMPT_AMOUNT", newData2);
  };

  const handleP_TAXES = (rowIndex: number, fieldValue: number, idx: number) => {
    var newData: any = [...initialP_TAXES];
    newData[idx][rowIndex] = fieldValue;
    newData[idx + 1][0] = Number(fieldValue);

    var newData2: any = [...newData];
    newData2.map((item: any, index: number) => {
      if (index !== 0) {
        newData2[index][3] =
          Number(newData2[index - 1][3]) +
          (Number(newData2[index - 1][1]) - Number(newData2[index - 1][0])) *
            Number(newData2[index - 1][2]);
      }
    });
    setInitialP_TAXES(newData2);
    handleChange("P_TAXES", newData2);
  };

  const handleP_CHILDREN = (rowIndex: number, fieldValue: number, idx: number) => {
    var newData: any = [...initialP_CHILDREN];
    newData[idx][rowIndex] = fieldValue;

    var newData2: any = [...newData];
    newData2.map((item: any, index: number) => {
      if (index >= 5) {
        newData2[index][3] = Number(newData2[index - 1][3]) + Number(newData2[5][1]);
      }
    });

    setInitialP_CHILDREN(newData2);
    handleChange("P_CHILDREN", newData2);
  };

  const handleP_Expenses = (rowIndex: number, fieldValue: number, idx: number) => {
    var newData: any = [...initialP_Expenses];
    newData[idx][rowIndex] = fieldValue;
    newData[idx + 1][0] = Number(fieldValue);

    var newData2: any = [...newData];
    newData2.map((item: any, index: number) => {
      if (index !== 0) {
        newData2[index][3] =
          Number(newData2[index - 1][3]) +
          (Number(newData2[index - 1][1]) - Number(newData2[index - 1][0])) *
            Number(newData2[index - 1][2]);
      }
    });
    setInitialP_Expenses(newData2);
    handleChange("P_Expenses", newData2);
  };

  const handleCompanyCarStatuses = (fieldName: string, fieldValue: number, idx: number) => {
    var newData: any = [...initialCompanyCarStatuses];
    newData[idx][fieldName] = fieldValue;
    setInitialCompanyCarStatuses(newData);
    handleChange("companyCarStatuses", newData);
  };

  const handleSS_SOCIAL_SECURITY = (rowIndex: number, fieldValue: number, idx: number) => {
    var newData: any = [...initialSS_SOCIAL_SECURITY];
    newData[idx][rowIndex] = fieldValue;
    if (rowIndex === 1) {
      newData[idx + 1][0] = Number(fieldValue);
    }

    var newData2: any = [...newData];
    newData2.map((item: any, index: number) => {
      if (index !== 0) {
        // (D122-C122)*E122+F122
        newData2[index][3] =
          (Number(newData2[index - 1][1]) - Number(newData2[index - 1][0])) *
            (Number(newData2[index - 1][2]) / 100) +
          Number(newData2[index - 1][3]);
      }
    });
    setInitialSS_SOCIAL_SECURITY(newData2);
    handleChange("SS_SOCIAL_SECURITY", newData2);
  };

  const handleRAS = (rowIndex: number, fieldValue: number, idx: number) => {
    var newData: any = [...initialRAS];
    newData[idx][rowIndex] = fieldValue;
    if (rowIndex === 1) {
      newData[idx + 1][0] = Number(fieldValue);
    }

    var newData2: any = [...newData];
    newData2.map((item: any, index: number) => {
      if (index !== 0) {
        // (C9-B9)*D9
        newData2[index][3] =
          (Number(newData2[index - 1][1]) - Number(newData2[index - 1][0])) *
          (Number(newData2[index - 1][2]) / 100);
      }
    });
    setInitialRAS(newData2);
    handleChange("RAS", newData2);
  };

  const handleFINAL_TAX = (rowIndex: number, fieldValue: number, idx: number) => {
    var newData: any = [...initialFINAL_TAX];
    newData[idx][rowIndex] = fieldValue;
    if (rowIndex === 1) {
      newData[idx + 1][0] = Number(fieldValue);
    }

    var newData2: any = [...newData];
    newData2.map((item: any, index: number) => {
      if (index !== 0) {
        // (J10-I10)*K10
        newData2[index][3] =
          (Number(newData2[index - 1][1]) - Number(newData2[index - 1][0])) *
          (Number(newData2[index - 1][2]) / 100);
      }
    });
    setInitialFINAL_TAX(newData2);
    handleChange("FINAL_TAX", newData2);
  };

  const handleTAX_RATE_G = (rowIndex: number, fieldValue: number, idx: number) => {
    var newData: any = [...initialTAX_RATE_G];
    newData[idx][rowIndex] = fieldValue;
    if (rowIndex === 1) {
      newData[idx + 1][0] = Number(fieldValue);
    }
    setInitialTAX_RATE_G(newData);
    handleChange("TAX_RATE_G", newData);
  };

  const handlePERSONAL_ALLOWANCE_P = (rowIndex: number, fieldValue: number, idx: number) => {
    var newData: any = [...initialPERSONAL_ALLOWANCE_P];
    newData[idx][rowIndex] = fieldValue;
    if (rowIndex === 1) {
      newData[idx + 1][0] = Number(fieldValue);
    }
    setInitialPERSONAL_ALLOWANCE_P(newData);
    handleChange("PERSONAL_ALLOWANCE_P", newData);
  };

  const handleTAX_RATE_P = (rowIndex: number, fieldValue: number, idx: number) => {
    var newData: any = [...initialTAX_RATE_P];
    newData[idx][rowIndex] = fieldValue;
    if (rowIndex === 1) {
      newData[idx + 1][0] = Number(fieldValue);
    }

    var newData2: any = [...newData];
    newData2.map((item: any, index: number) => {
      if (index !== 0) {
        // (C16-B16)*D16
        newData2[index][3] =
          (Number(newData2[index - 1][1]) - Number(newData2[index - 1][0])) *
          (Number(newData2[index - 1][2]) / 100);
      }
    });

    setInitialTAX_RATE_P(newData2);
    handleChange("TAX_RATE_P", newData2);
  };

  const handleTAX_RATE_S = (rowIndex: number, fieldValue: number, idx: number) => {
    var newData: any = [...initialTAX_RATE_S];
    newData[idx][rowIndex] = fieldValue;
    if (rowIndex === 1) {
      newData[idx + 1][0] = Number(fieldValue);
    }

    var newData2: any = [...newData];
    newData2.map((item: any, index: number) => {
      if (index !== 0) {
        // (B13-B12)*D12+E12
        newData2[index][3] =
          (Number(newData2[index][0]) - Number(newData2[index - 1][0])) *
            (Number(newData2[index - 1][2]) / 100) +
          Number(newData2[index - 1][3]);
      }
    });

    setInitialTAX_RATE_S(newData2);
    handleChange("TAX_RATE_S", newData2);
  };

  const handleEMPLOYMENT_REDUCTION = (rowIndex: number, fieldValue: number, idx: number) => {
    var newData: any = [...initialEMPLOYMENT_REDUCTION];
    newData[idx][rowIndex] = fieldValue;
    if (rowIndex === 1) {
      newData[idx + 1][0] = Number(fieldValue);
    }

    var newData2: any = [...newData];
    newData2.map((item: any, index: number) => {
      if (index !== 0) {
        // (B21-B20)*D20+E20
        newData2[index][3] =
          (Number(newData2[index][0]) - Number(newData2[index - 1][0])) *
            (Number(newData2[index - 1][2]) / 100) +
          Number(newData2[index - 1][3]);
      }
    });

    setInitialEMPLOYMENT_REDUCTION(newData2);
    handleChange("EMPLOYMENT_REDUCTION", newData2);
  };

  const handleGENERAL_REBATE = (rowIndex: number, fieldValue: number, idx: number) => {
    var newData: any = [...initialGENERAL_REBATE];
    newData[idx][rowIndex] = fieldValue;
    if (rowIndex === 1) {
      newData[idx + 1][0] = Number(fieldValue);
    }
    setInitialGENERAL_REBATE(newData);
    handleChange("GENERAL_REBATE", newData);
  };

  const handleEMPLOYMENT_REBATE = (rowIndex: number, fieldValue: number, idx: number) => {
    var newData: any = [...initialEMPLOYMENT_REBATE];
    newData[idx][rowIndex] = fieldValue;
    if (rowIndex === 1) {
      newData[idx + 1][0] = Number(fieldValue);
    }
    setInitialEMPLOYMENT_REBATE(newData);
    handleChange("EMPLOYMENT_REBATE", newData);
  };

  const handleTAX_RATE_N = (rowIndex: number, fieldValue: number, idx: number) => {
    var newData: any = [...initialTAX_RATE_N];
    newData[idx][rowIndex] = fieldValue;
    if (rowIndex === 1) {
      newData[idx + 1][0] = Number(fieldValue);
    }

    var newData2: any = [...newData];
    newData2.map((item: any, index: number) => {
      if (index !== 0) {
        // (B26-B25)*D25+E25
        newData2[index][3] =
          (Number(newData2[index][0]) - Number(newData2[index - 1][0])) *
            (Number(newData2[index - 1][2]) / 100) +
          Number(newData2[index - 1][3]);
      }
    });

    setInitialTAX_RATE_N(newData2);
    handleChange("TAX_RATE_N", newData2);
  };

  const handleSS_RATE_N = (rowIndex: number, fieldValue: number, idx: number) => {
    var newData: any = [...initialSS_RATE_N];
    newData[idx][rowIndex] = fieldValue;
    if (rowIndex === 1) {
      newData[idx + 1][0] = Number(fieldValue);
    }

    var newData2: any = [...newData];
    newData2.map((item: any, index: number) => {
      if (index !== 0) {
        // (G26-G25)*I25+J25
        newData2[index][3] =
          (Number(newData2[index][0]) - Number(newData2[index - 1][0])) *
            (Number(newData2[index - 1][2]) / 100) +
          Number(newData2[index - 1][3]);
      }
    });

    setInitialSS_RATE_N(newData);
    handleChange("SS_RATE_N", newData);
  };

  const handleTAX_RATE_UK = (rowIndex: number, fieldValue: number, idx: number) => {
    var newData: any = [...initialTAX_RATE_UK];
    newData[idx][rowIndex] = fieldValue;
    if (rowIndex === 1) {
      newData[idx + 1][0] = Number(fieldValue);
    }

    var newData2: any = [...newData];
    newData2.map((item: any, index: number) => {
      if (index !== 0) {
        // (C12-B12)*D12+E12
        newData2[index][3] =
          (Number(newData2[index - 1][1]) - Number(newData2[index - 1][0])) *
            (Number(newData2[index - 1][2]) / 100) +
          Number(newData2[index - 1][3]);
      }
    });

    setInitialTAX_RATE_UK(newData2);
    handleChange("TAX_RATE_UK", newData2);
  };

  const handlePERS_ALL_USA = (rowIndex: number, fieldValue: number, idx: number) => {
    var newData: any = [...initialPERS_ALL_USA];
    newData[idx][rowIndex] = fieldValue;
    setInitialPERS_ALL_USA(newData);
    handleChange("PERS_ALL_USA", newData);
  };

  const handlePERS_ALL_USA_MAR = (rowIndex: number, fieldValue: number, idx: number) => {
    var newData: any = [...initialPERS_ALL_USA_MAR];
    newData[idx][rowIndex] = fieldValue;
    setInitialPERS_ALL_USA_MAR(newData);
    handleChange("PERS_ALL_USA_MAR", newData);
  };

  const handleTAX_RATE_USA_SINGLE = (rowIndex: number, fieldValue: number, idx: number) => {
    var newData: any = [...initialTAX_RATE_USA_SINGLE];
    newData[idx][rowIndex] = fieldValue;
    if (rowIndex === 1) {
      newData[idx + 1][0] = Number(fieldValue);
    }

    var newData2: any = [...newData];
    newData2.map((item: any, index: number) => {
      if (index !== 0) {
        // (C58-B58)*D58+E58
        newData2[index][3] =
          (Number(newData2[index - 1][1]) - Number(newData2[index - 1][0])) *
            (Number(newData2[index - 1][2]) / 100) +
          Number(newData2[index - 1][3]);
      }
    });

    setInitialTAX_RATE_USA_SINGLE(newData2);
    handleChange("TAX_RATE_USA_SINGLE", newData2);
  };

  const handleTAX_RATE_USA_MARRIED = (rowIndex: number, fieldValue: number, idx: number) => {
    var newData: any = [...initialTAX_RATE_USA_MARRIED];
    newData[idx][rowIndex] = fieldValue;
    if (rowIndex === 1) {
      newData[idx + 1][0] = Number(fieldValue);
    }

    var newData2: any = [...newData];
    newData2.map((item: any, index: number) => {
      if (index !== 0) {
        // (I58-H58)*J58+K58
        newData2[index][3] =
          (Number(newData2[index - 1][1]) - Number(newData2[index - 1][0])) *
            (Number(newData2[index - 1][2]) / 100) +
          Number(newData2[index - 1][3]);
      }
    });

    setInitialTAX_RATE_USA_MARRIED(newData2);
    handleChange("TAX_RATE_USA_MARRIED", newData2);
  };

  const handleSubmit = (values: formTypes_SplitPayrollYear) => {
    props.onSubmitClick(values);
  };

  useEffect(() => {
    if (formData) {
      // setInitialData(formData);

      setInitialData((prevData: any) => ({
        ...prevData,
        id: formData.id,
        split_payroll_year_id: formData.split_payroll_year_id,
        computation_year: Number(formData.computation_year),
        tax_free_amount_for_attribution_to_spouse: Number(
          formData.tax_free_amount_for_attribution_to_spouse
        ),
        tax_free_amount_for_children_less_3_year_old: Number(
          formData.tax_free_amount_for_children_less_3_year_old
        ),
        tax_free_amount_for_single_parents: Number(formData.tax_free_amount_for_single_parents),
        tax_free_amount_for_taxpayer: Number(formData.tax_free_amount_for_taxpayer),
        tax_free_amount_for_spouse: Number(formData.tax_free_amount_for_spouse),
        business_expenses_director_percentage: Number(
          formData.business_expenses_director_percentage
        ),
        business_expenses_director_maximum_amount: Number(
          formData.business_expenses_director_maximum_amount
        ),
        commuting_exemption: Number(formData.commuting_exemption),
        attribution_to_spouse: Number(formData.attribution_to_spouse),
        min_bik_per_year: Number(formData.min_bik_per_year),
        revaluation_factor: Number(formData.revaluation_factor),

        P_TAXES_EXEMPT_AMOUNT: JSON.parse(formData.P_TAXES_EXEMPT_AMOUNT),
        P_TAXES: JSON.parse(formData.P_TAXES),
        P_CHILDREN: JSON.parse(formData.P_CHILDREN),
        P_Expenses: JSON.parse(formData.P_Expenses),
        companyCarStatuses: JSON.parse(formData.companyCarStatuses),
        SS_SOCIAL_SECURITY: JSON.parse(formData.SS_SOCIAL_SECURITY),

        // ESTONIA
        EST_TAX_RATE: Number(formData.EST_TAX_RATE),

        // FRANCE
        DED_10_MIN: Number(formData.DED_10_MIN),
        DED_10_MAX: Number(formData.DED_10_MAX),
        WORK_DAYS: Number(formData.WORK_DAYS),
        RAS: JSON.parse(formData.RAS),
        FINAL_TAX: JSON.parse(formData.FINAL_TAX),

        // GERMANY
        C_WERBUNG: Number(formData.C_WERBUNG),
        C_SONDER: Number(formData.C_SONDER),
        C_SPECIAL_EXP: Number(formData.C_SPECIAL_EXP),
        C_REBATE_TREATY: Number(formData.C_REBATE_TREATY),
        C_SOLIDARITY: Number(formData.C_SOLIDARITY),
        C_SOLIDARITY_MIN_LIMIT: Number(formData.C_SOLIDARITY_MIN_LIMIT),
        C_SOLIDARITY_MIN_PERC: Number(formData.C_SOLIDARITY_MIN_PERC),
        TAX_RATE_G: JSON.parse(formData.TAX_RATE_G),
        GER_TAX_RATE: Number(formData.GER_TAX_RATE),

        // POLAND
        POL_TAX_RATE: Number(formData.POL_TAX_RATE),
        EXCHANGE_RATE_POL: Number(formData.EXCHANGE_RATE_POL),
        PERSONAL_ALLOWANCE_P: JSON.parse(formData.PERSONAL_ALLOWANCE_P),
        TAX_RATE_P: JSON.parse(formData.TAX_RATE_P),
        STANDARD_DEDUCTION: Number(formData.STANDARD_DEDUCTION),

        // ROMANIA
        ROM_TAX_RATE: Number(formData.ROM_TAX_RATE),

        // SPAIN
        SPN_TAX_RATE: Number(formData.SPN_TAX_RATE),
        TAX_RATE_S: JSON.parse(formData.TAX_RATE_S),
        EMPLOYMENT_REDUCTION: JSON.parse(formData.EMPLOYMENT_REDUCTION),

        // NETHERLANDS
        C_DED_BE_SS: Number(formData.C_DED_BE_SS),
        GENERAL_REBATE: JSON.parse(formData.GENERAL_REBATE),
        EMPLOYMENT_REBATE: JSON.parse(formData.EMPLOYMENT_REBATE),
        TAX_RATE_N: JSON.parse(formData.TAX_RATE_N),
        SS_RATE_N: JSON.parse(formData.SS_RATE_N),

        // UNITED KINGDOM
        EXCHANGE_RATE_UK: Number(formData.EXCHANGE_RATE_UK),
        PERS_ALL_SINGLE: Number(formData.PERS_ALL_SINGLE),
        PERS_ALL_DEDUCTION: Number(formData.PERS_ALL_DEDUCTION),
        GBP_OVER_GBP: Number(formData.GBP_OVER_GBP),
        PERS_ALL_MARRIED: Number(formData.PERS_ALL_MARRIED),
        TAX_RATE_UK: JSON.parse(formData.TAX_RATE_UK),

        // UNITED STATES
        EXCHANGE_RATE_USA: Number(formData.EXCHANGE_RATE_USA),
        PERS_ALL_USA: JSON.parse(formData.PERS_ALL_USA),
        PERS_ALL_USA_MAR: JSON.parse(formData.PERS_ALL_USA_MAR),
        TAX_RATE_USA_SINGLE: JSON.parse(formData.TAX_RATE_USA_SINGLE),
        TAX_RATE_USA_MARRIED: JSON.parse(formData.TAX_RATE_USA_MARRIED),
        USA_STATE_TAX: Number(formData.USA_STATE_TAX),
      }));

      setInitialP_TAXES_EXEMPT_AMOUNT(JSON.parse(formData.P_TAXES_EXEMPT_AMOUNT));
      setInitialP_TAXES(JSON.parse(formData.P_TAXES));
      setInitialP_CHILDREN(JSON.parse(formData.P_CHILDREN));
      setInitialP_Expenses(JSON.parse(formData.P_Expenses));
      setInitialCompanyCarStatuses(JSON.parse(formData.companyCarStatuses));
      setInitialSS_SOCIAL_SECURITY(JSON.parse(formData.SS_SOCIAL_SECURITY));

      // FRANCE
      setInitialRAS(JSON.parse(formData.RAS));
      setInitialFINAL_TAX(JSON.parse(formData.FINAL_TAX));

      // GERMANY
      setInitialTAX_RATE_G(JSON.parse(formData.TAX_RATE_G));

      // POLAND
      setInitialPERSONAL_ALLOWANCE_P(JSON.parse(formData.PERSONAL_ALLOWANCE_P));
      setInitialTAX_RATE_P(JSON.parse(formData.TAX_RATE_P));

      // SPAIN-
      setInitialTAX_RATE_S(JSON.parse(formData.TAX_RATE_S));
      setInitialEMPLOYMENT_REDUCTION(JSON.parse(formData.EMPLOYMENT_REDUCTION));

      // NETHERLANDS
      setInitialGENERAL_REBATE(JSON.parse(formData.GENERAL_REBATE));
      setInitialEMPLOYMENT_REBATE(JSON.parse(formData.EMPLOYMENT_REBATE));
      setInitialTAX_RATE_N(JSON.parse(formData.TAX_RATE_N));
      setInitialSS_RATE_N(JSON.parse(formData.SS_RATE_N));

      // UNITED KINGDOM
      setInitialTAX_RATE_UK(JSON.parse(formData.TAX_RATE_UK));

      // UNITED STATES
      setInitialPERS_ALL_USA(JSON.parse(formData.PERS_ALL_USA));
      setInitialPERS_ALL_USA_MAR(JSON.parse(formData.PERS_ALL_USA_MAR));
      setInitialTAX_RATE_USA_SINGLE(JSON.parse(formData.TAX_RATE_USA_SINGLE));
      setInitialTAX_RATE_USA_MARRIED(JSON.parse(formData.TAX_RATE_USA_MARRIED));
    }
  }, [formData]);

  useEffect(() => {
    if (!validation.isSubmitting) return;
    if (Object.keys(validation.errors).length > 0) {
      const element = getFirstErrorElement(validationSchema, validation);
      document.getElementById(element)?.scrollIntoView({ behavior: "smooth", block: "center" });
      document.getElementById(element)?.focus({ preventScroll: true });
    }
  }, [validation.isSubmitting, validation.errors]);

  return (
    <React.Fragment>
      <Modal
        size="xl"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">{`${props.id ? t("Update") : t("Create")} ${t(
            "split payroll"
          )} ${t("year")}`}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label={t("Close")}
          ></button>
        </div>

        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          autoComplete="off"
        >
          <ModalBody>
            <Input id="id" name="id" type="hidden" value={initialData.id} />
            <Input
              id="split_payroll_year_id"
              name="split_payroll_year_id"
              type="hidden"
              value={initialData.split_payroll_year_id}
            />

            <Card>
              <CardBody>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">{t("Computation year")}</Label>
                      <Select
                        id="computation_year"
                        name="computation_year"
                        className="is-invalid"
                        options={yearsBackword()}
                        value={
                          initialData.computation_year
                            ? yearsBackword().find(
                                (option: any) => option.value == initialData.computation_year
                              )
                            : ""
                        }
                        onBlur={() => validation.setFieldTouched("computation_year", true)}
                        onChange={(e: any) => {
                          handleChange("computation_year", e.value);
                        }}
                      />
                      {validation.errors.computation_year ? (
                        <FormFeedback type="invalid">
                          {validation.errors.computation_year}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">
                        {t("Tax-free amount <br>for attribution to spouse")}
                      </Label>
                      <Input
                        id="tax_free_amount_for_attribution_to_spouse"
                        name="tax_free_amount_for_attribution_to_spouse"
                        className="form-control text-end"
                        type="text"
                        value={initialData.tax_free_amount_for_attribution_to_spouse}
                        onBlur={validation.handleBlur}
                        onChange={(e) =>
                          handleChange("tax_free_amount_for_attribution_to_spouse", e.target.value)
                        }
                        invalid={
                          validation.errors.tax_free_amount_for_attribution_to_spouse ? true : false
                        }
                      />
                      {validation.errors.tax_free_amount_for_attribution_to_spouse ? (
                        <FormFeedback type="invalid">
                          {validation.errors.tax_free_amount_for_attribution_to_spouse}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">
                        {t("Tax-free amount <br>for children < 3 years old")}
                      </Label>
                      <Input
                        id="tax_free_amount_for_children_less_3_year_old"
                        name="tax_free_amount_for_children_less_3_year_old"
                        className="form-control text-end"
                        type="text"
                        value={initialData.tax_free_amount_for_children_less_3_year_old}
                        onBlur={validation.handleBlur}
                        onChange={(e) =>
                          handleChange(
                            "tax_free_amount_for_children_less_3_year_old",
                            e.target.value
                          )
                        }
                        invalid={
                          validation.errors.tax_free_amount_for_children_less_3_year_old
                            ? true
                            : false
                        }
                      />
                      {validation.errors.tax_free_amount_for_children_less_3_year_old ? (
                        <FormFeedback type="invalid">
                          {validation.errors.tax_free_amount_for_children_less_3_year_old}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">
                        {t("Tax-free amount <br>for single parents")}
                      </Label>
                      <Input
                        id="tax_free_amount_for_single_parents"
                        name="tax_free_amount_for_single_parents"
                        className="form-control text-end"
                        type="text"
                        value={initialData.tax_free_amount_for_single_parents}
                        onBlur={validation.handleBlur}
                        onChange={(e) =>
                          handleChange("tax_free_amount_for_single_parents", e.target.value)
                        }
                        invalid={
                          validation.errors.tax_free_amount_for_single_parents ? true : false
                        }
                      />
                      {validation.errors.tax_free_amount_for_single_parents ? (
                        <FormFeedback type="invalid">
                          {validation.errors.tax_free_amount_for_single_parents}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Row>
                  {/* P_TAXES_EXEMPT_AMOUNT */}
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="table-responsive mb-3">
                      <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                        <thead>
                          <tr>
                            <th colSpan={4} className="text-center">
                              {t("Tax rates (for tax-exempt amounts)")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {initialP_TAXES_EXEMPT_AMOUNT &&
                            initialP_TAXES_EXEMPT_AMOUNT.map((item: any, idx: number) => (
                              <tr key={idx}>
                                <td className="text-end">{parseFloat(item[0]).toFixed(2)}</td>
                                <td className="text-end">
                                  {idx !== 4 ? (
                                    <Input
                                      className="form-control text-end"
                                      type="text"
                                      value={item[1]}
                                      onChange={(e: any) => {
                                        handleP_TAXES_EXEMPT_AMOUNT(1, e.target.value, idx);
                                      }}
                                    />
                                  ) : null}
                                </td>
                                <td className="text-end">{parseFloat(item[2]).toFixed(2)}</td>
                                <td className="text-end">{parseFloat(item[3]).toFixed(2)}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>

                  {/* P_TAXES */}
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="table-responsive mb-3">
                      <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                        <thead>
                          <tr>
                            <th colSpan={4} className="text-center">
                              {t("Tax rates (for tax calc)")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {initialP_TAXES &&
                            initialP_TAXES.map((item: any, idx: number) => (
                              <tr key={idx}>
                                <td className="text-end">{parseFloat(item[0]).toFixed(2)}</td>
                                <td className="text-end">
                                  {idx !== 3 ? (
                                    <Input
                                      className="form-control text-end"
                                      type="text"
                                      value={item[1]}
                                      onChange={(e: any) => {
                                        handleP_TAXES(1, e.target.value, idx);
                                      }}
                                    />
                                  ) : null}
                                </td>
                                <td className="text-end">{parseFloat(item[2]).toFixed(2)}</td>
                                <td className="text-end">{parseFloat(item[3]).toFixed(2)}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>

                  {/* P_CHILDREN */}
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="table-responsive mb-3">
                      <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                        <thead>
                          <tr>
                            <th colSpan={3} className="text-center">
                              {t("Tax-free amounts for children")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {initialP_CHILDREN &&
                            initialP_CHILDREN.map((item: any, idx: number) => (
                              <tr key={idx}>
                                <td className="text-end">{item[0]}</td>
                                <td className="text-end">
                                  {idx === 5 ? (
                                    <Input
                                      className="form-control text-end"
                                      type="text"
                                      value={item[1]}
                                      onBlur={validation.handleBlur}
                                      onChange={(e: any) => {
                                        handleP_CHILDREN(1, e.target.value, idx);
                                      }}
                                    />
                                  ) : null}
                                </td>
                                <td className="text-end">
                                  {idx > 0 && idx < 5 ? (
                                    <Input
                                      className="form-control text-end"
                                      type="text"
                                      value={item[3]}
                                      onBlur={validation.handleBlur}
                                      onChange={(e: any) => {
                                        handleP_CHILDREN(3, e.target.value, idx);
                                      }}
                                    />
                                  ) : (
                                    parseFloat(item[3]).toFixed(2)
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="table-responsive mb-3">
                      <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                        <thead>
                          <tr>
                            <th colSpan={2} className="text-center">
                              {t("Business expenses")}: {t("Director")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{t("Percentage")}</td>
                            <td className="text-end">
                              <Input
                                id="business_expenses_director_percentage"
                                name="business_expenses_director_percentage"
                                className="form-control text-end"
                                type="text"
                                value={initialData.business_expenses_director_percentage}
                                onBlur={validation.handleBlur}
                                onChange={(e) =>
                                  handleChange(
                                    "business_expenses_director_percentage",
                                    e.target.value
                                  )
                                }
                                invalid={
                                  validation.errors.business_expenses_director_percentage
                                    ? true
                                    : false
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>{t("Maximum amount")}</td>
                            <td className="text-end">
                              <Input
                                id="business_expenses_director_maximum_amount"
                                name="business_expenses_director_maximum_amount"
                                className="form-control text-end"
                                type="text"
                                value={initialData.business_expenses_director_maximum_amount}
                                onBlur={validation.handleBlur}
                                onChange={(e) =>
                                  handleChange(
                                    "business_expenses_director_maximum_amount",
                                    e.target.value
                                  )
                                }
                                invalid={
                                  validation.errors.business_expenses_director_maximum_amount
                                    ? true
                                    : false
                                }
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Col>

                  {/* P_Expenses */}
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="table-responsive mb-3">
                      <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                        <thead>
                          <tr>
                            <th colSpan={4} className="text-center">
                              {t("Business expenses")}: {t("Employee")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {initialP_Expenses &&
                            initialP_Expenses.map((item: any, idx: number) => (
                              <tr key={idx}>
                                <td className="text-end">{parseFloat(item[0]).toFixed(2)}</td>
                                <td className="text-end">
                                  {idx !== 1 ? (
                                    <Input
                                      className="form-control text-end"
                                      type="text"
                                      value={item[1]}
                                      onChange={(e: any) => {
                                        handleP_Expenses(1, e.target.value, idx);
                                      }}
                                    />
                                  ) : null}
                                </td>
                                <td className="text-end">{parseFloat(item[2]).toFixed(2)}</td>
                                <td className="text-end">{parseFloat(item[3]).toFixed(2)}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>

                  <Col xl={4} lg={4} md={4} sm={12}>
                    <Col className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">{t("Commuting exemption")}</Label>
                        <Input
                          id="commuting_exemption"
                          name="commuting_exemption"
                          className="form-control text-end"
                          type="text"
                          value={initialData.commuting_exemption}
                          onBlur={validation.handleBlur}
                          onChange={(e) => handleChange("commuting_exemption", e.target.value)}
                          invalid={validation.errors.commuting_exemption ? true : false}
                        />
                        {validation.errors.commuting_exemption ? (
                          <FormFeedback type="invalid">
                            {validation.errors.commuting_exemption}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">{t("Attribution to spouse")}</Label>
                        <Input
                          id="attribution_to_spouse"
                          name="attribution_to_spouse"
                          className="form-control text-end"
                          type="text"
                          value={initialData.attribution_to_spouse}
                          onBlur={validation.handleBlur}
                          onChange={(e) => handleChange("attribution_to_spouse", e.target.value)}
                          invalid={validation.errors.attribution_to_spouse ? true : false}
                        />
                        {validation.errors.attribution_to_spouse ? (
                          <FormFeedback type="invalid">
                            {validation.errors.attribution_to_spouse}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Row>
                  {/* companyCarStatuses */}
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="table-responsive mb-3">
                      <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                        <thead>
                          <tr>
                            <th colSpan={2} className="text-center">
                              {t("Fringe benefit company car")}
                            </th>
                          </tr>
                          <tr>
                            <th className="text-start">{t("Type of company car")}</th>
                            <th className="text-end">{t("CO2")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {initialCompanyCarStatuses &&
                            initialCompanyCarStatuses.map((item: any, idx: number) => (
                              <tr key={idx}>
                                <td className="text-start">{item.label}</td>
                                <td className="text-end">
                                  {idx !== 0 ? (
                                    <Input
                                      className="form-control text-end"
                                      type="text"
                                      value={item.co2}
                                      onChange={(e: any) => {
                                        handleCompanyCarStatuses("co2", e.target.value, idx);
                                      }}
                                    />
                                  ) : null}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>

                  <Col xl={4} lg={4} md={4} sm={12}>
                    <Col className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">{t("Minimum BIK")}</Label>
                        <Input
                          id="min_bik_per_year"
                          name="min_bik_per_year"
                          className="form-control text-end"
                          type="text"
                          value={initialData.min_bik_per_year}
                          onBlur={validation.handleBlur}
                          onChange={(e) => handleChange("min_bik_per_year", e.target.value)}
                          invalid={validation.errors.min_bik_per_year ? true : false}
                        />
                        {validation.errors.min_bik_per_year ? (
                          <FormFeedback type="invalid">
                            {validation.errors.min_bik_per_year}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col className="col-12">
                      <div className="mb-3">
                        <Label className="form-label">{t("Revaluation factor")}</Label>
                        <Input
                          id="revaluation_factor"
                          name="revaluation_factor"
                          className="form-control text-end"
                          type="text"
                          value={initialData.revaluation_factor}
                          onBlur={validation.handleBlur}
                          onChange={(e) => handleChange("revaluation_factor", e.target.value)}
                          invalid={validation.errors.revaluation_factor ? true : false}
                        />
                        {validation.errors.revaluation_factor ? (
                          <FormFeedback type="invalid">
                            {validation.errors.revaluation_factor}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Row>
                  {/* SS_SOCIAL_SECURITY */}
                  <Col xl={8} lg={8} md={8} sm={12}>
                    <div className="table-responsive mb-3">
                      <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                        <thead>
                          <tr>
                            <th colSpan={4} className="text-center">
                              {t("Self-employed social security")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {initialSS_SOCIAL_SECURITY &&
                            initialSS_SOCIAL_SECURITY.map((item: any, idx: number) => (
                              <tr key={idx}>
                                <td className="text-end">{parseFloat(item[0]).toFixed(2)}</td>
                                <td className="text-end">
                                  {idx !== 3 ? (
                                    <Input
                                      className="form-control text-end"
                                      type="text"
                                      value={item[1]}
                                      onChange={(e: any) => {
                                        handleSS_SOCIAL_SECURITY(1, e.target.value, idx);
                                      }}
                                    />
                                  ) : null}
                                </td>
                                <td className="text-end">{parseFloat(item[2]).toFixed(2)} %</td>
                                <td className="text-end">
                                  {idx === 0 ? (
                                    <Input
                                      className="form-control text-end"
                                      type="text"
                                      value={item[3]}
                                      onChange={(e: any) => {
                                        handleSS_SOCIAL_SECURITY(3, e.target.value, idx);
                                      }}
                                    />
                                  ) : (
                                    parseFloat(item[3]).toFixed(2)
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>{t("ESTONIA")}</CardTitle>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">
                        {t("Tax rate for non-resident taxpayers")}
                      </Label>
                      <div className="input-group">
                        <Input
                          id="EST_TAX_RATE"
                          name="EST_TAX_RATE"
                          className="form-control text-end"
                          type="text"
                          value={initialData.EST_TAX_RATE}
                          onBlur={validation.handleBlur}
                          onChange={(e) => handleChange("EST_TAX_RATE", e.target.value)}
                          invalid={validation.errors.EST_TAX_RATE ? true : false}
                        />
                        <span className="input-group-text">%</span>
                      </div>
                      {validation.errors.EST_TAX_RATE ? (
                        <FormFeedback type="invalid">{validation.errors.EST_TAX_RATE}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        {t(
                          "(Director's income is taxed as employment income) <br>(Tax rate before 2015: 21%)"
                        )}
                      </Label>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>{t("FRANCE")}</CardTitle>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">{t("DED_10_MIN")}</Label>
                      <Input
                        id="DED_10_MIN"
                        name="DED_10_MIN"
                        className="form-control text-end"
                        type="text"
                        value={initialData.DED_10_MIN}
                        onBlur={validation.handleBlur}
                        onChange={(e) => handleChange("DED_10_MIN", e.target.value)}
                        invalid={validation.errors.DED_10_MIN ? true : false}
                      />
                      {validation.errors.DED_10_MIN ? (
                        <FormFeedback type="invalid">{validation.errors.DED_10_MIN}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        {t("Not for calculation of withholding tax")}
                      </Label>
                    </div>
                  </Col>

                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">{t("DED_10_MAX")}</Label>
                      <Input
                        id="DED_10_MAX"
                        name="DED_10_MAX"
                        className="form-control text-end"
                        type="text"
                        value={initialData.DED_10_MAX}
                        onBlur={validation.handleBlur}
                        onChange={(e) => handleChange("DED_10_MAX", e.target.value)}
                        invalid={validation.errors.DED_10_MAX ? true : false}
                      />
                      {validation.errors.DED_10_MAX ? (
                        <FormFeedback type="invalid">{validation.errors.DED_10_MAX}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        {t("Not for calculation of withholding tax")}
                      </Label>
                    </div>
                  </Col>

                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">{t("Working days")}</Label>
                      <Input
                        id="WORK_DAYS"
                        name="WORK_DAYS"
                        className="form-control text-end"
                        type="text"
                        value={initialData.WORK_DAYS}
                        onBlur={validation.handleBlur}
                        onChange={(e) => handleChange("WORK_DAYS", e.target.value)}
                        invalid={validation.errors.WORK_DAYS ? true : false}
                      />
                      {validation.errors.WORK_DAYS ? (
                        <FormFeedback type="invalid">{validation.errors.WORK_DAYS}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col className="col-12">
                    <hr></hr>
                    <br />
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} lg={6} md={6} sm={12}>
                    <div className="table-responsive mb-3">
                      <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                        <thead>
                          <tr>
                            <th colSpan={4} className="text-center">
                              {t("Withholding tax")} (RAS)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {initialRAS &&
                            initialRAS.map((item: any, idx: number) => (
                              <tr key={idx}>
                                <td className="text-end">{parseFloat(item[0]).toFixed(2)}</td>
                                <td className="text-end">
                                  {idx !== 2 ? (
                                    <Input
                                      className="form-control text-end"
                                      type="text"
                                      value={item[1]}
                                      onChange={(e: any) => {
                                        handleRAS(1, e.target.value, idx);
                                      }}
                                    />
                                  ) : null}
                                </td>
                                <td className="text-end">{parseFloat(item[2]).toFixed(2)} %</td>
                                <td className="text-end">
                                  {idx === 0 ? (
                                    <Input
                                      className="form-control text-end"
                                      type="text"
                                      value={item[3]}
                                      onChange={(e: any) => {
                                        handleRAS(3, e.target.value, idx);
                                      }}
                                    />
                                  ) : (
                                    parseFloat(item[3]).toFixed(2)
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>

                  <Col xl={6} lg={6} md={6} sm={12}>
                    <div className="table-responsive mb-3">
                      <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                        <thead>
                          <tr>
                            <th colSpan={4} className="text-center">
                              {t("Final tax")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {initialFINAL_TAX &&
                            initialFINAL_TAX.map((item: any, idx: number) => (
                              <tr key={idx}>
                                <td className="text-end">{parseFloat(item[0]).toFixed(2)}</td>
                                <td className="text-end">
                                  {idx !== 3 ? (
                                    <Input
                                      className="form-control text-end"
                                      type="text"
                                      value={item[1]}
                                      onChange={(e: any) => {
                                        handleFINAL_TAX(1, e.target.value, idx);
                                      }}
                                    />
                                  ) : null}
                                </td>
                                <td className="text-end">{parseFloat(item[2]).toFixed(2)} %</td>
                                <td className="text-end">
                                  {idx === 0 ? (
                                    <Input
                                      className="form-control text-end"
                                      type="text"
                                      value={item[3]}
                                      onChange={(e: any) => {
                                        handleFINAL_TAX(3, e.target.value, idx);
                                      }}
                                    />
                                  ) : (
                                    parseFloat(item[3]).toFixed(2)
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>{t("GERMANY")}</CardTitle>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">{t("Advertising costs")}</Label>
                      <Input
                        id="C_WERBUNG"
                        name="C_WERBUNG"
                        className="form-control text-end"
                        type="text"
                        value={initialData.C_WERBUNG}
                        onBlur={validation.handleBlur}
                        onChange={(e) => handleChange("C_WERBUNG", e.target.value)}
                        invalid={validation.errors.C_WERBUNG ? true : false}
                      />
                      {validation.errors.C_WERBUNG ? (
                        <FormFeedback type="invalid">{validation.errors.C_WERBUNG}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">{t("Special editions")}</Label>
                      <Input
                        id="C_SONDER"
                        name="C_SONDER"
                        className="form-control text-end"
                        type="text"
                        value={initialData.C_SONDER}
                        onBlur={validation.handleBlur}
                        onChange={(e) => handleChange("C_SONDER", e.target.value)}
                        invalid={validation.errors.C_SONDER ? true : false}
                      />
                      {validation.errors.C_SONDER ? (
                        <FormFeedback type="invalid">{validation.errors.C_SONDER}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">{t("Special expense deduction")}</Label>
                      <Input
                        id="C_SPECIAL_EXP"
                        name="C_SPECIAL_EXP"
                        className="form-control text-end"
                        type="text"
                        value={initialData.C_SPECIAL_EXP}
                        onBlur={validation.handleBlur}
                        onChange={(e) => handleChange("C_SPECIAL_EXP", e.target.value)}
                        invalid={validation.errors.C_SPECIAL_EXP ? true : false}
                      />
                      {validation.errors.C_SPECIAL_EXP ? (
                        <FormFeedback type="invalid">
                          {validation.errors.C_SPECIAL_EXP}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">{t("Tax rebate double tax treaty")}</Label>
                      <div className="input-group">
                        <Input
                          id="C_REBATE_TREATY"
                          name="C_REBATE_TREATY"
                          className="form-control text-end"
                          type="text"
                          value={initialData.C_REBATE_TREATY}
                          onBlur={validation.handleBlur}
                          onChange={(e) => handleChange("C_REBATE_TREATY", e.target.value)}
                          invalid={validation.errors.C_REBATE_TREATY ? true : false}
                        />
                        <span className="input-group-text">%</span>
                      </div>
                      {validation.errors.C_REBATE_TREATY ? (
                        <FormFeedback type="invalid">
                          {validation.errors.C_REBATE_TREATY}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col className="col-12">
                    <hr></hr>
                    <br />
                  </Col>
                </Row>

                <Row>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">{t("Solidarity tax")}</Label>
                      <div className="input-group">
                        <Input
                          id="C_SOLIDARITY"
                          name="C_SOLIDARITY"
                          className="form-control text-end"
                          type="text"
                          value={initialData.C_SOLIDARITY}
                          onBlur={validation.handleBlur}
                          onChange={(e) => handleChange("C_SOLIDARITY", e.target.value)}
                          invalid={validation.errors.C_SOLIDARITY ? true : false}
                        />
                        <span className="input-group-text">%</span>
                      </div>
                      {validation.errors.C_SOLIDARITY ? (
                        <FormFeedback type="invalid">{validation.errors.C_SOLIDARITY}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">{t("C_SOLIDARITY_MIN_LIMIT")}</Label>
                      <Input
                        id="C_SOLIDARITY_MIN_LIMIT"
                        name="C_SOLIDARITY_MIN_LIMIT"
                        className="form-control text-end"
                        type="text"
                        value={initialData.C_SOLIDARITY_MIN_LIMIT}
                        onBlur={validation.handleBlur}
                        onChange={(e) => handleChange("C_SOLIDARITY_MIN_LIMIT", e.target.value)}
                        invalid={validation.errors.C_SOLIDARITY_MIN_LIMIT ? true : false}
                      />
                      {validation.errors.C_SOLIDARITY_MIN_LIMIT ? (
                        <FormFeedback type="invalid">
                          {validation.errors.C_SOLIDARITY_MIN_LIMIT}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">{t("C_SOLIDARITY_MIN_PERC")}</Label>
                      <div className="input-group">
                        <Input
                          id="C_SOLIDARITY_MIN_PERC"
                          name="C_SOLIDARITY_MIN_PERC"
                          className="form-control text-end"
                          type="text"
                          value={initialData.C_SOLIDARITY_MIN_PERC}
                          onBlur={validation.handleBlur}
                          onChange={(e) => handleChange("C_SOLIDARITY_MIN_PERC", e.target.value)}
                          invalid={validation.errors.C_SOLIDARITY_MIN_PERC ? true : false}
                        />
                        <span className="input-group-text">%</span>
                      </div>
                      {validation.errors.C_SOLIDARITY_MIN_PERC ? (
                        <FormFeedback type="invalid">
                          {validation.errors.C_SOLIDARITY_MIN_PERC}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col className="col-12">
                    <hr></hr>
                    <br />
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} lg={6} md={6} sm={12}>
                    <div className="table-responsive mb-3">
                      <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                        <thead>
                          <tr>
                            <th colSpan={4} className="text-center">
                              {t("Income tax rates")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {initialTAX_RATE_G &&
                            initialTAX_RATE_G.map((item: any, idx: number) => (
                              <tr key={idx}>
                                <td className="text-end">{parseFloat(item[0]).toFixed(2)}</td>
                                <td className="text-end">
                                  {idx !== 4 ? (
                                    <Input
                                      className="form-control text-end"
                                      type="text"
                                      value={item[1]}
                                      onChange={(e: any) => {
                                        handleTAX_RATE_G(1, e.target.value, idx);
                                      }}
                                    />
                                  ) : null}
                                </td>
                                <td className="text-end">{parseFloat(item[2]).toFixed(2)} %</td>
                                <td className="text-end">{parseFloat(item[3]).toFixed(2)}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col className="col-12">
                    <hr></hr>
                    <br />
                  </Col>
                </Row>

                <Row>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">
                        {t("Tax rate")} - {t("Director")}
                      </Label>
                      <div className="input-group">
                        <Input
                          id="GER_TAX_RATE"
                          name="GER_TAX_RATE"
                          className="form-control text-end"
                          type="text"
                          value={initialData.GER_TAX_RATE}
                          onBlur={validation.handleBlur}
                          onChange={(e) => handleChange("GER_TAX_RATE", e.target.value)}
                          invalid={validation.errors.GER_TAX_RATE ? true : false}
                        />
                        <span className="input-group-text">%</span>
                      </div>
                      {validation.errors.GER_TAX_RATE ? (
                        <FormFeedback type="invalid">{validation.errors.GER_TAX_RATE}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>{t("LUXEMBOURG")}</CardTitle>
              </CardHeader>

              <CardBody>
                <Row></Row>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>{t("POLAND")}</CardTitle>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">{t("Tax rate for directors")}</Label>
                      <Input
                        id="POL_TAX_RATE"
                        name="POL_TAX_RATE"
                        className="form-control text-end"
                        type="text"
                        value={initialData.POL_TAX_RATE}
                        onBlur={validation.handleBlur}
                        onChange={(e) => handleChange("POL_TAX_RATE", e.target.value)}
                        invalid={validation.errors.POL_TAX_RATE ? true : false}
                      />
                      {validation.errors.POL_TAX_RATE ? (
                        <FormFeedback type="invalid">{validation.errors.POL_TAX_RATE}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">{t("Exchange rate")}: PLN 1 = EUR</Label>
                      <Input
                        id="EXCHANGE_RATE_POL"
                        name="EXCHANGE_RATE_POL"
                        className="form-control text-end"
                        type="text"
                        value={initialData.EXCHANGE_RATE_POL}
                        onBlur={validation.handleBlur}
                        onChange={(e) => handleChange("EXCHANGE_RATE_POL", e.target.value)}
                        invalid={validation.errors.EXCHANGE_RATE_POL ? true : false}
                      />
                      {validation.errors.EXCHANGE_RATE_POL ? (
                        <FormFeedback type="invalid">
                          {validation.errors.EXCHANGE_RATE_POL}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        {t("Average exchange rate")} 01/01/
                        {initialData.computation_year - 1} - 31/12/
                        {initialData.computation_year - 1}
                      </Label>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col className="col-12">
                    <hr></hr>
                    <br />
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} lg={6} md={6} sm={12}>
                    <div className="table-responsive mb-3">
                      <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                        <thead>
                          <tr>
                            <th colSpan={4} className="text-center">
                              {t("Personal allowance")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {initialPERSONAL_ALLOWANCE_P &&
                            initialPERSONAL_ALLOWANCE_P.map((item: any, idx: number) => (
                              <tr key={idx}>
                                <td className="text-end">{parseFloat(item[0]).toFixed(2)}</td>
                                <td className="text-end">
                                  {idx !== 4 ? (
                                    <Input
                                      className="form-control text-end"
                                      type="text"
                                      value={item[1]}
                                      onChange={(e: any) => {
                                        handlePERSONAL_ALLOWANCE_P(1, e.target.value, idx);
                                      }}
                                    />
                                  ) : null}
                                </td>
                                <td className="text-end">{parseFloat(item[2]).toFixed(2)} %</td>
                                <td className="text-end">{parseFloat(item[3]).toFixed(2)}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>

                  <Col xl={6} lg={6} md={6} sm={12}>
                    <div className="table-responsive mb-3">
                      <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                        <thead>
                          <tr>
                            <th colSpan={4} className="text-center">
                              {t("Polish tax rates")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {initialTAX_RATE_P &&
                            initialTAX_RATE_P.map((item: any, idx: number) => (
                              <tr key={idx}>
                                <td className="text-end">{parseFloat(item[0]).toFixed(2)}</td>
                                <td className="text-end">
                                  {idx !== 4 ? (
                                    <Input
                                      className="form-control text-end"
                                      type="text"
                                      value={item[1]}
                                      onChange={(e: any) => {
                                        handleTAX_RATE_P(1, e.target.value, idx);
                                      }}
                                    />
                                  ) : null}
                                </td>
                                <td className="text-end">{parseFloat(item[2]).toFixed(2)} %</td>
                                <td className="text-end">{parseFloat(item[3]).toFixed(2)}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col className="col-12">
                    <hr></hr>
                    <br />
                  </Col>
                </Row>

                <Row>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">{t("Standard deduction")}</Label>
                      <Input
                        id="STANDARD_DEDUCTION"
                        name="STANDARD_DEDUCTION"
                        className="form-control text-end"
                        type="text"
                        value={initialData.STANDARD_DEDUCTION}
                        onBlur={validation.handleBlur}
                        onChange={(e) => handleChange("STANDARD_DEDUCTION", e.target.value)}
                        invalid={validation.errors.STANDARD_DEDUCTION ? true : false}
                      />
                      {validation.errors.STANDARD_DEDUCTION ? (
                        <FormFeedback type="invalid">
                          {validation.errors.STANDARD_DEDUCTION}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        {t(
                          "More than 1 employer + residence employee not where employer is located"
                        )}
                      </Label>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>ROMANIA</CardTitle>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">
                        {t("Tax rate for non-resident taxpayers")}
                      </Label>
                      <div className="input-group">
                        <Input
                          id="ROM_TAX_RATE"
                          name="ROM_TAX_RATE"
                          className="form-control text-end"
                          type="text"
                          value={initialData.ROM_TAX_RATE}
                          onBlur={validation.handleBlur}
                          onChange={(e) => handleChange("ROM_TAX_RATE", e.target.value)}
                          invalid={validation.errors.ROM_TAX_RATE ? true : false}
                        />
                        <span className="input-group-text">%</span>
                      </div>

                      {validation.errors.ROM_TAX_RATE ? (
                        <FormFeedback type="invalid">{validation.errors.ROM_TAX_RATE}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        ({t("Director's income is taxed as employment income")})
                        <br />
                        {t("Before")} 2018: 16%
                      </Label>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>{t("SPAIN")}</CardTitle>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">{t("Tax rate for expatriates")}</Label>
                      <div className="input-group">
                        <Input
                          id="SPN_TAX_RATE"
                          name="SPN_TAX_RATE"
                          className="form-control text-end"
                          type="text"
                          value={initialData.SPN_TAX_RATE}
                          onBlur={validation.handleBlur}
                          onChange={(e) => handleChange("SPN_TAX_RATE", e.target.value)}
                          invalid={validation.errors.SPN_TAX_RATE ? true : false}
                        />
                        <span className="input-group-text">%</span>
                      </div>
                      {validation.errors.SPN_TAX_RATE ? (
                        <FormFeedback type="invalid">{validation.errors.SPN_TAX_RATE}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        (
                        {t(
                          "On first € 600.000 - 47% on the rest of the income which is not in formula"
                        )}
                        )
                      </Label>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col className="col-12">
                    <hr></hr>
                    <br />
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} lg={6} md={6} sm={12}>
                    <div className="table-responsive mb-3">
                      <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                        <thead>
                          <tr>
                            <th colSpan={4} className="text-center">
                              {t("Tax rates")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {initialTAX_RATE_S &&
                            initialTAX_RATE_S.map((item: any, idx: number) => (
                              <tr key={idx}>
                                <td className="text-end">{parseFloat(item[0]).toFixed(2)}</td>
                                <td className="text-end">
                                  {idx !== 5 ? (
                                    <Input
                                      className="form-control text-end"
                                      type="text"
                                      value={item[1]}
                                      onChange={(e: any) => {
                                        handleTAX_RATE_S(1, e.target.value, idx);
                                      }}
                                    />
                                  ) : null}
                                </td>
                                <td className="text-end">
                                  <div className="input-group">
                                    <Input
                                      className="form-control text-end"
                                      type="text"
                                      value={item[2]}
                                      onChange={(e: any) => {
                                        handleTAX_RATE_S(2, e.target.value, idx);
                                      }}
                                    />
                                    <span className="input-group-text">%</span>
                                  </div>

                                  {/* {parseFloat(item[2]).toFixed(2)} % */}
                                </td>
                                <td className="text-end">{parseFloat(item[3]).toFixed(2)}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>

                  <Col xl={6} lg={6} md={6} sm={12}>
                    <div className="table-responsive mb-3">
                      <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                        <thead>
                          <tr>
                            <th colSpan={4} className="text-center">
                              {t("Employment reduction")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {initialEMPLOYMENT_REDUCTION &&
                            initialEMPLOYMENT_REDUCTION.map((item: any, idx: number) => (
                              <tr key={idx}>
                                <td className="text-end">{parseFloat(item[0]).toFixed(2)}</td>
                                <td className="text-end">
                                  {idx !== 2 ? (
                                    <Input
                                      className="form-control text-end"
                                      type="text"
                                      value={item[1]}
                                      onChange={(e: any) => {
                                        handleEMPLOYMENT_REDUCTION(1, e.target.value, idx);
                                      }}
                                    />
                                  ) : null}
                                </td>
                                <td className="text-end">
                                  {idx !== 2 ? (
                                    <Input
                                      className="form-control text-end"
                                      type="text"
                                      value={item[2]}
                                      onChange={(e: any) => {
                                        handleEMPLOYMENT_REDUCTION(2, e.target.value, idx);
                                      }}
                                    />
                                  ) : null}
                                </td>
                                <td className="text-end">{parseFloat(item[3]).toFixed(2)}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>
                  {t("NETHERLANDS")} ({t("HOLLAND")})
                </CardTitle>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">
                        {t("Deductible part of Belgian social security")}
                      </Label>
                      <div className="input-group">
                        <Input
                          id="C_DED_BE_SS"
                          name="C_DED_BE_SS"
                          className="form-control text-end"
                          type="text"
                          value={initialData.C_DED_BE_SS}
                          onBlur={validation.handleBlur}
                          onChange={(e) => handleChange("C_DED_BE_SS", e.target.value)}
                          invalid={validation.errors.C_DED_BE_SS ? true : false}
                        />
                        <span className="input-group-text">%</span>
                      </div>
                      {validation.errors.C_DED_BE_SS ? (
                        <FormFeedback type="invalid">{validation.errors.C_DED_BE_SS}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">(73% {t("of")} 13,07%)</Label>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col className="col-12">
                    <hr></hr>
                    <br />
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} lg={6} md={6} sm={12}>
                    <div className="table-responsive mb-3">
                      <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                        <thead>
                          <tr>
                            <th colSpan={4} className="text-center">
                              {t("Calculation of the general rebate (General tax credit)")}
                            </th>
                          </tr>
                          <tr>
                            <th colSpan={2} className="text-center">
                              Salary
                            </th>
                            <th className="text-center">{t("Fixed amount")}</th>
                            <th className="text-center">{t("Percentage")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {initialGENERAL_REBATE &&
                            initialGENERAL_REBATE.map((item: any, idx: number) => (
                              <tr key={idx}>
                                <td className="text-end">{parseFloat(item[0]).toFixed(2)}</td>
                                <td className="text-end">
                                  {idx !== 2 ? (
                                    <Input
                                      className="form-control text-end"
                                      type="text"
                                      value={item[1]}
                                      onChange={(e: any) => {
                                        handleGENERAL_REBATE(1, e.target.value, idx);
                                      }}
                                    />
                                  ) : null}
                                </td>
                                <td className="text-end">
                                  <Input
                                    className="form-control text-end"
                                    type="text"
                                    value={item[2]}
                                    onChange={(e: any) => {
                                      handleGENERAL_REBATE(2, e.target.value, idx);
                                    }}
                                  />
                                </td>
                                <td className="text-end">
                                  <div className="input-group">
                                    <Input
                                      className="form-control text-end"
                                      type="text"
                                      value={item[3]}
                                      onChange={(e: any) => {
                                        handleGENERAL_REBATE(3, e.target.value, idx);
                                      }}
                                    />
                                    <span className="input-group-text">%</span>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>

                  <Col xl={6} lg={6} md={6} sm={12}>
                    <div className="table-responsive mb-3">
                      <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                        <thead>
                          <tr>
                            <th colSpan={4} className="text-center">
                              {t("Calculation of the employment rebate (Labour discount)")}
                            </th>
                          </tr>
                          <tr>
                            <th colSpan={2} className="text-center">
                              Salary
                            </th>
                            <th className="text-center">{t("Fixed amount")}</th>
                            <th className="text-center">{t("Percentage")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {initialEMPLOYMENT_REBATE &&
                            initialEMPLOYMENT_REBATE.map((item: any, idx: number) => (
                              <tr key={idx}>
                                <td className="text-end">{parseFloat(item[0]).toFixed(2)}</td>
                                <td className="text-end">
                                  {idx !== 4 ? (
                                    <Input
                                      className="form-control text-end"
                                      type="text"
                                      value={item[1]}
                                      onChange={(e: any) => {
                                        handleEMPLOYMENT_REBATE(1, e.target.value, idx);
                                      }}
                                    />
                                  ) : null}
                                </td>
                                <td className="text-end">
                                  <Input
                                    className="form-control text-end"
                                    type="text"
                                    value={item[2]}
                                    onChange={(e: any) => {
                                      handleEMPLOYMENT_REBATE(2, e.target.value, idx);
                                    }}
                                  />
                                </td>
                                <td className="text-end">
                                  <div className="input-group">
                                    <Input
                                      className="form-control text-end"
                                      type="text"
                                      value={item[3]}
                                      onChange={(e: any) => {
                                        handleEMPLOYMENT_REBATE(3, e.target.value, idx);
                                      }}
                                    />
                                    <span className="input-group-text">%</span>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} lg={6} md={6} sm={12}>
                    <div className="table-responsive mb-3">
                      <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                        <thead>
                          <tr>
                            <th colSpan={4} className="text-center">
                              {t("Tax rates")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {initialTAX_RATE_N &&
                            initialTAX_RATE_N.map((item: any, idx: number) => (
                              <tr key={idx}>
                                <td className="text-end">{parseFloat(item[0]).toFixed(2)}</td>
                                <td className="text-end">
                                  {idx !== 3 ? (
                                    <Input
                                      className="form-control text-end"
                                      type="text"
                                      value={item[1]}
                                      onChange={(e: any) => {
                                        handleTAX_RATE_N(1, e.target.value, idx);
                                      }}
                                    />
                                  ) : null}
                                </td>
                                <td className="text-end">
                                  <div className="input-group">
                                    <Input
                                      className="form-control text-end"
                                      type="text"
                                      value={item[2]}
                                      onChange={(e: any) => {
                                        handleTAX_RATE_N(2, e.target.value, idx);
                                      }}
                                    />
                                    <span className="input-group-text">%</span>
                                  </div>
                                </td>
                                <td className="text-end">{parseFloat(item[3]).toFixed(0)}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>

                  <Col xl={6} lg={6} md={6} sm={12}>
                    <div className="table-responsive mb-3">
                      <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                        <thead>
                          <tr>
                            <th colSpan={4} className="text-center">
                              {t("Social security rates")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {initialSS_RATE_N &&
                            initialSS_RATE_N.map((item: any, idx: number) => (
                              <tr key={idx}>
                                <td className="text-end">{parseFloat(item[0]).toFixed(2)}</td>
                                <td className="text-end">
                                  {idx !== 3 ? (
                                    <Input
                                      className="form-control text-end"
                                      type="text"
                                      value={item[1]}
                                      onChange={(e: any) => {
                                        handleSS_RATE_N(1, e.target.value, idx);
                                      }}
                                    />
                                  ) : null}
                                </td>
                                <td className="text-end">
                                  <div className="input-group">
                                    <Input
                                      className="form-control text-end"
                                      type="text"
                                      value={item[2]}
                                      onChange={(e: any) => {
                                        handleSS_RATE_N(2, e.target.value, idx);
                                      }}
                                    />
                                    <span className="input-group-text">%</span>
                                  </div>
                                </td>
                                <td className="text-end">{parseFloat(item[3]).toFixed(0)}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>{t("UNITED KINGDOM")}</CardTitle>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">{t("Exchange rate")}: GBP 1 = EUR</Label>
                      <Input
                        id="EXCHANGE_RATE_UK"
                        name="EXCHANGE_RATE_UK"
                        className="form-control text-end"
                        type="text"
                        value={initialData.EXCHANGE_RATE_UK}
                        onBlur={validation.handleBlur}
                        onChange={(e) => handleChange("EXCHANGE_RATE_UK", e.target.value)}
                        invalid={validation.errors.EXCHANGE_RATE_UK ? true : false}
                      />
                      {validation.errors.EXCHANGE_RATE_UK ? (
                        <FormFeedback type="invalid">
                          {validation.errors.EXCHANGE_RATE_UK}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        {t("Average exchange rate")} 01/01/
                        {initialData.computation_year - 1} - 31/12/
                        {initialData.computation_year - 1}
                      </Label>
                    </div>
                  </Col>

                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">{t("PERS_ALL_SINGLE")}</Label>
                      <Input
                        id="PERS_ALL_SINGLE"
                        name="PERS_ALL_SINGLE"
                        className="form-control text-end"
                        type="text"
                        value={initialData.PERS_ALL_SINGLE}
                        onBlur={validation.handleBlur}
                        onChange={(e) => handleChange("PERS_ALL_SINGLE", e.target.value)}
                        invalid={validation.errors.PERS_ALL_SINGLE ? true : false}
                      />
                      {validation.errors.PERS_ALL_SINGLE ? (
                        <FormFeedback type="invalid">
                          {validation.errors.PERS_ALL_SINGLE}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        ({t("Personal allowance for single & married taxpayers")})
                      </Label>
                    </div>
                  </Col>

                  <Col className="col-12"></Col>

                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">{t("Deduction personal allowance")}</Label>
                      <Input
                        id="PERS_ALL_DEDUCTION"
                        name="PERS_ALL_DEDUCTION"
                        className="form-control text-end"
                        type="text"
                        value={initialData.PERS_ALL_DEDUCTION}
                        onBlur={validation.handleBlur}
                        onChange={(e) => handleChange("PERS_ALL_DEDUCTION", e.target.value)}
                        invalid={validation.errors.PERS_ALL_DEDUCTION ? true : false}
                      />
                      {validation.errors.PERS_ALL_DEDUCTION ? (
                        <FormFeedback type="invalid">
                          {validation.errors.PERS_ALL_DEDUCTION}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">{t("For every GBP 2 over GBP")}</Label>
                      <Input
                        id="GBP_OVER_GBP"
                        name="GBP_OVER_GBP"
                        className="form-control text-end"
                        type="text"
                        value={initialData.GBP_OVER_GBP}
                        onBlur={validation.handleBlur}
                        onChange={(e) => handleChange("GBP_OVER_GBP", e.target.value)}
                        invalid={validation.errors.GBP_OVER_GBP ? true : false}
                      />
                      {validation.errors.GBP_OVER_GBP ? (
                        <FormFeedback type="invalid">{validation.errors.GBP_OVER_GBP}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col className="col-12"></Col>

                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">{t("PERS_ALL_MARRIED")}</Label>
                      <Input
                        id="PERS_ALL_MARRIED"
                        name="PERS_ALL_MARRIED"
                        className="form-control text-end"
                        type="text"
                        value={initialData.PERS_ALL_MARRIED}
                        onBlur={validation.handleBlur}
                        onChange={(e) => handleChange("PERS_ALL_MARRIED", e.target.value)}
                        invalid={validation.errors.PERS_ALL_MARRIED ? true : false}
                      />
                      {validation.errors.PERS_ALL_MARRIED ? (
                        <FormFeedback type="invalid">
                          {validation.errors.PERS_ALL_MARRIED}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        {t("(only for people > 75 years old)")}
                        <br />
                        {t("(minimum additional allowance for married taxpayers granted at 20%)")}
                      </Label>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col className="col-12">
                    <hr></hr>
                    <br />
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} lg={6} md={6} sm={12}>
                    <div className="table-responsive mb-3">
                      <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                        <thead>
                          <tr>
                            <th colSpan={4} className="text-center">
                              {t("Tax rates")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {initialTAX_RATE_UK &&
                            initialTAX_RATE_UK.map((item: any, idx: number) => (
                              <tr key={idx}>
                                <td className="text-end">{parseFloat(item[0]).toFixed(2)}</td>
                                <td className="text-end">
                                  {idx !== 4 ? (
                                    <Input
                                      className="form-control text-end"
                                      type="text"
                                      value={item[1]}
                                      onChange={(e: any) => {
                                        handleTAX_RATE_UK(1, e.target.value, idx);
                                      }}
                                    />
                                  ) : null}
                                </td>
                                <td className="text-end">{parseFloat(item[2]).toFixed(2)} %</td>
                                <td className="text-end">{parseFloat(item[3]).toFixed(2)}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>{t("UNITED STATES")}</CardTitle>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">{t("Exchange rate")}: USD 1 = EUR</Label>
                      <Input
                        id="EXCHANGE_RATE_USA"
                        name="EXCHANGE_RATE_USA"
                        className="form-control text-end"
                        type="text"
                        value={initialData.EXCHANGE_RATE_USA}
                        onBlur={validation.handleBlur}
                        onChange={(e) => handleChange("EXCHANGE_RATE_USA", e.target.value)}
                        invalid={validation.errors.EXCHANGE_RATE_USA ? true : false}
                      />
                      {validation.errors.EXCHANGE_RATE_USA ? (
                        <FormFeedback type="invalid">
                          {validation.errors.EXCHANGE_RATE_USA}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        {t("Average exchange rate")} 01/01/
                        {initialData.computation_year - 1} - 31/12/
                        {initialData.computation_year - 1}
                      </Label>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col className="col-12">
                    <hr></hr>
                    <br />
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} lg={6} md={6} sm={12}>
                    <div className="table-responsive mb-3">
                      <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                        <thead>
                          <tr>
                            <th colSpan={4} className="text-center">
                              {t("PERS_ALL_USA")} ({t("Single")})
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {initialPERS_ALL_USA &&
                            initialPERS_ALL_USA.map((item: any, idx: number) => (
                              <tr key={idx}>
                                <td className="text-end">{parseFloat(item[0]).toFixed(2)}</td>
                                <td className="text-end">
                                  <Input
                                    className="form-control text-end"
                                    type="text"
                                    value={item[1]}
                                    onChange={(e: any) => {
                                      handlePERS_ALL_USA(1, e.target.value, idx);
                                    }}
                                  />
                                </td>
                                <td className="text-end">
                                  <Input
                                    className="form-control text-end"
                                    type="text"
                                    value={item[2]}
                                    onChange={(e: any) => {
                                      handlePERS_ALL_USA(2, e.target.value, idx);
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>

                  <Col xl={6} lg={6} md={6} sm={12}>
                    <div className="table-responsive mb-3">
                      <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                        <thead>
                          <tr>
                            <th colSpan={4} className="text-center">
                              {t("PERS_ALL_USA_MAR")} ({t("Married")} - {t("filing separately")})
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {initialPERS_ALL_USA_MAR &&
                            initialPERS_ALL_USA_MAR.map((item: any, idx: number) => (
                              <tr key={idx}>
                                <td className="text-end">{parseFloat(item[0]).toFixed(2)}</td>
                                <td className="text-end">
                                  <Input
                                    className="form-control text-end"
                                    type="text"
                                    value={item[1]}
                                    onChange={(e: any) => {
                                      handlePERS_ALL_USA_MAR(1, e.target.value, idx);
                                    }}
                                  />
                                </td>
                                <td className="text-end">
                                  <Input
                                    className="form-control text-end"
                                    type="text"
                                    value={item[2]}
                                    onChange={(e: any) => {
                                      handlePERS_ALL_USA_MAR(2, e.target.value, idx);
                                    }}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl={6} lg={6} md={6} sm={12}>
                    <div className="table-responsive mb-3">
                      <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                        <thead>
                          <tr>
                            <th colSpan={4} className="text-center">
                              {t("Tax rates")} ({t("Single")})
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {initialTAX_RATE_USA_SINGLE &&
                            initialTAX_RATE_USA_SINGLE.map((item: any, idx: number) => (
                              <tr key={idx}>
                                <td className="text-end">{parseFloat(item[0]).toFixed(2)}</td>
                                <td className="text-end">
                                  {idx !== 6 ? (
                                    <Input
                                      className="form-control text-end"
                                      type="text"
                                      value={item[1]}
                                      onChange={(e: any) => {
                                        handleTAX_RATE_USA_SINGLE(1, e.target.value, idx);
                                      }}
                                    />
                                  ) : null}
                                </td>
                                <td className="text-end">{parseFloat(item[2]).toFixed(2)} %</td>
                                <td className="text-end">{parseFloat(item[3]).toFixed(2)}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>

                  <Col xl={6} lg={6} md={6} sm={12}>
                    <div className="table-responsive mb-3">
                      <Table className="table table-bordered table-hover nowrap w-100 mb-0">
                        <thead>
                          <tr>
                            <th colSpan={4} className="text-center">
                              {t("Tax rates")} ({t("Married")} - {t("filing separately")})
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {initialTAX_RATE_USA_MARRIED &&
                            initialTAX_RATE_USA_MARRIED.map((item: any, idx: number) => (
                              <tr key={idx}>
                                <td className="text-end">{parseFloat(item[0]).toFixed(2)}</td>
                                <td className="text-end">
                                  {idx !== 6 ? (
                                    <Input
                                      className="form-control text-end"
                                      type="text"
                                      value={item[1]}
                                      onChange={(e: any) => {
                                        handleTAX_RATE_USA_MARRIED(1, e.target.value, idx);
                                      }}
                                    />
                                  ) : null}
                                </td>
                                <td className="text-end">{parseFloat(item[2]).toFixed(2)} %</td>
                                <td className="text-end">{parseFloat(item[3]).toFixed(2)}</td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col className="col-12">
                    <hr></hr>
                    <br />
                  </Col>
                </Row>

                <Row>
                  <Col xl={4} lg={4} md={4} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">{t("Average state tax")}</Label>
                      <Input
                        id="USA_STATE_TAX"
                        name="USA_STATE_TAX"
                        className="form-control text-end"
                        type="text"
                        value={initialData.USA_STATE_TAX}
                        onBlur={validation.handleBlur}
                        onChange={(e) => handleChange("USA_STATE_TAX", e.target.value)}
                        invalid={validation.errors.USA_STATE_TAX ? true : false}
                      />
                      {validation.errors.USA_STATE_TAX ? (
                        <FormFeedback type="invalid">
                          {validation.errors.USA_STATE_TAX}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </ModalBody>

          <ModalFooter>
            <Button color="primary" className="btn-block" type="submit" disabled={props.loading}>
              {props.loading ? (
                <span>
                  <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                    hourglass_empty
                  </span>
                  <span>{t("Please wait...")}</span>
                </span>
              ) : (
                <span>{t("Submit")}</span>
              )}
            </Button>
            <Button
              color="danger"
              className="btn-block"
              type="reset"
              onClick={props.onCloseClick}
              disabled={props.loading}
            >
              {t("Cancel")}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default SplitPayrollYearForm;
