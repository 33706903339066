import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../../components/Common/Loader';
import GraphFilters from '../../../components/Chart/Filters';
import { getUserProfile } from '../../../helpers/functions_helper';
import taskService from '../../../services/tasks.service';
import { serialize } from '../../../utils/utils';
import { useNavigate } from 'react-router-dom';
import gfilters from "../filter";
import { toast } from 'react-toastify';
import Chart from "../../../components/Chart";
import { currentMonth, currentYear } from '../../../common/staticdata';
interface TaskOriginatedProps extends CartProps {
    filters?: any;
}

const TaskGeneratedByQuestionnaire: FC<TaskOriginatedProps> = ({ hideHeading, filters = null }) => {

    const { t } = useTranslation();

    const userObj = getUserProfile('all-tasks');
    const navigate = useNavigate();
    const [selectedID, setSelectedID] = useState<any>({ id: null, event: null });
    const inititalFilter = {
        week: null,
        quarter: null,
        month: currentMonth,
        year: currentYear,
        user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_ID : null,
        user_name: null
    };

    const viewTaskList = (i: any) => {
        console.log(i);
        if (data.ids) {
            const x = data.ids[i];
            setSelectedID({ id: null, event: null });
            if (x !== -1) {
                let _filter = {
                    type: 'fees',
                    id: x,
                    label: 'taskOriginated'
                }
                gfilters.setFilter(_filter);
                navigate('../tasks/all-tasks');
            }
        }
    }

    useEffect(() => {
        if (selectedID?.event === 'click') {
            viewTaskList(selectedID.id);
        }
    }, [selectedID])

    const [loading, setLoading] = useState<boolean>(true);
    const [query, setQuery] = useState<string | null>(serialize(inititalFilter));
    const [filter, setFilter] = useState<any>(inititalFilter);

    const [data, setData] = useState<any>({
        series: [{
            name: 'Total',
            data: []
        }],
        ids: [],
        options: {
            labels: [],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        show: true,
                        position: 'left'
                    }
                }
            }],
            legend: {
                show: false,
                position: 'right'
            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            dataLabels: {
                enabled: true,
            },
            xaxis: {
                categories: [],
                labels: {
                    show: true,
                    trim: true,
                    rotate: -45,
                }
            },
            plotOptions: {
                bar: {
                    barHeight: '100%',
                    columnWidth: '100%',
                    distributed: true,
                    horizontal: false,
                    dataLabels: {
                        position: 'center',
                        orientation: 'vertical',
                    },
                }
            },
        },
    })



    const getChartData = async () => {
        try {
            const result = await taskService.getTaskGeneratedByQuestionnaire(query);
            if (result.data.status === 'success') {
                setData((data: any) => ({
                    ...data,
                    series: result?.data?.data[0].data ? [{
                        data: result?.data?.data[0].data || [],
                        name: t('Total'),
                    }] : [],
                    ids: result?.data?.data[0].ids || [],
                    options: {
                        ...data.options,
                        labels: result?.data?.data[0].label || [],
                        tooltip: {
                            enabled: true,
                        },
                    }
                }))
            }
            setLoading(false);
        } catch (error: any) {
            toast(error)
        }

    }

    const clearWorkAllocationFilter = () => {
        setFilter(inititalFilter);
    }



    useEffect(() => {
        loading && getChartData();
    }, [loading, query])

    useEffect(() => {
        setQuery(serialize(filter));
        setLoading(true);
    }, [filter])

    useEffect(() => {
        filters && setFilter(filters)
    }, [filters])

    return (
        <>
            <div className="d-sm-flex flex-wrap">
                <h4 className="card-title mb-4">{!hideHeading && t("Task generated by questionnaire")}</h4>&nbsp;
                <div className="ms-auto">
                    <GraphFilters
                        filter={filter}
                        setFilter={setFilter}
                        clearFilter={clearWorkAllocationFilter}
                        enableUser={true}
                        enableCompany={true}
                        slug='all-tasks'
                    />
                </div>
            </div>
            <div className="clearfix"></div>
            {loading ? <LoadingSpinner height={380} /> : <div style={{ height: '100%' }}><Chart options={data.options} series={data.series} type="bar" height={350} /></div>}
        </>
    )
}

export default TaskGeneratedByQuestionnaire;