import {
  GET_COMPANY_CATEGORY,
  GET_COMPANY_CATEGORY_SUCCESS,
  GET_COMPANY_CATEGORY_ERROR,

  GET_COMPANY_CATEGORY_LIST,
  GET_COMPANY_CATEGORY_LIST_SUCCESS,
  GET_COMPANY_CATEGORY_LIST_ERROR,

  CREATE_COMPANY_CATEGORY,
  CREATE_COMPANY_CATEGORY_SUCCESS,
  CREATE_COMPANY_CATEGORY_ERROR,

  UPDATE_COMPANY_CATEGORY,
  UPDATE_COMPANY_CATEGORY_SUCCESS,
  UPDATE_COMPANY_CATEGORY_ERROR,

  DELETE_COMPANY_CATEGORY,
  DELETE_COMPANY_CATEGORY_SUCCESS,
  DELETE_COMPANY_CATEGORY_ERROR,
} from './actionTypes'

const initialState = {
  GET_COMPANY_CATEGORY_SUCCESS: "",
  GET_COMPANY_CATEGORY_ERROR: "",
  GET_COMPANY_CATEGORY_LIST_SUCCESS: "",
  GET_COMPANY_CATEGORY_LIST_ERROR: "",
  CREATE_COMPANY_CATEGORY_SUCCESS: "",
  CREATE_COMPANY_CATEGORY_ERROR: "",
  UPDATE_COMPANY_CATEGORY: "",
  UPDATE_COMPANY_CATEGORY_SUCCESS: "",
  UPDATE_COMPANY_CATEGORY_ERROR: "",
  DELETE_COMPANY_CATEGORY: "",
  DELETE_COMPANY_CATEGORY_SUCCESS: "",
  DELETE_COMPANY_CATEGORY_ERROR: "",
  loading: false,
}

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    // ---------------------------------------------GET_COMPANY_CATEGORY
    case GET_COMPANY_CATEGORY:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_COMPANY_CATEGORY_SUCCESS:
      state = {
        ...state,
        GET_COMPANY_CATEGORY_SUCCESS: action.payload,
        loading: false,
      }
      break

    case GET_COMPANY_CATEGORY_ERROR:
      state = {
        ...state,
        GET_COMPANY_CATEGORY_ERROR: action.payload,
        loading: false
      }
      break

    // ---------------------------------------------GET_COMPANY_CATEGORY_LIST
    case GET_COMPANY_CATEGORY_LIST:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_COMPANY_CATEGORY_LIST_SUCCESS:
      state = {
        ...state,
        GET_COMPANY_CATEGORY_LIST_SUCCESS: action.payload,
        loading: false,
      }
      break

    case GET_COMPANY_CATEGORY_LIST_ERROR:
      state = {
        ...state,
        GET_COMPANY_CATEGORY_LIST_ERROR: action.payload,
        loading: false
      }
      break

    // ---------------------------------------------CREATE_COMPANY_CATEGORY
    case CREATE_COMPANY_CATEGORY:
      state = {
        ...state,
        loading: true,
      }
      break

    case CREATE_COMPANY_CATEGORY_SUCCESS:
      state = {
        ...state,
        CREATE_COMPANY_CATEGORY_SUCCESS: action.payload,
        loading: false,
      }
      break

    case CREATE_COMPANY_CATEGORY_ERROR:
      state = {
        ...state,
        CREATE_COMPANY_CATEGORY_ERROR: action.payload,
        loading: false
      }
      break

    // ---------------------------------------------UPDATE_COMPANY_CATEGORY
    case UPDATE_COMPANY_CATEGORY:
      state = {
        ...state,
        loading: true,
      }
      break

    case UPDATE_COMPANY_CATEGORY_SUCCESS:
      state = {
        ...state,
        UPDATE_COMPANY_CATEGORY_SUCCESS: action.payload,
        loading: false,
      }
      break

    case UPDATE_COMPANY_CATEGORY_ERROR:
      state = {
        ...state,
        UPDATE_COMPANY_CATEGORY_ERROR: action.payload,
        loading: false
      }
      break

    // ---------------------------------------------DELETE_COMPANY_CATEGORY
    case DELETE_COMPANY_CATEGORY:
      state = {
        ...state,
        loading: true,
      }
      break

    case DELETE_COMPANY_CATEGORY_SUCCESS:
      state = {
        ...state,
        DELETE_COMPANY_CATEGORY_SUCCESS: action.payload,
        loading: false,
      }
      break

    case DELETE_COMPANY_CATEGORY_ERROR:
      state = {
        ...state,
        DELETE_COMPANY_CATEGORY_ERROR: action.payload,
        loading: false
      }
      break

    // ---------------------------------------------DEFAULT
    default:
      state = { ...state }
      break
  }
  return state
}

export default reducer
