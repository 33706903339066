import moment from "moment";

export const initialValues = {
  id: "",
  split_payroll_year_id: "",
  computation_year: parseInt(moment().format("YYYY")),
  tax_free_amount_for_attribution_to_spouse: 0,
  tax_free_amount_for_children_less_3_year_old: 0,
  tax_free_amount_for_single_parents: 0,
  tax_free_amount_for_taxpayer: 0,
  tax_free_amount_for_spouse: 0,
  business_expenses_director_percentage: 0,
  business_expenses_director_maximum_amount: 0,
  commuting_exemption: 0,
  attribution_to_spouse: 0,
  min_bik_per_year: 0,
  revaluation_factor: 0,

  P_TAXES_EXEMPT_AMOUNT: [
    [0, 0, 0.25, 0],
    [0, 0, 0.3, 0],
    [0, 0, 0.4, 0],
    [0, 0, 0.45, 0],
    [0, 0, 0.5, 0],
  ],
  P_TAXES: [
    [0, 0, 0.25, 0],
    [0, 0, 0.4, 0],
    [0, 0, 0.45, 0],
    [0, 0, 0.5, 0],
  ],
  P_CHILDREN: [
    [0, 0, 0, 0],
    [1, 0, 0, 0],
    [2, 0, 0, 0],
    [3, 0, 0, 0],
    [4, 0, 0, 0],
    [5, 0, 0, 0],
    [6, 0, 0, 0],
  ],
  P_Expenses: [
    [0, 0, 0.3, 0],
    [0, 0, 0, 0],
  ],
  companyCarStatuses: [
    {
      value: 1,
      label: "No Company car",
      co2: 0, // MASTER VALUE
      basic: 0,
      increase: 0,
      min: 0,
      max: 0,
      applicable: 0,
      cal_0: 0,
      cal_1: 0,
      cal_2: 0,
      cal_3: 0,
      cal_4: 0,
      cal_5: 0,
      cal_6: 0,
    },
    {
      value: 2,
      label: "Company car: Petrol / LPG",
      co2: 0, // MASTER VALUE
      basic: 5.5,
      increase: 0.1,
      min: 4,
      max: 18,
      applicable: 0,
      cal_0: 0,
      cal_1: 0,
      cal_2: 0,
      cal_3: 0,
      cal_4: 0,
      cal_5: 0,
      cal_6: 0,
    },
    {
      value: 3,
      label: "Company car: Diesel",
      co2: 0, // MASTER VALUE
      basic: 5.5,
      increase: 0.1,
      min: 4,
      max: 18,
      applicable: 0,
      cal_0: 0,
      cal_1: 0,
      cal_2: 0,
      cal_3: 0,
      cal_4: 0,
      cal_5: 0,
      cal_6: 0,
    },
    {
      value: 4,
      label: "Company car: Electric",
      co2: 0, // MASTER VALUE
      basic: 4,
      increase: 0,
      min: 4,
      max: 4,
      applicable: 0,
      cal_0: 0,
      cal_1: 0,
      cal_2: 0,
      cal_3: 0,
      cal_4: 0,
      cal_5: 0,
      cal_6: 0,
    },
  ],
  SS_SOCIAL_SECURITY: [
    [0, 0, 0, 0],
    [0, 0, 20.5, 0],
    [0, 0, 14.16, 0],
    [0, 0, 0, 0],
  ],

  // ESTONIA
  EST_TAX_RATE: 0,

  // FRANCE
  DED_10_MIN: 0,
  DED_10_MAX: 0,
  WORK_DAYS: 0,
  RAS: [
    [0, 0, 0, 0],
    [0, 0, 12, 0],
    [0, 0, 20, 0],
  ],
  FINAL_TAX: [
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 20, 0],
    [0, 0, 30, 0],
  ],

  // GERMANY
  C_WERBUNG: 0,
  C_SONDER: 0,
  C_SPECIAL_EXP: 0,
  C_REBATE_TREATY: 0,
  C_SOLIDARITY: 0,
  C_SOLIDARITY_MIN_LIMIT: 0,
  C_SOLIDARITY_MIN_PERC: 0,
  TAX_RATE_G: [
    [0, 0, 0, 0],
    [0, 0, 14, 0],
    [0, 0, 23.97, 0],
    [0, 0, 42, 0],
    [0, 0, 45, 0],
  ],
  GER_TAX_RATE: 0,

  // LUXEMBOURG

  // NETHERLANDS
  C_DED_BE_SS: 0,
  GENERAL_REBATE: [
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0]
  ],
  EMPLOYMENT_REBATE: [
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0]
  ],
  TAX_RATE_N: [
    [0, 21043, 9.45, 0],
    [21043, 35129, 9.45, 1989],
    [35129, 68507, 37.10, 3320],
    [68507, 0, 49.50, 15703]
  ],
  SS_RATE_N: [
    [0, 21043, 27.65, 0],
    [21043, 35129, 27.65, 5818],
    [35129, 68507, 0, 9713],
    [68507, 0, 0, 9713]
  ],

  // POLAND
  POL_TAX_RATE: 0,
  EXCHANGE_RATE_POL: 0,
  PERSONAL_ALLOWANCE_P: [
    [0, 0, 0, 1360],
    [0, 0, 0, 0],
    [0, 0, 0, 525],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
  ],
  TAX_RATE_P: [
    [0, 0, 0, 0],
    [0, 0, 17, 0],
    [0, 0, 32, 0],
  ],
  STANDARD_DEDUCTION: 0,

  // ROMANIA
  ROM_TAX_RATE: 0,

  // SPAIN
  SPN_TAX_RATE: 0,
  TAX_RATE_S: [
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0]
  ],
  EMPLOYMENT_REDUCTION: [
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0]
  ],

  // UNITED KINGDOM
  EXCHANGE_RATE_UK: 0,
  PERS_ALL_SINGLE: 0,
  PERS_ALL_DEDUCTION: 0,
  GBP_OVER_GBP: 0,
  PERS_ALL_MARRIED: 0,
  TAX_RATE_UK: [
    [-1, 0, 0, 0],
    [0, 0, 20, 0],
    [0, 0, 40, 0],
    [0, 0, 45, 0]
  ],

  // UNITED STATES
  EXCHANGE_RATE_USA: 0,
  PERS_ALL_USA: [
    [0, 0, 0]
  ],
  PERS_ALL_USA_MAR: [
    [0, 0, 0]
  ],
  TAX_RATE_USA_SINGLE: [
    [0, 0, 10, 0],
    [0, 0, 12, 0],
    [0, 0, 22, 0],
    [0, 0, 24, 0],
    [0, 0, 32, 0],
    [0, 0, 35, 0],
    [0, 0, 37, 0]
  ],
  TAX_RATE_USA_MARRIED: [
    [0, 0, 10, 0],
    [0, 0, 12, 0],
    [0, 0, 22, 0],
    [0, 0, 24, 0],
    [0, 0, 32, 0],
    [0, 0, 35, 0],
    [0, 0, 37, 0]
  ],
  USA_STATE_TAX: 0,
};
