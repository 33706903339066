import { memo } from 'react'
import './style.scss'
import { Button } from './styles'
type attachmentProps = {
    link?: string,
    size: number,
    name: string,
    type?: string,
    handleOnDelete: () => void,
    download?:boolean,
    downloadAttachment?:()=>void,
    deleteOption: boolean
}

function FileIcon(type: string) {
    switch (type) {
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return <i className="fa fa-file-word-o text-primary" aria-hidden="true"></i>
        case 'application/pdf':
            return <i className="fa fa-file-pdf-o text-danger" aria-hidden="true"></i>;
        case 'application/vnd.ms-excel':
            return <i className="fa fa-file-excel-o text-success" aria-hidden="true"></i>
        case 'application/octet-stream':
            return <i className="fa fa-file-excel-o text-success" aria-hidden="true"></i>
        default:
            return <i className="fa fa-file-o text-dark" aria-hidden="true"></i>
    }
}

function formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

const Attachment = (props: attachmentProps) => {
    return (
        <div className="border border-light mb-2 align-items-center " style={{ borderRadius: '5px' }}>
            <div className="text-body">
                <div>
                    <div style={{cursor:'pointer'}} className="d-flex align-items-center " onClick={props?.download ? props?.downloadAttachment : undefined}>
                        <div className="avatar-xs align-self-center me-2 p-1">
                            <div className="avatar-title rounded bg-transparent text-dark font-size-20">
                                {FileIcon(props?.type || '')}
                            </div>
                        </div>
                        <div className="p-1" style={{ borderRight: '1px solid #eeeded', flex: 1 }}>
                            <h5 className="font-size-13 text-truncate mb-1">{props?.name}</h5>
                            <p className="text-muted text-truncate mb-0 font-weight-light" style={{ fontSize: '11px' }}>{formatBytes(props?.size)}</p>
                        </div>
                        {
                            props.deleteOption && <div className="ml-2 flex-shrink-1" style={{ width: '32px' }}>
                                <Button size="sm" color="link" onClick={() => props.handleOnDelete()}><i className="fa-solid fa-trash"></i></Button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default memo(Attachment);