import {
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  GET_USER_PROFILE,
  GET_USER_PROFILE_SUCCESS,
  GET_USER_PROFILE_ERROR,
  GET_USERS_LIST,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_ERROR,
  CREATE_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_ERROR,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
  GET_USER_GENERAL,
  GET_USER_GENERAL_SUCCESS,
  GET_USER_GENERAL_ERROR,
} from "./actionTypes";

const initialState = {
  GET_USER_SUCCESS: "",
  GET_USER_ERROR: "",
  GET_USER_PROFILE_SUCCESS: "",
  GET_USER_PROFILE_ERROR: "",
  GET_USERS_LIST_SUCCESS: "",
  GET_USERS_LIST_ERROR: "",
  CREATE_USER_SUCCESS: "",
  CREATE_USER_ERROR: "",
  UPDATE_USER: "",
  UPDATE_USER_SUCCESS: "",
  UPDATE_USER_ERROR: "",
  DELETE_USER: "",
  DELETE_USER_SUCCESS: "",
  DELETE_USER_ERROR: "",
  GET_USER_GENERAL: "",
  GET_USER_GENERAL_SUCCESS:"",
  GET_USER_GENERAL_ERROR:"",
  loading: false,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    // ---------------------------------------------GET_USER
    case GET_USER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_USER_SUCCESS:
      state = {
        ...state,
        GET_USER_SUCCESS: action.payload,
        loading: false,
      };
      break;

    case GET_USER_ERROR:
      state = {
        ...state,
        GET_USER_ERROR: action.payload,
        loading: false,
      };
      break;

    // ---------------------------------------------GET_USER_PROFILE
    case GET_USER_PROFILE:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_USER_PROFILE_SUCCESS:
      state = {
        ...state,
        GET_USER_PROFILE_SUCCESS: action.payload,
        loading: false,
      };
      break;

    case GET_USER_PROFILE_ERROR:
      state = {
        ...state,
        GET_USER_PROFILE_ERROR: action.payload,
        loading: false,
      };
      break;

    // ---------------------------------------------GET_USERS_LIST
    case GET_USERS_LIST:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_USERS_LIST_SUCCESS:
      state = {
        ...state,
        GET_USERS_LIST_SUCCESS: action.payload,
        loading: false,
      };
      break;

    case GET_USERS_LIST_ERROR:
      state = {
        ...state,
        GET_USERS_LIST_ERROR: action.payload,
        loading: false,
      };
      break;

    // ---------------------------------------------CREATE_USER
    case CREATE_USER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case CREATE_USER_SUCCESS:
      state = {
        ...state,
        CREATE_USER_SUCCESS: action.payload,
        loading: false,
      };
      break;

    case CREATE_USER_ERROR:
      state = {
        ...state,
        CREATE_USER_ERROR: action.payload,
        loading: false,
      };
      break;

    // ---------------------------------------------UPDATE_USER
    case UPDATE_USER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case UPDATE_USER_SUCCESS:
      state = {
        ...state,
        UPDATE_USER_SUCCESS: action.payload,
        loading: false,
      };
      break;

    case UPDATE_USER_ERROR:
      state = {
        ...state,
        UPDATE_USER_ERROR: action.payload,
        loading: false,
      };
      break;

    // ---------------------------------------------DELETE_USER
    case DELETE_USER:
      state = {
        ...state,
        loading: true,
      };
      break;

    case DELETE_USER_SUCCESS:
      state = {
        ...state,
        DELETE_USER_SUCCESS: action.payload,
        loading: false,
      };
      break;

    case DELETE_USER_ERROR:
      state = {
        ...state,
        DELETE_USER_ERROR: action.payload,
        loading: false,
      };
      break;
    
    // ---------------------------------------------USER GENERAL
    case GET_USER_GENERAL:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_USER_GENERAL_SUCCESS:
      state = {
        ...state,
        GET_USER_GENERAL_SUCCESS: action.payload,
        loading: false,
      }
      break

    case GET_USER_GENERAL_ERROR:
      state = { 
        ...state, 
        GET_USER_GENERAL_ERROR: action.payload, 
        loading: false 
      }
      break

    // ---------------------------------------------DEFAULT
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default reducer;
