import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Label,
  Row,
  FormFeedback,
  UncontrolledAlert,
  Modal,
  ModalBody,
  ModalFooter,

} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
//redux
import { useSelector } from "react-redux";
// @ts-ignore
import CustomEditor from "ckeditor5-custom-build/build/ckeditor";
import { ckEditorConfiguration, getDependentMultipleWhereSelectOptions, getSelectOptions, yearData } from "../../../helpers/functions_helper";
import { useTranslation } from "react-i18next";
import Select from "../../../components/Form/Select/Select";
import TextEditor from "../../../components/Form/TextEditor";

// Import Breadcrumb
//import Breadcrumbs from "../../../components/Layout/Breadcrumb";
const QuestionnaireForm = (props: any) => {
  let statusData = props.statusData;
  const { t } = useTranslation();

  // for setting color picker value
  const [color, setColor] = useState('');

  /* set user Status */
  const [userStatusOption, setuserStatusOption] = useState([]);
  const [userstatusValue, setUserstatusValue] = useState<any>();
  const [loadingUserStatus, setloadingUserStatus] = useState(true);
  const [userType, setUserType] = useState<any>("");
  const [yearValue, setYearValue] = useState<any>(statusData?.year);
  /* set user Status */

  /* set questionnaire type */
  const [questionnaireTypeOption, setQuestionnaireTypeOption] = useState([]);
  const [questionnaireType, setQuestionnaireType] = useState<any>();
  const [loadingQuestionnaireType, setLoadingQuestionnaireType] = useState(true);

  const handlecolor = (e: any) => {
    setColor(e.target.value)
  }
  const [userTypeFlag, setUserTypeFlag] = useState(false);
  const [hideAttach, setHideAttach] = useState(statusData.hide_attachement ? true : false);

  const [error, setError] = useState('');

  let data = useSelector((state: any) => ({
    success: state.QuestionnaireStatus.success,
    error: state.QuestionnaireStatus.error,
    isModel: state.QuestionnaireStatus.isModel,
  }));

  useEffect(() => {
    getSelectOptions("tbl_master_user_status", "user_status_value").then(function (
      options
    ) {
      setuserStatusOption(options);
      setloadingUserStatus(false);
      const selectedOption = options.find((option: any) => option.value === statusData.user_type);
      if (selectedOption) {
        setUserstatusValue(selectedOption);
        setUserType(selectedOption.value);
        validation.setFieldValue("user_type", statusData.user_type);
      }

    });

    getDependentMultipleWhereSelectOptions(
      "tbl_master_questionnaire_type",
      "questionnaire_type",
      ["year"],
      [(new Date()).getFullYear()]
    ).then(function (options) {
      setQuestionnaireTypeOption(options);
      setLoadingQuestionnaireType(false);
    });

  }, [])
  useEffect(() => {
    if (userType && yearValue) {
      setQuestionnaireTypeOption([]);
      setLoadingQuestionnaireType(true);
      validation.setFieldValue("questionnaire_type_id", 0);
      getDependentMultipleWhereSelectOptions(
        "tbl_master_questionnaire_type",
        "questionnaire_type",
        ["user_type", "year"],
        [userType, yearValue]
      ).then(function (options) {
        let opt: any = [];
        opt.push(
          ...options,
          { label: "-- Remove --", value: "" }
        );
        setQuestionnaireTypeOption(opt);
        setLoadingQuestionnaireType(false);
        const selectedOption = options.find((option: any) => option.value === statusData.questionnaire_type_id);
        validation.setFieldValue("questionnaire_type_id", statusData.questionnaire_type_id);
        setQuestionnaireType(selectedOption);
      });
    } else if (yearValue) {
      setQuestionnaireTypeOption([]);
      setLoadingQuestionnaireType(true);
      getDependentMultipleWhereSelectOptions(
        "tbl_master_questionnaire_type",
        "questionnaire_type",
        ["year"],
        [yearValue]
      ).then(function (options) {
        let opt: any = [];
        opt.push(
          ...options,
          { label: t("-- Remove --"), value: "" }
        );
        setQuestionnaireTypeOption(opt);
        setLoadingQuestionnaireType(false);
        const selectedOption = options.find((option: any) => option.value === statusData.questionnaire_type_id);
        validation.setFieldValue("questionnaire_type_id", statusData.questionnaire_type_id);
        setQuestionnaireType(selectedOption);
      });
    } else if (!yearValue) {
      setYearValue((new Date()).getFullYear());
    }
  }, [userType, yearValue]);


  const [yearList, setYearList] = useState<any>([]);
  const [languageList, setLanguageList] = useState<any>([]);
  const [userStatusList, setUserStatusList] = useState<any>([]);

  useEffect(() => {

    if (userStatusOption) {
      const options = userStatusOption?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setUserStatusList(options);
    }

    if (yearData) {
      const options = yearData?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setYearList(options);
    }

  }, [localStorage.getItem("I18N_LANGUAGE"), userStatusOption])

  useEffect(() => {
    if (data.error !== error) setError(data.error);
  }, [data.error]);

  useEffect(() => {
    if (statusData.user_type == "") {
      setUserTypeFlag(false);
    } else {
      setUserTypeFlag(true);
    }
  }, [statusData.user_type])


  const CKinputHandler = (event: any, editor: any) => {
    validation.setFieldValue("tooltip_data", editor.getData());
  };

  const CKinputHandlerTab = (event: any, editor: any) => {
    validation.setFieldValue("questionnaire_tab_value", editor.getData());
  };

  const validationSchema = Yup.object().shape({
    questionnaire_tab_value: Yup.string()
      .required("Questionnaire tab field is required.")
      .min(2, "Questionnaire tab must be greater than 2 characters.")
      .max(250, "Questionnaire tab may not be greater than 250 characters."),
    questionnaire_type_id: Yup.string().nullable()
      .required("Questionnaire type field is required."),
    year: Yup.number().nullable()
      .required("Year field is required."),
  });

  const validation = useFormik({
    initialValues: {
      id: (statusData && statusData.id) || '',
      questionnaire_tab_id: (statusData && statusData.questionnaire_tab_id) || '',
      questionnaire_tab_value: (statusData && statusData.questionnaire_tab_value_html) || '',
      year: (statusData && statusData.year) || ((new Date()).getFullYear()),
      color_code: (statusData && statusData.color_code) || "#000000",
      hide_attachement: (statusData && statusData.hide_attachement) || '',
      user_type: (statusData && statusData.user_type) || '',
      questionnaire_type_id: (statusData && statusData.questionnaire_type_id) || '',
      tooltip_data: (statusData && statusData.tooltip_data_html) || '',
      duplicate: (statusData && statusData.duplicate) || false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      props.onSubmitClick(values);
    },
  });
  return (
    <React.Fragment>
      <Modal
        size="xl"
        isOpen={props.show}
        toggle={props.onCloseClick}
        backdrop="static"
        keyboard={false}
        autoFocus={false}
      >
        <div className="modal-header">
          <h5 className="modal-title">{props.modalTitle}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={props.onCloseClick}
            aria-label="Close"
          ></button>
        </div>
        <Row>
          <Col lg="12">
            {error && data.isModel && error !== '' ? (
              <UncontrolledAlert color="danger" role="alert">
                {error}
              </UncontrolledAlert>
            ) : null}
            <Form
              onSubmit={(e: any) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              onChange={() => setError('')}
            >
              <ModalBody>
                <Row>
                  <Col lg="4">
                    <div className="mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheckcolor1"
                        name="user_type_flag"
                        onChange={e => {
                          setUserTypeFlag(!userTypeFlag);
                          if (!e.target.checked) {
                            setUserstatusValue({
                              value: "",
                              label: t("Please select..."),
                            })
                            setUserType("");
                            setQuestionnaireType({
                              value: "",
                              label: t("Please select..."),
                            });
                            validation.setFieldValue("user_type", null);
                          }
                        }}
                        checked={userTypeFlag}
                        onBlur={validation.handleBlur}
                      />
                      <Select
                        id="user_type"
                        name="user_type"
                        label="User type"
                        className="is-invalid"
                        options={userStatusList}
                        value={userstatusValue}
                        isSearchable={true}
                        isDisabled={loadingUserStatus || !userTypeFlag}
                        onChange={(option: any) => {
                          setUserstatusValue(option);
                          setUserType(option.value);
                          validation.setFieldValue("user_type", option.value);
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg="2">
                    <div className="mb-3">
                      <Select
                        id="year"
                        name="year"
                        label="Year"
                        required
                        className="is-invalid"
                        options={yearData}
                        defaultValue={yearData.find((opt: any) => opt.value == statusData.year)}
                        isSearchable={true}
                        onChange={(option: any) => {
                          validation.setFieldValue("year", option.value);
                          setYearValue(option.value);
                        }}
                        error={validation.errors.year}
                        touched={validation.touched.year}
                      />
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-3">
                      <Select
                        id="questionnaire_type_id"
                        name="questionnaire_type_id"
                        className="is-invalid"
                        label="Questionnaire type"
                        required
                        options={questionnaireTypeOption}
                        value={questionnaireType}
                        isSearchable={true}
                        isDisabled={loadingQuestionnaireType}
                        onChange={(option: any) => {
                          validation.setFieldValue("questionnaire_type_id", option.value);
                          setQuestionnaireType(option);
                        }}
                        error={validation.errors.questionnaire_type_id}
                        touched={validation.touched.questionnaire_type_id}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="3">
                    <div className="form-check form-check-primary mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customCheckenableSubmit"
                        name="hide_attachement"
                        onBlur={validation.handleBlur}
                        checked={hideAttach}
                        onChange={(e: any) => {
                          setHideAttach(e.target.checked);
                          validation.setFieldValue("hide_attachement", e.target.checked);
                        }}
                      />

                      <label
                        className="form-check-label"
                        htmlFor="customCheckenableSubmit"
                      >
                        {t("Hide attachment question")}
                      </label>

                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <div className="mb-3">
                      <div className="is-invalid">
                        <TextEditor
                          id="questionnaire_tab_value"
                          name="questionnaire_tab_value"
                          label="Questionnaire tab"
                          required
                          value={validation.values.questionnaire_tab_value}
                          onChange={(e: any, editor: any) => validation.setFieldValue('questionnaire_tab_value', editor.getData())}
                          className="form-control"
                          onBlur={() => validation.setFieldTouched("questionnaire_tab_value", true)}
                          touched={validation.touched.questionnaire_tab_value}
                          error={validation.errors.questionnaire_tab_value}
                        />
                      </div>
                      {/* <div className='color_palette_main_div'>
                      <Input
                        name="questionnaire_tab_value"
                        className="form-control"
                        placeholder="Enter Questionnaire tab"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.questionnaire_tab_value || ""}
                        invalid={
                          validation.touched.questionnaire_tab_value &&
                            validation.errors.questionnaire_tab_value
                            ? true
                            : false
                        }
                      />
                      <div className="color_palette_div">
                        <input
                          name="color_code"
                          className="color_palette form-control"
                          type="color"
                          id="example-color-input"
                          onChange={e => { handlecolor(e); validation.handleChange(e) }}
                          onBlur={validation.handleBlur}
                          value={validation.values.color_code || ""}

                        />
                      </div>
                      {validation.touched.questionnaire_tab_value &&
                        validation.errors.questionnaire_tab_value ? (
                        <FormFeedback type="invalid">
                          {validation.errors.questionnaire_tab_value}
                        </FormFeedback>
                      ) : null}
                    </div> */}
                    </div>
                  </Col>

                  <Col lg="6" className="mb-3">
                    <TextEditor
                      id="tooltip_data"
                      name="tooltip_data"
                      label="Tooltip data"
                      value={validation.values.tooltip_data}
                      onChange={(e: any, editor: any) => validation.setFieldValue('tooltip_data', editor.getData())}
                      className="form-control"
                      onBlur={() => validation.setFieldTouched("tooltip_data", true)}
                      touched={validation.touched.tooltip_data}
                      error={validation.errors.tooltip_data}
                    />
                  </Col>
                </Row>
                <ModalFooter>
                  <Button
                    color="primary"
                    className="btn-block"
                    type="submit"
                    disabled={props.loading}
                  >
                    {props.loading ? (
                      <span>
                        <i className="fa-solid fa-spinner fa-spin"></i>
                        <span>&nbsp;{t("Please wait...")}</span>
                      </span>
                    ) : (
                      <span>{t("Submit")}</span>
                    )}
                  </Button>
                  <Button
                    color="danger"
                    className="btn-block"
                    type="reset"
                    onClick={props.onCloseClick}
                    disabled={props.loading}
                  >
                    {t("Cancel")}
                  </Button>
                </ModalFooter>
              </ModalBody>
            </Form>
          </Col>
        </Row>
      </Modal>
    </React.Fragment >
  );
};
export default QuestionnaireForm;
