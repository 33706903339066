import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "reactstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getSelectOptions } from "../../../helpers/functions_helper";
import Select from "react-select";

const CreateTable = (props: any) => {
    const { t } = useTranslation();
    const rtdt = props.multi_input_data;
    const [columns, setColumns] = useState(["Column 1"]);
    const [generating, setGenerating] = useState(true);
    const [readOnly, setReadOnly] = useState(true);
    const [rows, setRows] = useState(1);
    const [rowsData, setRowsData] = useState<any>([['']]);
    const [modified, setModified] = useState(false);
    const [question, setQuestion] = useState<any>([]);
    const [answer, setAnswer] = useState<any>([]);
    const [loadinginput, setLoadingInput] = useState(true);
    const [inputOptions, setInputOptions] = useState([]);
    const [rtData, setRtData] = useState<any>([]);

    useEffect(() => {
        if (rtdt && rtdt.length > 0) {
            let dtrow: any = [];
            let maxlen = 0;
            rtdt.forEach((dr: any, index: any) => {
                let dtcol: any = [];
                if(dr.length){
                    dr.forEach((dc: any, index2: any) => {
                        if (dc.input_type == 0) {
                            dtcol[dc.array_column] = dc.question;
                        } else if (dc.input_type == 16) {
                            dtcol[dc.array_column] = [dc.input_type, dc.question];
                            setAnswer((prevanswer: any) => [
                                ...prevanswer,
                                index + '' + index2,
                            ]);
                        }
                        else {
                            dtcol[dc.array_column] = dc.input_type;
                            setAnswer((prevanswer: any) => [
                                ...prevanswer,
                                index + '' + index2,
                            ]);
                        }
                        if (maxlen < index2 + 1) {
                            maxlen = index2 + 1;
                        }
                    });
                }
                dtrow[index] = dtcol;
            });

            for (let i = 1; i < maxlen; i++) {
                setColumns((prevColumns) => [
                    ...prevColumns,
                    `Column ${i + 1}`,
                ]);
            }

            setRowsData(dtrow);
        }
    }, [rtdt]);

    const handleChange = (e: any, index: any, index2: any) => {
        const fields = rowsData[index].map((r: any, j: any) => (j === index2 ? e == 16 ? [16, e] : e : r));
        setRowsData(rowsData.map((rw: any, i: any) => (i === index ? fields : rw)));
    };

    const handleCheck = (e: any, index: any, index2: any) => {
        const fields = rowsData[index].map((r: any, j: any) => (j === index2 ? [16, e] : r));
        setRowsData(rowsData.map((rw: any, i: any) => (i === index ? fields : rw)));
    };

    const addRow = () => {
        setModified(true);
        setRows((prevRows) => prevRows + 1);
        let array = [""];
        for (let i = 1; i < columns.length; i++) {
            array.push("");
        }
        setRowsData((prevRowsData: any) => [...prevRowsData, array]);
    };

    const deleteRow = (index: any) => {
        setModified(true);
        if (rowsData.length === 1) {
            return toast.dark("There should be atleast 1 row!");
        }
        setRows((prevRows) => prevRows - 1);
        setRowsData((prevRowsData: any[]) => prevRowsData.filter((row: any, i: any) => i !== index));
    };

    const addColumn = () => {
        setModified(true);
        if (columns.length === 5) {
            return toast.dark("You can add max. 5 columns!");
        }
        setColumns((prevColumns) => [
            ...prevColumns,
            `Column ${columns.length + 1}`,
        ]);

        setRowsData((prevRowsData: any[]) =>
            prevRowsData.map((row: string[]) => row.push("") && row)
        );
    };
    const deleteColumn = (index: number) => {
        setModified(true);
        if (columns.length === 1) {
            return toast.dark("There Should be atleast 1 column!");
        }
        setColumns((prevColumns) =>
            prevColumns.filter((col) => prevColumns.indexOf(col) !== index)
        );

        setRowsData((prevRowsData: any[]) =>
            prevRowsData.map((row: any[]) => {
                const filteredRows = row.filter((rw: any, i: any) => i !== index);
                return filteredRows;
            })
        );
    };
    useEffect(() => {
        getSelectOptions("tbl_master_questionnaire_input_type", "input_type_value").then(function (options) {
            options = options.filter((option: any) => (![3, 5, 6, 7, 8, 9, 10, 15, 17, 18, 19, 20, 21].includes(option.value)));
            setInputOptions(options);
            setLoadingInput(false);
        });
    }, []);

    const changeQuestAnswer = (index: any, index2: any, text: any) => {
        if (text == 'question') {
            const data = answer.filter((item: any) => {
                return item !== index + '' + index2;
            })
            setAnswer(data);
        } else {
            setAnswer([...answer, index + '' + index2]);
        }
    }

    useEffect(() => {
        props.setTableData(rowsData);
    }, [rowsData]);

    return (
        <Container fluid>
            <Col xl="12" className="mb-1">
                <div>
                    <Table className="table table-bordered nowrap w-100">
                        <thead className="thead-light">
                            <tr>
                                <th className="text-left">Sl No</th>
                                {columns.map((col, index) => (
                                    <th>{col}</th>
                                ))}
                                <th>
                                    <Button
                                        outline={true}
                                        color="primary"
                                        size="sm"
                                        className="mt-1"
                                        title={t("Add Column")}
                                        onClick={addColumn}>
                                        <i className="fa-solid fa-plus"></i>
                                    </Button>
                                    <Button
                                        outline={true}
                                        color="danger"
                                        size="sm"
                                        className="mt-1 mx-2"
                                        title={t("Delete Column")}
                                        onClick={() => deleteColumn(columns.length - 1)}>
                                        <i className="fa-solid fa-xmark"></i>
                                    </Button>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {rowsData.length > 0 ? (
                                <>
                                    {rowsData.map((data: any[], index: number) => (
                                        <tr>
                                            <td className="text-left">{index + 1}</td>
                                            {data.map((row: any, index2: any) => (
                                                <td key={index2 + 988}>
                                                    <div className="input-group">
                                                        {!answer.includes(index + '' + index2) ?
                                                            <input
                                                                type="text"
                                                                className="form-control text-left"
                                                                placeholder={`Enter field`}
                                                                value={rowsData[index][index2]}
                                                                onChange={(e) =>
                                                                    handleChange(e.target.value, index, index2)
                                                                }
                                                            />
                                                            : null}
                                                        {answer.includes(index + '' + index2) ?
                                                            <>
                                                                <Select
                                                                    id="input_type"
                                                                    name="input_type"
                                                                    className="form-control select-table"
                                                                    options={inputOptions}
                                                                    value={
                                                                        rowsData[index][index2]
                                                                            ? inputOptions.find((option: any) => {
                                                                                if (option.value == rowsData[index][index2]) {
                                                                                    return rowsData[index][index2];
                                                                                } else if (rowsData[index][index2][0] && option.value == rowsData[index][index2][0]) {
                                                                                    return rowsData[index][index2][0];
                                                                                }
                                                                            })
                                                                            : ""
                                                                    }
                                                                    isSearchable={true}
                                                                    onChange={(e: any) =>
                                                                        handleChange(e.value, index, index2)
                                                                    }
                                                                />
                                                            </>
                                                            : null
                                                        }
                                                        <span
                                                            className={!answer.includes(index + '' + index2) ? `input-group-text bg-primary text-white` : `input-group-text`}
                                                            title={t("Open")}
                                                            onClick={() => { changeQuestAnswer(index, index2, 'question'); }}
                                                        >
                                                            <i className="fa-solid fa-question-circle"></i>
                                                        </span>
                                                        <span
                                                            className={answer.includes(index + '' + index2) ? `input-group-text bg-primary text-white` : `input-group-text`}
                                                            title={t("Open")}
                                                            onClick={() => { changeQuestAnswer(index, index2, 'answer'); }}
                                                        >
                                                            <i className="fa-solid fa-list-alt"></i>
                                                        </span>
                                                        {rowsData[index][index2][0] === 16 ?
                                                            <Col xs={12}>
                                                                <input
                                                                    type="text"
                                                                    className="form-control text-center mt-2"
                                                                    placeholder={`Enter field`}
                                                                    value={rowsData[index][index2][1]!==16 ? rowsData[index][index2][1]: null}
                                                                    onChange={(e) =>
                                                                        handleCheck(e.target.value, index, index2)
                                                                    }
                                                                />
                                                            </Col>
                                                            : null
                                                        }
                                                    </div>
                                                </td>
                                            ))}
                                            <td><Button
                                                outline={true}
                                                color="primary"
                                                size="sm"
                                                className="mt-1"
                                                title={t("Add Row")}
                                                onClick={addRow}>
                                                <i className="fa-solid fa-plus"></i>
                                            </Button>
                                                <Button
                                                    outline={true}
                                                    color="danger"
                                                    size="sm"
                                                    className="mt-1 mx-2"
                                                    title={t("Delete Row")}
                                                    onClick={() => deleteRow(index)}>
                                                    <i className="fa-solid fa-xmark"></i>
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </>
                            ) : null}
                        </tbody>
                    </Table>
                </div>
            </Col>
        </Container>
    );
};

export default CreateTable;