import React, { useCallback, useRef, useState } from "react";
import {
    Modal,
    ModalBody,
    Button,
    Card,
    CardBody,
    CardFooter,
} from "reactstrap";
import AsyncSelect from 'react-select/async';
import EmailService from '../../../../services/email.service';
import { debounce } from 'lodash';
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
const AttachmentListModel = (props: any) => {

    const { filters, selectFiles } = props;
    const [template, setTemplate] = useState<any>('');
    const { t } = useTranslation();
    const toastId = useRef(null) as any;
    const loadAttachmentOptions = async (keyword: string) => {
        const filter = {
            search: keyword,
            extra: filters,
        };
        const attachments = await EmailService.searchAttachment(filter) as any;
        if (attachments?.data?.status === 'error') {
            toastId.current = toast(attachments?.data?.message || 'Error', {
                type: toast.TYPE.WARNING,
                isLoading: false,
                autoClose: 4000,
            });
            return [];
        }
        if (attachments?.data?.status === 'success') {
            return attachments?.data?.data?.filter((item: any) => item.type === 'file' && item) || [];
        }
    };

    const loadAttachmentOption = useCallback(
        debounce((inputValue, callback) => {
            loadAttachmentOptions(inputValue).then(options => callback(options))
        }, 500), []);

    const handleOnChange = (value: any) => {
        setTemplate(value);
    };

    const handleSelectTemplate = () => {
        selectFiles(template);
        props.onCloseClick();
    }

    return (
        <Modal
            size="lg"
            isOpen={props.show}
            toggle={props.onCloseClick}
            backdrop="static"
            keyboard={false}
            autoFocus={false}>
            <div className="modal-header">
                <h5 className="modal-title">{props.title}</h5>
                <button
                    type="button"
                    className="btn-close"
                    onClick={props.onCloseClick}
                    aria-label="Close"></button>
            </div>
            <ModalBody>
                <Card>
                    <CardBody>
                        <div className="mb-3">
                            <AsyncSelect
                                cacheOptions
                                loadOptions={loadAttachmentOption}
                                defaultOptions
                                placeholder={t("Search")}
                                isClearable={false}
                                isMulti
                                components={{
                                    DropdownIndicator: () => null,
                                    IndicatorSeparator: () => null,
                                }}
                                onChange={handleOnChange}
                            />
                        </div>
                        {/* <object data="your_url_to_pdf" type="application/pdf">
                            <div>No online PDF viewer installed</div>
                        </object> */}
                    </CardBody>
                    <CardFooter className="d-sm-flex flex-wrap bg-transparent border-top">
                        <div className="ms-auto d-flex gap-2 justify-content-end">
                            <Button
                                color="primary"
                                className="btn-block"
                                type="button"
                                onClick={() => handleSelectTemplate()}
                            >
                                <span>{t('Attach')}</span>
                            </Button>
                        </div>
                    </CardFooter>
                </Card>
            </ModalBody>
        </Modal>
    )
}

export default AttachmentListModel;