import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class CompanyGeneralService {

    async getCompanyGeneral(DATA: any) {
        const response = await axios
            .get(SERVER_URL + url.COMPANY_GENERAL.GET + "/" + DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }    

    async updateCompanyGeneral(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.COMPANY_GENERAL.UPDATE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }
    
}

export default new CompanyGeneralService();