import {
  GET_USER_STATUS,
  GET_USER_STATUS_SUCCESS,
  GET_USER_STATUS_ERROR,

  GET_USER_STATUS_LIST,
  GET_USER_STATUS_LIST_SUCCESS,
  GET_USER_STATUS_LIST_ERROR,

  CREATE_USER_STATUS,
  CREATE_USER_STATUS_SUCCESS,
  CREATE_USER_STATUS_ERROR,

  UPDATE_USER_STATUS,
  UPDATE_USER_STATUS_SUCCESS,
  UPDATE_USER_STATUS_ERROR,

  DELETE_USER_STATUS,
  DELETE_USER_STATUS_SUCCESS,
  DELETE_USER_STATUS_ERROR,
} from "./actionTypes";

// ---------------------------------------------GET_USER_STATUS
export const getUserStatus = (userstatus: any, history: any) => {
  return {
    type: GET_USER_STATUS,
    payload: { userstatus, history },
  };
};

export const getUserStatusSuccess = (data: {}) => {
  return {
    type: GET_USER_STATUS_SUCCESS,
    payload: data,
  };
};

export const getUserStatusError = (error: {}) => {
  return {
    type: GET_USER_STATUS_ERROR,
    payload: error,
  };
};

// ---------------------------------------------GET_USER_STATUS_LIST
export const getUserStatusList = (query: any, history: any) => {
  return {
    type: GET_USER_STATUS_LIST,
    payload: {query, history},
  };
};

export const getUserStatusListSuccess = (data: {}) => {
  return {
    type: GET_USER_STATUS_LIST_SUCCESS,
    payload: data,
  };
};

export const getUserStatusListError = (error: {}) => {
  return {
    type: GET_USER_STATUS_LIST_ERROR,
    payload: error,
  };
};

// ---------------------------------------------CREATE_USER_STATUS
export const createUserStatus = (userstatus: any, history: any) => {
  return {
    type: CREATE_USER_STATUS,
    payload: { userstatus, history },
  };
};

export const createUserStatusSuccess = (userstatus: {}) => {
  return {
    type: CREATE_USER_STATUS_SUCCESS,
    payload: userstatus,
  };
};

export const createUserStatusError = (error: {}) => {
  return {
    type: CREATE_USER_STATUS_ERROR,
    payload: error,
  };
};

// ---------------------------------------------UPDATE_USER_STATUS
export const updateUserStatus = (userstatus: any, history: any) => {
  return {
    type: UPDATE_USER_STATUS,
    payload: { userstatus, history },
  };
};

export const updateUserStatusSuccess = (userstatus: {}) => {
  return {
    type: UPDATE_USER_STATUS_SUCCESS,
    payload: userstatus,
  };
};

export const updateUserStatusError = (error: {}) => {
  return {
    type: UPDATE_USER_STATUS_ERROR,
    payload: error,
  };
};

// ---------------------------------------------DELETE_USER_STATUS
export const deleteUserStatus = (userstatus: any, history: any) => {
  return {
    type: DELETE_USER_STATUS,
    payload: { userstatus, history },
  };
};

export const deleteUserStatusSuccess = (userstatus: {}) => {
  return {
    type: DELETE_USER_STATUS_SUCCESS,
    payload: userstatus,
  };
};

export const deleteUserStatusError = (error: {}) => {
  return {
    type: DELETE_USER_STATUS_ERROR,
    payload: error,
  };
};
