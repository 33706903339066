import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'

const ExportModel = (props: any) => {
    const {t} = useTranslation();
    return (
        <React.Fragment>
            <Modal
                isOpen={props.show}
                toggle={props.onCloseClick}
                backdrop="static"
                keyboard={false}
                autoFocus={false}
                centered
            >
                <div className="modal-header">
                    {t('EXPORT ORDER')}
                    <button
                        type="button"
                        className="btn-close"
                        onClick={props.onCloseClick}
                        aria-label="Close"
                    ></button>
                </div>
                <ModalBody>
                    {t("By exporting,tab and also question under questionnaire type will export too. Are you sure?")}
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={props.onYesClick}
                    >
                        {t("Yes")}
                    </Button>
                    {' '}
                    <Button onClick={props.onNoClick}>
                        {t("No")}
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )
}

export default ExportModel