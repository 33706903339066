import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Preloader from "./Preloader";
import Loader from "./Loader";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

// import { useIsAuthenticated, useMsal } from "@azure/msal-react";
// import { loginRequest } from "../../authConfig";
// import {
//   InteractionRequiredAuthError,
//   InteractionStatus,
// } from "@azure/msal-browser";

// Actions
import { UsersAction, CommonAction } from "../../store/actions";
import NewEmail from "../../pages/Email/components/NewEmail";
import WindowPortal from "../Common/WindowPortal";
import { useTranslation } from "react-i18next";
import { getUserProfile, moduleAccess } from "../../helpers/functions_helper";
import NotFound from "../../pages/NotFound/Page404";

const Layout = (props: any) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  // const { instance, accounts, inProgress } = useMsal();
  // const isAzureAuthenticated = useIsAuthenticated();

  const [isLoading, setIsLoading] = useState(true);
  const [loader, setLoader] = useState(false);
  const { path, slug = "" } = props;

  useEffect(() => {
    window.scrollTo(0, 0);

    if (document.readyState === "complete") {
      hideLoader();
    } else {
      window.addEventListener("load", hideLoader);
      return () => document.removeEventListener("load", hideLoader);
    }
  }, []);

  const showLoader = () => {
    setIsLoading(true);
  };
  const hideLoader = () => {
    setIsLoading(false);
  };

  const forceLogout = () => {
    window.localStorage.clear();
    return navigate("/login", { state: { from: location } });
  };

  //----------------------------------------------------USER_PROFILE

  let {
    getUserProfileSuccess,
    getUserProfileError,

    getCompanyOptionsList,
    getUserOptionsList,
    getFeesOptionsList,

    getCompanyOptionsList_New,
    getUserOptionsList_New,
    getFeesOptionsList_New,

    draftEmail,
  } = useSelector((state: any) => ({
    getUserProfileSuccess: state.Users.GET_USER_PROFILE_SUCCESS,
    getUserProfileError: state.Users.GET_USER_PROFILE_ERROR,

    getCompanyOptionsList: state.Common.GET_COMPANY_OPTIONS_LIST_SUCCESS,
    getUserOptionsList: state.Common.GET_USER_OPTIONS_LIST_SUCCESS,
    getFeesOptionsList: state.Common.GET_FEES_OPTIONS_LIST_SUCCESS,

    getCompanyOptionsList_New: state.Common.GET_COMPANY_OPTIONS_LIST_SUCCESS_NEW,
    getUserOptionsList_New: state.Common.GET_USER_OPTIONS_LIST_SUCCESS_NEW,
    getFeesOptionsList_New: state.Common.GET_FEES_OPTIONS_LIST_SUCCESS_NEW,

    draftEmail: state.Mails.draft,
  }));

  useEffect(() => {
    let bodyElement = document.getElementsByTagName("body")[0];
    bodyElement.setAttribute("id", "main-app-page");
  }, []);

  useEffect(() => {
    if (getUserProfileSuccess) {
      if (!getCompanyOptionsList) dispatch(CommonAction.getCompanyOptionsList());
      if (!getUserOptionsList) dispatch(CommonAction.getUserOptionsList());
      if (!getFeesOptionsList) dispatch(CommonAction.getFeesOptionsList());

      if (!getCompanyOptionsList_New) dispatch(CommonAction.getCompanyOptionsList_New());
      if (!getUserOptionsList_New) dispatch(CommonAction.getUserOptionsList_New());
      if (!getFeesOptionsList_New) dispatch(CommonAction.getFeesOptionsList_New());

      const AUTH_USER = localStorage.getItem("authUser");
      if (AUTH_USER) {
        const LAST_LOGIN = JSON.parse(AUTH_USER);
        if (LAST_LOGIN?.lastLogin?.length > 0 && LAST_LOGIN.lastLogin[0].is_shown !== true) {
          // const AGENT = LAST_LOGIN.lastLogin[0].agent;
          const IP_ADDRESS = LAST_LOGIN.lastLogin[0].ip_address;
          const CREATED_AT = LAST_LOGIN.lastLogin[0].created_at;

          toast(
            `${t("Signed in successfully. Last sign in from")}: ${IP_ADDRESS} ${t(
              "at"
            )}: ${CREATED_AT}`,
            {
              type: toast.TYPE.SUCCESS,
              autoClose: 5000,
              delay: 500,
            }
          );
          LAST_LOGIN.lastLogin[0].is_shown = true;
          localStorage.setItem("authUser", JSON.stringify(LAST_LOGIN));
        }
      }

      setLoader(false);
    } else {
      dispatch(UsersAction.getUserProfile());
      setLoader(true);
    }
  }, [getUserProfileSuccess]);

  useEffect(() => {
    if (getUserProfileError) {
      setLoader(false);
    }
  }, [getUserProfileError]);
  //----------------------------------------------------USER_PROFILE

  // useEffect(() => {
  //   if (
  //     localStorage.getItem("accessTokenSSO") !== null &&
  //     isAzureAuthenticated === false &&
  //     inProgress === InteractionStatus.None
  //   ) {
  //     toast("Azure AD Access Token has expired", {
  //       type: toast.TYPE.ERROR,
  //       autoClose: 5000,
  //       delay: 500,
  //     });

  //     setTimeout(() => {
  //       forceLogout();
  //     }, 2000);
  //   }
  // }, [
  //   localStorage.getItem("accessTokenSSO"),
  //   isAzureAuthenticated,
  //   inProgress,
  // ]);

  return (
    <React.Fragment>
      {isLoading && <Preloader />}
      {loader && <Loader />}

      {getUserProfileSuccess ? (
        moduleAccess(slug) ? (
          <div id="layout-wrapper">
            <Header slug={slug} />
            <Sidebar />

            <div className="main-content">
              <Outlet />
            </div>
            {draftEmail.openMailBox === 1 || draftEmail.openMailBox === 2 ? (
              <NewEmail data={draftEmail} />
            ) : null}
            {draftEmail.openMailBox === 3 ? (
              <WindowPortal>
                <NewEmail data={draftEmail} />
              </WindowPortal>
            ) : null}
            {path === "/tasks/kanban-view" ? null : <Footer />}
          </div>
        ) : (
          <NotFound />
        )
      ) : (
        getUserProfileError && forceLogout()
      )}
    </React.Fragment>
  );
};

interface Layout {
  isLoading: boolean;
  loader: boolean;
}

export default Layout;
