import React from 'react'
import { Col, Label } from 'reactstrap';
import { renderRowHTML } from '../../utils/utils';

const RadioInput = (props: any) => {
    let { label, name, value, answer_only, disabled, handleChangeMulti, handleKeyDown, handleChange, tabnotnext, isDisabled, field_width_input, title, space_input, space_input_value } = props;
    return (
        <div className={answer_only ? "checkbox_answer mt-3 d-flex flex-row" : "mt-3"}>
            {!!label &&
                <Label for={name} className={answer_only ? "pointer questionview" : "questionview mt-3"}>
                    {label}
                    {!!title && (
                        <span className='tooltip-txt'>
                            < i className="fa-regular fas fa-info-circle"></i><span className="tooltiptext">{title}</span>
                        </span>
                    )}
                </Label>
            }
            {answer_only ?
                <Label for="basicpill-phoneno-input3" className='answer_only'>
                    {value == 1 &&
                        <label className="container_radio version_2">Yes
                            <input type="radio" name={name} className="required"
                                checked disabled={isDisabled} />
                            <span className="checkmark"></span>
                        </label>
                    }
                    {value == 0 &&
                        <label className="container_radio version_2">No
                            <input type="radio" name={name} className="required"
                                checked disabled={isDisabled} />
                            <span className="checkmark"></span>
                        </label>
                    }
                </Label>
                : null}
            {!answer_only &&
                <Label for="basicpill-phoneno-input3" className={isDisabled? 'disabled-radio':''}>
                    <div className={(space_input && space_input_value) ? `${space_input}-${space_input_value} form-group` : 'form-group'} onKeyDown={handleKeyDown}>

                        <label className={field_width_input ? `w-${field_width_input} container_radio version_2` : 'container_radio version_2'}>Yes
                            <input type="radio" name={name} value={1} className="required"
                                checked={
                                    value && value == '1'
                                        ? true
                                        : false
                                }
                                onChange={tabnotnext ? handleChange : handleChangeMulti}
                                disabled={isDisabled} />
                            <span className="checkmark"></span>
                        </label>
                        <label className={field_width_input ? `w-${field_width_input} container_radio version_2` : 'container_radio version_2'}>No
                            <input type="radio" name={name} value={0} className="required"
                                checked={
                                    value && value == '0'
                                        ? true
                                        : false
                                }
                                onChange={handleChangeMulti}
                                disabled={isDisabled} />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                </Label>
            }
        </div>
    )
}

export default RadioInput