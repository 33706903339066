import {
  GET_INVOICES_LIST,
  GET_INVOICES_LIST_SUCCESS,
} from "./actionTypes"

const initialState = {
  INVOICES_LIST: [],
  loading: false,
}

const reducer = (state = initialState, action: any) => {
  switch (action.type) {

    // ---------------------------------------------GET_INVOICES_LIST
    case GET_INVOICES_LIST:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_INVOICES_LIST_SUCCESS:
      state = {
        ...state,
        INVOICES_LIST: action.payload,
        loading: false
      }
      break

    // ---------------------------------------------DEFAULT
    default:
      state = { ...state }
      break
  }
  return state
}

export default reducer
