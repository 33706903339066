import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import { toast } from "react-toastify";
// Import Breadcrumb
import Breadcrumbs from "../../components/Layout/Breadcrumb";
// Import DataTable
import DataTable from "../../components/Common/DataTable";
import { getDataTableQuery, getUserProfile } from "../../helpers/functions_helper";
import broadcastService from "../../services/broadcast.service";
import BroadcastFormModal from "./BroadcastFormModal";
import { useTranslation } from "react-i18next";



const AllBroadcast = (props: any) => {
  const {t}=useTranslation();
  document.title = `${t("Broadcast")} | ${process.env.REACT_APP_NAME}`;
  const userProfile = getUserProfile();
  const toastId = React.useRef(null) as any;
  const [show,setShow] =useState<boolean>(false);
  const [broadcastData,setBroadcastData]=useState([]);
  const [isEditMode,setIsEditMode]=useState<boolean>(false);

  const editBroadcast =(data:any)=>{
    setBroadcastData(data);
    setIsEditMode(true);
    setShow(true);
  }

  const Enviroment_Master = [
    {
      label: t('Test'),
      value: '1'
    },
    {
      label: t('Acceptance'),
      value: '2'
    },
    {
      label: t('Production'),
      value: '3'
    },
  ];
  
   const Severity = [
    {
      label: t('Highest'),
      value: '1',
    },
    {
      label: t('Medium'),
      value: '2',
    },
    {
      label: t('Low'),
      value: '3',
    },
  ];

  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "#",
      label: t("Broadcast"),
      active: true,
    },
  ];

  const client_columns = [
    {
      dataField: "title",
      text: t("Broadcast"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return row.is_deleted === true ? (
          <>
            {row.notification}
            {row.action !== "DELETE" && (
              <div>
                <span className="badge rounded-pill badge-soft-danger">
                  Record deleted
                </span>
              </div>
            )}
          </>
        ) : (
          <>
            <Link
              className="text-decoration-underline mb-1"
              to={`/broadcast/view/${row?.id}/${row?.broadcast_id}`}
              onClick={() => setViewed(row?.id)}
            >
              {row.title}
            </Link>
            {(row?.is_read === false || row?.is_read===null)   && (
              <div>
                <span className="badge rounded-pill badge-soft-primary me-2">
                  <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">mark_email_unread</span>
                  {t("Unread")}
                </span>
                <span className="badge rounded-pill bg-primary btn-outline-primary me-2" onClick={() => setReadUnread(row, true)}>
                  <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">mark_email_read</span>
                  {t("Mark as Read")}
                </span>
              </div>
            )}
          </>
        );
      },
    },
    // {
    //   dataField: "description",
    //   text: t("Custom message"),
    //   sort: true,
    //   search: true,
    // },
    {
      dataField: "time_elapsed",
      text: t("Time elapsed"),
      sort: true,
      search: true,
    },
  ];


  const columns = [
    {
      dataField: "DT_RowIndex",
      text: t("Sr. No."),
      sort: false,
      search: false,
    },
    {
      dataField: "client",
      text: t("Client"),
      sort: true,
      search: true,
    },
    {
      dataField: "project",
      text: t("Project"),
      sort: true,
      search: true,
    },
    {
      dataField: "title",
      text: t("Title"),
      sort: true,
      formatter: (rowContent: any, row: any): any => {
        return row.is_deleted === true ? (
          <>
            {row.notification}
            {row.action !== "DELETE" && (
              <div>
                <span className="badge rounded-pill badge-soft-danger">
                  Record deleted
                </span>
              </div>
            )}
          </>
        ) : (
          <>
            <Link
              className="text-decoration-underline mb-1"
              to={`/broadcast/view/${row?.id}/${row?.broadcast_id}`}
              onClick={() => setViewed(row?.id)}
            >
              {row.title}
            </Link>
            {(row?.is_read === false || row?.is_read===null)   && (
              <div>
                <span className="badge rounded-pill badge-soft-primary me-2">
                  <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">mark_email_unread</span>
                  {t("Unread")}
                </span>
                <span className="badge rounded-pill bg-primary btn-outline-primary me-2" onClick={() => setReadUnread(row, true)}>
                  <span className="align-middle material-symbols-outlined fw-light fs-4 me-1">mark_email_read</span>
                  {t("Mark as Read")}
                </span>
              </div>
            )}
          </>
        );
      },
    },
    // {
    //   dataField: "description",
    //   text: t("Description"),
    //   sort: true,
    //   search: true,
    // },
    {
      dataField: "enviorment",
      text: t("Enviorment"),
      sort: true,
      search: true,
    },
    {
      dataField: "start_date",
      text: t("Start Date"),
      sort: true,
      search: true,
    },
    {
      dataField: "end_date",
      text: t("End Date"),
      sort: true,
      search: true,
    },
    {
      dataField: "application_version",
      text: t("App Ver."),
      sort: true,
      search: true,
    },
    {
      dataField: "severity",
      text: t("Severity"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return t(row?.severity);
      },
    },
    {
      dataField: "email",
      text: t("Email"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return (
          <div className="gap-2 text-start">
            {row.email_id && row?.email_id?.map((item:any)=><><span className="font-size-11 badge-soft-success badge bg-secondary" key={`${row.id}_${item?.id}`}>{item?.value}</span><br /></>)}
          </div>
        );
      },
    },
    {
      dataField: "action",
      text: t("Action"),
      sort: false,
      search: false,
      formatter: (rowContent: any, row: any): any => {
        return (
          userProfile.IS_INTERNAL && userProfile.IS_ADMIN === 1 ?
         <div className="d-flex gap-2 text-end">
            <Link
              className="btn btn-pill btn-outline-primary btn-sm"
              to={"#"}
              onClick={() => editBroadcast(row)}
            >
              <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                edit
              </span>
              {t("Edit")}
            </Link>
          </div>:null
        );
      },
      footer: "",
    },
   
  ];

  const [loadingListData, setLoadingListData] = useState(true);
  const [datatableData, setDatatableData] = useState<any>([]);
  const [datatableOptions, setDatatableOptions] = useState({
    draw: 1,
    page: 1,
    recordsTotal: 0,
    recordsFiltered: 0,
    sizePerPage: 20,
    searchText: "",
    sortField: "id",
    sortOrder: "desc",
    type:2
  });

  const loadingDatatable = async () => {
    try {
      const res = (await broadcastService.getAll(
        getDataTableQuery(datatableOptions, columns),2
      )) as any;

      if (res.data.status === "success") {
        setDatatableData(res.data.data);
        setDatatableOptions((preState:any)=>({
          ...preState,
          recordsTotal: res.data.recordsTotal,
          recordsFiltered: res.data.recordsFiltered,
          type:2
        }))
        setLoadingListData(false);
      } else {
        setDatatableData([]);
        setLoadingListData(false);
      }
    } catch (err) {
      setDatatableData([]);
      setLoadingListData(false);
    }
  };

  const onTableChange = (type: any, newState: any) => {
    let draw = datatableOptions.draw + 1;
    let page = 1;
    if (type == "search") {
      page = 1;
    } else {
      page = newState.page;
    }
    setDatatableOptions((preState:any)=>({
      ...preState,
      draw: draw,
      page: page,
      sizePerPage: newState.sizePerPage,
      searchText: newState.searchText,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
      type:2
    }));
    setLoadingListData(true);
  };

  const clearFilter = () => {
    setDatatableOptions((preState:any)=>({
      ...preState,
      draw: 1,
      page: 1,
      sizePerPage: 20,
      searchText: "",
      sortField: "id",
      sortOrder: "desc",
      type:2
    }));
    setLoadingListData(true);
  };


  const setViewed =async (broadcast_id:number) => {
      const res = await broadcastService.setBroadcastReadUnread({broadcast_id,is_read:true});
  }

  const setReadUnread = async (data: any, is_read: boolean) => {
    toastId.current = toast(t("Updating notification"), {
      autoClose: false,
      isLoading: true,
    });

    try {
      const res = (await broadcastService.setBroadcastReadUnread({ broadcast_id: data.id, is_read: is_read })) as any;
      if (res.status === "success") {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res?.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoadingListData(true);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        setLoadingListData(true);
      }
    } catch (err) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: "Something went wrong, please try again",
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });

      setLoadingListData(true);
    }
  }

  useEffect(() => {
    if (loadingListData === true) {
      loadingDatatable();
    }
  }, [loadingListData]);


  const onClickModal =()=>{
    setIsEditMode(false);
    setBroadcastData([])
    setShow(true);
  }

  const onCloseModal =()=>{
    loadingDatatable();
    setShow(false)
  };

  useEffect(() => {
    setDatatableData([]);
    setLoadingListData(true);
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={t("Broadcast")}
            breadcrumbItems={breadcrumbItems}
            endAdornment={
              userProfile.IS_INTERNAL && userProfile.IS_ADMIN === 1 ? (
                <Link
                  to="#"
                  className="btn btn-primary btn-sm waves-effect waves-light btn-label"
                  onClick={() => onClickModal()}>
                  <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">add</span>{t("Create broadcast")}
                </Link>
              ) : null
            }
          />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <DataTable
                    columns={ (userProfile.IS_INTERNAL && userProfile.IS_ADMIN === 1) ? columns : client_columns}
                    products={datatableData}
                    datatableOptions={datatableOptions}
                    filters={[]}
                    onTableChange={onTableChange}
                    loadingListData={loadingListData}
                    clearFilter={clearFilter}
                    slug="broadcast"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <BroadcastFormModal 
        show={show}
        onCloseClick={onCloseModal}
        editdata={broadcastData}
        isEditMode={isEditMode}
      />
    </React.Fragment>
  );
};

export default AllBroadcast;
