import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
} from "reactstrap";

// Import Breadcrumb
import Breadcrumbs from "../../../components/Layout/Breadcrumb";

// Import DeleteModal
import Form from "./Form";

// Import DeleteModal
import DeleteModal from "../../../components/Common/DeleteModal";

// Import DataTable
import DataTable from "../../../components/Common/DataTable";

import { calenderTranslate, getUserProfile } from "../../../helpers/functions_helper";
import service from "../../../services/master.bank.holiday.service";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import BootstrapTheme from "@fullcalendar/bootstrap";
import interactionPlugin from '@fullcalendar/interaction';
import moment from "moment";
import { useTranslation } from "react-i18next";


const Calender = () => {
    const { t } = useTranslation();
    document.title = `${t("Bank holiday")} | ` + process.env.REACT_APP_NAME;
    const breadcrumbItems = [
        {
            url: "/dashboard",
            label: t("Dashboard"),
            active: false,
        },
        {
            url: "#",
            label: t("Bank holiday"),
            active: true,
        },
    ];
    const [loader, setLoader] = useState(true);
    const dispatch = useDispatch();

    const useObj = getUserProfile();

    const [loadingListData, setLoadingListData] = useState(false);
    const [holidayList, setHolidayList] = useState([]);
    const [events, setEvents] = useState([]);
    const [startDate, setStartDate] = useState(new Date());

    const getList = async () => {
        const res = await service.getCalenderList();
        if (res.status === 'success') {
            setHolidayList(res.data);
            setLoadingListData(false);
            setLoader(false);
        }
    }

    useEffect(() => {
        getList();
    }, [])


    useEffect(() => {
        if (holidayList.length > 0) {
            const evnt_ar: any = [];
            holidayList.forEach((element: any) => {
                evnt_ar.push({ 'id': element.id, 'country': element.country, 'title': element.holiday_name, 'start': moment(element.holiday_date).format('YYYY-MM-DD') })
            });
            setEvents(evnt_ar);
        }
    }, [holidayList])



    // Delete Form --------------------------------------------------------
    const [deleteRec, setDeleteRec] = useState("");
    const [recordName, setRecordName] = useState("");
    const [deleteModal, setDeleteModal] = useState(false);
    const [holidayData, setHolidayData] = useState<any>({});

    const [masterFormModal, setMasterFormModal] = useState(false);
    const [masterFormModalTitle, setMasterFormModalTitle] = useState<any>(t("Create bank holiday"));


    const handleDelete = async () => {
        const res = await service.delete(deleteRec);
        if (res.status === 'success') {
            toast(res.message, { type: toast.TYPE.SUCCESS })
            setHolidayData([]);
            getList();
        }
        setDeleteModal(false)
    };
    // Delete Form --------------------------------------------------------

    // Master Form -------------------------------------------------------- 


    const onClickModal = () => {
        setMasterFormModalTitle(t("Create bank holiday"));
        setMasterFormModal(true);
        setHolidayData([]);
    };
    const handleSubmit = async (values: any) => {
        try {
            if (values?.id && values?.country) {
                const res = await service.update(values);
                if (res.status === 'success') {
                    toast(res.message, { type: toast.TYPE.SUCCESS, onClose: () => setMasterFormModal(false) })
                    getList();
                    setMasterFormModal(false);
                }
            } else {
                const res = await service.create(values);
                if (res.status === 'success') {
                    toast(res.message, { type: toast.TYPE.SUCCESS, onClose: () => setMasterFormModal(false) })
                    getList();
                    setMasterFormModal(false);
                }
            }

        } catch (err: any) {
            toast('Something went wrong', { type: toast.TYPE.ERROR })
        }
    };

    const handleDateClick = (arg: any) => {
        setHolidayData([]);
        const date = moment(arg["date"]).format('YYYY-MM-DD');
        const year = parseInt(moment(arg["date"]).format('YYYY'));
        setHolidayData({ ...holidayData, 'holiday_date': date, 'year': year });
        setMasterFormModal(true);
    };


    const [enableDelete, setEnableDelete] = useState(false);
    const handleEventClick = (arg: any) => {
        const title = arg.event.title;
        const id = arg.event.id;
        const country = arg.event.extendedProps.country;
        const date = moment(arg.event.start).format('YYYY-MM-DD');
        const year = parseInt(moment(arg["date"]).format('YYYY'));
        setHolidayData({ ...holidayData, 'id': id, 'country': country, 'title': title, 'holiday_date': date, 'year': year, 'holiday_name': title });
        if (id) {
            setEnableDelete(true);
        } else {
            setEnableDelete(false);
        }
        setMasterFormModal(true);
    };

    const onClickDelete = (row: any) => {
        if (row?.id) {
            setMasterFormModal(false);
            setDeleteRec(row.id);
            setRecordName(row.holiday_name);
            setDeleteModal(true);
        }
    };



    return (
        <React.Fragment>

            {deleteModal && (
                <DeleteModal
                    recordName={recordName}
                    modalTitle={t("Bank holiday")}
                    show={deleteModal}
                    onDeleteClick={handleDelete}
                    onCloseClick={() => setDeleteModal(false)}
                    loading={false}
                />
            )}

            {(masterFormModal && holidayData) && (
                <Form
                    modalTitle={masterFormModalTitle}
                    show={masterFormModal}
                    loading={loader}
                    holidayData={holidayData}
                    onSubmitClick={handleSubmit}
                    onCloseClick={() => setMasterFormModal(false)}
                    errorMsg={''}
                    errorData={''}
                    enableDelete={enableDelete}
                    handleClickDelete={onClickDelete}
                />
            )}

            <div className={"page-content"}>
                <Container fluid>
                    <Breadcrumbs
                        breadcrumbTitle={t("Bank Holiday")}
                        breadcrumbItems={breadcrumbItems}
                        endAdornment={
                            <div className="d-flex gap-2 text-end">
                                {useObj?.USER_COMPANY === 1 &&
                                    <>
                                        <Link
                                            to="/masters/bank-holiday"
                                            className={`btn btn-outline-secondary btn-label btn-sm`}
                                        >
                                            <span className="material-symbols-outlined fw-light fs-4 label-icon">list</span> {t("List view")}
                                        </Link>
                                        <Link
                                            to="/masters/bank-holiday/calender"
                                            className={`btn btn-info btn-label btn-sm`}
                                        >
                                            <span className="material-symbols-outlined fw-light fs-4 label-icon">calendar_month</span> {t("Calender view")}
                                        </Link>
                                        <Link
                                            to="#"
                                            className="btn btn-outline-secondary btn-label btn-sm"
                                            onClick={() => onClickModal()}
                                        >
                                            <span className="material-symbols-outlined fw-light fs-4 label-icon">add</span> {t("Create bank holiday")}
                                        </Link>
                                    </>
                                }
                            </div>
                        }
                    />

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <FullCalendar
                                        plugins={[
                                            BootstrapTheme,
                                            dayGridPlugin,
                                            interactionPlugin,
                                        ]}
                                        locale={calenderTranslate()}
                                        handleWindowResize={true}
                                        themeSystem="bootstrap"
                                        headerToolbar={{
                                            left: "prev,next today",
                                            center: "title",
                                            right: "dayGridMonth,dayGridWeek,dayGridDay",
                                        }}
                                        //defaultView="listYear"
                                        events={events}
                                        editable={true}
                                        droppable={true}
                                        selectable={true}
                                        dateClick={handleDateClick}
                                        eventClick={handleEventClick}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Calender;
