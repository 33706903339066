import React, { useEffect, useState, useMemo, memo } from 'react';
import { Col, Button, Row, Label, Input, Table } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import moment from 'moment';
import Autocomplete from "react-google-autocomplete";
import { useTranslation } from 'react-i18next';

type BeneficiaryProps = {
    status?: number;
}

type beneficiaryProps = {
    name: string
    first_name: string
    address: string
    dob: string
    control_25: number | null
    decision_fn: number | null
}

type beneficiaryPropArray = {
    data?: Array<beneficiaryProps>;
    onChangeUpdate: (data: any) => void;
}

const Beneficiary = (props: beneficiaryPropArray) => {
    const {t} = useTranslation();
    const YesNoMaster = [
        {
            label: t('Yes'),
            value: "1",
        },
        {
            label: t('No'),
            value: "2",
        },
    ]
    const data = useMemo(() => props.data || [], [props.data]);
    //console.log("data",data);
    const [beneficiaryUser, setBeneficiaryUser] = useState<any>([]);
    const [intialData, setIntialData] = useState<beneficiaryProps>({
        name: "",
        first_name: "",
        address: "",
        dob: "",
        control_25: null,
        decision_fn: null,
    });

    const handleChange = (fieldName: any, fieldValue: any) => {
        setIntialData((preState => ({ ...preState, [fieldName]: fieldValue })));
    }

    const addBeneficiary = () => {
        setBeneficiaryUser((preState: any) => [...preState, intialData]);
        setEnableAdd(true);
        setIntialData({
            name: "",
            first_name: "",
            address: "",
            dob: "",
            control_25: null,
            decision_fn: null,
        })
    }

    const removePerson = (index: number) => {
        const beneAr = beneficiaryUser.filter((dt: any, key: any) => key != index);
        setBeneficiaryUser(beneAr);
    }

    const [enableAdd, setEnableAdd] = useState(true);
    useEffect(() => {
        if (intialData.name != "" && intialData.first_name != "" && intialData.address != "" && intialData.dob != "" && intialData.control_25 != null && intialData.decision_fn != null) {
            setEnableAdd(false);
        }

    }, [intialData]);


    useEffect(() => {
        props.onChangeUpdate(beneficiaryUser);
    }, [beneficiaryUser])

    useEffect(() => {
        if(data && data?.length>0){
            setBeneficiaryUser(data);
        }
    }, [data])


    return (
        <>
            <Row>
                <Col lg={12}>
                    <Label className="form-label">
                    {t("Beneficiaries of the company:")}
                    </Label>
                </Col>
            </Row>
            <Row>
                <Col xl={2} lg={2} md={2} sm={6}>
                    <div className="mb-3">
                        <Label className="form-label">
                        {t("Name")}
                        </Label>
                        <Input
                            id="name"
                            name="name"
                            placeholder={t("Name")}
                            type="text"
                            value={intialData.name}
                            onChange={(e) => handleChange("name", e.target.value)}
                        />
                    </div>
                </Col>

                <Col xl={2} lg={2} md={2} sm={6}>
                    <div className="mb-3">
                        <Label className="form-label">
                        {t("First Name")}
                        </Label>
                        <Input
                            id="first_name"
                            name="first_name"
                            placeholder={t("First Name")}
                            type="text"
                            value={intialData.first_name}
                            onChange={(e) => handleChange("first_name", e.target.value)}
                        />
                    </div>
                </Col>

                <Col xl={2} lg={2} md={2} sm={6}>
                    <div className="mb-3">
                        <Label className="form-label">
                        {t("Address")}
                        </Label>
                        {/* <Input
                            id="address"
                            name="address"
                            placeholder="Address"
                            type="text"
                            value={intialData.address}
                            onChange={(e) => handleChange("address", e.target.value)}
                        /> */}

                        <Autocomplete
                            id="address"
                            name="address"
                            className={"form-control"}
                            value={intialData.address}
                            placeholder={t("Address")}
                            onChange={(e: any) =>
                              handleChange("address", e.target.value)
                            }
                            apiKey={
                              process.env.REACT_APP_GOOGLE_ADDRESS_API_KEY!
                            }
                            options={{
                              types: ["geocode", "establishment"],
                            }}
                            onPlaceSelected={(data: any) => {
                                handleChange("address", data?.formatted_address);
                            }}
                          />
                    </div>
                </Col>


                <Col xl={2} lg={2} md={2} sm={12}>
                    <div className="mb-3">
                        <Label className="form-label">
                        {t("Date of birth")}
                        </Label>
                        <div className="input-group is-invalid">
                            <Flatpickr
                                id="date_of_birth"
                                name="date_of_birth"
                                className="form-control d-block"
                                type="text"
                                value={intialData.dob}
                                placeholder="DD/MM/YYYY"
                                options={{
                                    altInput: true,
                                    altFormat: "d/m/Y",
                                    dateFormat: "Y-m-d",
                                    maxDate: "today",
                                    allowInput: true,
                                    // plugins: [labelPlugin()],
                                    onChange: function (selectedDates, dateStr, instance) {
                                        handleChange("dob", dateStr);
                                    },
                                    onClose: function (selectedDates, dateStr, instance) {
                                        handleChange("dob", dateStr);
                                    },
                                    locale: { firstDayOfWeek: 1 }
                                }}
                            />
                            <span className="input-group-text">
                                <span className="align-middle material-symbols-outlined fw-light fs-4">calendar_today</span>
                            </span>
                        </div>
                    </div>
                </Col>

                <Col xl={3} lg={3} md={3} sm={6} className="d-flex">
                    <div className="mb-3">
                        <Label className="form-label">{t("Control")} &gt; 25%</Label>
                        <Select
                            id="control_25"
                            name="control_25"
                            className="is-invalid"
                            options={YesNoMaster}
                            placeholder="Y/N"
                            value={
                                intialData.control_25
                                    ? YesNoMaster.find(
                                        (option: any) => option.value == intialData.control_25
                                    )
                                    : ""
                            }
                            isSearchable={true}
                            onChange={(e: any) => {
                                handleChange("control_25", e.value);
                            }}
                        />
                        {/* <div className="form-check mb-2">
                            <Input
                                id="control_25"
                                name="control_25"
                                value="1"
                                className="form-check-input"
                                type="radio"
                                checked={intialData.control_25 == 1 ? true : false}
                                onChange={() => handleChange("control_25", "1")}
                            />
                            <label className="form-check-label" htmlFor="control_25">
                                Yes
                            </label>
                        </div>

                        <div className="form-check mb-2">
                            <Input
                                id="control_25"
                                name="control_25"
                                value="0"
                                className="form-check-input"
                                type="radio"
                                checked={intialData.control_25 == 0 ? true : false}
                                onChange={() => handleChange("control_25", "0")}
                            />
                            <label className="form-check-label" htmlFor="control_25">
                                No
                            </label>
                        </div> */}
                    </div>
                    <div className="mb-3 ms-2">
                        <Label className="form-label">{t("Decision maker function")}</Label>
                        <Select
                            id="gdpr_status_t"
                            name="gdpr_status_t"
                            className="is-invalid"
                            options={YesNoMaster}
                            placeholder="Y/N"
                            value={
                                intialData.decision_fn
                                    ? YesNoMaster.find(
                                        (option: any) => option.value == intialData.decision_fn
                                    )
                                    : ""
                            }
                            isSearchable={true}
                            onChange={(e: any) => {
                                handleChange("decision_fn", e.value);
                            }}
                        />
                        {/* <div className="form-check mb-2">
                            <Input
                                id="decision_fn_yes"
                                name="decision_fn"
                                value="1"
                                className="form-check-input"
                                type="radio"
                                checked={intialData.decision_fn == 1 ? true : false}
                                onChange={() => handleChange("decision_fn", "1")}
                            />
                            <label className="form-check-label" htmlFor="decision_fn_yes">
                                Yes
                            </label>
                        </div>

                        <div className="form-check mb-2">
                            <Input
                                id="decision_fn_no"
                                name="decision_fn"
                                value="0"
                                className="form-check-input"
                                type="radio"
                                checked={intialData.decision_fn == 0 ? true : false}
                                onChange={() => handleChange("decision_fn", "0")}
                            />
                            <label className="form-check-label" htmlFor="decision_fn_no">
                                No
                            </label>
                        </div> */}
                    </div>
                </Col>

                <Col xl={1} lg={1} md={1} sm={6}>
                    <div className="mb-3">
                        <Label className="form-label">{t("Action")}</Label>
                        <div>
                            <Button
                                className={`btn btn-sm ${enableAdd ? 'btn-primary' : 'btn-info'}`}
                                disabled={enableAdd}
                                onClick={() => addBeneficiary()}
                            >
                                <span className="align-middle material-symbols-outlined fw-light fs-4">add</span>
                            </Button>{" "}
                            <Button
                                className={`btn btn-primary btn-sm`}
                                onClick={() => console.log('more')}
                            >
                                <span className="align-middle material-symbols-outlined fw-light fs-4">download</span>
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>

            <hr />
            {data &&
                <Table
                >
                    <thead>
                        <tr>
                            <th>
                                #
                            </th>
                            <th>
                            {t("Name")}
                            </th>
                            <th>
                            {t("First Name")}
                            </th>
                            <th>
                            {t("Address")}
                            </th>
                            <th>
                            {t("Date of birth")}
                            </th>
                            <th>
                            {t("Control")} &gt; 25%
                            </th>
                            <th>
                            {t("Decision maker function")}
                            </th>
                            <th>
                            {t("Action")}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            beneficiaryUser.length>0 && Object.values(beneficiaryUser).map((item: any, i: number) => (
                                <>
                                    <tr key={`id_${i}`}>
                                        <th>{i + 1}</th>
                                        <th>{item.name}</th>
                                        <th>{item.first_name}</th>
                                        <th>{item.address}</th>
                                        <th>{moment(item.dob).format('DD/MM/YYYY')}</th>
                                        <th>{item.control_25 == 1 ? "Yes" : "No"}</th>
                                        <th>{item.decision_fn == 1 ? "Yes" : "No"}</th>
                                        <th>
                                            <Button color="danger" className={`btn btn-primary btn-sm`} onClick={() => removePerson(i)}>
                                                <span className="align-middle material-symbols-outlined fw-light fs-4">delete</span>
                                            </Button>
                                        </th>
                                    </tr>
                                </>
                            ))
                        }
                    </tbody>
                </Table>}
        </>
    )
}

export default memo(Beneficiary);
