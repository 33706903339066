import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Input, Label } from 'reactstrap';
const FileUpload = (props: any) => {
    const {t} = useTranslation();
    const { title, data, disabled, onClickHandler, onDownload, fileData } = props;
    return (
        <div>
            <div className="input-group">
                <Button color='primary' className='btn-label'
                    disabled={disabled}
                    onClick={onClickHandler}>
                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">upload</span>{t("Upload document")}
                </Button>
                
                <Button color='primary'
                    disabled={disabled}
                    onClick={onDownload}>
                    <span className="align-middle material-symbols-outlined fw-light fs-4">download</span>
                </Button>
            </div>
            
            {fileData && fileData?.length > 0 &&
                <a className='text-primary d-block mt-1 mb-1' onClick={onDownload}>{fileData[0].file_name + "." + fileData[0].file_extension}</a>
            }
        </div>
    )
}

export default memo(FileUpload);