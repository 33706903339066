import React from "react";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../../components/Layout/Breadcrumb";
import UserManualForm from "./UserManualForm";

import ScrollUp from "../../components/ScrollUpDown/ScrollUp";
import ScrollDown from "../../components/ScrollUpDown/ScrollDown";

const CreateUserManual = (props: any) => {
  const { t } = useTranslation();
  document.title = `${t("Create")} ${t("user manual")} | ` + process.env.REACT_APP_NAME;

  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "/user-manual/list/all",
      label: t("User manual"),
      active: false,
    },
    {
      url: "#",
      label: `${t("Create")} ${t("user manual")}`,
      active: true,
    },
  ];

  return (
    <React.Fragment>
      <ScrollUp />
      <ScrollDown top={'100px'} />

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={`${t("Create")} ${t("user manual")}`}
            breadcrumbItems={breadcrumbItems}
          />

          <UserManualForm />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateUserManual;
