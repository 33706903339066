import { 
  GET_TASK_STATUS,
  GET_TASK_STATUS_SUCCESS,
  GET_TASK_STATUS_ERROR,

  GET_TASK_STATUS_LIST,
  GET_TASK_STATUS_LIST_SUCCESS,
  GET_TASK_STATUS_LIST_ERROR,
  
  CREATE_TASK_STATUS,
  CREATE_TASK_STATUS_SUCCESS,
  CREATE_TASK_STATUS_ERROR, 

  UPDATE_TASK_STATUS,
  UPDATE_TASK_STATUS_SUCCESS,
  UPDATE_TASK_STATUS_ERROR,

  DELETE_TASK_STATUS,
  DELETE_TASK_STATUS_SUCCESS,
  DELETE_TASK_STATUS_ERROR,
} from "./actionTypes"
  
  const initialState = {
    GET_TASK_STATUS_SUCCESS: "",
    GET_TASK_STATUS_ERROR: "",
    GET_TASK_STATUS_LIST_SUCCESS: "",
    GET_TASK_STATUS_LIST_ERROR: "",
    CREATE_TASK_STATUS_SUCCESS: "",
    CREATE_TASK_STATUS_ERROR: "",
    UPDATE_TASK_STATUS: "",
    UPDATE_TASK_STATUS_SUCCESS: "",
    UPDATE_TASK_STATUS_ERROR: "",
    DELETE_TASK_STATUS: "",
    DELETE_TASK_STATUS_SUCCESS: "",
    DELETE_TASK_STATUS_ERROR: "",
    loading: false,
  }
  
  const reducer = (state = initialState, action: any) => {
    switch (action.type) {
      // ---------------------------------------------GET_TASK_STATUS
      case GET_TASK_STATUS:
        state = {
          ...state,
          loading: true,
        }
        break

      case GET_TASK_STATUS_SUCCESS:
        state = {
          ...state,
          GET_TASK_STATUS_SUCCESS: action.payload,
          loading: false,
        }
        break

      case GET_TASK_STATUS_ERROR:
        state = { 
          ...state, 
          GET_TASK_STATUS_ERROR: action.payload, 
          loading: false 
        }
        break

      // ---------------------------------------------GET_TASK_STATUS_LIST
      case GET_TASK_STATUS_LIST:
        state = {
          ...state,
          loading: true,
        }
        break

      case GET_TASK_STATUS_LIST_SUCCESS:
        state = {
          ...state,
          GET_TASK_STATUS_LIST_SUCCESS: action.payload,
          loading: false,
        }
        break

      case GET_TASK_STATUS_LIST_ERROR:
        state = { 
          ...state, 
          GET_TASK_STATUS_LIST_ERROR: action.payload, 
          loading: false 
        }
        break
        
      // ---------------------------------------------CREATE_TASK_STATUS
      case CREATE_TASK_STATUS:
        state = {
          ...state,
          loading: true,
        }
        break

      case CREATE_TASK_STATUS_SUCCESS:
        state = {
          ...state,
          CREATE_TASK_STATUS_SUCCESS: action.payload,
          loading: false,
        }
        break

      case CREATE_TASK_STATUS_ERROR:
        state = { 
          ...state, 
          CREATE_TASK_STATUS_ERROR: action.payload, 
          loading: false 
        }
        break

      // ---------------------------------------------UPDATE_TASK_STATUS
      case UPDATE_TASK_STATUS:
        state = {
          ...state,
          loading: true,
        }
        break

      case UPDATE_TASK_STATUS_SUCCESS:
        state = {
          ...state,
          UPDATE_TASK_STATUS_SUCCESS: action.payload,
          loading: false,
        }
        break

      case UPDATE_TASK_STATUS_ERROR:
        state = { 
          ...state, 
          UPDATE_TASK_STATUS_ERROR: action.payload, 
          loading: false 
        }
        break

      // ---------------------------------------------DELETE_TASK_STATUS
      case DELETE_TASK_STATUS:
        state = {
          ...state,
          loading: true,
        }
        break

      case DELETE_TASK_STATUS_SUCCESS:
        state = {
          ...state,
          DELETE_TASK_STATUS_SUCCESS: action.payload,
          loading: false,
        }
        break

      case DELETE_TASK_STATUS_ERROR:
        state = { 
          ...state, 
          DELETE_TASK_STATUS_ERROR: action.payload, 
          loading: false 
        }
        break

      // ---------------------------------------------DEFAULT
      default:
        state = { ...state }
        break
    }
    return state
  }
  
  export default reducer
  