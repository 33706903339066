const TermsConditions = (props: any) => {
  return (
    <>
      <h3>1.Portée</h3>
      <p>
        Les présentes conditions générales (les "<strong>Conditions Générales</strong>")
        s'appliquent à tous services fournis ou à fournir par ou au nom de Tax Consult HR Tax &amp;
        Mobilité internationale (ci-après &laquo; <strong>TC HR Tax &amp; IM</strong>&raquo;).
      </p>
      <p>
        En l'absence d'accord spécifique à ce sujet dans la lettre de mission, le contrat est conclu
        et prend effet :
      </p>
      <ul>
        <li>
          soit à la réception par le professionnel de la lettre de mission, signée par le client, et
          le contresigne à son tour ;
        </li>
        <li>
          ou lorsque le professionnel commence à exécuter la mission, à la demande du client,
          lorsque cette exécution commence plus tôt en raison de circonstances exceptionnelles ou
          urgentes.
        </li>
      </ul>
      <p>
        L'acceptation de la lettre de mission émise par TC HR Tax &amp; IM implique l'acceptation
        des Conditions Générales, y compris leurs évolutions futures, pour tout futur dossier traité
        par TC HR Tax &amp; JE SUIS.
      </p>
      <p>
        Lorsque le professionnel n'a pas encore reçu la lettre de mission signée par le client, tous
        les relations professionnelles entre les parties sont soumises aux présentes Conditions
        Générales &amp; conditions et la lettre de mission, sous réserve que ces documents
        contractuels aient été communiqué au client.
      </p>
      <p>
        Lorsque la lettre de mission comprend ou fait référence à des conditions particulières,
        celles-ci s'appliquent parallèlement aux Conditions Générales. Toutefois, en cas de conflit
        ou de incohérence entre les clauses des deux cahiers des charges, les spécificités
        conditions générales de vente prévalent sur les Conditions Générales de Vente.
      </p>
      <h3>2.Notre collaboration</h3>
      <h4>2.1.Conditions de collaboration</h4>
      <p>
        TC HR Tax & amp; IM, est une société belge immatriculée au registre belge des sociétés sous
        le numéro 0740.763.660 et est membre de l'Institut des Conseillers Fiscaux et Comptables
        (ITAA), enregistrée sous le numéro [50.752.723].
      </p>
      <h4>2.2.Secret professionnel &ndash; blanchiment d'argent &ndash; DAC6</h4>
      <p>
        TC HR Tax &amp ; IM, ainsi que ses directeurs, employés ou agents sont liés par secret
        professionnel, conformément à l'article 50 de la loi du 17 mars 2019 relative au les
        professions comptables et fiscales et l'article 32 de l'arrêté royal du 1er mars
        <sup>er</sup>, 1998 fixant les règles déontologiques des experts-comptables et les
        conseillers fiscaux, sous réserve toutefois de l'application des dispositions de la
        législation et la réglementation relative à la prévention de l'utilisation du système
        financier pour aux fins de blanchiment d'argent et de financement du terrorisme.
      </p>
      <p>
        Le client confirme avoir pris connaissance du fait que le professionnel est soumis à la
        législation relative à la prévention de l'utilisation du système financier à des fins de
        blanchiment de capitaux et de financement du terrorisme et s'engage à lui remettre, sans
        délai, toute information et/ou document requis en vertu de ladite législation.
      </p>
      <p>
        De plus, TC A&amp;A, ses directeurs, employés ou agents sont liés par la loi de 20 décembre
        2019, transposant la directive (UE) 2018/822 du Conseil du 25 mai 2018, modifiant la
        directive 2011/16/UE relative à l'échange automatique et obligatoire d'informations fiscales
        en concernant les accords transfrontaliers devant faire l'objet d'une déclaration (ci-après
        DAC 6).
      </p>
      <p>
        Cependant, conformément aux instructions de l'ITAA, TC HR Tax &amp; IM informe le client le
        secret professionnel l'empêche de faire toute déclaration DAC 6 ou de informer les
        intermédiaires concernés par un dispositif entrant dans le champ d'application du DAC 6.
      </p>
      <h4>2.3.TC Fiscalité RH &amp ; Étendue de la responsabilité professionnelle d'IM</h4>
      <p>
        Conformément au droit général des contrats, la responsabilité de TC HR Tax &amp; La
        messagerie instantanée ne peut être engagé pour des missions dont il est démontré qu'il les
        a acceptées.
      </p>
      <p>
        Sauf respect des délais légaux (sous réserve que le client ait s'est acquitté de toutes ses
        obligations telles que visées à l'article 4 des Conditions Générales et Conditions ou à
        l'article 8.1 de la lettre de mission), l'engagement de TC HR Tax &amp; JE SUIS vis-à-vis de
        son client est une obligation de moyens (<em>obligation de moyens</em>). CT RH Impôt &amp;
        IM exécutera les missions qui lui sont confiées en toute indépendance et avec les soins
        requis selon les normes, les instructions ou la réglementation de l'ITAA.
      </p>
      <p>
        TC HR Tax & amp; IM dépend du client pour l'exactitude des informations et/ou documentation
        fournie par ce dernier. TC HR Tax & amp; IM n'est pas obligé de vérifier les l'exactitude et
        l'exhaustivité des informations qui lui sont communiquées par le client ou sur son nom,
        ainsi que la fiabilité de tous actes, contrats, inventaires, factures et les pièces
        justificatives de toute nature, qui lui seraient confiées ou présentées par le client comme
        documents probants ou devant être utilisés comme tels.
      </p>
      <p>
        Notre mission ne comprend pas la détection de fraude, d'irrégularité ou d'illégalité dans
        les actions, mais seulement la vérification du contenu des documents reçus dans le cadre de
        notre mission, quant à leur exhaustivité et leur adéquation aux principes et normes
        généralement admis par le métier.
      </p>
      <p>
        TC Fiscalité RH &amp ; IM ne peut être tenu responsable des conséquences des modifications
        législatives.
      </p>
      <p>
        TC HR Tax & amp; IM ne peut être tenu pour responsable des manquements, fautes ou
        infractions commises avant son intervention.
      </p>
      <p>
        TC HR Tax & amp; IM est un cabinet indépendant membre d'Alliott Global Alliance, qui est un
        alliance internationale de cabinets comptables, juridiques et spécialisés indépendants.
        Alliott Global Alliance et ses cabinets membres sont des entités juridiquement distinctes et
        séparées. Ces entités sont pas et ne doit pas être interprété comme étant dans la relation
        d'une société mère, filiale, partenaire, coentreprise, agent ou réseau. Aucun cabinet membre
        d'Alliott Global Alliance n'a pouvoir (réel, apparent, implicite ou autre) d'obliger ou de
        lier Alliott Global Alliance ou tout autre cabinet membre d'Alliott Global Alliance de
        quelque manière que ce soit. Également, ni Alliott Global Alliance ni aucune autre société
        membre n'a le pouvoir d'obliger ou lier TC HR Tax &amp ; IM ou tout autre cabinet membre.
        Tous les membres d'Alliott Global Alliance sont cabinets indépendants, et à ce titre, ils
        rendent chacun leurs services entièrement par eux-mêmes compte (y compris les avantages et
        les risques). Dans le cadre de la lettre de mission, le général Conditions générales ou tout
        autre service fourni de temps à autre par TC HR Tax &amp; JE SUIS, TC HR Tax & amp; IM peut
        demander conseil à ou peut recommander la rétention d'un Alliott Global Cabinet membre de
        l'Alliance. Alliott Global Alliance et ses autres sociétés membres n'auront aucune
        responsabilité pour les conseils rendus par TC HR Tax &amp; IM, ou tel consulté ou retenu
        par Alliott Cabinet membre de l'Alliance mondiale. TC HR Tax &amp; Les GI sont responsables
        des conseils rendus par l'un des autres cabinets membres d'Alliott Global Alliance, même
        consultés ou recommandés à vous par TC HR Tax &amp; JE SUIS.
      </p>
      <p>
        En tout état de cause, la responsabilité de TC HR Tax &amp; MI pour tout service effectué
        par ou pour le compte de TC HR Tax &amp; IM ou qui est directement ou indirectement lié à
        une mission confiée à TC HR Tax &amp; IM est limité au montant couvert en vertu des
        conditions de la programme d'assurance responsabilité civile professionnelle souscrit par TC
        HR Tax &amp; messagerie instantanée via le Institut des conseillers fiscaux &amp;
        Comptables.
      </p>
      <p>
        Cette limitation s'applique également aux réclamations qui seraient dirigées par le
        responsable de la représentation de la cession à l'encontre de toutes personnes, associés,
        gérants et/ou indépendants collaborateurs, qui sont assurés au sens du contrat d'assurance.
      </p>
      <p>
        Lorsqu'il apparaît que deux ou plusieurs dommages résultent d'une même faute, ils seront
        considéré comme ne constituant qu'un seul cas de responsabilité, et la responsabilité sera
        donc limité au montant le plus élevé de ceux applicables aux missions ou accords concernés.
      </p>
      <p>
        Il est expressément stipulé que cette limitation est applicable en cas de faute lourde
        commis par TC HR Tax &amp; IM ainsi qu'en cas de responsabilité civile de TC HR Tax &amp; JE
        SUIS.
      </p>
      <p>
        En tout état de cause, TC HR Tax &amp; IM ne sera pas responsable des dommages indirects ou
        consécutifs perte ou dommage économique (y compris, sans s'y limiter, la perte de profit, de
        chiffre d'affaires et clientèle) que le client peut subir du fait de la fourniture des
        services de TC HR Impôt &amp; JE SUIS.
      </p>
      <p>
        Aucune responsabilité envers un tiers n'est acceptée à moins que TC HR Tax &amp; IM a donné
        son exprimer son consentement écrit.
      </p>
      <p>
        Les règles professionnelles autorisent TC HR Tax &amp; IM, dans le strict respect des règles
        professionnelles secret, d'accepter des affaires pour d'autres clients dont les activités
        peuvent entrer en concurrence avec celles du client.
      </p>
      <h3>3.Frais</h3>
      <p>
        Nos tarifs et offres forfaitaires s'entendent hors TVA, taxes étrangères et déductions
        éventuelles, débours et frais de déplacement.
      </p>
      <p>
        Ils font l'objet d'une indexation annuelle en fonction de l'évolution du coût de la vie.
        N'importe lequel toute évolution tarifaire non contestée dans les 8 jours de sa notification
        sera présumée accepté.
      </p>
      <p>
        Les tarifs horaires varient selon l'expérience du consultant et sont compris entre 70 euros
        et 420 euros.
      </p>
      <p>
        Les offres forfaitaires sont limitées aux prestations décrites et ne couvrent pas d'autres
        prestations tels que, mais sans s'y limiter, des services fiscaux spécifiques tels que
        divers conseils, réponse à une demande de l'administration ou assistance dans le cadre d'un
        litige avec administration.
      </p>
      <p>
        Ils sont déterminés en fonction d'une estimation du volume de travail requis, de la
        difficulté de la mission, la nature de l'activité et le temps et l'expérience requis par un
        professionnel.
      </p>
      <p>
        Si, au cours des travaux, l'un de ces facteurs devait changer de telle sorte que notre
        proposition soit n'est plus approprié, nous vous contacterons immédiatement pour convenir
        d'une nouvelle proposition de budget.
      </p>
      <p>
        Pour couvrir les frais de fonctionnement de TC HR Tax &amp; IM, un pourcentage de 5% sera
        appliqué sur factures (ce poste couvre, frais de dossier, informatique, courrier recommandé
        avec accusé de réception, frais de secrétariat et de réception).{" "}
      </p>
      <p>
        Les honoraires et frais sont dus au fur et à mesure de la prestation des services au profit
        du client, même si la mission n'est pas nécessairement terminée.
      </p>
      <p>
        TC HR Tax & amp; IM peut demander un ou plusieurs acomptes. Ces paiements sont intégrés
        compte dans le décompte final des frais et honoraires.
      </p>
      <p>
        Tous les frais et honoraires d'opposition doivent être formulés par écrit par lettre
        recommandée ou par e-mail dans les 60 jours suivant la date de la facture par défaut de ce
        que le client est réputé avoir d'accord avec les services et les montants facturés.
      </p>
      <p>
        Les factures et/ou notes d'honoraires sont payables dans les 14 jours suivant la date de
        facturation.
      </p>
      <p>
        Plus de 15 jours&rsquo; retard de paiement, sera de plein droit et sans mise en demeure
        préalable par défaut dû :
      </p>
      <p>
        &middot;un intérêt compensatoire au taux prévu à l'article 5 de la loi du 2 août 2002
        relative aux retards de paiement dans les transactions commerciales ;
      </p>
      <p>
        &middot;une indemnité forfaitaire fixée à 10% des sommes impayées, avec un minimum de &euro;
        250,00.
      </p>
      <h3>4.Le client s'engage par la présente :</h3>
      <p>
        Au vu de la mission confiée à TC HR Tax &amp; IM, le client scrupuleusement s'engage à
        collaborer et à fournir précisément et dans les délais tous les documents, données et
        informations nécessaires à l'exécution de la mission.
      </p>
      <p>Le client s'engage également</p>
      <ul>
        <li>
          de fournir toute information que TC HR Tax &amp; Demandes de messagerie instantanée selon
          la législation et la réglementation relative à la prévention de l'utilisation du système
          financier pour but du blanchiment d'argent et du financement du terrorisme ;
        </li>
      </ul>
      <ul>
        <li>
          communiquer sans délai tout changement de ses coordonnées à TC HR Tax &amp; je suis et
          tout changement de leurs bénéficiaires effectifs ;
        </li>
      </ul>
      <ul>
        <li>
          de se conformer au droit belge des sociétés et à toute législation empêchant ou relative à
          corruption, infraction pénale ou fraude ;
        </li>
      </ul>
      <ul>
        <li>
          à mettre à disposition de TC HR Tax &amp; IM, en temps opportun, tous les documents,
          données et informations nécessaire à l'exécution de la mission ;
        </li>
      </ul>
      <ul>
        <li>
          exécuter le travail qui lui est confié, le cas échéant, conformément à la mission lettre;
        </li>
      </ul>
      <ul>
        <li>
          à porter à l'attention de TC HR Tax &amp; IM toute donnée, événement ou développement
          susceptible influencer l'exécution de la mission;
        </li>
      </ul>
      <ul>
        <li>
          Sur TC HR Tax &amp ; À la demande d'IM, confirmez par écrit que les documents, les
          informations et explications fournies sont exactes et complètes ;
        </li>
      </ul>
      <ul>
        <li>
          Vérifier si les documents et déclarations produits par le professionnel correspondent à
          ses attentes et les informations fournies par lui, et si tel n'est pas le cas, d'informer
          sans délai TC HR Tax & amp; JE SUIS;
        </li>
      </ul>
      <ul>
        <li>
          informer TC HR Tax &amp; IM de tout défaut de paiement, dès la première échéance,
          concernant tout créancier administratif, fiscal, social, ou tout autre créancier quel
          qu'il soit.
        </li>
      </ul>
      <p>
        A défaut, TC HR Tax &amp; IM sera autorisé à mettre fin à la mission en fonction à l'article
        6.3 ci-dessous et sera dégagé de toute responsabilité en cas de non-respect des les délais
        fixés par les lois, règlements et conventions pour l'exécution des obligations fiscales,
        sociales, ou autres formalités, qui relèveraient de sa mission.
      </p>
      <h3>5.Lieu d'exécution de la mission</h3>
      <p>
        Livres et documents fournis ou préparés par TC HR Tax &amp; IM pour l'exécution de la la
        mission peut être déplacée vers d'autres endroits sur TC HR Tax &amp; La discrétion d'IM. Le
        professionnel peut les détenir le temps nécessaire à l'accomplissement de sa (ses)
        mission(s). Le client a toujours la droit de les consulter, soit personnellement, soit par
        l'intermédiaire de l'un de ses représentant ou mandataire muni d'une procuration écrite, à
        condition que ces livres et les documents sont la propriété du client.
      </p>
      <p>
        Il appartient au client de conserver les registres et documents comptables et de prendre les
        restituer dès que le professionnel l'aura informé de leur disponibilité. Le professionnel
        s'engage à tenir régulièrement à la disposition du client ses documents comptables.
      </p>
      <h3>6.Modalités de fin de collaboration</h3>
      <h4>6.1.Tâches récurrentes</h4>
      <p>
        Les affectations récurrentes sont des affectations qui sont de nature répétitive et qui ne
        répondent pas la définition visée à l'article 6.2 des "missions non récurrentes".
      </p>
      <p>
        Le contrat relatif aux missions récurrentes est conclu pour une durée indéterminée. Chaque
        Partie aura le droit d'y mettre fin en notifiant à l'autre Partie sa volonté de le faire par
        lettre recommandée et en observant un délai de trois mois&rsquo; période de préavis à moins
        que le Les parties en conviennent autrement.
      </p>
      <p>
        En cas de non-respect par le client du délai de préavis, TC HR Tax &amp; La messagerie
        instantanée est droit d'obtenir le paiement d'une indemnité correspondant à 50% des
        honoraires facturés au cours des 3 mois précédents.
      </p>
      <p>
        Pendant le délai de préavis, les dispositions de la lettre de mission et des présentes
        Conditions Générales et les conditions restent en vigueur.
      </p>
      <p>
        Sauf accord contraire des parties, la fin de la mission, même avec respect d'un préavis,
        entraîne la résiliation immédiate des services du professionnel pour l'établissement des
        comptes annuels de l'exercice précédant la date de Résiliation.
      </p>
      <h4>6.2.Missions non récurrentes</h4>
      <p>
        Les missions non récurrentes sont des missions ponctuelles et non répétitives, la
        réalisation de qui met fin à la mission.
      </p>
      <p>
        Sauf preuve contraire, la convention relative à une mission non récurrente est réputée être
        conclu pour une durée déterminée.
      </p>
      <p>
        Elle prend fin avec l'exécution de la mission et, si cela s'applique compte tenu de la
        nature de la mission, avec la fourniture des services convenus.
      </p>
      <p>
        En application de l'article 1794 Code civil et, le cas échéant, nonobstant l'article 2004
        Code civil, le client a le droit de résilier le contrat de manière anticipée, moyennant
        paiement à le professionnel des frais et honoraires correspondant aux travaux déjà réalisés.
      </p>
      <p>
        Cette rémunération est calculée sur une base réelle, avec un minimum de 50% des honoraires
        qui aurait été due en cas de pleine exécution de la mission.
      </p>
      <h4>6.3.Résiliation immédiate</h4>
      <p>
        TC HR Tax & amp; IM peut mettre fin à la collaboration, à tout moment, sans préavis ni aucun
        indemnité quelle qu'elle soit et sans intervention judiciaire préalable, en cas de faute
        inexécution d'une des obligations du client à laquelle il n'a pas remédié dans un délai de
        huit (8) jours suivant l'envoi d'une mise en demeure par TC HR Tax &amp; IM lui enjoignant
        de cesser tout défaut ainsi que dans l'une des situations suivantes :
      </p>
      <ul>
        <li>
          toute circonstance susceptible de compromettre l'indépendance de TC HR Tax &amp ;
          messagerie instantanée ;
        </li>
      </ul>
      <ul>
        <li>
          toute circonstance susceptible de rendre impossible l'exécution de la mission,
          conformément aux normes professionnelles et éthiques ;
        </li>
      </ul>
      <ul>
        <li>
          le défaut du client de communiquer les documents nécessaires à TC HR Tax &amp; Je suis
          pour l'exécution de sa mission ;
        </li>
      </ul>
      <ul>
        <li>
          en cas de réorganisation judiciaire, dissolution, liquidation, faillite du client ou toute
          procédure similaire.{" "}
        </li>
      </ul>
      <p>
        Lors de la résiliation du contrat, TC HR Tax &amp; IM informe le client de l'urgence et
        actes juridiques nécessaires qui, pour la sauvegarde de ses droits, doivent être accomplis
        et pour dont TC HR Tax &amp; IM avait été confié.
      </p>
      <p>
        Le client peut résilier le contrat, à tout moment, sans préavis et sans indemnité, TC HR Tax
        & amp; IM reste manifestement en défaut de remplir ses obligations, telles que décrites dans
        les présentes conditions générales et, le cas échéant, dans la lettre de mission. Le client
        va envoyer une mise en demeure à TC HR Tax &amp; IM identifiant le défaut et enjoignant TC
        HR Tax &amp; IM pour l'arrêter.
      </p>
      <h4>6.4.Suivi</h4>
      <p>
        A la fin de la mission, tous les livres et documents, propriété du client, sont rendus à sa
        disposition ou à celle de son représentant par l'établissement d'un inventaire daté et
        signée en autant d'originaux qu'il y a de parties.
      </p>
      <p>La fin de la mission :</p>
      <ul>
        <li>
          entraîne le retrait immédiat de tout mandat délivré pour le dépôt de toutes déclarations,
          documents ou formulaires tels que déclarations de revenus des particuliers, déclarations
          de revenus des sociétés, déclarations de TVA, comptes annuels, registre UBO &hellip;
        </li>
        <li>
          oblige le client à reprendre sans délai toutes ses pièces comptables auprès du
          professionnel.
        </li>
      </ul>
      <h4>6.5.Suspension ou report de la mission</h4>
      <p>
        En cas d'inexécution, de mauvaise exécution ou de retard d'exécution par le client, d'une ou
        plusieurs de ses obligations, y compris en cas de non-paiement d'honoraires ou d'acompte
        paiement(s), conformément à l'article 3 ci-dessus, TC HR Tax &amp; IM a le droit de
        suspendre ou différer l'exécution de ses obligations jusqu'à ce que le client ait satisfait
        aux siennes.
      </p>
      <p>
        TC HR Tax & amp; IM informe le client par écrit et notifie le client de toute urgence et les
        travaux nécessaires à la sauvegarde des droits du client qui doivent être effectués dans les
        trois mois après le début de la suspension ou du report de la mission.
      </p>
      <p>
        Tous les frais et charges (y compris les amendes et les indemnités) résultant de la
        suspension ou le report est à la charge du client.
      </p>
      <p>
        TC HR Tax & amp; IM a droit en toutes circonstances au paiement des honoraires et frais
        relatives aux prestations déjà réalisées.
      </p>
      <h3>7.Confidentialité - traitement des données</h3>
      <p>
        TC HR Tax & amp; IM assume ses obligations en matière de protection de la vie privée et de
        la protection des données personnelles très au sérieux.
      </p>
      <p>
        <em>Définitions : </em>Dans cette clause, les termes suivants auront les significations
        suivantes :
      </p>
      <p>
        "<strong>contrôleur</strong>", "<strong>sous-traitant</strong>", "
        <strong>personne concernée</strong>
        ", "Données <strong>personnelles</strong> <strong></strong>" et "<strong>traitement</strong>
        " (et « <strong>processus</strong> » a le sens qui lui est donné dans la Loi applicable sur
        la protection des données ;
      </p>
      <p>
        « <strong>Loi applicable sur la protection des données</strong> » désigne l'ensemble de la
        protection des données et les lois et réglementations en matière de confidentialité
        applicables aux données personnelles en question, y compris, le cas échéant applicable, la
        loi européenne sur la protection des données ; et
      </p>
      <p>
        « <strong>Législation européenne sur la protection des données</strong> » désigne (i) le
        règlement 2016/679 du Parlement européen et du Conseil sur la protection des personnes
        physiques à l'égard de traitement des données à caractère personnel et sur la libre
        circulation de ces données (Règlement général sur la protection des données règlement) (le
        « <strong>RGPD</strong> » ); (ii) la directive européenne e-Privacy (directive 2002/58/CE);
        et (iii) toutes les lois nationales applicables en matière de protection des données en
        vertu de ou conformément à (i) ou (ii); dans chaque cas, tel qu'il peut être modifié ou
        remplacé de temps à autre.
      </p>
      <p>
        <em>Qualification juridique des Parties : </em>TC HR Tax &amp ; IM et le client traiteront
        données personnelles (ci-après, les "<strong>Données</strong>") conformément aux Conditions
        Générales Conditions. Les Parties reconnaissent que chaque Partie traite les Données en sa
        possession ou contrôle en tant que contrôleur de données indépendant pour leur(s) propre(s)
        finalité(s) (et non en tant que contrôleurs conjoints ou en tant que responsable du
        traitement des données de l'autre Partie).
      </p>
      <p>
        <em>Conformité à la loi :</em> chaque partie doit se conformer à ses obligations en vertu
        des Loi sur la protection des données et les présentes conditions générales lors du
        traitement des données. Aucune des parties ne sera responsable de la conformité de l'autre
        partie aux données applicables Loi protectrice. En particulier, chaque Partie est
        individuellement responsable d'assurer que son traitement des Données est licite, loyal et
        transparent, et mettra à la disposition de les personnes concernées une déclaration de
        confidentialité qui répond aux exigences des données applicables Loi protectrice. Dans la
        mesure où Tax Consult HR Tax &amp; IM n'est pas en contact direct avec les personnes
        concernées, le client fournira une copie de Tax Consult HR Tax &amp; Confidentialité de la
        messagerie instantanée politique aux personnes concernées sans délai après la conclusion des
        présentes Conditions Générales et Conditions.
      </p>
      <h3>8.Divisibilité et juridiction</h3>
      <p>
        Les Conditions Générales sont applicables à l'exclusion de toutes autres. Toute clause ou
        condition constitue une disposition distincte et indépendante. Si une disposition est
        considérée nulles et non avenues ou inopposables, toutes les autres dispositions
        continueront de s'appliquer.
      </p>
      <p>
        L'engagement de TC HR Tax &amp; IM sera régi et interprété conformément à droit belge. Tout
        litige ou réclamation en découlant sera soumis, avant toute procédure judiciaire action, à
        une procédure de médiation conformément au règlement de médiation de bMediation, dont siège
        social est établi à 1050 Bruxelles, avenue Louise 500. La médiation commencer au plus tard
        quinze (15) jours après la demande de médiation notifiée par un partie à l'autre partie et
        la durée de la médiation ne doit pas dépasser quinze (15) jours, sauf convention expresse
        des parties.
      </p>
      <p>
        En cas d'échec de la médiation, les tribunaux de Bruxelles seront seuls compétents
        compétence, sans préjudice de la compétence des ordres professionnels dont TC Taxe RH &amp;
        La messagerie instantanée dépend.{" "}
      </p>
      <h3>9.Interdiction de solliciter</h3>
      <p>
        Pendant toute la durée de la collaboration, et pendant une période de 12 mois après la fin
        de la collaboration, quelle que soit la raison de la rupture de la relation, le client et TC
        HR Tax &amp ; IM s'engage expressément à ne pas embaucher de personnel ou d'indépendant
        collaborateurs de l'autre partie impliqués dans l'exécution de l'accord, directement ou
        indirectement (notamment par l'intermédiaire d'une personne morale), ni de leur faire
        exécuter des travaux, directement ou indirectement (notamment par l'intermédiaire d'une
        personne morale), sauf accord écrit préalable de l'autre partie a été obtenue.
      </p>
      <p>
        Toute infraction à cette interdiction donnera lieu à une indemnité forfaitaire de 25% du les
        charges annuelles de rémunération du membre du personnel censées couvrir les honoraires qui
        serait appliqué par un chasseur de têtes pour trouver un profil équivalent.
      </p>
      <br />
      <p>
        <strong>
          Toutes vos données personnelles et professionnelles seront stockées dans une base de
          données appartenant à Tax Consulter S.A..
        </strong>
      </p>
    </>
  );
};

export default TermsConditions;
