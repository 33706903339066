import React, { FC, useEffect, useState } from 'react'
import { Col, Label, Row } from 'reactstrap';

import Select from "react-select";
import commonService from '../../../services/common.service';
import { yearData } from '../../../helpers/functions_helper';
import { useTranslation } from 'react-i18next';
type FiltersProps = {
    filters: any;
    onApply: any;
    onClear: any;
    onChange?: any;
}

const Filters: FC<FiltersProps> = ({ filters, onApply, onClear, onChange }) => {
    const {t} = useTranslation();
    /* set user Status */
    const [userStatusOption, setuserStatusOption] = useState([]);
    const [loadingUserStatus, setloadingUserStatus] = useState(true);


    const getSelectOptionsOrder = async (tableName: string, fieldName: string) => {
        const response = await commonService.getSelectOptionsListOrder(
            tableName,
            fieldName
        );
        return response.data.data;
    };

    useEffect(() => {

        getSelectOptionsOrder("tbl_master_user_status", "user_status_value").then(function (
            options
        ) {
            setuserStatusOption(options);
            setloadingUserStatus(false);
        });

    }, [])
    
    
    const [statusList, setStatusList] = useState<any>([]);
    const [yearList, setYearList] = useState<any>([]);

    useEffect(()=>{
        if(yearData){
            const Options = yearData?.map((option: any) => ({
                label: t(option.label),
                value: option.value
              }));
              setYearList(Options);
        }
    },[]);
    useEffect(() => {
        if(userStatusOption){
            const Options = userStatusOption?.map((option: any) => ({
                label: t(option.label),
                value: option.value
              }));
              setStatusList(Options);
        }
        if(yearData){
            const Options = yearData?.map((option: any) => ({
                label: t(option.label),
                value: option.value
              }));
              setYearList(Options);
        }
     }, [localStorage.getItem("I18N_LANGUAGE"),userStatusOption]);

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <div className="mb-3">
                        <Label className="form-label">
                            {t("User type")}{" "}
                        </Label>
                        <Select
                            id="user_type"
                            name="user_type"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={statusList}
                            //@ts-ignore
                            defaultValue={statusList?.find((o: any) => o.value === filters?.user_type)}
                            isSearchable={true}
                            isDisabled={loadingUserStatus}
                            onChange={(option: any) => {
                                onChange({ user_type: option.value })
                            }}
                        />

                    </div>
                </Col>
                <Col>
                    <div className="mb-3">
                        <Label className="form-label">
                            {t("Year")} {" "}
                        </Label>
                        <Select
                            id="year"
                            name="year"
                            className="is-invalid"
                            placeholder={t("Select...")}
                            options={yearList}
                            //@ts-ignore
                            defaultValue={yearList?.find((opt: any) => opt.value === filters?.year)}
                            isSearchable={true}
                            onChange={(option: any) => {
                                onChange({ year: option.value })
                            }}
                        />
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default Filters