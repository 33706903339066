import styled, { css } from 'styled-components';

interface PropStyledSelect {
  variant?: string,
  tabIndex?: string,
}

interface CustomStyleProps {
  variant: string;
};


export const StyledSelect = styled.div<PropStyledSelect>`
  position: relative;
  border-radius: 4px;
  cursor: pointer;
  font-size:14px;
  ${(props: any) => props.variant === 'empty' && `display: inline-block;`}
  ${(props: any) =>
    props.variant === 'normal' &&
    css`
      width: 100%;
      border: 1px solid #dfe1e6;
      background: #F4F5F7;
      transition: background 0.1s;
      &:hover {
        background: #ebecf0;
      }
    `}
  &:focus {
    outline: none;
    ${(props: any) =>
    props.variant === 'normal' &&
    css`
        border: 1px solid #4c9aff;
        box-shadow: 0 0 0 1px #4c9aff;
        background: #fff;
      }
    `}
  }
  ${(props: any) =>
    props.invalid &&
    css`
      &,
      &:focus {
        border: 1px solid #E13C3C;
        box-shadow: none;
      }
    `}
`;

export const ValueContainer = styled.div<CustomStyleProps>`
  display: flex;
  align-items: center;
  width: 100%;
  ${(props: any) =>
    props.variant === 'normal' &&
    css`
      min-height: 32px;
      padding: 5px 5px 5px 10px;
    `}
`;

export const ChevronIcon = styled.i`
  margin-left: auto;
  font-size: 18px;
  color: #5E6C84;
`;

export const Placeholder = styled.div`
  color: #8993a4;
`;


export const ValueMulti = styled.div<CustomStyleProps>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${(props: any) => props.variant === 'normal' && `padding-top: 5px;`}
`;

export const ValueMultiItem = styled.div`
  margin: 0 5px 5px 0;
  display: inline-flex;
  align-items: center;
  height: 24px;
  padding: 0 8px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
`;

export const AddMore = styled.div`
  display: inline-block;
  margin-bottom: 3px;
  padding: 3px 0;
  cursor: pointer;
  color: #172b4d;
  font-weight: normal;
  &:hover, &:visited, &:active {
    color: #172b4d;
  }
  &:hover {
    text-decoration: underline;
  }
  i {
    margin-right: 3px;
    vertical-align: middle;
    font-size: 14px;
  }
`;

export const Dropdown = styled.div`
  z-index: 101;
  position: absolute;
  top: 88%;
  left: 0;
  border-radius: 0 0 4px 4px;
  background: #fff;
  min-width:360px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.31) 0px 0px 1px;
  ${(props: any) => (props.width ? `width: ${props.width}px;` : 'width: 100%;')}
`;

export const DropdownInput = styled.input`
  padding: 10px 14px 8px;
  width: 100%;
  border: none;
  color: #172b4d;
  background: none;
  &:focus {
    outline: none;
  }
`;

export const ClearIcon = styled.i`
  position: absolute;
  top: 4px;
  right: 7px;
  padding: 5px;
  font-size: 16px;
  color: #5E6C84;
  cursor: pointer;
  user-select: none;
`;

export const Options = styled.div`
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`;

export const Option = styled.div`
  padding: 8px 14px;
  word-break: break-word;
  cursor: pointer;
  &:last-of-type {
    margin-bottom: 8px;
  }
  &.jira-select-option-is-active {
    background: #D2E5FE;
  }
`;

export const OptionsNoResults = styled.div`
  padding: 5px 15px 15px;
  color: #8993a4;
`;
