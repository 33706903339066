import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface NoDataFoundProps {
    icon?: string;
    message?: string;
}

const NoDataFound: FC<NoDataFoundProps> = ({ icon = 'quick_reference', message = 'No data available to view' }) => {
    const { t } = useTranslation();
    return (
        <div className="nodata d-flex align-items-center justify-content-center">
            <div>
                <div className="text-center mt-3 mb-3">
                    <span className="align-middle material-symbols-outlined text-muted fw-lighter" style={{ fontSize: "80px" }}>{icon}</span>
                </div>

                <div className="mt-1 mb-3 text-center">
                    <h5 className="text-truncate text-muted fw-lighter">
                        {t(message)}
                    </h5>
                </div>
            </div>
        </div>
    )
}

export default NoDataFound;