import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class Service {

    async create(data: any) {
        const response = await axios
            .post(
                SERVER_URL + url.BROADCAST.CREATE,
                data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async update(data: any) {
        const response = await axios
            .put(
                SERVER_URL + url.BROADCAST.UPDATE,
                data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async delete(id: any) {
        const response = await axios
            .delete(
                SERVER_URL + url.BROADCAST.DELETE + '/' + id,
                {
                    headers: getAxiosConfigWithToken()
                }
            );
        return response.data;
    }

    async getAll(data: any, type?: number) {
        //const query = company_id ? '?company_id=' + company_id : '';
        const post_data ={
            ...data,
            type
        }
        const response = await axios
            .post(SERVER_URL + url.BROADCAST.GET_ALL,
                post_data,
                {
                    headers: getAxiosConfigWithToken(),
                }
            ).then(function (response: any) {
                return response;
            })
            .catch(function (error: any) {
                return error;
            });
        return response;
    }

    async get(id: number) {
        const response = await axios
            .get(
                SERVER_URL + url.BROADCAST.GET + '/' + id ,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async setBroadcastReadUnread(data:any) {
        const response = await axios
            .post(
                SERVER_URL + url.BROADCAST.READ_UNREAD ,data,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }


    async count() {
        const response = await axios
            .get(
                SERVER_URL + url.BROADCAST.COUNT ,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

}

export default new Service();