import axios from "axios";
import { getAxiosConfigWithToken, getAxiosConfigWithAzureToken } from "../helpers/functions_helper";
import * as url from "../helpers/url_helper";

const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;
const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const MICROSOFT_GRAPH_URL = 'https://graph.microsoft.com/v1.0';
class AzureService {
  async getDriveID() {
    const response = await axios
      .get(`${MICROSOFT_GRAPH_URL}/sites/root/drive`, {
        headers: await getAxiosConfigWithAzureToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async checkContent(drive_id: string, folder_path: string, file_name: string) {
    const response = await axios
      .get(
        `${MICROSOFT_GRAPH_URL}/sites/root/drives/${drive_id}/root:/${folder_path}/${file_name}`,
        {
          headers: await getAxiosConfigWithAzureToken(),
        }
      )
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async createContent(data: any) {
    const response = await axios
      .post(SERVER_URL + url.CREATE_CONTENT, data, {
        headers:  getAxiosConfigWithToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async getContent(item_id: string, format: string) {
    const response = await axios
      .get(`${MICROSOFT_GRAPH_URL}/drive/items/${item_id}/content?format=${format}`, {
        headers: await getAxiosConfigWithAzureToken(),
        responseType: "blob",
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async downloadContent(item_id: string) {
    const response = await axios
      .get(`${MICROSOFT_GRAPH_URL}/drive/items/${item_id}/content`, {
        headers: await getAxiosConfigWithAzureToken(),
        responseType: "blob",
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async getEvents(startDateTime: any, endDateTime: any) {
    const response = await axios
      .get(
        `${MICROSOFT_GRAPH_URL}/me/calendar/events?
        $filter=start/dateTime ge '${startDateTime}' and end/dateTime lt '${endDateTime}'
        &&
        $select=id,subject,start,end,webLink,organizer,bodyPreview`,
        {
          headers: await getAxiosConfigWithAzureToken(),
        }
      )
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  // async inviteUser(data: any) {
  //   const response = await axios
  //     .post(SERVER_URL + url.INVITE_USER, data, {
  //       headers: getAxiosConfigWithToken(),
  //     })
  //     .then(function (response) {
  //       return response;
  //     })
  //     .catch(function (error) {
  //       return error;
  //     });
  //   return response;
  // }

  async inviteUser(data: any) {
    const postQuery = {
      "invitedUserEmailAddress": data.email,
      "inviteRedirectUrl": REDIRECT_URI,
      "sendInvitationMessage": true,
    };
    const response = await axios
      .post(`${MICROSOFT_GRAPH_URL}/invitations`, postQuery, {
        headers: await getAxiosConfigWithAzureToken(),
        responseType: "json",
      })
      .then(function (response: any) {
        if (response.status === 200 || response.status === 201) {
          return {
            data: {
              status: "success",
              message: "User successfully invited",
              data: response,
            }
          }
        } else {
          return {
            data: {
              status: "error",
              message: "Somthing went wrong!",
              data: response,
            }
          }
        }

      }).catch(function (error) {
        if (error?.response) {
          return {
            data: {
              status: "error",
              message: error?.response?.data?.error?.message,
              data: error,
            }
          }
        } else if (error?.request) {
          return {
            data: {
              status: "error",
              message: "Somthing went wrong!",
              data: error,
            }
          }
        } else {
          return {
            data: {
              status: "error",
              message: error?.message,
              data: error,
            }
          }
        }
      });
    return response;
  }
}

export default new AzureService();
