import axios from "axios";
import { getAxiosConfigFileUploadWithToken, getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class QuestionnaireQuestionsService {
    async getQuestionnaireQuestions(DATA: any) {
        const response = await axios
            .get(SERVER_URL + url.GET_QUESTIONNAIRE_QUESTIONS_DATATABLE_LIST + "/" + DATA.id + "/" + DATA.tbl_master_questionnaire_tab,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async getQuestionnaireQuestionsList(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_QUESTIONNAIRE_QUESTIONS_DATATABLE_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            );
        return response;
    }

    async getQuestionsList() {
        const response = await axios
            .get(SERVER_URL + url.GET_QUESTIONNAIRE_QUESTIONS_LIST,
                {
                    headers: getAxiosConfigWithToken()
                }
            );
        return response;
    }


    async getQuestionsListWithId(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_QUESTIONNAIRE_QUESTIONS_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            );
        return response;
    }

    async createQuestionnaireQuestions(DATA: any) {
        var form_data = new FormData();
        for ( var key in DATA) {
            form_data.append(key, DATA[key]);
        }
        const response = await axios
            .post(SERVER_URL + url.CREATE_QUESTIONNAIRE_QUESTIONS, form_data,
                {
                    headers: getAxiosConfigFileUploadWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async updateQuestionnaireQuestions(DATA: any) {
        var form_data = new FormData();

        for ( var key in DATA) {
            form_data.append(key, DATA[key]);
        }
        const response = await axios
            .post(SERVER_URL + url.UPDATE_QUESTIONNAIRE_QUESTIONS, form_data,
                {
                    headers: getAxiosConfigFileUploadWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async deleteQuestionnaireQuestions(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.DELETE_QUESTIONNAIRE_QUESTIONS, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }

    async getQuestion(question: any){
        const response = await axios
            .get(SERVER_URL + url.GET_QUESTIONS_ID  + "/" + question.id + "/" + question.question_id,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
            return response;
    }
}

export default new QuestionnaireQuestionsService();