import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Button from '../Button/Button';

type AlertProps = {
    open: boolean,
    title: string,
    message: string,
    onClickOk: () => void,
}

const Alert: FC<AlertProps> = ({ open = false, title, message, onClickOk }) => {
    const { t } = useTranslation();

    return (
        <Modal isOpen={open} modalTransition={{ timeout: 200 }} backdrop>
            <ModalHeader>
                <span className="material-symbols-outlined align-middle text-warning">
                    report
                </span>
                <span className='mx-2 font-size-16 bold'>
                    {t(title)}
                </span>
            </ModalHeader>
            <ModalBody className='font-size-15'>{t(message)}</ModalBody>
            <ModalFooter>
                <Button color='primary' onClick={onClickOk}>{t("Ok")}</Button>
            </ModalFooter>
        </Modal>
    )
}

export default Alert;