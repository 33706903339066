import React, { FC } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';

interface ConfirmProps {
    title?: string;
    isOpen: boolean;
    message: string;
    onYesClick: () => void;
    onNoClick: () => void;
}

const Confirm: FC<ConfirmProps> = ({ title, isOpen = false, message, onYesClick, onNoClick }) => {

    const { t } = useTranslation();

    return (
        <Modal
            size="md"
            isOpen={isOpen}
            backdrop="static"
            keyboard={false}
            autoFocus={true}
            centered={true}
        >
            {title &&
                <ModalHeader>
                    {t(title)}
                </ModalHeader>
            }
            <ModalBody className='bg-light bg-soft font-size-18 text-dark text-center'>
                {t(message)}
            </ModalBody>
            <ModalFooter>
                <Button color='primary' onClick={onYesClick}>{t("Yes")}</Button>
                <Button color='danger' onClick={onNoClick}>{t("No")}</Button>
            </ModalFooter>
        </Modal>
    )
}

export default Confirm;