import React, { Component, useState, useEffect } from "react";
import "./styles.css";

const ScrollUp = (props: any) => {
  const [showBtnScrollUp, setShowBtnScrollUp] = useState(false);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const act = () => {
      if (window.scrollY > 400) {
        setShowBtnScrollUp(true);
      } else {
        setShowBtnScrollUp(false);
      }
    };
    const ev = window.addEventListener("scroll", act);
    return () => {
      window.removeEventListener("scroll", act);
    };
  }, []);

  return (
    <React.Fragment>
      {showBtnScrollUp && (
        <button
          type="button"
          className="btn btn-primary btn-floating btn-sm"
          id="btnScrollUp"
          title="Scroll up"
          onClick={goToTop}
        >
          <span className="align-middle material-symbols-outlined fw-light fs-4">
            expand_less
          </span>
        </button>
      )}
    </React.Fragment>
  );
};

export default ScrollUp;
