import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactApexChart from "react-apexcharts";
import { getUserProfile } from '../../../../helpers/functions_helper';
import { serialize } from '../../../../utils/utils';
import { noData } from '../../../../utils/data';
import GraphFilters from './Filters';
import LoadingSpinner from '../../../../components/Common/Loader';
import mastersCompanyService from '../../../../services/masters.company.service';
import { currentMonth, currentYear } from '../../../../common/staticdata';
import ChartFilters from '../../../../components/Chart/Filters';
import Chart from '../../../../components/Chart';

interface CompanyVsArchiveProps extends CartProps {
    filters?: any;
}

const CompanyVsArchive: FC<CompanyVsArchiveProps> = ({ hideHeading, filters = null }) => {

    const { t } = useTranslation();

    const userObj = getUserProfile();

    const inititalFilter = {
        week: null,
        quarter: null,
        month: null,
        year: currentYear,
        user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_ID : null
    };

    const [loading, setLoading] = useState<boolean>(true);
    const [query, setQuery] = useState<string | null>(serialize(inititalFilter));
    const [filter, setFilter] = useState<any>(inititalFilter);

    const [data, setData] = useState<any>({
        series: [],
        options: {
            chart: {
                width: 380,
                type: 'donut',
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        show: true,
                        position: 'left'
                    }
                }
            }],
            // stroke: {
            //     colors: ['#333'],
            //     width: 1
            // },
            noData: noData,
        },
    })

    const getChartData = async () => {
        const result = await mastersCompanyService.companyVsArchieved(query);
        if (result.data.status === 'success') {
            setData((data: any) => ({
                ...data,
                series: [result?.data?.data?.archive_user || 0, result?.data?.data?.non_archive_user || 0],
                options: {
                    ...data.options,
                    colors: ['#564ab1', '#d92626'],
                    labels: [t('Archive company'), t('Non-archive company')],
                }

            }))
        }
        setLoading(false);
    }

    const clearWorkAllocationFilter = () => {
        setFilter(inititalFilter);
    }

    useEffect(() => {
        loading && getChartData();
    }, [loading])

    useEffect(() => {
        setQuery(serialize(filter));
        setLoading(true);
    }, [filter])

    useEffect(() => {
        filters && setFilter(filters)
    }, [filters])

    return (
        <>
            <div className="d-sm-flex flex-wrap">
                {!hideHeading &&
                    <h4 className="card-title mb-4">{t("Archive vs Non-archive company")}</h4>
                }
                <div className="ms-auto">
                    <ChartFilters
                        filter={filter}
                        setFilter={setFilter}
                        clearFilter={clearWorkAllocationFilter}
                    />
                </div>
            </div>
            <div className="clearfix"></div>
            {loading ? <LoadingSpinner height={380} /> : <div style={{ height: '100%' }}><Chart options={data.options} series={data.series} type="donut" height={380} /></div>}
        </>
    )
}

export default CompanyVsArchive;