import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactApexChart from "react-apexcharts";
import { noData } from "../../../utils/data";
import LoadingSpinner from "../../../components/Common/Loader";
import GraphFilters from "../../../components/Common/GraphFilters";
import ApplyFilter from "../../../components/Common/ApplyFilter";
import { getUserProfile } from "../../../helpers/functions_helper";
import taskService from "../../../services/tasks.service";
import { serialize } from "../../../utils/utils";
import { toast } from "react-toastify";
import { Row } from "reactstrap";
import { useNavigate } from "react-router-dom";
import cpoService from "../../../services/cpo.service";
//import gfilters from "../filter";
interface WorkAllocationProps extends CartProps {
  filters?: any;
}

const WebsiteUsersVsCustomer: FC<WorkAllocationProps> = ({
  hideHeading,
  filters = null,
}) => {
  const { t } = useTranslation();

  const userObj = getUserProfile('all-cpo');
  const navigate = useNavigate();
  const [selectedID, setSelectedID] = useState<any>({
    user_id: null,
    status_id: null,
    event: null,
  });
  const inititalFilter = {
    week: null,
    quarter: null,
    month: null,
    year: null,
    user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN)
      ? userObj.USER_ID
      : null,
  };

  const viewTaskList = (i: any) => {
    if (i) {
      if (i.user_id && i.status_id) {
        let _filter = {
          type: "work-allocation",
          data: i,
          label: "work allocation",
        };
        //gfilters.setFilter(_filter);
        navigate("../tasks/all-tasks");
      }
      setSelectedID({ user_id: null, status_id: null, event: null });
    }
  };

  useEffect(() => {
    if (selectedID?.event === "click") {
      viewTaskList(selectedID);
    }
  }, [selectedID]);

  const [loading, setLoading] = useState<boolean>(true);
  const [query, setQuery] = useState<string | null>(serialize(inititalFilter));
  const [filter, setFilter] = useState<any>(inititalFilter);

  const [data, setData] = useState<any>({
    series: [
      {
        name: t("Website Users"),
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: t("Client"),
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#34c38f", "#556ee6"],
      xaxis: {
        categories: [
          t("Jan"),
          t("Feb"),
          t("Mar"),
          t("Apr"),
          t("May"),
          t("Jun"),
          t("Jul"),
          t("Aug"),
          t("Sep"),
          t("Oct"),
          t("Nov"),
          t("Dec"),
        ],
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      yaxis: {
        title: {
          text: t('Total user')
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val: number) {
            return val
          }
        }
      },
      legend: {
        horizontalAlign: "left",
        offsetX: 40,
      },
    },
  });

  const getChartData = async () => {
    try {
      const result = await cpoService.getWebsiteUserVsCustomer(query);
      if (result.data.status === 'success') {
        setData((data: any) => ({
          ...data,
          series: result?.data?.data ? [
            {
              data: result?.data?.data[0].data,
              name: t("Website Users"),
            },
            {
              data: result?.data?.data[1].data,
              name: t("Client"),
            }
          ] : [],
          options: {
            ...data.options,
            xaxis: {
              categories: [
                t("Jan"),
                t("Feb"),
                t("Mar"),
                t("Apr"),
                t("May"),
                t("Jun"),
                t("Jul"),
                t("Aug"),
                t("Sep"),
                t("Oct"),
                t("Nov"),
                t("Dec"),
              ],
            },
            yaxis: {
              title: {
                text: t('Total user')
              }
            }
          }
        }))
      }
      setLoading(false);
    } catch (error: any) {
      toast(error);
    }
  };

  const clearFilter = () => {
    setFilter(inititalFilter);
  };

  useEffect(() => {
    loading && getChartData();
  }, [loading]);

  useEffect(() => {
    setQuery(serialize(filter));
    setLoading(true);
  }, [filter]);

  useEffect(() => {
    filters && setFilter(filters);
  }, [filters]);

  useEffect(() => {
    setLoading(true);
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  return (
    <>
      <div className="d-sm-flex flex-wrap">
        <h4 className="card-title mb-4">
          {!hideHeading && t("Website users Vs Client")}
        </h4>
        &nbsp;
        <div className="ms-auto">
          <GraphFilters
            filter={filter}
            setFilter={setFilter}
            clearWorkAllocationFilter={clearFilter}
            enableCompany={false}
            enableUser={false}
            userObj={userObj}
          />
        </div>
      </div>
      <div className="clearfix"></div>
      {loading ? (
        <LoadingSpinner height={380} />
      ) : (
        <div style={{ height: "100%" }}>
          <ReactApexChart
            options={data.options}
            series={data.series}
            type="bar"
            height={350}
          />
        </div>
      )}
    </>
  );
};

export default WebsiteUsersVsCustomer;
