import {
  GET_PROVIDERS,
  GET_PROVIDERS_SUCCESS,
  GET_PROVIDERS_ERROR,

  GET_CONNECTIONS,
  GET_CONNECTIONS_SUCCESS,
  GET_CONNECTIONS_ERROR,
} from "./actionTypes"

const initialState = {
  GET_PROVIDERS_SUCCESS: "",
  GET_PROVIDERS_ERROR: "",

  GET_CONNECTIONS_SUCCESS: "",
  GET_CONNECTIONS_ERROR: "",

  loading: false,
}

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    // ---------------------------------------------GET_PROVIDERS
    case GET_PROVIDERS:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_PROVIDERS_SUCCESS:
      state = {
        ...state,
        GET_PROVIDERS_SUCCESS: action.payload,
        loading: false,
      }
      break

    case GET_PROVIDERS_ERROR:
      state = {
        ...state,
        GET_PROVIDERS_ERROR: action.payload,
        loading: false
      }
      break

    // ---------------------------------------------GET_CONNECTIONS
    case GET_CONNECTIONS:
      state = {
        ...state,
        loading: true,
      }
      break

    case GET_CONNECTIONS_SUCCESS:
      state = {
        ...state,
        GET_CONNECTIONS_SUCCESS: action.payload,
        loading: false,
      }
      break

    case GET_CONNECTIONS_ERROR:
      state = {
        ...state,
        GET_CONNECTIONS_ERROR: action.payload,
        loading: false
      }
      break

    // ---------------------------------------------DEFAULT
    default:
      state = { ...state }
      break
  }
  return state
}

export default reducer
