import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Form,
  Label,
  Button,
  FormFeedback,
  Spinner,
  InputGroup,
  NavItem,
  Nav,
  Input as InputNew
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import labelPlugin from "flatpickr/dist/plugins/labelPlugin/labelPlugin";

// Static Data
import { languageOptions, YesNoMaster, vatReasonOptions } from "../../../common/staticdata";
import {
  calculateVatData,
  filterCountryByLanguage,
  getSelectOptions,
  handleAddress,
  listingStatusOptions,
  statusOptions,
  datePickerI18,
  handleAutoSave
} from "../../../helpers/functions_helper";

// AvatarEditor
import AvatarEditor from "../../../components/Common/AvatarEditor";

// Service
import commonService from "../../../services/common.service";

// Import Breadcrumb
import Breadcrumbs from "../../../components/Layout/Breadcrumb";

import ScrollUp from "../../../components/ScrollUpDown/ScrollUp";
import ScrollDown from "../../../components/ScrollUpDown/ScrollDown";

import {
  filteredByFunction,
  getInitials,
  getOptionValueById,
  getUserProfile,
} from "../../../helpers/functions_helper";
import { useTranslation } from "react-i18next";

import Autocomplete from "react-google-autocomplete";
import Tooltip from "../../../components/Tooltip/Tooltip";
import { toast } from "react-toastify";
import mastersCompanyService from "../../../services/masters.company.service";
import { CommonAction } from "../../../store/actions";
import Drawer from "../../../components/Drawer";
import EditUser from "../../Users/EditUser";
import BanksData from "../../Users/Taxes/BanksData";
import Input from "../../../components/Form/Input";
import Select from '../../../components/Form/Select/Select';
import Flatpickr from "../../../components/Form/Flatpickr";

import { useCallbackPrompt } from '../../../hooks/useCallbackPrompt'
import Alert from "../../../components/Common/Alert";
import NavigationAlert from "../../../components/Common/NavigationAlert";

const phoneRegExp = /^(?:\+?\d{1}[ -]?[\d -][\d -]+)$/;
const NewCompany = (props: any) => {
  const {t} = useTranslation();
  document.title = `${t("Create Company")} | ${process.env.REACT_APP_NAME}`;
  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "/masters/company",
      label: t("Company"),
      active: true,
    },
    {
      url: "#",
      label: t("Create Company"),
      active: true,
    },
  ];
  
  const userProfile = getUserProfile("all-company");
  const toastId = React.useRef(null) as any;

  const validationSchema = Yup.object().shape({
    custome_code: Yup.string()
      .required("Custome code field is required.")
      .min(3, "Custome code must be at least 3 characters.")
      .max(15, "Custome code may not be greater than 15 characters."),
    company_name: Yup.string()
      .required("Client name field is required.")
      .min(2, "Client name must be at least 2 characters.")
      .max(200, "Client name may not be greater than 200 characters."),
    email_address: Yup.string()
      .max(100, "Email may not be greater than 100 characters.")
      .email("Email must be a valid email address.")
      .nullable(),
    legal_entity: Yup.string()
      .max(100, "Legal entity not be greater than 100 characters.")
      .nullable(),
    phone_number_1: Yup.string()
      // .required("The phone number field is required.")
      .matches(phoneRegExp, "Contact number is not valid.")
      .min(8, "Contact number must be at least 8 digits.")
      .max(15, "Contact number field accept only 15 digits.")
      .nullable(),
    phone_number_2: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid.")
      .min(8, "Contact number (Alternative) must be at least 8 digits.")
      .max(15, "Contact number (Alternative) field accept only 15 digits.")
      .when(["phone_number_1"], {
        is: (phone_number_1: string) => phone_number_1 != "" && phone_number_1 != null,
        then: Yup.string().notOneOf(
          [Yup.ref("phone_number_1")],
          "Contact number (Alternative) and alternative can not be same."
        ),
      })
      .nullable(),
    file_manager: Yup.string().required("File manager field is required."),
    signing_partner: Yup.string().required("Partner field is required."),
    listing_status_id: Yup.string().required("Listing status field is required."),

    // company_address: Yup.string()
    //   .required("The company address field is required.")
    //   .max(100, "The company address not be greater than 100 characters."),
    // office_number: Yup.string()
    //   .required("The office number is required.")
    //   .max(10, "The office number may not be greater than 10 characters."),
    // box: Yup.string()
    //   .max(10, "The box number may not be greater than 10 characters.")
    //   .nullable(),
    // postal_code: Yup.string()
    //   .required("The postal code field is required.")
    //   .max(30, "The postal code may not be greater than 30 characters."),
    // city: Yup.string()
    //   .required("The city field is required.")
    //   .max(100, "The city may not be greater than 100 characters."),
    // country: Yup.string().required("The country field is required."),

    // inv_company_address: Yup.string()
    //   .required("The company address field is required.")
    //   .max(100, "The company address not be greater than 100 characters."),
    // inv_office_number: Yup.string()
    //   .required("The office number is required.")
    //   .max(10, "The office number may not be greater than 10 characters."),
    // inv_box: Yup.string()
    //   .max(10, "The box number may not be greater than 10 characters.")
    //   .nullable(),
    // inv_postal_code: Yup.string()
    //   .required("The postal code field is required.")
    //   .max(30, "The postal code may not be greater than 30 characters."),
    // inv_city: Yup.string()
    //   .required("The city field is required.")
    //   .max(100, "The city may not be greater than 100 characters."),
    // inv_country: Yup.string().required("The country field is required."),

    invoice_payable_period: Yup.number()
      .required("Payable period (Invoice) field is required.")
      .typeError("You must specify number of days")
      .integer()
      .min(1, "Payable period (Invoice) should be greater than 0.")
      .max(365, "Payable period (Invoice) field accept only up to 365 days."),

    short_description_activities: Yup.string()
      .max(500, "Short description of activities may not be greater than 500 characters.")
      .nullable(),
    stuation_of_the_belgian_entity: Yup.string()
      .max(500, "Situation of the Belgian entity within the international group may not be greater than 500 characters.")
      .nullable(),
    additional_info: Yup.string()
      .max(500, "Additional information on the company may not be greater than 500 characters.")
      .nullable(),

    number_Of_employees: Yup.number()
      .typeError("Number of employees must be a numeric.")
      .integer()
      .nullable(),
    TVW_Nr: Yup.string()
      .min(4, t("The TVA/BTW must be at least 4 characters."))
      .max(18, t("The TVA/BTW number may not be greater than 18 characters."))
      .test("validator-custom-name", function (value) {
        if (validateVat.valid === false) {
          return this.createError({
            path: this.path,
            message: t("The TVA/BTW number is not valid."),
          });
        } else {
          return true;
        }
      })
      .nullable(),
  });
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [openUser, setOpenUser] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any>([]);
  const [insertId, setInsertId] = useState(0);
  const [insertSubId, setInsertSubId] = useState("");
  const [redirectCode, setRedirectCode] = useState("");
  const [bankData, setBankData] = useState<any>([]);

  const localFormData = handleAutoSave("GET");
  const [autoSave, setAutoSave] = useState(false);
  const [autoSaveAlert, setAutoSaveAlert] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(autoSave);
  
  const [initialData, setInitialData] = useState({
    id: "",
    company_id: "",
    code: "",
    custome_code: "",
    company_logo: "",
    company_name: props?.company || "",
    color_code: "#000000",
    company_category: "",
    language: "1",
    email_address: props?.email || "",
    legal_entity: "",
    phone_number_1: "",
    phone_number_2: "",
    fax_nr_company: "",

    preparer: "",
    reviewer: "",
    file_manager: "",
    signing_partner: "",
    assistant_administrative: "",

    category: "",
    account: [] as any[],
    status: "1",
    listing_status_id: "2",

    company_address: props?.address || "",
    office_number: "",
    box: "",
    postal_code: "",
    city: "",
    region: "",
    country: "21",

    inv_like_company_address: 0,
    inv_company_address: "",
    inv_office_number: "",
    inv_box: "",
    inv_postal_code: "",
    inv_city: "",
    inv_region: "",
    inv_country: "21",

    contact_person: "",
    contact_function: "",
    invoice_contact_person: "",
    invoice_payable_period: 90,
    hr_contact_person: "",
    gen_contact_person: "",

    bank_data: [],
    bank_name_prime: "",
    bank_address_prime: "",
    bank_address_number: "",
    bank_address_box: "",
    bank_address_postal_code: "",
    bank_address_commune: "",
    bank_address_city: "",
    bank_address_country: "",
    IBAN_Nr_prime: "",
    bic: "",

    short_description_activities: "",
    stuation_of_the_belgian_entity: "",
    additional_info: "",
    website: "",
    external_web_link: "",

    number_Of_employees: "",
    TVW_Nr: props?.TVW_Nr || "",
    company_profile: "",
    primary_industry_type: "",
    company_registration_id: "",
    is_archive: 0,
    start_date: moment().format("YYYY-MM-DD hh:mm:ss"),
    end_date: "",
    submit_btn_code: "save_add_new",
    vatcat: "",
    vat_percentage: "21",
    vat_code: "211400",
    to_apply_vat: 1,
    reason_for_vat: 1,
    process_trigger: 0,
  });

  const [disableInvCompanyAddress, setDisableInvCompanyAddress] = useState(false);
  const handleInvLikeCompanyAddress = () => {
    if (initialData.inv_like_company_address === 1) {
      setInitialData({
        ...initialData,
        inv_like_company_address: 0,
      });
      setDisableInvCompanyAddress(false);
    } else {
      setInitialData({
        ...initialData,
        inv_like_company_address: 1,
        inv_company_address: initialData.company_address,
        inv_office_number: initialData.office_number,
        inv_box: initialData.box,
        inv_postal_code: initialData.postal_code,
        inv_city: initialData.city,
        inv_country: initialData.country,
      });
      setDisableInvCompanyAddress(true);
    }
  };

  useEffect(() => {
    if (initialData.inv_like_company_address === 1) {
      setInitialData({
        ...initialData,
        inv_company_address: initialData.company_address,
        inv_office_number: initialData.office_number,
        inv_box: initialData.box,
        inv_postal_code: initialData.postal_code,
        inv_city: initialData.city,
        inv_region: initialData.region,
        inv_country: initialData.country,
      });
    }
  }, [
    initialData.inv_like_company_address,
    initialData.company_address,
    initialData.office_number,
    initialData.box,
    initialData.postal_code,
    initialData.city,
    initialData.region,
    initialData.country,
  ]);

  const [listingStatus, setListingStatus] = useState<any>([]);
  const [statusList, setStatusList] = useState<any>([]);
  const [yesNoList, setYesNoList] = useState<any>([]);
  const [reasonsList, setReasonsList] = useState<any>([]);
  const [languageList, setLanguageList] = useState<any>([]);
  useEffect(() => {
      if(listingStatusOptions){
          const listOptions = listingStatusOptions?.map((option: any) => ({
              label: t(option.label),
              value: option.value
            }));
            setListingStatus(listOptions);
      }

      if(languageOptions){
        const options = languageOptions?.map((option: any) => ({
          label: t(option.label),
          value: option.value
        }));
        setLanguageList(options);
      }

      if(statusOptions){
          const Options = statusOptions?.map((option: any) => ({
              label: t(option.label),
              value: option.value
            }));
            setStatusList(Options);
      }

      if(YesNoMaster){
        const Options = YesNoMaster?.map((option: any) => ({
            label: t(option.label),
            value: option.value
          }));
          setYesNoList(Options);
    }

    if(vatReasonOptions){
      const Options = vatReasonOptions?.map((option: any) => ({
          label: t(option.label),
          value: option.value
        }));
        setReasonsList(Options);
  }
      
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  useEffect(() => {
    if (initialData.bank_data) {
      let bank_data: any = [];
      if (initialData?.bank_data?.length === 1) {
        bank_data = initialData?.bank_data[0];
      } else {
        bank_data = initialData?.bank_data?.find((x: any) => x?.is_prime === true);

        if (!bank_data) {
          bank_data = initialData?.bank_data[initialData?.bank_data?.length - 1];
        }
      }

      setInitialData({
        ...initialData,
        bank_name_prime: bank_data?.bank_name_prime,
        bank_address_prime: bank_data?.bank_address_prime,
        bank_address_number: bank_data?.bank_address_number,
        bank_address_box: bank_data?.bank_address_box,
        bank_address_postal_code: bank_data?.bank_address_postal_code,
        bank_address_commune: bank_data?.bank_address_commune,
        bank_address_city: bank_data?.bank_address_city,
        bank_address_country: bank_data?.bank_address_country,
        IBAN_Nr_prime: bank_data?.iban_number_prime,
        bic: bank_data?.bic_number_prime,
      });
    }
  }, [initialData.bank_data]);

  //Validate vat number
  const [validateVat, setValidateVat] = useState<any>({
    valid: null,
    loading: false,
  });
  const handleValidate = async (vatNr: any) => {
    setValidateVat((state: any) => ({ ...state, loading: true }));

    const res = await commonService.validateVatNr(vatNr);
    if (res?.data?.status === "success") {
      if (res?.data?.data?.valid) {
        setValidateVat((state: any) => ({ ...state, valid: true }));
        if (vatNr.includes("BE")) {
          handleChange("vatcat", 1);
        } else {
          handleChange("vatcat", 2);
        }
      }
    } else {
      setValidateVat((state: any) => ({ ...state, valid: false }));
    }
    setValidateVat((state: any) => ({ ...state, loading: false }));
  };
  useEffect(() => {
    setValidateVat({
      valid: null,
      loading: false,
    });
  }, [initialData.TVW_Nr]);
  //Validate vat number

  const handleRedirect = (isCancel = false) => {
    setAutoSave(false);
    handleAutoSave("CLEAR");

    if (isCancel === true) {
      return navigate("/masters/company");
    }

    if (redirectCode === "save_add_new") {
      // return navigate("/masters/company/new-company?redirect=true");
      updateInitialData();
      window.scrollTo(0, 0);
    } else if (redirectCode === "save_close") {
      return navigate("/masters/company?redirect=true");
    } else if (redirectCode === "save") {
      return navigate("/masters/company/company-details/" + insertId + "/" + insertSubId + "?redirect=true");
    } else {
      return navigate("/masters/company?redirect=true");
    }
  };

  useEffect(() => {
    if (redirectCode !== "") {
      handleRedirect();
    }
  }, [redirectCode]);

  const handleChange = (fieldName: string, fieldValue: any) => {
    setInitialData({ ...initialData, [fieldName]: fieldValue });
    setAutoSave(true);
  };

  const handleAddressChange = async (data: any) => {
    const addressData = await handleAddress(data);
    setInitialData((prevData: any) => ({
      ...prevData,
      company_address: addressData?.address,
      office_number: addressData?.address_number.substring(0, 10),
      box: addressData?.box.substring(0, 10),
      postal_code: addressData?.postal_code,
      city: addressData?.commune,
      region: addressData?.city,
      country: addressData?.country,
    }));
  };
  const handleInvAddressChange = async (data: any) => {
    const addressData = await handleAddress(data);
    setInitialData((prevData: any) => ({
      ...prevData,
      inv_company_address: addressData?.address,
      inv_office_number: addressData?.address_number.substring(0, 10),
      inv_box: addressData?.box.substring(0, 10),
      inv_postal_code: addressData?.postal_code,
      inv_city: addressData?.commune,
      inv_region: addressData?.city,
      inv_country: addressData?.country,
    }));
  };
  const handleAddressChangeBank = async (data: any) => {
    const addressData = await handleAddress(data);
    setInitialData((prevData: any) => ({
      ...prevData,
      bank_address_prime: addressData?.address,
      bank_address_number: addressData?.address_number.substring(0, 10),
      bank_address_box: addressData?.box.substring(0, 10),
      bank_address_postal_code: addressData?.postal_code,
      bank_address_commune: addressData?.commune,
      bank_address_city: addressData?.city,
      bank_address_country: addressData?.country,
    }));
  };

  const create = async (values: companyPropsType) => {
    try {
      setLoading(true);
      toastId.current = toast(t("Creating company..."), {
        autoClose: false,
        isLoading: true,
      });

      const res = (await mastersCompanyService.createCompany(values)) as any;
      if (res.data.status === "success") {
        setInsertId(res.data.data.id);
        setInsertSubId(res.data.data.company_id);
        setRedirectCode(res.data.data.submit_btn_code);

        dispatch(CommonAction.getCompanyOptionsListSuccess(""));
        dispatch(CommonAction.getCompanyOptionsListSuccess_New(""));

        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        toast(t("Please update contact persons!"), {
          type: toast.TYPE.WARNING,
          autoClose: 5000,
          delay: 1000,
        });

        setLoading(false);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        if (res.data.data) {
          Object.keys(res.data.data).map((key) => {
            toast(res.data.data[key][0], {
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              delay: 500,
            });
          });
        }
        setLoading(false);
      }
    } catch (err: any) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      create(values);
    },
  });

  useEffect(() => {
    if (!validation.isSubmitting) return;
    if (Object.keys(validation.errors).length > 0) {
      document
        .getElementById(Object.keys(validation.errors)[0])
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
      document.getElementById(Object.keys(validation.errors)[0])?.focus({ preventScroll: true });
    }
  }, [validation.isSubmitting, validation.errors]);

  const [profileReset, setProfileReset] = useState(false);
  function updateInitialData() {
    const updatedInitialData = {
      ...initialData,
      id: "",
      company_id: "",
      code: "",
      custome_code: "",
      company_logo: "",
      company_name: "",
      color_code: "#000000",
      company_category: "",
      language: "1",
      email_address: "",
      legal_entity: "",
      phone_number_1: "",
      phone_number_2: "",
      fax_nr_company: "",

      preparer: "",
      reviewer: "",
      file_manager: "",
      signing_partner: "",
      assistant_administrative: "",

      category: "",
      account: accountOptions
        .filter((option: any) => option.label === "HR")
        .map((i: any) => i.value),
      status: "1",
      listing_status_id: "2",

      company_address: "",
      office_number: "",
      box: "",
      postal_code: "",
      city: "",
      region: "",
      country: "21",

      inv_like_company_address: 0,
      inv_company_address: "",
      inv_office_number: "",
      inv_box: "",
      inv_postal_code: "",
      inv_city: "",
      inv_region: "",
      inv_country: "21",

      contact_person: "",
      contact_function: "",
      invoice_contact_person: "",
      invoice_payable_period: 90,
      hr_contact_person: "",
      gen_contact_person: "",

      bank_data: [],
      bank_name_prime: "",
      bank_address_prime: "",
      bank_address_number: "",
      bank_address_box: "",
      bank_address_postal_code: "",
      bank_address_commune: "",
      bank_address_city: "",
      bank_address_country: "",
      IBAN_Nr_prime: "",
      bic: "",

      short_description_activities: "",
      stuation_of_the_belgian_entity: "",
      additional_info: "",
      website: "",
      external_web_link: "",

      number_Of_employees: "",
      TVW_Nr: "",
      company_profile: "",
      primary_industry_type: "",
      company_registration_id: "",
      is_archive: 0,
      start_date: moment().format("YYYY-MM-DD hh:mm:ss"),
      end_date: "",
      submit_btn_code: "save_add_new",
    };
    setInitialData(updatedInitialData);
    setProfileReset(true);
    setLoadingCode(true);
    window.scrollTo(0, 0);
  }

  // Generate custome code API call -------------------------------------------------
  const [loadingCode, setLoadingCode] = useState(true);
  const getCustomeCode = async (tableName: string, moduleCode: string) => {
    const response = await commonService.getCustomeCode(tableName, moduleCode, 7);
    return response.data.data;
  };
  useEffect(() => {
    if (loadingCode === true) {
      getCustomeCode("tbl_master_company", "CMP").then(function (result) {
        const updatedInitialData = {
          ...initialData,
          code: result,
          custome_code: result,
        };
        setInitialData(updatedInitialData);
        setLoadingCode(false);
      });
    }
  }, [loadingCode]);

  // Get options list -------------------------------------------------
  const [loadingCountry, setLoadingCountry] = useState(true);
  const [countryOptions, setCountryOptions] = useState<Array<any>>([]);
  useEffect(() => {
    getSelectOptions("tbl_master_country", "name", "", [
      "is_europian_country",
      "name",
      "name_french",
      "name_dutch",
    ]).then(function (options) {
      let countryOptionsList = filterCountryByLanguage(options);
      setCountryOptions(countryOptionsList);
      setLoadingCountry(false);
    });
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  const [accountOptions, setAccountOptions] = useState([]);
  const [vatcatOptions, setVatcatOptions] = useState([]);

  useEffect(() => {
    getSelectOptions("tbl_master_account", "account_name").then(function (options) {
      setAccountOptions(options);
    });
    getSelectOptions("tbl_vat_cat", "vatcat_name").then(function (options) {
      setVatcatOptions(options);
    });
    updateInitialData();
  }, []);

  const [loadingCategory, setLoadingCategory] = useState(true);
  const [categoryOptions, setCategoryOptions] = useState([]);
  useEffect(() => {
    getSelectOptions("tbl_master_company_category", "category_value").then(function (options) {
      setCategoryOptions(options);
      setLoadingCategory(false);
    });
  }, []);

  const [loadingProfile, setLoadingProfile] = useState(true);
  const [profileOptions, setProfileOptions] = useState([]);
  useEffect(() => {
    setLoadingProfile(true);

    let language = "";
    if (parseInt(initialData.language) === 2) {
      language = "_french";
    }
    if (parseInt(initialData.language) === 3) {
      language = "_dutch";
    }
    getSelectOptions("tbl_master_company_profile", "company_profile_value" + language).then(
      function (options) {
        setProfileOptions(options);
        setLoadingProfile(false);
      }
    );
  }, [initialData.language]);

  let { getUserOptionsList } = useSelector((state: any) => ({
    getUserOptionsList: state.Common.GET_USER_OPTIONS_LIST_SUCCESS_NEW,
  }));

  useEffect(() => {
    if (!getUserOptionsList) dispatch(CommonAction.getUserOptionsList_New());
  }, []);

  const [fileManagerOptions, setFileManagerOptions] = useState([]);
  const [partnerOptions, setPartnerOptions] = useState<Array<any>>([]);
  const [assistantAdministrativeOptions, setAssistantAdministrativeOptions] = useState<Array<any>>(
    []
  );

  useEffect(() => {
    if (getUserOptionsList.data) {
      setFileManagerOptions(getUserOptionsList.data);

      let partnerData = filteredByFunction(getUserOptionsList.data, [6]) as any; // Partners
      setPartnerOptions(partnerData);

      let assistantData = filteredByFunction(getUserOptionsList.data, [1]) as any; // Assistant
      setAssistantAdministrativeOptions(assistantData);
    }
  }, [getUserOptionsList]);

  // Profile image ------------------------------------------------------
  const onAvatarChange = (base64Data: string) => {
    if (base64Data) {
      initialData.company_logo = base64Data;
      setProfileReset(false);
    }
  };
  // Profile image ------------------------------------------------------

  useEffect(() => {
    if (partnerOptions?.length > 0 && initialData?.signing_partner) {
      const name =
        partnerOptions?.find((x: any) => x?.value === initialData?.signing_partner)?.label || " ";
      handleChange("category", getInitials(name, false));
    }
  }, [initialData?.signing_partner]);

  useEffect(() => {
    if (countryOptions?.length > 0 && initialData?.inv_country) {
      const isEuropean = countryOptions?.find(
        (x: any) => x?.value == initialData?.inv_country
      )?.is_europian_country;
      const vatData = calculateVatData(initialData?.inv_country, isEuropean, initialData.TVW_Nr);
      if (validateVat.valid !== false)
        setInitialData((intData: any) => ({
          ...intData,
          ...vatData,
        }));
    }
  }, [initialData.inv_country, validateVat.valid, countryOptions?.length]);

  const handleUserClick = (type: number) => {
    let user_id = 0;
    switch (type) {
      // Preparer
      case 1:
        user_id = getOptionValueById(fileManagerOptions, parseInt(initialData.preparer), "user_id");
        setSelectedUser({ id: initialData.preparer, user_id });
        setOpenUser(true);
        break;

      // Reviewer
      case 2:
        user_id = getOptionValueById(fileManagerOptions, parseInt(initialData.reviewer), "user_id");
        setSelectedUser({ id: initialData.reviewer, user_id });
        setOpenUser(true);
        break;

      // File manager
      case 3:
        user_id = getOptionValueById(
          fileManagerOptions,
          parseInt(initialData.file_manager),
          "user_id"
        );
        setSelectedUser({ id: initialData.file_manager, user_id });
        setOpenUser(true);
        break;

      // Partner
      case 4:
        user_id = getOptionValueById(
          partnerOptions,
          parseInt(initialData.signing_partner),
          "user_id"
        );
        setSelectedUser({ id: initialData.signing_partner, user_id });
        setOpenUser(true);
        break;

      // Assistant administrative
      case 5:
        user_id = getOptionValueById(
          assistantAdministrativeOptions,
          parseInt(initialData.assistant_administrative),
          "user_id"
        );
        setSelectedUser({ id: initialData.assistant_administrative, user_id });
        setOpenUser(true);
        break;
    }
  };

  useEffect(() => {
    if (localFormData && localFormData?.initialData) {
      setInitialData(localFormData?.initialData);
      setAutoSaveAlert(true);
    }
  }, []);

  useEffect(() => {
    if (autoSave) { handleAutoSave("SET", initialData); }
  }, [initialData, autoSave]);

  return (
    <React.Fragment>
      <ScrollUp />
      <ScrollDown top={"100px"} />

      <Alert 
        open={autoSaveAlert} 
        title={t("Autosaved data restored!")} 
        message={t("Your last autosaved data has been restored from the session. In order to avoid the data loss, you need either save it to retain the data or Cancel to loose the data.")} 
        onClickOk={() => setAutoSaveAlert(false)} 
      />
      <NavigationAlert
        open={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />

      <div className={props?.inTab ? "" : "page-content"}>
        <Container fluid>
          {!props?.inTab && (
            <Breadcrumbs breadcrumbTitle={t("Create Company")} breadcrumbItems={breadcrumbItems} />
          )}

          <Row>
            <Col xl="12">
              <Card>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                  autoComplete="off"
                >
                  <Input id="id" name="id" type="hidden" value={initialData.id} />
                  <Input
                    id="company_id"
                    name="company_id"
                    type="hidden"
                    value={initialData.company_id}
                  />
                  <Input id="code" name="code" type="hidden" value={initialData.code} />

                  <CardBody>
                    <Row>
                      <Col xl={3} lg={3} md={3} sm={12}>
                        {AvatarEditor ? (
                          <AvatarEditor
                            profileImage={null}
                            profileReset={profileReset}
                            onAvatarChange={onAvatarChange}
                          />
                        ) : null}
                      </Col>

                      <Col xl={9} lg={9} md={9} sm={12}>
                        <Row>
                          <Col xl={4} lg={4} md={4} sm={12}>
                            <div className="mb-3">
                              <Input
                                id="custome_code"
                                name="custome_code"
                                placeholder={t("Account ID")}
                                label="Account ID"
                                required
                                type="text"
                                maxLength={10}
                                value={initialData.custome_code}
                                readOnly={loadingCode}
                                onBlur={validation.handleBlur}
                                onChange={(e) => handleChange("custome_code", e.target.value)}
                                invalid={
                                  validation.touched.custome_code && validation.errors.custome_code
                                    ? true
                                    : false
                                }
                                error={validation.errors.custome_code}
                                touched={validation.touched.custome_code}
                              />
                              {loadingCode ? <Spinner /> : ""}
                            </div>
                          </Col>

                          <Col className="col-8">
                            <div className="mb-3">
                              <div className="color_palette_main_div">
                                <Input
                                  id="company_name"
                                  name="company_name"
                                  className="form-control"
                                  placeholder={t("Client name")}
                                  label="Client name"
                                  required
                                  type="text"
                                  value={initialData.company_name}
                                  autoFocus={true}
                                  onBlur={validation.handleBlur}
                                  onChange={(e) => handleChange("company_name", e.target.value)}
                                  invalid={
                                    validation.touched.company_name &&
                                      validation.errors.company_name
                                      ? true
                                      : false
                                  }
                                  error={validation.errors.company_name}
                                  touched={validation.touched.company_name}
                                />
                                <div className="color_palette_div">
                                  <Input
                                    id="color_code"
                                    name="color_code"
                                    type="color"
                                    value={initialData.color_code}
                                    className="color_palette"
                                    onChange={(e) => handleChange("color_code", e.target.value)}
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>

                          <Col className="col-4">
                            <div className="mb-3">
                              <Select
                                id="company_category"
                                name="company_category"
                                className="is-invalid"
                                label="Client category"
                                options={categoryOptions}
                                value={
                                  initialData.company_category
                                    ? categoryOptions.find(
                                      (option: any) =>
                                        option.value == initialData.company_category
                                    )
                                    : ""
                                }
                                isSearchable={true}
                                isLoading={loadingCategory}
                                onBlur={() => validation.setFieldTouched("company_category", true)}
                                onChange={(e: any) => {
                                  handleChange("company_category", e.value);
                                }}
                                error={validation.errors.company_category}
                                touched={validation.touched.company_category}
                              />
                            </div>
                          </Col>

                          <Col className="col-4">
                            <div className="mb-3">
                              <Select
                                id="language"
                                name="language"
                                className="is-invalid"
                                label="Language"
                                required
                                options={languageList}
                                value={
                                  validation.values.language
                                    ? languageList?.find(
                                      (option: any) => option.value == validation.values.language
                                    )
                                    : ""
                                }
                                isSearchable={false}
                                onBlur={() => validation.setFieldTouched("language", true)}
                                onChange={(e: any) => {
                                  handleChange("language", e.value);
                                }}
                                error={validation.errors.language}
                                touched={validation.touched.language}
                              />
                            </div>
                          </Col>

                          <Col className="col-4">
                            <div className="mb-3">
                              <Input
                                id="email_address"
                                name="email_address"
                                placeholder={t("Email")}
                                label="Email"
                                type="email"
                                value={initialData.email_address}
                                onBlur={validation.handleBlur}
                                onChange={(e) => handleChange("email_address", e.target.value)}
                                invalid={
                                  validation.touched.email_address &&
                                    validation.errors.email_address
                                    ? true
                                    : false
                                }
                                error={validation.errors.email_address}
                                touched={validation.touched.email_address}
                              />
                            </div>
                          </Col>

                          <Col className="col-4">
                            <div className="mb-3">
                              <Input
                                id="legal_entity"
                                name="legal_entity"
                                type="text"
                                placeholder={t("Legal entity")}
                                label="Legal entity"
                                value={initialData.legal_entity}
                                onBlur={validation.handleBlur}
                                onChange={(e) => handleChange("legal_entity", e.target.value)}
                                invalid={
                                  validation.touched.legal_entity && validation.errors.legal_entity
                                    ? true
                                    : false
                                }
                                error={validation.errors.legal_entity}
                                touched={validation.touched.legal_entity}
                              />
                            </div>
                          </Col>

                          <Col className="col-4">
                            <div className="mb-3">
                              <Input
                                id="phone_number_1"
                                name="phone_number_1"
                                placeholder={t("Contact number")}
                                label="Contact number"
                                type="text"
                                value={initialData.phone_number_1}
                                onBlur={validation.handleBlur}
                                onChange={(e) => handleChange("phone_number_1", e.target.value)}
                                invalid={
                                  validation.touched.phone_number_1 &&
                                    validation.errors.phone_number_1
                                    ? true
                                    : false
                                }
                                error={validation.errors.phone_number_1}
                                touched={validation.touched.phone_number_1}
                              />
                            </div>
                          </Col>

                          <Col className="col-4">
                            <div className="mb-3">
                              <Input
                                id="phone_number_2"
                                name="phone_number_2"
                                placeholder={t("Contact number (Alternative)")}
                                label="Contact number (Alternative)"
                                type="text"
                                value={initialData.phone_number_2}
                                onBlur={validation.handleBlur}
                                onChange={(e) => handleChange("phone_number_2", e.target.value)}
                                invalid={
                                  validation.touched.phone_number_2 &&
                                    validation.errors.phone_number_2
                                    ? true
                                    : false
                                }
                                error={validation.errors.phone_number_2}
                                touched={validation.touched.phone_number_2}
                              />
                            </div>
                          </Col>

                          <Col className="col-4">
                            <div className="mb-3">
                              <Input
                                id="fax_nr_company"
                                name="fax_nr_company"
                                placeholder={t("FAX number")}
                                label="FAX number"
                                type="text"
                                value={initialData.fax_nr_company}
                                onBlur={validation.handleBlur}
                                onChange={(e) => handleChange("fax_nr_company", e.target.value)}
                              />
                            </div>
                          </Col>
                          <Col className="col-4">
                            <div className="mb-3">
                              <Select
                                id="company_profile"
                                name="company_profile"
                                className="is-invalid"
                                options={profileOptions}
                                label="Client profile"
                                value={
                                  initialData.company_profile
                                    ? profileOptions.find(
                                      (option: any) => option.value == initialData.company_profile
                                    )
                                    : ""
                                }
                                isSearchable={true}
                                isLoading={loadingProfile}
                                isDisabled={loadingProfile}
                                onBlur={() => validation.setFieldTouched("company_profile", true)}
                                onChange={(e: any) => {
                                  handleChange("company_profile", e.value);
                                }}
                                error={validation.errors.company_profile}
                                touched={validation.touched.company_profile}
                              />
                            </div>
                          </Col>

                          <Col className="col-4">
                            <div className="mb-3">
                              <Input
                                name="primary_industry_type"
                                className="form-control"
                                placeholder={t("Paritary committee")}
                                label="Paritary committee"
                                type="text"
                                value={initialData.primary_industry_type}
                                onBlur={validation.handleBlur}
                                onChange={(e) =>
                                  handleChange("primary_industry_type", e.target.value)
                                }
                              />
                            </div>
                          </Col>

                          <Col className="col-4">
                            <div className="mb-3">
                              <Input
                                id="company_registration_id"
                                name="company_registration_id"
                                className="form-control"
                                placeholder={t("Registration ID")}
                                label="Registration ID"
                                type="text"
                                maxLength={50}
                                value={initialData.company_registration_id}
                                onBlur={validation.handleBlur}
                                onChange={(e) =>
                                  handleChange("company_registration_id", e.target.value)
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label">
                            {initialData.preparer ? (
                              <Link
                                to="#"
                                onClick={() => handleUserClick(1)}
                                className="text-decoration-underline"
                              >
                                {t("Preparer")}
                              </Link>
                            ) : (
                              t("Preparer")
                            )}
                          </Label>

                          <Select
                            id="preparer"
                            name="preparer"
                            className="is-invalid"
                            options={fileManagerOptions}
                            value={
                              initialData.preparer
                                ? fileManagerOptions.find(
                                  (option: any) => option.value == initialData.preparer
                                )
                                : ""
                            }
                            isSearchable={true}
                            onBlur={() => validation.setFieldTouched("preparer", true)}
                            onChange={(e: any) => {
                              handleChange("preparer", e.value);
                            }}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label">
                            {initialData.reviewer ? (
                              <Link
                                to="#"
                                onClick={() => handleUserClick(2)}
                                className="text-decoration-underline"
                              >
                                {t("Reviewer")}
                              </Link>
                            ) : (
                              t("Reviewer")
                            )}
                          </Label>

                          <Select
                            id="reviewer"
                            name="reviewer"
                            className="is-invalid"
                            options={fileManagerOptions}
                            value={
                              initialData.reviewer
                                ? fileManagerOptions.find(
                                  (option: any) => option.value == initialData.reviewer
                                )
                                : ""
                            }
                            isSearchable={true}
                            onBlur={() => validation.setFieldTouched("reviewer", true)}
                            onChange={(e: any) => {
                              handleChange("reviewer", e.value);
                            }}
                            
                            error={validation.errors.reviewer}
                            touched={validation.touched.reviewer}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label">
                            {initialData.file_manager ? (
                              <Link
                                to="#"
                                onClick={() => handleUserClick(3)}
                                className="text-decoration-underline"
                              >
                                {t("File manager")}
                              </Link>
                            ) : (
                              t("File manager")
                            )}{" "}
                            <span className="text-warning is_required">*</span>
                          </Label>

                          <Select
                            id="file_manager"
                            name="file_manager"
                            className="is-invalid"
                            options={fileManagerOptions}
                            value={
                              initialData.file_manager
                                ? fileManagerOptions.find(
                                  (option: any) => option.value == initialData.file_manager
                                )
                                : ""
                            }
                            isSearchable={true}
                            onBlur={() => validation.setFieldTouched("file_manager", true)}
                            onChange={(e: any) => {
                              handleChange("file_manager", e.value);
                            }}
                            error={validation.errors.file_manager}
                            touched={validation.touched.file_manager}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label">
                            {initialData.signing_partner ? (
                              <Link
                                to="#"
                                onClick={() => handleUserClick(4)}
                                className="text-decoration-underline"
                              >
                                {t("Partner")}
                              </Link>
                            ) : (
                              t("Partner")
                            )}{" "}
                            <span className="text-warning is_required">*</span>
                          </Label>

                          <Select
                            id="signing_partner"
                            name="signing_partner"
                            className="is-invalid"
                            options={partnerOptions}
                            value={
                              initialData.signing_partner
                                ? partnerOptions.find(
                                  (option: any) => option.value == initialData.signing_partner
                                )
                                : ""
                            }
                            isSearchable={true}
                            onBlur={() => validation.setFieldTouched("signing_partner", true)}
                            onChange={(e: any) => {
                              handleChange("signing_partner", e.value);
                            }}
                            error={validation.errors.signing_partner}
                            touched={validation.touched.signing_partner}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Label className="form-label">
                            {initialData.assistant_administrative ? (
                              <Link
                                to="#"
                                onClick={() => handleUserClick(4)}
                                className="text-decoration-underline"
                              >
                                {t("Assistant administrative")}
                              </Link>
                            ) : (
                              t("Assistant administrative")
                            )}
                          </Label>

                          <Select
                            id="assistant_administrative"
                            name="assistant_administrative"
                            className="is-invalid"
                            options={assistantAdministrativeOptions}
                            value={
                              initialData.assistant_administrative
                                ? assistantAdministrativeOptions.find(
                                  (option: any) =>
                                    option.value == initialData.assistant_administrative
                                )
                                : ""
                            }
                            isSearchable={true}
                            onBlur={() =>
                              validation.setFieldTouched("assistant_administrative", true)
                            }
                            onChange={(e: any) => {
                              handleChange("assistant_administrative", e.value);
                            }}
                            error={validation.errors.assistant_administrative}
                            touched={validation.touched.assistant_administrative}
                          />
                        </div>
                      </Col>

                      {userProfile.IS_INTERNAL && (
                        <>
                          <Col className="col-3">
                            <div className="mb-3">
                              <Input
                                id="category"
                                name="category"
                                placeholder={t("Category")}
                                label="Category"
                                value={initialData.category}
                                readOnly
                                onBlur={() => validation.setFieldTouched("category", true)}
                              />
                            </div>
                          </Col>

                          <Col className="col-3">
                            <div className="mb-3">

                              <Select
                                id="account"
                                name="account"
                                className="is-invalid"
                                options={accountOptions}
                                isMulti={true}
                                label="Account"
                                value={
                                  initialData.account.length
                                    ? accountOptions.filter((option: any) =>
                                      initialData.account?.includes(option.value)
                                    )
                                    : ""
                                }
                                isSearchable={true}
                                onBlur={() => validation.setFieldTouched("account", true)}
                                onChange={(e: any) => {
                                  handleChange(
                                    "account",
                                    e?.map((i: any) => i.value)
                                  );
                                }}
                                error={validation.errors.account}
                                touched={validation.touched.account}
                              />
                            </div>
                          </Col>
                        </>
                      )}

                      <Col className="col-3">
                        <div className="mb-3">
                          <Select
                            id="status"
                            name="status"
                            label="Status"
                            options={statusList}
                            value={
                              initialData.status
                                ? statusList?.find(
                                  (option: any) => option.value == initialData.status
                                )
                                : ""
                            }
                            isSearchable={false}
                            onBlur={() => validation.setFieldTouched("status", true)}
                            onChange={(e: any) => {
                              handleChange("status", e.value);
                            }}
                            
                            error={validation.errors.status}
                            touched={validation.touched.status}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Select
                            id="listing_status_id"
                            name="listing_status_id"
                            className="is-invalid"
                            label="Listing status"
                            required
                            options={listingStatus}
                            value={
                              initialData.listing_status_id
                                ? listingStatus?.find(
                                  (option: any) => option.value == initialData.listing_status_id
                                )
                                : ""
                            }
                            isSearchable={false}
                            onBlur={() => validation.setFieldTouched("listing_status_id", true)}
                            onChange={(e: any) => {
                              handleChange("listing_status_id", e.value);
                            }}
                            error={validation.errors.listing_status_id}
                            touched={validation.touched.listing_status_id}
                          />
                        </div>
                      </Col>

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <Col className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Client address")}
                            {/* {" "}
                            <span className="text-warning is_required">*</span> */}
                          </Label>
                          {/* <Input
                            id="company_address"
                            name="company_address"
                            className="form-control"
                            placeholder="Company address"
                            type="textarea"
                            maxLength={200}
                            value={initialData.company_address}
                            onBlur={validation.handleBlur}
                            onChange={(e) =>
                              handleChange("company_address", e.target.value)
                            }
                            invalid={
                              validation.touched.company_address &&
                                validation.errors.company_address
                                ? true
                                : false
                            }
                          /> */}
                          <Autocomplete
                            id="company_address"
                            name="company_address"
                            className={"form-control"}
                            value={initialData.company_address}
                            onBlur={validation.handleBlur}
                            placeholder={t("Client address")}
                            onChange={(e: any) => handleChange("company_address", e.target.value)}
                            apiKey={process.env.REACT_APP_GOOGLE_ADDRESS_API_KEY!}
                            options={{
                              types: ["geocode", "establishment"],
                            }}
                            onPlaceSelected={(data: any) => {
                              handleAddressChange(data);
                            }}
                          />

                          {validation.touched.company_address &&
                            validation.errors.company_address ? (
                            <FormFeedback type="invalid">
                              {validation.errors.company_address}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Input
                            id="office_number"
                            name="office_number"
                            className="form-control"
                            placeholder={t("Office number")}
                            label="Office number"
                            type="text"
                            maxLength={10}
                            value={initialData.office_number}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("office_number", e.target.value)}
                            invalid={
                              validation.touched.office_number && validation.errors.office_number
                                ? true
                                : false
                            }
                            error={validation.errors.office_number}
                            touched={validation.touched.office_number}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Input
                            id="box"
                            name="box"
                            className="form-control"
                            placeholder={t("Box number")}
                            label="Box number"
                            type="text"
                            maxLength={10}
                            value={initialData.box}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("box", e.target.value)}
                            invalid={validation.touched.box && validation.errors.box ? true : false}
                            error={validation.errors.box}
                            touched={validation.touched.box}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Input
                            id="postal_code"
                            name="postal_code"
                            className="form-control"
                            label="Postal code"
                            placeholder={t("Postal code")}
                            type="text"
                            maxLength={30}
                            value={initialData.postal_code}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("postal_code", e.target.value)}
                            invalid={
                              validation.touched.postal_code && validation.errors.postal_code
                                ? true
                                : false
                            }
                            error={validation.errors.postal_code}
                            touched={validation.touched.postal_code}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Input
                            id="city"
                            name="city"
                            className="form-control"
                            label="City"
                            placeholder={t("City")}
                            type="text"
                            maxLength={100}
                            value={initialData.city}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("city", e.target.value)}
                            invalid={
                              validation.touched.city && validation.errors.city ? true : false
                            }
                            error={validation.errors.city}
                            touched={validation.touched.city}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Input
                            id="region"
                            name="region"
                            className="form-control"
                            label="Region"
                            placeholder={t("Region")}
                            type="text"
                            maxLength={100}
                            value={initialData.region}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("region", e.target.value)}
                            invalid={
                              validation.touched.region && validation.errors.region ? true : false
                            }
                            error={validation.errors.region}
                            touched={validation.touched.region}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Select
                            id="country"
                            name="country"
                            className="is-invalid"
                            label="country"
                            options={countryOptions}
                            value={
                              initialData.country
                                ? countryOptions.find(
                                  (option: any) => option.value == initialData.country
                                )
                                : ""
                            }
                            isSearchable={true}
                            isDisabled={loadingCountry}
                            isLoading={loadingCountry}
                            onBlur={() => validation.setFieldTouched("country", true)}
                            onChange={(e: any) => {
                              handleChange("country", e.value);
                            }}
                            error={validation.errors.country}
                            touched={validation.touched.country}
                          />
                        </div>
                      </Col>

                      <Col className="col-12">
                        <br />
                      </Col>

                      <Col className="col-12">
                        <Label className="form-label">
                          <div className="form-check float-start">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="inv_like_company_address"
                              value={initialData.inv_like_company_address}
                              checked={initialData.inv_like_company_address === 1 ? true : false}
                              onBlur={validation.handleBlur}
                              onChange={handleInvLikeCompanyAddress}
                            />
                            <label className="form-check-label" htmlFor="inv_like_company_address">
                              {t("Same as a Client address")}
                            </label>
                          </div>
                        </Label>
                      </Col>

                      <Col className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Client address (Invoice)")}
                            {/* {" "}
                            <span className="text-warning is_required">*</span> */}
                          </Label>
                          {/* <Input
                            id="inv_company_address"
                            name="inv_company_address"
                            className="form-control"
                            placeholder="Company address"
                            type="textarea"
                            value={initialData.inv_company_address}
                            onBlur={validation.handleBlur}
                            onChange={(e) =>
                              handleChange(
                                "inv_company_address",
                                e.target.value
                              )
                            }
                            readOnly={disableInvCompanyAddress}
                            invalid={
                              validation.touched.inv_company_address &&
                                validation.errors.inv_company_address
                                ? true
                                : false
                            }
                          /> */}
                          <Autocomplete
                            id="inv_company_address"
                            name="inv_company_address"
                            className={"form-control"}
                            value={initialData.inv_company_address}
                            onBlur={validation.handleBlur}
                            onChange={(e: any) =>
                              handleChange("inv_company_address", e.target.value)
                            }
                            placeholder={t("Client address (Invoice)")}
                            readOnly={disableInvCompanyAddress}
                            apiKey={process.env.REACT_APP_GOOGLE_ADDRESS_API_KEY!}
                            options={{
                              types: ["geocode", "establishment"],
                            }}
                            onPlaceSelected={(data: any) => {
                              handleInvAddressChange(data);
                            }}
                          />

                          {validation.touched.inv_company_address &&
                            validation.errors.inv_company_address ? (
                            <FormFeedback type="invalid">
                              {validation.errors.inv_company_address}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Input
                            id="inv_office_number"
                            name="inv_office_number"
                            className="form-control"
                            placeholder={t("Office number")}
                            label="Office number"
                            type="text"
                            maxLength={10}
                            value={initialData.inv_office_number}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("inv_office_number", e.target.value)}
                            readOnly={disableInvCompanyAddress}
                            invalid={
                              validation.touched.inv_office_number &&
                                validation.errors.inv_office_number
                                ? true
                                : false
                            }
                            error={validation.errors.inv_office_number}
                            touched={validation.touched.inv_office_number}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Input
                            id="inv_box"
                            name="inv_box"
                            className="form-control"
                            placeholder={t("Box number")}
                            label="Box number"
                            type="text"
                            maxLength={10}
                            value={initialData.inv_box}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("inv_box", e.target.value)}
                            readOnly={disableInvCompanyAddress}
                            invalid={
                              validation.touched.inv_box && validation.errors.inv_box ? true : false
                            }
                            error={validation.errors.inv_box}
                            touched={validation.touched.inv_box}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Input
                            id="inv_postal_code"
                            name="inv_postal_code"
                            className="form-control"
                            placeholder={t("Postal code")}
                            label="Postal code"
                            type="text"
                            maxLength={30}
                            value={initialData.inv_postal_code}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("inv_postal_code", e.target.value)}
                            readOnly={disableInvCompanyAddress}
                            invalid={
                              validation.touched.inv_postal_code &&
                                validation.errors.inv_postal_code
                                ? true
                                : false
                            }
                            error={validation.errors.inv_postal_code}
                            touched={validation.touched.inv_postal_code}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Input
                            id="inv_city"
                            name="inv_city"
                            className="form-control"
                            placeholder={t("City")}
                            label="City"
                            type="text"
                            maxLength={100}
                            value={initialData.inv_city}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("inv_city", e.target.value)}
                            readOnly={disableInvCompanyAddress}
                            invalid={
                              validation.touched.inv_city && validation.errors.inv_city
                                ? true
                                : false
                            }
                            error={validation.errors.inv_city}
                            touched={validation.touched.inv_city}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Input
                            id="inv_region"
                            name="inv_region"
                            className="form-control"
                            placeholder={t("Region")}
                            label="Region"
                            type="text"
                            maxLength={100}
                            value={initialData.inv_region}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("inv_region", e.target.value)}
                            readOnly={disableInvCompanyAddress}
                            invalid={
                              validation.touched.inv_region && validation.errors.inv_region ? true : false
                            }
                            error={validation.errors.inv_region}
                            touched={validation.touched.inv_region}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Select
                            id="inv_country"
                            name="inv_country"
                            className="is-invalid"
                            label="Country"
                            options={countryOptions}
                            value={
                              initialData.inv_country
                                ? countryOptions.find(
                                  (option: any) => option.value == initialData.inv_country
                                )
                                : ""
                            }
                            isSearchable={true}
                            isDisabled={loadingCountry ? loadingCountry : disableInvCompanyAddress}
                            isLoading={loadingCountry}
                            onBlur={() => validation.setFieldTouched("inv_country", true)}
                            onChange={(e: any) => {
                              handleChange("inv_country", e.value);
                            }}
                          />
                        </div>
                      </Col>

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Input
                            id="contact_person"
                            name="contact_person"
                            className="form-control"
                            label="Contact person (Company)"
                            placeholder={t("Contact person (Company)")}
                            type="text"
                            readOnly={true}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Input
                            id="contact_function"
                            name="contact_function"
                            className="form-control"
                            placeholder={t("Function")}
                            label="Function"
                            type="text"
                            readOnly={true}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Input
                            id="invoice_contact_person"
                            name="invoice_contact_person"
                            className="form-control"
                            placeholder={t("Contact person (Invoice)")}
                            label="Contact person (Invoice)"
                            type="text"
                            readOnly={true}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Input
                            id="invoice_payable_period"
                            name="invoice_payable_period"
                            className="form-control"
                            placeholder={t("Payable period (Invoice)")}
                            label="Payable period (Invoice)"
                            required
                            type="text"
                            value={initialData.invoice_payable_period}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("invoice_payable_period", e.target.value)}
                            invalid={
                              validation.touched.invoice_payable_period &&
                                validation.errors.invoice_payable_period
                                ? true
                                : false
                            }
                            error={validation.errors.invoice_payable_period}
                            touched={validation.touched.invoice_payable_period}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Input
                            id="hr_contact_person"
                            name="hr_contact_person"
                            className="form-control"
                            placeholder={t("Contact person (HR)")}
                            label="Contact person (HR)"
                            type="text"
                            readOnly={true}
                          />
                        </div>
                      </Col>

                      <Col className="col-3">
                        <div className="mb-3">
                          <Input
                            id="gen_contact_person"
                            name="gen_contact_person"
                            className="form-control"
                            placeholder={t("Contact person (General)")}
                            label="Contact person (General)"
                            type="text"
                            readOnly={true}
                          />
                        </div>
                      </Col>

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <BanksData
                        data={bankData}
                        onChange={(data: any) => handleChange("bank_data", data)}
                      />

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <Col className="col-12">
                        <div className="mb-3">
                          <Input
                            id="short_description_activities"
                            name="short_description_activities"
                            className="form-control"
                            // placeholder="Short description of activities"
                            label="Short description of activities"
                            type="textarea"
                            value={initialData.short_description_activities}
                            onBlur={validation.handleBlur}
                            onChange={(e) =>
                              handleChange("short_description_activities", e.target.value)
                            }
                            invalid={
                              validation.touched.short_description_activities &&
                                validation.errors.short_description_activities
                                ? true
                                : false
                            }
                            error={validation.errors.short_description_activities}
                            touched={validation.touched.short_description_activities}
                          />
                        </div>
                      </Col>

                      <Col className="col-12">
                        <div className="mb-3">
                          <Input
                            id="stuation_of_the_belgian_entity"
                            name="stuation_of_the_belgian_entity"
                            className="form-control"
                            label="Situation of the Belgian entity within the international group"
                            // placeholder="Situation of the Belgian entity within the international group"
                            type="textarea"
                            value={initialData.stuation_of_the_belgian_entity}
                            onBlur={validation.handleBlur}
                            onChange={(e) =>
                              handleChange("stuation_of_the_belgian_entity", e.target.value)
                            }
                            invalid={
                              validation.touched.stuation_of_the_belgian_entity &&
                                validation.errors.stuation_of_the_belgian_entity
                                ? true
                                : false
                            }
                            error={validation.errors.stuation_of_the_belgian_entity}
                            touched={validation.touched.stuation_of_the_belgian_entity}
                          />
                        </div>
                      </Col>

                      <Col className="col-12">
                        <div className="mb-3">
                          <Input
                            id="additional_info"
                            name="additional_info"
                            className="form-control"
                            label="Additional information on the company"
                            // placeholder="Additional information on the company"
                            type="textarea"
                            value={initialData.additional_info}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("additional_info", e.target.value)}
                            invalid={
                              validation.touched.additional_info &&
                                validation.errors.additional_info
                                ? true
                                : false
                            }
                            error={validation.errors.additional_info}
                            touched={validation.touched.additional_info}
                          />
                        </div>
                      </Col>

                      <Col className="col-6">
                        <div className="mb-3">
                          <Input
                            id="website"
                            name="website"
                            className="form-control"
                            placeholder={t("Website")}
                            label="Website"
                            type="text"
                            value={initialData.website}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("website", e.target.value)}
                          />
                        </div>
                      </Col>

                      <Col className="col-6">
                        <div className="mb-3">
                          <Input
                            id="external_web_link"
                            name="external_web_link"
                            className="form-control"
                            placeholder={t("External web link")}
                            label="External web link"
                            type="text"
                            maxLength={500}
                            value={initialData.external_web_link}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("external_web_link", e.target.value)}
                          />
                        </div>
                      </Col>

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Input
                            id="number_Of_employees"
                            name="number_Of_employees"
                            className="form-control"
                            placeholder={t("Number of employees")}
                            label="Number of employees"
                            type="text"
                            value={initialData.number_Of_employees}
                            onBlur={validation.handleBlur}
                            onChange={(e) => handleChange("number_Of_employees", e.target.value)}
                            invalid={
                              validation.touched.number_Of_employees &&
                                validation.errors.number_Of_employees
                                ? true
                                : false
                            }
                            error={validation.errors.number_Of_employees}
                            touched={validation.touched.number_Of_employees}
                          />
                        </div>
                      </Col>

                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                        <Label className="form-label">
                            {t("TVA/BTW number")}&nbsp;
                            <Tooltip content={`${t("Format VAT")}: BE0123456789`} direction="top">
                              <i className="fa-regular fas fa-info-circle"></i>
                            </Tooltip>
                          </Label>
                          <InputGroup>
                            <InputNew
                              id="TVW_Nr"
                              name="TVW_Nr"
                              className="form-control"
                              placeholder={t("TVA/BTW number")}
                              label={t("TVA/BTW number")}
                              type="text"
                              mask="999-999-9999"
                              valid={validateVat.valid}
                              value={initialData.TVW_Nr}
                              onBlur={validation.handleBlur}
                              onChange={(e) => {
                                handleChange("TVW_Nr", e.target.value);
                              }}
                              invalid={
                                (validation.touched.TVW_Nr && validation.errors.TVW_Nr) ||
                                  validateVat.valid === false
                                  ? true
                                  : false
                              }
                            />
                            <Button
                              type="button"
                              disabled={
                                initialData.TVW_Nr === "" ||
                                validateVat.loading ||
                                validateVat.valid
                              }
                              outline
                              onClick={() => handleValidate(initialData.TVW_Nr)}
                              color={validateVat.valid ? "success" : "secondary"}
                            >
                              {validateVat.loading
                                ? t("Loading")
                                : validateVat.valid
                                  ? t("Valid")
                                  : t("Validate")}
                            </Button>
                          </InputGroup>
                          {validation.touched.TVW_Nr && validation.errors.TVW_Nr ? (
                            <FormFeedback type="invalid">{validation.errors.TVW_Nr}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      {userProfile.IS_INTERNAL && (
                        <>
                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <Select
                                id="vatcat"
                                name="vatcat"
                                className="is-invalid"
                                placeholder={t("VAT Category")}
                                label="VAT Category"
                                options={vatcatOptions}
                                value={
                                  validation.values?.vatcat
                                    ? vatcatOptions.find(
                                      (option: any) => option.value == validation.values.vatcat
                                    )
                                    : ""
                                }
                                isSearchable={false}
                                onBlur={() => validation.setFieldTouched("vatcat", true)}
                                onChange={(e: any) => {
                                  handleChange("vatcat", e.value);
                                }}
                                error={validation.errors.vatcat}
                                touched={validation.touched.vatcat}
                              />
                            </div>
                          </Col>

                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <Input
                                id="vat_percentage"
                                name="vat_percentage"
                                value={initialData.vat_percentage}
                                label="Vat Percentage"
                                readOnly
                                onChange={(e: any) => {
                                  handleChange("vat_percentage", e.target.value);
                                }}
                              />
                            </div>
                          </Col>
                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <Input
                                id="vat_code"
                                name="vat_code"
                                label="Vat Code"
                                value={initialData.vat_code}
                                readOnly
                                onChange={(e: any) => {
                                  handleChange("vat_code", e.target.value);
                                }}
                              />
                            </div>
                          </Col>
                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <Select
                                id="to_apply_vat"
                                name="to_apply_vat"
                                className="is-invalid"
                                label="To Apply Vat"
                                options={yesNoList}
                                value={
                                  validation.values?.to_apply_vat
                                    ? yesNoList?.find(
                                      (option: any) =>
                                        option.value == validation.values.to_apply_vat
                                    )
                                    : ""
                                }
                                isSearchable={false}
                                onBlur={() => validation.setFieldTouched("to_apply_vat", true)}
                                onChange={(e: any) => {
                                  handleChange("to_apply_vat", e.value);
                                }}
                                error={validation.errors.to_apply_vat}
                                touched={validation.touched.to_apply_vat}
                              />
                             
                            </div>
                          </Col>
                          <Col xl={3} lg={3} md={3} sm={12}>
                            <div className="mb-3">
                              <Select
                                id="reason_for_vat"
                                name="reason_for_vat"
                                className="is-invalid"
                                label="Reason"
                                options={reasonsList}
                                value={
                                  validation.values?.reason_for_vat
                                    ? reasonsList?.find(
                                      (option: any) =>
                                        option.value == validation.values.reason_for_vat
                                    )
                                    : ""
                                }
                                isSearchable={false}
                                onBlur={() => validation.setFieldTouched("reason_for_vat", true)}
                                onChange={(e: any) => {
                                  handleChange("reason_for_vat", e.value);
                                }}
                                error={validation.errors.reason_for_vat}
                                touched={validation.touched.reason_for_vat}
                              />
                            </div>
                          </Col>
                        </>
                      )}

                      <Col className="col-12"></Col>

                      {/* {userProfile.IS_INTERNAL && (
                        <Col xl={2} lg={2} md={2} sm={12}>
                          <div className="mb-3">
                            <Label className="form-label">Archive</Label>
                            <div className="form-check mb-2">
                              <Input
                                id="is_archive_yes"
                                name="is_archive"
                                value="1"
                                className="form-check-input"
                                type="radio"
                                checked={initialData.is_archive == 1 ? true : false}
                                onChange={() => handleChange("is_archive", 1)}
                              />
                              <label className="form-check-label" htmlFor="is_archive_yes">
                                Yes
                              </label>
                            </div>

                            <div className="form-check mb-2">
                              <Input
                                id="is_archive_no"
                                name="is_archive"
                                value="0"
                                className="form-check-input"
                                type="radio"
                                checked={initialData.is_archive == 0 ? true : false}
                                onChange={() => handleChange("is_archive", 0)}
                              />
                              <label className="form-check-label" htmlFor="is_archive_no">
                                No
                              </label>
                            </div>
                          </div>
                        </Col>
                      )}

                      <Col className="col-12"></Col> */}

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      <Col xl={6} lg={6} md={6} sm={12}>
                        <div className="mb-3">
                          <div className="input-group is-invalid">
                            <Flatpickr
                              id="start_date"
                              name="start_date"
                              className="form-control d-block"
                              label="Start date"
                              required
                              type="text"
                              value={initialData.start_date}
                              placeholder="DD/MM/YYYY"
                              options={{
                                altInput: true,
                                altFormat: "d/m/Y",
                                dateFormat: "Y-m-d H:i:S",
                                // minDate: "today",
                                allowInput: true,
                                plugins: [labelPlugin()],
                                onChange: function (selectedDates, dateStr, instance) {
                                  handleChange("start_date", dateStr);
                                },
                                onClose: function (selectedDates, dateStr, instance) {
                                  handleChange("start_date", dateStr);
                                },
                                locale: {...datePickerI18(), firstDayOfWeek: 1 },
                              }}
                              error={validation.errors.start_date}
                              touched={validation.touched.start_date}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col xl={6} lg={6} md={6} sm={12}>
                        <div className="mb-3">
                          <div className="input-group is-invalid">
                            <Flatpickr
                              id="end_date"
                              name="end_date"
                              className="form-control d-block"
                              type="text"
                              value={initialData.end_date}
                              label="End date"
                              placeholder="DD/MM/YYYY"
                              options={{
                                altInput: true,
                                altFormat: "d/m/Y",
                                dateFormat: "Y-m-d H:i:S",
                                minDate: initialData.start_date,
                                allowInput: true,
                                plugins: [labelPlugin()],
                                onChange: function (selectedDates, dateStr, instance) {
                                  handleChange("end_date", dateStr);
                                },
                                onClose: function (selectedDates, dateStr, instance) {
                                  handleChange("end_date", dateStr);
                                },
                                locale: { ...datePickerI18(),firstDayOfWeek: 1 },
                              }}
                              error={validation.errors.end_date}
                              touched={validation.touched.end_date}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>

                  <CardFooter className="d-sm-flex flex-wrap bg-transparent border-top">
                    <div className="d-flex gap-2"></div>

                    <div className="ms-auto d-flex gap-2 justify-content-end">
                      {userProfile.CAN_CREATE ? (
                        <>
                          <Button
                            color="primary"
                            className="btn-block"
                            type="submit"
                            onClick={() => handleChange("submit_btn_code", "save_add_new")}
                            disabled={loading}
                          >
                            {t("Save & Add new")}
                          </Button>

                          <Button
                            color="primary"
                            className="btn-block"
                            type="submit"
                            onClick={() => handleChange("submit_btn_code", "save_close")}
                            disabled={loading}
                          >
                            {t("Save & Close")}
                          </Button>

                          <Button
                            color="primary"
                            className="btn-block"
                            type="submit"
                            onClick={() => handleChange("submit_btn_code", "save")}
                            disabled={loading}
                          >
                            {t("Save")}
                          </Button>

                          <Button
                            color="danger"
                            className="btn-block"
                            type="reset"
                            onClick={() => {
                              handleRedirect(true);
                            }}
                            disabled={loading}
                            loading={loading}
                          >
                            {t("Cancel")}
                          </Button>
                        </>
                      ) : null}
                    </div>
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>

        <Drawer
          title={t("User")}
          open={openUser}
          onClose={() => setOpenUser((state) => !state)}
          fullWidth
        >
          <EditUser
            id={selectedUser?.id}
            user_id={selectedUser?.user_id}
            type={1}
            isWindow={true}
          />
        </Drawer>
      </div>
    </React.Fragment>
  );
};

export default NewCompany;
