import React, { useEffect, useState } from 'react';
import { Col, Nav, NavItem, Button } from 'reactstrap';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

type DocStatusProps = {
    value?: any;
    status?: any;
    e_date?: any,
    due_date?: any,
    evaluation_value?: any,
    evaluation_status?: any,
    evaluation_e_date?: any,
    evaluation_due_date?: any,
}

const DocStatus = (props: DocStatusProps) => {
    const {t} = useTranslation();
    const [danger, setDanger] = useState(0);
    const [warning, setWarring] = useState(0);
    const [success, setSuccess] = useState(0);
    const { e_date, due_date, status, value, evaluation_value, evaluation_status, evaluation_e_date, evaluation_due_date } = props;

    const [evaluationSuccess, setEvaluationSuccess] = useState(0);
    const [evaluationWarning, setEvaluationWarning] = useState(0);
    const [evaluationDanger, setEvaluatioDanger] = useState(0);

    // status change
    //signed , sent. - danger
    // signed , submitted. - warning
    // signed, finalized. - success
    // Not signed, sent - Danger
    // Not signed - submitted. - Danger
    // Not signed - finalized. - Danger
    // Refused - finalized - Danger
    // SENT = 3, SUBMITTED. - 4 , FINALISED =5
    // Signed- 1, Not signed- 2, Refused =3, Cancelled =4, Expired=5

    const calenderCheck  = () =>{
        //Danger condition 
        const one_week_less = moment().subtract(1, 'weeks').format('YYYY-M-D');
        const one_month_less = moment().subtract(1, 'month').subtract(1,'weeks').format('YYYY-M-D');
        //#TODO month compare
        //const one_month_less = moment().subtract(1, 'month').format('YYYY-M-D');
        if ((new Date(due_date) < new Date(one_week_less) && due_date != null) || (new Date(e_date) < new Date(one_month_less) && e_date != null) || (status < 5 && status != 0) && value>1) {
            setDanger(1);
            setSuccess(0);
        }

        //Warring condition
        const one_month_less_w = moment().subtract(1, 'month').format('YYYY-M-D');
        const two_month_less_w = moment().subtract(2, 'month').format('YYYY-M-D');

        if ((new Date(due_date) < new Date(one_month_less_w) && due_date != null) || (new Date(e_date) < new Date(two_month_less_w) && e_date != null) || (status < 5 && status != 0) && value>1) {
            setWarring(1);
            setDanger(0);
            setSuccess(0);
        }
    }    
    const showDocStatus = () => {
        setDanger(0);
        setWarring(0);
        setSuccess(0);   
        // if(value == "1" && status == "5"){
        //     setDanger(0);
        //     setWarring(0);
        //     setSuccess(1);
        //     return;
        // }

        
        let statusvalue =null;
        switch (value) {
            case '1': 
                statusvalue =  (status == "3" || status == "4")?  setWarring(1): (status == "5")? setSuccess(1) : setDanger(1) ;
                calenderCheck();
                break;
            case '2': 
                statusvalue =   (status == "3" || status == "4")?  setWarring(1): (status == "5")? setDanger(1) : setDanger(1) ;
                break;
            
            case '3':
                setDanger(1);
                break
        
            default:
                break;
        }

    }

    useEffect(() => {
        showDocStatus();
    }, [status, due_date,e_date,value])


    useEffect(()=>{
        setEvaluatioDanger(0);
        setEvaluationSuccess(0);
        setEvaluationWarning(0);
        if(evaluation_value==1){
            if(evaluation_status == "1"){
                setEvaluationWarning(1);
            }else if(evaluation_status == "2"){
                setEvaluationSuccess(1);
            }else if(evaluation_status == "3"){
                setEvaluatioDanger(1);
            }
        }else if(evaluation_value==2){
            if(evaluation_status == "1"){
                setEvaluationWarning(1);
            }else if(evaluation_status == "3"){
                setEvaluatioDanger(1);
            }
        }
    },[evaluation_status, evaluation_value, evaluation_e_date, evaluation_due_date]);





    return (
        <Col className="col-12 justify-content-end">
            <Nav className="nav justify-content-end">
                <NavItem>
                    {
                        danger == 1 &&
                        <Button className={`btn btn-danger btn-label btn-sm`}>
                            <i className="fas fa-light fa-ban label-icon"></i>{t("Danger")}
                        </Button>
                    } {" "}
                    {

                        warning == 1 &&
                        <Button
                            className={`btn btn-warning btn-label btn-sm`}>
                            <i className="fas fa-light fa-triangle-exclamation label-icon"></i>{t("Warning")}
                        </Button>
                    }
                    {" "}
                    {
                        (props.value == "1" && props.status == "5" && warning != 1 && danger!=1) &&
                        <Button
                            className={`btn btn-success btn-label btn-sm`}>
                            <i className="fas fa-light fa-check-double label-icon"></i>{t("Success")}
                        </Button>
                    }
                    {" "}
                    {
                        evaluationDanger == 1 &&
                        <Button className={`btn btn-danger btn-label btn-sm`}>
                            <i className="fas fa-light fa-ban label-icon"></i>{t("Danger")}
                        </Button>
                    } {" "}
                    {

                        evaluationWarning == 1 &&
                        <Button
                            className={`btn btn-warning btn-label btn-sm`}>
                            <i className="fas fa-light fa-triangle-exclamation label-icon"></i>{t("Warning")}
                        </Button>
                    }
                    {" "}
                    {
                        (evaluationSuccess==1) &&
                        <Button
                            className={`btn btn-success btn-label btn-sm`}>
                            <i className="fas fa-light fa-check-double label-icon"></i>{t("Success")}
                        </Button>
                    }
                    {" "}
                </NavItem>
            </Nav>
        </Col>
    )
}

export default DocStatus;

