import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class QuestionnaireService {
    async getQuestionnaireDatatableList(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_QUESTIONNAIRE_DATATABLE_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            );
        return response;
    }

    async getQuestionnaireList(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_QUESTIONNAIRE_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            );
        return response;
    }

    async getQuestionnaireDatatableExport(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_QUESTIONNAIRE_DATATABLE_EXPORT, DATA,
                {
                    headers: getAxiosConfigWithToken(),
                    responseType: "blob",
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getQuestionnaireFulldata(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_QUESTIONNAIRE_FULL_DATA, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            );
        return response;
    }


    async createQuestionnaireStatus(DATA: any) {
        const response = await axios
            .put(SERVER_URL + url.CREATE_QUESTIONNAIRE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    // async updateQuestionnaireStatus(DATA: any) {
    //     const response = await axios
    //         .post(SERVER_URL + url.UPDATE_QUESTIONNAIRE, DATA,
    //             {
    //                 headers: getAxiosConfigWithToken()
    //             }
    //         ).then(function (response) {
    //             return response;
    //         })
    //         .catch(function (error) {
    //             return error;
    //         });
    //         return response;
    // }

    async deleteQuestionnaire(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.DELETE_QUESTIONNAIRE, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }


    async getAnalytics(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(
                SERVER_URL + url.GET_QUESTIONNAIRE_ANALYTICS + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            );

        return response.data;
    }

    async AllTypes(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(SERVER_URL + url.ALL_TYPES + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }


    async getYearData(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(SERVER_URL + url.GET_YEAR_DATA + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getMoreCharts(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(SERVER_URL + url.GET_CHART_DATA + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async DisplayQuestionButton(taskID: any, userID: any) {
        const response = await axios
            .get(SERVER_URL + url.GET_QUESTIONNAIRE_DATA + '/' + taskID + '/' + userID,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }


    async sendQuestionnaires(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.SEND_QUESTIONNAIRES_TASK, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }


    async uploadSignature(data: any) {
        const response = await axios
            .post(
                SERVER_URL + url.UPLOAD_SIGNATURE,
                data,
                {
                    headers: { ...getAxiosConfigWithToken() }
                }
            );

        return response.data;
    }

    async onClickTerminate(data: any) {
        const response = await axios
            .post(
                SERVER_URL + url.TERMINATE_QUESTIONNAIRE,
                data,
                {
                    headers: { ...getAxiosConfigWithToken() }
                }
            );

        return response.data;
    }


    async redirectURL(token: any) {
        const response = await axios
            .post(
                SERVER_URL + url.REDIRECT_URL,
                token,
                {
                    headers: { ...getAxiosConfigWithToken() }
                }
            );

        return response.data;
    }

    async addCalendarData(data: any) {
        const response = await axios
            .post(
                SERVER_URL + url.ADD_CALENDER_DATA,
                data,
                {
                    headers: { ...getAxiosConfigWithToken() }
                }
            );

        return response.data;
    }


    async getCalendarData(data: any) {
        const response = await axios
            .post(
                SERVER_URL + url.GET_CALENDER_DATA,
                data,
                {
                    headers: { ...getAxiosConfigWithToken() }
                }
            );

        return response.data;
    }

    async addCalendarRangeData(data: any) {
        const response = await axios
            .post(
                SERVER_URL + url.ADD_CALENDER_RANGE_DATA,
                data,
                {
                    headers: { ...getAxiosConfigWithToken() }
                }
            );

        return response.data;
    }


    async getCalendarRangeData(data: any) {
        const response = await axios
            .post(
                SERVER_URL + url.GET_CALENDER_RANGE_DATA,
                data,
                {
                    headers: { ...getAxiosConfigWithToken() }
                }
            );

        return response.data;
    }

    async addUserCalenderData(data: any) {
        const response = await axios
            .post(
                SERVER_URL + url.ADD_USER_CALENDER_DATA,
                data,
                {
                    headers: { ...getAxiosConfigWithToken() }
                }
            );

        return response.data;
    }


    async addTaskfromWorkDay(data: any) {
        const response = await axios
            .post(
                SERVER_URL + url.ADD_WORK_DAY_CALENDER,
                data,
                {
                    headers: { ...getAxiosConfigWithToken() }
                }
            );

        return response.data;
    }



    async removeCalendarRangeData(data: any) {
        const response = await axios
            .post(
                SERVER_URL + url.REMOVE_USER_CALENDER_DATA,
                data,
                {
                    headers: { ...getAxiosConfigWithToken() }
                }
            );

        return response.data;
    }





}

export default new QuestionnaireService();