import React, { memo, useCallback } from 'react';
import { Link } from "react-router-dom"
import Tooltip from '../../components/Tooltip/Tooltip';

type TaskAssigneeProps = {
    assignee: string,
    status: number,
    user: string,
}

const TaskAssignee = ({ assignee, status, user }: TaskAssigneeProps) => {

    const statusClass = useCallback(() => {
        switch (user) {
            case 'p':
                return (status === 1 || status === 2) ? 'bg-primary' : 'bg-secondary';
            case 'r':
                return status === 3 ? 'bg-primary' : 'bg-secondary';
            case 'fm':
                return status === 4 ? 'bg-primary' : 'bg-secondary';
            case 'pr':
                return status === 5 ? 'bg-secondary' : 'bg-secondary';
            case 'aa':
                return status === 5 ? 'bg-secondary' : 'bg-secondary';
            case 'as':
                return status === 5 ? 'bg-primary' : 'bg-secondary';
            default:
                return 'bg-secondary';
        }
    }, [user])

    return (
        <Tooltip content={assignee} direction="bottom">
            <Link to="#" className="d-inline-block" style={{ 'marginRight': '2px' }}>
                <div className="avatar-xs" style={{ 'height': '1.4rem', 'width': '1.4rem' }}>
                    <span className={`avatar-title rounded-circle text-white font-size-12 text-uppercase ${statusClass()}`}>
                        {assignee?.charAt(0)}{assignee?.charAt(assignee.lastIndexOf(' ')+1)}
                    </span>
                </div>
            </Link>
        </Tooltip>
    )
}

export default memo(TaskAssignee);