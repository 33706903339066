import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';

type SecondaryType = {
    label: string;
    value: any;
    to?: string | null;
}
interface WidgetBasicType {
    label: string;
    value: any;
    icon: any;
    variant?: 'primary' | 'secondary' | 'info' | 'warning' | 'danger' | 'success' | 'default';
    to?: string | null;
    loading?: boolean;
    seconday?: null | Array<SecondaryType>
};

const WidgetBasic: FC<WidgetBasicType> = ({ variant = 'default', label, value, icon, to = null, loading = false, seconday = null }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleClick = (link: string | null | undefined) => {
        link && navigate(link)
    }
    return (
        <Card className={`mini-stats-wid bg-${variant} bg-gradient border-0 bg-soft ${to ? 'cursor-pointer' : ''}`} style={{ cursor: (to && value) ? 'pointer' : 'default', height: 'calc(100% - 24px)' }} onClick={() => handleClick(to)}>
            <CardBody>
                <div className={`text-${variant}`}>
                    <Row className='mb-3'>
                        <Col className='d-flex flex-wrap'>
                            <div className="me-3">
                                <p className={`mb-2 text-${variant} font-size-16`}>{t(label)}</p>
                                <h5 className={`mb-0 text-${variant}`}>{loading ? <i className='fa fa-spinner fa-spin'></i> : value}</h5>
                            </div>
                            {icon &&
                                <div className="avatar-sm ms-auto">
                                    <div className={`avatar-title bg-light rounded-circle text-${variant === 'default' ? 'secondary' : variant} font-size-20`}>
                                        {icon}
                                    </div>
                                </div>
                            }
                        </Col>
                    </Row>

                    <Row>
                        {seconday && seconday?.map((item: SecondaryType, idx: number) => (
                            <Col key={idx}>
                                <div onClick={() => handleClick(item?.to)} className={`${item.to ? 'cursor-pointer' : ''} me-3`}>
                                    <p className={`mb-2 font-size-14 text-${variant}`}>{t(item?.label)}</p>
                                    <h6 className={`mb-0 text-${variant}`}>{loading ? <i className='fa fa-spinner fa-spin'></i> : item?.value}</h6>
                                </div>
                            </Col>
                        ))}
                    </Row>

                </div>
            </CardBody>
        </Card >
    )
}

export default WidgetBasic;