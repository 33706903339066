export const GET_INBOX_MAILS = "GET_INBOX_MAILS"
export const GET_INBOX_MAILS_SUCCESS = "GET_INBOX_MAILS_SUCCESS"
export const GET_INBOX_MAILS_FAIL = "GET_INBOX_MAILS_FAIL"

export const GET_FOLDER_LIST = "GET_FOLDER_LIST"
export const GET_FOLDER_LIST_SUCCESS = "GET_FOLDER_LIST_SUCCESS"
export const GET_FOLDER_LIST_FAIL = "GET_FOLDER_LIST_FAIL"

export const ADD_NEW_INBOX_MAIL = "ADD_NEW_INBOX_MAIL"
export const ADD_INBOX_MAIL_SUCCESS = "ADD_INBOX_MAIL_SUCCESS"
export const ADD_INBOX_MAIL_FAIL = "ADD_INBOX_MAIL_FAIL"

export const DELETE_INBOX_MAIL = "DELETE_INBOX_MAIL"
export const DELETE_INBOX_MAIL_SUCCESS = "DELETE_INBOX_MAIL_SUCCESS"
export const DELETE_INBOX_MAIL_FAIL = "DELETE_INBOX_MAIL_FAIL"

//starred mail
export const GET_STARRED_MAILS = "GET_STARRED_MAILS"
export const GET_STARRED_MAILS_SUCCESS = "GET_STARRED_MAILS_SUCCESS"
export const GET_STARRED_MAILS_FAIL = "GET_STARRED_MAILS_FAIL"


//important mail
export const GET_IMPORTANT_MAILS = "GET_IMPORTANT_MAILS"
export const GET_IMPORTANT_MAILS_SUCCESS = "GET_IMPORTANT_MAILS_SUCCESS"
export const GET_IMPORTANT_MAILS_FAIL = "GET_IMPORTANT_MAILS_FAIL"


//get sent mail
export const GET_SENT_MAILS = "GET_SENT_MAILS"
export const GET_SENT_MAILS_SUCCESS = "GET_SENT_MAILS_SUCCESS"
export const GET_SENT_MAILS_FAIL = "GET_SENT_MAILS_FAIL"

//draft mail
export const GET_DRAFT_MAILS = "GET_DRAFT_MAILS"
export const GET_DRAFT_MAILS_SUCCESS = "GET_DRAFT_MAILS_SUCCESS"
export const GET_DRAFT_MAILS_FAIL = "GET_DRAFT_MAILS_FAIL"


//trash mail
export const GET_TRASH_MAILS = "GET_TRASH_MAILS"
export const GET_TRASH_MAILS_SUCCESS = "GET_TRASH_MAILS_SUCCESS"
export const GET_TRASH_MAILS_FAIL = "GET_TRASH_MAILS_FAIL"


//trash mail
export const GET_JUNK_MAILS = "GET_JUNK_MAILS"
export const GET_JUNK_MAILS_SUCCESS = "GET_JUNK_MAILS_SUCCESS"
export const GET_JUNK_MAILS_FAIL = "GET_TRASH_MAILS_FAIL"

//Archive mail
export const GET_ARCHIVE_MAIL = "GET_ARCHIVE_MAIL"
export const GET_ARCHIVE_MAIL_SUCCESS = "GET_ARCHIVE_MAIL_SUCCESS"
export const GET_ARCHIVE_MAIL_FAIL = "GET_ARCHIVE_MAIL_FAIL"

//READ AND  UNREAD mail
export const SET_READ_UNREAD = "SET_READ_UNREAD"
export const SET_READ_UNREAD_SUCCESS = "SET_READ_UNREAD_SUCCESS"
export const SET_READ_UNREAD_FAIL = "SET_READ_UNREAD_FAIL"


//get folder message
export const GET_FOLDER_MESSAGE = "GET_FOLDER_MESSAGE"
export const GET_FOLDER_MESSAGE_SUCCESS = "GET_FOLDER_MESSAGE_SUCCESS"
export const GET_FOLDER_MESSAGE_FAIL = "GET_FOLDER_MESSAGE_FAIL"


//common email dialog box 
export const IS_EMAIL_DIALOG_BOX_OPEN ='IS_EMAIL_DIALOG_BOX_OPEN'
export const GET_DRAFT_EMAIL_DATA ='GET_DRAFT_EMAIL_DATA'


export const CLEAR_ERROR_DATA='CLEAR_ERROR_DATA';

export const SET_DATA_TABLE_ID='SET_DATA_TABLE_ID';

export const SET_DRAFT_MAIL="SET_DRAFT_MAIL";


