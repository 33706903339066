import React, { FC, useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardTitle } from "reactstrap";
import { formatCurrency } from "../../../utils/formats";
import invoicesService from "../../../services/invoices.service";
import { toast } from "react-toastify";
import { getUserProfile, select2Styles } from "../../../helpers/functions_helper";
import { options, yearOptions, currentYear, languageCodes } from "../../../common/staticdata";
import LoadingSpinner from "../../../components/Common/Loader";
import { noData } from "../../../utils/data";
import Chart from "../../../components/Chart";
import i18next from "i18next";
import ChartFilters from "../../../components/Chart/Filters";
import { serialize } from "../../../utils/utils";

type InvoicePerTaskTypeProps = {
    hideHeading?: boolean;
}
const InvoicePerTaskType: FC<InvoicePerTaskTypeProps> = ({ hideHeading = false }) => {

    const { t } = useTranslation();
    const userObj = getUserProfile('all-invoices');

    const inititalFilter = {
        week: null,
        quarter: null,
        month: null,
        year: currentYear,
        user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_ID : null
    };

    const [loadingText, setLoadingText] = useState<string>('No data found');
    const [loading, setLoading] = useState<boolean>(true);
    const [formValues, setFormValues] = useState<any>({
        invoice_per_task_type: currentYear
    })
    const [filter, setFilter] = useState<any>(inititalFilter);
    const [chartData, setChartData] = useState<any>({
        options: {
            chart: {
                height: 350,
                type: 'line',
                stacked: false,
                toolbar: {
                    show: false
                }
            },
            // chart: {
            //     height: 350,
            //     type: 'line',
            //     color: "#000"
            // },
            stroke: {
                width: [0, 4],
                curve: 'smooth'
            },

            dataLabels: {
                enabled: false,
                enabledOnSeries: [1],
                formatter: function (value: any, opts: any) {
                    return formatCurrency(value);
                },
            },
            labels: [],
            colors: ["#00e396", "#a12830"],

            // dataLabels: {
            //     enabled: false,
            //     enabledOnSeries: undefined,
            //     formatter: function (value: any, opts: any) {
            //         return formatCurrency(value);
            //     },
            // },
            yaxis: {
                type: 'string',
                labels: {
                    formatter: function (value: any) {
                        return formatCurrency(value);
                    }
                },
            },
            tooltip: {
                shared: true,
                intersect: false,
                custom: (opts: any) => {
                    const lang = languageCodes(i18next.language);
                    const x = opts?.w?.config?.series;
                    const type = opts?.w?.config?.series[0]?.description[lang][opts?.dataPointIndex];
                    const code_name = opts?.w?.config?.labels[opts?.dataPointIndex];
                    return `<div class="">
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item"><span class="font-size-15">${type}</span><span class="badge bg-warning ms-2">${code_name}</span></li>
                      <li class="list-group-item"><label>${t("Revenue")} ${x[0]?.name}:</label> ${typeof x[0]?.data[opts?.dataPointIndex] !== "undefined" ? formatCurrency(x[0]?.data[opts?.dataPointIndex]) : null}</li>
                      <li class="list-group-item"><label>${t("Revenue")} ${x[1]?.name}:</label> ${typeof x[1]?.data[opts?.dataPointIndex] !== "undefined" ? formatCurrency(x[1]?.data[opts?.dataPointIndex]) : null}</li>
                    </ul>
                  </div> `;
                },
                y: {
                    formatter: function (y: any) {
                        if (typeof y !== "undefined") {
                            return formatCurrency(y);
                        }
                        return y;

                    }
                },

            },

            noData: noData
        },
        series: [],
    });

    const getInvoicePerTask = async (range: any) => {
        try {
            const res = await invoicesService.getInvoicePerTask(range);
            if (res.status === 'success') {
                setChartData((state: any) => (
                    {
                        ...state,
                        series: res?.tasksData,
                        options: {
                            ...state.options,
                            labels: res?.labels,
                        }
                    }));
            }
            setLoadingText(t("No data found"));
            setLoading(false);
        } catch (error: any) {
            toast(error)
        }
    }

    const clearFilter = () => {
        setFilter(inititalFilter);
    }


    useEffect(() => {
        loading && getInvoicePerTask(serialize(filter));
    }, [loading])

    // useEffect(() => {
    //     !loading && getInvoicePerTask(formValues.invoice_per_task_type);
    // }, [t])

    useEffect(() => {
        setLoading(true);
    }, [filter])

    return (
        <>
            <div className="d-sm-flex flex-wrap">
                <CardTitle className="mb-3">{!hideHeading && t("Top Revenue-Generating Tasks")}</CardTitle>
                <div className="ms-auto">
                    <ChartFilters filter={filter} setFilter={setFilter} clearFilter={clearFilter} slug="all-invoices" />
                </div>
            </div>
            <Chart loading={loading} options={chartData.options} series={chartData.series} type="bar" height={330} />
        </>

    )
}

export default InvoicePerTaskType;