import React, { memo} from 'react';

const ApplyFilter = (props: any) => {
    const { handleRemove, filter } = props;
    return (
            <div className="text-center">
                {
                    filter.company_id &&
                    <span className="badge rounded-pill badge-soft-secondary font-size-11">
                        <i className="far fa-building"></i>{' '}{filter?.company_name?.toUpperCase()}
                    </span> 
                }
                {' '}
                {
                    filter.month &&
                    <span className="badge rounded-pill badge-soft-secondary font-size-11">
                        <i className="far fa-calendar-alt"></i>{' '} {filter?.month?.toUpperCase()}
                    </span>
                }
                {' '}
                {
                    filter.quarter &&
                    <span className="badge rounded-pill badge-soft-secondary font-size-11">
                        <i className="far fa-chart-bar"></i>{' '} {filter?.quarter?.toUpperCase()}
                    </span>
                }
                {' '}
                {
                    filter.user_id &&
                    <span className="badge rounded-pill badge-soft-secondary font-size-11">
                        <i className="far fa-user"></i>{' '} {filter?.user_name?.toUpperCase()}
                    </span>
                }
                {' '}
                {
                    filter?.week &&
                    <span className="badge rounded-pill badge-soft-secondary font-size-11">
                       <i className="far fa-hourglass"></i>{' '} {filter?.week?.toUpperCase()}
                    </span>
                }
                {' '}
                {
                    filter.year &&
                    <span className="badge rounded-pill badge-soft-secondary font-size-11">
                        <i className="far fa-calendar-alt"></i>{' '} {filter?.year}
                    </span>
                }
            </div>
    )
}

export default memo(ApplyFilter)