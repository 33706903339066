import React, { useCallback, useEffect, useRef, useState, memo } from 'react';
// @ts-ignore
import CustomEditor from "ckeditor5-custom-build/build/ckeditor";
// @ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { ckEditorConfigurationEmail } from "../../../helpers/functions_helper";
import { useDropzone } from 'react-dropzone';
import { Button, ButtonGroup, Card, CardBody, CardFooter, DropdownItem, DropdownMenu, DropdownToggle, Input, Popover, PopoverBody, PopoverHeader, Row, UncontrolledDropdown, UncontrolledPopover } from 'reactstrap';
import { debounce,isEmpty } from 'lodash';
import { toast } from "react-toastify";

import fileDownload from 'js-file-download';
import axios from "axios";
import { Buffer } from "buffer";
import { useTranslation } from 'react-i18next';

import useWindowDimensions from './hook';
import MoreMenu from './MoreMenu';
import FileMenu from './FileMenu';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { useSelector, useDispatch } from "react-redux";
import EmailService from '../../../services/email.service';
import TitleBar from './Model/TitleBar';
import './style.scss';
import FloatingComponent from '../../../components/Floating/Floating';
import TemplateListModel from './Model/TemplateListModel';

//Mail Action
import { setDraftEmailData } from "../../../store/mails/actions";

import UserOptions from './UserOptions';
import { HeaderFooter } from "../../Masters/Email/data";
import EmailHeader from '../../Masters/Email/components/Header';
import EmailFooter from '../../Masters/Email/components/Footer';

import Attachment from './Attachment';
import { MaterialIcon } from './styles';
import AttachmentListModel from './Model/AttachmentListModel';
import { draftmailProps } from './type';
import FooterButton from "../../Masters/Email/components/FooterButton";
import AttachmentOptions from './AttachmentOptions';
import Signature from './Signature';


const NewEmail = (props: any) => {

    const [toolBar, setToolBar] = React.useState({
        is_mini: true,
        is_maxi: false,
    });
    const dispatch = useDispatch();
    const toastId = useRef(null) as any;
    const { t } = useTranslation();
    const [documentList, setDocumentList] = useState([]);
    const [viewTemplate, setViewTemplate] = useState<boolean>(false);
    //Uploda Document 
    const [uploadDocModal, setUploadDocModal] = useState<boolean>(false);
    const [uploadDocData, setUploadDocData] = useState<any>([]);
    const { width, height } = useWindowDimensions();

    //Template list model
    const [showTemplateModel, setShowTemplateModel] = useState<boolean>(false);
    const [showFileSystemModel, setShowFileSystemModel] = useState<boolean>(false);

    //Signature list model    
    const [showSignatureModel, setSignatureModel] = useState<boolean>(false);
    //File uploading
    const [progressInfos, setProgressInfos] = useState<any>({ val: [] });
    const [message, setMessage] = useState<any>([]);
    const [fileInfos, setFileInfos] = useState<any>([]);
    const progressInfosRef = useRef<any>(null);
    //show hidden , cc bcc
    const [isCC, setIsCC] = useState<boolean>(false);
    const [isBCC, setIsBCC] = useState<boolean>(false);
    const [composeType, setComposeType] = useState<string>('');
    const { draftEmail } = useSelector((state: any) => ({ draftEmail: state.Mails.draft }));
    const [draftFiles, setDraftFiles] = useState<any>([]);


    const onDrop = useCallback((acceptedFiles: any) => {
        setDraftFiles((preState: any) => ([...preState, ...acceptedFiles]));
        uploadFiles(acceptedFiles);
    }, [draftFiles])

    const {
        is_include_template,
        file_system_attachment,
        title,
        subject,
        body,
        attachment,
        to,
        cc,
        bcc,
        signature,
        openMailBox,
        filters,
    } = draftEmail //as draftmailProps;


    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({ onDrop });

    //File upload
    const uploadFiles = async (dfiles: any) => {
        const files = Array.from(dfiles);
        let _progressInfos = files.map((file: any) => ({ percentage: 0, fileName: file.name }));
        progressInfosRef.current = {
            val: _progressInfos,
        }
        const uploadPromises = files.map((file, i) => upload(i, file));
        const res = await Promise.all(uploadPromises);
        setFileInfos(res);
        setMessage([]);
    };

    const upload = async (idx: any, file: any) => {
        let _progressInfos = [...progressInfosRef.current.val];
        const formData = new FormData();
        formData.append("file_name", file as any);
        return EmailService.uploadDraftMailAttachment(formData)
            .then((data) => {
                setMessage((prevMessage: any) => ([
                    ...prevMessage,
                    "Uploaded the file successfully: " + file.name,
                ]));
                if (data?.data?.status === "success") {
                    return data.data.data;
                }
            })
            .catch(() => {
                _progressInfos[idx].percentage = 0;
                setProgressInfos({ val: _progressInfos });
                setMessage((prevMessage: any) => ([
                    ...prevMessage,
                    "Could not upload the file: " + file.name,
                ]));
            });
    };

    useEffect(() => {
        if (fileInfos?.length > 0) {
            fileInfos?.map((item: any) => {
                draftFiles?.map((fils: any, i: number) => {
                    if (fils.name === `${item?.name}.${item?.file_extension}`) {
                        const draft = draftEmail;
                        draftFiles[i]['file_name_source'] = item?.path;
                        if (draftFiles[i]['path'] === undefined || draftFiles[i]['path'] === '')
                            draftFiles[i]['path'] = `${item?.name}.${item?.file_extension}`;
                        if (draftFiles[i]['file_extension'] === undefined || draftFiles[i]['file_extension'] === '')
                            draftFiles[i]['file_extension'] = item?.file_extension;

                        draft.attachment = draftFiles;
                        dispatch(setDraftEmailData(draft));
                    }
                })
            })
        }
    }, [fileInfos])

    const deleteFile = async (f: any) => {
        //const draftFile =[...draftFiles];
        draftFiles.splice(draftFiles.indexOf(f), 1);
        const draft = draftEmail;
        draft.attachment = draftFiles;
        dispatch(setDraftEmailData(draft));
        await EmailService.deteteDraftFile(f.file_name_source);
    }

    useEffect(() => {
        if (draftEmail.files) {
            uploadFiles(draftEmail.files);
            setDraftFiles((preState: any) => ([...preState, ...draftEmail.files]));
            const draft = draftEmail;
            draft.files = [];
            dispatch(setDraftEmailData(draft));
        }
        if (draftEmail.cc) {
            setIsCC(true);
        }
    }, []);


    const createDraftMail = async () => {
        const postData = {
            type: composeType,
            id: props?.data?.id
        };
        const res = await EmailService.addNewInboxMail(postData);
        //console.log(res);
    }


    useEffect(() => {
        if (composeType === 'reply' || composeType === 'reply_all') {
            if (props?.data?.sender?.emailAddress) {
                const data = [{
                    label: props?.data?.sender?.emailAddress?.name,
                    value: props?.data?.sender?.emailAddress?.address,
                }];
                const draft = draftEmail;
                draft.to = data;
                dispatch(setDraftEmailData(draft));
            }

            if (props?.data?.subject) {
                const draft = draftEmail;
                draft.subject = props?.data?.subject;
                dispatch(setDraftEmailData(draft));
            }

        }
        if (composeType === 'forward') {
            const draft = draftEmail;
            draft.to = [];
            dispatch(setDraftEmailData(draft));
        }

        if (props?.data?.id) {
            const draft = draftEmail;
            draft.id = props?.data?.id;
            dispatch(setDraftEmailData(draft));
        }

    }, [props.data])

    const viewFile = (f: any) => {
        console.log(f);
    }

    const deleteFileSystem = (f: any) => {
        const system_file = [...draftEmail.file_system_attachment || []];
        system_file.splice(system_file.indexOf(f), 1);
        const draft = draftEmail;
        draft.file_system_attachment = system_file;
        dispatch(setDraftEmailData(draft));
    }

    const sendMail = async (type: string) => {
        const draft = draftEmail;
        draft.send_type = composeType || 'send';
        draft.button = type;
        toastId.current = toast(`${type === 'draft' ? t("Saving mail in draft") : t("Sending mail")}`, {
            autoClose: false,
            isLoading: true,
        });
        const res = await EmailService.sendMail(draftEmail);
        if (res?.status === 200 && res?.data.status === "success") {
            toast.update(toastId.current, {
                type: toast.TYPE.SUCCESS,
                render: `${type === 'draft' ? t("Message saved in draft") : t("Message sent")}`,
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });
            dispatch(setDraftEmailData({ openMailBox: 0 }));
        } else {
            toast.update(toastId.current, {
                type: toast.TYPE.ERROR,
                render: 'Error Token expire',
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });
        }
    }

    const loadOptions = async (keyword: string) => {
        const filter = {
            search: keyword,
            extra: filters,
        };
        const user_email = await EmailService.searchUser(filter) as any;
        return user_email?.data?.data || [];
    };

    const loadSuggestedOptions = useCallback(
        debounce((inputValue, callback) => {
            loadOptions(inputValue).then(options => callback(options))
        }, 500), []);

    const loadAttachmentOptions = async (keyword: string) => {
        const filter = {
            search: keyword,
            extra: filters,
        };
        const attachments = await EmailService.searchAttachment(filter) as any;
        return attachments?.data?.data || [];
    };

    const loadAttachmentOption = useCallback(
        debounce((inputValue, callback) => {
            loadAttachmentOptions(inputValue).then(options => callback(options))
        }, 500), []);

    const loadAttachmentFiles = async (query: any) => {
        if (query) {
            const listOfDocument = await EmailService.getAttachment(query);
            if (listOfDocument?.data?.status === "success") {
                setDocumentList(listOfDocument.data.data.original.data);
            }
        }
    }

    useEffect(() => {
        loadAttachmentFiles(filters?.attachment);
    }, [filters?.document])

    const attachDoc = (item: any) => {
        setShowFileSystemModel(true);
        setUploadDocData((preState: any) => ([{ ...preState, item }]));
    }

    // User List
    const loadUserList = async (filter: any) => {
        if(!isEmpty(filter)){
            const user_email = await EmailService.getUserEmail(filter) as any;
            if (user_email?.data?.status === "success") {
                if (user_email?.data?.data?.to.length > 0) {
                    console.log(user_email?.data?.data?.to);
                    const draft = draftEmail;
                    draft.to = user_email?.data?.data?.to ? user_email?.data?.data?.to : [];
                    draft.cc = user_email?.data?.data?.cc ? user_email?.data?.data?.cc : [];
                    if(draft.cc.length>0){
                        setIsCC(true);
                    }
                    dispatch(setDraftEmailData(draft));
                }
            }
        }
       
    }

    //TODO if company user required
    useEffect(() => {
        if (filters && (draftEmail?.to?.length === 0 || typeof draftEmail?.to === 'undefined')) {
            loadUserList(filters || '')
        }
    }, [filters?.company])


    // comment
    const loadCommentList = async (filter: any) => {
        const comment = await EmailService.getComment(filter) as any;
        if (comment.data.status === "success") {
            if (comment?.data?.data?.length > 0) {
                let commentString = comment?.data?.data ? comment.data.data.map((item: any) =>`<div>${item.comment}</div>`).join('') : ''
                const draft = draftEmail;
                draft.body = commentString;
                dispatch(setDraftEmailData(draft));
            }
        }
    }

    //TODO if company user required
    useEffect(() => {
        if (filters && (draftEmail?.body?.length === 0 || typeof draftEmail?.body === 'undefined')) {
            loadCommentList(filters || '')
        }
    }, [filters?.comment])


    //Mail Address 
    //TO
    const handleOnChangeTo = (value: any) => {
        const draft = draftEmail;
        draft.to = value;
        dispatch(setDraftEmailData(draft));
    };

    //CC
    const handleOnChangeCc = (value: any) => {
        if (value.length > 0) {
            const draft = draftEmail;
            draft.cc = value;
            dispatch(setDraftEmailData(draft));
        }
    };
    //BCC
    const handleOnChangeBcc = (value: any) => {
        const draft = draftEmail;
        draft.bcc = value;
        dispatch(setDraftEmailData(draft));
    };

    //Subject and Body Change Handler
    const handleChange = (fieldName: string, fieldValue: string) => {
        const draft = draftEmail;
        draft.body = fieldValue;
        dispatch(setDraftEmailData(draft));
    };

    const handleOnChange = (value: any) => {
        const draft = draftEmail;
        draft.subject = value;
        dispatch(setDraftEmailData(draft));
    };


    const handleSelectedTemplate = (temp: any) => {
        const draft = draftEmail;
        draft.is_include_template = true;
        draft.body = temp;
        dispatch(setDraftEmailData(draft));
    }

    const removeTemplate = () => {
        const draft = draftEmail;
        draft.is_include_template = false;
        setViewTemplate(false);
        dispatch(setDraftEmailData(draft));
    }

    const handleSelectedFileSystem = (temp: any) => {
        const draft = draftEmail;
        let data = [];
        if (typeof draft.file_system_attachment !== 'undefined') {
            data = [...draft.file_system_attachment, ...temp];
        } else {
            data = [...temp];
        }
        // const data = [...draft.file_system_attachment, files];
        draft.file_system_attachment = data;
        dispatch(setDraftEmailData(draft));
    }

    const addFileSystem = (files: any) => {
        const draft = draftEmail;
        //if()
        let data = [];
        if (typeof draft.file_system_attachment !== 'undefined') {
            data = [...draft.file_system_attachment, files];
        } else {
            data = [files];
        }
        draft.file_system_attachment = data;
        dispatch(setDraftEmailData(draft));
    }

    //Mail Box Operation
    //Minimum, Maximum, Drag, Close 
    const handleMaximum = () => {
        //TODO
        const draft = draftEmail;
        draft.openMailBox = draft.openMailBox === 1 ? 3 : 1;
        dispatch(setDraftEmailData(draft));
    }

    const handleMinimize = () => {
        //TODO
        //Save mail to draft in microsoft graphql
        const draft = draftEmail;
        draft.openMailBox = draft.openMailBox === 1 ? 2 : 1;
        dispatch(setDraftEmailData(draft));
    }

    const handleClose = () => {
        dispatch(setDraftEmailData({ openMailBox: 0 }));
    }

    useEffect(() => {
        if (composeType === 'forward') {
            if (props?.data?.body?.content) {
                const draft = draftEmail;
                draft.body = props?.data?.body?.content || '';
                dispatch(setDraftEmailData(draft));
            }
        }
    }, [composeType])

    // Download attachment ------------------------------------------------------------
    const onClickDownload = (row: any) => {
        const SERVER_URL = process.env.REACT_APP_SERVER_URL;
        const AUTH_USER = localStorage.getItem("authUser");
        const ACCESS_TOKEN = JSON.parse(String(AUTH_USER));
        axios({
            url:
                SERVER_URL +
                "/common/download-attachment/" +
                Buffer.from(String(row.id)).toString("base64") +
                "/" +
                Buffer.from(String(row.attachment_id)).toString("base64"),
            method: "GET",
            headers: {
                "Content-Type": "application/" + row.file_extension,
                "Access-Control-Allow-Origin": "*",
                Authorization: "Bearer " + ACCESS_TOKEN.accessToken,
            },
            responseType: "blob",
        }).then((response: any) => {
            fileDownload(response.data, row.file_name + "." + row.file_extension);
        });
    };
    // Download attachment ------------------------------------------------------------


    const dragMailBox = () => {

    }

    const handleView = () => {
        setViewTemplate(preState => !preState);
    }

    function isJsonString(str: string) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    const onOtherDrop = (ev: any) => {
        ev.preventDefault();
        if (isJsonString(ev.dataTransfer.getData("file"))) {
            var data = JSON.parse(ev.dataTransfer.getData("file"));
            const addInFile = {
                attachment_id: data?.attachment_id,
                file_extension: data?.file_extension,
                file_path: data?.file_path,
                id: data?.id,
                label: data?.file_name,
                type: data?.type,
            }
            addFileSystem(addInFile);
        }
    };

    function allowDrop(ev: any) {
        ev.preventDefault();
    }

    const viewAttachment = (data: any) => {
        var blob = new Blob([data], { type: data?.type });
        var objectURL = window.URL.createObjectURL(blob);
        console.log(objectURL);
        var link = document.createElement('a');
        link.href = objectURL;
        link.download = data?.name;
        document.body.appendChild(link);
        link.click();
        link.remove();
    }


    const getSignature = async () => {
        try {
            const res = await EmailService.getsignatureformail(1);
            if (res.data.status === 'success') {
                if (res.data.data.signature) {
                    const draft = draftEmail;
                    draft.body = res.data.data.signature;
                    dispatch(setDraftEmailData(draft));
                }
            }
        } catch (error) {

        }
    }
    useEffect(() => { getSignature() }, [])

    return (
        <FloatingComponent>
            <Card
                className="mb-0 mt-5 mt-lg-0" style={{ width: draftEmail.openMailBox === 2 ? '290px' : '515px', order: 8, zIndex: 992 }}>
                <TitleBar
                    title={title || t("New Message")}
                    is_mini={draftEmail.openMailBox === 2}
                    is_maxi={toolBar.is_maxi}
                    handleMaximum={handleMaximum}
                    handleMinimize={handleMinimize}
                    handleSaveClose={handleClose}
                    t={t}
                />
                <CardBody style={{ display: draftEmail.openMailBox === 2 ? 'none' : 'block', padding: '6px', overflow: 'hidden', overflowY: 'scroll', height: '420px' }}>
                    <div className="mb-3 d-flex">
                        <div className='flex-grow-1'>
                            <AsyncCreatableSelect
                                cacheOptions
                                loadOptions={loadSuggestedOptions}
                                defaultOptions
                                placeholder="To"
                                isMulti
                                isClearable={false}
                                formatCreateLabel={userInput => `${t("Add new email id")} ${userInput}`}
                                components={{
                                    DropdownIndicator: () => null,
                                    IndicatorSeparator: () => null,
                                    Option: UserOptions,
                                }}
                                value={draftEmail.to || []}
                                onChange={handleOnChangeTo}
                            />
                        </div>
                        <div>
                            <Button onClick={() => setIsCC(preState => !preState)}
                                color="link"
                            >
                                Cc
                            </Button>
                            <Button onClick={() => setIsBCC(preState => !preState)}
                                color="link"
                            >
                                Bcc
                            </Button>
                        </div>
                    </div>

                    {isCC && <div className="mb-3">
                        <AsyncCreatableSelect
                            cacheOptions
                            loadOptions={loadSuggestedOptions}
                            defaultOptions
                            isMulti
                            isClearable={false}
                            formatCreateLabel={userInput => `${t("Add new email id")} ${userInput}`}
                            components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                                Option: UserOptions,
                            }}
                            placeholder="Cc"
                            value={draftEmail.cc || []}
                            onChange={handleOnChangeCc}
                        />
                    </div>}
                    {isBCC && <div className="mb-3">
                        <AsyncCreatableSelect
                            cacheOptions
                            loadOptions={loadSuggestedOptions}
                            defaultOptions
                            isMulti
                            isClearable={false}
                            formatCreateLabel={userInput => `${t("Add new email id")} ${userInput}`}
                            components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                                Option: UserOptions,
                            }}
                            placeholder="Bcc"
                            value={draftEmail.bcc || []}
                            onChange={handleOnChangeBcc}
                        />
                    </div>}
                    <div className="mb-3">
                        <Input
                            type="text"
                            value={subject}
                            className="form-control"
                            placeholder={t("Subject")}
                            onChange={(e) => handleOnChange(e.target.value)}
                        />
                    </div>
                    {viewTemplate ? <div>
                        <EmailHeader header={HeaderFooter[0].header} />
                        <div style={{ 'padding': '15px' }}>
                            <div dangerouslySetInnerHTML={{ __html: body }} />
                            {/* <FooterButton title={item.button} /> */}
                        </div>
                        <EmailFooter footer={HeaderFooter[0].footer} />
                    </div> :
                        <div  {...getRootProps({
                            onClick: event => event.stopPropagation(),
                        })}>
                            <input {...getInputProps()} />
                            {isDragActive ? (
                                <div className="dz-message needsclick mt-2">
                                    <div className="mb-3">
                                        <span className="align-middle material-symbols-outlined fw-lighter display-4 text-muted">
                                            cloud_upload
                                        </span>
                                    </div>
                                    <h4>{t("Drop files here or click to upload.")}</h4>
                                </div>
                            ) :
                                (
                                    <div>
                                        <CKEditor
                                            editor={CustomEditor}
                                            config={ckEditorConfigurationEmail}
                                            data={body}
                                            id="comment"
                                            autoFocus
                                            name="comment"
                                            className="form-control"
                                            onChange={(e: any, editor: any) => {
                                                handleChange("template", editor.getData());
                                            }}
                                        />
                                    </div>
                                )

                            }

                        </div>}
                    <div className='row mt-2'>
                        {(attachment !== undefined && attachment?.length > 0) && attachment.map((file: any, i: number) => (
                            <div className='col-6' key={i}>
                                <Attachment
                                    size={file.size}
                                    name={file.name}
                                    type={file.type}
                                    handleOnDelete={() => deleteFile(file)}
                                    deleteOption={true}
                                    download={true}
                                    downloadAttachment={() => viewAttachment(file)}
                                />
                            </div>

                        ))}
                    </div>
                    <Row className='mb-3 p-2' onDrop={(e: any) => onOtherDrop(e)} onDragOver={(e: any) => allowDrop(e)}>
                        {(file_system_attachment !== undefined && file_system_attachment?.length > 0) ? <div><div className='col-12 p-2'>{t("File System Attachment")}</div>
                            {file_system_attachment?.map((item: any, i: number) => <div className='col-4 mb-1 p-1' key={i}>
                                <ButtonGroup style={{ width: '100%' }}>
                                    <Button
                                        color="light"
                                        outline
                                        type="button"
                                        className="text-truncate"
                                    >
                                        <i className="fa-solid fa-file text-primary"></i> {item.label}
                                    </Button>
                                    <ButtonGroup>
                                        <UncontrolledDropdown>
                                            <DropdownToggle caret color='light'>
                                                <MaterialIcon size={20}>expand_more</MaterialIcon>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => deleteFileSystem(item)}>
                                                    <MaterialIcon size={20}>delete</MaterialIcon>{t("Delete")}
                                                </DropdownItem>
                                                <DropdownItem divider />
                                                <DropdownItem onClick={() => onClickDownload(item)}>
                                                    <MaterialIcon size={20}>preview</MaterialIcon>{t("View")}
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </ButtonGroup>
                                </ButtonGroup>
                            </div>
                            )}
                        </div> :
                            <div className='p-1 '>
                                <div className='d-flex align-items-center justify-content-center' style={{ border: '2px dotted #ccc', height: '64px' }}>
                                    <span>{t("File system drag here")}</span><br />{" "}
                                    <span className="material-symbols-outlined ms-2">
                                        cloud_upload
                                    </span>
                                </div>
                            </div>
                        }
                    </Row>
                </CardBody>
                <CardFooter style={{ display: draftEmail.openMailBox === 2 ? 'none' : 'block', padding: '6px' }}>
                    <div className='row'>
                        <div className='col-8 d-flex align-items-center'>
                            <Button
                                color="success"
                                size="sm"
                                className='bg-btn-color me-2'
                                onClick={() => sendMail('send')}
                            >
                                {t("Send")}
                            </Button>
                            {' '}
                            <Button
                                color="light"
                                size="sm"
                                outline
                                className='me-2'
                                onClick={() => sendMail('draft')}
                            >
                                {t("Save in Draft")}
                            </Button>
                            {' '}
                            <Button
                                color="light"
                                size="sm"
                                outline
                                className='me-2'
                                onClick={() => handleClose()}
                            >
                                {t("Discard")}
                            </Button>
                            {' '}
                            <FileMenu
                                documents={documentList || []}
                                handleFileOpener={open}
                                attachDoc={(e) => attachDoc(e)}
                                handleFileSystemModel={() => setShowFileSystemModel(true)}
                                t={t}
                            />
                            {' '}
                            {
                                is_include_template && <>
                                    <Button
                                        color="light"
                                        className='ms-2'
                                        size="sm"
                                        onClick={() => handleView()}
                                    >
                                        <MaterialIcon>
                                            {viewTemplate ? 'preview_off' : 'preview'}
                                        </MaterialIcon>
                                    </Button>
                                    <Button
                                        color="light"
                                        className='ms-2'
                                        size="sm"
                                        onClick={() => removeTemplate()}
                                    >
                                        <MaterialIcon>
                                            format_ink_highlighter
                                        </MaterialIcon>
                                    </Button>
                                </>
                            }
                        </div>
                        <div className='col-4'>
                            <MoreMenu
                                OpenSignatureModel={() => setSignatureModel(true)}
                                direction={'up'}
                                OpenTemplateModel={() => setShowTemplateModel(true)}
                            />
                            {' '}
                        </div>
                    </div>
                </CardFooter>
            </Card>
            <TemplateListModel
                title={t("Template Search")}
                filters={filters}
                show={showTemplateModel}
                selectTemplate={handleSelectedTemplate}
                onCloseClick={() => setShowTemplateModel(false)}
            />

            <AttachmentListModel
                title="File System"
                filters={filters}
                show={showFileSystemModel}
                selectFiles={handleSelectedFileSystem}
                onCloseClick={() => setShowFileSystemModel(false)}
            />
            <Signature
                show={showSignatureModel}
                title={'Signature'}
                onCloseClick={() => setSignatureModel(false)}
            />
        </FloatingComponent>
    )

}

export default memo(NewEmail);