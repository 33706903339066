import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { useIsAuthenticated } from "@azure/msal-react";
// Simple bar
import SimpleBar from "simplebar-react";
// Pro sidebar
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarFooter } from "react-pro-sidebar";
import { isActiveMenu, getUserProfile, moduleAccess } from "../../helpers/functions_helper";

const SidebarContent = (props: any) => {
  const scrollableNodeRef = React.createRef() as any;
  const refDiv = React.createRef() as any;
  const { t } = useTranslation();
  const userProfile = getUserProfile();

  const { getSidebarcollpsed } = useSelector((state: any) => ({
    getSidebarcollpsed: state.Common.sidebarcollpsed,
  }));

  const [activeSubmenu, setActiveSubmenu] = useState<string>("");
  const isAzureAuthenticated = useIsAuthenticated();

  const handleActiveSubmenu = (item: string) => {
    if (activeSubmenu === item) {
      setActiveSubmenu("");
    } else {
      setActiveSubmenu(item);
    }
  }

  const scrollElement = (item: any) => {
    setTimeout(() => {
      if (refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop;

          // console.log("currentPosition", currentPosition);
          // console.log("window.innerHeight", window.innerHeight);

          if (currentPosition > window.innerHeight) {
            if (refDiv.current) refDiv.current.getScrollElement().scrollTop = currentPosition + 300;
          }
        }
      }
    }, 300);
  };

  useEffect(() => {
    const activeItem = document.getElementsByClassName("pro-menu-item active") as any;
    if (activeItem && activeItem[0]) {
      scrollElement(activeItem[0]);
    }
  }, [window.location.pathname]);

  return (
    <React.Fragment>
      <SimpleBar
        id="simple-bar"
        className="h-100"
        scrollableNodeProps={{ ref: scrollableNodeRef }}
        ref={refDiv}
      >
        <ProSidebar collapsed={getSidebarcollpsed}>
          <Menu id="side-menu">
            <li className="menu-title">{t("Menu")}</li>

            {moduleAccess("default") && userProfile.USER_TYPE !== 3 && (
              <MenuItem
                key="dashboard"
                title={t("Dashboard")}
                active={
                  isActiveMenu(window.location.pathname, "dashboard") ||
                  window.location.pathname === "/"
                }
                icon={
                  <span className="align-middle material-symbols-outlined fw-light">
                    space_dashboard
                  </span>
                }
              >
                <span>{t("Dashboard")}</span>
                <Link id="DashboardLink" to="/dashboard" />
              </MenuItem>
            )}

            {moduleAccess("all-tasks") && (
              <MenuItem
                key="task"
                title={t("Task")}
                active={isActiveMenu(window.location.pathname, "tasks")}
                icon={<span className="align-middle material-symbols-outlined fw-light">task</span>}
              >
                <span>{t("Task")}</span>

                {userProfile.USER_COMPANY !== 1 ? (
                  <Link id="TaskLink" to="/tasks/all-tasks" />
                ) : (
                  <Link id="TaskLink" to="tasks/kanban-view" />
                )}
              </MenuItem>
            )}

            {moduleAccess("all-time-sheets") && (
              <MenuItem
                key="time.sheets"
                title={t("Time sheet")}
                active={isActiveMenu(window.location.pathname, "time-sheets")}
                icon={
                  <span className="align-middle material-symbols-outlined fw-light">
                    pending_actions
                  </span>
                }
              >
                <span>{t("Time sheet")}</span>
                <Link id="TimeSheetLink" to="/time-sheets/all" />
              </MenuItem>
            )}

            {moduleAccess("all-invoices") && (
              <MenuItem
                key="invoices.management"
                title={t("Invoices")}
                active={isActiveMenu(window.location.pathname, "invoices-management")}
                icon={
                  <span className="align-middle material-symbols-outlined fw-light">
                    receipt_long
                  </span>
                }
              >
                <span>{t("Invoices")}</span>
                <Link id="InvoicesManagementLink" to="/invoices-management/invoices/all" />
              </MenuItem>
            )}

            {moduleAccess("all-questionnaires") && (
              <MenuItem
                key="questionnaire"
                title={t("Questionnaire")}
                active={isActiveMenu(window.location.pathname, "all-questionnaires")}
                icon={
                  <span className="align-middle material-symbols-outlined fw-light">
                    unknown_document
                  </span>
                }
              >
                <span>{t("Questionnaire")}</span>
                <Link id="QuestionnaireLink" to="/questionnaires/all-questionnaires" />
              </MenuItem>
            )}

            {moduleAccess("file-system") && (
              <MenuItem
                key="attachments"
                title={t("File system")}
                active={isActiveMenu(window.location.pathname, "file-system")}
                icon={
                  <span className="align-middle material-symbols-outlined fw-light">
                    file_present
                  </span>
                }
              >
                <span>{t("File system")}</span>
                <Link id="AttachmentsLink" to="/file-system" />
              </MenuItem>
            )}

            {moduleAccess("email") && isAzureAuthenticated && (
              <MenuItem
                key="email"
                title={t("Email")}
                active={isActiveMenu(window.location.pathname, "email")}
                icon={<span className="align-middle material-symbols-outlined fw-light">mail</span>}
              >
                <span>{t("Email")}</span>
                <Link id="EmailLink" to="/email/inbox" />
              </MenuItem>
            )}

            {moduleAccess("doc-signature") && (
              <SubMenu
                open={
                  isActiveMenu(window.location.pathname, "client-onboarding") ||
                  activeSubmenu === "client-onboarding"
                }
                onClick={() => handleActiveSubmenu("client-onboarding")}
                title={t("Client Onboarding")}
                icon={
                  <span className="align-middle material-symbols-outlined fw-light">
                    settings_account_box
                  </span>
                }
              >
                <MenuItem
                  key="client.onboarding.doc-signature"
                  active={isActiveMenu(window.location.pathname, "/client-onboarding/doc-signature")}
                  icon={
                    <span className="align-middle material-symbols-outlined fw-light">draw</span>
                  }
                >
                  <span>{t("Doc-Signature")}</span>
                  <Link id="docSignatureLink" to="/client-onboarding/doc-signature/list" />
                </MenuItem>
                {userProfile.USER_COMPANY == 1 ? (
                <MenuItem
                  key="client.onboarding.template"
                  active={isActiveMenu(window.location.pathname, "/client-onboarding/template")}
                  icon={
                    <span className="align-middle material-symbols-outlined fw-light">newspaper</span>
                  }
                >
                  <span>{t("Doc-Templates")}</span>
                  <Link id="docTemplatesLink" to="/client-onboarding/template/list" />
                </MenuItem>
                ) : null}
              </SubMenu>
            )}

            {moduleAccess("client-allocation") && (
              <MenuItem
                key="client.allocation"
                title={t("Client allocation")}
                active={isActiveMenu(window.location.pathname, "client-allocation")}
                icon={
                  <span className="align-middle material-symbols-outlined fw-light">
                    supervisor_account
                  </span>
                }
              >
                <span>{t("Client allocation")}</span>
                <Link id="ClientAllocationLink" to="/client-allocation/internal-users" />
              </MenuItem>
            )}

            {moduleAccess("all-cpo") && (
              <MenuItem
                key="cpo"
                title={t("CPO")}
                active={isActiveMenu(window.location.pathname, "cpo")}
                icon={
                  <span className="align-middle material-symbols-outlined fw-light">calculate</span>
                }
              >
                <span>{t("CPO")}</span>
                <Link id="CPOLink" to="/cpo/list/all" />
              </MenuItem>
            )}

            {moduleAccess("all-split-payroll") && (
              <MenuItem
                key="split.payroll"
                title={t("Split payroll")}
                active={isActiveMenu(window.location.pathname, "split-payroll")}
                icon={
                  <span className="align-middle material-symbols-outlined fw-light">
                    request_quote
                  </span>
                }
              >
                <span>{t("Split payroll")}</span>
                <Link id="SplitPayrollLink" to="/split-payroll/list/all" />
              </MenuItem>
            )}

            {moduleAccess("all-company") && (
              <>
                {/* <li className="menu-title">{t("Company Master")}</li> */}
                <MenuItem
                  key="masters.company"
                  active={isActiveMenu(window.location.pathname, "company")}
                  icon={
                    <span className="align-middle material-symbols-outlined fw-light">domain</span>
                  }
                >
                  <span>{t("Company")}</span>
                  <Link id="masterCompanyLink" to="/masters/company" />
                </MenuItem>
              </>
            )}

            {/* {moduleAccess("all-users") === true && (
              <li className="menu-title">{t("Users")}</li>
            )} */}

            {moduleAccess("all-users") && (
              <SubMenu
                open={isActiveMenu(window.location.pathname, "users") || isActiveMenu(window.location.pathname, "access-role") || activeSubmenu === "users"}
                onClick={() => handleActiveSubmenu("users")}
                title={t("Users & Access")}
                icon={
                  <span className="align-middle material-symbols-outlined fw-light">
                    manage_accounts
                  </span>
                }
              >
                <MenuItem
                  key="users.all.users"
                  active={isActiveMenu(window.location.pathname, "all-users")}
                  icon={
                    <span className="align-middle material-symbols-outlined fw-light">group</span>
                  }
                >
                  <span>{t("All users")}</span>
                  <Link id="masterUserLink" to="/users/all-users" />
                </MenuItem>

                {moduleAccess("access-role") ? (
                  <>
                    <div
                      className="dropdown-divider menu-divider mt-1 mb-1"
                      style={{ borderColor: "#2e364b" }}
                    ></div>

                    <MenuItem
                      key="users.access.role"
                      active={isActiveMenu(window.location.pathname, "access-role")}
                      icon={
                        <span className="align-middle material-symbols-outlined fw-light">
                          detection_and_zone
                        </span>
                      }
                    >
                      <span>{t("Access role")}</span>
                      <Link id="masterAccessRoleLink" to="/users/access-role" />
                    </MenuItem>
                  </>
                ) : null}
              </SubMenu>
            )}

            {/* <div className="dropdown-divider menu-divider ms-0 me-0"></div>
            <li className="menu-title">{t("News Feed")}</li> */}
            {moduleAccess("news-feed") && (
              <MenuItem
                key="masters.news.feed"
                active={isActiveMenu(window.location.pathname, "news-feed")}
                icon={
                  <span className="align-middle material-symbols-outlined fw-light">newspaper</span>
                }
              >
                <span>{t("News Feed")}</span>
                <Link id="masterNewsFeedLink" to="/masters/news-feed" />
              </MenuItem>
            )}
            {moduleAccess("projects") && (
              <MenuItem
                key="masters.projects"
                active={isActiveMenu(window.location.pathname, "projects")}
                icon={
                  <span className="align-middle material-symbols-outlined fw-light">post_add</span>
                }
              >
                <span>{t("Projects")}</span>
                <Link id="masterProjectsLink" to="/masters/projects" />
              </MenuItem>
            )}

            {moduleAccess("news-letter") && (
              <MenuItem
                key="masters.news.letter.list"
                active={isActiveMenu(window.location.pathname, "news-letter")}
                icon={<span className="align-middle material-symbols-outlined fw-light">feed</span>}
              >
                <span>{t("News Letter")}</span>
                <Link id="masterNewsLetterLink" to="/masters/news-letter/list" />
              </MenuItem>
            )}

            {moduleAccess("incident") && (
              <MenuItem
                key="incident.tracking.list"
                active={isActiveMenu(window.location.pathname, "incident")}
                icon={
                  <span className="align-middle material-symbols-outlined fw-light">
                    bug_report
                  </span>
                }
              >
                <span>{t("Incident Tracker")}</span>
                <Link id="masterIncidentLink" to="/incident/list" />
              </MenuItem>
            )}

            {moduleAccess("broadcast") && (
              <MenuItem
                key="broadcast.list"
                active={isActiveMenu(window.location.pathname, "broadcast")}
                icon={
                  <span className="align-middle material-symbols-outlined fw-light">campaign</span>
                }
              >
                <span>{t("Broadcast")}</span>
                <Link id="masterBroadcastLink" to="/broadcast/all" />
              </MenuItem>
            )}
            {moduleAccess("banking") && (
              <MenuItem
                key="banking.connections"
                active={isActiveMenu(window.location.pathname, "banking")}
                icon={
                  <span className="align-middle material-symbols-outlined fw-light">
                    account_balance
                  </span>
                }
              >
                <span>{t("Banking")}</span>
                <Link id="bankingLink" to="/banking/connections" />
              </MenuItem>
            )}

            {moduleAccess("masters") && <li className="menu-title">{t("Masters")}</li>}

            {moduleAccess("masters") && (
              <SubMenu
                title={t("Masters")}
                icon={
                  <span className="align-middle material-symbols-outlined fw-light">
                    dataset_linked
                  </span>
                }
                open={
                  isActiveMenu(window.location.pathname, "masters") || activeSubmenu === "masters"
                }
                onClick={() => handleActiveSubmenu("masters")}
              >
                <li className="menu-title">{t("Users Master")}</li>

                <MenuItem
                  key="masters.user.function"
                  active={isActiveMenu(window.location.pathname, "user-function")}
                  icon={
                    <span className="align-middle material-symbols-outlined fw-light">
                      assignment_ind
                    </span>
                  }
                >
                  <span>{t("User function")}</span>
                  <Link id="masterUserFunctionLink" to="/masters/user-function" />
                </MenuItem>

                <MenuItem
                  key="masters.document.type"
                  active={isActiveMenu(window.location.pathname, "document-type")}
                  icon={
                    <span className="align-middle material-symbols-outlined fw-light">
                      difference
                    </span>
                  }
                >
                  <span>{t("Document Type")}</span>
                  <Link id="masterDocumentTypeLink" to="/masters/document-type" />
                </MenuItem>

                <div className="dropdown-divider menu-divider ms-0 me-0"></div>
                <li className="menu-title">{t("Company")} {t("Master")}</li>

                {/* <MenuItem
                  key="masters.company"
                  active={isActiveMenu(window.location.pathname, "company")}
                  icon={<span className="align-middle material-symbols-outlined fw-light">domain</span>}
                >
                  <span>{t("Company")}</span>
                  <Link id="masterCompanyLink" to="/masters/company" />
                </MenuItem> */}

                <MenuItem
                  key="masters.company.category"
                  active={isActiveMenu(window.location.pathname, "company-category")}
                  icon={
                    <span className="align-middle material-symbols-outlined fw-light">
                      location_city
                    </span>
                  }
                >
                  <span>{t("Company category")}</span>
                  <Link id="masterCompanyCategoryLink" to="/masters/company-category" />
                </MenuItem>

                <MenuItem
                  key="masters.company.profile"
                  active={isActiveMenu(window.location.pathname, "company-profile")}
                  icon={
                    <span className="align-middle material-symbols-outlined fw-light">
                      location_city
                    </span>
                  }
                >
                  <span>{t("Company profile")}</span>
                  <Link id="masterCompanyProfileLink" to="/masters/company-profile" />
                </MenuItem>

                <div className="dropdown-divider menu-divider ms-0 me-0"></div>
                <li className="menu-title">{t("Fees Master")}</li>

                <MenuItem
                  key="masters.fees"
                  active={isActiveMenu(window.location.pathname, "fees")}
                  icon={
                    <span className="align-middle material-symbols-outlined fw-light">
                      account_balance_wallet
                    </span>
                  }
                >
                  <span>{t("Fees")}</span>
                  <Link id="masterFeesLink" to="/masters/fees" />
                </MenuItem>

                <div className="dropdown-divider menu-divider ms-0 me-0"></div>
                <li className="menu-title">{t("Account")} {t("Master")}</li>

                <MenuItem
                  key="masters.account"
                  active={isActiveMenu(window.location.pathname, "account")}
                  icon={
                    <span className="align-middle material-symbols-outlined fw-light">
                      account_balance
                    </span>
                  }
                >
                  <span>{t("Account")} {t("Master")}</span>
                  <Link id="masterAccountLink" to="/masters/account" />
                </MenuItem>

                <div className="dropdown-divider menu-divider ms-0 me-0"></div>
                <li className="menu-title">{t("VAT")} {t("Master")}</li>

                <MenuItem
                  key="masters.country"
                  active={isActiveMenu(window.location.pathname, "country")}
                  icon={
                    <span className="align-middle material-symbols-outlined fw-light">
                      travel_explore
                    </span>
                  }
                >
                  <span>{t("Country")} {t("Master")}</span>
                  <Link id="masterVatLink" to="/masters/country" />
                </MenuItem>

                <MenuItem
                  key="masters.vat.status"
                  active={isActiveMenu(window.location.pathname, "vat-master")}
                  icon={
                    <span className="align-middle material-symbols-outlined fw-light">money</span>
                  }
                >
                  <span>{t("VAT")} {t("Master")}</span>
                  <Link id="masterVatLink" to="/masters/vat-master" />
                </MenuItem>

                <MenuItem
                  key="masters.vatcat"
                  active={isActiveMenu(window.location.pathname, "vatcat")}
                  icon={
                    <span className="align-middle material-symbols-outlined fw-light">money</span>
                  }
                >
                  <span>{t("VAT Category")}</span>
                  <Link id="masterVatLink" to="/masters/vatcat" />
                </MenuItem>

                {/* <div className="dropdown-divider menu-divider ms-0 me-0"></div>
                <li className="menu-title">{t("Tasks Master")}</li>

                <MenuItem
                  key="masters.priority"
                  active={isActiveMenu(
                    window.location.pathname,
                    "task-priority"
                  )}
                  icon={<span className="align-middle material-symbols-outlined fw-light">task</span>}
                >
                  <span>{t("Task priority")}</span>
                  <Link
                    id="masterTaskPriorityLink"
                    to="/masters/task-priority"
                  />
                </MenuItem> */}

                <div className="dropdown-divider menu-divider ms-0 me-0"></div>
                <li className="menu-title">{t("Questionnaire")} {t("Master")}</li>

                <MenuItem
                  key="masters.questionnaire.type"
                  active={isActiveMenu(window.location.pathname, "questionnaire-type")}
                  icon={
                    <span className="align-middle material-symbols-outlined fw-light">quiz</span>
                  }
                >
                  <span>{t("Questionnaire type")}</span>
                  <Link id="masterQuestionnaireLink" to="/masters/questionnaire-type" />
                </MenuItem>

                <MenuItem
                  key="masters.questionnaire.status"
                  active={isActiveMenu(window.location.pathname, "questionnaire-status")}
                  icon={
                    <span className="align-middle material-symbols-outlined fw-light">
                      live_help
                    </span>
                  }
                >
                  <span>{t("Questionnaire status")}</span>
                  <Link id="masterQuestionnaireStatusLink" to="/masters/questionnaire-status" />
                </MenuItem>

                <div className="dropdown-divider menu-divider ms-0 me-0"></div>
                <li className="menu-title">{t("Bank Holiday")} {t("Master")}</li>

                <MenuItem
                  key="masters.bank_holiday"
                  active={isActiveMenu(window.location.pathname, "bank_holiday")}
                  icon={<span className="material-symbols-outlined fw-light">calendar_month</span>}
                >
                  <span>{t("Bank Holiday")}</span>
                  <Link id="masterBankHoliday" to="/masters/bank-holiday" />
                </MenuItem>

                <div className="dropdown-divider menu-divider ms-0 me-0"></div>
                <li className="menu-title">{t("Split Payroll")} {t("Master")}</li>

                <MenuItem
                  key="masters.split.payroll.country"
                  active={isActiveMenu(window.location.pathname, "split-payroll-country")}
                  icon={
                    <span className="align-middle material-symbols-outlined fw-light">
                      travel_explore
                    </span>
                  }
                >
                  <span>{t("Split payroll")} {t("country")}</span>
                  <Link id="masterSplitPayrollCountryLink" to="/masters/split-payroll-country" />
                </MenuItem>

                <MenuItem
                  key="masters.split.payroll.year"
                  active={isActiveMenu(window.location.pathname, "split-payroll-year")}
                  icon={
                    <span className="align-middle material-symbols-outlined fw-light">
                      calendar_month
                    </span>
                  }
                >
                  <span>{t("Split payroll")} {t("year")}</span>
                  <Link id="masterSplitPayrollYearLink" to="/masters/split-payroll-year" />
                </MenuItem>

                <div className="dropdown-divider menu-divider ms-0 me-0"></div>
                <li className="menu-title">{t("Email")}</li>

                <MenuItem
                  key="masters.email.template.list"
                  active={isActiveMenu(window.location.pathname, "email-template")}
                  icon={
                    <span className="align-middle material-symbols-outlined fw-light">
                      inbox_customize
                    </span>
                  }
                >
                  <span>{t("Email Template")}</span>
                  <Link id="masterEmailTemplateLink" to="/masters/email-template/list" />
                </MenuItem>
                <MenuItem
                  key="masters.email.category.list"
                  active={isActiveMenu(window.location.pathname, "email-category")}
                  icon={
                    <span className="align-middle material-symbols-outlined fw-light">
                      local_post_office
                    </span>
                  }
                >
                  <span>{t("Email Category")}</span>
                  <Link id="masterEmailCategoryLink" to="/masters/email-category/list" />
                </MenuItem>

                <div className="dropdown-divider menu-divider ms-0 me-0"></div>
                <li className="menu-title">{t("Tax return")}</li>

                <MenuItem
                  key="masters.tax.return.type.list"
                  active={isActiveMenu(window.location.pathname, "tax-return-type")}
                  icon={
                    <span className="align-middle material-symbols-outlined fw-light">
                      request_quote
                    </span>
                  }
                >
                  <span>{t("Tax return type")}</span>
                  <Link id="masterTaxReturnTypeLink" to="/masters/tax-return-type/list" />
                </MenuItem>
              </SubMenu>
            )}
          </Menu>

          {moduleAccess("all-user-manual") && (
            <SidebarFooter>
              <Menu>
                <MenuItem
                  key="user.manual"
                  active={isActiveMenu(window.location.pathname, "user-manual")}
                  icon={
                    <span className="align-middle material-symbols-outlined fw-light">help</span>
                  }
                >
                  <span>{t("User manual")}</span>
                  <Link id="userManualLink" to="/user-manual/list/all" />
                </MenuItem>
              </Menu>
            </SidebarFooter>
          )}
        </ProSidebar>
      </SimpleBar>
    </React.Fragment>
  );
};

export default SidebarContent;
