// LOGIN
export const LOGIN = "/login"; // METHOD: POST
export const SSO_LOGIN = "/sso-login"; // METHOD: POST

// FORGOT & RESET
export const FORGOT_PASSWORD = "/forgot-password"; // METHOD: POST
export const RESET_PASSWORD = "/reset-password"; // METHOD: POST

// LOGOUT
export const LOGOUT = "/logout"; // METHOD: POST

// AZURE
export const CREATE_CONTENT = "/azure/create-content"; //METHOD: POST
export const INVITE_USER = "/azure/invite-user"; //METHOD: POST

// OPTIONS LIST - COMMON
export const GET_CUSTOME_CODE = "/module/get-custome-code"; // PARAMETERS: /{table_name}/{module_code} // METHOD: GET
export const GET_BINARY_STREAM = "/module/get-binary-stream"; // PARAMETERS: /{file_name} // METHOD: GET
export const GET_SELECT_OPTIONS_LIST = "/list/get-select-options-list"; // PARAMETERS: /{table_name}/{field_name}/{query} // METHOD: GET
export const GET_SELECT_MAP_OPTIONS_LIST = "/list/get-select-map-options-list"; // PARAMETERS: /{table_name}/{field_name}/{query} // METHOD: GET
export const GET_SELECT_OPTIONS_LIST_ORDER = "/list/get-select-options-list-order"; // PARAMETERS: /{table_name}/{field_name}/{query} // METHOD: GET
export const GET_SELECT_OPTIONS_LIST_WITH_ORDER = "/list/get-select-options-list-with-order"; // PARAMETERS: /{table_name}/{field_name}/{query} // METHOD: GET
export const GET_DEPENDENT_SELECT_OPTIONS_LIST = "/list/get-dependent-select-options-list"; // PARAMETERS: /{table_name}/{field_name}/{WHERE id}/{query} // METHOD: GET
export const GET_DEPENDENT_USER_OPTIONS_LIST_NEW = "/list/get-dependent-user-options-list-new"; // PARAMETERS: /{table_name}/{field_name}/{WHERE id}/{query} // METHOD: GET
export const GET_DEPENDENT_SELECT_OPTIONS_LIST_WITH_ORDER =
  "/list/get-dependent-select-options-list-with-order"; // PARAMETERS: /{table_name}/{field_name}/{WHERE id}/{query} // METHOD: GET
export const GET_DEPENDENT_MULTIPLE_WHERE_SELECT_OPTIONS_LIST =
  "/list/get-dependent-multiple-where-select-options-list"; // PARAMETERS: /{table_name}/{field_name}/{WHERE id}/{query} // METHOD: GET
export const GET_TASK_BY_COMPANY = "/list/get-task-by-company";  // METHOD: GET
export const GET_TASKS_SUBTASKS_LIST = "/list/get-task-subtask-list";  // METHOD: GET
export const GET_DEPENDENT_ALL_FIELD_SELECT_OPTIONS_LIST = "/list/get-dependent-all-data";
export const GET_DEPENDENT_WHERE_IN_SELECT_OPTIONS_LIST =
  "/list/get-dependent-wherein-select-options-list"; // PARAMETERS: /{table_name}/{field_name}/{WHERE ids}/{query} // METHOD: GET

export const CHANGE_TABLE_ORDER_ON_DRAG = "/common/change-table-order-on-drag"; //METHOD: POST
export const UPDATE_ORDER_ON_DELETE = "/common/update-order-on-drag"; //METHOD: POST

export const GET_ATTACHMENT_LIST = "/common/get-attachment-list"; // METHOD: POST
export const UPLOAD_ATTACHMENT = "/common/upload-attachment"; // METHOD: POST
export const UPLOAD_LINK = "/common/upload-link"; // METHOD: POST
export const DELETE_ATTACHMENT = "/common/delete-attachment"; // METHOD: POST
export const DOWNLOAD_ATTACHMENT = "/common/download-attachment"; // METHOD: GET PARAMETERS: /{id} /{attachment_id}


export const UPLOAD_SIGNATURE_MASTER = "/common/upload-signature"; //METHOD:POST
export const GET_SIGNATURE = "/signature/get-signature-list";

export const GET_COMMENT_LIST = "/common/get-comment-list"; // METHOD: POST
export const CREATE_COMMENT = "/common/create-comment"; // METHOD: POST
export const DELETE_COMMENT = "/common/delete-comment"; // METHOD: POST
export const CREATE_DOCUMENT = "/common/upload-document"; // METHOD: POST
export const DOWNLOAD_DOCUMENT = "/common/download-documents"; // METHOD: GET PARAMETERS: /{module_main_id} /{document_type_id}
export const DELETE_DOCUMENT = "/common/delete-document"; // METHOD: DELETE PARAMETERS: /{module_main_id} /{document_type_id}
export const GET_VALIDATE_VAT_NR = "/common/validate-vat-number"; // METHOD: GET PARAMETERS: /{vat_nr}
export const UPDATE_DASHBOARD = "/common/update-dashboard"; // METHOD: PUT PARAMETERS: /{user_id}
export const GET_DASHBOARD = "/common/get-dashboard"; // METHOD: GET PARAMETERS: /{user_id}
export const GET_DASHBOARD_WIDGETS = "/common/get-dashboard-widgets"; // METHOD: GET
export const GET_COMMUNE_NAME = "/common/get-commune-name"; // METHOD: GET
export const GET_COUNTRY_ID = "/common/get-country-id"; // METHOD: GET PARAMETERS: /{short_name}

export const GET_DATATABLE_SETTINGS = "/common/get-datatable-settings"; // METHOD: POST
export const UPDATE_DATATABLE_SETTINGS = "/common/update-datatable-settings"; // METHOD: POST
export const RESET_DATATABLE_SETTINGS = "/common/reset-datatable-settings"; // METHOD: POST
export const UPDATE_DATATABLE_ORDER_SETTINGS = "/common/update-datatable-order-settings"; // METHOD: POST

export const GET_USER_OPTIONS_LIST = "/list/get-user-options-list"; // METHOD: GET
export const GET_COMPANY_OPTIONS_LIST = "/list/get-company-options-list"; // METHOD: GET
export const GET_FEES_OPTIONS_LIST = "/list/get-fees-options-list"; // METHOD: GET

export const GET_USERS_BY_MULTIPLE_COMPANIES_LIST = "/list/get-users-by-multiple-companies-list"; // METHOD: POST

export const GET_USER_OPTIONS_LIST_NEW = "/list/get-user-options-list-new"; // METHOD: GET
export const GET_COMPANY_OPTIONS_LIST_NEW = "/list/get-company-options-list-new"; // METHOD: GET
export const GET_FEES_OPTIONS_LIST_NEW = "/list/get-fees-options-list-new"; // METHOD: GET

export const GET_AUDIT_LOG_LIST = "/audit-logs/get-audit-log-list"; // METHOD: POST
export const GET_ATTACHMENTS_LIST = "/attachments/get-attachments-list"; // METHOD: POST

export const UPDATE_NOTIFICATION_STATUS = "/notifications/update-notification-status"; // PARAMETERS: /{module_app_id}/{module_main_id}/{module_sub_id} // METHOD: GET
export const SET_NOTIFICATION_READ_UNREAD = "/notifications/set-notification-read-unread"; // PARAMETERS: /{id}/{is_read} // METHOD: GET
export const SET_NOTIFICATION_READ_ALL = "/notifications/set-notification-read-all"; // METHOD: GET
export const GET_NOTIFICATION_COUNT = "/notifications/get-notification-count"; // METHOD: GET
export const GET_NOTIFICATION_LIST = "/notifications/get-notification-list"; // METHOD: POST
export const GET_NOTIFICATIONS_LIST = "/notifications/get-notifications-list"; // METHOD: POST

export const HANDLE_ARCHIVE = "/common/handle-archive"; // METHOD: POST

export const SHORTCUT = {
  CREATE: "/shortcut/create", // METHOD: POST
  DELETE: "/shortcut/delete", // METHOD: POST
  GET_ALL: "/shortcut/get-all", // METHOD: POST
};

// TIME SHEET
export const GET_TIME_SHEET = "/time-sheets/get-time-sheet"; // PARAMETERS: /{id}/{time_sheet_id} // METHOD: GET
export const GET_TIME_SHEETS_GROUP_LIST = "/time-sheets/get-time-sheets-datatable-group-list"; // METHOD: POST
export const GET_TIME_SHEETS_LIST = "/time-sheets/get-time-sheets-datatable-list"; // METHOD: POST
export const GET_TIME_SHEETS_EXPORT = "/time-sheets/get-time-sheets-datatable-export"; // METHOD: POST
export const CREATE_TIME_SHEET = "/time-sheets/create-time-sheet"; // METHOD: PUT
export const GET_TIME_SHEET_FORM = "/time-sheets/get-time-sheet-form"; // PARAMETERS: /{module_id}/{module_main_id}/{module_sub_id} // METHOD: GET
export const CREATE_TIME_SHEET_FORM = "/time-sheets/create-time-sheet-form"; // METHOD: PUT
export const UPDATE_TIME_SHEET = "/time-sheets/update-time-sheet"; // METHOD: POST
export const DELETE_TIME_SHEET = "/time-sheets/delete-time-sheet"; // METHOD: POST
export const TIMESHEET_VS_AMOUNT = "/time-sheets/timesheet-vs-amount"; // METHOD: GET
export const TIME_PER_TASK = "/time-sheets/time-per-task"; // METHOD: GET
export const FEE_VS_SERVICE_CHARGE = "/time-sheets/fee-vs-service-carge"; // METHOD: GET
export const EXTERNAL_VS_INTERNAL = "/time-sheets/external-vs-internal"; // METHOD: GET
export const PER_USER_TASK_TIME = "/time-sheets/per-user-task-time"; // METHOD: GET
export const PER_USER_PER_TASK_STATUS = "/time-sheets/per-user-per-task-status"; // METHOD: GET
export const TIME_PER_TASK_PER_USER = "/time-sheets/time-per-task-per-user"; // METHOD: GET
export const PER_USER_PER_TASK_TIME_SPENT = "/time-sheets/per-user-per-tast-time-spent"; // METHOD: GET
export const TIMESHEET_COUNTS = "/time-sheets/counts"; // METHOD: GET

// TASKS
export const GET_TASK = "/tasks/get-task"; // PARAMETERS: /{id}/{task_id} // METHOD: GET
export const GET_TASKS_LIST = "/tasks/get-tasks-datatable-list"; // METHOD: POST
export const GET_TASKS_EXPORT = "/tasks/get-tasks-datatable-export"; // METHOD: POST
export const GET_TASKS_SUB_LIST = "/tasks/get-sub-tasks-datatable-list"; // METHOD: POST
export const GET_SUB_TASKS_EXPORT = "/tasks/get-sub-tasks-datatable-export"; // METHOD: POST
export const CREATE_TASK = "/tasks/create-task"; // METHOD: PUT
export const UPDATE_TASK = "/tasks/update-task"; // METHOD: POST
export const DELETE_TASK = "/tasks/delete-task"; // METHOD: POST
export const GET_USER_TASK_UPCOMMING = "/tasks/get-user-task-up-comming"; // METHOD: POST
export const GET_USER_TASK_INPROCESS = "/tasks/get-user-task-in-process"; // METHOD: POST
export const ADD_FLAG_TASK = "/tasks/add-flag"; // METHOD: POST
export const REMOVE_FLAG_TASK = "/tasks/remove-flag"; // METHOD: POST
export const GET_TASK_INVOICED_BUDGET = "/tasks/get-task-invoiced-budget"; // METHOD: GET

// TASK ALLOCATION
export const GET_TASK_ALLOCATION = "/tasks-report/get-task-allocation"; // PARAMETERS: /{id}/{task_id} // METHOD: GET
export const GET_TASKS_ALLOCATION_LIST = "/tasks-report/get-tasks-allocation-list"; // METHOD: POST
export const GET_STATUS_WISE_TASK_COUNT = "/tasks-report/get-status-wise-task-count"; // METHOD: POST
export const GET_ORIGINATED_WISE_TASK_LIST = "/tasks-report/get-originated-from-task-types-list"; // METHOD: POST
export const GET_TASK_BY_COUNTRY = "/tasks-report/get-tasks-by-country"; // METHOD: POST
export const GET_TASK_GENERATED_BY_QUESTIONNAIRE = "/tasks-report/get-tasks-generated-by-questionnaire"; // METHOD: POST
export const GET_INTERNALTASK_VS_EXTERNALTASK = "/tasks-report/get-internaltask-vs-externaltask"; // METHOD: POST
export const GET_ORIGINATED_WISE_TASK_AMOUNT_LIST =
  "/tasks-report/get-originated-from-task-types-amount-list"; // METHOD: POST

// INVOICES
export const GET_INVOICE_NO = "/invoices/get-invoice-number"; // METHOD: GET
export const GET_REFERENCE_NO = "/invoices/get-reference-number"; // METHOD: GET
export const GET_INVOICE = "/invoices/get-invoice"; // PARAMETERS: /{id} // METHOD: GET
export const GET_INVOICES_LIST = "/invoices/get-all"; // METHOD: POST
export const GET_INVOICES = "/invoices/get-list"; // METHOD: Get
export const GET_NOT_PAID_INVOICES = "/invoices/get-not-paid-invoices"; // METHOD: Get PARAMETERS: /{contact_person_id}
export const SEND_INVOICE = "/invoices/send-invoice"; // METHOD: PUT
export const UNCHECK_INVOICE = "/invoices/uncheck-invoice"; // METHOD: PUT
export const EDIT_INVOICE = "/invoices/editing-invoice"; // METHOD: PUT PARAMETERS: /{id}/{invoice_id}/{is_editing}
export const ADD_TO_ACT = "/invoices/add-to-act"; // METHOD: PUT
export const CREATE_INVOICE = "/invoices/create-invoice"; //METHOD: POST
export const CREATE_CREDIT_NOTE = "/invoices/create-credit-note"; //METHOD: POST
export const UPDATE_INVOICE = "/invoices/update-invoice"; //METHOD: PUT
export const REMINDERS = "/invoices/reminders"; //METHOD: PUT
export const RECEIVE_PAYMENT = "/invoices/receive-payment"; //METHOD: POST
export const REFUND = "/invoices/refund"; //METHOD: POST
export const CARRY_FORWARD_INVOICE = "/invoices/carry-forward-invoice"; //METHOD: POST
export const CANCEL_INVOICE = "/invoices/cancel"; //METHOD: POST
export const GET_PAYMENTS = "/invoices/payment-history"; //METHOD: GET // PARAMETERS: /{id}
export const GET_REMINDERS = "/invoices/reminder-history"; //METHOD: GET // PARAMETERS: /{id}
export const GET_ANALYTICS = "/invoices/analytics"; //METHOD: GET
export const GET_INVOICE_PER_TYPE = "/invoices/invoice-per-task"; //METHOD: GET // PARAMETERS: /{range}
export const GET_SCHEDULE_OF_PAYMENT_CLIENTS_HISTORY = "/invoices/schedule-payment-history"; //METHOD: GET // PARAMETERS: /{range}
export const GET_CASH_FLOW = "/invoices/cash-flow"; //METHOD: GET // PARAMETERS: /{range}
export const GET_INVOICE_TYPE = "/invoices/invoice-type"; //METHOD: GET // PARAMETERS: /{range}
export const GET_TOTAL_VS_SERVICE_CHARGE = "/invoices/total-vs-service-charge"; //METHOD: GET // PARAMETERS: /{range}
export const GET_SALES_REPORT = "/invoices/sales-report"; //METHOD: GET // PARAMETERS: /{year}
export const GET_REMINDERS_DATA = "/invoices/reminders"; //METHOD: GET // PARAMETERS: /{year}
export const GET_ACCOUNT_DATA = "/invoices/account-data"; //METHOD: GET // PARAMETERS: /{year}
export const GET_TOP_CLIENTS = "/invoices/top-clients"; //METHOD: GET // PARAMETERS: /{year}
export const GET_INVOICE_GENERATED_FROM_TIMESHEET = "/invoices/invoice-from-timesheet"; //METHOD: GET // PARAMETERS: /{year}
export const GET_INVOICE_TRENDS = "/invoices/invoice-trends"; //METHOD: GET 
export const DOWNLOAD_ACT = "/invoices/export-act"; //METHOD: GET // 
export const DOWNLOAD_INVOICE = "/invoices/download"; //METHOD: GET // PARAMETERS: /{invoice}
export const UPLOAD_CSV = "/invoices/upload-csv"; //METHOD: POST // PARAMETERS: /{file}
export const UPLOAD_ACT = "/invoices/upload-act"; //METHOD: POST // PARAMETERS: /{file}

// CPO
// export const CREATE_CPO = "/cpo/create-cpo"; //METHOD: POST
// export const UPDATE_CPO = "/cpo/update-cpo"; //METHOD: PUT
// export const GET_All_CPOS = "/cpo/get-all"; // METHOD: POST
// export const GET_CPO = "/cpo/get-cpo"; // METHOD: GET // PARAMETERS: /{id}

// ACCOUNT
export const CREATE_ACCOUNT = "/account/create"; //METHOD: POST
export const UPDATE_ACCOUNT = "/account/update"; //METHOD: PUT
export const GET_All_ACCOUNT = "/account/get-all"; // METHOD: POST
export const GET_ACCOUNT = "/account/get-vat"; // METHOD: GET // PARAMETERS: /{id}
export const DELETE_ACCOUNT = "/account/delete"; // METHOD: DELETE // PARAMETERS: /{id}

// DOCUMENT_TYPE
export const CREATE_DOCUMENT_TYPE = "/document-type/create"; //METHOD: POST
export const UPDATE_DOCUMENT_TYPE = "/document-type/update"; //METHOD: PUT
export const GET_All_DOCUMENT_TYPE = "/document-type/get-all"; // METHOD: POST
export const GET_All_DOCUMENT_TYPE_LIST = "/document-type/get-all-list"; // METHOD: GET
export const GET_DOCUMENT_TYPE = "/document-type/get"; // METHOD: GET // PARAMETERS: /{id}
export const DELETE_DOCUMENT_TYPE = "/document-type/delete"; // METHOD: DELETE // PARAMETERS: /{id}

// VAT
export const CREATE_VAT = "/vat/create"; //METHOD: POST
export const UPDATE_VAT = "/vat/update"; //METHOD: PUT
export const GET_All_VAT = "/vat/get-all"; // METHOD: POST
export const GET_VAT = "/vat/get-vat"; // METHOD: GET // PARAMETERS: /{id}
export const DELETE_VAT = "/vat/delete"; // METHOD: DELETE // PARAMETERS: /{id}

// KANBAN TASKS
export const GET_KANBAN_TASK = "/kanbanboard/get-task"; // PARAMETERS: /{id}/{task_id} // METHOD: GET
export const GET_KANBAN_TASKS_LIST = "/kanbanboard/get-kanban-tasks-list"; // METHOD: POST
export const CREATE_KANBAN_TASK = "/kanbanboard/create-task"; // METHOD: PUT
export const UPDATE_KANBAN_TASK = "/kanbanboard/update-task-status"; // METHOD: POST
export const DELETE_KANBAN_TASK = "/kanbanboard/delete-task"; // METHOD: POST
export const GET_KANBAN_SEARCH = "/kanbanboard/get-kanban-search-list";

// USERS
export const GET_USER_PROFILE = "/users/get-user-profile"; // METHOD: GET
export const GET_USER = "/users/get-user"; // PARAMETERS: /{id}/{user_id} // METHOD: GET
export const GET_USERS_LIST = "/users/get-users-datatable-list"; // METHOD: POST
export const GET_USERS_EXPORT = "/users/get-users-datatable-export"; // METHOD: POST
export const GET_USERS_EXPORT_CSV = "/users/get-users-datatable-export-csv"; // METHOD: POST
export const CREATE_USER = "/users/create-user"; // METHOD: PUT
export const UPDATE_USER = "/users/update-user"; // METHOD: POST
export const DELETE_USER = "/users/delete-user"; // METHOD: POST
export const REGISTER_USER = "/users/register-user"; // METHOD: PUT
export const USER_CHART_DATA = "/users/get-chart-data"; //METHOD: get
export const ACTIVE_VS_NON_ACTIVE = "/users/active-vs-nonactive"; //METHOD: get
export const RESIDENT_VS_NON_RESIDENT = "/users/resident-vs-nonresident"; //METHOD: get
export const INDIVIDUAL_VS_COMPANY = "/users/individual-vs-company"; //METHOD: get
export const USER_VS_EXPIRED = "/users/user-vs-expired"; //METHOD: get
export const USER_VS_ARCHIEVE = "/users/user-vs-archived"; //METHOD: get
export const IMPATRIATES_DATA = "/users/impatriates-user-data"; //METHOD: get
export const TOTAL_USER_STATICS = "/users/total-user-statics"; //METHOD: get
export const NOTIFICATION_UPDATE = "/user-notification/update" //METHOD: get
export const NOTIFICATION_DATA = "/user-notification/get" //METHOD: get

// MASTERS -------------------------------------------------------------------------------- START

// USER FUNCTION
export const CREATE_USER_FUNCTION = "/masters/user-function/create-user-function"; // METHOD: PUT
export const GET_USER_FUNCTION = "/masters/user-function/get-user-function"; // PARAMETERS: /{id}/{access_role_id} // METHOD: GET
export const GET_USER_FUNCTION_LIST = "/masters/user-function/get-user-function-datatable-list"; // METHOD: POST
export const UPDATE_USER_FUNCTION = "/masters/user-function/update-user-function"; // METHOD: POST
export const DELETE_USER_FUNCTION = "/masters/user-function/delete-user-function"; // METHOD: POST

// USER TYPE
export const CREATE_USER_TYPE = "/masters/user-type/create-user-type"; // METHOD: PUT
export const GET_USER_TYPE = "/masters/user-type/get-user-type"; // PARAMETERS: /{id}/{user_type_id} // METHOD: GET
export const GET_USER_TYPE_LIST = "/masters/user-type/get-user-type-datatable-list"; // METHOD: POST
export const UPDATE_USER_TYPE = "/masters/user-type/update-user-type"; // METHOD: POST
export const DELETE_USER_TYPE = "/masters/user-type/delete-user-type"; // METHOD: POST

// USER STATUS
export const CREATE_USER_STATUS = "/masters/user-status/create-user-status"; // METHOD: PUT
export const GET_USER_STATUS = "/masters/user-status/get-user-status"; // PARAMETERS: /{id}/{user_status_id} // METHOD: GET
export const GET_USER_STATUS_LIST = "/masters/user-status/get-user-status-datatable-list"; // METHOD: POST
export const UPDATE_USER_STATUS = "/masters/user-status/update-user-status"; // METHOD: POST
export const DELETE_USER_STATUS = "/masters/user-status/delete-user-status"; // METHOD: POST

// ACCESS ROLE
export const CREATE_ACCESS_ROLE = "/masters/access-role/create-access-role"; // METHOD: PUT
export const GET_ACCESS_ROLE = "/masters/access-role/get-access-role"; // PARAMETERS: /{id}/{access_role_id} // METHOD: GET
export const GET_ACCESS_ROLES_LIST = "/masters/access-role/get-access-role-datatable-list"; // METHOD: POST
export const UPDATE_ACCESS_ROLE = "/masters/access-role/update-access-role"; // METHOD: POST
export const DELETE_ACCESS_ROLE = "/masters/access-role/delete-access-role"; // METHOD: POST

// ACCESS MODULE
export const CREATE_ACCESS_MODULE = "/masters/access-module/create-access-module"; // METHOD: PUT
export const GET_ACCESS_MODULE = "/masters/access-module/get-access-module"; // PARAMETERS: /{id}/{access_module_id} // METHOD: GET
export const GET_ACCESS_MODULES_LIST = "/masters/access-module/get-access-module-datatable-list"; // METHOD: POST
export const UPDATE_ACCESS_MODULE = "/masters/access-module/update-access-module"; // METHOD: POST
export const DELETE_ACCESS_MODULE = "/masters/access-module/delete-access-module"; // METHOD: POST
export const GET_ALL_MODULES = "/masters/access-module/get-all-modules"; // METHOD: GET

// COMPANY MASTER
export const CREATE_COMPANY = "/masters/company/create-company"; // METHOD: PUT
export const GET_COMPANY = "/masters/company/get-company"; // PARAMETERS: /{id}/{company_id} // METHOD: GET
export const GET_COMPANY_LIST = "/masters/company/get-company-datatable-list"; // METHOD: POST
export const GET_COMPANY_EXPORT = "/masters/company/get-company-datatable-export"; // METHOD: POST
export const GET_COMPANY_EXPORT_CSV = "/masters/company/get-company-datatable-export-csv"; // METHOD: POST
export const UPDATE_COMPANY = "/masters/company/update-company"; // METHOD: POST
export const DELETE_COMPANY = "/masters/company/delete-company"; // METHOD: POST
export const DOWNLOAD_CSF = "/masters/company/export-csf"; // METHOD: GET
export const CSF_COUNT = "/masters/company/export-csf-count"; // METHOD: GET
export const GET_YEARDATA_PER_YEAR = "/masters/company/get-year-data-per-year"; // METHOD: GET
export const GET_COMPANIES_PER_QUARTER = "/masters/company/get-companies-per-quarter"; // METHOD: GET 
export const ACTIVE_VS_NON_ACTIVE_COMPANY = "/masters/company/active-vs-nonactive"; //METHOD: get
export const COMPANY_VS_ARCHIEVE_COMPANY = "/masters/company/company-vs-archived"; //METHOD: get
export const COMPANY_CHART_DATA = "/masters/company/get-chart-data"; //METHOD: get
export const COMPANY_VS_EMPLOYEE = "/masters/company/company-vs-employee"; //METHOD: get

// COMPANY CATEGORY MASTER
export const CREATE_COMPANY_CATEGORY = "/masters/company-category/create-company-category"; // METHOD: PUT
export const GET_COMPANY_CATEGORY = "/masters/company-category/get-company-category"; // PARAMETERS: /{id}/{category_id} // METHOD: GET
export const GET_COMPANY_CATEGORY_LIST =
  "/masters/company-category/get-company-category-datatable-list"; // METHOD: POST
export const UPDATE_COMPANY_CATEGORY = "/masters/company-category/update-company-category"; // METHOD: POST
export const DELETE_COMPANY_CATEGORY = "/masters/company-category/delete-company-category"; // METHOD: POST

// FEES MODULE
export const CREATE_FEE = "/masters/fees/create-fees"; // METHOD: PUT
export const GET_FEE = "/masters/fees/get-fees"; // PARAMETERS: /{id}/{access_module_id} // METHOD: GET
export const GET_FEES_LIST = "/masters/fees/get-fees-datatable-list"; // METHOD: POST
export const UPDATE_FEE = "/masters/fees/update-fees"; // METHOD: POST
export const DELETE_FEE = "/masters/fees/delete-fees"; // METHOD: POST

// TASK TYPE
export const CREATE_TASK_TYPE = "/masters/task-type/create-task-type"; // METHOD: PUT
export const GET_TASK_TYPE = "/masters/task-type/get-task-type"; // PARAMETERS: /{id}/{access_module_id} // METHOD: GET
export const GET_TASK_TYPES_LIST = "/masters/task-type/get-task-type-datatable-list"; // METHOD: POST
export const UPDATE_TASK_TYPE = "/masters/task-type/update-task-type"; // METHOD: POST
export const DELETE_TASK_TYPE = "/masters/task-type/delete-task-type"; // METHOD: POST

// TASK STATUS
export const CREATE_TASK_STATUS = "/masters/task-status/create-task-status"; // METHOD: PUT
export const GET_TASK_STATUS = "/masters/task-status/get-task-status"; // PARAMETERS: /{id}/{access_module_id} // METHOD: GET
export const GET_TASK_STATUS_LIST = "/masters/task-status/get-task-status-datatable-list"; // METHOD: POST
export const UPDATE_TASK_STATUS = "/masters/task-status/update-task-status"; // METHOD: POST
export const DELETE_TASK_STATUS = "/masters/task-status/delete-task-status"; // METHOD: POST

// TASK PRIORITY
export const CREATE_TASK_PRIORITY = "/masters/task-priority/create-task-priority"; // METHOD: PUT
export const GET_TASK_PRIORITY = "/masters/task-priority/get-task-priority"; // PARAMETERS: /{id}/{access_module_id} // METHOD: GET
export const GET_TASK_PRIORITY_LIST = "/masters/task-priority/get-task-priority-datatable-list"; // METHOD: POST
export const UPDATE_TASK_PRIORITY = "/masters/task-priority/update-task-priority"; // METHOD: POST
export const DELETE_TASK_PRIORITY = "/masters/task-priority/delete-task-priority"; // METHOD: POST

// SUBTASK
export const GET_SUB_TASK = "/subtask/get-sub-task"; // PARAMETERS: /{id}/{task_id} // METHOD: GET
export const GET_SUB_TASKS_LIST = "/subtask/get-sub-tasks-datatable-list"; // METHOD: POST
// export const GET_SUB_TASKS_EXPORT = "/subtask/get-sub-tasks-datatable-export"; // METHOD: POST
export const CREATE_SUB_TASK = "/subtask/create-sub-task"; // METHOD: PUT
export const UPDATE_SUB_TASK = "/subtask/update-sub-task"; // METHOD: POST
export const DELETE_SUB_TASK = "/subtask/delete-sub-task"; // METHOD: POST
export const UPDATE_MUTIPLE_SUB_TASK_STATUS = "/subtask/update-multiple-subtask-status"; // METHOD: POST
export const GET_SUB_TASK_LIST_R_PERSON = "/subtask/get-list-of-resposible"; // METHOD: POST


//REGION MASTER
export const CREATE_REGION =
  "/masters/region/create"; // METHOD: PUT
export const GET_REGION_LIST =
  "/masters/region/get-region-datatable-list"; // METHOD: POST
export const UPDATE_REGION =
  "/masters/region/update"; // METHOD: POST
export const DELETE_REGION =
  "/masters/region/delete"; // METHOD: POST

// QUESTIONNAIRE STATUS
export const CREATE_QUESTIONNAIRE_STATUS =
  "/masters/questionnaire-status/create-questionnaire-status"; // METHOD: PUT
export const GET_QUESTIONNAIRE_STATUS = "/masters/questionnaire-status/get-questionnaire-status"; // PARAMETERS: /{id}/{access_module_id} // METHOD: GET
export const GET_QUESTIONNAIRE_STATUS_LIST =
  "/masters/questionnaire-status/get-questionnaire-status-datatable-list"; // METHOD: POST
export const UPDATE_QUESTIONNAIRE_STATUS =
  "/masters/questionnaire-status/update-questionnaire-status"; // METHOD: POST
export const DELETE_QUESTIONNAIRE_STATUS =
  "/masters/questionnaire-status/delete-questionnaire-status"; // METHOD: POST

// QUESTIONNAIRE TAB
export const CREATE_QUESTIONNAIRE_TAB = "/masters/questionnaire-tab/create-questionnaire-tab"; // METHOD: PUT
export const GET_QUESTIONNAIRE_TAB = "/masters/questionnaire-tab/get-questionnaire-tab"; // PARAMETERS: /{id}/{access_module_id} // METHOD: GET
export const GET_QUESTIONNAIRE_TAB_LIST =
  "/masters/questionnaire-tab/get-questionnaire-tab-datatable-list"; // METHOD: POST
export const GET_QUESTIONNAIRE_TABS = "/masters/questionnaire-tab/get-questionnaire-tab-list"; // METHOD: POST
export const UPDATE_QUESTIONNAIRE_TAB = "/masters/questionnaire-tab/update-questionnaire-tab"; // METHOD: POST
export const DELETE_QUESTIONNAIRE_TAB = "/masters/questionnaire-tab/delete-questionnaire-tab"; // METHOD: POST

// QUESTIONNAIRE TYPE
export const CREATE_QUESTIONNAIRE_TYPE = "/masters/questionnaire-type/create-questionnaire-type"; // METHOD: PUT
export const GET_QUESTIONNAIRE_TYPE = "/masters/questionnaire-type/get-questionnaire-type"; // PARAMETERS: /{id}/{access_module_id} // METHOD: GET
export const GET_QUESTIONNAIRE_TYPE_LIST =
  "/masters/questionnaire-type/get-questionnaire-type-datatable-list"; // METHOD: POST
export const GET_QUESTIONNAIRE_TYPES = "/masters/questionnaire-type/get-questionnaire-type-list"; // METHOD: POST
export const UPDATE_QUESTIONNAIRE_TYPE = "/masters/questionnaire-type/update-questionnaire-type"; // METHOD: POST
export const EXPORT_QUESTIONNAIRE_TYPE = "/masters/questionnaire-type/export-questionnaire-type"; // METHOD: POST
export const DELETE_QUESTIONNAIRE_TYPE = "/masters/questionnaire-type/delete-questionnaire-type"; // METHOD: POST
export const ACTIVATE_QUESTIONNAIRE_TYPE = "/masters/questionnaire-type/activate-questionnaire-type"; // METHOD: POST
export const GET_QUESTIONNAIRE_PREVIEW =
  "/masters/questionnaire-type/get-questionnaire-type-preview"; // METHOD: POST

// QUESTIONNAIRE questions
export const CREATE_QUESTIONNAIRE_QUESTIONS =
  "/masters/questionnaire-questions/create-questionnaire-questions"; // METHOD: PUT
export const GET_QUESTIONNAIRE_QUESTIONS_LIST =
  "/masters/questionnaire-questions/get-questionnaire-questions-list"; // PARAMETERS: /{id}/{access_module_id} // METHOD: GET
export const GET_QUESTIONNAIRE_QUESTIONS_LIST_WITH_ID =
  "/masters/questionnaire-questions/get-questionnaire-questions-list-with-id"; // PARAMETERS: /{id}/{access_module_id} // METHOD: GET
export const GET_QUESTIONNAIRE_QUESTIONS_DATATABLE_LIST =
  "/masters/questionnaire-questions/get-questionnaire-questions-datatable-list"; // METHOD: POST
export const UPDATE_QUESTIONNAIRE_QUESTIONS =
  "/masters/questionnaire-questions/update-questionnaire-questions"; // METHOD: POST
export const DELETE_QUESTIONNAIRE_QUESTIONS =
  "/masters/questionnaire-questions/delete-questionnaire-questions"; // METHOD: POST
export const GET_QUESTIONS_ID = "/masters/questionnaire-questions/get-questionnaire-question"; // METHOD: GET

// QUESTIONNAIRE user data
export const CREATE_QUESTIONNAIRE_USER_DATA =
  "/questionnaire-userdata/create-questionnaire-userdata"; // METHOD: PUT
export const SUBMIT_QUESTIONNAIRE_USER_DATA =
  "/questionnaire-userdata/submit-questionnaire-userdata"; // METHOD: PUT
export const INCOMPLETE_QUESTIONNAIRE_USER_DATA =
  "/questionnaire-userdata/incomplete-questionnaire-userdata"; // METHOD: PUT
export const UPDATE_QUESTIONNAIRE_USER_STATUS =
  "/questionnaire-userdata/update-questionnaire-user-status"; // METHOD: POST
export const GET_QUESTIONNAIRE_USER_DATA_LIST =
  "/questionnaire-userdata/get-questionnaire-userdata-list"; // PARAMETERS: /{id}/{access_module_id} // METHOD: GET
export const GET_QUESTIONNAIRE_USER_DATA_DATATABLE_LIST =
  "/questionnaire-userdata/get-questionnaire-userdata-datatable-list"; // METHOD: POST
export const UPDATE_QUESTIONNAIRE_USER_DATA =
  "/questionnaire-userdata/update-questionnaire-userdata"; // METHOD: POST
export const DELETE_QUESTIONNAIRE_USER_DATA =
  "/questionnaire-userdata/delete-questionnaire-userdata"; // METHOD: POST

//QUESTIONNAIRE
export const CREATE_QUESTIONNAIRE = "/questionnaire/create-questionnaire"; // METHOD: PUT
export const GET_QUESTIONNAIRE_DATATABLE_LIST = "/questionnaire/get-questionnaire-datatable-list"; // METHOD: POST
export const GET_QUESTIONNAIRE_DATATABLE_EXPORT = "/questionnaire/get-questionnaire-datatable-export"; // METHOD: POST
export const GET_QUESTIONNAIRE_LIST = "/questionnaire/get-questionnaire-list"; // METHOD: POST
export const GET_QUESTIONNAIRE_FULL_DATA = "/questionnaire/get-questionnaire-full-data"; // METHOD: POST
export const DELETE_QUESTIONNAIRE = "/questionnaire/delete-questionnaire"; // METHOD: POST
export const TERMINATE_QUESTIONNAIRE = "/questionnaire/terminate-questionnaire"; // METHOD: POST
export const GET_QUESTIONNAIRE_ANALYTICS = "/questionnaire/analytics"; // METHOD: POST
export const ALL_TYPES = "/questionnaire/all-types"; // METHOD: POST
export const GET_YEAR_DATA = "/questionnaire/get-year-data"; // METHOD: POST
export const GET_CHART_DATA = "/questionnaire/get-chart-data"; // METHOD: POST
export const SEND_QUESTIONNAIRES_TASK = "/questionnaire/send-questionnaires-task"; // METHOD: POST
export const GET_QUESTIONNAIRE_DATA = "/questionnaire/get-questionnaire-data"; // METHOD: GET
export const UPLOAD_SIGNATURE = "/questionnaire/upload-signature"; //METHOD: POST // PARAMETERS: /{file}
export const REDIRECT_URL = "/questionnaire/redirect-url"; // METHOD: GET
export const ADD_CALENDER_DATA = "/questionnaire/add-calendar-data"; // METHOD: POST
export const GET_CALENDER_DATA = "/questionnaire/get-calendar-data"; // METHOD: POST
export const GET_CALENDER_RANGE_DATA = "/questionnaire/get-calendar-range-data"; // METHOD: POST
export const ADD_CALENDER_RANGE_DATA = "/questionnaire/add-calendar-range-data"; // METHOD: POST
export const ADD_USER_CALENDER_DATA = "/questionnaire/add-user-calendar-data"; // METHOD: POST
export const ADD_WORK_DAY_CALENDER = "/questionnaire/add-work-day-calender"; // METHOD: POST
export const REMOVE_USER_CALENDER_DATA = "/questionnaire/remove-user-calendar-data"; // METHOD: POST

export const GET_USER_ALERT_UPCOMING = "/alert/get-alert-upcoming";
export const GET_USER_ALERT_INPROCESS = "/alert/get-alert-inprogress";
export const GET_USER_ALERT_FOLLOWUP = "/alert/get-alert-followup";
export const GET_USER_ALERT_LIST = "/alert/get-alert-all";
export const GET_USER_COMPANY_ALERT_LIST = "/alert/get-alert-process-todo";
export const GET_USER_COMPANY_REMINDER_LIST = "/alert/get-reminder-process-todo";

// PROJECTS
export const PROJECTS = {
  CREATE: "/project/create", // METHOD: POST
  UPDATE: "/project/update", // METHOD: PUT
  DELETE: "/project/delete", // METHOD: DELETE // PARAMETERS: /{id}
  GET: "/project/get", // METHOD: GET // PARAMETERS: /{id}
  GET_ALL: "/project/get-all", // METHOD: POST
};

// PROJECTS
export const ACCESS_ROLE = {
  CREATE: "/access-role/create", // METHOD: POST
  UPDATE: "/access-role/update", // METHOD: PUT
  DELETE: "/access-role/delete", // METHOD: DELETE // PARAMETERS: /{id}
  GET: "/access-role/get", // METHOD: GET // PARAMETERS: /{id}
  GET_ALL: "/access-role/get-all", // METHOD: POST
  GET_LIST: "/access-role/get-all-list", // METHOD: POST
  GET_USER_ACCESS: "/access-role/get-user-access", // METHOD: GET // PARAMETERS: /{user_id}
  UPDATE_USER_ACCESS: "/access-role/update-user-access", // METHOD: PUT
};

// DISPUTE
export const DISPUTE = {
  CREATE: "/dispute/create", // METHOD: POST
  UPDATE: "/dispute/update", // METHOD: PUT // PARAMETERS: /{id}
  ACCEPT: "/dispute/accept", // METHOD: PUT // PARAMETERS: /{id}
  REJECT: "/dispute/reject", // METHOD: PUT // PARAMETERS: /{id}
  CREDIT_NOTE: "/dispute/credit-note", // METHOD: PUT // PARAMETERS: /{id}
  DELETE: "/dispute/delete", // METHOD: DELETE // PARAMETERS: /{id}
  GET: "/dispute/get", // METHOD: GET // PARAMETERS: /{id}
  GET_ALL: "/dispute/get-all", // METHOD: GET //PARAMETERS: /{id}
  GET_DISPUTE_COUNT: "/dispute/get-dispute-count", // METHOD: GET 
  VALIDATE: "/invoices/validate-company", // METHOD: POST
};

// COUNTRY
export const COUNTRY = {
  CREATE: "/country/create", // METHOD: POST
  UPDATE: "/country/update", // METHOD: PUT // PARAMETERS: /{id}
  DELETE: "/country/delete", // METHOD: DELETE // PARAMETERS: /{id}
  GET: "/country/get", // METHOD: GET // PARAMETERS: /{id}
  GET_ALL: "/country/get-all", // METHOD: POST
  TOGGLE_ON_OFF: "/country/toggle-all" // METHOD: POST
};

// VATCAT
export const VATCAT = {
  CREATE: "/vatcat/create", // METHOD: POST
  UPDATE: "/vatcat/update", // METHOD: PUT
  DELETE: "/vatcat/delete", // METHOD: DELETE // PARAMETERS: /{id}
  GET: "/vatcat/get", // METHOD: GET // PARAMETERS: /{id}
  GET_ALL: "/vatcat/get-all", // METHOD: POST
};

// NEWSFEED
export const NEWSFEED = {
  CREATE: "/news-feed/create", // METHOD: POST
  UPDATE: "/news-feed/update", // METHOD: PUT
  DELETE: "/news-feed/delete", // METHOD: DELETE // PARAMETERS: /{id}
  GET: "/news-feed/get", // METHOD: GET // PARAMETERS: /{id}
  GET_ALL: "/news-feed/get-all", // METHOD: POST
  GET_LIST: "/news-feed/get-list", // METHOD: GET
};

// SPLIT PAYROLL
export const SPLIT_PAYROLL = {
  CREATE: "/split-payroll/create", //METHOD: PUT
  UPDATE: "/split-payroll/update", //METHOD: POST
  DELETE: "/split-payroll/delete", // METHOD: POST
  GET: "/split-payroll/get", // METHOD: GET // PARAMETERS: /{id}/{split_payroll_id}
  GET_ALL: "/split-payroll/get-all", // METHOD: POST
  GET_SP_COUNTRY: "/split-payroll/get-sp-country", // METHOD: GET
  GET_SP_YEAR: "/split-payroll/get-sp-year", // METHOD: GET
  REPORT: "/split-payroll/report", // METHOD: GET // PARAMETERS: /{id}/{split_payroll_id}
  GET_DAILY_COUNTER: "/split-payroll/graph/get-daily-counter", // METHOD: GET 
  GET_COMPANY_VS_INDIVIDUAL: "/split-payroll/graph/get-company-vs-individual", // METHOD: GET 
  GET_CREATED_BY_COMPANY: "/split-payroll/graph/get-created-by-company", // METHOD: GET 

};

// CPO
export const CPO = {
  CREATE: "/cpo/create", //METHOD: PUT
  UPDATE: "/cpo/update", //METHOD: POST
  DELETE: "/cpo/delete", // METHOD: POST
  GET: "/cpo/get", // METHOD: GET // PARAMETERS: /{id}/{cpo_id}
  GET_ALL: "/cpo/get-all", // METHOD: POST
  GET_SP_COUNTRY: "/cpo/get-sp-country", // METHOD: GET
  GET_SP_YEAR: "/cpo/get-sp-year", // METHOD: GET
  REPORT: "/cpo/report", // METHOD: GET // PARAMETERS: /{id}/{cpo_id}
  GET_CPO_INDIVIDUAL_HITS_COUNT: '/cpo/graph/dailycpohitscount', // METHOD: GET
  GET_CPO_HITS_COUNT: '/cpo/graph/cpo-hits', // METHOD: GET
  GET_WEBSITEVSCUSTOMER: '/cpo/graph/website-users-vs-customer', // METHOD: GET
  GET_WEBSITEUSER_CUSTOMER: '/cpo/graph/website-user-become-customer', // METHOD: GET
};

// SPLIT PAYROLL COUNTRY
export const SPLIT_PAYROLL_COUNTRY = {
  CREATE: "/masters/split-payroll-country/create", //METHOD: PUT
  UPDATE: "/masters/split-payroll-country/update", //METHOD: POST
  DELETE: "/masters/split-payroll-country/delete", // METHOD: POST
  GET_ALL: "/masters/split-payroll-country/get-all", // METHOD: POST
};
// SPLIT PAYROLL COUNTRY
export const SPLIT_PAYROLL_YEAR = {
  CREATE: "/masters/split-payroll-year/create", //METHOD: PUT
  UPDATE: "/masters/split-payroll-year/update", //METHOD: POST
  DELETE: "/masters/split-payroll-year/delete", // METHOD: POST
  GET_ALL: "/masters/split-payroll-year/get-all", // METHOD: POST
};

// SENTiMENTS
export const SENTiMENTS = {
  UPDATE: "/sentiments/update", // METHOD: PUT
  GET: "/sentiments/get", // METHOD: GET // PARAMETERS: /{module_app_id}/{module_main_id}/{module_sub_id}/{section_id}
};

// USER GENERAL
export const USER_GENERAL = {
  UPDATE_GENERAL: "/user-general/update-user-general", //METHOD POST
  UPDATE_TAXES: "/user-general/update-user-taxes", //METHOD POST
  GET: "/user-general/get-user-general", //METHOD GET
  GETOPTION: "/user-general/get-select-options-list", //METHOD GET,
  SEND_QUESTIONNAIRE: "/user-general/send-questionnaire", //METHOD POST,
};

// COMPANY GENERAL SERVICE
export const COMPANY_GENERAL = {
  UPDATE: '/masters/company-general/update-company-general',
  GET: '/masters/company-general/get-company-general'
}

export const EMAIL = {
  GET_INBOX_MAILS: '../',
  SEARCH_USER: "/email/search/user", // METHOD: POST
  SEARCH_TEMPLATE: "/email/search/template", // METHOD: POST
  GET_USER_LIST: "/email/user/list", // METHOD: POST
  GET_EMAIL_TEMPLATE_LIST: "/email/template/list", // METHOD: POST
  CREATE_EMAIL_TEMPLATE: "/email/template/create", // METHOD: POST
  UPDATE_EMAIL_TEMPLATE: "/email/template/update", // METHOD: POST
  DELETE_EMAIL_TEMPLATE: "/email/template/delete", // METHOD: POST
  DUPLICATE_EMAIL_TEMPLATE: "/email/template/duplicate", // METHOD: POST
  GET_EMAIL_TEMPLATE: "/email/template/get", // METHOD: POST
  SEARCH_ATTACHMENT: "/email/search/attachment", // METHOD: POST
  GET_EMAIL_TEMPLATE_VIEW: "/email/template/get-email-template", // METHOD: GET
  GET_EMAIL_CATEGORY_LIST: '/email/template/get-email-category-list', //METHOD : GET
  SEND_EMAIL: '/email/send', //METHOD : POST
  TRASH_EMAIL: '/email/trash', //METHOD : POST
  DRAFT_EMAIL: '/email/draft', //METHOD : POST
  GET_DRAFT_MAIL_ATTACHMENT: '/email/draft-attachment/get', //METHOD : POST
  UPLOAD_DRAFT_MAIL_ATTACHMENT: '/email/draft-attachment/upload', //METHOD : POST
  UPDATED_EMAIL_ATTACHMENT_STATUS: '/email/template/update-attachment-status', //METHOD : POST
  GET_CONTACT_PERSON: '/email/person/get', //METHOD : POST
  DELETE_DRAFT_MAIL_ATTACHMENN: '/email/draft-attachment/delete', //METHOD : DELETE
  CREATE_DRAFT_MAIL: '/email/draft/create',
  GET_FILE_SYSTEM_LIST: '/email/filesystem/get',
  GET_COMMENT_LIST: '/email/task-comments/get',
  SIGNATURE: '/email/signature/save', // METHOD : POST
  GET_SIGNATURE: '/email/signature/get', // METHOD : GET
  GET_SIGNATURE_MAIL: '/email/signature/formail', // METHOD : GET
  UPDATE_SIGNATURE_MAIL: '/email/signature/update', // METHOD : GET
  GET_HTML_TO_PDF: '/email/get-html-to-pdf' // METHOD : POST
}

// USER MANUAL
export const USER_MANUAL = {
  CREATE: "/user-manual/create", //METHOD: PUT
  UPDATE: "/user-manual/update", //METHOD: POST
  DELETE: "/user-manual/delete", // METHOD: POST
  GET: "/user-manual/get", // METHOD: GET // PARAMETERS: /{id}/{user_manual_id}
  GET_ALL: "/user-manual/get-all", // METHOD: POST
};

// EMAIL CATEGORY
export const EMAIL_CATEGORY = {
  CREATE: "/email/category/create", //METHOD: PUT
  DUPLICATE: "/email/category/duplicate", //METHOD: PUT
  UPDATE: "/email/category/update", //METHOD: POST
  DELETE: "/email/category/delete", // METHOD: POST
  GET: "/email/category/get", // METHOD: GET // PARAMETERS: /{id}/{user_manual_id}
  GET_ALL: "/email/category/list", // METHOD: POST
};

export const BANK_HOLIDAY = {
  CREATE: "/bankholiday/create", // METHOD: POST
  UPDATE: "/bankholiday/update", // METHOD: PUT // PARAMETERS: /{id}
  DELETE: "/bankholiday/delete", // METHOD: DELETE // PARAMETERS: /{id}
  GET_ALL: "/bankholiday/get-all", // METHOD: POST
  CALENDER: "/bankholiday/calender", // METHOD: POST,
  LIST: "/bankholiday/list", // METHOD: POST,
  BULK_CREATE: "/bankholiday/bulkcreate"
};

// EVENT
export const EVENT = {
  TASK: "/event/task/get-all", // METHOD: GET // PARAMETERS: /{startDateTime}/{endDateTime}/{myEvents}
};

// TAX RETURN TYPE
export const TAX_RETURN_TYPE = {
  CREATE: "/masters/tax-return-type/create", //METHOD: PUT
  UPDATE: "/masters/tax-return-type/update", //METHOD: POST
  DELETE: "/masters/tax-return-type/delete", // METHOD: POST
  GET_ALL: "/masters/tax-return-type/get-all", // METHOD: POST
};

// NEWSFEED
export const NEWSLETTER = {
  CREATE: "/masters/news-letter/create", // METHOD: POST
  DUPLICATE: "/masters/news-letter/duplicate", // METHOD: POST
  UPDATE: "/masters/news-letter/update", // METHOD: PUT
  DELETE: "/masters/news-letter/delete", // METHOD: DELETE // PARAMETERS: /{id}
  GET: "/masters/news-letter/get", // METHOD: GET // PARAMETERS: /{id}
  GET_ALL: "/masters/news-letter/get-list", // METHOD: POST
  GET_LIST: "/masters/news-letter/get-list", // METHOD: POST
  UPLOAD_IMAGE: "/masters/news-letter/upload-image", // METHOD: POST
  SEND_NEWS_LETTER_MAIL: "/masters/news-letter/sendmail", // METHOD: POST
  GET_NEWS_LETTER_SCHEDULE: "/masters/news-letter/schedule/get", // METHOD: get
  CREATE_NEWS_LETTER_SCHEDULE: "/masters/news-letter/schedule/create", // METHOD: POST
  UPDATE_NEWS_LETTER_SCHEDULE: "/masters/news-letter/schedule/update", // METHOD: POST
  DELETE_NEWS_LETTER_SCHEDULE: "/masters/news-letter/schedule/delete", // METHOD: POST
};

// CLIENT ALLOCATION
export const CLIENT_ALLOCATION = {
  GET_ALL_USERS: "/client-allocation/get-all-users", // METHOD: POST
  GET_ALL_CLIENTS: "/client-allocation/get-all-clients", // METHOD: POST
  UPDATE: "/client-allocation/update", // METHOD: POST
};

// COMPANY PROFILE
export const COMPANY_PROFILE = {
  CREATE: "/masters/company-profile/create", //METHOD: PUT
  UPDATE: "/masters/company-profile/update", //METHOD: POST
  DELETE: "/masters/company-profile/delete", // METHOD: POST
  GET_ALL: "/masters/company-profile/get-all", // METHOD: POST
};

// ISSUE REPORTING
export const ISSUE_REPORTING = {
  CREATE: "/issue-reporter/create", //METHOD: PUT
  UPDATE: "/issue-reporter/update", //METHOD: POST
  DELETE: "/issue-reporter/delete", // METHOD: POST
  GET_ALL: "/issue-reporter/get-all-issue", // METHOD: POST
  GET: "/issue-reporter/get", // METHOD: POST
  GET_USER: "/issue-reporter/get-user-data", 
  GET_COMMENTS:"/issue-reporter/get-comments/",
  POST_COMMENT:"/issue-reporter/post-comment",
  GET_TH:'/issue-reporter/get-thumbnail/',
  GET_FILE:'/issue-reporter/get-file/'
};

// OPEN BANKING
export const OPEN_BANKING = {
  CONNECTIONS: {
    CREATE: "/banking/connections/create", //METHOD: PUT
    UPDATE: "/banking/connections/update", //METHOD: POST
    DELETE: "/banking/connections/delete", // METHOD: POST
    GET_ALL: "/banking/connections/get-all", // METHOD: POST
  },
  ING: {
    PSD2: {
      GET_OB_TOKEN: "/psd2/ing/oauth2/ob/token", //METHOD: GET 
      GET_TOKEN: "/psd2/ing/oauth2/token", //METHOD: GET 
      GET_CLIENT_AUTH: "/psd2/ing/oauth2/client-auth", //METHOD: GET // PARAMETERS: /{state}
      GET_ACCOUNTS_INFO: "/psd2/ing/accounts/info", //METHOD: GET // PARAMETERS: /{state}/{code}
      GET_BALANCES_INFO: "/psd2/ing/balances/info", //METHOD: GET // PARAMETERS: /{state}/{code}/{resource_id}
      GET_TRANSACTIONS_INFO: "/psd2/ing/transactions/info", //METHOD: GET // PARAMETERS: /{state}/{code}/{resource_id}
    }
  },
};

// SALT EDGE
export const SALTEDGE = {
  GET_PROVIDERS: "/banking/saltedge/providers", // METHOD: GET // PARAMETERS: /{country_code?}
  CREATE_LEADS: "/banking/saltedge/create-lead",
  GET_LEADS: "/banking/saltedge/get-lead",
  CREATE_LEADS_SESSIONS: "/banking/saltedge/create-lead-sessions",
  RECONNECT_LEADS_SESSIONS: "/banking/saltedge/reconnect-lead-sessions", // METHOD: GET // PARAMETERS: /{connection_id}
  GET_CONNECTIONS: "/banking/saltedge/get-connections", // METHOD: GET // PARAMETERS: /{customer_id}
  GET_TRANSACTIONS: "/banking/saltedge/get-transactions", // METHOD: POST
  INITIATE_PAYMENT: "/banking/saltedge/initiate-payment", // METHOD: POST
  GET_PAYMENTS: "/banking/saltedge/payments", // METHOD: GET // PARAMETERS: /{end_to_end_id}/{customer_id}
}

// Broadcast 
export const BROADCAST = {
  CREATE: "/broadcast/create", // METHOD: PUT
  UPDATE: "/broadcast/update", // METHOD: POST
  DELETE: "/broadcast/delete", // METHOD: POST
  GET_ALL: "/broadcast/get-all", // METHOD: POST
  GET: "/broadcast/get", // METHOD: POST
  READ_UNREAD: "/broadcast/read-unread", // METHOD: POST,
  COUNT: '/broadcast/get-count'
};


// Document signature
export const DOCUMENT_TEMPLATE = {
  CREATE: "/document-template/create", // METHOD: POST
  UPDATE: "/document-template/update", // METHOD: PUT
  DELETE: "/document-template/delete", // METHOD: DELETE // PARAMETERS: /{id}
  GET: "/document-template/get", // METHOD: GET // PARAMETERS: /{id}
  GET_TEMPLATE: "/document-template/get-template", // METHOD: GET // PARAMETERS: /{id}
  GET_CONTENT:"/document-template/get-content",
  CONVERT_TO_PDF : "/document-template/convert-to-pdf",
  GET_ALL: "/document-template/get-all", // METHOD: POST
  GET_LIST: "/document-template/get-list", // METHOD: GET
  SEARCH_TEMPLATE: "/document-template/search-list", // METHOD: GET,
  DOWNLOAD: "/document-template/download",
  GET_DOC : "/document-signature/get_document"
};

export const DOCUMENT_SIGNATURE = {
  GET: "/document-signature/get", // METHOD: GET // PARAMETERS: /{id}
  GET_ALL: "/document-signature/get-all", // METHOD: POST
  SEND :"/document-signature/send",
  CHANGE_STATUS : "/document-signature/change_status",
  DELETE: "/document-signature/delete",
  SEND_NOTIFICATION: "/document-signature/send_notification"
};