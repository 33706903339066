import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { getUserProfile } from "../../../helpers/functions_helper";
import { useTranslation } from "react-i18next";

const UserManual = (props: any) => {
  const { t } = useTranslation();
  const userProfile = getUserProfile("all-user-manual");
  const [userManualData, setUserManualData] = useState<any>([]);

  useEffect(() => {
    let url = window.location.pathname;
    setUserManualData(
      userProfile.USER_MANUALS.find(
        (option: any) =>
          option.module_url &&
          option.module_url.replaceAll("/", "") === url.replaceAll("/", "")
      )
    );
  }, [window.location.pathname]);

  return (
    <React.Fragment>
      {userManualData ? (
        <Link
          to={`/user-manual/list/${userManualData.id}/${userManualData.user_manual_id}`}
        >
          <button
            type="button"
            className="btn header-item noti-icon"
            title={t("Quick help")}
          >
            <span className="align-middle material-symbols-outlined fw-light">help</span>
          </button>
        </Link>
      ) : null}
    </React.Fragment>
  );
};

export default UserManual;
