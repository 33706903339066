export const initialValues: userRegistrationTypes = {
  first_name: "",
  last_name: "",
  email: "",
  mobile: "",
  is_accepted: 0,
  is_gdpr_policy_accepted: false,
  is_privacy_policy_accepted: false,
  is_terms_conditions_accepted: false,
  re_captcha_token: "",
};