import { memo } from "react"
import { Col } from "reactstrap"

const Toolbar = (props: any) => {
    return (
        <Col sm="4" md="4" className="text-end">
            <div className="dt-buttons btn-group">
                <button
                    title="Copy to clipboard"
                    className="btn btn-outline-light"
                    // onClick={() =>
                    //     copyToClipboard(`dataTable_${uid}`)
                    // }
                >
                    <i className="fa-solid fa-copy"></i>
                </button>

                <button
                    title="Export to excel"
                    className="btn btn-outline-light"
                    // onClick={() =>
                    //     exportToExcel(
                    //         `dataTable_${uid}`,
                    //         exportedFileName
                    //     )
                    // }
                >
                    <i className="fa-solid fa-file-excel"></i>
                </button>

                {/* <ExportCSVButton
                    {...toolkitProps.csvProps}
                    className="btn-outline-light"
                >
                    <i
                        className="fa-solid fa-file-csv"
                        title="Export to CSV"
                    ></i>
                </ExportCSVButton> */}

                <button
                    title="Print"
                    className="btn btn-outline-light"
                    //onClick={() => printData(`dataTable_${uid}`)}
                >
                    <i className="fa-solid fa-print"></i>
                </button>

                {/* {customButtons ? customButtons : null} */}
            </div>

            <div className="dt-buttons btn-group ms-2">
                {/* {settingTableName && (
                    <button
                        title="Settings"
                        className="btn btn-outline-light"
                        style={{ borderColor: "#ced4da" }}
                        onClick={handleDataTableSettings}
                    >
                        <i className="fa-solid fa-gear"></i>
                    </button>
                )} */}

                <button
                    title="Clear filter"
                    className="btn btn-outline-light"
                    style={{ borderColor: "#ced4da" }}
                    // onClick={async () => {
                    //     await toolkitProps.searchProps.onSearch("");
                    //     clearFilter();
                    // }}
                >
                    <i className="fa-solid fa-arrows-rotate"></i>
                </button>
            </div>
        </Col>)
}

export default memo(Toolbar);