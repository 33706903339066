import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_QUESTIONNAIRE_TYPES, GET_QUESTIONNAIRE_TYPE_DETAIL, ADD_NEW_QUESTIONNAIRE_TYPE, DELETE_QUESTIONNAIRE_TYPE, UPDATE_QUESTIONNAIRE_TYPE,EXPORT_QUESTIONNAIRE_TYPE, GET_QUESTIONNAIRE_TYPE_LIST, GET_QUESTIONNAIRE_PREVIEW } from "./actionTypes"
import {
    getQuestionnaireTypesSuccess,
    getQuestionnaireTypesFail,
    getQuestionnaireTypeDetailFail,
    addQuestionnaireTypeFail,
    addQuestionnaireTypesuccess,
    updateQuestionnaireTypesuccess,
    updateQuestionnaireTypeFail,
    exportQuestionnaireTypesuccess,
    exportQuestionnaireTypeFail,
    deleteQuestionnaireTypesuccess,
    deleteQuestionnaireTypeFail,
    getQuestionnaireTypeListSuccess,
    getQuestionnaireTypeListFail,
    getQuestionnairePreviewSuccess,
    getQuestionnairePreviewFail,
} from "./actions"

import { AnyAction } from 'redux'

import MastersQuestionnaireTypeService from "../../../services/masters.questionnaire.type.service"

function* fetchQuestionnaireTypes({ payload: { query, history } }: AnyAction): any {
    try {
        const response = yield call(MastersQuestionnaireTypeService.getQuestionnaireTypeDatatableList, { ...query })
        if (response && response.data && response.data.status === 'success') {
            yield put(getQuestionnaireTypesSuccess(response.data))
        } else if (response && response.status === 'error') {
            yield put(getQuestionnaireTypesFail(response))
        } else {
            yield put(getQuestionnaireTypesFail(response))
        }
    } catch (error) {
        yield put(getQuestionnaireTypesFail(error))
    }
}

function* fetchQuestionnaireTypeList(): any {
    try {
        let response: any = yield call(MastersQuestionnaireTypeService.getQuestionnaireTypeList)
        yield put(getQuestionnaireTypeListSuccess(response.data))
    } catch (error) {
        yield put(getQuestionnaireTypeListFail(error))
    }
}



function* fetchQuestionnaireTypeDetail({ statusId }: AnyAction) {
    try {
        //  const response = yield call(getQuestionnaireTypesDetails, companyId)
        // yield put(getQuestionnaireTypeDetailSuccess(response))
    } catch (error) {
        yield put(getQuestionnaireTypeDetailFail(error))
    }
}

function* onUpdateQuestionnaireType({ payload: { statusdata, history } }: AnyAction): any {
    try {
        const response = yield call(MastersQuestionnaireTypeService.updateQuestionnaireType, statusdata)
        if (response && response.data && response.data.status === 'success') {
            yield put(updateQuestionnaireTypesuccess(response.data))
        } else if (response &&  response.data &&  response.data.status === 'error') {
            yield put(updateQuestionnaireTypeFail(response))
        } else {
            yield put(updateQuestionnaireTypeFail(response))
        }
    } catch (error) {
        yield put(updateQuestionnaireTypeFail(error))
    }
}

function* onExportQuestionnaireType({ payload: { statusdata, history } }: AnyAction): any {
    try {
        const response = yield call(MastersQuestionnaireTypeService.exportQuestionnaireType, statusdata)
        if (response && response.data && response.data.status === 'success') {
            yield put(exportQuestionnaireTypesuccess(response.data))
        } else if (response && response.data && response.data.status === 'error') {
            yield put(exportQuestionnaireTypeFail(response.data))
        } else {
            yield put(exportQuestionnaireTypeFail(response))
        }
    } catch (error) {
        yield put(exportQuestionnaireTypeFail(error))
    }
}


function* onDeleteQuestionnaireType({ payload: { statusdata, history } }: AnyAction): any {
    try {
        const response = yield call(MastersQuestionnaireTypeService.deleteQuestionnaireType, statusdata)
        if (response && response.data && response.data.status === 'success') {
            yield put(deleteQuestionnaireTypesuccess(response.data))
        } else if (response && response.data && response.data.status === 'error') {
            yield put(deleteQuestionnaireTypeFail(response))
        } else {
            yield put(deleteQuestionnaireTypeFail(response))
        }

    } catch (error) {
        yield put(deleteQuestionnaireTypeFail(error))
    }
}

function* onAddNewQuestionnaireType({ payload: { statusdata, history } }: AnyAction): any {
    try {
        const response = yield call(MastersQuestionnaireTypeService.createQuestionnaireType, statusdata)
        if (response && response.data && response.data.status === 'success') {
            yield put(addQuestionnaireTypesuccess(response.data))
        } else if (response && response.status === 'error') {
            yield put(addQuestionnaireTypeFail(response))
        } else {
            yield put(addQuestionnaireTypeFail(response))
        }
    } catch (error) {
        yield put(addQuestionnaireTypeFail(error))
    }
}


function* fetchQuestionnairePreview({ payload: data }: AnyAction): any {
    try {
        const response = yield call(MastersQuestionnaireTypeService.QuestionnaireTypePreview, data)
        if (response && response.data && response.data.status === 'success') {
            yield put(getQuestionnairePreviewSuccess(response.data))
        } else if (response && response.status === 'error') {
            yield put(getQuestionnairePreviewFail(response))
        } else {
            yield put(getQuestionnairePreviewFail(response))
        }
    } catch (error) {
        yield put(getQuestionnairePreviewFail(error))
    }
}

function* companysSaga(): any {
    yield takeEvery(GET_QUESTIONNAIRE_TYPES, fetchQuestionnaireTypes)
    yield takeEvery(GET_QUESTIONNAIRE_TYPE_LIST, fetchQuestionnaireTypeList)
    yield takeEvery(GET_QUESTIONNAIRE_TYPE_DETAIL, fetchQuestionnaireTypeDetail)
    yield takeEvery(ADD_NEW_QUESTIONNAIRE_TYPE, onAddNewQuestionnaireType)
    yield takeEvery(UPDATE_QUESTIONNAIRE_TYPE, onUpdateQuestionnaireType)
    yield takeEvery(EXPORT_QUESTIONNAIRE_TYPE, onExportQuestionnaireType)
    yield takeEvery(DELETE_QUESTIONNAIRE_TYPE, onDeleteQuestionnaireType)
    yield takeEvery(GET_QUESTIONNAIRE_PREVIEW, fetchQuestionnairePreview)
}

export default companysSaga
