import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Form,
  Label,
  Button,
  FormFeedback,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import labelPlugin from "flatpickr/dist/plugins/labelPlugin/labelPlugin";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Buffer } from "buffer";

import Autocomplete from "react-google-autocomplete";
import {
  datePickerI18,
  getDependentMultipleWhereSelectOptions,
  getSelectOptions,
  getUserProfile,
  handleAddress,
  handleAutoSave,
  yearsOnward,
} from "../../../helpers/functions_helper";
import {
  DocumentStatusMaster,
  generalStatusOptions,
  YesNoMaster,
  StatusMaster,
  EvaluationMaster,
} from "../../../common/staticdata";

import userGeneralService from "../../../services/users.general.service";
import FileUpload from "../../../components/Common/FileUpload";
import { useTranslation } from "react-i18next";
import UploadDocModal from "../UploadDocModal";
import commonService from "../../../services/common.service";
import fileDownload from "js-file-download";
import { useSelector, useDispatch } from "react-redux";
import DocStatus from "../../Masters/Company/components/DocStatus";
//Mail Action
import { setDraftEmailData } from "../../../store/mails/actions";
import { draftmailProps } from "../../Email/components/type";
import { useNavigate, useParams } from "react-router-dom";
import EmailButton from "../../Email/components/EmailButton";
import ResidenceCard from "./ResidenceCard";
import DatePicker from "../../../components/Form/DatePicker/DatePicker";
import SinglePermit from "./SinglePermit";
import Input from "../../../components/Form/Input";
import Select from "../../../components/Form/Select/Select";
import Flatpickr from "../../../components/Form/Flatpickr";
import { useCallbackPrompt } from "../../../hooks/useCallbackPrompt";
import Alert from "../../../components/Common/Alert";
import NavigationAlert from "../../../components/Common/NavigationAlert";
import Annexture46 from "./Annexture46";

type GeneralType = {
  id: string;
  user_id: string;
  user_sub_id?: string;
  user_email?: string;
  user_name?: string;
  company: number;
  resident_status: number;
  resident_previous_status: any;
  company_contact_person_email?: string;
  company_contact_person_name?: string;

  passport: number;
  passport_year: string;
  passport_exp_date: string;
  passport_due_date: string;
  passport_notification: number;
  passport_email_notification: number;
  passport_status: string;

  residence_card: number;
  residence_card_type: string;
  residence_card_number: string;
  residence_card_id: string;
  residence_card_exp_date: string;
  residence_card_due_date: string;
  residence_card_status: string;
  residence_card_notification: number;
  residence_card_email_notification: number;
  residence_card_file: any;
  residence_card_data: any;

  mutuality: number;
  mutuality_name: string;
  mutuality_exp_date: string;
  bank_account: string;
  swift_code: string;
  iban_number: string;
  bank_address: string;
  single_permit_count: number;
  single_permit: number;
  single_permit_start_date: string;
  single_permit_end_date: string;
  single_permit_status: string;
  single_permit_filing_date: string;
  single_permit_due_date: string;
  single_permit_data: any;

  annexure_46_status: string;
  annexure_46_exp_date: string;
  annexure_46_due_date: string;
  questionnaire_status_resident: number;
  questionnaire_year_resident: string;
  questionnaire_tax_return_type_resident: any;
  questionnaire_due_date_resident: string;
  questionnaire_expiry_date_resident: string;
  questionnaire_trigger_resident: any;
  questionnaire_status_nonresident: number;
  questionnaire_year_nonresident: string;
  questionnaire_tax_return_type_nonresident: any;
  questionnaire_due_date_nonresident: string;
  questionnaire_expiry_date_nonresident: string;
  questionnaire_trigger_nonresident: any;
  questionnaire_sent_resident: any;
  questionnaire_sent_nonresident: any;
  annual_calculation_request: number;
  annual_calculation: string;
  annual_calculation_due_date: string;
  pay_roll: number;
  pay_roll_year: string;
  pay_roll_requested_year: string;

  aml: number;
  aml_due_date: string;
  aml_expiry_date: string;
  aml_notification: number;
  aml_status: number;
  annex_data: any;

  evaluation: number;
  evaluation_due_date: string;
  evaluation_expiry_date: string;
  evaluation_status: number;

  tax_on_web_status: number;
  tax_on_web_expiry_date: string;
  tax_on_web_due_date: string;
  tax_on_web_document: string;

  proxy_tax_inspector: number,
  proxy_tow: number,
  proxy_filing_date: string,
  proxy_approval_date: string,
  proxy_inspector_address: string,

  submit_btn_code: string;
};

const UsersGeneral = (props: any) => {
  const { t } = useTranslation();
  const userProfile = getUserProfile("all-users");
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const toastId = React.useRef(null) as any;
  const questionnaireToast = React.useRef(null) as any;
  const [loading, setLoading] = useState(false);
  const [redirectCode, setRedirectCode] = useState("");

  let { user_id, user_id_code } = props;

  const [questStatusOptions, setQuestStatusOptions] = useState<Array<any>>([]);
  const [loadingQuestStatus, setLoadingQuestStatus] = useState(false);
  const [taxReturnTypeOption, setTaxReturnTypeOption] = useState<any>([]);
  const [addQuestionnaire, setAddQuestionnaire] = useState(false);

  const [residenceCardData, setResidenceCardData] = useState<any>([]);
  const [singlePermitData, setSinglePermitData] = useState<any>([]);
  const [annexData, setAnnexData] = useState<any>([]);

  const localFormData = handleAutoSave("GET");
  const [autoSave, setAutoSave] = useState(false);
  const [autoSaveAlert, setAutoSaveAlert] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(autoSave);

  const [intialData, setIntialData] = useState<GeneralType>({
    id: "",
    user_id: user_id || "",
    company: 0,
    resident_status: 0,
    resident_previous_status: null,

    passport: 0,
    passport_year: "",
    passport_exp_date: "",
    passport_due_date: "",
    passport_notification: 0,
    passport_email_notification: 0,
    passport_status: "",

    residence_card: 0,
    residence_card_type: "",
    residence_card_number: "",
    residence_card_id: "",
    residence_card_exp_date: "",
    residence_card_due_date: "",
    residence_card_status: "",
    residence_card_notification: 0,
    residence_card_email_notification: 0,
    residence_card_file: [],
    residence_card_data: [],

    mutuality: 0,
    mutuality_name: "",
    mutuality_exp_date: "",
    bank_account: "",
    swift_code: "",
    iban_number: "",
    bank_address: "",
    single_permit_count: 0,
    single_permit: 0,
    single_permit_start_date: "",
    single_permit_end_date: "",
    single_permit_status: "",
    single_permit_filing_date: "",
    single_permit_due_date: "",
    single_permit_data: [],

    annexure_46_status: "",
    annexure_46_exp_date: "",
    annexure_46_due_date: "",
    annex_data: [],

    questionnaire_status_resident: 0,
    questionnaire_year_resident: "",
    questionnaire_tax_return_type_resident: "",
    questionnaire_due_date_resident: "",
    questionnaire_expiry_date_resident: "",
    questionnaire_trigger_resident: null,
    questionnaire_sent_resident: null,

    questionnaire_status_nonresident: 0,
    questionnaire_year_nonresident: "",
    questionnaire_tax_return_type_nonresident: "",
    questionnaire_due_date_nonresident: "",
    questionnaire_expiry_date_nonresident: "",
    questionnaire_trigger_nonresident: null,
    questionnaire_sent_nonresident: null,

    annual_calculation_request: 0,
    annual_calculation: "",
    annual_calculation_due_date: "",
    pay_roll: 0,
    pay_roll_year: "",
    pay_roll_requested_year: "",

    aml: 0,
    aml_due_date: "",
    aml_expiry_date: "",
    aml_notification: 0,
    aml_status: 0,

    evaluation: 0,
    evaluation_due_date: "",
    evaluation_expiry_date: "",
    evaluation_status: 0,

    tax_on_web_status: 0,
    tax_on_web_expiry_date: "",
    tax_on_web_due_date: "",
    tax_on_web_document: "",

    proxy_tax_inspector: 0,
    proxy_tow: 0,
    proxy_filing_date: "",
    proxy_approval_date: "",
    proxy_inspector_address: "",

    submit_btn_code: "update",
  });

  const handleChange = (fieldName: string, fieldValue: any) => {
    setIntialData((preState) => ({ ...preState, [fieldName]: fieldValue }));
    setAutoSave(true);
  };

  const handleRedirect = () => {
    setAutoSave(false);
    handleAutoSave("CLEAR");

    if (redirectCode === "update_close") {
      return navigate("/users/all-users?redirect=true");
    } else if (redirectCode === "update") {
      window.scrollTo(0, 0);
    } else {
      return navigate("/users/all-users?redirect=true");
    }
  };
  useEffect(() => {
    if (redirectCode !== "") {
      handleRedirect();
    }
  }, [redirectCode]);

  const validationSchema = Yup.object().shape({
    aml_due_date: Yup.string().nullable().when("aml", {
      is: (aml: any) => aml > 0,
      then: Yup.string().nullable().required("AML Due date field is required."),
    }),
    aml_expiry_date: Yup.string().nullable().when("aml", {
      is: (aml: any) => aml > 0,
      then: Yup.string().nullable().required("AML Expiry date field is required."),
    }),
    evaluation_due_date: Yup.string().nullable().when("evaluation", {
      is: (evaluation: any) => evaluation > 0,
      then: Yup.string().nullable().required("Due date field is required."),
    }),
    evaluation_expiry_date: Yup.string().nullable().when("evaluation", {
      is: (evaluation: any) => evaluation > 0,
      then: Yup.string().nullable().required("Expiry date field is required."),
    }),
    passport_year: Yup.string().when("passport", {
      is: "1",
      then: Yup.string().required("Passport year field is required."),
    }),
    passport_exp_date: Yup.string().when("passport", {
      is: "1",
      then: Yup.string().required("Passport Exp Date field is required."),
    }),
    passport_due_date: Yup.string().when("passport", {
      is: "1",
      then: Yup.string().required("Passport due date field is required."),
    }),
    passport_status: Yup.string().when("passport", {
      is: "1",
      then: Yup.string().required("Passport status field is required."),
    }),
    tax_on_web_expiry_date: Yup.string().nullable().when("tax_on_web_status", {
      is: "1",
      then: Yup.string().nullable().required("Tax on web expiry date field is required."),
    }),
    tax_on_web_due_date: Yup.string().nullable().when("tax_on_web_status", {
      is: "1",
      then: Yup.string().nullable().required("Tax on web due date field is required."),
    }),
    proxy_filing_date: Yup.string().nullable().when("proxy_tax_inspector", {
      is: "1",
      then: Yup.string().nullable().required("Filing date field is required."),
    }),
    proxy_approval_date: Yup.string().nullable().when("proxy_tax_inspector", {
      is: "1",
      then: Yup.string().nullable().required("Approval date field is required."),
    }),
    mutuality_name: Yup.string().when("mutuality", {
      is: "1",
      then: Yup.string().required("Name of the mutuality field is required."),
    }),
    mutuality_exp_date: Yup.string().when("mutuality", {
      is: "1",
      then: Yup.string().required("Expiry date field is required."),
    }),
    single_permit_start_date: Yup.string().when("single_permit", {
      is: "1",
      then: Yup.string().required("The start date field is required."),
    }),
    single_permit_end_date: Yup.string().when("single_permit", {
      is: "1",
      then: Yup.string().required("The end date field is required."),
    }),
    single_permit_status: Yup.string().when("single_permit", {
      is: "1",
      then: Yup.string().required("The status field is required."),
    }),
    single_permit_filing_date: Yup.string().when("single_permit", {
      is: "1",
      then: Yup.string().required("The filing date field is required."),
    }),
    single_permit_due_date: Yup.string().when("single_permit", {
      is: "1",
      then: Yup.string().required("The due date field is required."),
    }),

    annexure_46_exp_date: Yup.string().when("annexure_46_status", {
      is: (annexure_46_status: any) => annexure_46_status > 0,
      then: Yup.string().required("Expiry date field is required."),
    }),
    annexure_46_due_date: Yup.string().when("annexure_46_status", {
      is: (annexure_46_status: any) => annexure_46_status > 0,
      then: Yup.string().required("Due date field is required."),
    }),

    questionnaire_year_resident: Yup.string().when("questionnaire_trigger_resident", {
      is: "1",
      then: Yup.string().required("Tax Year field is required."),
    }),
    questionnaire_tax_return_type_resident: Yup.string().nullable().when("questionnaire_trigger_resident", {
      is: "1",
      then: Yup.string().nullable().required("Tax return type field is required."),
    }),
    questionnaire_due_date_resident: Yup.string().when("questionnaire_trigger_resident", {
      is: "1",
      then: Yup.string().required("Sending date field is required."),
    }),
    questionnaire_expiry_date_resident: Yup.string().when("questionnaire_trigger_resident", {
      is: "1",
      then: Yup.string().required("Official due date field is required."),
    }),
    questionnaire_year_nonresident: Yup.string().when("questionnaire_trigger_nonresident", {
      is: "1",
      then: Yup.string().required("Tax Year field is required."),
    }),
    questionnaire_tax_return_type_nonresident: Yup.string().when("questionnaire_trigger_nonresident", {
      is: "1",
      then: Yup.string().required("Tax return type field is required."),
    }),
    questionnaire_due_date_nonresident: Yup.string().when("questionnaire_trigger_nonresident", {
      is: "1",
      then: Yup.string().required("Sending date field is required."),
    }),
    questionnaire_expiry_date_nonresident: Yup.string().when("questionnaire_trigger_nonresident", {
      is: "1",
      then: Yup.string().required("Official due date field is required."),
    }),
    pay_roll_year: Yup.string().when("pay_roll", {
      is: "1",
      then: Yup.string().required("Year is required."),
    }),
    pay_roll_requested_year: Yup.string().when("pay_roll", {
      is: "1",
      then: Yup.string().required("Requested year field is required."),
    }),
  });

  const [documentStatusList, setDocumentStatusList] = useState<any>([]);
  const [generalStatusList, setGeneralStatusList] = useState<any>([]);
  const [yesNoList, setYesNoList] = useState<any>([]);
  const [statusList, setStatusList] = useState<any>([]);
  const [evaluationList, setEvaluationList] = useState<any>([]);


  useEffect(() => {
    if (DocumentStatusMaster) {
      const Options = DocumentStatusMaster?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setDocumentStatusList(Options);
    }


    if (generalStatusOptions) {
      const Options = generalStatusOptions?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setGeneralStatusList(Options);
    }

    if (YesNoMaster) {
      const Options = YesNoMaster?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setYesNoList(Options);
    }

    if (StatusMaster) {
      const Options = StatusMaster?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setStatusList(Options);
    }

    if (EvaluationMaster) {
      const Options = EvaluationMaster?.map((option: any) => ({
        label: t(option.label),
        value: option.value
      }));
      setEvaluationList(Options);
    }

  }, [localStorage.getItem("I18N_LANGUAGE")])


  useEffect(() => {
    getSelectOptions("tbl_master_tax_return_type", "tax_return_type_value", "", [
      "external_due_date",
      "questionnaire_sending_date",
      "questionnaire_type",
    ]).then(function (options) {
      setTaxReturnTypeOption(options);
    });
  }, []);

  const [enableAdd, setEnableAdd] = useState(true);
  useEffect(() => {
    if (
      intialData.questionnaire_trigger_resident != null &&
      intialData.questionnaire_trigger_nonresident != null
    ) {
      setEnableAdd(false);
    }
  }, [intialData.questionnaire_trigger_resident, intialData.questionnaire_trigger_nonresident]);

  const [isTaxrturnEnable, setIsTaxreturnEnable] = useState<Number>(0);
  const addTaxreturn = (type: any) => {
    if (type == "INR") {
      setIsTaxreturnEnable(2);
    } else {
      setIsTaxreturnEnable(1);
    }
  };

  // const deleteTaxreturn = (type: any) => {
  //   if (type == "IPP") {
  //     setIsTaxreturnEnable(2);
  //     handleChange("questionnaire_trigger_resident", null);
  //   } else {
  //     setIsTaxreturnEnable(1);
  //     handleChange("questionnaire_trigger_nonresident", null);
  //   }
  // };

  useEffect(() => {
    if (intialData.resident_status == 1 && intialData.questionnaire_trigger_nonresident != null) {
      setIsTaxreturnEnable(2);
    } else if (
      intialData.resident_status == 2 &&
      intialData.questionnaire_trigger_resident != null
    ) {
      setIsTaxreturnEnable(1);
    } else {
      setIsTaxreturnEnable(0);
    }
  }, [
    intialData.resident_status,
    intialData.questionnaire_trigger_nonresident,
    intialData.questionnaire_trigger_resident,
  ]);
  const [taxReturnTypeResident, setTaxReturnTypeResident] = useState<any>([]);
  const [taxReturnTypeNonResident, setTaxReturnTypeNonResident] = useState<any>([]);
  useEffect(() => {
    if (intialData.questionnaire_tax_return_type_resident) {
      const type_options: any = taxReturnTypeOption.filter(
        (type: any) => type.value === intialData.questionnaire_tax_return_type_resident
      );
      if (type_options.length && type_options[0]) {
        setIntialData((preState: any) => {
          return {
            ...preState,
            questionnaire_expiry_date_resident: type_options[0].external_due_date,
            questionnaire_due_date_resident: type_options[0].questionnaire_sending_date,
          };
        });
      }
    }
  }, [intialData.questionnaire_tax_return_type_resident]);
  useEffect(() => {
    if (intialData.questionnaire_trigger_resident) {
      const type_options_resident: any = taxReturnTypeOption.filter(
        (type: any) => type.questionnaire_type === 1
      );
      setTaxReturnTypeResident(type_options_resident);
    }
  }, [intialData.questionnaire_trigger_resident, taxReturnTypeOption]);

  useEffect(() => {
    if (intialData.questionnaire_trigger_nonresident) {
      const type_options_nonresident: any = taxReturnTypeOption.filter(
        (type: any) => type.questionnaire_type === 2
      );
      setTaxReturnTypeNonResident(type_options_nonresident);
    }
  }, [intialData.questionnaire_trigger_nonresident, taxReturnTypeOption]);

  useEffect(() => {
    if (intialData.questionnaire_tax_return_type_nonresident) {
      const type_options: any = taxReturnTypeOption.filter(
        (type: any) => type.value === intialData.questionnaire_tax_return_type_nonresident
      );

      if (type_options.length && type_options[0]) {
        setIntialData((preState: any) => {
          return {
            ...preState,
            questionnaire_expiry_date_nonresident: type_options[0].external_due_date,
            questionnaire_due_date_nonresident: type_options[0].questionnaire_sending_date,
          };
        });
      }
    }
  }, [intialData.questionnaire_tax_return_type_nonresident]);

  useEffect(() => {
    if (
      intialData.resident_status == 2 &&
      intialData.questionnaire_trigger_resident &&
      intialData.questionnaire_due_date_resident != null &&
      intialData.questionnaire_sent_resident == null
    ) {
      setAddQuestionnaire(true);
    } else if (
      intialData.resident_status == 1 &&
      intialData.questionnaire_trigger_nonresident &&
      intialData.questionnaire_due_date_nonresident != null &&
      intialData.questionnaire_sent_nonresident == null
    ) {
      setAddQuestionnaire(true);
    } else {
      setAddQuestionnaire(false);
    }
  }, [intialData.questionnaire_due_date_resident, intialData.questionnaire_due_date_nonresident]);

  const sendQuestionnaire = async (resident_status: any) => {
    try {
      const values = {
        company_id: intialData.company,
        year:
          resident_status == 1
            ? intialData.questionnaire_year_nonresident
            : intialData.questionnaire_year_resident,
        user_type_flag: true,
        user_status: resident_status == 1 ? 2 : 1,
        employee: intialData.user_id,
        due_date:
          resident_status == 1
            ? intialData.questionnaire_due_date_nonresident
            : intialData.questionnaire_due_date_resident,
        expiry_date:
          resident_status == 1
            ? intialData.questionnaire_expiry_date_nonresident
            : intialData.questionnaire_expiry_date_resident,
        tax_return_type:
          resident_status == 1
            ? intialData.questionnaire_tax_return_type_nonresident
            : intialData.questionnaire_tax_return_type_resident,
      };
      const res = (await userGeneralService.sendQuestionnaires(values)) as any;
      if (res?.data?.status === "success") {
        setAddQuestionnaire(false);
        questionnaireToast.current = toast(t("Questionnaire sent succefully."), {
          type: toast.TYPE.SUCCESS,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        get();
      } else {
        questionnaireToast.current = toast(t("Questionnaire not sent succefully."), {
          type: toast.TYPE.ERROR,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
      }
    } catch (err: any) {
      questionnaireToast.current = toast(t("Questionnaire not sent succefully."), {
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
  };
  useEffect(() => {
    if (intialData.questionnaire_trigger_resident || intialData.questionnaire_trigger_nonresident) {
      getSelectOptions("tbl_master_questionnaire_status", "questionnaire_status_value", "").then(
        function (options) {
          const opt = options.filter((op: any) => op.value != 7);
          setQuestStatusOptions(opt);
          setLoadingQuestStatus(false);
        }
      );
    }
  }, [intialData.questionnaire_trigger_resident, intialData.questionnaire_trigger_nonresident]);

  const handleUsersGeneral = async (values: GeneralType) => {
    toast.dismiss();
    toastId.current = toast(t("Updating Users general..."), {
      autoClose: false,
      isLoading: true,
    });

    try {
      const res = (await userGeneralService.updateUsersGeneral(values)) as any;
      if (res?.data?.status === "success") {
        setRedirectCode(intialData.submit_btn_code);

        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        if (res.data.data) {
          Object.keys(res.data.data).map((key) => {
            toast(res.data.data[key][0], {
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              delay: 500,
            });
          });
        }
      }
    } catch (err: any) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
  };

  const updateInitialData = (data: GeneralType) => {
    setIntialData((preData: GeneralType) => ({
      ...preData,
      id: data?.id,
      user_id: data?.user_id || user_id,
      user_sub_id: user_id_code,
      user_email: data?.user_email || "",
      user_name: data?.user_name || "",
      company: data?.company || 0,
      resident_status: data?.resident_status || 0,
      resident_previous_status: data?.resident_previous_status || 0,
      company_contact_person_email: data?.company_contact_person_email || "",
      company_contact_person_name: data?.company_contact_person_name || "",

      passport: data?.passport || 0,
      passport_year: data?.passport_year || "",
      passport_exp_date: data?.passport_exp_date || "",
      passport_due_date: data?.passport_due_date || "",
      passport_notification: data?.passport_notification || 0,
      passport_email_notification: data?.passport_email_notification || 0,
      passport_status: data?.passport_status || "",

      residence_card: data?.residence_card || 0,
      residence_card_type: data?.residence_card_type || "",
      residence_card_number: data?.residence_card_number || "",
      residence_card_id: data?.residence_card_id || "",
      residence_card_exp_date: data?.residence_card_exp_date || "",
      residence_card_due_date: data?.residence_card_due_date || "",
      residence_card_status: data?.residence_card_status || "",
      residence_card_notification: data?.residence_card_notification || 0,
      residence_card_email_notification: data?.residence_card_email_notification || 0,
      residence_card_file: data?.residence_card_file || [],
      residence_card_data:
        (data?.residence_card_data && data?.residence_card_data?.length > 0 && JSON.parse(data?.residence_card_data)) || [],

      mutuality: data?.mutuality || 0,
      mutuality_name: data?.mutuality_name || "",
      mutuality_exp_date: data?.mutuality_exp_date || "",
      bank_account: data?.bank_account || "",
      swift_code: data?.swift_code || "",
      iban_number: data?.iban_number || "",
      bank_address: data?.bank_address || "",
      single_permit_count: data?.single_permit_count || 0,
      single_permit: data?.single_permit || 0,
      single_permit_start_date: data?.single_permit_start_date || "",
      single_permit_end_date: data?.single_permit_end_date || "",
      single_permit_status: data?.single_permit_status || "",
      single_permit_filing_date: data?.single_permit_filing_date || "",
      single_permit_due_date: data?.single_permit_due_date || "",
      single_permit_data:
        (data?.single_permit_data && data?.single_permit_data?.length > 0 && JSON.parse(data?.single_permit_data)) || [],

      annexure_46_status: data?.annexure_46_status || "",
      annexure_46_exp_date: data?.annexure_46_exp_date || "",
      annexure_46_due_date: data?.annexure_46_due_date || "",
      annex_data:
        (data?.annex_data && data?.annex_data?.length > 0 && JSON.parse(data?.annex_data)) || [],

      questionnaire_status_resident: data?.questionnaire_status_resident || 0,
      questionnaire_year_resident: data?.questionnaire_year_resident || "",
      questionnaire_tax_return_type_resident: data?.questionnaire_tax_return_type_resident || 0,
      questionnaire_due_date_resident: data?.questionnaire_due_date_resident || "",
      questionnaire_expiry_date_resident: data?.questionnaire_expiry_date_resident || "",
      questionnaire_trigger_resident: data?.questionnaire_trigger_resident || null,
      questionnaire_sent_resident: data?.questionnaire_sent_resident || null,

      questionnaire_status_nonresident: data?.questionnaire_status_nonresident || 0,
      questionnaire_year_nonresident: data?.questionnaire_year_nonresident || "",
      questionnaire_tax_return_type_nonresident:
        data?.questionnaire_tax_return_type_nonresident || 0,
      questionnaire_due_date_nonresident: data?.questionnaire_due_date_nonresident || "",
      questionnaire_expiry_date_nonresident: data?.questionnaire_expiry_date_nonresident || "",
      questionnaire_trigger_nonresident: data?.questionnaire_trigger_nonresident || null,
      questionnaire_sent_nonresident: data?.questionnaire_sent_nonresident || null,

      annual_calculation_request: data?.annual_calculation_request || 0,
      annual_calculation: data?.annual_calculation || "",
      annual_calculation_due_date: data?.annual_calculation_due_date || "",
      pay_roll: data?.pay_roll || 0,
      pay_roll_year: data?.pay_roll_year || "",
      pay_roll_requested_year: data?.pay_roll_requested_year || "",

      aml: data?.aml || 0,
      aml_due_date: data?.aml_due_date || "",
      aml_expiry_date: data?.aml_expiry_date || "",
      aml_notification: data?.aml_notification || 0,
      aml_status: data?.aml_status || 0,

      tax_on_web_status: data?.tax_on_web_status || 0,
      tax_on_web_expiry_date: data?.tax_on_web_expiry_date || "",
      tax_on_web_due_date: data?.tax_on_web_due_date || "",
      tax_on_web_document: data?.tax_on_web_document || "",

      'proxy_tax_inspector': data.proxy_tax_inspector || 0,
      'proxy_tow': data.proxy_tow || 0,
      'proxy_filing_date': data.proxy_filing_date == null ? "" : data.proxy_filing_date,
      'proxy_approval_date': data.proxy_approval_date == null ? "" : data.proxy_approval_date,
      'proxy_inspector_address': data.proxy_inspector_address || "",
    }));

    if (data?.residence_card_data && data?.residence_card_data?.length > 0) {
      setResidenceCardData(JSON.parse(data?.residence_card_data));
    }

    if (data?.single_permit_data && data?.single_permit_data?.length > 0) {
      setSinglePermitData(JSON.parse(data?.single_permit_data));
    }

    if (data?.annex_data && data?.annex_data?.length > 0) {
      setAnnexData(JSON.parse(data?.annex_data));
    }
  };

  useEffect(() => {
    if (intialData.residence_card_data) {
      let residence_card_data: any = [];
      if (intialData?.residence_card_data?.length === 1) {
        residence_card_data = intialData?.residence_card_data[0];
      } else {
        if (Array.isArray(intialData.residence_card_data)) {
          residence_card_data = intialData?.residence_card_data?.find(
            (x: any) => x?.is_prime === true
          );

          if (!residence_card_data) {
            residence_card_data =
              intialData?.residence_card_data[intialData?.residence_card_data?.length - 1];
          }
        }
      }

      setIntialData({
        ...intialData,
        residence_card: residence_card_data?.residence_card ? residence_card_data?.residence_card : 0,
        residence_card_type: residence_card_data?.residence_card_type,
        residence_card_number: residence_card_data?.residence_card_number,
        residence_card_id: residence_card_data?.residence_card_id,
        residence_card_status: residence_card_data?.residence_card_status,
        residence_card_exp_date: residence_card_data?.residence_card_exp_date,
        residence_card_due_date: residence_card_data?.residence_card_due_date,
        residence_card_file: residence_card_data?.residence_card_file,
      });
    }
  }, [intialData.residence_card_data]);

  const get = async () => {
    try {
      const res = (await userGeneralService.getUsersGeneral(user_id)) as any;
      if (res.data.status === "success") {
        updateInitialData(res.data.data[0]);
        setLoading(false);
      } else {
        toast.update(toastId.current, {
          render: res?.data?.message,
          type: toast.TYPE.ERROR,
          isLoading: false,
          autoClose: 5000,
        });
        setLoading(false);
        handleRedirect();
      }
    } catch (err: any) {
      toast.update(toastId.current, {
        render: err?.data?.message,
        type: toast.TYPE.ERROR,
        isLoading: false,
        autoClose: 5000,
      });
      setLoading(false);
      handleRedirect();
    }
  };

  useEffect(() => {
    if (localFormData && localFormData?.initialData) {
      updateInitialData(localFormData?.initialData);
      setAutoSaveAlert(true);
    } else {
      setLoading(true);
      get();
    }
  }, []);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: intialData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleUsersGeneral(values);
    },
  });

  useEffect(() => {
    if (!validation.isSubmitting) return;
    if (Object.keys(validation.errors).length > 0) {
      document
        .getElementById(Object.keys(validation.errors)[0])
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
      document.getElementById(Object.keys(validation.errors)[0])?.focus({ preventScroll: true });
    }
  }, [validation.isSubmitting, validation.errors]);

  useEffect(() => {
    if (intialData.single_permit == 0) {
      const updatedIntialData = {
        ...intialData,
        single_permit_start_date: "",
        single_permit_end_date: "",
        single_permit_status: "",
        single_permit_filing_date: "",
        single_permit_due_date: "",
      };
      setIntialData(updatedIntialData);
    }
  }, [intialData.single_permit]);

  useEffect(() => {
    if (intialData.passport == 0) {
      const updatedIntialData = {
        ...intialData,
        passport_year: "",
        passport_exp_date: "",
        passport_due_date: "",
      };
      setIntialData(updatedIntialData);
    } else {
      const updatedIntialData = {
        ...intialData,
        passport_year: moment().format("YYYY"),
      };
      setIntialData(updatedIntialData);
    }
  }, [intialData.passport]);

  useEffect(() => {
    if (intialData.tax_on_web_expiry_date) {
      const tax_on_web_due_date_str = moment(intialData.tax_on_web_expiry_date)
        .subtract(3, "months")
        .format("YYYY-M-D");
      const updatedIntialData = {
        ...intialData,
        tax_on_web_due_date: tax_on_web_due_date_str,
      };
      setIntialData(updatedIntialData);
    }
  }, [intialData.tax_on_web_expiry_date]);

  useEffect(() => {
    if (intialData.tax_on_web_due_date) {
      const tax_on_web_expiry_date_str = moment(intialData.tax_on_web_due_date)
        .add(3, "months")
        .format("YYYY-M-D");
      const updatedIntialData = {
        ...intialData,
        tax_on_web_expiry_date: tax_on_web_expiry_date_str,
      };
      setIntialData(updatedIntialData);
    }
  }, [intialData.tax_on_web_due_date]);

  useEffect(() => {
    if (intialData.annexure_46_exp_date) {
      const annexure_46_due_date_str = moment(intialData.annexure_46_exp_date)
        .subtract(3, "months")
        .format("YYYY-M-D");
      const updatedIntialData = {
        ...intialData,
        annexure_46_due_date: annexure_46_due_date_str,
      };
      setIntialData(updatedIntialData);
    }
  }, [intialData.annexure_46_exp_date]);

  useEffect(() => {
    if (intialData.annexure_46_due_date) {
      const annexure_46_due_date_str = moment(intialData.annexure_46_due_date)
        .add(3, "months")
        .format("YYYY-M-D");
      const updatedIntialData = {
        ...intialData,
        annexure_46_exp_date: annexure_46_due_date_str,
      };
      setIntialData(updatedIntialData);
    }
  }, [intialData.annexure_46_due_date]);

  useEffect(() => {
    if (intialData.passport_exp_date) {
      const passport_due_date = moment(intialData.passport_exp_date)
        .subtract(3, "months")
        .format("YYYY-M-D");
      const updatedIntialData = {
        ...intialData,
        passport_due_date: passport_due_date,
      };
      setIntialData(updatedIntialData);
    }
  }, [intialData.passport_exp_date]);


  useEffect(() => {
    if (intialData.passport_due_date) {
      const passport_due_date = moment(intialData.passport_due_date)
        .add(3, "months")
        .format("YYYY-M-D");
      const updatedIntialData = {
        ...intialData,
        passport_exp_date: passport_due_date,
      };
      setIntialData(updatedIntialData);
    }
  }, [intialData.passport_due_date]);


  useEffect(() => {
    if (intialData.residence_card_exp_date) {
      const passport_due_date = moment(intialData.residence_card_exp_date)
        .subtract(3, "months")
        .format("YYYY-M-D");
      const updatedIntialData = {
        ...intialData,
        residence_card_due_date: passport_due_date,
      };
      setIntialData(updatedIntialData);
    }
  }, [intialData.residence_card_exp_date]);


  useEffect(() => {
    if (intialData.residence_card_due_date) {
      const passport_due_date = moment(intialData.residence_card_due_date)
        .add(3, "months")
        .format("YYYY-M-D");
      const updatedIntialData = {
        ...intialData,
        residence_card_exp_date: passport_due_date,
      };
      setIntialData(updatedIntialData);
    }
  }, [intialData.residence_card_due_date]);

  useEffect(() => {
    if (intialData.mutuality == 0) {
      const updatedIntialData = {
        ...intialData,
        mutuality_name: "",
        mutuality_exp_date: "",
      };
      setIntialData(updatedIntialData);
    }
  }, [intialData.mutuality]);

  useEffect(() => {
    if (intialData.tax_on_web_status == 0) {
      const updatedIntialData = {
        ...intialData,
        tax_on_web_expiry_date: "",
        tax_on_web_due_date: "",
        tax_on_web_document: "",
      };
      setIntialData(updatedIntialData);
    }
  }, [intialData.tax_on_web_status]);

  useEffect(() => {
    if (intialData.questionnaire_trigger_resident == null) {
      const updatedIntialData = {
        ...intialData,
        questionnaire_year_resident: "",
        questionnaire_tax_return_type_resident: 0,
        questionnaire_due_date_resident: "",
        questionnaire_expiry_date_resident: "",
        questionnaire_status_resident: 0,
      };
      setIntialData(updatedIntialData);
    }
  }, [intialData.questionnaire_trigger_resident]);

  useEffect(() => {
    if (intialData.questionnaire_trigger_nonresident == null) {
      const updatedIntialData = {
        ...intialData,
        questionnaire_year_nonresident: "",
        questionnaire_tax_return_type_nonresident: 0,
        questionnaire_due_date_nonresident: "",
        questionnaire_expiry_date_nonresident: "",
        questionnaire_status_nonresident: 0,
      };
      setIntialData(updatedIntialData);
    }
  }, [intialData.questionnaire_trigger_nonresident]);

  useEffect(() => {
    if (intialData.annual_calculation_request == 0) {
      const updatedIntialData = {
        ...intialData,
        annual_calculation: "",
        annual_calculation_due_date: "",
      };
      setIntialData(updatedIntialData);
    }
  }, [intialData.annual_calculation_request]);

  useEffect(() => {
    if (intialData.pay_roll == 0) {
      const updatedIntialData = {
        ...intialData,
        pay_roll_year: "",
        pay_roll_requested_year: "",
      };
      setIntialData(updatedIntialData);
    }
  }, [intialData.pay_roll]);

  const statusOptions = [
    { value: "1", label: "Pending" },
    { value: "2", label: "Approved" },
    { value: "3", label: "In-progress" },
    { value: "4", label: "Recieved" },
    { value: "5", label: "Cancelled" },
    { value: "6", label: "Rejected" },
  ];

  const calculationOptions = [
    { value: "1", label: "YET TO DO" },
    { value: "2", label: "IN PROGRESS" },
    { value: "3", label: "COMPLETED" },
    { value: "4", label: "REQUESTED" },
  ];

  type dueorexpType = "due" | "exp";
  const syncDate = (type: string, xdate: any, dueorexp: dueorexpType) => {
    if (xdate) {
      const threeMonthAdd = moment(xdate).add(1, "M").format("YYYY-M-D");
      const threeMonthSub = moment(xdate).subtract(1, "M").format("YYYY-M-D");
      switch (type) {
        case "aml":
          if (dueorexp === "due") {
            setIntialData({
              ...intialData,
              aml_expiry_date: threeMonthAdd,
              aml_due_date: xdate,
            });
          } else {
            setIntialData({
              ...intialData,
              aml_due_date: threeMonthSub,
              aml_expiry_date: xdate,
            });
          }
          break;
      }
    }
  };

  //Uploda Document
  const [uploadDocModal, setUploadDocModal] = useState<boolean>(false);
  const [uploadDocData, setUploadDocData] = useState<any>([]);

  const handleUploadDocument = (type: string) => {
    const data = {
      type,
      id: user_id,
      user_id: user_id_code,
      title: type,
      year: type === "Passport" ? intialData.passport_year : moment().format("YYYY"),
      exp_date:
        type === "Passport" ? intialData.passport_exp_date : intialData.tax_on_web_expiry_date,
      due_date: type === "Passport" ? intialData.passport_due_date : intialData.tax_on_web_due_date,
    };
    setUploadDocData(data);
    setUploadDocModal(true);
  };

  useEffect(() => {
    getAttachmentList();
  }, []);

  const [fileData, setFileData] = useState<any>([]);
  const [passportFile, setPassportFile] = useState<any>([]);
  const [taxFile, setTaxFile] = useState<any>([]);
  const getAttachmentList = async () => {
    const moduleData = {
      module_app_id: 11,
      module_main_id: user_id,
      module_sub_id: user_id_code,
      section_id: "",
      file_path: "users",
    };
    const response = await commonService.getAttachmentList(moduleData);
    if (response.data.status === "success") {
      setFileData(response.data.data.original.data);
    }
  };

  const handleSingleDownload = async (type: any) => {
    if (fileData) {
      const row = fileData.filter((dt: any) => dt.section_id == type)[0];
      if (row) {
        const result = await commonService.downloadAttachment({
          id: Buffer.from(String(row.id)).toString("base64"),
          attachment_id: Buffer.from(String(row.attachment_id)).toString("base64"),
        });
        if (result) {
          fileDownload(result.data, row.file_name + "." + row.file_extension);
        }
      } else {
        toastId.current = toast("File is not available", {
          type: toast.TYPE.ERROR,
          isLoading: false,
        });
      }
    }
  };

  const onUploadSuccess = (id: any) => {
    getAttachmentList();
  };

  const handleAddressChange = async (data: any) => {
    const addressData = await handleAddress(data);
    setIntialData((prevData: any) => ({
      ...prevData,
      proxy_inspector_address: addressData?.address,
    }));
  };

  useEffect(() => {
    if (fileData.length > 0) {
      const row = fileData.filter((dt: any) => dt.section_id == 1);
      setPassportFile(row);
      const row_tax = fileData.filter((dt: any) => dt.section_id == 3);
      setTaxFile(row_tax);
    }
  }, [fileData]);

  //Email compose
  const composeMail = () => {
    const user_email = {
      label: `${intialData?.user_name}`,
      value: intialData.user_email,
    };
    const company_contact = {
      label: `${intialData?.company_contact_person_name}`,
      value: intialData.company_contact_person_email,
    };
    const draftMail: draftmailProps = {
      title: "New Message",
      filters: {},
      openMailBox: 1,
      to: [user_email],
      cc: [company_contact],
    };
    dispatch(setDraftEmailData(draftMail));
  };

  useEffect(() => {
    const params = window.location.hash.substring(1);
    const boxes: any = document.getElementById(
      params
    );
    setAddQuestionnaire(false);
    if (boxes) {
      boxes.scrollIntoView({ behavior: "smooth", block: "center" });
      boxes.focus();
    }
  }, [loading]);

  useEffect(() => {
    if (autoSave) { handleAutoSave("SET", intialData); }
  }, [intialData, autoSave]);

  return (
    <React.Fragment>
      <Alert
        open={autoSaveAlert}
        title={t("Autosaved data restored!")}
        message={t("Your last autosaved data has been restored from the session. In order to avoid the data loss, you need either save it to retain the data or Cancel to loose the data.")}
        onClickOk={() => setAutoSaveAlert(false)}
      />
      <NavigationAlert
        open={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />

      {uploadDocModal && (
        <UploadDocModal
          data={uploadDocData}
          isOpen={uploadDocModal}
          handleClose={() => setUploadDocModal(false)}
          onSuccess={onUploadSuccess}
          type="user"
        />
      )}

      <Row>
        <Col xl="12">
          <Card className="border-top-0 rounded-0 rounded-bottom">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              autoComplete="off"
            >
              <Input id="id" name="id" type="hidden" value={intialData.id} />
              <Input id="user_id" name="user_id" type="hidden" value={intialData.user_id} />
              <CardHeader className="d-flex bg-transparent border-bottom border-primary mt-2 pb-0">
                <CardTitle className="card-title text-uppercase flex-grow-1">
                  {t("General information")}
                </CardTitle>
                {!loading ? <EmailButton handlerCompose={() => composeMail()} /> : null}
              </CardHeader>
              <CardBody>
                <Row>
                  {!intialData.company ? (
                    <>
                      <DocStatus
                        value={intialData.aml}
                        status={intialData.aml_status}
                        due_date={intialData.aml_due_date}
                        e_date={intialData.aml_expiry_date}
                      />
                      <Col xl={2} lg={2} md={2} sm={6}>
                        <div className="mb-3">
                          <Label className="form-label">AML</Label>
                          <Select
                            id="aml"
                            name="aml"
                            className="is-invalid"
                            options={documentStatusList}
                            value={
                              intialData.aml
                                ? documentStatusList?.find(
                                  (option: any) => option.value == intialData.aml
                                )
                                : ""
                            }
                            isSearchable={true}
                            onBlur={() => validation.setFieldTouched("aml", true)}
                            onChange={(e: any) => {
                              handleChange("aml", e.value);
                            }}
                            isDisabled={!userProfile.IS_COMPANY_ADMIN}
                          />
                          {validation.touched.aml && validation.errors.aml ? (
                            <FormFeedback type="invalid">{validation.errors.aml}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col xl={2} lg={2} md={2} sm={12}>
                        <div className="mb-3">
                          <div className="input-group is-invalid">
                            <Flatpickr
                              id="aml_due_date"
                              name="aml_due_date"
                              label="AML Due date"
                              required={intialData.aml > 0 ? true : false}
                              className="form-control d-block"
                              type="text"
                              value={intialData.aml_due_date}
                              placeholder="DD/MM/YYYY"
                              disabled={!userProfile.IS_COMPANY_ADMIN}
                              options={{
                                altInput: true,
                                altFormat: "d/m/Y",
                                dateFormat: "Y-m-d",
                                allowInput: true,
                                maxDate: intialData.aml_expiry_date,
                                plugins: [labelPlugin()],
                                onChange: function (selectedDates, dateStr, instance) {
                                  handleChange("aml_due_date", dateStr);
                                  syncDate("aml", dateStr, "due");
                                },
                                onClose: function (selectedDates, dateStr, instance) {
                                  handleChange("aml_due_date", dateStr);
                                  syncDate("aml", dateStr, "due");
                                },
                                locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                              }}
                              error={validation.errors.aml_due_date}
                              touched={validation.touched.aml_due_date}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col xl={2} lg={2} md={2} sm={12}>
                        <div className="mb-3">
                          <Flatpickr
                            id="aml_expiry_date"
                            name="aml_expiry_date"
                            label="AML Expiry date"
                            required={intialData.aml > 0 ? true : false}
                            className="form-control d-block"
                            type="text"
                            value={intialData.aml_expiry_date}
                            placeholder="DD/MM/YYYY"
                            disabled={!userProfile.IS_COMPANY_ADMIN}
                            options={{
                              altInput: true,
                              altFormat: "d/m/Y",
                              dateFormat: "Y-m-d",
                              allowInput: true,
                              plugins: [labelPlugin()],
                              onChange: function (selectedDates, dateStr, instance) {
                                handleChange("aml_expiry_date", dateStr);
                                syncDate("aml", dateStr, "exp");
                              },
                              onClose: function (selectedDates, dateStr, instance) {
                                handleChange("aml_expiry_date", dateStr);
                                syncDate("aml", dateStr, "exp");
                              },
                              locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                            }}
                            error={validation.errors.aml_expiry_date}
                            touched={validation.touched.aml_expiry_date}
                          />
                        </div>
                      </Col>

                      <Col xl={2} lg={2} md={2} sm={6}>
                        <div className="mb-3">
                          <Label className="form-label">{t("AML notification")}</Label>
                          <Select
                            id="aml_notification"
                            name="aml_notification"
                            className="is-invalid"
                            options={yesNoList}
                            isDisabled={!userProfile.IS_COMPANY_ADMIN}
                            value={
                              intialData.aml_notification
                                ? yesNoList?.find(
                                  (option: any) => option.value == intialData.aml_notification
                                )
                                : ""
                            }
                            isSearchable={true}
                            onBlur={() => validation.setFieldTouched("aml_notification", true)}
                            onChange={(e: any) => {
                              handleChange("aml_notification", e.value);
                            }}
                          />
                          {validation.touched.aml_notification &&
                            validation.errors.aml_notification ? (
                            <FormFeedback type="invalid">
                              {validation.errors.aml_notification}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col xl={2} lg={2} md={2} sm={6}>
                        <div className="mb-3">
                          <Label className="form-label">{t("Status")}</Label>
                          <Select
                            id="aml_status"
                            name="aml_status"
                            className="is-invalid"
                            options={statusList}
                            value={
                              intialData.aml_status
                                ? statusList?.find(
                                  (option: any) => option.value == intialData.aml_status
                                )
                                : ""
                            }
                            isDisabled={!userProfile.IS_COMPANY_ADMIN}
                            isSearchable={true}
                            onBlur={() => validation.setFieldTouched("aml_status", true)}
                            onChange={(e: any) => {
                              handleChange("aml_status", e.value);
                            }}
                          />
                          {validation.touched.aml_status && validation.errors.aml_status ? (
                            <FormFeedback type="invalid">
                              {validation.errors.aml_status}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>

                      <Col className="col-12">
                        <hr></hr>
                      </Col>

                      {!!userProfile.IS_COMPANY_ADMIN && (
                        <>
                          <DocStatus
                            evaluation_value={intialData.evaluation}
                            evaluation_status={intialData.evaluation_status}
                            evaluation_due_date={intialData.evaluation_due_date}
                            evaluation_e_date={intialData.evaluation_expiry_date}
                          />
                          <Col xl={2} lg={2} md={2} sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">{t("Evaluation of Client")}</Label>
                              <Select
                                id="evaluation"
                                name="evaluation"
                                className="is-invalid"
                                options={documentStatusList}
                                isDisabled={!userProfile.IS_COMPANY_ADMIN}
                                value={
                                  intialData.evaluation
                                    ? documentStatusList?.find(
                                      (option: any) => option.value == intialData.evaluation
                                    )
                                    : ""
                                }
                                isSearchable={true}
                                onBlur={() => validation.setFieldTouched("evaluation", true)}
                                onChange={(e: any) => {
                                  handleChange("evaluation", e.value);
                                }}
                              />
                              {validation.touched.evaluation && validation.errors.evaluation ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.evaluation}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>

                          <Col xl={2} lg={2} md={2} sm={12}>
                            <div className="mb-3">
                              <div className="input-group is-invalid">
                                <Flatpickr
                                  id="evaluation_due_date"
                                  name="evaluation_due_date"
                                  label="Due date"
                                  required={intialData.evaluation > 0 ? true : false}
                                  className="form-control d-block"
                                  type="text"
                                  value={intialData.evaluation_due_date}
                                  disabled={!userProfile.IS_COMPANY_ADMIN}
                                  placeholder="DD/MM/YYYY"
                                  options={{
                                    altInput: true,
                                    altFormat: "d/m/Y",
                                    dateFormat: "Y-m-d",
                                    allowInput: true,
                                    maxDate: intialData.evaluation_expiry_date,
                                    plugins: [labelPlugin()],
                                    onChange: function (selectedDates, dateStr, instance) {
                                      handleChange("evaluation_due_date", dateStr);
                                      syncDate("evaluation", dateStr, "due");
                                    },
                                    onClose: function (selectedDates, dateStr, instance) {
                                      handleChange("evaluation_due_date", dateStr);
                                      syncDate("evaluation", dateStr, "due");
                                    },
                                    locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                                  }}
                                  error={validation.errors.evaluation_due_date}
                                  touched={validation.touched.evaluation_due_date}
                                />
                              </div>
                            </div>
                          </Col>

                          <Col xl={2} lg={2} md={2} sm={12}>
                            <div className="mb-3">
                              <div className="input-group is-invalid">
                                <Flatpickr
                                  id="evaluation_expiry_date"
                                  name="evaluation_expiry_date"
                                  label="Expiry date"
                                  required={intialData.evaluation > 0 ? true : false}
                                  className="form-control d-block"
                                  type="text"
                                  value={intialData.evaluation_expiry_date}
                                  disabled={!userProfile.IS_COMPANY_ADMIN}
                                  placeholder="DD/MM/YYYY"
                                  options={{
                                    altInput: true,
                                    altFormat: "d/m/Y",
                                    dateFormat: "Y-m-d",
                                    allowInput: true,
                                    plugins: [labelPlugin()],
                                    onChange: function (selectedDates, dateStr, instance) {
                                      handleChange("evaluation_expiry_date", dateStr);
                                      syncDate("evaluation", dateStr, "exp");
                                    },
                                    onClose: function (selectedDates, dateStr, instance) {
                                      handleChange("evaluation_expiry_date", dateStr);
                                      syncDate("evaluation", dateStr, "exp");
                                    },
                                    locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                                  }}
                                  error={validation.errors.evaluation_expiry_date}
                                  touched={validation.touched.evaluation_expiry_date}
                                />
                              </div>
                            </div>
                          </Col>

                          <Col xl={2} lg={2} md={2} sm={6}>
                            <div className="mb-3">
                              <Label className="form-label">{t("Status")}</Label>
                              <Select
                                id="evaluation_status"
                                name="evaluation_status"
                                className="is-invalid"
                                options={evaluationList}
                                isDisabled={!userProfile.IS_COMPANY_ADMIN}
                                value={
                                  intialData.evaluation_status
                                    ? evaluationList?.find(
                                      (option: any) =>
                                        option.value == intialData.evaluation_status
                                    )
                                    : ""
                                }
                                isSearchable={true}
                                onBlur={() => validation.setFieldTouched("evaluation_status", true)}
                                onChange={(e: any) => {
                                  handleChange("evaluation_status", e.value);
                                }}
                              />
                              {validation.touched.evaluation_status &&
                                validation.errors.evaluation_status ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.evaluation_status}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </>
                      )}

                      <Col className="col-12">
                        <hr></hr>
                      </Col>
                    </>
                  ) : null}

                  <Col xl={1} lg={1} md={1} sm={6} id="passport">
                    <div className="mb-3">
                      <Label className="form-label">{t("Passport")}</Label>
                      <div className="form-check mb-2">
                        <Input
                          id="passport_yes"
                          name="passport"
                          value="1"
                          className="form-check-input"
                          type="radio"
                          checked={intialData.passport == 1 ? true : false}
                          onChange={() => handleChange("passport", "1")}
                        />
                        <label className="form-check-label" htmlFor="passport_yes">
                          {t("Yes")}
                        </label>
                      </div>

                      <div className="form-check mb-2">
                        <Input
                          id="passport_no"
                          name="passport"
                          value="0"
                          className="form-check-input"
                          type="radio"
                          checked={intialData.passport == 0 ? true : false}
                          onChange={() => handleChange("passport", "0")}
                        />
                        <label className="form-check-label" htmlFor="passport_no">
                          {t("No")}
                        </label>
                      </div>
                    </div>
                  </Col>

                  <Col xl={2} lg={2} md={2} sm={6}>
                    <div className="mb-3">
                      <Select
                        id="passport_year"
                        name="passport_year"
                        label="Year"
                        required={intialData.passport == 1 ? true : false}
                        className="is-invalid"
                        options={yearsOnward()}
                        value={
                          intialData.passport_year
                            ? yearsOnward().find(
                              (option: any) => option.value == intialData.passport_year
                            )
                            : ""
                        }
                        isSearchable={true}
                        isDisabled={intialData.passport == 0 ? true : false}
                        onBlur={() => validation.setFieldTouched("passport_year", true)}
                        onChange={(e: any) => {
                          handleChange("passport_year", e.value);
                        }}
                        error={validation.errors.passport_year}
                        touched={validation.touched.passport_year}
                      />
                    </div>
                  </Col>

                  <Col xl={2} lg={2} md={2} sm={12}>
                    <div className="mb-3">
                      <div className="input-group is-invalid">
                        {intialData.passport == 1 ? (
                          <Flatpickr
                            id="passport_exp_date"
                            name="passport_exp_date"
                            label='Passport Exp Date'
                            required={intialData.passport == 1 ? true : false}
                            className="form-control d-block"
                            type="text"
                            value={intialData.passport_exp_date}
                            placeholder="DD/MM/YYYY"
                            options={{
                              altInput: true,
                              altFormat: "d/m/Y",
                              dateFormat: "Y-m-d H:i:S",
                              allowInput: true,
                              plugins: [labelPlugin()],
                              //minDate: "today",
                              onChange: function (selectedDates, dateStr, instance) {
                                handleChange("passport_exp_date", dateStr);
                                if (moment(dateStr) < moment()) {
                                  handleChange("passport_status", "Expired");
                                }
                              },
                              onClose: function (selectedDates, dateStr, instance) {
                                handleChange("passport_exp_date", dateStr);
                              },
                              locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                            }}
                            error={validation.errors.passport_year}
                            touched={validation.touched.passport_year}
                          />
                        ) : (
                          <Input
                            id="passport_exp_date"
                            name="passport_exp_date"
                            label='Passport Exp Date'
                            className="form-control d-block"
                            type="text"
                            placeholder="DD/MM/YYYY"
                            disabled={true}
                          />
                        )}
                      </div>
                    </div>
                  </Col>

                  <Col xl={2} lg={2} md={2} sm={12}>
                    <div className="mb-3">
                      <div className="input-group is-invalid">
                        {intialData.passport == 1 ? (
                          <Flatpickr
                            id="passport_due_date"
                            name="passport_due_date"
                            label="Passport Due Date"
                            required={intialData.passport == 1 ? true : false}
                            className="form-control d-block"
                            type="text"
                            value={intialData.passport_due_date}
                            placeholder="DD/MM/YYYY"
                            options={{
                              altInput: true,
                              altFormat: "d/m/Y",
                              dateFormat: "Y-m-d H:i:S",
                              allowInput: true,
                              plugins: [labelPlugin()],
                              maxDate: intialData.passport_exp_date,
                              //minDate: "today",
                              onChange: function (selectedDates, dateStr, instance) {
                                handleChange("passport_due_date", dateStr);
                              },
                              onClose: function (selectedDates, dateStr, instance) {
                                handleChange("passport_due_date", dateStr);
                              },
                              locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                            }}
                            error={validation.errors.passport_due_date}
                            touched={validation.touched.passport_due_date}
                          />
                        ) : (
                          <Input
                            id="passport_due_date"
                            name="passport_due_date"
                            label="Passport Due Date"
                            className="form-control d-block"
                            type="text"
                            placeholder="DD/MM/YYYY"
                            disabled={true}
                          />
                        )}
                      </div>
                    </div>
                  </Col>

                  {/* <Col xl={2} lg={2} md={2} sm={6}>
                    <div className="mb-3">
                      <Label className="form-label">
                        Application notification
                      </Label>
                      <div className="form-check mb-2">
                        <Input
                          id="passport_notification_yes"
                          name="passport_notification"
                          value="1"
                          className="form-check-input"
                          type="radio"
                          checked={
                            intialData.passport_notification == 1 ? true : false
                          }
                          onChange={() =>
                            handleChange("passport_notification", "1")
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="passport_notification_yes"
                        >
                          Yes
                        </label>
                      </div>

                      <div className="form-check mb-2">
                        <Input
                          id="passport_notification_no"
                          name="passport_notification"
                          value="0"
                          className="form-check-input"
                          type="radio"
                          checked={
                            intialData.passport_notification == 0 ? true : false
                          }
                          onChange={() =>
                            handleChange("passport_notification", "0")
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="passport_notification_no"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </Col>

                  {userProfile.IS_COMPANY_ADMIN ? (
                    <Col xl={2} lg={2} md={2} sm={6}>
                      <div className="mb-3">
                        <Label className="form-label">Email Notification</Label>
                        <div className="form-check mb-2">
                          <Input
                            id="passport_email_notification_yes"
                            name="passport_email_notification"
                            value="1"
                            className="form-check-input"
                            type="radio"
                            checked={
                              intialData.passport_email_notification == 1
                                ? true
                                : false
                            }
                            onChange={() =>
                              handleChange("passport_email_notification", "1")
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="passport_email_notification_yes"
                          >
                            Yes
                          </label>
                        </div>

                        <div className="form-check mb-2">
                          <Input
                            id="passport_email_notification_no"
                            name="passport_email_notification"
                            value="0"
                            className="form-check-input"
                            type="radio"
                            checked={
                              intialData.passport_email_notification == 0
                                ? true
                                : false
                            }
                            onChange={() =>
                              handleChange("passport_email_notification", "0")
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="passport_email_notification_no"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </Col>
                  ) : null} */}

                  <Col xl={3} lg={3} md={3} sm={3}>
                    <div className="mb-3">
                      <Label className="form-label">{t("Upload passport")}</Label>
                      <FileUpload
                        disabled={intialData.passport == 0 ? true : false}
                        title={t("Upload passport")}
                        onClickHandler={() => handleUploadDocument("Passport")}
                        onDownload={() => handleSingleDownload(1)}
                        fileData={passportFile}
                      />
                    </div>
                  </Col>

                  <Col xl={2} lg={2} md={2} sm={6}>
                    <div className="mb-3">
                      <Select
                        id="passport_status"
                        name="passport_status"
                        className="is-invalid"
                        label="Passport status"
                        required={intialData.passport == 1 ? true : false}
                        options={generalStatusList?.map((item: any, index: any) => {
                          return {
                            label: item.label,
                            value: (index + 1).toString(),
                          };
                        })}
                        value={
                          intialData.passport_status
                            ? generalStatusList?.find(
                              (option: any) => option.value == intialData.passport_status
                            )
                            : ""
                        }
                        isSearchable={true}
                        //isDisabled={intialData.passport == 0 ? true : false}
                        onBlur={() => validation.setFieldTouched("passport_status", true)}
                        onChange={(e: any) => {
                          handleChange("passport_status", e.value);
                        }}
                        error={validation.errors.passport_status}
                        touched={validation.touched.passport_status}
                      />
                    </div>
                  </Col>

                  <Col className="col-12">
                    <hr></hr>
                  </Col>

                  <Col className="col-12" id="residence_card">
                    <ResidenceCard
                      id={user_id}
                      user_id={user_id_code}
                      data={residenceCardData}
                      onChange={(data: any) =>
                        // handleChange("residence_card_data", data)

                        setIntialData((prevData: any) => ({
                          ...prevData,
                          residence_card_data: data,
                        }))
                      }
                    />
                  </Col>

                  <Col className="col-12">
                    <hr></hr>
                  </Col>

                  <Col xl={2} lg={2} md={2} sm={6} id="tax_on_web">
                    <div className="mb-3">
                      <Label className="form-label">{t("Tax on web")}</Label>
                      <div className="form-check mb-2">
                        <Input
                          id="tax_on_web_status_active"
                          name="tax_on_web_status"
                          value="1"
                          className="form-check-input"
                          type="radio"
                          disabled={!userProfile.IS_INTERNAL}
                          checked={intialData.tax_on_web_status == 1 ? true : false}
                          onChange={() => handleChange("tax_on_web_status", "1")}
                        />
                        <label className="form-check-label" htmlFor="tax_on_web_status_active">
                          {t("Activated")}
                        </label>
                      </div>

                      <div className="form-check mb-2">
                        <Input
                          id="tax_on_web_status_nonactive"
                          name="tax_on_web_status"
                          value="0"
                          className="form-check-input"
                          type="radio"
                          disabled={!userProfile.IS_INTERNAL}
                          checked={intialData.tax_on_web_status == 0 ? true : false}
                          onChange={() => handleChange("tax_on_web_status", "0")}
                        />
                        <label className="form-check-label" htmlFor="tax_on_web_status_nonactive">
                          {t("Non-activated")}
                        </label>
                      </div>
                    </div>
                  </Col>

                  <Col xl={2} lg={2} md={2} sm={12}>
                    <div className="mb-3">
                      <div className="input-group is-invalid">
                        {intialData.tax_on_web_status == 1 && userProfile.IS_INTERNAL ? (
                          <Flatpickr
                            id="tax_on_web_expiry_date"
                            name="tax_on_web_expiry_date"
                            label="Tax on web expiry date"
                            required={intialData.tax_on_web_status == 1 ? true : false}
                            className="form-control d-block"
                            type="text"
                            value={intialData.tax_on_web_expiry_date}
                            placeholder="DD/MM/YYYY"
                            options={{
                              altInput: true,
                              altFormat: "d/m/Y",
                              dateFormat: "Y-m-d H:i:S",
                              allowInput: true,
                              plugins: [labelPlugin()],
                              //minDate: "today",
                              onChange: function (selectedDates, dateStr, instance) {
                                handleChange("tax_on_web_expiry_date", dateStr);
                              },
                              onClose: function (selectedDates, dateStr, instance) {
                                handleChange("tax_on_web_expiry_date", dateStr);
                              },
                              locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                            }}
                            error={validation.errors.tax_on_web_expiry_date}
                            touched={validation.touched.tax_on_web_expiry_date}
                          />
                        ) : (
                          <Input
                            id="tax_on_web_expiry_date"
                            name="tax_on_web_expiry_date"
                            label="Tax on web expiry date"
                            className="form-control d-block"
                            type="text"
                            placeholder="DD/MM/YYYY"
                            disabled={true}
                          />
                        )}
                      </div>
                    </div>
                  </Col>

                  <Col xl={2} lg={2} md={2} sm={12}>
                    <div className="mb-3">
                      <div className="input-group is-invalid">
                        {intialData.tax_on_web_status == 1 && userProfile.IS_INTERNAL ? (
                          <Flatpickr
                            id="tax_on_web_due_date"
                            name="tax_on_web_due_date"
                            label="Tax on web due date"
                            required={intialData.tax_on_web_status == 1 ? true : false}
                            className="form-control d-block"
                            type="text"
                            value={intialData.tax_on_web_due_date}
                            placeholder="DD/MM/YYYY"
                            options={{
                              altInput: true,
                              altFormat: "d/m/Y",
                              dateFormat: "Y-m-d H:i:S",
                              allowInput: true,
                              plugins: [labelPlugin()],
                              maxDate: intialData.tax_on_web_expiry_date,
                              //minDate: "today",
                              onChange: function (selectedDates, dateStr, instance) {
                                handleChange("tax_on_web_due_date", dateStr);
                              },
                              onClose: function (selectedDates, dateStr, instance) {
                                handleChange("tax_on_web_due_date", dateStr);
                              },
                              locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                            }}
                            error={validation.errors.tax_on_web_due_date}
                            touched={validation.touched.tax_on_web_due_date}
                          />
                        ) : (
                          <Input
                            id="tax_on_web_due_date"
                            name="tax_on_web_due_date"
                            label="Tax on web due date"
                            className="form-control d-block"
                            type="text"
                            placeholder="DD/MM/YYYY"
                            disabled={true}
                          />
                        )}
                      </div>
                    </div>
                  </Col>

                  <Col xl={3} lg={3} md={3} sm={2}>
                    <div className="mb-3">
                      <Label className="form-label">{t("Upload tax on web document")}</Label>
                      <FileUpload
                        title={t("Upload tax on web document")}
                        onClickHandler={() => handleUploadDocument("Tax")}
                        onDownload={() => handleSingleDownload(3)}
                        fileData={taxFile}
                        disabled={
                          intialData.tax_on_web_status == 1 && userProfile.IS_INTERNAL === true
                            ? false
                            : true
                        }
                      />
                    </div>
                  </Col>

                  <Col className="col-12">
                  </Col>

                  <Col xl={1} lg={1} md={1} sm={6}>
                    <div className="mb-3">
                      <Label className="form-label">{t("Tax inspector proxy")}</Label>
                      <div className="form-check mb-2">
                        <Input
                          id="proxy_tax_inspector_yes"
                          name="proxy_tax_inspector"
                          value="1"
                          className="form-check-input"
                          type="radio"
                          disabled={intialData.tax_on_web_status == 0 ? true : false}
                          checked={intialData.proxy_tax_inspector == 1 ? true : false}
                          onChange={() => handleChange("proxy_tax_inspector", "1")}
                        />
                        <label className="form-check-label" htmlFor="proxy_tax_inspector_yes">
                          {t("Yes")}
                        </label>
                      </div>

                      <div className="form-check mb-2">
                        <Input
                          id="proxy_tax_inspector_no"
                          name="proxy_tax_inspector"
                          value="0"
                          className="form-check-input"
                          type="radio"
                          disabled={intialData.tax_on_web_status == 0 ? true : false}
                          checked={intialData.proxy_tax_inspector == 0 ? true : false}
                          onChange={() => handleChange("proxy_tax_inspector", "0")}
                        />
                        <label className="form-check-label" htmlFor="proxy_tax_inspector_no">
                          {t("No")}
                        </label>
                      </div>
                    </div>
                  </Col>

                  <Col xl={1} lg={1} md={1} sm={6}>
                    <div className="mb-3">
                      <Label className="form-label">{t("Tow proxy")}</Label>
                      <div className="form-check mb-2">
                        <Input
                          id="proxy_tow_yes"
                          name="proxy_tow"
                          value="1"
                          className="form-check-input"
                          type="radio"
                          disabled={intialData.tax_on_web_status == 0 ? true : false}
                          checked={intialData.proxy_tow == 1 ? true : false}
                          onChange={() => handleChange("proxy_tow", "1")}
                        />
                        <label className="form-check-label" htmlFor="proxy_tow_yes">
                          {t("Yes")}
                        </label>
                      </div>

                      <div className="form-check mb-2">
                        <Input
                          id="proxy_tow_no"
                          name="proxy_tow"
                          value="0"
                          className="form-check-input"
                          type="radio"
                          disabled={intialData.tax_on_web_status == 0 ? true : false}
                          checked={intialData.proxy_tow == 0 ? true : false}
                          onChange={() => handleChange("proxy_tow", "0")}
                        />
                        <label className="form-check-label" htmlFor="proxy_tow_no">
                          {t("No")}
                        </label>
                      </div>
                    </div>
                  </Col>

                  <Col xl={2} lg={2} md={2} sm={12}>
                    <div className="mb-3">
                      <DatePicker
                        id="proxy_filing_date"
                        name="proxy_filing_date"
                        label={"Filing date"}
                        placeholder="DD/MM/YYYY"
                        required={intialData.proxy_tax_inspector == 1 ? true : false}
                        value={intialData.proxy_filing_date}
                        disabled={intialData.tax_on_web_status == 0 ? true : false}
                        options={{
                          altInput: true,
                          altFormat: "d/m/Y",
                          dateFormat: "Y-m-d H:i:S",
                          allowInput: true,
                          onChange: function (selectedDates, dateStr, instance) {
                            handleChange("proxy_filing_date", dateStr);
                          },
                          onClose: function (selectedDates, dateStr, instance) {
                            handleChange("proxy_filing_date", dateStr);
                          },
                          locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                        }}
                        error={validation.errors.proxy_filing_date}
                        touched={validation.touched.proxy_filing_date}
                      />
                    </div>
                  </Col>

                  <Col xl={2} lg={2} md={2} sm={12}>
                    <div className="mb-3">
                      <DatePicker
                        id="proxy_approval_date"
                        name="proxy_approval_date"
                        label={"Approval date"}
                        placeholder="DD/MM/YYYY"
                        required={intialData.proxy_tax_inspector == 1 ? true : false}
                        value={intialData.proxy_approval_date}
                        disabled={intialData.tax_on_web_status == 0 ? true : false}
                        options={{
                          altInput: true,
                          altFormat: "d/m/Y",
                          dateFormat: "Y-m-d H:i:S",
                          allowInput: true,
                          onChange: function (selectedDates, dateStr, instance) {
                            handleChange("proxy_approval_date", dateStr);
                          },
                          onClose: function (selectedDates, dateStr, instance) {
                            handleChange("proxy_approval_date", dateStr);
                          },
                          locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                        }}
                        error={validation.errors.proxy_approval_date}
                        touched={validation.touched.proxy_approval_date}
                      />
                    </div>
                  </Col>

                  <Col xl={6} lg={6} md={6} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">{t("Tax inspector office address")}</Label>
                      <Autocomplete
                        id="proxy_inspector_address"
                        name="proxy_inspector_address"
                        className={"form-control"}
                        placeholder={t("Tax inspector office address")}
                        value={intialData.proxy_inspector_address}
                        disabled={intialData.tax_on_web_status == 0 ? true : false}
                        onChange={(e: any) => handleChange("proxy_inspector_address", e.target.value)}
                        apiKey={process.env.REACT_APP_GOOGLE_ADDRESS_API_KEY!}
                        options={{
                          types: ["geocode", "establishment"],
                        }}
                        onPlaceSelected={(data: any) => {
                          handleAddressChange(data);
                        }}
                      />
                    </div>
                  </Col>

                  <Col className="col-12">
                    <hr></hr>
                  </Col>

                  <Col xl={2} lg={2} md={2} sm={12} id="mutality">
                    <div className="mb-3">
                      <Label className="form-label">{t("Mutuality")}</Label>
                      <div className="form-check mb-2">
                        <Input
                          id="mutuality_yes"
                          name="mutuality"
                          value="1"
                          className="form-check-input"
                          type="radio"
                          disabled={!userProfile.IS_INTERNAL}
                          checked={intialData.mutuality == 1 ? true : false}
                          onChange={() => handleChange("mutuality", "1")}
                        />
                        <label className="form-check-label" htmlFor="mutuality_yes">
                          {t("Yes")}
                        </label>
                      </div>

                      <div className="form-check mb-2">
                        <Input
                          id="mutuality_no"
                          name="mutuality"
                          value="0"
                          className="form-check-input"
                          type="radio"
                          disabled={!userProfile.IS_INTERNAL}
                          checked={intialData.mutuality == 0 ? true : false}
                          onChange={() => handleChange("mutuality", "0")}
                        />
                        <label className="form-check-label" htmlFor="mutuality_no">
                          {t("No")}
                        </label>
                      </div>
                    </div>
                  </Col>

                  <Col xl={2} lg={2} md={2} sm={12}>
                    <div className="mb-3">
                      <Input
                        id="mutuality_name"
                        name="mutuality_name"
                        label="Name of the mutuality"
                        placeholder={t("Name of the mutuality")}
                        required={intialData.mutuality == 1 ? true : false}
                        type="text"
                        value={intialData.mutuality_name}
                        readOnly={intialData.mutuality == 0 ? true : false}
                        disabled={!userProfile.IS_INTERNAL}
                        onBlur={validation.handleBlur}
                        onChange={(e) => handleChange("mutuality_name", e.target.value)}
                        invalid={
                          validation.touched.mutuality_name && validation.errors.mutuality_name
                            ? true
                            : false
                        }
                      />
                    </div>
                    {validation.touched.mutuality_name && validation.errors.mutuality_name ? (
                      <FormFeedback type="invalid">{validation.errors.mutuality_name}</FormFeedback>
                    ) : null}
                  </Col>

                  <Col xl={2} lg={2} md={2} sm={12}>
                    <div className="mb-3">
                      <div className="input-group is-invalid">
                        {intialData.mutuality == 1 && userProfile.IS_INTERNAL ? (
                          <Flatpickr
                            id="mutuality_exp_date"
                            name="mutuality_exp_date"
                            label="Expiry date"
                            required={intialData.mutuality == 1 ? true : false}
                            className="form-control d-block"
                            type="text"
                            value={intialData.mutuality_exp_date}
                            placeholder="DD/MM/YYYY"
                            options={{
                              altInput: true,
                              altFormat: "d/m/Y",
                              dateFormat: "Y-m-d H:i:S",
                              allowInput: true,
                              plugins: [labelPlugin()],
                              //minDate: "today",
                              onChange: function (selectedDates, dateStr, instance) {
                                handleChange("mutuality_exp_date", dateStr);
                              },
                              onClose: function (selectedDates, dateStr, instance) {
                                handleChange("mutuality_exp_date", dateStr);
                              },
                              locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                            }}
                            error={validation.errors.mutuality_exp_date}
                            touched={validation.touched.mutuality_exp_date}
                          />
                        ) : (
                          <Input
                            id="mutuality_exp_date"
                            name="mutuality_exp_date"
                            label="Expiry date"
                            className="form-control d-block"
                            type="text"
                            placeholder="DD/MM/YYYY"
                            disabled={true}
                          />
                        )}
                      </div>
                    </div>
                  </Col>

                  {/* <Col className="col-12">
              <hr></hr>
            </Col>

            <Col xl={2} lg={2} md={2} sm={12}>
              <div className="mb-3">
                <Label className="form-label">Bank account</Label>
                <Input
                  id="bank_account"
                  name="bank_account"
                  placeholder="Bank account"
                  type="text"
                  value={intialData.bank_account}
                  onBlur={validation.handleBlur}
                  onChange={(e) => handleChange("bank_account", e.target.value)}
                  invalid={
                    validation.touched.bank_account && validation.errors.bank_account ? true : false
                  }
                />
              </div>
              {validation.touched.bank_account && validation.errors.bank_account ? (
                <FormFeedback type="invalid">{validation.errors.bank_account}</FormFeedback>
              ) : null}
            </Col>

            <Col xl={2} lg={2} md={2} sm={12}>
              <div className="mb-3">
                <Label className="form-label">Swift code</Label>
                <Input
                  id="swift_code"
                  name="swift_code"
                  placeholder="Swift code"
                  type="text"
                  value={intialData.swift_code}
                  onBlur={validation.handleBlur}
                  onChange={(e) => handleChange("swift_code", e.target.value)}
                  invalid={
                    validation.touched.swift_code && validation.errors.swift_code ? true : false
                  }
                />
              </div>
              {validation.touched.swift_code && validation.errors.swift_code ? (
                <FormFeedback type="invalid">{validation.errors.swift_code}</FormFeedback>
              ) : null}
            </Col>

            <Col xl={2} lg={2} md={2} sm={12}>
              <div className="mb-3">
                <Label className="form-label">IBAN Number</Label>
                <Input
                  id="iban_number"
                  name="iban_number"
                  placeholder="IBAN Number"
                  type="text"
                  value={intialData.iban_number}
                  onBlur={validation.handleBlur}
                  onChange={(e) => handleChange("iban_number", e.target.value)}
                  invalid={
                    validation.touched.iban_number && validation.errors.iban_number ? true : false
                  }
                />
              </div>
              {validation.touched.iban_number && validation.errors.iban_number ? (
                <FormFeedback type="invalid">{validation.errors.iban_number}</FormFeedback>
              ) : null}
            </Col>

            <Col xl={2} lg={2} md={2} sm={12}>
              <div className="mb-3">
                <Label className="form-label">Bank Address</Label>
                <Input
                  id="bank_address"
                  name="bank_address"
                  placeholder="Bank Address"
                  type="text"
                  value={intialData.bank_address}
                  onBlur={validation.handleBlur}
                  onChange={(e) => handleChange("bank_address", e.target.value)}
                  invalid={
                    validation.touched.bank_address && validation.errors.bank_address ? true : false
                  }
                />
              </div>
              {validation.touched.bank_address && validation.errors.bank_address ? (
                <FormFeedback type="invalid">{validation.errors.bank_address}</FormFeedback>
              ) : null}
            </Col> */}
                </Row>
              </CardBody>

              <CardHeader className="bg-transparent border-bottom border-primary pb-0" id="single_permit">
                <CardTitle className="card-title text-uppercase">
                  {t("Work permit / Single permit")}
                </CardTitle>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col xl={2} lg={2} md={2} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">{t("Single permit")} ({t("Count")})</Label>
                      <Input
                        id="single_permit_count"
                        name="single_permit_count"
                        placeholder="Single permit (Count)"
                        type="number"
                        readOnly={true}
                        value={intialData.single_permit_count}
                        onChange={(e) => handleChange("single_permit_count", e.target.value)}
                      />
                    </div>
                  </Col>

                  <Col className="col-12">
                    <br />
                  </Col>

                  <Col className="col-12">
                    <SinglePermit
                      id={user_id}
                      user_id={user_id_code}
                      data={singlePermitData}
                      onChange={(data: any) =>
                        // handleChange("single_permit_data", data)

                        setIntialData((prevData: any) => ({
                          ...prevData,
                          single_permit_data: data,
                        }))
                      }
                    />
                  </Col>
                </Row>
              </CardBody>



              <CardHeader className="bg-transparent border-bottom border-primary pb-0" id="single_permit">
                <CardTitle className="card-title text-uppercase">
                  {t("Annexture 46")}
                </CardTitle>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col className="col-12" id="annexure_46">
                    <Annexture46
                      id={user_id}
                      user_id={user_id_code}
                      data={annexData}
                      onChange={(data: any) =>
                        // handleChange("single_permit_data", data)

                        setIntialData((prevData: any) => ({
                          ...prevData,
                          annex_data: data,
                        }))
                      }
                    />
                  </Col>
                </Row>
              </CardBody>

              {userProfile.IS_COMPANY_ADMIN && ((intialData.resident_status == 1 || isTaxrturnEnable == 1) || (intialData.resident_status == 2 || isTaxrturnEnable == 2)) ? (
                <>
                  <CardHeader className="bg-transparent border-bottom border-primary pb-0">
                    <CardTitle className="card-title text-uppercase">
                      {t("Questionnaire - Tax return")}
                    </CardTitle>
                  </CardHeader>

                  <CardBody>
                    {!!(intialData.resident_status == 1 || isTaxrturnEnable == 1) && (
                      <Row>
                        <Col xl={1} lg={1} md={1} sm={6}>
                          <div className="mb-3">
                            <Label className="form-label"> {t("IPP Tax return")}</Label>
                            <div className="form-check mb-2">
                              <Input
                                id="questionnaire_trigger_resident_yes"
                                name="questionnaire_trigger_resident"
                                value="1"
                                className="form-check-input"
                                type="radio"
                                checked={
                                  intialData.questionnaire_trigger_resident == 1 ? true : false
                                }
                                onChange={() => handleChange("questionnaire_trigger_resident", "1")}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="questionnaire_trigger_resident_yes"
                              >
                                {t("Active")}
                              </label>
                            </div>

                            <div className="form-check mb-2">
                              <Input
                                id="questionnaire_trigger_resident_no"
                                name="questionnaire_trigger_resident"
                                value="0"
                                className="form-check-input"
                                type="radio"
                                checked={
                                  intialData.questionnaire_trigger_resident == 0 ? true : false
                                }
                                onChange={() => handleChange("questionnaire_trigger_resident", "0")}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="questionnaire_trigger_resident_no"
                              >
                                {t("Inactive")}
                              </label>
                            </div>
                          </div>
                        </Col>
                        <Col xl={1} lg={1} md={1} sm={6}>
                          <div className="mb-3">
                            <Select
                              id="questionnaire_year_resident"
                              name="questionnaire_year_resident"
                              className="is-invalid"
                              label="Tax Year"
                              required={intialData.questionnaire_trigger_resident == 1 ? true : false}
                              options={yearsOnward()}
                              value={
                                intialData.questionnaire_year_resident
                                  ? yearsOnward().find(
                                    (option: any) =>
                                      option.value == intialData.questionnaire_year_resident
                                  )
                                  : ""
                              }
                              isSearchable={true}
                              isDisabled={
                                intialData.questionnaire_trigger_resident == 0 ? true : false
                              }
                              onBlur={() =>
                                validation.setFieldTouched("questionnaire_year_resident", true)
                              }
                              onChange={(e: any) => {
                                handleChange("questionnaire_year_resident", e.value);
                              }}
                              error={validation.errors.questionnaire_year_resident}
                              touched={validation.touched.questionnaire_year_resident}
                            />
                          </div>
                        </Col>
                        <Col xl={2} lg={2} md={2} sm={6}>
                          <div className="mb-3">
                            <Select
                              id="questionnaire_tax_return_type_resident"
                              name="questionnaire_tax_return_type_resident"
                              className="is-invalid"
                              label="Tax return type"
                              required={intialData.questionnaire_trigger_resident == 1 ? true : false}
                              options={taxReturnTypeResident}
                              value={
                                intialData.questionnaire_tax_return_type_resident
                                  ? taxReturnTypeOption.find(
                                    (option: any) =>
                                      option.value ==
                                      intialData.questionnaire_tax_return_type_resident
                                  )
                                  : ""
                              }
                              isSearchable={true}
                              isDisabled={
                                intialData.questionnaire_trigger_resident == 0 ? true : false
                              }
                              onBlur={() =>
                                validation.setFieldTouched(
                                  "questionnaire_tax_return_type_resident",
                                  true
                                )
                              }
                              onChange={(e: any) => {
                                handleChange("questionnaire_tax_return_type_resident", e.value);
                              }}
                              error={validation.errors.questionnaire_tax_return_type_resident}
                              touched={validation.touched.questionnaire_tax_return_type_resident}
                            />
                          </div>
                        </Col>

                        <Col xl={2} lg={2} md={2} sm={12}>
                          <div className="mb-3">
                            <div className="input-group is-invalid">
                              {intialData.questionnaire_trigger_resident == 1 ? (
                                <Flatpickr
                                  id="questionnaire_expiry_date_resident"
                                  name="questionnaire_expiry_date_resident"
                                  className="form-control d-block"
                                  type="text"
                                  label="Official due date"
                                  required={intialData.questionnaire_trigger_resident == 1 ? true : false}
                                  value={intialData.questionnaire_expiry_date_resident}
                                  placeholder="DD/MM/YYYY"
                                  options={{
                                    altInput: true,
                                    altFormat: "d/m/Y",
                                    dateFormat: "Y-m-d H:i:S",
                                    //minDate: "today",
                                    allowInput: true,
                                    plugins: [labelPlugin()],
                                    onChange: function (selectedDates, dateStr, instance) {
                                      handleChange("questionnaire_expiry_date_resident", dateStr);
                                    },
                                    onClose: function (selectedDates, dateStr, instance) {
                                      handleChange("questionnaire_expiry_date_resident", dateStr);
                                    },
                                    locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                                  }}
                                  error={validation.errors.questionnaire_expiry_date_resident}
                                  touched={validation.touched.questionnaire_expiry_date_resident}
                                />
                              ) : (
                                <Input
                                  id="questionnaire_expiry_date_resident"
                                  name="questionnaire_expiry_date_resident"
                                  className="form-control d-block"
                                  type="text"
                                  label="Official due date"
                                  placeholder="DD/MM/YYYY"
                                  disabled={true}
                                />
                              )}
                            </div>
                          </div>
                        </Col>

                        <Col xl={2} lg={2} md={2} sm={12}>
                          <div className="mb-3">
                            {intialData.questionnaire_trigger_resident == 1 ? (
                              <Flatpickr
                                id="questionnaire_due_date_resident"
                                name="questionnaire_due_date_resident"
                                className="form-control d-block"
                                type="text"
                                label="Sending date"
                                required={intialData.questionnaire_trigger_resident == 1 ? true : false}
                                value={intialData.questionnaire_due_date_resident}
                                placeholder="DD/MM/YYYY"
                                disabled={
                                  intialData.questionnaire_status_resident != 0 &&
                                    intialData.questionnaire_status_resident != null
                                    ? true
                                    : false
                                }
                                options={{
                                  altInput: true,
                                  altFormat: "d/m/Y",
                                  dateFormat: "Y-m-d",
                                  // minDate: "today",
                                  allowInput: true,
                                  plugins: [labelPlugin()],
                                  onChange: function (selectedDates, dateStr, instance) {
                                    handleChange("questionnaire_due_date_resident", dateStr);
                                  },
                                  onClose: function (selectedDates, dateStr, instance) {
                                    handleChange("questionnaire_due_date_resident", dateStr);
                                  },
                                  locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                                }}
                                error={validation.errors.questionnaire_due_date_resident}
                                touched={validation.touched.questionnaire_due_date_resident}
                              />
                            ) : (
                              <Input
                                id="questionnaire_due_date_resident"
                                name="questionnaire_due_date_resident"
                                label="Sending date"
                                className="form-control d-block"
                                type="text"
                                placeholder="DD/MM/YYYY"
                                disabled={true}
                              />
                            )}
                          </div>
                        </Col>

                        <Col xl={2} lg={2} md={2} sm={6}>
                          <div className="mb-3">
                            <Select
                              id="questionnaire_status_resident"
                              name="questionnaire_status_resident"
                              className="is-invalid"
                              label="Questionnaire status"
                              options={questStatusOptions}
                              value={
                                intialData.questionnaire_status_resident
                                  ? questStatusOptions.find(
                                    (option: any) =>
                                      option.value == intialData.questionnaire_status_resident
                                  )
                                  : ""
                              }
                              isSearchable={true}
                              isDisabled={true}
                              onBlur={() =>
                                validation.setFieldTouched("questionnaire_status_resident", true)
                              }
                              onChange={(e: any) => {
                                handleChange("questionnaire_status_resident", e.value);
                              }}
                            />
                          </div>
                        </Col>
                        {isTaxrturnEnable == 0 && (
                          <Col xl={1} lg={1} md={1} sm={6}>
                            <Button
                              className={`mt-4 btn btn-sm btn-info`}
                              disabled={!enableAdd}
                              onClick={() => addTaxreturn("INR")}
                              title="Add INR Questionnaire"
                            >
                              <span className="align-middle material-symbols-outlined fw-light fs-4">
                                add
                              </span>
                            </Button>{" "}
                          </Col>
                        )}
                        {/* <Button
                            className={`mt-4 btn btn-sm ${enableAdd ? 'btn-primary' : 'btn-info'}`}
                            disabled={enableAdd}
                            onClick={() => deleteTaxreturn("IPP")}
                          >
                            <span className="align-middle material-symbols-outlined fw-light fs-4">delete</span>
                          </Button>{" "} */}
                      </Row>
                    )}
                    {!!(intialData.resident_status == 2 || isTaxrturnEnable == 2) && (
                      <Row>
                        <Col xl={1} lg={1} md={1} sm={6}>
                          <div className="mb-3">
                            <Label className="form-label">{t("INR Tax return")}</Label>
                            <div className="form-check mb-2">
                              <Input
                                id="questionnaire_trigger_nonresident_yes"
                                name="questionnaire_trigger_nonresident"
                                value="1"
                                className="form-check-input"
                                type="radio"
                                checked={
                                  intialData.questionnaire_trigger_nonresident == 1 ? true : false
                                }
                                onChange={() =>
                                  handleChange("questionnaire_trigger_nonresident", "1")
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="questionnaire_trigger_nonresident_yes"
                              >
                                {t("Active")}
                              </label>
                            </div>

                            <div className="form-check mb-2">
                              <Input
                                id="questionnaire_trigger_nonresident_no"
                                name="questionnaire_trigger_nonresident"
                                value="0"
                                className="form-check-input"
                                type="radio"
                                checked={
                                  intialData.questionnaire_trigger_nonresident == 0 ? true : false
                                }
                                onChange={() =>
                                  handleChange("questionnaire_trigger_nonresident", "0")
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="questionnaire_trigger_nonresident_no"
                              >
                                {t("Inactive")}
                              </label>
                            </div>
                          </div>
                        </Col>
                        <Col xl={1} lg={1} md={1} sm={6}>
                          <div className="mb-3">
                            <Select
                              id="questionnaire_year_nonresident"
                              name="questionnaire_year_nonresident"
                              className="is-invalid"
                              label="Tax Year"
                              required={intialData.questionnaire_trigger_nonresident == 1 ? true : false}
                              options={yearsOnward()}
                              value={
                                intialData.questionnaire_year_nonresident
                                  ? yearsOnward().find(
                                    (option: any) =>
                                      option.value == intialData.questionnaire_year_nonresident
                                  )
                                  : ""
                              }
                              isSearchable={true}
                              isDisabled={
                                intialData.questionnaire_trigger_nonresident == 0 ? true : false
                              }
                              onBlur={() =>
                                validation.setFieldTouched("questionnaire_year_nonresident", true)
                              }
                              onChange={(e: any) => {
                                handleChange("questionnaire_year_nonresident", e.value);
                              }}

                              error={validation.errors.questionnaire_year_nonresident}
                              touched={validation.touched.questionnaire_year_nonresident}
                            />
                          </div>
                        </Col>
                        <Col xl={2} lg={2} md={2} sm={6}>
                          <div className="mb-3">
                            <Select
                              id="questionnaire_tax_return_type_nonresident"
                              name="questionnaire_tax_return_type_nonresident"
                              className="is-invalid"
                              label="Tax return type"
                              required={intialData.questionnaire_trigger_nonresident == 1 ? true : false}
                              options={taxReturnTypeNonResident}
                              value={
                                intialData.questionnaire_tax_return_type_nonresident
                                  ? taxReturnTypeOption.find(
                                    (option: any) =>
                                      option.value ==
                                      intialData.questionnaire_tax_return_type_nonresident
                                  )
                                  : ""
                              }
                              isSearchable={true}
                              isDisabled={
                                intialData.questionnaire_trigger_nonresident == 0 ? true : false
                              }
                              onBlur={() =>
                                validation.setFieldTouched(
                                  "questionnaire_tax_return_type_nonresident",
                                  true
                                )
                              }
                              onChange={(e: any) => {
                                handleChange("questionnaire_tax_return_type_nonresident", e.value);
                              }}
                              error={validation.errors.questionnaire_tax_return_type_nonresident}
                              touched={validation.touched.questionnaire_tax_return_type_nonresident}
                            />
                          </div>
                        </Col>

                        <Col xl={2} lg={2} md={2} sm={12}>
                          <div className="mb-3">
                            {intialData.questionnaire_trigger_nonresident == 1 ? (
                              <Flatpickr
                                id="questionnaire_expiry_date_nonresident"
                                name="questionnaire_expiry_date_nonresident"
                                className="form-control d-block"
                                label="Official due date"
                                required={intialData.questionnaire_trigger_nonresident == 1 ? true : false}
                                type="text"
                                value={intialData.questionnaire_expiry_date_nonresident}
                                placeholder="DD/MM/YYYY"
                                options={{
                                  altInput: true,
                                  altFormat: "d/m/Y",
                                  dateFormat: "Y-m-d H:i:S",
                                  //minDate: "today",
                                  allowInput: true,
                                  plugins: [labelPlugin()],
                                  onChange: function (selectedDates, dateStr, instance) {
                                    handleChange(
                                      "questionnaire_expiry_date_nonresident",
                                      dateStr
                                    );
                                  },
                                  onClose: function (selectedDates, dateStr, instance) {
                                    handleChange(
                                      "questionnaire_expiry_date_nonresident",
                                      dateStr
                                    );
                                  },
                                  locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                                }}
                                error={validation.errors.questionnaire_expiry_date_nonresident}
                                touched={validation.touched.questionnaire_expiry_date_nonresident}
                              />
                            ) : (
                              <Input
                                id="questionnaire_expiry_date_nonresident"
                                name="questionnaire_expiry_date_nonresident"
                                className="form-control d-block"
                                type="text"
                                label="Official due date"
                                placeholder="DD/MM/YYYY"
                                disabled={true}
                              />
                            )}
                          </div>
                        </Col>

                        <Col xl={2} lg={2} md={2} sm={12}>
                          <div className="mb-3">
                            {intialData.questionnaire_trigger_nonresident == 1 ? (
                              <Flatpickr
                                id="questionnaire_due_date_nonresident"
                                name="questionnaire_due_date_nonresident"
                                className="form-control d-block"
                                type="text"
                                label="Sending date"
                                required={intialData.questionnaire_trigger_nonresident == 1 ? true : false}
                                value={intialData.questionnaire_due_date_nonresident}
                                placeholder="DD/MM/YYYY"
                                disabled={
                                  intialData.questionnaire_status_nonresident != 0 &&
                                    intialData.questionnaire_status_nonresident != null
                                    ? true
                                    : false
                                }
                                options={{
                                  altInput: true,
                                  altFormat: "d/m/Y",
                                  dateFormat: "Y-m-d",
                                  // minDate: "today",
                                  allowInput: true,
                                  plugins: [labelPlugin()],
                                  onChange: function (selectedDates, dateStr, instance) {
                                    handleChange("questionnaire_due_date_nonresident", dateStr);
                                  },
                                  onClose: function (selectedDates, dateStr, instance) {
                                    handleChange("questionnaire_due_date_nonresident", dateStr);
                                  },
                                  locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                                }}
                                error={validation.errors.questionnaire_due_date_nonresident}
                                touched={validation.touched.questionnaire_due_date_nonresident}
                              />
                            ) : (
                              <Input
                                id="questionnaire_due_date_nonresident"
                                name="questionnaire_due_date_nonresident"
                                label="Sending date"
                                className="form-control d-block"
                                type="text"
                                placeholder="DD/MM/YYYY"
                                disabled={true}
                              />
                            )}
                          </div>
                        </Col>

                        <Col xl={2} lg={2} md={2} sm={6}>
                          <div className="mb-3">
                            <Select
                              id="questionnaire_status_nonresident"
                              name="questionnaire_status_nonresident"
                              className="is-invalid"
                              options={questStatusOptions}
                              label="Questionnaire status"
                              value={
                                intialData.questionnaire_status_nonresident
                                  ? questStatusOptions.find(
                                    (option: any) =>
                                      option.value == intialData.questionnaire_status_nonresident
                                  )
                                  : ""
                              }
                              isSearchable={true}
                              isDisabled={true}
                              onBlur={() =>
                                validation.setFieldTouched("questionnaire_status_nonresident", true)
                              }
                              onChange={(e: any) => {
                                handleChange("questionnaire_status_nonresident", e.value);
                              }}
                            />
                            {validation.touched.questionnaire_status_nonresident &&
                              validation.errors.questionnaire_status_nonresident ? (
                              <FormFeedback type="invalid">
                                {validation.errors.questionnaire_status_nonresident}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        {isTaxrturnEnable == 0 && (
                          <Col xl={1} lg={1} md={1} sm={6}>
                            <Button
                              className={`mt-4 btn btn-sm btn-info`}
                              disabled={!enableAdd}
                              onClick={() => addTaxreturn("IPP")}
                              title={t("Add IPP Questionnaire")}
                            >
                              <span className="align-middle material-symbols-outlined fw-light fs-4">
                                add
                              </span>
                            </Button>{" "}
                            {/* <Button
                            className={`mt-4 btn btn-sm ${enableAdd ? 'btn-primary' : 'btn-info'}`}
                            disabled={enableAdd}
                            onClick={() => deleteTaxreturn("IPP")}
                          >
                            <span className="align-middle material-symbols-outlined fw-light fs-4">delete</span>
                          </Button>{" "} */}
                          </Col>
                        )}
                      </Row>
                    )}
                  </CardBody>
                </>
              ) : null}

              <CardHeader className="bg-transparent border-bottom border-primary pb-0">
                <CardTitle className="card-title text-uppercase">
                  {t("Annual calculation details")}
                </CardTitle>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col xl={2} lg={2} md={2} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">{t("Annual calculation request")}</Label>
                      <div className="form-check mb-2">
                        <Input
                          id="annual_calculation_request_yes"
                          name="annual_calculation_request"
                          value="1"
                          className="form-check-input"
                          type="radio"
                          disabled={!userProfile.IS_INTERNAL}
                          checked={intialData.annual_calculation_request == 1 ? true : false}
                          onChange={() => handleChange("annual_calculation_request", "1")}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="annual_calculation_request_yes"
                        >
                          {t("Yes")}
                        </label>
                      </div>

                      <div className="form-check mb-2">
                        <Input
                          id="annual_calculation_request_no"
                          name="annual_calculation_request"
                          value="0"
                          className="form-check-input"
                          type="radio"
                          disabled={!userProfile.IS_INTERNAL}
                          checked={intialData.annual_calculation_request == 0 ? true : false}
                          onChange={() => handleChange("annual_calculation_request", "0")}
                        />
                        <label className="form-check-label" htmlFor="annual_calculation_request_no">
                          {t("No")}
                        </label>
                      </div>
                    </div>
                  </Col>

                  <Col xl={2} lg={2} md={2} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">{t("Annual calculation")}</Label>
                      <Select
                        id="annual_calculation"
                        name="annual_calculation"
                        className="is-invalid"
                        options={calculationOptions}
                        value={
                          intialData.annual_calculation
                            ? calculationOptions.find(
                              (option: any) => option.value == intialData.annual_calculation
                            )
                            : ""
                        }
                        isSearchable={true}
                        isDisabled={
                          intialData.annual_calculation_request == 1 &&
                            userProfile.IS_INTERNAL === true
                            ? false
                            : true
                        }
                        onBlur={() => validation.setFieldTouched("annual_calculation", true)}
                        onChange={(e: any) => {
                          handleChange("annual_calculation", e.value);
                        }}
                      />
                      {validation.touched.annual_calculation &&
                        validation.errors.annual_calculation ? (
                        <FormFeedback type="invalid">
                          {validation.errors.annual_calculation}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col xl={2} lg={2} md={2} sm={12}>
                    <div className="mb-3">
                      <Label className="form-label">{t("Due date")}</Label>
                      <div className="input-group is-invalid">
                        {intialData.annual_calculation_request == 1 && userProfile.IS_INTERNAL ? (
                          <Flatpickr
                            id="annual_calculation_due_date"
                            name="annual_calculation_due_date"
                            className="form-control d-block"
                            type="text"
                            value={intialData.annual_calculation_due_date}
                            placeholder="DD/MM/YYYY"
                            options={{
                              altInput: true,
                              altFormat: "d/m/Y",
                              dateFormat: "Y-m-d H:i:S",
                              //minDate: "today",
                              allowInput: true,
                              plugins: [labelPlugin()],
                              onChange: function (selectedDates, dateStr, instance) {
                                handleChange("annual_calculation_due_date", dateStr);
                              },
                              onClose: function (selectedDates, dateStr, instance) {
                                handleChange("annual_calculation_due_date", dateStr);
                              },
                              locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                            }}
                          />
                        ) : (
                          <Input
                            id="annual_calculation_due_date"
                            name="annual_calculation_due_date"
                            className="form-control d-block"
                            type="text"
                            placeholder="DD/MM/YYYY"
                            disabled={true}
                          />
                        )}
                      </div>
                      {validation.touched.annual_calculation_due_date &&
                        validation.errors.annual_calculation_due_date ? (
                        <FormFeedback type="invalid">
                          {validation.errors.annual_calculation_due_date}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>

                  <Col className="col-12">
                    <hr></hr>
                  </Col>

                  <Col xl={2} lg={2} md={2} sm={6}>
                    <div className="mb-3">
                      <Label className="form-label">{t("Pay roll")}</Label>
                      <div className="form-check mb-2">
                        <Input
                          id="pay_roll_yes"
                          name="pay_roll"
                          value="1"
                          className="form-check-input"
                          type="radio"
                          checked={intialData.pay_roll == 1 ? true : false}
                          onChange={() => handleChange("pay_roll", "1")}
                        />
                        <label className="form-check-label" htmlFor="pay_roll_yes">
                          {t("Yes")}
                        </label>
                      </div>

                      <div className="form-check mb-2">
                        <Input
                          id="pay_roll_no"
                          name="pay_roll"
                          value="0"
                          className="form-check-input"
                          type="radio"
                          checked={intialData.pay_roll == 0 ? true : false}
                          onChange={() => handleChange("pay_roll", "0")}
                        />
                        <label className="form-check-label" htmlFor="pay_roll_no">
                          {t("No")}
                        </label>
                      </div>
                    </div>
                  </Col>

                  <Col xl={2} lg={2} md={2} sm={6}>
                    <div className="mb-3">
                      <Select
                        id="pay_roll_year"
                        name="pay_roll_year"
                        label="Year"
                        required={intialData.pay_roll == 1 ? true : false}
                        className="is-invalid"
                        options={yearsOnward()}
                        value={
                          intialData.pay_roll_year
                            ? yearsOnward().find(
                              (option: any) => option.value == intialData.pay_roll_year
                            )
                            : ""
                        }
                        isSearchable={true}
                        isDisabled={intialData.pay_roll == 0 ? true : false}
                        onBlur={() => validation.setFieldTouched("pay_roll_year", true)}
                        onChange={(e: any) => {
                          handleChange("pay_roll_year", e.value);
                        }}
                        error={validation.errors.pay_roll_year}
                        touched={validation.touched.pay_roll_year}
                      />
                    </div>
                  </Col>

                  <Col xl={2} lg={2} md={2} sm={6}>
                    <div className="mb-3">
                      <Select
                        id="pay_roll_requested_year"
                        name="pay_roll_requested_year"
                        className="is-invalid"
                        label="Requested year"
                        required={intialData.pay_roll == 1 ? true : false}
                        options={yearsOnward()}
                        value={
                          intialData.pay_roll_requested_year
                            ? yearsOnward().find(
                              (option: any) => option.value == intialData.pay_roll_requested_year
                            )
                            : ""
                        }
                        isSearchable={true}
                        isDisabled={intialData.pay_roll == 0 ? true : false}
                        onBlur={() => validation.setFieldTouched("pay_roll_requested_year", true)}
                        onChange={(e: any) => {
                          handleChange("pay_roll_requested_year", e.value);
                        }}
                        error={validation.errors.pay_roll_requested_year}
                        touched={validation.touched.pay_roll_requested_year}
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>

              <CardFooter className="bg-transparent border-top">
                <div className="d-sm-flex flex-wrap ">
                  <div className="d-flex gap-2"></div>

                  <div className="ms-auto d-flex gap-2 justify-content-end">
                    {userProfile.CAN_EDIT ? (
                      <Button
                        color="primary"
                        className="btn-block"
                        type="submit"
                        onClick={() => handleChange("submit_btn_code", "update_close")}
                        disabled={loading}
                        loading={loading}
                      >
                        {t("Update & Close")}
                      </Button>
                    ) : null}

                    <Button
                      color="primary"
                      className="btn-block"
                      type="submit"
                      onClick={() => handleChange("submit_btn_code", "update")}
                      disabled={loading}
                      loading={loading}
                    >
                      {t("Update")}
                    </Button>

                    <Button
                      color="danger"
                      className="btn-block"
                      type="reset"
                      onClick={handleRedirect}
                      disabled={loading}
                      loading={loading}
                    >
                      {t("Cancel")}
                    </Button>
                  </div>
                </div>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
      <Modal
        isOpen={addQuestionnaire}
        toggle={() => setAddQuestionnaire(!addQuestionnaire)}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title">{t("Send Questionnaire")}</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setAddQuestionnaire(false)}
            aria-label="Close"
          ></button>
        </div>
        <ModalBody>
          <p>
            {t("User status is")} {`${intialData.resident_status == 1 ? t("Resident") : t("Non resident")}`}, {t("Are you sure you want sent")} {`${intialData.resident_status == 1 ? `INR` : `IPP`}`}{" "}
            {t("Questionnaire")}?
          </p>
        </ModalBody>
        <ModalFooter>
          <button
            type="submit"
            className="btn btn-primary btn-md ms-2"
            onClick={() => sendQuestionnaire(intialData.resident_status)}
            disabled={loading}
          >
            {t("Send")}
          </button>
          <button
            type="button"
            className="btn btn-outline-danger btn-md ms-2"
            onClick={() => setAddQuestionnaire(false)}
          >
            {t("Cancel")}
          </button>
        </ModalFooter>
      </Modal>
    </React.Fragment >
  );
};

export default UsersGeneral;
