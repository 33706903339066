import { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Label, Row } from "reactstrap";
import Select from "react-select";
import commonService from "../../../services/common.service";
import {
  addIndividualInCompany,
  datePickerI18,
  filterFeesTypeByLanguage,
  filterListInByKey,
} from "../../../helpers/functions_helper";
import { taskStatusOptions } from "../../../common/staticdata";
// Actions
import { CommonAction } from "../../../store/actions";
import { useTranslation } from "react-i18next";
import Flatpickr from "../../../components/Form/Flatpickr";

type FiltersProps = {
  filters: any;
  onApply: any;
  onClear: any;
  onChange?: any;
};

const Filters: FC<FiltersProps> = ({ filters, onApply, onClear, onChange }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [query, setQuery] = useState("");
  const [loadingCompanyOptions, setLoadingCompanyOptions] = useState(true);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [loadingUserOptions, setLoadingUserOptions] = useState(true);
  const [userOptions, setUserOptions] = useState([]);
  const [disabledCompanyUserOption, setDisabledCompanyUserOption] = useState(true);
  const [loadingCompanyUserOption, setloadingCompanyUserOption] = useState(false);
  const [usersOptions, setUsersOptions] = useState([]);
  const [typeOption, setTypeOption] = useState([]);
  const [taskStatusOption, settaskStatusOption] = useState(taskStatusOptions);

  let { getCompanyOptionsList, getUserOptionsList, getFeesOptionsList, loading } = useSelector(
    (state: any) => ({
      getCompanyOptionsList: state.Common.GET_COMPANY_OPTIONS_LIST_SUCCESS_NEW,
      getUserOptionsList: state.Common.GET_USER_OPTIONS_LIST_SUCCESS_NEW,
      getFeesOptionsList: state.Common.GET_FEES_OPTIONS_LIST_SUCCESS_NEW,
      loading: state.Common.loading,
    })
  );

  // Select options list API call -------------------------------------------------
  const getUsersByMultipleCompanies = async (DATA: any) => {
    const response = (await commonService.getUsersByMultipleCompanies({
      company_ids: DATA,
    })) as any;

    setUsersOptions(response?.data?.data);
    setloadingCompanyUserOption(false);
    setDisabledCompanyUserOption(false);
  };

  // Get options list -------------------------------------------------
  useEffect(() => {
    if (!getCompanyOptionsList) dispatch(CommonAction.getCompanyOptionsList());
    if (!getUserOptionsList) dispatch(CommonAction.getUserOptionsList());
    if (!getFeesOptionsList) dispatch(CommonAction.getFeesOptionsList());
  }, []);

  useEffect(() => {
    if (getCompanyOptionsList.data) {
      const item = addIndividualInCompany(getCompanyOptionsList.data) as any;
      setCompanyOptions(item);
      setLoadingCompanyOptions(false);
    }
  }, [getCompanyOptionsList]);

  useEffect(() => {
    if (getUserOptionsList.data) {
      const item = getUserOptionsList.data;
      setUserOptions(item);
      setLoadingUserOptions(false);
    }
  }, [getUserOptionsList]);

  useEffect(() => {
    setUsersOptions([]);
    setloadingCompanyUserOption(false);
    setDisabledCompanyUserOption(true);

    if (filters?.company_id?.length > 0) getUsersByMultipleCompanies(filters?.company_id) as any;
  }, [filters?.company_id]);

  useEffect(() => {
    if (getFeesOptionsList.data) {
      let feesOptionsList = filterFeesTypeByLanguage(getFeesOptionsList.data);
      const fee_type_list: any = filterListInByKey(feesOptionsList, "is_sensitive", 0);
      setTypeOption(fee_type_list);
    }
  }, [getFeesOptionsList, localStorage.getItem("I18N_LANGUAGE")]);

  return (
    <Container>
      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label">{t("Client")}</Label>
            <Select
              placeholder={t("Select...")}
              options={companyOptions}
              value={companyOptions.filter((options: any) =>
                filters?.company_id?.includes(options.value)
              )}
              isMulti
              isSearchable={true}
              isDisabled={loadingCompanyOptions}
              isLoading={loadingCompanyOptions}
              onChange={(options: any) => {
                onChange({
                  is_filtered: true,
                  company_id: Array.isArray(options) ? options.map((data) => data.value) : [],
                });
              }}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label">{t("Contact person")}</Label>
            <Select
              placeholder={t("Select...")}
              options={usersOptions}
              value={
                filters?.user_id &&
                usersOptions.filter((options: any) => filters?.user_id?.includes(options.value))
              }
              isMulti
              isSearchable={true}
              isDisabled={disabledCompanyUserOption}
              isLoading={loadingCompanyUserOption}
              onChange={(options: any) => {
                onChange({
                  is_filtered: true,
                  user_id: Array.isArray(options) ? options.map((data) => data.value) : [],
                });
              }}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label">{t("User")}</Label>
            <Select
              placeholder={t("Select...")}
              options={userOptions}
              value={userOptions.filter((options: any) =>
                filters?.internal_user_id?.includes(options.value)
              )}
              isMulti
              isSearchable={true}
              isDisabled={loadingUserOptions}
              isLoading={loadingUserOptions}
              onChange={(options: any) => {
                onChange({
                  is_filtered: true,
                  internal_user_id: Array.isArray(options) ? options.map((data) => data.value) : [],
                });
              }}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label">{t("Task type")}</Label>
            <Select
              id="type"
              name="type"
              className="is-invalid"
              placeholder={t("Select...")}
              options={typeOption}
              value={typeOption.filter((options: any) => filters?.type?.includes(options.value))}
              isMulti
              isSearchable={true}
              onChange={(options: any) => {
                onChange({
                  is_filtered: true,
                  type: Array.isArray(options) ? options.map((data) => data.value) : [],
                });
              }}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label">{t("Task status")}</Label>
            <Select
              id="status"
              name="status"
              className="is-invalid"
              placeholder={t("Select...")}
              options={taskStatusOption}
              value={taskStatusOption.filter((options: any) =>
                filters?.status?.includes(options.value)
              )}
              isMulti
              isSearchable={true}
              onChange={(options: any) => {
                onChange({
                  is_filtered: true,
                  status: Array.isArray(options) ? options.map((data) => data.value) : [],
                });
              }}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label">{t("Start date")}</Label>
            <Flatpickr
              id="start_date"
              name="start_date"
              className="form-control d-block"
              type="text"
              value={filters.start_date}
              placeholder="DD/MM/YYYY"
              options={{
                altInput: true,
                altFormat: "d/m/Y",
                dateFormat: "Y-m-d H:i:S",
                allowInput: true,
                onChange: function (selectedDates, dateStr, instance) {
                  onChange({ is_filtered: true, start_date: dateStr });
                },
                onClose: function (selectedDates, dateStr, instance) {
                  onChange({ is_filtered: true, start_date: dateStr });
                },
                locale: { ...datePickerI18(), firstDayOfWeek: 1 },
              }}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label">{t("Internal due date")}</Label>
            <Flatpickr
              id="due_date"
              name="due_date"
              className="form-control d-block"
              type="text"
              value={filters.due_date}
              placeholder="DD/MM/YYYY"
              options={{
                altInput: true,
                altFormat: "d/m/Y",
                dateFormat: "Y-m-d H:i:S",
                allowInput: true,
                onChange: function (selectedDates, dateStr, instance) {
                  onChange({ is_filtered: true, due_date: dateStr });
                },
                onClose: function (selectedDates, dateStr, instance) {
                  onChange({ is_filtered: true, due_date: dateStr });
                },
                locale: { ...datePickerI18(), firstDayOfWeek: 1 },
              }}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label">{t("External due date")}</Label>
            <Flatpickr
              id="closing_date"
              name="closing_date"
              className="form-control d-block"
              type="text"
              value={filters.closing_date}
              placeholder="DD/MM/YYYY"
              options={{
                altInput: true,
                altFormat: "d/m/Y",
                dateFormat: "Y-m-d H:i:S",
                allowInput: true,
                onChange: function (selectedDates, dateStr, instance) {
                  onChange({ is_filtered: true, closing_date: dateStr });
                },
                onClose: function (selectedDates, dateStr, instance) {
                  onChange({ is_filtered: true, closing_date: dateStr });
                },
                locale: { ...datePickerI18(), firstDayOfWeek: 1 },
              }}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label">{t("Created from")}</Label>
            <Flatpickr
              id="created_at_from"
              name="created_at_from"
              className="form-control d-block"
              type="text"
              value={filters.created_at_from}
              placeholder="DD/MM/YYYY"
              options={{
                altInput: true,
                altFormat: "d/m/Y",
                dateFormat: "Y-m-d H:i:S",
                allowInput: true,
                onChange: function (selectedDates, dateStr, instance) {
                  onChange({ is_filtered: true, created_at_from: dateStr });
                },
                onClose: function (selectedDates, dateStr, instance) {
                  onChange({ is_filtered: true, created_at_from: dateStr });
                },
                locale: { ...datePickerI18(), firstDayOfWeek: 1 },
              }}
            />
          </div>
        </Col>

        <Col>
          <div className="mb-3">
            <Label className="form-label">{t("Created to")}</Label>
            <Flatpickr
              id="created_at_to"
              name="created_at_to"
              className="form-control d-block"
              type="text"
              value={filters.created_at_to}
              placeholder="DD/MM/YYYY"
              options={{
                altInput: true,
                altFormat: "d/m/Y",
                dateFormat: "Y-m-d H:i:S",
                allowInput: true,
                onChange: function (selectedDates, dateStr, instance) {
                  onChange({ is_filtered: true, created_at_to: dateStr });
                },
                onClose: function (selectedDates, dateStr, instance) {
                  onChange({ is_filtered: true, created_at_to: dateStr });
                },
                locale: { ...datePickerI18(), firstDayOfWeek: 1 },
              }}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Filters;
