import { takeEvery, call, put } from "redux-saga/effects";
import companyService from "../../../services/masters.company.service";
import { AnyAction } from "redux";

// Company Redux States
import {
  GET_COMPANY,
  GET_COMPANY_LIST,
  CREATE_COMPANY,
  UPDATE_COMPANY,
  DELETE_COMPANY,
  GET_COMPANY_GENERAL,
} from "./actionTypes";

import { 
    getCompanySuccess, 
    getCompanyError,
    
    getCompanyListSuccess,
    getCompanyListError,

    createCompanySuccess,
    createCompanyError,

    updateCompanySuccess,
    updateCompanyError,

    deleteCompanySuccess,
    deleteCompanyError,

    getCompanyGeneralSuccess,
    getCompanyGeneralError,
} from "./actions";
import compnayGeneralService from "../../../services/compnay.general.service";

function* getCompany({ payload: { company, history } }: AnyAction): any {
  try {
    const response = yield call(companyService.getCompany, company);
    if (response.data && response.data.status === "success") {
      yield put(getCompanySuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getCompanyError(response.data));
    } else {
      yield put(getCompanyError(response));
    }
  } catch (error: any) {
    yield put(getCompanyError(error));
  }
}

function* getCompanyList({ payload: { query, history } }: AnyAction): any {
  try {
    const response = yield call(companyService.getCompanyList, query);
    if (response.data && response.data.status === "success") {
      yield put(getCompanyListSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getCompanyListError(response.data));
    } else {
      yield put(getCompanyListError(response));
    }
  } catch (error: any) {
    yield put(getCompanyListError(error));
  }
}

function* createCompany({ payload: { company, history } }: AnyAction): any {
  try {
    const response = yield call(companyService.createCompany, company);
    if (response.data && response.data.status === "success") {
      yield put(createCompanySuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(createCompanyError(response.data));
    } else {
      yield put(createCompanyError(response));
    }
  } catch (error: any) {
    yield put(createCompanyError(error));
  }
}

function* updateCompany({ payload: { company, history } }: AnyAction): any {
  try {
    const response = yield call(companyService.updateCompany, company);
    if (response.data && response.data.status === "success") {
      yield put(updateCompanySuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(updateCompanyError(response.data));
    } else {
      yield put(updateCompanyError(response));
    }
  } catch (error: any) {
    yield put(updateCompanyError(error));
  }
}

function* deleteCompany({ payload: { company, history } }: AnyAction): any {
  try {
    const response = yield call(companyService.deleteCompany, company);
    if (response.data && response.data.status === "success") {
      yield put(deleteCompanySuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(deleteCompanyError(response.data));
    } else {
      yield put(deleteCompanyError(response));
    }
  } catch (error: any) {
    yield put(deleteCompanyError(error));
  }
}


function* getCompanyGeneral({ payload: { id } }: AnyAction): any {
  try {
    const response = yield call( compnayGeneralService.getCompanyGeneral, id);
    if (response && response.data && response.data.status === "success") {
      yield put(getCompanyGeneralSuccess(response.data));
    } else if (response && response.data && response.data.status === "error") {
      yield put(getCompanyGeneralError(response.data));
    } else {
      yield put(getCompanyGeneralError(response));
    }
  } catch (error: any) {
    yield put(getCompanyGeneralError(error));
  }
}

function* companyFunctionSaga(): any {
  yield takeEvery(GET_COMPANY, getCompany);
  yield takeEvery(GET_COMPANY_LIST, getCompanyList);
  yield takeEvery(CREATE_COMPANY, createCompany);
  yield takeEvery(UPDATE_COMPANY, updateCompany);
  yield takeEvery(DELETE_COMPANY, deleteCompany);
  yield takeEvery(GET_COMPANY_GENERAL, getCompanyGeneral);
}

export default companyFunctionSaga;
