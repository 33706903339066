import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import Avatar from "../../Avatar";
import { getUserProfile, getInitials } from "../../../helpers/functions_helper";
import user from "../../../assets/images/users/avatar-1.png";
import AuthService from "../../../services/auth.service";

import { useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import { useTranslation } from "react-i18next";

const ProfileMenu = (props: any) => {
  const { t } = useTranslation();
  const userProfile = getUserProfile('all-users');
  const { instance } = useMsal();
  const isAzureAuthenticated = useIsAuthenticated();

  const [menuOpen, setMenuOpen] = useState(false);
  const toggle = React.useCallback(() => {
    setMenuOpen((menuOpen) => !menuOpen);
  }, []);

  const logout = () => {
    if (isAzureAuthenticated === true) {
      instance.logoutRedirect().catch((e) => {
        console.error(e);
      });
    }

    AuthService.logout();
  };

  return (
    <React.Fragment>
      <Dropdown isOpen={menuOpen} toggle={toggle} className="d-inline-block">
        <DropdownToggle className="btn header-item " id="page-header-user-dropdown" tag="button">
          <img
            className="rounded-circle header-profile-user"
            src={userProfile.USER_PROFILE_IMAGE ? userProfile.USER_PROFILE_IMAGE : user}
            alt={getInitials(`${userProfile.USER_NAME} ${userProfile.USER_LAST_NAME}`)}
          />

          <span className="d-none d-xl-inline-block ms-2 me-1">{userProfile.USER_NAME}</span>
          <span className="align-middle material-symbols-outlined fw-light fs-4 d-none d-xl-inline-block">expand_more</span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {(userProfile.USER_TYPE !== 3 && userProfile?.CAN_VIEW) && (
            <>
              <Link
                to={"/users/user-details/" + userProfile.USER_ID + "/" + userProfile.USER_SUB_ID}
                className="dropdown-item"
                onClick={toggle}>
                <span className="text-danger align-middle me-2 material-symbols-outlined fw-light fs-4">account_circle</span>
                <span>{t("Profile")}</span>
              </Link>

              <div className="dropdown-divider" />
            </>
          )}

          <Link to="#" className="dropdown-item" onClick={logout}>
            <span className="text-danger align-middle me-2 material-symbols-outlined fw-light fs-4">logout</span>
            <span>{t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileMenu;
