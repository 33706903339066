import React from "react";
import { Nav, NavItem } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getUserProfile } from "../../helpers/functions_helper";

const NavItems = (props: any) => {
  const { t } = useTranslation();
  const userProfile = getUserProfile("all-tasks");

  return (
    <React.Fragment>
      <Nav tabs>
        <NavItem>
          <Link
            style={{ cursor: "pointer" }}
            to={`/tasks/task-details/${props.id}/${props.task_id}`}
            className={`${
              window.location.pathname === "/tasks/task-details/" + props.id + "/" + props.task_id
                ? "active"
                : ""
            }  nav-link`}
          >
            {t("Task details")}
          </Link>
        </NavItem>

        {!userProfile.IS_INDIVIDUAL && (
          <NavItem>
            <Link
              style={{ cursor: props?.subtask_count > 0 ? "pointer" : "" }}
              to={props?.subtask_count > 0 ? `/tasks/subtasks/${props.id}/${props.task_id}` : "#"}
              className={`${
                window.location.pathname === "/tasks/subtasks/" + props.id + "/" + props.task_id
                  ? "active"
                  : ""
              }  nav-link`}
            >
              {t("Subtask")}
              <span className="badge rounded-pill bg-primary ms-1" title={t("Total subtask")}>
                {props?.subtask_count}
              </span>
            </Link>
          </NavItem>
        )}

        {userProfile.USER_COMPANY === 1 && (
          <NavItem>
            <Link
              style={{ cursor: "pointer" }}
              to={`/tasks/timesheet/${props.id}/${props.task_id}`}
              className={`${
                window.location.pathname === "/tasks/timesheet/" + props.id + "/" + props.task_id
                  ? "active"
                  : ""
              }  nav-link`}
            >
              {t("Time sheet")}
            </Link>
          </NavItem>
        )}

        {userProfile.USER_COMPANY === 1 && userProfile.IS_PARTNER && (
          <NavItem>
            <Link
              style={{ cursor: props?.invoice_count > 0 ? "pointer" : "" }}
              to={props?.invoice_count > 0 ? `/tasks/invoices/${props.id}/${props.task_id}` : "#"}
              className={`${
                window.location.pathname === "/tasks/invoices/" + props.id + "/" + props.task_id
                  ? "active"
                  : ""
              }  nav-link`}
            >
              {t("Invoices")}
              <span className="badge rounded-pill bg-secondary ms-1" title={t("Total invoices")}>
                {props?.invoice_count}
              </span>
            </Link>
          </NavItem>
        )}
      </Nav>
    </React.Fragment>
  );
};

export default NavItems;
