import styled, { css } from 'styled-components';

export const Status = styled.div`
  text-transform: uppercase;
  transition: all 0.1s;
  ${props =>
    props.isValue &&
    css`
      padding: 0 12px;
      height: 32px;
      &:hover {
        transform: scale(1.05);
      }
    `}
`;

export const More = styled.div`
  text-transform: uppercase;
  height: 1.6rem;
  width: 1.6rem;
  margin-left:-12px;
  `;
