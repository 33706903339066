import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { noData } from '../../../utils/data';
import { serialize } from '../../../utils/utils';
import { getUserProfile } from '../../../helpers/functions_helper';
import gfilters from "../filter";
import LoadingSpinner from '../../../components/Common/Loader';
import taskService from '../../../services/tasks.service';
import GraphFilters from '../../../components/Chart/Filters';
import Chart from "../../../components/Chart";
import { currentMonth, currentYear } from '../../../common/staticdata';
interface TaskOriginatedProps extends CartProps {
    filters?: any;
}

const TaskOriginated: FC<TaskOriginatedProps> = ({ hideHeading, filters = null }) => {

    const { t } = useTranslation();
    const noTranData = { ...noData, 'text': t(noData.text) }
    const userObj = getUserProfile('all-tasks');
    const navigate = useNavigate();
    const [selectedID, setSelectedID] = useState<any>({ id: null, event: null });
    const inititalFilter = {
        week: null,
        quarter: null,
        month: currentMonth,
        year: currentYear,
        user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_ID : null,
        user_name: null
    };

    const viewTaskList = (i: any) => {
        if (data.ids) {
            const x = data.ids[i];
            setSelectedID({ id: null, event: null });
            if (x !== -1) {
                let _filter = {
                    type: 'fees',
                    id: x,
                    label: 'taskOriginated'
                }
                gfilters.setFilter(_filter);
                navigate('../tasks/all-tasks');
            }
        }
    }

    useEffect(() => {
        if (selectedID?.event === 'click') {
            viewTaskList(selectedID.id);
        }
    }, [selectedID])

    const [loading, setLoading] = useState<boolean>(true);
    const [query, setQuery] = useState<string | null>(serialize(inititalFilter));
    const [filter, setFilter] = useState<any>(inititalFilter);

    const [data, setData] = useState<any>({
        series: [{
            name: t('Total'),
            data: []
        }],
        ids: [],
        type: [],
        options: {
            labels: [],
            legend: {
                "show": true,
                "offsetY": 10
            },
            stroke: {
                width: 0,
            },
            yaxis: {
                title: {
                    text: t('Total task')
                }
            },
            tooltip: {
                custom: function (opts: any) {
                    const type = opts?.w?.config?.series[0]?.description[opts?.dataPointIndex];
                    const count = opts?.series[0][opts?.dataPointIndex];
                    const code_name = opts?.w?.config?.labels[opts?.dataPointIndex];
                    const bgcolor = opts?.w?.globals?.colors[opts?.dataPointIndex];
                    return `<div class="mt-2 mt-sm-0 p-1">
                    <p class="text-muted mb-2">${type}<span class="badge ms-1 align-bottom" style="background-color:${bgcolor}">${code_name}</span></p>
                    <h6>${t('Total task')}: ${count}</h6>
                    </div>`;
                },
            },
            plotOptions: {
                bar: {
                    barHeight: '100%',
                    columnWidth: '100%',
                    distributed: true,
                    horizontal: false,
                    dataLabels: {
                        orientation: 'vertical',
                        position: 'center' // bottom/center/top
                    }
                },
            },
            // dataLabels: {
            //     enabled: true,
            //     textAnchor: "center",
            //     formatter: function (_val:any, opt:any) {
            //       return opt.w.config.series[0].data[opt.dataPointIndex];
            //     },
            //     style: {
            //       colors: ["#000"]
            //     }
            //   },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        show: true,
                        position: 'left'
                    }
                }
            }],
            noData: noTranData,
            chart: {
                "height": 350,
                "type": "bar",
                "stacked": false,
                "toolbar": {
                    "show": true
                },
                events: {
                    click: (event: any, chartContext: any, config: any) => {
                        //setSelectedID({ id: config.dataPointIndex, event: 'click' });
                    }
                }
            }
        },

    })

    const getChartData = async () => {
        try {
            const result = await taskService.getOriginatedTaskTypeCount(query);
            if (result.data.status === 'success') {
                setData((preState: any) => ({
                    ...preState,
                    series: [{
                        data: result?.data?.data[0]?.data || [0],
                        name: t('Total'),
                        description: result?.data?.data[0]?.type || [0]
                    }],
                    ids: result?.data?.data[0]?.ids || [],
                    options: {
                        ...data.options,
                        labels: result?.data?.data[0]?.label || [0],
                        xaxis: { categories: result?.data?.data[0]?.label || [0], labels: { show: true } },
                    },
                }))
            }
            setLoading(false);
        } catch (error: any) {
            toast(error)
        }
    }
    const clearWorkAllocationFilter = () => {
        setFilter(inititalFilter);
    }

    useEffect(() => {
        loading && getChartData();
    }, [loading, query])

    useEffect(() => {
        setQuery(serialize(filter));
        setLoading(true);
    }, [filter])

    //Global Filter
    useEffect(() => {
        filters && setFilter(filters);
    }, [filters])

    useEffect(() => {
        setLoading(true);
    }, [t])


    //Graph Data 
    const loadGraphData = async (filter: any) => {
        if (filter) {
            const promises = filter.map(async (query: any) => {
                const q = serialize(query);
                const graphData = await taskService.getOriginatedTaskTypeCount(q)
                return graphData
            })
            const graphData = await Promise.all(promises);
            const graphSeries: any = [];
            let label: any = [];
            let typesd: any = [];
            graphData.map((item: any, i) => {
                if (item?.data?.status === 'success') {
                    graphSeries.push({
                        data: item?.data?.data[0].data,
                        type: i === 0 ? 'column' : 'line',
                        name: `Filter-${i + 1}`,
                        description: item?.data?.data[0]?.type
                    })

                    if (label.length < item?.data?.data[0].label?.length) {
                        label = item?.data?.data[0]?.label;
                    }

                    if (typesd.length < item?.data?.data[0]?.type?.length) {
                        typesd = item?.data?.data[0]?.type;
                    }


                }
            })
            setLoading(false)
            setData((preState: any) => ({
                ...preState,
                series: graphSeries || [0],
                //ids: result?.data?.data[0].ids || [],
                options: {
                    ...data.options,
                    labels: label || [0],
                    //xaxis: { categories: label || [0], labels: { show: true } },
                    xaxis: { categories: label || [0], type: "category" },
                    stroke: {
                        curve: "smooth",
                        width: 2,
                    },
                    chart: {
                        ...preState?.options?.chart,
                        type: graphSeries?.length > 1 ? 'line' : 'bar'
                    }
                },
            }))

        }

    }


    return (
        <>
            <div className="d-sm-flex flex-wrap">
                <h4 className="card-title mb-4">{!hideHeading && t("Task originated from Task types")}</h4>&nbsp;
                <div className="ms-auto">
                    <GraphFilters
                        filter={filter}
                        setFilter={setFilter}
                        clearFilter={clearWorkAllocationFilter}
                        enableUser={true}
                        enableCompany={true}
                        slug='all-tasks'
                    />
                </div>
            </div>
            <div className="clearfix"></div>
            {loading ? <LoadingSpinner height={380} /> : <div style={{ height: '100%' }}>
                <Chart options={data.options} series={data.series} type="bar" height={350} />
            </div>}
        </>
    )
}

export default TaskOriginated;