import {memo} from 'react';

const UserOptions = (props: any) => {
    const { innerProps, innerRef } = props
    if (props.data.hasOwnProperty('__isNew__')) {
        return (
            <div className="d-flex p-1" ref={innerRef} {...innerProps}>
                <div className="flex-grow-1 chat-user-box">
                    <p className="user-title m-0">{props.data.label}</p>
                </div>
            </div>
        );
    }
    else
        return (
            <div className="d-flex p-1" ref={innerRef} {...innerProps}>
                <div className="d-flex me-1 avatar-xs">
                    <span className="avatar-title rounded-circle">{props.data.label.substring(0, 1)}</span>
                </div>
                <div className="flex-grow-1 chat-user-box">
                    <p className="user-title m-0">{props.data.label}</p>
                    <p className="text-muted">{props.data.value}</p>
                </div>
            </div>
        );
};

export default memo(UserOptions);