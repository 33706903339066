export const templateData = {
  "type": "page",
  "data": {
    "value": {
      "breakpoint": "480px",
      "headAttributes": "",
      "font-size": "14px",
      "font-weight": "400",
      "line-height": "1.7",
      "headStyles": [],
      "fonts": [],
      "responsive": true,
      "font-family": "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans','Helvetica Neue', sans-serif",
      "text-color": "#000000",
      "content-background-color": "#ffffff"
    }
  },
  "attributes": {
    "background-color": "#efeeea",
    "width": "600px"
  },
  "children": [
    {
      "type": "advanced_wrapper",
      "data": {
        "value": {}
      },
      "attributes": {
        "padding": "20px 0px 20px 0px",
        "border": "none",
        "direction": "ltr",
        "text-align": "center"
      },
      "children": []
    },
    {
      "type": "advanced_section",
      "data": {
        "value": {
          "noWrap": false
        }
      },
      "attributes": {
        "padding": "20px 0px 20px 0px",
        "background-repeat": "repeat",
        "background-size": "auto",
        "background-position": "top center",
        "border": "none",
        "direction": "ltr",
        "text-align": "center"
      },
      "children": [
        {
          "type": "advanced_column",
          "data": {
            "value": {}
          },
          "attributes": {
            "padding": "0px 0px 0px 0px",
            "border": "none",
            "vertical-align": "top"
          },
          "children": [
            {
              "type": "advanced_text",
              "data": {
                "value": {
                  "content": "We don't like to say goodbye, but if you want to leave us you can <a href=\"{{localhost}}\" target=\"_blank\" style=\"color: inherit; text-decoration: underline;\">unsubscribe here</a>"
                }
              },
              "attributes": {
                "padding": "0px 25px 0px 25px",
                "align": "center",
                "font-weight": "normal"
              },
              "children": []
            }
          ]
        }
      ]
    }
  ]
}