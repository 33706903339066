import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import {
  Button,
  Col,
  Form,
  Label,
  Row,
  Container,
  Card,
  CardBody,
  FormFeedback,
  CardFooter,
  ModalFooter,
  ModalBody,
  Modal
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
// Import Breadcrumb
import Breadcrumbs from "../../../components/Layout/Breadcrumb";
import { useTranslation } from "react-i18next";
import ScrollUp from "../../../components/ScrollUpDown/ScrollUp";
import ScrollDown from "../../../components/ScrollUpDown/ScrollDown";
import {getSelectOptions } from "../../../helpers/functions_helper";
import { toast } from "react-toastify";
import documentTemplateService from "../../../services/document.template.service";
import "./style.scss";
import Drawer from "../../../components/Drawer";
import Dropzone from "../../../components/Common/Dropzone";
import { splitHTMLByPageBreak } from "../../../components/Common/Pdftotext";
import Loader from "../../../components/Common/Loader";
import MyPDFContent from "../DocSignature/MyPDFContent";
import DocEdit from "./DocEdit";
import { prepareAssets } from "./utils/prepareAssets";
import Select from "../../../components/Form/Select/Select";
import Input from "../../../components/Form/Input";
import TextEditor from "../../../components/Form/TextEditor";
const TemplateForm = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  let { id, template_id } = useParams();
  let breadcrumb = t("Create template");
  let btnName = t("Create");
  let moduleHeading = t("Create document template");
  if (id && template_id) {
    document.title = t("Update template") + "| ETC 3.0";
    breadcrumb = "update template";
    btnName = t("Update");
    moduleHeading = t("Update document template");
  }

  document.title = `${moduleHeading} | ETC 3.0`;
  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "/client-onboarding/template/list",
      label: t("All templates"),
      active: false,
    },
    {
      url: "#",
      label: breadcrumb,
      active: true,
    },
  ];

  const toastIdTemp = useRef(null) as any;
  const [loading, setLoading] = useState(false);
  const [documentTypeOptions, setDocumentTypeOptions] = useState([]);
  const [pDFView, setPDFView] = useState(false);
  const [attachmentView, setAttachmentView] = useState(false);
  const [insertId, setInsertId] = useState<any>(0);
  const [insertSubId, setInsertSubId] = useState("");
  const [attachmentData, setAttachmentData] = useState([]);
  const [multiUploadStatus, setMultiUploadStatus] = useState(false);
  const [pdfData, setPdfData] = useState<any>("");
  const [templateData, setTemplateData] = useState<any>([]);
  const [showcontent, setShowcontent] = useState(false);
  const [content, setContent] = useState("");
  const [intialData, setIntialData] = useState({
    id: id ? id : "",
    type: "",
    doc_type: 1,
    toggle: false,
    document_name: "",
    description: "",
  });


  const validationSchema = Yup.object().shape({
    type: Yup.string().required("Type of document field is required."),
    document_name: Yup.string().required(
      "Document name field is required."
    ),
    description: Yup.string().when(["toggle"], {
      is: (toggle: boolean) => toggle === false,
      then: Yup.string().required("Description field is required."),
    }),
  });

  const onClickSwitch = () => {
    handleChange('toggle', !intialData.toggle);
  };

  useEffect(() => {
    prepareAssets();
  }, [])


  useEffect(() => {
    if (id && template_id) {
      getData(id, template_id);
      setInsertId(id);
      setInsertSubId(template_id);
    }
  }, [id, template_id])



  const getData = async (id: any, template_id: any) => {
    setLoading(true);
    const res = (await documentTemplateService.get(id, template_id)) as any;
    if (res.status === "success") {
      updateIntialData(res.data);
      setTemplateData(res.data);
      setLoading(false);
      // setInsertId(id);
      // setInsertIdUpdate(id);
      // setInsertSubId(template_id);
    }
  }

  const updateIntialData = (docuData: any) => {
    setIntialData((preState) => ({
      ...preState,
      id: (docuData && docuData.id) || "",
      type: (docuData && docuData.type) || "",
      toggle: (docuData && docuData.toggle == 1 ? true : false) || false,
      doc_type: (docuData && docuData.doc_type) || 1,
      document_name: (docuData && docuData.document_name) || "",
      description: (docuData && docuData.description) || "",
    }));
  }

  const CKinputHandler = (event: any, editor: any) => {
    validation.setFieldValue("description", editor.getData());
    //setIntialData({ ...intialData, description: editor.getData() });
  };

  const handleChange = (fieldName: string, fieldValue: any) => {
    setIntialData({ ...intialData, [fieldName]: fieldValue });
  };

  const multiUploadSuccess = (isSuccess: boolean) => {
    if (isSuccess === true) {
      setMultiUploadStatus(true);
    }
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: intialData,
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      handleSubmit(values);
    },
  });


  const handleSubmit = (values: any) => {
    setLoading(true);
    if (values && values.id) {
      update(values);
    } else {
      create(values);
    }
  };


  const create = async (values: any) => {
    try {
      toastIdTemp.current = toast(t("Creating template..."), {
        autoClose: false,
        isLoading: true,
      });

      const res = (await documentTemplateService.create(values)) as any;
      if (res.status === "success") {
        toast.update(toastIdTemp.current, {
          type: toast.TYPE.SUCCESS,
          render: res.message,
          autoClose: 5000,
          isLoading: false,
        });
        setLoading(false);
        setInsertId(res.data.id);
        setInsertSubId(res.data.template_id);
        if (!intialData.toggle) {
          return navigate("/client-onboarding/template/list");
        }
      } else {
        toast.update(toastIdTemp.current, {
          type: toast.TYPE.ERROR,
          render: res.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        if (res.data.data) {
          Object.keys(res.data.data).map((key) => {
            toast(res.data.data[key][0], {
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              delay: 500,
            });
          });
        }
        setLoading(false);
      }
    } catch (err: any) {
      toast.update(toastIdTemp.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }
  };


  useEffect(() => {
    if (multiUploadStatus) {
      handleUploadDocument();
      return navigate("/client-onboarding/template/list");
    }
  }, [multiUploadStatus])


  const update = async (values: any) => {
    try {
      toastIdTemp.current = toast(t("Updating template..."), {
        autoClose: false,
        isLoading: true,
      });

      const res = (await documentTemplateService.update(values)) as any;
      if (res.status === "success") {
        toast.update(toastIdTemp.current, {
          type: toast.TYPE.SUCCESS,
          render: res.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoading(false);
        return navigate("/client-onboarding/template/list");
      } else {
        toast.update(toastIdTemp.current, {
          type: toast.TYPE.ERROR,
          render: res.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        if (res.data.data) {
          Object.keys(res.data.data).map((key) => {
            toast(res.data.data[key][0], {
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              delay: 500,
            });
          });
        }
        setLoading(false);
      }
    } catch (err: any) {
      toast.update(toastIdTemp.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getSelectOptions(
      'tbl_master_document_types',
      'document_type',
    ).then((options: any) => setDocumentTypeOptions(options))
  }, [])

  const handleUploadDocument = async () => {
    const res = (await documentTemplateService.convertTopdf({ id: insertId, template_id: insertSubId })) as any;

    // const SERVER_URL = process.env.REACT_APP_SERVER_URL;
    // const AUTH_USER = localStorage.getItem("authUser");
    // const ACCESS_TOKEN = JSON.parse(String(AUTH_USER));
    // axios({
    //   url:
    //     SERVER_URL +
    //     "/common/download-attachment/" +
    //     Buffer.from(String(files[0].id)).toString("base64") +
    //     "/" +
    //     Buffer.from(String(files[0].attachment_id)).toString("base64"),
    //   method: "GET",
    //   headers: {
    //     "Content-Type": "application/" + files[0].file_extension,
    //     "Access-Control-Allow-Origin": "*",
    //     Authorization: "Bearer " + ACCESS_TOKEN.accessToken,
    //   },
    //   responseType: "arraybuffer",
    // }).then((response: any) => {
    //   if (files[0].file_extension == "doc" || files[0].file_extension == "docx") {
    //     convertWordtoPdf(files);
    //   }
    // });
  }


  const convertWordtoPdf = async (file: any) => {

  }



  useEffect(() => {
    if (validation.values.description && pDFView) {
      splitHTMLByPageBreak(validation.values.description).then((result: any) => {
        setPdfData(result);
      });
    }
  }, [pDFView])


  const getContents = async () => {
    try {
      toastIdTemp.current = toast(t("Getting contents..."), {
        autoClose: false,
        isLoading: true,
      });

      const res = (await documentTemplateService.getContents(id, template_id)) as any;
      if (res.status === "success") {
        setShowcontent(true);
        console.log(res.data);
        setContent(res.data);
        toast.update(toastIdTemp.current, {
          type: toast.TYPE.SUCCESS,
          render: t("Content retrived successfully from file."),
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
      } else {
        toast.update(toastIdTemp.current, {
          type: toast.TYPE.ERROR,
          render: res.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });

        if (res.data.data) {
          Object.keys(res.data.data).map((key) => {
            toast(res.data.data[key][0], {
              type: toast.TYPE.ERROR,
              autoClose: 5000,
              delay: 500,
            });
          });
        }
        setLoading(false);
      }
    } catch (err: any) {
      toast.update(toastIdTemp.current, {
        type: toast.TYPE.ERROR,
        render: err?.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }
  }


  const useContent = async () => {
    handleChange("description", content);
    setIntialData((preState) => ({
      ...preState,
      toggle: false,
    }));
    setShowcontent(false);
  }

  return (
    <React.Fragment>
      <ScrollUp />
      <ScrollDown top={"100px"} />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={moduleHeading}
            breadcrumbItems={breadcrumbItems}
          ></Breadcrumbs>
          <Row>
            <Col lg="12">
              <Card>
                <Form
                  onSubmit={(e: any) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <CardBody>
                    <Row>
                      <Col xl={3} lg={3} md={3} sm={12}>
                        <div className="mb-3">
                          <Select
                            id="type"
                            name="type"
                            placeholder="Select"
                            label="Type of document"
                            required
                            options={documentTypeOptions}
                            value={intialData.type ? documentTypeOptions.find(
                              (o: any) => o.value === intialData.type
                            ) : ""}
                            isSearchable={true}
                            isDisabled={false}
                            onBlur={validation.handleBlur}
                            onChange={(option: any) => {
                              handleChange("type", option.value);
                            }}
                            touched={validation.touched.type}
                            error={validation.errors.type}
                          />
                        </div>
                      </Col>
                      <Col xl={9} lg={9} md={9} sm={12}>
                        <div className="mb-3">
                          <Input
                            id="document_name"
                            name="document_name"
                            label="Document name"
                            required
                            placeholder="Type"
                            type="text"
                            value={intialData.document_name}
                            onBlur={validation.handleBlur}
                            onChange={(e: any) =>
                              handleChange("document_name", e.target.value)
                            }
                            touched={validation.touched.document_name}
                            error={validation.errors.document_name}
                          />

                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={8} lg={8} md={8} sm={12} className="mb-1">
                        <Button
                          outline={true}
                          color="primary"
                          size="sm"
                          className="btn-label mt-1 mb-1"
                          title={t("Remove")}
                          onClick={() => onClickSwitch()}
                        >
                          {intialData.toggle === true && (
                            <>
                              <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                toggle_off
                              </span>
                              {t("Switch to contents")}
                            </>
                          )}
                          {intialData.toggle === false && (
                            <>
                              <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                                toggle_on
                              </span>
                              {t("Switch to attachments")}
                            </>
                          )}
                        </Button>
                      </Col>
                      {(!intialData.toggle && intialData.description) &&
                        <Col xl={4} lg={4} md={4} sm={12} className="mb-1">
                          <div className="ms-auto d-flex justify-content-end">
                            <Button
                              color="primary"
                              className="btn-block btn-sm"
                              type="button"
                              disabled={loading}
                              onClick={() => setPDFView(true)}
                            >
                              {t("Document view")}
                            </Button>
                          </div>
                        </Col>
                      }
                      {(intialData.toggle && id) &&
                        <Col xl={4} lg={4} md={4} sm={12} className="mb-1">
                          <div className="ms-auto d-flex justify-content-end">
                            <Button
                              color="primary"
                              className="btn-block btn-sm mx-3"
                              type="button"
                              disabled={loading}
                              onClick={getContents}
                            >
                              {t("Get contents")}
                            </Button>
                            <Button
                              color="primary"
                              className="btn-block btn-sm"
                              type="button"
                              disabled={loading}
                              onClick={() => setAttachmentView(true)}
                            >
                              {t("Edit document")}
                            </Button>
                          </div>
                        </Col>
                      }

                      {/* {(intialData.toggle && id) &&
                        <Col xl={4} lg={4} md={4} sm={12} className="mb-1">
                          <div className="ms-auto d-flex justify-content-end">
                            <Button
                              color="primary"
                              className="btn-block btn-sm"
                              type="button"
                              disabled={loading}
                              onClick={getContents}
                            >
                              {t("Get contents")}
                            </Button>
                          </div>
                        </Col>
                      } */}
                    </Row>
                    {intialData.toggle === true && (
                      <Row>
                        <Col>
                          <div className="mx-1">
                            <Col className="col-12">
                              <div className="mb-3">
                                <Label className="form-label">{t("Attachments")}</Label>
                                {(id && template_id) ?
                                  (
                                    <Dropzone
                                      moduleData={{
                                        module_app_id: 14,
                                        module_main_id: id,
                                        module_sub_id: template_id,
                                        section_id: 1,
                                        file_path: "user_document",
                                      }}
                                      hideList={false}
                                      fetchData={true}
                                      isOpen={true}
                                      enableExpiryDate={false}
                                      onSuccess={multiUploadSuccess}
                                      onUpload={(files: any) => handleUploadDocument()}
                                      setAttachmentData={setAttachmentData}
                                      linkBtn={false}
                                      isDelete={false}
                                      fileTypes={".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/PDF"}
                                    />)
                                  :
                                  (
                                    <Dropzone
                                      moduleData={{
                                        module_app_id: 14,
                                        module_main_id: 0,
                                        module_sub_id: "",
                                        section_id: 1,
                                        file_path: "user_document",
                                      }}
                                      //attachmentForIDs={}
                                      fetchData={false}
                                      isOpen={true}
                                      insertId={insertId}
                                      insertSubId={insertSubId}
                                      onSuccess={multiUploadSuccess}
                                      linkBtn={false}
                                      isEdit={false}
                                      isDelete={false}
                                      fileTypes={".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/PDF"}
                                    />
                                  )}
                              </div>
                            </Col>
                          </div>
                        </Col>
                      </Row>
                    )}
                    {/* <Row>
                      <Col className="col-12">
                        <hr></hr>
                      </Col>
                      <Col className="col-12 text-center"><b>OR</b></Col>
                      <Col className="col-12">
                        <hr></hr>
                      </Col>
                    </Row> */}
                    {intialData.toggle === false && (
                      <Row>
                        <Col xl={12} lg={12} md={12} sm={12}>
                          <div className="is-invalid">
                            <TextEditor
                              value={intialData.description}
                              onChange={CKinputHandler}
                              label="Description"
                              required={intialData.toggle ? false : true}
                              id="description"
                              name="description"
                              className="form-control"
                              onBlur={() => validation.setFieldTouched("description", true)}
                              touched={validation.touched.description}
                              error={validation.errors.description}
                            />
                          </div>
                        </Col>
                      </Row>
                    )}
                    {/* <Row>
                      <Col xl={6} lg={6} md={6} sm={12}>
                        <Label className="form-label mt-3">
                          {" "}
                          {t("Expiry date")}{" "}
                        </Label>
                        <div className="input-group is-invalid">
                          <Flatpickr
                            id="expiry_date"
                            name="expiry_date"
                            className="form-control d-block"
                            type="text"
                            value={intialData.expiry_date}
                            placeholder="DD/MM/YYYY"
                            options={{
                              altInput: true,
                              altFormat: "d/m/Y",
                              dateFormat: "Y-m-d H:i:S",
                              allowInput: true,
                              plugins: [labelPlugin()],
                              onChange: function (
                                selectedDates,
                                dateStr,
                                instance
                              ) {
                                handleChange("expiry_date", dateStr);
                              },
                              onClose: function (
                                selectedDates,
                                dateStr,
                                instance
                              ) {
                                handleChange("expiry_date", dateStr);
                              },
                              locale: { firstDayOfWeek: 1 },
                            }}
                          />
                          <span className="input-group-text">
                            <span className="align-middle material-symbols-outlined fw-light fs-4">
                              calendar_today
                            </span>
                          </span>
                        </div>
                      </Col>
                      <Col xl={6} lg={6} md={6} sm={12}>
                        <Label className="form-label mt-3">
                          {" "}
                          {t("Due date")}{" "}
                        </Label>
                        <div className="input-group is-invalid">
                          <Flatpickr
                            id="due_date"
                            name="due_date"
                            className="form-control d-block"
                            type="text"
                            value={intialData.due_date}
                            placeholder="DD/MM/YYYY"
                            options={{
                              altInput: true,
                              altFormat: "d/m/Y",
                              dateFormat: "Y-m-d H:i:S",
                              minDate: intialData.due_date,
                              allowInput: true,
                              plugins: [labelPlugin()],
                              onChange: function (
                                selectedDates,
                                dateStr,
                                instance
                              ) {
                                handleChange("due_date", dateStr);
                              },
                              onClose: function (
                                selectedDates,
                                dateStr,
                                instance
                              ) {
                                handleChange("due_date", dateStr);
                              },
                              locale: { firstDayOfWeek: 1 },
                            }}
                          />
                          <span className="input-group-text">
                            <span className="align-middle material-symbols-outlined fw-light fs-4">
                              calendar_today
                            </span>
                          </span>
                        </div>
                      </Col>
                    </Row> */}
                  </CardBody>
                  <CardFooter className="d-sm-flex flex-wrap bg-transparent border-top">
                    <div className="ms-auto d-flex gap-2 justify-content-end">
                      <Button
                        color="primary"
                        className="btn-block"
                        type="submit"
                        disabled={loading}
                      >
                        {btnName}
                      </Button>
                      <Link
                        className="btn btn-block btn-danger"
                        to={"/client-onboarding/template/list"}
                      >
                        {t("Cancel")}
                      </Link>
                    </div>
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {pDFView &&
        <Drawer
          title={t("PDF view")}
          open={pDFView}
          onClose={() => setPDFView(false)}
          fullWidth
        >
          {pdfData ?
            <MyPDFContent content={pdfData} />
            :
            <Loader />
          }
        </Drawer>
      }

      {
        attachmentView &&
        <Drawer
          title={t("Edit Dcoument")}
          open={attachmentView}
          onClose={() => setAttachmentView(false)}
          fullWidth
        >
          {templateData ?
            <DocEdit id={id} template_id={template_id} section_id={2} templateData={templateData} attachmentData={[]} type="template" template={true} popup={true} />
            :
            <Loader />
          }
        </Drawer>
      }


      {
        showcontent &&
        <Modal size="lg" isOpen={showcontent} toggle={() => setShowcontent(!showcontent)} centered={true}>
          <div className="modal-header">
            <h5 className="modal-title">
              {t("Contents")}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => setShowcontent(!showcontent)}
              aria-label="Close"
            ></button>
          </div>
          <ModalBody>
            <div className="mb-3">
              <Input
                type='textarea'
                id={`content`}
                name='content'
                placeholder={t('Content')}
                readOnly={true}
                value={content}
                style={{ minHeight: "300px" }}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-primary btn-md ms-2"
              disabled={content === ""}
              onClick={useContent}
            >
              {t("Use content")}
            </button>

          </ModalFooter>
        </Modal >

      }
    </React.Fragment>
  );
};
export default TemplateForm;

