import React, { FC, useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label, Row, Table } from 'reactstrap';
import Button from '../../components/Button/Button';
import Alert from '../../components/Common/Alert';
import LoadingSpinner from '../../components/Common/Loader';
import DatePicker from '../../components/Form/DatePicker/DatePicker';
import Input from '../../components/Form/Input';

type PreliminaryFormProps = {
    initialData: any;
    handleChange: any;
    setInitialData: any;
}

const PreliminaryForm: FC<PreliminaryFormProps> = ({ initialData, handleChange, setInitialData }) => {
    const { t } = useTranslation();

    const emptyRow = { is_preliminary_fixed_amount: true, amount: null, date: "", status: 0 };

    const [rows, setRows] = useState(initialData?.preliminary_details ? initialData?.preliminary_details : [emptyRow]);
    const [remainingBudget, setRemainingBudget] = useState<number>(initialData?.budget_amount);
    const [openAlert, setOpenAlert] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);

    const handleAddMore = () => {
        setRows((row: any) => [...row, emptyRow])
        handleChange('preliminary_details', [...initialData?.preliminary_details, emptyRow])
    }

    const handleDelete = (i: number) => {
        setRows((row: any) => row.filter((a: any, x: number) => x != i))
        handleChange('preliminary_details', initialData?.preliminary_details.filter((a: any, x: number) => x != i))
    }

    useEffect(() => {
        // console.log("initialData.preliminary_details", initialData.preliminary_details);
        handleChange('preliminary_details', initialData?.preliminary_details ? initialData?.preliminary_details : [emptyRow])
        setLoading(false)
    }, [])

    useEffect(() => {
        // console.log("initialData.preliminary_details", initialData.preliminary_details)
        if (initialData?.preliminary_details) {
            if (initialData?.is_budget) {
                const pm_amt = initialData.is_preliminary_fixed_amount === 1 ? initialData?.preliminary_percentage : (initialData.budget_amount * initialData?.preliminary_percentage) / 100;
                const budget_amount = initialData?.is_budget ? initialData?.budget_amount : 1000000000000000000000;
                // alert(pm_amt);
                const amount = initialData['preliminary_details'].reduce((tot: number, acc: any) => tot + (acc?.is_preliminary_fixed_amount ? parseFloat(acc?.amount) : ((initialData.budget_amount * acc?.amount) / 100)), 0);
                setRemainingBudget(initialData?.budget_amount - (parseFloat(pm_amt) + parseFloat(amount)));
            } else {
                setRemainingBudget(1);
            }
        }
    }, [initialData?.preliminary_details])

    useEffect(() => {
        if (remainingBudget < 0) {
            setOpenAlert(true)
        }
    }, [remainingBudget])

    return (
        loading ? <LoadingSpinner /> :
            <div>
                <Alert open={openAlert} onClickOk={() => setOpenAlert(false)} title="Alert" message='Total preliminary billing amount should be less than budget amount!' />
                <Table>
                    <thead>
                        <tr>
                            <th>{t("Preliminary billing")}</th>
                            <th>{t("Date")}</th>
                            <th></th>
                        </tr>
                    </thead>
                    {(initialData?.preliminary_details && initialData?.preliminary_details?.length > 0) ? initialData?.preliminary_details?.map((row: any, index: number) => (
                        <tr key={index}>
                            <td>
                                <div>
                                    {/* {JSON.stringify(initialData.preliminary)} */}
                                    <FormGroup switch inline className="float-start">
                                        <Input
                                            id="is_preliminary_fixed_amount"
                                            type="switch"
                                            checked={
                                                initialData['preliminary_details'][index]?.is_preliminary_fixed_amount
                                                    ? true
                                                    : false
                                            }
                                            disabled={
                                                initialData['preliminary_details'][index]?.is_preliminary_fixed_amount === 1 || row.status === 1
                                            }
                                            onChange={(e) => {
                                                setInitialData((preState: any) => ({
                                                    ...preState, preliminary_details: preState.preliminary_details.map((a: any, i: number) => {
                                                        if (i === index) {
                                                            return ({ ...a, is_preliminary_fixed_amount: e.target.checked })
                                                        } else {
                                                            return a;
                                                        }
                                                    })
                                                }));
                                                // handleChange(
                                                //     `preliminary[${index}][is_preliminary_fixed_amount]`,
                                                //     e.target.checked ? 1 : 0
                                                // );
                                            }}
                                        />
                                        <Label check>{t("Fixed amount")}</Label>
                                    </FormGroup>
                                </div>
                                {/* {(initialData?.preliminary?.length - 1)} */}
                                <Input
                                    placeholder={t('Preliminary billing')}
                                    value={initialData['preliminary_details'][index]?.amount}
                                    touched={index === (initialData?.preliminary_details?.length - 1)}
                                    disabled={row.status === 1}
                                    error={remainingBudget < 0 ? ' ' : null}
                                    onChange={(e) => {
                                        // handleChange(
                                        //     `preliminary[${index}][amount]`,
                                        //     e.target.checked
                                        // );
                                        setInitialData((preState: any) => ({
                                            ...preState, preliminary_details: preState.preliminary_details.map((a: any, i: number) => {
                                                if (i === index) {
                                                    return ({ ...a, amount: e.target.value })
                                                } else {
                                                    return a;
                                                }
                                            })
                                        }));
                                    }}
                                    endAdornment={initialData['preliminary_details'][index]['is_preliminary_fixed_amount'] ? <span className="align-middle material-symbols-outlined fw-light fs-4">euro</span> : <span className="align-middle material-symbols-outlined fw-light fs-4">percent</span>}
                                />
                            </td>
                            <td className='align-bottom'>
                                <DatePicker
                                    id="date"
                                    name="date"
                                    className="form-control d-block"
                                    type="text"
                                    placeholder="DD/MM/YYYY"
                                    value={initialData['preliminary_details'][index]?.date}
                                    disabled={row.status === 1}
                                    options={{
                                        altInput: true,
                                        altFormat: "d/m/Y",
                                        dateFormat: "Y-m-d",
                                        defaultDate: new Date(),
                                        allowInput: true,
                                        minDate: initialData['preliminary_details'][index - 1]?.date ? moment(initialData['preliminary_details'][index - 1]?.date).add(1, 'day').toISOString() : 'today',
                                        onChange: function (
                                            selectedDates,
                                            dateStr,
                                            instance
                                        ) {
                                            setInitialData((preState: any) => ({
                                                ...preState, preliminary_details: preState.preliminary_details.map((a: any, i: number) => {
                                                    if (i === index) {
                                                        return ({ ...a, date: dateStr })
                                                    } else {
                                                        return a;
                                                    }
                                                })
                                            }));
                                            // handleChange(`preliminary[${index}][amount]`, dateStr)
                                        },
                                    }}
                                />
                            </td>
                            <td className='align-bottom'>
                                <div>
                                    {row.status === 1 && <Button type='button' title='Invoice is already created' color={row.status === 1 ? 'success' : 'light'} size='sm'>
                                        <span className="align-middle material-symbols-outlined fw-light fs-4">{row.status === 1 ? 'check' : 'close'}</span>
                                    </Button>}
                                    {(rows.length > 1 && row.status === 0) && <Button type='button' title='Delete Preliminary billing' outline color={row.status === 1 ? 'success' : 'danger'} size='sm' onClick={() => handleDelete(index)}>
                                        <span className="align-middle material-symbols-outlined fw-light fs-4">{row.status === 1 ? 'check' : 'close'}</span>
                                    </Button>
                                    }
                                </div>
                            </td>
                        </tr>
                    )) : null}
                    {(initialData?.preliminary_details[(initialData?.preliminary_details?.length - 1)]['amount'] > 0 && initialData?.preliminary_details[(initialData?.preliminary_details?.length - 1)]['date'] !== "") &&
                        <tfoot>
                            <tr>
                                <td>
                                    <Button size='sm' className='ms-auto' onClick={handleAddMore}>{t("Add more")}</Button>
                                </td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tfoot>}
                </Table>

                <Row>
                    <Col>

                    </Col>
                </Row>
            </div>
    )
}

export default PreliminaryForm;