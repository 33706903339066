import React from "react"
import PropTypes from 'prop-types'
import { CardTitle } from "reactstrap"
import './style.scss';
import { useTranslation } from "react-i18next";
const RenderCardTitle = (props: any) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="kanban-title">
        <CardTitle className="h3 fs-6">{t(props?.title)}<h6 className="float-end fs-6 text-truncate">({props?.count} {t("Tasks")})</h6></CardTitle>
      </div>
    </React.Fragment>
  )
}


RenderCardTitle.propTypes = {
  title: PropTypes.string,
  count: PropTypes.number
}

export default React.memo(RenderCardTitle)
