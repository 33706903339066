import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import DataTable from '../../../components/Common/DataTable';
import Drawer from '../../../components/Drawer';
import Breadcrumbs from '../../../components/Layout/Breadcrumb';
import { getDataTableQuery, getUserProfile } from '../../../helpers/functions_helper';
import NewsFeedForm from './NewsFeedForm';
import NewsFeedList from './NewsFeedList';


const NewsFeed = () => {
    document.title = "News Feed | " + process.env.REACT_APP_NAME;
    const { t } = useTranslation();
    const { type } = useParams();

    const userObj = getUserProfile('news-feed');

    const moduleHeading = t('News Feed')

    const breadcrumbItems = useMemo(() => [
        {
            url: "/dashboard",
            label: t("Dashboard"),
            active: false,
        },
        {
            url: "#",
            label: moduleHeading,
            active: true,
        },
    ], [t]);

    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const handleSave = () => {
        setLoading(false);
        setOpenModal(false);
    }

    useEffect(() => {
        !openModal && setLoading(!openModal);
    }, [openModal])


    return (
        <div className={"page-content"}>
            <Container fluid>
                <Breadcrumbs
                    breadcrumbTitle={moduleHeading}
                    breadcrumbItems={breadcrumbItems}
                    endAdornment={
                        (userObj.CAN_CREATE) && <>
                            <Link
                                to="/masters/news-feed/create"
                                className="btn btn-primary btn-sm waves-effect waves-light btn-label"
                            >
                                <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">add</span> {t('Create News Feed')}
                            </Link>
                        </>
                    }
                />
                <Row>
                    <Col xl="12">
                        <Card>
                            <CardBody>
                                <NewsFeedList loadingData={loading} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Drawer
                open={openModal}
                onClose={() => setOpenModal(false)}
                title='Create News Feed'
                fullWidth
            >
                <NewsFeedForm onSave={handleSave} onCancel={() => setOpenModal(false)} />
            </Drawer>
        </div>
    );
}

export default NewsFeed;