import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  Nav,
  Alert,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  FormGroup,
} from "reactstrap";

// Actions
import { CompanyAction, CommonAction } from "../../../store/actions";
import companyService from "../../../services/masters.company.service";

// Import Breadcrumb
import Breadcrumbs from "../../../components/Layout/Breadcrumb";

// Import Modal
import DeleteModal from "../../../components/Common/DeleteModal";
import ArchiveModal from "../../../components/Common/ArchiveModal";

// Import DataTable
import DataTable from "../../../components/Common/DataTable";

import {
  dateTimeString,
  filterCountryByLanguage,
  getDataTableQuery,
  getSelectOptions,
  getUserProfile,
  handleFilters,
} from "../../../helpers/functions_helper";
import Avatar from "../../../components/Avatar";
import fileDownload from "js-file-download";
import Button from "../../../components/Button/Button";
import { toast } from "react-toastify";
import Filters from "./Filters";
import { useTranslation } from "react-i18next";
import commonService from "../../../services/common.service";
import { dateFormat } from "../../../utils/formats";
import Drawer from "../../../components/Drawer";
import Input from "../../../components/Form/Input";
import { getLangCode } from "../../../common/staticdata";
const AllCompany = (props: any) => {
  const { t } = useTranslation();
  document.title = `${t("Company")} | ${process.env.REACT_APP_NAME}`;
  const breadcrumbItems = [
    {
      url: "/dashboard",
      label: t("Dashboard"),
      active: false,
    },
    {
      url: "#",
      label: t("Company"),
      active: true,
    },
  ];
  const userProfile = getUserProfile("all-company");
  const savedfilters = handleFilters("GET");
  const { type } = useParams();
  const [loader, setLoader] = useState(true);
  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingExportCSV, setLoadingExportCSV] = useState(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [companyListData, setCompanyListData] = useState([]);
  const toastId = React.useRef(null) as any;
  const dispatch = useDispatch();

  let {
    datatableData,
    getCompanyListErrorMsg,
    createCompanySuccessMsg,
    updateCompanySuccessMsg,
    deleteCompanySuccessMsg,
    deleteCompanyErrorMsg,
    getCompanyErrorMsg,
    loading,
  } = useSelector((state: any) => ({
    datatableData: state.Company.GET_COMPANY_LIST_SUCCESS,
    getCompanyListErrorMsg: state.Company.GET_COMPANY_LIST_ERROR.message,

    createCompanySuccessMsg: state.Company.CREATE_COMPANY_SUCCESS.message,

    updateCompanySuccessMsg: state.Company.UPDATE_COMPANY_SUCCESS.message,

    deleteCompanySuccessMsg: state.Company.DELETE_COMPANY_SUCCESS.message,
    deleteCompanyErrorMsg: state.Company.DELETE_COMPANY_ERROR.message,

    getCompanyErrorMsg: state.Company.GET_COMPANY_ERROR.message,

    loading: state.Company.loading,
  }));

  // DataTable ----------------------------------------------
  function renderHeaderHTML(column: any, colIndex: any) {
    return <span dangerouslySetInnerHTML={{ __html: column.text }} />;
  }
  function renderRowHTML(rowContent: any, row: any) {
    return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
  }

  const [countryOptions, setCountryOptions] = useState<Array<any>>([]);
  useEffect(() => {
    getSelectOptions("tbl_master_country", "name", "", [
      "is_europian_country",
      "name",
      "name_french",
      "name_dutch",
    ]).then(function (options) {
      let countryOptionsList = filterCountryByLanguage(options);
      setCountryOptions(countryOptionsList);
    });
  }, []);

  const [lng, setLng] = useState(localStorage.getItem("I18N_LANGUAGE"));
  useEffect(()=>{
    setLng(localStorage.getItem("I18N_LANGUAGE"));
    setLoadingListData(true);
    setCompanyListData([]);
  },[localStorage.getItem("I18N_LANGUAGE")])

  const columns = [
    {
      dataField: "DT_RowIndex",
      text: `${t('Sr. No.')}`,
      sort: false,
      search: false,
      classes: "fix-col-left",
      headerClasses: "fix-col-left",
      headerStyle: { width: "155px" },
      style: { width: "155px" },
    },
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: true,
    //   search: true,
    // },
    {
      dataField: "company_logo_data",
      text: "",
      sort: false,
      search: false,
      formatter: (rowContent: any, row: any): any => {
        return (
          // row.company_logo_data && (
          //   <img
          //     src={row.company_logo_data}
          //     className="rounded-circle avatar-xs border"
          //   />
          // )
          <Avatar
            id={row.id}
            name={`${row.company_name} ${row.company_name}`}
            src={row.company_logo_data}
          />
        );
      },
    },
    {
      dataField: "custome_code",
      text: `${t('Account ID')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "company_name",
      text: `${t('Client name')}`,
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return (
          <Link
            className="text-decoration-underline"
            to={
              "/masters/company/company-details/" + row.id + "/" + row.company_id
            }
          >
            {row.company_name}
          </Link>
        );
      },
    },
    {
      dataField: "category_value",
      text: `${t('Client category')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "language_value",
      text: `${t('Language')}`,
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return t(row.language_value);
      },
    },
    {
      dataField: "email_address",
      text: `${t('Email')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "legal_entity",
      text: `${t('Legal entity')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "phone_number_1",
      text: `${t('Contact number')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "phone_number_2",
      text: `${t('Contact number (Alternate)')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "fax_nr_company",
      text: `${t('FAX number')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "company_profile",
      text: `${t('Client profile')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "primary_industry_type",
      text: `${t('Paritary committee')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "company_registration_id",
      text: `${t('Registration ID')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "file_manager_value",
      text: `${t('File manager')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "signing_partner_value",
      text: `${t('Partner')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "company_status_value",
      text: `${t('Status')}`,
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return t(row.company_status_value);
      },
    },
    {
      dataField: "company_listing_status_value",
      text: `${t('Listing status')}`,
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return t(row.company_listing_status_value);
      },
    },
    {
      dataField: "company_address",
      text: `${t('Client address')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "office_number",
      text: `${t('Office number')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "box",
      text: `${t('Box number')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "postal_code",
      text: `${t('Postal code')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "city",
      text: `${t('City')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "country_name",
      text: `${t('Country')}`,
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        const selectedCountry = countryOptions.find(
          (option: any) => option.value === row.country
        );
        const label = (lng == "English")? "label" : ((lng=="French")? "name_french": "name_dutch");
        return selectedCountry ? selectedCountry[label] : '';
      },
    },
    {
      dataField: "inv_company_address",
      text: `${t('Client address (Invoice)')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "inv_office_number",
      text: `${t('Office number')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "inv_box",
      text: `${t('Box number')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "inv_postal_code",
      text: `${t('Postal code')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "inv_city",
      text: `${t('City')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "inv_country_name",
      text: `${t('Country')}`,
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        const selectedCountry = countryOptions.find(
          (option: any) => option.value === row.inv_country
        );
        const label = (lng == "English")? "label" : ((lng=="French")? "name_french": "name_dutch");
        return selectedCountry ? selectedCountry[label] : '';
      },
    },
    {
      dataField: "contact_person_value",
      text: `${t('Contact person (Company)')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "contact_function",
      text: `${t('Function')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "invoice_contact_person_value",
      text: `${t('Contact person (Invoice)')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "invoice_payable_period",
      text: `${t('Payable period (Invoice)')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "hr_contact_person_value",
      text: `${t('Contact person (HR)')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "gen_contact_person_value",
      text: `${t('Contact person (GEN)')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "bank_name_prime",
      text: `${t('Bank name (Prime)')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "bank_address_prime",
      text: `${t('Bank address (Prime)')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "IBAN_Nr_prime",
      text: `${t('IBAN number (Prime)')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "bic",
      text: `${t('BIC number (Prime)')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "short_description_activities",
      text: `${t('Short description of activities')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "stuation_of_the_belgian_entity",
      text: `${t('Situation of the Belgian entity within the international group')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "additional_info",
      text: `${t('Additional information on the company')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "website",
      text: `${t('Website')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "external_web_link",
      text: `${t('External web link')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "number_Of_employees",
      text: `${t('Number of employees')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "TVW_Nr",
      text: `${t('TVA/BTW number')}`,
      sort: true,
      search: true,
    },
    // {
    //   dataField: "listing_status_value",
    //   text: `${t('Listing status')}`,
    //   sort: true,
    //   search: true,
    // },
    {
      dataField: "start_date_value",
      text: `${t('Start date')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "end_date_value",
      text: `${t('End date')}`,
      sort: true,
      search: true,
    },
    {
      dataField: "is_exported_to_csf",
      text: `${t('Exported to CSF')}`,
      sort: true,
      search: false,
      hidden: !(userProfile.IS_PARTNER),
      formatter: (rowContent: any, row: any): any => {
        return (
          <>
            <FormGroup switch inline className="form-check-success">
              <Input
                type="switch"
                dis
                checked={row?.is_exported_to_csf}
                // onChange={() => handleAct(row.id)}
                label={row?.is_exported_to_csf ? t('Yes') : t('No')}
              />
            </FormGroup>
          </>
        );
      },
    },

    // -------------- CREATED - UPDATED
    {
      dataField: "created_by_value",
      text: `${t('Created by')}`,
      sort: true,
      search: true,
      hidden: !userProfile.IS_INTERNAL,
    },
    {
      dataField: "created_at",
      text: `${t('Created on')}`,
      sort: true,
      search: true,
      hidden: !userProfile.IS_INTERNAL,
      formatter: (rowContent: any, row: any): any => {
        return dateFormat(
          row?.created_at,
          {
            dateStyle: "long",
            timeStyle: "short",
            hour12: true,
          },
          getLangCode(localStorage.getItem("I18N_LANGUAGE"))
        );
      },
    },
    {
      dataField: "updated_by_value",
      text: `${t('Updated by')}`,
      sort: true,
      search: true,
      hidden: !userProfile.IS_INTERNAL,
    },
    {
      dataField: "updated_at",
      text: `${t('Updated on')}`,
      sort: true,
      search: true,
      hidden: !userProfile.IS_INTERNAL,
      formatter: (rowContent: any, row: any): any => {
        return dateFormat(
          row?.updated_at,
          {
            dateStyle: "long",
            timeStyle: "short",
            hour12: true,
          },
          getLangCode(localStorage.getItem("I18N_LANGUAGE"))
        );
      },
    },
    // -------------- CREATED - UPDATED

    {
      dataField: "action",
      text: `${t('Action')}`,
      sort: false,
      search: false,
      hidden: userProfile.USER_COMPANY !== 1,
      formatter: (rowContent: any, row: any): any => {
        return (
          <div className="d-flex gap-2 text-end">

            {userProfile.IS_INTERNAL &&
              ((userProfile.USER_ID === parseInt(row?.created_by)) ||
                (userProfile.CAN_EDIT_ALL)) ? (
              <Link
                className="btn btn-pill btn-outline-primary btn-sm"
                to={"/masters/company/company-details/" + row.id + "/" + row.company_id}
              >
                <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                  edit
                </span>
                {(t('Edit'))}
              </Link>
            ) : (
              <Link
                className="btn btn-pill btn-outline-primary btn-sm"
                to={"/masters/company/company-details/" + row.id + "/" + row.company_id}
              >
                <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                  visibility
                </span>
                {(t('View'))}
              </Link>
            )}

            {(userProfile.CAN_DELETE && userProfile.USER_ID === parseInt(row.created_by) || userProfile.CAN_DELETE_ALL) &&
              <Button
                id={`btnArchive_${row.id}`}
                className="btn btn-pill btn-outline-danger btn-sm"
                color="danger"
                outline={true}
                onClick={() => onClickArchive(row)}
              >
                {row.is_archive === 0 ? (
                  <><i className="fa-solid fa-box-archive"></i> {(t('Archive'))}</>
                ) : (
                  <><i className="fa-solid fa-box-archive"></i> {(t('Unarchive'))}</>
                )}
              </Button>
            }
          </div>
        );
      },
    },
  ];

  const [csfCount, setCsfCount] = useState<number>(0);
  const [loadingListData, setLoadingListData] = useState(true);
  const [datatableOptions, setDatatableOptions] = useState(
    savedfilters && savedfilters?.datatableOptions
      ? (savedfilters?.datatableOptions as any)
      : {
        draw: 1,
        page: 1,
        recordsTotal: 0,
        recordsFiltered: 0,
        sizePerPage: 20,
        searchText: "",
        sortField: "company_name",
        sortOrder: "asc",
      }
  );
  const [filters, setFilters] = useState(
    savedfilters && savedfilters?.filters
      ? (savedfilters?.filters as any)
      : {
        is_filtered: false,
        company_id: "",
        country: "",
        listing_status_id: "",
        start_date: "",
        end_date: "",
        is_archive: type === "archive" ? 1 : 0,
        company_category: "",
        client_status: "",
        TVW_Nr: null,
        vatcat: "",
        to_apply_vat: ""
      }
  );

  useEffect(() => {
    setCompanyListData([]);
    setLoadingListData(true);
  }, [localStorage.getItem("I18N_LANGUAGE")]);

  const handleClear = () => {
    setFilters({
      is_filtered: false,
      company_id: "",
      country: "",
      listing_status_id: "",
      start_date: "",
      end_date: "",
      is_archive: type === "archive" ? 1 : 0,
      company_category: "",
      client_status: "",
      TVW_Nr: null,
      vatcat: "",
      to_apply_vat: ""
    });
  };

  const loadingDatatable = async () => {
    try {
      const res = (await companyService.getCompanyList({
        ...getDataTableQuery(datatableOptions, columns),
        filters,
      })) as any;

      if (res.data.status === "success") {
        if (userProfile.USER_COMPANY !== 1) {
          setCompanyListData(
            res.data.data.filter((i: any) => i.id === userProfile.USER_COMPANY)
          );
        } else {
          setCompanyListData(res.data.data);
        }

        const updatedDatatableOptions = {
          ...datatableOptions,
          recordsTotal: res.data.recordsTotal,
          recordsFiltered: res.data.recordsFiltered,
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingListData(false);
      } else {
        setCompanyListData([]);

        const updatedDatatableOptions = {
          ...datatableOptions,
          recordsTotal: 0,
          recordsFiltered: 0,
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingListData(false);
      }
    } catch (err) {
      setCompanyListData([]);

      const updatedDatatableOptions = {
        ...datatableOptions,
        recordsTotal: 0,
        recordsFiltered: 0,
      };
      setDatatableOptions(updatedDatatableOptions);
      setLoadingListData(false);
    }
  };

  useEffect(() => {
    if (loadingListData === true) {
      loadingDatatable();
    }
  }, [loadingListData]);

  const onTableChange = (type: any, newState: any) => {
    let draw = datatableOptions.draw + 1;
    let page = 1;
    if (type === "search") {
      page = 1;
    } else {
      page = newState.page;
    }
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: draw,
      page: page,
      sizePerPage: newState.sizePerPage,
      searchText: newState.searchText,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
    };
    setDatatableOptions(updatedDatatableOptions);
    setCompanyListData([]);
    setLoadingListData(true);
  };

  const clearFilter = () => {
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: 1,
      page: 1,
      sizePerPage: 20,
      searchText: "",
      sortField: "company_name",
      sortOrder: "asc",
    };
    setDatatableOptions(updatedDatatableOptions);

    const filter = {
      ...filters,
      is_filtered: false,
      company_id: "",
      country: "",
      listing_status_id: "",
      start_date: "",
      end_date: "",
      company_category: "",
      is_archive: type === "archive" ? 1 : 0,
      client_status: "",
      TVW_Nr: null,
      vatcat: "",
      to_apply_vat: ""
    };
    setFilters(filter);

    setCompanyListData([]);
    setLoadingListData(true);
  };

  const handleDownloadCsf = async () => {
    toastId.current = toast(t("Downloading csf.csv file..."), {
      autoClose: false,
      isLoading: true,
    });

    const res = await companyService.downloadCsf();
    if (res.status === 200) {
      fileDownload(res?.data, "csf.csv");
      toast.update(toastId.current, {
        type: toast.TYPE.SUCCESS,
        render: t("Csf.csv file is sucessfully downloaded"),
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setCsfCount(0);
    } else {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: t("No company or user to export"),
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
    }
  };

  const getCsfCount = async () => {
    const res = await companyService.getCsfCount();
    if (res.data.status === "success") {
      setCsfCount(res.data.data);
    }
  };

  useEffect(() => {
    getCsfCount();
  }, []);


  const [archiveCompany, setArchiveCompany] = useState({ id: "", table_name: "", field_name: "", is_archive: 0 });
  const [archiveRecordName, setArchiveRecordName] = useState("");
  const [archiveModal, setArchiveModal] = useState(false);

  const onClickArchive = (row: any) => {
    const archiveCompanyData = {
      ...archiveCompany,
      id: row.id,
      table_name: "tbl_master_company",
      field_name: "is_archive",
      is_archive: row.is_archive,
    };
    setArchiveCompany(archiveCompanyData);
    setArchiveRecordName(row.company_name);
    setArchiveModal(true);
  };

  const handleArchiveCompany = async () => {
    toastId.current = toast(t("Updating archive status..."), {
      autoClose: false,
      isLoading: true,
    });

    const res = (await commonService.handleArchive(archiveCompany)) as any;

    if (res.data.status === "success") {
      toast.update(toastId.current, {
        type: toast.TYPE.SUCCESS,
        render: res.data.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });

      dispatch(CompanyAction.getCompanySuccess(""));
      setArchiveModal(false);
      setCompanyListData([]);
      setLoadingListData(true);
    } else {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: res.data.message,
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setArchiveModal(false);
    }
  }

  const [company, setCompany] = useState({ id: "", company_id: "" });
  const [recordName, setRecordName] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (row: any) => {
    const deleteCompanyData = {
      ...company,
      id: row.id,
      company_id: row.company_id,
    };
    setCompany(deleteCompanyData);
    setRecordName(row.company_name);
    setDeleteModal(true);
  };
  const handleDeleteCompany = () => {
    dispatch(CompanyAction.deleteCompany(company, props.history));
    setDeleteModal(false);
  };

  // Delete on messages -------------------------------------------------
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    if (getCompanyListErrorMsg) {
      setErrorMsg(getCompanyListErrorMsg);
      dispatch(CompanyAction.getCompanyListError(""));
    }
  }, [getCompanyListErrorMsg]);

  useEffect(() => {
    if (createCompanySuccessMsg) {
      setSuccessMsg(createCompanySuccessMsg);
      dispatch(CompanyAction.createCompanySuccess(""));
      dispatch(CommonAction.getCompanyOptionsListSuccess(""));
      window.scrollTo(0, 0);
    }
  }, [createCompanySuccessMsg]);

  useEffect(() => {
    if (updateCompanySuccessMsg) {
      setSuccessMsg(updateCompanySuccessMsg);
      dispatch(CompanyAction.updateCompanySuccess(""));
      dispatch(CommonAction.getCompanyOptionsListSuccess(""));
      window.scrollTo(0, 0);
    }
  }, [updateCompanySuccessMsg]);

  useEffect(() => {
    if (deleteCompanySuccessMsg) {
      setSuccessMsg(deleteCompanySuccessMsg);
      setLoadingListData(true);
      dispatch(CompanyAction.deleteCompanySuccess(""));
      dispatch(CommonAction.getCompanyOptionsListSuccess(""));
      window.scrollTo(0, 0);
    }
  }, [deleteCompanySuccessMsg]);

  useEffect(() => {
    if (deleteCompanyErrorMsg) {
      setErrorMsg(deleteCompanyErrorMsg);
      dispatch(CompanyAction.deleteCompanyError(""));
    }
  }, [deleteCompanyErrorMsg]);

  useEffect(() => {
    if (getCompanyErrorMsg) {
      setErrorMsg(getCompanyErrorMsg);
      dispatch(CompanyAction.getCompanyError(""));
    }
  }, [getCompanyErrorMsg]);
  // Delete on messages -------------------------------------------------

  // Filters -------------------------------------------------
  const handleFiltersChange = (obj: any) => {
    setFilters((state: any) => ({ ...state, ...obj }));
  };

  const handleApplyFilters = () => {
    setCompanyListData([]);
    setLoadingListData(true);
    setOpenFilter(false);
  };
  // Filters -------------------------------------------------

  const exportAll = async () => {
    try {
      setLoadingExport(true);
      const res = (await companyService.getCompanyExport({
        ...getDataTableQuery(datatableOptions, columns),
        filters,
      })) as any;

      if (res) {
        fileDownload(res.data, `company-${dateTimeString()}-export.xlsx`);
        setLoadingExport(false);
      }
    } catch (err) {
      setLoadingExport(false);
    }
  };
  const exportCSV = async () => {
    try {
      setLoadingExportCSV(true);
      const res = (await companyService.getCompanyExportCSV({
        ...getDataTableQuery(datatableOptions, columns),
        filters,
      })) as any;

      if (res) {
        fileDownload(res.data, `company-${dateTimeString()}-export.csv`);
        setLoadingExportCSV(false);
      }
    } catch (err) {
      setLoadingExportCSV(false);
    }
  };

  return (
    <React.Fragment>
      {company && (
        <DeleteModal
          recordName={recordName}
          modalTitle={t("Company")}
          show={deleteModal}
          onDeleteClick={handleDeleteCompany}
          onCloseClick={() => setDeleteModal(false)}
          loading={loading}
        />
      )}

      {archiveCompany && (
        <ArchiveModal
          recordName={archiveRecordName}
          modalTitle={t("Company")}
          show={archiveModal}
          onArchiveClick={handleArchiveCompany}
          onCloseClick={() => setArchiveModal(false)}
          is_company={true}
          is_archive={archiveCompany.is_archive}
          loading={loading}
        />
      )}

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle={t("Company")}
            breadcrumbItems={breadcrumbItems}
            endAdornment={
              <div className="d-flex gap-2 text-end">
                {userProfile?.IS_GRAPHICAL_VIEW && (
                  <Link to="/masters/company" className={`btn btn-info btn-label btn-sm`}>
                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                      list_alt
                    </span>
                    {t("List view")}
                  </Link>
                )}

                {userProfile?.IS_GRAPHICAL_VIEW && (
                  <Link
                    to="/masters/company/graph"
                    className={`btn btn-outline-secondary btn-label btn-sm`}
                  >
                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                      insert_chart
                    </span>
                    {t("Graphical view")}
                  </Link>
                )}

                {userProfile.CAN_CREATE ? (
                  <Link to="/masters/company/new-company" className="btn btn-primary btn-label btn-sm">
                    <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
                      add
                    </span>{" "}
                    {t("Create company")}
                  </Link>
                ) : null}
              </div>
            }
          />

          <Row>
            <Col xl="12">
              {successMsg ? <Alert color="success">{successMsg}</Alert> : null}
              {errorMsg ? <Alert color="danger">{errorMsg}</Alert> : null}

              <Card>
                {userProfile.IS_INTERNAL ? (
                  <CardHeader className="bg-transparent border-bottom pb-0">
                    <div className="d-sm-flex flex-wrap">
                      <Nav tabs className="nav-tabs-custom">
                        <NavItem>
                          <Link
                            style={{ cursor: "pointer" }}
                            to="/masters/company"
                            className={!type ? "nav-link active" : "nav-link"}
                          >
                            <span className="d-block d-sm-none">
                              <i className="fas fa-home"></i>
                            </span>
                            <span className="d-none d-sm-block">
                              {t("All Company")}
                            </span>
                          </Link>
                        </NavItem>
                        <NavItem>
                          <Link
                            style={{ cursor: "pointer" }}
                            to="/masters/company/archive"
                            className={
                              type === "archive"
                                ? "nav-link active"
                                : "nav-link"
                            }
                          >
                            <span className="d-block d-sm-none">
                              <i className="fas fa-home"></i>
                            </span>
                            <span className="d-none d-sm-block">{("Archive")}</span>
                          </Link>
                        </NavItem>
                      </Nav>
                    </div>
                  </CardHeader>
                ) : null}

                <CardBody>
                  <DataTable
                    columns={columns}
                    products={companyListData}
                    datatableOptions={datatableOptions}
                    filters={filters}
                    onTableChange={onTableChange}
                    loadingListData={loadingListData}
                    clearFilter={clearFilter}
                    settingTableName="companies_table"
                    slug="all-company"
                    customButtons={
                      <>
                        {userProfile.IS_INTERNAL && userProfile.CAN_DOWNLOAD_CSF_ACT ? (
                          <Button
                            outline
                            color="light"
                            onClick={() => handleDownloadCsf()}
                            title="Download csf.csv file"
                          >
                            <span className="align-middle material-symbols-outlined fw-light fs-4">{t("download")}</span>
                            {csfCount > 0 ? (
                              <>
                                &nbsp;
                                <span className="badge rounded-pill bg-danger">
                                  {csfCount}
                                </span>
                              </>
                            ) : null}
                          </Button>
                        ) : null}

                        {userProfile.IS_INTERNAL ? (
                          <Button
                            title={t("Filter")}
                            outline={filters.is_filtered === true ? false : true}
                            color={filters.is_filtered === true ? "secondary" : "light"}
                            onClick={() => setOpenFilter((state) => !state)}
                          >
                            {filters.is_filtered === false ? (
                              <span className="align-middle material-symbols-outlined fw-light fs-4">filter_alt</span>
                            ) : (
                              <span className="align-middle material-symbols-outlined fw-light fs-4">filter_alt_off</span>
                            )}
                          </Button>
                        ) : null}

                        {userProfile.CAN_EXPORT ? (
                          <>
                            <Button
                              title={t("Export all")}
                              outline={true}
                              color={"light"}
                              onClick={() => {
                                exportAll();
                              }}
                              disabled={loadingExport}
                            >
                              {loadingExport ? (
                                <span className="align-middle material-symbols-outlined fw-light fs-4 fa-spin">
                                  hourglass_empty
                                </span>
                              ) : (
                                <span className="align-middle material-symbols-outlined fw-light fs-4">
                                  export_notes
                                </span>
                              )}
                            </Button>

                            {userProfile.CAN_VIEW_ALL ? (
                              <Button
                                title={`${t("Export all")} - CSV`}
                                outline={true}
                                color={"light"}
                                onClick={() => {
                                  exportCSV();
                                }}
                                disabled={loadingExportCSV}
                              >
                                {loadingExportCSV ? (
                                  <span className="align-middle material-symbols-outlined fw-light fs-4 fa-spin">
                                    hourglass_empty
                                  </span>
                                ) : (
                                  <span className="align-middle material-symbols-outlined fw-light fs-4">
                                    csv
                                  </span>
                                )}
                              </Button>
                            ) : null}
                          </>
                        ) : null}
                      </>
                    }
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Drawer
        title="Filters"
        open={openFilter}
        onClose={() => setOpenFilter((state) => !state)}
        onReset={clearFilter}
        footer={
          <Button
            color="primary"
            style={{ margin: "16px" }}
            onClick={handleApplyFilters}
          >
            {t("Apply")}
          </Button>
        }>
        <Filters
          filters={filters}
          onApply={() => { }}
          onClear={() => { }}
          onChange={(obj: any) => handleFiltersChange(obj)}
        />
      </Drawer>
    </React.Fragment>
  );
};

export default AllCompany;
