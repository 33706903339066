import { GET_ALERTS, GET_ALERT_ERROR, GET_ALERT_SUCCESS } from "./actionTypes"

const initialState = {
    alertlist: [],
}

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case GET_ALERTS:
            state = {
                ...state,
                alertlist: action.payload
            };

            break;
        case GET_ALERT_SUCCESS:
            state = {
                ...state,
                alertlist: action.payload
            };
            break;
        case GET_ALERT_ERROR:
            state = {
                ...state,
                alertlist: action.payload
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default reducer;