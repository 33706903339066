import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { noData } from '../../../utils/data';
import timeSheetsService from '../../../services/time.sheets.service';
import LoadingSpinner from '../../../components/Common/Loader';
import { secondsToTime, serialize } from '../../../utils/utils';
import { formatCurrency } from '../../../utils/formats';
import GraphFilters from '../../../components/Chart/Filters';
import { getUserProfile, graph_i18 } from '../../../helpers/functions_helper';
import Chart from '../../../components/Chart';
import { currentMonth, currentYear } from '../../../common/staticdata';

interface TimePerTaskPerUserProps extends CartProps {
    filters?: any;
}

const TimePerTaskPerUser: FC<TimePerTaskPerUserProps> = ({ hideHeading, filters = null }) => {

    const { t } = useTranslation();

    const userObj = getUserProfile('all-time-sheets');

    const inititalFilter = {
        week: null,
        quarter: null,
        month: currentMonth,
        year: currentYear,
        user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_ID : null,
        company_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_COMPANY : null,
    };

    const [loading, setLoading] = useState<boolean>(true);
    const [query, setQuery] = useState<string | null>(serialize(inititalFilter));
    const [filter, setFilter] = useState<any>(inititalFilter);

    const [data, setData] = useState<any>({
        series: [],
        options: {
            chart: {
                type: 'bar',
                height: 380,
                stacked: true,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: true
                },
                redrawOnParentResize: false,
                redrawOnWindowResize: false
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            plotOptions: {
                bar: {
                    horizontal: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            xaxis: {
                labels: {
                    show: true,
                    trim: true,
                    rotate: -45,
                }
            },
            yaxis: {
                labels: {
                    formatter: function (value: any) {
                        return secondsToTime(value);
                    }
                },
            },
            legend: {
                position: 'bottom',
                offsetY: 5
            },
            fill: {
                opacity: 1
            },
            noData: noData,
        },
    })

    const getChartData = async () => {
        const result = await timeSheetsService.timePerTaskPerUser(query);
        const local_i18n = graph_i18();
        if (result.data.status === 'success') {
            setData((data: any) => ({
                ...data,
                series: result?.data?.data?.series || [],
                options: {
                    ...data.options,
                    xaxis: {
                        ...data.options.xaxis,
                        categories: result?.data?.data?.users,
                        labels: {
                            show: true,
                            trim: true,
                            rotate: -45,
                        }
                    },
                    chart: {
                        ...data.options.chart,
                        locales: [local_i18n],
                        defaultLocale: local_i18n?.name,
                    }
                }

            }));
        }
        setLoading(false);
    }

    const clearWorkAllocationFilter = () => {
        setFilter(inititalFilter);
    }

    useEffect(() => {
        loading && getChartData();
    }, [loading])

    useEffect(() => {
        setQuery(serialize(filter));
        setLoading(true);
    }, [filter])

    useEffect(() => {
        filters && setFilter(filters)
    }, [filters])

    return (
        <div>
            <div className="d-sm-flex flex-wrap">
                <h4 className="card-title mb-4">{!hideHeading && t("Time spent per task type per user")}</h4>
                <div className="ms-auto">
                    <GraphFilters
                        filter={filter}
                        setFilter={setFilter}
                        clearFilter={clearWorkAllocationFilter}
                        slug='all-time-sheets'
                    />
                </div>
            </div>
            <div className="clearfix"></div>
            <Chart loading={loading} options={data.options} series={data.series} type="bar" height={380} />
        </div>
    )
}

export default TimePerTaskPerUser;