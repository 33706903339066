import React, { FC } from 'react';
import Flatpickr, { DateTimePickerProps } from "react-flatpickr";
import { useTranslation } from 'react-i18next';
import { FormFeedback, Label } from 'reactstrap';
import ErrorMessage from '../ErrorMessage';
import { dateFormat } from '../../../utils/formats';
import { datePickerI18 } from '../../../helpers/functions_helper';

interface DatePickerType extends DateTimePickerProps {
    label?: string;
    required?: boolean;
    error?: any;
    touched?: any;
    disabled?: any;
    sm?: boolean;
}

const DatePicker: FC<DatePickerType> = ({ label, required, error, touched, disabled = false, className, sm = false, ...props }) => {
    const { t } = useTranslation();
    return (
        <>
            {label &&
                <Label className="form-label">
                    {t(label)}
                    {required ? <span className="text-warning is_required ms-1">*</span> : null}
                </Label>
            }
            <div className={`input-group ${sm ? 'input-group-sm' : ''}  ${!!error && touched ? 'is-invalid' : ''}`}>
                {disabled ? (
                    <input
                        type="text"
                        placeholder="DD/MM/YYYY"
                        className="form-control d-block"
                        value={props?.value && dateFormat(props?.value)}
                        disabled
                    />
                ) : (
                    <Flatpickr
                        {...props}
                        options={{
                            ...props.options,
                            locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                        }}
                        type="text"
                        placeholder="DD/MM/YYYY"
                        className={`${className} form-control d-block datepicket`}
                    />
                )}
                <span className="input-group-text">
                    <span className="align-middle material-symbols-outlined fw-light fs-4">calendar_today</span>
                </span>
            </div>
            <ErrorMessage error={error} touched={touched} label={label} />
        </>
    )
}

export default DatePicker;