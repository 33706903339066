import React, { useEffect, useRef, useState } from 'react'
import { Button, Card, CardBody, CardFooter, CardHeader, CardImg, CardSubtitle, CardText, CardTitle, Col, Container, Modal, ModalBody, Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane } from 'reactstrap';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas'
import commonService from '../../../services/common.service';
import moment from 'moment';
import { getUserProfile } from '../../../helpers/functions_helper';
import Dropzone from 'react-dropzone';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIsAuthenticated } from "@azure/msal-react";

const AddSignature = ({
    moduleData,
    showlist = true,
    show = true, // hide button signature
    showAdd, // hide add button 
    isDisabled = false,  // disable the button
    hideSign = false, // disable sign
    handleSignatureData, // handle signa date
    className,
    signatureAdded = () => { },
    getList = true,
}: any) => {

    let navigate = useNavigate();
    const location = useLocation();
    const isAzureAuthenticated = useIsAuthenticated();
    const [activeTab, setActiveTab] = useState(1);
    const [disable, setDisable] = useState(true);
    const [signature, setSignature] = useState("Signature");
    const [activeSign, setActiveSign] = useState();
    const [signatureList, setSignatureList] = useState([]);
    const [signaturestyle, setSignatureStyle] = useState("sign_1");
    const [signData, setSignData] = useState<any>([]);
    const [sectionData, setSectionData] = useState<any>([]);
    const [cardset, setCardset] = useState(false);
    const [isSignEnable, setIsSignEnable] = useState(true);
    const [userSignData, setUserSignData] = useState<any>([]);
    const [intialSignData, setInitialSignData] = useState<any>([]);
    const [signType, setSignType] = useState(0);
    const [openAuth, setOpenAuth] = useState(false);


    const toastId = React.useRef(null) as any;
    const { t } = useTranslation();
    const sigCanvas = useRef({}) as React.MutableRefObject<any>;
    const userProfile = getUserProfile();

    useEffect(() => {
        setDisable(true);
        setActiveTab(1);
        setSignature("Signature");
        getSignature();
        if (moduleData.module_app_id != 1) {
            getSignatureUser();
        }
    }, []);
    const clear = () => {
        setDisable(true);
        sigCanvas.current?.clear();
    };

    const formatIntoPng = async () => {
        if (sigCanvas.current) {
            const dataURL = sigCanvas.current.toDataURL();
            AddSignature(1, dataURL);
        }
    };

    // const saveImage = async (dataURL: any) => {
    //     const data_url = dataURL.replace("data:png;base64,", "data:image/png;base64,");
    //     const res = await questionnaireService.uploadSignature({ data: data_url });
    //     if (res.status === "success") {
    //         toastId.current = toast(res.message, {
    //             type: toast.TYPE.SUCCESS,
    //             autoClose: 5000,
    //             isLoading: false,
    //             delay: 500,
    //         });
    //         AddSignature(1, res.data);
    //     } else {
    //         toastId.current = toast(res.message, {
    //             type: toast.TYPE.ERROR,
    //             autoClose: 5000,
    //             isLoading: false,
    //             delay: 500,
    //         });
    //     }
    // }

    const handleChange = (e: any) => {
        if (e.target.value == "") {
            setSignature("Signature");
        } else {
            setSignature(e.target.value);
        }
    }


    const AddSignature = async (type: any, signature: any) => {
        const request = {
            id: signID,
            module_app_id: moduleData.module_app_id,
            module_main_id: moduleData.module_main_id,
            module_sub_id: moduleData.module_sub_id,
            section_id: moduleData.section_id,
            type: type,
            sign_type: signType,
            signature: signature
        };
        const res = await commonService.uploadSignature(request);
        if (res?.data?.status == "success") {
            signatureAdded(signature, res?.data?.data);
            getSignature();
            setShowModal(false);
            setCardset(false);
            if (moduleData.module_app_id != "1") {
                uploadSIgnatureUser(type, signature);
            }
        }
    }


    const uploadSIgnatureUser = async (type: any, signature: any) => {
        const request = {
            module_app_id: 1,
            module_main_id: userProfile.USER_ID,
            module_sub_id: userProfile.USER_SUB_ID,
            section_id: 0,
            type: type,
            sign_type: signType,
            signature: signature
        };
        const res = await commonService.uploadSignature(request);
        if (res?.data?.status == "success") {
            getSignatureUser();
            // signatureAdded(signature);
            //console.log(res?.data?.status);
        }
    }


    const getSignature = async () => {
        if (getList) {
            const request = {
                module_app_id: moduleData.module_app_id,
                module_main_id: moduleData.module_main_id,
                module_sub_id: moduleData.module_sub_id,
                //section_id: moduleData.section_id,
            };
            const res = await commonService.getSignature(request);
            if (res?.data?.status == "success") {
                setSignatureList(res.data.data);
                if (res?.data?.data) {
                    if (res?.data?.data[0]) {
                        handleSignatureData(res?.data?.data[0]);
                    }

                    if (moduleData.section_id != 0) {
                        let data_singed_by_section = res?.data?.data.filter((dt: any) => dt.section_id == moduleData.section_id);
                        setSectionData(data_singed_by_section);
                    } else if (res?.data?.data[0]) {
                        let section_ar: any = [];
                        section_ar.push(res?.data?.data[0]);
                        setSectionData(section_ar);
                    }
                    let data_singed_by_user = res?.data?.data.filter((dt: any) => dt.created_by_id == userProfile.USER_ID);
                    setSignData(data_singed_by_user[0]);
                }
            } else if (res?.data?.status === 'error' && res?.data?.data && res?.data?.data?.ERROR_CODE == "CAN_VIEW") {
                return navigate("/404");
            } else {

            }
        }

        setLoading(false);
    }


    const getSignatureUser = async () => {
        const request = {
            module_app_id: 1,
            module_main_id: userProfile.USER_ID,
            module_sub_id: userProfile.USER_SUB_ID,
        };
        const res = await commonService.getSignature(request);
        if (res?.data?.status == "success") {
            setUserSignData(res.data.data);
            setInitialSignData(res.data.initial_data);
        }
    }



    const handleUploadDocument = async (files: any) => {
        await files.map(async (file: any) => {
            const request = {
                id: signID,
                module_app_id: moduleData.module_app_id,
                module_main_id: moduleData.module_main_id,
                module_sub_id: moduleData.module_sub_id,
                section_id: moduleData.section_id,
                type: 2,
                signature: file.file_name_source
            };
            const res = await commonService.uploadSignature(request);
            if (res?.data?.status == "success") {
                signatureAdded(request.signature);
                setShowModal(false);
                getSignature();
            }
        });
    }

    function clearCanvas() {
        let cnv = document.getElementById("canvas") as HTMLCanvasElement | null;
        if (cnv) {

            var ctx = cnv?.getContext('2d');     // gets reference to canvas context
            ctx?.beginPath();    // clear existing drawing paths
            ctx?.save();         // store the current transformation matrix

            // Use the identity matrix while clearing the canvas
            ctx?.setTransform(1, 0, 0, 1, 0, 0);
            ctx?.clearRect(0, 0, cnv.width, cnv.height);

            ctx?.restore();        // restore the transform

        }
    }


    const handleSignText = () => {
        clearCanvas();
        if (signature == "Signature" || signature == "") {
            toastId.current = toast(t("Please type your signature"), {
                type: toast.TYPE.ERROR,
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });
        } else {
            let canvas = document.getElementById("canvas") as HTMLCanvasElement | null;
            let context = canvas?.getContext("2d");
            if (context && canvas) {
                let width = context.measureText(signature).width;
                canvas.style.width = width + 'px';
                if (signaturestyle == "sign_1") {
                    context.font = "30px Brush Script MT";
                } else if (signaturestyle == "sign_2") {
                    context.font = "30px Copperplate, Papyrus, fantasy";
                }
                else if (signaturestyle == "sign_3") {
                    context.font = "30px Pacifico, cursive";
                }
                else if (signaturestyle == "sign_4") {
                    context.font = "30px Fredoka,fantasy, cursive";
                }
                //context.font = "30px Brush Script MT";
                context.fillStyle = "black";
                context.textAlign = "center";
                context.fillText(signature, canvas.width / 2, canvas.height / 2);
                AddSignature(3, context.canvas.toDataURL());
            }
        }
    }

    const [showModal, setShowModal] = useState<any>(show);
    const [signID, setSignId] = useState<any>("");
    const [loading, setLoading] = useState(true);
    const handleEditsign = (item: any) => {
        setShowModal(true);
        setSignId(item.id);
    }

    const handleAddsign = (item: any) => {
        setShowModal(true);
        setSignId("");
    }

    // const SignatureCard = () => {
    //     if (userSignData[0]?.created_by_id) {
    //         setCardset(true);
    //     } else {
    //         setShowModal(true);
    //     }
    // }

    useEffect(() => {
        if (signData?.created_by_id) {
            setIsSignEnable(false);
        } else {
            setIsSignEnable(true);
        }
    }, [signData])



    useEffect(() => {
        getSignature();
    }, [cardset])


    // useEffect(() => {
    //     if (userSignData?.created_by_id) {
    //         setCardset(true);
    //     } else {
    //         setCardset(false);
    //     }
    // }, [userSignData])


    const redirectSignin = () => {
        window.localStorage.clear();
        return navigate("/login", { state: { from: location } });
    }

    return (
        <React.Fragment>
            <Button color="primary" size="sm" onClick={() => isAzureAuthenticated ? setCardset(true) : setOpenAuth(true)} disabled={isDisabled}> <i className="fa-solid fa-sm fa-signature"></i>{t("Sign")}</Button>
            <Modal
                size="lg"
                isOpen={cardset}
                toggle={() => setCardset(!cardset)}
                backdrop="static"
                keyboard={false}
                autoFocus={false}
            >
                <div className="modal-header">
                    <h5 className="modal-title">{t("Add Signature")}</h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => setCardset(false)}
                        aria-label="Close"
                    ></button>
                </div>
                <ModalBody>
                    <Container fluid>
                        <Row>
                            {(userSignData.length > 0) &&
                                (
                                    <>
                                        {userSignData.map((data: any, idx: number) => (
                                            <Col xs={4} sm={4} style={{ 'cursor': 'pointer' }} onClick={() => AddSignature(1, userSignData[0]?.signature_image)}>
                                                <Card className="h-100 addsign">
                                                    <CardHeader>
                                                        <CardTitle className='align-center'>{t('Select Signature')}</CardTitle>
                                                    </CardHeader>
                                                    <CardImg top width="100%" src={data?.signature_image} alt="Card image cap" />
                                                    <CardBody>
                                                        <CardText>{t('Signed by')} {data?.user_first_name} {data?.user_last_name}</CardText>
                                                        <CardText>{moment(data?.created_at).format("DD/MM/Y HH:mm:ss")}</CardText>
                                                        <Button clasName="mt-auto justify-content-center" color="primary" size="md" title={t("Select Signature")}> {t("Select Signature")}</Button>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        ))}
                                    </>
                                )
                            }

                            {(intialSignData.length > 0) &&
                                (
                                    <>
                                        {intialSignData.map((data: any, idx: number) => (
                                            <Col xs={4} sm={4} style={{ 'cursor': 'pointer' }} onClick={() => AddSignature(1, userSignData[0]?.signature_image)}>
                                                <Card className="h-100 addsign">
                                                    <CardHeader>
                                                        <CardTitle className='align-center'>{t('Select Initial')}</CardTitle>
                                                    </CardHeader>
                                                    <CardImg top width="100%" src={data?.signature_image} alt="Card image cap" />
                                                    <CardBody>
                                                        <CardText>{t('Signed by')} {data?.user_first_name} {data?.user_last_name}</CardText>
                                                        <CardText>{moment(data?.created_at).format("DD/MM/Y HH:mm:ss")}</CardText>
                                                        <Button clasName="mt-auto justify-content-center" color="primary" size="md" title={t("Select initial")}> {t("Select initial")}</Button>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        ))}
                                    </>
                                )
                            }

                            <Col>
                                <Card className="h-100">
                                    <CardHeader>
                                        <CardTitle className='align-center'>{t('Add Signature')}</CardTitle>
                                    </CardHeader>
                                    <CardBody>
                                        <CardText>{t('Please click on add button to add new signature')}</CardText>
                                        <Row className='mt-3'>
                                            <Col xs={12} sm={12} md={12}>
                                                <Button clasName="mt-auto justify-content-center w-75" color="primary" size="md" title={t("Add Signature")} className='ms-1' onClick={() => { setShowModal(true); setCardset(false); setSignType(0); }}> <i className="fa-solid fa-lg fa-plus"></i>{t("Add signature")}</Button>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} className='mt-3'>
                                                <Button clasName="mt-auto justify-content-center w-75" color="primary" size="md" title={t("Add Signature")} className='ms-1' onClick={() => { setShowModal(true); setCardset(false); setSignType(1); }}> <i className="fa-solid fa-lg fa-plus"></i>{t("Add initial")}</Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>

            </Modal>
            <Modal
                size="lg"
                isOpen={showModal}
                toggle={() => setShowModal(!showModal)}
                backdrop="static"
                keyboard={false}
                autoFocus={false}
            >
                <div className="modal-header">
                    <h5 className="modal-title">{t("Add Signature")}</h5>
                    <button
                        type="button"
                        className="btn-close"
                        onClick={() => setShowModal(false)}
                        aria-label="Close"
                    ></button>
                </div>
                <ModalBody>
                    <Container fluid>
                        <Nav tabs>
                            <NavItem style={{ "display": "inline-flex" }}>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={activeTab === 1 ? "active" : "inactive"}
                                    onClick={() => {
                                        setActiveTab(1);
                                    }}>
                                    {t("Draw")}
                                </NavLink>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={activeTab === 2 ? "active" : "inactive"}
                                    onClick={() => {
                                        setActiveTab(2);
                                    }}>
                                    {t("Image")}
                                </NavLink>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={activeTab === 3 ? "active" : "inactive"}
                                    onClick={() => {
                                        setActiveTab(3);
                                    }}>
                                    {t("Type")}
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab} >
                            <TabPane tabId={1} className="border">
                                <Row>
                                    <Col sm="12">
                                        <SignatureCanvas penColor='black'
                                            ref={sigCanvas}
                                            canvasProps={{ width: 550, height: 250, className: 'sigCanvas' }}
                                            onEnd={() => setDisable(false)}
                                        />
                                    </Col>
                                    <Col sm="12">
                                        <h6 className='text-center'>{t("Sign here")}</h6>
                                    </Col>
                                    <CardFooter className="d-flex flex-wrap gap-2 bg-transparent justify-content-end">
                                        <Button color="danger" size="md" onClick={clear}
                                            className="btn-block">
                                            {t("Clear")}
                                        </Button>
                                        <Button color="primary" size="md" onClick={formatIntoPng}
                                            className="btn-block" disabled={disable}>
                                            {t("Done")}
                                        </Button>
                                    </CardFooter>
                                </Row>
                            </TabPane>
                            <TabPane tabId={2}>
                                <Row>
                                    <Card body>
                                        <CardBody>
                                            <Row>
                                                <Dropzone
                                                    onDrop={(acceptedFiles, fileRejections) => {
                                                        const reader = new FileReader();
                                                        reader.readAsDataURL(acceptedFiles[0]);
                                                        reader.onloadend = () => {
                                                            const base64data = reader.result;
                                                            AddSignature(1, base64data);
                                                        }
                                                    }}
                                                    multiple={false}
                                                    accept=".jpeg,.jpg,.png,.gif"
                                                    disabled={loading}>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <div className="dropzone">
                                                            <div className="dz-message needsclick mt-2" {...getRootProps()}>
                                                                <input
                                                                    {...getInputProps()}
                                                                    name="file_name"
                                                                    accept=".jpeg,.jpg,.png,.gif"
                                                                    disabled={loading}
                                                                />
                                                                <div className="mb-3">
                                                                    <i className="display-4 text-muted fa-solid fa-cloud-arrow-up" />
                                                                </div>
                                                                <h4>{t("Drop files here or click to upload.")}</h4>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Dropzone>
                                                {/* <Dropzone
                                                    moduleData={{
                                                        module_app_id: moduleData.module_app_id,
                                                        module_main_id: moduleData.module_main_id,
                                                        module_sub_id: moduleData.module_sub_id,
                                                        section_id: moduleData.section_id,
                                                        file_path: "questionnaire/sign",
                                                    }}
                                                    hideList={true}
                                                    fetchData={true}
                                                    isOpen={true}
                                                    enableExpiryDate={false}
                                                    onUpload={(files: any) => handleUploadDocument(files)}
                                                /> */}
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Row>
                            </TabPane>
                            <TabPane tabId={3}>
                                <Row className='sign-question_fonts'>
                                    <Col sm="12">
                                        <Card body>
                                            <Row>
                                                <Col sm={12} className="sign-question">
                                                    <input type="text" name="name" placeholder={t("Signature")}
                                                        className={`question ${signaturestyle}`} id="nme" required onChange={handleChange} />
                                                    <label htmlFor="nme"><span>{t("Signature")}</span></label>
                                                    <canvas id="canvas" width="200" height="200" style={{ "display": "none" }}></canvas>
                                                </Col>
                                                <Col sm="12">
                                                    <h6 className='text-left'>{t("Type here")}</h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={6} className="sign_1 mt-5 ml-2 mb-5">
                                                    <input type="radio" className='radio_font' name={signature} onClick={() => setSignatureStyle("sign_1")} />{signature}
                                                </Col>
                                                <Col sm={6} className="sign_2 mt-5 ml-2 mb-5">
                                                    <input type="radio" className='radio_font' name={signature} onClick={() => setSignatureStyle("sign_2")} />{signature}
                                                </Col>
                                                <Col sm={6} className="sign_3 mt-5 ml-2 mb-5">
                                                    <input type="radio" className='radio_font' name={signature} onClick={() => setSignatureStyle("sign_3")} />{signature}
                                                </Col>
                                                <Col sm={6} className="sign_4 mt-5 ml-2 mb-5">
                                                    <input type="radio" className='radio_font' name={signature} onClick={() => setSignatureStyle("sign_4")} />{signature}
                                                </Col>
                                            </Row>
                                            <CardFooter className="d-flex flex-wrap gap-2 bg-transparent justify-content-end">
                                                <Button color='primary' size='md' onClick={handleSignText} type="submit">{t("Save")}</Button>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </Container>
                </ModalBody>

            </Modal>
            <Modal
                size="md"
                isOpen={openAuth}
                toggle={() => setOpenAuth((state: boolean) => !state)}
                backdrop="static"
                keyboard={false}
                autoFocus={false}
                centered={false}
            >
                <ModalBody className=" bg-soft bg-danger text-danger font-size-17">
                    <p>{t("Document signature is not available now. Please login with active directory.")}</p>
                    <hr />
                    <div className="d-flex justify-content-between">
                        <Button color="primary" onClick={redirectSignin}><span>
                            <span className="align-middle me-1 material-symbols-outlined fw-light fs-4">
                                grid_view
                            </span>
                            <span>Azure AD</span>
                        </span></Button>
                        <Button color="danger" onClick={() => { setOpenAuth(false); }}>{t("Cancel")}</Button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment >
    )
}

export default AddSignature