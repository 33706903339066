import React, { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  Input,
  Button,
  FormFeedback,
  Alert,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMessage from "../../components/Form/ErrorMessage";
import logoDark from "../../assets/images/logos/tc-logo-red-block-with-name.png";
import authService from "../../services/auth.service";

import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";

import reCAPTCHA from "../../components/Common/reCAPTCHA";
import { useTranslation } from "react-i18next";
const recaptcha = new reCAPTCHA(process.env.REACT_APP_RECAPTCHA_SITE_KEY!, "login");

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email field is required.")
    .max(100, "Email may not be greater than 100 characters.")
    .email("Email must be a valid email address."),
  password: Yup.string()
    .required("Password field is required.")
    .min(8, "Password must be at least 8 characters.")
    .max(20, "Password may not be greater than 20 characters."),
});

const Login = (props: any) => {
  const { t } = useTranslation();
  document.title = t("Login") + " | " + process.env.REACT_APP_NAME;

  let navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { instance, accounts, inProgress } = useMsal();

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [azureLoading, setAzureLoading] = useState(false);
  const [azureDisabled, setAzureDisabled] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorData, setErrorData] = useState<any>({ data: {} });
  const [passwordShow, setPasswordShow] = useState(false);

  const handleLogin = async (values: {
    email: string;
    password: string;
    re_captcha_token: string;
  }) => {
    try {
      const response = await authService.login(values);
      if (response?.data?.status === "success" && response.data.accessToken) {
        localStorage.setItem("authUser", JSON.stringify(response.data));
        localStorage.setItem("I18N_LANGUAGE", response.data.language);
        localStorage.setItem("LANGUAGE_CODE", response?.data?.language_code);

        const prevLocation = location.state as any;
        if (prevLocation) {
          const prevPath: any = prevLocation?.from?.pathname;
          return navigate(prevPath);
        } else {
          return navigate("/dashboard");
        }
      } else if (response?.data?.status === "error") {
        setLoading(false);
        setAzureDisabled(false);
        setErrorMsg(response?.data?.message);
        setErrorData({ data: response?.data?.data });
      } else {
        setLoading(false);
        setAzureDisabled(false);
        setErrorMsg(response?.response?.data?.message);
      }
    } catch (err: any) {
      setLoading(false);
      setAzureDisabled(false);
    }
  };

  const handleSSO = (instance: any, loginType: string) => {
    const prevLocation = location.state as any;
    if (prevLocation) {
      const prevPath: any = prevLocation?.from?.pathname;
      // return navigate(prevPath);
      localStorage.setItem("PREV_PATH", prevPath);
    }

    if (loginType === "popup") {
      instance.loginPopup(loginRequest).catch((e: any) => {
        console.error(e);
      });
    }

    if (loginType === "redirect") {
      instance.loginRedirect(loginRequest).catch((e: any) => {
        console.log(e);
      });
    }
  };
  const handleAzureLogin = async (values: { email: string; accessToken: string }) => {
    try {
      setAzureLoading(true);
      setDisabled(true);
      const response = await authService.sso_login(values);
      if (response?.data?.status === "success" && response.data.accessToken) {
        localStorage.setItem("authUser", JSON.stringify(response.data));
        localStorage.setItem("I18N_LANGUAGE", response.data.language);
        localStorage.setItem("LANGUAGE_CODE", response?.data?.language_code);
        const prevPath = localStorage.getItem("PREV_PATH");

        // return navigate("/dashboard");
        // const prevLocation = location.state as any;
        if (prevPath) {
          // const prevPath: any = prevLocation?.from?.pathname;
          return navigate(prevPath);
        } else {
          return navigate("/dashboard");
        }
      } else if (response?.data?.status === "error") {
        setAzureLoading(false);
        setDisabled(false);
        setErrorMsg(response?.data?.message);
      } else {
        setAzureLoading(false);
        setDisabled(false);
        setErrorMsg(response?.response?.data?.message);
      }
    } catch (err: any) {
      setAzureLoading(false);
      setDisabled(false);
      setErrorMsg(err);
    }
  };
  const getAzureAccessToken = () => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(request)
      .then((response) => {
        localStorage.setItem("accessTokenSSO", response.accessToken);
        handleAzureLogin({
          email: response.account?.username || "",
          accessToken: response.accessToken,
        });
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(request);
        }
      });
  };

  const validation = useFormik({
    initialValues: {
      email: "",
      password: "",
      re_captcha_token: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setAzureDisabled(true);
      let response: any = await recaptcha.getToken();
      if (response[0].status === "success") {
        var newData: any = { ...values, re_captcha_token: response[0].token };
        handleLogin(newData);
      } else {
        setLoading(false);
        setAzureDisabled(false);
        setErrorMsg(
          t(
            "Could not connect to the reCAPTCHA service. Please check your internet connection and reload to get a reCAPTCHA challenge."
          )
        );
      }
    },
  });

  useEffect(() => {
    if (inProgress === InteractionStatus.None && searchParams?.get("session") !== "expired") {
      getAzureAccessToken();
    }
  }, [inProgress]);

  useEffect(() => {
    searchParams?.delete("session");
  }, [searchParams?.get("session")]);

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-white" style={{ borderBottom: "1px solid #c71e26" }}>
                  <Row>
                    <Col className="col-8">
                      <div className="p-4">
                        <h5 style={{ color: "#c71e26" }}>{t("Welcome Back")} !</h5>
                        <p style={{ color: "#c71e26" }}>
                          {t("Sign in to continue to")} {process.env.REACT_APP_NAME}
                        </p>
                      </div>
                    </Col>
                    <Col className="col-4 p-4 align-self-end">
                      <img src={logoDark} className="img-fluid" width={80} />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="p-2 mt-3">
                    {successMsg ? <Alert color="success">{successMsg}</Alert> : null}

                    {errorMsg ? (
                      <Alert color="danger">
                        {errorMsg}

                        {errorData.data
                          ? Object.keys(errorData.data).map((key) => (
                              <li color="danger" key={key}>
                                {errorData.data[key]}
                              </li>
                            ))
                          : null}
                      </Alert>
                    ) : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">{t("Email")}</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder={t("Enter email")}
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          disabled={loading || disabled}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        <ErrorMessage
                          error={validation.errors.email}
                          touched={validation.touched.email}
                          label={"Email"}
                        />
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">{t("Password")}</Label>
                        <div className="input-group">
                          <Input
                            name="password"
                            type={passwordShow ? "text" : "password"}
                            placeholder={t("Enter Password")}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            disabled={loading || disabled}
                            invalid={
                              validation.touched.password && validation.errors.password
                                ? true
                                : false
                            }
                          />
                          <span
                            className="input-group-text btn-outline-primary"
                            style={{
                              border: "1px solid #ced4da",
                              cursor: "pointer",
                            }}
                            title={t("Show / Hide password")}
                            onClick={() => setPasswordShow(!passwordShow)}
                          >
                            {!passwordShow ? (
                              <span className="align-middle material-symbols-outlined fw-light fs-4">
                                visibility
                              </span>
                            ) : (
                              <span className="align-middle material-symbols-outlined fw-light fs-4">
                                visibility_off
                              </span>
                            )}
                          </span>
                        </div>
                        <ErrorMessage
                          error={validation.errors.password}
                          touched={validation.touched.password}
                          label={"Password"}
                        />
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                          disabled={loading || disabled}
                        />
                        <label className="form-check-label" htmlFor="customControlInline">
                          {t("Remember me")}
                        </label>
                      </div>
                      <div className="mt-3 d-grid">
                        <Button
                          color="primary"
                          className="btn-block"
                          type="submit"
                          disabled={loading || disabled}
                        >
                          {loading ? (
                            <span>
                              <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                                hourglass_empty
                              </span>
                              <span>{t("Please wait...")}</span>
                            </span>
                          ) : (
                            <span>
                              <span className="align-middle me-1 material-symbols-outlined fw-light fs-4">
                                login
                              </span>
                              <span>{t("Login")}</span>
                            </span>
                          )}
                        </Button>
                      </div>
                    </Form>
                    <div className="mt-4 d-grid text-center">
                      <h5 className="font-size-14 mb-3">{t("Sign in with")}</h5>

                      <Button
                        color="primary"
                        className="btn-block"
                        disabled={azureLoading || azureDisabled}
                        onClick={() => handleSSO(instance, "redirect")}
                      >
                        {azureLoading ? (
                          <span>
                            <span className="align-middle me-1 material-symbols-outlined fw-light fs-4 fa-spin">
                              hourglass_empty
                            </span>
                            <span>{t("Please wait...")}</span>
                          </span>
                        ) : (
                          <span>
                            <span className="align-middle me-1 material-symbols-outlined fw-light fs-4">
                              grid_view
                            </span>
                            <span>Azure AD</span>
                          </span>
                        )}
                      </Button>
                    </div>

                    <div className="mt-4 text-center">
                      <Link to="/forgot-password" className="text-muted">
                        <span className="align-middle me-1 material-symbols-outlined fw-light fs-4">
                          lock_reset
                        </span>
                        {t("Forgot your password ?")}
                      </Link>
                    </div>
                  </div>
                </CardBody>
              </Card>

              <div className="mt-5 text-center">
                <p>
                  {t("Don't have an account ?")}
                  <Link to="/register-user" className="fw-medium text-primary ms-1">
                    {t("Signup Now")}
                  </Link>
                </p>
                <p>
                  © {new Date().getFullYear()}{" "}
                  <strong>Tax Consult HR Tax & International Mobility</strong>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
