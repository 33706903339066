import { Modal, ModalBody, ModalFooter } from "reactstrap";

const ArchiveModal = ({
  show,
  modalTitle,
  recordName,
  onArchiveClick,
  onCloseClick,
  is_company = false,
  is_archive = 0,
  loading = false,
}: ArchiveModal) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-header">
        <h5 className="modal-title">
          <span className="align-middle material-symbols-outlined fw-light fs-4 me-1 text-danger">
            emergency_home
          </span>
          {is_archive === 0 ? "Archive" : "Unarchive"} {modalTitle} ?
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={onCloseClick}
          aria-label="Close"
        ></button>
      </div>
      <ModalBody>
        <p>
          Do you want to {is_archive === 0 ? "archive" : "unarchive"}{" "}
          <b
            // style={{
            //   display: "block",
            //   overflow: "hidden",
            //   textOverflow: "ellipsis",
            // }}

            style={{
              wordBreak: "break-all",
            }}
          >
            {recordName} ?
          </b>
        </p>
        {is_company === true && is_archive === 0 && (
          <p>All the related users will be archive!</p>
        )}
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn btn-primary btn-md ms-2"
          onClick={onArchiveClick}
          disabled={loading}
        >
          {is_archive === 0 ? "Archive" : "Unarchive"}
        </button>
        <button
          type="button"
          className="btn btn-outline-danger btn-md ms-2"
          onClick={onCloseClick}
        >
          Cancel
        </button>
      </ModalFooter>
    </Modal>
  );
};

interface ArchiveModal {
  show: boolean;
  modalTitle: string;
  recordName: string;
  onCloseClick: React.MouseEventHandler<HTMLButtonElement>;
  onArchiveClick: React.MouseEventHandler<HTMLButtonElement>;
  is_company: boolean;
  is_archive: number;
  loading: boolean;
}

export default ArchiveModal;
