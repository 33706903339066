
import { useState, useEffect } from "react";
import Avatar from "react-avatar-editor";
import { useTranslation } from "react-i18next";
import { Col, Label, Input, Button } from "reactstrap";

type AvatarEditorProps = {
  profileImage: any;
  label?: string;
  profileReset?: boolean;
  onAvatarChange: any;
  width?: number;
  height?: number;
  borderRadius?: number;
}

const AvatarEditor = (props: AvatarEditorProps) => {
  const { t } = useTranslation();
  const { profileImage, label = t('Profile image'), profileReset = false, onAvatarChange, width = 160, height = 160, borderRadius = 160 } = props;

  const [avatarScale, setAvatarScale] = useState<number>(1.2);
  const [avatarPosition, setPosition] = useState({ x: 0.5, y: 0.5 });
  const [avatarImage, setAvatarImage] = useState<any>(null);
  const [newAvatarImage, setNewAvatarImage] = useState<string>("");
  const [profileImageUsed, setProfileImageUsed] = useState(false);

  const onSelectFile = (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      setAvatarImage(undefined);
      return;
    }
    setAvatarImage(e.target.files[0]);
    // e.target.value = null;
  };

  const handlePositionChange = (e: any) => {
    setPosition(e);
  };

  const setEditorRef = (editor: any) => {
    if (editor && avatarImage != null) {
      const img = editor.getImageScaledToCanvas().toDataURL();
      setNewAvatarImage(img);
    }
  };

  const handleImageChange = () => {
    onAvatarChange(newAvatarImage)
  }

  useEffect(() => {
    if (profileImage != null) {
      setAvatarScale(1);

      if (profileImageUsed === false) {
        setAvatarImage(profileImage);
        setProfileImageUsed(true);
      }
    }
    if (profileReset === true) {
      setAvatarImage(null);
      // @ts-ignore
      document.getElementById('avatar_image').value = null;
    }
  }, [profileImage, profileReset]);

  useEffect(() => {
    onAvatarChange(newAvatarImage)
  }, [newAvatarImage])

  return (
    <div className="mb-3">
      <Label className="form-label">{label} </Label>

      <div className="row">
        <Col className="col-12">
          <Avatar
            ref={(ref) => setEditorRef(ref)}
            image={avatarImage}
            width={width}
            height={height}
            border={0}
            borderRadius={borderRadius}
            color={[255, 255, 255, 0.6]} // RGBA
            scale={avatarScale}
            rotate={0}
            position={avatarPosition}
            onPositionChange={handlePositionChange}
            onImageChange={handleImageChange}
            style={{ background: "#ccc" }}
          />
        </Col>

        <Col className="col-12">
          <Input
            id="avatar_scale"
            name="avatar_scale"
            type="range"
            value={avatarScale}
            step="0.01"
            min="1"
            max="2"
            onChange={(e) => setAvatarScale(parseFloat(e.target.value))}
          />
        </Col>

        <Col className="col-12">
          <Label for="avatar_image" className="btn btn-sm btn-primary btn-label ">
            <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">
              upload
            </span>
            {t("Choose file")}
          </Label>
          <Input
            id="avatar_image"
            name="avatar_image"
            type="file"
            accept="image/*"
            hidden
            // value={avatarImage}
            onChange={onSelectFile}
          />
        </Col>
      </div>
    </div>
  );
};

export default AvatarEditor;
