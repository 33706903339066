import React, { FC, useState, useEffect } from 'react';
import { Col, Container, FormGroup, Label, OffcanvasBody, Row } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import moment from 'moment';
import { toast } from 'react-toastify';
import { invoiceStatuses } from '../../../common/staticdata';
import { formatCurrency } from '../../../utils/formats';
import disputesService from '../../../services/disputes.services';
import Button from '../../../components/Button/Button';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/Form/Input';
import { renderRowHTML } from '../../../utils/utils';
import e from 'express';
import Select from '../../../components/Form/Select/Select';



type EditInvoiceFormProps = {
    values: any;
    formValues: any;
    setFormValues: any;
    errors: any;
    onApply?: any;
    onClose: any;
    onChange?: any;
}


const EditInvoiceForm: FC<EditInvoiceFormProps> = ({ values, formValues, setFormValues, errors, onApply, onClose, onChange }) => {
    const toastId = React.useRef(null) as any;
    const { t } = useTranslation();

    const [invoice, setInvoice] = useState<any>(values);
    const [deletedItems, setDeletedItems] = useState<Array<ItemType>>([]);
    const [details, setDetails] = useState<Array<any>>(invoice.details || []);

    const handleDelete = (id: number, price: any, code: string, subtotal: any, qty: number) => {

        // setInvoice((s: any) => ({ ...s, details: s?.details?.filter((x: any) => x.id !== id) }))
        // const a = 
        setDeletedItems((items: Array<ItemType>) => [...items.filter((a: ItemType) => a.id !== id), { id: id, qty: qty, price: price, code: code }]);
    }

    const getOpts = (num: number) => {
        let a: Array<any> = [];
        for (let i = num; i >= 0; i--) {
            a = [...a, { label: i, value: i }]
        }
        return a;
    }

    useEffect(() => {
        if (deletedItems.length > 0) {
            let comment = t("Please remove ") + deletedItems.map((x: ItemType) => x.code).join(', ');
            const amt = deletedItems.map((x: ItemType) => x.price * x.qty).reduce((a, b) => a + b)
            setFormValues((state: any) => ({ ...state, amount: amt, comment: comment, deleted_items: deletedItems }));
        }
    }, [deletedItems])


    return (
        <React.Fragment>
            <Row>
                <Col>
                    {/* <div>{JSON.stringify(formValues)}</div> */}
                    <div className="mb-3">
                        <Label className="form-label">
                            {t("Date")}
                        </Label>
                        <Row>
                            <Col>
                                <div className="input-group is-invalid">
                                    <Flatpickr
                                        id="refund_date"
                                        name="refund_date"
                                        className="form-control d-block"
                                        type="text"
                                        placeholder="DD/MM/YYYY"
                                        value={formValues?.dispute_date}
                                        readOnly
                                        disabled={true}
                                        options={{
                                            altInput: true,
                                            altFormat: "d/m/Y",
                                            dateFormat: "Y-m-d",
                                            defaultDate: formValues?.refund_date,
                                            allowInput: true,
                                            onChange: function (
                                                selectedDates,
                                                dateStr,
                                                instance
                                            ) {
                                                setFormValues((state: any) => ({ ...state, refund_date: dateStr }))
                                            },
                                            locale: { firstDayOfWeek: 1 }
                                        }}
                                    />
                                    <span className="input-group-text">
                                        <span className="align-middle material-symbols-outlined fw-light fs-4">calendar_today</span>
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row>
                <table className='table table-striped'>
                    <thead className='thead-light'>
                        <tr>
                            <th>{t("Timesheet Name")}</th>
                            <th className='right-align'>{t("Qty")}</th>
                            <th className='right-align'>{t("Price")}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {invoice.details?.map((d: any) => (
                            <tr key={d.id} className={deletedItems.filter((x: ItemType) => x.qty === d.quantity).map((i: ItemType) => i.id).includes(d.id) ? 'deleted' : ''}>
                                <td> <h5 className='font-size-14'>{d.timesheet_code}</h5>
                                    <div className='text-muted'>{renderRowHTML(d.timesheet_name, '')}</div>
                                </td>
                                <td className='right-align font-size-14' width={100}>
                                    <Select
                                        name='qty'
                                        options={getOpts(d.quantity)}
                                        defaultValue={getOpts(d.quantity).find((a: any) => a.value = d.quantity)}
                                        // value={deletedItems.find((x: ItemType) => x.id === d.id) ? deletedItems.find((x: ItemType) => x.id === d.id)?.qty : d.quantity}
                                        onChange={(option: any) => {
                                            handleDelete(d.id, d.price, d.timesheet_code, d.subtotal, (d.quantity - option.value));
                                            setDetails((d: any) => d.map((a: any) => {
                                                if (a.id == d.id) {
                                                    return ({ ...a, quantity: option.value })
                                                } else {
                                                    return a;
                                                }
                                            }))
                                        }}
                                    />
                                </td>
                                <td className='right-align font-size-14'> {formatCurrency(d.price * (d.quantity - (formValues?.deleted_items?.find((x: ItemType) => x.id === d.id)?.qty || 0)))}</td>
                                <th>
                                    {deletedItems.includes(d.id) ? "" :
                                        <Button color='danger' size='sm' outline onClick={() => handleDelete(d.id, d.price, d.timesheet_code, d.subtotal, d.quantity)}>
                                            <i className='fa fa-trash'></i>
                                        </Button>
                                    }
                                </th>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Row>
            <Row>
                <Col>
                    <div className="mb-3">
                        <Input
                            id={`amount`}
                            name='amount'
                            placeholder={'100'}
                            label={t("Amount")}
                            required
                            readOnly
                            touched={!!errors?.amount}
                            error={errors?.amount}
                            className='rightAlign'
                            value={formValues?.amount}
                            onChange={(e) => {
                                setFormValues((state: any) => ({ ...state, amount: e.target.value }))
                            }}
                        />
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <div className="mb-3">
                        <Input
                            id={`comment`}
                            name='comment'
                            type='textarea'
                            placeholder={t('Enter reason for raising the dispute here')}
                            label={t("Comment")}
                            required
                            touched={!!errors?.comment}
                            error={errors?.comment}
                            className=''
                            value={formValues?.comment || ''}
                            onChange={(e) => {
                                setFormValues((state: any) => ({ ...state, comment: e.target.value }))
                            }}
                        />
                    </div>

                </Col>
            </Row>
        </React.Fragment >
    )
}

export default EditInvoiceForm;