import {
  GET_INBOX_MAILS_SUCCESS,
  GET_INBOX_MAILS_FAIL,

  ADD_INBOX_MAIL_SUCCESS,
  ADD_INBOX_MAIL_FAIL,

  DELETE_INBOX_MAIL_SUCCESS,
  DELETE_INBOX_MAIL_FAIL,

  GET_STARRED_MAILS_SUCCESS,
  GET_STARRED_MAILS_FAIL,

  GET_IMPORTANT_MAILS_SUCCESS,
  GET_IMPORTANT_MAILS_FAIL,

  GET_SENT_MAILS_SUCCESS,
  GET_SENT_MAILS_FAIL,

  GET_DRAFT_MAILS_SUCCESS,
  GET_DRAFT_MAILS_FAIL,

  GET_TRASH_MAILS_SUCCESS,
  GET_TRASH_MAILS_FAIL,

  IS_EMAIL_DIALOG_BOX_OPEN,
  GET_DRAFT_EMAIL_DATA,

  GET_FOLDER_LIST_SUCCESS,
  GET_FOLDER_LIST_FAIL,

  SET_READ_UNREAD_SUCCESS,
  SET_READ_UNREAD_FAIL,

  GET_FOLDER_MESSAGE_SUCCESS,
  GET_FOLDER_MESSAGE_FAIL,
  SET_DATA_TABLE_ID,
  SET_DRAFT_MAIL,
  CLEAR_ERROR_DATA
} from "./actionTypes"

const INIT_STATE = {
  inboxmails: {},
  starredmails: {},
  importantmails: {},
  draftmails: {},
  sentmails: {},
  trashmails: {},
  folderlist: { value: [] },
  draft: {},
  error: {},
  message: [],
  data_table_id: '',
  draftMailForTask: null,
  email_dialogbox_operate: { operate: 0 }, //0 for close clear, 1 for open with data, 2 for minimize with data , 3 maximum with data ,4 full screen with data ,5 close clear exit email dialog box
  loading: false,

}

const Mails = (state = INIT_STATE, action: any) => {
  switch (action.type) {

    case GET_INBOX_MAILS_SUCCESS:
      return {
        ...state,
        inboxmails: action.payload,
        error:{}
      }

    case GET_INBOX_MAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_INBOX_MAIL_SUCCESS:
      return {
        ...state,
        inboxmails: action.payload,
      }

    case GET_FOLDER_LIST_SUCCESS:
      return {
        ...state,
        folderlist: action.payload,
      }

    case GET_FOLDER_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }


    case ADD_INBOX_MAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_INBOX_MAIL_SUCCESS:
      return {
        ...state,
        inboxmails: state.inboxmails
      }

    case DELETE_INBOX_MAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_STARRED_MAILS_SUCCESS:
      return {
        ...state,
        starredmails: action.payload,
      }

    case GET_STARRED_MAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      }



    case GET_IMPORTANT_MAILS_SUCCESS:
      return {
        ...state,
        importantmails: action.payload,
      }

    case GET_IMPORTANT_MAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_TRASH_MAILS_SUCCESS:
      return {
        ...state,
        trashmails: action.payload,
      }

    case GET_TRASH_MAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_DRAFT_MAILS_SUCCESS:
      return {
        ...state,
        draftmails: action.payload,
      }

    case GET_DRAFT_MAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_SENT_MAILS_SUCCESS:
      return {
        ...state,
        sentmails: action.payload,
      }

    case GET_SENT_MAILS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case SET_READ_UNREAD_SUCCESS:
      return {
        ...state,
        sentmails: action.payload,
      }

    case SET_READ_UNREAD_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case IS_EMAIL_DIALOG_BOX_OPEN:
      return {
        ...state,
        email_dialogbox_operate: action.payload
      }

    case GET_DRAFT_EMAIL_DATA:
      return {
        ...state,
        draft: action.payload
      }

    case GET_FOLDER_MESSAGE_SUCCESS:
      return {
        ...state,
        message: action.payload,
      }

    case GET_FOLDER_MESSAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        message:{}
      }

    case SET_DATA_TABLE_ID:
      return {
        ...state,
        data_table_id: action.payload
      }

    case SET_DRAFT_MAIL:
      return {
        ...state,
        draftMailForTask: action.payload
      }
    
    case CLEAR_ERROR_DATA:
      return{
        ...state,
        error:{}
      }

    default:
      return state
  }
}

export default Mails