import { takeEvery, call, put } from "redux-saga/effects";
import saltEdgeService from "../../services/saltedge.service";
import { AnyAction } from "redux";

// Tasks Redux States
import {
  GET_PROVIDERS,
  GET_CONNECTIONS,
} from "./actionTypes";

import {
  getProvidersSuccess,
  getProvidersError,
  getConnectionsSuccess,
  getConnectionsError,
} from "./actions";

function* getProviders({ payload: { data, history } }: AnyAction): any {
  try {
    const response = yield call(saltEdgeService.getProviders, data);
    if (response.data && response.data.status === "success") {
      yield put(getProvidersSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getProvidersError(response.data));
    } else {
      yield put(getProvidersError(response));
    }
  } catch (error: any) {
    yield put(getProvidersError(error));
  }
}

function* getConnections({ payload: { data, history } }: AnyAction): any {
  try {
    const response = yield call(saltEdgeService.getConnections, data);
    if (response.data && response.data.status === "success") {
      yield put(getConnectionsSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getConnectionsError(response.data));
    } else {
      yield put(getConnectionsError(response));
    }
  } catch (error: any) {
    yield put(getConnectionsError(error));
  }
}

function* providersSaga(): any {
  yield takeEvery(GET_PROVIDERS, getProviders);
  yield takeEvery(GET_CONNECTIONS, getConnections);
}

export default providersSaga;
