
import { memo } from 'react';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Button } from 'reactstrap';
import './style.scss';
import { useTranslation } from 'react-i18next';
const ContactPerson = (props: any) => {
    const { contactPerson, isPresentData, handleUser, handleCompany } = props;
    const {t}=useTranslation();
    if (isPresentData)
        return (
            <Accordion open={['1', '2']}>
                <AccordionItem>
                    <AccordionHeader targetId="1" className='bg-light accordion-item-t'><span className="material-symbols-outlined fw-light">
                        person
                    </span>{t("Contact Details")}</AccordionHeader>
                    <AccordionBody accordionId="1">
                        <div className='d-flex'>
                            <div className="me-3">
                                <div className="avatar-sm me-3 mx-lg-auto mb-2 float-start float-lg-none rounded-circle">
                                    <span className="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-16">{`${contactPerson?.first_name?.substring(0, 1)}`}</span>
                                </div>
                            </div>
                            <div className='flex-grow-1'>
                                <div className='d-flex'>
                                    <div className='flex-grow-1'>
                                        <div className="text-muted">
                                            <h5 className="mb-1">{`${contactPerson?.first_name} ${contactPerson?.last_name}`}</h5>
                                            {(contactPerson?.title && contactPerson?.title !== null) ? <p className="mb-0">{`${contactPerson?.title}`}</p> : null}
                                            {(contactPerson?.company_name && contactPerson?.company !== 0) ? <p className="mb-0">{`${contactPerson?.company_name}`}</p> : null}
                                        </div>
                                    </div>
                                    {(contactPerson?.company_name && contactPerson?.company !== 0) ? <div className='ms-2'><Button size="sm" className="btn-primary" outline color='link' onClick={() => handleUser('edit')}>{t("Edit")}</Button>
                                    </div> : null}
                                </div>
                            </div>
                        </div>
                    </AccordionBody>
                </AccordionItem>
                <AccordionItem>
                    <AccordionHeader targetId="2" className='bg-light accordion-item-t'><span className="material-symbols-outlined fw-light">
                        apartment
                    </span>{t("Company")}</AccordionHeader>
                    <AccordionBody accordionId="2">
                        {(contactPerson?.company_name && contactPerson?.company !== 0) ? <div className='flex-grow-1'>
                            <div className='d-flex'>
                                <div className='flex-grow-1'>
                                    <div className="text-muted">
                                        <h5 className="mb-1">{`${contactPerson?.company_name}`}</h5>
                                    </div>
                                </div>
                                <div className='ms-2'><Button size="sm" className="btn-primary" outline color='link' onClick={() => handleCompany('edit')}>{t("Edit")}</Button></div>
                            </div>
                        </div> : <div className='d-flex justify-content-center'>
                            <Button className="btn btn-primary btn-sm waves-effect waves-light btn-label" onClick={() => handleUser('new')}>
                                <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">person</span>{t("Create user")}
                            </Button>
                        </div>}
                    </AccordionBody>
                </AccordionItem>
            </Accordion >
        )
    else
        return <Accordion open={['1', '2']}>
            <AccordionItem>
                <AccordionHeader targetId="1" className='bg-light accordion-item-t'>Contact Details</AccordionHeader>
                <AccordionBody accordionId="1">
                    <div className='d-flex justify-content-center'>
                        <Button className="btn btn-primary btn-sm waves-effect waves-light btn-label" onClick={() => handleUser('new')}>
                            <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">person</span> Create user
                        </Button>
                    </div>
                </AccordionBody>
            </AccordionItem>
            <AccordionItem>
                <AccordionHeader targetId="2" className='bg-light accordion-item-t'>Company</AccordionHeader>
                <AccordionBody accordionId="2">
                    <div className='d-flex justify-content-center'>
                        <Button className="btn btn-primary btn-sm waves-effect waves-light btn-label" onClick={() => handleCompany('new')}>
                            <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">apartment</span> Create company
                        </Button>
                    </div>
                </AccordionBody>
            </AccordionItem>
        </Accordion>;
}

export default memo(ContactPerson)