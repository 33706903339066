import React, { FC, useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardSubtitle, CardTitle, Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import { toast } from "react-toastify";
import { getUserProfile, select2Styles } from "../../../helpers/functions_helper";
import { yearOptions } from "../../../helpers/functions_helper";
import { currentMonth, currentYear, options } from "../../../common/staticdata";
import { noData } from "../../../utils/data";
import LoadingSpinner from "../../../components/Common/Loader";
import usersService from "../../../services/users.service";
import GraphFilters from "./Filters";
import { serialize } from "../../../utils/utils";
import ChartFilters from "../../../components/Chart/Filters";
import Chart from "../../../components/Chart";

type ResidentVsNonResidentProps = {
    hideHeading?: boolean;
    filters?: any;
}
const ResidentVsNonResident: FC<ResidentVsNonResidentProps> = ({ hideHeading = false, filters = null }) => {

    const { t } = useTranslation();

    const userObj = getUserProfile();

    const inititalFilter = {
        week: null,
        quarter: null,
        month: null,
        year: currentYear,
        user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_ID : null
    };

    const [loading, setLoading] = useState<boolean>(true);
    const [query, setQuery] = useState<string | null>(serialize(inititalFilter));
    const [filter, setFilter] = useState<any>(inititalFilter);
    useEffect(() => {
        clearWorkAllocationFilter();
    }, [localStorage.getItem("I18N_LANGUAGE")]);

    const [dChart, setDChart] = useState<any>({
        options: {
            // colors: ['#564ab1', '#d92626'],
            plotOptions: {
                pie: {
                    donut: {
                        size: '50%',
                    },

                },
            },
            labels: [t("Active"), t("Archive")],
            legend: {
                show: true,
                position: 'bottom',

            },
            noData: noData
        },
        series: [],

    })

    const getChartData = async () => {
        setLoading(true)
        try {
            const res = await usersService.activeVsNonActive(query);
            if (res.data.status === 'success') {
                setDChart((state: any) => ({
                    ...state,
                    series: res.data.data.map((i: any) => i.count),
                }));
                setLoading(false)
            }
        } catch (error: any) {
            toast(error)
        }
    }


    const clearWorkAllocationFilter = () => {
        setFilter(inititalFilter);
        setDChart((state: any) => ({
            ...state,
            options: {
                ...state.options,
                labels: [t("Active"), t("Archive")],
            }
        }));
    }

    useEffect(() => {
        loading && getChartData();
    }, [loading])

    useEffect(() => {
        setQuery(serialize(filter));
        setLoading(true);
    }, [filter])

    useEffect(() => {
        filters && setFilter(filters)
    }, [filters])

    return (
        <div style={{ height: '370px' }}>
            <div className="d-sm-flex flex-wrap">
                <CardTitle className="mb-3">{!hideHeading && t("Active vs Archive")}</CardTitle>
                <div className="ms-auto">
                    <ChartFilters
                        filter={filter}
                        setFilter={setFilter}
                        clearFilter={clearWorkAllocationFilter}
                    />
                </div>
            </div>
            {loading ? <LoadingSpinner height={335} /> : <Chart options={dChart.options} series={dChart.series} type="pie" height={335} />}
        </div>

    )
}

export default ResidentVsNonResident;