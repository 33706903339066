import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import ReactApexChart from "react-apexcharts";
import { noData } from '../../../utils/data';
import LoadingSpinner from '../../../components/Common/Loader';
import { serialize } from '../../../utils/utils';
//import GraphFilters from './Filters';
// import GraphFilters from '../../../components/Common/GraphFilters';
import { getUserProfile } from '../../../helpers/functions_helper';
import questionnaireService from '../../../services/questionnaire.service';
import { currentMonth, currentYear } from '../../../common/staticdata';
import ChartFilters from '../../../components/Chart/Filters';
import Chart from '../../../components/Chart';

interface FeeVsServiceChargeProps extends CartProps {
    filters?: any;
}

const Status: FC<FeeVsServiceChargeProps> = ({ hideHeading = false, filters = null }) => {

    const { t } = useTranslation();

    const userObj = getUserProfile();

    const inititalFilter = {
        week: null,
        quarter: null,
        month: currentMonth,
        year: currentYear,
        user_id: !(userObj.IS_PARTNER && userObj.IS_SUPER_ADMIN) ? userObj.USER_ID : null
    };

    const [loading, setLoading] = useState<boolean>(true);
    const [query, setQuery] = useState<string | null>(serialize(inititalFilter));
    const [filter, setFilter] = useState<any>(inititalFilter);

    useEffect(() => {
        clearWorkAllocationFilter();
    }, [localStorage.getItem("I18N_LANGUAGE")]);

    const [data, setData] = useState<any>({
        series: [],
        options: {
            chart: {
                width: 380,
                type: 'donut',
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        show: true,
                        position: 'left'
                    }
                }
            }],
            // stroke: {
            //     colors: ['#333'],
            //     width: 1
            // },
            noData: noData,
        },
    })

    const getChartData = async () => {
        const result = await questionnaireService.getAnalytics(query);
        if (result.status === 'success') {
            setData((data: any) => ({
                ...data,
                series: result?.yaxis[0] === 0 && result?.yaxis[1] === 0 ? [] : result?.yaxis,
                options: {
                    ...data.optitotalons,
                    labels: [t('Total Sent'), t('Total Finalized')],
                }
            }))
        }
        setLoading(false);
    }

    const clearWorkAllocationFilter = () => {
        setFilter(inititalFilter);
        setData((state: any) => ({
            ...state,
            options: {
                ...state.options,
                labels: [t('Total Sent'), t('Total Finalized')],
            }
        }));
    }

    useEffect(() => {
        loading && getChartData();
    }, [loading])

    useEffect(() => {
        setQuery(serialize(filter));
        setLoading(true);
    }, [filter])

    useEffect(() => {
        filters && setFilter(filters)
    }, [filters])

    return (
        <>
            <div className="d-sm-flex flex-wrap">
                <h4 className="card-title mb-4">{!hideHeading && t("Total Sent Vs Total Finalized")}</h4>
                <div className="ms-auto">
                    <ChartFilters
                        filter={filter}
                        setFilter={setFilter}
                        clearFilter={clearWorkAllocationFilter}
                    />
                </div>
            </div>
            <div className="clearfix"></div>
            {loading ? <LoadingSpinner height={380} /> : <Chart options={data.options} series={data.series} type="donut" height={380} />}
        </>
    )
}

export default Status;