import React, { FC, useEffect, useState } from 'react'
import { Button, Col, Input, InputGroup, Label, Row } from 'reactstrap';
import RadioInput from './RadioInput';
import Textfield from './Textfield';
import Flatpickr from "react-flatpickr";
import CheckboxInput from './CheckboxInput';
import CheckboxMultiInput from './CheckboxMultiInput';
import Dropzone from "../../components/Common/Dropzone";
import MultiInput from './MultiInput';
import InputGroupField from './InputGroupField';
import Select from "react-select";
import commonService from '../../services/common.service';
import "../Questionnaire/style.scss";
import fileDownload from "js-file-download";
import axios from "axios";
import { civil_status_master, genderOptions, languageOptions } from '../../common/staticdata';
import { getUserProfile, handleAddress, onlyPositiveValue, removeTags } from '../../helpers/functions_helper';
import AddSignature from '../../components/Common/AddSignature';
import WorkingCalender from './WorkingCalender';
import { useTranslation } from 'react-i18next';
import DatePicker from '../../components/Form/DatePicker/DatePicker';
const QuestionView = (props: any) => {
    const { t } = useTranslation();
    let {
        questionrow,
        input_type,
        field_width,
        question,
        reference_name,
        placeholder,
        user_data_answer,
        answer_option_list,
        questionnaireID,
        questionnaireSubID,
        section_id,
        countryOptions,
        handleChange,
        handleKeyDown,
        handleChangeMulti,
        handleMultiInputChange,
        handleChangeMulticheckbox,
        handleChangeCountry,
        handlecalender,
        handleChangecheck,
        adminview,
        multi_input_data,
        answerOnly,
        activeTabnames,
        required,
        mapped_dates,
        showSign,
        year,
        user_id,
        setUserAddress,
        setCompanyAddress,
        setCompanyInvAddress,
        setactiveTab,
        activeNextTab,
        activeTab,
        onSubmitClose,
        language = "English",
    } = props;
    const [activeTabs, setActiveTabs] = useState(activeTabnames);
    const [signatureShow, setSignatureShow] = useState(false);
    let mandatorySymbol = false;
    const textareaRef = React.useRef<any>(null);
    const [value, setValue] = React.useState("");
    const MIN_TEXTAREA_HEIGHT = 32;
    const userProfile = getUserProfile();
    const handleChangeTextarea = (e: any) => {
        setValue(e.target.value);
    };

    useEffect(() => {
        // Reset height - important to shrink on delete
        if (textareaRef.current != null) {
            textareaRef.current.style.height = "inherit";
            // Set height
            textareaRef.current.style.height = `${Math.max(
                textareaRef.current.scrollHeight,
                MIN_TEXTAREA_HEIGHT
            )}px`;
        }
    }, [value]);
    const setAttachmentMandatory = (count: any) => {
        if (reference_name) {
            // handleChangeCountry(reference_name, count);
        }
    }

    const handleAddressChange = async (data: any) => {
        const addressData = await handleAddress(data);
        if (addressData && questionrow.map_field_name == "address" && questionrow.map_field_type == "user") {
            setUserAddress(addressData);
        }
        else if (addressData && questionrow.map_field_name == "company_address" && questionrow.map_field_type == "company") {
            setCompanyAddress(addressData);
        }
        else if (addressData && questionrow.map_field_name == "inv_company_address" && questionrow.map_field_type == "company") {
            setCompanyInvAddress(addressData);
        }
        else {
            handleChangeCountry(questionrow.reference_name, addressData?.address);
        }
    }


    if ((questionrow.user_data_answer == null || questionrow.user_data_answer == "") && (questionrow.required_field && required)) {
        mandatorySymbol = true;
    } else if ((questionrow.user_data_answer == null || questionrow.user_data_answer == undefined || questionrow.user_data_answer == "" || questionrow.user_data_answer == 0) && (questionrow.required_field && required) && questionrow.input_type == 8) {
        mandatorySymbol = true;
    }

    function renderRowHTML(rowContent: any) {
        if (mandatorySymbol) {
            rowContent = rowContent + '<span class="text-warning is_required invalid-question-label">*</span>';
            return <span className='text-asstrick' dangerouslySetInnerHTML={{ __html: rowContent }} />;
        }
        else if (questionrow.required_field) {
            rowContent = rowContent + '<span class="text-warning is_required">*</span>';
            return <span className='text-asstrick' dangerouslySetInnerHTML={{ __html: rowContent }} />;
        } else {
            return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
        }

    }
    function htmlDecode(input: any) {
        var doc = new DOMParser().parseFromString(input, "text/html");
        return doc.documentElement.textContent;
    }

    let option_ar: any = [
        {
            value: 0,
            label: `${t('Please select...')}`,
        }
    ];
    if (answer_option_list && answer_option_list.length > 0) {
        Object.values(answer_option_list).forEach((option: any) => {
            option_ar.push({
                'label': option,
                'value': option
            })
        })
    }

    // Download attachment ------------------------------------------------------------
    const onClickDownload = (row: any) => {
        const SERVER_URL = process.env.REACT_APP_SERVER_URL;
        const AUTH_USER = localStorage.getItem("authUser");
        const ACCESS_TOKEN = JSON.parse(String(AUTH_USER));
        axios({
            url:
                SERVER_URL +
                "/masters/questionnaire-questions/download-attachment/" +
                row.question_id,
            method: "GET",
            headers: {
                "Content-Type": "application/" + row.file_extension,
                "Access-Control-Allow-Origin": "*",
                Authorization: "Bearer " + ACCESS_TOKEN.accessToken,
            },
            responseType: "blob",
        }).then((response: any) => {
            fileDownload(response.data, row.file_name + "." + row.file_extension);
        });
    };



    const handleSignatureData = (data: any) => {
        if (mapped_dates && mapped_dates.length > 0) {
            mapped_dates.forEach((value: any, key: any) => {
                if (value) {
                    handleChangeCountry(value.value, data?.created_at);
                }
            });
        }
    }


    const handleUploadPassport = async (files: any, questionrow: any) => {
        if (files.length > 0) {
            handleChangeCountry(questionrow.reference_name, files.length);
        }

        // if ((questionrow.map_field_name === "passport_file" || questionrow.map_field_name === "residence_card_file") && questionrow.map_field_type === "user") {
        //    await files.map(async (file: any) => {
        //         const req = { 
        //             module_app_id: 11,
        //             module_main_id: questionrow.user_id,
        //             module_sub_id: questionrow.user_id_code,
        //             section_id: 0,
        //             file_path: "user",
        //         }
        //         const res = await commonService.uploadAttachment(req);
        //         if(res.status =="success"){
        //             await files.map(async (file: any) => {
        //                 const req = {
        //                     'attachment_id': file?.id,
        //                     'document_type_id': 15,
        //                     'year': 2022,
        //                     'module_main_id': questionrow.user_id,
        //                     'document_name': file?.short_description ? file?.short_description : file?.file_name,
        //                     'expiry_date': file?.expiry_date,
        //                     'due_date': file?.due_date,
        //                 }
        //                 const res = await commonService.uploadDocument(req);
        //             });
        //         }
        //     });

        // }
    }

    const onClicknext = () => {
        setactiveTab(activeNextTab);
    }

    const onSaveClick = () => {
        onSubmitClose()
    }

    let matchedItem: any = '';
    if (input_type === 14 && countryOptions) {
        matchedItem = countryOptions.find((item: any) => item.value === user_data_answer);
    } else if (input_type === 22 && genderOptions) {
        matchedItem = genderOptions.find((item: any) => item.value === user_data_answer);
    } else if (input_type === 23 && languageOptions) {
        matchedItem = languageOptions.find((item: any) => item.value === user_data_answer);
    }
    else if (input_type === 25 && civil_status_master) {
        matchedItem = civil_status_master.find((item: any) => item.value === user_data_answer);
    }

    return (
        <React.Fragment>
            {
                !!(input_type === 1) &&
                <>
                    <Textfield label={renderRowHTML(question)} name={reference_name} placeholder={placeholder} value={user_data_answer != null ? user_data_answer : ''} handleChange={handleChange} handleKeyDown={handleKeyDown} isDisabled={!adminview} answer_only={answerOnly} field_width_input={questionrow.field_width_input} title={questionrow.tooltip ? questionrow.tooltip_description : ""}
                        enable_google={questionrow.enable_google_address} handleAddressChange={handleAddressChange} space_input={questionrow.space_input} space_input_value={questionrow.space_input_value} />
                    {
                        mandatorySymbol ?
                            <span className="mt-1 text-danger question invalid-question">This field is required.</span>
                            : null
                    }
                    {
                        questionrow.validation_error && questionrow.validation_error != "" ?
                            <span className="mt-1 text-danger question invalid-question">{questionrow.validation_error}</span>
                            : null
                    }
                </>
            }
            {
                !!(input_type === 2) &&
                <>
                    <RadioInput label={renderRowHTML(question)} name={reference_name} handleChangeMulti={handleChangeMulti} value={user_data_answer ? user_data_answer : ''} isDisabled={!adminview} answer_only={answerOnly} field_width_input={questionrow.field_width_input} title={questionrow.tooltip ? questionrow.tooltip_description : ""} space_input={questionrow.space_input} space_input_value={questionrow.space_input_value} />
                    {
                        mandatorySymbol ?
                            <>
                                <span className="mt-1 text-danger question invalid-question">This field is required.</span>
                            </>
                            : null
                    }
                    {
                        questionrow.validation_error && questionrow.validation_error != "" ?
                            <span className="mt-1 text-danger question invalid-question">{questionrow.validation_error}</span>
                            : null
                    }
                </>
            }
            {
                !!(input_type === 3) &&
                <>
                    <CheckboxInput label={renderRowHTML(question)} options={answer_option_list} name={reference_name} handleChangeMulti={handleChangeMulti} value={user_data_answer ? user_data_answer : ''} fullwidth={field_width}
                        isDisabled={!adminview} answer_only={answerOnly} field_width_input={questionrow.field_width_input}
                        title={questionrow.tooltip ? questionrow.tooltip_description : ""}
                        space_input={questionrow.space_input} space_input_value={questionrow.space_input_value} />
                    {
                        mandatorySymbol ?
                            <span className="mt-1 text-danger question invalid-question">This field is required.</span>
                            : null
                    }
                    {
                        questionrow.validation_error && questionrow.validation_error != "" ?
                            <span className="mt-1 text-danger question invalid-question">{questionrow.validation_error}</span>
                            : null
                    }
                </>
            }
            {
                !!(input_type === 4) &&
                <>
                    <Row className="mb-3 mt-3">
                        <Label className='questionview'>{renderRowHTML(question)}
                            {!!questionrow.tooltip && (
                                <span className='tooltip-txt'>
                                    < i className="fa-regular fas fa-info-circle"></i><span className="tooltiptext">{questionrow.tooltip_description}</span>
                                </span>
                            )}
                        </Label>
                        <div className={(questionrow.space_input && questionrow.space_input_value && !answerOnly) ? `${questionrow.space_input}-${questionrow.space_input_value}` : ''}>
                            <InputGroup className={questionrow.field_width_input ? `w-${questionrow.field_width_input} float-start` : 'float-start'}>

                                <DatePicker
                                    id={reference_name}
                                    name={reference_name}
                                    className="form-control"
                                    type="text"
                                    placeholder="DD/MM/YYYY"
                                    options={{
                                        altInput: true,
                                        altFormat: "d/m/Y",
                                        dateFormat: "Y-m-d",
                                        minDate: questionrow.min_date,
                                        maxDate: questionrow.max_date,
                                        defaultDate: (parseInt(year) - 1) + "/01/01",
                                        allowInput: true,
                                        onChange: function (selectedDates, dateStr, instance) {
                                            handlecalender(reference_name, dateStr);
                                        },
                                        onClose: function (selectedDates, dateStr, instance) {
                                            handlecalender(reference_name, dateStr);
                                        },
                                        locale: { firstDayOfWeek: 1 }
                                    }}
                                    value={user_data_answer ? user_data_answer : null}
                                    disabled={!adminview}
                                />
                            </InputGroup>
                        </div>
                        {
                            mandatorySymbol ?
                                <span className="mt-1 text-danger question invalid-question">This field is required.</span>
                                : null
                        }
                        {
                            questionrow.validation_error && questionrow.validation_error != "" ?
                                <span className="mt-1 text-danger question invalid-question">{questionrow.validation_error}</span>
                                : null
                        }
                    </Row>
                </>
            }
            {
                !!(input_type === 5) &&
                <>
                    <div className="mb-3 mt-3">
                        <Label className="form-label questionview">
                            {renderRowHTML(question)}
                            {!!questionrow.tooltip && (
                                <span className='tooltip-txt'>
                                    < i className="fa-regular fas fa-info-circle"></i><span className="tooltiptext">{questionrow.tooltip_description}</span>
                                </span>
                            )}
                        </Label>
                        {!answerOnly ?
                            <div className={(questionrow.space_input && questionrow.space_input_value) ? `${questionrow.space_input}-${questionrow.space_input_value}` : ''}>
                                <Select
                                    placeholder={t('Please select')}
                                    id="country"
                                    name={reference_name}
                                    className={questionrow.field_width_input ? `w-${questionrow.field_width_input}` : ''}
                                    options={option_ar}
                                    value={
                                        user_data_answer
                                            ? option_ar.find(
                                                (option: any) =>
                                                    option.value == user_data_answer
                                            )
                                            : ""
                                    }
                                    isSearchable={true}
                                    onChange={(e: any) => {
                                        handleChangeCountry(reference_name, e.value);
                                    }}
                                    isDisabled={!adminview}
                                />
                            </div>
                            :
                            <Input
                                type="text"
                                name={reference_name}
                                className={questionrow.field_width_input ? `w-${questionrow.field_width_input}` : ''}
                                id="basicpill-lastname-input2"
                                value={user_data_answer ? user_data_answer : ''}
                                disabled={!adminview}
                            />
                        }
                    </div>
                    {
                        mandatorySymbol ?
                            <span className="mt-1 text-danger question invalid-question">This field is required.</span>
                            : null
                    }
                    {
                        questionrow.validation_error && questionrow.validation_error != "" ?
                            <span className="mt-1 text-danger question invalid-question">{questionrow.validation_error}</span>
                            : null
                    }
                </>
            }

            {
                !!(input_type === 6) &&
                <>
                    <CheckboxMultiInput label={renderRowHTML(question)} options={answer_option_list} name={reference_name} handleChangeMulticheckbox={(e: any) => handleChangeMulticheckbox(e, user_data_answer)} value={user_data_answer ? user_data_answer : ''} isMulti fullwidth={field_width}
                        isDisabled={!adminview} answer_only={answerOnly} field_width_input={questionrow.field_width_input}
                        title={questionrow.tooltip ? questionrow.tooltip_description : ""} space_input={questionrow.space_input} space_input_value={questionrow.space_input_value} />
                    {
                        mandatorySymbol ?
                            <span className="mt-1 text-danger question invalid-question">This field is required.</span>
                            : null
                    }
                    {
                        questionrow.validation_error && questionrow.validation_error != "" ?
                            <span className="mt-1 text-danger question invalid-question">{questionrow.validation_error}</span>
                            : null
                    }
                </>
            }
            {
                !!(input_type === 7) &&
                <>
                    <Row className="mt-3">
                        <p className='text-left text-primary'>{question ? renderRowHTML(question) : ""}</p>
                    </Row>
                </>
            }
            {
                !!(input_type === 8) &&
                <>
                    <Row>

                        <div className="mt-3">
                            <Label className="form-label questionview">{renderRowHTML(question)}
                                {!!questionrow.tooltip ? (
                                    <span className='tooltip-txt'>
                                        < i className="fa-regular fas fa-info-circle"></i><span className="tooltiptext">{questionrow.tooltip_description}</span>
                                    </span>
                                ) : null}</Label>
                            <div className={(questionrow.space_input && questionrow.space_input_value) ? `${questionrow.space_input}-${questionrow.space_input_value}` : ''}>
                                <Dropzone
                                    moduleData={{
                                        module_app_id: 6,
                                        module_main_id: questionnaireID,
                                        module_sub_id: questionnaireSubID,
                                        section_id: section_id,
                                        file_path: "questionnaire",
                                    }}
                                    fetchData={activeTabnames ? (activeTabnames.includes(questionrow.tab_name) ? true : false) : true}
                                    isOpen={false}
                                    isDisabled={!adminview}
                                    fileTypes={((questionrow.file_types != "" && questionrow.file_types != null) ? questionrow.file_types : "")}
                                    isRequired={mandatorySymbol}
                                    ValidationError={(questionrow.pop_up_description != "" && questionrow.pop_up_description != null) ? questionrow.pop_up_description : removeTags(question)}
                                    setAttachmentMandatory={setAttachmentMandatory}
                                    onUpload={(files: any) => handleUploadPassport(files, questionrow)}
                                />
                            </div>
                        </div>
                    </Row>
                </>
            }
            {
                !!(input_type === 9) &&

                <MultiInput
                    label={question}
                    multi_input_data={multi_input_data}
                    reference_name={reference_name}
                    handleMultiInputChange={handleMultiInputChange}
                    user_data_answer={user_data_answer}
                    is_disabled={!adminview}
                />

            }
            {
                !!(input_type === 10) &&
                <>
                    <Row className="mt-3">
                        <b><p className='text-left '>{renderRowHTML(question)}</p></b>
                    </Row>
                </>
            }
            {
                !!(input_type === 11) &&

                <>
                    <InputGroupField label={renderRowHTML(question)} name={reference_name} placeholder={placeholder} value={user_data_answer ? user_data_answer : ''} handleChange={handleChange} handleKeyDown={handleKeyDown} type="amount"
                        isDisabled={!adminview} answer_only={answerOnly} field_width_input={questionrow.field_width_input}
                        space_input={questionrow.space_input} space_input_value={questionrow.space_input_value} />
                    {
                        mandatorySymbol ?
                            <span className="mt-1 text-danger question invalid-question">This field is required.</span>
                            : null
                    }
                    {
                        questionrow.validation_error && questionrow.validation_error != "" ?
                            <span className="mt-1 text-danger question invalid-question">{questionrow.validation_error}</span>
                            : null
                    }
                </>
            }
            {
                !!(input_type === 12) &&
                <>
                    <InputGroupField label={renderRowHTML(question)} name={reference_name} placeholder={placeholder} value={user_data_answer ? user_data_answer : ''} handleChange={handleChange} handleKeyDown={handleKeyDown} type="percentage"
                        isDisabled={!adminview} answer_only={answerOnly} field_width_input={questionrow.field_width_input}
                        space_input={questionrow.space_input} space_input_value={questionrow.space_input_value} />
                    {
                        mandatorySymbol ?
                            <span className="mt-1 text-danger question invalid-question">This field is required.</span>
                            : null
                    }
                    {
                        questionrow.validation_error && questionrow.validation_error != "" ?
                            <span className="mt-1 text-danger question invalid-question">{questionrow.validation_error}</span>
                            : null
                    }
                </>
            }
            {
                !!(input_type === 13) &&
                <>
                    <div className="mt-3">
                        <Label htmlFor="commentmessage-input questionview">
                            {renderRowHTML(question)}
                            {!!questionrow.tooltip && (
                                <span className='tooltip-txt'>
                                    < i className="fa-regular fas fa-info-circle"></i><span className="tooltiptext">{questionrow.tooltip_description}</span>
                                </span>
                            )}
                        </Label>
                        <div className={(questionrow.space_input && questionrow.space_input_value) ? `${questionrow.space_input}-${questionrow.space_input_value}` : ''}>
                            <textarea
                                className={questionrow.field_width_input ? `w-${questionrow.field_width_input} form-control resize-ta` : 'form-control resize-ta'}
                                id="commentmessage-input"
                                placeholder={placeholder}
                                name={reference_name}
                                ref={textareaRef}
                                style={{
                                    minHeight: MIN_TEXTAREA_HEIGHT,
                                    resize: "none"
                                }}
                                onChange={(e: any) => { handleChange(e) }}
                                onKeyUp={(e: any) => { handleChangeTextarea(e) }}
                                value={user_data_answer ? user_data_answer : ''}
                                disabled={!adminview}
                            ></textarea>
                        </div>
                    </div>
                    {
                        mandatorySymbol ?
                            <span className="mt-1 text-danger question invalid-question">This field is required.</span>
                            : null
                    }
                    {
                        questionrow.validation_error && questionrow.validation_error != "" ?
                            <span className="mt-1 text-danger question invalid-question">{questionrow.validation_error}</span>
                            : null
                    }
                </>
            }

            {
                !!(input_type === 14) &&
                <>
                    <div className="mt-3">
                        <Label className="form-label questionview">
                            {renderRowHTML(question)}
                            {!!questionrow.tooltip && (
                                <span className='tooltip-txt'>
                                    < i className="fa-regular fas fa-info-circle"></i><span className="tooltiptext">{questionrow.tooltip_description}</span>
                                </span>
                            )}
                        </Label>
                        {!answerOnly ?
                            <div className={(questionrow.space_input && questionrow.space_input_value) ? `${questionrow.space_input}-${questionrow.space_input_value}` : ''}>
                                <Select
                                    placeholder={t('Please select')}
                                    id="country"
                                    name={reference_name}
                                    className={questionrow.field_width_input ? `w-${questionrow.field_width_input}` : ''}
                                    options={countryOptions}
                                    value={
                                        user_data_answer
                                            ? countryOptions.find(
                                                (option: any) =>
                                                    option.value == user_data_answer
                                            )
                                            : ""
                                    }
                                    isSearchable={true}
                                    onChange={(e: any) => {
                                        handleChangeCountry(reference_name, e.value);
                                    }}
                                    isDisabled={!adminview}
                                />
                            </div>
                            :
                            <Input
                                type="text"
                                name={reference_name}
                                className={questionrow.field_width_input ? `w-${questionrow.field_width_input}` : ''}
                                id="basicpill-lastname-input2"
                                value={matchedItem ? matchedItem.label : ''}
                                disabled={!adminview}
                            />
                        }
                    </div>
                    {
                        mandatorySymbol ?
                            <span className="mt-1 text-danger question invalid-question">This field is required.</span>
                            : null
                    }
                    {
                        questionrow.validation_error && questionrow.validation_error != "" ?
                            <span className="mt-1 text-danger question invalid-question">{questionrow.validation_error}</span>
                            : null
                    }
                </>
            }
            {
                !!(input_type === 15) &&
                <div className="mt-3">
                    <Row>
                        <p className='text-left form-label'>{renderRowHTML(question)}</p>
                    </Row>
                </div>
            }
            {
                !!(input_type === 16) &&
                <>
                    <div className={(questionrow.space_input && questionrow.space_input_value) ? `${questionrow.space_input}-${questionrow.space_input_value} form-check form-check-primary mt-1` : 'form-check form-check-primary mt-1'}>
                        <input
                            type="checkbox"
                            className="form-check-input quetsionnaire_check_label"
                            id={reference_name}
                            name={reference_name}
                            onChange={(e) => handleChangecheck(e, questionrow.togglenext, questionrow.togglenexttab, questionrow.navigate_tab)}
                            checked={user_data_answer}
                            defaultChecked={user_data_answer ? true : false}
                            disabled={!adminview}
                        />

                        <label
                            className="form-check-label questionview"
                            htmlFor={reference_name}
                        >
                            {renderRowHTML(question)}
                            {!!questionrow.tooltip && (
                                <span className='tooltip-txt'>
                                    < i className="fa-regular fas fa-info-circle"></i><span className="tooltiptext">{questionrow.tooltip_description}</span>
                                </span>
                            )}
                        </label>
                    </div>
                    {
                        mandatorySymbol ?
                            <span className="mt-1 text-danger question invalid-question">This field is required.</span>
                            : null
                    }
                    {
                        questionrow.validation_error && questionrow.validation_error != "" ?
                            <span className="mt-1 text-danger question invalid-question">{questionrow.validation_error}</span>
                            : null
                    }
                </>
            }
            {/* {
                input_type === 17 &&
                <>
                    <Label htmlFor="commentmessage-input">
                        {renderRowHTML(question)}
                    </Label>
                    <Col lg={12} className="mb-3">
                        <Label htmlFor="commentmessage-input">
                            Street name
                        </Label>
                        <textarea
                            className="form-control"
                            id="commentmessage-input"
                            name={`streetname_${reference_name}`}
                            onChange={handleChange}
                            value={user_data_answer ? user_data_answer : ''}
                            disabled={!adminview}
                        ></textarea>
                    </Col>
                    <Row>
                        <Col lg={6}>
                            <Textfield label="House number" name={`housenumber_${reference_name}`} placeholder={placeholder} value={user_data_answer != null ? user_data_answer : ''} handleChange={handleChange} handleKeyDown={handleKeyDown} isDisabled={!adminview} answer_only={answerOnly} />
                        </Col>
                        <Col lg={6}>
                            <Textfield label="Box number" name={`boxnumber_${reference_name}`} placeholder={placeholder} value={user_data_answer != null ? user_data_answer : ''} handleChange={handleChange} handleKeyDown={handleKeyDown} isDisabled={!adminview} answer_only={answerOnly} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <Textfield label="Postal code" name={`postalcode_${reference_name}`} placeholder={placeholder} value={user_data_answer != null ? user_data_answer : ''} handleChange={handleChange} handleKeyDown={handleKeyDown} isDisabled={!adminview} answer_only={answerOnly} />
                        </Col>
                        <Col lg={6}>
                            <Textfield label="City" name={`city_${reference_name}`} placeholder={placeholder} value={user_data_answer != null ? user_data_answer : ''} handleChange={handleChange} handleKeyDown={handleKeyDown} isDisabled={!adminview} answer_only={answerOnly} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <Textfield label="Country" name={`country_${reference_name}`} placeholder={placeholder} value={user_data_answer != null ? user_data_answer : ''} handleChange={handleChange} handleKeyDown={handleKeyDown} isDisabled={!adminview} answer_only={answerOnly} />
                        </Col>
                    </Row>

                </>
            } */}
            {
                !!(input_type === 18) &&
                <>
                    <Row className="mt-3">
                        <p className='text-left form-label'>{renderRowHTML(question)}</p>
                    </Row>
                </>
            }
            {
                !!(input_type === 19) &&
                <>
                    <>
                        <Row className="mt-3">
                            <p className='text-left form-label questionview'>{renderRowHTML(question)}
                                {!!questionrow.tooltip && (
                                    <span className='tooltip-txt'>
                                        < i className="fa-regular fas fa-info-circle"></i><span className="tooltiptext">{questionrow.tooltip_description}</span>
                                    </span>
                                )}</p>
                        </Row>
                        {questionrow.file_name &&
                            <div className={(questionrow.space_input && questionrow.space_input_value) ? `${questionrow.space_input}-${questionrow.space_input_value} d-flex gap-2 text-end` : 'd-flex gap-2 text-end'}>
                                <span
                                    className="link-primary"
                                    title={questionrow.file_name}
                                    onClick={() => onClickDownload(questionrow)}>
                                    {questionrow.file_name.substring(0, 50)}
                                    {questionrow.file_name.length >= 50 && "..."}
                                    {"."}
                                    {questionrow.file_extension}
                                </span>
                                <Button
                                    outline={true}
                                    color="primary"
                                    size="sm"
                                    onClick={() => onClickDownload(questionrow)}
                                    title="Download file">
                                    <i className="fa-solid fa-cloud-arrow-down"></i>
                                </Button>
                            </div>
                        }
                    </>
                </>

            }
            {
                !!(input_type === 20) &&
                <>
                    <hr />
                </>
            }
            {
                !!(input_type === 22) &&
                <>
                    <div className="mt-3">
                        <Label className="form-label questionview">
                            {renderRowHTML(question)}
                            {!!questionrow.tooltip && (
                                <span className='tooltip-txt'>
                                    < i className="fa-regular fas fa-info-circle"></i><span className="tooltiptext">{questionrow.tooltip_description}</span>
                                </span>
                            )}
                        </Label>
                        {!answerOnly ?
                            <div className={(questionrow.space_input && questionrow.space_input_value) ? `${questionrow.space_input}-${questionrow.space_input_value}` : ''}>
                                <Select
                                    placeholder={t('Please select')}
                                    id="gender"
                                    name={reference_name}
                                    className={questionrow.field_width_input ? `w-${questionrow.field_width_input}` : ''}
                                    options={genderOptions}
                                    value={
                                        user_data_answer
                                            ? genderOptions.find(
                                                (option: any) =>
                                                    option.value == user_data_answer
                                            )
                                            : ""
                                    }
                                    isSearchable={true}
                                    onChange={(e: any) => {
                                        handleChangeCountry(reference_name, e.value);
                                    }}
                                    isDisabled={!adminview}
                                />
                            </div>
                            :
                            <Input
                                type="text"
                                name={reference_name}
                                className={questionrow.field_width_input ? `w-${questionrow.field_width_input}` : ''}
                                id="basicpill-gender-input2"
                                value={user_data_answer ? user_data_answer : ''}
                                disabled={!adminview}
                            />
                        }
                    </div>
                    {
                        mandatorySymbol ?
                            <span className="mt-1 text-danger question invalid-question">This field is required.</span>
                            : null
                    }
                    {
                        questionrow.validation_error && questionrow.validation_error != "" ?
                            <span className="mt-1 text-danger question invalid-question">{questionrow.validation_error}</span>
                            : null
                    }
                </>
            }
            {
                !!(input_type === 23) &&
                <>
                    <div className="mt-3">
                        <Label className="form-label questionview">
                            {renderRowHTML(question)}
                            {!!questionrow.tooltip && (
                                <span className='tooltip-txt'>
                                    < i className="fa-regular fas fa-info-circle"></i><span className="tooltiptext">{questionrow.tooltip_description}</span>
                                </span>
                            )}
                        </Label>
                        {!answerOnly ?
                            <div className={(questionrow.space_input && questionrow.space_input_value) ? `${questionrow.space_input}-${questionrow.space_input_value}` : ''}>
                                <Select
                                    placeholder={t('Please select')}
                                    id="language"
                                    name={reference_name}
                                    className={questionrow.field_width_input ? `w-${questionrow.field_width_input}` : ''}
                                    options={languageOptions}
                                    value={
                                        user_data_answer
                                            ? languageOptions.find(
                                                (option: any) =>
                                                    option.value == user_data_answer
                                            )
                                            : ""
                                    }
                                    isSearchable={true}
                                    onChange={(e: any) => {
                                        handleChangeCountry(reference_name, e.value);
                                    }}
                                    isDisabled={!adminview}
                                />
                            </div>
                            :
                            <Input
                                type="text"
                                name={reference_name}
                                className={questionrow.field_width_input ? `w-${questionrow.field_width_input}` : ''}
                                id="basicpill-language-input2"
                                value={user_data_answer ? user_data_answer : ''}
                                disabled={!adminview}
                            />
                        }
                    </div>
                    {
                        mandatorySymbol ?
                            <span className="mt-1 text-danger question invalid-question">This field is required.</span>
                            : null
                    }
                    {
                        questionrow.validation_error && questionrow.validation_error != "" ?
                            <span className="mt-1 text-danger question invalid-question">{questionrow.validation_error}</span>
                            : null
                    }
                </>
            }
            {
                !!(input_type === 24) &&
                <>
                    <div className="mt-3">
                        <Label for={reference_name} className={answerOnly ? "pointer questionview" : "questionview"}>
                            {renderRowHTML(question)}
                            {!!questionrow.tooltip && (
                                <span className='tooltip-txt'>
                                    < i className="fa-regular fas fa-info-circle"></i><span className="tooltiptext">{questionrow.tooltip_description}</span>
                                </span>
                            )}
                        </Label>
                        <div className={(questionrow.space_input && questionrow.space_input_value) ? `${questionrow.space_input}-${questionrow.space_input_value}` : ''}>
                            <Input
                                type="number"
                                name={reference_name}
                                className={questionrow.field_width_input ? `w-${questionrow.field_width_input} form-control` : 'form-control'}
                                id="basicpill-lastname-input2"
                                placeholder={placeholder}
                                defaultValue={questionrow.user_data_answer ? questionrow.user_data_answer : ''}
                                onChange={handleChange}
                                onKeyDown={handleKeyDown}
                                maxLength={questionrow.max_length ? questionrow.max_length : ''}
                                disabled={!adminview}
                                onKeyPress={(e) => onlyPositiveValue(e)}
                            />
                        </div>
                    </div>
                    {
                        mandatorySymbol ?
                            <span className="mt-1 text-danger question invalid-question">This field is required.</span>
                            : null
                    }
                    {
                        questionrow.validation_error && questionrow.validation_error != "" ?
                            <span className="mt-1 text-danger question invalid-question">{questionrow.validation_error}</span>
                            : null
                    }
                </>
            }
            {
                !!(input_type === 25) &&
                <>
                    <div className="mt-3">
                        <Label className="form-label questionview">
                            {renderRowHTML(question)}
                            {!!questionrow.tooltip && (
                                <span className='tooltip-txt'>
                                    < i className="fa-regular fas fa-info-circle"></i><span className="tooltiptext">{questionrow.tooltip_description}</span>
                                </span>
                            )}
                        </Label>
                        {!answerOnly ?
                            <div className={(questionrow.space_input && questionrow.space_input_value) ? `${questionrow.space_input}-${questionrow.space_input_value}` : ''}>
                                <Select
                                    placeholder={t('Please select')}
                                    id="civil_status"
                                    name={reference_name}
                                    className={questionrow.field_width_input ? `w-${questionrow.field_width_input}` : ''}
                                    options={civil_status_master}
                                    value={
                                        user_data_answer
                                            ? civil_status_master.find(
                                                (option: any) =>
                                                    option.value == user_data_answer
                                            )
                                            : ""
                                    }
                                    isSearchable={true}
                                    onChange={(e: any) => {
                                        handleChangeCountry(reference_name, e.value);
                                    }}
                                    isDisabled={!adminview}
                                />
                            </div>
                            :
                            <Input
                                type="text"
                                name={reference_name}
                                className={questionrow.field_width_input ? `w-${questionrow.field_width_input}` : ''}
                                id="basicpill-language-input2"
                                value={user_data_answer ? user_data_answer : ''}
                                disabled={!adminview}
                            />
                        }
                    </div>
                    {
                        mandatorySymbol ?
                            <span className="mt-1 text-danger question invalid-question">This field is required.</span>
                            : null
                    }
                    {
                        questionrow.validation_error && questionrow.validation_error != "" ?
                            <span className="mt-1 text-danger question invalid-question">{questionrow.validation_error}</span>
                            : null
                    }
                </>
            }
            {
                !!(input_type === 26) &&
                (
                    <div className={(questionrow.space_input && questionrow.space_input_value) ? `${questionrow.space_input}-${questionrow.space_input_value}` : ''}>
                        <Label className="form-label questionview mt-3">
                            {renderRowHTML(question)}
                            {!!questionrow.tooltip && (
                                <span className='tooltip-txt'>
                                    < i className="fa-regular fas fa-info-circle"></i><span className="tooltiptext">{questionrow.tooltip_description}</span>
                                </span>
                            )}
                        </Label>
                        <AddSignature
                            moduleData={{
                                module_app_id: 6,
                                module_main_id: questionnaireID,
                                module_sub_id: questionnaireSubID,
                                module_main_user_id: questionrow.user_id,
                                module_sub_user_id: questionrow.user_id_code,
                                section_id: questionnaireID,
                                file_path: "user",
                            }}
                            show={signatureShow} isDisabled={!adminview} showAdd={showSign}
                            handleSignatureData={handleSignatureData} />
                    </div>
                )
            }
            {
                !!(input_type === 27) &&
                (
                    <div className={(questionrow.space_input && questionrow.space_input_value) ? `${questionrow.space_input}-${questionrow.space_input_value}` : ''}>
                        <WorkingCalender user_id={questionrow.user_id} year={year} onClicknext={onClicknext} onSaveClick={onSaveClick} />
                    </div>
                )
            }
        </React.Fragment>
    )
}

export default QuestionView;