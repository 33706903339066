import { memo } from "react";
import { Button, Col, Row } from "reactstrap"

type PaginationProps = {
    count:number;
    nextPage:()=>void;
    prePage:()=>void;
    disable?:boolean;
    currentPage:number;
}

const Pagination = (props: PaginationProps) => {
    const { count, nextPage,prePage,currentPage } = props;
    if(count ===0) return null
    
    return (
        <Row className="bg-white p-2">
            <Col xs="7">Showing {currentPage} - 10 of {count}</Col>
            <Col xs="5">
                <div className="btn-group float-end">
                    <Button
                        type="button"
                        color="primary"
                        size="sm"
                        className=""
                        onClick={nextPage}
                        disabled={currentPage===1}
                    >
                        <i className="fa fa-chevron-left" />
                    </Button>
                    <Button
                        type="button"
                        color="primary"
                        size="sm"
                        className=""
                        onClick={prePage}
                    >
                        <i className="fa fa-chevron-right" />
                    </Button>
                </div>
            </Col>
        </Row>)
}
export default memo(Pagination);