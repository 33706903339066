import { takeEvery, call, put } from "redux-saga/effects";
import emailCategoryService from "../../../services/master.email.category.service";
import { AnyAction } from "redux";

// TaskTypes Redux States
import {
  GET_EMAIL_TEMPLATE_CATEGORY,
  GET_EMAIL_TEMPLATE_CATEGORY_LIST,
  CREATE_EMAIL_TEMPLATE_CATEGORY,
  CREATE_DUPLICATE_EMAIL_TEMPLATE_CATEGORY,
  UPDATE_EMAIL_TEMPLATE_CATEGORY,
  DELETE_EMAIL_TEMPLATE_CATEGORY,
} from "./actionTypes";

import { 
    getEmailCategorySuccess, 
    getEmailCategoryError,
    
    getEmailCategoryListSuccess,
    getEmailCategoryListError,

    createEmailCategorySuccess,
    createEmailCategoryError,

    createDuplicateEmailCategorySuccess,
    createDuplicateEmailCategoryError,

    updateEmailCategorySuccess,
    updateEmailCategoryError,

    deleteEmailCategorySuccess,
    deleteEmailCategoryError,
} from "./actions";

function* getEmailTemplateCategory({ payload: { emailCategory, history } }: AnyAction): any {
  try {
    const response = yield call(emailCategoryService.getEmailCategory, emailCategory);
    if (response.data && response.data.status === "success") {
      yield put(getEmailCategorySuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getEmailCategoryError(response.data));
    } else {
      yield put(getEmailCategoryError(response));
    }
  } catch (error: any) {
    yield put(getEmailCategoryError(error));
  }
}

function* getEmailTemplateCategoryList({ payload: { query, history } }: AnyAction): any {
  try {
    const response = yield call(emailCategoryService.getEmailCategoryList, query);
    if (response.data && response.data.status === "success") {
      yield put(getEmailCategoryListSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getEmailCategoryListError(response.data));
    } else {
      yield put(getEmailCategoryListError(response));
    }
  } catch (error: any) {
    yield put(getEmailCategoryListError(error));
  }
}

function* createDuplicateEmailTemplateCategory({ payload: { emailCategory, history } }: AnyAction): any {
  try {
    const response = yield call(emailCategoryService.createDuplicateEmailCategory, emailCategory);
    if (response.data && response.data.status === "success") {
      yield put(createDuplicateEmailCategorySuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(createDuplicateEmailCategoryError(response.data));
    } else {
      yield put(createDuplicateEmailCategoryError(response));
    }
  } catch (error: any) {
    yield put(createDuplicateEmailCategoryError(error));
  }
}

function* createEmailTemplateCategory({ payload: { emailCategory, history } }: AnyAction): any {
  try {
    const response = yield call(emailCategoryService.createEmailCategory, emailCategory);
    if (response.data && response.data.status === "success") {
      yield put(createEmailCategorySuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(createEmailCategoryError(response.data));
    } else {
      yield put(createEmailCategoryError(response));
    }
  } catch (error: any) {
    yield put(createEmailCategoryError(error));
  }
}

function* updateEmailTemplateCategory({ payload: { emailCategory, history } }: AnyAction): any {
  try {
    const response = yield call(emailCategoryService.updateEmailCategory, emailCategory);
    if (response.data && response.data.status === "success") {
      yield put(updateEmailCategorySuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(updateEmailCategoryError(response.data));
    } else {
      yield put(updateEmailCategoryError(response));
    }
  } catch (error: any) {
    yield put(updateEmailCategoryError(error));
  }
}

function* deleteEmailTemplateCategory({ payload: { emailCategory, history } }: AnyAction): any {
  try {
    const response = yield call(emailCategoryService.deleteEmailCategory, emailCategory);
    if (response.data && response.data.status === "success") {
      yield put(deleteEmailCategorySuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(deleteEmailCategoryError(response.data));
    } else {
      yield put(deleteEmailCategoryError(response));
    }
  } catch (error: any) {
    yield put(deleteEmailCategoryError(error));
  }
}

function* taskTypesSaga(): any {
  yield takeEvery(GET_EMAIL_TEMPLATE_CATEGORY, getEmailTemplateCategory);
  yield takeEvery(GET_EMAIL_TEMPLATE_CATEGORY_LIST, getEmailTemplateCategoryList);
  yield takeEvery(CREATE_EMAIL_TEMPLATE_CATEGORY, createEmailTemplateCategory);
  yield takeEvery(CREATE_DUPLICATE_EMAIL_TEMPLATE_CATEGORY, createDuplicateEmailTemplateCategory);
  yield takeEvery(UPDATE_EMAIL_TEMPLATE_CATEGORY, updateEmailTemplateCategory);
  yield takeEvery(DELETE_EMAIL_TEMPLATE_CATEGORY, deleteEmailTemplateCategory);
}

export default taskTypesSaga;
