import React, { memo, useEffect, useState } from "react";
import {
    Modal,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    Label,
} from "reactstrap";

const Layout = (props: any) => {
    const [arrangeReading, setArrangeReading] = useState<string>('');
    useEffect(() => {
        localStorage.setItem('reading_conf', arrangeReading);
    }, [arrangeReading])
    const {t}=props;
    return (
        <React.Fragment>
            <Modal
                isOpen={props.show}
                toggle={props.onCloseClick}
                backdrop="static"
                keyboard={false}
                autoFocus={false}
                centered
            >
                <div className="modal-header">
                {t("Message organization")}
                </div>
                <ModalBody>
                    <p>{t("Arrange the reading pane")}</p>
                    <div className="row">
                        <div className="col">
                            <Input
                                name="radio2"
                                type="radio"
                                id="arrange_top"
                                onChange={() => setArrangeReading('1')}
                            /> <Label for="arrange_top">{t("Newest on top")}</Label></div>
                        <div className="col"><img src="https://res.cdn.office.net/owamail/20230310007.20/scripts/../resources/images/newestTopLarge-hash-0604e774.svg" width={120} /></div>
                    </div>
                    <div className="row mt-2">
                        <div className="col">  <Input
                            name="radio2"
                            type="radio"
                            id="arrange_bottom"
                            onChange={() => setArrangeReading('2')}
                        /> <Label for="arrange_bottom">{t("Newest on bottom")}</Label></div>
                        <div className="col"><img src="https://res.cdn.office.net/owamail/20230310007.20/resources/images/newestBottomLarge-hash-b8eb0313.svg" width={120} /></div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        onClick={props.onYesClick}
                    >
                        Save
                    </Button>
                    {' '}
                    <Button onClick={props.onNoClick}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export default memo(Layout);
