import { 
  GET_ACCESS_MODULE,
  GET_ACCESS_MODULE_SUCCESS,
  GET_ACCESS_MODULE_ERROR,

  GET_ACCESS_MODULES_LIST,
  GET_ACCESS_MODULES_LIST_SUCCESS,
  GET_ACCESS_MODULES_LIST_ERROR,
  
  CREATE_ACCESS_MODULE,
  CREATE_ACCESS_MODULE_SUCCESS,
  CREATE_ACCESS_MODULE_ERROR, 

  UPDATE_ACCESS_MODULE,
  UPDATE_ACCESS_MODULE_SUCCESS,
  UPDATE_ACCESS_MODULE_ERROR,

  DELETE_ACCESS_MODULE,
  DELETE_ACCESS_MODULE_SUCCESS,
  DELETE_ACCESS_MODULE_ERROR,
} from "./actionTypes"
  
  const initialState = {
    GET_ACCESS_MODULE_SUCCESS: "",
    GET_ACCESS_MODULE_ERROR: "",
    GET_ACCESS_MODULES_LIST_SUCCESS: "",
    GET_ACCESS_MODULES_LIST_ERROR: "",
    CREATE_ACCESS_MODULE_SUCCESS: "",
    CREATE_ACCESS_MODULE_ERROR: "",
    UPDATE_ACCESS_MODULE: "",
    UPDATE_ACCESS_MODULE_SUCCESS: "",
    UPDATE_ACCESS_MODULE_ERROR: "",
    DELETE_ACCESS_MODULE: "",
    DELETE_ACCESS_MODULE_SUCCESS: "",
    DELETE_ACCESS_MODULE_ERROR: "",
    loading: false,
  }
  
  const reducer = (state = initialState, action: any) => {
    switch (action.type) {
      // ---------------------------------------------GET_ACCESS_MODULE
      case GET_ACCESS_MODULE:
        state = {
          ...state,
          loading: true,
        }
        break

      case GET_ACCESS_MODULE_SUCCESS:
        state = {
          ...state,
          GET_ACCESS_MODULE_SUCCESS: action.payload,
          loading: false,
        }
        break

      case GET_ACCESS_MODULE_ERROR:
        state = { 
          ...state, 
          GET_ACCESS_MODULE_ERROR: action.payload, 
          loading: false 
        }
        break

      // ---------------------------------------------GET_ACCESS_MODULES_LIST
      case GET_ACCESS_MODULES_LIST:
        state = {
          ...state,
          loading: true,
        }
        break

      case GET_ACCESS_MODULES_LIST_SUCCESS:
        state = {
          ...state,
          GET_ACCESS_MODULES_LIST_SUCCESS: action.payload,
          loading: false,
        }
        break

      case GET_ACCESS_MODULES_LIST_ERROR:
        state = { 
          ...state, 
          GET_ACCESS_MODULES_LIST_ERROR: action.payload, 
          loading: false 
        }
        break
        
      // ---------------------------------------------CREATE_ACCESS_MODULE
      case CREATE_ACCESS_MODULE:
        state = {
          ...state,
          loading: true,
        }
        break

      case CREATE_ACCESS_MODULE_SUCCESS:
        state = {
          ...state,
          CREATE_ACCESS_MODULE_SUCCESS: action.payload,
          loading: false,
        }
        break

      case CREATE_ACCESS_MODULE_ERROR:
        state = { 
          ...state, 
          CREATE_ACCESS_MODULE_ERROR: action.payload, 
          loading: false 
        }
        break

      // ---------------------------------------------UPDATE_ACCESS_MODULE
      case UPDATE_ACCESS_MODULE:
        state = {
          ...state,
          loading: true,
        }
        break

      case UPDATE_ACCESS_MODULE_SUCCESS:
        state = {
          ...state,
          UPDATE_ACCESS_MODULE_SUCCESS: action.payload,
          loading: false,
        }
        break

      case UPDATE_ACCESS_MODULE_ERROR:
        state = { 
          ...state, 
          UPDATE_ACCESS_MODULE_ERROR: action.payload, 
          loading: false 
        }
        break

      // ---------------------------------------------DELETE_ACCESS_MODULE
      case DELETE_ACCESS_MODULE:
        state = {
          ...state,
          loading: true,
        }
        break

      case DELETE_ACCESS_MODULE_SUCCESS:
        state = {
          ...state,
          DELETE_ACCESS_MODULE_SUCCESS: action.payload,
          loading: false,
        }
        break

      case DELETE_ACCESS_MODULE_ERROR:
        state = { 
          ...state, 
          DELETE_ACCESS_MODULE_ERROR: action.payload, 
          loading: false 
        }
        break

      // ---------------------------------------------DEFAULT
      default:
        state = { ...state }
        break
    }
    return state
  }
  
  export default reducer
  