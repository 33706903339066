import axios from "axios";
import { getAxiosConfigWithToken } from "../helpers/functions_helper";
import * as url from "../helpers/url_helper";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class CPO {
  async create(data: any) {
    const response = await axios
      .put(SERVER_URL + url.CPO.CREATE, data, {
        headers: getAxiosConfigWithToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async update(data: any) {
    const response = await axios
      .post(SERVER_URL + url.CPO.UPDATE, data, {
        headers: getAxiosConfigWithToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async delete(data: any) {
    const response = await axios
      .post(SERVER_URL + url.CPO.DELETE, data, {
        headers: getAxiosConfigWithToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async get(data: any) {
    const response = await axios
      .get(SERVER_URL + url.CPO.GET + "/" + data.id + "/" + data.cpo_id, {
        headers: getAxiosConfigWithToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async getAll(data: any) {
    const response = await axios
      .post(SERVER_URL + url.CPO.GET_ALL, data, {
        headers: getAxiosConfigWithToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async getSPYear() {
    const response = await axios
      .get(SERVER_URL + url.CPO.GET_SP_YEAR, {
        headers: getAxiosConfigWithToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async getSPCountry() {
    const response = await axios
      .get(SERVER_URL + url.CPO.GET_SP_COUNTRY, {
        headers: getAxiosConfigWithToken(),
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async exportReport(data: any) {
    const response = await axios
      .post(SERVER_URL + url.CPO.REPORT, data, {
        headers: getAxiosConfigWithToken(),
        responseType: 'blob',
      }).then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  //Graph
  async getCPOIndividualHitsCount(query: any) {
    const response = await axios
      .get(SERVER_URL + url.CPO.GET_CPO_INDIVIDUAL_HITS_COUNT+'?'+query,{
        headers: getAxiosConfigWithToken(),
      }).then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async getWebsiteUserVsCustomer(query: any) {
    const response = await axios
      .get(SERVER_URL + url.CPO.GET_WEBSITEVSCUSTOMER+'?'+query,{
        headers: getAxiosConfigWithToken(),
      }).then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async getCPOHits(query: any) {
    const response = await axios
      .get(SERVER_URL + url.CPO.GET_CPO_HITS_COUNT+'?'+query,{
        headers: getAxiosConfigWithToken(),
      }).then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }

  async getWebsiteUserBecomeCustomer(query: any) {
    const response = await axios
      .get(SERVER_URL + url.CPO.GET_WEBSITEUSER_CUSTOMER+'?'+query,{
        headers: getAxiosConfigWithToken(),
      }).then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
    return response;
  }




}

export default new CPO();
