import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, CardBody, CardFooter, Col, Label, Progress, Row, Spinner, ListGroup, ListGroupItem } from 'reactstrap';
import Dropzone from "../../components/Common/Dropzone";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { QuestionnaireAction, QuestionnaireUserDataAction } from '../../store/actions';
import moment from 'moment';
import { toast } from "react-toastify";
import MultiInput from './MultiInput';
import commonService from '../../services/common.service';
import { cloneDeep } from 'lodash';
import QuestionView from './QuestionView';
// Import Loader
import Loader from "../../components/Layout/Loader";
import { useTranslation } from 'react-i18next';
import TableMultiInput from './multiinput/Table';
import { filterCountryByLanguage, getSelectOptions, removeTags } from '../../helpers/functions_helper';
import { civil_status_master } from '../../common/staticdata';

const CustomerView = (props: any) => {
    let params = useParams();
    if (props?.questionnaireData) {
        params = props?.questionnaireData;
    }
    const { t, i18n } = useTranslation();

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const { id, questionnaire_id } = useParams();
    const [questionPreviewData, setQuestionPreviewData] = useState<any>([]);
    const [activeSubTab, setactiveSubTab] = useState<any>(0);
    const [totaltab, settotaltab] = useState<any>(0);
    const [needfile, setneedfile] = useState<any>();
    const [filevalue, setFileValue] = useState({});

    const [questionnaireData, setQuestionnaireData] = useState<any>([]);
    const [questionsData, setquestionsData] = useState<any>([]);
    const [questionnaireFullData, setQuestionnaireFullData] = useState<any>([]);

    const [intialData, setIntialData] = useState<any>({});
    const [user_id, setUser_id] = useState();
    const [title, setTitle] = useState("");
    const [tooltip, setTooltip] = useState("");
    const [questionnairestatus, setQuestionnaireStatus] = useState();
    const [questionnaireActive, setQuestionnaireActive] = useState(0);
    const [userType, setUserType] = useState(props.usertype);
    const [activeNextTab, setactiveNextTab] = useState();
    const [activeNextTabName, setactiveNextTabName] = useState();
    const [tabs, setTabs] = useState([]);
    const [activeTab, setactiveTab] = useState<any>();
    const [activeFirstTab, setactiveFirstTab] = useState();
    const [intialActiveTab, setIntialActiveTab] = useState(0);

    const [multicheck, setMulticheck] = useState<any>([]);
    const [multicheckname, setMulticheckname] = useState("");
    const [questionnaireLang, setQuestionnaireLang] = useState(1);
    const [language, setLanguage] = useState("English");
    const [isSubmit, setIsSubmit] = useState(false);
    const [hideAttach, setHideAttach] = useState(false);

    const [mainTitle, setMainTitle] = useState("");

    const [totalMenu, setTotalMenu] = useState(0);

    const toastId = React.useRef(null) as any;
    const [year, setYear] = useState(null) as any;

    const [query, setQuery] = useState("");
    const [loadingCountry, setLoadingCountry] = useState(true);
    const [countryOptions, setCountryOptions] = useState([]);
    const [loadingNationality, setLoadingNationality] = useState(true);
    const [nationalityOptions, setNationalityOptions] = useState([]);
    const [loader, setLoader] = useState(true);

    const [userAddress, setUserAddress] = useState<any>([]);
    const [companyAddress, setCompanyAddress] = useState<any>([]);
    const [companyInvAddress, setCompanyInvAddress] = useState<any>([]);
    const [questionnaireContent, setQuestionnaireContent] = useState("");


    const [intialPage, setIntialPage] = useState(false);
    const [intialTab, setIntialTab] = useState(false);

    // useEffect(() => {
    //     getSelectOptions("tbl_master_country", "name").then(function (options: any) {
    //         setCountryOptions(options);
    //         setLoadingCountry(false);
    //         setNationalityOptions(options);
    //         setLoadingNationality(false);
    //     });
    // }, []);

    useEffect(() => {
        getSelectOptions("tbl_master_country", "name", "", [
          "is_europian_country",
          "name",
          "name_french",
          "name_dutch",
        ]).then(function (options: any) {
          let countryOptionsList = filterCountryByLanguage(options, language);
          setCountryOptions(countryOptionsList);
          setLoadingCountry(false);
          setNationalityOptions(options);
          setLoadingNationality(false);
        });
      }, [language]);


    // fetch data from 
    let data = useSelector((state: any) => ({
        questionnaireFullData: state.Questionnaire.GET_QUESTIONS_FULL_DATA.data,
        fullDataQuestionError: state.Questionnaire.error,
        fullDataQuestionloading: state.Questionnaire.loading,
        getQuestionnaireTab: state.QuestionnaireTab.GET_TAB_LIST.data,
        success: state.QuestionnaireUserData.success,
        error: state.QuestionnaireUserData.error,
        loading: state.QuestionnaireUserData.loading,
        getCompanyOptionsList: state.Common.GET_COMPANY_OPTIONS_LIST_SUCCESS.data,
    }));

    const [isQuestionLoad, setIsQuestionLoad] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [errorData, setErrorData] = useState<any>({ message: "", status: "" });
    const [userID, setUserID] = useState();
    const [userIDCode, setUserIDCode] = useState();

    // fiel upload data
    const [fileAnswerData, setFileAnswerData] = useState([]);
    const toggleTab = (tab: any, subTab = 0) => {
        setactiveTab(tab);
        if (!subTab) {
            setLastSubTab(0);
        }
        setIntialPage(false);
        setIntialData((intialData: any) => {
            return {
                ...intialData,
                tab_id: tab
            };
        });
    }


    useEffect(() => {
        if (data.questionnaireFullData?.questionnaire_tab_data) {
            data.questionnaireFullData.questionnaire_tab_data.forEach((questionnaireTab: any, key: number) => {
                if (questionnaireTab.id === activeTab) {
                    setTitle(data.questionnaireFullData.questionnaire_tab_data[key].questionnaire_tab_value);
                    setTooltip(data.questionnaireFullData.questionnaire_tab_data[key].tooltip_data);
                    if (data.questionnaireFullData.questionnaire_tab_data[key].hide_attachement) {
                        setHideAttach(true);
                        setactiveSubTab(1);
                    } else if (!lastSubTab) {
                        setactiveSubTab(0);
                        setHideAttach(false);
                    } else {

                    }
                    if (data.questionnaireFullData.questionnaire_tab_data[key + 1]) {
                        setactiveNextTab(data.questionnaireFullData.questionnaire_tab_data[key + 1].id);
                        setactiveNextTabName(data.questionnaireFullData.questionnaire_tab_data[key + 1].questionnaire_tab_value)
                    }
                    if (data.questionnaireFullData.questionnaire_tab_data[key - 1]) {
                        setactiveFirstTab(data.questionnaireFullData.questionnaire_tab_data[key - 1].id);
                    }
                }
            })
        }

    }, [activeTab])

    useEffect(() => {
        if (tabData) {
            if (activeTab in tabData && tabData[activeTab].includes(1)) {
                setIntialTab(false);
                setToggleTabLi(false);
            } else {
                setIntialTab(true);
            }
        }
    }, [activeTab, activeSubTab])

    const clickNonapplicable = () => {
        const newTabData = { ...tabData };
        delete newTabData[activeTab];
        setTabData(newTabData);
        toggleTab(activeNextTab);
    }


    const onPreviousClick = () => {
        const answerAr: any = fileAnswerData.filter((question: any) => question.tab_id === activeTab);
        const tabAr: any = tabs.filter((tab: any) => tab.id === activeTab);
        if (activeTab == intialActiveTab && activeSubTab == 0 && questionnaireContent?.length > 0) {
            setIntialPage(true);
        }
        else if (activeSubTab == 0) {
            toggleTab(activeFirstTab);
        } else if (tabAr.length > 0 && tabAr[0].hide_attachement && activeSubTab == 1) {
            toggleTab(activeFirstTab);
        }
        else {
            setactiveSubTab(activeSubTab - 1);
        }
        setIsTabClick(true);
    }

    const onNextClick = () => {
        if (activeSubTab == totaltab || needfile) {
            toggleTab(activeNextTab);
        } else {
            setactiveSubTab(activeSubTab + 1);
        }

        setIsTabClick(true);

    }



    /*************************set left side answered questions************************* */
    useEffect(() => {
        const question_tab: any = questionPreviewData.filter((question: any) => (question.indexval == activeSubTab && question.tab_id == activeTab));
        let submit_enable = false;
        question_tab.forEach((element: any) => {
            if (element.enable_submit) {
                submit_enable = true;
            }
        });
        setIsSubmit(submit_enable);
    }, [activeSubTab, activeTab]);



    const [lastSubTab, setLastSubTab] = useState(0);
    useEffect(() => {
        if (lastSubTab && data.questionnaireFullData?.last_user_question_sub_tab) {
            setactiveSubTab(data.questionnaireFullData?.last_user_question_sub_tab);
        }
    }, [lastSubTab,])


    useEffect(() => {
        if (data.questionnaireFullData?.last_user_question_position && data.questionnaireFullData?.last_user_question_position !== 0) {
            toggleTab(data.questionnaireFullData?.last_user_question_position, data.questionnaireFullData?.last_user_question_sub_tab);
            setLastSubTab(data.questionnaireFullData?.last_user_question_sub_tab)
            setIntialPage(false);
            setIntialTab(false);
        } else {
            if (questionnaireContent?.length > 0) {
                setIntialPage(true);
                setIntialTab(true);
            } else {
                setIntialPage(false);
                setIntialTab(true);
            }
        }
    }, [questionnaireContent, data.questionnaireFullData])

    const [tabData, setTabData] = useState<any>({});
    useEffect(() => {
        if (tabs.length) {
            let tab_ar: any = [];
            tabs.forEach((tb: any) => {
                tab_ar = ({ ...tab_ar, [tb.id]: [] });
            })
            setTabData(tab_ar);
        }
    }, [tabs])

    useEffect(() => {
        if (tabs && tabData) {
            setTabData((prevTabData: any) => {
                const newTabData = { ...prevTabData };
                newTabData[activeTab]?.push(activeSubTab);
                return newTabData;
            });
        }
    }, [activeSubTab])


    useEffect(() => {
        if (questionnaireFullData?.answered_tabs) {
            setTabData(JSON.parse(questionnaireFullData?.answered_tabs));
        }
    }, [questionnaireFullData]);

    const isSubTabAvailable = (answer: any, tab: any, subTab: any) => {
        return (answer && answer !== "" && answer != "null") && tabData && tabData[tab] && tabData[tab].includes(subTab);
    };

    /****onclik left side question move to corresponding question tab */
    const handleClick = (indexval: any) => {
        setactiveSubTab(indexval);
    }
    /****onclik left side question move to corresponding question tab */
    /*************************set left side answered questions************************* */

    /**************upload file changes************ */
    useEffect(() => {
        if (fileAnswerData.length > 0) {
            fileAnswerData.forEach((fileone: any) => {
                setFileValue((filevalue: any) => {
                    return {
                        ...filevalue,
                        ["sent_file_" + fileone.tab_id]: fileone.file
                    };
                });
            });
        }
    }, [fileAnswerData]);


    const handleChangeFile = (e: any) => {
        const value = e.target.value == 1 ? true : false;
        setFileValue((filevalue: any) => {
            return {
                ...filevalue,
                [e.target.name]: value
            };
        });
    }

    useEffect(() => {
        if (filevalue) {
            for (const [key, value] of Object.entries(filevalue)) {
                if (key === `sent_file_${activeTab}`) {
                    setneedfile(value);
                } else {
                    setneedfile(false);
                }
            }
        }
    }, [filevalue, activeTab])


    useEffect(() => {
        if (questionsData) {
            const questionAr: any = questionsData.filter((question: any) => question.tab_id == activeTab);
            if (needfile) {
                settotaltab(0);
            } else {
                if (questionAr && questionAr[questionAr.length - 1] && questionAr[questionAr.length - 1].indexval) {
                    settotaltab(questionAr[questionAr.length - 1].indexval);
                }

            }
        }

    }, [needfile, activeTab])


    /**************upload file changes**************/

    useEffect(() => {
        if (data.questionnaireFullData) {
            setQuestionnaireFullData(data.questionnaireFullData);
            setQuestionnaireData(data.questionnaireFullData.questionnaire_data[0]);
            setquestionsData(data.questionnaireFullData.questions_data);
            setFileAnswerData(data.questionnaireFullData.file_data);
            setLoader(false);
            //setQuestionPreviewData((questionPreviewData: any ) => ([...questionPreviewData, ...data.questionnaireFullData.questions_data]));
            const userID = data.questionnaireFullData.questionnaire_data[0].employee;
            setQuestionnaireStatus(data.questionnaireFullData.questionnaire_data[0].status);
            setQuestionnaireActive(data.questionnaireFullData.questionnaire_data[0].questionnaire_active);
            setTabs(data.questionnaireFullData.questionnaire_tab_data);
            setYear(data.questionnaireFullData.questionnaire_data[0].year);
            setUserID(data.questionnaireFullData.questionnaire_data[0].user_id);
            setUserIDCode(data.questionnaireFullData.questionnaire_data[0].user_id_code);
            setQuestionnaireContent(data.questionnaireFullData.questionnaire_data[0].questionnaire_content);
            setQuestionnaireLang(data.questionnaireFullData.questionnaire_data[0].questionnaire_lang);
            if (data.questionnaireFullData.questionnaire_tab_data[0]) {
                setMainTitle(data.questionnaireFullData.questionnaire_tab_data[0].questionnaire_type);
                setTitle(data.questionnaireFullData.questionnaire_tab_data[0].questionnaire_tab_value);
                setTooltip(data.questionnaireFullData.questionnaire_tab_data[0].tooltip_data);
                setactiveFirstTab(data.questionnaireFullData.questionnaire_tab_data[0].id);
                setIntialActiveTab(data.questionnaireFullData.questionnaire_tab_data[0].id);
                setIntialData((intialData: any) => {
                    return {
                        ...intialData,
                        tab_id: data.questionnaireFullData.questionnaire_tab_data[0].id 
                    };
                });
                setactiveTab(data.questionnaireFullData.questionnaire_tab_data[0].id);
            }
            if (data.questionnaireFullData.questionnaire_tab_data[1]) {
                setactiveNextTab(data.questionnaireFullData.questionnaire_tab_data[1].id);
                setactiveNextTabName(data.questionnaireFullData.questionnaire_tab_data[1].questionnaire_tab_value)
            }
            if (data.questionnaireFullData.questionnaire_tab_data.length - 1) {
                setTotalMenu(data.questionnaireFullData.questionnaire_tab_data[data.questionnaireFullData.questionnaire_tab_data.length - 1].id);
            }

        }
        if (questionnaireFullData && questionnaireFullData.questions_data) {
            const questionAr: any = questionnaireFullData.questions_data.filter((question: any) => (question.tab_id === activeTab || question.condition_to_other));
            updateQuestionAr(questionAr);
        }

    }, [data.questionnaireFullData]);

    useEffect(() => {
        if (questionnaireLang == 1) {
            setLanguage("English");
        } else if (questionnaireLang == 2) {
            setLanguage("French");
        } else if (questionnaireLang == 3) {
            setLanguage("Dutch");
        } else {
            setLanguage("English");
        }
    }, [questionnaireLang])

    const rightdiv = document.getElementById('wizard_container');
    const leftdiv = document.getElementById('filled-details');
    if (rightdiv && leftdiv) {
        let offsetHeight = rightdiv.offsetHeight;
        leftdiv.style.height = offsetHeight + 60 + 'px';
    }

    useEffect(() => {
        if (activeSubTab == 0) {
            setToggleTabLi(true);
        } else {
            setToggleTabLi(false);
        }
    }, [activeSubTab]);

    //@Rahul K last save question
    const [isTabClick, setIsTabClick] = useState<boolean>(false);

    // useEffect(() => {
    //     if (isTabClick === false && data.questionnaireFullData?.last_user_question_position) {
    //         let tempQueData = cloneDeep(questionPreviewData);
    //         tempQueData.forEach((question: any) => {
    //             if (data.questionnaireFullData?.last_user_question_position.question_id === question.question_id) {
    //                 setactiveSubTab(question.indexval);
    //             }
    //         })
    //     }
    // }, [questionPreviewData]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [activeSubTab]);

    useEffect(() => {
        dispatch(QuestionnaireAction.getQuestionnaireFulldata({ id: params.id }));

        return () => {
            //Clear PreStore Data
            dispatch(QuestionnaireAction.getQuestionnaireFulldataSuccess(""))
            dispatch(QuestionnaireAction.getQuestionnaireFulldataFail(""))
        }
    }, [])

    useEffect(() => {
        if (data.fullDataQuestionError) {
            setErrorData(data.fullDataQuestionError);
            setIsError(true)
        }
    }, [data.fullDataQuestionError])

    const [userData, setUserData] = useState({
        tab_id: params.id,
    });



    function renderRowHTML(rowContent: any) {
        return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
    }

    const updateQuestionAr = (questionDataAr: any) => {
        let questionAr: any = [];
        // let obj: any = {};
        // questionDataAr.forEach((question: any) => {
        //     if (question.map_field_name != "" && question.user_data_answer == null) {
        //         type ObjectKey = keyof typeof obj;
        //         const map_field_name = 'user_' + question.map_field_name as ObjectKey;
        //         question.user_data_answer = questionnaireData[map_field_name];
        //     }
        //     obj = { ...obj, [question.reference_name]: question.user_data_answer };
        // });
        const condtionObj = Object.keys(intialData);
        const condtionAns = Object.values(intialData);
        questionDataAr = questionDataAr.filter((question: any) => question.tab_id === activeTab);
        if (questionDataAr) {
            questionDataAr.forEach((question: any) => {
                let checkcondition = false;
                if ((question.condition_reference_question == null) || (!condtionObj.includes(question.condition_reference_question))) {
                    questionAr.push(question);
                } else if (condtionObj.includes(question.condition_reference_question) || condtionObj.includes(question.condition_reference_question_2)) {
                    if (condtionObj.includes(question.condition_reference_question)) {
                        const answer_label = question.condition_answer_label;
                        const index = condtionObj.indexOf(question.condition_reference_question);
                        if (answer_label !== null && question.condition_input_type != 2) {
                            const answAr = answer_label.split(',');
                            // if (answAr.includes(condtionAns[index]) && (!condtionObj.includes(question.condition_reference_question_2) || question.condition_reference_question_2 == null)) {
                            //     questionAr.push(question);
                            // }
                            // else 
                            if (answAr.includes(condtionAns[index])) {
                                //checkcondition = true;
                                questionAr.push(question);
                            }
                            // else if(condtionAns[index] == null){
                            //     questionAr.push(question);
                            // }
                        }

                        else if (condtionAns[index] == '1' && question.condition_answer_label == "Yes") {
                            //checkcondition = true;
                            questionAr.push(question);
                        }
                        else if (condtionAns[index] == '0' && question.condition_answer_label == "No") {
                            //checkcondition = true;
                            questionAr.push(question);
                        }
                    }

                }
            })

            //change indexval
            const array_val = questionAr;
            let count = 1;
            let tab_id = 0;
            questionAr.forEach((question: any, key: any) => {
                if (question.tab_id != tab_id) {
                    tab_id = question.tab_id;
                    count = 1;
                }
                question.indexval = count;
                array_val.forEach((qu: any, key1: any) => {
                    if (qu.question_id == question.same_page_with && question.same_page_with != null) {
                        question.indexval = qu.indexval;
                        count--;
                    }
                })
                count++;
                question.user_id = userID;
                question.user_id_code = userIDCode;
            })

            if (questionAr && questionAr[questionAr.length - 1] && questionAr[questionAr.length - 1].indexval && !needfile) {
                settotaltab(questionAr[questionAr.length - 1].indexval);
            } else {
                settotaltab(0);
            }
            setQuestionPreviewData(questionAr);
            setIsQuestionLoad(true);
            setLoader(false);
        }
    }


    /// file upload
    const [multiUploadStatus, setMultiUploadStatus] = useState(false);
    const multiUploadSuccess = (isSuccess: boolean) => {
        if (isSuccess === true) {
            setMultiUploadStatus(true);
        }
    };

    useEffect(() => {
        setUser_id(props.user_id);
    }, [props])


    const [requiredtab, setRquiredTab] = useState(false);
    const togglerequired = () => {
        const questionAr: any = questionPreviewData.filter((question: any) => (question.indexval == activeSubTab && question.required_field == 1 && (question.user_data_answer == null || question.user_data_answer == "")));
        if (questionAr.length == 0) {
            setRquiredTab(false);
            setactiveSubTab(activeSubTab + 1);
        }
        else {
            setRquiredTab(true);
        }

    }

    const handleChangeCountry = (name: any, value: any) => {
        setIntialData((intialData: any) => {
            return {
                ...intialData,
                [name]: value 
            };
        });
    }

    useEffect(() => {
        if (questionsData.length > 0) {
            let objcop = {};
            let obj_map: any = {};
            questionsData.forEach((question: any) => {
                //if (question.map_field_name != null && question.map_field_type != null && question.map_field_name !== "risque_date" && question.map_field_name !== "risque_status" && question.map_field_name !== "aml_expiry_date" && question.map_field_name !== "ubo_expiry_date" && question.map_field_name !== "signed_date" && (questionnairestatus == 5 && questionnairestatus == 6 || question.user_data_answer == null)) {
                if (question.map_field_name != null && question.map_field_type != null && question.map_field_name !== "risque_date" && question.map_field_name !== "risque_status" && question.map_field_name !== "aml_expiry_date" && question.map_field_name !== "ubo_expiry_date" && question.map_field_name !== "signed_date" && (question.user_data_answer == null)) {
                    type ObjectKey = keyof typeof obj_map;
                    const map_type = question.map_field_type == null ? 'user' : question.map_field_type;
                    const map_field_name = map_type + '_' + question.map_field_name as ObjectKey;
                    question.user_data_answer = questionnaireData[map_field_name];
                    if (question.map_field_name == "civil_status" && question.input_type == 3) {
                        const civil_data = civil_status_master.filter((mst: any) => mst.value == questionnaireData[map_field_name]);
                        if (civil_data.length) {
                            question.user_data_answer = civil_data[0].label;
                        }

                    }
                }
                let obj = { [question.reference_name]: question.user_data_answer };
                objcop = Object.assign(objcop, obj);
            });
            setIntialData(objcop);
        }
    }, [questionsData]);


    useEffect(() => {
        if (questionnaireFullData.questions_data) {
            let userData = {};
            const questionAr: any = questionnaireFullData.questions_data;
            questionAr.forEach((question: any) => {
                if (userAddress?.address) {
                    if (question.map_field_name == "address" && question.map_field_type == "user") {
                        userData = ({ ...userData, [question.reference_name]: userAddress?.address });
                    }
                    if (question.map_field_name == "address_number" && question.map_field_type == "user") {
                        userData = ({ ...userData, [question.reference_name]: userAddress?.address_number });
                    }
                    if (question.map_field_name == "box" && question.map_field_type == "user") {
                        userData = ({ ...userData, [question.reference_name]: userAddress?.box });
                    }
                    if (question.map_field_name == "postal_code" && question.map_field_type == "user") {
                        userData = ({ ...userData, [question.reference_name]: userAddress?.postal_code });
                    }
                    if (question.map_field_name == "city" && question.map_field_type == "user") {
                        userData = ({ ...userData, [question.reference_name]: userAddress?.city });
                    }
                    if (question.map_field_name == "commune" && question.map_field_type == "user") {
                        userData = ({ ...userData, [question.reference_name]: userAddress?.commune });
                    }
                    if (question.map_field_name == "country" && question.map_field_type == "user") {
                        userData = ({ ...userData, [question.reference_name]: userAddress?.country });
                    }
                }
                if (companyAddress?.address) {
                    if (question.map_field_name == "company_address" && question.map_field_type == "company") {
                        userData = ({ ...userData, [question.reference_name]: companyAddress?.address });
                    }
                    if (question.map_field_name == "office_number" && question.map_field_type == "company") {
                        userData = ({ ...userData, [question.reference_name]: companyAddress?.address_number });
                    }
                    if (question.map_field_name == "box" && question.map_field_type == "company") {
                        userData = ({ ...userData, [question.reference_name]: companyAddress?.box });
                    }
                    if (question.map_field_name == "postal_code" && question.map_field_type == "company") {
                        userData = ({ ...userData, [question.reference_name]: companyAddress?.postal_code });
                    }
                    if (question.map_field_name == "city" && question.map_field_type == "company") {
                        userData = ({ ...userData, [question.reference_name]: companyAddress?.city });
                    }
                    if (question.map_field_name == "commune" && question.map_field_type == "company") {
                        userData = ({ ...userData, [question.reference_name]: companyAddress?.commune });
                    }
                    if (question.map_field_name == "country" && question.map_field_type == "company") {
                        userData = ({ ...userData, [question.reference_name]: companyAddress?.country });
                    }
                }
                if (companyInvAddress?.address) {
                    if (question.map_field_name == "inv_company_address" && question.map_field_type == "company") {
                        userData = ({ ...userData, [question.reference_name]: companyAddress?.address });
                    }
                    if (question.map_field_name == "inv_office_number" && question.map_field_type == "company") {
                        userData = ({ ...userData, [question.reference_name]: companyAddress?.address_number });
                    }
                    if (question.map_field_name == "inv_box" && question.map_field_type == "company") {
                        userData = ({ ...userData, [question.reference_name]: companyAddress?.box });
                    }
                    if (question.map_field_name == "inv_postal_code" && question.map_field_type == "company") {
                        userData = ({ ...userData, [question.reference_name]: companyAddress?.postal_code });
                    }
                    if (question.map_field_name == "inv_city" && question.map_field_type == "company") {
                        userData = ({ ...userData, [question.reference_name]: companyAddress?.city });
                    }
                    if (question.map_field_name == "commune" && question.map_field_type == "company") {
                        userData = ({ ...userData, [question.reference_name]: companyAddress?.commune });
                    }
                    if (question.map_field_name == "inv_country" && question.map_field_type == "company") {
                        userData = ({ ...userData, [question.reference_name]: companyAddress?.country });
                    }
                }
            })
            setIntialData((intialData: any) => {
                return {
                    ...intialData,
                    ...userData
                };
            });
        }
    }, [userAddress, companyAddress, companyInvAddress])

    //methods for on change event
    const handleChange = (e: any) => {
        setIntialData((intialData: any) => {
            return {
                ...intialData,
                [e.target.name]: e.target.value
            };
        });
    }

    const handleChangecheck = (e: any, togglenext: any, togglenexttab: number, navigate_tab: number) => {
        const pre_check_val = e.target.checked ? 1 : 0;
        setIntialData((intialData: any) => {
            return {
                ...intialData,
                [e.target.name]: pre_check_val
            };
        });
        if (activeTab != totalMenu && togglenexttab == pre_check_val && navigate_tab) {
            toggleTab(navigate_tab); // navigate to corresponsding tab
        } else if (activeTab != totalMenu && togglenexttab == pre_check_val) {
            toggleTab(activeTab + 1); // navigate to next tab
        }
        if (!navigate_tab) {
            if (activeSubTab != totaltab && (togglenext == pre_check_val)) {
                setactiveSubTab(activeSubTab + 1);
            } else if (activeSubTab != totaltab && (togglenext == null)) {
                const subtabquestion: any = questionPreviewData.filter((question: any) => (question.indexval === activeSubTab && question.togglenext == null));
                if (subtabquestion.length <= 1) {
                    setactiveSubTab(activeSubTab + 1); // navigate only if tab contains one question
                }
            }

        }
    }

    const handleChangeMulti = (e: any, togglenext: number, togglenexttab: number, navigate_tab: number) => {
     
        if (activeTab != totalMenu && togglenexttab == e.target.value && navigate_tab) {
            toggleTab(navigate_tab); // navigate to corresponsding tab
        } else if (activeTab != totalMenu && togglenexttab == e.target.value) {
            toggleTab(activeTab + 1); // navigate to next tab
        }
        if (!navigate_tab) {
            if (activeSubTab != totaltab && (togglenext == e.target.value)) {
                setactiveSubTab(activeSubTab + 1);
            } else if (activeSubTab != totaltab && (togglenext == null)) {
                const subtabquestion: any = questionPreviewData.filter((question: any) => (question.indexval === activeSubTab && question.togglenext == null));
                if (subtabquestion.length <= 1) {
                    setactiveSubTab(activeSubTab + 1); // navigate only if tab contains one question
                }
            }
        }

           
        setIntialData((prevData: any) => ({
            ...prevData,
            [e.target.name]: e.target.value
          }));
    }

    function handleMultiInputChange(e: any, ref: any) {
        setIntialData((intialData: any) => {
            return {
                ...intialData,
                [ref]: e.data 
            };
        });
    }

    const handleChangeMulticheckbox = (e: any, answer: any) => {
        setMulticheckname(e.target.name);
        if (e.currentTarget.checked) {
            setMulticheck([...answer, e.target.value]);
        } else {
            var index = answer.indexOf(e.target.value);
            if (index !== -1) {
                answer.splice(index, 1);
            }
            setMulticheck(answer);
        }
    }

    useEffect(() => {
        if (multicheckname != "") {
            setIntialData((intialData: any) => {
                return {
                    ...intialData,
                    [multicheckname]: multicheck
                };
            });
        }
    }, [multicheck, multicheckname]);


    const handlecalender = (name: any, dateval: any) => {
        setIntialData((intialData: any) => {
            return {
                ...intialData,
                [name]: moment(dateval).format("YYYY-MM-DD hh:mm:ss")
            };
        });
    }

    const handleKeyDown = (e: any) => {
        if (e.keyCode === 13 && activeSubTab != totaltab) {
            setactiveSubTab(activeSubTab + 1);
        }
    }


    useEffect(() => {
        // setRquiredTab(false);
        if (questionnaireFullData.questions_data) {
            let questionAr: any = questionnaireFullData.questions_data.filter((question: any) => question.tab_id === activeTab || question.condition_to_other);
            for (const [key, value] of Object.entries(intialData)) {
                questionAr.forEach((question: any) => {
                    if (question.reference_name == key) {
                        question.user_data_answer = value;
                    }
                    if (value != "" && value != null) {
                        const percentage_field: any = questionAr.filter((data: any) => data.perentage_depend_id == question.question_id);
                        if (percentage_field && percentage_field.length) {
                            percentage_field.forEach((elementper: any) => {
                                elementper.user_data_answer = 0;
                                const val_per = (100 - question.user_data_answer);
                                if (val_per != 100) {
                                    const matchingQuestion = questionAr.find((q: any) => q.question_id === elementper?.question_id);
                                    if (matchingQuestion) {
                                        matchingQuestion.user_data_answer = val_per;
                                    }
                                }
                            });
                        }

                    }
                })
            }
            updateQuestionAr(questionAr);
        }
    }, [activeTab, intialData, questionnaireFullData.questions_data])

    const getAnswerAr = () => {
        let questionArray: any = [];
        for (const [key_file, value_file] of Object.entries(filevalue)) {
            const tab_id_file = key_file.split("_")[2];
            questionArray.push({
                user_id: user_id,
                tab_id: tab_id_file,
                file: (value_file == 1 ? true : false),
                questionnaire_id: params.id,
                question_id: "",
                answer: "",
                question: ""
            });
        };


        questionsData.forEach((question: any, key: any) => {
            for (const [key, value] of Object.entries(intialData)) {
                if (question.reference_name == key) {
                    questionArray.push({
                        user_id: user_id,
                        tab_id: question.tab_id,
                        file: 0,
                        questionnaire_id: params.id,
                        question_id: question.question_id,
                        answer: typeof value === 'object' ? JSON.stringify(value) : value,
                        question: question.question
                    });
                }
            }
        })
        return questionArray;
    }

    const onSubmitClose = async () => {
        const answers = await getAnswerAr();
        const messg = t(
            `Saving data...`
          );
        toastId.current = toast(messg, {
            autoClose: false,
            isLoading: true,
        });
        dispatch(QuestionnaireUserDataAction.addNewQuestionnaireUserData({ questionAr: answers, usertype: props.usertype, "questionnaire_id": params.id, last_tab: activeTab, last_sub_tab: activeSubTab, answered_tabs: JSON.stringify(tabData) }));
    }



    const onSubmitQuestionnaire = async (admin: any, filevalue: any) => {
        const answers = await getAnswerAr();
        const messg = t(
            `Saving data...`
          );
        toastId.current = toast(messg, {
            autoClose: false,
            isLoading: true,
        });

        dispatch(QuestionnaireUserDataAction.SubmitQuestionnaireUserData({ questionAr: answers, usertype: props.usertype, "questionnaire_id": params.id, answered_tabs: JSON.stringify(tabData) }));
    }


    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    useEffect(() => {
        if (data.success) {
            const messg = t(
                `Data saved successfully`
              );
            toast.update(toastId.current, {
                type: toast.TYPE.SUCCESS,
                render: messg,
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });
            dispatch(QuestionnaireUserDataAction.SubmitQuestionnaireUserDataSuccess(""));
            return navigate("/questionnaires/all-questionnaires");
        } else if (error !== data.error) {
            dispatch(QuestionnaireUserDataAction.SubmitQuestionnaireUserDataFail(""));
            const messg = t(
                `Sorry, something went wrong!`
              );
            toast.update(toastId.current, {
                type: toast.TYPE.ERROR,
                render: messg,
                autoClose: 5000,
                isLoading: false,
                delay: 500,
            });
        }

    }, [data.success, data.error])


    const scrollToTop = (id: string) => {
        if (id) {
            document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const [toggleTabLi, setToggleTabLi] = useState(true);
    const toggleTabMenu = () => {
        setToggleTabLi(!toggleTabLi);
    }

    const multiInputwithCondition = (questionrow: any) => {

        if (shouldRenderDiv(questionrow.user_data_answer)) {
            return (
                <MultiInput
                    label={questionrow.question}
                    multi_input_data={questionrow.multi_input_data}
                    reference_name={questionrow.reference_name}
                    handleMultiInputChange={(e: any, ref: any) => handleMultiInputChange(e, ref)}
                    user_data_answer={questionrow.user_data_answer}
                    is_disabled={true}
                />
            )
        } else {
            return null;
        }
    }

    const TableInputWithcondition = (questionrow: any) => {
        if (shouldRenderDiv(questionrow.user_data_answer)) {
            return (<TableMultiInput
                label={questionrow.question}
                multi_input_data={questionrow.multi_input_data}
                reference_name={questionrow.reference_name}
                handleMultiInputChange={(e: any, ref: any) => handleMultiInputChange(e, ref)}
                user_data_answer={questionrow.user_data_answer}
                is_disabled={true}
            />);
        }
        else {
            return null;
        }
    }


    const shouldRenderDiv = (user_data_answer: any) => {
        const val_ar: any = user_data_answer;
        for (let i = 0; i < val_ar?.length; i++) {
            for (let j = 0; i < val_ar[i]?.length; j++) {
                if (val_ar[i][j]?.value != "") {
                    return true;
                }
            }
        }
        return false;
    }


    return (
        <Card>
            <CardBody >
                {loader ? <Loader /> : null}
                {
                    !isError ?
                        <div className='questionnaire'>
                            <Row>

                                <div className='d-flex flex-row justify-content-lg-between'>
                                    {questionnaireActive ?
                                        <span
                                            className='text-left font-size-16'
                                            role='button'
                                            onClick={toggleTabMenu}
                                        >
                                            <i className={toggleTabLi ? "fa fa-arrow-alt-circle-left" : "fa fa-arrow-alt-circle-right"}></i>
                                        </span>
                                        : null}
                                    <h3 className='text-center text-primary'>
                                        {mainTitle != "" ? mainTitle : null}
                                    </h3>
                                    {questionnaireActive ?
                                        <h5 className='text-right'>
                                            <span className="badge rounded-pill badge_custome" style={{ color: "#ffffff", backgroundColor: questionnaireData.questionnaire_status_color_code }}>
                                                {userType == "user" && (questionnaireData.questionnaire_status_value == "SENT") ? "YET TO START" : ((userType == "user" && questionnaireData.questionnaire_status_value == "INREVIEW") ? "SUBMITTED" : questionnaireData.questionnaire_status_value)}
                                            </span>
                                        </h5>
                                        : null
                                    }
                                </div>
                            </Row>
                            <Row>
                                {toggleTabLi ?
                                    <Col lg="3" className='items'>
                                        <div className="items-head">
                                            <p>{t('QUESTIONNAIRE', { lng: language })}</p>
                                        </div>

                                        <div className="items-body">
                                            {tabs &&
                                                <>
                                                    {
                                                        tabs.map((item: any, key: any) => {
                                                            return (
                                                                <div className={activeTab === item.id ? 'items-body-content active' : 'items-body-content'} onClick={() => {
                                                                    toggleTab(item.id);
                                                                    setIsTabClick(true);
                                                                }} key={key}>
                                                                    <span onClick={() => scrollToTop(item.questionnaire_tab_value)} id={item.id}>{removeTags(item.questionnaire_tab_value)}</span>
                                                                    <i className="fa fa-angle-right"></i>
                                                                </div>

                                                            )
                                                        })
                                                    }
                                                </>
                                            }

                                        </div>

                                    </Col>
                                    : null}
                                <Col lg={toggleTabLi ? '9' : '12'}>
                                    {!isQuestionLoad ?

                                        <div className='justify-content-center align-self-center text-center'>
                                            <div className='text-primary'>{t("Please wait", { lng: language })}...</div>
                                            <div><Spinner size="sm" color="primary" style={{ 'position': 'relative', 'right': '-5px', 'top': '5px', 'borderColor': '#564AB1', 'borderRightColor': '#ccc', 'margin': '0 auto' }} /></div>
                                        </div>

                                        :
                                        <Card className={intialPage ? 'intial-page-card' : ''}>
                                            <CardBody>
                                                <Row className={intialPage ? 'intial-page-row' : ''}>
                                                    {!!intialPage ?
                                                        <Col lg="12" className='d-flex align-items-center h-100'> {renderRowHTML(questionnaireContent)}

                                                        </Col>
                                                        :
                                                        <>
                                                            <Col lg="6" id="filled-details" className='filled-details'>
                                                                <Row>
                                                                    <div id="left_form" className={activeSubTab !== 0 ? 'next_fields mb-3' : 'mb-3'}>
                                                                        <h3 className='text-center'>{renderRowHTML(title)}</h3>
                                                                        {intialTab ?
                                                                            <>
                                                                                <Row className='mt-5 pt-5'>
                                                                                    <span>{t('If this ')} <b>{removeTags(title)} </b>{t('is not applicable to you or if you have already provided the information to us then please click on Not Applicable button to go next ')}<b>{removeTags(activeNextTabName ? activeNextTabName : "")}</b>.</span>
                                                                                </Row>
                                                                                <Button className='mt-5 btn btn-md btn-danger' onClick={clickNonapplicable}>{t('Not applicable')}</Button>
                                                                            </>
                                                                            : null}
                                                                    </div>

                                                                    {questionPreviewData && questionPreviewData.map((questionrow: any, key: any) => (
                                                                        <React.Fragment key={key}>
                                                                            {(questionrow.indexval != activeSubTab) ?
                                                                                <>
                                                                                    {(!intialTab && !needfile) &&
                                                                                        <>

                                                                                            {(questionrow.input_type === 9 || questionrow.input_type === 21) ?
                                                                                                <>

                                                                                                    {questionrow.input_type === 9 ?
                                                                                                        <>
                                                                                                            <div onClick={() => handleClick(questionrow.indexval)}>
                                                                                                                {multiInputwithCondition(questionrow)}
                                                                                                            </div>
                                                                                                        </>
                                                                                                        :
                                                                                                        <>
                                                                                                            <div onClick={() => handleClick(questionrow.indexval)}>
                                                                                                                {TableInputWithcondition(questionrow)}
                                                                                                            </div>

                                                                                                        </>
                                                                                                    }
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    {!!isSubTabAvailable(questionrow.user_data_answer, questionrow.tab_id, questionrow.indexval) && (
                                                                                                        <Col lg={questionrow.field_width ? questionrow.field_width : 12}>
                                                                                                            <div onClick={() => handleClick(questionrow.indexval)}>
                                                                                                                <QuestionView questionrow={questionrow} input_type={questionrow.input_type} field_width={questionrow.field_width} question={questionrow.question} reference_name={questionrow.reference_name} placeholder={questionrow.placeholder} user_data_answer={questionrow.user_data_answer} countryOptions={countryOptions}
                                                                                                                    answer_option_list={questionrow.answer_option_list} handleChange={handleChange} handleKeyDown={handleKeyDown} handleChangeMulti={(e: any) => handleChangeMulti(e, questionrow.togglenext, questionrow.togglenexttab, questionrow.navigate_tab)} handleMultiInputChange={(e: any, ref: any) => handleMultiInputChange(e, ref)}
                                                                                                                    handleChangeMulticheckbox={handleChangeMulticheckbox} handleChangeCountry={handleChangeCountry} handlecalender={handlecalender} handleChangecheck={handleChangecheck} adminview={true} questionnaireID={id} questionnaireSubID={questionnaire_id} section_id={questionrow.question_id}
                                                                                                                    multi_input_data={questionrow.multi_input_data}
                                                                                                                    answerOnly={true} required={requiredtab}
                                                                                                                    year={year}
                                                                                                                    user_id={user_id}
                                                                                                                    setUserAddress={setUserAddress}
                                                                                                                    setCompanyAddress={setCompanyAddress}
                                                                                                                    setCompanyInvAddress={setCompanyInvAddress}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </Col>
                                                                                                    )
                                                                                                    }
                                                                                                </>
                                                                                            }

                                                                                        </>
                                                                                    }
                                                                                </>
                                                                                : null
                                                                            }
                                                                        </React.Fragment>
                                                                    ))}
                                                                </Row>
                                                            </Col>

                                                            <Col lg="6" className='questionnaire-form' id="questionnaire-form">
                                                                {questionPreviewData &&
                                                                    <div>
                                                                        <div id="top-wizard">
                                                                            <Progress color="primary" value={activeSubTab * 100 / totaltab}></Progress>
                                                                            <span id="location">{activeSubTab} {t('of')} {totaltab} {t('completed')}</span>

                                                                        </div>
                                                                        <div id="wizard_container">
                                                                            <Row><h3 className='text-center text-primary mb-5'>{renderRowHTML(title)}</h3></Row>
                                                                            <Row>
                                                                                {!!(activeSubTab == 0) &&
                                                                                    <Col lg="12">
                                                                                        <h4 className="text-left text-warning mb-3">{t("Documents", { lng: language })}</h4>
                                                                                        <Label for="basicpill-phoneno-input3" className="document-label">
                                                                                            {t("Do you want to upload documents instead of answering questions?", { lng: language })}
                                                                                            <span className='tooltip-txt'><i className="fa-regular fas fa-info-circle"></i><span className="tooltiptext">{tooltip ? renderRowHTML(tooltip) : `Please upload your documents reletaed to your ${removeTags(title)}`}</span></span>
                                                                                        </Label>
                                                                                        <div className="form-group">
                                                                                            <label className="container_radio version_2">Yes
                                                                                                <input type="radio" value={1} name={`sent_file_${activeTab}`} className="required" onChange={handleChangeFile} checked={
                                                                                                    needfile == true
                                                                                                        ? true
                                                                                                        : false
                                                                                                } />
                                                                                                <span className="checkmark"></span>
                                                                                            </label>
                                                                                            <label className="container_radio version_2">No
                                                                                                <input type="radio" value={0} name={`sent_file_${activeTab}`} className="required" onChange={handleChangeFile} checked={
                                                                                                    needfile == false
                                                                                                        ? true
                                                                                                        : false
                                                                                                } />
                                                                                                <span className="checkmark"></span>
                                                                                            </label>
                                                                                        </div>
                                                                                    </Col>
                                                                                }
                                                                                {(activeSubTab == 0 && needfile == true) ?
                                                                                    <Row>
                                                                                        <Col className="col-12">
                                                                                            <div className="mb-3">
                                                                                                <Label className="form-label">{t(`Attachments`)}</Label>
                                                                                                <Dropzone
                                                                                                    moduleData={{
                                                                                                        module_app_id: 6,
                                                                                                        module_main_id: Number(id),
                                                                                                        module_sub_id: questionnaire_id,
                                                                                                        section_id: activeTab,
                                                                                                        file_path: "questionnaire",
                                                                                                        propchange: true,
                                                                                                    }}
                                                                                                    //hiddenColumns={[2]}
                                                                                                    fetchData={true}
                                                                                                    isOpen={false}
                                                                                                />
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    : null
                                                                                }
                                                                                {!needfile ?
                                                                                    <>
                                                                                        {questionPreviewData && questionPreviewData.map((questionrow: any, key: any) => (
                                                                                            <React.Fragment key={key}>
                                                                                                {(questionrow.indexval === activeSubTab) &&
                                                                                                    <>
                                                                                                        {(questionrow.input_type === 9 || questionrow.input_type === 21) ?
                                                                                                            <>
                                                                                                                {questionrow.input_type === 9 ?
                                                                                                                    <MultiInput
                                                                                                                        label={questionrow.question}
                                                                                                                        multi_input_data={questionrow.multi_input_data}
                                                                                                                        reference_name={questionrow.reference_name}
                                                                                                                        handleMultiInputChange={(e: any, ref: any) => handleMultiInputChange(e, ref)}
                                                                                                                        user_data_answer={questionrow.user_data_answer}
                                                                                                                        is_disabled={false}
                                                                                                                    />
                                                                                                                    :
                                                                                                                    <TableMultiInput
                                                                                                                        label={questionrow.question}
                                                                                                                        multi_input_data={questionrow.multi_input_data}
                                                                                                                        reference_name={questionrow.reference_name}
                                                                                                                        handleMultiInputChange={(e: any, ref: any) => handleMultiInputChange(e, ref)}
                                                                                                                        user_data_answer={questionrow.user_data_answer}
                                                                                                                        is_disabled={false}
                                                                                                                    />
                                                                                                                }
                                                                                                            </>
                                                                                                            :
                                                                                                            <Col lg={questionrow.field_width ? questionrow.field_width : 12}>
                                                                                                                <QuestionView questionrow={questionrow} input_type={questionrow.input_type} field_width={questionrow.field_width} question={questionrow.question} reference_name={questionrow.reference_name} placeholder={questionrow.placeholder} user_data_answer={questionrow.user_data_answer} countryOptions={countryOptions}
                                                                                                                    answer_option_list={questionrow.answer_option_list} handleChange={handleChange} handleKeyDown={handleKeyDown} handleChangeMulti={(e: any) => handleChangeMulti(e, questionrow.togglenext, questionrow.togglenexttab, questionrow.navigate_tab)} handleMultiInputChange={(e: any, ref: any) => handleMultiInputChange(e, ref)}
                                                                                                                    handleChangeMulticheckbox={handleChangeMulticheckbox} handleChangeCountry={handleChangeCountry} handlecalender={handlecalender} handleChangecheck={handleChangecheck} adminview={true} questionnaireID={id} questionnaireSubID={questionnaire_id} section_id={questionrow.question_id}
                                                                                                                    multi_input_data={questionrow.multi_input_data}
                                                                                                                    answerOnly={undefined} required={requiredtab}
                                                                                                                    year={year}
                                                                                                                    user_id={user_id}
                                                                                                                    setUserAddress={setUserAddress}
                                                                                                                    setCompanyAddress={setCompanyAddress}
                                                                                                                    setCompanyInvAddress={setCompanyInvAddress}
                                                                                                                    setactiveTab={setactiveTab}
                                                                                                                    activeNextTab={activeNextTab}
                                                                                                                    activeTab={activeTab}
                                                                                                                    onSubmitClose={onSubmitClose}
                                                                                                                />
                                                                                                            </Col>
                                                                                                        }
                                                                                                    </>
                                                                                                }
                                                                                            </React.Fragment>
                                                                                        ))}
                                                                                    </>
                                                                                    : null
                                                                                }
                                                                            </Row>
                                                                            <br />

                                                                            <div className="wizard clearfix">
                                                                                <div className="actions clearfix">
                                                                                    <ul>
                                                                                        <li
                                                                                            className={
                                                                                                (activeSubTab == 0) && (
                                                                                                    activeTab == activeFirstTab && !questionnaireContent?.length
                                                                                                ) ? "previous disabled" : "previous"
                                                                                            }
                                                                                        >
                                                                                            <Link
                                                                                                to="#"
                                                                                                onClick={onPreviousClick}
                                                                                            >
                                                                                                {t("Previous")}
                                                                                            </Link>
                                                                                        </li>

                                                                                        <li>
                                                                                            <Button
                                                                                                className={
                                                                                                    (activeSubTab == totaltab) && (
                                                                                                        activeTab == totalMenu
                                                                                                    ) ? "next disabled" : "next"
                                                                                                }
                                                                                                onClick={onNextClick}
                                                                                                color="primary"
                                                                                                type='submit'>
                                                                                                {t("Next")}
                                                                                            </Button>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </Col>
                                                        </>
                                                    }
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    }
                                    {!!intialPage ?
                                        <div className="wizard clearfix">
                                            <div className="actions clearfix">
                                                <ul>
                                                    <li>
                                                        <Button
                                                            className="next"
                                                            onClick={() => toggleTab(activeTab)}
                                                            color="primary"
                                                            type='submit'>
                                                            {t("Next")}
                                                        </Button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        :
                                        <>
                                            {
                                                !!(questionnairestatus && (questionnairestatus === 1 || questionnairestatus == 4 || questionnairestatus == 8)) &&
                                                <Row>
                                                    <CardFooter className="d-flex flex-wrap gap-2 bg-transparent justify-content-end">
                                                        <Button
                                                            color="primary"
                                                            className="btn-block"
                                                            type="submit"
                                                            onClick={() => onSubmitQuestionnaire("customer", filevalue)}
                                                            disabled={!(((activeSubTab == totaltab) && (
                                                                activeTab == totalMenu
                                                            )) || isSubmit)}
                                                        >

                                                            {data.loading ? (
                                                                <span>
                                                                    <i className="fa-solid fa-spinner fa-spin"></i>
                                                                    <span>&nbsp;  {t(`Please wait...`)}</span>
                                                                </span>
                                                            ) : (
                                                                <span>Submit </span>
                                                            )}
                                                        </Button>
                                                        <Button
                                                            color="primary"
                                                            className="btn-block"
                                                            type="submit"
                                                            onClick={onSubmitClose}
                                                            disabled={data.loading}
                                                        >
                                                            {data.loading ? (
                                                                <span>
                                                                    <i className="fa-solid fa-spinner fa-spin"></i>
                                                                    <span>&nbsp; {t(`Please wait...`)}</span>
                                                                </span>
                                                            ) : (
                                                                <span>Save </span>
                                                            )}
                                                        </Button>

                                                        {/* <Button
                                                                color="outline-danger"
                                                                className="btn-block"
                                                                type="button"
                                                                onClick={() => updateInitialData()}
                                                                disabled={data.loading}
                                                            >
                                                                {data.loading ? (
                                                                    <span>
                                                                        <i className="fa-solid fa-spinner fa-spin"></i>
                                                                        <span>&nbsp; Please wait...</span>
                                                                    </span>
                                                                ) : (
                                                                    <span>Reset</span>
                                                                )}
                                                            </Button> */}

                                                        {/* <Link
                                                    className="btn btn-block btn-outline-danger"
                                                    to={"/questionnaires/all-questionnaires"}
                                                >
                                                    Cancel
                                                </Link> */}
                                                    </CardFooter>
                                                </Row>
                                            }
                                        </>
                                    }
                                </Col>
                            </Row>
                        </div>
                        : <div><Alert color="warning">{errorData.message}</Alert></div>}
            </CardBody>
        </Card>
    )
}

export default CustomerView