import React, { FC } from "react";
import { useTranslation } from "react-i18next";

type LoadingSpinnerProps = {
    height?: number;
}

const LoadingSpinner: FC<LoadingSpinnerProps> = ({ height = 300 }) => {
    const { t } = useTranslation();

    return (
        <div className="text-muted" style={{ height: `${height}px`, paddingTop: `${(height / 2) - 10}px`, textAlign: "center", fontSize: "1.5em" }}>
            <i className='fa fa-spinner fa-spin'></i> {t("Loading...")}
        </div>
    )
}

export default LoadingSpinner;