import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import logo from "../../../assets/images/tc-logo.png";
import AlliottLogo from "../../../assets/images/alliott-logo.jpg";

import './style.scss';

import { renderRowHTML } from '../../../utils/utils';
const MyPDFContent = (props: any) => {

    const unescape = (s: string) => {
        return s.replace(/&amp;/g, "&")
            .replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">")
            .replace(/&#39;/g, "'")
            .replace(/&quot;/g, '"');
    }
    return (
        <Container>
            <div className='pdfContainer'>
                {props.content.map((content: any, index: any) => (
                    <div className='pdfPage'>
                        <Header />
                        <div className='pdfBody'>
                            <Row>
                                <Col>
                                    {content ? renderRowHTML(unescape(content), '') : ''}
                                </Col>
                            </Row>
                        </div>
                        <Footer />
                    </div>
                ))}
            </div>
        </Container >
    );
};
const Header = () => {
    return (
        <Row>
            <Col><img src={logo} alt='logo' width='120px' /></Col>
            <Col><h1 style={{ color: '#c8222a', fontSize: '28px', textAlign: 'right' }}>HR Tax & IM</h1></Col>
        </Row>
    )
}

const Footer = () => {
    return (
        <Row className='invoiceFooter'>
            <Col className='col-12 strong'><p>SRL Tax Consult HR Tax & International Mobility BV</p></Col>
            <Col className='col-9'>
                <p>Avenue du Dirigeable 8<br />1170 Brussels</p>
            </Col>
            <Col className='col-3 tel-no'>Tel. +32 2 675 50 05</Col>
            <Col className='col-9'>
                <p>T.V.A/B.T.W. BE 0740.763.660
                    <br /><br />Member of Alliott Group, a Worldwide Alliance of Independent Accounting, Law and Consulting Firms
                </p>
            </Col>
            <Col className='col-3'><img src={AlliottLogo} alt='logo' width='200px' /></Col>
        </Row>

    )
}

export default MyPDFContent;
