import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import DataTable from "../../components/Common/DataTable";
import { getDataTableQuery, handleFilters } from "../../helpers/functions_helper";
import { useTranslation } from "react-i18next";
import saltedgeService from "../../services/saltedge.service";
import { renderRowHTML } from "../../utils/utils";
import { dateFormat } from "../../utils/formats";
import { getLangCode } from "../../common/staticdata";
import Classifications from "./Classifications";

const Transactions = (props: any) => {
  const { customer_id, connection_id, account_id } = props;
  const { t } = useTranslation();
  const savedfilters = handleFilters("GET");
  const [loadingListData, setLoadingListData] = useState(true);
  const [datatableListData, setDatatableListData] = useState([]);

  const columns = [
    {
      dataField: "DT_RowIndex",
      text: t("Sr. No."),
      sort: false,
      search: false,
      classes: "fix-col-left",
      headerClasses: "fix-col-left",
      headerStyle: { width: "65px" },
      style: { width: "65px" },
    },
    {
      dataField: "transactions_date",
      text: t("Made on"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return dateFormat(
          row?.transactions_date,
          {
            dateStyle: "long",
            timeStyle: "short",
            hour12: true,
          },
          getLangCode(localStorage.getItem("I18N_LANGUAGE"))
        );
      },
    },
    {
      dataField: "transactions_classification",
      text: t("Category"),
      sort: true,
      search: true,
    },
    {
      dataField: "transactions_info",
      text: t("Description"),
      sort: true,
      search: true,
      formatter: renderRowHTML,
    },
    {
      dataField: "transactions_amount",
      text: t("Transactions amount"),
      sort: true,
      search: true,
      align: "right",
    },
    {
      dataField: "action",
      text: t("Classification"),
      sort: true,
      search: true,
      formatter: (rowContent: any, row: any): any => {
        return <Classifications />;
      },
    },
  ];

  const [datatableOptions, setDatatableOptions] = useState(
    savedfilters && savedfilters?.datatableOptions
      ? (savedfilters?.datatableOptions as any)
      : {
          draw: 1,
          page: 1,
          recordsTotal: 0,
          recordsFiltered: 0,
          sizePerPage: 20,
          searchText: "",
          sortField: "id",
          sortOrder: "desc",
        }
  );

  const [filters, setFilters] = useState({
    connection_id: connection_id,
    account_id: account_id,
  });

  const onTableChange = (type: any, newState: any) => {
    let draw = datatableOptions.draw + 1;
    let page = 1;
    if (type == "search") {
      page = 1;
    } else {
      page = newState.page;
    }
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: draw,
      page: page,
      sizePerPage: newState.sizePerPage,
      searchText: newState.searchText,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
    };
    setDatatableOptions(updatedDatatableOptions);
    setDatatableListData([]);
    setLoadingListData(true);
  };

  const clearFilter = () => {
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: 1,
      page: 1,
      sizePerPage: 20,
      searchText: "",
      sortField: "id",
      sortOrder: "desc",
    };
    setDatatableOptions(updatedDatatableOptions);

    setDatatableListData([]);
    setLoadingListData(true);
  };

  const loadingDatatable = async () => {
    try {
      const res = (await saltedgeService.getTransactions({
        ...getDataTableQuery(datatableOptions, columns),
        filters,
      })) as any;

      if (res.data.status === "success") {
        setDatatableListData(res.data.data);

        const updatedDatatableOptions = {
          ...datatableOptions,
          recordsTotal: res.data.recordsTotal,
          recordsFiltered: res.data.recordsFiltered,
        };
        setDatatableOptions(updatedDatatableOptions);
        setLoadingListData(false);
      } else {
        setDatatableListData([]);
        setLoadingListData(false);
      }
    } catch (err) {
      setDatatableListData([]);
      setLoadingListData(false);
    }
  };

  useEffect(() => {
    if (loadingListData === true) {
      loadingDatatable();
    }
  }, [loadingListData]);

  useEffect(() => {
    setFilters({
      connection_id: connection_id,
      account_id: account_id,
    });
    setDatatableListData([]);
    setLoadingListData(true);
  }, [connection_id, account_id]);

  return (
    <React.Fragment>
      <DataTable
        columns={columns}
        products={datatableListData}
        datatableOptions={datatableOptions}
        onTableChange={onTableChange}
        loadingListData={loadingListData}
        clearFilter={clearFilter}
        settingTableName="banking_connections_table"
        slug="banking"
      />
    </React.Fragment>
  );
};

export default Transactions;
