import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Col, Table, Button, FormFeedback } from "reactstrap";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

// @ts-ignore
import CustomEditor from "ckeditor5-custom-build/build/ckeditor";
// @ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
import "ckeditor5-custom-build/build/translations/fr";
import "ckeditor5-custom-build/build/translations/nl";

import DeleteModal from "../../components/Common/DeleteModal";
import commonService from "../../services/common.service";
import { dateFormat } from "../../utils/formats";
import { ckEditorConfiguration, ckEditorI18, getUserProfile } from "../../helpers/functions_helper";
import { useTranslation } from "react-i18next";
import { getLangCode } from "../../common/staticdata";

// type MultiCommentsProps = {
//   moduleData: any,
//   commentForIDs: Array<any>[],
//   hiddenColumns: Array<any>[],
//   fetchData: boolean,
//   isOpen: boolean,
//   isDisabled: boolean,
//   insertId: number,
//   insertSubId: string,
//   onSuccess: boolean,
//   comment?:string,
// }

const MultiComments = (props: any) => {
  const { t } = useTranslation();
  const userProfile = getUserProfile();
  const [loading, setLoading] = useState(false);
  const [defaultSelected, setDefaultSelected] = useState(false);
  const toastId = React.useRef(null) as any;
  const { preComment } = props;
  const commentData = React.useRef("");
  const {
    moduleData,
    commentForIDs = [],
    hiddenColumns = [],
    fetchData = false,
    isOpen = false,
    isDisabled = false,
    insertId = 0,
    insertSubId = "",
    onSuccess = false,
  } = props;

  const [intialData, setIntialData] = useState<CommentProps>({
    module_app_id: 0,
    module_main_id: 0,
    module_sub_id: "",
    section_id: 0,
    comment: "",
    comment_for_ids: "",
  });

  // Get comment ------------------------------------------------------------
  const [commentsList, setCommentsList] = useState<any>([]);

  const getCommentList = async () => {
    setLoading(true);
    const response = await commonService.getCommentList(moduleData);
    if (response.data.status === "success") {
      setCommentsList(response.data.data.original.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (moduleData !== null && fetchData === true) {
      const updatedIntialData = {
        ...intialData,
        module_app_id: moduleData.module_app_id,
        module_main_id: moduleData.module_main_id,
        module_sub_id: moduleData.module_sub_id,
        section_id: moduleData.section_id,
      };
      setIntialData(updatedIntialData);
      getCommentList();
    }
  }, [fetchData]);
  // Get comment ------------------------------------------------------------

  // Create comment ------------------------------------------------------------
  const [commentBlock, setCommentBlock] = useState(isOpen);

  const CKinputHandler = (event: any, editor: any) => {
    setIntialData({ ...intialData, comment: editor.getData() });
  };

  const validationSchema = Yup.object().shape({
    comment: Yup.string().when("module_main_id", {
      is: (module_main_id: number) => module_main_id !== 0,
      then: Yup.string().required(t("Comments") + " " + t("field is required")),
    }),
  });

  const handleSubmit = () => {
    validation.handleSubmit();
    return false;
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: intialData,
    validationSchema: validationSchema,
    onSubmit: async (values: CommentProps) => {
      toast.dismiss();
      setLoading(true);
      toastId.current = toast(`${t("Updating")} ${t("comment")}...`, {
        autoClose: false,
        isLoading: true,
      });

      try {
        const res = (await commonService.createComment(values)) as any;
        if (res.data.status === "success") {
          toast.update(toastId.current, {
            type: toast.TYPE.SUCCESS,
            render: res.data.message,
            autoClose: 5000,
            isLoading: false,
            delay: 500,
          });
          // Add added row -----------------------------------------------------
          if (isOpen === false) {
            setCommentBlock(false);
          }
          const updatedIntialData = {
            ...intialData,
            comment: "",
          };
          setIntialData(updatedIntialData);

          if (typeof onSuccess == "function") {
            onSuccess(true);
            setCommentToIDs([]);
          }

          getCommentList();
          setLoading(false);
          // Add added row -----------------------------------------------------
        } else {
          toast.update(toastId.current, {
            type: toast.TYPE.ERROR,
            render: res.data.message,
            autoClose: 5000,
            isLoading: false,
            delay: 500,
          });
          setLoading(false);
        }
      } catch (err) {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: t("Something went wrong."),
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    if (insertId !== 0 && insertSubId !== "") {
      const updatedIntialData = {
        ...intialData,
        module_app_id: moduleData.module_app_id,
        module_main_id: insertId,
        module_sub_id: insertSubId,
        section_id: moduleData.section_id,
      };
      setIntialData(updatedIntialData);

      if (intialData.comment !== null && intialData.comment !== "") {
        handleSubmit();
      } else {
        onSuccess(true);
        setCommentToIDs([]);
      }
    }
  }, [insertId, insertSubId]);
  // Create comment ------------------------------------------------------------

  // Delete comment ------------------------------------------------------------
  const [comment, setComment] = useState({ id: 0, comment_id: "" });
  const [recordName, setRecordName] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const onClickDelete = (row: any) => {
    const deleteCommentData = {
      ...comment,
      id: row.id,
      comment_id: row.comment_id,
    };
    setComment(deleteCommentData);
    setRecordName("Comment");
    setDeleteModal(true);
  };
  const handleDeleteComment = async () => {
    setLoading(true);
    toastId.current = toast(`${t("Deleting")} ${t("comment")}...`, {
      autoClose: false,
      isLoading: true,
    });
    try {
      const res = (await commonService.deleteComment(comment)) as any;
      if (res.data.status === "success") {
        toast.update(toastId.current, {
          type: toast.TYPE.SUCCESS,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        // Delete deleted row -----------------------------------------------------
        const temp = commentsList.filter((data: any) => data.id != res.data.data.id);
        setCommentsList(temp);
        // Delete deleted row -----------------------------------------------------
        setLoading(false);
      } else {
        toast.update(toastId.current, {
          type: toast.TYPE.ERROR,
          render: res.data.message,
          autoClose: 5000,
          isLoading: false,
          delay: 500,
        });
        setLoading(false);
      }
    } catch (err) {
      toast.update(toastId.current, {
        type: toast.TYPE.ERROR,
        render: t("Something went wrong."),
        autoClose: 5000,
        isLoading: false,
        delay: 500,
      });
      setLoading(false);
    }

    setDeleteModal(false);
  };
  // Delete comment ------------------------------------------------------------

  // Comments to ---------------------------------------------------------------
  const [commentToIDs, setCommentToIDs] = useState([]);
  const [commentForIDsFiltered, setCommentToIDsFiltered] = useState<any>([]);

  const filteredOptions = (options: Array<[]>) => {
    var newData: any = [];
    var selData: any = [];

    options.map((item: any, index: number) => {
      if (
        item.value !== userProfile.USER_ID &&
        item.value !== "" &&
        item.value !== 0 &&
        item.value !== null &&
        item.isHidden !== true
      ) {
        newData.push({
          label: item.label,
          value: item.value,
        });

        if (item.selected === true) {
          selData.push({
            label: item.label,
            value: item.value,
          });
        }
      }
    });

    if (selData.length > 0 && defaultSelected === false) {
      setCommentToIDs(selData);
      setDefaultSelected(true);
    }
    return newData;
  };

  const filteredData = (options: Array<[]>) => {
    var newData: any = [];

    options.map((item: any, index: number) => {
      if (
        item.created_by !== userProfile.USER_ID &&
        item.created_by !== "" &&
        item.created_by !== 0 &&
        item.created_by !== null
      ) {
        newData.push({
          label: item.first_name + " " + item.last_name,
          value: item.created_by,
        });
      }
    });
    return newData;
  };

  const filteredDuplicate = (options: Array<[]>) => {
    return options.filter((v: any, i, a) => a.findIndex((v2: any) => v2.value === v.value) === i);
  };

  useEffect(() => {
    var newData: any = [];
    commentToIDs.map((item: any, index: number) => {
      if (item.value !== userProfile.USER_ID) {
        newData.push(item.value);
      }
    });

    setIntialData((prevData: any) => ({
      ...prevData,
      comment_for_ids: newData,
    }));
  }, [commentToIDs]);

  useEffect(() => {
    let optionsData_1 = [];
    let optionsData_2 = [];
    let optionsData_3 = [];
    if (commentForIDs.length > 0) {
      optionsData_1 = filteredOptions(commentForIDs);
    }
    if (commentsList.length > 0) {
      optionsData_2 = filteredData(commentsList);
      optionsData_2 = optionsData_2.filter(
        (item: any, index: number, self: any) =>
          index === self.findIndex((t: any) => t.value === item.value)
      );
    }
    optionsData_3 = filteredDuplicate([...optionsData_1, ...optionsData_2]);
    setCommentToIDsFiltered(optionsData_3);
  }, [commentForIDs, commentsList]);

  // Comments to ---------------------------------------------------------------

  //Pre Commment
  useEffect(() => {
    if (preComment && preComment != "") {
      commentData.current = preComment;
    }
  }, [preComment]);

  useEffect(() => {
    if (commentData.current)
      setIntialData((preState: any) => ({ ...preState, comment: commentData.current }));
  }, [commentData.current]);

  return (
    <React.Fragment>
      {comment && (
        <DeleteModal
          recordName={recordName}
          modalTitle={t("Comments")}
          show={deleteModal}
          onDeleteClick={handleDeleteComment}
          onCloseClick={() => setDeleteModal(false)}
          loading={loading}
        />
      )}

      {moduleData.module_main_id !== 0 ? (
        <Col xl="12">
          <div id="comments_data_table" className="table-responsive mb-3">
            <Table id="multi_comments" className="table table-bordered nowrap w-100 mb-0">
              <thead className="thead-light">
                <tr>
                  {!hiddenColumns.includes(1) && <th>{t("Comments")}</th>}
                  {moduleData.module_app_id === 1 && <th>{t("Source")}</th>}
                  {!hiddenColumns.includes(3) && <th>{t("Action")}</th>}
                </tr>
              </thead>
              <tbody>
                {fetchData === true && commentsList && commentsList.length > 0 ? (
                  commentsList.map((data: any) => (
                    <tr key={data.id}>
                      {!hiddenColumns.includes(1) && (
                        <td
                          style={{
                            whiteSpace: "inherit",
                            wordBreak: "break-word",
                          }}
                        >
                          <p className="mb-0" dangerouslySetInnerHTML={{ __html: data.comment }} />

                          {data.comment_for_ids_array ? (
                            <>
                              <div className="mt-2"></div>

                              {data.comment_for_ids_array.map((user_data: any, idx: number) => (
                                <span
                                  key={idx}
                                  title={`${t("Shared with")} ${user_data.first_name + " " + user_data.last_name
                                    }`}
                                  className={`badge_custome badge ${user_data.id === userProfile.USER_ID
                                    ? "badge-soft-primary bg-primary"
                                    : "badge-soft-info bg-info"
                                    } rounded-pill me-2`}
                                >
                                  <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                                    alternate_email
                                  </span>
                                  {user_data.first_name + " " + user_data.last_name}
                                </span>
                              ))}
                            </>
                          ) : null}

                          <ul className="list-inline mt-1 mb-0 pt-2">
                            <li className="list-inline-item me-3">
                              <span className="text-muted">
                                <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                                  person
                                </span>
                                {t("Comment by")} {data.first_name} {data.last_name}
                              </span>
                            </li>
                            <li className="list-inline-item me-3">
                              <span className="text-muted">
                                <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                                  calendar_today
                                </span>
                                {dateFormat(
                                  data.created_at,
                                  {
                                    dateStyle: "long",
                                    timeStyle: "short",
                                    hour12: true,
                                  },
                                  getLangCode(localStorage.getItem("I18N_LANGUAGE"))
                                )}
                              </span>
                            </li>
                          </ul>
                        </td>
                      )}
                      {moduleData.module_app_id === 1 && (
                        <td>
                          {data.module_app_id !== 4 ? (
                            <Link
                              className="btn btn-sm btn-outline-primary btn-rounded"
                              to={`/${data.url_edit}${data.module_main_id}/${data.module_sub_id}`}
                            >
                              {t(data.module_value)}
                            </Link>
                          ) : userProfile.USER_COMPANY === 1 ? (
                            <Link
                              className="btn btn-sm btn-outline-primary btn-rounded"
                              to={`/${data.url_edit}${data.module_main_id}/${data.module_sub_id}`}
                            >
                              {data.module_value}
                            </Link>
                          ) : (
                            data.module_value
                          )}
                        </td>
                      )}
                      {!hiddenColumns.includes(3) && (
                        <td>
                          <div className="d-flex gap-2 text-end">
                            {(userProfile.IS_COMPANY_ADMIN ||
                              userProfile.USER_ID === data.created_by) &&
                              isDisabled === false ? (
                              <Button
                                outline={true}
                                color="danger"
                                size="sm"
                                onClick={() => onClickDelete(data)}
                              >
                                <span className="align-middle material-symbols-outlined fw-light fs-5">
                                  delete
                                </span>
                              </Button>
                            ) : null}
                          </div>
                        </td>
                      )}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={
                        moduleData.module_app_id === 1
                          ? 2 - hiddenColumns.length
                          : 3 - hiddenColumns.length
                      }
                    >
                      {loading ? t("Loading...") : t("No comments found")}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Col>
      ) : null}

      {commentBlock === false && isDisabled === false ? (
        <Col xl={12} lg={12} md={12} sm={12}>
          {/* {userProfile.CAN_CREATE ? ( */}
          <Button
            color="primary"
            className="btn-block ms-2"
            type="button"
            onClick={() => setCommentBlock(true)}
            disabled={loading}
          >
            {t("Comment")}
          </Button>
          {/* ) : null} */}
        </Col>
      ) : null}

      {commentBlock === true && isDisabled === false ? (
        <>
          {/* {userProfile.CAN_CREATE ? ( */}
          <>
            <Col xl={12} lg={12} md={12} sm={12} className="mb-3">
              <div className="is-invalid">
                <CKEditor
                  key={ckEditorI18()}
                  editor={CustomEditor}
                  config={{ ...ckEditorConfiguration, language: ckEditorI18() }}
                  data={intialData.comment}
                  onChange={CKinputHandler}
                  id="comment"
                  name="comment"
                  className="form-control"
                  onBlur={() => validation.setFieldTouched("comment", true)}
                />
              </div>
              {validation.touched.comment && validation.errors.comment ? (
                <FormFeedback type="invalid">{validation.errors.comment}</FormFeedback>
              ) : null}
            </Col>

            <Col xl="12">
              <Row>
                <Col xl={4} lg={4} md={4} sm={12} className="mb-1">
                  {commentForIDsFiltered.length > 0 && (
                    <Select
                      id="comment_for_ids"
                      name="comment_for_ids"
                      className="is-invalid"
                      options={commentForIDsFiltered}
                      placeholder={t("Comments are visible by...")}
                      value={commentToIDs}
                      isMulti={true}
                      isSearchable={true}
                      isClearable={true}
                      onChange={(e: any) => {
                        setCommentToIDs(e);
                      }}
                    />
                  )}
                </Col>

                <Col xl={8} lg={8} md={8} sm={12} className="mb-1">
                  {moduleData.module_main_id !== 0 ? (
                    <div className="ms-auto d-flex gap-2 justify-content-end">
                      <Button
                        color="primary"
                        className="btn-block"
                        type="button"
                        onClick={() => handleSubmit()}
                        disabled={loading}
                      >
                        {loading ? (
                          <span>
                            <span className="align-middle material-symbols-outlined fw-light fs-5 me-1">
                              hourglass_empty
                            </span>
                            <span>{t("Please wait...")}</span>
                          </span>
                        ) : (
                          <span>{t("Submit")}</span>
                        )}
                      </Button>

                      <Button
                        color="outline-danger"
                        className="btn-block"
                        type="button"
                        onClick={() => setCommentBlock(false)}
                      >
                        {t("Cancel")}
                      </Button>
                    </div>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </>
          {/* ) : null} */}
        </>
      ) : null}
    </React.Fragment>
  );
};

export default MultiComments;
