import axios from "axios";
import { getAxiosConfig, getAxiosConfigWithToken } from "../helpers/functions_helper"
import * as url from "../helpers/url_helper"

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

class UsersService {
    async getUser(DATA: any) {
        const response = await axios
            .get(SERVER_URL + url.GET_USER + "/" + DATA.id + "/" + DATA.user_id,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getUserProfile() {
        const response = await axios
            .get(SERVER_URL + url.GET_USER_PROFILE,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getUsersList(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_USERS_LIST, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getUsersExport(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_USERS_EXPORT, DATA,
                {
                    headers: getAxiosConfigWithToken(),
                    responseType: "blob",
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }
    async getUsersExportCSV(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.GET_USERS_EXPORT_CSV, DATA,
                {
                    headers: getAxiosConfigWithToken(),
                    responseType: "blob",
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async createUser(DATA: any) {
        const response = await axios
            .put(SERVER_URL + url.CREATE_USER, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async updateUser(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.UPDATE_USER, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async deleteUser(DATA: any) {
        const response = await axios
            .post(SERVER_URL + url.DELETE_USER, DATA,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async registerUser(DATA: any) {
        const response = await axios
            .put(SERVER_URL + url.REGISTER_USER, DATA,
                {
                    headers: getAxiosConfig()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }


    async getChartData(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(SERVER_URL + url.USER_CHART_DATA + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }


    async activeVsNonActive(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(SERVER_URL + url.ACTIVE_VS_NON_ACTIVE + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async residentVsNonresident(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(SERVER_URL + url.RESIDENT_VS_NON_RESIDENT + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async individualVsCompany(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(SERVER_URL + url.INDIVIDUAL_VS_COMPANY + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async userVsExpired(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(SERVER_URL + url.USER_VS_EXPIRED + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }



    async userVsArchieved(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(SERVER_URL + url.USER_VS_ARCHIEVE + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async impatriatesData(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(SERVER_URL + url.IMPATRIATES_DATA + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async totalUserStatics(query: string | null) {
        const queryString = query ? '?' + query : '';
        const response = await axios
            .get(SERVER_URL + url.TOTAL_USER_STATICS + queryString,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async notificationUpdate(DATA : any) {
        const response = await axios
            .get(SERVER_URL + url.NOTIFICATION_UPDATE + "/" + DATA.id + "/" + DATA.fieldName + "/" + DATA.fieldValue,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }

    async getNotificationData(DATA : any) {
        const response = await axios
            .get(SERVER_URL + url.NOTIFICATION_DATA + "/" + DATA.id ,
                {
                    headers: getAxiosConfigWithToken()
                }
            ).then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
        return response;
    }


}

export default new UsersService();