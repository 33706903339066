import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
  Alert,
} from "reactstrap";

// Actions
import { TaskStatusAction } from "../../../store/actions";

// Import Breadcrumb
import Breadcrumbs from "../../../components/Layout/Breadcrumb";

// Import DeleteModal
import TaskStatusModal from "./TaskStatusForm";

// Import DeleteModal
import DeleteModal from "../../../components/Common/DeleteModal";

// Import DataTable
import DataTable from "../../../components/Common/DataTable";

import { getDataTableQuery } from "../../../helpers/functions_helper";

const breadcrumbItems = [
  {
    url: "/dashboard",
    label: "Dashboard",
    active: false,
  },
  {
    url: "#",
    label: "Task Status",
    active: true,
  },
];

const AllTaskStatus = (props: any) => {
  document.title = "Task Status | " + process.env.REACT_APP_NAME;
  
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();

  let {
    datatableData,
    datatableErrorMsg,
    createTaskStatusSuccessMsg,
    createTaskStatusErrorMsg,
    createTaskStatusErrorData,
    updateTaskStatusSuccessMsg,
    updateTaskStatusErrorMsg,
    updateTaskStatusErrorData,
    deleteTaskStatusSuccessMsg,
    deleteTaskStatusErrorMsg,
    deleteTaskStatusErrorData,
    getTaskStatusErrorMsg,
    loading,
  } = useSelector((state: any) => ({
    datatableData: state.TaskStatus.GET_TASK_STATUS_LIST_SUCCESS,
    datatableErrorMsg: state.TaskStatus.GET_TASK_STATUS_LIST_ERROR.message,

    createTaskStatusSuccessMsg:
      state.TaskStatus.CREATE_TASK_STATUS_SUCCESS.message,
    createTaskStatusErrorMsg:
      state.TaskStatus.CREATE_TASK_STATUS_ERROR.message,
    createTaskStatusErrorData:
      state.TaskStatus.CREATE_TASK_STATUS_ERROR.data,

    updateTaskStatusSuccessMsg:
      state.TaskStatus.UPDATE_TASK_STATUS_SUCCESS.message,
    updateTaskStatusErrorMsg:
      state.TaskStatus.UPDATE_TASK_STATUS_ERROR.message,
    updateTaskStatusErrorData:
      state.TaskStatus.UPDATE_TASK_STATUS_ERROR.data,

    deleteTaskStatusSuccessMsg:
      state.TaskStatus.DELETE_TASK_STATUS_SUCCESS.message,
    deleteTaskStatusErrorMsg:
      state.TaskStatus.DELETE_TASK_STATUS_ERROR.message,
    deleteTaskStatusErrorData:
      state.TaskStatus.DELETE_TASK_STATUS_ERROR.data,

    getTaskStatusErrorMsg: state.TaskStatus.GET_TASK_STATUS_ERROR.message,

    loading: state.TaskStatus.loading,
  }));

  // DataTable ----------------------------------------------
  function renderHeaderHTML(column: any, colIndex: any) {
    return <span dangerouslySetInnerHTML={{ __html: column.text }} />;
  }
  function renderRowHTML(rowContent: any, row: any) {
    return <span dangerouslySetInnerHTML={{ __html: rowContent }} />;
  }

  const columns = [
    {
      dataField: "DT_RowIndex",
      text: "Sr. No.",
      sort: false,
      search: false,
    },
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: true,
    //   search: true,
    // },    
    {
      dataField: "task_status_value_html",
      text: "Task status",
      sort: true,
      search: true,
      formatter: renderRowHTML,
    },    
    {
      dataField: "action",
      text: "Action",
      sort: false,
      search: false,
      formatter: (rowContent: any, row: any): any => {
        return (
          <div className="d-flex gap-2 text-end">
            <Link 
              className="btn btn-pill btn-outline-primary btn-sm" 
              to="#"
              onClick={() => onClickEdit(row)}
            >
              <i className="fa-regular fa-pen-to-square"></i> Edit
            </Link>
            <Link
              className="btn btn-pill btn-outline-danger btn-sm"
              to="#"
              onClick={() => onClickDelete(row)}
            >
              <i className="fa-regular fa-trash-can"></i> Delete
            </Link>
          </div>
        );
      },
    },
  ];

  const [loadingListData, setLoadingListData] = useState(true);
  const [datatableOptions, setDatatableOptions] = useState({
    draw: 1,
    page: 1,
    recordsTotal: 0,
    recordsFiltered: 0,
    sizePerPage: 20,
    searchText: "",
    sortField: "id",
    sortOrder: "desc",
  });

  useEffect(() => {
    if (loadingListData === true) {
      dispatch(
        TaskStatusAction.getTaskStatusList(
          getDataTableQuery(datatableOptions, columns),
          props.history
        )
      );
    }
  }, [loadingListData]);

  const [taskStatusListData, setTaskStatusListData] = useState([]);
  useEffect(() => {
    if (datatableData) {
      setTaskStatusListData(datatableData.data);

      const updatedDatatableOptions = {
        ...datatableOptions,
        recordsTotal: datatableData.recordsTotal,
        recordsFiltered: datatableData.recordsFiltered,
      };
      setDatatableOptions(updatedDatatableOptions);

      setLoadingListData(false);
      setLoader(false);
    }
  }, [datatableData]);

  const onTableChange = (type: any, newState: any) => {
    let draw = datatableOptions.draw + 1;
    let page = 1;
    if (type == "search") {
      page = 1;
    } else {
      page = newState.page;
    }
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: draw,
      page: page,
      sizePerPage: newState.sizePerPage,
      searchText: newState.searchText,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
    };
    setDatatableOptions(updatedDatatableOptions);
    setLoadingListData(true);
  };

  const clearFilter = () => {
    const updatedDatatableOptions = {
      ...datatableOptions,
      draw: 1,
      page: 1,
      sizePerPage: 20,
      searchText: "",
      sortField: "id",
      sortOrder: "desc",
    };
    setDatatableOptions(updatedDatatableOptions);
    setLoadingListData(true);
  };
  // Datatable -------------------------------------------------

  // Delete Form --------------------------------------------------------
  const [deleteTaskStatus, setDeleteTaskStatus] = useState({ 
    id: "", 
    task_status_id: "" 
  });
  const [recordName, setRecordName] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (row: any) => {
    const deleteTaskStatusData = {
      ...deleteTaskStatus,
      id: row.id,
      task_status_id: row.task_status_id,
    };
    setDeleteTaskStatus(deleteTaskStatusData);
    setRecordName(row.task_status_value);
    setDeleteModal(true);
  };
  
  const handleDeleteTaskStatus = () => {
    dispatch(TaskStatusAction.deleteTaskStatus(deleteTaskStatus, props.history));
  };
  // Delete Form --------------------------------------------------------

  // Master Form -------------------------------------------------------- 
  const [taskStatusData, setTaskStatusData] = useState({}); 

  const [masterFormModal, setMasterFormModal] = useState(false);
  const [masterFormModalTitle, setMasterFormModalTitle] = useState("Create task status");

  const onClickModal = () => {
    setTaskStatusData('');
    setMasterFormModalTitle("Create task status");
    setMasterFormModal(true);
  };
  
  const onClickEdit = (row: any) => {
    setTaskStatusData(row);
    setMasterFormModalTitle("Update task status");
    setMasterFormModal(true);
  }

  const handleSubmitTaskStatus = (values: any) => {
    if(values.id && values.task_status_id){
      dispatch(TaskStatusAction.updateTaskStatus(values, props.history));
    }else{
      dispatch(TaskStatusAction.createTaskStatus(values, props.history));
    }
  };  
  // Master Form --------------------------------------------------------

  // Messages -------------------------------------------------
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorData, setErrorData] = useState({data: {}});

  useEffect(() => {
    if (createTaskStatusSuccessMsg) {
      setSuccessMsg(createTaskStatusSuccessMsg);
      setLoadingListData(true);
      window.scrollTo(0, 0);
      setErrorMsg("");
      setErrorData({data: {}});
      dispatch(TaskStatusAction.createTaskStatusSuccess(""));
      setMasterFormModal(false);
    }
  }, [createTaskStatusSuccessMsg]);

  useEffect(() => {
    if (createTaskStatusErrorMsg) {
      setErrorMsg(createTaskStatusErrorMsg);
      setErrorData({data: createTaskStatusErrorData});
      window.scrollTo(0, 0);
      setSuccessMsg("");
      dispatch(TaskStatusAction.createTaskStatusError(""));
    }
  }, [createTaskStatusErrorMsg]);

  useEffect(() => {
    if (updateTaskStatusSuccessMsg) {
      setSuccessMsg(updateTaskStatusSuccessMsg);
      setLoadingListData(true);
      window.scrollTo(0, 0);
      setErrorMsg("");
      setErrorData({data: {}});
      dispatch(TaskStatusAction.updateTaskStatusSuccess(""));
      setMasterFormModal(false);
    }
  }, [updateTaskStatusSuccessMsg]);

  useEffect(() => {
    if (updateTaskStatusErrorMsg) {
      setErrorMsg(updateTaskStatusErrorMsg);
      setErrorData({data: updateTaskStatusErrorData});
      window.scrollTo(0, 0);
      setSuccessMsg("");
      dispatch(TaskStatusAction.updateTaskStatusError(""));
    }
  }, [updateTaskStatusErrorMsg]);

  useEffect(() => {
    if (deleteTaskStatusSuccessMsg) {
      setSuccessMsg(deleteTaskStatusSuccessMsg);
      setLoadingListData(true);
      window.scrollTo(0, 0);
      setErrorMsg("");
      setErrorData({data: {}});
      dispatch(TaskStatusAction.deleteTaskStatusSuccess(""));
      setDeleteModal(false);
    }
  }, [deleteTaskStatusSuccessMsg]);

  useEffect(() => {
    if (deleteTaskStatusErrorMsg) {
      setErrorMsg(deleteTaskStatusErrorMsg);
      setErrorData({data: deleteTaskStatusErrorData});
      window.scrollTo(0, 0);
      setSuccessMsg("");
      dispatch(TaskStatusAction.deleteTaskStatusError(""));
      setDeleteModal(false);
    }
  }, [deleteTaskStatusErrorMsg]);

  useEffect(() => {
    if (getTaskStatusErrorMsg) {
      setErrorMsg(getTaskStatusErrorMsg);
      window.scrollTo(0, 0);
      setSuccessMsg("");
      dispatch(TaskStatusAction.getTaskStatusError(""));
    }
  }, [getTaskStatusErrorMsg]);
  // Messages -------------------------------------------------

  return (
    <React.Fragment>

      {deleteTaskStatus && (
        <DeleteModal
          recordName={recordName}
          modalTitle="task status"
          show={deleteModal}
          onDeleteClick={handleDeleteTaskStatus}
          onCloseClick={() => setDeleteModal(false)}
          loading={loading}
        />
      )}

      {masterFormModal && (
        <TaskStatusModal
          modalTitle={masterFormModalTitle}
          show={masterFormModal}
          loading={loading}
          taskStatusData={taskStatusData}
          onSubmitClick={handleSubmitTaskStatus}
          onCloseClick={() => setMasterFormModal(false)}
          errorMsg={errorMsg}
          errorData={errorData}
        />
      )}

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbTitle="Task status"
            breadcrumbItems={breadcrumbItems}
          />

          <Row>
            <Col xl="12">
              {successMsg ? <Alert color="success">{successMsg}</Alert> : null}
              {errorMsg ? <Alert color="danger">{errorMsg}</Alert> : null}

              <Card>
                <CardHeader className="bg-transparent border-bottom">
                  <div className="d-sm-flex flex-wrap">
                    <CardTitle className="card-title text-uppercase">
                      Task status list
                    </CardTitle>
                    <div className="ms-auto d-flex gap-2">
                      <Link
                        to="#"
                        className="btn btn-primary btn-rounded waves-effect waves-light btn-label"
                        onClick={() => onClickModal()}
                      >
                        <span className="align-middle material-symbols-outlined fw-light fs-4 label-icon">add</span> Create task status
                      </Link>
                      {/* <Button
                        color="primary"
                        className="btn-rounded waves-effect waves-light"
                      >
                        <i className="fa-solid fa-arrows-rotate"></i>
                      </Button> */}
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <DataTable
                    columns={columns}
                    products={taskStatusListData}
                    datatableOptions={datatableOptions}
                    filters={[]}
                    onTableChange={onTableChange}
                    loadingListData={loadingListData}
                    clearFilter={clearFilter}
                    slug="masters"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AllTaskStatus;
