import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormFeedback } from 'reactstrap';

type ErrorMessageType = {
    error: any;
    touched?: any;
    label?: any;
};

const ErrorMessage: FC<ErrorMessageType> = ({ error, touched, label }) => {
    const { t } = useTranslation();
    return (
        error && touched ? (
            <FormFeedback type="invalid">
                {label && error?.indexOf(label) > -1 ? t(label) + ' ' + t(error.replace(label + ' ', '')) : t(error)}
            </FormFeedback>
        ) : null
    )
}

export default ErrorMessage;