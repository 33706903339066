import React, { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { CommonAction } from "../../../store/actions";
import commonService from "../../../services/common.service";
import {
  addAllUsersInUsers,
  getUserProfile,
  addIndividualInCompany,
  datePickerI18,
} from "../../../helpers/functions_helper";
import { useTranslation } from "react-i18next";

type FiltersProps = {
  filters: any;
  onApply: any;
  onClear: any;
  onChange?: any;
};

const Filters: FC<FiltersProps> = ({ filters, onApply, onClear, onChange }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userProfile = getUserProfile("all-time-sheets");

  // Select options list API call -------------------------------------------------
  const [query, setQuery] = useState("");
  const [loadingCompanyOptions, setLoadingCompanyOptions] = useState(true);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [usersOptions, setUsersOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([
    { label: t("Draft"), value: 2 },
    { label: t("Invoiced"), value: 1 },
    { label: t("-- Remove --"), value: "" },
  ]);

  const getDependentSelectOptions = async (
    tableName: string,
    fieldName: string,
    whereField: string,
    whereID: number
  ) => {
    const response = await commonService.getDependentSelectOptionsList(
      tableName,
      fieldName,
      whereField,
      whereID,
      query ? query : ""
    );
    return response.data.data;
  };

  let { getCompanyOptionsList, loading } = useSelector((state: any) => ({
    getCompanyOptionsList: state.Common.GET_COMPANY_OPTIONS_LIST_SUCCESS_NEW,
    loading: state.Common.loading,
  }));

  // Get options list -------------------------------------------------
  useEffect(() => {
    if (!getCompanyOptionsList) dispatch(CommonAction.getCompanyOptionsList_New());
  }, []);

  useEffect(() => {
    if (getCompanyOptionsList.data) {
      const item = addIndividualInCompany(getCompanyOptionsList.data) as any;
      setCompanyOptions(item);
      setLoadingCompanyOptions(false);
    }
  }, [getCompanyOptionsList]);

  useEffect(() => {
    if (userProfile.CAN_VIEW_ALL) {
      setLoadingUsers(true);

      getDependentSelectOptions("tbl_master_users", "first_name", "company", 1).then(function (
        options
      ) {
        const item = addAllUsersInUsers(options) as any;
        setUsersOptions(item);
        setLoadingUsers(false);
      });
    }
  }, [userProfile.CAN_VIEW_ALL]);

  return (
    <Container>
      {filters?.is_external_filtere !== true ? (
        <>
          <Row>
            <Col>
              <div className="mb-3">
                <Label className="form-label">{t("Client")}</Label>
                <Select
                  placeholder={t("Select...")}
                  options={companyOptions}
                  value={
                    filters?.company_id
                      ? companyOptions.find((option: any) => option.value == filters?.company_id)
                      : ""
                  }
                  isSearchable={true}
                  isDisabled={loadingCompanyOptions}
                  isLoading={loadingCompanyOptions}
                  onChange={(option: any) => {
                    onChange({ is_filtered: true, company_id: option.value });
                  }}
                />
              </div>
            </Col>
          </Row>

          {userProfile.CAN_VIEW_ALL ? (
            <Row>
              <Col>
                <div className="mb-3">
                  <Label className="form-label">{t("User")}</Label>
                  <Select
                    placeholder={t("Select...")}
                    options={usersOptions}
                    value={
                      filters?.user_id
                        ? usersOptions.find((option: any) => option.value == filters?.user_id)
                        : ""
                    }
                    isSearchable={true}
                    isDisabled={loadingUsers}
                    isLoading={loadingUsers}
                    onChange={(option: any) => {
                      onChange({ is_filtered: true, user_id: option.value });
                    }}
                  />
                </div>
              </Col>
            </Row>
          ) : null}
        </>
      ) : null}

      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label">{t("Date")}</Label>
            <Row>
              <Col>
                <div className="input-group is-invalid">
                  <Flatpickr
                    id="from_date"
                    name="from_date"
                    className="form-control d-block"
                    type="text"
                    placeholder={t("From Date")}
                    value={filters?.from_date}
                    options={{
                      altInput: true,
                      altFormat: "d/m/Y",
                      dateFormat: "Y-m-d H:i:S",
                      maxDate: "today",
                      allowInput: true,
                      onChange: function (selectedDates, dateStr, instance) {
                        onChange({ is_filtered: true, from_date: dateStr });
                      },
                      locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                    }}
                  />
                  <span className="input-group-text">
                    <span className="align-middle material-symbols-outlined fw-light fs-4">
                      calendar_today
                    </span>
                  </span>
                </div>
              </Col>
              <Col>
                <div className="input-group is-invalid">
                  <Flatpickr
                    id="to_date"
                    name="to_date"
                    className="form-control d-block"
                    type="text"
                    placeholder={t("To Date")}
                    value={filters?.to_date}
                    options={{
                      altInput: true,
                      altFormat: "d/m/Y",
                      dateFormat: "Y-m-d H:i:S",
                      minDate: filters?.from_date,
                      maxDate: "today",
                      allowInput: true,
                      onChange: function (selectedDates, dateStr, instance) {
                        onChange({ is_filtered: true, to_date: dateStr });
                      },
                      locale: { ...datePickerI18(), firstDayOfWeek: 1 },
                    }}
                  />
                  <span className="input-group-text">
                    <span className="align-middle material-symbols-outlined fw-light fs-4">
                      calendar_today
                    </span>
                  </span>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="mb-3">
            <Label className="form-label">{t("Billing status")}</Label>
            <Select
              placeholder={t("Select...")}
              options={statusOptions}
              value={
                filters?.is_send_invoice
                  ? statusOptions.find((option: any) => option.value == filters?.is_send_invoice)
                  : ""
              }
              isSearchable={false}
              onChange={(option: any) => {
                onChange({ is_filtered: true, is_send_invoice: option.value });
              }}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Filters;
