import React, { FC } from 'react';
import BaseFlatpickr, { DateTimePickerProps } from "react-flatpickr";
import { useTranslation } from 'react-i18next';
import { FormFeedback, Label } from 'reactstrap';

interface FlatpickrtProps extends DateTimePickerProps {
    label?: string;
    required?: boolean;
    loading?: boolean;
    touched?: any;
    error?: any;
}

const Flatpickr: FC<FlatpickrtProps> = ({ label, required, loading, touched,disabled, error, ...props }) => {

    const { t } = useTranslation();

    return (
        <>
            {label ?
                <Label className="form-label">
                    {t(label)}
                    {required ? <span className="text-warning is_required">&nbsp; *</span> : null}
                </Label>
                : null
            }
            <div className="input-group is-invalid">
                <BaseFlatpickr
                    {...props}
                    disabled={loading||disabled}
                    className={`form-control ${touched && error ? "is-invalid" : ""}`}
                />
                <span className="input-group-text">
                    <span className="align-middle material-symbols-outlined fw-light fs-4">{props?.options?.enableTime ? "schedule" : "calendar_today"}</span>
                </span>
            </div>
            {error && touched ? (
                <FormFeedback type="invalid">
                    {label && error?.indexOf(label) > -1 ? t(label) + ' ' + t(error.replace(label + ' ', '')) : t(error)}
                </FormFeedback>
            ) : null}
        </>
    )
}

export default Flatpickr;