import { takeEvery, call, put } from "redux-saga/effects";
import taskTypesService from "../../../services/masters.task.type.service";
import { AnyAction } from "redux";

// TaskTypes Redux States
import {
  GET_TASK_TYPE,
  GET_TASK_TYPES_LIST,
  CREATE_TASK_TYPE,
  UPDATE_TASK_TYPE,
  DELETE_TASK_TYPE,
} from "./actionTypes";

import { 
    getTaskTypeSuccess, 
    getTaskTypeError,
    
    getTaskTypesListSuccess,
    getTaskTypesListError,

    createTaskTypeSuccess,
    createTaskTypeError,

    updateTaskTypeSuccess,
    updateTaskTypeError,

    deleteTaskTypeSuccess,
    deleteTaskTypeError,
} from "./actions";

function* getTaskType({ payload: { tasktypes, history } }: AnyAction): any {
  try {
    const response = yield call(taskTypesService.getTaskType, tasktypes);
    if (response.data && response.data.status === "success") {
      yield put(getTaskTypeSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getTaskTypeError(response.data));
    } else {
      yield put(getTaskTypeError(response));
    }
  } catch (error: any) {
    yield put(getTaskTypeError(error));
  }
}

function* getTaskTypesList({ payload: { query, history } }: AnyAction): any {
  try {
    const response = yield call(taskTypesService.getTaskTypesList, query);
    if (response.data && response.data.status === "success") {
      yield put(getTaskTypesListSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(getTaskTypesListError(response.data));
    } else {
      yield put(getTaskTypesListError(response));
    }
  } catch (error: any) {
    yield put(getTaskTypesListError(error));
  }
}

function* createTaskType({ payload: { tasktypes, history } }: AnyAction): any {
  try {
    const response = yield call(taskTypesService.createTaskType, tasktypes);
    if (response.data && response.data.status === "success") {
      yield put(createTaskTypeSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(createTaskTypeError(response.data));
    } else {
      yield put(createTaskTypeError(response));
    }
  } catch (error: any) {
    yield put(createTaskTypeError(error));
  }
}

function* updateTaskType({ payload: { tasktypes, history } }: AnyAction): any {
  try {
    const response = yield call(taskTypesService.updateTaskType, tasktypes);
    if (response.data && response.data.status === "success") {
      yield put(updateTaskTypeSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(updateTaskTypeError(response.data));
    } else {
      yield put(updateTaskTypeError(response));
    }
  } catch (error: any) {
    yield put(updateTaskTypeError(error));
  }
}

function* deleteTaskType({ payload: { tasktypes, history } }: AnyAction): any {
  try {
    const response = yield call(taskTypesService.deleteTaskType, tasktypes);
    if (response.data && response.data.status === "success") {
      yield put(deleteTaskTypeSuccess(response.data));
    } else if (response.data && response.data.status === "error") {
      yield put(deleteTaskTypeError(response.data));
    } else {
      yield put(deleteTaskTypeError(response));
    }
  } catch (error: any) {
    yield put(deleteTaskTypeError(error));
  }
}

function* taskTypesSaga(): any {
  yield takeEvery(GET_TASK_TYPE, getTaskType);
  yield takeEvery(GET_TASK_TYPES_LIST, getTaskTypesList);
  yield takeEvery(CREATE_TASK_TYPE, createTaskType);
  yield takeEvery(UPDATE_TASK_TYPE, updateTaskType);
  yield takeEvery(DELETE_TASK_TYPE, deleteTaskType);
}

export default taskTypesSaga;
