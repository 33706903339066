import { useEffect, useState } from "react";
import { dateFormat } from "../../utils/formats";
import { Button, Col, Input, Label, Row } from "reactstrap";
import React from "react";
import DataTableBasic from "../../components/Common/DataTableBasic";
import DatePicker from "../../components/Form/DatePicker/DatePicker";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";
import { YesNoMasters } from "../../common/staticdata";
import Select from "react-select";

type Type = {
  first_name: string;
  last_name: string;
  date_of_birth: string;
  national_number: string;
  is_disabled: number | "";
};

const Dependents = (props: any) => {
  const { t } = useTranslation();
  const { onChange } = props;

  const [initialData, setInitialData] = useState<Type>({
    first_name: "",
    last_name: "",
    date_of_birth: "",
    national_number: "",
    is_disabled: "",
  });

  const columns = [
    {
      dataField: "DT_RowIndex",
      text:  `${t('Sr. No.')}`,
      formatter: (rowContent: any, row: any, idx: number): any => {
        return idx + 1;
      },
    },
    {
      dataField: "first_name",
      text:  `${t('First name')}`,
    },
    {
      dataField: "last_name",
      text:  `${t('Last name')}`,
    },
    {
      dataField: "date_of_birth",
      text:  `${t('Date of birth')}`,
      formatter: (rowContent: any, row: any, idx: number): any => {
        return row.date_of_birth && dateFormat(row.date_of_birth);
      },
    },
    {
      dataField: "national_number",
      text:  `${t('National number')}`,
    },
    {
      dataField: "is_disabled",
      text:  `${t('Accessibility')}`,
      formatter: (rowContent: any, row: any, idx: number): any => {
        return parseInt(row.is_disabled) === 1 ? (
          <span className="btn btn-pill btn-outline-primary btn-sm">
            <span className="align-middle material-symbols-outlined fw-light fs-4">accessible</span>
          </span>
        ) : (<span className="btn btn-pill btn-outline-primary btn-sm">
          <span className="align-middle material-symbols-outlined fw-light fs-4">not_accessible</span>
        </span>);
      },
    },
    {
      dataField: "action",
      text: `${t('Action')}`,
      formatter: (rowContent: any, row: any, idx: number): any => {
        return (
          <div className="d-flex gap-2">
            <Button
              color="primary"
              outline={true}
              className={`btn btn-pill btn-outline-primary btn-sm`}
              type="button"
              onClick={() => editRow(idx)}
            >
              <span className="align-middle material-symbols-outlined fw-light fs-4">edit</span>
            </Button>

            <Button
              color="danger"
              outline={true}
              className={`btn btn-pill btn-outline-danger btn-sm`}
              type="button"
              onClick={() => removeRow(idx)}
            >
              <span className="align-middle material-symbols-outlined fw-light fs-4">delete</span>
            </Button>
          </div>
        );
      },
    },
  ];

  const [isEdit, setIsEdit] = useState(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [addRowBtnDisabled, setAddRowBtnDisabled] = useState(true);
  const [data, setData] = useState<any>([]);
  const [dataList, setDataList] = useState<any>([]);
  const [datatableOptions, setDatatableOptions] = useState({
    draw: 1,
    page: 1,
    recordsTotal: 0,
    recordsFiltered: 0,
    sizePerPage: 5,
    searchText: "",
    sortField: "id",
    sortOrder: "desc",
  });

  const onTableChange = (type: any, newState: any) => {
    let draw = datatableOptions.draw + 1;
    let page = 1;
    if (type === "search") {
      page = 1;
    } else {
      page = newState.page;
    }
    setDatatableOptions((prevData: any) => ({
      ...prevData,
      draw: draw,
      page: page,
      sizePerPage: newState.sizePerPage,
      searchText: newState.searchText,
      sortField: newState.sortField,
      sortOrder: newState.sortOrder,
    }));

    const slicedArray = data.slice(newState.sizePerPage * (page - 1), newState.sizePerPage * page);
    setDataList(slicedArray);
  };

  const addRow = () => {
    setData((preState: any) => [
      ...preState,
      {
        first_name: initialData?.first_name,
        last_name: initialData?.last_name,
        date_of_birth: initialData?.date_of_birth,
        national_number: initialData?.national_number,
        is_disabled: initialData?.is_disabled,
      },
    ]);

    clearForm();
    setAddRowBtnDisabled(true);
  };

  const updateRow = () => {
    if (editIndex !== null) {
      var rows: any = [...data];
      rows.map((item: any, index: number) => {
        if (index === editIndex) {
          rows[index] = {
            ...item,
            first_name: initialData?.first_name,
            last_name: initialData?.last_name,
            date_of_birth: initialData?.date_of_birth,
            national_number: initialData?.national_number,
            is_disabled: initialData?.is_disabled,
          };
        }
      });
      setData(rows);

      clearForm();
      setIsEdit(false);
      setEditIndex(null);
    }
  };

  const removeRow = (idx: number) => {
    const rows = data.filter((item: any, index: number) => index !== idx);
    setData(rows);

    setIsEdit(false);
    setEditIndex(null);
  };

  const editRow = (idx: number) => {
    const row = data.find((item: any, index: number) => index === idx);
    setInitialData((preData: any) => ({
      ...preData,
      first_name: row?.first_name,
      last_name: row?.last_name,
      date_of_birth: row?.date_of_birth,
      national_number: row?.national_number,
      is_disabled: row?.is_disabled,
    }));

    setIsEdit(true);
    setEditIndex(idx);
  };

  const clearForm = () => {
    setInitialData((preData: any) => ({
      ...preData,
      first_name: "",
      last_name: "",
      date_of_birth: "",
      national_number: "",
      is_disabled: 0,
    }));
  };

  const handleChange = (fieldName: string, fieldValue: any) => {
    setInitialData((preState) => ({ ...preState, [fieldName]: fieldValue }));
  };

  useEffect(() => {
    if (
      initialData.first_name !== "" &&
      initialData.last_name !== "" &&
      initialData.date_of_birth !== "" &&
      initialData.national_number !== ""
    ) {
      setAddRowBtnDisabled(false);
    }else{
      setAddRowBtnDisabled(true);
    }
  }, [
    initialData.first_name,
    initialData.last_name,
    initialData.date_of_birth,
    initialData.national_number,
  ]);

  useEffect(() => {
    setData(props?.data);
  }, [props?.data]);

  useEffect(() => {
    setDatatableOptions((prevData: any) => ({
      ...prevData,
      draw: 1,
      page: 1,
      sizePerPage: 5,
      recordsTotal: data.length,
      recordsFiltered: data.length,
    }));

    const slicedArray = data.slice(0, 5);
    setDataList(slicedArray);

    onChange(data);
  }, [data]);

  return (
    <React.Fragment>
      {(data.length < props?.dependent_count || isEdit) && (
        <Col className="col-12">
          <Row>
            <Col xl={3} lg={3} md={3} sm={6}>
              <div className="mb-3">
                <Label className="form-label">
                  {t("First name")} <span className="text-warning is_required">*</span>
                </Label>
                <Input
                  id="first_name"
                  name="first_name"
                  placeholder={t("First name")}
                  type="text"
                  value={initialData.first_name}
                  onChange={(e) => handleChange("first_name", e.target.value)}
                />
              </div>
            </Col>

            <Col xl={3} lg={3} md={3} sm={6}>
              <div className="mb-3">
                <Label className="form-label">
                {t("Last name")} <span className="text-warning is_required">*</span>
                </Label>
                <Input
                  id="last_name"
                  name="last_name"
                  placeholder={t("Last name")}
                  type="text"
                  value={initialData.last_name}
                  onChange={(e) => handleChange("last_name", e.target.value)}
                />
              </div>
            </Col>

            <Col xl={2} lg={2} md={2} sm={12}>
              <div className="mb-3">
                <DatePicker
                  id="date_of_birth"
                  name="date_of_birth"
                  label={t("Date of birth")}
                  required={true}
                  value={initialData.date_of_birth}
                  options={{
                    altInput: true,
                    altFormat: "d/m/Y",
                    dateFormat: "Y-m-d H:i:S",
                    allowInput: true,
                    maxDate: "today",
                    onChange: function (selectedDates, dateStr, instance) {
                      handleChange("date_of_birth", dateStr);
                    },
                    onClose: function (selectedDates, dateStr, instance) {
                      handleChange("date_of_birth", dateStr);
                    },
                    locale: { firstDayOfWeek: 1 },
                  }}
                />
              </div>
            </Col>

            <Col xl={2} lg={2} md={2} sm={6}>
              <div className="mb-3">
                <Label className="form-label">
                {t("National number")} <span className="text-warning is_required">*</span>
                </Label>
                <InputMask
                  id="national_number"
                  name="national_number"
                  className="form-control"
                  placeholder={t("National number")}
                  type="text"
                  mask="99.99.99-999.99"
                  value={initialData.national_number}
                  onChange={(e) => handleChange("national_number", e.target.value)}
                />
              </div>
            </Col>

            <Col xl={2} lg={2} md={2} sm={6}>
              <Row>
                <Col xl={8} lg={8} md={8} sm={8}>
                  <div className="mb-3">
                    <Label className="form-label">
                    {t("Accessibility")} <span className="text-warning is_required">*</span>
                    </Label>
                    <Select
                      id="is_disabled"
                      name="is_disabled"
                      className="is-invalid"
                      options={YesNoMasters}
                      value={
                        (initialData.is_disabled != "")
                          ? YesNoMasters.find((option: any) => option.value == initialData.is_disabled)
                          : ""
                      }
                      isSearchable={true}
                      onChange={(e: any) => {
                        handleChange("is_disabled", e.value);
                      }}
                    />
                  </div>
                </Col>

                <Col xl={4} lg={4} md={4} sm={4}>
                  <div className="mb-3">
                    <Label className="form-label">
                      <br />
                    </Label>
                    <div className="input-group">
                      {isEdit === true ? (
                        <Button
                          color="success"
                          type="button"
                          className={`btn btn-sm btn-success`}
                          disabled={addRowBtnDisabled}
                          onClick={() => updateRow()}
                        >
                          <span className="align-middle material-symbols-outlined fw-light fs-4">
                            done
                          </span>
                        </Button>
                      ) : (
                        <Button
                          type="button"
                          className={`btn btn-sm ${addRowBtnDisabled ? "btn-primary" : "btn-info"}`}
                          disabled={addRowBtnDisabled}
                          onClick={() => addRow()}
                        >
                          <span className="align-middle material-symbols-outlined fw-light fs-4">
                            add
                          </span>
                        </Button>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      )}

      <Col className="col-12 mt-2">
        {dataList && dataList.length > 0 && (
          <DataTableBasic
            columns={columns}
            products={dataList}
            datatableOptions={datatableOptions}
            onTableChange={onTableChange}
          />
        )}
      </Col>
    </React.Fragment>
  );
};

export default Dependents;
